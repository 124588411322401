// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OptionPutCall } from './OptionPutCall';
import { PriceLimitMeasure } from './PriceLimitMeasure';
import { StrikePriceSettings } from './StrikePriceSettings';

export class OptionContract {
    public GroupContractID: any;
    public StrikeContractID: any;
    public Alias: any;
    public StrikePrice: any;
    public PutCall: any;
    public LowLimit: number = 0;
    public HighLimit: number = 0;
    public minLot: number = -1;
    public maxLot: number = -1;
    public PriceLimitMeasure: any;
    public Description: any = null;
    public ExtFields: any = {};
    public TradeSessionStatusId: any = null;
    public ExchangeSessionName: any = null;
    public InstrumentTradableID: any = null;
    public ISIN: string = '';
    public TradingMode: any = null;
    public IsHighLimitFrontEndValidationEnabled: boolean;
    public IsLowLimitFrontEndValidationEnabled: boolean;

    constructor (groupContractID, strikeContractID, alias, strikePrice, putCall) {
        this.GroupContractID = groupContractID;
        this.StrikeContractID = strikeContractID;
        this.Alias = alias;
        this.StrikePrice = strikePrice;
        this.PutCall = putCall;
        this.PriceLimitMeasure = PriceLimitMeasure.DISABLE;
    }

    public static MergeOptionContractGroup (ids, optionContractList): any {
        if (!ids || !optionContractList) {
            return;
        }

        const idsContractID = ids.ContractID;
        const ocl = optionContractList.filter(function (oc) {
            return oc.GroupContractID === idsContractID;
        });

        for (let i = 0, len = ocl.length; i < len; i++) {
            const oc = ocl[i];

            let sps = null;
            const strikePricesList = ids.StrikePricesList;
            for (let spl_i = 0, spl_len = strikePricesList.length; spl_i < spl_len; spl_i++) {
                const sp = strikePricesList[spl_i];
                if (sp.StrikePrice === oc.StrikePrice) {
                    sps = sp;
                    break;
                }
            }

            const isPut = oc.PutCall === OptionPutCall.OPTION_PUT_VANILLA;
            const isCall = oc.PutCall === OptionPutCall.OPTION_CALL_VANILLA;

            if (!sps) {
                sps = new StrikePriceSettings(oc.StrikePrice, isPut, isCall);
                ids.StrikePricesList.push(sps);
            }

            sps.TradingMode = oc.TradingMode; // #115179

            if (isPut) {
                sps.PutTicker = oc.Alias;
                sps.LowLimitPut = oc.LowLimit;
                sps.HighLimitPut = oc.HighLimit;
                sps.minLotPut = oc.minLot;
                sps.maxLotPut = oc.maxLot;
                sps.PriceLimitMeasurePut = oc.PriceLimitMeasure;
                sps.DescriptionPut = oc.Description;
                sps.ExtFieldsPut = oc.ExtFields;
                sps.PutEnabled = true;
                sps.TradeSessionStatusIdPut = oc.TradeSessionStatusId;
                sps.ExchangeSessionNamePut = oc.ExchangeSessionName;
                sps.ContractIdPut = oc.StrikeContractID;
                sps.InstrumentTradableIDPut = oc.InstrumentTradableID;
                sps.ISINPut = oc.ISIN;
            } else {
                sps.CallTicker = oc.Alias;
                sps.LowLimitCall = oc.LowLimit;
                sps.HighLimitCall = oc.HighLimit;
                sps.minLotCall = oc.minLot;
                sps.maxLotCall = oc.maxLot;
                sps.PriceLimitMeasureCall = oc.PriceLimitMeasure;
                sps.DescriptionCall = oc.Description;
                sps.ExtFieldsCall = oc.ExtFields;
                sps.CallEnabled = true;
                sps.TradeSessionStatusIdCall = oc.TradeSessionStatusId;
                sps.ExchangeSessionNameCall = oc.ExchangeSessionName;
                sps.ContractIdCall = oc.StrikeContractID;
                sps.InstrumentTradableIDCall = oc.InstrumentTradableID;
                sps.ISINCall = oc.ISIN;
            }
        }

        ids.useAliasName = !!ocl.length;
    }
}
