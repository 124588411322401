// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type CustodialData } from '../../Utils/Instruments/CustodialData';
import { type Instrument } from './Instrument';

export class CustodialPlan {
    public _dataByInstruments: Record<string, CustodialData> = {};//= new ConcurrentDictionary<string, CustodialData>();
    public _dataByInstrumentGroup: Record<number, CustodialData> = {}; //= new ConcurrentDictionary<int, CustodialData>();
    public _defaultData: any;// CustodialData
    public _dataByInstrumentsHasValue = false;
    public _dataByInstrumentGroupHasValue = false;
    public Name: any;
    public Id: any;

    constructor (id) {
        this.Id = id;
    }

    public GetData (instr: Instrument | null): CustodialData {
        if (isNullOrUndefined(instr)) {
            return null;
        }

        let data = this._dataByInstruments[instr.Id];// InstrumentId === Id
        if (!isNullOrUndefined(data)) {
            return data;
        }

        data = this._dataByInstrumentGroup[instr.TypeId]; // InstrumentGroupId === TypeId
        if (!isNullOrUndefined(data)) {
            return data;
        }

        return this._defaultData;
    }

    public Update (message): void {
        this.Name = message.Name;
        this._dataByInstruments = {};
        this._dataByInstrumentGroup = {};
        this._defaultData = null;
        this._dataByInstrumentsHasValue = false;
        this._dataByInstrumentGroupHasValue = false;

        for (let i = 0; i < message.Data.length; i++) {
            this.AddData(message.Data[i]);
        }
    }

    public AddData (data): void {
        if (data.InstrumentId) {
            this._dataByInstruments[data.InstrumentId] = data;
            this._dataByInstrumentsHasValue = true;
        } else if (data.InstrumentGroupId !== null) {
            this._dataByInstrumentGroup[data.InstrumentGroupId] = data;
            this._dataByInstrumentGroupHasValue = true;
        } else {
            this._defaultData = data;
        }
    }

    public IsZero (): boolean {
        return !this._dataByInstrumentsHasValue && !this._dataByInstrumentGroupHasValue && this._defaultData.Fee === 0 && this._defaultData.InstrumentGroupId === null && this._defaultData.InstrumentId === '';
    }
}
