// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ColorStyleWidth, DynProperty } from '@shared/commons/DynProperty';
import { Font, Pen, PenStyle, SolidBrush } from '@shared/commons/Graphics';
import { Resources } from '@shared/localizations/Resources';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { TerceraChartBaseScaleRendererSettings } from './TerceraChartBaseScaleRenderer';

export class TerceraChartTimeScaleRendererSettings extends TerceraChartBaseScaleRendererSettings {
    private textSeparatorColor = ThemeManager.CurrentTheme.Chart_TextSeparateColor;

    constructor () {
        super();

        this.MonthSeparatorsVisibility = true;
        this.YearSeparatorsVisibility = true;
        this.WeekSeparatorsVisibility = true;
        this.DaySeparatorsVisibility = true;
        this.ScaleGridVisibility = true;

        this.textSeparatorBrush = new SolidBrush(this.textSeparatorColor);

        this.TimeScaleImportantDatesFont = new Font(10, ThemeManager.customFontFamily);

        this.daySeparatorsPen = new Pen('', 1, PenStyle.ShapedChart);
        this.weekSeparatorsPen = new Pen('', 1, PenStyle.ShapedChart);
        this.monthSeparatorsPen = new Pen('', 1, PenStyle.ShapedChart);
        this.yearSeparatorsPen = new Pen('', 1, PenStyle.ShapedChart);

        this.dayBorderSeparatorsPen = new Pen('', 1);
        this.weekBorderSeparatorsPen = new Pen('', 1);
        this.monthBorderSeparatorsPen = new Pen('', 1);
        this.yearBorderSeparatorsPen = new Pen('', 1);

        this.updateSeparatorsColors();
    }

    ThemeChanged (): void {
        this.ScaleAxisColor = ThemeManager.CurrentTheme.Chart_TimeScaleAxisColor;
        this.ScaleBackColor = ThemeManager.CurrentTheme.Chart_TimeScaleBackColor;
        this.ScaleTextColor = ThemeManager.CurrentTheme.Chart_TimeScaleTextColor;
        this.ScaleGridColor = ThemeManager.CurrentTheme.Chart_GridTimeColor;

        this.updateSeparatorsColors();

        this.textSeparatorColor = ThemeManager.CurrentTheme.Chart_TextSeparateColor;
        this.textSeparatorBrush = new SolidBrush(this.textSeparatorColor);
        this.ScaleFont = ThemeManager.Fonts.Font_10F_regular.copy();

        this.TimeScaleImportantDatesFont = ThemeManager.Fonts.Font_10F_regular.copy();
        this.TimeScaleImportantDatesFont.FontWeight = 'bold';
    };

    Properties (): any {
        const properties: DynProperty[] = [];

        // Grid
        let SeparatorGroup = '#-4#' + Resources.getResource('property.SeparatorGroup.Grid');

        const colorStyleWidth = new ColorStyleWidth(this.ScaleGridColor, this.ScaleGridStyle, this.ScaleGridWidth);
        colorStyleWidth.CheckBoxVisible = true;
        colorStyleWidth.Checked = this.ScaleGridVisibility;
        // colorStyleWidth.DisableWidth = true;

        let prop = new DynProperty('GridTime', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 1;
        properties.push(prop);

        // Appearance
        SeparatorGroup = '#2#' + Resources.getResource('property.SeparatorGroup.Appearance');

        /* if (this.ScaleFont)
            {
                prop = new DynProperty("TimeScaleFont", this.ScaleFont.copy(), DynProperty.FONT, DynProperty.TIME_SCALE_GROUP);
                prop.sortIndex = 0;
                prop.separatorGroup = SeparatorGroup;
                properties.push(prop);
            } */

        /* if (this.TimeScaleImportantDatesFont)
            {
                prop = new DynProperty("TimeScaleImportentFont", this.TimeScaleImportantDatesFont.copy(), DynProperty.FONT, DynProperty.TIME_SCALE_GROUP);
                prop.sortIndex = 1;
                prop.separatorGroup = SeparatorGroup;
                properties.push(prop);
            } */

        prop = new DynProperty('TimeScaleTextColor', this.ScaleTextColor, DynProperty.COLOR, DynProperty.TIME_SCALE_GROUP);
        prop.sortIndex = 2;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        prop = new DynProperty('TimeScaleAxisColor', this.ScaleAxisColor, DynProperty.COLOR, DynProperty.TIME_SCALE_GROUP);
        prop.sortIndex = 3;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        prop = new DynProperty('TimeScaleBackColor', this.ScaleBackColor, DynProperty.COLOR, DynProperty.TIME_SCALE_GROUP);
        prop.sortIndex = 4;
        prop.separatorGroup = SeparatorGroup;
        properties.push(prop);

        // Highlight Date
        properties.push(...this.getHighlightDateProperties());

        return properties;
    }

    callBack (properties): void {
        let dp = DynProperty.getPropertyByName(properties, 'GridTime');
        if (dp != null) {
            const csw = dp.value;
            this.ScaleGridColor = csw.Color;
            this.ScaleGridStyle = csw.Style;
            this.ScaleGridWidth = csw.Width;
            this.ScaleGridVisibility = csw.Checked;
        }

        // appearance
        dp = DynProperty.getPropertyByName(properties, 'TimeScaleFont');
        if (dp != null) this.ScaleFont = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'TimeScaleImportentFont');
        if (dp != null) this.TimeScaleImportantDatesFont = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'TimeScaleAxisColor');
        if (dp != null) this.ScaleAxisColor = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'TimeScaleBackColor');
        if (dp != null) this.ScaleBackColor = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'TimeScaleTextColor');
        if (dp != null) this.ScaleTextColor = dp.value;

        // DaySeparatorsVisibility is no longer saved
        dp = DynProperty.getPropertyByName(properties, 'DaySeparatorsVisibility');
        if (dp != null) this.DaySeparatorsVisibility = dp.value;

        // WeekSeparatorsVisibility is no longer saved
        dp = DynProperty.getPropertyByName(properties, 'WeekSeparatorsVisibility');
        if (dp != null) this.WeekSeparatorsVisibility = dp.value;

        // MonthSeparatorsVisibility is no longer saved
        dp = DynProperty.getPropertyByName(properties, 'MonthSeparatorsVisibility');
        if (dp != null) this.MonthSeparatorsVisibility = dp.value;

        // YearSeparatorsVisibility is no longer saved
        dp = DynProperty.getPropertyByName(properties, 'YearSeparatorsVisibility');
        if (dp != null) this.YearSeparatorsVisibility = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'DaySeparators');
        if (dp != null) {
            const csw: ColorStyleWidth = dp.value;
            this.daySeparatorsPen.setNewProperties(csw);
            this.DaySeparatorsVisibility = csw.Checked;
            this.dayBorderSeparatorsPen.Color = csw.Color;
        }

        dp = DynProperty.getPropertyByName(properties, 'WeekSeparators');
        if (dp != null) {
            const csw: ColorStyleWidth = dp.value;
            this.weekSeparatorsPen.setNewProperties(csw);
            this.WeekSeparatorsVisibility = csw.Checked;
            this.weekBorderSeparatorsPen.Color = csw.Color;
        }

        dp = DynProperty.getPropertyByName(properties, 'MonthSeparators');
        if (dp != null) {
            const csw: ColorStyleWidth = dp.value;
            this.monthSeparatorsPen.setNewProperties(csw);
            this.MonthSeparatorsVisibility = csw.Checked;
            this.monthBorderSeparatorsPen.Color = csw.Color;
        }

        dp = DynProperty.getPropertyByName(properties, 'YearSeparators');
        if (dp != null) {
            const csw: ColorStyleWidth = dp.value;
            this.yearSeparatorsPen.setNewProperties(csw);
            this.YearSeparatorsVisibility = csw.Checked;
            this.yearBorderSeparatorsPen.Color = csw.Color;
        }
    }

    private getHighlightDateProperties (): DynProperty[] {
        const properties: DynProperty[] = [];
        const SeparatorGroup = '#3#' + Resources.getResource('property.SeparatorGroup.HighlightDate');
        let colorStyleWidth = new ColorStyleWidth(this.daySeparatorsPen.Color, this.daySeparatorsPen.DashStyle, this.daySeparatorsPen.Width);
        colorStyleWidth.CheckBoxVisible = true;
        colorStyleWidth.Checked = this.DaySeparatorsVisibility;

        let prop = new DynProperty('DaySeparators', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.TIME_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 0;
        properties.push(prop);

        colorStyleWidth = new ColorStyleWidth(this.weekSeparatorsPen.Color, this.weekSeparatorsPen.DashStyle, this.weekSeparatorsPen.Width);
        colorStyleWidth.CheckBoxVisible = true;
        colorStyleWidth.Checked = this.WeekSeparatorsVisibility;

        prop = new DynProperty('WeekSeparators', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.TIME_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 1;
        properties.push(prop);

        colorStyleWidth = new ColorStyleWidth(this.monthSeparatorsPen.Color, this.monthSeparatorsPen.DashStyle, this.monthSeparatorsPen.Width);
        colorStyleWidth.CheckBoxVisible = true;
        colorStyleWidth.Checked = this.MonthSeparatorsVisibility;

        prop = new DynProperty('MonthSeparators', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.TIME_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 2;
        properties.push(prop);

        colorStyleWidth = new ColorStyleWidth(this.yearSeparatorsPen.Color, this.yearSeparatorsPen.DashStyle, this.yearSeparatorsPen.Width);
        colorStyleWidth.CheckBoxVisible = true;
        colorStyleWidth.Checked = this.YearSeparatorsVisibility;

        prop = new DynProperty('YearSeparators', colorStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.TIME_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 3;
        properties.push(prop);

        return properties;
    }

    private updateSeparatorsColors (): void {
        const yearSeparatorsColor = ThemeManager.CurrentTheme.Chart_YearSeparateColor;
        const monthSeparatorsColor = ThemeManager.CurrentTheme.Chart_MonthSeparateColor;
        const weekSeparatorsColor = ThemeManager.CurrentTheme.Chart_WeekSeparateColor;
        const daySeparatorsColor = ThemeManager.CurrentTheme.Chart_DaySeparateColor;

        this.daySeparatorsPen.Color = daySeparatorsColor;
        this.dayBorderSeparatorsPen.Color = daySeparatorsColor;
        this.weekSeparatorsPen.Color = weekSeparatorsColor;
        this.weekBorderSeparatorsPen.Color = weekSeparatorsColor;
        this.monthSeparatorsPen.Color = monthSeparatorsColor;
        this.monthBorderSeparatorsPen.Color = monthSeparatorsColor;
        this.yearSeparatorsPen.Color = yearSeparatorsColor;
        this.yearBorderSeparatorsPen.Color = yearSeparatorsColor;
    }
}
