// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { TradingSignalRequestMessage } from '../Messages/TradingSignalRequestMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingSignalRequestMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): TradingSignalRequestMessage[] {
        const message = new TradingSignalRequestMessage(fieldSet);
        return [message];
    }
}
