// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { InstrumentTypes } from '@shared/utils/Instruments/InstrumentTypes';
import { MarginInfoGroupType, MarginInfoItemType } from './MarginInfoEnums';

export class MarginInfoUtils {
    static EMPTY_STRING: string = '---';

    static TICKCOST_INS_TYPES: InstrumentTypes[] = [InstrumentTypes.FUTURES, InstrumentTypes.CFD_FUTURES, InstrumentTypes.OPTIONS, InstrumentTypes.SPREADBET];

    static ALLOW_SHOW_POSITIONS: string = Resources.getResource('InstrumentDetailsPanel.Allow short positions');
    static NOT_ALLOW_SHORT_POSITIONS: string = Resources.getResource('InstrumentDetailsPanel.Not allow short positions');

    static getGroupText (type: MarginInfoGroupType, currency: string): string {
        switch (type) {
        case MarginInfoGroupType.KEY_GROUP_RISKS:
            return `${Resources.getResource('OrderEntry.InfoBlock.1.Risks')} ${currency}`;
        case MarginInfoGroupType.KEY_GROUP_FEES:
            return `${Resources.getResource('OrderEntry.InfoBlock.2.Fees')} ${currency}`;
        default:
            return '';
        }
    }

    static getItemText (type: MarginInfoItemType): string {
        switch (type) {
        // 1.Risks
        case MarginInfoItemType.KEY_BALANCE:
            return Resources.getResource('OrderEntry.InfoBlock.Balance');
        case MarginInfoItemType.KEY_AVAILABLE_FUNDS:
            return Resources.getResource('OrderEntry.InfoBlock.Availible funds');
        case MarginInfoItemType.KEY_INCOMING_FUNDS:
            return Resources.getResource('OrderEntry.InfoBlock.Incoming funds');
        case MarginInfoItemType.KEY_MARGIN_AVAILABLE:
            return Resources.getResource('OrderEntry.InfoBlock.Margin availible');
        case MarginInfoItemType.KEY_INIT_MARGIN:
            return Resources.getResource('OrderEntry.InfoBlock.Init. margin');
        case MarginInfoItemType.KEY_MAINT_MARGIN:
            return Resources.getResource('OrderEntry.InfoBlock.Maint. margin');
        case MarginInfoItemType.KEY_WARN_MARGIN:
            return Resources.getResource('OrderEntry.InfoBlock.Warn. margin');
        case MarginInfoItemType.KEY_IMPACT_ON_PORTFOLIO:
            return Resources.getResource('OrderEntry.InfoBlock.Impact on portfolio');
        case MarginInfoItemType.KEY_AFTER_TRADE_FUNDS:
            return Resources.getResource('OrderEntry.InfoBlock.After trade funds');
        case MarginInfoItemType.KEY_BLOCKED_FOR_STOCKS:
            return Resources.getResource('OrderEntry.InfoBlock.Blocked for Stocks');
        case MarginInfoItemType.KEY_SPREAD_INIT_LOSS:
            return Resources.getResource('OrderEntry.InfoBlock.Spread in loss');
        case MarginInfoItemType.KEY_PL_PER_TICK:
            return Resources.getResource('OrderEntry.InfoBlock.P/L per Tick');
        case MarginInfoItemType.KEY_ALLOW_SHORT_POSITIONS:
            return Resources.getResource('OrderEntry.InfoBlock.Allow short positions');
        case MarginInfoItemType.KEY_AFTER_TRADE_CASH:
            return Resources.getResource('OrderEntry.InfoBlock.After trade cash');
            // 2.Fees
        case MarginInfoItemType.KEY_FILL_PER_LOT:
            return Resources.getResource('OrderEntry.InfoBlock.FillPerLot');
        case MarginInfoItemType.KEY_ORDER_PER_LOT:
            return Resources.getResource('OrderEntry.InfoBlock.OrderPerLot');
        case MarginInfoItemType.KEY_FILL_VOLUME:
            return Resources.getResource('OrderEntry.InfoBlock.FillVolume');
        case MarginInfoItemType.KEY_FILL_VOLUME_WITH_MIN_PD:
            return Resources.getResource('OrderEntry.InfoBlock.FillVolumeWithMinPD');
        case MarginInfoItemType.KEY_PER_FILL:
            return Resources.getResource('OrderEntry.InfoBlock.PerFill');
        case MarginInfoItemType.KEY_PER_TRANSACTION:
            return Resources.getResource('OrderEntry.InfoBlock.PerTransaction');
        case MarginInfoItemType.KEY_PER_PHONE_TRANSACTION:
            return Resources.getResource('OrderEntry.InfoBlock.PerPhoneTransaction');
        case MarginInfoItemType.KEY_VAT:
            return Resources.getResource('OrderEntry.InfoBlock.VAT');
        case MarginInfoItemType.KEY_ORDER_VOLUME:
            return Resources.getResource('OrderEntry.InfoBlock.OrderVolume');
        case MarginInfoItemType.KEY_SHORT_SWAP_INFO:
            return Resources.getResource('OrderEntry.InfoBlock.SwapDetails');
        case MarginInfoItemType.KEY_FREE_COMMISSION:
            return Resources.getResource('OrderEntry.InfoBlock.Free commission');
        case MarginInfoItemType.KEY_TOTAL_FEES:
            return Resources.getResource('OrderEntry.InfoBlock.TotalFee');
        case MarginInfoItemType.KEY_LONG_SWAP:
            return Resources.getResource('OrderEntry.InfoBlock.LongSwap');
        case MarginInfoItemType.KEY_SHORT_SWAP:
            return Resources.getResource('OrderEntry.InfoBlock.ShortSwap');
        case MarginInfoItemType.KEY_ORDER_CARE:
            return Resources.getResource('OrderEntry.InfoBlock.CareOrder');
        default:
            return '';
        }
    }

    static getGroupForItem (type: MarginInfoItemType): MarginInfoGroupType {
        switch (type) {
        case MarginInfoItemType.KEY_BALANCE:
        case MarginInfoItemType.KEY_AVAILABLE_FUNDS:
        case MarginInfoItemType.KEY_INCOMING_FUNDS:
        case MarginInfoItemType.KEY_MARGIN_AVAILABLE:
        case MarginInfoItemType.KEY_INIT_MARGIN:
        case MarginInfoItemType.KEY_MAINT_MARGIN:
        case MarginInfoItemType.KEY_WARN_MARGIN:
        case MarginInfoItemType.KEY_IMPACT_ON_PORTFOLIO:
        case MarginInfoItemType.KEY_AFTER_TRADE_FUNDS:
        case MarginInfoItemType.KEY_BLOCKED_FOR_STOCKS:
        case MarginInfoItemType.KEY_SPREAD_INIT_LOSS:
        case MarginInfoItemType.KEY_PL_PER_TICK:
        case MarginInfoItemType.KEY_ALLOW_SHORT_POSITIONS:
        case MarginInfoItemType.KEY_AFTER_TRADE_CASH:
            return MarginInfoGroupType.KEY_GROUP_RISKS;
        case MarginInfoItemType.KEY_FILL_PER_LOT:
        case MarginInfoItemType.KEY_ORDER_PER_LOT:
        case MarginInfoItemType.KEY_FILL_VOLUME:
        case MarginInfoItemType.KEY_FILL_VOLUME_WITH_MIN_PD:
        case MarginInfoItemType.KEY_PER_FILL:
        case MarginInfoItemType.KEY_PER_TRANSACTION:
        case MarginInfoItemType.KEY_PER_PHONE_TRANSACTION:
        case MarginInfoItemType.KEY_VAT:
        case MarginInfoItemType.KEY_ORDER_VOLUME:
        case MarginInfoItemType.KEY_SHORT_SWAP_INFO:
        case MarginInfoItemType.KEY_FREE_COMMISSION:
        case MarginInfoItemType.KEY_TOTAL_FEES:
        case MarginInfoItemType.KEY_LONG_SWAP:
        case MarginInfoItemType.KEY_SHORT_SWAP:
        case MarginInfoItemType.KEY_ORDER_CARE:
            return MarginInfoGroupType.KEY_GROUP_FEES;
        default: // for custom available funds #106952
            return MarginInfoGroupType.KEY_GROUP_RISKS;
        }
    }

    static isColoredItem (type: MarginInfoItemType): boolean {
        switch (type) {
        case MarginInfoItemType.KEY_BALANCE:
        case MarginInfoItemType.KEY_AVAILABLE_FUNDS:
        case MarginInfoItemType.KEY_INCOMING_FUNDS:
        case MarginInfoItemType.KEY_MARGIN_AVAILABLE:
        case MarginInfoItemType.KEY_INIT_MARGIN:
        case MarginInfoItemType.KEY_MAINT_MARGIN:
        case MarginInfoItemType.KEY_WARN_MARGIN:
        case MarginInfoItemType.KEY_AFTER_TRADE_FUNDS:
        case MarginInfoItemType.KEY_IMPACT_ON_PORTFOLIO:
        case MarginInfoItemType.KEY_BLOCKED_FOR_STOCKS:
        case MarginInfoItemType.KEY_SPREAD_INIT_LOSS:
        case MarginInfoItemType.KEY_PL_PER_TICK:
            return true;
        default:
            return false;
        }
    };

    static isAccountFeatureType (type: MarginInfoItemType) {
        switch (type) {
        case MarginInfoItemType.KEY_BALANCE:
        case MarginInfoItemType.KEY_AVAILABLE_FUNDS:
        case MarginInfoItemType.KEY_INCOMING_FUNDS:
        case MarginInfoItemType.KEY_MARGIN_AVAILABLE:
        case MarginInfoItemType.KEY_BLOCKED_FOR_STOCKS:
        case MarginInfoItemType.KEY_IMPACT_ON_PORTFOLIO:
            return true;
        default:
            return false;
        }
    }
}
