// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { SlTpPriceType } from '../Enums/Constants';

export class SlTpHolder {
    StopLossPriceValue: number = NaN;
    StopLossLimitPriceValue: number | null = null; // #91413
    TakeProfitPriceValue = NaN;

    StopLossPriceType: SlTpPriceType = SlTpPriceType.Absolute;
    TakeProfitPriceType: SlTpPriceType = SlTpPriceType.Absolute;

    RealTrStopPrice: number | null = null; // #103539
    SLTPTriggerShortValue: any = null; // #109798

    isEmpty (): boolean {
        return this.isSlEmpty() && this.isTpEmpty();
    }

    isSlEmpty (): boolean {
        return isNaN(this.StopLossPriceValue);
    }

    isTpEmpty (): boolean {
        return isNaN(this.TakeProfitPriceValue);
    }

    isSllEmpty (): boolean {
        return this.StopLossLimitPriceValue === null || isNaN(this.StopLossLimitPriceValue);
    }

    isSlAbsolute (): boolean {
        return this.StopLossPriceType === SlTpPriceType.Absolute || this.StopLossPriceType === SlTpPriceType.ProtectiveAbsolute;
    }

    isTpAbsolute (): boolean {
        return this.TakeProfitPriceType === SlTpPriceType.Absolute || this.TakeProfitPriceType === SlTpPriceType.ProtectiveAbsolute;
    }

    isTrailingStop (): boolean {
        return this.StopLossPriceType === SlTpPriceType.TrOffset;
    }

    copyFrom (sltpHolder: SlTpHolder): void {
        this.StopLossPriceValue = sltpHolder.StopLossPriceValue;
        this.StopLossLimitPriceValue = sltpHolder.StopLossLimitPriceValue;
        this.TakeProfitPriceValue = sltpHolder.TakeProfitPriceValue;

        this.StopLossPriceType = sltpHolder.StopLossPriceType;
        this.TakeProfitPriceType = sltpHolder.TakeProfitPriceType;

        this.RealTrStopPrice = sltpHolder.RealTrStopPrice;
        this.SLTPTriggerShortValue = sltpHolder.SLTPTriggerShortValue;
    }
}
