// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TimeSpan } from '../../../../Utils/Time/TimeSpan';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { HistoricalDataRequest } from '../HistoricalDataRequest';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { Indicator } from '../Indicator';
import { IndicatorDataType, Period } from '../IndicatorEnums';

export class MULTIMOVAVG extends IndicatorScriptBase {
    public Strategy: any;
    public Movavg: number;
    public MULTIMovavg: number;
    public Timeframe: any;
    public PeriodValue: number;
    public DataT: any;

    private _ATR: iBuildInIndicator;
    private _currTrend: any;
    private _prevUP: any;
    private _prevDown: any;
    private _prevTrend: any;
    private _down: any;
    private _up: any;
    private _ATR1: iBuildInIndicator;
    private _currTrend1: any;
    private _prevUP1: any;
    private _prevDown1: any;
    private _prevTrend1: any;
    private _down1: any;
    private _up1: any;
    public timeFrameData: any;
    public historyLoaded: boolean;
    public isProcessing: boolean;
    public isSameTF: boolean;
    public AtrPeriod: number;

    constructor () {
        super();
        this.ProjectName = 'MULTIMOV AVG';
        this.Comments = 'MULTIMOVAVG';
        this.SetIndicatorLine('EMATrendMT', Color.Blue, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('EMATrendMulti', Color.Yellow, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.Strategy = MAMode.EMA;
        super.InputParameter(new InputParameterCombobox('Timeframe', 'Timeframe', 4,
            [
                { SMA: MAMode.SMA },
                { EMA: MAMode.EMA },
                { SMMA: MAMode.SMMA },
                { LWMA: MAMode.LWMA }
            ]));

        this.Movavg = 21;
        super.InputParameter(new InputParameterInteger('Movavg', 'Period movavg', 1, 1, 900));

        this.MULTIMovavg = 21;
        super.InputParameter(new InputParameterInteger('MULTIMovavg', 'MULTIPeriod movavg', 2, 1, 100));

        this.Timeframe = Period.Min;
        super.InputParameter(new InputParameterCombobox('Timeframe', 'Timeframe', 3,
            [
                { Second: Period.Second },
                { Minute: Period.Min },
                { Hour: Period.Hour },
                { Day: Period.Day },
                { Week: Period.Week },
                { Month: Period.Month },
                { Year: Period.Year }
            ]));

        this.PeriodValue = 15;
        super.InputParameter(new InputParameterInteger('PeriodValue', 'Value Timeframe', 4, 1, 99999));

        this.DataT = IndicatorDataType.Trade;
        super.InputParameter(new InputParameterCombobox('DataT', 'DataType Timeframe', 6,
            [
                { Ask: IndicatorDataType.Ask },
                { Bid: IndicatorDataType.Bid },
                { Trade: IndicatorDataType.Trade }
            ]));
        this.historyLoaded = false;
        this.isProcessing = false;
        this.isSameTF = false;
    }

    public override GetIndicatorShortName (): string {
        return 'MULTIMOVAVG(' + this.Movavg + ')';
    }

    public override getCustomName (): string {
        return 'MULTIMOVAVG (' + this.ProjectName + ')';
    };

    public Init (): void {
        this.SetIndexDrawBegin(0, this.AtrPeriod);
    }

    public async RefreshPromise (): Promise<any> {
        await super.RefreshPromise()
            .then(() => {
                const cd = this.CurrentData;
                let result: any = null;
                if (this.Timeframe * this.PeriodValue == cd.Period) {
                    this.isSameTF = true;
                } else {
                    result = this.GetHistoricalDataPromise(new HistoricalDataRequest({
                        Instrument: cd.Instrument,
                        Period: this.Timeframe * this.PeriodValue,
                        DataType: this.DataT,
                        fromUTC: new Date(cd.FirstBarOpenTime),
                        toUTC: new Date(DateTimeUtils.DateTimeUtcNow().getTime() + TimeSpan.FromDays(1).getTime())
                    }));
                }
                return result;
            })
            .then((LoadedHistory) => {
                this.timeFrameData = LoadedHistory;

                if (LoadedHistory.Count === 0) {
                    console.log('DataType: ' + this.DataT.toString() + ' does not available in the TF');
                    return;
                }

                if (this.Strategy == MAMode.SMA) {
                    this._ATR1 = this.Indicators.iMA(this.timeFrameData, this.MULTIMovavg, MAMode.SMA);
                }

                if (this.Strategy == MAMode.EMA) {
                    this._ATR1 = this.Indicators.iMA(this.timeFrameData, this.MULTIMovavg, MAMode.EMA);
                }

                if (this.Strategy == MAMode.SMMA) {
                    this._ATR1 = this.Indicators.iMA(this.timeFrameData, this.MULTIMovavg, MAMode.SMMA);
                }

                if (this.Strategy == MAMode.LWMA) {
                    this._ATR1 = this.Indicators.iMA(this.timeFrameData, this.Movavg, MAMode.LWMA);
                }

                this.AppendInternalIndicators(this._ATR1, this.timeFrameData);

                this.Process(this.CurrentData.Count - 1);

                this.historyLoaded = true;
            });
    }

    public Process (count: any = null): void {
        if (count == null) {
            this.setMTFLine(0);
        } else {
            for (let i = count; i >= 0; i--) {
                this.setMTFLine(i);
            }
        }
    }

    public setMTFLine (barIndex): void {
        const currentBarTime = this.CurrentData.Time(barIndex);

        const offset = this.timeFrameData.FindInterval(currentBarTime);

        if (offset >= 0) {
            this._down1 = this._ATR1.GetValue();

            this._up1 = this._ATR1.GetValue();

            if (this.timeFrameData.GetPrice(PriceType.Close, offset) > this._prevUP1) { this._currTrend1 = 1; } else if (this.timeFrameData.GetPrice(PriceType.Close, offset) < this._prevDown1) { this._currTrend1 = -1; } else { this._currTrend1 = this._prevTrend1; }

            if (this._currTrend1 > 0 && this._up1 < this._prevDown1 && this._currTrend1 <= this._prevTrend1) { this._up1 = this._prevDown1; }
            if (this._currTrend1 < 0 && this._down1 > this._prevUP1 && this._currTrend1 >= this._prevTrend1) { this._down1 = this._prevUP1; }

            if (this._currTrend1 === 1) { this.SetValue(1, barIndex, this._up1); } else if (this._currTrend1 === -1) { this.SetValue(1, barIndex, this._down1); }

            this._prevUP1 = this._down1;
            this._prevDown1 = this._up1;
            this._prevTrend1 = this._currTrend1;
        }
    }

    public override createInternalIndicators (): iBuildInIndicator[] {
        if (!this.isSameTF) {
            if (this.Strategy == MAMode.SMA) {
                this._ATR = this.Indicators.iMA(this.CurrentData, this.Movavg, MAMode.SMA);
            }

            if (this.Strategy == MAMode.EMA) {
                this._ATR = this.Indicators.iMA(this.CurrentData, this.Movavg, MAMode.EMA);
            }

            if (this.Strategy == MAMode.SMMA) {
                this._ATR = this.Indicators.iMA(this.CurrentData, this.Movavg, MAMode.SMMA);
            }

            if (this.Strategy == MAMode.LWMA) {
                this._ATR = this.Indicators.iMA(this.CurrentData, this.Movavg, MAMode.LWMA);
            }
        }

        return [this._ATR];
    }

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.AtrPeriod);
    }

    public NextBar (): void {
        super.NextBar();

        this._prevUP = this._down;
        this._prevDown = this._up;
        this._prevTrend = this._currTrend;
    }

    public OnQuote (): void {
        super.OnQuote();

        this._down = this._ATR.GetValue();

        this._up = this._ATR.GetValue();

        if (this.CurrentData.GetPrice(PriceType.Close) > this._prevUP) { this._currTrend = 1; } else if (this.CurrentData.GetPrice(PriceType.Close) < this._prevDown) { this._currTrend = -1; } else { this._currTrend = this._prevTrend; }

        if (this._currTrend > 0 && this._up < this._prevDown && this._currTrend <= this._prevTrend) { this._up = this._prevDown; }
        if (this._currTrend < 0 && this._down > this._prevUP && this._currTrend >= this._prevTrend) { this._down = this._prevUP; }

        if (this._currTrend === 1) {
            this.SetValue(0, this._up);
        } else {
            this.SetValue(0, this._down);
        }

        if (this.historyLoaded && !this.isSameTF) {
            this.Process();
        } else if (this.isSameTF) {
            if (this._currTrend === 1) { this.SetValue(1, this._up); } else { this.SetValue(1, this._down); }
        }
    }
}
