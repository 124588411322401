// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class BaseRowDataItem {
    source: any;

    constructor (source) {
        this.source = source;
    }

    public getValue (index: RowIndexNames): any {
        throw 'not implement';
    }

    public getColor (index: RowIndexNames): RowColorText {
        throw 'not implement';
    }
}

export enum RowIndexNames {
    NAME = 0,
    COLUMN_ONE = 1,
    COLUMN_TWO = 2,
    COLUMN_THREE = 3,
    COLUMN_FOUR = 4,
    COLUMN_FIVE = 5,
    DESCRIPTION = 8,
    IMG = 9
};

export enum RowColorText {
    GREEN = 'Green',
    RED = 'Red',
    BLUE = 'Blue',
    BLACK = 'Black',
    GRAY = '#A1A1A1',
    PURPLE = 'Purple'
};
