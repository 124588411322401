// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectInstrumentGroupMessage extends DirectMessageBaseClass {
    public LanguageAliases: any = null;
    public ExtendedFields: Record<string, string> = {};
    public MinimalLot: number | null;
    public MaximalLot: number | null;
    public InstrumentType: number | null = null;

    private superTypeId: number = 0;
    private typeId: number = 0;
    private typeName: string | null = null;

    constructor () {
        super(Message.CODE_INSTRUMENT_TYPE);
    }

    get SuperTypeId (): number { return this.superTypeId; }
    set SuperTypeId (val) { this.superTypeId = val; }

    get TypeId (): number { return this.typeId; }
    set TypeId (val) { this.typeId = val; }

    get TypeName (): any { return this.typeName; }
    set TypeName (val) { this.typeName = val; }
}
