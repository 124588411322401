// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { SubscribeNewsGroup } from '../Groups/GroupsImport';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type SubscribeType } from '../../../../Utils/Enums/Constants';

export class NewsSubscribeMessage extends PFixMessage {
    constructor () {
        super(Message.CODE_NEWS_SUBSCRIBE_MESSAGE);
    }

    public addRouteId (routeID: number): void {
        const group = new SubscribeNewsGroup();

        group.setRouteId(routeID);

        this.addGroup(group);
    }

    public setSubscriptionAction (type: SubscribeType): void {
        this.setFieldValue(FieldsFactory.FIELD_SUBSCRIPTION_ACTION, type);
    }
}
