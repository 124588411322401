// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type DirectReportMessage } from '../../../Utils/DirectMessages/DirectReportMessage';
import { ReportMessageSource } from '../../../Utils/Enums/Constants';
import { ReportMessageTooltip } from '../../../Utils/ReportMessageTooltip';
import { TooltipType } from '../../../Utils/ReportMessageTooltipTypeEnum';
import { DataCache } from '../../DataCache';
import { type IBrokerConnectionAdapterHost, type IDestroyable, NotificationType } from '../charting_library';

export class TvNotificationManager implements IDestroyable {
    private readonly host: IBrokerConnectionAdapterHost;

    constructor (host: IBrokerConnectionAdapterHost) {
        this.host = host;
        DataCache.OnReportMessage.Subscribe(this.onReportMessage, this);
    }

    private onReportMessage (reportMessage: DirectReportMessage): void {
        if (!DataCache.Loaded) { return; }
        if (isNullOrUndefined(reportMessage)) { return; }
        if (reportMessage.SkipOnTicketViewer) { return; }
        if (reportMessage.source === ReportMessageSource.OpenOrderMessage) { return; }

        const reportTooltip = new ReportMessageTooltip(reportMessage);

        const header: string = reportTooltip.GetHeader();
        const text: string = reportTooltip.GetText();
        if (reportTooltip.TooltipDrawType === TooltipType.Refuse) {
            this.host.showNotification(header, text, NotificationType.Error);
        }
    }

    public destroy (): void {
        DataCache.OnReportMessage.UnSubscribe(this.onReportMessage, this);
    }
}
