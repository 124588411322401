// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { OrderTif, OrderTifMap } from '../../Utils/Trading/OrderTifEnum';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { Resources } from '../../Localizations/Resources';

export class TerceraLineStyleComboBoxUtils {
    public static getLocalizedTIF (tif: OrderTif, date): string {
        try {
            const tifLocName = Resources.getResource(OrderTifMap[tif]);

            return tif === OrderTif.GTD && date
                ? tifLocName + ' ' + DateTimeUtils.FormatToDate(date)
                : tifLocName;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        };
    };

    public static cretaComboItem (tif, date): any {
        const res: any = {};
        res.date = date;
        res.tif = {};
        res.tif.value = tif;
        res.tif.text = TerceraLineStyleComboBoxUtils.getLocalizedTIF(tif, date);
        return res;
    }
}
