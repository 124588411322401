// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectCustodialPlanMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { CustodialData } from '../../../../Utils/Instruments/CustodialData';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class CustodialPlanMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectCustodialPlanMessage[] {
        const message = new DirectCustodialPlanMessage();
        message.Id = fs.GetValue(FieldsFactory.FIELD_CUSTODIAL_PLAN_ID);
        message.Name = fs.GetValue(FieldsFactory.FIELD_NAME);

        const groups = fs.GetGroups(FieldsFactory.CUSTODY_FEE_GROUP);

        for (let i = 0; i < groups.length; i++) {
            const custodial = groups[i];
            const data = new CustodialData();

            const InstrumentId = custodial.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
            if (InstrumentId !== null) {
                data.InstrumentId = InstrumentId;
            }

            const InstrumentGroupId = custodial.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);
            if (InstrumentGroupId !== null) {
                data.InstrumentGroupId = InstrumentGroupId;
            }

            data.Fee = custodial.GetValue(FieldsFactory.FIELD_CUSTODIAL_FEE);
            data.Measure = custodial.GetValue(FieldsFactory.FIELD_CUSTODIAL_MEASURE);
            message.Data.push(data);
        }

        return [message];
    }
}
