// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectExternalLinkMessage } from '../../../Utils/DirectMessages/DirectExternalLinkMessage';
import { DataCache } from '../../DataCache';
import { Resources } from '../../../Localizations/Resources';
import { BrandingToolLinkGenerator } from './Branding/BrandingToolLinkGenerator';
import { type BrandingToolParams } from './Branding/BrandingToolParams';
import { BaseExtLink } from './BaseExtLink';
import { ResourcesManager } from '../../properties/ResourcesManager';

export class TradingCentralExtLink extends BaseExtLink {
    public tradingCentralTAAddress: string = '';
    public tradingCentralAlertAddress: string = '';
    public tradingCentralCandlestickAddress: string = '';
    public tradingCentralPartner: string = '';
    public tradingCentralToken: string = '';
    public tcrpPartnerID: string = '';
    public tcrpEncryptionKey: string = '';
    public clientUserInternalBrowser: boolean = true;

    // Override method
    update (brandingTool?: BrandingToolParams, extLinkMessage?: DirectExternalLinkMessage): void {
        super.update(brandingTool, extLinkMessage);
        if (brandingTool) {
            this.tradingCentralTAAddress = brandingTool.technicalAnalysis ?? this.tradingCentralTAAddress;
            this.tradingCentralAlertAddress = brandingTool.alerts ?? this.tradingCentralAlertAddress;
            this.tradingCentralCandlestickAddress = brandingTool.candlestick ?? this.tradingCentralCandlestickAddress;
            this.tradingCentralPartner = brandingTool.partnerID ?? this.tradingCentralPartner;
            this.tradingCentralToken = brandingTool.token ?? this.tradingCentralToken;
            this.tcrpPartnerID = brandingTool.tcrpPartnerID ?? this.tcrpPartnerID;
            this.tcrpEncryptionKey = brandingTool.tcrpEncryptionKey ?? this.tcrpEncryptionKey;
            this.urlStr = brandingTool.tcrpUrl ?? this.urlStr;
        }
        if (extLinkMessage) {
            this.clientUserInternalBrowser = extLinkMessage.UseInternalBrowser ?? this.clientUserInternalBrowser;
        }
    }

    // Override method
    generateUrl (): void {
        const userID = DataCache.getUserID();
        if (userID) {
            const tcrpUrl = BrandingToolLinkGenerator.generateTCRPLink(
                this.urlStr, this.tcrpPartnerID, userID, ResourcesManager.extLinkLocaleName(), this.tcrpEncryptionKey
            );
            if (tcrpUrl) {
                this.url = tcrpUrl;
                if (this.updateValue) {
                    this.updateValue(tcrpUrl);
                }
            }
        }
    }
}
