// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DirectPriceLimitsMessage } from '../../../../Utils/DirectMessages/DirectPriceLimitsMessage';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { MessageProcessorBase } from './MessageProcessorBase';

export class PriceLimitsMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectPriceLimitsMessage[] {
        const msg = new DirectPriceLimitsMessage();

        for (let i = 0, len = fieldSet.Count(); i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            const fieldID = fixField.FieldId;

            if (fieldID === 0) {
                continue;
            }

            if (fieldID === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
                msg.TradableId = fixField.Value;
            } else if (fieldID === FieldsFactory.FIELD_PRICE_HI_LIMIT) {
                msg.HighLimit = fixField.Value;
            } else if (fieldID === FieldsFactory.FIELD_PRICE_LOW_LIMIT) {
                msg.LowLimit = fixField.Value;
            }
        }

        return [msg];
    }
}
