// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { type IHistoryMerger } from '../../../../Utils/History/IHistoryMerger';
import { type IHistoryMergerCreator } from '../../../../Utils/History/IHistoryMergerCreator';
import { Periods } from '../../../../Utils/History/TFInfo';
import { HistoryMergerBySeconds } from './HistoryMergerBySeconds';
import { HistoryMergerDay } from './HistoryMergerDay';
import { HistoryMergerExtraday } from './HistoryMergerExtraday';
import { HistoryMergerIntradayByDayStart } from './HistoryMergerIntradayByDayStart';
import { HistoryMergerIntrdayBySession } from './HistoryMergerIntrdayBySession';
import { HistoryMergerMonth } from './HistoryMergerMonth';
import { HistoryMergerWeek } from './HistoryMergerWeek';
import { HistoryMergerYear } from './HistoryMergerYear';

class _HistoryMergerCreator implements IHistoryMergerCreator {
    public GetHistoryMerger (inputParams: HistoryMergerInputParams): IHistoryMerger {
        const period = inputParams.NewPeriod;

        if (period % Periods.DAY === 0 || period % Periods.YEAR === 0) {
            return this.GetHistoryMergerByDay(inputParams);
        } else if (period % Periods.SECOND === 0) {
            return this.GetHistoryMergerBySecond(inputParams);
        } else {
            return this.GetHistoryMergerByMin(inputParams);
        }
    }

    private GetHistoryMergerByDay (inputParams: HistoryMergerInputParams): IHistoryMerger {
        const period = inputParams.NewPeriod;

        if (period % Periods.YEAR === 0) {
            return new HistoryMergerYear(inputParams);
        } else if (period % Periods.MONTH === 0) {
            return new HistoryMergerMonth(inputParams);
        } else if (period % Periods.WEEK === 0) {
            return new HistoryMergerWeek(inputParams);
        } else {
            return new HistoryMergerDay(inputParams);
        }
    }

    private GetHistoryMergerByMin (inputParams: HistoryMergerInputParams): IHistoryMerger {
        const instrument = inputParams.Instrument;
        const period = inputParams.NewPeriod;
        if (period > Periods.DAY) {
            return new HistoryMergerExtraday(inputParams);
        }

        const aggregateIntradayBySession: boolean = instrument?.AllowFilterBarsBySession(period);
        if (aggregateIntradayBySession) {
            return new HistoryMergerIntrdayBySession(inputParams);
        }

        return new HistoryMergerIntradayByDayStart(inputParams);
    }

    private GetHistoryMergerBySecond (inputParams: HistoryMergerInputParams): IHistoryMerger {
        return new HistoryMergerBySeconds(inputParams);
    }
}

export const HistoryMergerCreator = new _HistoryMergerCreator();
