// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { BaseGannDataCacheTool } from './BaseGannDataCacheTool';
import { DynProperty } from '../../DynProperty';

export class GannFanDataCacheTool extends BaseGannDataCacheTool {
    /**
     * Constructor for GannFanDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.GannFan;
        this.InitPoints(1);
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 1;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 1;
    }

    /**
     * Gets the properties of the tool
     * @returns {DynProperty[]} - Array of DynProperty
     */
    Properties (): DynProperty[] {
        const props = super.Properties();

        props.push(new DynProperty('chart.tools.mirrored', this.mirrored, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP));
        props.push(new DynProperty('chart.tools.fibretr.fontcolor', this.FontColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP));

        return props;
    }

    /**
     * Handles the callback for the tool properties
     * @param {DynProperty[]} properties - Array of DynProperty
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        let prop = DynProperty.getPropertyByName(properties, 'chart.tools.mirrored');
        if (prop != null) this.mirrored = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'chart.tools.fibretr.fontcolor');
        if (prop != null) this.FontColor = prop.value;
    }
}
