// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { StatementItem } from '../cache/StatementItem';
import { ColouringModes } from '../elements/QuickTable/QuickTableColumn';
import { ExportScreen } from '../screen/ExportScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { type RangeSelectPanel } from './RangeSelectPanel';
import { ReportType } from '@shared/commons/cache/AllowedReport';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { StatementPanelTemplate } from '../../templates';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';
import { WDSettings } from '../settings/WDGeneralSettings';
import { ReportManager } from '@shared/utils/Managers/ReportManager';
import { EReportRequestParameter, ReportRequestConfig } from '@shared/commons/UtilsClasses/Report/ReportRequestConfig';

export class StatementPanel extends ApplicationPanelWithTable<StatementItem> {
    Controls: any;
    rangeSelectPanel: RangeSelectPanel;
    selectedStartTime: number | null = null;
    selectedFinishTime: number | null = null;
    displayAmountInLots: boolean;

    constructor () {
        super();

        this.Name = 'StatementPanel';
        this.headerLocaleKey = 'panel.statement';
    }

    getType (): PanelNames { return PanelNames.StatementPanel; }

    oncomplete (): void {
        super.oncomplete();

        this.addRangeSelectPanel();

        const noDataLabel = this.Controls.noDataLabel;
        noDataLabel.set({
            text: Resources.getResource('panel.statement.noData')
        });

        this.populateTableContextMenu();

        this.displayAmountInLots = WDSettingsUtils.displayAmountInLots();
        WDSettings.settingsChanged.Subscribe(this.settingsChanged, this);

        super.getQuickTable()?.setGroupByColumnIdx(StatementItem.POSITION_ID_COL_INDEX);
    }

    addRangeSelectPanel (): void {
        const topPanel = this.Controls.statementPanelRangeSelect;

        topPanel.set({
            refreshBtnTT: 'panel.statement.searchBtn.tt'
        });
        topPanel.getReportEvent.Subscribe(this.getStatementReport, this);
        topPanel.on('refreshClick', this.refreshStatementReport.bind(this));
        topPanel.updateSelectedRange();
        this.rangeSelectPanel = topPanel;
    }

    dispose (): void {
        this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getStatementReport, this);

        WDSettings.settingsChanged.UnSubscribe(this.settingsChanged, this);

        super.dispose();
    }

    refreshStatementReport (): void {
        this.getStatementReport(this.selectedStartTime, this.selectedFinishTime);
    }

    populateTableContextMenu (): void {
        const items = [];

        if (!Resources.isHidden('screen.export.visibility')) {
            items.push(
                {
                    text: Resources.getResource('screen.export.contextMenu'),
                    event: ExportScreen.show.bind(null, this)
                }
            );
        }

        if (!Resources.isHidden('panel.statement.print')) {
            items.push(
                {
                    text: Resources.getResource('screen.reports.print'),
                    event: super.printTable.bind(this)
                }
            );
        }

        if (!Resources.isHidden('contextMenu.Totals.visibility')) {
            items.push(
                {
                    text: Resources.getResource('panel.positions.menu.View'),
                    tag: 'View',
                    enabled: true,
                    subitems: [
                        {
                            text: Resources.getResource('panel.positions.menu.ShowTotals'),
                            tag: 'ShowTotals',
                            checked: false,
                            enabled: true,
                            canCheck: true,
                            event: super.ShowTotalsStateChange.bind(this)
                        }
                    ]
                }
            );
        }

        items.push({ separator: true });
        super.getQuickTable()?.setTableContextMenuItems(items);
    }

    getStatementReport (startTime: number, finishTime: number): void {
        this.selectedStartTime = startTime;
        this.selectedFinishTime = finishTime;

        void this.set('loading', true);

        ReportManager.reportsByComplited.set(ReportType.REPORT_STATEMENT, false);
        void ReportManager.sendRequestByDate(ReportType.REPORT_STATEMENT, startTime, finishTime).then((statements) => {
            const qt = super.getQuickTable();
            if (qt == null) return;

            qt.ClearRows();
            const len = statements.length;
            for (let i = 0; i < len; i++) { qt.AddItem(new StatementItem(statements[i], SessionSettings)); }

            void this.set({ isDataExist: len > 0 });

            if (LinkedSystem.accLinkingActive) { qt.filterByAccountNumber(LinkedSystem.getAccount()); }
        }).finally(() => {
            void this.set('loading', false);
        });
    }

    settingsChanged (): void {
        const newSettingsInLots = WDSettingsUtils.displayAmountInLots();
        if (this.displayAmountInLots === newSettingsInLots) { return; }

        this.displayAmountInLots = newSettingsInLots;
        this.refreshStatementReport();
    }

    SetColumnsColouringMode (table: QuickTable): void {
        super.ProcessSetColumnsColouringMode(table, [5], ColouringModes.Signed);
    }
}

ApplicationPanelWithTable.extendWith(StatementPanel, {
    partials: { bodyPartial: StatementPanelTemplate },

    data: function () {
        return {
            isAccountLinkShow: true,
            isSymbolLinkShow: false,
            isDataExist: true
        };
    }
});
