// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixField } from './PFixField';

export class PFixPublicKeyField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = null;
    }

    public override ReadValue (buffer): void {
        const certificateSize = buffer.ReadShort();
        const certificateBytes = buffer.ReadBuffer(certificateSize).ReadAllBytes();
        this.Value = certificateBytes;
    }

    public override Write (buffer): void {
        buffer.WriteShort(this.FieldId);

        if (this.Value == null) {
            return;
        }

        buffer.WriteShort(this.Value.length);
    }
}
