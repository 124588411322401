// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { type QuoteData, type QuoteOkData, type QuotesCallback } from '../charting_library';
import { DataCache } from '../../DataCache';
import { type Instrument } from '../../cache/Instrument';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { type DirectQuoteMessage } from '../../../Utils/DirectMessages/DirectQuoteMessage';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { type Account } from '../../cache/Account';
import { TvQuoteUtils } from '../../UtilsClasses/TvQuoteUtils';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';

export class TvQuoteSubscriptionItem {
    private realtimeCallback: QuotesCallback;
    private subscribedSymbols: string[] = [];

    public static getQuoteDataArray (symbols: string[], account: Account): QuoteData[] {
        const data: QuoteData[] = [];
        for (const symbol of symbols) {
            const interiorId = TvInteriorIdCache.getInteriorId(symbol);
            const instrument = DataCache.getInstrumentByName(interiorId);
            if (!instrument) {
                continue;
            }
            const quoteData = this.getQuoteData(instrument, account, symbol);
            data.push(quoteData);
        }

        return data;
    }

    private static getQuoteData (instrument: Instrument, account: Account, symbol: string): QuoteOkData {
        const askPrice = instrument.Level1.GetAsk(account);
        const bidPrice = instrument.Level1.GetBid(account);
        const spreadPrice = askPrice - bidPrice;

        const change = instrument.Level1.GetChange(account);
        const changePercent = instrument.Level1.GetChangePercent(account);
        const highPrice = instrument.InstrumentDayInfo.getHigh(account);
        const lowPrice = instrument.InstrumentDayInfo.getLow(account);
        const lastPrice = instrument.InstrumentDayInfo.getClose(account);
        const openPrice = instrument.InstrumentDayInfo.getOpen(account);
        const prevClosePrice = instrument.InstrumentDayInfo.getPrevClose(account);
        const volume = instrument.InstrumentDayInfo.TotalVolume();
        const symbolName = TvSymbolConvertor.getSymbolName(instrument);

        const quoteValues =
        {
            ask: TvQuoteUtils.replaceNaN(askPrice),
            bid: TvQuoteUtils.replaceNaN(bidPrice),
            ch: TvQuoteUtils.replaceNaN(change),
            chp: TvQuoteUtils.replaceNaN(changePercent),
            description: instrument.DescriptionValue(),
            exchange: instrument.TradingExchange, // ?
            high_price: TvQuoteUtils.replaceNaN(highPrice),
            low_price: TvQuoteUtils.replaceNaN(lowPrice),
            lp: TvQuoteUtils.replaceNaN(lastPrice),
            open_price: TvQuoteUtils.replaceNaN(openPrice),
            original_name: symbolName,
            prev_close_price: TvQuoteUtils.replaceNaN(prevClosePrice),
            short_name: symbolName,
            spread: TvQuoteUtils.replaceNaN(spreadPrice),
            volume: TvQuoteUtils.replaceNaN(volume)
        };

        return {
            n: symbol,
            s: 'ok',
            v: quoteValues
        };
    }

    public subscribe (symbols: string[], fastSymbols: string[], onRealtimeCallback: QuotesCallback): void {
        this.realtimeCallback = onRealtimeCallback;
        const prevSubscribedSymbols = this.subscribedSymbols;
        this.subscribedSymbols = symbols;

        const removedSymbols = prevSubscribedSymbols.filter(element => !this.subscribedSymbols.includes(element));
        this.unsubscribeSymbols(removedSymbols);

        const addedSymbols = this.subscribedSymbols.filter(element => !prevSubscribedSymbols.includes(element));
        for (const symbol of addedSymbols) {
            const interiorId = TvInteriorIdCache.getInteriorId(symbol);
            const instrument = DataCache.getInstrumentByName(interiorId);
            if (!instrument) {
                continue;
            }
            DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
            DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_TRADES);
        }
    }

    public unsubscribe (): void {
        this.unsubscribeSymbols(this.subscribedSymbols);
    }

    public unsubscribeSymbols (symbols: string[]): void {
        for (const symbol of symbols) {
            const interiorId = TvInteriorIdCache.getInteriorId(symbol);
            const instrument = DataCache.getInstrumentByName(interiorId);
            if (!instrument) {
                continue;
            }
            DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
            DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_TRADES);
        }
    }

    public newQuote (msg: DirectQuoteMessage): void {
        this.sendRealtimeData();
    }

    public sendRealtimeData (): void {
        if (!this.realtimeCallback) return;

        const account = TvAccountHelper.getCurrentAccount();
        const data: QuoteData[] = TvQuoteSubscriptionItem.getQuoteDataArray(this.subscribedSymbols, account);
        this.realtimeCallback(data);
    }
}
