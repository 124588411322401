// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DynProperty } from '@shared/commons/DynProperty';
import { Rectangle } from '@shared/commons/Geometry';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type TerceraChartMouseEventArgs } from '../Utils/TerceraChartMouseEventArgs';

export enum LayersEnum {
    Main = 0,
    Quotes = 1,
    CrossHair = 2,
    Tools = 3
}

export abstract class TerceraChartBaseRenderer<Chart extends TerceraChartBase = TerceraChartBase> {
    public plateRenderer: boolean;
    public priceScaleRenderer: boolean;

    public skipInLayoutWindows: boolean = false;

    protected _Visible: boolean = true;
    public Rectangle: Rectangle = Rectangle.Empty();

    public get Visible (): boolean {
        return this._Visible;
    }

    public set Visible (value: boolean) {
        this._Visible = value;
    }

    protected _UseInAutoscale: boolean = false;
    public get UseInAutoscale (): boolean {
        return this._UseInAutoscale;
    }

    public set UseInAutoscale (value: boolean) {
        this._UseInAutoscale = value;
    }

    assignLayer: LayersEnum = LayersEnum.Main;
    ClassNameStorage: Record<string, boolean> = {};
    chart: Chart;

    constructor (chart: Chart) {
        this.chart = chart;
        this.SetClassName('TerceraChartBaseRenderer');
    }

    SetClassName (className: string): void {
        this.ClassNameStorage = {};
        this.ClassNameStorage[className] = true;
    }

    FindMinMax (out_minMax: { tMin: number, tMax: number }, window: any): boolean {
        out_minMax.tMin = -Number.MAX_VALUE;
        out_minMax.tMax = Number.MAX_VALUE;
        return false;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any): void {

    }

    IsNeedDraw (numberOfLayer: LayersEnum): boolean {
        return this.assignLayer === numberOfLayer;
    }

    GetAssignLayer (): LayersEnum {
        return this.assignLayer;
    }

    IsNeedForceUpdate (): boolean {
        return false;
    }

    ProcessMouseMove (e: any): boolean {
        return false;
    }

    ProcessMouseDown (e: any): boolean {
        return false;
    }

    ProcessMouseEnter (e: any): void {

    }

    ProcessMouseUp (e: any): boolean {
        return false;
    }

    ProcessMouseLeave (e: any): void {

    }

    ProcessMouseDoubleClick (e: any): boolean {
        return false;
    }

    ProcessMouseClick (e: any): boolean {
        return false;
    }

    ProcessMouseWheel (e: any): boolean {
        return false;
    }

    OnKeyDown (e: KeyboardEvent): void {

    }

    OnKeyUp (e: KeyboardEvent): void {

    }

    Dispose (): void {
        this.chart = null;
    }

    Properties (): any[] {
        return [];
    }

    callBack (properties: DynProperty[]): void {

    }

    Localize (): void {

    }

    ThemeChanged (): void {

    }

    GetContextMenu (e: MouseEvent, chart: any): any {
        return null;
    }

    GetCursor (e: TerceraChartMouseEventArgs): any {
        return null;
    }

    GetTooltip (e: TerceraChartMouseEventArgs): string | null {
        return null;
    }
}
