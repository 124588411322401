// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { type Point, Rectangle } from '@shared/commons/Geometry';
import { Pen, SolidBrush } from '@shared/commons/Graphics';
import { MouseButtons } from '@front/controls/UtilsClasses/ControlsUtils';
import { Scroll, VisualControlState } from '@front/controls/elements/Scroll';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { DynProperty } from '@shared/commons/DynProperty';
import { TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { type TerceraChartBase } from '../TerceraChartBase';
import { TerceraChartMainPriceRenderer } from './TerceraChartMainPriceRenderer';

enum TerceraChartScrollerRendererMouseState {
  None,
  Moving
}

export class TerceraChartScrollerRenderer<Chart extends TerceraChartBase = TerceraChartBase> extends TerceraChartBaseRenderer<Chart> {
    static SCROLLER_HEIGHT = 20;
    static constScrollArrowSize = 12;

    insideRect: Rectangle;
    scrollRect: Rectangle;
    leftArrowRect: Rectangle;
    rightArrowRect: Rectangle;

    MouseState: TerceraChartScrollerRendererMouseState = TerceraChartScrollerRendererMouseState.None;
    lastMouseDownState: Point;
    lastMouseDownDeltaX: number;

    ScrollBorderPen: Pen = new Pen(ThemeManager.CurrentTheme.Chart_ScrollerBorderColor, 1);

    Enabled: boolean = true;
    backBrushChartScroll: SolidBrush;

    private scrollControlsStates: { leftBtn: any, rightBtn: any, slider: any };
    backBrush: SolidBrush;

    constructor (chart: Chart) {
        super(chart);
        this.scrollControlsStates = {
            leftBtn: VisualControlState.None,
            rightBtn: VisualControlState.None,
            slider: VisualControlState.None
        };
        this.Rectangle = new Rectangle();
        this.insideRect = new Rectangle();
        this.scrollRect = new Rectangle();
        this.leftArrowRect = new Rectangle();
        this.rightArrowRect = new Rectangle();
        this.setBackBrushChartScroll(ThemeManager.CurrentTheme.scrollBackgroundColor); // old value scrollBackgroundColorChart changed due to #116857

        this.SetClassName('TerceraChartScrollerRenderer');
    }

    ScaleKoef (): number {
        const seriesCount = this.chart.BarsCount();
        if (seriesCount === 0 || this.chart.mainWindow.im() > seriesCount + this.chart.BarsToRigth_Bars) {
            return 1;
        } else {
            return this.insideRect.Width / (seriesCount + this.chart.BarsToRigth_Bars);
        }
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        if (!this.Visible) { return; }

        const R = this.Rectangle;
        const RX = R.X;
        const RY = R.Y;
        const RW = R.Width;
        const RH = R.Height;

        gr.FillRect(this.backBrushChartScroll, RX, RY, RW, RH);

        this.leftArrowRect = new Rectangle(0, 0, 1, 10);
        this.rightArrowRect = new Rectangle(0, 0, 1, 10);

        Scroll.drawButtons(
            gr,
            this.leftArrowRect, this.scrollControlsStates.leftBtn,
            this.rightArrowRect, this.scrollControlsStates.rightBtn,
            true
        );

        this.insideRect = new Rectangle(RX, RY, RW, RH);
        this.insideRect.X += this.leftArrowRect.Width;
        this.insideRect.Width -= (this.leftArrowRect.Width + this.rightArrowRect.Width);
        this.insideRect.Y += 3;
        this.insideRect.Height -= 6;

        const outSubj = { rightX: 0, leftX: 0 };
        if (!this.CalcScrollPosition(window, outSubj)) { return; }

        let rightX = outSubj.rightX;
        let leftX = outSubj.leftX;

        while (rightX - leftX < 8) {
            rightX++;
            leftX--;
        }

        this.scrollRect = new Rectangle(leftX, this.insideRect.Y, rightX - leftX, this.insideRect.Height);
        Scroll.drawHorizontalSlider(gr, this.scrollRect, this.scrollControlsStates.slider);
    }

    CalcScrollPosition (window: any, outSubj: { rightX: number, leftX: number }): boolean {
        if (this.chart.BarsCount() === 0) {
            outSubj.rightX = outSubj.leftX = 0;
            return false;
        }

        const scaleKoef = this.ScaleKoef();
        const insideRect = this.insideRect;

        if (scaleKoef == 1) {
            const insideRectX = insideRect.X;
            outSubj.leftX = insideRectX;
            outSubj.rightX = insideRectX + insideRect.Width;
        } else {
            outSubj.rightX = insideRect.X + Math.round((window.i1 + 1) * scaleKoef) + 1;
            outSubj.leftX = insideRect.X + Math.round((window.i1 + 0 - window.im()) * scaleKoef);

            if (outSubj.leftX < insideRect.X) { outSubj.leftX = insideRect.X; }
        }

        return true;
    }

    ThemeChanged (): void {
        super.ThemeChanged();

        this.ScrollBorderPen.Color = ThemeManager?.CurrentTheme?.Chart_ScrollerBorderColor;
        this.backBrush = new SolidBrush(ThemeManager?.CurrentTheme?.scrollBackgroundColor);
    }

    clearScrollVisualState (): void {
        const scrollControlsStates = this.scrollControlsStates;
        const keys = Object.keys(scrollControlsStates);
        for (let i = 0; i < keys.length; i++) { scrollControlsStates[keys[i]] = VisualControlState.None; }
    }

    setScrollVisualState (controlName: string, state: any): void {
        this.clearScrollVisualState();
        if (controlName) { this.scrollControlsStates[controlName] = state; }
    }

    Properties (): any[] {
        const properties = super.Properties();
        const SeparatorGroup = '#3#' + Resources.getResource('property.SeparatorGroup.Additional');
        const prop = new DynProperty('ScrollBar', this.Visible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 2;
        properties.push(prop);
        return properties;
    }

    callBack (properties: any): void {
        super.callBack(properties);
        const dp = DynProperty.getPropertyByName(properties, 'ScrollBar');
        if (dp) this.Visible = dp.value;
    }

    setBackBrushChartScroll (color: any): void {
        this.backBrushChartScroll = new SolidBrush(color);
    }

    calci1 (x: number): number {
        return (x - this.insideRect.X) / this.ScaleKoef();
    }

    calcShiftByArrow (leftArrowClick: boolean): number {
        let delta = this.chart.mainWindow.im() / 30.0;
        if (delta < 1) delta = 1.0;
        return Math.round(this.chart.mainWindow.i1 + (leftArrowClick ? -delta : delta));
    }

    calcShiftByWheel (delta: number): number {
        const seriesCount = this.chart.BarsCount();
        if (seriesCount === 0) {
            return this.chart.mainWindow.i1;
        }

        const CountBars = seriesCount + this.chart.BarsToRigth_Bars;
        const temp = ((CountBars - this.chart.mainWindow.im()) / 30);
        let step = (temp != 0 ? temp : 1) * delta / 120;
        if (step == 0) { step = 1; }

        return this.chart.mainWindow.i1 + step;
    }

    ProcessMouseMove (e: any): boolean {
        if (!this.Enabled) { return false; }

        const result = false;

        if (this.MouseState === TerceraChartScrollerRendererMouseState.Moving) {
            const newI1 = this.calci1(e.X - this.lastMouseDownDeltaX);
            if (this.chart.moveScreenByX(newI1)) { e.NeedRedraw = true; }
        }

        this.clearScrollVisualState();

        if (this.leftArrowRect.Contains(e.X, e.Y)) { this.setScrollVisualState('leftBtn', VisualControlState.Hovered); }
        if (this.rightArrowRect.Contains(e.X, e.Y)) { this.setScrollVisualState('rightBtn', VisualControlState.Hovered); }
        if (this.scrollRect.Contains(e.X, e.Y)) { this.setScrollVisualState('slider', VisualControlState.Hovered); }

        return result;
    }

    ProcessMouseDown (e: any): boolean {
        if (!this.Enabled || e.Button != MouseButtons.Left) { return false; }

        let res = this.Rectangle.Contains(e.X, e.Y);
        const scrollRect = this.scrollRect;
        const tumbClick = scrollRect.Contains(e.X, e.Y);

        if (tumbClick) {
            this.MouseState = TerceraChartScrollerRendererMouseState.Moving;
            this.setScrollVisualState('slider', VisualControlState.Clicked);
        } else {
            this.MouseState = TerceraChartScrollerRendererMouseState.None;
        }

        const scrollRectX = scrollRect.X;
        this.lastMouseDownDeltaX = e.Location.X - scrollRectX - scrollRect.Width;
        this.lastMouseDownState = e.Location;

        const leftArrowClick = this.leftArrowRect.Contains(e.Location.X, e.Location.Y);
        const rightArrowClick = this.rightArrowRect.Contains(e.Location.X, e.Location.Y);

        if (leftArrowClick) { this.setScrollVisualState('leftBtn', VisualControlState.Clicked); } else if (rightArrowClick) { this.setScrollVisualState('rightBtn', VisualControlState.Clicked); }

        if (leftArrowClick || rightArrowClick) {
            const newI1 = this.calcShiftByArrow(leftArrowClick);
            this.chart.moveScreenByX(newI1);
            this.MouseState = TerceraChartScrollerRendererMouseState.None;
            res = true;
        }

        if (this.insideRect.Contains(e.Location.X, e.Location.Y) && !tumbClick) {
            const newI1 = this.calci1(e.X);
            if (this.chart.moveScreenByX(newI1)) { e.NeedRedraw = true; }
            this.MouseState = TerceraChartScrollerRendererMouseState.None;
            res = true;
        }

        return res;
    }

    ProcessMouseUp (e: any): boolean {
        super.ProcessMouseUp(e);
        this.MouseState = TerceraChartScrollerRendererMouseState.None;
        this.clearScrollVisualState();
        return false;
    }

    ProcessMouseLeave (e: any): void {
        super.ProcessMouseLeave(e);
        this.clearScrollVisualState();
    }

    ProcessMouseWheel (e: any): boolean {
        if (Math.abs(e._baseEvent.deltaX) > Math.abs(e._baseEvent.deltaY)) {
            const xDelta = e._baseEvent.deltaX;
            if (xDelta !== 0) {
                const coefSensitivity = 0.7;
                const coefBarW = xDelta / this.chart.mainWindow.XScale * coefSensitivity;
                if (this.chart.moveScreenByX(this.chart.rightBarIndexIncludingEmptyBars + coefBarW, true)) {
                    if (coefBarW < 0) { // scroll towards the left border
                        this.chart.afterZoomOutEvents();
                    }
                    e.NeedRedraw = true;
                }
            }
            return true;
        }

        if (this.Enabled) {
            const newI1 = this.calcShiftByWheel(e.Delta);
            this.chart.moveScreenByX(newI1);

            return true;
        }

        return false;
    }

    public TurnOffRenderer (): void {
        this.Visible = false;
        this.skipInLayoutWindows = true;
        this.Enabled = false;
    }
}
