// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { Resources } from '@shared/localizations/Resources';
import { ControlEvents } from '../Control';
import { TerceraDateSelectorDropDown } from './TerceraDateSelectorDropDown';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { TerceraLookup } from './TerceraLookup';

export class TerceraDateSelectorLookup extends TerceraLookup {
    constructor () {
        super();
    }

    public override getType (): string { return 'TerceraDateSelectorLookup'; }

    public override oncomplete (): void {
        super.oncomplete();
        this.observe('expirationTime', (newValue) => {
            this.repopulate(newValue);
        });
        this.textBox.on(ControlEvents.GotFocus, () => { this.showForm(); });
    }

    public override showForm (): void {
        const dropDownForm = new TerceraDateSelectorDropDown();
        dropDownForm.setCorrectLocation(this.getX(), this.getY());
        MainWindowManager.MainWindow.addControl(dropDownForm);

        dropDownForm.on('timeSelectorLookupClosed', () => {
            this.fire('timeSelectorLookupClosed');
        });

        dropDownForm.on('timeSelectorApplyClicked', (context, newDate) => {
            this.onTimeSelectorApplyClicked(newDate);
        });
        dropDownForm.observe('date', (newD) => {
            if (!isNullOrUndefined(newD)) {
                void this.set('expirationTime', newD);
            }
        }, { init: false });

        const today = new Date();
        today.setSeconds(0, 0);
        void dropDownForm.set({
            date: this.get('expirationTime') ?? today,
            isOnlyDate: this.get('isOnlyDate'),
            showHeader: this.get('showDatePickerHeader'),
            header: this.get('datePickerHeader'),
            buttons: this.get('datePickerButtons')
        });
        dropDownForm.on('complete', () => {
            this.setMinMax(dropDownForm);
        });
        this.fire('timeSelectorLookupOpened');
    }

    public setMinMax (dropDownForm: TerceraDateSelectorDropDown): void {
        let maxDate: Date = null;
        if (this.get('isTodayMaxMode')) {
            maxDate = new Date();
        }

        let minDate: Date = this.get('minDate');
        if (isNullOrUndefined(minDate) && this.get('isTodayMinMode')) {
            minDate = new Date();
        }

        dropDownForm?.setMinMax(minDate, maxDate);
    }

    public onTimeSelectorApplyClicked (newDate: Date): void {
        this.fire('changedDate', newDate);
    }

    public override getButtonTooltip (): string {
        return Resources.getResource(this.get('btnTooltip'));
    }

    public repopulate (newDate: Date): void {
        if (!isNullOrUndefined(newDate)) {
            this.setItems({ text: DateTimeUtils.formatDate(newDate, 'DD.MM.YYYY HH:mm') });
        } else {
            this.setItems({ text: 'No Data' });
        }

        this.setSelectValue(this.items[0]);
    }

    public override getContextMenuItem () {
        return {
            text: this.get('datePickerHeader'),
            enabled: this.get('enabled'),
            canCheck: false,
            checked: false,
            tag: null,
            style: '',
            event: () => { this.showForm(); }
        };
    }
}

TerceraLookup.extendWith(TerceraDateSelectorLookup, {
    data: function () {
        return {
            btnClass: 'js-lookup-datetime',
            btnName: 'dateTimeLookup',
            name: 'TerceraDateSelectorLookup',
            expirationTime: null,
            isReadOnly: true,
            canSelect: false,
            btnTooltip: 'screen.Alerts.Label.Expiration.Tooltip',
            isTodayMinMode: false,
            isTodayMaxMode: false,
            showDisabledToolTip: true,
            showDatePickerHeader: false,
            datePickerHeader: '',
            datePickerButtons: [],
            minDate: null
        };
    },
    selectedDate: null
});
