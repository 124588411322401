// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class VariableTick {
    public LeftBorder: number;
    public RightBorder: number;
    public IncludeLeftBorder: boolean;
    public PointSize: number;
    public TickCost: number;

    constructor (dataObj) {
        this.LeftBorder = dataObj.lowLimit ?? 0;
        this.RightBorder = dataObj.highLimit ?? 0;
        this.IncludeLeftBorder = dataObj.allowLimit;
        this.PointSize = dataObj.pointSize;
        this.TickCost = dataObj.tickCost;
    }

    /// <summary>
    /// проверка на вхождение
    /// </summary>
    public CheckPrice (price): boolean {
        if (isNaN(price)) { // если цена NaN - не находит никакой интервал
            price = -1;
        }; // пока преобразовываем в -1 для проверки, чтоб было по старой схеме. TODO: подумать над решением лучше

        if (this.LeftBorder === Number.POSITIVE_INFINITY && this.RightBorder === Number.POSITIVE_INFINITY) {
            return true;
        }

        if (this.LeftBorder === Number.NEGATIVE_INFINITY) {
            return price < this.RightBorder;
        } else if (this.RightBorder === Number.POSITIVE_INFINITY) {
            return price >= this.LeftBorder;
        } else {
            return price >= this.LeftBorder && price < this.RightBorder;
        }
    }

    public toString (): string {
        return this.LeftBorder + ((this.LeftBorder === Number.NEGATIVE_INFINITY) ? '<= ' : '< ') + 'price' +
        ((this.RightBorder === Number.POSITIVE_INFINITY) ? '<= ' : '< ') + this.RightBorder;
    }
}
