// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ControlAlertCommandMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_CONTROL_ALERT_COMMAND_MESSAGE, fieldSet);
    }

    public getAlertId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_ID);
    }

    public setAlertId (alertId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_ID, alertId);
    }

    public getAlertCommand (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_COMMAND);
    }

    public setAlertCommand (alertCommand: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_COMMAND, alertCommand);
    }
}
