// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// I think it would be good to move the constants here from DynProperty

export class DynPropertyTypes {
    public static readonly INTEGER = 0; // тип - целое (int)
    public static readonly DOUBLE = 1; // тип - вещественное (double)
    public static readonly COMBOBOX = 27; // тип - набор объектов в комбобоксе?
    public static readonly COMBOBOX_COMBOITEM = 185; // комбобокс работающий с Utils.ComboItem
    public static readonly COMBOBOX_COMBOITEM_TIF = 1001;//
}
