// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectReportGroupResponse, ReportColumn, ReportTable } from '../../../../Utils/DirectMessages/DirectReportGroupResponse';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ReportGroupResponseMessage extends PFixMessage {
    public RelatedMessages = [];

    constructor (fieldSet: PFixFieldSet) {
        super(Message.CODE_REPORT_GROUP_RESPONSE, fieldSet);
    }

    public getDirectReportGroupResponse (): DirectReportGroupResponse {
        const msgArr = this.RelatedMessages;
        const directMessage = new DirectReportGroupResponse();
        let reportName = null;
        const tables: ReportTable[] = [];
        const table = new ReportTable();

        for (let i = 0; i < msgArr.length; i++) {
            const fieldSet = msgArr[i].FieldSet;

            const reportGroup = fieldSet.GetGroup(FieldsFactory.REPORT_GROUP);
            if (reportGroup) {
                reportName = reportGroup.GetValue(FieldsFactory.FIELD_NAME); // reportName may be new table (e.g.Summary report)

                if (reportName) {
                    table.ReportName = reportName;
                    table.ReportColumns = [];
                    table.ReportRows = [];

                    tables.push(table);
                }
                // cols
                const headerGroup = reportGroup.GetGroup(FieldsFactory.REPORT_HEADER_GROUP);
                if (headerGroup) {
                    const columnGroups = headerGroup.GetGroups(FieldsFactory.REPORT_COLUMN_GROUP);
                    if (columnGroups) {
                        for (let i = 0; i < columnGroups.length; i++) {
                            const columnGroup = columnGroups[i];
                            const type = columnGroup.GetValue(FieldsFactory.FIELD_TYPE);
                            const name = columnGroup.GetValue(FieldsFactory.FIELD_NAME);

                            const reportColumns = new ReportColumn();
                            reportColumns.Type = type;
                            reportColumns.Name = name;
                            table.ReportColumns.push(reportColumns);
                        }
                    }
                }

                // rows
                const lineGroups = reportGroup.GetGroups(FieldsFactory.REPORT_LINE_GROUP);
                for (let i = 0; i < lineGroups.length; i++) {
                    const lineGroup = lineGroups[i];
                    const content = lineGroup.GetValue(FieldsFactory.FIELD_CONTENT);

                    table.ReportRows.push(content);
                }
            }
        }

        directMessage.Tables = tables;

        return directMessage;
    }
}
