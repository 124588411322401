// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoricalBrokerMessageInfo } from '../HistoricalBrokerMessageInfo';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectBMHistoryResponseMessage extends DirectMessageBaseClass {
    public HistoricalBMs: HistoricalBrokerMessageInfo[] | null = null;

    constructor () {
        super(Message.BM_HISTORY_RESPONSE);
    }
}
