// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OptionVolatilityLabChart } from '../../../Chart/OptionVolatilityLabChart';
import { BaseChartRactive } from './BaseChartRactive';

export class OptionVolatilityLabChartRactive extends BaseChartRactive<OptionVolatilityLabChart> {
    public override getType (): string { return 'VolatilityLab'; }

    public override createChart (): OptionVolatilityLabChart {
        return new OptionVolatilityLabChart(this.context, super.get('terceraChartPanelContext'));
    }
}
