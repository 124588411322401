// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class InstrumentNamesGroup extends PFixFieldSet {
    constructor (pfixFieldSet?: PFixFieldSet) {
        super(FieldsFactory.INSTRUMENT_NAMES_GROUP, null, pfixFieldSet);
    }

    public getName (): string {
        return this.GetValue(FieldsFactory.FIELD_NAME);
    }

    public setName (name: string): void {
        this.SetField(FieldsFactory.FIELD_NAME, name);
    }
}
