// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class Interval {
    public From: Date;
    public To: Date;

    constructor (from?: Date, to?: Date) {
        this.From = from;
        this.To = to;
    }

    public static GetInvalidInterval (): Interval {
        const invalidInterval = new Interval();
        invalidInterval.From = new Date(0);
        invalidInterval.To = new Date(0);
        return invalidInterval;
    }
}
