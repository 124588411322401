// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { HtmlScroll } from '../../Commons/HtmlScroll';
import { TerceraCheckBoxGroupTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';
import { type ParamType } from '../../Commons/cache/AllowedReportConstants';

export class TerceraCheckBoxGroup extends ContainerControl {
    public DataArrayChanged: CustomEvent;
    public paramType: ParamType;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.DataArrayChanged = new CustomEvent();
        this.observe('dataArray', this.onDataArrayChanged);
    }

    public override oncomplete (): void {
        super.oncomplete();
        HtmlScroll.addScroll(this.find('ul'));
    }

    public override dispose (): void {
        HtmlScroll.removeScroll(this.find('ul'));
        super.dispose();
    }

    public onDataArrayChanged (newVal): void {
        this.DataArrayChanged.Raise(this, newVal);
    }
}

ContainerControl.extendWith(TerceraCheckBoxGroup, {
    data: function () {
        return {
            width: 198,
            height: 50,
            dataArray: null
        };
    },
    template: TerceraCheckBoxGroupTemplate
});
