// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../../Localizations/Resources';
import { RangeSelectPanelTemplate } from '../../templates.js';
import { TerceraPanel } from '../elements/TerceraPanel';
import { DateTimeRangeOption, DateTimeUtils } from '../../Utils/Time/DateTimeUtils';

export class RangeSelectPanel extends TerceraPanel {
    public static readonly rangeKeysArray = [DateTimeRangeOption.DAILY, DateTimeRangeOption.H24, DateTimeRangeOption.D7, DateTimeRangeOption.D30, DateTimeRangeOption.D90, DateTimeRangeOption.RANGE];

    public getReportEvent: CustomEvent;

    constructor () { super(); }

    public override getType (): string { return 'RangeSelectPanel'; }

    public override oninit (): void {
        super.oninit();

        this.getReportEvent = new CustomEvent();
    }

    public override oncomplete (): void {
        super.oncomplete();

        const comboBox = this.Controls.rangeComboBox;

        comboBox.observe('selectedItem', function (newValue) { this.selectRange(newValue); }.bind(this));

        const keys = RangeSelectPanel.rangeKeysArray;
        const items = [];
        keys.forEach((key) => {
            if (!Resources.isHidden(key)) {
                items.push({ text: Resources.getResource(key), value: key });
            }
        });
        comboBox.set({ items });

        this.on('applyBtnClick', () => {
            this.selectRange({ value: DateTimeRangeOption.RANGE });
        });
    }

    public selectRange (selectedItem): void {
        if (isNullOrUndefined(selectedItem)) { return; }

        void this.set('dateRangeLookupVisibility', selectedItem.value === DateTimeRangeOption.RANGE);
        const dateFrom: Date = this.get('dateFrom') ?? new Date();
        const dateTo: Date = this.get('dateTo') ?? new Date();
        const rangeTimes = DateTimeUtils.getRangeByOption(selectedItem.value, dateFrom, dateTo);

        this.getReportEvent.Raise(rangeTimes.startTime, rangeTimes.finishTime);
    }

    public updateSelectedRange (): void {
        this.selectRange(this.Controls.rangeComboBox.get('selectedItem'));
    }

    public isDaily (): boolean {
        let key = DateTimeRangeOption.DAILY;
        let item = null;
        if (!isNullOrUndefined(this.Controls.rangeComboBox)) {
            item = this.Controls.rangeComboBox.get('selectedItem');
        }
        if (!isNullOrUndefined(item)) {
            key = item.value;
        }

        return key === DateTimeRangeOption.DAILY;
    }
}

TerceraPanel.extendWith(RangeSelectPanel, {
    template: RangeSelectPanelTemplate,
    data: function () {
        return {
            top: 5,
            left: 5,
            visible: true,
            cbTooltip: 'panel.rangeSelect.tooltip',
            dateRangeLookupVisibility: false,
            refreshBtnVisibility: false,
            refreshBtnTT: null,
            dateFrom: null,
            dateTo: null
        };
    }
});
