// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { OptionPositionItem } from '../../cache/OptionMaster/OptionPositionItem';
import { type OptionTrader } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { type Position } from '../../../Commons/cache/Position';
import { SessionSettings } from '../../../Commons/SessionSettings';
import { PositionsPanel } from '../PositionsPanel';
import { type PositionItem } from '../../cache/PositionItem';
import { DataCache } from '../../../Commons/DataCache';

export class OptionPositionsPanel extends PositionsPanel {
    private _optionTrader: OptionTrader;

    constructor () {
        super();
        this.isFiltered = true;
        this.Name = 'OptionPositionsPanel';
        this.headerLocaleKey = 'panel.optionPositions';
        this.AddPositionEvent = this.AddPositionEvent.bind(this);
        this.RemovePositionEvent = this.RemovePositionEvent.bind(this);
        this.populateItemsDirect = this.populateItemsDirect.bind(this);
        this.onAccountChanged = this.onAccountChanged.bind(this);
    }

    public override oninit (): void {
        super.oninit();
        void super.set('isRelativeStyle', true);
        void super.set('isAllowPadding', false);
        super.observe('visible', this.onVisibleChanged);
    }

    public override getType (): PanelNames { return PanelNames.OptionPositionsPanel; }

    public override populateItemsDirect (): void {
        const quickTable = this.getQuickTable();
        quickTable.ClearAll();
    }

    public override TickAsync (): void {
        super.TickAsync();
        const isVisible: boolean = this.get<boolean>('visible');
        if (!isVisible) {
            return;
        }
        this.getQuickTable().needRedrawBackground = true;
    }

    public override createPositionItem (position: Position): PositionItem {
        return new OptionPositionItem(this._optionTrader, position, SessionSettings);
    }

    public override DCEventsSubscribe (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader.subscribeOnChangeAccountForPaperPositions(this.onAccountChanged);
        this._optionTrader.subscribeOnRepopulate(this.populateItemsDirect);
        this._optionTrader.subscribeOnAddPosition(this.AddPositionEvent);
        this._optionTrader.subscribeOnRemovePosition(this.RemovePositionEvent);
        DataCache.OnUpdatePosition.Subscribe(this.UpdatePositionEvent, this);
    }

    public override DCEventsUnSubscribe (): void {
        if (isNullOrUndefined(this._optionTrader)) {
            return;
        }
        this._optionTrader.unsubscribeOnChangeAccountForPaperPositions(this.onAccountChanged);
        this._optionTrader.unsubscribeOnRepopulate(this.populateItemsDirect);
        this._optionTrader.unsubscribeOnAddPosition(this.AddPositionEvent);
        this._optionTrader.unsubscribeOnRemovePosition(this.RemovePositionEvent);
        DataCache.OnUpdatePosition.UnSubscribe(this.UpdatePositionEvent, this);
    }

    public setOptionTrader (optionTrader: OptionTrader): void {
        this._optionTrader = optionTrader;
        this.DCEventsSubscribe();
    }

    private onVisibleChanged (): void {
        this.layoutTable();
    }

    private onAccountChanged (): void {
        const qt = this.getQuickTable();
        const acctNumber = this._optionTrader?.account?.AcctNumber;
        if (qt != null && acctNumber != null) {
            qt.filterByAccountNumber(acctNumber);
        }
    }
}
