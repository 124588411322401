// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Point } from '@shared/commons/Geometry';
import { type Font } from '@shared/commons/Graphics';
import { type Account } from '@shared/commons/cache/Account';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type LayersEnum } from './LayersEnum';
import { type TerceraChartPriceScaleLayoutInfo } from './TerceraChartPriceScaleLayoutInfo';
import { type TerceraChartBase } from '../TerceraChartBase';

export class TerceraChartAdvancedParams<Chart extends TerceraChartBase = TerceraChartBase> {
    Font: Font;
    CursorPosition: Point;
    HoverWindowXValue: number;
    HoverWindowYValue: number;
    TerceraChart: Chart;
    DrawPointers: any[] = [];
    LayoutInfo: TerceraChartPriceScaleLayoutInfo;
    layers: any;
    Tag: any;
    layerId: LayersEnum;
    exitAfterFirst: boolean | null;
    isNeedConnectFirstAndLastPoints: boolean;

    Instrument: Instrument;
    Account: Account;
    mainPriceRenderer: any;
}
