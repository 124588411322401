// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ApplicationPanel } from '../ApplicationPanel';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { OptionMasterPanelTemplate } from '../../../templates';
import { OptionTrader } from '@shared/commons/cache/OptionMaster/OptionTrader/OptionTrader';
import { type OptionChainPanel } from './OptionChainPanel';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type Account } from '@shared/commons/cache/Account';
import { type OptionPositionsPanel } from './OptionPositionsPanel';
import { type OptionOrdersPanel } from './OptionOrdersPanel';
import { type OptionPaperPositionsPanel } from './OptionPaperPositionsPanel';
import { DynProperty } from '@shared/commons/DynProperty';
import { Resources } from '@shared/localizations/Resources';
import { IVCalculationPrice } from '@shared/commons/cache/OptionMaster/OptionTrader/IVCalculationPrice';
import { PanelSettingsScreen } from '../../screen/PanelSettingsScreen';
import { type OptionAnalyzerPanel } from './OptionAnalyzerPanel';
import { type OptionVolatilityLabPanel } from './OptionVolatilityLabPanel';
import { type OptionPortfolioControl } from './OptionPortfolioControl';
import { type PanelsContainerControl } from '../../elements/PanelsContainerControl';
import { ActivationDirection } from '../../elements/ResizeSplitter';
import { IsAllowedPanel } from '../../WorkSpace/WorkSpaceUtils';
import { Level1ItemType } from '@shared/utils/Enums/Level1ItemType';
import { type TerceraLevel1Panel } from '../../elements/TerceraLevel1Panel';
import { OptionSerializer } from '@shared/utils/Instruments/OptionSerializer';
import { DataCache } from '@shared/commons/DataCache';
import { OptionType } from '@shared/utils/Instruments/OptionType';

export class OptionMasterPanel extends ApplicationPanel {
    private _tickCounter: number = 0;
    private _isInitialized: boolean = false;
    private _optionPortfolioControl: OptionPortfolioControl;
    private _optionChainPanel: OptionChainPanel;
    private _optionAnalyzerPanel: OptionAnalyzerPanel;
    private _optionVolatilityLabPanel: OptionVolatilityLabPanel;
    private _optionPositionsPanel: OptionPositionsPanel;
    private _optionOrdersPanel: OptionOrdersPanel;
    private _optionPaperPositionsPanel: OptionPaperPositionsPanel;
    private _optionTrader: OptionTrader;
    private _delayedProperties: DynProperty[];

    constructor () {
        super();
        this.Name = 'OptionMasterPanel';
        this.headerLocaleKey = 'panel.optionMaster';
    }

    getType (): PanelNames { return PanelNames.OptionMasterPanel; }

    oninit (): void {
        super.oninit();
        this._optionTrader = new OptionTrader();
        super.observe('instrument', this.onInstrumentChanged);
        super.observe('account', this.onAccountChanged);
        super.on('onInfoButtonClick', this.onInfoButtonClicked);
        super.on('onSettingsButtonClick', this.onSettingsButtonClicked);
        super.on('onSplitterResize', this.onSplitterResized);
        super.on('onSplitterActivate', this.onSplitterActivate);
        super.on('onSplitterDeactivate', this.onSplitterDeactivate);
        super.on('onActivePanelChange', this.onActivePanelChanged);
        super.on('onCollapsedChange', this.onCollapsedChanged);
        super.on('onVisibleTopPanelsCountChange', this.onVisibleTopPanelsCountChanged);
        super.on('onVisibleBottomPanelsCountChange', this.onVisibleBottomPanelsCountChanged);
    }

    onteardown (): void {
        super.onteardown();
    }

    oncomplete (): void {
        super.oncomplete();
        this.initializeVariables();
        if (isValidArray(this._delayedProperties)) {
            this.callBack(this._delayedProperties);
            this._delayedProperties = undefined;
        } else {
            void this.setDefaultInstrumentAsync();
        }
        this.layoutTable();
        this.themeChange();
        this.localize();
        this.repopulate();
    }

    public override dispose (): void {
        this._optionTrader.dispose();
        this._optionAnalyzerPanel.dispose();
        this._optionVolatilityLabPanel.dispose();
        this._optionPositionsPanel.dispose();
        this._optionOrdersPanel.dispose();
        this._optionPaperPositionsPanel.dispose();
        super.dispose();
    }

    localize (): void {
        super.localize();
        if (!this._isInitialized) {
            return;
        }
        const containerControls = this.findAllComponents<PanelsContainerControl>('panelsContainerControl');
        if (isValidArray(containerControls)) {
            for (let i = 0; i < containerControls.length; i++) {
                containerControls[i].localize();
            }
        }
        this._optionChainPanel.localize();
        this._optionAnalyzerPanel.localize();
        this._optionVolatilityLabPanel.localize();
        this._optionPositionsPanel.localize();
        this._optionOrdersPanel.localize();
        this._optionPaperPositionsPanel.localize();
    }

    themeChange (): void {
        // super.themeChange();
        if (!this._isInitialized) {
            return;
        }
        this._optionChainPanel.themeChange();
        this._optionAnalyzerPanel.themeChange();
        this._optionVolatilityLabPanel.themeChange();
        this._optionPositionsPanel.themeChange();
        this._optionOrdersPanel.themeChange();
        this._optionPaperPositionsPanel.themeChange();
    }

    layoutTable (): void {
        if (!this._isInitialized) {
            return;
        }
        this._optionChainPanel.layoutTable();
        this._optionAnalyzerPanel.layoutTable();
        this._optionVolatilityLabPanel.layoutTable();
        this._optionPositionsPanel.layoutTable();
        this._optionOrdersPanel.layoutTable();
        this._optionPaperPositionsPanel.layoutTable();
    }

    public override setSize (w: number, h: number): void {
        super.setSize(w, h);

        this.layoutTable();
    }

    TickAsync (): void {
        if (!this._isInitialized) {
            return;
        }

        if (!this.get<boolean>('visible')) {
            return;
        }

        this._tickCounter++;
        if (this._tickCounter % 12 === 0) { // 60fps / 5 = 12
            this._optionChainPanel.updateData();
            this._optionPaperPositionsPanel.updateData();
            this._optionAnalyzerPanel.updateChart();
            this._optionVolatilityLabPanel.updateChart();
            this._tickCounter = 0;
        }
        this._optionChainPanel.TickAsync();
        this._optionPositionsPanel.TickAsync();
        this._optionOrdersPanel.TickAsync();
        this._optionPaperPositionsPanel.TickAsync();
        this._optionAnalyzerPanel.TickAsync();
        this._optionVolatilityLabPanel.TickAsync();
    }

    public override populate (): void {
        this.repopulate();
    }

    public override repopulate (): void {
        if (!this._isInitialized) {
            return;
        }
        this._optionTrader.account = super.get('account');
        this._optionTrader.fakeOption = super.get('instrument');
        this._optionChainPanel.repopulate();
        this.repopulatePanels([this._optionAnalyzerPanel, this._optionVolatilityLabPanel, this._optionPositionsPanel, this._optionOrdersPanel, this._optionPaperPositionsPanel]);
        void super.set('underlier', this._optionTrader.fakeOption?.ForwardBaseInstrument);
    }

    updateSettings (): void {
        super.updateSettings();
        if (!this._isInitialized) {
            return;
        }
        this._optionChainPanel.updateSettings();
        this._optionAnalyzerPanel.updateSettings();
        this._optionVolatilityLabPanel.updateSettings();
        this._optionPositionsPanel.updateSettings();
        this._optionOrdersPanel.updateSettings();
        this._optionPaperPositionsPanel.updateSettings();
    }

    public Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();

        let index: number = 0;
        const separatorGroup = '#0#' + Resources.getResource('property.Calculation');
        let dp = new DynProperty('property.InterestRate', this._optionTrader.interestRate * 100, DynProperty.DOUBLE, DynProperty.GENERAL_GROUP);
        dp.sortIndex = index++;
        dp.minimalValue = 0;
        dp.maximalValue = 999999999;
        dp.increment = 0.01;
        dp.decimalPlaces = 2;
        dp.separatorGroup = separatorGroup;
        properties.push(dp);

        dp = new DynProperty('property.IVCalculationPrice', this._optionTrader.ivCalculationPrice, DynProperty.COMBOBOX, DynProperty.GENERAL_GROUP);
        dp.sortIndex = index++;
        dp.separatorGroup = separatorGroup;
        dp.objectVariants = [
            { text: Resources.getResource('property.Ask'), value: IVCalculationPrice.Ask },
            { text: Resources.getResource('property.Bid'), value: IVCalculationPrice.Bid },
            { text: Resources.getResource('property.Last'), value: IVCalculationPrice.Last }
        ];
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(dp);

        const instrument: Instrument = this.get('instrument');
        if (!isNullOrUndefined(instrument)) {
            const optionSerializeData = OptionSerializer.createOptionSerializeData(instrument);
            properties.push(new DynProperty('symbol', OptionSerializer.serialize(optionSerializeData), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        properties.push(...this._optionChainPanel.Properties());
        if (IsAllowedPanel(this._optionAnalyzerPanel)) {
            properties.push(...this._optionAnalyzerPanel.Properties());
        }
        if (IsAllowedPanel(this._optionVolatilityLabPanel)) {
            properties.push(...this._optionVolatilityLabPanel.Properties());
        }

        const level1Panel = this.findComponent<TerceraLevel1Panel>('terceraLevel1Panel');
        properties.push(...level1Panel.properties());

        return properties;
    }

    public callBack (properties: DynProperty[]): void {
        super.callBack(properties);
        if (!this._isInitialized) {
            this._delayedProperties = properties;
            return;
        }
        const group = DynProperty.GENERAL_GROUP;

        let dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.InterestRate');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.interestRate = dp.value / 100;
        }
        dp = DynProperty.getPropertyByGroupAndName(properties, group, 'property.IVCalculationPrice');
        if (!isNullOrUndefined(dp?.value)) {
            this._optionTrader.ivCalculationPrice = dp.value;
        }

        dp = DynProperty.getPropertyByGroupAndName(properties, DynProperty.HIDDEN_GROUP, 'symbol');
        if (!isNullOrUndefined(dp?.value)) {
            const instrument = this.get<Instrument>('instrument');
            const propertiesSerializedData = OptionSerializer.deserialize(dp.value);
            const currentInstrumentSerializedData = OptionSerializer.createOptionSerializeData(instrument);
            if (!propertiesSerializedData.equals(currentInstrumentSerializedData)) {
                void this._optionTrader.deserializeOptionAsync(propertiesSerializedData.optionId, propertiesSerializedData.optionRoute, propertiesSerializedData.contractTradableId).then(ins => {
                    if (ins != null) {
                        void super.set('instrument', ins);
                    } else {
                        void this.setDefaultInstrumentAsync();
                    }
                });
            } else {
                void this.setDefaultInstrumentAsync();
            }
        } else {
            void this.setDefaultInstrumentAsync();
        }

        this._optionChainPanel.callBack(properties);
        this._optionAnalyzerPanel.callBack(properties);
        this._optionVolatilityLabPanel.callBack(properties);

        const level1Panel = this.findComponent<TerceraLevel1Panel>('terceraLevel1Panel');
        level1Panel.callBack(properties);
    }

    private onInstrumentChanged (instrument: Instrument, prevInstrument: Instrument): void {
        if (!this._isInitialized) {
            return;
        }
        if (instrument === prevInstrument) {
            return;
        }
        this._optionTrader.fakeOption = instrument;
        void super.set('underlier', instrument?.ForwardBaseInstrument);
    }

    private onAccountChanged (account: Account): void {
        if (!this._isInitialized) {
            return;
        }
        this.accountLink_Out(false, account);
        this._optionTrader.account = account;
    }

    private async onInfoButtonClicked (): Promise<void> {
        const isShowPortfolio: boolean = super.get('isShowPortfolio');
        await super.set('isShowPortfolio', !isShowPortfolio);
        this.layoutTable();
    }

    private onSettingsButtonClicked (): void {
        PanelSettingsScreen.EditProperties(this, null, Resources.getResource('panel.optionMaster.settingsHeader'));
    }

    private onSplitterResized (): void {
        this.layoutTable();
    }

    private onSplitterActivate (): void {
        void super.set('isCollapsedBottom', false);
    }

    private onSplitterDeactivate (): void {
        void super.set('isCollapsedBottom', true);
    }

    private onActivePanelChanged (): void {
        this.layoutTable();
    }

    private async onCollapsedChanged (context: any, isCollapsed: boolean): Promise<void> {
        void super.set('isCollapsedBottom', isCollapsed);
    }

    private onVisibleTopPanelsCountChanged (context: any, visiblePanelsCount: number): void {
        void super.set('isVisibleTopTabs', visiblePanelsCount > 1);
    }

    private onVisibleBottomPanelsCountChanged (context: any, visiblePanelsCount: number): void {
        void super.set('isVisibleBottom', visiblePanelsCount > 0);
    }

    // #region Heper methods
    private initializeVariables (): void {
        this._optionPortfolioControl = super.findComponent('optionPortfolioControl');
        this._optionChainPanel = super.findComponent('optionChainPanel');
        this._optionAnalyzerPanel = super.findComponent('optionAnalyzerPanel');
        this._optionVolatilityLabPanel = super.findComponent('optionVolatilityLabPanel');
        this._optionPositionsPanel = super.findComponent('optionPositionsPanel');
        this._optionOrdersPanel = super.findComponent('optionOrdersPanel');
        this._optionPaperPositionsPanel = super.findComponent('optionPaperPositionsPanel');
        this._optionPortfolioControl.setOptionTrader(this._optionTrader);
        this._optionChainPanel.setOptionTrader(this._optionTrader);
        this._optionAnalyzerPanel.setOptionTrader(this._optionTrader);
        this._optionVolatilityLabPanel.setOptionTrader(this._optionTrader);
        this._optionPositionsPanel.setOptionTrader(this._optionTrader);
        this._optionOrdersPanel.setOptionTrader(this._optionTrader);
        this._optionPaperPositionsPanel.setOptionTrader(this._optionTrader);
        this._isInitialized = true;
    }

    private repopulatePanels (panels: ApplicationPanel[]): void {
        for (let i = 0; i < panels.length; i++) {
            const panel = panels[i];
            const forbiddenPanel = !IsAllowedPanel(panel);
            void panel.set('forbiddenPanel', forbiddenPanel);
            if (!forbiddenPanel) {
                panel.repopulate();
            }
        }
    }

    private async setDefaultInstrumentAsync (): Promise<void> {
        const defaultOption = DataCache.getDefaultInstrument(true);
        if (!isNullOrUndefined(defaultOption) && defaultOption.optionType === OptionType.Fake) {
            const option = await this._optionTrader.getOptionAsync(defaultOption);
            void super.set('instrument', option);
        } else {
            void super.set('instrument', defaultOption);
        }
    }
    // #endregion
}

ApplicationPanel.extendWith(OptionMasterPanel,
    {
        data: function () {
            return {
                isBeta: true,
                showFullscreenModeButton: true,
                isShowToolbar: true,
                isShowPortfolio: false,
                instrument: null,
                account: null,
                underlier: null,
                level1Types: [
                    Level1ItemType.Last,
                    Level1ItemType.Ask,
                    Level1ItemType.Bid,
                    Level1ItemType.Change,
                    Level1ItemType.Volume,
                    Level1ItemType.Open,
                    Level1ItemType.High,
                    Level1ItemType.Low,
                    Level1ItemType.PrevClose
                ],
                isVisibleTopTabs: false,
                isVisibleBottom: false,
                isCollapsedBottom: false,
                splitterActivationDirection: ActivationDirection.BOTTOM
            };
        },
        partials: {
            bodyPartial: OptionMasterPanelTemplate
        }
    }
);
