// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { DPSLTPControlTemplate } from '../../templates.js';
import { type OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { SlTpUtils } from '../../Utils/Trading/SlTpUtils';
import { DynPropertyControl } from '../elements/DynPropertyControl';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';

export class DPSLTPControl extends DynPropertyControl {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): string { return ControlsTypes.DPSLTPControl; }

    public override oninit (): void {
        super.oninit();

        this.observe(
            'dp.sl.inOffset dp.sl.offsetType dp.tp.inOffset dp.tp.offsetType dp.trailingStop.enabled',
            this.localize);
    }

    public override oncomplete (): void {
        super.oncomplete();
        void this.set('SLNumericLink', this.Controls.SLNumeric);
        void this.set('TPNumericLink', this.Controls.TPNumeric);
    }

    public override localize (): void {
        super.localize();

        const isSlInOffset = this.get<boolean>('dp.sl.inOffset');
        const slOffsetType = this.get<OffsetModeViewEnum>('dp.sl.offsetType');
        const isTrailingStop = this.get<boolean>('dp.trailingStop.enabled');
        const trStopOffsetType = this.get<OffsetModeViewEnum>('dp.trailingStop.offsetType');
        const isTpInOffset = this.get<boolean>('dp.tp.inOffset');
        const tpOffsetType = this.get<OffsetModeViewEnum>('dp.tp.offsetType');

        const slLocKey = isSlInOffset || isTrailingStop
            ? 'panel.newOrderEntry.stopLossCheckBox.offset'
            : 'panel.newOrderEntry.stopLossCheckBox';

        const sllLocKey = isSlInOffset
            ? 'panel.newOrderEntry.stopLimitLimitOffset'
            : 'panel.newOrderEntry.stopLimitLimitPrice';

        const tpLocKey = isTpInOffset
            ? 'panel.newOrderEntry.takeProfitCheckBox.offset'
            : 'panel.newOrderEntry.takeProfitCheckBox';

        const slLocKeyOffset = isSlInOffset || isTrailingStop
            ? 'panel.newOrderEntry.stopLossCheckBox'
            : 'panel.newOrderEntry.stopLossCheckBox.offset';

        const tpLocKeyOffset = isTpInOffset
            ? 'panel.newOrderEntry.takeProfitCheckBox'
            : 'panel.newOrderEntry.takeProfitCheckBox.offset';

        const slTooltipKey = isSlInOffset || isTrailingStop
            ? 'panel.newOrderEntry.stopLossNumeric.ToolTip.offset'
            : 'panel.newOrderEntry.stopLossNumeric.ToolTip';

        const tpTooltipKey = isTpInOffset
            ? 'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset'
            : 'panel.newOrderEntry.takeProfitNumeric.ToolTip';

        const slNumericRightAlignedText = isSlInOffset ? SlTpUtils.getSymbolForOffsetMode(isTrailingStop ? trStopOffsetType : slOffsetType) : '';
        const tpNumericRightAlignedText = isTpInOffset ? SlTpUtils.getSymbolForOffsetMode(tpOffsetType) : '';

        void this.set({
            slLabel: Resources.getResource(slLocKey),
            slNumericRightAlignedText,
            sllLabel: Resources.getResource(sllLocKey),
            sllLabelTooltip: Resources.getResource(sllLocKey + '.tt'),
            tpLabel: Resources.getResource(tpLocKey),
            tpNumericRightAlignedText,
            slLabelOffset: Resources.getResource(slLocKeyOffset),
            tpLabelOffset: Resources.getResource(tpLocKeyOffset),
            slTooltip: Resources.getResource(slTooltipKey),
            tpTooltip: Resources.getResource(tpTooltipKey)
        });
    }
}

DynPropertyControl.extendWith(DPSLTPControl, {
    template: DPSLTPControlTemplate,
    data: function () {
        return {
            slLabel: '',
            slNumericRightAlignedText: '',
            tpLabel: '',
            tpNumericRightAlignedText: '',
            slLabelOffset: '',
            sllLabel: '', // #91413
            sllLabelTooltip: '', // #91413
            tpLabelOffset: '',
            slTooltip: '',
            tpTooltip: '',
            SLNumericLink: null,
            TPNumericLink: null,
            side: null,
            additionalClassName: '',
            SLTPParams:
            {
                width: 200,
                left: 100,
                margin: 6
            }
        };
    },
    computed: {
        slValue: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.value'
                        : 'dp.sl.value');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.value'
                        : 'dp.sl.value',
                    value);
            }
        },

        slMin: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.minimalValue'
                        : 'dp.sl.minimalValue');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.minimalValue'
                        : 'dp.sl.minimalValue',
                    value);
            }
        },

        slMax: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.maximalValue'
                        : 'dp.sl.maximalValue');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.maximalValue'
                        : 'dp.sl.maximalValue',
                    value);
            }
        },

        slStep: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.increment'
                        : 'dp.sl.increment');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.increment'
                        : 'dp.sl.increment',
                    value);
            }
        },

        slPrecision: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.decimalPlaces'
                        : 'dp.sl.decimalPlaces');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.decimalPlaces'
                        : 'dp.sl.decimalPlaces',
                    value);
            }
        },

        slCustomError: {
            get: function () {
                return this.get(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.error'
                        : 'dp.sl.error');
            },
            set: function (value) {
                void this.set(
                    this.get<boolean>('dp.trailingStop.enabled')
                        ? 'dp.trailingStop.error'
                        : 'dp.sl.error',
                    value);
            }
        },

        sllMin: function () {
            return this.get<boolean>('dp.sl.inOffset') ? -this.get('dp.sl.maximalValue') : this.get('dp.sl.minimalValue');
        },
        sllValue: { // #91413
            get: function () {
                let value = this.get('dp.sl.sllValue');
                if (isNaN(value) || value === null) {
                    value = this.get('dp.sl.value');
                    if (isNaN(value) || value === null) { value = 0; }
                }
                return value;
            },
            set: function (value) {
                if (this.get<boolean>('dp.sllValueEnabled')) {
                    void this.set('dp.sl.sllValue', value);
                }
            }
        },
        sllMax: function () {
            return this.get('dp.sl.maximalValue');
        },
        sllPrecision: {
            get: function () {
                return this.get('dp.sl.decimalPlaces');
            },
            set: function (value) {
                if (this.get<boolean>('dp.trailingStop.enabled')) { return; }
                void this.set('dp.sl.decimalPlaces', value);
            }
        },
        sllStep: {
            get: function () {
                return this.get('dp.sl.increment');
            },
            set: function (value) {
                if (this.get<boolean>('dp.trailingStop.enabled')) { return; }
                void this.set('dp.sl.increment', value);
            }
        },

        sllVisible: function () {
            return !!(this.get<boolean>('dp.sl.enabled') && this.get<boolean>('dp.sllValueEnabled') && !this.get<boolean>('dp.trailingStop.enabled'));
        },

        sltpTriggerShortValue: { // #109798
            get: function () {
                return this.get('dp.sltpTriggerShort');
            },
            set: function (value) {
                void this.set('dp.sltpTriggerShort', value);
            }
        }
    }
});
