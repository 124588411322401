// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ColorStyleWidth } from '../Commons/DynProperty';
import { PenStyle } from '../Commons/Graphics';
import { type VolatilityLabChartData } from '../Commons/cache/OptionMaster/OptionTrader/OptionVolatilityLab/VolatilityLabChartData';
import { Resources } from '../Localizations/Resources';
import { ThemeManager } from '../Controls/misc/ThemeManager';
import { type TerceraChartNumberScaleRenderer } from './Renderers/Scales/TerceraChartNumberScaleRenderer';
import { type TerceraChartNumberVerticalScaleRenderer } from './Renderers/Scales/TerceraChartNumberVerticalScaleRenderer';
import { TerceraChartBaseCrossHairRenderer } from './Renderers/TerceraChartBaseCrossHairRenderer';
import { LineStyle } from './Renderers/TerceraChartStyledLineRenderer';
import { TerceraChartStyledVerticalLineRenderer } from './Renderers/TerceraChartStyledVerticalLineRenderer';
import { TerceraChartAggregatedXYSeries, TerceraChartXYSeries, XYSeriesData } from './Series/TerceraChartXYSeries';
import { TerceraXYChart } from './TerceraXYChart';
import { type TerceraChartXYWindow } from './Windows/TerceraChartXYWindow';

export class OptionVolatilityLabChart extends TerceraXYChart {
    private _datesArray: Date[] = [];
    private _basePriceLineRenderer: TerceraChartStyledVerticalLineRenderer;
    public override CreateMainWindow (): TerceraChartXYWindow {
        const mainWindow = super.CreateMainWindow();
        mainWindow.rightYScaleRenderer.Step = 0.01;
        mainWindow.rightYScaleRenderer.Precision = 2;
        mainWindow.rightYScaleRenderer.Format = ' %';
        return mainWindow;
    }

    protected override CreateLeftYScaleRenderer (): TerceraChartNumberVerticalScaleRenderer {
        return null;
    }

    public override InitializeRenderers (): void {
        super.InitializeRenderers();
        this._basePriceLineRenderer = new TerceraChartStyledVerticalLineRenderer(this);
        this.mainWindow.Renderers.push(this._basePriceLineRenderer);
        this.mainWindow.Renderers.push(new TerceraChartBaseCrossHairRenderer(this));
    }

    public override DrawNoData (gr: CanvasRenderingContext2D): void {
        this.NoDataMessage = Resources.getResource('panel.optionVolatilityLab.ChartNoData');
        super.DrawNoData(gr);
    }

    public override HasCorrectData (): boolean {
        const hasCorrectData = super.HasCorrectData();
        let hasVisibleLine: boolean = false;
        const lineRenderers = this.getLineRenderers();
        for (let i = 0; i < lineRenderers.length; i++) {
            if (lineRenderers[i].Visible) {
                hasVisibleLine = true;
                break;
            }
        }
        return hasCorrectData && hasVisibleLine;
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();
        this._basePriceLineRenderer.ColorStyleWidth = new ColorStyleWidth(ThemeManager.CurrentTheme.OptionMasterAnalyzerZeroLineColor, PenStyle.SimpleChart, 1);
    }

    public set (chartData: VolatilityLabChartData): void {
        this._datesArray = [];
        for (const entry of chartData.seriesMapDataSeries) {
            const optionDataSeriesDate = entry[0];
            this._datesArray.push(optionDataSeriesDate);
        }
        this.updateBasePriceLine(chartData.underlierPrice);
        const series = this.createSeries(chartData);
        this.setSeries(series);
    }

    public update (chartData: VolatilityLabChartData): void {
        this.updateBasePriceLine(chartData.underlierPrice);
        const series = this.createSeries(chartData);
        super.updateSeries(series);
        this.IsDirty(true);
    }

    private updateBasePriceLine (basePrice: number): void {
        const basePriceLineSeries = new TerceraChartXYSeries();
        basePriceLineSeries.data.push(new XYSeriesData(basePrice, 0));
        this._basePriceLineRenderer.Series = basePriceLineSeries;
    }

    public resetScales (): void {
        this.updateMinMaxXScaleBorders();
        this.updateMinMaxYScaleBorders();
    }

    public setLineColor (date: Date, color: string): void {
        const lineRenderer = this.getLineRendererByIndex(this._datesArray.indexOf(date));
        if (!isNullOrUndefined(lineRenderer)) {
            lineRenderer.LineStyle = LineStyle.LineDots;
            lineRenderer.ColorStyleWidth = new ColorStyleWidth(color, PenStyle.SimpleChart, 1);
        }
        this.IsDirty(true);
    }

    public setSelectedLines (dates: Date[]): void {
        const lineRenderers = this.getLineRenderers();
        for (let i = 0; i < lineRenderers.length; i++) {
            const date = this._datesArray[i];
            const lineRenderer = lineRenderers[i];
            lineRenderer.isSelected = dates.includes(date);
        }
        this.IsDirty(true);
    }

    private createSeries (chartData: VolatilityLabChartData): TerceraChartAggregatedXYSeries {
        const xScaleRenderer = this.windowsContainer.xScaleRenderer as TerceraChartNumberScaleRenderer;
        xScaleRenderer.Step = chartData.priceStep;
        xScaleRenderer.Precision = chartData.pricePrecision;
        xScaleRenderer.Legend = chartData.xScaleLegend;

        const series = new TerceraChartAggregatedXYSeries();
        for (const entry of chartData.seriesMapDataSeries) {
            const optionDataSeries = entry[1];
            const optionsSeries: TerceraChartXYSeries = new TerceraChartXYSeries();
            for (let i = 0; i < optionDataSeries.length; i++) {
                optionsSeries.data.push(new XYSeriesData(optionDataSeries[i].x, optionDataSeries[i].y));
            }
            series.data.push(optionsSeries);
        }
        return series;
    }
}
