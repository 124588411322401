// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MAMode, MODE_MAIN, MODE_SIGNAL } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iMACDEx extends iBuildInIndicator {
    public FFastMaPeriod: any;
    public FSlowMaPeriod: any;
    public FSignalPeriod: any;
    public FEMA1: ExpandDoubleVector;
    public FEMA2: ExpandDoubleVector;
    public FArray: ExpandDoubleVector;

    constructor (fastMaPeriod, slowMaPeriod, signalPeriod, priceType) {
        super(2);
        this.FPriceType = priceType;
        this.FFastMaPeriod = fastMaPeriod || 12;
        this.FSlowMaPeriod = slowMaPeriod || 26;
        this.FSignalPeriod = signalPeriod || 9;

        this.FEMA1 = new ExpandDoubleVector();
        this.FEMA2 = new ExpandDoubleVector();
        this.FArray = new ExpandDoubleVector();
    }

    public get Name (): string {
        return 'iMACDEx';
    }

    public get Key (): string {
        return this.DefaultKey + this.FFastMaPeriod + this.FSlowMaPeriod + this.FSignalPeriod + this.FPriceType;
    }

    public NextBar (callBound): void {
        super.NextBar(callBound);
        this.FEMA1.Add(0.0);
        this.FEMA2.Add(0.0);
        this.FArray.Add(0.0);
    };

    public Refresh (count, newThread): void {
        this.FEMA1.Dispose();
        this.FEMA1 = new ExpandDoubleVector();
        this.FEMA2.Dispose();
        this.FEMA2 = new ExpandDoubleVector();
        this.FArray.Dispose();
        this.FArray = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    };

    public OnQuote (ci, callBound, callFromRefresh): void {
        const price = this.GetPrice(this.FPriceType, 0);
        this.FEMA1[this.FEMA1.Length - 1] = price;
        this.FEMA2[this.FEMA2.Length - 1] = price;
        const Parent = this.Parent;
        if (!Parent) { return; }

        const start = Math.max(this.FFastMaPeriod, this.FSlowMaPeriod);
        if (start > this.Count - 1) { return; }

        // Calculation of EMA1
        const ema1 = IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.FEMA1, this.FFastMaPeriod, 1);
        this.FEMA1[this.FEMA1.Length - 1] = ema1;

        // Calculation of EMA2
        const ema2 = IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.FEMA2, this.FSlowMaPeriod, 1);
        this.FEMA2[this.FEMA2.Length - 1] = ema2;
        // Calculation of diffenrence
        const differ = this.FEMA1[this.FEMA1.Length - 1] - this.FEMA2[this.FEMA2.Length - 1];
        // Calculation of signal value
        this.FArray[this.FArray.Length - 1] = differ;

        if (this.FSignalPeriod < this.FCount) { this.SetValue(MODE_MAIN, 0, differ); }

        if (this.FCount >= this.FSignalPeriod) {
            const signal = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FArray, this.FSignalPeriod, 1);
            this.SetValue(MODE_SIGNAL, 0, signal);
        }
    };
};

export class iMACD extends iMACDEx {
    constructor (fastMaPeriod, slowMaPeriod, signalPeriod) {
        super(fastMaPeriod, slowMaPeriod, signalPeriod, PriceType.Close);
    }

    get Name (): string {
        return 'iMACD';
    }

    set Name (value: string) {

    }
}
