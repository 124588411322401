// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Rectangle } from '../../Commons/Geometry';
import { TerceraPopupErrorScreen } from '../../templates.js';
import { Control } from '../elements/Control';
import { popupErrorHandler } from '../../Utils/AppHandlers';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import $ from 'jquery';

export class PopupErrorScreen extends Control {
    public isShowed: boolean = false;
    public owner: Control = null;

    public override getType (): ScreensNames { return ScreensNames.PopupErrorScreen; }

    // TODO. Ugly. Refactor.
    public Show (owner, errText: string, headerText: string, isDown: boolean): void {
        if (typeof this.el !== 'object') {
            this.el = document.getElementById(this.el);
            void this.render(this.el);
        }

        void this.set({
            headerText: headerText ?? 'Error',
            headerVisible: true,
            errorText: errText,
            visible: true
        });

        this.isShowed = true;
        this.owner = owner;

        const errLabel = this.find('.js-tercera-label:last-child');
        if (isNullOrUndefined(errLabel)) return;

        const $errLabel = $(errLabel);

        const bordersSize = 2;
        const errHeaderH = 28;
        const bottomFreeSpace = 16;
        const errH = $errLabel.height() + bordersSize + errHeaderH + bottomFreeSpace;

        void this.set('height', errH);

        const ownerRectangle = owner.getAbsoluteRectangle();

        const errRect = PopupErrorScreen.getErrorAbsoluteRectangle(
            ownerRectangle,
            this.get('width'),
            errH);

        isDown = isDown || ownerRectangle.Y > errRect.Y;

        void this.set({
            left: errRect.Left(),
            top: errRect.Top(),
            elementStyle: (isDown ? 'js-win-popupError-bottom' : 'js-win-popupError-top')
        });
    }

    // TODO. Ugly.
    public static getErrorAbsoluteRectangle (ownerAbsRect: Rectangle, errW: number, errH: number): Rectangle {
    // 52, 32 are top and bottom strips' heights.
        const scrRect = new Rectangle(0, 40, window.innerWidth, window.innerHeight - 52 - 32);

        const errRect = new Rectangle(ownerAbsRect.Left(), ownerAbsRect.Bottom(), errW, errH);

        if (scrRect.ContainsRectangle(errRect)) {
            return errRect;
        }

        let freeSpaceRect = null;

        const bottomFreeSpaceRect = new Rectangle(
            scrRect.Left(),
            ownerAbsRect.Bottom(),
            scrRect.Width,
            scrRect.Bottom() - ownerAbsRect.Bottom());

        if (bottomFreeSpaceRect.CanFit(errW, errH)) {
            errRect.Y = ownerAbsRect.Bottom();
            freeSpaceRect = bottomFreeSpaceRect;
        } else {
            const topFreeSpaceRect = new Rectangle(
                scrRect.Left(),
                scrRect.Top(),
                scrRect.Width,
                ownerAbsRect.Top() - scrRect.Top());

            if (topFreeSpaceRect.CanFit(errW, errH)) {
                errRect.Y = ownerAbsRect.Top() - errH;
                freeSpaceRect = topFreeSpaceRect;
            }
        }

        if (!isNullOrUndefined(freeSpaceRect)) {
            errRect.X =
            ownerAbsRect.Left() + errW < freeSpaceRect.Right()
                ? ownerAbsRect.Left()
                : scrRect.Right() - errW - 1;
        }

        return errRect;
    }

    public Hide (owner): void {
        if (!this.isShowed) {
            return;
        }

        if (owner !== this.owner) {
            return;
        }

        void this.set({ visible: false });
        this.isShowed = false;
    }

    public static PopupErrorHandlerInitialize (): void {
        const _popupError = new PopupErrorScreen();
        popupErrorHandler.setHandler(_popupError);
        popupErrorHandler.Show = _popupError.Show.bind(_popupError);
        popupErrorHandler.Hide = _popupError.Hide.bind(_popupError);
        popupErrorHandler.set = (key) => { void _popupError.set(key); };
        // popupErrorHandler.on = _popupError.on;
        // popupErrorHandler.off = _popupError.off;

        popupErrorHandler.isShowed = () => { return _popupError.isShowed; };
    }
}

Control.extendWith(PopupErrorScreen, {
    // template
    template: TerceraPopupErrorScreen,
    el: '#popupErrorScreenContainer',
    data: function () {
        return {
            visible: false,
            errorText: '',
            headerText: 'Error',
            headerVisible: true,
            width: 153,
            height: 0,
            elementStyle: 'js-win-popupError-top'
        };
    }
});
