// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ConnectionStates } from '@shared/commons/ConnectionEnums';
import { Connection } from '@shared/commons/Connection';
import { CustomErrorClass, ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { ApplicationInfo } from '@shared/commons/ApplicationInfo';
import { type IndexedDB } from '@shared/commons/DataBase/IndexedDB';
import { UserManager } from './UserManager';
import { type LocalDBRecord, type RemoteDBRecord } from '@shared/utils/DBRecords';

export abstract class BaseStorageManager<T> {
    private readonly SYNC_OUT_INTERVAL = 10 * 60 * 1000; // 10 minutes
    private readonly SYNC_IN_INTERVAL = 2 * 1000; // 2 seconds

    protected wasSyncedIn: boolean = true;
    protected wasSyncedOut: boolean = true;
    private syncOutTimer: any = null;
    private syncInTimer: any = null;

    private readonly indexedDB: IndexedDB | null;
    private readonly storeName: string;
    private get key (): string | null { return UserManager.getUserKey(); };

    constructor (indexedDB: IndexedDB, storeName: string) {
        this.indexedDB = indexedDB;
        this.storeName = storeName;
    }

    // #region Abstract methods
    protected abstract serializeData (): string;
    protected abstract deserializeData (data: string): T;
    protected abstract fetchRemoteSettings (): Promise<RemoteDBRecord | null>;
    protected abstract sendRemoteSettings (data: RemoteDBRecord): Promise<void>;
    protected abstract onRestore (result: T): void;
    // #endregion

    public wasChanged (): void {
        this.wasSyncedIn = false;
        if (!ApplicationInfo.isExploreMode) {
            this.wasSyncedOut = false;
        }
    };

    public initialize (): void {
        Connection.onConnectStateChange.Subscribe(this.connectionStateChange, this);
    }

    public async syncInOut (): Promise<void> {
        try {
            const [localResult, remoteResult] = await Promise.all([this.fetchIn(), this.fetchOut()]);
            const hasLocal = localResult != null;
            const hasRemote = remoteResult != null;

            let data = null;

            if (hasLocal && !hasRemote) {
                data = localResult.settings;
            } else if (!hasLocal && hasRemote) {
                data = remoteResult.settings;
            } else if (hasLocal && hasRemote) {
                if (localResult.updateTimeSpan > remoteResult.updateTimeSpan) {
                    data = localResult.settings;
                } else {
                    data = remoteResult.settings;
                }
            }

            const preparedData = data ? this.deserializeData(data) : null;
            this.onRestore(preparedData);
        } catch (error) {
            console.error('Error in syncInOut', error);
        }
    }

    private async connectionStateChange (): Promise<void> {
        const state = Connection.getState();
        switch (state) {
        case ConnectionStates.CONNECTED:
            this.startIntervalSyncInOut();
            break;
        case ConnectionStates.DISCONNECTED:
            this.stopIntervalSyncInOut();
            break;
        }
    }

    private startIntervalSyncInOut (): void {
        this.syncOutTimer = setInterval(() => {
            void this.syncOut();
        }, this.SYNC_OUT_INTERVAL);

        this.syncInTimer = setInterval(() => {
            void this.syncIn();
        }, this.SYNC_IN_INTERVAL);
    }

    private stopIntervalSyncInOut (): void {
        if (this.syncOutTimer) {
            clearInterval(this.syncOutTimer);
            this.syncOutTimer = null;
        }
        if (this.syncInTimer) {
            clearInterval(this.syncInTimer);
            this.syncInTimer = null;
        }
    }

    private async fetchIn (): Promise<LocalDBRecord | null> {
        try {
            const localResult = await this.indexedDB.get<LocalDBRecord>(this.storeName, this.key);
            return localResult;
        } catch (error) {
            console.error('Error fetching settings from local DB:', error);
            return null;
        }
    }

    private async fetchOut (): Promise<RemoteDBRecord | null> {
        try {
            const settingsData = await this.fetchRemoteSettings();
            return settingsData;
        } catch (error) {
            console.error('Error fetching settings from server:', error);
            return null;
        }
    }

    protected async syncIn (): Promise<void> {
        if (!this.wasSyncedIn) {
            this.wasSyncedIn = true;
            const dataJson = this.serializeData();
            const dataResult: LocalDBRecord = {
                userId: this.key,
                settings: dataJson,
                updateTimeSpan: +(new Date())
            };
            await this.indexedDB.addOrUpdate(this.storeName, dataResult);
        }
    }

    public async syncOut (): Promise<void> {
        try {
            if (!this.wasSyncedOut) {
                this.wasSyncedOut = true;
                const settingsData = await this.fetchIn();
                if (settingsData) {
                    await this.sendRemoteSettings(settingsData);
                }
            }
        } catch (error) {
            const ex = new CustomErrorClass('BaseSettingsStorageManager syncOut error', 'save', 'save -> UserWebStorage.settings');
            ErrorInformationStorage.GetException(ex);
        }
    }
}
