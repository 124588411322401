// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { KeyCode, KeyEventProcessor } from '../../Commons/KeyEventProcessor';
import { SoundManager } from '../../Utils/SoundManager';
import { TerceraMessageBoxTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';
import { ThemeManager } from '../misc/ThemeManager';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { messageBoxHandler } from '../../Utils/AppHandlers';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { type TerceraLabel } from '../elements/TerceraLabel';
import { type TerceraPictureBox } from '../elements/TerceraPictureBox';

export class TerceraMessageBox extends TerceraWindowBase {
    public customDisposeHandler: any = null;
    public OK_Handler: any = null;
    public NO_Handler: any = null;
    public OkClicked: (value?: any) => void;
    public CancelClicked: (value?: any) => void;
    public torndown: boolean;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): ScreensNames { return ScreensNames.TerceraMessageBox; }

    public override oninit (): void {
        super.oninit();
        void this.set({ pictureErrorUrl: ThemeManager.getImageFullUrlNew('i_messagebox/30x30_icon_warning.png') });
        this.on('okClick', this.okClick);
        this.on('cancelClick', this.cancelClick);
        this.observe('messageBoxType', (newValue) => {
            let imgUrl = 'darkNew/i_messagebox/30x30_icon_info.png';
            switch (newValue) {
            case MessageBoxType.Error:
                imgUrl = 'i_messagebox/30x30_icon_error.png';
                break;
            case MessageBoxType.Info:
                imgUrl = 'i_messagebox/30x30_icon_info.png';
                break;
            case MessageBoxType.Question:
                imgUrl = 'i_messagebox/30x30_icon_question.png';
                break;
            case MessageBoxType.Warning:
                imgUrl = 'i_messagebox/30x30_icon_warning.png';
                break;
            case MessageBoxType.Approve:
                imgUrl = 'i_messagebox/30x30_icon_approve.svg';
                break;
            case MessageBoxType.YellowInfo:
                imgUrl = 'i_messagebox/30x30_icon_yellow_info.svg';
                break;
            }
            void this.set({ pictureUrl: ThemeManager.getImageFullUrlNew(imgUrl) });
        });
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.arrangeControls();
        this.center();
    }

    public arrangeControls (): void {
        const controls = this.Controls;

        const mainTextLabel: TerceraLabel = controls.mainTextLabel;
        let y = mainTextLabel.realRelativeBottom();

        const mainImg = controls.mainImg;

        const mainTextTop = mainTextLabel.get('top');
        const mainTextMiddle = mainTextTop + (y - mainTextTop) / 2;

        mainImg.set('top', mainTextMiddle - mainImg.get('height') / 2);

        y += 17;

        const errorMode: boolean = this.get('errorMode');
        if (errorMode) {
            void this.set('errorTop', y);

            const errorTextLabel = controls.errorTextLabel;
            const errorBottom = errorTextLabel.realRelativeBottom();

            const errorImg: TerceraPictureBox = controls.errorImg;

            const errorTextTop = errorTextLabel.get('top');
            const errorTextMiddle = errorTextTop + (errorBottom - errorTextTop) / 2;

            void errorImg.set('top', errorTextMiddle - errorImg.get('height') / 2);

            y += errorBottom + 17;
        }

        void this.set('height', null);
    }

    public onGlobalKeyDown (code: KeyCode): void {
        if (this.get<boolean>('focused')) {
            if (code === KeyCode.ESC) {
                this.cancelClick();
            }

            if (code === KeyCode.ENTER && this.get<boolean>('allowKeyboardConfirmation')) {
                this.okClick();
            }
        }
    }

    public override onteardown (): void {
        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
    }

    public okClick (): void {
        const okCallBack = this.get('okCallBack');
        if (!isNullOrUndefined(okCallBack)) {
            okCallBack(this.get<boolean>('showNextTimeChB') ? this.get('showNextTime') : undefined);
        }

        // new Way
        if (!isNullOrUndefined(this.OkClicked)) {
            this.OkClicked();
        }

        this.close();
    }

    public cancelClick (): void {
        const cancelCallBack = this.get('cancelCallBack');
        if (!isNullOrUndefined(cancelCallBack)) {
            cancelCallBack(this.get<boolean>('showNextTimeChB') ? this.get('showNextTime') : undefined);
        }

        if (!isNullOrUndefined(this.CancelClicked)) {
            this.CancelClicked();
        }

        this.close();
    }

    // Close button (cross icon).
    public override onCloseButtonClick (): void {
        if (this.canCloseFromButton()) {
            this.close();
            const closeButtonCallBack = this.get('closeButtonCallBack') ?? this.get('cancelCallBack');
            if (!isNullOrUndefined(closeButtonCallBack)) {
                closeButtonCallBack(this.get<boolean>('showNextTimeChB') ? this.get('showNextTime') : undefined);
            }
        }
    }

    public override dispose (): void {
        if (!isNullOrUndefined(this.customDisposeHandler)) {
            this.customDisposeHandler();
        }

        super.dispose();
    }

    public updateWithParams (headerText: string, bodyText: string, type: MessageBoxType, okCallBack?, cancelCallBack?, showNextTimeChB?: boolean, hideCancel?, errorText?: string): void {
        void this.set({
            messageBoxType: type,
            header: headerText,
            messageBoxText: bodyText,
            okCallBack,
            cancelCallBack,
            showNextTimeChB,
            hideCancel,
            showNextTimeText: Resources.getResource('general.messageBox.showNextTime'),
            okText: Resources.getResource('general.messageBox.yes'),
            cancelText: Resources.getResource('general.messageBox.no')
        });

        if (isValidString(errorText)) {
            void this.set({
                errorMode: true,
                errorText,
                showNextTimeChB: !!showNextTimeChB
            });
        }
    }

    public updateWithAdditionalData (addData: Record<string, any>): void {
        if (!isNullOrUndefined(addData)) {
            if (Object.prototype.hasOwnProperty.call(addData, 'forceCloseOnLogout') === true) {
                this.forceCloseOnLogout = addData.forceCloseOnLogout;
            }
            if (isValidString(addData.cancelText)) {
                void this.set({ cancelText: addData.cancelText });
            }
            if (isValidString(addData.okText)) {
                void this.set({ okText: addData.okText });
            }
            if (!isNullOrUndefined(addData.closeButtonCallBack)) {
                void this.set('closeButtonCallBack', addData.closeButtonCallBack);
            }
            if (Object.prototype.hasOwnProperty.call(addData, 'allowKeyboardConfirmation') === true) {
                void this.set('allowKeyboardConfirmation', addData.allowKeyboardConfirmation);
            }
            if (Object.prototype.hasOwnProperty.call(addData, 'cantActionBeforeClose') === true) {
                void this.set('showFullscreenCloud', Boolean(addData.cantActionBeforeClose));
            }
            if (Object.prototype.hasOwnProperty.call(addData, 'hideCloseBtn') === true) {
                void this.set('closeBtnVisible', addData.hideCloseBtn !== true);
            }
            if (Object.prototype.hasOwnProperty.call(addData, 'zIndex') === true) {
                void this.set('zIndex', addData.zIndex);
            }
            if (Object.prototype.hasOwnProperty.call(addData, 'btnOKStyle') === true) {
                void this.set('btnOKStyle', addData.btnOKStyle);
            }
            if (Object.prototype.hasOwnProperty.call(addData, 'btnCancelStyle') === true) {
                void this.set('btnCancelStyle', addData.btnCancelStyle);
            }
            if (Object.prototype.hasOwnProperty.call(addData, 'style_addition_header') === true) {
                void this.set('style_addition_header', addData.style_addition_header);
            }
        }
    }

    public static PlaySound (type: MessageBoxType): void {
        let soundKey = null;

        switch (type) {
        case MessageBoxType.Error:
        case MessageBoxType.Warning:
        case MessageBoxType.Info:
        case MessageBoxType.Question:
            soundKey = SoundManager.SoundKeys.Warning;
            break;
        default:
            soundKey = SoundManager.SoundKeys.Confirmation;
        }

        SoundManager.tryPlaySound(soundKey);
    }

    public static MessageBoxHandlerInitialize (): void {
        messageBoxHandler.Show = TerceraMessageBox.Show;
        messageBoxHandler.msgType = MessageBoxType;
    }

    public static Show (headerText: string, bodyText: string, type: MessageBoxType, okCallBack?, cancelCallBack?, showNextTimeChB?: boolean, hideCancel?, errorText?: string, addData?, skipOnTeardown: boolean = false): TerceraMessageBox {
        const terceraMessageBox = new TerceraMessageBox();
        terceraMessageBox.updateWithParams(headerText, bodyText, type, okCallBack, cancelCallBack, showNextTimeChB, hideCancel, errorText);

        MainWindowManager.MainWindow.addControl(terceraMessageBox);

        terceraMessageBox.updateWithAdditionalData(addData);

        terceraMessageBox.setFocus();

        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.Subscribe(terceraMessageBox.onGlobalKeyDown, terceraMessageBox);
        terceraMessageBox.forceCloseOnLogout = !skipOnTeardown;

        terceraMessageBox.OK_Handler = new Promise(function (resolve) { terceraMessageBox.OkClicked = resolve; });
        terceraMessageBox.NO_Handler = new Promise(function (resolve) { terceraMessageBox.CancelClicked = resolve; });

        TerceraMessageBox.PlaySound(type);

        return terceraMessageBox;
    }
}

export enum MessageBoxType {
    Error = 1,
    Info = 2,
    Question = 3,
    Warning = 4,
    Approve = 5,
    YellowInfo = 6
};

TerceraWindowBase.extendWith(TerceraMessageBox, {
    data: function () {
        return {
            zIndex: 300,
            // width: 485, // Must be commented out or removed for the correct application of the Investing/TVTradingPlatform style
            errorTop: 0,
            header: '',
            resizable: false,
            showFooter: false,
            pictureUrl: '',
            messageBoxText: '',
            showNextTimeText: 'Show next time',
            showNextTime: true,
            showNextTimeChB: false,
            okText: 'Yes',
            cancelText: 'No',
            btnOKStyle: TerceraButtonStyle.Standard,
            btnCancelStyle: TerceraButtonStyle.Standard,
            messageBoxType: null,
            okCallBack: null,
            cancelCallBack: null,
            closeButtonCallBack: null,
            hideCancel: false,
            allowKeyboardConfirmation: true,
            showFullscreenCloud: false, // TODO 83829
            closeBtnVisible: true,
            errorMode: false,
            errorText: '',
            pictureErrorUrl: '',
            style_addition_header: 'js-PropertySetupScreen-AdditionalHeader'
        };
    },
    partials: { bodyPartial: TerceraMessageBoxTemplate }
});
