// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InstrumentTypes, InstrumentTypesColor, InstrumentTypesShortName } from '@shared/utils/Instruments/InstrumentTypes';
import { type Account } from '../cache/Account';
import { AccountUtils } from '../cache/AccountUtils';

export class AccountMenuItem {
    private readonly AccountName: string = '';
    private readonly PreferredInstrumentType: number = InstrumentTypes.NONE;
    private readonly Account: Account = null;
    public Selected: boolean = false;

    constructor (account: Account) {
        this.PreferredInstrumentType = account.PreferredInstrumentType;
        this.AccountName = account.toString(true);
        this.Account = account;
    }

    public GetModeText (): string {
        return AccountUtils.GetAccountModeText(this.Account);
    }

    public GetModeWidth (): number {
        return AccountUtils.GetAccountModeWidth(this.Account);
    }

    public GetInsTypeShortName (): string {
        return InstrumentTypesShortName[this.PreferredInstrumentType];
    }

    public GetInstrumentColor (): string {
        return InstrumentTypesColor[this.PreferredInstrumentType];
    }

    public GetName (): string {
        return this.AccountName;
    }

    public GetAccount (): Account {
        return this.Account;
    }

    public IsEmptyType (): boolean {
        return this.PreferredInstrumentType === InstrumentTypes.NONE;
    }

    public equal (item: AccountMenuItem): boolean {
        return this.AccountName === item.AccountName && this.Account === item.Account;
    }
}
