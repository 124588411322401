// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectTradingSignalSeenMessage extends DirectMessageBaseClass {
    public TradingSignalId: number | null = null;

    constructor () {
        super(Message.CODE_TRADING_SIGNAL_REQ_SEEN_MESSAGE);
    }
}
