// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type OrderType } from '@shared/utils/Trading/OrderType';
import { type OperationType } from '@shared/utils/Trading/OperationType';
import { type ProductType } from '@shared/utils/Instruments/ProductType';
import { type OrderTif } from '@shared/utils/Trading/OrderTifEnum';

export class OrderGroup extends PFixFieldSet {
    constructor (pfixFieldSet?: PFixFieldSet) {
        super(FieldsFactory.ORDER_GROUP_NAME, null, pfixFieldSet);
    }

    public getOrderId (): bigint {
        return this.GetValue(FieldsFactory.FIELD_ORDER_ID);
    }

    public getClientOrderId (): string {
        return this.GetValue(FieldsFactory.FIELD_CLIENT_ORDER_ID);
    }

    public setClientOrderId (orderId: string): void {
        this.SetField(FieldsFactory.FIELD_CLIENT_ORDER_ID, orderId);
    }

    public getOrderType (): OrderType {
        const type = this.GetValue(FieldsFactory.FIELD_ORDER_TYPE);
        return type;
    }

    public setOrderType (type: OrderType): void {
        this.SetField(FieldsFactory.FIELD_ORDER_TYPE, type);
    }

    public getOperationType (): OperationType {
        const type = this.GetValue(FieldsFactory.FIELD_OPERATION_TYPE);
        return type;
    }

    public setOperationType (operationType: OperationType): void {
        this.SetField(FieldsFactory.FIELD_OPERATION_TYPE, operationType);
    }

    public getInstrumentId (): number {
        return this.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID);
    }

    public setInstrumentId (id: number): void {
        this.SetField(FieldsFactory.FIELD_INSTRUMENT_ID, id);
    }

    public getInstrumentTradableID (): number {
        return this.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
    }

    public setInstrumentTradableID (id: number): void {
        this.SetField(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, id);
    }

    public getPrice (): number {
        return this.GetValue(FieldsFactory.FIELD_PRICE);
    }

    public setPrice (price: number): void {
        this.SetField(FieldsFactory.FIELD_PRICE, price);
    }

    public getStopPrice (): number {
        return this.GetValue(FieldsFactory.FIELD_STOP_PRICE);
    }

    public setStopPrice (stopPrice: number): void {
        this.SetField(FieldsFactory.FIELD_STOP_PRICE, stopPrice);
    }

    public getSLPrice (): number {
        return this.GetValue(FieldsFactory.FIELD_SL_PRICE);
    }

    public getTPPrice (): number {
        return this.GetValue(FieldsFactory.FIELD_TP_PRICE);
    }

    // FIELD_SL_TR_OFFSET - для аналога стоплосса
    public getTralingOffset2 (): number {
        return this.GetValue(FieldsFactory.FIELD_SL_TR_OFFSET);
    }

    public getAmount (): number {
        return this.GetValue(FieldsFactory.FIELD_AMOUNT);
    }

    public setAmount (amount: number): void {
        this.SetField(FieldsFactory.FIELD_AMOUNT, amount);
    }

    public setCashOrderQty (cash: number): void {
        this.SetField(FieldsFactory.FIELD_CASH_ORDER_QTY, cash);
    }

    public getValidity (): OrderTif {
        const tif = this.GetValue(FieldsFactory.FIELD_VALIDITY);
        return tif;
    }

    public setValidity (tif: OrderTif): void {
        this.SetField(FieldsFactory.FIELD_VALIDITY, tif);
    }

    public getExpDay (): any {
        return this.GetValue(FieldsFactory.FIELD_EXP_DAY);
    }

    public getExpMonth (): any {
        return this.GetValue(FieldsFactory.FIELD_EXP_MONTH);
    }

    public getExpYear (): any {
        return this.GetValue(FieldsFactory.FIELD_EXP_YEAR);
    }

    public getAccountId (): number {
        return this.GetValue(FieldsFactory.FIELD_ACCOUNT_ID);
    }

    public setAccountId (accountId: number): void {
        this.SetField(FieldsFactory.FIELD_ACCOUNT_ID, accountId);
    }

    public getProductType (): ProductType {
        return this.GetValue(FieldsFactory.FIELD_PRODUCT_TYPE);
    }

    public setProductType (productType: ProductType): void {
        this.SetField(FieldsFactory.FIELD_PRODUCT_TYPE, productType);
    }

    public getRouteId (): number {
        return this.GetValue(FieldsFactory.FIELD_ROUTE_ID);
    }

    public setRouteId (routeId: number): void {
        this.SetField(FieldsFactory.FIELD_ROUTE_ID, routeId);
    }

    public getTIFExpireDate (): Date | null {
        return this.GetValue(FieldsFactory.FIELD_EXPIRE_AT);
    }

    public setTIFExpireDate (dt: Date): void {
        this.SetField(FieldsFactory.FIELD_EXPIRE_AT, dt);
    }

    public setSLPrice (price: number): void {
        this.SetField(FieldsFactory.FIELD_SL_PRICE, price);
    }

    public setSLPriceType (priceType: number): void {
        this.SetField(FieldsFactory.FIELD_SL_PRICE_TYPE, priceType);
    }

    public setSLLimitPrice (price: number): void {
        this.SetField(FieldsFactory.FIELD_SL_LIMIT_PRICE, price);
    }

    public setTPPrice (price: number): void {
        this.SetField(FieldsFactory.FIELD_TP_PRICE, price);
    }

    public setTPPriceType (priceType: number): void {
        this.SetField(FieldsFactory.FIELD_TP_PRICE_TYPE, priceType);
    }

    public setTrailingOffset (offset: number): void {
        this.SetField(FieldsFactory.FIELD_TR_OFFSET, offset);
    }

    public setOpen (open: boolean): void {
        this.SetField(FieldsFactory.FIELD_IS_OPEN, open);
    }

    public setByBroker (isByBroker: boolean): void {
        this.SetField(FieldsFactory.FIELD_IS_BY_BROKER, isByBroker);
    }

    public setIsByStrategy (isByStrategy: boolean): void {
        this.SetField(FieldsFactory.FIELD_IS_AUTOTRADE_ORDER, isByStrategy);
    }

    public setBoundTo (id: number): void {
        this.SetField(FieldsFactory.FIELD_BOUNDTOORDERID, id);
    }

    public setOrderIdToLink (orderId): void {
        this.SetField(FieldsFactory.FIELD_CLIENT_ORDER_ID_TO_LINK, orderId.toString());
    }

    public setOrderLinkType (linkType): void {
        this.SetField(FieldsFactory.FIELD_ORDER_LINK_TYPE, linkType);
    }

    public setPlacedFrom (placedFrom): void {
        this.SetField(FieldsFactory.FIELD_CLIENT_PANEL, placedFrom);
    }

    public setDisclosedQty (disclosedQty: number): void {
        this.SetField(FieldsFactory.FIELD_DISCLOSED_QUANTITY, disclosedQty);
    }

    public setSLTriggerPrice (price): void {
        this.SetField(FieldsFactory.FIELD_SL_TRIGGER_PRICE, price);
    }

    public setTPTriggerPrice (price): void {
        this.SetField(FieldsFactory.FIELD_TP_TRIGGER_PRICE, price);
    }

    public setSLTPTriggerPrice (price): void {
        this.SetField(FieldsFactory.FIELD_SL_TP_TRIGGER_PRICE, price);
    }

    public setLeverage (leverageValue: number): void {
        this.SetField(FieldsFactory.FIELD_LEVERAGE_SHORT, leverageValue);
    }

    public setComment (comment: string): void {
        this.SetField(FieldsFactory.FIELD_COMMENT, comment);
    }

    public setUserComment (comment: string): void {
        this.SetField(FieldsFactory.FIELD_USER_COMMENT, comment);
    }
}
