// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum ITerceraChartShowScreenType {
    Properties = 0,
    PropertiesModal = 1,
    HDM = 2,
    InstrumentLookup = 3,
    ChartSettings = 4,
    NewPanel = 5,
    Layouts = 6,
    SaveLayouts = 7,
    EditOverlay = 8,
    SynhrCursor = 9,
    ScriptLookup = 10,
    UIManipulation = 11,
    OverlayScreen = 12,
    MakeShot = 13,
    EmailSnapShot = 14,
    ChartDrop = 15,
    HistoryDataManager = 16,
    EditScript = 17,
    CustomTFInfo = 18,
    ObjectManager = 19,
    AnalyseToolsMessageBox = 20,
    IsAllowAlgo = 21,
    ChartTrading = 22
}
