// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
class _HistoryType {
    // HistoryTypes
    public readonly DEFAULT = 0; // QUOTE_TIME_ZONE

    public readonly BID = 1; // QUOTE_LEVEL1
    public readonly QUOTE_LEVEL1 = 1;
    public readonly QUOTE_LEVEL2 = 2;
    public readonly QUOTE_TRADES = 4;
    public readonly LAST = 4; // QUOTE_TRADES
    public readonly QUOTE_OPTIONS = 8;

    public readonly QUOTE_INSTRUMENT_PRICES = 16;
    public readonly QUOTE_INSTRUMENT_DAY_PRICES = 12;
    public readonly QUOTE_INSTRUMENT_DAY_BAR = 32;
    /// <summary>
    /// искусственный режим - бары по аскам
    /// </summary>
    public readonly ASK = 1000; // QUOTE_ASK
    public readonly QUOTE_ASK = 1000;
    /// <summary>
    /// искусственный режим - бары по биду и аску (bid+ask)/2
    /// </summary>
    public readonly BIDASK_AVG = 1001; // QUOTE_BIDASK_AVG
    public readonly QUOTE_BIDASK_AVG = 1001; //
    /// <summary>
    /// искусственный режим - бары по биду и аску:
    /// open и close всегда по биду,
    /// high =max по всем (bid,ask) и low = min по всем (bid,ask)
    /// </summary>
    public readonly BIDASK_SUM = 1002; // QUOTE_BIDASK_SUM
    public readonly QUOTE_BIDASK_SUM = 1002; // QUOTE_BIDASK_SUM
}

export const HistoryType: _HistoryType = new _HistoryType();
