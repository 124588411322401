// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectClosePositionMessage extends DirectMessageBaseClass {
    public OrderWasManual = false;
    public Id: string | null = null;
    public CloseOrderId: any = null;
    public ClosePrice = 0;
    public CloseTime: Date | null = null;
    public Symbol = '';
    // для BT
    public Pnl = 0;
    public Commission = 0;

    constructor () {
        super(Message.CODE_CLOSEPOSITION);
    }
}
