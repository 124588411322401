// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ContainerControl } from '../../ContainerControl';
import { VideoWidgetItemSideBarTemplate } from '../../../../templates.js';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { Resources } from '../../../../Localizations/Resources';
import { externalScreenHandler } from '../../../../Utils/AppHandlers';
import { ExtLinkUtils } from '../../../../Commons/UtilsClasses/ExtLinkUtils';
import { YoutubeExtLink } from '../../../../Commons/cache/ExternalLink/YoutubeExtLink';
import { ExternalLinksObserver } from '../../../../Commons/UtilsClasses/Observers/ExternalLinksObserver';
import { type BaseExtLink } from '../../../../Commons/cache/ExternalLink/BaseExtLink';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';

export class VideoWidgetItemSideBar extends ContainerControl {
    private readonly externalScreenInitWidth = 545;
    private readonly externalScreenInitHeight = 343;
    private readonly locKeyOfLIVELabel = 'panel.VideoWidget.TimeDateLabelTextForLIVE';

    private readonly _extLinksObserver = new ExternalLinksObserver();

    get Link (): YoutubeExtLink { return this.get('externalLink'); }
    set Link (value: YoutubeExtLink) { void this.set('externalLink', value); }
    get VideoPreviewURL (): string { return this.get('videoPreviewURL'); }
    set VideoPreviewURL (value: string) { void this.set('videoPreviewURL', value); }
    get ChannelName (): string { return this.get('channelName'); }
    set ChannelName (value: string) { void this.set('channelName', value); }
    get ChannelPic (): string { return this.get('channelPic'); }
    set ChannelPic (value: string) { void this.set('channelPic', value); }
    get Title (): string { return this.get('title'); }
    set Title (value: string) { void this.set('title', value); }
    get StreamHasBeenAlreadyEnded (): boolean { return this.get('streamHasBeenAlreadyEnded'); }
    set StreamHasBeenAlreadyEnded (value: boolean) { void this.set('streamHasBeenAlreadyEnded', value); }
    get IsLIVE (): boolean { return this.get('isLive'); }
    set IsLIVE (value: boolean) { void this.set('isLive', value); }
    get isShowData (): boolean { return this.get('isShowData'); }
    set isShowData (value: boolean) { void this.set('isShowData', value); }
    get StreamStartDate (): any { return this.get('streamStartDateFormatted'); }
    set StreamStartDate (value) {
        if (value) {
            const date = new Date(value);
            void this.set({
                streamStartDate: date,
                streamStartDateFormatted: DateTimeUtils.formatDate(date, 'DD.MM.YYYY HH:mm')
            });
        }
    }

    get Localize (): string { return this.get('localizedLIVE'); }
    set Localize (value: string) { void this.set('localizedLIVE', value); }

    constructor () {
        super();

        void this.set('visible', true);
    }

    oncomplete (): void {
        super.oncomplete();

        this.Localize = Resources.getResource(this.locKeyOfLIVELabel);
        this.on('onCardClick', this.onCardClick);
        this.updateLinkValues();

        this._extLinksObserver.subscribeToUpdate(this.onUpdateYtExternalLinkEvent);
        this._extLinksObserver.subscribeToYtUpdate(this.onUpdateYtExternalLinkEvent);
    }

    public dispose (): void {
        this._extLinksObserver.unsubscribeToUpdate(this.onUpdateYtExternalLinkEvent);
        this._extLinksObserver.unsubscribeToYtUpdate(this.onUpdateYtExternalLinkEvent);
    }

    private readonly onUpdateYtExternalLinkEvent = (link: BaseExtLink): void => {
        if (link instanceof YoutubeExtLink && this.Link.id === link.id) {
            this.Link = link;
            this.updateLinkValues();
        }
    };

    private updateLinkValues (): void {
        this.VideoPreviewURL = this.Link.config.videoImage;
        this.ChannelName = this.Link.config.channelTitle;
        this.ChannelPic = this.Link.config.channelImage;
        this.Title = this.Link.config.videoTitle;
        this.StreamHasBeenAlreadyEnded = this.Link.config.streamHasBeenAlreadyEnded;
        this.IsLIVE = this.Link.config.getStreamOnline();
        this.StreamStartDate = this.Link.config.getStreamStartDate();
        this.isShowData = ExtLinkUtils.showDateIfNeeded(this.Link);
    }

    public getType (): ControlsTypes {
        return ControlsTypes.VideoWidgetItemSideBar;
    }

    private onCardClick (): void {
        if (this.Link.clientUseInternalBrowser) {
            const screenInitData = {
                ClientUseInternalBrowser: this.Link.clientUseInternalBrowser,
                Text: ExtLinkUtils.getEmbedYouTubeLinkByVideoID(this.Link.videoID),
                Tool: this.Link,
                ResizableScreen: true,
                ScreenWidth: this.externalScreenInitWidth,
                ScreenHeight: this.externalScreenInitHeight,
                ScreenName: this.Link.config.videoTitle
            };

            const scr = externalScreenHandler.Show(screenInitData, true);
        } else {
            window.open(this.Link.urlStr);
        }
    }
};

ContainerControl.extendWith(VideoWidgetItemSideBar,
    {
        template: VideoWidgetItemSideBarTemplate,
        data: function () {
            return {
                visible: true,
                externalLink: null,
                videoPreviewURL: null,
                channelName: null,
                channelPic: null,
                title: null,
                streamHasBeenAlreadyEnded: null,
                isLIVE: null,
                streamStartDateFormatted: null,
                isShowData: null
            };
        }
    });
