// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HolidayDescription } from '../Session/HolidayDescription';
import { type SessionPeriod } from '../Session/SessionPeriod';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectSessionMessage extends DirectMessageBaseClass {
    constructor () {
        super(Message.CODE_SESSION_MESSAGE);
    }

    public id: number = 0;
    public name: string = '';
    /**
     * Current session start of date on server. (with timeOffset)
     */
    public serverBeginDayDate: Date | null = null;
    public periods: SessionPeriod[] | null = null;
    public holidays: Map<string, HolidayDescription> | null = null;
    public currentPeriodId = 0;
    public blockTrading = false;
    public timeZoneID: string;
    public timeOffset: number;
    public firstDayOfWeek: number = 1;
}
