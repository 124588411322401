// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// main question what we need to do if chart have aggregated history?

import { CustomEvent } from '@shared/utils/CustomEvents';
import { AutoLoadingState } from './AutoLoadingState';
import { type TerceraChart } from './TerceraChart';
import { ChartLoadTrigger } from './TerceraChartMVC';

// how to append new data to chart?
export class TerceraChartAutoLoadHistoryManager {
    public chart: TerceraChart;
    public allowAutoLoad: boolean;
    public wasDragged: boolean;
    public isLoadingHistoryNow: boolean;

    public loadingStateChanged: CustomEvent = new CustomEvent();
    private barsForNextLoading: number = 0;

    constructor (chart: TerceraChart) {
        this.chart = chart;
        this.allowAutoLoad = false;
        this.wasDragged = false;
        this.isLoadingHistoryNow = false;
    }

    // chart was changed by zooming or resizing
    public resetCurrentView (): any {
        // need reload history
    }

    public dispose (): void {
        // need stop load history
        this.stopLoading();
    }

    public stopLoading (): any {
        this.isLoadingHistoryNow = false;
    }

    public setBarsToLoad (needToLoadBars: number): void {
        if (this.checkChartState()) { this.setPaddingBarsCount(needToLoadBars); }
    }

    private checkChartState (): boolean {
        const chart = this.chart;

        if (!chart.HasCorrectData()) {
            return false;
        }

        const cashItemSeries = chart.MainCashItemSeries;

        if (isNullOrUndefined(cashItemSeries)) {
            return false;
        }

        return true;
    }

    private setPaddingBarsCount (needToLoadBars: number): void {
        const chart = this.chart;
        const cashItemSeries = chart.MainCashItemSeries;
        cashItemSeries.setPaddingBarsCount(needToLoadBars);

        const len = chart.Overlays.length;
        for (let i = 0; i < len; i++) {
            const ser = chart.Overlays[i].mainPriceRenderer.Series;
            if (!isNullOrUndefined(ser)) {
                ser.setPaddingBarsCount(needToLoadBars);
            }
        }
    }

    public processMouseDown (): void {
        if (!this.allowAutoLoad) {
            return;
        }

        this.wasDragged = false;
    }

    public processMouseUp (): boolean {
        if (!this.allowAutoLoad) {
            return false;
        }

        if (!this.wasDragged) {
            return false;
        }

        this.wasDragged = false;

        void this.loadHistory();

        return true;

        // if loading stop loading
        // load history
        // how many bars need to load?
        // insert empty bars into CashItemSeries
    }

    public update (): void {
        // check if need to addiditionaly load history
        // for example view was changed by zooming or resizing
        void this.loadHistory();
    }

    public processDragRight (newI1: number): void {
        if (!this.allowAutoLoad) {
            return;
        }

        if (!this.checkChartState()) { return; }
        this.setPaddingBarsCount(0);
    }

    public processDragLeft (newI1: number): void {
        if (!this.allowAutoLoad) {
            return;
        }

        if (!this.checkChartState()) { return; }

        this.wasDragged = true;
        const chart = this.chart;

        const possibleLoadBarsCount = Math.floor(chart.mainWindow.im() - newI1);
        const needToLoadBars = possibleLoadBarsCount;

        this.setPaddingBarsCount(needToLoadBars);
    }

    public async loadHistory (fromDate?: Date): Promise<void> {
        const cashItemSeries = this.chart.MainCashItemSeries;
        if (isNullOrUndefined(cashItemSeries) || this.chart.isMaxHistoryLoaded) {
            return;
        }

        const barsToLoad = cashItemSeries.paddingBarsCount;
        if (barsToLoad === 0 && isNullOrUndefined(fromDate)) {
            return;
        }

        if (this.isLoadingHistoryNow) {
            this.barsForNextLoading = barsToLoad;
            return;
        }

        const chart = this.chart;

        this.loadingStateChanged.Raise(AutoLoadingState.LoadinStarted);
        this.isLoadingHistoryNow = true;

        const triggerAction = !isNullOrUndefined(fromDate) ? ChartLoadTrigger.LoadingByAdditionalPeriod : ChartLoadTrigger.Default;
        const historyParams = chart.GetHistoryParams(null, null, triggerAction);

        if (!isNullOrUndefined(fromDate)) {
            historyParams.ToTime = new Date(this.chart.model.HistoryFrom.getTime() - 1);
            historyParams.FromTime = new Date(fromDate.getTime());
        } else {
            const propogatedRightBorder = cashItemSeries.RealLeftRelativeBorderForPaddingBars;
            const rightDate = new Date(propogatedRightBorder);
            // correct right date border, beacuse this border include existing bar
            const rightDateInMillis = rightDate.getTime();
            rightDate.setTime(rightDateInMillis - 1);
            historyParams.ToTime = rightDate;
            historyParams.count = barsToLoad;
        }

        const cashItemFromChart = cashItemSeries._cashItem;
        const paddingIntervals = await cashItemFromChart.ReloadHistoryCallback(historyParams, null);
        if (!this.isLoadingHistoryNow) {
            return;
        }

        cashItemFromChart.InsertPaddingHistory(paddingIntervals, historyParams);
        chart.LoadHistoryFinish(cashItemFromChart, true, triggerAction, historyParams);

        const downloadedBars = cashItemFromChart.lastLoadedBarsCount;
        this.updatePaddingBars(downloadedBars);
        chart.rightBarIndexIncludingEmptyBars += downloadedBars;
        chart.lastMouseDownI1 += downloadedBars;

        this.isLoadingHistoryNow = false;
        this.loadingStateChanged.Raise(AutoLoadingState.LoadinFinised);
        void this.loadHistory();
    }

    private updatePaddingBars (loadedBars: number): void {
        const additionalBarsForLoading = this.barsForNextLoading - loadedBars;
        this.barsForNextLoading = 0;
        if (additionalBarsForLoading < 1) {
            return;
        }

        this.setPaddingBarsCount(additionalBarsForLoading);
    }
}
