// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class YouTubeHTMLConfig {
    public videoId: string = '';
    public videoTitle: string = '';
    public videoImage: string = '';
    public videoCreateDate: string = '';
    public channelTitle: string = '';
    public channelImage: string = '';
    public isLIVE: boolean = false;
    public isStream: boolean = false;
    public streamStartDate: string = '';
    public streamEndDate: string = '';
    public streamHasBeenAlreadyEnded: boolean = false;
    public streamHasNotStartedYet: boolean = false;

    public serialize (): string {
        return JSON.stringify(this);
    }

    public static deserialize (json: string): YouTubeHTMLConfig {
        const obj = JSON.parse(json);
        const entry = new YouTubeHTMLConfig();
        entry.videoId = obj.videoId;
        entry.videoTitle = obj.videoTitle;
        entry.videoImage = obj.videoImage;
        entry.videoCreateDate = obj.videoCreateDate;
        entry.channelTitle = obj.channelTitle;
        entry.channelImage = obj.channelImage;
        entry.isLIVE = obj.isLIVE;
        entry.isStream = obj.isStream;
        entry.streamStartDate = obj.streamStartDate;
        entry.streamEndDate = obj.streamEndDate;
        entry.streamHasBeenAlreadyEnded = obj.streamHasBeenAlreadyEnded;
        entry.streamHasNotStartedYet = obj.streamHasNotStartedYet;

        return entry;
    }

    public getVideoCreateDate (): Date | null {
        let createDate: Date | null = null;
        if (!isNullOrUndefined(this.videoCreateDate) && !this.streamStartDate.isEmpty()) {
            createDate = new Date(this.videoCreateDate);
        }

        const startTime = this.getStreamStartDate();
        if (startTime !== null && createDate !== null) {
            const hours = startTime.getHours();
            const minutes = startTime.getMinutes();

            createDate = this.withAddedHours(createDate, hours);
            createDate = this.withAddedMinutes(createDate, minutes);
            return createDate;
        }

        return null;
    }

    private withAddedHours (date: Date, hours: number): Date {
        const newDate = new Date(date);
        newDate.setHours(newDate.getHours() + hours);
        return newDate;
    }

    private withAddedMinutes (date: Date, minutes: number): Date {
        const newDate = new Date(date);
        newDate.setMinutes(newDate.getMinutes() + minutes);
        return newDate;
    }

    public getStreamStartDate (): Date | null {
        if (!isNullOrUndefined(this.streamStartDate) && !this.streamStartDate.isEmpty()) {
            if (this.getStreamEndDate() !== null && this.streamHasNotStartedYet) {
                return new Date(Date.now() - (1 * 60 * 1000));
            }

            return new Date(this.streamStartDate);
        }
        return null;
    }

    public getStreamEndDate (): Date | null {
        if (!isNullOrUndefined(this.streamEndDate) && !this.streamStartDate.isEmpty()) {
            return new Date(this.streamEndDate);
        }
        return null;
    }

    public getStreamOnline (): boolean {
        const nowDate = new Date();
        const startDate = this.getStreamStartDate();
        const endDate = this.getStreamEndDate();

        if (startDate !== null && nowDate > startDate) {
            if (endDate !== null && nowDate > endDate) {
                return false;
            }
            return true;
        }
        return false;
    }

    public update (object: any): void {
        this.videoTitle = object.title;
        this.videoImage = object.thumbnail;
        this.videoCreateDate = object.publishedAt;
        this.channelImage = object.authorThumbnail;
        this.channelTitle = object.author;
        this.streamStartDate = object.streamStartDateTimeString;
        this.streamEndDate = object.streamEndDateTimeString;
        this.isLIVE = object.isLIVE;
        this.isStream = object.isStream;
        this.streamHasBeenAlreadyEnded = object.streamHasBeenAlreadyEnded;
        this.streamHasNotStartedYet = object.streamHasNotStartedYet;
    }
}
