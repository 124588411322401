// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectExternalLinkMessage } from '../../../Utils/DirectMessages/DirectExternalLinkMessage';
import { type BrandingToolParams } from './Branding/BrandingToolParams';
import { TradingCentralExtLink } from './TradingCentralExtLink';

export class ITChartAdvancedExtLink extends TradingCentralExtLink {
    public itAdvancedPrivateKey: string = '';

    // Override method
    update (brandingTool?: BrandingToolParams, extLinkMessage?: DirectExternalLinkMessage): void {
        super.update(brandingTool, extLinkMessage);
        if (brandingTool) {
            this.itAdvancedPrivateKey = brandingTool.privateKey ?? this.itAdvancedPrivateKey;
        }
    }
}
