// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OptionTradingStyle } from '../Utils/Instruments/OptionTradingStyle';
import { OperationType } from '../Utils/Trading/OperationType';
import { type Position } from './cache/Position';
import { MarginModes } from '../Utils/Enums/Constants';
import { MarginTypes } from '../Utils/Instruments/MarginTypes';

export class MarginCounterLocal {
    public static GetProfitLossWithUnsettledProfitProperties (position: Position, profit): any {
        if (profit == 0 || position?.Account == null || position.Instrument == null || position.Account.RiskPlan == null) {
            return profit;
        }

        const rp = position.Account.RiskPlan;

        if (!rp) {
            return profit;
        }

        const riskSettings = rp.GetRisksForInstrument(position.Instrument.GetInteriorID());

        if (riskSettings == null) {
            return profit;
        }

        const item = riskSettings.cache[position.ProductType];

        // Profit
        if (profit > 0) {
            if (item != null && item.CalculateMarginType !== null && (item.CalculateMarginType == MarginTypes.MARGIN_FULL || item.CalculateMarginType == MarginTypes.MARGIN_STOCK)) {
                return 0;
            }

            if (position.Instrument.OptionTradingStyle == OptionTradingStyle.Premium) {
                return position.BuySell == OperationType.Buy ? profit : 0;
            }

            if (position.Instrument.OptionTradingStyle == OptionTradingStyle.MarkToMarket) {
                if (position.BuySell == OperationType.Sell) {
                    return 0;
                } else {
                    if (item.MarginMode !== null && item.MarginMode == MarginModes.USE_PROFIT_LOSS) {
                        return profit;
                    } else {
                        return 0;
                    }
                }
            }

            if (item != null && item.MarginMode !== null && (item.MarginMode == MarginModes.USE_PROFIT_IN_MARGIN_AVAILABLE || item.MarginMode == MarginModes.USE_PROFIT_LOSS)) {
                return profit;
            }
        }
        // Loss
        else {
            if (position.Instrument.OptionTradingStyle == OptionTradingStyle.MarkToMarket) {
                return position.BuySell == OperationType.Sell ? profit : 0;
            } else {
                if (item != null && item.CalculateMarginType !== null) {
                    if (item.CalculateMarginType == MarginTypes.MARGIN_FULL) {
                        return position.BuySell == OperationType.Sell ? profit : 0;
                    }

                    if (item.CalculateMarginType == MarginTypes.MARGIN_STOCK) {
                        return 0;
                    }
                }

                if (position.Instrument.OptionTradingStyle == OptionTradingStyle.Premium) {
                    return position.BuySell == OperationType.Sell ? profit : 0;
                }
            }
            return profit; // #62561
        }

        return 0;
    }
}
