// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../../Localizations/Resources';
import { BrandingToolLinkGenerator } from './Branding/BrandingToolLinkGenerator';
import { BaseExtLink } from './BaseExtLink';
import { ResourcesManager } from '../../properties/ResourcesManager';

export class LinkTokenLangExtLink extends BaseExtLink {
    protected generateUrl (): void {
        const url = BrandingToolLinkGenerator.generateLinkTokenLangURL(this, ResourcesManager.linkTokenLangName());
        if (url) {
            this.url = url;
            if (this.updateValue) {
                this.updateValue(url);
            }
        }
    }
}
