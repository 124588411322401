// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OrderExecutor } from './OrderExecutor';

export class ClosePositionData {
    public positions: any;
    public quantityToClose: any;
    public closingOrderTifDict: any;
    public mutualPositionIDs: any;
    public groupedByClusterNodeClosePositionData: {};

    constructor (positionOrDict, quantityToClose, closingOrderTifDict, groupedByClusterNode = false, mutualPositionsIds?) {
        this.positions = OrderExecutor.convertToPositionsDictionary(positionOrDict);
        this.quantityToClose = quantityToClose;
        this.closingOrderTifDict = closingOrderTifDict;
        this.mutualPositionIDs = mutualPositionsIds;

        if (!groupedByClusterNode) {
            const dict = OrderExecutor.createClusterNodeDictionaryByOrders(this.positions);
            this.groupedByClusterNodeClosePositionData = {};
            for (const CN in dict) {
                this.groupedByClusterNodeClosePositionData[CN] = new ClosePositionData(dict[CN], quantityToClose, closingOrderTifDict, true, this.mutualPositionIDs);
            }
        }
    }
}
