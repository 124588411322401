// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type OrderEditBase } from '../cache/OrderParams/order-edit/OrderEditBase';

export class MultiplyPlaceOrderWrapperItem {
    public orderId: string = null;
    public orderEditBase: OrderEditBase = null;

    constructor (orderId: string, orderEditBase: OrderEditBase) {
        this.orderId = orderId;
        this.orderEditBase = orderEditBase;
    }
}
