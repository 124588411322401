// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ControlsTypes } from '../../../Controls/UtilsClasses/FactoryConstants';
import { TerceraNumeric } from '../../../Controls/elements/TerceraNumeric';
import { InvestingNumericTemplate } from '../../templates.js';

export class InvestingNumeric extends TerceraNumeric {
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.InvestingNumeric; }

    public override oninit (): void {
        super.oninit();

        this.on('arrowDownButtonClick', this.arrowDownButtonClick);

        this.observe('dpData', this.applyDPValues);
    }

    public arrowDownButtonClick (ev, posX, posY): void {
        void this.set({ activeArrow: true });
        this.fire('openQuantityMenuEvent', this, posX, posY);
    }

    public override showPopupError (errorTextForNumeric): void {
    // if (!this.get('enabled') || !this.get('focused') || !errorTextForNumeric)
    //     return

    // popupErrorHandler.Show(this, errorTextForNumeric, '')
    }

    public applyDPValues (dp, o): void {
        if (isNullOrUndefined(dp)) { return; }

        void this.set({
            decimalPrecision: dp.decimalPlaces,
            minValue: dp.minimalValue,
            maxValue: dp.maximalValue,
            step: dp.increment,
            value: dp.value,
            enabled: dp.enabled,
            customError: dp.error,
            Intervals: dp.Intervals
        });
    }
}

TerceraNumeric.extendWith(InvestingNumeric, {
    el: 'numeric',
    template: InvestingNumericTemplate,
    data: function () {
        return {
            name: 'num',
            minValue: 1, // Минимальное значение
            maxValue: 100000, // Максимальное значение
            step: 1, // Шаг изменения

            // из-за внедрения интервалов возникла необходимость ввести коэффициент,
            // с помощью которого мы будем откладывать количество интервалов (количество тиков)
            incrementCoefficient: 1,

            decimalPrecision: 0, // Количество знаков после decimalseparator
            value: 0, // реальное значение
            formattedValue: '', // видимое значения
            defaultValue: 0.000001,
            showArrows: true,
            tradingNumeric: false,
            hasError: false,
            errorText: '',
            customError: null,
            validationStateInfo: null,
            Intervals: [],
            zIndex: 0,
            allowMinus: true,
            valueLessMinLocalKey: 'UserControl.TerceraNumeric.ValueLessMin',
            valueGreaterMaxLocalKey: 'UserControl.TerceraNumeric.ValueGreaterMax',
            DynPropertyData: {},
            onlyMouseWheel: true,
            dpData: null
        };
    }
});
