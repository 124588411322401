// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TvInteriorIdCache } from './TvInteriorIdCache';
import { DataCache } from '../../DataCache';
import { type Account } from '../../cache/Account';
import { type Instrument } from '../../cache/Instrument';
import { type IBrokerConnectionAdapterHost, type PipValues } from '../charting_library';

class _TvPipsValuesCache {
    private readonly subscibtionMap = new Map<string, PipValuesInfoContainer>();

    public subscribePipValue (symbol: string): void {
        const interiorId = TvInteriorIdCache.getInteriorId(symbol);
        const instrument = DataCache.getInstrumentByName(interiorId);

        if (isNullOrUndefined(instrument)) return;

        if (!this.subscibtionMap.has(symbol)) {
            const container = new PipValuesInfoContainer(instrument);
            this.subscibtionMap.set(symbol, container);
        }
    }

    public unsubscribePipValue (symbol: string): void {
        if (this.subscibtionMap.has(symbol)) {
            this.subscibtionMap.delete(symbol);
        }
    }

    public updateSubscribedPipValues (host: IBrokerConnectionAdapterHost, account: Account): void {
        this.subscibtionMap.forEach((value, key) => {
            const instrument = value.instrument;
            const pipSize = instrument.PipsSize;
            const crossPriceToAccountCurrency = DataCache.CrossRateCache.GetCrossPriceExp1Exp2(instrument.Exp2, account.BaseCurrency);
            const ticCost = instrument.GetTickCost();
            const pipValue = pipSize * ticCost * crossPriceToAccountCurrency;
            // const prevPipValue = value.prevPipValue;
            // if (pipValue !== prevPipValue) {
            const pipValues: PipValues = {
                buyPipValue: pipValue,
                sellPipValue: pipValue
            };
            host.pipValueUpdate(key, pipValues);
            // }
            // value.prevPipValue = pipValue;
        });
    }
}

class PipValuesInfoContainer {
    public prevPipValue: number = 0;
    public instrument: Instrument;

    constructor (instrument: Instrument) {
        this.instrument = instrument;
    }
}

export const TvPipsValuesCache = new _TvPipsValuesCache();
