// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectSessionSwitchMessage extends DirectMessageBaseClass {
    public SessionId = -1;
    public TradeSessionStatusId = -1;
    public ExchangeSessionName: string | null = null;

    constructor () {
        super(Message.CODE_PFIX_TRADE_SESSION_SWITCH);
    }
}
