// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type EMamManagementFeeType } from '../Enums/EMamManagementFeeType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectMamGroupMessage extends DirectMessageBaseClass {
    public mamGroupID: string;
    public userID: string;
    public assetID: number;
    public counterAccountID: string;
    public activeateMamFee: boolean;
    public subAccounts: MamSubAccount[] = [];

    constructor () {
        super(Message.CODE_PFIX_MAM_GROUP_MESSAGE);
    }
}

export class MamSubAccount {
    public accountNumb: string;
    public EMamManagementFeeType: EMamManagementFeeType;
    public managementFee: number;
    public performanceFee: number;
    public feeAssetID: number;
}
