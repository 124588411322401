// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { IndexedDB, type IndexedDBConfig } from './IndexedDB';

export enum UserIndexedDBStore {
    WD_SETTINGS = 'WDSettings',
    WM_SETTINGS = 'WMSettings',
    WL_SHEETS = 'WLSheets',
    EVENT_LOG = 'EventLog'
  }

export enum UserIndexedDBKey {
    USER_ID = 'userId',
    EVENT_ID = 'eventId',
    TIMESTAMP = 'timestamp'
  }

const config: IndexedDBConfig = {
    dbName: 'UserDB',
    version: 1, // In the future need increase version for migration on a new scheme.
    stores: [
        {
            storeName: UserIndexedDBStore.WD_SETTINGS,
            keyPath: UserIndexedDBKey.USER_ID,
            autoIncrement: false
        },
        {
            storeName: UserIndexedDBStore.WM_SETTINGS,
            keyPath: UserIndexedDBKey.USER_ID,
            autoIncrement: false
        },
        {
            storeName: UserIndexedDBStore.WL_SHEETS,
            keyPath: UserIndexedDBKey.USER_ID,
            autoIncrement: false
        },
        {
            storeName: UserIndexedDBStore.EVENT_LOG,
            keyPath: UserIndexedDBKey.EVENT_ID,
            autoIncrement: true,
            indexes: [
                { name: UserIndexedDBKey.USER_ID, keyPath: UserIndexedDBKey.USER_ID, options: { unique: false } },
                { name: UserIndexedDBKey.TIMESTAMP, keyPath: UserIndexedDBKey.TIMESTAMP, options: { unique: false } }
            ]
        }
    ]
};

export const UserIndexedDB = new IndexedDB(config);
