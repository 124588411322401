// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PingMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_PING_MESSAGE, fieldSet);
    }
}
