// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DayType } from './DayType';
import { type SessionPeriod } from './SessionPeriod';

export class HolidayDescription {
    public name: string | null;
    public key: string | null;
    public holidayType: number;
    public day: number;
    public month: number;
    public year: number;
    public sessionPeriods: SessionPeriod[] | null = null;

    public static createHolidayKey (year: number, month: number, day: number): string {
        const formattedYear = String(year).padStart(4, '0');
        const formattedMonth = String(month).padStart(2, '0');
        const formattedDay = String(day).padStart(2, '0');
        return `${formattedYear}-${formattedMonth}-${formattedDay}`;
    }

    public getDayOfWeek (): number {
        const date = new Date(this.year, this.month - 1, this.day);
        return date.getDay();
    }

    public HasPeriods (): boolean {
        return !isNullOrUndefined(this.sessionPeriods) &&
        this.sessionPeriods.length > 0;
    }

    public GetFormattedDate (): string {
        const formattedDay = String(this.day).padStart(2, '0');
        const formattedMonth = String(this.month).padStart(2, '0');
        const formattedYear = String(this.year).padStart(4, '0');
        return `${formattedDay}.${formattedMonth}.${formattedYear}`;
    }

    public GetHolidayPeriods () { return this.sessionPeriods; };
    public IsNotWorking (): boolean { return this.holidayType === DayType.NOT_WORKING; };
    public IsShorted () { return this.holidayType === DayType.SHORTED; };
    public IsWorking () { return !this.IsNotWorking(); }; // may be usefull
}
