// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ControlsUtils } from '../../UtilsClasses/ControlsUtils';

export class QuickTableUtils {
    public static DrawTextInRect (context, text: string, x: number, y: number, width?: number, height?: number, xPadding = 0, yPadding = 0, truncateTextOverflow = false, cellBackColor?): void {
        if (width < text.length * 6.2 + xPadding + 8) {
            context.textAlign = 'start';
        }

        if (context.textAlign === 'start') {
            context.fillText(text, x + xPadding, y + yPadding + 3);
        } else if (context.textAlign === 'end') {
            context.fillText(text, x + width - xPadding - 8, y + yPadding + 3);
        } else if (context.textAlign === 'center') {
            context.fillText(text, x + width / 2, y + yPadding + 3);
        }

        if (truncateTextOverflow && QuickTableUtils.GetWidth(context, text) > width) {
            context.TruncateWithEllipsis(x, y, width, height, xPadding, yPadding, cellBackColor);
        }
    }

    public static GetTextStartX (context, text: string, x: number, y: number, width: number, height: number, xPadding = 0, yPadding = 0): number {
    // if (width < text.length / 5)
    //     text = text.slice(0, width / 5);

        if (width < text.length * 6.2 + xPadding + 8) {
            context.textAlign = 'start';
        }

        const textW = QuickTableUtils.GetWidth(context, text);

        if (context.textAlign === 'start') {
            return x + xPadding - textW;
        } else if (context.textAlign === 'end') {
            return x + width - xPadding - 8 - textW;
        }
    }

    public static GetWidth (context, text: string): number {
        return ControlsUtils.GetTextWidth(text, context.font);
    }
}
