// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { BaseItem, ColumnData } from '../BaseItem';
import { ColumnParams } from '../ColumnParams';
import { QuickTableColumnType } from '../../elements/QuickTable/QuickTableMisc';
import { QuickTableComparingType } from '../../../Utils/QuickTableMisc/QuickTableComparingType';

export class OptionChainStrikesListTableItem extends BaseItem {
    public readonly strike: number;
    private readonly _strikeStr: string;

    constructor (strike: number) {
        super(null);
        this.strike = strike;
        this._strikeStr = strike.toString();
    }

    public GetItemId (): string {
        return this._strikeStr;
    }

    public getColumnValue (column: number): any {
        switch (column) {
        case OptionChainStrikesListTableItem.STRIKE_COL_INDEX:
            return this.strike;
        default:
            return undefined;
        }
    }

    public getColumnData (column: number): ColumnData {
        const value: any = this.getColumnValue(column);
        return new ColumnData(value, value.toString());
    }

    // ITableItem methods
    public ColumnCount (): number {
        return OptionChainStrikesListTableItem.columnsParams.length;
    }

    public GetColumnParams (i: number): any {
        return OptionChainStrikesListTableItem.columnsParams[i];
    }

    public GetDataType (columnIndex: number): number {
        return OptionChainStrikesListTableItem.dataTypes[columnIndex];
    }
    // ITableItem methods end

    // #region static table parame
    static readonly STRIKE_COL_INDEX = 0;

    static columnsParams: ColumnParams[] =
        [
            new ColumnParams('panel.optionChain.strike', 100, QuickTableColumnType.COL_DEFAULT, false, true, false)
        ];

    private static readonly dataTypes = [
        QuickTableComparingType.String
    ];

    // #endregion
}
