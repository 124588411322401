// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class MarginPriceBasedMultipleLeverage extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.MARGIN_PRICE_BASED_MULTIPLE_LEVERAGE, null, pfixFieldSet);
    }

    public getLeverage (): number {
        return this.GetValue(FieldsFactory.FIELD_LEVERAGE_SHORT);
    }

    public static getLeverages (groups): number[] {
        const result: number[] = [];

        if (groups) {
            for (let i = 0; i < groups.length; i++) {
                const group = new MarginPriceBasedMultipleLeverage(groups[i]);
                if (group) {
                    result.push(group.getLeverage());
                }
            }
        }

        return result.sort((a, b) => a - b); // leverage сортируем по возрастанию #113809
    }
}
