// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { CreateMapByObj } from '../CreateMapByObjectUtils';

export enum OrderTif {
    Day = 1,
    GTC = 2,
    IOC = 3,
    GTD = 4,
    FOK = 5,
    OnOpen = 6,
    OnClose = 7,
    GTS = 9
}

// для мапинга ключей локализации
export const OrderTifMap = CreateMapByObj(OrderTif);

export function sortTifs (tifs): OrderTif[] {
    const sortedTifs: OrderTif[] = [];
    const sortedTifList = [OrderTif.Day, OrderTif.GTC, OrderTif.IOC, OrderTif.FOK, OrderTif.GTD, OrderTif.OnOpen, OrderTif.OnClose, OrderTif.GTS];
    for (let i = 0; i < sortedTifList.length; i++) {
        const index = tifs.indexOf(sortedTifList[i]);
        if (index !== -1) {
            sortedTifs.push(sortedTifList[i]);
        }
    }
    return sortedTifs;
}
