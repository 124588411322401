// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectBMCounterMessage extends DirectMessageBaseClass {
    public UnreadIDs: any;
    constructor () {
        super(Message.BM_COUNTER_MESSAGE);
        this.UnreadIDs = null;
    }
}
