// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DrawPointer, DrawPointerTypeEnum } from '../Utils/DrawPointer';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { type TerceraChartBase } from '../TerceraChartBase';

export class TerceraChartOverlayStorageRenderer extends TerceraChartBaseRenderer {
    OverlaysWindows: any[];

    _UseInAutoscale = true;
    public get UseInAutoscale (): boolean {
        return true;
    }

    public set UseInAutoscale (value: boolean) {
        // this._UseInAutoscale = value;
    }

    constructor (chart: TerceraChartBase) {
        super(chart);

        this.OverlaysWindows = [];
        this.SetClassName('TerceraChartOverlayStorageRenderer');
    }

    IsNeedDraw (numberOfLayer: number) {
        return this.assignLayer === numberOfLayer || LayersEnum.Quotes === numberOfLayer;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null) {
        const adv = advParams;
        const chart = adv.TerceraChart;
        const mainWindow = chart.mainWindow;

        const pointersToMainScale = [];
        const OverlaysWindows = this.OverlaysWindows;

        for (let i = 0; i < OverlaysWindows.length; i++) {
            const workOverlay = OverlaysWindows[i];

            if (workOverlay.Overlay.SeparateScaleGet()) {

            } else {
                // Use params from the main symbol
                workOverlay.MoveY(mainWindow.FminFloatY, mainWindow.FmaxFloatY);
                workOverlay.Overlay.cashItemSeriesSettings.DataType = chart.cashItemSeriesSettings.DataType;
            }

            // одни настройки на все шкалы
            workOverlay.Overlay.cashItemSeriesSettings.BasisType = chart.cashItemSeriesSettings.BasisType;

            // Replace to actual overlay data
            let overlayCopy: any = null;

            if (advParams) {
                overlayCopy = { ...advParams };
                // overlayCopy = jQuery.extend(true, {}, advParams);// { ...advParams };
                overlayCopy.Instrument = workOverlay.Overlay.ActiveInstrument;
                overlayCopy.mainPriceRenderer = workOverlay.Overlay.mainPriceRenderer;
            }

            // рисование маркера на активном оверлее, или если оверлей на основной шкале
            if (workOverlay.Overlay.ShowMarker && overlayCopy?.Instrument !== null &&
                (workOverlay.Overlay === workOverlay.Overlay.chart.ActiveOverlayGet() || !workOverlay.Overlay.SeparateScaleGet())
            ) {
                const cashItemSeries = workOverlay.Overlay.mainPriceRenderer.Series;

                if (cashItemSeries) {
                    const ci = cashItemSeries.CashItem;
                    const ciA = ci.FNonEmptyCashArray;
                    const ciAC = ci.FNonEmptyCashArrayCount - 1;

                    if (ciAC >= 0) {
                        let value = ciA[ciAC].Close;
                        let text = overlayCopy.Instrument.formatPrice(value);
                        const dataType = cashItemSeries !== null ? cashItemSeries.settings.DataType : TerceraChartCashItemSeriesDataType.Absolute;

                        switch (dataType) {
                        case TerceraChartCashItemSeriesDataType.Relative:
                            value = cashItemSeries.settings.relativeDataConverter.Calculate(value);
                            text = `${value}%`;
                            break;
                        case TerceraChartCashItemSeriesDataType.Log:
                            value = cashItemSeries.settings.logDataConverter.Calculate(value);
                            break;
                        }

                        if (advParams.layerId === LayersEnum.Quotes) {
                            if (workOverlay.Overlay.SeparateScaleGet()) {
                                overlayCopy.DrawPointers.push(
                                    new DrawPointer(DrawPointerTypeEnum.Overlay, value, workOverlay.Overlay.MarkerBrush, text)
                                );
                            } else if (advParams) {
                                advParams.DrawPointers.push(
                                    new DrawPointer(DrawPointerTypeEnum.Overlay, value, workOverlay.Overlay.MarkerBrush, text)
                                );
                            }
                        }
                    }
                }
            }

            workOverlay.Draw(gr, chart.windowsContainer, overlayCopy);
        }
    }

    CalculateMinMax () {
        for (let i = 0; i < this.OverlaysWindows.length; i++) {
            this.OverlaysWindows[i].CalculateMinMax();
        }
    }

    ProcessMouseMove (e: any) {
        let res = super.ProcessMouseMove(e);

        // Overlays?
        if (!res) {
            for (let i = 0; i < this.OverlaysWindows.length; i++) {
                for (let j = 0; j < this.OverlaysWindows[i].Renderers.length; j++) {
                    res = this.OverlaysWindows[i].Renderers[j].ProcessMouseMove(e);
                    if (res) {
                        return res;
                    }
                }
            }
        }

        return res;
    }

    ProcessMouseDown (e: any) {
        let res = super.ProcessMouseDown(e);

        // Overlays?
        if (!res) {
            for (let i = 0; i < this.OverlaysWindows.length; i++) {
                for (let j = 0; j < this.OverlaysWindows[i].Renderers.length; j++) {
                    res = this.OverlaysWindows[i].Renderers[j].ProcessMouseDown(e);
                    if (res) {
                        return res;
                    }
                }
            }
        }

        return res;
    }

    ProcessMouseUp (e: any) {
    // Base processing
        let res = super.ProcessMouseUp(e);

        // Overlays
        if (!res) {
            for (let i = 0; i < this.OverlaysWindows.length; i++) {
                for (let j = 0; j < this.OverlaysWindows[i].Renderers.length; j++) {
                    res = this.OverlaysWindows[i].Renderers[j].ProcessMouseUp(e);
                    if (res) {
                        return res;
                    }
                }
            }
        }

        return res;
    }

    ProcessMouseEnter (e: any) {
    // Base processing
        super.ProcessMouseEnter(e);

        // Overlays
        for (let i = 0; i < this.OverlaysWindows.length; i++) {
            for (let j = 0; j < this.OverlaysWindows[i].Renderers.length; j++) {
                this.OverlaysWindows[i].Renderers[j].ProcessMouseEnter(e);
            }
        }
    }

    ProcessMouseLeave (e: any) {
    // Base processing
        super.ProcessMouseLeave(e);

        // Overlays
        for (let i = 0; i < this.OverlaysWindows.length; i++) {
            for (let j = 0; j < this.OverlaysWindows[i].Renderers.length; j++) {
                this.OverlaysWindows[i].Renderers[j].ProcessMouseLeave(e);
            }
        }
    }

    GetContextMenu (e: any, chart: any) {
        let contextMenu = super.GetContextMenu(e, chart);

        if (contextMenu == null) {
            for (let i = 0; i < this.OverlaysWindows.length; i++) {
                for (let j = 0; j < this.OverlaysWindows[i].Renderers.length; j++) {
                    contextMenu = this.OverlaysWindows[i].Renderers[j].GetContextMenu(e, chart);
                    if (contextMenu != null) {
                        return contextMenu;
                    }
                }
            }
        }

        return contextMenu;
    }

    LayoutWindows () {
    // Layout overlays
        for (let i = 0; i < this.OverlaysWindows.length; i++) {
            this.OverlaysWindows[i].Rectangle = this.chart.mainWindow.Rectangle;
            this.OverlaysWindows[i].OnLayout();
        }
    }

    ThemeChanged () {
        super.ThemeChanged();
        for (let i = 0; i < this.OverlaysWindows.length; i++) {
            this.OverlaysWindows[i].ThemeChanged();
        }
    }

    Dispose () {
        this.chart = null;
        super.Dispose();
    }
}
