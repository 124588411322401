// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class MultiEMA extends IndicatorScriptBase {
    public MAPeriod_1: number;
    public SourcePrices_1: number;
    public MAPeriod_2: number;
    public SourcePrices_2: number;
    public MAPeriod_3: number;
    public SourcePrices_3: number;
    public MAPeriod_4: number;
    public SourcePrices_4: number;
    public MAPeriod_5: number;
    public SourcePrices_5: number;

    private minRequiredBars: number;
    private ema1: iBuildInIndicator;
    private ema2: iBuildInIndicator;
    private ema3: iBuildInIndicator;
    private ema4: iBuildInIndicator;
    private ema5: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'Multi EMA';
        this.SetIndicatorLine('EMA 1', Color.Lime, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('EMA 2', Color.Blue, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('EMA 3', Color.Red, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('EMA 4', Color.Gray, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('EMA 5', Color.Gray, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.MAPeriod_1 = 25;
        super.InputParameter(new InputParameterInteger('MAPeriod_1', 'Period EMA 1', 0, 1, 9999));

        this.SourcePrices_1 = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrices_1', 'Sources prices EMA 1', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAPeriod_2 = 75;
        super.InputParameter(new InputParameterInteger('MAPeriod_2', 'Period EMA 2', 2, 1, 9999));

        this.SourcePrices_2 = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrices_2', 'Sources prices EMA 2', 3,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAPeriod_3 = 200;
        super.InputParameter(new InputParameterInteger('MAPeriod_3', 'Period EMA 3', 4, 1, 9999));

        this.SourcePrices_3 = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrices_3', 'Sources prices EMA 3', 5,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAPeriod_4 = 10;
        super.InputParameter(new InputParameterInteger('MAPeriod_4', 'Period EMA 4', 6, 1, 9999));

        this.SourcePrices_4 = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrices_4', 'Sources prices EMA 4', 7,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAPeriod_5 = 300;
        super.InputParameter(new InputParameterInteger('MAPeriod_5', 'Period EMA 5', 8, 1, 9999));

        this.SourcePrices_5 = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrices_5', 'Sources prices EMA 5', 9,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.minRequiredBars = 0;
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.minRequiredBars = Math.max(this.MAPeriod_1, Math.max(this.MAPeriod_2, Math.max(this.MAPeriod_3, Math.max(this.MAPeriod_4, this.MAPeriod_5))));
    }

    public override GetIndicatorShortName (): string {
        return 'EMAs(' + this.MAPeriod_1 + ';' + this.MAPeriod_2 + ';' + this.MAPeriod_3 + ';' + this.MAPeriod_4 + ';' + this.MAPeriod_5 + ')';
    }

    public override getCustomName (): string {
        return 'EMAs (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.minRequiredBars = Math.max(this.MAPeriod_1, Math.max(this.MAPeriod_2, Math.max(this.MAPeriod_3, Math.max(this.MAPeriod_4, this.MAPeriod_5))));
        this.ema1 = this.Indicators.iMA(this.CurrentData, this.MAPeriod_1, MAMode.EMA, 0, this.SourcePrices_1);
        this.ema2 = this.Indicators.iMA(this.CurrentData, this.MAPeriod_2, MAMode.EMA, 0, this.SourcePrices_2);
        this.ema3 = this.Indicators.iMA(this.CurrentData, this.MAPeriod_3, MAMode.EMA, 0, this.SourcePrices_3);
        this.ema4 = this.Indicators.iMA(this.CurrentData, this.MAPeriod_4, MAMode.EMA, 0, this.SourcePrices_4);
        this.ema5 = this.Indicators.iMA(this.CurrentData, this.MAPeriod_5, MAMode.EMA, 0, this.SourcePrices_5);
        return [this.ema1, this.ema2, this.ema3, this.ema4, this.ema5];
    }

    public NextBar (): void {
        super.NextBar();
    }

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count < this.minRequiredBars) {

        } else {
            this.SetValue(0, this.ema1.GetValue());
            this.SetValue(1, this.ema2.GetValue());
            this.SetValue(2, this.ema3.GetValue());
            this.SetValue(3, this.ema4.GetValue());
            this.SetValue(4, this.ema5.GetValue());
        }
    }
}
