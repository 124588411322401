// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { CloseAccountResponseStatus } from '../Enums/Constants';
import { DirectMessageBaseClass as DirectMessageBase } from './DirectMessageBase';
import { Message } from './Message';

export class DirectCloseAccountResponseMessage extends DirectMessageBase {
    private _status: CloseAccountStatus;
    private _closeAccountRequestID: number;

    constructor () {
        super(Message.CODE_PFIX_CLOSE_ACCOUNT_RESPONSE_MESSAGE);
        this._status = CloseAccountResponseStatus.New;
        this._closeAccountRequestID = -1;
    }

    get status (): CloseAccountStatus {
        return this._status;
    }

    set status (newStatus: CloseAccountStatus) {
        this._status = newStatus;
    }

    get closeAccountRequestID (): number {
        return this._closeAccountRequestID;
    }

    set closeAccountRequestID (newValue: number) {
        this._closeAccountRequestID = newValue;
    }
}

export type CloseAccountStatus = typeof CloseAccountResponseStatus[keyof typeof CloseAccountResponseStatus];
