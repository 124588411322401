// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../DataCache';
import { type KeyCode, KeyEventProcessor } from '../KeyEventProcessor';
import { type Idea } from '../cache/Idea';
import { EventEmitter } from 'events';

export class IdeasSideBarController {
    private readonly _eventEmitter: EventEmitter;

    constructor () {
        this._eventEmitter = new EventEmitter();
    }

    public subscribe (): void {
        DataCache.OnTradingIdeaAdd.Subscribe(this.addIdea, this);
        DataCache.OnTradingIdeaCancel.Subscribe(this.cancelIdea, this);
        DataCache.OnTradingSystemsChange.Subscribe(this.systemsChange, this);
        DataCache.OnTradingSystemUnsubscribe.Subscribe(this.systemUnsubscribe, this);
    }

    public unsubscribe (): void {
        DataCache.OnTradingIdeaAdd.UnSubscribe(this.addIdea, this);
        DataCache.OnTradingIdeaCancel.UnSubscribe(this.cancelIdea, this);
        DataCache.OnTradingSystemsChange.UnSubscribe(this.systemsChange, this);
        DataCache.OnTradingSystemUnsubscribe.UnSubscribe(this.systemUnsubscribe, this);
    }

    // subscribe
    public subscribeToAdd (callback: (businessObject: Idea) => void): void { this._eventEmitter.on('add', callback); }
    public subscribeToCancel (callback: (businessObject: Idea) => void): void { this._eventEmitter.on('cancel', callback); }
    public subscribeToSystemsChange (callback: (businessObject: number) => void): void { this._eventEmitter.on('change', callback); }
    public subscribeToSystemUnsubscribe (callback: (businessObject: number) => void): void { this._eventEmitter.on('unsubscribe', callback); }

    // unsubscribe
    public unsubscribeToAdd (callback: (businessObject: Idea) => void): void { this._eventEmitter.off('add', callback); }
    public unsubscribeToCancel (callback: (businessObject: Idea) => void): void { this._eventEmitter.off('cancel', callback); }
    public unsubscribeToSystemsChange (callback: (businessObject: number) => void): void { this._eventEmitter.off('change', callback); }
    public unsubscribeToSystemUnsubscribe (callback: (businessObject: number) => void): void { this._eventEmitter.off('unsubscribe', callback); }

    private addIdea (idea: Idea): void { this._eventEmitter.emit('add', idea); }
    private cancelIdea (idea: Idea): void { this._eventEmitter.emit('cancel', idea); }
    private systemsChange (systemID: number): void { this._eventEmitter.emit('change', systemID); }
    private systemUnsubscribe (systemID: number): void { this._eventEmitter.emit('unsubscribe', systemID); }
}
