// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixBooleanField } from '../Fields/PFixBooleanField';
import { PFixByteField } from '../Fields/PFixByteField';
import { PFixDateField } from '../Fields/PFixDateField';
import { PFixDoubleField } from '../Fields/PFixDoubleField';
import { PFixFloatField } from '../Fields/PFixFloatField';
import { PFixGroupField } from '../Fields/PFixGroupField';
import { PFixIntField } from '../Fields/PFixIntField';
import { PFixLongField } from '../Fields/PFixLongField';
import { PFixShortField } from '../Fields/PFixShortField';
import { PFixStringField } from '../Fields/PFixStringField';
import { PFixPFSMessageField } from '../Fields/PFixPFSMessageField';

import { PFixPublicKeyField } from '../Fields/PFixPublicKeyField';
import { PFixSignatureField } from '../Fields/PFixSignatureField';

import { PFixByteArrayField } from '../Fields/PFixByteArrayField';
import { PFixLongGroupField } from '../Fields/PFixLongGroupField';
import { PFixObjectField } from '../Fields/PFixObjectField';
import { PFixDerivativeContractDateField } from '../Fields/PFixDerivativeContractDateField';
import { PFixCompressedByteArrayField } from '../Fields/PFixCompressedByteArrayField';
import { PFixIntArrayField } from '../Fields/PFixIntArrayField';
import { PFixStringArrField } from '../Fields/PFixStringArrField';
import { PFixLongArrayField } from '../Fields/PFixLongArrayField';
import { Message } from '@shared/utils/DirectMessages/Message';
import { type PFixField } from '../Fields/PFixField';

export class FieldsFactory {
    public static FFieldNamesList;
    public static FGroupTypesList;

    public FieldFactoryList = [];
    public MaxFieldNumber: number;

    constructor () {
        // #region  FieldFactoryList
        this.FieldFactoryList[FieldsFactory.FIELD_MSG_TYPE] = this.GetPFixShortField;
        this.FieldFactoryList[FieldsFactory.FIELD_XML_REQUEST] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_PFS_MESSAGE] = this.GetPFixPFSMessageField;// pfsmessage
        this.FieldFactoryList[FieldsFactory.FIELD_LOGIN] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_PASSWORD] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSIONID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_AMOUNT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_AMOUNT_LONG] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_BALANCE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_CLOSEORDER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPENORDER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_NUMBER] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_BOUNDTOORDERID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_NONE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMENT] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_REQUEST_ID] = this.GetPFixIntField;

        this.FieldFactoryList[FieldsFactory.FIELD_LOTSIZE] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_POINTSIZE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_NAME] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_NAME_EXP1] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_NAME_EXP2] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENTTYPE_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_MODE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_DESCRIPTION] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_BARS_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_NUMBER_OF_SHARES] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_BEGIN_DAY_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_END_DAY_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_ROUTE_LIST] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_ROUTE_LIST] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_MARGIN_TYPE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_BASE_LEVERAGE_NEG] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_BASE_LEVERAGE_POS] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_COUNTER_LEVERAGE_NEG] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_COUNTER_LEVERAGE_POS] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HOLD_MARGINGSIZE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HOLD_MARGINGSIZE10] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HOLD_MARGINGSIZE25] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HOLD_MARGINGSIZE50] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_INIT_MARGINGSIZE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_INIT_MARGINGSIZE10] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_INIT_MARGINGSIZE25] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_INIT_MARGINGSIZE50] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_USE_LEVERAGE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_MARGIN_COEF] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SWAP_BUY] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SWAP_SELL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SWAP_MEASURE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_BASE_INSTRUMENT_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_FORMULA_TYPE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_STRIKE_INCREMENT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_STRIKE_NUMBE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_FIRST_BASE_STRIKE_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_START_TIME] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXPIRATION_PERIOD_NUM] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXPIRATION_PERIOD_DURATION] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXP_DATES] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_STRIKE_PRICES] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_MARGIN_MODE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_SPREAD_MODE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_SPREAD_MEASURE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_SPREAD] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_BIDSHIFT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ASKSHIFT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_HEDGED_MARGIN_SIZE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_BEGIN_PRE_SES_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_END_POST_SES_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_OPTIONS] = this.GetPFixBooleanField;

        this.FieldFactoryList[FieldsFactory.FIELD_GROUP] = this.GetPFixGroupField;

        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPERATION_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_UNFIXED_LIST] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_BY_BROKER] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_VALIDITY] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXP_DAY] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXP_MONTH] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXP_YEAR] = this.GetPFixShortField;
        this.FieldFactoryList[FieldsFactory.FIELD_STRIKE_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPTION_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_ROUTE_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_SL_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_TP_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_TR_OFFSET] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACCOUNT_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_USER_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_CLIENT_ORDER_ID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_SEQUANCE_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_ACTION_TYPE] = this.GetPFixByteField;

        this.FieldFactoryList[FieldsFactory.FIELD_PROTOCOL_ID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_VERIFICATION_PASSWORD] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_DATE_OF_BUILD] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_CONNECTION_MODE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_CLIENT_TYPE] = this.GetPFixIntField;

        this.FieldFactoryList[FieldsFactory.FIELD_FIRSTNAME] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_LASTNAME] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_MIDDLENAME] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_STREETADDRESS] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_CITY] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_COUNTRY] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_ZIP] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_STATE] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACCOUNTS] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_NEED_VERIFICATION_PWD] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_PWD_EXPIRED] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_TIME_ZONE_OFFSET] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_SUBSCRIPTION_TYPE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_SUBSCRIPTION_ACTION] = this.GetPFixByteField;

        this.FieldFactoryList[FieldsFactory.FIELD_SENDER_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TARGET_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TEXT] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_THEME] = this.GetPFixStringField;

        this.FieldFactoryList[FieldsFactory.FIELD_SOURCE] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_LINE_NUMBER] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_NEWS_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_SYMBOL] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_PRIORITY] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_UPPRCASE_SEARCH] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_CONTENT_SEARCH] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_EVENT_SEARCH] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_LIGHT_SUBS] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_POSITION_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_CREATED_AT] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACTIVE_FLAG] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_FILLED_AMOUNT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_AVERAGE_FILLED_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_GROUP_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_MASTER_ORDER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_STOP_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_REFFERANCE_ID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_STATUS] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPEN_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_CLOSE_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SWAP] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SL_ORDER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_TP_ORDER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_CLOSE_TIME] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISION] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_TRAILING] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_ORDER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_PNL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXT_ID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_CREATOR_NAME] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_BUY] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXCHANGE] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_OPEN] = this.GetPFixBooleanField;

        this.FieldFactoryList[FieldsFactory.FIELD_RANGE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_XML_BODY] = this.GetPFixStringField;

        this.FieldFactoryList[FieldsFactory.FIELD_SERVER_IDS] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_HOLIDAYS] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_DAY] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_GTC] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_GTD] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_IOC] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_MARKET] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_MOC] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_STOP] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_LIMIT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_STOP_TO_LIMIT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_TRAILING_STOP] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_TRADE_STATUS] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISION_PLAN_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_MOO] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_MARGINCALL] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_UNSECURE_DEVICE_ACCEPTED] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_GROUP_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_LEVRAGE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_CURRENCY] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_CROSS_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_CROSS_INSTRUMENT_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_BLOCKED_SUM] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MIN_CHANGE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_LEVEL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_WARNING_LEVEL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MARGIN_LEVEL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_BROCKER_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_BEGIN_BALANCE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_LOCKED_FOR_ORDERS] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMUNITY] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_SUBSCRIBE_PLAN_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_BID] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ASK] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_BID_SIZE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_ASK_SIZE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPEN] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HIGH] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_LOW] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_PRE_CLOSE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_VOLUME_TOTAL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_BID_ID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_ASK_ID] = this.GetPFixStringField;

        this.FieldFactoryList[FieldsFactory.FIELD_QUOTE_ID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_SIDE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_SIZE] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_DAY_TRADE_VOLUME] = this.GetPFixDoubleField;

        this.FieldFactoryList[FieldsFactory.FIELD_ON_BEHALF_USER_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_SUPER_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_SORT_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_OPEN_PRICE_IN_MARGIN] = this.GetPFixBooleanField;

        this.FieldFactoryList[FieldsFactory.FIELD_IS_MASTER] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_SIZE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_CROSS_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_CLOSED] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_UNDERLIER] = this.GetPFixStringField;

        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_REF_ID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_TIMESTAMP] = this.GetPFixDateField; // 213
        this.FieldFactoryList[FieldsFactory.FIELD_TEXT_MESSGE_TYPE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_BROCKER_IDS] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_LOCK] = this.GetPFixBooleanField;

        this.FieldFactoryList[FieldsFactory.FIELD_NEWS_CATEGORY] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_NEWPASSWORD] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_MODE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_MD5] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACCS] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_LIST] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_RULES_LIST] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_REPORTS] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_PWD_EXPIRED] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_PHONE] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_PHONE_PWD] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_EMAIL] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_BLOCKED] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_VALUE] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_KEY] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_TEMPLATE] = this.GetPFixStringField;

        this.FieldFactoryList[FieldsFactory.FIELD_DERIVATIVE_POSITION_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_DEPSIT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_PAGE_NUM] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_ROW] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TABLE_NAME] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_BRANDING_KEY] = this.GetPFixStringField;

        this.FieldFactoryList[FieldsFactory.FIELD_SIGNATURE] = this.GetPFixSignatureField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACC_STATE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_TODAY_FEES] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MINIMAL_LOT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_LOT_STEP] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_PUBLIC_KEY] = this.GetPFixPublicKeyField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXPIRE_AT] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_MODIFIED_AMOUNT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_FOK] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX] = this.GetPFixIntField;

        this.FieldFactoryList[FieldsFactory.FIELD_TYPE_MODIFICATION] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_AMOUNT_MODIFICATION] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_TIF_MODIFICATION] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_PRICE_MODIFICATION] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPERAIONTYPE_MODIFICATION] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXPIRATION_DATE_MODIFICATION] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_TIFS_FOR_ORDERTYPES] = this.GetPFixStringField;

        this.FieldFactoryList[FieldsFactory.FIELD_CLEARING_SPAN] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_INVESTMENT_SPAN] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_MANUAL_CONFIRM] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_LIQUIDATION_LEVEL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_REWARD_ALGORITHM] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_REWARD_FROM_TOTAL] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_GOVERNOR_ACCOUNT_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_VISIBLE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_GROSS_PNL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_REINVEST_CYCLES] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_REINVEST_PROFIT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_SL_EXPIRE_AT] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_TP_EXPIRE_AT] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_LINK_TYPE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_CLIENT_ORDER_ID_TO_LINK] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_DESCR] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_BEGIN] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_END] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_TYPE] = this.GetPFixIntField;

        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_COUNT] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_CASH_BALANCE] = this.GetPFixDoubleField;

        this.FieldFactoryList[FieldsFactory.FIELD_PRECISION] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_ROLLOVER_SPAN] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_POSITION_CLOSER_SETUP] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_FUND_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACCOUNT_ID_OF_USER] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_SIGNED] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_MINIMAL_SHARE_COUNT] = this.GetPFixLongField;

        this.FieldFactoryList[FieldsFactory.FIELD_MONTH_AVG_GROSS_PER_SHARE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPEN_PRICE_1MONTH] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPEN_PRICE_3MONTH] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPEN_PRICE_6MONTH] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPEN_PRICE_1YEAR] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_AVG_DAY_PRICE_CHANGE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_AVG_MONTH_PRICE_CHANGE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ANNUAL_GAIN] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_AVG_EXPOSURE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_DRAWDOWN] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_DRAWDOWN_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_RECOVERY_FACTOR] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_PAYOFF_RATIO] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SHARPE_RATIO] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_VALUE_AT_RISK] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SORTINO_RATIO_R] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SORTINO_RATIO_G] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_CLIENT_ORDER_GROUP_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_PROFIT_FACTOR] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_DAYS] = this.GetPFixIntField;

        this.FieldFactoryList[FieldsFactory.FIELD_RAW_BYTES] = this.GetPFixRawBytesFieldField;
        this.FieldFactoryList[FieldsFactory.FIELD_END_TIME] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_VERSION] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_COMPRESSED] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_HISTORY_PERIOD_TYPE] = this.GetPFixShortField;
        this.FieldFactoryList[FieldsFactory.FIELD_POINTER] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_TICK_COAST] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_COUNTER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_MAM_ORDERS] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_RESERVED_BALANCE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_AUTOTRADE_ORDER] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_SL_TR_OFFSET] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HEDGED_MARGIN_CALL_COEFF] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_OK] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_USED_MARGIN] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPEN_INTEREST] = this.GetPFixLongField;

        this.FieldFactoryList[FieldsFactory.FIELD_CONTRACT_MONTH_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_TRADE_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_SETTLEMENT_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_NOTICE_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_FIRST_TRADE_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_AUTO_CLOSE_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_INDICATIVE_AUCTION_PRICE] = this.GetPFixDoubleField;

        this.FieldFactoryList[FieldsFactory.FIELD_CONTRACT_DELIVERY_STATUS] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_PER_ORDER_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_FREE_COMMISSION_AMOUNT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_DEALER_COMMISSION] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_PER_ORDER] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MIN_PER_ORDER] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_DELIVERY_METHOD] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_QUOTE_ROUTE_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_AMOUNT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_CONTINUOUS_CONTRACT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_MANUAL_CONFIRM_EXIT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_CREDIT_VALUE] = this.GetPFixDoubleField;

        this.FieldFactoryList[FieldsFactory.FIELD_MANAGER_SHARE_COUNT] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_MANAGER_CAPITAL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_INVESTOR_COUNT] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_VALUED_DATE_BASIS] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPERATION_MODE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_BUY_INIT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_BUY_SUPP] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SELL_INIT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SELL_SUPP] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_LOGON_FOR_TRADE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_QUOTE_DELAY] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_NODE_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_NODE_REPORTABLE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ADDRESS_PROTOCOL] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_HOST_NODE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_NODE_LOAD] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_ROLLOVER_TIME] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_NEXT_ROLLOVER_TIME] = this.GetPFixDateField;

        this.FieldFactoryList[FieldsFactory.FIELD_CHANGE_PWD_STATUS] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_AVAILABLE_MARGIN] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAINTANCE_MARGIN] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_DEFICIENCY_MARGIN] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SURPLUS_MARGIN] = this.GetPFixDoubleField;

        this.FieldFactoryList[FieldsFactory.FIELD_INTRADAY_RISK_MANAGEMENT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACCOUNT_TRADE_STATUS] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_QUOTE_HISTORY_DEPTH_LIMIT_REACHED] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_DAILY_LOSS_LIMIT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_DAY_VOLUME] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_ORDERS_PER_DAY] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_POSITIONS] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_PENDING_ORDERS] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_ORDER_CAPITAL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_LOT] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_POSITION_QTY_PER_SYMBOL] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_STOP_TRADING_REASON] = this.GetPFixByteField;

        this.FieldFactoryList[FieldsFactory.FIELD_OPEN_CROSS_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_USE_SAME_CROSSPRICEFOR_OPEN_CLOSE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_DELAY] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_DELAY_PERIOD] = this.GetPFixIntField;

        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_DAY_TEMPLATE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_DAY_PERIOD] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_PERIOD_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_SUB_PERIOD_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSIOSN_IS_INTRADAY] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_ALLOWED_ORDER_TYPES] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_SESSION_ALLOWED_OPERATIONS] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_DAY_INDEX] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_SESSION_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_INIT_MARGINGSIZE_OVERNIGHT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HOLD_MARGINGSIZE_OVERNIGHT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_INIT_MARGINGSIZE_SHORT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HOLD_MARGINGSIZE_SHORT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_INIT_MARGINGSIZE_OVERNIGHT_SHORT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HOLD_MARGINGSIZE_OVERNIGHT_SHORT] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_USE_OVERNIGHT_MARGIN] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_USE_LONGSHORT_MARGIN] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_OVERNIGHT_TRADING] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_SESSION_CURRENT_PERIOD_ID] = this.GetPFixIntField;

        this.FieldFactoryList[FieldsFactory.FIELD_STOPOUT_TYPE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_DERIVATIVE_EXPIRATION_TEMLATE] = this.GetPFixIntField;

        this.FieldFactoryList[FieldsFactory.FIELD_PRICE_LOW_LIMIT] = this.GetPFixDoubleField; // 399
        this.FieldFactoryList[FieldsFactory.FIELD_PRICE_HI_LIMIT] = this.GetPFixDoubleField; // 400
        this.FieldFactoryList[FieldsFactory.FIELD_USE_DST] = this.GetPFixBooleanField; // 401
        this.FieldFactoryList[FieldsFactory.FIELD_PRICE_LIMIT_MESURE] = this.GetPFixByteField; // 402
        this.FieldFactoryList[FieldsFactory.FIELD_IS_DECREASE_ONLY_POSITION_COUNT] = this.GetPFixBooleanField; // 403
        this.FieldFactoryList[FieldsFactory.FIELD_DAY_TYPE] = this.GetPFixByteField; // 404
        this.FieldFactoryList[FieldsFactory.FIELD_ERROR_CODE] = this.GetPFixShortField; // 405
        this.FieldFactoryList[FieldsFactory.FIELD_FIRST_CLEARING_TIME] = this.GetPFixDateField; // 406
        this.FieldFactoryList[FieldsFactory.FIELD_CONTRACT_SIZE] = this.GetPFixLongField; // 407
        this.FieldFactoryList[FieldsFactory.FIELD_IS_REMOVED] = this.GetPFixBooleanField; // 408
        this.FieldFactoryList[FieldsFactory.FIELD_TIME_ZONE_ID] = this.GetPFixStringField; // 409

        this.FieldFactoryList[FieldsFactory.FIELD_OPEN_PRE_MARKET] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_CLOSE_POST_MARKET] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_HIGH_GENERAL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_LOW_GENERAL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_TICKS] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_TICKS_PRE_MARKET] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_TICKS_POST_MARKET] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_VOLUME_PRE_MARKET] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_VOLUME_POST_MARKET] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_INVESTOR] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACCOUNT_OPERATION_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_OWNER_TYPE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_VOLUME] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_BID_VOLUME] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ASK_VOLUME] = this.GetPFixDoubleField;

        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_PAYMENT_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_COUNTER_ACCOUNT_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_ACTIVATEIB] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_APPLY_OPERTIONTYPE] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_FROM_AMOUNT] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TO_AMOUNT] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_VALUE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_FOR_TRANSFER_VALUE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SPREAD_PLAN_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_SETTLEMENT_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_PREV_SETTLEMENT_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAIN_CLOSE_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_INTEREST] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ASSET_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACCOUNT_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_TURNOVER] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_DELIVERY_METHOD_ID] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_NEWS_ROUTE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_SETTLEMENT_CROSS_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_NODE_FREE_CONNECTIONS] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_LONG_GROUP] = this.GetPFixLongGroupField;
        this.FieldFactoryList[FieldsFactory.FIELD_REBATES] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_TODAY_REBATES] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_ENABLE_CALL] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ENABLE_PUT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_UNDERLIER_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXERCISE_STYLE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_CHECK_INTRINSIC_VALUE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_UNDERLIER_TYPE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXCHANGE_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_INTER_MONTH_SPREAD] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_INTER_COMMODITY_SPREAD_CHARGE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_DELIVERY_MONTH_SPREAD_CHARGE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_MD_SOURCE] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_COUNTER_ORDER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_CONNECTION_TYPE_MAIN] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_INVESTOR_ID] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_IS_SHORTABLE] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_MIN_VALUE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_MAX_VALUE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_CROSS_MARKUP_MEASURE] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_CROSS_MARKUP] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_CROSS_PLAN_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_ACTIVATE_MAM_FEE] = this.GetPFixBooleanField;// 469
        this.FieldFactoryList[FieldsFactory.FIELD_MAM_FEE_COUNTERACCOUNT] = this.GetPFixIntField;// 470
        this.FieldFactoryList[FieldsFactory.FIELD_MAM_FEE_CURRENCYID] = this.GetPFixIntField;// 471
        this.FieldFactoryList[FieldsFactory.FIELD_MAM_ACCOUNT_PERFORMANCE_FEE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAM_ACCOUNT_MANAGEMENT_FEE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAM_ACCOUNT_MANAGEMENT_FEE_TYPE] = this.GetPFixByteField;// 474
        this.FieldFactoryList[FieldsFactory.FIELD_PRICE_TYPE_FOR_RISK] = this.GetPFixByteField;// 475
        this.FieldFactoryList[FieldsFactory.FIELD_ACCOUNT_VISIBILITY_FLAG] = this.GetPFixIntField;// 476
        this.FieldFactoryList[FieldsFactory.FIELD_CLIENT_PANEL_ID] = this.GetPFixByteField;// 477
        this.FieldFactoryList[FieldsFactory.FIELD_INDICATIVE_AUCTION_VOLUME] = this.GetPFixDoubleField;// 478
        this.FieldFactoryList[FieldsFactory.FIELD_QUOTE_HISTORY_LIMIT_REACHED] = this.GetPFixBooleanField;// 479
        this.FieldFactoryList[FieldsFactory.FIELD_EXECUTION_TYPE] = this.GetPFixByteField;// 480
        this.FieldFactoryList[FieldsFactory.FIELD_MARKUP_FORMULA_TYPE] = this.GetPFixByteField;// 481
        this.FieldFactoryList[FieldsFactory.FIELD_RISK_PLAN_ID] = this.GetPFixLongField;// 482
        this.FieldFactoryList[FieldsFactory.FIELD_UPDATE_TYPE_FLAG] = this.GetPFixByteField;// 483
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_POSITION_TYPE] = this.GetPFixByteField;// 484
        this.FieldFactoryList[FieldsFactory.FIELD_MATURITY_DATE] = this.GetPFixDateField; // 485
        this.FieldFactoryList[FieldsFactory.FIELD_USE_EXPIRED_DATE_ON_TICKER] = this.GetPFixBooleanField; // 486
        this.FieldFactoryList[FieldsFactory.FIELD_TOTAL_GAIN] = this.GetPFixDoubleField; // 487
        this.FieldFactoryList[FieldsFactory.FIELD_ABS_TOTAL_GAIN] = this.GetPFixDoubleField; // 488
        this.FieldFactoryList[FieldsFactory.FIELD_ABS_ANNUAL_GAIN] = this.GetPFixDoubleField; // 489
        this.FieldFactoryList[FieldsFactory.FIELD_BLOCK_TRADING] = this.GetPFixBooleanField; // (490);
        this.FieldFactoryList[FieldsFactory.FIELD_HI_OPEN_INTEREST] = this.GetPFixLongField;// (491);
        this.FieldFactoryList[FieldsFactory.FIELD_LOW_OPEN_INTEREST] = this.GetPFixLongField;// (492);
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_TRADED_TIME] = this.GetPFixLongField;// (493);
        this.FieldFactoryList[FieldsFactory.FIELD_AVG_TRADED_PRICE] = this.GetPFixDoubleField; // 494);
        this.FieldFactoryList[FieldsFactory.FIELD_TOTAL_BUY_QTY] = this.GetPFixDoubleField; // 495);
        this.FieldFactoryList[FieldsFactory.FIELD_TOTAL_SELL_QTY] = this.GetPFixDoubleField; // 496);
        this.FieldFactoryList[FieldsFactory.FIELD_AUCTION_NUMBER] = this.GetPFixShortField; // (497);
        this.FieldFactoryList[FieldsFactory.FIELD_AUCTION_STATUS] = this.GetPFixShortField; // (498);
        this.FieldFactoryList[FieldsFactory.FIELD_INITIATOR_TYPE] = this.GetPFixShortField; // (499);
        this.FieldFactoryList[FieldsFactory.FIELD_INITIATOR_PRICE] = this.GetPFixDoubleField; // 500);
        this.FieldFactoryList[FieldsFactory.FIELD_INITIATOR_QTY] = this.GetPFixLongField;// (501);
        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_IS_WAS_MANUAL] = this.GetPFixBooleanField; // 502
        this.FieldFactoryList[FieldsFactory.FIELD_CONTRACT_ID] = this.GetPFixLongField;// (503);
        this.FieldFactoryList[FieldsFactory.FIELD_NUMBERS_OF_ORDERS] = this.GetPFixIntField;// 504
        this.FieldFactoryList[FieldsFactory.FIELD_INTERESTS_ON_BALANCE_PLAN_ID] = this.GetPFixLongField; // 505
        this.FieldFactoryList[FieldsFactory.FIELD_DATE_FORMAT] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPTIONS_USE_LONG_CP] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPTIONS_SEPARATOR] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPTIONS_USE_DESC] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_DISCLOSED_QUANTITY] = this.GetPFixDoubleField;// 510
        this.FieldFactoryList[FieldsFactory.FIELD_SWAP_PLAN_ID] = this.GetPFixLongField;// 511
        this.FieldFactoryList[FieldsFactory.FIELD_CHARGING_PLAN_ID] = this.GetPFixLongField;// 512
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_ORDER_ID] = this.GetPFixStringField;// 513
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_ORDER_STATUS] = this.GetPFixIntField;// 514
        this.FieldFactoryList[FieldsFactory.FIELD_FIFTY_TWO_WEEK_HIGH_PRICE] = this.GetPFixDoubleField;// 515
        this.FieldFactoryList[FieldsFactory.FIELD_FIFTY_TWO_WEEK_LOW_PRICE] = this.GetPFixDoubleField;// 516
        this.FieldFactoryList[FieldsFactory.FIELD_BARS_TYPE_CHART] = this.GetPFixByteField;// 517
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_BAR_TYPE] = this.GetPFixByteField;// 518
        this.FieldFactoryList[FieldsFactory.FIELD_SPREAD_TYPE] = this.GetPFixByteField;// 519
        this.FieldFactoryList[FieldsFactory.FIELD_SPREAD_ORDER_VALUE] = this.GetPFixDoubleField;// 520
        this.FieldFactoryList[FieldsFactory.FIELD_SPREAD_ORDER_MEASURE] = this.GetPFixByteField;// 521
        this.FieldFactoryList[FieldsFactory.FIELD_USE_UNSETTLED_PROFIT] = this.GetPFixBooleanField;// 522
        this.FieldFactoryList[FieldsFactory.FIELD_UNSETTLED_PROFIT] = this.GetPFixDoubleField;// 523
        this.FieldFactoryList[FieldsFactory.FIELD_UNSETTLED_LOSS] = this.GetPFixDoubleField;// 524
        this.FieldFactoryList[FieldsFactory.FIELD_COMPRESSED_DATA] = this.GetPFixCompressedRawBytesFieldField;// 525
        this.FieldFactoryList[FieldsFactory.FIELD_RESPONCE_END] = this.GetPFixBooleanField;// 526
        this.FieldFactoryList[FieldsFactory.FIELD_ASSET_ID2] = this.GetPFixIntField;// 527
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_PENDING_ORDERS_VALUE] = this.GetPFixIntField;// 528
        this.FieldFactoryList[FieldsFactory.FIELD_VAR_COEFF] = this.GetPFixDoubleField;// 529
        this.FieldFactoryList[FieldsFactory.FIELD_USE_VAR_ONLY_INTRADAY] = this.GetPFixBooleanField;// 530
        this.FieldFactoryList[FieldsFactory.FIELD_VAR_COEFF_APPLYING] = this.GetPFixByteField;// 531
        this.FieldFactoryList[FieldsFactory.FIELD_USE_UNSETTLED_PROFIT_FOR_STOCKS] = this.GetPFixBooleanField;// 532
        this.FieldFactoryList[FieldsFactory.FIELD_ASSET_NAME] = this.GetPFixStringField;// 533
        this.FieldFactoryList[FieldsFactory.FIELD_TRANSACT_TIME] = this.GetPFixDateField;// 534
        this.FieldFactoryList[FieldsFactory.FIELD_DAY_BAR_UPDATE_MODE] = this.GetPFixIntField;// 535
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_MIN_TRADED_VALUE] = this.GetPFixDoubleField;// 536
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_FINAL_VALUE] = this.GetPFixDoubleField;// 537
        this.FieldFactoryList[FieldsFactory.FIELD_MATURITY_DATE_VALUE] = this.GetPFixDateField; // 538;
        this.FieldFactoryList[FieldsFactory.FIELD_FACE_VALUE] = this.GetPFixLongField; // 539;
        this.FieldFactoryList[FieldsFactory.FIELD_COUPON_RATE_VALUE] = this.GetPFixFloatField; // 540;
        this.FieldFactoryList[FieldsFactory.FIELD_COUPON_CYCLE_VALUE] = this.GetPFixIntField; // 541;
        this.FieldFactoryList[FieldsFactory.FIELD_YIELD_RATE_VALUE] = this.GetPFixFloatField; // 542;
        this.FieldFactoryList[FieldsFactory.FIELD_SOURCE_ID] = this.GetPFixShortField; // 543;
        this.FieldFactoryList[FieldsFactory.FIELD_DERIVATIVE_DATE_TYPE] = this.GetPFixDerivativeContractDateField; // 544
        this.FieldFactoryList[FieldsFactory.FIELD_SOURCE_BUYER_SELLER_ID] = this.GetPFixIntField; // 545
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_WEEKLY_LOSS_LIMIT] = this.GetPFixDoubleField; // 546
        this.FieldFactoryList[FieldsFactory.FIELD_TRAILING_DRAWDOWN_LEVEL] = this.GetPFixDoubleField; // 547
        this.FieldFactoryList[FieldsFactory.FIELD_TOTAL_MAX_POSITION_QTY] = this.GetPFixDoubleField; // 548
        this.FieldFactoryList[FieldsFactory.FIELD_SUBSCRIPTION_PERIODICITY] = this.GetPFixByteField; // 549
        this.FieldFactoryList[FieldsFactory.FIELD_CLIENT_SHOW_MODE] = this.GetPFixIntField; // 550
        this.FieldFactoryList[FieldsFactory.FIELD_QUOTING_TYPE] = this.GetPFixByteField; // 551
        this.FieldFactoryList[FieldsFactory.FIELD_2FA_TYPE] = this.GetPFixByteField; // 552
        this.FieldFactoryList[FieldsFactory.FIELD_CURRENT_DAILY_LOSS] = this.GetPFixDoubleField; // 553
        this.FieldFactoryList[FieldsFactory.FIELD_CURRENT_WEEKLY_LOSS] = this.GetPFixDoubleField; // 554
        this.FieldFactoryList[FieldsFactory.FIELD_PARAMS] = this.GetPFixStringField; // 555
        this.FieldFactoryList[FieldsFactory.FIELD_SL_PRICE_TYPE] = this.GetPFixByteField;// 556
        this.FieldFactoryList[FieldsFactory.FIELD_TP_PRICE_TYPE] = this.GetPFixByteField;// 557
        this.FieldFactoryList[FieldsFactory.FIELD_QUOTE_TYPE] = this.GetPFixByteField;// 558
        this.FieldFactoryList[FieldsFactory.FIELD_STOCK_REQ] = this.GetPFixDoubleField;// 559
        this.FieldFactoryList[FieldsFactory.FIELD_PAPER_STOCK_REQ] = this.GetPFixDoubleField;// 560
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_TYPE_FOR_TRANSFER] = this.GetPFixByteField;// 561
        this.FieldFactoryList[FieldsFactory.FIELD_COMMISSION_TYPE_FOR_WITHDRAWAL] = this.GetPFixByteField;// 562
        this.FieldFactoryList[FieldsFactory.FIELD_TRANSFER_COMMISSION_PERCENTAGE] = this.GetPFixDoubleField;// 563
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_PERCENTAGE] = this.GetPFixDoubleField;// 564
        this.FieldFactoryList[FieldsFactory.FIELD_TRANSFER_COMMISSION_FIXED_FEE] = this.GetPFixDoubleField;// 565
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_FIXED_FEE] = this.GetPFixDoubleField;// 566
        this.FieldFactoryList[FieldsFactory.FIELD_TRANSFER_COMMISSION_CURRENCY_ID] = this.GetPFixIntField;// 567
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_CURRENCY_ID] = this.GetPFixIntField;// 568
        this.FieldFactoryList[FieldsFactory.FIELD_TRANSFER_COMMISSION_MIN_FEE] = this.GetPFixDoubleField;// 569
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_MIN_FEE] = this.GetPFixDoubleField;// 570
        this.FieldFactoryList[FieldsFactory.FIELD_TRANSFER_COMMISSION_MAX_FEE] = this.GetPFixDoubleField;// 571
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWAL_COMMISSION_MAX_FEE] = this.GetPFixDoubleField;// 572
        this.FieldFactoryList[FieldsFactory.FIELD_TRANSFER_COMM_COUNTER_ACCOUNT_ID] = this.GetPFixIntField;// 573
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWAL_COMM_COUNTER_ACCOUNT_ID] = this.GetPFixIntField;// 574
        this.FieldFactoryList[FieldsFactory.FIELD_ACCRUED_INTEREST] = this.GetPFixDoubleField;// 575
        this.FieldFactoryList[FieldsFactory.FIELD_ISSUE_DATE] = this.GetPFixDateField;// 576
        this.FieldFactoryList[FieldsFactory.FIELD_USED_UNSETTLED_NEGATIVE_PREMIUM] = this.GetPFixDoubleField;// 577
        this.FieldFactoryList[FieldsFactory.FIELD_UNSETTLED_POSITIVE_PREMIUM] = this.GetPFixDoubleField;// 578
        this.FieldFactoryList[FieldsFactory.FIELD_UNSETTLED_PREMIUM_FROM_OPEN_SELL] = this.GetPFixDoubleField;// 579
        this.FieldFactoryList[FieldsFactory.FIELD_USED_PREMIUM_FROM_OPEN_SELL] = this.GetPFixDoubleField;// 580
        this.FieldFactoryList[FieldsFactory.FIELD_IS_ACTIVE] = this.GetPFixBooleanField;// 581
        this.FieldFactoryList[FieldsFactory.FIELD_IS_ACTIVATED] = this.GetPFixBooleanField;// 582
        this.FieldFactoryList[FieldsFactory.FIELD_USED_UNSETTLED_NEGATIVE_CASH_FOR_STOCKS] = this.GetPFixDoubleField;// 583
        this.FieldFactoryList[FieldsFactory.FIELD_UNSETTLED_POSITIVE_CASH_FOR_STOCKS] = this.GetPFixDoubleField;// 584
        this.FieldFactoryList[FieldsFactory.FIELD_OPTION_TRADING_STYLE] = this.GetPFixByteField;// 585
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_TYPE] = this.GetPFixByteField;// 586
        this.FieldFactoryList[FieldsFactory.FIELD_UNUSED_UNSETTLED_NEGATIVE_PREMIUM] = this.GetPFixDoubleField;// 587
        this.FieldFactoryList[FieldsFactory.FIELD_UNUSED_PREMIUM_FROM_OPEN_SELL] = this.GetPFixDoubleField;// 588
        this.FieldFactoryList[FieldsFactory.FIELD_UNUSED_UNSETTLED_NEGATIVE_CASH_FOR_STOCKS] = this.GetPFixDoubleField;// 589
        this.FieldFactoryList[FieldsFactory.FIELD_CREDIT_FOR_SELL] = this.GetPFixDoubleField;// 590
        this.FieldFactoryList[FieldsFactory.FIELD_LIQUIDITY_RATE] = this.GetPFixDoubleField;// 591
        this.FieldFactoryList[FieldsFactory.FIELD_TOTAL_QUANTITY] = this.GetPFixDoubleField;// 592
        this.FieldFactoryList[FieldsFactory.FIELD_TOTAL_QUANTITY_FOR_MARGIN_AVAILABLE] = this.GetPFixDoubleField;// 593
        this.FieldFactoryList[FieldsFactory.FIELD_CALCULATION_METHOD] = this.GetPFixByteField;// 594
        this.FieldFactoryList[FieldsFactory.FIELD_TODAY_TRADED_QTY] = this.GetPFixDoubleField;// 595
        this.FieldFactoryList[FieldsFactory.FIELD_ASSET_SHARES_NAMES] = this.GetPFixStringField;// 596
        this.FieldFactoryList[FieldsFactory.FIELD_STOCKS_LIQUIDITY] = this.GetPFixDoubleField; // 597

        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SIGNAL_ID] = this.GetPFixLongField; // 598
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SYSTEM_ID] = this.GetPFixLongField; // 599
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SIGNAL_TITLE] = this.GetPFixStringField; // 600
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_STRATEGY_SUBSCRIPTION_STATUS] = this.GetPFixByteField; // 601
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SIGNAL_LIFETIME] = this.GetPFixIntField; // 602
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SIGNAL_SCREEN_ADDRESS] = this.GetPFixStringField; // 603
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SIGNAL_TIME] = this.GetPFixDateField; // 604
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SIGNAL_EXP_TIME] = this.GetPFixDateField; // 605
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SYSTEM_COMPANY_NAME] = this.GetPFixStringField; // 606
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SYSTEM_RISK_DISCLOSURE] = this.GetPFixStringField; // 607
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SYSTEM_AMOUNT1] = this.GetPFixIntField; // 608
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SYSTEM_AMOUNT2] = this.GetPFixIntField; // 609
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SYSTEM_AMOUNT3] = this.GetPFixIntField; // 610
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SIGNAL_IS_AGGRESSIVE] = this.GetPFixBooleanField; // 611
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_STRATEGY_ACTIVE_SUBSCRIPTIONS] = this.GetPFixIntField; // 612
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_STRATEGY_SIGNALS_POSTED] = this.GetPFixIntField; // 613

        this.FieldFactoryList[FieldsFactory.FIELD_MAX_UNREALIZED_LOSS_LIMIT] = this.GetPFixDoubleField;// 614
        this.FieldFactoryList[FieldsFactory.FIELD_SOURCE_EXT_ID] = this.GetPFixStringField;// 615
        this.FieldFactoryList[FieldsFactory.FIELD_NOT_USED] = this.GetPFixBooleanField;// 616
        this.FieldFactoryList[FieldsFactory.FIELD_USE_IN_VAT] = this.GetPFixBooleanField;// 617
        this.FieldFactoryList[FieldsFactory.FIELD_VOLUME_PERCENTAGE] = this.GetPFixDoubleField;// 618
        this.FieldFactoryList[FieldsFactory.FIELD_HISTORY_SOURCE_TYPE] = this.GetPFixIntField;// 619
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_HISTORY_CHANGE_DATE] = this.GetPFixDateField;// 620
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_UNLOCK_LOGIC] = this.GetPFixByteField;// 621
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_UNLOCK_MESSAGE_RESPONSE] = this.GetPFixBooleanField;// 622
        this.FieldFactoryList[FieldsFactory.FIELD_QUOTES_LIMIT] = this.GetPFixIntField;// 623
        this.FieldFactoryList[FieldsFactory.FIELD_SORT_ORDER] = this.GetPFixByteField;// 624
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_SESSION_STATUS_ID] = this.GetPFixIntField; // 625
        this.FieldFactoryList[FieldsFactory.FIELD_EXCHANGE_SESSION_NAME] = this.GetPFixStringField; // 626
        this.FieldFactoryList[FieldsFactory.FIELD_ASSET_TRADING_MODE] = this.GetPFixByteField; // 627
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_TRAILING_DRAWDOWN] = this.GetPFixDoubleField; // 628
        this.FieldFactoryList[FieldsFactory.FIELD_OPTION_CONTRACT_ID] = this.GetPFixLongField; // 629
        this.FieldFactoryList[FieldsFactory.FIELD_MESSAGE_CODES] = this.GetPFixIntArrayField;
        this.FieldFactoryList[FieldsFactory.FIELD_NOT_USED_2] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_ORDER_AMOUNT] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_VALUE_DATE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_RISK_WARN_LEVEL] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_HISTORY_CHANGE_SEQUENCE_NUM] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_POA_USER_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_ALGORITHM] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_TRADE_DATE_VALUE] = this.GetPFixDateField;
        this.FieldFactoryList[FieldsFactory.FIELD_REFERENCE_PRICE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_UNSETTLED_LOSS_AVAILABLE_FOR_WITHDRAWAL] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OPTION_PREMIUM_REQ] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_LANG] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_ORDER_RECEIVED_BY] = this.GetPFixIntField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADED_VALUE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OFF_EXCHANGE_VOLUME] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_OFF_EXCHANGE_VALUE] = this.GetPFixDoubleField;
        this.FieldFactoryList[FieldsFactory.FIELD_SUCCESS] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_MASKED_NAME] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_KEYBOARD_LETTERS] = this.GetStringArrField;
        this.FieldFactoryList[FieldsFactory.FIELD_KEYBOARD_VALUES] = this.GetStringArrField;
        this.FieldFactoryList[FieldsFactory.FIELD_USER_NODE_IP] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_TOKEN_ID] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_VERIFICATION_ERROR] = this.GetPFixStringField;
        this.FieldFactoryList[FieldsFactory.FIELD_EXT_RESOURCE] = this.GetPFixByteField;
        this.FieldFactoryList[FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID] = this.GetPFixLongField;
        this.FieldFactoryList[FieldsFactory.FIELD_USE_UNSETTLED_STOCK_OPERATION_IN_WA] = this.GetPFixBooleanField; // 656
        this.FieldFactoryList[FieldsFactory.FIELD_USE_PROFIT_AND_UNSETTLED_PNL_IN_WA] = this.GetPFixBooleanField; // 657
        this.FieldFactoryList[FieldsFactory.FIELD_VALUATION_TYPE] = this.GetPFixByteField; // 658
        this.FieldFactoryList[FieldsFactory.FIELD_VALUATION_TYPE_FOR_SHORTS] = this.GetPFixByteField; // 659
        this.FieldFactoryList[FieldsFactory.FIELD_MARGIN_MARKET_VALUE] = this.GetPFixDoubleField; // 660
        this.FieldFactoryList[FieldsFactory.FIELD_MARGIN_TRADED_BALANCE] = this.GetPFixDoubleField; // 661
        this.FieldFactoryList[FieldsFactory.FIELD_SHORT_TRADED_BALANCE] = this.GetPFixDoubleField; // 662
        this.FieldFactoryList[FieldsFactory.FIELD_MARGIN_EQUITY] = this.GetPFixDoubleField; // 663
        this.FieldFactoryList[FieldsFactory.FIELD_SMA_EQUITY] = this.GetPFixDoubleField;// 664
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWABLE_CASH_FOR_STOCKS] = this.GetPFixDoubleField; // 665
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWABLE_UNSETTLED_PNL] = this.GetPFixDoubleField; // 666
        this.FieldFactoryList[FieldsFactory.FIELD_USE_UNSETTLED_NEGATIVE_STOCK_OPERATION_IN_WA] = this.GetPFixBooleanField; // 667
        this.FieldFactoryList[FieldsFactory.FIELD_USE_UNSETTLED_POSITIVE_STOCK_OPERATION_IN_WA] = this.GetPFixBooleanField; // 668
        this.FieldFactoryList[FieldsFactory.FIELD_USE_UNSETTLED_PROFIT_IN_WA] = this.GetPFixBooleanField; // 669
        this.FieldFactoryList[FieldsFactory.FIELD_USE_NON_SSL_CONNECTIONS_FOR_QUOTES_AND_HISTORY] = this.GetPFixBooleanField; // 670
        this.FieldFactoryList[FieldsFactory.FIELD_NODE_STATE] = this.GetPFixByteField; // 671
        this.FieldFactoryList[FieldsFactory.FIELD_VERIFICATION_DESCRIPTION] = this.GetPFixStringField; // 672
        this.FieldFactoryList[FieldsFactory.FIELD_VERIFICATION_TYPE] = this.GetPFixStringField; // 673
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_DRAWDOWN_LEVEL] = this.GetPFixDoubleField; // 674
        this.FieldFactoryList[FieldsFactory.FIELD_AUCTION_END_TIME] = this.GetPFixStringField; // 675
        this.FieldFactoryList[FieldsFactory.FIELD_SUPER_POSITION_ID] = this.GetPFixLongField; // 676
        this.FieldFactoryList[FieldsFactory.FIELD_ISIN] = this.GetPFixStringField; // 677
        this.FieldFactoryList[FieldsFactory.FIELD_ROUTE_IDS] = this.GetPFixIntArrayField; // 678
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_ROUTE_IDS] = this.GetPFixIntArrayField; // 679
        this.FieldFactoryList[FieldsFactory.FIELD_UNDERLIER_CONTRACT_TRADABLE_INSTRUMENT_ID] = this.GetPFixLongField; // 680
        this.FieldFactoryList[FieldsFactory.FIELD_ACCESS_TOKEN] = this.GetPFixStringField; // 681
        this.FieldFactoryList[FieldsFactory.FIELD_INFO_MSG_TYPE] = this.GetPFixStringField; // 682
        this.FieldFactoryList[FieldsFactory.FIELD_CONTENT] = this.GetStringArrField; // 683
        this.FieldFactoryList[FieldsFactory.FIELD_USE_UNSETTLED_COLLATERAL_IN_MA] = this.GetPFixBooleanField; // 684
        this.FieldFactoryList[FieldsFactory.FIELD_USE_UNSETTLED_COLLATERAL_IN_WA] = this.GetPFixBooleanField; // 685
        this.FieldFactoryList[FieldsFactory.FIELD_UNSETTLED_COLLATERAL] = this.GetPFixDoubleField; // 686
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWABLE_UNSETTLED_COLLATERAL] = this.GetPFixDoubleField; // 687
        this.FieldFactoryList[FieldsFactory.FIELD_COLLATERAL] = this.GetPFixDoubleField; // 688

        this.FieldFactoryList[FieldsFactory.FIELD_ACCESS_TOKEN_LIFE_TIME] = this.GetPFixLongField; // 689
        this.FieldFactoryList[FieldsFactory.FIELD_REFRESH_TOKEN] = this.GetPFixStringField;// 690
        this.FieldFactoryList[FieldsFactory.FIELD_REFRESH_TOKEN_LIFE_TIME] = this.GetPFixLongField; // 691
        this.FieldFactoryList[FieldsFactory.FIELD_REMAINING_QTY] = this.GetPFixDoubleField; // 692
        this.FieldFactoryList[FieldsFactory.FIELD_REMAINING_QTY_SIDE] = this.GetPFixIntField; // 693
        this.FieldFactoryList[FieldsFactory.FIELD_ALGORITHM_ID] = this.GetPFixLongField; // 694
        this.FieldFactoryList[FieldsFactory.FIELD_ALGORITHM_TYPE] = this.GetPFixIntField; // 695
        this.FieldFactoryList[FieldsFactory.FIELD_ALGORITHM_NAME] = this.GetPFixStringField; // 696
        this.FieldFactoryList[FieldsFactory.FIELD_ALGORITHM_STATUS] = this.GetPFixIntField; // 697
        this.FieldFactoryList[FieldsFactory.FIELD_MD_EXCHANGE_NAME] = this.GetPFixStringField; // 698
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_EXCHANGE_NAME] = this.GetPFixStringField; // 699
        this.FieldFactoryList[FieldsFactory.FIELD_ALGORITHM_TOP] = this.GetPFixIntField; // 700
        this.FieldFactoryList[FieldsFactory.FIELD_INTEREST_CHANGE] = this.GetPFixDoubleField; // 701
        this.FieldFactoryList[FieldsFactory.FIELD_BEHAVIOR] = this.GetPFixIntField; // 702
        this.FieldFactoryList[FieldsFactory.FIELD_ABSOLUTE_CHANGE] = this.GetPFixDoubleField; // 703
        this.FieldFactoryList[FieldsFactory.FIELD_HIGH_LOW_EVENT] = this.GetPFixIntField; // 704
        this.FieldFactoryList[FieldsFactory.FIELD_BROKER_NAME] = this.GetPFixStringField; // 705
        this.FieldFactoryList[FieldsFactory.FIELD_CURRENCY_ID] = this.GetPFixIntField; // 706
        this.FieldFactoryList[FieldsFactory.FIELD_EXT_NAME] = this.GetPFixStringField; // 707
        this.FieldFactoryList[FieldsFactory.FIELD_STATUS] = this.GetPFixIntField; // 708
        this.FieldFactoryList[FieldsFactory.FIELD_RESTRICTION] = this.GetPFixIntField; // 709
        this.FieldFactoryList[FieldsFactory.FIELD_IS_OVERRIDDEN] = this.GetPFixBooleanField; // 710
        this.FieldFactoryList[FieldsFactory.FIELD_RAW_BYTES_ATTACHMENT] = this.GetPFixRawBytesFieldField; // 711
        this.FieldFactoryList[FieldsFactory.FIELD_ATTACHMENT_METADATA] = this.GetPFixStringField; // 712
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_SIGNAL_ATTACHMENT_ADDRESS] = this.GetPFixStringField; // 713
        this.FieldFactoryList[FieldsFactory.FIELD_IS_TRADING_SIGNAL_NOT_SEEN] = this.GetPFixBooleanField; // 714
        this.FieldFactoryList[FieldsFactory.FIELD_POSITION_CLOSE_ONLY] = this.GetPFixBooleanField; // 715
        this.FieldFactoryList[FieldsFactory.FIELD_BRANDING_ID] = this.GetPFixIntField; // 716
        this.FieldFactoryList[FieldsFactory.FIELD_WAIVED_MARGIN] = this.GetPFixDoubleField; // 717
        this.FieldFactoryList[FieldsFactory.FIELD_IS_TECHNICAL_REPORT] = this.GetPFixBooleanField; // 718
        this.FieldFactoryList[FieldsFactory.FIELD_IS_ALLOW_LISTED_MESSAGE] = this.GetPFixBooleanField; // 719
        this.FieldFactoryList[FieldsFactory.FIELD_VALUE_INSTRUMENT_INDUSTRY] = this.GetPFixStringField; // 720
        this.FieldFactoryList[FieldsFactory.FIELD_VALUE_INSTRUMENT_SECTOR] = this.GetPFixStringField; // 721
        this.FieldFactoryList[FieldsFactory.FIELD_EXECUTION_VENUE] = this.GetPFixStringField; // 722
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_EXCHANGE] = this.GetPFixStringField;// 723
        this.FieldFactoryList[FieldsFactory.FIELD_MARKET_DATA_EXCHANGE] = this.GetPFixStringField;// 724
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_COUNTRY] = this.GetPFixIntField;// 725
        this.FieldFactoryList[FieldsFactory.FIELD_BROKER_MESSAGE_RESPONSE_CASES] = this.GetStringArrField;// 726
        this.FieldFactoryList[FieldsFactory.FIELD_PORTFOLIO_SYSTEM_ID] = this.GetPFixIntField;// 727
        this.FieldFactoryList[FieldsFactory.FIELD_PORTFOLIO_MODEL_ID] = this.GetPFixIntField;// 728
        this.FieldFactoryList[FieldsFactory.FIELD_PERCENT] = this.GetPFixDoubleField;// 729
        this.FieldFactoryList[FieldsFactory.FIELD_REPORT_ID] = this.GetPFixIntField;// 730
        this.FieldFactoryList[FieldsFactory.FIELD_WARNING_MARGIN] = this.GetPFixDoubleField;// 731
        this.FieldFactoryList[FieldsFactory.FIELD_MARGIN_BEFORE_WARNING] = this.GetPFixDoubleField;// 732
        this.FieldFactoryList[FieldsFactory.FIELD_WARN_MARGINGSIZE] = this.GetPFixDoubleField;// 733
        this.FieldFactoryList[FieldsFactory.FIELD_WARN_MARGINGSIZE_SHORT] = this.GetPFixDoubleField;// 734
        this.FieldFactoryList[FieldsFactory.FIELD_WARN_MARGINGSIZE_OVERNIGHT] = this.GetPFixDoubleField;// 735
        this.FieldFactoryList[FieldsFactory.FIELD_WARN_MARGINGSIZE_OVERNIGHT_SHORT] = this.GetPFixDoubleField;// 736
        this.FieldFactoryList[FieldsFactory.FIELD_INITIAL_MARGIN_WITHOUT_WAIVED] = this.GetPFixDoubleField;// 737
        this.FieldFactoryList[FieldsFactory.FIELD_SL_LIMIT_PRICE] = this.GetPFixDoubleField;// 738
        this.FieldFactoryList[FieldsFactory.FIELD_MOBILE_DEVICE_ID] = this.GetPFixStringField; // 739
        this.FieldFactoryList[FieldsFactory.FIELD_SORTED_MD] = this.GetPFixBooleanField; // 740
        this.FieldFactoryList[FieldsFactory.FIELD_MD_ORDER_ID] = this.GetPFixIntField; // 741
        this.FieldFactoryList[FieldsFactory.FIELD_SUB_ID] = this.GetPFixShortField; // 742
        this.FieldFactoryList[FieldsFactory.FIELD_SUB_ID_DATE] = this.GetPFixLongField; // 743
        this.FieldFactoryList[FieldsFactory.FIELD_PREV_MD_ORDER_ID] = this.GetPFixIntField; // 744
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_LOGO_ADDRESS] = this.GetPFixStringField; // 745
        this.FieldFactoryList[FieldsFactory.FIELD_TIME_OFFSET] = this.GetPFixIntField; // 746
        this.FieldFactoryList[FieldsFactory.FIELD_CUSTODIAL_PLAN_ID] = this.GetPFixLongField; // 747
        this.FieldFactoryList[FieldsFactory.FIELD_CUSTODIAL_FEE] = this.GetPFixDoubleField; // 748
        this.FieldFactoryList[FieldsFactory.FIELD_CUSTODIAL_MEASURE] = this.GetPFixIntField; // 749
        this.FieldFactoryList[FieldsFactory.FIELD_OPTION_EXERCISE_STATUS] = this.GetPFixByteField; // 750
        this.FieldFactoryList[FieldsFactory.FIELD_SHORT_POSITION_INTEREST] = this.GetPFixDoubleField; // 751
        this.FieldFactoryList[FieldsFactory.FIELD_DAILY_AVAILABLE_FOR_SHORT] = this.GetPFixDoubleField; // 752
        this.FieldFactoryList[FieldsFactory.FIELD_TOTAL_RETURN] = this.GetPFixDoubleField; // 753
        this.FieldFactoryList[FieldsFactory.FIELD_PUSH_NOTIFICATION_TOKEN] = this.GetPFixStringField; // 754
        this.FieldFactoryList[FieldsFactory.FIELD_FINGERPRINT] = this.GetPFixStringField; // 755
        this.FieldFactoryList[FieldsFactory.FIELD_ATTRIBUTES] = this.GetPFixStringField; // 756
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_WITHDRAW_UNREALIZED_PROFIT] = this.GetPFixBooleanField; // 757
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_TYPE] = this.GetPFixByteField; // 758
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_CONDITION] = this.GetPFixByteField; // 759
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_VALUE] = this.GetPFixDoubleField; // 760
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_IMPORTANCE] = this.GetPFixByteField; // 761
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_AFTER_EXECUTE] = this.GetPFixByteField; // 762
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_ACTION] = this.GetPFixByteField; // 763
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_NOTIFICATION] = this.GetPFixIntField; // 764
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_ID] = this.GetPFixLongField; // 765
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_EVENT] = this.GetPFixByteField; // 766
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_STATUS] = this.GetPFixByteField; // 767
        this.FieldFactoryList[FieldsFactory.FIELD_ALERT_COMMAND] = this.GetPFixByteField; // 768
        this.FieldFactoryList[FieldsFactory.FIELD_DAY_TRADER_PATTERN] = this.GetPFixBooleanField; // 769
        this.FieldFactoryList[FieldsFactory.FIELD_DAY_TRADER_PATTERN_PROTECTION] = this.GetPFixBooleanField; // 770
        this.FieldFactoryList[FieldsFactory.FIELD_AVAILABLE_DAY_TRADES] = this.GetPFixIntField; // 771
        this.FieldFactoryList[FieldsFactory.FIELD_USE_SETTLED_CASH_FOR_STOCKS_FOR_OPENED_POSITIONS_IN_MA] = this.GetPFixBooleanField; // 772
        this.FieldFactoryList[FieldsFactory.FIELD_USE_SETTLED_COLLATERAL_FOR_OPENED_POSITION_IN_MA] = this.GetPFixBooleanField; // 773
        this.FieldFactoryList[FieldsFactory.FIELD_UNUSED_SETTLED_CASH_FOR_STOCKS] = this.GetPFixDoubleField; // 774
        this.FieldFactoryList[FieldsFactory.FIELD_UNUSED_SETTLED_COLLATERAL] = this.GetPFixDoubleField; // 775
        this.FieldFactoryList[FieldsFactory.FIELD_SAVE_QUOTES_HISTORY] = this.GetPFixBooleanField; // 776
        this.FieldFactoryList[FieldsFactory.FIELD_IS_TOKEN_EXPIRED] = this.GetPFixBooleanField; // 777
        this.FieldFactoryList[FieldsFactory.FIELD_USE_OVERNIGHT_KOEFICIENT_FOR_WA_CALCULATION] = this.GetPFixBooleanField; // 778
        this.FieldFactoryList[FieldsFactory.FIELD_UNUSED_INTRADAY_INITIAL_MARGIN] = this.GetPFixDoubleField; // 779
        this.FieldFactoryList[FieldsFactory.FIELD_USED_OVERNIGHT_INITIAL_MARGIN_FOR_INTRADAY] = this.GetPFixDoubleField; // 780
        this.FieldFactoryList[FieldsFactory.FIELD_USE_SESSION_PERIOD] = this.GetPFixBooleanField; // 781
        this.FieldFactoryList[FieldsFactory.FIELD_IS_SNAPSHOT] = this.GetPFixBooleanField; // 782
        this.FieldFactoryList[FieldsFactory.FIELD_TRADABLE_INSTRUMENT_IDS] = this.GetLongArrField; // 783
        // Product
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_ID] = this.GetPFixIntField; // 784
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_PRO_PRICE] = this.GetPFixDoubleField; // 785
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_NON_PRO_PRICE] = this.GetPFixDoubleField; // 786
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_HAS_DOCUMENTS] = this.GetPFixBooleanField; // 787
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_REQUEST_STATUS] = this.GetPFixByteField; // 788
        this.FieldFactoryList[FieldsFactory.FIELD_DOCUMENT_ID] = this.GetPFixIntField; // 789
        this.FieldFactoryList[FieldsFactory.FIELD_DOCUMENT_CONTENT] = this.GetPFixStringField; // 790
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_REQUEST_TYPE] = this.GetPFixByteField; // 791
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_REQUEST_TIME] = this.GetPFixLongField; // 792
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_ACCOUNT] = this.GetPFixStringField; // 793
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_CATEGORY] = this.GetPFixStringField; // 794
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_BASIS_TYPE] = this.GetPFixByteField; // 795
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_IDS] = this.GetPFixIntArrayField; // 796
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_GROUP_IDS] = this.GetPFixIntArrayField; // 797
        this.FieldFactoryList[FieldsFactory.FIELD_TRADING_EXCHANGES] = this.GetStringArrField; // 798
        this.FieldFactoryList[FieldsFactory.FIELD_ACCOUNT_STATUS_REASON] = this.GetPFixStringField; // 799
        this.FieldFactoryList[FieldsFactory.FIELD_MINIMAL_PROJECTED_BALANCE] = this.GetPFixDoubleField; // 800
        this.FieldFactoryList[FieldsFactory.FIELD_PDT_CONFIRMATION_MODE] = this.GetPFixByteField; // 801
        this.FieldFactoryList[FieldsFactory.FIELD_PDT_CONFIRMATION] = this.GetPFixByteField; // 802
        this.FieldFactoryList[FieldsFactory.FIELD_OPERATION_TYPE2] = this.GetPFixShortField; // 803
        this.FieldFactoryList[FieldsFactory.FIELD_BID_VENUE] = this.GetPFixShortField; // 804
        this.FieldFactoryList[FieldsFactory.FIELD_ASK_VENUE] = this.GetPFixShortField; // 805
        this.FieldFactoryList[FieldsFactory.FIELD_VENUE] = this.GetPFixShortField; // 806
        this.FieldFactoryList[FieldsFactory.FIELD_IS_DATA_SOURCE] = this.GetPFixBooleanField; // 807
        this.FieldFactoryList[FieldsFactory.FIELD_DATA_SOURCE_TR_INSTR_ID] = this.GetPFixLongField; // 808
        this.FieldFactoryList[FieldsFactory.FIELD_DATA_SOURCE_ROUTE_ID] = this.GetPFixIntField; // 809
        this.FieldFactoryList[FieldsFactory.FIELD_TARGET_TR_INSTR_ID] = this.GetPFixLongField; // 810
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_LINK_URL] = this.GetPFixStringField; // 811
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_LINK_CLIENT_USE_INTERNAL_BROWSER] = this.GetPFixBooleanField; // 812
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_RESOURCE] = this.GetPFixIntField; // 813
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_LINK_PARAMETERS] = this.GetPFixStringField; // 814
        this.FieldFactoryList[FieldsFactory.FIELD_CORP_ACTION_TYPE] = this.GetPFixByteField; // 815
        this.FieldFactoryList[FieldsFactory.FIELD_BEGIN_PROJECTED_BALANCE] = this.GetPFixDoubleField;// 816;
        this.FieldFactoryList[FieldsFactory.FIELD_UPLOADED_AMOUNT] = this.GetPFixDoubleField;// 817;
        this.FieldFactoryList[FieldsFactory.FIELD_CASH_ORDER_QTY] = this.GetPFixDoubleField;// 818
        this.FieldFactoryList[FieldsFactory.FIELD_MIN_PASSWORD_LENGTH] = this.GetPFixIntField;// 819;
        this.FieldFactoryList[FieldsFactory.FIELD_FIRST_DAY_CHANGE] = this.GetPFixDoubleField; // 820
        this.FieldFactoryList[FieldsFactory.FIELD_RANKING_30_DAY] = this.GetPFixDoubleField; // 821
        this.FieldFactoryList[FieldsFactory.FIELD_RANKING_60_DAY] = this.GetPFixDoubleField; // 822
        this.FieldFactoryList[FieldsFactory.FIELD_RANKING_120_DAY] = this.GetPFixDoubleField; // 823
        this.FieldFactoryList[FieldsFactory.FIELD_IS_L1] = this.GetPFixBooleanField;// 824
        this.FieldFactoryList[FieldsFactory.FIELD_IS_MD] = this.GetPFixBooleanField;// 825
        this.FieldFactoryList[FieldsFactory.FIELD_IS_L3] = this.GetPFixBooleanField;// 826
        this.FieldFactoryList[FieldsFactory.FIELD_ACCRUED_DIVIDENDS] = this.GetPFixDoubleField; // 827
        this.FieldFactoryList[FieldsFactory.FIELD_LIST_TYPE] = this.GetPFixIntField; // 828
        this.FieldFactoryList[FieldsFactory.FIELD_VALUE_INSTRUMENT_SECTOR_CUSTOM_LIST_ID] = this.GetPFixIntField; // 829
        this.FieldFactoryList[FieldsFactory.FIELD_VALUE_INSTRUMENT_INDUSTRY_CUSTOM_LIST_ID] = this.GetPFixIntField; // 830
        this.FieldFactoryList[FieldsFactory.FIELD_CALCULATED_DRAWDOWN] = this.GetPFixDoubleField; // 831
        this.FieldFactoryList[FieldsFactory.FIELD_MARKET_CAP] = this.GetPFixDoubleField; // 832
        this.FieldFactoryList[FieldsFactory.FIELD_ANNOUNCEMENT_DATE] = this.GetPFixDateField; // 833
        this.FieldFactoryList[FieldsFactory.FIELD_DECLARATION_DATE] = this.GetPFixDateField; // 834
        this.FieldFactoryList[FieldsFactory.FIELD_RECORD_DATE] = this.GetPFixDateField; // 835
        this.FieldFactoryList[FieldsFactory.FIELD_EX_DATE] = this.GetPFixDateField; // 836
        this.FieldFactoryList[FieldsFactory.FIELD_PAYMENT_DATE] = this.GetPFixDateField; // 837
        this.FieldFactoryList[FieldsFactory.FIELD_UNIT] = this.GetPFixStringField; // 838
        this.FieldFactoryList[FieldsFactory.FIELD_TAXATIONDETAIL_RATE] = this.GetPFixDoubleField; // 839
        this.FieldFactoryList[FieldsFactory.FIELD_IS_IGNORE_UNFIXED_LIST] = this.GetPFixBooleanField; // 840
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_FRACTIONAL_TRADING] = this.GetPFixBooleanField; // 841
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_ROUTE_ID] = this.GetPFixIntField; // 842
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_NAME] = this.GetPFixStringField; // 843
        this.FieldFactoryList[FieldsFactory.FIELD_MARKET_CONSENSUS_PERCENT_TYPE] = this.GetPFixIntField; // 844
        this.FieldFactoryList[FieldsFactory.FIELD_ALGORITHM_TOP_LONG_TOP_SHORT_EXPERT_CHOICE] = this.GetPFixBooleanField; // 845
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWABLE_OPTIONS_PREMIUM] = this.GetPFixDoubleField; // 846
        this.FieldFactoryList[FieldsFactory.FIELD_FIXGATEWAY_ID] = this.GetPFixIntField;// 847
        this.FieldFactoryList[FieldsFactory.FIELD_LOSS_LIMIT_PER_TRADE] = this.GetPFixDoubleField;// 848
        this.FieldFactoryList[FieldsFactory.FIELD_PRODUCT_IS_DEFAULT] = this.GetPFixBooleanField;
        this.FieldFactoryList[FieldsFactory.FIELD_TIME_RANGE_TYPE] = this.GetPFixByteField; // 850
        this.FieldFactoryList[FieldsFactory.FIELD_TIME_RANGE_NUMBER] = this.GetPFixIntField; // 851
        this.FieldFactoryList[FieldsFactory.FIELD_ALGORITHM_CONTAINER_TYPE] = this.GetPFixByteField; // 852
        this.FieldFactoryList[FieldsFactory.FIELD_ALGORITHM_FILTER_NAME] = this.GetPFixStringField; // 853
        this.FieldFactoryList[FieldsFactory.FIELD_UNSETTLED_DEPOSIT] = this.GetPFixDoubleField; // 854
        this.FieldFactoryList[FieldsFactory.FIELD_LAST_CHANGE] = this.GetPFixDoubleField; // 855
        this.FieldFactoryList[FieldsFactory.FIELD_TITLE] = this.GetPFixStringField; // 856
        this.FieldFactoryList[FieldsFactory.FIELD_TOOLTIP] = this.GetPFixStringField; // 857
        this.FieldFactoryList[FieldsFactory.FIELD_URL] = this.GetPFixStringField; // 858
        this.FieldFactoryList[FieldsFactory.FIELD_BROKER_MESSAGE_RESPONSE_TYPE] = this.GetPFixByteField; // 859
        this.FieldFactoryList[FieldsFactory.FIELD_SHOW_IN_SETTINGS] = this.GetPFixBooleanField; // 860
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_CUSTOM_SL_TP_TRIGGER_PRICE] = this.GetPFixBooleanField; // 861
        this.FieldFactoryList[FieldsFactory.FIELD_SL_TRIGGER_PRICE] = this.GetPFixByteField; // 862
        this.FieldFactoryList[FieldsFactory.FIELD_TP_TRIGGER_PRICE] = this.GetPFixByteField; // 863
        this.FieldFactoryList[FieldsFactory.FIELD_SL_TP_TRIGGER_PRICE] = this.GetPFixByteField; // 864
        this.FieldFactoryList[FieldsFactory.FIELD_COLOR_IN_FRONT] = this.GetPFixStringField; // 865
        this.FieldFactoryList[FieldsFactory.FIELD_IS_UNREAD] = this.GetPFixBooleanField; // 866
        this.FieldFactoryList[FieldsFactory.FIELD_COUNT] = this.GetLongArrField; // 867
        this.FieldFactoryList[FieldsFactory.FIELD_SHOW_POPUP] = this.GetPFixBooleanField; // 868
        this.FieldFactoryList[FieldsFactory.FIELD_INSTRUMENT_LIST_ID] = this.GetPFixIntField; // 869
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_NAME] = this.GetPFixStringField; // 870
        this.FieldFactoryList[FieldsFactory.FIELD_INFO_MSG_TYPE_ID] = this.GetPFixByteField; // 871
        this.FieldFactoryList[FieldsFactory.FIELD_LACK_OF_MARGIN] = this.GetPFixDoubleField; // 872
        this.FieldFactoryList[FieldsFactory.FIELD_HI_PRICE_LIMIT_ENABLED] = this.GetPFixBooleanField; // 873
        this.FieldFactoryList[FieldsFactory.FIELD_LOW_PRICE_LIMIT_ENABLED] = this.GetPFixBooleanField; // 874
        this.FieldFactoryList[FieldsFactory.FIELD_SYMBOL_LOOKUP_TYPE] = this.GetPFixByteField; // 875
        this.FieldFactoryList[FieldsFactory.FIELD_UNDERLIER_DESCRIPTION] = this.GetPFixStringField;// 876
        this.FieldFactoryList[FieldsFactory.FIELD_UNDERLIER_COUNTRY_ID] = this.GetPFixIntField;// 877
        this.FieldFactoryList[FieldsFactory.FIELD_DISPLAY_METHOD] = this.GetPFixIntField;// 878
        this.FieldFactoryList[FieldsFactory.FIELD_EXT_REQUEST_ID] = this.GetPFixStringField;// 879
        this.FieldFactoryList[FieldsFactory.FIELD_MAX_PASSWORD_LENGTH] = this.GetPFixIntField;// 880
        this.FieldFactoryList[FieldsFactory.FIELD_UNDERLIER_TRADING_EXCHANGE] = this.GetPFixStringField;// 881
        this.FieldFactoryList[FieldsFactory.FIELD_LEVERAGE_SHORT] = this.GetPFixShortField;// 882
        this.FieldFactoryList[FieldsFactory.FIELD_ALIAS] = this.GetPFixStringField; // 883
        this.FieldFactoryList[FieldsFactory.FIELD_FUNDING_RATE_VALUE] = this.GetPFixDoubleField; // 884
        this.FieldFactoryList[FieldsFactory.FIELD_MARK_PRICE] = this.GetPFixDoubleField; // 885
        this.FieldFactoryList[FieldsFactory.FIELD_NEXT_FUNDING_TIME] = this.GetPFixLongField; // 886
        this.FieldFactoryList[FieldsFactory.FIELD_FUNDING_RATE_MARKUP_PLAN_ID] = this.GetPFixLongField; // 887
        this.FieldFactoryList[FieldsFactory.FIELD_MARKUP_TYPE] = this.GetPFixIntField; // 888
        this.FieldFactoryList[FieldsFactory.FIELD_MARKUP_VALUE] = this.GetPFixDoubleField; // 889
        this.FieldFactoryList[FieldsFactory.FIELD_BROKER_MESSAGE_ID] = this.GetPFixLongField; // 890
        this.FieldFactoryList[FieldsFactory.FIELD_CLIENT_PANEL] = this.GetPFixIntField; // 891
        this.FieldFactoryList[FieldsFactory.FIELD_WITHDRAWAL_AVAILABLE] = this.GetPFixDoubleField; // 892
        this.FieldFactoryList[FieldsFactory.FIELD_APP_INSTALL_ID] = this.GetPFixStringField; // 893
        this.FieldFactoryList[FieldsFactory.FIELD_CLOSE_ACCOUNT_REQUEST_ID] = this.GetPFixIntField; // 894
        this.FieldFactoryList[FieldsFactory.FIELD_PROJECTED_BALANCE] = this.GetPFixDoubleField; // 895
        this.FieldFactoryList[FieldsFactory.FIELD_USER_GROUP_IDS] = this.GetPFixIntArrayField; // 896
        this.FieldFactoryList[FieldsFactory.FIELD_SHOW_EXTENDED_SESSION] = this.GetPFixBooleanField; // 897
        this.FieldFactoryList[FieldsFactory.FIELD_AGG_TYPE] = this.GetPFixByteField; // 898
        this.FieldFactoryList[FieldsFactory.FIELD_AGG_PERIOD] = this.GetPFixByteField; // 899
        this.FieldFactoryList[FieldsFactory.FIELD_AGG_VALUE] = this.GetPFixIntField; // 900
        this.FieldFactoryList[FieldsFactory.FIELD_SEND_LAST_QUOTES] = this.GetPFixBooleanField; // 901
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_LINK_WEBCON_DARK] = this.GetPFixStringField; // 902
        this.FieldFactoryList[FieldsFactory.FIELD_EXTERNAL_LINK_WEBCON_LIGHT] = this.GetPFixStringField; // 903
        this.FieldFactoryList[FieldsFactory.FIELD_PREFERRED_INSTRUMENT_TYPE] = this.GetPFixIntField; // 904
        this.FieldFactoryList[FieldsFactory.FIELD_DAYS_PER_MONTH] = this.GetPFixByteField; // 905
        this.FieldFactoryList[FieldsFactory.FIELD_DAYS_PER_YEAR] = this.GetPFixByteField; // 906
        this.FieldFactoryList[FieldsFactory.FIELD_MAINTENANCE_MARGIN_REQ_PERCENT] = this.GetPFixDoubleField; // 907
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_EXERCISE_FOR_ITM_OPTION] = this.GetPFixBooleanField; // 908
        this.FieldFactoryList[FieldsFactory.FIELD_INITIAL_MARGIN_REQ_PERCENT] = this.GetPFixDoubleField; // 909
        this.FieldFactoryList[FieldsFactory.FIELD_WARNING_MARGIN_REQ_PERCENT] = this.GetPFixDoubleField; // 910
        this.FieldFactoryList[FieldsFactory.FIELD_STOP_OUT_VALUE] = this.GetPFixDoubleField; // 911
        this.FieldFactoryList[FieldsFactory.FIELD_STRATEGY_ID] = this.GetPFixStringField; // 912
        this.FieldFactoryList[FieldsFactory.FIELD_IS_DELISTED] = this.GetPFixBooleanField; // 913
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_DAILY_LOSS] = this.GetPFixDoubleField; // 914
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_WEEKLY_LOSS] = this.GetPFixDoubleField; // 915
        this.FieldFactoryList[FieldsFactory.FIELD_CURRENT_UNREALIZED_LOSS] = this.GetPFixDoubleField; // 916
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_UNREALIZED_LOSS] = this.GetPFixDoubleField; // 917
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_TRAILING_DRAWDOWN_LEVEL] = this.GetPFixDoubleField; // 918
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_MAX_DRAWDOWN_LEVEL] = this.GetPFixDoubleField; // 919
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_OF_MAX_RELATIVE_DRAWDOWN_LEVEL] = this.GetPFixDoubleField; // 920
        this.FieldFactoryList[FieldsFactory.FIELD_CURRENT_DAILY_PROFIT] = this.GetPFixDoubleField; // 921
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_DAILY_PROFIT] = this.GetPFixDoubleField; // 922
        this.FieldFactoryList[FieldsFactory.FIELD_CURRENT_TOTAL_POSITION_QTY] = this.GetPFixDoubleField; // 923
        this.FieldFactoryList[FieldsFactory.FIELD_CURRENT_POSITIONS] = this.GetPFixIntField; // 924
        this.FieldFactoryList[FieldsFactory.FIELD_PENDING_ORDERS] = this.GetPFixIntField; // 925
        this.FieldFactoryList[FieldsFactory.FIELD_ORDERS_COUNT_PER_DAY] = this.GetPFixIntField; // 926
        this.FieldFactoryList[FieldsFactory.FIELD_EOD_TRAILING_DRAWDOWN_LEVEL] = this.GetPFixDoubleField; // 927
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_EOD_TRAILING_DRAWDOWN_LEVEL] = this.GetPFixDoubleField; // 928
        this.FieldFactoryList[FieldsFactory.FIELD_IGNORED_INSTRUMENT_TYPES] = this.GetPFixIntArrayField; // 929
        this.FieldFactoryList[FieldsFactory.FIELD_SHORT_SWAP_INFO] = this.GetPFixStringField; // 930
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_DAILY_LOSS_LIMIT] = this.GetPFixDoubleField; // 931
        this.FieldFactoryList[FieldsFactory.FIELD_CALCULATED_RELATIVE_DAILY_LOSS_LIMIT] = this.GetPFixDoubleField; // 932
        this.FieldFactoryList[FieldsFactory.FIELD_RELATIVE_OF_RELATIVE_DAILY_LOSS_LIMIT] = this.GetPFixDoubleField; // 933
        this.FieldFactoryList[FieldsFactory.FIELD_TRADE_SESSION_OPTION_EXERCISE] = this.GetPFixBooleanField; // 934
        this.FieldFactoryList[FieldsFactory.FIELD_CROSS_RATE_FOR_POSITIONS] = this.GetPFixByteField; // 935
        this.FieldFactoryList[FieldsFactory.FIELD_DONT_SAVE_HISTORY_QUOTES_FRONTEND] = this.GetPFixBooleanField; // 936
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOW_CASH_TRADING] = this.GetPFixBooleanField; // 937
        this.FieldFactoryList[FieldsFactory.FIELD_TOTAL_POSITIONS_VALUE] = this.GetPFixDoubleField; // 938
        this.FieldFactoryList[FieldsFactory.FIELD_AVAILABLE_CASH] = this.GetPFixDoubleField; // 939
        this.FieldFactoryList[FieldsFactory.FIELD_FROM_STP] = this.GetPFixBooleanField; // 940
        this.FieldFactoryList[FieldsFactory.FIELD_AFTER_TRADE_CASH] = this.GetPFixDoubleField; // 941
        this.FieldFactoryList[FieldsFactory.FIELD_FOLLOWER_REQUEST_STATUS] = this.GetPFixByteField; // 942
        this.FieldFactoryList[FieldsFactory.FIELD_MASTER_ID] = this.GetPFixIntField; // 943
        this.FieldFactoryList[FieldsFactory.FIELD_FOLLOWER_ID] = this.GetPFixIntField; // 944 field is absent
        this.FieldFactoryList[FieldsFactory.FIELD_FOLLOWER_IDS] = this.GetPFixIntArrayField; // 945
        this.FieldFactoryList[FieldsFactory.FIELD_ENABLE_COMMENT_FIELD] = this.GetPFixBooleanField; // 946
        this.FieldFactoryList[FieldsFactory.FIELD_USER_COMMENT] = this.GetPFixStringField; // 947
        this.FieldFactoryList[FieldsFactory.FIELD_SECRET_2FA_KEY] = this.GetPFixStringField; // 948
        this.FieldFactoryList[FieldsFactory.FIELD_BRANDING_PRIORITY] = this.GetPFixIntField; // 949
        this.FieldFactoryList[FieldsFactory.FIELD_BRANDING_INSTRUMENT_TYPE] = this.GetPFixIntField; // 950
        this.FieldFactoryList[FieldsFactory.FIELD_EXECUTION_SCHEME] = this.GetPFixIntField; // 951
        this.FieldFactoryList[FieldsFactory.FIELD_ALLOCATION] = this.GetPFixDoubleField; // 952
        this.FieldFactoryList[FieldsFactory.FIELD_ASSOCIATED_USERS_ID] = this.GetPFixStringField; // 953
        this.FieldFactoryList[FieldsFactory.FIELD_REJECT_ACCOUNT_ID] = this.GetPFixIntField; // 954

        // #endregion  FieldFactoryList

        this.MaxFieldNumber = this.FieldFactoryList.length - 1;
    }

    public GetPFixBooleanField = function (id): PFixBooleanField {
        return new PFixBooleanField(id);
    };

    public GetPFixByteField = function (id): PFixByteField {
        return new PFixByteField(id);
    };

    public GetPFixDateField = function (id): PFixDateField {
        return new PFixDateField(id);
    };

    public GetPFixDoubleField = function (id): PFixDoubleField {
        return new PFixDoubleField(id);
    };

    public GetPFixFloatField = function (id): PFixFloatField {
        return new PFixFloatField(id);
    };

    public GetPFixGroupField = function (id): PFixGroupField {
        return new PFixGroupField(id);
    };

    public GetPFixIntField = function (id): PFixIntField {
        return new PFixIntField(id);
    };

    public GetPFixLongField = function (id): PFixLongField {
        return new PFixLongField(id);
    };

    public GetPFixShortField = function (id): PFixShortField {
        return new PFixShortField(id);
    };

    public GetPFixStringField = function (id): PFixStringField {
        return new PFixStringField(id);
    };

    public GetPFixPFSMessageField = function (id): PFixPFSMessageField {
        return new PFixPFSMessageField(id);
    };

    public GetPFixPublicKeyField = function (id): PFixPublicKeyField {
        return new PFixPublicKeyField(id);
    };

    public GetPFixSignatureField = function (id): PFixSignatureField {
        return new PFixSignatureField(id);
    };

    public GetPFixRawBytesFieldField = function (id): PFixByteArrayField {
        return new PFixByteArrayField(id);
    };

    public GetPFixLongGroupField = function (id): PFixLongGroupField {
        return new PFixLongGroupField(FieldsFactory.FIELD_GROUP);
    };

    public GetPFixObjectField = function (id): PFixObjectField {
        return new PFixObjectField(id);
    };

    public GetPFixDerivativeContractDateField = function (id): PFixDerivativeContractDateField {
        return new PFixDerivativeContractDateField(id);
    };

    public GetPFixCompressedRawBytesFieldField = function (id): PFixCompressedByteArrayField {
        return new PFixCompressedByteArrayField(id);
    };

    public GetPFixIntArrayField = function (id): PFixIntArrayField {
        return new PFixIntArrayField(id);
    };

    public GetStringArrField = function (id): PFixStringArrField {
        return new PFixStringArrField(id);
    };

    public GetLongArrField (id): PFixLongArrayField {
        return new PFixLongArrayField(id);
    }

    // singleton
    private static instance: FieldsFactory | null = null;
    public static getInstance (): FieldsFactory {
        if (FieldsFactory.instance == null) {
            FieldsFactory.instance = new FieldsFactory();
        }
        return FieldsFactory.instance;
    }

    public getFieldById (id): PFixField {
        const factoryMember = this.FieldFactoryList[id];
        if (factoryMember && factoryMember !== null) {
            return factoryMember(id);
        } else {
            return null;
        }
    }

    // #region  FieldsFactory
    public static FIELD_MSG_TYPE = 0;// msgType
    public static FIELD_XML_REQUEST = 1;// xmlRequest
    public static FIELD_PFS_MESSAGE = 2;// pfsmessage
    public static FIELD_LOGIN = 3;// pfsmessage
    public static FIELD_PASSWORD = 4;// pfsmessage
    public static FIELD_SESSIONID = 5;// pfsmessage
    public static FIELD_ID = 6;
    public static FIELD_AMOUNT = 7;
    public static FIELD_AMOUNT_LONG = 8;
    public static FIELD_BALANCE = 9;
    public static FIELD_CLOSEORDER_ID = 10;
    public static FIELD_OPENORDER_ID = 11;
    public static FIELD_DATE = 12;
    public static FIELD_PRICE = 13;
    public static FIELD_NUMBER = 14;
    public static FIELD_BOUNDTOORDERID = 15;
    public static FIELD_NONE = 16;
    public static FIELD_COMMENT = 17;
    public static FIELD_ORDER_ID = 18;
    public static FIELD_REQUEST_ID = 19;

    public static FIELD_LOTSIZE = 20;
    public static FIELD_POINTSIZE = 21;
    public static FIELD_NAME = 22;
    public static FIELD_NAME_EXP1 = 23;
    public static FIELD_NAME_EXP2 = 24;
    public static FIELD_INSTRUMENT_ID = 25;
    public static FIELD_INSTRUMENTTYPE_ID = 26;
    public static FIELD_TRADE_MODE = 27;
    public static FIELD_DESCRIPTION = 28;
    public static FIELD_BARS_TYPE = 29;
    public static FIELD_NUMBER_OF_SHARES = 30;
    public static FIELD_BEGIN_DAY_DATE = 31;
    public static FIELD_END_DAY_DATE = 32;
    public static FIELD_ROUTE_LIST = 33;
    public static FIELD_TRADE_ROUTE_LIST = 34;
    public static FIELD_MARGIN_TYPE = 35;
    public static FIELD_BASE_LEVERAGE_NEG = 36;
    public static FIELD_BASE_LEVERAGE_POS = 37;
    public static FIELD_COUNTER_LEVERAGE_NEG = 38;
    public static FIELD_COUNTER_LEVERAGE_POS = 39;
    public static FIELD_HOLD_MARGINGSIZE = 40;
    public static FIELD_HOLD_MARGINGSIZE10 = 41;
    public static FIELD_HOLD_MARGINGSIZE25 = 42;
    public static FIELD_HOLD_MARGINGSIZE50 = 43;
    public static FIELD_INIT_MARGINGSIZE = 44;
    public static FIELD_INIT_MARGINGSIZE10 = 45;
    public static FIELD_INIT_MARGINGSIZE25 = 46;
    public static FIELD_INIT_MARGINGSIZE50 = 47;
    public static FIELD_IS_USE_LEVERAGE = 48;
    public static FIELD_MARGIN_COEF = 49;
    public static FIELD_SWAP_BUY = 50;
    public static FIELD_SWAP_SELL = 51;
    public static FIELD_SWAP_MEASURE = 52;
    public static FIELD_TYPE = 53;
    public static FIELD_BASE_INSTRUMENT_ID = 54;
    public static FIELD_FORMULA_TYPE = 55;
    public static FIELD_STRIKE_INCREMENT = 56;
    public static FIELD_STRIKE_NUMBE = 57;
    public static FIELD_FIRST_BASE_STRIKE_PRICE = 58;
    public static FIELD_START_TIME = 59;
    public static FIELD_EXPIRATION_PERIOD_NUM = 60;
    public static FIELD_EXPIRATION_PERIOD_DURATION = 61;
    public static FIELD_EXP_DATES = 62;
    public static FIELD_STRIKE_PRICES = 63;
    public static FIELD_MARGIN_MODE = 64;
    public static FIELD_SPREAD_MODE = 65;
    public static FIELD_SPREAD_MEASURE = 66;
    public static FIELD_SPREAD = 67;
    public static FIELD_BIDSHIFT = 68;
    public static FIELD_ASKSHIFT = 69;
    public static FIELD_IS_HEDGED_MARGIN_SIZE = 70;
    public static FIELD_BEGIN_PRE_SES_DATE = 71;
    public static FIELD_END_POST_SES_DATE = 72;
    public static FIELD_IS_OPTIONS = 73;

    public static FIELD_GROUP = 74;

    public static FIELD_ORDER_TYPE = 75;
    public static FIELD_OPERATION_TYPE = 76;
    public static FIELD_IS_UNFIXED_LIST = 77;
    public static FIELD_IS_BY_BROKER = 78;
    public static FIELD_VALIDITY = 79;
    public static FIELD_EXP_DAY = 80;
    public static FIELD_EXP_MONTH = 81;
    public static FIELD_EXP_YEAR = 82;
    public static FIELD_STRIKE_PRICE = 83;
    public static FIELD_OPTION_TYPE = 84;
    public static FIELD_ROUTE_ID = 85;
    public static FIELD_SL_PRICE = 86;
    public static FIELD_TP_PRICE = 87;
    public static FIELD_TR_OFFSET = 88;
    public static FIELD_ACCOUNT_ID = 89;
    public static FIELD_USER_ID = 90;
    public static FIELD_CLIENT_ORDER_ID = 91;
    public static FIELD_SEQUANCE_ID = 92;
    public static FIELD_ORDER_ACTION_TYPE = 93;

    public static FIELD_PROTOCOL_ID = 94;
    public static FIELD_VERIFICATION_PASSWORD = 95;
    public static FIELD_DATE_OF_BUILD = 96;
    public static FIELD_CONNECTION_MODE = 97;
    public static FIELD_CLIENT_TYPE = 98;

    public static FIELD_FIRSTNAME = 99;
    public static FIELD_LASTNAME = 100;
    public static FIELD_MIDDLENAME = 101;
    public static FIELD_STREETADDRESS = 102;
    public static FIELD_CITY = 103;
    public static FIELD_COUNTRY = 104;
    public static FIELD_ZIP = 105;
    public static FIELD_STATE = 106;
    public static FIELD_ACCOUNTS = 107;
    public static FIELD_NEED_VERIFICATION_PWD = 108;
    public static FIELD_PWD_EXPIRED = 109;
    public static FIELD_TIME_ZONE_OFFSET = 110;
    public static FIELD_SUBSCRIPTION_TYPE = 111;
    public static FIELD_SUBSCRIPTION_ACTION = 112;

    public static FIELD_SENDER_ID = 113;
    public static FIELD_TARGET_ID = 114;
    public static FIELD_TEXT = 115;
    public static FIELD_THEME = 116;
    public static FIELD_SOURCE = 117;
    public static FIELD_LINE_NUMBER = 118;
    public static FIELD_NEWS_ID = 119;
    public static FIELD_SYMBOL = 120;
    public static FIELD_PRIORITY = 121;
    public static FIELD_IS_UPPRCASE_SEARCH = 122;
    public static FIELD_IS_CONTENT_SEARCH = 123;
    public static FIELD_IS_EVENT_SEARCH = 124;
    public static FIELD_LIGHT_SUBS = 125;
    public static FIELD_POSITION_ID = 126;
    public static FIELD_CREATED_AT = 127;
    public static FIELD_ACTIVE_FLAG = 128;
    public static FIELD_FILLED_AMOUNT = 129;
    public static FIELD_AVERAGE_FILLED_PRICE = 130;
    public static FIELD_ORDER_GROUP_ID = 131;
    public static FIELD_MASTER_ORDER_ID = 132;
    public static FIELD_STOP_PRICE = 133;
    public static FIELD_REFFERANCE_ID = 134;
    public static FIELD_ORDER_STATUS = 135;
    public static FIELD_OPEN_PRICE = 136;
    public static FIELD_CLOSE_PRICE = 137;
    public static FIELD_SWAP = 138;
    public static FIELD_SL_ORDER_ID = 139;
    public static FIELD_TP_ORDER_ID = 140;
    public static FIELD_CLOSE_TIME = 141;
    public static FIELD_COMMISION = 142;
    public static FIELD_IS_TRAILING = 143;
    public static FIELD_LAST_ORDER_ID = 144;
    public static FIELD_TRADE_ID = 145;
    public static FIELD_PNL = 146;
    public static FIELD_EXT_ID = 147;
    public static FIELD_CREATOR_NAME = 148;
    public static FIELD_EXTERNAL_PRICE = 149;
    public static FIELD_IS_BUY = 150;
    public static FIELD_EXCHANGE = 151;
    public static FIELD_IS_OPEN = 152;

    public static FIELD_RANGE = 153;
    public static FIELD_XML_BODY = 154;

    public static FIELD_SERVER_IDS = 155;
    public static FIELD_HOLIDAYS = 156;
    public static FIELD_ALLOW_DAY = 157;
    public static FIELD_ALLOW_GTC = 158;
    public static FIELD_ALLOW_GTD = 159;
    public static FIELD_ALLOW_IOC = 160;
    public static FIELD_ALLOW_MARKET = 161;
    public static FIELD_ALLOW_MOC = 162;
    public static FIELD_ALLOW_STOP = 163;
    public static FIELD_ALLOW_LIMIT = 164;
    public static FIELD_ALLOW_STOP_TO_LIMIT = 165;
    public static FIELD_ALLOW_TRAILING_STOP = 166;
    public static FIELD_ALLOW_TRADE_STATUS = 167;
    public static FIELD_COMMISION_PLAN_ID = 168;
    public static FIELD_ALLOW_MOO = 169;
    public static FIELD_IS_MARGINCALL = 170;
    public static FIELD_UNSECURE_DEVICE_ACCEPTED = 171;
    public static FIELD_GROUP_ID = 172;
    public static FIELD_LEVRAGE = 173;
    public static FIELD_CURRENCY = 174;
    public static FIELD_CROSS_TYPE = 175;
    public static FIELD_CROSS_INSTRUMENT_ID = 176;
    public static FIELD_BLOCKED_SUM = 177;
    public static FIELD_MIN_CHANGE = 178;
    public static FIELD_TRADING_LEVEL = 179;
    public static FIELD_WARNING_LEVEL = 180;
    public static FIELD_MARGIN_LEVEL = 181;
    public static FIELD_BROCKER_ID = 182;
    public static FIELD_BEGIN_BALANCE = 183;
    public static FIELD_LOCKED_FOR_ORDERS = 184;
    public static FIELD_COMUNITY = 185;
    public static FIELD_SUBSCRIBE_PLAN_ID = 186;
    public static FIELD_BID = 187;
    public static FIELD_ASK = 188;
    public static FIELD_BID_SIZE = 189;
    public static FIELD_ASK_SIZE = 190;
    public static FIELD_OPEN = 191;
    public static FIELD_HIGH = 192;
    public static FIELD_LOW = 193;
    public static FIELD_PRE_CLOSE = 194;
    public static FIELD_LAST_PRICE = 195;
    public static FIELD_VOLUME_TOTAL = 196;
    public static FIELD_BID_ID = 197;
    public static FIELD_ASK_ID = 198;
    public static FIELD_QUOTE_ID = 199;
    public static FIELD_SIDE = 200;
    public static FIELD_SIZE = 201;
    public static FIELD_DAY_TRADE_VOLUME = 202;

    public static FIELD_ON_BEHALF_USER_ID = 203;
    public static FIELD_SUPER_ID = 204;
    public static FIELD_SORT_ID = 205;
    public static FIELD_IS_OPEN_PRICE_IN_MARGIN = 206;
    public static FIELD_IS_MASTER = 207;
    public static FIELD_LAST_SIZE = 208;
    public static FIELD_CROSS_PRICE = 209;
    public static FIELD_IS_CLOSED = 210;
    public static FIELD_UNDERLIER = 211;
    public static FIELD_ORDER_REF_ID = 212;
    public static FIELD_TIMESTAMP = 213;
    public static FIELD_TEXT_MESSGE_TYPE = 214;
    public static FIELD_BROCKER_IDS = 215;
    public static FIELD_IS_LOCK = 216;
    public static FIELD_NEWS_CATEGORY = 217;
    public static FIELD_NEWPASSWORD = 218;
    public static FIELD_MODE = 219;
    public static FIELD_IS_MD5 = 220;
    public static FIELD_ACCS = 221;
    public static FIELD_INSTRUMENT_LIST = 222;
    public static FIELD_RULES_LIST = 223;
    public static FIELD_REPORTS = 224;
    public static FIELD_IS_PWD_EXPIRED = 225;
    public static FIELD_PHONE = 226;
    public static FIELD_PHONE_PWD = 227;
    public static FIELD_EMAIL = 228;
    public static FIELD_IS_BLOCKED = 229;
    public static FIELD_VALUE = 230;
    public static FIELD_KEY = 231;
    public static FIELD_TEMPLATE = 232;
    public static FIELD_DERIVATIVE_POSITION_ID = 233;
    public static FIELD_IS_DEPSIT = 234;
    public static FIELD_PAGE_NUM = 235;
    public static FIELD_MAX_ROW = 236;
    public static FIELD_TABLE_NAME = 237;
    public static FIELD_BRANDING_KEY = 238;
    /// <summary>
    /// это хеш-код сообщения, подписанный private ключом
    /// </summary>
    public static FIELD_SIGNATURE = 239;
    public static FIELD_ACC_STATE = 240;
    public static FIELD_TODAY_FEES = 241;
    public static FIELD_MINIMAL_LOT = 242;
    public static FIELD_LOT_STEP = 243;
    /// <summary>
    /// это - публичный ключ, который присылает сервер, чтобы я мог проверять подлинность его сообщений
    /// </summary>
    public static FIELD_PUBLIC_KEY = 244;
    public static FIELD_EXPIRE_AT = 245;
    public static FIELD_MODIFIED_AMOUNT = 246;
    public static FIELD_ALLOW_FOK = 247;
    public static FIELD_MAX_PFIX_FIELD_INDEX = 248;

    public static FIELD_TYPE_MODIFICATION = 249;
    public static FIELD_AMOUNT_MODIFICATION = 250;
    public static FIELD_TIF_MODIFICATION = 251;
    public static FIELD_PRICE_MODIFICATION = 252;
    public static FIELD_OPERAIONTYPE_MODIFICATION = 253;
    public static FIELD_EXPIRATION_DATE_MODIFICATION = 254;
    public static FIELD_TIFS_FOR_ORDERTYPES = 255;

    public static FIELD_CLEARING_SPAN = 256;
    public static FIELD_INVESTMENT_SPAN = 257;
    public static FIELD_MANUAL_CONFIRM = 258;
    public static FIELD_LIQUIDATION_LEVEL = 259;
    public static FIELD_REWARD_ALGORITHM = 260;
    public static FIELD_REWARD_FROM_TOTAL = 261;
    public static FIELD_GOVERNOR_ACCOUNT_ID = 262;
    public static FIELD_TRADING_VISIBLE = 263;
    public static FIELD_GROSS_PNL = 264;
    public static FIELD_REINVEST_CYCLES = 265;
    public static FIELD_REINVEST_PROFIT = 266;
    public static FIELD_SL_EXPIRE_AT = 267;
    public static FIELD_TP_EXPIRE_AT = 268;

    public static FIELD_ORDER_LINK_TYPE = 269;
    public static FIELD_CLIENT_ORDER_ID_TO_LINK = 270;

    // fields for trade sessions
    public static FIELD_SESSION_DESCR = 271;
    public static FIELD_SESSION_BEGIN = 272;
    public static FIELD_SESSION_END = 273;
    public static FIELD_SESSION_TYPE = 274;

    public static FIELD_TRADE_COUNT = 275;
    public static FIELD_CASH_BALANCE = 276;

    public static FIELD_PRECISION = 277;

    public static FIELD_ROLLOVER_SPAN = 278;
    public static FIELD_POSITION_CLOSER_SETUP = 279;
    public static FIELD_FUND_TYPE = 280;
    public static FIELD_ACCOUNT_ID_OF_USER = 281;
    public static FIELD_IS_SIGNED = 282;
    public static FIELD_MINIMAL_SHARE_COUNT = 283;

    public static FIELD_MONTH_AVG_GROSS_PER_SHARE = 284;
    public static FIELD_OPEN_PRICE_1MONTH = 285;
    public static FIELD_OPEN_PRICE_3MONTH = 286;
    public static FIELD_OPEN_PRICE_6MONTH = 287;
    public static FIELD_OPEN_PRICE_1YEAR = 288;
    public static FIELD_AVG_DAY_PRICE_CHANGE = 289;
    public static FIELD_AVG_MONTH_PRICE_CHANGE = 290;
    public static FIELD_ANNUAL_GAIN = 291;
    public static FIELD_AVG_EXPOSURE = 292;
    public static FIELD_MAX_DRAWDOWN = 293;
    public static FIELD_MAX_DRAWDOWN_DATE = 294;
    public static FIELD_RECOVERY_FACTOR = 295;
    public static FIELD_PAYOFF_RATIO = 296;
    public static FIELD_SHARPE_RATIO = 297;
    public static FIELD_VALUE_AT_RISK = 298;
    public static FIELD_SORTINO_RATIO_R = 299;
    public static FIELD_SORTINO_RATIO_G = 300;
    public static FIELD_CLIENT_ORDER_GROUP_ID = 301;
    public static FIELD_PROFIT_FACTOR = 302;
    public static FIELD_DAYS = 303;

    public static FIELD_RAW_BYTES = 304;
    public static FIELD_END_TIME = 305;
    public static FIELD_VERSION = 306;
    public static FIELD_IS_COMPRESSED = 307;
    public static FIELD_HISTORY_PERIOD_TYPE = 308;
    public static FIELD_POINTER = 309;
    public static FIELD_TICK_COAST = 310;
    public static FIELD_COUNTER_ID = 311;
    public static FIELD_ALLOW_MAM_ORDERS = 312;
    public static FIELD_RESERVED_BALANCE = 313;
    public static FIELD_IS_AUTOTRADE_ORDER = 314;
    public static FIELD_SL_TR_OFFSET = 315;
    public static FIELD_HEDGED_MARGIN_CALL_COEFF = 316;
    public static FIELD_IS_OK = 317;
    public static FIELD_USED_MARGIN = 318;
    public static FIELD_OPEN_INTEREST = 319;
    public static FIELD_CONTRACT_MONTH_DATE = 320;
    public static FIELD_LAST_TRADE_DATE = 321;
    public static FIELD_SETTLEMENT_DATE = 322;
    public static FIELD_NOTICE_DATE = 323;
    public static FIELD_FIRST_TRADE_DATE = 324;
    public static FIELD_AUTO_CLOSE_DATE = 325;
    public static FIELD_INDICATIVE_AUCTION_PRICE = 326;
    public static FIELD_CONTRACT_DELIVERY_STATUS = 327;
    public static FIELD_PER_ORDER_PRICE = 328;
    public static FIELD_FREE_COMMISSION_AMOUNT = 329;
    public static FIELD_DEALER_COMMISSION = 330;
    public static FIELD_MAX_PER_ORDER = 331;
    public static FIELD_MIN_PER_ORDER = 332;
    public static FIELD_DELIVERY_METHOD = 333;
    public static FIELD_QUOTE_ROUTE_ID = 334;
    public static FIELD_LAST_AMOUNT = 335;
    public static FIELD_IS_CONTINUOUS_CONTRACT = 336;
    public static FIELD_MANUAL_CONFIRM_EXIT = 337;
    public static FIELD_CREDIT_VALUE = 338;
    public static FIELD_MANAGER_SHARE_COUNT = 339;
    public static FIELD_MANAGER_CAPITAL = 340;
    public static FIELD_INVESTOR_COUNT = 341;
    public static FIELD_VALUED_DATE_BASIS = 342;
    public static FIELD_OPERATION_MODE = 343;
    public static FIELD_BUY_INIT = 344;
    public static FIELD_BUY_SUPP = 345;
    public static FIELD_SELL_INIT = 346;
    public static FIELD_SELL_SUPP = 347;
    public static FIELD_LOGON_FOR_TRADE = 348;
    public static FIELD_QUOTE_DELAY = 349;
    public static FIELD_NODE_ID = 350;
    public static FIELD_NODE_REPORTABLE = 351;
    public static FIELD_ADDRESS_PROTOCOL = 352;
    public static FIELD_IS_HOST_NODE = 353;
    public static FIELD_NODE_LOAD = 354;
    public static FIELD_LAST_ROLLOVER_TIME = 355;
    public static FIELD_NEXT_ROLLOVER_TIME = 356;

    public static FIELD_CHANGE_PWD_STATUS = 357;
    public static FIELD_AVAILABLE_MARGIN = 358;
    public static FIELD_MAINTANCE_MARGIN = 359;
    public static FIELD_DEFICIENCY_MARGIN = 360;
    public static FIELD_SURPLUS_MARGIN = 361;

    public static FIELD_INTRADAY_RISK_MANAGEMENT = 362;
    public static FIELD_ACCOUNT_TRADE_STATUS = 363;
    public static FIELD_QUOTE_HISTORY_DEPTH_LIMIT_REACHED = 364;
    public static FIELD_MAX_DAILY_LOSS_LIMIT = 365;
    public static FIELD_MAX_DAY_VOLUME = 366;
    public static FIELD_MAX_ORDERS_PER_DAY = 367; // не используется. Вместо него используется рул VALUE_MAX_DAY_ORDERS
    public static FIELD_MAX_POSITIONS = 368;
    public static FIELD_MAX_PENDING_ORDERS = 369;
    public static FIELD_MAX_ORDER_CAPITAL = 370;
    public static FIELD_MAX_LOT = 371;
    public static FIELD_MAX_POSITION_QTY_PER_SYMBOL = 372;
    public static FIELD_STOP_TRADING_REASON = 373;
    public static FIELD_OPEN_CROSS_PRICE = 374;
    public static FIELD_USE_SAME_CROSSPRICEFOR_OPEN_CLOSE = 375;
    public static FIELD_IS_DELAY = 376;
    public static FIELD_DELAY_PERIOD = 377;

    public static FIELD_SESSION_DAY_TEMPLATE = 378;
    public static FIELD_SESSION_DAY_PERIOD = 379;
    public static FIELD_SESSION_PERIOD_TYPE = 380;
    public static FIELD_SESSION_SUB_PERIOD_TYPE = 381;
    public static FIELD_SESSIOSN_IS_INTRADAY = 382;
    public static FIELD_SESSION_ALLOWED_ORDER_TYPES = 383;
    public static FIELD_SESSION_ALLOWED_OPERATIONS = 384;
    public static FIELD_DAY_INDEX = 385;
    public static FIELD_TRADE_SESSION_ID = 386;
    public static FIELD_INIT_MARGINGSIZE_OVERNIGHT = 387;
    public static FIELD_HOLD_MARGINGSIZE_OVERNIGHT = 388;
    public static FIELD_INIT_MARGINGSIZE_SHORT = 389;
    public static FIELD_HOLD_MARGINGSIZE_SHORT = 390;
    public static FIELD_INIT_MARGINGSIZE_OVERNIGHT_SHORT = 391;
    public static FIELD_HOLD_MARGINGSIZE_OVERNIGHT_SHORT = 392;
    public static FIELD_USE_OVERNIGHT_MARGIN = 393;
    public static FIELD_USE_LONGSHORT_MARGIN = 394;
    public static FIELD_ALLOW_OVERNIGHT_TRADING = 395;
    public static FIELD_TRADE_SESSION_CURRENT_PERIOD_ID = 396;

    public static FIELD_STOPOUT_TYPE = 397; // int
    public static FIELD_DERIVATIVE_EXPIRATION_TEMLATE = 398; // int

    public static FIELD_PRICE_LOW_LIMIT = 399; // double
    public static FIELD_PRICE_HI_LIMIT = 400; // double
    public static FIELD_USE_DST = 401; // bool
    public static FIELD_PRICE_LIMIT_MESURE = 402; // byte
    public static FIELD_IS_DECREASE_ONLY_POSITION_COUNT = 403; // bool
    public static FIELD_DAY_TYPE = 404; // byte   - для session HolidayList
    public static FIELD_ERROR_CODE = 405;
    public static FIELD_FIRST_CLEARING_TIME = 406;
    public static FIELD_CONTRACT_SIZE = 407;
    public static FIELD_IS_REMOVED = 408;
    public static FIELD_TIME_ZONE_ID = 409;
    public static FIELD_OPEN_PRE_MARKET = 410;
    public static FIELD_CLOSE_POST_MARKET = 411;
    public static FIELD_HIGH_GENERAL = 412;
    public static FIELD_LOW_GENERAL = 413;
    public static FIELD_TICKS = 414;
    public static FIELD_TICKS_PRE_MARKET = 415;
    public static FIELD_TICKS_POST_MARKET = 416;
    public static FIELD_VOLUME_PRE_MARKET = 417;
    public static FIELD_VOLUME_POST_MARKET = 418;
    public static FIELD_IS_INVESTOR = 419;
    public static FIELD_ACCOUNT_OPERATION_ID = 420;
    public static FIELD_OWNER_TYPE = 421;
    public static FIELD_VOLUME = 422;
    public static FIELD_BID_VOLUME = 423;
    public static FIELD_ASK_VOLUME = 424;
    public static FIELD_COMMISSION_TYPE = 425;
    public static FIELD_COMMISSION_PAYMENT_TYPE = 426;
    public static FIELD_COUNTER_ACCOUNT_ID = 427;
    public static FIELD_COMMISSION_ACTIVATEIB = 428;
    public static FIELD_APPLY_OPERTIONTYPE = 429;
    public static FIELD_FROM_AMOUNT = 430;
    public static FIELD_TO_AMOUNT = 431;
    public static FIELD_COMMISSION_VALUE = 432;
    public static FIELD_COMMISSION_FOR_TRANSFER_VALUE = 433;
    public static FIELD_SPREAD_PLAN_ID = 434;
    public static FIELD_SETTLEMENT_PRICE = 435;
    public static FIELD_PREV_SETTLEMENT_PRICE = 436;
    public static FIELD_MAIN_CLOSE_PRICE = 437;
    public static FIELD_INTEREST = 438;
    public static FIELD_ASSET_ID = 439;
    public static FIELD_ACCOUNT_TYPE = 440;
    public static FIELD_TURNOVER = 441;
    public static FIELD_DELIVERY_METHOD_ID = 442;
    public static FIELD_IS_NEWS_ROUTE = 443;
    public static FIELD_SETTLEMENT_CROSS_PRICE = 444;
    public static FIELD_NODE_FREE_CONNECTIONS = 445;
    public static FIELD_LONG_GROUP = 446;
    public static FIELD_REBATES = 447;
    public static FIELD_TODAY_REBATES = 448;
    public static FIELD_ENABLE_CALL = 449;
    public static FIELD_ENABLE_PUT = 450;
    public static FIELD_UNDERLIER_DATE = 451;
    public static FIELD_EXERCISE_STYLE = 452;
    public static FIELD_CHECK_INTRINSIC_VALUE = 453;
    public static FIELD_UNDERLIER_TYPE = 454;
    public static FIELD_EXCHANGE_ID = 455;
    public static FIELD_ALLOW_INTER_MONTH_SPREAD = 456;
    public static FIELD_ALLOW_INTER_COMMODITY_SPREAD_CHARGE = 457;
    public static FIELD_ALLOW_DELIVERY_MONTH_SPREAD_CHARGE = 458;
    public static FIELD_MD_SOURCE = 459;
    public static FIELD_COUNTER_ORDER_ID = 460;
    public static FIELD_CONNECTION_TYPE_MAIN = 461;
    public static FIELD_INVESTOR_ID = 462;
    public static FIELD_IS_SHORTABLE = 463;
    public static FIELD_COMMISSION_MIN_VALUE = 464; // double
    public static FIELD_COMMISSION_MAX_VALUE = 465; // double
    public static FIELD_CROSS_MARKUP_MEASURE = 466;
    public static FIELD_CROSS_MARKUP = 467;
    public static FIELD_CROSS_PLAN_ID = 468;
    public static FIELD_ACTIVATE_MAM_FEE = 469;
    public static FIELD_MAM_FEE_COUNTERACCOUNT = 470;
    public static FIELD_MAM_FEE_CURRENCYID = 471;
    public static FIELD_MAM_ACCOUNT_PERFORMANCE_FEE = 472;
    public static FIELD_MAM_ACCOUNT_MANAGEMENT_FEE = 473;
    public static FIELD_MAM_ACCOUNT_MANAGEMENT_FEE_TYPE = 474;
    public static FIELD_PRICE_TYPE_FOR_RISK = 475;
    public static FIELD_ACCOUNT_VISIBILITY_FLAG = 476;
    public static FIELD_CLIENT_PANEL_ID = 477;
    public static FIELD_INDICATIVE_AUCTION_VOLUME = 478;
    public static FIELD_QUOTE_HISTORY_LIMIT_REACHED = 479;
    public static FIELD_EXECUTION_TYPE = 480; // #34535
    public static FIELD_MARKUP_FORMULA_TYPE = 481;
    public static FIELD_RISK_PLAN_ID = 482;
    public static FIELD_UPDATE_TYPE_FLAG = 483;
    public static FIELD_TRADING_POSITION_TYPE = 484;
    public static FIELD_MATURITY_DATE = 485;
    public static FIELD_USE_EXPIRED_DATE_ON_TICKER = 486;
    public static FIELD_TOTAL_GAIN = 487;
    public static FIELD_ABS_TOTAL_GAIN = 488;
    public static FIELD_ABS_ANNUAL_GAIN = 489;
    public static FIELD_BLOCK_TRADING = 490;
    public static FIELD_HI_OPEN_INTEREST = 491;
    public static FIELD_LOW_OPEN_INTEREST = 492;
    public static FIELD_LAST_TRADED_TIME = 493;
    public static FIELD_AVG_TRADED_PRICE = 494;
    public static FIELD_TOTAL_BUY_QTY = 495;
    public static FIELD_TOTAL_SELL_QTY = 496;
    public static FIELD_AUCTION_NUMBER = 497;
    public static FIELD_AUCTION_STATUS = 498;
    public static FIELD_INITIATOR_TYPE = 499;
    public static FIELD_INITIATOR_PRICE = 500;
    public static FIELD_INITIATOR_QTY = 501;
    public static FIELD_ORDER_IS_WAS_MANUAL = 502;
    public static FIELD_CONTRACT_ID = 503;
    public static FIELD_NUMBERS_OF_ORDERS = 504; // #40994
    public static FIELD_INTERESTS_ON_BALANCE_PLAN_ID = 505; // #40740
    public static FIELD_DATE_FORMAT = 506;
    public static FIELD_OPTIONS_USE_LONG_CP = 507;
    public static FIELD_OPTIONS_SEPARATOR = 508;
    public static FIELD_OPTIONS_USE_DESC = 509;
    public static FIELD_DISCLOSED_QUANTITY = 510;
    public static FIELD_SWAP_PLAN_ID = 511;
    public static FIELD_CHARGING_PLAN_ID = 512;
    public static FIELD_EXTERNAL_ORDER_ID = 513;
    public static FIELD_EXTERNAL_ORDER_STATUS = 514;
    public static FIELD_FIFTY_TWO_WEEK_HIGH_PRICE = 515; // #37671
    public static FIELD_FIFTY_TWO_WEEK_LOW_PRICE = 516; // #37671
    public static FIELD_BARS_TYPE_CHART = 517; // #46233
    public static FIELD_INSTRUMENT_BAR_TYPE = 518; // #46480, #46585
    public static FIELD_SPREAD_TYPE = 519; // #48794, #48798
    public static FIELD_SPREAD_ORDER_VALUE = 520; // #48794, #48798
    public static FIELD_SPREAD_ORDER_MEASURE = 521; // #48794, #48798
    public static FIELD_USE_UNSETTLED_PROFIT = 522; // #49418
    public static FIELD_UNSETTLED_PROFIT = 523;
    public static FIELD_UNSETTLED_LOSS = 524;
    public static FIELD_COMPRESSED_DATA = 525;
    public static FIELD_RESPONCE_END = 526;
    public static FIELD_ASSET_ID2 = 527; // #50974 - id второго ассета в CrossrateMessage
    public static FIELD_MAX_PENDING_ORDERS_VALUE = 528; // #50402
    public static FIELD_VAR_COEFF = 529;
    public static FIELD_USE_VAR_ONLY_INTRADAY = 530;
    public static FIELD_VAR_COEFF_APPLYING = 531;
    public static FIELD_USE_UNSETTLED_PROFIT_FOR_STOCKS = 532; // #52222
    public static FIELD_ASSET_NAME = 533;
    public static FIELD_TRANSACT_TIME = 534;
    public static FIELD_DAY_BAR_UPDATE_MODE = 535;
    public static FIELD_COMMISSION_MIN_TRADED_VALUE = 536;
    public static FIELD_COMMISSION_FINAL_VALUE = 537;
    public static FIELD_MATURITY_DATE_VALUE = 538;
    public static FIELD_FACE_VALUE = 539;
    public static FIELD_COUPON_RATE_VALUE = 540;
    public static FIELD_COUPON_CYCLE_VALUE = 541;
    public static FIELD_YIELD_RATE_VALUE = 542;
    public static FIELD_SOURCE_ID = 543;
    public static FIELD_DERIVATIVE_DATE_TYPE = 544;
    public static FIELD_SOURCE_BUYER_SELLER_ID = 545;
    public static FIELD_MAX_WEEKLY_LOSS_LIMIT = 546;
    public static FIELD_TRAILING_DRAWDOWN_LEVEL = 547;
    public static FIELD_TOTAL_MAX_POSITION_QTY = 548;
    public static FIELD_SUBSCRIPTION_PERIODICITY = 549;
    public static FIELD_CLIENT_SHOW_MODE = 550;
    public static FIELD_QUOTING_TYPE = 551;
    public static FIELD_2FA_TYPE = 552;
    public static FIELD_CURRENT_DAILY_LOSS = 553;
    public static FIELD_CURRENT_WEEKLY_LOSS = 554;
    public static FIELD_PARAMS = 555;
    public static FIELD_SL_PRICE_TYPE = 556;
    public static FIELD_TP_PRICE_TYPE = 557;
    public static FIELD_QUOTE_TYPE = 558;
    public static FIELD_STOCK_REQ = 559;
    public static FIELD_PAPER_STOCK_REQ = 560;
    public static FIELD_COMMISSION_TYPE_FOR_TRANSFER = 561;
    public static FIELD_COMMISSION_TYPE_FOR_WITHDRAWAL = 562;
    public static FIELD_TRANSFER_COMMISSION_PERCENTAGE = 563;
    public static FIELD_WITHDRAWAL_COMMISSION_PERCENTAGE = 564;
    public static FIELD_TRANSFER_COMMISSION_FIXED_FEE = 565;
    public static FIELD_WITHDRAWAL_COMMISSION_FIXED_FEE = 566;
    public static FIELD_TRANSFER_COMMISSION_CURRENCY_ID = 567;
    public static FIELD_WITHDRAWAL_COMMISSION_CURRENCY_ID = 568;
    public static FIELD_TRANSFER_COMMISSION_MIN_FEE = 569;
    public static FIELD_WITHDRAWAL_COMMISSION_MIN_FEE = 570;
    public static FIELD_TRANSFER_COMMISSION_MAX_FEE = 571;
    public static FIELD_WITHDRAWAL_COMMISSION_MAX_FEE = 572;
    public static FIELD_TRANSFER_COMM_COUNTER_ACCOUNT_ID = 573;
    public static FIELD_WITHDRAWAL_COMM_COUNTER_ACCOUNT_ID = 574;
    public static FIELD_ACCRUED_INTEREST = 575;
    public static FIELD_ISSUE_DATE = 576;
    public static FIELD_USED_UNSETTLED_NEGATIVE_PREMIUM = 577;
    public static FIELD_UNSETTLED_POSITIVE_PREMIUM = 578;
    public static FIELD_UNSETTLED_PREMIUM_FROM_OPEN_SELL = 579;
    public static FIELD_USED_PREMIUM_FROM_OPEN_SELL = 580;
    public static FIELD_IS_ACTIVE = 581;
    public static FIELD_IS_ACTIVATED = 582;
    public static FIELD_USED_UNSETTLED_NEGATIVE_CASH_FOR_STOCKS = 583;
    public static FIELD_UNSETTLED_POSITIVE_CASH_FOR_STOCKS = 584;
    public static FIELD_OPTION_TRADING_STYLE = 585;
    public static FIELD_PRODUCT_TYPE = 586;
    public static FIELD_UNUSED_UNSETTLED_NEGATIVE_PREMIUM = 587;
    public static FIELD_UNUSED_PREMIUM_FROM_OPEN_SELL = 588;
    public static FIELD_UNUSED_UNSETTLED_NEGATIVE_CASH_FOR_STOCKS = 589;
    public static FIELD_CREDIT_FOR_SELL = 590;
    public static FIELD_LIQUIDITY_RATE = 591;
    public static FIELD_TOTAL_QUANTITY = 592;
    public static FIELD_TOTAL_QUANTITY_FOR_MARGIN_AVAILABLE = 593;
    public static FIELD_CALCULATION_METHOD = 594;
    public static FIELD_TODAY_TRADED_QTY = 595;
    public static FIELD_ASSET_SHARES_NAMES = 596;
    public static FIELD_STOCKS_LIQUIDITY = 597;

    // TRADING IDEAS: #60004
    public static FIELD_TRADING_SIGNAL_ID = 598;
    public static FIELD_TRADING_SYSTEM_ID = 599;
    public static FIELD_TRADING_SIGNAL_TITLE = 600;
    public static FIELD_TRADING_STRATEGY_SUBSCRIPTION_STATUS = 601;
    public static FIELD_TRADING_SIGNAL_LIFETIME = 602;
    public static FIELD_TRADING_SIGNAL_SCREEN_ADDRESS = 603;
    public static FIELD_TRADING_SIGNAL_TIME = 604;
    public static FIELD_TRADING_SIGNAL_EXP_TIME = 605;
    public static FIELD_TRADING_SYSTEM_COMPANY_NAME = 606;
    public static FIELD_TRADING_SYSTEM_RISK_DISCLOSURE = 607;
    public static FIELD_TRADING_SYSTEM_AMOUNT1 = 608;
    public static FIELD_TRADING_SYSTEM_AMOUNT2 = 609;
    public static FIELD_TRADING_SYSTEM_AMOUNT3 = 610;
    public static FIELD_TRADING_SIGNAL_IS_AGGRESSIVE = 611;
    public static FIELD_TRADING_STRATEGY_ACTIVE_SUBSCRIPTIONS = 612;
    public static FIELD_TRADING_STRATEGY_SIGNALS_POSTED = 613;

    public static FIELD_MAX_UNREALIZED_LOSS_LIMIT = 614;
    public static FIELD_SOURCE_EXT_ID = 615;
    public static FIELD_NOT_USED = 616;
    public static FIELD_USE_IN_VAT = 617;
    public static FIELD_VOLUME_PERCENTAGE = 618;
    public static FIELD_HISTORY_SOURCE_TYPE = 619;
    public static FIELD_LAST_HISTORY_CHANGE_DATE = 620;
    public static FIELD_TRADING_UNLOCK_LOGIC = 621;
    public static FIELD_TRADING_UNLOCK_MESSAGE_RESPONSE = 622;
    public static FIELD_QUOTES_LIMIT = 623;
    public static FIELD_SORT_ORDER = 624;
    public static FIELD_TRADE_SESSION_STATUS_ID = 625;
    public static FIELD_EXCHANGE_SESSION_NAME = 626;
    public static FIELD_ASSET_TRADING_MODE = 627;
    public static FIELD_MAX_TRAILING_DRAWDOWN = 628;
    public static FIELD_OPTION_CONTRACT_ID = 629;

    public static FIELD_MESSAGE_CODES = 630;
    public static FIELD_NOT_USED_2 = 631;
    public static FIELD_MAX_ORDER_AMOUNT = 632;
    public static FIELD_VALUE_DATE = 633;
    public static FIELD_RISK_WARN_LEVEL = 634;
    public static FIELD_LAST_HISTORY_CHANGE_SEQUENCE_NUM = 635;
    public static FIELD_POA_USER_ID = 636;
    public static FIELD_ORDER_ALGORITHM = 637;
    public static FIELD_LAST_TRADE_DATE_VALUE = 638;
    public static FIELD_REFERENCE_PRICE = 639;
    public static FIELD_UNSETTLED_LOSS_AVAILABLE_FOR_WITHDRAWAL = 640;
    public static FIELD_OPTION_PREMIUM_REQ = 641;
    public static FIELD_LANG = 642;
    public static FIELD_ORDER_RECEIVED_BY = 643;
    public static FIELD_TRADED_VALUE = 644;
    public static FIELD_OFF_EXCHANGE_VOLUME = 645;
    public static FIELD_OFF_EXCHANGE_VALUE = 646;

    public static FIELD_SUCCESS = 647;
    public static FIELD_MASKED_NAME = 648;
    public static FIELD_KEYBOARD_LETTERS = 649;
    public static FIELD_KEYBOARD_VALUES = 650;
    public static FIELD_USER_NODE_IP = 651;
    public static FIELD_TOKEN_ID = 652;
    public static FIELD_VERIFICATION_ERROR = 653;
    public static FIELD_EXT_RESOURCE = 654;
    public static FIELD_TRADABLE_INSTRUMENT_ID = 655;
    public static FIELD_USE_UNSETTLED_STOCK_OPERATION_IN_WA = 656;
    public static FIELD_USE_PROFIT_AND_UNSETTLED_PNL_IN_WA = 657;
    public static FIELD_VALUATION_TYPE = 658;
    public static FIELD_VALUATION_TYPE_FOR_SHORTS = 659;
    public static FIELD_MARGIN_MARKET_VALUE = 660;
    public static FIELD_MARGIN_TRADED_BALANCE = 661;
    public static FIELD_SHORT_TRADED_BALANCE = 662;
    public static FIELD_MARGIN_EQUITY = 663;
    public static FIELD_SMA_EQUITY = 664;
    public static FIELD_WITHDRAWABLE_CASH_FOR_STOCKS = 665;
    public static FIELD_WITHDRAWABLE_UNSETTLED_PNL = 666;
    public static FIELD_USE_UNSETTLED_NEGATIVE_STOCK_OPERATION_IN_WA = 667;
    public static FIELD_USE_UNSETTLED_POSITIVE_STOCK_OPERATION_IN_WA = 668;
    public static FIELD_USE_UNSETTLED_PROFIT_IN_WA = 669;
    public static FIELD_USE_NON_SSL_CONNECTIONS_FOR_QUOTES_AND_HISTORY = 670;
    public static FIELD_NODE_STATE = 671;
    public static FIELD_VERIFICATION_DESCRIPTION = 672;
    public static FIELD_VERIFICATION_TYPE = 673;
    public static FIELD_MAX_DRAWDOWN_LEVEL = 674;
    public static FIELD_AUCTION_END_TIME = 675;
    public static FIELD_SUPER_POSITION_ID = 676;
    public static FIELD_ISIN = 677;
    public static FIELD_ROUTE_IDS = 678;
    public static FIELD_TRADE_ROUTE_IDS = 679;
    public static FIELD_UNDERLIER_CONTRACT_TRADABLE_INSTRUMENT_ID = 680;
    public static FIELD_ACCESS_TOKEN = 681;
    public static FIELD_INFO_MSG_TYPE = 682;
    public static FIELD_CONTENT = 683;
    public static FIELD_USE_UNSETTLED_COLLATERAL_IN_MA = 684;
    public static FIELD_USE_UNSETTLED_COLLATERAL_IN_WA = 685;
    public static FIELD_UNSETTLED_COLLATERAL = 686;
    public static FIELD_WITHDRAWABLE_UNSETTLED_COLLATERAL = 687;
    public static FIELD_COLLATERAL = 688;
    public static FIELD_ACCESS_TOKEN_LIFE_TIME = 689;
    public static FIELD_REFRESH_TOKEN = 690;
    public static FIELD_REFRESH_TOKEN_LIFE_TIME = 691;
    public static FIELD_REMAINING_QTY = 692;
    public static FIELD_REMAINING_QTY_SIDE = 693;
    public static FIELD_ALGORITHM_ID = 694;
    public static FIELD_ALGORITHM_TYPE = 695;
    public static FIELD_ALGORITHM_NAME = 696;
    public static FIELD_ALGORITHM_STATUS = 697;
    public static FIELD_MD_EXCHANGE_NAME = 698;
    public static FIELD_TRADE_EXCHANGE_NAME = 699;
    public static FIELD_ALGORITHM_TOP = 700;
    public static FIELD_INTEREST_CHANGE = 701;
    public static FIELD_BEHAVIOR = 702;
    public static FIELD_ABSOLUTE_CHANGE = 703;
    public static FIELD_HIGH_LOW_EVENT = 704;
    public static FIELD_BROKER_NAME = 705;
    public static FIELD_CURRENCY_ID = 706;
    public static FIELD_EXT_NAME = 707;
    public static FIELD_STATUS = 708;
    public static FIELD_RESTRICTION = 709;
    public static FIELD_IS_OVERRIDDEN = 710;
    public static FIELD_RAW_BYTES_ATTACHMENT = 711;
    public static FIELD_ATTACHMENT_METADATA = 712;
    public static FIELD_TRADING_SIGNAL_ATTACHMENT_ADDRESS = 713;
    public static FIELD_IS_TRADING_SIGNAL_NOT_SEEN = 714;
    public static FIELD_POSITION_CLOSE_ONLY = 715;
    public static FIELD_BRANDING_ID = 716;
    public static FIELD_WAIVED_MARGIN = 717;
    public static FIELD_IS_TECHNICAL_REPORT = 718;
    public static FIELD_IS_ALLOW_LISTED_MESSAGE = 719;
    public static FIELD_VALUE_INSTRUMENT_INDUSTRY = 720;
    public static FIELD_VALUE_INSTRUMENT_SECTOR = 721;
    public static FIELD_EXECUTION_VENUE = 722;
    public static FIELD_TRADING_EXCHANGE = 723;
    public static FIELD_MARKET_DATA_EXCHANGE = 724;
    public static FIELD_INSTRUMENT_COUNTRY = 725;
    public static FIELD_BROKER_MESSAGE_RESPONSE_CASES = 726;
    public static FIELD_PORTFOLIO_SYSTEM_ID = 727;
    public static FIELD_PORTFOLIO_MODEL_ID = 728;
    public static FIELD_PERCENT = 729;
    public static FIELD_REPORT_ID = 730;
    public static FIELD_WARNING_MARGIN = 731;
    public static FIELD_MARGIN_BEFORE_WARNING = 732;
    public static FIELD_WARN_MARGINGSIZE = 733;
    public static FIELD_WARN_MARGINGSIZE_SHORT = 734;
    public static FIELD_WARN_MARGINGSIZE_OVERNIGHT = 735;
    public static FIELD_WARN_MARGINGSIZE_OVERNIGHT_SHORT = 736;
    public static FIELD_INITIAL_MARGIN_WITHOUT_WAIVED = 737;
    public static FIELD_SL_LIMIT_PRICE = 738;
    public static FIELD_MOBILE_DEVICE_ID = 739;
    public static FIELD_SORTED_MD = 740;
    public static FIELD_MD_ORDER_ID = 741;
    public static FIELD_SUB_ID = 742;
    public static FIELD_SUB_ID_DATE = 743;
    public static FIELD_PREV_MD_ORDER_ID = 744;
    public static FIELD_INSTRUMENT_LOGO_ADDRESS = 745;
    public static FIELD_TIME_OFFSET = 746;
    public static FIELD_CUSTODIAL_PLAN_ID = 747;
    public static FIELD_CUSTODIAL_FEE = 748;
    public static FIELD_CUSTODIAL_MEASURE = 749;
    public static FIELD_OPTION_EXERCISE_STATUS = 750;
    public static FIELD_SHORT_POSITION_INTEREST = 751;
    public static FIELD_DAILY_AVAILABLE_FOR_SHORT = 752;
    public static FIELD_TOTAL_RETURN = 753;
    public static FIELD_PUSH_NOTIFICATION_TOKEN = 754;
    public static FIELD_FINGERPRINT = 755;
    public static FIELD_ATTRIBUTES = 756;
    public static FIELD_ALLOW_WITHDRAW_UNREALIZED_PROFIT = 757;
    public static FIELD_ALERT_TYPE = 758;
    public static FIELD_ALERT_CONDITION = 759;
    public static FIELD_ALERT_VALUE = 760;
    public static FIELD_ALERT_IMPORTANCE = 761;
    public static FIELD_ALERT_AFTER_EXECUTE = 762;
    public static FIELD_ALERT_ACTION = 763;
    public static FIELD_ALERT_NOTIFICATION = 764;
    public static FIELD_ALERT_ID = 765;
    public static FIELD_ALERT_EVENT = 766;
    public static FIELD_ALERT_STATUS = 767;
    public static FIELD_ALERT_COMMAND = 768;
    public static FIELD_DAY_TRADER_PATTERN = 769;
    public static FIELD_DAY_TRADER_PATTERN_PROTECTION = 770;
    public static FIELD_AVAILABLE_DAY_TRADES = 771;
    public static FIELD_USE_SETTLED_CASH_FOR_STOCKS_FOR_OPENED_POSITIONS_IN_MA = 772;
    public static FIELD_USE_SETTLED_COLLATERAL_FOR_OPENED_POSITION_IN_MA = 773;
    public static FIELD_UNUSED_SETTLED_CASH_FOR_STOCKS = 774;
    public static FIELD_UNUSED_SETTLED_COLLATERAL = 775;
    public static FIELD_SAVE_QUOTES_HISTORY = 776;
    public static FIELD_IS_TOKEN_EXPIRED = 777;
    public static FIELD_USE_OVERNIGHT_KOEFICIENT_FOR_WA_CALCULATION = 778;
    public static FIELD_UNUSED_INTRADAY_INITIAL_MARGIN = 779;
    public static FIELD_USED_OVERNIGHT_INITIAL_MARGIN_FOR_INTRADAY = 780;
    public static FIELD_USE_SESSION_PERIOD = 781;
    public static FIELD_IS_SNAPSHOT = 782;
    public static FIELD_TRADABLE_INSTRUMENT_IDS = 783;

    public static FIELD_PRODUCT_ID = 784;
    public static FIELD_PRODUCT_PRO_PRICE = 785;
    public static FIELD_PRODUCT_NON_PRO_PRICE = 786;
    public static FIELD_PRODUCT_HAS_DOCUMENTS = 787;
    public static FIELD_PRODUCT_REQUEST_STATUS = 788;
    public static FIELD_DOCUMENT_ID = 789;
    public static FIELD_DOCUMENT_CONTENT = 790;
    public static FIELD_PRODUCT_REQUEST_TYPE = 791;
    public static FIELD_PRODUCT_REQUEST_TIME = 792;
    public static FIELD_EXTERNAL_ACCOUNT = 793;
    public static FIELD_PRODUCT_CATEGORY = 794;
    public static FIELD_PRODUCT_BASIS_TYPE = 795;
    public static FIELD_INSTRUMENT_IDS = 796;
    public static FIELD_INSTRUMENT_GROUP_IDS = 797;

    public static FIELD_TRADING_EXCHANGES = 798;
    public static FIELD_ACCOUNT_STATUS_REASON = 799;
    public static FIELD_MINIMAL_PROJECTED_BALANCE = 800;
    public static FIELD_PDT_CONFIRMATION_MODE = 801;
    public static FIELD_PDT_CONFIRMATION = 802;
    public static FIELD_OPERATION_TYPE2 = 803;
    public static FIELD_BID_VENUE = 804;
    public static FIELD_ASK_VENUE = 805;
    public static FIELD_VENUE = 806;
    public static FIELD_IS_DATA_SOURCE = 807;
    public static FIELD_DATA_SOURCE_TR_INSTR_ID = 808;
    public static FIELD_DATA_SOURCE_ROUTE_ID = 809;
    public static FIELD_TARGET_TR_INSTR_ID = 810;
    public static FIELD_EXTERNAL_LINK_URL = 811;
    public static FIELD_EXTERNAL_LINK_CLIENT_USE_INTERNAL_BROWSER = 812;
    public static FIELD_EXTERNAL_RESOURCE = 813;
    public static FIELD_EXTERNAL_LINK_PARAMETERS = 814;
    public static FIELD_CORP_ACTION_TYPE = 815;
    public static FIELD_BEGIN_PROJECTED_BALANCE = 816;
    public static FIELD_UPLOADED_AMOUNT = 817;
    public static FIELD_CASH_ORDER_QTY = 818;
    public static FIELD_MIN_PASSWORD_LENGTH = 819;
    public static FIELD_FIRST_DAY_CHANGE = 820;
    public static FIELD_RANKING_30_DAY = 821;
    public static FIELD_RANKING_60_DAY = 822;
    public static FIELD_RANKING_120_DAY = 823;
    public static FIELD_IS_L1 = 824;
    public static FIELD_IS_MD = 825;
    public static FIELD_IS_L3 = 826;
    public static FIELD_ACCRUED_DIVIDENDS = 827;
    public static FIELD_LIST_TYPE = 828;
    public static FIELD_VALUE_INSTRUMENT_SECTOR_CUSTOM_LIST_ID = 829;
    public static FIELD_VALUE_INSTRUMENT_INDUSTRY_CUSTOM_LIST_ID = 830;
    public static FIELD_CALCULATED_DRAWDOWN = 831;
    public static FIELD_MARKET_CAP = 832;
    public static FIELD_ANNOUNCEMENT_DATE = 833;
    public static FIELD_DECLARATION_DATE = 834;
    public static FIELD_RECORD_DATE = 835;
    public static FIELD_EX_DATE = 836;
    public static FIELD_PAYMENT_DATE = 837;
    public static FIELD_UNIT = 838;
    public static FIELD_TAXATIONDETAIL_RATE = 839;
    public static FIELD_IS_IGNORE_UNFIXED_LIST = 840;
    public static FIELD_ALLOW_FRACTIONAL_TRADING = 841;
    public static FIELD_TRADE_ROUTE_ID = 842;
    public static FIELD_INSTRUMENT_NAME = 843;
    public static FIELD_MARKET_CONSENSUS_PERCENT_TYPE = 844;
    public static FIELD_ALGORITHM_TOP_LONG_TOP_SHORT_EXPERT_CHOICE = 845;
    public static FIELD_WITHDRAWABLE_OPTIONS_PREMIUM = 846;
    public static FIELD_FIXGATEWAY_ID = 847;
    public static FIELD_LOSS_LIMIT_PER_TRADE = 848;
    public static FIELD_PRODUCT_IS_DEFAULT = 849;
    public static FIELD_TIME_RANGE_TYPE = 850;
    public static FIELD_TIME_RANGE_NUMBER = 851;
    public static FIELD_ALGORITHM_CONTAINER_TYPE = 852;
    public static FIELD_ALGORITHM_FILTER_NAME = 853;
    public static FIELD_UNSETTLED_DEPOSIT = 854;
    public static FIELD_LAST_CHANGE = 855;
    public static FIELD_TITLE = 856;
    public static FIELD_TOOLTIP = 857;
    public static FIELD_URL = 858;
    public static FIELD_BROKER_MESSAGE_RESPONSE_TYPE = 859;
    public static FIELD_SHOW_IN_SETTINGS = 860;
    public static FIELD_ALLOW_CUSTOM_SL_TP_TRIGGER_PRICE = 861;
    public static FIELD_SL_TRIGGER_PRICE = 862;
    public static FIELD_TP_TRIGGER_PRICE = 863;
    public static FIELD_SL_TP_TRIGGER_PRICE = 864;
    public static FIELD_COLOR_IN_FRONT = 865;
    public static FIELD_IS_UNREAD = 866;
    public static FIELD_COUNT = 867;
    public static FIELD_SHOW_POPUP = 868;
    public static FIELD_INSTRUMENT_LIST_ID = 869;
    public static FIELD_EXTERNAL_NAME = 870;
    public static FIELD_INFO_MSG_TYPE_ID = 871;
    public static FIELD_LACK_OF_MARGIN = 872;
    public static FIELD_HI_PRICE_LIMIT_ENABLED = 873;
    public static FIELD_LOW_PRICE_LIMIT_ENABLED = 874;
    public static FIELD_SYMBOL_LOOKUP_TYPE = 875;
    public static FIELD_UNDERLIER_DESCRIPTION = 876;
    public static FIELD_UNDERLIER_COUNTRY_ID = 877;
    public static FIELD_DISPLAY_METHOD = 878;
    public static FIELD_EXT_REQUEST_ID = 879;
    public static FIELD_MAX_PASSWORD_LENGTH = 880;
    public static FIELD_UNDERLIER_TRADING_EXCHANGE = 881;
    public static FIELD_LEVERAGE_SHORT = 882;
    public static FIELD_ALIAS = 883;
    public static FIELD_FUNDING_RATE_VALUE = 884;
    public static FIELD_MARK_PRICE = 885;
    public static FIELD_NEXT_FUNDING_TIME = 886;
    public static FIELD_FUNDING_RATE_MARKUP_PLAN_ID = 887;
    public static FIELD_MARKUP_TYPE = 888;
    public static FIELD_MARKUP_VALUE = 889;
    public static FIELD_BROKER_MESSAGE_ID = 890;
    public static FIELD_CLIENT_PANEL = 891;
    public static FIELD_WITHDRAWAL_AVAILABLE = 892;
    public static FIELD_APP_INSTALL_ID = 893;
    public static FIELD_CLOSE_ACCOUNT_REQUEST_ID = 894;
    public static FIELD_PROJECTED_BALANCE = 895;
    public static FIELD_USER_GROUP_IDS = 896;
    public static FIELD_SHOW_EXTENDED_SESSION = 897;
    public static FIELD_AGG_TYPE = 898;
    public static FIELD_AGG_PERIOD = 899;
    public static FIELD_AGG_VALUE = 900;
    public static FIELD_SEND_LAST_QUOTES = 901;
    public static FIELD_EXTERNAL_LINK_WEBCON_DARK = 902;
    public static FIELD_EXTERNAL_LINK_WEBCON_LIGHT = 903;
    public static FIELD_PREFERRED_INSTRUMENT_TYPE = 904;
    public static FIELD_DAYS_PER_MONTH = 905;
    public static FIELD_DAYS_PER_YEAR = 906;
    public static FIELD_MAINTENANCE_MARGIN_REQ_PERCENT = 907;
    public static FIELD_ALLOW_EXERCISE_FOR_ITM_OPTION = 908;
    public static FIELD_INITIAL_MARGIN_REQ_PERCENT = 909;
    public static FIELD_WARNING_MARGIN_REQ_PERCENT = 910;
    public static FIELD_STOP_OUT_VALUE = 911;
    public static FIELD_STRATEGY_ID = 912;
    public static FIELD_IS_DELISTED = 913;
    public static FIELD_RELATIVE_DAILY_LOSS = 914;
    public static FIELD_RELATIVE_WEEKLY_LOSS = 915;
    public static FIELD_CURRENT_UNREALIZED_LOSS = 916;
    public static FIELD_RELATIVE_UNREALIZED_LOSS = 917;
    public static FIELD_RELATIVE_TRAILING_DRAWDOWN_LEVEL = 918;
    public static FIELD_RELATIVE_MAX_DRAWDOWN_LEVEL = 919;
    public static FIELD_RELATIVE_OF_MAX_RELATIVE_DRAWDOWN_LEVEL = 920;
    public static FIELD_CURRENT_DAILY_PROFIT = 921;
    public static FIELD_RELATIVE_DAILY_PROFIT = 922;
    public static FIELD_CURRENT_TOTAL_POSITION_QTY = 923;
    public static FIELD_CURRENT_POSITIONS = 924;
    public static FIELD_PENDING_ORDERS = 925;
    public static FIELD_ORDERS_COUNT_PER_DAY = 926;
    public static FIELD_EOD_TRAILING_DRAWDOWN_LEVEL = 927;
    public static FIELD_RELATIVE_EOD_TRAILING_DRAWDOWN_LEVEL = 928;
    public static FIELD_IGNORED_INSTRUMENT_TYPES = 929;
    public static FIELD_SHORT_SWAP_INFO = 930;
    public static FIELD_RELATIVE_DAILY_LOSS_LIMIT = 931;
    public static FIELD_CALCULATED_RELATIVE_DAILY_LOSS_LIMIT = 932;
    public static FIELD_RELATIVE_OF_RELATIVE_DAILY_LOSS_LIMIT = 933;
    public static FIELD_TRADE_SESSION_OPTION_EXERCISE = 934;
    public static FIELD_CROSS_RATE_FOR_POSITIONS = 935;
    public static FIELD_DONT_SAVE_HISTORY_QUOTES_FRONTEND = 936;
    public static FIELD_ALLOW_CASH_TRADING = 937;
    public static FIELD_TOTAL_POSITIONS_VALUE = 938;
    public static FIELD_AVAILABLE_CASH = 939;
    public static FIELD_FROM_STP = 940;
    public static FIELD_AFTER_TRADE_CASH = 941;
    public static FIELD_FOLLOWER_REQUEST_STATUS = 942;
    public static FIELD_MASTER_ID = 943;
    public static FIELD_FOLLOWER_ID = 944;
    public static FIELD_FOLLOWER_IDS = 945;
    public static FIELD_ENABLE_COMMENT_FIELD = 946;
    public static FIELD_USER_COMMENT = 947;
    public static FIELD_SECRET_2FA_KEY = 948;
    public static FIELD_BRANDING_PRIORITY = 949;
    public static FIELD_BRANDING_INSTRUMENT_TYPE = 950;
    public static FIELD_EXECUTION_SCHEME = 951;
    public static FIELD_ALLOCATION = 952;
    public static FIELD_ASSOCIATED_USERS_ID = 953;
    public static FIELD_REJECT_ACCOUNT_ID = 954;
    // #endregion  FieldsFactory
    // note: if you add a field, and there are unknown fields between your new and last known ones, i.e.
    // the sequence of numbers is missing, find out from the server developers the numbers and types of fields, and enter them
    // entered - immediately commit, do not hold, so that conflicts will not be resolved later.

    // #region  Groups
    public static ORDER_GROUP_NAME = 1;
    public static SUBSCRIBE_GROUP_NAME = 2;
    public static LINE_GROUP_NAME = 3;
    public static NEWSREQUEST_IDS = 4;
    public static NEWSREQUEST_KEYWORDS = 5;
    public static ORDER_ID_GROUP = 6;
    public static PRICE_GROUP = 7;
    public static ACCOUNT_BROKER_IDS_GROUP = 8;
    public static INSTRUMENT_BROKER_IDS_GROUP = 9;
    public static NEWS_GROUP = 10;
    public static KEY_VALUE_GROUP = 11;
    public static SERVER_URL_GROUP = 12;
    public static INSTRUMENT_GROUP = 13;
    public static ROUTE_ID_GROUP = 14;
    public static PAMM_FUTURE_GROUP = 15;
    public static PAMM_INVESTOR_GROUP = 16;
    public static PAMM_INVESTOR_HISTORY_GROUP = 17;
    public static PAMM_REQUEST_GROUP = 18;
    public static PAMM_GAIN_GROUP = 19;
    public static SESSION_GROUP_NAME = 20;
    public static PAMM_STATISTICS_GROUP = 21;
    public static HISTORY_FILE_INFO_GROUP = 22;
    public static QUOTE_BAR_GROUP = 23;
    // 24  видимо не используеться, создавал для указания явного распредиления по мамам, но потом отказались видимо (c)kovalenkodmitry
    public static KEY_VALUE_AGGREGATE_GROUP_BEAN = 25; // recovery
    public static EXPIRATION_DATE_GROUP_NAME = 26;
    public static SPREAD_LEVEL_GROUP = 27;
    public static COMMISSION_LEVEL_GROUP = 28;
    public static MARGIN_GROUP = 29;
    public static CLUSTER_NODE_GROUP = 30;
    public static TRADE_SESSION_PERIOD_TIME_GROUP = 31;
    public static MARGIN_COEFFICIENT_GROUP = 32;
    public static HOLIDAY_GROUP = 33;
    public static CROSS_GROUP = 34;
    public static COMMISSION_GROUP = 35;
    public static COMMISSION_VALUE_GROUP = 36;
    public static ASSET_ACCOUNT_GROUP = 37;
    public static SUBSCRIBE_NEWS_GROUP = 38;
    public static STRIKE_PRICE_GROUP = 39;
    public static VARIABLE_TICK_GROUP = 40;
    public static CROSS_MARKUP_GROUP = 41;
    public static MAM_SUBACCOUNT_GROUP = 42;
    public static MARKUP_ENTRY_GROUP = 43;
    public static RISK_PLAN_GROUP = 44;
    public static INSTRUMENT_INFO_GROUP = 45;
    public static EXCHANGE_GROUP = 46;
    public static INSTRUMENT_TYPE_GROUP = 47;
    public static INTEREST_ON_BALANCE_PLAN_ENTRY_GROUP = 48;
    public static INSTRUMENT_NAMES_GROUP = 49;
    public static CLIENT_INSTRUMENT_SETTINGS_GROUP = 50;
    public static INIT_DATA_REQUEST_INSTRUMENTS_GROUP = 51;
    public static DELIVERY_METHOD_ID_GROUP = 52;
    public static KEY_VALUE_GROUP_BEAN = 54;
    public static SOURCE_GROUP = 55;
    public static REPORT_PARAM_GROUP = 56;
    public static OPTION_CONTRACT_GROUP = 57; // #57266
    public static ASSET_BALANCE_GROUP = 58; // #59361
    public static TRADING_SIGNAL_HISTORY_GROUP = 59;
    public static INSTRUMENT_STATISTICS_DATA_GROUP = 60;
    public static I18N_NAMES_GROUP = 63; // #72094

    public static ALGORITHM_GAINERS_LOSERS_EXCHANGE_GROUP = 67;
    public static ALGORITHM_GAINERS_LOSERS_INSTRUMENT_GROUP = 68;
    public static ALGORITHM_HIGH_LOW_INSTRUMENT_GROUP = 69;

    public static EXT_FIELD_GROUP = 71;

    public static PORTFOLIO_MODEL_INSTRUMENT_ENTRY_GROUP = 74;

    public static REPORT_GROUP = 75;
    public static REPORT_HEADER_GROUP = 76;
    public static REPORT_COLUMN_GROUP = 77;
    public static REPORT_LINE_GROUP = 78;
    public static CUSTODY_FEE_GROUP = 81;

    public static PERCENT_ABSOLUTE_ENTRY_GROUP = 82;
    public static PORTFOLIO_MONTHLY_STATISTIC_GROUP = 83;
    public static PERCENT_ABSOLUTE_DAY_ENTRY_GROUP = 84;
    public static PERCENT_ABSOLUTE_DAY_TYPE_ENTRY_GROUP = 85;
    public static ABSOLUTE_DAY_ENTRY_GROUP = 86;
    public static PERCENT_DAY_ENTRY_GROUP = 87;
    public static PERCENT_INSTRUMENT_ROUTE_DAY_ENTRY_GROUP = 88;
    public static PORTFOLIO_MONTHLY_INSTRUMENT_RETURN_STATISTIC_ENTRY_GROUP = 89;
    public static PERCENT_DAY_TYPE_ENTRY_GROUP = 90;
    public static COMMISSION_RESPONSE_GROUP = 91;
    public static DOCUMENT_GROUP = 92;
    public static PRODUCT_DEFINITION_GROUP = 93;
    public static OPTIONS_FOR_UNDERLIER_GROUP = 94;
    public static STATISTICS_GROUP = 95;
    public static ACCOUNT_OPERATION_STATISTIC_GROUP = 96;

    public static ALGORITHM_GAINERS_LOSERS_DELAY_DATA_GROUP = 100;
    public static ALGORITHM_HIGH_LOW_DELAY_DATA_GROUP = 101;

    public static ALGORITHM_TOP_LONG_SHORT_INSTRUMENT_GROUP = 103;
    public static ALGORITHM_TOP_VOLUME_DATA_GROUP = 104;
    public static ALGORITHM_TOP_VOLUME_DELAY_DATA_GROUP = 105;
    public static ALGORITHM_TOP_VOLUME_INSTRUMENT_GROUP = 106;

    public static ALGORITHM_HISTORICAL_GAINER_LOSER_RT_GROUP = 107;
    public static ALGORITHM_HISTORICAL_GAINER_LOSER_DELAY_GROUP = 108;
    public static ALGORITHM_HISTORICAL_GAINER_LOSER_TIME_RANGE_GROUP = 109;

    public static WARNING_FOR_CHANGE_FROM_LAST_PRICE_GROUP = 110;
    public static CUSTOM_BUTTONS_GROUP = 111;

    public static HISTORICAL_BROKER_MESSAGE_GROUP = 115;

    public static ALGORITHM_HEATMAP_RT_GROUP = 116;
    public static ALGORITHM_HEATMAP_DELAY_DATA_GROUP = 117;

    public static BUYING_POWER_AVAILABLE_FUNDS_FOR_EQUITIES = 118;
    public static BUYING_POWER_AVAILABLE_FUNDS_FOR_OPTIONS_BUY = 119;

    public static MARGIN_PRICE_BASED_MULTIPLE_LEVERAGE = 120;

    public static FUNDING_RATE_MARKUP_ENTRY_GROUP = 121;

    public static SUPPORTED_AGGREGATIONS_GROUP = 122;
    // #endregion groups

    public static GetFieldName = function (id): string {
        let name = FieldsFactory.FFieldNamesList[id];
        if (!name) {
            name = id;
        }

        return name + '[' + id + ']';
    };

    public static GetGroupName = function (id): string {
        let name = FieldsFactory.FGroupTypesList[id];
        if (!name) {
            name = id;
        }

        return name + '[' + id + ']';
    };

    public static GetMsgTypeName = function (id): string {
        let name = Message.CodesString[id];
        if (!name) {
            name = id;
        }

        return name;
    };

    public static InitNames = (function () {
        FieldsFactory.FFieldNamesList = {};
        FieldsFactory.FGroupTypesList = {};
        // FieldsFactory.FMsgTypesList = Message.CodesString;

        const myKeys = Object.keys(FieldsFactory);

        for (let i = 0; i < myKeys.length; i++) {
            const key = myKeys[i];
            if (typeof FieldsFactory[key] === 'number') {
                if (key.includes('FIELD_')) {
                    FieldsFactory.FFieldNamesList[FieldsFactory[key]] = key;
                } else {
                    FieldsFactory.FGroupTypesList[FieldsFactory[key]] = key;
                }
            } else {
                continue;
            }
        }
    }());

    public static Test = (): any => {
        console.log('FieldsFactory');
    };
}
