// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '../../../../../Utils/Account/AccountFeature';
import { Resources } from '../../../../../Localizations/Resources';
import { BaseSettings } from '../../../../Settings/BaseGeneralSettingsWrapper';
import { Account } from '../../../Account';
import { BaseOrderWarning } from './BaseOrderWarning';

export class TodayVolumeExeedWarning extends BaseOrderWarning {
    public getText (): string {
        const warnTodayVolumeValue = BaseSettings.warnIfTodayVolumeExeedValue;
        const curAcc = this.orderEdit.account;
        const todayVolume = Account.GetAccountFeature(AccountFeature.TodayVolume, curAcc, curAcc.assetBalanceDefault);
        if (BaseSettings.warnIfTodayVolumeExeed && warnTodayVolumeValue < todayVolume) {
            const formattedWarnTodayVolume = Account.GetAccountFeatureString(warnTodayVolumeValue, AccountFeature.TodayVolume, curAcc, curAcc.assetBalanceDefault);
            const formattedTodayVolume = Account.GetAccountFeatureString(todayVolume, AccountFeature.TodayVolume, curAcc, curAcc.assetBalanceDefault);
            const text = Resources.getResource('general.warnings.todayVolumeExeedText', [formattedTodayVolume, formattedWarnTodayVolume]);
            return text;
        }

        return '';
    }
}
