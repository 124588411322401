// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { HistoryType } from '@shared/utils/History/HistoryType';
import { SolidBrush } from '@shared/commons/Graphics';
import { LinkedSystem } from '../misc/LinkedSystem';
import { MarketDepthOETemplate } from '../../templates.js';
import { Level2ItemQ, MDLotsMode } from '../cache/Level2ItemQ';
import { QuickTableRactive } from '../elements/QuickTable/QuickTableRactive';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { OrderEditViewBase } from './OrderEditViewBase';
import { ThemeManager } from '../misc/ThemeManager';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { Leve2QuoteType } from '@shared/utils/Enums/Constants';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { type Account } from '@shared/commons/cache/Account';
import { DynProperty } from '@shared/commons/DynProperty';
import { Instrument } from '@shared/commons/cache/Instrument';
import { IsAllowed } from '@shared/commons/IsAllowed';
import { type Quantity } from '@shared/utils/Trading/Quantity';
import { OrderEditUpdateData } from '@shared/utils/Trading/OrderEditUpdateData';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { TradingNumericErrorChecker } from '@shared/commons/Trading/TradingNumericErrorChecker';
import { PanelItemsFactory } from './PanelItemsFactory';
import { type TerceraLevel1Panel } from '../elements/TerceraLevel1Panel';
import { type OrderTypeBase } from '@shared/commons/cache/OrderParams/order-type/OrderTypeBase';
import { type ITradingData } from '@shared/utils/Trading/TradingData';
import { type ProductType } from '@shared/utils/Instruments/ProductType';
import { type TIF } from '@shared/utils/Trading/OrderTif';
import { type QuickTableRow } from '../elements/QuickTable/QuickTableRow';
import { type QuickTableCell } from '../elements/QuickTable/QuickTableCell';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { type Level2Collection } from '@shared/commons/cache/Level2Collection';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { WDSettings } from '../settings/WDGeneralSettings';
import { Level1ItemType } from '@shared/utils/Enums/Level1ItemType';
import { HotkeysManager } from '@shared/commons/cache/Hotkeys/HotkeysManager';
import { MarketDepthHotkeysEnum, OrderCancelActionHotkeysEnum } from '@shared/commons/cache/Hotkeys/NamesEventHotkey ';
import { LimitOrderType } from '@shared/commons/cache/OrderParams/order-type/LimitOrderType';

interface ISubMenuItem {
    text: string
    tag: string
    enabled: boolean
    checked: boolean
    canCheck: boolean
    event: any
}

interface IMenuItem {
    text: string
    enabled: boolean
    subitems: ISubMenuItem[]
}

export class MarketDepthPanel extends OrderEditViewBase {
    public static MarketDepthStackSizeLimit = -1;

    public Name: 'MarketDepthPanel';

    public quickTableRactiveBID: QuickTableRactive<Level2ItemQ> | null = null;
    public quickTableRactiveASK: QuickTableRactive<Level2ItemQ> | null = null;

    public headerLocaleKey: string = 'panel.level2';

    public FLayerColors: any[] = [];
    public FLayerColorsForeground: any[] = [];

    public MostSaturatedAskForeColor: string = '';
    public MostSaturatedAskBackColor: string = '';
    public MostSaturatedBidForeColor: string = '';
    public MostSaturatedBidBackColor: string = '';

    public ColoringMethod: ColoringMethod = ColoringMethod.ByPriceLVL;
    public TableMDBackgroundColor: any;
    public askStartIndex: number;
    public deferredBIDXmlTemplate: any;
    public deferredASKXmlTemplate: any;
    public menuItems: IMenuItem[];
    public TableBIDSizeHistogram: string;
    public TableASKSizeHistogram: string;
    public TableAllHistogramTextColor: string;
    public TableMDSizeHistogramTextColor: string;
    public TableMDSizeHistogramBrush: any;
    public TableMDSizeHistogram: any;
    // BidVisibleRowsCount: number = 0
    // AskVisibleRowsCount: number = 0

    constructor () {
        super();

        this.FLayerColors = new Array(5);
        this.FLayerColorsForeground = new Array(5);

        this.MostSaturatedAskForeColor = 'rgb(0,0,0)';
        this.MostSaturatedAskBackColor = 'rgb(0,0,0)';
        this.MostSaturatedBidForeColor = 'rgb(0,0,0)';
        this.MostSaturatedBidBackColor = 'rgb(0,0,0)';
        this.ColoringMethod = ColoringMethod.SizeHistogram;
    }

    public override getType (): PanelNames { return PanelNames.MarketDepthPanel; }

    public override updatePanelHeader (): void {
        const ins: Instrument = this.get('instrument');
        void this.set({
            header:
            Resources.getResource(this.headerLocaleKey) +
            (!isNullOrUndefined(ins) ? (' ' + ins.DisplayName()) : '')
        });
    }

    public layoutTable (): void {
        const tableBID = this.quickTableRactiveBID;
        const tableASK = this.quickTableRactiveASK;

        if (!isNullOrUndefined(tableBID)) {
            this.layoutTableResize(tableBID);
        }

        if (!isNullOrUndefined(tableASK)) {
            this.layoutTableResize(tableASK);
        }
    }

    public SetBackgroundColorForQuickTables (): void {
        this.quickTableRactiveBID.quickTable.BackColor = this.TableMDBackgroundColor;
        this.quickTableRactiveASK.quickTable.BackColor = this.TableMDBackgroundColor;
        this.quickTableRactiveBID.quickTable.gridColor = this.TableMDBackgroundColor;
        this.quickTableRactiveASK.quickTable.gridColor = this.TableMDBackgroundColor;
    }

    public override oninit (): void {
        super.oninit();

        this.observe('panelsVisibility.*', this.layoutTable);
        this.observe('instrument', this.onInstrumentChanged);
        this.observe('account', this.onAccountChanged);

        this.observe(
            'orderType',
            this.onOrderTypeChanged);

        this.observe(
            'instrument account quantity side tif productType userComment',
            this.onTradingDataChanged,
            { init: false });

        this.on('placeOrder', this.placeOrder);

        this.updateSettings();

        this.observe('mirror', this.onMirrorChanged);

        DataCache.OnUpdateAccount.Subscribe(this.onUpdateAccountData, this);
        DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.themeChange();
        this.localize();

        this.onInstrumentChanged(this.getInstrument(), null);
    }

    public override dispose (): void {
        this.Unsubscribe(this.getInstrument());

        DataCache.OnUpdateAccount.UnSubscribe(this.onUpdateAccountData, this);
        DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);

        super.dispose();
    }

    public override jbInit (): void {
        const me = this;

        me.askStartIndex = MarketDepthPanel.MarketDepthStackSizeLimit * 2;

        const panelInfo = PanelItemsFactory.GetPanelItem(me.getType());
        if (!isNullOrUndefined(panelInfo)) {
            const item: Level2ItemQ = new panelInfo() as Level2ItemQ;
            me.quickTableRactiveBID = this.createTable(this, item);
            me.quickTableRactiveASK = this.createTable(this, item);
        }

        const qtBid = me.quickTableRactiveBID.quickTable;
        if (!isNullOrUndefined(me.deferredBIDXmlTemplate)) {
            qtBid.xmlSettingsTemplate = me.deferredBIDXmlTemplate;
            delete me.deferredBIDXmlTemplate;
        }

        const qtAsk = me.quickTableRactiveASK.quickTable;
        if (!isNullOrUndefined(me.deferredASKXmlTemplate)) {
            qtAsk.xmlSettingsTemplate = me.deferredASKXmlTemplate;
            delete me.deferredASKXmlTemplate;
        }

        this.SetColumnsDefaultDisplayIndex(me.quickTableRactiveASK.quickTable);
        this.SetColumnsDefaultDisplayIndex(me.quickTableRactiveBID.quickTable);

        qtBid.UpdateSortedColumns();
        qtAsk.UpdateSortedColumns();
        this.onMirrorChanged();

        qtBid.OnVisibleColumnChanged.Subscribe(this.visibleColumnChanged, this);
        qtAsk.OnVisibleColumnChanged.Subscribe(this.visibleColumnChanged, this);

        qtBid.OnVisibleColumnChanged.Subscribe(
            this.syncColumnsVisibility.bind(this, qtBid, qtAsk), this);

        qtAsk.OnVisibleColumnChanged.Subscribe(
            this.syncColumnsVisibility.bind(this, qtAsk, qtBid), this);

        qtBid.OnColumnResize.Subscribe(
            this.syncColumnsWidth.bind(this, qtBid, qtAsk), this);

        qtAsk.OnColumnResize.Subscribe(
            this.syncColumnsWidth.bind(this, qtAsk, qtBid), this);

        qtBid.ColumnDisplayIndexChanged.Subscribe(
            this.syncColumnsIndex.bind(this, qtBid, qtAsk), this);

        qtAsk.ColumnDisplayIndexChanged.Subscribe(
            this.syncColumnsIndex.bind(this, qtAsk, qtBid), this);

        qtBid.ClickableCellsIndexesAndEvents['1'] =
        qtAsk.ClickableCellsIndexesAndEvents['1'] =
        this.setPriceFromTable.bind(this);

        qtBid.ClickableCellsIndexesAndEvents['2'] =
        qtAsk.ClickableCellsIndexesAndEvents['2'] =
        this.setAmountAndPriceFromTable.bind(this);

        me.OnResize.Subscribe(function () { me.layoutTable(); }, me);

        qtBid.SetScrollHidden(true);

        qtAsk.scroll.OnValueChange.Subscribe(this.SynchronizeScroll, this);
        qtBid.scroll.OnValueChange.Subscribe(this.SynchronizeScroll, this);

        me.quickTableRactiveASK.quickTable.isRowSeparated = true;
        me.quickTableRactiveBID.quickTable.isRowSeparated = true;
        me.quickTableRactiveASK.quickTable.borderWidth = 0;
        me.quickTableRactiveBID.quickTable.borderWidth = 0;

        me.localize();
        me.layoutTable();

        this.visibleColumnChanged(qtBid.getVisibleColumn());
    }

    public SynchronizeScroll (index: number): void {
    // this.BidVisibleRowsCount;
    // this.AskVisibleRowsCount;

        const max = Math.max(this.quickTableRactiveBID.quickTable.scroll.scrollElementsCount, this.quickTableRactiveASK.quickTable.scroll.scrollElementsCount);
        this.quickTableRactiveBID.quickTable.scroll.scrollElementsCount = this.quickTableRactiveASK.quickTable.scroll.scrollElementsCount = max;

        this.quickTableRactiveBID.quickTable.scroll.moveScrollToElement(index);
        this.quickTableRactiveASK.quickTable.scroll.moveScrollToElement(index);
    }

    public onOrderTypeChanged (newOrderType: OrderTypeBase, oldOrderType: OrderTypeBase): void {
        if (isNullOrUndefined(newOrderType) || newOrderType === oldOrderType) {
            return;
        }

        this.isShowCommentSelector();
        const orderEdit = newOrderType.createOrderEditObject({
            dataCache: DataCache
        // TODO. Ugly.
        // http://tp.pfsoft.net/entity/76728
        // Remove https://tp.traderevolution.com/entity/82704
        // fullRangeForLimitPrice: true
        });

        this.setOrderEdit(orderEdit);

        this.localizeShortCaptions();

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            this.getAllTradingDataDict()));
    }

    private isShowCommentSelector (): void {
        const orderType: OrderTypeBase = this.get('orderType');
        if (!isNullOrUndefined(orderType) && orderType.id() === OrderType.Care) {
            const instrument: Instrument = this.get('instrument');
            if (!isNullOrUndefined(instrument)) {
                void this.set('enableComment', instrument.EnableComment);
            }
        } else {
            void this.set('enableComment', false);
        }
    }

    // TODO. Rename; Parameters' constants.
    public onTradingDataChanged (newVal, oldVal, key: string): void {
        const orderEdit = this.orderEdit;
        if (!isNullOrUndefined(orderEdit)) {
            const tradingDataDict: ITradingData = {};
            tradingDataDict[key] = newVal;

            tradingDataDict.completed = this.completed;

            orderEdit.updateParameters(new OrderEditUpdateData(
                null,
                tradingDataDict));
        }

        if (key === 'instrument') {
            this.symbolLink_Out(false, newVal);
        } // #115325 <- can't call it in onInstrumentChanged because in that case when linking is on there left fakeNonFixedInstrument in this.orderEdit.instrument
    }

    // TODO. Rename; Parameters' constants.
    public getAllTradingDataDict (): ITradingData {
        const tradingDataDict: ITradingData = {};

        const instrument: Instrument = this.get('instrument');
        if (!isNullOrUndefined(instrument)) {
            tradingDataDict.instrument = instrument;
        }

        const account: Account = this.get('account');
        if (!isNullOrUndefined(account)) {
            tradingDataDict.account = account;
        }

        const quantity: Quantity = this.get('quantity');
        if (!isNullOrUndefined(quantity)) {
            tradingDataDict.quantity = quantity;
        }

        const tif: TIF = this.get('tif');
        if (!isNullOrUndefined(tif)) {
            tradingDataDict.tif = tif;
        }

        const productType: ProductType = this.get('productType');
        if (!isNullOrUndefined(productType)) {
            tradingDataDict.productType = productType;
        }

        const userComment: string = this.get('userComment');
        if (isValidString(userComment)) {
            tradingDataDict.userComment = userComment;
        }

        tradingDataDict.completed = this.completed;

        // TODO. UGLY. Default side.
        tradingDataDict.side = OperationType.Buy;

        return tradingDataDict;
    }

    public placeOrder (sender, side: OperationType): void {
        if (TradingNumericErrorChecker.HasErrors(this)) {
            return;
        }

        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit)) return;

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            { side, placedFrom: PlacedFrom.WEB_MARKETDEPTH, leverageValue: this.get('leverageValue') }
        ));

        DataCache.FOrderExecutor
            .placeOrderPromise(orderEdit, null, null, this.focusWarningNumeric.bind(this))
            .catch(function () {
                const ex = new CustomErrorClass('MarketDepthPanel error', 'MarketDepthPanel.placeOrder.', 'placeOrder -> placeOrderPromise');
                ErrorInformationStorage.GetException(ex);
            })
            .finally(function () {
            // TODO. Ugly. Back to default side.
                orderEdit.updateParameters(new OrderEditUpdateData(
                    null,
                    { side: OperationType.Buy }));
            });
    }

    public override localize (): void {
        super.localize();

        if (!isNullOrUndefined(this.quickTableRactiveBID?.quickTable)) {
            this.quickTableRactiveBID.quickTable.localize();
        }

        if (!isNullOrUndefined(this.quickTableRactiveASK?.quickTable)) {
            this.quickTableRactiveASK.quickTable.localize();
        }

        this.localizeShortCaptions();

        this.repopulatePanelContextMenu();
    }

    public localizeShortCaptions (): void {
        const orderType = this.get('orderType')?.id();
        const shortCaptions = [];

        if (orderType === OrderType.Stop || orderType === OrderType.StopLimit || orderType === OrderType.OCO) {
            shortCaptions.push(Resources.getResource('stopPrice.shortCaption'));
        }
        if (orderType === OrderType.Limit || orderType === OrderType.StopLimit || orderType === OrderType.OCO || orderType === OrderType.Care) {
            shortCaptions.push(Resources.getResource('limitPrice.shortCaption'));
        }
        if (orderType === OrderType.TrailingStop) {
            shortCaptions.push(Resources.getResource('trailingStop.shortCaption'));
        }

        void this.set({ shortCaptions });
    }

    public repopulatePanelContextMenu (): void {
        this.menuItems = this.createPanelMenuItems();
        this.menuTagDict = TerceraMenu.createTagDictionary(this.menuItems);

        if (!isNullOrUndefined(this.quickTableRactiveBID?.quickTable) &&
        !isNullOrUndefined(this.quickTableRactiveASK?.quickTable)) {
            this.quickTableRactiveBID.quickTable.setTableContextMenuItems(this.menuItems);
            this.quickTableRactiveASK.quickTable.setTableContextMenuItems(this.menuItems);
        }
    }

    public createTable (me, items: Level2ItemQ): QuickTableRactive<Level2ItemQ> {
        const myQuickTableRactive = new QuickTableRactive<Level2ItemQ>();
        me.addControl(myQuickTableRactive, true);

        const qt = myQuickTableRactive.quickTable;
        qt.allowGroupBy = false;
        qt.lockManualSorting = true;
        qt.InitializeDirect(items);
        qt.UpdateSortedColumns();

        return myQuickTableRactive;
    }

    public coloringRowsMethod (coloringMethod: ColoringMethod, askRows: Array<QuickTableRow<Level2ItemQ>>, bidRows: Array<QuickTableRow<Level2ItemQ>>): void {
        if (ColoringMethod.ByPriceLVL === coloringMethod) {
            this.coloringRowsMethodByPriceLVL(askRows);
            this.coloringRowsMethodByPriceLVL(bidRows);
        }

        if (ColoringMethod.RelativeToVolume === coloringMethod) {
            this.coloringRowsMethodRelativeToVolume(askRows, false);
            this.coloringRowsMethodRelativeToVolume(bidRows, true);
        }

        if (ColoringMethod.SizeHistogram === coloringMethod) {
            this.coloringRowsMethodSizeHistogram(askRows, false);
            this.coloringRowsMethodSizeHistogram(bidRows, true);
        }
    }

    public coloringRowsMethodByPriceLVL (rows: Array<QuickTableRow<Level2ItemQ>>): void {
        const len = rows.length;
        let depth = 0;
        for (let i = 0; i < len; i++) {
            const row = rows[i];
            if (!row.visible) {
                break;
            }
            row.BackColor = this.FLayerColors[depth];
            row.ForeColor = this.FLayerColorsForeground[depth];
            row.cells[2].ForeColor = null;
            depth++;

            if (depth > 4) {
                depth = 0;
            }
        }
    }

    public coloringRowsMethodRelativeToVolume (rows: Array<QuickTableRow<Level2ItemQ>>, isBidTable: boolean): void {
        let maxVolume = 0;
        let minVolume = rows.length !== 0 ? rows[0].item.quoteSize : 0;
        const len = rows.length;
        for (let i = 0; i < len; i++) {
            const row = rows[i];
            if (!row.visible) {
                break;
            }
            maxVolume = row.item.quoteSize > maxVolume
                ? row.item.quoteSize
                : maxVolume;

            minVolume = row.item.quoteSize < minVolume
                ? row.item.quoteSize
                : minVolume;
        }

        const totalVolume = maxVolume - minVolume;
        for (let i = 0; i < len; i++) {
            const row = rows[i];
            if (!row.visible) {
                break;
            }
            row.BackColor = MarketDepthPanel.RelativeToVolumeBackColoring(
                this.MostSaturatedBidBackColor,
                this.MostSaturatedAskBackColor,
                isBidTable,
                minVolume,
                totalVolume,
                row.item.quoteSize);

            row.ForeColor = isBidTable
                ? this.MostSaturatedBidForeColor
                : this.MostSaturatedAskForeColor;
            row.cells[2].ForeColor = null;
        }
    }

    public coloringRowsMethodSizeHistogram (rows: Array<QuickTableRow<Level2ItemQ>>, isBidTable: boolean): void {
        let maxVolume = 0;
        const len = rows.length;
        for (let i = 0; i < len; i++) {
            const row = rows[i];
            if (!row.visible) {
                break;
            }

            maxVolume = row.item.quoteSize > maxVolume
                ? row.item.quoteSize
                : maxVolume;
        }

        for (let i = 0; i < len; i++) {
            const row = rows[i];
            if (!row.visible) {
                break;
            }

            row.BackColor = isBidTable ? this.TableBIDSizeHistogram : this.TableASKSizeHistogram;

            const size = row.item.quoteSize;
            const coefVolume = size / maxVolume;
            row.cells[2].CustomDrawingHandlerParameters = { koef: coefVolume, isBidTable };

            row.ForeColor = this.TableAllHistogramTextColor;
            row.cells[2].ForeColor = this.TableMDSizeHistogramTextColor;
        }
    }

    public CustomDrawingHandler (context: CanvasRenderingContext2D, cell: QuickTableCell, cellX: number, cellY: number, cellW: number, cellH: number): boolean {
        if (this.ColoringMethod !== ColoringMethod.SizeHistogram) {
            return false;
        }
        if (isNullOrUndefined(cell.CustomDrawingHandlerParameters)) {
            return false;
        }

        if (cell.CustomDrawingHandlerParameters.isBidTable) {
            context.FillRect(this.TableMDSizeHistogramBrush, cellX + cellW - cell.CustomDrawingHandlerParameters.koef * cellW, cellY, cellW, cellH);
        } else {
            context.FillRect(this.TableMDSizeHistogramBrush, cellX, cellY, cell.CustomDrawingHandlerParameters.koef * cellW, cellH);
        }

        return true;
    }

    public static RelativeToVolumeBackColoring (
        MostSaturatedBidBackColor: string,
        MostSaturatedAskBackColor: string,
        isBidTable: boolean,
        minVolume: number,
        deltaVolume: number,
        size: number): string {
        const mostSaturatedColor =
        isBidTable
            ? MostSaturatedBidBackColor
            : MostSaturatedAskBackColor;

        // соотношение объема котировки к минимальному объему
        const coefVolume = size - minVolume;

        let opacity = 1 * (coefVolume / deltaVolume);
        opacity = opacity >= 0 ? (opacity <= 1 ? opacity : 1) : 1;

        // если StepToMaxVolume меньше, чем самое маленькое значение в таблице
        opacity = deltaVolume < 0 ? 1 : opacity;

        const color = mostSaturatedColor.replace('rgb', 'rgba').replace(')', ',' + opacity + ')');

        return color;
    }

    public override TickAsync (): void {
        super.TickAsync();

        if (this.quickTableRactiveBID?.quickTable.needRedraw) {
            this.quickTableRactiveBID.quickTable.Draw();
            this.quickTableRactiveBID.quickTable.needRedraw = false;
        }

        if (this.quickTableRactiveASK?.quickTable.needRedraw) {
            this.quickTableRactiveASK.quickTable.Draw();
            this.quickTableRactiveASK.quickTable.needRedraw = false;
        }
    }

    public onInstrumentChanged (instrument: Instrument, lastInstrument: Instrument): void {
        if (isNullOrUndefined(instrument) || instrument === lastInstrument || !this.completed) {
            return;
        }

        if (Instrument.IsEqualInstrument(instrument, lastInstrument)) {
            return;
        }

        this.Unsubscribe(lastInstrument);
        this.UpdateTable();
        this.Subscribe();
        this.updateTradingAllowed();
        this.updateLeverageVisibility();
        // this.symbolLink_Out(false, instrument);  // #115325 <- need to call it in onTradingDataChanged otherwise when linking is on there left fakeNonFixedInstrument in this.orderEdit.instrument
        this.updatePanelHeader();
        this.isShowCommentSelector();
    }

    public updateTradingAllowed (): void {
        const account: Account = this.get('account');
        const instrument: Instrument = this.get('instrument');
        const ordertype: OrderTypeBase = this.get('orderType');
        if (!isNullOrUndefined(instrument) && !isNullOrUndefined(account) && !isNullOrUndefined(ordertype)) {
            const isTradingAllowed = IsAllowed.IsTradingAllowed([account], instrument, ordertype.id());
            void this.set('tradingAllowed', isTradingAllowed.Allowed);
            void this.set('tradingForbiddenReason', isTradingAllowed.ReasonText);
        }
    }

    public updateLeverageVisibility (): void {
        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');
        const productType: ProductType = this.get('productType');
        let visible = false;

        if (!isNullOrUndefined(instrument)) {
            visible = instrument.isLeverageVisible(account, productType);
        }

        void this.set('panelsVisibility.leverage', visible);
    }

    public onUpdateInstrument (instrument: Instrument): void {
        const myInstrument: Instrument = this.get('instrument');
        if (!isNullOrUndefined(myInstrument) && myInstrument === instrument) {
            this.updateTradingAllowed();
        }
    }

    public onUpdateAccountData (): void {
        this.updateSettings(false);
    }

    public onAccountChanged (account: Account, lastAccount): void {
        if (isNullOrUndefined(account)) {
            return;
        }

        this.accountLink_Out(false, account);
        this.updateTradingAllowed();
        this.updateLeverageVisibility();
    }

    public override themeChange (): void {
        super.themeChange();

        if (!isNullOrUndefined(this.quickTableRactiveBID)) {
            this.quickTableRactiveBID.themeChange();
        }

        if (!isNullOrUndefined(this.quickTableRactiveASK)) {
            this.quickTableRactiveASK.themeChange();
        }

        const theme = ThemeManager.CurrentTheme;

        this.FLayerColors[0] = theme.MarketDepth_Layer0_BackColor;
        this.FLayerColors[1] = theme.MarketDepth_Layer1_BackColor;
        this.FLayerColors[2] = theme.MarketDepth_Layer2_BackColor;
        this.FLayerColors[3] = theme.MarketDepth_Layer3_BackColor;
        this.FLayerColors[4] = theme.MarketDepth_Layer4_BackColor;

        this.FLayerColorsForeground[0] = theme.MarketDepth_Layer0_ForeColor;
        this.FLayerColorsForeground[1] = theme.MarketDepth_Layer1_ForeColor;
        this.FLayerColorsForeground[2] = theme.MarketDepth_Layer2_ForeColor;
        this.FLayerColorsForeground[3] = theme.MarketDepth_Layer3_ForeColor;
        this.FLayerColorsForeground[4] = theme.MarketDepth_Layer4_ForeColor;

        this.MostSaturatedAskForeColor = theme.MostSaturatedAskForeColor;
        this.MostSaturatedAskBackColor = theme.MostSaturatedAskBackColor;
        this.MostSaturatedBidForeColor = theme.MostSaturatedBidForeColor;
        this.MostSaturatedBidBackColor = theme.MostSaturatedBidBackColor;

        this.TableMDSizeHistogram = theme.TableMDSizeHistogram;
        this.TableBIDSizeHistogram = theme.TableBIDSizeHistogram;
        this.TableASKSizeHistogram = theme.TableASKSizeHistogram;
        this.TableMDSizeHistogramBrush = new SolidBrush(this.TableMDSizeHistogram);
        this.TableAllHistogramTextColor = theme.TableAllHistogramTextColor;
        this.TableMDSizeHistogramTextColor = theme.TableMDSizeHistogramTextColor;
        this.TableMDBackgroundColor = theme.TableMDBackgroundColor;

        this.SetBackgroundColorForQuickTables();
    }

    // #region Link

    public override symbolLink_Out (newSubscriber, instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            const ins = this.get('instrument');
            if (isNullOrUndefined(ins)) return;
            instrument = ins;
        }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
        }
    }

    public override symbolLink_In (symbolName: string): void {
        const newInstr = DataCache.getInstrumentByName(symbolName);
        if (!isNullOrUndefined(newInstr)) {
            void this.set('instrument', newInstr);
        }
    }

    // #endregion Link

    public createPanelMenuItems (): IMenuItem[] {
        const onPanelMenuItemClicked = this.onPanelMenuItemClicked.bind(this);

        const menuItems: IMenuItem[] = [
            {
                text: Resources.getResource('panel.Level2.menu.View'),
                enabled: true,
                subitems: [
                    {
                        text: Resources.getResource('panel.Level2.menu.View.Order Entry'),
                        tag: 'oe',
                        enabled: true,
                        checked: this.get('panelsVisibility.oe'),
                        canCheck: true,
                        event: onPanelMenuItemClicked
                    },
                    {
                        text: Resources.getResource('panel.Level2.menu.View.Level1'),
                        tag: 'lvl1',
                        enabled: true,
                        checked: this.get('panelsVisibility.lvl1'),
                        canCheck: true,
                        event: onPanelMenuItemClicked
                    },
                    {
                        text: Resources.getResource('panel.Level2.menu.View.InstrInfo'),
                        tag: 'pos',
                        enabled: true,
                        checked: this.get('panelsVisibility.pos'),
                        canCheck: true,
                        event: onPanelMenuItemClicked
                    },
                    {
                        text: Resources.getResource('property.MirrorShow'),
                        tag: 'mirror',
                        enabled: true,
                        checked: this.get('mirror'),
                        canCheck: true,
                        event: onPanelMenuItemClicked
                    }
                ]
            },
            {
                text: Resources.getResource('property.MarketDepth.ColoringMethod'),
                enabled: true,
                subitems: [
                    {
                        text: Resources.getResource('MarketDepthPanel.ColoringMethod.RelativeToVolume'),
                        tag: 'coloringVolume',
                        enabled: true,
                        checked: this.ColoringMethod === ColoringMethod.RelativeToVolume,
                        canCheck: true,
                        event: onPanelMenuItemClicked
                    },
                    {
                        text: Resources.getResource('MarketDepthPanel.ColoringMethod.ByPriceLVL'),
                        tag: 'coloringPrice',
                        enabled: true,
                        checked: this.ColoringMethod === ColoringMethod.ByPriceLVL,
                        canCheck: true,
                        event: onPanelMenuItemClicked
                    },
                    {
                        text: Resources.getResource('MarketDepthPanel.ColoringMethod.SizeHistogram'),
                        tag: 'sizeHistogram',
                        enabled: true,
                        checked: this.ColoringMethod === ColoringMethod.SizeHistogram,
                        canCheck: true,
                        event: onPanelMenuItemClicked
                    }
                ]
            }
        ];

        return menuItems;
    }

    public onPanelMenuItemClicked (menuItem): void {
        const tag = menuItem.tag;
        const checked = menuItem.checked;

        switch (tag) {
        case 'mirror':
            void this.set('mirror', checked);
            break;
        case 'lvl1':
        case 'oe':
        case 'pos':
            void this.set('panelsVisibility.' + tag, checked);
            break;
        case 'coloringVolume':
            this.menuTagDict.coloringPrice.checked = false;
            this.menuTagDict.sizeHistogram.checked = false;
            this.ColoringMethod = ColoringMethod.RelativeToVolume;
            break;
        case 'coloringPrice':
            this.menuTagDict.coloringVolume.checked = false;
            this.menuTagDict.sizeHistogram.checked = false;
            this.ColoringMethod = ColoringMethod.ByPriceLVL;
            break;
        case 'sizeHistogram':
            this.menuTagDict.coloringPrice.checked = false;
            this.menuTagDict.coloringVolume.checked = false;
            this.ColoringMethod = ColoringMethod.SizeHistogram;
            break;
        }

        this.UpdateTable();
    }

    public setPriceFromTable (table, cell, rowIndex: number): void {
        const item = table.rows[rowIndex].item;
        const price = item.getColumnValue(1);
        const orderEdit = this.orderEdit;
        if (!isNullOrUndefined(orderEdit)) {
            orderEdit.setBasePrice(price);
        }
    }

    public setAmountAndPriceFromTable (table, cell, rowIndex: number): void {
        const item = table.rows[rowIndex].item;
        const price = item.getColumnValue(1);

        const orderEdit = this.orderEdit;
        if (!isNullOrUndefined(orderEdit)) {
            orderEdit.setBasePrice(price);
        }

    // TODO. Quantity. UGLY.
    // this.set('defaultQuantity', qty)      // закомментировал в связи с 94331
    }

    // #region Table synchronization

    public onMirrorChanged (): void {
        if (isNullOrUndefined(this.quickTableRactiveBID) || isNullOrUndefined(this.quickTableRactiveASK)) {
            return;
        }

        this.syncColumnsIndex(
            this.quickTableRactiveBID.quickTable,
            this.quickTableRactiveASK.quickTable);
    }

    public syncColumnsVisibility (srcTable, outTable): void {
        let changeOccured = false;
        const outColumns = outTable.columns;
        const srcColumns = srcTable.columns;
        const len = srcColumns.length;
        for (let i = 0; i < len; i++) {
            const srcColumn = srcColumns[i];
            const outColumn = outColumns[i];
            if (srcColumn.visible !== outColumn.visible) {
                outColumn.visible = srcColumn.visible;
                changeOccured = true;
            }
        }

        if (changeOccured) {
            outTable.UpdateSortedColumns();
            outTable.needRedrawBackground = true;
            outTable.needRedraw = true;
            outTable.populateHeaderContextMenu(outColumns);
            outTable.OnVisibleColumnChanged.Raise(outTable.getVisibleColumn());
        }
    }

    public SetColumnsDefaultDisplayIndex (table): void {
        table.columns[0].displayedIndex = 9;
        table.columns[1].displayedIndex = 8;
        table.columns[2].displayedIndex = 7;
        table.columns[3].displayedIndex = 6;
        table.columns[4].displayedIndex = 5;
        table.columns[5].displayedIndex = 4;
        table.columns[7].displayedIndex = 3;
        table.columns[9].displayedIndex = 2;
        table.columns[10].displayedIndex = 1;
    }

    public syncColumnsWidth (srcTable, outTable): void {
        let changeOccured = false;
        const outColumns = outTable.columns;
        const srcColumns = srcTable.columns;
        const len = srcColumns.length;
        for (let i = 0; i < len; i++) {
            const srcColumn = srcColumns[i];
            const outColumn = outColumns[i];
            if (srcColumn.width !== outColumn.width) {
                outColumn.width = srcColumn.width;
                changeOccured = true;
            }
        }

        if (changeOccured) {
            outTable.needRedrawBackground = true;
            outTable.needRedraw = true;
            outTable.OnColumnResize.Raise();
        }
    }

    public syncColumnsIndex (srcTable, outTable): void {
        if (isNullOrUndefined(srcTable) || isNullOrUndefined(outTable)) {
            return;
        }

        let changeOccured = false;
        const mirror: boolean = this.get('mirror');
        const outColumns = outTable.columns;
        const srcColumns = srcTable.columns;
        const len = srcColumns.length;
        if (mirror) {
            srcTable.CustomTextAlign = 'end';
            outTable.CustomTextAlign = 'start';
        } else {
            srcTable.CustomTextAlign = 'end';
            outTable.CustomTextAlign = 'end';
        }
        for (let i = 0; i < len; i++) {
            const srcColumn = srcColumns[i];
            const outColumn = outColumns[i];

            const srcDisplayedIdx = srcColumn.displayedIndex;
            const outDisplayedIdx = mirror
                ? len - srcDisplayedIdx - 1
                : srcDisplayedIdx;

            if (outDisplayedIdx !== outColumn.displayedIndex) {
                outColumn.displayedIndex = outDisplayedIdx;
                changeOccured = true;
            }
        }

        if (changeOccured) {
            outTable.UpdateSortedColumns();
            outTable.needRedrawBackground = true;
            outTable.needRedraw = true;
        }
    }

    // #endregion

    // TODO.
    public repopulate (): void {
        super.repopulate();

        void this.set('account', this.get('account'));
        void this.set('instrument', this.get('instrument'));

        if (!isNullOrUndefined(this.quickTableRactiveBID?.quickTable)) {
            this.visibleColumnChanged(this.quickTableRactiveBID.quickTable.getVisibleColumn());
        }
    }

    // #region Subscribe Unsubscribe newQuote
    public Subscribe (): void {
        const value: Instrument = this.get('instrument');

        if (isNullOrUndefined(value)) {
            return;
        }

        if (value.DataCache != null) {
            if (!isNullOrUndefined(this.quickTableRactiveBID?.quickTable)) {
                this.quickTableRactiveBID.quickTable.needRedrawBackground = true;
            }
            if (!isNullOrUndefined(this.quickTableRactiveASK?.quickTable)) {
                this.quickTableRactiveASK.quickTable.needRedrawBackground = true;
            }

            value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_TRADES);
            value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_LEVEL2);
            value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_LEVEL1);
        // this.newQuote(value.GetLastQuote(QuoteValid.Last));
        }

        value.RiskSettingsUpdated.Subscribe(this.updateSettings, this);
    }

    public Unsubscribe (lastInstrument: Instrument): void {
        if (isNullOrUndefined(lastInstrument)) {
            return;
        }

        lastInstrument.RiskSettingsUpdated.UnSubscribe(this.updateSettings, this);

        if (lastInstrument.DataCache != null) {
            lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_TRADES);
            lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_LEVEL2);
            lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_LEVEL1);
        }
    }

    public newQuote (message): void {
        if (isNullOrUndefined(message) && message.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) {
            return;
        }

        this.UpdateTable();
    }

    // #endregion

    public UpdateTable (): void {
    //    if (SuspendUpdateTable)
    //        return;
        if (isNullOrUndefined(this.quickTableRactiveBID) || isNullOrUndefined(this.quickTableRactiveASK)) {
            return;
        }

        const instrument: Instrument = this.get('instrument');
        if (isNullOrUndefined(instrument)) { return; }
        const lvl2 = instrument.DataCache.FQuoteCache.GetLevel2Cash(instrument);

        if (isNullOrUndefined(lvl2)) {
            return;
        }

        const acc = this.get('account');
        const sp = DataCache.GetSpreadPlan(acc);
        const bids = lvl2.GetBids(sp);
        const asks = lvl2.GetAsks(sp);

        this.PopulateTable(this.quickTableRactiveBID.quickTable, bids, true);
        this.PopulateTable(this.quickTableRactiveASK.quickTable, asks, false);
        this.coloringRowsMethod(this.ColoringMethod, this.quickTableRactiveASK.quickTable.rowsArray, this.quickTableRactiveBID.quickTable.rowsArray);
    }

    public PopulateTable (QuickTableList: QuickTable<Level2ItemQ>, Level2CollectionLev2Collect: Level2Collection, isBid: boolean): void {
        const instrument = this.getInstrument();
        const account: Account = this.get('account');
        const productType: ProductType = this.get('productType');

        const items = Level2CollectionLev2Collect.GetSortedList(isBid);
        let aggregatedVolume = 0;
        let prevAggregatedVolume = 0;
        let avgPrice = 0;
        let lastAvgPrice = 0;
        let count = 0;

        const len = items.length;

        for (let i = 0; i < len; i++) {
            const item = items[i];

            const quoteMessage = item.Quote;
            const MMPD = quoteMessage.MMkey;
            // TODO
            const mdLotsMode = MDLotsMode.General;
            const roundPrecisionValue = 0;
            const roundPrecision = false;

            let row = QuickTableList.rows[count];
            let qItem;
            if (isNullOrUndefined(row)) {
                qItem = new Level2ItemQ(SessionSettings);
                qItem.id = i;
            } else { qItem = row.item; }

            // Подсчет Aggregated Volume
            if (quoteMessage.QuoteType == Leve2QuoteType.None && !isNaN(quoteMessage.Price)) {
                aggregatedVolume += quoteMessage.Size;
                // Подсчет VMA Price
                avgPrice = (lastAvgPrice * prevAggregatedVolume + quoteMessage.Price * quoteMessage.Size) / (prevAggregatedVolume + quoteMessage.Size);
            }

            item.AggregatedVolume = aggregatedVolume;

            item.VWAPrice = avgPrice;

            // запоминаем значения
            lastAvgPrice = avgPrice;
            prevAggregatedVolume = aggregatedVolume;

            qItem.SetAccount(account);
            qItem.SetProductType(productType);
            qItem.SetQuoteMessage(quoteMessage);
            qItem.count = count;
            qItem.VWAP = item.VWAPrice;
            qItem.SetInstrument(instrument);
            qItem.MMPD = MMPD;
            qItem.aggregatedVolume = item.AggregatedVolume;
            qItem.mdLotsMode = mdLotsMode;
            qItem.RoundedPrecisionValue = roundPrecisionValue;
            qItem.RoundedPrecision = roundPrecision;

            qItem.ItemId = count;
            if (isNullOrUndefined(row)) {
                row = QuickTableList.AddItem(qItem);
                row.cells[2].CustomDrawingHandler = this.CustomDrawingHandler.bind(this);
            } else {
                row.updateItem(qItem);
            }

            qItem.Visible = true;
            if (!isNullOrUndefined(row)) {
                row.visible = true;
            }
            count++;
        }

        if (!isNullOrUndefined(QuickTableList.scroll)) {
            QuickTableList.scroll.setScrollElementsCount(count);
        }

        // if (isBid)
        //     this.BidVisibleRowsCount = count;
        // else
        //     this.AskVisibleRowsCount = count;

        // Скрываем лишнее
        for (let i = count; i < QuickTableList.rowsArray.length; i++) {
            QuickTableList.rowsArray[i].item.Visible = false;
            QuickTableList.rowsArray[i].visible = false;
        // QuickTable_list.rowsArray[i].SkipAlertFilter = true;
        }

        QuickTableList.needRedraw = true;
        QuickTableList.needRedrawBackground = true;
    }

    public override getInstrument (): Instrument {
        return this.get<Instrument>('instrument');
    }

    // #region ICaller

    public override callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        void this.set('instrument', this.getCallBackInstrument(properties, 'symbol'));

        let dp = DynProperty.getPropertyByName(properties, 'account');
        if (dp?.value) {
            void this.set('account', SessionSettings.getDefValueFromObj(DataCache.Accounts[dp.value], DataCache.Accounts));
        }

        dp = DynProperty.getPropertyByName(properties, 'mirror');
        if (!isNullOrUndefined(dp)) {
            void this.set('mirror', dp.value);
        }

        dp = DynProperty.getPropertyByName(properties, 'colorMetod');
        if (!isNullOrUndefined(dp)) {
            this.ColoringMethod = dp.value;
        }

        const panelsVisibility = this.get('panelsVisibility');
        for (const key in panelsVisibility) {
            const propName = 'panelsVisibility.' + key;
            dp = DynProperty.getPropertyByName(properties, propName);
            if (!isNullOrUndefined(dp)) {
                void this.set(propName, dp.value);
            }
        }

        const level1Panel = this.findComponent<TerceraLevel1Panel>('terceraLevel1Panel');
        level1Panel.callBack(properties);
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();
        const ins: Instrument = this.get('instrument');
        if (!isNullOrUndefined(ins)) {
            properties.push(new DynProperty('symbol', ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        const acc: Account = this.get('account');
        if (!isNullOrUndefined(acc)) {
            properties.push(new DynProperty('account', acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        const mirror: boolean = this.get('mirror');
        properties.push(new DynProperty('mirror', mirror, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        const colorMetod = this.ColoringMethod;
        properties.push(new DynProperty('colorMetod', colorMetod, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

        const panelsVisibility = this.get('panelsVisibility');
        for (const key in panelsVisibility) {
            properties.push(new DynProperty('panelsVisibility.' + key, panelsVisibility[key], DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
        }

        const level1Panel = this.findComponent<TerceraLevel1Panel>('terceraLevel1Panel');
        properties.push(...level1Panel.properties());

        return properties;
    }

    // #endregion

    public override getXmlSettingsTemplate (): any {
        let xml: any = {};

        const qtRactive = this.quickTableRactiveBID; // симметрична с quickTableRactiveASK
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (!isNullOrUndefined(qt)) {
            xml = qt.xmlSettingsTemplate;
        }

        return xml;
    }

    public override setXmlSettingsTemplate (value): void {
        const qtRactive = this.quickTableRactiveBID;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (!isNullOrUndefined(qt)) {
            qt.xmlSettingsTemplate = value;
            this.quickTableRactiveASK.quickTable.xmlSettingsTemplate = value;
        } else {
            this.deferredBIDXmlTemplate = value;
            this.deferredASKXmlTemplate = value;
        }
    }

    public override updateSettings (needRepopulate = true): void {
        void this.set('reverseOrderButtons', WDSettings.reverseButtonsOrder);

        this.UpdateTable();

        if (needRepopulate) {
            this.repopulate(); // обновляет comboBox-ы (OrderType и Tif) у MarketDepthPanel и AdvancedOrderEntry
        }
        this.updateTradingAllowed();
    }

    public override HotkeyPressed (hotkey: string): boolean {
        const isPressed = super.HotkeyPressed(hotkey);
        if (isPressed) {
            return true;
        }

        const account = this.get('account');
        const instrument = this.get('instrument');
        const hotkeyAction = HotkeysManager.GetActionByHotkeyForPanel(hotkey, this.getType());
        switch (hotkeyAction) {
        case MarketDepthHotkeysEnum.CancelLastOrderOnSelectedInstrument:
            return this.ProcessHotkeyCancelOrder(account, instrument,
                OrderCancelActionHotkeysEnum.LastOrder, PlacedFrom.WEB_MARKETDEPTH);
        case MarketDepthHotkeysEnum.CancelAllActiveOrdersOnSelectedInstrument:
            return this.ProcessHotkeyCancelOrder(account, instrument,
                OrderCancelActionHotkeysEnum.AllOrders, PlacedFrom.WEB_MARKETDEPTH);
        case MarketDepthHotkeysEnum.CancelBuyOrderClosestToTheLastPrice:
            return this.ProcessHotkeyCancelOrder(account, instrument,
                OrderCancelActionHotkeysEnum.BuyOrder, PlacedFrom.WEB_MARKETDEPTH);
        case MarketDepthHotkeysEnum.CancelSellOrderClosestToTheLastPrice:
            return this.ProcessHotkeyCancelOrder(account, instrument,
                OrderCancelActionHotkeysEnum.SellOrder, PlacedFrom.WEB_MARKETDEPTH);
        case MarketDepthHotkeysEnum.PlaceBuyOrder:
            this.placeOrder(null, OperationType.Buy);
            return true;
        case MarketDepthHotkeysEnum.PlaceSellOrder:
            this.placeOrder(null, OperationType.Sell);
            return true;
        case MarketDepthHotkeysEnum.SellAsk:
            this.addSellLimitToAsk();
            return true;
        case MarketDepthHotkeysEnum.BuyBid:
            this.addBuyLimitToBid();
            return true;
        }

        return false;
    }

    private addSellLimitToAsk (): void {
        const bidTable = this.quickTableRactiveASK.quickTable;
        const item = bidTable.rows[0].item;
        const price = item.getColumnValue(1);
        const side = OperationType.Sell;
        this.hotkeyActionAddOrder(price, side);
    }

    private addBuyLimitToBid (): void {
        const bidTable = this.quickTableRactiveBID.quickTable;
        const item = bidTable.rows[0].item;
        const price = item.getColumnValue(1);
        const side = OperationType.Buy;
        this.hotkeyActionAddOrder(price, side);
    }

    private hotkeyActionAddOrder (price: number, side: OperationType): void {
        const orderEdit = (new LimitOrderType()).createOrderEditObject({ dataCache: DataCache });
        const updateData = new OrderEditUpdateData(null, this.getAllTradingDataDict());
        updateData.tradingDataDict.side = side;
        orderEdit.updateParameters(updateData);
        orderEdit.setBasePrice(price);

        void DataCache.FOrderExecutor.placeOrderPromise(orderEdit, null, null, this.focusWarningNumeric.bind(this))
            .catch(function () {
                const ex = new CustomErrorClass('MarketDepthPanel error', 'MarketDepthPanel.placeOrder.', 'placeOrder -> placeOrderPromise');
                ErrorInformationStorage.GetException(ex);
            })
            .finally(function () {
                orderEdit.dispose();
            });
    }
}

enum ColoringMethod {
    ByPriceLVL = 0,
    RelativeToVolume = 1,
    StepToMaxVolume = 2,
    ByUpdateTime = 3,
    SizeHistogram = 4,
    None = 5
}

OrderEditViewBase.extendWith(MarketDepthPanel, {
    data: function () {
        return {
            width: 760,
            height: 400,
            posPanelTop: 100,

            isAccountLinkShow: true,
            isSymbolLinkShow: true,

            instrument: null,
            account: null,
            quantity: null, // QuantityObject
            tif: null, // TIF object
            productType: null,
            orderType: null,
            enableComment: false,
            // TODO. UGLY.
            defaultQuantity: null,
            mirror: true,
            oeTop: 0,
            canFilterByAccount: false,

            reverseOrderButtons: false,

            _OperationType: OperationType,

            shortCaptions: [], //  array of 0-2 strings from { 'LMT:', 'STP:', 'Offset:' } captions set

            panelsVisibility: {
                lvl1: true,
                oe: true,
                pos: true,
                productType: false,
                leverage: false,
                dataSource: true, // true to enable data source
                dataSourceOpened: false
            },
            level1Types: [
                Level1ItemType.Last,
                Level1ItemType.Open,
                Level1ItemType.ChangePercent,
                Level1ItemType.High,
                Level1ItemType.Volume,
                Level1ItemType.Low,
                Level1ItemType.IndicativeAuctionPrice,
                Level1ItemType.SettlementPrice,
                Level1ItemType.PrevSettlementPrice,
                Level1ItemType.VWAP,
                Level1ItemType.ReferencePrice,
                Level1ItemType.Ask,
                Level1ItemType.Spread,
                Level1ItemType.LastTime,
                Level1ItemType.ETB,
                Level1ItemType.PrevClose,
                Level1ItemType.Change,
                Level1ItemType.Bid,
                Level1ItemType.BidSize,
                Level1ItemType.AskSize,
                Level1ItemType.LastSize,
                Level1ItemType.OpenInterest,
                Level1ItemType.BidSource,
                Level1ItemType.AskSource,
                Level1ItemType.LastSource,
                Level1ItemType.Funding,
                Level1ItemType.Countdown,

                Level1ItemType.AuctionStart,
                Level1ItemType.AuctionEnd,
                Level1ItemType.LastUpdateTime,
                Level1ItemType.CurrentSession,
                Level1ItemType.TradingStatus,
                Level1ItemType.TradedValue,
                Level1ItemType.OffExchangeVolume,
                Level1ItemType.OffExchangeValue,
                Level1ItemType.NormalMarketSize,
                Level1ItemType.LimitHigh,
                Level1ItemType.LimitLow,
                Level1ItemType.RemainingQty,
                Level1ItemType.RemainingQtySide,
                Level1ItemType.TotalBuyQty,
                Level1ItemType.TotalSellQty,
                Level1ItemType.AvgTradedPrice,
                Level1ItemType.FiftyTwoWeekHighPrice,
                Level1ItemType.FiftyTwoWeekLowPrice,
                Level1ItemType.NSEValue,
                Level1ItemType.DPR,
                Level1ItemType.LastTradedTime
            ]
        };
    },
    partials: {
        bodyPartial: MarketDepthOETemplate
    }
});
