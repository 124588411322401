// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraWindowBase } from './TerceraWindowBase';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';

export class MainWindowBase extends TerceraWindowBase {
    oninit (): void {
        super.oninit();
        MainWindowManager.MainWindow = this;
    }
}
