// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';
import { type CrossRateData } from '../CrossRate/CrossRateData';

export class DirectCrossRateMessage extends DirectMessageBaseClass {
    public CrossRateData: CrossRateData[];

    constructor (crossRateDataArray: CrossRateData[]) {
        super(Message.CODE_CROSSRATE_MESSAGE);

        this.CrossRateData = crossRateDataArray;
    }
}
