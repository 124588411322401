// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { WatchlistSheetItem } from './WatchlistSheetItem';
import { DataCache } from '@shared/commons/DataCache';
import { RulesSet } from '../../Rules/RulesSet';
import { WatchlistSheets } from './WatchlistSheets';

export enum WatchlistSymbolStateEnum {
    None = 0,
    AddedToWatchlist = 1,
    RemoveFromWatchlist = 2,
    MaximumNumberReached = 3
}

export class _WatchlistManager {
    private readonly _defaultNewListName = Resources.getResource('screen.renameScreen.newList.placeholder');
    private readonly _defaultListName = Resources.getResource('editableComboBox.defaultList');

    private items: WatchlistSheetItem[] = [];

    public updateStorageItems (): void {
        this.items = [];
        const items = this.getStorageItems();

        if (items.length === 0) {
            const defaultItem = this.getDefaultItem();
            this.items.push(defaultItem);
            this.saveStorageItems(this.items);
        } else {
            this.items = items;
        }
    }

    public getFirstItem (): WatchlistSheetItem {
        return this.items[0];
    }

    public getItems (): WatchlistSheetItem[] {
        return this.items;
    }

    public addItem (newListName: string): string | null {
        const error = this.validateItemName(newListName);
        if (error) { return error; }

        const menuItem = new WatchlistSheetItem(newListName);
        this.items.push(menuItem);

        this.saveStorageItems(this.items);

        return null;
    }

    public renameItem (oldListName: string, newListName: string): string | null {
        const error = this.validateItemName(newListName);
        if (error) { return error; }

        const menuItem = this.getItemByName(oldListName);
        this.removeItemByName(oldListName);
        menuItem.name = newListName;

        this.items.push(menuItem);
        this.saveStorageItems(this.items);

        return null;
    }

    public removeItem (listName: string): string | null {
        const error = this.validateItemRemove();
        if (error) { return error; }

        this.removeItemByName(listName);
        this.saveStorageItems(this.items);

        return null;
    }

    public addSymbolIDToItemByName (itemName: string, symbolID: string): boolean {
        const item = this.getItemByName(itemName);
        if (!this.canAddSymbol()) { return false; }

        if (!item.has(symbolID)) {
            item.add(symbolID);
        }
        this.saveStorageByItem(item);

        return true;
    }

    public removeSymbolIDFromItemByName (itemName: string, symbolID: string): void {
        const item = this.getItemByName(itemName);
        item.delete(symbolID);
        this.saveStorageByItem(item);
    }

    public checkExistSymbolIDInAnyItems (symbolID: string): boolean {
        const items = this.getItems();
        for (const item of items) {
            if (item.has(symbolID)) {
                return true;
            }
        }
        return false;
    }

    public checkExistSymbolIDInItemName (name: string, symbolID: string): boolean {
        const item = this.getItemByName(name);

        return item.has(symbolID);
    }

    // suffix BeforeAction - add/remove actions.
    public getSymbolIDStateInItemBeforeAction (itemName: string, symbolID: string): WatchlistSymbolStateEnum {
        const symbolInWL = this.checkExistSymbolIDInItemName(itemName, symbolID);
        if (symbolInWL) {
            return WatchlistSymbolStateEnum.RemoveFromWatchlist;
        } else {
            if (this.canAddSymbol()) {
                return WatchlistSymbolStateEnum.AddedToWatchlist;
            } else {
                return WatchlistSymbolStateEnum.MaximumNumberReached;
            }
        }
    }

    public canAddSymbol (): boolean {
        const count = DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_THROTLING_PANEL_LIMIT_WATCHLIST_ROWS);
        if (count === -1) { return true; }

        const items = this.getItems();

        const symbolIds = WatchlistSheets.getAllSymbolIds(items);
        if (count <= symbolIds.length) { return false; }

        return true;
    }

    public validateItemName (name: string): string | null {
        const regex = /^[\s]+|[\s]+$|[^a-zA-Z0-9\s!?\-]/;

        if (regex.test(name)) { return Resources.getResource('screen.renameScreen.illegalName'); }
        if (name.trim() === '') { return Resources.getResource('screen.renameScreen.illegalName'); }

        for (let i = 0; i < this.items.length; i++) {
            const list = this.items[i];
            if (list.name === name || list.name.trim() === name.trim()) {
                return Resources.getResource('screen.rename.alreadyExist.firstPart') + "'" + name + "'" + Resources.getResource('screen.rename.alreadyExist.secondPart');
            }
        }

        return null;
    }

    public validateItemRemove (): string | null {
        if (this.items.length === 1) { return Resources.getResource('screen.remove.lastlist'); }

        return null;
    }

    public getItemByName (name: string): WatchlistSheetItem | null {
        const findItem = this.items.find((item) => item.name === name);
        return findItem ?? null;
    }

    private removeItemByName (name: string): boolean {
        const index = this.items.findIndex(item => item.name === name);
        if (index !== -1) {
            this.items.splice(index, 1);
            return true;
        }
        return false;
    }

    public saveStorageByItem (updateItem: WatchlistSheetItem): void {
        const updateIndex = this.items.findIndex((item) => item.name === updateItem.name);
        if (updateIndex !== -1) {
            this.items[updateIndex] = updateItem;
        }
        this.saveStorageItems(this.items);
    }

    public saveStorageItems (items: WatchlistSheetItem[]): void {
        items.sort(this.sortItems);
        WatchlistSheets.items = items;
    }

    private getStorageItems (): WatchlistSheetItem[] {
        return WatchlistSheets.items;
    }

    private getDefaultItem (): WatchlistSheetItem {
        const defaultItem = new WatchlistSheetItem(this._defaultListName);
        return defaultItem;
    }

    private readonly sortItems = (a: { name: string }, b: { name: string }): number => {
        const ax: Array<[number, string]> = [];
        const bx: Array<[number, string]> = [];

        const regex = /(\d+)|(\D+)/g;

        let match;
        while ((match = regex.exec(a.name)) !== null) {
            const [_, num, str] = match;
            ax.push([num !== undefined ? parseInt(num, 10) : Infinity, str || '']);
        }

        while ((match = regex.exec(b.name)) !== null) {
            const [_, num, str] = match;
            bx.push([num !== undefined ? parseInt(num, 10) : Infinity, str || '']);
        }

        while (ax.length > 0 && bx.length > 0) {
            const an = ax.shift()!;
            const bn = bx.shift()!;
            const nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if (nn) return nn;
        }

        return ax.length - bx.length;
    };

    public getPreferredItemName (): string {
        let listName = this._defaultNewListName;
        let ind = 0;
        let newNameFound = false;
        const len = this.items.length;

        do {
            ind++;
            newNameFound = true;
            for (let i = 0; i < len; i++) {
                if (this.items[i].name === listName) {
                    listName = this._defaultNewListName + ' ' + ind;
                    newNameFound = false;
                }
            }
        } while (!newNameFound);

        return listName;
    }
}

export const WatchlistManager: _WatchlistManager = new _WatchlistManager();
