// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type StopOutType } from '../Instruments/StopOutType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectMarginCallMessage extends DirectMessageBaseClass {
    public UserId: number | null = null;
    public AssetId: number | null = null;
    public StopOutType: StopOutType | null = null;

    public Balance: number | null = null;
    public UsedMargin: number | null = null;
    public IsMarginCall: boolean | null = null;

    constructor () {
        super(Message.CODE_MARGINCALL);
    }
}
