// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraLinkLabelTemplate } from '../../templates.js';
import { Control } from './Control';

export class TerceraLinkLabel extends Control {
    constructor () { super(); }

    public override oninit (): void {
        this.on('linkClick', this.private_LinkClick);
    }

    public private_LinkClick (sender): void {
        this.fire(TerceraLinkLabelEvents.Click, sender);
    }
}

export enum TerceraLinkLabelEvents {
    Click = 'onClick'
}

Control.extendWith(TerceraLinkLabel, {
    template: TerceraLinkLabelTemplate,
    data: function () {
        return {
            text: 'test',
            linkHref: ''
        };
    }
});
