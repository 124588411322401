// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { Color, SolidBrush } from '../../Commons/Graphics';
import { PlateBase } from './PlateBase';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { type TerceraChartBaseIndicatorRenderer } from '../Renderers/Indicators/TerceraChartBaseIndicatorRenderer';
import { type TerceraChartIndicatorRenderer } from '../Renderers/Indicators/TerceraChartIndicatorRenderer';
import { type TerceraChart } from '../TerceraChart';

// PlateIndicator
export class PlateIndicator extends PlateBase // TerceraChartBaseIndicatorRenderer
{
    public Renderer: TerceraChartBaseIndicatorRenderer<TerceraChart>;
    public isIndicator: boolean;

    constructor (renderer: TerceraChartBaseIndicatorRenderer<TerceraChart>) {
        super();

        this.Renderer = renderer;

        this.isIndicator = true;
    }

    public override GetHover (): boolean {
        return this.Renderer.Selected;
    }

    public override SetHover (value): void {
        this.Renderer.Selected = value;
    }

    public override GetSelected (): boolean {
        return false;
    }

    public override GetVisible (): boolean {
        return this.Renderer.Visible;
    }

    public override Dispose (): void {
        this.Renderer = null;
    }
}

export class PlatePTLIndicator extends PlateIndicator {
    public PTLIndicator: any;
    public lineColors: SolidBrush[];

    constructor (TerceraChartIndicatorRenderer: TerceraChartIndicatorRenderer) {
        super(TerceraChartIndicatorRenderer);

        /// <summary>
        /// replace from chart panel
        /// </summary>
        this.PTLIndicator = TerceraChartIndicatorRenderer.Indicator;

        this.lineColors = [];
        for (let i = 0; i < this.PTLIndicator.LinesCount; i++) {
            let c = this.PTLIndicator.LineColors[i];
            if (!c) {
                c = Color.Violet;
            }

            this.lineColors.push(new SolidBrush(c));
        }
    }

    public override getTextName (): string {
        return this.PTLIndicator.ShortName();
    }

    public getTextValue (lastMouseHoverBarIndex: number, lineNumber, seriesSettings = null): string {
        // TODO
        // var cv = this.PTLIndicator.lineIsConstantVector(lineNumber);
        // if (cv)
        //    return ''; //убрал возможность видеть LevelLine. думаю, это правильно. nicky.    красавчик!

        if (!this.PTLIndicator.LineVisible[lineNumber]) {
            return '';
        }

        // http://tp.pfsoft.net/entity/41138
        if (lastMouseHoverBarIndex < 0) {
            return '---';
        }

        const vector = this.PTLIndicator.getLineData(lineNumber);
        const value = vector.get(lastMouseHoverBarIndex);

        if (Math.abs(value) == Number.MAX_VALUE || isNaN(value) || value == 2147483647) {
            return 'N/A';
        }

        // if (value === 0)
        //     return "N/A"

        if (this.PTLIndicator.Digits < 0 || this.PTLIndicator.Digits > 8) {
            // var dataType = seriesSettings != null && this.Renderer.WindowsNumber == 0 ? seriesSettings.DataType : TerceraChartCashItemSeriesDataType.Absolute;
            // switch (dataType)
            // {
            //    case TerceraChartCashItemSeriesDataType.Relative:
            //        return seriesSettings.relativeDataConverter.Calculate(value).ToString("N2") + "%";
            // }

            return PriceFormatter.formatPrice(value, 5);
        } else {
            return PriceFormatter.formatPrice(value, this.PTLIndicator.Digits);
        }
    }

    public override getBrush (lineNumber): SolidBrush {
        if (this.PTLIndicator.LineColors[lineNumber] != this.lineColors[lineNumber].Color) {
            this.lineColors[lineNumber] = new SolidBrush(this.PTLIndicator.LineColors[lineNumber]);
        }

        return this.lineColors[lineNumber];
    }

    public getLinesCount (): number {
        return this.PTLIndicator.LinesCount;
    }

    public override Dispose (): void {
        this.PTLIndicator = null;
        super.Dispose();
    }

    public override getTypeName (): string {
        return this.getPropertiesText() + ' ' + this.getTextName();
    }

    public override getCloseTT (): string {
        return this.getCloseText() + ' ' + this.getTextName();
    }

    public override getVisibilityTT (): string {
        return 'Show/Hide indicator lines';
    }

    public getPropertiesText (): string {
        return Resources.getResource('chart.menu.PlateIndicatorSettings');
    }

    public override getCloseText (): string {
        return Resources.getResource('chart.menu.PlateIndicatorRemove');
    }

    public override getVisibilityText (): string {
        return Resources.getResource('chart.menu.PlateIndicatorVisible');
    }
}

// #region
//    class PlateBuiltinIndicator : PlateIndicator
// {
//    iBuildInIndicator Builtin;
//    List<SolidBrush> lineColors;
//    public PlateBuiltinIndicator(TerceraChartBuiltinRenderer TerceraChartBuiltinRenderer)
//    : base(TerceraChartBuiltinRenderer)
//    {
//        this.Builtin = TerceraChartBuiltinRenderer.buildin;

//        lineColors = new List<SolidBrush>();
//        for (int i = 0; i < Builtin.Data.Length; i++)
//        {
//            Color c = Color.FromArgb(TerceraChartBuiltinRenderer._selColor[i]);
//            lineColors.Add(new SolidBrush(c));
//        }
// }

// public override string getTextName()
// {
//    string name = Builtin.Name;
//    if (name != null && name.EndsWith("Ex"))
//        return name.Substring(0, name.Length - 2);
//    else
//        return name;
// }
// public override string getTextValue(int lastMouseHoverBarIndex, int lineNumber, TerceraChartCashItemSeriesSettings seriesSettings = null)
// {
//            double value = Builtin.Data[lineNumber][lastMouseHoverBarIndex];

// if (value == int.MinValue || Math.Abs(value) == Int32.MaxValue || double.IsNaN(value))
//    value = 0;

// TerceraChart chart = Builtin.MainChart as TerceraChart;
// if (chart != null)
// {
//    var dataType = seriesSettings != null && Renderer.WindowsNumber == 0 ? seriesSettings.DataType : TerceraChartCashItemSeriesDataType.Absolute;
//    switch (dataType)
//    {
//        case TerceraChartCashItemSeriesDataType.Relative:
//            return seriesSettings.relativeDataConverter.Calculate(value).ToString("N2") + "%";
//    }

//    return chart.Instrument.formatPrice(value);
// }
// else
//    return com.pfsoft.proftrading.cache.Instrument.formatPrice(value, 5);
// }

// public override SolidBrush getBrush(int lineNumber)
// {
//            int color = ((TerceraChartBuiltinRenderer)Renderer)._selColor[lineNumber];
// if (Color.FromArgb(color) != lineColors[lineNumber].Color)
//    lineColors[lineNumber] = new SolidBrush(Color.FromArgb(color));

// return lineColors[lineNumber];
// }
// public override int getLinesCount()
// {
//    return Builtin.Data.Length;
// }

// public override void Dispose()
// {
//    Builtin = null;
//    base.Dispose();
// }

// }
// #endregion
