// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraLabelTemplate } from '../../templates.js';
import { Control } from './Control';
import $ from 'jquery';

export class TerceraLabel extends Control {
    constructor () { super(); }

    public override oncomplete (): void {
        super.oncomplete();

        this.observe('text', (newValue) => {
            void this.set({ innerHTML: newValue });
        });
    }

    public realRelativeBottom (): number {
        const $root = $(this.find('div'));
        return this.get('top') + $root.height();
    }
}

Control.extendWith(TerceraLabel, {
    template: TerceraLabelTemplate,
    data: function () {
        return {
            text: '',
            innerHTML: '',
            style: '',
            additionalClass: ''
        };
    }
});
