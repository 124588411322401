// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TvDataFeed } from '@shared/commons/TradingViewDataFeed/TvDataFeed';
import { TvBrokerTerminal } from '@shared/commons/TradingViewDataFeed/TvBrokerTerminal';
import { type SingleBrokerMetaInfo, type IChartingLibraryWidget, type IBrokerTerminal, type ResolutionString, type TradingTerminalWidgetOptions, type CreateHTMLButtonOptions, type IChartWidgetApi, type ThemeName } from '@shared/commons/TradingViewDataFeed/charting_library';
import { DataCache } from '@shared/commons/DataCache';
import { TvInteriorIdCache, TvInteriorIdCacheMode } from '@shared/commons/TradingViewDataFeed/Caches/TvInteriorIdCache';
import { TvBrokerConfigFlags } from '@shared/commons/TradingViewDataFeed/BrokerConfig/TvBrokerConfigFlags';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { TvSymbolsManager } from '@shared/commons/TradingViewDataFeed/Managers/TvSymbolsManager';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { TvDurationConvertor } from '@shared/commons/TradingViewDataFeed/Convertors/TvDurationConvertor';

import { Resources } from '@shared/localizations/Resources';
import { Connection } from '@shared/commons/Connection';
import { TvTimeFrameHelper } from '@shared/commons/TradingViewDataFeed/Helpers/TvTimeFrameHelper';
import { TvSaveWorkspaceMode, TvWorkspaceManager } from '@shared/commons/TradingViewDataFeed/Managers/TvWorkspaceManager';
import { TvWorkspaceManagerState } from '@shared/commons/TradingViewDataFeed/TradingViewPrimitives/Workspace/TvWorkspaceManagerState';
import { TvSettingsAdapter } from '@shared/commons/TradingViewDataFeed/Settings/TvSettingsAdapter';
import { TvSymbolConvertor } from '@shared/commons/TradingViewDataFeed/Convertors/TvSymbolConvertor';
import { TvDefaultWatchlistProvider } from '@shared/commons/TradingViewDataFeed/Providers/TvDefaultWatchlistProvider';
import { TvFeaturesSet } from '@shared/commons/TradingViewDataFeed/Settings/TvFeaturesSet';
import { TVCustomTranslations } from '@shared/commons/TradingViewDataFeed/Localization/TvCustomTranlation';
import { TvAccountHelper } from '@shared/commons/TradingViewDataFeed/Helpers/TvAccountHelper';
import { tvch } from '@shared/commons/TradingViewDataFeed/Helpers/TvConsoleHelper';
import { TvSavedDataHelper } from '@shared/commons/TradingViewDataFeed/Helpers/TvSavedDataHelper';
import { TvPriceFormatter } from '@shared/commons/TradingViewDataFeed/Formatters/TvPriceFormatter';
import { delay } from '@shared/utils/Time/DateTimeUtils';
import { TvHistoryAggregationsHelper } from '@shared/commons/TradingViewDataFeed/Helpers/TvHistoryAggregationsHelper';
import { ApplicationInfo } from '@shared/commons/ApplicationInfo';

declare const TradingView: any;
declare const Brokers: any;
declare const Datafeeds: any;

enum TradingPlatformAppStates {
    NonInitialized,
    Inited,
    ReadyToInit,
}

class SvgContainer {
    public svgElement: any;
}

class _TradingPlatformApp {
    private tvwidget: IChartingLibraryWidget;
    private brokerFactory: TvBrokerTerminal | null;
    private datafeed: TvDataFeed;
    private state: TradingPlatformAppStates = TradingPlatformAppStates.NonInitialized;
    private activeChart: IChartWidgetApi | null = null;
    private themeName: ThemeName = 'light';
    private readonly logoutSvgContainer = new SvgContainer();
    private saveMode: TvSaveWorkspaceMode = TvSaveWorkspaceMode.None;

    constructor () {
        TvInteriorIdCache.mode = TvInteriorIdCacheMode.TvTradingPlatform;
        this.createBrokerFactory = this.createBrokerFactory.bind(this);
        this.onUpdateAccount = this.onUpdateAccount.bind(this);
        this.onActiveChartChanged = this.onActiveChartChanged.bind(this);
        this.onSymbolChanged = this.onSymbolChanged.bind(this);
        TvWorkspaceManager.subscribeLoadWorkspace(this.onLoadWorkSpace.bind(this));
        TvWorkspaceManager.subscribeSaveWorkspace(this.saveTvWidget.bind(this));
        window.addEventListener('beforeunload', (event) => {
            this.saveTvWidget(TvSaveWorkspaceMode.Both).catch(() => {
                console.error('Save TV Workspace Error::');
            });
        });
    }

    public async Init (): Promise<void> {
        this.state = TradingPlatformAppStates.ReadyToInit;
        const wrkState = TvWorkspaceManager.getState();
        if (wrkState === TvWorkspaceManagerState.Loaded) {
            await this.initOnReady();
        }
        DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);
    }

    public onUpdateInstrument (instrument: Instrument): void {
        if (instrument === TvSymbolsManager.getCurrentInstrument()) {
            void this.refreshOrderPanel();
        }
    }

    private async onLoadWorkSpace (): Promise<void> {
        if (this.state === TradingPlatformAppStates.ReadyToInit) {
            await this.initOnReady();
        }
    }

    private async initOnReady (): Promise<void> {
        this.state = TradingPlatformAppStates.Inited;

        TvHistoryAggregationsHelper.parseKeys();

        const defaultAccountId = TvWorkspaceManager.getDefaultAccountId();
        TvAccountHelper.resetCurrentAccount(defaultAccountId);

        this.createDataFeed();

        // временная заглушка для тестирования, в дальнейшем нужно будет сохранять настройки
        this.cashAllSymbols();

        this.correctSavedData();

        TvSymbolsManager.setCurrentInstrument(DataCache.getFirstTradableInstrument());

        let defaultSymbol: string | undefined;
        if (isNullOrUndefined(TvWorkspaceManager.getChartSettings())) {
            const instrument = this.getDefaultInstrument();
            defaultSymbol = TvSymbolConvertor.getFullname(instrument);
        }

        let defaultResolution = TvWorkspaceManager.getDefaultResolution();
        if (isNullOrUndefined(defaultResolution)) {
            defaultResolution = '1D';
        }

        this.themeName = TvWorkspaceManager.getTheme() as ThemeName;
        if (isNullOrUndefined(this.themeName)) {
            this.themeName = 'light';
        }

        const widgetOptions: TradingTerminalWidgetOptions = {
            debug: tvch.getDebugMode(), // uncomment this line to see Library errors and warnings in the console
            fullscreen: true,
            symbol: defaultSymbol,
            interval: defaultResolution as ResolutionString,
            container: 'tv_chart_container',
            datafeed: this.datafeed,
            library_path: '../../scripts/Libs/TradingViewTradingPlatform/charting_library/',
            locale: 'en',
            disabled_features: TvFeaturesSet.getDisabledFeatures(),
            enabled_features: TvFeaturesSet.getEnabledFeatures(),
            custom_translate_function: TVCustomTranslations.Translate,
            custom_css_url: 'custom_css/tvplatform.css',
            theme: this.themeName,

            widgetbar: {
                details: true,
                news: false,
                watchlist: true,
                datawindow: true
                // watchlist_settings: {
                //     default_symbols: ['MSFT', 'IBM', 'AAPL']
                // }
            },

            // rss_news_feed: {
            //     default: [{
            //         url: 'https://demo-feed-data.tradingview.com/news?symbol={SYMBOL}',
            //         name: 'Yahoo Finance'
            //     }]
            // },

            // broker_factory: function (host) { return new Brokers.BrokerSample(host, datafeed); },
            broker_factory: this.createBrokerFactory,
            broker_config: this.getBrokerConfig(),
            time_frames: TvTimeFrameHelper.getTimeFrames(),
            settings_adapter: TvSettingsAdapter,

            custom_formatters: {
                priceFormatterFactory: (symbolInfo, minTick) => {
                    if (isNullOrUndefined(symbolInfo)) return null;
                    return new TvPriceFormatter(symbolInfo.name);
                }
            }
        };

        const chartSettings = TvWorkspaceManager.getChartSettings();

        if (chartSettings) {
            widgetOptions.saved_data = chartSettings;
        } else {
            const defaultSymbols = await TvDefaultWatchlistProvider.getDefaultWatchlist();
            if (defaultSymbols.length > 0) {
                widgetOptions.widgetbar.watchlist_settings = {
                    default_symbols: defaultSymbols
                };
            }
        }

        this.tvwidget = new TradingView.widget(widgetOptions);
        this.tvwidget.onChartReady(this.onTVWChartReady.bind(this));
        await this.tvwidget.headerReady();
        this.afterHeaderReady();
    }

    private correctSavedData (): void {
        const chartSettings = TvWorkspaceManager.getChartSettings();
        TvSavedDataHelper.correctChartSettings(chartSettings);
        TvSavedDataHelper.correctInitialSettings(TvSettingsAdapter.initialSettings);
    }

    private createDataFeed (): TvDataFeed {
        this.destroyDataFeed();
        this.datafeed = new TvDataFeed();
        this.datafeed.setCurrentAccount(TvAccountHelper.getCurrentAccount());
        return this.datafeed;
    }

    private createBrokerFactory (host): IBrokerTerminal {
        this.destroyBrokerFactory();
        this.brokerFactory = new TvBrokerTerminal(host, this.datafeed);
        this.brokerFactory.subscribeOnUpdateAccount(this.onUpdateAccount);
        return this.brokerFactory;
    }

    private destroyBrokerFactory (): void {
        if (isNullOrUndefined(this.brokerFactory)) return;
        this.brokerFactory.unsubscribeOnUpdateAccount(this.onUpdateAccount);
        this.brokerFactory.destroy();
        this.brokerFactory = null;
    }

    private destroyDataFeed (): void {
        if (isNullOrUndefined(this.datafeed)) return;
        this.datafeed.destroy();
        this.datafeed = null;
    }

    private onTVWChartReady (): void {
        // init broker terminal
        if (isNullOrUndefined(this.tvwidget)) return;
        this.subscribeOnActiveChartChanged();
        this.onActiveChartChanged();
        this.subscribeIntervalChanged();

        // const chartSettings = TvWorkspaceManager.getChartSettings();
        // if (chartSettings) {
        //     this.tvwidget.load(chartSettings);
        // }
    }

    onUpdateAccount (_newAcc, _oldAcc): void {
        TvWorkspaceManager.setDefaultAccountId(TvAccountHelper.getCurrentAccountId());
        this.allChartsResetData();
    }

    private allChartsResetData (): void {
        const w = this.tvwidget;
        if (w == null) { return; }

        const numCharts = w.chartsCount();
        let chartsLoaded = 0;

        const onDataLoadedCallback = (): void => {
            chartsLoaded++;
            if (chartsLoaded === numCharts) {
                this.brokerFactory.updateHostCurrentAccount();
            }
        };

        for (let i = 0; i < numCharts; i++) {
            const chart = w.chart(i);
            chart.onDataLoaded().subscribe(null, onDataLoadedCallback, true);
            chart.resetData();
        }
    }

    private subscribeOnActiveChartChanged (): void {
        this.tvwidget.subscribe('activeChartChanged', this.onActiveChartChanged);
    }

    private onActiveChartChanged (): void {
        this.unsubscribeTvSymbolChanged();
        this.activeChart = this.tvwidget.activeChart();
        this.subscribeTvSymbolChanged();
        this.onSymbolChanged();
    }

    private unsubscribeTvSymbolChanged (): void {
        if (isNullOrUndefined(this.activeChart)) return;
        this.activeChart.onSymbolChanged().unsubscribe(this, this.onSymbolChanged);
    }

    private subscribeTvSymbolChanged (): void {
        this.activeChart.onSymbolChanged().subscribe(this, this.onSymbolChanged);
    }

    private onSymbolChanged (): void {
        const symbol = this.tvwidget.activeChart().symbol();
        const symbolWithoutExchange = InstrumentUtils.RemoveRouteName(symbol);
        const interiorId = TvInteriorIdCache.getInteriorId(symbolWithoutExchange);
        const instrument = DataCache.getInstrumentByName(interiorId);
        TvSymbolsManager.setCurrentInstrument(instrument);
    }

    private subscribeIntervalChanged (): void {
        this.tvwidget.activeChart().onIntervalChanged().subscribe(null, () => {
            const resolution = this.tvwidget.activeChart().resolution();
            TvWorkspaceManager.setDefaultResolution(resolution);
        });
    }

    private cashAllSymbols (): void {
        // TODO: non fixed list
        const allInstruments = DataCache.Instruments;
        for (const interiorID in allInstruments) {
            const instrument = allInstruments[interiorID];
            if (instrument.NeedToHide()) {
                continue;
            }

            const name = instrument.DisplayName();
            const interiorId = instrument.GetInteriorID();
            TvInteriorIdCache.saveInteriorId(name, interiorId);
        }
    }

    private getBrokerConfig (): SingleBrokerMetaInfo {
        const instrument = this.getDefaultInstrument();
        const account = TvAccountHelper.getCurrentAccount();

        const brokerConfig: SingleBrokerMetaInfo = {
            configFlags: TvBrokerConfigFlags.getCongiFlags(instrument, account),
            // customNotificationFields
            durations: TvDurationConvertor.getAllAllowedDurations()
            // positionDialogOptions
            // orderRules
            // customUI
        };

        return brokerConfig;
    }

    private async refreshOrderPanel (): Promise<void> {
        const isOrderPanelOpen = TvWorkspaceManager.isOrdersPanelOpen();
        if (!isOrderPanelOpen) {
            return;
        }

        const widgetbar = await this.tvwidget.widgetbar();
        widgetbar.closeOrderPanel();
        await delay(1000);
        widgetbar.openOrderPanel();
    }

    private getDefaultInstrument (): Instrument {
        let ins = TvSymbolsManager.getCurrentInstrument();
        if (ins == null) {
            ins = DataCache.getAnyInstrument();
        }
        return ins;
    }

    private async onLogoutButtonClick (): Promise<void> {
        await this.saveTvWidget(TvSaveWorkspaceMode.Both);
        this.destroyBrokerFactory();
        this.destroyDataFeed();
        DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);
        // TODO:
        // dispose TV caches?
        // remove subscriptions from tv widget
        // implement IDestroyable from API
        // stop all timers
        // clear all caches
        // remove all subsciptions
        try {
            this.tvwidget?.remove();
        } catch (e) {
            console.error(e);
        }
        Connection?.disconnect();
    }

    private async saveTvWidget (mode: TvSaveWorkspaceMode): Promise<void> {
        this.saveMode = mode;
        this.tvwidget?.save(this.saveWorkSpace.bind(this));
    }

    private async saveWorkSpace (data: any): Promise<void> {
        try {
            if (ApplicationInfo.isExploreMode) {
                return;
            }

            await TvWorkspaceManager.saveWorkSpace(data, this.saveMode);
        } catch (e) {
            console.error('Save TV Workspace Error::', e);
        }
    }

    private afterHeaderReady (): void {
        this.createThemeChangedToggle();
        this.createLogoutButton();
        this.fixingIframeClipboard();
    }

    private fixingIframeClipboard (): void {
        const tvIframe = document.getElementById('tv_chart_container')?.firstChild as HTMLIFrameElement;
        tvIframe.allow = 'clipboard-read; clipboard-write';
    }

    private createLogoutButton (): void {
        const options: CreateHTMLButtonOptions = {
            align: 'right',
            useTradingViewStyle: false
        };
        const button: HTMLElement = this.tvwidget.createButton(options);
        button.setAttribute('title', Resources.getResource('ribbon.enviroment.logout'));
        button.addEventListener('click', this.onLogoutButtonClick.bind(this));

        // create svg for button
        const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        svg.setAttribute('width', '20');
        svg.setAttribute('height', '20');
        svg.style.fill = this.themeName === 'dark' ? '#fff' : '#000';

        const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
        use.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '../../../../current_theme/web-desktop-sprite.svg#logoutTradingView');
        svg.appendChild(use);
        button.appendChild(svg);
        this.logoutSvgContainer.svgElement = svg;
    }

    private createThemeChangedToggle (): void {
        const widget = this.tvwidget;
        const logoutSvgContainer = this.logoutSvgContainer;
        const themeToggleEl = widget.createButton({
            useTradingViewStyle: false,
            align: 'right'
        });
        themeToggleEl.dataset.internalAllowKeyboardNavigation = 'true';
        themeToggleEl.id = 'theme-toggle';
        themeToggleEl.innerHTML = `
        <style>
            #documentation-toolbar-button {
                all: unset;
                position: relative;
                color: #FFF;
                font-size: 14px;
                font-weight: 400;
                line-height: 18px;
                letter-spacing: 0.15408px;
                padding: 5px 12px;
                border-radius: 80px;
                background: #2962FF;
                cursor: pointer;
            }
            #documentation-toolbar-button:hover {
                background: #1E53E5;
            }
            #documentation-toolbar-button:active {
                background: #1948CC;
            }
            #theme-toggle {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 12px;
            }
            .switcher {
                display: inline-block;
                position: relative;
                flex: 0 0 auto;
                width: 38px;
                height: 20px;
                vertical-align: middle;
                z-index: 0;
                -webkit-tap-highlight-color: transparent;
            }

            .switcher input {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 1;
                cursor: default;
            }

            .switcher .thumb-wrapper {
                display: block;
                border-radius: 20px;
                position: relative;
                z-index: 0;
                width: 100%;
                height: 100%;
            }

            .switcher .track {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                background-color: #a3a6af;
            }

            #theme-switch:checked + .thumb-wrapper .track {
                background-color: #2962ff;
            }

            .switcher .thumb {
                display: block;
                width: 14px;
                height: 14px;
                border-radius: 14px;
                transition-duration: 250ms;
                transition-property: transform;
                transition-timing-function: ease-out;
                transform: translate(3px, 3px);
                background: #ffffff;
            }

            [dir=rtl] .switcher .thumb {
                transform: translate(-3px, 3px);
            }

            .switcher input:checked + .thumb-wrapper .thumb {
                transform: translate(21px, 3px);
            }

            [dir=rtl] .switcher input:checked + .thumb-wrapper .thumb {
                transform: translate(-21px, 3px);
            }

            #documentation-toolbar-button:focus-visible:before,
            .switcher:focus-within:before {
                content: '';
                display: block;
                position: absolute;
                top: -2px;
                right: -2px;
                bottom: -2px;
                left: -2px;
                border-radius: 16px;
                outline: #2962FF solid 2px;
            }
        </style>
        <label for="theme-switch" id="theme-switch-label">Dark Mode</label>
        <div class="switcher">
            <input type="checkbox" id="theme-switch" tabindex="-1">
            <span class="thumb-wrapper">
                <span class="track"></span>
                <span class="thumb"></span>
            </span>
        </div>`;
        themeToggleEl.title = 'Toggle theme';
        const checkboxEl = themeToggleEl.querySelector<HTMLInputElement>('#theme-switch');
        checkboxEl.checked = this.themeName === 'dark';
        checkboxEl.addEventListener('change', function () {
            const themeToSet = this.checked ? 'dark' : 'light';
            void widget.changeTheme(themeToSet, { disableUndo: true });
            const svgElement = logoutSvgContainer.svgElement;
            if (!isNullOrUndefined(svgElement)) {
                svgElement.style.fill = this.checked ? '#fff' : '#000';
            }
            TvWorkspaceManager.setTheme(themeToSet);
        });
    }
}

export const TradingPlatformApp = new _TradingPlatformApp();
