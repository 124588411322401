// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { Interval } from '../../../../Utils/History/Interval';
import { Periods } from '../../../../Utils/History/TFInfo';
import { TimeSpanPeriods } from '../../../../Utils/Time/TimeSpan';
import { HistoryMerger } from './HistoryMerger';
import { Decimal } from 'decimal.js';

export class HistoryMergerBySeconds extends HistoryMerger {
    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
    }

    /** old algorithm (from Jan 1 1970 ) */
    FindIntervalBorders (date: Date): Interval {
        const data: number = date.getTime();
        const period = this.newPeriod / Periods.SECOND;
        const barLength = period * TimeSpanPeriods.TicksPerSecond;
        const periodsCountFractional: Decimal = new Decimal(data).div(barLength);
        const periodsCount: number = periodsCountFractional.trunc().toNumber();
        const barStartInTicks: number = periodsCount * barLength;
        const barEndInTicks: number = barStartInTicks + barLength;
        const interval = new Interval();
        interval.From = new Date(barStartInTicks);
        interval.To = new Date(barEndInTicks);
        return interval;
    }
}
