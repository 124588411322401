// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type MarginInfoGroupType } from './MarginInfoEnums';
import { type MarginInfoItem } from './MarginInfoItem';

export class MarginInfoGroup {
    id: MarginInfoGroupType;

    title: string;
    items: MarginInfoItem[];
}
