// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { ThemeManager } from '../misc/ThemeManager';
import { DynProperty } from '../../Commons/DynProperty';

export class TradingCentralItem extends BaseItem {
    public activeKey: any;
    public valueFull: any;
    public valueName: any;
    public value: any;
    public valueNameVisible: boolean;
    public offsetByValueName: any;
    public imgName: string;

    constructor (item, context) {
        super(null);

        if (item) {
            this.activeKey = item.activeKey;
            this.valueFull = item[item.activeKey];

            const separIndex = this.valueFull.indexOf(':') + 1;

            this.valueName = this.valueFull.substr(0, separIndex);
            this.value = this.valueFull.substr(separIndex);

            this.valueNameVisible = this.valueName.indexOf('*') !== 0; // is line break row if it's not visible
            this.offsetByValueName = context.GetTextWidth(this.valueName, ThemeManager.Fonts.Font_12F_regular);

            if (this.activeKey.indexOf('indicator_') === 0) {
                this.imgName = parseInt(this.value) ? (this.value == 1 ? 'bull' : 'bear') : 'neutral';
            } else {
                this.imgName = null;
            }
        }

        this.QuickTableEditingInfoMap[0] = (function () {
            return new QuickTableEditingInfo(DynProperty.TRADING_CENTRAL_ITEM);
        })();
    }

    public override ColumnCount (): number {
        return TradingCentralItem.columnsParams.length;
    }

    public override getColumnData (column: number): ColumnData {
        let value = null; let formattedValue = null;

        value = this.getColumnValue(column);

        switch (column) {
        case 0:
            formattedValue = this.valueFull;
            break;
        default:
            formattedValue = value ? value.toString() : '';
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (column: number): any {
        switch (column) {
        case 0:
            return this.valueFull;

        default:
            return null;
        }
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = TradingCentralItem.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey);
        result.Hidden = isHidden;
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        return result;
    }

    public static readonly columnsParams = [new ColumnParams('', 75, 0, false, true, false)];

    public GetMenuIndex (headerKey: string): number {
        if (TradingCentralItem.ListMenuIndex.Contains(headerKey)) {
            return TradingCentralItem.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    public static ListMenuIndex = [''];

    public static lineBreak (qtWidth, context, valueStr, key): any[] {
        const offsetX = 10;
        const cellWidth = qtWidth - offsetX;
        let finish = false;
        const result = [];

        const separIndex = valueStr.indexOf(':') + 1;
        const valueName = valueStr.substr(0, separIndex);
        let value = valueStr.substr(separIndex);
        const font = ThemeManager.Fonts.Font_12F_regular;
        const offsetByRowName = context.GetTextWidth(valueName, font) + offsetX;
        let valueWidth = context.GetTextWidth(value, font);
        let valuePlaceW = cellWidth - offsetByRowName - offsetX;
        let iterationNum = 0;

        while (!finish && ++iterationNum < 10) {
            if (cellWidth && valueWidth > valuePlaceW) {
                const shortLen = Math.ceil(valuePlaceW * value.length / valueWidth);
                const newValue = value.substr(0, value.substr(0, shortLen).lastIndexOf(' '));
                const valueShort = value.substr(newValue.length + 1);

                valueStr = valueName + newValue;

                if (result.length > 0) {
                    result[result.length - 1][key] = '*' + valueStr;
                } else {
                    result.push(TradingCentralItem.createObjectForItem(valueStr, key));
                }

                const newItem = '*' + valueName + valueShort;

                result.push(TradingCentralItem.createObjectForItem(newItem, key));

                value = valueShort;
                valueWidth = context.GetTextWidth(value, font);
                valuePlaceW = cellWidth - offsetByRowName - offsetX;
            } else {
                if (result.length === 0) {
                    result.push(TradingCentralItem.createObjectForItem(valueStr, key));
                }
                finish = true;
            }
        }

        return result;
    }

    public static createObjectForItem (item, key): any // превращение строки и ключа в обьект пригодный для передачи в new TradingCentralItem() всё потому что некий отсталый в развитии IE не признает форму записи result.push({ activeKey: key, [key]: valueStr })
    {
        const obj = { activeKey: key };
        obj[key] = item;

        return obj;
    }
}
