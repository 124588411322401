// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { TerceraBaseWindowTemplate, TerceraClosePositionBodyTemplate, PositionExerciseRequestScreenFooterTemplate } from '../../templates.js';
import { Control } from '../elements/Control';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { TerceraClosePositionScreen } from '../screen/TerceraClosePositionScreen';
import { positionExerciseRequestScreenHandler } from '../../Utils/AppHandlers';
import { DataCache } from '../../Commons/DataCache';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { PositionExerciseOptionValidator } from '../../Commons/UtilsClasses/PositionExerciseOptionValidator';

export class PositionExerciseRequestScreen extends TerceraClosePositionScreen {
    public exerciseValidator: any;
    public exerciseError: string;

    constructor () { super(); }

    public override getType (): ScreensNames { return ScreensNames.PositionExerciseRequestScreen; }

    public static show (position, placedFrom): void {
        const screen = MainWindowManager.Factory.addScreen(ScreensNames.PositionExerciseRequestScreen);

        screen.set('position', position);
        screen.set('placedFrom', placedFrom);

        screen.center();
    }

    public override oncomplete (): void {
        // TerceraClosePositionScreen.prototype.oncomplete.apply(this); // переопределим complete без вызова базового чтоб не создавать лишние контролы

        this.FPosition = null;
        super.oncomplete();
        this.onrender();

        this.on('exerciseBtnClick', this.exerciseBtnClick);
        this.on('cancelBtnClick', this.cancelBtnClick);

        const position = this.get('position');
        void this.fillByPositionAsync(position);
        this.FPosition = position;
        this.exerciseValidator = new PositionExerciseOptionValidator(position);
        Control.Ticker.Subscribe(this.TickAsync, this);

        void this.set({
            partialCloseAllowed: false, // скрытие нумерика и лейбла Quantity to close
            exerciseBtnEnabled: true,
            exerciseBtnTooltip: ''
        });
    }

    public override oninit (): void {
        super.oninit();

        this.localize();
    }

    public exerciseBtnClick (): void {
        const pos = this.get('position');
        const placedFrom = this.get('placedFrom');

        if (pos) {
            DataCache.ExerciseOptionsByPosition(pos, placedFrom);
        }

        this.close();
    }

    public cancelBtnClick (): void {
        this.close();
    }

    public override localize (): void {
        super.localize();

        void this.set({
            header: Resources.getResource('screen.PositionExerciseRequestScreen.title'),
            exerciseBtnText: Resources.getResource('screen.PositionExerciseRequestScreen.exercise'),
            cancelBtnText: Resources.getResource('screen.closePosition.cancel')
        });

        this.exerciseError = Resources.getResource('screen.PositionExerciseRequestScreen.exerciseError');
    }

    public override TickAsync (): void {
        super.TickAsync();
        const isExerciseBtnEnabled = this.get('exerciseBtnEnabled');
        const isAllowExercise = this.exerciseValidator.isAllowExercise();
        if (isExerciseBtnEnabled !== isAllowExercise) {
            const exercisetooltip = isAllowExercise ? '' : this.exerciseError;
            void this.set({
                exerciseBtnEnabled: isAllowExercise,
                exerciseBtnTooltip: exercisetooltip
            });
        }
    }

    public override dispose (): void {
        this.exerciseValidator.dispose();
        Control.Ticker.UnSubscribe(this.TickAsync, this);

        super.dispose();

        // TerceraClosePositionScreen.prototype.dispose.apply(this);    // поскольку oncomplete свой то и dispose также
    }

    public static PositionExerciseRequestScreenHandlerInitialize (): void {
        positionExerciseRequestScreenHandler.show = PositionExerciseRequestScreen.show;
    }
}

TerceraClosePositionScreen.extendWith(PositionExerciseRequestScreen, {
    data: function () {
        return {
            width: 340,
            position: null,
            movable: true,
            showHeader: true,
            showBorder: true,
            placedFrom: null
        };
    },
    template: TerceraBaseWindowTemplate,
    partials: {
        bodyPartial: TerceraClosePositionBodyTemplate,
        footerPartial: PositionExerciseRequestScreenFooterTemplate
    }
});
