// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { DataCacheToolType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty } from '../../DynProperty';

export class SymbolDataCacheTool extends DataCacheTool {
    private symbolNum: number;
    private symbolBmp: any; // Adjust the type accordingly

    /**
     * Constructor for SymbolDataCacheTool
     */
    constructor () {
        super();
        this.symbolNum = -1;
        this.symbolBmp = null;

        this.ToolType = DataCacheToolType.Symbol;
        this.InitPoints(1);
        this.InitBlock();
    }

    /**
     * Gets the symbol number
     * @returns {number} - Symbol number
     */
    get SymbolNum (): number {
        return this.symbolNum;
    }

    /**
     * Sets the symbol number
     * @param {number} value - Symbol number to set
     */
    set SymbolNum (value: number) {
        if (this.symbolNum === value) return;

        this.symbolNum = value;
        const key = 'symbol' + (this.symbolNum + 1) + 'Img'; // Adjust the key generation accordingly
        this.symbolBmp = ThemeManager.CurrentTheme[key];
    }

    /**
     * Gets the symbol bitmap
     * @returns {any} - Symbol bitmap (adjust the type accordingly)
     */
    get SymbolBmp (): any {
        return this.symbolBmp;
    }

    /**
     * Initializes the block for the tool
     */
    protected InitBlock (): void {
        super.InitBlock();
        this.Width = 4;
        this.SymbolNum = 0;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 1;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 1;
    }

    /**
     * Get properties for the tool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const oldPros: DynProperty[] = super.Properties();
        const props: DynProperty[] = [];
        props.push(new DynProperty('Symbol', this.symbolNum, DynProperty.SYMBOL, DynProperty.VISUAL_GROUP));

        const len = oldPros.length;
        for (let i = 0; i < len; i++) {
            const prop = oldPros[i];
            if (
                prop.group === DynProperty.NUMERICAL_PARAMETRS_GROUP ||
                prop.group === DynProperty.HIDDEN_GROUP ||
                prop.name === 'Stick to period' ||
                prop.name === 'IsBackground'
            ) {
                props.push(prop);
            }
        }

        return props;
    }

    /**
     * Callback function for handling property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const prop = DynProperty.getPropertyByName(properties, 'Symbol');
        if (prop) this.SymbolNum = prop.value;
    }
}
