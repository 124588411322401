// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { HolidayItem } from '../cache/HolidayItem';
import { Control } from '../elements/Control';
import { TerceraMenu } from '../elements/TerceraMenu';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { Rectangle } from '@shared/commons/Geometry';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { DayType } from '@shared/utils/Session/DayType';
import { type HolidayDescription } from '@shared/utils/Session/HolidayDescription';

export class InstrumentHolidaysScreen extends ApplicationPanelWithTable<HolidayItem> {
    public static instance: InstrumentHolidaysScreen | null = null;

    constructor () { super(); }

    public override getType (): PanelNames { return PanelNames.InstrumentHolidaysScreen; }

    public override updatePanelHeader (): void {}

    public override oninit (): void {
        super.oninit();
        this.observe('visible', this.onVisibleChanged);
        this.observe('instrument', this.populateHolidayList, { init: false });
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.populateHolidayList();
    }

    public override layoutTable (): void {
        if (isNullOrUndefined(this.quickTableRactive)) {
            return;
        }

        this.quickTableRactive.setBounds(
            0,
            0,
            this.get('width'),
            this.get('height') - 20);
    }

    public onVisibleChanged (visible: boolean): void {
        if (visible) {
            Control.Ticker.Subscribe(this.onControlGlobalTick, this);
        } else {
            Control.Ticker.UnSubscribe(this.onControlGlobalTick, this);
        }
    }

    public onControlGlobalTick (): void {
        this.TickAsync();
    }

    public override lostFocus (): void {
        super.lostFocus();
        void this.set('visible', false);
    }

    public override onCloseButtonClick (): void {
        void this.set('visible', false);
    }

    public static show (instrument: Instrument, x: number, y: number): void {
        const scr = InstrumentHolidaysScreen.getInstance();
        scr.themeChange();
        scr.localize();
        void scr.set({
            header: Resources.getResource('InstrumentDetailsPanel.Holidays list'),
            instrument
        });

        const newLocation = TerceraMenu.CorrectPopupLocation(new Rectangle(
            x,
            y,
            scr.get('width'),
            scr.get('height')));

        scr.setLocation(newLocation.newX, newLocation.newY);

        void scr.set('visible', true);
        scr.setFocus();
    }

    public populateHolidayList (): void {
        const scr = InstrumentHolidaysScreen.getInstance();
        const instrument: Instrument = scr.get('instrument');

        if (!isNullOrUndefined(scr.quickTableRactive)) {
            scr.quickTableRactive.quickTable.ClearAll();
        }

        const ts = instrument?.Session;
        if (isNullOrUndefined(ts)) {
            return;
        }

        const holidaysList: HolidayDescription[] = ts.getSortedHolidays();
        for (const holiday of holidaysList) {
            if (ts.isFutureHoliday(holiday)) {
                const formatDate = holiday.GetFormattedDate();
                const schedule = InstrumentHolidaysScreen.holidayTypeToShedule(holiday.holidayType);

                if (!isNullOrUndefined(scr.quickTableRactive)) {
                    scr.quickTableRactive.quickTable.AddItem(new HolidayItem(formatDate, holiday.name, schedule));
                }
            }
        }
    }

    public static holidayTypeToShedule (holidayType: DayType): string {
        switch (holidayType) {
        case DayType.NOT_WORKING:
            return Resources.getResource('InstrumentDetailsPanel.non trading day');
        case DayType.SHORTED:
            return Resources.getResource('InstrumentDetailsPanel.shortened');
        case DayType.WORKING:
        default:
            return Resources.getResource('InstrumentDetailsPanel.working');
        }
    }

    public override dispose (): void {
        super.dispose();
        Control.Ticker.UnSubscribe(this.onControlGlobalTick, this);
        InstrumentHolidaysScreen.instance = null;
    }

    public static getInstance (): InstrumentHolidaysScreen {
        if (!isNullOrUndefined(InstrumentHolidaysScreen.instance)) {
            return InstrumentHolidaysScreen.instance;
        }

        const scr = new InstrumentHolidaysScreen();
        void scr.set('visible', false);
        MainWindowManager.MainWindow.addControl(scr);

        InstrumentHolidaysScreen.instance = scr;
        return InstrumentHolidaysScreen.instance;
    }
}

ApplicationPanelWithTable.extendWith(InstrumentHolidaysScreen, {
    data: function () {
        return {
            showHeader: true,
            showFooter: false,
            movable: false,
            resizable: false,
            dockablePanel: false,
            width: 296,
            height: 220,
            zIndex: 1301,
            instrument: null
        };
    }
});
