// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { TradingUnlockMessageResponse } from '../Messages/TradingUnlockMessageResponse';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingUnlockResponseProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): TradingUnlockMessageResponse[] {
        const msg = new TradingUnlockMessageResponse(fieldSet);
        return [msg];
    }
}
