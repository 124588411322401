// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { OperationType } from '../../Utils/Trading/OperationType';
import { UrlUtils } from '../../Utils/UrlUtils';
import { type Instrument } from './Instrument';

export class Idea {
    public name: any;
    public companyName: any;
    public logoSrcBytes: any;
    public tradingSystemId: any;
    public title: any;
    public description: any;
    public descriptionParsedToHTML: any;
    public instrument: Instrument | null;
    public instrumentLabel: any;
    public buySide: boolean;
    public side: any;
    public limitPrice: any;
    public limitPriceToShow: null;
    public slPrice: any;
    public sllPrice: any;
    public tpPrice: any;
    public slPriceType: any;
    public tpPriceType: any;
    public slPriceToShow: null;
    public sllPriceToShow: null;
    public tpPriceToShow: null;
    public slPriceToShowFormatted: null;
    public tpPriceToShowFormatted: null;
    public slProfit: null;
    public tpProfit: null;
    public tif: any;
    public tifExpireAt: any;
    public productTypeAnal: any;
    public wasNotRead: any;
    public isOpen = false;
    public createTime: any;
    public expireTime: any;
    public tradingSignalId: any;
    public lifeTime: any;
    public AttachmentScreenPath: any;
    public AttachmentFilePath: any;
    public hasAttachments: any;
    public attachExtension: any;

    constructor (msg, ins: Instrument | null, tradingSystem) {
        this.name = tradingSystem.name;
        this.companyName = tradingSystem.companyName;
        this.logoSrcBytes = tradingSystem.logoBytes;
        this.tradingSystemId = msg.TradingSystemId;

        this.title = msg.Title;
        this.description = msg.Description;
        this.descriptionParsedToHTML = this.UrlifyDescription(this.description);

        this.instrument = ins;
        this.instrumentLabel = ins ? ins.DisplayName() : '';

        this.buySide = OperationType.Buy == msg.OperationType;
        this.side = msg.OperationType;

        this.limitPrice = msg.OpenPrice;
        this.limitPriceToShow = null;
        this.slPrice = msg.SLPrice;
        this.sllPrice = msg.SLLPrice;
        this.tpPrice = msg.TPPrice;
        this.slPriceType = msg.SLPriceType;
        this.tpPriceType = msg.TPPriceType;

        this.tif = msg.Tif;
        this.tifExpireAt = msg.TifExpireAt;
        this.productTypeAnal = msg.ProductType;

        this.wasNotRead = msg.NotSeen;

        this.createTime = msg.CreateTime;
        this.expireTime = msg.ExpireTime;
        this.tradingSignalId = msg.TradingSignalId;
        this.lifeTime = DateTimeUtils.convertTicksToHHMMSS(msg.ExpireTime - Date.now());

        this.AttachmentScreenPath = msg.AttachmentScreenPath;
        this.AttachmentFilePath = msg.AttachmentFilePath;
        this.hasAttachments = this.AttachmentScreenPath || this.AttachmentFilePath;

        if (this.AttachmentFilePath) {
            this.attachExtension = this.AttachmentFilePath.substring(this.AttachmentFilePath.lastIndexOf('.'));
        } // get file extension
    }

    public UrlifyDescription (descr): any {
        return descr.replace(UrlUtils.urlRegex, function (url) {
            return '<a target="_blank" class="ideaDescriptionLink" rel="noopener noreferrer" href="' + url + '">' + url + '</a>';
        });
    };
}
