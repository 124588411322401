// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type ProductType } from '../Instruments/ProductType';
import { type OperationType } from '../Trading/OperationType';
import { type OrderType } from '../Trading/OrderType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectTradingSignalMessage extends DirectMessageBaseClass {
    public NotSeen = false; // поле приходит только если true, потому задам по умолчанию false

    public Title: string | null = null;
    public Description: string | null = null;

    public TradingSignalId: number | null = null;
    public TradingSystemId: number | null = null;

    public OpenPrice: number | null = null;
    public OrderType: OrderType | null = null;
    public OperationType: OperationType | null = null;
    public Tif: any = null;
    public TifExpireAt: Date | null = null;
    public TradableInstrumentId: number | null = null;
    public RouteId: number | null = null;
    public ProductType: ProductType | null = null;

    public SLPrice: number | null = null;
    public SLLPrice: number | null = null;
    public TPPrice: number | null = null;
    public SLPriceType: any = null;
    public TPPriceType: any = null;

    public CreateTime: Date | null = null;
    public ExpireTime: Date | null = null;

    public AttachmentScreenPath: string | null = null;
    public AttachmentFilePath: string | null = null;

    constructor () {
        super(Message.CODE_TRADING_SIGNAL_MESSAGE);
    }
}
