// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { HistoryType } from '@shared/utils/History/HistoryType';
import { LayersEnum } from '@front/chart/Renderers/TerceraChartBaseRenderer';
import { DataCacheToolType } from '@shared/commons/cache/DataCacheToolEnums';
import { TerceraMessageBox } from '../screen/TerceraMessageBox';
import { TerceraChartAction, TerceraChartActionEnum, TerceraChartToolbarsEnum } from '@front/chart/TerceraChartAction';
import { LinkedSystem } from '../misc/LinkedSystem';
import { ChartLoadTrigger, ModelDataType, TerceraChartMVCCommand, type TerceraChartMVCModelItem } from '@front/chart/TerceraChartMVC';
import { ChartHistoryType, TerceraChartDrawingType } from '@front/chart/Utils/ChartConstants';
import { TerceraChartUtils, TerceraChartHistoryType } from '@front/chart/TerceraChartUtils';
import { KeyCode, KeyEventProcessor } from '@shared/commons/KeyEventProcessor';
import { contextMenuHandler } from '@shared/utils/AppHandlers';
import { TerceraIndicatorLookupDropDownForm } from '../elements/Lookup/TerceraIndicatorLookupDropDownForm';
import { ChartPanelTemplate } from '../../templates.js';
import { ControlEvents } from '../elements/Control';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { type TerceraQuantityNumeric } from '../elements/TerceraQuantityNumeric';
import { type TerceraVisualTradingWidget } from '../elements/TerceraVisualTradingWidget';
import { EditPositionScreen } from '../screen/EditPositionScreen';
import { ModifyOrderScreen } from '../screen/ModifyOrderScreen';
import { TerceraAddOverlayScreen } from '../screen/TerceraAddOverlayScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { LookupDropDownShowParams } from '.././UtilsClasses/LookupDropDownShowParams';
import { WINDOW_SIDE_BORDERS_WIDTH } from '../UtilsClasses/SizeConstants';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { Periods } from '@shared/utils/History/TFInfo';
import { DynProperty } from '@shared/commons/DynProperty';
import { IndicatorManager } from '@shared/commons/cache/indicators/IndicatorManager';
import { TradingLockUtils } from '@shared/utils/TradingLockUtils';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { TradingNumericErrorChecker } from '@shared/commons/Trading/TradingNumericErrorChecker';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { ITerceraChartShowScreenType } from '@front/chart/ITerceraChartShowScreenType';
import { TerceraChartActionProcessor } from '@front/chart/TerceraChartActionProcessor/TerceraChartActionProcessor';
import { PanelSettingsScreen } from '../screen/PanelSettingsScreen';
import { ChartTradingCore } from '@shared/utils/Trading/ChartTradingCore';
import { type TerceraChartToolsPanel } from '../elements/TerceraChartToolsPanel';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type TerceraChartRactive } from '../elements/TerceraChartRactive/TerceraChartRactive';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type Account } from '@shared/commons/cache/Account';
import { type TerceraChart } from '@front/chart/TerceraChart';
import { type Order } from '@shared/commons/cache/Order';
import { FullScreenManager } from '../UtilsClasses/FullScreenManager';
import { type IChartContextMenuItem } from './ChartContextMenuItem';
import { type TerceraEditableListComboBox } from '../elements/TerceraEditableListComboBox';
import { type OrderEditBase } from '@shared/commons/cache/OrderParams/order-edit/OrderEditBase';
import { type DirectReloadHistoryMessage } from '@shared/utils/DirectMessages/DirectReloadHistoryMessage';
import { InfoWindowDisplayMode } from '@front/chart/Renderers/InfoWindow/InfoWindowDisplayMode';
import { OrderCancelActionHotkeysEnum, ChartHotkeysEnum } from '@shared/commons/cache/Hotkeys/NamesEventHotkey ';
import { HotkeysManager } from '@shared/commons/cache/Hotkeys/HotkeysManager';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { TimeSpan } from '@shared/utils/Time/TimeSpan';
import { DateSelectorButton } from '../elements/Lookup/TerceraDateSelectorDropDown';
import { ObjectManagerScreen } from '../screen/ObjectManagerScreen';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { type HistoryDepthLimit } from '@shared/commons/cache/RuleProperties/HistoryDepthLimits';
import { type TerceraWindowBase } from '../screen/TerceraWindowBase';

export class ChartPanel extends ApplicationPanelWithTable<any> {
    public static ContextMenuItemsInstance: IChartContextMenuItem[] = null;

    public terceraChartRactive: TerceraChartRactive = null;
    public chartOE: any = null;
    public needUpdateLimitStopNumerics: boolean = false;
    public ModelChanged: CustomEvent = new CustomEvent();
    public myVisualWidget: TerceraVisualTradingWidget | null = null;
    public myTradeNumeric: TerceraQuantityNumeric | null = null; // нумерик для редактирования quantity размещенных на Chart-e ордеров
    public myTradeNumericPromise: any = null;
    public initialTemplateSelected: boolean = false;
    public _terceraChart: any = null;
    public myToolsPanel: TerceraChartToolsPanel;
    public ChartTradingCore: ChartTradingCore;
    public lastMouseMoveEvent: any;
    public mouseMoveAnimationFrameId: number;
    public lastMouseMoveX: number;
    public lastMouseMoveY: number;
    private isOpenedTradingWidget: boolean = false;

    constructor () { super(); }

    public override getType (): PanelNames { return PanelNames.ChartPanel; }

    public override oninit (): void {
        super.oninit();

        this.on('objectManagerButton_btnClick', this.onObjectManagerBtnClick);
        this.on('indicatorButton_btnClick', this.onIndicatorBtnClick);
        this.on('mouseTradeButton_btnClick', this.mouseTradeButton_btnClick);

        this.observe('instrumentItem', this.onInstrumentItemChanged);
        this.observe('accountItem', this.onAccountItemChanged);

        this.on('timeFrameComboBox_ComboItemClicked', this.onTimeFrameComboBox_ComboItemClicked);
        this.on('drawingStyleComboBox_ComboItemClicked', this.onDrawingStyleComboBox_ComboItemClicked);
        // this.on('loadRangeComboBox_ComboItemClicked', this.onLoadRangeComboBox_ComboItemClicked);

        this.observe('showToolsPanel isShowOE oeSmall',
            async () => { await this.layoutTable(); },
            { init: false });
        this.observe('showMainToolBarPanel',
            async (newValue) => {
                await this.set({ isVisibleMainToolBar: newValue });
                await this.layoutTable();
            },
            { init: false });

        this.on('overlay_btnClick', this.overlay_btnClick);

        // TODO
        // Utils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingLockState, this);
        // DataCache.isAllowCache.GlobalWebRulesChanged.Subscribe(this.updateTimeFrameComboBox, this);

        const hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');
        void this.set('showOverlayButton', !Resources.isHidden('chart.ChartSymbolSelector.OverlayTooltip'));
        void this.set('isHiddenMouseTradeButton', !Resources.isHidden('chart.ChartSymbolSelector.mouseTradeButton'));
        void this.set('isHiddenOEButton', !hideOE);

        // У когото может быть открыто ОЕ до его скрытия, нужно его принудительно закрыть
        if (hideOE) {
            void this.set('isShowOE', false);
        }
    }

    public override async oncomplete (): Promise<void> {
        super.oncomplete();

        this.myTradeNumeric = this.Controls.myTradeNumeric;
        this.myVisualWidget = this.Controls.myVisualWidget;

        this.initChart();

        this.chartOE = this.Controls.oe;

        this.OnResize.Subscribe(this.layoutTable, this);
        TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.updateTradingLockState, this);

        this.MouseTradingEnabledSet(false);
        if (!isNullOrUndefined(this.myVisualWidget)) {
            this.myVisualWidget.OnVisualTradingOrderTypeChange.Subscribe((isSingle: boolean) => {
                this.terceraChartRactive.terceraChart.setIsOCOMode(!isSingle);
            }, this);
            this.myVisualWidget.Placed = false;
            this.myVisualWidget.showChartSetting = this.ShowScreen.bind(this, ITerceraChartShowScreenType.ChartSettings, { PanelSettingsDefaultPage: DynProperty.VISUAL_TRADING_GROUP });
        }

        // Ugly. http://tp.pfsoft.lan/entity/61292
        // TODO. Implement "Reset layout" feature or whatever it's called.
        this.themeChange();

        const chartCallbackProps = this.get('chartCallbackProps');
        if (!isNullOrUndefined(chartCallbackProps)) {
            await this.callBack(chartCallbackProps);
            void this.set('chartCallbackProps', null);
        }
        const chartCallbackXml = this.get('chartCallbackXml');
        if (!isNullOrUndefined(chartCallbackXml)) {
            this.setXmlSettingsTemplate(chartCallbackXml);
        }
        this.terceraChartRactive.terceraChart.chartController.SuspendRefreshChart = false;
        this.terceraChartRactive.terceraChart.windowsContainer.minSizeForComponents = this.get<number>('minSizeForToolBars');
        if (DataCache.getNumberOfAccounts() === 1) {
            void this.set({ showAccountLookUp: false });
        }

        this.terceraChartRactive.terceraChart.model.ModelItemChanged.Subscribe(() => {
            this.ModelChanged.Raise();
        }, this);

        this.terceraChartRactive.terceraChart.onHasCorrectDataChanged.Subscribe(this.onHasCorrectDataChanged, this);
        // SessionSettings.timeZoneChanged.Subscribe(this.terceraChartRactive.terceraChart.RefreshChart, this.terceraChartRactive.terceraChart);

        KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
        KeyEventProcessor.OnKeyUp.Subscribe(this.onGlobalKeyUp, this);

        this.myToolsPanel = this.Controls.toolsPanel;
        this.observe('isShowOE', async (chartOEVisible) => {
            await this.myToolsPanel.set('chartOEVisible', chartOEVisible);
            this.myToolsPanel.recalculateLocation();
        });
        this.myToolsPanel.observe('isVertical', (val) => { void this.set('verticalToolsPanel', val); });
        this.myToolsPanel.observe('isLeftOrTop', (val) => { void this.set('leftOrTopToolsPanel', val); });
        this.myToolsPanel.observe('onProcessMove', (moving: boolean) => {
            if (!moving) {
                void this.layoutTable();
            }
        });
        void this.myToolsPanel.set(
            'actionProcessor',
            this.terceraChartRactive.terceraChart.TerceraChartActionProcessor);

        void this.layoutTable().then(() => {
            this.firstChartLoading();
        });
        this.localize();
        this.mouseMoveHandler = this.mouseMoveHandler.bind(this);
        if (!isNullOrUndefined(this.myTradeNumeric)) {
            this.myTradeNumeric.on(ControlEvents.LostFocus, this.onEnterPressed.bind(this));
        }

        const editableList = this.Controls.editableList;
        if (!isNullOrUndefined(editableList)) {
            editableList.set('visible', !Resources.isHidden('chart.templates.visibility'));
            editableList.OnItemSave.Subscribe(this.onSaveTemplate, this);
            editableList.OnSelectList.Subscribe(this.onSelectTemplate, this);
            this.selectInitialTemplate();
        }

        const newIns = this.get('instrumentItem');
        this.subscribe(newIns);
        this.initChartTradingCore();
        DataCache.OnHistoryChangedAndNeedReload.Subscribe(this.checkNeedReload, this);

        this.terceraChartRactive.terceraChart.windowsContainer.scrollerRenderer.TurnOffRenderer();
        // this.terceraChartRactive.terceraChart.autoLoadingStateChanged.Subscribe(this.autoLoadingStateChanged, this);
    }

    private checkNeedReload (message: DirectReloadHistoryMessage): void {
        const instrument: Instrument = this.get('instrumentItem');
        if (isNullOrUndefined(instrument)) { return; }

        const chart = this.terceraChartRactive?.terceraChart;
        if (isNullOrUndefined(chart)) { return; }

        const model = chart.model;
        const tfi = model.GetTimeFrameInfo();
        if (isNullOrUndefined(tfi)) { return; }

        const route = DataCache.getRouteById(instrument.Route);
        if (isNullOrUndefined(route)) { return; }

        const basePeriod = Periods.TranslateToParsePeriod(tfi.Periods);
        const serverPeriod = Periods.TranslateToServerPeriod(basePeriod);

        if (message.instrumentTradableID === instrument.InstrumentTradableID &&
            message.routeID === route.QuoteRouteId &&
            message.barsType === tfi.HistoryType &&
            message.historyPeriod === serverPeriod) {
            chart.RefreshChart();
        }
    }

    // private autoLoadingStateChanged (state: AutoLoadingState): void {
    //     void this.set({ autoLoadingNow: state === AutoLoadingState.LoadinStarted });
    // }

    public initChartTradingCore (): void {
        const ctc = new ChartTradingCore();
        this.ChartTradingCore = ctc;

        ctc.myTradeNumeric = this.myTradeNumeric;
        ctc.myVisualWidget = this.myVisualWidget;
        ctc.chartOE = this.chartOE;
        ctc.terceraChartRactive = this.terceraChartRactive;
        ctc.TradingNumericErrorChecker = TradingNumericErrorChecker;
        ctc.EditPositionScreen = EditPositionScreen;
        ctc.ModifyOrderScreen = ModifyOrderScreen;
        ctc.TerceraMessageBox = TerceraMessageBox;
        ctc.contextMenuHandler = contextMenuHandler;
        ctc.MainWindowManager = MainWindowManager;
    }

    public updateTradingLockState (): void {
        const tradingLocked = TradingLockUtils.TradingLock.tradingLocked;
        void this.set('tradingLocked', tradingLocked);

        if (tradingLocked) {
            const chart = this.terceraChartRactive.terceraChart;
            const ttRenderer = chart.TerceraChartTradingToolsRenderer;
            this.isOpenedTradingWidget = ttRenderer.MouseTradeEnabled;
            this.MouseTradingEnabledSet(false);
        } else {
            this.MouseTradingEnabledSet(this.isOpenedTradingWidget);
        }
    }

    public onInstrumentItemChanged (newIns: Instrument, oldIns: Instrument): void {
        if (isNullOrUndefined(newIns) || newIns === oldIns) {
            return;
        }

        if (!isNullOrUndefined(this.myVisualWidget)) {
            void this.myVisualWidget.set('instrumentItem', newIns);
        }

        this.symbolLink_Out(false, newIns);

        if (!this.completed) {
            return;
        }

        const chart = !isNullOrUndefined(this.terceraChartRactive) ? this.terceraChartRactive.terceraChart : null;
        if (!isNullOrUndefined(chart)) {
            chart.unsubscribeTrades();
            chart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), newIns);
            this.unsubscribe(oldIns);
            this.subscribe(newIns);
        }
    }

    public onAccountItemChanged (newAcc: Account, oldAcc: Account): void {
        if (isNullOrUndefined(newAcc) || newAcc === oldAcc) {
            return;
        }

        if (!isNullOrUndefined(this.myVisualWidget)) {
            void this.myVisualWidget.set('accountItem', newAcc);
        }

        if (!isNullOrUndefined(this.myTradeNumeric)) {
            this.myTradeNumeric.settingsUpdate();
        }

        if (!isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(
                new TerceraChartMVCCommand(ModelDataType.Account),
                newAcc);
        }

        this.accountLink_Out(false, newAcc);
    }

    // TODO. Refactor.
    public subscribe (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChartRactive.terceraChart;

        qc.addListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
    }

    // TODO. Refactor.
    public unsubscribe (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChartRactive.terceraChart;

        qc.removeListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
    // qc.removeListener(instrument, chart, HistoryType.QUOTE_TRADES)
    }

    public onEnterPressed (): void {
        if (isNullOrUndefined(this.myTradeNumeric)) {
            return;
        }

        void this.myTradeNumeric.set('visible', false);
        const value = this.myTradeNumeric.get('value');
        if (!isNullOrUndefined(this.myTradeNumericPromise)) {
            this.myTradeNumericPromise(value);
        }
    }

    public repopulate (): void {
        super.repopulate();
        this.terceraChartRactive.terceraChart.repopulate();
        void this.set({ instrumentItem: this.get('instrumentItem') });
        void this.set({ accountItem: this.get('accountItem') });
    }

    public override async layoutTable (): Promise<void> {
        const chartRactive = this.terceraChartRactive;
        if (isNullOrUndefined(chartRactive)) {
            return;
        }

        const minSizeForToolBars = this.get('minSizeForToolBars');
        const panelSize = this.getCorrectChartPanelSize();
        if ((panelSize.width <= minSizeForToolBars || panelSize.height <= minSizeForToolBars) && !FullScreenManager.IsFullScreenMode) {
            void this.set({ isVisibleMainToolBar: false });
        } else if (!this.get<boolean>('isVisibleMainToolBar') && this.get<boolean>('showMainToolBarPanel')) {
            void this.set({ isVisibleMainToolBar: true });
        }

        await this.layoutTableResize(chartRactive);
        if (this.get<boolean>('showToolsPanel')) {
            if (!isNullOrUndefined(this.myToolsPanel)) {
                this.myToolsPanel.onResize();
            }
        }
    }

    public override updatePanelHeader (): void {
        let header = Resources.getResource('panel.terceraChart') + ' ';

        const chart = this.tChart;

        if (!isNullOrUndefined(chart)) {
            const ins = chart.Instrument();
            if (!isNullOrUndefined(ins)) header += ins.DisplayName() + ' ';

            const tfi = chart.TimeFrameInfo();
            if (!isNullOrUndefined(tfi)) {
                header +=
                Resources.getResource('chart.timeframeselector.time') +
                ' - ' +
                Periods.ToLocalizedShortPeriod(tfi.Periods);
            }
        }

        void this.set('header', header);
    }

    public updateGoToState (): void {
        const tfi = this.tChart?.TimeFrameInfo();
        if (isNullOrUndefined(tfi)) {
            return;
        }

        const enabled = tfi.Periods !== Periods.TIC;
        void this.set('goToEnabled', enabled);
        void this.set('goToBtnTooltip', enabled ? 'GoToSelector.Btn.tt' : 'GoToSelector.Btn.Forbidden.tt');
        const goToDate: Date = this.get('goToDate');
        const minDate = this.getMaxDepthDate();
        void this.set({ minGoToDate: minDate });
        if (!enabled || isNullOrUndefined(goToDate)) {
            return;
        }

        if (goToDate.getTime() < minDate.getTime()) {
            goToDate.setTime(minDate.getTime());
        }

        const isDayPeriod = Periods.GetBasePeriod(tfi.Periods) === Periods.DAY;
        void this.set('isGoToOnlyDate', isDayPeriod);
        if (this.get<boolean>('isGoToOnlyDate')) {
            goToDate?.setHours(0, 0, 0, 0);
        }
    }

    public override localize (): void {
        super.localize();

        const addOverlayBtn = this.Controls.overlayButton;
        if (!isNullOrUndefined(addOverlayBtn)) {
            const addOverlayText = Resources.getResource('chart.ChartSymbolSelector.OverlayTooltip');
            addOverlayBtn.set('contextMenuItemText', addOverlayText);
            addOverlayBtn.set('tooltip', addOverlayText);
        }

        const timeFrameCB = this.Controls.timeFrameComboBox;
        if (!isNullOrUndefined(timeFrameCB)) {
            timeFrameCB.set('contextMenuItemText', Resources.getResource('chart.agregationType.Button'));
            timeFrameCB.set('tooltip', Resources.getResource('chart.agregationType.Button.ToolTip'));
        }

        const drawingStyleCB = this.Controls.drawingStyleComboBox;
        if (!isNullOrUndefined(drawingStyleCB)) {
            const styleText = Resources.getResource('chart.StyleButton.ToolTip');
            drawingStyleCB.set('contextMenuItemText', styleText);
            drawingStyleCB.set('tooltip', styleText);
        }

        // const loadRangeCB = this.Controls.loadRangeComboBox;
        // if (!isNullOrUndefined(loadRangeCB)) {
        //     loadRangeCB.set('contextMenuItemText', Resources.getResource('chart.timeframeselector.rangeButton'));
        //     loadRangeCB.set('tooltip', Resources.getResource('chart.timeframeselector.rangeButton.ToolTip'));
        // }

        const toolsButton = this.Controls.toolsButton;
        if (!isNullOrUndefined(toolsButton)) {
            toolsButton.set('contextMenuItemText', Resources.getResource('chart.ChartSymbolSelector.drawingButton'));
            toolsButton.set('tooltip', Resources.getResource('chart.ChartSymbolSelector.drawingButton.ToolTip'));
        }

        const indicatorButton = this.Controls.indicatorButton;
        if (!isNullOrUndefined(indicatorButton)) {
            const indicatorText = Resources.getResource('chart.ChartSymbolSelector.addIndicator.ButtonToolTip');
            indicatorButton.set('contextMenuItemText', indicatorText);
            indicatorButton.set('tooltip', indicatorText);
        }

        const objectManagerButton = this.Controls.objectManagerButton;
        if (!isNullOrUndefined(objectManagerButton)) {
            const objectManagerText = Resources.getResource('chart.ChartSymbolSelector.ObjectManagerTooltip');
            objectManagerButton.set('contextMenuItemText', objectManagerText);
            objectManagerButton.set('tooltip', objectManagerText);
        }

        const templatesEditable = this.Controls.editableList;
        if (!isNullOrUndefined(templatesEditable)) {
            const templatesText = Resources.getResource('chart.templates.tooltip');
            templatesEditable.set('contextMenuItemText', templatesText);
            templatesEditable.set('tooltip', templatesText);
        }

        const mouseTradeButton = this.Controls.mouseTradeButton;
        if (!isNullOrUndefined(mouseTradeButton)) {
            const mouseTradingButtonText = Resources.getResource('chart.ChartSymbolSelector.mouseTradeButton.ToolTip');
            mouseTradeButton.set('contextMenuItemText', mouseTradingButtonText);
            mouseTradeButton.set('tooltip', mouseTradingButtonText);
        }

        const oeButton = this.Controls.oeButton;
        if (!isNullOrUndefined(oeButton)) {
            const oeButtonText = Resources.getResource('chart.ChartSymbolSelector.oeButton.ToolTip');
            oeButton.set('contextMenuItemText', oeButtonText);
            oeButton.set('tooltip', oeButtonText);
        }

        this.updateTimeFrameComboBox();
        // this.updateLoadRangeComboBox();
        this.updateDrawingStyleComboBox();

        if (!isNullOrUndefined(this.terceraChartRactive)) {
            this.terceraChartRactive.localize();
        }

        if (!isNullOrUndefined(this.chartOE)) {
            this.chartOE.localize();
        }

        const toolsPanel = this.Controls.toolsPanel;
        if (!isNullOrUndefined(toolsPanel)) {
            toolsPanel.localize();
        }

        const editableCB = this.Controls.editableList;
        if (!isNullOrUndefined(editableCB)) {
            editableCB.localize();
        }

        this.localizeGoTo();
    }

    public static GetContextMenuItems (): IChartContextMenuItem[] {
        let menuItems: IChartContextMenuItem[] = ChartPanel.ContextMenuItemsInstance;
        if (isValidArray(menuItems)) return menuItems;

        menuItems = [];

        // ObjectManager
        menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.ObjectManager) });
        menuItems.push({ separator: true });
        // 2. Style
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.Style),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Renko) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.LinesBreak) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Line) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Bar) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Candle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Dot) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.DotLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Forest) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Solid) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Kagi) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Profile) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.TicTac) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Style, TerceraChartDrawingType.Cluster) }
            ]
        });

        // Info Window
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.InfoWindowMode),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.InfoWindowMode, InfoWindowDisplayMode.SeparateWindow) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.InfoWindowMode, InfoWindowDisplayMode.AttachedToCursor) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.InfoWindowMode, InfoWindowDisplayMode.Hidden) }
            ]
        });

        /*
    var editBars = new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.EditBar)));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.RemoveBar)));
    editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.ResetAllChanges)));
    //editBars.SubItems.Add(new ChartContextMenuItem(TerceraChartAction.Create(TerceraChartActionEnum.EditBars, TerceraChartEditBarsEnum.FinishEditing)));
    menuItems.Add(editBars);
    */

        /*
    // 3. Interval
    menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Agregation) });
    */

        // 4. DataType
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.Default)) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByBid)) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByBidAsk)) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByTrades)) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DataType, new TerceraChartHistoryType(ChartHistoryType.ByASk)) }
            ]
        });

        menuItems.push({ separator: true });

        // Overlays
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay, ITerceraChartShowScreenType.OverlayScreen) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Overlay, true) }
            ]
        });

        // Indicators
        menuItems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator, ITerceraChartShowScreenType.ScriptLookup) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Indicator, true) }
            ]
        });

        // Tools.
        const toolsMI: any = {
            tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.ToolSelector) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.Eraser) },

                { separator: true },

                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.TrendTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.HorizontalLine) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.VerticalLine) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Line) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.PriceChannel) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.RegressionChannel) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.AndrewsPitchFork) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GeometryTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Triangle) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Rectangle) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Circle) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Ellipse) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Polygon) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciRetracement) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciExpansion) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciArc) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciEllipse) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciSpiral) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciFans) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciPhiChannel) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTimeGoal) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciTimeZone) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannLine) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannFan) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannGrid) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottImpulse) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottCorrection) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTriangle) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottTripleCombo) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottDoubleCombo) }
                    ]
                },
                {
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.SecondaryTools),
                    subitems: [
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.InfoLine) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ArrowLabel) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.CommentsLabel) },
                        { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.PriceLabel) }
                    ]
                }
            // { tag: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Symbol) }
            ]
        };

        // Delete tools.
        toolsMI.subitems.push({
            tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.HorizontalLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.VerticalLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Line) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.PriceChannel) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.RegressionChannel) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.AndrewsPitchFork) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Triangle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Rectangle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Circle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Ellipse) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.Polygon) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciRetracement) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciExpansion) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciArc) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciEllipse) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciSpiral) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciFans) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciPhiChannel) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciTimeGoal) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.FibonacciTimeZone) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannFan) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.GannGrid) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottImpulse) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottCorrection) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottTriangle) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottDoubleCombo) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ElliottTripleCombo) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.InfoLine) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.ArrowLabel) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.CommentsLabel) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteToolsByType, DataCacheToolType.PriceLabel) },

                { separator: true },

                { tag: TerceraChartAction.Create(TerceraChartActionEnum.DeleteAllTools) }
            ]
        });

        menuItems.push(toolsMI);

        if (!Resources.isHidden('allowCreatePanelsFromPanel')) {
        // New
            const subitems = [];

            if (!Resources.isHidden('chart.new.oe')) { subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel, PanelNames.AdvancedOrderEntry) }); }
            if (!Resources.isHidden('chart.new.level2')) { subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel, PanelNames.MarketDepthPanel) }); }

            if (subitems.length > 0) {
                menuItems.push({
                    tag: TerceraChartAction.Create(TerceraChartActionEnum.NewPanel),
                    subitems
                });
            }
        }

        /// / View
        const viewMI = {
            tag: TerceraChartAction.Create(TerceraChartActionEnum.View, null, false),
            subitems: [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.PreviousCloseLine, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.TimeToNextBar, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.DayHighLow, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.Volume, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.GridTime, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.GridPrice, false) },
                { separator: true },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.AnalyseToolBar, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.DrawingToolBar, false) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.MainToolBar, false) }
            ]
        };

        viewMI.subitems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.OrderEntry, false) });
        menuItems.push(viewMI);

        menuItems.push({ separator: true });

        menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Refresh) });
        // Settings
        menuItems.push({ tag: TerceraChartAction.Create(TerceraChartActionEnum.Settings) });

        ChartPanel.ContextMenuItemsInstance = menuItems;
        return menuItems;
    }

    // #region ICaller

    public override Properties (skipBaseProperties: boolean = false): DynProperty[] {
        let properties = [];
        if (!skipBaseProperties) {
            properties = super.Properties();
        }

        const propsTCh = !isNullOrUndefined(this.terceraChartRactive) ? this.terceraChartRactive.Properties() : [];
        properties = properties.concat(propsTCh);

        let prop = null;

        // Additional
        let SeparatorGroup = '#3#' + Resources.getResource('property.SeparatorGroup.Additional');

        const terceraChart = !isNullOrUndefined(this.terceraChartRactive) ? this.terceraChartRactive.terceraChart : null;

        const mainToolBarAction = TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.MainToolBar);
        if (terceraChart?.TerceraChartActionProcessor.GetTerceraChartActionState(mainToolBarAction).Visible) {
            prop = new DynProperty('panel.caption.showHideToolbar', this.get('showMainToolBarPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 3;
            properties.push(prop);
        }

        prop = new DynProperty('ChartDrawingToolsToolBar', this.get('showToolsPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 0;
        properties.push(prop);

        const analyzeToolBarAction = TerceraChartAction.Create(TerceraChartActionEnum.View, TerceraChartToolbarsEnum.AnalyseToolBar);
        if (terceraChart?.TerceraChartActionProcessor.GetTerceraChartActionState(analyzeToolBarAction).Visible) {
            prop = new DynProperty('ChartAnalyseToolsToolBar', this.get('showAnalyzeToolsPanel'), DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
            prop.separatorGroup = SeparatorGroup;
            prop.sortIndex = 1;
            properties.push(prop);
        }

        const dataSourceOpen = this.get('dataSourceOpen');
        properties.push(new DynProperty('dataSourceOpen', dataSourceOpen, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        // OE
        const oePanel = this.chartOE;
        if (!isNullOrUndefined(oePanel)) {
            const isShowOE = this.get('isShowOE');

            SeparatorGroup = '#0#' + Resources.getResource('property.VisibilitySeparatorGroup');

            prop = new DynProperty('property.chart.showingOE', isShowOE, DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
            prop.sortIndex = 0;
            prop.separatorGroup = SeparatorGroup;
            prop.assignedProperty = [
                'OE_ShowBuyMarketSellMarket',
                'OE_ShowSellBidBuyASk',
                'OE_AddBidAddAsk'
            // "OE_Small",
            // "OE_ShowSLTP"
            ];
            properties.push(prop);

            SeparatorGroup = '#2#' + Resources.getResource('property.SeparatorViewButtons');

            prop = new DynProperty('OE_ShowBuyMarketSellMarket', oePanel.get('_userPreferredShowBuySellMarket'), DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
            prop.sortIndex = 1;
            prop.separatorGroup = SeparatorGroup;
            prop.enabled = isShowOE;
            properties.push(prop);

            const btnGr = Resources.isHidden('panel.newOrderEntry.LimitOrderButtons.Visibility') ? DynProperty.HIDDEN_GROUP : DynProperty.CHART_OE_GROUP;

            prop = new DynProperty('OE_ShowSellBidBuyASk', oePanel.get('_userPreferredShowSellBidBuyAsk'), DynProperty.BOOLEAN, btnGr);
            prop.sortIndex = 2;
            prop.separatorGroup = SeparatorGroup;
            prop.enabled = isShowOE;
            properties.push(prop);

            prop = new DynProperty('OE_AddBidAddAsk', oePanel.get('_userPreferredShowAddBidAsk'), DynProperty.BOOLEAN, btnGr);
            prop.sortIndex = 3;
            prop.separatorGroup = SeparatorGroup;
            prop.enabled = isShowOE;
            properties.push(prop);

            /* SeparatorGroup = "#1#" + Resources.getResource("property.SeparatorGroup.SeparatorOEWidth");

        prop = new DynProperty("OE_Small", this.get('oeSmall'), DynProperty.BOOLEAN, DynProperty.CHART_OE_GROUP);
        prop.tooltip = Resources.getResource("property.OE_Small.ToolTip");
        prop.sortIndex = 4;
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = isShowOE;
        properties.push(prop); */

        // #45682 - здесь для сохранения нужно использовать пременную oE_ShowSLTP, а не свойство OE_ShowSLTP
        // var vis = DataCache.isAllowCache.GlobalWebRules[IsAllowed.RequestsTypes.isAllowedForMyUser_FUNCTION_SLTP];
        // prop = new DynProperty("OE_ShowSLTP", oePanel.OE_ShowSLTP, DynProperty.BOOLEAN, vis ? DynProperty.CHART_OE_GROUP : DynProperty.HIDDEN_GROUP);
        // prop.sortIndex = 5;
        // prop.separatorGroup = SeparatorGroup;
        // prop.enabled = this.ShowingOE;
        // prop.visible = DataCache.isAllowCache.GlobalWebRules[IsAllowed.RequestsTypes.isAllowedForMyUser_FUNCTION_SLTP];
        // properties.push(prop);
        }

        // Hidden
        if (!skipBaseProperties) {
            const ins = this.get('instrumentItem');
            if (!isNullOrUndefined(ins)) {
                properties.push(new DynProperty('symbol', ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
            }

            const acc = this.get('accountItem');
            if (!isNullOrUndefined(acc)) {
                properties.push(new DynProperty('account', acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
            }
        }

        if (!isNullOrUndefined(this.Controls.editableList)) {
            const tmpItem = this.Controls.editableList.get('selectedItem');
            const tmp = !isNullOrUndefined(tmpItem) ? tmpItem.text : null;
            if (!isNullOrUndefined(tmp)) {
                properties.push(new DynProperty('selectedTemplate', tmp, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
            }
        }

        // const goToDate = this.get('goToDate');
        // if (!isNullOrUndefined(goToDate)) {
        //     properties.push(new DynProperty('goToDate', goToDate, DynProperty.DATE, DynProperty.HIDDEN_GROUP));
        // }

        return properties;
    }

    public override async callBack (properties: DynProperty[], skipBaseProperties = false): Promise<void> {
        super.callBack(properties);
        let dp: DynProperty | null = null;
        if (!skipBaseProperties) {
            // Hidden
            void this.set('instrumentItem', this.getCallBackInstrument(properties, 'symbol'));

            dp = DynProperty.getPropertyByName(properties, 'account');
            if (!isNullOrUndefined(dp?.value) && !LinkedSystem.accLinkingActive) { // #119833
                void this.set('accountItem', SessionSettings.getDefValueFromObj(DataCache.Accounts[dp.value], DataCache.Accounts));
            }
        }

        if (isNullOrUndefined(this.chartOE) || isNullOrUndefined(this.terceraChartRactive)) {
            await this.set({ chartCallbackProps: properties });
            return;
        }

        dp = DynProperty.getPropertyByName(properties, 'panel.caption.showHideToolbar');
        if (!isNullOrUndefined(dp)) {
            await this.set('showMainToolBarPanel', dp.value);
        }

        dp = DynProperty.getPropertyByName(properties, 'ChartDrawingToolsToolBar');
        if (!isNullOrUndefined(dp)) {
            await this.set('showToolsPanel', dp.value);
        }

        dp = DynProperty.getPropertyByName(properties, 'ChartAnalyseToolsToolBar');
        if (!isNullOrUndefined(dp)) {
            await this.set('showAnalyzeToolsPanel', dp.value);
        }

        const oePanel = this.chartOE;
        if (!isNullOrUndefined(oePanel)) {
        // У когото может быть открыто ОЕ до его скрытия, нужно его принудительно закрыть
            const hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');

            if (hideOE) {
                await this.set('isShowOE', false);
            } else {
                dp = DynProperty.getPropertyByName(properties, 'property.chart.showingOE');
                if (!isNullOrUndefined(dp)) {
                    await this.set('isShowOE', dp.value);
                }
            }

            // dp = DynProperty.getPropertyByName(properties, "OE_Small");
            // if (dp) this.set('oeSmall', dp.value);

            // dp = DynProperty.getPropertyByName(properties, "OE_ShowSLTP");
            // if (dp) oePanel.OE_ShowSLTP = dp.value;

            dp = DynProperty.getPropertyByName(properties, 'OE_ShowBuyMarketSellMarket');
            if (!isNullOrUndefined(dp)) {
                oePanel.set('_userPreferredShowBuySellMarket', dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'OE_ShowSellBidBuyASk');
            if (!isNullOrUndefined(dp)) {
                oePanel.set('_userPreferredShowSellBidBuyAsk', dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'OE_AddBidAddAsk');
            if (!isNullOrUndefined(dp)) {
                oePanel.set('_userPreferredShowAddBidAsk', dp.value);
            }
        }

        if (!isNullOrUndefined(this.terceraChartRactive)) {
            this.terceraChartRactive.callBack(properties);
        }

        if (!skipBaseProperties) {
            dp = DynProperty.getPropertyByName(properties, 'selectedTemplate');
            const cb: TerceraEditableListComboBox = this.Controls.editableList;
            if (isValidString(dp?.value) && !isNullOrUndefined(cb)) {
                await cb.restoreCorrectSelectItem(dp.value);
                this.initialTemplateSelected = true;
            }
        }

        dp = DynProperty.getPropertyByName(properties, 'dataSourceOpen');
        if (!isNullOrUndefined(dp)) {
            void this.set('dataSourceOpen', dp.value);
        }

        // dp = DynProperty.getPropertyByName(properties, 'goToDate');
        // if (!isNullOrUndefined(dp)) {
        //     const goToDate = new Date(dp.value);
        //     void this.set({ goToDate });
        // }
    }

    // #endregion

    public override themeChange (): void {
        const Controls = this.Controls;

        if (!isNullOrUndefined(this.terceraChartRactive)) {
            this.terceraChartRactive.themeChange();
        }

        const toolsPanel = Controls.toolsPanel;
        if (!isNullOrUndefined(toolsPanel)) {
            toolsPanel.themeChange();
        }
    }

    public onTimeFrameComboBox_ComboItemClicked (context, newVal): void {
        const model = this.terceraChartRactive.terceraChart.model;
        const oldTFInfo = model.GetTimeFrameInfo();
        const controller = this.terceraChartRactive.terceraChart.chartController;
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: newVal.tag }));
    }

    public onDrawingStyleComboBox_ComboItemClicked (context, newVal): void {
        this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Style), newVal.tag);
    }

    // public onLoadRangeComboBox_ComboItemClicked (context, newVal): void {
    //     this.terceraChartRactive.terceraChart.chartController.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Range), newVal.tag);
    // }

    public updateTimeFrameComboBox (): void {
        if (isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            return;
        }

        const cBox = this.Controls.timeFrameComboBox;

        const newItems = [
            { text: Periods.ToLocalizedShortPeriod(Periods.TIC), value: Periods.TIC },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN), value: Periods.MIN },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN5), value: Periods.MIN5 },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN15), value: Periods.MIN15 },
            { text: Periods.ToLocalizedShortPeriod(Periods.MIN30), value: Periods.MIN30 },
            { text: Periods.ToLocalizedShortPeriod(Periods.HOUR), value: Periods.HOUR },
            { text: Periods.ToLocalizedShortPeriod(Periods.HOUR4), value: Periods.HOUR4 },
            { text: Periods.ToLocalizedShortPeriod(Periods.DAY), value: Periods.DAY },
            { text: Periods.ToLocalizedShortPeriod(Periods.WEEK), value: Periods.WEEK },
            { text: Periods.ToLocalizedShortPeriod(Periods.MONTH), value: Periods.MONTH },
            { text: Periods.ToLocalizedShortPeriod(Periods.YEAR), value: Periods.YEAR }
        ];

        if (Resources.isHidden('chart.AllowedPeriods.1T'))
        // || DataCache.isAllowed(IsAllowed.RequestsTypes.isAllowed_PERIOD_TICK) )
        {
            newItems.splice(0, 1);
        }

        if (!isNullOrUndefined(cBox)) {
            cBox.set('items', newItems);
        }
        const tf = this.terceraChartRactive.terceraChart.model.GetTimeFrameInfo();
        if (!isNullOrUndefined(tf) && !isNullOrUndefined(cBox)) {
            cBox.setItembyValue(tf.Periods);
        }
    }

    public updateDrawingStyleComboBox (): void {
        if (isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            return;
        }

        const chart = this.terceraChartRactive.terceraChart;
        const allowedStyles = chart.model.CurrentAllowedStyles;
        const len = allowedStyles.length;
        const items = [];
        for (let i = 0; i < len; i++) {
            const style = allowedStyles[i];
            items.push({ style: 'js-drawing-style' + style, text: TerceraChartUtils.ToLocalizedTerceraChartDrawingType(style), value: style });
        }
        const cBox = this.Controls.drawingStyleComboBox;
        if (!isNullOrUndefined(cBox)) {
            cBox.set({ listWidth: 120, usePNGView: true, showArrow: false, items, enabled: len > 1 });
            cBox.setItembyValue(chart.model.GetChartDrawingType());
        }
    }

    // public updateLoadRangeComboBox (): void {
    //     if (isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
    //         return;
    //     }

    //     const model = this.terceraChartRactive.terceraChart.model;

    //     const periods = model.CurrentAllowedPeriods;
    //     const len = periods.length;
    //     const cBoxItems = [];

    //     let selItem = null;
    //     const curPeriodDesc = model.GetPeriodDesc();

    //     for (let i = 0; i < len; i++) {
    //         const p = periods[i];
    //         cBoxItems.push({ text: p.toString(), value: p });

    //         if (curPeriodDesc.equals(p)) {
    //             selItem = p;
    //         }
    //     }
    //     const cBox = this.Controls.loadRangeComboBox;
    //     if (!isNullOrUndefined(cBox)) {
    //         cBox.set('items', cBoxItems);
    //         cBox.setItembyValue(selItem);
    //     }
    // }

    public initChart (): void {
        this.terceraChartRactive = this.Controls.chart;
        if (isNullOrUndefined(this.terceraChartRactive)) {
            return;
        }

        this.terceraChartRactive.terceraChart.OnLoadingFinish.Subscribe(this.onLoadingFinish, this);

        this.terceraChartRactive.terceraChart.ContextMenuItems = ChartPanel.GetContextMenuItems();

        const model = this.terceraChartRactive.terceraChart.model;
        model.ModelItemChanged.Subscribe(this.MVCModelItemChanged, this);

        const controller = this.terceraChartRactive.terceraChart.chartController;

        controller.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Instrument),
            this.get('instrumentItem'));

        controller.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Account),
            this.get('accountItem'));
    }

    public MVCModelItemChanged (item: TerceraChartMVCModelItem): void {
        switch (item.ItemType) {
        case ModelDataType.Style:
            this.updateDrawingStyleComboBox();
            break;
        case ModelDataType.Instrument:
            this.updatePanelHeader();
            break;
        case ModelDataType.TFI:
            this.updateTimeFrameComboBox();
            this.updatePanelHeader();
            this.updateGoToState();
            break;
        case ModelDataType.Range:
            // this.updateLoadRangeComboBox();
            break;
        }
    }

    public GetTerceraChart (): TerceraChart {
        if (!isNullOrUndefined(this._terceraChart)) {
            return this._terceraChart;
        }

        if (!isNullOrUndefined(this.terceraChartRactive?.terceraChart)) {
            this._terceraChart = this.terceraChartRactive.terceraChart;
        }

        return this._terceraChart;
    }

    public override TickAsync (): void {
        const chart = this.GetTerceraChart();
        if (chart?.needRedraw) {
            chart.needRedraw = false;
            chart.Draw();
            chart.AddForceUpdate();
        }
    }

    public overlay_btnClick (): void {
        TerceraAddOverlayScreen.addNewOverlay(this.terceraChartRactive.terceraChart);
    }

    public onIndicatorBtnClick (): void {
        const params = new LookupDropDownShowParams();
        params.callBack = this.indicatorLookupCB.bind(this);
        TerceraIndicatorLookupDropDownForm.ShowForm(params);
    }

    public onObjectManagerBtnClick (): void {
        const chart = this.terceraChartRactive.terceraChart;
        ObjectManagerScreen.show(chart);
    }

    public indicatorLookupCB (selectedItem): void {
        if (typeof selectedItem === 'object' && isValidString(selectedItem.FullName)) {
            this.terceraChartRactive.terceraChart.AddIndicator(IndicatorManager.GetIndicator(selectedItem.FullName));
        }
    }
    // #region ITerceraChartPanelContext

    public ShowScreen (type, parameters): any {
        switch (type) {
        case ITerceraChartShowScreenType.ChartSettings:{
            if (this.AllowSettings) {
                const headerString = Resources.getResource('panel.terceraChart') +
                    Resources.getResource('PanelSettingsScreen.TextSmall');

                if (parameters?.PanelSettingsDefaultPage) {
                    PanelSettingsScreen.EditProperties(this, parameters.PanelSettingsDefaultPage, headerString);
                } else {
                    PanelSettingsScreen.EditProperties(this, DynProperty.DATA_STYLE_GROUP, headerString);
                }
            }
            break;
        }
        case ITerceraChartShowScreenType.Properties:{
            const caller = parameters.caller;
            const toolStateInfoDict = TerceraChartActionProcessor.ToolStateInfoDict;
            const toolType = caller.dataCacheTool.ToolType;
            const headerString =
                Resources.getResource(toolStateInfoDict[toolType].locKey) + ' ' +
                Resources.getResource('screen.properties.common');
            PanelSettingsScreen.EditProperties(caller, null, headerString, this);
            return null;
        }

        case ITerceraChartShowScreenType.UIManipulation:{
            const isSet: boolean = parameters.isSet;
            const toolBarType = parameters.toolBarType;
            switch (toolBarType) {
            case TerceraChartToolbarsEnum.MainToolBar:
                if (isSet) {
                    void this.toggle('showMainToolBarPanel');
                }
                return this.get('showMainToolBarPanel');
            case TerceraChartToolbarsEnum.DrawingToolBar:
                if (isSet) {
                    void this.toggle('showToolsPanel');
                }
                return this.get('showToolsPanel');
            case TerceraChartToolbarsEnum.AnalyseToolBar:
                if (isSet) {
                    void this.toggle('showAnalyzeToolsPanel');
                }
                return this.get('showAnalyzeToolsPanel');
            case TerceraChartToolbarsEnum.OrderEntry:
                if (isSet) {
                    void this.toggle('isShowOE');
                }
                return this.get('isShowOE');
            case TerceraChartToolbarsEnum.AllPricePanel:
                return {};
            /* TODO.
                if (isSet)
                {
                    allpricePanelSettings.Visible = !allpricePanelSettings.Visible;
                    UpdateChildPanelsState();
                }
                return new object[] { allpricePanelSettings.Visible, allpricePanelSettings.Visible && allPricePanel != null ? allPricePanel.quickTable.RealRowsCount > 0 : false };
                */
            case TerceraChartToolbarsEnum.TimeAndSalesPanel:
                return {};
                /* TODO.
                if (isSet)
                {
                    tsPanelSettings.Visible = !tsPanelSettings.Visible;
                    UpdateChildPanelsState();
                }
                return new object[] { tsPanelSettings.Visible, tsPanelSettings.Visible && tsPanel != null ? tsPanel.quickTable.RealRowsCount > 0 : false };
                */
            }
            break;
        }

        case ITerceraChartShowScreenType.OverlayScreen:
            TerceraAddOverlayScreen.addNewOverlay(this.terceraChartRactive.terceraChart);
            return null;
        case ITerceraChartShowScreenType.ScriptLookup:
            this.onIndicatorBtnClick();
            return null;
        case ITerceraChartShowScreenType.NewPanel:{
            const accItem = this.get('accountItem');
            const insItem = this.get('instrumentItem');
            MainWindowManager.Factory.addPanel(
                parameters.panelName,
                null,
                function (panel) {
                    if (!isNullOrUndefined(accItem)) {
                        panel.accountLink_In(accItem.AcctNumber);
                    }
                    if (!isNullOrUndefined(insItem)) {
                        panel.symbolLink_In(insItem.GetInteriorID());
                    }
                });
            return null;
        }

        case ITerceraChartShowScreenType.ChartTrading:
            if (parameters?.hasOwnProperty('tradingFlag')) {
                this.MouseTradingEnabledSet(parameters.tradingFlag);
            } else {
                this.MouseTradingEnabledSet(!this.MouseTradingEnabledGet());
            }
            break;
        }
        return null;
    }

    // #endregion

    public MouseTradingEnabledGet (): boolean {
        const chart = this.terceraChartRactive.terceraChart;
        return chart.TerceraChartTradingToolsRenderer.MouseTradeEnabled &&
        !TradingLockUtils.TradingLock.tradingLocked;
    }

    public MouseTradingEnabledSet (value): void {
        const chart = this.terceraChartRactive.terceraChart;
        chart.TerceraChartTradingToolsRenderer.MouseTradeEnabled = value;
        this.activateCtrlMouseTrading(value);
        this.UpdateMouseTradingButton();
    }

    public CtrlMouseTradingEnabledGet (): boolean {
        const chart = this.terceraChartRactive.terceraChart;
        return chart.TerceraChartTradingToolsRenderer.CtrlMouseTradeEnabled &&
        !TradingLockUtils.TradingLock.tradingLocked;
    }

    public CtrlMouseTradingEnabledSet (value): void {
        const chart = this.terceraChartRactive.terceraChart;
        chart.TerceraChartTradingToolsRenderer.CtrlMouseTradeEnabled = value;
        this.UpdateMouseTradingButton();
    }

    public UpdateMouseTradingButton (): void {
        const mouseTradeButton = this.Controls.mouseTradeButton;
        if (isNullOrUndefined(mouseTradeButton)) return;

        const checked = this.MouseTradingEnabledGet() || this.CtrlMouseTradingEnabledGet();

        mouseTradeButton.set('checked', checked);
        this.terceraChartRactive.terceraChart.ForceUpdateCursor();

        if (isNullOrUndefined(this.myVisualWidget)) {
            return;
        }

        void this.myVisualWidget.show(checked).then(() => {
            if (!checked) { return; }

            const mL = this.myVisualWidget.get('left');
            const mVWidthC = this.myVisualWidget.get('width');
            const mVWidth = this.terceraChartRactive.get('width');
            // TODO сделать по нормальному наверное
            if (!this.myVisualWidget.Placed || mL < 0 || mL + mVWidthC > mVWidth + 10/* небольшое окно на полгрешность справа */) {
                void this.myVisualWidget.set({ left: mVWidth / 2 - mVWidthC / 2, top: 31 });
                this.myVisualWidget.Placed = true;
            }
        });
    }

    public mouseTradeButton_btnClick (): void {
        this.terceraChartRactive.terceraChart.TerceraChartActionProcessor.ProcessTerceraChartAction(
            TerceraChartAction.Create(TerceraChartActionEnum.ChartTrading));
    }

    private get tChart (): TerceraChart {
        return this.terceraChartRactive?.terceraChart;
    }

    public onGoToLoadingStart (goToDate: Date): void {
        if (this.get<boolean>('isGoToOnlyDate')) {
            goToDate?.setHours(0, 0, 0, 0);
        }

        void this.set({ goToDate });

        if (isNullOrUndefined(goToDate)) return;

        if (this.tChart?.isTimeInHistoryRange(goToDate)) {
            this.tChart?.goToPositionBytime(goToDate);
        } else {
            void this.tChart?.autoLoadManager.loadHistory(goToDate);
        }
    }

    public ChartVisualTrading (order: Order, newData): any {
        return this.ChartTradingCore.ChartVisualTrading(order, newData, this.get('instrumentItem'));
    }

    // TODO. Very Ugly.
    public changeQuantity (order: Order, newData): void {
        const quant = newData.quantity;

        const modifyOrdObj = this.createModifyOrderObject(order);

        modifyOrdObj.setTradingData({ quantity: quant });

        DataCache.FOrderExecutor.modifyOrderPromiseWithCallbacks(modifyOrdObj)
            .then(function (confirmed) {
                if (!isNullOrUndefined(newData.CancelCallback) && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('ChangeQuantity error', 'ChartPanel.changeQuantity', 'changeQuantity -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);

                if (!isNullOrUndefined(newData.CancelCallback)) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
            // TODO. Refactor.
                modifyOrdObj.dispose();
            });
    }

    // TODO. Ugly.
    public createModifyOrderObject (order: Order): OrderEditBase {
        const orderTypeObj = DataCache.OrderParameterContainer.GetOrderType(order.OrderType);
        return orderTypeObj.createModifyOrderObject({
            dataCache: DataCache,
            order
        });
    }

    public override updateSettings (): void {
        super.updateSettings();
        this.chartOE?.updateTradingAllowedStuff();
    }

    public onGlobalKeyDown (btn, modificators): void {
        if (!this.isPanelOrChartFocused()) {
            return;
        }

        if (this.myTradeNumeric?.get<boolean>('visible') && btn === KeyCode.ENTER) {
            this.onEnterPressed();
        }

        // Removed due to id:123429
        // this.activateCtrlMouseTrading(
        //     btn === KeyCode.CTRL &&
        //     !modificators.ALT &&
        //     !modificators.SHIFT);
    }

    public onGlobalKeyUp (btn, modificators): void {
        if (!this.isPanelOrChartFocused()) {
            const chart = this.terceraChartRactive.terceraChart;
            const ttRenderer = chart.TerceraChartTradingToolsRenderer;
            if (!ttRenderer.CtrlMouseTradeEnabled) {
                return;
            }
        }

        this.activateCtrlMouseTrading(false);
    }

    public isPanelOrChartFocused (): boolean {
        return this.get('focused') ||
        this.terceraChartRactive.get('focused');
    }

    // TODO.
    public override lostFocus (): void {
        super.lostFocus();
        this.activateCtrlMouseTrading(false);
    }

    public activateCtrlMouseTrading (activate): void {
        if (isNullOrUndefined(this.terceraChartRactive)) {
            return;
        }

        // http://tp.pfsoft.net/entity/56578
        if (this.MouseTradingEnabledGet()) {
            return;
        }

        const hideOE = Resources.isHidden('chart.ChartSymbolSelector.oeButton');
        if (hideOE) {
            return;
        }

        const chart = this.terceraChartRactive.terceraChart;
        const ttRenderer = chart.TerceraChartTradingToolsRenderer;

        // http://tp.pfsoft.net/entity/56570
        if (ttRenderer.CtrlMouseTradeEnabled === activate) {
            return;
        }

        ttRenderer.CtrlMouseTradeEnabled = activate;
        this.UpdateMouseTradingButton();
        chart.IsDirty(LayersEnum.Tools);
    }

    public override dispose (): void {
        KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
        KeyEventProcessor.OnKeyUp.UnSubscribe(this.onGlobalKeyUp, this);
        DataCache.OnHistoryChangedAndNeedReload.UnSubscribe(this.checkNeedReload, this);

        if (!isNullOrUndefined(this.Controls.editableList)) {
            this.Controls.editableList.OnItemSave.UnSubscribe(this.onSaveTemplate, this);
            this.Controls.editableList.OnSelectList.UnSubscribe(this.onSelectTemplate, this);
        }
        // this.terceraChartRactive.terceraChart.autoLoadingStateChanged.UnSubscribe(this.autoLoadingStateChanged, this);
        // SessionSettings.timeZoneChanged.UnSubscribe(this.terceraChartRactive.terceraChart.RefreshChart, this.terceraChartRactive.terceraChart);
        this.unsubscribe(this.get('instrumentItem'));
        this.terceraChartRactive.terceraChart.Dispose();
        this.terceraChartRactive.terceraChart.OnLoadingFinish.UnSubscribe(this.onLoadingFinish, this);
        if (!isNullOrUndefined(this.myVisualWidget)) {
            this.myVisualWidget.dispose();
        }
        if (!isNullOrUndefined(this.myTradeNumeric)) {
            this.myTradeNumeric.dispose();
        }

        this.OnResize.UnSubscribe(this.layoutTable, this);
        TradingLockUtils.TradingLock.TradingLockChanged.UnSubscribe(this.updateTradingLockState, this);

        super.dispose();
    }

    public override getXmlSettingsTemplate (): any {
        const chart = this.terceraChartRactive?.terceraChart;
        if (isNullOrUndefined(chart) || chart.LoadingNow) {
            const chartCallbackXml = this.get('chartCallbackXml');
            return chartCallbackXml ?? {};
        }

        return {
            indicatorsObj: chart.SaveIndicators(),
            overlaysObj: chart.SaveOverlays()
        };
    }

    public override setXmlSettingsTemplate (value): void {
        if (isNullOrUndefined(this.chartOE) || isNullOrUndefined(this.terceraChartRactive)) {
            void this.set({ chartCallbackXml: value });
            return;
        }
        this.terceraChartRactive.terceraChart.LoadIndicators(!isNullOrUndefined(value) ? value.indicatorsObj : {});
        void this.terceraChartRactive.terceraChart.LoadOverlaysAsync(!isNullOrUndefined(value) ? value.overlaysObj : {});
    }

    public override accountLink_In (accId, fromLinkedSystem: boolean = false): boolean {
        if (fromLinkedSystem && !this.OnAccountLinking()) {
            return;
        }

        if (!isNullOrUndefined(DataCache.Accounts[accId])) {
            void this.set('accountItem', DataCache.Accounts[accId]);
        }

        this.localizeAccountLinkTooltip();

        return true;
    }

    public override symbolLink_In (symbolName: string): void {
        const currentInstrument = this.get('instrumentItem');

        const newInstr = DataCache.getInstrumentByName(symbolName);
        if (!isNullOrUndefined(newInstr)) {
        // убираем не нужный апдейт
            if (!isNullOrUndefined(currentInstrument) && currentInstrument.GetInteriorID() === symbolName) {
                return;
            }
            void this.set('instrumentItem', newInstr);
        }
    }

    public override symbolLink_Out (newSubscriber, instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            const ins = this.get('instrumentItem');
            if (isNullOrUndefined(ins)) return;
            instrument = ins;
        }
        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
        }
    }

    public override workWithToolBar (showHide): void {
        void this.set({ showMainToolBarPanel: showHide });
    }

    public override getInstrument (): Instrument {
        return this.get('instrumentItem');
    }

    public onSaveTemplate (element): void {
    // console.log(this._guid + " onSaveTemplate")
        const editableCB = this.Controls.editableList;
        if (isNullOrUndefined(editableCB?.get('selectedItem'))) {
            return;
        }

        const allLists = editableCB.get('valueOfLists');
        const list = allLists[element.tag];

        const props = DynProperty.serialize(this.Properties(true));
        const xmlSet = this.getXmlSettingsTemplate();

        list.itemsStr = JSON.stringify({ properties: props, xmlSettings: xmlSet });

        editableCB.updateListsValue(null, false, null, true);
    }

    public async onSelectTemplate (propJSON): Promise<void> {
    // console.log(this._guid + " onSelectTemplate")
        if (isNullOrUndefined(propJSON)) {
            return;
        }

        const chartTemplate = JSON.parse(propJSON);
        const properties = DynProperty.deserialize(chartTemplate.properties);
        const xmlSet = chartTemplate.xmlSettings;

        this.terceraChartRactive.terceraChart.RemoveAllIndicators();

        this.terceraChartRactive.terceraChart.model.skipRefreshChartInCallback = true;
        await this.callBack(properties, true);
        this.setXmlSettingsTemplate(xmlSet);
        this.terceraChartRactive.terceraChart.model.skipRefreshChartInCallback = false;
        this.firstChartLoading();
    }

    public selectInitialTemplate (): void {
        const editableList = this.Controls.editableList;

        if (this.initialTemplateSelected) {
            return;
        }

        const items = editableList.menuItems; // #87095
        const selItem = editableList.get('selectedItem');

        if (isNullOrUndefined(selItem) || selItem.value === undefined) {
            return;
        }

        if (!isNullOrUndefined(items[selItem.value])) {
            items[selItem.value].checked = false;
        }

        editableList.set('selectedItem', { separator: true });
        // editableList.set('showLastValue', true )

        // применение стартового темплейта для здоровых людей
        // const templates = editableList.get('valueOfLists');
        // const defaultTemplate = templates.length ? templates[0].itemsStr : null;

        // if (defaultTemplate) { this.onSelectTemplate(defaultTemplate); }
    }

    // setcursor settings to div
    public applyCursor (cursor): void {
        if (!isNullOrUndefined(this.terceraChartRactive)) {
            void this.terceraChartRactive.setCursor(cursor);
        }
    }

    public override RefreshInstrumentDescriptions (insArray: any[]): void {
        super.RefreshInstrumentDescriptions();
        if (!insArray.includes(this.get('instrumentItem'))) { return; }

        const tCh = this.terceraChartRactive?.terceraChart;
        if (isNullOrUndefined(tCh)) {
            return;
        }
        tCh.RefreshChart();
    }

    public onHasCorrectDataChanged (hasCorrectData): void {
        void this.set('dataSourceBottom', hasCorrectData ? 42 : 5);
    }

    // TODO перенести в один обработчик
    public override onMouseMove (event): void {
        super.onMouseMove(event);
        this.lastMouseMoveEvent = event;
        // In order to increase smoothness of panel's movement.
        cancelAnimationFrame(this.mouseMoveAnimationFrameId);
        this.mouseMoveAnimationFrameId = requestAnimationFrame(this.mouseMoveHandler.bind(this));
    }

    public mouseMoveHandler (): void {
        if (!this.get) { return; }
        const activeControl: TerceraWindowBase = this.get('activeControl');
        if (isNullOrUndefined(activeControl)) return;

        const event = this.lastMouseMoveEvent;
        if (isNullOrUndefined(event)) return;

        if (activeControl.get('onProcessMove') === true) {
            const myParams = activeControl.movingParameters;
            const ev = event.original;
            const xShift = myParams.startMoveCoordX - ev.pageX;
            const yShift = myParams.startMoveCoordY - ev.pageY;

            let lastMouseMoveX = myParams.startMyX - xShift;
            let lastMouseMoveY = myParams.startMyY - yShift;

            const windowSideBordersW = WINDOW_SIDE_BORDERS_WIDTH;
            const panelSize = this.getCorrectChartPanelSize();
            // New window left.
            const possibleControlWidth = activeControl.get<number>('width');
            let activeControlW = isValidNumber(possibleControlWidth) ? possibleControlWidth : activeControl.getControl().offsetWidth;
            activeControlW += windowSideBordersW;
            const mainWindowW = panelSize.width;

            if (lastMouseMoveX + activeControlW > mainWindowW) {
                lastMouseMoveX = mainWindowW - activeControlW;
            }

            if (lastMouseMoveX < 0) {
                lastMouseMoveX = 0;
            }

            // New window top.
            const possibleControlHeight = activeControl.get<number>('height');
            let activeControlH = isValidNumber(possibleControlHeight) ? possibleControlHeight : activeControl.getControl().offsetHeight;
            activeControlH += windowSideBordersW;
            const topWindowsMargin = 31;
            const bottomWindowsMargin = panelSize.height;

            if (lastMouseMoveY + activeControlH > bottomWindowsMargin) {
                lastMouseMoveY = bottomWindowsMargin - activeControlH;
            }

            if (lastMouseMoveY < topWindowsMargin) {
                lastMouseMoveY = topWindowsMargin;
            }

            this.lastMouseMoveX = lastMouseMoveX;
            this.lastMouseMoveY = lastMouseMoveY;

            // Workaround to avoid "heavy" ractive code.
            // var rootPanelDiv = this.activePanelRootDiv;
            void activeControl.set({ left: lastMouseMoveX, top: lastMouseMoveY });
        // rootPanelDiv.style.left = lastMouseMoveX + 'px';
        // rootPanelDiv.style.top = lastMouseMoveY + 'px';
        }

        if (activeControl.onProcessResize) {
            activeControl.onResizing(event);
        }
    }

    private getCorrectChartPanelSize (): { width: number, height: number } {
        if (FullScreenManager.IsFullScreenMode) {
            const control = this.getControl();
            return { width: control.offsetWidth, height: control.offsetHeight };
        }

        let mainWindowW: number = this.get('width');
        if (!isValidNumber(mainWindowW) || mainWindowW === 0) {
            mainWindowW = this.el.offsetWidth;
        }
        let mainWindowH: number = this.get('height');
        if (!isValidNumber(mainWindowH) || mainWindowH === 0) {
            mainWindowH = this.el.offsetHeight;
        }

        return { width: mainWindowW, height: mainWindowH };
    }

    public override HotkeyPressed (hotkey: string): boolean {
        const isPressed = super.HotkeyPressed(hotkey);
        if (isPressed) {
            return true;
        }

        const chart = this.terceraChartRactive.terceraChart;
        if (isNullOrUndefined(chart)) {
            return false;
        }

        const account = this.get('accountItem');
        const instrument = this.get('instrumentItem');
        const hotkeyAction = HotkeysManager.GetActionByHotkeyForPanel(hotkey, this.getType());
        switch (hotkeyAction) {
        case ChartHotkeysEnum.DisplayInFullScreenMode:
            this.toogleFullscreenMode();
            return true;
        case ChartHotkeysEnum.ChangeChartStyle:
            this.forcedChangeDrawingStyleComboBox();
            return true;
        case ChartHotkeysEnum.ZoomIn:
            chart.ZoomIn();
            return true;
        case ChartHotkeysEnum.ZoomOut:
            chart.ZoomOut();
            return true;
        case ChartHotkeysEnum.AutoManualScaling:
            chart.AutoManualScaling();
            return true;
        case ChartHotkeysEnum.MouseTrading:
            chart.MouseTrading();
            return true;
        case ChartHotkeysEnum.ShowHideInfoWindow:
            chart.ShowHideInfoWindow();
            return true;
        case ChartHotkeysEnum.AddIndicator:
            this.onIndicatorBtnClick();
            return true;
        case ChartHotkeysEnum.RemoveDrawings:
            chart.RemoveDrawings();
            return true;
        case ChartHotkeysEnum.ShowFilledOrders:
            chart.ShowFilledOrders();
            return true;
        case ChartHotkeysEnum.SetPeriod_Tick:
            this.forcedChangeTimeFrameComboBox(Periods.TIC);
            return true;
        case ChartHotkeysEnum.SetPeriod_1m:
            this.forcedChangeTimeFrameComboBox(Periods.MIN);
            return true;
        case ChartHotkeysEnum.SetPeriod_5m:
            this.forcedChangeTimeFrameComboBox(Periods.MIN5);
            return true;
        case ChartHotkeysEnum.SetPeriod_15m:
            this.forcedChangeTimeFrameComboBox(Periods.MIN15);
            return true;
        case ChartHotkeysEnum.SetPeriod_30m:
            this.forcedChangeTimeFrameComboBox(Periods.MIN30);
            return true;
        case ChartHotkeysEnum.SetPeriod_1H:
            this.forcedChangeTimeFrameComboBox(Periods.HOUR);
            return true;
        case ChartHotkeysEnum.SetPeriod_4H:
            this.forcedChangeTimeFrameComboBox(Periods.HOUR4);
            return true;
        case ChartHotkeysEnum.SetPeriod_1D:
            this.forcedChangeTimeFrameComboBox(Periods.DAY);
            return true;
        case ChartHotkeysEnum.SetPeriod_1W:
            this.forcedChangeTimeFrameComboBox(Periods.WEEK);
            return true;
        case ChartHotkeysEnum.SetPeriod_1M:
            this.forcedChangeTimeFrameComboBox(Periods.MONTH);
            return true;
        case ChartHotkeysEnum.SetPeriod_1Y:
            this.forcedChangeTimeFrameComboBox(Periods.YEAR);
            return true;
        case ChartHotkeysEnum.ObjectManagerIndicators:
            chart.HotkeyObjectManagerProcess(PanelNames.IndicatorsManagerPanel);
            return true;
        case ChartHotkeysEnum.ObjectManagerDrawings:
            chart.HotkeyObjectManagerProcess(PanelNames.DrawingsManagerPanel);
            return true;
        case ChartHotkeysEnum.ObjectManagerOverlays:
            chart.HotkeyObjectManagerProcess(PanelNames.OverlaysManagerPanel);
            return true;
        case ChartHotkeysEnum.DrawLine:
            chart.HotkeyDrawingProcess(DataCacheToolType.Line);
            return true;
        case ChartHotkeysEnum.DrawVerticalLine:
            chart.HotkeyDrawingProcess(DataCacheToolType.VerticalLine);
            return true;
        case ChartHotkeysEnum.DrawHorizontalLine:
            chart.HotkeyDrawingProcess(DataCacheToolType.HorizontalLine);
            return true;
        case ChartHotkeysEnum.DrawArrow:
            chart.HotkeyDrawingProcess(DataCacheToolType.ArrowLabel);
            return true;
        case ChartHotkeysEnum.DrawComments:
            chart.HotkeyDrawingProcess(DataCacheToolType.CommentsLabel);
            return true;
        case ChartHotkeysEnum.DrawFibonacciRetracement:
            chart.HotkeyDrawingProcess(DataCacheToolType.FibonacciRetracement);
            return true;
        case ChartHotkeysEnum.DrawFibonacciExpansion:
            chart.HotkeyDrawingProcess(DataCacheToolType.FibonacciExpansion);
            return true;
        case ChartHotkeysEnum.DrawFibonacciArc:
            chart.HotkeyDrawingProcess(DataCacheToolType.FibonacciArc);
            return true;
        case ChartHotkeysEnum.DrawFibonacciTimeGoalAnalysis:
            chart.HotkeyDrawingProcess(DataCacheToolType.FibonacciTimeGoal);
            return true;
        case ChartHotkeysEnum.DrawFibonacciFans:
            chart.HotkeyDrawingProcess(DataCacheToolType.FibonacciFans);
            return true;
        case ChartHotkeysEnum.DrawFibonacciSpiral:
            chart.HotkeyDrawingProcess(DataCacheToolType.FibonacciSpiral);
            return true;
        case ChartHotkeysEnum.DrawFibonacciTimeZone:
            chart.HotkeyDrawingProcess(DataCacheToolType.FibonacciTimeZone);
            return true;
        case ChartHotkeysEnum.DrawFibonacciPhiChannel:
            chart.HotkeyDrawingProcess(DataCacheToolType.FibonacciPhiChannel);
            return true;
        case ChartHotkeysEnum.DrawTriangle:
            chart.HotkeyDrawingProcess(DataCacheToolType.Triangle);
            return true;
        case ChartHotkeysEnum.DrawPolygon:
            chart.HotkeyDrawingProcess(DataCacheToolType.Polygon);
            return true;
        case ChartHotkeysEnum.DrawRectangle:
            chart.HotkeyDrawingProcess(DataCacheToolType.Rectangle);
            return true;
        case ChartHotkeysEnum.ShowHideGrid:
            chart.ShowHideGrid();
            return true;
        case ChartHotkeysEnum.ShowOpenPositions:
            chart.ShowOpenPositions();
            return true;
        case ChartHotkeysEnum.ShowVolume:
            chart.ShowVolume();
            return true;
        case ChartHotkeysEnum.ShowWorkingOrders:
            chart.ShowWorkingOrders();
            return true;
        case ChartHotkeysEnum.CancelLastOrderOnSelectedInstrument:
            return this.ProcessHotkeyCancelOrder(account, instrument,
                OrderCancelActionHotkeysEnum.LastOrder, PlacedFrom.WEB_CHART_VISUAL);
        case ChartHotkeysEnum.CancelAllActiveOrdersOnSelectedInstrument:
            return this.ProcessHotkeyCancelOrder(account, instrument,
                OrderCancelActionHotkeysEnum.AllOrders, PlacedFrom.WEB_CHART_VISUAL);
        case ChartHotkeysEnum.CancelBuyOrderClosestToTheLastPrice:
            return this.ProcessHotkeyCancelOrder(account, instrument,
                OrderCancelActionHotkeysEnum.BuyOrder, PlacedFrom.WEB_CHART_VISUAL);
        case ChartHotkeysEnum.CancelSellOrderClosestToTheLastPrice:
            return this.ProcessHotkeyCancelOrder(account, instrument,
                OrderCancelActionHotkeysEnum.SellOrder, PlacedFrom.WEB_CHART_VISUAL);
        }

        return false;
    }

    protected override closeAddidionalScreens (mainComponent: TerceraWindowBase): void {
        const mainWindowControls = mainComponent.Controls;
        mainWindowControls.addOverlayScreen?.close();
        mainWindowControls.renameScreen?.close();
        mainWindowControls.objectManagerScreen?.close();
        mainWindowControls.propertySetupScreen?.close();
    }

    private forcedChangeTimeFrameComboBox (newVal: number): void {
        const cBox = this.Controls.timeFrameComboBox;
        if (!isNullOrUndefined(cBox)) {
            cBox.forcedChangeMenuItemSelected(newVal);
        }

        const model = this.terceraChartRactive.terceraChart.model;
        const oldTFInfo = model.GetTimeFrameInfo();
        const controller = this.terceraChartRactive.terceraChart.chartController;
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: newVal }));
    }

    private forcedChangeDrawingStyleComboBox (): void {
        const chart = this.terceraChartRactive.terceraChart;
        const allowedStyles = chart.model.CurrentAllowedStyles;

        const oldValue = chart.model.GetChartDrawingType();
        const newValue = !isNullOrUndefined(allowedStyles[oldValue + 1])
            ? allowedStyles[oldValue + 1]
            : allowedStyles[0];

        const cBox = this.Controls.drawingStyleComboBox;
        if (!isNullOrUndefined(cBox)) {
            cBox.forcedChangeMenuItemSelected(newValue);
        }

        const controller = chart.chartController;
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Style), newValue);
    }

    private firstChartLoading (): void {
        const goToDate: Date = this.get('goToDate');
        const terceraChart = this.terceraChartRactive.terceraChart;
        const timeFrame = terceraChart.model.GetTimeFrameInfo();
        const barsCount = terceraChart.getInitBarsCount();
        const now = new Date().getTime();
        const dateByCount = now - TimeSpan.FromMinutes(barsCount * timeFrame.Periods).getTime();
        if (!isNullOrUndefined(goToDate) && timeFrame.Periods > 0 && dateByCount > goToDate.getTime()) {
            this.terceraChartRactive.terceraChart.model.SetHistoryFrom(goToDate);
            this.terceraChartRactive.terceraChart.RefreshChart(ChartLoadTrigger.FirstLoadingByPeriod);
        } else {
            terceraChart.RefreshChart();
        }
    }

    private localizeGoTo (): void {
        const goToText = Resources.getResource('chart.goTo.Button');
        const cancelBtnText = Resources.getResource('button.cancel');
        void this.set({
            goToHeader: goToText,
            goToBtnText: goToText,

            goToButtons: [
                new DateSelectorButton(goToText, this.onGoToLoadingStart.bind(this), 'js-button-buy25'),
                new DateSelectorButton(cancelBtnText, () => {})
            ]
        });
    }

    private getMaxDepthDate (): Date {
        const instrument = this.getInstrument();
        if (isNullOrUndefined(instrument)) { return null; }
        const tfi = this.tChart?.TimeFrameInfo();
        const todayDate = new Date();
        todayDate.setHours(0, 0, 0, 0);
        const historyDepthLimits = DataCache.RulesCache.GetRuleValue<Map<string, HistoryDepthLimit>>(RulesSet.VALUE_QUOTE_HISTORY_DEPTH_LIMITS);
        const daysToSubtract = Periods.getDepthOfChart(tfi.Periods, instrument.getInstrumentGroupId().toString(), historyDepthLimits);
        const dayTime = TimeSpan.FromDays(daysToSubtract);
        todayDate.setTime(todayDate.getTime() - dayTime.getTime());
        return todayDate;
    }

    private onLoadingFinish (): void {
        const chartCallbackXml = this.get('chartCallbackXml');
        if (!isNullOrUndefined(chartCallbackXml)) {
            this.set('chartCallbackXml', null);
        }
    }
}

ApplicationPanelWithTable.extendWith(ChartPanel, {
    Name: 'ChartPanel',
    data: function () {
        return {
            showFullscreenModeButton: true,
            isAccountLinkShow: true,
            isSymbolLinkShow: true,
            instrumentItem: null,
            accountItem: null,

            isShowOE: false,
            oeSmall: true,

            showToolsPanel: false,
            showMainToolBarPanel: true,
            showAnalyzeToolsPanel: false,
            showAccountLookUp: true,
            isVisibleMainToolBar: true,
            tradingLocked: false,

            canFilterByAccount: false,

            showOverlayButton: true,

            dataSourceOpen: false,
            dataSourceVisible: true, // true to enable data source
            dataSourceBottom: 5,

            hasCorrectData: false,

            verticalToolsPanel: true,
            leftOrTopToolsPanel: true,

            addWidgets: true,
            minSizeForToolBars: 200,
            // autoLoadingNow: false
            goToEnabled: false,
            isGoToOnlyDate: false,
            goToDate: null,
            goToBtnTooltip: '',
            goToHeader: '',
            minGoToDate: null,
            chartCallbackProps: null,
            chartCallbackXml: null
        };
    },
    computed: {
        terceraChartPanelContext: {
            get: function () { return this; },
            set: function (value) { }
        }
        // autoLoadingNowClass: {
        //     get: function () { return this.get('autoLoadingNow') as boolean ? 'autoLoadingNow' : ''; }
        // }
    },
    partials: {
        bodyPartial: ChartPanelTemplate
    }
});
