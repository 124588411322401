// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountActivityGroupEnum, type AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum, GeneralGroupEnum, MarginGroupEnum, RiskManagementGroupEnum, TodayResultsGroupEnum } from '../../../Utils/Enums/AccountDetailsEnum';
import { VerticalDetailsTypePointer } from './VerticalDetailsTypePointer';

export class ConvertingOldSettings {
    public static ConvertingSettingsFromOldToNew (type: number): VerticalDetailsTypePointer<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum> {
        switch (type) {
        // General
        case 2:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.Balance, AccountDetailsGroupsEnum.General);
        case 6:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.CurBalance, AccountDetailsGroupsEnum.General);
        case 43:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.BalancePlusAllRisks, AccountDetailsGroupsEnum.General);
        case 35:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.AccountValue, AccountDetailsGroupsEnum.General);
        case 61:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.CreditValue, AccountDetailsGroupsEnum.General);
        case 20:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.AvailableMargin, AccountDetailsGroupsEnum.General);
        case 3:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.LockedBalance, AccountDetailsGroupsEnum.General);
        case 36:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.CashBalance, AccountDetailsGroupsEnum.General);
        case 24:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.WithdrawalAvailable, AccountDetailsGroupsEnum.General);
        case 70:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.InterestRate, AccountDetailsGroupsEnum.General);
        case 66:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.EstimateValue, AccountDetailsGroupsEnum.General);
        case 84:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.UnsettledCash, AccountDetailsGroupsEnum.General);
        case 117:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.IncomingFunds, AccountDetailsGroupsEnum.General);
        case 34:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.StocksValue, AccountDetailsGroupsEnum.General);
        case 92:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.OptionValue, AccountDetailsGroupsEnum.General);
        case 93:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.StocksLiquidity, AccountDetailsGroupsEnum.General);
        case 103:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.Collateral, AccountDetailsGroupsEnum.General);
        case 109:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.BuyingPower, AccountDetailsGroupsEnum.General);
        case 112:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.GivenLeverage, AccountDetailsGroupsEnum.General);
        case 113:
            return new VerticalDetailsTypePointer(GeneralGroupEnum.OpenBalance, AccountDetailsGroupsEnum.General);
            // Margin
        case 59:
            return new VerticalDetailsTypePointer(MarginGroupEnum.FundsUsed, AccountDetailsGroupsEnum.Margin);
        case 60:
            return new VerticalDetailsTypePointer(MarginGroupEnum.FundsUsedPercent, AccountDetailsGroupsEnum.Margin);
        case 42:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginUsedNew, AccountDetailsGroupsEnum.Margin);
        case 19:
            return new VerticalDetailsTypePointer(MarginGroupEnum.StopOutPercent, AccountDetailsGroupsEnum.Margin);
        case 14:
            return new VerticalDetailsTypePointer(MarginGroupEnum.CurMargin, AccountDetailsGroupsEnum.Margin);
        case 15:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MaintMargin, AccountDetailsGroupsEnum.Margin);
        case 16:
            return new VerticalDetailsTypePointer(MarginGroupEnum.Leverage, AccountDetailsGroupsEnum.Margin);
        case 58:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginAvailableReal, AccountDetailsGroupsEnum.Margin);
        case 17:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginWarnPercent, AccountDetailsGroupsEnum.Margin);
        case 21:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginWarning, AccountDetailsGroupsEnum.Margin);
        case 44:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginRequirement, AccountDetailsGroupsEnum.Margin);
        case 12:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MinMargin, AccountDetailsGroupsEnum.Margin);
        case 13:
            return new VerticalDetailsTypePointer(MarginGroupEnum.BlockedByOrders, AccountDetailsGroupsEnum.Margin);
        case 23:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginStopOut, AccountDetailsGroupsEnum.Margin);
        case 91:
            return new VerticalDetailsTypePointer(MarginGroupEnum.StocksOrdersReq, AccountDetailsGroupsEnum.Margin);
        case 45:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginDeficiency, AccountDetailsGroupsEnum.Margin);
        case 46:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginSurplus, AccountDetailsGroupsEnum.Margin);
        case 72:
            return new VerticalDetailsTypePointer(MarginGroupEnum.BlockedForStocks, AccountDetailsGroupsEnum.Margin);
        case 73:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginForOthers, AccountDetailsGroupsEnum.Margin);
        case 74:
            return new VerticalDetailsTypePointer(MarginGroupEnum.BlockedForStocksNew, AccountDetailsGroupsEnum.Margin);
        case 102:
            return new VerticalDetailsTypePointer(MarginGroupEnum.OptionPremiumReq, AccountDetailsGroupsEnum.Margin);
        case 104:
            return new VerticalDetailsTypePointer(MarginGroupEnum.WaivedMargin, AccountDetailsGroupsEnum.Margin);
        case 105:
            return new VerticalDetailsTypePointer(MarginGroupEnum.WarningMarginReq, AccountDetailsGroupsEnum.Margin);
        case 106:
            return new VerticalDetailsTypePointer(MarginGroupEnum.WarningMarginReqPercent, AccountDetailsGroupsEnum.Margin);
        case 107:
            return new VerticalDetailsTypePointer(MarginGroupEnum.MarginBeforeWarning, AccountDetailsGroupsEnum.Margin);
        case 108:
            return new VerticalDetailsTypePointer(MarginGroupEnum.InitialMarginWithoutWaived, AccountDetailsGroupsEnum.Margin);
        case 115:
            return new VerticalDetailsTypePointer(MarginGroupEnum.StopOut, AccountDetailsGroupsEnum.Margin);
            // AccountActivity
        case 39:
            return new VerticalDetailsTypePointer(AccountActivityGroupEnum.CurrentFundCapital, AccountDetailsGroupsEnum.AccountActivity);
        case 40:
            return new VerticalDetailsTypePointer(AccountActivityGroupEnum.FundCapitalGain, AccountDetailsGroupsEnum.AccountActivity);
        case 5:
            return new VerticalDetailsTypePointer(AccountActivityGroupEnum.Profit, AccountDetailsGroupsEnum.AccountActivity);
        case 4:
            return new VerticalDetailsTypePointer(AccountActivityGroupEnum.ProfitNet, AccountDetailsGroupsEnum.AccountActivity);
        case 11:
            return new VerticalDetailsTypePointer(AccountActivityGroupEnum.Positions, AccountDetailsGroupsEnum.AccountActivity);
        case 10:
            return new VerticalDetailsTypePointer(AccountActivityGroupEnum.Orders, AccountDetailsGroupsEnum.AccountActivity);
        case 37:
            return new VerticalDetailsTypePointer(AccountActivityGroupEnum.BlockedByPamm, AccountDetailsGroupsEnum.AccountActivity);
            // TodayResult
        case 29:
            return new VerticalDetailsTypePointer(TodayResultsGroupEnum.GrossPNL, AccountDetailsGroupsEnum.TodayResult);
        case 31:
            return new VerticalDetailsTypePointer(TodayResultsGroupEnum.BeginBalance, AccountDetailsGroupsEnum.TodayResult);
        case 30:
            return new VerticalDetailsTypePointer(TodayResultsGroupEnum.TodayFees, AccountDetailsGroupsEnum.TodayResult);
        case 32:
            return new VerticalDetailsTypePointer(TodayResultsGroupEnum.TodayVolume, AccountDetailsGroupsEnum.TodayResult);
        case 33:
            return new VerticalDetailsTypePointer(TodayResultsGroupEnum.TodayTrades, AccountDetailsGroupsEnum.TodayResult);
        case 68:
            return new VerticalDetailsTypePointer(TodayResultsGroupEnum.TodayTurnover, AccountDetailsGroupsEnum.TodayResult);
        case 71:
            return new VerticalDetailsTypePointer(TodayResultsGroupEnum.TodayRebates, AccountDetailsGroupsEnum.TodayResult);
        case 83:
            return new VerticalDetailsTypePointer(TodayResultsGroupEnum.TodaySwaps, AccountDetailsGroupsEnum.TodayResult);
            // RiskManagement
        case 48:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.CurrentStatus, AccountDetailsGroupsEnum.RiskManagement);
        case 49:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.AllowTradingPrePost, AccountDetailsGroupsEnum.RiskManagement);
        case 50:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.DailyLoss_MaxDailyLossLimit, AccountDetailsGroupsEnum.RiskManagement);
        case 52:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.CurrentOrdersCountPerDay_MaxOrdersPerDay, AccountDetailsGroupsEnum.RiskManagement);
        case 53:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.CurrentPositions_MaxPositions, AccountDetailsGroupsEnum.RiskManagement);
        case 54:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.CurrentPendingOrders_MaxPendingOrders, AccountDetailsGroupsEnum.RiskManagement);
        case 85:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.MaxPendingOrdersValue, AccountDetailsGroupsEnum.RiskManagement);
        case 55:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.MaxOrderCapital, AccountDetailsGroupsEnum.RiskManagement);
        case 56:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.MaximumLot, AccountDetailsGroupsEnum.RiskManagement);
        case 57:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.MaxPositionQtyPerSymbol, AccountDetailsGroupsEnum.RiskManagement);
        case 62:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.AllowOvernightTrading, AccountDetailsGroupsEnum.RiskManagement);
        case 86:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.ProjectedBalance_TrailingDrawdownLevel, AccountDetailsGroupsEnum.RiskManagement);
        case 87:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.CurrentTotalPositionQty_TotalMaxPositionsQty, AccountDetailsGroupsEnum.RiskManagement);
        case 88:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.MaxWeekLostLimit, AccountDetailsGroupsEnum.RiskManagement);
        case 89:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.CurrentDailyLoss, AccountDetailsGroupsEnum.RiskManagement);
        case 90:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.CurrentWeeklyLoss, AccountDetailsGroupsEnum.RiskManagement);
        case 94:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.UnrealizedLoss, AccountDetailsGroupsEnum.RiskManagement);
        case 95:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.UnrealizedLoss_UnrealizedLossLimit, AccountDetailsGroupsEnum.RiskManagement);
        case 96:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.MaxDailyProfit, AccountDetailsGroupsEnum.RiskManagement);
        case 118:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForALL, AccountDetailsGroupsEnum.RiskManagement);
        case 119:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForFOREX, AccountDetailsGroupsEnum.RiskManagement);
        case 97:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForEQUITIES, AccountDetailsGroupsEnum.RiskManagement);
        case 98:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForFUTURES, AccountDetailsGroupsEnum.RiskManagement);
        case 99:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForOPTIONS, AccountDetailsGroupsEnum.RiskManagement);
        case 120:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForEQUITIES_CFD, AccountDetailsGroupsEnum.RiskManagement);
        case 121:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForFORWARD, AccountDetailsGroupsEnum.RiskManagement);
        case 122:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForCFD_FUTURES, AccountDetailsGroupsEnum.RiskManagement);
        case 123:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForINDICIES, AccountDetailsGroupsEnum.RiskManagement);
        case 124:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForCRYPTO, AccountDetailsGroupsEnum.RiskManagement);
        case 125:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForSPREADBET, AccountDetailsGroupsEnum.RiskManagement);
        case 126:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForBOND, AccountDetailsGroupsEnum.RiskManagement);
        case 127:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForETF, AccountDetailsGroupsEnum.RiskManagement);
        case 128:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForTBILL, AccountDetailsGroupsEnum.RiskManagement);
        case 129:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForSPOT, AccountDetailsGroupsEnum.RiskManagement);
        case 130:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.VolumeLimitForCORPORATE, AccountDetailsGroupsEnum.RiskManagement);
        case 101:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.MaxOrderAmount, AccountDetailsGroupsEnum.RiskManagement);
        case 110:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.DayTraderPatternProtection, AccountDetailsGroupsEnum.RiskManagement);
        case 111:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.AvailableDayTrades, AccountDetailsGroupsEnum.RiskManagement);
        case 114:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.ProjectedBalance_MaxRelativeDrawDownLevel, AccountDetailsGroupsEnum.RiskManagement);
        case 116:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.LossLimitPerTrade, AccountDetailsGroupsEnum.RiskManagement);
        case 131:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.EODTrailingDrawdownLevel, AccountDetailsGroupsEnum.RiskManagement);
        case 132:
            return new VerticalDetailsTypePointer(RiskManagementGroupEnum.DailyLoss_RelativeDailyLossLimit, AccountDetailsGroupsEnum.RiskManagement);

        default: return null;
        };
    }
}
