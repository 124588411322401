// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CareOrderType } from './order-type/CareOrderType';
import { LimitOrderType } from './order-type/LimitOrderType';
import { MarketOrderType } from './order-type/MarketOrderType';
import { OCOOrderType } from './order-type/OCOOrderType';
import { type OrderTypeBase } from './order-type/OrderTypeBase';
import { StopLimitOrderType } from './order-type/StopLimitOrderType';
import { StopOrderType } from './order-type/StopOrderType';
import { TrailingStopOrderType } from './order-type/TrailingStopOrderType';

// класс который содержит в себе дополнительные параметры ордeра
export class OrderParamsContainer {
    public OrderTypes: Record<number, OrderTypeBase>;

    constructor () {
        this.OrderTypes = {};

        this.AddOrderType(new MarketOrderType());
        this.AddOrderType(new LimitOrderType());
        this.AddOrderType(new StopOrderType());
        this.AddOrderType(new StopLimitOrderType());
        this.AddOrderType(new TrailingStopOrderType());
        this.AddOrderType(new CareOrderType());
        this.AddOrderType(new OCOOrderType());
    }

    public AddOrderType (orderType: OrderTypeBase): void {
        this.OrderTypes[orderType.id()] = orderType;
    }

    // TODO. OrderExecutor's constants dependent.
    public GetOrderType (id): OrderTypeBase {
        return this.OrderTypes[id];
    }
}
