// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { FillableDataCacheTool } from './FillableDataCacheTool';

export class PolygonDataCacheTool extends FillableDataCacheTool {
    /**
     * Constructor for PolygonDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.Polygon;
        this.InitPoints(3);
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 3;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 100000;
    }
}
