// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../DataCache';
import { type Account } from '../Account';
import { SavedOrder } from './SavedOrder';

export function Serialize (savedOrder: SavedOrder): string {
    const serializedData = {
        Id: savedOrder.Id,
        OpenedId: savedOrder.OpenedId,
        Status: savedOrder.Status,
        StatusError: savedOrder.StatusError,
        AccountId: savedOrder.Account?.userPin,
        AccountName: savedOrder.AccountName,
        InstrumentTradableId: savedOrder.Instrument?.InstrumentTradableID,
        InstrumentRouteId: savedOrder.Instrument?.Route,
        InstrumentName: savedOrder.InstrumentName,
        Active: savedOrder.Active,
        Operation: savedOrder.Operation,
        ProductType: savedOrder.ProductType,
        Cash: savedOrder.Cash,
        QuantityLots: savedOrder.QuantityLots,
        DisclosedQuantityLots: savedOrder.DisclosedQuantityLots,
        OrderType: savedOrder.OrderType,
        TIF: savedOrder.TIF,
        TIFExpiration: savedOrder.TIFExpiration,
        StopPrice: savedOrder.StopPrice,
        Price: savedOrder.Price,
        SLTPHolder: savedOrder.SLTPHolder,
        LinkedPriceType: savedOrder.LinkedPriceType,
        LinkedPriceOffset: savedOrder.LinkedPriceOffset,
        Leverage: savedOrder.Leverage,
        UserComment: savedOrder.UserComment
    };
    return JSON.stringify(serializedData, (key, value) => {
        if (Number.isNaN(value)) {
            return 'NaN';
        } else {
            return value;
        }
    });
}

export async function DeserializeAsync (savedOrderString: string): Promise<SavedOrder> {
    const serializedData = JSON.parse(savedOrderString);
    const account: Account = DataCache.GetAccountById(serializedData.AccountId);
    const savedOrder = new SavedOrder(serializedData.Id);
    savedOrder.OpenedId = serializedData.OpenedId;
    savedOrder.Status = Number(serializedData.Status);
    savedOrder.StatusError = serializedData.StatusError;
    savedOrder.Account = account;
    savedOrder.AccountName = serializedData.AccountName;
    savedOrder.Instrument = await DataCache.getInstrumentByInstrumentTradableID_NFL(serializedData.InstrumentTradableId, serializedData.InstrumentRouteId);
    savedOrder.InstrumentName = serializedData.InstrumentName;
    savedOrder.Active = Boolean(serializedData.Active);
    savedOrder.Operation = Number(serializedData.Operation);
    savedOrder.ProductType = Number(serializedData.ProductType);
    savedOrder.Cash = Number(serializedData.Cash);
    savedOrder.QuantityLots = Number(serializedData.QuantityLots);
    savedOrder.DisclosedQuantityLots = Number(serializedData.DisclosedQuantityLots);
    savedOrder.OrderType = Number(serializedData.OrderType);
    savedOrder.TIF = Number(serializedData.TIF);
    savedOrder.TIFExpiration = new Date(serializedData.TIFExpiration);
    savedOrder.StopPrice = isNullOrUndefined(serializedData.StopPrice) ? NaN : Number(serializedData.StopPrice);
    savedOrder.Price = isNullOrUndefined(serializedData.Price) ? NaN : Number(serializedData.Price);
    savedOrder.SLTPHolder.copyFrom(serializedData.SLTPHolder);
    savedOrder.LinkedPriceType = Number(serializedData.LinkedPriceType);
    savedOrder.LinkedPriceOffset = Number(serializedData.LinkedPriceOffset);
    savedOrder.Leverage = isNullOrUndefined(serializedData.Leverage) ? null : Number(serializedData.Leverage);
    savedOrder.UserComment = serializedData.UserComment;
    return savedOrder;
}
