// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Interval } from '../../../../../Utils/History/Interval';
import { IndicatorHistoryType, Period } from '../../IndicatorEnums';
import { type TimeInterval } from '../../../TimeInterval';
import { type HistoricalData } from '../../HistoricalData';
import { TimeSpan } from '../../../../../Utils/Time/TimeSpan';

export class VWAPCalculator {
    private daysCount: number;
    private timeInterval: TimeInterval;

    private sumSize: number = 0;
    private sumPriceSize: number = 0;
    private lastVWAPValue: number = 0;
    private currentCycleInterval: Interval;

    constructor (daysCount: number, timeInterval: TimeInterval) {
        this.updateParameters(daysCount, timeInterval);
    }

    public get LastVWAPValue (): number {
        return this.lastVWAPValue;
    }

    public CalculateVWAP (dateTime: Date, volume: number, price: number): void {
        if (this.TryCreateNewCycle(dateTime)) {
            this.sumSize = 0;
            this.sumPriceSize = 0;
            this.lastVWAPValue = 0;
        }

        if (this.AllowCalculateVWAP(dateTime)) {
            this.sumSize += volume;
            this.sumPriceSize += price * volume;
            this.lastVWAPValue = this.sumPriceSize / this.sumSize;
        }
    }

    public IsValidTimeFrame (historyData: HistoricalData): boolean {
        const historyType = historyData.cashItem.ChartDataType;
        const period = historyData.Period;
        if (historyType === IndicatorHistoryType.Simple && period !== Period.Second) {
            return true;
        } else if (historyType === IndicatorHistoryType.Kagi && period !== Period.Tick && period !== Period.Second) {
            return true;
        } else if (historyType === IndicatorHistoryType.PnF && period !== Period.Tick && period !== Period.Second) {
            return true;
        } else if (historyType === IndicatorHistoryType.HeikinAshi && period !== Period.Second) {
            return true;
        } else {
            return false;
        }
    }

    public AllowSetVWAP (dateTime: Date): boolean {
        return this.currentCycleInterval != null && this.currentCycleInterval.From <= dateTime && this.currentCycleInterval.To >= dateTime;
    }

    public cleanInterval (): void {
        this.currentCycleInterval = null;
    }

    public updateParameters (daysCount: number, timeInterval: TimeInterval): void {
        this.daysCount = daysCount;
        this.timeInterval = timeInterval;
    }

    private AllowCalculateVWAP (dateTime: Date): boolean {
        if (this.AllowSetVWAP(dateTime)) {
            const startInMins = this.timeInterval.getStartInMins();
            const endInMins = this.timeInterval.getEndInMins();
            const dateTimeInMins = dateTime.getHours() * 60 + dateTime.getMinutes();
            const isNextDayIntervalTime = startInMins > endInMins;
            if (isNextDayIntervalTime) {
                return dateTimeInMins >= startInMins || dateTimeInMins < endInMins;
            } else {
                return dateTimeInMins >= startInMins && dateTimeInMins < endInMins;
            }
        } else {
            return false;
        }
    }

    private TryCreateNewCycle (dateTime: Date): boolean {
        if (this.currentCycleInterval == null || this.currentCycleInterval.To <= dateTime) {
            this.currentCycleInterval = this.CreateCycleInterval(dateTime);
            return true;
        } else {
            return false;
        }
    }

    private CreateCycleInterval (dateTime: Date): Interval {
        const date = new Date(dateTime);
        date.setHours(0, 0, 0, 0);
        if ((!isNullOrUndefined(this.currentCycleInterval) && this.currentCycleInterval.To === date)) {
            date.setDate(date.getDate() + 1);
        }

        const startInMins = this.timeInterval.getStartInMins();
        const endInMins = this.timeInterval.getEndInMins();
        const isNextDayIntervalTime = startInMins > endInMins;
        const from = new Date(date.getTime() + TimeSpan.FromMinToMs(startInMins));
        const to = new Date(date);
        to.setDate(date.getDate() + (this.daysCount - 1) + (isNextDayIntervalTime ? 1 : 0));
        const intervalTo = new Date(to.getTime() + TimeSpan.FromMinToMs(endInMins + 1));
        intervalTo.setMilliseconds(-1);

        return new Interval(from, intervalTo);
    }
}
