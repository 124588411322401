// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ProfitCalculator } from './ProfitCalculator';
import { HistoryType } from '../../Utils/History/HistoryType';
import { Resources } from '../../Localizations/Resources';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OptionTradingStyle } from '../../Utils/Instruments/OptionTradingStyle';
import { AccountType } from '../../Utils/Account/AccountType';
import { QuoteValid } from '../../Utils/Quotes/QuoteValid';
import { Order } from './Order';
import { OrderEditRequestData } from '../../Utils/Trading/OrderEditRequestData';
import { Quantity } from '../../Utils/Trading/Quantity';
import { SlTpHolder } from '../../Utils/Trading/SlTpHolder';
import { OrderType } from '../../Utils/Trading/OrderType';
import { SlTpPriceType } from '../../Utils/Enums/Constants';

export class Position extends Order {
    public OpenOrderId: any;
    public RealizedPL: any;
    public OpenTime: any;
    public OpenPrice: any;

    public OpenCrossPrice = 1;
    public isSuperPosition: boolean;
    public isTradePosition: boolean;

    public AccruedInterest: number | null = null;
    // #80010
    // TODO. Refactor.
    public AvailableForSell = 0;

    public UnitVolume: number;

    public amountExp1: any;

    public Commission = 0;

    public Swaps = 0;

    public OptionExerciseStatus: any = null;
    public CorporateActionType: any = null;
    public StartOfDayAmount: any = null;
    public PnLCalculator: any;

    public expositionUSD: number;
    public expositionAccountCurr: number;
    public positionValueUSD: any;
    public positionValueAcc: any;

    public SLOrder: Order = null;
    public TPOrder: Order = null;

    constructor (dataCache, quoteController, paramObj) {
        super(dataCache,
            quoteController,
            {
                OrderNumber: paramObj.PositionId,
                OrderType: 0,
                Amount: paramObj.Amount,
                Price: paramObj.OpenPrice,
                BuySell: paramObj.OperationType,
                TimeInForce: 0,
                UTCDateTime: paramObj.OpenTime,
                Active: true,
                Route: paramObj.Route,
                Account: paramObj.Account,
                Instrument: paramObj.Instrument,
                ProductType: paramObj.ProductType,
                Leverage: paramObj.Leverage,
                ServerCalculation: paramObj.ServerCalculation
            });

        this.PositionId = paramObj.PositionId;
        this.SuperPositionId = paramObj.SuperPositionId;
        this.OpenOrderId = paramObj.OpenOrderId;
        this.RealizedPL = paramObj.RealizedPL;
        this.OpenTime = paramObj.OpenTime;
        this.OpenPrice = paramObj.OpenPrice;
        this.AccruedInterest = paramObj.AccruedInterest;

        this.isPosition = true;
        this.isSuperPosition = this.PositionId == this.SuperPositionId; // is super position of FIFO Position #80010
        this.isTradePosition = this.SuperPositionId != '-1' && !this.isSuperPosition; // is sub position of FIFO Position

        this.UnitVolume = this.Amount * this.Instrument.getLotSize();

        this.amountExp1 = (this.BuySell === OperationType.Buy) ? this.UnitVolume : -this.UnitVolume;

        this.PnLCalculator = new ProfitCalculator(this);
    }

    get AmountAvailableForSell (): number {
        return this.Amount - (this.AvailableForSell || 0);
    }

    // #93540
    override get IsActivated (): boolean {
        return this.SLOrder?.IsActivated;
    }

    public override GetBasePriceForTrailingStop (): number {
        if (this.lastPriceUpdated) {
            return this.CurPriceClose;
        } else {
            return this.Price;
        }
    }

    public override createSlTpHolder (): SlTpHolder {
        const sltpholder = new SlTpHolder();
        if (!isNullOrUndefined(this.SLOrder)) {
            switch (this.SLOrder.OrderType) {
            case OrderType.TrailingStop:
                sltpholder.StopLossPriceType = SlTpPriceType.TrOffset;
                sltpholder.StopLossPriceValue = this.SLOrder.TrStopOffset;
                break;
            case OrderType.StopLimit:
                sltpholder.StopLossPriceValue = this.SLOrder.StopLimit;
                sltpholder.StopLossLimitPriceValue = this.SLOrder.Price;
                break;
            default:
                sltpholder.StopLossPriceValue = this.SLOrder.Price;
                break;
            }
        }
        if (!isNullOrUndefined(this.TPOrder)) {
            sltpholder.TakeProfitPriceValue = this.TPOrder.Price;
        }
        return sltpholder;
    }

    // TODO.
    public UpdateByMessage (message): void {
        this.Route = message.Route || this.Route;
        this.Amount = message.Amount || this.Amount;
        this.Price = message.OpenPrice || this.Price;
        this.OpenPrice = message.OpenPrice || this.OpenPrice;
        this.OpenTime = message.OpenTime || this.OpenTime;
        this.OpenOrderId = message.OpenOrderId || this.OpenOrderId;
        this.RealizedPL = message.RealizedPL || this.RealizedPL;

        this.ProductType = message.ProductType || this.ProductType;
        this.SuperPositionId = message.SuperPositionId || this.SuperPositionId;

        if (message.hasOwnProperty('Swaps')) {
            this.Swaps = message.Swaps;
        }

        if (message.hasOwnProperty('Commission')) {
            this.Commission = message.Commission;
        }

        if (message.hasOwnProperty('OptionExerciseStatus')) {
            this.OptionExerciseStatus = message.OptionExerciseStatus;
            if (this.IsPendingExerciseOptionStatus()) // 93127: При смене статуса для позиций на Pending exercise. Нужно выводить поверх всех окон скрин
            {
                this.DataCache.PositionExerciseStatusChanged(this.PositionId);
            }
        }

        if (message.CorporateActionType) {
            this.CorporateActionType = message.CorporateActionType;
        }

        const serverCalculation = this.ServerCalculation;
        if (message.ServerCalculationCurPriceClose) {
            serverCalculation.CurPriceClose = message.ServerCalculationCurPriceClose;
        }

        if (message.ServerCalculationExposure) {
            serverCalculation.Exposure = message.ServerCalculationExposure;
        }

        if (message.ServerCalculationPositionValue) {
            serverCalculation.PositionValue = message.ServerCalculationPositionValue;
        }

        if (message.ServerCalculationNetPL) {
            serverCalculation.NetPL = message.ServerCalculationNetPL;
        }

        if (message.ServerCalculationGrossPL) {
            serverCalculation.GrossPL = message.ServerCalculationGrossPL;
        }

        // Taken from Order.newQuote() ServerCalculation exists only in positions;
        if (serverCalculation != null && serverCalculation.CurPriceClose !== null) {
            this.CurPriceClose = this.CurPriceCloseNotRounded = serverCalculation.CurPriceClose;
        }

        this.UnitVolume = this.Amount * this.Instrument.getLotSize();

        this.amountExp1 = (this.BuySell === OperationType.Buy) ? this.UnitVolume : -this.UnitVolume;

        this.StartOfDayAmount = message.StartOfDayAmount;

        if (message.Leverage) {
            this.Leverage = message.Leverage;
        }

        if (message.AccruedInterest != null) {
            this.AccruedInterest = message.AccruedInterest;
        }

        this.OnUpdate.Raise(this);
    }

    public override newQuote (mess): void {
    // Order.prototype.newQuote.call(this, mess);
        let quote = null;

        if (mess.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR || mess.Type === HistoryType.QUOTE_TRADES) {
            quote = this.Instrument.GetLastQuote(QuoteValid.Last);
        }

        if (mess.Type === HistoryType.QUOTE_LEVEL1 || quote != null) {
            const quote1Msg = mess.Type === HistoryType.QUOTE_LEVEL1 ? mess : quote;

            const lastPrice = this.Instrument.Level1.GetLastPrice(this.Account);

            if (!this.Instrument.CalcPriceOnTrade() && quote1Msg != null) {
                const sp = this.DataCache.GetSpreadPlan(this.Account);
                // по л1 всегда и точно всегда (и никаого тут трейда/ласта!!!)
                if (this.BuySell === OperationType.Buy) {
                    if (isNaN(quote1Msg.Bid)) { this.CurPriceClose = this.CurPriceCloseNotRounded = lastPrice; } else {
                        this.CurPriceClose = quote1Msg.BidSpread_SP_Ins(sp, this.Instrument);
                        this.CurPriceCloseNotRounded = quote1Msg.BidSpread_SP_Ins(sp, this.Instrument, false);
                    }
                } else {
                    if (isNaN(quote1Msg.Ask)) { this.CurPriceClose = this.CurPriceCloseNotRounded = lastPrice; } else {
                        this.CurPriceClose = quote1Msg.AskSpread_SP_Ins(sp, this.Instrument);
                        this.CurPriceCloseNotRounded = quote1Msg.AskSpread_SP_Ins(sp, this.Instrument, false);
                    }
                }

                this.lastPriceUpdated = true;
                if (isNaN(this.CurPriceClose)) {
                    this.CurPriceClose = this.CurPriceCloseNotRounded = lastPrice;
                }
            } else {
            // беру ласт (если он есть)
            // double last = quote1Msg.LastPice.GetValueOrDefault(); //quote1Msg.LastSpread(FAccount, instrument); //ласт не спредировать, брать оригинальный
                if (!isNaN(lastPrice)) {
                    this.CurPriceClose = this.CurPriceCloseNotRounded = lastPrice;
                    this.lastPriceUpdated = true;
                }

            // тут тоже можно превклоуз взять, когда нет трейда
            }

            this.LastQuote = quote1Msg;

            if (this.SLOrder) {
                this.SLOrder.newQuote(mess);
            }

            if (this.TPOrder) {
                this.TPOrder.newQuote(mess);
            }

            this.OnUpdate.Raise(this);
        }

        if (this.Instrument !== null && (this.Instrument.InstrumentDayBarMessageUpdateMode || mess.Type == HistoryType.QUOTE_INSTRUMENT_DAY_BAR)) {
            if (this.Instrument.CalcPriceOnTrade()) {
                const last = this.Instrument.Level1.GetLastPrice(this.Account);
                if (!isNaN(last) && last !== this.CurPriceClose) {
                    this.CurPriceClose = this.CurPriceCloseNotRounded = last;
                    this.lastPriceUpdated = true;
                }
            }
        } else if (mess.Type === HistoryType.QUOTE_TRADES) {
            if (this.Instrument.CalcPriceOnTrade()) {
                this.CurPriceClose = this.CurPriceCloseNotRounded = mess.Price;
                this.lastPriceUpdated = true;
            }
        }
    }

    public RecalcAmounts (): void {
        const curPriceAvailable = !isNaN(this.CurPriceClose);

        const buySell = this.BuySell;
        const openPrice = this.OpenPrice;
        const crossInstrumentUSD = this.DataCache.CrossRateCache.GetCrossPriceIns(this.Instrument, buySell);
        let openCrossPrice = this.OpenCrossPrice;
        const ticCost = this.Instrument.GetTickCost();

        let USCPFOC = true;
        if (this.Account.RiskPlan !== null) {
            const rps = this.Account.RiskPlan.riskPlanCache.getRiskPlanSettings(this.Instrument, this.ProductType, 'UseSameCrossPriceForOpenClose');
            USCPFOC = rps.getValue(this.ProductType, 'UseSameCrossPriceForOpenClose');
        }

        if (USCPFOC || openCrossPrice === 0) {
            let crossInstrumentAcc = this.DataCache.CrossRateCache.GetCrossPriceInsSideExp2(this.Instrument, buySell, this.Account.BaseCurrency);
            if (this.Account.AccountType === AccountType.MultiAsset) {
                crossInstrumentAcc = 1.0;
            } // сейчас позиций по мульта аккам не бывает, и всё же.
            openCrossPrice = crossInstrumentAcc;
        }

        if (curPriceAvailable) {
            this.PnLCalculator.Calculate();
        }

        this.expositionUSD = Math.abs(this.amountExp1) * openPrice * crossInstrumentUSD * ticCost;
        this.expositionAccountCurr = Math.abs(this.amountExp1) * openPrice * openCrossPrice * ticCost;

        if (curPriceAvailable) {
            this.positionValueUSD = (this.expositionUSD + (this.BuySell === OperationType.Buy ? 1 : -1) * this.PnLCalculator.NetGrossPL); // #85612
            this.positionValueAcc = (this.expositionAccountCurr + (this.BuySell === OperationType.Buy ? 1 : -1) * this.PnLCalculator.AccGrossPL); // #85612
        }
    }

    public getNetPnL (useAccountCurr): any {
        return useAccountCurr ? this.PnLCalculator.AccNetPL : this.PnLCalculator.NetPL;
    }

    public getGrossPnL (useAccountCurr): any {
        return useAccountCurr ? this.PnLCalculator.AccGrossPL : this.PnLCalculator.NetGrossPL;
    }

    public getMaintMargin (useAccountCurr): number {
        return 0;

    // if (useAccountCurr || this.Account == null) { return maintMargin; } else { return maintMargin / this.Account.getCrossPrice(); }
    }

    public GetCommissionFromSettlement (useAccountCurr: boolean): number {
        if (useAccountCurr) {
            return this.Commission;
        } else {
            return this.Commission / this.Account.getCrossPriceCorrect();
        }
    }

    public getPositionValue (useAccountCurr): any {
        if (useAccountCurr) {
            return this.positionValueAcc;
        } else {
            return this.positionValueUSD;
        }
    }

    public GetSwaps (useAccCurrency): number {
        return this.Swaps;
    }

    public getExpositionValue (useAccountCurr): number {
        if (useAccountCurr) {
            return this.expositionAccountCurr;
        } else {
            return this.expositionUSD;
        }
    }

    public CalcultateOptionPremium (): number {
        let premium = 0;

        if (this.Account !== null && (this.Instrument.OptionTradingStyle == OptionTradingStyle.Premium || this.Instrument.OptionTradingStyle == OptionTradingStyle.MarkToMarket)) {
            const quantity = (this.BuySell === OperationType.Buy ? 1 : -1) * this.Amount;
            const cross = this.DataCache.CrossRateCache.GetCrossPriceInsSideExp2(this.Instrument, this.BuySell, this.Account.assetBalanceDefault.Asset.Name);

            premium = (this.Instrument.OptionTradingStyle == OptionTradingStyle.MarkToMarket ? this.CurPriceClose : this.OpenPrice) * quantity * this.Instrument.LotSize * this.Instrument.GetTickCost() * cross;
        }

        return premium;
    }

    public GetMarkup (): number {
        let crossInstrumentAcc = this.DataCache.CrossRateCache.GetCrossPriceInsSideExp2(this.Instrument, this.BuySell, this.Account.BaseCurrency);

        if (this.Account != null && this.Account.AccountType === AccountType.MultiAsset) {
            crossInstrumentAcc = 1.0;
        } // сейчас позиций по мульта аккам не бывает, и всё же.

        let markupInstrumentAcc: any = null;
        if (this.Account?.CrossratesPlan != null) {
            markupInstrumentAcc = this.Account.CrossratesPlan.GetMarkup(this.Instrument.Exp2, this.Account.BaseCurrency, crossInstrumentAcc, this.Instrument, this.BuySell === OperationType.Buy);
        }

        if (markupInstrumentAcc) {
            return markupInstrumentAcc.markup;
        }

        return 0;
    }

    public IsPendingExerciseOptionStatus (): boolean {
        return this.OptionExerciseStatus === Position.OPTION_EXERCISE_STATUS.PENDING_EXERCISE; // #93127
    }

    public GetInstrumentDisplayName (forceHideRoute: boolean = false): string {
        const displayName = this.Instrument.DisplayName();
        if (this.CorporateActionType !== null && CorporateActionTypeMap[this.CorporateActionType]) {
            const corporateActionText = Resources.getResource('corporateActionType.' + CorporateActionTypeMap[this.CorporateActionType]);
            if ((this.Instrument.isHideRouteMode || forceHideRoute) || this.Instrument.FLanguageAliases) {
                return displayName + ' (' + corporateActionText + ')';
            } else {
                return this.Instrument.ShortName + ' (' + corporateActionText + ')' + ':' + this.Instrument.RouteName;
            }
        } else {
            return displayName;
        }
    }

    public GetInstrumentDescriptionValue (): string {
        const descriptionValue = this.Instrument.DescriptionValue();
        if (this.CorporateActionType !== null && CorporateActionTypeMap[this.CorporateActionType]) {
            const corporateActionText = Resources.getResource('corporateActionType.' + CorporateActionTypeMap[this.CorporateActionType]);
            return descriptionValue + ' (' + corporateActionText + ')';
        } else {
            return descriptionValue;
        }
    }

    public GetOrderEditRequestData (): OrderEditRequestData {
        const data = new OrderEditRequestData();

        Object.assign(data, {
            position: this,
            instrument: this.Instrument,
            account: this.Account,
            quantity: new Quantity(this.Amount, true),
            side: this.BuySell,
            productType: this.ProductType,
            leverageValue: this.Leverage
        });

        return data;
    }

    // тут ли им место? или в OrderExecutor? чи куды???
    public static OPTION_EXERCISE_STATUS = {
        PENDING_EXERCISE: 0,
        EXERCISED: 1,
        CANCELLED: 2,
        BLANK: -1
    };
}

// #region ServerCalculation

export class ServerCalculation {
    public Exposure: any;
    public PositionValue: any;
    public NetPL: any;
    public GrossPL: any;
    public CurPriceClose: any;

    constructor (curPriceClose, exposure, positionValue, netPL, grossPL) {
        this.Exposure = exposure;
        this.PositionValue = positionValue;
        this.NetPL = netPL;
        this.GrossPL = grossPL;
        this.CurPriceClose = curPriceClose;
    }
}

// #endregion ServerCalculation

export enum CorporateActionType {
    BonusIssue = 1,
    CashDividends = 2,
    StockDividends = 3,
    SpinOff = 5,
    RightsIssue = 6

}

export const CorporateActionTypeMap =
{
    1: 'BonusIssue',
    2: 'CashDividends',
    3: 'StockDividends',
    5: 'SpinOff',
    6: 'RightsIssue'
};
