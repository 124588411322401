// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataBuffer } from '../DataBuffer';
import { PFixFieldSet } from './FieldsImport';
import { PFixGroupField } from './PFixGroupField';

export class PFixLongGroupField extends PFixGroupField {
    public override ReadValue (buffer): void {
        const id = buffer.ReadInt32();
        const fieldSet = new PFixFieldSet(id);

        const size = buffer.ReadInt32();
        const groupBuffer = buffer.ReadBuffer(size);

        fieldSet.Read(groupBuffer);
        this.Value = fieldSet;
    }

    public override Write (buffer, maxServerNumber): void {
        const fieldSet = this.Value;

        buffer.WriteShort(this.FieldId);
        buffer.WriteInt32(fieldSet.GroupId);// write group name

        const groupBuffer = new DataBuffer();
        fieldSet.Write(groupBuffer, maxServerNumber);

        buffer.WriteInt32(groupBuffer.Length);
        buffer.WriteBuffer(groupBuffer);
    }
}
