// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../CustomEvents';

export class WatchlistSheetItem {
    public static symbolsChanged = new CustomEvent();

    public name: string;
    private _symbolIds = new Set<string>();

    constructor (name: string, symbolIds = new Set<string>()) {
        this.name = name;
        this._symbolIds = symbolIds;
    }

    public getSymbolIds (): string[] {
        return Array.from(this._symbolIds);
    }

    public has (symbolId: string): boolean {
        return this._symbolIds.has(symbolId);
    }

    public add (symbolId: string): void {
        if (!this._symbolIds.has(symbolId)) {
            this._symbolIds.add(symbolId);
            WatchlistSheetItem.symbolsChanged.Raise();
        }
    }

    public delete (symbolId: string): void {
        if (this._symbolIds.has(symbolId)) {
            this._symbolIds.delete(symbolId);
            WatchlistSheetItem.symbolsChanged.Raise();
        }
    }

    public set (symbolIds: Set<string>): void {
        this._symbolIds = symbolIds;
        WatchlistSheetItem.symbolsChanged.Raise();
    }

    public clear (): void {
        if (this._symbolIds.size !== 0) {
            this._symbolIds.clear();
            WatchlistSheetItem.symbolsChanged.Raise();
        }
    }

    public serialize (): string {
        return JSON.stringify(this);
    }

    public static deserialize (json: string): WatchlistSheetItem {
        const obj = JSON.parse(json);
        return new WatchlistSheetItem(obj.name, obj.symbolIds);
    }
}
