// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export const KEY_ShortCommissionInfo = 'InstrumentDetailsPanel.ShortCommissionInfo';
export const KEY_OptionExercise = 'InstrumentDetailsPanel.OptionExercise';
export const KEY_CustodialFee = 'InstrumentDetailsPanel.CustodialFee';
export const KEY_ShortPositionInterest = 'InstrumentDetailsPanel.ShortPostionInterest';
export const KEY_FILLPERLOT = 'InstrumentDetailsPanel.PerLot';
export const KEY_ORDERPERLOT = 'InstrumentDetailsPanel.OrderPerLot';
export const KEY_PERFILL = 'InstrumentDetailsPanel.PerFill';
export const KEY_PERTRANSACTION = 'InstrumentDetailsPanel.PerTransaction';
export const KEY_PERPHONETRANSACTION = 'InstrumentDetailsPanel.PerPhoneTransaction';
export const KEY_PERVOLUME = 'InstrumentDetailsPanel.PerVolume';
export const KEY_PERORDERVOLUME = 'InstrumentDetailsPanel.PerOrderVolume';
export const KEY_VAT = 'InstrumentDetailsPanel.VAT';
export const KEY_VOLUME_WITH_MIN_PD = 'InstrumentDetailsPanel.VolumeWithMinPD';
export const KEY_VOLUME = 'InstrumentDetailsPanel.Volume';
export const KEY_ORDER_VOLUME = 'InstrumentDetailsPanel.OrderVolume';
export const KEY_CARE_ORDER = 'InstrumentDetailsPanel.CareOrder';
