// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TimeSpanPeriods } from '../../../../../Utils/Time/TimeSpan';
import { Resources } from '../../../../../Localizations/Resources';
import { BaseSettings } from '../../../../Settings/BaseGeneralSettingsWrapper';
import { BaseOrderWarning } from './BaseOrderWarning';

export class IdenticalOrderWarning extends BaseOrderWarning {
    public getText (): string {
        if (!this.isIdenticalWithPrevious()) {
            return '';
        }

        const text = Resources.getResource('general.WarnIfIdenticalOrdertText', [BaseSettings.warnIfIdenticalOrderTime.toString()]);

        return text;
    }

    private isIdenticalWithPrevious (): boolean {
        if (!BaseSettings.warnIfIdenticalSubsequentOrderIsPlaced) {
            return false;
        }

        const prevOrder = this.orderEdit.previousOrderJSON;
        const thisOrder = this.orderEdit.getBaseCopyInJson();
        const thisOrderTime = Date.now();
        const timeLessToWarn = this.orderEdit.previousOrderDate &&
                (thisOrderTime - this.orderEdit.previousOrderDate < BaseSettings.warnIfIdenticalOrderTime * TimeSpanPeriods.TicksPerSecond);

        const needToShowWarning = prevOrder === thisOrder && timeLessToWarn;

        this.orderEdit.previousOrderJSON = thisOrder;
        this.orderEdit.previousOrderDate = thisOrderTime;

        return needToShowWarning;
    }
}
