// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FollowerRequestStatus } from '../Account/FollowerRequestStatus';
import { type IAccountFollower } from '../Interfaces/Response/IAccountFollower';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

/**
 * Represents a response message for an account follower action.
 * Implements the IAccountFollower interface to structure response data.
 */
export class DirectAccountFollowerResponseMessage extends DirectMessageBaseClass implements IAccountFollower {
    /**
     * The status code of the follower request.
     * Maps to FIELD_FOLLOWER_REQUEST_STATUS (942).
     */
    public Status: FollowerRequestStatus = FollowerRequestStatus.UNKNOWN;

    /**
     * The ID of the master account.
     * Maps to FIELD_MASTER_ID (943).
     */
    public MasterId: number | null = null;

    /**
     * The IDs of the follower accounts.
     * Maps to FIELD_FOLLOWER_IDS (945).
     */
    public FollowerIDs: number[] = [];

    /**
     * The ID of the account that caused the rejection.
     * Maps to FIELD_REJECT_ACCOUNT_ID (954).
     */
    public RejectAccountID: number | null = null;

    /**
     * Constructs a new DirectAccountFollowerResponseMessage.
     * Initializes the message code to CODE_PFIX_ACCOUNT_FOLLOWER_RESP (523).
     */
    constructor () {
        super(Message.CODE_PFIX_ACCOUNT_FOLLOWER_RESP);
    }

    public get IsReject (): boolean {
        return [
            FollowerRequestStatus.REJECT,
            FollowerRequestStatus.ALREADY_FOLLOWING_ANOTHER_MASTER,
            FollowerRequestStatus.MASTER_ACCOUNT_CANNOT_BE_FOLLOWER
        ].includes(this.Status);
    }
}
