// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { HelloMessage } from '../Messages/HelloMessage';
import { MessageProcessorBase } from './MessageProcessorBase';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class HelloMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): HelloMessage[] {
        return [new HelloMessage(fieldSet)];
    }
}
