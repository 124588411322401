// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/// настройка конкретной комиссии (по типу)

import { type CommissionPaymentType, type CommissionTypes } from '../../../Utils/Commission/CommissionEnums';
import { type RevenueCommissionValueItem } from './RevenueCommissionValueItem';

export class RevenueCommissionItem {
    public Type: CommissionTypes | null = null;
    public PaymentType: CommissionPaymentType | null = null;
    public CounterAccountID: number | null = null;
    public IsSetIB: boolean | null = null;
    public ApplyOperationType: string | null = null;

    public CommissionValueItems: RevenueCommissionValueItem[] = [];

    public GetCommission (param): number {
        let value = 0;

        if (this.Type != param.CommissionType) {
            return value;
        }

        this.CommissionValueItems.forEach(function (valueItem) {
            value += valueItem.GetCommission(param);
        });

        return value;
    }

    public NeedDifferent_BuySell_Short (): boolean {
        const items = this.CommissionValueItems;

        if (items.length > 2) {
            for (let i = 1; i < items.length; i++) {
                const valItem = items[i];
                if (valItem.OperationType != items[0].OperationType) { return true; }
            }
        }

        if (items.length == 2 && items[0].OperationType != items[1].OperationType && items[0].Value != items[1].Value) {
            return true;
        }

        return false;
    }

    public AreEqualBuyOpenCloseValues (): boolean {
        // if (CommissionValueItems != null && CommissionValueItems.Count == 2 &&
        //     (Type == CommissionTypes.PerLot || Type == CommissionTypes.OrderPerLot || Type == CommissionTypes.PerVolume || Type == CommissionTypes.PerOrderVolume) &&
        //     CommissionValueItems[0].OperationType == CommissionOpearationType.OPEN && CommissionValueItems[1].OperationType == CommissionOpearationType.CLOSE &&
        //     (CommissionValueItems[0].Value == CommissionValueItems[1].Value))
        //     return true;

        return false;
    }

    public ContainsShort (): boolean {
        const items = this.CommissionValueItems;

        for (let i = 0; i < items.length; i++) {
            if (items[i].IsShort) {
                return true;
            }
        }

        return false;
    }

    public ContainsOpenClose (): boolean {
        const items = this.CommissionValueItems;

        if (items.length > 1) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].IsOpenOrClose) {
                    return true;
                }
            }
        };

        return false;
    }

    public ContainsBuySellShort (): boolean {
        const items = this.CommissionValueItems;

        for (let i = 0; i < items.length; i++) {
            if (items[i].IsBuySellShort) {
                return true;
            }
        }

        return false;
    }

    public IsSingleField (): boolean {
        if (this.NeedDifferent_BuySell_Short() || this.AreEqualBuyOpenCloseValues()) {
            return false;
        }

        return true;
    }

    public IsShowOpenClose (): boolean {
        let isShowOpenClose = false;

        if (!this.NeedDifferent_BuySell_Short() && this.ContainsOpenClose()) {
            isShowOpenClose = true;
        }

        if (this.AreEqualBuyOpenCloseValues()) {
            isShowOpenClose = true;
        }

        return isShowOpenClose;
    }

    public GetShowFormat (): any {
        let isSingleField = true;
        let isShowOpenClose = false;

        if (this.NeedDifferent_BuySell_Short()) // buy/sell & short
        {
            isSingleField = false;
        }

        if (!isSingleField && this.ContainsOpenClose()) {
            isShowOpenClose = true;
        }

        if (this.AreEqualBuyOpenCloseValues()) {
            isSingleField = false;
            isShowOpenClose = true;
        }
        return { isSingleField, isShowOpenClose };
    }

    public GetLotsListForType (outLotsArray, commissionType: CommissionTypes, operationType): void {
        if (this.Type != commissionType) {
            return;
        }

        const items = this.CommissionValueItems;
        for (let i = 0; i < items.length; i++) {
            items[i].GetLotsListForType(outLotsArray, operationType);
        }
    }
}
