// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { BaseRowDataItem, RowColorText, RowIndexNames } from './BaseRowDataItem';

export class OrderRowDataItem extends BaseRowDataItem {
    public static readonly COLUMNAMOUNT = 5;

    public getColumnAmount (): number {
        return OrderRowDataItem.COLUMNAMOUNT;
    }

    public override getValue (index: RowIndexNames): any {
        const source = this.source;
        switch (index) {
        case RowIndexNames.NAME:
            return source.Symbol;

        case RowIndexNames.DESCRIPTION:
            return source.Description;

        case RowIndexNames.IMG:
            return source.Img;

        case RowIndexNames.COLUMN_ONE:
            return source.Side;

        case RowIndexNames.COLUMN_TWO:
            return source.Quantity;

        case RowIndexNames.COLUMN_THREE:
            return source.Order_Type;

        case RowIndexNames.COLUMN_FOUR:
            return source.Price;

        default:
            return null;
        }
    }

    public override getColor (index: RowIndexNames): RowColorText {
        const source = this.source;
        switch (index) {
        case RowIndexNames.COLUMN_ONE:
            if (source.Side === 'Long') {
                return RowColorText.BLUE;
            } else {
                return RowColorText.RED;
            }

        case RowIndexNames.COLUMN_TWO:
            return RowColorText.GREEN;

        case RowIndexNames.COLUMN_THREE:
            return RowColorText.PURPLE;

        case RowIndexNames.COLUMN_FOUR:
            return RowColorText.GREEN;

        default:
            return RowColorText.BLACK;
        }
    }
}
