// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { LocalStorage } from '../../Commons/LocalStorage';

export class BrokerMessageCustomButton {
    public fTitle: string | null = null;
    public fTooltip: string | null = null;
    public fURL: string | null = null;
    public fAliases: any = null;
    public fCustomButtonNumber: number;

    get Title (): any {
        let result = this.fTitle;
        if (this.fAliases) {
            const alias = this.fAliases[LocalStorage.getLanguage()];
            if (alias?.Name) {
                result = alias.Name;
            }
        }
        return result;
    }

    get Tooltip (): any {
        let result = this.fTooltip;
        if (this.fAliases) {
            const alias = this.fAliases[LocalStorage.getLanguage()];
            if (alias?.Description) {
                result = alias.Description;
            }
        }
        return result;
    }

    get URL (): any {
        return this.fURL;
    }

    get TitleForResponse (): any {
        return this.fTitle;
    }
}
