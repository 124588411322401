// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { CommissionItem } from './CommissionItem';
import { RevenueCommissionType } from '../../../Utils/Enums/Constants';
import { type Instrument } from '../Instrument';
import { type Account } from '../Account';

export class CommissionPlan {
    public Id: any = null;
    public Name: any = null;
    public Description: any = null;

    public CommisionForTransfer: any = null;

    public CounterAccountID: any = null;

    public TransferCommisionType = RevenueCommissionType.None;
    public WithdrawalCommisionType = RevenueCommissionType.None;

    public TransferPercentage: any = null;
    public WithdrawalPercentage: any = null;
    public TransferFixedFee: any = null;
    public WithdrawalFixedFee: any = null;
    public TransferMinFee: any = null;
    public WithdrawalMinFee: any = null;
    public TransferMaxFee: any = null;
    public WithdrawalMaxFee: any = null;
    public TransferCurrencyId: any = null;
    public WithdrawalCurrencyId: any = null;
    public TransferAccountId: any = null;
    public WithdrawalAccountId: any = null;
    public DCache: any;

    private _itemsByInstrumentId: any = {};
    private _itemsByType: any = {};
    private _defaultCommissionItem: CommissionItem | null = null;
    private _itemsByInstrumentIdHasValue = false;
    private _itemsByTypeHasValue = false;

    constructor (dCache) {
        this.DCache = dCache;
    }

    public Update (msg): void {
        this.Id = msg.Id;
        this.Name = msg.Name;
        this.Description = msg.Description;
        this.CommisionForTransfer = msg.CommisionForTransfer;
        this.CounterAccountID = msg.CounterAccountID;

        this.TransferCommisionType = msg.TransferCommisionType;
        this.WithdrawalCommisionType = msg.WithdrawalCommisionType;

        this.TransferPercentage = msg.TransferPercentage;
        this.WithdrawalPercentage = msg.WithdrawalPercentage;
        this.TransferFixedFee = msg.TransferFixedFee;
        this.WithdrawalFixedFee = msg.WithdrawalFixedFee;
        this.TransferMinFee = msg.TransferMinFee;
        this.WithdrawalMinFee = msg.WithdrawalMinFee;
        this.TransferMaxFee = msg.TransferMaxFee;
        this.WithdrawalMaxFee = msg.WithdrawalMaxFee;

        this.TransferCurrencyId = msg.TransferCurrencyId;
        this.WithdrawalCurrencyId = msg.WithdrawalCurrencyId;
        this.TransferAccountId = msg.TransferAccountId;
        this.WithdrawalAccountId = msg.WithdrawalAccountId;

        const items = msg.CommissionItems;
        for (let i = 0; i < items.length; i++) {
            this.AddItem(items[i]);
        }
    }

    public AddItem (data): void {
        const item = new CommissionItem(data);
        const insID = data.InstrumentId;
        const insTypeID = data.InstrumentTypeId;
        if (insID) {
            if (!this._itemsByInstrumentId[insID]) {
                this._itemsByInstrumentId[insID] = item;
            } else {
                this._itemsByInstrumentId[insID].Add(data);
            }

            this._itemsByInstrumentIdHasValue = true;
        } else if (insTypeID !== -1) {
            if (!this._itemsByType[insTypeID]) {
                this._itemsByType[insTypeID] = item;
            } else {
                this._itemsByType[insTypeID].Add(data);
            }

            this._itemsByTypeHasValue = true;
        } else {
            if (!this._defaultCommissionItem) {
                this._defaultCommissionItem = item;
            } else {
                this._defaultCommissionItem.Add(data);
            }
        }
    }

    public GetCommissionItem (instrument: Instrument): CommissionItem | null // получение CommissionItem по инструменту
    {
        if (isNullOrUndefined(instrument)) {
            return null;
        }

        let item = this._itemsByInstrumentId[instrument.Id];
        if (item) {
            return item;
        }

        let typeID = instrument.TypeId;
        while (typeID != -1) {
            item = this._itemsByType[typeID];
            if (item) {
                return item;
            }

            const insType = this.DCache.getInstrumentTypeById(typeID);
            typeID = insType != null ? insType.SuperTypeId : -1; // поищем определяющий CommissionItem в инстр.группах-предках, пока не упремся в корневую
        }

        return this._defaultCommissionItem;
    }

    public AddFeeItems (itemsForTable, ins: Instrument): void {
        const item = this.GetCommissionItem(ins);

        if (item) {
            item.AddFeeItems(itemsForTable);
        }
    }

    public GetAllowedCommissionTypes (ins: Instrument): any {
        const it = this.GetCommissionItem(ins);
        const res = {};
        if (!it) {
            return res;
        }
        for (let i = 0; i < it.Entry.length; i++) {
            const ent = it.Entry[i];
            const ci = ent.CommissionItems;
            for (let j = 0; j < ci.length; j++) {
                res[ci[j].Type] = true;
            }
        }
        return res;
    }

    public static TryGetCommissionDataValue (feeCurrency, key: string, instrument: Instrument, account: Account | null): string {
        if (isNullOrUndefined(account?.CommissionPlan)) {
            return null;
        }

        const commissionItem = account.CommissionPlan.GetCommissionItem(instrument);
        if (isNullOrUndefined(commissionItem)) {
            return null;
        }

        return commissionItem.GetCommissionDataValue(feeCurrency, key);
    }
}
