// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { AssetBalanceItem } from '../cache/AssetBalanceItem';
import { QuickTableRactiveEvents } from '../elements/QuickTable/QuickTableRactive';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { AccountType } from '../../Utils/Account/AccountType';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { CustomerAccess } from '../../Commons/CustomerAccess/CustomerAccess';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type Account } from '../../Commons/cache/Account';

export class AssetBalancesPanel extends ApplicationPanelWithTable<AssetBalanceItem> {
    public headerLocaleKey: string = 'panel.assetBalances';

    public override getType (): PanelNames { return PanelNames.AssetBalancesPanel; }

    public override oncomplete (): void {
        super.oncomplete();
        const qt = this.getQuickTable();
        qt.OnSelectionChanged.Subscribe(this.onSelectionChanged, this);
        qt.skipAccountFiltration = true; // #120237
        this.quickTableRactive.on(QuickTableRactiveEvents.DoubleClick, this.onDoubleClick.bind(this));
    }

    public createContextMenu (): any[] {
    // if (!selectedAccountId)
        return [];
    }

    public onSelectionChanged (): void {
        const accountID = this.getSelectedAccountId();
        this.getQuickTable().setTableContextMenuItems(this.createContextMenu());
        if (isValidString(accountID)) {
            this.accountLink_Out(false, DataCache.GetAccountById(accountID));
        }
    }

    public onViewReportClicked (): void {
        const selAccId = this.getSelectedAccountId();
        MainWindowManager.Factory.addPanel(
            PanelNames.ReportsPanel,
            null,
            function (panel) { panel.accountLink_In(selAccId); });
    }

    public onViewAccDetailsClicked (): void {
        this.openAccDetails();
    }

    public openAccDetails (): void {
        if (!CustomerAccess.panelAllowed(PanelNames.AccountDetailsPanel)) {
            return;
        }

        const data = this.getSelectedAccountAndAssetId();
        if (isNullOrUndefined(data)) return;

        MainWindowManager.Factory.addPanel(
            PanelNames.AccountDetailsPanel,
            null,
            function (panel) {
                panel.accountLink_In(data.accountId);
                panel.selectAsset(data.assetId);
            });
    }

    public getSelectedAccountAndAssetId (): any | null {
        const qt = this.getQuickTable();
        const selectedRowsIds = qt.selectedRowIds;
        if (!isValidArray(selectedRowsIds)) {
            return null;
        }

        const firstSelectedRowId = selectedRowsIds[0];
        if (isNullOrUndefined(firstSelectedRowId)) {
            return null;
        }

        const row = qt.rows[firstSelectedRowId];

        return {
            accountId: row.item.Account,
            assetId: row.item.AssetBalance.Asset.Id
        };
    }

    public getSelectedAccountId (): string {
        const qt = this.getQuickTable();
        const selectedRowsId = qt.selectedRowIds;
        if (!isValidArray(selectedRowsId)) return;

        let accId = '';

        if (selectedRowsId.length > 0) {
            const row = qt.rows[selectedRowsId[0]];
            const item = !isNullOrUndefined(row) ? row.item : null;
            if (isNullOrUndefined(item)) {
                const acc = item.GetCurrentAccount();
                if (!isNullOrUndefined(acc)) {
                    accId = acc.BstrAccount;
                }
            }
        }

        return accId;
    }

    public override localize (): void {
        super.localize();
        this.getQuickTable().setTableContextMenuItems(this.createContextMenu());
    }

    public override populateItemsDirect (): void {
        super.populateItemsDirect();
        const accs = DataCache.Accounts;
        const accsKeys = Object.keys(accs);
        for (let i = 0; i < accsKeys.length; i++) {
            const acc = accs[accsKeys[i]];
            for (const key in acc.assetBalances) {
                const asd = acc.assetBalances[key];
                if (acc.AccountType !== AccountType.MultiAsset || !acc.assetBalanceDefault.IsEmpty) {
                    this.getQuickTable().AddItem(new AssetBalanceItem(acc, SessionSettings, asd, asd.Asset));
                }
            }
        }

        DataCache.OnAddAccount.Subscribe(this.DataCacheOnAddAccountEvent, this);
        DataCache.OnRemoveAccount.Subscribe(this.DataCacheOnRemoveAccountEvent, this);
    }

    public override SetColumnsDefaultDisplayIndex (table): void {
        table.columns[1].displayedIndex = 0;
        table.columns[64].displayedIndex = 1;
        table.columns[2].displayedIndex = 2;
        table.columns[20].displayedIndex = 3;
        table.columns[42].displayedIndex = 4;
        table.columns[32].displayedIndex = 5;
        table.columns[33].displayedIndex = 6;
        table.columns[67].displayedIndex = 7;
        table.columns[24].displayedIndex = 8;
    }

    public override updatePanelHeader (): void {
        void this.set({ header: Resources.getResource(this.headerLocaleKey) + (this.NeedCalculateRowCount ? ('  (' + this.rowCount + ')') : '') });
    }

    public override filterByAccountNumber (): void {

    }

    public override dispose (): void {
        DataCache.OnAddAccount.UnSubscribe(this.DataCacheOnAddAccountEvent, this);
        DataCache.OnRemoveAccount.UnSubscribe(this.DataCacheOnRemoveAccountEvent, this);
        super.dispose();
    }

    public DataCacheOnAddAccountEvent (acc: Account): void {
        if (isNullOrUndefined(acc) || isNullOrUndefined(this.quickTableRactive) || isNullOrUndefined(this.getQuickTable())) {
            return;
        }

        const quickTable = this.getQuickTable();
        for (const key in acc.assetBalances) {
            const row = quickTable.getItemById(acc.AcctNumber + ':' + key);
            if (row != null) {
                row.updateItem(row.item);
            } else {
                const aBal = acc.assetBalances[key];
                if (!aBal.IsEmpty) {
                    quickTable.AddItem(new AssetBalanceItem(acc, SessionSettings, aBal, aBal.Asset));
                }
            }
        }
    }

    public DataCacheOnRemoveAccountEvent (acc: Account): void {
        this.getQuickTable().RemoveItem(acc.userID);
    }
}

ApplicationPanelWithTable.extendWith(AssetBalancesPanel,
    {
        data: function () { return { isAccountLinkShow: true, canFilterByAccount: false }; },
        headerLocaleKey: 'panel.assetBalances'
    });
