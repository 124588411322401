// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class SI extends IndicatorScriptBase {
    public T: number;

    private atrIndicator: iBuildInIndicator;

    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'Swing Index';
        this.Comments = 'Is used to confirm trend line breakouts on price charts';
        // #endregion

        this.SetIndicatorLine('SI', Color.Red, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.T = 300;
        super.InputParameter(new InputParameterInteger('T', 'T', 0, 1, 99999, 0, 1));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.UpdateIndexDrawBegin();
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.atrIndicator = this.Indicators.iATR(this.CurrentData, 1);
        return [this.atrIndicator];
    };

    public override GetIndicatorShortName (): string {
        return 'SI(' + this.T + ')';
    };

    public override getCustomName (): string {
        return 'SI (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count < 2) { return; };

        const prevOpen = this.CurrentData.GetPrice(PriceType.Open, 1);
        const prevClose = this.CurrentData.GetPrice(PriceType.Close, 1);
        const open = this.CurrentData.GetPrice(PriceType.Open, 0);
        const high = this.CurrentData.GetPrice(PriceType.High, 0);
        const low = this.CurrentData.GetPrice(PriceType.Low, 0);
        const close = this.CurrentData.GetPrice(PriceType.Close, 0);

        let ER = 0;
        if (prevClose >= low && prevClose <= high) { ER = 0; } else {
            if (prevClose > high) { ER = Math.abs(high - prevClose); }
            if (prevClose < low) { ER = Math.abs(low - prevClose); }
        }
        const K = Math.max(Math.abs(high - prevClose), Math.abs(low - prevClose));
        const SH = Math.abs(prevClose - prevOpen);
        const R = this.atrIndicator.GetValue() - 0.5 * ER + 0.25 * SH;

        let si = 0;
        if (Math.abs(R) >= 0.000001) { si = 50 * (close - prevClose + 0.5 * (close - open) + 0.25 * (prevClose - prevOpen)) * K / (this.T * 0.0001) / R; };

        const prevValue = this.GetValue(0, 1) === Number.MAX_VALUE ? 0 : this.GetValue(0, 1);
        this.SetValue(0, 0, prevValue + si);
    };
}
