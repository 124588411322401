// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Rectangle } from '@shared/commons/Geometry';
import { Cursors } from '@shared/commons/Cursors';
import { DrawPointer, DrawPointerTypeEnum } from '../../Utils/DrawPointer';
import { Brushes } from '@shared/commons/Graphics';
import { MovingType, ProMath, ToolView } from '../ToolView';
import { SelectionState, ToolSelectionType } from '../Selection';
import { Resources } from '@shared/localizations/Resources';
import { TerceraChartTradingOperation } from '../../Utils/ChartConstants';
import { StopLimitOrderToolView } from './StopLimitOrderToolView';
import { TradingToolViewBase, TradingToolViewType } from './TradingToolViewBase';

import { OperationType } from '@shared/utils/Trading/OperationType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { TradingLockUtils } from '@shared/utils/TradingLockUtils';
import { IsAllowed } from '@shared/commons/IsAllowed';
import { type Order } from '@shared/commons/cache/Order';
import { type TerceraChartCashItemSeries } from '../../Series/TerceraChartCashItemSeries';
import { BaseSettings } from '@shared/commons/Settings/BaseGeneralSettingsWrapper';
import { OffsetModeViewEnum } from '@shared/utils/Trading/OffsetModeViewEnum';
import { type Position } from '@shared/commons/cache/Position';

export class StopLossLimitToolView extends TradingToolViewBase {
    public parentY = -1;
    public parentValue = NaN;
    public errorMode = false;
    public TradeWasModified = false;
    public priceValue = NaN;
    public priceY = -1;

    public lastValidParentValue = NaN;
    public lastValidPriceValue = NaN;

    public parentOrder: Order;
    public sltpOrder: any;
    public GetToolViewByOrderId: any;
    public terceraChartPanelContext: any;

    constructor (order, sltpOrder, getToolViewByOrderId, terceraChartPanelContext, renderer) {
        super();

        this.collapsed = true;

        this.parentOrder = order;
        this.sltpOrder = sltpOrder;
        this.GetToolViewByOrderId = getToolViewByOrderId;
        this.terceraChartPanelContext = terceraChartPanelContext;
        this.myRenderer = renderer;

        if (this.parentOrder != null) {
            const isPosition = !!this.parentOrder.isPosition;
            const ID = isPosition ? this.parentOrder.PositionId : this.parentOrder.OrderNumber;
            const type = isPosition ? TradingToolViewType.PositionToolView : TradingToolViewType.OrderToolView;
            const parentTool = this.GetToolViewByOrderId(ID, type);
            if (parentTool != null) {
                this.collapsed = parentTool.Collapsed;
            }
        }

        this.movingType = MovingType.OnlyY;
        this.ToolViewType = TradingToolViewType.StopLossLimitToolView;

        this.Updated();
    }

    public AllowCancelOrder (): boolean {
        return IsAllowed.IsOrderCancelingAllowed(this.sltpOrder).Allowed;
    }

    public AllowTS (): boolean {
        if (this.parentOrder.IsActivated) {
            return false;
        } // comment in #109958

        return IsAllowed.IsTradingAllowed([this.parentOrder.Account], this.parentOrder.Instrument, OrderType.TrailingStop).Allowed;
    }

    public AllowModify (): boolean {
        return (IsAllowed.IsOrderModifyingAllowed(this.sltpOrder).Allowed || this.sltpOrder === null && IsAllowed.IsOrderModifyingAllowed(this.parentOrder).Allowed) && IsAllowed.IsOrderSLTPAllowed(this.parentOrder).Allowed;
    }

    override get Collapsed (): boolean { return this.collapsed; }
    override set Collapsed (value) {
        if (this.collapsed != value) {
            this.collapsed = value;
            if (this.parentOrder != null) {
                const isPosition = !!this.parentOrder.isPosition;
                const ID = isPosition ? this.parentOrder.PositionId : this.parentOrder.OrderNumber;
                const type = isPosition ? TradingToolViewType.PositionToolView : TradingToolViewType.OrderToolView;
                this.GetToolViewByOrderId(ID, type).Collapsed = this.collapsed;
            }
        }
    }

    override get ID (): string {
        return this.parentOrder.OrderNumber + '-SLL';
    }

    override get Price (): number {
        const order = this.parentOrder;

        if (order?.isPosition && this.sltpOrder) {
            return this.sltpOrder.Price;
        }

        return order ? order.GetStopLossLimitInPriceValue() : NaN;
    }

    get Side (): OperationType {
        if (this.sltpOrder != null) {
            return this.sltpOrder.BuySell;
        }

        return this.parentOrder.BuySell === OperationType.Buy ? OperationType.Sell : OperationType.Buy;
    }

    public override Updated (): void {
        let parentOrder = this.parentOrder;
        const isPositionSL = parentOrder?.isPosition;

        if (isPositionSL) {
            parentOrder = this.sltpOrder;
        }

        if (!parentOrder) return;

        const noStopLoss = isPositionSL ? parentOrder.Price === null : isNaN(parentOrder.StopLossPriceValue);

        if (noStopLoss) {
            return;
        }

        this.dataCacheTool.Points[0][0] = parentOrder.UTCDateTime;
        this.dataCacheTool.Points[0][1] = this.Price;
        if (!this.CurrentMovement.IsMovingRightNow) {
            this.CancelMoving();
        }
    }

    public isTrailingStop (): boolean {
        return false;
    }

    public override CancelMoving (): void {
        this.TradeWasModified = false;
        this.ResetMove();
        this.ResetMouse();
    }

    public override Draw (gr, window, param): void {
        this.MainOrderVisibility = false;
        if (!this.myRenderer.ShowOrders) {
            return;
        }

        if (this.parentOrder && !this.parentOrder.Price) {
            return;
        }

        if (this.sltpOrder && this.sltpOrder.OrderType === OrderType.TrailingStop) {
            return;
        } // #93794

        try {
            const CR: Rectangle = window.ClientRectangle;

            const minX = CR.X;
            const maxX = minX + CR.Width;

            let screenPoints00 = this.screenPoints[0][0];
            if (screenPoints00 < minX + 9) { screenPoints00 = minX + 9; }

            const offsetX = minX + 15 + (this.parentOrder.OrderType === OrderType.StopLimit ? StopLimitOrderToolView.LIMIT_OFFSET + 12 : minX + 15);
            if (this.myRenderer.ShowVisualTradingOnLeftSide) {
                screenPoints00 = offsetX;
            } else {
                screenPoints00 += offsetX;
            }

            let screenPoints01 = this.screenPoints[0][1];
            if (!isNaN(this.priceValue)) {
                screenPoints01 = Math.floor(this.priceY);
            }

            // Ловим ошибки
            if ((screenPoints00 < 0 && maxX < 0) || screenPoints01 >= ProMath.infinity || screenPoints01 >= ProMath.infinity || screenPoints01 <= ProMath.infinityMinus || screenPoints01 <= ProMath.infinityMinus) {
                return;
            }

            this.MainOrderVisibility = true;

            const hoveredOrSelected = this.CurrentSelection.CurrentState != SelectionState.None;
            // оптимизация - вне не рисуем ордер и не добавляем его плашку
            this.iconRect.X = screenPoints00 - 8;
            this.iconRect.Y = screenPoints01 - 8;
            if (CR.Contains(screenPoints00, screenPoints01)) {
                const orderIsBuy = this.Side === OperationType.Buy;

                let p = null;
                if (!orderIsBuy) {
                    p = this.TradeWasModified ? TradingToolViewBase.orderGrayPen : this.myRenderer.orderSellPen;
                } else {
                    p = this.TradeWasModified ? TradingToolViewBase.orderGrayPen : this.myRenderer.orderBuyPen;
                }

                let im = null;

                if (this.TradeWasModified) {
                    im = this.Collapsed ? TradingToolViewBase.close_SLL_gray_defaultImage : TradingToolViewBase.open_SLL_gray_defaultImage;
                } else {
                    if (!this.Collapsed) {
                        im = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_SLL_open_hoverImage : TradingToolViewBase.buy_SLL_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_SLL_open_hoverImage : TradingToolViewBase.sell_SLL_open_defaultImage);
                    } else {
                        im = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_SLL_close_hoverImage : TradingToolViewBase.buy_SLL_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_SLL_close_hoverImage : TradingToolViewBase.sell_SLL_close_defaultImage);
                    }
                }

                let infoRectDelta = 0;
                this.imageRect = new Rectangle(screenPoints00 + 16, screenPoints01 - 11, im.width, im.height);

                if (!this.Collapsed) {
                    const shortDescriptionText = this.DescriptionText();

                    let totalW = Math.floor(gr.measureText(shortDescriptionText).width) + 10;
                    if (totalW < 30) {
                        totalW = 30;
                    }

                    this.infoRect = new Rectangle(this.imageRect.X + this.imageRect.Width, screenPoints01 - 11, totalW, 22);

                    let backgroundBrush = orderIsBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActiveBrush : TradingToolViewBase.buyBackgroundBrush) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActiveBrush : TradingToolViewBase.sellBackgroundBrush);
                    let backgroundPen = orderIsBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.buyBackgroundPen) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActivePen : TradingToolViewBase.sellBackgroundPen);

                    if (this.TradeWasModified) {
                        backgroundBrush = TradingToolViewBase.grayBackgroundBrush;
                        backgroundPen = TradingToolViewBase.grayBackgroundPen;
                    }
                    gr.FillRect(backgroundBrush, this.infoRect.X, this.infoRect.Y, this.infoRect.Width, this.infoRect.Height);
                    gr.DrawLine(backgroundPen, this.infoRect.X, this.infoRect.Y, this.infoRect.X, this.infoRect.Y + this.infoRect.Height);
                    gr.DrawString(shortDescriptionText, TradingToolViewBase.tradeFont, Brushes.White, this.infoRect.X + 5, this.infoRect.Y + this.infoRect.Height / 2, 'left', 'middle');

                    const infoRect = this.infoRect;
                    if (!this.errorMode) {
                        const infoRectR = infoRect.X + infoRect.Width;
                        const isActivated = this.sltpOrder ? this.sltpOrder.IsActivated : false;
                        let curX = infoRectR;
                        //
                        // Trailing stop btn for activated order's sll
                        //
                        if (isActivated && this.AllowTS() && !TradingLockUtils.TradingLock.tradingLocked) {
                            const trailingButton = infoRect.copy();
                            this.trailingButton = trailingButton;
                            trailingButton.Width = 22;
                            trailingButton.X = infoRectR;

                            let trailingImg = null;
                            if (this.TradeWasModified || !this.isTrailingStop()) {
                                if (this.CurrentSelection.CurrentHover === ToolSelectionType.Trailing) {
                                    trailingImg = TradingToolViewBase.TSL_gray_mine_hoverImage;
                                } else {
                                    trailingImg = hoveredOrSelected ? TradingToolViewBase.TSL_gray_hoverImage : TradingToolViewBase.TSL_grayImage;
                                }
                            } else {
                                if (this.CurrentSelection.CurrentHover === ToolSelectionType.Trailing) {
                                    trailingImg = orderIsBuy ? TradingToolViewBase.buy_TSL_mine_hoverImage : TradingToolViewBase.sell_TSL_mine_hoverImage;
                                } else {
                                    trailingImg = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_TSL_hoverImage : TradingToolViewBase.buy_TSL_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_TSL_hoverImage : TradingToolViewBase.sell_TSL_defaultImage);
                                }
                            }

                            if (trailingImg) {
                                gr.drawImage(trailingImg, this.trailingButton.X, this.trailingButton.Y);
                            }
                            gr.DrawLine(backgroundPen, this.trailingButton.X, this.trailingButton.Y, this.trailingButton.X, this.trailingButton.Y + this.trailingButton.Height);

                            curX = trailingButton.X + trailingButton.Width;
                        } else {
                            this.trailingButton = Rectangle.Empty();
                        }

                        //
                        // Remove btn for activated order's sll
                        //
                        if (isActivated && this.AllowCancelOrder() && !TradingLockUtils.TradingLock.tradingLocked && !param.TerceraChart.TerceraChartTradingToolsRenderer.NotTradingContext) {
                            const infoRect = this.infoRect;
                            this.removeButton = infoRect.copy();
                            this.removeButton.X = curX;
                            this.removeButton.Width = 19;

                            let cancelOrderImg = null;
                            if (this.TradeWasModified) {
                                cancelOrderImg = TradingToolViewBase.moveOrder_cancelImage;
                            } else {
                                if (this.CurrentSelection.CurrentHover === ToolSelectionType.Remove) {
                                    cancelOrderImg = orderIsBuy ? TradingToolViewBase.buy_cancel_mine_hoverImage : TradingToolViewBase.sell_cancel_mine_hoverImage;
                                } else {
                                    cancelOrderImg = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_cancel_hoverImage : TradingToolViewBase.buy_cancel_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_cancel_hoverImage : TradingToolViewBase.sell_cancel_defaultImage);
                                }
                            }
                            if (cancelOrderImg) {
                                gr.drawImage(cancelOrderImg, this.removeButton.X, this.removeButton.Y);
                            }
                            gr.DrawLine(backgroundPen, this.removeButton.X, this.removeButton.Y, this.removeButton.X, this.removeButton.Y + this.removeButton.Height);
                        } else {
                            this.removeButton = Rectangle.Empty();
                        }

                        infoRectDelta = this.removeButton.Width + this.trailingButton.Width + infoRect.Width + im.width + 16;
                    } else {
                        infoRectDelta = infoRect.Width + im.width + 16;
                    }
                } else {
                    this.infoRect = Rectangle.Empty();
                    this.removeButton = Rectangle.Empty();
                    this.actionButton = Rectangle.Empty();
                    this.trailingButton = Rectangle.Empty();
                    infoRectDelta = im.width + 16;
                }

                const skipDrawingLine = screenPoints00 + infoRectDelta > maxX - 3;

                // Линия
                if (!skipDrawingLine) {
                    gr.DrawLine(p, screenPoints00 + infoRectDelta, screenPoints01, maxX, screenPoints01);
                }

                // Иконка
                if (im) {
                    gr.drawImage(im, this.imageRect.X, this.imageRect.Y);
                }

                // плашка
                if (!this.Collapsed) {
                    let backGroundImage = this.Side === OperationType.Buy ? TradingToolViewBase.buyBrush : TradingToolViewBase.sellBrush;
                    if (this.TradeWasModified) {
                        backGroundImage = TradingToolViewBase.grayBrush;
                    }

                    let price;
                    if (!isNaN(this.lastValidPriceValue)) {
                        price = this.lastValidPriceValue;
                    } else {
                        price = !isNaN(this.priceValue) ? this.priceValue : this.Price;
                    }
                    const text = this.myRenderer.instrument.formatPrice(price);

                    param.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.VisualTrading, price, backGroundImage, text));
                }
            } else if (this.imageRect.IsEmpty()) {
                this.imageRect = new Rectangle(screenPoints00 + 16, screenPoints01 - 11, 25, 22);
            }
            //
            // Bind line
            //
            if (!this.Collapsed || hoveredOrSelected) {
                let pen = null;
                if (this.parentOrder.BuySell === OperationType.Sell) {
                    pen = this.TradeWasModified ? TradingToolViewBase.orderGrayPen : this.myRenderer.orderSellPen;
                } else {
                    pen = this.TradeWasModified ? TradingToolViewBase.orderGrayPen : this.myRenderer.orderBuyPen;
                }

                if (this.parentY > screenPoints01) {
                    gr.DrawLine(pen, this.imageRect.X - 10, this.imageRect.Y + this.imageRect.Height / 2, this.imageRect.X - 10, this.parentY - this.imageRect.Height / 2);
                    gr.DrawLine(pen, this.imageRect.X - 10, this.imageRect.Y + this.imageRect.Height / 2, this.imageRect.X, this.imageRect.Y + this.imageRect.Height / 2);
                } else {
                    gr.DrawLine(pen, this.imageRect.X - 10, this.imageRect.Y + this.imageRect.Height / 2, this.imageRect.X - 10, this.parentY + this.imageRect.Height / 2);
                    gr.DrawLine(pen, this.imageRect.X - 10, this.imageRect.Y + this.imageRect.Height / 2, this.imageRect.X, this.imageRect.Y + this.imageRect.Height / 2);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public override UpdateScreenPoints (window, cashItemSeries = null): void {
        super.UpdateScreenPoints(window, cashItemSeries);

        if (isNaN(this.parentValue) && isNaN(this.lastValidParentValue)) // для рисования линии связи
        {
            let price;
            if (this.parentOrder.isPosition) {
                const position = this.parentOrder as Position;
                if (!position.IsActivated) {
                    price = position.SLOrder ? position.SLOrder.StopLimit : position.Price;
                } else { // #93540
                    price = position.Price;
                }
            } else {
                price = this.parentOrder.GetStopLossInPriceValue();
            }

            this.parentY = Math.floor(Math.round(window.PointsConverter.GetScreenY(price)));
        }
    }

    // #region mouse
    /// <summary>
    /// Является ли тулза выделенной мышью (сейчас по линиям, но можно и весь полигон)
    /// </summary>
    public override IsSelectCheck (x: number, y: number): boolean {
        if (this.TradeWasModified) // тулза смещена и с сервера не пришло подтверждение не двигаем её
        {
            return false;
        }

        if (!this.myRenderer.ShowOrders) {
            return false;
        }

        const newSelection = this.GetCurrentSelection(x, y);
        return newSelection !== ToolSelectionType.None;
    }

    public override GetCurrentSelection (x: number, y: number): ToolSelectionType {
        let newSelection = ToolSelectionType.None;
        if (this.imageRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Image;
        } else if (this.iconRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Icon;
        } else if (this.actionButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Action;
        } else if (this.removeButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Remove;
        } else if (this.infoRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Info;
        } else if (this.trailingButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Trailing;
        } else if (Math.abs(y - this.screenPoints[0][1]) < ToolView.POINT_DX) {
            newSelection = ToolSelectionType.Line;
        }

        return newSelection;
    }

    public override CheckMinDistanceForStartMoving (x: number, y: number): boolean {
        const mouseDownHover = this.CurrentSelection.MouseDownHover;
        if (this.AllowModify() && (!TradingLockUtils.TradingLock.tradingLocked) && (mouseDownHover === ToolSelectionType.Image || mouseDownHover === ToolSelectionType.Info)) {
            return Math.abs(this.CurrentMovement.PrevY - y) >= this.MinDistanceForStartMoving();
        }
        return false;
    }

    // move
    public override ProcessNewPosition (window, x: number, y: number, cashItemSeries: TerceraChartCashItemSeries = null): void {
        this.TradeWasModified = true;
        // move order
        if (this.AllowModify()) {
            super.ProcessNewPosition(window, x, y, cashItemSeries); // обновляем главного

            this.priceValue = window.PointsConverter.GetDataY(y);
            this.priceY = y;

            if (this.parentOrder.isPosition) {
                const prVal = this.myRenderer.Instrument.RoundPriceToNearestPointSize(this.priceValue);
            // this.errorMode = this.parentOrder.BuySell == OperationType.Buy ? prVal > this.parentOrder.CurPriceClose : prVal < this.parentOrder.CurPriceClose;
            } else {
                const parentPrice = this.parentOrder.Price;
                const prVal = this.myRenderer.Instrument.roundPrice(this.priceValue);
            // this.errorMode = this.parentOrder.BuySell == OperationType.Buy ? prVal >= parentPrice : prVal <= parentPrice;
            }
        }
    }

    // mouse finish
    public override ProcessMoveFinish (): void {
        try {
            super.ProcessMoveFinish();

            if (this.errorMode) {
                this.ResetMove();
                this.Updated();
                return;
            }

            const mouseDownHover = this.CurrentSelection.MouseDownHover;
            if (mouseDownHover === ToolSelectionType.Image || mouseDownHover === ToolSelectionType.Info || mouseDownHover === ToolSelectionType.Line) {
                const ins = this.myRenderer.Instrument;

                const newPrice = ins ? ins.roundPrice(this.priceValue) : this.priceValue;
                const trailing = this.isTrailingStop();
                let delta = newPrice;
                this.lastValidPriceValue = newPrice;
                if (trailing) {
                    // const parentPrice = this.parentOrder.isPosition ? this.parentOrder.CurPriceClose : this.parentOrder.Price;
                    delta = ins ? ins.roundPrice(delta) : delta;
                }
                this.terceraChartPanelContext.ChartVisualTrading(this.parentOrder, {
                    action: TerceraChartTradingOperation.ModifySLL,
                    price: delta,
                    ts: trailing,
                    CancelCallback: this.undoChanges.bind(this)
                });
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        } finally {
            this.ResetMouse();
        }
    }

    // mouse click
    public override ProcessClick (): void {
        if (this.CurrentSelection.MouseDownHover === this.CurrentSelection.CurrentHover) {
            switch (this.CurrentSelection.MouseDownHover) {
            case ToolSelectionType.Image:
            case ToolSelectionType.Info:
                this.Collapsed = !this.Collapsed;
                break;
            case ToolSelectionType.Action:

                if (!TradingLockUtils.TradingLock.tradingLocked) {
                    this.terceraChartPanelContext.ChartVisualTrading(this.sltpOrder, { action: TerceraChartTradingOperation.ActionButton });
                }
                break;
            case ToolSelectionType.Remove:

                if (!TradingLockUtils.TradingLock.tradingLocked) {
                    this.terceraChartPanelContext.ChartVisualTrading(this.parentOrder, { action: TerceraChartTradingOperation.RemoveSL });
                }
                break;

            case ToolSelectionType.Trailing:

                if (!TradingLockUtils.TradingLock.tradingLocked) {
                    const trailing = !this.isTrailingStop();
                    // const parentPrice = this.parentOrder.isPosition ? this.parentOrder.CurPriceClose : this.parentOrder.Price;
                    let delta = this.Price;
                    delta = this.myRenderer.Instrument != null ? this.myRenderer.Instrument.roundPrice(delta) : delta;

                    this.terceraChartPanelContext.ChartVisualTrading(this.parentOrder, { action: TerceraChartTradingOperation.ModifySLL, ts: trailing, price: delta });
                }
                break;

            case ToolSelectionType.Line:
            case ToolSelectionType.None:
            default:
                break;
            }
        }
        this.ResetMouse();
    }

    public override ResetMouse (): void {
        this.CurrentSelection.MouseDownHover = ToolSelectionType.None;

        this.errorMode = false;
        this.priceValue = NaN;
        if (isNaN(this.lastValidPriceValue)) {
            this.priceY = NaN;
        }
    }

    public ResetMove (): void {
        this.lastValidPriceValue = NaN;
        this.lastValidParentValue = NaN;
        this.priceY = -1;
        this.TradeWasModified = false;
    }

    // TODO. Rename. Refactor.
    public undoChanges (): void {
        this.ResetMove();
        this.Updated();
    }

    public override GetCursor (e): Cursors {
        const curElement = this.CurrentSelection.MouseDownHover === ToolSelectionType.None ? this.CurrentSelection.CurrentHover : this.CurrentSelection.MouseDownHover;

        switch (curElement) {
        case ToolSelectionType.Image:
        case ToolSelectionType.Info:
            return this.AllowModify() ? (this.errorMode ? Cursors.No : Cursors.NoMoveVert) : null;
        case ToolSelectionType.Action:
        case ToolSelectionType.Remove:
        case ToolSelectionType.Line:
        case ToolSelectionType.None:
        case ToolSelectionType.Trailing:
        default:
            return null;
        }
    }

    public override GetTooltip (e): string {
        let res = '';
        switch (this.CurrentSelection.CurrentHover) {
        case ToolSelectionType.Image:
            res = Resources.getResource('TradeTool.Order');
            break;
        case ToolSelectionType.Info:
            res = Resources.getResource('TradeTool.Tick and P/L');
            break;
        case ToolSelectionType.Remove:
            res = Resources.getResource('TradeTool.Cancel order');
            break;
        default:
            res = null;
            break;
        }
        return res ? super.GetTooltip(e) : res;
    }

    // #endregion

    public override Dispose (): void {
        this.GetToolViewByOrderId = null;
        this.parentOrder = null;
        this.sltpOrder = null;

        super.Dispose();
    }

    public override DescriptionText (): string {
    // от цены открытия.
        let orderPrice = this.parentOrder.Price;
        if (!isNaN(this.parentValue)) {
            orderPrice = this.parentValue;
        } else if (!isNaN(this.lastValidParentValue)) // #49010 - здесь восстанавливаем значение цены, мышку мы отпустили и parentValue == NaN, но ордер еще не выставился
        {
            orderPrice = this.lastValidParentValue;
        }

        let curPrice = this.priceValue;

        if (!isNaN(this.priceValue)) {
            this.lastValidPriceValue = this.priceValue;
        }

        if (isNaN(this.priceValue) && !isNaN(this.lastValidPriceValue)) {
            curPrice = this.lastValidPriceValue;
        } else {
            curPrice = this.priceValue;
        }

        const ins = this.myRenderer.instrument;

        const revertedPrice = curPrice;

        const price = !isNaN(revertedPrice) ? revertedPrice : this.Price;
        const offsetType = BaseSettings.offsetType;
        const offsetValue = offsetType === OffsetModeViewEnum.Percent ? SlTpUtils.calculatePercent(price, orderPrice) : SlTpUtils.calculateTicks(price, orderPrice, ins);
        const offsetFormattedValue = SlTpUtils.formatSLTPValue(offsetValue, ins, offsetType);

        let pnl = '';
        pnl = this.parentOrder.Account.formatPrice(TradingToolViewBase.FuturesSlTp(this.parentOrder, price - orderPrice));

        let shortDescriptionText = offsetFormattedValue + SlTpUtils.getSymbolForOffsetMode(offsetType);
        if (this.errorMode) {
            pnl = '';
            shortDescriptionText = Resources.getResource('chart.visualTrading.Invalid price');
        }

        return pnl + '  ' + shortDescriptionText;
    }
}
