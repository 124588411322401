// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Connection } from '../../Commons/Connection';
import { Resources, CurrentLang, LOCALE_ES } from '../../Localizations/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { ExternalScreenTemplate } from '../../templates';
import { DockPlaceConst, DockSystemInstance } from '../DockSystem';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { DynProperty } from '../../Commons/DynProperty';
import { externalScreenHandler } from '../../Utils/AppHandlers';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { DataCache } from '../../Commons/DataCache';
import { ApplicationPanel } from '../panels/ApplicationPanel';
import { type BaseExtLink } from '../../Commons/cache/ExternalLink/BaseExtLink';
import { WebContainerExtLink } from '../../Commons/cache/ExternalLink/WebContainerExtLink';
import { ResourcesManager } from '../../Commons/properties/ResourcesManager';

export class ExternalScreen extends ApplicationPanel {
    public getRefreshURL: any;
    private readonly ExternalLinkId: number = null;
    private readonly PanelContainer: any = null;

    getType (): PanelNames { return PanelNames.ExternalScreen; };

    oncomplete (): void {
        super.oncomplete();

        const iframe = this.find('iframe');
        if (!isNullOrUndefined(iframe)) {
            iframe.onload = () => {
                void this.set('loading', false);
            };
            this.center();
        } else {
            void this.set('loading', false);
        }

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);

        this.on('attachClick', this.AttachClick);
    };

    dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);
        super.dispose();
    };

    localize (): void {
        const aliases = this.get('aliases');
        const aliasToolName = aliases?.get(CurrentLang)?.toolName;

        void this.set('headerText', aliasToolName ?? this.get('headerText'));
        void this.set('header', this.get('headerText') ?? Resources.getResource('External panel'));
        void this.set('header_button_text', Resources.getResource('panel.external.Attach'));
    };

    AttachClick (): void {
        void this.set({ dockablePanel: true, showHeader: false });
        DockSystemInstance.addPanelOnDock(this, DockPlaceConst.Right, false);
        WorkSpaceManager.currentWorkspace.addPanel(this);
    }

    CustomPreparation (): void {
        void this.set({ dockablePanel: true, showHeader: false });
    }

    // #region ICaller
    private static readonly PROPERTY_NAMES = ['headerText', 'clientTab', 'originalUrl', 'ExternalLinkId']; // I suspect that ExternalLinkId is redundant here, but I haven't removed it yet.

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const updateProperty = (name: string): void => {
            const dp = DynProperty.getPropertyByName(properties, name);
            if (dp?.value != null) void this.set(name, dp.value);
        };

        ExternalScreen.PROPERTY_NAMES.forEach(updateProperty);
        this.UpdateAfterLoad();
    }

    Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();

        ExternalScreen.PROPERTY_NAMES.forEach(name => {
            const value = this.get(name);
            if (value != null) properties.push(new DynProperty(name, value, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        });

        return properties;
    }
    // #endregion

    UpdateAfterLoad (): void {
        const { headerText, clientTab, originalUrl } = this.get();

        let tool = null;
        if (headerText != null && clientTab != null) {
            tool = MainWindowManager.MainWindow.ExternalScreenStorage.GetTool(headerText, clientTab);
        }

        if (tool == null) {
            tool = DataCache.ExternalLinksCache.getLinkById(this.ExternalLinkId);
        }

        if (tool != null) {
            this.UpdateLink(tool.urlStr, tool.IsNoneLink(), tool);
            void this.set({ originalUrl: tool.urlStr });
            MainWindowManager.MainWindow.ExternalScreenStorage.AddScreenItem(tool, this);
        } else if (originalUrl != null) {
            this.UpdateLink(originalUrl);
        }
    }

    async webContainerCorrectingActions (tool: BaseExtLink): Promise<void> {
        await this.resetPartial('bodyPartial', this.makeNewPartial(tool as WebContainerExtLink));
        this.center();

        const attached: boolean = this.get('dockablePanel');
        if (!attached) {
            const ic = this.find('.js-win-innerContainer');
            const divs = ic.getElementsByTagName('div');
            const [firstCh, contentCh] = divs;

            setTimeout(() => {
                if (contentCh.style.width === '100%' && contentCh.style.height === '100%') {
                    firstCh.classList.add('iframe-container-widget');
                    void this.set({
                        width: 1024,
                        height: 600,
                        resizable: true
                    });
                } else {
                    void this.set({ width: null, height: null });
                }
            }, 500);
        }
        const { width, height } = this.PanelContainer ?? {};
        this.PanelContainer?.resize(width, height);// init resize content
    }

    makeNewPartial (extLink: WebContainerExtLink): string {
        return `<div  {{#if .dockablePanel}} class="iframe-container-widget" {{/if}}>
                ${extLink.getExternalLinkWebContent(true)}
                </div>`;
    }

    UpdateLink (newUrl: string, cleanUrl: boolean = false, tool: BaseExtLink = null): void {
        const culture = CurrentLang === LOCALE_ES ? 'es-ES' : 'en-GB';
        let url = this.getRefreshURL != null ? this.getRefreshURL() : newUrl;

        if (!cleanUrl) {
            const token = Connection.vendor.loginParams.accessToken;
            url += `?token=${token}&theme=night&language=${culture}&platform=TraderEvolution`;
        }

        if (tool instanceof WebContainerExtLink) {
            void this.webContainerCorrectingActions(tool);
        }

        void this.set({ url });

        if (tool != null) {
            const dataToUpdate = {
                clientTab: tool.clientTab,
                id: tool.id,
                headerText: tool.name ?? null,
                aliases: tool.aliases ?? null
            };

            void this.set(dataToUpdate);
            if (tool.name != null || tool.aliases != null) this.localize();
        }
    }

    static Show (messageInfo, cleanUrl = false): ExternalScreen | null {
        const culture = CurrentLang === LOCALE_ES ? 'es-ES' : 'en-GB';
        let url = messageInfo.Text;
        const tool: BaseExtLink = messageInfo.Tool;
        const token = Connection.vendor.loginParams.accessToken;

        if (!(tool instanceof WebContainerExtLink)) {
            if (!cleanUrl) {
                url += `?token=${token}&theme=night&language=${culture}&platform=TraderEvolution`;
            }

            const useInternalBrowser = Boolean(messageInfo.ClientUseInternalBrowser);
            if (!useInternalBrowser) {
                window.open(url);
                return null;
            }
        }

        const scr = tool.isAttached() ? MainWindowManager.MainWindow.addAttachedExternalScree() : new ExternalScreen();

        scr.set({
            url,
            headerText: messageInfo.ScreenName,
            aliases: messageInfo.Aliases,
            clientTab: messageInfo.ClientTab,
            originalUrl: messageInfo.Text
        });

        if (tool instanceof WebContainerExtLink) {
            scr.webContainerCorrectingActions(tool);
        }

        const { ScreenWidth: width, ScreenHeight: height, ResizableScreen: resizable } = messageInfo;
        if (width > 0) scr.set({ width });
        if (height > 0) scr.set({ height });
        if (resizable !== undefined) scr.set({ resizable });

        if (messageInfo.GetRefreshURL != null) {
            scr.getRefreshURL = messageInfo.GetRefreshURL;
        }

        if (tool instanceof WebContainerExtLink) {
            scr.ExternalLinkId = tool.id;
        }

        if (!tool.isAttached()) {
            MainWindowManager.MainWindow.addControl(scr);
        }

        scr.setFocus();
        scr.localize();

        MainWindowManager.MainWindow.ExternalScreenStorage.AddScreenItem(tool, scr);

        return scr;
    }

    public static externalScreenHandlerInitialize (): void {
        externalScreenHandler.Show = ExternalScreen.Show;
    }
}

ApplicationPanel.extendWith(ExternalScreen, {
    data: function () {
        return {
            width: 1024,
            height: 600,
            showFooter: false,
            resizable: false,
            url: null,
            headerText: null,
            aliases: null,
            loading: true,
            showHeader: true,
            dockablePanel: false,
            showAttachHeaderButton: true,
            canLinkByAccount: false,
            style_addition_header_button: 'js-button-attach',
            clientTab: '',
            originalUrl: ''
        };
    },
    partials: { bodyPartial: ExternalScreenTemplate }
});
