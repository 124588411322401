// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { Periods } from '../../../../Utils/History/TFInfo';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { HistoryMergerBasedByDay } from './HistoryMergerBasedByDay';
import { type DateTime } from 'luxon';

export class HistoryMergerYear extends HistoryMergerBasedByDay {
    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
    }

    protected override GetDaysInPeriod (dateTime: DateTime): number {
        let result = 0;

        let currentDate: DateTime = dateTime;
        for (let year = dateTime.year; year < dateTime.year + this.GetCount(); year++) {
            const isLeapYear: boolean = currentDate.isInLeapYear;
            result += isLeapYear ? 366 : 365;
            currentDate = currentDate.plus({ years: 1 });
        }

        return result;
    }

    protected override IsStartPeriodDate (dateTime: DateTime): boolean {
        const january = 1;
        return dateTime.month === january && dateTime.day === 1;
    };

    protected override GetBasePeriod (): number {
        return Periods.YEAR;
    }
}
