// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/// <summary>
/// Описание процесса выделения
/// </summary>
export class Selection {
    /// <summary>
    /// текущий режим выделения тулзы
    /// </summary>
    public CurrentState = SelectionState.None;

    /// <summary>
    /// Выделена точка N тулзы
    /// </summary>
    public SelectedPointNo = -1;
    public CurrentHover: ToolSelectionType;
    public MouseDownHover: ToolSelectionType;

    /// <summary>
    /// сброс выделения
    /// </summary>
    public ClearSelection (): void {
        this.CurrentState = SelectionState.None;
    }
}

export enum SelectionState {
    None = 0,
    Hovered = 1,
    Selected = 2
}

export enum ToolSelectionType {
    None = 0,
    Line = 1,
    Icon = 2,
    SL = 3,
    TP = 4,
    Action = 5,
    Remove = 6,
    Info = 7,
    Trailing = 8,
    Image = 9,
    LimitIcon = 10,
    LimitQuantity = 11,
    LimitInfo = 12,
    AcceptPlacing = 13,
    CancelPlacingSL = 14,
    CancelPlacingTP = 15,
    SideSelect = 16,
    QuantitySelect = 17,
    OrderTypeSelect = 18,
    OrderTifSelect = 19,
    Leverage = 20
}
