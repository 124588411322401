// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { MarginTypes } from '../../Utils/Instruments/MarginTypes';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { RiskPlanDataKey } from '../../Utils/RiskPlan/RiskPlanDataKey';
import { type RiskPlanItem } from '../../Utils/RiskPlan/RiskPlanItem';
import { RiskPlanMargin } from '../../Utils/RiskPlan/RiskPlanMargin';
import { type Instrument } from './Instrument';
import { RiskPlanSettings } from './RiskPlanSettings';

export class RiskPlan {
    public Id = 0;
    public Name: any = null;
    public riskPlanCache = new RiskPlanCache();
    public items: any = {};

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () {}

    public update (dataCache, message): void {
        this.Id = message.Id;
        if (message.Name) {
            this.Name = message.Name;
        }

        const riskPlanCache = this.riskPlanCache;
        const data = message.Data;
        for (let i = 0, len = data.length; i < len; i++) {
            riskPlanCache.addRiskData(data[i]);
        }

        riskPlanCache.UpdateCount();

        // TODO.
        const instruments = dataCache.getCachedInstrumentDict();
        const newItems = {};

        for (const key in instruments) {
            this.processOneInstrument(instruments[key], newItems);
        }

        this.items = newItems;
    }

    public applyToInstrument (dataCache): void {
        for (const it in this.items) {
            const item = this.items[it];
            const instrument = dataCache.getInstrumentByName(it);
            if (instrument == null) {
                continue;
            }

            instrument.RiskSettings.RiskPlanSettings = item;
            instrument.RiskSettingsUpdated.Raise();
        }
    }

    public processOneInstrument (instrument: Instrument, newItems): void {
        newItems = newItems || this.items;
        const item = this.riskPlanCache.getRiskPlanSettings(instrument);
        newItems[instrument.GetInteriorID()] = item;

        // TODO. Use current acc risk plan.
        instrument.RiskSettings.RiskPlanSettings = item;
        instrument.RiskSettingsUpdated.Raise();
    }

    public GetRisksForInstrument (instrumentName): RiskPlanSettings {
        let item = this.items[instrumentName];

        if (!item) {
            item = new RiskPlanSettings();
        }

        return item;
    }

    // margin
    public static GetRiskPlanItem (instrument: Instrument, productType: ProductType | string): RiskPlanItem {
        const rs = instrument.RiskSettings;
        const rps = rs.RiskPlanSettings;
        const ri = rps.get(productType);

        return ri;
    }

    // GetMargin
    public static GetMargin (instrument: Instrument, productType: ProductType, tier: number): RiskPlanMargin {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return new RiskPlanMargin(0, 0, 0);
        }

        if (RiskPlan.IsPriceBasedMultipleCoefMarginType(instrument, productType)) {
            const margin = new RiskPlanMargin();
            margin.Leverage = ri.GetLeverageTier(tier);
            return margin;
        } // #113782 comments

        return new RiskPlanMargin(ri.GetWarnMargin(tier), ri.GetInitMargin(tier), ri.GetMaintMargin(tier));
    }

    public static GetMarginCoeficientListLength (instrument: Instrument, productType: ProductType): number {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (!isValidArray(ri?.MarginCoeficientList)) {
            return 0;
        }

        if (!RiskPlan.IsTierMargin(instrument, productType)) // #95613   подозреваю баг на сервере из-за которого присылаются лишние коэф.
        {
            return 1;
        } // несколько слоев коэф. может быть только у TierMargin, у остальных 1

        const list = ri.MarginCoeficientList;

        return (list.length > 0) ? list.length : 0;
    }

    public static IsTierMargin (instrument: Instrument, productType: ProductType): boolean {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return false;
        }

        const marginType = ri.GetMarginType();
        return marginType === MarginTypes.TIERED_PRICE_BASED_CUSTOM_COEF || marginType === MarginTypes.TIERED_PRICE_BASED_CUSTOM_COEF_WITH_RANGES;
    }

    public static GetOvernightMargin (instrument: Instrument, productType: ProductType, tier: number): RiskPlanMargin {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return new RiskPlanMargin(0, 0, 0);
        }

        return new RiskPlanMargin(ri.GetWarnMarginOvernight(tier), ri.GetInitMarginOvernight(tier), ri.GetMaintMarginOvernight(tier));
    }

    public static GetShortMargin (instrument: Instrument, productType: ProductType, tier: number): RiskPlanMargin {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return new RiskPlanMargin(0, 0, 0);
        }

        return new RiskPlanMargin(ri.GetWarnMarginShort(tier), ri.GetInitMarginShort(tier), ri.GetMaintMarginShort(tier));
    }

    public static GetOvernightShortMargin (instrument: Instrument, productType: ProductType, tier: number): RiskPlanMargin {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return new RiskPlanMargin(0, 0, 0);
        }

        return new RiskPlanMargin(ri.GetWarnMarginOvernightShort(tier), ri.GetInitMarginOvernightShort(tier), ri.GetMaintMarginOvernightShort(tier));
    }

    // UseMargin
    public static UseOvernightMargin (instrument: Instrument, productType: ProductType): boolean {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return false;
        }

        return ri.GetUseOvernightMargin();
    }

    public static UseLongShortMargin (instrument: Instrument, productType: ProductType): boolean {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return false;
        }

        return ri.GetUseLongShortMargin();
    }

    // CoeffNullable
    public static GetUseVarOnlyIntraday (instrument: Instrument, productType: ProductType): boolean {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return false;
        }

        const varCoeff = ri.GetUseVarOnlyIntraday();
        return isValidNumber(varCoeff) && varCoeff !== 0;
    }

    public static GetVarCoeffNullable (instrument: Instrument, productType: ProductType): any {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (!ri) {
            return null;
        }

        return ri.GetVarCoeff();
    }

    public static GetVarCoeffApplyingNullable (instrument: Instrument, productType: ProductType): any {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (!ri) {
            return null;
        }

        return ri.GetVarCoeffApplying();
    }

    // GetType
    public static GetCalculateMarginTypeNullable (instrument: Instrument, productType: ProductType | string): MarginTypes | null {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (!ri) {
            return null;
        }

        return ri.GetMarginType();
    }

    // ProductTypes
    public static GetAvailableProductTypes (instrument: Instrument): ProductType[] {
        return instrument.RiskSettings.RiskPlanSettings.availableProductTypes;
    }

    public static AvailableOnlyOneProductType (instrument: Instrument): boolean {
        return instrument.RiskSettings.RiskPlanSettings.availableProductTypes.length === 1;
    }

    public static IsProductTypeAvailableForInstrument (instrument: Instrument, productType: ProductType): boolean {
        const availableProductTypes = instrument.RiskSettings.RiskPlanSettings.availableProductTypes;

        for (let i = 0; i < availableProductTypes.length; i++) {
            if (availableProductTypes[i] === productType) {
                return true;
            }
        }

        return false;
    }

    // Leverages #111414->#112880->#112886
    public static GetLeverages (instrument: Instrument, productType: ProductType): number[] {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (isNullOrUndefined(ri)) {
            return [];
        }

        return ri.GetLeverages();
    }

    public static IsPriceBasedMultipleCoefMarginType (instrument: Instrument, productType: ProductType): boolean {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (!ri) {
            return false;
        }

        return ri.IsPriceBasedMultipleCoefMarginType();
    }

    public static IsLeverageVisible (instrument: Instrument, productType: ProductType): boolean {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (!ri) {
            return false;
        }

        return ri.IsLeverageVisible();
    }

    public static GetCurrencyId (instrument: Instrument, productType: ProductType): any {
        const ri = RiskPlan.GetRiskPlanItem(instrument, productType);
        if (!ri) {
            return null;
        }

        return ri.GetCurrencyId();
    }

    public static GetCustomCurrencyStr (instrument: Instrument, productType: ProductType): string // #116496
    {
        const currencyId = RiskPlan.GetCurrencyId(instrument, productType);
        let currencyStr = '';

        if (currencyId === null) {
            return currencyStr;
        }

        const instrumentBaseCCY = -1;
        const instrumentQuotingCCY = -2;

        if (currencyId == instrumentBaseCCY) {
            currencyStr = instrument.Exp1;
        } else if (currencyId == instrumentQuotingCCY) {
            currencyStr = instrument.Exp2;
        } else {
            currencyStr = instrument.DataCache.GetAssetById(currencyId)?.Name;
        }

        return currencyStr;
    }

    public static showProductType = false;

    public GetUseSettledCashForStocksForOpenedPositionsInMA (): any {
        if (!this.riskPlanCache) return null;

        const riskPlanSettings = this.riskPlanCache.getDefaultRiskPlanSettings();
        if (!riskPlanSettings) return null;

        const productType = riskPlanSettings.availableProductTypes ? riskPlanSettings.availableProductTypes[0] : ProductType.General.toString();
        const riskItem = riskPlanSettings.get(productType);

        return riskItem ? riskItem.GetUseSettledCashForStocksForOpenedPositionsInMA() : null;
    }

    public GetUseSettledCollateralForOpenedPositionInMA (): any {
        if (!this.riskPlanCache) return null;

        const riskPlanSettings = this.riskPlanCache.getDefaultRiskPlanSettings();
        if (!riskPlanSettings) return null;

        const productType = riskPlanSettings.availableProductTypes ? riskPlanSettings.availableProductTypes[0] : ProductType.General.toString();
        const riskItem = riskPlanSettings.get(productType);

        return riskItem ? riskItem.GetUseSettledCollateralForOpenedPositionInMA() : null;
    }
}

export class RiskPlanCache {
    public defaultRiskSettings = new RiskPlanSettings();
    public defaultRiskDataCache = [];
    public riskPlanDataDictionary: any = {};
    public riskPlanDataDictionaryCount = 0;
    // this.chainByRoute = {} #85526

    public UpdateCount (): void {
        this.riskPlanDataDictionaryCount = Object.keys(this.riskPlanDataDictionary).length;
    }

    public addRiskData (data): void {
        if (!data) return;

        const defaultRiskSettings = this.defaultRiskSettings;
        const defaultRiskDataCache = this.defaultRiskDataCache;
        // let chainByRoute = this.chainByRoute #85526

        if (!data.RiskPlanDataKey.Route) {
            defaultRiskSettings.addItem(data.Item);
            defaultRiskDataCache.push(data);
            // Костя: сервер не гарантирует порядок настроек.
            // Добавляем дефолт дату в уже пришедшие настройки по роутам
            // for (let key in chainByRoute)  #85526
            //     chainByRoute[key].addRiskData(data)
            return;
        }

        const itemKey = data.RiskPlanDataKey.toString();
        let riskPlanDataDictionary = this.riskPlanDataDictionary[itemKey] || null;
        if (!riskPlanDataDictionary) {
            riskPlanDataDictionary = new RiskPlanSettings();
            this.riskPlanDataDictionary[itemKey] = riskPlanDataDictionary;
        }
        riskPlanDataDictionary.addItem(data.Item);
    }

    public getDefaultRiskPlanSettings (): RiskPlanSettings {
        return this.defaultRiskSettings;
    }

    public checkItem (cacheItem, ProductType, FieldLiteral): boolean {
        if (!cacheItem) {
            return false;
        }

        if ((ProductType === null && ProductType === undefined) || !FieldLiteral) {
            return true;
        }

        if (cacheItem.get(ProductType) && cacheItem.get(ProductType)[FieldLiteral] !== null) {
            return true;
        }

        return false;
    }

    public getRiskPlanSettings (instrument: Instrument | null, ProductType: ProductType = undefined, FieldLiteral: any = undefined): RiskPlanSettings {
        if (!this.riskPlanDataDictionaryCount || !instrument) {
            return this.defaultRiskSettings;
        }

        let cacheItem;
        // 1 Всегда пробуем создать ключ для конкретного инструмента
        let riskPlanDataKey = new RiskPlanDataKey();
        riskPlanDataKey.TradableId = instrument.InstrumentTradableID;
        riskPlanDataKey.Route = instrument.Route;

        cacheItem = this.riskPlanDataDictionary[riskPlanDataKey.toString()];
        if (this.checkItem(cacheItem, ProductType, FieldLiteral)) {
            return cacheItem;
        }

        if (instrument.InstrType === InstrumentTypes.OPTIONS) {
            riskPlanDataKey = new RiskPlanDataKey();
            riskPlanDataKey.ContractId = instrument.ContractID;
            riskPlanDataKey.Route = instrument.Route;

            cacheItem = this.riskPlanDataDictionary[riskPlanDataKey.toString()];
            if (this.checkItem(cacheItem, ProductType, FieldLiteral)) {
                return cacheItem;
            }
        }

        if (instrument.InstrType === InstrumentTypes.OPTIONS || instrument.InstrType === InstrumentTypes.FUTURES) {
            riskPlanDataKey = new RiskPlanDataKey();
            riskPlanDataKey.InstrumentId = instrument.Id;
            riskPlanDataKey.Route = instrument.Route;

            cacheItem = this.riskPlanDataDictionary[riskPlanDataKey.toString()];
            if (this.checkItem(cacheItem, ProductType, FieldLiteral)) {
                return cacheItem;
            }
        }

        const resForGroups = this.GetRiskFeatureForGroup(instrument.DataCache, instrument.TypeId, instrument.Route);
        if (resForGroups) {
            return resForGroups;
        }

        riskPlanDataKey = new RiskPlanDataKey();
        riskPlanDataKey.InstrumentType = instrument.InstrType;
        riskPlanDataKey.Route = instrument.Route;

        cacheItem = this.riskPlanDataDictionary[riskPlanDataKey.toString()];
        if (this.checkItem(cacheItem, ProductType, FieldLiteral)) {
            return cacheItem;
        }

        riskPlanDataKey = new RiskPlanDataKey();
        riskPlanDataKey.Route = instrument.Route;

        cacheItem = this.riskPlanDataDictionary[riskPlanDataKey.toString()];
        if (this.checkItem(cacheItem, ProductType, FieldLiteral)) {
            return cacheItem;
        }

        return this.defaultRiskSettings;
    }

    public GetRiskFeatureForGroup (dataCache, instrumentType: InstrumentTypes, routeName): any {
        const riskPlanDataKey = new RiskPlanDataKey();
        riskPlanDataKey.InstrumentType = instrumentType;
        riskPlanDataKey.Route = routeName;

        const cacheItem = this.riskPlanDataDictionary[riskPlanDataKey.toString()];
        if (cacheItem) {
            return cacheItem;
        }

        const instType = dataCache.getInstrumentTypeById(instrumentType);
        if (instType == null || instType.SuperTypeId == -1) {
            return null;
        }

        return this.GetRiskFeatureForGroup(dataCache, instType.SuperTypeId, routeName);
    }
}
