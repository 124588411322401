// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { QuickTree } from '../QuickTree/QuickTree';
import { QuickTableCell } from './QuickTableCell';
import { QuickTableUtils } from './QuickTableUtils';
import { Rectangle } from '../../../Commons/Geometry';
import { InstrumentLookupQuickTreeNode } from '../QuickTree/InstrumentLookupQuickTreeNode';
import { ThemeManager } from '../../misc/ThemeManager';
import { type InstrumentLookupAutoCompleteQuickTableRow } from './InstrumentLookupAutoCompleteQuickTableRow';
import { FontUtils } from '../../UtilsClasses/FontUtils';
export class InstrumentLookupAutoCompleteQuickTableCell extends QuickTableCell {
    public RowOwner: InstrumentLookupAutoCompleteQuickTableRow | null;

    public InstrumentNameColor = '#E3E1E2';
    public InstrumentNameText = '';
    public InstrumentNameTextWidth = 0;

    public InstrumentDescriptionColor = '#818181';
    public InstrumentDescriptionText = '';
    public InstrumentDescriptionTextWidth = 0;

    public InstrumentTradingExchangeColor = '#828F9D';
    public InstrumentTradingExchangeText = '';
    public InstrumentTradingExchangeTextWidth = 0;

    // public CountryId = -1;
    public FlagImg: any = null;
    // public UpdateFlagImgHandler : any = null;

    public InstrumentTypeImg: any = null;

    public InstrumentFont = '12px Trebuchet MS';
    public InstrumentFontBold: string;
    public InstrumentDrawingFont: string;
    public InstrumentDescriptionFont = '10px Trebuchet MS';

    public FilterNameText: string | null = null;
    public FilterNamePos: number | null = null;
    public FilterDescrText: string | null = null;
    public FilterDescrPos: number | null = null;

    constructor (rowOwner: InstrumentLookupAutoCompleteQuickTableRow) {
        super();

        this.InstrumentFontBold = FontUtils.makeCorrectBoldFont(this.InstrumentFont);

        this.RowOwner = rowOwner;

        this.InstrumentDrawingFont = this.InstrumentFont;
    }

    public Draw (context, cellX: number, cellY: number, cellW: number, cellH: number): void {
        const coll_size = QuickTree.COLLAPSE_ICON_SIZE;
        let dx = this.RowOwner.Level * coll_size;
        const insTypeIMG = this.InstrumentTypeImg;
        const collapseIconY = cellY + coll_size / 2;
        const collapseIconX = cellX + dx + coll_size + (insTypeIMG ? insTypeIMG.width : 0) + this.InstrumentNameTextWidth + COLLAPSE_ARROW_X_OFFSET;
        const rec = new Rectangle(collapseIconX, collapseIconY, coll_size, coll_size);
        const needCollapseArrow = this.NeedCollapseArrow();
        const isPartOfTree = this.IsPartOfTree();

        if (needCollapseArrow) {
            InstrumentLookupQuickTreeNode.DrawCollapseIcon(context, this.Hovered(), this.Collapsed(), rec);
        }

        let x_ofset = dx = isPartOfTree && this.MayContainTrees() ? dx : 0;

        if (insTypeIMG) {
            x_ofset += cellX + 8;
            const instrumentTypeImg = isPartOfTree ? ThemeManager.CurrentTheme.OPTIONS : insTypeIMG;
            context.drawImage(instrumentTypeImg, x_ofset - 0.5, cellY + 4.5);
        }

        // Name
        x_ofset += 31;

        context.font = this.InstrumentDrawingFont;
        context.fillStyle = this.InstrumentNameColor;

        const needHighlight = this.NeedHighlight(); // NeedHighlight -> #113316
        const mainText = this.InstrumentNameText;
        QuickTableUtils.DrawTextInRect(context, mainText, x_ofset, cellY + 3, cellW, cellH);
        if (needHighlight && this.FilterNameText) {
            context.fillStyle = '#FFB300';
            QuickTableUtils.DrawTextInRect(context, this.FilterNameText, x_ofset + this.FilterNamePos, cellY + 3);
        }

        const flagIMG = this.FlagImg; const // ? this.FlagImg : this.RowOwner.getFlag(),
            exchangeText = this.RowOwner.getExchange();
        const descrText = this.RowOwner.getDescription();

        // Exchange
        if (exchangeText) {
            const flag_offset = flagIMG ? 37 : 15;
            x_ofset = cellX + cellW - flag_offset - this.InstrumentTradingExchangeTextWidth;
            context.font = this.InstrumentFont;
            context.fillStyle = this.InstrumentTradingExchangeColor;
            QuickTableUtils.DrawTextInRect(context, exchangeText, x_ofset, cellY + 3, cellW, cellH);
        }

        // Description
        if (descrText) {
            x_ofset = dx + cellX + 39;
            context.fillStyle = this.InstrumentDescriptionColor;
            context.font = this.InstrumentDescriptionFont;
            QuickTableUtils.DrawTextInRect(context, descrText, x_ofset, cellY + 18, cellW, cellH);
            if (needHighlight && this.FilterDescrText) {
                context.fillStyle = '#FFB300';
                QuickTableUtils.DrawTextInRect(context, this.FilterDescrText, x_ofset + this.FilterDescrPos, cellY + 18);
            }
        }

        if (flagIMG) {
            x_ofset = cellX + cellW - 29;
            context.drawImage(flagIMG, x_ofset - 0.5, cellY + 4.5);
        }
    }

    public NeedHighlight (): boolean // #113316
    {
        return !isNullOrUndefined(this.RowOwner) ? !this.RowOwner.HasAnyChild : true;
    }

    public IsPartOfTree (): boolean {
        return !isNullOrUndefined(this.RowOwner) ? this.RowOwner.IsPartOfTree : false;
    }

    public NeedCollapseArrow (): boolean {
        return !isNullOrUndefined(this.RowOwner) ? this.RowOwner.mayContainTrees && this.RowOwner.HasAnyChild : false;
    }

    public MayContainTrees (): boolean {
        return !isNullOrUndefined(this.RowOwner) ? this.RowOwner.mayContainTrees : false;
    }

    public Hovered (): boolean {
        return !isNullOrUndefined(this.RowOwner) ? this.RowOwner.Hovered() : false;
    }

    public Collapsed (): boolean {
        return !isNullOrUndefined(this.RowOwner) ? this.RowOwner.Collapsed : false;
    }
}

const COLLAPSE_ARROW_X_OFFSET = 5; // #115092
