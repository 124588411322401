// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectInstrumentGroupMessage } from '../../Utils/DirectMessages/DirectInstrumentGroupMessage';
import { CurrentLang } from '../../Localizations/Resources';

export class InstrumentGroup {
    public TypeId: any;
    public Id: any;
    public SuperTypeId: any;
    public Name: string;
    public MinimalLot: any;
    public MaximalLot: any;
    public FDataCache: any;
    public FLanguageAliases: any;
    public ExtendedFields: Record<string, string>;
    public InstrumentType: number | null = null;

    private _disclosedQuantityMinCoefficient: number | null = null;

    constructor (message: DirectInstrumentGroupMessage, dataCache) {
        this.TypeId = message.TypeId;
        this.Id = message.TypeId;
        this.SuperTypeId = message.SuperTypeId;
        this.Name = message.TypeName;
        this.MinimalLot = message.MinimalLot;
        this.MaximalLot = message.MaximalLot;
        this.FDataCache = dataCache;
        this.FLanguageAliases = message.LanguageAliases || {};
        this.ExtendedFields = message.ExtendedFields || {};
        this.InstrumentType = message.InstrumentType;
    }

    public DisplayName (): string {
        let alias = '';
        if (this.FLanguageAliases) {
            alias = this.FLanguageAliases[CurrentLang];
        }

        return alias || this.Name;
    }

    public toString (): string {
        return this.Name;
    }

    public DisclosedQuantityMinCoefficient (): number {
        if (isValidNumber(this._disclosedQuantityMinCoefficient) && this._disclosedQuantityMinCoefficient !== 0) {
            return this._disclosedQuantityMinCoefficient;
        }

        if (isNullOrUndefined(this.ExtendedFields)) {
            return null;
        }

        const result = this.ExtendedFields[InstrumentGroup.DISCLOSED_QUANTITY_MIN_COEFFICIENT_KEY];

        if (!isValidString(result)) {
            return null;
        }

        this._disclosedQuantityMinCoefficient = parseFloat(result);

        return this._disclosedQuantityMinCoefficient;
    }

    public static DISCLOSED_QUANTITY_MIN_COEFFICIENT_KEY = 'Disclosed qty';
}
