// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { iStdDevEx } from "./iStdDevEx";

export class iStdDev extends iStdDevEx
{
    constructor(period, maMethod, shift, priceType){
        super(period, maMethod, shift, priceType);
    }

    public get Name(): string
    {
        return 'iStdDev'
    }
}