// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../../Utils/CustomEvents';
import { ControlsUtils } from '../../UtilsClasses/ControlsUtils';
import { TerceraQuickTreeTemplate } from '../../../templates.js';
import { Control } from '../Control';
import { QuickTree } from './QuickTree';
import { KeyEventProcessor } from '../../../Commons/KeyEventProcessor';
import { type QuickTreeNode } from './QuickTreeNode';

export class TerceraQuickTree<TNode extends QuickTreeNode = QuickTreeNode> extends Control {
    public static uniqueId = 0;
    public quickTree: QuickTree<TNode> | null;
    public GlobalKeyDownHandled: CustomEvent;
    public captured: boolean;

    constructor () {
        super();

        this.quickTree = null;
    }

    public override getType (): string { return 'TerceraQuickTree'; };

    public override oninit (): void {
        TerceraQuickTree.uniqueId++;
        void this.set({ myId: TerceraQuickTree.uniqueId });

        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
        keyProc.OnKeyUp.Subscribe(this.onGlobalKeyUp, this);

        this.GlobalKeyDownHandled = new CustomEvent();
    }

    public setSizes (): void {
        const panel = this.find('*');
        const w = panel.offsetWidth;
        const h = panel.offsetHeight;

        void this.set({ width: w, height: h });
    }

    public async resetSizes (): Promise<void> {
        await this.set({ width: 0, height: 0 });
    }

    public override onrender (): void {
        const canvas: HTMLCanvasElement = document.getElementById('tree_' + TerceraQuickTree.uniqueId) as HTMLCanvasElement;
        const ctx = canvas.getContext('2d');
        this.quickTree = new QuickTree<TNode>(ctx);
        this.quickTree.boldRootNode = this.get('boldRootNode');
        this.quickTree.isMultiSelect = false;
        this.quickTree.SelectionChanged.Subscribe(this.onSelectionChanged, this);

        this.quickTree.EnterPressed.Subscribe(this.onEnterPressed, this);

        this.observe('width', (newValue, oldValue, keypath) => {
            if (isValidNumber(newValue)) {
                const devicePixelRatio = window.devicePixelRatio || 1;
                this.quickTree.width = newValue;
                canvas.width = this.quickTree.width * devicePixelRatio;
                ctx.scale(devicePixelRatio, devicePixelRatio);
            } else {
                this.quickTree.width = newValue;
            }
            this.quickTree.onResize();
        }, { defer: true });
        this.observe('height', (newValue, oldValue, keypath) => {
            if (isValidNumber(newValue)) {
                const devicePixelRatio = window.devicePixelRatio || 1;
                this.quickTree.height = newValue;
                canvas.height = this.quickTree.height * devicePixelRatio;
                ctx.scale(devicePixelRatio, devicePixelRatio);
            } else {
                this.quickTree.height = newValue;
            }
            this.quickTree.onResize();
        }, { defer: true });
    }

    public onEnterPressed (): void {
        this.fire(TerceraQuickTreeEvents.EnterPress);
    }

    public onSelectionChanged (): void {
        this.fire(TerceraQuickTreeEvents.Selection);
    }

    public override onMouseDown (event): void {
        super.onMouseDown(event);
        this.quickTree.onMouseDown(event.original);
        ControlsUtils.Capture.SetCapture(this, event.original);

        this.fire(TerceraQuickTreeEvents.AfterMouseDown, event);
    }

    public override onMouseMove (event): void {
        if (this.captured && event.original) {
            return;
        }

        super.onMouseMove(event);
        this.quickTree.onMouseMove(event.original || event);
    }

    public override onMouseUp (event): void {
        if (this.captured && event.original) {
            return;
        }

        super.onMouseUp(event);
        this.quickTree.onMouseUp(event.original || event);
    }

    public override onMouseWheel (context): void {
        const event = context.event;
        super.onMouseWheel(event);
        this.quickTree.onMouseWheel(event);
    }

    public override onDoubleClick (event): void {
    // if (this.quickTree.isMultiSelect)   //  https://tp.traderevolution.com/entity/84756  (2nd issue)
    //  return;
        super.onDoubleClick(event);
        const res = this.quickTree.onDoubleClick(event.original);
        if (res) {
            this.fire(TerceraQuickTreeEvents.DoubleClick, this);
        }
    }

    public onGlobalKeyDown (): void {
        const container = this.get('parentContainerControl');
        if (container?.get('focused') || this.get('focused')) {
            this.quickTree.onKeyDown();
            this.GlobalKeyDownHandled.Raise();
        }
    }

    public onGlobalKeyUp (): void {
        const container = this.get('parentContainerControl');
        if (container?.get('focused') || this.get('focused')) {
            this.quickTree.onKeyUp();
        }
    }

    public override onteardown (): void {
        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
        keyProc.OnKeyUp.UnSubscribe(this.onGlobalKeyUp, this);
    }

    public setIsMultiSelect (isMultiSelect: boolean): void {
        this.quickTree.isMultiSelect = isMultiSelect;
    }

    public themeChange (): void {
        if (!isNullOrUndefined(this.quickTree)) {
            this.quickTree.themeChange();
        }
    }
}

Control.extendWith(TerceraQuickTree, {
    template: TerceraQuickTreeTemplate,
    data: function () {
        return {
            myId: 0,
            boldRootNode: false
        };
    }
});

export enum TerceraQuickTreeEvents {
    EnterPress = 'EnterPress',
    DoubleClick = 'DoubleClick',
    Selection = 'selection',
    AfterMouseDown = 'afterMouseDown'
}
