// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class HistoryDepthLimit {
    ticks: number;
    minBars: number;
    dayBars: number;

    constructor (ticks: number, minBars: number, dayBars: number) {
        this.ticks = ticks;
        this.minBars = minBars;
        this.dayBars = dayBars;
    }
}
