// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ProductType } from '@shared/utils/Instruments/ProductType';
import { OrderTif } from '@shared/utils/Trading/OrderTifEnum';
import { DataCache } from '../../DataCache';
import { BaseSettingsUtils } from '../../UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { MarginInfoParameters } from '../../UtilsClasses/MarginInfo/MarginInfoParameters';
import { MarginInfoWrapper } from '../../UtilsClasses/MarginInfo/MarginInfoWrapper';
import { type Account } from '../../cache/Account';
import { OrderFormatter } from '../../cache/Formatters/OrderFormatter';
import { type Instrument } from '../../cache/Instrument';
import { type OrderEditBase } from '../../cache/OrderParams/order-edit/OrderEditBase';
import { Resources } from '@shared/localizations/Resources';
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { TvOrderConvertor } from '../Convertors/TvOrderConvertor';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { TvOrdersManager } from '../Managers/TvOrdersManager';
import { type OrderPreviewSectionRow, type OrderPreviewResult, type OrderPreviewSection, type PreOrder, type OrderDuration, Side } from '../charting_library';

class _TvOrderPreview {
    async previewOrder (order: PreOrder): Promise<OrderPreviewResult> {
        const account = TvAccountHelper.getCurrentAccount();
        const symbol = order.symbol;
        const interiorId = TvInteriorIdCache.getInteriorId(symbol);
        const instrument = DataCache.getInstrumentByName(interiorId);
        let exchange = '';
        if (!isNullOrUndefined(instrument)) {
            exchange = TvSymbolConvertor.getMarketExchange(instrument);
        } else {
            console.error(`[TvOrderPreview::previewOrder]: Instrument ${symbol} not found`);
        }

        const addiditionalSections: OrderPreviewSection[] = [];

        const addiditionalInfo: OrderPreviewSection = {
            header: '',
            rows: [this.getExchangeRow(exchange), this.getTifRow(order.duration)]
        };
        addiditionalSections.push(addiditionalInfo);

        const marginInfoSections: OrderPreviewSection[] = await this.getMarginInfoSections(order, account, instrument);
        addiditionalSections.push(...marginInfoSections);

        const preview: OrderPreviewResult = {
            // confirmId
            sections: addiditionalSections
            // errors: ['test error 1', 'test error 2']
        };

        const warnings = await this.getWarings(order, account);
        if (warnings.length > 0) {
            preview.warnings = warnings;
        }
        return preview;
    }

    private getExchangeRow (exchange: string): OrderPreviewSectionRow {
        const exchangeRow: OrderPreviewSectionRow = {
            title: Resources.getResource('TvTradingPlatform.OrderConfirmation.Exchange'),
            value: exchange
        };
        return exchangeRow;
    }

    private getTifRow (duration: OrderDuration): OrderPreviewSectionRow {
        const tifId = OrderTif[duration.type];
        const date = !isNullOrUndefined(duration.datetime) ? new Date(duration.datetime) : new Date();
        const formattedValidityStr = OrderFormatter.GetFormattedValidityStr(tifId, date);
        const exchangeRow: OrderPreviewSectionRow = {
            title: Resources.getResource('TvTradingPlatform.OrderConfirmation.TimeInForce'),
            value: formattedValidityStr
        };
        return exchangeRow;
    }

    private async getWarings (order: PreOrder, account: Account): Promise<string[]> {
        const orderEditBase: OrderEditBase = TvOrdersManager.createOrderEditBase(order, account);
        const confirmationData = await orderEditBase.getConfirmationData();
        if (!BaseSettingsUtils.needToShowWarning(confirmationData)) return [];

        const localizedWarnings = confirmationData.warningArray.map(warning => Resources.getResource(warning));
        return localizedWarnings;
    }

    private async getMarginInfoSections (order: PreOrder, account: Account, instrument: Instrument): Promise<OrderPreviewSection[]> {
        if (isNullOrUndefined(instrument)) return [];

        const parameters: MarginInfoParameters = new MarginInfoParameters();
        parameters.account = account;
        parameters.instrument = instrument;
        parameters.isLong = order.side === Side.Buy;
        parameters.amountLots = order.qty;
        parameters.limitPrice = order.limitPrice;
        parameters.stopPrice = order.stopPrice;
        parameters.orderType = TvOrderConvertor.getRealOrderType(order.type);
        parameters.productType = ProductType.General; // TODO: support product type

        const marginInfoWrapper = new MarginInfoWrapper();
        marginInfoWrapper._parameters = parameters;
        const groups = await marginInfoWrapper.getInfoAsync();
        const sections: OrderPreviewSection[] = [];
        for (const group of groups) {
            const marginInfoRows = group.items.map(item => {
                const row: OrderPreviewSectionRow = {
                    title: item.title,
                    value: item.formattedValue
                };
                return row;
            });

            const section: OrderPreviewSection = {
                header: group.title,
                rows: marginInfoRows
            };
            sections.push(section);
        }

        return sections;
    }
}

export const TvOrderPreview = new _TvOrderPreview();
