// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectSourceMappingMessage extends DirectMessageBaseClass {
    public RouteId = 0;
    public SourceIDs: number[] = [];
    public SourceNames: string[] = [];
    public SourceExternalIds: string[] = [];

    constructor () {
        super(Message.CODE_SOURCE_LIST);
    }
}
