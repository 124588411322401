// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { EventType } from '../cache/Event/EventConstants';
import { Resources } from '@shared/localizations/Resources';

class _EventUtils {
    public getEventTypeStr (eventType: EventType): string | null {
        switch (eventType) {
        case EventType.None:
            return Resources.getResource('panel.EventLog.eventType.none');
        case EventType.Info:
            return Resources.getResource('panel.EventLog.eventType.info');
        case EventType.Exception:
            return Resources.getResource('panel.EventLog.eventType.exception');
        case EventType.Trading:
            return Resources.getResource('panel.EventLog.eventType.trading');
        case EventType.System:
            return Resources.getResource('panel.EventLog.eventType.system');
        case EventType.Comment:
            return Resources.getResource('panel.EventLog.eventType.comment');
        case EventType.Updater:
            return Resources.getResource('panel.EventLog.eventType.updater');
        case EventType.Alerts:
            return Resources.getResource('panel.EventLog.eventType.alerts');
        default:
            return null;
        }
    }
}

export const EventUtils = new _EventUtils();
