// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ErrorInformationStorage } from './ErrorInformationStorage';

class _ApplicationInfo {
    public BuildVersionData: any = null;
    public login_language_selector: boolean = false;
    public FrequencyLogsSending: number = 5;
    public ExternalResourse: number = -1;
    public LoginScreenType: number = 0;
    public LoginLanguage: string = 'en';
    public LoginToken: any = null;
    public myportfolio: boolean = false;
    public isExploreMode: boolean = false;
    public exploreTheAppLink: any = null;
}

export let ApplicationInfo = new _ApplicationInfo();

export function CreateApplicationInfo (): void {
    const elem = document.getElementById('startData');

    if (!elem) { return; }

    const dt = JSON.parse(elem.innerText);

    ApplicationInfo = new _ApplicationInfo();
    try {
        ApplicationInfo.BuildVersionData = JSON.parse(dt.BVD);
    } catch (ex) { }
    ApplicationInfo.login_language_selector = dt.lls;
    ApplicationInfo.FrequencyLogsSending = dt.FLS;
    ApplicationInfo.ExternalResourse = dt.ER;
    ApplicationInfo.LoginScreenType = dt.LST;
    ApplicationInfo.LoginLanguage = dt.LL;
    ApplicationInfo.LoginToken = dt.LT || null;
    ApplicationInfo.myportfolio = dt.mp || false;

    ErrorInformationStorage.SetTimerValue(ApplicationInfo.FrequencyLogsSending);
    ErrorInformationStorage.SetBuildVersion(ApplicationInfo.BuildVersionData);

    elem.parentElement.removeChild(elem);
}
