// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum ChartHotkeysEnum {
    DisplayInFullScreenMode = 'DisplayInFullScreenMode',
    ChangeChartStyle = 'ChangeChartStyle',
    ZoomIn = 'ZoomIn',
    ZoomOut = 'ZoomOut',
    AutoManualScaling = 'AutoManualScaling',
    MouseTrading = 'MouseTrading',
    ShowHideInfoWindow = 'ShowHideInfoWindow',
    AddIndicator = 'AddIndicator',
    RemoveDrawings = 'RemoveDrawings',
    ShowFilledOrders = 'ShowFilledOrders',
    SetPeriod_Tick = 'SetPeriod_Tick',
    SetPeriod_1m = 'SetPeriod_1m',
    SetPeriod_5m = 'SetPeriod_5m',
    SetPeriod_15m = 'SetPeriod_15m',
    SetPeriod_30m = 'SetPeriod_30m',
    SetPeriod_1H = 'SetPeriod_1H',
    SetPeriod_4H = 'SetPeriod_4H',
    SetPeriod_1D = 'SetPeriod_1D',
    SetPeriod_1W = 'SetPeriod_1W',
    SetPeriod_1M = 'SetPeriod_1M',
    SetPeriod_1Y = 'SetPeriod_1Y',
    ObjectManagerIndicators = 'ObjectManagerIndicators',
    ObjectManagerDrawings = 'ObjectManagerDrawings',
    ObjectManagerOverlays = 'ObjectManagerOverlays',
    DrawLine = 'DrawLine',
    DrawVerticalLine = 'DrawVerticalLine',
    DrawHorizontalLine = 'DrawHorizontalLine',
    DrawArrow = 'DrawArrow',
    DrawComments = 'DrawComments',
    DrawFibonacciRetracement = 'DrawFibonacciRetracement',
    DrawFibonacciExpansion = 'DrawFibonacciExpansion',
    DrawFibonacciArc = 'DrawFibonacciArc',
    DrawFibonacciTimeGoalAnalysis = 'DrawFibonacciTimeGoalAnalysis',
    DrawFibonacciFans = 'DrawFibonacciFans',
    DrawFibonacciSpiral = 'DrawFibonacciSpiral',
    DrawFibonacciTimeZone = 'DrawFibonacciTimeZone',
    DrawFibonacciPhiChannel = 'DrawFibonacciPhiChannel',
    DrawTriangle = 'DrawTriangle',
    DrawPolygon = 'DrawPolygon',
    DrawRectangle = 'DrawRectangle',
    ShowHideGrid = 'ShowHideGrid',
    ShowOpenPositions = 'ShowOpenPositions',
    ShowVolume = 'ShowVolume',
    ShowWorkingOrders = 'ShowWorkingOrders',
    CancelLastOrderOnSelectedInstrument = 'Chart.CancelLastOrderOnSelectedInstrument',
    CancelAllActiveOrdersOnSelectedInstrument = 'Chart.CancelAllActiveOrdersOnSelectedInstrument',
    CancelBuyOrderClosestToTheLastPrice = 'Chart.CancelBuyOrderClosestToTheLastPrice',
    CancelSellOrderClosestToTheLastPrice = 'Chart.CancelSellOrderClosestToTheLastPrice',
}

export enum MarketDepthHotkeysEnum {
    CancelLastOrderOnSelectedInstrument = 'MarketDepth.CancelLastOrderOnSelectedInstrument',
    CancelAllActiveOrdersOnSelectedInstrument = 'MarketDepth.CancelAllActiveOrdersOnSelectedInstrument',
    CancelBuyOrderClosestToTheLastPrice = 'MarketDepth.CancelBuyOrderClosestToTheLastPrice',
    CancelSellOrderClosestToTheLastPrice = 'MarketDepth.CancelSellOrderClosestToTheLastPrice',
    PlaceBuyOrder = 'PlaceBuyOrder',
    PlaceSellOrder = 'PlaceSellOrder',
    SellAsk = 'SellAsk',
    BuyBid = 'BuyBid'
}

export enum OrderEntryHotkeysEnum {
    OpenBuyOE = 'OpenBuyOE',
    OpenSellOE = 'OpenSellOE',
    PlaceOrder = 'PlaceOrder'
}

export enum TradingHotkeysEnum {
    CloseAllPositionsImmediately = 'CloseAllPositionsImmediately',
    CancelAllOrdersImmediately = 'CancelAllOrdersImmediately',
    CancelAllOrdersAndCloseAllPositionsImmediately = 'CancelAllOrdersAndCloseAllPositionsImmediately',
    OpenReports = 'OpenReports'
}

export enum WorkingOrdersHotkeysEnum {
    ModifyOrder = 'ModifyOrder',
    CancelOrder = 'CancelOrder',
    ChangeToMarket = 'ChangeToMarket'
}

export enum OrderCancelActionHotkeysEnum {
    LastOrder,
    AllOrders,
    BuyOrder,
    SellOrder
}

export const HotkeysKeyless = '<None>';
export const HotkeysGroupTrading = 'TRADING';
