// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OECommentTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { Resources } from '@shared/localizations/Resources';

export class OEComment extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return ControlsTypes.OEComment; }

    public override oninit (): void {
        super.oninit();
        this.localize();
    }

    public localize (): void {
        void this.set({
            label: Resources.getResource('panel.newOrderEntry.Comment'),
            tooltip: Resources.getResource('panel.newOrderEntry.Comment.ToolTip')
        });
    }
}

ContainerControl.extendWith(OEComment, {
    template: OECommentTemplate,
    data: function () {
        return {
            label: '',
            maxLength: 128,
            userComment: '',
            showLabel: true
        };
    }
});
