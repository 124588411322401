// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ModifyTrailingStopOrder } from '../order-modify/ModifyTrailingStopOrder';
import { OrderTypeBase } from './OrderTypeBase';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { TrailingStopOrderEdit } from '../order-edit/TrailingStopOrderEdit';

export class TrailingStopOrderType extends OrderTypeBase {
    constructor () {
        super(TrailingStopOrderEdit, ModifyTrailingStopOrder);
    }

    public override id (): OrderType {
        return OrderType.TrailingStop;
    }

    public localizationKey (): string {
        return 'Tr. stop';
    }

    public IsSupported (params): boolean {
        const allowedByRule = params.checkRuleForTrailingStopOrder
            ? this.checkRuleForAccount(params.account, RulesSet.FUNCTION_TRAILING_STOP, params.instrument)
            : true;

        return allowedByRule && super.IsSupported(params);
    }
}
