// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { IsAllowed } from '../../IsAllowed';
import { type AccountManagerColumn, type FormatterName } from '../charting_library';
import { AccountSummaryTablesEnum } from './AccountSummaryTablesEnum';
import { TvBaseColumns } from './TvBaseColumns';
import { TvColDefVisEnum } from './TvColumnDefaultVisibilityEnum';

class _TvAccountColumns extends TvBaseColumns {
    constructor () {
        super();
        this.defaultalignment = 'left';
    }

    public getColumns (subTableType: AccountSummaryTablesEnum): AccountManagerColumn[] {
        let columns: AccountManagerColumn[] = [];
        switch (subTableType) {
        case AccountSummaryTablesEnum.General:
            columns = this.getGeneralColumns();
            break;
        case AccountSummaryTablesEnum.Margin:
            columns = this.getMarginColumns();
            break;
        case AccountSummaryTablesEnum.AccountActivity:
            columns = this.getAccountActivityColumns();
            break;
        case AccountSummaryTablesEnum.TodaysResults:
            columns = this.getTodaysResultsColumns();
            break;
        }

        return columns.filter(c => c !== null);
    }

    private getGeneralColumns (): AccountManagerColumn[] {
        const balanceColumn = this.createAccSummaryColumnItem(
            'panel.accounts.Balance',
            'balance',
            'balanceFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const projectedBalanceColumn = this.createAccSummaryColumnItem(
            'panel.accounts.CurBalance',
            'projectedBalance',
            'projectedBalanceFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const availableFundsColumn = this.createAccSummaryColumnItem(
            'panel.accounts.AvailableMargin',
            'availableFunds',
            'availableFundsFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const blockedBalanceColumn = this.createAccSummaryColumnItem(
            'panel.accounts.LockedBalance',
            'blockedBalance',
            'blockedBalanceFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const unsettledCashColumn = this.createAccSummaryColumnItem(
            'panel.accounts.UnsettledCash',
            'unsettledCash',
            'unsettledCashFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const result = [
            balanceColumn,
            projectedBalanceColumn,
            availableFundsColumn,
            blockedBalanceColumn,
            unsettledCashColumn
        ];

        if (IsAllowed.IsCopyTradingAllowed()) {
            const copyTrading = this.createAccSummaryColumnItem(
                'panel.accounts.CopyTrading',
                'copyTrading',
                'copyTradingFormatter' as FormatterName,
                TvColDefVisEnum.Visible
            );

            result.push(copyTrading);
        }

        return result;
    }

    private getMarginColumns (): AccountManagerColumn[] {
        const initialMarginReqColumn = this.createAccSummaryColumnItem(
            'panel.accounts.MarginUsedNew',
            'initialMarginReq',
            'initialMarginReqFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const initialMarginReqPercentColumn = this.createAccSummaryColumnItem(
            'panel.accounts.CurMargin',
            'initialMarginReqPercent',
            'initialMarginReqPercentFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const maintMarginReqColumn = this.createAccSummaryColumnItem(
            'panel.accounts.MarginStopOut',
            'maintMarginReq',
            'maintMarginReqFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const maintMarginReqPercentColumn = this.createAccSummaryColumnItem(
            'panel.accounts.MaintMargin',
            'maintMarginReqPercent',
            'maintMarginReqPercentFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const marginWarningLevelColumn = this.createAccSummaryColumnItem(
            'panel.accounts.marginWarnPercent',
            'marginWarningLevel',
            'marginWarningLevelFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const marginAvailableColumn = this.createAccSummaryColumnItem(
            'panel.accounts.MarginAvailableReal',
            'marginAvailable',
            'marginAvailableFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const warningMarginColumn = this.createAccSummaryColumnItem(
            'panel.accounts.MarginWarning',
            'warningMargin',
            'warningMarginFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const blockedForStocksColumn = this.createAccSummaryColumnItem(
            'panel.accounts.BlockedForStocksNew',
            'blockedForStocks',
            'blockedForStocksFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const stocksOrdersReqColumn = this.createAccSummaryColumnItem(
            'panel.accounts.StocksOrdersReq',
            'stocksOrdersReq',
            'stocksOrdersReqFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        return [
            initialMarginReqColumn,
            initialMarginReqPercentColumn,
            maintMarginReqColumn,
            maintMarginReqPercentColumn,
            marginWarningLevelColumn,
            marginAvailableColumn,
            warningMarginColumn,
            blockedForStocksColumn,
            stocksOrdersReqColumn
        ];
    }

    private getAccountActivityColumns (): AccountManagerColumn[] {
        const openGrossPLColumn = this.createAccSummaryColumnItem(
            'panel.accounts.Profit',
            'openGrossPL',
            'openGrossPLFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const openNetPLColumn = this.createAccSummaryColumnItem(
            'panel.accounts.ProfitNet',
            'openNetPL',
            'openNetPLFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const openPositionsCountColumn = this.createAccSummaryColumnItem(
            'panel.accounts.Positions',
            'openPositionsCount',
            'openPositionsCountFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        const openOrdersCountColumn = this.createAccSummaryColumnItem(
            'panel.accounts.Orders',
            'openOrdersCount',
            'openOrdersCountFormatter' as FormatterName,
            TvColDefVisEnum.Hidden
        );

        return [
            openGrossPLColumn,
            openNetPLColumn,
            openPositionsCountColumn,
            openOrdersCountColumn
        ];
    }

    private getTodaysResultsColumns (): AccountManagerColumn[] {
        const todaysGrossColumn = this.createAccSummaryColumnItem(
            'panel.accounts.GrossPNL',
            'todaysGross',
            'todaysGrossFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const todaysNetColumn = this.createAccSummaryColumnItem(
            'panel.accounts.BeginBalance',
            'todaysNet',
            'todaysNetFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const todaysFeesColumn = this.createAccSummaryColumnItem(
            'panel.accounts.TodayFees',
            'todaysFees',
            'todaysFeesFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const todaysVolumeColumn = this.createAccSummaryColumnItem(
            'panel.accounts.TodayVolume',
            'todaysVolume',
            'todaysVolumeFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        const todaysTradesColumn = this.createAccSummaryColumnItem(
            'panel.accounts.TodayTrades',
            'todaysTrades',
            'todaysTradesFormatter' as FormatterName,
            TvColDefVisEnum.Visible
        );

        return [
            todaysGrossColumn,
            todaysNetColumn,
            todaysFeesColumn,
            todaysVolumeColumn,
            todaysTradesColumn
        ];
    }
}

export const TvAccountColumns = new _TvAccountColumns();
