// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { SolidBrush } from '../../Graphics';
import { DataCacheToolType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty } from '../../DynProperty';

export class InfoLineDataCacheTool extends DataCacheTool {
    private backgroundColor: any; // Change the type to the appropriate type for backgroundColor
    private backgroundBrush: any; // Change the type to the appropriate type for backgroundBrush

    /**
     * Constructor for InfoLineDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.InfoLine;
        this.InitPoints(2);
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 2;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Handles theme change event
     */
    ThemeChanged (): void {
        super.ThemeChanged();

        this.BackgroundColor = ThemeManager.CurrentTheme.Chart_InfoWindowBackColor;
        this.FontColor = ThemeManager.CurrentTheme.Chart_InfoWindowForeColor;
        this.Color = ThemeManager.CurrentTheme.Chart_InfoWindowForeColor;
        this.font = ThemeManager.Fonts.Font_11F25_regular;
    }

    get BackgroundColor (): any {
        return this.backgroundColor;
    }

    set BackgroundColor (value: any) {
        if (this.backgroundColor === value) return;
        this.backgroundColor = value;
        this.backgroundBrush = new SolidBrush(this.backgroundColor);
    }

    get BackgroundBrush (): any {
        return this.backgroundBrush;
    }

    /**
     * Gets the properties of the tool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const oldProps: DynProperty[] = super.Properties();
        const props: DynProperty[] = [];

        if (this.font != null) {
            props.push(new DynProperty('Font', this.font.copy(), DynProperty.FONT, DynProperty.VISUAL_GROUP));
        }

        const len: number = oldProps.length;
        for (let i = 0; i < len; i++) {
            const prop: DynProperty = oldProps[i];

            if (
                prop.group === DynProperty.NUMERICAL_PARAMETRS_GROUP ||
                prop.group === DynProperty.HIDDEN_GROUP ||
                prop.name === 'Stick to period' ||
                prop.name === 'Color'
            ) {
                props.push(prop);
            }
        }

        return props;
    }

    /**
     * Callback function for handling property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const prop: DynProperty | undefined = DynProperty.getPropertyByName(properties, 'Font');
        if (prop != null) this.font = prop.value;
    }
}
