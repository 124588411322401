// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Control } from './Control';
import { InfoControlTemplate } from '../../templates';

export class InfoControl extends Control {
    public getType (): string { return 'InfoControl'; }

    constructor () {
        super();
    }
}

export class InfoData {
    public readonly id: string = '';
    public title: string = '';
    public items: InfoDataItem[] = [];

    constructor (id: string, title: string = '', items: InfoDataItem[] = []) {
        this.id = id;
        this.title = title;
        this.items = items;
    }
}

export class InfoDataItem {
    public readonly id: string = '';
    public title: string = '';
    public value: string = '';

    constructor (id: string, title: string = '', value: string = '') {
        this.id = id;
        this.title = title;
        this.value = value;
    }
}

Control.extendWith(InfoControl, {
    template: InfoControlTemplate,
    data: function () {
        return {
            data: [],
            columnsCount: 2,
            rowsCount: 2
        };
    }
});
