// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type TextMessageType } from '../../../../Utils/Notifications/TextMessageType';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class BrokerMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_TEXT_MESSAGE, fieldSet);
    }

    public setText (text: string): void {
        this.setFieldValue(FieldsFactory.FIELD_TEXT, text);
    }

    public setType (type: TextMessageType): void {
        this.setFieldValue(FieldsFactory.FIELD_TEXT_MESSGE_TYPE, type);
    }

    public setSenderId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_SENDER_ID, id);
    }

    public setTargetId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TARGET_ID, id);
    }

    public setDate (date: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_DATE, date);
    }

    public setName (name: string): void {
        this.setFieldValue(FieldsFactory.FIELD_NAME, name);
    }

    public setId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ID, id);
    }
}
