// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

class _CurrencyUtils {
    public isSymbolCurrency: boolean = false;

    private readonly _currencyMap: Map<string, string> = new Map<string, string>(
        [
            ['ALL', 'Lek'],
            ['AED', '\u0AED'],
            ['AFN', '؋'],
            ['ARS', '$'],
            ['AWG', 'ƒ'],
            ['AUD', 'A$'],
            ['AZN', '₼'],
            ['BSD', '$'],
            ['BBD', '$'],
            ['BYN', 'Br'],
            ['BZD', 'BZ$'],
            ['BMD', '$'],
            ['BOB', '\$b'],
            ['BAM', 'KM'],
            ['BWP', 'P'],
            ['BGN', 'лв'],
            ['BRL', 'R$'],
            ['BND', '$'],
            ['KHR', '៛'],
            ['CAD', 'C$'],
            ['KYD', '$'],
            ['CLP', '$'],
            ['CNY', '¥'],
            ['COP', '$'],
            ['CRC', '₡'],
            ['HRK', 'kn'],
            ['CUP', '₱'],
            ['CZK', 'Kč'],
            ['DKK', 'kr'],
            ['DOP', 'RD$'],
            ['XCD', '$'],
            ['EGP', '£'],
            ['SVC', '$'],
            ['EUR', '€'],
            ['FKP', '£'],
            ['FJD', '$'],
            ['GHS', '¢'],
            ['GIP', '£'],
            ['GTQ', 'Q'],
            ['GGP', '£'],
            ['GYD', '$'],
            ['HNL', 'L'],
            ['HKD', '$'],
            ['HUF', 'Ft'],
            ['ISK', 'kr'],
            ['INR', '₹'],
            ['IDR', 'Rp'],
            ['IRR', '﷼'],
            ['IMP', '£'],
            ['ILS', '₪'],
            ['JMD', 'J$'],
            ['JPY', '¥'],
            ['JEP', '£'],
            ['KZT', '₸'],
            ['KPW', '₩'],
            ['KGS', 'с'],
            ['LAK', '₭'],
            ['LBP', '£'],
            ['LRD', '$'],
            ['MKD', 'ден'],
            ['MYR', 'RM'],
            ['MUR', '₨'],
            ['MXN', 'M$'],
            ['MNT', '₮'],
            ['MZN', 'MT'],
            ['NAD', '$'],
            ['NPR', '₨'],
            ['ANG', 'ƒ'],
            ['NZD', 'N$'],
            ['NIO', 'C$'],
            ['NGN', '₦'],
            ['NOK', 'kr'],
            ['OMR', '﷼'],
            ['PKR', '₨'],
            ['PAB', 'B/.'],
            ['PYG', 'Gs'],
            ['PEN', 'S/.'],
            ['PHP', '₱'],
            ['PLN', 'zł'],
            ['QAR', '﷼'],
            ['RON', 'lei'],
            ['RUB', '₽'],
            ['SHP', '£'],
            ['SAR', '﷼'],
            ['RSD', 'Дин.'],
            ['SCR', '₨'],
            ['SGD', '$'],
            ['SBD', '$'],
            ['SOS', 'S'],
            ['ZAR', 'R'],
            ['LKR', '₨'],
            ['SEK', 'kr'],
            ['CHF', '\u20A3'],
            ['SRD', '$'],
            ['SYP', '£'],
            ['TWD', 'NT$'],
            ['THB', '฿'],
            ['TTD', 'TT$'],
            ['TRY', '₺'],
            ['TVD', '$'],
            ['UAH', '₴'],
            ['GBP', '£'],
            ['USD', '$'],
            ['UYU', '\$U'],
            ['UZS', 'сум'],
            ['VEF', 'Bs'],
            ['VND', '₫'],
            ['YER', '﷼'],
            ['ZWD', 'Z$'],
            // cryp,currencies
            ['BTC', '₿'],
            ['ETH', 'Ξ'],
            ['ADA', '₳'],
            ['USDT', '₮'],
            ['XRP', '✕'],
            ['SOL', '◎'],
            ['DOT', '●'],
            ['DOGE', 'Ð'],
            ['LTC', 'Ł'],
            ['BCH', 'Ƀ'],
            ['ALGO', 'Ⱥ'],
            ['ICP', '∞'],
            ['ETC', 'ξ'],
            ['DAI', '◈'],
            ['XTZ', 'ꜩ'],
            ['XMR', 'ɱ'],
            ['EOS', 'ε'],
            ['MIOTA', 'ɨ'],
            ['BSV', 'Ɓ'],
            ['MKR', 'Μ'],
            ['DASH', 'Đ'],
            ['ZEC', 'ⓩ'],
            ['OMI', 'Ο'],
            ['NANO', 'Ӿ'],
            ['REP', 'Ɍ'],
            ['STEEM', 'ȿ']
        ]
    );

    public GetCurrencySymbol (currency: string): [string, boolean] {
        if (isNullOrUndefined(currency) || !this.isSymbolCurrency) {
            return [currency, false];
        }
        const symbol = this._currencyMap.get(currency.toUpperCase());
        return [symbol ?? currency, !isNullOrUndefined(symbol)];
    }

    public FormatWithCurrencySymbol (priceOrCashFormatted: string, currency: string): string {
        const [currencyStr, hasSymbol] = CurrencyUtils.GetCurrencySymbol(currency);
        return hasSymbol
            ? `${currencyStr} ${priceOrCashFormatted}`
            : `${priceOrCashFormatted} ${currencyStr}`;
    }
}

export const CurrencyUtils = new _CurrencyUtils();
