// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { Color, Pen, SolidBrush } from '../../../Commons/Graphics';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty, ColorStyleWidth } from '../../DynProperty';
import { type LinearRegressionCoefficients } from '@front/chart/TerceraChartUtils';
import { PriceType } from '@shared/utils/History/CashItemUtils';
import { Resources } from '@shared/localizations/Resources';
import { CashItem } from '../History/CashItem';

export class RegressionChannelDataCacheTool extends DataCacheTool {
    public needRecalculate: boolean = false;
    public coef: LinearRegressionCoefficients | null = null;
    public DownLinePen: Pen;
    public UpLinePen: Pen;
    public UpperDeviation: number;
    public LowerDeviation: number;
    public PriceType = PriceType.Open;

    private upBrushColor!: string;
    private downBrushColor!: string;
    public UpBrush!: SolidBrush;
    public DownBrush!: SolidBrush;

    constructor () {
        super();
        this.ToolType = DataCacheToolType.RegressionChannel;
        this.InitPoints(2);
    }

    protected InitBlock (): void {
        super.InitBlock();

        this.colorAlpha = 50;
        this.IsBackground = true;
        this.DownLinePen = new Pen(Color.Red);
        this.UpLinePen = new Pen(Color.Red);
        this.UpBrushColor = Color.Blue;
        this.DownBrushColor = Color.Red;
        this.UpperDeviation = 1;
        this.LowerDeviation = -1;
        this.needRecalculate = true;
        this.DownLinePen.Width = 1;
        this.UpLinePen.Width = 1;
    }

    public get PriceTypeInt (): number {
        switch (this.PriceType) {
        case PriceType.Open: return CashItem.OPEN_INDEX;
        case PriceType.Close: return CashItem.CLOSE_INDEX;
        case PriceType.High: return CashItem.HIGH_INDEX;
        case PriceType.Low: return CashItem.LOW_INDEX;
        case PriceType.Medium: return CashItem.MEDIAN_INDEX;
        case PriceType.Typical: return CashItem.TYPICAL_INDEX;
        case PriceType.Weighted: return CashItem.WEIGHTED_INDEX;
        default: return -1;
        }
    }

    MinPointsCount (): number {
        return 2;
    }

    MaxPointsCount (): number {
        return 2;
    }

    public set UpBrushColor (value: string) {
        if (this.upBrushColor === value) return;
        this.upBrushColor = value;
        this.UpBrush = new SolidBrush(Color.FromArgb(this.colorAlpha, this.upBrushColor));
    }

    public get UpBrushColor (): string {
        return this.upBrushColor;
    }

    public set DownBrushColor (value: string) {
        if (this.downBrushColor === value) return;
        this.downBrushColor = value;
        this.DownBrush = new SolidBrush(Color.FromArgb(this.colorAlpha, this.downBrushColor));
    }

    public get DownBrushColor (): string {
        return this.downBrushColor;
    }

    public get TopColor (): string {
        return this.UpBrushColor;
    }

    public set TopColor (value: string) {
        this.UpBrushColor = value;
    }

    public get BottomColor (): string {
        return this.DownBrushColor;
    }

    public set BottomColor (value: string) {
        this.DownBrushColor = value;
    }

    Properties (): DynProperty[] {
        const props: DynProperty[] = super.Properties();

        let prop = DynProperty.getPropertyByName(props, 'Line');
        if (prop) {
            prop.propertyComment = Resources.getResource('property.chart.regression.RegressionLine');
            prop.sortIndex = 6;
        }

        prop = new DynProperty('UpperDeviation', this.UpperDeviation, DynProperty.INTEGER, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.regression.UpperDeviation');
        prop.sortIndex = 2;
        prop.maximalValue = Number.POSITIVE_INFINITY;
        prop.minimalValue = 1;
        props.push(prop);

        prop = new DynProperty('LowerDeviation', this.LowerDeviation, DynProperty.INTEGER, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.regression.LowerDeviation');
        prop.sortIndex = 3;
        prop.minimalValue = Number.NEGATIVE_INFINITY;
        prop.maximalValue = -1;
        props.push(prop);

        prop = new DynProperty('PriceType', this.PriceType, DynProperty.COMBOBOX_COMBOITEM, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.regression.PriceType');
        prop.sortIndex = 4;
        prop.objectVariants = [
            {
                text: Resources.getResource('property.Open'),
                value: PriceType.Open
            },
            {
                text: Resources.getResource('property.Close'),
                value: PriceType.Close
            },
            {
                text: Resources.getResource('property.High'),
                value: PriceType.High
            },
            {
                text: Resources.getResource('property.Low'),
                value: PriceType.Low
            },
            {
                text: Resources.getResource('property.Typical'),
                value: PriceType.Typical
            },
            {
                text: Resources.getResource('property.Medium'),
                value: PriceType.Medium
            },
            {
                text: Resources.getResource('property.Weighted'),
                value: PriceType.Weighted
            }
        ];
        props.push(prop);

        prop = new DynProperty('UpLine', new ColorStyleWidth(this.UpLinePen.Color, this.UpLinePen.DashStyle, this.UpLinePen.Width), DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.regression.UpLine');
        prop.sortIndex = 5;
        props.push(prop);

        prop = new DynProperty('DownLine', new ColorStyleWidth(this.DownLinePen.Color, this.DownLinePen.DashStyle, this.DownLinePen.Width), DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.regression.DownLine');
        prop.sortIndex = 7;
        props.push(prop);

        prop = new DynProperty('AreaTop', this.upBrushColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.regression.Area.Top');
        prop.sortIndex = 8;
        props.push(prop);

        prop = new DynProperty('AreaBottom', this.downBrushColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.regression.Area.Bottom');
        prop.sortIndex = 9;
        props.push(prop);

        return props;
    }

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        let prop = DynProperty.getPropertyByName(properties, 'UpperDeviation');
        if (prop) this.UpperDeviation = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'LowerDeviation');
        if (prop) this.LowerDeviation = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'PriceType');
        if (prop && typeof prop.value === 'number') this.PriceType = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'UpLine');
        if (prop && prop.value instanceof ColorStyleWidth) {
            const colorStyleWidth = prop.value;
            this.UpLinePen.Color = colorStyleWidth.Color;
            this.UpLinePen.Width = colorStyleWidth.Width;
            this.UpLinePen.DashStyle = colorStyleWidth.Style;
        }

        prop = DynProperty.getPropertyByName(properties, 'DownLine');
        if (prop && prop.value instanceof ColorStyleWidth) {
            const colorStyleWidth = prop.value;
            this.DownLinePen.Color = colorStyleWidth.Color;
            this.DownLinePen.Width = colorStyleWidth.Width;
            this.DownLinePen.DashStyle = colorStyleWidth.Style;
        }

        prop = DynProperty.getPropertyByName(properties, 'AreaTop');
        if (prop) this.UpBrushColor = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'AreaBottom');
        if (prop) this.DownBrushColor = prop.value;

        this.needRecalculate = true;
    }
}
