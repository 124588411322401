// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/**
* A class to parse color values
* @author Stoyan Stefanov <sstoo@gmail.com>
* @link   http://www.phpied.com/rgb-color-parser-in-javascript/
* @license Use it if you like it
*/

/*
// How to use (example):
//
var color = new RGBColor('darkblue');
if (color.ok) { // 'ok' is true when the parsing was a success
// alert channels
alert(color.r + ', ' + color.g + ', ' + color.b);
// alert HEX and RGB
alert(color.toHex());
alert(color.toRGB());
}
*/

export function RGBColor (colorString: string): void {
    this.ok = false;
    this.transparent = false;
    if (!colorString) {
        return;
    }
    if (colorString == 'transparent') {
        this.transparent = true;
        return;
    }
    if (colorString.indexOf('rgb') == 0) { // if rgb format
        colorString = colorString.replace(/ /g, '');
        colorString = colorString.split('(')[1].split(')')[0];
        const colors_array = colorString.split(',');
        this.r = colors_array[0];
        this.g = colors_array[1];
        this.b = colors_array[2];
        this.ok = true;
    } else {
        // strip any leading #
        if (colorString.charAt(0) == '#') { // remove # if any
            colorString = colorString.substr(1, 6);
        }

        colorString = colorString.replace(/ /g, '');
        colorString = colorString.toLowerCase();

        // before getting into regexps, try simple matches
        // and overwrite the input
        const simpleColors = {
            aliceblue: 'f0f8ff',
            antiquewhite: 'faebd7',
            aqua: '00ffff',
            aquamarine: '7fffd4',
            azure: 'f0ffff',
            beige: 'f5f5dc',
            bisque: 'ffe4c4',
            black: '000000',
            blanchedalmond: 'ffebcd',
            blue: '0000ff',
            blueviolet: '8a2be2',
            brown: 'a52a2a',
            burlywood: 'deb887',
            cadetblue: '5f9ea0',
            chartreuse: '7fff00',
            chocolate: 'd2691e',
            coral: 'ff7f50',
            cornflowerblue: '6495ed',
            cornsilk: 'fff8dc',
            crimson: 'dc143c',
            cyan: '00ffff',
            darkblue: '00008b',
            darkcyan: '008b8b',
            darkgoldenrod: 'b8860b',
            darkgray: 'a9a9a9',
            darkgreen: '006400',
            darkkhaki: 'bdb76b',
            darkmagenta: '8b008b',
            darkolivegreen: '556b2f',
            darkorange: 'ff8c00',
            darkorchid: '9932cc',
            darkred: '8b0000',
            darksalmon: 'e9967a',
            darkseagreen: '8fbc8f',
            darkslateblue: '483d8b',
            darkslategray: '2f4f4f',
            darkturquoise: '00ced1',
            darkviolet: '9400d3',
            deeppink: 'ff1493',
            deepskyblue: '00bfff',
            dimgray: '696969',
            dodgerblue: '1e90ff',
            feldspar: 'd19275',
            firebrick: 'b22222',
            floralwhite: 'fffaf0',
            forestgreen: '228b22',
            fuchsia: 'ff00ff',
            gainsboro: 'dcdcdc',
            ghostwhite: 'f8f8ff',
            gold: 'ffd700',
            goldenrod: 'daa520',
            gray: '808080',
            green: '008000',
            greenyellow: 'adff2f',
            honeydew: 'f0fff0',
            hotpink: 'ff69b4',
            indianred: 'cd5c5c',
            indigo: '4b0082',
            ivory: 'fffff0',
            khaki: 'f0e68c',
            lavender: 'e6e6fa',
            lavenderblush: 'fff0f5',
            lawngreen: '7cfc00',
            lemonchiffon: 'fffacd',
            lightblue: 'add8e6',
            lightcoral: 'f08080',
            lightcyan: 'e0ffff',
            lightgoldenrodyellow: 'fafad2',
            lightgrey: 'd3d3d3',
            lightgreen: '90ee90',
            lightpink: 'ffb6c1',
            lightsalmon: 'ffa07a',
            lightseagreen: '20b2aa',
            lightskyblue: '87cefa',
            lightslateblue: '8470ff',
            lightslategray: '778899',
            lightsteelblue: 'b0c4de',
            lightyellow: 'ffffe0',
            lime: '00ff00',
            limegreen: '32cd32',
            linen: 'faf0e6',
            magenta: 'ff00ff',
            maroon: '800000',
            mediumaquamarine: '66cdaa',
            mediumblue: '0000cd',
            mediumorchid: 'ba55d3',
            mediumpurple: '9370d8',
            mediumseagreen: '3cb371',
            mediumslateblue: '7b68ee',
            mediumspringgreen: '00fa9a',
            mediumturquoise: '48d1cc',
            mediumvioletred: 'c71585',
            midnightblue: '191970',
            mintcream: 'f5fffa',
            mistyrose: 'ffe4e1',
            moccasin: 'ffe4b5',
            navajowhite: 'ffdead',
            navy: '000080',
            oldlace: 'fdf5e6',
            olive: '808000',
            olivedrab: '6b8e23',
            orange: 'ffa500',
            orangered: 'ff4500',
            orchid: 'da70d6',
            palegoldenrod: 'eee8aa',
            palegreen: '98fb98',
            paleturquoise: 'afeeee',
            palevioletred: 'd87093',
            papayawhip: 'ffefd5',
            peachpuff: 'ffdab9',
            peru: 'cd853f',
            pink: 'ffc0cb',
            plum: 'dda0dd',
            powderblue: 'b0e0e6',
            purple: '800080',
            red: 'ff0000',
            rosybrown: 'bc8f8f',
            royalblue: '4169e1',
            saddlebrown: '8b4513',
            salmon: 'fa8072',
            sandybrown: 'f4a460',
            seagreen: '2e8b57',
            seashell: 'fff5ee',
            sienna: 'a0522d',
            silver: 'c0c0c0',
            skyblue: '87ceeb',
            slateblue: '6a5acd',
            slategray: '708090',
            snow: 'fffafa',
            springgreen: '00ff7f',
            steelblue: '4682b4',
            tan: 'd2b48c',
            teal: '008080',
            thistle: 'd8bfd8',
            tomato: 'ff6347',
            turquoise: '40e0d0',
            violet: 'ee82ee',
            violetred: 'd02090',
            wheat: 'f5deb3',
            white: 'ffffff',
            whitesmoke: 'f5f5f5',
            yellow: 'ffff00',
            yellowgreen: '9acd32'
        };
        for (const key in simpleColors) {
            if (colorString == key) {
                colorString = simpleColors[key];
            }
        }
        // emd of simple type-in colors

        // array of color definition objects
        const color_defs = [
            {
                re: /^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$/,
                example: ['rgb(123, 234, 45)', 'rgb(255,234,245)'],
                process: function (bits) {
                    return [
                        parseInt(bits[1]),
                        parseInt(bits[2]),
                        parseInt(bits[3])
                    ];
                }
            },
            {
                re: /^(\w{2})(\w{2})(\w{2})$/,
                example: ['#00ff00', '336699'],
                process: function (bits) {
                    return [
                        parseInt(bits[1], 16),
                        parseInt(bits[2], 16),
                        parseInt(bits[3], 16)
                    ];
                }
            },
            {
                re: /^(\w{1})(\w{1})(\w{1})$/,
                example: ['#fb0', 'f0f'],
                process: function (bits) {
                    return [
                        parseInt(bits[1] + bits[1], 16),
                        parseInt(bits[2] + bits[2], 16),
                        parseInt(bits[3] + bits[3], 16)
                    ];
                }
            }
        ];

        // search through the definitions to find a match
        for (let i = 0; i < color_defs.length; i++) {
            const re = color_defs[i].re;
            const processor = color_defs[i].process;
            const bits = re.exec(colorString);
            if (bits) {
                const channels = processor(bits);
                this.r = channels[0];
                this.g = channels[1];
                this.b = channels[2];
                this.ok = true;
            }
        }

        // validate/cleanup values
        this.r = (this.r < 0 || isNaN(this.r)) ? 0 : ((this.r > 255) ? 255 : this.r);
        this.g = (this.g < 0 || isNaN(this.g)) ? 0 : ((this.g > 255) ? 255 : this.g);
        this.b = (this.b < 0 || isNaN(this.b)) ? 0 : ((this.b > 255) ? 255 : this.b);
    }
}

export const RGB2HSB = function (r, g, b) {
    const colors: any = {
        r: r / 255,
        g: g / 255,
        b: b / 255
    };
    const hsb = {
        h: 0,
        s: 0,
        b: 0
    };

    colors.max = Math.max(colors.r, colors.g, colors.b);
    colors.min = Math.min(colors.r, colors.g, colors.b);
    colors.delta = colors.max - colors.min;

    if (colors.max != 0) {
        hsb.s = ((colors.max - colors.min) / colors.max) * 100;
    } else {
        hsb.s = 0;
    }
    hsb.b = colors.max * 100;

    // hsb.h = Math.atan2(Math.sqrt(3) * (colors.g - colors.b), (2 * colors.r - colors.g - colors.b)) * 57.295779513;

    if ((colors.r >= colors.g) && (colors.g >= colors.b)) {
        hsb.h = 60 * (colors.g - colors.b) / (colors.r - colors.b);
    } else if ((colors.g > colors.r) && (colors.r >= colors.b)) {
        hsb.h = 60 * (2 - (colors.r - colors.b) / (colors.g - colors.b));
    } else if ((colors.g >= colors.b) && (colors.b > colors.r)) {
        hsb.h = 60 * (2 + (colors.b - colors.r) / (colors.g - colors.r));
    } else if ((colors.b > colors.g) && (colors.g > colors.r)) {
        hsb.h = 60 * (4 - (colors.g - colors.r) / (colors.b - colors.r));
    } else if ((colors.b > colors.r) && (colors.r >= colors.g)) {
        hsb.h = 60 * (4 + (colors.r - colors.g) / (colors.b - colors.g));
    } else if ((colors.r >= colors.b) && (colors.b > colors.g)) {
        hsb.h = 60 * (6 - (colors.b - colors.g) / (colors.r - colors.g));
    }

    if (isNaN(hsb.h)) hsb.h = 0;

    return hsb;
};

export const HSB2RGB = function (h, s, b) {
    const rgb: any = { r: 0, g: 0, b: 0 };

    var h = h;
    var s = s;
    let v = b;

    if (s == 0) {
        if (v == 0) {
            rgb.r = rgb.g = rgb.b = 0;
        } else {
            rgb.r = rgb.g = rgb.b = Math.floor(v * 255 / 100);
        }
    } else {
        if (h == 360) {
            h = 0;
        }
        h /= 60;

        // 100 scale
        s = s / 100;
        v = v / 100;

        const i = parseInt(h);
        const f = h - i;
        const p = v * (1 - s);
        const q = v * (1 - (s * f));
        const t = v * (1 - (s * (1 - f)));
        switch (i) {
        case 0:
            rgb.r = v;
            rgb.g = t;
            rgb.b = p;
            break;
        case 1:
            rgb.r = q;
            rgb.g = v;
            rgb.b = p;
            break;
        case 2:
            rgb.r = p;
            rgb.g = v;
            rgb.b = t;
            break;
        case 3:
            rgb.r = p;
            rgb.g = q;
            rgb.b = v;
            break;
        case 4:
            rgb.r = t;
            rgb.g = p;
            rgb.b = v;
            break;
        case 5:
            rgb.r = v;
            rgb.g = p;
            rgb.b = q;
            break;
        }

        rgb.r = Math.floor(rgb.r * 255);
        rgb.g = Math.floor(rgb.g * 255);
        rgb.b = Math.floor(rgb.b * 255);
    }
    rgb.hex = '' + (16777216 | rgb.b | (rgb.g << 8) | (rgb.r << 16)).toString(16).slice(1);
    return rgb;
};
