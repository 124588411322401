// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Rectangle } from '../../Geometry';
import { type Font } from '../../Graphics';

export class HeatMapFontManagerResult {
    public logoRectangle: Rectangle;
    public titleRectangle: Rectangle;
    public firstValueRectangle: Rectangle;
    public secondValueRectangle: Rectangle;

    public logoSize: number;
    public titleFont: Font;
    public firstValueFont: Font;
    public secondValueFont: Font;
}
