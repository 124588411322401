// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { PositionInfoViewTemplate } from '../../templates.js';
import { PositionItem } from '../cache/PositionItem';
import { Control } from './Control';
import { OperationType } from '../../Utils/Trading/OperationType';
import { ThemeManager } from '../misc/ThemeManager';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { type Position } from '../../Commons/cache/Position';
import { ResourcesManager } from '../../Commons/properties/ResourcesManager';

// TODO. Refactor. View template duplicate of OrderInfoView.
// I'm not using computed properties here and other cool stuff cuz of bugs.
export class PositionInfoView extends Control {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.observe('position', this.updateFields);
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        Control.Ticker.Subscribe(this.tickAsync, this);
        this.localize();
    }

    public override dispose (): void {
        Control.Ticker.UnSubscribe(this.tickAsync, this);
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public updateFields (pos: Position): void {
        if (isNullOrUndefined(pos)) return;

        const ins = pos.Instrument;
        const buy = pos.BuySell === OperationType.Buy;

        void this.set({
            hideRoute: ins.isHideRouteMode,
            fPositionId: pos.PositionId,
            fSide: Resources.getResource('general.trading.position.' + (buy ? 'Buy' : 'Sell')),
            // TODO. Ugly, i know.
            fAmount: PositionItem.GetPositionAmountText(pos),
            fOpenPrice: ins.formatPrice(pos.Price),
            fDateTime: DateTimeUtils.FormatToDateAndTime(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(pos.OpenTime)),
            fInstrumentName: ins.DisplayName(),
            fRoute: ins.getRoute(),
            sideColor: ThemeManager.CurrentTheme[buy ? 'BuyColor' : 'SellColor'],
            fLeverage: OrderUtils.GetFormattedLeverage(pos.Leverage),
            showProductType: ins.isProductTypeVisible(pos.Account),
            fProductType: InstrumentUtils.GetLocalizedProductType(ins, pos.ProductType),
            height: (ins.isHideRouteMode ? 140 : 160) + (pos.Leverage ? 20 : 0)
        });
    }

    public tickAsync (): void {
        const pos = this.get('position');
        if (isNullOrUndefined(pos)) return;

        const ins = pos.Instrument;
        void this.set('fMarketPrice', ins.formatPrice(pos.CurPriceClose));
    }

    public localize (): void {
        void this.set({
            positionIdLabel: Resources.getResource('screen.modifyOrder.number'),
            instrumentLabel: Resources.getResource('screen.modifyOrder.instrument'),
            routeLabel: Resources.getResource('screen.modifyOrder.route'),
            sideLabel: Resources.getResource('screen.modifyOrder.operation'),
            amountLabel: Resources.getResource('screen.modifyOrder.amount'),
            marketPriceLabel: Resources.getResource('screen.modifyOrder.market_price'),
            openPriceLabel: Resources.getResource('screen.modifyOrder.price'),
            dateTimeLabel: Resources.getResource('screen.modifyOrder.created'),
            leverageLabel: Resources.getResource('screen.modifyOrder.leverage'),
            productTypeLabel: Resources.getResource('screen.modifyOrder.productType')
        });

        this.updateFields(this.get('position'));
    }
}

Control.extendWith(PositionInfoView, {
    template: PositionInfoViewTemplate,
    data: function () {
        return {
            positionIdLabel: '',
            instrumentLabel: '',
            routeLabel: '',
            sideLabel: '',
            leverageLabel: '',
            productTypeLabel: '',
            amountLabel: '',
            marketPriceLabel: '',
            openPriceLabel: '',
            dateTimeLabel: '',

            position: null,

            hideRoute: false,
            showProductType: false,

            fPositionId: '',
            fMarketPrice: '',
            fOpenPrice: '',
            fDateTime: '',
            fAmount: '',
            fSide: '',
            fLeverage: '',
            fInstrumentName: '',
            fRoute: '',
            fProductType: '',

            sideColor: '',

            height: 0
        };
    }
});
