// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ReportRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_REPORT_REQUEST_MESSAGE, fieldSet);
    }

    public getTemplateName (): string {
        return this.getFieldValue(FieldsFactory.FIELD_TEMPLATE);
    }

    public setTemplateName (name: string): void {
        this.setFieldValue(FieldsFactory.FIELD_TEMPLATE, name);
    }
}
