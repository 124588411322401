// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { IchimokuMode } from '../../IndicatorConstants';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iIchimoku extends iBuildInIndicator {
    public FTenkanSen: any;
    public FKijunSen: any;
    public FSenkouSpanB: any;
    public GetAverage: (period: any, offset: any) => number;
    public SenkouSpanB: number;

    constructor (tenkanSen, kijunSen, senkouSpanB) {
        super(5);

        this.FTenkanSen = tenkanSen;
        this.FKijunSen = kijunSen;
        this.FSenkouSpanB = senkouSpanB;

        this.FData.FArray[2].FShift = this.FKijunSen;
        this.FData.FArray[3].FShift = this.FKijunSen;

        this.GetAverage = function (period, offset) {
            let high = this.GetPrice(PriceType.High, offset);
            let low = this.GetPrice(PriceType.Low, offset);
            let price = 0.0;
            for (let i = offset + 1; i < offset + period; i++) {
                price = this.GetPrice(PriceType.High, i);
                if (high < price) {
                    high = price;
                }
                price = this.GetPrice(PriceType.Low, i);
                if (low > price) {
                    low = price;
                }
            }
            return (high + low) / 2.0;
        };
    }

    override get Name (): string { return 'iIchimoku'; }

    override get Key (): string { return this.DefaultKey + this.FTenkanSen + this.FKijunSen + this.FSenkouSpanB; }

    public override OnQuote (): void {
        if (this.FCount >= this.FTenkanSen) {
        // 1 line
            this.SetValue(IchimokuMode.Tenkansen, 0, this.GetAverage(this.FTenkanSen, 0));
        }

        if (this.FCount >= this.FKijunSen) {
        // 2 line
            this.SetValue(IchimokuMode.Kijusen, 0, this.GetAverage(this.FKijunSen, 0));
            // 5 line
            this.SetValue(IchimokuMode.Chinkouspan, this.FKijunSen, this.GetPrice(PriceType.Close, 0));
        }

        if (this.FCount >= this.FTenkanSen && this.FCount >= this.FKijunSen) {
        // 3 line
            const senkouSpanA = (this.GetValue(IchimokuMode.Tenkansen, 0) + this.GetValue(IchimokuMode.Kijusen, 0)) / 2;
            this.SetValue(IchimokuMode.Senkouspana, 0, senkouSpanA);
        }

        if (this.FCount >= this.SenkouSpanB) {
        // 4 line
            this.SetValue(IchimokuMode.Senkouspanb, 0, this.GetAverage(this.SenkouSpanB, 0));
        }
    }
}
