// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class WarningForChangeFromLastPrice {
    public ranges: any;
    constructor (ranges) {
        this.ranges = ranges;
    }

    public GetChangeByPrice (price): any {
        const r = this.ranges;

        if (!r) { return null; };

        for (let i = r.length - 1; i >= 0; i--) {
            const ir = r[i];
            if (price >= ir.LastPrice) { return ir.LastChange; };
        }

        return null;
    }
}
