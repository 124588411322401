// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ConectionResultEnum } from '../../Commons/ConnectionEnums';
import { Resources } from '../../Localizations/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { KeyCode, KeyEventProcessor } from '../../Commons/KeyEventProcessor';
import { TerceraBaseWindowTemplate, AdditionalVerificationScreenTemplate } from '../../templates.js';
import { type TerceraButton } from '../elements/TerceraButton';
import { TerceraWindowBase } from './TerceraWindowBase';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';

export class AdditionalVerificationScreen extends TerceraWindowBase {
    public forceCloseOnLogout: boolean = false;

    public override oninit (): void {
        super.oninit();

        this.on('okClick', this.okClick);
        this.on('cancelClick', this.cancelClick);
    // this.set({ pictureUrl: App.ThemeManager.getImageFullUrl(imgUrl) });
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.center();
    }

    public okClick (): void {
        const okCallBack = this.get('okCallBack');
        if (!isNullOrUndefined(okCallBack)) {
            okCallBack(this.get('verificationValue'));
        }
        this.close();
    }

    public cancelClick (): void {
        const cancelCallBack = this.get('cancelCallBack');
        if (!isNullOrUndefined(cancelCallBack)) {
            cancelCallBack();
        }
        this.close();
    }

    // Close button (cross icon).
    public override onCloseButtonClick (): void {
        if (this.canCloseFromButton()) {
            this.cancelClick();
        }
    }

    public static Show (initRespons, okCB: any, cancelCB: TerceraButton, connectionParams): void {
        const isNeedActivation = !!(!isNullOrUndefined(initRespons) && initRespons.connectReason === ConectionResultEnum.NeedActivation);

        let headerText = Resources.getResource('screen.additionalVerification.title');
        if (!isNullOrUndefined(initRespons)) {
            switch (initRespons.verificationModeCode) {
            case VerificationMode.Sms:
                headerText = Resources.getResource('screen.additionalVerification.title.Sms');
                break;
            case VerificationMode.Email:
                headerText = Resources.getResource('screen.additionalVerification.title.Email');
                break;
            case VerificationMode.Fixed:
                headerText = Resources.getResource('screen.additionalVerification.title.Fixed');
                break;
            case VerificationMode.SmsPlusEmailOneTimePass:
                headerText = Resources.getResource('screen.additionalVerification.title.SmsPlusEmailOneTimePass');
                break;
            case VerificationMode.DailySmsPlusEmailOneTimePass:
                headerText = Resources.getResource('screen.additionalVerification.title.DailySmsPlusEmailOneTimePass');
                break;
            }
        }
        headerText = isNeedActivation ? Resources.getResource('screen.additionalVerification.Need activation') : headerText;
        const innerText = isNeedActivation ? Resources.getResource('screen.additionalVerification.Need activation.comment') : (!isNullOrUndefined(connectionParams) ? Resources.getResource('screen.additionalVerification.comment').replace('{1}', connectionParams.connectionName) : headerText);// "Connection ({1}) required additional verification:"
        const plHolder = isNeedActivation ? Resources.getResource('screen.additionalVerification.Need activation.watermark') : Resources.getResource('screen.additionalVerification.watermark');

        const verScreen = new AdditionalVerificationScreen();
        void verScreen.set({
            allowPreviewPassword: connectionParams?.allowPreviewPassword,
            isNeedActivation,
            okCallBack: okCB,
            cancelCallBack: cancelCB,
            header: headerText,
            reasonText: innerText,
            verificationValuePlaceholder: plHolder
        });

        MainWindowManager.MainWindow.addControl(verScreen);

        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.Subscribe(verScreen.onGlobalKeyDown, verScreen);
    }

    public onGlobalKeyDown (code: KeyCode): void {
        if (this.get<boolean>('focused')) {
            if (code === KeyCode.ESC) {
                this.cancelClick();
            }
            if (code === KeyCode.ENTER) {
                this.okClick();
            }
        }
    }

    public override onteardown (): void {
        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
    }
}

enum VerificationMode {
    None = 0,
    Sms = 1,
    Email = 2,
    Fixed = 3,
    Token = 4,
    SmsPlusEmailOneTimePass = 5,
    DailySmsPlusEmailOneTimePass = 6
}

TerceraWindowBase.extendWith(AdditionalVerificationScreen, {
    // template
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            zIndex: 300,
            height: 145,
            width: 445,

            resizable: false,
            showFooter: false,

            okText: 'Ok',
            cancelText: 'Cancel',
            btnStyle: TerceraButtonStyle.Standard,

            verificationValue: '',
            verificationValuePlaceholder: '<Enter activation code from E-mail>',
            header: 'Need activation',
            reasonText: 'Please enter activation code (sent to your mail)',
            isNeedActivation: true,
            allowPreviewPassword: false,

            okCallBack: null,
            cancelCallBack: null
        };
    },
    partials: { bodyPartial: AdditionalVerificationScreenTemplate }
});
