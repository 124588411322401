// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { HeatmapViewSettings } from './HeatmapViewSettings';
import { type IHeatmapSettings } from './IHeatmapSettings';
import { type IHeatmapViewSettings } from './IHeatmapViewSettings';

export class HeatmapSettings implements IHeatmapSettings {
    readonly viewSettings: IHeatmapViewSettings;
    constructor () {
        this.viewSettings = new HeatmapViewSettings();
    }
}
