// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// #region TerceraMultiComboBoxPopup
import { TerceraMultiComboBoxPopupTemplate } from '../../templates.js';
import { HtmlScroll } from '../../Commons/HtmlScroll';
import { ContainerControl } from './ContainerControl';
import { CheckBoxCheckedStates } from './TerceraCheckBox';
import { TerceraMenu } from './TerceraMenu';
import { Rectangle } from '../../Commons/Geometry';
import { Resources } from '../../Localizations/Resources';
import { multiComboBoxPopupMenuHandler } from '../../Utils/AppHandlers';

export class TerceraMultiComboBoxPopup extends ContainerControl {
    public lastIndex: number | null = null;
    public callBack: any;
    public scrollIsShown: boolean;

    public override oninit (): void {
        super.oninit();
        this.observe('items', this.onItemsChanged);
        this.observe('checkBoxCheckedState', this.onCheckBoxCheckedStateChanged);
    }

    public onItemsChanged (items): void {
        if (!isValidArray(items)) {
            void this.set('checkBoxCheckedState', CheckBoxCheckedStates.None);
            return;
        }

        let checkedCount = 0;
        const len = items.length;
        let lastIndex = null;
        for (let i = 0; i < len; i++) {
            const item = items[i];
            if (item.checked) {
                checkedCount++;
                lastIndex = i;
            }
        }
        if (!checkedCount && this.lastIndex != null && this.get<boolean>('useLastItem')) {
            items[this.lastIndex].checked = true;
            void this.set('items', items);
            return;
        }
        this.lastIndex = lastIndex;

        if (checkedCount === 0) {
            void this.set('checkBoxCheckedState', CheckBoxCheckedStates.None);
        } else if (checkedCount === len) {
            void this.set('checkBoxCheckedState', CheckBoxCheckedStates.Checked);
        } else {
            void this.set('checkBoxCheckedState', CheckBoxCheckedStates.Indeterminate);
        }
    }

    public onCheckBoxCheckedStateChanged (val): void {
        if (val === CheckBoxCheckedStates.Indeterminate) {
            return;
        }

        const items = this.get('items');
        if (!isValidArray(items)) { return; }

        const check = val === CheckBoxCheckedStates.Checked;

        let valueChanged = false;

        for (let i = 0, len = items.length; i < len; i++) {
            const item = items[i];
            if (isNullOrUndefined(item) || item.checked === check) {
                continue;
            }

            item.checked = check;
            valueChanged = true;
        }

        if (valueChanged) {
            void this.update('items');
        }
    }

    public Hide (): void {
        this.lastIndex = null;
        if (!isNullOrUndefined(this.callBack)) {
            this.callBack(this.get('items'));
            delete this.callBack;
        }

        void this.set('items', null);
        void this.set('visible', false);
        void this.set('hideAll', false);
        void this.set('useLastItem', false);

        if (this.scrollIsShown) {
            HtmlScroll.removeScroll(this.find('.items-container'));
            this.scrollIsShown = false;
        }
    }

    public Show (items: any[], x: number, y: number, width: number, callBack, hideAll: boolean, useLastItem: boolean): void {
        this.localize();
        void this.set('items', items);
        void this.set('hideAll', hideAll);
        void this.set('useLastItem', useLastItem);
        this.callBack = callBack;
        void this.set('visible', true);

        let height = items ? (items.length + 1) * 25 + 4 : 0;
        if (hideAll) { height -= 25; }
        if (height > 280) {
            height = 280;
            this.scrollIsShown = true;
        }

        const newLoc = TerceraMenu.CorrectPopupLocation(
            new Rectangle(x, y, width, height));

        this.setBounds(newLoc.newX, newLoc.newY, width, height);

        if (this.scrollIsShown) {
            HtmlScroll.addScroll(this.find('.items-container'));
        }
    }

    public localize (): void {
        void this.set('allText', Resources.getResource('PopupMultiCkeckBox.All'));
    }

    public ProcessOnMouseDown (caller): void {
        const callerParent = caller.parent;

        if (callerParent !== this) {
            this.Hide();
        }
    }

    public static MultiComboBoxPopupHandlerInitialize (): void {
        const multiComboBoxPopup = multiComboBoxPopupMenuHandler;
        multiComboBoxPopup.Init = () => {
            const _multiComboBoxPopup = new TerceraMultiComboBoxPopup();
            multiComboBoxPopup.Show = _multiComboBoxPopup.Show.bind(_multiComboBoxPopup);
            multiComboBoxPopup.Hide = _multiComboBoxPopup.Hide.bind(_multiComboBoxPopup);
            multiComboBoxPopup.ProcessOnMouseDown = _multiComboBoxPopup.ProcessOnMouseDown.bind(_multiComboBoxPopup);
            multiComboBoxPopup.isShowed = () => { return _multiComboBoxPopup.get('visible'); };
        };
    }
}

// #endregion

ContainerControl.extendWith(TerceraMultiComboBoxPopup, {
    el: '#multiComboBoxContainer',
    data: function () {
        return {
            allText: '',
            items: null,
            visible: false,
            useLastItem: false,
            checkBoxCheckedState: CheckBoxCheckedStates.None
        };
    },
    template: TerceraMultiComboBoxPopupTemplate
});
