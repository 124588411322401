// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TimeSpan, TimeSpanPeriods, TimeSpanFormat } from '@shared/utils/Time/TimeSpan';
import { HistoryType } from '@shared/utils/History/HistoryType';
import { ChartDataType } from '@front/chart/Utils/ChartConstants';
import { ErrorInformationStorage } from '../../ErrorInformationStorage';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { ReloadHistoryParams } from '@shared/utils/History/ReloadHistoryParams';
import { Periods, type TFInfo } from '@shared/utils/History/TFInfo';
import { type DirectQuoteMessage, Message } from '@shared/utils/DirectMessages/DirectMessagesImport';
import { PriceType } from '@shared/utils/History/CashItemUtils';
import { BaseInterval } from '@shared/utils/History/BaseInterval';
import { type Instrument } from '../Instrument';

import { type QuoteCache } from '../QuoteCache';
import { type SessionInfo } from '@shared/utils/History/SessionInfo';
import { type SpreadPlan } from '../SpreadPlan';
import { type SpreadItem } from '../SpreadItem';
import { type IndicatorScriptBase } from '../indicators/IndicatorScriptBase';
import { BaseIntervalInputParams } from '@shared/utils/History/BaseIntervalInputParams';
import { HistoryMergerInputParams } from '@shared/utils/History/HistoryMergerInputParams';
import { SetHistoryCreatorInstance, GetHistoryCreator } from '@shared/utils/History/IHistoryMergerCreator';
import { HistoryMergerCreator } from './Aggregations/HistoryMergerCreator';
import { NOT_FOUND_INTERVAL_VALUE } from './HistoryConstants';
import { SessionPeriod } from '@shared/utils/Session/SessionPeriod';
//
// Хранение истории. Аналог кешитема в клиенте
//
export class CashItem {
    public static readonly TIME_INDEX = 0;
    public static readonly OPEN_INDEX = 1;
    public static readonly CLOSE_INDEX = 2;
    public static readonly HIGH_INDEX = 3;
    public static readonly LOW_INDEX = 4;
    public static readonly VOLUME_INDEX = 5;
    public static readonly VOLUME_ASK_INDEX = 6;
    public static readonly TIME_CLOSE_INDEX = 7;
    public static readonly MEDIAN_INDEX = 8;
    public static readonly TYPICAL_INDEX = 9;
    public static readonly WEIGHTED_INDEX = 10;

    public FSymbol: string;
    public Instrument: Instrument;
    public TimeFrameInfo: TFInfo;
    public FPeriod: number;
    public ChartDataType: number;
    public AdditionalKey: string;
    public Plan: SpreadPlan | null;
    public HistoryType: number;
    public SessionInfo: SessionInfo;
    public Parent: QuoteCache;
    public FNonEmptyCashArray: BaseInterval[];
    public FNonEmptyCashArrayCount: number;
    private FStartTime: number;
    public FLoading: boolean;
    public HistoryExpanded: CustomEvent;
    public HistoryReload: CustomEvent;
    public QuoteProcessed: CustomEvent;
    public dayHigh: number;
    public dayLow: number;
    public lastLoadedBarsCount: number = 0;

    public get StartTime (): number {
        return this.FStartTime;
    }

    public get onlyMainSession (): boolean {
        return this.SessionInfo?.OnlyMainSession ?? false;
    }

    public spreadItem: SpreadItem;
    public FFuncList: IndicatorScriptBase[];

    private _hasHistory: boolean;
    private collectTickQuotes: boolean;
    private readonly collectedQuoteMessages: any[];
    // private SyncronizedSessionFlag: boolean;

    constructor (symbol: string, parent: QuoteCache, timeFrameInfo: TFInfo) {
        this.FSymbol = symbol;
        this.Instrument = parent.DataCache.getInstrumentByName(this.FSymbol);
        this.TimeFrameInfo = timeFrameInfo;

        this.FPeriod = timeFrameInfo.Periods;
        this.ChartDataType = timeFrameInfo.ChartDataType;
        this.AdditionalKey = timeFrameInfo.AdditionalKey;
        this.Plan = timeFrameInfo.Plan;
        this.HistoryType = timeFrameInfo.HistoryType;

        const sesInfo = timeFrameInfo.SessionInfo;
        this.SessionInfo = sesInfo;

        this.Parent = parent;

        // Здесь лежат бары
        this.FNonEmptyCashArray = [];
        /// <summary>
        /// Для быстрого доступа к размеру FNonEmptyCashArray (необходимо всегда синхронизировать)
        /// </summary>
        this.FNonEmptyCashArrayCount = 0;
        /// <summary>
        /// Стартовое время истории
        /// </summary>
        this.FStartTime = 0;

        this.FLoading = false;

        this._hasHistory = false;
        this.HistoryExpanded = new CustomEvent();
        this.HistoryReload = new CustomEvent();
        this.QuoteProcessed = new CustomEvent();

        /// //////////////////////////////////////////
        this.spreadItem = !isNullOrUndefined(this.Plan) ? this.Plan.GetItem(this.Instrument) : null;

        this.FFuncList = [];

        this.collectTickQuotes = true;
        this.collectedQuoteMessages = [];

        SetHistoryCreatorInstance(HistoryMergerCreator);

        // this.SyncronizedSessionFlag = null;     // #100139
    }

    public getType (): string { return 'CASH_ITEM_CONTROLLER'; };

    updateItemSortingFunction (a, b): number {
        if (a.FLeftTimeTicks > b.FLeftTimeTicks) { return 1; } else { return -1; }
    }

    // TODO. Rename.
    getNonEmptyRightBorderTime (): number {
        const arr = this.FNonEmptyCashArray;
        if (!arr?.length) return 0;

        return arr[arr.length - 1].FRightTimeTicks;
    }

    //
    // Перезагрузка истории
    //
    async Reload (/* ReloadHistoryParams */ historyParams, signal, subscribe): Promise<any> {
        if (historyParams == null) { historyParams = new ReloadHistoryParams(); }

        this.FLoading = true;
        this.collectTickQuotes = true;

        const intervals = await this.ReloadHistoryCallback(historyParams, signal);
        const aggregatedByServer: boolean = historyParams.ShouldCheckAggregatedPeriods;
        this.historyLoaded(intervals, historyParams, subscribe, !aggregatedByServer);
        this.collectTickQuotes = false;
        this.FLoading = false;
        return this;
    }

    expandAndMergeHistory (history: BaseInterval[], needAggregate: boolean, historyParams: ReloadHistoryParams): BaseInterval[] {
        const tfInfo = this.TimeFrameInfo;
        const originalPeriod = tfInfo.Periods;
        const step = this.GetBasePeriodExtended(originalPeriod);
        let historyN = history;

        historyN = CashItem.ExpandHistory(historyN, historyParams);
        if (step !== 1 && needAggregate) {
            const inputParams = new HistoryMergerInputParams();
            inputParams.BaseHistory = history;
            inputParams.NewPeriod = originalPeriod;
            inputParams.Instrument = this.Instrument;
            inputParams.ShowExtendedSession = !this.onlyMainSession;
            const historyMerger = GetHistoryCreator().GetHistoryMerger(inputParams);
            historyN = historyMerger.MergeHistory();
        }

        return historyN;
    }

    historyLoaded (history, historyParams: ReloadHistoryParams, subscribe = true, needAggregate = true): void {
        const historyN = this.expandAndMergeHistory(history, needAggregate, historyParams);

        this.FNonEmptyCashArray = historyN;
        this.FNonEmptyCashArrayCount = this.FNonEmptyCashArray.length;
        this.lastLoadedBarsCount = this.FNonEmptyCashArrayCount;
        if (this.FNonEmptyCashArrayCount > 0) { this.FStartTime = this.FNonEmptyCashArray[0].FLeftTimeTicks; }
        this._hasHistory = true;

        this.collectTickQuotes = false;

        if (subscribe) {
            const lastBar = this.FNonEmptyCashArrayCount > 0 ? this.FNonEmptyCashArray[this.FNonEmptyCashArrayCount - 1] : null;
            const lastQuoteId = lastBar !== null ? lastBar.LastQuoteId : null;
            const lastQuoteIdDate = lastBar !== null ? lastBar.LastQuoteIdDate : null;
            if (lastBar !== null) {
                this.collectedQuoteMessages.map(function (quote) {
                    if (quote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) {
                        this.newQuote(quote); // hsa: апдейтим всегда?
                        return;
                    }

                    const quoteTimeTicks = quote.cTime.getTime();

                    // 1 - время котировки меньше чем левая граница
                    if (quoteTimeTicks < lastBar.FLeftTimeTicks) {
                        return;
                    }

                    // 2 - кейс только для бар-интервальной истории - время котировки меньше чем последняя котировка записанная в бар
                    if (lastQuoteIdDate !== null &&
                        quoteTimeTicks < lastQuoteIdDate) {
                        return;
                    }

                    const quoteId = quote.QuoteUniqueID;

                    // 3 - кейс для бар-интервальной истории или для тиковой истории (может быть несколько котировок в одну еденицу времени)
                    if (lastQuoteId != null && quoteId != null && quoteId <= lastQuoteId) {
                        return;
                    };

                    this.newQuote(quote);
                }.bind(this));
            }
            this.collectedQuoteMessages.length = 0; // мы можем это сделать, квоты залочены
        }

        this.HistoryReload.Raise(this);
        this.FLoading = false;
    }

    InsertPaddingHistory (paddingInvervals: BaseInterval[], historyParams: ReloadHistoryParams): void {
        const aggregatedByServer = historyParams.ShouldCheckAggregatedPeriods;
        const paddingHistory = this.expandAndMergeHistory(paddingInvervals, !aggregatedByServer, historyParams);
        this.lastLoadedBarsCount = paddingHistory.length;
        this.FNonEmptyCashArray.unshift(...paddingHistory);
        this.FNonEmptyCashArrayCount = this.FNonEmptyCashArray.length;

        if (this.FNonEmptyCashArrayCount > 0) {
            this.FStartTime = this.FNonEmptyCashArray[0].FLeftTimeTicks;
        }
    }

    GetBasePeriodExtended (period): number {
        if (period < Periods.TIC) {
            return Math.abs(period);
        } else if (period === Periods.TIC || period === Periods.RANGE) {
            // Тиков - один
            return 1;
        }
        // Начинаем деление с самого большого базового периода
        else if (period % Periods.SECOND === 0) {
            // ~ 5 тиков на секунду
            return 5 * (period / Periods.SECOND);
        } else if (period % Periods.YEAR === 0 ||
            period % Periods.MONTH === 0 ||
            period % Periods.WEEK === 0 ||
            period % Periods.DAY === 0) {
            // Количество базовых баров для формирования одного расширенного бара
            return period / Periods.DAY;
        } else {
            // Количество базовых баров для формирования одного расширенного
            return period / Periods.MIN;
        }
    }

    /// <summary>
    /// Заполнение дырок в закачанной истории нулями (для корректного слияния в кастом-историю)
    /// </summary>
    public static ExpandHistory = function (baseHistory: BaseInterval[], historyParams: ReloadHistoryParams): BaseInterval[] {
        const history: BaseInterval[] = [];
        const bhL = baseHistory.length;
        if (baseHistory.length == 0) { return history; }

        try {
            // nicky fix
            // если придет бар с нулевым временем, клиент зависнет, пытаясь заполнить все эти дырки пустыми барами
            let needControlLeftBorder = false;
            let notTick = false;
            if (bhL > 0) {
                const bi0 = baseHistory[0];
                needControlLeftBorder = bi0.FLeftTimeTicks <= 0;
                notTick = bi0.Data.length >= 4;
            }

            for (let i = 0; i < bhL; i++) {
                const bi = baseHistory[i];

                if (needControlLeftBorder && bi.FLeftTimeTicks <= 0) {
                    historyParams.count--;
                    console.warn(`ExpandHistory: bi.FLeftTimeTicks <= 0 (Invalid time ${bi.LeftTime})`);
                    continue;
                }

                // Только реальные данные
                if (/* bi.IsEmpty || */ !bi.IsValid) {
                    historyParams.count--;
                    console.warn(`ExpandHistory: !bi.IsValid (Invalid time ${bi.LeftTime})`);
                    continue;
                }

                const bi2 = (i + 1 < bhL) ? baseHistory[i + 1] : null;
                if (notTick && bi2 != null && bi.FLeftTimeTicks === bi2.FLeftTimeTicks) {
                    historyParams.count--;
                    console.warn(`ExpandHistory: has duplicate bar (Invalid time ${bi.LeftTime})`);
                    continue;
                }
                //
                history.push(bi);
            }
        } catch (ex) {
        }

        return history;
    };

    // TODO. Refactor.
    GetByType (index, priceType) {
        if (index >= 0 && index < this.FNonEmptyCashArray.length) {
            const bi = this.FNonEmptyCashArray[index];

            // if (FPeriod == 0 && ChartDataType == cash.ChartDataType.Default)
            //    return bi.Data[priceType == PriceType.Close ? 1 : 0];

            if (priceType < 4) { return bi.Data[priceType]; };

            if (priceType === PriceType.Medium) { return bi.Median; } else if (priceType === PriceType.Typical) { return bi.Typical; } else if (priceType === PriceType.Weighted) { return bi.Weighted; } else { return 0; }
        } else { return 0; };
    }

    GetByConst (index, priceType) {
        if (index >= 0 && index < this.FNonEmptyCashArray.length) {
            const bi = this.FNonEmptyCashArray[index];

            // if (FPeriod == 0 && ChartDataType == cash.ChartDataType.Default)
            //    return bi.Data[priceType == PriceType.Close ? 1 : 0];

            // if (priceType < 4)
            if (priceType === CashItem.TIME_INDEX) { return bi.FLeftTimeTicks; }
            if (priceType === CashItem.TIME_CLOSE_INDEX) { return bi.FRightTimeTicks; }

            switch (priceType) {
            case CashItem.OPEN_INDEX:
                return bi.Data[BaseInterval.OPEN_INDEX];
            case CashItem.CLOSE_INDEX:
                return bi.Data[BaseInterval.CLOSE_INDEX];
            case CashItem.HIGH_INDEX:
                return bi.Data[BaseInterval.HIGH_INDEX];
            case CashItem.LOW_INDEX:
                return bi.Data[BaseInterval.LOW_INDEX];
            case CashItem.MEDIAN_INDEX:
                return bi.Median;
            case CashItem.TYPICAL_INDEX:
                return bi.Typical;
            case CashItem.WEIGHTED_INDEX:
                return bi.Weighted;
            }

            if (priceType === CashItem.VOLUME_INDEX) { return bi.Volume; } else if (priceType === PriceType.Typical) { return bi.Typical; } else if (priceType === PriceType.Weighted) { return bi.Weighted; } else { return 0; }
        } else { return 0; }
    };

    Dispose () {
        this.UnSubscribeCurrentInstrument();
    };

    SubscribeCurrentInstrument () {
        if (this.Parent?.addListener) {
            let subsType = this.HistoryType;
            if (subsType === HistoryType.BIDASK_AVG || subsType === HistoryType.ASK) { subsType = HistoryType.BID; };

            this.Parent.addListener(this.Instrument, this, subsType);
        }
    }

    UnSubscribeCurrentInstrument () {
        if (this.Parent?.removeListener) {
            let subsType = this.HistoryType;
            if (subsType === HistoryType.BIDASK_AVG || subsType === HistoryType.ASK) { subsType = HistoryType.BID; };

            this.Parent.removeListener(this.Instrument, this, subsType);
        }
    }

    Count () {
        return this.FNonEmptyCashArray.length;
    }

    GetVolume (index) {
        if (index >= 0 && index < this.FNonEmptyCashArrayCount) { return this.FNonEmptyCashArray[index].Volume; } else { return 0; }
    }

    GetVolumeAndTicks (index) {
        if (index < 0 || index >= this.FNonEmptyCashArrayCount || this.ChartDataType != ChartDataType.Default) {
            return { volume: null, ticks: null };
        }

        if (this.HistoryType == HistoryType.LAST) {
            if (this.FPeriod == 0) {
                return { ticks: 1, volume: this.FNonEmptyCashArray[index].Volume };
            } else {
                const bi = this.FNonEmptyCashArray[index];
                return { ticks: bi.Ticks, volume: bi.Volume };
            }
        } else {
            return { ticks: this.FNonEmptyCashArray[index].Volume, volume: null };
        }
    }

    public get Symbol (): string { return this.FSymbol; }
    public get Period (): number { return this.FPeriod; }

    /// <summary>
    /// Поиск временного интервала, в который
    /// попадает данное время
    /// </summary>
    public FindIntervalByTime (time: number): number {
        return this.FindInterval(time, 0, this.Count() - 1);
    };

    public FindNearestIntervalByTime (time: number): number {
        const [index, from, to] = this.FindNearestInterval(time, 0, this.Count() - 1);
        if (index === NOT_FOUND_INTERVAL_VALUE) { return from; }
        return index;
    };

    private FindNearestInterval (time: number, from: number, to: number): [number, number, number] {
        if ((to - from) === 0) {
            const ot = this.GetOpenTime(from);
            const ct = this.GetCloseTime(from);
            if (ot === -1 || ct === -1) { return [NOT_FOUND_INTERVAL_VALUE, from, to]; }
            if (time >= ot && time < ct) { return [from, from, to]; }
            // +++ Для тиков кейс такой, время открытия/закрытия у них одинаковое
            else if (ot === ct && time === ot) { return [from, from, to]; } else { return [NOT_FOUND_INTERVAL_VALUE, from, to]; }
        } else if ((to - from) === 1) {
            const ot1 = this.GetOpenTime(from);
            const ct1 = this.GetCloseTime(from);
            const ot2 = this.GetOpenTime(to);
            const ct2 = this.GetCloseTime(to);
            if (ot1 === -1 || ct1 === -1 || ot2 === -1 || ct2 === -1) { return [NOT_FOUND_INTERVAL_VALUE, from, to]; }
            if (time >= ot1 && time < ct1) { return [from, from, to]; } else if (time >= ot2 && time < ct2) { return [to, from, to]; }
            // +++ Для тиков кейс такой, время открытия/закрытия у них одинаковое
            else if (ot1 === ct1 && ot2 === ct2 && time >= ot1 && time <= ct2) {
                if (time >= ot2) { return [to, from, to]; } else { return [from, from, to]; }
            } else { return [NOT_FOUND_INTERVAL_VALUE, from, to]; }
        } else {
            const middle = Math.floor(from + (to - from) / 2);
            const mt = this.GetOpenTime(middle);
            if (mt === -1) { return [NOT_FOUND_INTERVAL_VALUE, from, to]; }
            if (time === mt) { return [middle, from, to]; } else if (time > mt) { return this.FindNearestInterval(time, middle, to); } else { return this.FindNearestInterval(time, from, middle); }
        }
    };

    /// <summary>
    /// Рекурсивный метод поиска делением пополам
    /// </summary>
    /// <param name="time"></param>
    /// <param name="index"></param>
    /// <returns></returns>
    public FindInterval (time: number, from: number, to: number): number {
        if ((to - from) === 0) {
            const ot = this.GetOpenTime(from);
            const ct = this.GetCloseTime(from);
            if (ot === -1 || ct === -1) { return NOT_FOUND_INTERVAL_VALUE; }
            if (time >= ot && time < ct) { return from; }
            // +++ Для тиков кейс такой, время открытия/закрытия у них одинаковое
            else if (ot === ct && time === ot) { return from; } else { return NOT_FOUND_INTERVAL_VALUE; }
        } else if ((to - from) === 1) {
            const ot1 = this.GetOpenTime(from);
            const ct1 = this.GetCloseTime(from);
            const ot2 = this.GetOpenTime(to);
            const ct2 = this.GetCloseTime(to);
            if (ot1 === -1 || ct1 === -1 || ot2 === -1 || ct2 === -1) { return NOT_FOUND_INTERVAL_VALUE; }
            if (time >= ot1 && time < ct1) { return from; } else if (time >= ot2 && time < ct2) { return to; }
            // +++ Для тиков кейс такой, время открытия/закрытия у них одинаковое
            else if (ot1 === ct1 && ot2 === ct2 && time >= ot1 && time <= ct2) {
                if (time >= ot2) { return to; } else { return from; }
            } else { return NOT_FOUND_INTERVAL_VALUE; }
        } else {
            const middle = Math.floor(from + (to - from) / 2);
            const mt = this.GetOpenTime(middle);
            if (mt === -1) { return NOT_FOUND_INTERVAL_VALUE; }
            if (time === mt) { return middle; } else if (time > mt) { return this.FindInterval(time, middle, to); } else { return this.FindInterval(time, from, middle); }
        }
    };

    /// <summary>
    /// Получить время начала интервала по индексу
    /// </summary>
    public GetOpenTime (index: number): number {
        // Извлекаем данные по новому индексу
        if (index >= 0 && index < this.FNonEmptyCashArrayCount) {
            if (index < this.FNonEmptyCashArrayCount) {
                return this.FNonEmptyCashArray[index].FLeftTimeTicks;
            } else {
                // Время по индексу вычислимо только для бар-интервальной истории
                if (this.FPeriod > Periods.TIC) { return this.CalculateTime(index); } else { return NOT_FOUND_INTERVAL_VALUE; }
            }
        } else { return NOT_FOUND_INTERVAL_VALUE; } // UnknownTime
    };

    /// <summary>
    /// Получить вермя конца интервала по индексу
    /// </summary>
    public GetCloseTime (index: number): number {
        if (index >= 0 && index < this.FNonEmptyCashArrayCount) {
            if (index < this.FNonEmptyCashArrayCount) { return this.FNonEmptyCashArray[index].FRightTimeTicks; } else {
                // Время по индексу вычислимо только для бар-интервальной истории
                if (this.FPeriod > Periods.TIC) { return this.CalculateTime(index) + BaseInterval.GetIntervalLength(this.FPeriod); } else { return NOT_FOUND_INTERVAL_VALUE; }
            }
        } else { return NOT_FOUND_INTERVAL_VALUE; } // UnknownTime
    }

    GetInterval (index) {
        return this.GetThis(index);
    }

    /// <summary>
    /// Для внутренних целей
    /// </summary>
    GetThis (index) {
        if (index >= 0 && index < this.FNonEmptyCashArrayCount) { return this.FNonEmptyCashArray[index]; } else { return null; }
    }

    /// <summary>
    /// Получить версмя по индексу относительно первого бара
    /// </summary>
    CalculateTime (index) {
        return this.FStartTime + BaseInterval.GetIntervalLength(this.FPeriod) * index;
    }

    CalculateTimeLtRt (index, lt, rt) {
        const intervalLength = BaseInterval.GetIntervalLength(this.FPeriod);
        lt = this.FStartTime + intervalLength * index;
        rt = lt + intervalLength;
        return { lt, rt };
    }

    AddIndicator (indicator) {
        // if (this.FFuncList.Contains(indicator))
        //     {return null;}

        indicator.Parent = this;

        this.FFuncList.push(indicator);

        return indicator;
    };

    RemoveIndicator (indicator) {
        // if (!this.FFuncList.Contains(indicator))
        //     {return null;}

        this.FFuncList.splice(this.FFuncList.indexOf(indicator), 1);
    }

    GetTimeToNext () {
        const useQuoteDelay = true;

        if (this.Period < 0) {
            // бары по тикам todo
            // let lastBar = this.GetInterval(this.Count - 1);
            // if (lastBar != null)
            //     return (string.Format("{0}t", -this.FPeriod - lastBar.QuoteCounter)).ToString();
        } else if (this.Period != 0 && this.Period % Periods.RANGE != 0) {
            const closeTime = this.GetCloseTime(this.FNonEmptyCashArrayCount - 1);

            let t = closeTime - Date.now() + (useQuoteDelay && this.Instrument != null ? this.Instrument.QuoteDelay : 0) * TimeSpanPeriods.TicksPerMinute;
            // Нестыковочка по времени
            if (t < 0) {
                t += BaseInterval.GetIntervalLength(this.FPeriod);

                if (t < 0) // полная нестыковочка, десктоп в таких случаях не показывает TimeToNext - поступим так же
                { return ''; };
            }

            const span = TimeSpan.ticksToTimeSpanObject(t);

            if (span.Days > 365) {
                return TimeSpan.ToTimeSpanString(span, TimeSpanFormat.YearDay);
            } else if (span.Days > 0) {
                return TimeSpan.ToTimeSpanString(span, TimeSpanFormat.DayHour);
            } else if (span.Hours > 0) {
                return TimeSpan.ToTimeSpanString(span, TimeSpanFormat.HourMinute);
            } else if (span.Minutes > 0) {
                return TimeSpan.ToTimeSpanString(span, TimeSpanFormat.MinuteSecond);
            } else {
                return TimeSpan.ToTimeSpanString(span);
            }
        }
        return '';
    }

    async ReloadHistoryCallback (state, signal) {
        try {
            if (this.Parent === null) { return []; }

            // Вызываем событие - до загрузки
            // this.Parent.OnBeforeLoad(this);
            const copyHistoryParams = new ReloadHistoryParams(state);
            const intervals = await this.LoadHistorySmart(copyHistoryParams, signal);
            state.ShouldCheckAggregatedPeriods = copyHistoryParams.ShouldCheckAggregatedPeriods;
            state.count = copyHistoryParams.count;
            return intervals;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            return [];
        }
    };

    async LoadHistorySmart (historyParams, signal) {
        historyParams.updateWithProps({ onlyMainSession: this.onlyMainSession });

        const intervals = await this.Parent.callOnReloadHistory(historyParams, signal);
        return intervals;
    };

    CheckQuote (message: DirectQuoteMessage) {
        // #90266, #100139 - фильтрация оносительно сессии
        if (this.onlyMainSession && message.SessionFlag && !SessionPeriod.IsMainType(message.SessionFlag)) { return false; }

        const trSess = message.TradingSession;
        if (this.Instrument?.AllowFilterBarsBySession(this.FPeriod) && trSess?.isBeforeOrAfterMarket()) { return false; }

        return true;
    };

    UpdateLastPrice (message) {
        if (this.HistoryType != HistoryType.QUOTE_TRADES) // пока только по трейдам
        { }

        // this.SyncronizedSessionFlag = message.SessionFlag;
    };

    newQuote (message) {
        let needCallNextBar = false;

        if (this.collectTickQuotes) {
            this.collectedQuoteMessages.push(message);
            return;
        }

        if (!this.CheckQuote(message)) { return; };

        this.UpdateLastPrice(message);

        // Обработка котировки кешем
        if (this.FPeriod < 0) {
            const a = 0;
            // #region Тик-интервальная история
            // В тик-интервальной истории виртуальных дырок быть не должно
            // все дырки физические, та как невозможно поределить время виртуальной дырки
            // if (FNonEmptyCashArrayCount == 0)
            // {
            //    if (message.Code === MessageCode.CODE_INSTRUMENT_DAY_BAR) //не нужно создавать бар если приходит InstrumentDayBarMessage
            //    return;

            //    // Добавляем бар
            //    FNonEmptyCashArray.Add(new BaseInterval(message, FPeriod, Parent.serverTimeZone, HistoryType, this.spreadItem));
            //    FNonEmptyCashArrayCount = FNonEmptyCashArray.Count;

            //    FStartTime = FNonEmptyCashArray[0].FLeftTimeTicks;
            //    CallNextBar();

            //    OnHistoryExpanded(0, 1);
            // }
            // else
            // {
            //    // Получаем последний интервал
            //    BaseInterval interval = FNonEmptyCashArray[FNonEmptyCashArrayCount - 1];
            //    // Добавляем в него котировку
            //    int result = interval.Add(message, HistoryType, FPeriod, this.spreadItem);
            //    if (result > 0)
            //    {
            //        // Добавляем следующий интервал
            //        int oldLength = FNonEmptyCashArrayCount;

            //        BaseInterval newInterval = new BaseInterval(message, FPeriod, Parent.serverTimeZone, HistoryType, this.spreadItem);
            //        newInterval.SetNewBorders(interval.FRightTimeTicks, newInterval.FRightTimeTicks);
            //        FNonEmptyCashArray.Add(newInterval);
            //        FNonEmptyCashArrayCount = FNonEmptyCashArray.Count;

            //        CallNextBar();

            //        // Вызываем событие для вьюшек - история увеличилась еще на один бар
            //        OnHistoryExpanded(oldLength, FNonEmptyCashArrayCount);
            //    }
            // }
            // CallOnQuote();
            /// /this.QuoteProcessed.Raise(this,message)
            /// / Для периодов, составленных из тиков
            // return;
            // #endregion
        }
        if (this.FNonEmptyCashArrayCount == 0 || this.FPeriod === Periods.TIC) {
            // #region // Тиковая история или пустая
            const oldLength = this.FNonEmptyCashArrayCount;

            let workMsg = message;
            if (message.Code === Message.CODE_INSTRUMENT_DAY_BAR) { workMsg = message.GenerateQuoteMessage(this.HistoryType); };

            if (workMsg === null) return; // TODO проверить, нужен ли message ниже после этого if

            let interval: BaseInterval;
            if (this.FPeriod === Periods.TIC && this.HistoryType !== HistoryType.QUOTE_TRADES) {
                interval = new BaseInterval();
                interval.FLeftTimeTicks = interval.FRightTimeTicks = workMsg.cTime.getTime();
                interval.LeftTime = interval.RightTime = workMsg.cTime;
                interval.Init(workMsg, this.HistoryType, this.FPeriod, this.spreadItem);
            } else {
                const biInput = new BaseIntervalInputParams();
                biInput.instrument = this.Instrument;
                biInput.onlyMainSession = this.onlyMainSession;
                biInput.LeftTimeTicks = workMsg.cTime.getTime();
                interval = new BaseInterval(biInput, this.Period);
                interval.Init(workMsg, this.HistoryType, this.FPeriod, this.spreadItem);

                // https://tp.traderevolution.com/entity/121924
                if (this.HistoryType === HistoryType.QUOTE_TRADES && message.Code === Message.CODE_QUOTE3 && this.FPeriod === Periods.DAY) {
                    const open = interval.Data[BaseInterval.OPEN_INDEX];
                    const tradeOpen = this.Instrument.InstrumentDayInfo.tradeOpen;
                    if (tradeOpen < open) { interval.Data[BaseInterval.OPEN_INDEX] = tradeOpen; }
                }
                //
                if (message.Code === Message.CODE_INSTRUMENT_DAY_BAR) {
                    if (message.Open != null) { interval.Data[BaseInterval.OPEN_INDEX] = message.Open; }
                }
            }

            const oneDayAggregation = Periods.TranslateToParsePeriod(this.FPeriod) === Periods.DAY;
            if (message.Code === Message.CODE_INSTRUMENT_DAY_BAR) {
                if (oneDayAggregation) { this.FNonEmptyCashArray.push(interval); }
            } else { this.FNonEmptyCashArray.push(interval); }

            // if (this.FPeriod == Periods.TIC && this.HistoryType != HistoryType.QUOTE_TRADES)
            //    this.FNonEmptyCashArray.push(new BaseIntervalTick(message, this.FPeriod, this.Parent.serverTimeZone, this.HistoryType, this.spreadItem));
            // else
            //    this.FNonEmptyCashArray.push(new BaseInterval(message, this.FPeriod, this.Parent.serverTimeZone, this.HistoryType, this.spreadItem));

            this.FNonEmptyCashArrayCount = this.FNonEmptyCashArray.length;
            if (this.FNonEmptyCashArrayCount == 1) { this.FStartTime = this.FNonEmptyCashArray[0].FLeftTimeTicks; }

            needCallNextBar = true;
            // Вызываем событие для вьюшек - история увеличилась еще на один бар
            this.OnHistoryExpanded(oldLength, this.FNonEmptyCashArrayCount);

            if (message.Code === Message.CODE_INSTRUMENT_DAY_BAR) {
                interval.correctBorders(this.FPeriod);
                if (oneDayAggregation) { this.newQuote(message); }
            }
            // #endregion
        } else {
            // #region Время-интервальная (или бар-интервальная) история

            // Получаем последний интервал
            let interval = this.FNonEmptyCashArrayCount > 0 ? this.FNonEmptyCashArray[this.FNonEmptyCashArrayCount - 1] : null;
            let result = -1;
            if (interval == null) {
                if (message.Code === Message.CODE_INSTRUMENT_DAY_BAR) { return; }

                // Последний интервал был пустым эмулируем его
                interval = new BaseInterval();
                let lt = 0;
                let rt = 0;
                // interval.Period = this.Period;
                const ltRtObj = this.CalculateTimeLtRt(this.FNonEmptyCashArrayCount - 1, lt, rt);
                lt = ltRtObj.lt;
                rt = ltRtObj.rt;
                interval.SetNewBorders(lt, rt);

                // Проверка, попадает ли котировка во временной интервал
                result = interval.Add(message, this.HistoryType, this.FPeriod, this.spreadItem, this.Parent.DataCache);
                if (result > 0) {
                    // Время котировки принадрежит следующему интервалу - ничего не делаем,
                    // в истории остается дырка - а мы переходим на слддующий интервал (код ниже)
                    // ...
                } else if (result == 0) {
                    // время котировки принадлежит текущему интервалу
                    // Загоняем данные в интервал (они начальные для него - Add не подходит)
                    interval.Init(message, this.HistoryType, this.FPeriod, this.spreadItem);
                    this.FNonEmptyCashArray.push(interval);
                    this.FNonEmptyCashArrayCount = this.FNonEmptyCashArray.length;
                }
            } else {
                const basePeriod = Periods.TranslateToParsePeriod(this.FPeriod);
                const ins = this.Instrument; // Если с роута приходит значение настройки dayBarUpdateMode = true, текущий дневной бар на чарте отображаем только по значениям из IDBM (включая обновление Current price из значения Last price). Трейды не учитываем. #87544 п.1)
                const needAdd = !(ins?.InstrumentDayBarMessageUpdateMode && message.Type == HistoryType.QUOTE_TRADES && basePeriod == Periods.DAY);
                // Пробуем добавить котировку в кеш
                if (needAdd) { result = interval.Add(message, this.HistoryType, this.FPeriod, this.spreadItem, this.Parent.DataCache); }
            }
            // Проверяем результат
            if (result > 0) {
                // Сохраняем предыдущую длину
                const oldLength = this.FNonEmptyCashArrayCount;
                // Получаем количество пустых интервалов, добавить
                let zeroes = 0;
                if (message.Type === HistoryType.QUOTE_LEVEL1) {
                    zeroes = interval.CalculateNextInterv(
                        message.Time,
                        this.Parent.serverTimeZone,
                        this.FPeriod,
                        this.Instrument,
                        !!this.onlyMainSession);
                } else if (message.Type === HistoryType.QUOTE_TRADES) {
                    zeroes = interval.CalculateNextInterv(
                        message.Time,
                        this.Parent.serverTimeZone,
                        this.FPeriod,
                        this.Instrument,
                        !!this.onlyMainSession);
                }

                // Получаем очередной интервал
                interval = interval.GetNextInterval(zeroes, this.HistoryType, this.FPeriod);
                // Проверка временных границ интервала с временем текущего тика (это алгоритмическая проверка )
                let valid = false;
                valid = interval.Validate(message.Time);
                if (valid) {
                    // Инициализируем интервал
                    interval.Init(message, this.HistoryType, this.FPeriod, this.spreadItem);
                    this.FNonEmptyCashArray.push(interval);
                    this.FNonEmptyCashArrayCount = this.FNonEmptyCashArray.length;

                    needCallNextBar = true;

                    // вызываем событие для вьюшек - история увеличилась на N-е количество баров
                    this.OnHistoryExpanded(oldLength, this.FNonEmptyCashArrayCount);
                } else {
                }
            }
            // #endregion
        }

        let indicator: any;
        let count = this.FFuncList.length;
        for (let k = 0; k < count; k++) {
            try {
                indicator = this.FFuncList[k];
                if (needCallNextBar) { indicator.NextBar(true); }

                indicator.OnQuote(this, true);
            }
            // чтобы 1 индюк не ломал все
            catch (ex) {
                ErrorInformationStorage.GetException(ex);
                count = this.FFuncList.length;
            }
        }

        this.QuoteProcessed.Raise(this, message);
    };

    OnHistoryExpanded (oldLength, newLength) {
        // if (Parent != null && Parent.ResubscribeStateHandler == QuoteCache.ResubscribeState.FullReconnect)
        //    return;

        // if (HistoryExpanded != null)
        // {
        try {
            this.HistoryExpanded.Raise(this, oldLength, newLength);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            // util.Utils.log("CashItem for {0}/{1} ::OnHistoryExpanded({2}, {3})  caused an exception {4} ",
            //    Symbol, Period, oldLength, newLength, ex.Message);
        }
        // }
    }

    // #region True Range

    GetTrueRange (index) {
        const hi = this.FNonEmptyCashArray[index].Data[BaseInterval.HIGH_INDEX];
        const lo = this.FNonEmptyCashArray[index].Data[BaseInterval.LOW_INDEX];
        const prevClose = index > 0 ? this.FNonEmptyCashArray[index - 1].Data[BaseInterval.CLOSE_INDEX] : this.FNonEmptyCashArray[index].Data[BaseInterval.CLOSE_INDEX];
        const max = Math.max(hi - lo, Math.max(Math.abs(prevClose - hi), Math.abs(prevClose - lo)));
        return max;
    }
    // #endregion

    /**
        * Create CashItem, Subscribe, LoadHistory
        * Use dispose to unsubscribe cashItem
    */
    public static async CreateWithHistory (
        instrument: Instrument,
        historyParams: ReloadHistoryParams,
        signal: AbortSignal | null = null,
        subscribe: boolean = true,
        loadHistory: boolean = true) {
        // нужно добавить логику что если запрос приходит от предыщущего владельца
        // то нужно отменить предыдущий запрос (закрыть стрим)
        const qc = instrument?.DataCache.FQuoteCache;
        if (qc == null) {
            return null;
        }

        historyParams.Instrument = instrument;
        const timeFrameInfo = historyParams.TimeFrameInfo.Copy();
        const symbol = instrument.GetInteriorID();
        const cashItem = new CashItem(symbol, qc, timeFrameInfo);
        if (subscribe) {
            cashItem.SubscribeCurrentInstrument();
        }
        let ci: CashItem | null = null;
        try {
            if (loadHistory) {
                ci = await cashItem.Reload(historyParams, signal, subscribe);
            } else {
                ci = cashItem;
                ci.collectTickQuotes = false;
            }
        } catch {
            ci = null;
        }

        if (signal?.aborted || ci == null) {
            ci?.Dispose();
            return null;
        }

        return ci;
    }
}
