// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { Interval } from '../../../../Utils/History/Interval';
import { TimeSpanPeriods } from '../../../../Utils/Time/TimeSpan';
import { HistoryMerger } from './HistoryMerger';
import { Decimal } from 'decimal.js';

export abstract class HistoryMergerBasedByMin extends HistoryMerger {
    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
    }

    FindIntervalBorders (date: Date): Interval {
        const interval: Interval = this.GetStartPoint(date);
        const startPointUtc: Date = interval.From;
        const maxEndUtc: Date = interval.To;

        const borders: Interval = this.GetPropagatedBarBorders(date, startPointUtc, this.newPeriod);

        // корректируем правую границу бара в соответствии с ограничениями
        if (borders.To > maxEndUtc) {
            borders.To = maxEndUtc;
        }

        // hsa: бар не попадает в нужный диапазон
        // например при интрадей агрегации для сессии возможно будет бар, который
        // попадает за границы сессии в которых ведется агрегация
        if (date < borders.From || date >= borders.To) {
            return Interval.GetInvalidInterval();
        }

        return borders;
    }

    protected abstract GetStartPoint (date: Date): Interval;

    protected GetPropagatedBarBorders (timeUtc: Date, startTime: Date, newPeriod: number): Interval {
        // на текущий момент это копипаста метода из CashItem.FindStartData
        const data: number = timeUtc.getTime() - startTime.getTime();
        const barLength = newPeriod * TimeSpanPeriods.TicksPerMinute;
        // how many bars since start time?
        const periodsCountFractional: Decimal = new Decimal(data).div(barLength);
        const periodsCount: number = periodsCountFractional.trunc().toNumber();
        const barStartInTicks: number = startTime.getTime() + periodsCount * barLength;
        const barEndInTicks: number = barStartInTicks + barLength;
        const interval = new Interval();
        interval.From = new Date(barStartInTicks);
        interval.To = new Date(barEndInTicks);
        return interval;
    }
}
