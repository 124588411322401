// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TrailingStopOrderEdit } from '../order-edit/TrailingStopOrderEdit';
import { OrderEditUpdateData } from '@shared/utils/Trading/OrderEditUpdateData';
import { TIF } from '@shared/utils/Trading/OrderTif';
import { SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { OrderExecutorUtils } from '../../../Trading/OrderExecutorUtils';
import { BoundToEdit } from '../BoundToEdit';
import { type DynProperty } from '../../../DynProperty';
import { IsAllowed } from '../../../IsAllowed';
import { type Order } from '../../Order';

// TODO. Ugly. Refactor. Lots of duplicated pieces of code
// (sltp, boundTo parameters and whatnot).
export class ModifyTrailingStopOrder extends TrailingStopOrderEdit {
    public order: Order;
    public boundToEdit: BoundToEdit;

    constructor (data) {
        super(data);
        this.order = data.order;
        this.boundToEdit = new BoundToEdit();
        this.updateParameters(new OrderEditUpdateData(
            null,
            {
                instrument: this.order.Instrument,
                account: this.order.Account,
                side: this.order.BuySell,
                quantity: new Quantity(this.order.Amount, true),
                tif: new TIF(this.order.TimeInForce, new Date(this.order.ExpireAt)),
                leverageValue: this.order.Leverage,
                order: this.order
            }
        ));
        this.setSLTP(this.order.createSlTpHolder());
    }

    public getParameterNameArray (): string[] {
        const paramNameArr = super.getParameterNameArray();
        paramNameArr.push('boundTo');
        return paramNameArr;
    }

    public override dispose (): void {
        super.dispose();
        this.order = null;
    }

    // TODO. Refactor.
    public override setTradingData (tradingDataDict) {
        const newTradingDataDict = super.setTradingData(tradingDataDict);

        if (!newTradingDataDict) { return newTradingDataDict; };

        if ('order' in tradingDataDict) {
            this.order = tradingDataDict.order;
            newTradingDataDict.order = this.order;
        }

        return newTradingDataDict;
    }

    // TODO. Refactor base class, trading data. Ugly.
    public override getTradingData (): any {
        const tradingDataDict = super.getTradingData();
        tradingDataDict.order = this.order;
        return tradingDataDict;
    }

    // TODO. Refactor base class, trading data. Ugly.
    public override getDataForRequest (): any {
        const reqData = super.getDataForRequest();
        if (reqData) {
            reqData.order = this.order;
        }

        return reqData;
    }

    public override update_trailingStop (updateData): boolean {
        let parameterChanged = super.update_trailingStop(updateData);

        const newTradingDataDict = updateData.newTradingDataDict;
        if (!newTradingDataDict) {
            return parameterChanged;
        }

        const order: Order = newTradingDataDict.order;
        if (isNullOrUndefined(order)) {
            return parameterChanged;
        }

        const ticksOffset = SlTpUtils.getPriceDifferenceInTicks(order.Price, this.getQuotePriceForCalculatingOffset(), this.instrument);
        const visualOffset = SlTpUtils.toVisualValue(ticksOffset, this.instrument, this.trailingStop.offsetType);

        if (visualOffset !== this.trailingStop.value) {
            this.trailingStop.value = visualOffset;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public update_boundTo (updateData): boolean {
        return this.boundToEdit.update(updateData);
    }

    public toDynProperty_boundTo (): DynProperty {
        return this.boundToEdit.getDynProperty();
    }

    public toRawValue_boundTo (): string {
        return this.boundToEdit.getRawValue();
    }

    public getLeverageValue (): any {
        return this.order ? this.order.Leverage : null;
    }

    // TODO. Ugly. Duplication.
    public override async getConfirmationText (onlyParams = false): Promise<string> {
        const confirmationText = await super.getConfirmationText();
        if (onlyParams) {
            return confirmationText;
        } else {
            return OrderExecutorUtils.getModifyOrderEditConfirmation(
                this.order,
                confirmationText
            );
        }
    }

    public override tradingAllowed (): boolean {
        return IsAllowed.IsOrderModifyingAllowed(this.order).Allowed;
    }
}
