// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MDButton } from './MDButton';
import { SessionOperations } from '../../Utils/Enums/Constants';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { TradingLockUtils } from '../../Utils/TradingLockUtils';
import { IsAllowed } from '../../Commons/IsAllowed';
import { DataCache } from '../../Commons/DataCache';
import { TerceraButtonEvents, TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';

export class CancelOrdersMDButton extends MDButton {
    public lastUpdateTradingStatusId: any = null;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        const dc = DataCache;
        dc.OnAddOrder.Subscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnRemoveOrder.Subscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnAddSLOrderToPosition.Subscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnRemoveSLOrderFromPosition.Subscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnAddTPOrderToPosition.Subscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnRemoveTPOrderFromPosition.Subscribe(this.deferUpdateTradingAllowedStatus, this);
        this.observe('instrument account', this.deferUpdateTradingAllowedStatus);

        TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(
            this.deferUpdateTradingAllowedStatus, this);

        this.on(TerceraButtonEvents.onClick, this.cancelAllOrders);
    }

    public override dispose (): void {
        super.dispose();
        const dc = DataCache;
        dc.OnAddOrder.UnSubscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnRemoveOrder.UnSubscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnAddSLOrderToPosition.UnSubscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnRemoveSLOrderFromPosition.UnSubscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnAddTPOrderToPosition.UnSubscribe(this.deferUpdateTradingAllowedStatus, this);
        dc.OnRemoveTPOrderFromPosition.UnSubscribe(this.deferUpdateTradingAllowedStatus, this);

        TradingLockUtils.TradingLock.TradingLockChanged.UnSubscribe(
            this.deferUpdateTradingAllowedStatus, this);

        if (this.lastUpdateTradingStatusId) {
            clearTimeout(this.lastUpdateTradingStatusId);
            this.lastUpdateTradingStatusId = null;
        }
    }

    // TODO. Optimization workaround - deferred execution.
    public deferUpdateTradingAllowedStatus (): void {
        if (this.lastUpdateTradingStatusId) {
            clearTimeout(this.lastUpdateTradingStatusId);
        }

        const self = this;

        this.lastUpdateTradingStatusId = setTimeout(
            function () { self.updateTradingAllowedStatus(); },
            500);
    }

    public updateTradingAllowedStatus (): void {
        const dc = DataCache; const ins = this.get('instrument'); const acc = this.get('account');
        const orderDict = dc.getOrdersByInstrumentAndAccount(ins, acc);
        const isTradingAllowed = IsAllowed.IsTradingAllowed([acc], ins, -1, SessionOperations.Cancel, null, true);
        void this.set('tradingAllowed', !!(orderDict && (Object.keys(orderDict).length > 0) && isTradingAllowed.Allowed));
        void this.set('tradingForbiddenReason', isTradingAllowed.ReasonText);
    }

    // TODO. Optimize. UpdateTradingAllowedStatus after every OnAddOrder/OrRemoveOrder events.
    public cancelAllOrders (): void {
        const dc = DataCache;
        const orderDict = dc.getOrdersByInstrumentAndAccount(
            this.get('instrument'), this.get('account'));

        void dc.FOrderExecutor.cancelOrdersByIdPromise(Object.keys(orderDict), true, PlacedFrom.WEB_MARKETDEPTH);
    }
}

MDButton.extendWith(CancelOrdersMDButton, {
    data: function () {
        return {
            locKey: 'Panels.MarketDepth.CancelAll',
            terceraButtonStyle: TerceraButtonStyle.Standard,
            instrument: null,
            account: null
        };
    }
});
