// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';
import { Message } from './../Message';

export class DirectAlgorithmSubscribeMessage extends DirectAlgorithmMessageBase {
    constructor () { super(Message.CODE_PFIX_ALGORITHM_SUBSCRIBE_MESSAGE); }

    SubscribeAction: number/* AlgorithmSubscribeType */; // 69
}
