// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { SolidBrush, Color } from '../../Graphics';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty } from '../../DynProperty';

export class FillableDataCacheTool extends DataCacheTool {
    public fill: boolean;
    private fillColor: string | null;
    private fillBrush: SolidBrush | null;

    constructor () {
        super();
        this.fill = false;
        this.fillColor = null;
        this.fillBrush = null;
        this.InitBlock();
    }

    /**
     * Gets the fill color string
     * @returns {string | null} - Fill color
     */
    get FillColor (): string | null {
        return this.fillColor;
    }

    /**
     * Sets the fill color
     * @param {string | null} value - Color string
     */
    set FillColor (value: string | null) {
        if (this.fillColor === value) return;

        this.fillColor = value;
        this.fillBrush = new SolidBrush(this.fillColor);
    }

    /**
     * Gets the fill brush
     * @returns {SolidBrush | null} - Fill brush
     */
    get FillBrush (): SolidBrush | null {
        return this.fillBrush;
    }

    /**
     * Initializes the block for the tool
     */
    InitBlock (): void {
        super.InitBlock();

        this.fill = true;
        this.FillColor = Color.FromArgb(this.colorAlpha, this.Color);
    }

    /**
     * Get properties for the tool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props: DynProperty[] = super.Properties();

        props.push(new DynProperty('chart.tools.back', this.fill, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP));
        props.push(new DynProperty('chart.tools.backcolor', this.fillColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP));

        return props;
    }

    /**
     * Callback function for handling property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        let prop = DynProperty.getPropertyByName(properties, 'chart.tools.back');
        if (prop != null) this.fill = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'chart.tools.backcolor');
        if (prop != null) this.FillColor = prop.value;
    }
}
