// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../Commons/Graphics';
import { KeyCode } from '../../Commons/KeyEventProcessor';
import { ColorPickerTemplate } from '../../templates.js';
import { Control } from './Control';
import { ThemeManager } from '../misc/ThemeManager';
import { CustomEvent } from '../../Utils/CustomEvents';
import $ from 'jquery';
import { HSB2RGB, RGB2HSB, RGBColor } from '../UtilsClasses/ColorParser';

export class ColorPicker extends Control {
    public static readonly DISTANCE_TO_SEPARATOR_MIDDLE = 4;
    public static readonly DISTANCE_TO_POINT_MIDDLE = 4;

    public dataH: number = 0;
    public dataS: number = 0;
    public dataB: number = 0;
    public valueA: number = 1;
    public startValueA: number = 1;
    public mouseEventPart: MouseEventParts = MouseEventParts.None;
    public opacityControlWidth: number = 280;
    public onMouseDown: any = null;

    public override oninit (): void {
        super.oninit();
        this.onMouseDown = new CustomEvent();
        void this.set({ imageforRemoveBtn: ThemeManager.getImageFullUrlNew('components/panels_tabs/14x14_icon_close_default.png') });
        void this.set({ noColorImage: ThemeManager.getImageFullUrl('web_only/colorPicker/21x16_colorOpacity0.png') });

        this.on('onRainbowBarMouseDown', (event) => {
            this.mouseEventPart = MouseEventParts.Rainbow;
            this.cpRainbowBarOver(event);
        });
        this.on('onRainbowBarMouseMove', this.cpRainbowBarOver);
        this.on('onRainbowBarMouseUp onRainbowBarMouseLeave onRainbowBarMouseEnte', () => {
            this.mouseEventPart = MouseEventParts.None;
        });

        this.on('onMapMouseDown', () => {
        // this.mouseEventPart = ColorPicker.MouseEventParts.Point;
        // this.cpMapOver(event);
            this.onMouseDown.Raise();
        });
        this.on('onMapMouseMove', this.cpMapOver);
        this.on('onMapMouseLeave', () => {
            this.mouseEventPart = MouseEventParts.None;
            this.setPointMap(this.get('startColor'));
        });

        this.on('onOpacityMouseDown', (event) => {
            this.mouseEventPart = MouseEventParts.Opacity;
            this.cpOpacityOver(event);
        });
        this.on('onOpacityMouseMove', this.cpOpacityOver);
        this.on('onOpacityMouseUp onOpacityMouseLeave onOpacityMouseEnte', () => {
            this.mouseEventPart = MouseEventParts.None;
        });

        this.on('onRGBValueChange', this.onRGBValueChange);

        this.on('removeColorClick', this.removeColorClick);
    }

    public setColor (color): void {
        this.setPointRainbowOpacityByColor(color);
    }

    public getColor (): string {
        let result = this.get('currentColor');
        if (this.get('startColor') === 'rgba(0,0,0,0)') {
            const splitedResult = result.split(',');
            result = splitedResult[0] + ',' + splitedResult[1] + ',' + splitedResult[2] + ',1)';
        }
        return result;
    }

    //
    // над вертикальной полосой
    //
    public cpRainbowBarOver (event): void {
        if (this.mouseEventPart !== MouseEventParts.Rainbow) {
            return;
        }

        const ev = event.original;

        let posY = ev.offsetY;
        if (ev.target !== ev.currentTarget) {
            posY = this.get('rainbowSelectorValue') + posY;
        }

        if (posY < 0) posY = 0;
        if (posY > 255) posY = 255;

        this.dataH = parseInt((360 * (256 - posY) / 256).toString(), 10);

        const mapColor = this.getMapByRainbow(this.dataH);

        void this.set({
            mapColor,
            rainbowSelectorValue: posY - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE
        });
    };

    //
    // над картой цветов
    //
    public cpMapOver (event): void {
        const ev = event.original;

        let posX = ev.offsetX;
        let posY = ev.offsetY;

        if (ev.target !== ev.currentTarget) {
            posX = this.get('pointX') + posX;
            posY = this.get('pointY') + posY;
        }

        if (posX < 0) posX = 0;
        if (posX > 255) posX = 255;
        if (posY < 0) posY = 0;
        if (posY > 255) posY = 255;

        this.dataS = Math.floor(100 * posX / 256);
        this.dataB = Math.floor(100 * (256 - posY) / 256);

        const newColor = this.getColorByPointRainbow();

        void this.set({
            currentColor: newColor,
            pointX: posX - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
            pointY: posY - ColorPicker.DISTANCE_TO_POINT_MIDDLE
        });
        this.changePointColor(this.dataB);
    }

    //
    // над прозрачностью
    //
    public cpOpacityOver (event): void {
        if (this.mouseEventPart !== MouseEventParts.Opacity) { return; }

        const ev = event.original;

        let posA = ev.offsetX;

        if (ev.target !== ev.currentTarget) {
            posA = this.get('pointA') + posA;
        }

        if (posA < 0) {
            posA = 0;
        }
        if (posA > 280) {
            posA = 280;
        }

        const persent = posA / $(event.node).width();
        this.valueA = persent;

        const newColor = 'rgba(' + this.get('valueR') + ', ' + this.get('valueG') + ', ' + this.get('valueB') + ',' + this.valueA + ')';
        void this.set({
            currentColor: newColor,
            valueA: this.valueA,
            pointA: posA - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE
        });
    }

    public getMapByRainbow (h): string {
        const rgbColor = HSB2RGB(parseInt(h, 10), 100, 100);
        return 'rgb(' + rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b + ')';
    };

    public changePointColor (value): void {
        let newPointColor = 'js-cp-black';
        if (value <= 50) {
            newPointColor = 'js-cp-whitePoint';
        }
        if (value > 50) {
            newPointColor = 'js-cp-blackPoint';
        }

        void this.set({ additionalStyle: newPointColor });
    }

    public getColorByPointRainbow (): string {
        const rgbColor = HSB2RGB(Math.floor(this.dataH), Math.floor(this.dataS), Math.floor(this.dataB));
        return 'rgba(' + rgbColor.r + ', ' + rgbColor.g + ', ' + rgbColor.b + ',' + this.valueA + ')';
    }

    public setPointRainbowOpacityByColor (rgbColor: string): void {
        if (isNullOrUndefined(rgbColor)) return;
        if (rgbColor === 'transparent' || rgbColor === '') return;
        let H = 1;
        let S = 0;
        let B = 0;
        if (rgbColor.indexOf('rgb') !== 0) rgbColor = this.getRGB(rgbColor);

        const res = Color.getColorChannels(rgbColor);
        const r = res.r;
        const g = res.g;
        const b = res.b;
        let a = 1;
        if (res.a !== undefined) { a = res.a; }

        this.valueA = a;
        this.startValueA = a;

        const HSB = RGB2HSB(r, g, b);
        H = 256 - Math.floor(HSB.h * 256 / 360);
        S = Math.floor(HSB.s * 256 / 100);
        B = 256 - Math.floor(HSB.b * 256 / 100);

        this.dataS = Math.floor(HSB.s);
        this.dataB = Math.floor(HSB.b);

        rainbowSelectorValue: H - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE;
        this.dataH = Math.floor(HSB.h);

        const mapColor = this.getMapByRainbow(this.dataH);
        void this.set({
            pointX: S - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
            pointY: B - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
            valueR: r,
            valueG: g,
            valueB: b,
            mapColor,
            currentColor: rgbColor,
            startColor: rgbColor,
            valueA: this.valueA,
            pointA: this.opacityControlWidth * a - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE
        });

        this.changePointColor(this.dataB);
    }

    public setPointMap (rgbColor): void {
        if (isNullOrUndefined(rgbColor)) return;
        if (rgbColor === 'transparent' || rgbColor === '') return;
        let H = 1;
        let S = 0;
        let B = 0;
        if (rgbColor.indexOf('rgb') !== 0) {
            rgbColor = this.getRGB(rgbColor);
        }

        const res = Color.getColorChannels(rgbColor);
        const r = res.r;
        const g = res.g;
        const b = res.b;

        const HSB = RGB2HSB(r, g, b);
        H = 256 - Math.floor(HSB.h * 256 / 360);
        S = Math.floor(HSB.s * 256 / 100);
        B = 256 - Math.floor(HSB.b * 256 / 100);

        rainbowSelectorValue: H - ColorPicker.DISTANCE_TO_SEPARATOR_MIDDLE;

        void this.set({
            pointX: S - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
            pointY: B - ColorPicker.DISTANCE_TO_POINT_MIDDLE,
            valueR: r,
            valueG: g,
            valueB: b,
            currentColor: 'rgba(' + r + ', ' + g + ', ' + b + ',' + this.valueA + ')'
        });

        this.changePointColor(this.dataB);
    }

    public getRGB (colorName: string): string {
        const color = new RGBColor(colorName);
        if (color.ok) {
            return 'rgb(' + color.r + ', ' + color.g + ', ' + color.b + ')';
        }
    }

    public onRGBValueChange (event): void {
        const r = this.get('valueR');
        const g = this.get('valueG');
        const b = this.get('valueB');
        void this.set({ currentColor: 'rgba(' + r + ', ' + g + ', ' + b + ',' + this.valueA + ')' });
        if (event.original.keyCode === KeyCode.ENTER) {
            this.onMouseDown.Raise();
        }
    }

    public removeColorClick (): void {
        void this.set({ currentColor: 'rgba(0,0,0,0)' });
        this.onMouseDown.Raise();
    }
}

enum MouseEventParts {
    None = 0,
    Rainbow = 1,
    Opacity = 2,
    Point = 3
};

Control.extendWith(ColorPicker, {
    template: ColorPickerTemplate,
    data: function () {
        return {
            mapColor: 'transparent',
            currentColor: 'transparent',
            startColor: 'transparent',
            rainbowSelectorValue: 100,
            pointX: 100,
            pointY: 100,
            pointA: 100,
            additionalStyle: 'js-cp-blackPoint',
            valueR: 0,
            valueG: 0,
            valueB: 0,
            valueA: 1,
            imageforRemoveBtn: '',
            noColorImage: ''
        };
    }
});
