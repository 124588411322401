// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class AlgorithmSubscribeMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_ALGORITHM_SUBSCRIBE_MESSAGE, fieldSet);
    }

    public setAlgorithmId (algorithmId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ALGORITHM_ID, algorithmId);
    }

    public getAlgorithmId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ALGORITHM_ID);
    }

    public setSubscribeAction (action: number): void {
        this.setFieldValue(FieldsFactory.FIELD_SUBSCRIPTION_ACTION, action);
    }

    public getSubscribeAction (): number {
        return this.getFieldValue(FieldsFactory.FIELD_SUBSCRIPTION_ACTION);
    }
}
