// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class ScrollUtils {
    public static IsScrollUp (deltaY: number): boolean {
        if (deltaY < 0) {
            return true;
        }

        return false;
    }

    public static IsScrollDown (deltaY: number): boolean {
        if (deltaY > 0) {
            return true;
        }

        return false;
    }
}
