// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { DataCache } from '../../DataCache';
import { type TradingTerminalFeatureset } from '../charting_library';
import { TvHistoryAggregationsHelper } from '../Helpers/TvHistoryAggregationsHelper';
import { ApplicationInfo } from '../../ApplicationInfo';

class _TvFeaturesSet {
    public getEnabledFeatures (): TradingTerminalFeatureset[] {
        const enabledFeatures: TradingTerminalFeatureset[] = [
            // 'study_templates',
            'dom_widget',
            'show_symbol_logos',
            'show_symbol_logo_in_legend',
            // 'hide_resolution_in_legend',
            'pre_post_market_sessions',
            'show_order_panel_on_start',
            // https://www.tradingview.com/charting-library-docs/latest/ui_elements/Legend/#display-delayed-data-information
            'display_data_mode' as TradingTerminalFeatureset,
            'items_favoriting'
        ];

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_SUPER_DOM)) {
            enabledFeatures.push('show_dom_first_time');
        }

        if (TvHistoryAggregationsHelper.hasTicks()) {
            enabledFeatures.push('tick_resolution');
        }

        if (TvHistoryAggregationsHelper.hasSeconds()) {
            enabledFeatures.push('seconds_resolution');
        }

        if (TvHistoryAggregationsHelper.isCustomAggregationsAllowed()) {
            enabledFeatures.push('custom_resolutions');
        }

        return enabledFeatures;
    }

    public getDisabledFeatures (): TradingTerminalFeatureset[] {
        const disabledFeatures: TradingTerminalFeatureset[] = [
            'use_localstorage_for_settings',
            'header_saveload',
            'drawing_templates',
            // 'header_layouttoggle', //#120934 - enable layout toggle
            'save_shortcut',
            'header_compare',
            'show_hide_button_in_legend',
            'show_symbol_logo_in_account_manager'
        ];

        if (ApplicationInfo.isExploreMode) {
            const exploreFeatures: TradingTerminalFeatureset[] = [
                'buy_sell_buttons'

            ];
            disabledFeatures.push(...exploreFeatures);
        }

        return disabledFeatures;
    }
}

export const TvFeaturesSet = new _TvFeaturesSet();
