// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type SubscribeType } from '../../../../Utils/Enums/Constants';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class SubscribeMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_SUBSCRIBE_MESSAGE, fieldSet);
    }

    public getSubscriptionAction (): SubscribeType {
        return this.getFieldValue(FieldsFactory.FIELD_SUBSCRIPTION_ACTION);
    }

    public setSubscriptionAction (type: SubscribeType): void {
        this.setFieldValue(FieldsFactory.FIELD_SUBSCRIPTION_ACTION, type);
    }
}
