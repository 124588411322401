// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { TerceraButtonEvents } from '../../Utils/Enums/ButtonEnums';
import { PairColorPickerTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

export class PairColorPicker extends ContainerControl {
    public PairColorChanged: CustomEvent;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.PairColorChanged = new CustomEvent();
        this.observe('pairColor', this.onPairColorChanged);
    }

    public onPairColorChanged (newPairColor): void {
        this.fire(TerceraButtonEvents.onPairColorChanged, newPairColor);
        if (!isNullOrUndefined(this.PairColorChanged)) {
            this.PairColorChanged.Raise(this, newPairColor);
        }
    }
}

ContainerControl.extendWith(PairColorPicker, {
    data: function () {
        return {
            pairColor: null
        };
    },
    template: PairColorPickerTemplate
});
