// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MAMode } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iAO extends iBuildInIndicator {
    public Signal: ExpandDoubleVector = new ExpandDoubleVector();

    constructor () {
        super(1);
    }

    override get Name (): string { return 'iAO'; }

    public override Refresh (count, newThread): void {
        this.Signal.Dispose();
        this.Signal = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override NextBar (callBound): void {
        this.Signal.Add(0.0);
        super.NextBar(callBound);
    }

    public override OnQuote (): void {
        const Parent = this.Parent;
        if (!Parent) { return; }

        const close = this.GetPrice(PriceType.Close, 0);
        this.Signal[this.Signal.Length - 1] = close;
        if (this.Signal.Length >= 35) {
        // Пересчитываем текущее значение индикатора
            this.SetValue(0, 0, IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.Signal, 5, 1) - IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.Signal, 34, 1));
        }
    }
}
