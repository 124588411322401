// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

const AppliedExtreme =
{
    highLow: 0,
    highLowOpen: 1,
    highLowClose: 2,
    openHighLow: 3,
    closeHighLow: 4
};

export class DonchianChannel extends IndicatorScriptBase {
    public margins: number;
    public shift: number;
    public extremes: number;
    public donchianPeriod: number;

    constructor () {
        super();
        this.ProjectName = 'Donchian Channel';
        this.Comments = 'Donchian Channel';
        super.SetIndicatorLine('upper donchian', Color.Green, 3, LineStyle.SimpleChart);
        super.SetIndicatorLine('middle donchian', Color.Gray, 3, LineStyle.SimpleChart);
        super.SetIndicatorLine('lower donchian', Color.Red, 3, LineStyle.SimpleChart);
        this.SeparateWindow = false;
        this.upperBuffer = [];
        this.lowerBuffer = [];

        this.donchianPeriod = 20;
        super.InputParameter(new InputParameterInteger('donchianPeriod', 'Donchian Period', 0, 1, 9999));

        this.extremes = AppliedExtreme.highLow;
        super.InputParameter(new InputParameterCombobox('extremes', 'Extremes', 1,
            [
                { highLow: AppliedExtreme.highLow },
                { highLowOpen: AppliedExtreme.highLowOpen },
                { highLowClose: AppliedExtreme.highLowClose },
                { openHighLow: AppliedExtreme.openHighLow },
                { closeHighLow: AppliedExtreme.closeHighLow }
            ]));

        this.margins = -2;
        super.InputParameter(new InputParameterInteger('margins', 'Margins', 2, -9999, 9999));

        this.shift = 0;
        super.InputParameter(new InputParameterInteger('shift', 'Shift', 3, 0, 9999));
    }

    public Init (): void {
        this.IndicatorShortName('DC (' + this.donchianPeriod + ')');
    };

    public OnQuote (): void {
        if (this.CurrentData.Count - 1 < this.donchianPeriod) { return; };
        let smin, smax;
        this.upperBuffer = [];
        this.lowerBuffer = [];

        for (let i = 0; i < this.donchianPeriod; i++) {
            switch (this.extremes) {
            case AppliedExtreme.highLow:
                this.upperBuffer.push(this.CurrentData.GetPrice(PriceType.High, i + this.shift));
                this.lowerBuffer.push(this.CurrentData.GetPrice(PriceType.Low, i + this.shift));
                break;
            case AppliedExtreme.highLowOpen:
                this.upperBuffer.push((this.CurrentData.GetPrice(PriceType.Open, i + this.shift) + this.CurrentData.GetPrice(PriceType.High, i + this.shift)) / 2);
                this.lowerBuffer.push((this.CurrentData.GetPrice(PriceType.Open, i + this.shift) + this.CurrentData.GetPrice(PriceType.Low, i + this.shift)) / 2);
                break;
            case AppliedExtreme.highLowClose:
                this.upperBuffer.push((this.CurrentData.GetPrice(PriceType.Close, i + this.shift) + this.CurrentData.GetPrice(PriceType.High, i + this.shift)) / 2);
                this.lowerBuffer.push((this.CurrentData.GetPrice(PriceType.Close, i + this.shift) + this.CurrentData.GetPrice(PriceType.Low, i + this.shift)) / 2);
                break;
            case AppliedExtreme.openHighLow:
                this.upperBuffer.push(this.CurrentData.GetPrice(PriceType.Open, i + this.shift));
                this.lowerBuffer.push(this.CurrentData.GetPrice(PriceType.Open, i + this.shift));
                break;
            case AppliedExtreme.closeHighLow:
                this.upperBuffer.push(this.CurrentData.GetPrice(PriceType.Close, i + this.shift));
                this.lowerBuffer.push(this.CurrentData.GetPrice(PriceType.Close, i + this.shift));
                break;
            }
        }
        smin = Math.min(...this.lowerBuffer) + (Math.max(...this.upperBuffer) - Math.min(...this.lowerBuffer)) * this.margins / 100;
        smax = Math.max(...this.upperBuffer) - (Math.max(...this.upperBuffer) - Math.min(...this.lowerBuffer)) * this.margins / 100;
        this.SetValue(0, smax);
        this.SetValue(1, (smax + smin) / 2);
        this.SetValue(2, smin);
    };

    public NextBar (): void {
        super.NextBar();
    }

    private upperBuffer: number[];
    private lowerBuffer: number[];
}
