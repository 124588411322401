// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iBWMFI extends iBuildInIndicator {
    constructor () {
        super(1);
    }

    override get Name (): string { return 'iBWMFI'; }

    public override OnQuote (): void {
        const Parent = this.Parent;
        if (!Parent) {
            return;
        }

        if (Parent != null) {
            const volume = Parent.GetVolume(0);
            if (volume != 0) {
                this.SetValue((this.GetPrice(PriceType.High, 0) - this.GetPrice(PriceType.Low, 0)) / (volume * this.FParent.Instrument.PointSize));
            }
        }
    }
}
