// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Enum } from '../Utils/Enum';

export enum IsAllowedResponceReason {
    // [LocalizedDescription("Unknown")]
    Unknown = 0,
    // [LocalizedDescription("LockTrading")]
    LockTrading = 1, //
    // [LocalizedDescription("RuleFunctionTrading")]
    RuleFunctionTrading = 2, // rule FUNCTION_TRADING
    // [LocalizedDescription("RuleClosePositionDisable")]
    RuleClosePositionDisable = 3, // rule FUNCTION_CLOSE_POSITION
    // [LocalizedDescription("InstrumentTradingMode")]
    InstrumentTradingMode = 4, // ins.TradingMode == TradingMode.FullyClosed
    // [LocalizedDescription("NoLastQuote")]
    NoLastQuote = 5,
    // [LocalizedDescription("NoAskPrice")]
    NoAskPrice = 6,
    // [LocalizedDescription("NoBidPrice")]
    NoBidPrice = 7,
    // [LocalizedDescription("OrderTypeNotAllowedBySession")]
    OrderTypeNotAllowedBySession = 8,
    // [LocalizedDescription("RouteNotRunning")]
    RouteNotRunning = 9,
    // [LocalizedDescription("OperationNotAllowedBySession")]
    OperationNotAllowedBySession = 10,
    // [LocalizedDescription("OperationNotAllowedByOrderType")]
    OperationNotAllowedByOrderType = 11, // modify for Market, ...
    // [LocalizedDescription("NotVisibleInstrumentRoute")]
    NotVisibleInstrumentRoute = 12, // for account
    // [LocalizedDescription("NotAllowedRouteOrderType")]
    NotAllowedRouteOrderType = 13,
    // [LocalizedDescription("NotClosablePosition")]
    NotClosablePosition = 14,
    // [LocalizedDescription("RuleSlTpNotAllowed")]
    RuleSlTpNotAllowed = 15,
    // [LocalizedDescription("ExecuteAtMarketNotAllowedForSlTp")]
    ExecuteAtMarketNotAllowedForSlTp = 16,
    // [LocalizedDescription("NotAllowedByOrderStaus")]
    NotAllowedByOrderStaus = 17,
    // [LocalizedDescription("NotAllowOpenPositionRule")]
    NotAllowOpenPositionRule = 18,
    // [LocalizedDescription("RuleFunctionSelfTrading")]
    RuleFunctionSelfTrading = 19, // rule FUNCTION_SELF_TRADING
    // [LocalizedDescription("InstrumentForAccountDelivery")]
    InstrumentForAccountDelivery = 20,
    // [LocalizedDescription("WrongTraidingPositionType")]
    WrongTraidingPositionType = 21,
    // [LocalizedDescription("RuleOCO")]
    RuleOCO = 22,
    // [LocalizedDescription("RuleTrStop")]
    RuleTrStop = 23,
    // [LocalizedDescription("NotLoadCache")]
    NotLoadCache = 24,
    // [LocalizedDescription("RuleAdministration")]
    RuleAdministration = 25,
    // [LocalizedDescription("PositionsAdmin")]
    PositionsAdmin = 26,
    // [LocalizedDescription("WrongAccount")]
    WrongAccount = 27,
    // [LocalizedDescription("CustomReason")]
    CustomReason = 28,
    // [LocalizedDescription("NotSupportedTIF")]
    NotSupportedTIF = 29,
    // [LocalizedDescription("ExecuteAtMarketNotAllowedForBindOrders")]
    ExecuteAtMarketNotAllowedForBindOrders = 30,
    // [LocalizedDescription("ExecuteAtMarketNotAllowedOrderType")]
    ExecuteAtMarketNotAllowedOrderType = 31,
    // [LocalizedDescription("LockTradingByPassword")]
    LockTradingByPassword = 32,
    // [LocalizedDescription("PositionCloseOnly")]
    NotAllowedPositionCloseOnly = 33,
    // [LocalizedDescription("OptionPendingExerciseStatus")]
    OptionPendingExerciseStatus = 34, // #93127
    MoreThanOnePosSelected = 35, // #93127
    OpenNotAllowedInTrSession = 36, // #93127
    // [LocalizedDescription("NotAllowedCorporateActionPositions")]
    NotAllowedCorporateActionPositions = 37, // #101851
    NotAllowedMaxLotHasBeenReached = 38, // #92710
    NotAllowedByExploreMode = 39, // #117636
    NotAllowedForTrades = 40, // #120264
    RuleTrading = 41, // #122105
}

export const IsAllowedResponceReasonName = Enum.TakeKeysFromEnum(IsAllowedResponceReason);
