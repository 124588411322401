// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType, PriceTypeMap } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterDouble } from '../InputParamaterClasses/InputParameterDouble';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class KELTNER extends IndicatorScriptBase {
    public SourcePrice: number;
    public MAType: any;
    public MAPeriod: number;
    public CoefChannelWidth: number;
    public bandStyle: number;

    private ma: iBuildInIndicator;
    private distanceMA: any;

    constructor () {
        super();
        this.ProjectName = 'Keltner Channel';
        this.Comments = 'KELTNER';
        this.SetIndicatorLine('Line 1', Color.Coral, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('Line 2', Color.Red, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('Line 3', Color.Purple, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of moving average', 1,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.MAPeriod = 5;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of MA for Keltner\'s Channel', 2, 1, 9999));

        this.CoefChannelWidth = 2;
        super.InputParameter(new InputParameterDouble('CoefChannelWidth', 'Coefficient of channel\’s width', 3, 1, 20, 1, 0.1));

        this.bandStyle = BandStyle.TrueRange;
        super.InputParameter(new InputParameterCombobox('bandStyle', 'Bands style', 4,
            [
                { 'True range': BandStyle.TrueRange },
                { 'Trading range': BandStyle.TradingRange }
            ]));
    }

    public override GetIndicatorShortName (): string {
        return 'KELTNER(' +
            this.MAPeriod + ';' +
            this.CoefChannelWidth + ';' +
            PriceTypeMap[this.SourcePrice].toLowerCase() + ')';
    };

    public override getCustomName (): string {
        return 'KELTNER (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ma = this.Indicators.iMA(
            this.CurrentData, this.MAPeriod, this.MAType, 0, this.SourcePrice);

        const distanceCB =
            this.bandStyle === BandStyle.TradingRange
                ? this.getTradingRange.bind(this)
                : this.getTrueRange.bind(this);

        this.distanceMA = this.Indicators.iMAOnArray(
            this.CurrentData, distanceCB, this.MAPeriod, this.MAType);

        return [this.ma, this.distanceMA];
    };

    public OnQuote (): void {
        super.OnQuote();

        const CurrentData = this.CurrentData;
        const MAPeriod = this.MAPeriod;
        if (CurrentData.Count <= MAPeriod) { return; };

        const maVal = this.ma.GetValue();
        const distance = this.distanceMA.GetValue();

        this.SetValue(0, 0, maVal + distance);
        this.SetValue(1, 0, maVal);
        this.SetValue(2, 0, maVal - distance);
    };

    public getTradingRange (offset): number {
        const CurrentData = this.CurrentData;
        const high = CurrentData.GetPrice(PriceType.High, offset);
        const low = CurrentData.GetPrice(PriceType.Low, offset);
        return (high - low) * this.CoefChannelWidth;
    };

    public getTrueRange (offset): number {
        const prevCloseOffset = offset + 1;

        const CurrentData = this.CurrentData;
        const high = CurrentData.GetPrice(PriceType.High, offset);
        const low = CurrentData.GetPrice(PriceType.Low, offset);
        const close = CurrentData.GetPrice(
            PriceType.Close,
            CurrentData.Count > prevCloseOffset ? prevCloseOffset : offset);

        const max = Math.max(
            high - low,
            Math.abs(high - close),
            Math.abs(low - close));

        return max * this.CoefChannelWidth;
    };
}

enum BandStyle {
    TrueRange = 0,
    TradingRange = 1
}
