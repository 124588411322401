// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { type LineDataCacheTool } from '../../Commons/cache/Tools/LineDataCacheTool';
import { ToolView } from './ToolView';

export class LineToolView extends ToolView<LineDataCacheTool> {
    public override Draw (gr, ww, param): void {
        try {
            const cacheTool = this.dataCacheTool;
            if (cacheTool == null) {
                return;
            }

            if (cacheTool.ShowAngle() && (this.screenPoints[0][0] != this.screenPoints[1][0] || this.screenPoints[0][1] != this.screenPoints[1][1])) {
                const k = -(this.screenPoints[0][1] - this.screenPoints[1][1]) / (this.screenPoints[0][0] - this.screenPoints[1][0]);
                const R = Math.atan(k);
                const Gr = Math.round(R * 180 / Math.PI);

                const angle = Gr.toString() + '\u00B0';
                gr.font = cacheTool.font;
                const xPos = (this.screenPoints[0][0] < this.screenPoints[1][0] ? this.screenPoints[0][0] : this.screenPoints[1][0]) - gr.measureText(angle, cacheTool.font).width - 10;
                const yPos = this.screenPoints[0][0] < this.screenPoints[1][0] ? this.screenPoints[0][1] : this.screenPoints[1][1];
                gr.DrawString(angle, cacheTool.font, cacheTool.FontBrush, xPos, yPos);
            }

            ToolView.DrawLineWithRay(gr, ww, this.screenPoints[0][0], this.screenPoints[0][1], this.screenPoints[1][0], this.screenPoints[1][1],
                this.dataCacheTool.RayType, this.dataCacheTool.Pen, this.dataCacheTool.PenRay);

            super.Draw(gr, ww, param);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }
}
