// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// TODO. Inherit from DataCacheBusinessObject.

import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { AccountType } from '@shared/utils/Account/AccountType';
import { AccountAdditionalInfoItem } from '@shared/utils/Account/AdditionalInfoItem';
import { MathUtils } from '@shared/utils/MathUtils';
import { type Asset } from './Asset';
import { type Account } from './Account';
import { type DataCache } from '../DataCache';

export class AssetBalance {
    public account: Account;
    public FDataCache: DataCache;
    public IsEmpty: boolean;
    public assetBalanceData: AssetBalanceData;
    public Asset: Asset | null = null;
    public OpenOrdNumber: any;
    public OpenPosNumber: any;

    constructor (account: Account, isEmpty: boolean) {
        this.account = account;

        this.FDataCache = account.DataCache;

        isEmpty = isEmpty || false;

        this.IsEmpty = isEmpty;
        this.assetBalanceData = isEmpty
            ? AssetBalanceData.Empty
            : new AssetBalanceData();
    }

    public RecreateData (): void {
        this.assetBalanceData = new AssetBalanceData();
        this.IsEmpty = false;
    }

    public FillByMessage (mess): boolean {
        const assetBalanceData = this.assetBalanceData;
        if (mess.Balance !== null) { assetBalanceData.balance = mess.Balance; }
        if (mess.BlockedSum !== null) { assetBalanceData.blockedSum = mess.BlockedSum; }
        if (mess.CashBalance !== null) { assetBalanceData.cashBalance = mess.CashBalance; }
        if (mess.BeginBalance !== null) { assetBalanceData.beginBalance = mess.BeginBalance; }
        if (mess.RealizedPnl !== null) { assetBalanceData.realizedPnl = mess.RealizedPnl; }
        // nicky fix (по согласованию с сашей и женей)
        // сервером присылается + если с меня снимают деньги, + если приплюсовывают.
        if (mess.TodayFees !== null) { assetBalanceData.todayFees = -mess.TodayFees; }
        if (mess.TodayTurnover !== null) { assetBalanceData.todayTurnover = mess.TodayTurnover; }
        if (mess.BlockedForOrders !== null) { assetBalanceData.blockedForOrders = mess.BlockedForOrders; }
        // if (mess.LockedForPAMM !== null)
        //     assetBalanceData.blockedForPAMM = mess.LockedForPAMM
        if (mess.ReservedBalance !== null) { assetBalanceData.reservedBalance = mess.ReservedBalance; }
        if (mess.CreditValue !== null) { assetBalanceData.creditValue = mess.CreditValue; }
        if (mess.UsedMargin !== null) { assetBalanceData.minMargin = mess.UsedMargin; }
        if (mess.AvailableMargin !== null) { assetBalanceData.availableMargin = mess.AvailableMargin; }
        if (mess.MaintanceMargin !== null) { assetBalanceData.maintanceMargin = mess.MaintanceMargin; }
        if (mess.MarginDeficiency !== null) { assetBalanceData.marginDeficiency = mess.MarginDeficiency; }
        if (mess.MarginSurplus !== null) { assetBalanceData.marginSurplus = mess.MarginSurplus; }
        if (mess.InitialMarginWithoutWaived !== null) {
            assetBalanceData.InitialMarginWithoutWaived = mess.InitialMarginWithoutWaived;
        }
        if (mess.TodayTrades !== null) { assetBalanceData.todayTrades = mess.TodayTrades; }
        if (mess.TodayVolume !== null) { assetBalanceData.todayVolume = mess.TodayVolume; }
        if (mess.TodayRebates !== null) { assetBalanceData.todayRebates = mess.TodayRebates; }
        if (mess.TodaySwaps !== null) { assetBalanceData.todaySwaps = mess.TodaySwaps; }
        if (mess.UnsettledLoss !== null) { assetBalanceData.unsettledLoss = mess.UnsettledLoss; }
        if (mess.UnsettledProfit !== null) { assetBalanceData.unsettledProfit = mess.UnsettledProfit; }
        if (mess.CurrentDailyLoss !== null) { assetBalanceData.currentDailyLoss = mess.CurrentDailyLoss; }
        if (mess.CurrentWeeklyLoss !== null) { assetBalanceData.currentWeeklyLoss = mess.CurrentWeeklyLoss; }
        if (mess.TrailingDrawdownLevel !== null) {
            assetBalanceData.trailingDrawdownLevel = mess.TrailingDrawdownLevel;
        }
        if (mess.StockOrdersReq !== null) { assetBalanceData.stockOrdersReq = mess.StockOrdersReq; }
        if (mess.UsedUnsettledNegativePremium !== null) {
            assetBalanceData.UsedUnsettledNegativePremium = mess.UsedUnsettledNegativePremium;
        }
        if (mess.UnusedUnsettledNegativePremium !== null) {
            assetBalanceData.UnusedUnsettledNegativePremium = mess.UnusedUnsettledNegativePremium;
        }
        if (mess.UnsettledPositivePremium !== null) {
            assetBalanceData.UnsettledPositivePremium = mess.UnsettledPositivePremium;
        }
        if (mess.UnsettledPremiumFromOpenSell !== null) {
            assetBalanceData.UnsettledPremiumFromOpenSell = mess.UnsettledPremiumFromOpenSell;
        }
        if (mess.UsedPremiumFromOpenSell !== null) {
            assetBalanceData.UsedPremiumFromOpenSell = mess.UsedPremiumFromOpenSell;
        }
        if (mess.UnusedPremiumFromOpenSell !== null) {
            assetBalanceData.UnusedPremiumFromOpenSell = mess.UnusedPremiumFromOpenSell;
        }
        if (mess.UsedUnsettledNegativeCashForStocks !== null) {
            assetBalanceData.UsedUnsettledNegativeCashForStocks = mess.UsedUnsettledNegativeCashForStocks;
        }
        if (mess.UnusedUnsettledNegativeCashForStocks !== null) {
            assetBalanceData.UnusedUnsettledNegativeCashForStocks = mess.UnusedUnsettledNegativeCashForStocks;
        }
        if (mess.UnsettledPositiveCashForStocks !== null) {
            assetBalanceData.UnsettledPositiveCashForStocks = mess.UnsettledPositiveCashForStocks;
        }
        if (mess.StocksLiquidity !== null) { assetBalanceData.StocksLiquidity = mess.StocksLiquidity; }
        if (mess.OptionPremiumReq !== null) { assetBalanceData.OptionPremiumReq = mess.OptionPremiumReq; }
        if (mess.UnsettledLossAvailableForWithdrawal !== null) {
            assetBalanceData.UnsettledLossAvailableForWithdrawal = mess.UnsettledLossAvailableForWithdrawal;
        }
        if (mess.WithdrawableCashForStocks !== null) {
            assetBalanceData.WithdrawableCashForStocks = mess.WithdrawableCashForStocks;
        }

        if (mess.WithdrawableUnsettledPnL !== null) {
            assetBalanceData.WithdrawableUnsettledPnL = mess.WithdrawableUnsettledPnL;
        }

        if (mess.UnsettledCollateral !== null) {
            assetBalanceData.UnsettledCollateral = mess.UnsettledCollateral;
        }

        if (mess.WithdrawableUnsettledCollateral !== null) {
            assetBalanceData.WithdrawableUnsettledCollateral = mess.WithdrawableUnsettledCollateral;
        }

        if (mess.Collateral !== null) { assetBalanceData.Collateral = mess.Collateral; }

        if (mess.UnusedSettledCashForStocks !== null) {
            assetBalanceData.UnusedSettledCashForStocks = mess.UnusedSettledCashForStocks;
        }
        if (mess.UnusedSettledCollateral !== null) {
            assetBalanceData.UnusedSettledCollateral = mess.UnusedSettledCollateral;
        }

        if (mess.UnsettledDeposit !== null) { assetBalanceData.unsettledDeposit = mess.UnsettledDeposit; }

        if (mess.UnusedIntradayInitialMargin !== null) {
            assetBalanceData.UnusedIntradayInitialMargin = mess.UnusedIntradayInitialMargin;
        }
        if (mess.UsedOvernightInitialMarginForIntraday !== null) {
            assetBalanceData.UsedOvernightInitialMarginForIntraday = mess.UsedOvernightInitialMarginForIntraday;
        }

        if (mess.AccruedDividends !== null) {
            assetBalanceData.AccruedDividends = mess.AccruedDividends;
        }

        if (mess.WithdrawableOptionsPremium !== null) {
            assetBalanceData.WithdrawableOptionsPremium = mess.WithdrawableOptionsPremium;
        }

        if (mess.WarningMarginRequirement !== null) {
            assetBalanceData.warningMarginRequirement = mess.WarningMarginRequirement;
        }

        if (mess.MarginBeforeWarning !== null) {
            assetBalanceData.marginBeforeWarning = mess.MarginBeforeWarning;
        }

        if (mess.WithdrawalAvailable !== null) {
            assetBalanceData.WithdrawalAvailable = mess.WithdrawalAvailable;
        }

        if (mess.MaintenanceMarginReqPercent != null) {
            assetBalanceData.MaintenanceMarginReqPercent = mess.MaintenanceMarginReqPercent;
        }

        if (mess.InitialMarginReqPercent != null) {
            assetBalanceData.InitialMarginReqPercent = mess.InitialMarginReqPercent;
        }

        if (mess.WarningMarginReqPercent != null) {
            assetBalanceData.WarningMarginReqPercent = mess.WarningMarginReqPercent;
        }

        if (mess.StopOutValue != null) {
            assetBalanceData.StopOutValue = mess.StopOutValue;
        }

        if (mess.EODTrailingDrawdownLevel != null) {
            assetBalanceData.EODTrailingDrawdownLevel = mess.EODTrailingDrawdownLevel;
        }

        // #120451
        if (mess.CalculatedRelativeDailyLossLimit != null) {
            assetBalanceData.CalculatedRelativeDailyLossLimit = mess.CalculatedRelativeDailyLossLimit;
        }

        if (mess.RelativeOfRelativeDailyLossLimit != null) {
            assetBalanceData.RelativeOfRelativeDailyLossLimit = mess.RelativeOfRelativeDailyLossLimit;
        }

        if (mess.TotalPositionsValue != null) {
            assetBalanceData.TotalPositionsValue = mess.TotalPositionsValue;
        }

        if (mess.AvailableCash != null) {
            assetBalanceData.AvailableCash = mess.AvailableCash;
        }

        let AssetAdditionalInfoUpdated = false;
        if (mess.AssetAdditionalInfo != null) {
            for (const itemKey in mess.AssetAdditionalInfo) {
                if (!mess.AssetAdditionalInfo.hasOwnProperty(itemKey)) {
                    continue;
                }

                const itemValue = mess.AssetAdditionalInfo[itemKey];

                if (assetBalanceData.AssetAdditionalInfo[itemKey]) {
                    assetBalanceData.AssetAdditionalInfo[itemKey].Update(itemValue);
                } else {
                    const newItem = new AccountAdditionalInfoItem();
                    newItem.Update(itemValue);
                    assetBalanceData.AssetAdditionalInfo[itemKey] = newItem;
                    AssetAdditionalInfoUpdated = true;
                }
            }
        }

        if (mess.BuyingPowerAvailableFunds !== null) {
            assetBalanceData.BuyingPowerAvailableFunds = mess.BuyingPowerAvailableFunds;
        } // #106952

        return AssetAdditionalInfoUpdated;
    }

    get TodayRealizedPNL (): any {
        const assetBalanceData = this.assetBalanceData;
        return isNaN(assetBalanceData.realizedPnl)
            ? assetBalanceData.balance - assetBalanceData.beginBalance
            : assetBalanceData.realizedPnl;
    }

    get TodayRealizedPNLCrossPrice (): number {
        return this.TodayRealizedPNL * this.getCrossPrice();
    }

    get TodayFees (): number { return this.assetBalanceData.todayFees; }

    get TodayFeesCrossPrice (): number {
        return this.assetBalanceData.todayFees * this.getCrossPrice();
    }

    get TodayGrossPNL (): number {
        return this.assetBalanceData.realizedPnl - this.assetBalanceData.todayFees - this.assetBalanceData.todaySwaps;
    }

    get TodayGrossPNLCrossPrice (): number {
        return this.TodayGrossPNL * this.getCrossPrice();
    }

    get TodayVolume (): number { return this.assetBalanceData.todayVolume; }

    get TodayTrades (): number { return this.assetBalanceData.todayTrades; }

    get TodayRebates (): number { return this.assetBalanceData.todayRebates; }

    get TodayRebatesCrossPrice (): number {
        return this.assetBalanceData.todayRebates * this.getCrossPrice();
    }

    get WithdrawForAccount (): number {
        if (this.assetBalanceData.blockedSum < Number.MAX_VALUE && this.assetBalanceData.blockedSum > Number.MIN_VALUE) {
            return this.assetBalanceData.blockedSum;
        } else {
            return 0;
        }
    }

    get BalanceCrossPrice (): number {
        return this.assetBalanceData.balance * this.getCrossPrice();
    }

    get BlockedSumCrossPrice (): number {
        return this.assetBalanceData.blockedSum * this.getCrossPrice();
    }

    get MinMarginCrossPrice (): number {
        return this.assetBalanceData.minMargin * this.getCrossPrice();
    }

    get MarginAvailableCrossPrice (): number {
        return this.assetBalanceData.availableMargin * this.getCrossPrice();
    }

    get MaintMarginForPositionCrossPrice (): number {
        return this.assetBalanceData.maintanceMargin * this.getCrossPrice();
    }

    get BlockedForOrdersCrossPrice (): number {
        return this.assetBalanceData.blockedForOrders * this.getCrossPrice();
    }

    get CreditValueCrossPrice (): number {
        return this.assetBalanceData.creditValue * this.getCrossPrice();
    }

    get MarginDeficiencyCrossPrice (): number {
        return this.assetBalanceData.marginDeficiency * this.getCrossPrice();
    }

    get MarginSurplusCrossPrice (): number {
        return this.assetBalanceData.marginSurplus * this.getCrossPrice();
    }

    get TodaySwapsCrossPrice (): number {
        return this.assetBalanceData.todaySwaps * this.getCrossPrice();
    }

    get AssetAdditionalInfo (): any {
        return this.assetBalanceData.AssetAdditionalInfo;
    }

    // #region Server data
    get AvailableMargin (): number { return this.assetBalanceData.availableMargin; }
    set AvailableMargin (value) { this.assetBalanceData.availableMargin = value; }

    get StocksLiquidity (): number { return this.assetBalanceData.StocksLiquidity; }
    set StocksLiquidity (value) { this.assetBalanceData.StocksLiquidity = value; }

    get BalancePlusAllRisks (): number { return this.Balance; }

    get Balance (): number { return this.assetBalanceData.balance; }
    set Balance (value) { this.assetBalanceData.balance = value; }

    get BeginBalance (): number { return this.assetBalanceData.beginBalance; }
    set BeginBalance (value) { this.assetBalanceData.beginBalance = value; }

    get BlockedForOrders (): number { return this.assetBalanceData.blockedForOrders; }
    set BlockedForOrders (value) { this.assetBalanceData.blockedForOrders = value; }

    get BlockedSum (): number { return this.assetBalanceData.blockedSum; }
    set BlockedSum (value) { this.assetBalanceData.blockedSum = value; }

    get CashBalance (): number { return this.assetBalanceData.cashBalance; }
    set CashBalance (value) { this.assetBalanceData.cashBalance = value; }

    get CreditValue (): number { return this.assetBalanceData.creditValue; }
    set CreditValue (value) { this.assetBalanceData.creditValue = value; }

    get RealizedPnl (): number { return this.assetBalanceData.realizedPnl; }
    set RealizedPnl (value) { this.assetBalanceData.realizedPnl = value; }

    get MaintanceMargin (): number { return this.assetBalanceData.maintanceMargin; }
    set MaintanceMargin (value) { this.assetBalanceData.maintanceMargin = value; }

    get MarginDeficiency (): number { return this.assetBalanceData.marginDeficiency; }
    set MarginDeficiency (value) { this.assetBalanceData.marginDeficiency = value; }

    get MarginSurplus (): number { return this.assetBalanceData.marginSurplus; }
    set MarginSurplus (value) { this.assetBalanceData.marginSurplus = value; }

    get ReservedBalance (): number { return this.assetBalanceData.reservedBalance; }
    set ReservedBalance (value) { this.assetBalanceData.reservedBalance = value; }

    get TodayTurnover (): number { return this.assetBalanceData.todayTurnover; }
    set TodayTurnover (value) { this.assetBalanceData.todayTurnover = value; }

    get MinMargin (): number { return this.assetBalanceData.minMargin; }
    set MinMargin (value) { this.assetBalanceData.minMargin = value; }

    get TodaySwaps (): number { return this.assetBalanceData.todaySwaps; }

    get UnsettledProfit (): number { return this.assetBalanceData.unsettledProfit; }
    set UnsettledProfit (value) { this.assetBalanceData.unsettledProfit = value; }

    get UnsettledLoss (): number { return this.assetBalanceData.unsettledLoss; }
    set UnsettledLoss (value) { this.assetBalanceData.unsettledLoss = value; }

    get UnsettledLossAvailableForWithdrawal (): number {
        return this.assetBalanceData.UnsettledLossAvailableForWithdrawal;
    }

    set UnsettledLossAvailableForWithdrawal (value) {
        this.assetBalanceData.UnsettledLossAvailableForWithdrawal = value;
    }

    get UnusedSettledCashForStocks (): number {
        return this.assetBalanceData.UnusedSettledCashForStocks;
    }

    set UnusedSettledCashForStocks (value) {
        this.assetBalanceData.UnusedSettledCashForStocks = value;
    }

    get UnsettledDeposit (): number {
        return this.assetBalanceData.unsettledDeposit;
    }

    set UnsettledDeposit (value) {
        this.assetBalanceData.unsettledDeposit = value;
    }

    get UnusedSettledCollateral (): number {
        return this.assetBalanceData.UnusedSettledCollateral;
    }

    set UnusedSettledCollateral (value) {
        this.assetBalanceData.UnusedSettledCollateral = value;
    }

    /// <summary>
    /// #98625
    /// </summary>
    get UnusedIntradayInitialMargin (): number {
        return this.assetBalanceData.UnusedIntradayInitialMargin;
    }

    set UnusedIntradayInitialMargin (value) {
        this.assetBalanceData.UnusedIntradayInitialMargin = value;
    }

    get UsedOvernightInitialMarginForIntraday (): number {
        return this.assetBalanceData.UsedOvernightInitialMarginForIntraday;
    }

    set UsedOvernightInitialMarginForIntraday (value) {
        this.assetBalanceData.UsedOvernightInitialMarginForIntraday = value;
    }

    get AccruedDividends (): number {
        return this.assetBalanceData.AccruedDividends;
    }

    set AccruedDividends (value) {
        this.assetBalanceData.AccruedDividends = value;
    }

    /// <summary>
    /// #75187
    /// </summary>
    get WithdrawableCashForStocks (): number {
        return this.assetBalanceData.WithdrawableCashForStocks;
    }

    set WithdrawableCashForStocks (value) {
        this.assetBalanceData.WithdrawableCashForStocks = value;
    }

    /// <summary>
    /// #75187
    /// </summary>
    get WithdrawableUnsettledPnL (): number {
        return this.assetBalanceData.WithdrawableUnsettledPnL;
    }

    set WithdrawableUnsettledPnL (value) {
        this.assetBalanceData.WithdrawableUnsettledPnL = value;
    }

    get TrailingDrawdownLevel (): number {
        return this.assetBalanceData.trailingDrawdownLevel;
    }

    set TrailingDrawdownLevel (value) {
        this.assetBalanceData.trailingDrawdownLevel = value;
    }

    /// <summary>
    /// #59262
    /// </summary>
    get StockOrdersReq (): number { return this.assetBalanceData.stockOrdersReq; }
    set StockOrdersReq (value) { this.assetBalanceData.stockOrdersReq = value; }

    get CurrentDailyLoss (): number { return this.assetBalanceData.currentDailyLoss; }
    set CurrentDailyLoss (value) { this.assetBalanceData.currentDailyLoss = value; }

    get CurrentWeeklyLoss (): number {
        return this.assetBalanceData.currentWeeklyLoss;
    }

    set CurrentWeeklyLoss (value) {
        this.assetBalanceData.currentWeeklyLoss = value;
    }

    get UsedUnsettledNegativePremium (): number {
        return this.assetBalanceData.UsedUnsettledNegativePremium;
    }

    set UsedUnsettledNegativePremium (value) {
        this.assetBalanceData.UsedUnsettledNegativePremium = value;
    }

    get UnusedUnsettledNegativePremium (): number {
        return this.assetBalanceData.UnusedUnsettledNegativePremium;
    }

    set UnusedUnsettledNegativePremium (value) {
        this.assetBalanceData.UnusedUnsettledNegativePremium = value;
    }

    get UnsettledPositivePremium (): number {
        return this.assetBalanceData.UnsettledPositivePremium;
    }

    set UnsettledPositivePremium (value) {
        this.assetBalanceData.UnsettledPositivePremium = value;
    }

    get UnsettledPremiumFromOpenSell (): number {
        return this.assetBalanceData.UnsettledPremiumFromOpenSell;
    }

    set UnsettledPremiumFromOpenSell (value) {
        this.assetBalanceData.UnsettledPremiumFromOpenSell = value;
    }

    get UsedPremiumFromOpenSell (): number {
        return this.assetBalanceData.UsedPremiumFromOpenSell;
    }

    set UsedPremiumFromOpenSell (value) {
        this.assetBalanceData.UsedPremiumFromOpenSell = value;
    }

    get UnusedPremiumFromOpenSell (): number {
        return this.assetBalanceData.UnusedPremiumFromOpenSell;
    }

    set UnusedPremiumFromOpenSell (value) {
        this.assetBalanceData.UnusedPremiumFromOpenSell = value;
    }

    get UsedUnsettledNegativeCashForStocks (): number {
        return this.assetBalanceData.UsedUnsettledNegativeCashForStocks;
    }

    set UsedUnsettledNegativeCashForStocks (value) {
        this.assetBalanceData.UsedUnsettledNegativeCashForStocks = value;
    }

    get UnusedUnsettledNegativeCashForStocks (): number {
        return this.assetBalanceData.UnusedUnsettledNegativeCashForStocks;
    }

    set UnusedUnsettledNegativeCashForStocks (value) {
        this.assetBalanceData.UnusedUnsettledNegativeCashForStocks = value;
    }

    get UnsettledPositiveCashForStocks (): number {
        return this.assetBalanceData.UnsettledPositiveCashForStocks;
    }

    set UnsettledPositiveCashForStocks (value) {
        this.assetBalanceData.UnsettledPositiveCashForStocks = value;
    }

    get OptionPremiumReq (): number { return this.assetBalanceData.OptionPremiumReq; }
    set OptionPremiumReq (value) { this.assetBalanceData.OptionPremiumReq = value; }

    /// <summary>
    /// #82716
    /// </summary>
    get UnsettledCollateral (): number { return this.assetBalanceData.UnsettledCollateral; }
    set UnsettledCollateral (value) { this.assetBalanceData.UnsettledCollateral = value; }

    /// <summary>
    /// #82716
    /// </summary>

    get WithdrawableUnsettledCollateral (): number {
        return this.assetBalanceData.WithdrawableUnsettledCollateral;
    }

    set WithdrawableUnsettledCollateral (value) {
        this.assetBalanceData.WithdrawableUnsettledCollateral = value;
    }
    /// <summary>
    /// #82716
    /// </summary>

    get Collateral (): number {
        return this.assetBalanceData.Collateral;
    }

    set Collateral (value) {
        this.assetBalanceData.Collateral = value;
    }

    /// <summary>
    /// #91468
    /// </summary>
    get WarningMarginRequirement (): number { return this.assetBalanceData.warningMarginRequirement; }

    get MarginBeforeWarning (): number { return this.assetBalanceData.marginBeforeWarning; }

    /// <summary>
    /// #110792
    /// </summary>

    get WithdrawalAvailable (): number { return this.assetBalanceData.WithdrawalAvailable; }
    set WithdrawalAvailable (value) { this.assetBalanceData.WithdrawalAvailable = value; }
    // #endregion

    get MaintenanceMarginReqPercent (): number {
        return this.assetBalanceData.MaintenanceMarginReqPercent;
    }

    get InitialMarginReqPercent (): number {
        return this.assetBalanceData.InitialMarginReqPercent;
    }

    get WarningMarginReqPercent (): number {
        return this.assetBalanceData.WarningMarginReqPercent;
    }

    get StopOutValue (): number {
        return this.assetBalanceData.StopOutValue;
    }

    get EODTrailingDrawdownLevel (): number {
        return this.assetBalanceData.EODTrailingDrawdownLevel;
    }

    // #120451
    get CalculatedRelativeDailyLossLimit (): number {
        return this.assetBalanceData.CalculatedRelativeDailyLossLimit;
    }

    get RelativeOfRelativeDailyLossLimit (): number {
        return this.assetBalanceData.RelativeOfRelativeDailyLossLimit;
    }

    get WithdrawableOptionsPremium (): number {
        return this.assetBalanceData.WithdrawableOptionsPremium;
    }

    set WithdrawableOptionsPremium (value) {
        this.assetBalanceData.WithdrawableOptionsPremium = value;
    }

    get BuyingPowerAvailableFunds (): any {
        return this.assetBalanceData.BuyingPowerAvailableFunds;
    }

    set BuyingPowerAvailableFunds (value) {
        this.assetBalanceData.BuyingPowerAvailableFunds = value;
    }

    get AvailableCash (): any {
        return this.assetBalanceData.AvailableCash;
    }

    set AvailableCash (value) {
        this.assetBalanceData.AvailableCash = value;
    }

    get TotalPositionsValue (): any {
        return this.assetBalanceData.TotalPositionsValue;
    }

    set TotalPositionsValue (value) {
        this.assetBalanceData.TotalPositionsValue = value;
    }

    public getCrossPrice (): number {
        return this.Asset ? this.FDataCache.CrossRateCache.GetCrossPriceExp1(this.Asset.Name) : 1;
    }

    public formatPriceExactly (value, noCurrency = false): any {
        return this.Asset.formatPrice(value, noCurrency);
    }

    public formatPrice (value: number, noCurrency: boolean = false): string {
        return this.Asset.formatPrice(value, noCurrency);
    }

    public roundFormatPrice (value: number, noCurrency: boolean = false): string {
        return this.Asset.roundFormatPrice(value, noCurrency);
    }

    public WithdrawalAvailableTruncateToAsset (val): number {
        return MathUtils.TruncateDouble(Math.max(val, 0), this.Asset.Point);
    }

    public WithdrawalAvaliable (acc: Account): number {
        const valueFromServer = this.WithdrawalAvailable; // #110792
        return valueFromServer !== null
            ? this.WithdrawalAvailableTruncateToAsset(valueFromServer)
            : this.AvaliableWithdrawCalculate(acc);
    }

    public AvaliableWithdrawCalculate (acc: Account | null): number {
        try {
            let stockValueDiscount = 0;

            if (acc != null && acc.AccountType !== AccountType.MultiAsset) {
                stockValueDiscount = acc.StockValueDiscount;
            }

            // ++64321
            const creditValue = this.CreditValue > 0 ? this.CreditValue : 0;

            const withdrawalAvailable = this.AvailableMargin -
                stockValueDiscount -
                creditValue -
                acc.OnlyProfitForWithdrawalAvailable -
                this.UnsettledProfit -
                this.UnsettledLossAvailableForWithdrawal +
                this.UnusedUnsettledNegativeCashForStocks -
                this.UnsettledPositiveCashForStocks +
                this.UnusedUnsettledNegativePremium -
                this.UnsettledPositivePremium +
                this.UnsettledPremiumFromOpenSell -
                this.UsedPremiumFromOpenSell +
                this.WithdrawableCashForStocks +
                this.WithdrawableUnsettledPnL +
                this.WithdrawableUnsettledCollateral -
                this.StocksLiquidity +
                this.UnusedIntradayInitialMargin -
                this.UsedOvernightInitialMarginForIntraday -
                this.AccruedDividends +
                this.WithdrawableOptionsPremium -
                this.UnsettledDeposit;

            return this.WithdrawalAvailableTruncateToAsset(withdrawalAvailable);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            return 0;
        }
    }
}

// #region AssetBalanceData

export class AssetBalanceData {
    public availableMargin = 0;
    public balance = 0;
    public beginBalance = 0;
    public blockedForOrders = 0;
    // public blockedForPAMM = 0
    public blockedSum = 0;
    public cashBalance = 0;
    public creditValue = 0;
    public realizedPnl = 0;
    public maintanceMargin = 0;
    public warningMarginRequirement = 0;
    public marginBeforeWarning = 0;
    public marginDeficiency = 0;
    public marginSurplus = 0;
    public reservedBalance = 0;
    public todayFees = 0;
    public todayTurnover = 0;
    public minMargin = 0;
    public todayTrades = 0;
    public todayVolume = 0;
    public todayRebates = 0;
    public todaySwaps = 0;
    public unsettledProfit = 0;
    public unsettledLoss = 0;
    public unsettledDeposit = 0;
    public UnsettledLossAvailableForWithdrawal = 0;
    public currentDailyLoss = 0;
    public currentWeeklyLoss = 0;
    public trailingDrawdownLevel = -1;
    public stockOrdersReq = 0;
    public UsedUnsettledNegativePremium = 0;
    public UnusedUnsettledNegativePremium = 0;
    public UnsettledPositivePremium = 0;
    public UnsettledPremiumFromOpenSell = 0;
    public UsedPremiumFromOpenSell = 0;
    public UnusedPremiumFromOpenSell = 0;
    public UsedUnsettledNegativeCashForStocks = 0;
    public UnusedUnsettledNegativeCashForStocks = 0;
    public UnsettledPositiveCashForStocks = 0;
    public StocksLiquidity = 0;
    public OptionPremiumReq = 0;
    public WithdrawableCashForStocks = 0;
    public WithdrawableUnsettledPnL = 0;

    // 82717
    public UnsettledCollateral = 0;
    public WithdrawableUnsettledCollateral = 0;
    public Collateral = 0;
    public InitialMarginWithoutWaived = 0;
    // 98161
    public UnusedSettledCashForStocks = 0;
    public UnusedSettledCollateral = 0;
    public UnsettledDeposit = 0;

    // 98625
    public UnusedIntradayInitialMargin = 0;
    public UsedOvernightInitialMarginForIntraday = 0;

    public AccruedDividends = 0;
    public AssetAdditionalInfo: any = {};

    public WithdrawableOptionsPremium = 0;

    public WithdrawalAvailable: any = null;

    public BuyingPowerAvailableFunds: any = null; // array of BuyingPowerAvailableFund

    // 119981
    public MaintenanceMarginReqPercent: number | null = null;
    public InitialMarginReqPercent: number | null = null;
    public WarningMarginReqPercent: number | null = null;
    public StopOutValue: number | null = null;
    public EODTrailingDrawdownLevel: number | null = null;

    // #120451
    public CalculatedRelativeDailyLossLimit: number | null = null;
    public RelativeOfRelativeDailyLossLimit: number | null = null;
    public AvailableCash: number | null = null;
    public TotalPositionsValue: number | null = null;
    public static Empty = new AssetBalanceData();
}
