// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingChartPanelTemplate } from '../templates.js';
import { RowColorText } from '../RowItems/BaseRowDataItem';
import { openOrderEntry } from '../Screen/InvestingOE';
import { Level1Calculator } from '../../Commons/cache/Level1Calculator';
import { Control } from '../../Controls/elements/Control';
import { debounce, format_Market_cap } from '../Utils/InvestingUtils';
import { ProcessChartVisible } from '../Investing';
import { WL_ListController } from '../Utils/WL_ListController';
import { LOCALE_EN } from '../../Localizations/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { Account } from '../../Commons/cache/Account';
import { AccountFeature } from '../../Utils/Account/AccountFeature';
import { DataCache } from '../../Commons/DataCache';
import Ractive from 'ractive';

export class InvestingChartPanel extends Ractive {
    public selectedHeaderSwitcherItem: any = null;
    public Account: any = null;
    public AssetBalance: any = null;
    debounceHandler: () => void;
    el: any;
    prew_last: any;
    terceraChart: any;

    public getType (): string { return 'InvestingChartPanel'; }

    public oninit (): void {
        this.on('closeButtonClick', this.closeButtonClick);
        this.on('clickHeaderSwitcher', this.changeActiveHeaderSwitcher);
        this.on('openOrderEntryEvent', this.openOrderEntryEvent);
        this.on('AddRemoveClick', this.AddRemoveClick);
        this.on('openAdvancedChart', this.OpenAdvancedChart);
        this.observe('dataItem', this.setDataItem);
        this.observe('accountItem', this.setAccountItem);
    }

    public oncomplete (): void {
        this.createHeaderSwitcher();
        this.debounceHandler = debounce(this.UpdateData, 400);
        Control.Ticker.Subscribe(this.debounceHandler, this);

        this.el.getElementsByClassName('investing_chart_panel_container')[0].getBoundingClientRect();
        const chartContainerSmall = this.el.getElementsByClassName('investing_chart_panel_container');
        if (chartContainerSmall) {
            const el = chartContainerSmall[0];
            if (el) {
                const sizes = el.getBoundingClientRect();
                void this.set({
                    chartWidth: sizes.width,
                    chartHeight: sizes.height
                });
            }
        }
    }

    public UpdateData (): void {
        const instrumentItem = this.get('instrumentItem');
        if (!instrumentItem) {
            return;
        }

        const lvl1 = instrumentItem.Level1;

        const currency = ' ' + instrumentItem.Exp2;
        const NA = 'N/A';
        const acc = DataCache.getPrimaryAccount();
        let Open = lvl1.strOpen(acc);
        if (Open !== NA) {
            Open += currency;
        }
        let Hight = lvl1.strHigh(acc);
        if (Hight !== NA) {
            Hight += currency;
        }
        let Low = lvl1.strLow(acc);
        if (Low !== NA) {
            Low += currency;
        }
        let Last = lvl1.StrLastPrice(acc);
        let Last_Color = this.get('Last_Color');
        if (Last !== NA) {
            const pr_l = this.prew_last;
            if (Last > pr_l) {
                Last_Color = 'green';
            }
            if (Last < pr_l) {
                Last_Color = 'red';
            }

            this.prew_last = Last;
            Last += currency;
        }
        let Prev_close = lvl1.strPrevClose(acc);
        if (Prev_close !== NA) {
            Prev_close += currency;
        }

        let Change = lvl1.strChangePercent(acc);
        if (Change !== Level1Calculator.NAString) {
            Change = parseFloat(Change).toLocaleString();
        }
        const ch_pr_v = lvl1.GetChangePercent(acc);
        let Change_Color = 'green';
        if (ch_pr_v < 0) {
            Change_Color = 'red';
        }
        const Volume = lvl1.strVolume(false);

        let Sector = '';
        let Industry = '';
        let Market_cap = '';

        if (instrumentItem.MarketCap) {
            Market_cap = format_Market_cap(instrumentItem.MarketCap);
        }

        const tmp_sector = DataCache.customInstrumentListsCache.instrumentListsDict.get(instrumentItem.SectorCustomListId);
        const tmp_industry = DataCache.customInstrumentListsCache.instrumentListsDict.get(instrumentItem.IndustryCustomListId);

        if (tmp_sector) {
            Sector = tmp_sector.name;
            if (tmp_sector.langAliases) {
                Sector = tmp_sector.langAliases[LOCALE_EN].Name;
            }
        }

        if (tmp_industry) {
            Industry = tmp_industry.name;

            if (tmp_industry.langAliases) {
                Industry = tmp_industry.langAliases[LOCALE_EN].Name;
            }
        }

        void this.set({
            Open,
            Hight,
            Low,
            Change,
            Last,
            Prev_close,
            Volume,
            Change_Color,
            Last_Color,
            Sector,
            Industry,
            Market_cap
        });

        if (this.Account && this.AssetBalance) {
            this.setAvailableCash();
        }
    }

    public override onteardown (): void {
        Control.Ticker.UnSubscribe(this.debounceHandler, this);
    }

    public closeButtonClick (): void {
        this.fire('CloseButtonClick');
    }

    public openOrderEntryEvent (isBuy): void {
        openOrderEntry(isBuy, this.get('dataItem'), this.get('accountItem'));
    }

    public setDataItem (): void {
        const data = this.get('dataItem');
        if (!data) return;

        this.unsubscribe(this.get('instrumentItem'));

        void this.set({
            name_value: data.Symbol,
            description_value: data.Description,
            logoSrc: data.Img,
            instrumentItem: data.Instrument
        });

        this.prew_last = 0;
        this.createHeaderSwitcher();
        this.subscribe(data.Instrument);

        const isPresent = WL_ListController.IsPresent(data.Instrument.GetInteriorID());
        void this.set({
            addInstrumentWL: !isPresent,
            AddRemoveText: isPresent ? 'Remove from watchlist' : 'Add to watchlist'
        });
    }

    public setAccountItem (): void {
        const account = this.get('accountItem');
        if (!account) return;

        this.Account = account;
        this.AssetBalance = account.assetBalanceDefault;

        this.setAvailableCash();
    }

    public setAvailableCash (): void {
        const curAccount = this.Account;
        const AssetBalance = this.AssetBalance;

        const value = Account.GetAccountFeature(AccountFeature.AvailableFunds, curAccount, AssetBalance);
        const formattedValue = Account.GetAccountFeatureString(value, AccountFeature.AvailableFunds, curAccount, AssetBalance);

        void this.set('availableCash', formattedValue);
    }

    public AddRemoveClick (): void {
        const state = this.get('addInstrumentWL');
        void this.set({
            addInstrumentWL: !state,
            AddRemoveText: state ? 'Remove from watchlist' : 'Add to watchlist'
        });

        if (state) {
            WL_ListController.AddInstrument(this.get('instrumentItem'));
        } else {
            WL_ListController.RemoveInstrument(this.get('instrumentItem'));
        }
    }

    public createHeaderSwitcher (): void {
        const arrayHeaderSwitcher: any = [
            { Name: 'Quick chart', TypePanel: InvestingChartPanel.Panels.CHART },
            { Name: 'Info', TypePanel: InvestingChartPanel.Panels.INFO }
        ];
        const instrumentItem = this.get('instrumentItem');
        if (instrumentItem && instrumentItem.InstrType === InstrumentTypes.EQUITIES) {
            arrayHeaderSwitcher.push({ Name: 'Profile ', TypePanel: InvestingChartPanel.Panels.PROFILE });
        }
        // { Name: "News", TypePanel: InvestingChartPanel.Panels.NEWS }

        arrayHeaderSwitcher[0].active = true;
        this.selectedHeaderSwitcherItem = arrayHeaderSwitcher[0];

        void this.set({ arrayHeaderSwitcher });
        this.changeShowPanel();
    }

    public changeActiveHeaderSwitcher (ev, index): void {
        const items = this.get('arrayHeaderSwitcher');
        if (!items) {
            return;
        }

        const newSelectedHeaderSwitcherItem = items[index];
        if (newSelectedHeaderSwitcherItem === this.selectedHeaderSwitcherItem) {
            return;
        }

        if (this.selectedHeaderSwitcherItem) {
            this.selectedHeaderSwitcherItem.active = false;
        }

        newSelectedHeaderSwitcherItem.active = true;
        this.selectedHeaderSwitcherItem = newSelectedHeaderSwitcherItem;

        void this.set({ arrayHeaderSwitcher: items });
        this.changeShowPanel();
    }

    public subscribe (instrument): void {
        if (!instrument) {
            return;
        }

        const qc = DataCache.FQuoteCache;

        qc.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        qc.addListener(instrument, this, HistoryType.QUOTE_TRADES);
    }

    public newQuote (quote): void {

    }

    public unsubscribe (instrument): void {
        if (!instrument) {
            return;
        }

        const qc = DataCache.FQuoteCache;

        qc.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        qc.removeListener(instrument, this, HistoryType.QUOTE_TRADES);
    }

    public changeShowPanel (): void {
        const whichPanel: any = {};

        switch (this.selectedHeaderSwitcherItem.TypePanel) {
        case InvestingChartPanel.Panels.CHART:
            whichPanel.Chart = true;
            break;
        case InvestingChartPanel.Panels.INFO:
            whichPanel.Info = true;
            break;
        case InvestingChartPanel.Panels.PROFILE:
            whichPanel.Profile = true;
            break;
        case InvestingChartPanel.Panels.NEWS:
            whichPanel.News = true;
            break;
        };

        void this.set({ whichPanel });
    }

    public static readonly Panels = {
        CHART: 0,
        INFO: 1,
        PROFILE: 2,
        NEWS: 3
    };

    public setColorProfit (numberProfit): RowColorText {
        if (numberProfit !== 0) {
            const profitColor = numberProfit > 0 ? RowColorText.GREEN : RowColorText.RED;
            return profitColor;
        } else {
            return RowColorText.BLACK;
        }
    }

    public OpenAdvancedChart (): void {
        ProcessChartVisible(true, this.get('instrumentItem'));
    }
}

Ractive.extendWith(InvestingChartPanel, {
    template: InvestingChartPanelTemplate,
    data: function () {
        return {
            dataItem: null,
            arrayHeaderSwitcher: [],
            whichPanel: {},
            instrumentItem: null,
            accountItem: null,

            addInstrumentWL: true,
            AddRemoveText: 'Remove from watchlist'
        };
    },
    selectedHeaderSwitcherItem: null,
    Account: null,
    AssetBalance: null
});
