// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectTradeMessage } from '../../Utils/DirectMessages/DirectMessagesImport';
import { type IHistoryBusinessObject } from './interface/IHistoryBusinessObject';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { OperationType } from '../../Utils/Trading/OperationType';
import { type Account } from './Account';
import { type Instrument } from './Instrument';
import { TradeSideEnum, TradeStatusEnum } from './TradeEnums';

/// <summary>
/// Объект для нового трейда
/// </summary>
export class NewTrade implements IHistoryBusinessObject {
    public TradeId: string;
    public OrderId: any;
    public Symbol: any;
    public AccountId: any;
    public Amount: any;
    public Price: any;
    public Comission: any;
    public Rebates = 0;
    public PnL: any;
    public Time: any;
    public BuySell: any;
    public Login: any;
    public RouteID: any;
    public ExternalPrice: any;
    public Exchange: any;
    public OrderType: any;

    public TradeIdLabel: any;

    /// <summary>
    /// Используется для начисления свопа не на баланс а на pnl (без необходимости не трогать)
    /// </summary>
    public Swap = 0;

    public OpenCrossPrise: any;
    public PositionID: any;
    public LastPositionAmount: any;
    public TradeSide = TradeSideEnum.None;
    public PositionSide: any;
    public CounterOrderID: any;

    public ExternalTradeId: any;
    public ExternalOrderId: any;

    /// <summary>
    /// #46611 - считаем сами, используем только если инструмент был удален
    /// </summary>
    public Exposure: any = undefined;

    public Instrument: Instrument | null = null;
    public Account: Account | null = null;

    /// <summary>
    /// Признак того, что трейд получен по отчёту
    /// </summary>
    public IsHistoryTrade = false;

    public isBaseInstrument = false;

    public ComissionInInstrumentQuotingCurrency: any;
    public PnLInInstrumentQuotingCurrency: any;

    public boughtString = '';

    public soldString = '';

    public AdvancedTradeParams: any = null;
    public ProductType: ProductType = null;
    public ExecutionVenue: any;

    public Description: any = null;

    public Leverage: any = null;

    public InstrumentStr: any = undefined;
    public Bought: any = undefined;
    public Sold: any = undefined;
    public TradeVolume: any = undefined;
    public TradingExchange: any = undefined;
    public DataCache: any;
    public InstrumentTradableID: any;
    public SymbolType: any;
    public StrikePrice: number;

    constructor (cache, message) {
        this.DataCache = cache;
        if (message) {
            this.UpdateByMessage(message);
        }
    }

    public CreateTradeMessage (): DirectTradeMessage {
        const message = new DirectTradeMessage();

        message.TradeId = this.TradeId;
        message.OrderId = this.OrderId;
        message.Symbol = this.Symbol;
        message.AccountId = this.AccountId;
        message.Amount = this.Amount;
        message.Price = this.Price;
        message.Comission = this.Comission;
        message.Rebates = this.Rebates;
        message.PnL = this.PnL;
        message.Time = this.Time;
        message.BuySell = this.BuySell;
        message.ExternalPrice = this.ExternalPrice;

        // +++yura
        message.Exchange = this.Exchange;
        message.OrderType = this.OrderType;

        message.Login = this.Login;
        message.Route = this.RouteID;

        message.PositionID = this.PositionID;
        message.LastPositionAmount = this.LastPositionAmount;
        message.PositionSide = this.PositionSide;
        message.IsClosing = this.PositionSide == TradeStatusEnum.Close;
        message.CounterOrderID = this.CounterOrderID;
        message.OpenCrossPrise = this.OpenCrossPrise;
        message.ExternalTradeId = this.ExternalTradeId;
        message.ExternalOrderId = this.ExternalOrderId;
        message.ProductType = this.ProductType;
        message.InstrumentTradableID = this.InstrumentTradableID;
        message.ExecutionVenue = this.ExecutionVenue;
        message.Leverage = this.Leverage;

        return message;
    }

    public UpdateByMessage (message): void {
        this.TradeId = message.TradeId;

        if (!message.TradeIdLabel) {
            this.TradeIdLabel = message.TradeIdLabel;
        }

        this.OrderId = message.OrderId;
        this.Symbol = message.Symbol;
        this.AccountId = message.AccountId;
        this.Amount = message.Amount;
        this.Price = message.Price;
        this.Comission = message.Comission / message.OpenCrossPrise;
        this.Rebates = message.Rebates || this.Rebates || 0; // TODO найти rebates seter
        this.PnL = message.PnL;
        this.Time = message.Time;
        this.BuySell = message.BuySell;
        this.ExternalPrice = message.ExternalPrice;

        // if (message.ComissionInInstrumentQuotingCurrency.HasValue)
        //    this.ComissionInInstrumentQuotingCurrency = message.ComissionInInstrumentQuotingCurrency.Value;
        // if (message.PnLInInstrumentQuotingCurrency.HasValue)
        //    this.PnLInInstrumentQuotingCurrency = message.PnLInInstrumentQuotingCurrency.Value;

        // +++yura
        this.Exchange = message.Exchange;
        this.OrderType = message.OrderType;

        this.Login = message.Login;
        this.RouteID = message.Route;
        this.PositionID = message.PositionID;
        this.LastPositionAmount = message.LastPositionAmount;

        this.TradeSide = message.PositionSide;

        this.CounterOrderID = message.CounterOrderID;

        this.OpenCrossPrise = message.OpenCrossPrise;

        this.ExternalTradeId = message.ExternalTradeId;
        this.ExternalOrderId = message.ExternalOrderId;

        if (this.LastPositionAmount == 0) {
            this.PositionSide = TradeStatusEnum.Close;
        } else if ((this.BuySell === OperationType.Buy && this.TradeSide === TradeSideEnum.Long) || // buy
        (this.BuySell === OperationType.Sell && this.TradeSide === TradeSideEnum.Short)) // sell
        {
            if (this.LastPositionAmount == this.Amount) {
                this.PositionSide = TradeStatusEnum.Open;
            } else if (this.Amount > this.LastPositionAmount) {
                this.PositionSide = TradeStatusEnum.Reverce;
            } else {
                this.PositionSide = TradeStatusEnum.Increase;
            }
        } else {
            this.PositionSide = TradeStatusEnum.Decrease;
        }

        this.InstrumentTradableID = message.InstrumentTradableID;

        this.Instrument = this.DataCache.getInstrumentByTradable_ID(this.InstrumentTradableID, this.RouteID);

        if (this.Instrument) {
            this.Description = this.Instrument.DescriptionValue();
        }

        // if (!this.RouteID)
        //    this.Symbol = DataCache.GetFullName(this.Symbol, this.RouteID);

        if (this.AccountId != null) {
            this.Account = this.DataCache.GetAccountById(this.AccountId.toString());
        }

        this.Swap = message.Swap || this.Swap || 0; // TODO найти Swap seter
        this.AdvancedTradeParams = message.AdvancedTradeParams;

        this.ProductType = message.ProductType ?? ProductType.General;
        this.ExecutionVenue = message.ExecutionVenue;

        this.Leverage = message.Leverage || null;
    }

    public getTradeVolume (): any {
        if (this.TradeVolume) {
            return this.TradeVolume;
        }

        return this.Price * this.Amount * this.Instrument?.LotSize;
    }

    public getAmount (): number {
        return this.Amount;
    }

    // #region Misc

    public Dispose (): void {
        this.Instrument = null;
        this.Account = null;
    }

    // #endregion Misc
}
