// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
class _TvNumberParser {
    private readonly grouRegExp: RegExp;
    private readonly decimalRegExp: RegExp;
    constructor () {
        const formatter = new Intl.NumberFormat(undefined);
        // Получаем опции, используемые форматтером
        const parts = formatter.formatToParts(12345.6);
        let groupSeparator, decimalSeparator;
        for (const part of parts) {
            if (part.type === 'group') {
                groupSeparator = part.value;
            }
            if (part.type === 'decimal') {
                decimalSeparator = part.value;
            }
        }

        this.grouRegExp = new RegExp('\\' + groupSeparator, 'g');
        this.decimalRegExp = new RegExp('\\' + decimalSeparator);
    }

    public parse (value: string) {
        const normalizedStr = value.replace(this.grouRegExp, '').replace(this.decimalRegExp, '.');
        return parseFloat(normalizedStr);
    }
}

export const TvNumberParser = new _TvNumberParser();
