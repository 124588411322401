// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type EntitlmentProductDefinition } from '../../Commons/cache/Entitlement/EntitlmentProductDefinition';
import { type ProductType } from '../Instruments/ProductType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectEntitlementProductMessage extends DirectMessageBaseClass {
    public Id = -1;
    public Name: string | null = null;
    public Description: string | null = null;
    public Category: string | null = null;
    public CurrencyId: number | null = null;
    public ProPrice: number | null = null;
    public NonProPrice: number | null = null;
    public HasDocument: boolean | null = null;
    public ImageBytes: any[] | null = null;
    public ProductDefinitions: EntitlmentProductDefinition[] | null = null;
    public IsUnsubscribeAllowed: boolean | null = null;
    public IsActive: boolean | null = null;
    public ProductDataType: ProductType | null = null;
    public ShowInSettings: boolean | null = null;
    public ColorHEX: string | null = null;
    public QuoteDelay: number = 0;

    constructor () {
        super(Message.CODE_PFIX_PRODUCT_MESSAGE);
    }
}
