// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Pen, Color, PenStyle } from '@shared/commons/Graphics';
import { PlatePTLIndicator } from '../../Tools/PlateIndicator';
import { TerceraChartBaseIndicatorRenderer } from './TerceraChartBaseIndicatorRenderer';
import { DynProperty } from '@shared/commons/DynProperty';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { PriceFormatter } from '@shared/utils/Instruments/PriceFormatter';
import { type Indicator } from '@shared/commons/cache/indicators/Indicator';
import { type TerceraChart } from '../../TerceraChart';

export class TerceraChartIndicatorRenderer extends TerceraChartBaseIndicatorRenderer {
    private readonly PTLIndicator: Indicator;

    constructor (indicatorModule: Indicator, chart: TerceraChart) {
        super(chart, indicatorModule.SeparateWindow);

        this.PTLIndicator = indicatorModule;
        // this.LoadSchemeMyProperties();
        this.SetClassName('TerceraChartIndicatorRenderer');
    }

    public get Indicator (): Indicator {
        return this.PTLIndicator;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        if (!this.Visible) { return; }
        if (this.PTLIndicator == null) { return; }
        const p = advParams;
        if (p == null || window.ClientRectangle.Height < 2) // не рисуем на сжатых окнах вообще
        { return; }
        const clientRect = window.ClientRectangle;
        gr.save();
        gr.beginPath();
        gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip();
        const cashItemSeries = p.mainPriceRenderer.Series;
        const converter = this.WindowsNumber == 0 ? p.TerceraChart.cashItemSeriesSettings.ActiveConverter : null;
        const XScaleCoefMultiply = window.XScale;
        for (let i = 0; i < this.PTLIndicator.CloudBuffer.Count; i++) {
            // #region// рисовалка облаков
            const cloudVector = this.PTLIndicator.CloudBuffer.Get(i);
            const timeShift = this.PTLIndicator.TimeShift[i] || 0;
            // Определяем метод градиента
            const firstLineColor = cloudVector.FirstLineColor;
            const secondLineColor = cloudVector.SecondLineColor;
            let color1 = Color.Transparent;
            let color2 = Color.Transparent;
            // Определяем цвет метод заливки облака, если оба цвета установлены в 0, то заливки нет
            const gradiendMethod = cloudVector.GradientMethod;
            if (firstLineColor != 0 && secondLineColor != 0) {
                color1 = firstLineColor;
                color2 = secondLineColor;
            }
            const from = Math.floor(window.i1);
            const to = Math.floor(window.i1 - (window.ClientRectangle.Width / XScaleCoefMultiply));
            let curX = (window.ClientRectangle.X + window.ClientRectangle.Width) - XScaleCoefMultiply;
            const screenData = cashItemSeries.ChartScreenData.Storage;
            if (screenData.length > 0) {
                const lastScreenDate = screenData[screenData.length - 1].Time;
                curX = window.PointsConverter.GetScreenXbyTime(lastScreenDate);
            }
            const middle = XScaleCoefMultiply / 2;
            for (let j = from; j >= to; j--) {
                curX -= XScaleCoefMultiply;
                const i = cashItemSeries.GetIndex(j - timeShift) + timeShift;
                const CurVal0 = cloudVector.Get(0).get(i); // (resData[cc][i]); // max, max-1, max-2, ..
                const CurVal1 = cloudVector.Get(0).get(i - 1); // (resData[cc][i - 1]); // max-1, max-2, ..
                const CurVal0_ = cloudVector.Get(1).get(i); // (resData[3][i]); // max, max-1, max-2, ..
                const CurVal1_ = cloudVector.Get(1).get(i - 1); // (resData[3][i - 1]); // max-1, max-2, ..
                if ((CurVal0 <= 0) || Math.abs(CurVal0) >= Number.MAX_VALUE) { continue; }
                if ((CurVal1 <= 0) || Math.abs(CurVal1) >= Number.MAX_VALUE) { continue; }
                if ((CurVal0_ <= 0) || Math.abs(CurVal0_) >= Number.MAX_VALUE) { continue; }
                if ((CurVal1_ <= 0) || Math.abs(CurVal1_) >= Number.MAX_VALUE) { continue; }
                // Support relative/Log scales
                let CurVal0Converted, CurVal1Converted, CurVal0_Converted, CurVal1_Converted;
                if (converter != null) {
                    CurVal0Converted = converter.Calculate(CurVal0);
                    CurVal1Converted = converter.Calculate(CurVal1);
                    CurVal0_Converted = converter.Calculate(CurVal0_);
                    CurVal1_Converted = converter.Calculate(CurVal1_);
                } else {
                    CurVal0Converted = CurVal0;
                    CurVal1Converted = CurVal1;
                    CurVal0_Converted = CurVal0_;
                    CurVal1_Converted = CurVal1_;
                }
                const xx0 = curX + middle + XScaleCoefMultiply;
                const yy0 = window.PointsConverter.GetScreenY(CurVal0Converted);
                const xx1 = curX + middle;
                const yy1 = window.PointsConverter.GetScreenY(CurVal1Converted);
                // Если есть заливка, то рисуем ее
                if (gradiendMethod > 0 && firstLineColor != 0 && secondLineColor != 0) {
                    if ((CurVal0 != 0) && (CurVal1 != 0) && (CurVal0_ != 0) && (CurVal1_ != 0)) {
                        const yy0_ = window.PointsConverter.GetScreenY(CurVal0_Converted);
                        const yy1_ = window.PointsConverter.GetScreenY(CurVal1_Converted);
                        this.PaintCloud(gr, xx0, xx1, yy0, yy1, yy0_, yy1_, color1, color2, gradiendMethod);
                    }
                }
            }
            // #endregion
        }
        for (let i = 0; i < this.PTLIndicator.LinesCount; i++) {
            if (!this.PTLIndicator.LineVisible[i]) { continue; }
            const vector = this.PTLIndicator.getLineData(i);
            const timeShift = this.PTLIndicator.TimeShift[i] || 0;
            const drawBegin = this.PTLIndicator.DrawBegin[i] || 0; ;
            const csNum = this.PTLIndicator.LineStyles[i];
            const emptyValue = this.PTLIndicator.EmptyValue[i];
            if (csNum === PenStyle.HistogrammChart) {
                // #region // График - гистограмма
                const barWidth = Math.floor(XScaleCoefMultiply);
                // настраиваем кисть рисования
                const color = this.Selected ? ThemeManager.CurrentTheme.Chart_IndicatorLineHover : this.PTLIndicator.LineColors[i];
                const width = Math.min(barWidth, this.PTLIndicator.LineWidth[i] + (this.Selected ? 1 : 0));
                const indP = new Pen(color, width);
                // let histogrammStyle = this.PTLIndicator.HistogrammStyles[i];
                // if (histogrammStyle >= 0)
                //    Pen.ProcessPen(indP, histogrammStyle);
                const markerVector = this.PTLIndicator.LineMarkers[i];
                // кешируем расчетные значения
                const from = Math.floor(window.i1);
                const to = Math.floor(window.i1 - window.im());
                let curX = window.ClientRectangle.X + window.ClientRectangle.Width - XScaleCoefMultiply;
                const screenData = cashItemSeries.ChartScreenData.Storage;
                if (screenData.length > 0) {
                    const lastScreenDate = screenData[screenData.length - 1].Time;
                    curX = window.PointsConverter.GetScreenXbyTime(lastScreenDate);
                }
                const middle = XScaleCoefMultiply / 2;
                for (let j = from; j >= to; j--) {
                    let linecolor = indP;
                    const barMIddle = Math.floor(curX + middle);
                    const index = cashItemSeries.GetIndex(j - timeShift);
                    let value = vector.get(index);
                    if (index < drawBegin || (value == 0 && this.WindowsNumber == 0) || Math.abs(value) == Number.MAX_VALUE || value == emptyValue) {
                        curX -= XScaleCoefMultiply;
                        continue;
                    }
                    let lowValue: any = { value: 0 };
                    if (!this.DrawMt4Histogram(i, index, lowValue)) {
                        curX -= XScaleCoefMultiply;
                        continue;
                    }
                    lowValue = lowValue.value;
                    // Support relative/Log scales
                    if (converter != null) {
                        value = converter.Calculate(value);
                        lowValue = converter.Calculate(lowValue);
                    }
                    const valueY = Math.floor(window.PointsConverter.GetScreenY(value));
                    const lowValueY = Math.floor(window.PointsConverter.GetScreenY(lowValue));
                    if (j >= 0 && markerVector !== null && j < markerVector.Count) {
                        const curHistColor = markerVector.getColor(j);
                        if (curHistColor) { linecolor = new Pen(curHistColor, width); }
                    }
                    // if (histogrammStyle >= 0)
                    // Pen.ProcessPen(indP, histogrammStyle);
                    gr.DrawLine(linecolor, barMIddle, valueY, barMIddle, lowValueY);
                    curX -= XScaleCoefMultiply;
                }
                if (this.PTLIndicator.LinePlateVisible[i]) { this.DrawPlate(window, p, vector, this.PTLIndicator.LineColors[i]); }
                // #endregion
            }
            // else if (csNum == Pen.csArrowChart)
            // {
            //     //#region // график из символов-стрелок
            //     //создаю одну тулзу на линию
            //     let arrowTool = new ArrowDataCacheTool();
            //     arrowTool.ArrowCode = PTLIndicator.SymbolCode[i];
            //     arrowTool.Color = Color.FromArgb(PTLIndicator.LineColors[i]);
            //     let toolWidth = PTLIndicator.LineWidth[i];
            //     if (toolWidth > 0)
            //         arrowTool.Size += 3 * (toolWidth - 1);
            //     let arrow = new ArrowToolView(arrowTool);
            //     let from = Math.Min(window.i1, cashItemSeries.Count + timeShift - 1); //window.i1;
            //     let to = (let)(window.i1 - window.im);
            //     double middle = window.XScale / 2;
            //     for (let j = from; j >= to; j--)
            //     {
            //         let index = cashItemSeries.GetIndex(j - timeShift);
            //         if (index >= drawBegin && index != -1)
            //         {
            //             double value = vector.get(index);
            //             if (index >= 0 && (value > 0 || WindowsNumber == 0) && Math.Abs(value) < Int32.MaxValue && value != emptyValue)
            //             {
            //                 // Support relative/Log scales
            //                 if (converter != null)
            //                     value = converter.Calculate(value);
            //                 //рисую тулзу в очередной точке
            //                 arrowTool.Points[0][0] = cashItemSeries.GetValue(j, 0) + middle;
            //                 arrowTool.Points[0][1] = value;
            //                 arrow.UpdateScreenPoints(window);
            //                 arrow.Draw(gr, window, p);
            //             }
            //         }
            //     }
            //     //#endregion
            // }
            else if (csNum == PenStyle.HorisontalLineChart || csNum == PenStyle.Ladder) {
                let width = this.PTLIndicator.LineWidth[i];
                if (this.Selected) { width += 2; }
                const color = this.Selected ? ThemeManager.CurrentTheme.Chart_IndicatorLineHover : this.PTLIndicator.LineColors[i];
                const indP = new Pen(color, width);
                const barWidth = Math.floor(XScaleCoefMultiply);
                const from = Math.floor(window.i1);
                const to = Math.floor(window.i1 - window.im());
                let curX = window.ClientRectangle.X + window.ClientRectangle.Width - XScaleCoefMultiply;
                const screenData = cashItemSeries.ChartScreenData.Storage;
                if (screenData.length > 0) {
                    const lastScreenDate = screenData[screenData.length - 1].Time;
                    curX = window.PointsConverter.GetScreenXbyTime(lastScreenDate);
                }
                for (let j = from; j >= to; j--) {
                    const index = cashItemSeries.GetIndex(j - timeShift);
                    let value = vector.get(index);
                    if (index < drawBegin || (value == 0 && this.WindowsNumber == 0) || Math.abs(value) == Number.MAX_VALUE || value == emptyValue) {
                        curX -= XScaleCoefMultiply;
                        continue;
                    }
                    // Support relative/Log scales
                    if (converter != null) { value = converter.Calculate(value); }
                    const valueY = Math.floor(window.PointsConverter.GetScreenY(value));
                    gr.DrawLine(indP, Math.floor(curX), valueY, Math.floor(curX + barWidth), valueY);
                    if (csNum === PenStyle.Ladder) // дорисовываем вертикальные линии, чтобы получились ступеньки
                    {
                        const prevIndex = cashItemSeries.GetIndex(j - timeShift - 1);
                        let prevValue = vector.get(prevIndex);
                        const noValue = prevIndex < drawBegin || (prevValue == 0 && this.WindowsNumber == 0) || Math.abs(prevValue) == Number.MAX_VALUE || prevValue == emptyValue;
                        if (!noValue) {
                            if (converter != null) { prevValue = converter.Calculate(prevValue); }
                            const prevValueY = Math.floor(window.PointsConverter.GetScreenY(prevValue));
                            gr.DrawLine(indP, Math.floor(curX), prevValueY, Math.floor(curX), valueY);
                        }
                    }
                    curX -= XScaleCoefMultiply;
                }
                if (this.PTLIndicator.LinePlateVisible[i]) { this.DrawPlate(window, p, vector, this.PTLIndicator.LineColors[i]); }
            } else {
                // let mode = gr.SmoothingMode;
                // gr.SmoothingMode = System.Drawing.Drawing2D.SmoothingMode.HighQuality;
                // Графики различного стиля
                super.paintStyledWidthLineNew(gr, vector, this.Selected, window, this.WindowsNumber != 0, cashItemSeries,
                    drawBegin, emptyValue, this.PTLIndicator.LineWidth[i], this.PTLIndicator.LineColors[i], csNum, false, false, this.PTLIndicator.LineMarkers[i] || null, converter);
                // gr.SmoothingMode = mode;
                if (this.PTLIndicator.LinePlateVisible[i]) { this.DrawPlate(window, p, vector, this.PTLIndicator.LineColors[i]); }
            }
        }
        // Reset clip.
        gr.restore();
    }

    FormatPlateText (value: any, advParamsObj: any, seriesDataType: any, scaleRenderer: any): string {
        if (this.PTLIndicator.Digits < 0 || this.PTLIndicator.Digits > 8) {
            return super.FormatPlateText(value, advParamsObj, seriesDataType, scaleRenderer);
        } else {
            return PriceFormatter.formatPrice(value, this.PTLIndicator.Digits);
        }
    }

    FindMinMax (minMaxObj: any, window: any) {
        minMaxObj.tMin = Number.MAX_VALUE;
        minMaxObj.tMax = -Number.MAX_VALUE;

        if (
            this.PTLIndicator == null ||
            this.PTLIndicator.LinesCount === 0 ||
            this.PTLIndicator.getLineData(0) == null ||
            !this.Visible
        ) {
            return false;
        }

        const parentSeries = this.Series;
        if (parentSeries?.settings == null) {
            return false;
        }

        const from = Math.floor(window.i1);
        const to = Math.floor(window.i1 - window.im());

        let paintThisInidcator = false;

        const activeConverter =
            this.WindowsNumber === 0
                ? parentSeries.settings.ActiveConverter
                : null;

        for (let j = 0; j < this.PTLIndicator.LinesCount; j++) {
            // невидимая линия - го дальше
            if (!this.PTLIndicator.LineVisible[j]) { continue; }

            const emptyValue = this.PTLIndicator.EmptyValue[j];
            const drawBegin = this.PTLIndicator.DrawBegin[j];

            const vector = this.PTLIndicator.getLineData(j);

            for (let i = from; i >= to; i--) {
                const timeShift = this.PTLIndicator.TimeShift[i] || 0;
                let value: number = emptyValue;
                const index = parentSeries.GetIndex(i - timeShift);
                if (index == -1) { continue; }

                if (index > -1) { value = vector.get(index); }

                // проверки значения индикатора
                if ((value != 0 || this.PTLIndicator.SeparateWindow) && value != emptyValue && index >= drawBegin /* && !double.IsInfinity(value) && !double.IsNaN(value) && Math.Abs(value) != let.MaxValue */) {
                    if (activeConverter != null) { value = activeConverter.Calculate(value); }

                    paintThisInidcator = true;
                    if (value < minMaxObj.tMin) { minMaxObj.tMin = value; }
                    if (value > minMaxObj.tMax) { minMaxObj.tMax = value; }
                }
            }
        }

        return paintThisInidcator;
    }

    WindowFind (sender: any, name: any, winNum: any) {
        if (this.PTLIndicator == null || this.PTLIndicator.ShortName() !== name) {
            return false;
        }

        winNum = this.WindowsNumber;

        return this.PTLIndicator === sender;
    }

    PlateFactory () {
        return new PlatePTLIndicator(this);
    }

    AllowDublicate () {
        return true;
    }

    /**
       * Рисует гистограмму в соответствии с MT4-правилом.
       *
       * @param i Индекс линии.
       * @param index Индекс точки в линии.
       * @param priceObj Объект, в который будет записана цена.
       * @returns `true`, если линия должна быть нарисована, `false` - иначе.
       */
    public DrawMt4Histogram (i: number, index: number, priceObj: any): boolean {
        // Алгоритм:
        // Проверяем, если соответствующая вторая линия - тоже гистограмма,
        // включаем MT4 правило (см. описание функции)

        priceObj.value = 0;
        if (this.WindowsNumber !== 0) {
            // Для дополнительных окон это правило не действует!!!
            return true;
        }

        const j = (i % 2 === 0) ? i + 1 : i - 1;
        if (j < 0 || j >= this.PTLIndicator.LinesCount) {
            return true;
        }

        // if (csNum[j] !== Pen.csHistogrammChart) // гистограмму не обязат ограничивать другой гистограммой, как оказалось (MA_With_Band.mq4, #23190)
        //     return true;

        const curValue = this.PTLIndicator.getLineData(i)[index];
        const newValue = this.PTLIndicator.getLineData(j)[index];

        // Если текущая линия больше контр-линии, рисуем ее.
        // Иначе выходим: если контр-линия больше, она отрисуется в своем цикле.
        // Если линия = 0, или совпадает с текущей, MT4 ее не рисует
        if (curValue > newValue && newValue !== 0) {
            priceObj.value = newValue;
            return true;
        }

        // else if (/*curValue == newValue ||*/ newValue == this.PTLIndicator.EmptyValue[j]) //<- коммент, а то мкл4 ишимоку не рисуется!
        // {
        //     priceObj.value = 0;
        //     return true;
        // }

        return false;
    }

    PaintCloud (
        gr: any,
        x0: any,
        x1: any,
        y0: any,
        y1: any,
        y2: any,
        y3: any,
        color1: any,
        color2: any,
        gradienMethod: any
    ) {
        let startColor;
        let stopColor;
        const step: any = 4;
        if ((parseInt(y0, 10) == parseInt(y1, 10)) && (parseInt(y2, 10) == parseInt(y3, 10)) && (parseInt(x0, 10) != parseInt((x1 + 1), 10))) {
            if (gradienMethod == 1) {
                // simple
                for (let xxx = parseInt(x0, 10); xxx > x1; xxx--) {
                    const yyy_c2 = parseInt(y1, 10);
                    const yyy_c3 = parseInt(y3, 10);
                    if (yyy_c2 < yyy_c3) {
                        startColor = color1;
                        stopColor = color2;
                    } else {
                        startColor = color2;
                        stopColor = color1;
                    }
                    const stP = new Pen(startColor);
                    gr.DrawLine(stP, xxx, yyy_c2, xxx, yyy_c3);
                }
            } else {
                const yyy_c2 = parseInt(y1, 10);
                const yyy_c3 = parseInt(y3, 10);
                if (yyy_c2 < yyy_c3) {
                    startColor = color1;
                    stopColor = color2;
                } else {
                    startColor = color2;
                    stopColor = color1;
                }
                let stP = new Pen(startColor);
                const rr0 = parseInt(startColor.R, 10);
                const gg0 = parseInt(startColor.G, 10);
                const bb0 = parseInt(startColor.B, 10);
                const rr1 = parseInt(stopColor.R, 10);
                const gg1 = parseInt(stopColor.G, 10);
                const bb1 = parseInt(stopColor.B, 10);
                let r0;
                let b0;
                let g0;
                const height = Math.abs(yyy_c2 - yyy_c3);
                const rk = (rr1 - rr0) / (height);
                const gk = (gg1 - gg0) / (height);
                const bk = (bb1 - bb0) / (height);
                let i = 0;
                const offset = Math.min(yyy_c2, yyy_c3);
                const xx0 = parseInt(x0, 10);
                const xx1 = parseInt(x1 + 1, 10);
                for (let ii = 1; ii < height; ii += step) {
                    i = ii + offset;
                    r0 = rr0 + parseInt((ii * rk) + '', 10);
                    g0 = gg0 + parseInt((ii * gk) + '', 10);
                    b0 = bb0 + parseInt((ii * bk) + '', 10);
                    stP = new Pen(Color.FromArgb(128, `${r0}, ${g0}, ${b0}`));
                    let hi = i + step - 1;
                    if (step == 1) {
                        hi = i + 1;
                    }
                    if (hi > (offset + height)) { hi = height + offset; }
                    for (let iii = i; iii <= hi; iii++) { gr.DrawLine(stP, xx0, iii, xx1, iii); }
                }
            }
        } else {
            for (let xxx = parseInt(x0, 10); xxx > x1; xxx--) {
                const yyy_c2 = parseInt((y1 + (xxx - x1) * (y0 - y1) / (x0 - x1)), 10);
                const yyy_c3 = parseInt((y3 + (xxx - x1) * (y2 - y3) / (x0 - x1)), 10);
                if (yyy_c2 < yyy_c3) {
                    startColor = color1;
                    stopColor = color2;
                } else {
                    startColor = color2;
                    stopColor = color1;
                }
                let stP = new Pen(startColor);
                if (gradienMethod == 1) {
                    gr.DrawLine(stP, xxx, yyy_c2, xxx, yyy_c3);
                } else if (yyy_c2 == yyy_c3) {
                    gr.DrawLine(stP, xxx, yyy_c2, xxx + 1, yyy_c3);
                } else {
                    const rr0: any = parseInt(startColor.R, 10);
                    const gg0: any = parseInt(startColor.G, 10);
                    const bb0: any = parseInt(startColor.B, 10);
                    const rr1: any = parseInt(stopColor.R, 10);
                    const gg1: any = parseInt(stopColor.G, 10);
                    const bb1: any = parseInt(stopColor.B, 10);
                    let r0: any;
                    let b0: any;
                    let g0: any;
                    const height: any = Math.abs(yyy_c2 - yyy_c3);
                    const rk: any = (rr1 - rr0) / (height);
                    const gk: any = (gg1 - gg0) / (height);
                    const bk: any = (bb1 - bb0) / (height);
                    let i: any = 0;
                    const offset = Math.min(yyy_c2, yyy_c3);
                    for (let ii: any = 1; ii < height; ii += step) {
                        i = ii + offset;
                        r0 = rr0 + parseInt((ii * rk) + '', 10);
                        g0 = gg0 + parseInt((ii * gk) + '', 10);
                        b0 = bb0 + parseInt((ii * bk) + '', 10);
                        stP = new Pen(Color.FromArgb(128, `${r0}, ${g0}, ${b0}`));
                        let hi = i + step - 1;
                        if (step == 1) {
                            hi = i + 1;
                        }
                        if (hi > (offset + height)) { hi = height + offset; }
                        gr.DrawLine(stP, xxx, i, xxx, hi);
                    }
                }
            }
        }
    }

    // ICaller functions...
    Properties () {
        const props = super.Properties();
        let showTop = true;

        if (this.PTLIndicator != null) {
            props.push(...this.PTLIndicator.Properties());
            showTop = this.PTLIndicator.ShowOnTop();
        }

        const dynPropWinNum = new DynProperty(
            'Win num',
            this.WindowsNumber,
            DynProperty.CHART_WINDOW,
            DynProperty.SCRIPT_PARAMETERS_GROUP
        );
        dynPropWinNum.minimalValue = 1;
        dynPropWinNum.maximalValue = this.chart.windowsContainer.Windows.length;
        dynPropWinNum.sortIndex = 100000;
        props.push(dynPropWinNum);

        const dynPropIsVisible = new DynProperty(
            'IsVisible',
            this.Visible,
            DynProperty.BOOLEAN,
            DynProperty.HIDDEN_GROUP
        );
        dynPropIsVisible.sortIndex = 100000;
        props.push(dynPropIsVisible);

        if (showTop) {
            const dynPropOnTop = new DynProperty(
                'OnTop',
                this.OnTop,
                DynProperty.BOOLEAN,
                DynProperty.SCRIPT_PARAMETERS_GROUP
            );
            dynPropOnTop.sortIndex = -100000;
            props.push(dynPropOnTop);
        }

        return props;
    }

    callBack (props: any) {
        const dppWinNum = DynProperty.getPropertyByName(props, 'Win num');
        if (dppWinNum != null) {
            this.WindowsNumber = dppWinNum.value;
        }

        const dppIsVisible = DynProperty.getPropertyByName(props, 'IsVisible');
        if (dppIsVisible != null) {
            this.Visible = dppIsVisible.value;
        }

        const dppOnTop = DynProperty.getPropertyByName(props, 'OnTop');
        if (dppOnTop != null) {
            this.OnTop = dppOnTop.value;
        }

        if (this.PTLIndicator != null) {
            this.PTLIndicator.callBack(props);
        }

        super.callBack(props);
    }

    public getMinBarsCount (): number {
        return this.PTLIndicator.getMinBarsCount();
    }
}
