// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class ExternalScreenStorage {
    public _storage: any = {};
    public _storageByID: any = {};
    public screensDisabled: boolean = false;

    public generateKey (name, tb): string {
        return name + '+++' + tb;
    }

    public Clear (): void {
        this._storage = {};
        this._storageByID = {};
    }

    public GetToolData (id) {
        const it = this._storageByID[id];
        if (!it) {
            return null;
        }

        const tool = it.tool;

        return { Tool: tool, Text: tool.urlStr, ScreenName: tool.name, Aliases: tool.aliases, ClientTab: tool.clientTab, ClientUseInternalBrowser: tool.clientUseInternalBrowser };
    }

    public GetTool (name, clientTab): any {
        const key = this.generateKey(name, clientTab);
        const it = this._storage[key];
        if (!it) {
            return null;
        }

        const tool = it.tool;

        return tool;
    }

    public AddItem (tool): void {
        const toolById = this._storageByID[tool.id];
        const key = this.generateKey(tool.name, tool.clientTab);

        if (!toolById) {
            const it: any = {};
            it.scrArray = [];
            it.url = tool.urlStr;
            it.tool = tool;

            this._storage[key] = this._storageByID[tool.id] = it;
            return;
        }

        this.UpdateLinkItem(tool);
    }

    public AddScreenItem (tool, scr): void {
        const toolById = this._storageByID[tool.id];
        if (!toolById) {
            this.AddItem(tool);
        }

        toolById.scrArray.push(scr);
    }

    public UpdateLinkItem (tool): void {
        const it = this._storageByID[tool.id];
        if (!it) {
            return;
        }

        const arr = it.scrArray;
        const newarr = [];
        arr.map(function (scr) {
            if (!scr.torndown) {
                scr.UpdateLink(tool.urlStr, tool.IsNoneLink(), tool);
                newarr.push(scr);
            }
        });

        it.scrArray = newarr;
        it.url = tool.urlStr;
        it.tool = tool;
    }

    public DeleteItem (link): void {
        const key = this.generateKey(link.name, link.clientTab);
        const item = this._storageByID[link.id];

        if (item) {
            const scrArr = item.scrArray;
            for (let i = 0; i < scrArr.length; i++) {
                const scr = scrArr[i];
                scr.close();
                scr.PanelContainer?.onCloseButtonClicked();
            }

            item.scrArray = [];

            delete this._storageByID[link.id];
            if (this._storage[key]) {
                delete this._storage[key];
            }
        }
    }

    public DisableAllScreens (): void {
        if (this.screensDisabled) { return; }
        this.changeAllScreensPointerEvents(true);
    }

    public EnableAllScreens (): void {
        if (!this.screensDisabled) { return; }
        this.changeAllScreensPointerEvents(false);
    }

    public changeAllScreensPointerEvents (disabling: boolean): void {
        const toolsById = Object.values(this._storageByID);
        toolsById.forEach((t: any) => {
            const scrArr = t.scrArray;
            if (scrArr != null) {
                scrArr.forEach((scr) => {
                    if (scr.rendered === true) {
                        const iframe = scr.find('iframe');
                        if (iframe != null) {
                            iframe.style.pointerEvents = disabling ? 'none' : 'all';
                        }
                    }
                });
            }
        });
        this.screensDisabled = disabling;
    }
}
