// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TokenVerifyRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_TOKEN_VERIFY_REQUEST, fieldSet);
    }

    public SetUserSessionId (accessToken: string): void {
        this.setFieldValue(FieldsFactory.FIELD_ACCESS_TOKEN, accessToken);
    }
}
