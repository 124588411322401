// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { WDSettingsUtils } from '@front/controls/UtilsClasses/WDGeneralSettingsUtils';
import { Level1ItemType } from '@shared/utils/Enums/Level1ItemType';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { type Account } from '../cache/Account';
import { type Instrument } from '../cache/Instrument';
import { ColoringEnum, Level1CalculatorUtils } from '../cache/Level1Utils/Level1CalculatorUtils';
import { Level1CalculatorViewModelHelper } from '../cache/Level1Utils/Level1CalculatorViewModelHelper';
import { Resources } from '@shared/localizations/Resources';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { DateTimeConvertor } from '@shared/utils/Time/DateTimeConvertor';

export class Level1PanelWrapper {
    private instrument: Instrument;
    private account: Account;
    private readonly strNA: string;

    constructor (instrument: Instrument, account: Account) {
        this.instrument = instrument;
        this.account = account;
        this.strNA = Resources.getResource('general.N_A');
    }

    public setInstrument (newInstrument: Instrument): void {
        this.instrument = newInstrument;
    }

    public setAccount (newAccount: Account): void {
        this.account = newAccount;
    }

    public getLevel1ItemLabel (type: Level1ItemType): string {
        const locKey = this.getLevel1ItemLocKey(type);
        return Resources.getResource(locKey);
    }

    public isLevel1ItemHidden (type: Level1ItemType): boolean {
        const locKey = this.getLevel1ItemLocKey(type);
        return Resources.isHidden(locKey);
    }

    public getLevel1ItemColor (type: Level1ItemType): string {
        if (isNullOrUndefined(this.instrument)) {
            return '';
        }
        const lvl1 = this.instrument.Level1;
        let changeColor: ColoringEnum = null;
        switch (type) {
        case Level1ItemType.Last:
            changeColor = Level1CalculatorUtils.getColorBySign(lvl1.GetChange(this.account));
            break;
        case Level1ItemType.Change:
            changeColor = Level1CalculatorUtils.getColorBySign(lvl1.GetChangePercent(this.account));
            break;
        case Level1ItemType.ChangePercent:
            changeColor = Level1CalculatorUtils.getColorBySign(lvl1.GetChangePercent(this.account));
            break;
        default:
            changeColor = ColoringEnum.Neitral;
            break;
        }

        return Level1CalculatorViewModelHelper.getDesktopColor(changeColor);
    }

    public getLevel1ItemValueStr (type: Level1ItemType): string {
        if (isNullOrUndefined(this.instrument)) {
            return '';
        }
        const lvl1 = this.instrument.Level1;
        const instDayInfo = this.instrument.InstrumentDayInfo;
        const showLots = WDSettingsUtils.displayAmountInLots();

        switch (type) {
        case Level1ItemType.Bid:
            return lvl1.strBid(this.account, false);
        case Level1ItemType.Ask:
            return lvl1.strAsk(this.account, false);
        case Level1ItemType.Last:
            return lvl1.StrLastPrice(this.account);
        case Level1ItemType.Change:
            return lvl1.strChange(this.account);
        case Level1ItemType.ChangePercent:
            return lvl1.strChangePercent(this.account);
        case Level1ItemType.Volume:
            return lvl1.strVolume(showLots);
        case Level1ItemType.OpenInterest:
            return this.instrument.OpenInterest > 0 ? this.instrument.OpenInterest.toString() : this.strNA;
        case Level1ItemType.Open:
            return instDayInfo.strOpen(this.account);
        case Level1ItemType.High:
            return instDayInfo.strHigh(this.account);
        case Level1ItemType.Low:
            return instDayInfo.strLow(this.account);
        case Level1ItemType.PrevClose:
            return lvl1.strPrevClose(this.account);
        case Level1ItemType.LastTradedTime:{
            const value = lvl1.GetLastTradedTime();
            return value !== -1 ? value : this.strNA;
        }
        case Level1ItemType.LastTime:{
            const lastMessageTime: Date = lvl1.getLastMessageTime;
            if (isNullOrUndefined(lastMessageTime)) return this.strNA;
            if (lastMessageTime.getTime() === 0) return this.strNA;
            const value = DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(lastMessageTime);
            return DateTimeUtils.formatDate(value, 'HH:mm:ss');
        }
        case Level1ItemType.Countdown:
            return lvl1.StrNextFundingSettlement();
        case Level1ItemType.Funding:
            return lvl1.GetSpreadedFundingRateValueFormatted(this.account);
        case Level1ItemType.BidSource:{
            const value = lvl1.GetBidSourceName();
            return value !== '-1' ? value : this.strNA;
        }
        case Level1ItemType.AskSource:{
            const value = lvl1.GetAskSourceName();
            return value !== '-1' ? value : this.strNA;
        }
        case Level1ItemType.LastSource:{
            const value = lvl1.GetLastSourceName();
            return value !== '-1' ? value : this.strNA;
        }
        case Level1ItemType.IndicativeAuctionPrice:
            return lvl1.strIndicativeAuctionPrice();
        case Level1ItemType.SettlementPrice:
            return lvl1.strSettlementPrice();
        case Level1ItemType.PrevSettlementPrice:
            return lvl1.strPrevSettlementPrice();
        case Level1ItemType.VWAP:
            return this.strNA;
        case Level1ItemType.ReferencePrice:
            return lvl1.strReferencePrice;
        case Level1ItemType.Spread:
            return lvl1.strSpread(this.account);
        case Level1ItemType.ETB:
            return lvl1.GetETB();
        case Level1ItemType.BidSize:{
            const value = lvl1.getBidSize(showLots);
            return !isNaN(value) ? value.toString() : this.strNA;
        }
        case Level1ItemType.AskSize:{
            const value = lvl1.getAskSize(showLots);
            return !isNaN(value) ? value.toString() : this.strNA;
        }
        case Level1ItemType.LastSize:{
            const value = lvl1.GetLastSize(showLots);
            return !isNaN(value) ? value.toString() : this.strNA;
        }

        case Level1ItemType.CurrentSession:
            return this.instrument.ExchangeSessionName;
        case Level1ItemType.TradingStatus:
            return InstrumentUtils.GetTradingStatus(this.instrument);
        case Level1ItemType.TradedValue:
            return lvl1.strTradedValue;
        case Level1ItemType.OffExchangeVolume:
            return lvl1.strOffExchangeVolume(showLots);
        case Level1ItemType.OffExchangeValue:
            return lvl1.strOffExchangeValue;
        case Level1ItemType.NormalMarketSize:
            return lvl1.getNormalMarketSize;
        case Level1ItemType.LimitHigh:
            return this.instrument.Limits.HighLimitPriceByMeasureString();
        case Level1ItemType.LimitLow:
            return this.instrument.Limits.LowLimitPriceByMeasureString();
        case Level1ItemType.AuctionStart:
            return lvl1.strCbAuctionStart();
        case Level1ItemType.AuctionEnd:
            return lvl1.strAuctionEndTime;
        case Level1ItemType.RemainingQty:
            return lvl1.strRemainingQty;
        case Level1ItemType.RemainingQtySide:
            return lvl1.strRemainingQtySide;
        case Level1ItemType.TotalBuyQty:{
            const value = lvl1.GetTotalBuyQty();
            return !isNaN(value) ? value.toString() : this.strNA;
        }
        case Level1ItemType.TotalSellQty:{
            const value = lvl1.GetTotalSellQty();
            return !isNaN(value) ? value.toString() : this.strNA;
        }
        case Level1ItemType.AvgTradedPrice:{
            const value = lvl1.GetAvgTradedPrice();
            return !isNaN(value) ? value.toString() : this.strNA;
        }
        case Level1ItemType.FiftyTwoWeekHighPrice:{
            const value = lvl1.GetFiftyTwoWeekHighPrice();
            return !isNaN(value) ? value.toString() : this.strNA;
        }
        case Level1ItemType.FiftyTwoWeekLowPrice:{
            const value = lvl1.GetFiftyTwoWeekLowPrice();
            return !isNaN(value) ? value.toString() : this.strNA;
        }
        case Level1ItemType.NSEValue:{
            const value = lvl1.GetNSEValue();
            return !isNaN(value) ? value.toString() : this.strNA;
        }
        case Level1ItemType.DPR:{
            const value = lvl1.GetDPRValue();
            return value !== -1 ? value : this.strNA;
        }
        case Level1ItemType.LastUpdateTime:
            return lvl1.GetLastUpdate();

        default:
            return '';
        }
    }

    private getLevel1ItemLocKey (type: Level1ItemType): string {
        switch (type) {
        case Level1ItemType.Bid:
            return 'panel.detail.bid';
        case Level1ItemType.Ask:
            return 'panel.detail.ask';
        case Level1ItemType.Last:
            return 'panel.detail.last';
        case Level1ItemType.Change:
            return 'panel.detail.change';
        case Level1ItemType.ChangePercent:
            return 'panel.detail.changePr';
        case Level1ItemType.Volume:
            return 'panel.detail.volume';
        case Level1ItemType.OpenInterest:
            return 'panel.detail.OpenInterest';
        case Level1ItemType.Open:
            return 'panel.detail.open';
        case Level1ItemType.High:
            return 'panel.detail.high';
        case Level1ItemType.Low:
            return 'panel.detail.low';
        case Level1ItemType.PrevClose:
            return 'panel.detail.prevClose';
        case Level1ItemType.LastTradedTime:
            return 'panel.detail.LastTradedTime';
        case Level1ItemType.LastTime:
            return 'panel.detail.LastTime';
        case Level1ItemType.Countdown:
            return 'panel.detail.Countdown';
        case Level1ItemType.Funding:
            return 'panel.detail.Funding';
        case Level1ItemType.BidSource:
            return 'panel.detail.BidSource';
        case Level1ItemType.AskSource:
            return 'panel.detail.AskSource';
        case Level1ItemType.LastSource:
            return 'panel.detail.LastSource';
        case Level1ItemType.IndicativeAuctionPrice:
            return 'panel.detail.IndicativeAuctionPrice';
        case Level1ItemType.SettlementPrice:
            return 'panel.detail.SettlementPrice';
        case Level1ItemType.PrevSettlementPrice:
            return 'panel.detail.PrevSettlementPrice';
        case Level1ItemType.VWAP:
            return 'panel.detail.VWAP';
        case Level1ItemType.ReferencePrice:
            return 'panel.detail.ReferencePrice';
        case Level1ItemType.Spread:
            return 'panel.detail.Spread';
        case Level1ItemType.ETB:
            return 'panel.detail.ETB';
        case Level1ItemType.BidSize:
            return 'panel.detail.BidSize';
        case Level1ItemType.AskSize:
            return 'panel.detail.AskSize';
        case Level1ItemType.LastSize:
            return 'panel.detail.LastSize';

        case Level1ItemType.CurrentSession:
            return 'panel.detail.CurrentSession';
        case Level1ItemType.TradingStatus:
            return 'panel.detail.TradingStatus';
        case Level1ItemType.TradedValue:
            return 'panel.detail.TradedValue';
        case Level1ItemType.OffExchangeVolume:
            return 'panel.detail.OffExchangeVolume';
        case Level1ItemType.OffExchangeValue:
            return 'panel.detail.OffExchangeValue';
        case Level1ItemType.NormalMarketSize:
            return 'panel.detail.NormalMarketSize';
        case Level1ItemType.LimitHigh:
            return 'panel.detail.Limit.High';
        case Level1ItemType.LimitLow:
            return 'panel.detail.Limit.Low';
        case Level1ItemType.AuctionStart:
            return 'panel.detail.CBAuctionStart';
        case Level1ItemType.AuctionEnd:
            return 'panel.detail.AuctionEndTime';
        case Level1ItemType.RemainingQty:
            return 'panel.detail.RemainingQty';
        case Level1ItemType.RemainingQtySide:
            return 'panel.detail.RemainingQtySide';
        case Level1ItemType.TotalBuyQty:
            return 'panel.detail.TotalBuyQty';
        case Level1ItemType.TotalSellQty:
            return 'panel.detail.TotalSellQty';
        case Level1ItemType.AvgTradedPrice:
            return 'panel.detail.AvgTradedPrice';
        case Level1ItemType.FiftyTwoWeekHighPrice:
            return 'panel.detail.FiftyTwoWeekHighPrice';
        case Level1ItemType.FiftyTwoWeekLowPrice:
            return 'panel.detail.FiftyTwoWeekLowPrice';
        case Level1ItemType.NSEValue:
            return 'panel.detail.NSEValue';
        case Level1ItemType.DPR:
            return 'panel.detail.DPR';
        case Level1ItemType.LastUpdateTime:
            return 'panel.detail.LastUpdateTime';

        default:
            return '';
        }
    }
}
