// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { GradientMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterBoolean } from '../InputParamaterClasses/InputParameterBoolean';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class SUPERTREND extends IndicatorScriptBase {
    public AtrPeriod: number;
    public Digit: number;
    public IsCheckedCloud: boolean;
    public DownTrendColor: any;
    public UpTrendColor: any;

    private _ATR: iBuildInIndicator;
    private _currTrend: number;
    private _prevUP: number;
    private _prevDown: number;
    private _prevTrend: number;
    private _down: number;
    private _up: number;

    constructor () {
        super();
        this.ProjectName = 'SUPER TREND';
        this.Comments = 'SUPERTREND';
        this.SetIndicatorLine('Supertrend line', Color.Blue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.AtrPeriod = 10;
        super.InputParameter(new InputParameterInteger('AtrPeriod', 'Period of ATR', 0, 1, 100));

        this.Digit = 3;
        super.InputParameter(new InputParameterInteger('Digit', 'Digit', 1, 1, 100));

        this.IsCheckedCloud = false;
        super.InputParameter(new InputParameterBoolean('IsCheckedCloud', 'Display clouds', 2));

        this.DownTrendColor = Color.OrangeRed;
        this.UpTrendColor = Color.CadetBlue;
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this._ATR = this.Indicators.iATR(this.CurrentData, this.AtrPeriod);
        return [this._ATR];
    };

    public override GetIndicatorShortName (): string {
        return 'SUPERTREND(' + this.AtrPeriod + ')';
    };

    public override getCustomName (): string {
        return 'SUPERTREND (' + this.ProjectName + ')';
    };

    public NextBar (): void {
        super.NextBar();

        this._prevUP = this._down;
        this._prevDown = this._up;
        this._prevTrend = this._currTrend;
    };

    public OnQuote (): void {
        super.OnQuote();

        const middle = (this.CurrentData.GetPrice(PriceType.High) + this.CurrentData.GetPrice(PriceType.Low)) / 2;
        this._down = middle + (this.Digit * this._ATR.GetValue());
        this._up = middle - (this.Digit * this._ATR.GetValue());

        if (this.CurrentData.GetPrice(PriceType.Close) > this._prevUP) { this._currTrend = 1; } else if (this.CurrentData.GetPrice(PriceType.Close) < this._prevDown) { this._currTrend = -1; } else { this._currTrend = this._prevTrend; }

        if (this._currTrend > 0 && this._up < this._prevDown && this._currTrend <= this._prevTrend) { this._up = this._prevDown; }
        if (this._currTrend < 0 && this._down > this._prevUP && this._currTrend >= this._prevTrend) { this._down = this._prevUP; }

        if (this._currTrend === 1) {
            this.SetValue(0, this._up);
        } else {
            this.SetValue(0, this._down);
        }

        if (this.IsCheckedCloud) {
            this.SetCloud(0, 0, this.GetValue(0, 0), this.CurrentData.GetPrice(PriceType.Open), Color.FromHexToArgb(60, this.DownTrendColor), Color.FromHexToArgb(60, this.UpTrendColor), GradientMode.Simple);
        }
    };
}
