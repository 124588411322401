// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type AlertAction, AlertType, type AlertAfterExecute, type AlertNotification, type AlertCondition, type AlertImportance } from '../../Utils/Alert/AlertConstants';
import { type DataCache } from '../DataCache';
import { type Account } from './Account';
import { type Instrument } from './Instrument';

export class Alert {
    public Account: Account;
    public DataCache: DataCache;
    public Instrument: Instrument;
    public AlertType: AlertType;
    public Condition: AlertCondition;
    public Value: number;
    public Importance: AlertImportance;
    public AfterExecute: AlertAfterExecute;
    public Action: AlertAction;
    public Notification: AlertNotification;
    public Expiration: any;
    public MessageText: any;
    public Active: any;
    public AlertId: any;
    public leverageValue: number;
    public OrderPlaceParametrs: any = null;

    constructor (dataCache, paramObj) {
        this.DataCache = dataCache;

        this.Account = paramObj.Account;
        this.Instrument = paramObj.Instrument;
        this.AlertType = paramObj.AlertType;
        this.Condition = paramObj.Condition;
        this.Value = paramObj.Value;
        this.Importance = paramObj.Importance;
        this.AfterExecute = paramObj.AfterExecute;
        this.Action = paramObj.Action;
        this.Notification = paramObj.Notification;
        this.Expiration = paramObj.Expiration;
        this.MessageText = paramObj.MessageText;

        if (paramObj.OrderPlaceParametrs) {
            this.OrderPlaceParametrs = paramObj.OrderPlaceParametrs;
        }

        this.Active = paramObj.IsActive;
        this.AlertId = paramObj.AlertId;

        this.leverageValue = paramObj.leverageValue || null;
    }

    public CanLocateOnChart (instrument: Instrument): boolean {
        return this.Instrument === instrument && this.Active && this.AlertType !== AlertType.CHANGE && this.AlertType !== AlertType.VOLUME;
    }

    public GetDataToSend (): AlertSendToServerItem {
        const data = new AlertSendToServerItem();
        data.Account = this.Account;
        data.Instrument = this.Instrument;
        data.AlertType = this.AlertType;
        data.Condition = this.Condition;
        data.Value = this.Value;
        data.Importance = this.Importance;
        data.AfterExecute = this.AfterExecute;
        data.Action = this.Action;
        data.Notification = this.Notification;
        data.Expiration = this.Expiration;
        data.MessageText = this.MessageText;
        if (this.OrderPlaceParametrs) {
            data.OrderPlaceParametrs = this.OrderPlaceParametrs.getDataForRequest();
        }

        data.Active = this.Active;
        data.AlertId = this.AlertId;

        if (this.leverageValue) {
            data.leverageValue = this.leverageValue;
        }

        return data;
    }

    public dispose (): void {
        if (this.OrderPlaceParametrs?.dispose) {
            this.OrderPlaceParametrs.dispose();
        }
    }
}

export class AlertSendToServerItem {
    public Instrument: Instrument | null = null;
    public AlertType: AlertType | null = null;
    public Condition: AlertCondition | null = null;
    public Value: number | null = null;
    public Importance: AlertImportance | null = null;
    public AfterExecute: AlertAfterExecute | null = null;
    public Action: AlertAction | null = null;
    public Notification: AlertNotification | null = null;
    public Expiration: any = null;
    public MessageText: any = null;
    public OrderPlaceParametrs: any = null;
    public Active: any = null;
    public AlertId: any = null;
    public Account: Account | null = null;
    public leverageValue: number;
}
