// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Point, Rectangle } from '../../Commons/Geometry';
import { Cursors } from '../../Commons/Cursors';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { MouseButtons } from '../../Controls/UtilsClasses/ControlsUtils';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { TerceraChartAction, TerceraChartActionEnum } from '../TerceraChartAction';
import { TerceraChartPriceScaleRendererMouseState } from '../Renderers/Scales/TerceraChartPriceScaleRenderer';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { PlateOverlay } from '../../Chart/Tools/PlateOverlay';
import { type PlateIndicator } from '../../Chart/Tools/PlateIndicator';
import { type PlateBase } from '../../Chart/Tools/PlateBase';
import { type TerceraChart } from '../TerceraChart';

export class TerceraChartPlateRenderer extends TerceraChartBaseRenderer<TerceraChart> {
    public WinNum: number = -1;
    public ellipsisRectangle: Rectangle = new Rectangle();
    public totalWidth: number = 0;
    public curX: number = 0;
    public lastMouseMovePt: Point = Point.Empty();
    public mouseState: TerceraChartPriceScaleRendererMouseState =
        TerceraChartPriceScaleRendererMouseState.None;

    public readonly plateRenderer: boolean = true;
    public platesIndicator: any[] = [];
    public platesOverlay: any[] = [];
    public plates: any[] = [];
    public mouseDownButtonRect: Rectangle = new Rectangle();
    ellipsisMenuItems: any[];

    constructor (chart: TerceraChart) {
        super(chart);
        this.assignLayer = LayersEnum.CrossHair;
        this.ellipsisMenuItems = [];
        this.SetClassName('TerceraChartPlateRenderer');
    }

    GetPlateOverlay (overlay: any, createIfNotExist: boolean = true): any | null {
        const index = this.findOverlayIndex(overlay);
        if (index === -1) {
            if (createIfNotExist) {
                return new PlateOverlay(overlay);
            } else {
                return null;
            }
        }
        return this.platesOverlay[index];
    }

    findOverlayIndex (overlay: any): number {
        for (let i = 0; i < this.platesOverlay.length; i++) {
            if (this.platesOverlay[i].terceraChartOverlay === overlay) {
                return i;
            }
        }
        return -1;
    }

    GetPlateIndicator (renderer: any, createIfNotExist: boolean = true): any | null {
        const index = this.findIndicatorIndex(renderer);
        if (index === -1) {
            if (createIfNotExist && renderer.Indicator !== null) {
                return renderer.PlateFactory();
            } else {
                return null;
            }
        }
        return this.platesIndicator[index];
    }

    findIndicatorIndex (renderer: any): number {
        for (let i = 0; i < this.platesIndicator.length; i++) {
            if (this.platesIndicator[i].Renderer === renderer) {
                return i;
            }
        }
        return -1;
    }

    Clear (): void {
        for (let i = 0; i < this.platesIndicator.length; i++) {
            this.platesIndicator[i].Dispose();
        }
        this.platesIndicator = [];

        for (let i = 0; i < this.platesOverlay.length; i++) {
            this.platesOverlay[i].Dispose();
        }
        this.platesOverlay = [];

        this.UpdatePlates();
    }

    UpdatePlateIndicators (plates: PlateIndicator[]): void {
        this.platesIndicator = plates;
        this.UpdatePlates();
    }

    UpdatePlateOverlays (plates: PlateOverlay[]): void {
        this.platesOverlay = plates;
        this.UpdatePlates();
    }

    UpdatePlates (): void {
        this.plates = [].concat(this.platesOverlay);
        this.plates = this.plates.concat(this.platesIndicator);
    }

    ResetPlatesSize (): void {
        for (let i = 0; i < this.plates.length; i++) {
            this.plates[i].ResetPlateSize();
        }
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        const param = advParams;
        const cashItemSeries = param.mainPriceRenderer.Series;

        if (cashItemSeries == null || this.IsEmpty()) {
            return;
        }

        this.AnalyzeWidth();
        const lastMouseHoverBarIndex = this.GetMouseHoverBarIndex(window, cashItemSeries);

        const clientRectangle = this.Rectangle;
        const ellipsisRectangle = this.ellipsisRectangle;
        let needResetClip = false;

        if (this.totalWidth > clientRectangle.Width) {
            gr.save();
            gr.beginPath();
            gr.rect(
                this.Rectangle.X,
                this.Rectangle.Y,
                clientRectangle.Width - ellipsisRectangle.Width,
                ellipsisRectangle.Height
            );
            gr.clip();

            needResetClip = true;
        }

        for (let i = 0; i < this.plates.length; i++) {
            try {
                const curCashItemSeries = this.plates[i].isOverlay
                    ? this.plates[i].terceraChartOverlay.mainPriceRenderer.Series
                    : cashItemSeries;

                let realIndexInChachItem = lastMouseHoverBarIndex;
                realIndexInChachItem = this.GetMouseHoverBarIndex(window, curCashItemSeries);

                if (this.plates[i].PlateVisible) {
                    this.plates[i].Draw(
                        gr,
                        window,
                        curCashItemSeries,
                        realIndexInChachItem,
                        this.mouseDownButtonRect,
                        this.lastMouseMovePt
                    );
                }
            } catch (ex) {
                ErrorInformationStorage.GetException(ex);
            }
        }

        if (needResetClip) gr.restore();

        if (this.totalWidth > this.Rectangle.Width) {
            const coordinates = param.CursorPosition;
            let imgEllipsisRect = ThemeManager.CurrentTheme.ellipsisDef; // PlateBase.ellipsisDef;

            if (coordinates) {
                if (ellipsisRectangle.Contains(coordinates.X, coordinates.Y)) {
                    imgEllipsisRect = ThemeManager.CurrentTheme.ellipsisHover; // PlateBase.ellipsisHover;
                }
            }

            if (imgEllipsisRect) gr.drawImage(imgEllipsisRect, ellipsisRectangle.X, ellipsisRectangle.Y);
        }
    }

    GetMouseHoverBarIndex (window: any, cashItemSeries: any): number {
        if (cashItemSeries == null) {
            return -1;
        }

        if (this.lastMouseMovePt.IsEmpty()) {
            return cashItemSeries.CashItem.FNonEmptyCashArray.length - 1; // last bar
        }

        const ticks = Math.floor(window.PointsConverter.GetDataX(this.lastMouseMovePt.X));
        const index = Math.floor(cashItemSeries.CashItem.FindIntervalByTime(ticks));
        return index;
    }

    AnalyzeWidth (): void {
    // 1. calculate total width
        let wdth = 0;
        this.ellipsisMenuItems = [];
        for (let i = 0; i < this.plates.length; i++) {
            wdth += this.plates[i].Width;
            if (wdth > this.Rectangle.Width) {
                this.plates[i].PlateVisible = false;
                this.ellipsisMenuItems.push(this.CreateEllipsisMenuItems(this.plates[i]));
            } else {
                this.plates[i].PlateVisible = true;
            }
        }
        this.totalWidth = wdth;

        // 2. show ellipsis rectangle
        if (this.totalWidth > this.Rectangle.Width) {
            const imgEllipsisRect = ThemeManager.CurrentTheme.ellipsisDef; // PlateBase.ellipsisDef;
            this.ellipsisRectangle = new Rectangle(
                this.Rectangle.X + this.Rectangle.Width - imgEllipsisRect.width,
                this.Rectangle.Y,
                imgEllipsisRect.width,
                imgEllipsisRect.height
            );
        } else {
            this.ellipsisRectangle = Rectangle.Empty();
        }

        // смещение при оверлей
        this.curX = this.WinNum < this.chart.windowsContainer.Windows.length ? this.chart.windowsContainer.Windows[this.WinNum].PaddingLeft : 0;

        // не должно быть пустого места справа при оверфлоу
        if (this.curX + this.totalWidth < this.ellipsisRectangle.X) {
            this.curX = this.ellipsisRectangle.X - this.totalWidth;
        }

        // 3. update plates rects
        let curX = this.curX;
        const plates = this.plates;
        for (let i = 0; i < plates.length; i++) {
            const plate: PlateBase = plates[i];
            const width = plate.Width;

            // влазит
            if (this.totalWidth <= this.Rectangle.Width || curX + width < this.ellipsisRectangle.X) {
                plate.clientRectangle = new Rectangle(curX, this.Rectangle.Y, width, this.Rectangle.Height);
            } else {
                plate.clientRectangle = Rectangle.Empty();
            }

            curX += width;
        }
    }

    CreateEllipsisMenuItems (plate: PlateBase): any {
        const item: any = {};
        const clickItemVisible = (): void => {
            this.ActionProcessVisible(plate);
        };
        const clickItemSettings = (): void => {
            this.ActionProcessSettings(plate);
        };
        const clickItemClose = (): void => {
            this.ActionProcessRemove(plate);
        };

        item.text = plate.getTextName();
        item.subitems = [
            {
                text: plate.getVisibilityText(),
                enabled: true,
                event: clickItemVisible
            },
            {
                text: plate.getPropertiesText(),
                enabled: true,
                event: clickItemSettings
            },
            {
                text: plate.getCloseText(),
                enabled: true,
                event: clickItemClose
            }
        ];

        return item;
    }

    GetCursor (e: any): any {
        if (!this.Visible) {
            return null;
        }
        for (let i = 0; i < this.plates.length; i++) {
            if (this.plates[i].GetHover()) {
                return Cursors.Hand;
            }
        }
        return null;
    }

    IsEmpty (): boolean {
        return this.platesIndicator.length === 0 && this.platesOverlay.length === 0;
    }

    GetContextMenu (e: any, chart: any): any {
        if (!this.Visible) {
            return TerceraChartBaseRenderer.prototype.GetContextMenu.call(this, e, chart);
        }

        let hoverPlate;
        const plates = this.plates;
        for (let i = 0, len = plates.length; i < len; i++) {
            const plate = plates[i];
            if (plate.GetHover()) {
                hoverPlate = plate;
                break;
            }
        }

        if (!hoverPlate) {
            return TerceraChartBaseRenderer.prototype.GetContextMenu.call(this, e, chart);
        }

        const renderer = hoverPlate.Renderer;
        const overlay = hoverPlate.terceraChartOverlay;

        const menuItems = hoverPlate.isIndicator
            ? [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.PlateIndicatorVisible, renderer) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.PlateIndicatorDublicate, renderer) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.PlateIndicatorRemove, renderer) },
                { separator: true },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.PlateIndicatorSettings, renderer) }
            ]
            : [
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.PlateOverlayVisible, overlay) },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.PlateOverlayRemove, overlay) },
                { separator: true },
                { tag: TerceraChartAction.Create(TerceraChartActionEnum.PlateOverlaySettings, overlay) }
            ];

        return chart.TerceraChartActionProcessor.CreateMenu(menuItems);
    }

    // Process mouse events
    ProcessMouseDown (e: any): boolean {
        if (!this.Visible || e.Button !== MouseButtons.Left) {
            return false;
        }

        this.lastMouseMovePt = e.Location;
        this.mouseState = TerceraChartPriceScaleRendererMouseState.None;
        this.mouseDownButtonRect = Rectangle.Empty();

        if (this.ellipsisRectangle.Contains(e.Location.X, e.Location.Y)) {
            const handler = contextMenuHandler.getHandler();
            handler.Show(this.ellipsisMenuItems, e.ClientLocation.X, e.ClientLocation.Y);
        } else if (this.Rectangle.Contains(e.Location.X, e.Location.Y)) {
            for (let i = 0; i < this.plates.length; i++) {
                if (this.plates[i].closeRectangle.Contains(e.Location.X, e.Location.Y)) {
                    this.mouseDownButtonRect = this.plates[i].closeRectangle;
                    return true;
                } else if (this.plates[i].visibilityRectangle.Contains(e.Location.X, e.Location.Y)) {
                    this.mouseDownButtonRect = this.plates[i].visibilityRectangle;
                    return true;
                } else if (this.plates[i].editRectangle.Contains(e.Location.X, e.Location.Y)) {
                    this.mouseDownButtonRect = this.plates[i].editRectangle;
                    return true;
                } else if (this.plates[i].ellipseRectangle.Contains(e.Location.X, e.Location.Y)) {
                    this.plates[i].SetSelected(!this.plates[i].GetSelected());
                    return true;
                }
            }
            if (this.mouseDownButtonRect.IsEmpty() && this.totalWidth > this.Rectangle.Width) {
                this.mouseState = TerceraChartPriceScaleRendererMouseState.Moving;
                return true;
            }
        }

        return false;
    }

    ProcessMouseMove (e: any): boolean {
        if (!this.Visible) {
            return false;
        }
        for (let i = 0; i < this.plates.length; i++) {
            const plate = this.plates[i];
            const newSelection = plate.clientRectangle.Contains(e.Location.X, e.Location.Y);
            const oldSelection = plate.GetHover();
            if (oldSelection !== newSelection) {
                plate.SetHover(newSelection);
                e.NeedRedraw = true; // надо также перерисовать все, что в фоне
            }
            if (oldSelection) plate.ProcessMouseMove(e.Location.X, e.Location.Y);
            if (oldSelection && !newSelection) plate.ProcessMouseLeave();
            if (newSelection) e.NeedRedraw = true;
        }

        let res = false;
        if (this.mouseState === TerceraChartPriceScaleRendererMouseState.Moving) {
            const newX = this.lastMouseMovePt.X;
            const xDeltaPx = e.Location.X - newX;
            this.curX += xDeltaPx;
            res = true;
        }

        this.lastMouseMovePt = e.Location;
        if (!res) {
            return TerceraChartBaseRenderer.prototype.ProcessMouseMove.call(this, e);
        } else {
            return true;
        }
    }

    ProcessMouseUp (e: any): boolean {
        if (this.mouseState === TerceraChartPriceScaleRendererMouseState.None && this.mouseDownButtonRect.Contains(e.Location.X, e.Location.Y)) {
            this.mouseDownButtonRect = Rectangle.Empty();
            for (let i = 0; i < this.plates.length; i++) {
                if (this.plates[i].GetHover()) {
                    if (this.plates[i].closeRectangle.Contains(e.Location.X, e.Location.Y)) {
                        this.ActionProcessRemove(this.plates[i]);
                    } else if (this.plates[i].editRectangle.Contains(e.Location.X, e.Location.Y)) {
                        this.ActionProcessSettings(this.plates[i]);
                    } else if (this.plates[i].visibilityRectangle.Contains(e.Location.X, e.Location.Y)) {
                        this.ActionProcessVisible(this.plates[i]);
                    }
                    return true;
                }
            }
        }

        this.mouseState = TerceraChartPriceScaleRendererMouseState.None;
        this.mouseDownButtonRect = Rectangle.Empty();

        TerceraChartBaseRenderer.prototype.ProcessMouseUp.call(this, e);
        return false;
    }

    ActionProcessRemove (plate: any): void {
        if (plate.isIndicator) this.chart.RemoveIndicator(plate.Renderer, true);
        else if (plate.isOverlay) this.chart.RemoveOverlay(plate.terceraChartOverlay);
    }

    ActionProcessSettings (plate: any): void {
        if (plate.isIndicator) {
            this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(TerceraChartActionEnum.PlateIndicatorSettings, plate.Renderer)
            );
        } else if (plate.isOverlay) {
            this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(TerceraChartActionEnum.PlateOverlaySettings, plate.terceraChartOverlay)
            );
        }
    }

    ActionProcessVisible (plate: any): void {
        if (plate.isIndicator) {
            this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(TerceraChartActionEnum.PlateIndicatorVisible, plate.Renderer)
            );
        } else if (plate.isOverlay) {
            this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(TerceraChartActionEnum.PlateOverlayVisible, plate.terceraChartOverlay)
            );
        }
    }

    ProcessMouseEnter (e: any): void {
        TerceraChartBaseRenderer.prototype.ProcessMouseEnter.call(this, e);
    }

    ProcessMouseLeave (e: any): void {
        TerceraChartBaseRenderer.prototype.ProcessMouseEnter.call(this, e);

        for (let i = 0; i < this.plates.length; i++) {
            this.plates[i].SetHover(false);
        }

        this.lastMouseMovePt = Point.Empty();
    }
}
