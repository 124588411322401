// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class CustomButtonsGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.CUSTOM_BUTTONS_GROUP, null, pfixFieldSet);
    }

    public getTitle (): string {
        return this.GetValue(FieldsFactory.FIELD_TITLE);
    }

    public getTooltip (): string {
        return this.GetValue(FieldsFactory.FIELD_TOOLTIP);
    }

    public getURL (): string {
        return this.GetValue(FieldsFactory.FIELD_URL);
    }

    public getCustomButtonNumber (): number {
        return this.GetValue(FieldsFactory.FIELD_NUMBER);
    }
}
