// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { type EllipseDataCacheTool } from '../../Commons/cache/Tools/EllipseDataCacheTool';
import { type TerceraChartCashItemSeries } from '../Series/TerceraChartCashItemSeries';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { CurveToolView } from './CurveToolView';

export class EllipseToolView<TDataCacheTool extends EllipseDataCacheTool> extends CurveToolView<TDataCacheTool> {
    public forceUpdateB = false;
    public lastMoveB = 0;

    public override Draw (gr, ww, param): void {
        const dataCacheTool = this.dataCacheTool;

        const pen = dataCacheTool.Pen;
        const brush = dataCacheTool.FillBrush;

        const fill = dataCacheTool.fill;

        const pc = ww.PointsConverter;
        //
        // блок для сброса искажения. рисеут эллипс каким он станет в случае сброса масштаба, в случае смещения точки
        // выделена точка, рисуем контур модифая!
        if (this.CurrentSelection.SelectedPointNo >= 0) {
            let point2;
            const res = this.getEllipsePoints(ww, point2, false, false);

            point2 = res.point2;

            const newLocationPoints = res.newAddPoints;
            const newLocationPoints_len = newLocationPoints.length;

            const newLocationPoint2 = new Point(pc.GetScreenX(point2[0]), pc.GetScreenY(point2[1]));

            const scrP2 = this.screenPoints[2];
            // если смещение больше 2пх то рисуем - иначе нет
            if (Math.abs(newLocationPoint2.X - scrP2[0]) > 2 || Math.abs(newLocationPoint2.Y - scrP2[1]) > 2) {
                const afterModifyPoints = [];
                for (let t = 0; t < newLocationPoints_len; t++) {
                    const newLocationPoint = newLocationPoints[t];
                    afterModifyPoints.push(new Point(
                        pc.GetScreenX(newLocationPoint[0]),
                        pc.GetScreenY(newLocationPoint[1])));
                }

                this.DrawSelectedPoint(gr, ww, newLocationPoint2.X, newLocationPoint2.Y);

                // рисуем эллипс по точкам
                if (afterModifyPoints.length > 4) {
                // Ловим ошибки
                /*
                if (lastCurvePoints[0].X >= ProMath.infinity || lastCurvePoints[0].Y >= ProMath.infinity || lastCurvePoints[0].X <= ProMath.infinityMinus || lastCurvePoints[0].Y <= ProMath.infinityMinus)
                { }
                else */
                    // {
                    gr.DrawClosedCurve(pen, afterModifyPoints);
                    if (fill) gr.FillClosedCurve(brush, afterModifyPoints);
                    // }
                }
            }
        }

        const lastCurvePoints = this.lastCurvePoints;
        if (lastCurvePoints.length > 2) {
        // Ловим ошибки
        /*
        if (lastCurvePoints[0].X >= ProMath.infinity || lastCurvePoints[0].Y >= ProMath.infinity || lastCurvePoints[0].X <= ProMath.infinityMinus || lastCurvePoints[0].Y <= ProMath.infinityMinus)
        { }
        else */
            // {
            // рисуем эллипс по точкам
            gr.DrawClosedCurve(pen, lastCurvePoints);
            if (fill) gr.FillClosedCurve(brush, lastCurvePoints);
            // }
        }

        super.Draw(gr, ww, param);
    }

    // началось тягание тулзы
    public ProcessMoveStart (x: number, y: number): void {
        super.ProcessMoveStart(x, y);
        const CurrentMovement = this.CurrentMovement;

        if (CurrentMovement.MovingPointNo === 0 || CurrentMovement.MovingPointNo === 1) {
            this.forceUpdateB = true;
        }
    }

    public override UpdateAdditionalPoints (ww, movePointNo: number, cashItemSeries: TerceraChartCashItemSeries): void {
        const dataCacheTool = this.dataCacheTool;
        // только 3 базовые точки
        this.UpdateScreenPointsALL(ww, cashItemSeries, true);
        let point2;
        const updateLastB = movePointNo === 2 || movePointNo === -1 || this.forceUpdateB;

        const res = this.getEllipsePoints(ww, point2, updateLastB, !this.forceUpdateB, cashItemSeries);

        const newPts = res.newAddPoints;
        const newPts_len = newPts.length;

        point2 = res.point2;

        const temp = dataCacheTool.CopyPoints();
        temp[2] = point2;

        for (let i = 0; i < newPts_len && temp.length > 3 + i; i++) {
            temp[3 + i] = newPts[i];
        }

        dataCacheTool.Points = temp;
        this.forceUpdateB = false;
    }

    // вызывается на изменение коорданат точек эллипса, вызывает пересчёт контура. на реальное перемещение надо useLastB (чтоб сохранялась высота эллипса), на рисование предсказанного контура не надо
    // PriceChannelToolView - наследник для того чтоб было такое же поведение у второй точки
    public getEllipsePoints (ww, point2, updateLastB, useLastB, cashItemSeries?: TerceraChartCashItemSeries) {
        point2 = new Array(2);
        const newAddPoints = [];

        const pc = ww.PointsConverter;

        const dataCacheTool = this.dataCacheTool;

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP1 = screenPoints[1];
        const scrP2 = screenPoints[2];

        const begin = new Point(scrP0[0], scrP0[1]);
        const end = new Point(scrP1[0], scrP1[1]);

        if (scrP1[0] < scrP0[0]) {
            begin.X = scrP1[0];
            begin.Y = scrP1[1];
            end.X = scrP0[0];
            end.Y = scrP0[1];
        }

        const dY = end.Y - begin.Y;
        const dX = end.X - begin.X;

        if (!dY && !dX) {
            return {
                newAddPoints,
                point2
            };
        }

        /// !!! Будем рисовать эллипс по точкам. точек будем искать много чтоб не страдала точность прорисовки кривой по точкам.
        /// http://stackoverflow.com/questions/4467121/solutions-for-y-for-a-rotated-ellipse
        /// уравнение эллипса  x = a*cos(t)*cos(w) - b*sin(t)*sin(w),  y = a*cos(t)*sin(w) + b*sin(t)*cos(w)
        /// где а и b - большая и меньшая оси эллипса соответственно,
        /// w - угол поворота эллипса, t - направление на точку в полярных координатах
        /// х0 и у0 - центр эллипса.
        ///
        const centerX = (end.X + begin.X) / 2;
        const centerY = (end.Y + begin.Y) / 2;

        let w = Math.atan(dY / Math.abs(dX));
        if (dX < 0) {
            w += Math.PI / 2;
        }

        const a = Math.sqrt(dX * dX + dY * dY) / 2;
        let t = 0;

        let b = 0;
        // расстояние по х от центра эллипса до 2 координаты
        const x = scrP2[0] - centerX;
        // расстояние по у от центра эллипса до 2 координаты
        const y = centerY - scrP2[1];

        if (x || y) {
        // угол поворота от центра эллипса до 2 координаты
            t = Math.atan((y) / Math.abs(x));
            // большая и малая оси
            b = Math.sqrt((x) * (x) + (y) * (y)) * Math.sin(x > 0 ? t + w : t - w);
        } else {
            b = 0;
        } // #34379

        const additionalPointsCount = dataCacheTool.AdditionalPointsCount();
        if (additionalPointsCount) {
            const incr = 6.28 / additionalPointsCount;

            for (t = 0; t < 6.28; t += incr)// fix 6,28 чтоб точки не накладывались в петельку
            {
                const xt = a * Math.cos(t) * Math.cos(w) - b * Math.sin(t) * Math.sin(w);
                const yt = b * Math.cos(w) * Math.sin(t) + a * Math.cos(t) * Math.sin(w);

                if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Relative) {
                    newAddPoints.push([
                        pc.GetDataX(xt + centerX),
                        cashItemSeries.settings.relativeDataConverter.Revert(pc.GetDataY(centerY + yt))
                    ]);
                } else if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Log) {
                    newAddPoints.push([
                        pc.GetDataX(xt + centerX),
                        cashItemSeries.settings.logDataConverter.Revert(pc.GetDataY(centerY + yt))
                    ]);
                } else {
                    newAddPoints.push([
                        pc.GetDataX(xt + centerX),
                        pc.GetDataY(centerY + yt)
                    ]);
                }
            }
        }

        if (updateLastB && b) {
            this.lastMoveB = b;
        }

        const point2CalculateB = useLastB ? this.lastMoveB : b;

        // Сохраняем новые координаты для точки 3 !!!
        t = -Math.PI / 2; // 3-я точка - задает размер второй диагонали и рисуем мы её перпендикулярно к первой оси.

        point2[0] = pc.GetDataX(centerX + a * Math.cos(t) * Math.cos(w) - point2CalculateB * Math.sin(t) * Math.sin(w));

        const point2Y = centerY + point2CalculateB * Math.cos(w) * Math.sin(t) + a * Math.cos(t) * Math.sin(w);

        if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Relative) {
            point2[1] = cashItemSeries.settings.relativeDataConverter.Revert(pc.GetDataY(point2Y));
        } else if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Log) {
            point2[1] = cashItemSeries.settings.logDataConverter.Revert(pc.GetDataY(point2Y));
        } else {
            point2[1] = pc.GetDataY(point2Y);
        }

        return {
            newAddPoints,
            point2
        };
    }

    public override DrawSelection (gr, ww): void {
        const dataCacheTool = this.dataCacheTool;
        const pointLevel = dataCacheTool.PointLevel();

        const screenPoints = this.screenPoints;

        for (let i = 0; i < pointLevel; i++) {
            const scrP = screenPoints[i];
            super.DrawSelectedPoint(gr, ww, scrP[0], scrP[1]);
        }

        const scrP0 = screenPoints[0];
        const x0 = scrP0[0];
        const y0 = scrP0[1];

        const scrP1 = screenPoints[1];
        const x1 = scrP1[0];
        const y1 = scrP1[1];

        super.DrawSelectedLine(gr, ww, x0, y0, x1, y1);
        gr.DrawLine(dataCacheTool.Pen, x0, y0, x1, y1);
    }
}
