// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { BaseRowDataItem, RowColorText, RowIndexNames } from './BaseRowDataItem';

export class PositionRowDataItem extends BaseRowDataItem {
    public static readonly COLUMNAMOUNT = 4;

    public getColumnAmount (): number {
        return PositionRowDataItem.COLUMNAMOUNT;
    }

    public override getValue (index: RowIndexNames): any {
        const source = this.source;
        switch (index) {
        case RowIndexNames.NAME:
            return source.Symbol;

        case RowIndexNames.DESCRIPTION:
            return source.Description;

        case RowIndexNames.IMG:
            return source.Img;

        case RowIndexNames.COLUMN_ONE:
            return source.Side;

        case RowIndexNames.COLUMN_TWO:
            return source.Quantity;

        case RowIndexNames.COLUMN_THREE:
            return source.Profit;

        default:
            return null;
        }
    }

    public override getColor (index: RowIndexNames): RowColorText {
        const source = this.source;
        switch (index) {
        case RowIndexNames.COLUMN_ONE:
            if (source.Side === 'Long') {
                return RowColorText.BLUE;
            } else {
                return RowColorText.RED;
            }

        case RowIndexNames.COLUMN_TWO:
            return RowColorText.GREEN;

        case RowIndexNames.COLUMN_THREE:
            if (source.NumberProfit && source.NumberProfit !== 0) {
                const profitColor = source.NumberProfit > 0 ? RowColorText.GREEN : RowColorText.RED;
                return profitColor;
            } else {
                return RowColorText.BLACK;
            }

        default:
            return RowColorText.BLACK;
        }
    }
}
