// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
'use strict';

import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class RouteIDGroup extends PFixFieldSet {
    constructor (pfixFieldSet?) {
        super(FieldsFactory.ROUTE_ID_GROUP, null, pfixFieldSet);
    }

    public getRouteId (): number {
        return this.GetValue(FieldsFactory.FIELD_ROUTE_ID);
    }

    public setRouteId (routeID: number): void {
        this.SetField(FieldsFactory.FIELD_ROUTE_ID, routeID);
    }
}
