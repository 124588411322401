// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { type CustomTranslateOptions } from '../charting_library';
import { ApplicationInfo } from '../../ApplicationInfo';

export class _TVCustomTranslations {
    public Translate (key: string, options?: CustomTranslateOptions, isTranslated?: boolean): string | null {
        if (key === 'StopLimit') {
            return Resources.getResource('TvTradingPlatform.OrderType.StopLimit');
        }

        if (ApplicationInfo.isExploreMode && key === 'To learn more, please contact your broker.') {
            return '';
        }

        return null;
    }
}

export const TVCustomTranslations = new _TVCustomTranslations();
