// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CreateAlertInstrumentLookupTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Localizations/Resources';
import { ResourcesManager } from '../../../Commons/properties/ResourcesManager';

export class CreateAlertInstrumentLookup extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'CreateAlertInstrumentLookup'; }

    public override oninit (): void {
        super.oninit();
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        void this.set('label', Resources.getResource('screen.Alerts.Label.Symbol'));
    }
}

ContainerControl.extendWith(CreateAlertInstrumentLookup, {
    template: CreateAlertInstrumentLookupTemplate,
    data: function () {
        return {
            label: '',
            isSymbolInfoOpened: false,
            instrument: null,
            account: null,
            width: 198
        };
    }
});
