// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type Algorithm, AlgorithmType, AlgorithmSubscribeType } from '../../Commons/cache/Algorithm/Algorithm';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { DynProperty } from '../../Commons/DynProperty';
import { type Instrument } from '../../Commons/cache/Instrument';
import { DataCache } from '../../Commons/DataCache';
import { Resources } from '../../Localizations/Resources';
import { HighLowPanelTemplate } from '../../templates';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { Control } from '../elements/Control';
import { QuickTableRactive } from '../elements/QuickTable/QuickTableRactive';
import { TerceraMenu } from '../elements/TerceraMenu';
import { LinkedSystem } from '../misc/LinkedSystem';
import { HighLowData, HighLowEventType, IsHigh } from '../../Commons/cache/Algorithm/HighLow/HighLowData';
import { HighLowItem } from '../cache/HighLowItem';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';

export class HighLowPanel extends ApplicationPanelWithTable<HighLowItem> {
    highsQTR: QuickTableRactive<HighLowItem>;
    lowsQTR: QuickTableRactive<HighLowItem>;
    savedExchangesState: string[] | null = null;
    exchanges: string[] = [];
    menuTagDictHigh: any;
    menuTagDictLow: any;
    deferredHighsSettings: any;
    deferredLowsSettings: any;

    constructor () {
        super();

        this.Name = 'HighLowPanel';
        this.headerLocaleKey = 'panel.HighLowPanel';
        this.topPanelHeight = 27;
        this.NeedCalculateRowCount = false;
    }

    getType (): PanelNames { return PanelNames.HighLowPanel; }

    get highsTable (): QuickTable | undefined { return this.highsQTR?.quickTable; }
    get lowsTable (): QuickTable | undefined { return this.lowsQTR?.quickTable; }

    oninit (): void {
        super.oninit();
        Control.Ticker.Subscribe(this.TickAsync, this);
    }

    oncomplete (): void {
        super.oncomplete();
        this.createContextMenu();
        this.repopulate();
        this.localize();

        this.symbolsLookup.IsMultiSelect = true;

        this.highsTable?.OnSelectionChanged.Subscribe(this.Table_SelectionChanged.bind(this, true), this);
        this.lowsTable?.OnSelectionChanged.Subscribe(this.Table_SelectionChanged.bind(this, false), this);
    }

    dispose (): void {
        this.UnSubscribe();
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        this.highsTable?.OnSelectionChanged.UnSubscribe(this.Table_SelectionChanged, this);
        this.lowsTable?.OnSelectionChanged.UnSubscribe(this.Table_SelectionChanged, this);
        super.dispose();
    }

    localize (): void {
        super.localize();

        const noExchText = Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges');

        this.exchangeComboBox?.set({
            allItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges'),
            noItemsSelectedText: noExchText,
            noItemsText: noExchText,
            fewItemsSelectedPostfixText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges')
        });

        if (this.highsTable != null) {
            if (this.highsTable.needRedraw) { this.highsTable.localize(); }
            this.localizeMenuItems(this.highsTable.tableContextMenuItems);
        }
        if (this.lowsTable != null) {
            if (this.lowsTable.needRedraw) { this.lowsTable.localize(); }
            this.localizeMenuItems(this.lowsTable.tableContextMenuItems);
        }

        void this.set('noDataText', Resources.getResource('panel.HighLowPanel.noData'));
    }

    repopulate (): void {
        super.repopulate();
        this.PopulateSymbols();
    }

    TickAsync (): void {
        super.TickAsync();
        if (this.highsTable != null && this.highsTable.needRedraw) {
            this.highsTable.Draw();
            this.highsTable.needRedraw = false;
        }
        if (this.lowsTable != null && this.lowsTable.needRedraw) {
            this.lowsTable.Draw();
            this.lowsTable.needRedraw = false;
        }
    }

    ProcessAlgorithm (highLowMessage: any/* DirectAlgorithmHighLowMessage */): void {
        const needRepopulateExchangeList = this.ProcessItems(highLowMessage.HighLowItems);
        if (needRepopulateExchangeList || this.savedExchangesState != null) { this.RepopulateExchanges(); }
    }

    private readonly UpdateNoData = (): void => {
        void this.set({
            noDataForLows: this.lowsTable?.rowsArray.length === 0,
            noDataForHighs: this.highsTable?.rowsArray.length === 0
        });
    };

    ProcessItems (highLowDataItems: HighLowData[]): boolean {
        const highTable = this.highsTable;
        const lowTable = this.lowsTable;

        if (highTable == null || lowTable == null) { return false; }

        let needRepopulateExchangeList = false;
        for (const item of highLowDataItems) {
            if (!this.IsAllowByProductSubscription(item)) { continue; }

            // Update exchange list
            if (item.MarketDataExchange != null && !this.exchanges.includes(item.MarketDataExchange)) {
                this.exchanges.push(item.MarketDataExchange);
                needRepopulateExchangeList = true;
            }

            // Check filter
            if (!this.IsAllowByFilter(item)) { continue; }

            const tableItem: HighLowItem = new HighLowItem(item);
            if (IsHigh(item.EventType)) {
                highTable.AddItem(tableItem);
                highTable.needRedraw = true;
            } else {
                lowTable.AddItem(tableItem);
                lowTable.needRedraw = true;
            }
        }

        this.UpdateNoData();

        return needRepopulateExchangeList;
    }

    private readonly IsAllowByFilter = (highLowData: HighLowData): boolean => {
        let allowByExchange: boolean = true;

        if (this.exchangeComboBox == null) { return false; }

        let exchangeItems = this.exchangeComboBox.get('items');

        if (exchangeItems == null) { exchangeItems = []; }

        const checkedExchanges = exchangeItems.filter((item) => item.checked);

        if (checkedExchanges.length !== exchangeItems.length) { allowByExchange = checkedExchanges.filter(exchange => exchange.value === highLowData.MarketDataExchange).length > 0; }
        let allowBySymbol: boolean = true;
        const symbolFilter = this.symbolsLookup.get('selectedItem');
        const filterSymbols = symbolFilter.FullName;
        const insArr = symbolFilter.arraySelected || [];
        if (symbolFilter.InstrumentTradableID != null) { insArr.push(symbolFilter); }
        if (filterSymbols !== 'All' && insArr != null) {
            allowBySymbol = insArr.some(ins => {
                return ins.InstrumentTradableID === highLowData.TradableId;
            });
        }
        return allowByExchange && allowBySymbol;
    };

    protected readonly jbInit = (): void => {
        const highsItems = new HighLowItem(new HighLowData(HighLowEventType.BreakingHigh));
        const lowsItems = new HighLowItem(new HighLowData(HighLowEventType.BreakingLow));

        this.lowsQTR = this.createTable(lowsItems);
        this.highsQTR = this.createTable(highsItems);

        const qtHighs = this.highsQTR.quickTable;
        const qtLows = this.lowsQTR.quickTable;

        if (qtHighs == null || qtLows == null) { return; }

        this.applyDeferredXmlSettings();

        qtHighs.UpdateSortedColumns();
        qtLows.UpdateSortedColumns();

        this.OnResize.Subscribe(this.layoutTable, this);

        this.on('selectedSymbolsChanged', this.SymbolsLookup_SelectedSymbolChanged);
        this.on('exchangeFilterChanged', this.ExchangeComboBox_ValuesChanged);

        qtHighs.beforeTableContextMenuShowing = this.preparePopup.bind(this);
        qtLows.beforeTableContextMenuShowing = this.preparePopup.bind(this);

        qtHighs.lockManualSorting = qtLows.lockManualSorting = true;
        qtHighs.AddToEnd = qtLows.AddToEnd = false;

        this.localize();
        this.layoutTable();
    };

    private readonly SymbolsLookup_SelectedSymbolChanged = (): void => { this.ClearByFilter(); };
    private readonly ExchangeComboBox_ValuesChanged = (): void => {
        this.savedExchangesState = this.exchangeComboBox.get('items').filter((e) => e.checked).map((e) => e.value);
        this.ClearByFilter();
    };

    private readonly RepopulateExchanges = (): void => {
        const oldItems = this.exchangeComboBox.get('items');
        const newItems = this.exchanges.map(str => {
            return {
                text: str,
                value: str,
                checked: this.savedExchangesState === null || this.savedExchangesState.includes(str) ||
                    (oldItems != null && this.savedExchangesState.length === oldItems.length) // All exchanges selected (need to include new if received)
            };
        });

        this.exchangeComboBox.set({ items: newItems });
    };

    private readonly ClearByFilter = (): void => {
        this.ClearTableByFilter(this.highsTable);
        this.ClearTableByFilter(this.lowsTable);
        this.UpdateNoData();
    };

    private readonly ClearTableByFilter = (table: QuickTable | undefined): void => {
        if (table == null) { return; }

        const itemsToRemove: HighLowItem[] = [];
        table.rowsArray.forEach(row => {
            if (!this.IsAllowByFilter(row.item.Data)) { itemsToRemove.push(row.item); }
        });

        itemsToRemove.forEach(item => { table.RemoveItem(item.ItemId); });
        if (itemsToRemove.length > 0) { table.OnScroll(); } // for redraw
    };

    private readonly createTable = (items: HighLowItem): QuickTableRactive<HighLowItem> => {
        const qtr = new QuickTableRactive<HighLowItem>();
        super.addControl(qtr, true);

        const qt = qtr.quickTable;
        qt.allowGroupBy = true;
        qt.InitializeDirect(items);
        qt.UpdateSortedColumns();

        return qtr;
    };

    private readonly OnClearTableClick = (forHighTable: boolean): void => {
        const qt = forHighTable ? this.highsTable : this.lowsTable;
        qt?.ClearAll();
        this.UpdateNoData();
    };

    private readonly Table_SelectionChanged = (onHighsTable: boolean, fromMouseClick: boolean): void => {
        if (fromMouseClick == null) { return; }

        const selectTable = onHighsTable ? this.highsTable : this.lowsTable;
        const anotherTable = onHighsTable ? this.lowsTable : this.highsTable;

        if (selectTable == null || anotherTable == null) { return; }

        let selectedRowNum = selectTable.selectedRowIds.length;
        if (selectedRowNum > 1) {
            selectTable.selectedRowIds = [selectTable.selectedRowIds[selectedRowNum - 1]];
            selectedRowNum = 1;
            selectTable.OnScroll(); // for redraw
        }

        if (selectedRowNum === 1) {
            const selectedRow = selectTable.getItemById(selectTable.selectedRowIds[0]);
            const data = selectedRow.item.Data;
            // const selectedIns = DataCache.getInstrument(data.TradableId.toString(), data.RouteId.toString());
            void DataCache.getInstrumentByInstrumentTradableID_NFL(data.TradableId, data.RouteId).then((selectedIns: Instrument) => {
                this.symbolLink_Out(false, selectedIns?.GetInteriorID());
            });
        }

        if (anotherTable != null && anotherTable.selectedRowIds.length > 0) {
            anotherTable.selectedRowIds = [];
            anotherTable.OnScroll(); // for redraw
        }
    };

    private readonly IsAllowByProductSubscription = (highLowData: HighLowData): boolean => {
        const entitlmentSystemCache = DataCache.EntitlementManager;
        const instrumentId = highLowData.InstrumentId.toString();
        const instrumentGroupId = highLowData.InstrumentGroupId;
        const tradingExchange = highLowData.TradingExchange;

        if (highLowData.IsDelayed) {
            // Don't have RealTime subscription and have Delayed
            return !entitlmentSystemCache.IsExistSubscription(false, instrumentId, instrumentGroupId, tradingExchange) &&
                entitlmentSystemCache.IsExistSubscription(true, instrumentId, instrumentGroupId, tradingExchange);
        } else {
            return entitlmentSystemCache.IsExistSubscription(false, instrumentId, instrumentGroupId, tradingExchange);
        }
    };

    public readonly layoutTable = (): void => {
        if (this.lowsQTR != null) { super.layoutTableResize(this.lowsQTR); }
        if (this.highsQTR != null) { super.layoutTableResize(this.highsQTR); }
    };

    protected readonly symbolLink_Out = (newSubscriber, instrument): void => {
        if (instrument == null) { return; }

        const color = super.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) { LinkedSystem.setSymbol(color, instrument, newSubscriber); }
    };

    private get symbolsLookup (): any { return this.Controls?.SymbolsList; }
    private get exchangeComboBox (): any { return this.Controls?.ExchangeFilter; }

    private readonly Subscribe = (): void => {
        const algorithmCache = DataCache.AlgorithmCache;
        if (algorithmCache != null) {
            const algorithms = algorithmCache.getAlgorithmsByAlgorithmType(AlgorithmType.HighLow);
            algorithms.forEach((algorithm: Algorithm) => {
                algorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Subscribe, algorithm.AlgorithmId, this);
            });
        }
    };

    private readonly UnSubscribe = (): void => {
        const algorithmCache = DataCache.AlgorithmCache;
        if (algorithmCache != null) {
            const algorithms = algorithmCache.getAlgorithmsByAlgorithmType(AlgorithmType.HighLow);
            algorithms.forEach((algorithm: Algorithm) => {
                algorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Unsubscribe, algorithm.AlgorithmId, this);
            });
        }
    };

    private readonly PopulateSymbols = (): void => {
        this.Subscribe();
        // this.algorithmsComboBox?.clearItems();

        // const algorithmCache = DataCache.AlgorithmCache;
        // if (algorithmCache != null) {
        //     const algorithms = [
        //         ...algorithmCache.GetAlgorithmsByAlgorithmType(AlgorithmType.HighLow)
        //     ];

        //     algorithms.sort((x, y) => x.AlgorithmName.localeCompare(y.AlgorithmName));

        //     for (const algorithm of algorithms) {
        //         const comboItem = { text: algorithm.AlgorithmName, value: algorithm.AlgorithmId };
        //         this.algorithmsComboBox.addItem(comboItem);
        //     }
        // }

        // const algoCBItems = this.algorithmsComboBox.get('items');

        // this.algorithmsComboBox.set('visible', algoCBItems.length > 1);

        // if (algoCBItems.length > 0) {
        //     // if (this._subscribedAlgorithmIdProp == null) { this._subscribedAlgorithmIdProp = algoCBItems[0].value; }
        //     // this.algorithmsComboBox.setItembyValue(this._subscribedAlgorithmIdProp);
        // }
    };

    private readonly createContextMenu = (): void => {
        const menuItemsHigh = this.createMenuItems(true);
        this.menuTagDictHigh = TerceraMenu.createTagDictionary(menuItemsHigh);

        this.highsTable?.setTableContextMenuItems(menuItemsHigh);

        const menuItemsLow = this.createMenuItems(false);
        this.menuTagDictLow = TerceraMenu.createTagDictionary(menuItemsLow);
        this.lowsTable?.setTableContextMenuItems(menuItemsLow);
    };

    private readonly localizeMenuItems = (items: any[]): void => {
        items?.forEach((item) => {
            this.localizeMenuItem(item);
            if (item.subitems != null) {
                item.subitems.forEach((subitem: any) => { this.localizeMenuItems(item.subitems); });
            }
        });
    };

    private readonly localizeMenuItem = (item: any): void => {
        if (item?.locKey != null) {
            item.text = Resources.getResource(item.locKey);
        };
    };

    private readonly createMenuItems = (forHighTable: boolean): any[] => {
        const items: any[] = [];

        if (!Resources.isHidden('panel.HighLowPanel.menu.ClearTable')) {
            items.push(
                {
                    locKey: 'panel.HighLowPanel.menu.ClearTable',
                    tag: 'ClearTable',
                    enabled: true,
                    event: this.OnClearTableClick.bind(this, forHighTable)
                });
        }

        if (!Resources.isHidden('panel.HighLowPanel.menu.ShowToolbar')) {
            items.push(
                {
                    locKey: 'panel.positions.menu.View',
                    tag: 'View',
                    enabled: true,
                    subitems: [
                        {
                            locKey: 'panel.positions.menu.ShowToolbar',
                            tag: 'ShowToolbar',
                            checked: super.get('showToolbar'),
                            enabled: true,
                            canCheck: true,
                            event: this.ShowToolbarStateChange.bind(this)
                        }]
                });
        }
        return items;
    };

    private readonly ShowToolbarStateChange = (menuItem: any): void => {
        this.ShowToolbarStateUpdate(menuItem.checked);
    };

    private readonly ShowToolbarStateUpdate = (state: boolean): void => {
        void this.set('showToolbar', state);
        this.layoutTable();
    };

    applyDeferredXmlSettings = (): void => {
        if (this.highsTable != null && this.deferredHighsSettings != null) {
            this.highsTable.xmlSettingsTemplate = this.deferredHighsSettings;
            /* delete */ this.deferredHighsSettings = null;
        }

        if (this.lowsTable != null && this.deferredLowsSettings != null) {
            this.lowsTable.xmlSettingsTemplate = this.deferredLowsSettings;
            /* delete */ this.deferredLowsSettings = null;
        }
    };

    getXmlSettingsTemplate = (): any => {
        const xml = { HighsSettings: {}, LowsSettings: {} };

        if (this.highsTable != null) { xml.HighsSettings = this.highsTable.xmlSettingsTemplate; }
        if (this.lowsTable != null) { xml.LowsSettings = this.lowsTable.xmlSettingsTemplate; }

        return xml;
    };

    setXmlSettingsTemplate = (value: any): void => {
        const highsSettings = value?.HighsSettings;
        const lowsSettings = value?.LowsSettings;

        if (highsSettings != null) {
            if (this.highsTable != null) { this.highsTable.xmlSettingsTemplate = value; } else { this.deferredHighsSettings = highsSettings; }
        }

        if (lowsSettings != null) {
            if (this.lowsTable != null) { this.lowsTable.xmlSettingsTemplate = value; } else { this.deferredLowsSettings = lowsSettings; }
        }
    };

    Properties = (): DynProperty[] => {
        const props: DynProperty[] = super.Properties();

        const dp: DynProperty = new DynProperty('SelectedExchangesState', JSON.stringify(this.savedExchangesState), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        props.push(dp);
        return props;
    };

    callBack = (properties: DynProperty[]): void => {
        super.callBack(properties);

        const prop = DynProperty.getPropertyByName(properties, 'SelectedExchangesState');
        if (prop != null) { this.savedExchangesState = JSON.parse(prop.value); }
    };
}

ApplicationPanelWithTable.extendWith(HighLowPanel, {
    partials: { bodyPartial: HighLowPanelTemplate },

    data: function () {
        return {
            canLinkByAccount: false,
            isSymbolLinkShow: true,
            showToolbar: true,
            HighsTableCaption: 'High',
            LowsTableCaption: 'Low',
            noDataForLows: true,
            noDataForHighs: true,
            noDataText: '',
            highsLeft: null,
            tablesHeight: null,
            tablesWidth: null,
            timeRangeFilterVisible: true
        };
    }
});
