// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Decimal } from 'decimal.js';

export class MathUtils {
    public static regexpPrecision = /(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/;
    public static MATH_ROUND_EPSILON = 0.000000001;

    // Polyfill.
    public static trunc = Math.trunc || function (x) {
        return x < 0 ? Math.ceil(x) : Math.floor(x);
    };

    public static TruncateDouble (value, signCount): number {
        if (!value) { return 0; } // -0 fix #98014

        const factor = Math.pow(10, signCount);
        const epsilon = value > 0 ? MathUtils.MATH_ROUND_EPSILON : -MathUtils.MATH_ROUND_EPSILON;// #85860
        return MathUtils.trunc((value * 10 + epsilon) * factor / 10) / factor; // #85574 //#85860
    }

    // WARNING. May cause minor precision errors.
    // отбрасывание до инкремента
    public static RoundToIncrement (value, roundToValue): number {
        if (roundToValue <= 0) { return value; }

        // +hsa - на сервере используют математическое округление
        return Math.round(value / roundToValue + MathUtils.MATH_ROUND_EPSILON) * roundToValue;
    }

    public static RoundTo (number, roundTo): number {
        if (roundTo == 0) { return number; }

        if (number == 0) { return number; }

        const rounded = Math.round(number / roundTo) * roundTo;
        return Math.abs(rounded) >= roundTo ? rounded : roundTo;
    }

    public static RoundDouble (value, signCount): number {
        if (!value) { return 0; } // -0 fix #98014

        const factor = Math.pow(10, signCount);
        const epsilon = value > 0 ? MathUtils.MATH_ROUND_EPSILON : -MathUtils.MATH_ROUND_EPSILON;// #85860
        return Math.round((value * 10 + epsilon) * factor / 10) / factor; // #85574 //#85860
    }

    public static Round (value: number, decimalPlaces: number): number {
        const f = Math.pow(10, decimalPlaces);
        return Math.round(value * f) / f;
    }

    public static CeilToDecimalPlaces (value: number, decimalPlaces: number): number {
        const f = Math.pow(10, decimalPlaces);
        return Math.ceil(value * f) / f;
    }

    public static FloorToDecimalPlaces (value: number, decimalPlaces: number): number {
        const f = Math.pow(10, decimalPlaces);
        return Math.floor(value * f) / f;
    }

    public static equalToEpsilon (value1, value2): boolean // проверяет равны ли величины с точностью до MathUtils.MATH_ROUND_EPSILON = 0.000000001
    {
        return Math.abs(value1 - value2) < MathUtils.MATH_ROUND_EPSILON;
    }

    public static GetValuePrecision (value, precisionIncr, minPrecision = -2): number {
        if (value == 0 || isNaN(value)) {
            return 0;
        }

        value = Math.abs(value);

        let precision = Math.log10(value);

        if (precision > 0) {
            const fraction = value % Math.floor(value); // .Net: value % (int)value
            if (fraction != 0) {
                precision = Math.abs(Math.log10(fraction)) + precisionIncr;
            } else {
                precision = 0;
            }
        } else { precision = Math.abs(precision) + precisionIncr; }

        return minPrecision != -1 && minPrecision >= precision ? minPrecision : Math.floor(precision);
    }

    // http://stackoverflow.com/questions/10454518/javascript-how-to-retrieve-the-number-of-decimals-of-a-string-number
    public static getPrecision (value): number {
        const match = ('' + value).match(MathUtils.regexpPrecision);
        if (!match) { return 0; }
        return Math.max(
            0,
            // Number of digits right of decimal point.
            (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0));
    }

    /**
     * The method returns the remainder of the division with increased precision.
     * This is because the regular division remainder (%) does not always return data correctly.
     * For example, 1 % 0.01 === 0.00999999999999998 in JavaScript.
     * This method returns 0
     * @param {number} dividend - The number to be divided.
     * @param {number} divisor - The number by which the dividend is to be divided.
     * @returns {number} Remainder of the division
     */
    public static mod (dividend: number, divisor: number): number {
        const dividendDecimal = new Decimal(dividend);
        const divisorDecimal = new Decimal(divisor);
        const remainder = dividendDecimal.mod(divisorDecimal);
        return remainder.toNumber();
    }

    public static IsNullOrUndefined (o): boolean { return o === null || o === undefined; }

    // Получить котичество знаков после точки
    public static GetDecimalPlaces (value, max: any = undefined): number // вероятно стоит отказаться от использования - performance test показал что этот метод на ~35% менее эффективный в сравнении с MathUtils.getPrecision (на случайных числах десятичной записи от 0 до 8 точек после запятой)
    {
        if (MathUtils.IsNullOrUndefined(value)) {
            return null;
        }

        const str = value.toString();
        let spl = str.split('.');

        let places = 0;
        if (spl.length > 1) {
            places = spl[1].length;
        } else {
            spl = str.split('e-');
            if (spl.length > 1) { places = parseInt(spl[1]); }
        }

        return places;
    }

    public static EPS_ROUNDING_REGEXP = /0+$/;
    public static EpsTableFormat = {};

    public static formatValueWithEps (numb): string {
        if (!numb) {
            return '0';
        }
        let res = numb.toString();
        if (res.indexOf('e') !== -1) {
            res = MathUtils.EpsTableFormat[numb] || numb.toFixed(20).replace(MathUtils.EPS_ROUNDING_REGEXP, '');
            if (!MathUtils.EpsTableFormat[numb]) {
                MathUtils.EpsTableFormat[numb] = res;
            }
        }

        return res;
    }

    public static ProcessNaN (value, defaultValue: any = undefined): any {
        defaultValue = defaultValue || 0;
        return value === null || isNaN(value) ? defaultValue : value;

        // return !value.HasValue || double.IsNaN(value.Value) ? defaultValue : value.Value;
    }

    public static isValueMultipleToStep (value, step: any = undefined, stepPrecision: any = undefined): boolean {
        const curValuePrecision = this.getPrecision(value);
        if (curValuePrecision > stepPrecision) return false;

        const multiplier = Math.pow(10, stepPrecision);

        const intValue = Math.round(value * multiplier);
        const intStep = Math.round(step * multiplier);

        return intValue % intStep === 0;
    }

    public static random (min: number, max: number): number {
        return Math.floor(Math.random() * (max - min)) + min;
    }
}

// TODO
Math.log10 = Math.log10 || function (x) {
    return Math.log(x) * Math.LOG10E;
};

Math.trunc = Math.trunc || function (x) {
    if (isNaN(x)) {
        return NaN;
    }
    if (x > 0) {
        return Math.floor(x);
    }
    return Math.ceil(x);
};

export const DECIMAL_SEPARATOR = (() => {
    const tmp = new Intl.NumberFormat(undefined, { minimumFractionDigits: 1, maximumFractionDigits: 1 });
    return tmp.format(1).charAt(1);
})();
