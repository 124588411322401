// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CreateAlertMessageSelectorTemplate } from '../../../templates';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Localizations/Resources';
import { AlertType } from '../../../Utils/Alert/AlertConstants';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { AlertUtils } from '../../../Utils/Alert/AlertUtils';
import { WDSettings } from '../../settings/WDGeneralSettings';
import { ResourcesManager } from '../../../Commons/properties/ResourcesManager';

export class CreateAlertMessageSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.CreateAlertMessageSelector; }

    public override oninit (): void {
        super.oninit();
        super.observe('account instrument alertType alertCondition alertValue', this.updateAlertMessageText);

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public updateAlertMessageText (): void {
        const instrument = super.get('instrument');
        if (instrument == null) { return; }

        const msgTxt = AlertUtils.GetMessageText(instrument, super.get('alertType'), super.get('alertCondition'), this.getAlertValueFormatted(), super.get('account'));

        void super.set('messageText', msgTxt);
    }

    public getAlertValueFormatted (): string {
        const instrument = super.get('instrument');
        if (instrument == null) { return ''; }

        const alertType = super.get('alertType');
        const value = super.get('alertValue');

        let formattedValue = '';
        if (alertType === AlertType.CHANGE) {
            formattedValue = value.toFixed(2);
        } else if (alertType === AlertType.VOLUME) {
            formattedValue = instrument.DataCache.formatVolume(instrument, value, null, WDSettings.productType, super.get('account'));
        } else { formattedValue = instrument.formatPrice(value); }

        return formattedValue;
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);
        super.dispose();
    }

    public localize (): void {
        void super.set('label', Resources.getResource('screen.Alerts.Message'));
        void super.set('placeholder', Resources.getResource('screen.Alerts.Button.Create.Tooltip'));
    }
}

ContainerControl.extendWith(CreateAlertMessageSelector, {
    template: CreateAlertMessageSelectorTemplate,
    data: function () {
        return {
            label: '',
            messageText: '',
            placeholder: '',
            isMessageTextValid: true
        };
    }
});
