// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
class _DataCacheHolder {
    private _dataCache: any = {};
    public getDataCache (): any {
        return this._dataCache;
    }

    public setDataCache (dataCache: any): void {
        this._dataCache = dataCache;
    }
}

export const DataCacheHolder = new _DataCacheHolder();
