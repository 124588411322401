// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCacheToolRayType, SelectionModeEnum } from './DataCacheToolEnums';
import { Color, Pen, PenStyle, SolidBrush } from '../../Commons/Graphics';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { DynProperty, ColorStyleWidth } from '../DynProperty';
import { DataCache } from '../DataCache';
import { type Instrument } from './Instrument';

export class DataCacheTool {
    public static toolId = 0;

    public ID: string;
    public ToolType: any = null;
    public forceUpdateAdditionalPoints = true;
    public OnUpdate = new CustomEvent();
    public stickTo = 0;
    public completed = false;
    public addedPointsNum = -1;
    public Points: any[];
    public fontColor: any;
    public fontBrush: SolidBrush;
    public fontBrushHighlight: SolidBrush;
    public color: any;
    public width: any;
    public penHalo: any;
    public styleRay: any;
    public pen: Pen;
    public penRay: Pen;
    public penHighlight: Pen;
    public RayType: DataCacheToolRayType;
    public SelectionMode: SelectionModeEnum;
    public StickToPeriod: any[];
    public IsBackground: boolean;
    public font: any;
    public colorAlpha: number;
    public style: number;
    public Instrument: Instrument;
    public AssignedSymbol: string;
    public WindowsNumber: number = 0;

    constructor () {
        this.ID = 'DataCacheTool_' + DataCacheTool.toolId;
        DataCacheTool.toolId++;
        this.InitBlock();
    }

    public InitCompleted (): void {
        this.completed = true;
        this.InitedPointsNumber = this.PointLevel();
    }

    get InitedPointsNumber (): number { return this.addedPointsNum; }
    set InitedPointsNumber (value) { this.addedPointsNum = value; }

    get StickTo (): number { return this.stickTo; }
    set StickTo (value) { this.stickTo = value; }

    /// <summary>
    /// number without AdditionalPoints, only base points
    /// </summary>
    public PointLevel (): number {
        return this.Points.length - this.AdditionalPointsCount();
    }

    public CopyPoints (): any[] {
        const copy = new Array(this.Points.length);
        for (let i = 0; i < this.Points.length; i++) {
            copy[i] = this.Points[i].slice();
        }
        return copy;
    }

    /// <summary>
    /// Сколько точек (максимально) может быть у тулзы
    /// </summary>
    public MaxPointsCount (): number {
        return this.Points.length;
    }

    // Минимально необходимое число точек для прорисовки тулзы
    public MinPointsCount (): number {
        return 1;
    }

    /// <summary>
    /// AdditionalPoints число точек для прорисовки тулзы - только для некоторых тулз
    /// </summary>
    public AdditionalPointsCount (): number {
        return 0;
    }

    /// <summary>
    /// Добавить новые точки к тулзе
    /// </summary>
    public AddMorePoints (count): void {
        if (count <= 0) {
            return;
        }

        const newPoints = new Array(this.Points.length + count);
        for (let i = 0; i < this.Points.length; i++) {
            newPoints[i] = [this.Points[i][0], this.Points[i][1]];
        }

        for (let i = this.Points.length; i < this.Points.length + count; i++) {
            newPoints[i] = [,];
        }

        this.Points = newPoints;
    }

    /// <summary>
    /// Удалить точки тулзы
    /// </summary>
    public RemoveLastPoints (count): void {
        if (count <= 0) { return; }

        const newPoints = new Array(this.Points.length - count);
        for (let i = 0; i < newPoints.length; i++) {
            newPoints[i] = [this.Points[i][0], this.Points[i][1]];
        }

        this.Points = newPoints;
    }

    public FireUpdate (): void {
        this.OnUpdate.Raise(this);
    }

    get FontColor (): any { return this.fontColor; }

    set FontColor (value) {
        if (this.fontColor === value) return;

        this.fontColor = value;
        this.fontBrush = new SolidBrush(this.fontColor);
        this.fontBrushHighlight = new SolidBrush(this.FontColorHighlight);
    }

    get FontColorHighlight (): string {
        return DataCacheTool.GetLighterColor(this.fontColor);
    }

    get FontBrush (): SolidBrush { return this.fontBrush; }

    get FontBrushHighlight (): SolidBrush { return this.fontBrushHighlight; }

    // Цвет тулзы
    get Color (): any { return this.color; }

    set Color (value) {
        this.color = value;

        this.UpdatePen();
        this.UpdatePenHighlight();
        this.UpdatePenRay();
    }

    get ColorHighlight (): string { return DataCacheTool.GetLighterColor(this.color); }

    // Ширина тулзы
    get Width (): any { return this.width; }

    set Width (value) {
        this.width = value;
        this.UpdatePen();
        this.UpdatePenRay();
        this.UpdatePenHighlight();
        this.penHalo.Width = this.width + 12;
    }

    get Style (): number {
        return this.style;
    }

    set Style (value) {
        this.style = value;
        this.UpdatePen();
        this.UpdatePenHighlight();
    }

    get StyleRay (): any {
        return this.styleRay;
    }

    set StyleRay (value) {
        this.styleRay = value;
        this.UpdatePenRay();
    }

    get Pen (): any { return this.pen; }

    get PenRay (): any { return this.penRay; }

    get PenHighlight (): any { return this.penHighlight; }

    get PenHalo (): any { return this.penHalo; }

    protected InitBlock (): void {
        this.pen = new Pen('');
        this.penRay = new Pen('');
        this.penHighlight = new Pen('');
        this.penHalo = new Pen('');
        this.penHalo.LineCap = 'round';

        this.ThemeChanged();

        this.Style = PenStyle.SimpleChart;
        this.StyleRay = PenStyle.ShapedChart;
        this.Width = 1;
        this.RayType = DataCacheToolRayType.NoRay;
        this.SelectionMode = SelectionModeEnum.All;

        // TODO. Remove.
        this.StickToPeriod = [];

        this.Localize();

        this.IsBackground = false;
    }

    public ThemeChanged (): void {
        this.Color = ThemeManager.CurrentTheme.Chart_ToolsDefaultColor;
        this.penHalo.Color = ThemeManager.CurrentTheme.Chart_ToolsSelectionColor;
        this.FontColor = ThemeManager.CurrentTheme.Chart_ToolsDefaultColor;
        this.font = ThemeManager.Fonts.Font_10F_regular.copy();
        this.colorAlpha = 128;
    }

    public Localize (): any {
    }

    public UpdatePen (): void {
        try {
            this.pen.Color = this.color;
            this.pen.Width = this.width;
            this.pen.DashStyle = this.style;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public UpdatePenRay (): void {
        try {
            this.penRay.Color = this.color;
            this.penRay.Width = this.width;
            this.penRay.DashStyle = this.styleRay;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public UpdatePenHighlight (): void {
        try {
            this.penHighlight.Color = this.ColorHighlight;
            this.penHighlight.Width = this.width;
            this.penHighlight.DashStyle = this.style;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public InitPoints (level, fromCallback = false): void {
        this.Points = new Array(level);
        for (let i = 0; i < this.Points.length; i++) {
            this.Points[i] = new Array(2);
        }

        if (!fromCallback) {
            this.addedPointsNum = 0;
        } // init started
    }

    // #region ICaller

    public Properties (): DynProperty[] {
        let prop = null;
        const properties = [];

        prop = new DynProperty('ToolType', this.ToolType, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP);
        properties.push(prop);

        prop = new DynProperty('WindowsNumber', this.WindowsNumber, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP);
        properties.push(prop);

        prop = new DynProperty('Stick to', this.stickTo, DynProperty.LINESTICK, DynProperty.HIDDEN_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.stickTo');
        properties.push(prop);

        // prop = new DynProperty("IsBackground", this.IsBackground, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        // prop.propertyComment = Resources.getResource("property.chart.isBackground");
        // properties.push(prop);

        const csw = new ColorStyleWidth(this.color, this.style, this.width);
        prop = new DynProperty('Line', csw, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP);
        prop.propertyComment = Resources.getResource('property.chart.line');
        properties.push(prop);

        // Hidden prop:
        properties.push(new DynProperty('AssignedSymbol', this.AssignedSymbol, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        const points = this.Points;
        if (points && this.Instrument) {
            properties.push(new DynProperty('Points.Length', points.length, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));

            const len = points.length - this.AdditionalPointsCount();
            for (let i = 0; i < len; i++) {
                properties.push(new DynProperty('X' + i, points[i][0], DynProperty.DATETIME, /* DynProperty.NUMERICAL_PARAMETRS_GROUP */DynProperty.HIDDEN_GROUP));

                prop = new DynProperty('Y' + i, points[i][1], DynProperty.DOUBLE, /* DynProperty.NUMERICAL_PARAMETRS_GROUP */DynProperty.HIDDEN_GROUP);
                prop.increment = this.Instrument.MinDelta,
                prop.decimalPlaces = this.Instrument.Precision;
                properties.push(prop);
            }
        }

        return properties;
    }

    public callBack (properties: DynProperty[]): void {
        let prop = DynProperty.getPropertyByName(properties, 'ToolType');
        if (prop) this.ToolType = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'WindowsNumber');
        if (prop) this.WindowsNumber = prop.value;

        // prop = DynProperty.getPropertyByName(properties, "IsBackground");
        // if (prop) this.IsBackground = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'Line');
        if (prop) {
            const colorStyleWidth = prop.value;
            this.Color = colorStyleWidth.Color;
            this.Style = colorStyleWidth.Style;
            this.Width = colorStyleWidth.Width;
        }

        prop = DynProperty.getPropertyByName(properties, 'Stick to');
        if (prop) this.StickTo = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'AssignedSymbol');
        if (prop) {
            this.AssignedSymbol = prop.value;
            this.Instrument = DataCache.getInstrumentByName(this.AssignedSymbol);
        }

        prop = DynProperty.getPropertyByName(properties, 'Points.Length');
        if (prop?.value !== null && prop.value !== undefined) {
            this.InitPoints(prop.value, true);

            const len = this.Points.length - this.AdditionalPointsCount();
            for (let i = 0; i < len; i++) {
                const point = this.Points[i];
                prop = DynProperty.getPropertyByName(properties, 'X' + i);
                if (prop) point[0] = prop.value;
                prop = DynProperty.getPropertyByName(properties, 'Y' + i);
                if (prop) point[1] = prop.value;
            }
        }
    }

    // #endregion

    /// /#region Misc

    public static GetRayType (properties): DataCacheToolRayType {
        let RayType = DataCacheToolRayType.NoRay;

        let left = false;
        let right = false;

        let prop = DynProperty.getPropertyByName(properties, 'Left ray');
        if (prop) left = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'Right ray');
        if (prop) right = prop.value;

        if (left && right) {
            RayType = DataCacheToolRayType.DoubleRay;
        } else if (left) {
            RayType = DataCacheToolRayType.LeftRay;
        } else if (right) {
            RayType = DataCacheToolRayType.RightRay;
        } else {
            RayType = DataCacheToolRayType.NoRay;
        }

        return RayType;
    }

    public static GetLighterColor (srcColor): string {
        const correctionFactor = 0.5;

        const channels = Color.getColorChannels(srcColor);

        const a = channels.a;
        const r = channels.r;
        const g = channels.g;
        const b = channels.b;

        const red = (255 - r) * correctionFactor + r;
        const green = (255 - g) * correctionFactor + g;
        const blue = (255 - b) * correctionFactor + b;

        return Color.CreateColor(
            Math.round(red),
            Math.round(green),
            Math.round(blue),
            a);
    }

    /// /#endregion

    public LoadSchemeMyProperties (): any {

    }
}
