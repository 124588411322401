// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Account } from '../../cache/Account';
import { type IBrokerConnectionAdapterHost, Side, type PlacedOrderBase, OrderType as tvOrderType, OrderStatus as tvOrderStatus, type OrderDuration, type PreOrder, type PlaceOrderResult, type Order as tvOrder, type BracketOrder, ParentType, type CustomInputFieldsValues, type Brackets, type PlacedOrder, type OrderOrPositionMessage, type IDestroyable, NotificationType } from '../charting_library';
import { type Order } from '../../cache/Order';
import { DataCache } from '../../DataCache';
import { OrderFormatter } from '../../cache/Formatters/OrderFormatter';
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { TIF } from '@shared/utils/Trading/OrderTif';
import { OrderTif } from '@shared/utils/Trading/OrderTifEnum';
import { OrderEditUpdateData } from '@shared/utils/Trading/OrderEditUpdateData';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { ProductType } from '@shared/utils/Instruments/ProductType';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { TvOrderConvertor } from '../Convertors/TvOrderConvertor';
import { DirectOpenOrderMessage } from '@shared/utils/DirectMessages/DirectOpenOrderMessage';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { SlTpHolder } from '@shared/utils/Trading/SlTpHolder';
import { SlTpPriceType } from '@shared/utils/Enums/Constants';
import { PositionEdit } from '../../cache/OrderParams/PositionEdit';
import { type Position } from '../../cache/Position';
import { TvSlTpCache, TvSlTpModificationType } from '../Caches/TvSlTpCache';
import { DirectOrderDialogMessage } from '@shared/utils/DirectMessages/DirectOrderDialogMessage';
import { LOCALE_EN, Resources } from '@shared/localizations/Resources';
import { OrderUtils } from '@shared/utils/Trading/OrderUtils';
import { OrderExecutionType } from '@shared/utils/Trading/OrderExecutionType';
import { TvDurationConvertor } from '../Convertors/TvDurationConvertor';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { type OrderEditBase } from '../../cache/OrderParams/order-edit/OrderEditBase';
import { DirectReportMessage } from '@shared/utils/DirectMessages/DirectReportMessage';

export class TvOrdersManager implements IDestroyable {
    private readonly host: IBrokerConnectionAdapterHost;

    constructor (host: IBrokerConnectionAdapterHost) {
        this.host = host;
        this.subscribeEvents();
    }

    public async getOrders (): Promise<PlacedOrderBase[]> {
        return await new Promise((resolve, reject) => {
            const rOrders = DataCache.getOrdersByAccount(TvAccountHelper.getCurrentAccount());
            let orders: PlacedOrderBase[] = [];
            for (const orderId in rOrders) {
                const rOrder = rOrders[orderId];
                if (!this.isOrderAllowed(rOrder)) continue;
                const tvOrders = this.convertToTvOrders(rOrder);
                if (tvOrders.length === 0) {
                    continue;
                }

                orders = [...orders, ...tvOrders];
            }

            resolve(orders);
        });
    }

    public async placeOrder (preOrder: PreOrder, confirmId?: string): Promise<PlaceOrderResult> {
        const orderEditBase = TvOrdersManager.createOrderEditBase(preOrder, TvAccountHelper.getCurrentAccount());
        const res = await DataCache.FOrderExecutor.placeOrderAsync(orderEditBase);
        let orderId: string | null = null;
        if (res instanceof DirectOpenOrderMessage) {
            const directOpenOrderMessage: DirectOpenOrderMessage = res;
            orderId = directOpenOrderMessage.OrderNumber;
        } else if (res instanceof DirectOrderDialogMessage) {
            const directOrderDialogMessage: DirectOrderDialogMessage = res;
            orderId = directOrderDialogMessage.OrderId;
        }

        if (!isNullOrUndefined(orderId)) {
            const correctResult: PlaceOrderResult = { orderId };
            return await Promise.resolve(correctResult);
        } else if (res instanceof DirectReportMessage) {
            const error = new Error(res.Data[0][1]);
            return await Promise.reject(error);
        } else {
            const incorrrectResult: PlaceOrderResult = {};
            return await Promise.resolve(incorrrectResult); // Promise.reject: TV dispays info: please contact your broker
        }
    }

    public async modifyOrder (tvOrder: tvOrder, confirmId?: string): Promise<void> {
        if (tvOrder.parentType === ParentType.Position &&
            !isNullOrUndefined(tvOrder.parentId)) {
            const position = DataCache.getPositionById(tvOrder.parentId);
            if (isNullOrUndefined(position)) {
                await Promise.reject(new Error(`position not found ${tvOrder.parentId}`));
                return;
            }
            if (!this.isValidPositionBrackets(position, tvOrder)) {
                await Promise.reject(new Error('Is not possible to modify Quantity and Tif for position bracket'));
                return;
            }

            await this.modifyPositionBracket(position, tvOrder);
            return;
        }

        const orderId = tvOrder.id;
        if (TvSlTpCache.isSlId(orderId)) {
            const rOrder = DataCache.getOrderById(tvOrder.parentId);
            if (isNullOrUndefined(rOrder)) {
                await Promise.reject(new Error(`order not found ${tvOrder.parentId}`));
                return;
            }

            if (!this.isValidOrderBrackets(rOrder, tvOrder)) {
                await Promise.reject(new Error('Is not possible to modify Quantity and Tif for order bracket'));
                return;
            }

            await this.modifySLOrder(rOrder, tvOrder as BracketOrder, confirmId);
            return;
        }

        if (TvSlTpCache.isTpId(orderId)) {
            const rOrder = DataCache.getOrderById(tvOrder.parentId);
            if (isNullOrUndefined(rOrder)) {
                await Promise.reject(new Error(`order not found ${tvOrder.parentId}`));
                return;
            }

            if (!this.isValidOrderBrackets(rOrder, tvOrder)) {
                await Promise.reject(new Error('Is not possible to modify Quantity and Tif for order bracket'));
                return;
            }

            await this.modifyTPOrder(tvOrder as BracketOrder, confirmId);
            return;
        }

        const rOrder = DataCache.getOrderById(tvOrder.id);
        if (isNullOrUndefined(rOrder)) {
            await Promise.resolve();
            return;
        }
        const orderEditBase = this.getOrderTypeObject(rOrder);
        const sltpHolder: SlTpHolder = new SlTpHolder(); // this.getSlTpHolder(rOrder);
        if (!isNullOrUndefined(tvOrder.takeProfit)) {
            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            sltpHolder.TakeProfitPriceValue = tvOrder.takeProfit;
        }
        if (!isNullOrUndefined(tvOrder.stopLoss)) {
            if (isValidNumber(tvOrder.trailingStopPips)) // StopType.TrailingStop
            {
                sltpHolder.StopLossPriceType = SlTpPriceType.TrOffset;
                sltpHolder.StopLossPriceValue = tvOrder.trailingStopPips;
            } else {
                sltpHolder.StopLossPriceType = SlTpPriceType.Absolute;
                sltpHolder.StopLossPriceValue = tvOrder.stopLoss;
            }
        }
        TvOrdersManager.fillOrderEditBase(orderEditBase, TvAccountHelper.getCurrentAccount(), tvOrder, sltpHolder);
        await DataCache.FOrderExecutor.modifyOrderPromise(orderEditBase);
    }

    public async cancelOrder (orderId: string): Promise<void> {
        if (TvSlTpCache.isSlId(orderId)) {
            await this.cancelSlOrder(orderId);
            return;
        }
        if (TvSlTpCache.isTpId(orderId)) {
            await this.cancelTpOrder(orderId);
            return;
        }

        await this.cancelOrders('', Side.Buy, [orderId], PlacedFrom.TV_TRADING_PLATFORM_OTHER);
    }

    public async cancelOrders (symbol: string, side: Side, ordersIds: string[], placedFrom: PlacedFrom): Promise<void> {
        await new Promise<void>((resolve, reject) => {
            // hsa: сюда заходят и SL и TP ордера
            // но не думаю что их нужно отменять отдельно, поэтому просто пропускаю
            const ordersWithouSlTp = ordersIds.filter(orderId => !TvSlTpCache.isSlId(orderId) && !TvSlTpCache.isTpId(orderId));
            const orderDict = DataCache.getOrdersById(ordersWithouSlTp);
            DataCache.FOrderExecutor.cancelSelectedOrders(orderDict, placedFrom);
            resolve();
        });
    }

    public async editPositionBrackets (positionId: string, brackets: Brackets, customFields?: CustomInputFieldsValues): Promise<void> {
        const position = DataCache.getPositionById(positionId);
        if (!position) {
            await Promise.reject(new Error(`position not found ${positionId}`));
            return;
        }
        const positionEdit = new PositionEdit({
            dataCache: position.DataCache,
            position,
            placedFrom: PlacedFrom.TV_TRADING_PLATFORM_OE
        });
        const hasSlTrailingStop = !isNullOrUndefined(brackets.trailingStopPips);
        const hasSl = !isNullOrUndefined(brackets.stopLoss);
        const hasTp = !isNullOrUndefined(brackets.takeProfit);

        const slTpHolder = new SlTpHolder();

        if (hasSlTrailingStop || hasSl) {
            if (hasSlTrailingStop) {
                slTpHolder.StopLossPriceType = SlTpPriceType.TrOffset;
                slTpHolder.StopLossPriceValue = brackets.trailingStopPips;
            } else {
                slTpHolder.StopLossPriceType = SlTpPriceType.Absolute;
                slTpHolder.StopLossPriceValue = brackets.stopLoss;
            }
        }

        if (hasTp) {
            slTpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            slTpHolder.TakeProfitPriceValue = brackets.takeProfit;
        }
        positionEdit.setSLTP(slTpHolder);
        await DataCache.FOrderExecutor.WMmodifyPositionPromise(positionEdit);
    }

    public updateOrders (): void {
        const rOrders = DataCache.getOrdersByAccount(TvAccountHelper.getCurrentAccount());
        for (const orderId in rOrders) {
            const rOrder = rOrders[orderId];
            if (!this.isOrderAllowed(rOrder)) continue;
            const tvOrders = this.convertToTvOrders(rOrder);
            for (const tvOrder of tvOrders) {
                this.orderPartialUpdate(rOrder, tvOrder);
            }
        }
    }

    private async modifyPositionBracket (position: Position, tvOrder: tvOrder): Promise<void> {
        const positionEdit = new PositionEdit({
            dataCache: position.DataCache,
            position,
            placedFrom: PlacedFrom.TV_TRADING_PLATFORM_OE
        });
        const sltpDynPropety = positionEdit.toDynProperty_sltp();

        const isTp = tvOrder.type === tvOrderType.Limit;
        if (isTp) {
            sltpDynPropety.tp.enabled = true;
            sltpDynPropety.tp.value = tvOrder.limitPrice;
        } else {
            const isTrailingStop = tvOrder.stopType === 1; // StopType.TrailingStop
            sltpDynPropety.sl.enabled = true;
            sltpDynPropety.trailingStop.enabled = isTrailingStop;
            if (isTrailingStop) {
                sltpDynPropety.trailingStop.value = tvOrder.trailingStopPips;
            } else {
                sltpDynPropety.sl.value = tvOrder.stopPrice;
            }
        }

        positionEdit.updateParameters(new OrderEditUpdateData({ sltp: sltpDynPropety }));

        await DataCache.FOrderExecutor.WMmodifyPositionPromise(positionEdit);
    }

    private getSymbol (order: Order): string {
        // const symbol = order.Instrument.DisplayName();
        // return TvInteriorIdCache.getInteriorId(symbol);
        return TvSymbolConvertor.getFullname(order.Instrument);
    }

    public getTvOrderType (rOrder: Order): tvOrderType {
        const rOrderType = OrderFormatter.OrderType(rOrder);
        return TvOrderConvertor.getTvOrderType(rOrderType);
    }

    private getTvOrderStatus (rOrder: Order): tvOrderStatus {
        const rOrderStatus = OrderFormatter.OrderStatus(rOrder);
        return TvOrderConvertor.getTvOrderStatus(rOrderStatus);
    }

    private getTvOrderSide (rOrder: Order): Side {
        return TvOrderConvertor.getTvOrderSide(rOrder.BuySell);
    }

    private InvertSide (side: Side): Side {
        return side === Side.Buy ? Side.Sell : Side.Buy;
    }

    private getTvOrderDuration (order: Order): OrderDuration {
        const tif = order.TimeInForce;
        const expiration = order.ExpireAt;

        return TvOrderConvertor.getTvOrderDuration(tif, expiration);
    }

    private convertToTvOrders (rOrder: Order): PlacedOrderBase[] {
        if (rOrder.isClosingOrder()) {
            const position = DataCache.getPositionById(rOrder.PositionId);
            if (isNullOrUndefined(position)) {
                return [];
            }

            const rOrderType = rOrder.OrderType;
            const isTP = rOrderType === OrderType.Limit;
            if (isTP && !isNullOrUndefined(position.TPOrder)) {
                const tvTpOrder = this.createTpBracketForPosition(position);
                return [tvTpOrder];
            }

            const isSL = rOrderType === OrderType.Stop ||
            rOrderType === OrderType.StopLimit ||
            rOrderType === OrderType.TrailingStop;
            if (isSL && !isNullOrUndefined(position.SLOrder)) {
                const tvSlOrder = this.createSlBracketForPosition(position);
                return [tvSlOrder];
            }
        }

        const rOrderType = OrderFormatter.OrderType(rOrder);

        const tvOrder: PlacedOrderBase = {
            id: rOrder.OrderNumber,
            symbol: this.getSymbol(rOrder),
            type: this.getTvOrderType(rOrder),
            side: this.getTvOrderSide(rOrder),
            qty: OrderFormatter.Amount(rOrder),
            status: this.getTvOrderStatus(rOrder),
            updateTime: OrderFormatter.DateTime(rOrder).getTime(),
            filledQty: OrderFormatter.QuantityFilled(rOrder),
            avgPrice: OrderFormatter.QuantityAverageFilledPrice(rOrder),
            duration: this.getTvOrderDuration(rOrder)
        };

        if (rOrderType === OrderType.Market) {
            tvOrder.limitPrice = OrderFormatter.Price(rOrder);
        } else if (rOrderType === OrderType.Limit || rOrderType === OrderType.SLTPLimit) {
            tvOrder.limitPrice = OrderFormatter.Price(rOrder);
        } else if (rOrderType === OrderType.Stop || rOrderType === OrderType.SLTPStop) {
            tvOrder.stopPrice = OrderFormatter.Price(rOrder);
            tvOrder.stopType = 0; // StopType.Stop
        } else if (rOrderType === OrderType.StopLimit) {
            tvOrder.limitPrice = OrderFormatter.Price(rOrder);
            tvOrder.stopPrice = OrderFormatter.StopPrice(rOrder);
        } else if (rOrderType === OrderType.TRStop) {
            tvOrder.stopPrice = OrderFormatter.Price(rOrder); // TODO
            tvOrder.stopType = 1; // StopType.TrailingStop
        }

        const tvOrderSl = this.createStopLossBracket(rOrder);
        const tvOrderTp = this.createTakeProfitBracket(rOrder);

        const tvOrders: PlacedOrderBase[] = [];
        tvOrders.push(tvOrder);
        if (tvOrderSl !== null) {
            if (!isNullOrUndefined(tvOrderSl.stopPrice)) {
                tvOrder.stopLoss = tvOrderSl.stopPrice;
            }
            if (!isNullOrUndefined(tvOrderSl.trailingStopPips)) {
                tvOrder.trailingStopPips = tvOrderSl.trailingStopPips;
            }

            const isTrailingStop = OrderFormatter.IsStopLossTrailingStop(rOrder);
            let slModType = TvSlTpModificationType.WithoutChanges;
            if (isTrailingStop) {
                slModType = TvSlTpCache.insertSlTrailingStopPrice(rOrder.OrderNumber, tvOrder.stopLoss, tvOrder.trailingStopPips);
            } else {
                slModType = TvSlTpCache.insertSlPrice(rOrder.OrderNumber, tvOrder.stopLoss);
            }
            // if (slModType === TvSlTpModificationType.New || slModType === TvSlTpModificationType.ModifyOrder) {
            tvOrders.push(tvOrderSl);
            // } else if (slModType === TvSlTpModificationType.Replace) {
            //    throw new Error('TvSlTpModificationType.Replace');
            // TODO: cancel prev order and place new?
            // }
        }
        if (tvOrderTp !== null) {
            tvOrder.takeProfit = tvOrderTp.limitPrice;
            const tpModType = TvSlTpCache.insertTpPrice(rOrder.OrderNumber, tvOrder.takeProfit);
            // if (tpModType === TvSlTpModificationType.New || tpModType === TvSlTpModificationType.ModifyOrder) {
            tvOrders.push(tvOrderTp);
            // }
        }

        if (tvOrder.status === tvOrderStatus.Rejected) {
            const msg: OrderOrPositionMessage = {
                type: 'error' as OrderOrPositionMessageType,
                text: rOrder.Comment
            };
            for (const tvOrder of tvOrders) {
                tvOrder.message = msg;
            }
        }

        return tvOrders;
    }

    private subscribeEvents (): void {
        DataCache.OnAddOrder.Subscribe(this.onAddOrUpdateOrderEvent, this);
        DataCache.OnUpdateOrder.Subscribe(this.onAddOrUpdateOrderEvent, this);
        DataCache.OnRemoveOrder.Subscribe(this.onRemoveOrderEvent, this);

        DataCache.OnAddSLOrderToPosition.Subscribe(this.onAddSLOrderToPositionEvent, this);
        DataCache.OnAddTPOrderToPosition.Subscribe(this.onAddTPOrderToPositionEvent, this);
        DataCache.OnRemoveSLOrderFromPosition.Subscribe(this.onRemoveSLOrderFromPositionEvent, this);
        DataCache.OnRemoveTPOrderFromPosition.Subscribe(this.onRremoveTPOrderFromPositionEvent, this);
    }

    private unsubscribeEvents (): void {
        DataCache.OnAddOrder.UnSubscribe(this.onAddOrUpdateOrderEvent, this);
        DataCache.OnUpdateOrder.UnSubscribe(this.onAddOrUpdateOrderEvent, this);
        DataCache.OnRemoveOrder.UnSubscribe(this.onRemoveOrderEvent, this);

        DataCache.OnAddSLOrderToPosition.UnSubscribe(this.onAddSLOrderToPositionEvent, this);
        DataCache.OnAddTPOrderToPosition.UnSubscribe(this.onAddTPOrderToPositionEvent, this);
        DataCache.OnRemoveSLOrderFromPosition.UnSubscribe(this.onRemoveSLOrderFromPositionEvent, this);
        DataCache.OnRemoveTPOrderFromPosition.UnSubscribe(this.onRremoveTPOrderFromPositionEvent, this);
    }

    private onAddOrUpdateOrderEvent (order: Order): void {
        if (!this.isOrderAllowed(order)) return;

        this.notifyIfOrderActivated(order);

        const tvOrders = this.convertToTvOrders(order);

        if (tvOrders.length === 0) return;

        let hasSl = false;
        let hasTp = false;

        for (const tvOrder of tvOrders) {
            if (!hasSl) { hasSl = TvSlTpCache.isSlId(tvOrder.id); }
            if (!hasTp) { hasTp = TvSlTpCache.isTpId(tvOrder.id); }
        }

        const orderId = order.OrderNumber;

        if (!hasSl) {
            // могли удалить sl или sl tr stop, проверяем в кэше

            const slId = TvSlTpCache.getSlId(orderId, false);
            this.tryToRemoveDeletedSlOrder(order, slId, false);

            const slTrStopId = TvSlTpCache.getSlId(orderId, true);
            this.tryToRemoveDeletedSlOrder(order, slTrStopId, true);
        } else {
            // могли сменить тип ордера на sl или sl tr stop или наоборот, проверяем
            // hsa: нельзя так делать в этом случае платформа не ловит второй ордер
            // const isTrailingStop = OrderFormatter.IsStopLossTrailingStop(order);
            // const oppositeTrailingStop = !isTrailingStop;
            // const oppositeSlId = this.getSlId(orderId, oppositeTrailingStop);
            // this.tryToRemoveDeletedSlOrder(order, oppositeSlId, oppositeTrailingStop);
        }

        if (!hasTp) {
            if (TvSlTpCache.hasTpPrice(orderId)) {
                const tpId = TvSlTpCache.getTpId(orderId);
                const tpPrice = TvSlTpCache.getTpPrice(orderId);
                const fakeTp = this.createTpBracketWithoutTp(order, tpId, tpPrice);
                this.cancelTvOrder(order, fakeTp);
            }
        }

        for (const tvOrder of tvOrders) {
            this.orderUpdate(order, tvOrder);
        }
    }

    private onRemoveOrderEvent (order: Order): void {
        if (!this.isOrderAllowed(order)) return;

        const tvOrders = this.convertToTvOrders(order);
        for (const tvOrder of tvOrders) {
            this.cancelTvOrder(order, tvOrder);
        }
    }

    private onAddSLOrderToPositionEvent (position: Position): void {
        if (!this.isPositionAllowed(position)) return;
        const tvSlOrder = this.createSlBracketForPosition(position);
        this.orderUpdate(position.SLOrder, tvSlOrder);
    }

    private onAddTPOrderToPositionEvent (position: Position): void {
        if (!this.isPositionAllowed(position)) return;
        const tvTpOrder = this.createTpBracketForPosition(position);
        this.orderUpdate(position.TPOrder, tvTpOrder);
    }

    private onRemoveSLOrderFromPositionEvent (position: Position): void {
        if (!this.isPositionAllowed(position)) return;
        const tvSlOrder = this.createSlBracketForPosition(position);
        this.cancelTvOrder(position.SLOrder, tvSlOrder);
    }

    private onRremoveTPOrderFromPositionEvent (position: Position): void {
        if (!this.isPositionAllowed(position)) return;
        const tvTpOrder = this.createTpBracketForPosition(position);
        this.cancelTvOrder(position.TPOrder, tvTpOrder);
    }

    private createSlBracketForPosition (position: Position): BracketOrder {
        const rSlOrder = position.SLOrder;
        const slOrder = this.createBaseBracketForPosition(position);
        slOrder.id = rSlOrder.OrderNumber;
        slOrder.type = tvOrderType.Stop;
        slOrder.status = this.getTvOrderStatus(rSlOrder);
        slOrder.duration = this.getTvOrderDuration(rSlOrder);
        slOrder.qty = OrderFormatter.Amount(rSlOrder);
        slOrder.filledQty = OrderFormatter.QuantityFilled(rSlOrder);

        const isTrailingStop = rSlOrder.OrderType === OrderType.TrailingStop;
        const slPrice = rSlOrder.Price;

        if (isTrailingStop) {
            slOrder.stopType = 1; // StopType.TrailingStop
            slOrder.stopPrice = slPrice;

            const posOpenPrice = position.Price; // position.CurPriceClose;
            const delta = slPrice - posOpenPrice;
            const tiks = position.Instrument.CalculateTicks(posOpenPrice, delta, true);
            slOrder.trailingStopPips = tiks;
        } else {
            slOrder.stopPrice = slPrice;
        }
        return slOrder;
    }

    private createTpBracketForPosition (position: Position): BracketOrder {
        const rTpOrder = position.TPOrder;
        const tpOrder = this.createBaseBracketForPosition(position);
        tpOrder.id = rTpOrder.OrderNumber;
        tpOrder.type = tvOrderType.Limit;
        tpOrder.status = this.getTvOrderStatus(rTpOrder);
        tpOrder.limitPrice = rTpOrder.Price;
        tpOrder.duration = this.getTvOrderDuration(rTpOrder);
        tpOrder.qty = OrderFormatter.Amount(rTpOrder);
        tpOrder.filledQty = OrderFormatter.QuantityFilled(rTpOrder);
        return tpOrder;
    }

    private tryToRemoveDeletedSlOrder (order: Order, slId: string, isTrailingStop: boolean): void {
        const orderId = order.OrderNumber;
        if (TvSlTpCache.hasSlPrice(orderId)) {
            let slPrice = NaN;
            let slTrOfset = NaN;
            if (isTrailingStop) {
                [slPrice, slTrOfset] = TvSlTpCache.getSlTrailingStopPrices(order.OrderNumber);
            } else {
                slPrice = TvSlTpCache.getSlPrice(order.OrderNumber);
            }
            const fakeSl = this.createSlBracketWithoutSl(order, slId, isTrailingStop, slPrice, slTrOfset);
            this.cancelTvOrder(order, fakeSl);
        }
    }

    private cancelTvOrder (rOrder: Order, tvOrder: tvOrder): void {
        const status = this.getTvOrderStatus(rOrder);
        if (status === tvOrderStatus.Filled) {
            tvOrder.status = tvOrderStatus.Filled;
        } else if (status === tvOrderStatus.Rejected) {
            tvOrder.status = tvOrderStatus.Rejected;
        } else {
            tvOrder.status = tvOrderStatus.Canceled;
        }
        this.orderUpdate(rOrder, tvOrder);
        const orderId = tvOrder.id;
        if (TvSlTpCache.isSlId(orderId)) {
            TvSlTpCache.removeSl(orderId);
        } else if (TvSlTpCache.isTpId(orderId)) {
            TvSlTpCache.removeTp(orderId);
        }
    }

    private createTakeProfitBracket (rOrder: Order): BracketOrder | null {
        if (!OrderFormatter.HasTakeProfitPrice(rOrder)) {
            return null;
        }
        const id = TvSlTpCache.getTpId(rOrder.OrderNumber);
        const limitPrice = OrderFormatter.TakeProfitPrice(rOrder);
        return this.createTpBracketWithoutTp(rOrder, id, limitPrice);
    }

    private createTpBracketWithoutTp (rOrder: Order, tpId: string, tpPrice: number): BracketOrder | null {
        const tpOrder = this.createBaseBracketForOrder(rOrder);
        tpOrder.id = tpId;
        tpOrder.type = tvOrderType.Limit;
        tpOrder.limitPrice = tpPrice;

        return tpOrder;
    }

    private createStopLossBracket (rOrder: Order): BracketOrder | null {
        if (!OrderFormatter.HasStopLossPrice(rOrder)) {
            return null;
        }

        const isTrailingStop = OrderFormatter.IsStopLossTrailingStop(rOrder);
        const id = TvSlTpCache.getSlId(rOrder.OrderNumber, isTrailingStop);

        const price: number = OrderFormatter.StopLossPrice(rOrder);
        let offset = NaN;

        if (isTrailingStop) {
            offset = rOrder.StopLossPriceValue;
        }

        return this.createSlBracketWithoutSl(rOrder, id, isTrailingStop, price, offset);
    }

    private createSlBracketWithoutSl (rOrder: Order,
        slId: string,
        isTrailingStop: boolean,
        slPrice: number,
        slOffset: number = NaN): BracketOrder | null {
        const slOrder = this.createBaseBracketForOrder(rOrder);
        slOrder.id = slId;
        slOrder.type = tvOrderType.Stop;

        if (isTrailingStop) {
            slOrder.stopType = 1; // StopType.TrailingStop
            slOrder.trailingStopPips = slOffset;
            slOrder.stopPrice = slPrice;
        } else {
            slOrder.stopPrice = slPrice;
        }

        return slOrder;
    }

    private createBaseBracketForOrder (rOrder: Order): BracketOrder {
        let tvOrdStatus = this.getTvOrderStatus(rOrder);
        if (tvOrdStatus !== tvOrderStatus.Filled && tvOrdStatus !== tvOrderStatus.Rejected) {
            tvOrdStatus = tvOrderStatus.Inactive;
        }
        const bracketOrder: BracketOrder = {
            parentId: rOrder.OrderNumber,
            parentType: ParentType.Order,
            id: rOrder.OrderNumber,
            symbol: this.getSymbol(rOrder),
            type: this.getTvOrderType(rOrder),
            side: this.InvertSide(this.getTvOrderSide(rOrder)),
            qty: OrderFormatter.Amount(rOrder),
            status: tvOrdStatus,
            duration: this.getTvOrderDuration(rOrder)
        };

        return bracketOrder;
    }

    private createBaseBracketForPosition (position: Position): BracketOrder {
        const bracketOrder: BracketOrder = {
            parentId: position.PositionId,
            parentType: ParentType.Position,
            id: position.PositionId,
            symbol: this.getSymbol(position),
            type: tvOrderType.Market,
            side: this.InvertSide(this.getTvOrderSide(position)),
            qty: position.Amount,
            status: tvOrderStatus.Inactive
        };

        return bracketOrder;
    }

    private async modifySLOrder (rOrder: Order, slOrder: BracketOrder, confirmId?: string): Promise<void> {
        const orderEditBase = this.getOrderTypeObject(rOrder);
        const slTpHolder = this.getSlTpHolder(rOrder);

        const isTrailingStop = slOrder.stopType === 1; // StopType.TrailingStop
        if (isTrailingStop) {
            TvOrdersManager.setSlTrailingStopPrice(slTpHolder, slOrder.trailingStopPips);
        } else {
            TvOrdersManager.setSlPrice(slTpHolder, slOrder.stopPrice);
        }

        orderEditBase.setSLTP(slTpHolder);
        try {
            await DataCache.FOrderExecutor.modifyOrderPromise(orderEditBase);
        } catch (error) {
            const slError = orderEditBase.sltp.sl.error?.toString();
            const rejectedError = isValidString(slError) ? new Error(slError) : error;
            await Promise.reject(rejectedError);
        }
    }

    private async modifyTPOrder (tpOrder: BracketOrder, confirmId?: string): Promise<void> {
        const rOrder = DataCache.getOrderById(tpOrder.parentId);
        if (isNullOrUndefined(rOrder)) {
            return;
        }
        const orderEditBase = this.getOrderTypeObject(rOrder);
        const slTpHolder = this.getSlTpHolder(rOrder);
        TvOrdersManager.setTpPrice(slTpHolder, tpOrder.limitPrice);
        orderEditBase.setSLTP(slTpHolder);
        try {
            await DataCache.FOrderExecutor.modifyOrderPromise(orderEditBase);
        } catch (error) {
            const tpError = orderEditBase.sltp.tp.error?.toString();
            const rejectedError = isValidString(tpError) ? new Error(tpError) : error;
            await Promise.reject(rejectedError);
        }
    }

    private async cancelSlOrder (orderId: string): Promise<void> {
        await new Promise<void>((resolve, reject) => {
            const rOrderId = TvSlTpCache.removeSlSuffix(orderId);
            const rOrder = DataCache.getOrderById(rOrderId);
            if (!rOrder) {
                return;
            }
            const fakeSl = this.createStopLossBracket(rOrder);
            const orderEditBase = this.getOrderTypeObject(rOrder);
            const slTpHolder = this.getSlTpHolder(rOrder);
            this.removeSlPrice(slTpHolder);
            orderEditBase.setSLTP(slTpHolder);
            void DataCache.FOrderExecutor.modifyOrderPromise(orderEditBase);
            this.cancelTvOrder(rOrder, fakeSl); // TODO Check reject
            resolve();
        });
    }

    private async cancelTpOrder (orderId: string): Promise<void> {
        await new Promise<void>((resolve, reject) => {
            const rOrderId = TvSlTpCache.removeTpSuffix(orderId);
            const rOrder = DataCache.getOrderById(rOrderId);
            if (!rOrder) {
                return;
            }
            const fakeTp = this.createTakeProfitBracket(rOrder);
            const orderEditBase = this.getOrderTypeObject(rOrder);
            const slTpHolder = this.getSlTpHolder(rOrder);
            this.removeTpPrice(slTpHolder);
            orderEditBase.setSLTP(slTpHolder);
            void DataCache.FOrderExecutor.modifyOrderPromise(orderEditBase);
            this.cancelTvOrder(rOrder, fakeTp); // TODO Check reject
            resolve();
        });
    }

    private getOrderTypeObject (order: Order): OrderEditBase {
        const orderTypeObj = DataCache.OrderParameterContainer.GetOrderType(order.OrderType);
        const orderEdit = orderTypeObj.createModifyOrderObject({
            dataCache: DataCache,
            order
        });

        orderEdit.placedFrom = PlacedFrom.TV_TRADING_PLATFORM_OTHER;

        return orderEdit;
    }

    public static createOrderEditBase (preOrder: PreOrder, account: Account): any {
        const orderType = TvOrderConvertor.getRealOrderType(preOrder.type);
        const type = DataCache.OrderParameterContainer.GetOrderType(orderType);
        const orderEditBase = type.createOrderEditObject({ dataCache: DataCache });
        TvOrdersManager.fillOrderEditBase(orderEditBase, account, preOrder, new SlTpHolder());
        return orderEditBase;
    }

    private static fillOrderEditBase (orderEditBase: any,
        account: Account,
        preModOrder: PreOrder | tvOrder,
        sltpHolder: SlTpHolder): void {
        const side = preModOrder.side === Side.Buy ? OperationType.Buy : OperationType.Sell;
        const orderEditData = TvOrdersManager.getOrderEditData(account, preModOrder, side);
        orderEditBase.updateParameters(new OrderEditUpdateData(null, orderEditData));

        if (preModOrder.takeProfit) {
            TvOrdersManager.setTpPrice(sltpHolder, preModOrder.takeProfit);
        }

        if (preModOrder.trailingStopPips) {
            TvOrdersManager.setSlTrailingStopPrice(sltpHolder, preModOrder.trailingStopPips);
        } else if (preModOrder.stopLoss) {
            TvOrdersManager.setSlPrice(sltpHolder, preModOrder.stopLoss);
        }

        if (preModOrder.limitPrice) orderEditBase.setBasePrice(preModOrder.limitPrice, true);
        if (preModOrder.stopPrice) orderEditBase.setStopPrice(preModOrder.stopPrice, false);
        orderEditBase.setSLTP(sltpHolder);
        orderEditBase.placedFrom = PlacedFrom.TV_TRADING_PLATFORM_OE;
    }

    private static getOrderEditData (account: Account,
        preModOrder: PreOrder | tvOrder,
        side: OperationType): any {
        const interiorId = TvInteriorIdCache.getInteriorId(preModOrder.symbol);
        const rInstrument = DataCache.getInstrumentByName(interiorId);

        const tifId = OrderTif[preModOrder.duration.type];
        const tif = new TIF(tifId);
        const gtdExpirationTime = preModOrder.duration.datetime;
        if (!isNullOrUndefined(gtdExpirationTime)) {
            tif.expirationTime = new Date(gtdExpirationTime);
        }

        const orderEditData = {
            account,
            instrument: rInstrument,
            side,
            quantity: new Quantity(preModOrder.qty, true),
            tif,
            productType: ProductType.General
        };

        return orderEditData;
    }

    private getSlTpHolder (rOrder: Order): SlTpHolder {
        const slTpHolder = new SlTpHolder();
        if (OrderFormatter.HasStopLossPrice(rOrder)) {
            if (OrderFormatter.IsStopLossTrailingStop(rOrder)) {
                slTpHolder.StopLossPriceType = SlTpPriceType.TrOffset;
                slTpHolder.StopLossPriceValue = rOrder.StopLossPriceValue;
            } else {
                slTpHolder.StopLossPriceType = SlTpPriceType.Absolute;
                slTpHolder.StopLossPriceValue = OrderFormatter.StopLossPrice(rOrder);
            }
        }

        if (OrderFormatter.HasTakeProfitPrice(rOrder)) {
            slTpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            slTpHolder.TakeProfitPriceValue = OrderFormatter.TakeProfitPrice(rOrder);
        }
        return slTpHolder;
    }

    private static setSlPrice (sltpHolder: SlTpHolder, price: number): void {
        sltpHolder.StopLossPriceType = SlTpPriceType.Absolute;
        sltpHolder.StopLossPriceValue = price;
    }

    private static setTpPrice (sltpHolder: SlTpHolder, price: number): void {
        sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
        sltpHolder.TakeProfitPriceValue = price;
    }

    private static setSlTrailingStopPrice (sltpHolder: SlTpHolder, ticks: number): void {
        sltpHolder.StopLossPriceType = SlTpPriceType.TrOffset;
        sltpHolder.StopLossPriceValue = ticks;
    }

    private removeSlPrice (sltpHolder: SlTpHolder): void {
        TvOrdersManager.setSlPrice(sltpHolder, NaN);
    }

    private removeTpPrice (sltpHolder: SlTpHolder): void {
        TvOrdersManager.setTpPrice(sltpHolder, NaN);
    }

    private orderUpdate (rOrder: Order, tvOrder: PlacedOrderBase): void {
        this.host.orderUpdate(tvOrder);
        this.orderPartialUpdate(rOrder, tvOrder);
    }

    private orderPartialUpdate (rOrder: Order, tvOrder: PlacedOrder): void {
        const bracket = tvOrder as BracketOrder; /// ???
        if (isNullOrUndefined(bracket)) {
            return;
        }

        const isSlOrTp = TvSlTpCache.isSlId(tvOrder.id) || TvSlTpCache.isTpId(tvOrder.id);

        const qtyRem = OrderFormatter.QuantityRemaining(rOrder);
        tvOrder.qtyrem = qtyRem > 0 ? qtyRem : 0;
        tvOrder.last = OrderFormatter.CurrentPrice(rOrder);

        tvOrder.tif = OrderFormatter.ValidityStr(rOrder);

        if (!isSlOrTp && OrderFormatter.HasStopLossPrice(rOrder)) {
            tvOrder.stopLoss = OrderFormatter.StopLossPrice(rOrder);
        }

        if (!isSlOrTp && OrderFormatter.HasTakeProfitPrice(rOrder)) {
            tvOrder.takeProfit = OrderFormatter.TakeProfitPrice(rOrder);
        }

        tvOrder.filledQty = OrderFormatter.QuantityFilled(rOrder);

        const lastUpdateTime = Math.max(rOrder.LastUpdateTime?.getTime() ?? 0, rOrder.UTCDateTime.getTime());
        tvOrder.date = { dateOrDateTime: lastUpdateTime, hasTime: true };

        this.host.orderPartialUpdate(tvOrder.id, tvOrder);
    }

    private notifyIfOrderActivated (rOrder: Order): void {
        if (rOrder.ExecutionType !== OrderExecutionType.ACTIVATED) return;

        const ordTypeStr = Resources.getResourceLang(`property.${OrderUtils.getOrderTypeLocalizationKey(rOrder.OrderType)}`, LOCALE_EN);
        const header = Resources.getResource(`${ordTypeStr} order activated`);
        const text = `${header} ${rOrder.OrderNumber}`;
        this.host.showNotification(header, text, NotificationType.Success);
    }

    private isOrderAllowed (rOrder: Order): boolean {
        if (rOrder.Account !== TvAccountHelper.getCurrentAccount()) return false;
        return TvDurationConvertor.getAllAlowedOrderTypes().includes(rOrder.OrderType);
    }

    private isPositionAllowed (position: Position): boolean {
        return position.Account === TvAccountHelper.getCurrentAccount();
    }

    private isValidPositionBrackets (position: Position, tvOrder: tvOrder): boolean {
        const isTp = tvOrder.type === tvOrderType.Limit;
        const rOrder = isTp ? position.TPOrder : position.SLOrder;
        if (isNullOrUndefined(rOrder)) {
            console.warn(`Position bracket not found for PositionID: ${position.PositionId} Bracket OrderID: ${tvOrder.id}`);
            return false;
        }

        const duration = this.getTvOrderDuration(rOrder);
        const qty = OrderFormatter.Amount(rOrder);

        if (tvOrder.qty !== qty) return false;
        if (tvOrder.duration.type !== duration.type) return false;
        if (tvOrder.duration.datetime !== duration.datetime) return false;

        return true;
    }

    private isValidOrderBrackets (rOrder: Order, tvOrder: tvOrder): boolean {
        const duration = this.getTvOrderDuration(rOrder);
        const qty = OrderFormatter.Amount(rOrder);

        if (tvOrder.qty !== qty) return false;
        if (tvOrder.duration.type !== duration.type) return false;
        if (tvOrder.duration.datetime !== duration.datetime) return false;

        return true;
    }

    // #region IDestroyable

    destroy (): void {
        this.unsubscribeEvents();
    }
    // #endregion IDestroyable
}

// There is no public access to this enum in TV API
enum OrderOrPositionMessageType {
    Information = 'information',
    Warning = 'warning',
    Error = 'error'
}
