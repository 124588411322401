// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { BaseItem, ColumnData } from '../BaseItem';
import { ColumnParams } from '../ColumnParams';
import { QuickTableEditingInfo } from '../../elements/QuickTable/QuickTableMisc';
import { DynProperty } from '@shared/commons/DynProperty';
import { TerceraChartActionProcessor } from '@front/chart/TerceraChartActionProcessor/TerceraChartActionProcessor';
import { type DataCacheTool } from '@shared/commons/cache/DataCacheTool';
import { type IObjectManagerItem } from '@shared/commons/cache/interface/IObjectManagerItem';

export class DrawingsManagerItem extends BaseItem implements IObjectManagerItem<DataCacheTool> {
    public readonly Tool: DataCacheTool;

    constructor (tool: DataCacheTool) {
        super();
        this.Tool = tool;

        this.QuickTableEditingInfoMap[DrawingsManagerItem.SHOWONCHART_COL_INDEX] = new QuickTableEditingInfo(DynProperty.SHOW_ON_CHART_BUTTON);

        const settingsButton = new QuickTableEditingInfo(DynProperty.SETTINGS_BUTTON);
        settingsButton.TooltipKey = 'ObjectManagerItem.Settings.ToolTip';
        this.QuickTableEditingInfoMap[DrawingsManagerItem.SETTINGS_COL_INDEX] = settingsButton;

        const closeButton = new QuickTableEditingInfo(DynProperty.CLOSE_BUTTON);
        closeButton.TooltipKey = 'ObjectManagerItem.Remove.ToolTip';
        this.QuickTableEditingInfoMap[DrawingsManagerItem.REMOVE_COL_INDEX] = closeButton;
    }

    public getItemSubject (): DataCacheTool {
        return this.Tool;
    }

    public override ColumnCount (): number {
        return DrawingsManagerItem.columnsParams.length;
    }

    public override GetColumnParams (i: number): ColumnParams {
        return DrawingsManagerItem.columnsParams[i];
    }

    public GetMenuIndex (headerKey: string): number {
        if (DrawingsManagerItem.ListMenuIndex[headerKey]) {
            return DrawingsManagerItem.ListMenuIndex[headerKey];
        } else {
            return 1000;
        }
    }

    public override getColumnValue (column: number): any {
        const toolStateInfoDict = TerceraChartActionProcessor.ToolStateInfoDict;
        const toolType = this.Tool.ToolType;

        switch (column) {
        case DrawingsManagerItem.NAME_COL_INDEX:
            return Resources.getResource(toolStateInfoDict[toolType].locKey);
        case DrawingsManagerItem.WINDOW_COL_INDEX:
            if (this.Tool.WindowsNumber === 0) {
                return Resources.getResource('ChartObjectManager.mainWindow');
            } else {
                return `${Resources.getResource('ChartObjectManager.subWindow')} ${this.Tool.WindowsNumber}`;
            }
        default:
            return '';
        }
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let FormattedValue = null;

        switch (column) {
        case DrawingsManagerItem.NAME_COL_INDEX:
            FormattedValue = value;
            break;
        case DrawingsManagerItem.WINDOW_COL_INDEX:
            FormattedValue = value;
            break;
        }

        return new ColumnData(value, FormattedValue);
    }

    static NAME_COL_INDEX = 0;
    static WINDOW_COL_INDEX = 1;
    static SHOWONCHART_COL_INDEX = 2;
    static SETTINGS_COL_INDEX = 3;
    static REMOVE_COL_INDEX = 4;

    static columnsParams =
        [
            new ColumnParams('ObjectManagerItem.Name', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Window', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Show on chart', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Settings', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Remove', 70, 0, false, true, false)
        ];

    public static readonly ListMenuIndex = [
        'ObjectManagerItem.Name',
        'ObjectManagerItem.Window',
        'ObjectManagerItem.Show on chart',
        'ObjectManagerItem.Settings',
        'ObjectManagerItem.Remove'];
}
