// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { WorkSpaceUserStorageType } from '../../UtilsClasses/WorkSpaceUserKey';
import { UserWebStorageInstance } from '../../../user-web-storage';
import { DataBaseWorkingClass } from '../../DataBase/old/DataBaseWorkingClass';
import { type TvSettingsData } from '../Settings/TvSettingsData';
import { UserManager } from '../../../Utils/Managers/UserManager'; ;

class _TvWorkspaceProvider {
    private hash: string;
    private readonly indexedDBProvider: DataBaseWorkingClass;
    private readonly dbName: string = 'TvDb';
    private readonly tableName: string = 'TvWorkSpaces';
    private readonly userStorageKey: string = 'id';

    constructor () {
        this.indexedDBProvider = new DataBaseWorkingClass(this.dbName);
    }

    public async reinitDatabase (): Promise<any> {
        this.indexedDBProvider.CloseDB();
    }

    public async getWorkSpace (): Promise<any> {
        let localTime = 0;
        await this.openLocalDataBase();
        const request = await this.indexedDBProvider.GetFromDB(this.hash, this.tableName);
        const localData = request.result as TvSettingsData;
        if (!isNullOrUndefined(localData)) {
            localTime = localData.time;
        }

        let serverTime = 0;

        let serverData: TvSettingsData = null;
        try {
            const serverResponse = await UserWebStorageInstance.tvWorkspaces.get(this.hash);
            serverData = serverResponse?.data as TvSettingsData;
            if (!isNullOrUndefined(serverData)) {
                serverTime = serverData.time;
            }
        } catch (e) {
            console.warn('Error getting workspace from server storage', e);
        }

        if (localTime === 0 && serverTime === 0) {
            return null;
        }

        if (localTime > serverTime) {
            return localData;
        }

        return serverData;
    }

    public async saveWorkSpaceOnServer (data: any): Promise<void> {
        try {
            await UserWebStorageInstance.tvWorkspaces.put(this.hash, data);
        } catch (e) {
            console.error('Error saving workspace to server storage', e);
        }
    }

    public async saveWorkSpaceToIndexedDb (data: any): Promise<void> {
        try {
            await this.openLocalDataBase();
            data.id = this.hash;
            await this.indexedDBProvider.AddToDB(data, this.tableName);
        } catch (e) {
            console.error('Error saving workspace to local storage', e);
        }
    }

    private async openLocalDataBase (): Promise<void> {
        this.hash = UserManager.getWorkSpaceKey(WorkSpaceUserStorageType.TVTradingPlatform);
        if (this.indexedDBProvider.IsOpened()) return;
        await this.indexedDBProvider.OpenDB(this.tableName, this.userStorageKey);
    }
}

export const TvWorkspaceProvider = new _TvWorkspaceProvider();
