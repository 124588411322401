// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class MarkupData {
    public TypeId: any;
    public Formula: any;
    public Markups: any;

    constructor (typeId, formula, markups) {
        this.TypeId = typeId;
        this.Formula = formula;
        this.Markups = markups;
    }

    public toString (): string {
        return this.TypeId.toString();
    }
}
