// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OptionPutCall } from '../../../../../Utils/Instruments/OptionPutCall';
import { type IOptionCalculator } from '../IOptionCalculator';

class _BlackSholesModel implements IOptionCalculator {
    // #region Technical Methods

    private norm_dist (x: number): number {
        const d1 = 0.0498673470;
        const d2 = 0.0211410061;
        const d3 = 0.0032776263;
        const d4 = 0.0000380036;
        const d5 = 0.0000488906;
        const d6 = 0.0000053830;

        const a = Math.abs(x);
        let t = 1.0 + a * (d1 + a * (d2 + a * (d3 + a * (d4 + a * (d5 + a * d6)))));
        t *= t;
        t *= t;
        t *= t;
        t *= t;
        t = 1.0 / (t + t);
        if (x >= 0) { t = 1 - t; }

        return t;
    }

    private d1 (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return (Math.log(underlierPrice / strikePrice) + (interestRate + Math.pow(sigma, 2) / 2) * expiration) / (sigma * Math.sqrt(expiration));
    }

    private d2 (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma) - sigma * Math.sqrt(expiration);
    }
    // #endregion

    // #region Private Methods
    private call_price (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        const d1 = this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma);
        const d2 = this.d2(underlierPrice, strikePrice, expiration, interestRate, sigma);
        return underlierPrice * this.norm_dist(d1) - strikePrice * Math.exp(-(interestRate * expiration)) * this.norm_dist(d2);
    }

    private put_price (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        const d1 = this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma);
        const d2 = this.d2(underlierPrice, strikePrice, expiration, interestRate, sigma);
        return strikePrice * Math.exp(-(interestRate * expiration)) * this.norm_dist(-d2) - underlierPrice * this.norm_dist(-d1);
    }

    private call_delta (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return this.norm_dist(this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma));
    }

    private put_delta (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return this.norm_dist(this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma)) - 1;
    }

    private call_theta (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return (-(underlierPrice * (Math.exp(-(Math.pow(this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma), 2)) / 2) / Math.sqrt(2 * Math.PI)) * sigma) / (2 * Math.sqrt(expiration)) - interestRate * strikePrice * Math.exp(-(interestRate * expiration)) * this.norm_dist(this.d2(underlierPrice, strikePrice, expiration, interestRate, sigma))) / 365;
    }

    private put_theta (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return (-(underlierPrice * (Math.exp(-(Math.pow(this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma), 2)) / 2) / Math.sqrt(2 * Math.PI)) * sigma) / (2 * Math.sqrt(expiration)) + interestRate * strikePrice * Math.exp(-(interestRate * expiration)) * this.norm_dist(-1 * this.d2(underlierPrice, strikePrice, expiration, interestRate, sigma))) / 365;
    }

    private call_rho (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return strikePrice * expiration * Math.exp(-(interestRate * expiration)) * this.norm_dist(this.d2(underlierPrice, strikePrice, expiration, interestRate, sigma)) / 100;
    }

    private put_rho (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return -strikePrice * expiration * Math.exp(-(interestRate * expiration)) * this.norm_dist(-1 * this.d2(underlierPrice, strikePrice, expiration, interestRate, sigma)) / 100;
    }

    private call_iv (optionPrice: number, underlierPrice: number, strikePrice: number, expiration: number, interestRate: number): number {
        if (!isValidNumber(optionPrice) || optionPrice === 0) {
            return undefined;
        }
        if (!isValidNumber(underlierPrice) || underlierPrice === 0) {
            return undefined;
        }
        if (!isValidNumber(strikePrice) || strikePrice === 0) {
            return undefined;
        }
        if (expiration <= 0) {
            return undefined;
        }

        let callPrice = 0;
        let sigma = 0.00001;
        let increment = 0.1;
        let forceWhile = false;
        while (forceWhile || callPrice < optionPrice) {
            forceWhile = false;
            callPrice = this.call_price(underlierPrice, strikePrice, expiration, interestRate, sigma);
            if (callPrice > optionPrice && increment > 0.00001 && sigma > 0.0001) {
                sigma -= increment;
                increment *= 0.1;
                sigma += increment;
                forceWhile = true;
                continue;
            } else if (callPrice > optionPrice) {
                const res = callPrice - optionPrice;
                if (res > 0.0000000001) {
                    return sigma;
                }
            }

            sigma += increment;
            if (sigma > 10000) {
                return Infinity;
            }
        }
        return sigma;
    }

    private put_iv (optionPrice: number, underlierPrice: number, strikePrice: number, expiration: number, interestRate: number): number {
        if (!isValidNumber(optionPrice) || optionPrice === 0) {
            return undefined;
        }
        if (!isValidNumber(underlierPrice) || underlierPrice === 0) {
            return undefined;
        }
        if (!isValidNumber(strikePrice) || strikePrice === 0) {
            return undefined;
        }
        if (expiration <= 0) {
            return undefined;
        }

        let putPrice = 0;
        let sigma = 0.00001;
        let increment = 0.1;
        let forceWhile = false;
        while (forceWhile || putPrice < optionPrice) {
            forceWhile = false;
            putPrice = this.put_price(underlierPrice, strikePrice, expiration, interestRate, sigma);
            if (putPrice > optionPrice && increment > 0.00001 && sigma > 0.0001) {
                sigma -= increment;
                increment *= 0.1;
                sigma += increment;
                forceWhile = true;
                continue;
            } else if (putPrice > optionPrice) {
                const res = putPrice - optionPrice;
                if (res > 0.0000000001) {
                    return sigma;
                }
            }

            sigma += increment;
            if (sigma > 10000) {
                return Infinity;
            }
        }
        return sigma;
    }
    // #endregion

    // #region  IOptionCalculator
    public gamma (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return (Math.exp(-(Math.pow(this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma), 2)) / 2) / Math.sqrt(2 * Math.PI)) / (underlierPrice * sigma * Math.sqrt(expiration));
    }

    public vega (underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        return underlierPrice * Math.sqrt(expiration) * (Math.exp(-(Math.pow(this.d1(underlierPrice, strikePrice, expiration, interestRate, sigma), 2)) / 2) / Math.sqrt(2 * Math.PI)) / 100;
    }

    public delta (putCall: OptionPutCall, underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        switch (putCall) {
        case OptionPutCall.OPTION_CALL_VANILLA:
            return this.call_delta(underlierPrice, strikePrice, expiration, interestRate, sigma);
        case OptionPutCall.OPTION_PUT_VANILLA:
            return this.put_delta(underlierPrice, strikePrice, expiration, interestRate, sigma);
        default:
            return undefined;
        }
    }

    public theta (putCall: OptionPutCall, underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        switch (putCall) {
        case OptionPutCall.OPTION_CALL_VANILLA:
            return this.call_theta(underlierPrice, strikePrice, expiration, interestRate, sigma);
        case OptionPutCall.OPTION_PUT_VANILLA:
            return this.put_theta(underlierPrice, strikePrice, expiration, interestRate, sigma);
        default:
            return undefined;
        }
    }

    public rho (putCall: OptionPutCall, underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, sigma: number): number {
        switch (putCall) {
        case OptionPutCall.OPTION_CALL_VANILLA:
            return this.call_rho(underlierPrice, strikePrice, expiration, interestRate, sigma);
        case OptionPutCall.OPTION_PUT_VANILLA:
            return this.put_rho(underlierPrice, strikePrice, expiration, interestRate, sigma);
        default:
            return undefined;
        }
    }

    public iv (putCall: OptionPutCall, optionPrice: number, underlierPrice: number, strikePrice: number, expiration: number, interestRate: number): number {
        switch (putCall) {
        case OptionPutCall.OPTION_CALL_VANILLA:
            return this.call_iv(optionPrice, underlierPrice, strikePrice, expiration, interestRate);
        case OptionPutCall.OPTION_PUT_VANILLA:
            return this.put_iv(optionPrice, underlierPrice, strikePrice, expiration, interestRate);
        default:
            return undefined;
        }
    }

    public theoreticalPrice (putCall: OptionPutCall, underlierPrice: number, strikePrice: number, expiration: number, interestRate: number, iv: number): number {
        switch (putCall) {
        case OptionPutCall.OPTION_CALL_VANILLA:
            return this.call_price(underlierPrice, strikePrice, expiration, interestRate, iv);
        case OptionPutCall.OPTION_PUT_VANILLA:
            return this.put_price(underlierPrice, strikePrice, expiration, interestRate, iv);
        default:
            return underlierPrice;
        }
    }
    // #endregion
}

export const BlackSholesModel = new _BlackSholesModel();
