// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingButtonTemplate } from '../../templates.js';
import { TerceraButton } from '../../../Controls/elements/TerceraButton';
import { ControlsTypes } from '../../../Controls/UtilsClasses/FactoryConstants';

export class InvestingButton extends TerceraButton {
    constructor () { super(); }

    getType (): string { return ControlsTypes.InvestingButton; };
}

TerceraButton.extendWith(InvestingButton, {
    template: InvestingButtonTemplate,
    data: function () {
        return {
            text: '',
            investingButtonStyle: '',
            enabled: true
        };
    }
});
