// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { CanvasRactive } from './CanvasRactive';
import { HeatMapDrawer } from './HeatmapDrawer';

export class HeatmapRactive extends CanvasRactive {
    oninit (): void {
        super.oninit();
        super.setDrawer(new HeatMapDrawer());
    }
}
