// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { type BrokerMessageResponseType } from '../../../../Utils/Notifications/BrokerMessageResponseType';

export class BrokerResponseMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_BROKER_RESPONSE_MESSAGE, fieldSet);
    }

    public setId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ID, id);
    }

    public setValue (value: string): void {
        this.setFieldValue(FieldsFactory.FIELD_VALUE, value);
    }

    public setUserID (userID: number): void {
        this.setFieldValue(FieldsFactory.FIELD_USER_ID, userID);
    }

    public setUsername (userName: string): void {
        this.setFieldValue(FieldsFactory.FIELD_LOGIN, userName);
    }

    public setResponseType (responseType: BrokerMessageResponseType): void {
        this.setFieldValue(FieldsFactory.FIELD_BROKER_MESSAGE_RESPONSE_TYPE, responseType);
    }
}
