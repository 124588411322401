// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DynProperty, PairColor } from '../../../Commons/DynProperty';
import { Color } from '../../../Commons/Graphics';
import { Resources } from '../../../Localizations/Resources';
import { RowPropertyClass } from './RowPropertyClass';

export class VerticalPanelDataProviderItem extends RowPropertyClass {
    public HeaderBackColor = Color.Empty;
    public HeaderForeColor = Color.Empty;
    public ValueBackColor = Color.Empty;
    public ValueForeColor = Color.Empty;
    /// <summary>
    /// завел чтоб создавать если надо типизирование экшн-контролы. (напр. кнопки, линки)
    /// </summary>
    public DynPropertyControltype = DynProperty.STRING;
    public GroupIndex: any = null;

    public Reset (Visible): void {
        this.visible = Visible;
        this.HeaderBackColor = this.HeaderForeColor = this.ValueBackColor = this.ValueForeColor = Color.Empty;
    }

    public override Apply (sourceItem): void {
        this.visible = sourceItem.visible;
        this.HeaderBackColor = sourceItem.HeaderBackColor;
        this.HeaderForeColor = sourceItem.HeaderForeColor;
        this.ValueBackColor = sourceItem.ValueBackColor;
        this.ValueForeColor = sourceItem.ValueForeColor;
    }

    public override Properties (): DynProperty[] {
        const result: DynProperty[] = [];
        let dp = new DynProperty('HeaderColors', new PairColor(this.HeaderForeColor, this.HeaderBackColor), DynProperty.PAIR_COLOR, DynProperty.COLORS_GROUP);
        dp.propertyComment = Resources.getResource('property.LabelColors');
        result.push(dp);

        dp = new DynProperty('ValueColors', new PairColor(this.ValueForeColor, this.ValueBackColor), DynProperty.PAIR_COLOR, DynProperty.COLORS_GROUP);
        dp.propertyComment = Resources.getResource('property.ValueColors');
        result.push(dp);
        return result;
    }

    public override callBack (v): void {
        let p = DynProperty.getPropertyByName(v, 'HeaderColors');
        if (p != null /* && p.Value is PairColor */) {
            const pairColor = p.value;

            this.HeaderForeColor = pairColor.Color1;
            this.HeaderBackColor = pairColor.Color2;
        }
        p = DynProperty.getPropertyByName(v, 'ValueColors');
        if (p?.value /* is PairColor */) {
            const pairColor = p.value;

            this.ValueForeColor = pairColor.Color1;
            this.ValueBackColor = pairColor.Color2;
        }
    }
}
