// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// TODO. Ugly. Refactor. Lots of duplicated pieces of code
// (sltp, boundTo parameters and whatnot).

import { StopLimitOrderEdit } from '../order-edit/StopLimitOrderEdit';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { TIF } from '../../../../Utils/Trading/OrderTif';
import { Quantity } from '../../../../Utils/Trading/Quantity';
import { OrderExecutorUtils } from '../../../Trading/OrderExecutorUtils';
import { BoundToEdit } from '../BoundToEdit';
import { type OrderEditRequestData } from '../../../../Utils/Trading/OrderEditRequestData';
import { type DynProperty } from '../../../DynProperty';
import { IsAllowed } from '../../../IsAllowed';
import { Order } from '../../Order';

export class ModifyStopLimitOrder extends StopLimitOrderEdit {
    public boundToEdit: BoundToEdit;
    public order:Order;

    constructor (data) {
        super(data);
        this.order = data.order;
        this.boundToEdit = new BoundToEdit();

        this.updateParameters(new OrderEditUpdateData(
            null,
            {
                instrument: this.order.Instrument,
                account: this.order.Account,
                side: this.order.BuySell,
                quantity: new Quantity(this.order.Amount, true),
                tif: new TIF(this.order.TimeInForce, new Date(this.order.ExpireAt)),
                leverageValue: this.order.Leverage,
                order: this.order
            }
        ));
        this.setSLTP(this.order.createSlTpHolder());
    }

    public override getParameterNameArray (): string[] {
        const paramNameArr = super.getParameterNameArray();
        paramNameArr.push('boundTo');
        return paramNameArr;
    }

    public override dispose (): void {
        super.dispose();
        this.order = null;
    }

    // TODO. Refactor.
    public override setTradingData (tradingDataDict): any {
        const newTradingDataDict = super.setTradingData(tradingDataDict);

        if (!newTradingDataDict) {
            return newTradingDataDict;
        }

        if ('order' in tradingDataDict) {
            this.order = tradingDataDict.order;
            newTradingDataDict.order = this.order;
        }

        return newTradingDataDict;
    }

    // TODO. Refactor base class, trading data. Ugly.
    public override getTradingData (): any {
        const tradingDataDict = super.getTradingData();
        tradingDataDict.order = this.order;
        return tradingDataDict;
    }

    // TODO. Refactor base class, trading data. Ugly.
    public override getDataForRequest (): OrderEditRequestData {
        const reqData = super.getDataForRequest();
        if (reqData) {
            reqData.order = this.order;
        }

        return reqData;
    }

    public override update_stopPrice (updateData): boolean {
        let parameterChanged = super.update_stopPrice(updateData);

        const newTradingDataDict = updateData.newTradingDataDict;
        if (!newTradingDataDict) {
            return parameterChanged;
        }

        const order = newTradingDataDict.order;
        if (!order) {
            return parameterChanged;
        }

        const newStopPrice = order.StopLimit;
        if (newStopPrice !== this.stopPrice) {
            this.stopPrice = newStopPrice;
            parameterChanged = true || parameterChanged;

            parameterChanged = this.tryUpdateWarning_stopPrice(this.stopPrice, this.quote) || parameterChanged;
        }

        return parameterChanged;
    }

    public override update_limitPrice (updateData) {
        let parameterChanged = super.update_limitPrice(updateData);

        const newTradingDataDict = updateData.newTradingDataDict;
        if (!newTradingDataDict) {
            return parameterChanged;
        }

        const order = newTradingDataDict.order;
        if (!order) {
            return parameterChanged;
        }

        const newLimitPrice = order.Price;
        if (newLimitPrice !== this.limitPrice) {
            this.limitPrice = newLimitPrice;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public update_boundTo (updateData): boolean {
        return this.boundToEdit.update(updateData);
    }

    public toDynProperty_boundTo (): DynProperty {
        return this.boundToEdit.getDynProperty();
    }

    public toRawValue_boundTo (): string {
        return this.boundToEdit.getRawValue();
    }

    public getLeverageValue (): any {
        return this.order ? this.order.Leverage : null;
    }

    // TODO. Ugly. Duplication.
    public override async getConfirmationText (onlyParams = false): Promise<string> {
        const confirmationText = await super.getConfirmationText();
        if (onlyParams) { return confirmationText; } else {
            return OrderExecutorUtils.getModifyOrderEditConfirmation(
                this.order,
                confirmationText
            );
        }
    }

    public override tradingAllowed (): boolean {
        return IsAllowed.IsOrderModifyingAllowed(this.order).Allowed;
    }
}
