// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '@shared/utils/Account/AccountFeature';
import { BaseCustomTableFormatter } from './BaseCustomTableFormatter';
import { BaseCustomTableFormatterName } from './BaseCustomTableFormatterName';
import { CopyTradingPanel } from '@front/controls/panels/CopyTradingPanel';
import { MainWindowManager } from '@front/controls/UtilsClasses/MainWindowManager';

export class CopyTradingFormatter extends BaseCustomTableFormatter {
    constructor () {
        super(BaseCustomTableFormatterName.CopyTrading, AccountFeature.CopyTrading);
    }

    formatElement (dataFields): HTMLElement {
        const button = document.createElement('button');
        button.innerText = 'Copy trading';
        button.style.border = '1px solid #000000'; // Button border
        button.style.borderRadius = '6px'; // Button border radius
        button.style.fontFamily = '-apple-system,BlinkMacSystemFont,Trebuchet MS,Roboto,Ubuntu,sans-serif'; // #124714 1.
        button.style.fontStyle = 'normal';
        button.style.fontWeight = '400';
        button.style.fontSize = '14px';
        button.style.lineHeight = '17px';
        button.style.color = '#131722'; // Text color

        button.addEventListener('click', (event) => {
            event.stopPropagation();
            this.createCopyTradingPanel();
        });

        return button;
    }

    createCopyTradingPanel (): void {
        const panel = new CopyTradingPanel();
        void panel.set({ showCustomFullscreenCloud: true, needAdditionalDropdownOffset: true }); // #124782, #124857
        MainWindowManager.MainWindow.addControl(panel);
    }
}
