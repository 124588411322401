// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraPropertyGroupSeparatorTemplate } from '../../templates.js';
import { Control } from './Control';

export class TerceraPropertyGroupSeparator extends Control {
    constructor () { super(); }
}

Control.extendWith(TerceraPropertyGroupSeparator, {
    template: TerceraPropertyGroupSeparatorTemplate,
    data: function () { return { text: null }; }
});
