// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectInstrumentGroupMessage } from '../../../../Utils/DirectMessages/DirectInstrumentGroupMessage';
import { AliasesGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class InstrumentGroupMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectInstrumentGroupMessage[] {
        const message = new DirectInstrumentGroupMessage();

        message.TypeName = fs.GetValue(FieldsFactory.FIELD_NAME);
        message.TypeId = fs.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);
        message.SuperTypeId = fs.GetValue(FieldsFactory.FIELD_SUPER_ID);
        message.LanguageAliases = AliasesGroup.GetFromFieldSetOnlyName(fs);
        message.MinimalLot = fs.GetValue(FieldsFactory.FIELD_MINIMAL_LOT);
        message.MaximalLot = fs.GetValue(FieldsFactory.FIELD_MAX_LOT);
        message.InstrumentType = fs.GetValue(FieldsFactory.FIELD_TYPE);

        const extendedFieldsGroup = fs.GetGroups(FieldsFactory.KEY_VALUE_GROUP);
        for (let i = 0; i < extendedFieldsGroup.length; i++) {
            const field = extendedFieldsGroup[i];
            message.ExtendedFields[field.GetValue(FieldsFactory.FIELD_KEY)] = field.GetValue(FieldsFactory.FIELD_VALUE);
        }

        return [message];
    }
}
