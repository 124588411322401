// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OrderEditBaseUtils } from '@shared/utils/Trading/OrderEditBaseUtils';
import { BaseSettings } from '../../../Settings/BaseGeneralSettingsWrapper';
import { BaseSettingsUtils } from '../../../UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { AvailibleFundsLessWarning } from './order-warnings/AvailibleFundsLessWarning';
import { type BaseOrderWarning } from './order-warnings/BaseOrderWarning';
import { IdenticalOrderWarning } from './order-warnings/IdenticalOrderWarning';
import { OrderCapitalExceedWarning } from './order-warnings/OrderCapitalExceedWarning';
import { OrderQtyExceedWarning } from './order-warnings/OrderQtyExceedWarning';
import { SMFlagsWarning } from './order-warnings/SMFlagsWarning';
import { TodayGrossLessWarning } from './order-warnings/TodayGrossLessWarning';
import { TodayVolumeExeedWarning } from './order-warnings/TodayVolumeExeedWarning';
import { NumericLinks, type OrderEditBase } from './OrderEditBase';
import { ClientType } from '@shared/utils/ClientType';

export class OrderWarningsManager {
    private readonly orderEditBase: OrderEditBase;
    private readonly warningDict: Record<string, string> = {};
    private readonly generalWarnings: BaseOrderWarning[] = [];

    private isAnyGeneralWarnings: boolean = false;

    constructor (orderEditBase: OrderEditBase) {
        this.orderEditBase = orderEditBase;

        if (ClientType.IsWebDesktop()) {
            this.generalWarnings.push(new OrderQtyExceedWarning(orderEditBase));
            this.generalWarnings.push(new OrderCapitalExceedWarning(orderEditBase));
            this.generalWarnings.push(new TodayGrossLessWarning(orderEditBase));
            this.generalWarnings.push(new TodayVolumeExeedWarning(orderEditBase));
            this.generalWarnings.push(new AvailibleFundsLessWarning(orderEditBase));
        }

        this.generalWarnings.push(new IdenticalOrderWarning(orderEditBase));
        this.generalWarnings.push(new SMFlagsWarning(orderEditBase));
    }

    public needToShowWarningForcefully (): boolean { // some warnings ignore the warnOnWrongOrder setting #110331
        return this.isAnyGeneralWarnings ||
        !!this.warningDict[OrderEditBaseUtils.LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE] ||
        !!this.warningDict[OrderEditBaseUtils.AFTER_TRADE_CASH];
    }

    public getWarningArray (): string[] {
        const warningArr: string[] = [];

        const warningDict = this.warningDict;
        const paramNameArr = this.orderEditBase.parameterNameArray;
        const warnOnWrongOrder = BaseSettingsUtils.needToShowWarning(); // #110331 не добавляем warning в warningArray, если настройка warnOnWrongOrder отключена, но предупреждение отобразятся, поскольку есть другие типы предупреждений, игнорирующие настройку

        for (let i = 0, len = paramNameArr.length; i < len; i++) {
            const paramName = paramNameArr[i];
            const warning = warningDict[paramName];
            const needToAdd = warnOnWrongOrder ||
            paramName === OrderEditBaseUtils.LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE ||/* || тут должны быть и другие типы игнорирующие настройку warnOnWrongOrder но пока таких нет (не считая isIdenticalWithPrevious - отсутствующий в parameterNameArray и учитываемый в this.hasWarnOnIdenticalOrder) */
            (paramName === OrderEditBaseUtils.AFTER_TRADE_CASH && BaseSettings.warnWhenEnteringIntoLeverage);
            if (warning && needToAdd) {
                warningArr.push(warning);
            }
        }

        warningArr.push(...this.getWarningTexts());

        return warningArr;
    }

    public getWarningNumeric (): IWarningNumeric { // for numeric focusing when it has warning after click button in confirmation screen of place/modify order
        const warningDict = this.warningDict;
        let numericLinkKey: string | null = null;
        let dpControlName: string | null = null;

        if (isNullOrUndefined(warningDict)) {
            return;
        }

        if (isValidString(warningDict[OrderEditBaseUtils.LIMIT_PRICE_PARAM]) ||
            isValidString(warningDict[OrderEditBaseUtils.LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE])) {
            numericLinkKey = NumericLinks.LimitPrice;
            dpControlName = OrderEditBaseUtils.LIMIT_PRICE_PARAM;
        }

        if (isValidString(warningDict[OrderEditBaseUtils.STOP_PRICE_PARAM])) { // если warning в обоих фокус установится на stop поскольку он всюду идет раньше limit
            numericLinkKey = NumericLinks.StopPrice;
            dpControlName = OrderEditBaseUtils.STOP_PRICE_PARAM;
        }

        return {
            numericLinkKey,
            dpControlName
        };
    }

    // warning: null - removes warning.
    // returns true - if warning is new/changed/removed.
    public setParameterWarning (paramName, warning): any {
        const warningDict = this.warningDict;

        const oldWarning = warningDict.hasOwnProperty(paramName)
            ? warningDict[paramName]
            : null;

        if (warning === oldWarning) {
            return false;
        }

        if (warning === null) {
            delete warningDict[paramName];
        } else {
            warningDict[paramName] = warning;
        }

        return true;
    }

    public setAfterTradeWarning (): void {
        const paramName = OrderEditBaseUtils.AFTER_TRADE_CASH;
        if (paramName in this.warningDict) {
            delete this.warningDict[paramName];
        }

        const orderEditBase = this.orderEditBase;
        if (orderEditBase.account.assetBalanceDefault.AvailableCash > 0 && orderEditBase.afterTradeCash < 0) {
            this.setParameterWarning(paramName, 'ServerMargin.AvailableCashRiskWarningMessage');
        }
    }

    private getWarningTexts (): string[] {
        const generalWarningsTexts = this.generalWarnings.map(warning => warning.getText()).filter(text => isValidString(text));

        if (isValidArray(generalWarningsTexts)) {
            this.isAnyGeneralWarnings = true;
        } else {
            this.isAnyGeneralWarnings = false;
        }

        return generalWarningsTexts;
    }
}

export interface IWarningNumeric {
    numericLinkKey: string | null
    dpControlName: string | null
}
