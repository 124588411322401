// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectPortfolioInvestorMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { MessageProcessorBase } from './MessageProcessorBase';

export class PortfolioInvestorMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectPortfolioInvestorMessage[] {
        const message = new DirectPortfolioInvestorMessage();

        message.PortfolioSystemID = fieldSet.GetValue(FieldsFactory.FIELD_PORTFOLIO_SYSTEM_ID);
        message.ModelID = fieldSet.GetValue(FieldsFactory.FIELD_PORTFOLIO_MODEL_ID);
        message.StatusOut = fieldSet.GetValue(FieldsFactory.FIELD_STATUS);
        message.AccountID = fieldSet.GetValue(FieldsFactory.FIELD_ACCOUNT_ID);

        return [message];
    }
}
