// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type OrderType } from '../../../../Utils/Trading/OrderType';
import { type MarginModes } from '../../../../Utils/Enums/Constants';
import { type ProductType } from '../../../../Utils/Instruments/ProductType';

export class MarginGroup extends PFixFieldSet {
    constructor (pfixFieldSet?: PFixFieldSet) {
        super(FieldsFactory.MARGIN_GROUP, null, pfixFieldSet);
    }

    public getId (): number {
        return this.GetValue(FieldsFactory.FIELD_ID);
    }

    public setId (id: number): void {
        this.SetField(FieldsFactory.FIELD_ID, id);
    }

    public setAccountId (accountId: number): void {
        this.SetField(FieldsFactory.FIELD_ACCOUNT_ID, accountId);
    }

    public setTradableInstrumentID (insID: number): void {
        this.SetField(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, insID);
    }

    public setRouteId (routeId: number): void {
        this.SetField(FieldsFactory.FIELD_ROUTE_ID, routeId);
    }

    public setAmount (amount: number): void {
        this.SetField(FieldsFactory.FIELD_AMOUNT, amount);
    }

    public setCashOrderQty (cash: number): void {
        this.SetField(FieldsFactory.FIELD_CASH_ORDER_QTY, cash);
    }

    public setPrice (price: number): void {
        this.SetField(FieldsFactory.FIELD_PRICE, price);
    }

    public setStopPrice (price: number): void {
        this.SetField(FieldsFactory.FIELD_STOP_PRICE, price);
    }

    public setOrderType (orderType: OrderType): void {
        this.SetField(FieldsFactory.FIELD_ORDER_TYPE, orderType);
    }

    public setOperationMode (operationMode: number): void {
        this.SetField(FieldsFactory.FIELD_OPERATION_MODE, operationMode);
    }

    public setMarginMode (marginMode: MarginModes): void {
        this.SetField(FieldsFactory.FIELD_MARGIN_MODE, marginMode);
    }

    public setProductType (productType: ProductType): void {
        this.SetField(FieldsFactory.FIELD_PRODUCT_TYPE, productType);
    }

    public setLeverage (setLeverage: number): void {
        this.SetField(FieldsFactory.FIELD_LEVERAGE_SHORT, setLeverage);
    }
}
