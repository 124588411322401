// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { LogoutMessage } from '../Messages/LogoutMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class LogoutMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): LogoutMessage[] {
        return [new LogoutMessage(fieldSet)];
    }
}
