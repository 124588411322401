// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ChangePasswordMessage } from '../Messages/ChangePasswordMessage';
import { MessageProcessorBase } from './MessageProcessorBase';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class ChangePasswordMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder?) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): ChangePasswordMessage[] {
        const message = new ChangePasswordMessage(fieldSet);
        return [message];
    }
}
