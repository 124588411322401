// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { NewOrderMessage } from '../Messages/NewOrderMessage';
import { DirectCreateMessage } from '@shared/utils/DirectMessages/DirectCreateMessage';
import { DirectDialogCreatedMessage } from '@shared/utils/DirectMessages/DirectDialogCreatedMessage';
import { OrderGroup } from '../Groups/GroupsImport';
import { type DirectMessageBaseClass } from '@shared/utils/DirectMessages/DirectMessagesImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class OrderDialogMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectMessageBaseClass[] {
        const decoder = this.decoder;

        const newOrder = new NewOrderMessage(fieldSet);
        const groups = newOrder.FieldSet.GetGroups(FieldsFactory.ORDER_GROUP_NAME, OrderGroup);

        const answers = [];
        answers.push(newOrder);

        const len = groups.length;
        for (let i = 0; i < len; i++) {
            const group = groups[i];
            const dialogId = group.getOrderId();
            const pin = group.getAccountId().toString();

            const cMess = new DirectDialogCreatedMessage();
            cMess.DialogId = dialogId;
            cMess.Pin = pin;
            answers.push(cMess);

            const message = new DirectCreateMessage();
            message.BoundToOrderId = '-1';
            const orderIdGroup = this.GetOrderIdGroup(group);

            message.BoundToOrderId =
            orderIdGroup
                ? orderIdGroup.getOrderId().toString()
                : '-1';

            message.DialogId = dialogId;
            message.AccountID = pin;
            message.OpenOrderId = '-1';

            message.Symbol = decoder.GetSymbolById(group.getInstrumentId());
            message.BstrMarket = group.getRouteId();// decoder.GetRouteById(group.getRouteId())
            message.OrderId = group.getOrderId().toString();

            message.OrderType = group.getOrderType();
            message.Amount = group.getAmount();
            message.Price = group.getPrice();
            message.OperationType = group.getOperationType();

            answers.push(message);
        }

        return answers;
    }

    private GetOrderIdGroup (group): OrderGroup | null {
        const fields = group.GetGroup(FieldsFactory.ORDER_ID_GROUP);
        return fields === null ? null : new OrderGroup(fields);
    }
}
