// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { DirectAccountFollowerResponseMessage } from '@shared/utils/DirectMessages/DirectAccountFollowerResponseMessage';

export class AccountFollowerResponseMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectAccountFollowerResponseMessage[] {
        const message = new DirectAccountFollowerResponseMessage();

        message.Status = fieldSet.GetValue(FieldsFactory.FIELD_FOLLOWER_REQUEST_STATUS);
        message.MasterId = fieldSet.GetValue(FieldsFactory.FIELD_MASTER_ID);
        message.FollowerIDs = fieldSet.GetValue(FieldsFactory.FIELD_FOLLOWER_IDS);
        message.RejectAccountID = fieldSet.GetValue(FieldsFactory.FIELD_REJECT_ACCOUNT_ID);

        return [message];
    }
}
