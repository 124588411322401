// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum PositionInfoType {
    None,
    PLByCurrency,
    AverageOpenPrice,
    BreakEven,
    NetQTY,
    LongQty,
    ShortQty
}
