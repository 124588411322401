// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { Rectangle } from '@shared/commons/Geometry';
import { Cursors } from '@shared/commons/Cursors';
import { DrawPointer, DrawPointerTypeEnum } from '../../Utils/DrawPointer';
import { LayersEnum } from '../../Renderers/TerceraChartBaseRenderer';
import { Brushes } from '@shared/commons/Graphics';
import { MovingType, ProMath, ToolView } from '../ToolView';
import { SelectionState, ToolSelectionType } from '../Selection';
import { TerceraChartTradingOperation } from '../../Utils/ChartConstants';
import { CancelCB } from './OrderToolView';
import { StopLimitOrderToolView } from './StopLimitOrderToolView';
import { TradingToolViewBase, TradingToolViewType } from './TradingToolViewBase';

import { OperationType } from '@shared/utils/Trading/OperationType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { SlTpPriceType } from '@shared/utils/Enums/Constants';
import { OffsetModeViewEnum } from '@shared/utils/Trading/OffsetModeViewEnum';
import { TradingLockUtils } from '@shared/utils/TradingLockUtils';
import { IsAllowed, type IsAllowedResponce } from '@shared/commons/IsAllowed';
import { PositionEdit } from '@shared/commons/cache/OrderParams/PositionEdit';
import { type TerceraChartCashItemSeries } from '../../Series/TerceraChartCashItemSeries';
import { SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { type SlTpHolder } from '@shared/utils/Trading/SlTpHolder';

export class SLTPOrderToolView extends TradingToolViewBase {
    public parentY = -1;
    public parentValue = NaN;
    public errorMode = false;
    public TradeWasModified = false;
    public priceValue = NaN;
    public priceY = -1;

    public lastValidParentValue = NaN;
    public lastValidPriceValue = NaN;

    public parentOrder: any;
    public sltpOrder: any;
    public GetToolViewByOrderId: any;
    public terceraChartPanelContext: any;

    public sllTool: any = null; // for Stoplosslimit tool #91413

    public movingType = MovingType.OnlyY;
    public ToolViewType = TradingToolViewType.SLTPOrderToolView;

    protected _isSl: boolean;

    constructor (order, sltpOrder, getToolViewByOrderId, terceraChartPanelContext, renderer, isSl) {
        super();

        this._isSl = !!isSl;
        this.collapsed = true;

        this.parentOrder = order;
        this.sltpOrder = sltpOrder;
        this.GetToolViewByOrderId = getToolViewByOrderId;// Func<string, TradingToolViewBase>
        this.terceraChartPanelContext = terceraChartPanelContext;
        this.myRenderer = renderer;

        if (this.parentOrder != null) {
            const isPosition = !!this.parentOrder.isPosition;
            const ID = isPosition ? this.parentOrder.PositionId : this.parentOrder.OrderNumber;
            const type = isPosition ? TradingToolViewType.PositionToolView : TradingToolViewType.OrderToolView;
            const parentTool = this.GetToolViewByOrderId(ID, type);
            if (parentTool != null) {
                this.collapsed = parentTool.Collapsed;
            }
        }

        if (this.sltpOrder !== null) {
            this._isSl = this.parentOrder.SLOrder !== null && this.parentOrder.SLOrder.OrderNumber === this.sltpOrder.OrderNumber;
        }

        this.Updated();
    }

    // #region properties
    public AllowCancelOrder (): boolean {
        return IsAllowed.IsOrderCancelingAllowed(this.sltpOrder).Allowed || this.sltpOrder === null && IsAllowed.IsOrderModifyingAllowed(this.parentOrder).Allowed;
    }

    public AllowTS (): IsAllowedResponce {
        return IsAllowed.IsTrailingStopForSLAllowed(this.parentOrder.Instrument, this.parentOrder.Account, this.parentOrder.isPosition);
    }

    public AllowModify (): boolean {
        return (IsAllowed.IsOrderModifyingAllowed(this.sltpOrder).Allowed || this.sltpOrder === null && IsAllowed.IsOrderModifyingAllowed(this.parentOrder).Allowed) && IsAllowed.IsOrderSLTPAllowed(this.parentOrder).Allowed;
    }

    override get Collapsed (): boolean { return this.collapsed; }
    override set Collapsed (value) {
        if (this.collapsed != value) {
            this.collapsed = value;
            if (this.parentOrder != null) {
                const isPosition = !!this.parentOrder.isPosition;
                const ID = isPosition ? this.parentOrder.PositionId : this.parentOrder.OrderNumber;
                const type = isPosition ? TradingToolViewType.PositionToolView : TradingToolViewType.OrderToolView;
                this.GetToolViewByOrderId(ID, type).Collapsed = this.collapsed;

                const sllTool = this.sllTool;
                if (sllTool) {
                    sllTool.Collapsed = this.collapsed;
                }
            }
        }
    }

    override get ID (): string {
        return this.sltpOrder !== null ? this.sltpOrder.OrderNumber : (this.parentOrder.OrderNumber + (this.isSl ? '-SL' : '-TP'));
    }

    override get Price (): number {
        if (this.sltpOrder != null) {
            return this.sltpOrder.getPriceForStopLoss();
        }

        if (this.isSl) {
            return this.parentOrder.GetStopLossInPriceValue();
        } else {
            return this.parentOrder.GetTakeProfitInPriceValue();
        }
    }

    get isSl (): boolean {
    // if (this.sltpOrder != null)
    //    return this.sltpOrder.IsSLOrder;

        return this._isSl;
    }

    get Side (): OperationType {
        if (this.sltpOrder != null) {
            return this.sltpOrder.BuySell;
        }

        return this.parentOrder.BuySell === OperationType.Buy ? OperationType.Sell : OperationType.Buy;
    }

    // #endregion

    public override Updated (): void {
        if (this.sltpOrder === null && (this.parentOrder === null || isNaN(this.parentOrder.StopLossPriceValue) && isNaN(this.parentOrder.TakeProfitPriceValue))) {
            return;
        }

        this.dataCacheTool.Points[0][0] = this.parentOrder.UTCDateTime;
        this.dataCacheTool.Points[0][1] = this.Price;

        if (this.sllTool) {
            this.sllTool.Updated();
        }

        if (!this.CurrentMovement.IsMovingRightNow) {
            this.CancelMoving();
        }

        this.myRenderer.chart.IsDirty(LayersEnum.Tools);
    }

    public isTrailingStop (): boolean {
        if (this.sltpOrder != null) {
            return this.sltpOrder.OrderType === OrderType.TrailingStop;
        }

        return this._isSl && this.parentOrder.StopLossPriceType === SlTpPriceType.TrOffset;
    }

    public override CancelMoving (): void {
        this.TradeWasModified = false;
        this.ResetMove();
        this.ResetMouse();
    }

    public override Draw (gr, window, param): void {
        this.MainOrderVisibility = false;
        if (!this.myRenderer.ShowOrders) {
            return;
        }

        const parentOrder = this.parentOrder;

        if (!parentOrder?.Price) {
            return;
        }

        if (this._isSl && this.sllTool && parentOrder.isPosition && parentOrder.IsActivated) // #93540
        {
            return;
        }

        if (IsAllowed.IsProtectiveOrders()) {
            return;
        } // #108469 <- 108200

        try {
            const CR = window.ClientRectangle;

            const minX = CR.X;
            const maxX = minX + CR.Width;

            let screenPoints00 = this.screenPoints[0][0];
            if (screenPoints00 < minX + 9) {
                screenPoints00 = minX + 9;
            }

            const offsetX = parentOrder.OrderType === OrderType.StopLimit ? StopLimitOrderToolView.LIMIT_OFFSET + 12 : minX + 15;
            if (this.myRenderer.ShowVisualTradingOnLeftSide) {
                screenPoints00 = offsetX;
            } else {
                screenPoints00 += offsetX;
            }

            let screenPoints01 = this.screenPoints[0][1];
            if (!isNaN(this.priceValue)) {
                screenPoints01 = Math.floor(this.priceY);
            }

            // Ловим ошибки
            if ((screenPoints00 < 0 && maxX < 0) || screenPoints01 >= ProMath.infinity || screenPoints01 >= ProMath.infinity || screenPoints01 <= ProMath.infinityMinus || screenPoints01 <= ProMath.infinityMinus) {
                return;
            }

            this.MainOrderVisibility = true;

            const hoveredOrSelected = this.CurrentSelection.CurrentState != SelectionState.None;
            // оптимизация - вне не рисуем ордер и не добавляем его плашку
            this.iconRect.X = screenPoints00 - 8;
            this.iconRect.Y = screenPoints01 - 8;
            if (CR.Contains(screenPoints00, screenPoints01)) {
                const orderIsBuy = this.Side === OperationType.Buy;

                let p = null;
                if (!orderIsBuy) {
                    p = this.TradeWasModified ? TradingToolViewBase.orderGrayPen : this.myRenderer.orderSellPen;
                } else {
                    p = this.TradeWasModified ? TradingToolViewBase.orderGrayPen : this.myRenderer.orderBuyPen;
                }

                let im = null;
                if (this._isSl) {
                    if (this.TradeWasModified) {
                        im = this.Collapsed ? TradingToolViewBase.close_SL_grayImage : TradingToolViewBase.open_SL_grayImage;
                    } else {
                        if (!this.Collapsed) {
                            im = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.duy_SL_open_hoverImage : TradingToolViewBase.duy_SL_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_SL_open_hoverImage : TradingToolViewBase.sell_SL_open_defaultImage);
                        } else {
                            im = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.duy_SL_close_hoverImage : TradingToolViewBase.duy_SL_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_SL_close_hoverImage : TradingToolViewBase.sell_SL_close_defaultImage);
                        }
                    }
                } else {
                    if (this.TradeWasModified) {
                        im = this.Collapsed ? TradingToolViewBase.close_TP_grayImage : TradingToolViewBase.open_TP_grayImage;
                    } else {
                        if (!this.Collapsed) {
                            im = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_TP_open_hoverImage : TradingToolViewBase.buy_TP_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_TP_open_hoverImage : TradingToolViewBase.sell_TP_open_defaultImage);
                        } else {
                            im = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_TP_close_hoverImage : TradingToolViewBase.buy_TP_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_TP_close_hoverImage : TradingToolViewBase.sell_TP_close_defaultImage);
                        }
                    }
                }

                let infoRectDelta = 0;
                this.imageRect = new Rectangle(screenPoints00 + 16, screenPoints01 - 11, im.width, im.height);

                if (!this.Collapsed) {
                    const shortDescriptionText = this.DescriptionText();

                    let totalW = Math.floor(gr.GetTextWidth(shortDescriptionText, TradingToolViewBase.tradeFont)) + 10;
                    if (totalW < 30) {
                        totalW = 30;
                    }

                    if (!shortDescriptionText.length) {
                        totalW = 0;
                    } // #91413

                    this.infoRect = new Rectangle(this.imageRect.X + this.imageRect.Width, screenPoints01 - 11, totalW, 22);

                    let backgroundBrush = orderIsBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActiveBrush : TradingToolViewBase.buyBackgroundBrush) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActiveBrush : TradingToolViewBase.sellBackgroundBrush);
                    let backgroundPen = orderIsBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.buyBackgroundPen) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActivePen : TradingToolViewBase.sellBackgroundPen);

                    if (this.TradeWasModified) {
                        backgroundBrush = TradingToolViewBase.grayBackgroundBrush;
                        backgroundPen = TradingToolViewBase.grayBackgroundPen;
                    }
                    // gr.RenderButton(backgroundBrush, backgroundPen, this.infoRect);
                    gr.FillRect(backgroundBrush, this.infoRect.X, this.infoRect.Y, this.infoRect.Width, this.infoRect.Height);
                    gr.DrawLine(backgroundPen, this.infoRect.X, this.infoRect.Y, this.infoRect.X, this.infoRect.Y + this.infoRect.Height);
                    gr.DrawString(shortDescriptionText, TradingToolViewBase.tradeFont, Brushes.White, this.infoRect.X + 5, this.infoRect.Y + this.infoRect.Height / 2, 'left', 'middle');

                    const infoRect = this.infoRect;
                    if (!this.errorMode) {
                        const infoRectR = infoRect.X + infoRect.Width;

                        let curX = infoRectR;
                        if (this._isSl && this.AllowTS() && !TradingLockUtils.TradingLock.tradingLocked) {
                            const trailingButton = infoRect.copy();
                            this.trailingButton = trailingButton;
                            trailingButton.Width = 22;
                            trailingButton.X = infoRectR;

                            // gr.RenderButton(
                            //     this.isTrailingStop() ? backgroundBrush : TradingToolViewBase.grayBackgroundBrush,
                            //     this.isTrailingStop() ? backgroundPen : TradingToolViewBase.grayBackgroundPen,
                            //     trailingButton);

                            let trailingImg = null;
                            if (this.TradeWasModified || !this.isTrailingStop()) {
                                if (this.CurrentSelection.CurrentHover === ToolSelectionType.Trailing) {
                                    trailingImg = TradingToolViewBase.TSL_gray_mine_hoverImage;
                                } else {
                                    trailingImg = hoveredOrSelected ? TradingToolViewBase.TSL_gray_hoverImage : TradingToolViewBase.TSL_grayImage;
                                }
                            } else {
                                if (this.CurrentSelection.CurrentHover === ToolSelectionType.Trailing) {
                                    trailingImg = orderIsBuy ? TradingToolViewBase.buy_TSL_mine_hoverImage : TradingToolViewBase.sell_TSL_mine_hoverImage;
                                } else {
                                    trailingImg = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_TSL_hoverImage : TradingToolViewBase.buy_TSL_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_TSL_hoverImage : TradingToolViewBase.sell_TSL_defaultImage);
                                }
                            }

                            if (trailingImg) {
                                gr.drawImage(trailingImg, this.trailingButton.X, this.trailingButton.Y);
                            }

                            gr.DrawLine(backgroundPen, this.trailingButton.X, this.trailingButton.Y, this.trailingButton.X, this.trailingButton.Y + this.trailingButton.Height);
                            // gr.DrawLine(backgroundPen, this.trailingImg.X, this.trailingImg.Y, this.trailingImg.X, this.trailingImg.Y + this.trailingImg.Height);

                            curX = trailingButton.X + trailingButton.Width;
                        } else {
                            this.trailingButton = Rectangle.Empty();
                        }

                        if (this.AllowCancelOrder() && !TradingLockUtils.TradingLock.tradingLocked && !param.TerceraChart.TerceraChartTradingToolsRenderer.NotTradingContext) {
                            const infoRect = this.infoRect;
                            this.removeButton = infoRect.copy();
                            this.removeButton.X = curX;
                            this.removeButton.Width = 19;
                            // gr.RenderButton(backgroundBrush, backgroundPen, this.removeButton);

                            let cancelOrderImg = null;
                            if (this.TradeWasModified) {
                                cancelOrderImg = TradingToolViewBase.moveOrder_cancelImage;
                            } else {
                                if (this.CurrentSelection.CurrentHover === ToolSelectionType.Remove) {
                                    cancelOrderImg = orderIsBuy ? TradingToolViewBase.buy_cancel_mine_hoverImage : TradingToolViewBase.sell_cancel_mine_hoverImage;
                                } else {
                                    cancelOrderImg = (orderIsBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_cancel_hoverImage : TradingToolViewBase.buy_cancel_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_cancel_hoverImage : TradingToolViewBase.sell_cancel_defaultImage);
                                }
                            }

                            if (cancelOrderImg) {
                                gr.drawImage(cancelOrderImg, this.removeButton.X, this.removeButton.Y);
                            }

                            gr.DrawLine(backgroundPen, this.removeButton.X, this.removeButton.Y, this.removeButton.X, this.removeButton.Y + this.removeButton.Height);
                        } else {
                            this.removeButton = Rectangle.Empty();
                        }

                        infoRectDelta = this.removeButton.Width + this.trailingButton.Width + infoRect.Width + im.width + 16;
                    } else {
                        infoRectDelta = infoRect.Width + im.width + 16;
                    }
                } else {
                    this.infoRect = Rectangle.Empty();
                    this.removeButton = Rectangle.Empty();
                    this.actionButton = Rectangle.Empty();
                    this.trailingButton = Rectangle.Empty();
                    infoRectDelta = im.width + 16;
                }

                // this.imageRect = new Rectangle(screenPoints00 + 13, screenPoints01 - 10, im.width, im.height);
                const skipDrawingLine = screenPoints00 + infoRectDelta > maxX - 3;

                // Линия
                if (!skipDrawingLine) {
                    gr.DrawLine(p, screenPoints00 + infoRectDelta, screenPoints01, maxX, screenPoints01);
                }

                // +++ add right line
                // if (!isNaN(this.priceValue )|| this.TradeWasModified)
                //     gr.DrawLine(p, screenPoints00 - 8 - 5, screenPoints01, 0, screenPoints01);

                // Иконка
                if (im) {
                    gr.drawImage(im, this.imageRect.X, this.imageRect.Y);
                }

                // плашка
                if (!this.Collapsed || this.TradeWasModified) {
                    let backGroundImage = this.Side === OperationType.Buy ? TradingToolViewBase.buyBrush : TradingToolViewBase.sellBrush;
                    if (this.TradeWasModified) {
                        backGroundImage = TradingToolViewBase.grayBrush;
                    }

                    let price;
                    if (!isNaN(this.lastValidPriceValue)) {
                        price = this.lastValidPriceValue;
                    } else {
                        price = !isNaN(this.priceValue) ? this.priceValue : this.Price;
                    }
                    const text = this.myRenderer.instrument.formatPrice(price);
                    param.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.VisualTrading, price, backGroundImage, text));
                }
            } else if (this.imageRect.IsEmpty()) {
                this.imageRect = new Rectangle(screenPoints00 + 16, screenPoints01 - 11, 25, 22);
            }

            //
            // Bind line
            //
            if (!this.Collapsed || hoveredOrSelected || this.TradeWasModified) {
                let pen = null;
                if (parentOrder.BuySell === OperationType.Sell) {
                    pen = this.TradeWasModified ? TradingToolViewBase.orderGrayPen : this.myRenderer.orderSellPen;
                } else {
                    pen = this.TradeWasModified ? TradingToolViewBase.orderGrayPen : this.myRenderer.orderBuyPen;
                }

                if (this.parentY > screenPoints01) {
                    gr.DrawLine(pen, this.imageRect.X - 10, this.imageRect.Y + this.imageRect.Height / 2, this.imageRect.X - 10, this.parentY - this.imageRect.Height / 2);
                    gr.DrawLine(pen, this.imageRect.X - 10, this.imageRect.Y + this.imageRect.Height / 2, this.imageRect.X, this.imageRect.Y + this.imageRect.Height / 2);
                } else {
                    gr.DrawLine(pen, this.imageRect.X - 10, this.imageRect.Y + this.imageRect.Height / 2, this.imageRect.X - 10, this.parentY + this.imageRect.Height / 2);
                    gr.DrawLine(pen, this.imageRect.X - 10, this.imageRect.Y + this.imageRect.Height / 2, this.imageRect.X, this.imageRect.Y + this.imageRect.Height / 2);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public override UpdateScreenPoints (window, cashItemSeries: TerceraChartCashItemSeries = null): void {
        super.UpdateScreenPoints(window, cashItemSeries);

        if (isNaN(this.parentValue) && isNaN(this.lastValidParentValue)) // для рисования линии связи
        {
            this.parentY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.parentOrder.Price)));
        }
    }

    // #region mouse
    /// <summary>
    /// Является ли тулза выделенной мышью (сейчас по линиям, но можно и весь полигон)
    /// </summary>
    public override IsSelectCheck (x: number, y: number): boolean {
        if (this.TradeWasModified) // тулза смещена и с сервера не пришло подтверждение не двигаем её
        {
            return false;
        }

        if (!this.myRenderer.ShowOrders) {
            return false;
        }

        const newSelection = this.GetCurrentSelection(x, y);
        return newSelection !== ToolSelectionType.None;
    }

    public override GetCurrentSelection (x: number, y: number): ToolSelectionType {
        let newSelection = ToolSelectionType.None;
        if (this.imageRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Image;
        } else if (this.iconRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Icon;
        } else if (this.actionButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Action;
        } else if (this.removeButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Remove;
        } else if (this.infoRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Info;
        } else if (this.trailingButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Trailing;
        } else if (Math.abs(y - this.screenPoints[0][1]) < ToolView.POINT_DX) {
            newSelection = ToolSelectionType.Line;
        }

        return newSelection;
    }

    public override CheckMinDistanceForStartMoving (x: number, y: number): boolean {
        const mouseDownHover = this.CurrentSelection.MouseDownHover;
        if (this.AllowModify() && (!TradingLockUtils.TradingLock.tradingLocked) && (mouseDownHover === ToolSelectionType.Image || mouseDownHover === ToolSelectionType.Info)) {
            return Math.abs(this.CurrentMovement.PrevY - y) >= this.MinDistanceForStartMoving();
        }

        return false;
    }

    // move
    public override ProcessNewPosition (window, x: number, y: number, cashItemSeries: TerceraChartCashItemSeries = null): void {
        this.TradeWasModified = true;
        // move order
        if (this.AllowModify()) {
            super.ProcessNewPosition(window, x, y, cashItemSeries); // обновляем главного

            this.priceValue = window.PointsConverter.GetDataY(y);
            this.priceY = y;

            if (this.isSl) {
                const sllTool = this.sllTool;
                if (sllTool) {
                    sllTool.priceValue = sllTool.Price + (this.priceValue - this.Price);
                    sllTool.priceY = Math.floor(Math.round(window.PointsConverter.GetScreenY(sllTool.priceValue)));
                    sllTool.TradeWasModified = true;
                    sllTool.parentValue = this.priceValue;
                    sllTool.parentY = y;
                }
            }

            const ins = this.myRenderer?.Instrument;
            const parentOrder = this.parentOrder;
            const isBuy = parentOrder.BuySell == OperationType.Buy;

            if (parentOrder.isPosition) {
                const prVal = ins.RoundPriceToNearestPointSize(this.priceValue);
                const closePrice = PositionEdit.SlTpPriceGetter(this._isSl, isBuy, parentOrder, ins, parentOrder.Account, parentOrder.LastQuote);
                if (this._isSl) {
                    this.errorMode = isBuy ? prVal > closePrice : prVal < closePrice;
                } else {
                    this.errorMode = isBuy ? prVal < closePrice : prVal > closePrice;
                }
            } else {
                const parentPrice = parentOrder.Price;
                const prVal = ins.roundPrice(this.priceValue);// this.myRenderer.Instrument.RoundPriceToNearestPointSize(this.priceValue);#60125
                if (this._isSl) {
                    this.errorMode = isBuy ? prVal >= parentPrice : prVal <= parentPrice;
                } else {
                    this.errorMode = isBuy ? prVal <= parentPrice : prVal >= parentPrice;
                }
            }
        }
    }

    // mouse finish
    public override ProcessMoveFinish (): void {
        try {
            super.ProcessMoveFinish();

            if (this.errorMode) {
                this.undoChanges();

                return;
            }

            const mouseDownHover = this.CurrentSelection.MouseDownHover;
            if (mouseDownHover === ToolSelectionType.Image || mouseDownHover === ToolSelectionType.Info || mouseDownHover === ToolSelectionType.Line) {
                const ins = this.myRenderer?.Instrument;

                const newPrice = ins ? ins.roundPrice(this.priceValue) : this.priceValue;
                let newSLLPrice = null;
                const trailing = this.isTrailingStop();
                let delta = newPrice;
                this.lastValidPriceValue = newPrice;
                if (trailing) {
                    // const parentPrice = this.parentOrder.isPosition ? this.parentOrder.CurPriceClose : this.parentOrder.Price;
                    // if (trailing)
                    //    delta = (this.parentOrder.BuySell == OperationType.Buy ? (parentPrice - newPrice) : (newPrice - parentPrice)) / this.myRenderer.Instrument.priceIncrement;;
                    delta = ins ? ins.roundPrice(delta) : delta;
                }

                if (this.isSl) {
                    const cancel = new CancelCB();
                    const sllTool = this.sllTool;
                    if (sllTool) {
                        newSLLPrice = ins ? ins.roundPrice(sllTool.priceValue) : sllTool.priceValue;
                        sllTool.lastValidParentValue = newPrice;
                        cancel.add(sllTool.Updated.bind(sllTool));
                    }
                }

                this.terceraChartPanelContext.ChartVisualTrading(this.parentOrder, {
                    action: this._isSl ? TerceraChartTradingOperation.ModifySL : TerceraChartTradingOperation.ModifyTP,
                    price: delta,
                    sllPrice: newSLLPrice,
                    ts: trailing,
                    CancelCallback: this.undoChanges.bind(this)
                });
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        } finally {
            this.ResetMouse();
        }
    }

    // mouse click
    public override ProcessClick (e): void {
        if (this.CurrentSelection.MouseDownHover === this.CurrentSelection.CurrentHover) {
            switch (this.CurrentSelection.MouseDownHover) {
            case ToolSelectionType.Image:
            case ToolSelectionType.Info:
                this.Collapsed = !this.Collapsed;
                break;
            case ToolSelectionType.Action:

                if (!TradingLockUtils.TradingLock.tradingLocked)
                // this.terceraChartPanelContext.ChartVisualTrading(this.sltpOrder, TerceraChartTradingOperation.this.actionButton);
                {
                    this.terceraChartPanelContext.ChartVisualTrading(this.sltpOrder, { action: TerceraChartTradingOperation.ActionButton });
                }
                // show menu
                break;
            case ToolSelectionType.Remove:

                if (!TradingLockUtils.TradingLock.tradingLocked)
                // this.terceraChartPanelContext.ChartVisualTrading(this.parentOrder, this._isSl ? TerceraChartTradingOperation.RemoveSL : TerceraChartTradingOperation.RemoveTP, null);
                {
                    this.terceraChartPanelContext.ChartVisualTrading(this.parentOrder, { action: this._isSl ? TerceraChartTradingOperation.RemoveSL : TerceraChartTradingOperation.RemoveTP });
                }
                break;

            case ToolSelectionType.Trailing:

                if (!TradingLockUtils.TradingLock.tradingLocked) {
                    const trailing = !this.isTrailingStop();
                    // const parentPrice = this.parentOrder.isPosition ? this.parentOrder.CurPriceClose : this.parentOrder.Price;
                    let delta = this.Price;
                    // if (trailing)
                    //    delta = (this.parentOrder.BuySell == OperationType.Buy ? (parentPrice - this.Price) : (this.Price - parentPrice)) / this.myRenderer.Instrument.priceIncrement;
                    delta = this.myRenderer.Instrument != null ? this.myRenderer.Instrument.roundPrice(delta) : delta;

                    // this.terceraChartPanelContext.ChartVisualTrading(this.parentOrder, TerceraChartTradingOperation.ModifySL, new TerceraChartTradingOperationValues() { ts = trailing, price = delta });
                    this.terceraChartPanelContext.ChartVisualTrading(this.parentOrder, { action: TerceraChartTradingOperation.ModifySL, ts: trailing, price: delta });
                }
                break;

            case ToolSelectionType.Line:
            case ToolSelectionType.None:
            default:
                break;
            }
        }
        this.ResetMouse();
    }

    public override ResetMouse (): void {
        this.CurrentSelection.MouseDownHover = ToolSelectionType.None;

        this.errorMode = false;
        this.priceValue = NaN;
        if (isNaN(this.lastValidPriceValue)) {
            this.priceY = NaN;
        }
    }

    public ResetMove (): void {
        this.lastValidPriceValue = NaN;
        this.lastValidParentValue = NaN;
        this.priceY = NaN;
        this.TradeWasModified = false;

        const tool = this.sllTool;
        if (tool) {
            tool.lastValidPriceValue = tool.parentValue = NaN;
        }
    }

    // TODO. Rename. Refactor.
    public undoChanges (): void {
        this.ResetMove();
        this.Updated();

        const tool = this.sllTool;
        if (tool) {
            tool.undoChanges();
        }
    }

    public override GetCursor (e): Cursors {
        const curElement = this.CurrentSelection.MouseDownHover === ToolSelectionType.None ? this.CurrentSelection.CurrentHover : this.CurrentSelection.MouseDownHover;

        switch (curElement) {
        case ToolSelectionType.Image:
        case ToolSelectionType.Info:
            return this.AllowModify() ? (this.errorMode ? Cursors.No : Cursors.NoMoveVert) : null;
        case ToolSelectionType.Action:
        case ToolSelectionType.Remove:
        case ToolSelectionType.Line:
        case ToolSelectionType.None:
        case ToolSelectionType.Trailing:
        default:
            return null;
        }
    }

    public override GetTooltip (e): string | null {
        let res = '';
        switch (this.CurrentSelection.CurrentHover) {
        case ToolSelectionType.Image:
            res = Resources.getResource('TradeTool.Order');
            break;
        case ToolSelectionType.Info:
            res = Resources.getResource('TradeTool.Tick and P/L');
            break;
        case ToolSelectionType.Remove:
            res = Resources.getResource('TradeTool.Cancel order');
            break;
        default:
            res = null;
            break;
        }
        return res ? super.GetTooltip(e) : res;
    }

    // #endregion

    public override Dispose (): void {
        this.GetToolViewByOrderId = null;
        this.parentOrder = null;
        this.sltpOrder = null;

        super.Dispose();
    }

    public override DescriptionText (): string {
        const isSlPresent = this.parentOrder.isPosition
            ? this.parentOrder.SLOrder?.StopLimit && this.parentOrder.SLOrder.OrderType != OrderType.TrailingStop
            : this.parentOrder.StopLossLimitPriceValue != null;

        if (this.isSl && isSlPresent && this.sllTool) {
            return '';
        }

        // от цены открытия.
        const isMovedParentOrder = !isNaN(this.parentValue);
        let orderPrice = this.parentOrder.Price;
        if (isMovedParentOrder) {
            orderPrice = this.parentValue;
        } else if (!isNaN(this.lastValidParentValue)) // #49010 - здесь восстанавливаем значение цены, мышку мы отпустили и parentValue == NaN, но ордер еще не выставился
        {
            orderPrice = this.lastValidParentValue;
        }

        let curPrice = this.priceValue;

        if (!isNaN(this.priceValue)) {
            this.lastValidPriceValue = this.priceValue;
        }

        if (isNaN(this.priceValue) && !isNaN(this.lastValidPriceValue)) {
            curPrice = this.lastValidPriceValue;
        } else { curPrice = this.priceValue; }

        const ins = this.myRenderer.instrument;

        /// revertedPrice
        const revertedPrice = curPrice; // 87781 - округление //!= Nn ? ins.RoundPriceToNearestPointSize(curPrice) : curPrice;
        // orderPrice = ins.RoundPriceToNearestPointSize(orderPrice);
        const sltpPrice = !isNaN(revertedPrice) ? revertedPrice : this.Price;
        const absoluteOffset = (sltpPrice - orderPrice);
        const ticksVisualOffset = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(ins);
        const offsetTicks = SlTpUtils.calculateTicks(orderPrice, sltpPrice, ins);
        const offsetTicksStr = `${SlTpUtils.formatSLTPValue(SlTpUtils.toVisualValue(offsetTicks, ins, ticksVisualOffset), ins, ticksVisualOffset)}${SlTpUtils.getSymbolForOffsetMode(ticksVisualOffset)}`;

        let shortDescriptionText: string;
        if (!this.errorMode) {
            const pnl = this.parentOrder.Account.formatPrice(TradingToolViewBase.FuturesSlTp(this.parentOrder, absoluteOffset));
            if (!this.isTrailingStop()) {
                let offsetPercent: number;
                const sltpHolder: SlTpHolder = this.parentOrder.createSlTpHolder();
                const isHasCloseOrder = this.isSl ? !sltpHolder.isSlEmpty() : !sltpHolder.isTpEmpty();
                const isCloseOrderInPrecent = this.isSl ? sltpHolder.StopLossPriceType === SlTpPriceType.Percent : sltpHolder.TakeProfitPriceType === SlTpPriceType.Percent;
                if (isMovedParentOrder && isHasCloseOrder && isCloseOrderInPrecent) {
                    offsetPercent = this.isSl ? sltpHolder.StopLossPriceValue : sltpHolder.TakeProfitPriceValue;
                } else {
                    offsetPercent = SlTpUtils.calculatePercent(orderPrice, sltpPrice);
                }
                const offsetPercentStr = `${SlTpUtils.formatSLTPValue(SlTpUtils.toVisualValue(offsetPercent, ins, OffsetModeViewEnum.Percent), ins, OffsetModeViewEnum.Percent)}${SlTpUtils.getSymbolForOffsetMode(OffsetModeViewEnum.Percent)}`;
                shortDescriptionText = `${pnl} ${offsetTicksStr} ${offsetPercentStr}`;
            } else {
                shortDescriptionText = `${pnl} ${offsetTicksStr}`;
            }
        } else {
            shortDescriptionText = Resources.getResource('chart.visualTrading.Invalid price');
        }

        return shortDescriptionText;
    }
}
