// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { Resources } from '../../Localizations/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { MarketConsensusPanelTopTemplate } from '../../templates';
import { MarketConsensusItem } from '../cache/MarketConsensusItem';
import { QuickTableRactive } from '../elements/QuickTable/QuickTableRactive';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { OperationType } from '../../Utils/Trading/OperationType';
import { AlgorithmSubscribeType, AlgorithmType } from '../../Commons/cache/Algorithm/Algorithm';
import { DataCache } from '../../Commons/DataCache';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { PanelItemsFactory } from './PanelItemsFactory';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type QuickTableRow } from '../elements/QuickTable/QuickTableRow';
import { GetColorByRowNumber, type MarketConsensusData, type InstrumentDataResult } from '../../Commons/cache/MarketConsensus';
import { type Account } from '../../Commons/cache/Account';
import { IsAllowed } from '../../Commons/IsAllowed';

export class MarketConsensusPanel extends ApplicationPanelWithTable<MarketConsensusItem> {
    private static readonly ARROW_STEP = 45;

    private quickTableRactiveLong: QuickTableRactive | null = null;
    private quickTableRactiveShort: QuickTableRactive | null = null;
    private OnInstrumentChange: CustomEvent;

    public headerLocaleKey: string = 'panel.AlgorithmMarketConsensusPanel';
    public moreThanOneTTKey: string = 'panel.AlgorithmMarketConsensusPanel.menu.MoreOneSymbolInfoDisabled.tt';
    public noOneTTKey: string = 'panel.AlgorithmMarketConsensusPanel.menu.NoSymbolInfoDisabled.tt';
    public NeedCalculateRowCount: boolean = false;

    private marketFilter: any;
    private itemsMarketFilter: any;
    private LongMarketConsensus: any;
    private ShortMarketConsensus: any;
    private menuTagDictLong: any;
    private menuTagDictShort: any;

    public override getType (): PanelNames { return PanelNames.MarketConsensusPanel; }

    private get longQT (): QuickTable<MarketConsensusItem> | null { return this.quickTableRactiveLong?.quickTable ?? null; }

    private get shortQT (): QuickTable<MarketConsensusItem> | null { return this.quickTableRactiveShort?.quickTable ?? null; }

    public override jbInit (): void {
        const panelInfo = PanelItemsFactory.GetPanelItem(this.getType());
        if (panelInfo != null) {
            // eslint-disable-next-line new-cap
            const items = new panelInfo();
            this.quickTableRactiveLong = this.createTable(items);
            this.quickTableRactiveShort = this.createTable(items);
        }

        const qtLong = this.longQT;
        const qtShort = this.shortQT;

        qtLong.UpdateSortedColumns();
        qtShort.UpdateSortedColumns();

        qtLong.OnColumnResize.Subscribe(
            this.syncColumnsWidth.bind(this, qtLong, qtShort), this);

        qtShort.OnColumnResize.Subscribe(
            this.syncColumnsWidth.bind(this, qtShort, qtLong), this);

        this.OnResize.Subscribe(() => { this.layoutTable(); }, this);

        qtLong.SetScrollHidden(true);

        qtLong.scroll.OnValueChange.Subscribe(this.SynchronizeScroll, this);
        qtShort.scroll.OnValueChange.Subscribe(this.SynchronizeScroll, this);

        qtShort.columns[0].headerKey = 'panel.AlgorithmMarketConsensusPanel.TopShort';

        qtLong.beforeTableContextMenuShowing = this.preparePopup.bind(this);
        qtShort.beforeTableContextMenuShowing = this.preparePopup.bind(this);

        qtLong.canShowHeaderMenu = qtShort.canShowHeaderMenu = false;

        this.localize();
        this.layoutTable();
    };

    public override oninit (): void {
        super.oninit();

        this.OnInstrumentChange = new CustomEvent();
    };

    public override oncomplete (): void {
        super.oncomplete();

        [this.longQT, this.shortQT].forEach((qt) => {
            qt.OnSelectionChanged.Subscribe(this.selectionChange.bind(this, qt), this);
        });

        this.PopulateFiltersConcensus();
        this.populateTableContextMenu();
        this.localize();
        this.layoutTable();
    };

    private syncColumnsWidth (srcTable, outTable): void {
        let changeOccured = false;
        const outColumns = outTable.columns;
        const srcColumns = srcTable.columns;
        const len = srcColumns.length;
        for (let i = 0; i < len; i++) {
            const srcColumn = srcColumns[i];
            const outColumn = outColumns[i];
            if (srcColumn.width !== outColumn.width) {
                outColumn.width = srcColumn.width;
                changeOccured = true;
            }
        }

        if (changeOccured) {
            outTable.needRedrawBackground = true;
            outTable.needRedraw = true;
            outTable.OnColumnResize.Raise();
        }
    };

    private SynchronizeScroll (index): void {
        const max = Math.max(this.longQT.scroll.scrollElementsCount, this.shortQT.scroll.scrollElementsCount);
        this.longQT.scroll.scrollElementsCount = this.shortQT.scroll.scrollElementsCount = max;

        this.longQT.scroll.moveScrollToElement(index);
        this.shortQT.scroll.moveScrollToElement(index);
    };

    private createTable (items): QuickTableRactive {
        const myQuickTableRactive = new QuickTableRactive();
        this.addControl(myQuickTableRactive, true);

        const qt = myQuickTableRactive.quickTable;
        qt.allowGroupBy = false;
        qt.lockManualSorting = true;
        qt.InitializeDirect(items);
        qt.UpdateSortedColumns();

        return myQuickTableRactive;
    };

    public override layoutTable (): void {
        this.NormalizeArrows();
        const marginW = 5;
        const marginH = 25;
        const tableTop = this.get('visibleFilter') === true ? 30 : 5;

        const tableW = (this.get('width') - marginW * 2) / 2;
        let tableH = this.ContentContainerHeight() - tableTop - marginW;

        const noDataWidth = this.get('width') - 10;
        const noDataHeight = this.ContentContainerHeight() - tableTop - marginW - marginH;
        void this.set({
            noDataWidth,
            noDataHeight
        });

        if (tableH < 40) { tableH = 40; }

        if (this.quickTableRactiveLong != null) { this.quickTableRactiveLong.setBounds(marginW, tableTop, tableW, tableH); }
        if (this.quickTableRactiveShort != null) { this.quickTableRactiveShort.setBounds(marginW + tableW, tableTop, tableW, tableH); }
    };

    private NormalizeArrows (): void {
        if (!this.getNodes()) { return; }

        let visibleRightArrowFilter = true;

        const avalibleSize = this.marketFilter.offsetWidth;
        const fullSize = this.itemsMarketFilter.offsetWidth;
        const leftFilterOffset = this.itemsMarketFilter.offsetLeft;

        if (avalibleSize + Math.abs(leftFilterOffset) >= fullSize) { visibleRightArrowFilter = false; }

        const visibleLeftArrowFilter = this.itemsMarketFilter.offsetLeft < 0;
        void this.set('visibleRightArrowFilter', visibleRightArrowFilter);
        void this.set('visibleLeftArrowFilter', visibleLeftArrowFilter);
    };

    private clickLeftArrowFilter (): void {
        if (!this.getNodes()) { return; }

        let leftFilterOffset = this.itemsMarketFilter.offsetLeft;
        leftFilterOffset += MarketConsensusPanel.ARROW_STEP;
        if (leftFilterOffset > 0) { leftFilterOffset = 0; }
        void this.set('leftFilterOffset', leftFilterOffset);

        this.NormalizeArrows();
    };

    private clickRightArrowFilter (): void {
        if (!this.getNodes()) { return; }

        const avalibleSize = this.marketFilter.offsetWidth;
        const fullSize = this.itemsMarketFilter.offsetWidth;
        let leftFilterOffset = this.itemsMarketFilter.offsetLeft;
        leftFilterOffset += -MarketConsensusPanel.ARROW_STEP;

        if (avalibleSize + Math.abs(leftFilterOffset) >= fullSize) { leftFilterOffset = avalibleSize - fullSize; }
        void this.set('leftFilterOffset', leftFilterOffset);

        this.NormalizeArrows();
    };

    private getNodes (): boolean {
        if (this.marketFilter != null && this.itemsMarketFilter != null) { return true; }

        const el = this.getClientPanel();
        this.marketFilter = el.getElementsByClassName('marketFilter')[0];
        this.itemsMarketFilter = el.getElementsByClassName('itemsMarketFilter')[0];
    };

    private UpdateTables (): void {
        void this.PopulateTable(this.longQT, true);
        void this.PopulateTable(this.shortQT, false);
    };

    private async PopulateTable (qt: QuickTable, isLong: boolean): Promise<void> {
        if (qt == null) { return; }

        const items = isLong ? this.LongMarketConsensus : this.ShortMarketConsensus;

        const results = await this.FetchInstrumentData(this.getAccount(), items);

        const UpdatedMap = this.updateTable(qt, isLong, results);
        this.handleNoData(qt, UpdatedMap, isLong);
        this.updateRowColors(qt);
    }

    private async FetchInstrumentData (acc: Account, items: MarketConsensusData[]): Promise<InstrumentDataResult[]> {
        if (acc == null) return [];
        const promises = items.map(async item => {
            if (IsAllowed.CheckVisibilityForAccountInstrType(acc, item.InstrumentNameTypeId)) {
                try {
                    const instr = await DataCache.getInstrumentByInstrumentTradableID_NFL(item.TradableId, item.RouteId);
                    return { instr, item };
                } catch (error) {
                    console.error('Error fetching instrument data:', error);
                    return null;
                }
            }
            return null;
        });

        const results = await Promise.all(promises);
        return results.filter(result => !isNullOrUndefined(result?.instr)) as InstrumentDataResult[];
    }

    private updateTable (qt: QuickTable, isLong: boolean, results: InstrumentDataResult[]): Record<string, boolean> {
        const UpdatedMap: Record<string, boolean> = {};
        for (const { instr, item } of results) {
            const itemId = this.MakeItemId(item);
            const row = qt.rows[itemId];
            const color = GetColorByRowNumber(qt.rowsArray.length);

            if (row == null) {
                const qItem = new MarketConsensusItem(item, isLong, instr, color);
                qItem.ItemId = itemId;
                qt.AddItem(qItem);
            } else {
                row.item.UpdateItemData(item, color);
            }

            UpdatedMap[itemId] = true;
        }
        return UpdatedMap;
    }

    private handleNoData (qt: QuickTable, UpdatedMap: Record<string, boolean>, isLong: boolean): void {
        if (qt.rowsArray.length !== 0) {
            void this.set('noData' + (isLong ? 'Long' : 'Short'), false);
        }

        for (const rowId in qt.rows) {
            if (!UpdatedMap[rowId]) {
                qt.RemoveItem(rowId);
            }
        }
    }

    private updateRowColors (qt: QuickTable): void {
        qt.rowsArray.sort((x, y) => x.item.Value > y.item.Value ? -1 : 1);
        qt.rowsArray.forEach((row, index) => { row.item.Color = GetColorByRowNumber(index); });
        qt.redraw();
    }

    private MakeItemId (item): string {
        if (item == null) { return ''; }
        return item.InstrumentName + (item.TradableId ?? '') + (item.RouteId ?? '');
    };

    public override TickAsync (): void {
        const visible = this.get('visible');
        if (visible === false) { return; }

        [this.longQT, this.shortQT].forEach(qt => {
            if (qt?.needRedraw) {
                qt.updateRowsCellsValues();
                qt.Draw();
                qt.needRedraw = false;
            }
        });
    }

    private createFilterPanel (items): void {
        const visibleFilter = items.length > 1;
        items[0].active = true;

        void this.set({
            visibleFilter,
            arrayFilterMarket: items,
            selectedFilterMarketItem: items[0]
        });

        this.layoutTable();
        DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Subscribe, items[0].Id, this);
    };

    private clickFilterMarketItemChange (index): void {
        const items = this.get('arrayFilterMarket');
        const selectedItem = this.get('selectedFilterMarketItem');

        const newSelectedItem = items[index];
        if (newSelectedItem === selectedItem) { return; }

        if (selectedItem != null) { selectedItem.active = false; }

        newSelectedItem.active = true;

        void this.set({
            noDataLong: true,
            noDataShort: true,
            arrayFilterMarket: items,
            selectedFilterMarketItem: newSelectedItem
        });

        DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Subscribe, newSelectedItem.Id, this);
        DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Unsubscribe, selectedItem.Id, this);

        if (this.quickTableRactiveLong == null || this.quickTableRactiveShort == null) { return; }

        this.longQT.ClearAll();
        this.shortQT.ClearAll();
    };

    private PopulateFiltersConcensus (): void {
        const algorithms = DataCache.AlgorithmCache.getAlgorithmsByAlgorithmType(AlgorithmType.MarketConsensus);
        const arr = [];
        for (let i = 0; i < algorithms.length; i++) { arr.push({ Name: algorithms[i].AlgorithmName, Id: algorithms[i].AlgorithmId }); }

        arr.sort((a, b) => { return a.Name.localeCompare(b.Name); });
        if (arr.length > 0) { this.createFilterPanel(arr); }
    };

    private ProcessAlgorithm (msg): void {
        this.LongMarketConsensus = msg.TopLongList;
        this.ShortMarketConsensus = msg.TopShortList;

        this.UpdateTables();
    };

    private populateTableContextMenu (): void {
        const tables = [this.longQT, this.shortQT];
        for (const qt of tables) {
            if (qt != null) {
                const isLong = qt === this.longQT;
                const items = this.MakeItems(isLong);
                if (isLong) {
                    this.menuTagDictLong = TerceraMenu.createTagDictionary(items);
                } else {
                    this.menuTagDictShort = TerceraMenu.createTagDictionary(items);
                }
                qt.setTableContextMenuItems(items);
            }
        }
    }

    private MakeItems (isLongTable: boolean): Array<Record<string, any>> {
        const items = this.AddOpeningPanelsCM([]).filter((item) => item.locKey).reverse();

        const openOEItem = items.find((item) => { return item.tag === ApplicationPanelWithTable.OPEN_OE; });
        if (openOEItem != null) {
            openOEItem.event = this.openOEPanel.bind(this, isLongTable);
        }

        this.AddSymbolInfoContextMenuItemIfNeed(items, false);

        return items;
    };

    public override GetOpeningPanelsCMLocKeysSet (): Record<string, string> {
        const keysObj = {};

        keysObj[ApplicationPanelWithTable.OPEN_OE] = 'panel.neworder';
        keysObj[ApplicationPanelWithTable.OPEN_CHART] = 'panel.terceraChart';

        return keysObj;
    }

    private openOEPanel (isLong: boolean): void {
        const ins = this.getSelectedInstrumentID();
        MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, (panel) => {
            panel.symbolLink_In(ins);
            panel.set('side', isLong ? OperationType.Buy : OperationType.Sell);
        });
    };

    public override getSelectedInstrumentID (): string {
        const ins = this.getInstrument();
        let result = '';

        if (ins != null) { result = ins.GetInteriorID(); }

        return result;
    };

    public override getInstrument (): Instrument | null {
        const qtLong = this.longQT;
        const qtShort = this.shortQT;

        if (qtLong == null || qtShort == null) return null;

        const selectedLongRowIds = this.selectedTableRowIds(qtLong);
        const selectedShortRowIds = this.selectedTableRowIds(qtShort);

        const row = selectedLongRowIds ?? selectedShortRowIds;

        return row?.item != null ? row.item.GetCurrentInstrument() : null;
    };

    private selectedTableRowIds (qt: QuickTable): QuickTableRow {
        if (qt == null) return null;

        const selectedRowIds = qt.selectedRowIds;
        const row = selectedRowIds?.[0] != null ? qt.rows[selectedRowIds[0]] : null;

        return row;
    };

    private selectionChange (selectedTable: QuickTable): void {
        const selectedRowsId = selectedTable.selectedRowIds;

        if (selectedRowsId == null) { return; }

        const otherTable = selectedTable === this.longQT ? this.shortQT : this.longQT;
        if (otherTable?.selectedRowIds.length > 0) {
            otherTable.selectedRowIds = [];
            otherTable.redraw();
        }

        this.symbolLink_Out(this.isNewSubscriber(), this.getSelectedInstrumentID());
    };

    public override preparePopup (): void {
        this.updateTagDict(this.menuTagDictLong);
        this.updateTagDict(this.menuTagDictShort);
    };

    private updateTagDict (menuTagDict): void {
        if (menuTagDict == null) { return; }

        for (const tag in menuTagDict) {
            this.updateMenuItem(menuTagDict[tag]);
        }
    };

    public override getQuickTable (): QuickTable {
        if (this.longQT == null || this.shortQT == null) { return null; }
        const shortSelected = this.shortQT.selectedRowIds?.length !== 0;
        return shortSelected ? this.shortQT : this.longQT;
    };

    public override symbolLink_Out (newSubscriber, instrument): void {
        if (instrument == null) { return; }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) { LinkedSystem.setSymbol(color, instrument, newSubscriber); }

        this.OnInstrumentChange.Raise(instrument);
    };

    private isNewSubscriber (): boolean {
        const color = this.get('symbolLinkValue');
        if (color === TerceraLinkControlConstants.STATE_NONE) { return false; }

        return LinkedSystem.getSymbol(color) == null;
    };

    public override localize (): void {
        super.localize();

        void this.set({ noDataText: Resources.getResource('panel.AlgorithmMarketConsensusPanel.noData') });

        [this.longQT, this.shortQT].forEach(qt => {
            if (qt != null) {
                this.repopulatePanelContextMenu(qt);
                qt.localize();
            }
        });

        this.updatePanelHeader();
    };

    private repopulatePanelContextMenu (table: QuickTable): void {
        const contextItems = table.tableContextMenuItems;
        if (contextItems == null || contextItems.length === 0) { return; }

        for (let i = 0; i < contextItems.length; i++) {
            const item = contextItems[i];
            if (item != null) {
                if (item.locKey != null) { item.text = Resources.getResource(item.locKey); }
            }
        }
    };

    public override dispose (): void {
        const curIt = this.get('selectedFilterMarketItem');
        if (curIt != null) { DataCache.AlgorithmCache.SubscriptionHandler(AlgorithmSubscribeType.Unsubscribe, curIt.Id, this); }

        super.dispose();
    };
}

ApplicationPanelWithTable.extendWith(MarketConsensusPanel,
    {
        data: function () {
            return {
                canLinkByAccount: false,
                isAccountLinkShow: false,
                isSymbolLinkShow: true,
                noDataLong: true,
                noDataShort: true,
                noDataText: '',

                visibleFilter: false,
                arrayFilterMarket: [],
                selectedFilterMarketItem: null,

                leftFilterOffset: 0
            };
        },
        partials: {
            bodyPartial: MarketConsensusPanelTopTemplate
        }
    }
);
