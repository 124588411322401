// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectNewsSubscribeResponseMessage } from '../../../../Utils/DirectMessages/DirectNewsSubscribeResponseMessage';
import { SubscribeNewsGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class NewsSubscribeResponseProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectNewsSubscribeResponseMessage[] {
        const message = new DirectNewsSubscribeResponseMessage();

        const subscribeNewsGroups = fieldSet.GetGroups(FieldsFactory.SUBSCRIBE_NEWS_GROUP);
        if (subscribeNewsGroups) {
            for (let i = 0; i < subscribeNewsGroups.length; i++) {
                const subscribeNewsGroupFieldSet = subscribeNewsGroups[i];
                const subscribeNewsGroup = new SubscribeNewsGroup(subscribeNewsGroupFieldSet);
                const routeID = subscribeNewsGroup.getRouteId();

                if (routeID) {
                    message.RouteIdsArr.push(routeID);
                }
            }
        }

        return [message];
    }
}
