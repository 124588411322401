// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Connection } from '@shared/commons/Connection';
import { ConnectionStates } from '@shared/commons/ConnectionEnums';
import { Resources } from '@shared/localizations/Resources';
import { TerceraLookupDropDownForm } from './TerceraLookupDropDownForm';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { MultiComboBoxEvents, type TerceraMultiComboBox } from '../TerceraMultiComboBox';
import { type TerceraButton } from '../TerceraButton';
import { InstrumentLookupManager } from './InstrumentLookupManager';
import { InstrumentTypes } from '@shared/utils/Instruments/InstrumentTypes';
import { Instrument } from '@shared/commons/cache/Instrument';
import { TerceraInstrumentLookupDropDownFormTemplate } from '../../../templates.js';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { DataCache } from '@shared/commons/DataCache';
import { InstrumentLookupComparer } from '@shared/utils/Instruments/InstrumentLookupComparer';
import { InstrumentLookupInstrumentType } from '@shared/utils/Instruments/InstrumentLookupInstrumentType';
import { type InstrumentLookupQuickTreeNode } from '../QuickTree/InstrumentLookupQuickTreeNode';
import { InstrumentLookupProperties, InstrumentLookupType } from './InstrumentLookupManagerProps';
import { ScreensNames } from '../../UtilsClasses/FactoryConstants';

export class TerceraInstrumentLookupDropDownForm extends TerceraLookupDropDownForm<InstrumentLookupQuickTreeNode> {
    public types: TerceraMultiComboBox | null = null;
    public exchanges: TerceraMultiComboBox | null = null;
    public collapseAllBtn: TerceraButton;
    public expandExchangesBtn: TerceraButton;
    public expandTypesBtn: TerceraButton;
    public typesCBValueChangeHandler: any;
    public exchangesCBValueChangeHandler: any;

    public override getType (): ScreensNames { return ScreensNames.TerceraInstrumentLookupDropDownForm; }

    public override initializationComponents (): void {
        this.textBox = this.Controls.textBox;
        this.exchanges = this.Controls.exchanges;
        this.types = this.Controls.types;
        this.collapseAllBtn = this.Controls.collapseAllBtn;
        this.expandExchangesBtn = this.Controls.expandExchangesBtn;
        this.expandTypesBtn = this.Controls.expandTypesBtn;

        const qtr = this.Controls.quickTree;
        if (!isNullOrUndefined(qtr?.quickTree)) {
            this.quickTree = qtr;
            qtr.quickTree.rowHeigth = 35;
            qtr.quickTree.ClearColor = '#262c3b';
            qtr.quickTree.reInitScrollHeigth();
        }
    }

    public static expandNodes (nodes: InstrumentLookupQuickTreeNode[], expandNodeLevel: number, currentNodeLevel?: number): void {
        if (!isValidArray(nodes)) { return; }

        const len = nodes.length;
        if (!len) { return; }

        currentNodeLevel = isNullOrUndefined(currentNodeLevel) ? 0 : currentNodeLevel;

        const collapse = currentNodeLevel > expandNodeLevel;
        const nextNodeLevel = currentNodeLevel + 1;

        for (let i = 0; i < len; i++) {
            const node = nodes[i];
            if (node.childNodes.length > 0) {
                node.collapsed = collapse;
            }
            TerceraInstrumentLookupDropDownForm.expandNodes(node.childNodes, expandNodeLevel, nextNodeLevel);
        }
    }

    public collapseAll (): void {
        const qt = this.quickTree.quickTree;
        const nodeCollection = qt.nodeCollection;
        TerceraInstrumentLookupDropDownForm.expandNodes(nodeCollection, 0, 1);
        qt.Draw(true);
    }

    public clickExchange (bool): void {
        let type = NodeType.InstrumentType;
        if (bool) {
            type = NodeType.Exchange;
        }

        this.expandNodesByType(type);
    }

    public expandNodesByType (type: NodeType): void {
        const qt = this.quickTree.quickTree;
        const nodeCollection = qt.nodeCollection;

        if (!this.needShowExchanges()) // https://tp.traderevolution.com/entity/84756 (3rd issue)
        {
            type = type === NodeType.Exchange
                ? NodeType.InstrumentType
                : NodeType.InstrumentGroup;
        }

        const level = TerceraInstrumentLookupDropDownForm.findNodeTypeTreeLevel(nodeCollection, type);

        if (level === -1) { return; }

        TerceraInstrumentLookupDropDownForm.expandNodes(nodeCollection, level);
        qt.Draw(true);
    }

    public static getNodeType (node: InstrumentLookupQuickTreeNode): NodeType {
        if (isNullOrUndefined(node)) { return null; }

        const tag = node.tag;

        if (tag === 'exchange') { return NodeType.Exchange; }

        if (typeof tag === 'number') { return NodeType.InstrumentType; }

        if (tag === 'group') { return NodeType.InstrumentGroup; }

        if (tag instanceof Instrument) { return NodeType.Instrument; }

        return NodeType.Other;
    }

    // TODO. Optimize if necessary.
    public static findNodeTypeTreeLevel (nodeArray: InstrumentLookupQuickTreeNode[], nodeType: NodeType, currentNodeLevel?: number): number {
        if (!isValidArray(nodeArray)) {
            return -1;
        }

        currentNodeLevel = isNullOrUndefined(currentNodeLevel) ? 0 : currentNodeLevel;

        // TODO. Remove loop?
        for (let i = 0, len = nodeArray.length; i < len; i++) {
            const node = nodeArray[i];
            if (TerceraInstrumentLookupDropDownForm.getNodeType(node) === nodeType) {
                return currentNodeLevel;
            }

            const foundNodeLevel = TerceraInstrumentLookupDropDownForm.findNodeTypeTreeLevel(
                node.childNodes, nodeType, currentNodeLevel + 1);

            if (foundNodeLevel !== -1) {
                return foundNodeLevel;
            }
        }

        return -1;
    }

    public selectFirstTopNode (): void {
        const qt = this.quickTree.quickTree;
        const plainArray = qt.plainArray;
        if (plainArray.length > 0) {
            qt.CheckUnCheckAll(false);
            qt.setSelectedNode(plainArray[0]);
        }
    }

    public arrangeControls (): void {
        const exchangesCB = this.exchanges;
        const showExchanges = this.needShowExchanges();
        void exchangesCB.set('visible', showExchanges);

        const qTree = this.quickTree;
        qTree.setSizes();
    }

    public needShowExchanges (): boolean {
        const exchanges = this.exchanges;
        if (isNullOrUndefined(exchanges)) return false;

        const items = exchanges.get('items');
        return isValidArray(items);
    }

    public needShowTypes (): boolean {
        const types = this.types;
        if (isNullOrUndefined(types)) return false;

        const items = types.get('items');
        return isValidArray(items);
    }

    public getInstrumentFromSelectedNode (): Instrument | null {
        const selectedNode = this.quickTree.quickTree.selectedNode;
        const tag = !isNullOrUndefined(selectedNode) ? selectedNode.tag : null;
        return tag instanceof Instrument ? tag : null;
    }

    public static override async createInstance (): Promise<TerceraInstrumentLookupDropDownForm> {
        const lookup = new TerceraInstrumentLookupDropDownForm();
        lookup.setBounds(0, 0, 535, 580);
        void lookup.set('visible', false);
        MainWindowManager.MainWindow.addControl(lookup);

        Connection.onConnectStateChange.Subscribe(
            lookup.onConnectionStateChange,
            lookup);

        return await lookup.OnCompliteWaiter;
    }

    public static ShowForm (parametersStruct): void {
        let instanceWaiter = null;
        if (isNullOrUndefined(MainWindowManager.TerceraInstrumentLookupDropDownForm)) {
            instanceWaiter = TerceraInstrumentLookupDropDownForm.createInstance().then((instance) => {
                MainWindowManager.TerceraInstrumentLookupDropDownForm = instance;
                return instance;
            });
        } else {
            instanceWaiter = Promise.resolve(MainWindowManager.TerceraInstrumentLookupDropDownForm);
        }

        // TODO. Ugly. Refactor.
        // Resetting stuff.
        // These setters don't invoke fillTree() due to unsubscribing before.
        // Thus, combo boxes will be populated only once.
        instanceWaiter.then((instance) => {
            instance.unsubscribeCBValueChanged();
            instance.types.set('items', null);
            instance.exchanges.set('items', null);
            instance.currentNameFilter = instance.oldNameFilter = null;

            instance.showForm(parametersStruct);
        });
    }

    public subscribeCBValueChanged (): void {
        const typesCB = this.types;
        const exchangesCB = this.exchanges;

        const boundFillTree = this.fillTree.bind(this);

        this.typesCBValueChangeHandler =
        typesCB.on(MultiComboBoxEvents.ValueChange, boundFillTree);

        this.exchangesCBValueChangeHandler =
        exchangesCB.on(MultiComboBoxEvents.ValueChange, boundFillTree);
    }

    public unsubscribeCBValueChanged (): void {
        if (!isNullOrUndefined(this.typesCBValueChangeHandler)) {
            this.typesCBValueChangeHandler.cancel();
            this.typesCBValueChangeHandler = null;
        }

        if (!isNullOrUndefined(this.exchangesCBValueChangeHandler)) {
            this.exchangesCBValueChangeHandler.cancel();
            this.exchangesCBValueChangeHandler = null;
        }
    }

    public onConnectionStateChange (): void {
        const connectionStates = ConnectionStates;
        const connectionState = Connection.connectionState;
        if (connectionState === connectionStates.DISCONNECTED ||
        connectionState === connectionStates.CONNECTION_LOST) {
            MainWindowManager.TerceraInstrumentLookupDropDownForm = null;

            Connection.onConnectStateChange.UnSubscribe(
                this.onConnectionStateChange,
                this);

            this.dispose();
        }
    }

    // TODO. UGLY. Refactor.
    public override fillTree (): void {
        this.unsubscribeCBValueChanged();

        const isAllExchangesChecked = !isNullOrUndefined(this.exchanges) ? this.exchanges.isAllItemsChecked() : false;
        const isAllTypesChecked = !isNullOrUndefined(this.types) ? this.types.isAllItemsChecked() : false;
        const filteredExchanges = this.getFilteredExchanges();
        const filteredInstrumentTypes = this.getFilteredInstrumentTypes();

        const allInstruments = this.isOptionMasterMode ? InstrumentLookupComparer.sortOptionList(this.items, this.currentNameFilter ?? '') : InstrumentLookupComparer.sortInstrumentList(this.items, this.currentNameFilter ?? '');
        const visibleInstruments = allInstruments.filter((instrument: Instrument) => {
            const instrumentType = instrument.CFD ? InstrumentTypes.EQUITIES_CFD : instrument.InstrType;
            const exchange = instrument.TradingExchange;
            return !instrument.NeedToHide() && (isAllExchangesChecked || filteredExchanges.Contains(exchange)) && (isAllTypesChecked || filteredInstrumentTypes.Contains(instrumentType));
        });
        this.items = allInstruments;
        const properties = new InstrumentLookupProperties(InstrumentLookupType.Big, visibleInstruments, this.quickTree.quickTree, this.currentNameFilter);
        properties.isOptionMaster = this.isOptionMasterMode;
        properties.dataProvider = this.dataProvider;
        properties.selectedInstruments = this.selectedItem;
        InstrumentLookupManager.fillTree(properties);

        const visibleInstrumentTypes: InstrumentTypes[] = [];
        const visibleExchanges: string[] = [];
        for (let i = 0; i < visibleInstruments.length; i++) {
            const instrument = visibleInstruments[i];
            const instrumentType = instrument.CFD ? InstrumentTypes.EQUITIES_CFD : instrument.InstrType;
            if (!visibleExchanges.Contains(instrument.TradingExchange)) {
                visibleExchanges.push(instrument.TradingExchange);
            }
            if (!visibleInstrumentTypes.Contains(instrumentType) && !(InstrumentTypes.FUTURES === instrumentType && InstrumentUtils.UseFuturesInsteadSpot())) {
                visibleInstrumentTypes.push(instrumentType);
            }
        }
        this.populateExchangesComboBox(visibleExchanges);
        this.populateTypesComboBox(visibleInstrumentTypes);

        this.subscribeCBValueChanged();

        this.arrangeControls();
    }

    public populateExchangesComboBox (exchanges: string[]): void {
        if (isNullOrUndefined(this.exchanges)) {
            return;
        }
        const exchangesMap = this.exchanges.getItemsMap();
        const newItems = [];
        for (const exchangeEntry of exchangesMap) {
            const exchange = exchangeEntry[0];
            const checked = exchangeEntry[1];
            newItems.push({
                value: exchange,
                text: exchange,
                checked
            });
        }
        for (let i = 0; i < exchanges.length; i++) {
            const exchange = exchanges[i];
            if (exchangesMap.has(exchange)) {
                continue;
            }
            newItems.push({
                value: exchange,
                text: exchange,
                checked: true
            });
        }
        newItems.sort(TerceraInstrumentLookupDropDownForm.sortFilterItems);
        void this.exchanges.set('items', newItems);
    }

    public populateTypesComboBox (instrumentTypes: InstrumentTypes[]): void {
        if (isNullOrUndefined(this.types)) {
            return;
        }
        const typesMap = this.types.getItemsMap();
        const newItems = [];
        for (const typeEntry of typesMap) {
            const insType = typeEntry[0];
            const checked = typeEntry[1];
            newItems.push({
                value: insType,
                text: InstrumentUtils.getInstrumentTypeStringLocalized(insType, false),
                checked
            });
        }
        for (let i = 0; i < instrumentTypes.length; i++) {
            const insType = instrumentTypes[i];
            if (typesMap.has(insType)) {
                continue;
            }
            newItems.push({
                value: insType,
                text: InstrumentUtils.getInstrumentTypeStringLocalized(insType, false),
                checked: true
            });
        }
        newItems.sort(TerceraInstrumentLookupDropDownForm.sortFilterItems);
        void this.types.set('items', newItems);
    }

    private getFilteredInstrumentTypes (): InstrumentTypes[] {
        const selectedInstrumentTypes: InstrumentTypes[] = [];
        if (isNullOrUndefined(this.types)) {
            return selectedInstrumentTypes;
        }
        selectedInstrumentTypes.push(...this.types.getSelectedItems());
        const selectedTypesMap: Map<InstrumentTypes, boolean> = this.types.getItemsMap();
        if (DataCache.NonFixedList) {
            const nonFixedListTypes = InstrumentLookupInstrumentType.getNonFixedListTypes();
            for (let i = 0; i < nonFixedListTypes.length; i++) {
                const nonFixedListType = nonFixedListTypes[i];
                if (selectedInstrumentTypes.Contains(nonFixedListType)) {
                    continue;
                }
                if (!selectedTypesMap.has(nonFixedListType)) {
                    selectedInstrumentTypes.push(nonFixedListType);
                }
            }
        }
        return selectedInstrumentTypes;
    }

    private getFilteredExchanges (): string[] {
        const selectedExchanges: string[] = [];
        if (isNullOrUndefined(this.exchanges)) {
            return selectedExchanges;
        }
        selectedExchanges.push(...this.exchanges.getSelectedItems());
        const selectedExchangesMap: Map<string, boolean> = this.exchanges.getItemsMap();
        if (DataCache.NonFixedList) {
            const nonFixedExchanges = DataCache.TradingExchanges;
            for (let i = 0; i < nonFixedExchanges.length; i++) {
                const nonFixedExchange = nonFixedExchanges[i];
                if (selectedExchanges.Contains(nonFixedExchange)) {
                    continue;
                }
                if (!selectedExchangesMap.has(nonFixedExchange)) {
                    selectedExchanges.push(nonFixedExchange);
                }
            }
        }
        return selectedExchanges;
    }

    public override populateAsync (): void {
        clearTimeout(this.asyncPopulateInterval);
        this.asyncPopulateInterval = setTimeout(this.beforePopulate.bind(this), 300);
    }

    public beforePopulate (list: any[]): void {
        if (this.currentNameFilter === this.oldNameFilter) {
            if (!isNullOrUndefined(list)) {
                this.items = list;
            }

            this.fillTree();
            return;
        }

        const me = this;
        this.oldNameFilter = this.currentNameFilter;

        if (!isNullOrUndefined(this.dataProvider) && this.currentNameFilter) {
            if (this.isOptionMasterMode) {
                void this.dataProvider.getOptionsList(this.currentNameFilter, null)
                    .then(function (instruments) {
                        me.beforePopulate(instruments);
                    });
            } else {
                void this.dataProvider.getInstrumentsList(this.currentNameFilter, null, null, true)
                    .then(function (instruments) {
                        me.beforePopulate(instruments);
                    });
            }
        } else if (!isNullOrUndefined(this.cachedItems)) {
            this.items = this.cachedItems;
            this.fillTree();
        }
    }

    public override themeChange (): void {
        super.themeChange();

        const collapseAllBtn = this.collapseAllBtn;
        if (!isNullOrUndefined(collapseAllBtn)) {
            void collapseAllBtn.set('terceraButtonStyle', 'js-AllSymbol-Icon');
        }

        const expandExchangesBtn = this.expandExchangesBtn;
        if (!isNullOrUndefined(expandExchangesBtn)) {
            void expandExchangesBtn.set('terceraButtonStyle', 'js-NodeExchanges-Icon');
        }

        const expandTypesBtn = this.expandTypesBtn;
        if (!isNullOrUndefined(expandTypesBtn)) {
            void expandTypesBtn.set('terceraButtonStyle', 'js-NodeTypes-Icon');
        }
    }

    public override localize (): void {
        super.localize();

        const collapseAllBtn = this.collapseAllBtn;
        if (!isNullOrUndefined(collapseAllBtn)) {
            void collapseAllBtn.set('tooltip',
                Resources.getResource('panel.TerceraSymbolLookupDropDownForm.collapceAllButton'));
        }

        const expandExchangesBtn = this.expandExchangesBtn;
        const expandExchangesTooltip = this.needShowExchanges()
            ? Resources.getResource('panel.TerceraSymbolLookupDropDownForm.expandExchangesButton')
            : Resources.getResource('panel.TerceraSymbolLookupDropDownForm.expandTypesButton'); // #85255
        if (!isNullOrUndefined(expandExchangesBtn)) {
            void expandExchangesBtn.set('tooltip', expandExchangesTooltip);
        }

        const expandTypesBtn = this.expandTypesBtn;
        const expandTypesTooltip = this.needShowExchanges()
            ? Resources.getResource('panel.TerceraSymbolLookupDropDownForm.expandTypesButton')
            : Resources.getResource('panel.TerceraSymbolLookupDropDownForm.expandGroupsButton'); // #85255
        if (!isNullOrUndefined(expandTypesBtn)) {
            void expandTypesBtn.set('tooltip', expandTypesTooltip);
        }

        const exchangesCB = this.exchanges;
        if (!isNullOrUndefined(exchangesCB)) {
            void exchangesCB.set({
                allItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges'),
                noItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges'),
                fewItemsSelectedPostfixText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges')
            });
        }
        const typesCB = this.types;
        if (!isNullOrUndefined(typesCB)) {
            void typesCB.set({
                allItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types'),
                noItemsSelectedText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types'),
                fewItemsSelectedPostfixText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.typeComboBox.types')
            });
        }
    }

    public override getSelectedNodesCount (): number {
        if (this.get<boolean>('isMultiSelect')) {
            const qt = this.quickTree.quickTree;
            return Object.values(qt.selectedNodesDict).filter((node) => {
                const instrument: Instrument = node.tag;
                return !isNullOrUndefined(instrument) && !(instrument.isOptionSymbol && instrument.StrikePrice <= 0);
            }).length;
        } else {
            return super.getSelectedNodesCount();
        }
    }

    public override isButtonAddEnable (): boolean {
        const qt = this.quickTree.quickTree;
        const result = super.isButtonAddEnable();
        if (this.get<boolean>('isMultiSelect')) {
            const hasNonNullInstrumentTradableID = Object.values(qt.selectedNodesDict).some(
                (node) => {
                    const instrument: Instrument = node.tag;
                    return !isNullOrUndefined(instrument) && !(instrument.isOptionSymbol && instrument.StrikePrice <= 0);
                }
            );

            return result && hasNonNullInstrumentTradableID;
        } else {
            return result && !isNullOrUndefined(qt.selectedNode?.tag?.InstrumentTradableID);
        }
    }

    protected override isValidResult (nodeTag: any): boolean {
        const instrument = nodeTag as Instrument;
        if (isNullOrUndefined(instrument)) {
            return false;
        }
        return isValidNumber(instrument.InstrumentTradableID);
    }

    public static sortFilterItems (i1, i2): number {
        return i1.text.toLowerCase().localeCompare(i2.text.toLowerCase());
    }
}

enum NodeType {
    Exchange = 0,
    InstrumentType = 1,
    InstrumentGroup = 2,
    Instrument = 3,
    Other = 4
}

TerceraLookupDropDownForm.extendWith(TerceraInstrumentLookupDropDownForm, {
    partials: { bodyPartial: TerceraInstrumentLookupDropDownFormTemplate }
});
