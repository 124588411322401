// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { SLTPTrigger } from '../Utils/SlTpTrigger';
import { Connection } from './Connection';
import { DataCache } from './DataCache';
import { DynProperty } from './DynProperty';
import { ErrorInformationStorage } from './ErrorInformationStorage';
import { SessionSettingsConst } from './SessionSettingsConst';
import { BaseSettings } from './Settings/BaseGeneralSettingsWrapper';
import { InsDefSettings } from './cache/InstrumentDefaults';
import { LOCALE_EN, Resources } from '../Localizations/Resources';

export class SettingsLoggingManager {
    public static lastLogs: any;

    public static LogChange (oldProps: DynProperty[], newProps: DynProperty[], isSnapshot = false): void {
        let propsForLog = [];
        const loggingAllowed = DataCache.AllowLoggingChanges();

        if (!newProps || !loggingAllowed) {
            return;
        }

        for (let i = 0; i < newProps.length; i++) {
            if (SettingsLoggingManager.NeedLogOnChangeByProp(newProps[i])) {
                const newProp = newProps[i];
                const propName = newProp.name;
                const oldProp = DynProperty.getPropertyByName(oldProps, propName);

                if (oldProp) {
                    propsForLog = propsForLog.concat(SettingsLoggingManager.GetDiffer(newProp, oldProp, isSnapshot));
                }
            }
        }

        if (propsForLog.length > 0) {
            Connection.vendor.SendLogsWhenSettingsWasChanged(SettingsLoggingManager.GetInfoForLogging(propsForLog, isSnapshot));
            BaseSettings.logSnapshotHasBeenSent = true;
        }
    }

    public static LastLogs (): void {
        let logs = SettingsLoggingManager.lastLogs;
        let date = null;

        if (logs) {
            date = logs.date;
            logs = logs.logs;
        }

        if (date) {
            console.log('%s %O\nheader: %s\nversion: %s\n', date.toLocaleString(), logs.msgBody, logs.header, logs.version);
        } else {
            console.log('No logs yet');
        }
    }

    public static GetDiffer (newProp, oldProp, isSnapshot = false): any {
        if (!newProp || !oldProp) {
            return null;
        }

        if (newProp.name == SessionSettingsConst.TYPES_MANAGER) {
            let newValue = newProp.value;
            if (!newValue.GetDiffer) {
                newValue = SettingsLoggingManager.GetInsDefSettingsFromJSONString(newValue);
            }

            if (oldProp.value && !oldProp.value.GetDiffer) {
                oldProp.value = SettingsLoggingManager.GetInsDefSettingsFromJSONString(oldProp.value);
            }

            return newValue.GetDiffer(oldProp.value, isSnapshot);
        } else {
            return oldProp.value == newProp.value && !isSnapshot ? [] : [newProp];
        }
    }

    public static GetInsDefSettingsFromJSONString (jsonStr): InsDefSettings {
        if (!jsonStr) {
            return null;
        }

        const insDefSettings = new InsDefSettings();
        insDefSettings.LoadFromXML(jsonStr);

        return insDefSettings;
    }

    public static NeedLogOnChangeByProp (prop): boolean {
        if (!prop?.name) {
            return false;
        }

        const propsNamesWhoNeedLogging = [
            // Вкладка Trading defaults:
            SessionSettingsConst.SET_SLTP_VALUES_IN_OFFSET,
            SessionSettingsConst.SHOW_OFFSET_IN,
            SessionSettingsConst.USE_STOP_LIMIT_INSTEAD_OF_STOP,
            // SLTPTrigger.HIDDEN_DYN_PROPERTY_NAME,
            SLTPTrigger.BUY_TP_DYN_PROPERTY_NAME,
            SLTPTrigger.BUY_SL_DYN_PROPERTY_NAME,
            SLTPTrigger.SELL_TP_DYN_PROPERTY_NAME,
            SLTPTrigger.SELL_SL_DYN_PROPERTY_NAME,
            SLTPTrigger.TRIGGER_IN_OE_DYN_PROPERTY_NAME,
            // Вкладка Confirmations:
            SessionSettingsConst.CONFIRM_ORDER_PLACEMENT,
            SessionSettingsConst.CONFIRM_ORDER_CANCELLATION,
            SessionSettingsConst.CONFIRM_ORDER_OR_POSITION_MODIFICATION,
            SessionSettingsConst.CONFIRM_POSITION_CLOSING,
            SessionSettingsConst.CONFIRM_ALERT_REMOVE,
            SessionSettingsConst.SHOW_DEAL_TICKETS,
            SessionSettingsConst.SHOW_MARGIN_DEAL_TICKETS_ON_WORKSPACE,
            // Вкладка View:
            SessionSettingsConst.IS_ROUNDED_AVERAGE_OPEN_PRICE,
            SessionSettingsConst.DISPLAY_QUANTITY_IN_LOTS,
            SessionSettingsConst.REVERSE_BUTTONS_ORDER,
            SessionSettingsConst.TOOLTIPS,
            // TypesManager:
            SessionSettingsConst.TYPES_MANAGER
        ];

        return propsNamesWhoNeedLogging.includes(prop.name);
    }

    public static GetInfoForLogging (dynPropertyArray, isSnapshot = false) {
        const jsonPropsInfo = dynPropertyArray.map((prop) => {
            const action = prop.action ? prop.action : SettingsLoggingManager.ACTIONS.CHANGED;
            const res: any = {
                name: Resources.getResourceLang(prop.localizationKey, LOCALE_EN),
                value: SettingsLoggingManager.GetValueForProp(prop),
                location: SettingsLoggingManager.GetLocationForProp(prop),
                action: isSnapshot ? SettingsLoggingManager.ACTIONS.ADDED : action
            };

            if (prop.entity) {
                res.entityName = prop.entity;
            }

            return res;
        });

        const result = {
            version: ErrorInformationStorage.buildVersion,
            code: '',
            // 'date': new Date(),  //#111674
            header: 'General settings' + (isSnapshot ? ' - snapshot' : ''),
            msgBody: jsonPropsInfo
        };

        SettingsLoggingManager.lastLogs = { logs: result, date: new Date() };

        return result;
    }

    public static GetValueForProp (prop): any {
        if (!prop) {
            return null;
        }

        if (prop.name == SessionSettingsConst.SHOW_OFFSET_IN) {
            const v = prop.objectVariants ? prop.objectVariants[prop.value] : null;
            return Resources.getResourceLang(v.key, LOCALE_EN);
        }

        const triggers = [SLTPTrigger.BUY_TP_DYN_PROPERTY_NAME, SLTPTrigger.BUY_SL_DYN_PROPERTY_NAME, SLTPTrigger.SELL_TP_DYN_PROPERTY_NAME, SLTPTrigger.SELL_SL_DYN_PROPERTY_NAME];
        if (triggers.includes(prop.name)) {
            return SLTPTrigger.ConvertBidAskRawValueToStr(prop.value + 1);
        }

        return prop.value;
    }

    public static GetLocationForProp (prop): any {
        if (!prop) {
            return '';
        }

        if (prop.entity) {
            return SettingsLoggingManager.TYPES_MANAGER;
        }

        if (prop.group == DynProperty.TRADING_DEFAULTS_GROUP) {
            return SettingsLoggingManager.TRADING_DEFAULTS_LOCATION;
        }

        return prop.group;
    }

    public static readonly TYPES_MANAGER = 'Types manager';
    public static readonly TRADING_DEFAULTS_LOCATION = 'Trading defaults';
    public static readonly ACTIONS = { CHANGED: 'changed', ADDED: 'added', REMOVED: 'removed' };
}
