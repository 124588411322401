// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { LinkedSystem } from '../misc/LinkedSystem';
import { TimeSalesPanelTemplate } from '../../templates';
import { TimeSalesItem, TSItemType } from '../cache/TimeSalesItem';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ThemeManager } from '../misc/ThemeManager';
import { AgressorFlagType } from '../../Utils/Enums/Constants';
import { RouteUtils } from '../../Commons/cache/RouteUtils';
import { DynProperty, PairColor } from '../../Commons/DynProperty';
import { QuoteValid } from '../../Utils/Quotes/QuoteValid';
import { Quantity } from '../../Utils/Trading/Quantity';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type DirectQuote3Message } from '../../Utils/DirectMessages/DirectQuote3Message';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';

export class TimeSalesPanel extends ApplicationPanelWithTable<TimeSalesItem> {
    public static readonly MAX_ROW_COUNT = 500;

    public isSeparatedSourceMode: boolean | null = null;
    public rowIdCounter: number = 0;

    public cMIDLO: any = null;
    public cMIDHI: any = null;
    public cBIDLO: any = null;
    public cBID: any = null;
    public cASKHI: any = null;
    public cASK: any = null;
    public cQUOTE: any = null;
    public cASKHIbackground: any = null;
    public cASKbackground: any = null;
    public cBIDbackground: any = null;
    public cBIDLObackground: any = null;
    public cMIDLObackground: any = null;
    public cQUOTEbackground: any = null;

    constructor () {
        super();

        this.Name = 'TimeSalesPanel';
        this.topPanelHeight = 25;
        this.NeedCalculateRowCount = false;
        this.headerLocaleKey = 'panel.timeSales';
    }

    public override getType (): PanelNames { return PanelNames.TimeSalesPanel; }

    public override oninit (): void {
        super.oninit();

        this.observe('instrument', this.changeInstrument);
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.themeChange();
        this.changeInstrument(this.get('instrument'), null);
    }

    public override updatePanelHeader (): void {
        const ins: Instrument = this.get('instrument');
        void this.set({
            header:
            Resources.getResource(this.headerLocaleKey) +
            (!isNullOrUndefined(ins) ? (' ' + ins.DisplayName()) : '')
        });
    }

    public changeInstrument (instrument: Instrument, lastInstrument: Instrument): void {
        if (isNullOrUndefined(instrument) || instrument === lastInstrument || !this.completed) {
            return;
        }

        this.isSeparatedSourceMode = RouteUtils.isSeparatedSourceMode(instrument);
        this.unsubscribeInstrument(lastInstrument);
        this.clearTable();
        this.subscribeInstrument();
        this.symbolLink_Out(false, instrument);
        this.updatePanelHeader();
    }

    // #region ICaller

    public override callBack (properties: DynProperty[]): void {
        super.callBack(properties);
        void this.set('instrument', this.getCallBackInstrument(properties, 'symbol'));
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();
        const ins: Instrument = this.get('instrument');
        if (!isNullOrUndefined(ins)) {
            properties.push(new DynProperty('symbol', ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        return properties;
    }

    // #endregion

    public subscribeInstrument (): void {
        const value: Instrument = this.get('instrument');

        if (!isNullOrUndefined(value?.DataCache)) {
            value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_LEVEL1);
            value.DataCache.FQuoteCache.addListener(value, this, HistoryType.QUOTE_TRADES);
        }
    }

    public unsubscribeInstrument (lastInstrument: Instrument): void {
        if (isNullOrUndefined(lastInstrument?.DataCache)) {
            return;
        }

        lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_LEVEL1);
        lastInstrument.DataCache.FQuoteCache.removeListener(lastInstrument, this, HistoryType.QUOTE_TRADES);
    }

    public newQuote (tradeMessage: DirectQuote3Message): void {
        if (tradeMessage.Type !== HistoryType.QUOTE_TRADES) {
            return;
        }

        const ins: Instrument = this.get('instrument');
        if (isNullOrUndefined(ins)) { return; }

        if (isNullOrUndefined(this.quickTableRactive)) {
            return;
        }

        // Fix for continuous contracts.
        if (ins.GetInteriorID() !== tradeMessage.TargetInstrumentName) {
            return;
        }

        let size = tradeMessage.Size;
        const inLots = WDSettingsUtils.displayAmountInLots();
        if (inLots) {
            size = Quantity.convertQuantityValue(new Quantity(size, false), ins, inLots, this.getAccount() /* , productType /* TODO ? */);
        }

        const lastQuote = ins.GetLastQuote(QuoteValid.Last);

        if (isNullOrUndefined(lastQuote)) {
            return;
        }

        const res = TimeSalesPanel.getTimeSalesItemData(
            tradeMessage,
            lastQuote.Bid,
            lastQuote.Ask,
            true);

        const item = new TimeSalesItem(SessionSettings);
        item.ItemId = this.rowIdCounter++;
        item.instrument = ins;
        item.isSeparatedSourceMode = this.isSeparatedSourceMode;
        item.aggressorFlag = res.correctAggressorFlag;
        item.size = size;
        item.colorValue = res.colorValue;
        item.itemType = res.tsItemType;
        item.time = new Date(tradeMessage.cTime);
        item.price = tradeMessage.Price;
        item.exchange = tradeMessage.Exchange;
        item.lastVenue = tradeMessage.LastVenue;
        item.buyerSource = tradeMessage.BuyerSource;
        item.sellerSource = tradeMessage.SellerSource;

        const qt = this.getQuickTable();
        const row = qt.AddItem(item);
        if (!isNullOrUndefined(row)) {
        // TODO. Ugly.
            const colors = this.getRowColors(item.itemType);
            row.ForeColor = colors.Color1;
            row.BackColor = colors.Color2;
        }

        // TODO. Removing the oldest item.
        if (qt.rowsArray.length > TimeSalesPanel.MAX_ROW_COUNT) {
            qt.RemoveItem(this.rowIdCounter - 1 - TimeSalesPanel.MAX_ROW_COUNT);
        }

        // TODO. Ugly.
        qt.needRedrawBackground = true;
        qt.needRedraw = true;
    }

    public override dispose (): void {
        super.dispose();
        this.unsubscribeInstrument(this.get('instrument'));
    }

    public override jbInit (): void {
        super.jbInit();
        this.getQuickTable().AddToEnd = false;
        this.getQuickTable().lockManualSorting = true;
        this.updateHiddenColumns();
    }

    public updateHiddenColumns (): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) {
            return;
        }

        const isSeparatedSourceMode = this.isSeparatedSourceMode;

        qt.setHiddenColumnsData({
        /* Exchange */4: (isSeparatedSourceMode || Resources.isHidden(qt.columns[4].headerKey)),
            /* Buyer */12: (!isSeparatedSourceMode || Resources.isHidden(qt.columns[12].headerKey)),
            /* Seller */13: (!isSeparatedSourceMode || Resources.isHidden(qt.columns[13].headerKey))
        });
    }

    public override symbolLink_Out (newSubscriber, instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            const ins: Instrument = this.get('instrument');
            if (isNullOrUndefined(ins)) return;
            instrument = ins;
        }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
        }
    }

    public override getInstrument (): Instrument {
        return this.get('instrument');
    }

    public override symbolLink_In (symbolName: string): void {
        const newInstr = DataCache.getInstrumentByName(symbolName);
        if (!isNullOrUndefined(newInstr)) {
            void this.set('instrument', newInstr);
        }
    }

    public override repopulate (): void {
        super.repopulate();
        void this.set('instrument', this.get('instrument'));
    }

    public getRowColors (tsItemType: TSItemType): PairColor {
        switch (tsItemType) {
        case TSItemType.AboveAsk:
            return new PairColor(this.cASKHI, this.cASKHIbackground);
        case TSItemType.AtAsk:
            return new PairColor(this.cASK, this.cASKbackground);
        case TSItemType.AtBid:
            return new PairColor(this.cBID, this.cBIDbackground);
        case TSItemType.BelowBid:
            return new PairColor(this.cBIDLO, this.cBIDLObackground);
        case TSItemType.BetweenSpread:
            return new PairColor(this.cMIDLO, this.cMIDLObackground);
        case TSItemType.Quotes:
            return new PairColor(this.cQUOTE, this.cQUOTEbackground);
        default:
            return null;
        }
    }

    public override themeChange (): void {
        super.themeChange();

        const theme = ThemeManager.CurrentTheme;

        this.cMIDLO = theme.TS_cMIDLO;
        this.cMIDHI = theme.TS_cMIDHI;

        this.cBIDLO = theme.TS_cBIDLO;
        this.cBID = theme.TS_cBID;

        this.cASKHI = theme.TS_cASKHI;
        this.cASK = theme.TS_cASK;

        this.cQUOTE = theme.TS_cQUOTE;

        this.cASKHIbackground = theme.TS_cASKHIbackground;
        this.cASKbackground = theme.TS_cASKbackground;
        this.cBIDbackground = theme.TS_cBIDbackground;
        this.cBIDLObackground = theme.TS_cBIDLObackground;

        this.cMIDLObackground = theme.TS_cMIDLObackground;
        this.cQUOTEbackground = theme.TS_cQUOTEbackground;

        const qtRactive = this.quickTableRactive;
        if (isNullOrUndefined(qtRactive)) return;

        const qt = qtRactive.quickTable;
        if (isNullOrUndefined(qt)) return;

        const rowDict = qt.rows;
        if (isNullOrUndefined(rowDict)) return;

        for (const key in rowDict) {
            const item = rowDict[key].item;
            if (isNullOrUndefined(item)) continue;

            const colors = this.getRowColors(item.itemType);
            const row = qt.rows[item.ItemId];
            if (!isNullOrUndefined(colors)) {
                row.ForeColor = colors.Color1;
                row.BackColor = colors.Color2;
            }
        }

        // TODO. Ugly.
        qt.needRedrawBackground = true;
        qt.needRedraw = true;
    }

    // TODO. Rename.
    // Returns colorValue, agressorFlag, TSItemType.
    public static getTimeSalesItemData (msg: DirectQuote3Message, bid: number, ask: number, realTime: boolean): { colorValue: number, tsItemType: TSItemType, correctAggressorFlag: AgressorFlagType } {
        let correctAggressorFlag = msg.LType;
        let tsItemType: TSItemType = null;
        let colorValue: number = null;

        const aggressorFlagTypes = AgressorFlagType;
        // CrossTrade & Auction
        if (correctAggressorFlag === aggressorFlagTypes.CrossTrade ||
        correctAggressorFlag === aggressorFlagTypes.Auction) {
            if (msg.Price < bid) {
                colorValue = 1;
                tsItemType = TSItemType.BelowBid;
            } else if (msg.Price === bid) {
                colorValue = 2;
                tsItemType = TSItemType.AtBid;
            } else if (msg.Price > ask) {
                colorValue = 3;
                tsItemType = TSItemType.AboveAsk;
            } else if (msg.Price === ask) {
                colorValue = 4;
                tsItemType = TSItemType.AtAsk;
            } else {
                colorValue = 0;
                tsItemType = TSItemType.BetweenSpread;
            }

        // Bid >= Ask - красим по агрессору, или серым
        } else if (bid === 0 || bid >= ask || !realTime) {
        // если есть агрессор флаг красим по нему
            if (correctAggressorFlag === aggressorFlagTypes.Ask) {
                colorValue = 4;
                tsItemType = TSItemType.AtAsk;
            } else if (correctAggressorFlag === aggressorFlagTypes.Bid) {
                colorValue = 2;
                tsItemType = TSItemType.AtBid;
            } else {
                colorValue = 0;
                tsItemType = TSItemType.BetweenSpread;
            }

        // below bid
        } else if (msg.Price < bid) {
        // совпадает с агрессор флаг - класс, нет возврящаем цвет по аггрессор флагу
            if (correctAggressorFlag === aggressorFlagTypes.Bid ||
            correctAggressorFlag === aggressorFlagTypes.None ||
            correctAggressorFlag === aggressorFlagTypes.NotSet) {
                correctAggressorFlag = aggressorFlagTypes.Bid;
                colorValue = 1;
                tsItemType = TSItemType.BelowBid;
            } else {
                correctAggressorFlag = aggressorFlagTypes.Ask;
                colorValue = 4;
                tsItemType = TSItemType.AtAsk;
            }
        // at bid
        } else if (msg.Price === bid) {
        // совпадает с агрессор флаг - класс, нет возврящаем цвет по аггрессор флагу
            if (correctAggressorFlag === aggressorFlagTypes.Bid ||
            correctAggressorFlag === aggressorFlagTypes.None ||
            correctAggressorFlag === aggressorFlagTypes.NotSet) {
                correctAggressorFlag = aggressorFlagTypes.Bid;
                colorValue = 2;
                tsItemType = TSItemType.AtBid;
            } else {
                correctAggressorFlag = aggressorFlagTypes.Ask;
                colorValue = 4;
                tsItemType = TSItemType.AtAsk;
            }
        // above ask
        } else if (msg.Price > ask) {
        // совпадает с агрессор флаг - класс, нет возврящаем цвет по аггрессор флагу
            if (correctAggressorFlag === aggressorFlagTypes.Ask ||
            correctAggressorFlag === aggressorFlagTypes.None ||
            correctAggressorFlag === aggressorFlagTypes.NotSet) {
                correctAggressorFlag = aggressorFlagTypes.Ask;
                colorValue = 3;
                tsItemType = TSItemType.AboveAsk;
            } else {
                correctAggressorFlag = aggressorFlagTypes.Bid;
                colorValue = 2;
                tsItemType = TSItemType.AtBid;
            }
        } else if (msg.Price === ask) { // at ask
        // совпадает с агрессор флаг - класс, нет возврящаем цвет по аггрессор флагу
            if (correctAggressorFlag === aggressorFlagTypes.Ask ||
            correctAggressorFlag === aggressorFlagTypes.None ||
            correctAggressorFlag === aggressorFlagTypes.NotSet) {
                correctAggressorFlag = aggressorFlagTypes.Ask;
                colorValue = 4;
                tsItemType = TSItemType.AtAsk;
            } else {
                correctAggressorFlag = aggressorFlagTypes.Bid;
                colorValue = 2;
                tsItemType = TSItemType.AtBid;
            }
        // спред
        } else {
        // если есть агрессор флаг красим по нему
            if (correctAggressorFlag === aggressorFlagTypes.Ask) {
                colorValue = 4;
                tsItemType = TSItemType.AtAsk;
            } else if (correctAggressorFlag === aggressorFlagTypes.Bid) {
                colorValue = 2;
                tsItemType = TSItemType.AtBid;
            // если нет флага то серым
            } else {
            // Price is in between the bid and ask. Calc if closer to bid or to ask.
                const dSpread = ask - bid;
                const dMid = bid + dSpread / 2;
                if (msg.Price < dMid) {
                    colorValue = 5;
                    tsItemType = TSItemType.BetweenSpread;
                } else {
                    colorValue = 6;
                    tsItemType = TSItemType.BetweenSpread;
                }
            }
        }

        return {
            colorValue,
            tsItemType,
            correctAggressorFlag
        };
    }
}

ApplicationPanelWithTable.extendWith(TimeSalesPanel, {
    data: function () {
        return {
            instrument: null,
            account: null,
            isSymbolLinkShow: true,
            isAccountLinkShow: true,
            canFilterByAccount: false
        };
    },

    partials: {
        bodyPartial: TimeSalesPanelTemplate
    }
});
