// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { TvSessionIdEnum, VisiblePlotsSetEnum } from '../TradingViewPrimitives/TvEnums';
import { type InstrumentInfo, type QuantityMetainfo, type SearchSymbolResultItem, type SymbolResolveExtension, type Timezone, type SymbolType, type AdditionalSymbolInfoField } from '../charting_library';
import { TvSearchSymbolResultItem } from '../TradingViewPrimitives/TvSearchSymbolResultItem';
import { TvSymbol } from '../TradingViewPrimitives/TvSymbol';
import { type Instrument } from '../../cache/Instrument';
import { type VariableTick } from '../../../Utils/Instruments/VariableTick';
import { TvSessionConvertor } from './TvSessionConvertor';
import { Periods } from '../../../Utils/History/TFInfo';
import { TvSymbolInfoPriceSource } from '../TradingViewPrimitives/TvSymbolInfoPriceSource';
import { Resources } from '../../../Localizations/Resources';
import { type TvSubsessionInfo } from '../TradingViewPrimitives/TvSubsessionInfo.js';
import { TvTimeZoneHelper } from '../Helpers/TvTimeZoneHelper';
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { DataCache } from '../../DataCache';
import { InstrumentTypes } from '../../../Utils/Instruments/InstrumentTypes';
import { ProductType } from '../../../Utils/Instruments/ProductType';
import { type Account } from '../../cache/Account.js';
import { TvDurationConvertor } from './TvDurationConvertor';
import { Decimal } from 'decimal.js';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { SymbolSvgLogoManager } from '../../UtilsClasses/SymbolSvgLogoManager';
import { type ITvChartStateSettings } from '../TradingViewPrimitives/TvChartStateSettings';
import { TvAccountsManager } from '../Managers/TvAccountsManager';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { InstrumentSpecificType } from '../../../Utils/Instruments/InstrumentSpecificType';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { TimeZoneInfo } from '../../../Utils/Time/TimeZoneInfo';
const fakeTvSymbolName: string = 'fakeTVSymbol';
export class _TvSymbolConvertor {
    readonly #sessionConvertor = new TvSessionConvertor();

    convertToTvSymbol (instrument: Instrument, extension: SymbolResolveExtension): TvSymbol {
        const session = instrument.Session;
        const holidays = this.#sessionConvertor.getHolidays(session);

        const tvSubsessions: TvSubsessionInfo[] = this.#sessionConvertor.getSubsessions(session);
        const subsessionID = extension?.session ?? TvSessionIdEnum.regular;
        const tvSubSession = tvSubsessions.find(x => x.id === subsessionID);
        const tvSession = tvSubSession?.session ?? '';
        const corrections = tvSubSession?.getCorrection();

        const precision = instrument.getPrecision();
        const priceScale = new Decimal(10).pow(precision).toNumber();
        const mintickSize = instrument.GetPointSize(null);
        const minmov = new Decimal(mintickSize).mul(priceScale).toNumber();

        const tvSymbol = new TvSymbol();
        tvSymbol.ticker = this.getTicker(instrument);
        tvSymbol.name = this.getSymbolName(instrument);
        tvSymbol.description = instrument.DescriptionValue();
        tvSymbol.type = this.getSymbolType(instrument.InstrType);

        tvSymbol.pricescale = priceScale;
        tvSymbol.minmov = minmov;
        tvSymbol.minmove2 = tvSymbol.type === TvInstrumentType.Forex ? 10 : 0;

        tvSymbol.volume_precision = instrument.getAmountPrecision(null, TvAccountHelper.getCurrentAccount(), ProductType.General);
        tvSymbol.exchange = instrument.TradingExchange;
        tvSymbol.listed_exchange = instrument.TradingExchange;
        tvSymbol.sector = instrument.LocalizedSector;
        tvSymbol.industry = instrument.LocalizedIndustry;
        tvSymbol.currency_code = tvSymbol.original_currency_code = this.getSymbolCurrency(instrument);

        tvSymbol.session = tvSession;
        tvSymbol.session_holidays = holidays;
        tvSymbol.corrections = corrections;
        tvSymbol.subsession_id = subsessionID;
        tvSymbol.subsessions = tvSubsessions;
        tvSymbol.timezone = TvTimeZoneHelper.getTimeZoneForTV(session.TimeZoneID) as Timezone;

        tvSymbol.logo_urls = this.getLogoUrls(instrument);

        if (instrument.isOptionSymbol || (instrument.isFuturesSymbol && instrument.InstrumentSpecificType !== InstrumentSpecificType.ContinuousContract)) {
            const serverDateNow = TimeZoneInfo.ConvertTimeFromUtc(new Date(), TimeZoneInfo.SERVER_TIME_ZONE); // converted to server`s date because ExpDateReal is in server timeZone
            const serverDateNowWithoutTime = DateTimeUtils.getDateWithoutTime(serverDateNow);
            if (instrument.ExpDateReal.getFullYear() > 2000 && instrument.ExpDateReal < serverDateNowWithoutTime) {
                tvSymbol.expired = true;
                tvSymbol.expiration_date = instrument.ExpDateReal.getTime() / 1000;
            }
        }

        const variableString = this.getVariableTickListString(instrument);
        if (variableString.length > 0) tvSymbol.variable_tick_size = variableString;

        // неважно какой период передавать, главное чтобы не тики
        const allowedHistoryTypes = instrument.DataCache.GetAllowedHistoryTypesByPeriod(Periods.MIN);

        const priceSources: TvSymbolInfoPriceSource[] = [];
        for (const historyType of allowedHistoryTypes) {
            const historyTypeId = historyType;
            const historyTypeLocalization = Resources.getResource('chart.view.source' + historyType);
            const priceSource: TvSymbolInfoPriceSource = new TvSymbolInfoPriceSource();
            priceSource.id = historyTypeId;
            priceSource.name = historyTypeLocalization;
            priceSources.push(priceSource);
        }

        if (priceSources.length > 0) {
            tvSymbol.price_sources = priceSources;
            const defChartHistoryType = instrument.DefaultChartHistoryType;
            tvSymbol.price_source_id = defChartHistoryType.toString();
            tvSymbol.visible_plots_set = defChartHistoryType === HistoryType.QUOTE_TRADES
                ? VisiblePlotsSetEnum.ohlcv
                : VisiblePlotsSetEnum.ohlc;
        }

        const quoteDelay = this.getQuoteDelay(instrument);
        if (quoteDelay > 0) {
            tvSymbol.data_status = 'delayed_streaming';
            tvSymbol.delay = quoteDelay * 60; // in seconds
        }
        // else if (instrument.QuoteCanGetSnapshot) {
        //     tvSymbol.data_status = 'endofday';
        //     tvSymbol.delay = -1; // for endofday
        // }

        TvInteriorIdCache.saveInteriorId(tvSymbol.ticker, instrument.GetInteriorID());

        return tvSymbol;
    }

    public correctTvChartState (state: ITvChartStateSettings, instrument: Instrument): void {
        state.symbol = instrument.GetInteriorID();
        state.shortName = this.getSymbolName(instrument);
        state.currencyId = this.getSymbolCurrency(instrument);
        state.sessionId = undefined;
    }

    public getMarketExchange (instrument: Instrument): string {
        return instrument.TradingExchange;
    }

    public getTicker (instrument: Instrument): string {
        if (TvInteriorIdCache.isTvChartMode()) {
            return instrument.GetInteriorID();
        }
        return instrument.DisplayName();
    }

    public getSymbolName (instrument: Instrument): string {
        return instrument.DisplayName();
    }

    public getFullname (instrument: Instrument): string {
        if (instrument == null) { return ''; }
        const symbolWithoutRouteName = InstrumentUtils.RemoveRouteName(instrument.DisplayName());
        const exchange = instrument.TradingExchange;
        return `${exchange}:${symbolWithoutRouteName}`;
        // return instrument.DisplayName();
    }

    private getLogoUrls (instrument: Instrument): [string] | [string, string] {
        if (instrument.LogoAddress) {
            return [instrument.LogoAddress];
        }

        const defaultLogo = SymbolSvgLogoManager.getDefaultImageInBase64(instrument.LogoName());
        return [defaultLogo];
    }

    convertToTvSearchSymbolResultItem (instrument): SearchSymbolResultItem {
        const resultItem = new TvSearchSymbolResultItem();
        resultItem.description = instrument.DescriptionValue();
        resultItem.exchange = instrument.MarketDataExchange; // ?
        resultItem.ticker = this.getTicker(instrument);
        resultItem.symbol = this.getSymbolName(instrument);
        resultItem.full_name = this.getFullname(instrument);
        resultItem.type = this.getSymbolType(instrument.InstrType);
        resultItem.logo_urls = this.getLogoUrls(instrument);

        TvInteriorIdCache.saveInteriorId(resultItem.ticker, instrument.GetInteriorID());
        return resultItem;
    }

    public isFakeSymbolName (symbolName: string | undefined): boolean {
        return !symbolName || symbolName === fakeTvSymbolName;
    }

    public getFakeSymbol (): TvSymbol {
        const fakeTvSymbol = new TvSymbol();
        fakeTvSymbol.name = fakeTvSymbol.ticker = fakeTvSymbolName;
        fakeTvSymbol.session = '24x7';
        return fakeTvSymbol;
    }

    public async getInstrumentInfoAsync (symbol: string, account: Account): Promise<InstrumentInfo> {
        return await new Promise((resolve, reject) => {
            try {
                // Вызываем синхронный метод и возвращаем его результат
                const info = this.getInstrumentInfo(symbol, account);
                resolve(info);
            } catch (error) {
                // Обработка возможных ошибок синхронного метода
                reject(error);
            }
        });
    }

    private getInstrumentInfo (symbol: string, account: Account): InstrumentInfo {
        // TODO: В дальнейшем планируется добавить динамический филд для выбора типа продукта
        const defaultProdutType = ProductType.General;

        const interiorId = TvInteriorIdCache.getInteriorId(symbol);
        const instrument = DataCache.getInstrumentByName(interiorId);
        const instrumentInfo = this.getDefaultInstrumentInfo();

        if (!instrument) return instrumentInfo;

        instrumentInfo.type = this.getSymbolType(instrument.InstrType);
        instrumentInfo.brokerSymbol = this.getSymbolName(instrument);
        instrumentInfo.description = instrument.DescriptionValue();

        // Quantity
        const lotStep = instrument.getLotStep(defaultProdutType, account);
        const minAmount = Math.max(instrument.getMinLot(defaultProdutType, account), lotStep);
        const maxAmount = instrument.getMaxLot(defaultProdutType, account);

        instrumentInfo.qty.default = minAmount;
        instrumentInfo.qty.min = minAmount;
        instrumentInfo.qty.max = maxAmount;
        instrumentInfo.qty.step = lotStep;

        // PipValue
        const pipSize = instrument.PipsSize;
        const crossPriceToAccountCurrency = DataCache.CrossRateCache.GetCrossPriceExp1Exp2(instrument.Exp2, account.BaseCurrency);
        const pipValue = pipSize * crossPriceToAccountCurrency;
        instrumentInfo.pipValue = pipValue;
        instrumentInfo.pipSize = pipSize;
        instrumentInfo.minTick = instrument.PointSize;
        instrumentInfo.lotSize = instrument.LotSize;

        instrumentInfo.units = Resources.getResource('TvTradingPlatform.Quantity');
        instrumentInfo.domVolumePrecision = instrument.getAmountPrecision(true, account, defaultProdutType);
        // leverage?: string;
        // limitPriceStep?: number;
        // stopPriceStep?: number;

        const allowedDurations = TvDurationConvertor.getAllowedDurationsForInstrumentAccount(instrument, account);
        instrumentInfo.allowedDurations = allowedDurations.map(x => x.value);

        instrumentInfo.allowedOrderTypes = TvDurationConvertor.getAllowedTvOrderTypes(instrument, account);

        const variableString = this.getVariableTickListString(instrument);
        if (variableString.length > 0) instrumentInfo.variableMinTick = variableString;

        instrumentInfo.currency = instrument.Exp2;
        instrumentInfo.baseCurrency = instrument.Exp1;

        // quoteCurrency
        // bigPointValue
        // priceMagnifier

        return instrumentInfo;
    }

    private getDefaultInstrumentInfo (): InstrumentInfo {
        const qtyInfo: QuantityMetainfo =
        {
            min: 1,
            max: 1000000,
            step: 1
        };

        const instrumentInfo: InstrumentInfo =
        {
            qty: qtyInfo,
            pipValue: 1,
            pipSize: 0.00001,
            minTick: 0.00001,
            lotSize: 0.1,
            type: 'forex',
            brokerSymbol: 'InstrumentInfoBrokerSymbol',
            description: 'InstrumentInfoDescription'
        };

        return instrumentInfo;
    }

    private getVariableTickListString (instrument: Instrument): string {
        let variableString: string = '';
        const varTickList = instrument.VariableTickList;
        const listLength = varTickList.length;
        // the first interval (from negative infinity to 0) was skipped
        for (let i = 1; i < listLength; i++) {
            const tick: VariableTick = varTickList[i];

            if (i === listLength - 1) {
                variableString += `${tick.PointSize}`;
            } else {
                variableString += `${tick.PointSize} ${tick.RightBorder} `;
            }
        }
        return variableString;
    }

    public getQuoteDelay (instrument: Instrument): number {
        if (instrument.HasDelay()) return instrument.GetDelay();
        return DataCache.EntitlementManager.GetQuoteDelay(instrument);
    }

    public getSymbolType (instType: number): SymbolType {
        switch (instType) {
        case InstrumentTypes.FOREX:
            return TvInstrumentType.Forex;
        case InstrumentTypes.EQUITIES:
            return TvInstrumentType.Stock;
        case InstrumentTypes.EQUITIES_CFD:
        case InstrumentTypes.CFD_FUTURES:
            return TvInstrumentType.Cfd;
        case InstrumentTypes.FUTURES:
            return TvInstrumentType.Futures;
        case InstrumentTypes.INDICIES:
            return TvInstrumentType.Index;
        case InstrumentTypes.CRYPTO:
            return TvInstrumentType.Crypto;
        case InstrumentTypes.BOND:
            return TvInstrumentType.Bond;
        case InstrumentTypes.SPOT:
            return TvInstrumentType.Spot;
        case InstrumentTypes.OPTIONS:
            return TvInstrumentType.Options as SymbolType;
        case InstrumentTypes.FORWARD:
            return TvInstrumentType.Forward as SymbolType;
        case InstrumentTypes.SPREADBET:
            return TvInstrumentType.Spreadbet as SymbolType;
        case InstrumentTypes.ETF:
            return TvInstrumentType.Etf as SymbolType;
        case InstrumentTypes.TBILL:
            return TvInstrumentType.TBill as SymbolType;
        case InstrumentTypes.CORPORATE:
            return TvInstrumentType.Corporate as SymbolType;
        default:
            return TvInstrumentType.Undefined;
        }
    }

    public getInstrumentTypes (symbolType: string): number[] {
        const tvSymbolType = symbolType as TvInstrumentType;
        switch (tvSymbolType) {
        case TvInstrumentType.Forex:
            return [InstrumentTypes.FOREX];
        case TvInstrumentType.Stock:
            return [InstrumentTypes.EQUITIES];
        case TvInstrumentType.Futures:
            return [InstrumentTypes.FUTURES];
        case TvInstrumentType.Index:
            return [InstrumentTypes.INDICIES];
        case TvInstrumentType.Crypto:
            return [InstrumentTypes.CRYPTO];
        case TvInstrumentType.Bond:
            return [InstrumentTypes.BOND];
        case TvInstrumentType.Spot:
            return [InstrumentTypes.SPOT];
        case TvInstrumentType.Options:
            return [InstrumentTypes.OPTIONS];
        case TvInstrumentType.Forward:
            return [InstrumentTypes.FORWARD];
        case TvInstrumentType.Spreadbet:
            return [InstrumentTypes.SPREADBET];
        case TvInstrumentType.Etf:
            return [InstrumentTypes.ETF];
        case TvInstrumentType.TBill:
            return [InstrumentTypes.TBILL];
        case TvInstrumentType.Corporate:
            return [InstrumentTypes.CORPORATE];
        case TvInstrumentType.Cfd:
            return [InstrumentTypes.CFD_FUTURES, InstrumentTypes.EQUITIES_CFD];

        case TvInstrumentType.Undefined:
        default:
            return [InstrumentTypes.GENERAL];
        }
    }

    public getSymbolCurrency (instrument: Instrument): string {
        return instrument?.Exp2;
    }
}

export const TvSymbolConvertor = new _TvSymbolConvertor();

enum TvInstrumentType {
    Undefined = 'undefined',
    Forex = 'forex',
    Stock = 'stock',
    Cfd = 'cfd',
    Futures = 'futures',
    Index = 'index',
    Crypto = 'crypto',
    Bond = 'bond',
    Spot = 'spot',
    Options = 'options',
    Forward = 'forward',
    Spreadbet = 'spreadbet',
    Etf = 'etf',
    TBill = 'tbill',
    Corporate = 'corporate'
}
