// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ModifyStopOrder } from '../order-modify/ModifyStopOrder';
import { OrderTypeBase } from './OrderTypeBase';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { StopOrderEdit } from '../order-edit/StopOrderEdit';

export class StopOrderType extends OrderTypeBase {
    public stopPrice: any;

    constructor () {
        super(StopOrderEdit, ModifyStopOrder);
    }

    // TODO. Remove.
    public override id (): OrderType {
        return OrderType.Stop;
    }
}
