// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Cursors } from '../../../Commons/Cursors';
import { LayersEnum, TerceraChartBaseRenderer } from '../TerceraChartBaseRenderer';
import { MouseButtons } from '../../../Controls/UtilsClasses/ControlsUtils';
import { type TerceraChartBase } from '../../TerceraChartBase';
import { TerceraChartToolRenderer } from './TerceraChartToolRenderer';

export class TerceraChartEraserRenderer extends TerceraChartBaseRenderer {
    Active: boolean;
    eraserCursor: string;

    constructor (chart: TerceraChartBase) {
        super(chart);
        this.Active = false;
        this.eraserCursor = Cursors.eraserCursor;
        this.SetClassName('TerceraChartEraserRenderer');
    }

    ProcessMouseDown (e: any): boolean {
        if (!this.Active) return super.ProcessMouseDown(e);

        const wCont = this.chart.windowsContainer;
        const len_win = wCont.Windows.length;

        for (let i_win = 0; i_win < len_win; i_win++) {
            const window = wCont.Windows[i_win];
            const toolsRenderer = window.GetRenderer(TerceraChartToolRenderer);
            if (toolsRenderer?.HoverToolGet() != null) {
                toolsRenderer.RemoveTool(toolsRenderer.HoverToolGet());
                return true;
            }
        }

        if (e.Button == MouseButtons.Right) return true;

        return super.ProcessMouseDown(e);
    }

    ProcessMouseMove (e: any): boolean {
        if (this.Active) {
            e.NeedRedraw = LayersEnum.CrossHair;
        }
        return super.ProcessMouseMove(e);
    }

    GetCursor (e: any): string | null {
        if (this.Active) {
            const len = this.chart.windowsContainer.Windows.length;
            for (let i = 0; i < len; i++) {
                if (this.chart.windowsContainer.Windows[i].ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
                    return this.eraserCursor;
                }
            }
        }
        return null;
    }

    ThemeChanged (): void {
        super.ThemeChanged();
        this.eraserCursor = Cursors.eraserCursor;
    }

    Dispose (): void {
        this.chart = null;
        super.Dispose();
    }
}
