// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MAMode } from '../../IndicatorConstants';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBearsPower } from './iBearsPower';

export class iBullsPower extends iBearsPower {
    override get Name (): string { return 'iBullsPower'; }

    public override OnQuote (): void {
        const price = this.GetPrice(this.FPriceType, 0);
        this.FEMA[this.FEMA.Length - 1] = price;
        const Parent = this.Parent;

        if (!Parent) {
            return;
        }
        if (this.FMaPeriod > this.Count - 1) {
            return;
        }

        this.SetValue(this.GetPrice(PriceType.High, 0) - IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.FEMA, this.FMaPeriod, 1));
    }
}
