// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ControlsTypes } from '../../../Controls/UtilsClasses/FactoryConstants';
import { InvestingPasswordTextBoxTemplate } from '../../../Investing/templates';
import { PasswordTextBox } from '../../../Controls/elements/PasswordTextBox';
export class InvestingPasswordTextBox extends PasswordTextBox {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    getType (): string { return ControlsTypes.InvestingPasswordTextBox; }
}

PasswordTextBox.extendWith(InvestingPasswordTextBox, {
    template: InvestingPasswordTextBoxTemplate,
    data: function () {
        return {
            showPasswordPreview: false,
            passwordHided: true,
            focused: false,
            showPassEyeTooltip: '',
            hidePassEyeCrossedTooltip: ''
        };
    }
});
