// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PenStyle } from '../Graphics';
import { Resources } from '../../Localizations/Resources';

export class LineStyleUtils {
    public static getCBItems (): Array<{ value: number, style: string }> {
        const arr = [];
        if (!Resources.isHidden('chart.tool.line')) {
            arr.push({ value: PenStyle.SimpleChart, style: 'js-StyleLine-line' });
        }

        if (!Resources.isHidden('chart.tool.dot')) {
            arr.push({ value: PenStyle.DotChart, style: 'js-StyleLine-dot' });
        }

        if (!Resources.isHidden('chart.tool.dotline')) {
            arr.push({ value: PenStyle.DotLineChart, style: 'js-StyleLine-dotted_line' });
        }

        if (!Resources.isHidden('chart.tool.IsoDot')) {
            arr.push({ value: PenStyle.IsoDotChart, style: 'js-StyleLine-iso_dot' });
        }

        if (!Resources.isHidden('chart.tool.ShapedLine')) {
            arr.push({ value: PenStyle.ShapedChart, style: 'js-StyleLine-shaped_line' });
        }

        if (!Resources.isHidden('chart.tool.DottedLine')) {
            arr.push({ value: PenStyle.ShapedDotChart, style: 'js-StyleLine-loosely_dotted_line' });
        }

        if (!Resources.isHidden('chart.tool.Dash')) {
            arr.push({ value: PenStyle.ShapedDoubleDotChart, style: 'js-StyleLine-Dash' });
        }

        return arr;
    }
}
