// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TvBaseFormatter } from './TvBaseFormatter';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { ProductType } from '../../../Utils/Instruments/ProductType';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { type Account } from '../../cache/Account';

export class TvQuantityFormatter extends TvBaseFormatter {
    private readonly account: Account;
    private readonly productType: ProductType = ProductType.General;

    constructor (symbol: string) {
        super(symbol);
        this.account = TvAccountHelper.getCurrentAccount();
    }

    public override format (value: number): string {
        if (this.instrument != null) return InstrumentUtils.formatAmountValue(value, this.instrument, this.account, this.productType);

        return value.toString();
    }
}
