// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { ObjectManagerScreenTemplate } from '../../templates.js';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraWindowBase } from './TerceraWindowBase';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { type TerceraChart } from '@front/chart/TerceraChart.js';
import { type PanelNames } from '../UtilsClasses/FactoryConstants.js';

let instance: ObjectManagerScreen = null;
export class ObjectManagerScreen extends TerceraWindowBase {
    public static OnResizingProcess: CustomEvent = new CustomEvent();
    constructor () { super(); }

    public override getType (): string { return 'ObjectManagerScreen'; }

    public override oncomplete (): void {
        super.oncomplete();
        this.OnResize.Subscribe(this.resizingProcess, this);
        this.localize();
        this.center();
    }

    public override localize (): void {
        void this.set({
            header: Resources.getResource('ChartObjectManager.Text')
        });
    }

    private resizingProcess (): void {
        ObjectManagerScreen.OnResizingProcess.Raise();
    }

    public override dispose (): void {
        this.OnResize.Subscribe(this.resizingProcess, this);
        super.dispose();
    }

    public static show (chart: TerceraChart, typePanel?: PanelNames): void {
        if (!instance) {
            instance = new ObjectManagerScreen();
            void instance.set({ name: 'objectManagerScreen' });
            if (!isNullOrUndefined(typePanel)) {
                const panels = instance.findAllComponents().filter(component => component instanceof TerceraWindowBase);
                for (const panel of panels) {
                    if (panel.getType() === typePanel) {
                        void instance.set('forcedSelectedPanel', panel);
                        break;
                    }
                }
            }

            void instance.set('chart', chart);
            instance.on('teardown', function () {
                instance = null;
            });
            MainWindowManager.MainWindow.addControl(instance);
        };

        instance.setFocus();
    }
}

TerceraWindowBase.extendWith(ObjectManagerScreen, {
    data: function () {
        return {
            movable: true,
            resizable: true,
            showFooter: false,
            focused: true,

            width: 556,
            height: 287,
            zIndex: 100,
            arrayHeaderTabs: [],
            style_addition_header: 'objectManagerScreen-AdditionalHeader'
        };
    },
    partials: { bodyPartial: ObjectManagerScreenTemplate }
});
