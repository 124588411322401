// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/// Fill volume% with min p/d - используется отдельная логика расчета комиссии включающая доп. поля
import { RevenueCommissionValueItemForPerFill } from './RevenueCommissionValueItemForPerFill';

export class RevenueCommissionValueItemForFillVolumeWithMinPD extends RevenueCommissionValueItemForPerFill {
    private finalValue = 0.0;

    get FinalValue (): number { return this.finalValue; }
    set FinalValue (value) { this.finalValue = value; }

    public override GetFixedCommissionValue (commission, ignoreMinMaxCorrection = false, markup = 1.0): number {
        if (commission < this.MinTradedValue) {
            return this.MinValue;
        } else {
            return this.MinValue + (commission - this.minTradedValue) * this.finalValue / 100;
        }
    }
}
