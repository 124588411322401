// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Account } from '../../cache/Account';
import { type AssetBalance } from '../../cache/AssetBalance';
import { type AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum } from '../../../Utils/Enums/AccountDetailsEnum';
import { AccountActivityGroupController } from '../Controllers/AccountControllers/AccountActivityGroupController';
import { GeneralGroupController } from '../Controllers/AccountControllers/GeneralGroupController';
import { InfoGroupController } from '../Controllers/AccountControllers/InfoGroupController';
import { type IGroupController } from '../Interfaces/IGroupController';
import { MarginGroupController } from '../Controllers/AccountControllers/MarginGroupController';
import { RiskManagementGroupController } from '../Controllers/AccountControllers/RiskManagementGroupController';
import { TodayResultGroupController } from '../Controllers/AccountControllers/TodayResultGroupController';
import { type BaseAccountGroupController } from '../Controllers/BaseControllers/BaseAccountGroupController';

export class AccountDetailsControllersDistributor {
    private _account: Account = null;
    private _assetBalance: AssetBalance = null;
    private arrayGroupControllers: BaseAccountGroupController[] = [];

    private generalGroupController: GeneralGroupController = null;
    private marginGroupController: MarginGroupController = null;
    private accountActivityGroupController: AccountActivityGroupController = null;
    private todayResultGroupController: TodayResultGroupController = null;
    private riskManagementGroupController: RiskManagementGroupController = null;
    private infoGroupController: InfoGroupController = null;

    constructor (account: Account) {
        this._account = account;
        this._assetBalance = account.assetBalanceDefault;
        this.createGroupControllers();
    }

    get Account (): Account { return this._account; }
    set Account (value: Account) {
        this._account = value;
        this.AssetBalance = value.assetBalanceDefault;
        this.changeAccountGroupControllers(value);
    }

    get AssetBalance (): AssetBalance { return this._assetBalance; }
    set AssetBalance (value: AssetBalance) {
        this._assetBalance = value;
        this.changeAssetBalanceGroupControllers(value);
    }

    private createGroupControllers (): void {
        const account = this.Account;
        const assetBalance = this.AssetBalance;

        this.arrayGroupControllers = [
            this.generalGroupController = new GeneralGroupController(account, assetBalance),
            this.marginGroupController = new MarginGroupController(account, assetBalance),
            this.accountActivityGroupController = new AccountActivityGroupController(account, assetBalance),
            this.todayResultGroupController = new TodayResultGroupController(account, assetBalance),
            this.riskManagementGroupController = new RiskManagementGroupController(account, assetBalance),
            this.infoGroupController = new InfoGroupController(account, assetBalance)
        ];
    }

    private changeAccountGroupControllers (value: Account): void {
        for (const controller of this.arrayGroupControllers) {
            controller.Account = value;
        }
    }

    private changeAssetBalanceGroupControllers (value: AssetBalance): void {
        for (const controller of this.arrayGroupControllers) {
            controller.AssetBalance = value;
        }
    }

    public getGroupController (type: AccountDetailsGroupsEnum): IGroupController<AccountDetailsGroupItemsEnumType> {
        switch (type) {
        case AccountDetailsGroupsEnum.General:
            return this.generalGroupController;
        case AccountDetailsGroupsEnum.Margin:
            return this.marginGroupController;
        case AccountDetailsGroupsEnum.AccountActivity:
            return this.accountActivityGroupController;
        case AccountDetailsGroupsEnum.TodayResult:
            return this.todayResultGroupController;
        case AccountDetailsGroupsEnum.RiskManagement:
            return this.riskManagementGroupController;
        case AccountDetailsGroupsEnum.Info:
            return this.infoGroupController;
        }
    }
}
