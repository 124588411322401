// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { KeyCode } from '../../Commons/KeyEventProcessor';
import { TerceraLabel } from '../elements/TerceraLabel';
import { TerceraWindowBase } from './TerceraWindowBase';
import { TerceraLookupEvents } from '../elements/Lookup/TerceraLookup';
import { TerceraInstrumentLookup } from '../elements/Lookup/TerceraInstrumentLookup';

export class TerceraQuickInstrumentLookupScreen extends TerceraWindowBase {
    // такое же как и в конструкторе
    public static readonly WIDTH = 270;
    public static readonly HEIGHT = 70;

    public parentFocus: any;
    public callBackForInstrumentSelection: any;
    public functionForOpenQuickTree: any;

    constructor () { super(); }

    public override getType (): string { return 'TerceraQuickInstrumentLookupScreen'; }

    public static Show (posX: number, posY: number, event, callBackForInstrumentSelection, functionForOpenQuickTree, parent): TerceraQuickInstrumentLookupScreen {
        const terceraquickinstrumentlookupscreen = new TerceraQuickInstrumentLookupScreen();
        terceraquickinstrumentlookupscreen.setLocation(posX, posY);
        MainWindowManager.MainWindow.addControl(terceraquickinstrumentlookupscreen);

        terceraquickinstrumentlookupscreen.callBackForInstrumentSelection = callBackForInstrumentSelection;
        terceraquickinstrumentlookupscreen.functionForOpenQuickTree = functionForOpenQuickTree;
        terceraquickinstrumentlookupscreen.parentFocus = parent;

        const lookup = new TerceraInstrumentLookup();
        lookup.oninit();
        lookup.setLocation(10, 30);
        void lookup.set({ width: 255, isPosAbsolute: true });
        lookup.off('lookupBtnClick');
        lookup.on('lookupBtnClick', function () {
            if (this.functionForOpenQuickTree) {
                this.functionForOpenQuickTree();
            }
            this.close();
        }.bind(terceraquickinstrumentlookupscreen));
        lookup.on(TerceraLookupEvents.SelectedItemChanged, function (sender, newInstrument) {
            if (this.callBackForInstrumentSelection) {
                this.callBackForInstrumentSelection([newInstrument]);
            }
            this.close();
        }.bind(terceraquickinstrumentlookupscreen));
        lookup.on('complete', function (event) {
            this.set({ selectedItem: null });
            this.selectedItem = null;
            this.textBox.set({ text: '' });
            this.selectedItemIndex = -1;
            setTimeout(function () {
                this.textBox.set({ text: event.original.key, skipfocus: true });
                this.textBox.setFocus();
            }.bind(this), 10);
        }.bind(lookup, event));

        // lookup.moveToItem = function () { };
        terceraquickinstrumentlookupscreen.addControl(lookup);
        lookup.CanMoveToNextIfHaveError = false;

        const label = new TerceraLabel();
        label.setLocation(10, 10);
        void label.set({ text: lookup.getButtonTooltip(), isPosAbsolute: true });
        terceraquickinstrumentlookupscreen.addControl(label);
        return terceraquickinstrumentlookupscreen;
    }

    public override lostFocus (): void {
        super.lostFocus();
        this.close();
    }

    public override onKeyDown (event): boolean {
        if (event.original.keyCode === KeyCode.ESC) {
            this.close();
            return true;
        }
    }

    public override close (): void {
        if (this.parentFocus) {
            const func = function () {
                this.setFocus();
                this.find('div').focus();
            }.bind(this.parentFocus);
            delete this.parentFocus;
            func();
        }
        super.close();
    }
}

TerceraWindowBase.extendWith(TerceraQuickInstrumentLookupScreen, {
    data: function () {
        return {
            zIndex: 300,
            height: TerceraQuickInstrumentLookupScreen.HEIGHT,
            width: TerceraQuickInstrumentLookupScreen.WIDTH,
            header: '',
            resizable: false,
            showFooter: false,
            movable: false,
            showHeader: false
        };
    }
});
