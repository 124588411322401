// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type InitOpts } from 'ractive';
import type Ractive from 'ractive';
import { TerceraPanelTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

export class TerceraPanel extends ContainerControl {
    constructor (opts?: InitOpts<Ractive<any>>) { super(opts); }

    public override getType (): string { return 'TerceraPanel'; }

    public override getClientPanel (): HTMLElement {
        return this.el;
    }
}

ContainerControl.extendWith(TerceraPanel, {
    template: TerceraPanelTemplate
});
