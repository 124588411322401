// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { ExpandDoubleVector } from '../DoubleMatrix';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { Color } from '../../../Graphics';

export class VO extends IndicatorScriptBase {
    public ExpirationDate: number;

    constructor () {
        super();
        this.Comments = 'Volume Oscillator';
        this.Company = 'TraderEvolution';
        this.ProjectName = 'VO';
        this.ExpirationDate = 0;

        this.SetIndicatorLine('Volume Oscillator', Color.Green, 2, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.PeriodMASlow = 28;
        super.InputParameter(new InputParameterInteger('Slow period', 'Slow period', 0, 1, 9999));

        this.PeriodMAFast = 14;
        super.InputParameter(new InputParameterInteger('Fast period', 'Fast period', 0, 1, 9999));

        this.smaSlowSeries = new ExpandDoubleVector();
        this.smaFastSeries = new ExpandDoubleVector();
    }

    private smaSlowSeries: ExpandDoubleVector;
    private smaFastSeries: ExpandDoubleVector;

    public PeriodMASlow: number;
    public PeriodMAFast: number;

    public Init (): void {
        super.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'VO';
    }

    public override getCustomName (): string {
        return 'VO (' + this.ProjectName + ')';
    };

    public NextBar (): void {
        this.smaSlowSeries.Add(0.0);
        this.smaFastSeries.Add(0.0);
        super.NextBar();
    }

    public OnQuote (): void {
        if (this.CurrentData.Count - 1 <= Math.max(this.PeriodMASlow, this.PeriodMAFast)) {
            return;
        }
        this.setMASlow();
        this.setMAFast();
        super.SetValue(0, 0, this.getVO());
    }

    private setMASlow (): void {
        this.smaSlowSeries[this.smaSlowSeries.Length - 1] = this.sma(this.PeriodMASlow);
    }

    private setMAFast (): void {
        this.smaFastSeries[this.smaFastSeries.Length - 1] = this.sma(this.PeriodMAFast);
    }

    private sma (period: number): number {
        let volume = 0;
        for (let index = 0; index < period; index++) {
            volume += this.CurrentData.Volume(index);
        }

        return volume > 0 ? volume / period : 0;
    }

    private getVO (): number {
        const result = 100 * (this.smaFastSeries[this.smaFastSeries.Length - 1] - this.smaSlowSeries[this.smaSlowSeries.Length - 1]) / this.smaSlowSeries[this.smaSlowSeries.Length - 1];
        return result;
    }
}
