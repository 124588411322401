// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PFixField } from './PFixField';

export class PFixObjectField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = null;
    }

    public override ReadValue (buffer): void {
        const dataType = buffer.ReadByte();
        switch (dataType) {
        case DataType.Boolean:
            this.Value = buffer.ReadBoolean(); break;
        case DataType.Int32:
            this.Value = buffer.ReadInt32(); break;
        case DataType.Double:
            this.Value = buffer.ReadDouble(); break;
        case DataType.String:
            this.Value = buffer.ReadString(); break;
        case DataType.DateTime:
            this.Value = buffer.ReadDate(); break;
        case DataType.Long:
            this.Value = buffer.ReadInt64(); break;
        case DataType.Single:
            this.Value = buffer.ReadSingle(); break;
        }
    }

    public override Write (buffer): void {
        throw new Error('not implemented');
    }
}

enum DataType {
    Boolean = 0,
    Int32 = 1,
    Double = 2,
    String = 3,
    DateTime = 4,
    Long = 5,
    Single = 5,
}
