// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ModifyStopLimitOrder } from '../order-modify/ModifyStopLimitOrder';
import { OrderTypeBase } from './OrderTypeBase';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { StopLimitOrderEdit } from '../order-edit/StopLimitOrderEdit';

export class StopLimitOrderType extends OrderTypeBase {
    constructor () {
        super(StopLimitOrderEdit, ModifyStopLimitOrder);
    }

    public override id (): OrderType {
        return OrderType.StopLimit;
    }

    public override localizationKey (): string {
        return 'Stop Limit';
    }
}
