// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountType } from '../../../Utils/Account/AccountType';
import { DataCache } from '../../DataCache';
import { type Account } from '../../cache/Account';
import { type AccountId } from '../charting_library';

class _TvAccountHelper {
    private currentAccount: Account;
    private readonly fakeAccountId: string = 'fakeId';

    public getCurrentAccount (): Account {
        return this.currentAccount;
    }

    public setCurrentAccount (account: Account): void {
        this.currentAccount = account;
    }

    public resetCurrentAccount (id: string): void {
        const savedAccount = DataCache.Accounts[id];
        this.setCurrentAccount(savedAccount ?? this.getDefaultAccount());
    }

    public getCurrentAccountId (): AccountId {
        return this.currentAccount?.BstrAccount ?? this.fakeAccountId;
    }

    public getFakeAccountId (): AccountId {
        return this.fakeAccountId as AccountId;
    }

    public setAccountById (id: string): void {
        this.setCurrentAccount(DataCache.Accounts[id]);
    }

    private getDefaultAccount (): Account {
        let curAccount: Account = DataCache.MainAccountNew;
        if (curAccount.AccountType === AccountType.SingleCCY) {
            return curAccount;
        }

        curAccount = curAccount.User.Accounts.find(acc => acc.AccountType === AccountType.SingleCCY);
        if (!isNullOrUndefined(curAccount)) {
            return curAccount;
        }

        const accounts = DataCache.Accounts;

        for (const accID in accounts) {
            const account: Account = accounts[accID];

            if (account.AccountType === AccountType.SingleCCY) {
                return account;
            }
        }

        return null;
    }
}

export const TvAccountHelper = new _TvAccountHelper();
