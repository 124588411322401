// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';
import { TradingMode } from '../Instruments/TradingMode';
import { PriceLimitMeasure } from '../Instruments/PriceLimitMeasure';
import { TradingPositionType } from '../Instruments/TradingPositionType';
import { SwapType } from '../Instruments/SwapType';
import { QuotingType } from '../Instruments/QuotingType';
import { OptionTradingStyle } from '../Instruments/OptionTradingStyle';
import { VolumeHistoryMode } from '../Volume/VolumeConstantsAndEnums';
import { CurPriceSource } from '../Instruments/CurPriceSource';
import { CouponCycle } from '../Instruments/CouponCycle';
import { type MarginCoefficients } from '../RiskPlan/MarginCoefficients';
import { type ExerciseStyle } from '@shared/commons/cache/InstrumentConstants';
import { type InstrDateSettings } from '../Instruments/InstrDateSettings';
import { type CrossRateForPositions, type MarginModes } from '../Enums/Constants';
import { type VariableTick } from '../Instruments/VariableTick';
import { type DaysPerType } from '../Instruments/InstrumentFeatureEnum';
import { type UnderlierOptionInfo } from '../Instruments/UnderlierOptionInfo';

export class DirectInstrumentMessage extends DirectMessageBaseClass {
    public TypeId = -1;
    public Name: string | null = null;
    public RoutesSupported: number[] = [];
    public Descr: string | null = null;
    public UnderlierDescription: string | null = null;
    public LotSize = 0;
    public ContractSize: any = null;
    public PointSize = 0;
    public PipsSizeSpecified: any = null;
    public Exp1: string | null = null;
    public Exp2: string | null = null;
    public AssetName: string | null = null;
    public Precision = 0;
    public ShortSwapInfo: string = '';
    public SwapBuy: number = 0;
    public SwapSell: number = 0;
    public InstrumentAdditionalInfo: any = null;
    public TradingMode = TradingMode.FullyClosed;
    public TradingPositionType = TradingPositionType.MultiPosition;
    public QuotingType = QuotingType.None;
    public OptionTradingStyle = OptionTradingStyle.Undefined;
    public VolumeHistoryMode = VolumeHistoryMode.Trades;
    public LowLimit = NaN;
    public HightLimit = NaN;
    public PriceLimitMeasure = PriceLimitMeasure.DISABLE;
    public IsLowLimitFrontEndValidationEnabled: boolean | null = null;
    public IsHighLimitFrontEndValidationEnabled: boolean | null = null;
    public UseOnlyLast = false;
    public SeriesGroupName: any = null;
    public HistoryType = 0;
    public DefaultChartHistoryType = 0;
    public InstrType = 0;
    public TradableRoutes: number[] = [];
    public SwapType = SwapType.SWAP_IN_CURRENCY;
    public InstrDateSettingsList: InstrDateSettings[] = [];
    public ValueCrossRateForPositions: CrossRateForPositions | null = null;
    // 0-physically
    // 1-cash
    public DeliveryMethod: any = null;
    public ForwardBaseInstrument = '';
    public ForwardBaseInstrumentUnderlier: any = null;
    public LockedBy: string | null = null;
    public LotStep = -1;
    public FuturesTickCoast = 0;
    public curPriceSource = CurPriceSource.Level1;
    public TradingBalance = 0;
    public TradingSessionsId = -1;
    public CurrentTradingSessionsId = -2;
    public Id: string | null = null;
    public ExerciseStyle: ExerciseStyle | null = null;
    public ExchangeId: number | null = null;
    public TradingExchange: string | null = null;
    public MarketDataExchange: string | null = null;
    public VariableTickList: VariableTick[] | null = null;

    public CalculateMarginType: number | null = null;
    public MarginMode: MarginModes | null = null;
    public UseSameCrossPriceForOpenClose: boolean | null = null;
    public HedgedMarginCallSize: number | null = null;
    public UseOvernightMargin: boolean | null = null;
    public UseLongShortMargin: boolean | null = null;

    public MarginCoeficientList: MarginCoefficients[] = [];
    public IsShortable: boolean | null = null;
    public MaxLot: number | null = null;
    public MinimalLot: number | null = null;
    public MarginCoefficient: number | null = null;
    public UseOpenPriceInMargin: boolean | null = null;

    public AdditionalFields: any = {};

    public UseOptionDescription: boolean | null = null;
    public ContractMultiplier = 1;
    public allowUseFractinalTicksForForexSpecified: any = null;

    // #region Bonds
    public LastTradeDate: Date | null = null;
    public MaturityDate: Date | null = null;
    public FaceValue = 0;
    public CouponRate = 0;
    public CouponCycle = CouponCycle.OneMonth;
    public YieldRate = 0;
    public AccruedInterest = 0;
    public IssueDate: Date | null = null;
    public DaysPerMonth: DaysPerType | null = null;
    public DaysPerYear: DaysPerType | null = null;
    // #endregion Bonds

    public AllExpDateLoaded = true;
    public AllContractsLoaded = true;
    public FuturesNamingType: any = null;
    public TradeSessionStatusId: number | null = null;
    public ExchangeSessionName: string | null = null;
    public ForwardBaseInstrumentID: number | null = null;

    public ForwardBaseInstrumentDescr: any = null;
    public ForwardBaseInstrumentCountryId: any = null;
    public ForwardBaseInstrumentTradingExchange: any = null;

    // new Scheme
    public InstrumentTradableID: number | null = null;

    public LanguageAliases: any = null;
    public ISIN = '';
    public Industry = '';
    public Sector = '';
    public CountryId = -1;
    public ShortPositionInterest: number | null = null;
    public DailyAvailableForShort: number | null = null;

    public SaveQuoteHistory: boolean | null = null;

    public WarningForChangeFromLastPrice: any = null;

    public DataSourceTradableId: number | null = null;
    public DataSourceRouteId: number | null = null;

    public IsAllowCustomSLTPTriggerPrice: boolean | null = null;

    public IsAllowExerciseForItmOption: boolean | null = null;

    public LogoAddress: string | null = null;
    public MarketCap: number;

    public SectorCustomListId: number | null = null;
    public IndustryCustomListId: number | null = null;

    public UseSessionPeriodForIntradayChart: boolean;

    public WarningForChangeFromLastPriceRanges: WarningForChangeFromLastPriceRange[];

    public ReqerenceId: number;

    public underlierOptionInfo: UnderlierOptionInfo[] = [];

    public EnableComment: boolean | null = null;

    constructor () {
        super(Message.CODE_INSTRUMENT);
    }
}

export class WarningForChangeFromLastPriceRange {
    public LastPrice: number;
    public LastChange: any;

    constructor (lastChange, lastPrice = 0) {
        this.LastPrice = lastPrice;
        this.LastChange = lastChange;
    }
}
