// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PositionRowDataItem } from '../RowItems/PositionRowDataItem';
import { InvestingPositionsPanelTemplate } from '../templates.js';
import { openContextMenu } from '../Controls/elements/InvestingMenu';
import { openOrderEntry } from '../Screen/InvestingOE';
import { debounce } from '../Utils/InvestingUtils';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { PositionActionEnum } from '../../Utils/Trading/PositionActionEnum';
import { DataCache } from '../../Commons/DataCache';
import { Control } from '../../Controls/elements/Control';
import Ractive from 'ractive';

export class InvestingPositionsPanel extends Ractive {
    public selectedItem: any = null;
    public debounceHandler: () => void;

    public getType (): string { return 'InvestingPositionsPanel'; }

    public override oninit (): void {
        this.on('showChartPanelEvent', this.showChartPanelEvent);
        this.on('moreButtonClickEvent', this.moreButtonClickEvent);
    }

    public override oncomplete (): void {
        this.createHeaderRow();
        this.debounceHandler = debounce(this.UpdateData, 400);
        Control.Ticker.Subscribe(this.debounceHandler, this);
    }

    public override onteardown (): void {
        Control.Ticker.UnSubscribe(this.debounceHandler, this);
    }

    public showChartPanelEvent (index, tableRowItem): void {
        const items = this.get('arrayRowData');
        if (!items) {
            return;
        }

        const newSelectedItem = tableRowItem;
        if (newSelectedItem === this.selectedItem) {
            return;
        }

        if (this.selectedItem) {
            this.selectedItem.setActiveStatus(false);
        }

        newSelectedItem.setActiveStatus(true);
        this.selectedItem = newSelectedItem;

        this.fire('ChartOpen', this, { date: items[index] });
    }

    public moreButtonClickEvent (index, tableRowItem, posX: number, posY: number): void {
        const items = this.get('arrayRowData');
        const itemsMenu = this.createMenuItems(items[index]);
        openContextMenu(posX, posY, itemsMenu);
    }

    public closeChartPanelEvent (): void {
        if (this.selectedItem) {
            this.selectedItem.setActiveStatus(false);
        }

        this.selectedItem = null;
    }

    public createHeaderRow (): void {
        const arrayHeaderColumn = [
            { Name: 'Symbol' },
            { Name: 'Side' },
            { Name: 'Quantity' },
            { Name: 'Profit' }
        ];

        void this.set({ arrayHeaderColumn });
    }

    public UpdateData (): void {
        this.createRowTable();
    }

    public createRowTable (): void {
        const arrayRowData = [];
        const dict = DataCache.PositionDict;

        const panelAcc = this.get('accountItem');
        if (!panelAcc) { return; }

        const positionsRows = [];
        for (const posID in dict) {
            const pos = dict[posID];
            const Obj: any = {};
            const acc = pos.Account;
            if (panelAcc !== acc) { continue; }

            Obj.Symbol = pos.GetInstrumentDisplayName();
            Obj.Description = pos.GetInstrumentDescriptionValue();
            Obj.Img = pos.Instrument.LogoAddress;
            Obj.Instrument = pos.Instrument;

            Obj.Side = pos.GetFullOperationName(true);
            Obj.Quantity = pos.Amount.toLocaleString();
            Obj.Profit = acc.formatPrice(pos.getNetPnL(true));
            Obj.NumberProfit = pos.getNetPnL(true);
            Obj.PositionId = posID;
            positionsRows.push(Obj);
        }

        for (let i = 0; i < positionsRows.length; i++) {
            const item = positionsRows[i];
            const row = new PositionRowDataItem(item);
            arrayRowData.push(row);
        }

        void this.set({ arrayRowData });
    }

    public ClosePosition (itemObject): void {
        if (!itemObject) {
            return;
        }

        void DataCache.FOrderExecutor.positionAction(PositionActionEnum.ByIds, false, [itemObject.PositionId], [itemObject.PositionId], false, PlacedFrom.WEB_POSITIONS_PANEL_DB_CLICK);
    }

    public createMenuItems (dataItem): Array<{ text: string, imgStyle: string, event: () => void }> {
        const items = [
        // {
        //     text: 'Details',
        //     imgStyle: "info"
        // },
        // {
        //     text: 'Exercise',
        //     imgStyle: "exercise"
        // },
            {
                text: 'Buy',
                imgStyle: 'buy',
                event: () => { openOrderEntry(true, dataItem.source, this.get('accountItem')); }
            },
            {
                text: 'Sell',
                imgStyle: 'sell',
                event: () => { openOrderEntry(false, dataItem.source, this.get('accountItem')); }
            },
            {
                text: 'Close',
                imgStyle: 'delete',
                event: () => { this.ClosePosition(dataItem.source); }
            }
        ];

        return items;
    }
}

Ractive.extendWith(InvestingPositionsPanel, {
    template: InvestingPositionsPanelTemplate,
    data: function () {
        return {
            arrayHeaderColumn: [],
            arrayRowData: [],
            accountItem: null
        };
    }
});
