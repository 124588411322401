// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../../DataCache';
import { Account } from '../../../cache/Account';
import { Resources } from '@shared/localizations/Resources';
import { AccountFeature } from '@shared/utils/Account/AccountFeature';
import { MarginGroupEnum } from '@shared/utils/Enums/AccountDetailsEnum';
import { MathUtils } from '@shared/utils/MathUtils';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { BaseAccountGroupController } from '../BaseControllers/BaseAccountGroupController';
import { type IGroupController } from '../../Interfaces/IGroupController';
import { RowItemDataNumberPair, RowItemDataStringPair } from '../../../cache/VerticalPanel/AccountDetails/RowItemData';
import { VerticalPanelColoringTypes } from '../../VerticalPanelColoringTypes';

export class MarginGroupController<GroupItemsType = MarginGroupEnum> extends BaseAccountGroupController<GroupItemsType> implements IGroupController<GroupItemsType> {
    public getGroupItemsTypes (): GroupItemsType[] {
        return [
            // MarginGroupEnum.FundsUsed,
            // MarginGroupEnum.FundsUsedPercent,
            MarginGroupEnum.MarginUsedNew,
            MarginGroupEnum.CurMargin,
            MarginGroupEnum.MaintMargin,
            // MarginGroupEnum.Leverage,
            MarginGroupEnum.MarginAvailableReal,
            MarginGroupEnum.MarginWarnPercent,
            MarginGroupEnum.MarginWarning,
            // MarginGroupEnum.MarginRequirement,
            // MarginGroupEnum.MinMargin,
            // MarginGroupEnum.BlockedByOrders,
            MarginGroupEnum.MarginStopOut,
            MarginGroupEnum.BlockedForStocks,
            MarginGroupEnum.StocksOrdersReq,
            MarginGroupEnum.StopOutPercent,
            // MarginGroupEnum.MarginDeficiency,
            // MarginGroupEnum.MarginSurplus,
            MarginGroupEnum.MarginForOthers,
            MarginGroupEnum.BlockedForStocksNew,
            MarginGroupEnum.OptionPremiumReq,
            MarginGroupEnum.WaivedMargin,
            MarginGroupEnum.WarningMarginReq,
            MarginGroupEnum.WarningMarginReqPercent,
            MarginGroupEnum.MarginBeforeWarning,
            MarginGroupEnum.InitialMarginWithoutWaived,
            MarginGroupEnum.StopOut,
            MarginGroupEnum.LiveLeverage
        ] as GroupItemsType[];
    }

    public getItemValue (type: GroupItemsType): RowItemDataNumberPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;

        switch (type) {
        case MarginGroupEnum.FundsUsed:
            return new RowItemDataNumberPair(NaN);
        case MarginGroupEnum.FundsUsedPercent:
            return new RowItemDataNumberPair(NaN);
        case MarginGroupEnum.MarginUsedNew:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.MarginUsed, curAccount, assetBalance));
        case MarginGroupEnum.StopOutPercent:
            return new RowItemDataNumberPair(curAccount.MarginLevel);
        case MarginGroupEnum.CurMargin:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.MarginUsedPercent, curAccount, assetBalance));
        case MarginGroupEnum.MaintMargin:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.RiskLevelPercent, curAccount, assetBalance));
        case MarginGroupEnum.Leverage:
            return new RowItemDataNumberPair(NaN);
        case MarginGroupEnum.MarginAvailableReal:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.MarginAvailable, curAccount, assetBalance));
        case MarginGroupEnum.MarginWarnPercent:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.MarginWarningLevel, curAccount, assetBalance));
        case MarginGroupEnum.MarginWarning:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.WarningMargin, curAccount, assetBalance));
        case MarginGroupEnum.MarginRequirement:
            return new RowItemDataNumberPair(NaN);
        case MarginGroupEnum.MinMargin:
            return new RowItemDataNumberPair(NaN);
        case MarginGroupEnum.BlockedByOrders:
            return new RowItemDataNumberPair(NaN);
        case MarginGroupEnum.MarginStopOut:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.TotalMaintReq, curAccount, assetBalance));
        case MarginGroupEnum.StocksOrdersReq:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.StocksOrdersReq, curAccount, assetBalance));
        case MarginGroupEnum.MarginDeficiency:
            return new RowItemDataNumberPair(NaN);
        case MarginGroupEnum.MarginSurplus:
            return new RowItemDataNumberPair(NaN);
        case MarginGroupEnum.BlockedForStocks:
            return new RowItemDataNumberPair(curAccount.BlockedForStocksAccountCurrency / curAccount.getCrossPrice());
        case MarginGroupEnum.MarginForOthers:
        {
            const marginUsed = Account.GetAccountFeature(AccountFeature.MarginUsed, curAccount, assetBalance);
            return new RowItemDataNumberPair(((marginUsed - curAccount.BlockedForStocksAccountCurrency) / curAccount.getCrossPrice()));
        }
        case MarginGroupEnum.BlockedForStocksNew:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.BlockedForStocks, curAccount, assetBalance));
        case MarginGroupEnum.OptionPremiumReq:
            return new RowItemDataNumberPair(assetBalance.OptionPremiumReq);
        case MarginGroupEnum.WaivedMargin:
            return new RowItemDataNumberPair(curAccount.WaivedMargin);
        case MarginGroupEnum.WarningMarginReq:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.WarnMarginReq, curAccount, assetBalance));
        case MarginGroupEnum.WarningMarginReqPercent:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.WarnMarginReqPercent, curAccount, assetBalance));
        case MarginGroupEnum.MarginBeforeWarning:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.MarginBeforeWarning, curAccount, assetBalance));
        case MarginGroupEnum.InitialMarginWithoutWaived:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.InitialMarginWithoutWaived, curAccount, assetBalance));
        case MarginGroupEnum.StopOut:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.StopOut, curAccount, assetBalance));
        case MarginGroupEnum.LiveLeverage:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.LiveLeverage, curAccount, assetBalance));
        }
    }

    public getItemFormattedValue (type: GroupItemsType): RowItemDataStringPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;
        const value = this.getItemValue(type);

        switch (type) {
        case MarginGroupEnum.FundsUsed:
            return new RowItemDataStringPair('');
        case MarginGroupEnum.FundsUsedPercent:
            return new RowItemDataStringPair('');
        case MarginGroupEnum.MarginUsedNew:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.MarginUsed, curAccount, assetBalance));
        case MarginGroupEnum.StopOutPercent:
            return new RowItemDataStringPair(MathUtils.TruncateDouble(curAccount.MarginLevel, 2) + ' %');
        case MarginGroupEnum.CurMargin:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.MarginUsedPercent, curAccount, assetBalance));
        case MarginGroupEnum.MaintMargin:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.RiskLevelPercent, curAccount, assetBalance));
        case MarginGroupEnum.Leverage:
            return new RowItemDataStringPair('');
        case MarginGroupEnum.MarginAvailableReal:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.MarginAvailable, curAccount, assetBalance));
        case MarginGroupEnum.MarginWarnPercent:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.MarginWarningLevel, curAccount, assetBalance));
        case MarginGroupEnum.MarginWarning:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.WarningMargin, curAccount, assetBalance));
        case MarginGroupEnum.MarginRequirement:
            return new RowItemDataStringPair('');
        case MarginGroupEnum.MinMargin:
            return new RowItemDataStringPair('');
        case MarginGroupEnum.BlockedByOrders:
            return new RowItemDataStringPair('');
        case MarginGroupEnum.MarginStopOut:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.TotalMaintReq, curAccount, assetBalance));
        case MarginGroupEnum.StocksOrdersReq:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.StocksOrdersReq, curAccount, assetBalance));
        case MarginGroupEnum.MarginDeficiency:
            return new RowItemDataStringPair('');
        case MarginGroupEnum.MarginSurplus:
            return new RowItemDataStringPair('');
        case MarginGroupEnum.BlockedForStocks:
            return new RowItemDataStringPair(assetBalance.formatPrice(curAccount.BlockedForStocksAccountCurrency));
        case MarginGroupEnum.MarginForOthers:
            return new RowItemDataStringPair(assetBalance.formatPrice(value.firstValue * curAccount.getCrossPrice()));
        case MarginGroupEnum.BlockedForStocksNew:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.BlockedForStocks, curAccount, assetBalance));
        case MarginGroupEnum.OptionPremiumReq:
            return new RowItemDataStringPair(assetBalance.formatPrice(assetBalance.OptionPremiumReq));
        case MarginGroupEnum.WaivedMargin:
            return new RowItemDataStringPair((value !== null && value.firstValue !== -1 ? assetBalance.formatPrice(value.firstValue) : Resources.getResource('general.N_A')));
        case MarginGroupEnum.WarningMarginReq:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.WarnMarginReq, curAccount, assetBalance));
        case MarginGroupEnum.WarningMarginReqPercent:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.WarnMarginReqPercent, curAccount, assetBalance));
        case MarginGroupEnum.MarginBeforeWarning:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.MarginBeforeWarning, curAccount, assetBalance));
        case MarginGroupEnum.InitialMarginWithoutWaived:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.InitialMarginWithoutWaived, curAccount, assetBalance));
        case MarginGroupEnum.StopOut:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.StopOut, curAccount, assetBalance));
        case MarginGroupEnum.LiveLeverage:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.LiveLeverage, curAccount, assetBalance, null, true));
        }
    }

    public getItemNameKey<MarginGroupEnum> (type: MarginGroupEnum): string {
        switch (type) {
        case MarginGroupEnum.FundsUsed:
            return 'panel.accounts.FundsUsed';
        case MarginGroupEnum.FundsUsedPercent:
            return 'panel.accounts.FundsUsedPercent';
        case MarginGroupEnum.MarginUsedNew:
            return 'panel.accounts.MarginUsedNew';
        case MarginGroupEnum.StopOutPercent:
            return 'panel.accounts.stopOutPercent';
        case MarginGroupEnum.CurMargin:
            return 'panel.accounts.CurMargin';
        case MarginGroupEnum.MaintMargin:
            return 'panel.accounts.MaintMargin';
        case MarginGroupEnum.Leverage:
            return 'panel.accounts.Leverage';
        case MarginGroupEnum.MarginAvailableReal:
            return 'panel.accounts.MarginAvailableReal';
        case MarginGroupEnum.MarginWarnPercent:
            return 'panel.accounts.marginWarnPercent';
        case MarginGroupEnum.MarginWarning:
            return 'panel.accounts.MarginWarning';
        case MarginGroupEnum.MarginRequirement:
            return 'panel.accounts.MarginRequirement';
        case MarginGroupEnum.MinMargin:
            return 'panel.accounts.MinMargin';
        case MarginGroupEnum.BlockedByOrders:
            return 'panel.accounts.BlockedByOrders';
        case MarginGroupEnum.MarginStopOut:
            return 'panel.accounts.MarginStopOut';
        case MarginGroupEnum.StocksOrdersReq:
            return 'panel.accounts.StocksOrdersReq';
        case MarginGroupEnum.MarginDeficiency:
            return 'panel.accounts.MarginDeficiency';
        case MarginGroupEnum.MarginSurplus:
            return 'panel.accounts.MarginSurplus';
        case MarginGroupEnum.BlockedForStocks:
            return 'panel.accounts.BlockedForStocks';
        case MarginGroupEnum.MarginForOthers:
            return 'panel.accounts.MarginForOthers';
        case MarginGroupEnum.BlockedForStocksNew:
            return 'panel.accounts.BlockedForStocksNew';
        case MarginGroupEnum.OptionPremiumReq:
            return 'panel.accounts.OptionPremiumReq';
        case MarginGroupEnum.WaivedMargin:
            return 'panel.accounts.WaivedMargin';
        case MarginGroupEnum.WarningMarginReq:
            return 'panel.accounts.WarningMarginReq';
        case MarginGroupEnum.WarningMarginReqPercent:
            return 'panel.accounts.WarningMarginReqPercent';
        case MarginGroupEnum.MarginBeforeWarning:
            return 'panel.accounts.MarginBeforeWarning';
        case MarginGroupEnum.InitialMarginWithoutWaived:
            return 'panel.accounts.InitialMarginWithoutWaived';
        case MarginGroupEnum.StopOut:
            return 'panel.accounts.StopOut';
        case MarginGroupEnum.LiveLeverage:
            return 'panel.accounts.LiveLeverage';
        }
    }

    public isHiddenItemRow (type: GroupItemsType): boolean {
        const itemNameKey = this.getItemNameKey(type);
        if (Resources.isHidden(itemNameKey)) {
            return true;
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_EXT_RISK)) {
            return true;
        }
    }

    public getColoringMethod (type: GroupItemsType): VerticalPanelColoringTypes {
        if (type === MarginGroupEnum.LiveLeverage) { return VerticalPanelColoringTypes.LiveLeverage; }
        return VerticalPanelColoringTypes.Default;
    }
}
