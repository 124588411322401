// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum Level1ItemType {
    Last,
    Open,
    ChangePercent,
    High,
    Volume,
    Low,
    IndicativeAuctionPrice,
    SettlementPrice,
    PrevSettlementPrice,
    VWAP,
    ReferencePrice,
    Ask,
    Spread,
    LastTime,
    ETB,
    PrevClose,
    Change,
    Bid,
    BidSize,
    AskSize,
    LastSize,
    OpenInterest,
    BidSource,
    AskSource,
    LastSource,
    Funding,
    Countdown,

    AuctionStart,
    AuctionEnd,
    LastUpdateTime,
    CurrentSession,
    TradingStatus,
    TradedValue,
    OffExchangeVolume,
    OffExchangeValue,
    NormalMarketSize,
    LimitHigh,
    LimitLow,
    RemainingQty,
    RemainingQtySide,
    TotalBuyQty,
    TotalSellQty,
    AvgTradedPrice,
    FiftyTwoWeekHighPrice,
    FiftyTwoWeekLowPrice,
    NSEValue,
    DPR,
    LastTradedTime,
}
