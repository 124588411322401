// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolRayType } from '../../Commons/cache/DataCacheToolEnums';
import { type FibonacciTimeGoalDataCacheTool } from '../../Commons/cache/Tools/FibonacciTimeGoalDataCacheTool';
import { ToolView } from './ToolView';
import { ToolViewUtils } from './Utils/ToolViewUtils';

export class FibonacciTimeGoalToolView extends ToolView<FibonacciTimeGoalDataCacheTool> {
    public DrawFibTGA (gr, ww, highlight): void {
        const dataCacheTool = this.dataCacheTool;
        const fibLevel = dataCacheTool.fibLevel;
        const points = dataCacheTool.Points;
        const clientRect = ww.ClientRectangle;
        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;
        const font = dataCacheTool.font;
        const fontColor = dataCacheTool.FontColor;

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        const xStep = scrP1X - scrP0X;
        const instrument = dataCacheTool.Instrument;

        gr.DrawLine(pen, scrP0X, scrP0Y, scrP1X, scrP1Y);

        const fibLevel_len = fibLevel.length;
        for (let i = 0; i < fibLevel_len; i++) {
            const fibLvl = fibLevel[i];

            const cx = scrP1X + xStep * fibLvl * 0.01;
            if (cx > clientRect.X && cx < clientRect.X + clientRect.Width) {
                ToolView.DrawLineWithRay(gr, ww, cx, clientRect.Y + 2, cx, clientRect.Y + clientRect.Height - 1, DataCacheToolRayType.NoRay, pen);
                if (!highlight) {
                    const fibText = ToolViewUtils.FormatFibonacciText(fibLvl, points, instrument);
                    ToolViewUtils.DrawFibonacciText(gr, ww, cx + 2, clientRect.Y + clientRect.Height - 2, fibText, font, fontColor);
                }
            }
        }
    }

    public override Draw (gr, ww, param): void {
        this.DrawFibTGA(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        this.DrawFibTGA(gr, ww, true);
        super.DrawSelection(gr, ww);
    }
}
