// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { type AlertAction, type AlertAfterExecute, type AlertCondition, type AlertImportance, type AlertNotification, type AlertType } from '../../../../Utils/Alert/AlertConstants';

export class CreateAlertMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_CREATE_ALERT_MESSAGE, fieldSet);
    }

    public setAlertAccountId (accountId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ACCOUNT_ID, accountId);
    }

    public setUserId (userId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_USER_ID, userId);
    }

    public setTradableInstrumentId (instrumentId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, instrumentId);
    }

    public setRouteId (routeId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ROUTE_ID, routeId);
    }

    public setAlertType (alertType: AlertType): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_TYPE, alertType);
    }

    public setAlertCondition (alertCondition: AlertCondition): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_CONDITION, alertCondition);
    }

    public setAlertValue (alertValue: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_VALUE, alertValue);
    }

    public setAlertImportance (alertImportance: AlertImportance): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_IMPORTANCE, alertImportance);
    }

    public setAlertDate (alertDate: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_DATE, alertDate);
    }

    public setAlertAfterExecute (alertAfterExecute: AlertAfterExecute): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_AFTER_EXECUTE, alertAfterExecute);
    }

    public setAlertAction (alertAction: AlertAction): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_ACTION, alertAction);
    }

    public setAlertNotification (alertNotification: AlertNotification): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_NOTIFICATION, alertNotification);
    }

    public setAlertText (alertText: string): void {
        this.setFieldValue(FieldsFactory.FIELD_TEXT, alertText);
    }

    public setAlertLeverage (alertLeverage: number): void {
        this.setFieldValue(FieldsFactory.FIELD_LEVERAGE_SHORT, alertLeverage);
    }
}
