// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type Instrument } from '../Commons/cache/Instrument';
import { Intervals } from './Instruments/Intervals';

export class _NumericUtils {
    public readonly MINVALUE = -999999999;
    public readonly MAXVALUE = 999999999;

    public getPriceNumericParameters (instrument: Instrument): { min: number, max: number, step: number, precision: number } {
        if (isNullOrUndefined(instrument)) {
            return { min: 0, max: 0, step: 0, precision: 0 };
        }
        const max = this.MAXVALUE;
        const precision = instrument.Precision;
        const step = instrument.GetPointSize(null);
        const intervals = Intervals.GetIntervalsFromInstrument(instrument, max);
        const min = isValidArray(intervals) ? intervals[0].LeftValue : step;
        return { min, max, step, precision };
    }
}

export const NumericUtils = new _NumericUtils();
