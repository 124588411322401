// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum PlacedFrom // last upd in #111940
{
    OTHER = 0,
    TABLE_ALERT = 1,
    CUSTOM_COLUMN_BTN_SCRIPT = 2,
    SDK = 3,
    OE = 4,
    // FXBOARD = 5, // c 2017 года была удалена
    MARKETDEPTH = 6,
    MATRIX = 7,
    CHART_OE = 8,
    CHART_VISUAL = 9,
    // = 10, Desktop =Currency Exchange События, связанные с панелью Currency Exchange
    POSITIONS_PANEL_HOT_BTN = 11,
    POSITIONS_PANEL_DB_CLICK = 12,
    ORDERS_PANEL_HOT_BTN = 13,
    ORDERS_PANEL_DB_CLICK = 14,
    OPTION_MASTER = 15,
    SAVED_ORDERS = 16,
    SCALPER = 17,
    HOT_BUTTON = 18,
    CHART_VISUAL_DB_CLICK = 19,
    TRADINGSYSTEMLIST = 20,
    MATRIX_OE = 21,
    MAM_OE = 22,
    // = 23, Desktop =Console События из созданной консоли панели Trading status list
    LADDER_VIEW = 24,
    LADDER_VIEW_OE = 25,
    TRADING_IDEA_CARD = 26,
    PORTFOLIO_MANAGER = 27,
    POSITIONS_PANEL_CONTEXT_MENU = 28,
    ORDERS_PANEL_CONTEXT_MENU = 29,
    CHART_VISUAL_CONTEXT_MENU = 30,

    WEB_CHART_OE = 50,
    WEB_CHART_VISUAL = 51,
    WEB_CHART_VISUAL_DB_CLICK = 52,
    WEB_MARKETDEPTH = 53,
    WEB_OE = 54,
    // WEB_FXBOARD = 55, // same as 5
    WEB_OPTION_MASTER = 56,
    WEB_ORDERS_PANEL_HOT_BTN = 57,
    WEB_ORDERS_PANEL_DB_CLICK = 58,
    WEB_POSITIONS_PANEL_HOT_BTN = 59,
    WEB_POSITIONS_PANEL_DB_CLICK = 60,
    WEB_PORTFOLIO_MANAGER = 61,
    WEB_CHART_VISUAL_CONTEXT_MENU = 62,
    WEB_ORDERS_PANEL_CONTEXT_MENU = 63,
    WEB_POSITIONS_PANEL_CONTEXT_MENU = 64,
    WEB_SAVED_ORDERS = 65,

    MOB_CHART_OE = 70,
    MOB_WL_OE = 71,
    MOB_MARKETDEPTH = 72,
    MOB_ORDERS_HOT_BTN = 73,
    MOB_POSITIONS_HOT_BTN = 74,
    MOB_TAB_CHART_VISUAL_HOT_BTN = 75,
    MOB_CANCEL_ALL_ORDERS = 76,
    MOB_CLOSE_ALL_POSITIONS = 77,
    MOB_OTHER = 78,
    MOB_PORTFOLIO_MANAGER = 79,
    MOB_CHART_VISUAL_TRADING = 80,
    MOB_TRADING_IDEAS = 81,
    MOB_INVESTING_CHART_OE = 82,
    MOB_INVESTING_WATCHLIST_OPTION_OE = 83,
    MOB_INVESTING_ASSETS_SELL_ASSET = 84,
    MOB_INVESTING_POSITIONS_INCREASE = 85,
    MOB_INVESTING_CANCEL_ORDERS = 86,
    MOB_INVESTING_CLOSE_POSITIONS = 87,
    MOB_INVESTING_TRADING_IDEAS = 88,
    MOB_INVESTING_OTHER = 89,

    CLOSE_ALL_ORDERS = 97,
    CLOSE_ALL_POSITIONS = 98,

    WEB_OTHER = 100,
    SERVER_OTHER = 101,
    REST = 102,

    DESKTOP_ALERT = 105,
    WEB_ALERT = 106,
    MAC_ALERT = 107,
    ANDROID_ALERT = 108,
    IOS_ALERT = 109,
    MOB_OPTIONS_PANEL = 110,

    AUTO_INVEST_MOB_ANDROID = 122,
    AUTO_INVEST_MOB_IOS = 123,
    AUTO_INVEST_MOB_WEB = 124,
    AUTO_INVEST_DESKTOP = 125,
    AUTO_INVEST_WEB = 126,
    AUTO_INVEST_MOB_INV_ANDROID = 127,
    AUTO_INVEST_MOB_INV_IOS = 128,

    TV_TRADING_PLATFORM_OTHER = 180,
    TV_TRADING_PLATFORM_OE = 181,
    TV_TRADING_PLATFORM_DOM = 182,
    TV_TRADING_PLATFORM_CHART_VISUAL = 183,
    TV_TRADING_PLATFORM_POSITIONS = 184,
    TV_TRADING_PLATFORM_ORDER_PANEL = 185,

    SERVER = 255
}
