// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// import { types } from 'node-sass';
import { DirectPropertyOwner } from '@shared/utils/Enums/Constants';
import { type Account } from './Account';
import { type User } from './User';

export class RulesCache {
    private readonly DataCache: any;
    private RulesByAccount = {};
    private RulesByUser = {};
    private RulesByUserGroup = {};
    private FeatureFlags = {};
    private GlobalRules = {};

    constructor (datacache) {
        this.DataCache = datacache;
    }

    public Clear (): void {
        this.RulesByAccount = {};
        this.RulesByUser = {};
        this.RulesByUserGroup = {};
        this.FeatureFlags = {};
        this.GlobalRules = {};
    }

    public AddRule (ownerType: DirectPropertyOwner, ownerID: string, ruleKey: string, ruleValue: any): void {
        switch (ownerType) {
        case DirectPropertyOwner.Account:
            if (!this.RulesByAccount.hasOwnProperty(ownerID)) {
                this.RulesByAccount[ownerID] = {};
            }
            this.RulesByAccount[ownerID][ruleKey] = ruleValue;
            break;

        case DirectPropertyOwner.User:
            if (!this.RulesByUser.hasOwnProperty(ownerID)) {
                this.RulesByUser[ownerID] = {};
            }
            this.RulesByUser[ownerID][ruleKey] = ruleValue;
            break;

        case DirectPropertyOwner.Group:
            if (!this.RulesByUserGroup.hasOwnProperty(ownerID)) {
                this.RulesByUserGroup[ownerID] = {};
            }
            this.RulesByUserGroup[ownerID][ruleKey] = ruleValue;
            break;

        default:
            this.GlobalRules[ruleKey] = ruleValue;
            break;
        }
    }

    public AddFeatureFlag (ruleKey: string, ruleValue: string): void {
        this.FeatureFlags[ruleKey] = ruleValue;
    }

    // Get rule value from cache
    public GetRuleValue<T = string> (ruleName: string, account?: Account | null): T {
        let acc = account;
        if (acc == null) {
            acc = this.DataCache.getPrimaryAccount();
        }

        if (acc == null) {
            return this.GlobalRules.hasOwnProperty(ruleName) ? this.GlobalRules[ruleName] : null;
        }

        if (this.RulesByAccount.hasOwnProperty(acc.BstrAccount) &&
            this.RulesByAccount[acc.BstrAccount].hasOwnProperty(ruleName)) {
            return this.RulesByAccount[acc.BstrAccount][ruleName];
        }

        if (this.RulesByUser.hasOwnProperty(acc.userID) &&
            this.RulesByUser[acc.userID].hasOwnProperty(ruleName)) {
            return this.RulesByUser[acc.userID][ruleName];
        }

        let groupMessage = this.DataCache.GetUserGroupById(acc.User.GroupId);
        while (groupMessage != null) {
            const groupId = groupMessage.GroupId;
            if (this.RulesByUserGroup.hasOwnProperty(groupId) &&
                this.RulesByUserGroup[groupId].hasOwnProperty(ruleName)) {
                return this.RulesByUserGroup[groupId][ruleName];
            }
            groupMessage = this.DataCache.GetUserGroupById(groupMessage.SuperGroupId);
        }

        return this.GlobalRules.hasOwnProperty(ruleName) ? this.GlobalRules[ruleName] : null;
    }

    public IsAllowedForAccount (ruleName: string, account: Account | null): boolean {
        return this.GetRuleValue(ruleName, account) === '1';
    }

    public IsExistForAccount (ruleName: string, account: Account | null): boolean {
        return this.GetRuleValue(ruleName, account) !== null;
    }

    public IsAllowedFeatureFlag (ruleName: string): boolean {
        if (this.FeatureFlags.hasOwnProperty(ruleName)) {
            return this.FeatureFlags[ruleName] === 'true';
        }
        return false;
    }

    public IsAllowedForUser (ruleName: string, user: User | null): boolean {
        if (user == null) {
            return false;
        }

        for (let i = 0; i < user.Accounts.length; i++) {
            if (this.IsAllowedForAccount(ruleName, user.Accounts[i])) {
                return true;
            }
        }

        return false;
    }

    public IsExistForUser (ruleName: string, user: User | null): boolean {
        if (user == null) {
            return false;
        }

        for (let i = 0; i < user.Accounts.length; i++) {
            if (this.IsExistForAccount(ruleName, user.Accounts[i])) {
                return true;
            }
        }

        return false;
    }

    public IsAllowedForMyUser (name: string): boolean {
        const dc = this.DataCache;

        let user = dc.LoginUser;
        if (user == null) {
            user = dc.getUserByLogin(dc.FLogin);
        }

        return this.IsAllowedForUser(name, user);
    }

    public IsExistForMyUser (name: string): boolean {
        const dc = this.DataCache;

        let user = dc.LoginUser;
        if (user == null) {
            user = dc.getUserByLogin(dc.FLogin);
        }

        return this.IsExistForUser(name, user);
    }

    // Check rule through all accounts on user
    public GetRuleValueForUser (ruleName: string, user: User | null): string | null {
        if (user == null) {
            return null;
        }

        for (let i = 0; i < user.Accounts.length; i++) {
            const ruleValue = this.GetRuleValue(ruleName, user.Accounts[i]);
            if (ruleValue) {
                return ruleValue;
            }
        }
        return null;
    }

    public GetRuleValueForMyUser (name: string): string | null {
        const dc = this.DataCache;

        let user = dc.LoginUser;
        if (user == null) {
            user = dc.getUserByLogin(dc.FLogin);
        }

        return this.GetRuleValueForUser(name, user);
    }
}
