// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Message } from '@shared/utils/DirectMessages/Message';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { PFixMessage } from './PFixMessage';

export class AccountFollowerRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_ACCOUNT_FOLLOWER_REQ, fieldSet);
    }

    /**
     * Sets the follower request status.
     * @param {number} status - The status code of the follower request.
     */
    public setFollowerRequestStatus (status: number): void {
        this.FieldSet.SetField(FieldsFactory.FIELD_FOLLOWER_REQUEST_STATUS, status);
    }

    /**
     * Sets the master ID.
     * @param {number} masterId - The ID of the master account.
     */
    public setMasterId (masterId: number): void {
        this.FieldSet.SetField(FieldsFactory.FIELD_MASTER_ID, masterId);
    }

    /**
     * Sets the follower ID.
     * @param {number} followerId - The ID of the follower account.
     */
    public setFollowerIds (followerIDs: number[]): void {
        this.FieldSet.SetField(FieldsFactory.FIELD_FOLLOWER_IDS, followerIDs);
    }
}
