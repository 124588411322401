// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class RLW extends IndicatorScriptBase {
    public ExtWPRPeriod: number;

    constructor () {
        super();
        this.ProjectName = '%R Larry Williams';
        this.Comments = 'Uses Stochastic to determine overbought and oversold levels';
        this.SetIndicatorLine('RWL', Color.DodgerBlue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.SetLevelLine('Up', -20, Color.Gray, 1, LineStyle.ShapedChart);
        this.SetLevelLine('Down', -80, Color.Gray, 1, LineStyle.ShapedChart);

        this.ExtWPRPeriod = 14;
        super.InputParameter(new InputParameterInteger('ExtWPRPeriod', 'ExtWPRPeriod', 1, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'RLW(' + this.ExtWPRPeriod + ')';
    };

    public override getCustomName (): string {
        return 'RLW (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count < this.ExtWPRPeriod) { return; }

        const highest = this.getExtremumPrice(PriceType.High);
        const lowest = this.getExtremumPrice(PriceType.Low);
        if ((highest - lowest) > 0.0000001) { this.SetValue(0, 0, -100 * (highest - this.CurrentData.GetPrice(PriceType.Close)) / (highest - lowest)); }
    };

    public getExtremumPrice (priceType): number {
        const arr = new Array<number>();
        for (let i = 0; i < this.ExtWPRPeriod; i++) {
            arr.push(this.CurrentData.GetPrice(priceType, i));
        }
        const res = priceType === PriceType.High ? Math.max.apply(Math, arr) : Math.min.apply(Math, arr);
        return res;
    };
}
