// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class CCI extends IndicatorScriptBase {
    public MAType: number;
    public MAPeriod: number;

    private ma: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'Commodity Channel Index';
        this.Comments = 'Measures the position of price in relation to its moving average';
        super.SetIndicatorLine('cci', Color.Red, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.SetLevelLine('150', 150, Color.Gray, 1, LineStyle.ShapedChart);
        this.SetLevelLine('100', 100, Color.Gray, 1, LineStyle.ShapedChart);
        this.SetLevelLine('Zero', 0, Color.Gray, 1, LineStyle.ShapedChart);
        this.SetLevelLine('-100', -100, Color.Gray, 1, LineStyle.ShapedChart);
        this.SetLevelLine('-150', -150, Color.Gray, 1, LineStyle.ShapedChart);

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of Moving Average', 0,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.MAPeriod = 14;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of Moving Average', 1, 1, 99999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'CCI(' + this.MAPeriod + ')';
    };

    public override getCustomName (): string {
        return 'CCI (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ma = this.Indicators.iMA(this.CurrentData, this.MAPeriod, this.MAType, 0, PriceType.Typical);
        return [this.ma];
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count < this.MAPeriod) { return; }

        const val = this.ma.GetValue();
        let d = 0;
        for (let i = 0; i < this.MAPeriod; i++) { d += Math.abs(this.CurrentData.GetPrice(PriceType.Typical, i) - val); }

        d = 0.015 * (d / this.MAPeriod);
        this.SetValue(0, 0, (this.CurrentData.GetPrice(PriceType.Typical, 0) - val) / d);
    };
}
