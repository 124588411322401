// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { StandardFormatterName, type OrderTableColumn } from '../charting_library';
import { TvBaseColumns } from './TvBaseColumns';

class _TvOrderHistoryColumns extends TvBaseColumns {
    public getOrderHistoryColumns (): OrderTableColumn[] {
        const date = this.createColumnItem('panel.orders.date_time', 'date', StandardFormatterName.LocalDateOrDateTime);
        const symbol = this.createColumnItem('panel.orders.symbol', 'symbol', StandardFormatterName.Symbol);
        const side = this.createColumnItem('panel.orders.action', 'side', StandardFormatterName.Side);
        const type = this.createColumnItem('panel.orders.type',
            'type',
            StandardFormatterName.Type,
            false,
            ['type', 'parentId', 'stopType']);
        const qty = this.createColumnItem('panel.orders.quantity', 'qty', StandardFormatterName.FormatQuantity);
        const limitPrice = this.createColumnItem('panel.orders.price', 'limitPrice', StandardFormatterName.FormatPrice);
        const stopPrice = this.createColumnItem('panel.orders.StopPrice', 'stopPrice', StandardFormatterName.FormatPrice);
        const tif = this.createColumnItem('panel.orders.TIF', 'tif', StandardFormatterName.Text);
        const status = this.createColumnItem('panel.orders.Status', 'status', StandardFormatterName.Status);
        const id = this.createColumnItem('panel.orders.Reference', 'id', StandardFormatterName.Text);

        return [
            date,
            symbol,
            side,
            type,
            qty,
            limitPrice,
            stopPrice,
            tif,
            status,
            id
        ];
    }
}

export const TvOrderHistoryColumns = new _TvOrderHistoryColumns();
