// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { KeyValueGroup } from './GroupsImport';

import { OptionContract } from '../../../../Utils/Instruments/OptionContract';
import { StrikePriceSettings } from '../../../../Utils/Instruments/StrikePriceSettings';
import { PriceLimitMeasure } from '../../../../Utils/Instruments/PriceLimitMeasure';
import { type OptionPutCall } from '../../../../Utils/Instruments/OptionPutCall';
import { type TradingMode } from '../../../../Utils/Instruments/TradingMode';

export class OptionContractGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.OPTION_CONTRACT_GROUP, null, pfixFieldSet);
    }

    public getGroupContractID (): number {
        return this.GetValue(FieldsFactory.FIELD_CONTRACT_ID);
    }

    public getStrikeContractID (): number {
        return this.GetValue(FieldsFactory.FIELD_OPTION_CONTRACT_ID);
    }

    public getStrikeTicker (): string {
        return this.GetValue(FieldsFactory.FIELD_NAME);
    }

    public getStrikePrice (): number {
        return this.GetValue(FieldsFactory.FIELD_STRIKE_PRICE);
    }

    // возвращает call (0) или put (1)
    public getPutOrCall (): OptionPutCall {
        return this.GetValue(FieldsFactory.FIELD_OPTION_TYPE);
    }

    public getLowLimit (): number {
        return this.GetValue(FieldsFactory.FIELD_PRICE_LOW_LIMIT);
    }

    public getHighLimit (): number {
        return this.GetValue(FieldsFactory.FIELD_PRICE_HI_LIMIT);
    }

    public getPriceLimitMeasure (): PriceLimitMeasure {
        const val = this.GetValue(FieldsFactory.FIELD_PRICE_LIMIT_MESURE);
        return val === null ? PriceLimitMeasure.DISABLE : val;
    }

    public getDescription (): string {
        return this.GetValue(FieldsFactory.FIELD_DESCRIPTION);
    }

    public getTradeSessionStatusId (): number {
        return this.GetValue(FieldsFactory.FIELD_TRADE_SESSION_STATUS_ID);
    }

    public getExchangeSessionName (): string {
        return this.GetValue(FieldsFactory.FIELD_EXCHANGE_SESSION_NAME);
    }

    public getInstrumentTradableID (): number {
        return this.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
    }

    public getMinLot (): number {
        return this.GetValue(FieldsFactory.FIELD_MINIMAL_LOT);
    }

    public getMaxLot (): number {
        return this.GetValue(FieldsFactory.FIELD_MAX_LOT);
    }

    public getISIN (): string {
        return this.GetValue(FieldsFactory.FIELD_ISIN);
    }

    public getTradingMode (): TradingMode {
        return this.GetValue(FieldsFactory.FIELD_TRADE_MODE);
    }

    public getHighLimitFrontEndValidationEnabled (): boolean {
        return this.GetValue(FieldsFactory.FIELD_HI_PRICE_LIMIT_ENABLED);
    }

    public getLowLimitFrontEndValidationEnabled (): boolean {
        return this.GetValue(FieldsFactory.FIELD_LOW_PRICE_LIMIT_ENABLED);
    }

    public getExtFields (): any {
        const extFields = this.GetGroups(FieldsFactory.KEY_VALUE_GROUP, KeyValueGroup);
        if (extFields.length === 0) {
            return {};
        }

        const result: any = {};

        const len = extFields.length;
        for (let i = 0; i < len; i++) {
            const kv = extFields[i];
            result[kv.getKey()] = kv.getValue();
        }

        return result;
    }

    public static GetOldOptionContractGroup (fs): StrikePriceSettings[] {
        const ff = FieldsFactory;
        const strikes = fs.GetGroups(FieldsFactory.STRIKE_PRICE_GROUP);
        const resArr: StrikePriceSettings[] = [];

        const len = strikes.length;
        for (let i = 0; i < len; i++) {
            const strike = strikes[i];
            resArr.push(new StrikePriceSettings(
                strike.GetValue(ff.FIELD_STRIKE_PRICE),
                strike.GetValue(ff.FIELD_ENABLE_PUT),
                strike.GetValue(ff.FIELD_ENABLE_CALL)));
        }

        return resArr;
    }

    // новая схема OPTION_CONTRACT_GROUP(57) приходит в InstrumentMessage (2)
    // это сделано так, потому что при расположении группы по старой схеме
    // происходило переполнение EXPIRATION_DATE_GROUP_NAME(26) (#57391)
    public static GetNewOptionContractGroup (fs, contractID = null): OptionContract[] {
        const ocGroupList = fs.GetGroups(FieldsFactory.OPTION_CONTRACT_GROUP, OptionContractGroup);
        const ocList: OptionContract[] = [];
        const len = ocGroupList.length;
        for (let i = 0; i < len; i++) {
            const ocg = ocGroupList[i];

            const oc = new OptionContract(
                contractID === null ? ocg.getGroupContractID() : contractID,
                ocg.getStrikeContractID(),
                ocg.getStrikeTicker(),
                ocg.getStrikePrice(),
                ocg.getPutOrCall());

            oc.LowLimit = ocg.getLowLimit();
            oc.HighLimit = ocg.getHighLimit();
            oc.PriceLimitMeasure = ocg.getPriceLimitMeasure();
            oc.Description = ocg.getDescription();
            oc.ExtFields = ocg.getExtFields();
            oc.TradeSessionStatusId = ocg.getTradeSessionStatusId();
            oc.ExchangeSessionName = ocg.getExchangeSessionName();
            oc.InstrumentTradableID = ocg.getInstrumentTradableID();
            oc.ISIN = ocg.getISIN();
            oc.TradingMode = ocg.getTradingMode();
            oc.minLot = ocg.getMinLot();
            oc.maxLot = ocg.getMaxLot();
            oc.IsHighLimitFrontEndValidationEnabled = ocg.getHighLimitFrontEndValidationEnabled();
            oc.IsLowLimitFrontEndValidationEnabled = ocg.getLowLimitFrontEndValidationEnabled();

            ocList.push(oc);
        }

        return ocList;
    }

    // мержим контракты пришедшие по старой схеме, с контрактами, пришедшими, по новой схеме
    public static MergeOptionContractGroup (fs, insDateSettingsArray): void {
        const optionContractArr = OptionContractGroup.GetNewOptionContractGroup(fs);
        for (let i = 0, len = insDateSettingsArray.length; i < len; i++) {
            OptionContract.MergeOptionContractGroup(
                insDateSettingsArray[i],
                optionContractArr);
        }
    }
}
