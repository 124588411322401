// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type ICanvasDrawer } from './ICanvasDrawer';
import { MapContainer } from '../../../Commons/Heatmap/Maps/MapContainer';
import { HeatmapSectorRenderer } from '../../panels/Heatmap/Renderers/HeatmapSectorRenderer';
import { HeatmapIndustryRenderer } from '../../panels/Heatmap/Renderers/HeatmapIndustryRenderer';
import { HeatmapSymbolRenderer } from '../../panels/Heatmap/Renderers/HeatmapSymbolRenderer';
import { type IHeatmapSettings } from '../../../Commons/Heatmap/Settings/IHeatmapSettings';
import { type SectorMap } from '../../../Commons/Heatmap/Maps/SectorMap';
import { type IndustryMap } from '../../../Commons/Heatmap/Maps/IndustryMap';
import { Point, Rectangle } from '../../../Commons/Geometry';
import { HeatmapSettings } from '../../../Commons/Heatmap/Settings/HeatmapSettings';
import { PartialIntervalManager } from '../../../Commons/Heatmap/PartialIntervals/PartialIntervalManager';
import { type PartialInterval } from '../../../Commons/Heatmap/PartialIntervals/PartialInterval';
import { HeatmapLogoProvider } from '../../panels/Heatmap/HeatmapLogoProvider';
import { type HeatmapData } from '../../../Commons/Heatmap/Models/HeatmapData';
import { EventEmitter } from 'events';
import { ThemeManager } from '../../misc/ThemeManager';
import { SolidBrush } from '../../../Commons/Graphics';
import { Resources } from '../../../Localizations/Resources';
import { type Instrument } from '../../../Commons/cache/Instrument';

export class HeatMapDrawer implements ICanvasDrawer {
    private readonly _eventEmitter = new EventEmitter();

    public width: number = 0;
    public height: number = 0;

    private readonly _sectorRenderers: HeatmapSectorRenderer[] = [];
    private readonly _industryRenderers: HeatmapIndustryRenderer[] = [];
    private readonly _symbolRenderers: HeatmapSymbolRenderer[] = [];
    private readonly _mapContainer: MapContainer;
    private readonly _settings: IHeatmapSettings;
    private readonly _partialIntervalManager: PartialIntervalManager;

    private _mousePoint: Point = Point.Empty();
    private _hoveredSymbolRenderer: HeatmapSymbolRenderer;

    constructor () {
        this._settings = new HeatmapSettings();
        this._partialIntervalManager = new PartialIntervalManager();
        this._mapContainer = new MapContainer(this._partialIntervalManager);
    }

    getStepMultiplier (): number {
        return this._partialIntervalManager.getStepMultiplier();
    }

    setStepMultiplier (stepMultiplier: number): void {
        this._partialIntervalManager.setStepMultiplier(stepMultiplier);
    }

    populateIntervals (): void {
        this._partialIntervalManager.populateIntervals();
    }

    getIntervals (): PartialInterval[] {
        return this._partialIntervalManager.getIntervals();
    }

    public applySettings (): void {
        const viewSettings = this._settings.viewSettings;
        this._partialIntervalManager.applyColors(viewSettings.minColor, viewSettings.midColor, viewSettings.maxColor);
        this.updateMap();
    }

    public getSettings (): IHeatmapSettings {
        return this._settings;
    }

    public populate (heatmapData: HeatmapData[]): void {
        HeatmapLogoProvider.loadLogos(heatmapData);
        this._mapContainer.Populate(heatmapData);
        this.removeRenderers();
        this.createRenderers();
        this.updateMap();
    }

    public getData (): HeatmapData[] {
        return this._mapContainer.getData();
    }

    public Draw (ctx: CanvasRenderingContext2D): void {
        ctx.fillStyle = ThemeManager.CurrentTheme.Chart_BottomColor;
        ctx.fillRect(0, 0, this.width, this.height);

        if (this.width === 0 || this.height === 0) {
            return;
        }

        if (this._mapContainer.isEmpty || !this._mapContainer.isVisible) {
            this.drawNoData(ctx);
            return;
        }

        for (const renderer of this._sectorRenderers) {
            renderer.draw(ctx, this._mousePoint);
        }

        for (const renderer of this._industryRenderers) {
            renderer.draw(ctx, this._mousePoint);
        }

        for (const renderer of this._symbolRenderers) {
            renderer.draw(ctx, this._mousePoint);
        }

        for (const renderer of this._industryRenderers) {
            renderer.drawSelection(ctx, this._mousePoint);
        }

        for (const renderer of this._sectorRenderers) {
            renderer.drawSelection(ctx, this._mousePoint);
        }
    }

    private drawNoData (ctx: CanvasRenderingContext2D): void {
        let noDataText = '';
        if (this._mapContainer.isEmpty) {
            noDataText = Resources.getResource('general.NoData...');
        } else if (!this._mapContainer.isVisible) {
            noDataText = Resources.getResource('general.NoDataMatchingYourCriteria');
        }

        if (!isValidString(noDataText)) {
            return;
        }

        const foregroundBrush = new SolidBrush(ThemeManager.CurrentTheme.ContentColor);
        const font = ThemeManager.Fonts.Font_10F_regular;
        const r = new Rectangle(0, 0, this.width, this.height);

        const textSize = ctx.GetTextSize(noDataText, font, false);
        const locationImg = (r.Width - textSize.width - 42) / 2;
        if (locationImg > 0) {
            const img = ThemeManager.CurrentTheme.messageboxIconInfoImage;
            if (!isNullOrUndefined(img)) {
                ctx.drawImage(img, locationImg, r.Height / 2 - 16);
            }
            ctx.DrawString(noDataText, font, foregroundBrush, locationImg + 42, r.Height / 2 - font.Height / 2, 'left', 'top');
        } else {
            ctx.DrawString(noDataText, font, foregroundBrush, r.X, r.Y, 'left', 'top');
        }
    }

    private createRenderers (): void {
        for (const sector of this._mapContainer.getSectorMapList()) {
            this._sectorRenderers.push(new HeatmapSectorRenderer(sector, this._settings.viewSettings));
            for (const industyMap of sector.items) {
                this._industryRenderers.push(new HeatmapIndustryRenderer(industyMap, this._settings.viewSettings));
                for (const symbolMap of industyMap.items) {
                    this._symbolRenderers.push(new HeatmapSymbolRenderer(symbolMap, this._settings.viewSettings));
                }
            }
        }
    }

    private removeRenderers (): void {
        this._sectorRenderers.length = 0;
        this._industryRenderers.length = 0;
        this._symbolRenderers.length = 0;
    }

    private processSelectSector (): boolean {
        const hoveredSector = this.getHoveredSector();
        if (isNullOrUndefined(hoveredSector) || hoveredSector.isSelected || !isNullOrUndefined(hoveredSector.selectedIndustry)) {
            return false;
        }
        this._mapContainer.selectSector(hoveredSector);
        return true;
    }

    private processActionSector (): boolean {
        const hoveredSector = this.getHoveredSector();
        if (isNullOrUndefined(hoveredSector) || (!hoveredSector.isSelected && isNullOrUndefined(hoveredSector.selectedIndustry))) {
            return false;
        }
        for (const sectorRenderer of this._sectorRenderers) {
            if (sectorRenderer.isAllHovered(this._mousePoint)) {
                this._mapContainer.clearSelection();
                return true;
            } else if (sectorRenderer.isSectorNameHovered(this._mousePoint)) {
                this._mapContainer.clearSelection();
                this._mapContainer.selectSector(hoveredSector);
                return true;
            }
        }

        return false;
    }

    private processSelectIndustry (): boolean {
        const hoveredIndustry = this.getHoveredIndustry();
        if (isNullOrUndefined(hoveredIndustry) || hoveredIndustry.isSelected) {
            return false;
        }
        this._mapContainer.selectIndustry(hoveredIndustry);
        return true;
    }

    private processSelectInstrument (): boolean {
        const hoveredInstrument = this.getHoveredInstrument();
        if (!isNullOrUndefined(hoveredInstrument)) {
            this._eventEmitter.emit('onSymbolClicked', hoveredInstrument);
            return true;
        }
        return true;
    }

    private getHoveredSector (): SectorMap {
        const sectors = this._mapContainer.getSectorMapList();
        for (const sector of sectors) {
            if (!sector.Visible) {
                continue;
            }
            if (sector.headerRectangle.Contains(this._mousePoint.X, this._mousePoint.Y)) {
                return sector;
            }
        }
        return undefined;
    }

    private getHoveredIndustry (): IndustryMap {
        const industries = this._mapContainer.getIndustryMapList();
        for (const industry of industries) {
            if (!industry.Visible) {
                continue;
            }
            if (industry.headerRectangle.Contains(this._mousePoint.X, this._mousePoint.Y)) {
                return industry;
            }
        }
        return undefined;
    }

    public getHoveredInstrument (): Instrument {
        if (!isNullOrUndefined(this._hoveredSymbolRenderer)) {
            const instrument = this._hoveredSymbolRenderer.getData()?.Instrument;
            if (!isNullOrUndefined(instrument)) {
                return instrument;
            }
        }
        return undefined;
    }

    public ThemeChanged (): void {

    }

    public readonly onMouseDown = (event: MouseEvent): void => {

    };

    public readonly onMouseMove = (event: MouseEvent): void => {
        this._mousePoint = new Point(event.offsetX, event.offsetY);
        if (isNullOrUndefined(this._hoveredSymbolRenderer) || !this._hoveredSymbolRenderer.isHovered(this._mousePoint)) {
            for (let i = this._symbolRenderers.length - 1; i >= 0; i--) {
                if (this._symbolRenderers[i].isHovered(this._mousePoint)) {
                    this._hoveredSymbolRenderer = this._symbolRenderers[i];
                    const data = !isNullOrUndefined(this._hoveredSymbolRenderer) ? this._hoveredSymbolRenderer.getData() : null;
                    this._eventEmitter.emit('onHoveredSymbolChanged', data);
                    break;
                }
            }
        }
    };

    public readonly onMouseUp = (event: MouseEvent): void => {

    };

    public readonly onMouseLeave = (event: MouseEvent): void => {
        this._mousePoint = Point.Empty();
    };

    public readonly onClick = (event: MouseEvent): void => {
        const isUpdateMap = this.processSelectSector() || this.processSelectIndustry() || this.processActionSector();
        if (isUpdateMap) {
            this.updateMap();
        } else {
            this.processSelectInstrument();
        }
    };

    public readonly onSizeChanged = (width: number, height: number): void => {
        this.width = Math.round(width);
        this.height = Math.round(height);
        this.updateMap();
    };

    public updateMap (): void {
        if (isNullOrUndefined(this._mapContainer)) {
            return;
        }
        this._mapContainer.updateMap(this.width, this.height);
        if (!isNullOrUndefined(this._hoveredSymbolRenderer)) {
            this._eventEmitter.emit('onHoveredSymbolChanged', this._hoveredSymbolRenderer.getData());
        }
    }

    public subscribeOnHoveredSymbolChanged (callback: (data: HeatmapData) => void): void {
        this._eventEmitter.on('onHoveredSymbolChanged', callback);
    }

    public subscribeOnSymbolClicked (callback: (instrument: Instrument) => void): void {
        this._eventEmitter.on('onSymbolClicked', callback);
    }

    public unsubscribeOnHoveredSymbolChanged (callback: (data: HeatmapData) => void): void {
        this._eventEmitter.off('onHoveredSymbolChanged', callback);
    }

    public unsubscribeOnSymbolClicked (callback: (instrument: Instrument) => void): void {
        this._eventEmitter.off('onSymbolClicked', callback);
    }
}
