// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectExternalLinkMessage } from '@shared/utils/DirectMessages/DirectExternalLinkMessage';
import { type BrandingToolParams } from './Branding/BrandingToolParams';
import { BaseExtLink } from './BaseExtLink';

export class WebContainerExtLink extends BaseExtLink {
    public externalLinkWebconDark?: string;
    public externalLinkWebconLight?: string;

    public update (brandingTool: BrandingToolParams | null, extLinkMessage: DirectExternalLinkMessage): void {
        super.update(brandingTool, extLinkMessage);

        const changed = this.externalLinkWebconDark !== extLinkMessage.ExternalLinkWebContentDark ||
                        this.externalLinkWebconLight !== extLinkMessage.ExternalLinkWebContentLight;

        this.externalLinkWebconDark = extLinkMessage.ExternalLinkWebContentDark;
        this.externalLinkWebconLight = extLinkMessage.ExternalLinkWebContentLight;

        if (changed && this.updateValue) {
            this.updateValue(this);
        }
    }

    public getExternalLinkWebContent (isDark: boolean): string {
        if (isDark) {
            if (!isNullOrUndefined(this.externalLinkWebconDark)) {
                return this.externalLinkWebconDark;
            } else {
                return this.externalLinkWebconLight;
            }
        } else {
            if (!isNullOrUndefined(this.externalLinkWebconLight)) {
                return this.externalLinkWebconLight;
            } else {
                return this.externalLinkWebconDark;
            }
        }
    }
}
