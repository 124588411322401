// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectExternalLinkMessage } from '../../../Utils/DirectMessages/DirectExternalLinkMessage';
import { DataCache } from '../../DataCache';
import { Resources } from '../../../Localizations/Resources';
import { BrandingToolLinkGenerator } from './Branding/BrandingToolLinkGenerator';
import { type BrandingToolParams } from './Branding/BrandingToolParams';
import { BaseExtLink } from './BaseExtLink';
import { ResourcesManager } from '../../properties/ResourcesManager';

export class AutochartistExtLink extends BaseExtLink {
    public ssoDNS: string = '';
    public ssoEndPoint: string = '';
    public brokerID: string = '';
    public secretKey: string = '';
    public accountType: string = '';

    update (brandingTool?: BrandingToolParams, extLinkMessage?: DirectExternalLinkMessage): void {
        super.update(brandingTool, extLinkMessage);
        if (brandingTool) {
            this.ssoDNS = brandingTool.ssoDNS ?? this.ssoDNS;
            this.ssoEndPoint = brandingTool.ssoEndPoint ?? this.ssoEndPoint;
            this.brokerID = brandingTool.brokerId ?? this.brokerID;
            this.secretKey = brandingTool.secretKey ?? this.secretKey;
            this.accountType = brandingTool.accountType ?? this.accountType;
        }
    }

    generateUrl (): void {
        const brandingTC = DataCache.ExternalLinksCache.getAutochartistExtLink();
        const userID = DataCache.getUserID();
        const autochartistUrl = BrandingToolLinkGenerator.generateAutochartistLink(
            brandingTC,
            userID ? `${userID}` : '',
            ResourcesManager.extLinkLocaleName()
        );

        if (autochartistUrl) {
            this.url = autochartistUrl;
            this.updateValue?.(autochartistUrl);
        }
    }
}
