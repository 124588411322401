// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TIF } from '../../Utils/Trading/OrderTif';
import { OrderTif } from '../../Utils/Trading/OrderTifEnum';
import { TerceraTIFComboBox } from './TerceraTIFComboBox';

export class QuickTableTifComboBox extends TerceraTIFComboBox {
    public tag: any;
    public callBack: any;

    private readonly _initialTif: any;

    constructor (initProps, data?, callback?, rect?) {
        super();
        const rowData = data.cell.QuickTableEditingInfo.GetDataHandler(data.row);
        const items = [];
        for (let i = 0; i < rowData.items.length; i++) {
            items.push({ text: rowData.items[i].text, value: rowData.items[i].value });
        }

        const selectedTif = rowData.selectedItem;
        this._initialTif = selectedTif;
        void super.set({ items });
        super.setItembyValue(selectedTif.type);
        void super.set({ gtdDate: selectedTif.expirationTime });
        this.tag = data;
        this.callBack = callback;
        super.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
        void super.set('isAllowEmpty', true);
        void super.set('isPosAbsolute', true);
    }

    public override oncomplete (): void {
        super.oncomplete();
        super.setFocus();
        super.MenuShow();
    }

    public override private_OnMenuItemSelected (menuItem): void {
        super.private_OnMenuItemSelected(menuItem);
        const selectedItem = this.get('selectedItem');
        if (!selectedItem || selectedItem.value !== OrderTif.GTD) {
            this.lostFocus();
        }
    }

    public override onGTDDateChangedFromDropDown (date): void {
        super.onGTDDateChangedFromDropDown(date);
        this.lostFocus();
    }

    public override lostFocus (): void {
        super.lostFocus();
        const selectedItem = this.get('selectedItem');
        const gtdDate = this.get('gtdDate');
        if (isNullOrUndefined(selectedItem) ||
        (selectedItem.value === this._initialTif.type && gtdDate === this._initialTif.expirationTime) ||
        (selectedItem.value === OrderTif.GTD && !gtdDate)) {
            this.callBack(null, this.tag);
        } else {
            this.callBack(new TIF(selectedItem.value, gtdDate), this.tag);
        }
    }
}

TerceraTIFComboBox.extendWith(QuickTableTifComboBox, { });
