// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum } from '../../../../Utils/Enums/AccountDetailsEnum';
import { BaseGroupItem } from '../BaseGroupItem';

export class AccountDetailsGroupItem extends BaseGroupItem<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum> {
    protected override getGroupNameKey (): string | null {
        switch (this.type) {
        case AccountDetailsGroupsEnum.General:
            return 'panel.accountDetails.Groups.1.General';
        case AccountDetailsGroupsEnum.Margin:
            return 'panel.accountDetails.Groups.2.Margin';
        case AccountDetailsGroupsEnum.AccountActivity:
            return 'panel.accountDetails.Groups.3.AccountActivity';
        case AccountDetailsGroupsEnum.TodayResult:
            return 'panel.accountDetails.Groups.4.TodayResults';
        case AccountDetailsGroupsEnum.RiskManagement:
            return 'panel.accountDetails.Groups.5.RiskManagement';
        case AccountDetailsGroupsEnum.Info:
            return 'panel.accountDetails.Groups.6.Info';
        default:
            return null;
        }
    }
}
