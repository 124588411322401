// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TimeSpan } from '@shared/utils/Time/TimeSpan';
import { Color } from '../../../Graphics';
import { PriceType } from '@shared/utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { ErrorInformationStorage } from '../../../ErrorInformationStorage';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { HistoricalDataRequest } from '../HistoricalDataRequest';
import { Period } from '../IndicatorEnums';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterBoolean } from '../InputParamaterClasses/InputParameterBoolean';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class PivotPoint extends IndicatorScriptBase {
    public ExpirationDate: number;
    public OnlyCurrentPeriod: boolean;
    public BasePeriod: any;
    public PeriodValue: number;
    public isLoaded: boolean;
    public LoadedHistory: any;
    public allowDataRange: boolean;
    public prevLoadedHistoryCount: number;
    public prevPeriodIntervalsCount: number;
    public IndicatorCalculationMethod: any;

    constructor () {
        super();
        this.Comments = '';
        this.Company = 'TraderEvolution';
        this.DateOfCreation = '16.11.2015';
        this.ExpirationDate = 0;
        this.Version = '1.1';
        this.ProjectName = 'Pivot point';

        this.SetIndicatorLine('PP', Color.Black, 1, LineStyle.HorizontalLine);
        this.SetIndicatorLine('R1', Color.Red, 1, LineStyle.HorizontalLine);
        this.SetIndicatorLine('R2', Color.Red, 1, LineStyle.HorizontalLine);
        this.SetIndicatorLine('R3', Color.Red, 1, LineStyle.HorizontalLine);
        this.SetIndicatorLine('S1', Color.Blue, 1, LineStyle.HorizontalLine);
        this.SetIndicatorLine('S2', Color.Blue, 1, LineStyle.HorizontalLine);
        this.SetIndicatorLine('S3', Color.Blue, 1, LineStyle.HorizontalLine);

        this.SeparateWindow = true;

        this.OnlyCurrentPeriod = false;
        super.InputParameter(new InputParameterBoolean('OnlyCurrentPeriod', 'Only current period', 0));

        this.BasePeriod = Period.Day;
        super.InputParameter(new InputParameterCombobox('BasePeriod', 'Base period', 1,
            [
                { Hour: Period.Hour },
                { Day: Period.Day },
                { Week: Period.Week },
                { Month: Period.Month }
            ]));

        this.PeriodValue = 1;
        super.InputParameter(new InputParameterInteger('PeriodValue', 'Value', 2, 1, 60));

        this.IndicatorCalculationMethod = CalculationMethod.Classic;
        super.InputParameter(new InputParameterCombobox('IndicatorCalculationMethod', 'Method', 3,
            [
                { Classic: CalculationMethod.Classic },
                { Camarilla: CalculationMethod.Camarilla },
                { Fibonacci: CalculationMethod.Fibonacci },
                { Woodie: CalculationMethod.Woodie },
                { DeMark: CalculationMethod.DeMark }
            ]));

        this.isLoaded = false;
        this.LoadedHistory = null;
        this.allowDataRange = false;
        this.prevLoadedHistoryCount = 0;
        this.prevPeriodIntervalsCount = 1;
    }

    public async RefreshPromise (): Promise<any> {
        await super.RefreshPromise()
            .then(async () => {
                const cd = this.CurrentData;
                return await this.GetHistoricalDataPromise(new HistoricalDataRequest({
                    Instrument: cd.Instrument,
                    Period: this.BasePeriod,
                    DataType: cd.DataType,
                    fromUTC: new Date(cd.FirstBarOpenTime),
                    toUTC: new Date(DateTimeUtils.DateTimeUtcNow().getTime() + TimeSpan.FromDays(1).getTime()),
                    PeriodValue: this.PeriodValue
                }));
            })
            .then((LoadedHistory) => {
                this.LoadedHistory = LoadedHistory;
                this.LoadedHistory.GetCashItemCount = this.LoadedHistory.BarsCountAny;
                // TODO. Rename.
                this.isLoaded = false;
            });
    };

    public NextBar (): void {
        super.NextBar();

        const LoadedHistory = this.LoadedHistory;

        if (!this.isLoaded) {
            this.isLoaded = true;
            this.prevLoadedHistoryCount = LoadedHistory.HistoryCount;
            this.allowDataRange = this.AllowIndicatorDataRange();
        }

        if (this.OnlyCurrentPeriod && !this.allowDataRange) { return; };

        this.CalculateValues();
    };

    public CalculateValues (): void {
        const LoadedHistory = this.LoadedHistory;
        const CurrentData = this.CurrentData;
        const OnlyCurrentPeriod = this.OnlyCurrentPeriod;

        let interval = LoadedHistory.FindInterval(CurrentData.Time());
        if (interval === -1) {
            if (OnlyCurrentPeriod) {
                if (LoadedHistory.HistoryCount >= this.prevLoadedHistoryCount) {
                    this.prevLoadedHistoryCount = LoadedHistory.HistoryCount;
                    this.RemovePrevPeriodLines();
                }
            }
            interval = LoadedHistory.HistoryCount;
        }

        const offset = LoadedHistory.HistoryCount - interval;
        if (offset >= LoadedHistory.HistoryCount || OnlyCurrentPeriod && offset > 1) { return; };

        if (OnlyCurrentPeriod) { this.prevPeriodIntervalsCount++; };

        const close = LoadedHistory.GetPrice(PriceType.Close, offset);
        const high = LoadedHistory.GetPrice(PriceType.High, offset);
        const low = LoadedHistory.GetPrice(PriceType.Low, offset);

        let pp = 0; let r1 = 0; let r2 = 0; let r3 = 0; let s1 = 0; let s2 = 0; let s3 = 0;

        switch (this.IndicatorCalculationMethod) {
        case CalculationMethod.Classic:
            pp = (high + low + close) / 3;
            r1 = 2 * pp - low;
            r2 = pp + high - low;
            r3 = 2 * pp + high - 2 * low;
            s1 = 2 * pp - high;
            s2 = pp + low - high;
            s3 = 2 * pp + low - 2 * high;
            break;

        case CalculationMethod.Camarilla:
            pp = (high + low + close) / 3;
            r1 = close + 0.0916 * (high - low);
            r2 = close + 0.183 * (high - low);
            r3 = close + 0.275 * (high - low);
            s1 = close - 0.0916 * (high - low);
            s2 = close - 0.183 * (high - low);
            s3 = close - 0.275 * (high - low);
            break;

        case CalculationMethod.Fibonacci:
            pp = (high + low + close) / 3;
            r1 = pp + 0.382 * (high - low);
            r2 = pp + 0.618 * (high - low);
            r3 = pp + (high - low);
            s1 = pp - 0.382 * (high - low);
            s2 = pp - 0.618 * (high - low);
            s3 = pp - (high - low);
            break;

        case CalculationMethod.Woodie:
            pp = (high + low + 2 * close) / 4;
            r1 = 2 * pp - low;
            r2 = pp + high - low;
            r3 = high + 2 * (pp - low);
            s1 = 2 * pp - high;
            s2 = pp + low - high;
            s3 = low - 2 * (high - pp);
            break;

        case CalculationMethod.DeMark:
            const open0 = offset === 0
                ? CurrentData.GetPrice(PriceType.Open, 0)
                : LoadedHistory.GetPrice(PriceType.Open, offset - 1);

            let x = 0;

            if (close < open0) { x = high + 2 * low + close; } else if (close > open0) { x = 2 * high + low + close; } else { x = high + low + 2 * close; }

            pp = x / 4;
            r1 = x / 2 - low;
            s1 = x / 2 - high;
            break;
        }

        const index = 0;

        this.SetValue(0, index, pp);
        this.SetValue(1, index, r1);
        this.SetValue(4, index, s1);

        if (this.IndicatorCalculationMethod !== CalculationMethod.DeMark) {
            this.SetValue(2, index, r2);
            this.SetValue(3, index, r3);

            this.SetValue(5, index, s2);
            this.SetValue(6, index, s3);
        }
    };

    public RemovePrevPeriodLines (): void {
        for (let i = this.prevPeriodIntervalsCount; i > 0; i--) {
            try {
                this.SetValue(0, i, Number.MAX_VALUE);
                this.SetValue(1, i, Number.MAX_VALUE);
                this.SetValue(4, i, Number.MAX_VALUE);

                if (this.IndicatorCalculationMethod !== CalculationMethod.DeMark) {
                    this.SetValue(2, i, Number.MAX_VALUE);
                    this.SetValue(3, i, Number.MAX_VALUE);

                    this.SetValue(5, i, Number.MAX_VALUE);
                    this.SetValue(6, i, Number.MAX_VALUE);
                }
            } catch (ex) {
                ErrorInformationStorage.GetException(ex);
            }
        }
        this.prevPeriodIntervalsCount = 0;
    };

    public AllowIndicatorDataRange (): boolean {
        const LoadedHistory = this.LoadedHistory;
        const CurrentData = this.CurrentData;
        const PeriodValue = this.PeriodValue;

        const lastTime = LoadedHistory.Count > 0 ? LoadedHistory.Time().getTime() : 0;
        const chartTimeSpan: number = lastTime - CurrentData.Time(CurrentData.Count - 1).getTime();
        let indicatorBasePeriod = new Date();

        switch (this.BasePeriod) {
        case Period.Hour:
            indicatorBasePeriod = TimeSpan.FromHours(PeriodValue);
            break;

        case Period.Day:
            indicatorBasePeriod = TimeSpan.FromDays(PeriodValue);
            break;

        case Period.Week:
            indicatorBasePeriod = TimeSpan.FromDays(PeriodValue * 7);
            break;

        case Period.Month:
            indicatorBasePeriod = TimeSpan.FromDays(PeriodValue * 30);
            break;
        }

        return chartTimeSpan >= indicatorBasePeriod.getTime();
    };

    public override GetIndicatorShortName (): string {
        return 'Pivot Point';
    };

    public override getCustomName (): string {
        return 'Pivot Point (' + this.ProjectName + ')';
    };
}

enum CalculationMethod {
    Classic = 0,
    Camarilla = 1,
    Fibonacci = 2,
    Woodie = 3,
    DeMark = 4
}
