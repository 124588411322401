// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { TerceraChartCashItemSeriesCacheScreenDataDateChangeType } from '../Series/TerceraChartCashItemSeriesEnums';
import { DataCacheToolType } from '@shared/commons/cache/DataCacheToolEnums';
import { ModelDataType, ChartLoadTrigger, TerceraChartMVCCommand } from '../TerceraChartMVC';
import { TerceraChartUtils } from '../TerceraChartUtils';
import { PanelNames } from '@front/controls/UtilsClasses/FactoryConstants';
import { ChartDataType, TerceraChartTradingOperation } from '../Utils/ChartConstants';
import { TerceraChartDayHighLowRenderer } from '../Renderers/TerceraChartDayHighLowRenderer';
import { TerceraChartTimeToNextBarRenderer } from '../Renderers/TerceraChartTimeToNextBarRenderer';
import { TerceraChartNewToolRenderer } from '../Renderers/Tools/TerceraChartNewToolRenderer';
import { TerceraChartToolRenderer } from '../Renderers/Tools/TerceraChartToolRenderer';
import { TradeAnalysisType } from '@shared/utils/History/TFInfo';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { DynProperty } from '@shared/commons/DynProperty';
import { IndicatorManager } from '@shared/commons/cache/indicators/IndicatorManager';
import { TradingLockUtils } from '@shared/utils/TradingLockUtils';
import { IsAllowed } from '@shared/commons/IsAllowed';
import { TerceraChartActionEnum, TerceraChartAction, TerceraChartToolbarsEnum, CancelActionContext } from '../TerceraChartAction';
import { TerceraChartActionState } from '../TerceraChartActionState';
import { DataCache } from '@shared/commons/DataCache';
import { CustomerAccess } from '@shared/commons/CustomerAccess/CustomerAccess';
import { ITerceraChartShowScreenType } from '../ITerceraChartShowScreenType';
import { TerceraChartInfoWindowRenderer } from '../Renderers/TerceraChartInfoWindowRenderer';
import { addOverlayScreenHandler, propertySetupScreenForIndicatorHandler } from '@shared/utils/AppHandlers';
import { Position } from '@shared/commons/cache/Position';
import { type TerceraChart } from '../TerceraChart';
import { TerceraChartBaseActionProcessor } from './TerceraChartBaseActionProcessor';
import { InfoWindowDockingType } from '../Renderers/InfoWindow/InfoWindowDockingType';
import { InfoWindowDisplayMode } from '../Renderers/InfoWindow/InfoWindowDisplayMode';
import { ObjectManagerScreen } from '@front/controls/screen/ObjectManagerScreen';
import { type DataCacheTool } from '@shared/commons/cache/DataCacheTool';
import { type ToolView } from '../Tools/ToolView';
import { EnsureVisibleTypeEnum } from '../Utils/EnsureVisibleTypeEnum';

export class TerceraChartActionProcessor extends TerceraChartBaseActionProcessor<TerceraChart> {
    protected override initializeActions (): void {
        super.initializeActions();
        this.actionsProcessor[TerceraChartActionEnum.SymbolsLookup] = this.processSymbolsLookupAction;
        this.actionsProcessor[TerceraChartActionEnum.Eraser] = this.processEraserAction;
        this.actionsProcessor[TerceraChartActionEnum.ToolSelector] = this.processToolSelectorAction;
        this.actionsProcessor[TerceraChartActionEnum.CreationTool] = this.processCreationToolAction;
        this.actionsProcessor[TerceraChartActionEnum.PlateIndicatorSettings] = this.plateIndicatorSettingsAction;
        this.actionsProcessor[TerceraChartActionEnum.View] = this.processViewAction;
        this.actionsProcessor[TerceraChartActionEnum.SetStayInDrawingMode] = this.setStayInDrawingModeAction;

        this.actionsProcessor[TerceraChartActionEnum.InfoWindowHide] = this.processInfoWindowHideAction;
        this.actionsProcessor[TerceraChartActionEnum.InfoWindowMode] = this.processInfoWindowModeAction;
        this.actionsProcessor[TerceraChartActionEnum.InfoWindowDock] = this.processInfoWindowDockAction;
        this.actionsProcessor[TerceraChartActionEnum.InfoWindowShortMode] = this.processInfoWindowShortModeAction;
        this.actionsProcessor[TerceraChartActionEnum.InfoWindowSettings] = this.processInfoWindowSettingsAction;

        this.actionsProcessor[TerceraChartActionEnum.PlateOverlaySettings] = this.plateOverlaySettingsAction;

        this.actionsProcessor[TerceraChartActionEnum.HoverAndSelectedToolSettings] = this.processHoverAndSelectedToolSettingsAction;
        this.actionsProcessor[TerceraChartActionEnum.CloneTool] = this.processCloneToolAction;
        this.actionsProcessor[TerceraChartActionEnum.DeleteHoverAndSelectedTools] = this.processDeleteHoverAndSelectedToolsAction;

        this.actionsProcessor[TerceraChartActionEnum.Style] = this.processStyleAction;
        this.actionsProcessor[TerceraChartActionEnum.DataType] = this.processDataTypeAction;

        this.actionsProcessor[TerceraChartActionEnum.ClosePosition] = this.processClosePositionAction;
        this.actionsProcessor[TerceraChartActionEnum.CloseAllPosition] = this.processCloseAllPositionAction;
        this.actionsProcessor[TerceraChartActionEnum.ModifyPosition] = this.processModifyPositionAction;
        this.actionsProcessor[TerceraChartActionEnum.RollbackPosition] = this.processRollbackPositionAction;
        this.actionsProcessor[TerceraChartActionEnum.CancelOrders] = this.processCancelOrdersAction;
        this.actionsProcessor[TerceraChartActionEnum.CancelOrderID] = this.processCancelOrderIDAction;
        this.actionsProcessor[TerceraChartActionEnum.CancelOrdersAccount] = this.processCancelOrdersAccountAction;
        this.actionsProcessor[TerceraChartActionEnum.CancelOrdersSymbol] = this.processCancelOrdersSymbolAction;
        this.actionsProcessor[TerceraChartActionEnum.ModifyOrder] = this.processModifyOrderAction;
        this.actionsProcessor[TerceraChartActionEnum.ChangeToMarket] = this.processChangeToMarketAction;

        this.actionsProcessor[TerceraChartActionEnum.FitIndicators] = this.processFitIndicatorsAction;
        this.actionsProcessor[TerceraChartActionEnum.FitOrders] = this.processFitOrdersAction;
        this.actionsProcessor[TerceraChartActionEnum.FitDayHighLow] = this.processFitDayHighLowAction;
        this.actionsProcessor[TerceraChartActionEnum.FitDrawings] = this.processFitDrawingsAction;
        this.actionsProcessor[TerceraChartActionEnum.FitAlerts] = this.processFitAlertsAction;

        this.actionsProcessor[TerceraChartActionEnum.MainSymbol] = this.processMainSymbolAction;

        this.actionsProcessor[TerceraChartActionEnum.TimeSeparators] = this.processTimeSeparatorsAction;
        this.actionsProcessor[TerceraChartActionEnum.ShowHoles] = this.processShowHolesAction;
        this.actionsProcessor[TerceraChartActionEnum.ShowExtendedSession] = this.processShowExtendedSessionAction;

        this.actionsProcessor[TerceraChartActionEnum.Overlay] = this.processOverlayAction;
        this.actionsProcessor[TerceraChartActionEnum.Indicator] = this.processIndicatorAction;

        this.actionsProcessor[TerceraChartActionEnum.NewPanel] = this.processNewPanelAction;

        this.actionsProcessor[TerceraChartActionEnum.Refresh] = this.processRefreshAction;

        this.actionsProcessor[TerceraChartActionEnum.DeleteToolsByType] = this.processDeleteToolsByTypeAction;
        this.actionsProcessor[TerceraChartActionEnum.DeleteAllTools] = this.processDeleteAllToolsAction;

        this.actionsProcessor[TerceraChartActionEnum.ChartTrading] = this.processChartTradingAction;

        this.actionsProcessor[TerceraChartActionEnum.PlateIndicatorVisible] = this.processPlateIndicatorVisibleAction;
        this.actionsProcessor[TerceraChartActionEnum.PlateIndicatorDublicate] = this.processPlateIndicatorDublicateAction;
        this.actionsProcessor[TerceraChartActionEnum.PlateIndicatorRemove] = this.processPlateIndicatorRemoveAction;

        this.actionsProcessor[TerceraChartActionEnum.PlateOverlayVisible] = this.processPlateOverlayVisibleAction;
        this.actionsProcessor[TerceraChartActionEnum.PlateOverlayRemove] = this.processPlateOverlayRemoveAction;

        this.actionsProcessor[TerceraChartActionEnum.CancelOrderSLTP] = this.processCancelOrderSLTPAction;

        this.actionsProcessor[TerceraChartActionEnum.DrawingToolsGroup] = this.processDrawingToolsGroupAction;

        this.actionsProcessor[TerceraChartActionEnum.ToBegin] = this.processToBeginAction;

        this.actionsProcessor[TerceraChartActionEnum.ObjectManager] = this.processObjectManagerAction;

        this.actionsProcessor[TerceraChartActionEnum.ShowOnChart] = this.processShowOnChartAction;
        this.actionsProcessor[TerceraChartActionEnum.DeleteTool] = this.processDeleteToolAction;
        this.actionsProcessor[TerceraChartActionEnum.ToolSettings] = this.processToolSettingsAction;
    }

    protected override initializeStates (): void {
        super.initializeStates();
        this.statesProcessor[TerceraChartActionEnum.SymbolsLookup] = this.processSymbolsLookupState;
        this.statesProcessor[TerceraChartActionEnum.Eraser] = this.processEraserState;
        this.statesProcessor[TerceraChartActionEnum.ToolSelector] = this.processToolSelectorState;
        this.statesProcessor[TerceraChartActionEnum.CreationTool] = this.processCreationToolState;
        this.statesProcessor[TerceraChartActionEnum.PlateIndicatorSettings] = this.plateIndicatorSettingsState;
        this.statesProcessor[TerceraChartActionEnum.View] = this.processViewState;
        this.statesProcessor[TerceraChartActionEnum.PriceIndicator] = this.priceIndicatorState;
        this.statesProcessor[TerceraChartActionEnum.SetStayInDrawingMode] = this.setStayInDrawingModeState;
        this.statesProcessor[TerceraChartActionEnum.DeleteSelectedTools] = this.deleteSelectedToolsState;
        this.statesProcessor[TerceraChartActionEnum.SelectedToolSettings] = this.selectedToolSettingsState;
        this.statesProcessor[TerceraChartActionEnum.ToolColor] = this.toolColorState;
        this.statesProcessor[TerceraChartActionEnum.ToolLineWidth] = this.toolLineWidthState;

        this.statesProcessor[TerceraChartActionEnum.InfoWindowHide] = this.processInfoWindowHideState;
        this.statesProcessor[TerceraChartActionEnum.InfoWindowMode] = this.processInfoWindowModeState;
        this.statesProcessor[TerceraChartActionEnum.InfoWindowDock] = this.processInfoWindowDockState;
        this.statesProcessor[TerceraChartActionEnum.InfoWindowShortMode] = this.processInfoWindowShortModeState;
        this.statesProcessor[TerceraChartActionEnum.InfoWindowSettings] = this.processInfoWindowSettingsState;

        this.statesProcessor[TerceraChartActionEnum.PlateOverlaySettings] = this.plateOverlaySettingsState;

        this.statesProcessor[TerceraChartActionEnum.HoverAndSelectedToolSettings] = this.processHoverAndSelectedToolSettingsState;
        this.statesProcessor[TerceraChartActionEnum.CloneTool] = this.processCloneToolState;
        this.statesProcessor[TerceraChartActionEnum.DeleteHoverAndSelectedTools] = this.processDeleteHoverAndSelectedToolsState;

        this.statesProcessor[TerceraChartActionEnum.Style] = this.processStyleState;
        this.statesProcessor[TerceraChartActionEnum.DataType] = this.processDataTypeState;

        this.statesProcessor[TerceraChartActionEnum.ClosePosition] = this.processClosePositionState;
        this.statesProcessor[TerceraChartActionEnum.CloseAllPosition] = this.processCloseAllPositionState;
        this.statesProcessor[TerceraChartActionEnum.ModifyPosition] = this.processModifyPositionState;
        this.statesProcessor[TerceraChartActionEnum.RollbackPosition] = this.processRollbackPositionState;
        this.statesProcessor[TerceraChartActionEnum.CancelOrders] = this.processCancelOrdersState;
        this.statesProcessor[TerceraChartActionEnum.CancelOrderID] = this.processCancelOrderIDState;
        this.statesProcessor[TerceraChartActionEnum.CancelOrdersAccount] = this.processCancelOrdersAccountState;
        this.statesProcessor[TerceraChartActionEnum.CancelOrdersSymbol] = this.processCancelOrdersSymbolState;
        this.statesProcessor[TerceraChartActionEnum.ModifyOrder] = this.processModifyOrderState;
        this.statesProcessor[TerceraChartActionEnum.ChangeToMarket] = this.processChangeToMarketState;

        this.statesProcessor[TerceraChartActionEnum.FitIndicators] = this.processFitIndicatorsState;
        this.statesProcessor[TerceraChartActionEnum.FitOrders] = this.processFitOrdersState;
        this.statesProcessor[TerceraChartActionEnum.FitDayHighLow] = this.processFitDayHighLowState;
        this.statesProcessor[TerceraChartActionEnum.FitDrawings] = this.processFitDrawingsState;
        this.statesProcessor[TerceraChartActionEnum.FitAlerts] = this.processFitAlertsState;

        this.statesProcessor[TerceraChartActionEnum.MainSymbol] = this.processMainSymbolState;

        this.statesProcessor[TerceraChartActionEnum.TimeSeparators] = this.processTimeSeparatorsState;
        this.statesProcessor[TerceraChartActionEnum.ShowHoles] = this.processShowHolesState;
        this.statesProcessor[TerceraChartActionEnum.ShowExtendedSession] = this.processShowExtendedSessionState;

        this.statesProcessor[TerceraChartActionEnum.Overlay] = this.processOverlayState;
        this.statesProcessor[TerceraChartActionEnum.Indicator] = this.processIndicatorState;

        this.statesProcessor[TerceraChartActionEnum.NewPanel] = this.processNewPanelState;

        this.statesProcessor[TerceraChartActionEnum.Refresh] = this.processRefreshState;

        this.statesProcessor[TerceraChartActionEnum.DeleteToolsByType] = this.processDeleteToolsByTypeState;
        this.statesProcessor[TerceraChartActionEnum.DeleteAllTools] = this.processDeleteAllToolsState;

        this.statesProcessor[TerceraChartActionEnum.ChartTrading] = this.processChartTradingState;

        this.statesProcessor[TerceraChartActionEnum.PlateIndicatorVisible] = this.processPlateIndicatorVisibleState;
        this.statesProcessor[TerceraChartActionEnum.PlateIndicatorDublicate] = this.processPlateIndicatorDublicateState;
        this.statesProcessor[TerceraChartActionEnum.PlateIndicatorRemove] = this.processPlateIndicatorRemoveState;

        this.statesProcessor[TerceraChartActionEnum.PlateOverlayVisible] = this.processPlateOverlayVisibleState;
        this.statesProcessor[TerceraChartActionEnum.PlateOverlayRemove] = this.processPlateOverlayRemoveState;

        this.statesProcessor[TerceraChartActionEnum.CancelOrderSLTP] = this.processCancelOrderSLTPState;

        this.statesProcessor[TerceraChartActionEnum.ToBegin] = this.processToBeginState;
        this.statesProcessor[TerceraChartActionEnum.ObjectManager] = this.processObjectManagerState;
    }

    public processSymbolsLookupAction (): boolean {
        let processed = false;
        const chart = this.chart;
        if (!isNullOrUndefined(chart)) {
            const res = chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.InstrumentLookup, null);
            processed = !!res;
        }
        return processed;
    }

    public processEraserAction (action, active): boolean {
        let processed = false;
        if (this.chart?.mainWindow && this.chart.EraserRenderer) {
            const eraserRenderer = this.chart.EraserRenderer;
            const newValue = active !== null ? active : !eraserRenderer.Active;
            processed = eraserRenderer.Active !== newValue;
            eraserRenderer.Active = newValue;
        }
        return processed;
    }

    public processToolSelectorAction (action, active): boolean {
        let processed = false;

        if (this.chart?.mainWindow && this.chart.SelectionRenderer) {
            const selectionRenderer = this.chart.SelectionRenderer;
            const newValue = active !== null ? active : !selectionRenderer.Active;
            processed = selectionRenderer.Active !== newValue;
            selectionRenderer.Active = newValue;
        }

        return processed;
    }

    public processCreationToolAction (action: TerceraChartAction): boolean {
        const processed = false;

        const toolType = action.Details;
        if (this.chart) {
            this.chart.NewToolStrategy.Reset(toolType);
        }

        return processed;
    }

    public plateIndicatorSettingsAction (action: TerceraChartAction): boolean {
        const processed = false;
        const header = `${action.Details.Indicator.Script.ProjectName} ${Resources.getResource('screen.properties.common')}`;
        void propertySetupScreenForIndicatorHandler.editProperty(action.Details, action.Details.editPropertiesCallBackOnPlacedIndicator, header);

        return processed;
    }

    public processViewAction (action: TerceraChartAction): boolean {
        const processed = false;

        const details = action.Details;
        const chart = this.chart;

        if (details === undefined || details === null || !chart) {
            return;
        }

        const wc = chart.windowsContainer;

        switch (details) {
        case TerceraChartToolbarsEnum.ScrollBar:
            wc.scrollerRenderer.Visible = !wc.scrollerRenderer.Visible;
            wc.LayoutWindows();
            break;
        case TerceraChartToolbarsEnum.OrderEntry:
        case TerceraChartToolbarsEnum.AnalyseToolBar:
        case TerceraChartToolbarsEnum.DrawingToolBar:
        case TerceraChartToolbarsEnum.MainToolBar:
            chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.UIManipulation, {
                isSet: true, toolBarType: details
            });
            break;
        case TerceraChartToolbarsEnum.PreviousCloseLine:
            if (typeof TerceraChartDayHighLowRenderer === 'function') {
                const renderer = chart.mainWindow.GetRenderer(TerceraChartDayHighLowRenderer);
                if (renderer) {
                    renderer.prevCloseVisible = !renderer.prevCloseVisible;
                }
            }
            break;
        case TerceraChartToolbarsEnum.TimeToNextBar:{
            const renderer = chart.mainWindow.GetRenderer(TerceraChartTimeToNextBarRenderer);
            renderer.Visible = !renderer.Visible;
            break;
        }
        case TerceraChartToolbarsEnum.DayHighLow:
            if (typeof TerceraChartDayHighLowRenderer === 'function') {
                const renderer = chart.mainWindow.GetRenderer(TerceraChartDayHighLowRenderer);
                if (renderer) {
                    renderer.Visible = !renderer.Visible;
                }
            }
            break;
        case TerceraChartToolbarsEnum.Volume:{
            const volumeBarsRenderer = chart.volumeBarsRenderer;
            volumeBarsRenderer.Visible = !volumeBarsRenderer.Visible;
            break;
        }
        case TerceraChartToolbarsEnum.GridTime:{
            const timeScaleRendererSettings = wc.xScaleRenderer.settings;
            timeScaleRendererSettings.ScaleGridVisibility = !timeScaleRendererSettings.ScaleGridVisibility;
            break;
        }
        case TerceraChartToolbarsEnum.GridPrice:{
            const yScaleRendererSettings = chart.yScaleRendererSettings;
            yScaleRendererSettings.ScaleGridVisibility = !yScaleRendererSettings.ScaleGridVisibility;
            break;
        }
        }

        chart.IsDirty();

        return processed;
    }

    public setStayInDrawingModeAction (): void {
        if (this.chart != null) {
            const r = this.chart.mainWindow.GetMainThreadRenderer('TerceraChartNewToolRenderer');
            if (r != null) {
                r.StayInDrawingMode = !r.StayInDrawingMode;
            }
        }
    }

    public processInfoWindowHideAction (action: TerceraChartAction): boolean {
        const processed = false;
        (action.Details as TerceraChartInfoWindowRenderer).displayMode = InfoWindowDisplayMode.Hidden;

        return processed;
    }

    public processInfoWindowModeAction (action: TerceraChartAction): boolean {
        const processed = false;

        const infoWindowRenderer = this.chart.mainWindow.GetRenderer(TerceraChartInfoWindowRenderer);
        if (isNullOrUndefined(infoWindowRenderer)) {
            return processed;
        }

        if (!isNullOrUndefined(action.Details)) {
            infoWindowRenderer.displayMode = action.Details;
        } else {
            if (infoWindowRenderer.displayMode === InfoWindowDisplayMode.AttachedToCursor) {
                infoWindowRenderer.displayMode = InfoWindowDisplayMode.SeparateWindow;
            } else {
                infoWindowRenderer.displayMode = InfoWindowDisplayMode.AttachedToCursor;
            }
        }

        return processed;
    }

    public processInfoWindowDockAction (action: TerceraChartAction): boolean {
        const processed = false;

        action.Details.DockingType = action.Details.DockingType === InfoWindowDockingType.None
            ? InfoWindowDockingType.Top
            : InfoWindowDockingType.None;

        return processed;
    }

    public processInfoWindowShortModeAction (action: TerceraChartAction): boolean {
        const processed = false;

        action.Details.ShortMode = !action.Details.ShortMode;

        return processed;
    }

    public processInfoWindowSettingsAction (): boolean {
        const processed = false;

        const parameters = { PanelSettingsDefaultPage: DynProperty.DATA_BOX_GROUP };

        this.chart.terceraChartPanelContext.ShowScreen(
            ITerceraChartShowScreenType.ChartSettings,
            parameters);

        return processed;
    }

    public plateOverlaySettingsAction (action: TerceraChartAction): void {
        addOverlayScreenHandler.editOverlay(this.chart, action.Details);
    }

    public processHoverAndSelectedToolSettingsAction (action: TerceraChartAction, active): boolean {
        const processed = false;

        let tool = action.Details;
        // If hovered(?) tool is null, then we try to get selected one.
        if (!tool) tool = this.chart.AllWindowsHoverAndSelectedTools[0];

        if (tool) {
            const parameters = { caller: tool };
            // parameters["Properties"] = tool.Properties();
            // parameters["caller"] = tool;
            // parameters["refreshHandler"] = new EmptyHandler(chart.RedrawBuffer);
            // parameters["allowResetToDefault"] = true;
            //
            this.chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.Properties, parameters);
        }

        return processed;
    }

    public processCloneToolAction (action: TerceraChartAction): boolean {
        const processed = false;

        const tool = action.Details;
        if (tool) {
            const windows = this.chart.windowsContainer.Windows;
            const len = windows.length;
            for (let i = 0; i < len; i++) {
                const window = windows[i];
                const toolRenderer = window.GetRenderer(TerceraChartToolRenderer);
                if (toolRenderer.IsMyTool(tool)) {
                    toolRenderer.CloneTool(tool, window);
                }
            }
        }

        return processed;
    }

    public processDeleteHoverAndSelectedToolsAction (): boolean {
        const processed = false;

        const windows = this.chart.windowsContainer.Windows;
        const len = windows.length;
        for (let i = 0; i < len; i++) {
            const toolRenderer = windows[i].GetRenderer(TerceraChartToolRenderer);
            toolRenderer.RemoveSelectedAndHoverTools();
        }

        return processed;
    }

    public processStyleAction (action: TerceraChartAction): boolean {
        const processed = false;

        this.chart.chartController.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.Style),
            action.Details);

        return processed;
    }

    public processDataTypeAction (action: TerceraChartAction): void {
        this.chart.chartController.ExecuteCommand(
            new TerceraChartMVCCommand(ModelDataType.HistoryType),
            action.Details.ChartDataType);
    }

    public processClosePositionAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.ClosePosition });
        return processed;
    }

    public processCloseAllPositionAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.CloseAllPosition });
        return processed;
    }

    public processModifyPositionAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.ModifyOrderPosition });
        return processed;
    }

    public processModifyOrderAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.ModifyOrderPosition });
        return processed;
    }

    public processRollbackPositionAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.RollbackPosition });
        return processed;
    }

    public processCancelOrdersAction (action: TerceraChartAction): boolean {
        const processed = false;
        return processed;
    }

    public processCancelOrderIDAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.CancelOrder });
        return processed;
    }

    public processCancelOrdersAccountAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.CancelOrdersAccount });
        return processed;
    }

    public processCancelOrdersSymbolAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.CancelOrdersSymbol });
        return processed;
    }

    public processChangeToMarketAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ChartVisualTrading(action.Details, { action: TerceraChartTradingOperation.ChangeToMarket });
        return processed;
    }

    public processFitIndicatorsAction (action: TerceraChartAction): boolean {
        const processed = false;
        const window = action.Details;
        if (window) {
            const chart = this.chart;
            if (chart) {
                window.indicatorRendererSettings.UseInAutoscale = !window.indicatorRendererSettings.UseInAutoscale;
            }
        }
        return processed;
    }

    public processFitOrdersAction (action: TerceraChartAction): boolean {
        const processed = false;
        const chart = this.chart;
        if (chart) {
            chart.TerceraChartTradingToolsRenderer.OrderScale = !chart.TerceraChartTradingToolsRenderer.OrderScale;
        }

        return processed;
    }

    public processFitAlertsAction (action: TerceraChartAction): boolean {
        const processed = false;
        const chart = this.chart;
        if (chart) {
            chart.TerceraChartTradingToolsRenderer.AlertScale = !chart.TerceraChartTradingToolsRenderer.AlertScale;
        }

        return processed;
    }

    public processFitDayHighLowAction (action: TerceraChartAction): boolean {
        const processed = false;
        const chart = this.chart;
        if (chart) {
            chart.DayHighLowRenderer.UseInAutoscale = !chart.DayHighLowRenderer.UseInAutoscale;
        }

        return processed;
    }

    public processFitDrawingsAction (action: TerceraChartAction): boolean {
        const processed = false;
        const window = action.Details;
        if (window) {
            const renderer = window.GetRenderer(TerceraChartToolRenderer);
            if (renderer) {
                renderer.UseInAutoscale = !renderer.UseInAutoscale;
            }
        }
        return processed;
    }

    public processMainSymbolAction (action: TerceraChartAction): boolean {
        const processed = false;
        const overlay = action.Details;
        const chart = this.chart;
        if (overlay && chart) {
            chart.activeOverlay = overlay;
        }

        return processed;
    }

    public processTimeSeparatorsAction (action: TerceraChartAction): boolean {
        const processed = false;
        const type = action.Details;
        if (type) {
            const renderer = this.chart.windowsContainer.xScaleRenderer;
            if (renderer) {
                const settings = renderer.settings;
                switch (type) {
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day:
                    settings.DaySeparatorsVisibility = !settings.DaySeparatorsVisibility;
                    break;
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Week:
                    settings.WeekSeparatorsVisibility = !settings.WeekSeparatorsVisibility;
                    break;
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Month:
                    settings.MonthSeparatorsVisibility = !settings.MonthSeparatorsVisibility;
                    break;
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Year:
                    settings.YearSeparatorsVisibility = !settings.YearSeparatorsVisibility;
                    break;
                }
            }
        }
        return processed;
    }

    public processShowHolesAction (): boolean {
        const processed = false;
        const chart = this.chart;
        if (chart) {
            chart.chartController.ExecuteCommand(
                new TerceraChartMVCCommand(ModelDataType.ShowEmptyBars),
                !chart.cashItemSeriesSettings.ShowEmptyBars);
        }
        return processed;
    }

    public processShowExtendedSessionAction (): boolean {
        const processed = false;
        const chart = this.chart;
        if (chart) {
            chart.chartController.ExecuteCommand(
                new TerceraChartMVCCommand(ModelDataType.ShowAllSession),
                !chart.ShowAllSessions);
        }
        return processed;
    }

    public processOverlayAction (action: TerceraChartAction): boolean {
        const processed = false;
        const details = action.Details;
        const chart = this.chart;
        if (chart) {
            if (details === true) {
                chart.RemoveAllOverlays();
            } else if (typeof details === 'number') {
                chart.terceraChartPanelContext.ShowScreen(
                    ITerceraChartShowScreenType.OverlayScreen, null);
            }
        }
        return processed;
    }

    public processIndicatorAction (action: TerceraChartAction): boolean {
        const processed = false;
        const details = action.Details;
        const chart = this.chart;
        if (chart) {
            if (details === true) {
                chart.RemoveAllIndicators();
            } else if (chart && typeof details === 'number') {
                chart.terceraChartPanelContext.ShowScreen(details, null);
            }
        }
        return processed;
    }

    public processRefreshAction (): boolean {
        let processed = false;
        const chart = this.chart;
        if (chart) {
            chart.RefreshChart(ChartLoadTrigger.RefreshAction);
            processed = true;
        }
        return processed;
    }

    public processNewPanelAction (action: TerceraChartAction): boolean {
        const processed = false;
        const details = action.Details;
        if (details) {
            this.chart.terceraChartPanelContext.ShowScreen(
                ITerceraChartShowScreenType.NewPanel,
                { panelName: details });
        }
        return processed;
    }

    public processDeleteToolsByTypeAction (action: TerceraChartAction): boolean {
        const processed = false;

        const chart = this.chart;
        const toolType = action.Details;

        if (!chart || typeof toolType !== 'number') {
            return;
        }

        const windows = chart.windowsContainer.Windows;
        const len = windows.length;

        for (let i = 0; i < len; i++) {
            const toolRenderer = windows[i].GetRenderer(TerceraChartToolRenderer);
            toolRenderer.RemoveToolsByType(toolType);
        }

        return processed;
    }

    public processDeleteAllToolsAction (): boolean {
        const processed = false;

        const chart = this.chart;

        if (chart) {
            const windows = chart.windowsContainer.Windows;
            const len = windows.length;

            for (let i = 0; i < len; i++) {
                const toolRenderer = windows[i].GetRenderer(TerceraChartToolRenderer);
                toolRenderer.RemoveAllTools();
            }
        }

        return processed;
    }

    public processDeleteToolAction (action: TerceraChartAction): boolean {
        const processed = false;
        const details: DataCacheTool = action.Details;
        const chart = this.chart;

        if (chart) {
            const windows = chart.windowsContainer.Windows;
            for (const window of windows) {
                const toolRenderer = window.GetRenderer(TerceraChartToolRenderer);
                const toolView = this.getToolViewByTool(toolRenderer, details);
                if (toolView) {
                    toolRenderer.RemoveTool(toolView);
                    break;
                }
            }
        }

        return processed;
    }

    public processToolSettingsAction (action: TerceraChartAction): boolean {
        const processed = false;
        const details: DataCacheTool = action.Details;
        const chart = this.chart;

        if (chart) {
            const windows = chart.windowsContainer.Windows;
            for (const window of windows) {
                const toolRenderer = window.GetRenderer(TerceraChartToolRenderer);
                const toolView = this.getToolViewByTool(toolRenderer, details);
                if (toolView) {
                    const parameters = { caller: toolView };
                    chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.Properties, parameters);
                    break;
                }
            }
        }

        return processed;
    }

    private getToolViewByTool (toolRenderer: TerceraChartToolRenderer, dataCacheTool: DataCacheTool): ToolView {
        for (const toolView of toolRenderer.tools) {
            if (toolView.dataCacheTool === dataCacheTool) {
                return toolView;
            }
        }

        return null;
    }

    public processChartTradingAction (action: TerceraChartAction): boolean {
        const processed = false;

        const chart = this.chart;
        if (chart) {
            const details = action.Details;

            chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.ChartTrading,
                details === null || details === undefined
                    ? null
                    : { tradingFlag: details });
        }

        return processed;
    }

    public processPlateIndicatorVisibleAction (action: TerceraChartAction): boolean {
        const processed = false;
        action.Details.Visible = !action.Details.Visible;
        return processed;
    }

    public processPlateIndicatorDublicateAction (action: TerceraChartAction): boolean {
        const processed = false;

        const curIndicator = action.Details.PTLIndicator;
        const newIndicator = IndicatorManager.GetIndicator(action.Details.PTLIndicator.Script.ProjectName);
        newIndicator.callBack(curIndicator.Properties());
        //     newIndicator.OnNewHistory.Subscribe(function () { this.chart.IsDirty(); }, this);
        this.chart.AddIndicator(newIndicator, true);

        return processed;
    }

    public processPlateIndicatorRemoveAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.RemoveIndicator(action.Details, true);
        return processed;
    }

    public processPlateOverlayVisibleAction (action: TerceraChartAction): boolean {
        const processed = false;
        action.Details.mainPriceRenderer.Visible = !action.Details.mainPriceRenderer.Visible;
        return processed;
    }

    public processPlateOverlayRemoveAction (action: TerceraChartAction): boolean {
        const processed = false;
        this.chart.RemoveOverlay(action.Details);
        return processed;
    }

    public processCancelOrderSLTPAction (action: TerceraChartAction): boolean {
        const processed = false;

        const details = action.Details;
        this.chart.terceraChartPanelContext.ChartVisualTrading(
            details.order,
            {
                action: details.cancelSL
                    ? TerceraChartTradingOperation.RemoveSL
                    : TerceraChartTradingOperation.RemoveTP
            });

        return processed;
    }

    public processDrawingToolsGroupAction (action: TerceraChartAction): boolean {
        action.Details.Visible = false;
        return false;
    }

    public processToBeginAction (action: TerceraChartAction): boolean {
        this.chart.ToBegin();

        return true;
    }

    public processObjectManagerAction (action: TerceraChartAction): boolean {
        const typePanel: PanelNames = action.Details;
        ObjectManagerScreen.show(this.chart, typePanel);
        return true;
    }

    public processShowOnChartAction (action: TerceraChartAction): boolean {
        const tool: DataCacheTool = action.Details;
        const points = tool.Points;
        const chart = this.chart;

        if (isNullOrUndefined(chart)) {
            return;
        }

        let middletime = 0;
        for (const point of points) {
            middletime += point[0];
        }
        middletime = Math.trunc(middletime / points.length);

        this.EnsureVisible(middletime, EnsureVisibleTypeEnum.Middle);
        for (const tool of chart.AllWindowsHoverAndSelectedTools) {
            tool.ResetSelection();
        }

        const windows = chart.windowsContainer.Windows;
        for (const window of windows) {
            const toolRenderer = window.GetRenderer(TerceraChartToolRenderer);
            const toolView = this.getToolViewByTool(toolRenderer, tool);
            if (toolView) {
                toolView.SelectTool();
                break;
            }
        }

        const LeftRelativeBorder = chart.MainCashItemSeries.LeftRelativeBorder;
        const RightRelativeBorder = chart.MainCashItemSeries.RightRelativeBorder;
        if (LeftRelativeBorder > points[0][0] && RightRelativeBorder < points[0][0] &&
            (tool.PointLevel() < 2 || (LeftRelativeBorder > points[1][0] && RightRelativeBorder < points[1][0]))) {
            // super
        } else {
            // обе точки не влезли попробуем показать 1ну
            this.EnsureVisible(points[0][0], EnsureVisibleTypeEnum.Middle);
        }

        return true;
    }

    private EnsureVisible (time: number, ensureVisibleType: EnsureVisibleTypeEnum): void {
        const chart = this.chart;
        if (chart.MainCashItemSeries == null) {
            return;
        }

        const mainWindow = chart.mainWindow;
        let index = Math.round(mainWindow.PointsConverter.GetBarIndex(time));

        if (index > 0) {
            // Already visible?
            if (index < mainWindow.i1 && index > mainWindow.i1 - mainWindow.im() &&
                ensureVisibleType !== EnsureVisibleTypeEnum.LeftBorderWithOfsset) {
                return;
            }

            if (ensureVisibleType === EnsureVisibleTypeEnum.Middle) {
                index = (index + mainWindow.im() / 2);
            }

            if (ensureVisibleType === EnsureVisibleTypeEnum.LeftBorderWithOfsset) {
                index = (index + mainWindow.im() * 0.2);
            }

            const newI1 = index;

            if (newI1 !== mainWindow.i1) {
                chart.ToCustomPositionOnChart(Math.trunc(newI1));
                chart.IsDirty();
            }
        }
    }

    // #endregion

    // #region State processing.

    public processDrawingToolsGroupState (outState): void {
        outState.locKey = 'chart.tools.eraser';
        // outState.ImageKey = "h_chart/tools/16x16_icon_erase_default.png";
        // outState.ImageCheckedKey = "h_chart/tools/16x16_icon_erase_checked.png";

        const chart = this.chart;

        if (chart?.mainWindow && chart.EraserRenderer) {
            outState.Active = chart.EraserRenderer.Active;
        } else {
            outState.Active = false;
        }

        outState.Visible = false;
    }

    public processSymbolsLookupState (outState): void {
        outState.locKey = 'chart.instrumentsLookup';
    }

    public processEraserState (outState): void {
        outState.locKey = 'chart.tools.eraser';

        const chart = this.chart;

        if (chart?.mainWindow && chart.EraserRenderer) {
            outState.Active = chart.EraserRenderer.Active;
        } else {
            outState.Active = false;
        }
    }

    public processToolSelectorState (outState): void {
        outState.locKey = 'chart.tools.selection';

        const chart = this.chart;

        if (chart?.mainWindow && chart.SelectionRenderer) {
            outState.Active = chart.SelectionRenderer.Active;
        } else {
            outState.Active = false;
        }
    }

    public processCreationToolState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const toolType = action.Details;

        if (toolType === undefined || toolType === null) {
            outState.locKey = 'chart.head.line';
            return;
        }

        const tsi = TerceraChartActionProcessor.ToolStateInfoDict[toolType];
        outState.locKey = tsi.locKey;

        const curTool = this.chart.NewToolStrategy.CurrentTool();
        outState.Active = curTool && curTool.ToolType === toolType;
    }

    public plateIndicatorSettingsState (outState): void {
        outState.locKey = 'chart.menu.PlateIndicatorSettings';
    }

    public priceIndicatorState (): void {

    }

    public setStayInDrawingModeState (outState): void {
        outState.locKey = 'chart.tools.StayDrawingMode';

        if (this.chart) {
            const allRenderers = this.chart.mainWindow.GetAllRenderers();
            const len = allRenderers.length;
            for (let i = 0; i < len; i++) {
                const r = allRenderers[i];
                if (r instanceof TerceraChartNewToolRenderer) {
                    outState.Active = r ? r.StayInDrawingMode : false;
                    break;
                }
            }
        } else {
            outState.Active = false;
        }
    }

    public deleteSelectedToolsState (outState): void {
        outState.locKey = 'chart.tool.delete';

        const allWSelTools = this.chart.AllWindowsSelectedTools;
        outState.Visible = allWSelTools && allWSelTools.length > 0;
    }

    public selectedToolSettingsState (outState): void {
        outState.locKey = 'chart.tool.properties';

        const allWSelTools = this.chart.AllWindowsSelectedTools;
        outState.Visible = allWSelTools && allWSelTools.length === 1;
    }

    public toolColorState (outState): void {
        outState.Visible = false;
        const allSelectedTools = this.chart.AllWindowsHoverAndSelectedTools;
        if (allSelectedTools && allSelectedTools.length > 0) {
            const colorProp = DynProperty.getPropertyByName(allSelectedTools[0].Properties(), 'Color');
            if (colorProp?.value) {
                outState.Visible = true;
                outState.details = colorProp.value;
            }
        }
    }

    public toolLineWidthState (outState): void {
        outState.Visible = false;
        const allSelectedTools = this.chart.AllWindowsHoverAndSelectedTools;
        if (allSelectedTools && allSelectedTools.length > 0) {
            const widthProp = DynProperty.getPropertyByName(allSelectedTools[0].Properties(), 'Width');
            if (widthProp?.value) {
                outState.Visible = true;
                outState.details = widthProp.value;
            }
        }
    }

    public processInfoWindowHideState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.InfoWindowHide';
    }

    public processInfoWindowModeState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        if (isNullOrUndefined(action.Details)) {
            outState.locKey = 'chart.view.dataInfo';
            return;
        }

        switch (action.Details) {
        case InfoWindowDisplayMode.SeparateWindow:
            outState.locKey = 'chart.menu.InfoWindowSeparateWindow';
            break;
        case InfoWindowDisplayMode.AttachedToCursor:
            outState.locKey = 'chart.menu.InfoWindowAttachedToCursor';
            break;
        case InfoWindowDisplayMode.Hidden:
            outState.locKey = 'chart.menu.InfoWindowHidden';
            break;
        }

        const infoWindowRenderer = this.chart.mainWindow.GetRenderer(TerceraChartInfoWindowRenderer);
        if (!isNullOrUndefined(infoWindowRenderer)) {
            outState.Active = infoWindowRenderer.displayMode === action.Details;
            outState.CanCheck = true;
        }
    }

    public processInfoWindowDockState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.InfoWindowDock';
        outState.CanCheck = true;
        outState.Active = action.Details.DockingType !== InfoWindowDockingType.None;
    }

    public processInfoWindowShortModeState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.InfoWindowShortMode';
        outState.CanCheck = true;
        outState.Active = action.Details.ShortMode;
    }

    public processInfoWindowSettingsState (outState): void {
        outState.locKey = 'chart.menu.InfoWindowSettings';
    }

    public plateOverlaySettingsState (outState): void {
        outState.locKey = 'chart.menu.PlateOverlaySettings';
    }

    public processHoverAndSelectedToolSettingsState (outState): void {
        outState.locKey = 'chart.tool.properties';
        outState.Visible = this.chart.AllWindowsHoverAndSelectedTools.length > 0;
    }

    public processCloneToolState (outState): void {
        outState.locKey = 'chart.tool.clone';
    }

    public processDeleteHoverAndSelectedToolsState (outState): void {
        outState.locKey = 'chart.tool.delete';
        outState.Visible = this.chart.AllWindowsHoverAndSelectedTools.length > 0;
    }

    public processStyleState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const chart = this.chart;
        if (!chart) return;

        const tfInfo = chart.TimeFrameInfo();
        if (!tfInfo) return;

        const drawingStyle = action.Details;
        if (drawingStyle !== undefined && drawingStyle !== null) {
            outState.locKey = TerceraChartUtils.ToLocalizedTerceraChartDrawingType(drawingStyle);
            outState.Visible = chart.model.GetAllowedStyles(tfInfo).includes(drawingStyle);
            outState.Active = chart.mainPriceRenderer.ChartDrawingType === drawingStyle;
            outState.CanCheck = true;
        } else {
            outState.locKey = 'chart.head.charttype';
            outState.Enabled = chart.model.GetAllowedStyles(tfInfo).length > 1;
        }
    }

    public processDataTypeState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const historyType = action.Details;
        const chart = this.chart;
        const instrument = chart.Instrument();
        const tfInfo = chart.TimeFrameInfo();

        if (historyType && instrument && tfInfo) {
            const historyState = TerceraChartActionState.GetHistoryTypeActionState(
                action.Details, tfInfo.Periods, instrument, chart.ChartHistoryType());

            outState.applyFrom(historyState);
        } else {
            outState.locKey = 'chart.view.source';
        }

        outState.Enabled = tfInfo ? tfInfo.TradeAnalysisType() !== TradeAnalysisType.Cluster : true;
    }

    public processClosePositionState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.close';
        outState.Enabled = IsAllowed.IsPositionClosingAllowed(action.Details).Allowed;
        outState.Visible = true;
    }

    public processCloseAllPositionState (outState): void {
        outState.locKey = 'chart.menu.closeall';
        outState.Enabled = !TradingLockUtils.TradingLock.tradingLocked && !DataCache.isAllowedForAccount(RulesSet.TERMINAL_HTML) && IsAllowed.IsCloseAllAllowed(true, false).Allowed;
        outState.Visible = !DataCache.isAllowedForAccount(RulesSet.FUNCTION_FIFO_POSITIONS);
    }

    public processModifyPositionState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const position = action.Details;
        outState.locKey = 'chart.menu.modify';
        outState.Enabled = position && IsAllowed.IsPositionModifyingAllowed(position).Allowed;
        outState.Visible = true;
    }

    public processRollbackPositionState (outState): void {
        outState.locKey = 'chart.menu.rollback';
        outState.Enabled = true;
        outState.Visible = IsAllowed.IsRollbackAllowed().Allowed;
    }

    public processCancelOrdersState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.cancelOrder';
        outState.Enabled = IsAllowed.IsOrderCancelingAllowed(action.Details).Allowed;
        outState.Visible = true;
    }

    public processCancelOrderIDState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = Resources.getResource('chart.menu.cancelOrder') + ' ' +
            (action.Details instanceof Position ? action.Details.PositionId : action.Details.OrderNumber);

        outState.Enabled = IsAllowed.IsOrderCancelingAllowed(action.Details).Allowed;
        outState.Visible = true;
    }

    public processCancelOrdersAccountState (outState): void {
        outState.locKey = 'chart.menu.cancelByAccount';
        outState.Enabled = IsAllowed.IsCloseAllAllowed(false).Allowed;
        outState.Visible = true;
    }

    public processCancelOrdersSymbolState (outState): void {
        outState.locKey = 'chart.menu.cancelByInstrument';
        outState.Enabled = IsAllowed.IsCloseAllAllowed(false).Allowed;
        outState.Visible = true;
    }

    public processModifyOrderState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.modify';
        outState.Visible = true;
        const details = action.Details;
        outState.Enabled = details.isPosition ? IsAllowed.IsPositionModifyingAllowed(details).Allowed : IsAllowed.IsOrderModifyingAllowed(details).Allowed;
    }

    public processChangeToMarketState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'panel.orders.menu.ExecuteAsMarket';
        outState.Enabled = IsAllowed.IsOrderExecutingAllowed(action.Details).Allowed;
        outState.Visible = true;
    }

    public processFitIndicatorsState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const window = action.Details;
        if (window) {
            outState.Active = window.indicatorRendererSettings.UseInAutoscale;
        }

        outState.CanCheck = true;
        outState.locKey = 'chart.priceScaleContextMenu.FitIndicators';
    }

    public processFitOrdersState (outState): void {
        const chart = this.chart;
        if (chart) {
            outState.Active = chart.TerceraChartTradingToolsRenderer.OrderScale;
        }

        outState.CanCheck = true;
        outState.locKey = 'chart.priceScaleContextMenu.FitOrdersPositions';
    }

    public processFitAlertsState (outState): void {
        const chart = this.chart;
        if (chart) {
            outState.Active = chart.TerceraChartTradingToolsRenderer.AlertScale;
        }

        outState.CanCheck = true;
        outState.Visible = !Resources.isHidden('screen.Alerts.visibility') && DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS);
        outState.locKey = 'chart.priceScaleContextMenu.FitAlerts';
    }

    public processFitDayHighLowState (outState): void {
        if (!this.chart.DayHighLowRenderer) {
            outState.Visible = false;
            return;
        }

        if (this.chart) {
            outState.Active = this.chart.DayHighLowRenderer.UseInAutoscale;
        }

        outState.CanCheck = true;
        outState.locKey = 'chart.priceScaleContextMenu.FitDay';
    }

    public processFitDrawingsState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const window = action.Details;
        if (window) {
            const renderer = window.GetRenderer(TerceraChartToolRenderer);
            if (renderer) {
                outState.Active = renderer.UseInAutoscale;
            } else {
                outState.Visible = false;
            }
        }
        outState.CanCheck = true;
        outState.locKey = 'chart.priceScaleContextMenu.FitDrawings';
    }

    public processMainSymbolState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const overlay = action.Details;
        const chart = this.chart;
        if (overlay && chart) {
            outState.Active = chart.activeOverlay === overlay;
            outState.CanCheck = true;
            outState.locKey = overlay.ActiveInstrument.toString();
        }
    }

    public processTimeSeparatorsState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const type = action.Details;
        if (!type) {
            outState.locKey = 'chart.timeScaleContextMenu.ShowTimePeriodsSeparator';
            return;
        }

        const renderer = this.chart.windowsContainer.xScaleRenderer;
        if (!renderer) return;

        const settings = renderer.settings;
        outState.CanCheck = true;
        switch (type) {
        case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day:
            outState.locKey = 'chart.timeScaleContextMenu.ShowDaySeparator';
            outState.Active = settings.DaySeparatorsVisibility;
            break;
        case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Week:
            outState.locKey = 'chart.timeScaleContextMenu.ShowWeeksSeparator';
            outState.Active = settings.WeekSeparatorsVisibility;
            break;
        case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Month:
            outState.locKey = 'chart.timeScaleContextMenu.ShowMonthSeparator';
            outState.Active = settings.MonthSeparatorsVisibility;
            break;
        case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Year:
            outState.locKey = 'chart.timeScaleContextMenu.ShowYearSeparator';
            outState.Active = settings.YearSeparatorsVisibility;
            break;
        }
    }

    public processShowHolesState (outState): void {
        const chart = this.chart;
        if (chart) {
            outState.Enabled = chart.TimeFrameInfo().ChartDataType !== ChartDataType.HeikinAshi;
            outState.Active = chart.cashItemSeriesSettings.ShowEmptyBars;
            outState.CanCheck = true;
        }
        outState.locKey = 'chart.timeScaleContextMenu.ShowEmptyBars';
    }

    public processShowExtendedSessionState (outState): void {
        const chart = this.chart;
        if (chart) {
            outState.Active = chart.ShowAllSessions;
        }

        outState.CanCheck = true;
        outState.locKey = 'chart.timeScaleContextMenu.ShowAllSessions';
    }

    public processOverlayState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const details = action.Details;
        if (details === true) {
            const chart = this.chart;
            if (chart) {
                outState.Enabled = chart.Overlays.length > 0;
            }

            outState.locKey = 'chart.head.overlayRemoveAll';
        } else if (typeof details === 'number') {
            outState.locKey = 'chart.head.addOverlay';
        } else {
            outState.locKey = 'chart.head.overlays';
        }
    }

    public processIndicatorState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const details = action.Details;
        if (details === true) {
            const chart = this.chart;
            if (chart) outState.Enabled = chart.IndicatorRenderers().length > 0;
            outState.locKey = 'chart.indicatorRemoveAll';
        } else if (typeof details === 'number') {
            outState.locKey = 'chart.indicatorAdd';
        } else {
            outState.locKey = 'chart.indicators';
        }
    }

    public processViewState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const details = action.Details;
        const chart = this.chart;
        if (details === undefined || details === null || !chart) {
            outState.locKey = 'chart.view';
            return;
        }
        switch (details) {
        // TODO.
        case TerceraChartToolbarsEnum.AnalyseToolBar:
            outState.Visible = false;
            break;
        case TerceraChartToolbarsEnum.DrawingToolBar:
            outState.locKey = 'chart.view.drawingtoolbar';
            outState.Active = chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.UIManipulation, {
                isSet: false, toolBarType: TerceraChartToolbarsEnum.DrawingToolBar
            });
            break;
        case TerceraChartToolbarsEnum.MainToolBar:
            outState.locKey = 'chart.view.toolbar';
            outState.Active = chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.UIManipulation, {
                isSet: false, toolBarType: TerceraChartToolbarsEnum.MainToolBar
            });
            break;
        case TerceraChartToolbarsEnum.OrderEntry:
            outState.locKey = 'chart.view.orderEntry';
            outState.Active = chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.UIManipulation, {
                isSet: false, toolBarType: TerceraChartToolbarsEnum.OrderEntry
            });
            break;
        case TerceraChartToolbarsEnum.PreviousCloseLine:
            outState.Visible = false;
            if (typeof TerceraChartDayHighLowRenderer === 'function') {
                const renderer = chart.mainWindow.GetRenderer(TerceraChartDayHighLowRenderer);
                if (renderer) {
                    outState.Visible = true;
                    outState.locKey = 'chart.view.previousCloseLine';
                    outState.Enabled = renderer.Enabled();
                    outState.Active = renderer.PrevCloseVisibleGet();
                }
            }
            break;
        case TerceraChartToolbarsEnum.TimeToNextBar:
            outState.locKey = 'chart.view.TimeToNextBar';
            outState.Active = chart.mainWindow.GetRenderer(TerceraChartTimeToNextBarRenderer).Visible;
            break;
        case TerceraChartToolbarsEnum.DayHighLow:
            outState.Visible = false;
            if (typeof TerceraChartDayHighLowRenderer === 'function') {
                const renderer = chart.mainWindow.GetRenderer(TerceraChartDayHighLowRenderer);
                if (renderer) {
                    outState.Visible = true;
                    outState.locKey = 'chart.view.Day_HighLow';
                    outState.Enabled = renderer.Enabled();
                    outState.Active = renderer.VisibleGet();
                }
            }
            break;
        case TerceraChartToolbarsEnum.Volume:
            outState.locKey = 'chart.mainlayer.volume';
            outState.Active = chart.volumeBarsRenderer.Visible;
            outState.Visible = !Resources.isHidden('property.hotkeyManager.ShowVolume') || DataCache.isAllowedForMyUser(RulesSet.FUNCTION_CHART_VOLUME_BARS);
            break;
        case TerceraChartToolbarsEnum.GridTime:
            outState.locKey = 'chart.mainlayer.gridTime';
            outState.Active = chart.xScaleRendererSettings.ScaleGridVisibility;
            break;
        case TerceraChartToolbarsEnum.GridPrice:
            outState.locKey = 'chart.mainlayer.gridPrice';
            outState.Active = chart.yScaleRendererSettings.ScaleGridVisibility;
            break;
        }
        outState.CanCheck = true;
    }

    public processRefreshState (outState): void {
        outState.Enabled = true;
        outState.locKey = 'chart.refresh';
    }

    public processNewPanelState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        switch (action.Details) {
        /* case PanelNames.ChartPanel:
                outState.locKey = "panel.copypanel";
                // TODO.
                //outState.Visible = DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING);
                outState.Visible = true;
                break; */
        case PanelNames.AdvancedOrderEntry:
            outState.locKey = 'panel.neworder';
            outState.Visible = CustomerAccess.panelAllowed(PanelNames.AdvancedOrderEntry);
            break;
        case PanelNames.MarketDepthPanel:
            outState.locKey = 'panel.level2';
            outState.Visible = CustomerAccess.panelAllowed(PanelNames.MarketDepthPanel);
            break;
            /* TODO.
        case Utils.NAME_NEWTRADES_PANEL:
            outState.locKey = "panel.trades";
            outState.Visible = DataCache.isAllowed(RulesSet.FUNCTION_TRADES, null);
            break; */
        default:
            outState.locKey = 'panel.menu.new';
            break;
        }
    }

    public processDeleteToolsByTypeState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const chart = this.chart;
        const toolType = action.Details;

        if (!toolType) {
            outState.locKey = 'chart.tool.delete';
            return;
        }

        if (!chart) return;

        outState.locKey = TerceraChartActionProcessor.ToolStateInfoDict[toolType].locKey + '.deleteByType';
        outState.Visible = false;

        const windows = chart.windowsContainer.Windows;
        const len = windows.length;
        for (let i = 0; i < len; i++) {
            const toolRenderer = windows[i].GetRenderer(TerceraChartToolRenderer);
            if (isNullOrUndefined(toolRenderer)) {
                continue;
            }

            const tools = toolRenderer.tools;
            if (isNullOrUndefined(tools)) {
                continue;
            }

            const t_len = tools.length;
            for (let t_i = 0; t_i < t_len; t_i++) {
                const tool = tools[t_i];
                if (tool.dataCacheTool.ToolType === toolType) {
                    outState.Visible = true;
                    return;
                }
            }
        }
    }

    public processDeleteAllToolsState (outState): void {
        outState.locKey = 'chart.tool.deleteAll';
        outState.Enabled = false;

        const chart = this.chart;
        if (chart) {
            const windows = chart.windowsContainer.Windows;
            const len = windows.length;

            for (let i = 0; i < len; i++) {
                const toolRenderer = windows[i].GetRenderer(TerceraChartToolRenderer);
                if (toolRenderer.tools.length > 0) {
                    outState.Enabled = true;
                    break;
                }
            }
        }
    }

    public processChartTradingState (outState): void {
        outState.Active = this.chart.TerceraChartTradingToolsRenderer.QuickOEMode();
    }

    public processPlateIndicatorVisibleState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.PlateIndicatorVisible';
        outState.CanCheck = true;
        outState.Active = action.Details.Visible;
    }

    public processPlateIndicatorDublicateState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.Visible = action.Details.AllowDublicate();
        outState.locKey = 'chart.menu.PlateIndicatorDublicate';
    }

    public processPlateIndicatorRemoveState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.PlateIndicatorRemove';
    }

    public processPlateOverlayVisibleState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.PlateOverlayVisible';
        outState.CanCheck = true;
        outState.Active = action.Details.mainPriceRenderer.Visible;
    }

    public processPlateOverlayRemoveState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        outState.locKey = 'chart.menu.PlateOverlayRemove';
    }

    public processCancelOrderSLTPState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const details = action.Details;

        outState.locKey = details.cancelSL
            ? 'TradeTool.Cancel SL'
            : 'TradeTool.Cancel TP';
    }

    public processToBeginState (outState: TerceraChartActionState): void {
        const chart = this.chart;
        if (isNullOrUndefined(chart)) {
            return;
        }

        outState.ToolTiplocKey = 'chart.chartScroll.toBegin';
        outState.Enabled = !chart.IsAtTheBegin();
    }

    public processObjectManagerState (outState: TerceraChartActionState): void {
        outState.locKey = 'ChartObjectManager.Text';
        outState.Enabled = true;
    }

    // #endregion

    public override processCancelAction (action: TerceraChartAction): boolean {
        let processed = super.processCancelAction(action);
        let context = CancelActionContext.Esc;
        const details = action.Details;
        // Old condition: action.Details is CancelActionContext
        if (typeof details === 'number') {
            context = details;
        }
        processed = this.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Eraser, false), true) || processed;
        processed = this.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ToolSelector, false), true) || processed;

        if (context === CancelActionContext.Esc) {
            processed = this.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ChartTrading, false), true) || processed;
        }

        processed = this.chart.NewToolStrategy.Cancel() || processed;
        return processed;
    }

    public override processZoomState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        super.processZoomState(outState, action);
        this.chart.resetCurrentView();
    }

    public override processCancelState (outState): void {
        outState.locKey = 'chart.tools.default';
        const chart = this.chart;
        outState.Active = (!chart.NewToolStrategy.CurrentTool()) &&
            !this.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ScalingByRect)).Active &&
            !this.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.Eraser)).Active &&
            !this.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ToolSelector)).Active &&
            !this.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ChartTrading)).Active;
    }

    // #region Tools State Info Dictionary

    public static readonly ToolStateInfoDict = {
        [DataCacheToolType.Line]: {
            locKey: 'chart.tools.Line'
        },
        [DataCacheToolType.HorizontalLine]: {
            locKey: 'chart.tools.horizontal'
        },
        [DataCacheToolType.VerticalLine]: {
            locKey: 'chart.tools.vertical'
        },
        [DataCacheToolType.Triangle]: {
            locKey: 'chart.tools.Triangle'
        },
        [DataCacheToolType.Rectangle]: {
            locKey: 'chart.tools.Rectangle'
        },
        [DataCacheToolType.Polygon]: {
            locKey: 'chart.tools.Polygon'
        },
        [DataCacheToolType.Circle]: {
            locKey: 'chart.tools.Circle'
        },
        [DataCacheToolType.Ellipse]: {
            locKey: 'chart.tools.Ellipse'
        },
        [DataCacheToolType.AndrewsPitchFork]: {
            locKey: 'chart.tools.AndrewPitchfork'
        },
        [DataCacheToolType.PriceChannel]: {
            locKey: 'chart.tools.PriceChannel'
        },
        [DataCacheToolType.FibonacciRetracement]: {
            locKey: 'chart.tools.Fibretr'
        },
        [DataCacheToolType.FibonacciExpansion]: {
            locKey: 'chart.tools.FibExp'
        },
        [DataCacheToolType.FibonacciArc]: {
            locKey: 'chart.tools.FibArc'
        },
        [DataCacheToolType.FibonacciSpiral]: {
            locKey: 'chart.tools.FibSpiral'
        },
        [DataCacheToolType.FibonacciEllipse]: {
            locKey: 'chart.tools.FibEllipses'
        },
        [DataCacheToolType.FibonacciFans]: {
            locKey: 'chart.tools.FibFan'
        },
        [DataCacheToolType.FibonacciPhiChannel]: {
            locKey: 'chart.tools.FibPhiChannel'
        },
        [DataCacheToolType.FibonacciTimeGoal]: {
            locKey: 'chart.tools.FibTga'
        },
        [DataCacheToolType.FibonacciTimeZone]: {
            locKey: 'chart.tools.FibTz'
        },
        [DataCacheToolType.GannLine]: {
            locKey: 'chart.tools.GannLine'
        },
        [DataCacheToolType.GannFan]: {
            locKey: 'chart.tools.GannFan'
        },
        [DataCacheToolType.GannGrid]: {
            locKey: 'chart.tools.GannGrid'
        },
        [DataCacheToolType.ElliottImpulse]: {
            locKey: 'chart.tools.ElliottImpulse'
        },
        [DataCacheToolType.ElliottCorrection]: {
            locKey: 'chart.tools.ElliottCorrection'
        },
        [DataCacheToolType.ElliottTriangle]: {
            locKey: 'chart.tools.ElliottTriangle'
        },
        [DataCacheToolType.ElliottTripleCombo]: {
            locKey: 'chart.tools.ElliottTripleCombo'
        },
        [DataCacheToolType.ElliottDoubleCombo]: {
            locKey: 'chart.tools.ElliottDoubleCombo'
        },
        [DataCacheToolType.InfoLine]: {
            locKey: 'chart.tools.infoline'
        },
        [DataCacheToolType.ArrowLabel]: {
            locKey: 'chart.tools.arrowLabel'
        },
        [DataCacheToolType.CommentsLabel]: {
            locKey: 'chart.tools.commentsLabel'
        },
        [DataCacheToolType.PriceLabel]: {
            locKey: 'chart.tools.priceLabel'
        },
        [DataCacheToolType.Symbol]: {
            locKey: 'chart.tools.symbol'
        },
        [DataCacheToolType.Text]: {
            locKey: 'chart.tools.text'
        },
        [DataCacheToolType.RegressionChannel]: {
            locKey: 'chart.tools.RegressionChannel'
        },
        [DataCacheToolType.ABCPatern]: {
            locKey: 'chart.tools.ABCPatern'
        },
        [DataCacheToolType.PPZ]: {
            locKey: 'chart.tools.PPZ'
        },
        [DataCacheToolType.TopBottomPattern]: {
            locKey: 'chart.tools.TopBottomPattern'
        },
        [DataCacheToolType.ButterflyGartley]: {
            locKey: 'chart.tools.ButterflyGartley'
        },
        [DataCacheToolType.FibonacciTools]: {
            locKey: 'chart.tools.FibonacciTools'
        },
        [DataCacheToolType.GannTools]: {
            locKey: 'chart.tools.GannTools'
        },
        [DataCacheToolType.ElliottTools]: {
            locKey: 'chart.tools.ElliottTools'
        },
        [DataCacheToolType.GeometryTools]: {
            locKey: 'chart.tools.GeometryTools'
        },
        [DataCacheToolType.TrendTools]: {
            locKey: 'chart.tools.TrendTools'
        },
        [DataCacheToolType.SecondaryTools]: {
            locKey: 'chart.tools.SecondaryTools'
        }
    };

    // #endregion

    protected override shouldCancelAction (actionType: TerceraChartActionEnum): boolean {
        return actionType !== TerceraChartActionEnum.InfoWindowMode && super.shouldCancelAction(actionType);
    }
}
// #endregion
