// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from './DataCacheToolEnums';
import { AndrewsPitchforkDataCacheTool } from './Tools/AndrewsPitchforkDataCacheTool';
import { ArrowLabelDataCacheTool } from './Tools/ArrowLabelDataCacheTool';
import { CircleDataCacheTool } from './Tools/CircleDataCacheTool';
import { ElliotWavesDataCacheTool } from './Tools/ElliotWavesDataCacheTool';
import { EllipseDataCacheTool } from './Tools/EllipseDataCacheTool';
import { FibonacciArcDataCacheTool } from './Tools/FibonacciArcDataCacheTool';
import { FibonacciEllipseDataCacheTool } from './Tools/FibonacciEllipseDataCacheTool';
import { FibonacciExpansionDataCacheTool } from './Tools/FibonacciExpansionDataCacheTool';
import { FibonacciFansDataCacheTool } from './Tools/FibonacciFansDataCacheTool';
import { FibonacciPhiChannelDataCacheTool } from './Tools/FibonacciPhiChannelDataCacheTool';
import { FibonacciRetracementDataCacheTool } from './Tools/FibonacciRetracementDataCacheTool';
import { FibonacciSpiralDataCacheTool } from './Tools/FibonacciSpiralDataCacheTool';
import { FibonacciTimeGoalDataCacheTool } from './Tools/FibonacciTimeGoalDataCacheTool';
import { FibonacciTimeZoneDataCacheTool } from './Tools/FibonacciTimeZoneDataCacheTool';
import { GannFanDataCacheTool } from './Tools/GannFanDataCacheTool';
import { GannGridDataCacheTool } from './Tools/GannGridDataCacheTool';
import { GannLineDataCacheTool } from './Tools/GannLineDataCacheTool';
import { HorizontalLineDataCacheTool } from './Tools/HorizontalLineDataCacheTool';
import { InfoLineDataCacheTool } from './Tools/InfoLineDataCacheTool';
import { LineDataCacheTool } from './Tools/LineDataCacheTool';
import { PolygonDataCacheTool } from './Tools/PolygonDataCacheTool';
import { PriceChannelDataCacheTool } from './Tools/PriceChannelDataCacheTool';
import { PriceLabelDataCacheTool } from './Tools/PriceLabelDataCacheTool';
import { RectangleDataCacheTool } from './Tools/RectangleDataCacheTool';
import { RegressionChannelDataCacheTool } from './Tools/RegressionChannelDataCacheTool';
import { SymbolDataCacheTool } from './Tools/SymbolDataCacheTool';
import { TextDataCacheTool } from './Tools/TextDataCacheTool';
import { TriangleDataCacheTool } from './Tools/TriangleDataCacheTool';
import { VerticalLineDataCacheTool } from './Tools/VerticalLineDataCacheTool';

export class DataCacheToolFactory {
    public static Create (toolType: DataCacheToolType, dataCache): any {
        let tool = null;

        switch (toolType) {
        // case DataCacheToolType.Text:
        //     tool = new TextDataCacheTool();
        //     break;
        // case DataCacheToolType.Arrow:
        //     tool = new ArrowDataCacheTool();
        //     break;
        case DataCacheToolType.Symbol:
            tool = new SymbolDataCacheTool();
            break;
        case DataCacheToolType.InfoLine:
            tool = new InfoLineDataCacheTool();
            break;
        case DataCacheToolType.ArrowLabel:
            tool = new ArrowLabelDataCacheTool();
            break;
        case DataCacheToolType.CommentsLabel:
            tool = new TextDataCacheTool();
            break;
        case DataCacheToolType.PriceLabel:
            tool = new PriceLabelDataCacheTool();
            break;
        case DataCacheToolType.GannGrid:
            tool = new GannGridDataCacheTool();
            break;
        case DataCacheToolType.GannFan:
            tool = new GannFanDataCacheTool();
            break;
        case DataCacheToolType.GannLine:
            tool = new GannLineDataCacheTool();
            break;
        case DataCacheToolType.ElliottImpulse:
        case DataCacheToolType.ElliottCorrection:
        case DataCacheToolType.ElliottTriangle:
        case DataCacheToolType.ElliottTripleCombo:
        case DataCacheToolType.ElliottDoubleCombo:
            tool = new ElliotWavesDataCacheTool(toolType);
            break;
        case DataCacheToolType.FibonacciTimeZone:
            tool = new FibonacciTimeZoneDataCacheTool();
            break;
        case DataCacheToolType.FibonacciTimeGoal:
            tool = new FibonacciTimeGoalDataCacheTool();
            break;
        case DataCacheToolType.FibonacciPhiChannel:
            tool = new FibonacciPhiChannelDataCacheTool();
            break;
        case DataCacheToolType.FibonacciFans:
            tool = new FibonacciFansDataCacheTool();
            break;
        case DataCacheToolType.FibonacciEllipse:
            tool = new FibonacciEllipseDataCacheTool();
            break;
        case DataCacheToolType.FibonacciSpiral:
            tool = new FibonacciSpiralDataCacheTool();
            break;
        case DataCacheToolType.FibonacciArc:
            tool = new FibonacciArcDataCacheTool();
            break;
        case DataCacheToolType.FibonacciExpansion:
            tool = new FibonacciExpansionDataCacheTool();
            break;
        case DataCacheToolType.FibonacciRetracement:
            tool = new FibonacciRetracementDataCacheTool();
            break;
        case DataCacheToolType.PriceChannel:
            tool = new PriceChannelDataCacheTool();
            break;
        case DataCacheToolType.RegressionChannel:
            tool = new RegressionChannelDataCacheTool();
            break;
        case DataCacheToolType.AndrewsPitchFork:
            tool = new AndrewsPitchforkDataCacheTool();
            break;
        case DataCacheToolType.Ellipse:
            tool = new EllipseDataCacheTool();
            break;
        case DataCacheToolType.Circle:
            tool = new CircleDataCacheTool();
            break;
        case DataCacheToolType.VerticalLine:
            tool = new VerticalLineDataCacheTool();
            break;
        case DataCacheToolType.HorizontalLine:
            tool = new HorizontalLineDataCacheTool();
            break;
        case DataCacheToolType.Line:
            tool = new LineDataCacheTool();
            break;

        case DataCacheToolType.Rectangle:
            tool = new RectangleDataCacheTool();
            break;
        case DataCacheToolType.Triangle:
            tool = new TriangleDataCacheTool();
            break;
        case DataCacheToolType.Polygon:
            tool = new PolygonDataCacheTool();
            break;
            // case DataCacheToolType.LineByAngle:
            //     tool = new LineAngleDataCacheTool();
            //     break;
            // case DataCacheToolType.Label:
            //     tool = new LabelDataCacheTool();
            //     break;

            // case DataCacheToolType.ABCPatern:
            //     tool = new ABCPaternDataCacheTool();
            //     break;
            // case DataCacheToolType.PPZ:
            //     tool = new PPZDataCacheTool();
            //     break;
            // case DataCacheToolType.TopBottomPattern:
            //     tool = new TopBottomPatternDataCacheTool();
            //     break;
            // case DataCacheToolType.ButterflyGartley:
            //     tool = new ButterflyGartleyDataCacheTool();
            //     break;
        }
        tool.DataCache = dataCache;
        if (tool != null) {
            tool.LoadSchemeMyProperties();
        }

        return tool;
    }

    public static Clone (dataCacheTool): any {
        const toolCpy = DataCacheToolFactory.Create(dataCacheTool.ToolType, dataCacheTool.DataCache);
        toolCpy.callBack(dataCacheTool.Properties());
        return toolCpy;
    }
}
