// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Point, Rectangle } from '../../Commons/Geometry';
import { Cursors } from '../../Commons/Cursors';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { Pen } from '../../Commons/Graphics';
import { MouseButtons } from '../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartUtils } from '../TerceraChartUtils';
import { type TerceraChartBase } from '../TerceraChartBase';

export class TerceraChartScalingByRectangleRenderer extends TerceraChartBaseRenderer {
    Active: boolean;
    lastMouseDown: Point;
    lastMouseMove: Point;
    pen: Pen;

    constructor (chart: TerceraChartBase) {
        super(chart);

        this.Active = false;
        this.lastMouseDown = new Point();
        this.lastMouseMove = new Point();
        this.chart = chart;
        this.pen = new Pen('red', 1);
        this.assignLayer = LayersEnum.CrossHair;
        this.SetClassName('TerceraChartScalingByRectangleRenderer');
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null) {
        if (this.Active && !this.lastMouseDown.IsEmpty()) {
            const rect = this.CurrentRect();
            gr.DrawRect(this.pen, rect.X, rect.Y, rect.Width, rect.Height);
        }
    }

    ProcessMouseDown (e: any) {
        if (e.Button != MouseButtons.Left) { return super.ProcessMouseDown(e); }

        this.lastMouseDown = e.Location;

        if (this.Active && e.window?.ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
            return true;
        } else {
            return super.ProcessMouseDown(e);
        }
    }

    CurrentRect () {
        if (this.lastMouseDown.IsEmpty() || this.lastMouseMove.IsEmpty()) { return Rectangle.Empty(); }

        return TerceraChartUtils.AbsRectangle(new Rectangle(
            this.lastMouseDown.X,
            this.lastMouseDown.Y,
            this.lastMouseMove.X - this.lastMouseDown.X,
            this.lastMouseMove.Y - this.lastMouseDown.Y
        ));
    }

    ProcessMouseMove (e: any) {
        if (this.Active && e.window?.ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
            this.lastMouseMove = e.Location;
            e.NeedRedraw = LayersEnum.CrossHair;
            return true;
        } else {
            if (this.lastMouseDown.IsEmpty()) { this.lastMouseMove = Point.Empty(); }

            return super.ProcessMouseMove(e);
        }
    }

    ProcessMouseUp (e: any) {
        const scalingRect = this.CurrentRect();
        const startPoint = this.lastMouseDown;

        this.lastMouseDown = Point.Empty();
        this.lastMouseMove = Point.Empty();

        if (this.Active && e.window?.ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
            if (!scalingRect.IsEmpty() && scalingRect.Width > 1 && scalingRect.Height > 1) {
                this.chart.ZoomToRectangle(scalingRect, startPoint);
            }
            return true;
        } else {
            return super.ProcessMouseUp(e);
        }
    }

    ProcessMouseLeave (e: any) {
        super.ProcessMouseLeave(e);

        this.lastMouseDown = Point.Empty();
        this.lastMouseMove = Point.Empty();
    }

    GetCursor (e: any) {
        if (this.Active && e.window?.ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
            for (let i = 0, len = this.chart.windowsContainer.Windows.length; i < len; i++) {
                if (this.chart.windowsContainer.Windows[i].ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
                    return Cursors.Cross;
                }
            }
        }
        return null;
    }
}
