// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolRayType, DataCacheToolType } from '../DataCacheToolEnums';
import { Color } from '../../../Commons/Graphics';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty, ColorStyleWidth } from '../../DynProperty';
import { EllipseDataCacheTool } from './EllipseDataCacheTool';

export class PriceChannelDataCacheTool extends EllipseDataCacheTool {
    /**
     * Constructor for PriceChannelDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.PriceChannel;
        this.InitPoints(3);
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 3;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 3;
    }

    override AdditionalPointsCount (): number {
        return 0;
    }

    /**
     * Gets the properties of the tool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props: DynProperty[] = super.Properties();

        const leftRayProp = new DynProperty('Left ray', this.RayType === DataCacheToolRayType.LeftRay || this.RayType === DataCacheToolRayType.DoubleRay, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        leftRayProp.sortIndex = 0;
        props.push(leftRayProp);

        const rightRayProp = new DynProperty('Right ray', this.RayType === DataCacheToolRayType.RightRay || this.RayType === DataCacheToolRayType.DoubleRay, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        rightRayProp.sortIndex = 1;
        props.push(rightRayProp);

        const colorStyleWidthRays = new ColorStyleWidth(Color.Empty, this.styleRay, 1);
        colorStyleWidthRays.enableStyleOnly();
        props.push(new DynProperty('Ray style', colorStyleWidthRays, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP));

        return props;
    }

    /**
     * Callback function for handling property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const styleProp = DynProperty.getPropertyByName(properties, 'Ray style');
        if (styleProp != null) this.StyleRay = styleProp.value.Style;

        this.RayType = DataCacheTool.GetRayType(properties);
    }
}
