// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type EntitlementProductRequestType, type EntitlementProductRequestStatus } from '../../Commons/cache/Entitlement/EntitlementPrimitives';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectEntitlementProductSubscriptionResponseMessage extends DirectMessageBaseClass {
    public Id = -1;
    public ReqType: EntitlementProductRequestType | null = null;
    public ReqStatus: EntitlementProductRequestStatus | null = null;
    public ReqTime: number | null = null;
    public ResponseEnd: boolean | null = null;
    public ErrorCode: number | null = null;
    public Comment: string | null = null;

    constructor () {
        super(Message.CODE_PFIX_PRODUCT_SUBSCRIPTION_MANAGEMENT_RESP);
    }
}
