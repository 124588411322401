// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DateTimeConvertor } from '@shared/utils/Time/DateTimeConvertor';
import { OrderExecutionType } from '@shared/utils/Trading/OrderExecutionType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { ReportManager } from '@shared/utils/Managers/ReportManager';
import { OrderFormatter } from '../../cache/Formatters/OrderFormatter';
import { type OrderHistory } from '../../cache/OrderHistory';
import { TvDurationConvertor } from '../Convertors/TvDurationConvertor';
import { TvOrderConvertor } from '../Convertors/TvOrderConvertor';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { type Order, OrderStatus as tvOrderStatus, type OrderDuration, ParentType } from '../charting_library';
import { ReportType } from '../../cache/AllowedReport';

export class TvOrdersHistoryManager {
    private fakeParentId: number = 1;

    public async ordersHistory (): Promise<Order[]> {
        const startTime = new Date(0).getTime();
        const finishTime = new Date().setHours(23, 59, 59);
        const account = TvAccountHelper.getCurrentAccount();
        const login = account?.FullAccString;
        ReportManager.reportsByComplited.set(ReportType.REPORT_ORDERBOOK, false);
        const rOrdersHistory: OrderHistory[] = await ReportManager.sendRequestByDate(ReportType.REPORT_ORDERBOOK, startTime, finishTime, login);

        const allowedOrdersTypes = TvDurationConvertor.getAllAlowedOrderTypes();
        const protectedOrderTypes = TvDurationConvertor.getProtectedOrderTypes();

        const tvOrdersHistory: Order[] = [];
        for (const rOrderHistory of rOrdersHistory) {
            const executionType: OrderExecutionType = rOrderHistory.eventTypeRaw;
            if (executionType !== OrderExecutionType.REFUSED &&
                executionType !== OrderExecutionType.FILLED &&
                executionType !== OrderExecutionType.CANCELED) {
                continue;
            }
            if (rOrderHistory.Account !== account) {
                continue;
            }

            // TODO: Why only filled orders?

            if (!allowedOrdersTypes.includes(rOrderHistory.OrderType) &&
                !protectedOrderTypes.includes(rOrderHistory.OrderType)) continue;

            const rOrderType = rOrderHistory.OrderType;
            const orderType = TvOrderConvertor.getTvOrderType(rOrderType);

            const tvOrder: Order = {
                id: rOrderHistory.FOrderNumber,
                symbol: this.getSymbol(rOrderHistory),
                status: this.getTvOrderStatus(rOrderHistory.eventTypeRaw),
                type: orderType,
                side: TvOrderConvertor.getTvOrderSide(rOrderHistory.BuySell),
                qty: rOrderHistory.Quantity,
                duration: this.getTvOrderDuration(rOrderHistory),
                updateTime: DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(rOrderHistory.Date).getTime(),
                filledQty: rOrderHistory.FFilledQuantity
            };

            if (protectedOrderTypes.includes(rOrderType)) {
                tvOrder.parentId = this.fakeParentId++;
                tvOrder.parentType = ParentType.Position;
            }

            if (rOrderType === OrderType.Market) {
                tvOrder.limitPrice = rOrderHistory.Price;
            } else if (rOrderType === OrderType.Limit || rOrderType === OrderType.SLTPLimit) {
                tvOrder.limitPrice = rOrderHistory.Price;
            } else if (rOrderType === OrderType.Stop || rOrderType === OrderType.SLTPStop) {
                tvOrder.stopPrice = rOrderHistory.Price;
                tvOrder.stopType = 0; // StopType.Stop
            } else if (rOrderType === OrderType.StopLimit) {
                tvOrder.limitPrice = rOrderHistory.Price;
                tvOrder.stopPrice = rOrderHistory.StopLimit;
            } else if (rOrderType === OrderType.TRStop) {
                tvOrder.stopPrice = rOrderHistory.Price;
                tvOrder.stopType = 1; // StopType.TrailingStop
            }

            tvOrder.date = { dateOrDateTime: rOrderHistory.Date.getTime(), hasTime: true };
            const tifType = rOrderHistory.TimeInForce.type;
            const expiration = rOrderHistory.TimeInForce.expirationTime;
            tvOrder.tif = OrderFormatter.GetFormattedValidityStr(tifType, expiration);

            tvOrdersHistory.push(tvOrder);
        }
        return tvOrdersHistory;
    }

    private getTvOrderStatus (orderStatus: OrderExecutionType): tvOrderStatus {
        switch (orderStatus) {
        case OrderExecutionType.FILLED:
            return tvOrderStatus.Filled;
        case OrderExecutionType.CANCELED:
            return tvOrderStatus.Canceled;
        case OrderExecutionType.REFUSED:
        default:
            return tvOrderStatus.Rejected;
        }
    }

    private getSymbol (rOrderHistory: OrderHistory): string {
        if (!isNullOrUndefined(rOrderHistory.Instrument)) {
            return TvSymbolConvertor.getFullname(rOrderHistory.Instrument);
        }
        return rOrderHistory.InstrumentStr;
    }

    private getTvOrderDuration (rOrderHistory: OrderHistory): OrderDuration {
        const tif = rOrderHistory.TimeInForce.type;
        const expiration = rOrderHistory.TimeInForce.expirationTime;

        return TvOrderConvertor.getTvOrderDuration(tif, expiration);
    }
}
