// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { XYSeriesData } from '../../../../../Chart/Series/TerceraChartXYSeries';
import { MathUtils } from '../../../../../Utils/MathUtils';
import { VolatilityLabBasis } from './VolatilityLabBasis';
import { VolatilityLabChartData } from './VolatilityLabChartData';
import { type VolatilityLabChartDataInputParameters } from './VolatilityLabChartDataInputParameters';
import { VolatilityLabSide } from './VolatilityLabSide';

export class OptionVolatilityLab {
    private readonly _deltaPrecision: number = 5;
    private readonly _atmStrikeDelta: number = 50;
    private readonly _callsDeltaValues: number[] = [95, 90, 85, 80, 75, 70, 65, 60, 55, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5];
    private readonly _putsDeltaValues: number[] = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95];
    private readonly _putsCallsDeltaValues: number[] = [-5, -10, -15, -20, -25, -30, -35, -40, -45, 50, 45, 40, 35, 30, 25, 20, 15, 10, 5];

    public basis: VolatilityLabBasis = VolatilityLabBasis.StrikePrices;
    public side: VolatilityLabSide = VolatilityLabSide.CallsAndPuts;

    public getVolatilityChartData (inputParameters: VolatilityLabChartDataInputParameters): VolatilityLabChartData {
        const seriesChartData = this.getSeriesChartData(inputParameters);
        let priceStep = inputParameters.underlierPriceStep;
        let pricePrecision = inputParameters.underlierPricePrecision;
        if (this.basis === VolatilityLabBasis.Delta) {
            priceStep = this._deltaPrecision;
            pricePrecision = 0;
        }
        return new VolatilityLabChartData(priceStep, pricePrecision, inputParameters.underlierPrice, seriesChartData.seriesMap, seriesChartData.xScaleLegend);
    }

    private getSeriesChartData (inputParameters: VolatilityLabChartDataInputParameters): { seriesMap: Map<Date, XYSeriesData[]>, xScaleLegend: Map<number, string> } {
        const side = this.side;
        const basis = this.basis;
        const seriesMap = new Map<Date, XYSeriesData[]>();
        const xScaleLegend = new Map<number, string>();
        for (const entry of inputParameters.seriesMapInputParameters) {
            let delta = 0;
            const deltaValues = new Map<number, { str: string, values: number [] }>();
            if (basis === VolatilityLabBasis.Delta) {
                let deltaArray;
                switch (side) {
                case VolatilityLabSide.Calls:
                    deltaArray = this._callsDeltaValues;
                    break;
                case VolatilityLabSide.Puts:
                    deltaArray = this._putsDeltaValues;
                    break;
                default:
                    deltaArray = this._putsCallsDeltaValues;
                    break;
                }
                for (let i = 0; i < deltaArray.length; i++) {
                    deltaValues.set(deltaArray[i], { str: Math.abs(deltaArray[i]).toString(), values: [] });
                }
            }
            const date = entry[0];
            const minATMStrike = entry[1].minATMStrike;
            const maxATMStrike = entry[1].maxATMStrike;
            const strikesInfo = entry[1].strikesInfo;
            const optionDataSeries: XYSeriesData[] = [];
            for (let i = 0; i < strikesInfo.length; i++) {
                const strikeInfo = strikesInfo[i];
                if (side === VolatilityLabSide.Calls && !strikeInfo.callEnabled) {
                    continue;
                }
                if (side === VolatilityLabSide.Puts && !strikeInfo.putEnabled) {
                    continue;
                }

                let iv;
                if (side !== VolatilityLabSide.CallsAndPuts) {
                    const greeks = side === VolatilityLabSide.Calls ? strikeInfo.callGreeks : strikeInfo.putGreeks;
                    iv = greeks.iv * 100;
                    if (basis === VolatilityLabBasis.Delta && isValidNumber(iv)) {
                        delta = Math.abs(100 * greeks.delta);
                    }
                } else {
                    if (!isValidNumber(minATMStrike) || !isValidNumber(maxATMStrike)) {
                        continue;
                    }

                    if (strikeInfo.strikePrice > maxATMStrike) {
                        iv = strikeInfo.callGreeks.iv * 100;
                        if (basis === VolatilityLabBasis.Delta && isValidNumber(iv)) {
                            delta = 100 * strikeInfo.callGreeks.delta;
                        }
                    } else if (strikeInfo.strikePrice < minATMStrike) {
                        iv = strikeInfo.putGreeks.iv * 100;
                        if (basis === VolatilityLabBasis.Delta && isValidNumber(iv)) {
                            delta = 100 * strikeInfo.putGreeks.delta;
                        }
                    } else {
                        const ivCall = strikeInfo.callGreeks.iv * 100;
                        const ivPut = strikeInfo.putGreeks.iv * 100;
                        if (basis === VolatilityLabBasis.Delta) {
                            if (isValidNumber(ivCall)) {
                                delta = 100 * strikeInfo.callGreeks.delta;
                                delta = MathUtils.RoundToIncrement(delta, this._deltaPrecision);

                                if (delta > this._atmStrikeDelta || Math.abs(delta) < this._deltaPrecision) {
                                    continue;
                                }

                                const deltaValue = deltaValues.get(delta);
                                if (!isNullOrUndefined(deltaValue)) {
                                    deltaValue.values.push(ivCall);
                                }
                            }
                            if (isValidNumber(ivPut)) {
                                delta = 100 * strikeInfo.putGreeks.delta;
                                if (Math.abs(delta) > this._atmStrikeDelta) {
                                    continue;
                                }
                            }
                        }
                        if (isValidNumber(ivCall) && isValidNumber(ivPut)) {
                            iv = (ivCall + ivPut) / 2;
                        }
                    }
                }

                if (!isValidNumber(iv)) {
                    continue;
                }

                if (basis === VolatilityLabBasis.Delta) {
                    delta = MathUtils.RoundToIncrement(delta, this._deltaPrecision);
                    if (Math.abs(delta) < this._deltaPrecision) {
                        continue;
                    }

                    if (side === VolatilityLabSide.CallsAndPuts && delta === -this._atmStrikeDelta) {
                        delta = Math.abs(delta);
                    }

                    const deltaValue = deltaValues.get(delta);
                    if (!isNullOrUndefined(deltaValue)) {
                        deltaValue.values.push(iv);
                    }
                } else {
                    optionDataSeries.push(new XYSeriesData(strikeInfo.strikePrice, iv));
                }
            }

            if (basis === VolatilityLabBasis.Delta) {
                const keys = Array.from(deltaValues.keys());
                for (let i = 0; i < keys.length; i++) {
                    const deltaValue = deltaValues.get(keys[i]);
                    const xValue = (i + 1) * this._deltaPrecision;
                    xScaleLegend.set(xValue, deltaValue.str);
                    if (!isValidArray(deltaValue.values)) {
                        continue;
                    }
                    const yValue = deltaValue.values.reduce((acc, curr) => acc + curr, 0) / deltaValue.values.length;
                    optionDataSeries.push(new XYSeriesData(xValue, yValue));
                }
            }

            if (isValidArray(optionDataSeries)) {
                seriesMap.set(date, optionDataSeries);
            }
        }

        return {
            seriesMap,
            xScaleLegend
        };
    }
}
