// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraChartOverlayStorageRenderer } from '@front/chart/Renderers/TerceraChartOverlayStorageRenderer';
import { OverlaysManagerItem } from '../../cache/ObjectManager/OverlaysManagerItem';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { ObjectManagerPanelBase } from './ObjectManagerPanelBase';
import { type TerceraChartOverlayWindow } from '@front/chart/TerceraChartOverlayWindow';
import { TerceraChartActionEnum } from '@front/chart/TerceraChartAction';
import { type TerceraChartOverlay } from '@front/chart/TerceraChartOverlay';

export class OverlaysManagerPanel extends ObjectManagerPanelBase<OverlaysManagerItem, TerceraChartOverlay> {
    protected override readonly ChartActionVisibility: TerceraChartActionEnum = TerceraChartActionEnum.PlateOverlayVisible;
    protected override readonly ChartActionSettings: TerceraChartActionEnum = TerceraChartActionEnum.PlateOverlaySettings;
    protected override readonly ChartActionRemove: TerceraChartActionEnum = TerceraChartActionEnum.PlateOverlayRemove;
    protected override readonly ChartActionDeleteAll: TerceraChartActionEnum = TerceraChartActionEnum.Overlay;

    constructor () {
        super();
        this.Name = 'OverlaysManagerPanel';
        this.headerLocaleKey = 'chart.head.overlays';
    }

    public override getType (): PanelNames { return PanelNames.OverlaysManagerPanel; }

    public override oncomplete (): void {
        super.oncomplete();
        this.Chart.OnAddOverlay.Subscribe(this.AddItemToTable, this);
        this.Chart.OnRemoveOverlay.Subscribe(this.RemoveItemFromTable, this);
    }

    public override onteardown (): void {
        this.Chart.OnAddOverlay.UnSubscribe(this.AddItemToTable, this);
        this.Chart.OnRemoveOverlay.UnSubscribe(this.RemoveItemFromTable, this);
        super.onteardown();
    }

    public AddItemToTable (overlay: TerceraChartOverlay): void {
        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }

        quickTable.AddItem(new OverlaysManagerItem(overlay));
    }

    public override populateItemsDirect (): void {
        super.populateItemsDirect();
        const renderer = this.Chart.mainWindow.GetRenderer(TerceraChartOverlayStorageRenderer);
        this.populateItems(renderer.OverlaysWindows);
    }

    public populateItems (renderers: TerceraChartOverlayWindow[]): void {
        for (const renderer of renderers) {
            this.AddItemToTable(renderer.Overlay);
        }
    }
}
