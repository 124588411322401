// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HeatmapAlgorithmData } from '../../cache/Algorithm/Heatmap/HeatmapAlgorithmData';
import { type Instrument } from '../../cache/Instrument';
import { Resources } from '../../../Localizations/Resources';
import { type IPartialIntervalInput } from '../PartialIntervals/IPartialIntervalInput';
import { VolumeFormatter } from '../../../Utils/Volume/VolumeFormatter';

export class HeatmapData implements IPartialIntervalInput {
    private readonly instrument: Instrument;
    public heatmapAlgorithmData: HeatmapAlgorithmData;

    constructor (instrument: Instrument) {
        this.instrument = instrument;
    }

    public get exchange (): string {
        return this.instrument.TradingExchange;
    }

    public get sector (): string {
        return this.instrument.Sector;
    }

    public get industry (): string {
        return this.instrument.Industry;
    }

    public get symbol (): string {
        return this.instrument.DisplayName();
    }

    public get symbolDescription (): string {
        return this.instrument.DescriptionValue();
    }

    public get marketDataExchange (): string {
        return this.instrument.MarketDataExchange;
    }

    public get Weight (): number {
        return this.instrument.MarketCap;
    }

    public get changePercent (): number {
        const changePercent = this.heatmapAlgorithmData?.PercentChange ?? NaN;
        if (isNaN(changePercent)) {
            return NaN;
        }
        // TODO: двойная конвертация
        return parseFloat(changePercent.toFixed(2));
    }

    public get changePercentString (): string {
        const changePercent = this.heatmapAlgorithmData?.PercentChange ?? NaN;
        if (isNaN(changePercent)) {
            return Resources.getResource('general.N_A');
        }
        return `${changePercent}%`;
    }

    public get changeString (): string {
        return this.instrument.formatPrice(this.heatmapAlgorithmData?.Change ?? NaN);
    }

    public get lastPriceString (): string {
        return this.instrument.formatPrice(this.heatmapAlgorithmData?.LastPrice ?? NaN);
    }

    public get marketCapString (): string {
        return VolumeFormatter.getVolumeFormat(this.Instrument.MarketCap, true, 2);
    }

    public get Instrument (): Instrument {
        return this.instrument;
    }

    public get IntervalValue (): number {
        return this.changePercent;
    }

    public get logoAddress (): string {
        return this.instrument.LogoAddress;
    }
}
