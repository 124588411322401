// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { DirectPropertyOwner } from '../../Utils/Enums/Constants';
import { Message } from './Message';

export class DirectPropertyMessage extends DirectMessageBaseClass {
    public OwnerType = DirectPropertyOwner.Unspecified;
    public OwnerID = 0;
    public Name: string | null = null;
    public Value: any = null;

    constructor () {
        super(Message.CODE_PFIX_PROPERTY_MESSAGE);
    }
}
