// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectQuote1Message } from '../../../../Utils/DirectMessages/DirectQuote1Message';
import { type PFixFieldSet, PfixQuoteBarGroup } from '../Groups/GroupsImport';
import { DirectQuoteMessage } from '../../../../Utils/DirectMessages/DirectQuoteMessage';
import { DirectQuote2Message } from '../../../../Utils/DirectMessages/DirectQuote2Message';
import { HistoryTypeConverter } from '../../../../Utils/History/HistoryTypeConverter';

export class Quote1MessageProcessor extends MessageProcessorBase {
    public decoder: any;
    public advancedTable: any;
    public Quote1ValidDictionary: any = {};

    constructor (decoder, processor) {
        super();

        this.decoder = decoder;

        this.advancedTable = processor?.GetAvancedTable
            ? processor.GetAvancedTable()
            : null;
    }

    public override decode (fieldSet: PFixFieldSet): DirectQuoteMessage[] {
        const message = new DirectQuote1Message();

        let routeId = -1;
        let bid: number | null = null;
        let ask: number | null = null;
        let bidSize: number | null = null;
        let askSize: number | null = null;
        let hasGroup = false;

        // alexb: оптимизация - заполняем за один проход. Распологать по частоте.
        for (let i = 0, len = fieldSet.Count(); i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }
            // symbol
            // if (fixField.FieldId === FieldsFactory.FIELD_INSTRUMENT_ID)
            // {
            //    let tickId = fixField.Value
            //    message.Symbol = symDecodeMap[tickId] || ''
            // } else
            if (fixField.FieldId === FieldsFactory.FIELD_ROUTE_ID) {
                routeId = fixField.Value;
                message.Route = routeId.toString();// decoder.GetRouteById(routeId)
            } else if (fixField.FieldId === FieldsFactory.FIELD_BID) {
                bid = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_ASK) {
                ask = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_BID_VOLUME) {
                bidSize = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_ASK_VOLUME) {
                askSize = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_BID_VENUE) {
                message.BidVenue = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_ASK_VENUE) {
                message.AskVenue = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_LAST_SIZE) {
                message.LastSize = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_DATE) {
                message.cTime = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_SUB_ID) {
                message.QuoteUniqueID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_LAST_PRICE) {
                message.LastPice = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_OPEN_INTEREST) {
                message.OpenInterest = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_PREV_SETTLEMENT_PRICE) {
                message.PrevSettlementPrice = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_VOLUME_TOTAL) {
                message.TotalVolume = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_BID_SIZE) {
                bidSize = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_ASK_SIZE) {
                askSize = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_GROUP) {
                hasGroup = true;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
                message.InstrumentTradableID = fixField.Value;
            }
        }

        const Quote1ValidDictionary = this.Quote1ValidDictionary;
        let lastQuote = null;
        let dict = null;
        const quoteKey = message.InstrumentTradableID;
        // #50669
        if (bid === null || ask === null || bidSize === null || askSize === null) {
            dict = Quote1ValidDictionary[routeId] || null;
            if (dict) {
                lastQuote = dict[quoteKey] || null;
            }
        }

        message.Bid = bid !== null ? bid : (lastQuote !== null ? lastQuote.Bid : Number.NaN);
        message.Ask = ask !== null ? ask : (lastQuote !== null ? lastQuote.Ask : Number.NaN);
        message.BidSize = bidSize !== null ? bidSize : (lastQuote !== null ? lastQuote.BidSize : Number.NaN);
        message.AskSize = askSize !== null ? askSize : (lastQuote !== null ? lastQuote.AskSize : Number.NaN);

        if (hasGroup) {
            const groups = fieldSet.GetGroups(FieldsFactory.QUOTE_BAR_GROUP, PfixQuoteBarGroup);
            if (groups.length > 0) {
                let group = groups[0];
                let askGroup = groups.length > 1 ? groups[1] : null;

                if (groups.length > 1 &&
                    HistoryTypeConverter.GetLocalHistoryType(
                        group.GetValue(FieldsFactory.FIELD_BARS_TYPE)) === DirectQuoteMessage.QUOTE_ASK) {
                // на случай, когда аск записан раньше бида
                    group = groups[1];
                    askGroup = groups[0];
                }

                message.Open = group.GetValue(FieldsFactory.FIELD_OPEN);
                message.High = group.GetValue(FieldsFactory.FIELD_HIGH);
                message.Low = group.GetValue(FieldsFactory.FIELD_LOW);
                message.PrevClose = group.GetValue(FieldsFactory.FIELD_CLOSE_PRICE);
                message.BidMainClose = group.getMainClose();

                message.Ticks = group.GetValue(FieldsFactory.FIELD_TICKS);
                message.TicksPreMarket = group.GetValue(FieldsFactory.FIELD_TICKS_PRE_MARKET);
                message.TicksPostMarket = group.GetValue(FieldsFactory.FIELD_TICKS_POST_MARKET);

                message.VolumePreMarket = group.GetValue(FieldsFactory.FIELD_VOLUME_PRE_MARKET);
                message.VolumePostMarket = group.GetValue(FieldsFactory.FIELD_VOLUME_POST_MARKET);

                const fieldVolume = group.GetValue(FieldsFactory.FIELD_VOLUME);
                if (fieldVolume !== null) {
                    message.volumeTotal = fieldVolume;
                }

                if (askGroup) {
                    message.AskOpen = askGroup.GetValue(FieldsFactory.FIELD_OPEN);
                    message.AskHigh = askGroup.GetValue(FieldsFactory.FIELD_HIGH);
                    message.AskLow = askGroup.GetValue(FieldsFactory.FIELD_LOW);
                    message.AskPrevClose = askGroup.GetValue(FieldsFactory.FIELD_CLOSE_PRICE);
                    message.AskMainClose = askGroup.getMainClose();
                }
            }
        }

        // #50669
        if (Quote1ValidDictionary.hasOwnProperty(routeId)) {
            Quote1ValidDictionary[routeId][quoteKey] = message;
        } else {
            const dict = {};
            dict[quoteKey] = message;
            Quote1ValidDictionary[routeId] = dict;
        }

        let q2m = null;
        if (this.advancedTable) {
            q2m = this.advancedTable.Count ? DirectQuote2Message.GetQute2MessagesFromQuote1ByDenyTable(message, this.advancedTable) : null;
        }

        return [message, q2m];
    }
}
