// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iATR extends iBuildInIndicator {
    public FMaPeriod: any;
    public FMaMode: any;
    public FTR: ExpandDoubleVector;

    constructor (maPeriod, maMode) {
        super(1);
        this.FMaPeriod = maPeriod;
        this.FMaMode = maMode || 0;

        this.FTR = new ExpandDoubleVector();
    }

    public get Name (): string {
        return 'iATR';
    }

    public get Key (): string {
        return this.DefaultKey + this.FMaPeriod + this.FMaMode;
    }

    public Refresh (count, newThread): void {
        this.FTR.Dispose();
        this.FTR = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    };

    // TODO: Було public NextBar (callBound, isHistory), але isHistory не буде
    // передаватися в iBuildInIndicator.NextBar, бо NextBar приймає лише один параметр
    public NextBar (callBound): void {
        this.FTR.Add(0.0);
        super.NextBar(callBound);
    };

    public OnQuote (ci, callBound, callFromRefresh): void {
        const Parent = this.Parent;
        if (!Parent) { return; };
        this.FTR[this.FTR.Length - 1] = Parent.GetTrueRange(this.Count - 1);
        if (this.Count >= this.FMaPeriod) {
            this.SetValue(IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FTR, this.FMaPeriod, 1), undefined, undefined);
        }
    };

    public Dispose (): void {
        this.FTR.Dispose();
        super.Dispose();
    };
}
