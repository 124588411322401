// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode, GradientMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterBoolean } from '../InputParamaterClasses/InputParameterBoolean';

export class EMACROSS extends IndicatorScriptBase {
    public MAPeriod1: number;
    public MAPeriod2: number;
    public SourcePrice: number;
    public IsCheckedCloud: boolean;
    private fastEMA: iBuildInIndicator;
    private slowEMA: iBuildInIndicator;
    private readonly DownTrendColor: any;
    private readonly UpTrendColor: any;

    constructor () {
        super();
        this.ProjectName = 'EMA CROSS';
        this.Comments = 'EMACROSS';
        super.SetIndicatorLine('EMATrend1', Color.Blue, 2, LineStyle.SimpleChart);
        super.SetIndicatorLine('EMATrend2', Color.Yellow, 2, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.MAPeriod1 = 9;
        super.InputParameter(new InputParameterInteger('MAPeriod1', 'FIRST Period of Exponential Moving Average', 0, 1, 9999));

        this.MAPeriod2 = 26;
        super.InputParameter(new InputParameterInteger('MAPeriod2', 'Second  Period of Exponential Moving Average', 1, 1, 9999));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 2,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.IsCheckedCloud = false;
        super.InputParameter(new InputParameterBoolean('IsCheckedCloud', 'Display clouds', 3));

        this.DownTrendColor = Color.Yellow;
        this.UpTrendColor = Color.Blue;
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.SetIndexDrawBegin(0, Math.max(this.MAPeriod1, this.MAPeriod2));
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.fastEMA = this.Indicators.iMA(this.CurrentData, this.MAPeriod1, MAMode.EMA, 0, this.SourcePrice);
        this.slowEMA = this.Indicators.iMA(this.CurrentData, this.MAPeriod2, MAMode.EMA, 0, this.SourcePrice);
        return [this.fastEMA, this.slowEMA];
    };

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, Math.max(this.MAPeriod1, this.MAPeriod2));
    };

    public override GetIndicatorShortName (): string {
        return 'EMA(' + this.MAPeriod1 + ')' + ' EMA(' + this.MAPeriod2 + ')';
    };

    public override getCustomName (): string {
        return 'EMA (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count > Math.max(this.MAPeriod1, this.MAPeriod2)) {
            this.SetValue(0, 0, this.fastEMA.GetValue());
            this.SetValue(1, 0, this.slowEMA.GetValue());
        }

        if (this.IsCheckedCloud) {
            this.SetCloud(0, 0, this.slowEMA.GetValue(), this.fastEMA.GetValue(), Color.FromHexToArgb(60, this.DownTrendColor), Color.FromHexToArgb(60, this.UpTrendColor), GradientMode.Simple);
        }
    };
}
