// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MathUtils } from '@shared/utils/MathUtils';
import { OrderEditUpdateData } from '@shared/utils/Trading/OrderEditUpdateData';
import { TIF } from '@shared/utils/Trading/OrderTif';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { type PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { SlTpHolder } from '@shared/utils/Trading/SlTpHolder';
import { DataCache } from '../../DataCache';
import { ErrorInformationStorage } from '../../ErrorInformationStorage';
import { TradingOrderStatus } from '../../Trading/TradingOrderStatus';
import { type Instrument } from '../Instrument';
import { type OrderEditBase } from '../OrderParams/order-edit/OrderEditBase';
import { SavedOrder } from './SavedOrder';
import { UUID } from '@shared/utils/UUID';
import { OrderConfirmation } from '../../Trading/OrderConfirmation';
import { OrderStatus } from '@shared/utils/Trading/OrderStatus';
import { MultiplyPlaceOrderWrapper } from '../../Trading/MultiplyPlaceOrderWrapper';
import { AccountType } from '@shared/utils/Account/AccountType';

class _SavedOrdersController {
    async placeOrders (savedOrders: SavedOrder[], placedFrom: PlacedFrom): Promise<void> {
        if (MathUtils.IsNullOrUndefined(savedOrders) || savedOrders.length === 0) {
            return;
        }

        const multiOrders: MultiplyPlaceOrderWrapper = new MultiplyPlaceOrderWrapper();
        const savedOrderToSend: Record<string, SavedOrder> = {};
        for (let i = 0; i < savedOrders.length; i++) {
            const order = savedOrders[i];
            const orderEdit = this.createOrderEdit(order, false, placedFrom);
            const isConfirmed = await OrderConfirmation.processPlaceOrderConfirmationAsync(orderEdit);
            if (!isConfirmed) {
                order.Status = TradingOrderStatus.CancelledByUser;
            } else {
                if (this.ValidateOrderEditBase(orderEdit, order)) {
                    multiOrders.AddItem(order.Id, orderEdit);
                    savedOrderToSend[order.Id] = order;
                }
            }
        }
        try {
            await this.multiplyPlaceOrdersAsync(multiOrders, savedOrderToSend);
        } catch (exception) {
            ErrorInformationStorage.GetException(exception);
        } finally {
            const orderEdits = multiOrders.GetAllOrderEditsArr();
            for (const oE of orderEdits) { oE.dispose(); }
        }
    }

    createSavedOrder (orderEdit: OrderEditBase): SavedOrder {
        const savedOrder = new SavedOrder(UUID.generateUUIDv5());
        this.updateByOrderEdit(savedOrder, orderEdit);
        return savedOrder;
    }

    createOrderEdit (savedOrder: SavedOrder, isModify: boolean, placedFrom: PlacedFrom): OrderEditBase {
        const orderEdit = DataCache.OrderParameterContainer.OrderTypes[savedOrder.OrderType].createOrderEditObject({
            dataCache: DataCache
        });

        orderEdit.needSetDefaultStopPrice = false;
        orderEdit.needSetDefaultLimitPrice = false;
        orderEdit.needSetDefaultPrice = false;
        orderEdit.sltp.needSetDefaultSLTP = false;
        orderEdit.updateParameters(new OrderEditUpdateData(null, {
            placedFrom,
            account: savedOrder.Account,
            instrument: savedOrder.Instrument,
            side: savedOrder.Operation,
            tif: new TIF(savedOrder.TIF, savedOrder.TIFExpiration),
            quantity: new Quantity(savedOrder.QuantityLots, true),
            cash: savedOrder.Cash,
            isCashQtyMode: savedOrder.Cash > 0,
            productType: savedOrder.ProductType,
            leverageValue: savedOrder.Leverage,
            disclosedQuantity: new Quantity(savedOrder.DisclosedQuantityLots, true)
        }));
        const sltpHolder: SlTpHolder = new SlTpHolder();
        sltpHolder.copyFrom(savedOrder.SLTPHolder);
        orderEdit.setSLTP(sltpHolder);
        orderEdit.comment = `SavedOrder.${savedOrder.Id}`;

        if (savedOrder.OrderType === OrderType.TrailingStop) {
            const marketPrice = savedOrder.Instrument.GetMarketPrice(savedOrder.Account, savedOrder.Operation);
            const trailingStopTicks = savedOrder.StopPrice;
            const calculatedPrice = savedOrder.Instrument.CalculatePrice(marketPrice, trailingStopTicks, true);
            orderEdit.setBasePrice(calculatedPrice);
        } else {
            orderEdit.setBasePrice(savedOrder.Price);
        }

        if (orderEdit.setStopPrice !== undefined) {
            orderEdit.setStopPrice(savedOrder.StopPrice);
        }
        return orderEdit;
    }

    updateByOrderEdit (savedOrder: SavedOrder, orderEdit: OrderEditBase): void {
        const tradingData = orderEdit.getTradingData();
        const instrument: Instrument = tradingData.instrument;
        savedOrder.Account = tradingData.account;
        savedOrder.Instrument = instrument;
        savedOrder.Operation = tradingData.side;
        savedOrder.Cash = tradingData.cash;
        savedOrder.QuantityLots = Quantity.toLots(tradingData.quantity, instrument);
        savedOrder.DisclosedQuantityLots = Quantity.toLots(tradingData.disclosedQuantity, instrument);
        savedOrder.OrderType = tradingData.OrderType;
        savedOrder.TIF = tradingData.tif.type;
        savedOrder.TIFExpiration = tradingData.tif.expirationTime;
        if (!MathUtils.IsNullOrUndefined(tradingData.trailingStop)) {
            savedOrder.StopPrice = SlTpUtils.toRealValue(tradingData.trailingStop.value, instrument, tradingData.trailingStop.offsetType);
        } else {
            savedOrder.StopPrice = tradingData.stopPrice;
        }
        savedOrder.Price = tradingData.limitPrice;
        const sltpHolder: SlTpHolder = orderEdit.sltp.getRawValue(orderEdit.getTradingData());
        savedOrder.SLTPHolder.copyFrom(sltpHolder);
        savedOrder.ProductType = Number(tradingData.productType);
        savedOrder.Leverage = tradingData.leverageValue;
        savedOrder.UserComment = tradingData.userComment;
    }

    private async placeSavedOrderAsync (savedOrder: SavedOrder, orderEdit: OrderEditBase): Promise<void> {
        savedOrder.Status = TradingOrderStatus.Placing;
        const response = await DataCache.FOrderExecutor.placeOrderAsync(orderEdit);
        if (!isNullOrUndefined(response) && response.reject) {
            savedOrder.Status = TradingOrderStatus.Error;
            savedOrder.StatusError = response.Data[0][1];
        } else if (response.Status === OrderStatus.REFUSED) {
            savedOrder.Status = TradingOrderStatus.Error;
            savedOrder.StatusError = response.Comment;
        }
    }

    private async multiplyPlaceOrdersAsync (multiplyOrdersWrapper: MultiplyPlaceOrderWrapper, savedOrderToSend: Record<string, SavedOrder>): Promise<void> {
        let response = null;
        try {
            response = await DataCache.FOrderExecutor.multiplyPlaceOrdersAsync(multiplyOrdersWrapper);
        } catch (exception) {
            const ids = multiplyOrdersWrapper.GetAllOrdersId();
            for (const id of ids) {
                const savedOrder = savedOrderToSend[id];
                if (isNullOrUndefined(savedOrder)) continue;
                const reason = response[0];
                if (!isNullOrUndefined(reason) && reason.reject) {
                    savedOrder.Status = TradingOrderStatus.Error;
                    savedOrder.StatusError = reason.Data[0][1];
                } else if (reason.Status === OrderStatus.REFUSED) {
                    savedOrder.Status = TradingOrderStatus.Error;
                    savedOrder.StatusError = reason.Comment;
                }
            }
            ErrorInformationStorage.GetException(exception);
        } finally {
            console.dir(response);
        }
    }

    private ValidateOrderEditBase (orderEditBase: OrderEditBase, savedOrder: SavedOrder): boolean {
        const orderEditObj = orderEditBase;
        if (isNullOrUndefined(orderEditObj)) {
            savedOrder.Status = TradingOrderStatus.IllegalParameters;
            return false;
        }

        if (orderEditObj.account.AccountType !== AccountType.MultiAsset && (!orderEditObj.valid() || !orderEditObj.validSLTP())) {
            savedOrder.Status = TradingOrderStatus.IllegalParameters;
            return false;
        }

        savedOrder.Status = TradingOrderStatus.Placing;
        return true;
    }
}

export const SavedOrdersController = new _SavedOrdersController();
