// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class LookupDropDownShowParams {
    public x = 0;
    public y = 0;
    public items: any[] = [];
    public callBack: any = null;
    public closeCallback: any = null;
    public isMultiSelect: boolean = false;
    public selectedItem: any = null;
    public isCentered: boolean = false;
    public autoClose: boolean = true;
    public dataProvider: any = null;
    public isOptionMasterMode: boolean = false;
    public isMultiSelectMode: boolean;
    public isMultiSelectCheckboxUsing: boolean;
    public caller: any;
    public showMAM: boolean;
    public parentPanel: any;
    public isShowSelectAll: boolean;
    public showButtons: any;

    public CreateInstrumentsItems (): void {

    }
}
