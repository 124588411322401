// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraRadioButtonComponentTemplate } from '../../templates.js';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { ContainerControl } from './ContainerControl';

export class TerceraRadioButtonComponent extends ContainerControl {
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraRadioButtonComponent; }

    public override oninit (): void {
        super.oninit();
        this.on('radioBtnChange', this.onRbchange);
    }

    public onRbchange (context, rindex: number): void {
        const clickedItemIdx = rindex;
        const len = this.get('radioItems').length;
        for (let i = 0; i < len; i++) {
            void this.set('radioItems.' + i + '.checked', clickedItemIdx === i);
        }

        this.fire(RadioButtonComponentEvents.StateChange, clickedItemIdx);
    }
}

export enum RadioButtonComponentEvents {
    StateChange = 'StateChange'
}

ContainerControl.extendWith(TerceraRadioButtonComponent, {

    template: TerceraRadioButtonComponentTemplate,
    data: function () {
        return {
            radioItems: [],
            groupName: '',
            isHorisontal: false
        };
    }
});
