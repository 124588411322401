// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraDatePickerDropDown } from '../Controls/elements/DateTimePicker/TerceraDatePickerDropDown';
import { TerceraButtonsPopup } from '../Controls/elements/TerceraButtonsPopup';
import { TerceraMenu } from '../Controls/elements/TerceraMenu';
import { TerceraMenuWithOver } from '../Controls/elements/TerceraMenuWithOver';
import { TerceraMultiComboBoxPopup } from '../Controls/elements/TerceraMultiComboBoxPopup';
import { TerceraNumeric } from '../Controls/elements/TerceraNumeric';
import { PopupErrorScreen } from '../Controls/screen/PopupErrorScreen';
import { PositionExerciseRequestScreen } from '../Controls/screen/PositionExerciseRequestScreen';
import { PropertySetupScreenForIndicator } from '../Controls/screen/PropertySetupScreenForIndicator';
import { TerceraMessageBox } from '../Controls/screen/TerceraMessageBox';
import { TerceraRiskWarningMessageScreen } from '../Controls/screen/TerceraRiskWarningMessageScreen';
import { SnapshotScreen } from '../Controls/screen/SnapshotScreen';
import { TerceraBrokerMessageScreen } from '../Controls/screen/TerceraBrokerMessageScreen';
import { AlertMessageBox } from '../Controls/screen/AlertMessageBox';
import { ModifyOrderScreen } from '../Controls/screen/ModifyOrderScreen';
import { EditPositionScreen } from '../Controls/screen/EditPositionScreen';
import { WorkSpaceManager } from '../Controls/WorkSpace/WorkSpaceManager';
import { ClosePositionsMutually } from '../Controls/panels/ClosePositionsMutually';
import { ExternalScreen } from '../Controls/screen/ExternalScreen';
import { EntitlementSubscribeDocumentScreen } from '../Controls/screen/EntitlementSubscribeDocumentScreen';
import { TerceraAddOverlayScreen } from '../Controls/screen/TerceraAddOverlayScreen';
import { RactiveTooltip } from '../Controls/misc/Decorators';
import { ConfirmationScreen } from './Trading/ConfirmationScreen';

export class ApplicationHandlersInitializer {
    public static Initialize (): void {
        RactiveTooltip.tooltipHandlerInitialize();
        PopupErrorScreen.PopupErrorHandlerInitialize();
        TerceraButtonsPopup.ButtonsPopupHandlerInitialize();
        TerceraMenu.ContextMenuHandlerInitialize();
        TerceraMenuWithOver.ContextMenuHandlerInitialize();
        TerceraDatePickerDropDown.DatePickerDropDownHandlerInitialize();
        TerceraNumeric.NumericHandlerInitialize();
        TerceraMultiComboBoxPopup.MultiComboBoxPopupHandlerInitialize();
        PositionExerciseRequestScreen.PositionExerciseRequestScreenHandlerInitialize();
        PropertySetupScreenForIndicator.PropertySetupScreenForIndicatorHandlerInitialize();
        TerceraMessageBox.MessageBoxHandlerInitialize();
        ConfirmationScreen.MessageBoxHandlerInitialize();
        TerceraRiskWarningMessageScreen.RiskWarningMessageScreenHandlerInitialize();
        SnapshotScreen.SnapshotScreenHandlerInitialize();
        TerceraBrokerMessageScreen.brokerMessageScreenHandlerInitialize();
        AlertMessageBox.alertMessageScreenHandlerInitialize();
        WorkSpaceManager.workspaceManagerHandlerInitialize();
        ClosePositionsMutually.positionsMutualCloseScreenHandlerInitialize();
        ModifyOrderScreen.modifyOrderScreenHandlerInitialize();
        EditPositionScreen.editPositionScreenHandlerInitialize();
        ExternalScreen.externalScreenHandlerInitialize();
        EntitlementSubscribeDocumentScreen.entitlementSubscribeDocumentScreenHandlerInitialize();
        TerceraAddOverlayScreen.addOverlayScreenHandlerInitialize();
    }
}
