// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type InstrDateSettings } from '../Instruments/InstrDateSettings';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectNonFixedInstumentListResponseMessage extends DirectMessageBaseClass {
    public NonFixedInstrumentInfoList: NonFixedInstrumentInfo[];

    constructor () {
        super(Message.CODE_INSTRUMENT_LIST_RESPONSE);

        this.NonFixedInstrumentInfoList = [];
    }
}

export class NonFixedInstrumentInfo {
    public InstrDateSettingsList: InstrDateSettings[] | null = null;
    public ID: string | null = null;
    public Name: string | null = null;
    public InstrType = 0;
    public GroupId = 0;
    public ExchangeId = 0;
    public Underlier: string | null = null;
    public UnderlierContractTradableId: number;
    public UnderlierUnderlier: any = null;
    public PointSize = 0;
    public UseOptionDescription: boolean | null = null;
    public AllExpDateLoaded = true;
    public AllContractsLoaded = true;
    public SerieName: any = null;
    public UnderlierID: any = null;
    public Description: string | null = null;
    public Routes: number[] | null = null;
    public TradingExchange: string | null = null;
    public MarketDataExchange: string | null = null;
    public CountryId = -1;
    public UnderlierDescr: string | null = null;
    public UnderlierCountryId: number | null = null;
    public UnderlierTradingExchange: string | null = null;
    public InstrumentTradableID: number;
    public LanguageAliases: any;

    public toString (): string {
        return this.Name || 'null';
    }
}
