// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Point } from '@shared/commons/Geometry';
import { ChartMath } from '../Utils/ChartMath';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { DataCacheToolRayType } from '@shared/commons/cache/DataCacheToolEnums';
import { SolidBrush } from '@shared/commons/Graphics';
import { type DataCacheTool } from '@shared/commons/cache/DataCacheTool';
import { type DynProperty } from '@shared/commons/DynProperty';
import { type TerceraChartCashItemSeries } from '../Series/TerceraChartCashItemSeries';
import { Selection, SelectionState } from './Selection';

export abstract class ToolView<TDataCacheTool extends DataCacheTool = DataCacheTool> {
    /// <summary>
    /// base and additional. dataCacheTool.PointLevel - less is base, more is additional
    /// </summary>
    public refreshHandler?: () => void;

    public screenPoints: number[][]; // int[][]
    public pointsLabel = {}; // Object: { '<screenPointIndex>': textNearPoint }

    public movingType = MovingType.All;
    public excludedOuterActionTypes: ActionType[] = [];
    public dataCacheTool: TDataCacheTool;
    public CurrentSelection: Selection;
    public CurrentMovement: Movement;
    public isDisposed: boolean;

    constructor (dataCacheTool: TDataCacheTool) {
        this.dataCacheTool = dataCacheTool;
        this.CurrentSelection = this.CreateSelection();
        this.CurrentMovement = this.CreateMovement();
        this.InitPoints(dataCacheTool.PointLevel() + dataCacheTool.AdditionalPointsCount());

        this.Localize();
    }

    public CreateSelection (): Selection {
        return new Selection();
    }

    public CreateMovement (): Movement {
        return new Movement();
    }

    public InitPoints (level): void {
        this.screenPoints = Array(level);
        for (let i = 0; i < this.screenPoints.length; i++) {
            this.screenPoints[i] = Array(2);
        }
    }

    /// <summary>
    ///
    /// </summary>
    public Draw (gr, ww, param): void {
    // Ловим ошибки
        if (this.screenPoints[0][0] >= ProMath.infinity || this.screenPoints[0][1] >= ProMath.infinity || this.screenPoints[0][0] <= ProMath.infinityMinus || this.screenPoints[0][1] <= ProMath.infinityMinus) {
            return;
        }

        if (this.CurrentSelection.SelectedPointNo > -1) {
        // ++ все точки подсвечиваем
            for (let i = 0; i < this.dataCacheTool.PointLevel(); i++) {
                this.DrawSelectedPoint(gr, ww, this.screenPoints[i][0], this.screenPoints[i][1]);
            }
        } else if (this.CurrentSelection.CurrentState !== SelectionState.None) {
            this.DrawSelection(gr, ww, param);
        }
    }

    public DrawSelectedPoint (gr, ww, x: number, y: number): void {
        if (x >= ProMath.infinity || y >= ProMath.infinity || x <= ProMath.infinityMinus || y <= ProMath.infinityMinus) {
            return;
        }

        const brush = new SolidBrush(this.dataCacheTool.Color);
        gr.FillEllipse(brush, x - 3, y - 3, 6, 6);
    }

    /// <summary>
    /// Отрисовка выделения точки
    /// </summary>
    public DrawSelectedPoint2Params (gr, ww): void {
        this.DrawSelectedPoint(gr, ww, this.screenPoints[this.CurrentSelection.SelectedPointNo][0], this.screenPoints[this.CurrentSelection.SelectedPointNo][1]);
    }

    public DrawSelectedLine (gr, ww, x1: number, y1: number, x2: number, y2: number): void {
        gr.DrawLine(this.dataCacheTool.PenHighlight, x1, y1, x2, y2);
        gr.DrawLine(this.dataCacheTool.PenHalo, x1, y1, x2, y2);
    }

    public DrawSelectedLines (gr, ww, param): void {
        for (let firstPointIdx = 0; firstPointIdx < this.dataCacheTool.PointLevel(); firstPointIdx++) {
            const secondPointIdx = firstPointIdx + 1 < this.dataCacheTool.PointLevel() ? firstPointIdx + 1 : 0;

            if (!param || param.isNeedConnectFirstAndLastPoints || secondPointIdx > 0) {
                this.DrawSelectedLine(gr, ww,
                    this.screenPoints[firstPointIdx][0], this.screenPoints[firstPointIdx][1],
                    this.screenPoints[secondPointIdx][0], this.screenPoints[secondPointIdx][1]);
            }
        }
    }

    /// <summary>
    /// Отрисовка выделения тулзы
    /// </summary>
    public DrawSelection (gr, ww, param?): void {
        this.DrawSelectedLines(gr, ww, param);
        for (let i = 0; i < this.dataCacheTool.PointLevel(); i++) {
            this.DrawSelectedPoint(gr, ww, this.screenPoints[i][0], this.screenPoints[i][1]);
        }
    }

    public CopyScreenPoints (): number[][] {
        const screenPoints = this.screenPoints;
        const len = screenPoints.length;
        const copy: number[][] = Array(len);
        for (let i = 0; i < len; i++) {
            copy[i] = screenPoints[i].slice();
        }

        return copy;
    }

    /// <summary>
    /// Рассчитать экранные координаты
    /// </summary>
    public UpdateScreenPoints (ww, cashItemSeries: TerceraChartCashItemSeries | null = null): void {
        this.UpdateScreenPointsALL(ww, cashItemSeries, true);

        //
        // callback need recalculate points
        //
        if (this.dataCacheTool.forceUpdateAdditionalPoints) {
            this.dataCacheTool.forceUpdateAdditionalPoints = false;
            this.UpdateAdditionalPoints(ww, -1, cashItemSeries);
            this.UpdateScreenPointsALL(ww, cashItemSeries, false);
        }
    }

    public UpdateScreenPointsALL (ww, cashItemSeries: TerceraChartCashItemSeries | null = null, onlyPointLevel = false): void {
        const dataType = ww.IsMainWindow && cashItemSeries?.settings != null ? cashItemSeries.settings.DataType : TerceraChartCashItemSeriesDataType.Absolute;
        const points = onlyPointLevel ? this.dataCacheTool.PointLevel() : this.dataCacheTool.Points.length;

        const temp = this.CopyScreenPoints();
        for (let i = 0; i < points; i++) {
            temp[i][0] = Math.round(ww.PointsConverter.GetScreenXbyTime(this.dataCacheTool.Points[i][0]));

            switch (dataType) {
            case TerceraChartCashItemSeriesDataType.Absolute:
                temp[i][1] = Math.round(ww.PointsConverter.GetScreenY(this.dataCacheTool.Points[i][1]));
                break;
            case TerceraChartCashItemSeriesDataType.Relative:
                temp[i][1] = Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(this.dataCacheTool.Points[i][1])));
                break;
            case TerceraChartCashItemSeriesDataType.Log:
                temp[i][1] = Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(this.dataCacheTool.Points[i][1])));
                break;
            }
        }
        this.screenPoints = temp;
    }

    public OnFinishCreationTool (): void {
        // to be overriden if necessary
    }

    /// <summary>
    /// !!
    /// </summary>
    public UpdateAdditionalPoints (ww, pointNo, cashItemSeries: TerceraChartCashItemSeries | null = null): void {
    // to be overriden if necessary
    }

    // #region Drawing Utils

    public static DrawLineWithRay (gr, ww, xx0: number, yy0: number, xx1: number, yy1: number, ray: DataCacheToolRayType, linePen, rayPen = null): void {
        gr.DrawLine(linePen, xx0, yy0, xx1, yy1);

        if (ray !== DataCacheToolRayType.NoRay) {
            const clientRect = ww.ClientRectangle;
            // из уравнения прямой через 2 точки находим где луч пересечет границы экрана
            const points: Point[] = [];
            const screen_intersect_by_ray_yy2 = (clientRect.X - xx0) * (yy1 - yy0) / (xx1 - xx0) + yy0;
            if (screen_intersect_by_ray_yy2 > clientRect.Y && screen_intersect_by_ray_yy2 < clientRect.Height) {
                points.push(new Point(clientRect.X, screen_intersect_by_ray_yy2));
            }

            const screen_intersect_by_ray_yy3 = (clientRect.Width - xx0) * (yy1 - yy0) / (xx1 - xx0) + yy0;
            if (screen_intersect_by_ray_yy3 > clientRect.Y && screen_intersect_by_ray_yy3 < clientRect.Height) {
                points.push(new Point(clientRect.Width, screen_intersect_by_ray_yy3));
            }

            const screen_intersect_by_ray_xx2 = (clientRect.Y - yy0) * (xx1 - xx0) / (yy1 - yy0) + xx0;
            if (screen_intersect_by_ray_xx2 > clientRect.X && screen_intersect_by_ray_xx2 < clientRect.Width) {
                points.push(new Point(screen_intersect_by_ray_xx2, clientRect.Y));
            }

            const screen_intersect_by_ray_xx3 = (clientRect.Height - yy0) * (xx1 - xx0) / (yy1 - yy0) + xx0;
            if (screen_intersect_by_ray_xx3 > clientRect.X && screen_intersect_by_ray_xx3 < clientRect.Width) {
                points.push(new Point(screen_intersect_by_ray_xx3, clientRect.Height));
            }

            // если есть пересечение лучем экрана
            if (points.length == 2) {
                let xx2 = 0; let xx3 = 0; let yy2 = 0; let yy3 = 0; // продолжение луча

                if (ray === DataCacheToolRayType.DoubleRay) {
                // точка с которой луч
                    xx2 = points[0].X;
                    yy2 = points[0].Y;
                    // точка в которую
                    xx3 = points[1].X;
                    yy3 = points[1].Y;
                }

                if (ray === DataCacheToolRayType.RightRay || (ray === DataCacheToolRayType.Ray && xx0 < xx1)) {
                // точка с которой луч
                    xx2 = xx0 > xx1 ? xx0 : xx1;
                    yy2 = xx0 > xx1 ? yy0 : yy1;
                    // точка в которую
                    xx3 = points[0].X > points[1].X ? points[0].X : points[1].X;
                    yy3 = points[0].X > points[1].X ? points[0].Y : points[1].Y;
                }
                if (ray === DataCacheToolRayType.LeftRay || (ray === DataCacheToolRayType.Ray && xx0 > xx1)) {
                // точка с которой луч
                    xx2 = xx0 < xx1 ? xx0 : xx1;
                    yy2 = xx0 < xx1 ? yy0 : yy1;
                    // точка в которую
                    xx3 = points[0].X < points[1].X ? points[0].X : points[1].X;
                    yy3 = points[0].X < points[1].X ? points[0].Y : points[1].Y;
                }

                if (ray === DataCacheToolRayType.Ray && xx0 === xx1) // #51411 - частный случай
                {
                    xx2 = xx0;
                    yy2 = yy0 > yy1 ? yy1 : yy0;

                    xx3 = points[0].X;
                    yy3 = yy0 > yy1 ? points[0].Y : points[1].Y;
                }

                gr.DrawLine(rayPen || linePen, xx2, yy2, xx3, yy3);
            }
        }
    }

    public static DrawTextByAngle (gr, text: string, font, brush, x0: number, y0: number, x1: number, y1: number, rotation: number): void {
        gr.save();

        gr.translate(x0, y0);
        gr.rotate(rotation * Math.PI / 180);
        gr.translate(-x0, -y0);

        const width = gr.GetTextWidth(text, font);
        gr.DrawString(text, font, brush, x1 - width, y1 - font.Height - 6);

        gr.restore();
    }

    // #endregion

    // #region Theme Changed, Localize

    public Localize (): void {
        this.dataCacheTool.Localize();
    }

    // #endregion

    // #region ICaller

    public Properties (): DynProperty[] {
        return this.dataCacheTool.Properties();
    }

    public callBack (properties): void {
        const dc = this.dataCacheTool;
        dc.callBack(properties);
        dc.forceUpdateAdditionalPoints = true;
        this.FireUpdate();
    }

    // #endregion

    public Dispose (): void {
        this.dataCacheTool = null;
        this.isDisposed = true;
    }

    /// <summary>
    /// сообщение о необходимости перерисовки
    /// </summary>
    public FireUpdate (): void {
        this.dataCacheTool.FireUpdate();
    // console.log("tool FireUpdate");
    }

    /// <summary>
    /// Является ли тулза выделенной мышью (сейчас по линиям, но можно и весь полигон)
    /// </summary>
    public IsSelectCheck (x: number, y: number): boolean {
        for (let i = 1; i < this.dataCacheTool.PointLevel(); i++) {
            if (ChartMath.CalcDistanceSqrFromPointToSection(this.screenPoints[i - 1], this.screenPoints[i], x, y) <= ToolView.TOOL_DX) {
                return true;
            }
        }

        return false;
    }

    // #region Selection

    /// <summary>
    /// допуск при выделении точки
    /// </summary>
    public static readonly POINT_DX = 4;

    /// <summary>
    /// допуск при выделении линии
    /// </summary>
    public static readonly TOOL_DX = 16; // рекомендовано POINT_DX^2

    public SelectTool (): void {
        this.CurrentSelection.CurrentState = SelectionState.Selected;
    }

    public ResetSelection (): void {
        this.CurrentSelection.CurrentState = SelectionState.None;
    }

    public IsSelect (x: number, y: number): boolean {
        const selected = this.PreSelectAction(x, y) || this.IsSelectCheck(x, y);

        this.PostSelectAction(x, y, selected);

        return selected;
    }

    /// <summary>
    /// Предварительные проверки - если вдруг тулзы скрыта и т.п. вернуть false
    /// </summary>
    public PreSelectAction (x: number, y: number): boolean {
        this.CurrentSelection.SelectedPointNo = this.FindSelectedPoint(x, y);

        if (this.CurrentSelection.CurrentState === SelectionState.Selected)
        // выделение запомнено
        {
            return true;
        }

        if (this.CurrentMovement.IsMovingRightNow) {
            return true;
        }

        if (this.CurrentSelection.SelectedPointNo >= 0)
        // выделена точка, значит и вся тулза
        {
            return true;
        }

        return null;
    }

    public PostSelectAction (x: number, y: number, isSelect: boolean): void {
        switch (this.CurrentSelection.CurrentState) {
        case SelectionState.None:
            if (isSelect) {
                this.CurrentSelection.CurrentState = SelectionState.Hovered;
                this.FireUpdate();
            }
            break;

        case SelectionState.Hovered:
            if (!isSelect) {
                this.CurrentSelection.CurrentState = SelectionState.None;
                this.FireUpdate();
            }
            break;
        }
        this.CurrentMovement.CreationState = false;
    }

    public FindSelectedPoint (x: number, y: number): number {
        for (let i = 0; i < this.dataCacheTool.PointLevel(); i++) {
            const p = this.screenPoints[i];
            if (p[0] - x < ToolView.POINT_DX && p[0] - x > -ToolView.POINT_DX && p[1] - y < ToolView.POINT_DX && p[1] - y > -ToolView.POINT_DX) {
                return i;
            }
        }
        return -1;
    }

    // #endregion

    // #region Movement

    public MinDistanceForStartMoving (): number {
        return 1;
    }

    public CheckMinDistanceForStartMoving (x: number, y: number): boolean {
        return Math.abs(this.CurrentMovement.PrevX - x) + Math.abs(this.CurrentMovement.PrevY - y) >= this.MinDistanceForStartMoving();
    }

    /// <summary>
    /// тулза выделяется (активируется). будет ли перемещение потом - неизвестно
    /// </summary>
    public ProcessActivate (x: number, y: number): boolean {
        const selectedPointNo = this.FindSelectedPoint(x, y);
        if (selectedPointNo > -1) {
            this.CurrentMovement.MovingPointNo = selectedPointNo;
            return true;
        }

        if (this.IsSelectCheck(x, y)) {
            this.CurrentMovement.PrevX = x;
            this.CurrentMovement.PrevY = y;

            this.CurrentMovement.PrevScreenPoints = null;
            this.CurrentMovement.WholeTool = true;
            return true;
        }

        return false;
    }

    /// <summary>
    /// началось тягание тулзы
    /// </summary>
    public ProcessMoveStart (x, y): void {
        this.CurrentMovement.IsMovingRightNow = true;
    }

    /// <summary>
    /// новое положение тулзы
    /// </summary>
    public ProcessNewPosition (window, x: number, y: number, cashItemSeries = null): void {
        if (window == null || this.dataCacheTool == null) {
            return;
        }

        const movingPointNo = this.CurrentMovement.MovingPointNo;
        if (movingPointNo > -1) {
            this.MoveScreenPoints(window, movingPointNo, x, y, cashItemSeries);
        } else if (this.CurrentMovement.WholeTool) {
        // ! нельзя двигать как инт и преобразовать потом в дабл, перевел смещение тулз на даблы
            if (this.CurrentMovement.PrevScreenPoints == null) {
            // точки смоздаем только если начали смещать
                const temp = this.dataCacheTool.CopyPoints();
                for (let i = 0; i < temp.length; i++) {
                    temp[i][0] = window.PointsConverter.GetScreenXbyTime(this.dataCacheTool.Points[i][0]);

                    switch (cashItemSeries.settings.DataType) {
                    case TerceraChartCashItemSeriesDataType.Absolute:
                        temp[i][1] = window.PointsConverter.GetScreenY(this.dataCacheTool.Points[i][1]);
                        break;

                    case TerceraChartCashItemSeriesDataType.Relative:
                        temp[i][1] = window.PointsConverter.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(this.dataCacheTool.Points[i][1]));
                        break;
                    case TerceraChartCashItemSeriesDataType.Log:
                        temp[i][1] = window.PointsConverter.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(this.dataCacheTool.Points[i][1]));
                        break;
                    }
                }
                this.CurrentMovement.PrevScreenPoints = temp;
                this.CurrentMovement.WholeTool = true;
            }
            const dx = x - this.CurrentMovement.PrevX;
            const dy = y - this.CurrentMovement.PrevY;
            const prevPosition = this.CurrentMovement.PrevScreenPoints;

            for (let i = 0; i < prevPosition.length; i++) {
                const point = prevPosition[i];
                this.MoveScreenPoints(window, i, point[0] + dx, point[1] + dy, cashItemSeries, false, false);
            }
        }
        this.FireUpdate();
    }

    /// <summary>
    /// Изменение координат точки №pointNo
    /// </summary>
    public MoveScreenPoints (ww, pointNo, newX: number, newY: number, cashItemSeries: TerceraChartCashItemSeries | null = null, callFireUpdate = true, callUpdateAdditionalPoints = true): void {
        if (pointNo < 0 || pointNo >= this.dataCacheTool.Points.length) {
            return;
        }

        const dataType = ww.IsMainWindow && cashItemSeries != null ? cashItemSeries.settings.DataType : TerceraChartCashItemSeriesDataType.Absolute;

        const point = this.dataCacheTool.Points[pointNo];

        if (this.movingType !== MovingType.OnlyY) {
            point[0] = ww.PointsConverter.GetDataX(newX);
        }

        let newValue = ww.PointsConverter.GetDataY(newY);

        switch (dataType) {
        case TerceraChartCashItemSeriesDataType.Relative:
            newValue = cashItemSeries.settings.relativeDataConverter.Revert(ww.PointsConverter.GetDataY(newY));
            break;
        case TerceraChartCashItemSeriesDataType.Log:
            newValue = cashItemSeries.settings.logDataConverter.Revert(ww.PointsConverter.GetDataY(newY));
            break;
        }

        point[1] = newValue;

        if (callUpdateAdditionalPoints) {
            this.UpdateAdditionalPoints(ww, pointNo, cashItemSeries);
        }

        if (callFireUpdate) {
            this.FireUpdate();
        }
    }

    /// <summary>
    /// тягание тулзы завершено (вызывается после ProcessMoveStart)
    /// </summary>
    public ProcessMoveFinish (): void {
        this.CurrentMovement.IsMovingRightNow = false;
    }

    /// <summary>
    /// выделние тулзы завершено (неважно, было ли перемещение)
    /// </summary>
    public ProcessDeactivate (): void {
        this.CurrentMovement.MovingPointNo = -1;
        this.CurrentMovement.WholeTool = false;
        this.CurrentMovement.PrevScreenPoints = null; // необязательно
    }

    public ProcessClick (e): void {
    }

    public ProcessDoubleClick (e): void {
    }

    // #endregion

    // #region MouseActions

    public OnMouseEnter (e): any {
    }

    public OnMouseMove (e): any {
    }

    public OnMouseLeave (e): any {
    }

    public GetPointLabel (pointIndex): string {
        const result = this.pointsLabel[pointIndex] || '';
        return result;
    }

    public AddPointLabel (pointIndex, labelText): string // returns old label text value if change with new text
    {
        const oldLabel = this.pointsLabel[pointIndex] || null;

        this.pointsLabel[pointIndex] = labelText;

        return oldLabel != labelText ? oldLabel : null;
    }
}

// #endregion

// #region Utils

export enum MovingType {
    All = 0,
    OnlyX = 1,
    OnlyY = 2
}

export enum ActionType {
    All = 0,
    Click = 1,
    DoubleClick = 2,
}

export class Movement {
    /// <summary>
    /// Тулза перемещается
    /// </summary>
    public WholeTool: boolean;

    /// <summary>
    /// Состояние когда тулза рисуется мышкой
    /// </summary>
    public CreationState: boolean = true;

    /// <summary>
    /// Перемещается точка N тулзы
    /// </summary>
    public MovingPointNo = -1;

    /// <summary>
    /// Когда тулза двигается физически (смещена на N пикселей)
    /// </summary>
    public IsMovingRightNow: boolean;

    // чтобы не рассчитывать перемещение через векторы, что чревато погрешностью и потерями призводительности,
    // сделаю как в старой вебке, расчет на базе координат курсора
    public PrevX = 0;
    public PrevY = 0;
    // чтоб не считать при перемещении как инты! перевел смещение тулз на даблы
    public PrevScreenPoints; // public double[][]
    public lastMousePosition; // Point
}

// #endregion

export class ProMath {
// #region Const

    public static readonly epsilon = 0.00000000001; // minimal price value
    // +/- 1 млрд. для того чтоб int.MinValue/int.MaxValue влазили
    public static readonly infinityMinus = -1 / 0.000000001; // minimal price value - !!!AlexB: now can be negative!!!
    public static readonly infinity = 1 / 0.000000001; // maximal price value

    public static readonly grFreeHand = 4; // свободное
    public static readonly grOpen = 0; // открытие
    public static readonly grClose = 1; // закрытие
    public static readonly grBid = 1; // БИД (предложение)
    public static readonly grAsk = 0; // АСК (спрос)
    public static readonly grHi = 2; // максимальное
    public static readonly grLow = 3; // минимальное
    public static readonly grHL = 4; // среднее (макс+мин)/2
    public static readonly grHLC = 5; // среднее2 (макс+мин+закр)/3
    public static readonly grHLCC = 6; // среднее3 (макс+мин+2*закр)/4
    public static readonly grZero = 7; // сервисная
    public static readonly grOne = 8; // сервисная
    public static readonly grCloseMinusOpen = 9; // сервисная
    public static readonly grCloseMinusHi = 10; // сервисная
    public static readonly grCloseMinusLow = 11; // сервисная
    public static readonly grVolume = 12; // объемы

    public static readonly grSimple = 0; // простой (скользящее среднее)
    public static readonly grExponential = 1; // экспоненциальное сколязящее среднее
    public static readonly grLinearWeighted = 2; // взвешенное
    public static readonly grModified = 3; // модифицированное

// #endregion
}
