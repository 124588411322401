// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType, SelectionModeEnum } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';

export class VerticalLineDataCacheTool extends DataCacheTool {
    /**
     * Constructor for VerticalLineDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.VerticalLine;
        this.InitPoints(1);
    }

    /**
     * Initializes the block for the tool
     */
    protected InitBlock (): void {
        super.InitBlock();
        this.SelectionMode = SelectionModeEnum.X;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 1;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 1;
    }
}
