// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '@shared/commons/DataCache';
import { Instrument } from '@shared/commons/cache/Instrument';
import { OrderTypeBaseParameters } from '@shared/commons/cache/OrderParams/order-type/OrderTypeBaseParameters';
import { Resources } from '@shared/localizations/Resources';
import { SessionOperations } from '../Enums/Constants';
import { MathUtils } from '../MathUtils';
import { NumericUtils } from '../NumericUtils';
import { DateTimeConvertor } from '../Time/DateTimeConvertor';
import { DateTimeUtils } from '../Time/DateTimeUtils';
import { DaysPerType, GeneralSymbolInfoFeatureEnum, InstrumentFeatureEnum, TradinInfoSymbolInfoFeatureEnum } from './InstrumentFeatureEnum';
import { InstrumentTradingBalance } from './InstrumentTradingBalance';
import { InstrumentTypes } from './InstrumentTypes';
import { InstrumentUtils } from './InstrumentUtils';
import { SwapType } from './SwapType';
import { TradingMode } from './TradingMode';
import { Level1Calculator } from '@shared/commons/cache/Level1Calculator';
import { TimeSpanPeriods } from '../Time/TimeSpan';
import { CommissionItem } from '@shared/commons/cache/Commissions/CommissionItem';
import { CommissionPlan } from '@shared/commons/cache/Commissions/CommissionPlan';
import { commisionSplitterArr } from '../Commission/CommisionSplitter';
import { CommissionOperationType } from '../Commission/CommissionEnums';
import { RiskPlan } from '@shared/commons/cache/RiskPlan';
import { ProductType } from './ProductType';
import { MarginTypes } from './MarginTypes';
import { PriceLimitMeasure } from './PriceLimitMeasure';
import { OrderUtils } from '../Trading/OrderUtils';
import { QuotingType } from './QuotingType';
import { type Account } from '@shared/commons/cache/Account';
import { Enum } from '../Enum';
import { SubTypes } from '../Session/SubTypes';
import { DayPeriods } from '../Session/DayPeriods';
import { SessionUtils } from '../Session/SessionUtils';

export class InstrumentFeatureHelper {
    static getGeneralInfoItems (): GeneralSymbolInfoFeatureEnum[] {
        const items: GeneralSymbolInfoFeatureEnum[] = [];
        items.push(GeneralSymbolInfoFeatureEnum.SymbolName);
        items.push(GeneralSymbolInfoFeatureEnum.SymbolDescription);
        items.push(GeneralSymbolInfoFeatureEnum.TradingExchage);
        items.push(GeneralSymbolInfoFeatureEnum.MarketDataExchange);
        items.push(GeneralSymbolInfoFeatureEnum.SymbolType);
        items.push(GeneralSymbolInfoFeatureEnum.Underlier);
        items.push(GeneralSymbolInfoFeatureEnum.DeliveryMethod);
        items.push(GeneralSymbolInfoFeatureEnum.ContactMonth);
        items.push(GeneralSymbolInfoFeatureEnum.FirstTradeDate);
        items.push(GeneralSymbolInfoFeatureEnum.NoticeDate);
        items.push(GeneralSymbolInfoFeatureEnum.SettlementDate);
        items.push(GeneralSymbolInfoFeatureEnum.TradingBalance);
        items.push(GeneralSymbolInfoFeatureEnum.StrikePrice);
        items.push(GeneralSymbolInfoFeatureEnum.MaturityDate);
        items.push(GeneralSymbolInfoFeatureEnum.FaceValue);
        items.push(GeneralSymbolInfoFeatureEnum.CouponRate);
        items.push(GeneralSymbolInfoFeatureEnum.CouponCycle);
        items.push(GeneralSymbolInfoFeatureEnum.Ytm);
        items.push(GeneralSymbolInfoFeatureEnum.AccruedInterest);
        items.push(GeneralSymbolInfoFeatureEnum.PrevPaymentDate);
        items.push(GeneralSymbolInfoFeatureEnum.NextPaymentDate);
        items.push(GeneralSymbolInfoFeatureEnum.Yield);
        items.push(GeneralSymbolInfoFeatureEnum.Asset);
        items.push(GeneralSymbolInfoFeatureEnum.ExerciseStyle);
        items.push(GeneralSymbolInfoFeatureEnum.Isin);
        items.push(GeneralSymbolInfoFeatureEnum.Industry);
        items.push(GeneralSymbolInfoFeatureEnum.Sector);
        items.push(GeneralSymbolInfoFeatureEnum.ExtSmFlag);
        return items;
    }

    static getTradingInfoItems (): TradinInfoSymbolInfoFeatureEnum[] {
        const items: TradinInfoSymbolInfoFeatureEnum[] = [];
        items.push(TradinInfoSymbolInfoFeatureEnum.TradingStatus);
        items.push(TradinInfoSymbolInfoFeatureEnum.AllowedOperations);
        items.push(TradinInfoSymbolInfoFeatureEnum.AllowedOrderTypes);
        items.push(TradinInfoSymbolInfoFeatureEnum.ProductType);
        items.push(TradinInfoSymbolInfoFeatureEnum.DeliveryStatus);
        items.push(TradinInfoSymbolInfoFeatureEnum.CurrentSession);
        items.push(TradinInfoSymbolInfoFeatureEnum.SessionStatus);
        items.push(TradinInfoSymbolInfoFeatureEnum.TradindBlockedOnSession);
        items.push(TradinInfoSymbolInfoFeatureEnum.NextHoliday);
        items.push(TradinInfoSymbolInfoFeatureEnum.QuotingCurrency);
        items.push(TradinInfoSymbolInfoFeatureEnum.LotSize);
        items.push(TradinInfoSymbolInfoFeatureEnum.ContractMultiplier);
        items.push(TradinInfoSymbolInfoFeatureEnum.MinimalLot); // group items
        items.push(TradinInfoSymbolInfoFeatureEnum.MaximumLot); // group items
        items.push(TradinInfoSymbolInfoFeatureEnum.LotStep);
        items.push(TradinInfoSymbolInfoFeatureEnum.HighLimit);
        items.push(TradinInfoSymbolInfoFeatureEnum.LowLimit);
        items.push(TradinInfoSymbolInfoFeatureEnum.NormalMarketSize);
        items.push(TradinInfoSymbolInfoFeatureEnum.MaxPositionQuantity); // group items
        items.push(TradinInfoSymbolInfoFeatureEnum.AllowShortPositions); // group items
        items.push(TradinInfoSymbolInfoFeatureEnum.AllowOvernightTrading);
        items.push(TradinInfoSymbolInfoFeatureEnum.TickSizeTickCoast); // group items
        items.push(TradinInfoSymbolInfoFeatureEnum.HighLimitWarning);
        items.push(TradinInfoSymbolInfoFeatureEnum.LowLimitWarning);
        // items.push(TradinInfoSymbolInfoFeatureEnum.InstrumentAdditionalInfo); //????
        items.push(TradinInfoSymbolInfoFeatureEnum.Etb);
        items.push(TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TRADINGUNIT);
        items.push(TradinInfoSymbolInfoFeatureEnum.EXTFIELD_PRICEQUOTEUNIT);
        items.push(TradinInfoSymbolInfoFeatureEnum.EXTFIELD_DELIVERYUNIT);
        items.push(TradinInfoSymbolInfoFeatureEnum.EXTFIELD_LOTSIZE);
        items.push(TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TENDERPERIODSTARTDATE);
        items.push(TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TENDERPERIODENDDATE);
        return items;
    }

    static getMarginRequirementsItems (currentInstrument: Instrument): Array<[string, string]> {
        let items: Array<[string, string]> = [];
        items = items.concat(this.AddMaintenanceItems(currentInstrument));
        return items;
    }

    static getFeesItems (currentInstrument: Instrument, account: Account): Array<[string, string]> {
        const items: Array<[string, string]> = [];

        const itemsForTable = [];
        if (account.CommissionPlan) {
            account.CommissionPlan.AddFeeItems(itemsForTable, currentInstrument);
        }

        const feeCurrency = CommissionItem.GetCommissionCurrency(account, currentInstrument);

        const allS = CommissionOperationType.ALL;
        const suff = CommissionItem.OperationTypeSuffix;

        for (let i = 0; i < itemsForTable.length; i++) {
            const keyArr = itemsForTable[i].split(commisionSplitterArr);
            const key = itemsForTable[i];

            const locKey = (keyArr.length == 4 && keyArr[1] != allS) ? keyArr[0] + suff[keyArr[1]] : keyArr[0]; // имя которое отображается в таблице составное: имя + CommissionOperationType, если тип ALL то скипаем вторую часть, иначе добавляем

            // if (exFeeTypeName == locKey)
            //     displayFeeTypeName = ""; // если у предыдущей строки имя такое же не показываем

            const value = CommissionPlan.TryGetCommissionDataValue(feeCurrency, key, currentInstrument, account) ?? '';
            items.push([Resources.getResource(locKey), value]);
        }

        if (currentInstrument.ShortSwapInfo !== '' && (currentInstrument.SwapBuy !== 0 || currentInstrument.SwapSell !== 0)) {
            const shortSwapInfo = Resources.getResource('InstrumentDetailsPanel.SwapDetails');
            const shortSwapInfoValue = currentInstrument.ShortSwapInfo;
            items.push([shortSwapInfo, shortSwapInfoValue]);
        }

        if (currentInstrument.SwapBuy !== 0) {
            const swapBuyName = Resources.getResource('InstrumentDetailsPanel.SwapBuy');
            const swapBuyValue = this.getSwapBuySell(currentInstrument.SwapBuy, currentInstrument.SwapType);
            items.push([swapBuyName, swapBuyValue]);
        }

        if (currentInstrument.SwapSell !== 0) {
            const swapSellName = Resources.getResource('InstrumentDetailsPanel.SwapSell');
            const swapSellValue = this.getSwapBuySell(currentInstrument.SwapSell, currentInstrument.SwapType);
            items.push([swapSellName, swapSellValue]);
        }

        const custodialFeeData = DataCache.CustodialPlans.GetCustodialDataByInstrument(account.CustodialPlanId, currentInstrument);
        if (custodialFeeData !== null) {
            const custodialFeeName = Resources.getResource('InstrumentDetailsPanel.CustodialFee');
            const custodialFeeValue = custodialFeeData.FormattedValue();
            items.push([custodialFeeName, custodialFeeValue]);
        }

        if (currentInstrument.ShortPositionInterest != null) {
            const shortPositionInterestName = Resources.getResource('InstrumentDetailsPanel.ShortPostionInterest');
            const shortPositionInterestValue = currentInstrument.ShortPositionInterest + ' %';
            items.push([shortPositionInterestName, shortPositionInterestValue]);
        }

        const historicalLongSwap = this.getHistoricalSwap(currentInstrument, 'Latest historical long swap');
        if (historicalLongSwap !== null) {
            const historicalLongSwapName = Resources.getResource('InstrumentDetailsPanel.Latest historical long swap');
            const historicalLongSwapValue = historicalLongSwap + ' ' + Resources.getResource('InstrumentDetailsPanel.HistoricalSwap.points');
            items.push([historicalLongSwapName, historicalLongSwapValue]);
        }

        const historicalShortSwap = this.getHistoricalSwap(currentInstrument, 'Latest historical short swap');
        if (historicalShortSwap !== null) {
            const historicalShortSwapName = Resources.getResource('InstrumentDetailsPanel.Latest historical short swap');
            const historicalShortSwapValue = historicalShortSwap + ' ' + Resources.getResource('InstrumentDetailsPanel.HistoricalSwap.points');
            items.push([historicalShortSwapName, historicalShortSwapValue]);
        }

        const historicalLastSwap = this.getHistoricalSwap(currentInstrument, 'Historical swap last updated on');
        if (historicalLastSwap !== null) {
            const historicalLastSwapName = Resources.getResource('InstrumentDetailsPanel.Historical swap last updated on');
            const historicalLastSwapValue = historicalLastSwap;
            items.push([historicalLastSwapName, historicalLastSwapValue]);
        }

        return items;
    }

    static getSessionItems (currentInstrument: Instrument): Array<[string, string]> {
        const items: Array<[string, string]> = [];

        const dayPeriods = currentInstrument.Session.GetTodayDayPeriods();
        for (let i = 0, len = dayPeriods.length; i < len; i++) {
            const dayPeriod = dayPeriods[i];
            const sessTypeName = this.GetTradeSessionTypeName(dayPeriod);
            const key = i.toString() + '.' + sessTypeName;
            const sessName = this.TradeSessionValue(key, currentInstrument, false, true);
            const sessionValue = this.TradeSessionValue(key, currentInstrument, true, false);
            items.push([sessName, sessionValue]);
        }
        return items;
    }

    static valideGeneralInfoItem (item: GeneralSymbolInfoFeatureEnum, instrument: Instrument): boolean {
        const locKey = this.getGeneralInfoFeatureKey(item);
        if (Resources.isHidden(locKey)) {
            return false;
        }

        switch (item) {
        case GeneralSymbolInfoFeatureEnum.Underlier:
        case GeneralSymbolInfoFeatureEnum.ContactMonth:
        case GeneralSymbolInfoFeatureEnum.FirstTradeDate:
            return instrument.InstrType === InstrumentTypes.OPTIONS ||
                    instrument.InstrType === InstrumentTypes.FUTURES ||
                    instrument.InstrType === InstrumentTypes.CFD_FUTURES;

        case GeneralSymbolInfoFeatureEnum.DeliveryMethod:
            return instrument.InstrType === InstrumentTypes.FUTURES ||
                    instrument.InstrType === InstrumentTypes.CFD_FUTURES ||
                    instrument.InstrType === InstrumentTypes.FORWARD ||
                    instrument.InstrType === InstrumentTypes.SPOT;

        case GeneralSymbolInfoFeatureEnum.LastTradeDate:
            return instrument.InstrType === InstrumentTypes.OPTIONS ||
                    instrument.InstrType === InstrumentTypes.FUTURES ||
                    instrument.InstrType === InstrumentTypes.CFD_FUTURES ||
                    instrument.InstrType === InstrumentTypes.FORWARD;

        case GeneralSymbolInfoFeatureEnum.NoticeDate:
            return instrument.InstrType === InstrumentTypes.FUTURES ||
                    instrument.InstrType === InstrumentTypes.CFD_FUTURES;

        case GeneralSymbolInfoFeatureEnum.SettlementDate:
            return !!instrument.SettlementDate;

        case GeneralSymbolInfoFeatureEnum.TradingBalance:
            return instrument.InstrType !== InstrumentTypes.INDICIES;

        case GeneralSymbolInfoFeatureEnum.StrikePrice:
            return instrument.InstrType === InstrumentTypes.OPTIONS;

        case GeneralSymbolInfoFeatureEnum.CouponRate:
        case GeneralSymbolInfoFeatureEnum.CouponCycle:
        case GeneralSymbolInfoFeatureEnum.Ytm:
        case GeneralSymbolInfoFeatureEnum.AccruedInterest:
        case GeneralSymbolInfoFeatureEnum.PrevPaymentDate:
        case GeneralSymbolInfoFeatureEnum.NextPaymentDate:
            return instrument.InstrType === InstrumentTypes.BOND;

        case GeneralSymbolInfoFeatureEnum.Yield:
            return instrument.InstrType === InstrumentTypes.TBILL;

        case GeneralSymbolInfoFeatureEnum.Asset:
            if (instrument.InstrType === InstrumentTypes.ETF ||
                    instrument.InstrType === InstrumentTypes.EQUITIES ||
                    instrument.InstrType === InstrumentTypes.EQUITIES_CFD ||
                    instrument.InstrType === InstrumentTypes.CORPORATE) {
                return instrument.Exp1 != instrument.ShortName;
            }
            return false;

        case GeneralSymbolInfoFeatureEnum.ExerciseStyle:
            return instrument.InstrType === InstrumentTypes.OPTIONS;

        case GeneralSymbolInfoFeatureEnum.Isin:
            return !!instrument.ISIN;

        case GeneralSymbolInfoFeatureEnum.FaceValue:
            return instrument.InstrType === InstrumentTypes.BOND ||
                    instrument.InstrType === InstrumentTypes.TBILL;

        case GeneralSymbolInfoFeatureEnum.MaturityDate:
            if (instrument.InstrType === InstrumentTypes.FORWARD ||
                    instrument.InstrType === InstrumentTypes.BOND ||
                    instrument.InstrType === InstrumentTypes.TBILL ||
                    instrument.InstrType === InstrumentTypes.SPOT ||
                    instrument.InstrType === InstrumentTypes.CORPORATE) {
                return !!instrument.MaturityDate;
            }
            return false;

        case GeneralSymbolInfoFeatureEnum.Asset:
            if (instrument.HideAsset) {
                return false;
            }
            return instrument.Exp1 != instrument.ShortName;

        case GeneralSymbolInfoFeatureEnum.Industry:
            if (isValidString(instrument.Industry)) { return true; }
            return isValidString(instrument.ForwardBaseInstrument?.Industry);

        case GeneralSymbolInfoFeatureEnum.Sector:
            if (isValidString(instrument.Sector)) { return true; }
            return isValidString(instrument.ForwardBaseInstrument?.Sector);

        case GeneralSymbolInfoFeatureEnum.ExtSmFlag:
            return Object.getOwnPropertyNames(instrument.GetExtSMFlags()).length > 0;

        default:
            return true;
        }
    }

    static validateTradingInfoItem (item: TradinInfoSymbolInfoFeatureEnum, instrument: Instrument): boolean {
        const locKey = this.getTradingInfoFeatureKey(item);
        if (Resources.isHidden(locKey)) {
            return false;
        }

        switch (item) {
        case TradinInfoSymbolInfoFeatureEnum.DeliveryStatus:
            return instrument.InstrType === InstrumentTypes.FUTURES ||
                    instrument.InstrType === InstrumentTypes.CFD_FUTURES ||
                    instrument.InstrType === InstrumentTypes.OPTIONS ||
                    instrument.InstrType === InstrumentTypes.SPOT;

        case TradinInfoSymbolInfoFeatureEnum.LotSize:
            return instrument.InstrType === InstrumentTypes.BOND ||
                    instrument.InstrType === InstrumentTypes.TBILL;

        case TradinInfoSymbolInfoFeatureEnum.ContractMultiplier:
            if (instrument.InstrType === InstrumentTypes.FUTURES ||
                    instrument.InstrType === InstrumentTypes.CFD_FUTURES ||
                    instrument.InstrType === InstrumentTypes.OPTIONS ||
                    instrument.InstrType === InstrumentTypes.SPOT) {
                return false;
            }
            return true;

        case TradinInfoSymbolInfoFeatureEnum.Etb:
            return instrument.Level1.GetETB() !== Level1Calculator.NAString;

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TRADINGUNIT:
            return !!instrument.GetExtTradingUnit();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_PRICEQUOTEUNIT:
            return !!instrument.GetExtPriceQuoteUnit();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_DELIVERYUNIT:
            return !!instrument.GetExtDeliveryUnit();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_LOTSIZE:
            return !!instrument.GetExtLotSize();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TENDERPERIODSTARTDATE:
            return !!instrument.GetExtTenderPeriodStartDate();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TENDERPERIODENDDATE:
            return !!instrument.GetExtTenderPeriodEndDate();

        case TradinInfoSymbolInfoFeatureEnum.ProductType:
            const apt: any = instrument.RiskSettings.RiskPlanSettings.availableProductTypes;
            if (apt.length == 1 && apt[0] == ProductType.General) { return false; }
            return true;

        case TradinInfoSymbolInfoFeatureEnum.NextHoliday:
            return !!instrument.Session.GetNextHoliday();

        case TradinInfoSymbolInfoFeatureEnum.HighLimit:
            return !isNaN(instrument.HightLimit);

        case TradinInfoSymbolInfoFeatureEnum.LowLimit:
            return !isNaN(instrument.LowLimit);

        case TradinInfoSymbolInfoFeatureEnum.HighLimitWarning:
        case TradinInfoSymbolInfoFeatureEnum.LowLimitWarning:
            return !!instrument.WarningForChangeFromLastPrice;

        default:
            return true;
        }
    }

    static getGeneralInfoFeatureString (feature: GeneralSymbolInfoFeatureEnum, currentInstrument: Instrument): string {
        switch (feature) {
        case GeneralSymbolInfoFeatureEnum.SymbolName:
            return currentInstrument.DisplayName();

        case GeneralSymbolInfoFeatureEnum.SymbolDescription:
            return currentInstrument.DescriptionValue();

        case GeneralSymbolInfoFeatureEnum.TradingExchage:
            return currentInstrument.TradingExchange;

        case GeneralSymbolInfoFeatureEnum.MarketDataExchange:
            return currentInstrument.MarketDataExchange;

        case GeneralSymbolInfoFeatureEnum.SymbolType:
            return InstrumentUtils.getInstrumentTypeStringLocalized(currentInstrument.InstrType, currentInstrument.CFD);

        case GeneralSymbolInfoFeatureEnum.Underlier:
            return currentInstrument.ForwardBaseInstrument ? currentInstrument.ForwardBaseInstrument.ShortName : '';

        case GeneralSymbolInfoFeatureEnum.DeliveryMethod:
            return currentInstrument.DeliveryMethod === Instrument.Delivery_Physical
                ? Resources.getResource('InstrumentDetailsPanel.Physically')
                : Resources.getResource('InstrumentDetailsPanel.Cash');

        case GeneralSymbolInfoFeatureEnum.ContactMonth:
            return DateTimeUtils.formatDate(currentInstrument.ContractMonthDate, 'DD.MM.YYYY');

        case GeneralSymbolInfoFeatureEnum.FirstTradeDate:
            if (currentInstrument.FirstTradeDate == DateTimeUtils._ZeroTime) { return ''; }
            return DateTimeUtils.formatDate(currentInstrument.FirstTradeDate, 'DD.MM.YYYY');

        case GeneralSymbolInfoFeatureEnum.LastTradeDate:
            return DateTimeUtils.formatDate(currentInstrument.LastTradeDate, 'DD.MM.YYYY');

        case GeneralSymbolInfoFeatureEnum.MaturityDate:
            return currentInstrument.MaturityDate
                ? DateTimeUtils.formatDate(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(currentInstrument.MaturityDate), 'DD.MM.YYYY')
                : '---';

        case GeneralSymbolInfoFeatureEnum.NoticeDate:
            if (currentInstrument.NoticeDate == DateTimeUtils._ZeroTime) { return ''; }
            return DateTimeUtils.formatDate(currentInstrument.NoticeDate, 'DD.MM.YYYY');

        case GeneralSymbolInfoFeatureEnum.SettlementDate:
            if (!currentInstrument.SettlementDate) // #46089 ===null
            { return Resources.getResource('InstrumentDetailsPanel.None'); } else { return DateTimeUtils.formatDate(currentInstrument.SettlementDate, 'DD.MM.YYYY'); }

        case GeneralSymbolInfoFeatureEnum.TradingBalance:
            if (currentInstrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_IMMEDIATE) { return Resources.getResource('InstrumentDetailsPanel.immediate'); } else if (currentInstrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_T_PLUS) { return Resources.getResource('InstrumentDetailsPanel.T+0'); } else { return ('T + ' + currentInstrument.TradingBalance.toString()); }

        case GeneralSymbolInfoFeatureEnum.Isin:
            return currentInstrument.ISIN;

        case GeneralSymbolInfoFeatureEnum.StrikePrice:
            return currentInstrument.StrikePrice.toString();

        case GeneralSymbolInfoFeatureEnum.ExerciseStyle:
            return currentInstrument.GetLocalizedExerciseStyle();

        case GeneralSymbolInfoFeatureEnum.FaceValue:
            return currentInstrument.FaceValue.toString();

        case GeneralSymbolInfoFeatureEnum.CouponCycle:
            return Instrument.FormatCouponCycle(currentInstrument.CouponCycle);

        case GeneralSymbolInfoFeatureEnum.CouponRate:
            return currentInstrument.CouponRate + ' %';

        case GeneralSymbolInfoFeatureEnum.Ytm:
            return currentInstrument.YieldRate ? currentInstrument.YieldRate + ' %' : '0 %';

        case GeneralSymbolInfoFeatureEnum.AccruedInterest:
            return currentInstrument.formatPrice(currentInstrument.AccruedInterest) + ' ' + currentInstrument.Exp2;

        case GeneralSymbolInfoFeatureEnum.PrevPaymentDate:
            return DateTimeUtils.formatDate(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(currentInstrument.PrevPaymentDate), 'DD.MM.YYYY');

        case GeneralSymbolInfoFeatureEnum.NextPaymentDate:
            return DateTimeUtils.formatDate(currentInstrument.NextPaymentDate, 'DD.MM.YYYY');

        case GeneralSymbolInfoFeatureEnum.Yield:
            return currentInstrument.YieldRate ? currentInstrument.YieldRate + ' %' : '0 %';

        case GeneralSymbolInfoFeatureEnum.Asset:
            return currentInstrument.Exp1;

        case GeneralSymbolInfoFeatureEnum.Industry:
            return currentInstrument.Industry
                ? currentInstrument.Industry
                : (currentInstrument.ForwardBaseInstrument ? currentInstrument.ForwardBaseInstrument.Industry : '');

        case GeneralSymbolInfoFeatureEnum.Sector:
            return currentInstrument.Sector
                ? currentInstrument.Sector
                : (currentInstrument.ForwardBaseInstrument ? currentInstrument.ForwardBaseInstrument.Sector : '');

        case GeneralSymbolInfoFeatureEnum.ExtSmFlag:
        default:
            return `General item ${feature} not found in InstrumentFeatureHelper::getGeneralInfoFeatureString`;
        }
    }

    static getTradingInfoFeatureString (feature: TradinInfoSymbolInfoFeatureEnum, currentInstrument: Instrument, account?: Account, productType?: ProductType): string {
        switch (feature) {
        case TradinInfoSymbolInfoFeatureEnum.TradingStatus:
            return InstrumentUtils.GetTradingStatus(currentInstrument);

        case TradinInfoSymbolInfoFeatureEnum.AllowedOperations: {
            const allowedOperations = this.GetAllowedOperations(currentInstrument);
            if ((allowedOperations != null)) { return allowedOperations; } else { return Resources.getResource('panel.watchlist.cell.Not allow'); }
        }
        case TradinInfoSymbolInfoFeatureEnum.AllowedOrderTypes: {
            const allowedOrderTypes = this.GetAllowedOrderTypes(currentInstrument);
            if ((allowedOrderTypes != null)) { return allowedOrderTypes; } else { return Resources.getResource('panel.watchlist.cell.Not allow'); }
        }
        case TradinInfoSymbolInfoFeatureEnum.ProductType: {
            const availableProductTypes = currentInstrument.RiskSettings.RiskPlanSettings.availableProductTypes;
            if (availableProductTypes.length === 2 && productType == null) { return Resources.getResource('ProductType.Intraday') + '/' + Resources.getResource('ProductType.Delivery'); } else {
                if (productType == null) productType = availableProductTypes[0];

                return productType != null
                    ? InstrumentUtils.GetLocalizedProductType(currentInstrument, productType)
                    : '';
            }
        }

        case TradinInfoSymbolInfoFeatureEnum.DeliveryStatus:
            return currentInstrument.LastTradeDate < DateTimeUtils.DateTimeUtcNow()
                ? '---' // GetDeliveryStatus(currentInstrument.DeliveryStatus, sett) ???????????????
                : '---';

        case TradinInfoSymbolInfoFeatureEnum.CurrentSession:
            return currentInstrument.ExchangeSessionName;

        case TradinInfoSymbolInfoFeatureEnum.SessionStatus: {
            const tradeSessionStatus = DataCache.GetTradeSessionStatus(currentInstrument.GetTradeSessionStatusId());
            return tradeSessionStatus != null ? tradeSessionStatus.Name : '';
        }
        case TradinInfoSymbolInfoFeatureEnum.NextHoliday: {
            const holiday = currentInstrument.Session.GetNextHoliday();
            return holiday != null ? holiday.GetFormattedDate() + ' (' + holiday.name + ')' : '';
        }
        case TradinInfoSymbolInfoFeatureEnum.QuotingCurrency:
            return currentInstrument.Exp2;

        case TradinInfoSymbolInfoFeatureEnum.LotSize:
            if (currentInstrument.InstrType === InstrumentTypes.FUTURES ||
                    currentInstrument.InstrType === InstrumentTypes.CFD_FUTURES ||
                    currentInstrument.InstrType === InstrumentTypes.OPTIONS ||
                    (currentInstrument.InstrType === InstrumentTypes.SPREADBET /* && key.indexOf(SymbolInfoPanel.KEY_TickCoast) !== -1 */)) //! !!!
            {
                let tickCoast = currentInstrument.FuturesTickCoast;
                if (tickCoast < 0) { tickCoast = currentInstrument.LotSize * currentInstrument.PointSize; }
                // if (key.indexOf(SymbolInfoPanel.KEY_TickCoast) !== -1) {
                //     let commisionKey = key.split(SymbolInfoPanel.commisionSplitter);
                //     if (commisionKey.length == 6) {
                //         return SymbolInfoPanel.GetFeeString(commisionKey[1], commisionKey[2],
                //             commisionKey[3], SymbolInfoPanel.ConvertToBoolean(commisionKey[4]), SymbolInfoPanel.ConvertToBoolean(commisionKey[5]), sett, SymbolInfoPanel.FeeStringVariable.Price);
                //     }
                //     return tickCoast.toString();
                // }

                // if (key == SymbolInfoPanel.KEY_LotSize && currentInstrument.QuotingType == QuotingType.LotSize)
                //     return currentInstrument.LotSize.toString() + (currentInstrument.InstrType == InstrumentTypes.FOREX ? " " + currentInstrument.Exp2 : "");

                return '---';
            } else {
                // let stLotSize = currentInstrument.LotSize.toString();
                // if (currentInstrument.isForexSymbol())
                //     stLotSize += " " + currentInstrument.Exp1;
                // if (key == SymbolInfoPanel.KEY_LotSize)
                //     return stLotSize;

                // //х.з. зачем так делать, почему не прислать в мессадже тик кост если нужен
                // //double tickCoast = currentInstrument.LotSize * (double)currentInstrument.PointSize;
                // let tickCoast = currentInstrument.FuturesTickCoast;
                // if (key.indexOf(SymbolInfoPanel.KEY_TickCoast) !== -1)
                //     return tickCoast.toString();
                return '---';
            }

        case TradinInfoSymbolInfoFeatureEnum.ContractMultiplier:
            return currentInstrument.ContractMultiplier.toString() + ' ' + currentInstrument.Exp1;

        case TradinInfoSymbolInfoFeatureEnum.LotStep:
            return MathUtils.formatValueWithEps(currentInstrument.getLotStep(productType, account));

        case TradinInfoSymbolInfoFeatureEnum.HighLimit:
            return currentInstrument.Limits.HighLimitPriceByMeasureString();

        case TradinInfoSymbolInfoFeatureEnum.LowLimit:
            return currentInstrument.Limits.LowLimitPriceByMeasureString();

        case TradinInfoSymbolInfoFeatureEnum.AllowOvernightTrading:
            return this.getLocalizedAllowShortPosition(currentInstrument.RiskSettings.isOvernightTradingsAllowed());

        case TradinInfoSymbolInfoFeatureEnum.NormalMarketSize:
            return currentInstrument.Level1.getNormalMarketSize;

        case TradinInfoSymbolInfoFeatureEnum.Etb:
            return currentInstrument.Level1.GetETB();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TRADINGUNIT:
            return currentInstrument.GetExtTradingUnit();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_PRICEQUOTEUNIT:
            return currentInstrument.GetExtPriceQuoteUnit();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_DELIVERYUNIT:
            return currentInstrument.GetExtDeliveryUnit();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_LOTSIZE:
            return currentInstrument.GetExtLotSize();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TENDERPERIODSTARTDATE:
            return currentInstrument.GetExtTenderPeriodStartDate();

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TENDERPERIODENDDATE:
            return currentInstrument.GetExtTenderPeriodEndDate();

        case TradinInfoSymbolInfoFeatureEnum.TradindBlockedOnSession:
            if (currentInstrument.Session.BlockTrading || currentInstrument.Session.CurrentSessionPeriod == null) {
                return Resources.getResource('InstrumentDetailsPanel.Yes');
            } else {
                return Resources.getResource('InstrumentDetailsPanel.No');
            }

            // group items
        case TradinInfoSymbolInfoFeatureEnum.MinimalLot: // addMinimalLotItems
        case TradinInfoSymbolInfoFeatureEnum.MaximumLot: // addMaximalLotItems
        case TradinInfoSymbolInfoFeatureEnum.MaxPositionQuantity: // addMaxPositionQtyItems
        case TradinInfoSymbolInfoFeatureEnum.AllowShortPositions:// addMaxPositionQtyItems
        case TradinInfoSymbolInfoFeatureEnum.TickSizeTickCoast:// addTickSizeTickCoastItems
        case TradinInfoSymbolInfoFeatureEnum.HighLimitWarning:
        case TradinInfoSymbolInfoFeatureEnum.LowLimitWarning:
        default:
            return `Trading item ${feature} not found in InstrumentFeatureHelper::getGeneralInfoFeatureString`;
        }
    }

    static isTradingInfoLongItem (feature: TradinInfoSymbolInfoFeatureEnum): boolean {
        switch (feature) {
        case TradinInfoSymbolInfoFeatureEnum.MinimalLot:
        case TradinInfoSymbolInfoFeatureEnum.MaximumLot:
        case TradinInfoSymbolInfoFeatureEnum.MaxPositionQuantity:
        case TradinInfoSymbolInfoFeatureEnum.AllowShortPositions:
        case TradinInfoSymbolInfoFeatureEnum.TickSizeTickCoast:
            return true;

        default:
            return false;
        }
    }

    static getTradingInfoFeatureItems (feature: TradinInfoSymbolInfoFeatureEnum, currentInstrument: Instrument): Array<[string, string]> {
        const items: Array<[string, string]> = [];

        if (!this.isTradingInfoLongItem(feature)) {
            return items;
        }

        if (feature === TradinInfoSymbolInfoFeatureEnum.TickSizeTickCoast) {
            return this.AddTickSizeCostItems(currentInstrument);
        }

        return this.ProcessItemByProductType(feature, currentInstrument);
    }

    static getGeneralInfoFeatureLocalization (feature: GeneralSymbolInfoFeatureEnum): string {
        return Resources.getResource(InstrumentFeatureHelper.getGeneralInfoFeatureKey(feature));
    }

    private static getGeneralInfoFeatureKey (feature: GeneralSymbolInfoFeatureEnum): string {
        switch (feature) {
        case GeneralSymbolInfoFeatureEnum.SymbolName:
            return 'InstrumentDetailsPanel.Symbol';

        case GeneralSymbolInfoFeatureEnum.SymbolDescription:
            return 'InstrumentDetailsPanel.Description';

        case GeneralSymbolInfoFeatureEnum.TradingExchage:
            return 'InstrumentDetailsPanel.ExchangeTrading';

        case GeneralSymbolInfoFeatureEnum.MarketDataExchange:
            return 'InstrumentDetailsPanel.ExchangeMarketData';

        case GeneralSymbolInfoFeatureEnum.SymbolType:
            return 'InstrumentDetailsPanel.AssetClass';

        case GeneralSymbolInfoFeatureEnum.Underlier:
            return 'InstrumentDetailsPanel.Underlier';

        case GeneralSymbolInfoFeatureEnum.DeliveryMethod:
            return 'InstrumentDetailsPanel.DeliveryMethod';

        case GeneralSymbolInfoFeatureEnum.ContactMonth:
            return 'InstrumentDetailsPanel.ContactMonth';

        case GeneralSymbolInfoFeatureEnum.FirstTradeDate:
            return 'InstrumentDetailsPanel.FirstTradeDate';

        case GeneralSymbolInfoFeatureEnum.LastTradeDate:
            return 'InstrumentDetailsPanel.LastTradeDate';

        case GeneralSymbolInfoFeatureEnum.MaturityDate:
            return 'InstrumentDetailsPanel.MaturityDate';

        case GeneralSymbolInfoFeatureEnum.NoticeDate:
            return 'InstrumentDetailsPanel.NoticeDate';

        case GeneralSymbolInfoFeatureEnum.SettlementDate:
            return 'InstrumentDetailsPanel.SettlementDate';

        case GeneralSymbolInfoFeatureEnum.TradingBalance:
            return 'InstrumentDetailsPanel.TradingBalance';

        case GeneralSymbolInfoFeatureEnum.Isin:
            return 'InstrumentDetailsPanel.ISIN';

        case GeneralSymbolInfoFeatureEnum.StrikePrice:
            return 'InstrumentDetailsPanel.StrikePrice';

        case GeneralSymbolInfoFeatureEnum.ExerciseStyle:
            return 'InstrumentDetailsPanel.ExerciseStyle';

        case GeneralSymbolInfoFeatureEnum.FaceValue:
            return 'InstrumentDetailsPanel.FaceValue';

        case GeneralSymbolInfoFeatureEnum.CouponCycle:
            return 'InstrumentDetailsPanel.CouponCycle';

        case GeneralSymbolInfoFeatureEnum.CouponRate:
            return 'InstrumentDetailsPanel.CouponRate';

        case GeneralSymbolInfoFeatureEnum.Ytm:
            return 'InstrumentDetailsPanel.YTM';

        case GeneralSymbolInfoFeatureEnum.AccruedInterest:
            return 'InstrumentDetailsPanel.AccruedInterest';

        case GeneralSymbolInfoFeatureEnum.PrevPaymentDate:
            return 'InstrumentDetailsPanel.PreviousPaymentDate';

        case GeneralSymbolInfoFeatureEnum.NextPaymentDate:
            return 'InstrumentDetailsPanel.NextPaymentDate';

        case GeneralSymbolInfoFeatureEnum.Yield:
            return 'InstrumentDetailsPanel.Yield';

        case GeneralSymbolInfoFeatureEnum.Asset:
            return 'InstrumentDetailsPanel.Exp1';

        case GeneralSymbolInfoFeatureEnum.Industry:
            return 'InstrumentDetailsPanel.Industry';

        case GeneralSymbolInfoFeatureEnum.Sector:
            return 'InstrumentDetailsPanel.Sector';

        case GeneralSymbolInfoFeatureEnum.ExtSmFlag:
        default:
            return `General item ${feature} not found in InstrumentFeatureHelper::getGeneralInfoFeatureString`;
        }
    }

    static getTradingInfoFeatureLocalization (feature: TradinInfoSymbolInfoFeatureEnum): string {
        return Resources.getResource(InstrumentFeatureHelper.getTradingInfoFeatureKey(feature));
    }

    private static getTradingInfoFeatureKey (feature: TradinInfoSymbolInfoFeatureEnum): string {
        switch (feature) {
        case TradinInfoSymbolInfoFeatureEnum.TradingStatus:
            return 'InstrumentDetailsPanel.TradingStatus';

        case TradinInfoSymbolInfoFeatureEnum.AllowedOperations:
            return 'InstrumentDetailsPanel.AllowedOperations';

        case TradinInfoSymbolInfoFeatureEnum.AllowedOrderTypes:
            return 'InstrumentDetailsPanel.AllowedOrderTypes';

        case TradinInfoSymbolInfoFeatureEnum.ProductType:
            return 'InstrumentDetailsPanel.ProductType';

        case TradinInfoSymbolInfoFeatureEnum.DeliveryStatus:
            return 'InstrumentDetailsPanel.DeliveryStatus';

        case TradinInfoSymbolInfoFeatureEnum.CurrentSession:
            return 'InstrumentDetailsPanel.CurrentSession';

        case TradinInfoSymbolInfoFeatureEnum.SessionStatus:
            return 'InstrumentDetailsPanel.SessionStatus';

        case TradinInfoSymbolInfoFeatureEnum.NextHoliday:
            return 'InstrumentDetailsPanel.KEYNextHoliday';

        case TradinInfoSymbolInfoFeatureEnum.QuotingCurrency:
            return 'InstrumentDetailsPanel.QuotiongCurrency';

        case TradinInfoSymbolInfoFeatureEnum.LotSize:
            return 'InstrumentDetailsPanel.LotSize';

        case TradinInfoSymbolInfoFeatureEnum.ContractMultiplier:
            return 'InstrumentDetailsPanel.ContractMultiplier';

        case TradinInfoSymbolInfoFeatureEnum.LotStep:
            return 'InstrumentDetailsPanel.LotStep';

        case TradinInfoSymbolInfoFeatureEnum.HighLimit:
            return 'InstrumentDetailsPanel.HiLimit';

        case TradinInfoSymbolInfoFeatureEnum.LowLimit:
            return 'InstrumentDetailsPanel.LowLimit';

        case TradinInfoSymbolInfoFeatureEnum.AllowOvernightTrading:
            return 'InstrumentDetailsPanel.AllowOvernightTrading';

        case TradinInfoSymbolInfoFeatureEnum.NormalMarketSize:
            return 'InstrumentDetailsPanel.NormalMarketSize';

        case TradinInfoSymbolInfoFeatureEnum.Etb:
            return 'InstrumentDetailsPanel.ETB';

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TRADINGUNIT:
            return 'InstrumentDetailsPanel.ExtField.TradingUnit';

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_PRICEQUOTEUNIT:
            return 'InstrumentDetailsPanel.ExtField.PriceQuoteUnit';

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_DELIVERYUNIT:
            return 'InstrumentDetailsPanel.ExtField.DeliveryUnit';

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_LOTSIZE:
            return 'InstrumentDetailsPanel.ExtField.LotSize';

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TENDERPERIODSTARTDATE:
            return 'InstrumentDetailsPanel.ExtField.TenderPeriodStartDate';

        case TradinInfoSymbolInfoFeatureEnum.EXTFIELD_TENDERPERIODENDDATE:
            return 'InstrumentDetailsPanel.ExtField.TenderPeriodEndDate';

        case TradinInfoSymbolInfoFeatureEnum.TradindBlockedOnSession:
            return 'InstrumentDetailsPanel.TradindBlockedOnSession';

            // group items
        case TradinInfoSymbolInfoFeatureEnum.MinimalLot:
            return 'InstrumentDetailsPanel.MinimalLot';

        case TradinInfoSymbolInfoFeatureEnum.MaximumLot:
            return 'InstrumentDetailsPanel.MaximumLot';

        case TradinInfoSymbolInfoFeatureEnum.MaxPositionQuantity:
            return 'InstrumentDetailsPanel.MaxPositionQtyPerSymbol';

        case TradinInfoSymbolInfoFeatureEnum.AllowShortPositions:
            return 'InstrumentDetailsPanel.AllowShortPositions';

        case TradinInfoSymbolInfoFeatureEnum.TickSizeTickCoast:
            return 'InstrumentDetailsPanel.TickSize'; // ?? how to hide it? - TickSize/TickCoast/LotSize

        case TradinInfoSymbolInfoFeatureEnum.HighLimitWarning:
            return 'InstrumentDetailsPanel.HighLimitWarning';

        case TradinInfoSymbolInfoFeatureEnum.LowLimitWarning:
            return 'InstrumentDetailsPanel.LowLimitWarning';
        default:
            return `Trading item ${feature} not found in InstrumentFeatureHelper::getGeneralInfoFeatureString`;
        }
    }

    static GetInstrumentFeatureString (feature: InstrumentFeatureEnum, currentInstrument: Instrument): string {
        switch (feature) {
        case InstrumentFeatureEnum.SwapBuy:
            if (currentInstrument.SwapBuy != 0) { return this.getSwapBuySell(currentInstrument.SwapBuy, currentInstrument.SwapType); } else { return Resources.getResource('InstrumentDetailsPanel.None'); }

        case InstrumentFeatureEnum.SwapSell:
            if (currentInstrument.SwapSell != 0) { return this.getSwapBuySell(currentInstrument.SwapSell, currentInstrument.SwapType); } else { return Resources.getResource('InstrumentDetailsPanel.None'); }

        default:
            return 'Item not found in InstrumentFeatureHelper';
        }
    }

    static GetInstrumentFeatureStringWithProductType (currentInstrument: Instrument, feature: InstrumentFeatureEnum, productType): string {
        switch (feature) {
        case InstrumentFeatureEnum.MinimalLot:
            const minLot = currentInstrument.getMinLot(productType);
            return MathUtils.formatValueWithEps(minLot);

        case InstrumentFeatureEnum.MaximumLot:
            const maxLot = currentInstrument.getMaxLot(productType);
            if (maxLot > 0 && maxLot != NumericUtils.MAXVALUE) { return MathUtils.formatValueWithEps(maxLot); } else { return Resources.getResource('InstrumentDetailsPanel.None'); }

        case InstrumentFeatureEnum.MaxPositionQuantity:
            const currenRiskPlanItem = currentInstrument.RiskSettings.RiskPlanSettings.cache[productType];
            if (currenRiskPlanItem?.MaxPositionQtyPerSymbol != null && currenRiskPlanItem.MaxPositionQtyPerSymbol != -1) { return currenRiskPlanItem.MaxPositionQtyPerSymbol.toString(); } else { return Resources.getResource('InstrumentDetailsPanel.None'); }

        case InstrumentFeatureEnum.AllowShortPositions:
            return this.getLocalizedAllowShortPosition(currentInstrument.IsAllowShortPositions(productType));

        default:
            return 'Item not found in InstrumentFeatureHelper';
        }
    }

    private static GetAllowedOperations (currentInstrument: Instrument) {
        const comma = ', ';
        let allow = '';

        if (!currentInstrument) { return allow; };

        const tradingMode = currentInstrument.TradingMode;
        if (tradingMode === TradingMode.FullyOpen || tradingMode === TradingMode.LiquidationOnly) {
            const allowedOperations = Instrument.GetAllowedOperations(currentInstrument);
            const decreaseOnlyPositionCount = Instrument.IsDecreaseOnlyPositionCount(currentInstrument);

            if (allowedOperations & SessionOperations.Open &&
                this.GetAllowedOrderTypes(currentInstrument)) { allow += Resources.getResource('panel.watchlist.cell.sending') + comma; };

            if (allowedOperations & SessionOperations.Modify) { allow += Resources.getResource('panel.watchlist.cell.modify') + comma; };

            if (allowedOperations & SessionOperations.Cancel) { allow += Resources.getResource('panel.watchlist.cell.cancel') + comma; };

            if (decreaseOnlyPositionCount) { allow += Resources.getResource('InstrumentDetailsPanel.onlyCloseOrders') + comma; };
        } else if (tradingMode === TradingMode.TradingHalt) { allow = Resources.getResource('panel.watchlist.cell.cancel'); };

        if (allow.endsWith(comma)) { allow = allow.substring(0, allow.length - comma.length); };

        return allow;
    }

    private static GetAllowedOrderTypes (instrument: Instrument) {
        const comma = ', ';
        let allow = '';
        const tradingMode = instrument.TradingMode;
        if (tradingMode === TradingMode.FullyOpen ||
            tradingMode === TradingMode.TradingHalt ||
            tradingMode === TradingMode.LiquidationOnly) {
            const supportedParamObj = new OrderTypeBaseParameters(instrument);

            const OrderTypes = DataCache.OrderParameterContainer.OrderTypes;
            for (const orderType in OrderTypes) {
                const oT = OrderTypes[orderType];
                if (oT.IsSupported(supportedParamObj)) { allow += Resources.getResource(oT.localizationKey()) + comma; };
            }
        }

        if (allow.endsWith(comma)) { allow = allow.substring(0, allow.length - comma.length); };

        return allow;
    }

    private static getSwapBuySell (price, swapType): string {
        switch (swapType) {
        case SwapType.SWAP_IN_CURRENCY:
            return price + ' ' + DataCache.baseCurrency;
        case SwapType.SWAP_IN_PERCENT:
            return price + ' %';
        case SwapType.SWAP_IN_PIPS:
            return price + ' ' + Resources.getResource('general.trading.pips');
        default:
            return '';
        }
    }

    private static getLocalizedAllowShortPosition (isAllowed) {
        return Resources.getResource(isAllowed
            ? 'InstrumentDetailsPanel.Allow short positions'
            : 'InstrumentDetailsPanel.Not allow short positions');
    }

    static GetTradeSessionTypeName (dayPeriod) {
        const SESSION_DAY_PERIOD_PREOPEN: string = 'InstrumentDetailsPanel.PREOPEN';
        const SESSION_DAY_PERIOD_MAIN: string = 'InstrumentDetailsPanel.MAIN';
        const SESSION_DAY_PERIOD_POST_CLOSE: string = 'InstrumentDetailsPanel.POSTCLOSE';
        const SESSION_DAY_PERIOD_BEFORE_MARKET: string = 'InstrumentDetailsPanel.BEFORE_MARKET';
        const SESSION_DAY_PERIOD_AFTER_MARKET: string = 'InstrumentDetailsPanel.AFTER_MARKET';

        switch (dayPeriod) {
        case DayPeriods.PRE_OPEN:
            return SESSION_DAY_PERIOD_PREOPEN;
        case DayPeriods.MAIN:
            return SESSION_DAY_PERIOD_MAIN;
        case DayPeriods.POST_CLOSE:
            return SESSION_DAY_PERIOD_POST_CLOSE;
        case DayPeriods.BEFORE_MARKET:
            return SESSION_DAY_PERIOD_BEFORE_MARKET;
        case DayPeriods.AFTER_MARKET:
            return SESSION_DAY_PERIOD_AFTER_MARKET;
        }
        return '';
    }

    public static TradeSessionValue (key: string, currentInstrument: Instrument, showSessionTime: boolean, showSessionName: boolean): string {
        let sessionPeriodIndex: number = -1;
        if (key.includes('.')) {
            sessionPeriodIndex = Number(key.split('.')[0]);
        }
        return SessionUtils.GetSessionPeriodTimeForInstrumentDetails(currentInstrument.Session, sessionPeriodIndex, showSessionTime, showSessionName);
    }

    private static AddMaintenanceItems (currentInstrument: Instrument): Array<[string, string]> {
        let items: Array<[string, string]> = [];

        RiskPlan.GetAvailableProductTypes(currentInstrument).map((productType) => {
            items = items.concat(InstrumentFeatureHelper.AddMaintenanceItemsByProductType(currentInstrument, productType));
        });

        return items;
    }

    private static AddMaintenanceItemsByProductType (instrument: Instrument, productType: ProductType): Array<[string, string]> {
        let items: Array<[string, string]> = [];

        if (!RiskPlan.UseOvernightMargin(instrument, productType) && !RiskPlan.UseLongShortMargin(instrument, productType)) {
            items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_Margin, instrument, productType));
        } else if (RiskPlan.UseOvernightMargin(instrument, productType) && !RiskPlan.UseLongShortMargin(instrument, productType)) {
            items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_MarginDay, instrument, productType));
            items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_MarginOvernight, instrument, productType));
        } else if (!RiskPlan.UseOvernightMargin(instrument, productType) && RiskPlan.UseLongShortMargin(instrument, productType)) {
            items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_MarginBuy, instrument, productType));
            items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_MarginSell, instrument, productType));
        } else if (RiskPlan.UseOvernightMargin(instrument, productType) && RiskPlan.UseLongShortMargin(instrument, productType)) {
            if (RiskPlan.GetCalculateMarginTypeNullable(instrument, productType) !== MarginTypes.MARGIN_FULL) {
                items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_MarginDayBuy, instrument, productType));
                items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_MarginDaySell, instrument, productType));
                items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_MarginOvernightBuy, instrument, productType));
                items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_MarginOvernightSell, instrument, productType));
            } else { items = items.concat(this.GetMarginRequirementsItemsFromProductType(MarginEnum.KEY_Margin, instrument, productType)); };
        }

        items = items.concat(this.GetLeverageItemsFromProductType(instrument, productType));

        return items;
    }

    private static GetMarginRequirementsItemsFromProductType (key: MarginEnum, instrument: Instrument, productType: ProductType): Array<[string, string]> {
        const items: Array<[string, string]> = [];
        const itemsN = RiskPlan.GetMarginCoeficientListLength(instrument, productType);

        for (let i = 0; i < itemsN; i++) {
            const marginKey = this.getMarginLocalizationKeyProductType(key, instrument, productType);
            const marginName = Resources.getResource(marginKey);
            const marginValue = this.GetMarginValue(key, instrument, productType, i);
            items.push([marginName, marginValue]);
        }

        return items;
    }

    private static GetMarginValue (key: MarginEnum, instrument: Instrument, productType: ProductType, tier: number) {
        let marginValue;
        let isOvernight = false;
        switch (key) {
        case MarginEnum.KEY_Margin:
        case MarginEnum.KEY_MarginDay:
        case MarginEnum.KEY_MarginBuy:
        case MarginEnum.KEY_MarginDayBuy:
        default:
            marginValue = RiskPlan.GetMargin(instrument, productType, tier);
            break;

        case MarginEnum.KEY_MarginSell:
        case MarginEnum.KEY_MarginDaySell:
            marginValue = RiskPlan.GetShortMargin(instrument, productType, tier);
            break;

        case MarginEnum.KEY_MarginOvernight:
        case MarginEnum.KEY_MarginOvernightBuy:
            marginValue = RiskPlan.GetOvernightMargin(instrument, productType, tier);
            isOvernight = true;
            break;

        case MarginEnum.KEY_MarginOvernightSell:
            marginValue = RiskPlan.GetOvernightShortMargin(instrument, productType, tier);
            isOvernight = true;
            break;
        }

        return this.FormatMargin(marginValue.Initial, marginValue.Warning, marginValue.Maintenance, instrument, productType, isOvernight);
    }

    private static GetLeverageItemsFromProductType (instrument: Instrument, productType: ProductType) {
        const items: Array<[string, string]> = [];
        const leverageLength = this.GetLeverageLengthFromProductType(instrument, productType);

        for (let i = 0; i < leverageLength; i++) {
            const leverageKey = this.getMarginLocalizationKeyProductType(MarginEnum.KEY_Leverage, instrument, productType);
            const leverageName = Resources.getResource(leverageKey);
            const margin = RiskPlan.GetMargin(instrument, productType, i);
            const leverageValue = margin.Leverage;
            const leverageStr = OrderUtils.GetFormattedLeverage(leverageValue);
            items.push([leverageName, leverageStr]);
        }

        return items;
    }

    private static GetLeverageLengthFromProductType (instrument: Instrument, productType: ProductType) {
        const isHedgingNettingType = instrument.isHedgingNettingType();
        const leverages = RiskPlan.GetLeverages(instrument, productType);
        const itemsN = leverages ? (isHedgingNettingType || (leverages.length === 0) ? leverages.length : 1) : 0;
        return itemsN;
    }

    private static getMarginLocalizationKeyProductType (key: MarginEnum, instrument: Instrument, productType: ProductType) {
        const useProductType = RiskPlan.GetAvailableProductTypes(instrument).length > 1;

        const marginLocKey = this.getMarginLocalizationKey(key);

        if (!useProductType) {
            return marginLocKey;
        }

        const separator = '.';
        const productTypeSuffix = this.GetProductTypeSuffix(productType);

        return marginLocKey + separator + productTypeSuffix;
    }

    private static getMarginLocalizationKey (key: MarginEnum) {
        switch (key) {
        case MarginEnum.KEY_Margin:
            return 'InstrumentDetailsPanel.Margin';
        case MarginEnum.KEY_MarginBuy:
            return 'InstrumentDetailsPanel.MarginBuy';
        case MarginEnum.KEY_MarginSell:
            return 'InstrumentDetailsPanel.MarginSell';

        case MarginEnum.KEY_MarginDay:
            return 'InstrumentDetailsPanel.MarginDay';
        case MarginEnum.KEY_MarginDayBuy:
            return 'InstrumentDetailsPanel.MarginDayBuy';
        case MarginEnum.KEY_MarginDaySell:
            return 'InstrumentDetailsPanel.MarginDaySell';

        case MarginEnum.KEY_MarginOvernight:
            return 'InstrumentDetailsPanel.MarginOvernight';
        case MarginEnum.KEY_MarginOvernightBuy:
            return 'InstrumentDetailsPanel.MarginOvernightBuy';
        case MarginEnum.KEY_MarginOvernightSell:
            return 'InstrumentDetailsPanel.MarginOvernightSell';

        case MarginEnum.KEY_Leverage:
            return 'InstrumentDetailsPanel.Leverage';
        }
    }

    private static GetProductTypeSuffix (type: ProductType): string {
        const productTypeKeys = Enum.TakeKeysFromEnum(ProductType);
        for (const key of productTypeKeys) {
            if (ProductType[key] === type) {
                return key;
            }
        }
    }

    static FormatMargin (init, warn, maint, instrument, productType, isOvernight): string {
        if (!isOvernight) { isOvernight = false; };

        const isShowAllKoef = init !== maint || warn !== maint;
        const currentInstrument = instrument;

        if (currentInstrument === null) { return this.FormatMargin_5ARG(init, warn, maint, '', isShowAllKoef); }

        const exp2 = currentInstrument.Exp2;
        const marginType = RiskPlan.GetCalculateMarginTypeNullable(currentInstrument, productType);

        if (marginType === MarginTypes.MARGIN_FIXED) { return this.FormatMargin_5ARG(init, warn, maint, exp2, isShowAllKoef); }

        // instrument CCY

        if (marginType === MarginTypes.MARGIN_FULL || marginType === MarginTypes.MARGIN_STOCK) { return '100 %'; }

        if (marginType === MarginTypes.MARGIN_PRICE_LIMIT) // Limit based.
        {
            // #28806   MD: futures margin coeficients
            // Alex Khomenko: при типе маржи MARGIN_SETLEMENT / 7 /  инит маржин должна браться не из объекта маржи, а из лимитов, тоесть нужно складывать значения полей 400 399 (*Андрей*)

            init = currentInstrument.Limits.HightLimit + currentInstrument.Limits.LowLimit;
            // #26758 Symbol info и тип маржи Limit based.
            // сервер: значение константы FIELD_PRICE_LIMIT_MESURE:  byte PERCENTEGE = 0; byte OFFSET = 1; byte DISABLE = -1;

            let lotSize = currentInstrument.LotSize;
            if (currentInstrument.isFutureOrOption()) { lotSize = currentInstrument.FuturesTickCoast / currentInstrument.PointSize; }

            if (currentInstrument.Limits.PriceLimitMeasure === PriceLimitMeasure.PERCENTAGE_SETTL_PRICE) {
                const k = lotSize * currentInstrument.PrevSettlementPrice * 0.01;
                return this.FormatMargin_5ARG(init * k, warn * k, init * /* Возможно это опечатка */maint * k, exp2, isShowAllKoef);
            }

            if (currentInstrument.Limits.PriceLimitMeasure === PriceLimitMeasure.OFFSET_SETTL_PRICE) {
                const k = lotSize * currentInstrument.PointSize;
                return this.FormatMargin_5ARG(init * k, warn * k, maint * k, exp2, isShowAllKoef);
            }

            return Resources.getResource('InstrumentDetailsPanel.None');
        }

        if (marginType === MarginTypes.MARGIN_PT_RISK_MODULE) { return Resources.getResource('InstrumentDetailsPanel.PTRM'); }

        if (marginType === MarginTypes.MARGIN_NSE_VAR) {
            if (isOvernight && RiskPlan.GetUseVarOnlyIntraday(currentInstrument, productType)) { return this.FormatMargin_3ARG(init, warn, maint); }

            const varKoef = RiskPlan.GetVarCoeffNullable(currentInstrument, productType);

            if (RiskPlan.GetVarCoeffApplyingNullable(currentInstrument, productType) === 0) { return this.FormatMargin_3ARG(varKoef * init, varKoef * warn, varKoef * maint); }

            return this.FormatMargin_3ARG(Math.max(init, varKoef), Math.max(warn, varKoef), Math.max(maint, varKoef));
        }

        if (marginType === MarginTypes.MARGIN_NONE) { return '-'; }

        if (marginType === MarginTypes.LONG_OPTIONS_FULLY_PAID_MARGIN) { return currentInstrument.isOptionSymbol ? this.FormatMarginPercentage_3ARG(1, 0, 0) : this.FormatMarginPercentage_3ARG(0, 0, 0); };

        if (marginType === MarginTypes.MARGIN_CUSTOM_FIXED) {
            const currencyStr = RiskPlan.GetCustomCurrencyStr(currentInstrument, productType);
            return this.FormatMargin_5ARG(init, warn, maint, currencyStr, isShowAllKoef);
        }

        const precisions = [MathUtils.getPrecision(init) - 2, MathUtils.getPrecision(warn) - 2, MathUtils.getPrecision(maint) - 2]; //  95646 запоминаем исходную точность после умножения на 100 (= -2 знака ), чтобы точность не менялась в процессе арифметической операции (JS 1 <3)
        return this.FormatMargin_5ARG(init * 100, warn * 100, maint * 100, '%', isShowAllKoef, precisions);
    }

    static FormatMargin_5ARG = function (init, warn, maint, sign, isShowAllKoef, precisions?): string {
        precisions = precisions || [];

        const initFormat = MathUtils.TruncateDouble(init, precisions[0] || 2);
        const warnFormat = MathUtils.TruncateDouble(warn, precisions[1] || 2);
        const maintFormat = MathUtils.TruncateDouble(maint, precisions[2] || 2);
        if (isShowAllKoef) {
            if (sign) { return initFormat + ' ' + sign + ' / ' + warnFormat + ' ' + sign + ' / ' + maintFormat + ' ' + sign; } else { return initFormat + '/' + warnFormat + '/' + maintFormat; };
        }
        return sign ? initFormat + ' ' + sign : initFormat.toString();
    };

    static FormatMargin_3ARG (init, warn, maint): string {
        return MathUtils.TruncateDouble(init, 2) + '% / ' + MathUtils.TruncateDouble(warn, 2) + '% / ' + MathUtils.TruncateDouble(maint, 2) + '%';
    }

    static FormatMarginPercentage_3ARG (init, warn, maint): string {
        if (init !== maint || warn !== maint) { return MathUtils.TruncateDouble(init * 100, 2) + '% / ' + MathUtils.TruncateDouble(warn * 100, 2) + '% / ' + MathUtils.TruncateDouble(maint * 100, 2) + '%'; }

        return MathUtils.TruncateDouble(init * 100, 2) + '%';
    }

    /// определяем какие айтемы отображать (для айтемов, количество которых зависит от ProductType)
    static ProcessItemByProductType (key: TradinInfoSymbolInfoFeatureEnum, instrument: Instrument): Array<[string, string]> {
        const items: Array<[string, string]> = [];

        const aPT = instrument.RiskSettings.RiskPlanSettings.availableProductTypes;
        for (let i = 0; i < aPT.length; i++) {
            const productType = aPT[i];
            const locKey = this.getTradingInfoLocalizationKeyProductType(key, instrument, productType);
            const keyName = Resources.getResource(locKey);
            const keyValue = this.getTradingInfoValueByProductType(key, instrument, productType);
            items.push([keyName, keyValue]);
        }
        return items;
    }

    private static getTradingInfoValueByProductType (key: TradinInfoSymbolInfoFeatureEnum, instrument: Instrument, productType: ProductType) {
        switch (key) {
        case TradinInfoSymbolInfoFeatureEnum.MinimalLot:
            const minLot = instrument.getMinLot(productType);
            return MathUtils.formatValueWithEps(minLot);

        case TradinInfoSymbolInfoFeatureEnum.MaximumLot:
            const maxLot = instrument.getMaxLot(productType);
            if (maxLot > 0 && maxLot != NumericUtils.MAXVALUE) { return MathUtils.formatValueWithEps(maxLot); } else { return Resources.getResource('InstrumentDetailsPanel.None'); }

        case TradinInfoSymbolInfoFeatureEnum.MaxPositionQuantity:
            const currenRiskPlanItem = instrument.RiskSettings.RiskPlanSettings.cache[productType];
            if (currenRiskPlanItem?.MaxPositionQtyPerSymbol != null && currenRiskPlanItem.MaxPositionQtyPerSymbol != -1) { return currenRiskPlanItem.MaxPositionQtyPerSymbol.toString(); } else { return Resources.getResource('InstrumentDetailsPanel.None'); }

        case TradinInfoSymbolInfoFeatureEnum.AllowShortPositions:
            const isAllowShortPositions = instrument.IsAllowShortPositions(productType);
            return Resources.getResource(isAllowShortPositions
                ? 'InstrumentDetailsPanel.Allow short positions'
                : 'InstrumentDetailsPanel.Not allow short positions');
        }
    }

    private static getTradingInfoLocalizationKeyProductType (key: TradinInfoSymbolInfoFeatureEnum, instrument: Instrument, productType: ProductType) {
        const useProductType = RiskPlan.GetAvailableProductTypes(instrument).length > 1;

        const marginLocKey = this.getTradingInfoLocalizationKey(key);

        if (!useProductType) {
            return marginLocKey;
        }

        const separator = '.';
        const productTypeSuffix = this.GetProductTypeSuffix(productType);

        return marginLocKey + separator + productTypeSuffix;
    }

    private static getTradingInfoLocalizationKey (key: TradinInfoSymbolInfoFeatureEnum): string {
        switch (key) {
        case TradinInfoSymbolInfoFeatureEnum.MinimalLot:
            return 'InstrumentDetailsPanel.MinimalLot';
        case TradinInfoSymbolInfoFeatureEnum.MaximumLot:
            return 'InstrumentDetailsPanel.MaximumLot';
        case TradinInfoSymbolInfoFeatureEnum.MaxPositionQuantity:
            return 'InstrumentDetailsPanel.MaxPositionQtyPerSymbol';
        case TradinInfoSymbolInfoFeatureEnum.AllowShortPositions:
            return 'InstrumentDetailsPanel.AllowShortPositions';
        default:
            return key.toString();
        }
    }

    private static AddTickSizeCostItems (instrument: Instrument): Array<[string, string]> {
        let items: Array<[string, string]> = [];
        if (instrument.VariableTickList == null || instrument.VariableTickList.length <= 1) {
            if (instrument.QuotingType == QuotingType.TickCost_TickSize) {
                const tickCoastName = Resources.getResource('InstrumentDetailsPanel.TickCoast');
                const tickCoastValue = this.getTickCoast(instrument);
                items.push([tickCoastName, tickCoastValue]);
            }
            const tickSizeName = Resources.getResource('InstrumentDetailsPanel.TickSize');
            const tickSizeValue = MathUtils.formatValueWithEps(instrument.PointSize);
            items.push([tickSizeName, tickSizeValue]);
        } else {
            if (instrument.QuotingType != QuotingType.LotSize) {
                items.push([Resources.getResource('InstrumentDetailsPanel.TickCoast'), '']);
                items = items.concat(this.PrepareTickSizeCostItems(instrument, TickSizeTickCoastEnum.KEY_TickCoast));
            }
            items.push([Resources.getResource('InstrumentDetailsPanel.TickSize'), '']);
            items = items.concat(this.PrepareTickSizeCostItems(instrument, TickSizeTickCoastEnum.KEY_TickSize));
        }

        if (instrument.QuotingType == QuotingType.LotSize) {
            const lotSizeName = Resources.getResource('InstrumentDetailsPanel.LotSize');
            const lotSizeValue = this.getLotSize(instrument);
            items.push([lotSizeName, lotSizeValue]);
        }
        return items;
    }

    static PrepareTickSizeCostItems (instrument: Instrument, key: TickSizeTickCoastEnum): Array<[string, string]> {
        const items: Array<[string, string]> = [];

        for (let i = 1; i < instrument.VariableTickList.length; i++) {
            let rightBorder = instrument.VariableTickList[i].RightBorder;
            const includeRightBorder = i < instrument.VariableTickList.length - 1 ? !instrument.VariableTickList[i + 1].IncludeLeftBorder : false;
            if (!isFinite(rightBorder)) { rightBorder = -1; }

            const lb = instrument.VariableTickList[i].LeftBorder;
            const rb = rightBorder;
            const il = instrument.VariableTickList[i].IncludeLeftBorder;
            const ir = includeRightBorder;

            if (key == TickSizeTickCoastEnum.KEY_TickCoast && instrument.isFutureOrOption()) {
                const tc = instrument.VariableTickList[i].TickCost;

                const keyName = this.GetTickSizeTickCoastFormatString(lb, rb, il, ir, FormatStringEnum.Price);
                const keyValue = tc.toString();
                items.push([keyName, keyValue]);
            }

            if (key == TickSizeTickCoastEnum.KEY_TickSize) {
                const pointSize = instrument.VariableTickList[i].PointSize;
                const point = (pointSize < 1 ? parseFloat(pointSize.toFixed(10)) : pointSize).toString();

                const keyName = this.GetTickSizeTickCoastFormatString(lb, rb, il, ir, FormatStringEnum.Price);
                const keyValue = point;
                items.push([keyName, keyValue]);
            }
        }

        return items;
    }

    private static getLotSize (instrument: Instrument): string {
        let stLotSize = instrument.LotSize.toString();

        if (instrument.isForexSymbol()) { stLotSize += ' ' + instrument.Exp1; }
        if (instrument.QuotingType == QuotingType.LotSize) { return stLotSize; }

        return '';
    }

    private static getTickCoast (instrument: Instrument): string {
        if (instrument.InstrType == InstrumentTypes.FUTURES ||
            instrument.InstrType == InstrumentTypes.CFD_FUTURES ||
            instrument.InstrType == InstrumentTypes.OPTIONS ||
            instrument.InstrType == InstrumentTypes.SPREADBET) {
            let tickCoast = instrument.FuturesTickCoast;
            if (tickCoast < 0) { tickCoast = instrument.LotSize * instrument.PointSize; }

            return tickCoast.toString();
        } else {
            const tickCoast = instrument.FuturesTickCoast;
            return tickCoast.toString();
        }
    }

    private static GetTickSizeTickCoastFormatString (lotsFrom, lotsTo, allowLimit, allowHighLimit, feeVar: FormatStringEnum) {
        const lf = parseFloat(lotsFrom);
        const lt = parseFloat(lotsTo);

        const localizeKey = Resources.getResource(
            feeVar === FormatStringEnum.Price
                ? 'InstrumentDetailsPanel.Price'
                : 'InstrumentDetailsPanel.Amount');

        return lf +
            (allowLimit ? ' <= ' : ' < ') +
            localizeKey +
            (allowHighLimit ? ' <= ' : ' < ') +
            (lt === -1 ? '\u221e'/* ∞ */ : lt.toString());
    }

    private static getHistoricalSwap (instrument: Instrument, shortKey: string): string {
        if (!instrument) { return null; }

        if (!instrument.InstrumentAdditionalInfo) { return null; }

        if (instrument.InstrumentAdditionalInfo.hasOwnProperty(shortKey)) { return instrument.InstrumentAdditionalInfo[shortKey]; };

        return null;
    }

    public static getDaysPerFormatted (value: DaysPerType, period: GeneralSymbolInfoFeatureEnum): string {
        const perMonth = period === GeneralSymbolInfoFeatureEnum.DaysPerMonth; // else DaysPerYear

        let result = null;
        switch (value) {
        case DaysPerType.Actual:
            result = Resources.getResource('DaysPerType.Actual');
            break;
        case DaysPerType.Default:
            result = perMonth ? 30 : 360;
            break;
        }

        return result === null ? '' : result.toString();
    }
}

enum MarginEnum {
    KEY_Margin,
    KEY_MarginBuy,
    KEY_MarginSell,

    KEY_MarginDay,
    KEY_MarginDayBuy,
    KEY_MarginDaySell,

    KEY_MarginOvernight,
    KEY_MarginOvernightBuy,
    KEY_MarginOvernightSell,

    KEY_Leverage
}

enum TickSizeTickCoastEnum {
    KEY_TickSize,
    KEY_TickCoast
}

enum FormatStringEnum {
    Price,
    Amount,
}
