// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class YTMRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_YTM_REQUEST, fieldSet);
    }

    public setRequestId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_REQUEST_ID, id);
    }

    public setInstrumentId (instrumentId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_INSTRUMENT_ID, instrumentId);
    }

    public setRouteId (routeId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ROUTE_ID, routeId);
    }
}
