// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolRayType } from '@shared/commons/cache/DataCacheToolEnums';
import { ToolView } from './ToolView';
import { ToolViewUtils } from './Utils/ToolViewUtils';
import { type FibonacciFansDataCacheTool } from '@shared/commons/cache/Tools/FibonacciFansDataCacheTool';

export class FibonacciFansToolView extends ToolView<FibonacciFansDataCacheTool> {
    public DrawFibFan (gr, ww, highlight): void {
        const pc = ww.PointsConverter;
        const clientRect = ww.ClientRectangle;

        const dataCacheTool = this.dataCacheTool;
        const fibLevel = dataCacheTool.fibLevel;

        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;
        const penRay = dataCacheTool.PenRay;
        const font = dataCacheTool.font;
        const fontColor = dataCacheTool.FontColor;
        const fontBrush = dataCacheTool.FontBrush;
        const instrument = dataCacheTool.Instrument;

        const points = dataCacheTool.Points;

        const p0 = points[0];
        // const p0X = p0[0];
        const p0Y = p0[1];

        const p1 = points[1];
        // const p1X = p1[0];
        const p1Y = p1[1];

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        gr.DrawLine(pen, scrP0X, scrP0Y, scrP1X, scrP1Y);

        const fibLevel_len = fibLevel.length;
        for (let i = 0; i < fibLevel_len; i++) {
            const fibLvl = fibLevel[i];

            const yi = scrP0Y + (scrP1Y - scrP0Y) * (90 - fibLvl) / 100;
            const yiDraw = p0Y + (p1Y - p0Y) * (90 - fibLvl) / 100;

            const p = pc.GetScreenY(yiDraw + (yiDraw - p0Y) * 20);

            ToolView.DrawLineWithRay(gr, ww,
                scrP0X,
                scrP0Y,
                scrP1X + 20 * (scrP1X - scrP0X),
                p,
                DataCacheToolRayType.Ray,
                pen,
                penRay);

            if (!highlight) {
            // #region TEXT levels for fibonacci Fans

                ///
                /// #18801. Drawings - мелкие доработки - показываем  уровнь
                ///
                const ch_ww = clientRect.X + clientRect.Width;
                const fibText = ToolViewUtils.FormatFibonacciText(fibLvl, points, instrument);
                // TEXT to DOWN edge
                if (scrP0Y < yi) {
                // ур-е прямой через 2 точки, для поиска коорд text_xi при высоте чарта
                // и известных 2х точках ((screenPoints[0][0], screenPoints[0][1])) и ((screenPoints[1][0], yi))
                    const text_xi = ((clientRect.Height - yi) / (yi - scrP0Y) * (scrP1X - scrP0X)) + scrP1X;

                    if (isNaN(text_xi)) continue;

                    if (text_xi > 0 && text_xi < clientRect.Width && scrP0Y < clientRect.Height) {
                        ToolViewUtils.DrawFibonacciText(gr, ww, text_xi, clientRect.Height, fibText, font, fontColor);
                    }
                }
                // TEXT to TOP edge
                else {
                    const text_xi = ((0 - yi) / (yi - scrP0Y) * (scrP1X - scrP0X)) + scrP1X;

                    if (isNaN(text_xi)) continue;

                    if (text_xi > 0 && text_xi < clientRect.Width && scrP0Y > 0) {
                        ToolViewUtils.DrawFibonacciText(gr, ww, text_xi, font.Height, fibText, font, fontColor);
                    }
                }
                // TEXT to RIGHT screen edge
                if (scrP1X > scrP0X) {
                // ур-е прямой через 2 точки, для поиска коорд text_yi при ширине чарта
                // и известных 2х точках ((screenPoints[0][0], screenPoints[0][1])) и ((screenPoints[1][0], yi))
                    const text_yi = ((ch_ww - scrP1X) * (yi - scrP0Y) / (scrP1X - scrP0X)) + yi;
                    if (isNaN(text_yi) || text_yi > clientRect.Height || text_yi < 0 || scrP0X > ch_ww) // при таких игрек не пытаемся рисовать текст
                    {
                        continue;
                    }

                    gr.DrawString(fibText, font, fontBrush, ch_ww, text_yi, 'right', 'bottom');
                }
                // TEXT to LEFT screen edge
                else {
                    const text_yi = ((0 - scrP1X) * (yi - scrP0Y) / (scrP1X - scrP0X)) + yi;
                    if (isNaN(text_yi) || text_yi > clientRect.Height || text_yi < 0 || scrP0X < 0) // при таких игрек не пытаемся рисовать текст
                    {
                        continue;
                    }

                    ToolViewUtils.DrawFibonacciText(gr, ww, 1/* x == 1px */, text_yi, fibText, font, fontColor);
                }

            // #endregion
            }
        }
    }

    public override Draw (gr, ww, param): void {
        this.DrawFibFan(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        this.DrawFibFan(gr, ww, true);
        super.DrawSelection(gr, ww);
    }
}
