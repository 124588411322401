// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectNonFixedInstumentStrikesResponseMessage } from '../../../../Utils/DirectMessages/DirectNonFixedInstumentStrikesResponseMessage';
import { OptionContractGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class NonFixedInstumentStrikesResponseMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectNonFixedInstumentStrikesResponseMessage[] {
        const message = new DirectNonFixedInstumentStrikesResponseMessage();

        const contractID = fieldSet.GetValue(FieldsFactory.FIELD_CONTRACT_ID);
        message.instrID = contractID;
        message.instrName = this.decoder.GetSymbolById(message.instrID);

        message.PointSize = fieldSet.GetValue(FieldsFactory.FIELD_POINTSIZE);

        // TODO
        const variableTickGroups = fieldSet.GetGroups(FieldsFactory.VARIABLE_TICK_GROUP);
        if (variableTickGroups.length > 0) {
        //    for (int i = 0; i < variableTickGroups.Count; i++)
        //    {
        //        VariableTickGroup group = variableTickGroups[i];
        //        var ps = group.getPointSize(); // пока пользуем минимальный, в перспективе надо брать List и работать с переменным
        //        if (message.PointSize == 0)
        //            message.PointSize = ps;
        //        else
        //            message.PointSize = Math.Min(message.PointSize, ps);
        // }
        }

        message.FutureAliasName = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        message.strikePricesList = OptionContractGroup.GetOldOptionContractGroup(fieldSet);
        message.optionContractGroup = OptionContractGroup.GetNewOptionContractGroup(fieldSet, contractID);

        return [message];
    }
}
