// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet.js';

export class ReportGroupRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_REPORT_GROUP_REQUEST, fieldSet);
    }

    public setReportId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_REPORT_ID, id);
    }

    public setLanguage (language: string): void {
        this.setFieldValue(FieldsFactory.FIELD_LANG, language);
    }

    public setIsTechnicalReport (isTechnical: boolean): void {
        this.setFieldValue(FieldsFactory.FIELD_IS_TECHNICAL_REPORT, isTechnical);
    }
}
