// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class Greeks {
    public iv: number = NaN;
    public delta: number = NaN;
    public gamma: number = NaN;
    public vega: number = NaN;
    public theta: number = NaN;
    public rho: number = NaN;

    public get ivPercent (): number {
        return this.iv * 100;
    }

    public isEmpty (): boolean {
        return isNaN(this.iv) && isNaN(this.delta) && isNaN(this.gamma) && isNaN(this.vega) && isNaN(this.theta) && isNaN(this.rho);
    }
}
