// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PFixField } from './PFixField';

export class PFixStringArrField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = [''];
    }

    public override ReadValue (buffer): void {
        const length = buffer.ReadInt32();
        const list = [];

        for (let i = 0; i < length; i++) {
            list.push(buffer.ReadString());
        }

        this.Value = list;
    }

    public override Write (buffer): void {
        buffer.WriteShort(this.FieldId);

        const length = this.Value.length;
        buffer.WriteInt32(length);

        for (let i = 0; i < length; i++) {
            buffer.WriteString(this.Value[i]);
        }
    }
}
