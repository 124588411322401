// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TradingPlatformTemplate } from './templates';
import { Connection } from '../Commons/Connection';
import { ConnectionStates } from '../Commons/ConnectionEnums';
import { DataCache } from '../Commons/DataCache';
import { TradingPlatformApp } from './Core/TradingPlatformApp';
import { LocalStorage } from '../Commons/LocalStorage';
import { UserWebStorage, UserWebStorageInstance } from '../user-web-storage';
import { Resources } from '../Localizations/Resources';
import { messageBoxHandler } from '../Utils/AppHandlers';
import { TerceraReconnectScreen } from '../Controls/screen/TerceraReconnectScreen';
import { MainWindowBase } from '../Controls/screen/MainWindowBase';
import { AppNames } from '../Controls/UtilsClasses/FactoryConstants';

export class TradingPlatform extends MainWindowBase {
    public selectedArrayHeaderButton: any = null;
    public _logoutReason: any;
    public pingInterval: NodeJS.Timeout;

    public override getClientPanel (): HTMLElement { return this.el; }

    public override getType (): string { return AppNames.TradingPlatform; }

    public override oninit (): void {
        this.on('loginEvent', this.loginEvent);
    }

    public override oncomplete (): void {
        const msgBoxHanl = messageBoxHandler;
        msgBoxHanl.Show = undefined;
        msgBoxHanl.msgType = undefined;

        Connection.onConnectStateChange.Subscribe(this.onConnectionsStateChanged, this);
        Connection.SubscribeOnErrorMultiPolicy(this.errorMultiPolicy);
    }

    public loginEvent (): void {
        void this.set({
            login: false,
            onDisconnectHandler: () => { this.logoutEvt(); }
        });
        this.StartPingLoop();
        void TradingPlatformApp.Init();
    }

    public logoutEvt (): void {
        void this.set('login', true);
        this.StopPingLoop();
    }

    public StartPingLoop (): void {
        const loop = function (): void {
            Connection.SendPing().then(function (value) {
            });
        };
        loop();
        this.pingInterval = setInterval(loop, 10000);
    }

    public StopPingLoop (): void {
        clearInterval(this.pingInterval);
    }

    public logoutEvent (): void {
        Connection.disconnect();
    }

    public setBrandingWebHeader (header, brandingKey): void {}

    public onConnectionsStateChanged = (newState, oldState, key): void => {
        const state = Connection.getState();
        switch (state) {
        case ConnectionStates.CONNECTED:{
            const vendor = Connection.vendor;
            const connName = LocalStorage.getLastConnectionName();
            UserWebStorageInstance.reinit(vendor.GetLogin(), connName);
            void this.loadingStarted();
            break;
        }

        case ConnectionStates.DISCONNECTED:
            DataCache.cleanup();
            UserWebStorage.RestApiNotWork = false;
            Resources.setNeedForceUpdateLocale();

            // //this.stopPingLoop();
            UserWebStorageInstance.clear();
            break;
        case ConnectionStates.CONNECTION_LOST:
            DataCache.cleanup();
            TerceraReconnectScreen.startReconnection();

            UserWebStorage.RestApiNotWork = false;
            Resources.setNeedForceUpdateLocale();

            // //this.stopPingLoop();
            break;
        }
    };

    public errorMultiPolicy = (disconectArguments): void => {
        const disconnectReasonText = disconectArguments?.Text;
        if (disconnectReasonText != null) {
            alert(disconnectReasonText);
        }
    };

    public loadingStarted = async (): Promise<void> => {
        this._logoutReason = null;
        const brandingKey = Connection.vendor.loginParams.brandingKey; //  #86122 #85820 #86776 #87660 #87238
        if (brandingKey) {
            await DataCache.GetBrandingRules(brandingKey);
        }
        // await DataCache.StartLoadingWaiting();
        // await SessionSettings.load();
        // this.startPingLoop();
    };
}

MainWindowBase.extendWith(TradingPlatform, {
    template: TradingPlatformTemplate,
    el: 'body',
    data: function () {
        return {
            login: true,
            loginLogoSource: 'current_theme/TvTradingPlatform/images/logo/LogoTradingPlatform.svg', // empty logo placeholder with "Powered by..." text
            onDisconnectHandler: null
        };
    },
    partials: {}
});
