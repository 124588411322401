// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MarginModes } from '../../Utils/Enums/Constants';
import { MarginTypes } from '../../Utils/Instruments/MarginTypes';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { type RiskPlanItem } from '../../Utils/RiskPlan/RiskPlanItem';
import { type Account } from './Account';
import { type Instrument } from './Instrument';

import { RiskPlanSettings } from './RiskPlanSettings';

export class RiskSettings {
    public useSameCrossPriceForOpenClose: any;
    public hedgedMarginCallSize: any;
    public useOvernightMargin: any;
    public useLongShortMargin: any;
    public useOpenPriceInMargin: any;
    // TODO
    public isShortable = true;
    public maxLot: any;
    public minLot: any;

    public MarginCoefficients: any;
    // #endregion

    public instrument: Instrument;
    public RiskPlanSettings = new RiskPlanSettings();
    public marginType = MarginTypes.MARGIN_PFS;
    public marginMode = MarginModes.USE_PROFIT_LOSS;
    public marginCoefficient = 1;

    public allowOvernightTrading: any;
    public AllowToWithdrawUnrealizedProfit: any;

    constructor (instrument: Instrument) {
        this.instrument = instrument;
    }

    public IsShortable (productType: ProductType): boolean {
        const riskPlanItem = this.getRiskPlanItem(productType || ProductType.General);

        if (!riskPlanItem) {
            return null;
        }

        return riskPlanItem.IsShortable;
    }

    public isOvernightTradingsAllowed (): boolean {
        const riskPlanItem = this.getRiskPlanItem(ProductType.General);

        if (!riskPlanItem) {
            return null;
        }

        return riskPlanItem.AllowOvernightTrading;
    }

    public IsPositionCloseOnly (productType: ProductType, account: Account) {
        const riskPlanItem = this.getRiskPlanItem(productType || ProductType.General, account);

        if (!riskPlanItem) {
            return null;
        }

        return riskPlanItem.IsPositionCloseOnly;
    }

    public AllowWithdrawUnrealizedProfit (productType: ProductType, account: Account): any {
        const riskPlanItem = this.getRiskPlanItem(productType || ProductType.General, account);

        if (!riskPlanItem) {
            return null;
        }

        return riskPlanItem.AllowToWithdrawUnrealizedProfit;
    }

    public getLotStep (productType: ProductType, account: Account): number | null {
        const riskPlanItem = this.getRiskPlanItem(productType, account);
        return riskPlanItem?.LotStep ?? null;
    }

    public IsCashTradingAllowed (productType: ProductType, account: Account): boolean {
        const riskPlanItem = this.getRiskPlanItem(productType, account);
        return riskPlanItem?.IsCashTradingAllowed ?? false;
    }

    public getMaxLot (productType: ProductType, account: Account): any {
        const riskPlanItem = this.getRiskPlanItem(productType, account);
        return riskPlanItem && riskPlanItem.MaxLot !== null
            ? riskPlanItem.MaxLot
            : this.maxLot;
    }

    public getMinLot (productType: ProductType, account: Account): any {
        const riskPlanItem = this.getRiskPlanItem(productType, account);
        return riskPlanItem && riskPlanItem.MinLot !== null
            ? riskPlanItem.MinLot
            : this.minLot;
    }

    public getRiskPlanItem (productType: ProductType, account: Account | null = null): RiskPlanItem | null {
        const riskPlanSettings = account?.RiskPlan ? account.RiskPlan.riskPlanCache.getRiskPlanSettings(this.instrument, productType) : this.RiskPlanSettings;
        return riskPlanSettings ? riskPlanSettings.get(productType) : null;
    }

    /// /#region Misc
    public Update (im): void {
        if (im == null) {
            return;
        }

        if (im.CalculateMarginType !== null && im.CalculateMarginType !== undefined) {
            this.marginType = im.CalculateMarginType;
        }

        if (im.MarginMode !== null && im.MarginMode !== undefined) {
            this.marginMode = im.MarginMode;
        }

        if (im.UseSameCrossPriceForOpenClose !== null && im.UseSameCrossPriceForOpenClose !== undefined) {
            this.useSameCrossPriceForOpenClose = im.UseSameCrossPriceForOpenClose;
        }

        if (im.HedgedMarginCallSize !== null && im.HedgedMarginCallSize !== undefined) {
            this.hedgedMarginCallSize = im.HedgedMarginCallSize;
        }

        if (im.UseOvernightMargin !== null && im.UseOvernightMargin !== undefined) {
            this.useOvernightMargin = im.UseOvernightMargin;
        }

        if (im.AllowOvernightTrading !== null && im.AllowOvernightTrading !== undefined) {
            this.allowOvernightTrading = im.AllowOvernightTrading;
        }

        if (im.AllowToWithdrawUnrealizedProfit !== null && im.AllowToWithdrawUnrealizedProfit !== undefined) {
            this.AllowToWithdrawUnrealizedProfit = im.AllowToWithdrawUnrealizedProfit;
        }

        if (im.UseLongShortMargin !== null && im.UseLongShortMargin !== undefined) {
            this.useLongShortMargin = im.UseLongShortMargin;
        }

        if (im.MarginCoefficient !== null && im.MarginCoefficient !== undefined) {
            this.marginCoefficient = im.MarginCoefficient;
        }

        if (im.UseOpenPriceInMargin !== null && im.UseOpenPriceInMargin !== undefined) {
            this.useOpenPriceInMargin = im.UseOpenPriceInMargin;
        }

        if (im.IsShortable !== null && im.IsShortable !== undefined) {
            this.isShortable = im.IsShortable;
        }

        if (im.MaxLot !== null && im.MaxLot !== undefined) {
            this.maxLot = im.MaxLot;
        }

        if (im.MinimalLot !== null && im.MinimalLot !== undefined) {
            this.minLot = im.MinimalLot;
        }
    }

    public UseSameCrossPriceForOpenClose (productType): any {
        return !!this.RiskPlanSettings.cache[productType] && this.RiskPlanSettings.cache[productType].UseSameCrossPriceForOpenClose;
    }
}
