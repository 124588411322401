// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { EditPositionScreenTemplate } from '../../templates.js';
import { type TerceraTabStrip, TerceraTabStripItem } from '../elements/TerceraTabStrip';
import { ScreensNames } from '../UtilsClasses/FactoryConstants';
import { TerceraWindowBase } from './TerceraWindowBase';
import { IsAllowed } from '../../Commons/IsAllowed';
import { editPositionScreenHandler } from '../../Utils/AppHandlers';
import { DataCache } from '../../Commons/DataCache';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { type Position } from '../../Commons/cache/Position';
import { type PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { type OrderEditViewBase } from '../panels/OrderEditViewBase';
import { ResourcesManager } from '../../Commons/properties/ResourcesManager';

export class EditPositionScreen extends TerceraWindowBase {
    public childScreen: OrderEditViewBase | null = null;
    public NotSkipNextTime: boolean;

    public override getType (): ScreensNames { return ScreensNames.EditPositionScreen; }

    public override oninit (): void {
        super.oninit();

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.populateTabStrip();
        this.setInitialTab();
        this.Controls.tabStrip.onTabChange.Subscribe(this.selectTab, this);

        this.localize();
    }

    public override localize (): void {
        void this.set('header', Resources.getResource('screen.editPosition.header'));

        const items = this.get('items');
        if (isValidArray(items)) {
            for (let i = 0; i < items.length; i++) {
                items[i].text = Resources.getResource(items[i].data);
            }

            void this.set('items', items);
        }

        if (!isNullOrUndefined(this.childScreen)) {
            this.childScreen.localize();
        }
    }

    public override dispose (): void {
        if (!isNullOrUndefined(this.Controls.tabStrip)) {
            this.Controls.tabStrip.onTabChange.UnSubscribe(this.selectTab, this);
        }

        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public populateTabStrip (): void {
        const tabStrip: TerceraTabStrip = this.Controls.tabStrip;
        const tabs = EditPositionScreen.tabHeaderKeys;
        const items = [];

        for (let i = 0; i < tabs.length; i++) {
            const newItem = new TerceraTabStripItem();
            newItem.text = Resources.getResource(tabs[i]);
            newItem.data = tabs[i];
            newItem.visible = true;
            newItem.enabled = this.isActionAllowed(tabs[i]);
            items.push(newItem);
        }

        void this.set('items', items);

        tabStrip.updateView();
    }

    public selectTab (dontChangeTabType, numericLinkName: string, notSkipNextTime): void {
        const pos: Position = this.get('position');
        const placedFrom: PlacedFrom = this.get('placedFrom');
        let isModify = !this.get<boolean>('isModifyTabActive');

        if (dontChangeTabType === true) {
            isModify = !isModify;
        }

        this.close();

        EditPositionScreen.show(pos.PositionId, placedFrom, numericLinkName, isModify, undefined, notSkipNextTime);
    }

    public setInitialTab (): void {
        const screenType = this.get<boolean>('isModifyTabActive') ? ScreensNames.ModifyPositionScreen : ScreensNames.ClosePositionScreen;

        const tabStrip = this.Controls.tabStrip;
        const tabIndex = EditPositionScreen.tabScreenKeys.indexOf(screenType);

        tabStrip.selectItem(EditPositionScreen.tabHeaderKeys[tabIndex]);
    }

    public isActionAllowed (tab: string): boolean {
        const pos: Position = this.get('position');

        if (tab === EditPositionScreen.tabHeaderKeys[0]) {
            return IsAllowed.IsPositionClosingAllowed(pos).Allowed;
        } else {
            return IsAllowed.IsPositionModifyingAllowed(pos).Allowed;
        }
    }

    public static show (positionID, placedFrom: PlacedFrom, numericLinkName: string, isModify: boolean, location, notSkipNextTime: boolean): void {
        const position = DataCache.getPositionById(positionID);

        const allowed = isModify
            ? IsAllowed.IsPositionModifyingAllowed(position).Allowed
            : IsAllowed.IsPositionClosingAllowed(position).Allowed;

        if (!allowed) { return; }

        const screen = EditPositionScreen.getScreenByPositionID(positionID);

        notSkipNextTime = notSkipNextTime || false;

        if (!isNullOrUndefined(screen)) // only one screen for one position
        {
            screen.selectTab(isModify === screen.get('isModifyTabActive'), numericLinkName, screen.NotSkipNextTime);
            screen.setFocus();
            return;
        }

        const parentScreen: EditPositionScreen = MainWindowManager.Factory.addScreen(ScreensNames.EditPositionScreen);
        const childScreenType = isModify ? ScreensNames.ModifyPositionScreen : ScreensNames.ClosePositionScreen;
        const childScreen = MainWindowManager.Factory.addScreen(childScreenType);
        childScreen.on('onFillByPosition', () => {
            if (!isNullOrUndefined(location)) {
                parentScreen.setLocation(location.x, location.y);
            } else {
                parentScreen.center();
            }
        });

        childScreen.set({
            position,
            placedFrom
        });

        if (isValidString(numericLinkName)) {
            childScreen.activateNumeric(numericLinkName);
        }

        void parentScreen.set({
            position,
            placedFrom,
            isModifyTabActive: isModify
        });
        parentScreen.addControl(childScreen);
        parentScreen.childScreen = childScreen;

        parentScreen.NotSkipNextTime = notSkipNextTime; // TODO костыль ебаный
    }

    public static getScreenByPositionID (positionID): EditPositionScreen {
        const keys = Object.keys(MainWindowManager.MainWindow.Controls);
        for (let i = keys.length - 1; i >= 0; i--) {
            const control = MainWindowManager.MainWindow.Controls[keys[i]];

            if (control.getType() === ScreensNames.EditPositionScreen) {
                const pos: Position = control.get('position');

                if (!isNullOrUndefined(pos) && pos.PositionId === positionID) {
                    return control;
                }
            }
        }

        return null;
    }

    public static tabHeaderKeys = ['screen.closePosition.ok', 'screen.modifyOrder.modify'];
    public static tabScreenKeys = [ScreensNames.ClosePositionScreen, ScreensNames.ModifyPositionScreen];

    public static editPositionScreenHandlerInitialize (): void {
        editPositionScreenHandler.show = EditPositionScreen.show;
    }
}

TerceraWindowBase.extendWith(EditPositionScreen, {
    data: function () {
        return {
            resizable: false,
            width: 340,
            dockablePanel: false,
            showHeader: true,
            showFooter: true,
            items: null,
            header: 'Edit position',
            position: null,
            isModifyTabActive: null,
            placedFrom: null
        };
    },

    template: EditPositionScreenTemplate,
    childScreen: null
});
