// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class InitDataRequest extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_INIT_DATA_REQUEST, fieldSet);
    }

    public getMaxFieldNumber (): number {
        const FIELD_MAX_PFIX_FIELD_INDEX = this.FieldSet.GetField(FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX);
        if (FIELD_MAX_PFIX_FIELD_INDEX) {
            return FIELD_MAX_PFIX_FIELD_INDEX.Value;
        } else {
            return 2147483647;
        }// int.MaxValue;
    }

    public setMaxFieldNumber (number: number): void {
        this.setFieldValue(FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX, number);
    }
}
