// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { RevenueCommissionValueItem } from './RevenueCommissionValueItem';

export class RevenueCommissionValueItemForPerFill extends RevenueCommissionValueItem {
    protected minTradedValue = 0.0;

    get MinTradedValue (): number { return this.minTradedValue; }
    set MinTradedValue (value) { this.minTradedValue = value; }

    public override GetFixedCommissionValue (commission, ignoreMinMaxCorrection = false, markup = 1.0): number {
    // For symbol info panel return value
        if (commission == 0) {
            return this.Value;
        }
        return commission < this.minTradedValue * markup ? 0 : this.Value * markup;
    }
}
