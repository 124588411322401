// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { ControlEvents } from '../Control';
import { TerceraDateRangeDropDownForm } from './TerceraDateRangeDropDownForm';
import { DateTimeUtils } from '../../../Utils/Time/DateTimeUtils';
import { TerceraLookup } from './TerceraLookup';

export class TerceraDateRangeLookup extends TerceraLookup {
    public dateFrom: Date | null = null;
    public dateTo: Date | null = null;

    constructor () {
        super();
    }

    public override getType (): string { return 'TerceraDateRangeLookup'; }

    public override showForm (): void {
        const dropDownForm = new TerceraDateRangeDropDownForm();

        const me = this;

        dropDownForm.setCorrectLocation(me.getX(), me.getY());

        dropDownForm.setDateFrom(me.get('dateFrom') || new Date());
        dropDownForm.setDateTo(me.get('dateTo') || new Date());

        dropDownForm.observe('dateFrom', (newVal) => {
            if (!newVal) return;
            void me.set({ dateFrom: newVal });

            if (!isNullOrUndefined(this.Controls.dtPickerTo)) {
                const newDateFrom = new Date();
                newDateFrom.setTime(me.get('dateTo').getTime());
                this.Controls.dtPickerTo.picker.selectDate(newDateFrom);
            }
        });
        dropDownForm.observe('dateTo', (newVal) => {
            if (!newVal) return;
            void me.set({ dateTo: newVal });

            if (!isNullOrUndefined(this.Controls.dtPickerFrom)) {
                const newDateTo = new Date();
                newDateTo.setTime(me.get('dateFrom').getTime());
                this.Controls.dtPickerFrom.picker.selectDate(newDateTo);
            }
        });
        dropDownForm.on('applyBtnClick', function () {
            me.repopulate();

            this.fire('componentRangeSelect');
        }.bind(this));

        MainWindowManager.MainWindow.addControl(dropDownForm);
    }

    public override oninit (): void {
        super.oninit();

        this.repopulate();
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.textBox.on(ControlEvents.GotFocus, function () { this.showForm(); }.bind(this));
    }

    public repopulate (): void {
        const dateFrom = DateTimeUtils.formatDate(this.get('dateFrom') || new Date(), 'DD.MM.YYYY');
        const dateTo = DateTimeUtils.formatDate(this.get('dateTo') || new Date(), 'DD.MM.YYYY');

        this.setItems({ text: dateFrom + ' - ' + dateTo });
        this.setSelectValue(this.items[0]);
    }
}

TerceraLookup.extendWith(TerceraDateRangeLookup, {
    data: function () {
        return {
            btnClass: 'js-lookup-showDate',
            btnName: 'showDateLookup',
            name: 'TerceraDateRangeLookup',
            visible: false,
            isReadOnly: true,
            canSelect: false
        };
    }
});
