// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { IndicatorsManagerItem } from '../../cache/ObjectManager/IndicatorsManagerItem';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { ObjectManagerPanelBase } from './ObjectManagerPanelBase';
import { TerceraChartIndicatorStorageRenderer } from '@front/chart/Renderers/Indicators/TerceraChartIndicatorStorageRenderer';
import { type TerceraChartIndicatorRenderer } from '@front/chart/Renderers/Indicators/TerceraChartIndicatorRenderer';
import { TerceraChartActionEnum } from '@front/chart/TerceraChartAction';

export class IndicatorsManagerPanel extends ObjectManagerPanelBase<IndicatorsManagerItem, TerceraChartIndicatorRenderer> {
    protected override readonly ChartActionVisibility: TerceraChartActionEnum = TerceraChartActionEnum.PlateIndicatorVisible;
    protected override readonly ChartActionSettings: TerceraChartActionEnum = TerceraChartActionEnum.PlateIndicatorSettings;
    protected override readonly ChartActionRemove: TerceraChartActionEnum = TerceraChartActionEnum.PlateIndicatorRemove;
    protected override readonly ChartActionDeleteAll: TerceraChartActionEnum = TerceraChartActionEnum.Indicator;

    constructor () {
        super();
        this.Name = 'IndicatorsManagerPanel';
        this.headerLocaleKey = 'chart.indicators';
    }

    public override getType (): PanelNames { return PanelNames.IndicatorsManagerPanel; }

    public override oncomplete (): void {
        super.oncomplete();
        this.Chart.OnAddIndicator.Subscribe(this.AddItemToTable, this);
        this.Chart.OnRemoveIndicator.Subscribe(this.RemoveItemFromTable, this);
    }

    public override onteardown (): void {
        this.Chart.OnAddIndicator.UnSubscribe(this.AddItemToTable, this);
        this.Chart.OnRemoveIndicator.UnSubscribe(this.RemoveItemFromTable, this);
        super.onteardown();
    }

    public AddItemToTable (renderer: TerceraChartIndicatorRenderer): void {
        const quickTable = this.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }

        quickTable.AddItem(new IndicatorsManagerItem(renderer));
    }

    public override populateItemsDirect (): void {
        super.populateItemsDirect();
        if (isNullOrUndefined(this.Chart)) {
            return;
        }

        for (const window of this.Chart.windowsContainer.Windows) {
            const renderer = window.GetRenderer(TerceraChartIndicatorStorageRenderer);
            this.populateItems(renderer.Indicators);
        }
    }

    public populateItems (renderers): void {
        for (const renderer of renderers) {
            this.AddItemToTable(renderer);
        }
    }
}
