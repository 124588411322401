// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type OrderType, OrderTypeMap } from '@shared/utils/Trading/OrderType';
import { type Account } from '../../Account';
import { type Instrument } from '../../Instrument';
import { type OrderEditBase } from '../order-edit/OrderEditBase';
import { type OrderTypeBaseParameters } from './OrderTypeBaseParameters';

// TODO. Refactor. App needs only 1 instance per order type.
// Rename. UGLY.

export class OrderTypeBase {
    public orderEdit: any;
    public modifyOrder: any;

    constructor (orderEdit, modifyOrder) {
        // TODO. Rename. Synonymic names.
        this.orderEdit = orderEdit;
        this.modifyOrder = modifyOrder;
    }

    // TODO. Refactor.
    public localizationKey (): string {
        return OrderTypeMap[this.id()];
    }

    // TODO. Refactor.
    public id (): OrderType {
        return undefined;
    }

    // TODO. Refactor. CheckSession.
    public IsSupported (params: OrderTypeBaseParameters): boolean {
        return OrderTypeBase.IsSupported(this.id(), params.instrument, params.checkSession);
    }

    // TODO. Refactor.
    public static IsSupported (orderTypeId, instrument: Instrument | null, checkSession: any = undefined): boolean {
        if (!instrument) {
            return false;
        }

        checkSession =
            checkSession === undefined
                ? true
                : !!checkSession;

        const route = instrument
            ? instrument.DataCache.getRouteByName(instrument.Route)
            : null;

        return route?.IsAllowableOrderType(orderTypeId) &&
            (!checkSession || instrument.IsAllowOrderType(orderTypeId));
    }

    // TODO. Rename.
    public createOrderEditObject (data): any {
        if (this.orderEdit) {
            return new this.orderEdit(data, this.id());
        }

        return null;
    }

    // TODO. Refactor. Rename. UGLY.
    public createModifyOrderObject (data): OrderEditBase {
        if (this.modifyOrder) {
            return new this.modifyOrder(data, this.id());
        }

        return null;
    }

    public checkRuleForAccount (account: Account | null, rule, instrument: Instrument | null): boolean {
        const dc = account ? account.DataCache : (instrument ? instrument.DataCache : null);
        return dc.isAllowedForAccount(rule, account);
    }
}
