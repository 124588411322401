// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { PFixXmlMessage } from '../Messages/PFixXmlMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class XMLMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): PFixXmlMessage[] {
        // TODO. Ugly. Refactor.
        // Hack for using "Request id + Promise" feature in ProcessorWorker.
        return [new PFixXmlMessage(fieldSet)];
    }
}
