// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { HtmlScrollXY } from '../../Commons/HtmlScroll';
import { TerceraBaseWindowTemplate, TerceraBrokerInfoScreenTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';
import { DataCache } from '../../Commons/DataCache';

export class TerceraBrokerInfoScreen extends TerceraWindowBase {
    constructor () { super(); }

    public override getType (): string { return 'TerceraBrokerInfoScreen'; }

    public override oncomplete (): void {
        super.oncomplete();

        this.fillWithBrokerInfo();
        this.updateBrandingImage();
        this.localize();
    }

    public fillWithBrokerInfo (): void {
        const info = DataCache.BrokerInformation;
        const container = this.find('.js-broker-info-text');

        if (!info || !container) {
            return;
        }

        const infoArr = info.split(';');
        let rowNum = 0;

        for (let i = 0; i < infoArr.length; i++) {
            const d = document.createElement('div');
            d.innerText += infoArr[i] + '\n';
            d.style.width = '309px';
            container.appendChild(d);

            if (infoArr[i]) {
                rowNum++;
            }
        }

        if (rowNum < 5) {
            HtmlScrollXY.addScroll(container, 'x');
            container.style.lineHeight = (container.offsetHeight / rowNum) + 'px';
        } else {
            HtmlScrollXY.addScroll(container, 'xy');
        }
    }

    public override localize (): void {
        void this.set({ header: Resources.getResource('screen.brokerInfo.header') });
    }
}

TerceraWindowBase.extendWith(TerceraBrokerInfoScreen, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            movable: false,
            showHeader: true,
            showFooter: false,
            resizable: false,
            canSelect: false,
            alwaysMiddle: true,
            showFullscreenCloud: true,
            focused: true,
            width: 309,
            height: 439,
            style_addition_body: 'brokerinfo-screen-addition-body',
            style_addition_header: 'brokerinfo-screen-addition-header'
        };
    },
    partials: { bodyPartial: TerceraBrokerInfoScreenTemplate }
});
