// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Control } from '../Control';
import { HotkeysSettingsGroupTemplate } from '../../../templates';
import { type HotkeyGroup } from './HotkeysSettingsContainer';

export class HotkeysSettingsGroup extends Control {
    constructor () { super(); }

    oncomplete (): void {
        super.oncomplete();
        this.on('clickHeader', this.changeStateCollapse);
        this.observe('groupItem', this.createGroupController);
    }

    private createGroupController (value: HotkeyGroup): void {
        if (isNullOrUndefined(value)) {
            return;
        };

        void this.set({ groupName: value.GroupName, groupItems: value.GroupItems });
    }

    private changeStateCollapse (): void {
        const isOpen: boolean = this.get('isOpen');
        void this.set({ isOpen: !isOpen });
    }
}

Control.extendWith(HotkeysSettingsGroup, {
    template: HotkeysSettingsGroupTemplate,
    data: function () {
        return {
            groupName: '',
            groupItems: null,
            isOpen: true
        };
    }
});
