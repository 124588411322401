// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

// строковый филд
import { PFixField } from './PFixField';

export class PFixStringField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = '';
    }

    public override ReadValue (buffer): void {
        this.Value = buffer.ReadString();
    }

    public override Write (buffer): void {
        buffer.WriteShort(this.FieldId);
        buffer.WriteString(this.Value);
    }
}
