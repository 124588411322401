// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { popupErrorHandler } from '../../Utils/AppHandlers';
import { TerceraSLTPNumericTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';
import { type Intervals } from '../../Utils/Instruments/Intervals';

export class TerceraSLTPNumeric extends ContainerControl {
    public static readonly WARNING_HIDE_TIME_MS = 3000;
    public static readonly WARNING_CHART_OFFSET_STYLE = 'js-win-popupWarning';

    public warnPopupTimeout: any = null;

    constructor () { super(); }

    public override getType (): string { return 'TerceraSLTPNumeric'; }

    public override oninit (): void {
        super.oninit();
        this.observe('value', this.onValueChanged);
        this.observe('SLTPChecked', this.onSLTPChecked);
        this.observe('trStopChecked', this.onTrailingStopChecked);
        this.on('terceraNumericInputClick', this.onInputClick); // #86098

        void this.set('customErrorText', Resources.getResource('UserControl.TerceraSLTPNumeric.ValueIsEmpty'));
    }

    public GetInterval (value, forDecimalPlaces): Intervals | null {
        if (isNullOrUndefined(this.Controls?.terceraNumeric)) {
            return null;
        }

        return this.Controls.terceraNumeric.GetInterval(value, forDecimalPlaces);
    }

    public onValueChanged (value): void {
        this.fire(TerceraSLTPNumericEvents.ValueChanged, this, value);
    }

    public onSLTPChecked (value: boolean): void {
        this.fire(TerceraSLTPNumericEvents.SLTPChecked, this, value);
    }

    public onTrailingStopChecked (value: boolean): void {
        this.fire(TerceraSLTPNumericEvents.TrailingStopChecked, this, value);
    }

    public override setFocus (changeEnableState: boolean, needActivate: boolean): void {
        super.setFocus();
        this.Controls.terceraNumeric.setFocus(changeEnableState);
        if (needActivate) {
            this.onInputClick();
        }
    }

    public override gotFocus (): void {
        super.gotFocus();

        if (this.get<boolean>('forChart')) { // #107212
            this.showPopupWarning();
        }
    }

    public onInputClick (): void {
        if (!this.get<boolean>('SLTPChecked')) { // #86098
            void this.set('SLTPChecked', true);
        }
    }

    public showPopupWarning (): void { // #107212
        const cntrl = this.Controls.terceraNumeric;
        if (isNullOrUndefined(cntrl)) return;

        if (!isNullOrUndefined(this.warnPopupTimeout)) {
            clearTimeout(this.warnPopupTimeout);
        }

        this.warnPopupTimeout = setTimeout(() => {
            const popup = popupErrorHandler;
            popup.Show(cntrl, Resources.getResource('UserControl.TerceraSLTPNumeric.ChartOffsetWarning'));
            popup.set({
                elementStyle: TerceraSLTPNumeric.WARNING_CHART_OFFSET_STYLE,
                headerVisible: false
            });

            this.warnPopupTimeout = setTimeout(() => { popup.Hide(cntrl); }, TerceraSLTPNumeric.WARNING_HIDE_TIME_MS);
        }, 10);
    }
}

export enum TerceraSLTPNumericEvents {
    ValueChanged = 'ValueChanged',
    SLTPChecked = 'SLTPChecked',
    TrailingStopChecked = 'TrailingStopChecked'
}

ContainerControl.extendWith(TerceraSLTPNumeric, {
    data: function () {
        return {
            customErrorText: null,
            forChart: false, // #107212
            canEdit: true,
            rightAlignedText: '',
            rightAlignedTextAdditionalClass: ''
        };
    },
    template: TerceraSLTPNumericTemplate
});
