// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DynProperty } from '../../Commons/DynProperty';
import { Resources } from '../../Localizations/Resources';
import { EntitlementProductRequestStatus, EntitlementProductRequestStatusMap, EntitlementProductRequestTypeMap } from '../../Commons/cache/Entitlement/EntitlementPrimitives';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { DataCache } from '../../Commons/DataCache';
import { type SessionSettingsType } from '../../Commons/SessionSettings';

export class EntitlementProductsItem extends BaseItem {
    public requestData: any;

    constructor (requestData, sessionSettings: SessionSettingsType, colors) {
        super(sessionSettings);

        this.requestData = requestData;
        if (colors) {
            if (colors[1]) {
                this.CellForeColorMap[1] = colors[1];
            }

            if (colors[2]) {
                this.CellForeColorMap[2] = colors[2];
            }
        }
        this.QuickTableEditingInfoMap[5] = (function () {
            return new QuickTableEditingInfo(DynProperty.CLOSE_BUTTON);
        })();
    }

    public override ColumnCount (): number {
        return EntitlementProductsItem.columnsParams.length;
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let formattedValue = '';

        switch (column) {
        case 0:
            formattedValue = value ? value.toString() : '';
            break;

        case 1:
            formattedValue = EntitlementProductRequestTypeMap[value];
            break;

        case 2:
            formattedValue = Resources.getResource('panel.product.ProductStatus.Value.' + EntitlementProductRequestStatusMap[value]);
            break;

        case 3:
            formattedValue = !value ? '' : value.toString();
            break;

        case 4:
            formattedValue = DateTimeUtils.FormatToDateAndTime(new Date(value));
            break;

        default:
            formattedValue =
                value === null ||
                    value === '' ||
                    value === undefined ||
                    Number.isNaN(value)
                    ? Resources.getResource('general.N_A')
                    : value.toString();
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (column: number): any {
        const requestData = this.requestData;

        switch (column) {
        case 0:
            return requestData.Name;

        case 1:
            return requestData.ReqType;

        case 2:
            return requestData.ReqStatus;

        case 3:
            return requestData.Comment;

        case 4:
            return requestData.ReqTime;

        case 5:{
            const product = DataCache.EntitlementManager.ProductsCache[requestData.Id];
            if (!product) {
                return null;
            }
            const value = requestData.ReqStatus == EntitlementProductRequestStatus.Processing && product.ReqStatus == EntitlementProductRequestStatus.Processing && product.ReqTime == requestData.ReqTime;
            return value ? 1 : null;
        }

        default:
            return 0;
        }
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = EntitlementProductsItem.columnsParams[column];

        return result;
    }

    public static readonly columnsParams = [
        new ColumnParams('panel.product.ProductName', 70, 0, false, true, false),
        new ColumnParams('panel.product.ProductType', 70, 0, false, true, false),
        new ColumnParams('panel.product.ProductStatus', 70, 0, false, true, false),
        new ColumnParams('panel.product.Comment', 70, 0, false, false, false),
        new ColumnParams('panel.product.DateTime', 70, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        new ColumnParams('panel.product.Cancel', 70, QuickTableColumnType.COL_DEFAULT, false, true, false)
    ];

    public static readonly ListMenuIndex = [
        'panel.product.ProductName',
        'panel.product.ProductType',
        'panel.product.ProductStatus',
        'panel.product.Comment',
        'panel.product.DateTime',
        'panel.product.Cancel'
    ];
}
