// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum VolumeMode {
    Delta,
    DeltaPercent,

    TotalVolume,
    BuyVolume,
    SellVolume,

    AverageTotalSize,
    AverageBuySize,
    AverageSellSize,

    CustomTotalVolumePercent,
    CustomBuyVolumePercent,
    CustomSellVolumePercent,

    Trades,
    OpenInterest,

    BuyVolumePercent,
    SellVolumePercent,

    MaxTickVolume,
    LevelVolumePercent,
    LevelDeltaPercent,
    CustomPair,
    BuySell,

    VolumeImbalance
}

export enum VolumeColoringMode {
    Fixed = 1,
    ByBar = 2,
    ByDifference = 3,
    ByMovingAverage = 4
  }

export const VolumeHistoryMode = {
    Trades: 0,
    Ticks: 1
};
