// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { TrioColorPickerTemplate } from '../../templates';
import { ContainerControl } from './ContainerControl';

export class TrioColorPicker extends ContainerControl {
    public TrioColorChanged: CustomEvent;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.TrioColorChanged = new CustomEvent();
        this.observe('trioColor', this.onTrioColorChanged);
    }

    public onTrioColorChanged (newTrioColor: any): void {
        this.fire('onTrioColorChanged', newTrioColor);
        if (!isNullOrUndefined(this.TrioColorChanged)) {
            this.TrioColorChanged.Raise(this, newTrioColor);
        }
    }
}

ContainerControl.extendWith(TrioColorPicker, {
    data: function () {
        return {
            trioColor: null
        };
    },
    template: TrioColorPickerTemplate
});
