// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { EventItem } from '../cache/EventItem';
import { TerceraMenu } from '../elements/TerceraMenu';
import { ReportMessageTooltip } from '@shared/utils/ReportMessageTooltip';
import { TerceraTicketScreen } from '../screen/TerceraTicketScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { EventType } from '@shared/commons/cache/Event/EventConstants';
import { EventUtils } from '@shared/commons/UtilsClasses/EventUtils';
import { EventCache } from '@shared/commons/cache/Event/EventCache';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type Event } from '@shared/commons/cache/Event/Event';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { type RangeSelectPanel } from './RangeSelectPanel';
import { EventsPanelTemplate } from '../../templates.js';
import { DateTimeRangeOption, DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { contextMenuHandler } from '@shared/utils/AppHandlers';

export class EventsPanel extends ApplicationPanelWithTable<EventItem> {
    public static readonly REMOVE_ALL = 'REMOVE_ALL';

    private readonly selectedEventTypes = new Set<EventType>();
    private readonly eventTypes = new Set<EventType>();
    private startTime: number = 0;
    private finishTime: number = 0;

    public rangeSelectPanel: RangeSelectPanel | null = null;
    private get qt (): QuickTable | null { return this.getQuickTable(); }

    // 'panel.rangeSelect.logs.tooltip': 'Select period for displaying logs'

    public headerLocaleKey: string = 'panel.EventLog.NamePanel';
    public skipOnSave: boolean = true;
    public NeedCalculateRowCount: boolean = false;
    public allowResizers: any = {
        left: true,
        top: true,
        right: false,
        bottom: false
    };

    public EventColoring: any;

    public override getType (): PanelNames { return PanelNames.EventsPanel; }

    public override oncomplete (): void {
        super.oncomplete();

        this.localize();
        this.rangeSelectPanel = this.Controls.eventsRangeSelect;
        this.rangeSelectPanel.getReportEvent.Subscribe(this.rangeSelected, this);
        this.rangeSelectPanel.updateSelectedRange();
        [EventType.Exception, EventType.Trading, EventType.System].forEach(type => { this.adjustEventFilter(type); });

        this.qt.AddToEnd = false;
        this.qt.isMoveColumnAllowed = false;
        this.qt.canShowHeaderMenu = false; // #88411
        this.populateTableContextMenu();
        this.qt.OnPaintedPictureButtonClick.Subscribe(this.getEventTicket, this);

        const evtCache = EventCache;
        evtCache.OnAddEvent.Subscribe(this.OnAddEvent, this);
        evtCache.OnClearEvents.Subscribe(this.OnClearEvents, this);
        this.on('filterShowContextMenu', this.filterShowContextMenuAction);

        const rangeComboBox = this.rangeSelectPanel.Controls.rangeComboBox;
        const comboItems = [
            { text: Resources.getResource(DateTimeRangeOption.DAILY), value: DateTimeRangeOption.DAILY },
            { text: Resources.getResource('panel.rangeSelect.range.all'), value: DateTimeRangeOption.D7 }
        ];
        rangeComboBox.clearItems();
        comboItems.forEach(item => rangeComboBox.addItem(item));

        const rangeTimes = DateTimeUtils.getRangeByOption(comboItems[0].value);
        this.startTime = rangeTimes.startTime;
        this.finishTime = rangeTimes.finishTime;

        this.observe('visible', this.onVisibleChanged);
    }

    public override dispose (): void {
        super.dispose();

        const evtCache = EventCache;
        evtCache.OnAddEvent.UnSubscribe(this.OnAddEvent, this);
        evtCache.OnClearEvents.UnSubscribe(this.OnClearEvents, this);
        this.off('filterShowContextMenu', this.filterShowContextMenuAction);
        this.off('visible', this.onVisibleChanged);
        this.rangeSelectPanel.getReportEvent.UnSubscribe(this.rangeSelected, this);
    }

    public override populateItemsDirect (): void {
        super.populateItemsDirect();

        void this.updateEventItems();
    }

    public onVisibleChanged (newValue, oldValue): void {
        if (newValue) {
            void this.updateEventItems();
        }
    }

    private async updateEventItems (): Promise<void> {
        const eventArr = await EventCache.getEventsFromDB();
        this.OnClearEvents();
        eventArr.forEach(event => { this.OnAddEvent(event); });
    }

    public OnAddEvent (ev: Event): void {
        this.adjustEventFilter(ev.EventType);

        if (this.eventFilter(ev)) {
            const colors = ev.GetColorRow(this.EventColoring);
            this.qt.AddItem(new EventItem(ev, SessionSettings, DataCache, colors.ForeColor, colors.BackColor));
        }
    }

    public OnClearEvents (): void {
        if (!isNullOrUndefined(this.qt)) {
            this.qt.ClearAll();
        }
    }

    public getEventTicket (data): void {
        const row = data.row;
        if (isNullOrUndefined(row)) return;

        const event: Event = row.item.ev;
        const ticket = TerceraTicketScreen.TicketFromEvent(event);
        // TODO. UGLY.
        if (event.EventType === EventType.Trading) {
            ReportMessageTooltip.fixEntries(
                ticket.sortedEntries,
                SessionSettings,
                DataCache.getInstrumentByTradable_ID(event.InstrumentTradableID, event.RouteID),
                event.Account,
                event.ProductType);
        }

        ReportMessageTooltip.localizeEntries(ticket.sortedEntries);
        TerceraTicketScreen.show(ticket);
    }

    public populateTableContextMenu (): void {
        const items = [
            {
                text: Resources.getResource('panel.EventLog.menu.Clear'),
                event: this.onRemoveAll.bind(this),
                enabled: false,
                tag: EventsPanel.REMOVE_ALL
            }
        ];

        this.menuTagDict = TerceraMenu.createTagDictionary(items);
        this.qt.setTableContextMenuItems(items);
    }

    public onRemoveAll (): void {
        const qtRactive = this.quickTableRactive;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (isNullOrUndefined(qt)) {
            return;
        }

        qt.ClearRows();
    }

    public override preparePopup (): void {
        super.preparePopup();

        this.menuTagDict[EventsPanel.REMOVE_ALL].enabled = this.qt.rowsArray.length > 0;
    }

    private adjustEventFilter (eventType: EventType): void {
        if (!this.eventTypes.has(eventType)) {
            this.selectedEventTypes.add(eventType);
        }
        this.eventTypes.add(eventType);
    }

    private eventFilter (event: Event): boolean {
        if (this.startTime < event.timestamp &&
            this.finishTime > event.timestamp &&
            this.selectedEventTypes.has(event.EventType)) {
            return true;
        }
        return false;
    }

    public filterShowContextMenuAction (context, index): void {
        const hiddenArray = [...this.eventTypes].map(eventType => ({
            text: EventUtils.getEventTypeStr(eventType),
            canCheck: true,
            checked: this.selectedEventTypes.has(eventType),
            closeOnClick: false,
            tag: eventType,
            enabled: true,
            event: this.filterContextMenuItemsSelected.bind(this)
        }));

        const offset = this.find('.events-panel-filterTypeBtn').getBoundingClientRect();
        const width = 120;
        const posY = offset.top + window.scrollY + 26;
        const posX = offset.left + window.scrollX;
        const selectedItem = this.get('selectedItem');

        const additionalParams = {
            width: Math.max(this.get('listWidth') || width),
            isComboboxMenu: false,
            initialSelectedValue: selectedItem ? selectedItem.value : null
        };

        contextMenuHandler.Show(hiddenArray, posX, posY, additionalParams);
    }

    private async rangeSelected (startTime: number, finishTime: number): Promise<void> {
        this.startTime = startTime;
        this.finishTime = finishTime;
        void this.updateEventItems();
    }

    public filterContextMenuItemsSelected (filterItem): void {
        if (filterItem.checked) {
            this.selectedEventTypes.add(filterItem.tag);
        } else {
            this.selectedEventTypes.delete(filterItem.tag);
        }
        void this.updateEventItems();
    }
}

ApplicationPanelWithTable.extendWith(EventsPanel,
    {
        partials: {
            bodyPartial: EventsPanelTemplate
        },
        data: function () {
            return {
                zIndex: 1300,
                showHeader: true,
                showFooter: false,
                dockablePanel: false,
                resizable: true,
                width: 680,
                height: 300,
                closeBtnVisible: false,
                movable: false,
                canLinkByAccount: false,
                filterBtnTooltip: Resources.getResource('panel.filterSelect.logs.tooltip')
            };
        },
        headerLocaleKey: 'panel.EventLog.NamePanel',
        skipOnSave: true,
        NeedCalculateRowCount: false,
        allowResizers: {
            left: true,
            top: true,
            right: false,
            bottom: false
        },
        rangeSelectPanel: null
    });
