// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingCustomSchemeLoader } from '@shared/commons/ApplicationLoader';
import { Connection, ConntionInfo } from '@shared/commons/Connection';
import { ConnectionStates } from '@shared/commons/ConnectionEnums';
import { DataCache } from '@shared/commons/DataCache';
import { BrandingUtils } from '@shared/commons/UtilsClasses/BrandingUtils';
import { Resources } from '@shared/localizations/Resources';
import { ResourcesManager } from '@shared/commons/properties/ResourcesManager';
import { ScreensNames } from '@front/controls/UtilsClasses/FactoryConstants';
import { TerceraLoginScreen } from '@front/controls/screen/TerceraLoginScreen';
import { InvestingLoginScreenTemplate } from '../templates';
import { type InvestingChangePasswordScreen } from './InvestingChangePasswordScreen';

export class InvestingLoginScreen extends TerceraLoginScreen {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    getType (): ScreensNames {
        return ScreensNames.InvestingLoginScreen;
    }

    oninit (): void {
        super.oninit();
        ResourcesManager.onLocaleChange.Subscribe(this.localize.bind(this), this);
    }

    connectionStateChange (): void {
        super.connectionStateChange();
        const state = Connection.getState();
        switch (state) {
        case ConnectionStates.CONNECTED:
            void DataCache.StartLoadingWaiting().then(() => {
                DataCache.RecalculateBalances();
                super.fire('loginBtnEventClick');
            });
            break;
        case ConnectionStates.DISCONNECTED:
            /* eslint-disable no-case-declarations */
            const onDisconnect = super.get('onDisconnectHandler');
            if (onDisconnect != null) { onDisconnect(); }
            const err = Connection.getLocalizedConnectionErrorString(super.get('connectionParams.login'));
            if (err != null) { void super.set({ hasError: true }); };
            break;
        }
    }

    oncomplete (): void {
        // NO BASE CALLING
        const loginTextBox = this.Controls.tbLogin;
        const passTextBox = this.Controls.tbPwd;
        loginTextBox.on('keyDown', super.onLoginKeyDown.bind(this));
        passTextBox.on('keyDown', super.onPasswordKeyDown.bind(this));

        loginTextBox.getInputElement().addEventListener('input', super.trimStringIfWasPaste.bind(this));
        passTextBox.getInputElement().addEventListener('input', super.trimStringIfWasPaste.bind(this));

        Connection.onConnectStateChange.Subscribe(this.connectionStateChange.bind(this), this);
        this.updateConnectionInfo();
        ConntionInfo.OnConnectionInfoArrayChanged.Subscribe(this.updateConnectionInfo.bind(this), this);
    }

    updateConnectionInfo (): void {
        void super.set('connectionInfo', ConntionInfo.ConnectionInfoArray);
    }

    onRecoverClicked (): void {
        const connectionParams = super.get('connectionParams');
        const forgotURL = connectionParams.forgotURL;

        if (forgotURL === '') {
            void super.set('showForgotScreen', true);
        } else {
            window.open(forgotURL);
        }
    }

    startLogin (context?, verificationPassword?, isExploreMode?): void {
        void super.set({ hasError: false }).then(() => {
            super.startLogin(context, verificationPassword, isExploreMode);
        });
    }

    public get ChangePasswordScreen (): InvestingChangePasswordScreen {
        return this.Controls.invChangePassScr;
    }

    public override showChangePwdScreen (UserID: number | string = '', OldPass: string, cancelCB: () => void, isTradingPassword: boolean = false): void {
        const changePasswordScreen = this.ChangePasswordScreen;
        if (changePasswordScreen != null) {
            changePasswordScreen.clearFields();
            changePasswordScreen.hidePasswords();
            changePasswordScreen.setScreenData(UserID, OldPass, cancelCB, isTradingPassword);
        }
    }

    dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize.bind(this), this);
        ConntionInfo.OnConnectionInfoArrayChanged.UnSubscribe(this.updateConnectionInfo.bind(this), this);
        super.dispose();
    }

    localize (): void {
        void super.set({
            forgotPasswordLabel: Resources.getResource('screen.LoginScreen.ForgotLinkLabel'),
            loginBtnTxt: Resources.getResource('screen.LoginScreen.LoginButtonText'),
            savePassText: Resources.getResource('screen.LoginScreen.SavePassCheckBox'),
            textExplore: Resources.getResource('screen.LoginScreen.ExploreLinkLabel'),
            tooltipExplore: Resources.getResource('screen.LoginScreen.ExploreLinkLabel.ToolTip')
        });
    }

    SetWebLoginBanner (relativeImgPath: string, propName: string, brandingKey: string): void {
        void super.set({
            'connectionParams.webLoginBanner': relativeImgPath,
            loginLogoSrc: BrandingUtils.GetWebLoginBanner(relativeImgPath, super.get('loginLogoSrc'), propName, brandingKey)
        });
    }

    SetSplashScreen (connData): void {
        const propName = 'BrandingInvestingSplashScreen';
        const cData = connData != null ? connData[propName] : null;
        if (cData == null) { return; }

        const splashSrc = cData.length > 0 ? BrandingUtils.getLocalFileName(cData, propName, connData.brandingKey) : null;
        void super.set('splashImgSrc', splashSrc);
    }

    SetInvestingCustomScheme (connectionName: string): void {
        const customCSSElementID = 'customSchemeForInvestingWEB';
        const oldStyleElement = document.getElementById(customCSSElementID);
        if (oldStyleElement != null) oldStyleElement.parentNode?.removeChild(oldStyleElement);

        return InvestingCustomSchemeLoader(connectionName)
            .then((customCSS) => {
                const styleElement = document.createElement('style');
                styleElement.id = customCSSElementID;
                styleElement.textContent = customCSS;
                document.head.appendChild(styleElement);
            });
    }
}

TerceraLoginScreen.extendWith(InvestingLoginScreen, {
    template: InvestingLoginScreenTemplate,
    data: function () {
        return {
            splashImgSrc: null,
            logo_login_style: 'logo_login',
            loginLogoSrc: 'current_theme/styleInvesting/imgInvesting/LoginScreen/logo_te.svg',
            onDisconnectHandler: null,
            showForgotScreen: false, // is InvestingForgotPasswordScreen visible
            showChangeScreen: false, // is InvestingChangePasswordScreen visible
            textErrorLabel: '',
            hasError: false
        };
    }
});
