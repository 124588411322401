// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DateTimeUtils } from '../Utils/Time/DateTimeUtils';
import { type Instrument } from '../Commons/cache/Instrument';

export class PortfolioGrowth // данные из запроса с типом PortfolioRequestType.PORTFOLIO_GROWTH
{
    public Date: any;
    public AbsoluteAmount: any;

    constructor (date, absoluteAmount) {
        this.Date = date; // Значение “день”
        this.AbsoluteAmount = absoluteAmount; // Значение “абсолютное”
    }
}

export class PortfolioCacheMonthReturn // данные из запроса с типом PortfolioRequestType.MONTHLY_PORTFOLIO_RETURN
{
    public dateFormatted: any;
    public percent: any;
    public absoluteAmount: any;
    public date: any;
    public dateInMonthWordFormat: any;
    public percentFormatted: any;
    public absoluteAmountFormatted: any;
    public DCache: any;

    constructor (date, percent, absoluteAmount) {
        this.Date = date; // Значение “день”
        this.Percent = percent; // Значение “процентов”
        this.AbsoluteAmount = absoluteAmount; // Значение “абсолютное”
    }

    get Date (): any { return this.dateFormatted; }
    set Date (value) {
        this.date = value;
        if (value) {
            this.dateFormatted = DateTimeUtils.formatDate(value, 'MM.YYYY');
            this.dateInMonthWordFormat = DateTimeUtils.formatDate(value, 'MMM, YYYY');
        }
    }

    get Percent (): any { return this.percentFormatted; }
    set Percent (value) {
        this.percent = value;
        this.percentFormatted = value + '%';
    }

    get AbsoluteAmount (): any { return this.absoluteAmountFormatted; }
    set AbsoluteAmount (value) {
        this.absoluteAmount = value;

        const acc = this.DCache?.getPrimaryAccount();
        this.absoluteAmountFormatted = acc?.formatPrice(value);// .split(' ')[0] + ' USD';
    }
}

export class PortfolioCacheInstrumentReturn // данные из запроса с типом PortfolioRequestType.MONTHLY_INSTRUMENT_RETURN
{
    public ins: Instrument | null;
    public instrumentTradableID: any;
    public insName: any;
    public routeId: any;
    public interiorID: any;
    public DCache: any;
    public dateFormatted: any;
    public date: any;
    public percentFormatted: any;
    public percent: any;

    constructor (instrument: Instrument | null, date, percent) {
        this.ins = instrument; // ссылка на инструмент

        if (instrument) {
            this.instrumentTradableID = instrument.InstrumentTradableID; // Идентификатор инструмента
            this.insName = instrument.DisplayName(); // отображаемое имя
            this.routeId = instrument.Route; // routeID
            this.interiorID = instrument.GetInteriorID(); // for color identification
        }

        this.Date = date; // Значение “день”
        this.Percent = percent; // Значение “процентов”
    }

    get Date (): any { return this.dateFormatted; }
    set Date (value) {
        this.date = value;
        if (value) { this.dateFormatted = DateTimeUtils.formatDate(value, 'MMM, YYYY'); }
    }

    get Percent (): any { return this.percentFormatted; }
    set Percent (value) {
        this.percent = value;
        this.percentFormatted = value + '%';
    }

    get Instrument (): any { return this.insName; }

    get Color (): string {
        return this.DCache.PortfolioCache.GetColorByInteriorID(this.interiorID);
    }
}

export class PortfolioCacheDailyInstrumentReturn // данные из запроса с типом PortfolioRequestType.DAILY_INSTRUMENT_RETURN
{
    public Instrument: Instrument;
    public Date: any;
    public Percent: any;

    constructor (instrument, date, percent) {
        this.Instrument = instrument; // ссылка на инструмент
        this.Date = date; // Значение “день”
        this.Percent = percent; // Значение “процентов”
    }
}

export class PortfolioCacheDailyCashReturn // данные из запроса с типом PortfolioRequestType.DAILY_CASH_RETURN
{
    public Date: any;
    public Percent: any;

    constructor (date, percent) {
        this.Date = date; // Значение “день”
        this.Percent = percent; // Значение “процентов”
    }
}

export class PortfolioSystem // данные из мсджа CODE_PFIX_PORTFOLIO_SYSTEM_MESSAGE
{
    public ID: any;
    public Name: any;
    public ManagerLogin: any;

    constructor (id, name, managerLogin) {
        this.ID = id;
        this.Name = name;
        this.ManagerLogin = managerLogin;
    }
}

export class PortfolioInvestor // данные из мсджа CODE_PFIX_PORTFOLIO_INVESTOR_MESSAGE
{
    public PortfolioSystemID: any;
    public ModelID: any;
    public AccountID: any;

    constructor (portfolioSystemID, modelID, accountID) {
        this.PortfolioSystemID = portfolioSystemID;
        this.ModelID = modelID;
        this.AccountID = accountID;
    }
}
