// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum AlertAction {
    PLACE_ORDER_NOTIFY = 0,
    NOTIFY = 1, // Значение по умолчанию
}

export enum AlertEvent {
    DELETED = 1,
    EXECUTED = 2,
    EXPIRED = 3,
    CREATED = 4,
    MODIFIED = 5,
    RESTARTED = 6
}

export enum AlertStatus {
    ACTIVE = 0,
    NOT_ACTIVE = 1
}

// избражение для кнопок в таблице
export enum AlertSubImage {
    PLAY_STOP = 0,
    UPDATE = 1,
    CLOSE = 2
}

export enum AlertActions {
    START = 0,
    STOP = 1,
    DELETE = 2
}

// должен быть множественный выбор параметров./
export enum AlertNotification {
    SHOW_POP_UP = 1, // Значение по умолчанию
    SOUND = 2,
    EMAIL = 4,
    PUSH = 8
    // 1=Popup, 2=Sound, 4=Email, 8=Push  из доки не брать, Макс написал https://tp.traderevolution.com/entity/90577
}

export const AlertNotificationMap =
{
    1: 'Show Pop-up', // Значение по умолчанию
    2: 'Sound',
    4: 'Email',
    8: 'Push'
};

export enum AlertType {
    ASK = 0,
    BID = 1,
    LAST = 2,
    CHANGE = 3,
    VOLUME = 4

    // CERFFFFFFFFFFF
    // DEFAULT = 10
}

export const AlertTypeMap = {
    0: 'Ask',
    1: 'Bid',
    2: 'Last',
    3: 'ChangePr',
    4: 'Volume'
};

export enum AlertImportance {
    HIGH = 1,
    MEDIUM = 5,
    LOW = 10
}

export const AlertImportanceMap = {
    1: 'High',
    5: 'Medium',
    10: 'Low'
};

export enum AlertCondition {
    MORE = 0,
    LESS = 1,
    MORE_EQUAL = 2,
    LESS_EQUAL = 3
}

export const AlertConditionMap = {
    0: 'Greater',
    1: 'Less',
    2: 'Greater or equal',
    3: 'Less or equal'
};

export const AlertSymbolConditionMap = {
    0: '>',
    1: '<',
    2: '>=',
    3: '<='
};

export enum AlertAfterExecute {
    STOP = 0,
    REMOVE = 1
}
export const AlertAfterExecuteMap =
{
    0: 'Stop',
    1: 'Remove'
};
