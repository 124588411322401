// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectTradingSystemMessage extends DirectMessageBaseClass {
    public ImageBytes: any[] | null = null; // logo
    public Login: string | null = null;
    public Name: string | null = null;
    public ID: number | null = null; // TradingSystemID
    public CompanyName: string | null = null;
    public RiskDisclosure: string | null = null;

    constructor () {
        super(Message.CODE_TRADING_SYSTEM_MESSAGE);
    }
}
