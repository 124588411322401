// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { VideoWidgetPanelSideBarTemplate } from '../../../../templates.js';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { Resources } from '../../../../Localizations/Resources';
import { HtmlScroll } from '../../../../Commons/HtmlScroll';
import { DataCache } from '../../../../Commons/DataCache';
import { PanelSideBarBase } from './PanelSideBarBase';
import { ExternalLinksObserver } from '../../../../Commons/UtilsClasses/Observers/ExternalLinksObserver';
import { type BaseExtLink } from '../../../../Commons/cache/ExternalLink/BaseExtLink.js';
import { type YoutubeExtLink } from '../../../../Commons/cache/ExternalLink/YoutubeExtLink.js';
import { ExtLinkUtils } from '../../../../Commons/UtilsClasses/ExtLinkUtils';

export class VideoWidgetPanelSideBar extends PanelSideBarBase {
    externalLinksArrayLengthWasSet = null;

    private readonly _extLinksObserver = new ExternalLinksObserver();

    constructor () { super(); }

    public getType (): ControlsTypes {
        return ControlsTypes.VideoWidgetPanelSideBar;
    }

    oncomplete (): void {
        super.oncomplete();

        this.updateExternalLinks(DataCache.ExternalLinksCache.getYoutubeExtLinks());
        this.init();
    }

    private init (): void {
        this._extLinksObserver.subscribeToAdd(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.subscribeToUpdate(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.subscribeToRemove(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.run();
    }

    onteardown (): void {
        this._extLinksObserver.unsubscribeToAdd(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.unsubscribeToUpdate(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.unsubscribeToRemove(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.stop();
    }

    private readonly onUpdateExternalLinkEvent = (link: BaseExtLink): void => {
        this.updateExternalLinks(DataCache.ExternalLinksCache.getYoutubeExtLinks());
    };

    private updateExternalLinks (links: YoutubeExtLink[]): void {
        this.externalLinksArrayLengthWasSet = links.length;

        void this.set({
            visibleCardsNum: this.externalLinksArrayLengthWasSet,
            loaded: links.length !== 0,
            externalLinks: ExtLinkUtils.sortYoutubeLinks(links)
        });
        this.allVideosScrollAdd();
    }

    private allVideosScrollAdd (): void {
        const allVideos = this.find('.videoWidgetPanelSideBar');
        if (!isNullOrUndefined(allVideos)) {
            HtmlScroll.addScroll(allVideos);
        }
    }

    public localize (): void {
        void this.set({
            _noDataText: Resources.getResource('panel.VideoWidget.noDataText'),
            isLoadingText: Resources.getResource('panel.VideoWidget.isLoadingText')
        });
    }
};

PanelSideBarBase.extendWith(VideoWidgetPanelSideBar,
    {
        template: VideoWidgetPanelSideBarTemplate,
        data: function () {
            return {
                externalLinks: [],
                visibleCardsNum: null, // number of visible external links (videos) in widget
                cards: {},
                loaded: false, // Determines whether the loading of data from the API or MongoDB has fully completed yet

                _noDataText: '', // localized text of noData info
                isLoadingText: '', // localized text of IsLoading info

                cacheReference: null, // reference to DataCache.ExternalLinksCache
                noDataImg: 'videoNoData'
            };
        },
        computed: {
            noDataText:
            {
                get: function () {
                    const visibleCardsNum = this.get('visibleCardsNum');
                    const isLoadingText = this.get('isLoadingText');
                    const _noDataText = this.get('_noDataText');
                    return visibleCardsNum ? isLoadingText : _noDataText;
                },
                set: function (value) { }
            }
        }
    });
