// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectExternalLinkMessage } from '../../../Utils/DirectMessages/DirectExternalLinkMessage';
import { Resources } from '../../../Localizations/Resources';
import { type BrandingAlias } from './Branding/BrandingAlias';
import { ExternalLinkType } from './Branding/ExternalLinkType';
import { type BrandingToolParams } from './Branding/BrandingToolParams';
import { ExtLinkClientTab } from './ExtLinkClientTab';
import { ResourcesManager } from '../../properties/ResourcesManager';

export declare type UpdateValue = (object: any) => void;

enum AttachingArea {
    PopUp = 0,
    AttachPanel = 1
}

export class BaseExtLink {
    public id: number = -1;
    public updateValue?: UpdateValue;

    private _urlStr: string = '';
    public name: string = '';
    public clientTab: ExtLinkClientTab = ExtLinkClientTab.MORE;
    public clientTabName: string = '';
    public clientUseInternalBrowser: boolean = false;
    public aliases: Map<string, BrandingAlias> = new Map<string, BrandingAlias>();
    public brandingToolExtResource: ExternalLinkType = ExternalLinkType.NONE;
    public attachingArea: AttachingArea | null = AttachingArea.PopUp;

    protected url?: URL | null = null;

    get urlStr (): string {
        return this._urlStr;
    }

    set urlStr (value: string) {
        this._urlStr = value;
        this.generateUrl();
    }

    public update (brandingTool: BrandingToolParams | null, extLinkMessage: DirectExternalLinkMessage): void {
        this.id = extLinkMessage.Id ?? this.id;
        this.name = extLinkMessage.Name ?? this.name;
        this.urlStr = extLinkMessage.Url ?? this.urlStr;
        this.clientUseInternalBrowser = extLinkMessage.UseInternalBrowser ?? this.clientUseInternalBrowser;
        this.brandingToolExtResource = extLinkMessage.ExternalResource ?? this.brandingToolExtResource;
        this.clientTab = brandingTool?.clientTab ?? this.clientTab;
        this.clientTabName = brandingTool?.clientTabName ?? this.clientTabName;
        this.attachingArea = brandingTool?.attachingArea ?? this.attachingArea;
        this.aliases.clear();
        brandingTool?.aliases?.forEach(alias => {
            this.aliases.set(alias.lang, alias);
        });
    }

    public getLocalizedToolNameOrNull (): string | null {
        const alias = this.aliases.get(ResourcesManager.GetCurrentAppLang());
        if (!isNullOrUndefined(alias?.toolName)) {
            return alias.toolName;
        }
        return null;
    }

    public getLocalizedTabNameOrNull (): string | null {
        const alias = this.aliases.get(ResourcesManager.GetCurrentAppLang());
        if (!isNullOrUndefined(alias?.tabName)) {
            return alias.tabName;
        }
        return null;
    }

    public getUrl (): URL | null {
        this.generateUrl();
        return this.url;
    }

    protected generateUrl (): void {
        // Implementation logic should go here
    }

    public isAttached (): boolean {
        return this.attachingArea === AttachingArea.AttachPanel;
    }
}
