// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Point } from '@shared/commons/Geometry';
import { type TerceraChartWindowBase } from '../Windows/TerceraChartWindowBase';
import { type LayersEnum } from './LayersEnum';

export class TerceraChartMouseEventArgs<MouseEvent = any> {
    public window: TerceraChartWindowBase;
    public NeedRedraw: boolean | LayersEnum | LayersEnum[] = false;
    public _baseEvent: MouseEvent;
    public Button: any;
    public X: number;
    public Y: number;
    public Delta: number;
    public Location: Point;
    public ClientLocation: Point;

    constructor (ww: TerceraChartWindowBase, ev) {
        this.window = ww;
        this._baseEvent = ev;
        this.Button = ev.button;
        this.X = ev.offsetX;
        this.Y = ev.offsetY;
        this.Delta = -1 * parseInt(ev.deltaY);
        this.Location = new Point(this.X, this.Y);
        this.ClientLocation = new Point(ev.clientX, ev.clientY);
    }
}
