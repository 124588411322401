// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type IAccountFollower } from '@shared/utils/Interfaces/Response/IAccountFollower';
import { CopyTradingPanelTemplate } from '../../templates';
import { PanelLocKeys, PanelNames } from '../UtilsClasses/FactoryConstants';
import { ApplicationPanel } from './ApplicationPanel';
import { DataCache } from '@shared/commons/DataCache';
import { FollowerRequestStatus } from '@shared/utils/Account/FollowerRequestStatus';
import { AccountMenuItem } from '@shared/commons/AccountWidget/AccountMenuItem';
import { BaseSettingsUtils } from '@shared/commons/UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { ConfirmationTypesEnum } from '@shared/utils/Trading/ConfirmationTypesEnum';
import { Resources } from '@shared/localizations/Resources';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { Account } from '@shared/commons/cache/Account';
import { type DirectAccountFollowerResponseMessage } from '@shared/utils/DirectMessages/DirectAccountFollowerResponseMessage';
import { Connection } from '@shared/commons/Connection';
import { ConnectionStates } from '@shared/commons/ConnectionEnums';

export class CopyTradingPanel extends ApplicationPanel {
    get accModeWidth (): number { return this.get('accModeWidth'); }
    set accModeWidth (value: number) { void this.set({ accModeWidth: value }); }

    constructor () {
        super();
        this.headerLocaleKey = PanelLocKeys[this.getType()];
    }

    getType (): PanelNames { return PanelNames.CopyTradingPanel; }

    oninit (): void {
        super.oninit();
        this.observe('visible', () => { this.refreshPanelData(); });
        Account.FollowerIDsChanged.Subscribe(this.refreshPanelData, this);
        Account.TradeStatusChanged.Subscribe(this.refreshPanelData, this);
    }

    oncomplete (): void {
        super.oncomplete();

        DataCache.OnAddAccount.Subscribe(this.refreshPanelData, this);
        DataCache.OnAccountFollowerResponse.Subscribe(this.onAccountFollowerResponse, this);
        Connection.onConnectStateChange.Subscribe(this.connectionStateChange, this);

        this.refreshPanelData();
        this.center();
        this.localize();
        this.updateAccModeWidth();
    }

    connectionStateChange (): void {
        const state = Connection.getState();
        switch (state) {
        case ConnectionStates.CONNECTION_LOST:
        case ConnectionStates.DISCONNECTED:
            this.close();
            break;
        }
    }

    localize (): void {
        super.localize();
        void this.set({
            fromAccountText: Resources.getResource('panel.CopyTrading.item.fromAccount'),
            toAccountsText: Resources.getResource('panel.CopyTrading.item.toAccount'),
            btnStartText: Resources.getResource('panel.CopyTrading.RowControl.ButtonStart.Text'),
            btnStopText: Resources.getResource('panel.CopyTrading.RowControl.ButtonStop.Text'),
            tooltipStartBtn: Resources.getResource('panel.CopyTrading.RowControl.ButtonStart.Tooltip'),
            tooltipStopBtn: Resources.getResource('panel.CopyTrading.RowControl.ButtonStop.Tooltip'),
            tooltipStartDisabled: Resources.getResource('panel.CopyTrading.RowControl.ButtonStart.Disabled.Tooltip')
        });
    }

    dispose (): void {
        Account.TradeStatusChanged.UnSubscribe(this.refreshPanelData, this);
        Account.FollowerIDsChanged.UnSubscribe(this.refreshPanelData, this);
        DataCache.OnAddAccount.UnSubscribe(this.refreshPanelData, this);
        DataCache.OnAccountFollowerResponse.UnSubscribe(this.onAccountFollowerResponse, this);
        Connection.onConnectStateChange.UnSubscribe(this.connectionStateChange, this);
        super.dispose();
    }

    /**
     * Handles the Start/Stop button click event for a specific account row.
     * Sends a DISABLE_FOLLOWERS request for selected items for Stop,
     * and a FOLLOW request with all selectedFollowerIDs for Start.
     *
     * @param {number} rowIndex - The zero-based index of the account row.
     * @returns {void}
     */
    async onStartOrStopButtonClick (rowIndex: number): Promise<boolean> {
        const confirmed = !this.isUseConfirmation() || await this.showMessageBoxAsync(rowIndex);
        if (confirmed) {
            this.sendAccountFollowerRequest(rowIndex);
        }
        return confirmed;
    }

    sendAccountFollowerRequest (rowIndex: number): void {
        const masterAccount = this.get(`accounts[${rowIndex}]`);
        const masterFollowerIDs = new Set<number>(masterAccount.FollowerIDs);
        const isStarted = masterFollowerIDs.size > 0;

        const selectedFollowerIDs = new Set<number>(
            (this.get(`selectedItems[${rowIndex}]`)).map((account) => account.ID)
        );

        const followerIDsArray = Array.from(selectedFollowerIDs);
        const filteredFollowerIDs = isStarted
            ? followerIDsArray
            : followerIDsArray.filter((id) => !masterFollowerIDs.has(id));

        if (filteredFollowerIDs.length > 0) {
            const requestArgs: IAccountFollower = {
                MasterId: masterAccount.ID,
                FollowerIDs: filteredFollowerIDs,
                Status: isStarted ? FollowerRequestStatus.DISABLE_FOLLOWERS : FollowerRequestStatus.FOLLOW
            };

            this.sendRequestWithErrorHandler(requestArgs, rowIndex, isStarted);
        }
    }

    private onAccountFollowerResponse (msg: DirectAccountFollowerResponseMessage): void {
        if (!this.rendered) { return; }

        if (msg.IsReject) {
            const masterAccount = DataCache.GetAccountById(msg.MasterId.toString());
            const accounts = this.get('accounts');
            const rowIndex = accounts.indexOf(masterAccount);
            const isStarted = this.get(`isStarted[${rowIndex}]`);
            const rejectAccountName = DataCache.GetAccountById(msg.RejectAccountID?.toString())?.toString() ?? '';
            this.showErrorMessageBox(!isStarted, msg.Status, rejectAccountName);
        }
    }

    private sendRequestWithErrorHandler (requestArgs: IAccountFollower, rowIndex: number, isStarted: boolean): void {
        void DataCache.SendAccountFollowerRequest(requestArgs);
    }

    private showErrorMessageBox (isForStart: boolean, status: FollowerRequestStatus, accountName: string): void {
        const headerText = Resources.getResource('screen.error.title');
        const bodyLocalKey = (() => {
            switch (status) {
            case FollowerRequestStatus.ALREADY_FOLLOWING_ANOTHER_MASTER:
                return 'panel.CopyTrading.Error.Reject.AlreadyFollowingMaster';
            case FollowerRequestStatus.MASTER_ACCOUNT_CANNOT_BE_FOLLOWER:
                return 'panel.CopyTrading.Error.Reject.MasterCannotFollow';
            default:
                return isForStart
                    ? 'panel.CopyTrading.Error.Start.Message'
                    : 'panel.CopyTrading.Error.Stop.Message';
            }
        })();

        TerceraMessageBox.Show(
            headerText,
            Resources.getResource(bodyLocalKey, [accountName]),
            MessageBoxType.Error,
            null, null, false, true
        );
    }

    private isUseConfirmation (): boolean {
        return BaseSettingsUtils.useConfirmation(ConfirmationTypesEnum.CopyTradingStartStopFollow);
    }

    private async showMessageBoxAsync (rowIndex: number): Promise<boolean> {
        return await new Promise(resolve => {
            const isStopRequest = this.get('isStarted')[rowIndex];
            const headerKey = isStopRequest
                ? 'panel.CopyTrading.Confirmation.Stop.Caption'
                : 'panel.CopyTrading.Confirmation.Start.Caption';
            const bodyKey = isStopRequest
                ? 'panel.CopyTrading.Confirmation.Stop.Message'
                : 'panel.CopyTrading.Confirmation.Start.Message';

            TerceraMessageBox.Show(
                Resources.getResource(headerKey),
                Resources.getResource(bodyKey),
                MessageBoxType.Question,
                showNext => {
                    BaseSettingsUtils.updateConfirmation(ConfirmationTypesEnum.CopyTradingStartStopFollow, showNext);
                    resolve(true);
                },
                showNext => {
                    BaseSettingsUtils.updateConfirmation(ConfirmationTypesEnum.CopyTradingStartStopFollow, showNext);
                    resolve(false);
                },
                this.isUseConfirmation()
            );
        });
    }

    private refreshPanelData (): void {
        const accounts = DataCache.getSortedAccountsArray();
        const ownedAccounts = Object.values(DataCache.getOwnedAccounts());

        void this.set({
            accounts,
            accountLabelItems: accounts.map(account => new AccountMenuItem(account)),
            selectedItems: accounts.map(account => account.FollowerIDs.map(id => DataCache.getOwnedAccounts()[id]).filter(Boolean)),
            isStarted: accounts.map(account => account.FollowerIDs.length > 0),
            excludedAccounts: accounts.map(account =>
                [account, ...ownedAccounts.filter(ownedAcc => !accounts.includes(ownedAcc))]
            )
        });
    }

    private updateAccModeWidth (): void {
        const accounts = DataCache.getSortedAccountsArray();
        const items = accounts.map(account => new AccountMenuItem(account));

        items.forEach(item => {
            const itemWidth = item.GetModeWidth();
            if (itemWidth > this.accModeWidth) {
                this.accModeWidth = itemWidth;
            }
        });
    }

    private getBtnTooltip (index: number): string {
        const { isStarted, selectedItems, tooltipStopBtn, tooltipStartBtn, tooltipStartDisabled } = this.get();
        return isStarted[index]
            ? tooltipStopBtn
            : selectedItems[index].length > 0
                ? tooltipStartBtn
                : tooltipStartDisabled;
    }
}

ApplicationPanel.extendWith(CopyTradingPanel, {
    data: () => ({
        accounts: null, // Account[]
        selectedItems: null, // Account[][]
        accountLabelItems: null, // AccountMenuItem[]
        isStarted: null, // - boolean[]: for each from accounts

        fromAccountText: '',
        toAccountText: '',
        btnStartText: '',
        btnStopText: '',
        tooltipStartBtn: '',
        tooltipStopBtn: '',

        zIndex: 300,
        resizable: false,
        dockablePanel: false,
        showHeader: true,
        showFooter: false,
        style_addition_header: 'js-CopyTrading-Header',
        accModeWidth: 30,
        needAdditionalDropdownOffset: false,
        addtype: true
    }),
    partials: {
        bodyPartial: CopyTradingPanelTemplate
    }
});
