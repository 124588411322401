// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum ConfirmationTypesEnum {
    OrderCancel,
    OrderPlace,
    PositionClose,
    PositionReverse,
    Modify,
    DealTickets,
    OrderPlaceAlert,
    AlertCancel,
    PositionSizeCalculatorInfoWindow,
    SubscriptionsAndUnsubscriptions,
    ShowMarginDealTicketsOnWorkspace,
    CopyTradingStartStopFollow
}
