// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Rectangle } from '../../../Commons/Geometry';

export class QuickTreeNode {
    public nodeText: string;
    public visible = true;
    public selected = false;
    public nodeId = '';
    public nodeLevel = 0;
    public hasVisibleChild = false;
    public childNodes: this[] = [];
    public parentNode: this | null = null;
    public collapsed = true;
    public CollapseRectangle = new Rectangle();
    public CheckBoxRectangle = new Rectangle();
    public ImgRectangle = new Rectangle();
    public TextRectangle = new Rectangle();
    public DisplayRectangle = new Rectangle();
    public defaultImage: any = null;
    public selectedImage: any = null;
    public tag: any = null;
    public checked = false;
    public font: string | null = null;
    public fontColor: any = null;
    public textPattern: string | null = null;
    public checkedChildsCount = 0;
    public InstrumentNameText: string;
    public InstrumentDescriptionText: string;
    public InstrumentTradingExchangeText: string;
    public SpecificTag: any;
    public sortingStop: boolean;

    constructor (text?) {
        this.nodeText = text;
    }

    public AddChildNode (newNode: this): void {
        newNode.parentNode = this;
        this.childNodes.push(newNode);
    }

    public AddChildNodeRange (newNodesArray: this[]): void {
        const len = newNodesArray.length;
        for (let i = 0; i < len; i++) {
            newNodesArray[i].parentNode = this;
        }

        this.childNodes = this.childNodes.concat(newNodesArray);
    }

    public RemoveChildNode (index: number): void {
        this.childNodes.splice(index - 1, index);
    }

    public RemoveAllChildNodes (): void {
        this.childNodes = [];
    }

    public HasChildNodes (): boolean {
        return this.childNodes.length > 0;
    }

    public GetFirstChild (): this {
        return this.childNodes[0] || null;
    }

    public GetLastChild (): this | null {
        return (this.childNodes.length > 0) ? this.childNodes[this.childNodes.length - 1] : null;
    }

    public getFullPath (): string {
        let path = this.nodeText;
        let parent = this.parentNode;
        while (parent !== null) {
            path += parent.nodeText;
            parent = parent.parentNode;
        }
        return path;
    }

    public setCheck (value: boolean): void {
        if (this.checked === value) {
            return;
        }

        const parent = this.parentNode;
        if (!isNullOrUndefined(parent)) {
            const previousCount = parent.checkedChildsCount;
            if (value) {
                parent.checkedChildsCount++;
            } else {
                parent.checkedChildsCount--;
            }

            if (parent.checkedChildsCount === parent.childNodes.length) {
                parent.setCheck(true);
            } else if (previousCount === parent.childNodes.length) {
                parent.setCheck(false);
            }
        }
        this.checked = value;
    }

    public DoCollapse (): void {
        this.collapsed = !this.collapsed;
    }
}
