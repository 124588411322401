// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectPortfolioModelMessage } from '../../../../Utils/DirectMessages/DirectPortfolioModelMessage';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { SEPARATOR } from '../../../../Utils/Enums/Constants';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class PortfolioModelMessageProcessor extends MessageProcessorBase {
    public static CASH_KEY = 'Cash';

    public override decode (fieldSet: PFixFieldSet): DirectPortfolioModelMessage[] {
        const message = new DirectPortfolioModelMessage();
        const groups = fieldSet.GetGroups(FieldsFactory.PORTFOLIO_MODEL_INSTRUMENT_ENTRY_GROUP);

        message.ModelID = fieldSet.GetValue(FieldsFactory.FIELD_PORTFOLIO_MODEL_ID);
        message.PortfolioSystemID = fieldSet.GetValue(FieldsFactory.FIELD_PORTFOLIO_SYSTEM_ID);
        message.ModelName = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        message.Description = fieldSet.GetValue(FieldsFactory.FIELD_DESCRIPTION);
        message.DiagramPercent = fieldSet.GetValue(FieldsFactory.FIELD_PERCENT);

        message.ByInstrumentPercent = {};

        if (groups) {
            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                const tradableID = group.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
                const routeID = group.GetValue(FieldsFactory.FIELD_ROUTE_ID);
                const percent = group.GetValue(FieldsFactory.FIELD_PERCENT);

                let key = PortfolioModelMessageProcessor.CASH_KEY;
                if (tradableID && routeID) {
                    key = tradableID + SEPARATOR + routeID;
                }

                message.ByInstrumentPercent[key] = percent;
            }
        }

        return [message];
    }
}
