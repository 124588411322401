// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PortfolioInvestingTemplate } from '../templates.js';
import Ractive from 'ractive';

export class PortfolioInvestingPanel extends Ractive {
    constructor () { super(); }

    public getType (): string { return 'PortfolioInvestingPanel'; }

    public override oninit (): void { }
    public override oncomplete (): void { }
}

Ractive.extendWith(PortfolioInvestingPanel, {
    template: PortfolioInvestingTemplate,
    data: function () {
        return {
            selectedAccountName: ''
        };
    }

});
