// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Point } from '@shared/commons/Geometry';
import { LayersEnum, TerceraChartBaseRenderer } from '../TerceraChartBaseRenderer';
import { Pen, PenStyle, SolidBrush } from '@shared/commons/Graphics';
import { DialogResultsStuct } from '@shared/commons/UtilsClasses/DialogResultsStuct';
import { propertySetupScreenForIndicatorHandler } from '@shared/utils/AppHandlers';
import { TerceraChartPriceScaleRenderer } from '../../../Chart/Renderers/Scales/TerceraChartPriceScaleRenderer';
import { PriceFormatter } from '@shared/utils/Instruments/PriceFormatter';
import { DrawPointer, DrawPointerTypeEnum } from '../../Utils/DrawPointer';
import { type TerceraChartCashItemSeries } from '../../Series/TerceraChartCashItemSeries';
import { type ISeriesRenderer } from '../Utils/ISeriesRenderer';
import { type Indicator } from '@shared/commons/cache/indicators/Indicator';
import { type TerceraChart } from '../../TerceraChart';
export class TerceraChartBaseIndicatorRenderer<Chart extends TerceraChart = TerceraChart> extends TerceraChartBaseRenderer<Chart> implements ISeriesRenderer<TerceraChartCashItemSeries> {
    WindowsNumber: number;
    OnTop: boolean;
    Selected: boolean;
    InvalidState: boolean;

    Series: TerceraChartCashItemSeries = null;

    constructor (chart: Chart, isSeparateWindow: boolean) {
        super(chart);
        this.WindowsNumber = 0;
        this.OnTop = true;
        this.Selected = false;
        this.InvalidState = false;
        if (isSeparateWindow) { this.WindowsNumber = chart.windowsContainer.Windows.length; }
        this.SetClassName('TerceraChartBaseIndicatorRenderer');
    }

    public get Indicator (): Indicator { return null; }

    paintStyledWidthLineNew (
        gr: any,
        arraypoint: any,
        indicatorIsSelected: boolean,
        window: any,
        isSeparateWindow: boolean,
        cashItemSeries: any,
        drawBegin: number,
        emptyValue: number,
        width: number,
        chcolor: number,
        chstyle: any,
        UseRound: boolean,
        mergeLineParts: boolean,
        markersVector: any,
        converter: any
    ): void {
        if (gr === null || arraypoint === null || arraypoint.Length === 0) { return; }

        if (!converter) { converter = null; }

        const vector = arraypoint;
        const length = vector.Length;
        const timeShift = vector !== null ? vector.TimeShift : 0;
        let curvePoints: AdvancedChartBaseIndicatorRendererPointColor[] = [];

        const XScaleCoefMultiply = window.XScale;

        const from = Math.floor(window.i1);
        const to = Math.floor(window.i1 - window.im());

        const CR = window.ClientRectangle;

        let curX = CR.X + CR.Width - XScaleCoefMultiply;
        const screenData = cashItemSeries.ChartScreenData.Storage;
        if (screenData.length > 0) {
            const lastScreenDate = screenData[screenData.length - 1].Time;
            curX = window.PointsConverter.GetScreenXbyTime(lastScreenDate);
        }
        const middle = XScaleCoefMultiply / 2;

        for (let i = from; i >= to; i--) {
            let value: any = null;
            const index = cashItemSeries.GetIndex(i - timeShift);
            if (index > -1) { value = vector.get(index); }

            let currentPointColor = chcolor;
            if (index >= 0 && markersVector !== null && index < markersVector.Count) {
                const tmpcolor = markersVector.getColor(index);
                if (tmpcolor) { currentPointColor = tmpcolor; }
            }

            if (
                index < length &&
                (index >= drawBegin && index !== -1) &&
                (value > 0 || isSeparateWindow) &&
                Math.abs(value) < Number.MAX_VALUE &&
                value !== emptyValue
            ) {
                if (converter != null) { value = converter.Calculate(value); }

                const cv = window.PointsConverter.GetScreenY(value);

                if (isNaN(cv)) { continue; }

                const point1 = new Point(curX + middle, cv);

                if (curvePoints.length === 0) { curvePoints.push(new AdvancedChartBaseIndicatorRendererPointColor(point1, currentPointColor)); } else {
                    const prevPoint = curvePoints[curvePoints.length - 1].Point;
                    const dx = Math.abs(prevPoint.X - point1.X);
                    const dy = Math.abs(prevPoint.Y - point1.Y);
                    if (dx > 0 || dy > 0) { curvePoints.push(new AdvancedChartBaseIndicatorRendererPointColor(point1, currentPointColor)); }
                }
            } else if (!mergeLineParts && curvePoints.length >= 1) {
                if (curvePoints.length > 1) {
                    TerceraChartBaseIndicatorRenderer.DrawLinePart(
                        gr,
                        indicatorIsSelected,
                        chstyle,
                        curvePoints,
                        chcolor,
                        width,
                        UseRound
                    );
                } else {
                    // Decision to not draw anything
                }

                curvePoints = [];
            }

            curX -= XScaleCoefMultiply;
        }

        if (curvePoints.length > 1) {
            TerceraChartBaseIndicatorRenderer.DrawLinePart(
                gr,
                indicatorIsSelected,
                chstyle,
                curvePoints,
                chcolor,
                width,
                UseRound
            );
        }
    }

    public DrawPlate (window: any, p: any, vector: any, color: any) {
        const cashItemSeries = p.mainPriceRenderer.Series;

        // +++ плашки
        const barIndex = cashItemSeries.GetIndex(cashItemSeries.Count() - 1); // last bar
        if (barIndex >= 0) {
            const value = vector.get(barIndex);
            if (!value) { return; }
            let text = '';

            if (value > window.FminFloatY && value < window.FmaxFloatY) {
                if (p.layerId === LayersEnum.Quotes) {
                    if (!text) { text = this.FormatPlateText(value, p, cashItemSeries.settings.DataType, window.rightYScaleRenderer); }
                    p.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.Indicator, value, new SolidBrush(color), text));
                }
            }
        }
    }

    public FormatPlateText (value: any, advParamsObj: any, seriesDataType: any, scaleRenderer: any): string {
        const formattedValue = TerceraChartPriceScaleRenderer.FormatPrice(value, advParamsObj, seriesDataType, scaleRenderer);

        return (!formattedValue) ? PriceFormatter.formatPrice(value, 5) : formattedValue;
    };

    static DrawLinePart (
        gr: any,
        indicatorIsSelected: boolean,
        chstyle: any,
        curvePoints: AdvancedChartBaseIndicatorRendererPointColor[],
        chcolor: number,
        width: number,
        UseRound: boolean
    ) {
        if (indicatorIsSelected) { width += 2; }

        if (chstyle == PenStyle.DotChart) { width += 1; }

        const defaultLineColor = chcolor;
        let tempPoints: Point[] = [];

        let currentColor = curvePoints[0].Color === null ? chcolor : curvePoints[0].Color;
        for (let i = 0; i < curvePoints.length; i++) {
            if (curvePoints[i].Color === currentColor && i !== curvePoints.length - 1) { tempPoints.push(curvePoints[i].Point); } else {
                const curCol = currentColor;
                const pen = new Pen(curCol, width, chstyle);

                tempPoints.push(curvePoints[i].Point);

                if (tempPoints.length > 1) { gr.DrawLines(pen, tempPoints); }

                tempPoints = [];
                tempPoints.push(curvePoints[i].Point);
                currentColor = curvePoints[i].Color === null ? defaultLineColor : curvePoints[i].Color;
            }
        }
    }

    editProperties (chart: any) {
        propertySetupScreenForIndicatorHandler.getHandler()?.editProperty(this, this.editPropertiesCallBack, this.Indicator.Name);
    }

    editPropertiesCallBack (dialogResults: any) {
        if (dialogResults.Button === DialogResultsStuct.CallBackInitiator.okClick) {
            this.indicatorEditPropertiesSuccessfully(dialogResults.Data);
            this.chart.AddIndicatorProcess(this);
            this.chart.CorrectIndicatorWindows();
            this.chart.CheckBarsForIndicator();
        } else if (
            dialogResults.Button === DialogResultsStuct.CallBackInitiator.cancelClick ||
            dialogResults.Button === DialogResultsStuct.CallBackInitiator.closeClick
        ) {
            this.indicatorEditNotDone();
        } else return;
    }

    editPropertiesCallBackOnPlacedIndicator (dialogResults: any): void {
        if (dialogResults.Button === DialogResultsStuct.CallBackInitiator.okClick) {
            this.indicatorEditPropertiesSuccessfully(dialogResults.Data);
            this.chart.CorrectIndicatorWindows();
            this.chart.CheckBarsForIndicator();
        }
    }

    indicatorEditPropertiesSuccessfully (properties: any): void {
        this.callBack(properties);
    }

    indicatorEditNotDone (): void {
        this.Indicator.Dispose();
        this.Dispose();
    };
}
class AdvancedChartBaseIndicatorRendererPointColor {
    Point: Point;
    Color: number;

    constructor (point: Point, color: number) {
        this.Point = point;
        this.Color = color;
    }
}
