// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum CouponCycle {
    OneMonth = 0,
    ThreeMonth = 1,
    SixMonth = 2,
    Year = 3
}

export enum EXT_INSTRUMENT_MESSAGE {
    INSTRUMENT_TYPE = 'Instrument name',
    SYMBOL = 'Symbol',
    TOKEN = 'Token',
    MARKET_TYPE = 'Market type',
    SERIES = 'Series',
    TRADINGUNIT = 'Trading unit',
    PRICEQUOTEUNIT = 'Price quote unit',
    DELIVERYUNIT = 'Delivery unit',
    LOTSIZE = 'Lot size',
    TENDERPERIODSTARTDATE = 'Tender period start date',
    TENDERPERIODENDDATE = 'Tender period end date',
    FLAG_GSM = 'GSM Flag',
    FLAG_ESM = 'ESM Flag',
    FLAG_LONG_ASM = 'Long term ASM Flag',
    FLAG_SHORT_ASM = 'Short term ASM Flag'
}

export enum ExerciseStyle {
    EUROPEAN = 0,
    AMERICAN = 1
}

export enum VariableTickMovemementType {
    Unknown = 0,
    Left = 1,
    Right = 2
}
