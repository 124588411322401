// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';
import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { type Font, SolidBrush } from '../../Graphics';
import { DynProperty } from '../../DynProperty';

export class ElliotWavesDataCacheTool extends DataCacheTool {
    public labelBackgroundColor: string;
    public labelBackgroundBrush: SolidBrush;
    public font: Font;

    constructor (toolType: DataCacheToolType) {
        super();
        this.ToolType = toolType;

        switch (toolType) {
        case DataCacheToolType.ElliottImpulse:
            this.InitPoints(6);
            this.Color = '#00FF0B'; // "#00FF0D"
            break;
        case DataCacheToolType.ElliottCorrection:
            this.InitPoints(4);
            this.Color = '#FFB300'; // "#EBB400"
            break;
        case DataCacheToolType.ElliottTriangle:
            this.InitPoints(6);
            this.Color = '#FF3400'; // "#EB3300"
            break;
        case DataCacheToolType.ElliottTripleCombo:
            this.InitPoints(6);
            this.Color = '#FF00C7'; // "#EB00C8"
            break;
        case DataCacheToolType.ElliottDoubleCombo:
            this.InitPoints(4);
            this.Color = '#00FFE0'; // "#00FFE1"
            break;
        }
    }

    MinPointsCount (): number {
        return this.Points.length;
    }

    ThemeChanged (): void {
        super.ThemeChanged();

        this.labelBackgroundColor = ThemeManager.CurrentTheme.ElliotLabelsBGColor;
        this.labelBackgroundBrush = new SolidBrush(this.labelBackgroundColor);
        this.FontColor = ThemeManager.CurrentTheme.Chart_PlateTextColor;
        this.font = ThemeManager.Fonts.Font_11F25_regular;
    }

    Properties (): DynProperty[] {
        const props = super.Properties();

        if (this.FontColor != null) {
            props.push(new DynProperty('ElliotWaves.Font', this.FontColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP));
        }

        return props;
    }

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const prop = DynProperty.getPropertyByName(properties, 'ElliotWaves.Font');
        if (prop != null) this.FontColor = prop.value;
    }
}
