// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export const BOTTOM_WINDOWS_MARGIN = 32;
export const TOP_WINDOWS_MARGIN = 52;
export const WINDOW_SIDE_BORDERS_WIDTH = 4;
export const ACCOUNT_WIDGET_MENU_TOP_OFFSET = 6;
export const AUTO_HIDE_BLOCK_WIDTH = 24;
export const AUTO_HIDE_AVALIABLE_PANELS_COUNT = 3;
export const SIDE_BAR_PANEL = 50;
export const TOP_BOTTOM_PADDING_OFFSET = 3;
export const PADDING_OFFSET_LEFT = 3;
