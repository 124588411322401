// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixField } from './PFixField';

export class PFixShortField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = 0;
    }

    public override ReadValue (buffer): void {
        this.Value = buffer.ReadShort();
    }

    public override Write (buffer): void {
        buffer.WriteShort(this.FieldId);
        buffer.WriteShort(this.Value);
    }
}
