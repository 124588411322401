// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum ExternalLinkType {
    UNKNOWN = -1,
    NONE = 0,
    LINK_TOKEN_LANG = 1,
    TRADING_CENTRAL = 2,
    AUTOCHARTIST_RP = 3,
    AUTOCHARTIST_IFRAME = 4,
    ITCHART_ADVANCED = 5,
    YOUTUBE_LINK = 6,
    WEB_CONTAINER = 7
}
