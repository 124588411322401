// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../../Utils/CustomEvents';
import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { Rectangle } from '../../../Commons/Geometry';
import { ControlsUtils } from '../../UtilsClasses/ControlsUtils';
import { QuickTableRactiveTemplate } from '../../../templates.js';
import { RactiveTooltip } from '../../misc/Decorators';
import { Control } from '../Control';
import { QuickTableNumeric } from '../QuickTableNumeric';
import { QuickTableComboBox } from '../QuickTableComboBox';
import { QuickTableTifComboBox } from '../QuickTableTifComboBox';
import { TerceraQuickTreeEvents } from '../QuickTree/TerceraQuickTree';
import { QuickTable, QuickTableZone } from './QuickTable';
import { PlacedFrom } from '../../../Utils/Trading/PlacedFrom';
import { ThemeManager } from '../../misc/ThemeManager';
import { KeyEventProcessor } from '../../../Commons/KeyEventProcessor';
import { DynProperty } from '../../../Commons/DynProperty';
import { QuickTableAccountLookup } from '../QuickTableAccountLookup';
import { QuickTableInstrumentLookup } from '../QuickTableInstrumentLookup';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { MathUtils } from '../../../Utils/MathUtils';
import { type BaseItem } from '../../cache/BaseItem';
import { ColorPickerScreen } from '../../screen/ColorPickerScreen';
import $ from 'jquery';
import { QuickTableAccountsComboBox } from '../QuickTableAccountsComboBox';
import { AccountMenuItemsHelper } from '../../../Commons/AccountWidget/AccountMenuItemsHelper';
import { WDSettings } from '../../settings/WDGeneralSettings';

export class QuickTableRactive<ItemType extends BaseItem = any> extends Control {
    public static staticID = 0;

    public quickTable: QuickTable<ItemType>;

    public tooltipKey: string | null;
    private ttRowNumber: number = -1;
    private ttColNumber: number = -1;
    public lastShowTooltipTimeoutId: any;

    public prevWidth: number;
    public prevHeight: number;

    public GlobalKeyDownHandled: CustomEvent;
    public captured: boolean;

    constructor () {
        super();

        this.quickTable = null;

        this.tooltipKey = null;
        this.lastShowTooltipTimeoutId = null;

        this.prevWidth = 0;
        this.prevHeight = 0;
    }

    public override getType (): string { return 'QuickTableRactive'; }

    public override oninit (): void {
        QuickTableRactive.staticID++;

        void this.set('id', 'quicktable' + QuickTableRactive.staticID);

        this.GlobalKeyDownHandled = new CustomEvent();

        this.onEditingResult = this.onEditingResult.bind(this);
    }

    public override onrender (): void {
        try {
            const canvas = $('#' + this.get('id'))[0];
            const ctx = canvas.getContext('2d', { alpha: false });
            this.quickTable = new QuickTable<ItemType>(ctx);
            this.quickTable.OnShowTooltip.Subscribe(this.onShowTooltip, this);

            this.observe('width', (newValue, oldValue, keypath) => {
                if (isValidNumber(newValue)) {
                    const devicePixelRatio = window.devicePixelRatio || 1;
                    this.quickTable.width = newValue;
                    canvas.width = this.quickTable.width * devicePixelRatio;
                    ctx.scale(devicePixelRatio, devicePixelRatio);
                } else {
                    this.quickTable.width = newValue;
                }

                this.quickTable.onResize();
                if (isValidNumber(newValue) && newValue !== 0 && this.prevWidth !== newValue) {
                    this.prevWidth = newValue;
                    this.removeEditableControl();
                }
            });
            this.observe('height', (newValue, oldValue, keypath) => {
                if (isValidNumber(newValue)) {
                    const devicePixelRatio = window.devicePixelRatio || 1;
                    this.quickTable.height = newValue;
                    canvas.height = this.quickTable.height * devicePixelRatio;
                    ctx.scale(devicePixelRatio, devicePixelRatio);
                } else {
                    this.quickTable.height = newValue;
                }

                this.quickTable.onResize();
                if (isValidNumber(newValue) && newValue !== 0 && this.prevHeight !== newValue) {
                    this.prevHeight = newValue;
                    this.removeEditableControl();
                }
            });

            this.quickTable.OnExternalCellEditRequest.Subscribe(this.onExternalCellEditRequest, this);
            // TODO. Wrong concept.
            this.quickTable.OnExternalShowTooltipRequest.Subscribe(this.onExternalShowTooltipRequest, this);
            this.quickTable.scroll.OnValueChange.Subscribe(this.onQuickTableScroll, this);

            this.themeChange();
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            this.quickTable = new QuickTable<ItemType>(null);
        }
    }

    public onExternalCellEditRequest (data): void {
        if (isNullOrUndefined(data)) return;

        const cell = data.cell;
        if (isNullOrUndefined(cell)) return;

        const qtEditingInfo = cell.QuickTableEditingInfo;
        if (isNullOrUndefined(qtEditingInfo)) return;

        const absoluteLocation = ControlsUtils.getAbsoluteLocation(this);
        const cellRect = this.quickTable.getCellRectangleRelativeToCanvas(data.row, data.columnIndex);
        const controlRect = new Rectangle(
            absoluteLocation.X + cellRect.X,
            absoluteLocation.Y + cellRect.Y,
            cellRect.Width,
            cellRect.Height);

        switch (qtEditingInfo.ControlType) {
        case DynProperty.DOUBLE:
            data.control = new QuickTableNumeric(null, data, this.onEditingResult, controlRect);
            break;
        case DynProperty.COMBOBOX:
            data.control = new QuickTableComboBox(null, data, this.onEditingResult, controlRect);
            break;
        case DynProperty.COMBOBOX_COMBOITEM_TIF:
            data.control = new QuickTableTifComboBox(null, data, this.onEditingResult, controlRect);
            break;
        case DynProperty.ACCOUNT:{
            const IsOnlyOwnAccounts = AccountMenuItemsHelper.GetDataToFillOut(null).IsOnlyOwnAccounts;
            if (IsOnlyOwnAccounts) {
                data.control = new QuickTableAccountsComboBox(null, data, this.onEditingResult, controlRect);
            } else {
                data.control = new QuickTableAccountLookup(null, data, this.onEditingResult, controlRect);
            }
            break;
        }

        case DynProperty.INSTRUMENT:
            data.control = new QuickTableInstrumentLookup(null, data, this.onEditingResult, controlRect);
            break;
        case DynProperty.COLOR:
            ColorPickerScreen.ShowScreen(controlRect.X, controlRect.Y, data.cell.value, (color) => { this.onEditingResult(color, data); });
            break;
        }

        if (!isNullOrUndefined(data.control)) {
            this.quickTable.isActiveEditControl = true;
            MainWindowManager.MainWindow.Controls.quickTableControlContainer.addControl(data.control);
        }
    }

    private onQuickTableScroll (): void {
        const controls = MainWindowManager.MainWindow.Controls.quickTableControlContainer.Controls;
        if (!isNullOrUndefined(controls) && this.quickTable.isActiveEditControl) {
            for (const _guid in controls) {
                const control = controls[_guid];
                control?.lostFocus();
            }
        }
        this.removeEditableControl();
    }

    public removeEditableControl (): void {
        if (!this.quickTable.isActiveEditControl) {
            return;
        }
        MainWindowManager.MainWindow.Controls.quickTableControlContainer.removeActiveControl();
        MainWindowManager.MainWindow.Controls.quickTableControlContainer.removeParentActiveControl();
        MainWindowManager.MainWindow.Controls.quickTableControlContainer.removeAllControls();
        this.setFocus();
        this.quickTable.isActiveEditControl = false;
    }

    // TODO. Duplicate of RactiveTooltip.decorator = function (node, text).
    public onExternalShowTooltipRequest (tooltipKey: string, rowNumber: number = -1, colNumber: number = -1): void {
        const samePosition = (rowNumber !== -1 && this.ttRowNumber === rowNumber) && (colNumber !== -1 && this.ttColNumber === colNumber);
        this.ttRowNumber = rowNumber;
        this.ttColNumber = colNumber;
        if (isValidString(tooltipKey) && this.tooltipKey === tooltipKey && samePosition) {
            return;
        }

        this.tooltipKey = tooltipKey;
        clearTimeout(this.lastShowTooltipTimeoutId);
        RactiveTooltip.setTooltipVisibility(false);

        if (!WDSettings.tooltips || !isValidString(tooltipKey)) {
            return;
        }

        this.lastShowTooltipTimeoutId = setTimeout(function (tooltipKey) {
            RactiveTooltip.showTooltip(tooltipKey);
        }, 500, tooltipKey);
    }

    public onEditingResult (value, data): void {
        if (!MathUtils.IsNullOrUndefined(value)) {
            this.quickTable.afterExternalCellEdit(value, data);
        }
        this.removeEditableControl();
    }

    public setShowColumnHeaders (show: boolean): void {
        this.quickTable.setShowColumnHeaders(show);
    }

    public onResizeBegin (): void {
        this.quickTable.onResizeBegin();
    }

    public onResizeEnd (): void {
        this.quickTable.onResizeEnd();
    }

    public setSizes (): void {
        const panel = this.find('*');
        const w = panel.offsetWidth;
        const h = panel.offsetHeight;

        void this.set({ width: w, height: h });
    }

    public async resetSizes (): Promise<void> {
        await this.set({ width: 0, height: 0 });
    }

    public setBounds (x: number, y: number, width: number, height: number): void {
        if (this.quickTable.lazyResizing) {
            super.setBounds(x, y, width, height);
        } else {
        // Save image before resize and draw it after to avoid flickering (for goo performance)
            // var imgD = this.quickTable.context.getImageData(0, 0, this.quickTable.context.canvas.width - 1, this.quickTable.context.canvas.height - 1);

            super.setBounds(x, y, width, height);

            // this.quickTable.context.putImageData(imgD, 0, 0);

            this.quickTable.needRedrawBackground = true;
            this.quickTable.needRedraw = true;
        }
    }

    public enableRowHover (enableRowHover: boolean): void {
        this.quickTable.enableRowHover = enableRowHover;
    }

    public enableRowHoverWithBorder (enableRowHoverWithBorder: boolean): void {
        this.quickTable.enableRowHover = enableRowHoverWithBorder;
        this.quickTable.enableRowHoverBorder = enableRowHoverWithBorder;
        this.quickTable.hoveredForeColor = null;
    }

    public override onClick (event): void {
        this.fire(QuickTableRactiveEvents.Click, event.original);
    }

    public override onMouseDown (event): void {
        super.onMouseDown(event);
        this.quickTable.onMouseDown(event.original);
        this.fire(QuickTableRactiveEvents.MouseDown, event.original);
        ControlsUtils.Capture.SetCapture(this, event.original);
    }

    public override onMouseMove (event): void {
        if (this.captured && event.original) {
            return;
        }

        super.onMouseMove(event);
        this.quickTable.onMouseMove(event.original || event);

        void this.set('currentCursor', this.quickTable.currentCursor);
    }

    public override onMouseLeave (event): void {
        super.onMouseLeave(event);
        this.quickTable.onMouseLeave(event.original || event);
    }

    public override onMouseUp (event): void {
        if (this.captured && !isNullOrUndefined(event.original)) {
            return;
        }

        super.onMouseUp(event);
        this.quickTable.onMouseUp(event.original || event);

        void this.set('currentCursor', this.quickTable.currentCursor);
    }

    public override onMouseWheel (event): void {
        this.quickTable.onMouseWheel(event.original || event);
    }

    public override onDoubleClick (event): void {
        const isProcessed = this.quickTable.onDoubleClick(event);
        if (!isProcessed) {
            return;
        }
        super.onDoubleClick(event);
        this.fire(TerceraQuickTreeEvents.DoubleClick, PlacedFrom.WEB_ORDERS_PANEL_DB_CLICK, this);
    }

    public onGlobalKeyDown (): void {
        if (!this.get('focused')) return;

        this.quickTable.onKeyDown();
        this.GlobalKeyDownHandled.Raise();
    }

    public onGlobalKeyUp (): void {
        if (!this.get('focused')) return;

        this.quickTable.onKeyUp();
    }

    public onShowTooltip (tooltip: string): void {
        RactiveTooltip.showTooltip(tooltip);
    }

    public override oncomplete (): void {
        super.oncomplete();
        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
        keyProc.OnKeyUp.Subscribe(this.onGlobalKeyUp, this);
    }

    public override onteardown (): void {
        if (!isNullOrUndefined(this.quickTable)) {
            this.quickTable.OnShowTooltip.UnSubscribe(this.onShowTooltip, this);
            this.quickTable.Dispose();
        }

        const keyProc = KeyEventProcessor;
        keyProc.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
        keyProc.OnKeyUp.UnSubscribe(this.onGlobalKeyUp, this);
    }

    public themeChange (): void {
        if (!isNullOrUndefined(this.quickTable)) {
            this.quickTable.themeChange();
        }

        void this.set('tableBackColor', ThemeManager.CurrentTheme.quickTableBackColor);
    }

    public redraw (): void {
        this.quickTable.redraw();
    }
}

Control.extendWith(QuickTableRactive, {
    template: QuickTableRactiveTemplate,
    data: function () {
        return {
            id: 'ds',
            zIndex: null, // for lookup
            tableBackColor: 'black',
            currentCursor: 'default',
            autocompleteTable: false
        };
    }
});

export enum QuickTableRactiveEvents {
    MouseDown = 'MouseDown',
    Click = 'Click',
    DoubleClick = 'DoubleClick'
}
