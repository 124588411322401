// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ChartHistoryType } from './Utils/ChartConstants';
import { HistoryType } from '../Utils/History/HistoryType';
import { Periods } from '../Utils/History/TFInfo';
import { Resources } from '../Localizations/Resources';
import { type Instrument } from '../Commons/cache/Instrument';
import { type TerceraChartHistoryType } from './TerceraChartUtils';

export class TerceraChartActionState {
    public Visible = true;
    public Enabled = true;
    public Active = false;
    public CanCheck = false;
    public locKey = '';
    public ToolTiplocKey = '';
    public details: any = null;
    public ImageKey: any;

    public applyFrom (state: TerceraChartActionState): void {
        this.Visible = state.Visible;
        this.Enabled = state.Enabled;
        this.Active = state.Active;
        this.CanCheck = state.CanCheck;
        this.locKey = state.locKey;
        this.ToolTiplocKey = state.ToolTiplocKey;
        this.details = state.details;
    }

    public static GetHistoryTypeActionState (historyType: TerceraChartHistoryType, period, ins: Instrument, currentHistoryType: ChartHistoryType): TerceraChartActionState {
        const res = new TerceraChartActionState();

        if (!ins) return res;

        // SourceTypes
        const lSourceTypes = ins.DataCache.GetAllowedHistoryTypesByPeriod(period);
        const isTick = period === Periods.TIC;

        switch (historyType.ChartDataType) {
        case ChartHistoryType.Default:{
            const def = ins.DefaultChartHistoryType;
            res.locKey =
                Resources.getResource('chart.view.source0') + ' (' +
                Resources.getResource('chart.view.source' + (def === 1 && isTick ? 'BID/ASK' : def)) + ')';
            break;
        }
        case ChartHistoryType.ByBid:
            res.locKey = 'chart.view.source' + HistoryType.BID;
            break;
        case ChartHistoryType.ByBidAsk:
            res.locKey = 'chart.view.sourceBID/ASK';
            break;
        case ChartHistoryType.ByTrades:
            res.locKey = 'chart.view.source' + HistoryType.LAST;
            break;
        case ChartHistoryType.ByASk:
            res.locKey = 'chart.view.source' + HistoryType.ASK;
            break;
        }

        res.Active = currentHistoryType === historyType.ChartDataType;
        res.CanCheck = true;

        // Visibility
        if (historyType.ChartDataType === ChartHistoryType.Default) {
            res.Visible = true;
        } else if (historyType.ChartDataType === ChartHistoryType.ByBid) {
            res.Visible = lSourceTypes.indexOf(historyType.HistoryType()) !== -1 && !isTick;
        } else if (historyType.ChartDataType === ChartHistoryType.ByBidAsk) {
            res.Visible = lSourceTypes.indexOf(historyType.HistoryType()) !== -1 && isTick;
        } else {
            res.Visible = lSourceTypes.indexOf(historyType.HistoryType()) !== -1;
        }

        return res;
    }
}
