// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TvSymbolConvertor } from './Convertors/TvSymbolConvertor';
import { TvAccountsManager } from './Managers/TvAccountsManager';
import { type AccountManagerInfo, type ActionMetaInfo, type Brackets, ConnectionStatus, type CustomInputFieldsValues, type DefaultContextMenuActionsParams, type Execution, type IBrokerConnectionAdapterHost, type IBrokerTerminal, type INumberFormatter, type InstrumentInfo, type IsTradableResult, type LeverageInfo, type LeverageInfoParams, type LeveragePreviewResult, type LeverageSetParams, type LeverageSetResult, type Order, type OrderPreviewResult, type PlaceOrderResult, type Position, type PreOrder, type Side, type TradeContext, type IDestroyable, type IndividualPosition, type AccountMetainfo, type SymbolSpecificTradingOptions, type SortingParameters, type AccountId } from './charting_library';
import { TvOrdersManager } from './Managers/TvOrdersManager';
import { TvBrokerConfigFlags } from './BrokerConfig/TvBrokerConfigFlags';
import { TvPipsValuesCache } from './Caches/TvPipsValuesCache';
import { TvPriceFormatter } from './Formatters/TvPriceFormatter';
import { TvSpreadFormatter } from './Formatters/TvSpreadFormatter';
import { TvQuantityFormatter } from './Formatters/TvQuantityFormatter';
import { TvPositionsManager } from './Managers/TvPositionsManager';
import { TvOrderColumns } from './Columns/TvOrderColumns';
import { TvOrderHistoryColumns } from './Columns/TvOrderHistoryColumns';
import { TvPositionColumns } from './Columns/TvPositionColumns';
import { TvExecutionsManager } from './Managers/TvExecutionsManager';
import { TvQuoteRealtimeManager } from './Managers/TvQuoteRealtimeManager';
import { TvOrderPreview } from './Preview/TvOrderPreview';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { type TvDataFeed } from './TvDataFeed';
import { EventEmitter } from 'events';
import { TvNotificationManager } from './Managers/TvNotificationManager';
import { TvOrdersHistoryManager } from './Managers/TvOrdersHistoryManager';
import { TvAccountHelper } from './Helpers/TvAccountHelper';
import { TvRuleValidator } from './BrokerConfig/TvRuleValidator';
import { ApplicationInfo } from '../ApplicationInfo';

export class TvBrokerTerminal implements IBrokerTerminal, IDestroyable {
    private readonly subscirbeGUID: string = 'TvBrokerTerminal';
    private readonly accountsManager: TvAccountsManager;
    private readonly positionsManager: TvPositionsManager;
    private readonly executionManager: TvExecutionsManager;
    private readonly dataFeed: TvDataFeed;
    private readonly host: IBrokerConnectionAdapterHost;
    private readonly ordersManager: TvOrdersManager;
    private readonly quoteRealtimeManager: TvQuoteRealtimeManager;
    private readonly notificationManager: TvNotificationManager;
    private readonly historyOrdersManager: TvOrdersHistoryManager;
    private readonly ruleValidator: TvRuleValidator;
    private intervalId: NodeJS.Timeout | undefined;
    private readonly _eventEmitter: EventEmitter;

    constructor (host: IBrokerConnectionAdapterHost, dataFeed: TvDataFeed) {
        this._eventEmitter = new EventEmitter();
        this.accountsManager = new TvAccountsManager(host);
        this.ordersManager = new TvOrdersManager(host);
        this.positionsManager = new TvPositionsManager(host);
        this.executionManager = new TvExecutionsManager(host);
        this.quoteRealtimeManager = new TvQuoteRealtimeManager(host);
        this.notificationManager = new TvNotificationManager(host);
        this.historyOrdersManager = new TvOrdersHistoryManager();
        this.ruleValidator = new TvRuleValidator(host);
        this.dataFeed = dataFeed;
        this.host = host;
        this.startInterval();
    }

    subscribeRealtime (symbol: string): void {
        this.quoteRealtimeManager.subscribeRealtime(symbol);
    }

    unsubscribeRealtime (symbol: string): void {
        this.quoteRealtimeManager.unsubscribeRealtime(symbol);
    }

    subscribeDOM (symbol: string): void { // ?
        this.dataFeed.subscribeDepth(symbol, (data) => {
            this.host.domUpdate(symbol, data);
        });
    }

    unsubscribeDOM (symbol: string): void { // ?
        this.dataFeed.unsubscribeDepth(symbol);
    }

    async placeOrder (order: PreOrder, confirmId?: string): Promise<PlaceOrderResult> {
        await this.checkExploreMode();
        return await this.ordersManager.placeOrder(order, confirmId);
    }

    async previewOrder (order: PreOrder): Promise<OrderPreviewResult> {
        const previewResult = await TvOrderPreview.previewOrder(order);
        return previewResult;
    }

    async modifyOrder (order: Order, confirmId?: string): Promise<void> {
        await this.checkExploreMode();
        await this.ordersManager.modifyOrder(order, confirmId);
    }

    async cancelOrder (orderId: string): Promise<void> {
        await this.checkExploreMode();
        await this.ordersManager.cancelOrder(orderId);
    }

    async cancelOrders (symbol: string, side: Side, ordersIds: string[]): Promise<void> {
        await this.checkExploreMode();
        await this.ordersManager.cancelOrders(symbol, side, ordersIds, PlacedFrom.TV_TRADING_PLATFORM_DOM);
    }

    async reversePosition (positionId: string): Promise<void> { // ?
        throw new Error('Method not implemented.');
    }

    async closePosition (positionId: string, amount?: number): Promise<void> {
        await this.checkExploreMode();
        await this.positionsManager.closePosition(positionId, amount);
    }

    async closeTrade (tradeId: string, amount?: number): Promise<void> { // ?
        throw new Error('Method not implemented.');
    }

    async editPositionBrackets (positionId: string, brackets: Brackets, customFields?: CustomInputFieldsValues): Promise<void> {
        await this.checkExploreMode();
        await this.ordersManager.editPositionBrackets(positionId, brackets, customFields);
    }

    async editTradeBrackets (tradeId: string, brackets: Brackets): Promise<void> { // ?
        throw new Error('Method not implemented.');
    }

    async leverageInfo (leverageInfoParams: LeverageInfoParams): Promise<LeverageInfo> { // ?
        throw new Error('Method not implemented.');
    }

    async setLeverage (leverageSetParams: LeverageSetParams): Promise<LeverageSetResult> { // ?
        throw new Error('Method not implemented.');
    }

    async previewLeverage (leverageSetParams: LeverageSetParams): Promise<LeveragePreviewResult> { // ?
        throw new Error('Method not implemented.');
    }

    subscribeEquity (): void {
        this.accountsManager.subscribeEquity();
    }

    subscribeMarginAvailable (symbol: string): void {
        this.accountsManager.subscribeMarginAvailable(symbol);
    }

    unsubscribeMarginAvailable (symbol: string): void {
        this.accountsManager.unsubscribeMarginAvailable(symbol);
    }

    subscribePipValue (symbol: string): void {
        TvPipsValuesCache.subscribePipValue(symbol);
    }

    unsubscribePipValue (symbol: string): void {
        TvPipsValuesCache.unsubscribePipValue(symbol);
    }

    unsubscribeEquity (): void {
        this.accountsManager.unsubscribeEquity();
    }

    async chartContextMenuActions (context: TradeContext, options?: DefaultContextMenuActionsParams): Promise<ActionMetaInfo[]> {
        return this.ruleValidator.isTradePosible() ? await this.host.defaultContextMenuActions(context, options) : [];
    }

    async isTradable (symbol: string): Promise<boolean | IsTradableResult> {
        const isTradable: boolean = true;
        return await Promise.resolve(isTradable);
    }

    connectionStatus (): ConnectionStatus {
        return ConnectionStatus.Connected;
    }

    async orders (): Promise<Order[]> {
        return await this.ordersManager.getOrders();
    }

    public async ordersHistory (): Promise<Order[]> {
        return await this.historyOrdersManager.ordersHistory();
    }

    async positions (): Promise<Position[]> {
        return await this.positionsManager.getPositions();
    }

    async individualPositions (): Promise<IndividualPosition[]> { // ?
        const trades: IndividualPosition[] = [];
        return await Promise.resolve(trades);
    }

    async executions (symbol: string): Promise<Execution[]> {
        return await this.executionManager.executions(symbol);
    }

    async symbolInfo (symbol: string): Promise<InstrumentInfo> {
        return await TvSymbolConvertor.getInstrumentInfoAsync(symbol, TvAccountHelper.getCurrentAccount());
    }

    accountManagerInfo (): AccountManagerInfo {
        const orderSortingParams: SortingParameters =
        {
            property: 'date',
            asc: false
        };

        const accountInfo: AccountManagerInfo =
        {
            accountTitle: 'ACCOUNT_TITLE', // TODO: not displayed?
            summary: this.accountsManager.getSummary(),
            customFormatters: this.accountsManager.getCustomFormatters(),
            orderColumns: TvOrderColumns.getOrderColumns(),
            orderColumnsSorting: orderSortingParams,
            historyColumns: TvOrderHistoryColumns.getOrderHistoryColumns(),
            historyColumnsSorting: orderSortingParams,
            positionColumns: TvPositionColumns.getPositionColumns(),
            // tradeColumns?: AccountManagerColumn[];
            pages: this.accountsManager.getPages()
            // possibleOrderStatuses?: OrderStatus[];
            // marginUsed?: IWatchedValue<number>;
            // contextMenuActions?(contextMenuEvent: MouseEvent | TouchEvent, activePageActions: ActionMetaInfo[]): Promise<ActionMetaInfo[]>;
        };
        return accountInfo;
    }

    public async getSymbolSpecificTradingOptions (symbol: string): Promise<SymbolSpecificTradingOptions | undefined> {
        return await TvBrokerConfigFlags.getSymbolSpecificTradingOptions(symbol);
    }

    async formatter? (symbol: string, alignToMinMove: boolean): Promise<INumberFormatter> {
        return await new Promise((resolve, reject) => {
            const formatter = new TvPriceFormatter(symbol, alignToMinMove);
            resolve(formatter);
        });
    }

    async spreadFormatter? (symbol: string): Promise<INumberFormatter> {
        return await new Promise((resolve, reject) => {
            const formatter = new TvSpreadFormatter(symbol);
            resolve(formatter);
        });
    }

    async quantityFormatter? (symbol: string): Promise<INumberFormatter> {
        return await new Promise((resolve, reject) => {
            const formatter = new TvQuantityFormatter(symbol);
            resolve(formatter);
        });
    }

    // async getOrderDialogOptions? (symbol: string): Promise<OrderDialogOptions> {
    //     throw new Error('Method not implemented.');
    // }

    // getPositionDialogOptions? (): PositionDialogOptions {
    //     throw new Error('Method not implemented.');
    // }

    // #region  Additional Accounts Methods
    async accountsMetainfo (): Promise<AccountMetainfo[]> {
        return await this.accountsManager.accountsMetainfo();
    }

    public currentAccount (): AccountId {
        return TvAccountHelper.getCurrentAccountId();
    }

    public setCurrentAccount (id: AccountId): void {
        const oldAccount = TvAccountHelper.getCurrentAccount();
        TvAccountHelper.setAccountById(id);
        const curAccount = TvAccountHelper.getCurrentAccount();
        this.quoteRealtimeManager.changeCurrentAccount();
        this.dataFeed.setCurrentAccount(curAccount);
        this.accountsManager.changeAccount();
        this.onUpdateAccount(curAccount, oldAccount);
    }

    public updateHostCurrentAccount (): void {
        this.host.currentAccountUpdate();
    }
    // #region

    // #region Utils
    private updateDataByTimer (): void {
        const account = TvAccountHelper.getCurrentAccount();
        if (isNullOrUndefined(account)) return;

        this.accountsManager.updateAccountData();
        TvPipsValuesCache.updateSubscribedPipValues(this.host, account);
        this.ruleValidator.applyIfNeed();
        this.positionsManager.updatePositions();
        this.ordersManager.updateOrders();
    }

    private startInterval (): void {
        this.intervalId = setInterval(() => { this.updateDataByTimer(); }, 1000);
    }

    private stopInterval (): void {
        if (isNullOrUndefined(this.intervalId)) return;
        clearInterval(this.intervalId);
    }
    // #endregion Utils

    // #region IDestroyable

    destroy (): void {
        this.stopInterval();
        // this.accountsManager.destroy();
        this.ordersManager.destroy();
        this.positionsManager.destroy();
        this.executionManager.destroy();
        this.notificationManager.destroy();
    }
    // #endregion IDestroyable

    // #region Events
    public subscribeOnUpdateAccount (callback: (newValue, oldValue) => void): void {
        this._eventEmitter.on('onUpdateAccount', callback);
    }

    public unsubscribeOnUpdateAccount (callback: (newValue, oldValue) => void): void {
        this._eventEmitter.off('onUpdateAccount', callback);
    }

    private onUpdateAccount (newValue, oldValue): void {
        this._eventEmitter.emit('onUpdateAccount', newValue, oldValue);
    }
    // #endregion Events

    private async checkExploreMode (): Promise<void> {
        if (ApplicationInfo.isExploreMode) {
            await Promise.reject(new Error('Please log in to start trading'));
        }
    }
}
