// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Rectangle } from '@shared/commons/Geometry';
import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { SolidBrush } from '@shared/commons/Graphics';
import { type TextDataCacheTool } from '@shared/commons/cache/Tools/TextDataCacheTool';
import { ActionType, ToolView } from './ToolView';

export class TextToolView extends ToolView<TextDataCacheTool> {
    private textRect: Rectangle = Rectangle.Empty();
    private editTextRect: Rectangle = Rectangle.Empty();
    private wasCreated: boolean = false;
    private textInput: HTMLInputElement | null = null;

    constructor (dataCacheTool: TextDataCacheTool, refreshHandler?: () => void) {
        super(dataCacheTool);

        this.refreshHandler = refreshHandler;
        this.excludedOuterActionTypes = [ActionType.DoubleClick];
    }

    public override Draw (gr: CanvasRenderingContext2D, ww, param): void {
        if (this.textInput) return;

        try {
            const screenPoints = this.screenPoints;
            const p0 = screenPoints[0];
            const x0 = p0[0];
            const y0 = p0[1];

            if (this.dataCacheTool == null) { return; }

            const textBrush = new SolidBrush(this.dataCacheTool.color);
            const textSize = gr.GetTextSize(this.dataCacheTool.text, this.dataCacheTool.font, false);

            this.textRect = new Rectangle(x0, y0 - textSize.height * 0.75, textSize.width, textSize.height);
            this.editTextRect = new Rectangle(
                this.textRect.X,
                this.textRect.Y + this.textRect.Height / 0.75 + ww.HeaderHeight - this.textRect.Height,
                this.textRect.Width,
                this.textRect.Height);
            gr.DrawStringInRect(this.dataCacheTool.text, this.dataCacheTool.font, textBrush, this.textRect, false);

            if (this.wasCreated) {
                this.initEditControl();
                this.wasCreated = false;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public override IsSelectCheck (x: number, y: number): boolean {
        if (this.textRect.Contains(x, y)) {
            return true;
        }
        return false;
    }

    public initEditControl (): void {
        if (this.textInput !== null) return;

        const textRect = this.editTextRect;

        this.textInput = document.createElement('input');
        this.textInput.type = 'text';
        this.textInput.value = this.dataCacheTool?.text ?? '';
        this.textInput.style.position = 'absolute';
        this.textInput.style.paddingLeft = `${textRect.X}px`;
        this.textInput.style.top = `${textRect.Y}px`;
        this.textInput.style.backgroundColor = 'transparent';
        this.textInput.style.border = 'none';
        this.textInput.style.font = this.dataCacheTool?.font;
        this.textInput.style.color = this.dataCacheTool?.color;
        this.textInput.style.width = `${textRect.Width + 40}px`;
        this.textInput.style.height = `${textRect.Height}px`;

        const parent: HTMLElement = document.querySelector('.chart-ractive');
        parent.appendChild(this.textInput);

        this.textInput.focus();
        this.textInput.select();

        this.textInput.onblur = () => { this.endEditing(); };
        this.textInput.onkeydown = (e) => {
            e.stopPropagation();
            this.handleKeyDown(e);
        };
    }

    private handleKeyDown (event: KeyboardEvent): void {
        if (event.key === 'Enter') this.endEditing();
        else if (event.key === 'Escape' && this.textInput) {
            this.textInput.value = this.dataCacheTool?.text ?? '';
            this.endEditing();
        }
    }

    private isEditingEnded = false;
    private endEditing (): void {
        if (this.isEditingEnded || !this.textInput) return;

        this.isEditingEnded = true;
        this.dataCacheTool.text = this.textInput.value;
        if (this.textInput.parentElement) {
            this.textInput.remove();
        }
        this.textInput = null;

        if (this.refreshHandler) this.refreshHandler();
        this.isEditingEnded = false;
    }

    public ProcessDoubleClick (e: any): void {
        this.initEditControl();
    }

    public override OnFinishCreationTool (): void {
        this.wasCreated = true;
        super.OnFinishCreationTool();
    }
}
