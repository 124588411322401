// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { MathUtils } from '../../Utils/MathUtils';
import { QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { QuickTableComparingType } from '../../Utils/QuickTableMisc/QuickTableComparingType';
import { AssetInfoData } from '../../Commons/cache/AssetInfoData';
import { DynProperty } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type Account } from '../../Commons/cache/Account';
import { type Asset } from '../../Commons/cache/Asset';
import { type Instrument } from '../../Commons/cache/Instrument';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';

export class AssetsItem extends BaseItem {
    public Account: Account;
    public Asset: Asset;
    public userGroupName: string = '';
    public Removed: boolean = false;
    public Instrument: Instrument = null;
    public SellExchanges: any = null;
    public listeners: any[] = [];
    public InfoData = new AssetInfoData();

    private _sellExchange: any = null;

    constructor (account: Account, asset: Asset, sessionSettings: SessionSettingsType) {
        super(sessionSettings);

        this.Account = account;
        this.Asset = asset;

        this.itemId = (AssetsItem._staticId++).toString();

        if (!isNullOrUndefined(this.Account)) {
            this.Account.OnUpdateInfoData.Subscribe(this.Account_OnUpdateInfoData, this);
        }

        this.UpdatInfoData();

        if (!isNullOrUndefined(this.Asset)) {
            void this.Asset.GetInstrumentAsync().then(function (instrument) {
                this.Instrument = instrument;
                this.SubscribeInstrument(this.Instrument);
                this.Asset.UpdateSellExchanges()
                    .then(function (SellExchanges) {
                        this.SellExchanges = SellExchanges;
                        this._sellExchange = SellExchanges[0] || '';
                        this.fireUpdate();
                    }.bind(this));
            }.bind(this));
        }

        this.QuickTableEditingInfoMap[AssetsItem.COL_SELL_ASSET_BUTTON] = (function () {
            return new QuickTableEditingInfo(DynProperty.SELL_ASSET_BUTTON);
        })();
    }

    public static _staticId = 0;
    public static readonly COL_ACCOUNT = 0;
    public static readonly COL_ASSET_NAME = 1;
    public static readonly COL_DEFAULT_SYMBOL = 2;
    public static readonly COL_EXCHANGE = 3;
    public static readonly COL_START_DAY_QTY = 4;
    public static readonly COL_START_DAY_QTY_MARGIN = 5;
    public static readonly COL_LIQUIDITY_RATE = 6;
    public static readonly COL_TODAY_TRADED_QTY = 7;
    public static readonly COL_AVAILABLE_QTY_SELL = 8;
    public static readonly COL_CURRENT_PRICE = 9;
    public static readonly COL_CURRENT_VALUE_SELL = 10;
    public static readonly COL_SELL_ASSET_BUTTON = 11;
    public static readonly COL_CURRENT_QTY = 12;
    public static readonly COL_CURRENT_VALUE = 13;
    public static readonly COL_SELL_EXCHANGE = 14;

    public Account_OnUpdateInfoData (asset: Asset): void {
        if (this.Asset !== asset) {
            return;
        }

        this.UpdatInfoData();
        this.fireUpdate();
    }

    public UpdatInfoData (): void {
        if (!isNullOrUndefined(this.Account)) {
            this.InfoData = this.Account.GetAssetDataInfoByName(this.Asset.Name);
        }
    }

    public LastPrice (): number {
        return this.Instrument !== null ? this.Instrument.Level1.GetLastPrice(this.Account) : NaN;
    }

    public GetCurrentAccount (): Account {
        return this.Account;
    }

    public newQuote (message): void {
        if (message === null) {
            return;
        }

        if (message.Type === HistoryType.QUOTE_LEVEL1 ||
        message.Type === HistoryType.QUOTE_TRADES ||
        message.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) {
            this.fireUpdate();
        }
    }

    public override fireUpdate (): void {
        if (this.Removed) {
            return;
        }

        this.isNeedFireUpdate = true;
    // for (let i = 0; i < this.listeners.length; i++)
    //     this.listeners[i].updateItem(this);
    }

    public GetSellExchange (): void {
    // if (this._sellExchange)
    //     this._sellExchange = this.Asset.SellExchanges[0] || "";

        return this._sellExchange;
    }

    public SetSellExchange (value): void {
        this._sellExchange = value;
    }

    public override Dispose (): void {
        this.Removed = true;
        this.UnsubscribeInstrument(this.Instrument);
        if (this.Account) {
            this.Account.OnUpdateInfoData.UnSubscribe(this.Account_OnUpdateInfoData, this);
        }
    }

    public SubscribeInstrument (instr: Instrument): void {
        if (instr) {
            const qc = DataCache.FQuoteCache;

            qc.addListener(instr, this, HistoryType.QUOTE_LEVEL1);
            qc.addListener(instr, this, HistoryType.QUOTE_TRADES);
        }
    }

    public UnsubscribeInstrument (instr: Instrument): void {
        if (instr) {
            const qc = DataCache.FQuoteCache;

            qc.removeListener(instr, this, HistoryType.QUOTE_LEVEL1);
            qc.removeListener(instr, this, HistoryType.QUOTE_TRADES);
        }
    }

    public override getColumnValue (column: number): any {
        switch (column) {
        case AssetsItem.COL_ACCOUNT:
            return this.Account.FullAccString;
        case AssetsItem.COL_ASSET_NAME:
            return this.Asset.Name;
        case AssetsItem.COL_DEFAULT_SYMBOL:
            if (this.Instrument === null) { return null; }
            return this.Instrument.DisplayShortName();
        case AssetsItem.COL_EXCHANGE:
            if (this.Instrument === null) { return null; }
            return this.Instrument.TradingExchange;
        case AssetsItem.COL_START_DAY_QTY:
            return this.InfoData.TotalQuantity;
        case AssetsItem.COL_START_DAY_QTY_MARGIN:
            return this.InfoData.TotalQuantityForMarginAvailable;
        case AssetsItem.COL_LIQUIDITY_RATE:
            return this.Asset.LiquidityRate;
        case AssetsItem.COL_TODAY_TRADED_QTY:
            return this.InfoData.TodayTradedQty;
        case AssetsItem.COL_AVAILABLE_QTY_SELL:
            return this.InfoData.GetAvailableQty();
        case AssetsItem.COL_CURRENT_PRICE:
            return this.LastPrice();
        case AssetsItem.COL_CURRENT_VALUE_SELL:
            return this.InfoData.GetCurrentValue(this.InfoData.GetAvailableQty(), this.Account);
        case AssetsItem.COL_CURRENT_QTY:
            return this.InfoData.GetCurrentQty();
        case AssetsItem.COL_CURRENT_VALUE:
            return this.InfoData.GetCurrentValue(this.InfoData.GetCurrentQty(), this.Account);
        case AssetsItem.COL_SELL_EXCHANGE:
        { this.GetSellExchange(); return; }
        default:
            return null;
        }
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let formattedValue = '';
        switch (column) {
        case AssetsItem.COL_TODAY_TRADED_QTY:
        case AssetsItem.COL_AVAILABLE_QTY_SELL:
        case AssetsItem.COL_CURRENT_QTY:
            formattedValue = MathUtils.RoundToIncrement(value, (!isNullOrUndefined(this.Asset) ? this.Asset.MinChange : 1)).toString();
            break;
        case AssetsItem.COL_CURRENT_PRICE:
            if (isNaN(value) || !this.Instrument) {
                formattedValue = Resources.getResource('general.N_A');
            } else {
                formattedValue = this.Instrument.formatPrice(value);
            }
            break;
        case AssetsItem.COL_CURRENT_VALUE_SELL:
        case AssetsItem.COL_CURRENT_VALUE:
            if (isNaN(value)) {
                formattedValue = Resources.getResource('general.N_A');
            } else {
                formattedValue = this.Account.assetBalanceDefault.Asset.formatPrice(value);
            }
            break;
        default:
            formattedValue = value ? value.toString() : '';
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public override GetCurrentInstrument (): Instrument {
        return this.Instrument;
    }

    public override getFormatTotalValue (column, value, precisionMode = false): string | number {
        let formattedValue = '';
        const asset = DataCache.GetAssetByName(DataCache.userBaseCurrency);

        switch (column) {
        case AssetsItem.COL_START_DAY_QTY:
        case AssetsItem.COL_START_DAY_QTY_MARGIN:
        case AssetsItem.COL_TODAY_TRADED_QTY:
        case AssetsItem.COL_AVAILABLE_QTY_SELL:
            if (precisionMode) {
                return 2;
            }
            formattedValue = value.toFixed(2);
            break;

        case AssetsItem.COL_CURRENT_VALUE_SELL:
            if (precisionMode) {
                return asset.Point;
            }
            formattedValue = asset.formatPrice(value);
            break;
        }

        return formattedValue;
    }

    public addItemListener (listener): void {
        const index = this.listeners.indexOf(listener);
        if (index === -1) {
            this.listeners.push(listener);
        }
    }

    public removeItemListener (listener): void {
        const index = this.listeners.indexOf(listener);
        if (index !== -1) {
            this.listeners.splice(index, 1);
        }
    }

    public override ColumnCount (): number {
        return AssetsItem.columnsParams.length;
    }

    public override GetColumnParams (i: number): ColumnParams {
        return AssetsItem.columnsParams[i];
    }

    public override GetDataType (columnIndex: number): QuickTableComparingType {
        switch (columnIndex) {
        case AssetsItem.COL_ACCOUNT:
        case AssetsItem.COL_ASSET_NAME:
        case AssetsItem.COL_DEFAULT_SYMBOL:
        case AssetsItem.COL_SELL_ASSET_BUTTON:
        case AssetsItem.COL_EXCHANGE:
        case AssetsItem.COL_SELL_EXCHANGE:
            return QuickTableComparingType.String;

        case AssetsItem.COL_START_DAY_QTY:
        case AssetsItem.COL_TODAY_TRADED_QTY:
        case AssetsItem.COL_START_DAY_QTY_MARGIN:
        case AssetsItem.COL_LIQUIDITY_RATE:
        case AssetsItem.COL_CURRENT_PRICE:
        case AssetsItem.COL_CURRENT_VALUE_SELL:
        case AssetsItem.COL_CURRENT_VALUE:
        case AssetsItem.COL_AVAILABLE_QTY_SELL:
        case AssetsItem.COL_CURRENT_QTY:
            return QuickTableComparingType.Double;

        default:
            return super.GetDataType(columnIndex);
        }
    }

    public static readonly columnsParams = [
        /* 0 */ new ColumnParams('panel.Assets.QuickTable.Column.Account', 100, 0, false, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.AssetName', 100, 0, false, true, false, false, true),
        new ColumnParams('panel.Assets.QuickTable.Column.DefaultSymbol', 100, 0, false, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.ExchangeTrading', 100, 0, false, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.StartDayQty', 100, 1, true, true, false, false, true),
        /* 5 */ new ColumnParams('panel.Assets.QuickTable.Column.StartDayQtyMargin', 100, 1, true, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.LiquidityRate', 100, 1, false, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.TodayTradedQty', 100, 1, true, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.AvailableQtySell', 100, 1, true, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.CurrentPrice', 100, 1, false, true, false, false, false, -1),
        /* 10 */ new ColumnParams('panel.Assets.QuickTable.Column.CurrentValueSell', 100, 1, true, true, false, false, false, -1),
        new ColumnParams('panel.Assets.QuickTable.Column.SellAsset', 100, 0, false, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.CurrentQty', 100, 0, false, true, false, false, false, -1),
        new ColumnParams('panel.Assets.QuickTable.Column.CurrentValue', 100, 1, false, true, false),
        new ColumnParams('panel.Assets.QuickTable.Column.SellExchange', 100, 0, false, true, false)
    ];

    public GetMenuIndex (headerKey: string): number {
        const index = AssetsItem.ListMenuIndex.indexOf(headerKey);
        return index > -1 ? index : 1000;
        // if (AssetsItem.ListMenuIndex[headerKey]) {
        //     return AssetsItem.ListMenuIndex[headerKey];
        // } else {
        //     return 1000;
        // }
    }

    public static readonly ListMenuIndex = [
        'panel.Assets.QuickTable.Column.Account',
        'panel.Assets.QuickTable.Column.AssetName',
        'panel.Assets.QuickTable.Column.DefaultSymbol',
        'panel.Assets.QuickTable.Column.ExchangeTrading',
        'panel.Assets.QuickTable.Column.StartDayQty',
        'panel.Assets.QuickTable.Column.StartDayQtyMargin',
        'panel.Assets.QuickTable.Column.LiquidityRate',
        'panel.Assets.QuickTable.Column.TodayTradedQty',
        'panel.Assets.QuickTable.Column.AvailableQtySell',
        'panel.Assets.QuickTable.Column.CurrentPrice',
        'panel.Assets.QuickTable.Column.CurrentValueSell',
        'panel.Assets.QuickTable.Column.SellAsset',
        'panel.Assets.QuickTable.Column.CurrentQty',
        'panel.Assets.QuickTable.Column.CurrentValue',
        'panel.Assets.QuickTable.Column.SellExchange'
    ];

    public CalculateQty (input: number): number {
        if (WDSettingsUtils.displayAmountInLots()) {
            return input;
        }

        return input * (this.Instrument ? this.Instrument.LotSize : 1);
    }
}
