// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraChartAction, TerceraChartActionEnum } from '../TerceraChartAction';
import { TerceraChartActionState } from '../TerceraChartActionState';
import { type TerceraChartBase } from '../TerceraChartBase';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { ChartZoomRendererButtons } from '../Renderers/TerceraChartZoomRenderer';
import { ITerceraChartShowScreenType } from '../ITerceraChartShowScreenType';
import { TerceraChartScalingByRectangleRenderer } from '../Renderers/TerceraChartScalingByRectangleRenderer';

export class TerceraChartBaseActionProcessor<Chart extends TerceraChartBase = TerceraChartBase> {
    public StateChanged = new CustomEvent();
    public chart: Chart;
    public readonly actionsProcessor: Record<number, (action?: TerceraChartAction, active?) => boolean | void> = {};
    public readonly statesProcessor: Record<number, (outState?: TerceraChartActionState, action?: TerceraChartAction) => void> = {};

    constructor (chart: Chart) {
        this.chart = chart;
        this.initializeActions();
        this.initializeStates();
    }

    protected initializeActions (): void {
        this.actionsProcessor[TerceraChartActionEnum.Settings] = this.processSettingsAction;
        this.actionsProcessor[TerceraChartActionEnum.Zoom] = this.processZoomAction;
        this.actionsProcessor[TerceraChartActionEnum.ZoomX] = this.processZoomXAction;
        this.actionsProcessor[TerceraChartActionEnum.ZoomY] = this.processZoomYAction;
        this.actionsProcessor[TerceraChartActionEnum.SnapToCandle] = this.processSnapToCandleAction;
        this.actionsProcessor[TerceraChartActionEnum.ScalingByRect] = this.processScalingByRectAction;
        this.actionsProcessor[TerceraChartActionEnum.Cancel] = this.processCancelAction;
        this.actionsProcessor[TerceraChartActionEnum.AutoScale] = this.processAutoScaleAction;
        this.actionsProcessor[TerceraChartActionEnum.ManualScale] = this.processManualScaleAction;
    }

    protected initializeStates (): void {
        this.statesProcessor[TerceraChartActionEnum.Settings] = this.processSettingsState;
        this.statesProcessor[TerceraChartActionEnum.Zoom] = this.processZoomState;
        this.statesProcessor[TerceraChartActionEnum.SnapToCandle] = this.processSnapToCandleState;
        this.statesProcessor[TerceraChartActionEnum.ScalingByRect] = this.processScalingByRectState;
        this.statesProcessor[TerceraChartActionEnum.Cancel] = this.processCancelState;
        this.statesProcessor[TerceraChartActionEnum.AutoScale] = this.processAutoScaleState;
        this.statesProcessor[TerceraChartActionEnum.ManualScale] = this.processManualScaleState;
    }

    // Action's которые отключаются по повторному клику
    public IsAutoCheckItem (action: TerceraChartAction): boolean {
        if (action.Type === TerceraChartActionEnum.Eraser || action.Type === TerceraChartActionEnum.ToolSelector) {
            return true;
        }

        if (action.Type === TerceraChartActionEnum.Zoom && action.Details === ChartZoomRendererButtons.ScalingByRectangle) {
            return true;
        }

        if (action.Type === TerceraChartActionEnum.CreationTool) {
            return true;
        }

        if (action.Type === TerceraChartActionEnum.ChartTrading) {
            return true;
        }

        return false;
    }
    // #region Action processing.

    public ProcessTerceraChartAction (action: TerceraChartAction, recursive = false): boolean {
        let processed = false;
        try {
            if (isNullOrUndefined(action)) {
                return false;
            }

            const state = this.GetTerceraChartActionState(action);
            if (!state.Enabled) return false;

            let active = null;
            if (typeof (action.Details) === 'boolean') {
                active = action.Details;
            }

            // Auto check items.
            if (this.IsAutoCheckItem(action) &&
                !recursive &&
                this.GetTerceraChartActionState(action).Active) {
                const recAction = TerceraChartAction.Create(TerceraChartActionEnum.Cancel, true);
                this.ProcessTerceraChartAction(recAction, true);
                return true;
            }

            // Если включаем какой нибудь режим,
            // все остальные нужно выключить, используем TerceraChartActionEnum.Cancel
            if (this.shouldCancelAction(action.Type) && !recursive) {
                const newAction = TerceraChartAction.Create(TerceraChartActionEnum.Cancel, true);
                this.ProcessTerceraChartAction(newAction, true);
            }

            processed = this.actionsProcessor[action.Type].call(this, action, active);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        } finally {
            // call only one time
            if (!recursive) this.StateChanged.Raise();
        }
        return processed;
    }

    // Get action state: checked, enabled, current image, localization key etc.
    public GetTerceraChartActionState (action: TerceraChartAction): TerceraChartActionState {
        if (isNullOrUndefined(action)) {
            return null;
        }

        const actionState = new TerceraChartActionState();

        const handler = this.statesProcessor[action.Type];
        if (!isNullOrUndefined(handler)) {
            handler.call(this, actionState, action);
        }

        if (Resources.isHidden(actionState.locKey)) {
            actionState.Visible = false;
        }

        return actionState;
    }

    public CreateMenu (actionMenuItems: any[]): any[] {
        const processor = this;
        const len = actionMenuItems.length;
        // TODO.
        const callback = this.onMenuItemClick.bind(processor);

        for (let i = 0; i < len; i++) {
            const mi = actionMenuItems[i];

            const action = mi.tag;
            if (!action) continue;

            const state = processor.GetTerceraChartActionState(action);
            if (!state) continue;

            mi.text = Resources.getResource(state.locKey);
            mi.image = ThemeManager.getImageFullUrl(state.ImageKey);
            mi.imageHover = ThemeManager.getImageFullUrl(state.ImageKey);
            mi.canCheck = state.CanCheck;
            mi.checked = state.Active;
            mi.enabled = state.Enabled;
            mi.visible = state.Visible;
            mi.closeOnClick = action.CloseOnClick;
            mi.noCheckMark = action.CloseOnClick;
            mi.event = callback;

            const subitems = mi.subitems;
            if (subitems) {
                this.CreateMenu(subitems);
            }
        }

        return actionMenuItems;
    }

    public onMenuItemClick (menuItem): void {
        const action = menuItem.tag;
        const processor = this;
        processor.ProcessTerceraChartAction(action);
    }

    // #region Processors
    public processSettingsAction (): boolean {
        const processed = false;
        this.chart.terceraChartPanelContext.ShowScreen(ITerceraChartShowScreenType.ChartSettings, null);
        return processed;
    }

    public processZoomAction (action: TerceraChartAction): boolean {
        let processed = false;

        switch (action.Details) {
        case ChartZoomRendererButtons.ZoomIn:
            this.chart.mainWindow.ZoomIn();
            this.chart.moveScreenByX(this.chart.rightBarIndexIncludingEmptyBars, true);
            processed = true;
            break;

        case ChartZoomRendererButtons.ZoomOut:
            this.chart.mainWindow.ZoomOut();
            this.chart.moveScreenByX(this.chart.rightBarIndexIncludingEmptyBars, true);
            this.chart.afterZoomOutEvents();
            processed = true;
            break;

        case ChartZoomRendererButtons.ScalingByRectangle:
            if (this.chart.mainWindow.GetRenderer(TerceraChartScalingByRectangleRenderer).Active) {
                processed = this.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Cancel, true));
            } else {
                processed = this.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ScalingByRect));
            }
            break;
        default:
            processed = false;
            break;
        }

        return processed;
    }

    public processZoomXAction (action: TerceraChartAction): boolean {
        return this.chart.mainWindow.ZoomX(action.Details);
    }

    public processZoomYAction (action: TerceraChartAction): boolean {
        return this.chart.mainWindow.ZoomY(action.Details);
    }

    public processSnapToCandleAction (): boolean {
        const processed = false;
        if (this.chart != null) {
            this.chart.setSnapToCandle(!this.chart.getSnapToCandle());
        }
        return processed;
    }

    public processScalingByRectAction (action, active): boolean {
        let processed = false;
        const renderer = this.chart.mainWindow.GetRenderer(TerceraChartScalingByRectangleRenderer);
        const newValue = active !== null ? active : !renderer.Active;
        processed = renderer.Active !== newValue;
        renderer.Active = newValue;
        return processed;
    }

    public processCancelAction (action: TerceraChartAction): boolean {
        let processed = false;
        processed = this.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ScalingByRect, false), true) || processed;
        return processed;
    }

    public processAutoScaleAction (action: TerceraChartAction): boolean {
        const processed = false;
        const window = action.Details;
        if (!isNullOrUndefined(window)) {
            window.AutoScale = true;
        }
        return processed;
    }

    public processManualScaleAction (action: TerceraChartAction): boolean {
        const processed = false;
        const window = action.Details;
        if (!isNullOrUndefined(window)) {
            window.AutoScale = false;
        }
        return processed;
    }
    // #endregion

    // #region States
    public processSettingsState (outState): void {
        outState.Enabled = true;
        outState.locKey = 'chart.layerManager';
    }

    public processZoomState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const chart = this.chart;
        if (isNullOrUndefined(chart)) {
            return;
        }

        switch (action.Details) {
        case ChartZoomRendererButtons.ZoomIn:
            outState.ToolTiplocKey = 'chart.chartScroll.zoomInButton';
            outState.Enabled = chart.mainWindow.CanZoomIn();
            break;
        case ChartZoomRendererButtons.ZoomOut:
            outState.ToolTiplocKey = 'chart.chartScroll.zoomOutButton';
            outState.Enabled = chart.mainWindow.CanZoomOut();
            break;
        case ChartZoomRendererButtons.ScalingByRectangle:
            outState = chart.TerceraChartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ScalingByRect));
            break;
        }
    }

    public processSnapToCandleState (outState): void {
        outState.locKey = 'chart.tools.snapToCandle';
        const chart = this.chart;
        if (!isNullOrUndefined(chart)) {
            outState.Active = chart.getSnapToCandle();
        } else {
            outState.Active = false;
        }
    }

    public processScalingByRectState (outState): void {
        outState.ToolTiplocKey = 'chart.tools.ScalingByRect';
        const renderer = this.chart.mainWindow.GetRenderer(TerceraChartScalingByRectangleRenderer);
        outState.Active = renderer ? renderer.Active : false;
    }

    public processCancelState (outState: TerceraChartActionState): void {
        outState.locKey = 'chart.tools.default';
        outState.Active = !this.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ScalingByRect)).Active;
    }

    public processAutoScaleState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const window = action.Details;
        if (!isNullOrUndefined(window)) {
            outState.Active = window.AutoScale;
        }

        outState.CanCheck = true;
        outState.locKey = 'chart.priceScaleContextMenu.AutoScale';
        outState.ToolTiplocKey = 'chart.chartWindowManager.SetManual';
    }

    public processManualScaleState (outState: TerceraChartActionState, action: TerceraChartAction): void {
        const window = action.Details;
        if (!isNullOrUndefined(window)) {
            outState.Active = !window.AutoScale;
        }
        outState.CanCheck = true;
        outState.locKey = 'chart.priceScaleContextMenu.ManualScale';
        outState.ToolTiplocKey = 'chart.chartWindowManager.SetAuto';
    }
    // #endregion

    protected shouldCancelAction (actionType: TerceraChartActionEnum): boolean {
        return actionType !== TerceraChartActionEnum.Cancel &&
               actionType !== TerceraChartActionEnum.Zoom &&
               actionType !== TerceraChartActionEnum.ZoomX &&
               actionType !== TerceraChartActionEnum.ZoomY &&
               actionType !== TerceraChartActionEnum.SnapToCandle &&
               !this.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.Cancel)).Active;
    }
}
