// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import * as XLSX from 'xlsx';

/* eslint-disable @typescript-eslint/no-extraneous-class */
export class FilesHelper {
    // #region Private
    private static readonly BlobTypesByExtension: Record<string, string> = { // https://www.iana.org/assignments/media-types/media-types.xhtml#text
        txt: 'text/plain',
        html: 'text/html',
        json: 'application/json',
        csv: 'text/csv',
        log: 'text/plain'
    };

    private static getBlobTypeByFileName (filename: string): string | null {
        if (filename == null) { return null; }

        const splits = filename.split('.');
        const extension = (splits.length > 0) ? splits[splits.length - 1] : '';
        const blobType = this.BlobTypesByExtension[extension] ?? this.BlobTypesByExtension.txt;

        return blobType;
    }

    private static s2ab (s: string): ArrayBuffer { // need for export to Excel with SheetJS #93681
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);

        for (let i = 0; i < s.length; i++) {
            view[i] = s.charCodeAt(i) & 0xFF;
        }

        return buf;
    }
    // #endregion Private

    // #region Public
    public static saveFile (filename: string, data: any, blob?: Blob): void {
        const file = blob ?? new Blob([data], { type: this.getBlobTypeByFileName(filename) });

        if ((window.navigator as any).msSaveOrOpenBlob != null) { // IE10+
            (window.navigator as any).msSaveOrOpenBlob(file, filename);
        } else { // Others
            const a = document.createElement('a');
            const url = URL.createObjectURL(file);
            a.href = url;
            a.download = filename;
            document.body.appendChild(a);
            a.click();
            setTimeout(() => {
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            }, 0);
        }
    }

    public static saveExcelFile (filename: string, tables: Array<{ Data: any[], TableName: string }>): void {
        const wb = XLSX.utils.book_new();
        wb.Props = {
            Title: filename,
            CreatedDate: new Date()
        };

        for (let tableI = 0; tableI < tables.length; tableI++) {
            const table = tables[tableI];
            const tableData = table.Data ?? [];
            const reportName = table.TableName != null ? table.TableName.slice(0, 31) : ''; // can't set sheetNames longer than 31 symb

            wb.SheetNames.push(reportName);

            const ws = XLSX.utils.aoa_to_sheet(tableData);
            wb.Sheets[reportName] = ws;
        }

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

        this.saveFile(filename + '.xlsx', null, new Blob([this.s2ab(wbout)], { type: 'application/octet-stream' }));
    }
    // #endregion Public
}
