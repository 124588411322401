// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type Account } from '@shared/commons/cache/Account';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type SpreadPlan } from '@shared/commons/cache/SpreadPlan';
import { InstrumentSpecificType } from '../Instruments/InstrumentSpecificType';
import { HistoryType } from './HistoryType';
import { TFInfo } from './TFInfo';

export class ReloadHistoryParams {
    public count: number | undefined = undefined;
    public callBack: any;
    public userPin: any;
    public userId: any;
    public route: any;
    public RouteId: any;
    public symbol: any;
    public Instrument: Instrument;

    public UseDefaultInstrumentHistoryType: boolean = false;
    public FromTime: Date = null;
    public ToTime: Date = new Date();

    public TimeFrameInfo: TFInfo;

    public InstrumentID: any = -1;
    public IsContinuous: boolean = false;
    public InstrumentTradableID: any = null;

    public InstrumentFullName: string = '';

    public Plan: SpreadPlan = null;
    public NeedApplySpreadPlan: boolean = false;
    public NeedLoadSecondPartOfHistory: boolean = false;

    /**
     * Aggregated by server if true
     */
    public ShouldCheckAggregatedPeriods: boolean = false;

    constructor (hp: ReloadHistoryParams | undefined = undefined) {
        this.fill(hp);
    }

    private fill (hp: ReloadHistoryParams | undefined): void {
        this.TimeFrameInfo = new TFInfo();
        if (hp === undefined) {
            return;
        }
        this.count = hp.count;
        this.callBack = hp.callBack;

        this.userPin = hp.userPin;
        this.route = hp.route;

        this.UseDefaultInstrumentHistoryType = hp.UseDefaultInstrumentHistoryType;

        this.FromTime = hp.FromTime;
        this.ToTime = hp.ToTime;

        this.TimeFrameInfo = hp.TimeFrameInfo.Copy();

        this.symbol = hp.symbol;
        this.userId = hp.userId;

        this.InstrumentID = hp.InstrumentID;
        this.IsContinuous = hp.IsContinuous;
        this.Instrument = hp.Instrument;
        this.InstrumentTradableID = hp.InstrumentTradableID;
        this.InstrumentFullName = hp.InstrumentFullName;

        this.Plan = hp.Plan;
        this.NeedApplySpreadPlan = hp.NeedApplySpreadPlan;
        this.NeedLoadSecondPartOfHistory = hp.NeedLoadSecondPartOfHistory;
        this.ShouldCheckAggregatedPeriods = hp.ShouldCheckAggregatedPeriods;
    }

    public updateWithProps (props: Record<string, any>): void {
        this.updateAccount(props.account);
        this.updateInstrument(props.instrument);
        this.updateTimeFrameInfo(props.TimeFrameInfo, props.onlyMainSession);
        if (props.FromTime != null) { this.FromTime = props.FromTime; }
        if (props.ToTime != null) { this.ToTime = props.ToTime; }
        if (props.count != null) { this.count = props.count; }
        if (props.UseDefaultInstrumentHistoryType != null) {
            this.UseDefaultInstrumentHistoryType = props.UseDefaultInstrumentHistoryType;
        }
    }

    private updateAccount (account?: Account): void {
        if (account != null) {
            this.userPin = account?.userPin;
            this.userId = account?.userID;
        }
    }

    private updateInstrument (instrument?: Instrument): void {
        if (instrument != null) {
            this.symbol = instrument.ShortName;
            this.route = instrument.Route;

            this.InstrumentFullName = instrument.GetInteriorID();

            this.IsContinuous = instrument.InstrumentSpecificType === InstrumentSpecificType.ContinuousContract;
            this.InstrumentTradableID = instrument.InstrumentTradableID;
            this.InstrumentID = parseInt(instrument.Id);
            this.Instrument = instrument;
        }
    }

    private updateTimeFrameInfo (tfInfo?: TFInfo, onlyMainSession?: boolean): void {
        if (tfInfo != null) {
            this.TimeFrameInfo = tfInfo;

            this.Plan = this.TimeFrameInfo.Plan;
            this.NeedApplySpreadPlan = this.calculateNeedApplySpreadPlan();
            this.NeedLoadSecondPartOfHistory = this.Plan?.IsNeedLoadHistoryForSpread(this.Instrument, this.TimeFrameInfo.HistoryType) ?? false;

            if (onlyMainSession != null) {
                this.TimeFrameInfo.SessionInfo.OnlyMainSession = onlyMainSession;
            }
        }
    }

    private calculateNeedApplySpreadPlan (): boolean {
        return this.Plan !== null &&
            this.Plan.IsNotEmptyPlan(this.Instrument) &&
            this.TimeFrameInfo.HistoryType !== HistoryType.LAST;
    }

    static createFrom (props: Record<string, any>): ReloadHistoryParams {
        const historyParams = new ReloadHistoryParams();
        historyParams.updateWithProps(props);
        return historyParams;
    }
}
