// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraInstrumentLookup } from './Lookup/TerceraInstrumentLookup';
import { type Rectangle } from '@shared/commons/Geometry';
import { type Instrument } from '@shared/commons/cache/Instrument';

export class QuickTableInstrumentLookup extends TerceraInstrumentLookup {
    private _isProcessedCallback: boolean = false;
    tag: any;
    callback: (value: Instrument | undefined, tag: any) => void;
    isFormShowed: boolean;
    constructor (initProps, data?, callback?: (value: Instrument | undefined, tag: any) => void, rect?: Rectangle) {
        super();
        this.tag = data;
        this.callback = callback;
        this.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
        void this.set('isPosAbsolute', true);
    }

    oncomplete (): void {
        super.oncomplete();
        const selectedInstrument: Instrument = this.tag.cell.QuickTableEditingInfo.GetDataHandler();
        void this.set('selectedItem', selectedInstrument);
        void this.set('visible', true);
        this.Controls.lookupTB.setFocus();
        // const timeout = setTimeout(() => {
        //     super.setFocus();
        //     clearTimeout(timeout);
        // }, 50);
    }

    runCallback (instrument: Instrument): void {
        this._isProcessedCallback = true;
        this.callback(instrument, this.tag);
    }

    onLostFocus (): void {
        if (!this.isFormShowed) {
            this.callback(undefined, this.tag);
        }
    }

    closeForm (): void {
        super.closeForm();
        if (!this._isProcessedCallback) {
            this.onLostFocus();
        }
    }
}

TerceraInstrumentLookup.extendWith(QuickTableInstrumentLookup, { });
