// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectPortfolioRealtimeStatisticsMessage } from '../../../../Utils/DirectMessages/DirectPortfolioRealtimeStatisticsMessage';
import { DirectPortfolioStatistic } from '../../../../Utils/DirectMessages/DirectPortfolioStatisticsMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PortfolioStatisticsMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectPortfolioRealtimeStatisticsMessage[] {
        const message = new DirectPortfolioRealtimeStatisticsMessage();
        const groups = this.getGroupsFromFieldSet(fieldSet);

        this.ProcessGroups(groups, message);

        return [message];
    }

    private ProcessGroups (groups, message): void {
        if (groups) {
            for (let i = 0; i < groups.length; i++) {
                let group = groups[i];
                if (group.GroupId === FieldsFactory.PORTFOLIO_MONTHLY_STATISTIC_GROUP) {
                    this.ProcessGroups(group.fieldSet, message);
                    return;
                }

                if (group.FieldId === FieldsFactory.FIELD_GROUP) {
                    group = group.Value;
                }

                const grData = this.readGroupData(group);
                message.DataArray.push(grData);
            }
        }
    }

    public readGroupData (group): DirectPortfolioStatistic {
        const directGroup = new DirectPortfolioStatistic();
        const groupLen = group.Count();

        for (let j = 0; j < groupLen; j++) {
            const field = group.fieldSet[j];
            const value = field.Value;

            switch (field.FieldId) {
            case FieldsFactory.FIELD_DATE:
                directGroup.Date = value;
                break;
            case FieldsFactory.FIELD_PERCENT:
                directGroup.Percent = value;
                break;
            case FieldsFactory.FIELD_AMOUNT:
                directGroup.AbsoluteAmount = value;
                break;
            case FieldsFactory.FIELD_TYPE:
                directGroup.IsBest = value == 1;
                break;
            case FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID:
                directGroup.TradableInstrumentId = value;
                break;
            case FieldsFactory.FIELD_ROUTE_ID:
                directGroup.RouteId = value;
                break;
            }
        }

        return directGroup;
    }

    private getGroupsFromFieldSet (fieldSet: PFixFieldSet): any[] {
        const groupIds = [FieldsFactory.PERCENT_ABSOLUTE_ENTRY_GROUP,
            FieldsFactory.PORTFOLIO_MONTHLY_STATISTIC_GROUP,
            FieldsFactory.PERCENT_ABSOLUTE_DAY_ENTRY_GROUP,
            FieldsFactory.PERCENT_ABSOLUTE_DAY_TYPE_ENTRY_GROUP,
            FieldsFactory.ABSOLUTE_DAY_ENTRY_GROUP,
            FieldsFactory.PERCENT_DAY_ENTRY_GROUP,
            FieldsFactory.PERCENT_INSTRUMENT_ROUTE_DAY_ENTRY_GROUP,
            FieldsFactory.PORTFOLIO_MONTHLY_INSTRUMENT_RETURN_STATISTIC_ENTRY_GROUP,
            FieldsFactory.PERCENT_DAY_TYPE_ENTRY_GROUP];

        for (let i = 0; i < groupIds.length; i++) {
            const groups = fieldSet.GetGroups(groupIds[i]);

            if (groups?.length) {
                return groups;
            } // предполагаем, что в одном fieldSet-е группы только одного типа
        }

        return null;
    }
}
