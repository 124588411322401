// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectEntitlementProductDocumentResponseMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { EntitlementDocumentGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class EntitlementProductDocumentResponseMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectEntitlementProductDocumentResponseMessage[] {
        const msg = new DirectEntitlementProductDocumentResponseMessage();

        const documents = fs.GetGroups(FieldsFactory.DOCUMENT_GROUP);

        const len = documents ? documents.length : 0;
        for (let i = 0; i < len; i++) {
            const document = new EntitlementDocumentGroup(documents[i]);
            msg.AddDocument(document.GetDoucument());
        }

        return [msg];
    }
}
