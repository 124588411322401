// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class PositionsTotalInfo {
    public accountTotalPositionAmount: Map<string, number> = new Map<string, number>();
    public accountAveragePositionPriceWithAmountBuy: Map<string, number> = new Map<string, number>();
    public accountAveragePositionPriceWithAmountSell: Map<string, number> = new Map<string, number>();
    public accountPositionAmountBuy: Map<string, number> = new Map<string, number>();
    public accountPositionAmountSell: Map<string, number> = new Map<string, number>();
    public accountTotalProfitLoss: Map<string, number> = new Map<string, number>();

    public Clear (): void {
        this.accountTotalPositionAmount.clear();
        this.accountAveragePositionPriceWithAmountBuy.clear();
        this.accountAveragePositionPriceWithAmountSell.clear();
        this.accountPositionAmountBuy.clear();
        this.accountPositionAmountSell.clear();
        this.accountTotalProfitLoss.clear();
    }
}
