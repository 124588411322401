// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { contextMenuHandler } from '../../../Utils/AppHandlers';
import { TerceraLookup } from './TerceraLookup';
import $ from 'jquery';

export class TerceraFontLookup extends TerceraLookup {
    constructor () {
        super();
    }

    public override oninit (): void {
        super.oninit();

        this.items = [
            'Tahoma',
            'Verdana',
            'Times New Roman',
            'Georgia',
            'Courier New',
            'Lucida Console'
        ];
    }

    public override showForm (): void {
        if (!this.get('enabled')) {
            return;
        }

        const items = this.createMenuItems();

        const width = $(this.find('div')).outerWidth() + 50;
        const offset = $(this.find('div')).offset();
        const posY = offset.top - $(window).scrollTop() + 20;
        const posX = offset.left - $(window).scrollLeft();

        contextMenuHandler.Show(items, posX, posY, { width });
    }

    public createMenuItems (): any[] {
        const items = this.items;
        const menuItems = [];

        const callback = this.private_OnMenuItemSelected.bind(this);

        const len = items.length;
        for (let i = 0; i < len; i++) {
            const item = items[i];

            menuItems.push({
                text: item,
                tag: item,
                event: callback
            });
        }

        return menuItems;
    }

    public override getTypingLookupValueForSet (typedText: string): any {
        const items = this.items;
        const len = items.length;

        let selectedItem = this.get('selectedItem');
        typedText = typedText.toUpperCase();

        for (let i = 0; i < len; i++) {
            const font = items[i];
            if (font.toUpperCase().indexOf(typedText) !== -1) {
                selectedItem = font;
                break;
            }
        }

        return selectedItem;
    }

    public private_OnMenuItemSelected (menuItem): void {
        void this.set('selectedItem', menuItem.tag);
    }
}

TerceraLookup.extendWith(TerceraFontLookup, {
    data: function () {
        return {
            width: 200,
            height: 20
        };
    }
});
