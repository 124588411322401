// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { _BaseGeneralSettings } from '../../Commons/Settings/BaseGeneralSettings';
import { BaseGeneralSettingsInstanceCreator } from '../../Commons/Settings/BaseGeneralSettingsWrapper';
import { WDSettingsStorageManager } from '../UtilsClasses/WDSettingsStorageManager';

export class WDGeneralSettings extends _BaseGeneralSettings {
    public override reset (): void {
        const defaultSettings = new WDGeneralSettings();
        this.restore(defaultSettings);
    }

    public onSettingsChanged (): void {
        WDSettingsStorageManager.wasChanged();
        super.onSettingsChanged();
    }
}

export const WDSettings: WDGeneralSettings = new WDGeneralSettings();
BaseGeneralSettingsInstanceCreator(WDSettings);
