// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectExternalLinkMessage, type DirectExternalLinkRemoveMessage } from '../../Utils/DirectMessages/DirectMessagesImport';
import { CustomEvent } from '../../Utils/CustomEvents';
import { UserWebStorageInstance } from '../../user-web-storage';
import { YoutubeExtLink } from './ExternalLink/YoutubeExtLink';
import { WebContainerExtLink } from './ExternalLink/WebContainerExtLink';
import { type BaseExtLink } from './ExternalLink/BaseExtLink';
import { AutochartistExtLink } from './ExternalLink/AutochartistExtLink';
import { LinkTokenLangExtLink } from './ExternalLink/LinkTokenLangExtLink';
import { NoneExtLink } from './ExternalLink/NoneExtLink';
import { AutochartistIFrameExtLink } from './ExternalLink/AutochartistIFrameExtLink';
import { TradingCentralExtLink } from './ExternalLink/TradingCentralExtLink';
import { type BrandingToolParams } from './ExternalLink/Branding/BrandingToolParams';
import { DataCache } from '../DataCache';
import { ExternalLinkType } from './ExternalLink/Branding/ExternalLinkType';
import { ITChartAdvancedExtLink } from './ExternalLink/ITChartAdvancedExtLink';

export class ExternalLinksCache {
    private readonly linkTools: Map<number, BaseExtLink> = new Map<number, BaseExtLink>();

    public onAdd = new CustomEvent();
    public onUpdate = new CustomEvent();
    public onRemove = new CustomEvent();
    public onYtDataUpdate = new CustomEvent();

    public processExternalLinkMessage (externalLinkMessage: DirectExternalLinkMessage): void {
        const linkID = externalLinkMessage.Id;
        if (!linkID) return;

        let brandingToolParams: BrandingToolParams | null = null;
        if (externalLinkMessage.ExternalLinkParameters) {
            try {
                brandingToolParams = JSON.parse(externalLinkMessage.ExternalLinkParameters) as BrandingToolParams;
            } catch (e) {}
        }

        let isNewLink = false;
        let linkTool = this.linkTools.get(linkID);

        if (!isNullOrUndefined(externalLinkMessage.ExternalResource)) {
            if (!linkTool || linkTool.brandingToolExtResource !== externalLinkMessage.ExternalResource) {
                linkTool = this.getObjectByExtResource(externalLinkMessage.ExternalResource);
                this.linkTools.set(linkID, linkTool);
                isNewLink = true;
            }
            linkTool?.update(brandingToolParams, externalLinkMessage);
        }

        if (DataCache.Loaded) {
            if (isNewLink) {
                this.onAdd.Raise(linkTool);
            } else {
                this.onUpdate.Raise(linkTool);
            }

            if (linkTool instanceof YoutubeExtLink) {
                void this.ytRequestLinks([linkTool]);
            }
        }
    }

    public processExternalLinkRemoveMessage (externalLinkRemove: DirectExternalLinkRemoveMessage): void {
        const linkID = externalLinkRemove.Id;
        if (!linkID) return;

        const deleteLink = this.linkTools.get(linkID);
        this.linkTools.delete(linkID);

        this.onRemove.Raise(deleteLink);
    }

    public clear (): void {
        this.linkTools.clear();
        this.ytRequestCount = 0;
        this.ytFirstRequest = true;
        if (!isNullOrUndefined(this.ytTimer)) {
            clearInterval(this.ytTimer);
            this.ytTimer = null;
        }
    }

    public getLinkById (id: number): BaseExtLink {
        if (id === null) { return null; }
        return this.linkTools[id];
    }

    public getExtLinks (): BaseExtLink[] {
        return Array.from(this.linkTools.values());
    }

    public getWebContainerExtLinks (): WebContainerExtLink[] {
        return this.getToolsOfType(WebContainerExtLink);
    }

    public getAutochartistIFrameExtLinks (): AutochartistIFrameExtLink[] {
        return this.getToolsOfType(AutochartistIFrameExtLink);
    }

    public getNoneExtLinks (): NoneExtLink[] {
        return this.getToolsOfType(NoneExtLink);
    }

    public getLinkTokenLangExtLinks (): LinkTokenLangExtLink[] {
        return this.getToolsOfType(LinkTokenLangExtLink);
    }

    public getAutochartistExtLink (): AutochartistExtLink | null {
        return this.getExtLinks()
            .find((linkTool): linkTool is AutochartistExtLink => linkTool instanceof AutochartistExtLink);
    }

    public getTradingCentralExtLink (): TradingCentralExtLink | null {
        return this.getExtLinks()
            .find((linkTool): linkTool is TradingCentralExtLink => linkTool instanceof TradingCentralExtLink);
    }

    public getITChartAdvancedExtLink (): ITChartAdvancedExtLink | null {
        return this.getExtLinks()
            .find((linkTool): linkTool is ITChartAdvancedExtLink => linkTool instanceof ITChartAdvancedExtLink);
    }

    public getYoutubeExtLinks (): YoutubeExtLink[] {
        return this.getExtLinks()
            .filter((linkTool): linkTool is YoutubeExtLink => linkTool instanceof YoutubeExtLink)
            .filter(linkTool => new Date() <= linkTool.availUntilDate);
    }

    private getObjectByExtResource (extResource: ExternalLinkType): BaseExtLink {
        switch (extResource) {
        case ExternalLinkType.AUTOCHARTIST_RP:
            return new AutochartistExtLink();
        case ExternalLinkType.NONE:
            return new NoneExtLink();
        case ExternalLinkType.LINK_TOKEN_LANG:
            return new LinkTokenLangExtLink();
        case ExternalLinkType.TRADING_CENTRAL:
            return new TradingCentralExtLink();
        case ExternalLinkType.AUTOCHARTIST_IFRAME:
            return new AutochartistIFrameExtLink();
        case ExternalLinkType.ITCHART_ADVANCED:
            return new ITChartAdvancedExtLink();
        case ExternalLinkType.YOUTUBE_LINK:
            return new YoutubeExtLink();
        case ExternalLinkType.WEB_CONTAINER:
            return new WebContainerExtLink();
        default:
            throw new Error(`Unsupported extResource type: ${extResource}`);
        }
    }

    private ytTimer: NodeJS.Timeout = null;
    private readonly ytUpdateInterval = 10 * 60 * 1000; // 10 minutes
    private ytRequestCount = 0;
    private ytFirstRequest = true;

    public async ytStartScheduler (): Promise<void> {
        const linkTools = this.ytOptimizedLinks();
        await this.ytRequestLinks(linkTools);

        this.ytTimer = setInterval(() => {
            const linkTools = this.ytOptimizedLinks();
            this.ytRequestLinks(linkTools).catch(error => {
                console.error('Error in ytRequestLinks:', error);
            });
        }, this.ytUpdateInterval);
    }

    private ytOptimizedLinks (): YoutubeExtLink[] {
        const linkTools = this.getYoutubeExtLinks();

        const filteredTools = linkTools.filter((tool) => {
            if (!tool.getUrl()) { return false; }

            const videoCreateDate = tool.config.getVideoCreateDate();
            const streamStartDate = tool.config.getStreamStartDate();
            const streamEndDate = tool.config.getStreamEndDate();

            const wasCreatedToday = videoCreateDate?.isSameDay(new Date()) ?? false;
            const wasStreamStartedToday = streamStartDate?.isSameDay(new Date()) ?? false;

            if (
                this.ytFirstRequest ||
                (wasCreatedToday || (wasStreamStartedToday && streamEndDate === null))
            ) {
                return true;
            }
            return false;
        });

        return filteredTools;
    }

    private async ytRequestLinks (linkTools: YoutubeExtLink[]): Promise<void> {
        this.ytRequestCount = linkTools.length;

        for (const linkTool of linkTools) {
            const linkID = linkTool.id;

            try {
                const resp = await UserWebStorageInstance.youtubePageParser.post(linkTool.getUrl(), linkTool.videoID);
                const tool = this.linkTools.get(linkID) as YoutubeExtLink;
                tool?.config.update(resp.data);
            } catch (error) {
                console.error(`Failed to fetch or process URL for linkID ${linkID}:`, error);
            } finally {
                this.ytRequestCount -= 1;
                this.onYtDataUpdate.Raise(linkTool);
                if (this.ytRequestCount === 0) {
                    this.ytFirstRequest = false;
                }
            }
        }
    }

    private getToolsOfType<T extends BaseExtLink>(toolClass: new (...args: any[]) => T): T[] {
        return this.getExtLinks()
            .filter((linkTool): linkTool is T => linkTool instanceof toolClass)
            .sort((tool1, tool2) => tool1.id - tool2.id);
    }
}
