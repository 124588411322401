// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DirectQuote2Message } from '../../Utils/DirectMessages/DirectMessagesImport';
import { Level2Update } from '../../Utils/Enums/Constants';
import { SpreadMeasureMode, SpreadPlan } from './SpreadPlan';
import { Level2Collection, Level2Type } from './Level2Collection';
import { type Instrument } from './Instrument.js';
import { type QuoteCache } from './QuoteCache';

export class Level2CashItem {
    public Symbol: Instrument;
    public SymbolID: string;
    public Owner: QuoteCache;
    public BidsNonSpread: Level2Collection;
    public AsksNonSpread: Level2Collection;

    public Asks = {};//= new Dictionary<SpreadPlan, Level2Collection>();
    public Bids = {};//= new Dictionary<SpreadPlan, Level2Collection>();

    constructor (instrument: Instrument, quoteCache: QuoteCache) {
        this.Symbol = instrument;
        this.SymbolID = instrument.GetInteriorID();
        this.Owner = quoteCache;
        this.BidsNonSpread = Level2Collection.Synchronized(null, Level2Type.Bids);
        this.AsksNonSpread = Level2Collection.Synchronized(null, Level2Type.Asks);
    }

    public newQuote (message): void {
        if (message.TargetInstrumentName !== this.SymbolID) {
            return;
        }

        if (message.Thail == null) {
            const topLevelQuoteChanged = this.InsertQuote(message);

            // alexb: верхний уровень изменился - генерим левел2 котировку
            // if (Utils.GenerateLevel1QuoteFromLevel2 && topLevelQuoteChanged)
            //     this.GenerateLevel1Quote(message);

            return;
        }

        if (message.Update === Level2Update.FullRefresh) {
            this.BidsNonSpread.Clear();
            this.AsksNonSpread.Clear();
        }

        this.InsertQuote(message, message.Update === Level2Update.Incremental);

        const al = message.Thail;
        for (let i = 0; i < al.length; i++) {
            this.InsertQuote(al[i], message.Update === Level2Update.Incremental);
        }

        //
        // Generate level1 quote
        //
        // if (Utils.GenerateLevel1QuoteFromLevel2)
        //     this.GenerateLevel1Quote(message);

        //
        // Один раз пересчитываем спредированные котировки
        //
        if (message.Update === Level2Update.FullRefresh) {
            this.CalcSpreadQuotesQuick();
        }
    }

    public InsertQuote (q2Msg, calcSpread = true): boolean {
        let topLevelQuoteChanged = false;

        if (q2Msg.Side == 1) {
            topLevelQuoteChanged = this.BidsNonSpread.Add(q2Msg);
        } else if (q2Msg.Side == 2) {
            topLevelQuoteChanged = this.AsksNonSpread.Add(q2Msg) || topLevelQuoteChanged;
        }

        return !!topLevelQuoteChanged;
    }

    /// получить спредированный L2Message (создается копия)
    public GetL2SpreadQuote (basis, si, best_bid, best_ask): any {
        if (si != null) {
            const copy = new DirectQuote2Message();

            basis.CopyQuoteMsg(copy);
            if (si.SpreadMode != SpreadPlan.DYNAMIC_SPREADMODE) {
                const useVariableTickSize = si.SpreadMeasure == SpreadMeasureMode.SPREAD_IN_PIPS && this.Symbol !== null && this.Symbol.VariableTickList.length > 1;
                if (basis.Side == 1) {
                    if (useVariableTickSize) // для переменного тиксайза отсчитываем сколько тиков лежит между двумя ценами
                    {
                        const spread_best_bid = si.CalcBid(best_bid, best_ask, this.Symbol); // находим спредированное значение лучшего бида
                        const sign = Math.sign(spread_best_bid - best_bid);
                        const ticks = this.Symbol.CalculateTicks(best_bid, spread_best_bid - best_bid) * sign; // сколько тиков между спредированным и неспредированным значением
                        copy.Price = this.Symbol.CalculatePrice(copy.Price, ticks); // отчитываем кол-во тиков от неспредированного значения для того чтобы получить спредированное
                    } else {
                        copy.Price += si.CalcBid(best_bid, best_ask, this.Symbol) - best_bid;
                    }
                } else if (basis.Side == 2) {
                    if (useVariableTickSize) // аналогично биду
                    {
                        const spread_best_ask = si.CalcAsk(best_bid, best_ask, this.Symbol);
                        const sign = Math.sign(spread_best_ask - best_ask);
                        const ticks = this.Symbol.CalculateTicks(best_ask, spread_best_ask - best_ask) * sign;
                        copy.Price = this.Symbol.CalculatePrice(copy.Price, ticks);
                    } else {
                        copy.Price += si.CalcAsk(best_bid, best_ask, this.Symbol) - best_ask;
                    }
                }
            } else {
                if (basis.Side == 1) {
                    copy.Price = si.CalcBid(copy.Price, 0, this.Symbol);
                } else if (basis.Side == 2) {
                    copy.Price = si.CalcAsk(0, copy.Price, this.Symbol);
                }
            }

            // #28258
            if (this.Symbol !== null) {
                copy.Price = this.Symbol.RoundPriceToNearestPointSize(copy.Price);
            }

            return copy;
        }
        return basis;
    }

    public CalcSpreadQuotesQuick (): void {
        // --- применение спредов ---
        if (Object.keys(this.Bids).length > 0 || Object.keys(this.Asks).length > 0) {
            const isFullName = true;// DataCache.IsFullName(this.SymbolID);

            // сначала считаем бест_бид и бест_аск. исходя из них, будет считаться спред
            let bid = 0;
            let ask = 0;

            let bidSortedList = null;
            let askSortedList = null;

            let bestBidQuote = null;
            let bestAskQuote = null;

            if (Object.keys(this.BidsNonSpread.items).length > 0) {
                // бест бид (без спреда!)
                bidSortedList = this.BidsNonSpread.GetSortedList(true);
                bestBidQuote = bidSortedList[0].Quote;
                bid = bestBidQuote.Price;
            }

            if (Object.keys(this.AsksNonSpread.items).length > 0) {
                // бест аск (без спреда!)
                askSortedList = this.AsksNonSpread.GetSortedList(false);
                bestAskQuote = askSortedList[0].Quote;
                ask = bestAskQuote.Price;
            }

            // обновляем все спредированные элементы
            if (bidSortedList !== null) {
                const keys = Object.keys(this.Bids);
                for (let i = 0; i < keys.length; i++) {
                    const bids = this.Bids[keys[i]];

                    if (isFullName) {
                        bids.Clear();
                    }

                    for (let i = 0; i < bidSortedList.length; i++) {
                        bids.Add(this.GetL2SpreadQuote(bidSortedList[i].Quote, bids.SpreadItem, bid, ask));
                    }
                }
            }

            if (askSortedList !== null) {
                const keys = Object.keys(this.Asks);
                for (let i = 0; i < keys.length; i++) {
                    const asks = this.Asks[keys[i]];

                    if (isFullName) {
                        asks.Clear();
                    }

                    for (let i = 0; i < askSortedList.length; i++) {
                        asks.Add(this.GetL2SpreadQuote(askSortedList[i].Quote, asks.SpreadItem, bid, ask));
                    }
                }
            }
        }
    }

    public GetBids (sp: SpreadPlan): Level2Collection {
        // alexb optmization: игнорим пустые спред планы
        if (sp === null || !sp.IsNotEmptyPlan(this.Symbol)) {
            return this.BidsNonSpread;
        }

        let collection = this.Bids[sp.Id];
        if (!collection) {
            collection = Level2Collection.Synchronized(sp.GetItem(this.Symbol), Level2Type.Bids);
            this.Bids[sp.Id] = collection;
            // Костя: на момент создания спредированной коллекции уже могла прийти котировка, в случае если котировка единственная - мы ничего не увидим (#62648)
            this.CalcSpreadQuotesQuick();
        }

        return collection;
    }

    public GetAsks (sp: SpreadPlan): Level2Collection {
        // alexb optmization: игнорим пустые спред планы
        if (sp === null || !sp.IsNotEmptyPlan(this.Symbol)) {
            return this.AsksNonSpread;
        }

        let collection = this.Asks[sp.Id];
        if (!collection) {
            collection = Level2Collection.Synchronized(sp.GetItem(this.Symbol), Level2Type.Asks);
            this.Asks[sp.Id] = collection;
            // Костя: на момент создания спредированной коллекции уже могла прийти котировка, в случае если котировка единственная - мы ничего не увидим (#62648)
            this.CalcSpreadQuotesQuick();
        }

        return collection;
    }
}
