// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AssetRowDataItem } from '../RowItems/AssetRowDataItem';
import { InvestingAssetsPanelTemplate } from '../templates.js';
import { openContextMenu } from '../Controls/elements/InvestingMenu';
import { openOrderEntry } from '../Screen/InvestingOE';
import Ractive from 'ractive';

export class InvestingAssetsPanel extends Ractive {
    public selectedItem: any = null;

    public getType (): string { return 'InvestingAssetsPanel'; }

    public override oninit (): void {
        this.on('showChartPanelEvent', this.showChartPanelEvent);
        this.on('moreButtonClickEvent', this.moreButtonClickEvent);
    }

    public override oncomplete (): void {
        this.createHeaderRow();
        this.createRowTable();
    }

    public showChartPanelEvent (index, tableRowItem): void {
        const items = this.get('arrayRowData');
        if (!items) {
            return;
        }

        const newSelectedItem = tableRowItem;
        if (newSelectedItem === this.selectedItem) {
            return;
        }

        if (this.selectedItem) {
            this.selectedItem.setActiveStatus(false);
        }

        newSelectedItem.setActiveStatus(true);
        this.selectedItem = newSelectedItem;

        this.fire('ChartOpen', this, { date: items[index] });
    }

    public moreButtonClickEvent (index, tableRowItem, posX: number, posY: number): void {
        const items = this.get('arrayRowData');
        const itemsMenu = this.createMenuItems(items[index]);
        openContextMenu(posX, posY, itemsMenu);
    }

    public closeChartPanelEvent (): void {
        if (this.selectedItem) {
            this.selectedItem.setActiveStatus(false);
        }

        this.selectedItem = null;
    }

    public createHeaderRow (): void {
        const arrayHeaderColumn = [
            { Name: 'Def. symbol' },
            { Name: 'SOD qty' },
            { Name: 'Cur. value for sell' }
        ];

        void this.set({ arrayHeaderColumn });
    }

    public createRowTable (): void {
        const arrayRowData = [];
        const instr = [
            {
                Symbol: 'AAPL',
                Description: 'Apple Inc',
                Side: 'Long',
                Quantity: '1.00',
                Profit: '140.00 USD',
                NumberProfit: 140.00,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_apple.svg'
            },
            {
                Symbol: 'MSFT',
                Description: 'Microsoft Corporation',
                Side: 'Short',
                Quantity: '3.00',
                Profit: '-13856.00 USD',
                NumberProfit: -138.00,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'Instr',
                Description: 'my test instr',
                Side: 'Short',
                Quantity: '12.00',
                Profit: '0 USD',
                NumberProfit: 0,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'AAPL',
                Description: 'Apple Inc',
                Side: 'Long',
                Quantity: '1.00',
                Profit: '140.00 USD',
                NumberProfit: 140.00,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_apple.svg'
            },
            {
                Symbol: 'MSFT',
                Description: 'Microsoft Corporation',
                Side: 'Short',
                Quantity: '3.00',
                Profit: '-13856.00 USD',
                NumberProfit: -138.00,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'Instr',
                Description: 'my test instr',
                Side: 'Short',
                Quantity: '12.00',
                Profit: '0 USD',
                NumberProfit: 0,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'Instr',
                Description: 'my test instr',
                Side: 'Short',
                Quantity: '12.00',
                Profit: '0 USD',
                NumberProfit: 0,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'AAPL',
                Description: 'Apple Inc',
                Side: 'Long',
                Quantity: '1.00',
                Profit: '140.00 USD',
                NumberProfit: 140.00,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_apple.svg'
            },
            {
                Symbol: 'MSFT',
                Description: 'Microsoft Corporation',
                Side: 'Short',
                Quantity: '3.00',
                Profit: '-13856.00 USD',
                NumberProfit: -138.00,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'Instr',
                Description: 'my test instr',
                Side: 'Short',
                Quantity: '12.00',
                Profit: '0 USD',
                NumberProfit: 0,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'Instr',
                Description: 'my test instr',
                Side: 'Short',
                Quantity: '12.00',
                Profit: '0 USD',
                NumberProfit: 0,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'AAPL',
                Description: 'Apple Inc',
                Side: 'Long',
                Quantity: '1.00',
                Profit: '140.00 USD',
                NumberProfit: 140.00,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_apple.svg'
            },
            {
                Symbol: 'MSFT',
                Description: 'Microsoft Corporation',
                Side: 'Short',
                Quantity: '3.00',
                Profit: '-13856.00 USD',
                NumberProfit: -138.00,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'Instr',
                Description: 'my test instr',
                Side: 'Short',
                Quantity: '12.00',
                Profit: '0 USD',
                NumberProfit: 0,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            },
            {
                Symbol: 'Instr',
                Description: 'my test instr',
                Side: 'Short',
                Quantity: '12.00',
                Profit: '0 USD',
                NumberProfit: 0,
                Img: 'current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg'
            }
        ];

        for (let i = 0; i < instr.length; i++) {
            const item = instr[i];
            const row = new AssetRowDataItem(item);
            arrayRowData.push(row);
        }

        void this.set({ arrayRowData });
    }

    public createMenuItems (dataItem): any[] {
        const items = [
            {
                text: 'Details',
                imgStyle: 'info'
            },
            {
                text: 'Buy',
                imgStyle: 'buy',
                event: function () {
                    openOrderEntry(true, dataItem.source, this.get('accountItem'), true);
                }.bind(this)
            },
            {
                text: 'Sell',
                imgStyle: 'sell',
                event: function () {
                    openOrderEntry(false, dataItem.source, this.get('accountItem'), true);
                }.bind(this)
            }
        ];

        return items;
    }
}

Ractive.extendWith(InvestingAssetsPanel, {
    template: InvestingAssetsPanelTemplate,
    data: function () {
        return {
            arrayHeaderColumn: [],
            arrayRowData: []
        };
    }
});
