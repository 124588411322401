// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCache } from '../../Commons/DataCache';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { PositionFormatter } from '../../Commons/cache/Formatters/PositionFormatter';
import { type Position } from '../../Commons/cache/Position';
import { Resources } from '../../Localizations/Resources';
import { OperationType } from '../../Utils/Trading/OperationType';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { ThemeManager } from '../misc/ThemeManager';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { PositionItem } from './PositionItem';

export class ClosePositionsMutuallyItem extends BaseItem {
    public position: Position;
    public NetQty: boolean;

    constructor (pos: Position, sessionSettings?: SessionSettingsType/*, isNetQty */) {
        super(sessionSettings);

        if (isNullOrUndefined(pos)) return;
        this.position = pos;

        this.NetQty = false; //! !isNetQty;   // #92796 пункт 4 a. ii. доки: https://docs.google.com/document/d/1y4W7_QHbI4siuOD4OwrBucXWxQdbbqJoiw8bfEKvDhI/edit

        const curTh = ThemeManager.CurrentTheme;

        this.CellForeColorMap[SIDE_COLUMN] = pos.BuySell === OperationType.Buy ? curTh.TableLongBuyColor : curTh.TableShortSellColor;
        this.CellForeColorMap[QUANTITY_COLUMN] = curTh.TableGoldColor;
    }

    public override ColumnCount (): number {
        return ClosePositionsMutuallyItem.columnsParams.length;
    }

    public override getColumnData (column): ColumnData {
        const resultData = new ColumnData();
        const value = resultData.Value = this.getColumnValue(column);
        const pos = this.position;

        if (pos) {
            switch (column) {
            // case POSITION_ID_COLUMN:
            // case SIDE_COLUMN:
            case QUANTITY_COLUMN:
                resultData.FormattedValue = DataCache.formatVolume(pos.Instrument, Math.abs(value), WDSettingsUtils.displayAmountInLots(), pos.ProductType, pos.Account);
                break;

            default:
                resultData.FormattedValue = value ? value.toString() : '';
                break;
            }
        }

        return resultData;
    }

    public override getColumnValue (column: number): any {
        const pos = this.position;
        if (!pos) return null;

        switch (column) {
        case POSITION_ID_COLUMN:
            return PositionFormatter.PositionId(pos);

        case SIDE_COLUMN:
            return PositionFormatter.OperationStr(pos);

        case QUANTITY_COLUMN:{
            const sign = PositionItem.GetPositionSign(pos, this.NetQty);
            return PositionItem.GetPositionAmount(pos) * sign;
        }

        default:
            return null;
        }
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = ClosePositionsMutuallyItem.columnsParams[column];

        result.Hidden = Resources.isHidden(result.HeaderKey);
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        return result;
    }

    /// <summary>
    /// Здесь хранятся настройки колонок (один экземпляр на класс)
    /// </summary>
    public static readonly columnsParams = [
        new ColumnParams('panel.ClosePositionsMutually.PositionID', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.ClosePositionsMutually.Side', 75, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams('panel.ClosePositionsMutually.Quantity', 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false)
    ];

    public GetMenuIndex (headerKey: string): number {
        if (ClosePositionsMutuallyItem.ListMenuIndex.Contains(headerKey)) {
            return ClosePositionsMutuallyItem.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    public static readonly ListMenuIndex =
        [
            'panel.ClosePositionsMutually.PositionID',
            'panel.ClosePositionsMutually.Side',
            'panel.ClosePositionsMutually.Quantity'
        ];
}

const POSITION_ID_COLUMN = 0;
const SIDE_COLUMN = 1;
const QUANTITY_COLUMN = 2;
