// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { PositionItem } from '../cache/PositionItem';
import { ColouringModes } from '../elements/QuickTable/QuickTableColumn';
import { TradingButtonItem } from '../elements/TradingButtonStripe';
import { RactiveTooltip } from '../misc/Decorators';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { OrdersPanelBase } from './OrdersPanelBase';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { DynProperty } from '@shared/commons/DynProperty';
import { IsAllowed, type IsAllowedResponce } from '@shared/commons/IsAllowed';
import { PositionActionEnum } from '@shared/utils/Trading/PositionActionEnum';
import { NumericLinks } from '@shared/commons/cache/OrderParams/order-edit/OrderEditBase';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { IsAllowedResponceReason } from '@shared/commons/IsAllowedResponceReason';
import { type Position } from '@shared/commons/cache/Position';

export class PositionsPanel extends OrdersPanelBase<PositionItem> {
    public regroupFIFOTimerID: any = null; // for setTimeout of regrouping FIFO positions when populate #80010
    public InitShowTotals: any = null;
    public InitDisplayTrades: any = null;
    public InitShowGropQtyAsTotal: any = null;

    constructor () {
        super();
        this.Name = 'PositionsPanel';
        this.headerLocaleKey = 'panel.positions';
        this.moreThanOneTTKey = 'panel.positions.menu.MoreOneSymbolInfoDisabled.tt'; // #95439
        this.noOneTTKey = 'panel.positions.menu.NoSymbolInfoDisabled.tt';
    }

    // #region Overrides

    public override oncomplete (): void {
        super.oncomplete();
        this.initContextMenuItemsState();

        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }
        quickTable.onTableMouseDown.Subscribe(this.onTableMouseDown, this);
        quickTable.AfterExpandItem.Subscribe(this.onExpandSuperPositionClick, this); // collapse super fifo event #80010
        this.UpdateTradesVisibility(quickTable.additionalFilter); // fifo sub items will show for a moment if not do this
    }

    // #region ApplicationPanelWithTable
    public override getType (): PanelNames { return PanelNames.PositionsPanel; }
    public override populateItemsDirect (): void {
        super.populateItemsDirect();
        const positions = DataCache.getAllPositions();
        const positionsCorporateAction = DataCache.getAllPositionsCorporateAction();

        this.populateItems(positions);
        this.populateItems(positionsCorporateAction);
    }

    public override createMenuItems (): any[] {
        const actions = PositionActionEnum;
        const placedFromVal = PlacedFrom.WEB_POSITIONS_PANEL_CONTEXT_MENU;

        let items = [
            {
                locKey: 'panel.positions.menu.Modify',
                tag: actions.PositionModifying,
                enabled: true,
                event: this.action.bind(this, actions.PositionModifying, true, placedFromVal, null)
            },
            {
                locKey: 'panel.positions.menu.Close',
                tag: actions.ByIds,
                enabled: true,
                event: this.action.bind(this, actions.ByIds, true, placedFromVal)
            },
            {
                locKey: 'panel.positions.menu.MutualClose',
                tag: actions.Mutual,
                enabled: true,
                event: this.action.bind(this, actions.Mutual, true, placedFromVal)
            },
            {
                locKey: 'panel.positions.menu.ExercisePosition',
                tag: actions.ExerciseOption,
                visible: false, // по умолчанию видимость отключена. Пункт отображается и активен, если в панели выбрана позиция по опциону с Exercise style = American
                enabled: true,
                event: this.action.bind(this, actions.ExerciseOption, true, placedFromVal)
            },
            {
                locKey: 'panel.positions.menu.ExerciseCancel',
                tag: actions.ExerciseCancel,
                visible: false, // по умолчанию видимость отключена. Пункт отображается и активен, если в панели выбрана позиция по опциону со статусом Pending exercise.
                enabled: true,
                disabledReason: null,
                event: this.action.bind(this, actions.ExerciseCancel, true, placedFromVal)
            },
            {
                locKey: 'panel.positions.menu.ModifyProductType',
                tag: actions.ModifyProductType,
                enabled: true,
                visible: DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING_MODE_MODIFICATION),
                event: this.action.bind(this, actions.ModifyProductType, true, placedFromVal, NumericLinks.ProductType)
            },
            {
                locKey: 'panel.positions.menu.View',
                tag: 'View',
                enabled: true,
                subitems: [
                    {
                        locKey: 'panel.positions.menu.DisplayTrades',
                        tag: 'DisplayTrades',
                        checked: false,
                        enabled: true,
                        canCheck: true,
                        visible: !Resources.isHidden('panel.positions.menu.DisplayTrades'),
                        event: this.DisplayTradesStateChange.bind(this)
                    },
                    {
                        locKey: 'panel.positions.menu.ShowToolbar',
                        tag: 'ShowToolbar',
                        checked: this.ToolbarVisible,
                        visible: !Resources.isHidden('panel.positions.menu.ShowToolbar'),
                        enabled: true,
                        canCheck: true,
                        event: this.ShowToolbarStateChange.bind(this)
                    },
                    {
                        locKey: 'panel.positions.menu.ShowTotals',
                        tag: 'ShowTotals',
                        checked: false,
                        visible: !Resources.isHidden('contextMenu.Totals.visibility'),
                        enabled: true,
                        canCheck: true,
                        event: this.ShowTotalsStateChange.bind(this)
                    },
                    {
                        locKey: 'panel.positions.menu.ShowGroupQtyAs.locKey',
                        tag: 'ShowGroupQtyAs',
                        checked: false,
                        enabled: true,
                        visible: !Resources.isHidden('panel.positions.menu.ShowGroupQtyAs'),
                        canCheck: true,
                        subitems: [
                            {
                                locKey: 'panel.positions.menu.ShowGroupQtyAs.Total',
                                tag: PositionsPanel.ShowGroupQtyAs.Total,
                                checked: this.ToolbarVisible,
                                enabled: true,
                                canCheck: true,
                                event: this.ShowGroupQtyAsStateChange.bind(this)
                            },
                            {
                                locKey: 'panel.positions.menu.ShowGroupQtyAs.Net',
                                tag: PositionsPanel.ShowGroupQtyAs.Net,
                                checked: true,
                                enabled: true,
                                canCheck: true,
                                event: this.ShowGroupQtyAsStateChange.bind(this)
                            }
                        ]
                    }
                ]
            }
        ];

        items = super.AddOpeningPanelsCM(items);
        super.AddSymbolInfoContextMenuItemIfNeed(items, true);

        return items;
    }

    public override GetOpeningPanelsCMLocKeysSet (): any {
        const keysObj = {};

        keysObj[ApplicationPanelWithTable.OPEN_CHART] = 'panel.positions.showChartMI';
        keysObj[ApplicationPanelWithTable.OPEN_MD] = 'panel.positions.showMDMI';
        keysObj[ApplicationPanelWithTable.OPEN_TS] = 'panel.positions.showTSMI';
        keysObj[ApplicationPanelWithTable.OPEN_OE] = 'panel.positions.showOEMI';

        return keysObj;
    }

    public override preparePopup (): void {
        super.preparePopup();
        this.updateAllowedActions();
        this.updateMenuItemsTooltip();
    }

    public override Properties (): DynProperty[] {
        const qt = super.getQuickTable();
        const properties = OrdersPanelBase.prototype.Properties.call(this);

        properties.push(new DynProperty('ShowTotals', qt.ShowTotals, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('DisplayTrades', qt.additionalFilter, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('ShowGroupQtyAsTotal', !this.IsShowGroupQtyAsNet(), DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public override callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);
        this.InitShowTotals = DynProperty.getPropValue(newProperties, 'ShowTotals');
        this.InitDisplayTrades = DynProperty.getPropValue(newProperties, 'DisplayTrades');
        this.InitShowGropQtyAsTotal = DynProperty.getPropValue(newProperties, 'ShowGroupQtyAsTotal');
    }

    public override SetColumnsDefaultDisplayIndex (table): void {
        table.columns[PositionItem.SYMBOL_COL_INDEX].displayedIndex = 0;
        table.columns[PositionItem.ACCOUNT_COL_INDEX].displayedIndex = 15;
        table.columns[PositionItem.QUANTITY_COL_INDEX].displayedIndex = 4;
        table.columns[PositionItem.BASIS_COL_INDEX].displayedIndex = 5;
        table.columns[PositionItem.EXPDATE_COL_INDEX].displayedIndex = 7;
        table.columns[PositionItem.STRIKE_COL_INDEX].displayedIndex = 9;
        table.columns[PositionItem.POSNUMBER_COL_INDEX].displayedIndex = 10;
        table.columns[PositionItem.OPERATION_COL_INDEX].displayedIndex = 1;
        table.columns[PositionItem.DATE_TIME_COL_INDEX].displayedIndex = 14;
        table.columns[PositionItem.CUR_PRICE_COL_INDEX].displayedIndex = 6;
        table.columns[PositionItem.SL_COL_INDEX].displayedIndex = 16;
        table.columns[PositionItem.TP_COL_INDEX].displayedIndex = 17;
        table.columns[PositionItem.PROFIT_USD_COL_INDEX].displayedIndex = 8;
        table.columns[PositionItem.SWAPS_COL_INDEX].displayedIndex = 2;
        table.columns[PositionItem.EXPOSITION_USD_COL_INDEX].displayedIndex = 3;
        table.columns[PositionItem.COMISSION_COL_INDEX].displayedIndex = 11;
        table.columns[PositionItem.COMMENTS_COL_INDEX].displayedIndex = 18;
        table.columns[PositionItem.ROUTE_COL_INDEX].displayedIndex = 19;
        table.columns[PositionItem.TYPE_COL_INDEX].displayedIndex = 20;
        table.columns[PositionItem.TRAILING_STOP_COL_INDEX].displayedIndex = 21;
        table.columns[PositionItem.LOGIN_COL_INDEX].displayedIndex = 22;
        table.columns[PositionItem.SYMBOL_DESCRIPTION_COL_INDEX].displayedIndex = 23;
        table.columns[PositionItem.USED_MARGIN_COL_INDEX].displayedIndex = 24;
        table.columns[PositionItem.PL_TICKS_COL_INDEX].displayedIndex = 13;
        table.columns[PositionItem.NET_PL_COL_INDEX].displayedIndex = 12;
        table.columns[PositionItem.NET_PL_PRECENT_POS_EXPOSURE_COL_INDEX].displayedIndex = 13;

        table.columns[PositionItem.CLOSE_COL_INDEX].displayedIndex = 99999;
    }

    public override SetColumnsColouringMode (table): void {
        super.ProcessSetColumnsColouringMode(table, [PositionItem.CUR_PRICE_COL_INDEX], ColouringModes.Previous, undefined, undefined);
        table.columnsIndexWithColoringByPrevValue = [PositionItem.CUR_PRICE_COL_INDEX];
        super.ProcessSetColumnsColouringMode(table, [PositionItem.PROFIT_USD_COL_INDEX,
            PositionItem.COMMENTS_COL_INDEX,
            PositionItem.PL_TICKS_COL_INDEX,
            PositionItem.NET_PL_COL_INDEX,
            PositionItem.NET_PL_PRECENT_POS_EXPOSURE_COL_INDEX],
        ColouringModes.Signed, undefined, undefined);
    }
    // #endregion

    // #region OrdersPanelBase
    public override getDoubleClickActionParams (): any {
        return {
            action: PositionActionEnum.ByIds,
            confirm: true,
            placedFrom: PlacedFrom.WEB_POSITIONS_PANEL_DB_CLICK
        };
    }

    public override getHotButtonPlacedFrom (): PlacedFrom { return PlacedFrom.WEB_POSITIONS_PANEL_HOT_BTN; }
    public override DCEventsSubscribe (): void {
        DataCache.OnAddPosition.Subscribe(this.AddPositionEvent, this);
        DataCache.OnUpdatePosition.Subscribe(this.UpdatePositionEvent, this);
        DataCache.OnRemovePosition.Subscribe(this.RemovePositionEvent, this);

        // CorporateAction
        DataCache.OnAddCorporateAction.Subscribe(this.AddPositionEvent, this);
        DataCache.OnUpdateCorporateAction.Subscribe(this.UpdatePositionEvent, this);
        DataCache.OnRemoveCorporateAction.Subscribe(this.RemovePositionEvent, this);
    }

    public override DCEventsUnSubscribe (): void {
        DataCache.OnAddPosition.UnSubscribe(this.AddPositionEvent, this);
        DataCache.OnUpdatePosition.UnSubscribe(this.UpdatePositionEvent, this);
        DataCache.OnRemovePosition.UnSubscribe(this.RemovePositionEvent, this);

        // CorporateAction
        DataCache.OnAddCorporateAction.UnSubscribe(this.AddPositionEvent, this);
        DataCache.OnUpdateCorporateAction.UnSubscribe(this.UpdatePositionEvent, this);
        DataCache.OnRemoveCorporateAction.UnSubscribe(this.RemovePositionEvent, this);
    }

    public override CloseBtnClick (data): void {
        if (data.row.item?.Disabled) {
            RactiveTooltip.showTooltip('IsAllowedResponceReason.OptionPendingExerciseStatus');
            return;
        }

        const selectedOrderIdArr = [data.row.id];
        if (this.IsClosingForbidden(selectedOrderIdArr)) {
            return;
        }

        void DataCache.FOrderExecutor.positionAction(PositionActionEnum.ByIds, this.isFiltered, selectedOrderIdArr, selectedOrderIdArr, true, PlacedFrom.WEB_POSITIONS_PANEL_DB_CLICK, undefined);
    }

    public override IsClosingAllowed (selectedOrderIdArr): IsAllowedResponce | null {
        const positions = DataCache.getPositionsById(selectedOrderIdArr) || DataCache.getPositionsCorporateActionById(selectedOrderIdArr);
        let result = null;
        for (const ID in positions) {
            const res = IsAllowed.IsPositionClosingAllowed(positions[ID]);
            if (result === null || !res.Allowed) {
                result = res;
            }
        }
        return result;
    }

    public override createTradingButtonItems (): TradingButtonItem[] {
        return [
            new TradingButtonItem(PositionActionEnum.All, 'panels.positions.buttons.closeAll', this.isFiltered && this.isFilteredAction(PositionActionEnum.All)),
            new TradingButtonItem(PositionActionEnum.CLXAll, 'panels.positions.buttons.clxAll', this.isFiltered && this.isFilteredAction(PositionActionEnum.CLXAll)),
            new TradingButtonItem(PositionActionEnum.Negative, 'panels.positions.buttons.closeNegative', this.isFiltered && this.isFilteredAction(PositionActionEnum.Negative)),
            new TradingButtonItem(PositionActionEnum.Positive, 'panels.positions.buttons.closePositive', this.isFiltered && this.isFilteredAction(PositionActionEnum.Positive)),
            new TradingButtonItem(PositionActionEnum.ByAccountAndInstrument, 'panels.positions.buttons.closeForInstrument', this.isFiltered && this.isFilteredAction(PositionActionEnum.ByAccountAndInstrument)),
            new TradingButtonItem(PositionActionEnum.Short, 'panels.positions.buttons.closeShort', this.isFiltered && this.isFilteredAction(PositionActionEnum.Short)),
            new TradingButtonItem(PositionActionEnum.Long, 'panels.positions.buttons.closeLong', this.isFiltered && this.isFilteredAction(PositionActionEnum.Long)),
            new TradingButtonItem(PositionActionEnum.ByIds, 'panels.positions.buttons.closeSelected', this.isFiltered && this.isFilteredAction(PositionActionEnum.ByIds)),
            new TradingButtonItem(PositionActionEnum.ReverseByInstrument, 'panels.positions.buttons.reverse', this.isFiltered && this.isFilteredAction(PositionActionEnum.ReverseByInstrument)),
            new TradingButtonItem(PositionActionEnum.ReverseByIds, 'panels.positions.buttons.reverseSelected', this.isFiltered && this.isFilteredAction(PositionActionEnum.ReverseByIds)),
            new TradingButtonItem(PositionActionEnum.CLXByAccountAndInstrument, 'panels.positions.buttons.clxForInstrument', this.isFiltered && this.isFilteredAction(PositionActionEnum.CLXByAccountAndInstrument))
        ];
    }

    protected override isFilteredAction (action: number): boolean {
        switch (action) {
        case PositionActionEnum.CLXAll:
        case PositionActionEnum.ByIds:
        case PositionActionEnum.ByAccountAndInstrument:
        case PositionActionEnum.ReverseByInstrument:
        case PositionActionEnum.ReverseByIds:
        case PositionActionEnum.CLXByAccountAndInstrument:
            return false;
        default:
            return true;
        }
    }

    public override updateAllowedActions (): void {
        if (isNullOrUndefined(this.Controls)) {
            return;
        }
        if (isNullOrUndefined(this.quickTableRactive)) {
            return;
        }
        const menuTagDict = this.menuTagDict;
        if (isNullOrUndefined(menuTagDict)) {
            return;
        }

        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }

        const btns = this.Controls.btns;
        if (isNullOrUndefined(btns)) {
            return;
        }

        const actions = PositionActionEnum;

        const selectedOrderIdArr = quickTable.selectedRowIds;
        const row = quickTable.rows[selectedOrderIdArr[0]];
        const allRowsIds = quickTable.getTableItemsIds();
        const allowedActionSet = DataCache.FOrderExecutor.getAllowedPositionActionSet(selectedOrderIdArr, allRowsIds, this.isFiltered);

        btns.updateEnability(allowedActionSet);

        const ins = !isNullOrUndefined(row) ? row.item.DisplayInstrumentName() : '';
        btns.setInstrument(actions.ByAccountAndInstrument, ins);
        btns.setInstrument(actions.CLXByAccountAndInstrument, ins);
        btns.setInstrument(actions.ReverseByInstrument, ins);

        const isAllowedPositionModifying = allowedActionSet[actions.PositionModifying];
        menuTagDict[actions.PositionModifying].enabled = isAllowedPositionModifying !== undefined && isAllowedPositionModifying.Allowed;

        const isAllowedByIds = allowedActionSet[actions.ByIds];
        menuTagDict[actions.ByIds].enabled = isAllowedByIds !== undefined && isAllowedByIds.Allowed;
        const isAllowedModifyProductType = allowedActionSet[actions.ModifyProductType];
        menuTagDict[actions.ModifyProductType].enabled = isAllowedModifyProductType !== undefined && isAllowedModifyProductType.Allowed;
        menuTagDict[actions.ModifyProductType].visible = DataCache.isAllowedForMyUser(RulesSet.FUNCTION_TRADING_MODE_MODIFICATION);

        const isAllowedExerciseOption = allowedActionSet[actions.ExerciseOption];
        const exerciseOptionMenuItem = menuTagDict[actions.ExerciseOption];
        exerciseOptionMenuItem.visible = !isNullOrUndefined(isAllowedExerciseOption) && isAllowedExerciseOption?.Allowed; // Пункт отображается и активен, если в панели выбрана позиция по опциону с Exercise style = American.                // 93127
        // Пункт отображается и не активен, если в панели выбрано несколько позиций по опционам с Exercise style = American.  // https://docs.google.com/document/d/1GPd6aeAV_O25CrHPaRh_ixsSG0GpCHUx3JCU68QGPdI/edit
        exerciseOptionMenuItem.enabled = isAllowedExerciseOption?.Allowed && selectedOrderIdArr.length == 1;

        const isVisibleExerciseCancelOption = allowedActionSet[actions.ExerciseCancelVisibility];
        const isAllowedExerciseCancelOption = allowedActionSet[actions.ExerciseCancel];
        const exerciseCancelOptionMenuItem = menuTagDict[actions.ExerciseCancel];
        // Пункт отображается и активен, если в панели выбрана позиция по опциону со статусом Pending exercise.
        exerciseCancelOptionMenuItem.visible = !isNullOrUndefined(isVisibleExerciseCancelOption) && isVisibleExerciseCancelOption?.Allowed;
        exerciseCancelOptionMenuItem.enabled = isAllowedExerciseCancelOption?.Allowed;
        exerciseCancelOptionMenuItem.disabledReason = isAllowedExerciseCancelOption !== undefined ? isAllowedExerciseCancelOption.Reason : undefined;

        const mutualCloseMenuItem = menuTagDict[actions.Mutual];
        mutualCloseMenuItem.visible = DataCache.isAllowedForMyUser(RulesSet.FUNCTION_MUTUAL_CLOSE); // #115483
        mutualCloseMenuItem.enabled = DataCache.isAllowedMutualCloseByPosIdArr(selectedOrderIdArr); // https://docs.google.com/document/d/1GnwZfiWpM7ilTS43hmXhlje1pwk8HIkoj3OypQRqeak/edit

        const enabled = !!ins;
        super.SetOpeningPanelsCMEnability(menuTagDict, enabled);
    }

    public override action (action, confirm, placedFrom, numericLinkName): boolean {
        if (!super.action(action, confirm, placedFrom, numericLinkName)) {
            return false;
        }
        const quickTable = super.getQuickTable();
        const selectedRowsIds = quickTable.selectedRowIds;
        const allRowsIds = quickTable.getTableItemsIds();
        void DataCache.FOrderExecutor.positionAction(action, this.isFiltered, selectedRowsIds, allRowsIds, confirm, placedFrom, numericLinkName);
        return true;
    }

    public override isClosingAction (action): boolean {
        const closingActions = [
            PositionActionEnum.All,
            PositionActionEnum.ByAccountAndInstrument,
            PositionActionEnum.ByIds,
            PositionActionEnum.CLXAll,
            PositionActionEnum.CLXByAccountAndInstrument,
            PositionActionEnum.Long,
            PositionActionEnum.Short,
            PositionActionEnum.Positive,
            PositionActionEnum.Negative
        ];

        return closingActions.includes(action);
    }
    // #endregion

    // #endregion

    // из сохраненных properties восстанавливаем значения пунктов из контекстного меню панели
    public initContextMenuItemsState (): void {
        this.UpdateShowTotalsStateChange(!!this.InitShowTotals);
        if (this.menuTagDict?.ShowTotals) {
            this.menuTagDict.ShowTotals.checked = !!this.InitShowTotals;
        }

        if (this.menuTagDict?.DisplayTrades) {
            const displayTradesMenuItem = this.menuTagDict.DisplayTrades;
            displayTradesMenuItem.checked = !!this.InitDisplayTrades;
            this.DisplayTradesStateChange(displayTradesMenuItem);
        }

        if (this.menuTagDict?.ShowGroupQtyAs) {
            const showGroupQtyAsMenuItem = this.menuTagDict.ShowGroupQtyAs;
            if (showGroupQtyAsMenuItem.subitems) {
                const subItemTag = this.InitShowGropQtyAsTotal ? PositionsPanel.ShowGroupQtyAs.Total : PositionsPanel.ShowGroupQtyAs.Net;

                this.ShowGroupQtyAsStateChange(showGroupQtyAsMenuItem.subitems[subItemTag]); // #92796
            }
        }
    }

    // только нажатие левой кнопки мыши!
    public onTableMouseDown (hittestInfo): void {
        const quickTable = super.getQuickTable();

        if (isNullOrUndefined(quickTable) || quickTable.editableColID != hittestInfo.columnIndex || quickTable.editableRowID != hittestInfo.rowIndex) {
            return;
        }

        const actions = PositionActionEnum;
        const placedFromVal = PlacedFrom.WEB_POSITIONS_PANEL_DB_CLICK;
        const columnID = quickTable.sortedColumns[hittestInfo.columnIndex].PRIVATE.index;
        let numericLinkName;
        if (columnID === PositionItem.SL_COL_INDEX) {
            numericLinkName = NumericLinks.StopLoss;
        } else if (columnID === PositionItem.TP_COL_INDEX) {
            numericLinkName = NumericLinks.TakeProfit;
        } else if (columnID === PositionItem.PRODUCTTYPE_COL_INDEX) {
            numericLinkName = NumericLinks.ProductType;
        }

        this.action(actions.PositionModifying, true, placedFromVal, numericLinkName);
    }

    protected createPositionItem (position: Position): PositionItem {
        return new PositionItem(position, SessionSettings, this.IsShowGroupQtyAsNet());
    }

    public AddPositionEvent (position: Position): void {
        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }

        quickTable.AddItem(this.createPositionItem(position));
        if (position.SuperPositionId != -1) {
            this.updateFIFOItems();
        }

        quickTable.needRedrawBackground = true;
    }

    public RemovePositionEvent (position: Position): void {
        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }
        quickTable.RemoveItem(PositionItem.GetItemId(position));
        quickTable.needRedrawBackground = true;
    }

    public UpdatePositionEvent (position: Position): void {
        if (isNullOrUndefined(position)) {
            return;
        }
        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }

        const row = quickTable.rows[position.PositionId];
        if (isNullOrUndefined(row)) {
            return;
        }

        const item = row.item;
        if (isNullOrUndefined(item)) {
            return;
        }

        const isDisabledBecauseOfExerciseOption = position.IsPendingExerciseOptionStatus();
        item.Disabled = isDisabledBecauseOfExerciseOption; // #93127 обновление серого состояния основного айтема position

        const superPositionID = position.SuperPositionId;

        if (superPositionID != -1) {
            if (item.superItemId !== superPositionID) {
                item.superItemId = superPositionID;
                this.updateFIFOItems();
            }

            this.UpdateTradesDisabledState(superPositionID, isDisabledBecauseOfExerciseOption); // #93127 обновление серого состояния subitem-ов position
        }

        quickTable.needRedrawBackground = true; // #92796
    }

    public populateItems (positions): void {
        for (const posId in positions) {
            const pos = positions[posId];
            this.AddPositionEvent(pos);
        }
    }

    // expand super position event (FIFO) #80010
    public onExpandSuperPositionClick (data): void {
        const superRow = data.row;
        const superPos = superRow.item.FPosition;
        const table = data.table;

        superRow.collapsedFIFO = !superRow.collapsedFIFO;

        const posKeys = Object.keys(table.rows); let needUpdate = false;

        for (let i = 0; i < posKeys.length; i++) {
            const row = table.rows[posKeys[i]];
            const pos = row.item.FPosition;
            const superID = pos.SuperPositionId;
            if (pos.PositionId != superID && superID == superPos.PositionId) {
                needUpdate = true;
                row.visible = superRow.collapsedFIFO;
            }
        }

        const qt = super.getQuickTable();
        if (needUpdate && qt) {
            qt.rowCountChanged = true;
            qt.updateScrollElementsCount();
        }
    }

    public updateMenuItemsTooltip (): void {
        const menuTagDict = this.menuTagDict;
        if (isNullOrUndefined(menuTagDict)) {
            return;
        }

        const menuItem = menuTagDict[PositionActionEnum.ExerciseOption];
        if (!isNullOrUndefined(menuItem) && !menuItem.enabled) {
            menuItem.tooltip = Resources.getResource('panel.positions.menu.ExercisePositionDisabled.tt');
        } else {
            menuItem.tooltip = '';
        }

        const menuItemCancel = menuTagDict[PositionActionEnum.ExerciseCancel];
        // Пункт может отображаться и быть неактивным (тогда нужен тултип) по 2м причинам: 1) если в панели выбрано несколько позиций по опционам с Exercise style = American и Option exercise=Pending exercise. 2) если статус сессии не торговый(Trade session status у инструмента Allow operations -> Order entry = False) то не даем отменить исполнение.
        if (!isNullOrUndefined(menuItemCancel) && !menuItemCancel.enabled && menuItemCancel.disabledReason) {
            menuItemCancel.tooltip = Resources.getResource(
                menuItemCancel.disabledReason === IsAllowedResponceReason.MoreThanOnePosSelected
                    ? 'panel.positions.menu.ExercisePositionDisabled.tt'
                    : 'panel.positions.menu.CancelExerciseDisabledCuzTrSession.tt'
            );
        } else {
            menuItemCancel.tooltip = '';
        }
    }

    public IsShowGroupQtyAsNet (): boolean {
        return this.GetShowGroupQtyAsState() === PositionsPanel.ShowGroupQtyAs.Net;
    }

    public GetShowGroupQtyAsState (): number | null {
        if (Resources.isHidden('panel.positions.menu.ShowGroupQtyAs')) {
            return PositionsPanel.ShowGroupQtyAs.Total;
        }

        if (!this.menuTagDict) {
            return null;
        }

        const menu = this.menuTagDict.ShowGroupQtyAs;

        if (!menu?.subitems) {
            return null;
        }

        const netItem = menu.subitems[PositionsPanel.ShowGroupQtyAs.Net];

        if (netItem?.checked) {
            return PositionsPanel.ShowGroupQtyAs.Net;
        } else {
            return PositionsPanel.ShowGroupQtyAs.Total;
        }
    }

    public ShowGroupQtyAsStateChange (menuItem): void {
        if (!this.menuTagDict) {
            return;
        }

        const menu = this.menuTagDict.ShowGroupQtyAs;

        if (!menu?.subitems) {
            return;
        }

        const subitems = menu.subitems;

        let itemToUnCheck = PositionsPanel.ShowGroupQtyAs.Total;
        if (menuItem.tag === itemToUnCheck) { itemToUnCheck = PositionsPanel.ShowGroupQtyAs.Net; }

        subitems[itemToUnCheck].checked = false;
        subitems[menuItem.tag].checked = true;

        this.UpdateCellsOnShowGroupQtyAsStateChange(menuItem.tag);
    }

    public UpdateCellsOnShowGroupQtyAsStateChange (showGroupQtyAsValue): void {
        if (Resources.isHidden('panel.positions.menu.ShowGroupQtyAs')) {
            return;
        }

        if (showGroupQtyAsValue === undefined) {
            showGroupQtyAsValue = this.GetShowGroupQtyAsState();
        }

        const netMode = showGroupQtyAsValue === PositionsPanel.ShowGroupQtyAs.Net;

        const qt = super.getQuickTable();
        if (!qt) {
            return;
        }

        for (let i = 0; i < qt.rowsArray.length; i++) {
            const row = qt.rowsArray[i];

            if (!row || row.isGroupRow || !row.item) {
                continue;
            }

            row.item.NetQty = netMode;
        }

        qt.needRedrawBackground = true;
    }

    // обновление disabled состояния item-ов по superPositionID
    public UpdateTradesDisabledState (superID, isDisabled: boolean): void {
        isDisabled = !!isDisabled;

        const qt = super.getQuickTable();
        if (!qt) {
            return;
        }

        for (let i = 0; i < qt.rowsArray.length; i++) {
            const row = qt.rowsArray[i];
            const item = row.item;

            if (item && item.superItemId == superID) {
                item.Disabled = isDisabled;
            }
        }
    }

    public DisplayTradesStateChange (menuItem): void {
        this.UpdateTradesVisibility(menuItem.checked);
    }

    public UpdateTradesVisibility (state: boolean): void {
        const qt = super.getQuickTable();
        if (!qt) {
            return;
        }

        qt.additionalFilter = state;

        const collapsed = {};

        for (let i = 0; i < qt.rowsArray.length; i++) // trades rows visibiility change here
        {
            const row = qt.rowsArray[i];
            const item = row.item;

            if (item?.superItemId) {
                row.visible = state || item.ItemId == item.superItemId;

                if (!item.isSubItem) // запоминание collapsed состояния супер позиций
                { collapsed[item.ItemId] = row.collapsedFIFO; } else
                    if (state) { row.visible = collapsed[item.superItemId]; } // применение видимости для sub позиций
            }
        }

        const lS = LinkedSystem;

        if (lS.accLinkingActive) {
            const accId = lS.accountStorage[Object.keys(lS.accountStorage)[0]];
            qt.filterByAccountNumber(accId); // отфильтруем по аккаунту позиции, если раскрылся лишний трейд
        }

        qt.needRedrawBackground = true;
        qt.rowCountChanged = true;
        qt.updateScrollElementsCount();
    }

    public updateFIFOItems (): void {
        const me = this;
        const qt = me.quickTableRactive.quickTable;
        if (!qt) {
            return;
        }

        if (me.regroupFIFOTimerID) { clearTimeout(me.regroupFIFOTimerID); }

        me.regroupFIFOTimerID = setTimeout(
            function () {
                qt.regroupSuperItems(); // enough to do this one time when all positions are added (here for DataCache AddPosition event) (FIFO) #80010
                me.UpdateTradesVisibility(qt.additionalFilter);
            }, 0);
    }

    static ShowGroupQtyAs = {
        Total: 0,
        Net: 1
    };
}
