// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class VerticalDetailsTypePointer<GroupItemsType, GroupsType> {
    private _itemType: GroupItemsType = null;
    private _groupType: GroupsType = null;

    constructor (itemType: GroupItemsType, groupType: GroupsType) {
        this.ItemType = itemType;
        this.GroupType = groupType;
    }

    get ItemType (): GroupItemsType { return this._itemType; }
    set ItemType (value: GroupItemsType) { this._itemType = value; }

    get GroupType (): GroupsType { return this._groupType; }
    set GroupType (value: GroupsType) { this._groupType = value; }

    public toString (): string {
        return JSON.stringify(this);
    }

    public toJSON (): object {
        return {
            ItemType: this._itemType,
            GroupType: this._groupType
        };
    }

    public static fromJSON <GroupItemsType, GroupsType>(value: string): VerticalDetailsTypePointer<GroupItemsType, GroupsType> {
        if (isNullOrUndefined(value)) {
            return null;
        }

        const pased = JSON.parse(value);
        if (isNullOrUndefined(pased.ItemType) || isNullOrUndefined(pased.GroupType)) {
            return null;
        }

        return new VerticalDetailsTypePointer(pased.ItemType, pased.GroupType);
    }
}
