// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/// <summary>
/// AccountOperationRequestMessage
/// </summary>
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { type OperationType } from '../../../../Utils/Trading/OperationType';

export class AccountOperationRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet | null) {
        super(Message.CODE_ACCOUNT_OPERATION_REQUEST_MESSAGE, fieldSet);
    }

    public assetId (assetId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ASSET_ID, assetId);
    }

    public setOperationType (operationType: OperationType, serverMaxField): void {
        if (serverMaxField >= FieldsFactory.FIELD_OPERATION_TYPE2) {
            this.setFieldValue(FieldsFactory.FIELD_OPERATION_TYPE2, operationType);
        } else {
            this.setFieldValue(FieldsFactory.FIELD_OPERATION_TYPE, operationType);
        }
    }

    public setAccountId (accountId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ACCOUNT_ID, accountId);
    }

    public setCounterId (counterId: number): void {
        if (counterId) // #114179
        {
            this.setFieldValue(FieldsFactory.FIELD_COUNTER_ID, counterId);
        }
    }

    public setAmount (amount: number): void {
        this.setFieldValue(FieldsFactory.FIELD_AMOUNT, amount);
    }

    public setComment (comment: string): void {
        this.setFieldValue(FieldsFactory.FIELD_COMMENT, comment);
    }
}
