// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type Account } from '@shared/commons/cache/Account';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type Order } from '@shared/commons/cache/Order';
import { MarginInfoParameters } from '@shared/commons/UtilsClasses/MarginInfo/MarginInfoParameters';
import { MarginInfoWrapper } from '@shared/commons/UtilsClasses/MarginInfo/MarginInfoWrapper';
import { type ProductType } from '../Instruments/ProductType';
import { QuoteValid } from '../Quotes/QuoteValid';
import { OperationType } from './OperationType';
import { OrderType } from './OrderType';
import { SlTpUtils } from './SlTpUtils';
import { Quantity } from './Quantity';

export class OrderEditRequestData {
    public orderTypeId: any;
    public instrument: Instrument | null = null;
    public account: Account | null = null;
    public quote: any = null;
    public side: any = null;
    public tif: any = null;
    public cash: number = null;
    public quantity: any = null;
    public isCashMode: boolean = false;
    // TODO. Rename.
    public parameterDict: any = {};
    public placedFrom: any = null;
    public disclosedQuantity: any = null;
    public productType: ProductType | null = null;
    public leverageValue: any = null;
    public comment: any = null;
    public order: any = null;
    public useStopLimitInsteadStop: any = null;
    public limitPriceForStopLimitOrder: any = null;
    public ocoCustomOrdersData: any = null;
    public totalFee: number | null = null;
    public afterTradeCash: number | null = null;

    // Тільки в PositionEdit використовує:
    public position: any = null;
    public canEditSLOrTrailingStop: any = null;
    public canEditTP: any = null;
    public isSLchanged: any = null;
    public isTPchanged: any = null;
    public UseSkipNoChange: any = null;
    public userComment: string = null;

    public TryGetProductType (): ProductType | null {
        if (this.productType !== null) {
            return this.productType;
        } else if (!isNullOrUndefined(this.order) && this.order.ProductType !== null) {
            return this.order.ProductType;
        } else if (!isNullOrUndefined(this.position) && this.position.ProductType !== null) {
            return this.position.ProductType;
        } else {
            return null;
        }
    }

    public static GetDataFromOrderDict (orderDict: OrderEditRequestData): OrderEditRequestData {
        const result = new OrderEditRequestData();
        const orders = Object.values(orderDict);
        const order: Order = orders[0];

        if (order != null) {
            result.instrument = order.Instrument;
            result.account = order.Account;
            result.side = order.BuySell;
            result.orderTypeId = order.OrderType;
            result.order = order;
        }

        return result;
    }

    public GetMarginParameters (): MarginInfoParameters {
        const params = new MarginInfoParameters();
        params.account = this.account;
        params.instrument = this.instrument;
        params.orderType = this.orderTypeId;
        params.productType = this.TryGetProductType();
        params.leverageValue = this.leverageValue;

        params.isCashMode = this.isCashMode;
        if (params.isCashMode) {
            params.cash = this.cash;
        } else {
            params.amountLots = Quantity.toLots(this.quantity, this.instrument);
        }

        params.limitPrice = this.getLimitPrice();
        params.stopPrice = this.getStopPrice();
        params.isLong = this.side === OperationType.Buy;

        return params;
    }

    /**
     * Makes a margin request and returns the Amount from the response.
     * @returns {Promise<number | null>} The amount from the margin request, or null if not found.
     */
    public async GetMarginAmount (): Promise<number | null> {
        const marginParameters = this.GetMarginParameters();

        const marginInfoWrapper = new MarginInfoWrapper();
        const amount = await marginInfoWrapper.GetMarginAmount(marginParameters);

        return amount ?? null;
    }

    public async MarginRequest (): Promise<[number, number]> {
        const marginParameters = this.GetMarginParameters();

        const marginInfoWrapper = new MarginInfoWrapper();
        const totalFee = await marginInfoWrapper.GetTotalFeeFromMarginRequest(marginParameters);
        const afterTradeCash = await marginInfoWrapper.GetAfterTradeCashFromMarginRequest(marginParameters);

        return [totalFee, afterTradeCash];
    }

    private getLimitPrice (): number {
        const orderType = this.orderTypeId;
        if (orderType === OrderType.Stop) {
            return this.getStopPrice();
        }

        if (orderType === OrderType.TrailingStop) {
            return this.getTrailingStopPrice();
        }

        if (orderType === OrderType.Limit || orderType === OrderType.StopLimit || orderType === OrderType.OCO) {
            return this.parameterDict?.limitPrice;
        }

        return null;
    };

    private getStopPrice (): number {
        const orderType = this.orderTypeId;
        if (orderType === OrderType.Stop || orderType === OrderType.StopLimit) {
            return this.parameterDict?.stopPrice;
        }

        return null;
    };

    private getTrailingStopPrice (): number {
        const offsetVisual = this.parameterDict?.trailingStop;
        const instrument = this.instrument;
        const isBuy = this.side === OperationType.Buy;

        if (isNullOrUndefined(instrument)) {
            return offsetVisual;
        }
        const lastQuote = instrument.GetLastQuote(QuoteValid.Last);
        if (isNullOrUndefined(lastQuote)) { return offsetVisual; }

        const price = isBuy ? lastQuote.Ask : lastQuote.Bid;
        const sign = isBuy ? 1 : -1;
        const ticks = SlTpUtils.toRealValue(offsetVisual, instrument, SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(instrument));
        return instrument.CalculatePrice(price, ticks * sign, true);
    }
}
