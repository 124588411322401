// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum FollowerRequestStatus {
    UNKNOWN = -1,
    FOLLOW = 0,
    DISABLE_FOLLOWERS = 1,
    REJECT = 2,
    ALREADY_FOLLOWING_ANOTHER_MASTER = 3,
    MASTER_ACCOUNT_CANNOT_BE_FOLLOWER = 4,
}
