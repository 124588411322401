// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { OELeverageSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Localizations/Resources';
import { OrderUtils } from '../../../Utils/Trading/OrderUtils';
import { ProductType } from '../../../Utils/Instruments/ProductType';
import { type Instrument } from '../../../Commons/cache/Instrument.js';
import { type Account } from '../../../Commons/cache/Account.js';
import { ResourcesManager } from '../../../Commons/properties/ResourcesManager';

export class OELeverageSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'OELeverageSelector'; }

    public override oninit (): void {
        super.oninit();
        this.observe('selectedItem', this.updateLeverageValue);
        this.observe('leverageValue', this.updateSelectedItemByLeverage);
        this.observe('instrument account productType', this.repopulate);
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.newOrderEntry.Leverage'));
    }

    public updateLeverageValue (selectedItem): void {
        void this.set('leverageValue', selectedItem ? selectedItem.value : null);
    }

    public updateSelectedItemByLeverage (leverage: number): void // need for CreateAlertPanel to change ComboBox selected item by custom leverageValue
    {
        const currentSelectedItem = this.get('selectedItem');
        if (!isNullOrUndefined(currentSelectedItem) && currentSelectedItem.value !== leverage) {
            const items = this.get('items');
            for (let i = 0; i < items.length; i++) {
                if (items[i].value == leverage) {
                    void this.set('selectedItem', items[i]);
                    return;
                }
            }
        }
    }

    public async repopulate (newV, oldV): Promise<void> {
        if (!isNullOrUndefined(newV) && newV === oldV) {
            return;
        }

        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');
        const productType: ProductType = this.get('productType') || ProductType.General;
        const dict = !isNullOrUndefined(instrument) ? instrument.getLeverages(account, productType) : null;

        if (isNullOrUndefined(instrument) || isNullOrUndefined(account) || !dict?.length || !instrument.isHedgingNettingType()) {
            await this.set({
                leverageValue: null, // #113886
                visible: false
            }); return;
        }

        const items = dict.map(function (leverage) {
            return {
                value: leverage,
                text: OrderUtils.GetFormattedLeverage(leverage)
            };
        });

        const visible = true; // (items[0].value == ProductType.General) ? false : true,           // при ProductType.General скрываем комбобокс
        const enabled = items.length !== 1; // при единственном ProductType делаем комбобокс enabled

        void this.set({
            defaultValue: (items.length > 0) ? items[items.length - 1].value : null,
            items,
            visible,
            enabled
        });
    }
}

ContainerControl.extendWith(OELeverageSelector, {
    template: OELeverageSelectorTemplate,
    data: function () {
        return {
            label: '',

            instrument: null,
            account: null,

            items: null,
            selectedItem: null,
            defaultValue: null,

            leverageValue: null,
            productType: null,
            showLastValue: false,

            visible: false,
            enabled: true,

            showLabel: true,

            tooltip: 'OELeverageSelector.tooltip'
        };
    }
});
