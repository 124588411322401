// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Quantity } from '@shared/utils/Trading/Quantity';
import { Resources } from '@shared/localizations/Resources';
import { BaseSettings } from '../../../../Settings/BaseGeneralSettingsWrapper';
import { BaseSettingsUtils } from '../../../../UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { BaseOrderWarning } from './BaseOrderWarning';

export class OrderQtyExceedWarning extends BaseOrderWarning {
    public getText (): string {
        const orderEdit = this.orderEdit;
        const quantity = orderEdit.getEstOrExactQuantity();
        const lotsAmount = Quantity.toLots(quantity, orderEdit.instrument, orderEdit.account, orderEdit.productType);
        const exceedValue = BaseSettings.warnIfOrderQtyExceedValue;
        if (BaseSettings.warnIfOrderQtyExceed && lotsAmount > exceedValue) {
            const inLots = BaseSettingsUtils.displayAmountInLots();
            const lotSize = orderEdit.instrument.LotSize;
            const qtyStr = inLots ? lotsAmount.toString() : (lotsAmount * lotSize).toString();
            const qtyWarnStr = inLots ? exceedValue.toString() : (exceedValue * lotSize).toString();
            const text = Resources.getResource('general.warnings.quantityExceedText', [qtyStr, qtyWarnStr]);

            return text;
        }

        return '';
    }
}
