// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Rectangle } from '@shared/commons/Geometry';
import { Cursors } from '@shared/commons/Cursors';
import { TerceraChartCashItemSeriesCacheScreenDataDateChangeType } from '../../Series/TerceraChartCashItemSeriesEnums';
import { Line, PolyLine, PolyText, PolyTextItem, SolidBrush } from '@shared/commons/Graphics';
import { TerceraChartBaseScaleRenderer, type TerceraChartBaseScaleRendererSettings } from './TerceraChartBaseScaleRenderer';
import { MouseButtons } from '@front/controls/UtilsClasses/ControlsUtils';
import { ChartDataType } from '../../Utils/ChartConstants';
import { TerceraChartAction, TerceraChartActionEnum } from '../../TerceraChartAction';
import { ChartZoomRendererButtons } from '../TerceraChartZoomRenderer';
import { TerceraChartPriceScaleRendererMouseState } from './TerceraChartPriceScaleRenderer';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { Periods } from '@shared/utils/History/TFInfo';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { type ISeriesRenderer } from '../Utils/ISeriesRenderer';
import { type TerceraChartCashItemSeries } from '../../Series/TerceraChartCashItemSeries';
import { type TerceraChartBase } from '../../TerceraChartBase';
import { type TerceraChartBaseActionProcessor } from '../../TerceraChartActionProcessor/TerceraChartBaseActionProcessor';

export class TerceraChartTimeScaleRenderer extends TerceraChartBaseScaleRenderer<TerceraChartBase> implements ISeriesRenderer<TerceraChartCashItemSeries> {
    static CHART_RIGHT_SPACE = 55;
    // = function (chartActionProcessor, settings, terceraChart)
    // #region Properties

    // this.settings; //TerceraChartTimeScaleRendererSettings

    lastMouseDownPt: any;// Point

    chartActionProcessor; // ITerceraChartActionProcessor

    // #endregion

    mouseState: TerceraChartPriceScaleRendererMouseState = TerceraChartPriceScaleRendererMouseState.None; // TerceraChartPriceScaleRendererMouseState

    // TerceraChartBaseScaleRenderer.call(this, settings, terceraChart);

    Series: TerceraChartCashItemSeries = null;

    constructor (chartActionProcessor: TerceraChartBaseActionProcessor, settings: TerceraChartBaseScaleRendererSettings, terceraChart: TerceraChartBase) {
        super(settings, terceraChart);
        this.settings = settings;
        this.chartActionProcessor = chartActionProcessor;
        this.SetClassName('TerceraChartTimeScaleRenderer');
    }

    // TerceraChartTimeScaleRenderer.prototype = Object.create(TerceraChartBaseScaleRenderer.prototype);

    /// <summary>
    /// Набор фиксированных шагов для шкалы (в минутах)
    /// </summary>
    static stepsMinutes = [1, 5, 15, 30, 60, 240, 1440, 2880, 4320, 5760, 7200, 14400, 43200, 86400, 129600, 432000];

    /// <summary>
    /// Алгоритм:
    /// Засечки на шкале представлены в виде нескольких уровней: обычная, смена дня, недели, месяца, года. В первую очередь должны быть нарисованы
    /// старшие уровни, плюс засечки не должны налазить друга на друга и не находится чаще, чем stepPixel на одну засечку.
    /// В основном проходе по серии анализируем засечки и сохраняем их отедльно оп типам (allItems). Потом начинаем рисование со старших уровней,
    /// запоминая занятые диапозон пикселей, если в дальнейшем, младшая засечка не поместиться (IsCanBePlaced), она не будет рисоваться.
    /// </summary>
    Draw (gr, window, windowsContainer, advParams = null): void {
        const windowRect = window.ClientRectangle;

        const R = this.Rectangle;
        const RX = R.X;
        const RY = R.Y;
        const RW = R.Width - TerceraChartTimeScaleRenderer.CHART_RIGHT_SPACE;
        const RH = R.Height;

        gr.FillRect(this.settings.scaleBackBrush, RX, RY, R.Width, RH);
        // gr.DrawLine(this.settings.scaleAxisPen, RX, RY, RR - delta, RY);
        //
        const cashItemSeries = this.Series;
        if (cashItemSeries == null) { return; }

        const screenData = cashItemSeries.ChartScreenData;
        const allItems: any[] = [];
        for (let i = 0; i < 5; i++) { allItems.push([]); }

        const PixelMap: boolean [] = [];
        for (let i = 0; i < RW; i++) { PixelMap.push(false); }

        const TEXT_PADDING_ADDITIONAL = 6;
        const TEXT_PLATE_SIZE = 14;

        //
        // 1. Определяем подходящий шаг в пикселях (не чаще чем)
        // Пока фиксируем, потом возможно будем реагировать на шрифт
        //
        const stepPixel = 50;

        //
        // 2. Определяем подходящий шаг в минутах
        //
        let stepMin = -1;
        const currentPeriod = cashItemSeries.CashItem.FPeriod;

        //
        // Для кастом таймфреймов и тайм агрегаций отличных от Time выключаем логику
        //
        if (cashItemSeries.CashItem.ChartDataType != ChartDataType.Default || !Periods.IsStandardPeriod(currentPeriod)) {
            stepMin = 0;
        }
        //
        // Для остальных пытаемся подобрать подходящий шаг кратности
        //
        else {
            const stepsMinutes = TerceraChartTimeScaleRenderer.stepsMinutes;
            for (let i = 0; i < stepsMinutes.length; i++) {
                if (stepsMinutes[i] / currentPeriod * window.XScale >= stepPixel) {
                    stepMin = stepsMinutes[i];
                    break;
                }
            }

            if (stepMin == -1) { stepMin = stepsMinutes[stepsMinutes.length - 1]; }

            // По идее не должно быть такого, столкнулся на месяце при крупном масштабе
            if (stepMin < currentPeriod) { stepMin = currentPeriod; }
        }

        if (screenData.Storage.length < 1) {
            return;
        }

        // 3. Расчитываем данные для шкалы
        const scX = window.XScale;
        const lastScreenDate = screenData.Storage[screenData.Storage.length - 1].Time;
        let curX = window.PointsConverter.GetScreenXbyTime(lastScreenDate);

        let lastDrawingX = 0;
        const len = screenData.Storage.length;
        for (let i = len - 1; i >= 0; i--) {
            const curData = screenData.Storage[i]; // TerceraChartCashItemSeriesCacheScreenData
            const dt = screenData.Storage[i].DateTime;
            const dtInSessionTimeZoneWithOffset = screenData.Storage[i].DateTimeInSessionTimeZoneWithOffset;

            // Важно округлять в меньшую сторону, т.к. смещаются бары (связано с определением ширины бара: TerceraChartMainPriceRenderer.ProcessBarWidth), 2 и 4 - особый случай
            const currentDrawingX = window.XScale == 2 || window.XScale == 4 ? (curX + window.XScale / 2 + 1) : Math.floor(curX + window.XScale / 2);

            let isMultiplier = false;
            if (stepMin == 0) {
                // Чтобы не рисовать сильно часто линии
                if (curData.DateChangeType != TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None || Math.abs(currentDrawingX - lastDrawingX) > stepPixel) {
                    isMultiplier = true;
                    lastDrawingX = currentDrawingX;
                }
            }
            // Если шаг равен периоду - на каждома баре
            else if (stepMin == currentPeriod) { isMultiplier = true; }
            // Шаг меньше дня - часы+минут должны быть кратны
            else if (stepMin < 1440) {
                isMultiplier = ((dt.getHours() * 60 + dt.getMinutes()) % stepMin == 0);

                // Сепаратор может находиться на любом баре
                if (!isMultiplier && curData.DateChangeType != TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None) { isMultiplier = true; }
            }
            // Шаг день - на баре должна быть смена дня (или старше)
            else if (stepMin == 1440) { isMultiplier = curData.DateChangeType != TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None; }
            // Шаг больше дня - смена недели/месяца/года или дни кратны шагу
            else {
                // произошла смена недели/месяца/года
                if (curData.DateChangeType != TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None && curData.DateChangeType != TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day) { isMultiplier = true; }
                //
                else if (currentPeriod >= Periods.MONTH) { isMultiplier = (dt.getMonth() % (stepMin / Periods.MONTH)) == 0; }
                // Период больше равен дневки - смотрим чтобы день был кратиен
                else if (currentPeriod >= Periods.DAY) { isMultiplier = (dt.getDate() % (stepMin / 1440)) == 0; }
                // Период меньше дневки  - смотрим кратный день, плюс чтобы был флаг смена дня
                else { isMultiplier = (dt.getDate() % (stepMin / 1440)) == 0 && curData.DateChangeType == TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day; }
            }

            //
            if (stepMin >= 1440 && curData.DateChangeType == TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None) { curData.DateChangeType = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day; }

            if (isMultiplier) {
                let text = '';
                let curFont = this.settings.ScaleFont;
                let activeList = allItems[0]; // List<TerceraChartTimeScaleRendererItem>

                //
                // Видимость переходов может быть выключена, рисуем предыдущий переход в таком случае
                //

                let curDataDateChangeType = curData.DateChangeType; // TerceraChartCashItemSeriesCacheScreenDataDateChangeType
                curDataDateChangeType = this.CheckDateChangeType(curDataDateChangeType);

                switch (curDataDateChangeType) {
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None:

                    if (currentPeriod < Periods.TIC || (currentPeriod > 0 && (currentPeriod % Periods.SECOND == 0))) {
                        text = DateTimeUtils.formatDate(dt, '0:HH:mm:ss');
                    } else if (currentPeriod == Periods.TIC || currentPeriod == Periods.RANGE) {
                        text = DateTimeUtils.formatDate(dt, 'HH:mm:ss:SSS');
                    }
                    // #41873 - Все что дневка и выше - подписи даты
                    else if (currentPeriod >= Periods.DAY) {
                        text = DateTimeUtils.formatDate(dtInSessionTimeZoneWithOffset, 'DD.MM.YYYY');
                    } else {
                        text = DateTimeUtils.formatDate(dt, 'HH:mm');
                    }
                    activeList = allItems[0];
                    break;
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day:
                    text = DateTimeUtils.formatDate(dtInSessionTimeZoneWithOffset, 'DD.MM.YYYY');
                    curFont = this.settings.TimeScaleImportantDatesFont;
                    activeList = allItems[1];
                    break;
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Week:
                    text = DateTimeUtils.formatDate(dtInSessionTimeZoneWithOffset, 'DD.MM.YYYY');
                    curFont = this.settings.TimeScaleImportantDatesFont;
                    activeList = allItems[2];
                    break;
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Month:
                    text = DateTimeUtils.formatDate(dtInSessionTimeZoneWithOffset, 'MMMM');
                    curFont = this.settings.TimeScaleImportantDatesFont;
                    activeList = allItems[3];
                    break;
                case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Year:
                    text = DateTimeUtils.formatDate(dtInSessionTimeZoneWithOffset, 'YYYY');
                    curFont = this.settings.TimeScaleImportantDatesFont;
                    activeList = allItems[4];
                    break;
                }

                //
                const textWidth = Math.round(gr.GetTextWidth(text, curFont)) + TEXT_PADDING_ADDITIONAL;
                const rightBorder = (curDataDateChangeType == TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None)
                    ? currentDrawingX + textWidth / 2
                    : currentDrawingX + textWidth + TEXT_PLATE_SIZE;
                activeList.push(new TerceraChartTimeScaleRendererItem(curDataDateChangeType, currentDrawingX - textWidth / 2, rightBorder, text, currentDrawingX, textWidth));
            }
            curX -= scX;
        }

        //
        // Определение уровня шкалы, для которого будем использовать жирный шрифт
        //
        let boldLevel = 100;
        let filledLevelsCount = 0;
        for (let k = allItems.length - 1; k >= 0; k--) {
            if (allItems[k].Count > 0) {
                if (boldLevel == 100) { boldLevel = k; }
                filledLevelsCount++;
            }
        }
        if (filledLevelsCount < 2) { boldLevel = 100; }

        //
        // Рисование, на основе расчитанных данных
        //

        const windows = windowsContainer.Windows;
        const windows_len = windows.length;

        const dashPolyLines = new PolyLine();
        const dashPolyLinesLines = dashPolyLines.lines;

        const gridPolyLines = new PolyLine();
        const gridPolyLinesLines = gridPolyLines.lines;

        const gridPolyTextScaleFont = new PolyText();
        const gridPolyTextImportantDatesFont = new PolyText();
        let separatorPlaced = false;
        for (let k = allItems.length - 1; k >= 0; k--) {
            const Items = allItems[k];
            const Items_len = Items.length;

            for (let i = 0; i < Items_len; i++) {
                const Item = Items[i];
                const ItemX = Item.X;
                if (TerceraChartTimeScaleRenderer.IsCanBePlaced(Item, PixelMap)) {
                    /// / Текст
                    let itemText;
                    if (Item.DataChangeType == TerceraChartCashItemSeriesCacheScreenDataDateChangeType.None) { itemText = new PolyTextItem(Item.Text, ItemX, RY + 2 + RH / 2); } else { itemText = new PolyTextItem(Item.Text, ItemX + Item.Width / 2 + 9, RY + 2 + RH / 2); }

                    if (k === boldLevel) { gridPolyTextImportantDatesFont.textArray.push(itemText); } else { gridPolyTextScaleFont.textArray.push(itemText); }

                    //
                    // Draw separators
                    //
                    let separatorPen = null;
                    let platePen = null;
                    let separatorColor = '';
                    let separatorText: string | null = null;
                    switch (Item.DataChangeType) {
                    case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day:
                        if ((currentPeriod <= Periods.HOUR4 || currentPeriod % Periods.RANGE == 0) && this.settings.DaySeparatorsVisibility) {
                            separatorPen = this.settings.daySeparatorsPen;
                            platePen = this.settings.dayBorderSeparatorsPen;
                            separatorColor = ThemeManager.CurrentTheme.Chart_DaySeparateColor;
                            separatorText = 'D';
                            separatorPlaced = true;
                        }
                        break;
                    case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Week:
                        if (this.settings.WeekSeparatorsVisibility) {
                            separatorPen = this.settings.weekSeparatorsPen;
                            platePen = this.settings.weekBorderSeparatorsPen;
                            separatorColor = ThemeManager.CurrentTheme.Chart_WeekSeparateColor;
                            separatorText = 'W';
                            separatorPlaced = true;
                        }
                        break;
                    case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Month:
                        if (this.settings.MonthSeparatorsVisibility) {
                            separatorPen = this.settings.monthSeparatorsPen;
                            platePen = this.settings.monthBorderSeparatorsPen;
                            separatorColor = ThemeManager.CurrentTheme.Chart_MonthSeparateColor;
                            separatorText = 'M';
                            separatorPlaced = true;
                        }
                        break;
                    case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Year:
                        if (this.settings.YearSeparatorsVisibility) {
                            separatorPen = this.settings.yearSeparatorsPen;
                            platePen = this.settings.yearBorderSeparatorsPen;
                            separatorColor = ThemeManager.CurrentTheme.Chart_YearSeparateColor;
                            separatorText = 'Y';
                            separatorPlaced = true;
                        }
                        break;
                    }

                    if (separatorPen != null) {
                        const separatorPolyLines = new PolyLine();
                        const separatorPolyLinesLines = separatorPolyLines.lines;

                        for (let w_i = 0; w_i < windows_len; w_i++) {
                            const windowClientRect = windows[w_i].ClientRectangle;
                            const windowClientRectY = windowClientRect.Y;

                            separatorPolyLinesLines.push(new Line(
                                ItemX,
                                windowClientRectY,
                                ItemX,
                                windowClientRectY + windowClientRect.Height));
                        }
                        gr.DrawPolyLine(separatorPen, separatorPolyLines);

                        const rect = new Rectangle(ItemX - TEXT_PLATE_SIZE / 2, RY + 1, TEXT_PLATE_SIZE, RH - 2);
                        gr.RenderButton(new SolidBrush(separatorColor), platePen, rect);
                        gr.DrawString(separatorText, this.settings.ScaleFont, this.settings.textSeparatorBrush, rect.X + rect.Width / 2, rect.Y + rect.Height / 2, 'center', 'middle');
                    }
                }

                // Черточка на шкале
                dashPolyLinesLines.push(new Line(ItemX, RY, ItemX, RY + 3));
                // Сетка
                if (this.settings.ScaleGridVisibility && !separatorPlaced) {
                    for (let j = 0; j < windows_len; j++) {
                        const windowClientRect = windows[j].ClientRectangle;
                        const windowClientRectY = windowClientRect.Y;
                        gridPolyLinesLines.push(new Line(ItemX, windowClientRectY, ItemX, windowClientRectY + windowClientRect.Height));
                    }
                } else { separatorPlaced = false; }
            }
        }

        gr.DrawPolyLine(this.settings.scaleAxisPen, dashPolyLines);
        gr.DrawPolyLine(this.settings.scaleGridPen, gridPolyLines);
        gr.DrawPolyText(gridPolyTextImportantDatesFont, this.settings.TimeScaleImportantDatesFont, this.settings.scaleTextBrush, 'center', 'middle');
        gr.DrawPolyText(gridPolyTextScaleFont, this.settings.ScaleFont, this.settings.scaleTextBrush, 'center', 'middle');
    };

    CheckDateChangeType (changeType: any): any {
        let res = changeType;

        switch (changeType) {
        case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Week:
            if (!this.settings.WeekSeparatorsVisibility) {
                res = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day;
            }
            break;
        case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Month:
            if (!this.settings.MonthSeparatorsVisibility) {
                res = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day;
            }
            break;
        case TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Year:
            if (!this.settings.YearSeparatorsVisibility) {
                res = TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Month;
                res = this.CheckDateChangeType(res);
            }
            break;
        default:
            break;
        }

        return res;
    };

    public override GetPreferredHeight (): number {
        let maxH = this.settings.ScaleFont.Height;
        const curH = this.settings.TimeScaleImportantDatesFont.Height;
        if (maxH < curH) {
            maxH = curH;
        }
        const padding = 3;
        return maxH + padding * 2;
    }

    static IsCanBePlaced (item, PixelMap): boolean {
        if (item.LeftBorder > PixelMap.length - 1) { return false; }

        // Check
        if (item.LeftBorder < 0) { item.LeftBorder = 0; }
        if (item.RightBorder < 0) { item.RightBorder = 0; }
        if (item.RightBorder > PixelMap.length - 1) { item.RightBorder = PixelMap.length - 1; }

        // Check free place - light
        if (PixelMap[item.LeftBorder] || PixelMap[item.RightBorder]) { return false; }

        // Check free place - full
        let i = item.LeftBorder + 1;
        let len = item.RightBorder - 1;
        for (i; i < len; i++) {
            if (PixelMap[i]) { return false; }
        }

        // Store place
        i = item.LeftBorder;
        len = item.RightBorder;
        for (i; i < len; i++) { PixelMap[i] = true; }

        return true;
    };

    // #region Process mouse events

    ProcessMouseDown (e: any): boolean {
        if (!this.Visible || e.Button !== MouseButtons.Left) { return false; }

        this.mouseState = TerceraChartPriceScaleRendererMouseState.None;
        if (this.Rectangle.Contains(e.Location.X, e.Location.Y)) {
            this.mouseState = TerceraChartPriceScaleRendererMouseState.Moving;
            this.lastMouseDownPt = e.Location;
            return true;
        }

        return false;
    }

    /// <summary>
    ///
    /// </summary>
    ProcessMouseMove (e): boolean {
        if (!this.Visible) { return false; }

        if (this.mouseState == TerceraChartPriceScaleRendererMouseState.Moving) {
            const newX = this.lastMouseDownPt.X;

            const xDeltaPx = e.Location.X - newX;

            if (Math.abs(xDeltaPx) > 15) {
                if (xDeltaPx > 0) { this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, ChartZoomRendererButtons.ZoomIn)); } else { this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, ChartZoomRendererButtons.ZoomOut)); }

                this.lastMouseDownPt = e.Location;
            }
            e.NeedRedraw = true;
            return true;
        }

        return TerceraChartBaseScaleRenderer.prototype.ProcessMouseMove.call(this, e);
    }

    ProcessMouseUp (e: any): boolean {
        this.mouseState = TerceraChartPriceScaleRendererMouseState.None;
        TerceraChartBaseScaleRenderer.prototype.ProcessMouseUp.call(this, e);
        return false;
    }
    // #endregion

    GetCursor (e: any): any {
        if (this.mouseState == TerceraChartPriceScaleRendererMouseState.Moving && this.Rectangle.Contains(e.Location.X, e.Location.Y)) { return Cursors.SizeWE; }
        return null;
    }

    /// <summary>
    /// Context menu for selected tools
    /// </summary>
    GetContextMenu (e: any, chart: any): any {
        const location = e.Location;
        if (!this.Visible || !this.Rectangle.Contains(location.X, location.Y)) { return TerceraChartBaseScaleRenderer.prototype.GetContextMenu.call(this, e, chart); }

        const menuItems = [
            {
                tag: TerceraChartAction.Create(TerceraChartActionEnum.TimeSeparators),
                subitems: [
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.TimeSeparators, TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Day) },
                    // { tag: TerceraChartAction.Create(TerceraChartActionEnum.TimeSeparators, TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Week) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.TimeSeparators, TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Month) },
                    { tag: TerceraChartAction.Create(TerceraChartActionEnum.TimeSeparators, TerceraChartCashItemSeriesCacheScreenDataDateChangeType.Year) }
                ]
            },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.ShowHoles) },
            { tag: TerceraChartAction.Create(TerceraChartActionEnum.ShowExtendedSession) }
        ];

        // Create items using common functions
        return chart.TerceraChartActionProcessor.CreateMenu(menuItems);
    };
};
/// <summary>
/// Итем для хранения данных по шкале
/// </summary>
class TerceraChartTimeScaleRendererItem {
    DataChangeType: any;
    LeftBorder: number;
    RightBorder: number;
    Text: string;
    X: number;
    Width: number;

    constructor (DataChangeType, LeftBorder: number, RightBorder: number, Text: string, x: number, width: number) {
        this.DataChangeType = DataChangeType;
        this.LeftBorder = Math.floor(LeftBorder);
        this.RightBorder = Math.floor(RightBorder);
        this.Text = Text;
        this.X = x;
        this.Width = width;
    }
}
