// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectAlgorithmTopVolumeMessage } from '../../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { type PFixFieldSet } from '../../Fields/PFixFieldSet';
import { MessageProcessorBase } from '../MessageProcessorBase';

export class AlgorithmTopVolumeMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlgorithmTopVolumeMessage[] {
        const msg = new DirectAlgorithmTopVolumeMessage();
        const ff = FieldsFactory;

        msg.AlgorithmId = fs.GetValue(ff.FIELD_ALGORITHM_ID);
        msg.AlgorithmName = fs.GetValue(ff.FIELD_ALGORITHM_NAME);
        msg.AlgorithmTop = fs.GetValue(ff.FIELD_ALGORITHM_TOP); // 700 - (int)

        msg.AlgorithmTopVolumeDataGroups = fs.GetGroups(ff.ALGORITHM_TOP_VOLUME_DATA_GROUP); // 104 - группа инструментов для юзеров с RT датой.
        msg.AlgorithmTopVolumeDelayDataGroups = fs.GetGroups(ff.ALGORITHM_TOP_VOLUME_DELAY_DATA_GROUP); // 105 - группа инструментов для юзеров с Delay data.
        msg.AlgorithmTopVolumeInstrumentGroups = fs.GetGroups(ff.ALGORITHM_TOP_VOLUME_INSTRUMENT_GROUP); // 106

        return [msg];
    }
}
