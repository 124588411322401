// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectTradingSignalMessage } from '../../../../Utils/DirectMessages/DirectTradingSignalMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingSignalMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectTradingSignalMessage[] {
        const message = new DirectTradingSignalMessage();

        const len = fieldSet.Count();

        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_OPEN_PRICE) {
                message.OpenPrice = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_IS_TRADING_SIGNAL_NOT_SEEN) {
                message.NotSeen = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_ORDER_TYPE) {
                message.OrderType = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_OPERATION_TYPE) {
                message.OperationType = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
                message.TradableInstrumentId = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_ROUTE_ID) {
                message.RouteId = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SIGNAL_TITLE) {
                message.Title = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_COMMENT) {
                message.Description = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SIGNAL_ID) {
                message.TradingSignalId = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SYSTEM_ID) {
                message.TradingSystemId = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_SL_PRICE) {
                message.SLPrice = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_SL_LIMIT_PRICE) {
                message.SLLPrice = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TP_PRICE) {
                message.TPPrice = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_SL_PRICE_TYPE) {
                message.SLPriceType = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TP_PRICE_TYPE) {
                message.TPPriceType = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SIGNAL_TIME) {
                message.CreateTime = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SIGNAL_EXP_TIME) {
                message.ExpireTime = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_VALIDITY) {
                message.Tif = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_EXPIRE_AT) {
                message.TifExpireAt = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_PRODUCT_TYPE) {
                message.ProductType = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SIGNAL_SCREEN_ADDRESS) {
                message.AttachmentScreenPath = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SIGNAL_ATTACHMENT_ADDRESS) {
                message.AttachmentFilePath = fixField.Value;
            }
        }

        return [message];
    }
}
