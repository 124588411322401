// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class TimeInterval {
    public startHours: number;
    public startMins: number;
    public endHours: number;
    public endMins: number;

    constructor (startHours?: number, startMins?: number, endHours?: number, endMins?: number) {
        this.startHours = startHours ?? 0;
        this.startMins = startMins ?? 0;
        this.endHours = endHours === 0 ? 0 : (endHours ?? 23);
        this.endMins = endMins === 0 ? 0 : (endMins ?? 59);
    }

    public tryUpdate (newTimeInterval: TimeInterval): boolean {
        let isUpdated = false;

        if (this.startHours !== newTimeInterval.startHours) {
            this.startHours = newTimeInterval.startHours;
            isUpdated = true;
        }

        if (this.startMins !== newTimeInterval.startMins) {
            this.startMins = newTimeInterval.startMins;
            isUpdated = true;
        }

        if (this.endHours !== newTimeInterval.endHours) {
            this.endHours = newTimeInterval.endHours;
            isUpdated = true;
        }

        if (this.endMins !== newTimeInterval.endMins) {
            this.endMins = newTimeInterval.endMins;
            isUpdated = true;
        }

        return isUpdated;
    }

    public getStartInMins (): number {
        return this.getMinutes(this.startHours, this.startMins);
    }

    public getEndInMins (): number {
        return this.getMinutes(this.endHours, this.endMins);
    }

    private getMinutes (hours: number, mins: number): number {
        return hours * 60 + mins;
    }
}
