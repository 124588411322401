// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { AccountFeature } from '@shared/utils/Account/AccountFeature';
import { BaseCustomTableFormatter } from './BaseCustomTableFormatter';
import { CopyTradingFormatter } from './CopyTradingFormatter';
import { type CustomTableElementFormatter } from '../../charting_library';

export enum BaseCustomTableFormatterName {
    Balance = 'balanceFormatter',
    ProjectedBalance = 'projectedBalanceFormatter',
    AvailableFunds = 'availableFundsFormatter',
    BlockedBalance = 'blockedBalanceFormatter',
    UnsettledCash = 'unsettledCashFormatter',
    MarginUsed = 'initialMarginReqFormatter',
    MarginUsedPercent = 'initialMarginReqPercentFormatter',
    TotalMaintReq = 'maintMarginReqFormatter',
    RiskLevelPercent = 'maintMarginReqPercentFormatter',
    MarginWarningLevel = 'marginWarningLevelFormatter',
    MarginAvailable = 'marginAvailableFormatter',
    WarningMargin = 'warningMarginFormatter',
    BlockedForStocks = 'blockedForStocksFormatter',
    StocksOrdersReq = 'stocksOrdersReqFormatter',
    OpenGrossPL = 'openGrossPLFormatter',
    OpenNetPL = 'openNetPLFormatter',
    OpenPostionsNumber = 'openPositionsCountFormatter',
    OpenOrdersNumber = 'openOrdersCountFormatter',
    TodayGrossPNL = 'todaysGrossFormatter',
    TodaysNetProfit = 'todaysNetFormatter',
    TodaysFee = 'todaysFeesFormatter',
    TodayVolume = 'todaysVolumeFormatter',
    TodayTrades = 'todaysTradesFormatter',
    CopyTrading = 'copyTradingFormatter'
}

// Map associating formatter names with their special constructors (only for specific formatters)
const CustomFormatterMap: Partial<Record<string, typeof BaseCustomTableFormatter>> = {
    [BaseCustomTableFormatterName.CopyTrading]: CopyTradingFormatter
};

export function GetCustomFormatters (): CustomTableElementFormatter[] {
    const formatters: CustomTableElementFormatter[] = [];
    for (const formatterName in BaseCustomTableFormatterName) {
        if (AccountFeature[formatterName] != null) {
            const name = BaseCustomTableFormatterName[formatterName];
            const FormatterConstructor = CustomFormatterMap[name] || BaseCustomTableFormatter; // Use a specific formatter from FormatterMap or fall back to BaseCustomTableFormatter
            const formatter = new FormatterConstructor(name, AccountFeature[formatterName]); // Create an instance of the appropriate formatter
            formatters.push(formatter);
        }
    }

    return formatters;
}

export const FAILED_TO_FORMAT = 'Too many values to format or inputValue == null';
