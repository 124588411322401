// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectPortfolioInvestorMessage extends DirectMessageBaseClass {
    public PortfolioSystemID: number | null = null; // PFixIntField FIELD_PORTFOLIO_SYSTEM_ID = new PFixIntField(727); - идентификатор портфолио системы
    public ModelID: number | null = null; // PFixIntField FIELD_PORTFOLIO_MODEL_ID = new PFixIntField(728); - идентификатор модели назначенной системе
    public AccountID: number | null = null; // PFixIntField FIELD_ACCOUNT_ID = new PFixIntField(89); - аккаунт ID инвестора
    public StatusOut: number | null = null; // PFixIntField FIELD_STATUS = new PFixIntField(708); - 0 инвестор в системе, если 1 - значит произошла отписка аккаунтом инвестора от портфолио системы

    constructor () {
        super(Message.CODE_PFIX_PORTFOLIO_INVESTOR_MESSAGE);
    }

    public IsSubscribed (): boolean {
        return this.StatusOut === 0;
    }
}
