// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '../../../../../Localizations/Resources';
import { Instrument } from '../../../Instrument';
import { BaseOrderWarning } from './BaseOrderWarning';

export class SMFlagsWarning extends BaseOrderWarning {
    public getText (): string {
        const result = this.getSMFlagsWarning();

        if (!result) {
            return '';
        }

        const textToInsert = ' - ' + result;
        const text = Resources.getResource('FlagSM.Message', [textToInsert]);
        return text;
    }

    private getSMFlagsWarning (): string {
        const ins = this.orderEdit.instrument;
        const flags = ins ? ins.GetExtSMFlags() : {};

        let result = '';
        for (const flag in flags) {
            const flagValue = ins?.GetExtSMFlagByKey(flag);

            if (Instrument.ShownSMFlagValue(flags[flag], flag)) {
                result += (result ? ', ' : '') + flagValue;
            }
        }

        return result;
    }
}
