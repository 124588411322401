// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { TerceraQuickTree, TerceraQuickTreeEvents } from '../elements/QuickTree/TerceraQuickTree';
import { NewsPanelTemplate } from '../../templates.js';
import { NewsItem } from '../cache/NewsItem';
import { TerceraMenu } from '../elements/TerceraMenu';
import { ExportScreen } from '../screen/ExportScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { type RangeSelectPanel } from './RangeSelectPanel';
import { UrlUtils } from '@shared/utils/UrlUtils';
import { DynProperty } from '@shared/commons/DynProperty';
import { DataCache } from '@shared/commons/DataCache';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type News } from '@shared/commons/cache/News';
import { type TerceraComboBox } from '../elements/TerceraComboBox';
import { type TerceraLabel } from '../elements/TerceraLabel';
import { type QuickTableRow } from '../elements/QuickTable/QuickTableRow';

export class NewsPanel extends ApplicationPanelWithTable<NewsItem> {
    public static readonly CLEAR_TABLE_CONTEXT_MENU_TAG = 'REMOVE_ALL_MENU_TAG';
    public static readonly REMOVE_ONE_MENU_TAG = 'REMOVE_ONE_MENU_TAG';
    public static readonly ALL_FEEDS_CB_ITEM_VALUE = -1;

    public unviewedNewsCount: number = 0; // количество непросмотренных новостей для хедера панели
    public toolbarVisible: boolean = true; // видимость лейбла и комбобокса выбора Feed (новостного роута)
    public selectedFeedValue: number = -1; // routeID выбранный в feedsComboBox
    public deletedNewsByRoute: any = null; // сохраняем id новостей удаленных пользователем
    public callBackWas: boolean = false;
    public initialLoadingStarted: boolean = false; // произошла ли начальная загрузка новостей (запустился ли LoadAllNews())
    public feedsComboBoxTooltipLocalKey: string = 'panel.news.feedComboBox.tt';
    public feedLabelLocalKey: string = 'panel.news.feed';
    public headerLocaleKey: string = 'panel.news';
    public rangeSelectPanel: RangeSelectPanel = null;

    public override getType (): PanelNames { return PanelNames.NewsPanel; }

    public override oncomplete (): void {
        super.oncomplete();

        this.addRangeSelectPanel();
        this.populateTableContextMenu();

        this.createToolbar();
        this.subscribeToEvents();

        this.LoadAllNews();

        this.localize();
    }

    public override jbInit (): void {
        super.jbInit();

        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        qt.AddToEnd = false;

        if (!this.callBackWas) // если панель совсем новая, а не пришла из MongoDB
        {
            qt.sortIndex = 0; // по умолчанию сортируем по нулевой колонке (Received - дата получения)
            qt.asc = false; // порядок сортировки по убыванию
        }
    }

    public addRangeSelectPanel (): void {
        const topPanel = this.Controls.newsPanelRangeSelect;
        topPanel.set({ name: 'topPanel' });
        topPanel.getReportEvent.Subscribe(this.getNewsReport, this);
        this.rangeSelectPanel = topPanel;
        topPanel.updateSelectedRange();
    }

    public subscribeToEvents (): void {
        DataCache.OnAddNewsEvent.Subscribe(this.AddNewsEvent, this);
        DataCache.OnRemoveNewsEvent.Subscribe(this.RemoveNewsEvent, this);

        const caller = (): void => {
            this.AfterLoad();
            this.layoutTable();
        // clearTimeout(this.asyncI);
        // this.asyncI = null;
        // this.asyncI = setTimeout(() => { this.LoadAllNews() }, 700);
        };
        DataCache.SubscribeToNewsIfNeed(caller);

        if (!isNullOrUndefined(this.quickTableRactive)) {
            const qt = this.getQuickTable();
            if (!isNullOrUndefined(qt)) {
                qt.AfterEditItem.Subscribe(this.onLinkInContentClick, this);
            }

            this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.onDoubleClicked.bind(this));
        }
    }

    public unsubscribeToEvents (fromClosePanel: boolean): void {
        DataCache.OnAddNewsEvent.UnSubscribe(this.AddNewsEvent, this);
        DataCache.OnRemoveNewsEvent.UnSubscribe(this.RemoveNewsEvent, this);

        const qt = this.getQuickTable();
        if (!isNullOrUndefined(qt)) {
            qt.AfterEditItem.UnSubscribe(this.onLinkInContentClick, this);
        }

        DataCache.UnsubscribeToNewsIfNeed(fromClosePanel);
    }

    public LoadAllNews (): void {
        const news = DataCache.News;
        if (isNullOrUndefined(news)) return;

        this.initialLoadingStarted = true; // не добавляем айтемы раньше вызова LoadAllNews, чтоб была возможность использовать этот метод как для панели, что совершает подписку так и для последующих без лишних созданий айтемов #99505

        // for (let routeID in news)
        // {
        const newsToAdd = this.prepareNews(NewsPanel.ALL_FEEDS_CB_ITEM_VALUE);
        for (let i = 0; i < newsToAdd.length; i++) {
            this.AddNewsEvent(newsToAdd[i], true);
        }
        // }

        const qt = this.getQuickTable();
        if (!isNullOrUndefined(qt)) {
            qt.sortRowsArray();
        } // #99511
    }

    public AfterLoad (): void {
        const qtRactive = this.quickTableRactive;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (isNullOrUndefined(qt)) return;

        qt.ClearAll();
        this.unviewedNewsCount = 0;
        this.LoadAllNews();
    }

    public getNewsReport (startTime: number, finishTime: number): void {
        const news = DataCache.getNewsRoutesIDs();
        if (Object.keys(news).length === 0) {
            return;
        }

        void this.set('loading', true);
        DataCache.clearNewsCache();
        for (const routeID of news) {
            DataCache.SendNewsRequestMessage(routeID, new Date(startTime), new Date(finishTime))
                .then(() => { this.AfterLoad(); })
                .finally(() => {
                    void this.set('loading', false);
                });
        }
    }

    public AddNewsEvent (news: News, noNeedToSort: boolean): void {
        if (isNullOrUndefined(news) || !this.initialLoadingStarted) {
            return;
        }

        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        const newsID = news.GetID();
        const routeID = news.GetRouteID();
        const rowIsVisible = this.selectedFeedValue === NewsPanel.ALL_FEEDS_CB_ITEM_VALUE || this.selectedFeedValue.toString() === routeID.toString();
        const newsWasDeleted = this.checkIfNewsWasDeleted(routeID, newsID);
        const newsWasViewed = DataCache.IsNewsViewed(newsID);

        if (rowIsVisible && !newsWasDeleted) {
            if (!noNeedToSort) {
                const isDaily = this.rangeSelectPanel.isDaily();
                const arr = DataCache.News[routeID];
                let news = null;
                if (isDaily && arr.length > 100) {
                    news = arr[arr.length - 101];
                }
                if (!isNullOrUndefined(news)) {
                    this.RemoveNewsEvent(news.newsID);
                }
            }

            qt.AddItem(new NewsItem(news, newsWasViewed));

            if (!noNeedToSort) {
                qt.sortRowsArray();
            } // #99511

            if (!newsWasViewed) {
                this.unviewedNewsCount++;
            }
        }

        if (this.get<boolean>('noData') && qt.rowsArray.length > 0) // я хз как нужно, но так ломается панель
        {
            void this.set('noData', false);
        }
    }

    public RemoveNewsEvent (newsID): void // это удаление происходит, когда новая новость вытесняет старую по их максимальному кол-ву на роут
    {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        const rowsArray = qt.rowsArray;
        if (isValidArray(rowsArray)) {
            for (let i = 0; i < rowsArray.length; i++) {
                const row = rowsArray[i];
                const item = !isNullOrUndefined(row) ? row.item : null;

                if (!isNullOrUndefined(item) && item.getNewsID() === newsID) {
                    qt.RemoveItem(item.ItemId);

                    if (!item.Disabled) {
                        this.unviewedNewsCount--;
                    }

                    return;
                }
            }
        }
    }

    public override close (): void {
        const fromClosePanel = true;
        this.dispose(fromClosePanel);
    }

    public override dispose (fromClosePanel?): void {
        fromClosePanel = fromClosePanel ?? false;

        this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getNewsReport, this);
        this.unsubscribeToEvents(fromClosePanel);

        super.dispose();
    }

    public override updatePanelHeader (): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        void this.set({ header: Resources.getResource(this.headerLocaleKey) + '  (' + this.unviewedNewsCount + ')' });
    }

    public onLinkInContentClick (info): void {
        let url = null;
        const item: NewsItem | null = !isNullOrUndefined(info?.row) ? info.row.item : null;
        if (!isNullOrUndefined(item)) {
            url = item.getLinkInContent();
        }

        UrlUtils.openFixedURL(url);
    }

    public onSelectedFeedChange (el, cbItem): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt) || isNullOrUndefined(cbItem)) {
            return;
        }

        qt.ClearAll();

        const value = cbItem.value ? cbItem.value : cbItem;
        let newsToAdd = [];

        this.selectedFeedValue = value;
        this.unviewedNewsCount = 0;
        void this.set('noData', true);

        newsToAdd = this.prepareNews(value);

        if (isValidArray(newsToAdd)) {
            for (let i = 0; i < newsToAdd.length; i++) {
                this.AddNewsEvent(newsToAdd[i], true);
            }

            qt.sortRowsArray();
        }
    }

    public prepareNews (value: number): any[] {
        let newsToAdd = [];
        const allNews = DataCache.News;
        let isDaily = true;
        if (!isNullOrUndefined(this.rangeSelectPanel)) {
            isDaily = this.rangeSelectPanel.isDaily();
        }
        if (value === NewsPanel.ALL_FEEDS_CB_ITEM_VALUE) {
            for (const routeID in allNews) {
                newsToAdd = newsToAdd.concat(this.limitateNewsNumber(isDaily, allNews[routeID]));
            }
        } else {
            newsToAdd = this.limitateNewsNumber(isDaily, allNews[value]);
        }

        return newsToAdd;
    }

    public limitateNewsNumber (isDaily: boolean, arr = []): any[] {
        if (isDaily && arr.length > 100) {
            return arr.slice(arr.length - 100);
        }

        return arr;
    }

    public createToolbar (): void {
        this.fillFeedsComboBox();
        this.on('onSelectedFeedChange', this.onSelectedFeedChange);

        this.ShowToolbarStateUpdate(this.toolbarVisible);
        this.layoutTable();
    }

    public fillFeedsComboBox (): void {
        const dc = DataCache;
        const newsRoutesIDs = dc.getNewsRoutesIDs();
        if (newsRoutesIDs.length === 0) {
            return;
        }

        const itemAllFeeds = { text: Resources.getResource('panel.news.all'), value: NewsPanel.ALL_FEEDS_CB_ITEM_VALUE };
        let selectedCBItem = this.selectedFeedValue === itemAllFeeds.value ? itemAllFeeds : null;
        let items = [];
        for (let i = 0; i < newsRoutesIDs.length; i++) {
            const routeID = newsRoutesIDs[i];
            const route = dc.getRouteById(routeID);

            if (!isNullOrUndefined(route)) {
                const item = { text: route.Name, value: routeID };
                if (this.selectedFeedValue === item.value) {
                    selectedCBItem = item;
                }

                items.push(item);
            }
        }

        const feedsComboBox: TerceraComboBox = !isNullOrUndefined(this.Controls) ? this.Controls.feedsComboBox : null;
        if (isNullOrUndefined(feedsComboBox)) return;

        items.sort(function (a, b) { // #99501
            const s1 = a.text.toUpperCase();
            const s2 = b.text.toUpperCase();
            if (s1 === s2) return 0;
            return s1 > s2 ? 1 : -1;
        });

        items = [itemAllFeeds].concat(items);

        void feedsComboBox.set({ items });

        if (!isNullOrUndefined(selectedCBItem)) {
            void feedsComboBox.set('selectedItem', selectedCBItem);
        }
    }

    public override localize (): void {
        super.localize();

        void this.set('noDataText', Resources.getResource('panel.news.noData'));

        const feedsLabel: TerceraLabel = !isNullOrUndefined(this.Controls) ? this.Controls.feedsLabel : null;
        if (!isNullOrUndefined(feedsLabel)) {
            void feedsLabel.set('text', Resources.getResource(this.feedLabelLocalKey));
        }

        const feedsComboBox: TerceraComboBox = !isNullOrUndefined(this.Controls) ? this.Controls.feedsComboBox : null;
        if (!isNullOrUndefined(feedsComboBox)) {
            void feedsComboBox.set('tooltip', Resources.getResource(this.feedsComboBoxTooltipLocalKey));
        }

        if (isNullOrUndefined(this.quickTableRactive)) {
            return;
        }
        const contextItems = this.getQuickTable().tableContextMenuItems;

        if (!isValidArray(contextItems)) {
            return;
        }

        for (let i = 0; i < contextItems.length; i++) {
            const item = contextItems[i];
            if (!isNullOrUndefined(item)) {
                if (isValidString(item.locKey)) {
                    item.text = Resources.getResource(item.locKey);
                }

                if (isValidArray(item.subitems)) {
                    for (let j = 0; j < item.subitems.length; j++) {
                        const subitem = item.subitems[j];
                        if (isValidString(subitem.locKey)) {
                            subitem.text = Resources.getResource(subitem.locKey);
                        }
                    }
                }
            }
        }
    }

    public onDoubleClicked (): void {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;
        const selectedOrderIdArr = qt.selectedRowIds;

        const qtRow = qt.getItemById(selectedOrderIdArr[0]);
        const item = !isNullOrUndefined(qtRow) ? qtRow.item : null;

        if (!isNullOrUndefined(item)) {
            if (!item.Disabled) // новость не была прежде прочитана -> следует обновить header
            {
                this.unviewedNewsCount--;
                this.updatePanelHeader();
            }

            item.Disabled = true;
            DataCache.setNewsAsViewed(item.getNewsID());

            item.OnNewsDoubleClicked();
        }
    }

    public onRemoveAll (): void // удаление пользователем через пункт контекстного меню Clear table
    {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        this.unviewedNewsCount = 0;
        const rows = qt.rowsArray;
        for (let i = 0; i < rows.length; i++) {
            this.onRowWasDeletedByUser(rows[i]);
        }

        qt.ClearAll();

        if (qt.rowsArray.length === 0)// я хз как нужно, но так ломается панель
        {
            void this.set('noData', true);
        }
    }

    public onRemoveSelected (): void // удаление пользователем через пункт контекстного меню Remove
    {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        const selectedRowIds = qt.selectedRowIds.slice();
        for (let i = 0; i < selectedRowIds.length; i++) {
            const rowID = selectedRowIds[i];
            const row = qt.getItemById(rowID);

            if (!isNullOrUndefined(row) && !isNullOrUndefined(row.item) && !row.item.Disabled) {
                this.unviewedNewsCount--;
            }

            this.onRowWasDeletedByUser(row);
            qt.RemoveRow(rowID);
        }

        if (qt.rowsArray.length === 0)// я хз как нужно, но так ломается панель
        {
            void this.set('noData', true);
        }
    }

    public onRowWasDeletedByUser (row: QuickTableRow<NewsItem>): void {
        if (isNullOrUndefined(row?.item)) {
            return;
        }

        const item = row.item;
        const newsID = item.getNewsID();
        const routeID = item.getRouteID();

        if (isNullOrUndefined(this.deletedNewsByRoute)) {
            this.deletedNewsByRoute = {};
        }

        if (isNullOrUndefined(this.deletedNewsByRoute[routeID])) {
            this.deletedNewsByRoute[routeID] = [];
        }

        this.deletedNewsByRoute[routeID].push(newsID);
    }

    public checkIfNewsWasDeleted (routeID: string, newsID: string): boolean // была ли новость удалена пользователем из таблицы
    {
        if (isNullOrUndefined(this.deletedNewsByRoute)) {
            return false;
        }

        const deletedNewsIDs: any[] = this.deletedNewsByRoute[routeID];

        return isValidArray(deletedNewsIDs) && deletedNewsIDs.includes(newsID);
    }

    public override preparePopup (): void {
        super.preparePopup();

        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return;

        this.menuTagDict[NewsPanel.CLEAR_TABLE_CONTEXT_MENU_TAG].enabled = qt.visibleRowCount() > 0;

        const selectedRowIds = qt.selectedRowIds;
        this.menuTagDict[NewsPanel.REMOVE_ONE_MENU_TAG].enabled = isValidArray(selectedRowIds);
    }

    public populateTableContextMenu (): void {
        const items = [];
        const moreThanOnFeed = DataCache.getNewsRoutesIDs().length > 1;

        if (!Resources.isHidden('screen.export.visibility')) {
            items.push(
                {
                    locKey: 'screen.export.contextMenu',
                    event: ExportScreen.show.bind(null, this)
                }
            );
        }

        items.push(
            {
                locKey: 'panel.positions.menu.View',
                tag: 'View',
                enabled: true,
                subitems: [
                    {
                        locKey: 'panel.news.menu.ShowToolbar',
                        checked: this.toolbarVisible,
                        visible: !Resources.isHidden('panel.news.menu.ShowToolbar'),
                        enabled: moreThanOnFeed,
                        canCheck: true,
                        event: this.ShowToolbarStateChange.bind(this)
                    }
                ]
            }
        );

        items.push({
            text: Resources.getResource('panel.news.menu.clearAll'),
            event: this.onRemoveAll.bind(this),
            enabled: true,
            tag: NewsPanel.CLEAR_TABLE_CONTEXT_MENU_TAG
        });

        items.push({
            text: Resources.getResource('panel.news.menu.removeOneRow'),
            event: this.onRemoveSelected.bind(this),
            enabled: false,
            tag: NewsPanel.REMOVE_ONE_MENU_TAG
        });

        this.menuTagDict = TerceraMenu.createTagDictionary(items);
        this.getQuickTable().setTableContextMenuItems(items);
    }

    public ShowToolbarStateChange (menuItem): void {
        this.toolbarVisible = menuItem.checked;

        this.ShowToolbarStateUpdate(this.toolbarVisible);
    }

    public ShowToolbarStateUpdate (state): void {
        const feedsLabel: TerceraLabel = this.Controls.feedsLabel;
        const feedsComboBox: TerceraComboBox = this.Controls.feedsComboBox;

        const routes = !isNullOrUndefined(feedsComboBox) ? feedsComboBox.get('items') : null;
        const routesNum = isValidArray(routes) ? routes.length : 0;

        if (routesNum < 3) // ALL + one route -> need to hide
        {
            state = false;
        }

        void feedsComboBox.set('visible', state);
        void feedsLabel.set('visible', state);

        const leftRangePanel = state ? feedsComboBox.get('left') + feedsComboBox.get('width') + 5 : 5;
        const rangePanel = this.rangeSelectPanel;
        void rangePanel.set({ left: leftRangePanel });

        this.layoutTable();
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();

        properties.push(new DynProperty('toolbarVisible', this.toolbarVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('selectedFeed', this.selectedFeedValue, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('deletedNewsByRoute', JSON.stringify(this.deletedNewsByRoute), DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public override callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);

        this.toolbarVisible = DynProperty.getPropValue(newProperties, 'toolbarVisible');
        this.selectedFeedValue = DynProperty.getPropValue(newProperties, 'selectedFeed');

        const deletedNewsByRouteProp = DynProperty.getPropValue(newProperties, 'deletedNewsByRoute');
        if (!isNullOrUndefined(deletedNewsByRouteProp)) {
            this.deletedNewsByRoute = JSON.parse(deletedNewsByRouteProp);
        }

        this.callBackWas = true;
    }
}

ApplicationPanelWithTable.extendWith(NewsPanel,
    {
        partials: { bodyPartial: NewsPanelTemplate },
        data: function () {
            return {
                noData: true,
                noDataText: '',
                canLinkByAccount: false,
                isAccountLinkShow: false,
                isSymbolLinkShow: false
            };
        }
    });
