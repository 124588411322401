// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Control } from '../../elements/Control';
import { HeatmapFilterTemplate } from '../../../templates';
import { type PartialInterval } from '../../../Commons/Heatmap/PartialIntervals/PartialInterval';

export class HeatmapFilter extends Control {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.observe('scaleMultiplier', this.onScaleMultiplierChanged);
        this.on('scaleMultiplierSelectedItemChanged', this.onScaleMultiplierSelectedItemChanged);
        this.on('intervalClicked', this.onIntervalClicked);
    }

    private onScaleMultiplierChanged (): void {
        const items = this.get('scaleMultiplierItems');
        const scaleMultiplier = this.get('scaleMultiplier');
        for (let i = 0; i < items.length; i++) {
            if (items[i].value === scaleMultiplier) {
                void this.set('scaleMultiplierSelectedItem', items[i]);
                break;
            }
        }
    }

    private onScaleMultiplierSelectedItemChanged (): void {
        const scaleMultiplierCombobox = this.findComponent('terceraComboBox');
        if (!isNullOrUndefined(scaleMultiplierCombobox)) {
            const selectedItem = scaleMultiplierCombobox.get('selectedItem');
            this.fire('scaleMultiplierChanged', selectedItem.value);
        }
    }

    private onIntervalClicked (_sender: any, interval: PartialInterval): void {
        interval.IsSelected = !interval.IsSelected;
        void this.update();
        this.fire('scaleFilterChanged');
    }
}

Control.extendWith(HeatmapFilter, {
    template: HeatmapFilterTemplate,
    data: function () {
        return {
            intervals: [],
            scaleMultiplierItems: [
                { text: 'x0.1', value: 0.1 },
                { text: 'x0.2', value: 0.2 },
                { text: 'x0.25', value: 0.25 },
                { text: 'x0.5', value: 0.5 },
                { text: 'x1', value: 1 },
                { text: 'x2', value: 2 },
                { text: 'x3', value: 3 },
                { text: 'x5', value: 5 },
                { text: 'x10', value: 10 }
            ],
            scaleMultiplierSelectedItem: null,
            scaleMultiplier: undefined,
            foregroundColor: ''
        };
    }
});
