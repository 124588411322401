// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { DayType } from './DayType';
import { DateTimeUtils } from '../Time/DateTimeUtils';
import { SubTypes } from './SubTypes';
import { type DateTime } from 'luxon';
import { type Session } from './Session';
import { type SessionPeriod } from './SessionPeriod';
import { type HolidayDescription } from './HolidayDescription';

export class _SessionUtils {
    public GetSessionPeriodTimeForInstrumentDetails (session: Session, sessionPeriodIndex: number, showSessionTime: boolean, showSessionName: boolean): string | null {
        const [sessionPeriods, dayOfWeek] = session.FindSessionPeriodsForDate(session.ServerTodayDate);
        if (sessionPeriods == null) { return null; }

        for (let i = 0; i < sessionPeriods.length; i++) {
            const ts: SessionPeriod = sessionPeriods[i];
            const holiday: HolidayDescription = session.GetTodayHoliday();

            if (showSessionTime && holiday != null && holiday.holidayType === DayType.NOT_WORKING) { return Resources.getResource('InstrumentDetailsPanel.non trading day'); }

            if (sessionPeriodIndex !== i) { continue; }

            let val = '';

            if (showSessionTime) {
                const startOfSession: DateTime = session.AddTimeToSessionDate(session.ServerTodayDate, ts.GetBegin(dayOfWeek));
                const endOfSession: DateTime = session.AddTimeToSessionDate(session.ServerTodayDate, ts.GetEnd(dayOfWeek));
                const startOfSessionUtc: Date = session.ConvertSessionDateTimeToDate(startOfSession);
                const endOfSessionUtc: Date = session.ConvertSessionDateTimeToDate(endOfSession);
                val = DateTimeUtils.FormatToTime(new Date(startOfSessionUtc), true) +
                    ' - ' +
                    DateTimeUtils.FormatToTime(new Date(endOfSessionUtc), true);
            }

            if (showSessionName) {
                val += (showSessionTime ? ' / ' : '') + (isValidString(ts.description) ? ts.description : Resources.getResource('InstrumentDetailsPanel.' + this.SessionSubTypeToString(ts.subType)));

                if (holiday != null && holiday.holidayType === DayType.SHORTED) { val += ' (' + Resources.getResource('InstrumentDetailsPanel.shortened') + ')'; }
            }

            return val;
        }
        return null;
    }

    private SessionSubTypeToString (sessionSubType: SubTypes): string {
        switch (sessionSubType) {
        case SubTypes.AUCTION_PRE_CROSS:
            return 'AUCTION_PRE_CROSS';
        case SubTypes.AUCTION_FREEZE:
            return 'AUCTION_FREEZE';
        case SubTypes.CONTINUOUS:
            return 'CONTINUOUS';
        case SubTypes.IN_BETWEEN_CLEARING:
            return 'IN_BETWEEN_CLEARING';
        case SubTypes.SETTLEMENT_CLEARING:
            return 'SETTLEMENT_CLEARING';
        default:
            throw new Error();
        }
    }
}

export const SessionUtils = new _SessionUtils();
