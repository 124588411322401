// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { EventEmitter } from 'events';
class SideBarButtonsHelperClass {
    connectedStatus: boolean = false;
    private readonly _eventEmitter: EventEmitter = new EventEmitter();

    public subscribeOnConnectedStatusChanged (callback: (connectedStatus: boolean) => void): void {
        this._eventEmitter.on('OnConnectedStatusChanged', callback);
    }

    public unsubscribeOnConnectedStatusChanged (callback: (connectedStatus: boolean) => void): void {
        this._eventEmitter.off('OnConnectedStatusChanged', callback);
    }

    public OnConnectedStatusChangedEmit (value: boolean): void {
        this.connectedStatus = value;
        this._eventEmitter.emit('OnConnectedStatusChanged', value);
    }
}

export const SideBarButtonsHelper = new SideBarButtonsHelperClass();
