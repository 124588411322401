// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// #region RiskPlanItem

import { type MarginModes } from '../Enums/Constants';
import { MarginTypes } from '../Instruments/MarginTypes';
import { ProductType } from '../Instruments/ProductType';
import { type MarginCoefficients } from './MarginCoefficients';

export class RiskPlanItem {
    public ProductType = ProductType.General;
    public CalculateMarginType: MarginTypes | null = null;
    public MarginMode: MarginModes | null = null;
    public UseSameCrossPriceForOpenClose: boolean | null = null;
    public HedgedMarginCallSize: number | null = null;
    public HedgedMarginCallCoeff: number | null = null;
    public AllowOvernightTrading: boolean | null = null;
    public AllowToWithdrawUnrealizedProfit: any = null;
    public UseOvernightMargin: boolean | null = null;
    public UseLongShortMargin: boolean | null = null;
    public MarginCoeficientList: MarginCoefficients[] = null;
    public MarginCoefficient: number | null = null;
    public UseOpenPriceInMargin: boolean | null = null;
    public UseUnsettledProfitAndOpenProfitInMarginAvailable: boolean | null = null;
    public UseUnsettledCashForStocksInMarginAvailable: boolean | null = null;
    public IsShortable: boolean | null = null;
    public MaxLot: number | null = null;
    public MinLot: number | null = null;
    public LotStep: number | null = null;
    public IsCashTradingAllowed: boolean = false;
    public MaxPositionQtyPerSymbol: number | null = null;
    public UseUnsettledNegativeStockOperationInWA: boolean | null = null;
    public UseUnsettledPositiveStockOperationInWA: boolean | null = null;
    public UseUnsettledProfitInWA: boolean | null = null;
    public IsPositionCloseOnly: boolean | null = null;
    public CurrencyId: number | null = null; // #116496
    /// <summary>
    /// #82717 Use Usettled collateral in margin available calculation
    /// </summary>
    public UseUnsettledCollateralInMA: boolean | null = null;
    /// <summary>
    /// #82717 Use Usettled collateral in Withdrawal available calculation
    /// </summary>
    public UseUnsettledCollateralInWA: boolean | null = null;

    /// #98161
    public UseSettledCashForStocksForOpenedPositionsInMA: boolean | null = null; // if checked, settled stock operations for opened positions will be added to Margin available
    public UseSettledCollateralForOpenedPositionInMA: boolean | null = null; //  if checked, settled collateral operations for opened positions will be added to Margin available

    public Leverages: number[] = []; // #111414->#112880->#112886

    public mergeWith (newItem): void {
        this.ProductType = newItem.ProductType;
        this.Leverages = newItem.Leverages;

        if (newItem.CalculateMarginType !== null) {
            this.CalculateMarginType = newItem.CalculateMarginType;
        }
        if (newItem.MarginMode !== null) {
            this.MarginMode = newItem.MarginMode;
        }
        if (newItem.UseSameCrossPriceForOpenClose !== null) {
            this.UseSameCrossPriceForOpenClose = newItem.UseSameCrossPriceForOpenClose;
        }
        if (newItem.HedgedMarginCallSize !== null) {
            this.HedgedMarginCallSize = newItem.HedgedMarginCallSize;
        }
        if (newItem.HedgedMarginCallCoeff !== null) {
            this.HedgedMarginCallCoeff = newItem.HedgedMarginCallCoeff;
        }
        if (newItem.UseOvernightMargin !== null) {
            this.UseOvernightMargin = newItem.UseOvernightMargin;
        }
        if (newItem.AllowOvernightTrading !== null) {
            this.AllowOvernightTrading = newItem.AllowOvernightTrading;
        }
        if (newItem.AllowToWithdrawUnrealizedProfit !== null) {
            this.AllowToWithdrawUnrealizedProfit = newItem.AllowToWithdrawUnrealizedProfit;
        }
        if (newItem.UseLongShortMargin !== null) {
            this.UseLongShortMargin = newItem.UseLongShortMargin;
        }
        if (newItem.MarginCoefficient !== null) {
            this.MarginCoefficient = newItem.MarginCoefficient;
        }
        if (newItem.UseOpenPriceInMargin !== null) {
            this.UseOpenPriceInMargin = newItem.UseOpenPriceInMargin;
        }
        if (newItem.IsShortable !== null) {
            this.IsShortable = newItem.IsShortable;
        }
        if (newItem.MaxLot !== null) {
            this.MaxLot = newItem.MaxLot;
        }
        if (newItem.MinLot !== null) {
            this.MinLot = newItem.MinLot;
        }
        if (newItem.MaxPositionQtyPerSymbol !== null) {
            this.MaxPositionQtyPerSymbol = newItem.MaxPositionQtyPerSymbol;
        }

        if (newItem.MarginCoeficientList !== null) {
            this.MarginCoeficientList = newItem.MarginCoeficientList;
        }

        if (newItem.UseUnsettledProfitAndOpenProfitInMarginAvailable !== null) {
            this.UseUnsettledProfitAndOpenProfitInMarginAvailable =
                newItem.UseUnsettledProfitAndOpenProfitInMarginAvailable;
        }

        if (newItem.UseUnsettledCashForStocksInMarginAvailable !== null) {
            this.UseUnsettledCashForStocksInMarginAvailable =
                newItem.UseUnsettledCashForStocksInMarginAvailable;
        }

        if (newItem.UseUnsettledNegativeStockOperationInWA !== null) {
            this.UseUnsettledNegativeStockOperationInWA = newItem.UseUnsettledNegativeStockOperationInWA;
        }

        if (newItem.UseUnsettledPositiveStockOperationInWA !== null) {
            this.UseUnsettledPositiveStockOperationInWA = newItem.UseUnsettledPositiveStockOperationInWA;
        }

        if (newItem.UseUnsettledProfitInWA !== null) {
            this.UseUnsettledProfitInWA = newItem.UseUnsettledProfitInWA;
        }

        if (newItem.UseUnsettledCollateralInMA !== null) {
            this.UseUnsettledCollateralInMA = newItem.UseUnsettledCollateralInMA;
        }

        if (newItem.UseUnsettledCollateralInWA !== null) {
            this.UseUnsettledCollateralInWA = newItem.UseUnsettledCollateralInWA;
        }

        if (newItem.UseSettledCashForStocksForOpenedPositionsInMA !== null) {
            this.UseSettledCashForStocksForOpenedPositionsInMA = newItem.UseSettledCashForStocksForOpenedPositionsInMA;
        }

        if (newItem.UseSettledCollateralForOpenedPositionInMA !== null) {
            this.UseSettledCollateralForOpenedPositionInMA = newItem.UseSettledCollateralForOpenedPositionInMA;
        }

        if (newItem.IsPositionCloseOnly !== null) {
            this.IsPositionCloseOnly = newItem.IsPositionCloseOnly;
        }

        // if (newItem.CurrencyId !== null)
        this.CurrencyId = newItem.CurrencyId;
    }

    public getCopy (): RiskPlanItem {
        const copy = new RiskPlanItem();
        copy.mergeWith(this);
        return copy;
    }

    // Margin
    public GetMarginCoeficientItem (tier: number): any {
        let item = null;

        if (!tier) tier = 0;

        if (this.MarginCoeficientList?.[tier]) {
            item = this.MarginCoeficientList[tier];
        }

        return item;
    }

    public GetWarnMargin (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_WARN_MARGINGSIZE : null;

        return value !== null ? value : 0;
    }

    public GetInitMargin (tier: number): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_INIT_MARGINGSIZE : null;

        return value !== null ? value : 0;
    }

    public GetMaintMargin (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_HOLD_MARGINGSIZE : null;

        return value !== null ? value : 0;
    }

    // Overnight
    public GetWarnMarginOvernight (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_WARN_MARGINGSIZE_OVERNIGHT : null;

        return value !== null ? value : 0;
    }

    public GetInitMarginOvernight (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_INIT_MARGINGSIZE_OVERNIGHT : null;

        return value !== null ? value : 0;
    }

    public GetMaintMarginOvernight (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_HOLD_MARGINGSIZE_OVERNIGHT : null;

        return value !== null ? value : 0;
    }

    // Short
    public GetWarnMarginShort (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_WARN_MARGINGSIZE_SHORT : null;

        return value !== null ? value : 0;
    }

    public GetInitMarginShort (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_INIT_MARGINGSIZE_SHORT : null;

        return value !== null ? value : 0;
    }

    public GetMaintMarginShort (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_HOLD_MARGINGSIZE_SHORT : null;

        return value !== null ? value : 0;
    }

    // OvernightShort
    public GetWarnMarginOvernightShort (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_WARN_MARGINGSIZE_OVERNIGHT_SHORT : null;

        return value !== null ? value : 0;
    }

    public GetInitMarginOvernightShort (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_INIT_MARGINGSIZE_OVERNIGHT_SHORT : null;

        return value !== null ? value : 0;
    }

    public GetMaintMarginOvernightShort (tier): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_HOLD_MARGINGSIZE_OVERNIGHT_SHORT : null;

        return value !== null ? value : 0;
    }

    // UseOvernight and UseLongShort
    public GetUseOvernightMargin (): any {
        return this.UseOvernightMargin;
    }

    public GetUseLongShortMargin (): any {
        return this.UseLongShortMargin;
    }

    // VarCoeff
    public GetUseVarOnlyIntraday (tier: any = undefined): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_USE_VAR_ONLY_INTRADAY : null;

        return value !== null ? value : 0;
    }

    public GetVarCoeff (tier: any = undefined): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_VAR_COEFF : null;

        return value !== null ? value : 0;
    }

    public GetVarCoeffApplying (tier: any = undefined): number {
        const marginCoefficientItem = this.GetMarginCoeficientItem(tier);
        const value = marginCoefficientItem ? marginCoefficientItem.Futures_VAR_COEFF_APPLYING : null;

        return value !== null ? value : 0;
    }

    // GetType
    public GetMarginType (): MarginTypes | null {
        return this.CalculateMarginType;
    }

    // Leverages: #111414->#112880->#112886
    public IsPriceBasedMultipleCoefMarginType (): boolean {
        return this.CalculateMarginType === MarginTypes.PRICE_BASED_MULTIPLE_COEF;
    }

    public GetLeverages (): number[] {
        return this.Leverages;
    }

    public GetLeverageTier (tier): any {
        return this.Leverages[tier] || null;
    }

    public IsLeverageVisible (): boolean {
        const coefMarginTypeSuit = this.IsPriceBasedMultipleCoefMarginType();
        const leverages = this.GetLeverages();
        const leveragesNotEmpty = leverages?.length;

        return !!(coefMarginTypeSuit && leveragesNotEmpty);
    }

    public GetUseSettledCashForStocksForOpenedPositionsInMA (): any {
        return this.UseSettledCashForStocksForOpenedPositionsInMA;
    }

    public GetUseSettledCollateralForOpenedPositionInMA (): any {
        return this.UseSettledCollateralForOpenedPositionInMA;
    }

    public GetCurrencyId (): any {
        return this.CurrencyId;
    }

    public getValue (literal): any {
        return this[literal];
    }
}
