// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Position } from '../../cache/Position';
import { type IDestroyable, type IBrokerConnectionAdapterHost, type Position as tvPosition } from '../charting_library';
import { DataCache } from '../../DataCache';
import { Quantity } from '../../../Utils/Trading/Quantity';
import { PlacedFrom } from '../../../Utils/Trading/PlacedFrom';
import { TvPositionConvertor } from '../Convertors/TvPositionConvertor';
import { PositionFormatter } from '../../cache/Formatters/PositionFormatter';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';

export class TvPositionsManager implements IDestroyable {
    private readonly host: IBrokerConnectionAdapterHost;

    constructor (host: IBrokerConnectionAdapterHost) {
        this.host = host;
        this.subscribe();
    }

    public async getPositions (): Promise<tvPosition[]> {
        return await new Promise((resolve, reject) => {
            const rPositions = DataCache.getPositionsByAccount(TvAccountHelper.getCurrentAccount());
            const positions: tvPosition[] = [];
            for (const posID in rPositions) {
                const position = rPositions[posID];
                const tvPosition = TvPositionConvertor.convertToTvPosition(position);
                positions.push(tvPosition);
            }

            resolve(positions);
        });
    }

    public async closePosition (positionId: string, amount?: number): Promise<void> {
        await new Promise<void>((resolve, reject) => {
            const position = DataCache.getPositionById(positionId);
            if (!position) {
                reject(`Position with id ${positionId} not found`);
                return;
            }
            const amountToClose = amount ?? position.Amount;
            const qty = new Quantity(amountToClose, true);
            DataCache.FOrderExecutor.closePositionQuantity(position, qty, PlacedFrom.TV_TRADING_PLATFORM_OTHER);
            resolve();
        });
    }

    public updatePositions (): void {
        const rPositions = DataCache.getPositionsByAccount(TvAccountHelper.getCurrentAccount());
        for (const posID in rPositions) {
            const position = rPositions[posID];
            // TODO: кэшировать ласт прайс?
            // И апдейтить только если ласт прайс обновился?
            if (position.lastPriceUpdated) {
                const pnl = position.Account.roundPrice(position.getGrossPnL(true));
                this.host.plUpdate(posID, pnl);
                const tvPosition = TvPositionConvertor.convertToTvPosition(position);
                tvPosition.last = position.CurPriceClose;
                tvPosition.pl = pnl;// position.Account.formatPrice(pnl);
                tvPosition.netpl = PositionFormatter.NetPnL(position, true);

                tvPosition.swaps = position.GetSwaps(true);
                tvPosition.fee = PositionFormatter.Commission(position, true);

                const sl = PositionFormatter.StopLossPrice(position);
                if (!isNaN(sl)) {
                    tvPosition.stopLoss = sl;
                } else {
                    tvPosition.stopLoss = undefined;
                }

                const tp = PositionFormatter.TakeProfitPrice(position);
                if (!isNaN(tp)) {
                    tvPosition.takeProfit = tp;
                } else {
                    tvPosition.takeProfit = undefined;
                }
                this.host.positionPartialUpdate(posID, tvPosition);
            }
        }
    }

    private subscribe () {
        DataCache.OnAddPosition.Subscribe(this.onAddOrUpdatePosition, this);
        DataCache.OnUpdatePosition.Subscribe(this.onAddOrUpdatePosition, this);
        DataCache.OnRemovePosition.Subscribe(this.onRemovePosition, this);
        // CorporateAction
        DataCache.OnAddCorporateAction.Subscribe(this.onAddOrUpdatePosition, this);
        DataCache.OnUpdateCorporateAction.Subscribe(this.onAddOrUpdatePosition, this);
        DataCache.OnRemoveCorporateAction.Subscribe(this.onRemovePosition, this);
    }

    private unsubscribe () {
        DataCache.OnAddPosition.UnSubscribe(this.onAddOrUpdatePosition, this);
        DataCache.OnUpdatePosition.UnSubscribe(this.onAddOrUpdatePosition, this);
        DataCache.OnRemovePosition.UnSubscribe(this.onRemovePosition, this);

        // CorporateAction
        DataCache.OnAddCorporateAction.UnSubscribe(this.onAddOrUpdatePosition, this);
        DataCache.OnUpdateCorporateAction.UnSubscribe(this.onAddOrUpdatePosition, this);
        DataCache.OnRemoveCorporateAction.UnSubscribe(this.onRemovePosition, this);
    }

    private onAddOrUpdatePosition (position: Position) {
        if (position.Account !== TvAccountHelper.getCurrentAccount()) return;
        const tvPosition = TvPositionConvertor.convertToTvPosition(position);
        this.host.positionUpdate(tvPosition);
    }

    private onRemovePosition (position: Position) {
        if (position.Account !== TvAccountHelper.getCurrentAccount()) return;
        const tvPosition = TvPositionConvertor.convertToTvPosition(position);
        tvPosition.qty = 0; // признак удаления позиции
        this.host.positionUpdate(tvPosition);
    }

    // #region IDestroyable

    destroy (): void {
        this.unsubscribe();
    }
    // #endregion IDestroyable
}
