// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Md5 } from 'ts-md5';

export class UserKey {
    private readonly Login: string = '';
    private readonly ConnectionName: string = '';

    constructor (Login: string, ConnectionName: string) {
        this.Login = Login;
        this.ConnectionName = ConnectionName;
    }

    public getKey (): string {
        return Md5.hashStr(`${this.Login}-${this.ConnectionName}`);
    }
}
