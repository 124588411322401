// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { OEQuantityTemplate } from '../../../templates';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '@shared/localizations/Resources';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { type ComboboxItem } from '../../elements/Combobox';
import { type Account } from '@shared/commons/cache/Account';
import { MarginInfoParameters } from '@shared/commons/UtilsClasses/MarginInfo/MarginInfoParameters';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { MarginInfoWrapper } from '@shared/commons/UtilsClasses/MarginInfo/MarginInfoWrapper';
import { OrderEditBaseUtils } from '@shared/utils/Trading/OrderEditBaseUtils';
import { IsAllowed } from '@shared/commons/IsAllowed';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type ProductType } from '@shared/utils/Instruments/ProductType';
import { ResourcesManager } from '@shared/commons/properties/ResourcesManager';
import { type DynProperty } from '@shared/commons/DynProperty';

export class OEQuantity extends ContainerControl {
    private cashMarginRequestTimeoutID: NodeJS.Timeout | null = null; // If Margin info is opened Quantity should be updated together with other margin parameters in one request (MarginOEControl->RefreshByMarginRequest)
    private readonly cashMarginRequestTimeoutDuration: number = 200; // timeout delay duration (in milliseconds) before sending the margin request
    private autoMarginRequestIntervalID: NodeJS.Timeout | null = null; // If Margin info isn’t opened, then for Market order CHECK_MARGIN_REQ should be sent automatically every 10 seconds.
    private readonly autoMarginRequestIntervalDuration: number = 10000; // interval duration (in milliseconds)

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () {
        super();
    }

    public override getType (): ControlsTypes { return ControlsTypes.OEQuantity; }

    public override oninit (): void {
        super.oninit();

        this.observe('value', this.onValueChanged);
        this.observe('inLots', this.updateQuantity);
        this.observe('instrument account productType orderType', this.updateIsCashAvailable, { init: false });
        this.observe('qtyCashSelectedItem', this.onQtyCashSelectedItemChanged);
        this.observe('estQtyText estQtyValue inLots isCashMode', this.updateEstQuantity);
        this.observe('account isCashMode', this.updateAssetName);
        this.observe('orderEditParameterArray', this.onPriceCouldChanged);
        this.observe('cash operation', this.handleCashMarginRequest); // may need send margin request to get Est.qty value if MarginOE is absent or closed
        this.observe('isCashMode', this.onIsCashModeChanged, { init: false }); // subscribe to outside changing (e.g. callBack of OE)
        this.observe('skipMarginRequest orderType isCashMode', this.observeIfMarginRequestNeeded);

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.updateIsCashAvailable();
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        if (this.cashMarginRequestTimeoutID) { clearTimeout(this.cashMarginRequestTimeoutID); }
        if (this.autoMarginRequestIntervalID) { clearInterval(this.autoMarginRequestIntervalID); }

        super.dispose();
    }

    public override setFocus (): void {
        super.setFocus();
        this.Controls.QN.setFocus();
    }

    public onValueChanged (value): void {
        const isCashMode: boolean = this.get('isCashMode');
        if (isCashMode) {
            void this.set('cash', value);
        } else {
            this.updateQuantity();
        }
    }

    public updateQuantity (): void {
        const isCashMode: boolean = this.get('isCashMode');
        if (!isCashMode) {
            const value = this.get('value');
            const inLots = this.get('inLots');
            void this.set('quantity', new Quantity(value, inLots));
        }
    }

    public localize (): void {
        this.localizeEstQtyLabel();
        this.localizeQtyCashLabel();
        this.localizeContextMenuItems();
    }

    private localizeEstQtyLabel (): void {
        void this.set('estQtyText', Resources.getResource('EstQuantity'));
    }

    private localizeQtyCashLabel (): void {
        const item = this.get('qtyCashSelectedItem');
        const localKey = this.getLocalKeyForContextMenuItem(item);
        void this.set('label', Resources.getResource(localKey ?? 'panel.newOrderEntry.amountLabel'));
    }

    private localizeContextMenuItems (): void {
        const contextMenuItems = this.get('qtyCashItems');
        contextMenuItems.forEach((item) => {
            const locKey = this.getLocalKeyForContextMenuItem(item);
            item.text = Resources.getResource(locKey);
        });
        void this.set('qtyCashItems', contextMenuItems);
    }

    private getLocalKeyForContextMenuItem (item): string {
        switch (item?.value) {
        case OEQuantityCashContextItems.Cash:
            return 'panel.newOrderEntry.cashLabel';
        case OEQuantityCashContextItems.Quantity:
            return 'panel.newOrderEntry.amountLabel';
        }
    }

    private updateCashSelection (): ComboboxItem {
        let { qtyCashSelectedItem, qtyCashItems, isCashAvailable } = this.get();
        const cashForbidden: boolean = !(isCashAvailable as boolean);

        if (qtyCashSelectedItem == null ||
            (cashForbidden && this.isCashContextItem(qtyCashSelectedItem))) {
            qtyCashSelectedItem = isCashAvailable && SessionSettings.IsCashModePreferred() ? qtyCashItems[1] : qtyCashItems[0];
        }

        return qtyCashSelectedItem;
    }

    private updateIsCashAvailable (): void {
        const { instrument, account, productType, showLabel } = this.get();
        const orderTypeId = this.get('orderType')?.id();
        const suitableTradingEntity = showLabel; // Cash trading is only available from the main OE and in trading ideas – i.e., OEQuantity that has a label
        const isCashAvailable = IsAllowed.IsCashTradingAllowed(account, instrument, orderTypeId, productType) && suitableTradingEntity;

        const qtyCashSelectedItem = this.updateCashSelection();

        if (this.get('isCashMode') && !isCashAvailable) {
            void this.set({ isCashMode: false }); // when changing the order type to OCO while in Cash
        }

        void this.set({
            isCashAvailable,
            qtyCashSelectedItem
        });
    }

    private onQtyCashSelectedItemChanged (newItem, oldItem): void {
        if (newItem?.value === oldItem?.value) { return; }
        const qtyCashSelectedItem = this.updateCashSelection();
        const isCashMode = this.isCashContextItem(qtyCashSelectedItem);

        let value = null; // #122525: If some value is entered for Cash or Quantity it should be saved if mode has been changed. If the mode is selected again, the previously saved value should be displayed.
        if (isCashMode) {
            value = this.get('cash') ?? 1; // #124063+
        } else {
            const qty = this.get('quantity');
            if (qty?.value != null) {
                value = qty.toNumber(this.get('instrument'));
            }
        }

        void this.set({
            isCashMode,
            qtyCashSelectedItem
        }).then(() => {
            if (value > 0) {
                void this.set({ value }).then(() => {
                    this.onValueChanged(value);
                });
            }
        });
    }

    private isCashContextItem (item): boolean {
        return item?.value === OEQuantityCashContextItems.Cash;
    }

    private updateEstQuantity (): void {
        const { estQtyText, estQtyValue } = this.get();
        if (estQtyValue == null) { return; }
        const account: Account = this.get('account');
        const instrument: Instrument = this.get('instrument');
        const productType: ProductType = this.get('productType');
        const inLots = this.get<boolean>('inLots'); // #122525: `Display qty in lots setting` should be considered for Est. Quantity label
        const estQtyNumber = Quantity.convertQuantityValue(new Quantity(estQtyValue, true), instrument, inLots, account, productType);
        const estQtyValueFormatted = inLots ? InstrumentUtils.formatAmountValue(estQtyNumber, instrument, account, productType) : estQtyNumber;

        const estQtyFormatted = `${estQtyText ?? ''} ${estQtyValueFormatted ?? ''}`;
        void this.set({
            estQtyFormatted
        });
    }

    private updateAssetName (): void {
        const account: Account = this.get('account');
        const isCashMode: boolean = this.get('isCashMode');

        if (account == null) { return; }

        const assetNameForCash = isCashMode ? account.assetName : '';
        void this.set({
            assetNameForCash
        });
    }

    private onIsCashModeChanged (): void { // for outside changing (e.g. callBack of OE)
        let { qtyCashSelectedItem, qtyCashItems, isCashMode } = this.get();

        SessionSettings.updateIsCashModePreferred(isCashMode);

        if (isCashMode === this.isCashContextItem(qtyCashSelectedItem)) { return; }

        qtyCashItems.forEach((item) => {
            if (isCashMode === this.isCashContextItem(item)) {
                qtyCashSelectedItem = item;
            }
        });

        void this.set({
            qtyCashSelectedItem
        });
    }

    private prepareMarginRequestParameters (): MarginInfoParameters | null {
        if (this.get<boolean>('skipMarginRequest') || !this.get<boolean>('isCashMode')) {
            return null;
        }

        const parameters: MarginInfoParameters = new MarginInfoParameters();
        parameters.account = this.get('account');
        parameters.instrument = this.get('instrument');
        parameters.isLong = this.get('operation') === OperationType.Buy;
        parameters.isCashMode = true;
        parameters.cash = this.get('cash');
        parameters.orderType = this.get('orderType')?.id();
        parameters.productType = this.get('productType');

        const limitPrice = OrderEditBaseUtils.GetLimitPrice(this);
        if (limitPrice != null) { parameters.limitPrice = limitPrice; }

        const stopPrice = OrderEditBaseUtils.GetStopPrice(this);
        if (stopPrice != null) { parameters.stopPrice = stopPrice; }

        return parameters;
    }

    /**
 * Handles price changes by comparing the new and previous order edit parameters.
 * @param orderEditParameterArray - The new order edit parameters.
 */
    private onPriceCouldChanged (orderEditParameterArray: DynProperty[]): void {
        this.handleCashMarginRequest();
    }

    private handleCashMarginRequest (): void {
        if (this.cashMarginRequestTimeoutID != null) {
            clearTimeout(this.cashMarginRequestTimeoutID);
        }

        this.cashMarginRequestTimeoutID = setTimeout(() => {
            void (async () => {
                this.cashMarginRequestTimeoutID = null;
                if (!this.get<boolean>('isCashMode')) { return; }

                const parameters = this.prepareMarginRequestParameters();
                if (parameters?.cash == null) {
                    return;
                }

                const marginInfoWrapper = new MarginInfoWrapper();
                const amount = await marginInfoWrapper.GetMarginAmount(parameters);
                if (amount != null) {
                    void this.set('estQtyValue', amount);
                }
            })();
        }, this.cashMarginRequestTimeoutDuration);
    }

    private observeIfMarginRequestNeeded (): void {
        if (this.autoMarginRequestIntervalID) {
            clearInterval(this.autoMarginRequestIntervalID);
            this.autoMarginRequestIntervalID = null;
        }

        const isMarket = this.get('orderType')?.id() === OrderType.Market;
        if (isMarket) {
            this.autoMarginRequestIntervalID = setInterval(() => {
                this.handleCashMarginRequest();
            }, this.autoMarginRequestIntervalDuration);

            this.handleCashMarginRequest();
        }
    }
}

ContainerControl.extendWith(OEQuantity, {
    template: OEQuantityTemplate,
    data: function () {
        return {
            label: '',
            instrument: null,
            account: null,
            operation: null,
            orderType: null,
            orderEditParameterArray: null,

            value: null,
            inLots: false,
            quantity: null,
            // TODO. Ugly. Remove.
            defaultQuantity: null,

            isCashAvailable: false,
            isCashMode: false,
            qtyCashItems: [ // #122525
                { text: '', value: OEQuantityCashContextItems.Quantity },
                { text: '', value: OEQuantityCashContextItems.Cash }
            ],
            qtyCashSelectedItem: null,
            qtyCashTooltip: 'Quantity or Cash can be selected.',

            skipMarginRequest: false,

            estQtyText: null,
            estQtyValue: null,
            estQtyFormatted: '', // = estQtyText + estQtyValue
            assetNameForCash: '', // a.k.a rightAlignedText in TerceraNumeric

            showLabel: true,
            noArrowCB: false,
            positionSizingVisible: false,
            positionSizingChecked: false
        };
    }
});

export enum OEQuantityCashContextItems {
    Quantity,
    Cash
}
