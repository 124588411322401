// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type ErrorFormatterParseResult, type SuccessFormatterParseResult } from '../charting_library';
import { TvBaseFormatter } from './TvBaseFormatter';

export class TvSpreadFormatter extends TvBaseFormatter {
    constructor (symbol: string) {
        super(symbol);
    }

    public override format (value: number): string {
        if (this.instrument) {
            const tickSize = this.instrument.GetPointSize(null);
            if (tickSize === 0) return this.instrument.formatPrice(value);
            const ticks = Math.round(value / tickSize);
            return `${ticks} ticks`; // TODO: localization
        }

        return value.toString();
    }

    public override parse (value: string): ErrorFormatterParseResult | SuccessFormatterParseResult<number> {
        const valueWithoutTicks = value.replace(/ ticks$/, '');
        return super.parse(valueWithoutTicks);
    }
}
