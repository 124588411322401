// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingDataSourceScreenTemplate } from '../templates.js';
import Ractive from 'ractive';

export let closeDataSourceScreen = null;
export let openDataSourceScreen = null;

export class InvestingDataSourceScreen extends Ractive {
    constructor () { super(); }

    public getType (): string { return 'InvestingDataSourceScreen'; }

    public override oncomplete (): void {
        openDataSourceScreen = (x, y) => { this.Show(x, y); };
        closeDataSourceScreen = () => { this.Close(); };
    }

    public Show (posX: number, posY: number): void {
        void this.set({
            showDataSourceScreen: true,
            posX,
            posY
        });
    }

    public Close (): void {
        void this.set({ showDataSourceScreen: false });
    }
}

Ractive.extendWith(InvestingDataSourceScreen, {
    el: 'dataSourceScreen',
    template: InvestingDataSourceScreenTemplate,
    data: function () {
        return {
            showDataSourceScreen: null
        };
    }
});
