// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { iBandsEx } from './iBandsEx';

export class iBands extends iBandsEx {
    constructor (maPeriod, maMode, deviation, bandsShift) {
        super(maPeriod, maMode, deviation, bandsShift, PriceType.Close);
    }

    override get Name () { return 'iBands'; }
}
