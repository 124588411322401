// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectTradingSystemMessage } from '../../../../Utils/DirectMessages/DirectTradingSystemMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingSystemMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectTradingSystemMessage[] {
        const message = new DirectTradingSystemMessage();

        const len = fieldSet.Count();

        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_RAW_BYTES) {
                message.ImageBytes = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_LOGIN) {
                message.Login = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_NAME) {
                message.Name = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SYSTEM_ID) {
                message.ID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SYSTEM_COMPANY_NAME) {
                message.CompanyName = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SYSTEM_RISK_DISCLOSURE) {
                message.RiskDisclosure = fixField.Value;
            }
        }

        return [message];
    }
}
