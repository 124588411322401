// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { OperationType } from '@shared/utils/Trading/OperationType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { DynProperty } from '../../../DynProperty';
import { SlTpNumericInputParameters, SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { OrderEditUpdateData } from '@shared/utils/Trading/OrderEditUpdateData';
import { SLTPEdit, SLTPMode } from '../SLTPEdit';
import { OrderEditBaseUtils } from '@shared/utils/Trading/OrderEditBaseUtils';
import { OrderEditBase } from './OrderEditBase';
import { type SlTpHolder } from '@shared/utils/Trading/SlTpHolder';
import { type OrderEditConfirmationTextParams } from '../OrderEditConfirmationTextParams';
import { TrailingStop } from '../OrderBrackets/TrailingStop';

// TODO. Refactor. Come up with an offset numeric parameter
// for TrailingStopOrderEdit and SLTPEdit.
export class TrailingStopOrderEdit extends OrderEditBase {
    constructor (data) {
        super(data);
        this.sltp = new SLTPEdit(data.dataCache, SLTPMode.Offset);
        this.trailingStop = new TrailingStop();
        this.tryUpdateTrailingStop(null, false);
    }

    public override getParameterNameArray (): string[] {
        return [
            OrderEditBaseUtils.TRAILING_STOP_PARAM,
            OrderEditBaseUtils.POSITION_SIZING_PARAM,
            OrderEditBaseUtils.SLTP_PARAM,
            OrderEditBaseUtils.REAL_TRSTOP_PRICE,
            OrderEditBaseUtils.AFTER_TRADE_CASH
        ];
    }

    // TODO. Refactor. Same as OrderTypeBase.Id function.
    public override getOrderTypeId (): OrderType {
        return OrderType.TrailingStop;
    }

    // TODO. Refactor. Use error/warning dicts for sltp.
    // public valid ()
    // {
    //     return OrderEditBase.prototype.valid.call(this) &&
    //         this.sltp.valid()
    // }

    // #region Update Parameter Handlers

    // TODO. Rename. Refactor.
    public tryUpdateTrailingStop (dp, sessionSettingsChanged): boolean {
        const ins = this.instrument;
        const trStopObj = this.trailingStop;

        if ((sessionSettingsChanged || trStopObj.value === null) && ins) {
            const offsetType = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(ins);
            let ticks = null;

            if (trStopObj.value) {
                ticks = SlTpUtils.toRealValue(trStopObj.value, ins, trStopObj.offsetType);
            }

            trStopObj.offsetType = offsetType;
            trStopObj.value = SlTpUtils.toVisualValue(ticks || 1, ins, offsetType);

            return true;
        }

        if (dp && trStopObj.value !== dp.value) {
            trStopObj.value = dp.value;

            return true;
        }

        return false;
    }

    public update_trailingStop (updateData): boolean {
        return this.tryUpdateTrailingStop(
            updateData.dp,
            updateData.sessionSettingsChanged);
    }

    // #endregion Update Parameter Handlers

    // #region Parameters' DynProperty Generators

    public toDynProperty_trailingStop (): DynProperty {
        const offsetType = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(this.instrument);
        const sltpNumericInputParameters = new SlTpNumericInputParameters(this.instrument, offsetType);
        const data = SlTpUtils.getSlTpNumericParameters(sltpNumericInputParameters);

        const dp = new DynProperty(OrderEditBaseUtils.TRAILING_STOP_PARAM);
        dp.type = DynProperty.DOUBLE;
        dp.decimalPlaces = data.precision;
        dp.increment = data.step;
        dp.minimalValue = dp.increment;
        dp.maximalValue = 999999999;
        dp.value = this.trailingStop.value;
        dp.localizationKey = 'panel.newOrderEntry.trstopOffset';

        return dp;
    }

    // #endregion Parameters' DynProperty Generators

    // #region Raw Values

    public toRawValue_trailingStop (): number {
        const trStop = this.trailingStop;
        return SlTpUtils.toRealValue(trStop.value, this.instrument, trStop.offsetType);
    }

    public toRawValue_RealTrStopPrice (): number {
        if (!this.instrument.DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_TRAILING_STOP_BY_PRICE) || this.AlerEdit) {
            return null;
        }

        const openPrice = this.getQuotePriceForCalculatingOffset();

        const trStop = this.trailingStop;
        const sideSign = this.side === OperationType.Buy ? 1 : -1;
        // Посчитали истинные тики, соответсвенно потом в расчёте цены мы игнорим фракционы
        const ticks = SlTpUtils.toRealValue(trStop.value, this.instrument, trStop.offsetType) * sideSign;
        return this.instrument.roundPrice(this.instrument.CalculatePrice(openPrice, ticks, true));
    }

    public update_RealTrStopPrice (): boolean {
        return false;
    }

    public toDynProperty_RealTrStopPrice (): any {
        return {};
    }
    // #endregion Raw Values

    public validateParameters (): any {
        const updatedParamNameDict = {};

        if (this.sltp.validate(null, null, this.getTradingData())) {
            updatedParamNameDict[OrderEditBaseUtils.SLTP_PARAM] = true;
        }

        updatedParamNameDict[OrderEditBaseUtils.POSITION_SIZING_PARAM] = true;

        return updatedParamNameDict;
    }

    protected override createConfirmationTextParams (): OrderEditConfirmationTextParams {
        const textParams = super.createConfirmationTextParams();
        textParams.OrderTypeKey = 'Tr. stop';
        textParams.PriceArray = [this.trailingStop.value];
        textParams.PriceInOffset = true;
        textParams.RealTrStopPrice = this.toRawValue_RealTrStopPrice();

        return textParams;
    }

    // Returns SlTpHolder instance.
    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public getRawSLTP (): SlTpHolder {
        return this.sltp.getRawValue(this.getTradingData());
    }

    // TODO. Refactor. Rename. UGLY. Details are in OrderEditBase.ts.
    public setBasePrice (price): void {
        const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.TRAILING_STOP_PARAM);

        let newTicks = SlTpUtils.getPriceDifferenceInTicks(
            price,
            this.getQuotePriceForCalculatingOffset(),
            this.instrument);

        // TODO. Ugly.
        if (newTicks <= 0) {
            newTicks = 1;
        }

        // TODO. Works but meh...
        dp.value = SlTpUtils.toVisualValue(newTicks, this.instrument, this.trailingStop.offsetType);
        this.updateParameters(new OrderEditUpdateData({ trailingStop: dp }));
    }

    // TODO. Rename. Refactor.
    public getQuotePriceForCalculatingOffset (): any {
        const quote = this.quote;
        if (!quote) {
            return 0;
        }

        const sp = this.instrument.DataCache.GetSpreadPlan(this.account);
        return this.side === OperationType.Buy
            ? quote.AskSpread_SP_Ins(sp, this.instrument)
            : quote.BidSpread_SP_Ins(sp, this.instrument);
    };

    public getBasePrice (): any {
        return this.trailingStop.value;
    }
}
