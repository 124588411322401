// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';

export class HolidayItem extends BaseItem {
    public date: string;
    public name: string;
    public shedule: string;

    constructor (date: string, name: string, shedule: string) {
        super(null);

        this.date = date;
        this.name = name;
        this.shedule = shedule;
    }

    public override ColumnCount (): number {
        return HolidayItem.columnsParams.length;
    }

    public override GetColumnParams (column: number): ColumnParams {
        return HolidayItem.columnsParams[column];
    }

    public override getColumnData (column: number): ColumnData {
        const val = this.getColumnValue(column);
        return new ColumnData(val, val ? val.toString() : '');
    }

    public override getColumnValue (column: number): string | null {
        switch (column) {
        case 0:
            return this.date;
        case 1:
            return this.name;
        case 2:
            return this.shedule;
        default:
            return null;
        }
    }

    public static readonly columnsParams = [
        new ColumnParams('InstrumentDetailsPanel.HolidayDate', 40, 0, true, true, true),
        new ColumnParams('InstrumentDetailsPanel.HolidayName', 40, 0, true, true, true),
        new ColumnParams('InstrumentDetailsPanel.HolidayType', 40, 0, true, true, true)
    ];
}
