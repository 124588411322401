// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type PortfolioRequestType } from '../Portfolio/PortfolioRequestType';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectPortfolioRealtimeStatisticsMessage extends DirectMessageBaseClass {
    public Account: number | null = null; // FIELD_ACCOUNT_ID[89]  идентификатор аккаунта в системе
    public Type: PortfolioRequestType | null = null; // FIELD_TYPE[53]  тип статистики
    public TotalReturn: number | null = null; // FIELD_TOTAL_RETURN[753]  значение параметра рассчитанного по формуле из п.5.1.1 https://docs.google.com/document/d/1eD8P5Ck4ZN6zmikSFYK3YpyAUdF09pR6w6hqS84y6aY/edit
    public Groups: PortfolioAllocation[] = null; // PERCENT_INSTRUMENT_ROUTE_DAY_ENTRY_GROUP = 88  в данной группе будут передаваться значения долей по инструментам и кешу.Поле FIELD_DATE в группе всегда будет отсутствовать. Отсутствие полей FIELD_TRADABLE_INSTRUMENT_ID и FIELD_ROUTE_ID будет означать что это значение для “Cash”. В отличии от описания для группы, в поле FIELD_ROUTE_ID будет передавать идентификатор торгового роута.

    constructor () {
        super(Message.CODE_PFIX_PORTFOLIO_REALTIME_STATISTICS);
    }
}

export class PortfolioAllocation {
    public percent: number;
    public tradableInsID: number;
    public routeID: number;
}
