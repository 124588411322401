// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type MarginInfoItemType } from './MarginInfoEnums';

export class MarginInfoItem {
    id: MarginInfoItemType;

    title: string;
    value: number;
    formattedValue: string;
    isColored: boolean;
}
