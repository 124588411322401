// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MAMode, AlligatorMode } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iAlligator extends iBuildInIndicator {
    public FJawPeriod: any;
    public FJawShift: any;
    public FTeethShift: any;
    public FLipsPeriod: any;
    public FLipsShift: any;
    public FTeethPeriod: any;
    public FMaMode: any;
    public FJawMA: ExpandDoubleVector;
    public FTeethMA: ExpandDoubleVector;
    public FLipsMA: ExpandDoubleVector;

    constructor (jawPeriod, jawShift,
        teethPeriod, teethShift,
        lipsPeriod, lipsShift,
        maMode, priceType) {
        super(3);
        this.FJawPeriod = jawPeriod;
        this.FJawShift = jawShift;
        this.FTeethPeriod = teethPeriod;
        this.FTeethShift = teethShift;
        this.FLipsPeriod = lipsPeriod;
        this.FLipsShift = lipsShift;

        this.FMaMode = maMode;
        this.FPriceType = priceType;
        this.FJawMA = new ExpandDoubleVector(0, this.FJawShift);
        this.FTeethMA = new ExpandDoubleVector(0, this.FTeethShift);
        this.FLipsMA = new ExpandDoubleVector(0, this.FLipsShift);
    }

    public get Name (): string {
        return 'iAlligator';
    }

    public get Key (): string {
        return this.DefaultKey + this.FJawPeriod + this.FJawShift +
                this.FTeethPeriod + this.FTeethShift +
                this.FLipsPeriod + this.FLipsShift +
                this.FMaMode + this.FPriceType;
    }

    public NextBar (callBound): void {
        super.NextBar(callBound);
        this.FJawMA.Add(0.0);
        this.FTeethMA.Add(0.0);
        this.FLipsMA.Add(0.0);
    };

    public OnQuote (ci, callBound, callFromRefresh): void {
        const price = this.GetPrice(this.FPriceType, 0);
        const count = this.Count - 1;

        this.FJawMA[this.FJawMA.Length - 1] = price;
        this.FTeethMA[this.FTeethMA.Length - 1] = price;
        this.FLipsMA[this.FLipsMA.Length - 1] = price;

        const useMagic = this.FMaMode === MAMode.EMA || this.FMaMode === MAMode.SMMA;

        if (useMagic) {
            if (this.FJawPeriod === count - 1) { this.FJawMA[this.FJawMA.Length - 1] = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FJawMA, this.FJawPeriod); }

            if (this.FTeethPeriod === count - 1) { this.FTeethMA[this.FTeethMA.Length - 1] = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FTeethMA, this.FTeethPeriod); }

            if (this.FLipsPeriod === count - 1) { this.FLipsMA[this.FLipsMA.Length - 1] = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this.FLipsMA, this.FLipsPeriod); }
        }
        const Parent = this.Parent;
        if (!Parent) { return; };

        if (this.FJawPeriod <= count) {
            const val = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FJawMA, this.FJawPeriod, 1);
            this.SetValue(AlligatorMode.Jaw, 0, val);
            if (useMagic) { this.FJawMA[this.FJawMA.Length - 1] = val; }
        }
        if (this.FTeethPeriod <= count) {
            const val = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FTeethMA, this.FTeethPeriod, 1);
            this.SetValue(AlligatorMode.Teeth, 0, val);
            if (useMagic) { this.FTeethMA[this.FTeethMA.Length - 1] = val; }
        }

        if (this.FLipsPeriod <= count) {
            const val = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.FLipsMA, this.FLipsPeriod, 1);
            this.SetValue(AlligatorMode.Lips, 0, val);
            if (useMagic) { this.FLipsMA[this.FLipsMA.Length - 1] = val; }
        }
    };
}
