// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { IdeasPanelSideBarTemplate } from '../../../../templates.js';
import { HtmlScroll, HtmlScrollXY } from '../../../../Commons/HtmlScroll';
import { DataCache } from '../../../../Commons/DataCache';
import { InstrumentTypes, InstrumentTypesColor, InstrumentTypesImageFileNameMap, InstrumentTypesShortName } from '../../../../Utils/Instruments/InstrumentTypes';
import { InstrumentUtils } from '../../../../Utils/Instruments/InstrumentUtils';
import { Resources } from '../../../../Localizations/Resources';
import { IdeasSideBarController } from '../../../../Commons/SideBarController/IdeasSideBarController';
import { type Idea } from '../../../../Commons/cache/Idea';
import { ControlsTypes } from '../../../UtilsClasses/FactoryConstants';
import { PanelSideBarBase } from './PanelSideBarBase';

export class IdeasPanelSideBar extends PanelSideBarBase {
    private readonly _ideasSideBarController = new IdeasSideBarController();
    private openIdeaId = null;
    private selectedTypeIndex = null; // выбранный тип фильтрации
    private filteringIsEnabled = false; // может быть включен ключом, но отсутствовать если идеи только по одному instrType
    private filteringAvailableByKey = false;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public getType (): ControlsTypes {
        return ControlsTypes.IdeasPanelSideBar;
    }

    oncomplete (): void {
        super.oncomplete();
        this.eventsSubscribe();
        this.showRiskDisclosureIfNeed();
        this.loadAllIdeas();
        this.localizeInstrumentTypes();

        this.on('agreeBtnClick', this.agreeBtnClick);
        this.on('selectTypeToFilter', this.selectTypeToFilter);
        this.observe('ideas', this.registerAllControl);
    };

    public override lostFocus (): void {
        if (!this.get('productTypeChangeConfirmationIsInFocus')) {
            super.lostFocus();
        }
    }

    public override isLostFocusForbidden (): boolean {
        return this.get('productTypeChangeConfirmationIsInFocus');
    }

    public override customLostFocus (_parentPanel): boolean {
        return true;
    }

    private readonly addIdea = (idea): void => {
        const allIdeas = this.get('allIdeas');

        let indexToInsert = 0;
        for (let i = 0; i < allIdeas.length; i++) {
            if (allIdeas[i].createTime > idea.createTime) {
                indexToInsert++;
            } else {
                break;
            }
        }

        allIdeas.splice(indexToInsert, 0, idea);
        void this.set('allIdeas', allIdeas);
        if (this.selectedTypeIndex === null) {
            void this.set('ideas', allIdeas);
        }

        this.addIdeaToTypes(idea);
        this.allIdeasScrollAdd();
    };

    private readonly cancelIdea = (idea): void => {
        const ideas = this.get('allIdeas');
        const index = ideas.indexOf(idea);

        if (this.openIdeaId === index) { // #90817
            this.openIdeaId = null;
        }

        ideas.splice(index, 1);
        this.deleteIdeaFromTypes(idea);
        void this.set('allIdeas', ideas);

        if (this.selectedTypeIndex === null) {
            void this.set('ideas', ideas);
        }
    };

    private deleteIdeaFromTypes (idea: Idea): void {
        if (!this.filteringAvailableByKey) {
            return;
        }

        const typesArr = this.get('types');
        let instrType = idea.instrument.InstrType;

        if (InstrumentUtils.UseFuturesInsteadSpot() && instrType === InstrumentTypes.SPOT) {
            instrType = InstrumentTypes.FUTURES;
        }

        const text = InstrumentUtils.getInstrumentTypeStringLocalized(instrType);
        let arrInd;
        for (arrInd = 1; arrInd < typesArr.length; arrInd++) { // ищем нужный instrType (начинаем с 1 потому что в нулевом All)
            if (typesArr[arrInd].instrType === instrType) {
                break;
            }
        }

        const byTypeObj = typesArr[arrInd];
        const index = byTypeObj.ideas.indexOf(idea);
        byTypeObj.ideas.splice(index, 1);
        let typeWasDelete = false;

        if (byTypeObj.ideas.length === 0) {
            typesArr.splice(arrInd, 1); // идей по такой группе больше нет - удаляем ее
            typeWasDelete = true;
        } else { // иначе только обновляем текст
            byTypeObj.text = text + ' (' + byTypeObj.ideas.length + ')';
        }

        if (this.selectedTypeIndex === arrInd || typesArr.length === 1) { // если удаленная идея по открытому типу надо обновить массив чтоб идея исчезла
            if (!byTypeObj.ideas.length) {
                this.selectedTypeIndex = 0; // состояние selected перемещаем на All согласно комментарию в #96602
                typesArr[0].isSelected = true;
                void this.set('ideas', typesArr[0].ideas);
            } else {
                void this.set('ideas', byTypeObj.ideas);
            }

            if (this.openIdeaId === index) { // #90817
                this.openIdeaId = null;
            }
        } else if (!byTypeObj.ideas.length && this.selectedTypeIndex > arrInd) { // какой-то тип удалили перед selected типом -> индекс изменился
            this.selectedTypeIndex--;
        }

        void this.set('types', typesArr);

        if (typeWasDelete) { // скролл усох - надо обновить
            this.typesScrollAdd();
        }

        this.filteringIsEnabled = typesArr.length > 2;
        this.allIdeasScrollAdd();
    }

    private readonly onTradingSystemUnsubscribe = (deletedTrSystemID): void => {
        const ideas = this.get('ideas');
        const ideasLeft = [];

        for (let i = 0; i < ideas.length; i++) {
            const idea = ideas[i];

            if (idea.tradingSystemId !== deletedTrSystemID) {
                ideasLeft.push(idea);
            }
        }

        void this.set('ideas', ideasLeft);
    };

    eventsSubscribe (): void {
        this._ideasSideBarController.subscribe();
        this._ideasSideBarController.subscribeToAdd(this.addIdea);
        this._ideasSideBarController.subscribeToCancel(this.cancelIdea);
        this._ideasSideBarController.subscribeToSystemsChange(this.showRiskDisclosureIfNeed);
        this._ideasSideBarController.subscribeToSystemUnsubscribe(this.onTradingSystemUnsubscribe);
    }

    eventsUnSubscribe (): void {
        this._ideasSideBarController.unsubscribe();
        this._ideasSideBarController.unsubscribeToAdd(this.addIdea);
        this._ideasSideBarController.unsubscribeToCancel(this.cancelIdea);
        this._ideasSideBarController.unsubscribeToSystemsChange(this.showRiskDisclosureIfNeed);
        this._ideasSideBarController.unsubscribeToSystemUnsubscribe(this.onTradingSystemUnsubscribe);
    }

    private allIdeasScrollAdd (): void {
        const allIdeas = this.find('.ideasPanelSideBar-item-container');
        if (allIdeas) {
            HtmlScroll.removeScroll(allIdeas);
            HtmlScroll.addScroll(allIdeas);
        }
    };

    private loadAllIdeas (): void {
        const tradingIdeas = DataCache.tradingIdeas;
        const ideaIDs = Object.keys(tradingIdeas);
        const allIdeas = [];
        this.openIdeaId = null;

        for (let i = ideaIDs.length - 1; i >= 0; i--) {
            const id = ideaIDs[i];
            const idea = tradingIdeas[id];
            allIdeas.push(idea);
        }

        void this.set('allIdeas', allIdeas);
        this.initTypes();
        this.allIdeasScrollAdd();
    }

    private initTypes (): void {
        this.filteringIsEnabled = this.filteringAvailableByKey = !Resources.isHidden('panel.Ideas.FilteringByInstrumentTypes');
        const allIdeas = this.get('allIdeas');
        void this.set('ideas', allIdeas); // по умолчанию выбран All

        if (!this.filteringIsEnabled) {
            return;
        }

        this.selectedTypeIndex = 0; // по умолчанию выбран All - нулевой элемент
        const typesObj = {};

        const localizedWordAll = Resources.getResource('panel.Ideas.FilteringAllTypes');
        const typesArr = [{ shortName: localizedWordAll, text: localizedWordAll, ideas: allIdeas, isSelected: true, isAllType: true }];
        for (let i = 0; i < allIdeas.length; i++) {
            const idea = allIdeas[i];
            let tID = idea.instrument.InstrType;

            if (InstrumentUtils.UseFuturesInsteadSpot() && tID === InstrumentTypes.SPOT) {
                tID = InstrumentTypes.FUTURES;
            }

            if (idea.instrument.CFD) {
                tID = InstrumentTypes.EQUITIES_CFD;
            }

            const text = InstrumentUtils.getInstrumentTypeStringLocalized(tID);
            const shortName = InstrumentTypesShortName[tID];
            const color = InstrumentTypesColor[tID];

            if (!typesObj[tID]) {
                typesObj[tID] = {
                    ideas: [],
                    instrType: tID,
                    text,
                    imgFileName: InstrumentTypesImageFileNameMap[tID],
                    isSelected: false,
                    color,
                    arrIndex: typesArr.length,
                    shortName
                };
                typesArr.push(typesObj[tID]);
            }

            const idx = typesObj[tID].arrIndex;

            typesArr[idx].ideas.push(idea);
            typesArr[idx].text = text + ' (' + typesArr[idx].ideas.length + ')';
        }

        void this.set('types', typesArr);

        this.sortTypes();

        this.typesScrollAdd();

        this.filteringIsEnabled = typesArr.length > 2;
    }

    private addIdeaToTypes (idea: Idea): void {
        if (!this.filteringAvailableByKey) {
            return;
        }

        const typesArr = this.get('types');
        let instrType = idea.instrument.InstrType;

        if (InstrumentUtils.UseFuturesInsteadSpot() && instrType === InstrumentTypes.SPOT) {
            instrType = InstrumentTypes.FUTURES;
        }

        if (idea.instrument.CFD) {
            instrType = InstrumentTypes.EQUITIES_CFD;
        }

        const color = InstrumentTypesColor[instrType];
        const shortName = InstrumentTypesShortName[instrType];
        const text = InstrumentUtils.getInstrumentTypeStringLocalized(instrType);
        const lowerText = text.toLowerCase();

        let arrInd;
        for (arrInd = 1; arrInd < typesArr.length; arrInd++) { // ищем нужный instrType (начинаем с 1 потому что в нулевом All)
            if (typesArr[arrInd].instrType === instrType) {
                break;
            }
        }

        let newTypeWasAdded = false;
        if (arrInd === typesArr.length) { // такого типа инструмента еще не было, надо создать
            for (let i = 0; i <= typesArr.length; i++) { // ищем правильное место для вставки чтобы сохранить алфавитный порядок
                const s = i < typesArr.length ? typesArr[i].text.toLowerCase() : '';
                if (i === typesArr.length || s > lowerText) {
                    typesArr.splice(i, 0, {
                        ideas: [],
                        instrType,
                        text,
                        color,
                        isSelected: false,
                        shortName
                    });

                    if (i <= this.selectedTypeIndex) { // новую идею вставили перед selected типом -> индекс изменился
                        this.selectedTypeIndex++;
                    }

                    arrInd = i;
                    break;
                }
            }
            newTypeWasAdded = true;
        }

        const byTypeObj = typesArr[arrInd];
        let indexToInsert = 0;

        for (let i = 0; i < byTypeObj.ideas.length; i++) {
            if (byTypeObj.ideas[i].createTime > idea.createTime) { // для надежности ищем правильное место идеи (чем новее тем выше), на практике покидаем цикл после первого if
                indexToInsert++;
            } else {
                break;
            }
        }

        byTypeObj.ideas.splice(indexToInsert, 0, idea);
        byTypeObj.text = text + ' (' + byTypeObj.ideas.length + ')';

        if (newTypeWasAdded) {
            void this.set('types', typesArr);
        }

        void this.set('types[' + arrInd + '].ideas', byTypeObj.ideas);
        void this.set('types[' + arrInd + '].text', byTypeObj.text);

        if (this.selectedTypeIndex === arrInd) { // если новая идея по открытому типу надо обновить массив чтоб идея появилась
            void this.set('ideas', byTypeObj.ideas);
        }

        if (this.selectedTypeIndex === 0) {
            void this.set('ideas', typesArr[0].ideas);
        }

        if (newTypeWasAdded) { // скролльчик подрос
            this.typesScrollAdd();
        }

        this.filteringIsEnabled = typesArr.length > 2;
    }

    public localize (): void {
        void this.set({
            textAgreeButton: Resources.getResource('panel.Ideas.riskDisclosure.agreeBtnTxt'),
            noDataText: Resources.getResource('panel.Ideas.riskDisclosure.noDataText')
        });
    };

    private localizeInstrumentTypes (): void {
        const types = this.get('types');
        if (!types.length) {
            return;
        };

        for (let i = 0; i < types.length; i++) {
            const insType = types[i].instrType;
            types[i].text = insType ? InstrumentUtils.getInstrumentTypeStringLocalized(insType) : Resources.getResource('panel.Ideas.FilteringAllTypes');
        }

        void this.set('types', types);
        this.sortTypes();
    }

    private sortTypes (): void {
        const typesArr = this.get('types');
        if (!typesArr.length) { return; };

        typesArr.sort(function (a, b) // сортируем типы в алфавитном порядке их названий
        {
            const s1 = a.text.toLowerCase(); // переводим в нижний регистр для правильного порядка (иначе, например ETFs и Equities сортируется неверно)
            const s2 = b.text.toLowerCase();

            if (s1 == s2) { return 0; }

            if (!a.instrType) return -1; // instrType отсутствует у элемента All
            if (!b.instrType) return 1; // чтобы All всегда оставался в начале в независимости от локализации

            return s1 < s2 ? -1 : 1;
        });

        void this.set('types', typesArr);
    }

    /// Risk Disclosure
    private readonly showRiskDisclosureIfNeed = (onlyForTrSystemID?): void => {
        const riskDisclosureData = DataCache.tradingSystems;
        if (!riskDisclosureData) {
            return;
        };

        void this.set('allRiskDisclosuresAccepted', false);
        let tradingSystems = Object.keys(riskDisclosureData);

        if (onlyForTrSystemID) {
            tradingSystems = [onlyForTrSystemID];
        }

        for (let i = 0; i < tradingSystems.length; i++) {
            const trSystemID = tradingSystems[i];
            const trSystem = riskDisclosureData[trSystemID];

            if (trSystem && !trSystem.accepted) {
                this.showRiskDisclosureByTradingSystemID(trSystemID);
                return;
            }
        }

        void this.set('allRiskDisclosuresAccepted', true);
        this.allIdeasScrollAdd();
    };

    private showRiskDisclosureByTradingSystemID (tradingSystemID): void {
        const riskDisclosureData = DataCache.tradingSystems;
        if (!riskDisclosureData?.[tradingSystemID]) {
            return;
        }

        const riskDisclosureTitle = '\n' + Resources.getResource('panel.Ideas.riskDisclosure'); // TITLE: 'RISK DISCLOSURE'
        const riskDisclosureText = riskDisclosureData[tradingSystemID].riskDisclosureText; // Add Disclosure text

        void this.set({
            currentRiskDisclosureTradingSystemID: tradingSystemID,
            textRiskDisclosure: riskDisclosureText,
            titleRiskDisclosure: riskDisclosureTitle
        }); // save ID for agree btn
    };

    private agreeBtnClick (): void {
        const tradingSystemID = this.get('currentRiskDisclosureTradingSystemID');

        if (!tradingSystemID) {
            return;
        }

        const riskDisclosureData = DataCache.tradingSystems;
        if (!riskDisclosureData?.[tradingSystemID]) {
            return;
        }

        riskDisclosureData[tradingSystemID].accepted = true;
        DataCache.SendSubscriptionStrategyRequest(tradingSystemID);
        this.showRiskDisclosureIfNeed();
    }

    private selectTypeToFilter (e, typeI): void {
        if (this.selectedTypeIndex === typeI) {
            return;
        }

        void this.set('types[' + this.selectedTypeIndex + '].isSelected', false);
        void this.set('types[' + typeI + '].isSelected', true);

        this.selectedTypeIndex = typeI;

        void this.set('ideas', this.get('types[' + typeI + '].ideas'));
        this.allIdeasScrollAdd();
        void this.update();
    }

    private typesScrollAdd (): void {
        const allTypes = this.find('.ideasPanelSideBar-types-container');
        if (allTypes) {
            HtmlScrollXY.removeScroll(allTypes);
            HtmlScrollXY.addScroll(allTypes, 'x');
        }
    };

    onteardown (): void {
        this.eventsUnSubscribe();
    };
};

PanelSideBarBase.extendWith(IdeasPanelSideBar,
    {
        template: IdeasPanelSideBarTemplate,
        data: function () {
            return {
                types: [], // instrument types for horizontal scroller (filtering)
                ideas: [], // ideas to show in panel
                allIdeas: [], // all available ideas
                allRiskDisclosuresAccepted: true,
                currentRiskDisclosureTradingSystemID: null,
                textAgreeButton: '',
                textRiskDisclosure: '',
                titleRiskDisclosure: '',
                productTypeChangeConfirmationIsInFocus: false,
                noDataImg: 'ideas'
            };
        }
    });
