// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectTokenVerifyMessage } from '../../../../Utils/DirectMessages/DirectTokenVerifyMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TokenVerifyResponseMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectTokenVerifyMessage[] {
        const msg = new DirectTokenVerifyMessage();

        msg.Login = fs.GetValue(FieldsFactory.FIELD_LOGIN);
        msg.IsExpired = fs.GetValue(FieldsFactory.FIELD_IS_TOKEN_EXPIRED);
        msg.RequestID = fs.GetValue(FieldsFactory.FIELD_REQUEST_ID) || fs.GetValue(FieldsFactory.FIELD_SEQUANCE_ID);

        return [msg];
    }
}
