// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Account } from '../../Commons/cache/Account';
import { AccountFeature } from '../../Utils/Account/AccountFeature';
import { Resources } from '../../Localizations/Resources';
import { Control } from '../../Controls/elements/Control';
import { PortfolioAccountCardTemplate } from '../templates.js';
import { debounce } from '../Utils/InvestingUtils';
import Ractive from 'ractive';

export class PortfolioAccountCard extends Ractive {
    public Account: Account | null = null;
    public AssetBalance: any = null;
    public debounceHandler: any;

    public getType (): string { return 'PortfolioAccountCard'; }

    public override oninit (): void {
        this.observe('accountItem', this.populate);
    }

    public override oncomplete (): void {
        this.debounceHandler = debounce(this.UpdateData, 400);
        Control.Ticker.Subscribe(this.debounceHandler, this);

        this.populate();
    }

    public populate (): void {
        const account = this.get('accountItem');
        if (isNullOrUndefined(account)) return;

        this.Account = account;
        this.AssetBalance = account.assetBalanceDefault;

        this.UpdateData();
    }

    public changeChartEvent (bool): void {
        if (this.get('isPieChart') !== bool) {
            void this.set('isPieChart', bool);
        }
    }

    public getCashData (): void {
        const keys = Object.keys(AccountCashName);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const data = this.getAccountCashData(i);
            this.setFormattedValue(data.FormattedValue, data.Color, key);
        }
    }

    public onteardown (): void {
        Control.Ticker.UnSubscribe(this.debounceHandler, this);
    }

    public UpdateData (): void {
        if (!isNullOrUndefined(this.Account) && !isNullOrUndefined(this.AssetBalance)) {
            this.getCashData();
        }
    }

    public setFormattedValue (formattedValue, color, key): void {
        const sign = (0.1).toLocaleString().substr(1, 1);
        const arrFormat = formattedValue.split(sign);

        const wholeValue = arrFormat[0];
        const fractionallyValue = sign + arrFormat[1];

        void this.set('wholeValue' + key, wholeValue);
        void this.set('fractionallyValue' + key, fractionallyValue);
        void this.set('Change_Color_' + key, color);
    }

    public getAccountCashData (index): { Value: number, FormattedValue: string, Color: string } {
        const curAccount = this.Account;
        const AssetBalance = this.AssetBalance;

        const value = this.getAccountCashValue(index);
        const color = this.getAccountCashColor(value);
        let formattedValue = '';

        switch (index) {
        case AccountCashName.PendingCash:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.ProjectedBalance, curAccount, AssetBalance);
            break;

        case AccountCashName.OpenPnL:
            formattedValue = !isNaN(value) !== null ? AssetBalance.formatPrice(value) : Resources.getResource('general.Calculating');
            break;

        case AccountCashName.AvailableCash:
            formattedValue = Account.GetAccountFeatureString(value, AccountFeature.AvailableFunds, curAccount, AssetBalance);
            break;

        default:
            formattedValue = value === null || value === undefined ? '' : value.toString();
        }

        return { Value: value, FormattedValue: formattedValue, Color: color };
    }

    public getAccountCashValue (index: AccountCashName): number {
        const curAccount = this.Account;
        const AssetBalance = this.AssetBalance;

        switch (index) {
        case AccountCashName.PendingCash:
            return Account.GetAccountFeature(AccountFeature.ProjectedBalance, curAccount, AssetBalance);

        case AccountCashName.OpenPnL:
            return curAccount.ProfitNet ? curAccount.ProfitNet : 0;

        case AccountCashName.AvailableCash:
            return Account.GetAccountFeature(AccountFeature.AvailableFunds, curAccount, AssetBalance);

        default:
            return null;
        }
    }

    public getAccountCashColor (value): string {
        let color = 'green';
        if (value < 0) {
            color = 'red';
        }

        return color;
    }
}

enum AccountCashName {
    PendingCash = 0,
    OpenPnL = 1,
    AvailableCash = 2
};

Ractive.extendWith(PortfolioAccountCard, {
    template: PortfolioAccountCardTemplate,
    data: function () {
        return {
            isPieChart: true,
            accountItem: null
        };
    }
});
