// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { BaseInterval } from '../../../../../Utils/History/BaseInterval';
import { ReloadHistoryParams } from '../../../../../Utils/History/ReloadHistoryParams';
import { SessionInfo } from '../../../../../Utils/History/SessionInfo';
import { Periods, TFInfo } from '../../../../../Utils/History/TFInfo';
import { OptionPutCall } from '../../../../../Utils/Instruments/OptionPutCall';
import { DateTimeUtils } from '../../../../../Utils/Time/DateTimeUtils';
import { DataCache } from '../../../../DataCache';
import { type Account } from '../../../Account';
import { CashItem } from '../../../History/CashItem';
import { type Instrument } from '../../../Instrument';
import { OptionTraderUtils } from '../OptionTraderUtils';
import { HistoricalVolatilityData } from './HistoricalVolatilityData';
import { HistoricalVolatilityPriceType } from './HistoricalVolatilityPriceType';
import { StrikesAggregationStyle } from './StrikesAggregationStyle';
import { StrikesColoringMethod } from './StrikesColoringMethod';

export class OptionChain {
    private readonly HV_HISTORY_REQUEST_MS = 60 * 24 * 60 * 60 * 1000;
    private readonly STD_PERIOD = 21;
    private readonly VOLATILITY_PERIOD = 365;

    public strikesAggregationStyle: StrikesAggregationStyle = StrikesAggregationStyle.FromATMStrike;
    public coloringMethod: StrikesColoringMethod = StrikesColoringMethod.Classic;
    public historicalVolatilityPriceType: HistoricalVolatilityPriceType = HistoricalVolatilityPriceType.Last;

    public customUnderlierPrice: number = 0;
    public currentHVPercent: number = 0;

    public inTheMoneyColor: string = '';
    public outTheMoneyColor: string = '';
    public atmStrikeColor: string = '';
    public standardDeviationColor: string = '';
    public secondStandardDeviationColor: string = '';
    public askColor: string = '';
    public bidColor: string = '';

    public getColorInOutMoney (account: Account, underlier: Instrument, option: Instrument): string | undefined {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier) || isNullOrUndefined(option)) {
            return undefined;
        }
        return this.isInTheMoneyOption(account, underlier, option) ? this.inTheMoneyColor : this.outTheMoneyColor;
    }

    public isInTheMoneyOption (account: Account, underlier: Instrument, option: Instrument): boolean {
        const last = underlier.Level1.GetLastPrice(account);
        const price = isValidNumber(last) ? last : (underlier.Level1.GetBid(account) + underlier.Level1.GetAsk(account)) / 2;
        if (option.PutCall === OptionPutCall.OPTION_PUT_VANILLA) {
            return option.StrikePrice > price;
        } else {
            return option.StrikePrice < price;
        }
    }

    public isAllowHVColoring (underlier: Instrument): boolean {
        return !isNullOrUndefined(underlier) && !isNullOrUndefined(underlier.SaveQuotesHistory) && underlier.SaveQuotesHistory;
    }

    public async CalculateHistoricalVolatilityAsync (account: Account, underlier: Instrument): Promise<number> {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier)) {
            return 0;
        }

        const to = DateTimeUtils.DateTimeUtcNow();
        const from = new Date(to.getTime() - this.HV_HISTORY_REQUEST_MS);
        const tfInfo = new TFInfo();
        tfInfo.Periods = Periods.DAY;
        tfInfo.HistoryType = underlier.HistoryType;
        tfInfo.SessionInfo = new SessionInfo(false);
        tfInfo.Plan = DataCache.GetSpreadPlan(account);
        const historyParams = new ReloadHistoryParams();
        historyParams.updateWithProps({
            account,
            instrument: underlier,
            TimeFrameInfo: tfInfo,
            FromTime: from,
            ToTime: to,
            UseDefaultInstrumentHistoryType: false
        });
        const cashItem: CashItem = await CashItem.CreateWithHistory(underlier, historyParams);
        if (isNullOrUndefined(cashItem) || cashItem.FNonEmptyCashArrayCount <= this.STD_PERIOD) {
            return 0;
        }

        const lnData: number[] = [];
        const lastIdx = cashItem.FNonEmptyCashArrayCount - this.STD_PERIOD;
        for (let i = cashItem.FNonEmptyCashArrayCount - 1; i >= lastIdx; i--) {
            const curPrice = cashItem.FNonEmptyCashArray[i].Data[BaseInterval.CLOSE_INDEX];
            const prevPrice = cashItem.FNonEmptyCashArray[i - 1].Data[BaseInterval.CLOSE_INDEX];
            lnData.push(Math.log(curPrice / prevPrice));
        }

        // calc the standard deviation by the data
        const sumOfDerivationAverage = lnData.reduce((acc, value) => acc + value * value, 0) / this.STD_PERIOD;
        const average: number = lnData.reduce((acc, value) => acc + value, 0) / lnData.length;

        const standardDeviation = Math.sqrt(sumOfDerivationAverage - average * average);
        return standardDeviation * Math.sqrt(this.VOLATILITY_PERIOD) * 100;
    }

    public getHistoricalVolatilityData (account: Account, underlier: Instrument): HistoricalVolatilityData | undefined {
        if (isNullOrUndefined(account) || isNullOrUndefined(underlier)) {
            return undefined;
        }
        const daysToExpire: number = OptionTraderUtils.getDaysToExpirationForInstrument(underlier);
        const hvPrice = this.calculatePriceForHistoricalVolatility(account, underlier, this.historicalVolatilityPriceType);
        const lvl1Deviation1 = this.calculateDeviation(true, daysToExpire, hvPrice, 1);
        const lvl1Deviation2 = this.calculateDeviation(false, daysToExpire, hvPrice, 1);
        const lvl2Deviation1 = this.calculateDeviation(true, daysToExpire, hvPrice, 2);
        const lvl2Deviation2 = this.calculateDeviation(false, daysToExpire, hvPrice, 2);
        const data = new HistoricalVolatilityData();
        data.lastPrice = underlier.Level1.GetLastPrice(account);
        data.lvl1Left = Math.min(lvl1Deviation1, lvl1Deviation2);
        data.lvl1Right = Math.max(lvl1Deviation1, lvl1Deviation2);
        data.lvl2Left = Math.min(lvl2Deviation1, lvl2Deviation2);
        data.lvl2Right = Math.max(lvl2Deviation1, lvl2Deviation2);
        return data;
    }

    private calculatePriceForHistoricalVolatility (account: Account, underlier: Instrument, type: HistoricalVolatilityPriceType): number {
        switch (type) {
        case HistoricalVolatilityPriceType.Last:
            return underlier.Level1.GetLastPrice(account);
        case HistoricalVolatilityPriceType.BidAsk:
            return (underlier.Level1.GetAsk(account) + underlier.Level1.GetBid(account)) / 2;
        case HistoricalVolatilityPriceType.OHL:
        {
            const open = underlier.InstrumentDayInfo.getOpen(account);
            const high = underlier.InstrumentDayInfo.getHigh(account);
            const low = underlier.InstrumentDayInfo.getLow(account);
            return (open + high + low) / 3;
        }
        case HistoricalVolatilityPriceType.OHLC:
        {
            const open = underlier.InstrumentDayInfo.getOpen(account);
            const high = underlier.InstrumentDayInfo.getHigh(account);
            const low = underlier.InstrumentDayInfo.getLow(account);
            const last = underlier.Level1.GetLastPrice(account);
            return (open + high + low + last) / 4;
        }
        default:
            return 0;
        }
    }

    private calculateDeviation (isTop: boolean, daysToExpire: number, hvPrice: number, multiplier: number): number {
        if (daysToExpire <= 0) {
            return 0;
        }
        const hv = this.currentHVPercent / 100;
        const res = multiplier * (hv / Math.sqrt(this.VOLATILITY_PERIOD) * Math.sqrt(daysToExpire));
        return hvPrice * (1 + (isTop ? res : -res));
    }
}
