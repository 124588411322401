// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type Instrument } from '../Instrument';

export class EntitlmentProductDefinition {
    Type: BasisType;
    InstrumentIds: number[];
    InstrumentGroups: number[];
    TradingExchanges: string[];
    IsDataSource: boolean;
    IsDelay: boolean;
    IsSnapshot: boolean;
    IsL1: boolean;
    IsMD: boolean;
    IsL3: boolean;

    IsAllowDataSourceForInstrument (instrument: Instrument): boolean {
        if (!this.IsDataSource) { return false; }

        if (instrument == null) { return false; }

        return this.IsExistDefinition(instrument.Id, instrument.TypeId, instrument.TradingExchange);
    }

    IsExistDefinition (instrumentId: string, instrumentGroupId: number, tradingExchange: string): boolean {
        const instId = parseInt(instrumentId);

        switch (this.Type) {
        case BasisType.Instrument:
            if (isNaN(instId)) { return false; }
            return this.InstrumentIds.includes(instId);

        case BasisType.InstrumentGroup:
            return this.InstrumentGroups.includes(instrumentGroupId);

        case BasisType.TradingExchange:
            return this.TradingExchanges.includes(tradingExchange);

        default:
            return false;
        }
    }
}

export enum BasisType { TradingExchange = 0, InstrumentGroup = 1, Instrument = 2 };
