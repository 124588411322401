// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MathUtils } from '../MathUtils';
import { Resources } from '../../Localizations/Resources';

class _VolumeFormatter {
    getVolumeFormat (volume: number, isAbbreviateVolumes: boolean = false, abbreviatePrecision: number = 1): string {
        if (volume === null || isNaN(volume)) {
            return Resources.getResource('general.N_A');
        }

        if (isAbbreviateVolumes) {
            let abbreviate = '';
            if (Math.abs(volume) >= 1000000000000) {
                volume /= 1000000000000;
                abbreviate = 'T';
            } else if (Math.abs(volume) >= 1000000000) {
                volume /= 1000000000;
                abbreviate = 'B';
            } else if (Math.abs(volume) >= 1000000) {
                volume /= 1000000;
                abbreviate = 'M';
            } else if (Math.abs(volume) >= 1000) {
                volume /= 1000;
                abbreviate = 'K';
            }

            if (abbreviate !== '') {
                return `${MathUtils.TruncateDouble(volume, abbreviatePrecision)}${abbreviate}`;
            }
        }

        return volume.toString();
    }
}

export const VolumeFormatter = new _VolumeFormatter();
