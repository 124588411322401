// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraChartBaseRenderer } from '../TerceraChartBaseRenderer';
import { Font, Pen, PenStyle, SolidBrush } from '@shared/commons/Graphics';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { type DynProperty } from '@shared/commons/DynProperty';
import { type TerceraChartBase } from '../../TerceraChartBase';

export class TerceraChartBaseScaleRenderer<Chart extends TerceraChartBase = TerceraChartBase, ScaleRendererSettings extends TerceraChartBaseScaleRendererSettings = TerceraChartBaseScaleRendererSettings> extends TerceraChartBaseRenderer<Chart> {
    settings: ScaleRendererSettings;

    constructor (settings: ScaleRendererSettings, terceraChart: Chart) {
        super(terceraChart);
        this.settings = settings;
        // this.Orientation;
        this.SetClassName('TerceraChartBaseScaleRenderer');
    }

    public GetPreferredHeight (): number {
        return this.settings.ScaleFont.Height + 4;
    }

    public FormatValue (value: number): string {
        return value.toString();
    }
}

// TerceraChartBaseScaleRenderer.prototype = Object.create(TerceraChartBaseRenderer.prototype);

export class TerceraChartBaseScaleRendererSettings {
    textSeparatorBrush: SolidBrush;
    HighlightMarkingsStep: any;
    PercentView: any;
    Asset: any;

    AutoScaleSwitcherVisible: boolean = false;

    UseCustomYMarkings: any;
    CustomYMarkingValue: number;

    scaleGridColor: string = ThemeManager.CurrentTheme.Chart_GridPriceColor;
    scaleGridStyle: number = PenStyle.IsoDotChart;
    scaleGridWidth: number = 1;
    scaleGridPen: Pen = new Pen(this.scaleGridColor, this.scaleGridWidth, PenStyle.ShapedChart);
    ScaleGridVisibility: boolean = true;
    scaleAxisColor: string = ThemeManager.CurrentTheme.Chart_PriceScaleAxisColor;
    scaleAxisPen: Pen = new Pen(this.scaleAxisColor, 1);
    scaleBackColor: string = ThemeManager.CurrentTheme.Chart_PriceScaleBackColor;
    scaleBackBrush: SolidBrush = new SolidBrush(this.scaleBackColor);
    scaleBackBrushShadow: SolidBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_PriceScaleBackColorSdadow);
    scaleTextColor: string = ThemeManager.CurrentTheme.Chart_PriceScaleTextColor;
    scaleTextBrush: SolidBrush = new SolidBrush(this.scaleTextColor);
    ScaleFont: Font = new Font(11, ThemeManager.customFontFamily);
    AxisColorContour: string = ThemeManager.CurrentTheme.Chart_AxisColorContour;
    AxisPenContour: Pen = new Pen(this.AxisColorContour, 1);
    TimeScaleImportantDatesFont: Font;
    DaySeparatorsVisibility: boolean;
    daySeparatorsPen: Pen;
    dayBorderSeparatorsPen: Pen;
    WeekSeparatorsVisibility: boolean;
    weekSeparatorsPen: Pen;
    weekBorderSeparatorsPen: Pen;
    MonthSeparatorsVisibility: boolean;
    monthSeparatorsPen: Pen;
    monthBorderSeparatorsPen: Pen;
    YearSeparatorsVisibility: boolean;
    yearSeparatorsPen: Pen;
    yearBorderSeparatorsPen: Pen;

    get ScaleGridColor (): string {
        return this.scaleGridColor;
    }

    set ScaleGridColor (value: string) {
        this.scaleGridColor = value;
        this.scaleGridPen.Color = value;
    }

    get ScaleGridStyle (): number {
        return this.scaleGridStyle;
    }

    set ScaleGridStyle (value: number) {
        this.scaleGridStyle = value;
        this.scaleGridPen.DashStyle = this.scaleGridStyle;
    }

    get ScaleGridWidth (): number {
        return this.scaleGridWidth;
    }

    set ScaleGridWidth (value: number) {
        this.scaleGridWidth = value;
        this.scaleGridPen.Width = this.scaleGridWidth;
        this.scaleGridPen.DashStyle = this.scaleGridStyle;
    }

    get ScaleAxisColor (): string {
        return this.scaleAxisColor;
    }

    set ScaleAxisColor (value: string) {
        this.scaleAxisColor = value;
        this.scaleAxisPen.Color = value;
    }

    get ScaleBackColor (): string {
        return this.scaleBackColor;
    }

    set ScaleBackColor (value: string) {
        this.scaleBackColor = value;
        this.scaleBackBrush.Color = value;
    }

    get ScaleTextColor (): string {
        return this.scaleTextColor;
    }

    set ScaleTextColor (value: string) {
        this.scaleTextColor = value;
        this.scaleTextBrush.Color = value;
    }

    ThemeChanged (): void {
        this.ScaleAxisColor = ThemeManager.CurrentTheme.Chart_PriceScaleAxisColor;
        this.ScaleBackColor = ThemeManager.CurrentTheme.Chart_PriceScaleBackColor;
        this.ScaleTextColor = ThemeManager.CurrentTheme.Chart_PriceScaleTextColor;
        this.ScaleGridColor = ThemeManager.CurrentTheme.Chart_GridPriceColor;
    }

    setScaleBackColor (color: string): void {
        this.scaleBackColor = color;
        this.scaleBackBrush.Color = color;
    }

    setScaleAxisColor (color: string): void {
        this.scaleAxisColor = color;
        this.scaleAxisPen.Color = color;
    }

    Properties (): DynProperty[] {
        return [];
    }

    callBack (properties: DynProperty[]): void {

    }
}
