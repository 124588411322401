// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PFixHistoryResponseMessage extends PFixMessage {
    constructor (fieldSet: PFixFieldSet) {
        super(Message.CODE_PFIX_HISTORY_RESP, fieldSet);
    }

    public GetIsHistoryLimitReached (): boolean {
        const fs = this.FieldSet;
        return fs.GetValue(FieldsFactory.FIELD_QUOTE_HISTORY_LIMIT_REACHED) || false;
    }
}
