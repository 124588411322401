// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import Ractive from 'ractive';

import { PortfolioAccountCard } from './Cards/PortfolioAccountCard';
import { InvestingButton } from './Controls/elements/InvestingButton';
import { InvestingPositionsPanel } from './Panels/InvestingPositionsPanel';
import { InvestingOrdersPanel } from './Panels/InvestingOrdersPanel';
import { InvestingAssetsPanel } from './Panels/InvestingAssetsPanel';
import { PortfolioInvestingPanel } from './Panels/PortfolioInvestingPanel';
import { PortfolioManagerPanel } from './Panels/PortfolioManagerPanel';
import { InvestingTableRow } from './TablesPrimitives/InvestingTableRow';
import { InvestingHeaderTableRow } from './TablesPrimitives/InvestingHeaderTableRow';
import { InvestingLoginScreen } from './Screen/InvestingLoginScreen';
import { InvestingTextBox } from './Controls/elements/InvestingTextBox';
import { InvestingForgotPasswordScreen } from './Screen/InvestingForgotPasswordScreen';
import { InvestingChangePasswordScreen } from './Screen/InvestingChangePasswordScreen';
import { InvestingComboBox } from './Controls/elements/InvestingComboBox';
import { InvestingCheckBox } from './Controls/elements/InvestingCheckBox';
import { InvestingMenu } from './Controls/elements/InvestingMenu';
import { WatchlistInvestingPanel } from './Panels/WatchlistInvestingPanel';
import { InvestingChartPanel } from './Chart/InvestingChartPanel';
import { InvestingPieChart } from './Chart/InvestingPieChart';
import { InvestingOE } from './Screen/InvestingOE';
import { InvestingDataSourceScreen } from './Screen/InvestingDataSourceScreen';
import { InvestingNumeric } from './Controls/elements/InvestingNumeric';
import { InvestingQuantityNumeric } from './Controls/elements/InvestingQuantityNumeric';
import { InvestingLookup } from './Controls/elements/InvestingLookup';
import { InvestingLookupDropDownForm } from './Screen/InvestingLookupDropDownForm';
import { InvestingLookupDropDownFormItem } from './Controls/elements/InvestingLookupDropDownFormItem';
import { InvestingChartSmall } from './Chart/InvestingChartSmall';
import { InvestingAdvancedChartPanel } from './Chart/InvestingAdvancedChartPanel';
import { InvestingPasswordTextBox } from './Controls/elements/InvestingPasswordTextBox';
Ractive.components.portfolioAccountCard = PortfolioAccountCard;
Ractive.components.investingButton = InvestingButton;
Ractive.components.investingPositionsPanel = InvestingPositionsPanel;
Ractive.components.investingOrdersPanel = InvestingOrdersPanel;
Ractive.components.investingAssetsPanel = InvestingAssetsPanel;
Ractive.components.portfolioInvestingPanel = PortfolioInvestingPanel;
Ractive.components.portfolioManagerPanel = PortfolioManagerPanel;
Ractive.components.investingTableRow = InvestingTableRow;
Ractive.components.investingHeaderTableRow = InvestingHeaderTableRow;
Ractive.components.investingLoginScreen = InvestingLoginScreen;
Ractive.components.investingTextBox = InvestingTextBox;
Ractive.components.investingForgotPasswordScreen = InvestingForgotPasswordScreen;
Ractive.components.investingChangePasswordScreen = InvestingChangePasswordScreen;
Ractive.components.investingComboBox = InvestingComboBox;
Ractive.components.investingCheckBox = InvestingCheckBox;
Ractive.components.investingMenu = InvestingMenu;
Ractive.components.watchlistInvestingPanel = WatchlistInvestingPanel;
Ractive.components.investingChartPanel = InvestingChartPanel;
Ractive.components.investingPieChart = InvestingPieChart;
Ractive.components.investingOE = InvestingOE;
Ractive.components.investingDataSourceScreen = InvestingDataSourceScreen;
Ractive.components.investingNumeric = InvestingNumeric;
Ractive.components.investingQuantityNumeric = InvestingQuantityNumeric;
Ractive.components.investingLookup = InvestingLookup;
Ractive.components.investingLookupDropDownForm = InvestingLookupDropDownForm;
Ractive.components.investingLookupDropDownFormItem = InvestingLookupDropDownFormItem;
Ractive.components.investingChartSmall = InvestingChartSmall;
Ractive.components.investingAdvancedChartPanel = InvestingAdvancedChartPanel;
Ractive.components.investingPasswordTextBox = InvestingPasswordTextBox;
