// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { closeLookupDropDownForm } from '../../Screen/InvestingLookupDropDownForm';
import { InvestingLookupDropDownFormItemTemplate } from '../../templates.js';
import Ractive from 'ractive';

export class InvestingLookupDropDownFormItem extends Ractive {
    constructor () { super(); }

    public getType (): string { return 'InvestingLookupDropDownFormItem'; };

    public oninit (): void {
        this.on('openAdvancedChartDropDown', this.openAdvancedChart);
    }

    public oncomplete (): void {
        this.observe('itemData', (newV, oldV) => {
            if (!newV) {
                return;
            }

            void this.set({ addInstrumentWL: !newV.IsPresent_in_WL });
        });
    }

    public AddRemoveClick (isAdd: boolean): void {
        this.fire(ITEM_EVENT, this.get('itemData'), isAdd ? ADD_WL : REMOVE_WL);
        void this.set('addInstrumentWL', !isAdd);
    }

    public starTradingOperation (isBuy: boolean): void {
        this.fire(ITEM_EVENT, this.get('itemData'), isBuy ? BUY : SELL);
        closeLookupDropDownForm();
    }

    public openAdvancedChart (): void {
        this.fire(ITEM_EVENT, this.get('itemData'), OPEN_CHART);
        closeLookupDropDownForm();
    }
}

export const ITEM_EVENT = 'itemEvent';
export const OPEN_CHART = 1;
export const ADD_WL = 2;
export const REMOVE_WL = 3;
export const BUY = 4;
export const SELL = 5;

Ractive.extendWith(InvestingLookupDropDownFormItem, {
    template: InvestingLookupDropDownFormItemTemplate,
    data: function () {
        return {
            itemData: {},
            addInstrumentWL: true
        };
    }
});
