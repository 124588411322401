// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ArrayUtils } from '../../Utils/ArrayUtils';
import { Message } from '../../Utils/DirectMessages/DirectMessagesImport';
import { Connection } from '../Connection';
import { Algorithm, AlgorithmSubscribeType } from './Algorithm/Algorithm';

export class AlgorithmCache {
    private algorithmsAvailableByID: Record<number, Algorithm> = {};
    private algorithmsAvailableByType: Record<number, Algorithm[]> = {};
    private algorithmSubscribersByAlgorithmId: Record<number, any> = {};
    private algorithmMessagesCacheByAlgorithmId: Record<number, any> = {};
    private readonly DCache: any;

    constructor (dCache) {
        this.DCache = dCache;
    }

    public SubscriptionHandler (subscribe: number, algorithmID, subscriber): void {
        let NeedProc = false;
        if (subscribe === AlgorithmSubscribeType.Subscribe) {
            let subscribers = this.algorithmSubscribersByAlgorithmId[algorithmID];
            if (!subscribers) {
                subscribers = [];
            }

            NeedProc = !subscribers.length;
            subscribers.push(subscriber);
            this.algorithmSubscribersByAlgorithmId[algorithmID] = subscribers;
            const msg = this.algorithmMessagesCacheByAlgorithmId[algorithmID];
            if (msg) {
                subscriber.ProcessAlgorithm(msg);
            }
        } else {
            const subscribers = this.algorithmSubscribersByAlgorithmId[algorithmID];
            if (subscribers) {
                ArrayUtils.RemoveElementFromArray(subscribers, subscriber);
                NeedProc = !subscribers.length;
            }
        }

        if (NeedProc) {
            Connection.vendor.AlgorithmSubscribeMessage(subscribe, algorithmID);
        }
    }

    public NewMessage (msg): void {
        switch (msg.Code) {
        case Message.CODE_PFIX_ALGORITHMS_AVAILABLE_MESSAGE:
            this.ProcessAlgorithmsAvailableMessage(msg);
            break;
        case Message.CODE_PFIX_ALGORITHM_SUBSCRIBE_MESSAGE:
            // TODO Subscribe/Unsubscibe result from server
            break;
        case Message.CODE_PFIX_ALGORITHM_MARKET_CONSENSUS_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_GAINERS_LOSERS_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_HIGH_LOW_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_TOP_VOLUME_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_HISTORICAL_GAINER_LOSER_TOPS_MESSAGE:
        case Message.CODE_PFIX_ALGORITHM_HEATMAP_MESSAGE:
            this.ProcessAlgorithmMessage(msg);
            break;
        }
    }

    public ProcessAlgorithmsAvailableMessage (msg): void {
        if (!msg) {
            return;
        }

        const myAlgorithm = this.getAlgorithmByAlgorithmId(msg.AlgorithmId); // search in created
        if (myAlgorithm) // if already exist
        {
            myAlgorithm.UpdateByMessage(msg);
        } // update algorithm
        else // if have not exist yet
        {
            this.addAlgorithmId(new Algorithm(msg));
        } // create new algorithm and add it to AlgorithmCache

        // if (msg.AlgorithmType != 2)
        //     this.SubscriptionHandler(AlgorithmSubscribeType.Subscribe, msg.AlgorithmId, this)
    }

    public ProcessAlgorithm (msg): any // for test
    {

    }

    public ProcessAlgorithmMessage (msg): void {
        if (!msg) {
            return;
        }

        const subscribers = this.algorithmSubscribersByAlgorithmId[msg.AlgorithmId];
        if (subscribers) {
            for (let i = 0; i < subscribers.length; i++) {
                subscribers[i].ProcessAlgorithm(msg);
            }
        }

        this.algorithmMessagesCacheByAlgorithmId[msg.AlgorithmId] = msg;
    }

    public addAlgorithmId (algorithm): void {
        this.algorithmsAvailableByID[algorithm.AlgorithmId] = algorithm;

        const arr = this.getAlgorithmsByAlgorithmType(algorithm.AlgorithmType);
        arr.push(algorithm);
        this.algorithmsAvailableByType[algorithm.AlgorithmType] = arr;
    }

    public clear (): void {
        this.algorithmsAvailableByType = {};
        this.algorithmMessagesCacheByAlgorithmId = {};
        this.algorithmSubscribersByAlgorithmId = {};
        this.algorithmsAvailableByID = {};
    }

    public getAlgorithmsByAlgorithmType (algorithmType: number): Algorithm[] {
        return this.algorithmsAvailableByType[algorithmType] || [];
    }

    public getAlgorithmByAlgorithmId (algorithmId: number): Algorithm | null {
        return this.algorithmsAvailableByID[algorithmId] || null;
    }
}
