// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { SessionOperations } from '../Enums/Constants';
import { type DirectTradeSessionStatusMessage } from '../DirectMessages/DirectMessagesImport';
import { type OrderType } from '../Trading/OrderType';

export class TradeSessionStatus {
    public Id: number;
    public Name: string;
    public AllowedOrderTypes: OrderType[];
    public AllowedOperations: any; // ?
    public IsDecreaseOnlyPositionCount: boolean;

    constructor (msg: DirectTradeSessionStatusMessage) {
        this.Id = msg.Id;
        this.Name = '';
        this.AllowedOrderTypes = null;
        this.AllowedOperations = SessionOperations.None;
        this.IsDecreaseOnlyPositionCount = false;
        this.update(msg);
    }

    public update (msg: DirectTradeSessionStatusMessage): void {
        if (isNullOrUndefined(msg)) {
            return;
        }

        this.Name = msg.Name;
        this.IsDecreaseOnlyPositionCount = msg.IsDecreaseOnlyPositionCount;
        this.AllowedOperations = msg.AllowedOperations;
        this.AllowedOrderTypes = msg.AllowedOrderTypes;
    }
}

// #endregion TradeSessionStatus
