// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '../../Localizations/Resources';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { ThemeManager } from '../misc/ThemeManager';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type Account } from '../../Commons/cache/Account';
import { type Statement } from '../../Commons/cache/Statement';

export class StatementItem extends BaseItem {
    public statement: Statement;

    static readonly ACCOUNT_COL_INDEX = 0;
    static readonly INSTRUMENT_COL_INDEX = 1;
    static readonly DATE_COL_INDEX = 2;
    static readonly OPERATION_TYPE_COL_INDEX = 3;
    static readonly OPERATION_ID_COL_INDEX = 4;
    static readonly AMOUNT_COL_INDEX = 5;
    static readonly OPEN_PRICE_COL_INDEX = 6;
    static readonly CLOSE_PRICE_COL_INDEX = 7;
    static readonly QUANTITY_COL_INDEX = 8;
    static readonly CURRENCY_COL_INDEX = 9;
    static readonly TRADE_OPENING_DATE_COL_INDEX = 10;
    static readonly OPEN_CROSS_PRICE_COL_INDEX = 11;
    static readonly POSITION_ID_COL_INDEX = 12;
    static readonly TRADE_ID_COL_INDEX = 13;

    static readonly columnsHeaderLocKey = {
        [StatementItem.ACCOUNT_COL_INDEX]: 'panel.statement.Account',
        [StatementItem.INSTRUMENT_COL_INDEX]: 'panel.statement.Instrument',
        [StatementItem.DATE_COL_INDEX]: 'panel.statement.Date',
        [StatementItem.OPERATION_TYPE_COL_INDEX]: 'panel.statement.Operation_type',
        [StatementItem.OPERATION_ID_COL_INDEX]: 'panel.statement.Operation_ID',
        [StatementItem.AMOUNT_COL_INDEX]: 'panel.statement.Amount',
        [StatementItem.OPEN_PRICE_COL_INDEX]: 'panel.statement.Open_price',
        [StatementItem.CLOSE_PRICE_COL_INDEX]: 'panel.statement.Close_price',
        [StatementItem.QUANTITY_COL_INDEX]: 'panel.statement.Quantity',
        [StatementItem.CURRENCY_COL_INDEX]: 'panel.statement.Currency',
        [StatementItem.TRADE_OPENING_DATE_COL_INDEX]: 'panel.statement.Trade_opening_date',
        [StatementItem.OPEN_CROSS_PRICE_COL_INDEX]: 'panel.statement.FX_rate',
        [StatementItem.POSITION_ID_COL_INDEX]: 'panel.statement.positionID',
        [StatementItem.TRADE_ID_COL_INDEX]: 'panel.statement.tradeID'
    };

    static readonly columnsParams: ColumnParams[] = [
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.ACCOUNT_COL_INDEX], 75, QuickTableColumnType.COL_DEFAULT, false, true, true),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.INSTRUMENT_COL_INDEX], 75, QuickTableColumnType.COL_DEFAULT, false, true, true),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.DATE_COL_INDEX], 75, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.OPERATION_TYPE_COL_INDEX], 75, QuickTableColumnType.COL_DEFAULT, false, true, true),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.OPERATION_ID_COL_INDEX], 75, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.AMOUNT_COL_INDEX], 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.OPEN_PRICE_COL_INDEX], 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.CLOSE_PRICE_COL_INDEX], 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.QUANTITY_COL_INDEX], 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.CURRENCY_COL_INDEX], 75, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.TRADE_OPENING_DATE_COL_INDEX], 70, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.OPEN_CROSS_PRICE_COL_INDEX], 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.POSITION_ID_COL_INDEX], 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, true),
        new ColumnParams(StatementItem.columnsHeaderLocKey[StatementItem.TRADE_ID_COL_INDEX], 75, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false)
    ];

    constructor (statement: Statement, sessionSettings: SessionSettingsType) {
        super(sessionSettings);
        this.statement = statement;
        const tm = ThemeManager.CurrentTheme;
        this.CellForeColorMap[StatementItem.AMOUNT_COL_INDEX] = tm.TableGoldColor;
        this.CellForeColorMap[StatementItem.QUANTITY_COL_INDEX] = tm.TableGoldColor;
    }

    public override ColumnCount (): number {
        return StatementItem.columnsParams.length;
    }

    public override getColumnData (column: number): ColumnData {
        let value = null;
        let formattedValue = null;

        if (this.statement === null) {
            value = null;
            formattedValue = '';
        }

        value = this.getColumnValue(column);

        switch (column) {
        case StatementItem.DATE_COL_INDEX:
        case StatementItem.TRADE_OPENING_DATE_COL_INDEX:
            formattedValue = isNaN(value.getTime()) ? Resources.getResource('general.N_A') : DateTimeUtils.formatDate(value, 'DD.MM.YYYY  HH:mm:ss');
            break;
        case StatementItem.AMOUNT_COL_INDEX:
            formattedValue = this.formatAmount(value);
            break;

        default:
            formattedValue = value != null ? value.toString() : '';
            break;
        }

        return new ColumnData(value, formattedValue, this.GetGroupByValue(value, column));
    }

    public override getColumnValue (column: number): any {
        const statement = this.statement;

        if (statement === null) { return null; }

        switch (column) {
        case StatementItem.ACCOUNT_COL_INDEX:
            return statement.Account;
        case StatementItem.INSTRUMENT_COL_INDEX:
            return statement.InstrumentStr;
        case StatementItem.DATE_COL_INDEX:
            return statement.Date;
        case StatementItem.OPERATION_TYPE_COL_INDEX:
            return statement.OperationType;
        case StatementItem.OPERATION_ID_COL_INDEX:
            return statement.OperationID;
        case StatementItem.AMOUNT_COL_INDEX:
            return statement.Amount;
        case StatementItem.OPEN_PRICE_COL_INDEX:
            return statement.OpenPrice;
        case StatementItem.CLOSE_PRICE_COL_INDEX:
            return statement.ClosePrice;
        case StatementItem.QUANTITY_COL_INDEX:
            return statement.Quantity;
        case StatementItem.CURRENCY_COL_INDEX:
            return statement.Currency;
        case StatementItem.TRADE_OPENING_DATE_COL_INDEX:
            return statement.TradeOpeningDate;
        case StatementItem.OPEN_CROSS_PRICE_COL_INDEX:
            return statement.OpenCrossPrise;
        case StatementItem.POSITION_ID_COL_INDEX:
            return statement.PositionID;
        case StatementItem.TRADE_ID_COL_INDEX:
            return statement.TradeId;
        default:
            return null;
        }
    }

    public override GetCurrentAccount (): Account {
        return this.statement !== null ? this.statement.Account : null;
    }

    public override getFormatTotalValue (column: number, value, precisionMode = false): string | number {
        let formattedValue = '';

        switch (column) {
        case StatementItem.AMOUNT_COL_INDEX:
        case StatementItem.QUANTITY_COL_INDEX:
            if (precisionMode) { return 2; }
            formattedValue = value.toFixed(2);
            break;
        }

        return formattedValue;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = StatementItem.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey);
        result.Hidden = isHidden;
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        return result;
    }

    static readonly ListMenuIndex = Object.values(StatementItem.columnsHeaderLocKey);

    public GetMenuIndex (headerKey: string): number {
        if (StatementItem.ListMenuIndex.includes(headerKey)) {
            return StatementItem.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    public get Account (): Account { return this.statement?.Account; }

    private formatAmount (value: number): string {
        if (this.Account != null) {
            return this.Account.formatPrice(value, true);
        }

        return value != null ? value.toString() : '';
    }

    private GetGroupByValue (value, columnIndex: number): string {
        if (value == null || value === Resources.getResource('general.N_A')) {
            return Resources.getResource('StatementItem.PositionID.GroupKey.EmptyValue');
        }

        switch (columnIndex) {
        case StatementItem.POSITION_ID_COL_INDEX:
            return Resources.getResource('StatementItem.PositionID.GroupKey.Format').replace('{0}', value);

        default:
            return value;
        }
    }
}
