// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { BaseItem, ColumnData } from '../BaseItem';
import { ColumnParams } from '../ColumnParams';
import { QuickTableEditingInfo } from '../../elements/QuickTable/QuickTableMisc';
import { DynProperty } from '@shared/commons/DynProperty';
import { type TerceraChartOverlay } from '@front/chart/TerceraChartOverlay';
import { type IObjectManagerItem } from '@shared/commons/cache/interface/IObjectManagerItem';

export class OverlaysManagerItem extends BaseItem implements IObjectManagerItem<TerceraChartOverlay> {
    public readonly Overlay: TerceraChartOverlay;

    constructor (overlay: TerceraChartOverlay) {
        super();
        this.Overlay = overlay;

        const visibilityButton = new QuickTableEditingInfo(DynProperty.VISIBILITY_BUTTON);
        visibilityButton.TooltipKey = 'ObjectManagerItem.Visible.ToolTip';
        this.QuickTableEditingInfoMap[OverlaysManagerItem.VISIBLE_COL_INDEX] = visibilityButton;

        const settingsButton = new QuickTableEditingInfo(DynProperty.SETTINGS_BUTTON);
        settingsButton.TooltipKey = 'ObjectManagerItem.Settings.ToolTip';
        this.QuickTableEditingInfoMap[OverlaysManagerItem.SETTINGS_COL_INDEX] = settingsButton;

        const closeButton = new QuickTableEditingInfo(DynProperty.CLOSE_BUTTON);
        closeButton.TooltipKey = 'ObjectManagerItem.Remove.ToolTip';
        this.QuickTableEditingInfoMap[OverlaysManagerItem.REMOVE_COL_INDEX] = closeButton;
    }

    public getItemSubject (): TerceraChartOverlay {
        return this.Overlay;
    }

    public override ColumnCount (): number {
        return OverlaysManagerItem.columnsParams.length;
    }

    public override GetColumnParams (i: number): ColumnParams {
        return OverlaysManagerItem.columnsParams[i];
    }

    public GetMenuIndex (headerKey: string): number {
        if (OverlaysManagerItem.ListMenuIndex[headerKey]) {
            return OverlaysManagerItem.ListMenuIndex[headerKey];
        } else {
            return 1000;
        }
    }

    public override getColumnValue (column: number): any {
        switch (column) {
        case OverlaysManagerItem.NAME_COL_INDEX:
            return this.Overlay.ActiveInstrument.DisplayName();
        case OverlaysManagerItem.VISIBLE_COL_INDEX:
            return this.Overlay.mainPriceRenderer.Visible;
        default:
            return '';
        }
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let FormattedValue = null;

        switch (column) {
        case OverlaysManagerItem.NAME_COL_INDEX:
            FormattedValue = value;
            break;
        }

        return new ColumnData(value, FormattedValue);
    }

    static NAME_COL_INDEX = 0;
    static VISIBLE_COL_INDEX = 1;
    static SETTINGS_COL_INDEX = 2;
    static REMOVE_COL_INDEX = 3;

    static columnsParams =
        [
            new ColumnParams('ObjectManagerItem.Name', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Visible', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Settings', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Remove', 70, 0, false, true, false)
        ];

    public static readonly ListMenuIndex = [
        'ObjectManagerItem.Name',
        'ObjectManagerItem.Visible',
        'ObjectManagerItem.Settings',
        'ObjectManagerItem.Remove'];
}
