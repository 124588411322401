// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Message } from '../../Utils/DirectMessages/Message';
import { type ServerMessaeContainer } from '../../Utils/FullLogs/ServerMessageContainer';
import { Connection } from '../Connection';

export class FullLogsHelper {
    private msgCodes: number[] = [];

    constructor () {
        this.OnNewMessageHandler = this.OnNewMessageHandler.bind(this);
    }

    public start (...msgCodes: number[]): void {
        this.msgCodes = msgCodes;
        Connection.SubscribeOnFullLoging(this.OnNewMessageHandler);
    }

    public stop (): void {
        Connection.UnSubscribeOnFullLoging();
    }

    public getMessages (): void {
        const msgDict = Connection.GetMessagesList();
        for (const key in msgDict) {
            console.warn(msgDict[key] + '(' + key + ')');
        }
    }

    private OnNewMessageHandler (info: ServerMessaeContainer): void {
        if (this.msgCodes.length > 0 &&
            !this.msgCodes.includes(info.MessageCode) &&
            info.MessageCode !== Message.CODE_BUSINESS_REJECT_MESSAGE) {
            return;
        }
        const msgName = info.MessageName + ' (' + info.MessageCode + ')';
        const fieldsTxt = info.Text;
        const fieldsNewTxt = info.TextNew;
        console.warn(`${msgName} ### ${fieldsTxt} ### ${fieldsNewTxt}`);
    }
}

export const flh = new FullLogsHelper();
