// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Level1ItemType } from '../../Utils/Enums/Level1ItemType';

export class Level1MenuItem {
    text: string;
    tag: Level1ItemType;
    event: any;
    checked: boolean;
    readonly canCheck: boolean = true;

    constructor (text: string, tag: Level1ItemType, event, checked = false) {
        this.text = text;
        this.tag = tag;
        this.event = event;
        this.checked = checked;
    }
}
