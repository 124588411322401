// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { TerceraProductsComponent } from '../../templates.js';
import { EntitlementSubscribeDocumentScreen } from '../screen/EntitlementSubscribeDocumentScreen';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { Control } from './Control';
import { ThemeManager } from '../misc/ThemeManager';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { ConfirmationTypesEnum } from '../../Utils/Trading/ConfirmationTypesEnum';
import { EntitlementManager } from '../../Commons/cache/Entitlement/EntitlementManager';
import { DataCache } from '../../Commons/DataCache';
import { ApplicationInfo } from '../../Commons/ApplicationInfo';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';
import { ResourcesManager } from '../../Commons/properties/ResourcesManager';

export class ProductsComponent extends Control {
    public static readonly Zero_formater = new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    public static readonly FREE_PRODUCT_VALUE = 'FREE';

    public ProductData: any = null;
    public DefaultImage: any = null;
    public myMsgBox: any = null;
    public myDocumentScreen: any = null;

    constructor () { super(); }

    public override getType (): string { return 'ProductsComponent'; }

    public override oncomplete (): void {
        super.oncomplete();
        this.DefaultImage = ThemeManager.getImageFullUrlNew('EntitlementSystem/defaultImg.png');

        this.on('subscribeClick', this.subscribeClick);
        this.on('unsubscribeClick', this.unsubscribeClick);
        this.on('cancelSubscribeRequest', this.cancelSubscribeRequestClick);
        this.observe('propertiesProduct', this.getPropertiesProduct);
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public getPropertiesProduct (newData): void {
        if (isNullOrUndefined(newData)) {
            return;
        }

        this.ProductData = newData;
        if (newData.Description) {
            void this.set({ visibleInfoBtn: true, infoBtnTooltip: newData.Description });
        } else {
            void this.set({ visibleInfoBtn: false, infoBtnTooltip: '' });
        }

        void this.set({ nameProductText: newData.Name });
        void this.set({
            isProcessing: newData.isWaitForUnsubscribe || newData.isWaitForSubscribe, // SAME AS BELOW!
            isActive: newData.isSubscribeActionDone,
            reqTimeCreated: newData.ReqTimeCreated,
            IsUnsubscribeAllowed: newData.IsUnsubscribeAllowed // for tooltip's reactively update in computed RequestInfoTooltip
        });

        // this.ProductData.StatusUpdateHandler = function ()
        // {
        //     let pr = this.ProductData;
        void this.set({ isProcessing: newData.isWaitForUnsubscribe || newData.isWaitForSubscribe }); // SAME AS ABOVE!
        // }.bind(this)

        if (newData.Image) {
            void this.set({ pictureUrl: newData.Image });
        } else {
            void this.set({ pictureUrl: this.DefaultImage });
        }

        this.correctWidthName(newData.Name);
        this.applySubscriberStatus();
    }

    public correctWidthName (Name: string): void {
    // 100 - крайняя граница первой строки
    // 190 - крайняя граница второй строки
        const len = Name.length;
        const width = len * 6;

        void this.set({ webkitMod: false });
        if (width > 100) {
            void this.set({ webkitMod: true });
        }

        if (width > 190) {
            void this.set({ nameProductTextTooltip: Name });
        }
    }

    public subscribeClick (): void {
        if (ApplicationInfo.isExploreMode) {
            return;
        }

        if (isNullOrUndefined(this.ProductData)) {
            return;
        }

        const OkCb = function (showNextTime) {
            WDSettingsUtils.updateConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions, showNextTime);

            const data = this.ProductData;
            if (data.HasDocument) {
                EntitlementManager.GetDocuments(data.Id).then(this.ShowDocumentsSubscr.bind(this));
            } else {
                EntitlementManager.ProductSubscribeRequest(data.Id, null);
            }
        }.bind(this);

        if (WDSettingsUtils.useConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions)) {
            const msgBox = TerceraMessageBox.Show(
                Resources.getResource('screen.products.SubscribeRequestConfirmScreen.header'),
                Resources.getResource('screen.products.SubscribeRequestConfirmScreen.text'),
                MessageBoxType.Info, OkCb, null, true, true, null,
                { okText: Resources.getResource('screen.products.SubscribeRequestConfirmScreen.OkBtnText') }
            );

            this.myMsgBox = msgBox;
            msgBox.customDisposeHandler = function () { this.myMsgBox = null; }.bind(this);
        } else {
            OkCb();
        }
    }

    public unsubscribeClick (): void {
        if (ApplicationInfo.isExploreMode) {
            return;
        }

        const data = this.ProductData;
        if (isNullOrUndefined(data)) { return; }

        if (!isNullOrUndefined(this.myMsgBox)) { return; }

        if (!data.IsUnsubscribeAllowed) { return; }

        const Id = data.Id;
        const OkCb = (showNextTime): void => {
            WDSettingsUtils.updateConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions, showNextTime);
            EntitlementManager.ProductUnSubscribeRequest(Id);
        };
        const NoCb = (showNextTime): void => { WDSettingsUtils.updateConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions, showNextTime); };

        if (WDSettingsUtils.useConfirmation(ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions)) {
            const msg = Resources.getResource('panel.Products.Unsubscribe.PopupMessage');// .replace("{0}", data.Name)  // #108197
            const msgBox = TerceraMessageBox.Show(
                Resources.getResource('AdditionalProperty.Unsubscribe'),
                msg,
                MessageBoxType.Question,
                OkCb,
                NoCb,
                true,
                null,
                Resources.getResource('screen.products.ButtonUnsubscribe.Click')
            );

            this.myMsgBox = msgBox;
            msgBox.customDisposeHandler = function () { this.myMsgBox = null; }.bind(this);
        } else {
            EntitlementManager.ProductUnSubscribeRequest(Id);
        }
    }

    public cancelSubscribeRequestClick (): void {
        if (ApplicationInfo.isExploreMode) {
            return;
        }

        const data = this.ProductData;
        if (isNullOrUndefined(data)) { return; }

        if (!isNullOrUndefined(this.myMsgBox)) { return; }

        this.myMsgBox = EntitlementManager.GetMessageBoxForProductCancelRequest(this, data);
    }

    public ShowDocumentsSubscr (documents): void {
        if (!documents) {
            return;
        }

        if (!isNullOrUndefined(this.myDocumentScreen)) {
            return;
        }

        const documentScreen = EntitlementSubscribeDocumentScreen.Show(this.ProductData.Id, documents);
        this.myDocumentScreen = documentScreen;
        documentScreen.customDisposeHandler = function () { this.myDocumentScreen = null; }.bind(this);
    }

    public localize (): void {
        void this.set({
            buttonSubscribeText: Resources.getResource('screen.products.ButtonSubscribe'),
            buttonSubscribedText: Resources.getResource('screen.products.ButtonSubscribed'),
            buttonCancelSubscribeRequestText: Resources.getResource('screen.products.CancelSubscribeRequestButton.text'),
            reqTimeCreated: this.get('reqTimeCreated'), // for localizing  tooltip's reactively update in computed RequestInfoTooltip
            buttonUnsubscribeText: Resources.getResource('screen.products.ButtonUnsubscribe')
        });
    }

    public applySubscriberStatus (): void {
        let priceText = '';
        const product = this.ProductData;
        if (isNullOrUndefined(product)) { return; }

        if (isNaN(product.ProPrice) && isNaN(product.NonProPrice)) {
            void this.set({
                valueProductTextBig: ProductsComponent.FREE_PRODUCT_VALUE,
                valueProductTextSmall: '',
                valueProductTextBig2: '',
                valueProductTextSmall2: '',
                labelProductText: '' // #108192
            });
            return;
        }

        const status = Math.floor(DataCache.getRuleNumberValueForMyUser(RulesSet.VALUE_MARKET_DATA_SUBSCRIBER_STATUS, 0));
        const asset = DataCache.GetAssetById(product.CurrencyId);
        switch (status) {
        case SubscriberStatus.NotDefined: // нужно отображать обе цены за использование продукта
        {
            const nonProPriceText = isNaN(product.NonProPrice) ? ProductsComponent.FREE_PRODUCT_VALUE : product.NonProPrice;
            const proPriceText = isNaN(product.ProPrice) ? ProductsComponent.FREE_PRODUCT_VALUE : product.ProPrice;

            const nonProPriceLabelText = nonProPriceText === ProductsComponent.FREE_PRODUCT_VALUE ? ProductsComponent.FREE_PRODUCT_VALUE : asset.formatPrice(nonProPriceText);
            const proPriceLabelText = proPriceText === ProductsComponent.FREE_PRODUCT_VALUE ? ProductsComponent.FREE_PRODUCT_VALUE : asset.formatPrice(proPriceText);

            const labelText = 'Non-pro price: ' + nonProPriceLabelText + '\n Pro price: ' + proPriceLabelText;
            void this.set({ labelProductText: labelText });

            this.setFormattedValues(nonProPriceText, proPriceText, asset);
            break;
        }

        case SubscriberStatus.NotRequired: // отображаем Non-pro price.
        case SubscriberStatus.NonPro: // отображаем Non-pro price.
            if (isNaN(product.NonProPrice)) {
                priceText = ProductsComponent.FREE_PRODUCT_VALUE;
            } else {
                priceText = product.NonProPrice;
            }

            this.setFormattedValues(priceText, null, asset);
            break;
        case SubscriberStatus.Pro: // отображаем значение из Pro price
            if (isNaN(product.ProPrice)) {
                priceText = ProductsComponent.FREE_PRODUCT_VALUE;
            } else {
                priceText = product.ProPrice;
            }

            this.setFormattedValues(priceText, null, asset);
            break;
        default: // по умолчанию тоже Non-pro price
            if (isNaN(product.NonProPrice)) {
                priceText = ProductsComponent.FREE_PRODUCT_VALUE;
            } else {
                priceText = product.NonProPrice;
            }

            this.setFormattedValues(priceText, null, asset);
            break;
        };
    }

    public setFormattedValues (price1, price2, asset): void {
        if (price1 === ProductsComponent.FREE_PRODUCT_VALUE) {
            void this.set({ valueProductTextBig: ProductsComponent.FREE_PRODUCT_VALUE, valueProductTextSmall: '' });
        } else {
            const noCurrency = !!price2;
            const bigValue = Math.trunc(price1);
            const smallValue = price1 - bigValue;

            const bigValueFormat = ProductsComponent.Zero_formater.format(bigValue);
            let smallValueFormat = asset.formatPrice(smallValue, noCurrency).substring(1);

            if (price2 === ProductsComponent.FREE_PRODUCT_VALUE) {
                smallValueFormat = asset.formatPrice(smallValue, false).substring(1);
            }

            void this.set({
                valueProductTextBig: bigValueFormat,
                valueProductTextSmall: smallValueFormat
            });
        }

        if (!price2) { return; }

        if (price2 === ProductsComponent.FREE_PRODUCT_VALUE) {
            void this.set({ valueProductTextBig2: '/' + ProductsComponent.FREE_PRODUCT_VALUE, valueProductTextSmall2: '' });
        } else {
            const bigValue = Math.trunc(price2);
            const smallValue = price2 - bigValue;

            const bigValueFormat = '/' + ProductsComponent.Zero_formater.format(bigValue);
            const smallValueFormat = asset.formatPrice(smallValue).substring(1);

            void this.set({
                valueProductTextBig2: bigValueFormat,
                valueProductTextSmall2: smallValueFormat
            });
        }
    }
}

enum SubscriberStatus {
    /// <summary>
    /// статус не определен
    /// </summary>
    NotDefined = 0,
    /// <summary>
    /// статус подписки не имеет значения
    /// </summary>
    NotRequired = 1,
    /// <summary>
    /// состояние определяет стоимость подписки на продукт
    /// </summary>
    NonPro = 2,
    /// <summary>
    /// состояние определяет стоимость подписки на продукт
    /// </summary>
    Pro = 3
};

Control.extendWith(ProductsComponent, {
    template: TerceraProductsComponent,
    data: function () {
        return {
            isActive: false,
            isProcessing: false,
            visibleInfoBtn: false,
            nameProductText: '',
            labelProductText: '',
            valueProductTextBig: '',
            valueProductTextSmall: '',
            pictureUrl: ThemeManager.getImageFullUrlNew('EntitlementSystem/defaultImg.png'),
            webkitMod: false,
            reqTimeCreated: null, // same field as in this.ProductData but for tooltip's reactively update in computed RequestInfoTooltip
            // isDoublePrice: false
            IsUnsubscribeAllowed: true // hide unsubsbtn
        };
    },
    computed: {
        RequestInfoTooltip: function () {
            if (ApplicationInfo.isExploreMode) {
                return Resources.getResource('IsAllowedResponceReason.NotAllowedByExploreMode');
            }
            const dateObj = this.get('reqTimeCreated'); // эта строка тут для того, чтобы при изменении reqTimeCreated вызывался этот метод и тултип обновлялся
            return EntitlementManager.GetReplacedStringWithProductData(this.ProductData, 'screen.products.CancelSubscribeRequestButton.tt');
        },
        RequestButtonsTooltip: function () {
            if (ApplicationInfo.isExploreMode) {
                return Resources.getResource('IsAllowedResponceReason.NotAllowedByExploreMode');
            }
            return '';
        }
    }
});
