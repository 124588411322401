// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { InsDefSettings } from './cache/InstrumentDefaults';
import { AlertUtils } from '@shared/utils/Alert/AlertUtils';
import { Resources, LocaleNames } from '@shared/localizations/Resources';
import { TimeZoneInfo } from '@shared/utils/Time/TimeZoneInfo';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { Connection } from './Connection';
import { OrderType, OrderTypeMap } from '@shared/utils/Trading/OrderType';
import { TIF } from '@shared/utils/Trading/OrderTif';
import { OrderTif, OrderTifMap } from '@shared/utils/Trading/OrderTifEnum';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { TerceraLineStyleComboBoxUtils } from './UtilsClasses/TerceraTIFComboBoxUtils';
import { DynProperty, BoolNumeric, DynPropertyRelationType } from './DynProperty';
import { SLTPTriggerUtils } from './cache/OrderParams/SLTPTriggerUtils';
import { type SLTPTrigger } from '@shared/utils/SlTpTrigger';
import { OffsetModeViewEnum } from '@shared/utils/Trading/OffsetModeViewEnum';
import { NumericUtils } from '@shared/utils/NumericUtils';
import { TradingLockUtils } from '@shared/utils/TradingLockUtils';
import { IsAllowed } from './IsAllowed';
import { OrderUtils } from '@shared/utils/Trading/OrderUtils';
import { DataCache } from './DataCache';
import { SessionSettingsConst } from './SessionSettingsConst';
import { SettingsLoggingManager } from './SettingsLoggingManager';
import { messageBoxHandler } from '@shared/utils/AppHandlers';
import { DateTimeConvertor } from '@shared/utils/Time/DateTimeConvertor';
import { ApplicationInfo } from './ApplicationInfo';
import { ProductType } from '@shared/utils/Instruments/ProductType';
import { type Instrument } from './cache/Instrument';
import { BaseSettingsUtils } from './UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { BaseSettings } from './Settings/BaseGeneralSettingsWrapper';
import { _WatchlistSheets, WatchlistSheets } from '@shared/utils/Managers/WatchlistManager/WatchlistSheets';
import { WatchlistSheetItem } from '@shared/utils/Managers/WatchlistManager/WatchlistSheetItem';
import { HotkeysManager } from './cache/Hotkeys/HotkeysManager';

class _SessionSettings {
    public chartSheets: any = {};
    public savedOrdersSheets: any = {};

    public timeZoneChanged = new CustomEvent();
    public sheetsChangedEvents = {
        chartSheets: new CustomEvent(),
        savedOrdersSheets: new CustomEvent()
    };

    public AdditionalPropertiesHandlers = [];
    public AdditionalCallBacksHandlers = [];
    public favoriteTimeZoneInfoIdsSet: Record<string, boolean>;
    public SettingsChanged: any;

    constructor () {
        this.AdditionalPropertiesHandlers.push(this.GetAlertPanelProperties.bind(this, true));
        TradingLockUtils.TradingLock.TradingLockChanged.Subscribe(this.save, this);
    }

    public GetAllowedTifsForOrderTypes (orderTypeArray): any[] {
        const dc = DataCache;
        const allowedTiffs: any[] = [];
        const allowedTiffsObj: Record<string, any> = {};

        const routeDict = dc.routes;
        for (const routeName in routeDict) {
            if (!Object.hasOwnProperty.call(routeDict, routeName)) {
                continue;
            }

            const route = routeDict[routeName];
            for (let i = 0; i < orderTypeArray.length; i++) {
                const orderType = orderTypeArray[i];
                const allowedOrderType = route.IsAllowableOrderType(orderType);

                if (!allowedOrderType) {
                    continue;
                }

                for (const tifName in OrderTifMap) {
                    const tifId = OrderTif[tifName];
                    const tifIsAllowed = route.IsAllowableTif(tifId, orderType);

                    if (tifIsAllowed) {
                        allowedTiffsObj[tifId] = tifId;
                    }
                }
            }
        }

        Object.keys(allowedTiffsObj).forEach(function (it): void {
            allowedTiffs.push(allowedTiffsObj[it]);
        });

        return allowedTiffs;
    }

    public Properties (forSaving = false): DynProperty[] {
        let properties: DynProperty[] = [];

        for (let i = 0; i < this.AdditionalPropertiesHandlers.length; i++) {
            properties = properties.concat(this.AdditionalPropertiesHandlers[i]());
        }

        let dp = new DynProperty('property.Language', BaseSettings.language, DynProperty.COMBOBOX_COMBOITEM, DynProperty.PARAM_GROUP);
        dp.objectVariants = [];

        const locs = LocaleNames;
        const len = locs.length;
        for (let i = 0; i < len; i++) {
            const l = locs[i];
            if (!Resources.isHidden('locale.' + l)) {
                dp.objectVariants.push({
                    value: l,
                    text: Resources.getLangNameByLocale(l)
                });
            }
        }

        dp.COMBOBOX_TYPE = DynProperty.STRING;
        dp.sortIndex = 1;
        properties.push(dp);

        dp = new DynProperty('property.onboardinFirstTimeShowed', BaseSettings.onboardInFirstTimeShowed, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty('property.DisableInactivityPeriod', BaseSettings.disableInactivityPeriod, DynProperty.BOOLEAN_EVT,
            Resources.isHidden('property.DisableInactivityPeriod') ? DynProperty.HIDDEN_GROUP : DynProperty.PARAM_GROUP);
        dp.sortIndex = 3;
        // dp.tooltip = Resources.getResource("property.DisableInactivityPeriod.tt")
        dp.BeforeChangeEvent = function (newCheck, resolver) {
            if (!newCheck) {
                return resolver(false);
            }

            const scr = messageBoxHandler.Show(Resources.getResource('property.general.DisableInactivityPeriod.confirmation.caption'), Resources.getResource('property.general.DisableInactivityPeriod.confirmation.message'), messageBoxHandler.msgType.Info);
            scr.OK_Handler.then(function () {
                resolver(true);
            });

            scr.NO_Handler.then(function () {
                resolver(false);
            });
        };
        properties.push(dp);

        let prop = new DynProperty('property.Enable Sounds', BaseSettings.enableSounds, DynProperty.BOOLEAN, DynProperty.PARAM_GROUP);
        prop.sortIndex = 4;
        prop.assignedProperty = ['property.DisableStartEndSounds'];
        properties.push(prop);

        prop = new DynProperty('property.DisableStartEndSounds', BaseSettings.disableStartEndSound, DynProperty.BOOLEAN,
            Resources.isHidden('property.DisableStartEndSounds') ? DynProperty.HIDDEN_GROUP : DynProperty.PARAM_GROUP);
        prop.sortIndex = 5;
        properties.push(prop);

        dp = new DynProperty(SessionSettingsConst.IS_ROUNDED_AVERAGE_OPEN_PRICE, BaseSettings.roundedAverageOpenPrice, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.sortIndex = 1;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.DISPLAY_QUANTITY_IN_LOTS, BaseSettings.isQuantityInLots, DynProperty.BOOLEAN,
            Resources.isHidden(SessionSettingsConst.DISPLAY_QUANTITY_IN_LOTS) ? DynProperty.HIDDEN_GROUP : DynProperty.VIEW_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.REVERSE_BUTTONS_ORDER, BaseSettings.reverseButtonsOrder, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.sortIndex = 3;
        properties.push(dp);

        if (!forSaving) {
            dp = new DynProperty('property.isShowToolTipsGrop', '', DynProperty.SEPARATOR, DynProperty.VIEW_GROUP);
            dp.sortIndex = 6;
            properties.push(dp);
        }

        dp = new DynProperty(SessionSettingsConst.TOOLTIPS, BaseSettings.tooltips, DynProperty.BOOLEAN, DynProperty.VIEW_GROUP);
        dp.sortIndex = 7;
        properties.push(dp);

        if (!forSaving) {
            dp = new DynProperty('', '', DynProperty.STRING, DynProperty.SEPARATOR_GROUP1);
            properties.push(dp);
        }

        dp = new DynProperty('property.logSnapshotHasBeenSent', BaseSettings.logSnapshotHasBeenSent, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        properties.push(dp);

        dp = new DynProperty('property.isCashModePreferred', BaseSettings.isCashModePreferred, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        properties.push(dp);

        // #region Sounds

        // #endregion

        dp = new DynProperty('property.routing.account', BaseSettings.tradingAccountID, DynProperty.ACCOUNT,
            DataCache.getNumberOfAccounts() === 1 ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 0;
        properties.push(dp);

        dp = new DynProperty('property.general.defaultSymbol', BaseSettings.tradingSymbolID, DynProperty.INSTRUMENT, DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 10;
        properties.push(dp);

        if (!forSaving) {
            this.AddSeparator(properties, DynProperty.TRADING_DEFAULTS_GROUP, 20);
        }

        const dc = DataCache;

        const sltpSettingsHideByRules = IsAllowed.IsProtectiveOrders(); // || !DataCache.isAllowed(RulesSet.FUNCTION_SLTP)

        dp = new DynProperty(SessionSettingsConst.SET_SLTP_VALUES_IN_OFFSET, BaseSettings.isSlTpInOffset, DynProperty.BOOLEAN, // isHidden ключ ('property.sltpOffsetMode') отличается от DynProp.name('sltpOffsetMode'), стоило б исправить, но вдруг уже настроено так у кого-то из клиентов потому оставил
            Resources.isHidden('property.' + SessionSettingsConst.SET_SLTP_VALUES_IN_OFFSET) || sltpSettingsHideByRules ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 30;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.SHOW_OFFSET_IN, BaseSettings.offsetType, DynProperty.COMBOBOX_COMBOITEM, // isHidden ключ ('property.property.OffsetMode') отличается от DynProp.name('property.OffsetMode'), стоило б исправить, но вдруг уже настроено так у кого-то из клиентов потому оставил
            Resources.isHidden('property.' + SessionSettingsConst.SHOW_OFFSET_IN) || sltpSettingsHideByRules ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.assignedProperty = [SessionSettingsConst.USE_STOP_LIMIT_INSTEAD_OF_STOP];
        dp.DynPropertyRelationType = DynPropertyRelationType.Visibility;
        dp.sortIndex = 40;
        properties.push(dp);

        const offsetModeTypes = [];
        for (const key in OffsetModeViewEnum) {
            const str = SessionSettingsConst.SHOW_OFFSET_IN + '.' + key;
            if (!isNaN(Number(key)) || Resources.isHidden(str)) continue;

            const value = OffsetModeViewEnum[key];
            if (Number(value) === OffsetModeViewEnum.Percent && !DataCache.IsAllowSLTPPercentForUser()) {
                continue;
            }

            offsetModeTypes.push({
                key: str,
                value,
                text: Resources.getResource(str)
            });
        }
        dp.objectVariants = offsetModeTypes;

        dp = new DynProperty(SessionSettingsConst.USE_STOP_LIMIT_INSTEAD_OF_STOP, BaseSettings.useStopLimitInsteadStop, DynProperty.BOOLEAN,
            Resources.isHidden(SessionSettingsConst.USE_STOP_LIMIT_INSTEAD_OF_STOP) ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.enabilityValue = [OffsetModeViewEnum.Ticks, OffsetModeViewEnum.TicksFractionalForForex, OffsetModeViewEnum.Points];
        dp.sortIndex = 45;
        dp.assignedProperty = ['property.limitOffsetTicks'];
        properties.push(dp);

        dp = new DynProperty('property.limitOffsetTicks', BaseSettings.limitOffsetTicks, DynProperty.INTEGER,
            Resources.isHidden('property.limitOffsetTicks') ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 46;
        dp.minimalValue = -NumericUtils.MAXVALUE;
        dp.maximalValue = NumericUtils.MAXVALUE;
        properties.push(dp);

        if (dc.AllowCustomSLTPTriggerPriceForUser()) { //  AddSLTPTriggerProperties
            properties = properties.concat(SLTPTriggerUtils.GetProperties(BaseSettings.slTpTriggers, 50, forSaving));
        }

        if (!forSaving) {
            this.AddSeparator(properties, DynProperty.TRADING_DEFAULTS_GROUP, 60);
        }

        dp = new DynProperty('property.routing.orderType', BaseSettings.orderType, DynProperty.COMBOBOX_COMBOITEM, DynProperty.TRADING_DEFAULTS_GROUP);
        const OrderTypes = dc.OrderParameterContainer.OrderTypes;
        const forOrderTypesPopulate = [];
        for (const key in OrderTypes) {
            forOrderTypesPopulate.push(OrderTypes[key].id());
        }

        dp.objectVariants = _SessionSettings.createVariantsForOrderTypes(forOrderTypesPopulate);
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.sortIndex = 70;
        properties.push(dp);

        dc.TIF = this.GetAllowedTifsForOrderTypes([
            OrderType.Market
        ]);

        dp = new DynProperty('TIF',
            TerceraLineStyleComboBoxUtils.cretaComboItem(this.checkTifInArray(dc.TIF, BaseSettings.orderTif), TIF.expireTimeTIF_Market),
            DynProperty.COMBOBOX_COMBOITEM_TIF,
            DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = TIF.createVariantsForTifs(dc.TIF);
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.sortIndex = 80;
        properties.push(dp);

        dc.TIF_Limit_And_Stop_Limit = this.GetAllowedTifsForOrderTypes([
            OrderType.Limit,
            OrderType.StopLimit
        ]);

        dp = new DynProperty('TIF_Limit_And_Stop_Limit',
            TerceraLineStyleComboBoxUtils.cretaComboItem(this.checkTifInArray(dc.TIF_Limit_And_Stop_Limit, BaseSettings.limitAndStopLimitValidity), TIF.expireTimeTIF_Limit),
            DynProperty.COMBOBOX_COMBOITEM_TIF,
            DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = TIF.createVariantsForTifs(dc.TIF_Limit_And_Stop_Limit);
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.sortIndex = 90;
        properties.push(dp);

        dc.TIF_Stop = this.GetAllowedTifsForOrderTypes([
            OrderType.Stop
        ]);

        dp = new DynProperty('TIF_Stop',
            TerceraLineStyleComboBoxUtils.cretaComboItem(this.checkTifInArray(dc.TIF_Stop, BaseSettings.stopValidity), TIF.expireTimeTIF_Stop),
            DynProperty.COMBOBOX_COMBOITEM_TIF,
            DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = TIF.createVariantsForTifs(dc.TIF_Stop);
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        dp.sortIndex = 100;
        properties.push(dp);

        const dprtC = DataCache.productTypeEnabilityCache;
        const arrP = [];
        for (const t in dprtC) {
            arrP.push(t);
        }

        const prodTypes = _SessionSettings.createVariantsForProductTypes(arrP);
        dp = new DynProperty('property.DefaultProductType', BaseSettings.productType, DynProperty.COMBOBOX_COMBOITEM,
            prodTypes.length === 1 && prodTypes[0].value === ProductType.General ? DynProperty.HIDDEN_GROUP : DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = prodTypes;
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        if (prodTypes.length === 1) {
            dp.enabled = false;
        }

        dp.sortIndex = 110;
        properties.push(dp);

        if (!forSaving) {
            this.AddSeparator(properties, DynProperty.TRADING_DEFAULTS_GROUP, 120);
        }

        dp = new DynProperty(SessionSettingsConst.TYPES_MANAGER,
            BaseSettings.insDefSettingsStorage.GetSettingsCopy().ToXML(),
            DynProperty.INSTRUMENTS_DEFAULTS,
            DynProperty.TRADING_DEFAULTS_GROUP);
        dp.sortIndex = 130;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_ORDER_PLACEMENT, BaseSettings.confirmOrderSending, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 1;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_ORDER_CANCELLATION, BaseSettings.confirmOrderCancel, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_ORDER_OR_POSITION_MODIFICATION, BaseSettings.confirmOrderPositionsModification, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 3;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_POSITION_CLOSING, BaseSettings.confirmPositionClosing, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 4;
        properties.push(dp);

        dp = new DynProperty('property.oe.confirmReversePosition', BaseSettings.confirmReversePosition, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        // https://tp.traderevolution.com/entity/98279
        // Resources.isHidden('property.oe.confirmReversePosition') ? DynProperty.HIDDEN_GROUP : DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 5;
        properties.push(dp);

        if (!forSaving) {
            dp = new DynProperty('property.OrderCreateAlertGroup', '', DynProperty.GROUP_SEPARATOR, DynProperty.CONFIRMATIONS_GROUP);
            dp.sortIndex = 48;
            properties.push(dp);
        }

        dp = new DynProperty('property.oe.confirmOrderCreateAlert', BaseSettings.confirmPlaceAlert, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 49;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.CONFIRM_ALERT_REMOVE, BaseSettings.confirmCancelAlert, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 49;
        properties.push(dp);

        if (!forSaving) {
            dp = new DynProperty('property.ConfirmationSubgroup.Other', '', DynProperty.GROUP_SEPARATOR, DynProperty.CONFIRMATIONS_GROUP);
            dp.sortIndex = 56;
            properties.push(dp);
        }

        dp = new DynProperty('property.dealTickets.openOnWorkspace', BaseSettings.openDialTicketsOnWorkspace, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.sortIndex = 57;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.SHOW_MARGIN_DEAL_TICKETS_ON_WORKSPACE, BaseSettings.showMarginDealTicketOnWorkspace, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.tooltip = Resources.getResource('property.dealTickets.showMarginDealTicketsOnWorkspace.ToolTip');
        dp.sortIndex = 58;
        properties.push(dp);

        dp = new DynProperty(SessionSettingsConst.SHOW_DEAL_TICKETS, BaseSettings.showDialTicketsInApplicationTray, DynProperty.BOOLEAN, DynProperty.CONFIRMATIONS_GROUP);
        dp.tooltip = Resources.getResource('property.dealTickets.showDealTickets.ToolTip');
        dp.sortIndex = 59;
        properties.push(dp);

        let groupToAdd = DataCache.isAllowedForMyUser(RulesSet.FUNCTION_COPY_TRADE) && !Resources.isHidden('panel.CopyTrading') ? DynProperty.CONFIRMATIONS_GROUP : DynProperty.HIDDEN_GROUP;
        dp = new DynProperty(SessionSettingsConst.CONFIRM_ACCOUNT_FOLLOWING, BaseSettings.confirmAccountFollowing, DynProperty.BOOLEAN, groupToAdd);
        dp.sortIndex = 60;
        properties.push(dp);

        dp = new DynProperty('property.positionSizing.infoWindowShow', BaseSettings.positionSizeCalculatorInfoWindow, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP);
        properties.push(dp);

        groupToAdd = DataCache.isAllowedForMyUser(RulesSet.FUNCTION_PRODUCTS) && !Resources.isHidden('ribbon.tools.products') ? DynProperty.CONFIRMATIONS_GROUP : DynProperty.HIDDEN_GROUP;
        dp = new DynProperty('property.subscriptions.confirmSubscribeAndUnsubscribe', BaseSettings.confirmSubscriptions, DynProperty.BOOLEAN, groupToAdd);
        dp.sortIndex = 60;
        properties.push(dp);

        dp = new DynProperty('property.general.OvernightMarginNotificationMessage', BaseSettings.overnightMarginNotificationMessage, DynProperty.BOOLEAN, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfWrongOrderParam', BaseSettings.warnIfWrongOrder, DynProperty.BOOLEAN, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 3;
        dp.tooltip = Resources.getResource('property.general.WarnIfWrongOrderParam.tooltip');
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfIdenticalOrder', new BoolNumeric(BaseSettings.warnIfIdenticalSubsequentOrderIsPlaced, BaseSettings.warnIfIdenticalOrderTime),
            DynProperty.BOOL_NUMERIC, Resources.isHidden('general.WarnIfOrderRepeat') ? DynProperty.HIDDEN_GROUP : DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 4;
        dp.decimalPlaces = 0;
        dp.increment = 1;
        dp.minimal = 0;
        properties.push(dp);

        groupToAdd = DataCache.getValidateMaxLotCloseOrderBoolRule() ? DynProperty.WARNINGS_GROUP : DynProperty.HIDDEN_GROUP;
        dp = new DynProperty('property.general.WarnIfQtyToCloseMoreThanMaxLot', BaseSettings.warnIfQtyToCloseMoreThanMaxLot, DynProperty.BOOLEAN, groupToAdd);
        dp.sortIndex = 5;
        properties.push(dp);

        groupToAdd = DataCache.isAllowedForMyUser(RulesSet.LEVERAGE_USAGE_WARN) ? DynProperty.WARNINGS_GROUP : DynProperty.HIDDEN_GROUP;
        dp = new DynProperty('property.general.WarnWhenEnteringIntoLeverage', BaseSettings.warnWhenEnteringIntoLeverage, DynProperty.BOOLEAN, groupToAdd);
        dp.sortIndex = 6;
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfOrderQtyExceed', new BoolNumeric(BaseSettings.warnIfOrderQtyExceed, BaseSettings.warnIfOrderQtyExceedValue),
            DynProperty.BOOL_NUMERIC, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 7;
        dp.decimalPlaces = 0;
        dp.increment = 1;
        dp.minimalValue = 1;
        dp.maximalValue = 100000000;
        dp.tooltip = Resources.getResource('property.general.WarnIfOrderQtyExceed.tooltip');
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfOrderCapitalExceed', new BoolNumeric(BaseSettings.warnIfOrderCapitalExceed, BaseSettings.warnIfOrderCapitalExceedValue),
            DynProperty.BOOL_NUMERIC, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 8;
        dp.decimalPlaces = 2;
        dp.increment = 0.01;
        dp.minimalValue = 1;
        dp.maximalValue = 100000000;
        dp.tooltip = Resources.getResource('property.general.WarnIfOrderCapitalExceed.tooltip');
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfTodayGrossLess', new BoolNumeric(BaseSettings.warnIfTodayGrossLess, BaseSettings.warnIfTodayGrossLessValue),
            DynProperty.BOOL_NUMERIC, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 9;
        dp.decimalPlaces = 2;
        dp.increment = 0.01;
        dp.minimalValue = -100000000;
        dp.maximalValue = 100000000;
        dp.tooltip = Resources.getResource('property.general.WarnIfTodayGrossLess.tooltip');
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfAvailibleFundsLess', new BoolNumeric(BaseSettings.warnIfAvailibleFundsLess, BaseSettings.warnIfAvailibleFundsLesssValue),
            DynProperty.BOOL_NUMERIC, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 10;
        dp.decimalPlaces = 2;
        dp.increment = 0.01;
        dp.minimalValue = 1;
        dp.maximalValue = 100000000;
        dp.tooltip = Resources.getResource('property.general.WarnIfAvailibleFundsLess.tooltip');
        properties.push(dp);

        dp = new DynProperty('property.general.WarnIfTodayVolumeExeed', new BoolNumeric(BaseSettings.warnIfTodayVolumeExeed, BaseSettings.warnIfTodayVolumeExeedValue),
            DynProperty.BOOL_NUMERIC, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 11;
        dp.decimalPlaces = 0;
        dp.increment = 1;
        dp.minimalValue = 1;
        dp.maximalValue = 100000000;
        dp.tooltip = Resources.getResource('property.general.WarnIfTodayVolumeExeed.tooltip');
        properties.push(dp);

        dp = new DynProperty('property.general.ShowInstrumentHalt', BaseSettings.showInstrumentHalt,
            DynProperty.BOOLEAN, DynProperty.WARNINGS_GROUP);
        dp.sortIndex = 12;
        dp.tooltip = Resources.getResource('property.general.ShowInstrumentHalt.tooltip');
        properties.push(dp);

        if (!forSaving) {
            DataCache.EntitlementManager.AddNotificationProperties(properties);
        } // for Products with ProductDataType == EntitlementProductDataType.Notifications && ShowInSettings == true

        dp = new DynProperty('watchlistSheets.itemsValue', _WatchlistSheets.serialize(WatchlistSheets.items), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty('chartSheets.itemsValue', JSON.stringify(this.chartSheets.itemsValue), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        dp = new DynProperty('savedOrdersSheets.itemsValue', JSON.stringify(this.savedOrdersSheets.itemsValue), DynProperty.STRING, DynProperty.HIDDEN_GROUP);
        dp.sortIndex = 2;
        properties.push(dp);

        const curTZ = DateTimeConvertor.currentTimeZoneInfoId.value;
        properties.push(new DynProperty('property.general.timezone.current', curTZ, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        const favTZones = TimeZoneInfo.serializeToIdsString(this.favoriteTimeZoneInfoIdsSet);
        properties.push(new DynProperty('property.general.timezone.favorites', favTZones, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('property.general.Settings.TradingLock.TradingLocked', TradingLockUtils.TradingLock.tradingLocked, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        // 123429
        properties = properties.concat(HotkeysManager.GetProperties());

        return properties;
    }

    public callBack (properties: DynProperty[], changed): void {
        if (!changed) {
            return;
        }

        SettingsLoggingManager.LogChange(this.Properties(), properties); // #111072

        const needInform = !Resources.isHidden('property.DisableInactivityPeriod');
        let needSend = false;

        if (needInform) {
            let newDIPV = null;
            const dp = DynProperty.getPropertyByName(properties, 'property.DisableInactivityPeriod');
            if (DynProperty.isCorrect(dp)) {
                newDIPV = dp.value;
            }

            needSend = newDIPV !== null && newDIPV !== BaseSettings.disableInactivityPeriod;
        }

        this.applyNewSettings(properties);

        if (needSend) {
            const msgText = BaseSettings.disableInactivityPeriod ? 'Inactivity period setting was activated' : 'Inactivity period setting was disabled';
            Connection.vendor.SendInactivityPeriodState(msgText);
        }
    }

    public AddSeparator (properties: DynProperty[], group: string, sortIndex): void { // add DynProperty of separator to properties array
        const dp = new DynProperty('', '', DynProperty.SEPARATOR, group);
        if (sortIndex !== null) {
            dp.sortIndex = sortIndex;
        }

        properties.push(dp);
    }

    public GetAlertPanelProperties (needHide?: boolean): DynProperty[] {
        const properties: DynProperty[] = [];
        const Group = needHide ? DynProperty.HIDDEN_GROUP : DynProperty.ALERTS_GROUP;

        const prop1 = new DynProperty('Notification', BaseSettings.alertNotification, DynProperty.ALERT_NOTIFICATION_SELECTOR, Group);
        prop1.sortIndex = 2;
        prop1.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop1);

        const prop2 = new DynProperty('AlertType', BaseSettings.alertType, DynProperty.COMBOBOX, Group);
        prop2.sortIndex = 3;
        prop2.objectVariants = AlertUtils.GetAlertTypeItems();
        prop2.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop2);

        const prop3 = new DynProperty('Condition', BaseSettings.alertCondition, DynProperty.COMBOBOX, Group);
        prop3.sortIndex = 4;
        prop3.objectVariants = AlertUtils.GetAlertConditionItems();
        prop3.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop3);

        const prop4 = new DynProperty('Importance', BaseSettings.alertImportance, DynProperty.COMBOBOX, Group);
        prop4.sortIndex = 5;
        prop4.objectVariants = AlertUtils.GetAlertImportanceItems();
        prop4.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop4);

        const prop5 = new DynProperty('AfterExecute', BaseSettings.alertAfterExecute, DynProperty.COMBOBOX, Group);
        prop5.sortIndex = 6;
        prop5.objectVariants = AlertUtils.GetAlertAfterExecuteItems();
        prop5.COMBOBOX_TYPE = DynProperty.INTEGER;
        properties.push(prop5);

        return properties;
    }

    public AlertPanelCallBack (newProperties: DynProperty[]): void {
        const dp1 = DynProperty.getPropertyByName(newProperties, 'Notification');
        if (dp1) {
            BaseSettings.alertNotification = dp1.value;
        }

        const dp2 = DynProperty.getPropertyByName(newProperties, 'AlertType');
        if (dp2) {
            BaseSettings.alertType = dp2.value;
        }

        const dp3 = DynProperty.getPropertyByName(newProperties, 'Condition');
        if (dp3) {
            BaseSettings.alertCondition = dp3.value;
        }

        const dp4 = DynProperty.getPropertyByName(newProperties, 'AfterExecute');
        if (dp4) {
            BaseSettings.alertAfterExecute = dp4.value;
        }

        const dp5 = DynProperty.getPropertyByName(newProperties, 'Importance');
        if (dp5) {
            BaseSettings.alertImportance = dp5.value;
        }
    }

    public applyNewSettings (properties: DynProperty[], fromJson = false, loginScreenLang = null): void {
        try {
            let dp = DynProperty.getPropertyByName(properties, 'property.Language');
            if (DynProperty.isCorrect(dp)) {
                let value = dp.value;
                if (isValidString(loginScreenLang) && value !== loginScreenLang) { // #89339
                    value = loginScreenLang;
                }

                BaseSettings.language = value;
                Resources.setLocale(value);
            }

            for (let i = 0; i < this.AdditionalCallBacksHandlers.length; i++) {
                properties = properties.concat(this.AdditionalCallBacksHandlers[i](properties));
            }

            dp = DynProperty.getPropertyByName(properties, 'property.onboardinFirstTimeShowed');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.onboardInFirstTimeShowed = dp.value && !ApplicationInfo.isExploreMode;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.logSnapshotHasBeenSent');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.logSnapshotHasBeenSent = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.isCashModePreferred');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.isCashModePreferred = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.DisableInactivityPeriod');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.disableInactivityPeriod = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.DISPLAY_QUANTITY_IN_LOTS);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.isQuantityInLots = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.TOOLTIPS);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.tooltips = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.IS_ROUNDED_AVERAGE_OPEN_PRICE);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.roundedAverageOpenPrice = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.REVERSE_BUTTONS_ORDER);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.reverseButtonsOrder = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.Enable Sounds');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.enableSounds = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.DisableStartEndSounds');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.disableStartEndSound = dp.value;
                BaseSettings.enableWelcomeSound = !BaseSettings.disableStartEndSound;
                BaseSettings.enableFinishSound = !BaseSettings.disableStartEndSound;
            }
            // #region trading

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.TYPES_MANAGER);
            if (dp) {
                const insDefSettings = new InsDefSettings();
                insDefSettings.LoadFromXML(dp.value);
                BaseSettings.insDefSettingsStorage.SetSettings(insDefSettings);
            }

            dp = DynProperty.getPropertyByName(properties, 'property.routing.orderType');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.orderType = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.DefaultProductType');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.productType = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.SET_SLTP_VALUES_IN_OFFSET);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.isSlTpInOffset = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.SHOW_OFFSET_IN);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.offsetType = !DataCache.IsAllowSLTPPercentForUser() && dp.value === OffsetModeViewEnum.Percent ? OffsetModeViewEnum.Ticks : dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.USE_STOP_LIMIT_INSTEAD_OF_STOP);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.useStopLimitInsteadStop = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.limitOffsetTicks');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.limitOffsetTicks = dp.value;
            }

            SLTPTriggerUtils.ApplyProperties(BaseSettings.slTpTriggers, properties, fromJson); // #109798 callBack SLTPTrigger Properties

            const dc = DataCache;

            dp = DynProperty.getPropertyByName(properties, 'TIF');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.orderTif = dp.value.tif.value;
                TIF.expireTimeTIF_Market = TIF.handleNewExpireTime(dp.value.date);
            }

            dp = DynProperty.getPropertyByName(properties, 'TIF_Limit_And_Stop_Limit');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.limitAndStopLimitValidity = dp.value.tif.value;
                TIF.expireTimeTIF_Limit = TIF.handleNewExpireTime(dp.value.date);
            }

            dp = DynProperty.getPropertyByName(properties, 'TIF_Stop');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.stopValidity = dp.value.tif.value;
                TIF.expireTimeTIF_Stop = TIF.handleNewExpireTime(dp.value.date);
            }

            dp = DynProperty.getPropertyByName(properties, 'property.routing.account');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.tradingAccountID = BaseSettingsUtils.existTradingAccountIdOrFirst(dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.defaultSymbol');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.tradingSymbolID = BaseSettingsUtils.existTradingSymbolIdOrFirst(dp.value);
            }
            // #endregion
            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ORDER_CANCELLATION);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmOrderCancel = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ORDER_PLACEMENT);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmOrderSending = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.oe.confirmOrderCreateAlert');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmPlaceAlert = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ALERT_REMOVE);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmCancelAlert = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_POSITION_CLOSING);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmPositionClosing = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.oe.confirmReversePosition');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmReversePosition = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ORDER_OR_POSITION_MODIFICATION);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmOrderPositionsModification = dp.value;
            }

            let openDealTicketsOnWorkspaceDynPropertyAvailable = false;
            dp = DynProperty.getPropertyByName(properties, 'property.dealTickets.openOnWorkspace');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.openDialTicketsOnWorkspace = dp.value;
                openDealTicketsOnWorkspaceDynPropertyAvailable = true;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.SHOW_MARGIN_DEAL_TICKETS_ON_WORKSPACE);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.showMarginDealTicketOnWorkspace = dp.value;
            } else if (openDealTicketsOnWorkspaceDynPropertyAvailable) {
                // migration process #123386
                BaseSettings.showMarginDealTicketOnWorkspace = Resources.isHidden('property.dealTickets.openMarginOnWorkspace.Default') ? BaseSettings.openDialTicketsOnWorkspace : true;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.SHOW_DEAL_TICKETS);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.showDialTicketsInApplicationTray = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, SessionSettingsConst.CONFIRM_ACCOUNT_FOLLOWING);
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmAccountFollowing = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.positionSizing.infoWindowShow');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.positionSizeCalculatorInfoWindow = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.subscriptions.confirmSubscribeAndUnsubscribe');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.confirmSubscriptions = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfWrongOrderParam');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.warnIfWrongOrder = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfIdenticalOrder');
            if (DynProperty.isCorrect(dp)) {
                const boolNumeric: BoolNumeric = dp.value;

                if (boolNumeric) {
                    BaseSettings.warnIfIdenticalSubsequentOrderIsPlaced = boolNumeric.Checked;
                    BaseSettings.warnIfIdenticalOrderTime = boolNumeric.Value;
                }
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfOrderQtyExceed');
            if (DynProperty.isCorrect(dp)) {
                const boolNumeric: BoolNumeric = dp.value;

                if (boolNumeric) {
                    BaseSettings.warnIfOrderQtyExceed = boolNumeric.Checked;
                    BaseSettings.warnIfOrderQtyExceedValue = boolNumeric.Value;
                }
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfOrderCapitalExceed');
            if (DynProperty.isCorrect(dp)) {
                const boolNumeric: BoolNumeric = dp.value;

                if (boolNumeric) {
                    BaseSettings.warnIfOrderCapitalExceed = boolNumeric.Checked;
                    BaseSettings.warnIfOrderCapitalExceedValue = boolNumeric.Value;
                }
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfTodayGrossLess');
            if (DynProperty.isCorrect(dp)) {
                const boolNumeric: BoolNumeric = dp.value;

                if (boolNumeric) {
                    BaseSettings.warnIfTodayGrossLess = boolNumeric.Checked;
                    BaseSettings.warnIfTodayGrossLessValue = boolNumeric.Value;
                }
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfAvailibleFundsLess');
            if (DynProperty.isCorrect(dp)) {
                const boolNumeric: BoolNumeric = dp.value;

                if (boolNumeric) {
                    BaseSettings.warnIfAvailibleFundsLess = boolNumeric.Checked;
                    BaseSettings.warnIfAvailibleFundsLesssValue = boolNumeric.Value;
                }
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfTodayVolumeExeed');
            if (DynProperty.isCorrect(dp)) {
                const boolNumeric: BoolNumeric = dp.value;

                if (boolNumeric) {
                    BaseSettings.warnIfTodayVolumeExeed = boolNumeric.Checked;
                    BaseSettings.warnIfTodayVolumeExeedValue = boolNumeric.Value;
                }
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.ShowInstrumentHalt');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.showInstrumentHalt = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnIfQtyToCloseMoreThanMaxLot');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.warnIfQtyToCloseMoreThanMaxLot = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.OvernightMarginNotificationMessage');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.overnightMarginNotificationMessage = dp.value;
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.WarnWhenEnteringIntoLeverage');
            if (DynProperty.isCorrect(dp)) {
                BaseSettings.warnWhenEnteringIntoLeverage = dp.value;
            }

            dc.EntitlementManager.callBackNotificationProperties(properties, fromJson); // #109924

            // TODO. Implement with TimeZone
            // dp = DynProperty.getPropertyByName(properties, "property.general.timezone.current");
            // if (DynProperty.isCorrect(dp))
            //    this.currentTimeZoneInfoId = dp.value;
            DateTimeConvertor.currentTimeZoneInfoId.value = TimeZoneInfo.LOCAL;

            dp = DynProperty.getPropertyByName(properties, 'property.general.timezone.favorites');
            if (DynProperty.isCorrect(dp)) {
                this.favoriteTimeZoneInfoIdsSet = TimeZoneInfo.deserializeToIdsSet(dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'property.general.Settings.TradingLock.TradingLocked');
            if (DynProperty.isCorrect(dp)) {
                TradingLockUtils.TradingLock.updateTradingLock(dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'chartSheets.itemsValue');
            if (DynProperty.isCorrect(dp)) {
                this.chartSheets.itemsValue = JSON.parse(dp.value);
            }

            dp = DynProperty.getPropertyByName(properties, 'savedOrdersSheets.itemsValue');
            if (DynProperty.isCorrect(dp)) {
                this.savedOrdersSheets.itemsValue = JSON.parse(dp.value);
            }

            this.AlertPanelCallBack(properties);

            // 123429
            HotkeysManager.CallBackProperties(properties);
        } catch (e) {
            console.log('Crashed while applying new settings. ' + e);
        }

        this.save();
    }

    public clear (): void {
        this.clearSessionSettings();
    }

    public save (): void {
        BaseSettings.onSettingsChanged();
    }

    public updateIsCashModePreferred (isCashMode: boolean): void {
        if (BaseSettings.isCashModePreferred !== isCashMode) {
            BaseSettings.isCashModePreferred = isCashMode;
            this.save();
        }
    }

    public updateFavoriteTimeZoneInfoIdsSet (idsSet): void {
        this.favoriteTimeZoneInfoIdsSet = idsSet;
        this.save();
    }

    public updateCurrentTimeZoneInfoId (currentTimeZoneInfoId): any {
        DateTimeConvertor.currentTimeZoneInfoId.value = currentTimeZoneInfoId;
        this.timeZoneChanged.Raise();
        this.save();
    }

    public updateCurrentTheme (currentTheme): any {
        BaseSettings.currentTheme = currentTheme;
        this.save();
    }

    public setSheetsData (storageName, valueOfLists, owner?): any {
        this[storageName].itemsValue = valueOfLists;
        this.save();
        this.sheetsChangedEvents[storageName].Raise(owner);
    }

    public updateInstrumentsDefaultSettings (settings): any {
        if (!settings) return;
        BaseSettings.insDefSettingsStorage.SetSettings(settings);
        this.save();
    }

    public overnightMarginNotificationMessage (showWarning): any {
        if (showWarning === undefined) return;

        if (BaseSettings.overnightMarginNotificationMessage !== showWarning) {
            BaseSettings.overnightMarginNotificationMessage = showWarning;
            this.SettingsChanged.Raise();
            this.save();
        }
    }

    public getDefValueFromObj (defVal, ifNotObj): any {
        if (defVal) return defVal;

        const objKeys = Object.keys(ifNotObj);

        if (objKeys.length > 0) {
            defVal = ifNotObj[objKeys[0]];
            return ifNotObj[objKeys[0]];
        }

        return null;
    }

    public getFirstTradableInstrument (defValue?): Instrument {
        if (defValue?.RouteIsTradable()) {
            return defValue;
        }

        const dCache = DataCache;
        const instruments = dCache.Instruments;

        for (const insId in instruments) {
            const ins = instruments[insId];
            if (ins.RouteIsTradable()) {
                return ins;
            }
        }

        return defValue;
    }

    public static createVariantsForProductTypes (array: any[]): any[] {
        const res = [];
        const len = array.length;
        for (let i = 0; i < len; i++) {
            const val = array[i];
            res.push({
                value: +val,
                text: Resources.getResource('ProductType.' + ProductType[val])
            });
        }
        return res;
    }

    public static createVariantsForOrderTypes (array: any[]): any[] {
        const limit = array.indexOf(3);
        const stop = array.indexOf(2);
        if (stop !== -1) {
            if (limit !== -1) {
                const temp = array[stop];
                array[stop] = array[limit];
                array[limit] = temp;
            }
        }
        const res = [];
        const len = array.length;
        for (let i = 0; i < len; i++) {
            const val = array[i];
            const lockKey = OrderUtils.getOrderTypeLocalizationKey(val);
            if (!Resources.isHidden('DefaultSettings.' + lockKey)) {
                res.push({
                    value: val,
                    text: Resources.getResource(lockKey)
                });
            }
        }
        return res;
    }

    // TODO доделать как ОЕ заменим
    public static createVariantsForOrderTypesAdvanced (array: any[]): any[] {
        const res = [];
        const len = array.length;
        const allowedKeys = DataCache.OrderParameterContainer.OrderTypes;
        for (let i = 0; i < len; i++) {
            const val = array[i];
            res.push({
                value: allowedKeys[OrderTypeMap[val]],
                text: Resources.getResource(OrderUtils.getOrderTypeLocalizationKey(val))
            });
        }
        return res;
    }

    public checkTifInArray (array: any[], type): any {
        if (array.includes(type)) {
            return type;
        } else if (array.length > 0) {
            return array[0];
        } else {
            return type;
        }
    }

    // TODO. Implement Multivendor scheme.
    public getDefaultTifForOrderType (ordType: OrderType): OrderTif {
        switch (ordType) {
        case OrderType.Market:
            return BaseSettings.orderTif;
        case OrderType.Limit:
        case OrderType.StopLimit:
            return BaseSettings.limitAndStopLimitValidity;
        case OrderType.Stop:
        case OrderType.TrailingStop:
            return BaseSettings.stopValidity;
        }
    }

    public OnBoardingWasShown (): boolean {
        return BaseSettings.onboardInFirstTimeShowed;
    }

    public IsCashModePreferred (): boolean { // #122525: If the user selected Cash, this value should be saved in the dropdown list for the next panel opening.
        return BaseSettings.isCashModePreferred;
    }

    public LogSnapshotHasBeenSent (): boolean { // #111072
        return BaseSettings.logSnapshotHasBeenSent;
    }

    public GetTriggers (): SLTPTrigger {
        return BaseSettings.slTpTriggers;
    }

    public GetWatchListDefaultItem (): {
        name: string
        symbolIds: string[]
        } { return DataCache.GetWatchListDefaultItem(); }

    private clearSessionSettings (): void {
        this.chartSheets = this.savedOrdersSheets = {};
    }

    public async requestDefaultWatchlistLists (): Promise<WatchlistSheetItem[]> {
        const watchListItems = await DataCache.SendDefaultAndCustomListRequest();
        const wlSheetitems: WatchlistSheetItem[] = [];
        for (let i = 0; i < watchListItems.length; i++) {
            wlSheetitems.push(new WatchlistSheetItem(watchListItems[i].name, new Set(watchListItems[i].symbolIds)));
        }
        return wlSheetitems;
    }
}

export const SessionSettings = new _SessionSettings();
export type SessionSettingsType = _SessionSettings;
