// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Instrument } from '@shared/commons/cache/Instrument';
import { BaseSettings } from '@shared/commons/Settings/BaseGeneralSettingsWrapper';
import { SlTpPriceType } from '../Enums/Constants';
import { Intervals } from '../Instruments/Intervals';
import { MathUtils } from '../MathUtils';
import { NumericUtils } from '../NumericUtils';
import { OffsetModeViewEnum } from './OffsetModeViewEnum';
import { type SlTpHolder } from './SlTpHolder';

class _SlTpUtils {
    public toVisualValue (realValue: number, instrument: Instrument | null | undefined, visualOffset: OffsetModeViewEnum | null | undefined): number {
        if (isNaN(realValue) || isNullOrUndefined(instrument)) {
            return realValue;
        }
        switch (visualOffset) {
        case OffsetModeViewEnum.Ticks:
            return MathUtils.Round(realValue, 0);
        case OffsetModeViewEnum.TicksFractionalForForex:
            if (instrument.AllowUseFractinalTicksForForex()) {
                return MathUtils.Round(realValue * 0.1, 1);
            } else {
                return MathUtils.Round(realValue, 0);
            }
        case OffsetModeViewEnum.Points:
            return MathUtils.Round(realValue * instrument.PointSize, instrument.Precision);
        case OffsetModeViewEnum.Percent:
            return MathUtils.Round(realValue, 2);
        default:
            return realValue;
        }
    }

    public toRealValue (visualValue: number, instrument: Instrument | null | undefined, viewMode: OffsetModeViewEnum | null | undefined): number {
        if (isNaN(visualValue) || isNullOrUndefined(instrument)) {
            return visualValue;
        }

        switch (viewMode) {
        case OffsetModeViewEnum.Ticks:
            return MathUtils.Round(visualValue, 0);
        case OffsetModeViewEnum.TicksFractionalForForex:
            if (instrument.AllowUseFractinalTicksForForex()) {
                return MathUtils.Round(visualValue * 10, 0);
            } else {
                return MathUtils.Round(visualValue, 0);
            }
        case OffsetModeViewEnum.Points:
            return MathUtils.Round(visualValue / instrument.PointSize, 0);
        case OffsetModeViewEnum.Percent:
            return MathUtils.Round(visualValue, 2);
        default:
            return visualValue;
        }
    }

    public calculatePercent (basePrice: number, sltpPrice: number): number {
        return MathUtils.Round(Math.abs((basePrice - sltpPrice)) / basePrice * 100, 2);
    }

    public precentToTicks (isBuy: boolean, isSl: boolean, basePrice: number, precent: number, instrument: Instrument): number {
        return MathUtils.Round(instrument.CalculateTicks(basePrice, basePrice * (precent * this.getDirectionSign(isBuy, isSl) / 100), true), 0);
    }

    public calculateTicks (basePrice: number, sltpPrice: number, instrument: Instrument): number {
        return MathUtils.Round(Math.abs(instrument.CalculateTicks(basePrice, sltpPrice - basePrice, true)), 0);
    }

    public getSlTpNumericParameters (parameters: SlTpNumericInputParameters): { min: number, max: number, step: number, precision: number } {
        const instrument = parameters.instrument;
        const offsetType = parameters.offsetType;
        if (isNullOrUndefined(instrument)) {
            return { min: 0, max: 0, step: 0, precision: 0 };
        }

        if (isNullOrUndefined(offsetType)) {
            return NumericUtils.getPriceNumericParameters(instrument);
        }

        const max = NumericUtils.MAXVALUE;
        const precision = instrument.Precision;
        const step = instrument.PointSize;
        const intervals = Intervals.GetIntervalsFromInstrument(instrument, max);

        switch (offsetType) {
        case OffsetModeViewEnum.Ticks:
            return { min: 1, max, step: 1, precision: 0 };
        case OffsetModeViewEnum.TicksFractionalForForex:
            if (instrument.AllowUseFractinalTicksForForex()) {
                return { min: 0.1, max, step: 0.1, precision: 1 };
            } else {
                return { min: 1, max, step: 1, precision: 0 };
            }
        case OffsetModeViewEnum.Points:
            if (isValidArray(intervals)) {
                return { min: intervals[0].LeftValue, max, step: intervals[0].LeftValue, precision: intervals[0].DecimalPlaces };
            } else {
                return { min: step, max, step, precision };
            }
        case OffsetModeViewEnum.Percent:
            return { min: this.calculateMinSlTpPercent(instrument, parameters.basePrice, parameters.isBuy, parameters.isSl), max: this.calculateMaxSlTpPercent(instrument, parameters.basePrice, parameters.isBuy, parameters.isSl), step: 0.01, precision: 2 };

        default:
            return { min: 0, max: 0, step: 0, precision: 0 };
        }
    }

    public getPriceDifferenceInTicks (price1: number, price2: number, instrument: Instrument | null): number {
        if (isNullOrUndefined(instrument)) {
            return 0;
        }
        const ticks = instrument.CalculateTicks(price1, price2 - price1, true);
        return MathUtils.Round(Math.abs(ticks), instrument.Precision);
    }

    public getSLTPPriceType (offsetMode: OffsetModeViewEnum | null): SlTpPriceType {
        switch (offsetMode) {
        case OffsetModeViewEnum.Ticks:
        case OffsetModeViewEnum.TicksFractionalForForex:
        case OffsetModeViewEnum.Points:
            return SlTpPriceType.Offset;
        case OffsetModeViewEnum.Percent:
            return SlTpPriceType.Percent;
        default:
            return SlTpPriceType.Absolute;
        }
    }

    public getSLTPPrice (instrument: Instrument, isBuy: boolean, isSl: boolean, basePrice: number, sltpValue: number, sltpPriceType: SlTpPriceType): number {
        const sign = this.getDirectionSign(isBuy, isSl);
        switch (sltpPriceType) {
        case SlTpPriceType.Absolute:
        case SlTpPriceType.ProtectiveAbsolute:
            return sltpValue;
        case SlTpPriceType.Offset:
        case SlTpPriceType.TrOffset:
            return instrument.CalculatePrice(basePrice, sign * sltpValue, true);
        case SlTpPriceType.Percent:
            return instrument.CalculatePrice(basePrice, sign * this.precentToTicks(isBuy, isSl, basePrice, sltpValue, instrument), true);
        default:
            return NaN;
        }
    }

    public getSLLPrice (instrument: Instrument, isBuy: boolean, basePrice: number, slValue: number, sllValue: number, slPriceType: SlTpPriceType): number {
        if (!isValidNumber(sllValue)) {
            return 0;
        }
        const sign = this.getDirectionSign(isBuy, true);
        const sllOffset = (slValue * sign) + (sllValue * sign);
        switch (slPriceType) {
        case SlTpPriceType.Absolute:
        case SlTpPriceType.ProtectiveAbsolute:
            return sllValue;
        case SlTpPriceType.Offset:
            return instrument.CalculatePrice(basePrice, sllOffset, true);
        default:
            return NaN;
        }
    }

    // returns offset in Ticks or Percent
    public getSLTPOffset (instrument: Instrument, basePrice: number, sltpValue: number, sltpPriceType: SlTpPriceType): number {
        const settingsOffsetType = BaseSettings.offsetType;
        switch (sltpPriceType) {
        case SlTpPriceType.Absolute:
        case SlTpPriceType.ProtectiveAbsolute:
            return settingsOffsetType === OffsetModeViewEnum.Percent ? this.calculatePercent(basePrice, sltpValue) : this.calculateTicks(basePrice, sltpValue, instrument);
        default:
            return sltpValue;
        }
    }

    public getSLLOffset (instrument: Instrument, slValue: number, sllValue: number, slPriceType: SlTpPriceType): number {
        switch (slPriceType) {
        case SlTpPriceType.Offset:
            return sllValue;
        case SlTpPriceType.Absolute:
        case SlTpPriceType.ProtectiveAbsolute:
        {
            const priceDiff = slValue - sllValue;
            const sign = Math.sign(priceDiff);
            return MathUtils.Round(sign * instrument.CalculateTicks(slValue, priceDiff, true), 0);
        }
        default:
            return NaN;
        }
    }

    public formatSLTPValue (sltpValue: number, instrument: Instrument | null | undefined, offsetType: OffsetModeViewEnum | null | undefined): string {
        if (!isValidNumber(sltpValue)) {
            return '';
        }
        if (isNullOrUndefined(instrument)) {
            return sltpValue.toString();
        }
        switch (offsetType) {
        case OffsetModeViewEnum.Ticks:
            return sltpValue.toFixed(0);
        case OffsetModeViewEnum.TicksFractionalForForex:
            if (instrument.AllowUseFractinalTicksForForex()) {
                return sltpValue.toFixed(1);
            } else {
                return sltpValue.toFixed(0);
            }
        case OffsetModeViewEnum.Points:
            return instrument.formatPrice(sltpValue);
        case OffsetModeViewEnum.Percent:
            return sltpValue.toFixed(2);
        default:
            return instrument.formatPrice(sltpValue);
        }
    }

    public getDefaultVisualOffsetType (instrument: Instrument | null | undefined): OffsetModeViewEnum {
        const settingsOffsetType = BaseSettings.offsetType;
        if (settingsOffsetType === OffsetModeViewEnum.TicksFractionalForForex && !isNullOrUndefined(instrument)) {
            return instrument.AllowUseFractinalTicksForForex() ? settingsOffsetType : OffsetModeViewEnum.Ticks;
        } else {
            return settingsOffsetType;
        }
    }

    public getDefaultVisualOffsetTypeExcludePercent (instrument: Instrument): OffsetModeViewEnum {
        const defaultOffsetType = this.getDefaultVisualOffsetType(instrument);
        return defaultOffsetType === OffsetModeViewEnum.Percent ? OffsetModeViewEnum.Ticks : defaultOffsetType;
    }

    public getVisualOffsetType (instrument: Instrument, sltpType: SlTpPriceType): OffsetModeViewEnum | null {
        const defaultVisualOffsetType = this.getDefaultVisualOffsetType(instrument);
        switch (sltpType) {
        case SlTpPriceType.TrOffset:
            return this.getDefaultVisualOffsetTypeExcludePercent(instrument);
        case SlTpPriceType.Offset:
            return defaultVisualOffsetType === OffsetModeViewEnum.Percent ? OffsetModeViewEnum.Ticks : defaultVisualOffsetType;
        case SlTpPriceType.Percent:
            return OffsetModeViewEnum.Percent;
        default:
            return null;
        }
    }

    public getSLVisualOffsetType (instrument: Instrument, sltpHolder: SlTpHolder): OffsetModeViewEnum | null {
        if (sltpHolder.isSlEmpty()) {
            return sltpHolder.isTpEmpty() ? this.getDefaultVisualOffsetType(instrument) : this.getTPVisualOffsetType(instrument, sltpHolder);
        } else {
            return this.getVisualOffsetType(instrument, sltpHolder.StopLossPriceType);
        }
    }

    public getTPVisualOffsetType (instrument: Instrument, sltpHolder: SlTpHolder): OffsetModeViewEnum {
        if (sltpHolder.isTpEmpty()) {
            return sltpHolder.isSlEmpty() ? this.getDefaultVisualOffsetType(instrument) : this.getSLVisualOffsetType(instrument, sltpHolder);
        } else {
            return this.getVisualOffsetType(instrument, sltpHolder.TakeProfitPriceType);
        }
    }

    public getSymbolForOffsetMode (offsetMode: OffsetModeViewEnum): string {
        if (isNullOrUndefined(offsetMode)) {
            return '';
        }
        switch (offsetMode) {
        case OffsetModeViewEnum.Ticks:
            return 't';
        case OffsetModeViewEnum.TicksFractionalForForex:
            return 'f';
        case OffsetModeViewEnum.Points:
            return 'p';
        case OffsetModeViewEnum.Percent:
            return '%';
        default:
            return '';
        }
    }

    public getTextForOffsetMode (offsetMode: OffsetModeViewEnum): string {
        if (isNullOrUndefined(offsetMode)) {
            return '';
        }
        switch (offsetMode) {
        case OffsetModeViewEnum.Ticks:
            return 'ticks';
        case OffsetModeViewEnum.TicksFractionalForForex:
            return 'f_ticks';
        case OffsetModeViewEnum.Points:
            return 'points';
        case OffsetModeViewEnum.Percent:
            return '%';
        default:
            return '';
        }
    }

    public getVisualOffsetTypeReportText (offsetMode: OffsetModeViewEnum | null | undefined): string {
        switch (offsetMode) {
        case OffsetModeViewEnum.Points:
            return 'points';
        case OffsetModeViewEnum.Percent:
            return '%';
        default:
            return '';
        }
    }

    public getLocalizationForOffsetMode (offsetMode: OffsetModeViewEnum): string {
        if (isNullOrUndefined(offsetMode)) {
            return '';
        }
        switch (offsetMode) {
        case OffsetModeViewEnum.Ticks:
        case OffsetModeViewEnum.TicksFractionalForForex:
            return 'general.trading.pips';
        case OffsetModeViewEnum.Points:
            return 'general.trading.points';
        case OffsetModeViewEnum.Percent:
            return 'general.trading.percent';
        default:
            return '';
        }
    }

    private calculateMinSlTpPercent (instrument: Instrument, basePrice: number, isBuy: boolean, isSl: boolean): number {
        if (!isValidNumber(basePrice) || basePrice === 0) {
            return 0.01;
        }
        const sign = this.getDirectionSign(isBuy, isSl);
        const sltpPrice = instrument.CalculatePrice(basePrice, sign);
        const percent = Math.abs(1 - basePrice / sltpPrice) * 100;
        return MathUtils.CeilToDecimalPlaces(percent, 2);
    }

    private calculateMaxSlTpPercent (instrument: Instrument, basePrice: number, isBuy: boolean, isSl: boolean): number {
        if (!isValidNumber(basePrice) || basePrice === 0) {
            return NumericUtils.MAXVALUE;
        }
        const isNegativePosible = (isBuy && isSl) || (!isBuy && !isSl);
        if (!isNegativePosible) {
            return NumericUtils.MAXVALUE;
        }
        const slTpPrice = instrument.CalculatePrice(0, 1);
        const percent = Math.abs(1 - slTpPrice / basePrice) * 100;
        return MathUtils.FloorToDecimalPlaces(Math.min(99.99, percent), 2);
    }

    private getDirectionSign (isBuy: boolean, isSl: boolean): number {
        if (isBuy) {
            return isSl ? -1 : 1;
        } else {
            return isSl ? 1 : -1;
        }
    }
}

export class SlTpNumericInputParameters {
    public readonly instrument: Instrument;
    public readonly offsetType: OffsetModeViewEnum;
    public readonly basePrice: number | undefined;
    public readonly isBuy: boolean | undefined;
    public readonly isSl: boolean | undefined;

    constructor (instrument: Instrument, offetType: OffsetModeViewEnum, basePrice: number | undefined = undefined, isBuy: boolean | undefined = undefined, isSl: boolean | undefined = undefined) {
        this.instrument = instrument;
        this.offsetType = offetType;
        this.basePrice = basePrice;
        this.isBuy = isBuy;
        this.isSl = isSl;
    }
}

export const SlTpUtils = new _SlTpUtils();
