// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { SubscriptionStrategyRequestMessage } from '../Messages/SubscriptionStrategyRequestMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class SubscriptionStrategyRequestMessageProccesor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): SubscriptionStrategyRequestMessage[] {
        const message = new SubscriptionStrategyRequestMessage(fieldSet);
        return [message];
    }
}
