// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraChartCashItemSeriesDataType, TerceraChartCashItemSeriesDataTypeBasisType } from './TerceraChartCashItemSeriesEnums';
import { ChartDataType } from '../Utils/ChartConstants';
import { type TerceraChartMVCModel } from '../TerceraChartMVC';

export class TerceraChartCashItemSeriesSettings {
    public chartMVCModel: TerceraChartMVCModel;

    public relativeDataConverter: any;
    public logDataConverter: any;

    public profilePOCType = ProfilePOCTypesEnum.Marker;
    public profileVAType = ProfileVATypesEnum.Vertical;

    public clusterMinimumStep = 0; // расчетное поле (минимальный степ, чтобы текст не налезал)
    public clusterCurrentStep = 0; // расчетное поле (текущий степ с учетом всех настроек)

    // Тип данных: абсолютные/относительные/логарифмические
    public DataType = TerceraChartCashItemSeriesDataType.Absolute;
    // Откуда брать значения для расчета относительных шкал
    public BasisType = TerceraChartCashItemSeriesDataTypeBasisType.BeginOfScreen;

    public showVolume = true;
    public UseTicksData = false;
    public ActiveConverter = null;
    public profileMinimumStep: number;

    constructor (model: TerceraChartMVCModel) {
        this.chartMVCModel = model;

        /* TODO.
        this.relativeDataConverter = new TerceraChartSeriesRelativeDataConverter();
        this.relativeDataConverter.BasisValue = 1;
        this.logDataConverter = new TerceraChartSeriesLogDataConverter();
        */
        this.relativeDataConverter = { BasisValue: 1 };
        this.logDataConverter = { LogBase: 0.0 };
    }

    // #region Renderers
    get ShowEmptyBars (): boolean {
        const chartMVCModel = this.chartMVCModel;
        const tfInfo = chartMVCModel.GetTimeFrameInfo();
        return chartMVCModel.GetShowZeroes() && (!tfInfo || tfInfo.ChartDataType !== ChartDataType.HeikinAshi);
    }
    // #endregion
}

export enum ProfilePOCTypesEnum {
    None = 0,
    Marker = 1,
    Line = 2
}

export enum ProfileVATypesEnum {
    None = 0,
    Vertical = 1,
    Horizontal = 2,
    ToNextProfile = 3
}
