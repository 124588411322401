// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { PlateBase } from './PlateBase';
import { PriceFormatter } from '../../Utils/Instruments/PriceFormatter';
import { type SolidBrush } from '../../Commons/Graphics';

export class PlateOverlay extends PlateBase {
    public ValueBrush: any;
    public terceraChartOverlay: any;

    constructor (terceraChartOverlay) {
        super();

        this.terceraChartOverlay = terceraChartOverlay;
        this.isOverlay = true;
    }

    public override GetHover (): any {
        return this.terceraChartOverlay.Selected;
    }

    public override SetHover (value): void {
        this.terceraChartOverlay.Selected = value;
    }

    public override GetSelected (): boolean {
        return this.terceraChartOverlay.chart.ActiveOverlayGet() == this.terceraChartOverlay;
    }

    public override SetSelected (): void {
        this.terceraChartOverlay.chart.FocusedOverlay = this.terceraChartOverlay;
        this.terceraChartOverlay.chart.ActiveOverlaySet(this.terceraChartOverlay);
    }

    public GetVisible (): any {
        return this.terceraChartOverlay.mainPriceRenderer.Visible;
    }

    public getTextName (): string {
        if (this.terceraChartOverlay.ActiveInstrument != null) {
            return Resources.getResource('chart.menu.PlateOverlay.Overlay') + ': ' + this.terceraChartOverlay.ActiveInstrument.DisplayName();
        }

        return 'Empty symbol';
    }

    public getTextValue (lastMouseHoverBarIndex, lineNumber, seriesSettings = null): string {
        if (this.terceraChartOverlay.mainPriceRenderer.Series == null) {
            return '';
        }

        // http://tp.pfsoft.net/entity/41138
        if (lastMouseHoverBarIndex < 0) {
            return '---';
        }

        if (!this.terceraChartOverlay.mainPriceRenderer.Series.CashItem.FNonEmptyCashArray[lastMouseHoverBarIndex]) {
            return '---';
        }

        let value = this.terceraChartOverlay.mainPriceRenderer.Series.CashItem.FNonEmptyCashArray[lastMouseHoverBarIndex].Close;

        if (Math.abs(value) == Number.MAX_VALUE || isNaN(value)) {
            value = 0;
        }

        if (this.terceraChartOverlay.ActiveInstrument != null) {
            const dataType = seriesSettings != null ? seriesSettings.DataType : TerceraChartCashItemSeriesDataType.Absolute;
            switch (dataType) {
            case TerceraChartCashItemSeriesDataType.Relative:
                return seriesSettings.relativeDataConverter.Calculate(value) + '%';
            }

            return this.terceraChartOverlay.ActiveInstrument.formatPrice(value);
        } else {
            return PriceFormatter.formatPrice(value, 5);
        }
    }

    public override getBrush (lineNumber): SolidBrush {
        if (this.terceraChartOverlay.HistoryType === HistoryType.ASK) {
            return this.terceraChartOverlay.AskOverlayBrush;
        } else if (this.terceraChartOverlay.HistoryType === HistoryType.LAST) {
            return this.terceraChartOverlay.LastOverlayBrush;
        } else {
            return this.terceraChartOverlay.BidOverlayBrush;
        }
    }

    public override getLinesCount (): number {
        return 1; // close only
    }

    public override Dispose (): void {
        this.terceraChartOverlay = null;
    }

    public override getTypeName (): string {
        return this.getPropertiesText() + ' ' + this.getTextName();
    }

    public override getCloseTT (): string {
        return this.getCloseText() + ' ' + this.getTextName();
    }

    public override getVisibilityTT (): string {
        return 'Show/Hide overlay lines';
    }

    public override getPropertiesText (): string {
        return Resources.getResource('chart.menu.PlateOverlaySettings');
    }

    public override getCloseText (): string {
        return Resources.getResource('chart.menu.PlateOverlayRemove');
    }

    public override getVisibilityText (): string {
        return Resources.getResource('chart.menu.PlateOverlayVisible');
    }
}
