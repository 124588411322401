// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Periods } from '../../../Utils/History/TFInfo';
import { IndicatorDataType, IndicatorHistoryType, IndicatorBarsAggregations } from './IndicatorEnums';

export class HistoricalDataRequest {
    public Instrument: any = null;
    // Defines the period of historical elements.
    public Period = 0;
    // Defines special parameter for  historical type.
    public HistorySize = 0;
    // The amount of price movement required to shift a chart to the right.
    // This condition is used on charts that only take into consideration
    // price movement instead of both price and time.
    public Reversal = 0;
    // Defines the data type in created historical data request.
    public DataType = IndicatorDataType.Bid;
    // Defines the history building type in created historical data request.
    public HistoryType = IndicatorHistoryType.Simple;
    // Defines special style of building history. Use only for Renko and PnF.
    public HistoryMode = IndicatorBarsAggregations.None;

    public newBoxOnBreak = false;
    public buildCurrentBar = true;

    public fromUTC: any = null;
    public toUTC: any = null;

    public PeriodValue: number;

    private inversion = 100;
    private extension = 100;

    constructor (params) {
        if ('Instrument' in params) {
            this.Instrument = params.Instrument;
        }
        if ('Period' in params) {
            this.Period = params.Period;
        }
        if ('DataType' in params) {
            this.DataType = params.DataType;
        }
        if ('HistoryType' in params) {
            this.HistoryType = params.HistoryType;
        }
        if ('HistorySize' in params) {
            this.HistorySize = params.HistorySize;
        }
        if ('Reversal' in params) {
            this.Reversal = params.Reversal;
        }
        if ('HistoryMode' in params) {
            this.HistoryMode = params.HistoryMode;
        }
        if ('Extension' in params) {
            this.Extension = params.Extension;
        }
        if ('Inversion' in params) {
            this.Inversion = params.Inversion;
        }
        if ('fromUTC' in params) {
            this.fromUTC = params.fromUTC;
        }
        if ('toUTC' in params) {
            this.toUTC = params.toUTC;
        }

        this.PeriodValue = params.PeriodValue || 1;
    }

    // Defines Extension, % only for Renko Advanced mode
    get Extension (): number {
        if (this.HistoryMode !== IndicatorBarsAggregations.Advanced) {
            return 100;
        }

        return this.extension;
    }

    set Extension (value) {
        this.extension = value;
    }

    // Defines Inversion, % only for Renko Advanced mode
    get Inversion (): number {
        if (this.HistoryMode !== IndicatorBarsAggregations.Advanced) {
            return 100;
        }

        return this.inversion;
    }

    set Inversion (value) { this.inversion = value; }

    // NewBoxOnBreak, for Renko Mode
    // (Allow create new brick when price greater then brick size if True
    // or allow create new brick when price greater or equal then brick size if False)
    get NewBoxOnBreak (): boolean { return this.newBoxOnBreak; }
    set NewBoxOnBreak (value) { this.newBoxOnBreak = value; }

    // BuildCurrentBar, for Renko mode allow build last uncompleted bar
    get BuildCurrentBar (): boolean { return this.buildCurrentBar; }
    set BuildCurrentBar (value) { this.buildCurrentBar = value; }

    public Clone (): HistoricalDataRequest {
        return new HistoricalDataRequest(this);
    }

    public GetAdditionalData (): string {
        const HistoryTypeEnum = IndicatorHistoryType;
        const HistorySize = this.HistorySize;

        switch (this.HistoryType) {
        case HistoryTypeEnum.Kagi:
        case HistoryTypeEnum.LineBreak:
            return this.Reversal.toString();

            /* TODO.
    case HistoryTypeEnum.PnF:
        return HistorySize + '$' + (int)HistoricalData.GetStyle(HistoryMode) + "$" + Reversal;

    case HistoryTypeEnum.Renko:
        let showWicks = false
        RenkoStyle renkoStyle = HistoricalData.GetRenkoStyle(HistoryMode, out showWicks);
        return (new TFAggregationModeRencoNew(renkoStyle, HistorySize, (int)Extension, (int)Inversion, showWicks, BuildCurrentBar, NewBoxOnBreak)).AdditionalKey;
    */

        case HistoryTypeEnum.Simple:
        default:
            return this.Period === Periods.RANGE ? HistorySize.toString() : '';
        }
    }
}
