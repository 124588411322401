// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TvDurationConvertor } from '../Convertors/TvDurationConvertor';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { tvch } from '../Helpers/TvConsoleHelper';
import { TvSymbolsManager } from '../Managers/TvSymbolsManager';
import { type IBrokerConnectionAdapterHost, type BrokerConfigFlags, type OrderDurationMetaInfo } from '../charting_library';
import { TvBrokerConfigFlags } from './TvBrokerConfigFlags';
import equal from 'fast-deep-equal';

export class TvRuleValidator {
    private readonly host: IBrokerConnectionAdapterHost;
    private prevBrokerConfigFlags: BrokerConfigFlags | null = null;
    private prevAllowedDurations: OrderDurationMetaInfo[] | null = null;

    constructor (host: IBrokerConnectionAdapterHost) {
        this.host = host;
    }

    public applyIfNeed (): void {
        const instrument = TvSymbolsManager.getCurrentInstrument();
        const account = TvAccountHelper.getCurrentAccount();
        if (instrument === null || account === null) return;

        const brokerConfigFlags = TvBrokerConfigFlags.getCongiFlags(instrument, account);
        if (!equal(brokerConfigFlags, this.prevBrokerConfigFlags)) {
            this.host.patchConfig(brokerConfigFlags);
            tvch.setBrokerConfigFlags(brokerConfigFlags);
        }
        this.prevBrokerConfigFlags = brokerConfigFlags;

        const allowedDurations = TvDurationConvertor.getAllowedDurationsForInstrumentAccount(instrument, account);
        if (!equal(allowedDurations, this.prevAllowedDurations)) {
            this.host.setDurations(allowedDurations);
            tvch.setAllowedDurations(allowedDurations);
        }
        this.prevAllowedDurations = allowedDurations;
    }

    public isTradePosible (): boolean {
        return isValidArray(this.prevAllowedDurations);
    }
}
