// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '../../../../Utils/Account/AccountFeature';
import { Account } from '../../../cache/Account';

export class TvAccountActivityData {
    public openGrossPL: number = 0;
    public openNetPL: number = 0;
    public openPositionsCount: number = 0;
    public openOrdersCount: number = 0;

    public update (acc: Account): boolean {
        let isNeedUpdate = false;
        const openGrossPL = Account.GetAccountFeature(AccountFeature.OpenGrossPL, acc, acc.assetBalanceDefault);
        if (openGrossPL !== this.openGrossPL) {
            this.openGrossPL = openGrossPL;
            isNeedUpdate = true;
        }

        const openNetPL = Account.GetAccountFeature(AccountFeature.OpenNetPL, acc, acc.assetBalanceDefault);
        if (openNetPL !== this.openNetPL) {
            this.openNetPL = openNetPL;
            isNeedUpdate = true;
        }

        const openPositionsCount = Account.GetAccountFeature(AccountFeature.OpenPostionsNumber, acc, acc.assetBalanceDefault);
        if (openPositionsCount !== this.openPositionsCount) {
            this.openPositionsCount = openPositionsCount;
            isNeedUpdate = true;
        }

        const openOrdersCount = Account.GetAccountFeature(AccountFeature.OpenOrdersNumber, acc, acc.assetBalanceDefault);
        if (openOrdersCount !== this.openOrdersCount) {
            this.openOrdersCount = openOrdersCount;
            isNeedUpdate = true;
        }

        return isNeedUpdate;
    }
}
