// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../../../Commons/DataCache';
import { Resources } from '../../../../Localizations/Resources';
import { AccountType } from '../../../../Utils/Account/AccountType';
import { AccountActivityGroupEnum } from '../../../../Utils/Enums/AccountDetailsEnum';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { BaseAccountGroupController } from '../BaseControllers/BaseAccountGroupController';
import { type IGroupController } from '../../../../Commons/VerticalGroupedPanels/Interfaces/IGroupController';
import { RowItemDataNumberPair, RowItemDataStringPair } from '../../../cache/VerticalPanel/AccountDetails/RowItemData';
import { AccountFeature } from '../../../../Utils/Account/AccountFeature';
import { Account } from '../../../cache/Account';
import { VerticalPanelColoringTypes } from '../../VerticalPanelColoringTypes';

export class AccountActivityGroupController<GroupItemsType = AccountActivityGroupEnum> extends BaseAccountGroupController<GroupItemsType> implements IGroupController<GroupItemsType> {
    public getGroupItemsTypes (): GroupItemsType[] {
        return [
            // AccountActivityGroupEnum.CurrentFundCapital,
            // AccountActivityGroupEnum.FundCapitalGain,
            AccountActivityGroupEnum.Profit,
            AccountActivityGroupEnum.ProfitNet,
            AccountActivityGroupEnum.Positions,
            AccountActivityGroupEnum.Orders,
            AccountActivityGroupEnum.TotalPositionsValue
            // AccountActivityGroupEnum.BlockedByPamm
        ] as GroupItemsType[];
    }

    public getItemValue (type: GroupItemsType): RowItemDataNumberPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;

        switch (type) {
        case AccountActivityGroupEnum.CurrentFundCapital:
            return new RowItemDataNumberPair(curAccount.CurrPammCapital);
        case AccountActivityGroupEnum.FundCapitalGain:
            return new RowItemDataNumberPair(0.0);
        case AccountActivityGroupEnum.Profit:
            return new RowItemDataNumberPair(curAccount.Profit);
        case AccountActivityGroupEnum.ProfitNet:
            return new RowItemDataNumberPair(curAccount.ProfitNet ? curAccount.ProfitNet : 0);
        case AccountActivityGroupEnum.Positions:
            return new RowItemDataNumberPair(curAccount.AccountType === AccountType.MultiAsset ? assetBalance.OpenPosNumber : curAccount.OpenPosNumber);
        case AccountActivityGroupEnum.Orders:
            return new RowItemDataNumberPair(curAccount.AccountType === AccountType.MultiAsset ? assetBalance.OpenOrdNumber : curAccount.OpenOrdNumber);
        case AccountActivityGroupEnum.BlockedByPamm:
            return new RowItemDataNumberPair(curAccount.StartPammCapital);
        case AccountActivityGroupEnum.TotalPositionsValue:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.TotalPositionsValue, curAccount, assetBalance));
        }
    }

    public getItemFormattedValue (type: GroupItemsType): RowItemDataStringPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;
        const value = this.getItemValue(type);

        switch (type) {
        case AccountActivityGroupEnum.CurrentFundCapital:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(value.firstValue));
        case AccountActivityGroupEnum.FundCapitalGain:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(0));
        case AccountActivityGroupEnum.Profit:
            return new RowItemDataStringPair(assetBalance.formatPrice(curAccount.Profit));
        case AccountActivityGroupEnum.ProfitNet:
            return new RowItemDataStringPair((!isNaN(curAccount.ProfitNet) !== null
                ? assetBalance.formatPrice(curAccount.ProfitNet)
                : Resources.getResource('general.Calculating')));
        case AccountActivityGroupEnum.Positions:
            return new RowItemDataStringPair(value.firstValue.toString());
        case AccountActivityGroupEnum.Orders:
            return new RowItemDataStringPair(value.firstValue.toString());
        case AccountActivityGroupEnum.BlockedByPamm:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(value.firstValue));
        case AccountActivityGroupEnum.TotalPositionsValue:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.TotalPositionsValue, curAccount, assetBalance));
        }
    }

    public getItemNameKey (type: GroupItemsType): string {
        switch (type) {
        case AccountActivityGroupEnum.CurrentFundCapital:
            return 'panel.accounts.CurrentFundCapital';
        case AccountActivityGroupEnum.FundCapitalGain:
            return 'panel.accounts.FundCapitalGain';
        case AccountActivityGroupEnum.Profit:
            return 'panel.accounts.Profit';
        case AccountActivityGroupEnum.ProfitNet:
            return 'panel.accounts.ProfitNet';
        case AccountActivityGroupEnum.Positions:
            return 'panel.accounts.Positions';
        case AccountActivityGroupEnum.Orders:
            return 'panel.accounts.Orders';
        case AccountActivityGroupEnum.BlockedByPamm:
            return 'panel.accounts.BlockedByPamm';
        case AccountActivityGroupEnum.TotalPositionsValue:
            return 'panel.accounts.TotalPositionsValue';
        }
    }

    public isHiddenItemRow (type: GroupItemsType): boolean {
        const itemNameKey = this.getItemNameKey(type);
        if (Resources.isHidden(itemNameKey)) {
            return true;
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_EXT_RISK)) {
            return true;
        }
    }

    public getColoringMethod (type: GroupItemsType): VerticalPanelColoringTypes {
        return VerticalPanelColoringTypes.Default;
    }
}
