// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoryMergerInputParams } from './HistoryMergerInputParams';
import { type IHistoryMerger } from './IHistoryMerger';

export interface IHistoryMergerCreator {

    GetHistoryMerger: (inputParams: HistoryMergerInputParams) => IHistoryMerger
}

let historyCreatorInstance: IHistoryMergerCreator;

export function SetHistoryCreatorInstance (historyCreator: IHistoryMergerCreator): void {
    historyCreatorInstance = historyCreator;
}

export function GetHistoryCreator (): IHistoryMergerCreator {
    return historyCreatorInstance;
}
