// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type SearchSymbolResultItem } from '../charting_library';

export class TvSearchSymbolResultItem implements SearchSymbolResultItem {
    public description: string;
    public exchange: string;
    public full_name: string;
    public symbol: string;
    public ticker: string;
    public type: string;

    public logo_urls?: [string] | [string, string];
    public exchange_logo?: string;
}
