// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Connection } from '@shared/commons/Connection';
import { UserKey } from '@shared/commons/UtilsClasses/UserKey';
import { WorkSpaceUserKey, type WorkSpaceUserStorageType } from '@shared/commons/UtilsClasses/WorkSpaceUserKey';
import { Md5 } from 'ts-md5';

class _UserManager {
    public getBeforeLoginKey (): string {
        return Md5.hashStr('BeforeLoginKey');
    }

    public getUserKey (): string | null {
        const login = Connection?.vendor?.login ?? null;
        const connectionName = Connection?.vendor?.loginParams?.connectionName ?? null;

        if (!login || !connectionName) { return null; }

        const wuk = new UserKey(login, connectionName);
        return wuk.getKey();
    }

    public getWorkSpaceKey (storageType: WorkSpaceUserStorageType): string | null {
        const login = Connection?.vendor?.login ?? null;
        const connectionName = Connection?.vendor?.loginParams?.connectionName ?? null;

        if (!login || !connectionName) { return null; }

        const wuk = new WorkSpaceUserKey(login, connectionName, storageType);
        return wuk.getKey();
    }
}

export const UserManager = new _UserManager();
