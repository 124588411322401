// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { VariableTick } from '../../../../Utils/Instruments/VariableTick';

export class VariableTickGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.VARIABLE_TICK_GROUP, null, pfixFieldSet);
    }

    public static ImproveTicksIntervals (variableTickGroups, pointsize): VariableTick[] {
        const ff = FieldsFactory;
        const VariableTickList: VariableTick[] = [];
        const lowLimits = [];

        const len = variableTickGroups.length;
        for (let i = 0; i < len; i++) {
            const group = variableTickGroups[i];
            lowLimits.push(group.GetValue(ff.FIELD_ALLOW_LIMIT));
        }

        for (let i = 0; i < len; i++) {
            const group = variableTickGroups[i];
            const allowLimit = group.GetValue(ff.FIELD_ALLOW_LIMIT);
            const allowerLowerLimit =
            allowLimit === null
                ? lowLimits.length > i + 1 && lowLimits[i + 1] !== null ? !lowLimits[i + 1] : true
                : allowLimit;

            const highLimit =
            i !== variableTickGroups.length - 1
                ? variableTickGroups[i + 1].GetValue(ff.FIELD_PRICE_LOW_LIMIT)
                : Number.POSITIVE_INFINITY;

            VariableTickList.push(new VariableTick({
                lowLimit: group.GetValue(ff.FIELD_PRICE_LOW_LIMIT),
                highLimit,
                allowLimit: allowerLowerLimit,
                pointSize: group.GetValue(ff.FIELD_POINTSIZE),
                tickCost: group.GetValue(ff.FIELD_TICK_COAST) || 1
            }));
        }

        // сервер как правило передает нам интервалы в виде [number, + infinity) или (number, + infinity)
        // здесь мы дополняем дипапзон так чтобы у нас всегда был интервал вида (-infinity, +infinity)
        if (VariableTickList.length > 0) {
            VariableTickList.splice(0, 0, new VariableTick({
                lowLimit: Number.NEGATIVE_INFINITY,
                highLimit: VariableTickList[0].LeftBorder,
                allowLimit: VariableTickList[0].IncludeLeftBorder,
                pointSize: VariableTickList[0].PointSize,
                tickCost: VariableTickList[0].TickCost
            }));
        } else {
            VariableTickList.push(new VariableTick({
                lowLimit: Number.NEGATIVE_INFINITY,
                highLimit: Number.POSITIVE_INFINITY,
                allowLimit: true,
                pointSize: pointsize,
                tickCost: 1
            }));
        }

        return VariableTickList;
    }
}
