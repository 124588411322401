// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { OrderItem } from '../cache/OrderItem';
import { ColouringModes } from '../elements/QuickTable/QuickTableColumn';
import { TradingButtonItem } from '../elements/TradingButtonStripe';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { OrdersPanelBase } from './OrdersPanelBase';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { IsAllowed, type IsAllowedResponce } from '@shared/commons/IsAllowed';
import { OrderActionEnum } from '@shared/utils/Trading/OrderActionEnum';
import { NumericLinks } from '@shared/commons/cache/OrderParams/order-edit/OrderEditBase';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { type Order } from '@shared/commons/cache/Order';
import { type Position } from '@shared/commons/cache/Position';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { WorkingOrdersHotkeysEnum } from '@shared/commons/cache/Hotkeys/NamesEventHotkey ';
import { HotkeysManager } from '@shared/commons/cache/Hotkeys/HotkeysManager';

export class OrdersPanel extends OrdersPanelBase<OrderItem> {
    constructor () {
        super();
        this.Name = 'OrdersPanel';
        this.headerLocaleKey = 'panel.orders';
        this.moreThanOneTTKey = 'panel.orders.menu.MoreOneSymbolInfoDisabled.tt'; // #95439
        this.noOneTTKey = 'panel.orders.menu.NoSymbolInfoDisabled.tt';
    }
    // #region Overrides

    public override oninit (): void {
        super.oninit();
        this.processTableMouseDown = this.processTableMouseDown.bind(this);
    }

    public override oncomplete (): void {
        super.oncomplete();
        const quickTable = this.getQuickTable();
        if (!isNullOrUndefined(quickTable)) {
            quickTable.onTableMouseDown.Subscribe(this.onTableMouseDown, this);
        }
    }

    // #region ApplicationPanelWithTable
    public override getType (): PanelNames { return PanelNames.OrdersPanel; }
    public override SetColumnsDefaultDisplayIndex (table: QuickTable): void {
        table.columns[0].displayedIndex = 0;
        table.columns[1].displayedIndex = 1;
        table.columns[4].displayedIndex = 2;
        table.columns[11].displayedIndex = 3;
        table.columns[6].displayedIndex = 4;
        table.columns[7].displayedIndex = 5;
        table.columns[9].displayedIndex = 6;
        table.columns[5].displayedIndex = 7;
        table.columns[16].displayedIndex = 8;
        table.columns[35].displayedIndex = 9;

        table.columns[46].displayedIndex = 99999; // Close button column
    }

    public override SetColumnsColouringMode (table: QuickTable): void {
        super.ProcessSetColumnsColouringMode(table, [8], ColouringModes.Previous, undefined, undefined);
        table.columnsIndexWithColoringByPrevValue = [8];
    }

    public override populateItemsDirect (): void {
        super.populateItemsDirect();
        const orderDict = DataCache.getAllOrders();
        for (const id in orderDict) {
            this.AddOrderEvent(orderDict[id]);
        }
    }

    public override createMenuItems (): any[] {
        const actions = OrderActionEnum;
        const placedFromVal = PlacedFrom.WEB_ORDERS_PANEL_CONTEXT_MENU;

        let items = [
            {
                locKey: 'panel.orders.menu.ModifyOrder',
                tag: actions.ModifyOrder,
                enabled: true,
                canCheck: false,
                checked: false,
                event: this.action.bind(this, actions.ModifyOrder, true, placedFromVal, null)
            },
            {
                locKey: 'panel.orders.menu.ExecuteAsMarket',
                tag: actions.ChangeToMarket,
                canCheck: false,
                checked: false,
                enabled: true,
                visible: !Resources.isHidden('panel.orders.menu.ExecuteAsMarket'),
                event: this.action.bind(this, actions.ChangeToMarket, true, placedFromVal)
            },
            {
                locKey: 'panel.orders.menu.Remove',
                tag: 'CloseGroup',
                canCheck: false,
                checked: false,
                enabled: true,
                subitems: [
                    {
                        locKey: 'panel.orders.menu.CancelOrder',
                        tag: actions.ByIds,
                        canCheck: false,
                        checked: false,
                        enabled: true,
                        event: this.action.bind(this, actions.ByIds, true, placedFromVal)
                    },
                    {
                        locKey: 'panel.orders.menu.CancelAllByInstrument',
                        tag: actions.ByInstrument,
                        canCheck: false,
                        checked: false,
                        enabled: true,
                        event: this.action.bind(this, actions.ByInstrument, true, placedFromVal)
                    },
                    {
                        locKey: 'panel.orders.menu.CancelAllForAccount',
                        tag: actions.ByAccount,
                        canCheck: false,
                        checked: false,
                        enabled: true,
                        event: this.action.bind(this, actions.ByAccount, true, placedFromVal)
                    },
                    {
                        locKey: 'panel.orders.menu.CancelAllOrders',
                        tag: actions.All,
                        canCheck: false,
                        checked: false,
                        enabled: true,
                        event: this.action.bind(this, actions.All, true, placedFromVal)
                    }
                ]
            }
        ];

        // переделать в случае появления новых subitems
        if (!Resources.isHidden('panel.orders.menu.ShowToolbar')) {
            items.push(
                {
                    locKey: 'panel.positions.menu.View',
                    tag: 'View',
                    canCheck: false,
                    checked: false,
                    enabled: true,
                    subitems: [
                        {
                            locKey: 'panel.orders.menu.ShowToolbar',
                            tag: actions.ShowToolbar,
                            checked: this.ToolbarVisible,
                            enabled: true,
                            canCheck: true,
                            event: this.ShowToolbarStateChange.bind(this)
                        }]
                });
        }

        items = this.AddOpeningPanelsCM(items);
        this.AddSymbolInfoContextMenuItemIfNeed(items);

        return items;
    }

    public override GetOpeningPanelsCMLocKeysSet (): any {
        const keysObj = {};

        keysObj[ApplicationPanelWithTable.OPEN_CHART] = 'panel.orders.showChartMI';
        keysObj[ApplicationPanelWithTable.OPEN_MD] = 'panel.orders.showMDMI';
        keysObj[ApplicationPanelWithTable.OPEN_TS] = 'panel.orders.showTSMI';
        keysObj[ApplicationPanelWithTable.OPEN_OE] = 'panel.orders.showOEMI';

        return keysObj;
    }
    // #endregion

    // #region OrderPanelBase
    public override DCEventsSubscribe (): void {
        DataCache.OnAddOrder.Subscribe(this.AddOrderEvent, this);
        DataCache.OnRemoveOrder.Subscribe(this.RemoveOrderEvent, this);

        DataCache.OnAddSLOrderToPosition.Subscribe(this.AddSLOrderToPositionEvent, this);
        DataCache.OnAddTPOrderToPosition.Subscribe(this.AddTPOrderToPositionEvent, this);

        DataCache.OnRemoveSLOrderFromPosition.Subscribe(this.RemoveSLOrderFromPositionEvent, this);
        DataCache.OnRemoveTPOrderFromPosition.Subscribe(this.RemoveTPOrderFromPositionEvent, this);
    }

    public override DCEventsUnSubscribe (): void {
        DataCache.OnAddOrder.UnSubscribe(this.AddOrderEvent, this);
        DataCache.OnRemoveOrder.UnSubscribe(this.RemoveOrderEvent, this);

        DataCache.OnAddSLOrderToPosition.UnSubscribe(this.AddSLOrderToPositionEvent, this);
        DataCache.OnAddTPOrderToPosition.UnSubscribe(this.AddTPOrderToPositionEvent, this);

        DataCache.OnRemoveSLOrderFromPosition.UnSubscribe(this.RemoveSLOrderFromPositionEvent, this);
        DataCache.OnRemoveTPOrderFromPosition.UnSubscribe(this.RemoveTPOrderFromPositionEvent, this);
    }

    public override getDoubleClickActionParams (): { action: OrderActionEnum, confirm: boolean, placedFrom: PlacedFrom } {
        return {
            action: OrderActionEnum.ByIds,
            confirm: true,
            placedFrom: PlacedFrom.WEB_ORDERS_PANEL_DB_CLICK
        };
    }

    public override getHotButtonPlacedFrom (): PlacedFrom {
        return PlacedFrom.WEB_ORDERS_PANEL_HOT_BTN;
    }

    public override CloseBtnClick (data): void {
        const selectedOrderIdArr = [data.row.id];
        if (this.IsClosingForbidden(selectedOrderIdArr)) {
            return;
        }
        void DataCache.FOrderExecutor.orderAction(OrderActionEnum.ByIds, this.isFiltered, selectedOrderIdArr, selectedOrderIdArr, true, PlacedFrom.WEB_ORDERS_PANEL_DB_CLICK, undefined);
    }

    public override IsClosingAllowed (selectedOrderIdArr): IsAllowedResponce | null {
        const orders = DataCache.getOrdersById(selectedOrderIdArr);
        let result = null;
        for (const ID in orders) {
            const res = IsAllowed.IsOrderCancelingAllowed(orders[ID]);
            if (result === null || !res.Allowed) {
                result = res;
            }
        }
        return result;
    }

    public override createTradingButtonItems (): TradingButtonItem[] {
        return [
            new TradingButtonItem(OrderActionEnum.All, 'panels.orders.buttons.CancelAllButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.All)),
            new TradingButtonItem(OrderActionEnum.Sell, 'panels.orders.buttons.CancelSellButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.Sell)),
            new TradingButtonItem(OrderActionEnum.Buy, 'panels.orders.buttons.CancelBuyButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.Buy)),
            new TradingButtonItem(OrderActionEnum.Stops, 'panels.orders.buttons.CancelStopsButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.Stops)),
            new TradingButtonItem(OrderActionEnum.Limits, 'panels.orders.buttons.CancelLimitsButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.Limits)),
            new TradingButtonItem(OrderActionEnum.Days, 'panels.orders.buttons.CancelDayButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.Days)),
            new TradingButtonItem(OrderActionEnum.GTCs, 'panels.orders.buttons.CancelGTCButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.GTCs)),
            new TradingButtonItem(OrderActionEnum.ByIds, 'panels.orders.buttons.CancelSelectedButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.ByIds)),
            new TradingButtonItem(OrderActionEnum.ByAccountAndInstrument, 'panels.orders.buttons.CancelByInstrumentButton', this.isFiltered && this.isFilteredAction(OrderActionEnum.ByAccountAndInstrument)),
            new TradingButtonItem(OrderActionEnum.ModifyOrder, 'panels.orders.buttons.ModifyOrder', this.isFiltered && this.isFilteredAction(OrderActionEnum.ModifyOrder)),
            new TradingButtonItem(OrderActionEnum.ChangeToMarket, 'panels.orders.buttons.AsMarket', this.isFiltered && this.isFilteredAction(OrderActionEnum.ChangeToMarket))
        ];
    }

    protected override isFilteredAction (action: number): boolean {
        switch (action) {
        case OrderActionEnum.ByIds:
        case OrderActionEnum.ByAccountAndInstrument:
        case OrderActionEnum.ModifyOrder:
        case OrderActionEnum.ChangeToMarket:
            return false;
        default:
            return true;
        }
    }

    // TODO. Rename.
    public override action (action, confirm, placedFrom, columnIndexToActivate): boolean {
        if (!super.action(action, confirm, placedFrom, columnIndexToActivate)) {
            return false;
        }
        const quickTable = this.getQuickTable();
        const selectedRowsIds = quickTable.selectedRowIds;
        const allRowsId = quickTable.getTableItemsIds();
        void DataCache.FOrderExecutor.orderAction(action, this.isFiltered, selectedRowsIds, allRowsId, confirm, placedFrom, columnIndexToActivate);
        return true;
    }

    public override updateAllowedActions (): void {
        if (isNullOrUndefined(this.Controls) || isNullOrUndefined(this.quickTableRactive)) {
            return;
        }

        const menuTagDict = this.menuTagDict;
        if (isNullOrUndefined(menuTagDict)) return;

        const quickTable = this.getQuickTable();
        if (isNullOrUndefined(quickTable)) return;

        const btns = this.Controls.btns;
        if (isNullOrUndefined(btns)) return;

        const actions = OrderActionEnum;

        const selectedOrderIdArr = quickTable.selectedRowIds;
        const row = quickTable.rows[selectedOrderIdArr[0]];
        const allRowsIds = quickTable.getTableItemsIds();
        const allowedActionSet = DataCache.FOrderExecutor.getAllowedOrderActionSet(selectedOrderIdArr, allRowsIds, this.isFiltered);
        const ins = !isNullOrUndefined(row) ? row.item.DisplayInstrumentName() : '';

        btns.updateEnability(allowedActionSet);
        btns.setInstrument(actions.ByAccountAndInstrument, ins);

        const changeToMarket = allowedActionSet[actions.ChangeToMarket];
        const modifyOrder = allowedActionSet[actions.ModifyOrder];
        const cancelAll = allowedActionSet[actions.All];
        const cancelByIds = allowedActionSet[actions.ByIds];
        const cancelByInstrument = allowedActionSet[actions.ByInstrument];
        const cancelByAccount = allowedActionSet[actions.ByAccount];

        menuTagDict[actions.ChangeToMarket].enabled = changeToMarket !== undefined && changeToMarket.Allowed;
        menuTagDict[actions.ModifyOrder].enabled = modifyOrder !== undefined && modifyOrder.Allowed;

        menuTagDict.CloseGroup.enabled = cancelAll !== undefined || cancelByInstrument !== undefined || cancelByAccount !== undefined || cancelByIds !== undefined;

        menuTagDict[actions.All].enabled = cancelAll !== undefined && cancelAll.Allowed;
        menuTagDict[actions.ByIds].enabled = cancelByIds !== undefined && cancelByIds?.Allowed;
        menuTagDict[actions.ByInstrument].enabled = cancelByInstrument !== undefined && cancelByInstrument?.Allowed;
        menuTagDict[actions.ByAccount].enabled = cancelByAccount !== undefined && cancelByAccount?.Allowed;

        const enabled = !!ins;
        this.SetOpeningPanelsCMEnability(menuTagDict, enabled);
    }

    public override isClosingAction (action: OrderActionEnum): boolean {
        const closingActions = [
            OrderActionEnum.All,
            OrderActionEnum.ByIds,
            OrderActionEnum.Buy,
            OrderActionEnum.Sell,
            OrderActionEnum.ByInstrument,
            OrderActionEnum.ByAccount,
            OrderActionEnum.ByAccountAndInstrument
        ];

        return closingActions.includes(action);
    }
    // #endregion

    // #endregion

    public onTableMouseDown (hittestInfo: any): void {
        if (!isNullOrUndefined(this.doubleClickedTimerID)) {
            clearTimeout(this.doubleClickedTimerID);
        }
        this.doubleClickedTimerID = setTimeout(() => { this.processTableMouseDown(hittestInfo); }, 200);
    }

    public processTableMouseDown (hittestInfo: any): void {
        const quickTable = this.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }
        if (quickTable.editableColID !== hittestInfo.columnIndex || quickTable.editableRowID !== hittestInfo.rowIndex) {
            return;
        }

        const actions = OrderActionEnum;
        const placedFromVal = PlacedFrom.WEB_ORDERS_PANEL_DB_CLICK;
        const columnID = quickTable.sortedColumns[hittestInfo.columnIndex].PRIVATE.index;

        let numericLinkName;
        if (columnID === OrderItem.QUANTITY_COL_INDEX) {
            numericLinkName = NumericLinks.Quantity;
        } else if (columnID === OrderItem.PRICE_COL_INDEX) {
            numericLinkName = NumericLinks.LimitPrice;
        } else if (columnID === OrderItem.STOP_PRICE_COL_INDEX) {
            numericLinkName = NumericLinks.StopPrice;
        } else if (columnID === OrderItem.SL_PRICE_COL_INDEX || columnID === OrderItem.SL_OFFSET_COL_INDEX) {
            numericLinkName = NumericLinks.StopLoss;
        } else if (columnID === OrderItem.TP_PRICE_COL_INDEX || columnID === OrderItem.TP_OFFSET_COL_INDEX) {
            numericLinkName = NumericLinks.TakeProfit;
        }

        this.action(actions.ModifyOrder, true, placedFromVal, numericLinkName);
    }

    public AddOrderEvent (order: Order): void {
        this.getQuickTable().AddItem(new OrderItem(order, SessionSettings));
    }

    public AddSLOrderToPositionEvent (position: Position): void {
        if (isNullOrUndefined(position) || isNullOrUndefined(position.SLOrder)) {
            return;
        }
        this.AddOrderEvent(position.SLOrder);
    }

    public AddTPOrderToPositionEvent (position: Position): void {
        if (isNullOrUndefined(position) || isNullOrUndefined(position.TPOrder)) {
            return;
        }
        this.AddOrderEvent(position.TPOrder);
    }

    public RemoveOrderEvent (order: Order): void {
        this.getQuickTable().RemoveItem(OrderItem.GetItemId(order));
    }

    public RemoveSLOrderFromPositionEvent (position: Position): void {
        if (isNullOrUndefined(position) || isNullOrUndefined(position.SLOrder)) {
            return;
        }
        this.RemoveOrderEvent(position.SLOrder);
    }

    public RemoveTPOrderFromPositionEvent (position: Position): void {
        if (isNullOrUndefined(position) || isNullOrUndefined(position.TPOrder)) {
            return;
        }
        this.RemoveOrderEvent(position.TPOrder);
    }

    public override HotkeyPressed (hotkey: string): boolean {
        const isPressed = super.HotkeyPressed(hotkey);
        if (isPressed) {
            return true;
        }

        const placedFromVal = PlacedFrom.WEB_ORDERS_PANEL_HOT_BTN;
        const hotkeyAction = HotkeysManager.GetActionByHotkeyForPanel(hotkey, this.getType());
        switch (hotkeyAction) {
        case WorkingOrdersHotkeysEnum.ModifyOrder:
            this.action(OrderActionEnum.ModifyOrder, true, placedFromVal, null);
            return true;
        case WorkingOrdersHotkeysEnum.CancelOrder:
            this.action(OrderActionEnum.ByIds, true, placedFromVal, null);
            return true;
        case WorkingOrdersHotkeysEnum.ChangeToMarket:
            this.action(OrderActionEnum.ChangeToMarket, true, placedFromVal, null);
            return true;
        }

        return false;
    }
}
