// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { OrderInfoViewTemplate } from '../../templates.js';
import { Control } from './Control';
import { OperationType } from '../../Utils/Trading/OperationType';
import { ThemeManager } from '../misc/ThemeManager';
import { DataCache } from '../../Commons/DataCache';
import { OrderUtils } from '../../Utils/Trading/OrderUtils';
import { type Order } from '../../Commons/cache/Order';
import { ResourcesManager } from '../../Commons/properties/ResourcesManager';

export class OrderInfoView extends Control {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.observe('order', this.updateFields);
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public updateFields (order: Order): void {
        if (isNullOrUndefined(order)) return;

        const ins = order.Instrument;
        const buy = order.BuySell === OperationType.Buy;

        const orderTypeDict = DataCache.OrderParameterContainer.OrderTypes;
        const orderType = orderTypeDict[order.OrderType];

        void this.set({
            showRoute: !ins.isHideRouteMode,
            fOrderId: order.OrderNumber,
            fSide: Resources.getResource('general.trading.' + (buy ? 'Buy' : 'Sell')),
            fLeverage: OrderUtils.GetFormattedLeverage(order.Leverage),
            fInstrumentName: ins.DisplayName(),
            fRoute: ins.getRoute(),
            fOrderType: Resources.getResource(orderType.localizationKey()),
            sideColor: ThemeManager.CurrentTheme[buy ? 'BuyColor' : 'SellColor'],
            height: (ins.isHideRouteMode ? 80 : 100) + (order.Leverage ? 20 : 0)
        });
    }

    public localize (): void {
        void this.set({
            orderIdLabel: Resources.getResource('screen.modifyOrder.orderNumber'),
            instrumentLabel: Resources.getResource('screen.modifyOrder.instrument'),
            routeLabel: Resources.getResource('screen.modifyOrder.route'),
            leverageLabel: Resources.getResource('screen.modifyOrder.leverage'),
            sideLabel: Resources.getResource('screen.modifyOrder.operation'),
            orderTypeLabel: Resources.getResource('screen.modifyOrder.ordertype')
        });

        this.updateFields(this.get('order'));
    }
}

Control.extendWith(OrderInfoView, {
    template: OrderInfoViewTemplate,
    data: function () {
        return {
            order: null,
            showRoute: false,

            sideColor: '',
            orderIdLabel: '',
            fOrderId: '',
            instrumentLabel: '',
            fInstrumentName: '',
            routeLabel: '',
            fRoute: '',
            orderTypeLabel: '',
            fOrderType: '',
            sideLabel: '',
            fSide: '',
            leverageLabel: '',
            fLeverage: ''
        };
    }
});
