// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class CHANNEL extends IndicatorScriptBase {
    PrPeriod: number;
    MIN: number;

    constructor () {
        super();

        this.ProjectName = 'Price Channel';
        this.Comments = 'Based on measurement of min and max prices for the definite number of periods';
        super.SetIndicatorLine('upper', Color.Red, 1, LineStyle.SimpleChart);
        super.SetIndicatorLine('lower', Color.Red, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.PrPeriod = 10;
        super.InputParameter(new InputParameterInteger('PrPeriod', 'Period of price channel', 0, 1, 99999));

        this.MIN = 10000000;
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'CHANNEL(' + this.PrPeriod + ')';
    };

    public override getCustomName (): string {
        return 'CHANNEL (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();
        if (this.CurrentData.Count < this.PrPeriod) { return; }
        let high = 0;
        let low = this.MIN;
        for (let i = 0; i < this.PrPeriod; i++) {
            let price = this.CurrentData.GetPrice(PriceType.High, i);
            if (price > high) { high = price; }
            price = this.CurrentData.GetPrice(PriceType.Low, i);
            if (price < low) { low = price; }
        }
        if (low < this.MIN) {
            this.SetValue(0, 0, high);
            this.SetValue(1, 0, low);
        }
    };
}
