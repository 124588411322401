// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { AdditionalProperty } from '@shared/localizations/Resources';
import { type Event } from './Event';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';

export class EventFormatter {
    static typesStr = ['None', 'Information', 'Exception', 'Trading', 'System', 'Comment', 'Updater', 'Script'];
    static sourcesStr = ['None', 'Out', 'MarginCall', 'Refused', 'In', 'RiskRuleWarning', 'Out'];

    static typeStartTeg = '<typ>';
    static typeEndTeg = '</typ>';
    static sourceStartTeg = '<src>';
    static sourceEndTeg = '</src>';
    static actionStartTeg = '<act>';
    static actionEndTeg = '</act>';
    static textStartTeg = '<mes>';
    static textEndTeg = '</mes>';
    static idStartTeg = '<id>';
    static idEndTeg = '</id>';

    public static formattedData (events: Event[]): string {
        return events.map((event) => `${this.getFEvent(event)}`).join('\n');
    }

    private static getFEvent (ev: Event): string {
        const formattedDateTime = DateTimeUtils.formatDate(ev.DateTime, 'DD.MM.YYYY HH:mm:ss.SSS');

        return (
            '->' +
            formattedDateTime +
            ' ' +
            this.typeStartTeg +
            this.typesStr[ev.EventType] +
            this.typeEndTeg +
            ' ' +
            this.sourceStartTeg +
            this.sourcesStr[ev.Source] +
            this.sourceEndTeg +
            ' ' +
            this.actionStartTeg +
            ev.Action +
            this.actionEndTeg +
            ' ' +
            this.textStartTeg +
            this.getFDescription(ev) +
            this.textEndTeg
        );
    }

    private static getFDescription (ev: Event): string {
        let result = '';

        for (let i = 0; i < ev.DescriptionItems.length; i++) {
            const header = ev.DescriptionItems[i].Header.replace(`${AdditionalProperty}.`, '');
            let value = '';

            if (ev.DescriptionItems[i].Value != null) {
                if (ev.DescriptionItems[i].Value instanceof Date) {
                    value = DateTimeUtils.formatDate(ev.DescriptionItems[i].Value, 'DD.MM.YYYY HH:mm:ss.SSS');
                } else {
                    value = ev.DescriptionItems[i].Value.toString().replace(`${AdditionalProperty}.`, '');
                }
            }
            result += value ? `${header}:${value}; ` : header;
        }

        return result.trim();
    }
}
