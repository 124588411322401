// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { type RectangleDataCacheTool } from '../../Commons/cache/Tools/RectangleDataCacheTool';
import { ChartMath } from '../Utils/ChartMath';
import { ToolView } from './ToolView';

export class RectangleToolView extends ToolView<RectangleDataCacheTool> {
    private readonly points_: Point[];

    constructor (tool: RectangleDataCacheTool) {
        super(tool);

        this.points_ = new Array(4);
        for (let i = 0; i < this.points_.length; i++) {
            this.points_[i] = new Point();
        }
    }

    public override Draw (gr, ww, param): void {
        const points = this.points_;
        const scrPoints = this.screenPoints;
        const scrP0 = scrPoints[0];
        const scrP1 = scrPoints[1];

        points[0] = new Point(scrP0[0], scrP0[1]);
        points[1] = new Point(scrP0[0], scrP1[1]);
        points[2] = new Point(scrP1[0], scrP1[1]);
        points[3] = new Point(scrP1[0], scrP0[1]);

        const cacheTool = this.dataCacheTool;
        if (cacheTool.fill) {
            gr.FillPolygon(cacheTool.FillBrush, points);
        }

        gr.DrawPolygon(cacheTool.Pen, points);

        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        gr.DrawPolygon(this.dataCacheTool.PenHighlight, this.points_);

        const scrPoints = this.screenPoints;
        const scrP0 = scrPoints[0];
        const scrP1 = scrPoints[1];

        super.DrawSelectedPoint(gr, ww, scrP0[0], scrP0[1]);
        super.DrawSelectedPoint(gr, ww, scrP1[0], scrP1[1]);
    }

    public override IsSelectCheck (x: number, y: number): boolean {
        const points_ = this.points_;
        const points_len = points_.length;
        const TOOL_DX = ToolView.TOOL_DX;

        for (let i = 0; i < points_len; i++) {
            const sec = i + 1 < points_len ? i + 1 : 0;

            if (ChartMath.CalcDistanceSqrFromPointToSection([points_[i].X, points_[i].Y], [points_[sec].X, points_[sec].Y], x, y) <= TOOL_DX) {
                return true;
            }
        }
        return false;
    }
}
