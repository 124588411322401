// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectQuoteMessage } from './DirectQuoteMessage';
import { HistoryType } from '../../Utils/History/HistoryType';
import { Leve2QuoteType, Level2Update } from '../../Utils/Enums/Constants';
import { Message } from './Message';

export class DirectQuote2Message extends DirectQuoteMessage {
    public Update = Level2Update.Incremental;
    // Use instead of FThail.
    public Thail: any = null;
    public DayTradeVolume = 0;
    public Source: string | null = null;
    public mmkey: any = null;
    public MMID: string | null = null;
    // #48800 - используется только для MarketDepth,
    // для того чтобы при клике на ячейку подставлялась неспредировання цена в MarketDepthOE
    public NonSpreadPrice: any = null;
    public Size = Number.NaN;
    public Side = 0;
    public Price = Number.NaN;
    // #40994 (relations)
    public NumberOfOrders = 0;
    public Closed = false;
    public MdOrderId: number;

    // Сообщение описывает рынок по цене (по умолчанию для MBT протокола)
    public static LEVEL2_BYPRICE = 1;
    // Сообщение описывает рынок по спросу/предложению (используется MBT протоколом), обычные условия (regular book)
    public static LEVEL2_BYORDER_REGULAR = 2;
    // Сообщение опысывает рынок по спросу/предложению (используется MBT протоколом), специальные условия (special book)
    public static LEVEL2_BYORDER_SPECIAL = 3;
    // Стартовое сообщение пакета Level2 By Price (по этому сообщению происходит очистка окна level2)
    public static LEVEL2_BYPRICE_START = -1;
    // Стартовое сообщение пакета Level2 By Order Regular Book (по этому сообщению происходит очистка окна level2)
    public static LEVEL2_BYORDER_REGULAR_START = -2;
    // Стартовое сообщение пакета Level2 By Order Special Book (по этому сообщению происходит очистка окна level2)
    public static LEVEL2_BYORDER_SPECIAL_START = -3;

    constructor () {
        super(Message.CODE_QUOTE2);

        this.Type = HistoryType.QUOTE_LEVEL2;
        this.QuoteType = Leve2QuoteType.None;
    }

    get Route (): string {
        return this.FRoute;
    }

    set Route (value) {
        this.FRoute = value;
        const tail = this.Thail;
        if (!tail) {
            return;
        }
        const len = tail.length;
        for (let i = 0; i < len; i++) {
            tail[i].Route = value;
        }
    }

    get MMkey (): any {
        if (!this.mmkey) {
            this.mmkey = DirectQuote2Message.GetMMIDDisplay(this.MMID);
        }
        return this.mmkey;
    }

    public static GetQute2MessagesFromQuote1ByDenyTable (message, denyTable): DirectQuote2Message | null {
        const item = denyTable[message.InstrumentTradableID];

        if (!item) { return null; };

        const msg = DirectQuote2Message.CreateQuote2MessagesFromQuote1(message);

        return msg;
    }

    public static CreateQuote2MessagesFromQuote1 (message): DirectQuote2Message {
        const q2MessageBid = new DirectQuote2Message();
        const q2MessageAsk = new DirectQuote2Message();
        const killThemAll = new DirectQuote2Message();
        DirectQuote2Message.CP(message, q2MessageBid);

        q2MessageBid.Type = HistoryType.QUOTE_LEVEL2;
        q2MessageBid.Update = Level2Update.FullRefresh;
        q2MessageBid.Source = message.Route;
        q2MessageBid.Side = 1;
        q2MessageBid.Price = message.Bid;
        q2MessageBid.MMID = 'ByBid';
        q2MessageBid.MdOrderId = 0;
        q2MessageBid.Size = !isNaN(message.BidSize) ? message.BidSize : 0;

        DirectQuote2Message.CP(q2MessageBid, q2MessageAsk);
        q2MessageAsk.Side = 2;
        q2MessageAsk.Price = message.Ask;
        q2MessageAsk.MMID = 'ByAsk';
        q2MessageAsk.MdOrderId = 0;
        q2MessageAsk.Size = !isNaN(message.AskSize) ? message.AskSize : 0;

        killThemAll.Route = message.Route;
        killThemAll.InstrumentTradableID = message.InstrumentTradableID;
        killThemAll.Side = -1;
        killThemAll.Closed = true;
        killThemAll.Thail = [q2MessageBid, q2MessageAsk];
        killThemAll.Update = Level2Update.FullRefresh;

        return killThemAll;
    }

    public static CP (messageFrom, messageTo): void {
        messageTo.Route = messageFrom.Route;
        messageTo.Type = messageFrom.Type;
        messageTo.cTime = messageFrom.cTime;
        messageTo.InstrumentTradableID = messageFrom.InstrumentTradableID;
        messageTo.SessionFlag = messageFrom.SessionFlag;
        messageTo.VendorName = messageFrom.VendorName;
        messageTo.QuoteUniqueID = messageFrom.QuoteUniqueID;
    }

    public SetSymbolIgnoreCase (symbol): void {
        this.symbol = symbol;
        const tail = this.Thail;
        if (!tail) {
            return;
        }
        const len = tail.length;
        for (let i = 0; i < len; i++) {
            tail[i].SetSymbolIgnoreCase(symbol);
        }
    }

    public static GetMMIDDisplay (sMMID): any {
        const nPos = sMMID.indexOf(':');
        if (nPos !== -1) {
            return sMMID.substr(0, nPos);
        }
        return sMMID;
    }
}
