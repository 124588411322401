// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type ContextHelper } from 'ractive';
import { AccountWidgetItemTemplate } from '../../../templates';
import { Control } from '../Control';
import { DynProperty } from '@shared/commons/DynProperty';
import { type IGroupController } from '@shared/commons/VerticalGroupedPanels/Interfaces/IGroupController';
import { VerticalDetailsTypePointer } from '@shared/commons/cache/VerticalPanel/VerticalDetailsTypePointer';
import { ConvertingOldSettings } from '@shared/commons/cache/VerticalPanel/ConvertingOldSettings';
import { type AccountDetailsGroupItemsEnumType, type AccountDetailsGroupsEnum } from '@shared/utils/Enums/AccountDetailsEnum';
import { RowItemDataColorPair, type RowItemDataNumberPair, type RowItemDataStringPair } from '@shared/commons/cache/VerticalPanel/AccountDetails/RowItemData';

export class AccountWidgetItem extends Control {
    private _controller: IGroupController<AccountDetailsGroupItemsEnumType> = null;
    private _tileTypePointer: VerticalDetailsTypePointer<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum> = null;

    constructor () { super(); }

    get Controller (): IGroupController<AccountDetailsGroupItemsEnumType> { return this._controller; }
    set Controller (value: IGroupController<AccountDetailsGroupItemsEnumType>) { this._controller = value; }

    get TileTypePointer (): VerticalDetailsTypePointer<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum> { return this._tileTypePointer; }
    set TileTypePointer (value: VerticalDetailsTypePointer<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum>) { this._tileTypePointer = value; }

    public getType (): string { return 'AccountWidgetItem'; };

    public oncomplete (): void {
        super.oncomplete();
        this.on('DragNDropEnd', this.DragNDropEnd);
        this.on('accWidgetTileOver', this.accWidgetTileOver);
        this.on('accWidgetTileLeave', this.accWidgetTileLeave);
    }

    public UpdateData (): void {
        if (isNullOrUndefined(this.Controller)) {
            return null;
        }

        const controller = this.Controller;
        const itemType = this.TileTypePointer.ItemType;

        const values = controller.getItemFormattedValue(itemType);
        const colors = this.getItemColor();

        void this.set({
            firstValue: values.firstValue,
            firstValueColor: colors.firstValue,
            secondValue: values.secondValue,
            secondValueColor: colors.secondValue
        });
    }

    public Properties (): DynProperty[] {
        const properties: DynProperty[] = [];
        properties.push(new DynProperty(this.getPropKey(), this.TileTypePointer.toString(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        return properties;
    }

    public callBack (newProperties: DynProperty[]): void {
        const dp = DynProperty.getPropValue(newProperties, this.getPropKey());
        if (!isNullOrUndefined(dp)) {
            const pointer = VerticalDetailsTypePointer.fromJSON<AccountDetailsGroupItemsEnumType, AccountDetailsGroupsEnum>(dp);
            if (!isNullOrUndefined(pointer)) {
                this.TileTypePointer = pointer;
            }
        }

        const dpOld = DynProperty.getPropValue(newProperties, this.getOldPropKey());
        if (!isNullOrUndefined(dpOld)) {
            const oldParams = ConvertingOldSettings.ConvertingSettingsFromOldToNew(dpOld);
            if (!isNullOrUndefined(oldParams)) {
                this.TileTypePointer = oldParams;
            }
        }
    }

    public SetDataToWork (): void {
        if (isNullOrUndefined(this.Controller)) {
            return null;
        };

        const controller = this.Controller;
        const itemType = this.TileTypePointer.ItemType;

        const header = controller.getItemName(itemType);
        const values = controller.getItemFormattedValue(itemType);
        const colors = this.getItemColor();
        const tooltip = controller.getItemTooltip(itemType);

        void this.set({
            header: header.toUpperCase(),
            firstValue: values.firstValue,
            firstValueColor: colors.firstValue,
            secondValue: values.secondValue,
            secondValueColor: colors.secondValue,
            tooltip
        });
    }

    public Validate (): void {
        if (isNullOrUndefined(this.Controller)) {
            return null;
        };

        const controller = this.Controller;
        const itemType = this.TileTypePointer.ItemType;

        const header = controller.getItemName(itemType).toUpperCase();
        const tooltip = controller.getItemTooltip(itemType);
        void this.set({ header, tooltip });
    }

    public UpdateRelations (): void {
        if (isNullOrUndefined(this.Controller) && isNullOrUndefined(this.TileTypePointer)) {
            return null;
        }

        this.SetDataToWork();
    }

    private accWidgetTileOver (containerContext): void {
        void this.set({ hovered: true });
    }

    private accWidgetTileLeave (containerContext): void {
        void this.set({ hovered: false });
    }

    private getPropKey (): string {
        return `${this.get('itemKey')}TileTypePointer`;
    }

    private getOldPropKey (): string {
        return `${this.get('itemKey')}RowId`;
    }

    private DragNDropEnd (sender: ContextHelper): void {
        void this.set({ hovered: false });
        this.fire('itemDragNDropEnd');
    }

    private getItemColor (): RowItemDataColorPair {
        if (isNullOrUndefined(this.Controller)) {
            return null;
        }

        const controller = this.Controller;
        const itemType = this.TileTypePointer.ItemType;

        const value: RowItemDataNumberPair = controller.getItemValue(itemType);
        const strValue: RowItemDataStringPair = controller.getItemFormattedValue(itemType);

        if (strValue.isNAString()) {
            return new RowItemDataColorPair(null, controller.getColoringMethod(itemType));
        }

        return new RowItemDataColorPair(value, controller.getColoringMethod(itemType));
    }
}

Control.extendWith(AccountWidgetItem, {
    template: AccountWidgetItemTemplate,
    data: function () {
        return {
            header: '',
            firstValue: '',
            firstValueColor: '',
            secondValue: '',
            secondValueColor: '',
            tooltip: '',
            itemKey: 'AWI',
            DragNDropInProcess: false,
            hovered: false
        };
    }
});
