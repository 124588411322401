// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DirectAlgorithmSubscribeMessage } from '../../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { type PFixFieldSet } from '../../Fields/PFixFieldSet';
import { MessageProcessorBase } from '../MessageProcessorBase';

export class AlgorithmSubscribeMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlgorithmSubscribeMessage[] {
        const msg = new DirectAlgorithmSubscribeMessage();
        const ff = FieldsFactory;

        msg.AlgorithmId = fs.GetValue(ff.FIELD_ALGORITHM_ID);
        msg.SubscribeAction = fs.GetValue(ff.FIELD_SUBSCRIPTION_ACTION);

        return [msg];
    }
}
