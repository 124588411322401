// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import $ from 'jquery';
import 'jquery-mousewheel';
import 'malihu-custom-scrollbar-plugin';

class _HtmlScroll {
    public addScroll (node): any {
        return $(node).mCustomScrollbar({
            theme: 'tercera',
            autoHideScrollbar: true,
            scrollButtons: {
                enable: true,
                scrollType: 'stepless'
            },
            mouseWheel: { enable: true },
            keyboard: { enable: true },
            advanced: { updateOnContentResize: true }
        });
    }

    public removeScroll (node): void {
        $(node).mCustomScrollbar('destroy');
    }

    public updateScroll (node): void {
        $(node).mCustomScrollbar('update');
    }
}

class _HtmlScrollXY extends _HtmlScroll {
    public override addScroll (node, axis: 'x' | 'y' | 'xy' = 'x'): any {
        $(node).mCustomScrollbar({
            axis,
            theme: 'terceraXY',
            autoHideScrollbar: true,
            scrollButtons: {
                enable: true,
                scrollType: 'stepless'
            },
            mouseWheel: { enable: true },
            keyboard: { enable: true },
            advanced: { updateOnContentResize: true }
        });
    }
}

export const HtmlScroll = new _HtmlScroll();

export const HtmlScrollXY = new _HtmlScrollXY();
