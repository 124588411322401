// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraMainHeaderButtonTemplate } from '../../templates';
import { TerceraButton } from './TerceraButton';

export class TerceraMainHeaderButton extends TerceraButton {
    constructor () {
        super();
    }

    getType (): string { return 'TerceraMainHeaderButton'; };
}

TerceraButton.extendWith(TerceraMainHeaderButton, {
    template: TerceraMainHeaderButtonTemplate,
    data: function () {
        return {
            text: '',
            haveBackground: false
        };
    }
});
