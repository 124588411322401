// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectPropertyMessage } from '../../../../Utils/DirectMessages/DirectPropertyMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PropertyMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectPropertyMessage[] {
        const msg = new DirectPropertyMessage();

        msg.Name = fs.GetValue(FieldsFactory.FIELD_NAME);
        msg.Value = fs.GetValue(FieldsFactory.FIELD_VALUE);

        msg.OwnerType = fs.GetValue(FieldsFactory.FIELD_OWNER_TYPE);
        msg.OwnerID = fs.GetValue(FieldsFactory.FIELD_ID);

        return [msg];
    }
}
