// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';

// TODO. Base class?
export class SLTPError {
    public readonly type: SLTPErrorType;
    public readonly minOrMaxPossibleValue: string;

    constructor (type: SLTPErrorType, minOrMaxPossibleValue: string) {
        this.type = type;
        this.minOrMaxPossibleValue = minOrMaxPossibleValue;
    }

    public equals (sltpError: SLTPError): boolean {
        if (isNullOrUndefined(sltpError)) {
            return false;
        }
        if (this === sltpError) {
            return true;
        }

        return this.type === sltpError.type && this.minOrMaxPossibleValue === sltpError.minOrMaxPossibleValue;
    }

    public toString (): string {
        switch (this.type) {
        case SLTPErrorType.LessMin:
            return Resources.getResource('UserControl.TerceraNumeric.ValueLessMin') + this.minOrMaxPossibleValue;
        case SLTPErrorType.MoreMax:
            return Resources.getResource('UserControl.TerceraNumeric.ValueGreaterMax') + this.minOrMaxPossibleValue;
        default:
            return '';
        }
    }
}

export enum SLTPErrorType {
    LessMin = 1,
    MoreMax = 2
}
