// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectPortfolioStatisticsMessage extends DirectMessageBaseClass {
    public DataArray: DirectPortfolioStatistic[] = [];

    constructor () {
        super(Message.CODE_PFIX_PORTFOLIO_STATISTICS_RESPONSE);
    }
}

export class DirectPortfolioStatistic {
    public Date: Date | null = null; // приходит в FIELD_DATE[12] - Значение “день”,
    public Percent: number | null = null; // приходит в FIELD_PERCENT[729] - Значение “процентов”,
    public AbsoluteAmount: number | null = null; // приходит в FIELD_AMOUNT[7] - Значение “абсолютное”,
    public IsBest: boolean | null = null; // приходит в FIELD_TYPE[53] - байтовое поле, в рамках тек.задачи, будет использоваться для передачи признака “best” = 1 или “worst” = 0,
    public TradableInstrumentId: number | null = null;// приходит в FIELD_TRADABLE_INSTRUMENT_ID[655] - Идентификатор инструмента,
    public RouteId: number | null = null; // приходит в FIELD_ROUTE_ID[85] - Идентификатор котировочного роута
}
