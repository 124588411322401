// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type OffsetModeViewEnum } from '../../../../Utils/Trading/OffsetModeViewEnum';
import { type SLTPError } from '../SLTPError';

export class OrderSlTpBase {
    public value: number | null = null; // null - value awaits for recalculation
    public offsetType: OffsetModeViewEnum | null = null; // null - absolute value
    public enabled: boolean = false;
    public error: SLTPError | null = null;
}
