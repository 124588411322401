// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { _BaseGeneralSettingsUtils } from '../../Commons/UtilsClasses/BaseGeneralSettingsUtils';
import { BaseGeneralSettingsUtilsInstanceCreator } from '../../Commons/UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { WDGeneralSettings } from '../../Controls/settings/WDGeneralSettings';

export class WDGeneralSettingsUtils extends _BaseGeneralSettingsUtils {

}

export const WDSettingsUtils: WDGeneralSettingsUtils = new WDGeneralSettingsUtils();
BaseGeneralSettingsUtilsInstanceCreator(WDSettingsUtils);
