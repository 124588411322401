// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectSourceMappingMessage } from '../../../../Utils/DirectMessages/DirectSourceMappingMessage';
import { type PFixFieldSet, SourceGroup } from '../Groups/GroupsImport';

export class SourceListProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fs: PFixFieldSet): DirectSourceMappingMessage[] {
        const routeId = fs.GetValue(FieldsFactory.FIELD_ROUTE_ID);

        const smm = new DirectSourceMappingMessage();
        smm.RouteId = routeId;

        const sourceMap = this.decoder.SourceMap;

        let sourceDict = sourceMap[routeId];
        if (!sourceDict) {
            sourceMap[routeId] = sourceDict = {};
        }

        const groupArr: SourceGroup[] = fs.GetGroups(FieldsFactory.SOURCE_GROUP, SourceGroup);
        for (let i = 0, len = groupArr.length; i < len; i++) {
            const group = groupArr[i];
            const sourceId = group.getSourceId();
            const sourceName = group.getSource();
            const sourceExternalId = group.getExternalSourceId();

            sourceDict[sourceId] = sourceName;
            smm.SourceIDs.push(sourceId);
            smm.SourceNames.push(sourceName);
            smm.SourceExternalIds.push(sourceExternalId);
        }

        return [smm];
    }
}
