// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class BuyingPowerAvailableFundsForOptionsBuyGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.BUYING_POWER_AVAILABLE_FUNDS_FOR_OPTIONS_BUY, null, pfixFieldSet);
    }

    public getBuyingPowerName (): string {
        return this.GetValue(FieldsFactory.FIELD_NAME);
    }

    public getAvailableMargin (): number {
        return this.GetValue(FieldsFactory.FIELD_AVAILABLE_MARGIN);
    }
}
