// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectTradingSignalSeenMessage } from '../../../../Utils/DirectMessages/DirectTradingSignalSeenMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class TradingSignalSeenRequestMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectTradingSignalSeenMessage[] {
        const message = new DirectTradingSignalSeenMessage();

        const len = fieldSet.Count();

        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_TRADING_SIGNAL_ID) {
                message.TradingSignalId = fixField.Value;
            }
        }

        return [message];
    }
}
