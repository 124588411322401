// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MAMode } from './IndicatorConstants';
import { iAC } from './indicators/built-in/iAC';
import { iAD } from './indicators/built-in/iAD';
import { iADX } from './indicators/built-in/iADX';
import { iADXEx } from './indicators/built-in/iADXEx';
import { iADXEma } from './indicators/built-in/iADXEma';
import { iAlligator } from './indicators/built-in/iAlligator';
import { iAO } from './indicators/built-in/iAO';
import { iATR } from './indicators/built-in/iATR';
import { iBands } from './indicators/built-in/iBands';
import { iBandsEx } from './indicators/built-in/iBandsEx';
import { iBearsPower } from './indicators/built-in/iBearsPower';
import { iBullsPower } from './indicators/built-in/iBullsPower';
import { iBWMFI } from './indicators/built-in/iBWMFI';
import { iCCI } from './indicators/built-in/iCCI';
import { iCCIEx } from './indicators/built-in/iCCIEx';
import { iDeMarker } from './indicators/built-in/iDeMarker';
import { iEnvelopes } from './indicators/built-in/iEnvelopes';
import { iForce } from './indicators/built-in/iForce';
import { iFractals } from './indicators/built-in/iFractals';
import { iGator } from './indicators/built-in/iGator';
import { iIchimoku } from './indicators/built-in/iIchimoku';
import { iMA } from './indicators/built-in/iMA';
import { iMACD, iMACDEx } from './indicators/built-in/iMACD';
import { iMAEx } from './indicators/built-in/iMAEx';
import { iMom, iMomEx } from './indicators/built-in/iMom';
import { iOBV } from './indicators/built-in/iOBV';
import { iOsMA } from './indicators/built-in/iOsMA';
import { iRVI } from './indicators/built-in/iRVI';
import { iSAR } from './indicators/built-in/iSAR';
import { iStdDevEx } from './indicators/built-in/iStdDevEx';
import { iStdDev } from './indicators/built-in/iStdDev';
import { iSTO } from './indicators/built-in/iSTO';
import { iWPR } from './indicators/built-in/iWPR';
import { PriceType } from '../../../Utils/History/CashItemUtils';
import { type iBuildInIndicator } from './iBuildInIndicator';

class _iBuildInIndicatorFactory {
    public CreateIndicator (name, args): iBuildInIndicator {
        switch (name) {
        case 'iAC':
            return new iAC();

        case 'iAD':
            return new iAD();

        case 'iADX':
        {
            if (args.length === 1) {
                return new iADX(args[0], MAMode.SMA);
            }

            const mode = args[1];
            return mode === 1
                ? new iADXEma(args[0])
                : new iADXEx(args[0], PriceType.Close, mode);
        }

        case 'iADXEx':
        {
            if (args.length === 2) {
                return new iADXEx(args[0], args[1], MAMode.SMA);
            }

            const mode = args[2];
            return mode === 1
                ? new iADXEma(args[0])
                : new iADXEx(args[0], args[1], args[2]);
        }

        case 'iAlligator':
            return new iAlligator(
                args[0], args[1],
                args[2], args[3],
                args[4], args[5],
                args[6], args[7]);

        case 'iAO':
            return new iAO();

        case 'iATR':
            return new iATR(
                args[0],
                args.length === 1 ? MAMode.SMA : args[1]);

        case 'iBands':
            return args.length === 2
                ? new iBands(args[0], MAMode.SMA, args[1], 0)
                : new iBands(args[0], args[1], args[2], args[3]);

        case 'iBandsEx':
            return args.length === 4
                ? new iBandsEx(args[0], MAMode.SMA, args[1], args[2], args[3])
                : new iBandsEx(args[0], args[1], args[2], args[3], args[4]);

        case 'iBearsPower':
            return new iBearsPower(args[0], args[1]);

        case 'iBullsPower':
            return new iBullsPower(args[0], args[1]);

        case 'iBWMFI':
            return new iBWMFI();

            // добавочный агумент int maMode
        case 'iCCI':
            return args.length === 1
                ? new iCCI(args[0], MAMode.SMA)
                : new iCCI(args[0], args[1]);

            // Добавочный аргумент int maMode
        case 'iCCIEx':
            return args.length === 2
                ? new iCCIEx(args[0], MAMode.SMA, args[1])
                : new iCCIEx(args[0], args[2], args[1]);

        case 'iDeMarker':
            return new iDeMarker(args[0]);

        case 'iEnvelopes':
            return args.length === 4
                ? new iEnvelopes(args[0], args[1], 0, args[2], args[3])
                : new iEnvelopes(args[0], args[1], args[2], args[3], args[4]);

        case 'iForce':
            return new iForce(args[0], args[1], args[2]);

        case 'iFractals':
            return new iFractals();

        case 'iGator':
            return new iGator(
                args[0], args[1],
                args[2], args[3],
                args[4], args[5],
                args[6], args[7]);

        case 'iIchimoku':
            return new iIchimoku(args[0], args[1], args[2]);

        case 'iMA':
            return new iMA(args[0], args[1]);

        case 'iMAEx':
            return new iMAEx(args[0], args[1], args[2], args[3]);

        case 'iMACD':
            return new iMACD(args[0], args[1], args[2]);

        case 'iMACDEx':
            return new iMACDEx(args[0], args[1], args[2], args[3]);

        case 'iMFI':
            return new iBWMFI();

        case 'iMom':
            return new iMom(args[0]);

        case 'iMomEx':
            return new iMomEx(args[0], args[1]);

        case 'iOBV':
            return new iOBV(args[0]);

        case 'iOsMA':
            return new iOsMA(args[0], args[1], args[2], args[3]);

            // // Дополнительные три параметра - int maMode, int maPeriod, int rsiMethod
            // case 'iRSI':
            //     // Е. Бабаев: #63476 - в конструктор мы таки передаем method
            //     return args.length === 1
            //         ? new iRSI(args[0], iBuildInIndicator.MODE_RSI_SIMPLE)
            //         : new iRSI(args[0], args[1])

            // // дополнительынй параметр rsi_mode
            // case 'iRSIEx':
            //     // может лажать, порефакторил, проверь число параметров!
            //     return new iRSIEx(args[0], args[1], args[2])

            // дополнитеьный параметр int maMode
        case 'iRVI':
            return args.length === 1
                ? new iRVI(args[0], MAMode.SMA)
                : new iRVI(args[0], args[1]);

            // дополнительынй параметр
        case 'iSAR':
            return args.length === 1
                ? new iSAR(args[0], 0.2)
                : new iSAR(args[0], args[1]);

        case 'iStdDev':
            return args.length === 4
                ? new iStdDev(args[0], args[1], args[2], args[3])
                : new iStdDev(args[0], args[1], args[2], PriceType.Close);

        case 'iStdDevEx':
            return args.Length === 4
                ? new iStdDevEx(args[0], args[1], args[2], args[3])
                : new iStdDevEx(args[0], args[1], args[2], PriceType.Close);

        case 'iSTO':
            return args.length === 4
                ? new iSTO(args[0], args[1], args[2], args[3], 0)
                : new iSTO(args[0], args[1], args[2], args[3], args[4]);

        case 'iWPR':
            return new iWPR(args[0]);
        }
    }
}

export const iBuildInIndicatorFactory = new _iBuildInIndicatorFactory();
