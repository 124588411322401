// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

// TODO Есть проблемы с синхронизацией элементов первого списка
//      идеи для улучшения:
//          - совместить items и valueOfLists (хранить itemsStr в items)
//          - переопределить TerceraComboBox.onItemsChanged => решить проблему выбора первого списка при загрузке itemsStr при нескольких WL

import { Resources } from '@shared/localizations/Resources';
import { contextMenuHandler } from '@shared/utils/AppHandlers';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { TerceraRenameScreen } from '../screen/TerceraRenameScreen';
import { type MenuItem, TerceraComboBox } from './TerceraComboBox';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { BrowserUtils } from '@shared/commons/UtilsClasses/BrowserUtils';
import $ from 'jquery';

export class TerceraEditableListComboBox extends TerceraComboBox {
    public static readonly DEFAUL_VALUE_SEPARATOR = 1433; // значение для сепаратора (рандомное значение из головы вредной Женщины)
    public static readonly VALUE_DEFAULT = 0; // значение для default (рандомное значение из головы вредного Жирдяя)

    public updateProcess = false;
    public OnSelectList: CustomEvent;
    public OnItemSave: CustomEvent;
    public ownerID: any;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () {
        super();
    }

    public override getType (): string { return 'TerceraEditableListComboBox'; }

    public override oncomplete (): void {
        super.oncomplete();

        this.observe('selectedItem', this.selectList.bind(this));

        this.OnSelectList = new CustomEvent();

        this.OnItemSave = new CustomEvent();

        const storageName = this.get('storageName');
        if (storageName) {
            SessionSettings.sheetsChangedEvents[storageName].Subscribe(this.loadItemLists, this);
        }

        this.loadItemLists(this._guid);
    }

    public override dispose (): void {
        const storageName = this.get('storageName');
        if (storageName) {
            SessionSettings.sheetsChangedEvents[storageName].UnSubscribe(this.loadItemLists, this);
        }

        super.dispose();
    }

    public MenuShow (): void {
        const width = $(this.find('div')).outerWidth();
        const offset = $(this.find('div')).offset();
        const posY = offset.top - $(window).scrollTop() + 26;
        const posX = offset.left - $(window).scrollLeft();

        const additionalParams = {
            width: this.get('listWidth') || width,
            isComboboxMenu: true,
            isEditableListComboBoxMenu: true,
            isTemplateMenu: this.get('saveImage')
        };

        this.isMenuShown = true;
        contextMenuHandler.SetCallerControl(this);
        contextMenuHandler.Show(this.menuItems, posX, posY, additionalParams);
    }

    public smallBtnAction (element, key: number): void {
        if (key === 1) // rename logic
        {
            TerceraRenameScreen.show(element.text, this.renameItem.bind(this, element), Resources.getResource(this.get('renameHeader')));
        }
        if (key === 2) // delete logic
        {
            this.deleteItemConfirm(element);
        }
        if (key === 3) // save logic
        {
            this.saveItem(element);
        }
    }

    public createNewClicked (): void {
        const items = this.get('items');
        const len = items.length;
        const placeholder = this.get('newItemPlaceholder');
        const newListNamePlaceHolder = Resources.getResource(placeholder);
        let listName = newListNamePlaceHolder;
        let newNameFound; let ind = 0;

        do { // поиск уникального имени для placeholder на rename screen-e
            ind++;
            newNameFound = true;
            for (let i = 0; i < len; i++) {
                if (items[i].text === listName) {
                    listName = newListNamePlaceHolder + ' ' + ind;
                    newNameFound = false;
                }
            }
        } while (!newNameFound);

        const header = this.get('newItemHeader');

        TerceraRenameScreen.show(listName, this.createItem.bind(this), Resources.getResource(header));
    }

    public createItem (listName: string): void {
        const header = this.get('newItemHeader');

        const isNameValid = this.listNameValidation(listName, Resources.getResource(header), this.createItem.bind(this), null);

        if (!isNameValid) {
            return;
        }

        const items = this.get('items');

        const index = items.length - 2;
        const newItem = {
            text: listName,
            value: index,
            tag: index
        };

        const allLists = this.get('valueOfLists');
        const newList = { name: listName, itemsStr: '' };
        allLists.splice(index, 0, newList);
        this.ownerID = this._guid;
        this.saveItem(newItem);

        items.splice(index, 0, newItem);
        void this.set({ items });

        this.updateListsValue(listName, true, true, true);
        this.ownerID = null;
    }

    public renameItem (element, listName: string): void {
        const isNameValid = this.listNameValidation(listName, Resources.getResource(this.get('renameHeader')), this.renameItem.bind(this, element), element);

        if (!isNameValid) {
            return;
        }

        const items = this.get('items');

        const selItem = this.get('selectedItem');
        let selectedText = selItem.text;

        if (element.tag === selItem.value) {
            selectedText = listName;
        }

        items[element.tag].text = listName;
        void this.set({ items });

        const allLists = this.get('valueOfLists');
        allLists[element.tag].name = listName;

        this.updateListsValue(selectedText, true, true);
    }

    public saveItem (element): void {
        this.OnItemSave.Raise(element);

        contextMenuHandler.Hide();
    }

    public deleteItemConfirm (element): void {
        if (!element) {
            return;
        }

        const items = this.get('items');

        if (items.length === 3 && !this.get('saveImage')) // Need at least one element (+ CreateNew element + separator) except Chart Templates List
        {
            TerceraMessageBox.Show(Resources.getResource('screen.error.title'), Resources.getResource('screen.remove.lastlist'), MessageBoxType.Error, null, null, false, true);
            return;
        }

        const self = this;

        TerceraMessageBox.Show(
            Resources.getResource('screen.remove.title'),
            Resources.getResource('screen.remove.confirmText.firstPart') + "'" + element.text + "'" + Resources.getResource(this.get('removeTxtSecondPart')),
            MessageBoxType.Question,
            function () { self.deleteItem(element); });
    }

    public deleteItem (element): void {
        const items = this.get('items');

        const index = element.tag;
        const selectedText = this.get('selectedItem').text;

        items.splice(index, 1);
        void this.set({ items });

        const allLists = this.get('valueOfLists');
        allLists.splice(index, 1);

        if (selectedText === element.text) {
            this.updateListsValue(null, true);
        } else {
            this.updateListsValue(selectedText, true, true);
        }
    }

    public resetList (): void {
        const currentList = this.get('selectedItem');
        this.selectList(currentList, null);
    }

    public selectList (newItem, oldItem): void {
        if (this.updateProcess || (this.ownerID && this._guid !== this.ownerID)) {
            return;
        }

        const currentList = this.get('selectedItem');

        if (!currentList || !newItem || currentList.separator || (!isNullOrUndefined(oldItem) && newItem.text === oldItem.text)) {
            return;
        }

        const allLists = this.get('valueOfLists');
        const list = allLists[currentList.value];

        if (this.menuItems) {
            if (oldItem && !oldItem.separator && newItem && newItem.text !== oldItem.text) {
                this.menuItems[oldItem.value].checked = false;
            }

            if (!this.menuItems[newItem.value]) {
                return;
            }

            this.menuItems[newItem.value].checked = true;
            void this.set('checkedMenuItem', currentList.value);
        }

        if (!list) // some troubles if edit list in other WL in same Workspace
        {
            return;
        }

        this.updateProcess = true;
        this.OnSelectList.Raise(list.itemsStr);
        this.updateProcess = false;
    }

    public updateListsValue (selectedText, needSort, needRestoreSelectedItem?, fromCreate = false): void {
        if (this.updateProcess) {
            return;
        }

        let ID = '';
        if (fromCreate) {
            ID = this._guid;
        }

        if (needSort) {
            this.sortMenuItems();
        }

        if (needRestoreSelectedItem) {
            this.restoreCorrectSelectItem(selectedText);
        }

        const storageName = this.get('storageName');

        if (storageName && SessionSettings[storageName]) {
            this.updateProcess = true;
            SessionSettings.setSheetsData(storageName, this.get('valueOfLists'), ID);
            this.updateProcess = false;
        }
    }

    public loadItemLists (ownerID): void {
        if (this.updateProcess) {
            return;
        }

        this.ownerID = ownerID;
        const strgName = this.get('storageName');

        if (strgName && SessionSettings[strgName]) {
            const storage = SessionSettings[strgName];
            const allLists = storage?.itemsValue ? storage.itemsValue : [];
            let newCBItems = [];

            if (!allLists.length && (strgName === 'watchlistSheets' || strgName === 'savedOrdersSheets')) // #89217
            {
                this.setDefaultLists();
                return;
            }

            for (let i = 0; i < allLists.length; i++) {
                newCBItems.push({ text: allLists[i].name, value: i });
            }
            newCBItems = newCBItems.concat(this.getDefaultCBItems());

            const selItem = this.get('selectedItem');
            const selectedText = selItem ? selItem.text : null;

            void this.set({
                items: newCBItems,
                valueOfLists: allLists
            }).then(function () { this.ownerID = null; }.bind(this));

            if (selectedText) {
                this.restoreCorrectSelectItem(selectedText);

            // TODO   ёбаный костыль для синхронизации первого листа и только!
            // if (!selItem.value)
            //     this.OnSelectList.Raise(allLists[0].itemsStr)
            }
        } else {
            this.setDefaultLists();
        }

        this.ownerID = null;
    }

    public listNameValidation (listName: string, renameScreenHeader, renameScreenCallBack, element): boolean {
        if (listName.trim() === '') { // cant add Illegal name such a "    "
            TerceraMessageBox.Show(Resources.getResource('screen.error.title'), Resources.getResource('screen.renameScreen.illegalName'), MessageBoxType.Error, null, null, false, true);
            TerceraRenameScreen.show(listName, renameScreenCallBack, renameScreenHeader);
            return false;
        }

        const items = this.get('items');
        const len = items.length - 2;

        for (let i = 0; i < len; i++) {
            if (items[i].text === listName)// &&                         // проверка существования списка с таким названием
            // (!element || element.tag != items[i].value))          // #86928
            {
                TerceraMessageBox.Show(Resources.getResource('screen.error.title'),
                    Resources.getResource('screen.rename.alreadyExist.firstPart') + "'" + listName + "'" + Resources.getResource('screen.rename.alreadyExist.secondPart'),
                    MessageBoxType.Error, null, null, false, true, null,
                    { zIndex: 2100 }); // #115349 (TerceraRenameScreen.zIndex == 2000)

                TerceraRenameScreen.show(element ? element.text : listName, renameScreenCallBack, renameScreenHeader);
                return false;
            }
        }

        if (BrowserUtils.regexpEnd.test(listName) || // #86930
        BrowserUtils.regexpText.test(listName) ||
        BrowserUtils.regexpSymbol.test(listName)) {
            TerceraMessageBox.Show(Resources.getResource('screen.error.title'), Resources.getResource('screen.renameScreen.illegalName'), MessageBoxType.Error, null, null, false, true, null,
                { zIndex: 2100 }); // #115349 (TerceraRenameScreen.zIndex == 2000)
            TerceraRenameScreen.show(listName, renameScreenCallBack, renameScreenHeader);
            return false;
        }

        return true;
    }

    public restoreCorrectSelectItem (selectedText: string): void {
        const itemsAfterSort = this.get('items');
        for (let i = 0; i < itemsAfterSort.length; i++) {
            if (itemsAfterSort[i].text === selectedText) {
                void this.set('selectedItem', itemsAfterSort[i]);
                break;
            }
        }
    }

    public setDefaultLists (): void {
        let newItems = [{ text: Resources.getResource('editableComboBox.defaultList'), value: TerceraEditableListComboBox.VALUE_DEFAULT }];

        newItems = newItems.concat(this.getDefaultCBItems());

        void this.set({ items: newItems });

        const list = SessionSettings.GetWatchListDefaultItem();
        void this.set('valueOfLists', [list]);
    }

    public getDefaultCBItems (): any[] {
        return [{ separator: true, value: TerceraEditableListComboBox.DEFAUL_VALUE_SEPARATOR }, { text: Resources.getResource(this.get('createNew')), value: -1, noImages: true }];
    }

    public override getDefaultItem (): MenuItem {
        let defV = this.getItemByValue(this.get('selectedItem'));
        if (!defV) {
            defV = this.getItemByValue(this.get('defaultValue'));
        }

        return defV;
    }

    public sortMenuItems (): void {
        const itemsAll = this.get('items');
        const items = itemsAll.slice(0, itemsAll.length - 2);
        const defaultItems = itemsAll.slice(itemsAll.length - 2);
        const allLists = this.get('valueOfLists');

        items.sort(function (a, b) {
            if (a.text > b.text) { return 1; }
            if (a.text < b.text) { return -1; }
            return 0;
        });

        for (let i = 0; i < items.length; i++) {
            items[i].value = i;
        }

        allLists.sort(function (a, b) {
            if (a.name > b.name) { return 1; }
            if (a.name < b.name) { return -1; }
            return 0;
        });

        void this.set({
            items: items.concat(defaultItems),
            valueOfLists: allLists
        });
    }

    public override createMenuItems (): any[] {
        const items = this.get('items');
        const menuItems = [];

        const callback = this.private_OnMenuItemSelected.bind(this);

        const len = items.length;
        const selectedItem = this.get('selectedItem');
        const hasSaveImage = this.get('saveImage');
        for (let i = 0; i < len; i++) {
            const item = items[i];
            const mItem = item.separator ? item : {
                text: item.text,
                style: item.style,
                tag: item.value,
                enabled: true,
                event: i + 1 < len ? callback : this.createNewClicked.bind(this),
                smallEvent: this.smallBtnAction.bind(this),
                hasImages: !item.noImages,
                hasSaveImage,
                imagewidth: this.get('imagewidth') || 25,
                canCheck: true,
                checked: selectedItem ? selectedItem.value == item.value : false, // && this.ownerID === this._guid,
                hasPlusImg: item.noImages && hasSaveImage,
                tooltip: item.noImages ? this.get('createNewTooltip') : '',
                noCheckMark: true
            };

            menuItems.push(mItem);
        }

        menuItems[menuItems.length - 1].text = Resources.getResource(this.get('createNew'));

        return menuItems;
    }

    public localize (): void {
        const menu = this.menuItems;

        if (menu) {
            menu[menu.length - 1].text = Resources.getResource(this.get('createNew'));
        }
    }

    public InitByDefaultOrFirst (): void {
        let defV = this.getItemByValue(TerceraEditableListComboBox.VALUE_DEFAULT);
        if (!defV) {
            const items = this.get('items');
            if (items) {
                defV = items[0];
            }
        }
        if (!defV) {
            return;
        }

        this.restoreCorrectSelectItem(defV.text);
    }
}

TerceraComboBox.extendWith(TerceraEditableListComboBox, {
    data: function () {
        return {
            valueOfLists: [],
            checkedMenuItem: null,
            storageName: null,
            saveImage: false,
            newItemPlaceholder: 'screen.renameScreen.newList.placeholder',
            renameHeader: 'screen.renameScreen.rename.header',
            newItemHeader: 'screen.renameScreen.newList.header',
            createNew: 'editableComboBox.CreateNew',
            createNewTooltip: 'editableComboBox.CreateNew.tooltip',
            removeTxtSecondPart: 'screen.remove.confirmText.secondPart',

            showLastValue: true,
            defaultValue: TerceraEditableListComboBox.VALUE_DEFAULT
        };
    }
});
