// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type SymbolSpecificTradingOptions, type BrokerConfigFlags } from '../charting_library';
import { type Account } from '../../cache/Account';
import { DataCache } from '../../DataCache';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { type Instrument } from '../../cache/Instrument';
import { IsAllowed } from '../../IsAllowed';
import { SessionOperations } from '@shared/utils/Enums/Constants';
import { TradingPositionType } from '@shared/utils/Instruments/TradingPositionType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { ProductType } from '@shared/utils/Instruments/ProductType';
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { TvDurationConvertor } from '../Convertors/TvDurationConvertor';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { ApplicationInfo } from '../../ApplicationInfo';

class _TvBrokerConfigFlags {
    public getCongiFlags (instrument: Instrument, account: Account): BrokerConfigFlags {
        const isAllowTradingForPlaceOrder = this.isTradingAllowed(instrument, account, SessionOperations.Open) || ApplicationInfo.isExploreMode;
        const isAllowTradingForModifyOrder = this.isTradingAllowed(instrument, account, SessionOperations.Modify);
        const iAllowModifyOrder = isAllowTradingForModifyOrder && this.isAllowModifyOrder(instrument);

        const configFlags: BrokerConfigFlags = {
            showQuantityInsteadOfAmount: true,
            supportOrderBrackets: this.isSupportOrderBrackets(instrument, account),
            supportTrailingStop: false, // this.isAllowTrailingStop(account),
            supportPositions: this.isAllowPositionsPanel(account), // TODO: NEW RULE FROM SERVER
            supportPositionBrackets: this.isSupportPositionBrackets(instrument, account),
            supportIndividualPositionBrackets: this.isSupportIndividualPositionBrackets(), // Not used, related to Position netting type = Net position by FIFO
            supportPositionNetting: false, // Not used, related to Position netting type = Net position by FIFO
            supportClosePosition: true,
            supportCloseIndividualPosition: false, // Not used, related to Position netting type = Net position by FIFO
            supportModifyOrderPrice: iAllowModifyOrder,
            supportEditAmount: iAllowModifyOrder,
            supportModifyBrackets: this.isSupportModifyBrackets(instrument, account),
            supportLevel2Data: this.isAllowPanel(RulesSet.FUNCTION_SUPER_DOM), // TODO use from Products field FIELD_IS_MD
            supportDOM: this.isAllowPanel(RulesSet.FUNCTION_SUPER_DOM),
            supportMultiposition: this.isSupportMultiPosition(instrument),
            supportPLUpdate: true,
            supportReversePosition: this.isSupportReversePosition(),
            supportNativeReversePosition: false,
            supportMarketOrders: isAllowTradingForPlaceOrder && this.isAllowOrderType(instrument, OrderType.Market),
            supportLimitOrders: isAllowTradingForPlaceOrder && this.isAllowOrderType(instrument, OrderType.Limit),
            supportStopOrders: isAllowTradingForPlaceOrder && this.isAllowOrderType(instrument, OrderType.Stop),
            supportStopLimitOrders: isAllowTradingForPlaceOrder && this.isAllowOrderType(instrument, OrderType.StopLimit),
            supportDemoLiveSwitcher: false,
            supportMarketBrackets: this.isAllowSltp(instrument, account),
            supportSymbolSearch: true, // ?
            supportModifyDuration: iAllowModifyOrder,
            supportModifyTrailingStop: false, // iAllowModifyOrder && this.isAllowOrderType(instrument, OrderType.TrailingStop),
            supportMargin: true,
            calculatePLUsingLast: this.isCalculatePLUsingLast(instrument),
            supportPlaceOrderPreview: !ApplicationInfo.isExploreMode,
            supportModifyOrderPreview: false,
            supportLeverage: false, // UNKNOWN property
            supportLeverageButton: true, // UNKNOWN property
            supportOrdersHistory: this.isAllowPanel(RulesSet.FUNCTION_ORDER_BOOK),
            supportAddBracketsToExistingOrder: this.isSupportAddBracketsToExistingOrder(instrument, account),
            supportBalances: false, // UNKNOWN property
            closePositionCancelsOrders: true,
            supportOnlyPairPositionBrackets: false,
            supportCryptoExchangeOrderTicket: false, // UNKNOWN property
            positionPLInInstrumentCurrency: false,
            supportPartialClosePosition: this.isAllowPartialCloseForPosition(account),
            supportPartialCloseIndividualPosition: false, // UNKNOWN property
            supportCancellingBothBracketsOnly: false,
            supportCryptoBrackets: false, // UNKNOWN property
            showNotificationsLog: this.isAllowPanel(RulesSet.FUNCTION_EVENT_LOG),
            supportStopOrdersInBothDirections: false,
            supportStopLimitOrdersInBothDirections: false,
            supportExecutions: true,
            supportModifyOrderType: false,
            requiresFIFOCloseIndividualPositions: false // UNKNOWN property
        };
        return configFlags;
    }

    public async getSymbolSpecificTradingOptions (symbol: string): Promise<SymbolSpecificTradingOptions | undefined> {
        return await new Promise<SymbolSpecificTradingOptions | undefined>((resolve, reject) => {
            const interiorId = TvInteriorIdCache.getInteriorId(symbol);
            const instrument = DataCache.getInstrumentByName(interiorId);
            if (isNullOrUndefined(instrument)) {
                resolve(undefined);
                return;
            }
            const account = TvAccountHelper.getCurrentAccount();
            const allowedDurations = TvDurationConvertor.getAllowedDurationsForInstrumentAccount(instrument, account);

            const symbolSpecificTradingOptions: SymbolSpecificTradingOptions =
            {
                allowedDurations: allowedDurations.map(x => x.value),
                allowedOrderTypes: TvDurationConvertor.getAllowedTvOrderTypes(instrument, account),
                supportOrderBrackets: this.isSupportOrderBrackets(instrument, account),
                supportAddBracketsToExistingOrder: this.isSupportAddBracketsToExistingOrder(instrument, account),
                supportModifyBrackets: this.isSupportModifyBrackets(instrument, account),
                supportPositionBrackets: this.isSupportPositionBrackets(instrument, account),
                supportIndividualPositionBrackets: this.isSupportIndividualPositionBrackets(), // Not used, related to Position netting type = Net position by FIFO
                supportReversePosition: this.isSupportReversePosition()
                // warningMessage
            };

            resolve(symbolSpecificTradingOptions);
        });
    }

    private isAllowSltp (instrument: Instrument, account: Account): boolean {
        // if(!account)
        // {
        //     return DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_SLTP);
        // }
        // return DataCache.isAllowedForAccount(RulesSet.FUNCTION_SLTP, account);
        const allowSL = IsAllowed.IsSLTPAllowed(instrument, account, true).Allowed;
        const allowTP = IsAllowed.IsSLTPAllowed(instrument, account, false).Allowed;
        return allowSL && allowTP;
    }

    private isAllowTrailingStop (account: Account): boolean {
        return DataCache.isAllowedForAccount(RulesSet.FUNCTION_TRAILING_STOP, account);
    }

    private isAllowPositionsPanel (account: Account): boolean {
        return DataCache.isAllowedForMyUser(RulesSet.FUNCTION_SHOW_POSITIONS);
    }

    private isAllowModifyOrder (instrument: Instrument): boolean {
        return IsAllowed.Check_Session_Operation(instrument, SessionOperations.Modify);
    }

    private isAllowPanel (rule: string): boolean {
        return DataCache.isAllowedForMyUser(rule);
    }

    private isSupportMultiPosition (instrument: Instrument): boolean {
        const posType = instrument.TradingPositionType;

        switch (posType) {
        case TradingPositionType.OnePosition: // Net position by average price
        case TradingPositionType.NotPositionByFIFO:
            return false;

        case TradingPositionType.MultiPosition: // Hedging
        case TradingPositionType.MultiPositionPerSide: // MultiPositionPerSide
        default:
            return true;
        }
    }

    private isAllowOrderType (instrument: Instrument, orderType: number): boolean {
        return instrument.IsAllowOrderType(orderType);
    }

    private isCalculatePLUsingLast (instrument: Instrument): boolean {
        return instrument.CalcPriceOnTrade();
    }

    private isAllowPartialCloseForPosition (account: Account): boolean {
        // TODO: TradingPositionType.OnePosition - разрешено на WinDesktop
        return DataCache.isAllowedForAccount(RulesSet.FUNCTION_PARTIAL_CLOSE, account);
    }

    private isTradingAllowed (instrument: Instrument, account: Account, sessionOperation: number): boolean {
        const defProductType = ProductType.General;// TODO: логика по умолчанию
        return IsAllowed.IsTradingAllowed([account], instrument, -1, sessionOperation, defProductType).Allowed;
    }

    private isSupportOrderBrackets (instrument: Instrument, account: Account): boolean {
        return this.isAllowSltp(instrument, account);
    }

    private isSupportAddBracketsToExistingOrder (instrument: Instrument, account: Account): boolean {
        return this.isAllowSltp(instrument, account);
    }

    private isSupportModifyBrackets (instrument: Instrument, account: Account): boolean {
        const isAllowTradingForModifyOrder = this.isTradingAllowed(instrument, account, SessionOperations.Modify);
        const iAllowModifyOrder = isAllowTradingForModifyOrder && this.isAllowModifyOrder(instrument);
        return this.isAllowSltp(instrument, account) && iAllowModifyOrder;
    }

    private isSupportPositionBrackets (instrument: Instrument, account: Account): boolean {
        return this.isAllowSltp(instrument, account);
    }

    private isSupportIndividualPositionBrackets (): boolean {
        return false;
    }

    private isSupportReversePosition (): boolean {
        return false;
    }
}

export const TvBrokerConfigFlags = new _TvBrokerConfigFlags();
