// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Instrument } from '../../Commons/cache/Instrument';

class _OptionSerializer {
    private readonly separator: string = '|';

    public serialize (optionSerializeData: OptionSerializeData): string {
        let serialized = `${optionSerializeData.optionId}${this.separator}${optionSerializeData.optionRoute}`;
        if (isValidNumber(optionSerializeData.contractTradableId)) {
            serialized += `${this.separator}${optionSerializeData.contractTradableId}`;
        }
        return serialized;
    }

    public deserialize (serialized: string): OptionSerializeData {
        const parts = serialized.split(this.separator);
        return new OptionSerializeData(
            parseInt(parts[0]),
            parseInt(parts[1]),
            parts.length > 2 ? parseInt(parts[2]) : undefined
        );
    }

    public createOptionSerializeData (option: Instrument): OptionSerializeData {
        if (isNullOrUndefined(option)) {
            return new OptionSerializeData(undefined, undefined, undefined);
        }
        const optionId = option.Id;
        const optionRoute = option.Route;
        const contractTradableId = option.ForwardBaseInstrument.isFuturesSymbol ? option.ForwardBaseInstrument.InstrumentTradableID : undefined;
        return new OptionSerializeData(optionId, optionRoute, contractTradableId);
    }
}

export class OptionSerializeData {
    public readonly optionId: number;
    public readonly optionRoute: number;
    public readonly contractTradableId: number | null | undefined;

    constructor (optionId: number, optionRoute: number, contractTradableId: number | null | undefined) {
        this.optionId = optionId;
        this.optionRoute = optionRoute;
        this.contractTradableId = contractTradableId;
    }

    public equals (optionSerializeData: OptionSerializeData): boolean {
        return this.optionId === optionSerializeData.optionId &&
            this.optionRoute === optionSerializeData.optionRoute &&
            this.contractTradableId === optionSerializeData.contractTradableId;
    }
}

export const OptionSerializer = new _OptionSerializer();
