// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../../Localizations/Resources';
import { TerceraButton } from '../../elements/TerceraButton';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { TerceraButtonStyle } from '../../../Utils/Enums/ButtonEnums';
import { ResourcesManager } from '../../../Commons/properties/ResourcesManager';

export class OEPlaceOrderButton extends TerceraButton {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();

        this.observe('textLocalizationKey', this.localize);
        this.observe('side', this.onSideChanged);
        this.observe('tradingAllowed tradingForbiddenReason', this.onTradingRulesChanged);

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        super.dispose();
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);
    }

    public localize (): void {
        const textLocalizationKey = this.get('textLocalizationKey');
        if (isValidString(textLocalizationKey)) {
            void this.set('text', Resources.getResource(textLocalizationKey));
        } else {
            void this.set('text', Resources.getResource('panel.newOrderEntry.placeOrderButton'));
        }
    }

    public onSideChanged (side: OperationType): void {
        void this.set('terceraButtonStyle',
            side === OperationType.Buy
                ? TerceraButtonStyle.Buy
                : TerceraButtonStyle.Sell
        );
    }

    public onTradingRulesChanged (): void {
        const tradingAllowed: boolean = this.get('tradingAllowed');
        const tradingForbiddenReason: string = this.get('tradingForbiddenReason');

        void this.set({
            enabled: tradingAllowed,
            tooltip:
            tradingAllowed || !isValidString(tradingForbiddenReason)
                ? ''
                : tradingForbiddenReason
        });
    }
}

TerceraButton.extendWith(OEPlaceOrderButton, {
    data: function () {
        return {
            width: 296,
            height: 25,

            side: null,
            textLocalizationKey: '',
            tradingAllowed: false,
            tradingForbiddenReason: ''
        };
    }
});
