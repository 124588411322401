// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';

export class ColumnParams {
    public HeaderKey: string; // Ключ колонки
    public ShowTotal: boolean; // Видимость тотала для колонки
    public ColumnType: any; // Тип колонки
    public Width: number; // Ширина колонки
    public Visible: any; // Видимость колонки
    public Hidden: boolean; // Скрытая колонка
    public AllowGrouping: boolean; // Разрешено/запрещено группирование для этой колонки
    public BeginGroup: boolean; // Указывает, должна ли начинаться новая группа в меню
    public EmptyValue: any; // Значение, которое должно игнорироваться при расчете тотала
    public CanEdit: boolean; // #86290

    public TextCompareColumn = false; // Для ВЕБа - на обновление данных должно сравниваться (строковое значение, а не getValue)
    public Locale = ''; // Установить язык, который будет использоваться
    public FormatPrice = false; // Использовать форматирование цены
    public FormulaKey = '';
    public MenuIndex = 1000;
    public AutoGenerated = false;
    public TooltipKey: string;
    public MaxFilterIndex: number;
    public ToolTip: string;

    constructor (headerKey: string, // 1
        width: number, // 2
        columnType, // 3
        showTotal: boolean, // 4
        visible: boolean, // 5
        allowGrouping?: boolean, // 6
        hidden?: boolean, // 7
        beginGroupFlag?, // 8
        emptyValue?, // 9
        canEdit?: boolean) { // 10{
        this.HeaderKey = headerKey;

        this.ShowTotal = showTotal;

        this.ColumnType = columnType;

        this.Width = width;

        this.Visible = visible;

        this.Hidden = hidden || false;

        this.AllowGrouping = allowGrouping;

        this.BeginGroup = beginGroupFlag || false;

        this.EmptyValue = emptyValue || 0;

        this.CanEdit = canEdit || false;
    }

    // Текст колонки (локализированный)
    public HeaderText (): string {
        if (this.Locale == null) {
            return Resources.getResource(this.HeaderKey);
        } else {
            return this.HeaderKey;
        }
    //    return Resources.getResourceLang(this.HeaderKey, this.Locale);
    }
}
