// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type ProductType } from '../../../Utils/Instruments/ProductType';
import { type TIF } from '../../../Utils/Trading/OrderTif';
import { type OrderType } from '../../../Utils/Trading/OrderType';
import { type PlacedFrom } from '../../../Utils/Trading/PlacedFrom';
import { type Quantity } from '../../../Utils/Trading/Quantity';
import { type Account } from '../Account';
import { type Instrument } from '../Instrument';

export class OrderEditConfirmationTextParams {
    OrderTypeKey: string = '';
    Tif: TIF = null;
    Account: Account | null = null;
    Instrument: Instrument | null = null;
    IsCashMode: boolean = null;
    Cash: number = null;
    Quantity: Quantity = null;
    Buy: boolean = null;
    PriceArray: number[] = [];
    SlTpText: string = '';
    PriceInOffset: boolean = null;
    ProductType: ProductType = null;
    OrderType: OrderType = null;
    RealTrStopPrice: number | null = null;
    LeverageValue: number = null;
    PlacedFrom: PlacedFrom | null = null;
    TotalFee: number | null = null;
    AfterTradeCash: number | null = null;
}
