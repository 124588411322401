// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type AccountFeature } from '@shared/utils/Account/AccountFeature';
import { Account } from '../../../cache/Account';
import { TvAccountHelper } from '../../Helpers/TvAccountHelper';
import { type CustomTableElementFormatter, type FormatterName, type TableFormatterInputValues, type TableFormatterInputs } from '../../charting_library';
import { FAILED_TO_FORMAT } from './BaseCustomTableFormatterName';

export class BaseCustomTableFormatter implements CustomTableElementFormatter {
    public readonly name: FormatterName;
    private readonly accountFeatureType: AccountFeature;

    constructor (name: string, accountFeatureType: AccountFeature) {
        this.name = name as FormatterName;
        this.accountFeatureType = accountFeatureType;

        this.formatText = this.formatText.bind(this);
        this.formatElement = this.formatElement.bind(this);
    }

    public formatText (inputs: TableFormatterInputs<TableFormatterInputValues>): string {
        const inputValue = inputs.values[0];
        if (inputs.values.length !== 1 || isNullOrUndefined(inputValue)) {
            return FAILED_TO_FORMAT;
        }

        if (isValidNumber(inputValue)) {
            const account: Account = TvAccountHelper.getCurrentAccount();
            return Account.GetAccountFeatureString(inputValue, this.accountFeatureType, account, account.assetBalanceDefault);
        }
        return inputValue.toString();
    }

    public formatElement (inputs: TableFormatterInputs<TableFormatterInputValues>): string | HTMLElement | undefined {
        const formattedText = this.formatText(inputs);
        if (formattedText === FAILED_TO_FORMAT) { return undefined; }

        let result: string | HTMLElement;

        const numericValue = parseFloat(formattedText);
        if (isValidNumber(numericValue)) {
            const colorStyle = numericValue > 0 ? 'green' : numericValue < 0 ? 'red' : '';
            const span = document.createElement('span');
            span.style.color = colorStyle;
            span.textContent = formattedText;
            result = span;
        } else {
            result = formattedText;
        }

        return result;
    }

    // isPriceFormatterNeeded?: boolean;
}
