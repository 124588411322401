// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export const zh_sm =
{
    'AdditionalProperty.Account': '账户',
    'AdditionalProperty.Reverse': '反向',
    'accountDetailsWidget.DropdownButton': '账户',
    'accountDetailsWidget.DropdownButton.Tooltip': '账户菜单',
    'additional.verification': '需要额外的密码验证。',
    Alligator: '鳄鱼线',
    'Average Directional Index': '平均趋向指数',
    'Average True Range': '平均真实范围',
    'Bollinger Band': '布林带',
    'Bollinger Bands': '布林带',
    'bonds.couponCycle.1m': '1 个月',
    'bonds.couponCycle.1y': '1 年',
    'bonds.couponCycle.3m': '3 个月',
    'bonds.couponCycle.6m': '6 个月',
    'BrokerMessage.Cancel': '取消',
    'BrokerMessage.OK': '确定',
    'BrokerMessage.Text': '经纪人消息',
    'BusinessRejectMessage.28': '当前密码不正确',
    'BusinessRejectMessage.31': '新密码不正确：不能使用相同的密码',
    'BusinessRejectMessage.201': '新密码含有禁止使用的符号',
    'BusinessRejectMessage.202': '密码不能与登录名相同',
    'BusinessRejectMessage.203': '请输入新密码',
    'BusinessRejectMessage.221': '无法更改密码：新密码至少应该包含 1 个大写字符、1 个小写字符和 1 个数字。请重试。',
    'BusinessRejectMessage.222': '无法更改密码：新密码至少应该包含 1 个大写字符、1 个小写字符、1 个数字并且至少包含 8 个字符。请重试。',
    'BusinessRejectMessage.223': '无法更改密码：新密码至少应该包含 1 个大写字符、1 个小写字符、1 个数字、1 个特殊字符（例如 @#$%）并且至少包含 8 个字符。请重试。',
    'BusinessRejectMessage.338': '交易解锁逻辑不允许交易解锁密码。',
    'BusinessRejectMessage.339': '当前交易密码不正确。',
    'Chande Momentum Oscillator': '钱德动量摆动指标',
    'chart.agregationType.Button.ToolTip': '使用预定义的汇总类型',
    'chart.AllowedPeriods.15M': '15 分钟',
    'chart.AllowedPeriods.1D': '1 天',
    'chart.AllowedPeriods.1H': '1 小时',
    'chart.AllowedPeriods.1M': '1 分钟',
    'chart.AllowedPeriods.1S': '1 秒',
    'chart.AllowedPeriods.1T': '1 个跳动点',
    'chart.AllowedPeriods.1W': '1 周',
    'chart.AllowedPeriods.1Y': '1 年',
    'chart.AllowedPeriods.30M': '30 分钟',
    'chart.AllowedPeriods.4H': '4 小时',
    'chart.AllowedPeriods.5M': '5 分钟',
    'chart.AllowedPeriods.Month': '1 个月',
    'chart.AllowedPeriods.Range': '范围',
    'chart.AllowedPeriods.Ticks': '跳动点',
    'chart.ChartPanel.accountLookup.ToolTip': '选择账户',
    'chart.ChartPanel.symbolLookup.ToolTip': '选择股票代码',
    'chart.chartScroll.toBegin': '至最后报价',
    'chart.chartScroll.zoomInButton': '放大',
    'chart.chartScroll.zoomOutButton': '缩小',
    'chart.ChartSymbolSelector.addIndicator.ButtonToolTip': '添加指示符',
    'chart.ChartSymbolSelector.drawingButton.ToolTip': '显示/隐藏绘图工具栏',
    'chart.ChartSymbolSelector.mouseTradeButton.ToolTip': '鼠标交易',
    'chart.ChartSymbolSelector.oeButton': '订单条目',
    'chart.ChartSymbolSelector.oeButton.ToolTip': '图表订单条目',
    'chart.ChartSymbolSelector.OverlayTooltip': '添加覆盖层',
    'chart.ChartSymbolSelector.ObjectManagerTooltip': '对象管理器',
    'Chart.chartWindow.auto': '自动',
    'Chart.chartWindow.collapsed': '已折叠',
    'Chart.chartWindow.manual': '手动',
    'chart.chartWindowManager.SetAuto': '切换到自动价格表',
    'chart.chartWindowManager.SetManual': '切换到手动价格表',
    'chart.head.addOverlay': '添加覆盖层...',
    'chart.head.charttype': '样式',
    'chart.head.line': '绘图工具',
    'chart.head.overlayRemoveAll': '全部删除',
    'chart.head.overlays': '覆盖层',
    'chart.indicator': '指示符',
    'chart.indicatorAdd': '添加指示符',
    'chart.indicatorRemoveAll': '全部删除',
    'chart.indicators': '指示符',
    'chart.Infoline.days': '天',
    'chart.Infoline.hours': '小时',
    'chart.Infoline.min': '分钟',
    'chart.Infoline.price': '价格',
    'chart.Infoline.sec': '秒',
    'chart.Infoline.time': '时间',
    'chart.infoWindow.Items.Ask': '要价：',
    'chart.infoWindow.Items.Bars': '长条数：',
    'chart.infoWindow.Items.Bid': '出价：',
    'chart.infoWindow.Items.Change': '变动，%',
    'chart.infoWindow.Items.Close': '收盘价：',
    'chart.infoWindow.Items.Date': '日期：',
    'chart.infoWindow.Items.High': '高位：',
    'chart.infoWindow.Items.Low': '低位：',
    'chart.infoWindow.Items.Open': '开盘价：',
    'chart.infoWindow.Items.Ticks': '跳动点：',
    'chart.infoWindow.Items.Time': '时间：',
    'chart.infoWindow.Items.Volume': '成交量：',
    'chart.instrumentsLookup': '股票代码查找',
    'chart.layerManager': '设置...',
    'chart.loading': '正在加载...',
    'chart.loadingData': '正在加载以下项的数据',
    'chart.mainlayer.gridPrice': '网格价格',
    'chart.mainlayer.gridTime': '网格时间',
    'chart.mainlayer.volume': '成交量长条图',
    'chart.menu.cancelByAccount': '按账户全部取消',
    'chart.menu.cancelByInstrument': '按股票代码全部取消',
    'chart.menu.cancelOrder': '取消订单',
    'chart.menu.close': '平仓',
    'chart.menu.closeall': '全部平仓...',
    'chart.menu.InfoWindowDock': '基座',
    'chart.menu.InfoWindowHide': '隐藏',
    'chart.menu.InfoWindowSettings': '设置',
    'chart.menu.InfoWindowShortMode': '精简模式',
    'chart.menu.modify': '修改',
    'chart.menu.PlateIndicatorDublicate': '重复指示符',
    'chart.menu.PlateIndicatorRemove': '关闭',
    'chart.menu.PlateIndicatorSettings': '设置',
    'chart.menu.PlateIndicatorVisible': '可见',
    'chart.menu.PlateOverlay.Overlay': '覆盖层',
    'chart.menu.PlateOverlayRemove': '关闭',
    'chart.menu.PlateOverlaySettings': '设置',
    'chart.menu.PlateOverlayVisible': '可见',
    'chart.menu.rollback': '复原头寸',
    'chart.noAvailableData': '无以下项的数据可用',
    'chart.NoData': '无数据',
    'chart.priceScaleContextMenu.AutoScale': '自动',
    'chart.priceScaleContextMenu.FitDay': '适合日高位/低位',
    'chart.priceScaleContextMenu.FitDrawings': '适合绘图',
    'chart.priceScaleContextMenu.FitIndicators': '适合指示符',
    'chart.priceScaleContextMenu.FitOrdersPositions': '适合订单/头寸',
    'chart.priceScaleContextMenu.ManualScale': '手动',
    'chart.refresh': '刷新',
    'chart.Remove': '删除',
    'chart.shortPeriodName.m': '分钟',
    'chart.shortPeriodName.ticks': '跳动点',
    'chart.ShowHighLow.Close': '关闭',
    'chart.ShowHighLow.High': '高',
    'chart.ShowHighLow.Low': '低',
    'chart.StyleButton.ToolTip': '样式',
    'chart.templates.tooltip': '模板',
    'chart.tic.short.15min.name': '15 分钟',
    'chart.tic.short.1day.name': '1 天',
    'chart.tic.short.1hour.name': '1 小时',
    'chart.tic.short.1min.name': '1 分钟',
    'chart.tic.short.1tic.name': '跳动点',
    'chart.tic.short.30min.name': '30 分钟',
    'chart.tic.short.4hour.name': '4 小时',
    'chart.tic.short.5min.name': '5 分钟',
    'chart.tic.short.month.name': '1 个月',
    'chart.tic.short.range.name': '范围',
    'chart.tic.short.second.name': 's',
    'chart.tic.short.week.name': '1 周',
    'chart.tic.short.year.name': '1 年',
    'chart.timeframeselector.rangeButton.ToolTip': '选择数据显示时间段',
    'chart.timeframeselector.time': '时间',
    'chart.timeScaleContextMenu.ShowAllSessions': '显示扩展的时段',
    'chart.timeScaleContextMenu.ShowDaySeparator': '显示天数分隔符',
    'chart.timeScaleContextMenu.ShowEmptyBars': '显示历史记录中的漏洞',
    'chart.timeScaleContextMenu.ShowMonthSeparator': '显示月数分隔符',
    'chart.timeScaleContextMenu.ShowTimePeriodsSeparator': '显示时间段分隔符',
    'chart.timeScaleContextMenu.ShowWeeksSeparator': '显示周数分隔符',
    'chart.timeScaleContextMenu.ShowYearSeparator': '显示年数分隔符',
    'chart.tool.bar': '长条',
    'chart.tool.candle': '阴阳烛',
    'chart.tool.clone': '克隆',
    'chart.tool.Dash': '短划线',
    'chart.tool.delete': '删除',
    'chart.tool.deleteAll': '全部删除',
    'chart.tool.dot': '点',
    'chart.tool.dotline': '虚线',
    'chart.tool.DottedLine': '虚线',
    'chart.tool.forest': '直方图',
    'chart.tool.IsoDot': '等中心点',
    'chart.tool.line': '线条',
    'chart.tool.properties': '设置',
    'chart.tool.ShapedLine': '带形状的线条',
    'chart.tool.solid': '区域',
    'chart.tools.ABCPatern': 'ABC 图案',
    'chart.tools.AndrewPitchfork': '安德鲁音叉线',
    'chart.tools.backcolor': '背景颜色',
    'chart.tools.ButterflyGartley': '加利蝴蝶',
    'chart.tools.Circle': '圆形',
    'chart.tools.default': '指针',
    'chart.tools.Ellipse': '椭圆',
    'chart.tools.eraser': '橡皮擦',
    'chart.tools.FibArc': '斐波纳契弧',
    'chart.tools.FibEllipses': '斐波纳契椭圆',
    'chart.tools.FibExp': '斐波纳契扩展线',
    'chart.tools.FibFan': '斐波纳契扇形',
    'chart.tools.FibPhiChannel': '斐波纳契 phi 通道',
    'chart.tools.Fibretr': '斐波纳契折回',
    'chart.tools.FibSpiral': '斐波纳契螺旋形',
    'chart.tools.FibTga': '斐波纳契时间目标分析',
    'chart.tools.FibTz': '斐波纳契时区',
    'chart.tools.GannFan': '甘恩角度线',
    'chart.tools.GannGrid': '甘恩网格线',
    'chart.tools.GannLine': '甘恩线',
    'chart.tools.horizontal': '水平线',
    'chart.tools.infoline': '标尺',
    'chart.tools.Line': '线条',
    'chart.tools.mirrored': '激活镜像视图',
    'chart.tools.points': '点计数',
    'chart.tools.Polygon': '多边形',
    'chart.tools.PPZ': 'PPZ（价格枢轴区）',
    'chart.tools.PriceChannel': '价格通道',
    'chart.tools.Rectangle': '矩形',
    'chart.tools.RegressionChannel': '回归通道',
    'chart.tools.ScalingByRect': '按矩形缩放',
    'chart.tools.selection': '选择',
    'chart.tools.snapToCandle': '对齐阴阳烛',
    'chart.tools.StayDrawingMode': '留在绘图模式',
    'chart.tools.symbol': '标记',
    'chart.tools.text': '注释',
    'chart.tools.TopBottomPattern': '从上到下模式',
    'chart.tools.Triangle': '三角形',
    'chart.tools.vertical': '垂直线',
    'chart.view': '查看',
    'chart.view.analyseGroupMenu': '分析工具',
    'chart.view.analysetoolbar': '分析工具',
    'chart.view.barsToRight': '右走向长条图...',
    'chart.view.colors': '设置',
    'chart.view.dataInfo': '信息窗口',
    'chart.view.Day_HighLow': '日高位/低位',
    'chart.view.drawingtoolbar': '绘图工具栏',
    'chart.view.font': '轴字体',
    'chart.view.landscapequestion': '使用横向纸张排列？',
    'chart.view.merge': '连续线',
    'chart.view.orderEntry': '图表订单条目',
    'chart.view.periods': '间隔...',
    'chart.view.previousCloseLine': '上一收盘线',
    'chart.view.PrevSettlementPriceLine': '上一结算价格',
    'chart.view.scroller': '滚动条',
    'chart.view.setOrder': '设置顺序',
    'chart.view.SettlementPriceLine': '结算价格',
    'chart.view.show_cross': '跟踪光标',
    'chart.view.show_history': '显示交易历史记录',
    'chart.view.show_set_zero': '显示空长条',
    'chart.view.showEvents': '显示交易',
    'chart.view.showNews': '显示新闻',
    'chart.view.showOrders': '显示订单',
    'chart.view.showPositions': '显示头寸',
    'chart.view.source': '数据类型',
    'chart.view.source0': '按股票代码设置默认值',
    'chart.view.source1': '出价',
    'chart.view.source1000': '要价',
    'chart.view.source1001': '买麦价平均线',
    'chart.view.source1002': '最低值（出价）和最高值（要价）',
    'chart.view.source4': '最后',
    'chart.view.sourceBID/ASK': '按买卖价差',
    'chart.view.sourceTick1': '按买卖价差',
    'chart.view.TimeToNextBar': '到下一长条的时间',
    'chart.view.toolbar': '工具栏',
    'chart.view.volume': '显示成交量',
    'chart.visualTrading.Invalid price': '价格无效',
    'chart.volumeColoringMode.ByBar': '按长条',
    'chart.volumeColoringMode.ByDifference': '按差额',
    'chart.volumeColoringMode.Fixed': '固定',
    'chart.volumeColoringMode.ByMovingAverage': '在移动平均线之上/之下',
    'chart.quickTrading.Buy limit': '买入限价',
    'chart.quickTrading.Buy stop': '买入止损',
    'chart.quickTrading.Invalid price': '价格无效',
    'chart.quickTrading.Sell limit': '卖出限价',
    'chart.quickTrading.Sell stop': '卖出止损',
    'Commodity Channel Index': '商品渠道指数',
    'connect.connection.connect.error': '连接参数错误',
    'connect.connection.tradestream.error': '找不到任何交易流！',
    'connect.connection.unknown.error': '未知错误！',
    Custom: '自定义',
    Day: '天',
    'dealticket.RiskRuleWarning.Header': '风险规则警告',
    Deutsch: '#hidden#',
    'enum.AccountType.ClosedFund': '封闭式基金',
    'enum.AccountType.MultiAsset': '多元资产',
    'enum.AccountType.OpenedFund': '开放式基金',
    'enum.AccountType.SingleCCY': '单一货币',
    'enum.AssetType.COMMODITIES': '商品',
    'enum.AssetType.CRYPTO_CCY': '加密货币',
    'enum.AssetType.CURRENCY': '货币',
    'enum.AssetType.SHARES': '股份',
    'editableComboBox.CreateNew': '新建',
    'editableComboBox.CreateNewTemplate': '创建模板',
    'editableComboBox.CreateNew.tooltip': '单击以创建新的工具列表',
    'editableComboBox.CreateNewTemplate.tooltip': '单击以创建新的模板',
    'editableComboBox.defaultList': '默认列表',
    'editableComboBox.tooltip': '保存的列表',
    'ExchangeId.All': '所有交易所',
    'Exponential Moving Average': '指数移动平均线',
    FOK: 'FOK',
    'FullLogs.Cluster': '服务器',
    'FullLogs.DateTime': '时间',
    'FullLogs.Fields': '字段',
    'FullLogs.Message name': '消息名称',
    'FullLogs.Session': '会话',
    'general.Calculating': '正在计算...',
    'general.connection.restErrorMsg': '此时，保存设置和工作区时出现问题。请联系支持部门',
    'general.connection.Verification password not valid.': '验证密码无效',
    'general.CUSTOM': '自定义',
    'general.Friday': '星期五',
    'general.messageBox.cancel': '取消',
    'general.messageBox.no': '否',
    'general.messageBox.ok': '确定',
    'general.messageBox.showNextTime': '下次显示',
    'general.messageBox.yes': '是',
    'general.Monday': '星期一',
    'general.day1.Mo': '周一',
    'general.day2.Tu': '周二',
    'general.day3.We': '周三',
    'general.day4.Th': '周四',
    'general.day5.Fr': '周五',
    'general.day6.Sa': '周六',
    'general.day7.Su': '周日',
    'general.month.1': '1 月',
    'general.month.10': '10 月',
    'general.month.11': '11 月',
    'general.month.12': '12 月',
    'general.month.2': '2 月',
    'general.month.3': '3 月',
    'general.month.4': '4 月',
    'general.month.5': '5 月',
    'general.month.6': '6 月',
    'general.month.7': '7 月',
    'general.month.8': '8 月',
    'general.month.9': '9 月',
    'general.monthFullName.1': '1 月',
    'general.monthFullName.10': '10 月',
    'general.monthFullName.11': '11 月',
    'general.monthFullName.12': '12 月',
    'general.monthFullName.2': '2 月',
    'general.monthFullName.3': '3 月',
    'general.monthFullName.4': '4 月',
    'general.monthFullName.5': '5 月',
    'general.monthFullName.6': '6 月',
    'general.monthFullName.7': '7 月',
    'general.monthFullName.8': '8 月',
    'general.monthFullName.9': '9 月',
    'general.N_A': '不适用',
    'general.NoData...': '没有数据',
    'general.OvernightMarginNotificationMessage': '显示隔夜保证金通知消息',
    'general.Saturday': '星期六',
    'general.Settings': '设置',
    'general.Sunday': '星期日',
    'general.Thursday': '星期四',
    'general.trading.at': '在',
    'general.trading.Buy': '买入',
    'general.trading.confirmation': '确认',
    'general.trading.confirmation.prefix': '',
    'general.trading.confirmationString': '{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}',
    'general.trading.for': '为',
    'general.trading.limitBuyLessAsk': '限价买入订单价格高于市场要价价格，将立即完成订单。',
    'general.trading.limitSellMoreBid': '限价卖出订单价格低于市场出价价格，将立即完成订单。',
    'general.trading.marketCloseFor': '以下项目的收盘价',
    'general.trading.pips': '跳动点',
    'general.trading.points': '点',
    'general.trading.position': '头寸',
    'general.trading.position.Buy': '多头头寸',
    'general.trading.position.Sell': '空头头寸',
    'general.trading.priceNotAvailable': '不可用',
    'general.trading.refused': '已拒绝',
    'general.trading.Sell': '卖出',
    'general.trading.stopBuyMoreAsk': '止损买入订单价格低于市场要价价格，将立即完成订单。',
    'general.trading.stopSellLessBid': '止损卖出订单价格高于市场出价价格，将立即完成订单。',
    'general.trading.with offset': '有互抵',
    'general.Tuesday': '星期二',
    'property.general.WarnIfWrongOrderParam': '订单错误时警告',
    'general.Wednesday': '星期三',
    GTC: 'GTC',
    'helpPage.link': 'https://guide.traderevolution.com/project/',
    'homePage.link': 'http://traderevolution.com/',
    Ichimoku: '一目均衡表',
    'Instrument.Futures.contract': '合约',
    'Instrument.Level1.ExtendedFields.NormalMarketSize': '正常市场规模',
    'Instrument.Spreadbet.bet': '下注',
    'InstrumentDetailsPanel.(Indicative symbol)': '（指示性符号）',
    'InstrumentDetailsPanel.1.GeneralInfo': '一般信息',
    'InstrumentDetailsPanel.2.TradingInfo': '交易信息',
    'InstrumentDetailsPanel.3.MarginReg': '保证金要求',
    'InstrumentDetailsPanel.4.Fees': '费用',
    'InstrumentDetailsPanel.5.Rebates': '回扣',
    'InstrumentDetailsPanel.6.SessionInfo': '指示性市场时间表',
    'InstrumentDetailsPanel.7.Plans': '计划',
    'InstrumentDetailsPanel.AccruedInterest': '应计利息',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot': '添加每口数的流动性回扣订单',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot': '添加每口数的流动性回扣',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume': '添加每成交量流的动性回扣',
    'InstrumentDetailsPanel.AFTER_MARKET': '售后市场',
    'InstrumentDetailsPanel.Allow short positions': '是',
    'InstrumentDetailsPanel.AllowedOperations': '允许的操作',
    'InstrumentDetailsPanel.AllowedOrderTypes': '允许的订单类型',
    'InstrumentDetailsPanel.AllowShortPositions': '允许卖出空头头寸',
    'InstrumentDetailsPanel.Amount': '数量',
    'InstrumentDetailsPanel.AssetClass': '股票代码类型',
    'InstrumentDetailsPanel.BEFORE_MARKET': '售前市场',
    'InstrumentDetailsPanel.Cash': '现金',
    'InstrumentDetailsPanel.Close': '关闭',
    'InstrumentDetailsPanel.Closed': '已关闭',
    'InstrumentDetailsPanel.CloseOutDeadline': '平仓期限',
    'InstrumentDetailsPanel.Commisions': '佣金',
    'InstrumentDetailsPanel.ContactMonth': '合约月份',
    'InstrumentDetailsPanel.ContractMultiplier': '#hidden#',
    'InstrumentDetailsPanel.ContractSize': '合约规模',
    'InstrumentDetailsPanel.CouponCycle': '息票周期',
    'InstrumentDetailsPanel.CouponRate': '息票率，%',
    'InstrumentDetailsPanel.CurrentSession': '当前时段',
    'InstrumentDetailsPanel.DeliveryMethod': '交割方式',
    'InstrumentDetailsPanel.DeliveryStatus': '交割状态',
    'InstrumentDetailsPanel.Depend from account': '取决于账户',
    'InstrumentDetailsPanel.Description': '描述',
    'InstrumentDetailsPanel.Exchange': '交易所',
    'InstrumentDetailsPanel.ExchangeTradeSession': '交易所时段',
    'InstrumentDetailsPanel.Exp1': '资产',
    'InstrumentDetailsPanel.FaceValue': '面值',
    'InstrumentDetailsPanel.FirstTradeDate': '第一个交易日期',
    'InstrumentDetailsPanel.Futures': '期貨',
    'InstrumentDetailsPanel.FuturesClass': '期貨类',
    'InstrumentDetailsPanel.HiLimit': '上限',
    'InstrumentDetailsPanel.Holiday': '假日',
    'InstrumentDetailsPanel.HolidayDate': '日期',
    'InstrumentDetailsPanel.HolidayName': '假日名称',
    'InstrumentDetailsPanel.Holidays list': '假日列表',
    'InstrumentDetailsPanel.HolidayType': '时间表',
    'InstrumentDetailsPanel.immediate': '立即',
    'InstrumentDetailsPanel.KEYNextHoliday': '下一个假日',
    'InstrumentDetailsPanel.LastTradeDate': '最后一个交易日期',
    'InstrumentDetailsPanel.LotSize': '口数大小',
    'InstrumentDetailsPanel.LotStep': '每口数步长',
    'InstrumentDetailsPanel.LowLimit': '下限',
    'InstrumentDetailsPanel.MAIN': '主要',
    'InstrumentDetailsPanel.Margin': '保证金要求',
    'InstrumentDetailsPanel.Margin_ByAccount': '按账户列出的保证金',
    'InstrumentDetailsPanel.MarginBuy': '买入',
    'InstrumentDetailsPanel.MarginDay': '天',
    'InstrumentDetailsPanel.MarginDayBuy': '日间买入',
    'InstrumentDetailsPanel.MarginDaySell': '日间卖出',
    'InstrumentDetailsPanel.MarginInAccountCurrency': '#hidden#',
    'InstrumentDetailsPanel.MarginOvernight': '隔夜',
    'InstrumentDetailsPanel.MarginOvernightBuy': '隔夜买入',
    'InstrumentDetailsPanel.MarginOvernightSell': '隔夜卖出',
    'InstrumentDetailsPanel.MarginSell': '卖出',
    'InstrumentDetailsPanel.MaturityDate': '成熟度日期',
    'InstrumentDetailsPanel.MaximumLot': '最大口数',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol': '最大头寸数量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday': '日间最大头寸数量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery': '交割最大头寸数量',
    'InstrumentDetailsPanel.MinimalLot': '最小口数',
    'InstrumentDetailsPanel.NextPaymentDate': '下一次收益付款',
    'InstrumentDetailsPanel.No': '否',
    'InstrumentDetailsPanel.non trading day': '关闭',
    'InstrumentDetailsPanel.None': '无',
    'InstrumentDetailsPanel.NormalMarketSize': '#hidden#',
    'InstrumentDetailsPanel.Not allow short positions': '否',
    'InstrumentDetailsPanel.NoticeDate': '通知日期',
    'InstrumentDetailsPanel.onlyCloseOrders': '仅关闭订单',
    'InstrumentDetailsPanel.Open': '打开',
    'InstrumentDetailsPanel.OrderPerLot': '每口数订单',
    'InstrumentDetailsPanel.PerFill': '每完成',
    'InstrumentDetailsPanel.PerLot': '每口数完成',
    'InstrumentDetailsPanel.PerOrder': '每笔订单',
    'InstrumentDetailsPanel.PerOrderVolume': '订单成交量，%',
    'InstrumentDetailsPanel.PerPhoneTransaction': '每宗电话交易',
    'InstrumentDetailsPanel.PerTransaction': '每宗交易',
    'InstrumentDetailsPanel.PerVolume': '完成成交量，%',
    'InstrumentDetailsPanel.Physically': '实物',
    'InstrumentDetailsPanel.POSTCLOSE': '盘后',
    'InstrumentDetailsPanel.PREOPEN': '盘售',
    'InstrumentDetailsPanel.PreviousPaymentDate': '上一次收益付款',
    'InstrumentDetailsPanel.Price': '价格',
    'InstrumentDetailsPanel.ProductType': '产品类型',
    'InstrumentDetailsPanel.QuotiongCurrency': '报价货币',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot': '删除每口数流动性回扣订单',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot': '删除每口数流动性回扣',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume': '删除每成交量流动性回扣',
    'InstrumentDetailsPanel.SessionStatus': '#hidden#',
    'InstrumentDetailsPanel.SettlementDate': '结算日期',
    'InstrumentDetailsPanel.shortened': '做空日期',
    'InstrumentDetailsPanel.StrikePrice': '执行价格',
    'InstrumentDetailsPanel.SwapBuy': '做多掉期',
    'InstrumentDetailsPanel.SwapSell': '做空掉期',
    'InstrumentDetailsPanel.Symbol': '股票代码',
    'InstrumentDetailsPanel.T+0': 'T + 0（本日）',
    'InstrumentDetailsPanel.TickCoast': '跳动点成本',
    'InstrumentDetailsPanel.TickSize': '跳动点规模',
    'InstrumentDetailsPanel.TradindBlockedOnSession': '交易在时段内被冻结',
    'InstrumentDetailsPanel.TradingBalance': '结算系统',
    'InstrumentDetailsPanel.TradingHalt': '交易暂停',
    'InstrumentDetailsPanel.TradingStatus': '股票代码状态',
    'InstrumentDetailsPanel.Underlier': '标的资产',
    'InstrumentDetailsPanel.VAT': 'VAT',
    'InstrumentDetailsPanel.Volume': '完成成交量',
    'InstrumentDetailsPanel.VolumeWithMinPD': '完成成交量 %，含最低 p/d',
    'InstrumentDetailsPanel.working': '开盘',
    'InstrumentDetailsPanel.Yes': '是',
    'InstrumentDetailsPanel.Yield': '收益率',
    'InstrumentDetailsPanel.YTM': '到期收益率，%',
    'InstrumentDetailsPanel.YTM.RefreshLink': '刷新',
    IOC: 'IOC',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders': '不允许按市价执行绑定订单',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp': '不允许在市场中使用止损/盈利价格',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType': '不允许更改订单类型或市价订单类型',
    'IsAllowedResponceReason.InstrumentForAccountDelivery': '账户交割工具',
    'IsAllowedResponceReason.InstrumentTradingMode': '不允许进行工具交易',
    'IsAllowedResponceReason.LockTrading': '交易在平台中被锁定',
    'IsAllowedResponceReason.LockTradingByPassword': '请在终端机底部输入交易密码',
    'IsAllowedResponceReason.NoLastQuote': '没有最后报价',
    'IsAllowedResponceReason.NotAllowedByOrderStaus': '不允许按订单状态键操作',
    'IsAllowedResponceReason.NotAllowedPositionCloseOnly': '只允许平仓',
    'IsAllowedResponceReason.NotAllowedRouteOrderType': '无法创建订单，因为不支持所选择的委托时间有效期和订单类型。',
    'IsAllowedResponceReason.NotClosablePosition': '不可平仓',
    'IsAllowedResponceReason.NotLoadCache': '未加载高速缓存',
    'IsAllowedResponceReason.NotVisibleInstrumentRoute': '不可见的工具或路线',
    'IsAllowedResponceReason.OperationNotAllowedByOrderType': '不允许按订单类型执行操作',
    'IsAllowedResponceReason.OperationNotAllowedBySession': '不允许按时段执行操作',
    'IsAllowedResponceReason.OrderTypeNotAllowedBySession': '不允许按时段划分订单类型',
    'IsAllowedResponceReason.RouteNotRunning': '不允许进行工具交易。',
    'IsAllowedResponceReason.RuleAdministration': '规则管理',
    'IsAllowedResponceReason.RuleClosePositionDisable': '不允许按规则平仓',
    'IsAllowedResponceReason.RuleFunctionSelfTrading': '自行交易规则功能',
    'IsAllowedResponceReason.RuleFunctionTrading': '不允许按规则进行交易',
    'IsAllowedResponceReason.RuleOCO': '二选一委托规则',
    'IsAllowedResponceReason.RuleSlTpNotAllowed': '不允许按规则止损/盈利',
    'IsAllowedResponceReason.RuleTrStop': '规则跟踪止损',
    'IsAllowedResponceReason.Unknown': '未知原因',
    'Keltner Channel': '肯特纳通道',
    Limit: '限额',
    'Linear Weighted Moving Average': '线性加权移动平均线',
    LOC: 'LOC',
    LOO: 'LOO',
    Manual: '手动',
    Market: '市场',
    'MarketDepthPanel.ColoringMethod.ByPriceLVL': '按价格水平',
    'MarketDepthPanel.ColoringMethod.RelativeToVolume': '相对于成交',
    'MarketDepthPanel.ColoringMethod.SizeHistogram': '规模直方图',
    'McGinley Dynamic': '麦金利动态指标',
    'MDLotsMode.General': '一般预设 ({1})',
    'MDLotsMode.Lots': '口数',
    'MDLotsMode.RealSize': '实际规模',
    'MessageBox.ThemeChanged.Title': '警告',
    MOC: 'MOC',
    'Modified Moving Average': '修改后的移动平均线',
    Momentum: '动量',
    MOO: 'MOO',
    'Moving Average Envelope': '移动平均线图',
    'Moving Average/Convergence Divergence': '移动平均/收敛发散指标',
    'NetTrader.SaveWorkspace.Save': '保存工作区',
    'NumericComboCtrl.Edit': '设置默认值',
    OCO: 'OCO',
    'On Balance Volume': '余额数量',
    OnClose: '收盘',
    OnOpen: '开盘',
    'Order.ExecutionType.ACCEPTED': '已接受',
    'Order.ExecutionType.ACTIVATED': '已激活',
    'Order.ExecutionType.CANCELED': '已取消',
    'Order.ExecutionType.FILLED': '已完成',
    'Order.ExecutionType.NEW': '已创建',
    'Order.ExecutionType.PART_FILLED': '已部分完成',
    'Order.ExecutionType.REFUSED': '已拒绝',
    'Order.ExecutionType.REPLACED': '已修改',
    'Order.ExecutionType.RESTATED': '已重列',
    'Order.ExecutionType.UNKNOWN': '未知',
    'OrderEntry.InfoBlock.After trade funds': '交易后资金',
    'OrderEntry.InfoBlock.Availible funds': '可用资金',
    'OrderEntry.InfoBlock.Balance': '余额',
    'OrderEntry.InfoBlock.Fee': '费用',
    'OrderEntry.InfoBlock.Gross': '总额',
    'OrderEntry.InfoBlock.Impact on portfolio': '对投资组合的影响',
    'OrderEntry.InfoBlock.Init. margin': '初始保证金',
    'OrderEntry.InfoBlock.Maint. margin': '维持保证金',
    'OrderEntry.InfoBlock.Margin availible': '可用保证金',
    'OrderEntry.InfoBlock.Net': '净额',
    'OrderEntry.InfoBlock.Spread in loss': '价差初始损失',
    'OverlayScreen.barsBodyLabel': '烛体',
    'OverlayScreen.barsBorderLabel': '边界',
    'OverlayScreen.barsHiLowLabel': '线条',
    'OverlayScreen.cancelButton': '取消',
    'OverlayScreen.dataLabel': '数据类型',
    'OverlayScreen.dojiLabel': '十字线',
    'OverlayScreen.histogramLineLabel': '直方图线',
    'OverlayScreen.instrumentLabel': '股票代码',
    'OverlayScreen.Main scale': '主标尺',
    'OverlayScreen.name': '覆盖层',
    'OverlayScreen.numberOverlaysError': '不能添加超过 5 个覆盖层。',
    'OverlayScreen.okButton': '确定',
    'OverlayScreen.Overlays': '覆盖层',
    'OverlayScreen.priceLabel': '价格比例',
    'OverlayScreen.priceMarkerCheckbox': '显示价格标记',
    'OverlayScreen.Separate scale': '分开标度',
    'OverlayScreen.solidPriceLabel': '区域',
    'OverlayScreen.styleLabel': '样式',
    'OverlayScreen.wickLabel': '烛心',
    'panel.accountDetails.Groups.1.General': '常规',
    'panel.accountDetails.Groups.2.Margin': '保证金',
    'panel.accountDetails.Groups.3.AccountActivity': '账户活动',
    'panel.accountDetails.Groups.4.TodayResults': '本日业绩',
    'panel.accountDetails.Groups.5.RiskManagement': '风险管理',
    'panel.accountDetails.Groups.55.Plans': '计划',
    'panel.accountDetails.Groups.6.Info': '信息',
    'panel.AccountDetailsPanel': '账户详情',
    'panel.AccountDetailsPanel.InfoTooltip': '通过从表格中拖放更改小组件中的字段',
    'panel.accountDetails.Groups.Rico risks': '账户详情',
    'panel.accountDetails.Groups.Kyrrex risks': '账户详情',
    'panel.accountDetails.Groups.AccountActivity': '2.账户活动',
    'panel.accountDetails.Groups.General': '1.常规',
    'panel.accountDetails.Groups.Info': '5.信息',
    'panel.accountDetails.Groups.RiskManagement': '4.风险管理',
    'panel.accountDetails.Groups.TodayResults': '3.本日业绩',
    'panel.accountDetails.Kyrrex risks.AvailableBalance': '可用余额',
    'panel.accountDetails.Kyrrex risks.BlockedBalance': '冻结余额',
    'panel.accountDetails.Rico risks.AVLM': '可用保证金',
    'panel.accountDetails.Rico risks.BCKA': '冻结金额',
    'panel.accountDetails.Rico risks.BKBL': '冻结余额',
    'panel.accountDetails.Rico risks.BMBM': 'BMF 冻结保证金',
    'panel.accountDetails.Rico risks.BMFL': 'BMF 限额',
    'panel.accountDetails.Rico risks.BOPT': '余额选项',
    'panel.accountDetails.Rico risks.BUOP': '余额未覆盖操作',
    'panel.accountDetails.Rico risks.CED0': '贷项分录第 0 天',
    'panel.accountDetails.Rico risks.CED1': '贷项分录第 1 天',
    'panel.accountDetails.Rico risks.CED2': '贷项分录第 2 天',
    'panel.accountDetails.Rico risks.CED3': '贷项分录第 3 天',
    'panel.accountDetails.Rico risks.CRLM': '信用限额',
    'panel.accountDetails.Rico risks.CRLO': '信用限额选项',
    'panel.accountDetails.Rico risks.CULM': '当期限额',
    'panel.accountDetails.Rico risks.D1PB': 'D1 预期余额',
    'panel.accountDetails.Rico risks.D2PB': 'D2 预期余额',
    'panel.accountDetails.Rico risks.D3PB': 'D3 预期余额',
    'panel.accountDetails.Rico risks.D1PD': 'D1 预期日期',
    'panel.accountDetails.Rico risks.D2PD': 'D2 预期日期',
    'panel.accountDetails.Rico risks.D3PD': 'D3 预期日期',
    'panel.accountDetails.Rico risks.DED0': '借项分录第 0 天',
    'panel.accountDetails.Rico risks.DED1': '借项分录第 1 天',
    'panel.accountDetails.Rico risks.DED2': '借项分录第 2 天',
    'panel.accountDetails.Rico risks.DED3': '借项分录第 3 天',
    'panel.accountDetails.Rico risks.DLBL': '日间流动性余额',
    'panel.accountDetails.Rico risks.DLMT': '每日限额',
    'panel.accountDetails.Rico risks.EBVL': '执行买入价值',
    'panel.accountDetails.Rico risks.ESVL': '执行卖出价值',
    'panel.accountDetails.Rico risks.FBBL': '最终 BMF 余额',
    'panel.accountDetails.Rico risks.FNBL': '最终余额',
    'panel.accountDetails.Rico risks.FNLE': '财务杠杆',
    'panel.accountDetails.Rico risks.INBA': '初始余额',
    'panel.accountDetails.Rico risks.INBL': '初始余额',
    'panel.accountDetails.Rico risks.LEVE': '杠杆',
    'panel.accountDetails.Rico risks.LILO': '杠杆期权限额',
    'panel.accountDetails.Rico risks.LVLM': '杠杆限额',
    'panel.accountDetails.Rico risks.OPBL': '期权余额',
    'panel.accountDetails.Rico risks.OVLT': '透支限额',
    'panel.accountDetails.Rico risks.PBVL': '待定买入价值',
    'panel.accountDetails.Rico risks.POVA': '潜在透支金额',
    'panel.accountDetails.Rico risks.PRBA': '预期余额',
    'panel.accountDetails.Rico risks.PSVL': '待定卖出价格',
    'panel.accountDetails.Rico risks.SKBL': '股票余额',
    'panel.accountDetails.Rico risks.WDBL': '提款余额',
    'panel.AccountLink.SymbolLink.ToolTip': '股票代码链接',
    'panel.AccountLink.ToolTip': '账户链接',
    'panel.AccountLink.CanFilterToolTip': '面板中的数据已按账户过滤',
    'panel.AccountLinkButton.ToolTip': '此功能可按账户过滤您的头寸和订单。账户会在所有面板中同步切换',
    'panel.accounts.AccountLink.ToolTip': '已启用账户链接',
    'panel.accounts': '账户',
    'panel.accounts.AccountDescription': '#hidden#',
    'panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached': '已达到每日损失限额',
    'panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached': '已达到最大日成交量',
    'panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)': '订单流量限额（每日）',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled': '已禁用',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled': '已启用',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Active': '活动',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed': '已自动关闭',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed': '已关闭',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules': '风险规则已禁用交易',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning': '有关可能止损离场的警告',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached': '已达到最大跟踪回撤限额',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend': '暂停',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached': '已达到未实现损失限制',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached': '已达到每周损失限额',
    'panel.accounts.AccountMode': '账户模式',
    'panel.accounts.AccountType': '账户类型',
    'panel.accounts.AccountValue': '账户值',
    'panel.accounts.AllowOvernightTrading': '允许隔夜交易',
    'panel.accounts.AllowTradingPrePost': '盘前/盘后交易',
    'panel.accounts.Asset': '资产',
    'panel.accounts.AssetDescription': '资产描述',
    'panel.accounts.AssetType': '资产类型',
    'panel.accounts.AvailableMargin': '可用资金',
    'panel.accounts.Balance': '余额',
    'panel.accounts.BalancePlusAllRisks': '余额和全部风险',
    'panel.accounts.BeginBalance': '本日的净额',
    'panel.accounts.BlockedByOrders': '用于订单的保证金',
    'panel.accounts.BlockedByPamm': '投资基金资本',
    'panel.accounts.BlockedForStocks': '#hidden#',
    'panel.accounts.BlockedForStocksNew': '针对股票冻结',
    'panel.accounts.CashBalance': '现金余额',
    'panel.accounts.ClientType': '终端机类型',
    'panel.accounts.Commissions': '佣金',
    'panel.accounts.CreditValue': '信用值',
    'panel.accounts.CurBalance': '预期余额',
    'panel.accounts.CurMargin': '初始保证金要求，%',
    'panel.accounts.CurrentDailyLoss': '每日损失',
    'panel.accounts.CurrentFundCapital': '当前基金资本',
    'panel.accounts.CurrentStatus': '交易状态',
    'panel.accounts.CurrentStatus.descr': '交易状态',
    'panel.accounts.CurrentWeeklyLoss': '每周损失',
    'panel.accounts.Email': '电子邮件',
    'panel.accounts.Estimate value': '估计价值',
    'panel.accounts.FundCapitalGain': '基金资本收益',
    'panel.accounts.FundsUsed': '已用资金',
    'panel.accounts.FundsUsedPercent': '已用资金，%',
    'panel.accounts.GrossPNL': '本日的总额',
    'panel.accounts.InsertOnBalance': '在余额中插入',
    'panel.accounts.InterestRate': '利率，%',
    'panel.accounts.IntradayRiskManagement': '风险管理状态',
    'panel.accounts.Leverage': '杠杆',
    'panel.accounts.LockedBalance': '冻结余额',
    'panel.accounts.LockedBy': '已锁定',
    'panel.accounts.Login': '账户',
    'panel.accounts.MaintMargin': '维持保证金要求，%',
    'panel.accounts.MarginAndRisks': '保证金和风险',
    'panel.accounts.MarginAvailableReal': '可用保证金',
    'panel.accounts.MarginDeficiency': '保证金不足',
    'panel.accounts.MarginForOthers': '#hidden#',
    'panel.accounts.MarginRequirement': '头寸维持要求',
    'panel.accounts.MarginStopOut': '维持保证金要求',
    'panel.accounts.MarginStopTrade': '止损交易',
    'panel.accounts.MarginSurplus': '保证金盈余',
    'panel.accounts.MarginUsedNew': '初始保证金要求',
    'panel.accounts.MarginWarning': '警告保证金',
    'panel.accounts.marginWarnPercent': '保证金警告水平',
    'panel.accounts.MarketDataCharging': '市场数据收费',
    'panel.accounts.MaxDailyLossLimit': '每日损失限额',
    'panel.accounts.MaxDailyProfit': '每日盈利目标',
    'panel.accounts.MaxDayVolume': '#hidden#',
    'panel.accounts.MaximumLot': '最大口数',
    'panel.accounts.MaxOrderAmount': '最高订单金额',
    'panel.accounts.MaxOrderCapital': '最高订单资本',
    'panel.accounts.MaxOrdersPerDay': '# 订单流量限额（每日）',
    'panel.accounts.MaxPendingOrders': '# 最大待定订单',
    'panel.accounts.MaxPendingOrdersValue': '最大待定订单价值',
    'panel.accounts.MaxPositionQtyPerSymbol': '每个股票代码最大数量',
    'panel.accounts.MaxPositions': '最大头寸数',
    'panel.accounts.MaxWeekLostLimit': '每周损失限额',
    'panel.accounts.MinMargin': '用于头寸的保证金',
    'panel.accounts.Murkups': '加成',
    'panel.accounts.N/A_TelephoneNumber': '不适用',
    'panel.accounts.OptionPremiumReq': '期权? ?溢价? ?要求',
    'panel.accounts.OptionValue': '期权价值',
    'panel.accounts.Orders': '订单数',
    'panel.accounts.Owner': '所有人',
    'panel.accounts.Positions': '头寸数',
    'panel.accounts.PowerOfAttorney': '授权委托书',
    'panel.accounts.Profit': '建仓总损益',
    'panel.accounts.ProfitNet': '建仓净损益',
    'panel.accounts.Role': '资金类型',
    'panel.accounts.Spreads': '价差',
    'panel.accounts.Status': '状态',
    'panel.accounts.Collateral': '#hidden#',
    'panel.accounts.StocksLiquidity': '股票流动性',
    'panel.accounts.StocksOrdersReq': '股票订单要求',
    'panel.accounts.StocksValue': '股票价值',
    'panel.accounts.stopOutPercent': '止损水平',
    'panel.accounts.stopTradingPercent': '止损交易水平',
    'panel.accounts.Swap': '掉期',
    'panel.accounts.TelephoneNumber': '电话号码',
    'panel.accounts.TodayFees': '本日的费用',
    'panel.accounts.TodayRebates': '本日的回扣',
    'panel.accounts.TodaySwaps': '#hidden#',
    'panel.accounts.TodayTrades': '# 本日的交易',
    'panel.accounts.TodayTurnover': '本日成交额',
    'panel.accounts.TodayVolume': '本日成交量',
    'panel.accounts.TotalMaxPositionsQty': '最大头寸总数',
    'panel.accounts.TrailingDrawdownLevel': '跟踪回撤水平',
    'panel.accounts.UnrealizedLoss': '未实现损失',
    'panel.accounts.UnrealizedLossLimit': '未实现损失限额',
    'panel.accounts.UnsettledCash': '未结算资金',
    'panel.accounts.UserGroup': '用户组',
    'panel.accounts.UserID': '用户 ID',
    'panel.accounts.UserLogin': '用户登录',
    'panel.accounts.VolumeLimitForEquities': '股票成交量限额',
    'panel.accounts.VolumeLimitForFutures': '期货成交量限额',
    'panel.accounts.VolumeLimitForOptions': '期权成交数限额',
    'panel.accounts.WithdrawalAvailable': '可用提款',
    'panel.accounts.WaivedMargin': '已放弃保证金',
    'panel.accounts.WaivedMargin.descr': '账户的已放弃保证金',
    'panel.assetBalances': '账户',
    'panel.backoffice': '后台',
    'panel.backoffice.You need to add connection!': '您需要添加连接！',
    'panel.positionsBalance': '头寸余额',
    'panel.caption.NotAllowedMode': '规则集中不允许当前面板',
    'panel.caption.showHideToolbar': '显示工具栏',
    'panel.chart': '图表',
    'panel.close': '关闭',
    'panel.closePosition.Gross_P/L_ToClose': '待结账的总损益',
    'panel.closePosition.QunatityToClose': '待结账的数量',
    'panel.copypanel': '复制面板',
    'panel.detail': '详细报价',
    'panel.detail.AuctionEndTime': '#hidden#',
    'panel.detail.CBAuctionStart': '#hidden#',
    'panel.detail.change': '变动',
    'panel.detail.changePr': '变动，%',
    'panel.detail.CurrentSession': '#hidden#',
    'panel.detail.high': '高',
    'panel.detail.last': '最后',
    'panel.detail.Limit.High': '#hidden#',
    'panel.detail.Limit.Low': '#hidden#',
    'panel.detail.low': '低',
    'panel.detail.NormalMarketSize': '#hidden#',
    'panel.detail.OffExchangeValue': '#hidden#',
    'panel.detail.OffExchangeVolume': '#hidden#',
    'panel.detail.open': '开盘',
    'panel.detail.RemainingQty': '#hidden#',
    'panel.detail.RemainingQtySide': '#hidden#',
    'panel.detail.TradedValue': '#hidden#',
    'panel.detail.TradingStatus': '#hidden#',
    'panel.detail.volume': '成交量',
    'panel.EventLog.Action': '事件',
    'panel.EventLog.Date': '日期',
    'panel.EventLog.eventType.alerts': '警报',
    'screen.Alerts.Ask': '要价',
    'screen.Alerts.Bid': '出价',
    'screen.Alerts.Last': '最后',
    'screen.Alerts.ChangePr': '变动，%',
    'screen.Alerts.Volume': '成交量',
    'panel.EventLog.eventType.comment': '备注',
    'panel.EventLog.eventType.disconnect': '断开连接',
    'panel.EventLog.eventType.exception': '异常',
    'panel.EventLog.eventType.info': '信息',
    'panel.EventLog.eventType.none': '无',
    'panel.EventLog.eventType.system': '系统',
    'panel.EventLog.eventType.trading': '交易',
    'panel.EventLog.eventType.updater': '更新程序',
    'panel.EventLog.menu.Clear': '清除',
    'panel.EventLog.Message': '详细信息',
    'panel.EventLog.NamePanel': '事件日志',
    'panel.EventLog.Roundtrip': '往返，ms',
    'panel.EventLog.Type': '类型',
    'panel.GridPanel.listButton.ToolTip': '选择面板的股票代码',
    'panel.GridPanel.refreshAllCharts.ToolTip': '刷新所有图表',
    'panel.GridPanel.resetMI.Text': '重置面板大小',
    'panel.informer': '观察列表',
    'panel.instrumentInfo.AvgPrice': '平均价格：',
    'panel.instrumentInfo.P/L': '损益：',
    'panel.instrumentInfo.Qty': '数量：',
    'panel.instruments': '股票代码',
    'panel.instrumentsInfo': '股票代码信息',
    'panel.knowledgebase': '知识库',
    'panel.level2': '市场深度',
    'panel.Level2.Age': '#hidden#',
    'panel.level2.Auction': '拍卖',
    'panel.Level2.AvgPrice': '平均价格',
    'panel.Level2.Contr. CCY Total value': '控制货币总值',
    'panel.Level2.Contr. CCY value': '控制货币价值',
    'panel.Level2.DayTradeVolume': '成交量',
    'panel.Level2.menu.View': '查看',
    'panel.Level2.menu.View.InstrInfo': '头寸长条',
    'panel.Level2.menu.View.Level1': '水平 1 长条',
    'panel.Level2.menu.View.Order Entry': '订单条目',
    'panel.Level2.MPID': 'MPID',
    'panel.Level2.Number': '订单',
    'panel.Level2.oe.BuyButton': '买入',
    'panel.Level2.oe.SellButton': '卖出',
    'panel.Level2.Price': '价格',
    'panel.Level2.Size': '规模',
    'panel.Level2.Source': '来源',
    'panel.Level2.Time': '时间',
    'panel.Level2.TotalVol': '总规模',
    'panel.log': '事件日志',
    'panel.menu.GroupBy': '分组依据',
    'panel.menu.new': '新',
    'panel.menu.Remove': '删除',
    'panel.menu.resetToDefault': '重置为默认值',
    'panel.menu.renameList': '单击以重命名工具列表',
    'panel.menu.removeList': '单击以删除工具列表',
    'panel.menu.renameTemplate': '单击以重命名模板',
    'panel.menu.removeTemplate': '单击以删除模板',
    'panel.menu.saveList': '单击以重新保存模板',
    'panel.neworder': '订单条目',
    'panel.newOrderEntry.accountLabel': '账户',
    'panel.newOrderEntry.AddAskButton': '按要价卖出',
    'panel.newOrderEntry.AddBitButton': '按出价买入',
    'panel.newOrderEntry.amountLabel': '数量',
    'panel.newOrderEntry.BuyAskButton': '按要价买入',
    'panel.newOrderEntry.BuyMarketButton': '买方市场',
    'panel.newOrderEntry.buySideCheckBox': '买入',
    'panel.newOrderEntry.instrumentLabel': '股票代码',
    'panel.newOrderEntry.limitPricePanel': '限制价格',
    'panel.newOrderEntry.placeOrderButton': '下单',
    'panel.newOrderEntry.ProductType': '产品类型',
    'panel.newOrderEntry.RefreshMarginReq': '单击以刷新保证金数据，或者等待 30 秒。',
    'panel.newOrderEntry.riskDetailsLabel': '保证金',
    'panel.newOrderEntry.SellBidButton': '按出价卖出',
    'panel.newOrderEntry.SellMarketButton': '卖方市场',
    'panel.newOrderEntry.sellSideCheckBox': '卖出',
    'panel.newOrderEntry.Side': '侧面',
    'panel.newOrderEntry.singleOrderRadio': '单一',
    'panel.newOrderEntry.slPriceRisk': '止损价格',
    'panel.newOrderEntry.slPriceRiskOffset': '止损互抵',
    'panel.newOrderEntry.stopLossCheckBox': '止损价格',
    'panel.newOrderEntry.stopLossCheckBox.offset': '止损互抵',
    'panel.newOrderEntry.stopPricePanel': '止损价格',
    'panel.newOrderEntry.takeProfitCheckBox': '盈利价格',
    'panel.newOrderEntry.takeProfitCheckBox.offset': '盈利互抵',
    'panel.newOrderEntry.tifPanel': '委托时间有效期',
    'panel.newOrderEntry.tpPriceRisk': '盈利价格',
    'panel.newOrderEntry.tpPriceRiskOffset': '盈利互抵',
    'panel.newOrderEntry.trstopOffset': '跟踪止损互抵',
    'panel.newOrderEntry.typePanel': '订单类型',
    'panel.newOrderEntry.stopLossNumeric.ToolTip.offset': '在互抵值中设置止损。要切换到价格，请转至“常规设置”\“交易”默认值',
    'panel.newOrderEntry.stopLossNumeric.ToolTip': '在价格值中设置止损。要切换到互抵，请转至“常规设置”\“交”易默认值',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset': '在互抵值中设置获利。要切换到价格，请转至“常规设置”\“交易”默认值',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip': '在价格值中设置获利。要切换到互抵，请转至“常规设置”\“交”易默认值',
    'panel.NewTrades.Account': '账户',
    'panel.NewTrades.Amount': '数量',
    'panel.NewTrades.Bought': '已买入',
    'panel.NewTrades.Commission': '执行费用',
    'panel.NewTrades.Commission.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Date': '日期/时间',
    'panel.NewTrades.Exchange': '#hidden#',
    'panel.NewTrades.ExpirationDate': '到期日期',
    'panel.NewTrades.ExternalOrderId': '#hidden#',
    'panel.NewTrades.ExternalPrice': '外部价格',
    'panel.NewTrades.ExternalTradeId': '外部 ID',
    'panel.NewTrades.instrType': '股票代码类型',
    'panel.NewTrades.Instrument': '股票代码',
    'panel.NewTrades.Login': '登录',
    'panel.NewTrades.Net_PL': '净损益',
    'panel.NewTrades.Net_PL.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Operation': '侧面',
    'panel.NewTrades.OrderId': '订单 ID',
    'panel.NewTrades.OrderType': '订单类型',
    'panel.NewTrades.Price': '价格',
    'panel.NewTrades.ProductType': '产品类型',
    'panel.NewTrades.PurchasePrice': '曝险',
    'panel.NewTrades.RealizedProfitLoss': '总损益',
    'panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Rebates': '回扣',
    'panel.NewTrades.Route': '路由',
    'panel.NewTrades.Sold': '已卖出',
    'panel.NewTrades.StrikePrice': '执行价格',
    'panel.NewTrades.Time': '时间',
    'panel.NewTrades.TradeID': '交易 ID',
    'panel.NewTrades.TradeIDLabel': '#hidden#',
    'panel.OrderBook.Account': '账户',
    'panel.OrderBook.Action': '侧面',
    'panel.OrderBook.Bought': '已买入',
    'panel.OrderBook.Date': '日期/时间',
    'panel.OrderBook.DislocedQty': '#hidden#',
    'panel.OrderBook.Event': '事件',
    'panel.OrderBook.ExpirationDate': '到期日期',
    'panel.OrderBook.ExternalOrderID': '#hidden#',
    'panel.OrderBook.FilledQuantity': '#hidden#',
    'panel.OrderBook.instrType': '股票代码类型',
    'panel.OrderBook.Instrument': '股票代码',
    'panel.OrderBook.Login': '登录',
    'panel.OrderBook.Message': '消息',
    'panel.OrderBook.OrderNumber': '订单 ID',
    'panel.OrderBook.Price': '价格',
    'panel.OrderBook.ProductType': '产品类型',
    'panel.OrderBook.Quantity': '数量',
    'panel.OrderBook.ReminingQuantity': '#hidden#',
    'panel.OrderBook.RemoteID': '远程 ID',
    'panel.OrderBook.Route': '路由',
    'panel.OrderBook.Sold': '已卖出',
    'panel.OrderBook.StopPrice': '止损价格',
    'panel.OrderBook.StrikePrice': '执行价格',
    'panel.OrderBook.TIF': '委托时间有效期',
    'panel.OrderBook.Time': '时间',
    'panel.OrderBook.TraderID': '用户已修改',
    'panel.OrderBook.Type': '类型',
    'panel.orderEntry': '订单条目',
    'panel.orderHistory': '订单历史记录',
    'panel.orderHistory.print': '#hidden#',
    'panel.orders': '工作订单',
    'panel.orders.Account': '账户',
    'panel.orders.action': '侧面',
    'panel.orders.AvgFilledPrice': '#hidden#',
    'panel.orders.BindTo': '绑定到',
    'panel.orders.Cancel': '取消',
    'panel.orders.Comment': '#hidden#',
    'panel.orders.Comments': '注释',
    'panel.orders.Ct_Ahead': '向前计数',
    'panel.orders.CurrentPrice': '当前价格',
    'panel.orders.date': '日期',
    'panel.orders.date_time': '日期/时间',
    'panel.orders.disclosedQty': '#hidden#',
    'panel.orders.Event': '事件',
    'panel.orders.Exchange': '交易所',
    'panel.orders.ExpirationDate': '到期日期',
    'panel.orders.ExternalOrderId': '#hidden#',
    'panel.orders.ExternalOrderStatus': '#hidden#',
    'panel.orders.GroupId': 'MAM 组 ID',
    'panel.orders.InitReq': '初始要求',
    'panel.orders.Instr_Type': '股票代码类型',
    'panel.orders.Login': '登录',
    'panel.orders.Market': '市场',
    'panel.orders.menu.CancelAllByInstrument': '按选择的股票代码全部取消',
    'panel.orders.menu.CancelAllForAccount': '为选择的账户全部取消',
    'panel.orders.menu.CancelAllOrders': '取消所有订单',
    'panel.orders.menu.CancelOrder': '取消订单',
    'panel.orders.menu.ExecuteAsMarket': '市场变动',
    'panel.orders.menu.ExecuteAsMarket.question': '是否确定要按市价执行订单？',
    'panel.orders.menu.ModifyOrder': '修改订单...',
    'panel.orders.menu.Remove': '取消订单',
    'panel.orders.OCOGrouID': 'OCO 组 ID',
    'panel.orders.price': '价格',
    'panel.orders.ProductType': '产品类型',
    'panel.orders.quantity': '数量',
    'panel.orders.QuantityFilled': '已完成数量',
    'panel.orders.QuantityRemaining': '剩余数量',
    'panel.orders.Reference': '订单 ID',
    'panel.orders.RemoteID': '远程 ID',
    'panel.orders.Route': '路由',
    'panel.orders.SL': '止损',
    'panel.orders.SL_Offset': '止损互抵',
    'panel.orders.SL_Price': '止损价格',
    'panel.orders.Status': '状态',
    'panel.orders.StopPrice': '止损价格',
    'panel.orders.StrikePrice': '执行价格',
    'panel.orders.symbol': '股票代码',
    'panel.orders.symbolDescription': '股票代码描述',
    'panel.orders.TIF': '委托时间有效期',
    'panel.orders.time': '时间',
    'panel.orders.Token': '订单 ID',
    'panel.orders.TP': '盈利',
    'panel.orders.TP_Offset': '盈利互抵',
    'panel.orders.TP_Price': '盈利价格',
    'panel.orders.TraderID': '交易员 ID',
    'panel.orders.type': '类型',
    'panel.orders.UserGroup': '用户组',
    'panel.positions': '头寸',
    'panel.positions.Account': '账户',
    'panel.positions.AvailableForSell': '#hidden#',
    'panel.positions.Basis': '开盘价格',
    'panel.positions.Close': '平仓',
    'panel.positions.comments': '注释',
    'panel.positions.commissions': '费用',
    'panel.positions.commissions.InstrumentCurrency': '#hidden#',
    'panel.positions.confirmation.Reverse': '冲销',
    'panel.positions.confirmation.ReverseAllPositions': '是否确定要冲销所有头寸？',
    'panel.positions.confirmation.ReverseByInstrument': '是否确定要按股票代码和账户冲销所有头寸？',
    'panel.positions.confirmation.ReverseSelectedPositions': '是否确定要冲销选择的头寸？',
    'panel.positions.cur_price': '当前价格',
    'panel.positions.date_time': '日期/时间',
    'panel.positions.Delta': 'Delta',
    'panel.positions.ExpDate': '到期日期',
    'panel.positions.exposition_usd': '头寸曝险',
    'panel.positions.Gamma': 'Gamma',
    'panel.positions.GroupId': 'MAM 组 ID',
    'panel.positions.InitReq': '初始要求',
    'panel.positions.IV': 'IV',
    'panel.positions.Login': '登录',
    'panel.positions.MaintReq': '维持要求',
    'panel.positions.MarginSuplus': '保证金盈余/不足',
    'panel.positions.menu.Close': '平仓',
    'panel.positions.menu.Modify': '修改头寸',
    'panel.positions.menu.ShowToolbar': '显示工具栏',
    'panel.positions.menu.ShowTotals': '显示总额',
    'panel.positions.menu.DisplayTrades': '显示交易',
    'panel.positions.menu.View': '查看',
    'panel.positions.Net_PL': '净损益',
    'panel.positions.Net_PL.InstrumentCurrency': '#hidden#',
    'panel.positions.Operation': '侧面',
    'panel.positions.PL_ticks': '损益，互抵',
    'panel.positions.pos_number': '头寸 ID',
    'panel.positions.PositionValue': '头寸价值',
    'panel.positions.ProductType': '产品类型',
    'panel.positions.profit_usd': '总损益',
    'panel.positions.profit_usd.InstrumentCurrency': '#hidden#',
    'panel.positions.Qunatity': '数量',
    'panel.positions.RealizedPnL': '#hidden#',
    'panel.positions.RealizedPnL.InstrumentCurrency': '#hidden#',
    'panel.positions.Rho': 'Rho',
    'panel.positions.route': '路由',
    'panel.positions.SL': '止损价格',
    'panel.positions.SL_Value': '止损，价值',
    'panel.positions.strike': '执行价格',
    'panel.positions.swaps': '掉期',
    'panel.positions.Symbol': '股票代码',
    'panel.positions.SymbolDescription': '股票代码描述',
    'panel.positions.Theta': 'Theta',
    'panel.positions.TP': '盈利价格',
    'panel.positions.type': '股票代码类型',
    'panel.positions.UsedMargin': '已用保证金',
    'panel.positions.UserGroup': '用户组',
    'panel.positions.Vega': '织女星',
    'panel.settings.Information': '信息',
    'panel.settings.saveDefault': '已成功保存！',
    'panel.statement': '报表',
    'panel.statement.print': '未隐藏',
    'panel.statement.Account': '账户',
    'panel.statement.Instrument': '工具名称',
    'panel.statement.Date': '日期',
    'panel.statement.Operation_type': '操作类型',
    'panel.statement.Operation_ID': '操作 ID',
    'panel.statement.Amount': '金额',
    'panel.statement.Open_price': '开盘价格',
    'panel.statement.Close_price': '收盘价格',
    'panel.statement.Quantity': '数量',
    'panel.statement.Currency': '货币',
    'panel.statement.Trade_opening_date': '交易开放日期',
    'panel.statement.FX_rate': '汇率',
    'panel.statement.searchBtn.tt': '单击以搜索，以查看“报表”面板中的信息',
    'panel.statement.noData': '对于选择的时间范围和过滤器，没有用户历史记录。',
    'panel.terceraChart': '图表',
    'panel.TerceraSymbolLookupDropDownForm.Add': '添加',
    'panel.TerceraSymbolLookupDropDownForm.All': '全部',
    'panel.TerceraSymbolLookupDropDownForm.Bonds': '债券',
    'panel.TerceraSymbolLookupDropDownForm.Cancel': '取消',
    'panel.TerceraSymbolLookupDropDownForm.CFDs': '价差合约',
    'panel.TerceraSymbolLookupDropDownForm.collapceAllButton': '全部折叠',
    'panel.TerceraSymbolLookupDropDownForm.crypto': '加密货币',
    'panel.TerceraSymbolLookupDropDownForm.Equities': '股票',
    'panel.TerceraSymbolLookupDropDownForm.ETFs': '指数股票型基金',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges': '所有交易所',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges': '交易所',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges': '没有交易所',
    'panel.TerceraSymbolLookupDropDownForm.expandExchangesButton': '展开交易所',
    'panel.TerceraSymbolLookupDropDownForm.expandTypesButton': '展开类型',
    'panel.TerceraSymbolLookupDropDownForm.expandGroupsButton': '展开组',
    'panel.TerceraSymbolLookupDropDownForm.Forex': '外汇',
    'panel.TerceraSymbolLookupDropDownForm.Forward': '远期合约',
    'panel.TerceraSymbolLookupDropDownForm.Futures': '期貨',
    'panel.TerceraSymbolLookupDropDownForm.indices': '指标',
    'panel.TerceraSymbolLookupDropDownForm.Options': '期权',
    'panel.TerceraSymbolLookupDropDownForm.Portfolio': '合成',
    'panel.TerceraSymbolLookupDropDownForm.Select': '选择',
    'panel.TerceraSymbolLookupDropDownForm.Select all': '全选',
    'panel.TerceraSymbolLookupDropDownForm.Show additionl info': '显示其他信息',
    'panel.TerceraSymbolLookupDropDownForm.Spot': '即期',
    'panel.TerceraSymbolLookupDropDownForm.Spreadbet': '价差交易',
    'panel.TerceraSymbolLookupDropDownForm.TBill': '国库券',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types': '所有类型',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types': '无类型',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.types': '类型',
    'panel.timeSales': '时间和销售',
    'panel.timeSales.Agressor flag': '侧面',
    'panel.timeSales.AgressorFlag.Auction': '拍卖',
    'panel.timeSales.AgressorFlag.Buy': '买入',
    'panel.timeSales.AgressorFlag.CrossTrade': '套售交易',
    'panel.timeSales.AgressorFlag.None': '无',
    'panel.timeSales.AgressorFlag.Sell': '卖出',
    'panel.timeSales.AgressorFlag.RLP': 'RLP',
    'panel.timeSales.ask': '要价',
    'panel.timeSales.AskBidSize': '买卖价差规模',
    'panel.timeSales.AskSource': '要价 MPID',
    'panel.timeSales.bid': '出价',
    'panel.timeSales.BidSource': '出价 MPID',
    'panel.timeSales.BuyerSource': '买方',
    'panel.timeSales.Exchange': '来源',
    'panel.timeSales.Instrument': '股票代码',
    'panel.timeSales.MenuName': '时间和销售',
    'panel.timeSales.Price': '价格',
    'panel.timeSales.SellerSource': '卖方',
    'panel.timeSales.Size': '规模',
    'panel.timeSales.Time': '时间',
    'panel.timeSales.Type': '股票代码类型',
    'panel.tooltiper.System': '系统',
    'panel.trades': '已完成订单',
    'panel.trades.print': '#hidden#',
    'panel.tradingCentral': '交易中心',
    'panel.tradingCentral.screenBtn.text': '屏幕',
    'panel.tradingCentral.chartBtn.text': '图表',
    'panel.tradingCentral.ScreenButton': '未隐藏',
    'panel.tradingCentral.settings': '设置...',
    'panel.tradingCentral.settings.technicalAnalysis.term': '期限',
    'panel.tradingCentral.settings.technicalAnalysis.days': '天',
    'panel.tradingCentral.settings.technicalAnalysis.lastOnly': '仅最后',
    'panel.tradingCentral.settings.alerts.lastOnly': '仅最后',
    'panel.tradingCentral.settings.alerts.days': '天',
    'panel.tradingCentral.settings.candlestick.lastOnly': '仅最后',
    'panel.tradingCentral.settings.candlestick.days': '天',
    'panel.tradingCentral.term.All': '全部',
    'panel.tradingCentral.term.Intraday': '盘中',
    'panel.tradingCentral.term.Short term': '短期',
    'panel.tradingCentral.term.Middle term': '中期',
    'panel.watchlist': '观察列表',
    'panel.watchlist.Ask': '要价',
    'panel.watchlist.AskSize': '要价规模',
    'panel.watchlist.AuctionEndTime': '#hidden#',
    'panel.watchlist.Bid': '出价',
    'panel.watchlist.BidSize': '出价规模',
    'panel.watchlist.Buy': '买入',
    'panel.watchlist.CBAuctionStart': '#hidden#',
    'panel.watchlist.cell.cancel': '取消',
    'panel.watchlist.cell.modify': '修改',
    'panel.watchlist.cell.Not allow': '不允许',
    'panel.watchlist.cell.sending': '新',
    'panel.watchlist.cell.ticks': '跳动点',
    'panel.watchlist.Change': '变动',
    'panel.watchlist.ChangeFromOpen': '从开盘更改',
    'panel.watchlist.ChangeFromOpen%': '较开盘价的变动，%',
    'panel.watchlist.ChangePr': '变动，%',
    'panel.watchlist.Close': '关闭',
    'panel.watchlist.ClosePostMarket': '盘后平仓',
    'panel.watchlist.Comment': '备注',
    'panel.watchlist.Company': '公司',
    'panel.watchlist.ContractSize': '口数大小',
    'panel.watchlist.CurrentSession': '当前时段',
    'panel.watchlist.Date': '最后日期',
    'panel.watchlist.Descr': '描述',
    'panel.watchlist.Exchange': '交易所',
    'panel.watchlist.ExpDate': '到期日期',
    'panel.watchlist.High': '高',
    'panel.watchlist.High13': '#hidden#',
    'panel.watchlist.High26': '#hidden#',
    'panel.watchlist.High52': '#hidden#',
    'panel.watchlist.IndicativeAuctionPrice': '指示性拍卖价格',
    'panel.watchlist.Instrument': '股票代码',
    'panel.watchlist.Last': '最后',
    'panel.watchlist.LastSize': '最后规模',
    'panel.watchlist.Limit.High': '#hidden#',
    'panel.watchlist.Limit.Low': '#hidden#',
    'panel.watchlist.Low': '低',
    'panel.watchlist.Low13': '#hidden#',
    'panel.watchlist.Low26': '#hidden#',
    'panel.watchlist.Low52': '#hidden#',
    'panel.watchlist.Main Volume': '主成交量',
    'panel.watchlist.Market': '市场',
    'panel.watchlist.MarketCap': '市值',
    'panel.watchlist.Match Volume': '匹配成交量',
    'panel.watchlist.menu.AddSymbol': '添加股票代码...',
    'panel.watchlist.menu.clearAll': '全部清除',
    'panel.watchlist.menu.SymbolLists': '股票代码列表',
    'panel.watchlist.menu.RemoveInstrument': '删除选择的股票代码',
    'panel.watchlist.menu.SymbolInfo': '股票代码信息',
    'panel.watchlist.menu.Chart': '#hidden#',
    'panel.watchlist.menu.MarketDepth': '#hidden#',
    'panel.watchlist.menu.TimeSales': '#hidden#',
    'panel.watchlist.menu.OrderEntry': '#hidden#',
    'panel.watchlist.NA': '不适用',
    'panel.watchlist.noQuotes': '最后更新',
    'panel.watchlist.NormalMarketSize': '#hidden#',
    'panel.watchlist.OffExchangeValue': '#hidden#',
    'panel.watchlist.OffExchangeVolume': '#hidden#',
    'panel.watchlist.Open': '开盘',
    'panel.watchlist.OpenInterest': '#hidden#',
    'panel.watchlist.OpenPreMarket': '盘前建仓',
    'panel.watchlist.Order': '#hidden#',
    'panel.watchlist.Order.tt': '下限价单',
    'panel.watchlist.Trade': '#hidden#',
    'panel.watchlist.Trade.tt': '下市价单',
    'panel.watchlist.ITChartAdvanced': '高级图表',
    'panel.watchlist.ITChartAdvanced.tt': '按 IT-金融划分的高级图表',
    'panel.watchlist.PreMarketChange': '盘前变动',
    'panel.watchlist.PreMarketChange%': '盘前变动，%',
    'panel.watchlist.PrevClose': '上一收盘价',
    'panel.watchlist.PrevSettlementPrice': '上一结算价格',
    'panel.watchlist.ReferencePrice': '参考价格',
    'panel.watchlist.RemainingQty': '#hidden#',
    'panel.watchlist.RemainingQtySide': '#hidden#',
    'panel.watchlist.Sell': '卖出',
    'panel.watchlist.SettlementPrice': '结算价格',
    'panel.watchlist.Spread': '价差',
    'panel.watchlist.SpreadPr': '价差，%',
    'panel.watchlist.Ticks': '跳动点',
    'panel.watchlist.TicksPostMarket': '盘后跳动点',
    'panel.watchlist.TicksPreMarket': '盘前跳动点',
    'panel.watchlist.Time': '上次',
    'panel.watchlist.TradedValue': '#hidden#',
    'panel.watchlist.Type': '股票代码类型',
    'panel.watchlist.Volume': '成交量',
    'panel.watchlist.VolumePostMarket': '盘后成交量',
    'panel.watchlist.VolumePreMarket': '盘前成交量',
    'Panels.MarketDepth.CancelAll': '全部取消',
    'panels.orders.buttons.AsMarket': '市场变动',
    'panels.orders.buttons.CancelAllButton': '全部取消',
    'panels.orders.buttons.CancelBuyButton': '取消买入',
    'panels.orders.buttons.CancelByInstrumentButton': '取消',
    'panels.orders.buttons.CancelDayButton': '取消天数',
    'panels.orders.buttons.CancelGTCButton': '取消 GTC',
    'panels.orders.buttons.CancelLimitsButton': '取消限额',
    'panels.orders.buttons.CancelSelectedButton': '取消所选',
    'panels.orders.buttons.CancelSellButton': '取消卖出',
    'panels.orders.buttons.CancelStopsButton': '取消止损',
    'panels.orders.buttons.ModifyOrder': '修改订单',
    'panels.positions.buttons.closeAll': '全部平仓',
    'panels.positions.buttons.closeForInstrument': '关闭',
    'panels.positions.buttons.closeLong': '多头头寸平仓',
    'panels.positions.buttons.closeNegative': '负值平仓',
    'panels.positions.buttons.closePositive': '正值平仓',
    'panels.positions.buttons.closeSelected': '所选项平仓',
    'panels.positions.buttons.closeShort': '空头头寸平仓',
    'panels.positions.buttons.clxAll': '全部平仓',
    'panels.positions.buttons.clxForInstrument': 'CLX',
    'panels.positions.ButtonsPanel.helpLabel': '右键单击以显示/隐藏按钮',
    'panel.rangeSelect.daily': '每日',
    'panel.rangeSelect.24h': '#hidden#',
    'panel.rangeSelect.7d': '#hidden#',
    'panel.rangeSelect.30d': '#hidden#',
    'panel.rangeSelect.90d': '#hidden#',
    'panel.rangeSelect.range': '范围',
    'panel.rangeSelect.tooltip': '选择显示时间段',
    'PanelSettingsScreen.Text': '- 设置',
    'PanelSettingsScreen.TextSmall': '- 设置',
    'Parabolic Time/Price System': '抛物线时间/价格系统',
    'period.Day': '天',
    'period.Days': '天',
    'period.Hour': '小时',
    'period.Hours': '小时',
    'period.Min': '分钟',
    'period.Mins': '分钟',
    'period.Month': '月',
    'period.Months': '月',
    'period.Week': '周',
    'period.Weeks': '周',
    'period.Year': '年',
    'period.Years': '年',
    'Pivot Point Moving Average': '枢轴点移动平均线',
    PivotPoint: '枢轴点',
    'PopupMultiCkeckBox.All': '全部',
    Português: '葡萄牙语（巴西）',
    'Price Channel': '价格通道',
    'Price Oscillator': '价格摆动指标',
    'ProductType.CarryForward': '结转',
    'ProductType.Delivery': '交割',
    'ProductType.General': '常规',
    'ProductType.Intraday': '盘中',
    'property.%': '%',
    'property.Additional': '其他',
    'property.AllPrice': '价格统计信息',
    'property.appearance': '外观',
    'property.Ask': '要价',
    'property.Author': '作者',
    'property.autosize': '自动调整列大小',
    'property.autoYScale.Auto': '自动缩放',
    'property.autoYScale.Manual': '手动缩放',
    'property.Average': '平均值',
    'property.Backgr': '背景颜色',
    'property.Backgr. top': '背面颜色',
    'property.BarPattern': '长条图图案',
    'property.barsHiLowColor': '线条',
    'property.BarStatistics': '长条图统计信息',
    'property.Bid': '出价',
    'property.bodyColor': '烛体',
    'property.borderColor': '边界',
    'property.BottomCenter': '中下',
    'property.BottomLeft': '左下',
    'property.BottomRight': '右下',
    'property.Browser': '浏览器',
    'property.Buy': '买入',
    'property.BuyColor': '买入',
    'property.cAsk': '要价规模',
    'property.cASK': '要要价',
    'property.cASKHI': '高于要价',
    'property.cBid': '出价规模',
    'property.cBID': '按出价',
    'property.cBIDLO': '低于出价',
    'property.cellscin.useSellBuy': '反转按钮顺序',
    'property.Change': '涨跌',
    'property.ChangePercent': '涨跌%',
    'property.Chart': '图表',
    'property.Chart OE': '图表订单条目',
    'property.chart.isBackground': '移至后台',
    'property.chart.line': '线条',
    'property.chart.ray': 'RAY',
    'property.chart.rayStyle': '射线样式',
    'property.chart.showAngle': '显示角度',
    'property.chart.showingOE': '可见',
    'property.chart.stickTo': '跟随',
    'property.chart.tools.back': '填充绘图',
    'property.chart.tools.fibretr.font': '标签字体',
    'property.chart.tools.fibretr.fontcolor': '字体颜色',
    'property.ChartAnalyseToolsToolBar': '分析工具栏',
    'property.ChartDrawingToolsToolBar': '绘图工具栏',
    'property.cLimit': '限价单',
    'property.Close': '关闭',
    'property.Cluster.ClusterUpDownColors': '群集',
    'property.Cluster.ColorScheme': '配色方案',
    'property.Cluster.ColorScheme.ByDelta': '按 delta 值',
    'property.Cluster.ColorScheme.ByDelta.Colors': '颜色',
    'property.Cluster.ColorScheme.ByDelta.Colors.BuyColor': '买入',
    'property.Cluster.ColorScheme.ByDelta.Colors.SellColor': '卖出',
    'property.Cluster.ColorScheme.ByTrades': '按交易',
    'property.Cluster.ColorScheme.ByVolume': '按成交量',
    'property.Cluster.ColorScheme.Color': '颜色',
    'property.Cluster.ColorScheme.None': '无',
    'property.Cluster.CustomStep': '自定义步长',
    'property.Cluster.Down': '向下',
    'property.Cluster.FilterValue': '过滤器值',
    'property.Cluster.Font': '字体',
    'property.Cluster.FontColor': '字体颜色',
    'property.Cluster.HighlightMax': '突出显示最大值',
    'property.Cluster.HighlightMax.Colors': '颜色',
    'property.Cluster.Operator': '运算符',
    'property.Cluster.PriceStep': '价格步长',
    'property.Cluster.PriceStep.Custom': '自定义',
    'property.Cluster.PriceStep.MinTick': '最小跳动点',
    'property.Cluster.ShowOnlySimilarDelta': '仅显示类似的 delta 值',
    'property.Cluster.ShowValueFilter': '值过滤器',
    'property.Cluster.Up': '向上',
    'property.cMIDLO': '价差之间',
    'property.Color': '颜色',
    'property.ColoringMode': '配色模式',
    'property.Colors': '颜色',
    'property.colors_param': '颜色',
    'property.colorScheme': '样式',
    'property.ColorsSeparatorGroup': '颜色',
    'property.ColouringMode': '配色模式',
    'property.Comments': '注释',
    'property.Company': '公司',
    'property.Confirmations': '确认',
    'property.connection': '连接',
    'property.Connection': '连接',
    'property.ContentAlignment': '对齐',
    'property.Copyrights': '版权',
    'property.cQUOTE': '报价',
    'property.CreateColor': '创建',
    'property.crossHairFontColor': '字体颜色',
    'property.cStop': '止损单',
    'property.currency': '货币',
    'property.CustomYMarkingValue': '最小网格步长',
    'property.Data Box': '信息窗口',
    'property.Data Style': '数据样式',
    'property.Day': '天',
    'property.DaySeparators': '天分隔符',
    'property.DefaultProductType': '默认产品类型',
    'property.Defaults': '交易默认值',
    'property.deviation': '偏差',
    'property.dK': 'dK',
    'property.Doji': '十字线',
    'property.Downband deviation in %': '以百分比表示的下段偏差',
    'property.email': '电子邮件地址\n（使用冒号或分号分隔）',
    'property.Emulator': '模拟器',
    'property.enable': '启用',
    'property.Enable Sounds': '启用声音',
    'property.Exponential': '指数',
    'property.fib.Arc': '斐波纳契弧',
    'property.Fibonacci': '斐波纳契',
    'property.Filters': '过滤器',
    'property.FitDrawings': '适合绘图',
    'property.FitHighLow': '适合日高位/低位',
    'property.FitIndicator': '适合指示符',
    'property.FitOrders': '适合订单/头寸',
    'property.FOK': 'FOK',
    'property.Font': '字体',
    'property.Forex': '外汇',
    'property.Forwards': '远期合约',
    'property.Futures': '期貨',
    'property.FX Cell': '外汇单元',
    'property.G': 'G',
    'property.General': '常规',
    'property.General.ToolTip': '常规设置',
    'property.general.defaultSymbol': '股票代码',
    'property.general.OvernightMarginNotificationMessage': '#hidden#',
    'property.general.showLotsFX': '显示口数中的数量',
    'property.gr_type': '样式',
    'property.Gradient': '渐变',
    'property.GraphicType.Absolute': '绝对',
    'property.GraphicType.Log': '对数',
    'property.GraphicType.Relative': '相对',
    'property.GridPrice': '价格（水平）',
    'property.GridPriceHighLight': '突出显示网格颜色',
    'property.GridTime': '时间（垂直）',
    'property.GTC': 'GTC',
    'property.GTD': 'GTD',
    'property.HeaderFont': '字体',
    'property.HeaderText': '标题文本',
    'property.High': '高',
    'property.HighlightMarkingsStep': '突出显示网格步长',
    'property.HistogramLineColor': '直方图线',
    'property.HistoryType': '数据类型',
    'property.HorizontalLine.LeftRay': '向左扩展',
    'property.hostName': '主机名',
    'property.hotkeyManager.ShowVolume': '显示成交量',
    'property.infowindow_font': '字体',
    'property.infoWindow_fore_back_color': '颜色',
    'property.infowindow_visible': '显示信息窗口',
    'property.Instrument Types': '按类型列出的订单默认值',
    'property.Intrinsic': '内在',
    'property.IOC': 'IOC',
    'property.IsBackground': '是背景',
    'property.IsBarsVisible': '# 长条图',
    'property.IsChangeVisible': '变动，%',
    'property.IsCloseVisible': '关闭',
    'property.IsDateVisible': '日期',
    'property.IsHighVisible': '高',
    'property.IsLowVisible': '低',
    'property.IsOpenVisible': '开盘',
    'property.IsRoundedAvgPrice': '四舍五入的平均开盘价',
    'property.isShowToolTips': '工具提示',
    'property.isShowToolTipsGrop': '工具提示',
    'property.ConfirmationSubgroup.Other': '其他',
    'property.dealTickets.openOnWorkspace': '在工作区上打开交易票据',
    'property.IsSyncActive': '与图表同步',
    'property.IsTicksVisible': '跳动点',
    'property.IsTimeVisible': '时间',
    'property.IsVolumeVisible': '成交量',
    'property.LabelColors': '标签颜色',
    'property.Language': '语言',
    'property.Last': '最后',
    'property.lastPrice_AskIndicatorColor': '要价',
    'property.lastPrice_BidIndicatorColor': '出价',
    'property.lastPrice_LastIndicatorColor': '最后',
    'property.lastPrice_SpreadIndicatorColor': '价差区域颜色',
    'property.Left ray': '向左扩展',
    'property.Level': '水平',
    'property.Limit': '限额',
    'property.Line': '线条',
    'property.line.lineName': '行名称',
    'property.line.plateVisible': '显示行 {0} 标记',
    'property.line.timeShift': '时间迁移',
    'property.line.value': '值',
    'property.line.Visible': '可见',
    'property.line.yFactor': 'Y 因数',
    'property.Linear Weighted': '线性加权',
    'property.lineProperties': '属性',
    'property.LOC': 'LOC',
    'property.logBase': '对数底',
    'property.LOO': 'LOO',
    'property.LotsMode': '在以下项目中显示规模',
    'property.Low': '低',
    'property.Mail Server': '邮件服务器',
    'property.Manual': '手动',
    'property.Market': '市场',
    'property.MarketCap': '市值',
    'property.MarketDepth.ColoringMethod': '配色方法',
    'property.MarketProfile.ColorScheme': '配色方案',
    'property.MarketProfile.ColorScheme.Color': '颜色',
    'property.MarketProfile.ColorScheme.GradientColor': '区域',
    'property.MarketProfile.ColorScheme.PairColor.Color': '颜色',
    'property.MarketProfile.ColorScheme.VolumeColor': '颜色',
    'property.MarketProfile.Font': '字体',
    'property.MarketProfile.PointOfControl': '控制点',
    'property.MarketProfile.PointOfControlStyle': '控制点样式',
    'property.MarketProfile.ShowSingles': '显示单个',
    'property.MarketProfile.SinglesStyle': '单个样式',
    'property.MarketProfile.ValueArea': '值区域',
    'property.MarketProfile.ValueAreaStyleHorisontal': '水平线条样式',
    'property.MarketProfile.ValueAreaStyleVertical': '垂直线条样式',
    'property.MarketProfile.ValuePercents': '值，%',
    'property.Medium': '中等',
    'property.message': '消息',
    'property.method': '方法',
    'property.MirrorShow': '镜像视图',
    'property.MOC': 'MOC',
    'property.Mode': '模式',
    'property.Mode.Trades': '交易',
    'property.Modified': '已修改',
    'property.MonthSeparators': '月分隔符',
    'property.MOO': 'MOO',
    'property.name': '名称',
    'property.Name': '名称',
    'property.News': '新闻',
    'property.None': '无',
    'property.Note': '备注',
    'property.NotSet': '未设置',
    'property.Number': '计数',
    'property.OCO': 'OCO',
    'property.OE_AddBidAddAsk': '按出价买入/按要价卖出',
    'property.OE_ShowBuyMarketSellMarket': '卖方市场/卖方市场',
    'property.OE_ShowSellBidBuyASk': '按出价卖出/按要价买入',
    'property.OE_ShowSLTP': '关闭订单（止损/盈利）',
    'property.OE_Small': '收窄订单条目',
    'property.OE_Small.ToolTip': '如果选中，订单条目将具有狭窄的视图',
    'property.oe.confirmClosePosition': '确认平仓',
    'property.oe.confirmOrderCancel': '确认取消订单',
    'property.oe.confirmOrderChange': '确认修改订单/头寸',
    'property.oe.confirmOrderCreate': '确认下单',
    'property.oe.confirmReversePosition': '确认冲销头寸',
    'property.OffsetMode': '在以下项目中显示互抵',
    'property.OffsetMode.Points': '点',
    'property.OffsetMode.Ticks': '跳动点',
    'property.OffsetMode.TicksFractionalForForex': '跳动点（外汇的分数跳动点）',
    'property.OnClose': '收盘',
    'property.OnOpen': '开盘',
    'property.Open': '开盘',
    'property.Order Entry': '订单条目',
    'property.OrderBuyStyleWidth': '买入',
    'property.OrderSellStyleWidth': '卖出',
    'property.OverlayVisible': '覆盖层可见',
    'property.Panel name': '面板名称',
    'property.panel.accountDetails.Groups.1.General': '常规',
    'property.panel.accountDetails.Groups.2.Margin': '保证金',
    'property.panel.accountDetails.Groups.3.AccountActivity': '账户活动',
    'property.panel.accountDetails.Groups.4.TodayResults': '本日业绩',
    'property.panel.accountDetails.Groups.5.RiskManagement': '风险管理',
    'property.panel.accountDetails.Groups.6.Info': '信息',
    'property.Panels': '面板',
    'property.Parameters': '参数',
    'property.password': '密码',
    'property.period': '时间段',
    'property.Period of indicator': '指示符时间段',
    'property.Period of indicator:': '指示符时间段',
    'property.Period of Linear Regression': '线性回归的时间段',
    'property.Period of MA for envelopes': '移动平均线圈时间段',
    'property.Period of MA1': '移动平均线 1 时间段',
    'property.Period of MA2': '移动平均线 2 时间段',
    'property.Period of momentum:': '动量的时间段',
    'property.Period of Moving Average': '移动平均线的时间段',
    'property.Period of price channel': '价格通道的时间段',
    'property.Period of Simple Moving Average': '简单移动平均线的时间段',
    'property.Period of Smoothed Moving Average': '平滑移动平均线的时间段',
    'property.Periods': '时间段',
    'property.pin': 'PIN',
    'property.pips': '种子',
    'property.POCTypes.Line': '线条',
    'property.POCTypes.Marker': '标记',
    'property.POCTypes.None': '无',
    'property.points': '点',
    'property.Points': '点',
    'property.PositionBuyStyleWidth': '多头头寸',
    'property.PositionSellStyleWidth': '空头头寸',
    'property.Previous': '上一',
    'property.price': '价格',
    'property.Price Scale': '价格标尺',
    'property.PriceIndicatorDrawingType': '价格指示符',
    'property.PriceIndicatorDrawingType.None': '无',
    'property.PriceIndicatorDrawingType.ScaleMarker': '标度标示器',
    'property.PriceIndicatorDrawingType.ScaleMarkerWithLine': '带线的标度标示器',
    'property.PriceScaleAxisColor': '轴',
    'property.PriceScaleBackColor': '轴背景',
    'property.PriceScaleFont': '字体',
    'property.PriceScaleTextColor': '文本颜色',
    'property.PriceType': '价格类型',
    'property.ProfileColoringTypes.DeltaBars': 'Delta 长条图',
    'property.ProfileColoringTypes.DeltaPrice': 'Delta 价格',
    'property.ProfileColoringTypes.DeltaProfile': 'Delta 曲线',
    'property.ProfileColoringTypes.Single': '单色',
    'property.ProfileColoringTypes.UpdownBars': '上/下长条图',
    'property.ProfileColoringTypes.UpdownProfile': '上/下曲线',
    'property.ProfileColoringTypes.VolumeBars': '成交量长条图',
    'property.ProfileColoringTypes.VolumePrice': '成交量价格',
    'property.protrader.showOrders': '订单',
    'property.Range': '范围',
    'property.Ray style': '射线样式',
    'property.Relative': '相对',
    'property.relativeCalcType': '开始价格',
    'property.RelativeCalculationType.BeginOfTheData': '数据开始',
    'property.RelativeCalculationType.BeginOfTheDay': '天开始',
    'property.RelativeCalculationType.BeginOfTheScreen': '屏幕开始',
    'property.relativeScaleType': '标度类型',
    'property.Reserve': '保留',
    'property.Reverse': '冲销',
    'property.Right ray': '向右扩展',
    'property.routing.account': '账户',
    'property.routing.orderType': '订单类型',
    'property.RSI Method:': '相对强弱指标法',
    'property.RSI Period:': '相对强弱指标时间段',
    'property.SCRIPTS_SUBGROUP': '脚本',
    'property.ScrollBar': '滚动条',
    'property.secure': '安全',
    'property.Secure': '安全',
    'property.Selection': '选择',
    'property.Sell': '卖出',
    'property.SellColor': '卖出',
    'property.SeparatorGroup.ActiveOrders': '工作订单',
    'property.SeparatorGroup.additional': '其他',
    'property.SeparatorGroup.Additional': '其他',
    'property.SeparatorGroup.Appearance': '外观',
    'property.SeparatorGroup.CrossHair': '十字线',
    'property.SeparatorGroup.ExecutedOrders': '已完成订单',
    'property.SeparatorGroup.Grid': '网格',
    'property.SeparatorGroup.HighlightDate': '突出显示日期',
    'property.SeparatorGroup.HighlightPrices': '突出显示价格',
    'property.SeparatorGroup.OpenPositions': '建仓',
    'property.SeparatorGroup.ScaleMarkings': '刻度标记',
    'property.SeparatorGroup.ScaleType': '标度类型',
    'property.SeparatorGroup.SeparatorOEWidth': '查看',
    'property.SeparatorGroup.Show': '显示',
    'property.SeparatorGroup.TimeToNextBar': '到下一长条的时间',
    'property.SeparatorGroup.WindowColors': '背景颜色',
    'property.SeparatorGroup.Zoom': '缩放',
    'property.SeparatorViewButtons': '快速交易',
    'property.Sesonality': '季节性',
    'property.shift': '迁移',
    'property.showAllSessions': '显示扩展的时段',
    'property.ShowEmptyBars': '历史记录中的漏洞',
    'property.showEvents': '可见',
    'property.ShowFullArc': '显示完整的弧',
    'property.ShowHighLow': '日高位/低位',
    'property.showOrders': '可见',
    'property.showPositions': '可见',
    'property.ShowPrevCloseLine': '上一收盘线',
    'property.ShowTimeToNextBar': '可见',
    'property.ShowTotal': '显示总计',
    'property.ShowVisualTradingOnLeftSide': '将活动的头寸/订单绑定到左侧',
    'property.showVolume': '可见',
    'property.showVolumeMarker': '在标度上显示值标记',
    'property.Simple': '单一',
    'property.sltpOffsetMode': '在互抵中设置止损/盈利值',
    'property.Smoothing': '平滑',
    'property.solidPriceColor': '区域',
    'property.sounds_param': '声音',
    'property.Sources prices for MA': '移动平均先的来源价格',
    'property.Specific': '特定',
    'property.SpreadType': '差价类型',
    'property.SpreadType.Area': '区域',
    'property.SpreadType.Line': '线条',
    'property.SpreadType.LineWithLabel': '带价格的行',
    'property.SpreadType.None': '无',
    'property.Step of parabolic SAR system': '抛物线停损转向指标系统的步长',
    'property.Stick to': '跟随',
    'property.stickTo': '跟随：',
    'property.Stocks': '股票',
    'property.Stop': '止损',
    'property.Stop Limit': '止损限额',
    'property.Stop-loss': '止损',
    'property.style': '样式：',
    'property.Sum': '合计',
    'property.Symbols': '股票代码',
    'property.T': 'T',
    'property.Take-profit': '盈利',
    'property.text': '文本',
    'property.TIF': '市场委托时间有效期',
    'property.TIF_Limit_And_Stop_Limit': '限额委托时间有效期（止损限额委托时间有效期）',
    'property.TIF_Stop': '止损委托时间有效期',
    'property.Time Scale': '时间标度',
    'property.TimeLine': '时间线',
    'property.TimelinePreview': '滚动条图表预览',
    'property.TimeScaleAxisColor': '轴',
    'property.TimeScaleBackColor': '轴背景',
    'property.TimeScaleFont': '字体',
    'property.TimeScaleImportentFont': '重要日期字体',
    'property.TimeScaleTextColor': '字体颜色',
    'property.TimeToNextBarColor': '颜色',
    'property.TimeToNextBarFont': '字体',
    'property.TopCenter': '中上',
    'property.TopLeft': '左上',
    'property.TopRight': '右上',
    'property.Tr. stop': '跟踪止损',
    'property.trackShowCrossHair': '可见',
    'property.Trading': '交易',
    'property.Trading Defaults': '交易',
    'property.Type of moving average': '移动平均线类型',
    'property.Type of Moving Average': '移动平均线类型',
    'property.Typical': '典型',
    'property.Upband deviation in %': '以百分比表示的上段偏差',
    'property.Update': '更新',
    'property.UseCustomYMarkings': '自定义网格',
    'property.user': '登录',
    'property.useSSL': '安全',
    'property.Value of confidence interval': '置信区间值',
    'property.ValueColors': '值颜色',
    'property.VATypes.Horizontal': '水平',
    'property.VATypes.None': '无',
    'property.VATypes.ToNextProfile': '到下一条曲线',
    'property.VATypes.Vertical': '垂直',
    'property.vendorName': '供应商',
    'property.View': '查看',
    'property.ViewSeparatorGroup': '查看',
    'property.Visibility': '可见性',
    'property.VisibilitySeparatorGroup': '可见性',
    'property.Visible': '可见',
    'property.Visual Trading': '可见交易',
    'property.Volume': '成交量长条图',
    'property.Volume.Font': '字体',
    'property.Volume.FontColor': '字体颜色',
    'property.Volume.UseTicksData': '使用实际跳动点成交量',
    'property.VolumeColoringMode': '大小配色方案',
    'property.VolumeDownColor': '负值',
    'property.VolumeFixedColor': '等于',
    'property.VolumeHeightPercent': '高度，%',
    'property.VolumeMode.BuyVolume': '买入量',
    'property.VolumeMode.Delta': 'Delta',
    'property.VolumeMode.MaxTickVolume': '最大跳动点成交量',
    'property.VolumeMode.SellVolume': '卖出量',
    'property.VolumeMode.TotalVolume': '总成交量',
    'property.VolumeMode.Trades': '交易',
    'property.VolumeOpacityPercent': '透明度',
    'property.VolumeProfile': '成交量曲线',
    'property.VolumeUpColor': '正数',
    'property.VWAP': 'VWAP',
    'property.Warnings': '警告',
    'property.WatermarkColor': '水印颜色',
    'property.WeekSeparators': '周分隔符',
    'property.Weighted': '加权',
    'property.wickColor': '烛心',
    'property.width': '宽度：',
    'property.Win num': '指示符图表上的赢数',
    'property.Win num.addWin': '子窗口',
    'property.Win num.main': '主要',
    'property.Y factor': 'Y 因数',
    'property.YearSeparators': '年分隔符',
    'property.ZeroLine': '零线',
    'property.ZoomValue': '标度步长',
    'propery.MainPriceRenderer.Down': '向下',
    'propery.MainPriceRenderer.Negative': '负值',
    'propery.MainPriceRenderer.Positive': '正数',
    'propery.MainPriceRenderer.Up': '向上',
    'Relative Strength Index': '相对强弱指数',
    'reports.#subgroup.Revenue plan.0': '佣金',
    'reports.#subgroup.Revenue plan.1': '价差',
    'reports.#subgroup.Revenue plan.2': '掉期',
    'reports.#subgroup.Revenue plan.3': '回扣',
    'reports.#subgroup.Revenue plan.4': '股票代码部分',
    'reports.$broker': '经纪人',
    'reports.$broker_create_order': '经纪人创建订单',
    'reports.$broker_refuse_with_answer': '经纪人拒绝并提供回复',
    'reports.$client': '客户端',
    'reports.$client_request': '客户端请求',
    'reports.$close_position': '平仓',
    'reports.$confirm_order_removed': '确认删除订单',
    'reports.$enter_system': '进入系统',
    'reports.$executed': '已执行',
    'reports.$leave_system': '离开系统',
    'reports.$open_position': '建仓',
    'reports.$order': '订单',
    'reports.$request_order_removed': '请求删除订单',
    'reports.4CastNews': '4CastNews',
    'reports.Accepted': '已接受',
    'reports.accname': '账户名称',
    'reports.accoperreqstatus': '状态',
    'reports.account': '账户',
    'reports.Account': '账户',
    'reports.Account Name': '账户名称',
    'reports.Account operation confirmation.': '账户操作确认',
    'reports.Account Operation Report': '账户操作报告',
    'reports.Account operation report (settled)': '账户操作报告（已结算）',
    'reports.Account Operation Report Summary': '账户操作报告摘要',
    'reports.Account operations report': '账户操作报告',
    'reports.Account Operations Report': '账户操作报告',
    'reports.Account Performance Report': '账户表现报告',
    'reports.Account Statement': '账户报表报告',
    'reports.Account statement in defined currency report': '采用已定义货币的账户报表报告',
    'reports.Account Statement on chosen moment report': '选定时刻的账户报表报告',
    'reports.Account Statement Report': '账户报表报告',
    'reports.account_ID': '账户 ID',
    'reports.account.balance': '余额',
    'reports.account.crossinstrument': '十字线符号',
    'reports.account.currency': '货币',
    'reports.account.ismaster': '是主要',
    'reports.account.mirroraccounts': '镜像账户',
    'reports.account.name': '账户名称',
    'reports.account.status': '状态',
    'reports.account.type': '类型',
    'reports.account.userid': '用户 ID',
    'reports.account.visibility.editforsuper': '编辑超级组',
    'reports.account.visibility.editforthis': '编辑此组',
    'reports.account.visibility.tradeforsuper': '交换超级组',
    'reports.account.visibility.tradeforthis': '交换此组',
    'reports.account.visibility.viewforsuper': '查看超级组',
    'reports.account.visibility.viewforthis': '查看此组',
    'reports.accountcurrency': '货币',
    'reports.AccountId': '账户',
    'reports.accountname': '账户',
    'reports.AccountOperationAmount': '金额',
    'reports.accountoperationid': '账户操作 ID',
    'reports.accountPassword': '账户密码',
    'reports.accounts': '账户',
    'reports.accountUsername': '账户用户名',
    'reports.AccruedInterest': '应计利息',
    'reports.ACM Feed': 'ACM 源',
    'reports.action': '侧面',
    'reports.actionname': '操作名称',
    'reports.Activated': '已激活',
    'reports.Active Orders Report': '活动订单报告',
    'reports.actualprice': '实际价格',
    'reports.actualtime': '实际时间',
    'reports.addliquidityrebate': '添加流动性回扣',
    'reports.address': '地址',
    'reports.Adjusment': '调整',
    'reports.Adjustment': '调整',
    'reports.ADMINISTRATION_RULES': '管理规则',
    'reports.AK Equity Algo': 'AK Equity Algo',
    'reports.AK Genex Equity': 'AK Genex Equity',
    'reports.AK Genex Turdex': 'AK Genex Turdex',
    'reports.alertNotify': '警报通知',
    'reports.All brokers': '所有经纪人',
    'reports.All data received.': '已收到所有数据均。',
    'reports.ALLOW_AUTO_APPROVE_TRANSFER_REQUEST': '允许自动批准转账请求',
    'reports.ALLOW_AUTO_ORDER_TYPE': '自动设置订单类型（限价/止损/市价）',
    'reports.ALLOW_AUTO_RESET_PWD': '允许密码自动重置',
    'reports.ALLOW_CLOSE_POSITIONS_ON_STOP_OUT': '在止损离场时平仓',
    'reports.ALLOW_DEMO_AUTO_REGISTRATION': '允许演示用户自动注册',
    'reports.ALLOW_GENERATE_L3': '允许生成 L3',
    'reports.ALLOW_GENERATE_PRICE_FROM_SPOT': '允许从现货生成价格',
    'reports.ALLOW_HEDGE_BY_SPOT': '按现货对冲',
    'reports.ALLOW_HEDGE_MARGIN': '允许对冲保证金',
    'reports.ALLOW_JOIN_POSITIONS': '在对冲时联合单边头寸',
    'reports.ALLOW_LAZY_LOAD_QUOTES': '允许加载延迟的报价',
    'reports.ALLOW_LIVE_AUTO_REGISTRATION': '允许实时自动注册',
    'reports.ALLOW_ONE_BROKER_FOR_ALL_OPERATION': '允许一个经纪人执行所有操作',
    'reports.ALLOW_ORDER_AUTOEXECUTION_IS_MARKET_PRICE': '以市价执行订单（如果为 False，则以订单价执行）',
    'reports.ALLOW_PRICE_EXECUTION_MAPPING': '价格执行映射',
    'reports.ALLOW_PWD_VERIFICATION': '通过 sms 验证密码',
    'reports.ALLOW_REFUSE_IF_BROKER_OFFLINE': '如果经纪人离线则允许拒绝',
    'reports.ALLOW_REMOVE_ORDERS_ON_STOP_OUT': '在止损离场时删除订单',
    'reports.ALLOW_SAVE_HISTORY': '保存股票代码的报价历史记录',
    'reports.ALLOW_SAVE_QUOTE_LOGS': '允许保存报价记录',
    'reports.ALLOW_SAVE_QUOTE_TICKS': '允许保存报价跳动点',
    'reports.ALLOW_SAVE_QUOTES': '允许保存报价',
    'reports.ALLOW_SAVE_TRADE_LOGS': '允许保存交易记录',
    'reports.ALLOW_SHORT_POSITIONS': '允许卖出空头头寸',
    'reports.ALLOW_SKIP_REVERSED_PRICE': '跳过所有“反转”报价',
    'reports.ALLOW_SPIKE_FILTER': '允许价格大幅波动过滤器',
    'reports.ALLOW_STOP_AVARE_MARGIN': '使用止损预警保证金',
    'reports.ALLOW_STORE_USERS_FILES': '允许存储用户文件',
    'reports.ALLOW_USE_CERT': '允许在请求中使用股票代码',
    'reports.ALLOW_USER_LEVERIGE': '允许用户杠杆',
    'reports.ALLOW_USERS_EMAIL_DUPLICATE': '允许复制演示账户的电子邮件',
    'reports.ALLOW_VISIBLITY': '允许可见性',
    'reports.allowLimitOrders': '允许限价单',
    'reports.allowStopOrders': '允许止损单',
    'reports.already in processing': '已在处理中',
    'reports.amount': '数量',
    'reports.Amount': '数量',
    'reports.Amount filled': '已完成数量',
    'reports.amountperside': '每侧金额',
    'reports.An error occurred during sending mail using user settings. Will use default settings.': '使用用户设置发送电子邮件时发生错误。将使用默认设置。',
    'reports.Aphelion': 'Aphelion',
    'reports.APPLICATION.NAME': '应用程序名称',
    'reports.Approved': '已批准',
    'reports.asset': '资产',
    'reports.AuditReport': '后台更改报告',
    'reports.Authorization Error: 不允许操作': '授权错误：不允许操作',
    'reports.autotrade': '自动交易',
    'reports.Average filled price': '价格',
    'reports.Back Office Changes Report': '后台更改报告',
    'reports.balance': '余额',
    'reports.Balance': '余额',
    'reports.Balance by Instrument Report': '按股票代码列出的余额报告',
    'reports.Balance By Instrument Report': '按股票代码列出的余额报告',
    'reports.Balance Operations Report': '余额操作报告',
    'reports.Balance Report': '余额报告',
    'reports.Balance Report(copy)': '余额报告（副本）',
    'reports.Balance Summary report': '余额摘要报告',
    'reports.Balance Summary Report': '余额摘要报告',
    'reports.balance_after': '以下时间后的余额',
    'reports.balance_before': '以下时间前的余额',
    'reports.balance.amount': '数量',
    'reports.balance.buy': '买入',
    'reports.balance.everbuy': '平均买入价',
    'reports.balance.eversell': '平均卖出价',
    'reports.balance.exposure': '曝险',
    'reports.balance.exposurenet': '曝险净额',
    'reports.balance.grossexposure': '曝险总额',
    'reports.balance.grosspl': '总损益',
    'reports.balance.grossvalue': '头寸总价值',
    'reports.balance.net': '净额',
    'reports.balance.netexposure': '曝险净额',
    'reports.balance.netvalue': '头寸净价值',
    'reports.balance.profit': '盈利',
    'reports.balance.sell': '卖出',
    'reports.Barclays': '巴克莱',
    'reports.basis': '备注',
    'reports.Basis': '详细信息',
    'reports.because Not enoght margin to create Order': '因为用于创建订单的保证金不足',
    'reports.because Price for Stop limit order is incorrect.': '因为止损限额单的价格不正确。',
    'reports.BeginString': '开始字符串',
    'reports.blocked_funds': '冻结资金（保证金）',
    'reports.blockedbalance': '冻结余额',
    'reports.Blocking': '冻结',
    'reports.bmr_brokermessageid': '消息 ID',
    'reports.bmr_brokermessagename': '消息名称',
    'reports.bmr_confirmtime': '目标时间',
    'reports.bmr_targetuserid': '用户 ID',
    'reports.bmr_targetuserlogin': '用户',
    'reports.Bound To': '绑定到',
    'reports.Bound to order': '绑定到订单',
    'reports.boundtoorderid': '绑定订单 ID',
    'reports.BRANDING_ADMIN_HELP': '管理员帮助 URL',
    'reports.BRANDING_ANONYM_GROUP': '将自动注册的匿名用户放入',
    'reports.BRANDING_ANONYM_GROUP_SUPPORT': '匿名用户的支持组',
    'reports.BRANDING_CLIENT_MINIMAL_VERSION': '最低客户端版本（用于强制更新）',
    'reports.BRANDING_COMMON_RULES': '共用',
    'reports.BRANDING_COMPANY_DESC': '品牌构建公司描述',
    'reports.BRANDING_COMPANY_INFO_RULES': '公司信息',
    'reports.BRANDING_COMPANY_NAME': '公司名称',
    'reports.BRANDING_COMPANY_URL': '公司 URL',
    'reports.BRANDING_DEMO_GROUP': '将自动注册的演示用户放入',
    'reports.BRANDING_DEMOREGISTRATION_URL': '演示注册（空-此服务器；0-禁用；url-网页）',
    'reports.BRANDING_DEPOSIT_URL': '存款 URL（空-禁用；url-网页）',
    'reports.BRANDING_DISCLAMER': '免责声明文本（在登录皮肤 0 中显示）',
    'reports.BRANDING_DOWJONES_ENCRYPTED_TOKEN': '道琼斯加密令牌',
    'reports.BRANDING_EMAIL': '公司电子邮件',
    'reports.BRANDING_EXT_LINK_RULES': '外部链接',
    'reports.BRANDING_FORGOTPWD_URL': '忘记密码 URL',
    'reports.BRANDING_HTML_FOOTER': '页脚图像',
    'reports.BRANDING_HTML_LOGINSCREEN_LOGO': '登录屏幕图像',
    'reports.BRANDING_HTML_LOGO': '徽标图像',
    'reports.BRANDING_HTML_NAME': '应用程序名称',
    'reports.BRANDING_HTML_STATION_RULES': '后线',
    'reports.BRANDING_INFO_MESSAGE': '弹出消息（登录后在工作站中打开）',
    'reports.BRANDING_KEY': '品牌构建密钥（用于将安装人员链接到它）',
    'reports.BRANDING_KNOWLEDGE_BASE_URL': '知识库 URL（-1=禁用）',
    'reports.BRANDING_LANG_SET': '语言资源',
    'reports.BRANDING_LIVE_GROUP': '将自动注册的实时用户放入',
    'reports.BRANDING_LOGIN_MESSAGE': '登录屏幕注意消息',
    'reports.BRANDING_MAIL_CONFIGURATION': '邮件配置',
    'reports.BRANDING_MAIL_PATTERN': '图案',
    'reports.BRANDING_NET_BACKGROUND': '背景图像（可选/.bmp 任何大小）',
    'reports.BRANDING_NET_BANNER': '横幅图像 (.bmp 500x36)',
    'reports.BRANDING_NET_BROKERIMAGE': '报告图像（.bmp 任何大小）',
    'reports.BRANDING_NET_CONFIGURAION': '默认配置（program.ini 文件）',
    'reports.BRANDING_NET_DEFWORKSPACE': '默认工作区（*.wrk 文件）',
    'reports.BRANDING_NET_FX_STYLE': '外汇单元皮肤（输入代码）',
    'reports.BRANDING_NET_HELP': '帮助 URL',
    'reports.BRANDING_NET_ICON': '图标图像 (.ico 16x16+32x32)',
    'reports.BRANDING_NET_INSTALLER_BANNER_IMAGE': '安装程序横幅图像 (500*59)',
    'reports.BRANDING_NET_INSTALLER_DIALOG_IMAGE': '安装程序对话框图像 (500*316)',
    'reports.BRANDING_NET_LINK': '应用程序链接',
    'reports.BRANDING_NET_LOADING_IMAGE': '正在加载图像 *.gif',
    'reports.BRANDING_NET_LOGIN_STYLE': '登录屏幕皮肤（输入代码）',
    'reports.BRANDING_NET_LOGO': '徽标图像 (.bmp 500x35)',
    'reports.BRANDING_NET_NAME': '应用程序名称（用于应用程序数据，快捷方式）',
    'reports.BRANDING_NET_OE_STYLE': '订单条目皮肤（输入代码）',
    'reports.BRANDING_NET_RIBBONICON': '功能区图标图像 (.ico 32x32)',
    'reports.BRANDING_NET_STATION_RULES': '.Net 工作站',
    'reports.BRANDING_NET_USE_FINGERPRINT': '使用指纹',
    'reports.BRANDING_PHONE': '公司电话',
    'reports.BRANDING_POWERED_BY': '技术支持（公司或人眼名称）',
    'reports.BRANDING_PROPERTIES_RULES': '品牌构建属性',
    'reports.BRANDING_RULES': '品牌构建可见性',
    'reports.BRANDING_SCRIPT_URL': '脚本 URL',
    'reports.BRANDING_SERVER_URL': '品牌化服务器 URL （用于链接第一页的 URL 或 ip，在登录前）',
    'reports.BRANDING_STATUS_MESSAGE': '状态栏消息（显示在工作站状态栏中）',
    'reports.BRANDING_THEME': '外观',
    'reports.BRANDING_TOOLS_RULES': '工具菜单中的外部 Web 链接',
    'reports.BRANDING_TTP_FILE': '面板模板',
    'reports.BRANDING_USERS_RULES': '用户规则',
    'reports.BRANDING_WITHDRAW_URL': '取款 URL（空-禁用；url-网页，1-win 保单）',
    'reports.branding.name': '名称',
    'reports.Broker Message Report': '经纪人消息报告',
    'reports.Brokerage': '业务',
    'reports.brokerCancel': '经纪人取消',
    'reports.brokerlogin': '经纪人登录',
    'reports.Buy': '买入',
    'reports.buy trade amount': '买入交易金额',
    'reports.buy trades': '买入交易',
    'reports.buyselltype': '买卖类型',
    "reports.Can't allocate route for trade using hadget": '无法使用 hadget 为交易分配路由',
    "reports.Can't close entry": '无法关闭条目',
    'reports.Cancel order:': '取消订单：',
    'reports.Cancel request accepted by broker': '经纪人接收到取消请求',
    'reports.Cancel request received by STP': 'STP 接收到取消请求',
    'reports.Canceled': '已取消',
    'reports.Canceling': '已取消',
    'reports.Cancelled': '已取消',
    'reports.Cancelling': '已取消',
    'reports.CantorFX': 'CantorFX',
    'reports.Cash Deposit And Withdrawal Report': '资金存入和提取报告',
    'reports.cash_posted': '资金已过账',
    'reports.Champ stats report': '冠军统计报告',
    'reports.change': '变动',
    'reports.Change password error, new password must differ from old one': '更改密码错误，新密码不能与旧密码相同',
    'reports.CHART_OE': '图表（内置于 OE）',
    'reports.CHART_VISUAL': '图表（可见交易）',
    'reports.Chat History Report': '聊天历史记录报告',
    'reports.CheckCompID': '检查公司 ID',
    'reports.CheckLatency': '检查延迟',
    'reports.ChoiceFX': 'ChoiceFX',
    'reports.CIBC': 'CIBC',
    'reports.Citi': '城市',
    'reports.ClearingVmTransfer': '清除 VM 转移',
    'reports.clfr_close_date': '平仓日期',
    'reports.clfr_fund_name': '基金名称',
    'reports.clientid': '客户端 ID',
    'reports.clienttype': '客户端类型',
    'reports.Close Limit order created': '已创建收盘限价单',
    'reports.Close Limit order created is off market': '收盘限价单已离场',
    'reports.Close Limit order created is unplaced': '没有在交易所下收盘限价单',
    'reports.Close Limit order received by broker': '经纪人已接收到收盘限价单',
    'reports.Close Market order created': '已创建收盘市价单',
    'reports.Close Market Order Created': '已创建收盘市价单',
    'reports.Close Market order created is off market': '收盘市价单已离场',
    'reports.Close Market order created is unplaced': '没有在交易所下收盘市价单',
    'reports.Close Market order received by broker': '经纪人已接收到收盘市价单',
    'reports.Close OCO order created': '已创建收盘二选一委托单',
    'reports.Close OCO order created is off market': '收盘二选一委托单已离场',
    'reports.Close OCO order created is unplaced': '没有在交易所下收盘二选一委托单',
    'reports.Close OCO order received by broker': '经纪人已接收到收盘二选一委托单',
    'reports.Close order type': '收盘订单类型',
    'reports.Close position:': '平仓：',
    'reports.Close Sell': '收盘时卖出',
    'reports.Close SL order created': '已创建收盘止损单',
    'reports.Close SL order created is off market': '收盘收盘止损单已离场',
    'reports.Close SL order created is unplaced': '没有在交易所下收盘止损单',
    'reports.Close SL order received by broker': '经纪人已接收到收盘止损单',
    'reports.Close SL tr. stop order created': '已创建收盘止损跟踪止损单',
    'reports.Close SL tr. stop order created is off market': '收盘止损跟踪止损单已离场',
    'reports.Close SL tr. stop order created is unplaced': '没有在交易所下收盘止损跟踪止损单',
    'reports.Close SL tr. stop order received by broker': '经纪人已接收到收盘止损跟踪止损单',
    'reports.Close Stop Limit order created': '已创建收盘止损限价单',
    'reports.Close Stop Limit order created is off market': '收盘止损限价单已离场',
    'reports.Close Stop Limit order created is unplaced': '没有在交易所下收盘止损限价单',
    'reports.Close Stop Limit order received by broker': '订单经纪人已接收到收盘止损限价单',
    'reports.Close Stop order created': '已创建收盘止损单',
    'reports.Close Stop order created is off market': '收盘止损单已离场',
    'reports.Close Stop order created is unplaced': '没有在交易所下收盘止损单',
    'reports.Close Stop order received by broker': '经纪人已接收到收盘止损单',
    'reports.Close TP order created': '已创建收盘盈利单',
    'reports.Close TP order created is off market': '收盘收盘盈利单已离场',
    'reports.Close TP order created is unplaced': '没有在交易所下收盘盈利单',
    'reports.Close TP order received by broker': '经纪人已接收到收盘盈利单',
    'reports.Close Tr. stop order created': '已创建收盘跟踪止损单',
    'reports.Close Tr. stop order created is off market': '收盘跟踪止损单已离场',
    'reports.Close Tr. stop order created is unplaced': '没有在交易所下收盘跟踪止损单',
    'reports.Close Tr. stop order received by broker': '经纪人已接收到收盘跟踪止损单',
    'reports.CLOSE_ALL': '全部平仓',
    'reports.CLOSE_ALL_ORDERS': '取消所有订单',
    'reports.CLOSE_ALL_POSITIONS': '全部平仓',
    'reports.CLOSE_FIFO': '保证金不足时平仓（先进先出）',
    'reports.CLOSE_LAGES': '保证金不足时平仓（最大者优先）',
    'reports.CLOSE_LIFO': '保证金不足时平仓（后进先出）',
    'reports.closebytradeid': '按交易 ID 平仓',
    'reports.Closed funds report': '封闭式基金报告',
    'reports.Closed Positions': '已平仓头寸报告',
    'reports.closedat': '关闭',
    'reports.closedbyorderid': '按订单 ID 平仓',
    'reports.ClosedResendInterval': '已平仓重新发送间隔',
    'reports.CloseOptionPremium': '平仓权利金',
    'reports.Closeposition': '平仓',
    'reports.closeprice': '收盘价格',
    'reports.closetime': '平仓时间',
    'reports.comission': '费用',
    'reports.Comment': '备注',
    'reports.comments': '注释',
    'reports.comments.CancelOperation': '取消操作 #',
    'reports.COMMISIONSPLAN_RULES': '收入计划可见性',
    'reports.commission': '佣金',
    'reports.commission_out': '费用支出',
    'reports.commissionpertrade': '每宗交易的佣金',
    'reports.commissions': '佣金',
    'reports.Commissions': '费用',
    'reports.Commissions report': '佣金报告',
    'reports.Commissions Report': '佣金报告',
    'reports.Commissions Report(copy)': '佣金报告（副本）',
    'reports.commissiontotal': '佣金总额',
    'reports.COMMON_RULES': '一般规则',
    'reports.Community': '社区',
    'reports.ConfigFilePath': '配置文件路径',
    'reports.Connected': '已连接',
    'reports.Connection established': '已建立连接',
    'reports.Connections Report': '连接报告',
    'reports.ConnectionType': '连接类型',
    'reports.connrp_conntime': '连接次数尝试',
    'reports.connrp_fullname': '名字和姓氏',
    'reports.connrp_ip': 'IP',
    'reports.connrp_lastpwdchange': '上次密码更改',
    'reports.connrp_login': '登录',
    'reports.connrp_success': '连接成功',
    'reports.ContinueInitializationOnError': '出错时继续初始化',
    'reports.Contract Specification Report': '合约规格报告',
    'reports.Counter Account Name': '柜台账户名称',
    'reports.Counter Operation Id': '柜台操作 ID',
    'reports.COUNTERACCOUNTS_RULES': '柜台账户',
    'reports.Created': '已创建',
    'reports.Created by': '创建者',
    'reports.createdat': '创建时间',
    'reports.Creating': '已创建',
    'reports.creator': '创建者',
    'reports.credit': '贷项',
    'reports.Credit': '贷项',
    'reports.crossprice': '交叉价格',
    'reports.crosstype': '交叉类型',
    'reports.currency': '货币',
    'reports.Currency Exposure Report': '货币曝险报告',
    'reports.Currency summary exposure': '货币汇总曝险',
    'reports.currency.balance_interest': '余额利息 (%)',
    'reports.currency.interest_buy_rate': '正数利息 (%)',
    'reports.currency.interest_sell_rate': '负数利息 (%)',
    'reports.currency.leverage_negative': '负数曝险杠杆',
    'reports.currency.leverage_positive': '正数曝险杠杆',
    'reports.currency.min_change': '最小变动',
    'reports.currency.name': '名称',
    'reports.Currenex': 'Currenex',
    'reports.CurrenexFIX': 'CurrenexFIX',
    'reports.Current Balance': '当期余额',
    'reports.currentprice': '当前价格',
    'reports.CUSTOM_COLUMN_BTN_SCRIPT': '自定义列（按钮脚本）',
    'reports.customReport': '自定义报告',
    'reports.DataDictionary': '数据字典',
    'reports.DateTime': '日期/时间',
    'reports.days': '天',
    'reports.daystillexpiration': '到期前天数',
    'reports.DBFX': 'DBFX',
    'reports.dealercommission': '经销商佣金',
    'reports.debit': '借项',
    'reports.Default settings failed': '默认设置失败',
    'reports.Delayed Limit Order Created': '已创建待定限价单',
    'reports.Delayed Limit Order Modified': '已修改待定限价单',
    'reports.Delayed Limit Order Removed': '已删除待定限价单',
    'reports.Delayed OCO Order Created': '已创建待定二选一委托单',
    'reports.Delayed Stop Limit Order Created': '已创建止损限价单',
    'reports.Delayed Stop Limit Order Modified': '已修改止损限价单',
    'reports.Delayed Stop Limit Order Removed': '已删除止损限价单',
    'reports.Delayed Stop Order Created': '已创建待定止损单',
    'reports.Delayed Stop Order Modified': '已修改待定止损单',
    'reports.Delayed Stop Order Removed': '已删除处待定止损单',
    'reports.Delayed Trailing Stop Order Created': '已创建跟踪止损单',
    'reports.Delayed Trailing Stop Order Modified': '已修改跟踪止损单',
    'reports.Delayed Trailing Stop Order Removed': '已删除跟踪止损单',
    'reports.DelayedCharging': '延迟收费',
    'reports.DelayedDataCharging': '延迟数据收费',
    'reports.delivered': '已交割',
    'reports.DeliveredForward': '已交割远期合约',
    'reports.Delivery': '交割',
    'reports.demoMode': '演示模式',
    'reports.deposit': '存款',
    'reports.Deposit': '存款',
    'reports.Detail': '详细信息',
    'reports.Deutsche Bank': '德意志银行',
    'reports.DeutscheBankFIXFeed': 'DeutscheBankFIXFeed',
    'reports.DGCX': 'DGCX',
    'reports.Dictionary': '字典',
    'reports.discount': '折扣',
    'reports.dividends': '红利',
    'reports.DO_NOT_CLOSE': '不平仓',
    'reports.DukasCopy': 'DukasCopy',
    'reports.DWT': 'DWT',
    'reports.email': '电子邮件',
    'reports.EMAIL_NOTIFICATION': '电子邮件通知图案可见性',
    'reports.EMAIL_RULES': '电子邮件通知图案可见性',
    'reports.EndDay': '结束日期',
    'reports.EndTime': '结束时间',
    'reports.entityId': '实体 ID',
    'reports.enum_StopOutType_AutoClose': '自动平仓',
    'reports.enum_StopOutType_CloseAllByInstruments': '通过工具全部平仓',
    'reports.enum_StopOutType_CloseAllPositions': '全部平仓',
    'reports.enum_StopOutType_CloseLagestLoss': '保证金不足时平仓（损失最大最先）',
    'reports.enum_StopOutType_ClosePositionsByMarginExcess': '通过保证金超额部分平仓',
    'reports.enum_StopOutType_DoNotClosePositions': '不平仓',
    'reports.enum_StopOutType_FIFO': '保证金不足时平仓（先进先出）',
    'reports.enum_StopOutType_LargestFirst': '保证金不足时平仓（最大者优先）',
    'reports.enum_StopOutType_LIFO': '保证金不足时平仓（后进先出）',
    'reports.Error occured during downloading update:': '下载更新时出错：',
    'reports.Error sending email': '发送电子邮件时出错',
    'reports.Error: 没有足够的建仓保证金': '错误：没有足够的建仓保证金',
    'reports.error.certificate_corrupted': '用户证书损坏。',
    'reports.error.certificate_not_found': '找不到用户证书。',
    'reports.error.error_during_saving_route': '保存路由时出错。请与您的管理员联系。',
    'reports.error.fill_mandatory': '请填写所有必填字段。',
    'reports.error.name_is_in_use': '此名称已存在！',
    'reports.error.not_valid_email': '电子邮件无效。',
    'reports.error.not_valid_firstname': '名称无效',
    'reports.error.not_valid_instrumentname_capital': '名称无效只需要大写字母。',
    'reports.error.not_valid_instrumentname_exist': '名称无效此名称已存在。',
    'reports.error.not_valid_instrumentname_length': '名称无效名称长度不能超过 32 个字符。',
    'reports.error.not_valid_instrumenttypename': '工具类型无效。请指定正确的工具类型。',
    'reports.error.not_valid_lastname': '姓氏无效。',
    'reports.error.not_valid_login': '登录无效。',
    'reports.error.not_valid_name': '名称无效。',
    'reports.error.not_valid_name_exist': '名称无效此名称已存在。',
    'reports.error.not_valid_phone_number': '电话号码无效。只能输入数字数据。',
    'reports.error.photo_not_found': '找不到照片',
    'reports.error.too_short_phone_password': '电话密码太短',
    'reports.error.wrong_percenage': '百分比错误',
    'reports.ESignal': '电子信号',
    'reports.event': '事件',
    'reports.Event History Report': '事件历史记录报告',
    'reports.Event History Report with IP': '含 IP 的事件历史记录报告',
    'reports.Exception': '异常',
    'reports.exchange': '交易所',
    'reports.Exchange': '交易所',
    'reports.Executed': '已完成',
    'reports.Executed Orders Report': '已执行订单报告',
    'reports.executedat': '执行',
    'reports.Executing': '已完成',
    'reports.executionfee': '执行费用',
    'reports.executionprice': '执行价格',
    'reports.expiredate': '到期日期',
    'reports.expiresat': '到期',
    'reports.external_order_id': '外部订单 ID',
    'reports.externalid': '外部 ID',
    'reports.externalorderid': '外部订单 ID',
    'reports.extfield': '外部字段',
    'reports.EXTFIELDS_RULES': '扩展字段可见性',
    'reports.extprice': '外部价格',
    'reports.False': 'False',
    'reports.fee': '费用',
    'reports.Fee: [addliquidityrebate]': '费用：[addliquidityrebate]',
    'reports.Fee: [Brokerage]': '费用：[Brokerage]',
    'reports.Fee: [Exchange]': '费用：[Exchange]',
    'reports.Fee: [InvestorProtectionLevy]': '费用：[InvestorProtectionLevy]',
    'reports.Fee: [removeliquidityrebate]': '费用：[removeliquidityrebate]',
    'reports.Fee: [SecuritiesTransferTax]': '费用：[SecuritiesTransferTax]',
    'reports.Fee: [SettlementFee]': '费用：[SettlementFee]',
    'reports.Fee: [ValueAddedTax]': '费用：[ValueAddedTax]',
    'reports.Feed Data': '源数据',
    'reports.Fees Report': '费用报告',
    'reports.FFastFillFIXFeed': 'FFastFillFIXFeed',
    'reports.fiahr_action': '操作',
    'reports.fiahr_amount': '金额',
    'reports.fiahr_capital': '资本',
    'reports.fiahr_count': '股份数',
    'reports.fiahr_date': '日期',
    'reports.fiahr_fee': '报酬',
    'reports.fiahr_grosspl': '总损益',
    'reports.fiahr_investor_id': '投资者 ID',
    'reports.fiahr_investor_name': '投资者',
    'reports.fiahr_netpl': '净损益',
    'reports.fiahr_pamm_id': '基金 ID',
    'reports.fiahr_pamm_name': '基金',
    'reports.fiahr_price': '价格',
    'reports.fiahr_requestid': '请求 ID',
    'reports.Fidessa': 'Fidessa',
    'reports.FileIncludeMilliseconds': '文件包括毫秒',
    'reports.FileIncludeTimeStampForMessages': '文件包括消息的时间戳',
    'reports.FileLogHeartbeats': '文件日志检测信号',
    'reports.Filled': '已完成',
    'reports.Filled close Limit order': '已完成收盘限价单',
    'reports.Filled close Limit order (Mutual)': '已完成收盘限价单（相互）',
    'reports.Filled close Market order': '已完成收盘市价单',
    'reports.Filled close Market order (Mutual)': '已完成收盘市价单（相互）',
    'reports.Filled close OCO order': '已完成收盘二选一委托单',
    'reports.Filled close OCO order (Mutual)': '已完成收盘二选一委托单（相互）',
    'reports.Filled close SL order': '已完成收盘止损单',
    'reports.Filled close SL order (Mutual)': '已完成收盘止损单（相互）',
    'reports.Filled close SL tr. stop order': '已完成收盘止损跟踪止损单',
    'reports.Filled close SL tr. stop order (Mutual)': '已完成收盘止损跟踪止损单（相互）',
    'reports.Filled close Stop Limit order': '已完成收盘止损限价单',
    'reports.Filled close Stop Limit order (Mutual)': '已完成收盘止损限价单（相互）',
    'reports.Filled close Stop order': '已完成收盘止损单',
    'reports.Filled close Stop order (Mutual)': '已完成收盘止损单（相互）',
    'reports.Filled close TP order': '已完成收盘盈利单',
    'reports.Filled close TP order (Mutual)': '已完成收收盘盈利单（相互）',
    'reports.Filled close Tr. stop loss order': '已完成收盘跟踪止损单',
    'reports.Filled close Tr. stop order': '已完成收盘跟踪止损单',
    'reports.Filled close Tr. stop order (Mutual)': '已完成收盘跟踪止损单（相互）',
    'reports.Filled Limit order': '已完成限价单',
    'reports.Filled Limit order (Mutual)': '已完成限价单（相互）',
    'reports.Filled Market order': '已完成市价单',
    'reports.Filled Market order (Mutual)': '已完成市价单（相互）',
    'reports.Filled OCO order': '已完成二选一委托单',
    'reports.Filled OCO order (Mutual)': '已完成二选一委托单（相互）',
    'reports.Filled SL order': '已完成止损单',
    'reports.Filled SL order (Mutual)': '已完成止损单（相互）',
    'reports.Filled SL tr. stop order': '已完成止损跟踪止损单',
    'reports.Filled SL tr. stop order (Mutual)': '已完成止损跟踪止损单（相互）',
    'reports.Filled Stop Limit order': '已完成止损限价单',
    'reports.Filled Stop Limit order (Mutual)': '已完成止损限价单（相互）',
    'reports.Filled Stop order': '已完成止损单',
    'reports.Filled Stop order (Mutual)': '已完成止损单（相互）',
    'reports.Filled TP order': '已完成盈利单',
    'reports.Filled TP order (Mutual)': '已完成盈利单（相互）',
    'reports.Filled Tr. stop order': '已完成跟踪止损单',
    'reports.Filled Tr. stop order (Mutual)': '已完成跟踪止损单（相互）',
    'reports.filledamount': '已完成金额',
    'reports.fir_exit_capital': '当前资本',
    'reports.fir_exit_price': '当前股价',
    'reports.fir_investor_id': '投资者 ID',
    'reports.fir_investor_name': '投资者姓名',
    'reports.fir_join_capital': '启动资本',
    'reports.fir_join_date': '加入日期',
    'reports.fir_join_price': '加入价格',
    'reports.fir_pamm_id': '基金 ID',
    'reports.fir_pamm_name': '基金名称',
    'reports.fir_reinvest_cycles': '再投资周期',
    'reports.fir_share_count': '股数',
    'reports.FirstDerivatives': 'FirstDerivatives',
    'reports.firstname': '名字',
    'reports.FixedFee': '固定佣金',
    'reports.Foreks': 'Foreks',
    'reports.Forex balances report': '外汇余额报告',
    'reports.Formal Dialog Report': '正式对话报告',
    'reports.FORTEX': 'FORTEX',
    'reports.fpghr_count': '股份',
    'reports.fpghr_exit_amount': '退出金额',
    'reports.fpghr_exit_price': '退出价格',
    'reports.fpghr_fee': '费用',
    'reports.fpghr_first_join_date': '加入日期',
    'reports.fpghr_grosspl': '总损益',
    'reports.fpghr_investor_id': '投资者 ID',
    'reports.fpghr_investor_name': '投资者姓名',
    'reports.fpghr_join_amount': '加入金额',
    'reports.fpghr_join_price': '加入价格',
    'reports.fpghr_last_exit_date': '退出日期',
    'reports.fpghr_netpl': '净损益',
    'reports.fpghr_pamm_id': '基金 ID',
    'reports.fpghr_pamm_name': '基金名称',
    'reports.fpghr_period': '时间段',
    'reports.fpghr_period_end_date': '结束日期',
    'reports.fpghr_period_start_date': '开始日期',
    'reports.fprhr_date': '最后更新',
    'reports.fprhr_details': '详细信息',
    'reports.fprhr_entryid': '条目 ID',
    'reports.fprhr_event': '事件',
    'reports.fprhr_investor_id': '投资者 ID',
    'reports.fprhr_investor_name': '投资者',
    'reports.fprhr_pamm_id': '基金 ID',
    'reports.fprhr_pamm_name': '基金',
    'reports.fprhr_requestid': '请求 ID',
    'reports.from': '从',
    'reports.From.SERVER': '服务器',
    'reports.fromDate': '从',
    'reports.fromDateConnAttempt': '从',
    'reports.fromDatePwdChange': '从（过去）',
    'reports.FUNCTION_ACCOUNT_GROUP': '账户组',
    'reports.FUNCTION_ACCOUNT_OPERATIONS': '融资操作可见性',
    'reports.FUNCTION_ACCOUNTS': '账户',
    'reports.FUNCTION_ALERTS': '警报',
    'reports.FUNCTION_ARBITRAGER': '套利者',
    'reports.FUNCTION_AUTOBROKET_LIST': '自动经纪人列表',
    'reports.FUNCTION_BACK_TESTER': '回测者',
    'reports.FUNCTION_BARS_FROM': '从以下项目制作长条',
    'reports.FUNCTION_BASE_CURRENCY': '系统基本货币（您可能需要更改工具交叉）',
    'reports.FUNCTION_BASKET': '一篮子',
    'reports.FUNCTION_BETTING': '投注',
    'reports.FUNCTION_BINDEDORDERS': '允许二选一委托单',
    'reports.FUNCTION_BLOCKED_SUMM_FOR_ORDERS': '已冻结订单资金',
    'reports.FUNCTION_BRANDING': '构建品牌',
    'reports.FUNCTION_BRANDINGADMIN': '构建品牌管理员',
    'reports.FUNCTION_BUILDER': '构建者',
    'reports.FUNCTION_CACHEADMIN': '高速缓存管理员',
    'reports.FUNCTION_CHART': '图表',
    'reports.FUNCTION_CHAT': '聊天',
    'reports.FUNCTION_CLOSE_POSITIONS_DISABLE': '不允许平仓',
    'reports.FUNCTION_COMMUNITY': '社区',
    'reports.FUNCTION_CONFIGADMIN': '配置和构建品牌',
    'reports.FUNCTION_CONNECTIONSSADMIN': '连接管理员',
    'reports.FUNCTION_CROSS_PAIR': '交叉价格',
    'reports.FUNCTION_CUR_EXP': '货币曝险',
    'reports.FUNCTION_DISABLE_BUY': '禁用买入订单',
    'reports.FUNCTION_DISABLE_OPTION_TRADING': '禁用期权订单',
    'reports.FUNCTION_DISABLE_SELL': '禁用卖出订单',
    'reports.FUNCTION_DO_NOT_MARGIN': '不使用保证金',
    'reports.FUNCTION_EVENT_LOG': '事件日志',
    'reports.FUNCTION_FUNDING': '操作管理员',
    'reports.FUNCTION_GENERATE_OPTIONS': '从水平 1 生成期权',
    'reports.FUNCTION_HEDGE_RULE_TYPE': '对冲规则列表：',
    'reports.FUNCTION_INSTRUMENT_LOOKUP': '股票代码查找',
    'reports.FUNCTION_INSTRUMENT_PORTFOLIO': '投资组合',
    'reports.FUNCTION_INSTRUMENTSADMIN': '股票代码管理员',
    'reports.FUNCTION_INTERSERVER': '服务器间',
    'reports.FUNCTION_LEVEL2': '水平 2 数据和订单',
    'reports.FUNCTION_LEVEL3': '时间和销售',
    'reports.FUNCTION_LOCKED_BY_BROKER': '经纪人已锁定',
    'reports.FUNCTION_MANUAL': '允许手动订单',
    'reports.FUNCTION_MARGIN_MODE': '保证金模式',
    'reports.FUNCTION_MASTER': '主账户管理',
    'reports.FUNCTION_MATRIX': '矩阵',
    'reports.FUNCTION_MULTI_CONNECTION': '允许多个连接',
    'reports.FUNCTION_MUTUAL_CLOSE': '允许相互平仓',
    'reports.FUNCTION_NEWS': '新闻',
    'reports.FUNCTION_NEWSADMIN': '新闻管理员',
    'reports.FUNCTION_NOT_ALLOW_OPEN_POSITION': '不允许建仓',
    'reports.FUNCTION_NOT_ALLOW_SHORT_STOCK': '不允许做空股票',
    'reports.FUNCTION_ONE_COUNTERACCOUNT': '一个柜台账户',
    'reports.FUNCTION_ONE_POSITION': '每个股票代码一个头寸（对冲）',
    'reports.FUNCTION_ONE_QUOTECACHECOUNT': '用于全部的一个高速缓存大小',
    'reports.FUNCTION_OPTIONS': '期权',
    'reports.FUNCTION_ORDER_BOOK': '订单历史记录',
    'reports.FUNCTION_PARTIAL_CLOSE': '允许部分平仓',
    'reports.FUNCTION_POSITION_BALANCE': '头寸余额',
    'reports.FUNCTION_POSITIONSADMIN': '头寸管理员',
    'reports.FUNCTION_QUOTE_HISTORY': '报价历史记录',
    'reports.FUNCTION_QUOTES': '报价',
    'reports.FUNCTION_REPORT': '报告',
    'reports.FUNCTION_REPORTADMIN': '报告管理员',
    'reports.FUNCTION_RESERVER_WITHDRAWAL': '提款',
    'reports.FUNCTION_RFQ': '请求报价面板',
    'reports.FUNCTION_ROLESADMIN': '用户组管理员',
    'reports.FUNCTION_ROUTESADMIN': '路由管理员',
    'reports.FUNCTION_SCHEDULERADMIN': '计划程序管理员',
    'reports.FUNCTION_SCRIPT_BUILDER': '脚本构建者',
    'reports.FUNCTION_SERVICESADMIN': '服务管理员',
    'reports.FUNCTION_SETTINGSADMIN': '设置管理员',
    'reports.FUNCTION_SHOW_ORDERS': '订单',
    'reports.FUNCTION_SHOW_POSITIONS': '头寸',
    'reports.FUNCTION_SLAVE': '从属',
    'reports.FUNCTION_SLTP': '允许止损/盈利单',
    'reports.FUNCTION_SMS_SERVICE_DEST': 'SMS 服务目的地',
    'reports.FUNCTION_SN': '许可证编号',
    'reports.FUNCTION_STRATEGIESADMIN': '策略管理员',
    'reports.FUNCTION_SUPPORT_EMAIL': '支持团队的电子邮件',
    'reports.FUNCTION_TABLESADMIN': '表格管理员',
    'reports.FUNCTION_THREADEDSAVER': '已启用线程保护程序',
    'reports.FUNCTION_TRADES': '已完成订单',
    'reports.FUNCTION_TRADING': '交易',
    'reports.FUNCTION_TRADING_MODE': '交易模式',
    'reports.FUNCTION_TRAILING_STOP': '允许追踪止损',
    'reports.FUNCTION_USER_CONNECTION_POLICY': '用户多连接政策',
    'reports.FUNCTION_USERSADMIN': '用户管理员',
    'reports.FUNCTION_VALIDATE_SSL_CERTIFICATE': '需要验证 SSL 证书',
    'reports.FUNCTION_VENDOR_NAME': '供应商名称',
    'reports.FUNCTION_WATCHLIST': '观察列表',
    'reports.Fund Gain History Report': '资金收益历史记录报告',
    'reports.Fund Investments Report': '资金投资报告',
    'reports.Fund Investor Action History Report': '资金投资者操作历史记录报告',
    'reports.Fund Investor History Report': '资金投资者历史记录报告',
    'reports.Fund Request History Report': '资金请求历史记录报告',
    'reports.Fund Transfer': '资金转账',
    'reports.fund_type.closed': '封闭式',
    'reports.fund_type.open': '开放式',
    'reports.fundID': '基金 ID',
    'reports.FUNDING_RULES': '融资操作可见性',
    'reports.fxintegrate.cert.host.verify': '外汇集成证书主机验证',
    'reports.fxintegrate.cert.root.ca.file': '外汇集成证书根 ca 文件',
    'reports.fxintegrate.host': '外汇集成主机',
    'reports.fxintegrate.logging.file': '外汇集成日志记录文件',
    'reports.fxintegrate.logging.maxfilecount': '外汇集成日志记录最大文件数',
    'reports.fxintegrate.logging.maxfilesize': '外汇集成日志记录最大文件大小',
    'reports.fxintegrate.logging.output': '外汇集成日志记录输出',
    'reports.fxintegrate.logging.priority': '外汇集成日志记录优先级',
    'reports.fxintegrate.port': '外汇集成端口',
    'reports.fxintegrate.protocol': '外汇集成协议',
    'reports.fxintegrate.usessl': '外汇集成使用 ssl',
    'reports.GENERAL_COMMON_RULES': '一般通用规则',
    'reports.GENERAL_USER_RULES': '一般规则',
    'reports.GFT': 'GFT',
    'reports.gi.available_margin': '可用保证金',
    'reports.gi.avaliable_funds': '可用资金',
    'reports.gi.balance': '余额',
    'reports.gi.balance_blocked_by_orders': '按订单冻结',
    'reports.gi.cur_balance': '预期余额',
    'reports.gi.cur_margin': '当前保证金，%',
    'reports.gi.fund_capital': '资金资本',
    'reports.gi.fund_used': '已用资金',
    'reports.gi.maintain_margin': '维持保证金',
    'reports.gi.margin_orders': '订单的保证金',
    'reports.gi.margin_positions': '头寸的保证金',
    'reports.gi.margin_stopout': '止损离场',
    'reports.gi.margin_stoptrade': '止损交易',
    'reports.gi.margin_used': '已用保证金',
    'reports.gi.margin_warning': '保证金警告',
    'reports.gi.min_margin': '最低保证金',
    'reports.gi.pos_maintenance_req': '头寸维持要求',
    'reports.gi.risk_lvl': '风险级别',
    'reports.gi.stock_value': '股票',
    'reports.gi.today_released_pnl': '已实现损益',
    'reports.gi.withdrawal_available': '可用提款',
    'reports.Goldman Sachs': '高盛',
    'reports.grosspl': '总损益',
    'reports.groupid': '组 ID',
    'reports.groupname': '组',
    'reports.GTL': 'GTL',
    'reports.HeartBtInt': '检测间隔',
    'reports.hedged': 'STP（直通式处理）',
    'reports.helpIndex': 'http://protrader.org/en/knowledge-base/pt3/desktop/account/reports',
    'reports.host': '主机',
    'reports.HOT_BUTTON': '热键',
    'reports.HotSpot': '热点',
    'reports.hours': '小时',
    'reports.HTTP.SERVER.HOST': 'HTTP 服务器主机',
    'reports.HTTP.SERVER.PORT': 'HTTP 服务器端口',
    'reports.HTTP.SERVER.PROTOCOL': 'HTTP 服务器协议',
    'reports.HTTP.URL.HEARTBEAT': 'HTTP URL 检测信号',
    'reports.HTTP.URL.LOGIN': 'HTTP URL 登录',
    'reports.HTTP.URL.LOGOUT': 'HTTP URL 注销',
    'reports.HTTP.URL.PASSWORD.VALIDATION': 'HTTP URL 密码验证',
    'reports.HTTP.URL.PRELOGIN': 'HTTP URL 预登录',
    'reports.HTTP.URL.QUERY': 'HTTP URL 查询',
    'reports.HTTP.URL.WORKFLOW': 'HTTP URL 工作流程',
    'reports.IB Trades Report': '介绍经纪人易报告',
    'reports.IGIndex': 'IGIndex',
    'reports.IndexingQuoteFrequency(sec)': '索引报价频率（秒）',
    'reports.Information': '信息',
    'reports.Informational message': '参考消息',
    'reports.Init margin req.': '初始保证金要求',
    'reports.Init. margin req.': '初始保证金要求',
    'reports.INSTALLER_SERVICE_URL': '服务安装程序 URL',
    'reports.InstrRouteMap': '路由图',
    'reports.instrtype': '股票代码组',
    'reports.INSTRTYPES_RULES': '股票代码组可见性',
    'reports.instrument': '股票代码',
    'reports.Instrument': '股票代码',
    'reports.instrument group': '股票代码组',
    'reports.Instrument Specification': '股票代码规格',
    'reports.Instrument Statistic Report': '工具统计报告',
    'reports.Instrument Usage By Signal Report': '按信号的股票代码使用情况报告',
    'reports.Instrument Visibility Report': '股票代码可见性报告',
    'reports.INSTRUMENT_DERIVATIVES': '衍生物',
    'reports.INSTRUMENT_GENERAL': '常规',
    'reports.INSTRUMENT_MARGIN': '保证金',
    'reports.INSTRUMENT_TRADING': '交易',
    'reports.INSTRUMENT_TYPE_DERIVATIVES': '衍生物',
    'reports.instrument.group': '股票代码组',
    'reports.instrument.name': '股票代码名称',
    'reports.instrumentgroup': '股票代码组',
    'reports.InstrumentId': '股票代码',
    'reports.instrumentname': '股票代码',
    'reports.instruments': '工具',
    'reports.instrumenttype': '股票代码类型',
    'reports.instrumenttype.duplicatename': '具有此名称的股票代码组已存在',
    'reports.instrumenttype.inheritanceerror': '类型继承出错',
    'reports.instrumentType.name': '股票代码组名称',
    'reports.instrumentType.superId': '股票代码超级型名称',
    'reports.instrumentVisibilityReport': '股票代码可见性报告',
    'reports.Integral': 'Integral',
    'reports.IntegralFIX': 'IntegralFIX',
    'reports.InteractiveData': 'InteractiveData',
    'reports.IntererstOnAvailableFundsAndUnsettledCash': '可用资金 + 未结算资金利息',
    'reports.interest': '利息',
    'reports.InterestOnAvailableFunds': '可用资金利息',
    'reports.InterestOnCash': '资金利息',
    'reports.InterestOnMarginAvailable': '可用保证金利息',
    'reports.InterestOnMarginAvailableAndUnsettledCash': '可用保证金和未结算资金利息',
    'reports.Invalid installer hash.': '安装程序哈希值无效。',
    'reports.Invalid installer size.': '安装程序大小无效。',
    'reports.Invalid installer URL.': '安装程序 URL 无效。',
    'reports.Invalid password：新密码至少应该包含 1 个大写字符、1 个小写字符、1 个数字、1 个特殊字符（例如 @#$%）并且至少包含 8 个字符。请重试。': '新密码至少应该包含 1 个大写字符、1 个小写字符、1 个数字、1 个特殊字符（例如 @#$%）并且至少包含 8 个字符。请重试。',
    'reports.Invalid price for the order.': '订单的价格无效。',
    'reports.Investor Action History Report': '投资者操作历史记录报告',
    'reports.investorID': '投资者 ID',
    'reports.InvestorProtectionLevy': '投资者保护税',
    'reports.ip': 'IP',
    'reports.IP': 'IP',
    'reports.IQFeed': 'IQFeed',
    'reports.irdnumber': 'IR 码',
    'reports.is out of range': '超出范围',
    'reports.IsOnlyLastPeriod': '最后时间段',
    'reports.isPriceStream': '价格流',
    'reports.isShowLots': '显示口数中的数量',
    'reports.IsShowLots': '显示口数中的数量',
    'reports.IsShowPamm': '显示资金',
    'reports.issingle': '是单一',
    'reports.JdbcDriver': 'Jdbc 驱动程序',
    'reports.JdbcPassword': 'Jdbc 密码',
    'reports.JdbcSessionIdDefaultPropertyValue': 'JDBC 会话 ID 默认属性值',
    'reports.JdbcURL': 'JdbcURL',
    'reports.JdbcUser': 'Jdbc 用户',
    'reports.JMS.INIT.QUEUE': 'JMS 初始队列',
    'reports.JMS.SERVER.HOST': 'JMS 服务器主机',
    'reports.JMS.SERVER.PASSWORD': 'JMS 服务器密码',
    'reports.JMS.SERVER.PORT': 'JMS 服务器端口',
    'reports.JMS.SERVER.PROTOCOL': 'JMS 服务器协议',
    'reports.JMS.SERVER.USER': 'JMS 服务器用户',
    'reports.JPMorganTrade': 'JPMorganTrade',
    'reports.keystoreAlias': '密钥存储别名',
    'reports.keystoreFile': '密钥存储文件',
    'reports.KyteFIX': 'KyteFIX',
    'reports.L1port': 'L1port',
    'reports.L2port': 'L2port',
    'reports.LADDER_VIEW': '超级 DOM',
    'reports.LADDER_VIEW_OE': '超级 DOM（内置于 OE）',
    'reports.Last fill price': '最后完成价格',
    'reports.lastname': '姓氏',
    'reports.Level1Charging': 'Level1Charging',
    'reports.Level1DataCharging': '水平 1 数据费用',
    'reports.Level2Charging': 'Level2Charging',
    'reports.Level2DataCharging': '水平 2 数据费用',
    'reports.Level3Charging': 'Level3Charging',
    'reports.Level3DataCharging': '水平 3 数据费用',
    'reports.Limit accepted by broker': '经纪人已接受限价单',
    'reports.Limit accepted by STP': 'STP 已接受制订单',
    'reports.Limit order accepted': '已接受限价单',
    'reports.Limit order activated': '已激活限价单',
    'reports.Limit order cancel request': '限价单取消请求',
    'reports.Limit order canceled': '已取消限价单',
    'reports.Limit order created': '已创建限价单',
    'reports.Limit Order Created': '已创建限价单',
    'reports.Limit order created is off market': '限价单已离场',
    'reports.Limit order created is unplaced': '没有在交易所下限价单',
    'reports.Limit order is awaiting market': '限价单正在等待市场',
    'reports.Limit Order Modified': '已修改限价单',
    'reports.Limit order partially reject': '已部分取消限价单',
    'reports.Limit order placing request': '限价单下单请求',
    'reports.Limit order received by broker': '经纪人已接收到限价单',
    'reports.Limit order rejected': '已拒绝限价单',
    'reports.Limit Order Removed': '已删除限价单',
    'reports.Limit order replace request': '限价单重新下单请求',
    'reports.Limit order replaced': '已修改限价单',
    'reports.Limit order restated': '已重列限价单',
    'reports.Linked Users': '链接的用户',
    'reports.LMAX': 'LMAX',
    'reports.LMAX FIX': 'LMAX FIX',
    'reports.Local': '本地',
    'reports.locked': '已锁定',
    'reports.LogFileMaxSize': '日志文件最大大小',
    'reports.login': '账户',
    'reports.Login': '登录',
    'reports.LOGIN.USER.NAMESPACE': '登录用户名称空间',
    'reports.Login/password combination is not valid': '登录/密码组合无效',
    'reports.LogonTimeout': '登录超时',
    'reports.Logout': '注销',
    'reports.Lots to open': '要建仓的口数',
    'reports.lotsize': '口数大小',
    'reports.Mail Settings': '邮件设置',
    'reports.mail.mailsettings': '邮件设置',
    'reports.mailPattern.ChatDuplicating': '聊天复制',
    'reports.mailPattern.DemoUserNotify': '演示用户到期日期通知',
    'reports.mailPattern.DemoUserRegistration': '演示用户注册确认',
    'reports.mailPattern.DemoUserRemove': '演示用户删除确认',
    'reports.mailPattern.DepositWithdrawalNotification': '存款/提款电子邮件通知',
    'reports.mailPattern.MarginCall': '保证金催交通知',
    'reports.mailPattern.NewsCreated': '已创建新闻',
    'reports.mailPattern.OrderPlaced': '已下止损/限价单',
    'reports.mailPattern.OrderRemoved': '已删除止损/限价单',
    'reports.mailPattern.PositionClosed': '确认平仓',
    'reports.mailPattern.PositionOpened': '确认建仓',
    'reports.mailPattern.SignalDuplicating': '信号复制',
    'reports.mailPattern.TransactionStatementNotifyBroker': '经纪人每日报告',
    'reports.mailPattern.TransactionStatementNotifyUser': '用户每日报告',
    'reports.mailPattern.UserEmailConfirmation': '确认电子邮件',
    'reports.mailPattern.UserPasswordRecovery': '用户密码恢复',
    'reports.mailPattern.UserPasswordReset': '用户密码重置',
    'reports.mailPattern.UserRegistration': '用户注册确认',
    'reports.mailPattern.UserUnlocking': '在用暴力破解密码后用户解锁',
    'reports.Main': '主要',
    'reports.MAM trades report': 'MAM 交易报告',
    'reports.MAM_OE': 'MAM（内置于 OE）',
    'reports.MamManagementFee': 'MAM 管理费',
    'reports.MamPerfomanceFee': 'MamPerfomanceFee',
    'reports.MamPerformanceFee': 'MAM 表现费',
    'reports.Manual order cancel request': '手动订单取消请求',
    'reports.Manual order placing request': '手动订单下达请求',
    'reports.Manual order replace request': '手动订单重新下达请求',
    'reports.ManufacturedDividends': '已产生的红利',
    'reports.margin': '保证金',
    'reports.Margin Call': '保证金催交',
    'reports.Margin Call Reached': '已到达保证金催交',
    'reports.Margin Call Warning.': '保证金催交警告。',
    'reports.Margin used': '已用保证金',
    'reports.MARGIN_RULES_ACC': '保证金规则（账户）',
    'reports.MARGIN_RUSLES': '保证金规则',
    'reports.Margincall': '保证金催交',
    'reports.MarginCallMessage.header.MarginCallReached': '已到达保证金催交',
    'reports.MarginCallMessage.header.MarginWarning': '保证金警告',
    'reports.MarginCallMessage.Message': '请向您的账户存款',
    'reports.Market accepted by broker': '经纪人已接受市价单',
    'reports.Market accepted by STP': 'SIP 已接受市价单',
    'reports.Market Close Order Removed': '已删除收盘市价单',
    'reports.Market data charging report': '市场数据收费报告',
    'reports.Market order accepted': '已接受市价单',
    'reports.Market order activated': '已激活市价单',
    'reports.Market order cancel request': '市价单取消请求',
    'reports.Market order canceled': '已取消市价单',
    'reports.Market order created': '已创建市价单',
    'reports.Market Order Created': '已创建市价单',
    'reports.Market order created is off market': '市价单已离场',
    'reports.Market order created is unplaced': '没有在交易所下市价单',
    'reports.Market Order Modified': '已修改市价单',
    'reports.Market order partially reject': '已部分取消市价单',
    'reports.Market order placing request': '市价单下单请求',
    'reports.Market order received by broker': '经纪人已接收到市价单',
    'reports.Market order rejected': '已拒绝市价单',
    'reports.Market Order Removed': '已删除市价单',
    'reports.Market order replace request': '市价单重新下单请求',
    'reports.Market order replaced': '已修改市价单',
    'reports.Market order restated': '已重列市价单',
    'reports.Market price': '市价',
    'reports.MARKETDEPTH': '市场深度',
    'reports.MarketFactory Feed': 'MarketFactory 源',
    'reports.Markup': '加成',
    'reports.MATRIX': '矩阵',
    'reports.MATRIX_OE': '矩阵（内置于 OE）',
    'reports.MaxLogsBackups': '最大日志备份数',
    'reports.MBT': 'MBT',
    'reports.message': '消息',
    'reports.Message': '消息',
    'reports.messageid': '消息 ID',
    'reports.MFGlobal': 'MFGlobal',
    'reports.MillisecondsinTimeStamp': '时间戳中的毫秒',
    'reports.MillisecondsInTimeStamp': '时间戳中的毫秒',
    'reports.minchange': '最小变动',
    'reports.MinFeeAdjEquity': '最低费用调整权益',
    'reports.Minimum Open Margin': '最低建仓保证金',
    'reports.minutes': '分钟',
    'reports.MLFIX': 'MLFIX',
    'reports.MOB_CANCEL_ALL_ORDERS': '移动（取消所有订单）',
    'reports.MOB_CHART_OE': '移动图表（订单条目）',
    'reports.MOB_CLOSE_ALL_POSITIONS': '移动（全部平仓）',
    'reports.MOB_MARKETDEPTH': '移动市场深度',
    'reports.MOB_ORDERS_HOT_BTN': '移动工作订单（热按钮）',
    'reports.MOB_OTHER': '移动其他',
    'reports.MOB_POSITIONS_HOT_BTN': '移动头寸（热按钮）',
    'reports.MOB_TAB_CHART_VISUAL_HOT_BTN': '移动图表（内置于 OE/平板电脑中）',
    'reports.MOB_WL_OE': '移动观察列表（订单条目）',
    'reports.Modified': '已修改',
    'reports.Modifing': '已修改',
    'reports.Modifyied by': '修改者',
    'reports.Modifying': '已修改',
    'reports.months': '月',
    'reports.Morgan Stanley': '摩根士丹利',
    'reports.MorganStanleyCFD': 'MorganStanleyCFD',
    'reports.Mutual close request': '相互平仓请求',
    'reports.name': '名称',
    'reports.NAMESPACE': '名称空间',
    'reports.NEED_EMAIL_CONFIRM_ON_REGISTRATION': '通过电子邮件进行账户注册确认',
    'reports.NeoNet': 'NeoNet',
    'reports.Net Open Positions': '建仓净额',
    'reports.netpl': '净损益',
    'reports.New Back Office Changes Report': '新后台更改报告',
    'reports.New password must differ from several previous passwords.': '新密码不得与前几个密码相同',
    'reports.New Users Report': '新用户报告',
    'reports.news.feed.type': '新闻源类型',
    'reports.news.reciving': '新闻接收',
    'reports.news.save': '新闻保存',
    'reports.news.sendEmail': '在电子邮件中发送新闻',
    'reports.NewsCharging': '新闻收费',
    'reports.NewsFeed': '新闻源',
    'reports.newvalue': '新值',
    'reports.no such position, no such order.': '无此类头寸，无此类订单。',
    'reports.Nomura': 'Nomura',
    'reports.Nomura-Lehman': 'Nomura-Lehman',
    'reports.None': '无',
    'reports.Not enoght margin to create Order': '没有创建订单所需的足够保证金',
    'reports.Not enough margin to open order': '没有创建订单所需的足够保证金',
    'reports.Not enough Margin to open position': '没有建仓所需的足够保证金',
    'reports.Not enough offer': '卖方要价不足',
    'reports.Not Filled Orders Report': '未完成订单报告',
    'reports.Not Successful': '不成功',
    'reports.notShowMam': '不显示 MAM',
    'reports.NotSuccessful': '不成功',
    'reports.number': '编号',
    'reports.objectid': '对象 ID',
    'reports.ObjectId': '订单 ID',
    'reports.ObjectID': '对象 ID',
    'reports.OCO accepted by broker': '经纪人已接受二选一委托单',
    'reports.OCO accepted by STP': 'SIP 已接受二选一委托单',
    'reports.OCO order activated': '已激活二选一委托单',
    'reports.OCO order cancel request': '二选一委托单取消请求',
    'reports.OCO order canceled': '已取消二选一委托单',
    'reports.OCO order created': '已创建二选一委托单',
    'reports.OCO Order Created': '已创建二选一委托单',
    'reports.OCO order created is off market': '二选一委托单已离场',
    'reports.OCO order created is unplaced': '没有在交易所下二选一委托单',
    'reports.OCO order partially reject': '已部分取消二选一委托单',
    'reports.OCO order placing request': '二选一委托单下单请求',
    'reports.OCO order received by broker': '经纪人已接收到二选一委托单',
    'reports.OCO order rejected': '已拒绝二选一委托单',
    'reports.OCO order replace request': '二选一委托单重新下单请求',
    'reports.OCO order replaced': '已修改二选一委托单',
    'reports.OCO order restated': '已重列二选一委托单',
    'reports.OE': 'OE',
    'reports.Off market': '离场',
    'reports.ONE_MARGIN_FOR_USER_ACC': '一份用户账户的保证金',
    'reports.Open': '开盘',
    'reports.Open Buy': '开盘时买入',
    'reports.Open date': '开盘日期',
    'reports.Open lots': '开盘口数',
    'reports.Open order is forbidden by trade session': '交易时段禁止未完成订单',
    'reports.Open order type': '开盘订单类型',
    'reports.Open Positions By Signal Report': '按信号列出的建仓报告',
    'reports.Open Positions Report': '建仓报告',
    'reports.Open price': '开盘价格',
    'reports.Open Sell': '开盘时卖出',
    'reports.OPEN_SPOT_ON_OPTION_EXP': '期权到期时建立即现货仓',
    'reports.openclosebuysell': '操作类型',
    'reports.opengrosspnl': '建仓总损益',
    'reports.OpenOptionPremium': '建仓期权溢价',
    'reports.openorderid': '开盘订单 ID',
    'reports.openprice': '开盘价格',
    'reports.opentime': '建仓时间',
    'reports.operation': '操作',
    'reports.Operation': '操作',
    'reports.Operation Id': '操作 ID',
    'reports.Operation type': '操作类型',
    'reports.Operation Type': '操作类型',
    'reports.operation.not_enough_margin': '没有足够的保证金',
    'reports.operation.wrong_length': '数量太大',
    'reports.operation.wrong_length;': '数量太大',
    'reports.operation.wrong_sum': '总和有误',
    'reports.OperationID': '操作 ID',
    'reports.OperationInDisconnectedMode': '此操作在断开连接的模式下不可用！',
    'reports.operationtype': '操作类型',
    'reports.OPTION_MASTER': '主要期权',
    'reports.option_value': '期权价值',
    'reports.OrcFIX': 'OrcFIX',
    'reports.order': '订单',
    'reports.Order': '订单',
    'reports.Order Book History': '订单簿历史记录',
    'reports.Order Book Report': '订单簿报告',
    'reports.Order cancel is forbidden by trade session': '交易时段禁止取消订单',
    'reports.Order cancel request': '取消订单请求',
    'reports.Order canceled successfully': '订单已成功取消',
    'reports.Order cannot be created since order amount is forbidden.': '无法创建订单，因为禁止该订单金额。',
    'reports.Order cannot be created since route is not active.': '无法创建订单，因为路由不活动',
    'reports.Order cannot be created, because position is in executing': '无法创建订单，因为正在执行平仓',
    'reports.Order cannot be created, because route is not active': '无法创建订单，因为路由不活动',
    "reports.Order cannot be created, because short positions are forbidden. You don't have enough rights.": '无法创建订单，因为禁止做空。您的权限不足。',
    'reports.Order cannot be created, because short positions are forbidden. You don?t have enough rights.': '无法创建订单，因为禁止做空。您的权限不足。',
    'reports.Order cannot be created, because short positions are forbidden.Or yor already have Limit/Stop orders for cover all your positions.': '无法创建订单，因为禁止做空。或者您已经具有涵盖所有头寸的限价/止损单。',
    'reports.Order cannot be placed in non-trading day. Please, check the trading calendar.': '无法在非交易日下单。请检查交易日历。',
    'reports.Order groupID': '订单组 ID',
    'reports.Order History Report': '订单历史记录报告',
    'reports.Order modification is forbidden by trade session': '交易时段禁止修改订单',
    'reports.Order number': '订单 ID',
    'reports.Order placing request': '订单下单请求',
    'reports.Order price': '订单价格',
    'reports.Order Price': '订单价格',
    'reports.Order received by STP': 'SIP 已接收到订单',
    'reports.Order replace request': '订单重新下单请求',
    'reports.Order replacing confirmed': '已确认订单重新下单',
    'reports.Order TIF is forbidden by trade session': '交易时段禁止订单委托时间有效期',
    'reports.Order type': '订单类型',
    'reports.Order type is forbidden by trade session': '交易时段禁止订单类型',
    'reports.Order was rejected due to invalid price!': '由于价格无效，已拒绝订单！',
    'reports.order_book_account': '账户',
    'reports.order_book_datetime': '日期',
    'reports.order_book_event': '事件',
    'reports.order_book_login': '登录',
    'reports.order_book_modified_by': '用户已修改',
    'reports.order_book_orderid': '订单 ID',
    'reports.order_book_price': '价格',
    'reports.order_book_quantity': '数量',
    'reports.order_book_route': '路由',
    'reports.order_book_side': '侧面',
    'reports.order_book_stop_price': '止损价格',
    'reports.order_book_symbol': '股票代码',
    'reports.order_book_symbol_type': '股票代码类型',
    'reports.order_book_tif': '委托时间有效期',
    'reports.order_book_type': '类型',
    'reports.orderCreate': '订单创建',
    'reports.orderExecute': '订单执行',
    'reports.OrderExternalConfirm': '订单外部确认',
    'reports.orderid': '订单 ID',
    'reports.orderId': '订单 ID',
    'reports.OrderId': '订单 ID',
    'reports.orderModify': '订单修改',
    'reports.orderMutural': '订单相互',
    'reports.orderRemoved': '已删除订单',
    'reports.ORDERS_PANEL_HOT_BTN': '工作订单（热按钮）',
    'reports.ordertype': '订单类型',
    'reports.Oreder Number': '订单 ID',
    'reports.originalvalue': '原始价值',
    'reports.OTHER': '其他',
    'reports.other_account_operations': '其他账户操作',
    'reports.Out of session trading is forbidden': '禁止在时段外交易',
    'reports.Overnight trading is forbidden for account': '禁止账户进行隔夜交易',
    'reports.PAMM account can not be removed during investment period.': '无法在投资时间段内撤除资金',
    'reports.PAMM join': '资金加入',
    'reports.pamm.OrderByPnL.max_loss': '最大亏损',
    'reports.pamm.OrderByPnL.max_profit': '最大盈利',
    'reports.pamm.OrderByPnL.min_loss': '最小亏损',
    'reports.pamm.OrderByPnL.min_profit': '最小盈利',
    'reports.pamm.OrderByPnL.none': '无',
    'reports.pamm.OrderBySize.max': '最大头寸',
    'reports.pamm.OrderBySize.min': '最小头寸',
    'reports.pamm.OrderByTime.first': '第一次建仓',
    'reports.pamm.OrderByTime.last': '最后一次建仓',
    'reports.pammState.0': '新',
    'reports.pammState.1': '投资',
    'reports.pammState.2': '结束投资',
    'reports.pammState.3': '正在清除',
    'reports.pammState.4': '等待投资者',
    'reports.pammState.6': '开始投资',
    'reports.pammState.clearing': '正在清除',
    'reports.pammState.confirmed': '已确认',
    'reports.pammState.end_of_investment': '结束投资',
    'reports.pammState.investment': '投资',
    'reports.pammState.Investment': '投资',
    'reports.pammState.new': '新',
    'reports.pammState.pending': '待定',
    'reports.pammState.start_of_investment': '开始投资',
    'reports.pammState.wait_for_investors': '等待投资者',
    'reports.PAMMTransfer': '资金转账',
    'reports.Partially filled': '已部分完成',
    'reports.Partially filled close Limit order': '已部分完成收盘限价单',
    'reports.Partially filled Close Limit Order': '已部分完成收盘限价单',
    'reports.Partially filled close Limit order (Mutual)': '已部分完成收盘限价单（相互）',
    'reports.Partially filled close Market order': '已部分完成收盘市价单',
    'reports.Partially filled Close Market Order': '已部分完成收盘市价单',
    'reports.Partially filled close Market order (Mutual)': '已部分完成收盘市价单（相互）',
    'reports.Partially filled close OCO order': '已部分完成收盘二选一委托单',
    'reports.Partially filled close OCO order (Mutual)': '已部分完成收盘二选一委托单（相互）',
    'reports.Partially filled close SL order': '已部分完成收盘止损单',
    'reports.Partially filled close SL order (Mutual)': '已部分完成收盘止损单（相互）',
    'reports.Partially filled close SL tr. stop order': '已部分完成收盘止损跟踪止损单',
    'reports.Partially filled close SL tr. stop order (Mutual)': '已部分完成收盘止损跟踪止损单（相互）',
    'reports.Partially filled close Stop Limit order': '已部分完成收盘止损限价单',
    'reports.Partially filled close Stop Limit order (Mutual)': '已部分完成收盘止损限价单（相互）',
    'reports.Partially filled close Stop order': '已部分完成收盘止损单',
    'reports.Partially filled Close Stop Order': '已部分完成收盘止损单',
    'reports.Partially filled close Stop order (Mutual)': '已部分完成收盘止损单（相互）',
    'reports.Partially filled close TP order': '已部分完成收盘盈利单',
    'reports.Partially filled close TP order (Mutual)': '已部分完成收盘盈利单（相互）',
    'reports.Partially filled close Tr. stop order': '已部分完成收盘跟踪止损单',
    'reports.Partially filled close Tr. stop order (Mutual)': '已部分完成收盘跟踪止损单（相互）',
    'reports.Partially filled Limit order': '已部分完成限价单',
    'reports.Partially filled Limit order (Mutual)': '已部分完成限价单（相互）',
    'reports.Partially filled Market order': '已部分完成市价单',
    'reports.Partially filled Market Order': '已部分完成市价单',
    'reports.Partially filled Market order (Mutual)': '已部分完成市价单（相互）',
    'reports.Partially filled OCO order': '已部分完成二选一委托单',
    'reports.Partially filled OCO order (Mutual)': '已部分完成二选一委托单（相互）',
    'reports.Partially filled SL order': '已部分完成止损单',
    'reports.Partially filled SL order (Mutual)': '已部分完成止损单（相互）',
    'reports.Partially filled SL tr. stop order': '已部分完成止损跟踪止损单',
    'reports.Partially filled SL tr. stop order (Mutual)': '已部分完成止损跟踪止损单（相互）',
    'reports.Partially filled Stop Limit order': '已部分完成止损限价单',
    'reports.Partially filled Stop Limit order (Mutual)': '已部分完成止损限价单（相互）',
    'reports.Partially filled Stop order': '已部分完成止损单',
    'reports.Partially filled Stop order (Mutual)': '已部分完成止损单（相互）',
    'reports.Partially filled TP order': '已部分完成盈利单',
    'reports.Partially filled TP order (Mutual)': '已部分完成盈利单（相互）',
    'reports.Partially filled Tr. stop order': '已部分完成跟踪止损单',
    'reports.Partially filled Tr. stop order (Mutual)': '已部分完成跟踪止损单（相互）',
    'reports.Partially filled Traling Stop Order': '已部分完成跟踪止损单',
    'reports.Password required with level - 1': '密码无效：新密码至少应该包含 1 个大写字符、1 个小写字符和 1 个数字。请重试',
    'reports.Password required with level - 2': '密码无效：新密码至少应该包含 1 个大写字符、1 个小写字符、1 个数字并且长度至少为 8 个字符。请重试',
    'reports.Password required with level - 3': '密码无效：新密码至少应该包含 1 个大写字符、1 个小写字符、1 个数字并且长度至少为 8 个字符并且至少包含 1 个特殊字符。请重试',
    'reports.PatsystemsFIXFeed': 'PatsystemsFIXFeed',
    'reports.Pending': '待定',
    'reports.pendingorderscount': '待定订单计数',
    'reports.PeresysFIX': 'PeresysFIX',
    'reports.Perfomance By Signal Report': '按信号列出的表现报告',
    'reports.Perform': '表现',
    'reports.Performance history report': '表现历史记录报告',
    'reports.PFixFeed': 'PFixFeed',
    'reports.phone': '电话',
    'reports.phonepassword': '电话密码',
    'reports.pin': 'Pin',
    'reports.pl_unrealized': '未实现损益',
    'reports.Placed': '已下单',
    'reports.Please deposit on your account.': '请向您的账户存款',
    'reports.pnl': '损益',
    'reports.port': '端口',
    'reports.posId': '头寸 ID',
    'reports.Position': '头寸',
    'reports.Position close request': '平仓请求',
    'reports.Position Closed': '已平仓',
    'reports.Position Closed (Mutual)': '已平仓（相互）',
    'reports.Position Closed (SL)': '已平仓（止损）',
    'reports.Position Closed (TP)': '已平仓（盈利）',
    'reports.Position closed successfully': '已成功平仓',
    'reports.POSITION MODIFIED': '已修改头寸',
    'reports.Position modify request': '头寸修改请求',
    'reports.Position number': '头寸 ID',
    'reports.positionOpened': '已建仓',
    'reports.Position Opened (Limit Order)': '已建仓（限价单）',
    'reports.Position Types Ratio By Instrument': '按股票代码列出的头寸类型比率',
    'reports.Position: {1} 已成功平仓': '头寸：{1} 已成功平仓',
    'reports.positionamount': '头寸金额',
    'reports.positionid': '头寸 ID',
    'reports.positionId': '头寸 ID',
    'reports.PositionId': '头寸 ID',
    'reports.Positions History Report': '头寸历史记录报告',
    'reports.Positions On Chosen Moment Report': '所选时刻的头寸报告',
    'reports.POSITIONS_PANEL_HOT_BTN': '头寸面板（热按钮）',
    'reports.positionstatus': '头寸状态',
    'reports.price': '价格',
    'reports.Price': '价格',
    'reports.Price is too close to current Ask price': '价格太接近当前要价',
    'reports.Price is too close to current Bid price': '价格太接近当前出价',
    'reports.priceExchange': '价格交换',
    'reports.priceExternal': '外部价格',
    'reports.product type': '产品类型',
    'reports.productType': '产品类型',
    'reports.PROFILE_LANGUAGE': '配置文件语言',
    'reports.profit': '盈利',
    'reports.Projected balance': '预期余额',
    'reports.protocol': '协议',
    'reports.ProTrader': 'Protrader',
    'reports.ProTraderFIX': 'ProTraderFIX',
    'reports.Purchase And Sales Report': '买卖报告',
    'reports.purchaseprice': '曝险',
    'reports.quantity': '数量',
    'reports.QueueMaxSize': '队列最大大小',
    'reports.quicktable.noData': '没有数据可供显示。请选择所需的报告标准以获得所需的信息。',
    'reports.Quote is too old': '报价太旧',
    'reports.QuoteDelay(sec)': '报价延迟（秒）',
    'reports.quoteticker': '报价看板',
    'reports.raor_administrator': '经纪人',
    'reports.raor_approvetime': '批准时间',
    'reports.raor_balance': '从账户余额',
    'reports.raor_basis': '注释',
    'reports.raor_commission_percent': '佣金（百分比）',
    'reports.raor_counterbalance': '到账户余额',
    'reports.raor_countersum': '到账户合计',
    'reports.raor_crossprice': '交叉价格',
    'reports.raor_fromaccount': '从账户',
    'reports.raor_operationtime': '操作时间',
    'reports.raor_operationtype': '操作类型',
    'reports.raor_requestid': '操作 ID',
    'reports.raor_state': '批准状态',
    'reports.raor_sum': '从账户合计',
    'reports.raor_sum_without_commission': '不含佣金合计',
    'reports.raor_toaccount': '到账户',
    'reports.rate': '费率',
    'reports.Rates Report': '费率报告',
    'reports.Realised profit/loss': '已实现损益',
    'reports.Reason for rejection: 风险规则禁用的交易。.': '拒绝原因：风险规则禁用的交易。。',
    'reports.receiveBestBidOfferOnly': '仅接收最佳出价要约',
    'reports.recipient': '收件人',
    'reports.ReconnectInterval': '重新连接间隔',
    'reports.refreshInterval': '刷新间隔',
    'reports.RefreshonLogon': '登录时刷新',
    'reports.RefreshOnLogon': '登录时刷新',
    'reports.Refused': '已拒绝',
    'reports.Refused Amount': '已取消数量',
    'reports.Rejected': '已拒绝',
    'reports.remoteid': '远程 ID',
    'reports.Remove SL for': '删除以下项的止损',
    'reports.Removed Orders Report': '已删除订单报告',
    'reports.removedtime': '删除时间',
    'reports.removeliquidityrebate': '删除流动性返利',
    'reports.Replace request received by STP': 'SIP 已接收到重新下单请求',
    'reports.REPORT_RULES': '报告可见性',
    'reports.report.currency': '货币',
    'reports.report.route': '路由',
    'reports.reportAccountSummaryReport': '账户报表报告',
    'reports.reportAllActiveOrdersReport': '活动订单报告',
    'reports.reportAllNotActiveOrdersReport': '已完成订单报告',
    'reports.reportAllRemovedOrdersReport': '已删除订单报告',
    'reports.reportBalanceByInstrument': '按股票代码列出的余额报告',
    'reports.reportBalanceReport': '余额报告',
    'reports.reportFormalDialogReport': '正式对话报告',
    'reports.reportInformalDialogReport': '聊天历史记录报告',
    'reports.reportNewUsersReport': '新用户报告',
    'reports.reportOpenedPositionReport': '已建仓报告',
    'reports.reportPerfomanceReport': '表现报告',
    'reports.reportPurchaseAndSalesReport': '买卖报告',
    'reports.reportSummaryNewReport': '汇总报告',
    'reports.reportSummaryReport': '余额摘要报告',
    'reports.reportTradeOperationReport': '交易操作报告',
    'reports.reportTradesReport': '交易报告',
    'reports.reportUsersReport': '用户报告',
    'reports.Request Account Operation Report': '请求账户操作报告',
    'reports.Request is not allowed for this account': '不允许此账户的请求',
    'reports.Request not supported': '请求不受支持',
    'reports.RequestAccountOperationReport': '请求账户操作报告',
    'reports.Required Balance': '所需余额',
    'reports.REQUIRED_STRONG_PASSWORDS': '需要严密密码',
    'reports.ResetOnDisconnect': '断开连接时重置',
    'reports.ResetOnLogon': '登录时重置',
    'reports.ResetOnLogout': '注销时重置',
    'reports.Restated': '已重列',
    'reports.Reuters': '路透社',
    'reports.Revenue plan': '收入计划',
    'reports.REVENUE_PLAN_GENERAL': '常规',
    'reports.revenue.name': '收入计划名称',
    'reports.revenue.speccrossinstrument': '特殊十字线符号',
    'reports.revenue.speccurrency': '特殊货币',
    'reports.Reverse': '冲销',
    'reports.reward_type.capital_percent': '资本，%',
    'reports.reward_type.fixed': '固定',
    'reports.reward_type.fixed_and_percent': '固定 + 投资期间增长，%',
    'reports.reward_type.no': '否',
    'reports.reward_type.percent': '投资期间增长，%',
    'reports.role': '角色',
    'reports.Rollback': '复原',
    'reports.Rollback position': '复原头寸',
    'reports.Rollback position request': '复原头寸请求',
    'reports.route': '路由',
    'reports.Route': '路由',
    'reports.Route Trading Statistics Report': '路由交易统计报告',
    'reports.Route Visibility': '路由可见性报告',
    'reports.Route Visibility Report': '路由可见性报告',
    'reports.RouteInstrMap': '股票代码图',
    'reports.routename': '路由',
    'reports.routes': '路由',
    'reports.routes.account': '链接的源账户',
    'reports.routes.allow_day': '天',
    'reports.routes.allow_foc': 'FOK',
    'reports.routes.allow_gtc': 'GTC',
    'reports.routes.allow_ioc': 'IOC',
    'reports.routes.allow_market': '市场',
    'reports.routes.allow_moc': '允许 MOC',
    'reports.routes.allow_moo': '允许 MOO',
    'reports.routes.allow_stop': '止损',
    'reports.routes.allow_stoplimit': '限额',
    'reports.routes.allow_stoptolimit': '止损限额',
    'reports.routes.route_comment': '备注',
    'reports.routes.route_external_feed': '流动性提供者',
    'reports.routes.route_isactive': '活动',
    'reports.routes.route_isinfo': '信息',
    'reports.routes.route_istrade': '交易',
    'reports.routes.route_login': '登录',
    'reports.routes.route_name': '名称',
    'reports.routes.route_password': '密码',
    'reports.routes.route_saveQuotes': '保存独立的历史记录',
    'reports.routes.route_status': '状态',
    'reports.ROUTESVIS_RULES': '路由可见性',
    'reports.RssNews': 'RSS 新闻',
    'reports.RTS': 'RTS',
    'reports.rules.error.restriction.max': '数字必须小于',
    'reports.rules.error.restriction.min': '数字必须大于',
    'reports.SAVED_ORDERS': '保存的订单',
    'reports.saveLogs': '保存日志',
    'reports.saveNews': '保存新闻',
    'reports.Saxo Bank': '盛宝银行',
    'reports.SCALPER': '帽客',
    'reports.Scheduler Log Report': '计划程序日志报告',
    'reports.scheduler.accountoperation_archive': '归档账户操作',
    'reports.scheduler.dailyreport': '发送报告',
    'reports.scheduler.dividentsservice': '针对头寸运行红利',
    'reports.scheduler.lastexcecuted': '执行时间',
    'reports.scheduler.modifyRuleTask': '修改任务',
    'reports.scheduler.movepositiontask': '移动头寸任务',
    'reports.scheduler.openclosetradingfor': '打开/关闭交易',
    'reports.scheduler.opentradingfor': '打开交易',
    'reports.scheduler.parameter.backup': '备份级别',
    'reports.scheduler.parameter.calculateByClosePrice': '使用当前收盘价格',
    'reports.scheduler.parameter.calculateStrategy': '计算类型',
    'reports.scheduler.parameter.coeff1': '第 1 部分',
    'reports.scheduler.parameter.coeff2': '第 2 部分',
    'reports.scheduler.parameter.comment': '账户操作备注',
    'reports.scheduler.parameter.counteraccount': '柜台账户',
    'reports.scheduler.parameter.days_before_exp': '到期之前天数',
    'reports.scheduler.parameter.divident': '红利数量',
    'reports.scheduler.parameter.dividentmode': '红利类型',
    'reports.scheduler.parameter.email': '电子邮件（用逗号分隔）',
    'reports.scheduler.parameter.inheritUserGroups': '包括子组',
    'reports.scheduler.parameter.instrtype': '股票代码组',
    'reports.scheduler.parameter.instrument': '股票代码',
    'reports.scheduler.parameter.isopen': '打开/关闭交易',
    'reports.scheduler.parameter.isupdateall': '更新组中的所有工具',
    'reports.scheduler.parameter.margincoeff': '保证金系数（基于时间）',
    'reports.scheduler.parameter.max': '最大值 (%)',
    'reports.scheduler.parameter.message': '消息',
    'reports.scheduler.parameter.min': '最小值 (%)',
    'reports.scheduler.parameter.operations': '操作类型',
    'reports.scheduler.parameter.owner': '对象',
    'reports.scheduler.parameter.ownerType': '对象类型',
    'reports.scheduler.parameter.putinaleerts': '放入警告',
    'reports.scheduler.parameter.reconciliation.ClientId': '用于核对的客户端 ID',
    'reports.scheduler.parameter.reconciliation.Direction': '指示',
    'reports.scheduler.parameter.reconciliation.FromDate': '开始核对日期',
    'reports.scheduler.parameter.reconciliation.Route': '路由',
    'reports.scheduler.parameter.reconciliation.SendItemName': '发送文件名',
    'reports.scheduler.parameter.reconciliation.Storage': '存储类型',
    'reports.scheduler.parameter.reconciliation.Transport': '传输类型',
    'reports.scheduler.parameter.reconciliation.transportDestination': '目的地',
    'reports.scheduler.parameter.reconciliation.transportLogin': '身份验证登录',
    'reports.scheduler.parameter.reconciliation.transportPassword': '身份验证密码',
    'reports.scheduler.parameter.reconciliation.Type': '核对类型',
    'reports.scheduler.parameter.reports': '选择报告',
    'reports.scheduler.parameter.revenueplan': '选择收入计划',
    'reports.scheduler.parameter.routes': '每个活动的路由必须',
    'reports.scheduler.parameter.routesName': '包括路由',
    'reports.scheduler.parameter.rule': '规则',
    'reports.scheduler.parameter.ruleType': '规则类型',
    'reports.scheduler.parameter.ruleView': '值',
    'reports.scheduler.parameter.sum': '数量（以系统货币计）',
    'reports.scheduler.parameter.swapCoefKey': '掉期系数',
    'reports.scheduler.parameter.userGroup': '用户组',
    'reports.scheduler.parameter.usergroups': '选择用户组',
    'reports.scheduler.removedo': '运行日期订单删除服务',
    'reports.scheduler.rolloverservice': '运行期货转仓',
    'reports.scheduler.runinterestservice': '运行余额利息服务',
    'reports.scheduler.runsignalsubscribtion': '运行信号的每日收费佣金',
    'reports.scheduler.runswaptask': '运行掉期',
    'reports.scheduler.splitinstrument': '拆分股票代码',
    'reports.scheduler.type.accountoperation': '账户操作',
    'reports.scheduler.type.backup': '创建备份',
    'reports.scheduler.type.beginbalance': '期初余额',
    'reports.scheduler.type.broadcastmessage': '在线广播消息',
    'reports.scheduler.type.changemargincoeff': '保证金系数（基于时间）',
    'reports.scheduler.type.changerevenue': '更改收入计划',
    'reports.scheduler.type.closeoptions': '隐藏已过期的股票代码',
    'reports.scheduler.type.deletedemousers': '删除已过期的演示用户',
    'reports.scheduler.type.deletequotes': '删除旧报价',
    'reports.scheduler.type.fixswapplan': '计算定息掉期计划',
    'reports.scheduler.type.futuresrollover': '运行期货转仓',
    'reports.scheduler.type.insertlastquotestask': '插入最后的报价',
    'reports.scheduler.type.lockbybroker': '经纪人锁定',
    'reports.scheduler.type.message': '消息',
    'reports.scheduler.type.modifyobject': '修改对象',
    'reports.scheduler.type.pamm': '资金任务',
    'reports.scheduler.type.pendingpayment': '待定付款',
    'reports.scheduler.type.reconciliation': '核对任务',
    'reports.scheduler.type.reconnect_routes': '活动的路由开始/停止',
    'reports.scheduler.type.subscribe': '获取订阅付款',
    'reports.scheduler.type.unknown': '未知类型',
    'reports.scheduler.type.updatespikefilter': '更新价格大幅波动过滤器',
    'reports.scheduler.type.updateuserstradinginfo': '更新用户交易统计信息',
    'reports.ScreenLogEvents': '屏幕日志事件',
    'reports.ScreenLogShowHeartBeats': '屏幕日志显示检测信号',
    'reports.ScreenLogShowIncoming': '屏幕日志显示传入',
    'reports.ScreenLogShowOutgoing': '屏幕日志显示传出',
    'reports.Script': '脚本',
    'reports.SDK': 'SDK',
    'reports.seconds': '秒',
    'reports.SecuritiesTransferTax': '证券过户税',
    'reports.Self trading is not allowed.': '不允许自助交易。',
    'reports.Sell': '卖出',
    'reports.sell trade amount': '卖出交易金额',
    'reports.sell trades': '卖出交易',
    'reports.Send from': '发送自',
    'reports.Send_Rej': '拒绝',
    'reports.sender': '发件人',
    'reports.SenderCompID': '发件人计算机 ID',
    'reports.senderip': '发件人 IP',
    'reports.SendResetSeqNumFlag': '发送重置序号标记',
    'reports.sendTag200': '发送 Tag200',
    'reports.server': '服务器',
    'reports.server_url': '服务器 URL',
    'reports.SERVER.AUTO.RECONNECT': '服务器自动重新连接',
    'reports.SERVER.POLLING.INTERVAL': '服务器轮训间隔',
    'reports.serviceName': '服务名称',
    'reports.sessionid': '会话 ID',
    'reports.sessiontype': '会话类型',
    'reports.SETTINGS_FILTER': '过滤器',
    'reports.SETTINGS_GENERAL': '常规',
    'reports.SETTINGS_LOCKING': '正在锁定',
    'reports.SETTINGS_QUOTE_CACHE_SIZE': '报价高速缓存大小设置',
    'reports.SETTINGS_QUOTE_SAVE': '报价保存设置',
    'reports.SETTINGS_QUOTES': '报价',
    'reports.SETTINGS_REGISTRATION': '注册',
    'reports.SETTINGS_TRADING': '交易',
    'reports.SettleDate': '到期日期',
    'reports.SettleDateForward': '价值日期',
    'reports.SettlementFee': '结算费用',
    'reports.Sharia Operations Report': 'Sharia 操作报告',
    'reports.signedrequest': '已签署请求！',
    'reports.sizeForQuoteSubscription': '报价规模订阅',
    'reports.SL': '止损',
    'reports.SL accepted by broker': '经纪人已接受止损单',
    'reports.SL accepted by STP': 'SIP 已接受止损单',
    'reports.SL order accepted': '已接受止损单',
    'reports.SL order cancel request': '止损单取消请求',
    'reports.SL order canceled': '已取消止损单',
    'reports.SL order created': '已创建止损单',
    'reports.SL Order Created': '已创建止损单',
    'reports.SL order created is off market': '止损单已离场',
    'reports.SL order created is unplaced': '没有在交易所下止损单',
    'reports.SL Order Modified': '已修改止损单',
    'reports.SL order partially reject': '已部分取消止损单',
    'reports.SL order placing request': '止损单下单请求',
    'reports.SL order received by broker': '经纪人已接收到止损单',
    'reports.SL order rejected': '已拒绝止损单',
    'reports.SL Order Removed': '已删除止损单',
    'reports.SL order replace request': '止损单重新下单请求',
    'reports.SL order replaced': '已修改止损单',
    'reports.SL price': '止损价格',
    'reports.SL tr. stop': '止损跟踪止损',
    'reports.SL tr. stop accepted by broker': '经纪人已接受止损跟踪止损单',
    'reports.SL tr. stop accepted by STP': 'STP 已接受止损跟踪止损单',
    'reports.SL tr. stop order accepted': '已接受止损跟踪止损单',
    'reports.SL tr. stop order canceled': '已取消止损跟踪止损单',
    'reports.SL tr. stop order created': '已创建止损跟踪止损单',
    'reports.SL tr. stop order created is off market': '止损跟踪止损单已离场',
    'reports.SL tr. stop order created is unplaced': '没有在交易所下止损跟踪止损单',
    'reports.SL tr. stop order partially reject': '已部分取消止损跟踪止损单',
    'reports.SL tr. stop order received by broker': '经纪人已接收到止损跟踪止损单',
    'reports.SL tr. stop order rejected': '已拒绝止损跟踪止损单',
    'reports.SL tr. stop order replaced': '已修改止损跟踪止损单',
    'reports.SmartRoute(aggregator)': '拆分模拟器',
    'reports.Sms generation error': 'Sms 生成错误',
    'reports.SMTP server required a secure connection, or identity of the client has not been established.': 'SMTP 服务器需要安全连接，或未建立客户端的身份。',
    'reports.SOAP API methods are not available for execution for this user': 'SOAP 相对强弱指标法不适用于为此用户执行操作',
    'reports.SocketConnectHost': '套接字连接主机',
    'reports.SocketConnectPort': '套接字连接端口',
    'reports.SocketKeyStore': '套接字密钥存储',
    'reports.SocketKeyStorePassword': '套接字密钥存储密码',
    'reports.SocketUseSSL': '套接字使用 SSL',
    'reports.SplitOrders': '拆分订单',
    'reports.spread': '价差',
    'reports.Spread Amount Report': '差价金额报告',
    'reports.spreadpnl': '差价损益',
    'reports.Start receiving data from server...': '开始接收来自服务器的数据...',
    'reports.StartDay': '开始日期',
    'reports.StartTime': '开始时间',
    'reports.STATIONS_RULES': '工作站',
    'reports.Statistic Report': '统计报告',
    'reports.STATUS_BLOCKED': '已冻结',
    'reports.stock_req': '股票订单要求',
    'reports.stock_value': '股票价值',
    'reports.StockOperation': '股票操作',
    'reports.stocks_liquidity': '股票流动性',
    'reports.Stop accepted by broker': '经纪人已接受止损单',
    'reports.Stop accepted by STP': 'STP 已接受止损单',
    'reports.Stop Limit accepted by broker': '经纪人已接受止损限价单',
    'reports.Stop Limit accepted by STP': 'SIP 已接受止损限价单',
    'reports.Stop Limit order accepted': '已接受止损限价单',
    'reports.Stop Limit order activated': '已激活止损限价单',
    'reports.Stop limit order cancel request': '止损限价单取消请求',
    'reports.Stop limit order canceled': '已取消止损限价单',
    'reports.Stop Limit order canceled': '已取消止损限价单',
    'reports.Stop Limit order created': '已创建止损限价单',
    'reports.Stop Limit Order Created': '已创建止损限价单',
    'reports.Stop Limit order created is off market': '止损限价单已离场',
    'reports.Stop Limit order created is unplaced': '没有在交易所下止损限价单',
    'reports.Stop limit order is awaiting market': '止损限价单正在等待市场',
    'reports.Stop Limit Order Modified': '已修改止损限价单',
    'reports.Stop Limit order partially reject': '已部分取消止损限价单',
    'reports.Stop limit order placing request': '止损限价单下单请求',
    'reports.Stop Limit order received by broker': '经纪人已接收到止损限价单',
    'reports.Stop Limit order rejected': '已拒绝止损限价单',
    'reports.Stop Limit Order Removed': '已删除止损限价单',
    'reports.Stop limit order replace request': '止损限价单重新下单请求',
    'reports.Stop limit order replaced': '已修改止损限价单',
    'reports.Stop Limit order replaced': '已修改止损限价单',
    'reports.Stop Limit order restated': '已重列止损限价单',
    'reports.Stop loss order canceled': '已取消止损单',
    'reports.Stop Loss price is too big': '订单的止损价格无效',
    'reports.Stop order accepted': '已接受止损单',
    'reports.Stop order activated': '已激活止损单',
    'reports.Stop order cancel request': '止损单取消请求',
    'reports.Stop order canceled': '已取消止损单',
    'reports.Stop order created': '已创建止损单',
    'reports.Stop Order Created': '已创建止损单',
    'reports.Stop order created is off market': '止损单已离场',
    'reports.Stop order created is unplaced': '没有在交易所下止损单',
    'reports.Stop order is awaiting market': '止损单正在等待市场',
    'reports.Stop Order Modified': '已修改止损单',
    'reports.Stop order partially reject': '已部分取消止损单',
    'reports.Stop order placing request': '止损单下单请求',
    'reports.Stop order received by broker': '经纪人已接收到止损单',
    'reports.Stop order rejected': '已拒绝止损单',
    'reports.Stop Order Removed': '已删除止损单',
    'reports.Stop order replace request': '止损单重新下单请求',
    'reports.Stop order replaced': '已修改止损单',
    'reports.Stop order restated': '已重列止损单',
    'reports.Stop price': '止损价格',
    'reports.stoploss': '止损',
    'reports.StopOutType': '止损类型',
    'reports.stopprice': '止损价格',
    'reports.STP_Route_Change': '在订单中设置原始路由名称',
    'reports.Strike Price': '执行价格',
    'reports.submitted': '已提交',
    'reports.Subscribe': '订阅',
    'reports.SUBSCRIBE_RULES': '订阅计划',
    'reports.success': '成功',
    'reports.Successful': '成功',
    'reports.sum': '合计',
    'reports.Sum': '合计',
    'reports.Summary Report': '汇总报告',
    'reports.SunGard': 'SunGard',
    'reports.SUSPEND_MARGINCALL_ON_CLOSE_MARKET': '暂挂封闭式市场的保证金催交助手',
    'reports.swap': '掉期',
    'reports.swapbuy': '做多掉期',
    'reports.swapplan': '掉期计划',
    'reports.swaps': '掉期',
    'reports.swaps_in': '换入',
    'reports.swaps_out': '换出',
    'reports.swapsell': '做空掉期',
    'reports.symbol': '股票代码',
    'reports.Symbol': '股票代码',
    'reports.symbol_type': '股票代码类型',
    'reports.System': '系统',
    'reports.system_currency': '系统货币',
    'reports.systemMonitor.instruments': '股票代码',
    'reports.systemMonitor.onlineConnections': '在线连接',
    'reports.systemMonitor.positions': '建仓',
    'reports.systemMonitor.regusers': '已注册用户',
    'reports.systemMonitor.runningRoutes': '已从活动的路由启动路由',
    'reports.systemMonitor.totalQueueSize': '服务中的队列',
    'reports.TABLE_ALERT': '表格警报',
    'reports.tablename': '表格名称',
    'reports.TableName': '表格名称',
    'reports.Take profit order canceled': '已取消盈利单',
    'reports.Take Profit price is too big': '盈利价格太高',
    'reports.takeprofit': '盈利',
    'reports.TargetCompID': '目标计算机 ID',
    'reports.task.comment': '备注',
    'reports.task.day': '天',
    'reports.task.hour': '小时',
    'reports.task.minute': '分钟',
    'reports.task.month': '月',
    'reports.task.timezone': '时区时差',
    'reports.task.usedst': '使用 DST',
    'reports.task.year': '年',
    'reports.TempFilePath': '临时文件路径',
    'reports.Tenfore': 'Tenfore',
    'reports.terminal': '终端机',
    'reports.Terminal': '终端机',
    'reports.TERMINAL_API': 'API',
    'reports.TERMINAL_COMMUNITY': '社区',
    'reports.TERMINAL_FIX': 'FIX 连接',
    'reports.TERMINAL_HTML': 'HTML',
    'reports.TERMINAL_JAVA': 'Java',
    'reports.TERMINAL_MOBILE': '移动',
    'reports.TERMINAL_NET': '.Net',
    'reports.TERMINAL_POCKETPC': 'Pocket PC',
    'reports.TERMINAL_WEB': 'WebStation',
    'reports.terminaltype': '终端机类型',
    'reports.TFDS Quote Receiver': 'TFDS 报价接收方',
    'reports.tif': '委托时间有效期',
    'reports.TIF': '委托时间有效期',
    'reports.time': '时间',
    'reports.Time': '日期/时间',
    'reports.to': '到',
    'reports.toDate': '到',
    'reports.toDateConnAttempt': '到',
    'reports.toDatePwdChange': '到（通过）',
    'reports.tooLargeData': '报告的数据太大！显示 {1} 行（共 {2} 行）',
    'reports.total': '总计',
    'reports.total traded amount': '总交易金额',
    'reports.totalfee': '总费用',
    'reports.TP': '盈利',
    'reports.TP accepted by broker': '经纪人已接受盈利单',
    'reports.TP accepted by STP': 'SIP 已接受盈利单',
    'reports.TP order accepted': '已接受盈利单',
    'reports.TP order cancel request': '盈利单取消请求',
    'reports.TP order canceled': '已取消盈利单',
    'reports.TP order created': '已创建盈利单',
    'reports.TP Order Created': '已创建盈利单',
    'reports.TP order created is off market': '盈利单已离场',
    'reports.TP order created is unplaced': '没有在交易所下盈利单',
    'reports.TP Order Modified': '已修改盈利单',
    'reports.TP order partially reject': '已部分取消盈利单',
    'reports.TP order placing request': '盈利单下单请求',
    'reports.TP order received by broker': '经纪人已接收到盈利单',
    'reports.TP order rejected': '已拒绝盈利单',
    'reports.TP Order Removed': '已删除盈利单',
    'reports.TP order replace request': '盈利单重新下单请求',
    'reports.TP order replaced': '已修改盈利单',
    'reports.Tr stop loss order cancel request': '跟踪止损单取消请求',
    'reports.Tr stop loss order placing request': '跟踪止损单下单请求',
    'reports.Tr stop loss order replace request': '跟踪止损单重新下单请求',
    'reports.tr. stop': '跟踪止损',
    'reports.Tr. stop accepted by broker': '经纪人已接受跟踪止损单',
    'reports.Tr. stop accepted by STP': 'STP 已接受跟踪止损单',
    'reports.Tr. stop is too big': '订单的跟踪止损互抵无效',
    'reports.Tr. stop loss order accepted': '已接受跟踪止损单',
    'reports.Tr. stop loss order canceled': '已取消跟踪止损单',
    'reports.Tr. stop loss order created': '已创建跟踪止损单',
    'reports.Tr. stop loss order created is off market': '跟踪止损单已离场',
    'reports.Tr. stop loss order created is unplaced': '没有在交易所下跟踪止损单',
    'reports.Tr. stop loss order replaced': '已修改跟踪止损单',
    'reports.Tr. stop order accepted': '已接受跟踪止损单',
    'reports.Tr. stop order activated': '已激活跟踪止损单',
    'reports.Tr. stop order canceled': '已取消跟踪止损单',
    'reports.Tr. stop order created': '已创建跟踪止损单',
    'reports.Tr. stop order created is off market': '跟踪止损单已离场',
    'reports.Tr. stop order created is unplaced': '没有在交易所下跟踪止损单',
    'reports.Tr. stop order is awaiting market': '跟踪止损单正在等待市场',
    'reports.Tr. stop order partially reject': '已部分取消跟踪止损单',
    'reports.Tr. stop order received by broker': '经纪人已接收到跟踪止损单',
    'reports.Tr. stop order placing request': '跟踪止损单下单请求',
    'reports.Tr. stop order cancel request': '跟踪止损单取消请求',
    'reports.Tr. stop order replace request': '跟踪止损单重新下单请求',
    'reports.Tr. stop order rejected': '已拒绝跟踪止损单',
    'reports.Tr. stop order replaced': '已修改跟踪止损单',
    'reports.Tr. stop order restated': '已重列跟踪止损单',
    'reports.Tr.SL Order Removed': '已删除跟踪止损单',
    'reports.Trade executed': '已执行交易',
    'reports.Trade executed (Mutual)': '已执行交易（相互）',
    'reports.Trade executed (SL)': '已执行交易（止损）',
    'reports.Trade Operations Report': '交易操作报告',
    'reports.Trade Periods By Signal Report': '按信号列出的交易时间段报告',
    'reports.trade.BeginString': '交易开始字符串',
    'reports.trade.CheckCompID': '交易检查计算机 ID',
    'reports.trade.CheckLatency': '交易检查延迟',
    'reports.trade.ClosedResendInterval': '交易关闭重新发送间隔',
    'reports.trade.ConnectionType': '交易连接类型',
    'reports.trade.ContinueInitializationOnError': '交易出错时继续初始化',
    'reports.trade.DataDictionary': '交易数据字典',
    'reports.trade.EndDay': '交易结束日期',
    'reports.trade.EndTime': '交易结束时间',
    'reports.trade.entityId': '交易实体 ID',
    'reports.trade.FileIncludeMilliseconds': '交易文件包括毫秒',
    'reports.trade.FileIncludeTimeStampForMessages': '交易文件包括消息的时间戳',
    'reports.trade.FileLogHeartbeats': '交易文件日志检测信号',
    'reports.trade.fxintegrate.cert.host.verify': '交易外汇集成证书主机验证',
    'reports.trade.fxintegrate.cert.root.ca.file': '交易外汇集成证书根 ca 文件',
    'reports.trade.fxintegrate.host': '交易外汇集成主机',
    'reports.trade.fxintegrate.logging.file': '交易外汇集成日志记录文件',
    'reports.trade.fxintegrate.logging.maxfilecount': '交易外汇集成日志记录最大文件数',
    'reports.trade.fxintegrate.logging.output': '交易外汇集成日志记录输出',
    'reports.trade.fxintegrate.logging.priority': '交易外汇集成日志记录优先级',
    'reports.trade.fxintegrate.port': '交易外汇集成端口',
    'reports.trade.fxintegrate.protocol': '交易外汇集成协议',
    'reports.trade.fxintegrate.usessl': '交易外汇集成使用 ssl',
    'reports.trade.HeartBtInt': '交易信号检测间隔',
    'reports.trade.JdbcDriver': '交易 Jdbc 驱动程序',
    'reports.trade.JdbcPassword': '交易 Jdbc 密码',
    'reports.trade.JdbcURL': '交易 Jdbc URL',
    'reports.trade.JdbcUser': '交易 Jdbc 用户',
    'reports.trade.keystoreAlias': '交易密钥存储别名',
    'reports.trade.keystoreFile': '交易密钥存储文件',
    'reports.trade.LogFileMaxSize': '交易日志文件最大大小',
    'reports.trade.login': '交易登录',
    'reports.trade.MaxLogsBackups': '交易最大日志备份',
    'reports.trade.MillisecondsinTimeStamp': '时间戳中的毫秒',
    'reports.trade.MillisecondsInTimeStamp': '交易时间戳中的毫秒',
    'reports.trade.password': '交易密码',
    'reports.trade.ReconnectInterval': '交易重新连接间隔',
    'reports.trade.RefreshonLogon': '登录时刷新',
    'reports.trade.RefreshOnLogon': '交易登录时刷新',
    'reports.trade.ResetOnDisconnect': '交易断开连接时重置',
    'reports.trade.ResetOnLogon': '交易登录时重置',
    'reports.trade.ResetOnLogout': '交易注销时重置',
    'reports.trade.saveLogs': '交易保存日志',
    'reports.trade.ScreenLogEvents': '交易屏幕日志事件',
    'reports.trade.ScreenLogShowHeartBeats': '交易屏幕日志显示信号检测',
    'reports.trade.ScreenLogShowIncoming': '交易屏幕日志显示传入',
    'reports.trade.ScreenLogShowOutgoing': '交易屏幕日志显示传出',
    'reports.trade.SenderCompID': '交易发件人公司 ID',
    'reports.trade.SendResetSeqNumFlag': '交易发送重置序号标记',
    'reports.trade.SocketConnectHost': '交易套接字连接主机',
    'reports.trade.SocketConnectPort': '交易套接字连接端口',
    'reports.trade.SocketKeyStore': '交易套接字密钥存储',
    'reports.trade.SocketKeyStorePassword': '交易套接字密钥存储密码',
    'reports.trade.SocketUseSSL': '交易套接字使用 SSL',
    'reports.trade.StartDay': '交易开始日期',
    'reports.trade.StartTime': '交易开始时间',
    'reports.trade.TargetCompID': '交易目标公司 ID',
    'reports.trade.UseDataDictionary': '交易使用数据字典',
    'reports.trade.ValidateFieldsHaveValues': '交易验证字段具有值',
    'reports.trade.ValidateFieldsOutOfOrder': '交易验证字段顺序不对',
    'reports.trade.ValidateUserDefinedFields': '交易验证用户定义对的字段',
    'reports.tradeamount': '交易金额',
    'reports.tradeid': '交易 ID',
    'reports.tradePassword': '交易密码',
    'reports.trader': '交易员',
    'reports.Trades by Signal': '按信号列出的交易',
    'reports.Trades Report': '交易报告',
    'reports.Trades report by instrument': '按工具列出的交易报告',
    'reports.trades_bought': '买入交易',
    'reports.trades_sold': '卖出交易',
    'reports.tradevolume': '交易成交数',
    'reports.trading': '交易',
    'reports.Trading': '交易',
    'reports.Trading disabled by risk rules': '风险规则已禁用交易',
    'reports.TRADING_IDEA_CARD': '交易构思卡',
    'reports.trading_in': '折价贴换',
    'reports.trading_out': '以物换物',
    'reports.TRADING_RULES_ACC': '交易规则（账户）',
    'reports.TRADING_RUSLES': '交易规则',
    'reports.TRADINGSYSTEMLIST': '战略经理',
    'reports.Traiding out of Main session is forbidden for this account': '禁止此账户在主要时段以外交易',
    'reports.Trailing stop order cancel request': '跟踪止损单取消请求',
    'reports.Trailing Stop Order Created': '已创建跟踪止损单',
    'reports.Trailing Stop Order Modified': '已修改跟踪止损单',
    'reports.Trailing stop order placing request': '跟踪止损单下单请求',
    'reports.Trailing Stop Order Removed': '已删除跟踪止损单',
    'reports.Trailing stop order replace request': '跟踪止损单重新下单请求',
    'reports.TrailingStop': '跟踪止损',
    'reports.Transfer': '过户',
    'reports.TransferFee': '过户费用',
    'reports.True': 'True',
    'reports.type': '类型',
    'reports.Type': '类型',
    'reports.Types': '类型',
    'reports.UBSFIXFeed': 'UBSFIXFeed',
    'reports.Unable to cancel the order since route is not active.': '无法取消订单，因为路由无效。',
    'reports.Unblocking': '解冻',
    'reports.Unplaced': '未下单',
    'reports.unrealized p/l': '未实现损益',
    'reports.unrealizedpnl': '未实现损益',
    'reports.unsettled_cash': '未结算资金',
    'reports.updatedtime': '更新时间',
    'reports.Updater': '更新程序',
    'reports.URL': 'URL',
    'reports.usage': '使用情况',
    'reports.USE_OPEN_PRICE_IN_MARGIN': '使用开市价格',
    'reports.UseDataDictionary': '使用数据字典',
    'reports.usePrevQuotedOrders': '使用上次更新的订单',
    'reports.UsePriority': '使用优先级',
    'reports.user': '用户',
    'reports.User': '用户',
    'reports.User Authorization Error': '用户授权错误',
    'reports.user group': '用户组',
    'reports.User Previledges Report': '用户特权报告',
    'reports.user.acc_4_signals': '信号的账户',
    'reports.user.address': '地址',
    'reports.user.amount': '金额',
    'reports.user.amountType': '账户类型',
    'reports.user.birthday': '出生日期',
    'reports.user.certificate': '用户证书',
    'reports.user.chatOnEmail': '在电子邮件中发送聊天记录',
    'reports.user.city': '城市',
    'reports.user.comment': '备注',
    'reports.user.commission_type': '佣金依据',
    'reports.user.email': '电子邮件',
    'reports.user.extaccount': '外部账户',
    'reports.user.firstname': '名字',
    'reports.user.gender': '性别',
    'reports.user.group': '组',
    'reports.user.how_handle': '处理信号方式',
    'reports.user.introducebroker': '介绍经纪人账户',
    'reports.user.language': '语言',
    'reports.user.lastname': '姓氏',
    'reports.user.linkedUsers': '链接的用户',
    'reports.user.login': '登录',
    'reports.user.middlename': '中间名',
    'reports.user.password': '新密码',
    'reports.user.phonenumber': '电话号码',
    'reports.user.phonepassword': '电话密码',
    'reports.user.photo': '照片',
    'reports.user.pin': 'Pin 码',
    'reports.user.showInSearch': '在搜索中显示',
    'reports.user.showMyContacts': '显示我的联系人',
    'reports.user.signalsOnEmail': '在电子邮件中发送信号',
    'reports.user.state': '省份',
    'reports.user.status': '状态',
    'reports.user.subscribeplan': '订阅计划',
    'reports.user.website': '网站',
    'reports.user.zipcode': '邮政编码',
    'reports.usergroup.group': '超级组',
    'reports.usergroup.name': '用户组',
    'reports.USERGROUPSVIS_RULES': '用户组可见性',
    'reports.userlogin': '用户登录',
    'reports.UserLogin': '用户登录',
    'reports.username': '用户',
    'reports.Users Report': '用户报告',
    'reports.Users Report(copy)': '用户报告（副本）',
    'reports.users_num': '用户数',
    'reports.useSSL': '使用 SSL',
    'reports.ValidateFieldsHaveValues': '验证字段具有值',
    'reports.ValidateFieldsOutOfOrder': '验证字段顺序混乱',
    'reports.ValidateUserDefinedFields': '验证用户定义对的字段',
    'reports.value': '值',
    'reports.VALUE_ADDRESS': '地址',
    'reports.VALUE_BASE_CURRENCY': '基本股票代码部分',
    'reports.VALUE_BRUTEFORCE_LOCK_MINUTS': '值暴力破解锁定分钟数',
    'reports.VALUE_CITY': '城市',
    'reports.VALUE_COMMISSION_ACCOUNT': '佣金柜台账户',
    'reports.VALUE_COMMISSION_FOR_TRANSFER': '过户所得佣金',
    'reports.VALUE_COMMISSION_ID': '收入计划',
    'reports.VALUE_COUNTER_CURRENCY': '柜台股票代码部分',
    'reports.VALUE_COUNTRY': '国家/地区',
    'reports.VALUE_DAY_BEGIN': '开始日期',
    'reports.VALUE_DAY_END': '结束日期',
    'reports.VALUE_DAYS_TILL_EXP': '到期前天数',
    'reports.VALUE_DEFAULT_BROKER': '默认经纪人',
    'reports.VALUE_DEFAULT_LANG': '默认语言',
    'reports.VALUE_DERIVATIVE_TYPE': '衍生工具类型',
    'reports.VALUE_HEDGE_COEFFICIENT': '对冲系数',
    'reports.VALUE_L2QUOTEGROUP_DEPTH': '级别 2 组深度',
    'reports.VALUE_L2QUOTEGROUP_INTERVAL': '级别 2 组间隔',
    'reports.VALUE_LEVERAGE': '杠杆',
    'reports.VALUE_LOT_SIZE': '口数大小',
    'reports.VALUE_LOTSTEP': '每口数步长',
    'reports.VALUE_MARGIN_COEFFICIENT': '保证金系数（基于时间）',
    'reports.VALUE_MARGINCALL': '保证金催交，%',
    'reports.VALUE_MARGINTRADING': '止损交易水平，%',
    'reports.VALUE_MARKET_RANGE': '市场范围，跳动点',
    'reports.VALUE_MAX_DELTA_QUOTES': '两次连续报价（跳动点）之间允许的最大价格差异',
    'reports.VALUE_MAX_DELTA_TRADES': '两次后续交易之间允许的最大价格差异',
    'reports.VALUE_MAXIMUM_CACHE_SIZE': '最大高速缓存大小',
    'reports.VALUE_MAXIMUM_FILTER': '价格大幅波动的最大值',
    'reports.VALUE_MAXIMUMLOT': '最大口数',
    'reports.VALUE_MIN_OPTION_INTERVAL': '两次期权报价之间允许的最大时差',
    'reports.VALUE_MIN_PRICE_DIFF_FOR_MARGIN': '保证金的最小价格差',
    'reports.VALUE_MINIMALLOT': '最小口数',
    'reports.VALUE_MINIMUM_FILTER': '价格大幅波动的最小值',
    'reports.value_nav': '值/负数',
    'reports.VALUE_NO_QUOTES_MAXTIME': '无报价最长时间',
    'reports.VALUE_NUMBER_OF_SHARES': '股数',
    'reports.VALUE_ONE_COUNTER_ACCOUNT': '柜台账户',
    'reports.VALUE_PIP_SIZE': '跳动点大小（最小变动）',
    'reports.VALUE_PnL_ACCOUNT': '损益柜台账户',
    'reports.VALUE_POST_SESSION_END': '时段结束后',
    'reports.VALUE_PRE_SESSION_BEGIN': '时段开始前',
    'reports.VALUE_PRICE_GENERATION_MODEL': '价格生成模型',
    'reports.VALUE_PWD_VERIFICATION_LEN': '验证密码长度',
    'reports.VALUE_QUOTE_CACHE_15MIN_SIZE': '报价高速缓存 15 分钟大小',
    'reports.VALUE_QUOTE_CACHE_1MIN_SIZE': '报价高速缓存 1 分钟大小',
    'reports.VALUE_QUOTE_CACHE_30MIN_SIZE': '报价高速缓存 30 分钟大小',
    'reports.VALUE_QUOTE_CACHE_4HOUR_SIZE': '报价高速缓存 4 小时大小',
    'reports.VALUE_QUOTE_CACHE_5MIN_SIZE': '报价高速缓存 5 分钟大小',
    'reports.VALUE_QUOTE_CACHE_DAY_SIZE': '报价高速缓存天大小',
    'reports.VALUE_QUOTE_CACHE_HOUR_SIZE': '报价高速缓存小时大小',
    'reports.VALUE_QUOTE_CACHE_MONTH_SIZE': '报价高速缓存月大小',
    'reports.VALUE_QUOTE_CACHE_SIZE': '报价高速缓存大小',
    'reports.VALUE_QUOTE_CACHE_TIC_SIZE': '报价高速缓存跳动点大小',
    'reports.VALUE_QUOTE_CACHE_WEEK_SIZE': '报价高速缓存周大小',
    'reports.VALUE_QUOTE_CACHE_YEAR_SIZE': '报价高速缓存年大小',
    'reports.VALUE_QUOTE_PATH': '报价高速缓存路径',
    'reports.VALUE_QUOTES_FILE_SIZE': '报价文件大小',
    'reports.VALUE_SLIPPAGE': '进度落后',
    'reports.VALUE_SMS_PATTERN': 'SMS 图案',
    'reports.VALUE_SPOT_INSTRUMENT': '现货符号',
    'reports.VALUE_SPREAD_ACCOUNT': '差价柜台账户',
    'reports.VALUE_STATE': '省份',
    'reports.VALUE_STOPOUT': '止损水平，%',
    'reports.VALUE_SWAP_ACCOUNT': '交换柜台账户',
    'reports.VALUE_TEMPLATE_NUMERIC': '电子邮件模板',
    'reports.VALUE_TICK_COAST': '跳动点成本',
    'reports.VALUE_TIMEZONE_OFFSET': '时区时差',
    'reports.VALUE_UNREPEAT_PASSWORD': '不重复密码数',
    'reports.VALUE_WL_ACCOUNT': '存款/提款柜台账户',
    'reports.VALUE_WRONG_PASSWORDS_BEFORE_LOCK': '允许输入错误密码次数',
    'reports.VALUE_ZIP': '邮政编码',
    'reports.ValueAddedTax': '增值税',
    'reports.valuedate': '估价日期',
    'reports.Visibility': '可见性',
    'reports.VISIBILITY_BRANDING': '品牌构建可见性',
    'reports.VISIBILITY_COMMISSIONSPLAN': '收入计划可见性',
    'reports.VISIBILITY_EXTFIELDS': '扩展字段可见性',
    'reports.VISIBILITY_INSTRUMENT_TYPE': '股票代码类型可见性',
    'reports.VISIBILITY_ROUTE': '路由可见性',
    'reports.VISIBILITY_SUBSCRIBE_PLAN': '订阅计划',
    'reports.VISIBILITY_USERGROUP': '用户组可见性',
    'reports.Wait confirm for cancel': '等待取消确认',
    'reports.Wait confirm for execution': '等待执行确认',
    'reports.Wait confirm for modify': '等待修改确认',
    'reports.Waiting market': '等待市场',
    'reports.WEB_CHART_OE': 'Web 图表（内置于 OE）',
    'reports.WEB_CHART_VISUAL': 'Web 图表（可见交易）',
    'reports.WEB_MARKETDEPTH': 'Web 市场深度',
    'reports.WEB_OE': 'Web OE',
    'reports.WEB_OPTION_MASTER': 'Web 期权链',
    'reports.WEB_ORDERS_PANEL_HOT_BTN': 'Web 工作订单（热按钮）',
    'reports.WEB_OTHER': 'Web 其他',
    'reports.WEB_POSITIONS_PANEL_HOT_BTN': 'Web 头寸面板（热按钮）',
    'reports.weeks': '周',
    'reports.Widgets Instrument Statistics Report': '小组件工具统计报告',
    'reports.widgets_isr_buytrades': '买入交易',
    'reports.widgets_isr_commission': '费用',
    'reports.widgets_isr_instrumentname': '股票代码',
    'reports.widgets_isr_netpl': '净损益',
    'reports.widgets_isr_plticks': '损益跳动点',
    'reports.widgets_isr_selltrades': '卖出交易',
    'reports.widgets_isr_swap': '掉期',
    'reports.widgets_isr_totaltrades': '交易总计',
    'reports.widgets_isr_trades': '交易',
    'reports.widgets_isr_tradevolume': '成交量',
    'reports.widgets_isr_winningtrades': '盈利交易',
    'reports.widthdraw': '提款',
    'reports.Withdraw': '提款',
    'reports.Withdrawal': '提款',
    'reports.withdrawalfee': '提款费用',
    'reports.Wrong account operation summ.': '错误账户操作合计',
    'reports.Wrong recovery key': '恢复密钥错误',
    'reports.years': '年',
    'reports.yes': '是',
    "reports.You can't trade with this account.": '您不能使用此账户交易。',
    Reserve: '保留',
    'ribbon.enviroment': '环境',
    'ribbon.enviroment.help': '帮助',
    'ribbon.enviroment.logout': '注销',
    'ribbon.enviroment.New': '新工作区',
    'ribbon.enviroment.NotificationWindow.lockTrading': '交易已锁定',
    'ribbon.enviroment.NotificationWindow.UnlockTrading': '交易已解锁',
    'ribbon.enviroment.Save': '保存工作区',
    'ribbon.enviroment.settings': '设置',
    'ribbon.help.about': '关于',
    'ribbon.help.onboarding': '登录',
    'ribbon.help.help': '更多',
    'ribbon.help.home': '主页',
    'ribbon.linking.linkBlue': '蓝色',
    'ribbon.linking.linkEmpty': '无',
    'ribbon.linking.linkGreen': '绿色',
    'ribbon.linking.linkOrange': '橙色',
    'ribbon.linking.linkPurple': '紫色',
    'ribbon.linking.linkRed': '红色',
    'ribbon.linking.linkYellow': '黄色',
    'ribbon.more.tradingTools': '交易工具',
    'ribbon.tools': '工具',
    'ribbon.tools.full_logs': '完整日志记录服务器消息',
    'ribbon.tools.grid': '网格',
    'ribbon.tools.withdrawal': '提款',
    'ribbon.tools.reports': '报告',
    'ribbon.tradingTerminal': '终端机',
    'ribbon.tradingTerminal.statement': '#hidden#',
    'RiskManagement.Messages.DailyLimit': '已达到每日损失限额',
    'RiskManagement.Messages.MaxDailyProfit': '已达到每日盈利',
    'RiskManagement.Messages.MaxTrailingLimit': '已达到最大跟踪回撤限额',
    'RiskManagement.Messages.MaxUnrealizedLossLimit': '已达到未实现损失限额',
    'RiskManagement.Messages.WeeklyLimit': '已达到每周损失限额',
    'screen.about.copyright': '© 2017-2025 TraderEvolution Global LTD.',
    'screen.about.dateBuild': '构建日期：',
    'screen.about.title': '关于',
    'screen.about.titleWeb': '关于 TraderEvolution',
    'screen.about.versionWeb': '版本号',
    'screen.additionalVerification.comment': '连接 ({1}) 需要额外验证：',
    'screen.additionalVerification.Need activation': '需要激活',
    'screen.additionalVerification.Need activation.comment': '请输入激活代码（已发送到您的电子邮箱）',
    'screen.additionalVerification.Need activation.watermark': '<输入电子邮件中的激活代码>',
    'screen.additionalVerification.title': '额外验证',
    'screen.additionalVerification.title.Email': '额外验证（电子邮件一次性密码）',
    'screen.additionalVerification.title.Fixed': '额外验证（固定密码）',
    'screen.additionalVerification.title.Sms': '额外验证（SMS 一次性密码）',
    'screen.additionalVerification.watermark': '<输入额外密码>',
    'screen.changepass.cancel': '取消',
    'screen.changepass.changed': '密码已成功更改',
    'screen.changepass.confirm': '确认新密码',
    'screen.changepass.new': '新密码',
    'screen.changepass.ok': '变动',
    'screen.changepass.old': '当前密码',
    'screen.changepass.title': '更改密码',
    'screen.changeTradingPass.title': '更改交易密码',
    'screen.changepass.watermark_confirm': '<确认密码>',
    'screen.changepass.watermark_new': '<新密码>',
    'screen.changepass.watermark_old': '<当前密码>',
    'screen.changepass.alert_not_match': '密码和确认密码不匹配',
    'screen.changepass.wrongVerification': '错误验证',
    'screen.closePosition.cancel': '取消',
    'screen.closePosition.ok': '关闭',
    'screen.closePosition.title': '关闭',
    'screen.error.title': '错误',
    'screen.export.header': '导出',
    'screen.export.contextMenu': '导出',
    'screen.export.Export': '导出',
    'screen.export.Cancel': '取消',
    'screen.export.CSV': 'CSV',
    'screen.export.Excel': 'Excel',
    'screen.export.selectFormat.tt': '选择文件类型',
    'screen.export.checkAllText': '全选',
    'screen.licensing.emptyFieldErrors.password': '密码字段是空的',
    'screen.licensing.statusMessages.connecting': '正在连接...',
    'screen.login.change_pass': '密码已重置或已过期，需要更改',
    'screen.lookup.scripts.noitems': '没有匹配项目',
    'screen.lookup.scripts.showing': '显示 {1} 个项目',
    'screen.lookup.scripts.watermark': '<搜索>',
    'screen.modifyOrder.amount': '数量：',
    'screen.modifyOrder.bind_to': '绑定到：',
    'screen.modifyOrder.Change order': '修改订单',
    'screen.modifyOrder.created': '日期/时间：',
    'screen.modifyOrder.instrument': '股票代码：',
    'screen.modifyOrder.market_price': '当前价格：',
    'screen.modifyOrder.modify': '修改',
    'screen.modifyOrder.modifyPosition': '修改头寸',
    'screen.modifyOrder.number': '头寸 ID：',
    'screen.modifyOrder.operation': '侧面：',
    'screen.modifyOrder.orderNumber': '订单 ID：',
    'screen.modifyOrder.ordertype': '订单类型：',
    'screen.modifyOrder.price': '开盘价：',
    'screen.modifyOrder.route': '路由：',
    'screen.properties.cancel': '关闭',
    'screen.properties.cancel.ToolTip': '关闭',
    'screen.properties.common': '属性',
    'screen.properties.ok': '确定',
    'screen.properties.ok.ToolTip': '确定',
    'screen.properties.title': '常规设置',
    'screen.properties.unsavedChanges': '您有未保存的更改。是否退出并放弃您的更改？',
    'screen.LoginScreen.Connecting': '正在连接...',
    'screen.LoginScreen.Error.loginIdIsEmpty': '登录 ID 字段是空的',
    'screen.LoginScreen.Error.passwordIdIsEmpty': '密码字段是空的',
    'screen.LoginScreen.ForgotLinkLabel': '忘记密码',
    'screen.LoginScreen.ForgotLinkLabel.ToolTip': '密码恢复',
    'screen.LoginScreen.LoadingUserData': '正在加载用户数据...',
    'screen.LoginScreen.LoginButtonText': '登录',
    'screen.LoginScreen.LoginButtonTooltip': '登录平台',
    'screen.LoginScreen.LoginIDLabel': '登录',
    'screen.LoginScreen.LoginIDTextBox.ToolTip': '用户登录',
    'screen.LoginScreen.PasswordLabel': '密码',
    'screen.LoginScreen.PasswordTextBox.ToolTip': '用户密码',
    'screen.LoginScreen.RegistrationLinkLabel': '注册',
    'screen.LoginScreen.RegistrationLinkLabel.ToolTip': '演示账户注册',
    'screen.LoginScreen.SavePassCheckBox': '保存密码',
    'screen.LoginScreen.SavePassCheckBox.ToolTip': '记住凭据',
    'screen.LoginScreen.Settings.LanguageLabel': '语言',
    'screen.recconection.attemptLabelLabel': '尝试次数：',
    'screen.recconection.reconnecting': '正在重新连接...',
    'screen.recconection.seconds': '秒...',
    'screen.recconection.statusLabelLabel': '状态：',
    'screen.recconection.stop': '止损',
    'screen.recconection.title': '重新连接管理器',
    'screen.recconection.tryNow': '立即尝试',
    'screen.recconection.waiting': '正在等待',
    'screen.RecoveryPassForm.Cancel': '取消',
    'screen.RecoveryPassForm.Confirm': '确认密码',
    'screen.RecoveryPassForm.Email': '电子邮件：',
    'screen.RecoveryPassForm.HaveCode': '我有密钥',
    'screen.RecoveryPassForm.Key': '密钥',
    'screen.RecoveryPassForm.Login': '登录：',
    'screen.RecoveryPassForm.New password': '新密码',
    'screen.RecoveryPassForm.New password contains prohibited symbols': '新密码含有禁止使用的符号',
    'screen.RecoveryPassForm.Send': '恢复',
    'screen.RecoveryPassForm.Title': '密码恢复',
    'screen.RecoveryPassForm.invalid_login': '用于恢复密码的登录无效',
    'screen.RecoveryPassForm.invalid_login_or_email': '用于恢复密码的登录或电子邮件无效',
    'screen.RecoveryPassForm.invalid_email': '此用户的电子邮件无效',
    'screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!': '您的恢复密码请求已成功发送。请检查您的电子邮件！',
    'screen.registrationWizard.confirmPassword': '*确认密码：',
    'screen.registrationWizard.confirmPassword.watermark': '<确认您的密码>',
    'screen.registrationWizard.email': '*电子邮件：',
    'screen.registrationWizard.email.watermark': '<电子邮件>',
    'screen.registrationWizard.Country': '国家/地区：',
    'screen.registrationWizard.Password': '*密码：',
    'screen.registrationWizard.Balance': '余额：',
    'screen.registrationWizard.firstName': '*名字：',
    'screen.registrationWizard.firstName.watermark': '<至少 2 个字符>',
    'screen.registrationWizard.lastName': '*姓氏：',
    'screen.registrationWizard.lastName.watermark': '<至少 2 个字符>',
    'screen.registrationWizard.login': '登录：',
    'screen.registrationWizard.login.watermark': '<留空以自动生成>',
    'screen.registrationWizard.password.watermark': '<输入您的密码>',
    'screen.registrationWizard.phone': '电话号码：',
    'screen.registrationWizard.phoneNumber.watermark': '<(国家/地区代码) 电话号码>',
    'screen.registrationWizard.registrationStatus': '注册状态',
    'screen.registrationWizard.successMessage': '注册成功！\n登录：{1}\n密码：{2}',
    'screen.registrationWizard.Header': '创建演示账户',
    'screen.registrationWizard.Register': '注册',
    'screen.registrationWizard.Cancel': '取消',
    'screen.renameScreen.cancel': '取消',
    'screen.renameScreen.illegalName': '名称不合法！',
    'screen.renameScreen.ok': '确定',
    'screen.renameScreen.newList.header': '列表名称',
    'screen.renameScreen.newList.placeholder': '新列表',
    'screen.renameScreen.newTemplate.header': '模板名称',
    'screen.renameScreen.newTemplate.placeholder': '新模板',
    'screen.renameScreen.rename.header': '重命名',
    'screen.rename.alreadyExist.firstPart': '名称',
    'screen.rename.alreadyExist.secondPart': '已存在。',
    'screen.remove.lastlist': '至少应该有一个列表',
    'screen.remove.title': '删除',
    'screen.remove.confirmText.firstPart': '删除',
    'screen.remove.confirmText.secondPart': '列表？',
    'screen.remove.confirmText.secondPart.templates': '模板？',
    'screen.ReportMessageTooltip.CreatedAt': '创建时间',
    'screen.ReportMessageTooltip.FilledAt': '完成时间',
    'screen.ReportMessageTooltip.MarginCall': '保证金催交',
    'screen.ReportMessageTooltip.Market': '市场',
    'screen.ReportMessageTooltip.ModifiedAt': '已于以下时间修改',
    'screen.ReportMessageTooltip.RemovedAt': '已于以下时间删除',
    'screen.reports.actions_print': '打印',
    'screen.reports.address': '地址',
    'screen.reports.beginDatePicker.ButtonDropDown.ToolTip': '单击以打开日历',
    'screen.reports.beginDatePicker.ToolTip': '选择日期/时间',
    'screen.reports.brokerzip': '邮政编码',
    'screen.reports.button_printer_setup': '设置',
    'screen.reports.byGroup': '按组',
    'screen.reports.byInstrumentGroup': '按组',
    'screen.reports.byInstruments': '按工具',
    'screen.reports.byUser': '按账户',
    'screen.reports.byUserGroup': '按组',
    'screen.reports.cell_data': '',
    'screen.reports.column.inner_text': '',
    'screen.reports.columns_maneger': '列管理器...',
    'screen.reports.country': '国家/地区',
    'screen.reports.csv': '导出到 CSV...',
    'screen.reports.customerzip': '城市，邮政编码',
    'screen.reports.dataCoverage': '数据覆盖范围',
    'screen.reports.errSaving': '保存文件时出错。',
    'screen.reports.excel': '导出到 Excel...',
    'screen.reports.exportToButton': '导出到...',
    'screen.reports.filter': '过滤器管理器...',
    'screen.reports.historyFor': '以下项目的历史记录',
    'screen.reports.html': '导出到 HTML...',
    'screen.reports.info': '信息',
    'screen.reports.error': '错误',
    'screen.reports.Reports': '报告',
    'screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip': '选择股票代码的组',
    'screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip': '选择股票代码',
    'screen.reports.license': '许可证编号',
    'screen.reports.listViewEx.itemAll': '全部',
    'screen.reports.manager': '管理器',
    'screen.reports.minimizeButton.Hide reports parameters': '隐藏报告参数',
    'screen.reports.minimizeButton.Show reports parameters': '显示报告参数',
    'screen.reports.name': '名称',
    'screen.reports.news.daily': '每日',
    'screen.reports.news.from': '从',
    'screen.reports.news.hideACriterias': '隐藏高级标准',
    'screen.reports.news.invalidDateTime': '请选择有效的“从”和“到”日期/时间',
    'screen.reports.news.monthToDate': '月-到-日',
    'screen.reports.news.range': '范围',
    'screen.reports.news.showACriterias': '显示高级标准',
    'screen.reports.news.sinceInception': '自成立以来',
    'screen.reports.news.till': '直到',
    'screen.reports.news.yearToDate': '本年至今',
    'screen.reports.noData': '无可用数据',
    'screen.reports.order': '订单',
    'screen.reports.param.Name': '名称',
    'screen.reports.paramOfReport': '报告的参数',
    'screen.reports.pdf': '导出到 PDF...',
    'screen.reports.phone': '电话号码',
    'screen.reports.pos': '头寸',
    'screen.reports.president': '总裁的姓名',
    'screen.reports.print': '打印...',
    'screen.reports.processingRequset': '取消',
    'screen.reports.rangeError': '“从”日期不能晚于“到”日期！',
    'screen.reports.report.button_printer_setup': '设置',
    'screen.reports.reportHeader.accnumber': '账户编号',
    'screen.reports.reportHeader.from': '从',
    'screen.reports.reportHeader.Text': '客户和经纪人信息',
    'screen.reports.reportHeaders.account': '账户：',
    'screen.reports.reportHeaders.currancy': '货币：',
    'screen.reports.reportHeaders.date': '日期：',
    'screen.reports.reportHeaders.from': '从：',
    'screen.reports.reportHeaders.owner': '所有者：',
    'screen.reports.reportHeaders.to': '到：',
    'screen.reports.reportHeaderText': '客户和经纪人信息',
    'screen.reports.reportParameters': '报告参数',
    'screen.reports.reports.Canceled': '操作已取消',
    'screen.reports.reports.ExpingToCSV': '正在将数据导出到 CSV 文档...',
    'screen.reports.reports.ExpingToExcel': '正在将数据导出到 Excel 文档...',
    'screen.reports.reports.ExpingToHTML': '正在将数据导出到 HTML 文档...',
    'screen.reports.reports.ExpingToPDF': '正在将数据导出到 PDF 文档...',
    'screen.reports.reports.ExportingPrint': '正在导出数据以打印...',
    'screen.reports.reports.ExpPrint': '已成功导出数据以打印。',
    'screen.reports.reports.ExpPrintErr': '导出数据以打印时出错！',
    'screen.reports.reports.ExpToCSV': '数据已成功导出到 CSV。',
    'screen.reports.reports.ExpToCSVErr': '导出到 CSV 期间出错！',
    'screen.reports.reports.ExpToExcel': '数据已成功导出到 Excel。',
    'screen.reports.reports.ExpToExcelErr': '导出到 Excel 期间出错！',
    'screen.reports.reports.ExpToHTML': '数据已成功导出到 HTML 文档。',
    'screen.reports.reports.ExpToHTMLErr': '导出到 HTML 文档期间出错！',
    'screen.reports.reports.ExpToPDF': '数据已成功导出到 PDF 文档。',
    'screen.reports.reports.ExpToPDFErr': '导出到 PDF 文档期间出错！',
    'screen.reports.reports.getReport': '获取报告',
    'screen.reports.reports.in_separate_window': '报告已在单独的窗口中打开',
    'screen.reports.reports.inCurrentWindow': '在当前窗口中',
    'screen.reports.reports.info': '使用报告管理器查看和打印账户活动报告',
    'screen.reports.reports.inSeparateWindow': '在独立的窗口中',
    'screen.reports.reports.loading': '正在加载报告的数据...',
    'screen.reports.reports.noData': '很抱歉，没有此请求的可用数据。请尝试适用报告的另一个参数。',
    'screen.reports.reports.noItemAvailable': '无可用项目',
    'screen.reports.reportType': '报告类型',
    'screen.reports.reportTypeCombobox.ToolTip': '选择报告类型',
    'screen.reports.save': '保存',
    'screen.reports.showSummary': '显示汇总',
    'screen.reports.sinceInception': '自成立以来',
    'screen.reports.summary': '汇总',
    'screen.reports.table': '表格',
    'screen.reports.title': '报告管理器',
    'screen.reports.to': '到',
    'screen.reports.trades': '交易',
    'screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip': '选择账户的组',
    'screen.reports.UserAndGroupLookup.usersLookup.ToolTip': '选择账户',
    'screen.TimeZoneSelector.title': '选择时区',
    'screen.tradingTools.header': '交易工具',
    'screen.UnlockTradingScreen.lockedLabel': '交易已锁定',
    'screen.UnlockTradingScreen.unlockButton': '解锁',
    'screen.withdrawal.account': '从账户',
    'screen.withdrawal.Asset': '资产',
    'screen.withdrawal.Availible funds': '可用：',
    'screen.withdrawal.avaliableNumeric.ToolTip': '设置剩余资本',
    'screen.withdrawal.Cancel': '取消',
    'screen.withdrawal.error.not_allowed': '该用户不允许类型为“提款”的“账户操作”，请联系您的经纪人',
    'screen.withdrawal.error.not_enough_balance': '余额不足',
    'screen.withdrawal.error.not_enough_blocked': '金额无效（解冻金额不能大于冻结金额）',
    'screen.withdrawal.error.not_enough_margin': '没有足够的保证金',
    'screen.withdrawal.error.UnableToBlock': '出错了！无法冻结。',
    'screen.withdrawal.error.WrongSum': '错误：合计错误。',
    'screen.withdrawal.errorLabel': '余额不足，无法提款',
    'screen.withdrawal.Fee': '提款费用：',
    'screen.withdrawal.from': '从',
    'screen.withdrawal.Remains': '剩余',
    'screen.withdrawal.RequiredAmount': '需要金额：',
    'screen.withdrawal.ReservedWithdrawal': '提款',
    'screen.withdrawal.usersLookup.ToolTip': '选择账户',
    'screen.withdrawal.Withdraw': '提款',
    'screen.withdrawal.Withdrawal': '提款',
    'screen.withdrawal.withdrawalNumeric.ToolTip': '设置提款资本',
    'screen.withdrawal.Amount': '金额',
    'Simple Moving Average': '简单移动平均线',
    SixMonth: '6 个月',
    'Smoothed Moving Average': '平滑移动平均线',
    'Standard Deviation': '标准偏差',
    'statusBar.passwordpanel.DetalTicket.Text': '允许访问交易活动',
    'statusBar.passwordPanel.passwordBox.invalidPass': '交易密码无效。',
    Stochastic: '随机',
    'Stochastic Slow': '随机慢',
    Stop: '止损',
    'Stop Limit': '止损限额',
    'Stop-loss': '止损',
    Suomi: '#hidden#',
    'Swing Index': '摆动指数',
    'Take-profit': '盈利',
    'TerceraAccountLookup.AccountNotExist': '账户不存在',
    'TerceraAccountLookup.IncorrectAccount': '账户不正确',
    'TerceraAccountLookup.Linked accounts': '链接的账户',
    'TerceraAccountLookup.Multy select': '多选',
    'TerceraAccountLookup.Select': '选择',
    'TerceraAccountLookup.Select All': '全选',
    'textBoxWithValidating.ErrorText.NameContainsInvalidChar': '名称含有无效的字符',
    'textBoxWithValidating.ErrorText.NameExist': '此名称已存在',
    ThreeMonth: '3 个月',
    'Tr. stop': '跟踪止损',
    'TradeDefaultManager.Set defaults': '设置默认值',
    'TradeDefaultManager.Types manager': '类型管理器',
    'TradeTool.Amount of order': '订单数量',
    'TradeTool.Cancel order': '取消订单',
    'TradeTool.Cancel SL': '取消止损',
    'TradeTool.Cancel TP': '取消盈利',
    'TradeTool.Close position': '平仓',
    'TradeTool.Drag to set SL order': '拖动以设置止损单',
    'TradeTool.Drag to set TP order': '拖动以设置盈利单',
    'TradeTool.Order': '订单',
    'TradeTool.Position': '头寸',
    'TradeTool.Position P/L': '总损益',
    'TradeTool.Tick and P/L': '跳动点和损益',
    'TradeTool.Trailing Stop': '跟踪止损',
    'TradeTool.View more actions': '查看更多操作',
    TTO: 'TTO',
    'TypesManagerScreen.DefaultLotsSetup': '默认口数设置',
    'TypesManagerScreen.NumericLabel.Price increment (arrow), ticks': '价格增量（箭头），跳动点',
    'TypesManagerScreen.NumericLabel.SL default offset, ticks': '止损默认互抵，跳动点',
    'TypesManagerScreen.NumericLabel.TP default offset, ticks': '盈利默认互抵，跳动点',
    'TypesManagerScreen.NumericLabel.User quantity coefficient': '用户数量系数',
    'TypesManagerScreen.QuickTable.Column.Default lots': '默认口数',
    'TypesManagerScreen.QuickTable.Column.Enable': '启用',
    'TypesManagerScreen.Types Manager': '类型管理器',
    'UserControl.TerceraNumeric.ValueGreaterMax': '值大于最大值',
    'UserControl.TerceraNumeric.ValueIsEmpty': '值是空的',
    'UserControl.TerceraNumeric.ValueLessMin': '值小于最小值',
    'UserControl.TerceraNumeric.ValueNotMultiple': '值不是最小更改的倍数',
    'UserControl.TerceraNumeric.ValueNotNumber': '值不是数值',
    Volume: '成交量',
    'Volume Weighted Average Price': '成交量加权平均价格',
    VWAP: 'VWAP',
    allowCreatePanelsFromPanel: '',
    allowLinkSystem: '',
    allowWorkspaceContextMenu: '',
    allowWorkspaceEdit: '',
    'orders.confirm.all.question1': '取消所有 (',
    'orders.confirm.all.question2': ') 订单？',
    'orders.confirm.all.title': '取消所有订单',
    'orders.confirm.cancel.question1': '按选择的股票代码取消所有订单？',
    'positions.confirm.mutual.question1': '是否要相互关闭选择的 (',
    'positions.confirm.mutual.question2': ') 头寸？',
    'Rows.firstPart': '在',
    'Rows.secondPart': '中，已达到可用股票代码/行限额。请联系您的支持团队，以获得详细信息',
    'general.messageBox.cancel.ToolTip': '废弃更改并关闭',
    'property.Confirmations.ToolTip': '确认设置',
    'general.messageBox.ok.ToolTip': '应用更改并关闭',
    'property.Defaults.ToolTip': '交易默认设置',
    'property.View.ToolTip': '查看设置',
    'property.Warnings.ToolTip': '警告设置',
    'Throtling.Count.firstPart': '已达到可用',
    'Throtling.Count.secondPart': '面板限额。请联系您的支持团队，以获得详细信息',
    'workspace.At first you should unlock workspace to rename it': '首先，您应该解锁工作区以重命名它。',
    'workspace.Clear': '清除',
    'workspace.clear_ask': '清除工作区？',
    'workspace.clear_wsp': '清除工作区',
    'workspace.Close': '关闭',
    'workspace.close_wsp': '关闭工作区',
    'workspace.DefaultNameSpace': 'Wrk#',
    'workspace.errorSavingFile': '保存文件时出错。',
    'workspace.information': '信息',
    'workspace.saved.Message': '已保存工作区',
    'workspace.Lock': '锁定',
    'workspace.Lock.Message': '已锁定工作区',
    'workspace.menu.Clone': '克隆',
    'workspace.menu.Close': '关闭',
    'workspace.menu.CloseAllButThis': '全部关闭，但此工作去除外',
    'workspace.menu.Lock': '锁定',
    'workspace.newWorkspaceName': '输入新的工作区名称',
    'workspace.numberOfWorkspace': '打开的工作区数目超过允许的最大数目',
    'workspace.Rename': '重命名',
    'workspace.Unlock': '解锁',
    'workspace.Unlock.Message': '已解锁工作区',
    'InstrumentDetailsPanel.ISIN': 'ISIN',
    'InstrumentDetailsPanel.AccruedInterest.descr': '按 1 口数合约计算的当前应计利息',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot.descr': '表示已进行交易固定返利价值中每一口数将收费/回扣（以计划货币为单位）',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot.descr': '表示已进行交易固定返利价值中每一口数将收费/回扣（以计划货币为单位）',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume.descr': '表示收费/回扣合计将按成交量的百分比计算',
    'InstrumentDetailsPanel.AllowedOperations.descr': '显示允许针对给定股票代码执行的操作',
    'InstrumentDetailsPanel.AllowedOrderTypes.descr': '显示给定股票代码允许的订单类型',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday': '盘中允许做空',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery': '交割允许做空',
    'InstrumentDetailsPanel.AllowShortPositions.descr': '显示是否允许通过给定股票代码建立做空仓',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday.descr': '显示是否允许通过给定股票代码建立做空仓',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery.descr': '显示是否允许通过给定股票代码建立做空仓',
    'InstrumentDetailsPanel.AssetClass.descr': '在市场上买卖的股票代码类型',
    'InstrumentDetailsPanel.CloseOutDeadline.descr': '根据每个具体合约将平仓的日期',
    'InstrumentDetailsPanel.ContactMonth.descr': '合约到期的日期。可用于期权和期货。',
    'InstrumentDetailsPanel.ContractSize.descr': '一个合约的标的资产金额',
    'InstrumentDetailsPanel.CurrentSession.descr': '有关当前交易时段的信息',
    'InstrumentDetailsPanel.DeliveryMethod.descr': '资产交割的类型',
    'InstrumentDetailsPanel.DeliveryStatus.descr': '资产交割的状态',
    'InstrumentDetailsPanel.Description.descr': '有关股票代码的详细信息',
    'InstrumentDetailsPanel.FirstTradeDate.descr': '将首次交易合约的日期。可用于期权和期货。',
    'InstrumentDetailsPanel.HiLimit.descr': '允许价格区间的上限，不会接受高于该值的延迟订单',
    'InstrumentDetailsPanel.ISIN.descr': '国际证券识别号',
    'InstrumentDetailsPanel.KEYNextHoliday.descr': '显示不允许交易的下一个假日的日期',
    'InstrumentDetailsPanel.LastTradeDate.descr': '可最后交易合约的日期。可用于期权、期货和远期合约',
    'InstrumentDetailsPanel.LotSize.descr': '一口数的基本资产的金额',
    'InstrumentDetailsPanel.LotStep.descr': '口数变动的步长',
    'InstrumentDetailsPanel.LowLimit.descr': '允许价格区间的下限，不会接受低于该值的延迟订单',
    'InstrumentDetailsPanel.MAIN.descr': '有关主要时段设置的信息',
    'InstrumentDetailsPanel.Margin.descr': '显示保证金要求，其取决于保证金计算类型',
    'InstrumentDetailsPanel.Margin_ByAccount.descr': '显示基于账户系数的保证金要求',
    'InstrumentDetailsPanel.MarginBuy.descr': '显示做多的保证金要求，其使用不同的做多/做空保证金，并且不依赖于隔夜保证金',
    'InstrumentDetailsPanel.MarginDay.descr': '显示日保证金要求，其不依赖于做多/做空保证金',
    'InstrumentDetailsPanel.MarginDayBuy.descr': '显示做多的初始和维持保证金的日系数',
    'InstrumentDetailsPanel.MarginDaySell.descr': '显示做空的初始和维持保证金的日系数',
    'InstrumentDetailsPanel.MarginOvernight.descr': '显示隔夜保证金要求，其不依赖于做多/做空保证金',
    'InstrumentDetailsPanel.MarginOvernightBuy.descr': '显示做多隔夜过户的初始和维持保证金系数',
    'InstrumentDetailsPanel.MarginOvernightSell.descr': '显示做空隔夜过户的初始和维持保证金系数',
    'InstrumentDetailsPanel.MarginSell.descr': '显示做多的保证金要求，其使用不同的做多/做空保证金，并且不依赖于隔夜保证金',
    'InstrumentDetailsPanel.Margin.Intraday.descr': '显示保证金要求，其取决于保证金计算类型',
    'InstrumentDetailsPanel.MarginBuy.Intraday.descr': '显示做多的保证金要求，其使用不同的做多/做空保证金，并且不依赖于隔夜保证金',
    'InstrumentDetailsPanel.MarginDay.Intraday.descr': '显示日保证金要求，其不依赖于做多/做空保证金',
    'InstrumentDetailsPanel.MarginDayBuy.Intraday.descr': '显示做多的初始和维持保证金的日系数',
    'InstrumentDetailsPanel.MarginDaySell.Intraday.descr': '显示做空的初始和维持保证金的日系数',
    'InstrumentDetailsPanel.MarginOvernight.Intraday.descr': '显示隔夜保证金要求，其不依赖于做多/做空保证金',
    'InstrumentDetailsPanel.MarginOvernightBuy.Intraday.descr': '显示做多隔夜过户的初始和维持保证金系数',
    'InstrumentDetailsPanel.MarginOvernightSell.Intraday.descr': '显示做空隔夜过户的初始和维持保证金系数',
    'InstrumentDetailsPanel.MarginSell.Intraday.descr': '显示做多的保证金要求，其使用不同的做多/做空保证金，并且不依赖于隔夜保证金',
    'InstrumentDetailsPanel.Margin.Delivery.descr': '显示保证金要求，其取决于保证金计算类型',
    'InstrumentDetailsPanel.MarginBuy.Delivery.descr': '显示做多的保证金要求，其使用不同的做多/做空保证金，并且不依赖于隔夜保证金',
    'InstrumentDetailsPanel.MarginDay.Delivery.descr': '显示日保证金要求，其不依赖于做多/做空保证金',
    'InstrumentDetailsPanel.MarginDayBuy.Delivery.descr': '显示做多的初始和维持保证金的日系数',
    'InstrumentDetailsPanel.MarginDaySell.Delivery.descr': '显示做空的初始和维持保证金的日系数',
    'InstrumentDetailsPanel.MarginOvernight.Delivery.descr': '显示隔夜保证金要求，其不依赖于做多/做空保证金',
    'InstrumentDetailsPanel.MarginOvernightBuy.Delivery.descr': '显示做多隔夜过户的初始和维持保证金系数',
    'InstrumentDetailsPanel.MarginOvernightSell.Delivery.descr': '显示做空隔夜过户的初始和维持保证金系数',
    'InstrumentDetailsPanel.MarginSell.Delivery.descr': '显示做多的保证金要求，其使用不同的做多/做空保证金，并且不依赖于隔夜保证金',
    'InstrumentDetailsPanel.MaturityDate.descr': '合同到期的日期',
    'InstrumentDetailsPanel.MaximumLot.descr': '口数中允许的最大交易',
    'InstrumentDetailsPanel.MaximumLot.Intraday': '盘中最大口数',
    'InstrumentDetailsPanel.MaximumLot.Intraday.descr': '口数中允许的最大交易',
    'InstrumentDetailsPanel.MaximumLot.Delivery': '交割最大口数',
    'InstrumentDetailsPanel.MaximumLot.Delivery.descr': '口数中允许的最大交易',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.descr': '多口数合约中每个工具的所有头寸的最大合计数量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday.descr': '多口数合约中每个工具的所有头寸的最大合计数量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery.descr': '多口数合约中每个工具的所有头寸的最大合计数量',
    'InstrumentDetailsPanel.MinimalLot.descr': '可供交易的每口数合约的最小部分',
    'InstrumentDetailsPanel.MinimalLot.Intraday': '盘中最小口数',
    'InstrumentDetailsPanel.MinimalLot.Intraday.descr': '可供交易的每口数合约的最小部分',
    'InstrumentDetailsPanel.MinimalLot.Delivery': '交割最小口数数',
    'InstrumentDetailsPanel.MinimalLot.Delivery.descr': '可供交易的每口数合约的最小部分',
    'InstrumentDetailsPanel.NextPaymentDate.descr': '下一次收益付款日期',
    'InstrumentDetailsPanel.NormalMarketSize.descr': '正常市场规模',
    'InstrumentDetailsPanel.NoticeDate.descr': '将发送有关最近到期日期的通知的日期。可用于期货。',
    'InstrumentDetailsPanel.OrderPerLot.descr': '为已买卖的每口数支付的费用',
    'InstrumentDetailsPanel.OrderVolume.descr': '为已完成的订单成交量的每个部分支付的费用',
    'InstrumentDetailsPanel.PerFill.descr': '为每个已完成的订单支付的费用',
    'InstrumentDetailsPanel.PerLot.descr': '为已买卖的每口数支付的费用',
    'InstrumentDetailsPanel.PerOrderVolume.descr': '为已完成的订单成交量的每个部分支付的费用',
    'InstrumentDetailsPanel.PerPhoneTransaction.descr': '为电话经销商进行的每笔交易支付的费用',
    'InstrumentDetailsPanel.PerTransaction.descr': '每笔交易的费用。交易：下订单、取消订单和修改订单',
    'InstrumentDetailsPanel.PerVolume.descr': '根据每笔交易的成交量计算费用，%',
    'InstrumentDetailsPanel.POSTCLOSE.descr': '有关盘后时段的设置的信息',
    'InstrumentDetailsPanel.PREOPEN.descr': '有关盘前时段的设置的信息',
    'InstrumentDetailsPanel.PreviousPaymentDate.descr': '上一次收益付款日期',
    'InstrumentDetailsPanel.QuotiongCurrency.descr': '有关用于计算报价的货币的信息',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot.descr': '表示已进行交易固定返利价值中每一口数将收费/回扣（以计划货币为单位）',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot.descr': '表示已进行交易固定返利价值中每一口数将收费/回扣（以计划货币为单位）',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume.descr': '表示收费/回扣合计将按成交量的百分比计算',
    'InstrumentDetailsPanel.SessionStatus.descr': '时段状态，可用的值：开盘、收盘',
    'InstrumentDetailsPanel.SettlementDate.descr': '执行交割的日期',
    'InstrumentDetailsPanel.StrikePrice.descr': '执行期权合同所基于的标的资产的价格水平',
    'InstrumentDetailsPanel.SwapBuy.descr': '显示应用于股票代码的隔夜做多的掉期价值',
    'InstrumentDetailsPanel.SwapSell.descr': '显示应用于股票代码的隔夜做空的掉期价值',
    'InstrumentDetailsPanel.Symbol.descr': '在查找中选择的股票代码的名称',
    'InstrumentDetailsPanel.TickCoast.descr': '一个跳动点的基本资产的金额',
    'InstrumentDetailsPanel.TickSize.descr': '价格的最小变动',
    'InstrumentDetailsPanel.TradingBalance.descr': 'T+x 结算周期',
    'InstrumentDetailsPanel.TradindBlockedOnSession.descr': '显示当前时段是否阻止交易',
    'InstrumentDetailsPanel.TradingStatus.descr': '交易状态，可用的值：已打开、已关闭、交易暂停',
    'InstrumentDetailsPanel.Underlier.descr': '将交割的资产的名称。可用于期权和期货。',
    'InstrumentDetailsPanel.VAT.descr': '为每个收费操作支付的增值税',
    'InstrumentDetailsPanel.Volume.descr': '根据每笔交易的数量计算费用',
    'InstrumentDetailsPanel.Yield.descr': '当前收益价值',
    'panel.accounts.AccountValue.descr': '账户总价值',
    'panel.accounts.Asset.descr': '资产名称',
    'panel.accounts.AvailableMargin.descr': '此账户的可用资金',
    'panel.accounts.Balance.descr': '账户余额',
    'panel.accounts.BalancePlusAllRisks.descr': '账户和所有风险',
    'panel.accounts.BeginBalance.descr': '本日的净损益',
    'panel.accounts.BlockedByOrders.descr': '针对订单冻结的保证金',
    'panel.accounts.BlockedByPamm.descr': '投资作为基金的开始资本',
    'panel.accounts.CashBalance.descr': '现金余额',
    'panel.accounts.ClientType.descr': '账户所有者上次连接自（ .Net、Java、移动、Html）',
    'panel.accounts.CreditValue.descr': '交易的可用信贷资',
    'panel.accounts.CurBalance.descr': '当前权益（余额 + 未实现损益）',
    'panel.accounts.CurMargin.descr': '与余额和所有风险相关的初始保证金要求',
    'panel.accounts.CurrentFundCapital.descr': '投资作为基金的当前资本',
    'panel.accounts.Email.descr': '电子邮件地址',
    'panel.accounts.Estimate value.descr': '从其他资产计算所得价值',
    'panel.accounts.FundCapitalGain.descr': '当前资本和开始资金资本的差额',
    'panel.accounts.FundsUsedPercent.descr': '用于余额和所有风险的资金',
    'panel.accounts.InterestRate.descr': '存款的年利率',
    'panel.accounts.Leverage.descr': '账户的初始保证金杠杆',
    'panel.accounts.LockedBalance.descr': '用于进一步取款的锁定余额',
    'panel.accounts.LockedBy.descr': '经纪人已锁定（请求交易员报价）',
    'panel.accounts.Login.descr': '账户名称',
    'panel.accounts.MaintMargin.descr': '与余额和所有风险相关的维持保证金要求',
    'panel.accounts.MarginAvailableReal.descr': '账户的可用保证金',
    'panel.accounts.MarginRequirement.descr': '所有建仓的维持保证金要求',
    'panel.accounts.MarginStopOut.descr': '针对头寸和订单冻结的维持保证金',
    'panel.accounts.MarginStopTrade.descr': '如果余额小于此水平，则阻止账户进行交易',
    'panel.accounts.MarginUsedNew.descr': '针对头寸和订单冻结的初始保证金',
    'panel.accounts.MarginWarning.descr': '如果余额小于此水平，则会显示警告保证金消息',
    'panel.accounts.marginWarnPercent.descr': '保证金警告水平，%',
    'panel.accounts.MaxDailyLossLimit.descr': '账户的日净亏损的最大值',
    'panel.accounts.MaxDailyProfit.descr': '账户的日净盈利的最大值',
    'panel.accounts.MaxDrawdownLevel.descr': '指示账户可以拥有的允许的最低预期余额值',
    'panel.accounts.MaximumLot.descr': '一个订单的最大数量',
    'InstrumentDetailsPanel.Account.descr': '账户的名称',
    'panel.accounts.MaxOrderAmount.descr': '最大订单金额',
    'panel.accounts.MaxOrderCapital.descr': '一个订单的最大资本',
    'panel.accounts.MaxOrdersPerDay.descr': '每日的最大订单数',
    'panel.accounts.MaxPendingOrders.descr': '最大待定订单数',
    'panel.accounts.MaxPendingOrdersValue.descr': '用户可用的最大累计待定订单价值',
    'panel.accounts.MaxPositionQtyPerSymbol.descr': '每股票代码的一个头寸的最大数量',
    'panel.accounts.MaxPositions.descr': '最大头寸数量',
    'panel.accounts.MaxWeekLostLimit.descr': '账户的每周净亏损的最大值',
    'panel.accounts.MinMargin.descr': '针对头寸冻结的保证金',
    'panel.accounts.GrossPNL.descr': '本日的总损益',
    'panel.accounts.OptionPremiumReq.descr': '买入订单的期权费',
    'panel.accounts.OptionValue.descr': '投资组合中的当前期权价值',
    'panel.accounts.Orders.descr': '订单数',
    'panel.accounts.Owner.descr': '账户的所有人',
    'panel.accounts.Positions.descr': '头寸数',
    'panel.accounts.Profit.descr': '所有建仓的损益',
    'panel.accounts.ProfitNet.descr': '建仓的净盈利或亏损',
    'panel.accounts.Role.descr': '资金类型',
    'panel.accounts.Status.descr': '账户所有者用户状态（在线、离线）',
    'panel.accounts.Collateral.descr': '卖出空头头寸的当前抵押品价值',
    'panel.accounts.StocksOrdersReq.descr': '股票订单要求',
    'panel.accounts.StocksValue.descr': '投资组合中的当前股票价值',
    'panel.accounts.stopOutPercent.descr': '止损发生时的维持保证金要求 %',
    'panel.accounts.stopTradingPercent.descr': '止损交易水平，%',
    'panel.accounts.TodayFees.descr': '账户支付的费用金额',
    'panel.accounts.TodayRebates.descr': '经纪人为创建/删除当前日期流动性而收到/支付的所有资金合计',
    'panel.accounts.TodayTrades.descr': '本日交易数',
    'panel.accounts.TodayTurnover.descr': '当前账户每天结转资金',
    'panel.accounts.TodayVolume.descr': '本日的成交数',
    'panel.accounts.TotalMaxPositionsQty.descr': '多口数合约中所有头寸的最大合计数量',
    'panel.accounts.TrailingDrawdownLevel.descr': '账户的最高回撤水平',
    'panel.accounts.UnsettledCash.descr': '将根据 T+n 价值周期记入账户贷方的损益',
    'panel.accounts.UserGroup.descr': '用户组',
    'panel.accounts.UserID.descr': '用户 ID',
    'panel.accounts.UserLogin.descr': '用户登录',
    'panel.accounts.WithdrawalAvailable.descr': '可以提款的最大余额',
    'panel.accounts.CurrentDailyLoss.descr': '账户的当前每日净亏损限值',
    'panel.accounts.CurrentWeeklyLoss.descr': '账户的当前每周净亏损限值',
    'panel.accounts.UnrealizedLoss.descr': '账户的当前未实现亏损值',
    'panel.accounts.UnrealizedLossLimit.descr': '账户的未实现亏损最高值',
    'panel.accounts.VolumeLimitForEquities.descr': '账户的所有头寸和订单的最大汇总数量（按工具类型列出）。价值按口数/合约设置，以金额（数量 * 口数规模或数量*跳动点成本/跳动点规模）或以资金曝险为单位',
    'panel.accounts.VolumeLimitForFutures.descr': '账户的所有头寸和订单的最大汇总数量（按工具类型列出）。价值按口数/合约设置，以金额（数量 * 口数规模或数量*跳动点成本/跳动点规模）或以资金曝险为单位',
    'panel.accounts.VolumeLimitForOptions.descr': '账户的所有头寸和订单的最大汇总数量（按工具类型列出）。价值按口数/合约设置，以金额（数量 * 口数规模或数量*跳动点成本/跳动点规模）或以资金曝险为单位',
    'panel.accountDetails.Kyrrex risks.AvailableBalance.descr': '可用余额',
    'panel.accountDetails.Kyrrex risks.BlockedBalance.descr': '冻结余额',
    'panel.accountDetails.Groups.Cedro risks': '账户详情',
    'panel.accountDetails.Cedro risks.SLDTL': '余额总计',
    'panel.accountDetails.Cedro risks.UNQL': '限额总计',
    'Order.OrderStatus.STATUS_UNKNOWN': '未知',
    'Order.OrderStatus.STATUS_PENDING_NEW': '待定新',
    'Order.OrderStatus.STATUS_NEW': '已创建',
    'Order.OrderStatus.STATUS_ACCEPTED': '已接受',
    'Order.OrderStatus.STATUS_PENDING_EXECUTION': '待定执行',
    'Order.OrderStatus.STATUS_REPLACED': '已修改',
    'Order.OrderStatus.STATUS_PENDING_CANCEL': '待定取消',
    'Order.OrderStatus.STATUS_PART_FILLED': '已部分完成',
    'Order.OrderStatus.STATUS_FILLED': '已完成',
    'Order.OrderStatus.STATUS_PENDING_REPLACE': '待定修改',
    'Order.OrderStatus.STATUS_WAITING_MARKET': '等待市场',
    'Order.OrderStatus.STATUS_OFF_MARKET': '离场',
    'Order.OrderStatus.STATUS_UNPLACED': '未下单',
    'pageNavigation.nextButton': '下一步',
    'pageNavigation.backButton': '上一步',
    'pageNavigation.doneButton': '完成',
    'onBoarding.slide1.header1': '工作区区域',
    'onBoarding.slide1.text1': '工作区包含面板集、其配置设置和在屏幕上的位置。对工作区的更改会自动保存',
    'onBoarding.slide2.header1': '查看更多操作控件',
    'onBoarding.slide2.text1': '克隆 - 创建工作区的副本  重命名 - 允许您重命名工作区',
    'onBoarding.slide2.text2': '您可以通过双击工作区板来重命名工作区',
    'onBoarding.slide3.header1': '工作区锁定控制',
    'onBoarding.slide3.header2': '关闭工作区',
    'onBoarding.slide3.header3': '创建工作区',
    'onBoarding.slide3.text1': '如果工作区已锁定，则用户无法关闭面板，也无法添加一个或多个新面板',
    'onBoarding.slide3.text2': '您可以通过单击“关闭”控件来关闭工作区',
    'onBoarding.slide3.text3': '通过单击“创建”控件，您可以创建新的工作区',
    'onBoarding.slide4.header1': '按股票代码列出的链接面板',
    'onBoarding.slide4.text1': '此功能允许您在一个面板中更改股票代码时，在所有面板中自动切换股票代码',
    'onBoarding.slide5.header1': '鼠标交易',
    'onBoarding.slide5.text1': '通过使用鼠标交易，您可以下止损、限价、止损限价或二选一委托单',
    'onBoarding.slide5.text2': '当使用鼠标交易时，数量和委托时间有效期从小组件设置',
    'onBoarding.slide5.text3': '使用拖放功能，您可以在图表上修改订单和头寸，还可以添加止损/盈利或更改数量',
    'onBoarding.slide6.header1': '保证金风险',
    'onBoarding.slide6.text1': '在“订单输入”面板中，您可以在下订单前查看有关风险的其他信息',
    'onBoarding.slide6.text2': '单击隐藏/显示控件',
    'onBoarding.slide7.header1': '对接系统',
    'onBoarding.slide7.text1': '您可以使用拖放功能，在工作区上移动面板',
    'onBoarding.slide8.header1': '股票代码信息',
    'onBoarding.slide8.text1': '通过单击观察列表面板中的“信息”控件，您可以查看“股票代码信息”',
    'onBoarding.slide9.header1': '账户详细信息小组件',
    'onBoarding.slide9.text1': '您可以使用拖放功能，自定义小组件字段。',
    'onBoarding.slide10.header1': '面板链接',
    'onBoarding.slide10.text1': '您可以按账户启用面板链接',
    'onBoarding.slide10.text2': '您可以按账户启用订单和头寸过滤',
    'onBoarding.slide11.header1': '帮助',
    'onBoarding.slide11.header2': '常规设置',
    'onBoarding.slide11.text1': '要使用手册：单击“帮助”图标',
    'onBoarding.slide11.text2': '您可以通过单击“设置”控件来打开主设置菜单',
    'onBoarding.slide12.header1': '完成！',
    'IndicatorGroup.Extra': '額外',
    Didi: 'Didi',
    HiloEscandina: 'HiloEscandina',
    StopATR: 'StopATR',
    Fractals3: 'Fractals3',
    Fractals5: 'Fractals5',
    'Kaufman adaptive moving average': 'Kaufman adaptive moving average',
    'Multi EMA': 'Multi EMA',
    'Self-Adjusting Alpha with Fractals Energy': 'RSI Self-Adjusting Alpha with Fractals Energy',
    'panel.positions.confirmation.ModifyProductType': '将{2}，{3}，{4}的产品类型从{0}更改为{1}？',

    'Web.mobile.active.WorkingOrders': '活动委托',
    'Web.mobile.active.WorkingOrders.EmptyPlaceholder': '没有订单',
    'Web.mobile.active.FilledOrders': '已成交委托',
    'Web.mobile.active.HistoryOrders': '历史委托',
    'Web.mobile.active.Positions': '仓位',
    'Web.mobile.active.Positions.EmptyPlaceholder': '没有持仓',
    'Web.mobile.active.Assets': '资产',
    'Web.mobile.active.Orders': '委托',
    'Web.mobile.active.More': '更多',
    'Web.mobile.active.MamSummary': 'MAM 摘要',
    'Web.mobile.active.OptionChain': '期权链',
    'Web.mobile.active.Alerts.NoAlerts': '无警报',
    'Web.mobile.active.EventLog.NoEvents': '无事件',
    'Web.mobile.active.EventLog.DateTime': '日期和时间',
    'Web.mobile.active.MamSummary.Column.OperationType': '操作类型',
    'Web.mobile.active.MamSummary.Column.Amount': '金额',
    'Web.mobile.active.More.Header': '更多',
    'Web.mobile.active.Settings.Defaults': '默认设置',
    'Web.mobile.active.Settings.QuantityInLots': '手数',
    'Web.mobile.active.Settings.SetSlTpInOffset': '设置 SL/TP 偏移',
    'Web.mobile.active.Settings.OrderSending': '发送订单',
    'Web.mobile.active.Settings.OrderModifying': '修改订单',
    'Web.mobile.active.Settings.OrderExecuting': '执行订单',
    'Web.mobile.active.Settings.OrderCancelling': '取消订单',
    'Web.mobile.active.Settings.PositionModifying': '修改持仓',
    'Web.mobile.active.Table.Total.Profit': '总利润',
    'Web.mobile.active.NewTrades.PnL': '盈亏',
    'Web.mobile.active.OrderBook.ExecutionType': '执行类型',
    'Web.mobile.active.Assets.EmptyPlaceholder': '没有资产',
    'Web.mobile.active.Assets.DefSymbol': '默认符号',
    'Web.mobile.active.Assets.SODQuantity': 'SOD 数量',
    'Web.mobile.active.Assets.CurrentValueForSale': '可售价值',
    'Web.mobile.active.Positions.Profit': 'P/L',
    'Web.mobile.active.AccountDetails.LinkingTooltip': '此功能按帐户筛选您的仓位和订单。帐户切换在所有面板中同步。',
    'Web.mobile.active.Watchlist.EmptyPlaceholder': '没有符号',
    'Web.mobile.active.Watchlist.ListNamePlaceholder': '在此处输入名称',
    'Web.mobile.active.Watchlist.Added': '已添加到监视列表',
    'Web.mobile.active.Watchlist.Removed': '已从监视列表中移除',
    'Web.mobile.active.Watchlist.MaxReached': '已达到最大数量',
    'Web.mobile.active.ExchangesFilter.Filter': '过滤器',
    'Web.mobile.active.OrderEntry.Send': '发送订单',
    'Web.mobile.active.OrderEntry.Price': '价格',
    'Web.mobile.active.OrderEntry.TrStop': '跟踪止损',
    'Web.mobile.active.OrderEntry.SlPrice': '止损价格',
    'Web.mobile.active.OrderEntry.SlOffset': '止损偏移',
    'Web.mobile.active.OrderEntry.TrSlOffset': '跟踪止损偏移',
    'Web.mobile.active.OrderEntry.SllPrice': '止损限价价格',
    'Web.mobile.active.OrderEntry.SllOffset': '止损限价偏移',
    'Web.mobile.active.OrderEntry.TpPrice': '止盈价格',
    'Web.mobile.active.OrderEntry.TpOffset': '止盈偏移',
    'Web.mobile.active.Login.ExploreTheApp': '探索应用程序',
    'Web.mobile.active.InstrumentInfo.Price': '价格',
    'Web.mobile.active.InstrumentInfo.BreakEven': '保本价',
    'Web.mobile.active.Asset.SellExchange': '交易所',
    'Web.mobile.active.Asset.StartDayQtyMargin': 'MA 的 SOD 数量',
    'Web.mobile.active.Asset.LiquidityRate': '流动性费率 %',
    'Web.mobile.active.Asset.TodayTradedQty': '当日交易数量',
    'Web.mobile.active.Asset.AvailableQtySell': '可售数量',
    'Web.mobile.active.FilledOrder.TradeVolume': '交易量',
    'Web.mobile.active.FilledOrder.Date': '日期',
    'Web.mobile.active.FilledOrder.GrossPnL': '总损益',
    'Web.mobile.active.HistoryOrder.Date': '日期',
    'Web.mobile.active.MamSummary.Account': '帐户',
    'Web.mobile.active.MamSummary.OperationId': '操作 ID',
    'Web.mobile.active.MamSummary.InstrumentName': '工具名称',
    'Web.mobile.active.MamSummary.OpenPrice': '开盘价格',
    'Web.mobile.active.MamSummary.ClosePrice': '收盘价格',
    'Web.mobile.active.MamSummary.Side': '方向',
    'Web.mobile.active.MamSummary.Quantity': '数量',
    'Web.mobile.active.MamSummary.Buy': '买入',
    'Web.mobile.active.MamSummary.Sell': '卖出',
    'Web.mobile.active.Position.StopLoss': '止损',
    'Web.mobile.active.Position.TakeProfit': '止盈',
    'Web.mobile.active.Position.Date': '日期',
    'Web.mobile.active.Order.StopLoss': '止损',
    'Web.mobile.active.Order.TrStopOffset': 'Trailing SL offset',
    'Web.mobile.active.Order.TrStopLoss': 'SL Trailing stop',
    'Web.mobile.active.Order.TakeProfit': '止盈',
    'Web.mobile.active.Order.QuantityFilled': 'Qty. filled',
    'Web.mobile.active.Search': '搜索',
    'Web.mobile.active.CalendarPeriod.Today': '今天',
    'Web.mobile.active.CalendarPeriod.LastWeek': '上周',
    'Web.mobile.active.CalendarPeriod.Last1M': '上月',
    'Web.mobile.active.CalendarPeriod.Last3M': '过去 3 个月',
    'Web.mobile.active.CalendarPeriod.Last6M': '过去 6 个月',
    'Web.mobile.active.InstrumentDetails.6.SessionInfo': '日程安排',
    'Web.mobile.active.OrderModify.Confirmation.Title.Modify': '修改订单',
    'Web.mobile.active.OrderModify.Confirmation.Title.Cancel': '取消订单',
    'Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket': '在市场上执行订单？',
    'Web.mobile.active.OrderModify.Error.SLSmallerMin': '止损小于最小可能值',
    'Web.mobile.active.OrderModify.Error.SLGreaterMax': '止损大于最大可能值',
    'Web.mobile.active.OrderModify.Error.TPSmallerMin': '止盈小于最小可能值',
    'Web.mobile.active.OrderModify.Error.TPGreaterMax': '止盈大于最大可能值',
    'Web.mobile.active.PositionModify.Confirmation.Title': '修改仓位',
    'Web.mobile.active.PositionClose.Confirmation.Lots': '手',
    'Web.mobile.active.Lists': '列表',
    'Web.mobile.active.AccountDetails.Groups.1.AccountSummary': '帐户摘要',
    'Web.mobile.active.AccountDetails.Groups.4.TodayResult': '今日结果',
    'Web.mobile.active.AccountDetails.MarginUsedPercent': '初始保证金要求 %',
    'Web.mobile.active.AccountDetails.OptionPremiumReq': '期权保证金要求',
    'Web.mobile.active.AccountDetails.OpenGrossPL': '开放总毛利/亏损',
    'Web.mobile.active.AccountDetails.OpenPostionsNumber': '开放职位',
    'Web.mobile.active.AccountDetails.OpenOrdersNumber': '工作订单',
    'Web.mobile.active.AccountDetails.TodaysNetProfit': '今日净利润',
    'Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators': '已添加指标',
    'Web.mobile.active.Chart.Menu.ChartSyle': '图表样式',
    'Web.mobile.active.Chart.Menu.Settings': '设置',
    'Web.mobile.active.Chart.NoDataAvailable': 'No data available for {0}',
    'Web.mobile.active.TrailingStopLoss': 'Tr.',
    'Web.mobile.active.Position.GrossPnL': '总损益',
    'Web.mobile.active.Table.Total.GrossPnL': 'Total Gross P/L',
    'ChartObjectManager.Text': '对象管理器',
    'ObjectManagerItem.Name': '名称',
    'ObjectManagerItem.Remove': '删除',
    'ObjectManagerItem.Settings': '设置',
    'ObjectManagerItem.Show on chart': '在图表上显示',
    'ObjectManagerItem.Visible': '可见',
    'ObjectManagerItem.Window': '窗口',
    'ChartObjectManager.mainWindow': '主窗口',
    'ChartObjectManager.subWindow': '子窗口',
    'chart.menu.ObjectManager.RemoveAll': '全部删除',
    'chart.menu.ObjectManager.RemoveSelected': '删除选择'
};
