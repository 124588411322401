// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DayPeriods } from './DayPeriods';
import { MainTypes } from './MainTypes';
import { SubTypes } from './SubTypes';

export class SessionPeriod {
    public readonly id: number;
    public readonly description: string = '';
    public readonly dayPeriod: DayPeriods = DayPeriods.MAIN;
    public mainType: MainTypes = MainTypes.MAIN_CONTINUOUS1;
    public subType: SubTypes = SubTypes.CONTINUOUS;
    public isIntraday: boolean = false;
    public decreaseOnlyPositionCount: boolean = false;
    public allowOptionsExerciseOnLastTradeDate: boolean = false;

    private readonly begins: Date[];
    private readonly ends: Date[];

    constructor (id: number, description: string, dayPeriod: DayPeriods, begins: Date[], ends: Date[]) {
        this.id = id;
        this.description = description;
        this.dayPeriod = dayPeriod;
        this.begins = begins;
        this.ends = ends;
    }

    public GetBegin (dayOfWeek: number): Date {
        return this.begins[dayOfWeek];
    }

    public GetEnd (dayOfWeek: number): Date {
        return this.ends[dayOfWeek];
    }

    public IsMain (): boolean {
        return this.dayPeriod === DayPeriods.MAIN;
    }

    public static IsMainType (type): boolean {
        return type === MainTypes.AUCTION_OPEN ||
            type === MainTypes.MAIN_CONTINUOUS1 ||
            type === MainTypes.IN_BETWEEN_CLEARING ||
            type === MainTypes.MAIN_CONTINUOUS2 ||
            type === MainTypes.AUCTION_CLOSE ||
            type === MainTypes.CLEARING;
    }

    public isBeforeOrAfterMarket (): boolean {
        return this.dayPeriod === DayPeriods.BEFORE_MARKET || this.dayPeriod === DayPeriods.AFTER_MARKET;
    };

    public toString (): string {
        const formatTimeUTC = (date: Date): string => {
            const hours = date.getUTCHours().toString().padStart(2, '0');
            const minutes = date.getUTCMinutes().toString().padStart(2, '0');
            return `${hours}:${minutes}`;
        };

        const result: string[] = [];

        result.push('==SessionPeriod==');
        result.push(`Id: ${this.id};`);
        result.push(`DayPeriod: ${this.dayPeriod};`);
        result.push(`MainType: ${this.mainType};`);
        result.push(`Description: ${this.description};`);
        result.push('0 - Sunday; 1 - Monday; 2 - Tuesday; 3 - Wednesday; 4 - Thursday; 5 - Friday; 6 - Saturday;');

        for (let i = 0; i < this.begins.length; i++) {
            const dayOfWeek = i;
            const beginTime = this.begins[i];
            const endTime = this.ends[i];
            result.push(`${dayOfWeek} (${formatTimeUTC(beginTime)} - ${formatTimeUTC(endTime)});`);
        }

        return result.join('\n');
    }
}
