// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ControlsTypes } from '../../../Controls/UtilsClasses/FactoryConstants';
import { TerceraQuantityNumeric } from '../../../Controls/elements/TerceraQuantityNumeric';
import { InvestingQuantityNumericTemplate } from '../../templates.js';
import { closeContextMenuEvent, openContextMenu } from './InvestingMenu';

export class InvestingQuantityNumeric extends TerceraQuantityNumeric {
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.InvestingQuantityNumeric; }

    public override oninit (): void {
        super.oninit();

        this.on('openQuantityNumericMenu', this.openQuantityNumericMenu);
    }

    public openQuantityNumericMenu (ev, posX: number, posY: number): void {
        if (!this.get<boolean>('enabled')) { return; }

        const itemsMenu = this.menuItems.slice(0, 5);
        openContextMenu(posX, posY + 4, itemsMenu);
        closeContextMenuEvent.Subscribe(this.closeMenu, this);
    }

    public closeMenu (): void {
        closeContextMenuEvent.UnSubscribe(this.closeMenu, this);
        void this.set({ isActiveArrow: false });
    }
}

TerceraQuantityNumeric.extendWith(InvestingQuantityNumeric, {
    el: 'quantityNumeric',
    template: InvestingQuantityNumericTemplate,
    data: function () {
        return {
            instrument: null,
            inLots: false,
            height: '',
            // TODO. Ugly. Remove.
            defaultQuantity: null,
            noArrowCB: false,
            showArrows: true,
            isActiveArrow: false
        };
    }
});
