// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type SolidBrush } from '@shared/commons/Graphics';
import { type QuickTableEditingInfo } from './QuickTableMisc';

export class QuickTableCell {
    // Cell data
    public value: any = null;
    public formattedValue = '';

    public groupByValue = ''; // The context menu action "Group by" is processed using this value, e.g., #114165.

    // Colors
    public BackColor: string | null = null;
    public ForeColor: string | null = null;

    public BackColorBrush: SolidBrush | null = null;
    public ForeColorBrush: SolidBrush | null = null;

    public BackColorRecover: string | null = null;
    public ForeColorRecover: string | null = null;
    public isDirty = true;
    /// Произошла раскраска ячейки - через определленое время нужно сбросить цвет
    public ColoringOccured = -1;

    public textPattern: any = null;

    public CustomDrawingHandler: any = null;
    public CustomDrawingHandlerParameters: any = null;

    public TooltipKey: string = '';

    public TruncateTextOverflowAddingEllipsis = false; // нужно ли обрезать не влезающий в ячейку текст добавляя троеточие
    public QuickTableEditingInfo: QuickTableEditingInfo;
    public ReadOnly: boolean;
    public LastColoring: number;
    public FlagImg: any;
    public InstrumentTradingExchangeText: any;
    public InstrumentDescriptionText: string;
    public ToolTip: string;

    public xPadding: number = 0;

    public CanShowTooltip (): boolean {
        const isQTEINull = isNullOrUndefined(this.QuickTableEditingInfo);
        return (!isQTEINull && !this.ReadOnly) || (isQTEINull && this.ReadOnly && isValidString(this.TooltipKey));
    }

    public GetTooltipKey (): string {
        if (isNullOrUndefined(this.QuickTableEditingInfo)) { return this.TooltipKey; }
        if (isValidString(this.TooltipKey)) { return this.TooltipKey; }
        return this.QuickTableEditingInfo.GetCellTooltipKey();
    }
}
