// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCache } from '../../Commons/DataCache';
import { ExternalLinkType } from '../../Commons/cache/ExternalLink/Branding/ExternalLinkType';
import { type ExtLinkClientTab } from '../../Commons/cache/ExternalLink/ExtLinkClientTab';
import { type BaseExtLink } from '../../Commons/cache/ExternalLink/BaseExtLink';
import { type YoutubeExtLink } from '../../Commons/cache/ExternalLink/YoutubeExtLink';

class ExternalLinkManager {
    public generateSVGWithText (text: string): string {
        const svgString: string = `
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <rect x="2" y="2" rx="2" ry="2" width="20" height="20" style="fill:none;stroke:white;stroke-width:1"/>
        <text x="50%" y="50%" text-anchor="middle" dominant-baseline="middle" font-size="10" fill="#fff">${text}</text>
        </svg>
        `;

        return svgString;
    }

    public getIconAbbrName (toolName: string): string // #87369
    {
        let result = '';

        if (!toolName) { return result; }

        const splitArr = toolName.split(' ');
        const words = [];

        for (let i = 0; i < splitArr.length; i++) // защита от дебилов
        {
            if (splitArr[i]) { words.push(splitArr[i]); }
        }

        if (words.length === 1) {
            result = words[0].substr(0, 2).toUpperCase();
        } else if (words.length >= 2) {
            result = (words[0].substr(0, 1) + words[1].substr(0, 1)).toUpperCase();
        }

        return result;
    };

    public getExternalMenu (externalMenu, tool: BaseExtLink): any | null {
        if (externalMenu === null || tool === null) { return null; }
        for (const menuIt of externalMenu) {
            const text = menuIt.text;
            const clientTab = tool.clientTab;
            const alliaseTabName = tool.getLocalizedTabNameOrNull() ?? '';
            const hasAlliaseTabName = alliaseTabName !== '';
            const tabMatch = text === clientTab;
            const aliasMatch = text === alliaseTabName;

            if ((hasAlliaseTabName && aliasMatch) || (!hasAlliaseTabName && tabMatch)) { return menuIt; }
        }
        return null;
    }

    public getWMLinks (clientTabs: ExtLinkClientTab[] = []): BaseExtLink[] {
        const allowedLinkTypes = [ExternalLinkType.NONE, ExternalLinkType.LINK_TOKEN_LANG, ExternalLinkType.WEB_CONTAINER];
        return this.getLinks(clientTabs, allowedLinkTypes);
    }

    public getYoutubeLinks (): YoutubeExtLink[] {
        return DataCache.ExternalLinksCache.getYoutubeExtLinks();
    }

    private getLinks (clientTabs: ExtLinkClientTab[] = [], linkTypes: ExternalLinkType[] = []): BaseExtLink[] {
        const filteredLinks: BaseExtLink[] = [];
        const tools = DataCache.ExternalLinksCache.getExtLinks();

        for (const tool of tools) {
            if (!isNullOrUndefined(tool) &&
                (clientTabs.length === 0 || clientTabs.includes(tool.clientTab)) &&
                (linkTypes.length === 0 || linkTypes.includes(tool.brandingToolExtResource))) {
                filteredLinks.push(tool);
            }
        }

        const groupedLinks = this.groupBy(filteredLinks, link => link.brandingToolExtResource);
        const sortedLinks: BaseExtLink[] = [];

        for (const linkType of Object.keys(groupedLinks)) {
            const linksOfType = groupedLinks[linkType].sort((a, b) => a.id - b.id);
            sortedLinks.push(...linksOfType);
        }

        return sortedLinks;
    }

    private groupBy<T>(array: T[], keyGetter: (item: T) => string | number): Record<string | number, T[]> {
        return array.reduce<Record<string | number, T[]>>((result, currentItem) => {
            const key = keyGetter(currentItem);
            if (!result[key]) {
                result[key] = [];
            }
            result[key].push(currentItem);
            return result;
        }, {});
    }

    public isTabLink (link: BaseExtLink): boolean {
        const type = link.brandingToolExtResource;
        const extTypesAllowTab = [
            ExternalLinkType.NONE,
            ExternalLinkType.LINK_TOKEN_LANG,
            ExternalLinkType.AUTOCHARTIST_IFRAME,
            ExternalLinkType.WEB_CONTAINER];
        const allowTabChoise = extTypesAllowTab.includes(type);

        return allowTabChoise;
    }
}

export const externalLinkManager = new ExternalLinkManager();
