// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Control } from '../Control';
import { RangeSelectorTemplate } from '../../../templates.js';
import { type ContextHelper } from 'ractive';
import { ScrollUtils } from '../../UtilsClasses/ScrollUtils';

export class RangeSelector extends Control {
    constructor () { super(); }

    override oncomplete (): void {
        super.oncomplete();

        this.on('onChangeValue', this.onChangeValue);
        this.on('onMouseWheel', this.onMouseWheel);
        this.observe('rangeValue', (newValue: number) => { this.updateSliderBackground(newValue); });
        this.setLabelsWithLeft();
    }

    public onMouseWheel = (context: ContextHelper): void => {
        const event: any = context.event as WheelEvent;
        if (!isValidNumber(event.deltaY)) {
            return;
        }

        const isUp = ScrollUtils.IsScrollUp(event.deltaY);
        const currentMoveType = isUp ? 1 : -1;
        const value: number = this.get('rangeValue');
        const min: number = this.get('min');
        const max: number = this.get('max');
        const newValue = Math.min(max, Math.max(min, value + currentMoveType));
        this.onValueChanged(newValue);
        this.updateSliderBackground(newValue);
    };

    public onChangeValue = (event: ContextHelper): void => {
        const slider = event.node as HTMLInputElement;
        const value = parseInt(slider.value);
        this.onValueChanged(value);
        this.updateSliderBackground(value);
    };

    private updateSliderBackground (value: number): void {
        const min: number = this.get('min');
        const max: number = this.get('max');
        const percentage = (value - min) / (max - min) * 100;
        void this.set({ backgroundInPercent: percentage });
    }

    private setLabelsWithLeft (): void {
        const labels: Map<number, string> = this.get('labels');
        const max: number = this.get('max');
        const min: number = this.get('min');
        const delta = max - min;
        const labelsWithLeft: Array<{ label: string, left: number }> = [];
        labels.forEach((label, value) => {
            const left = value * 100 / delta;
            labelsWithLeft.push({ label, left });
        });

        void this.set({ labelsWithLeft });
    }

    private onValueChanged (newValue: number): void {
        this.fire('changedValue', newValue);
    }
}

Control.extendWith(RangeSelector, {
    template: RangeSelectorTemplate,
    data: function () {
        return {
            rangeValue: 0,
            min: 0,
            max: 100,
            step: 1,
            backgroundInPercent: 0,
            labels: null,
            count: 0,
            labelsWithLeft: []
        };
    }
});
