// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Execution, Side, type IBrokerConnectionAdapterHost, type IDestroyable } from '../charting_library';
import { DataCache } from '../../DataCache';
import { type NewTrade } from '../../cache/NewTrade';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { ReportType } from '../../cache/AllowedReport';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { tvch } from '../Helpers/TvConsoleHelper';
import { ReportManager } from '@shared/utils/Managers/ReportManager';
import { EReportRequestParameter, ReportRequestConfig } from '../../UtilsClasses/Report/ReportRequestConfig';

export class TvExecutionsManager implements IDestroyable {
    private readonly host: IBrokerConnectionAdapterHost;

    constructor (host: IBrokerConnectionAdapterHost) {
        this.host = host;
        this.subscribe();
    }

    public async executions (symbol: string): Promise<Execution[]> {
        const account = TvAccountHelper.getCurrentAccount();
        const startTime = new Date(0).getTime();
        const finishTime = new Date().setHours(23, 59, 59);
        const login = account?.FullAccString;
        ReportManager.reportsByComplited.set(ReportType.REPORT_TRADES, false);
        const rTrades = await ReportManager.sendRequestByDate(ReportType.REPORT_TRADES, startTime, finishTime, login);
        const executions: Execution[] = [];
        for (const rTrade of rTrades) {
            if (rTrade.Account !== account) continue;
            if (isNullOrUndefined(rTrade.Instrument)) continue;
            const execution: Execution = this.convertTradeToExecution(rTrade);
            executions.push(execution);
        }
        const filtered = executions.filter(execution => execution.symbol === symbol);
        if (tvch.isExececutionLoggingEnabled()) {
            console.warn(`History exectuions for ${account?.FullAccString}:`, filtered);
        }
        return filtered;
    }

    private subscribe (): void {
        DataCache.OnAddNewTradeEvent.Subscribe(this.onAddTradeEvent, this);
    }

    private unsubscribe (): void {
        DataCache.OnAddNewTradeEvent.Subscribe(this.onAddTradeEvent, this);
    }

    private onAddTradeEvent (dc: any/* ? */, trade: NewTrade): void {
        if (trade.Account !== TvAccountHelper.getCurrentAccount()) return;
        if (!trade.Instrument) return;

        const execution: Execution = this.convertTradeToExecution(trade);
        this.host.executionUpdate(execution);

        if (tvch.isExececutionLoggingEnabled()) {
            console.warn(`Realtime exectuion for ${trade.Account?.FullAccString}:`, execution);
        }
    }

    private convertTradeToExecution (trade: NewTrade): Execution {
        const execution: Execution = {
            id: trade.TradeId,
            symbol: TvSymbolConvertor.getFullname(trade.Instrument),
            price: trade.Price,
            qty: trade.Amount,
            side: trade.BuySell === OperationType.Buy ? Side.Buy : Side.Sell,
            time: trade.Time.getTime(),
            commission: trade.Comission
            // netAmount
        };

        return execution;
    }

    // #region IDestroyable

    destroy (): void {
        this.unsubscribe();
    }
    // #endregion IDestroyable
}
