// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { HistoryType } from '../History/HistoryType';
import { ProductType } from './ProductType';
import { InstrumentTypes, InstrumentTypesColor, InstrumentTypesShortName } from './InstrumentTypes';
import { LOCALE_EN, Resources } from '../../Localizations/Resources';
import { MathUtils } from '../MathUtils';
import { TradingMode } from './TradingMode';
import { PriceFormatter } from './PriceFormatter';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type SpreadPlan } from '../../Commons/cache/SpreadPlan';
import { type SpreadItem } from '../../Commons/cache/SpreadItem';
import { type Account } from '../../Commons/cache/Account';

class _InstrumentUtils {
    public MAX_PRECISION_ON_SERVER = 8; // #46163  - уточнил у Хоменко, база данных на сервере не поддерживает большую точность цены , для js взял с клиента

    public OpenSpread (SpreadPlan: SpreadPlan | null, instrument: Instrument): number {
        let spreadItem: SpreadItem | null = null;
        if (!isNullOrUndefined(SpreadPlan) && SpreadPlan.IsNotEmptyPlan(instrument)) { spreadItem = SpreadPlan.GetItem(instrument); }

        if (spreadItem !== null) {
            if (instrument.HistoryType === HistoryType.QUOTE_LEVEL1) {
                return spreadItem.CalcBid(instrument.BidOpen, instrument.AskOpen, instrument);
            } else if (instrument.HistoryType === HistoryType.QUOTE_ASK) {
                return spreadItem.CalcAsk(instrument.BidOpen, instrument.AskOpen, instrument);
            }
        }

        return instrument.Open;
    }

    public HighSpread (SpreadPlan: SpreadPlan | null, instrument: Instrument): number {
        let spreadItem: SpreadItem | null = null;
        if (!isNullOrUndefined(SpreadPlan) && SpreadPlan.IsNotEmptyPlan(instrument)) { spreadItem = SpreadPlan.GetItem(instrument); }

        if (spreadItem !== null) {
            if (instrument.HistoryType === HistoryType.QUOTE_LEVEL1) {
                return spreadItem.CalcBid(instrument.BidHigh, instrument.AskHigh, instrument);
            } else if (instrument.HistoryType === HistoryType.QUOTE_ASK) {
                return spreadItem.CalcAsk(instrument.BidHigh, instrument.AskHigh, instrument);
            }
        }

        return instrument.High;
    }

    public LowSpread (SpreadPlan: SpreadPlan | null, instrument: Instrument): number {
        let spreadItem: SpreadItem | null = null;
        if (!isNullOrUndefined(SpreadPlan) && SpreadPlan.IsNotEmptyPlan(instrument)) { spreadItem = SpreadPlan.GetItem(instrument); }

        if (spreadItem !== null) {
            if (instrument.HistoryType === HistoryType.QUOTE_LEVEL1) {
                return spreadItem.CalcBid(instrument.BidLow, instrument.AskLow, instrument);
            } else if (instrument.HistoryType === HistoryType.QUOTE_ASK) {
                return spreadItem.CalcAsk(instrument.BidLow, instrument.AskLow, instrument);
            }
        }

        return instrument.Low;
    }

    public PrevCloseSpread (SpreadPlan: SpreadPlan | null, instrument: Instrument): number {
        let spreadItem: SpreadItem | null = null;
        if (!isNullOrUndefined(SpreadPlan) && SpreadPlan.IsNotEmptyPlan(instrument)) { spreadItem = SpreadPlan.GetItem(instrument); }

        if (spreadItem !== null) {
            if (instrument.HistoryType === HistoryType.QUOTE_LEVEL1) {
                return spreadItem.CalcBid(instrument.BidPrevClose, instrument.AskPrevClose, instrument);
            } else if (instrument.HistoryType === HistoryType.QUOTE_ASK) {
                return spreadItem.CalcAsk(instrument.BidPrevClose, instrument.AskPrevClose, instrument);
            }
        }

        return instrument.Close;
    }

    public Get_Ticks_From_RealOffsetPrice (instrument: Instrument | null, absoluteOffset: number, optionsVariousPipsSize = -1): number {
        if (instrument == null) {
            return absoluteOffset;
        }
        if (instrument.PointSize === 0) {
            return 0.0;
        }

        if (optionsVariousPipsSize === -1 || optionsVariousPipsSize === 0) {
            optionsVariousPipsSize = instrument.PointSize;
        }

        return Number((absoluteOffset / optionsVariousPipsSize).toFixed(0)); // именно пипс тут! так правильно только для форекс
    }

    public SEPARATOR = ':';

    public GetFullName (symbol, route): string {
        if (!symbol) return '';

        return (!route || symbol.indexOf(InstrumentUtils.SEPARATOR) !== -1)
            ? symbol
            : symbol + InstrumentUtils.SEPARATOR + route;
    }

    public ParseRouteName (symbol): string {
        if (!symbol) {
            return '';
        }

        const index = symbol.lastIndexOf(InstrumentUtils.SEPARATOR);
        return index >= 0 ? symbol.substr(index + InstrumentUtils.SEPARATOR.length) : '';
    }

    public RemoveRouteName (symbol): string {
        if (!symbol) {
            return null;
        }

        const index = symbol.lastIndexOf(InstrumentUtils.SEPARATOR);
        return index >= 0 ? symbol.substr(0, index) : symbol;
    }

    public IsFullName (name: string | null): boolean {
        return name !== null && name.lastIndexOf(InstrumentUtils.SEPARATOR) > -1;
    }

    public GetFullNameUnsafe (symbol, route): string {
        return symbol + InstrumentUtils.SEPARATOR + route;
    }

    // TODO. Ugly.
    public DivideFullName (symbol):
        {
            name: string
            route: string
        } {
        let name = null;
        let route = null;
        const index = symbol.lastIndexOf(InstrumentUtils.SEPARATOR);

        if (index === -1) {
            name = symbol;
            route = '';
        } else {
            name = symbol.substr(0, index);
            route = symbol.substr(index + 1);
        }

        return { name, route };
    }

    public getAllowedProductTypeDict (instrument: Instrument | null): ProductType[] {
        if (!instrument) {
            return [];
        }

        return instrument.RiskSettings.RiskPlanSettings.availableProductTypes;
    }

    public GetLocalizedProductType (instrument: Instrument | null, productType: ProductType | string): string {
        if (!instrument) return '';
        // eslint-disable-next-line eqeqeq
        if (productType == ProductType.Delivery &&
            (instrument.isFutureOrOption() || (instrument.InstrType && instrument.InstrType === InstrumentTypes.CFD_FUTURES))) {
            return Resources.getResource('ProductType.CarryForward');
        }

        return Resources.getResource('ProductType.' + ProductType[productType]);
    }

    public getInstrumentTypeStringLocalized (type, cfd: any = undefined): string {
        if (cfd) {
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.CFDs');
        }

        switch (type) {
        case -1:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.All');
        case InstrumentTypes.FUTURES:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Futures');
        case InstrumentTypes.FOREX:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Forex');
        case InstrumentTypes.EQUITIES:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Equities');
        case InstrumentTypes.OPTIONS:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Options');
        case InstrumentTypes.CFD_FUTURES:
        case InstrumentTypes.EQUITIES_CFD:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.CFDs');
        case InstrumentTypes.INDICIES:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.indices');
        case InstrumentTypes.CRYPTO:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.crypto');
        case InstrumentTypes.PORTFOLIO:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Portfolio');
        case InstrumentTypes.INS_HISTORY:
            return Resources.getResource('general.CUSTOM');
        case InstrumentTypes.SPREADBET:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Spreadbet');
        case InstrumentTypes.BOND:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Bonds');
        case InstrumentTypes.ETF:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.ETFs');
        case InstrumentTypes.TBILL:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.TBill');
        case InstrumentTypes.SPOT:
            return InstrumentUtils.UseFuturesInsteadSpot()
                ? Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Futures')
                : Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Spot');
        case InstrumentTypes.FORWARD:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Forward');
        case InstrumentTypes.CORPORATE:
            return Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Corporate');
        default:
            return InstrumentUtils.GENERAL_STRING;
        }
    }

    public getPutCallOptionsTypeStringLocalized (instrument: Instrument | null): string { // special Option type string for WatchList's Group By #114165
        if (!instrument) {
            return null;
        }

        return instrument.isOptionSymbol
            ? (Resources.getResource(instrument.PutCall
                ? 'panel.TerceraSymbolLookupDropDownForm.Options.Put'
                : 'panel.TerceraSymbolLookupDropDownForm.Options.Call'))
            : instrument.getTypeString();
    }

    public GENERAL_STRING = 'General';
    public _useFuturesInsteadSpot: boolean | null = null;

    public UseFuturesInsteadSpot (): boolean | null {
        if (!InstrumentUtils._useFuturesInsteadSpot) {
            InstrumentUtils._useFuturesInsteadSpot = Resources.getResourceLang('panel.TerceraSymbolLookupDropDownForm.Spot', LOCALE_EN) === 'Futures';
        }

        return InstrumentUtils._useFuturesInsteadSpot;
    }

    public UseFuturesInsteadSpotShortName (): string {
        return InstrumentUtils.UseFuturesInsteadSpot()
            ? InstrumentTypesShortName[InstrumentTypes.FUTURES]
            : InstrumentTypesShortName[InstrumentTypes.SPOT];
    }

    public UseFuturesInsteadSpotColor (): string {
        return InstrumentUtils.UseFuturesInsteadSpot()
            ? InstrumentTypesColor[InstrumentTypes.FUTURES]
            : InstrumentTypesColor[InstrumentTypes.SPOT];
    }

    public ConvertPointsToTicks (instrument: Instrument | null, offsetInPoints: number): number {
        if (!instrument?.PointSize) {
            return null;
        }

        return offsetInPoints / instrument.PointSize;
    }

    public GetTradingStatus (instrument: Instrument | null): string {
        if (!instrument) {
            return Resources.getResource('general.N_A');
        }

        const route = instrument
            ? instrument.DataCache.getRouteByName(instrument.getRoute())
            : null;

        if (route == null || !route.IsTradable) {
            return Resources.getResource('InstrumentDetailsPanel.Closed') + ' ' +
                Resources.getResource('InstrumentDetailsPanel.(Indicative symbol)');
        } else if (instrument.TradingMode === TradingMode.FullyOpen) {
            return Resources.getResource('InstrumentDetailsPanel.Open') +
                (instrument.InstrType === InstrumentTypes.INDICIES
                    ? (' ' + Resources.getResource('InstrumentDetailsPanel.(Indicative symbol)'))
                    : '');
        } else if (instrument.TradingMode === TradingMode.TradingHalt) {
            return Resources.getResource('InstrumentDetailsPanel.TradingHalt');
        } else if (instrument.TradingMode === TradingMode.LiquidationOnly) {
            return Resources.getResource('InstrumentDetailsPanel.LiquidationOnly');
        } else {
            return Resources.getResource('InstrumentDetailsPanel.Closed');
        }
    }

    public formatAmountValue (value: number, instrument: Instrument | null, account?: Account, productType = ProductType.General): string {
        if (instrument == null) {
            return PriceFormatter.formatPrice(value, 2);
        }

        const prec = instrument.getLotStepPrecision(productType, account);
        if (value >= 1 && MathUtils.trunc(value) === value) {
            const lotStep = instrument.getLotStep(productType, account);
            if (MathUtils.trunc(lotStep) === lotStep) { // N0 format.
                return PriceFormatter.formatPrice(value, 0);
            } else {
                return PriceFormatter.formatPrice(value, prec);
            }
        } else {
            return PriceFormatter.formatPrice(value, prec);
        }
    }

    public getFormatPrice (instrument: Instrument | null, price: number, NAString: string): string {
        if (isNaN(price)) { // || price === -1)   // #87569
            return NAString;
        }

        return instrument
            ? instrument.formatPrice(price)
            : price.toFixed(4);
    }

    public getFormatVolume (instrument: Instrument | null, volume: number, showLots = false, NAString: string = null, productType = ProductType.General, account: Account = null): string {
        NAString = NAString ?? Resources.getResource('general.N_A');
        if (isNaN(volume) || instrument == null) {
            return NAString;
        }

        return instrument.DataCache.formatVolume(instrument, instrument.getDoubleVolume(volume, showLots), showLots, productType, account);
    }

    public ParseSearchPattern (pattern: string): string {
        if (!isValidString(pattern)) {
            return pattern;
        }
        if (pattern.startsWith('[')) {
            pattern = pattern.substring(1);
        }
        if (pattern.endsWith(']')) {
            pattern = pattern.substring(0, pattern.length - 1);
        }
        return pattern;
    }
}

export const InstrumentUtils = new _InstrumentUtils();
