// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Resources } from '../../Localizations/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { Control } from '../elements/Control';
import { TerceraQuickTreeEvents } from '../elements/QuickTree/TerceraQuickTree';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { EditPositionScreen } from '../screen/EditPositionScreen';
import { ModifyOrderScreen } from '../screen/ModifyOrderScreen';
import { TerceraMessageBox, MessageBoxType } from '../screen/TerceraMessageBox';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { DynProperty } from '../../Commons/DynProperty';
import { ApplicationInfo } from '../../Commons/ApplicationInfo';
import { TradingLockUtils } from '../../Utils/TradingLockUtils';
import { OrdersPanelBaseTemplate } from '../../templates';
import { type Account } from '../../Commons/cache/Account';
import { OrderItem } from '../cache/OrderItem';
import { type PositionItem } from '../cache/PositionItem';
import { IsAllowedResponce } from '../../Commons/IsAllowed';
import { type TradingButtonStripe, type TradingButtonItem } from '../elements/TradingButtonStripe';

export class OrdersPanelBase<ItemType extends OrderItem | PositionItem> extends ApplicationPanelWithTable<ItemType> {
    public static UPDATE_ALLOWED_ACTIONS_MILLIS = 500;
    private updateCounter: number = 0;

    public doubleClickedTimerID: any;
    public DelayProperties: any;
    public ToolbarVisible: boolean;

    protected isFiltered: boolean = false;

    constructor () {
        super();
        this.Name = 'OrdersPanelBase';
        this.topPanelHeight = 25;
        this.ToolbarVisible = false;
    }

    protected isFilteredAction (action: number): boolean { return false; }

    public getDoubleClickActionParams (): any { return undefined; }
    public getHotButtonPlacedFrom (): any { return undefined; }
    public DCEventsSubscribe (): void { }
    public DCEventsUnSubscribe (): void { }
    public createMenuItems (): any[] { return []; }
    public createTradingButtonItems (): TradingButtonItem[] { return []; }
    public updateAllowedActions (): void { };
    public isClosingAction (action): boolean { return false; }

    public override oninit (): void {
        super.oninit();
        this.updateAllowedActions = this.updateAllowedActions.bind(this);
    }

    public override oncomplete (): void {
        super.oncomplete();

        const qt = super.getQuickTable();
        const menuItems = this.createMenuItems();
        this.menuTagDict = TerceraMenu.createTagDictionary(menuItems);
        qt.setTableContextMenuItems(menuItems);

        qt.OnSelectionChanged.Subscribe(this.selectionChange, this);

        this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.onDoubleClicked.bind(this));
        qt.OnPaintedPictureButtonClick.Subscribe(this.CloseBtnClick, this);

        const btns: TradingButtonStripe = this.Controls.btns;
        btns.setItemArray(this.createTradingButtonItems());
        btns.on('tradingAction', this.onTradingButtonClick.bind(this));

        if (this.DelayProperties) {
            btns.setBtnsVisibility(this.DelayProperties);
            this.DelayProperties = null;
        }
        this.UpdateToolbarVisibility(this.ToolbarVisible);
        this.localize();
        this.DCEventsSubscribe();
        this.updateAllowedActions();
    }

    public getAccount (): Account {
        const qt = super.getQuickTable();
        const rowItem = qt?.getSelectedTableItems()[0];
        if (rowItem) {
            return rowItem.GetCurrentAccount();
        } else {
            return undefined;
        }
    }

    public action (action, confirm, placedFrom, numericLinkName): boolean {
        if (TradingLockUtils.TradingLock.tradingLocked) {
            return false;
        }

        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return false;
        }

        if (this.isClosingAction(action) && this.IsClosingForbidden(quickTable.selectedRowIds)) {
            return false;
        }

        return true;
    }

    public override TickAsync (): void {
        super.TickAsync();
        this.updateCounter++;
        if (this.updateCounter % 10 === 0) {
            this.updateAllowedActions(); this.updateCounter = 0;
        }
    }

    public ShowToolbarStateChange (menuItem): void {
        this.UpdateToolbarVisibility(menuItem.checked);
    }

    public UpdateToolbarVisibility (state: boolean): void {
        void this.set('visibleContainerBtn', state);
        this.Controls.btns.set('visible', state);
        this.topPanelHeight = state ? 25 : 0;
        super.layoutTable();
        this.ToolbarVisible = state;
    }

    public CloseBtnClick (data): void { /* virtual */ }
    public IsClosingAllowed (data): IsAllowedResponce { return IsAllowedResponce.AllowedResponce(); }
    public IsClosingForbidden (selectedOrderIdArr): boolean {
        const isAllowed = this.IsClosingAllowed(selectedOrderIdArr);

        if (isNullOrUndefined(isAllowed)) { return false; }
        if (isAllowed.Allowed) {
            return false;
        }

        const title = Resources.getResource('MessageBox.ThemeChanged.Title');
        const reason = isAllowed.ReasonText;
        TerceraMessageBox.Show(title, reason, MessageBoxType.Warning, null, null, false, true);
        return true;
    }

    public onDoubleClicked (): void {
        const params = this.getDoubleClickActionParams();

        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }
        const selectedOrderIdArr = quickTable.selectedRowIds;

        if (this.IsClosingForbidden(selectedOrderIdArr)) {
            return;
        }

        let screen = ModifyOrderScreen.getScreenByOrderID(selectedOrderIdArr[0]);

        if (isNullOrUndefined(screen)) {
            const qtItem = quickTable.getItemById(selectedOrderIdArr[0]);
            let posID;
            if (qtItem?.item instanceof OrderItem) {
                posID = qtItem.item.FOrder.PositionId;
            } // достаем PositionId
            screen = EditPositionScreen.getScreenByPositionID(posID); // чтобы найти открытый EditPositionScreen при клике на S/L(T/P) в панеле Working orders
        }

        if (!isNullOrUndefined(screen) && !screen.NotSkipNextTime) // TODO !!!
        {
            screen.NotSkipNextTime = true; // ЕБАНЫЙ КОСТЫЛЬ
            return;
        }

        const timerID = this.doubleClickedTimerID;

        if (timerID) {
            clearTimeout(timerID);
        }

        this.action(params.action, params.confirm, params.placedFrom, undefined);
    }

    public onMouseDown (event, isHeader): void {
        if (Control.IsEventProcessed(event)) { return; }

        const btns = this.Controls.btns;

        const offsetY = event.original.offsetY;
        if (offsetY <= 0 || offsetY >= 30 || !btns) {
            super.onMouseDown(event, isHeader);
            Control.ProcessEvent(event);
            return;
        }

        if (btns && !btns.get('visible')) return;

        btns.onMouseDown(event, isHeader);
    }

    public onTradingButtonClick (context, action): void {
        this.action(action, false, this.getHotButtonPlacedFrom(), undefined);
    }

    public selectionChange (): void {
        const qt = super.getQuickTable();
        const selectedRowsId = qt.selectedRowIds;
        if (isNullOrUndefined(selectedRowsId)) return;

        let insId = '';
        // let accId = ''

        if (selectedRowsId.length > 0) {
            const row = qt.rows[selectedRowsId[0]];
            const item = row ? row.item : null;
            if (item) {
                const ins = item.GetCurrentInstrument();
                if (ins) insId = ins.GetInteriorID();

                // let acc = item.GetCurrentAccount()
                // if (acc) accId = acc.BstrAccount
            }
        }

        this.symbolLink_Out(false, insId);
        // this.accountLink_Out(false, accId)
        this.updateAllowedActions();
    }

    public symbolLink_Out (newSubscriber, instrument): void {
        if (!instrument) {
            return;
        }

        const color = super.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument, newSubscriber);
        }
    }

    public getSelectedInstrumentID (): string {
        const ins = super.getInstrument();
        let result = '';

        if (ins) {
            result = ins.GetInteriorID();
        }

        return result;
    }

    public localize (): void {
        super.localize();
        const menuTagDict = this.menuTagDict;
        if (!menuTagDict) {
            return;
        }

        for (const key in menuTagDict) {
            const menuItem = menuTagDict[key];
            menuItem.text = Resources.getResource(menuItem.locKey);
        }
    }

    public Properties (): DynProperty[] {
        const properties = super.Properties();

        const btns = this.Controls.btns;
        if (!isNullOrUndefined(btns)) {
            const btnProp = btns.getBtnsVisibility();
            properties.push(new DynProperty('BtnsVisibility', JSON.stringify(btnProp), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        properties.push(new DynProperty('ToolbarVisible', this.ToolbarVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);
        let btnsVisibility = DynProperty.getPropValue(newProperties, 'BtnsVisibility');
        if (btnsVisibility) {
            try {
                btnsVisibility = JSON.parse(btnsVisibility);
            } catch (ex) {
                ErrorInformationStorage.GetException(ex);
                btnsVisibility = [];
            }
            if (this.Controls.btns) {
                this.Controls.btns.setBtnsVisibility(btnsVisibility);
            } else {
                this.DelayProperties = btnsVisibility;
            }
        }

        const toolbarVisible = DynProperty.getPropValue(newProperties, 'ToolbarVisible');
        if (toolbarVisible != null) {
            this.ToolbarVisible = toolbarVisible;
        }
    }

    public dispose (): void {
        super.dispose();
        this.DCEventsUnSubscribe();
    }
}

ApplicationPanelWithTable.extendWith(OrdersPanelBase, {
    data: function () {
        return {
            isAllowPadding: true,
            isAccountLinkShow: true,
            isSymbolLinkShow: true,
            isShowExploreTheAppLink: ApplicationInfo.isExploreMode
        };
    },
    partials: {
        bodyPartial: OrdersPanelBaseTemplate
    }
});
