// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AuthenticationType } from '../AuthenticationType';
import { type IAuthenticationService } from '../IAuthenticationService';
import { AuthenticationResponseType } from '../AuthenticationResponseType';
import { UUID } from '../../UUID';
import { AuthenticationResponseResult } from '../AuthenticationResponseResult';

export class VelocityAuthenticationService implements IAuthenticationService {
    private readonly _url;
    private readonly _clientId;
    private readonly _scopes = 'openid+email';
    private readonly _responseType = 'code';
    private readonly _uuid = UUID.generateUUIDv5();

    constructor (url: string, clientId: string) {
        this._url = url;
        this._clientId = clientId;
    }

    get uuid (): string {
        return this._uuid;
    }

    get authenticationType (): AuthenticationType {
        return AuthenticationType.Velocity;
    }

    get authenticationAddress (): string {
        let currentUrl = window.location.origin;
        if (currentUrl.charAt(currentUrl.length - 1) === '/') {
            currentUrl = currentUrl.slice(0, -1);
        }
        return `${this._url}client_id=${this._clientId}&redirect_uri=${encodeURIComponent(currentUrl)}&scope=${this._scopes}&response_type=${this._responseType}&state=${this._uuid}`;
    }

    get text (): string {
        const text = 'Login with Velocity';
        return text;
    }

    processResponse (response: string): AuthenticationResponseResult {
        const queryParams = new URLSearchParams(response);
        const error = queryParams.get('error');
        const errorDescription = queryParams.get('error_description');

        if (isValidString(error)) {
            return new AuthenticationResponseResult(AuthenticationResponseType.Error, `${error}: ${errorDescription}`);
        }

        const code = queryParams.get('code');

        return new AuthenticationResponseResult(AuthenticationResponseType.Success, code);
    }
}
