// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type ProductType } from '../../../../Utils/Instruments/ProductType';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ModifyPositionMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_MODIFY_POSITION, fieldSet);
    }

    public setPositionId (positionId: bigint): void {
        this.setFieldValue(FieldsFactory.FIELD_POSITION_ID, positionId);
    }

    public setProductType (productType: ProductType): void {
        this.setFieldValue(FieldsFactory.FIELD_PRODUCT_TYPE, productType);
    }

    public setQuantity (amount: number): void {
        this.setFieldValue(FieldsFactory.FIELD_AMOUNT, amount);
    }
}
