// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { PFixFieldSet } from '../Fields/PFixFieldSet';

export class AccountOperationStatisticGroup extends PFixFieldSet {
    constructor (pfixFieldSet?: PFixFieldSet) {
        super(FieldsFactory.ACCOUNT_OPERATION_STATISTIC_GROUP, null, pfixFieldSet);
    }

    public getOperationType (): number {
        return this.GetValue(FieldsFactory.FIELD_OPERATION_TYPE2);
    }

    public setOperationType (operationType: number): void {
        this.SetField(FieldsFactory.FIELD_OPERATION_TYPE2, operationType);
    }

    public getAmount (): number {
        return this.GetValue(FieldsFactory.FIELD_AMOUNT);
    }

    public setAmount (amount: number): void {
        this.SetField(FieldsFactory.FIELD_AMOUNT, amount);
    }
}
