// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
interface IMenuItem {
    text: string
    checked: boolean
    canCheck: boolean
    event: (item: any) => void
}

export class ComboBoxController {
    public updateContextMenuHandler: any;
    public exchanges: any;
    public menuItems: IMenuItem[];

    constructor (updateContextMenuHandler) {
        this.updateContextMenuHandler = updateContextMenuHandler;
    }

    public setMenuItems (exchanges): void {
        this.exchanges = exchanges;
        this.menuItems = this.createMenuItems(exchanges);
    }

    public getMenuItems (): IMenuItem[] {
        return this.menuItems;
    }

    public createMenuItems (exchanges): IMenuItem[] {
        const items = [{
            text: 'All',
            checked: true,
            canCheck: true,
            event: (item) => { this.checkedChanged(item); }
        }];

        for (const exch in exchanges) {
            items.push({
                text: exch,
                checked: true,
                canCheck: true,
                event: (item) => { this.checkedChanged(item); }
            });
        }

        return items;
    }

    public checkedChanged (item): void {
        if (!item) {
            return;
        }

        const isAll = item.text === 'All';
        if (isAll) {
            this.setAll(item.checked);
            return;
        }

        this.updateAllSelect();
    }

    public setAll (value): void {
        for (let i = 0; i < this.menuItems.length; i++) {
            this.menuItems[i].checked = value;
        }

        if (this.updateContextMenuHandler) {
            this.updateContextMenuHandler(this.menuItems);
        }
    }

    public updateAllSelect (): void {
        let needAllSelect = true;
        for (let i = 1; i < this.menuItems.length; i++) {
            needAllSelect = needAllSelect && this.menuItems[i].checked;
        }
        this.menuItems[0].checked = needAllSelect;

        if (this.updateContextMenuHandler) {
            this.updateContextMenuHandler(this.menuItems);
        }
    }
}
