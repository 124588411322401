// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InfoWindowItem } from './InfoWindowItem';

export class InfoWindowDescriptionItem extends InfoWindowItem {
    public get Label (): string { return this.label; }
    public set Label (value: string) { this.label = value; }

    public get Value (): string { return this.formattedValues[0]; }
    public set Value (value: string) { this.formattedValues = [value]; }

    public NumberValue: number = null; // exact value before formatting (By now, it's implemented only for InfoWindowItemType.Change)

    public Font: any = null;

    public get Visible (): boolean { return this.isVisible; }
    public set Visible (value: boolean) { this.isVisible = value; }

    public Allowed: boolean = true;
    public curLabelLabelWidth: number = 0;
    public curValueWidth: number = 0;

    get LabelWidth (): number {
        return this.curLabelLabelWidth;
    }

    get ValueWidth (): number {
        return this.curValueWidth;
    }

    public SetValueWidth (newValueWidth: number, forceReset?: boolean): number {
        forceReset = forceReset || false;

        let w = Math.max(newValueWidth, 10); // min is 10 px
        w = Math.min(w, 300); // max is 300

        if (this.curValueWidth < w || forceReset) {
            this.curValueWidth = w;
        }

        return this.curValueWidth;
    }

    public SetLabelWidth (newLabelWidth: number, forceReset?: boolean): number {
        forceReset = forceReset || true;

        let w = Math.max(newLabelWidth, 10); // min is 10 px
        w = Math.min(w, 300); // max is 300

        if (this.curLabelLabelWidth < w || forceReset) {
            this.curLabelLabelWidth = w;
        }

        return this.curLabelLabelWidth;
    }
}
