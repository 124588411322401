// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MAMode } from "../../IndicatorConstants";
import { iBuildInIndicator } from "../../iBuildInIndicator";

export class iMAEx extends iBuildInIndicator
{
    constructor(period: number, maMethod: number, maShift: number, priceType: number)
    {
        super(1);
        this.FPriceType = priceType;
        this.FMaMethod = maMethod;
        this.FMaPeriod = period;

        this.FData.get(0).Shift = maShift
    }

    public FMaMethod: number;
    public FMaPeriod: number;

    public get SeparateWindow(): any
    {
        return false;
    }

    public get MAPeriod(): any
    {
        return this.FMaPeriod
    }

    public get MaMethod(): any
    {
        return this.FMaMethod
    }

    public get Name(): any
    {
        return 'iMAEx'
    }

    public get Key(): any
    {
        return this.DefaultKey
            + this.MAPeriod
            + this.MaMethod
            + this.FPriceType
    }

    public OnQuote(ci, callBound, callFromRefresh = false): void
    {
        if (this.FMaPeriod <= 0)
            return

        switch (this.FMaMethod) {
            case MAMode.SMA:
                this.OnSMA()
                break

            case MAMode.EMA:
                this.OnEMA()
                break

            case MAMode.SMMA:
                this.OnSMMA()
                break

            case MAMode.LWMA:
                this.OnLWMA()
                break
        }
    }

    private OnSMA(): void
    {
        if (!this.FParent)
            return;
        if (this.FCount >= this.FMaPeriod) {
            let summa = 0 // Sum of prices
            for (let period = 0; period < this.FMaPeriod; period++)
                // Adding bar's price to the summa
                summa += this.GetPrice(this.FPriceType, period)
            // Setting the current value of the indicator
            this.SetValue(0, 0, summa / this.FMaPeriod)
        }
    }

    private OnEMA(): void
    {
        // Checking, if current amount of bars
        // more, than period of moving average. If it is
        // then the calculation is possible
        if (!this.FParent)
            return;
        let price = this.GetPrice(this.FPriceType, 0)
        if (this.FCount > this.FMaPeriod) {
            // Calculation of a coefficient
            let k = 2 / (this.FMaPeriod + 1)
            // Getting previous EMA
            let prevEMA = this._GetValue(0, 1)
            let s = prevEMA + k * (price - prevEMA)
            this.SetValue(0, 0, s)
        }
        else if (this.FCount === this.FMaPeriod)
            this.OnSMA();
    }

    private OnSMMA(): void
    {
        // Checking, if current amount of bars 
        // more, than period of moving average. If it is
        // then the calculation is possible
        if (!this.FParent)
            return;
        if (this.FCount > this.FMaPeriod)
        {
            let prevMa = this._GetValue(0, 1)
            // Setting the current value of the indicator
            this.SetValue(0, 0, (prevMa * (this.FMaPeriod - 1) + this.GetPrice(this.FPriceType, 0)) / this.FMaPeriod)
        }
        else if (this.FCount === this.FMaPeriod)
            this.OnSMA();
    }

    private OnLWMA(): void
    {
        // Checking, if current amount of bars 
        // more, than period of moving average. If it is
        // then the calculation is possible
        if (!this.FParent)
            return;
        if (this.FCount >= this.FMaPeriod)
        {
            let numerator = 0 // Numerator of the rate
            let denominator = 0 // Denominator of the rate
            for (let i = 0; i < this.FMaPeriod; i++)
            {
                let weight = this.FMaPeriod - i
                numerator += weight * this.GetPrice(this.FPriceType, i)
                denominator += weight
            }
            // Setting the current value of the indicator
            if (denominator !== 0)
                this.SetValue(0, 0, numerator / denominator)
        }
    }
}