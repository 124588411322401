// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectPortfolioRealtimeStatisticsMessage, PortfolioAllocation } from '../../../../Utils/DirectMessages/DirectPortfolioRealtimeStatisticsMessage';
import { PortfolioRequestType } from '../../../../Utils/Portfolio/PortfolioRequestType';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { MessageProcessorBase } from './MessageProcessorBase';

export class PortfolioRealtimeStatisticsMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectPortfolioRealtimeStatisticsMessage[] {
        const message = new DirectPortfolioRealtimeStatisticsMessage();

        message.Type = fieldSet.GetValue(FieldsFactory.FIELD_TYPE);
        message.Account = fieldSet.GetValue(FieldsFactory.FIELD_ACCOUNT_ID);

        if (message.Type === PortfolioRequestType.PORTFOLIO_ALLOCATION) {
            const groups = fieldSet.GetGroups(FieldsFactory.PERCENT_INSTRUMENT_ROUTE_DAY_ENTRY_GROUP);
            const messageGroups = [];

            for (let i = 0; i < groups.length; i++) {
                const group = groups[i];
                const mGroup = new PortfolioAllocation();
                mGroup.percent = group.GetValue(FieldsFactory.FIELD_PERCENT);
                // date = group.GetValue(FieldsFactory.FIELD_DATE),     // если тип статистики = 13 -> Поле FIELD_DATE в группе всегда будет отсутствовать.
                mGroup.tradableInsID = group.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
                mGroup.routeID = group.GetValue(FieldsFactory.FIELD_ROUTE_ID);

                messageGroups.push(mGroup);
            }

            message.Groups = messageGroups;
        }

        if (message.Type === PortfolioRequestType.TOTAL_RETURN) {
            message.TotalReturn = fieldSet.GetValue(FieldsFactory.FIELD_TOTAL_RETURN);
        }

        return [message];
    }
}
