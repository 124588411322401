// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Message } from '../../../../Utils/DirectMessages/Message';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { PFixMessage } from './PFixMessage';

export class SOAPRespMessage extends PFixMessage {
    constructor (fieldSet: PFixFieldSet) {
        super(Message.CODE_PFIX_SOAP_RESP, fieldSet);
    }
}
