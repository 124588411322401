// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type ReportType } from '../../cache/AllowedReport';

export enum EReportRequestParameter {
    FromDate = 'fromDate',
    ToDate = 'toDate',
    Begin = 'begin',
    End = 'end',
    Login = 'login',
    UserId = 'userid',
    Instruments = 'instruments',
    InstrumentID = 'InstrumentId',
    AccountID = 'AccountId',
    OrderId = 'OrderId',
    PositionId = 'PositionId',
    ShowLots = 'IsShowLots',
    ReportType = 'reporttype',
    Asset = 'asset',
    Types = 'Types',
    UseLanguageAliases = 'useLanguageAliases',
    FromRecordId = 'fromRecordId',
    RecordsNumber = 'recordsNumber'
}

export class ReportRequestConfig {
    public reportType: ReportType;
    public isTechnical: boolean = true;
    public params = new Map<EReportRequestParameter, string>();

    constructor (reportType: ReportType, isTechnical: boolean = true) {
        this.reportType = reportType;
        this.isTechnical = isTechnical;
    }

    public getParameter (key: EReportRequestParameter): string | null {
        return this.params.get(key) ?? null;
    }

    public addParameter (key: EReportRequestParameter, value: string | null): void {
        if (!isNullOrUndefined(value)) {
            this.params.set(key, value);
        }
    }
}
