// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Side, OrderType as tvOrderType, OrderStatus as tvOrderStatus, type OrderDuration } from '../charting_library';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { OrderStatus } from '../../../Utils/Trading/OrderStatus';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderTif, OrderTifMap } from '../../../Utils/Trading/OrderTifEnum';

export class _TvOrderConvertor {
    public getTvOrderType (orderType: number): tvOrderType {
        switch (orderType) {
        case OrderType.Market:
            return tvOrderType.Market;
        case OrderType.Stop:
        case OrderType.SLTPStop:
        case OrderType.TRStop:
            return tvOrderType.Stop;
        case OrderType.Limit:
        case OrderType.SLTPLimit:
            return tvOrderType.Limit;
        case OrderType.StopLimit:
            return tvOrderType.StopLimit;

        case OrderType.Manual:
        case OrderType.OCO:
        default:
            throw new Error(`[TvOrderConvertor::getOrderType]: OrderType ${orderType} not supported`);
        }
    }

    public getRealOrderType (orderType: tvOrderType): number {
        switch (orderType) {
        case tvOrderType.Market:
            return OrderType.Market;
        case tvOrderType.Stop:
            return OrderType.Stop;
        case tvOrderType.Limit:
            return OrderType.Limit;
        case tvOrderType.StopLimit:
            return OrderType.StopLimit;

        // OrderType.SLTPStop:
        // OrderType.TRStop:
        // case OrderType.SLTPLimit:
        // OrderType.Manual:
        // OrderType.TRStop:
        // OrderType.OCO:
        default:
            throw new Error(`[TvOrderConvertor::getRealOrderType]: OrderType ${orderType} not supported`);
        }
    }

    public getTvOrderStatus (orderStatus: number): tvOrderStatus {
        switch (orderStatus) {
        case OrderStatus.PENDING_NEW:
        case OrderStatus.WAITING_MARKET:
        case OrderStatus.OFF_MARKET:
            return tvOrderStatus.Placing;
        case OrderStatus.NEW:
            return tvOrderStatus.Working;
        case OrderStatus.CANCELED:
            return tvOrderStatus.Canceled;
        case OrderStatus.FILLED:
            return tvOrderStatus.Filled;
        case OrderStatus.REFUSED:
            return tvOrderStatus.Rejected;

            // tvOrderStatus.Inactive - уточнить у аналитика

        default:
            return tvOrderStatus.Working;
        }
    }

    public getTvOrderSide (side: number): Side {
        return side === OperationType.Buy ? Side.Buy : Side.Sell;
    }

    public getTvOrderDuration (tif: number, expiration: Date): OrderDuration {
        if (tif === OrderTif.GTD) {
            return {
                type: OrderTifMap[OrderTif.GTD],
                datetime: expiration.getTime()
            };
        }

        const duration: OrderDuration = {
            type: OrderTifMap[tif]
        };

        return duration;
    }
}

export const TvOrderConvertor = new _TvOrderConvertor();
