// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum AccessType {
    User = 1,
    Technical = 2,
    Both = 3
}

export enum ParamType {
    GroupPanel = 0,
    TextBox = 1,
    DateBox = 2,
    AccountLookup = 3,
    UsersLookup = 4,
    InstrumentsLookup = 5,
    CheckBox = 6,
    Period = 7,
    CheckBoxGroup = 8,
    AssetComboBox = 25,
    AccountLookupOnlyOneSelect = 26,
    AssetComboBoxWithoutAll = 29
}
