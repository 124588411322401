// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

/* TODO: Remove, as it appears to be deprecated. */

import { Resources } from '@shared/localizations/Resources';
import { AlertSubImage, AlertType, AlertConditionMap, AlertTypeMap } from '@shared/utils/Alert/AlertConstants';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { ThemeManager } from '../misc/ThemeManager';
import { DynProperty } from '@shared/commons/DynProperty';
import { type Alert } from '@shared/commons/cache/Alert';

export class AlertItem extends BaseItem {
    private readonly alert: Alert;

    static readonly INSTRUMENT_COL_INDEX = 0;
    static readonly CONDITION_COL_INDEX = 1;
    static readonly ACCOUNT_COL_INDEX = 2;
    static readonly EDIT_BUTTONS_COL_INDEX = 3;

    static readonly columnsHeaderLocKey = {
        [AlertItem.INSTRUMENT_COL_INDEX]: 'general.Instrument',
        [AlertItem.CONDITION_COL_INDEX]: 'screen.Alerts.TableItem.Condition',
        [AlertItem.ACCOUNT_COL_INDEX]: 'AdditionalProperty.Account',
        [AlertItem.EDIT_BUTTONS_COL_INDEX]: ''
    };

    static readonly columnsParams: ColumnParams[] = [
        new ColumnParams(AlertItem.columnsHeaderLocKey[AlertItem.INSTRUMENT_COL_INDEX], 75, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams(AlertItem.columnsHeaderLocKey[AlertItem.CONDITION_COL_INDEX], 100, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams(AlertItem.columnsHeaderLocKey[AlertItem.ACCOUNT_COL_INDEX], 50, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams(AlertItem.columnsHeaderLocKey[AlertItem.EDIT_BUTTONS_COL_INDEX], 50, QuickTableColumnType.COL_DEFAULT, false, true, false)
    ];

    public readonly CellForeColorMap = {};
    public readonly QuickTableEditingInfoMap = {};

    constructor (alert: Alert) {
        super();
        this.alert = alert;
        super.ItemId = alert?.AlertId;

        this.CellForeColorMap[AlertItem.INSTRUMENT_COL_INDEX] = ThemeManager.CurrentTheme.TableGoldColor;

        this.QuickTableEditingInfoMap[AlertItem.ACCOUNT_COL_INDEX] = new QuickTableEditingInfo(DynProperty.TRUNCATE_TEXT_ADDING_ELLIPSIS);
        this.QuickTableEditingInfoMap[AlertItem.CONDITION_COL_INDEX] = new QuickTableEditingInfo(DynProperty.TRUNCATE_TEXT_ADDING_ELLIPSIS);
        this.QuickTableEditingInfoMap[AlertItem.EDIT_BUTTONS_COL_INDEX] = new QuickTableEditingInfo(DynProperty.ALERT_BUTTONS_GROUP);
    }

    getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        const formattedValue = value != null ? value.toString() : Resources.getResource('general.N_A');
        return new ColumnData(value, formattedValue);
    }

    getColumnValue (column: number): any {
        const alert = this.alert;
        const instrument = alert.Instrument;
        const account = alert.Account;

        switch (column) {
        case AlertItem.INSTRUMENT_COL_INDEX:
            return instrument.DisplayName();

        case AlertItem.CONDITION_COL_INDEX:
            return `${Resources.getResource('panel.watchlist.' + AlertTypeMap[alert.AlertType])} ${Resources.getResource('screen.Alerts.Condition.' + AlertConditionMap[alert.Condition])} ${this.formatConditionValue(alert)}`;

        case AlertItem.ACCOUNT_COL_INDEX:
            return account != null ? account.toString() : '';

        case AlertItem.EDIT_BUTTONS_COL_INDEX:
            return '';

        default:
            return null;
        }
    }

    private formatConditionValue (alert: any): string {
        if (alert.AlertType === AlertType.CHANGE) {
            return alert.Value.toFixed(2);
        } else if (alert.AlertType === AlertType.VOLUME) {
            return alert.Value.toString();
        } else {
            return alert.Instrument.formatPrice(alert.Value);
        }
    }

    static readonly ListMenuIndex = Object.values(AlertItem.columnsHeaderLocKey);

    static GetMenuIndex (headerKey: string): number {
        const index = AlertItem.ListMenuIndex.indexOf(headerKey);
        return index !== -1 ? index : 1000;
    };

    ColumnCount (): number {
        return AlertItem.columnsParams.length;
    }

    GetColumnParams (column: number): ColumnParams {
        const params = AlertItem.columnsParams[column];
        params.MenuIndex = AlertItem.GetMenuIndex(params.HeaderKey);
        return params;
    }

    GetImage (image: number, hover: boolean): any {
        const theme = ThemeManager.CurrentTheme;
        switch (image) {
        case AlertSubImage.PLAY_STOP:
            return this.getImageByAlertStatus(theme, hover);
        case AlertSubImage.UPDATE:
            return hover ? theme.alert_update_hover : theme.alert_update;
        case AlertSubImage.CLOSE:
            return hover ? theme.alert_close_hover : theme.alert_close;
        default:
            return null;
        }
    }

    private getImageByAlertStatus (theme: any, hover: boolean): any {
        const active: boolean = this.alert.Active;
        return active ? (hover ? theme.alert_stop_hover : theme.alert_stop) : (hover ? theme.alert_play_hover : theme.alert_play);
    }
}
