// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PanelNames } from '../../Controls/UtilsClasses/FactoryConstants';
import { PanelDisplayMode } from './PanelDisplayMode';
import { RulesSet } from '../../Utils/Rules/RulesSet';
import { DataCache } from '../DataCache';
import { type Account } from '../cache/Account';

class _CustomerAccess {
    GetPanelsVisibility (): object {
        const result = {};

        result[PanelNames.AssetBalancesPanel] = this.GetDisplayMode(PanelNames.AssetBalancesPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.ChartPanel] = this.GetDisplayMode(PanelNames.ChartPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.ProductsPanel] = this.GetDisplayMode(PanelNames.ProductsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.InformerPanel] = this.GetDisplayMode(PanelNames.InformerPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.AlertPanel] = this.GetDisplayMode(PanelNames.AlertPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.MarketDepthPanel] = this.GetDisplayMode(PanelNames.MarketDepthPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.TimeSalesPanel] = this.GetDisplayMode(PanelNames.TimeSalesPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.PositionsPanel] = this.GetDisplayMode(PanelNames.PositionsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.OrdersPanel] = this.GetDisplayMode(PanelNames.OrdersPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.OrderHistoryPanel] = this.GetDisplayMode(PanelNames.OrderHistoryPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.FilledOrdersPanel] = this.GetDisplayMode(PanelNames.FilledOrdersPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.SymbolInfoPanel] = this.GetDisplayMode(PanelNames.SymbolInfoPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.NewAccountDetailsPanel] = this.GetDisplayMode(PanelNames.NewAccountDetailsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.EventsPanel] = this.GetDisplayMode(PanelNames.EventsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.TradingCentral] = this.GetDisplayMode(PanelNames.TradingCentral) === PanelDisplayMode.Allowed;
        result[PanelNames.IdeasPanel] = this.GetDisplayMode(PanelNames.IdeasPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.AssetsPanel] = this.GetDisplayMode(PanelNames.AssetsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.PositionsBalancePanel] = this.GetDisplayMode(PanelNames.PositionsBalancePanel) === PanelDisplayMode.Allowed;
        result[PanelNames.NewsPanel] = this.GetDisplayMode(PanelNames.NewsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.RiskCalculator] = this.GetDisplayMode(PanelNames.RiskCalculator) === PanelDisplayMode.Allowed;
        result[PanelNames.MarketConsensusPanel] = this.GetDisplayMode(PanelNames.MarketConsensusPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.NotificationsPanel] = this.GetDisplayMode(PanelNames.NotificationsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.VideoWidget] = this.GetDisplayMode(PanelNames.VideoWidget) === PanelDisplayMode.Allowed;
        result[PanelNames.TradingViewChartPanel] = this.GetDisplayMode(PanelNames.TradingViewChartPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.SavedOrdersPanel] = this.GetDisplayMode(PanelNames.SavedOrdersPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.GainersLosersPanel] = this.GetDisplayMode(PanelNames.GainersLosersPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.HighLowPanel] = this.GetDisplayMode(PanelNames.HighLowPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.HeatmapPanel] = this.GetDisplayMode(PanelNames.HeatmapPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.GridPanel] = this.GetDisplayMode(PanelNames.GridPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.OptionMasterPanel] = this.GetDisplayMode(PanelNames.OptionMasterPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.OptionAnalyzerPanel] = this.GetDisplayMode(PanelNames.OptionAnalyzerPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.OptionVolatilityLabPanel] = this.GetDisplayMode(PanelNames.OptionVolatilityLabPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.OptionPositionsPanel] = this.GetDisplayMode(PanelNames.OptionPositionsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.OptionOrdersPanel] = this.GetDisplayMode(PanelNames.OptionOrdersPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.OptionPaperPositionsPanel] = this.GetDisplayMode(PanelNames.OptionPaperPositionsPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.StatementPanel] = this.GetDisplayMode(PanelNames.StatementPanel) === PanelDisplayMode.Allowed;
        result[PanelNames.CopyTradingPanel] = this.GetDisplayMode(PanelNames.CopyTradingPanel) === PanelDisplayMode.Allowed;

        return result;
    }

    GetDisplayMode (panelType: PanelNames, account: Account | null = null): PanelDisplayMode {
        switch (panelType) {
        case PanelNames.IdeasPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_TRADING_IDEAS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.AssetBalancesPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_ACCOUNTS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.ChartPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_CHART, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.ProductsPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_PRODUCTS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.InformerPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_WATCHLIST, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.AlertPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_ALERTS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.MarketDepthPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_LEVEL2, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.TimeSalesPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_LEVEL3, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.PositionsPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_SHOW_POSITIONS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.OrdersPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_SHOW_ORDERS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.OrderHistoryPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_ORDER_BOOK, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.FilledOrdersPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_TRADES, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.SymbolInfoPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_SYMBOL_INFO, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.EventsPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_EVENT_LOG, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.TradingCentral:
            return this.isAllowedRule(RulesSet.FUNCTION_TRADING_CENTRAL, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.AssetsPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_ASSETS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.PositionsBalancePanel:
            return this.isAllowedRule(RulesSet.FUNCTION_POSITION_BALANCE, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.NewsPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_NEWS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.RiskCalculator: // TODO WHEN SERVER IMPLEMENT
            // return this.isAllowedRule(RulesSet.FUNCTION_RISK_CALCULATOR, account) ? Factory.PanelDisplayMode.Allowed : Factory.PanelDisplayMode.NotAllowed;
            return PanelDisplayMode.NotAllowed;
        case PanelNames.NotificationsPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_NOTIFICATIONS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.VideoWidget:
            return this.isAllowedRule(RulesSet.FUNCTION_VIDEO, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.MarketConsensusPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_CONSENSUS_ALGORITHM, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.ReportsPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_REPORT, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.TradingViewChartPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_TRADINGVIEW_CHART, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.SavedOrdersPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_BASKET, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.GainersLosersPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_GAINERS_LOSERS_ALGORITHM, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.HighLowPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_HIGH_LOW_ALGORITHM, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.HeatmapPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_HEATMAP, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.GridPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_GRID, account) && this.isAllowedRule(RulesSet.FUNCTION_CHART, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.MamSummary:
            return this.isAllowedRule(RulesSet.FUNCTION_MANAGED_STATISTICS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.OptionMasterPanel:
            return this.isAllowedRule(RulesSet.FUNCTION_OPTION_TRADER, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
        case PanelNames.OptionAnalyzerPanel:
            if (this.isExistRule(RulesSet.FUNCTION_OPTION_TRADER_ANALYZER, account)) {
                return this.isAllowedRule(RulesSet.FUNCTION_OPTION_TRADER_ANALYZER, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
            } else {
                return PanelDisplayMode.Allowed;
            }
        case PanelNames.OptionVolatilityLabPanel:
            if (this.isExistRule(RulesSet.FUNCTION_OPTION_TRADER_VOLATILITY_LAB, account)) {
                return this.isAllowedRule(RulesSet.FUNCTION_OPTION_TRADER_VOLATILITY_LAB, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
            } else {
                return PanelDisplayMode.Allowed;
            }
        case PanelNames.OptionPositionsPanel:
            if (this.isExistRule(RulesSet.FUNCTION_OPTION_TRADER_POSITIONS, account)) {
                return this.isAllowedRule(RulesSet.FUNCTION_OPTION_TRADER_POSITIONS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
            } else {
                return PanelDisplayMode.Allowed;
            }
        case PanelNames.OptionOrdersPanel:
            if (this.isExistRule(RulesSet.FUNCTION_OPTION_TRADER_WORKING_ORDERS, account)) {
                return this.isAllowedRule(RulesSet.FUNCTION_OPTION_TRADER_WORKING_ORDERS, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
            } else {
                return PanelDisplayMode.Allowed;
            }
        case PanelNames.OptionPaperPositionsPanel:
            if (this.isExistRule(RulesSet.FUNCTION_OPTION_TRADER_TEST_ON_PAPER, account)) {
                return this.isAllowedRule(RulesSet.FUNCTION_OPTION_TRADER_TEST_ON_PAPER, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
            } else {
                return PanelDisplayMode.Allowed;
            }
        case PanelNames.StatementPanel:
            if (this.isExistRule(RulesSet.FUNCTION_STATEMENT_PANEL, account)) {
                return this.isAllowedRule(RulesSet.FUNCTION_STATEMENT_PANEL, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
            } else {
                return PanelDisplayMode.Allowed;
            }
        case PanelNames.CopyTradingPanel:
            if (this.isExistRule(RulesSet.FUNCTION_COPY_TRADE, account)) {
                return this.isAllowedRule(RulesSet.FUNCTION_COPY_TRADE, account) ? PanelDisplayMode.Allowed : PanelDisplayMode.NotAllowed;
            } else {
                return PanelDisplayMode.NotAllowed;
            }
        default:
            return PanelDisplayMode.Allowed;
        }
    }

    panelAllowed (panelType: PanelNames, account: Account | null = null): boolean {
        return this.GetDisplayMode(panelType, account) === PanelDisplayMode.Allowed;
    }

    private isExistRule (rule: string, account: Account | null): boolean {
        return isNullOrUndefined(account) ? DataCache.isExistForMyUser(rule) : DataCache.isExistForAccount(rule, account);
    }

    private isAllowedRule (rule: string, account: Account | null): boolean {
        return isNullOrUndefined(account) ? DataCache.isAllowedForMyUser(rule) : DataCache.isAllowedForAccount(rule, account);
    }
}

export const CustomerAccess = new _CustomerAccess();
