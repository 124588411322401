// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class GainerLoserData {
    public InstrumentName: string;
    public TradableId: number;
    public InstrumentId: number;
    public InstrumentGroupId: number;
    public TradingExchange: string;
    public RouteId: number;
    public InterestChange: number;
    public LastPrice?: number;
    public IsDelayed: boolean;
    public Behavior: Behavior;
    public MarketDataExchange: string;
    public Range?: any /* AlgorithmDataRange | null */ = null;
    public Volume?: number | null = null;

    constructor (type: Behavior) {
        this.Behavior = type;
    }
}

export enum Behavior {
    Gainer = 0,
    Loser = 1
}
