// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { AlgorithmDataRange, type AlgorithmRangeType } from '../../../../../Commons/cache/Algorithm/Algorithm';
import { GainerLoserData } from '../../../../../Commons/cache/Algorithm/GainersLosers/GainerLoserData';
import { DirectAlgorithmHistoricalGainersLosersTopsMessage } from '../../../../../Utils/DirectMessages/Algorithm/DirectAlgorithmHistoricalGainersLosersTopsMessage';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { type PFixFieldSet } from '../../Fields/PFixFieldSet';
import { AlgorithmGainersLosersDelayDataGroup, AlgorithmGainersLosersInstrumentGroup, AlgorithmGainersLosersRangeGroup, AlgorithmHistoricalGainersLosersTopsDelayDataGroup } from '../../Groups/GroupsImport';
import { MessageProcessorBase } from '../ProcessorsImport';

export class AlgorithmHistoricalGainersLosersTopsMessageProcessor extends MessageProcessorBase {
    decode (fieldSet: PFixFieldSet): DirectAlgorithmHistoricalGainersLosersTopsMessage[] {
        const ff = FieldsFactory;

        const message = new DirectAlgorithmHistoricalGainersLosersTopsMessage();
        message.AlgorithmId = fieldSet.GetValue(ff.FIELD_ALGORITHM_ID);
        message.AlgorithmTop = fieldSet.GetValue(ff.FIELD_ALGORITHM_TOP);
        message.AlgorithmName = fieldSet.GetValue(ff.FIELD_ALGORITHM_NAME);

        for (const rtGroup of fieldSet.GetGroups(ff.ALGORITHM_HISTORICAL_GAINER_LOSER_RT_GROUP, AlgorithmGainersLosersDelayDataGroup)) {
            const rangeGroup = rtGroup.GetGroups(ff.ALGORITHM_HISTORICAL_GAINER_LOSER_TIME_RANGE_GROUP, AlgorithmGainersLosersRangeGroup)[0];
            for (const gainerLoserRtData of rtGroup.GetGroups(ff.ALGORITHM_GAINERS_LOSERS_INSTRUMENT_GROUP, AlgorithmGainersLosersInstrumentGroup)) {
                message.GainersLosersData.push(this.CreateHistoricalGainersLosersData(gainerLoserRtData, false, rangeGroup.RangeType, rangeGroup.RangeValue));
            }
        }

        for (const delayedGroup of fieldSet.GetGroups(ff.ALGORITHM_HISTORICAL_GAINER_LOSER_DELAY_GROUP, AlgorithmHistoricalGainersLosersTopsDelayDataGroup)) {
            const rangeGroup = delayedGroup.GetGroups(ff.ALGORITHM_HISTORICAL_GAINER_LOSER_TIME_RANGE_GROUP, AlgorithmGainersLosersRangeGroup)[0];
            for (const gainerLoserRtData of delayedGroup.GetGroups(ff.ALGORITHM_GAINERS_LOSERS_INSTRUMENT_GROUP, AlgorithmGainersLosersInstrumentGroup)) {
                message.GainersLosersData.push(this.CreateHistoricalGainersLosersData(gainerLoserRtData, true, rangeGroup.RangeType, rangeGroup.RangeValue));
            }
        }

        return [message];
    }

    CreateHistoricalGainersLosersData (group: AlgorithmGainersLosersInstrumentGroup, isDelayed: boolean, rangeType: AlgorithmRangeType, rangeValue: number): GainerLoserData {
        const data = new GainerLoserData(group.Behavior);

        data.Range = new AlgorithmDataRange(rangeType, rangeValue);

        data.InstrumentName = group.InstrumentName;
        data.TradableId = group.TradableInsID;
        data.InstrumentId = group.InstrumentId;
        data.InstrumentGroupId = group.InstrumentTypeId;
        data.MarketDataExchange = group.MarketDataExchange;
        data.TradingExchange = group.TradingExchange;
        data.RouteId = group.RouteID;
        data.InterestChange = group.InterestChange;
        data.LastPrice = group.LastPrice;
        data.IsDelayed = isDelayed;
        data.Volume = group.Volume;

        return data;
    }
}
