// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Connection } from '../Commons/Connection';
import { CustomEvent } from '../Utils/CustomEvents';
import { Resources } from '../Localizations/Resources';
import { Message } from '../Utils/DirectMessages/DirectMessagesImport';
import { SEPARATOR } from '../Utils/Enums/Constants';
import { PortfolioRequestType } from '../Utils/Portfolio/PortfolioRequestType';
import { PortfolioCacheDailyCashReturn, PortfolioCacheDailyInstrumentReturn, PortfolioCacheInstrumentReturn, PortfolioCacheMonthReturn, PortfolioGrowth, PortfolioInvestor, PortfolioSystem } from './PortfolioRequestsData';

export class PortfolioCache {
    public DCache: any;

    public portfolioSystem = {}; // доступные портфолио системы по их id
    public investorByAccountID = {}; // по аккаунт ID храним инфу по инвесторам

    public allocation = {}; // данные из реалтайм запроса с типом PortfolioRequestType.PORTFOLIO_ALLOCATION (для круговой диаграмы в режиме Portfolio Allocation)
    public portfolioGrowth: PortfolioGrowth[] = [];
    public totalReturn = {}; // значение внутри бублика в области Performance по аккаунту (передается в FIELD_TOTAL_RETURN[753] мсджа PORTFOLIO_REALTIME_STATISTICS(481))
    public modelData = {};
    public monthReturns = []; // возвращаемые данные при запросе с типом PortfolioRequestType.MONTHLY_PORTFOLIO_RETURN

    public dailyInstrumentReturn = {}; // возвращаемые данные при запросе с типом PortfolioRequestType.DAILY_INSTRUMENT_RETURN
    public dailyCashReturn = []; // возвращаемые данные при запросе с типом PortfolioRequestType.DAILY_CASH_RETURN

    public instrumentReturns = []; // возвращаемые данные при запросе с типом PortfolioRequestType.MONTHLY_INSTRUMENT_RETURN все одним массивом как их присылает сервер (может и не нужны)
    public instrumentReturnsByMonth = {}; // разложенные по месяцам
    public instrumentReturnsMinMaxByMonth = {}; // по каждому месяцу min и max среди всех инструментов
    public allInstrumentUsedInPortfolio = []; // массив с interiorID для каждого инструмента задействованого в портфолио (в соответствующем порядке с instrumentColor)
    public instrumentRealBarIndexByMonth = {}; // ассоциативный массив, где ключ месяц, а значение - объект в котором по каждому инструменту указан порядковый номер бара на чарте для этого месяца и инструмента (на случай если в какой-то месяц нет данных по какому-то инструменту)

    public instrumentColor: string[]; // TODO создавать кол-во цветов точно соответствующие количеству участвующих инструментов
    public instrumentColorRGBA: string[]; // те же цвета но с затемнением и в формате rgba (используется например при ховер эффекте)

    public OnAllocationUpdate = new CustomEvent();
    public OnTotalReturnUpdate = new CustomEvent();
    public OnReturnsUpdate = new CustomEvent();
    public OnModelUpdate = new CustomEvent();

    constructor (dCache) {
        // for avoid exception on TerceraChartToolRenderer.ts:68 line - event subscribe
        this.DCache = dCache;

        const colorSet = ['#FF2D55', '#FF9500', '#4CD964', '#007AFF', '#EA606F', '#00FFFF', '#FC5BAC', '#33EEAA', '#FF4224', '#5500FF', '#49A191'];
        this.instrumentColor = [].concat(colorSet, colorSet, colorSet, colorSet, colorSet);
        this.instrumentColorRGBA = this.CreateinstrumentColorRGBA();
    }

    public NewMessage (msg): void {
        if (!msg) return;

        switch (msg.Code) {
        case Message.CODE_PFIX_PORTFOLIO_REALTIME_STATISTICS:
            this.UpdateRealTimeStatistics(msg);
            break;

        case Message.CODE_PFIX_PORTFOLIO_MODEL_MESSAGE:
            this.UpdateModel(msg);
            break;

        case Message.CODE_PFIX_PORTFOLIO_SYSTEM_MESSAGE:
            this.UpdatePortfolioSystemByDirectMsg(msg);
            break;

        case Message.CODE_PFIX_PORTFOLIO_INVESTOR_MESSAGE:
            this.UpdateInvestorByDirectMsg(msg);
            break;
        }
    }

    public UpdateReturns (msg, portfolioRequestType: PortfolioRequestType): void {
        switch (portfolioRequestType) {
        case PortfolioRequestType.PORTFOLIO_GROWTH:
            this.portfolioGrowth = this.GetGrowthFromDirectMsg(msg);
            break;

        case PortfolioRequestType.MONTHLY_PORTFOLIO_RETURN:
            this.monthReturns = this.GetMonthReturnsFromDirectMsg(msg);
            break;

        case PortfolioRequestType.DAILY_INSTRUMENT_RETURN:
            this.dailyInstrumentReturn = this.GetDailyInstrumentReturnFromDirectMsg(msg);
            break;

        case PortfolioRequestType.DAILY_CASH_RETURN:
            this.dailyCashReturn = this.GetDailyCashReturnFromDirectMsg(msg);
            break;

        case PortfolioRequestType.MONTHLY_INSTRUMENT_RETURN:
            const insReturnsObj = this.GetInsReturnsFromDirectMsg(msg);
            if (insReturnsObj) {
                this.instrumentReturnsByMonth = insReturnsObj.instrumentReturnsByMonth;
                this.instrumentReturns = insReturnsObj.allInstrumentReturns;
                this.allInstrumentUsedInPortfolio = insReturnsObj.allInstruments;
                this.instrumentReturnsMinMaxByMonth = insReturnsObj.instrumentReturnsMinMaxByMonth;
                this.instrumentRealBarIndexByMonth = insReturnsObj.instrumentRealBarIndexByMonth;
            }
            break;
        }

        this.OnReturnsUpdate.Raise(portfolioRequestType);
    }

    public Clear (): void {
        this.monthReturns = [];
        this.OnReturnsUpdate.Raise();

        this.modelData = {};
        this.OnModelUpdate.Raise();
    }

    public SendPortfolioModelRequest (): any {
        return Connection.vendor.SendPortfolioModelRequest();
    }

    public SendPortfolioStatisticsRequest (accountId, type, startTime, endTime, routeId, instrumentTradableID): any {
        accountId = accountId || this.DCache.getPrimaryAccount().AcctNumber;

        return Connection.vendor.SendPortfolioStatisticsRequest(accountId, type, startTime, endTime, routeId, instrumentTradableID)
            .then(function (msg) {
                if (msg?.length) {
                    this.UpdateReturns(msg[0], type);
                }
                return msg;
            }.bind(this));
    }

    public UpdatePortfolioSystemByDirectMsg (msg): void {
        if (!msg) return;

        const managerLogin = msg.PortfolioManagerLogin;
        const id = msg.PortfolioSystemID;
        const name = msg.PortfolioSystemName;
        // isSubscribed = msg.IsSubscribed()

        if (!id) return;

        this.portfolioSystem[id] = new PortfolioSystem(id, name, managerLogin);
    }

    public UpdateInvestorByDirectMsg (msg): void {
        if (!msg) return;

        const id = msg.PortfolioSystemID;
        const modelID = msg.ModelID;
        const accountID = msg.AccountID;
        const isSubscribed = msg.IsSubscribed ? msg.IsSubscribed() : null;

        if (!id) return;

        this.investorByAccountID[accountID] = isSubscribed ? new PortfolioInvestor(id, modelID, accountID) : null;
    }

    public IsInvestorAccount (accData): boolean // можно передать в качестве аргумента либо Account либо AcctNumber
    {
        if (!accData) return false;

        const accID = accData.AcctNumber ? accData.AcctNumber : accData;
        const investor = this.investorByAccountID[accID];

        return !!investor;
    }

    public GetInsReturnsFromDirectMsg (msg) {
        const dataArray = msg.DataArray;

        if (!dataArray) return null;

        const instrumentReturnsByMonth = {};
        const allInstrumentReturns = [];
        const allInstruments = [];

        for (let i = 0; i < dataArray.length; i++) {
            const data = dataArray[i];

            const insTID = data.TradableInstrumentId;
            const routeID = data.RouteId;
            const ins = this.DCache.getInstrumentByTradable_ID(insTID, routeID);

            if (!ins) continue;

            const instrumentReturn = new PortfolioCacheInstrumentReturn(ins, data.Date, data.Percent);

            let arrByMonth = instrumentReturnsByMonth[instrumentReturn.Date];
            if (!arrByMonth) {
                arrByMonth = [];
            }

            arrByMonth.push(instrumentReturn);

            instrumentReturnsByMonth[instrumentReturn.Date] = arrByMonth;

            allInstrumentReturns.push(instrumentReturn);

            if (!allInstruments.includes(ins.GetInteriorID())) {
                allInstruments.push(ins.GetInteriorID());
            }
        }

        return {
            allInstrumentReturns,
            instrumentReturnsByMonth,
            allInstruments: allInstruments.sort(),
            instrumentReturnsMinMaxByMonth: this.GetMinMaxByMonth(instrumentReturnsByMonth),
            instrumentRealBarIndexByMonth: this.CacheInstrumentRealBarIndexByMonth(instrumentReturnsByMonth, allInstruments)
        };
    }

    public GetGrowthFromDirectMsg (msg): PortfolioGrowth[] | null {
        if (!msg) return null;

        const dataArray = msg.DataArray;
        if (!dataArray) return null;

        const growth: PortfolioGrowth[] = [];

        for (let i = 0; i < dataArray.length; i++) {
            const msgData = dataArray[i];

            growth.push(new PortfolioGrowth(msgData.Date, msgData.AbsoluteAmount));
        }

        return growth;
    }

    public GetMonthReturnsFromDirectMsg (msg): PortfolioCacheMonthReturn[] | null {
        const dataArray = msg.DataArray;

        if (!dataArray) return null;

        const monthReturns: PortfolioCacheMonthReturn[] = [];

        for (let i = 0; i < dataArray.length; i++) {
            const msgData = dataArray[i];

            monthReturns.push(new PortfolioCacheMonthReturn(msgData.Date, msgData.Percent, msgData.AbsoluteAmount));
        }

        return monthReturns;
    }

    public GetDailyInstrumentReturnFromDirectMsg (msg): any {
        const dataArray = msg.DataArray;
        if (!dataArray) return null;

        const dailyInstrumentReturn = {};

        for (let i = 0; i < dataArray.length; i++) {
            const msgData = dataArray[i];
            const insTID = msgData.TradableInstrumentId;
            const routeID = msgData.RouteId;
            const ins = this.DCache.getInstrumentByTradable_ID(insTID, routeID);

            if (!ins) continue;

            const interiorID = ins.GetInteriorID();

            if (!dailyInstrumentReturn[interiorID]) {
                dailyInstrumentReturn[interiorID] = [];
            }

            dailyInstrumentReturn[interiorID].push(new PortfolioCacheDailyInstrumentReturn(ins, msgData.Date, msgData.Percent));
        }

        return dailyInstrumentReturn;
    }

    public GetDailyCashReturnFromDirectMsg (msg): PortfolioCacheDailyCashReturn[] {
        const dataArray = msg.DataArray;
        if (!dataArray) return null;

        const dailyCashReturn: PortfolioCacheDailyCashReturn[] = [];

        for (let i = 0; i < dataArray.length; i++) {
            const msgData = dataArray[i];

            dailyCashReturn.push(new PortfolioCacheDailyCashReturn(msgData.Date, msgData.Percent));
        }

        return dailyCashReturn;
    }

    public UpdateRealTimeStatistics (msg): void {
        if (!msg) return;

        if (msg.Groups) {
            const accID = msg.Account;
            if (!accID) return;

            this.allocation[accID] = msg.Groups;

            this.OnAllocationUpdate.Raise(accID);
        }

        if (msg.TotalReturn) {
            const accID = msg.Account;
            if (!accID) return;

            this.totalReturn[accID] = msg.TotalReturn;

            this.OnTotalReturnUpdate.Raise(accID);
        }
    }

    public GetTotalReturn (account): any {
        if (!account) {
            return null;
        }

        if (account.AcctNumber) // аргумент - аккаунт
        {
            const accID = account.AcctNumber;

            return this.totalReturn[accID] || null;
        }

        if (this.totalReturn[account]) // аргумент - id
        {
            return this.totalReturn[account] || null;
        }

        return null;
    }

    public UpdateModel (model): void {
        if (!model) return;

        const id = model.ModelID;
        if (id) {
            this.modelData[id] = model;

            this.OnModelUpdate.Raise(id);
        }
    }

    public GetDataForDiagramPortfolioAllocation (selectedAccountID): any[] {
        const cashLocalized = Resources.getResource('portfolio.performance.cash');
        const arr = [];

        if (!this.allocation) {
            return [{
                value: 1,
                text: cashLocalized
            }];
        }

        const allocation = this.allocation;
        for (const accID in allocation) {
            if (accID != selectedAccountID) {
                continue;
            }

            const allocationByAcc = allocation[accID];

            for (let i = 0; i < allocationByAcc.length; i++) {
                const g = allocationByAcc[i];
                const trInsID = g.tradableInsID;
                const routeID = g.routeID;
                const percent = g.percent;
                const interiorID = trInsID ? trInsID + SEPARATOR + routeID : null;
                const ins = interiorID !== null ? this.DCache.getInstrumentByName(interiorID) : null;
                const text = ins ? ins.DisplayName() : cashLocalized;
                const obj = {
                    value: percent / 100,
                    text
                };

                arr.push(obj);
            }

            break;
        }

        return arr;
    }

    public GetMinMaxByMonth (byMonth): any {
        if (!byMonth) return null;

        const allMonth = Object.keys(byMonth);
        const result = {};

        for (let i = 0; i < allMonth.length; i++) {
            const monthKey = allMonth[i];
            const insInMonth = byMonth[monthKey];
            const minMaxObj = { min: Number.MAX_VALUE, max: Number.MIN_VALUE };

            for (let j = 0; j < insInMonth.length; j++) {
                const value = insInMonth[j].percent;
                minMaxObj.min = Math.min(minMaxObj.min, value);
                minMaxObj.max = Math.max(minMaxObj.max, value);
            }

            if (Math.sign(minMaxObj.min) === Math.sign(minMaxObj.max)) {
                if (Math.sign(minMaxObj.min) < 0) {
                    minMaxObj.max = 0;
                } else {
                    minMaxObj.min = 0;
                }
            }

            result[monthKey] = minMaxObj;
        }

        return result;
    }

    public CacheInstrumentRealBarIndexByMonth (byMonth, allInstruments): any {
        if (!byMonth || !allInstruments) return null;

        const monthReturnsLen = this.monthReturns ? this.monthReturns.length : 0;
        const result = {};
        const indexByInstrument = {};

        for (let i = 0; i < allInstruments.length; i++) {
            const insKey = allInstruments[i];
            indexByInstrument[insKey] = -1;
        }

        for (let i = 0; i < monthReturnsLen; i++) {
            const monthKey = this.GetKeyByMonthReturnsIndex(i);
            const insInMonth = byMonth[monthKey];

            const hasBarInCurrentMonth = {};

            if (insInMonth) {
                for (let j = 0; j < insInMonth.length; j++) {
                    const ins = insInMonth[j].interiorID;

                    indexByInstrument[ins]++;
                    hasBarInCurrentMonth[ins] = true;
                }
            }

            const obj = {};
            for (let j = 0; j < allInstruments.length; j++) {
                const ins = allInstruments[j];

                obj[ins] = hasBarInCurrentMonth[ins] ? indexByInstrument[ins] : null;
            }

            result[monthKey] = obj;
        }

        return result;
    }

    public GetKeyByMonthReturnsIndex (index): any // return key like as for example 'Jun, 2020'
    {
        const monthReturns = this.monthReturns;
        if (!monthReturns?.[index]) return null;

        return monthReturns[index].dateInMonthWordFormat;
    }

    public GetLastMonthReturn (): any // last month return percent (like current price)
    {
        const monthReturns = this.monthReturns;
        if (!monthReturns) return null;

        const lastI = monthReturns.length - 1;

        return monthReturns[lastI].percent;
    }

    public GetLastGrowth (): any // last growth value (like current price)
    {
        const growth = this.portfolioGrowth;
        if (!growth) return null;

        const lastI = growth.length - 1;

        return growth[lastI].AbsoluteAmount;
    }

    public GetColorByInteriorID (interiorID): string // достать цвет соответствующий инструменту
    {
        const insIndex = this.allInstrumentUsedInPortfolio.indexOf(interiorID);

        return this.instrumentColor[insIndex];
    }

    public static RETURNS_BARS_OPACITY_VALUE = 12; // % измененные цвета например при hover эффекте (к цвету применяется данное значение opacity)

    public CreateinstrumentColorRGBA (): string[] {
        const colors = this.instrumentColor;
        const colorsRGBA: string[] = [];

        for (let i = 0; i < colors.length; i++) {
            colorsRGBA.push(PortfolioCache.AddOpacityToHexColor(colors[i], PortfolioCache.RETURNS_BARS_OPACITY_VALUE));
        }

        return colorsRGBA;
    }

    public static AddOpacityToHexColor (hexCode, opacity): string {
        let hex = hexCode.replace('#', '');
        if (hex.length === 3) {
            hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
        }

        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity / 100 + ')';
    }
}
