// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Message } from '../../Utils/DirectMessages/DirectMessagesImport';

export class SourceCollection {
    public routeID: any = null;
    public cache: any = {};

    constructor (collection?) {
        if (collection) {
            this.Update(collection);
        }
    }

    public Update (collection): void {
        const cache = collection.cache;
        for (const key in cache) {
            const item = cache[key];
            this.AddSource(key, item.Name, item.ExternalId);
        }
    }

    public AddSource (id, name, externalId): void {
        const src = new Source(id, name, externalId);
        this.cache[src.Id] = src;
    }

    public GetSourceName (id): string {
        const source = this.getSource(id);
        return source ? source.Name : id.toString();
    }

    public getSource (id): any {
        return this.cache[id];
    }

    public UpdateByMessage (msg): void {
        if (!msg || msg.Code != Message.CODE_SOURCE_LIST) {
            return;
        }

        this.routeID = msg.RouteId;

        if (msg.SourceIDs) {
            for (let i = 0; i < msg.SourceIDs.length; i++) {
                this.AddSource(msg.SourceIDs[i], msg.SourceNames[i], msg.SourceExternalIds[i]);
            }
        }
    }
}

export class Source {
    public Id: any;
    public Name: any;
    public ExternalId: any;

    constructor (id, name, externalId) {
        this.Id = id;
        this.Name = name;
        this.ExternalId = externalId;
    }
}
