// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { CustomerAccess } from '../../Commons/CustomerAccess/CustomerAccess';
import { PanelDisplayMode } from '../../Commons/CustomerAccess/PanelDisplayMode';
import { Factory, ThrottlingOperation } from '../panels/Factory';

export function IsAllowedPanel (panel): boolean {
    const panelType = panel.getType();
    const isAllowedByDisplayMode = CustomerAccess.GetDisplayMode(panelType) === PanelDisplayMode.Allowed; // rule check
    const isAllowedByLimit = Factory.GetThrottlingOperationValue(panelType).throttlingOperation === ThrottlingOperation.CanAddPanel; // panels limit number check
    return isAllowedByDisplayMode && isAllowedByLimit;
}
