// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export const ja =
{
    'AdditionalProperty.Account': '取引口座',
    'AdditionalProperty.Reverse': '取り消す',
    'accountDetailsWidget.DropdownButton': '取引口座',
    'accountDetailsWidget.DropdownButton.Tooltip': 'アカウントメニュー',
    'additional.verification': '追加でパスワード認証が必要です。',
    Alligator: 'アリゲーター',
    'Average Directional Index': 'ADX',
    'Average True Range': 'アベレージトゥルーレンジ',
    'Bollinger Band': 'ボリンジャーバンド',
    'Bollinger Bands': 'ボリンジャーバンド',
    'bonds.couponCycle.1m': '1カ月',
    'bonds.couponCycle.1y': '1年',
    'bonds.couponCycle.3m': '3カ月',
    'bonds.couponCycle.6m': '6カ月',
    'BrokerMessage.Cancel': 'キャンセル',
    'BrokerMessage.OK': 'OK',
    'BrokerMessage.Text': 'ブローカーメッセージ',
    'BusinessRejectMessage.28': '現在のパスワードが正しくありません',
    'BusinessRejectMessage.31': '新しいパスワードが正しくありません：現在のパスワードは使用できません',
    'BusinessRejectMessage.201': '新しいパスワードに使用できない文字が含まれています',
    'BusinessRejectMessage.202': 'パスワードはログインIDと同じものは使用できません。',
    'BusinessRejectMessage.203': '新しいパスワードを入力してください',
    'BusinessRejectMessage.221': 'パスワードが変更できません: パスワードは大文字、小文字、数字をそれぞれ1種類以上含めて設定してください もう一度入力し直してください',
    'BusinessRejectMessage.222': 'パスワードが変更できません: パスワードは、8文字以上で、大文字、小文字、数字をそれぞれ1種類以上含めて設定してください もう一度入力し直してください',
    'BusinessRejectMessage.223': 'パスワードが変更できません: パスワードは、8文字以上で、大文字、小文字、数字、記号 (@#$%) をそれぞれ1種類以上含めて設定してください もう一度入力し直してください',
    'BusinessRejectMessage.338': '取引のロック解除用パスワードはロック解除ロジックにより許可されません。',
    'BusinessRejectMessage.339': '現在の取引用パスワードが正しくありません',
    'Chande Momentum Oscillator': 'シャンデモメンタムオシレーター(CMO)',
    'chart.agregationType.Button.ToolTip': '時間足を選択',
    'chart.AllowedPeriods.15M': '15分足',
    'chart.AllowedPeriods.1D': '日足',
    'chart.AllowedPeriods.1H': '1時間足',
    'chart.AllowedPeriods.1M': '1分足',
    'chart.AllowedPeriods.1S': '1秒足',
    'chart.AllowedPeriods.1T': '1ティック足',
    'chart.AllowedPeriods.1W': '週足',
    'chart.AllowedPeriods.1Y': '年足',
    'chart.AllowedPeriods.30M': '30分足',
    'chart.AllowedPeriods.4H': '4時間足',
    'chart.AllowedPeriods.5M': '5分足',
    'chart.AllowedPeriods.Month': '月足',
    'chart.AllowedPeriods.Range': '範囲',
    'chart.AllowedPeriods.Ticks': 'ティック',
    'chart.ChartPanel.accountLookup.ToolTip': '取引口座を選択',
    'chart.ChartPanel.symbolLookup.ToolTip': '銘柄を選択',
    'chart.chartScroll.toBegin': '最新の値へ',
    'chart.chartScroll.zoomInButton': '拡大',
    'chart.chartScroll.zoomOutButton': '縮小',
    'chart.ChartSymbolSelector.addIndicator.ButtonToolTip': 'インディケーターの追加',
    'chart.ChartSymbolSelector.drawingButton.ToolTip': '描写ツールの表示/非表示',
    'chart.ChartSymbolSelector.mouseTradeButton.ToolTip': 'マウス操作による注文',
    'chart.ChartSymbolSelector.oeButton': '注文パネル',
    'chart.ChartSymbolSelector.oeButton.ToolTip': 'チャート注文パネル',
    'chart.ChartSymbolSelector.OverlayTooltip': 'チャートを重ねる',
    'chart.ChartSymbolSelector.ObjectManagerTooltip': 'オブジェクト・マネージャー',
    'Chart.chartWindow.auto': '自動',
    'Chart.chartWindow.collapsed': '最小化',
    'Chart.chartWindow.manual': '手動',
    'chart.chartWindowManager.SetAuto': '価格スケール自動調整',
    'chart.chartWindowManager.SetManual': '価格スケール手動調整',
    'chart.head.addOverlay': 'チャートを重ねる',
    'chart.head.charttype': 'チャートの種類',
    'chart.head.line': '描写ツール',
    'chart.head.overlayRemoveAll': '全て削除',
    'chart.head.overlays': 'チャートの重ね合わせ',
    'chart.indicator': 'インディケーター',
    'chart.indicatorAdd': 'インディケーターの追加',
    'chart.indicatorRemoveAll': '全て削除',
    'chart.indicators': 'インディケーター',
    'chart.Infoline.days': '日',
    'chart.Infoline.hours': '時',
    'chart.Infoline.min': '分',
    'chart.Infoline.price': 'プライス',
    'chart.Infoline.sec': '秒',
    'chart.Infoline.time': '時間',
    'chart.infoWindow.Items.Ask': 'Ask:',
    'chart.infoWindow.Items.Bars': 'バー数:',
    'chart.infoWindow.Items.Bid': 'Bid:',
    'chart.infoWindow.Items.Change': '値動き,%',
    'chart.infoWindow.Items.Close': '終値:',
    'chart.infoWindow.Items.Date': '日付:',
    'chart.infoWindow.Items.High': '高値:',
    'chart.infoWindow.Items.Low': '安値:',
    'chart.infoWindow.Items.Open': '始値:',
    'chart.infoWindow.Items.Ticks': 'ティック:',
    'chart.infoWindow.Items.Time': '時間:',
    'chart.infoWindow.Items.Volume': '出来高:',
    'chart.instrumentsLookup': '銘柄一覧パネル',
    'chart.layerManager': '設定',
    'chart.loading': '読込中...',
    'chart.loadingData': 'データ読込中',
    'chart.mainlayer.gridPrice': '価格のグリッド線',
    'chart.mainlayer.gridTime': '時間のグリッド線',
    'chart.mainlayer.volume': '出来高',
    'chart.menu.cancelByAccount': '口座毎に全てを取消',
    'chart.menu.cancelByInstrument': '銘柄毎に全てを取消',
    'chart.menu.cancelOrder': '注文の取消',
    'chart.menu.close': 'ポジションの決済',
    'chart.menu.closeall': '全てのポジションの決済',
    'chart.menu.InfoWindowDock': '固定',
    'chart.menu.InfoWindowHide': '非表示',
    'chart.menu.InfoWindowSettings': '設定',
    'chart.menu.InfoWindowShortMode': '短縮モード',
    'chart.menu.modify': '変更',
    'chart.menu.PlateIndicatorDublicate': 'インディケーターの複製',
    'chart.menu.PlateIndicatorRemove': '削除',
    'chart.menu.PlateIndicatorSettings': '設定',
    'chart.menu.PlateIndicatorVisible': '表示',
    'chart.menu.PlateOverlay.Overlay': 'チャートを重ねる',
    'chart.menu.PlateOverlayRemove': '削除',
    'chart.menu.PlateOverlaySettings': '設定',
    'chart.menu.PlateOverlayVisible': '表示',
    'chart.menu.rollback': '注文の取消',
    'chart.noAvailableData': 'に該当するデータがありません',
    'chart.NoData': 'データなし',
    'chart.priceScaleContextMenu.AutoScale': '自動',
    'chart.priceScaleContextMenu.FitDay': '当日の高値/安値に合わす',
    'chart.priceScaleContextMenu.FitDrawings': '描写ツールに合わす',
    'chart.priceScaleContextMenu.FitIndicators': 'インディケーターに合わす',
    'chart.priceScaleContextMenu.FitOrdersPositions': '注文/ポジションに合わす',
    'chart.priceScaleContextMenu.ManualScale': '手動',
    'chart.refresh': '更新',
    'chart.Remove': '削除',
    'chart.shortPeriodName.m': '分',
    'chart.shortPeriodName.ticks': 'ティック',
    'chart.ShowHighLow.Close': '終値',
    'chart.ShowHighLow.High': '高値',
    'chart.ShowHighLow.Low': '安値',
    'chart.StyleButton.ToolTip': '表示形式',
    'chart.templates.tooltip': '定型チャート',
    'chart.tic.short.15min.name': '15分足',
    'chart.tic.short.1day.name': '日足',
    'chart.tic.short.1hour.name': '1時間足',
    'chart.tic.short.1min.name': '1分足',
    'chart.tic.short.1tic.name': 'ティック',
    'chart.tic.short.30min.name': '30分足',
    'chart.tic.short.4hour.name': '4時間足',
    'chart.tic.short.5min.name': '5分足',
    'chart.tic.short.month.name': '月足',
    'chart.tic.short.range.name': '範囲',
    'chart.tic.short.second.name': '秒足',
    'chart.tic.short.week.name': '週足',
    'chart.tic.short.year.name': '年足',
    'chart.timeframeselector.rangeButton.ToolTip': '表示期間の選択',
    'chart.timeframeselector.time': '時間',
    'chart.timeScaleContextMenu.ShowAllSessions': '拡張セッションを表示',
    'chart.timeScaleContextMenu.ShowDaySeparator': '日付区切り線を表示',
    'chart.timeScaleContextMenu.ShowEmptyBars': 'マーケット閉場時間を時間軸に表示',
    'chart.timeScaleContextMenu.ShowMonthSeparator': '月の区切り線を表示',
    'chart.timeScaleContextMenu.ShowTimePeriodsSeparator': '時間区切り線を表示',
    'chart.timeScaleContextMenu.ShowWeeksSeparator': '週の区切り線を表示',
    'chart.timeScaleContextMenu.ShowYearSeparator': '年の区切り線を表示',
    'chart.tool.bar': 'バー',
    'chart.tool.candle': 'ローソク',
    'chart.tool.clone': '複製',
    'chart.tool.Dash': '罫線',
    'chart.tool.delete': '削除',
    'chart.tool.deleteAll': '全て削除',
    'chart.tool.dot': 'ドット',
    'chart.tool.dotline': 'ドットライン',
    'chart.tool.DottedLine': 'ドットライン',
    'chart.tool.forest': 'ヒストグラム',
    'chart.tool.IsoDot': '保留',
    'chart.tool.line': 'ライン',
    'chart.tool.properties': '設定',
    'chart.tool.ShapedLine': '図形',
    'chart.tool.solid': '範囲',
    'chart.tools.ABCPatern': 'ABCパターン',
    'chart.tools.AndrewPitchfork': 'アンドリューズピッチフォーク',
    'chart.tools.backcolor': '背景色',
    'chart.tools.ButterflyGartley': 'ガートレイバタフライ',
    'chart.tools.Circle': '円形',
    'chart.tools.default': 'マウスポインタ',
    'chart.tools.Ellipse': '楕円',
    'chart.tools.eraser': '消しゴム',
    'chart.tools.FibArc': 'フィボナッチアーク',
    'chart.tools.FibEllipses': 'フィボナッチエリプシス',
    'chart.tools.FibExp': 'フィボナッチエクスパンション',
    'chart.tools.FibFan': 'フィボナッチファン',
    'chart.tools.FibPhiChannel': 'フィボナッチフィーチャネル',
    'chart.tools.Fibretr': 'フィボナッチリトレースメント',
    'chart.tools.FibSpiral': 'フィボナッチスパイラル',
    'chart.tools.FibTga': 'フィボナッチタイムゴール分析',
    'chart.tools.FibTz': 'フィボナッチタイムゾーン',
    'chart.tools.GannFan': 'ギャンファン',
    'chart.tools.GannGrid': 'ギャングリッド',
    'chart.tools.GannLine': 'ギャンライン',
    'chart.tools.horizontal': '水平ライン',
    'chart.tools.infoline': '定規',
    'chart.tools.Line': 'トレンドライン',
    'chart.tools.mirrored': 'ミラー表示',
    'chart.tools.points': 'ポイントカウント',
    'chart.tools.Polygon': '多角形',
    'chart.tools.PPZ': 'PPZ (Price pivot zone)',
    'chart.tools.PriceChannel': 'プライスチャネル',
    'chart.tools.Rectangle': '長方形',
    'chart.tools.RegressionChannel': '線形回帰チャネル',
    'chart.tools.ScalingByRect': '長方形を使ったスケーリング',
    'chart.tools.selection': '範囲を選択',
    'chart.tools.snapToCandle': 'スナップ切り替え',
    'chart.tools.StayDrawingMode': '描写モード切り替え',
    'chart.tools.symbol': 'マーク',
    'chart.tools.text': 'コメント',
    'chart.tools.TopBottomPattern': 'トップボトムパターン',
    'chart.tools.Triangle': '三角形',
    'chart.tools.vertical': '垂直ライン',
    'chart.view': '表示設定',
    'chart.view.analyseGroupMenu': '分析ツール',
    'chart.view.analysetoolbar': '分析ツール',
    'chart.view.barsToRight': 'バーを右へ移動中...',
    'chart.view.colors': '設定',
    'chart.view.dataInfo': '情報ウインドウ',
    'chart.view.Day_HighLow': '当日の高値/安値',
    'chart.view.drawingtoolbar': '描写ツールバー',
    'chart.view.font': '軸のフォント',
    'chart.view.landscapequestion': '横向きに表示しますか？',
    'chart.view.merge': '継続したライン',
    'chart.view.orderEntry': 'チャート注文パネル',
    'chart.view.periods': 'インターバル',
    'chart.view.previousCloseLine': '前日の終値',
    'chart.view.PrevSettlementPriceLine': '前回の決済価格',
    'chart.view.scroller': 'スクロールバー',
    'chart.view.setOrder': '注文を出す',
    'chart.view.SettlementPriceLine': '決済価格',
    'chart.view.show_cross': 'トラックカーソル',
    'chart.view.show_history': '取引履歴の表示',
    'chart.view.show_set_zero': '空のバーの表示',
    'chart.view.showEvents': '取引の表示',
    'chart.view.showNews': 'ニュースの表示',
    'chart.view.showOrders': '注文の表示',
    'chart.view.showPositions': 'ポジションの表示',
    'chart.view.source': '適用価格',
    'chart.view.source0': '各銘柄のデフォルト',
    'chart.view.source1': 'Bid',
    'chart.view.source1000': 'Ask',
    'chart.view.source1001': 'BidとAskの平均値',
    'chart.view.source1002': '最小(売気配)と最大(買気配)',
    'chart.view.source4': '終値',
    'chart.view.sourceBID/ASK': '売/買',
    'chart.view.sourceTick1': '売/買',
    'chart.view.TimeToNextBar': 'ローソク足の確定までの時間',
    'chart.view.toolbar': 'ツールバー',
    'chart.view.volume': '出来高の表示',
    'chart.visualTrading.Invalid price': '無効な価格',
    'chart.volumeColoringMode.ByBar': 'バー毎の表示',
    'chart.volumeColoringMode.ByDifference': '差異による表示',
    'chart.volumeColoringMode.Fixed': '固定',
    'chart.quickTrading.Buy limit': '指値買',
    'chart.quickTrading.Buy stop': '買逆指値',
    'chart.quickTrading.Invalid price': '不適切な価格',
    'chart.quickTrading.Sell limit': '売指値',
    'chart.quickTrading.Sell stop': '売逆指値',
    'Commodity Channel Index': '商品チャンネル指数',
    'connect.connection.connect.error': '接続パラメーターが違います',
    'connect.connection.tradestream.error': '取引の情報がみつかりません',
    'connect.connection.unknown.error': '不明なエラー!',
    Custom: 'カスタマイズ',
    Day: '日',
    'dealticket.RiskRuleWarning.Header': 'リスクルール警告',
    Deutsch: '#hidden#',
    'enum.AccountType.ClosedFund': 'クローズドファンド',
    'enum.AccountType.MultiAsset': 'マルチアセット',
    'enum.AccountType.OpenedFund': 'オープンファンド',
    'enum.AccountType.SingleCCY': '単一通貨',
    'enum.AssetType.COMMODITIES': '商品',
    'enum.AssetType.CRYPTO_CCY': '仮想通貨',
    'enum.AssetType.CURRENCY': '通貨',
    'enum.AssetType.SHARES': '株',
    'editableComboBox.CreateNew': '新規作成',
    'editableComboBox.CreateNewTemplate': 'テンプレートの作成',
    'editableComboBox.CreateNew.tooltip': '新しい通貨ペアのリストを作成',
    'editableComboBox.CreateNewTemplate.tooltip': '新しいテンプレートを作成',
    'editableComboBox.defaultList': 'デフォルトリスト',
    'editableComboBox.tooltip': '保存されたリスト',
    'ExchangeId.All': '全ての取引所',
    'Exponential Moving Average': '指数平滑移動平均線(EMA)',
    FOK: 'FOK',
    'FullLogs.Cluster': 'サーバー',
    'FullLogs.DateTime': '時間',
    'FullLogs.Fields': 'フィールド',
    'FullLogs.Message name': 'メッセージネーム',
    'FullLogs.Session': 'セッション',
    'general.Calculating': '計算中...',
    'general.connection.restErrorMsg': 'ワークスペースへの保存中に問題が発生しました。サポートデスクへ連絡ください。',
    'general.connection.Verification password not valid.': '検証パスワードが有効ではありません',
    'general.CUSTOM': 'カスタマイズ',
    'general.Friday': '金曜日',
    'general.messageBox.cancel': 'キャンセル',
    'general.messageBox.no': 'いいえ',
    'general.messageBox.ok': 'OK',
    'general.messageBox.showNextTime': '次回も表示',
    'general.messageBox.yes': 'はい',
    'general.Monday': '月曜日',
    'general.day1.Mo': '月',
    'general.day2.Tu': '火',
    'general.day3.We': '水',
    'general.day4.Th': '木',
    'general.day5.Fr': '金',
    'general.day6.Sa': '土',
    'general.day7.Su': '日',
    'general.month.1': '1月',
    'general.month.10': '10月',
    'general.month.11': '11月',
    'general.month.12': '12月',
    'general.month.2': '2月',
    'general.month.3': '3月',
    'general.month.4': '4月',
    'general.month.5': '5月',
    'general.month.6': '6月',
    'general.month.7': '7月',
    'general.month.8': '8月',
    'general.month.9': '9月',
    'general.monthFullName.1': '1月',
    'general.monthFullName.10': '10月',
    'general.monthFullName.11': '11月',
    'general.monthFullName.12': '12月',
    'general.monthFullName.2': '2月',
    'general.monthFullName.3': '3月',
    'general.monthFullName.4': '4月',
    'general.monthFullName.5': '5月',
    'general.monthFullName.6': '6月',
    'general.monthFullName.7': '7月',
    'general.monthFullName.8': '8月',
    'general.monthFullName.9': '9月',
    'general.N_A': '該当なし',
    'general.OvernightMarginNotificationMessage': 'オーバーナイトマージンのお知らせを表示',
    'general.Saturday': '土曜日',
    'general.Settings': '設定',
    'general.Sunday': '日曜日',
    'general.Thursday': '木曜日',
    'general.trading.at': '価格',
    'general.trading.Buy': '買い',
    'general.trading.confirmation': '確認画面',
    'general.trading.confirmation.prefix': '',
    'general.trading.confirmationString': '{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}',
    'general.trading.for': '口座',
    'general.trading.limitBuyLessAsk': '買指値注文価格がAsk値より高く設定されたため、注文後即時に約定されることになります',
    'general.trading.limitSellMoreBid': '売指値注文価格がBid値より低く設定されたため、注文後即時に約定されることになります',
    'general.trading.marketCloseFor': '成行決済で',
    'general.trading.pips': 'ティック',
    'general.trading.points': 'ポイント',
    'general.trading.position': 'ポジション',
    'general.trading.position.Buy': '買',
    'general.trading.position.Sell': '売',
    'general.trading.priceNotAvailable': '使用不可',
    'general.trading.refused': '拒否',
    'general.trading.Sell': '売り',
    'general.trading.stopBuyMoreAsk': '買逆指値注文価格が成行買値価格より低いため注文は直ぐに決済されます',
    'general.trading.stopSellLessBid': '売逆指値注文価格が成行売値価格より高いため注文は直ぐに決済されます',
    'general.trading.with offset': 'オフセット注文',
    'general.Tuesday': '火曜日',
    'property.general.WarnIfWrongOrderParam': '誤った注文操作時、警告する',
    'general.Wednesday': '水曜日',
    GTC: 'GTC',
    'helpPage.link': 'https://guide.traderevolution.com/project/',
    'homePage.link': 'http://traderevolution.com/',
    Ichimoku: '一目均衡表',
    'Instrument.Futures.contract': '契約内容',
    'Instrument.Level1.ExtendedFields.NormalMarketSize': '通常のマーケットサイズ',
    'Instrument.Spreadbet.bet': 'bets',
    'InstrumentDetailsPanel.(Indicative symbol)': '（参考となる銘柄）',
    'InstrumentDetailsPanel.1.GeneralInfo': '一般情報',
    'InstrumentDetailsPanel.2.TradingInfo': '取引情報',
    'InstrumentDetailsPanel.3.MarginReg': '必要証拠金',
    'InstrumentDetailsPanel.4.Fees': '手数料',
    'InstrumentDetailsPanel.5.Rebates': 'リベート',
    'InstrumentDetailsPanel.6.SessionInfo': 'マーケットスケジュール',
    'InstrumentDetailsPanel.7.Plans': '計画',
    'InstrumentDetailsPanel.AccruedInterest': '経過利子',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot': 'リクイディティリベート注文をロットで追加',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot': 'リクイディティリベートをロットで追加',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume': 'リクイディティリベートを数量で追加',
    'InstrumentDetailsPanel.AFTER_MARKET': 'アフターマーケット',
    'InstrumentDetailsPanel.Allow short positions': 'はい',
    'InstrumentDetailsPanel.AllowedOperations': '可能な操作',
    'InstrumentDetailsPanel.AllowedOrderTypes': '可能な注文操作',
    'InstrumentDetailsPanel.AllowShortPositions': 'ショートポジションの許可',
    'InstrumentDetailsPanel.Amount': '数量',
    'InstrumentDetailsPanel.AssetClass': '銘柄タイプ',
    'InstrumentDetailsPanel.BEFORE_MARKET': 'ビフォーマーケット',
    'InstrumentDetailsPanel.Cash': '現金',
    'InstrumentDetailsPanel.Close': '決済',
    'InstrumentDetailsPanel.Closed': '決済済',
    'InstrumentDetailsPanel.CloseOutDeadline': '決済期限',
    'InstrumentDetailsPanel.Commisions': '手数料',
    'InstrumentDetailsPanel.ContactMonth': '契約月',
    'InstrumentDetailsPanel.ContractMultiplier': '#hidden#',
    'InstrumentDetailsPanel.ContractSize': '契約サイズ',
    'InstrumentDetailsPanel.CouponCycle': 'クーポンサイクル',
    'InstrumentDetailsPanel.CouponRate': 'クーポンレート',
    'InstrumentDetailsPanel.CurrentSession': '現在のセッション',
    'InstrumentDetailsPanel.DeliveryMethod': '受渡方法',
    'InstrumentDetailsPanel.DeliveryStatus': '受渡状況',
    'InstrumentDetailsPanel.Depend from account': '口座による',
    'InstrumentDetailsPanel.Description': '説明',
    'InstrumentDetailsPanel.Exchange': '取引所',
    'InstrumentDetailsPanel.ExchangeTradeSession': '市場のセッション',
    'InstrumentDetailsPanel.Exp1': '資産',
    'InstrumentDetailsPanel.FaceValue': '額面金額',
    'InstrumentDetailsPanel.FirstTradeDate': '当初取引日',
    'InstrumentDetailsPanel.Futures': '先物取引',
    'InstrumentDetailsPanel.FuturesClass': '先物クラス',
    'InstrumentDetailsPanel.HiLimit': '許容高値',
    'InstrumentDetailsPanel.Holiday': '休日',
    'InstrumentDetailsPanel.HolidayDate': '日付',
    'InstrumentDetailsPanel.HolidayName': '休日名',
    'InstrumentDetailsPanel.Holidays list': '休日リスト',
    'InstrumentDetailsPanel.HolidayType': 'スケジュール',
    'InstrumentDetailsPanel.immediate': '即時',
    'InstrumentDetailsPanel.KEYNextHoliday': '次回の休日',
    'InstrumentDetailsPanel.LastTradeDate': '最終取引日',
    'InstrumentDetailsPanel.LotSize': 'ロットサイズ',
    'InstrumentDetailsPanel.LotStep': 'ロットサイズの最小変動幅',
    'InstrumentDetailsPanel.LowLimit': '許容安値',
    'InstrumentDetailsPanel.MAIN': '取引内時間',
    'InstrumentDetailsPanel.Margin': '必要証拠金',
    'InstrumentDetailsPanel.Margin_ByAccount': '口座毎の必要証拠金',
    'InstrumentDetailsPanel.MarginBuy': '買い',
    'InstrumentDetailsPanel.MarginDay': '日計りでの必要証拠金',
    'InstrumentDetailsPanel.MarginDayBuy': '日計り買いでの必要証拠金',
    'InstrumentDetailsPanel.MarginDaySell': '日計り売りでの必要証拠金',
    'InstrumentDetailsPanel.MarginInAccountCurrency': '#hidden#',
    'InstrumentDetailsPanel.MarginOvernight': 'オーバーナイトでの必要証拠金',
    'InstrumentDetailsPanel.MarginOvernightBuy': 'オーバーナイト買いでの必要証拠金',
    'InstrumentDetailsPanel.MarginOvernightSell': 'オーバーナイト売りでの必要証拠金',
    'InstrumentDetailsPanel.MarginSell': '売り',
    'InstrumentDetailsPanel.MaturityDate': '満期日',
    'InstrumentDetailsPanel.MaximumLot': '最大ロットサイズ',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol': '最大ポジション数量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday': '日中の最大ポジション数量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery': '受渡の最大ポジション数量',
    'InstrumentDetailsPanel.MinimalLot': '最小ロットサイズ',
    'InstrumentDetailsPanel.NextPaymentDate': '次回イールド支払い',
    'InstrumentDetailsPanel.No': 'いいえ',
    'InstrumentDetailsPanel.non trading day': 'クローズ',
    'InstrumentDetailsPanel.None': 'なし',
    'InstrumentDetailsPanel.NormalMarketSize': '#hidden#',
    'InstrumentDetailsPanel.Not allow short positions': 'いいえ',
    'InstrumentDetailsPanel.NoticeDate': '通知日',
    'InstrumentDetailsPanel.onlyCloseOrders': 'クローズ注文のみ',
    'InstrumentDetailsPanel.Open': 'オープン',
    'InstrumentDetailsPanel.OrderPerLot': 'ロット毎注文',
    'InstrumentDetailsPanel.PerFill': '約定毎',
    'InstrumentDetailsPanel.PerLot': 'ロット毎約定',
    'InstrumentDetailsPanel.PerOrder': '注文毎',
    'InstrumentDetailsPanel.PerOrderVolume': '注文量、%',
    'InstrumentDetailsPanel.PerPhoneTransaction': '電話取引毎',
    'InstrumentDetailsPanel.PerTransaction': '取引毎',
    'InstrumentDetailsPanel.PerVolume': '約定量、％',
    'InstrumentDetailsPanel.Physically': '物理的',
    'InstrumentDetailsPanel.POSTCLOSE': 'ポスト・マーケット',
    'InstrumentDetailsPanel.PREOPEN': 'プレ・マーケット',
    'InstrumentDetailsPanel.PreviousPaymentDate': '過去イールド支払い',
    'InstrumentDetailsPanel.Price': '価格',
    'InstrumentDetailsPanel.ProductType': '商品の種類',
    'InstrumentDetailsPanel.QuotiongCurrency': '決済通貨',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot': 'ロット毎の流動性リベート注文を削除',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot': 'ロット毎の流動性リベートを削除',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume': 'ボリューム毎の流動性リベートを削除',
    'InstrumentDetailsPanel.SessionStatus': '#hidden#',
    'InstrumentDetailsPanel.SettlementDate': '決済日',
    'InstrumentDetailsPanel.shortened': '短縮日',
    'InstrumentDetailsPanel.StrikePrice': '権利行使価格',
    'InstrumentDetailsPanel.SwapBuy': '買スワップ',
    'InstrumentDetailsPanel.SwapSell': '売スワップ',
    'InstrumentDetailsPanel.Symbol': '銘柄',
    'InstrumentDetailsPanel.T+0': 'Tプラス0(当日中)',
    'InstrumentDetailsPanel.TickCoast': 'ティックコスト',
    'InstrumentDetailsPanel.TickSize': 'ティックサイズ',
    'InstrumentDetailsPanel.TradindBlockedOnSession': 'セッションでトレーディング停止',
    'InstrumentDetailsPanel.TradingBalance': '決済期間',
    'InstrumentDetailsPanel.TradingHalt': 'トレーディング停止',
    'InstrumentDetailsPanel.TradingStatus': 'マーケットの開場状況',
    'InstrumentDetailsPanel.Underlier': '原資産',
    'InstrumentDetailsPanel.VAT': '消費税',
    'InstrumentDetailsPanel.Volume': '約定量',
    'InstrumentDetailsPanel.VolumeWithMinPD': '約定量％、最小p/d付',
    'InstrumentDetailsPanel.working': 'オープン',
    'InstrumentDetailsPanel.Yes': 'はい',
    'InstrumentDetailsPanel.Yield': 'イールド',
    'InstrumentDetailsPanel.YTM': 'YTM, %',
    'InstrumentDetailsPanel.YTM.RefreshLink': '更新',
    IOC: 'IOC',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders': 'バインドされた注文はマーケットで実行できません',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp': '市場での実行は、SL / TPでは許可されていない',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType': '注文タイプまたは成行注文タイプの変更は許可されていません',
    'IsAllowedResponceReason.InstrumentForAccountDelivery': 'アカウント受渡用銘柄',
    'IsAllowedResponceReason.InstrumentTradingMode': '本銘柄の取引は許可されていません',
    'IsAllowedResponceReason.LockTrading': 'WebStationによる取引がロックされています',
    'IsAllowedResponceReason.LockTradingByPassword': 'ターミナルの下部に取引パスワードを入力してください',
    'IsAllowedResponceReason.NoLastQuote': '最後のクオートなし',
    'IsAllowedResponceReason.NotAllowedByOrderStaus': '注文ステータスで許可されていない操作',
    'IsAllowedResponceReason.NotAllowedPositionCloseOnly': 'ポジションのクローズのみが許可されています',
    'IsAllowedResponceReason.NotAllowedRouteOrderType': '選択したTIFと注文タイプはサポートされていないため、注文を作成できません。',
    'IsAllowedResponceReason.NotClosablePosition': 'クローズできないポジション',
    'IsAllowedResponceReason.NotLoadCache': 'キャッシュがロードされませんでした',
    'IsAllowedResponceReason.NotVisibleInstrumentRoute': '銘柄またはルートが可視化されていません',
    'IsAllowedResponceReason.OperationNotAllowedByOrderType': '操作は本注文タイプでは許可されていません',
    'IsAllowedResponceReason.OperationNotAllowedBySession': '操作は本セッションでは許可されていません',
    'IsAllowedResponceReason.OrderTypeNotAllowedBySession': '注文タイプは本セッションでは許可されていません',
    'IsAllowedResponceReason.RouteNotRunning': '銘柄取引は許可されていません。',
    'IsAllowedResponceReason.RuleAdministration': 'ルール管理',
    'IsAllowedResponceReason.RuleClosePositionDisable': 'ルールによりポジションのクローズは許可されていません',
    'IsAllowedResponceReason.RuleFunctionSelfTrading': 'ルール機能の自己取引',
    'IsAllowedResponceReason.RuleFunctionTrading': '本取引はルールで許可されていません',
    'IsAllowedResponceReason.RuleOCO': 'ルールOCO',
    'IsAllowedResponceReason.RuleSlTpNotAllowed': 'SL / TPはルールで許可されていません',
    'IsAllowedResponceReason.RuleTrStop': 'トレーリングストップのルール',
    'IsAllowedResponceReason.Unknown': '原因不明',
    'Keltner Channel': 'ケルトナーチャネル',
    Limit: '指値',
    'Linear Weighted Moving Average': '線形加重移動平均(LWMA)',
    LOC: 'LOC',
    LOO: 'LOO',
    Manual: 'マニュアル',
    Market: '成行',
    'MarketDepthPanel.ColoringMethod.ByPriceLVL': '気配値レベルごとに色付け',
    'MarketDepthPanel.ColoringMethod.RelativeToVolume': '取引量による色付け',
    'MarketDepthPanel.ColoringMethod.SizeHistogram': '棒グラフで取引量を色付け表示',
    'McGinley Dynamic': 'マッギンリーダイナミック',
    'MDLotsMode.General': '一般デフォルト',
    'MDLotsMode.Lots': 'ロット',
    'MDLotsMode.RealSize': '実サイズ',
    'MessageBox.ThemeChanged.Title': '警告',
    MOC: 'MOC',
    'Modified Moving Average': '修正移動平均線(MMA)',
    Momentum: 'モメンタム',
    MOO: 'MOO',
    'Moving Average Envelope': '移動平均エンベロープ(MAE)',
    'Moving Average/Convergence Divergence': 'MACD',
    'NetTrader.SaveWorkspace.Save': 'ワークスペースを保存',
    'NumericComboCtrl.Edit': 'デフォルト設定',
    OCO: 'OCO',
    'On Balance Volume': 'オンバランスボリューム(OBV)',
    OnClose: 'クローズ時',
    OnOpen: 'オープン時',
    'Order.ExecutionType.ACCEPTED': '受付',
    'Order.ExecutionType.ACTIVATED': '有効',
    'Order.ExecutionType.CANCELED': '取消',
    'Order.ExecutionType.FILLED': '約定',
    'Order.ExecutionType.NEW': '受付',
    'Order.ExecutionType.PART_FILLED': '一部約定',
    'Order.ExecutionType.REFUSED': '拒否',
    'Order.ExecutionType.REPLACED': '変更',
    'Order.ExecutionType.RESTATED': '再注文',
    'Order.ExecutionType.UNKNOWN': '不明',
    'OrderEntry.InfoBlock.After trade funds': 'トレード資金後',
    'OrderEntry.InfoBlock.Availible funds': '利用可能資金',
    'OrderEntry.InfoBlock.Balance': '残高',
    'OrderEntry.InfoBlock.Fee': '手数料',
    'OrderEntry.InfoBlock.Gross': 'グロス',
    'OrderEntry.InfoBlock.Impact on portfolio': 'ポートフォリオへの影響',
    'OrderEntry.InfoBlock.Init. margin': '必要証拠金',
    'OrderEntry.InfoBlock.Maint. margin': '維持証拠金',
    'OrderEntry.InfoBlock.Margin availible': '余剰証拠金',
    'OrderEntry.InfoBlock.Net': 'ネット',
    'OrderEntry.InfoBlock.Spread in loss': '初期ロススプレッド',
    'OverlayScreen.barsBodyLabel': '実体',
    'OverlayScreen.barsBorderLabel': '実体の外枠',
    'OverlayScreen.barsHiLowLabel': 'ライン',
    'OverlayScreen.cancelButton': 'キャンセル',
    'OverlayScreen.dataLabel': '適用価格',
    'OverlayScreen.dojiLabel': '同時線',
    'OverlayScreen.histogramLineLabel': 'ヒストグラムのライン色',
    'OverlayScreen.instrumentLabel': '銘柄',
    'OverlayScreen.Main scale': '右側の価格スケールに合わせて表示',
    'OverlayScreen.name': 'チャートを重ねる',
    'OverlayScreen.numberOverlaysError': '5銘柄以上のチャートを重ねることはできません',
    'OverlayScreen.okButton': 'OK',
    'OverlayScreen.Overlays': 'チャートを重ねる',
    'OverlayScreen.priceLabel': '価格スケール',
    'OverlayScreen.priceMarkerCheckbox': '価格軸に価格を表示',
    'OverlayScreen.Separate scale': '左側の価格スケールに合わせて表示',
    'OverlayScreen.solidPriceLabel': '範囲',
    'OverlayScreen.styleLabel': '種類',
    'OverlayScreen.wickLabel': 'ヒゲ',
    'panel.accountDetails.Groups.1.General': '全般',
    'panel.accountDetails.Groups.2.Margin': '証拠金',
    'panel.accountDetails.Groups.3.AccountActivity': 'アカウントアクティビティ',
    'panel.accountDetails.Groups.4.TodayResults': '本日の結果',
    'panel.accountDetails.Groups.5.RiskManagement': 'リスク管理',
    'panel.accountDetails.Groups.55.Plans': '予定',
    'panel.accountDetails.Groups.6.Info': '情報',
    'panel.AccountDetailsPanel': 'アカウント詳細',
    'panel.AccountDetailsPanel.InfoTooltip': 'クリック後に表示される項目一覧から、ドラッグ＆ドロップすることで、表示項目を変更することができます',
    'panel.accountDetails.Groups.Rico risks': 'アカウント詳細',
    'panel.accountDetails.Groups.Kyrrex risks': 'アカウント詳細',
    'panel.accountDetails.Groups.AccountActivity': '2. アカウントアクティビティ',
    'panel.accountDetails.Groups.General': '1.全般',
    'panel.accountDetails.Groups.Info': '5.情報',
    'panel.accountDetails.Groups.RiskManagement': '4.リスク管理',
    'panel.accountDetails.Groups.TodayResults': '3.本日の結果',
    'panel.accountDetails.Rico risks.AVLM': '余剰証拠金',
    'panel.accountDetails.Rico risks.BCKA': '保留合計金額',
    'panel.accountDetails.Rico risks.BKBL': '保留金',
    'panel.accountDetails.Rico risks.BMBM': 'BMFロックマージン',
    'panel.accountDetails.Rico risks.BOPT': '残高オプション',
    'panel.accountDetails.Rico risks.D2PB': 'D2有効評価額',
    'panel.accountDetails.Rico risks.D3PB': 'D3有効評価額',
    'panel.AccountLink.SymbolLink.ToolTip': 'シンボルリンク',
    'panel.AccountLink.ToolTip': 'アカウントリンク',
    'panel.AccountLink.CanFilterToolTip': 'パネルのデータはアカウントでフィルタリングされます',
    'panel.AccountLinkButton.ToolTip': 'この機能は、アカウント毎にポジションと注文をフィルタリングします アカウントを切り替えると、全てのパネルにて同期されます',
    'panel.accounts.AccountLink.ToolTip': 'アカウントリンク有効',
    'panel.accounts': '取引口座',
    'panel.accounts.AccountDescription': '#hidden#',
    'panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached': '日次損失限度額に達しました',
    'panel.accounts.accountDetails.RiskManagement.Trading.Max day volumereached': '日次最大取引数量に達しました',
    'panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)': '注文フロー上限（1日あたり）',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled': '無効',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled': '有効',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Active': 'アクティブ',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed': 'オートクローズ',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed': 'クロースド',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules': 'リスクルールによって無効にされた取引',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning': '停止の可能性に関する警告',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached': '最大トレーリングドローダウンに到達しました',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend': 'サスペンド',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached': '未実現損失上限に達しました',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached': '週次損失限度額に達しました',
    'panel.accounts.AccountMode': '口座種別',
    'panel.accounts.AccountType': '口座種別',
    'panel.accounts.AccountValue': 'アカウント評価',
    'panel.accounts.AllowOvernightTrading': 'ロールオーバー取引の許可',
    'panel.accounts.AllowTradingPrePost': 'プレ/ポストマーケットでの取引',
    'panel.accounts.Asset': '資産',
    'panel.accounts.AssetDescription': '資産の説明',
    'panel.accounts.AssetType': '資産の種別',
    'panel.accounts.AvailableMargin': '注文可能額',
    'panel.accounts.Balance': '残高',
    'panel.accounts.BalancePlusAllRisks': '有効評価額(株式除外)',
    'panel.accounts.BeginBalance': '本日の総損益',
    'panel.accounts.BlockedByOrders': '注文に使用されているマージン',
    'panel.accounts.BlockedByPamm': '投資に使用されている資金',
    'panel.accounts.BlockedForStocks': '#hidden#',
    'panel.accounts.BlockedForStocksNew': '株式取引にロックされている金額',
    'panel.accounts.CashBalance': '現金残高',
    'panel.accounts.ClientType': 'ターミナルの種別',
    'panel.accounts.Commissions': 'コミッション',
    'panel.accounts.CreditValue': 'クレジット残高',
    'panel.accounts.CurBalance': '有効評価額',
    'panel.accounts.CurMargin': '必要証拠金率, %',
    'panel.accounts.CurrentDailyLoss': '日次総損失限度額',
    'panel.accounts.CurrentFundCapital': '現在の資金残高',
    'panel.accounts.CurrentStatus': '取引状況',
    'panel.accounts.CurrentStatus.descr': '取引状況',
    'panel.accounts.CurrentWeeklyLoss': '週次総損失限度額',
    'panel.accounts.Email': 'Email',
    'panel.accounts.Estimate value': '見積値',
    'panel.accounts.FundCapitalGain': 'キャピタルゲイン額',
    'panel.accounts.FundsUsed': '使用資金',
    'panel.accounts.FundsUsedPercent': '使用資金、％',
    'panel.accounts.GrossPNL': '本日のスポット損益',
    'panel.accounts.InsertOnBalance': 'バランスに挿入',
    'panel.accounts.InterestRate': '金利, %',
    'panel.accounts.IntradayRiskManagement': 'リスク管理状況',
    'panel.accounts.Leverage': 'レバレッジ',
    'panel.accounts.LockedBalance': '保留金',
    'panel.accounts.LockedBy': 'ロック済み',
    'panel.accounts.Login': '取引口座',
    'panel.accounts.MaintMargin': '維持証拠金率, %',
    'panel.accounts.MarginAndRisks': 'マージンとリスク',
    'panel.accounts.MarginAvailableReal': '余剰証拠金',
    'panel.accounts.MarginDeficiency': 'マージン不足',
    'panel.accounts.MarginForOthers': '#hidden#',
    'panel.accounts.MarginRequirement': '維持証拠金',
    'panel.accounts.MarginStopOut': '維持証拠金',
    'panel.accounts.MarginStopTrade': '取引を停止',
    'panel.accounts.MarginSurplus': 'マージン余剰',
    'panel.accounts.MarginUsedNew': '必要証拠金',
    'panel.accounts.MarginWarning': 'マージンコール',
    'panel.accounts.marginWarnPercent': 'マージンコールレベル',
    'panel.accounts.MarketDataCharging': '市場データの課金',
    'panel.accounts.MaxDailyLossLimit': '日次損失限度額',
    'panel.accounts.MaxDailyProfit': '日次利益目標',
    'panel.accounts.MaxDayVolume': '#hidden#',
    'panel.accounts.MaximumLot': '最大ロットサイズ',
    'panel.accounts.MaxOrderAmount': '最大注文数',
    'panel.accounts.MaxOrderCapital': '最大注文可能額',
    'panel.accounts.MaxOrdersPerDay': '＃注文フロー制限（1日あたり）',
    'panel.accounts.MaxPendingOrders': '＃最大予約注文数',
    'panel.accounts.MaxPendingOrdersValue': '最大未決注文額',
    'panel.accounts.MaxPositionQtyPerSymbol': 'シンボル毎最大数量',
    'panel.accounts.MaxPositions': '＃最大ポジション数',
    'panel.accounts.MaxWeekLostLimit': '週次損失限度額',
    'panel.accounts.MinMargin': 'ポジションに使用されるマージン',
    'panel.accounts.Murkups': 'マークアップ',
    'panel.accounts.N/A_TelephoneNumber': '登録なし',
    'panel.accounts.OptionPremiumReq': '必要オプションプレミアム',
    'panel.accounts.OptionValue': 'オプション価値',
    'panel.accounts.Orders': '予約注文数',
    'panel.accounts.Owner': '所有者',
    'panel.accounts.Positions': 'ポジション数',
    'panel.accounts.PowerOfAttorney': '委任状',
    'panel.accounts.Profit': '未評価損益',
    'panel.accounts.ProfitNet': '未実現損益',
    'panel.accounts.Role': 'ファンドの種類',
    'panel.accounts.Spreads': 'スプレッド',
    'panel.accounts.Status': '状態',
    'panel.accounts.Collateral': '#hidden#',
    'panel.accounts.StocksLiquidity': '株式流動性',
    'panel.accounts.StocksOrdersReq': '必要株式注文数',
    'panel.accounts.StocksValue': '株式価値',
    'panel.accounts.stopOutPercent': 'ロスカットレベル',
    'panel.accounts.stopTradingPercent': '取引停止レベル',
    'panel.accounts.Swap': 'スワップ',
    'panel.accounts.TelephoneNumber': '電話番号',
    'panel.accounts.TodayFees': '本日の手数料',
    'panel.accounts.TodayRebates': '本日のリベート',
    'panel.accounts.TodaySwaps': '#hidden#',
    'panel.accounts.TodayTrades': '本日の取引数',
    'panel.accounts.TodayTurnover': '本日の売上高',
    'panel.accounts.TodayVolume': '本日の純入金額',
    'panel.accounts.TotalMaxPositionsQty': '最大ポジション数',
    'panel.accounts.TrailingDrawdownLevel': '最大ドローダウン',
    'panel.accounts.UnrealizedLoss': '未実現損失',
    'panel.accounts.UnrealizedLossLimit': '未実現損失上限額',
    'panel.accounts.UnsettledCash': '未決済金額',
    'panel.accounts.UserGroup': 'ユーザーグループ',
    'panel.accounts.UserID': 'ユーザーID',
    'panel.accounts.UserLogin': 'ユーザーログイン',
    'panel.accounts.VolumeLimitForEquities': '株式数量上限',
    'panel.accounts.VolumeLimitForFutures': '先物数量上限',
    'panel.accounts.VolumeLimitForOptions': 'オプション数量上限',
    'panel.accounts.WithdrawalAvailable': '出金可能額',
    'panel.accounts.WaivedMargin': '放棄されたマージン',
    'panel.accounts.WaivedMargin.descr': 'アカウントの放棄されたマージン',
    'panel.assetBalances': '取引口座',
    'panel.backoffice': 'バックオフィス',
    'panel.backoffice.You need to add connection!': '接続先の追加が必要です!',
    'panel.positionsBalance': 'ポジション残高',
    'panel.caption.NotAllowedMode': '現在のパネルは規則により許可されていません',
    'panel.caption.showHideToolbar': 'ツールバーの表示',
    'panel.chart': 'チャート',
    'panel.close': '決済',
    'panel.closePosition.Gross_P/L_ToClose': 'グロス損益で決済',
    'panel.closePosition.QunatityToClose': '数量で決済',
    'panel.copypanel': 'パネルの複製',
    'panel.detail': '配信の詳細',
    'panel.detail.AuctionEndTime': '#hidden#',
    'panel.detail.CBAuctionStart': '#hidden#',
    'panel.detail.change': '変動幅',
    'panel.detail.changePr': '前日比,%',
    'panel.detail.CurrentSession': '#hidden#',
    'panel.detail.high': '高値',
    'panel.detail.last': '終値',
    'panel.detail.Limit.High': '#hidden#',
    'panel.detail.Limit.Low': '#hidden#',
    'panel.detail.low': '安値',
    'panel.detail.NormalMarketSize': '#hidden#',
    'panel.detail.OffExchangeValue': '#hidden#',
    'panel.detail.OffExchangeVolume': '#hidden#',
    'panel.detail.open': '始値',
    'panel.detail.RemainingQty': '#hidden#',
    'panel.detail.RemainingQtySide': '#hidden#',
    'panel.detail.TradedValue': '#hidden#',
    'panel.detail.TradingStatus': '#hidden#',
    'panel.detail.volume': '取扱量',
    'panel.EventLog.Action': 'イベント',
    'panel.EventLog.Date': '日時',
    'panel.EventLog.eventType.alerts': 'アラート',
    'panel.EventLog.eventType.comment': 'コメント',
    'panel.EventLog.eventType.disconnect': '未接続',
    'panel.EventLog.eventType.exception': 'その他',
    'panel.EventLog.eventType.info': '情報',
    'panel.EventLog.eventType.none': 'なし',
    'panel.EventLog.eventType.system': 'システム',
    'panel.EventLog.eventType.trading': '取引',
    'panel.EventLog.eventType.updater': 'アップデート',
    'panel.EventLog.menu.Clear': '消去',
    'panel.EventLog.Message': '詳細',
    'panel.EventLog.NamePanel': 'イベントログ',
    'panel.EventLog.Roundtrip': '往復通信速度,ミリセカンド',
    'panel.EventLog.Type': '種別',
    'panel.GridPanel.listButton.ToolTip': 'パネルのシンボルを選択してください',
    'panel.GridPanel.refreshAllCharts.ToolTip': '全チャート更新',
    'panel.GridPanel.resetMI.Text': 'パネルサイズリセット',
    'panel.informer': 'ウォッチリスト',
    'panel.instrumentInfo.AvgPrice': '平均価格:',
    'panel.instrumentInfo.P/L': 'P/L:',
    'panel.instrumentInfo.Qty': '数量:',
    'panel.instruments': '銘柄',
    'panel.instrumentsInfo': '銘柄情報',
    'panel.knowledgebase': 'ナレッジベース',
    'panel.level2': '板情報',
    'panel.Level2.Age': '#hidden#',
    'panel.level2.Auction': 'AUC',
    'panel.Level2.AvgPrice': '平均価格',
    'panel.Level2.Contr. CCY Total value': '相手通貨換算価値合計',
    'panel.Level2.Contr. CCY value': '相手通貨換算価値',
    'panel.Level2.DayTradeVolume': '取引数量',
    'panel.Level2.menu.View': '表示',
    'panel.Level2.menu.View.InstrInfo': 'ポジションバー',
    'panel.Level2.menu.View.Level1': 'レベル1バー',
    'panel.Level2.menu.View.Order Entry': '注文パネル',
    'panel.Level2.MPID': 'MPID',
    'panel.Level2.Number': '注文',
    'panel.Level2.oe.BuyButton': '買い',
    'panel.Level2.oe.SellButton': '売り',
    'panel.Level2.Price': '価格',
    'panel.Level2.Size': '取引サイズ',
    'panel.Level2.Source': 'ソース',
    'panel.Level2.Time': '時間',
    'panel.Level2.TotalVol': '総取引サイズ',
    'panel.log': 'イベントログ',
    'panel.menu.GroupBy': 'グループ化',
    'panel.menu.new': '新規注文',
    'panel.menu.Remove': '削除',
    'panel.menu.resetToDefault': '初期化',
    'panel.menu.renameList': 'クリックしてリスト名を変更',
    'panel.menu.removeList': 'クリックしてリストを削除',
    'panel.menu.renameTemplate': 'クリックしてテンプレート名を変更',
    'panel.menu.removeTemplate': 'クリックしてテンプレートを削除',
    'panel.menu.saveList': 'クリックしてテンプレートを上書き保存',
    'panel.neworder': '注文パネル',
    'panel.newOrderEntry.accountLabel': '取引口座',
    'panel.newOrderEntry.AddAskButton': 'Askで売り',
    'panel.newOrderEntry.AddBitButton': 'Bidで買い',
    'panel.newOrderEntry.amountLabel': '数量',
    'panel.newOrderEntry.BuyAskButton': 'Askで買い',
    'panel.newOrderEntry.BuyMarketButton': '成行買い',
    'panel.newOrderEntry.buySideCheckBox': '買い',
    'panel.newOrderEntry.instrumentLabel': '銘柄',
    'panel.newOrderEntry.limitPricePanel': '指値価格',
    'panel.newOrderEntry.placeOrderButton': '新規注文の発注',
    'panel.newOrderEntry.ProductType': '商品の種類',
    'panel.newOrderEntry.RefreshMarginReq': 'クリックでマージン値の更新,もしくは30秒お待ちください',
    'panel.newOrderEntry.riskDetailsLabel': '証拠金',
    'panel.newOrderEntry.SellBidButton': 'Bidで売り',
    'panel.newOrderEntry.SellMarketButton': '成行売り',
    'panel.newOrderEntry.sellSideCheckBox': '売り',
    'panel.newOrderEntry.Side': '売買種別',
    'panel.newOrderEntry.singleOrderRadio': 'シングル',
    'panel.newOrderEntry.slPriceRisk': 'S/L値',
    'panel.newOrderEntry.slPriceRiskOffset': 'S/L設定値',
    'panel.newOrderEntry.stopLossCheckBox': 'S/L値',
    'panel.newOrderEntry.stopLossCheckBox.offset': 'S/L設定値',
    'panel.newOrderEntry.stopPricePanel': '逆指値価格',
    'panel.newOrderEntry.takeProfitCheckBox': 'T/P値',
    'panel.newOrderEntry.takeProfitCheckBox.offset': 'T/P設定値',
    'panel.newOrderEntry.tifPanel': '有効期限',
    'panel.newOrderEntry.tpPriceRisk': 'T/P値',
    'panel.newOrderEntry.tpPriceRiskOffset': 'T/P設定値',
    'panel.newOrderEntry.trstopOffset': 'トレーリングストップオフセット',
    'panel.newOrderEntry.typePanel': '注文種別',
    'panel.newOrderEntry.stopLossNumeric.ToolTip.offset': 'S/Lをオフセット値で入力すると価格が表示されます 価格入力による設定に変更したい場合は、一般設定>取引のデフォルト設定にて変更できます',
    'panel.newOrderEntry.stopLossNumeric.ToolTip': 'S/Lを設定する価格を入力します オフセット値による設定に変更したい場合は、一般設定>取引のデフォルト設定にて変更できます',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset': 'T/Pを設定する価格を入力します オフセット値による設定に変更したい場合は、一般設定>取引のデフォルト設定にて変更できます',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip': 'T/Pを設定する価格を入力します オフセット値による設定に変更したい場合は、一般設定>取引のデフォルト設定にて変更できます',
    'panel.NewTrades.Account': '取引口座',
    'panel.NewTrades.Amount': '数量',
    'panel.NewTrades.Bought': '購入額',
    'panel.NewTrades.Commission': '決済手数料',
    'panel.NewTrades.Commission.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Date': '日付/時間',
    'panel.NewTrades.Exchange': '#hidden#',
    'panel.NewTrades.ExpirationDate': '有効期限',
    'panel.NewTrades.ExternalOrderId': '#hidden#',
    'panel.NewTrades.ExternalPrice': '外部価格',
    'panel.NewTrades.ExternalTradeId': '外部ID',
    'panel.NewTrades.instrType': '銘柄タイプ',
    'panel.NewTrades.Instrument': '銘柄',
    'panel.NewTrades.Login': 'ログイン',
    'panel.NewTrades.Net_PL': '純損益',
    'panel.NewTrades.Net_PL.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Operation': '売買種別',
    'panel.NewTrades.OrderId': '注文ID',
    'panel.NewTrades.OrderType': '注文種別',
    'panel.NewTrades.Price': '価格',
    'panel.NewTrades.ProductType': '商品の種類',
    'panel.NewTrades.PurchasePrice': '保有額',
    'panel.NewTrades.RealizedProfitLoss': 'スポット損益',
    'panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Rebates': 'リベート',
    'panel.NewTrades.Route': 'ルート',
    'panel.NewTrades.Sold': '売却額',
    'panel.NewTrades.StrikePrice': '権利行使価格',
    'panel.NewTrades.Time': '時間',
    'panel.NewTrades.TradeID': '取引ID',
    'panel.NewTrades.TradeIDLabel': '#hidden#',
    'panel.OrderBook.Account': '取引口座',
    'panel.OrderBook.Action': '売買種別',
    'panel.OrderBook.Bought': '購入額',
    'panel.OrderBook.Date': '日付/時間',
    'panel.OrderBook.DislocedQty': '#hidden#',
    'panel.OrderBook.Event': '操作ログ',
    'panel.OrderBook.ExpirationDate': '有効期限',
    'panel.OrderBook.ExternalOrderID': '#hidden#',
    'panel.OrderBook.FilledQuantity': '#hidden#',
    'panel.OrderBook.instrType': '銘柄タイプ',
    'panel.OrderBook.Instrument': '銘柄',
    'panel.OrderBook.Login': 'ログイン',
    'panel.OrderBook.Message': 'メッセージ',
    'panel.OrderBook.OrderNumber': '注文ID',
    'panel.OrderBook.Price': '価格',
    'panel.OrderBook.ProductType': '商品の種類',
    'panel.OrderBook.Quantity': '数量',
    'panel.OrderBook.ReminingQuantity': '#hidden#',
    'panel.OrderBook.RemoteID': 'リモートID',
    'panel.OrderBook.Route': 'ルート',
    'panel.OrderBook.Sold': '売却額',
    'panel.OrderBook.StopPrice': 'ストップ価格',
    'panel.OrderBook.StrikePrice': '権利行使価格',
    'panel.OrderBook.TIF': '有効期限',
    'panel.OrderBook.Time': '時間',
    'panel.OrderBook.TraderID': 'ユーザーによって編集された',
    'panel.OrderBook.Type': '注文方法',
    'panel.orderEntry': '注文パネル',
    'panel.orderHistory': '注文履歴',
    'panel.orderHistory.print': '#hidden#',
    'panel.orders': '未約定注文',
    'panel.orders.Account': '取引口座',
    'panel.orders.action': '売買種別',
    'panel.orders.AvgFilledPrice': '#hidden#',
    'panel.orders.BindTo': '保留',
    'panel.orders.Cancel': 'キャンセル',
    'panel.orders.Comment': '#hidden#',
    'panel.orders.Comments': 'コメント',
    'panel.orders.Ct_Ahead': '',
    'panel.orders.CurrentPrice': '現在価格',
    'panel.orders.date': '日付',
    'panel.orders.date_time': '日付/時間',
    'panel.orders.disclosedQty': '#hidden#',
    'panel.orders.Event': '操作ログ',
    'panel.orders.Exchange': '取引所',
    'panel.orders.ExpirationDate': '有効期限',
    'panel.orders.ExternalOrderId': '#hidden#',
    'panel.orders.ExternalOrderStatus': '#hidden#',
    'panel.orders.GroupId': 'MAMグループID',
    'panel.orders.InitReq': '1ロット当たりの必要証拠金',
    'panel.orders.Instr_Type': '銘柄タイプ',
    'panel.orders.Login': 'ログイン',
    'panel.orders.Market': '成行',
    'panel.orders.menu.CancelAllByInstrument': '選択した銘柄を全てキャンセル',
    'panel.orders.menu.CancelAllForAccount': '選択した口座を全てキャンセル',
    'panel.orders.menu.CancelAllOrders': '全ての注文をキャンセル',
    'panel.orders.menu.CancelOrder': '注文のキャンセル',
    'panel.orders.menu.ExecuteAsMarket': '成行注文への変更',
    'panel.orders.menu.ExecuteAsMarket.question': '成行注文を実行してもよいですか?',
    'panel.orders.menu.ModifyOrder': '注文の変更...',
    'panel.orders.menu.Remove': '注文のキャンセル',
    'panel.orders.OCOGrouID': 'OCOグループID',
    'panel.orders.price': '予約価格',
    'panel.orders.ProductType': '商品の種類',
    'panel.orders.quantity': '数量',
    'panel.orders.QuantityFilled': '約定数量',
    'panel.orders.QuantityRemaining': '未約定数量',
    'panel.orders.Reference': '注文ID',
    'panel.orders.RemoteID': 'リモートID',
    'panel.orders.Route': 'ルート',
    'panel.orders.SL': 'S/L値',
    'panel.orders.SL_Offset': 'S/L設定値',
    'panel.orders.SL_Price': 'S/L値',
    'panel.orders.Status': '注文状況',
    'panel.orders.StopPrice': 'ストップ価格',
    'panel.orders.StrikePrice': '権利行使価格',
    'panel.orders.symbol': '銘柄',
    'panel.orders.symbolDescription': '銘柄の説明',
    'panel.orders.TIF': '有効期限',
    'panel.orders.time': '時間',
    'panel.orders.Token': '注文ID',
    'panel.orders.TP': 'TP',
    'panel.orders.TP_Offset': 'T/P設定値',
    'panel.orders.TP_Price': 'T/P値',
    'panel.orders.TraderID': 'トレーダーID',
    'panel.orders.type': '種類',
    'panel.orders.UserGroup': 'ユーザーグループ',
    'panel.positions': 'ポジション',
    'panel.positions.Account': '取引口座',
    'panel.positions.AvailableForSell': '#hidden#',
    'panel.positions.Basis': '始値',
    'panel.positions.Close': '決済',
    'panel.positions.comments': 'コメント',
    'panel.positions.commissions': '手数料',
    'panel.positions.commissions.InstrumentCurrency': '#hidden#',
    'panel.positions.confirmation.Reverse': 'リバース注文',
    'panel.positions.confirmation.ReverseAllPositions': '全てのポジションをリバースしますか？',
    'panel.positions.confirmation.ReverseByInstrument': '選択された銘柄と取引口座のすべてのポジションをリバースしますか？',
    'panel.positions.confirmation.ReverseSelectedPositions': '選択したポジションをリバースしますか？',
    'panel.positions.cur_price': '現在価格',
    'panel.positions.date_time': '日付/時間',
    'panel.positions.Delta': 'デルタ',
    'panel.positions.ExpDate': '有効期限',
    'panel.positions.exposition_usd': '保有エクスポージャー',
    'panel.positions.Gamma': 'ガンマ',
    'panel.positions.GroupId': 'MAMグループID',
    'panel.positions.InitReq': '必要証拠金',
    'panel.positions.IV': 'IV',
    'panel.positions.Login': 'ログイン',
    'panel.positions.MaintReq': '維持証拠金',
    'panel.positions.MarginSuplus': '余剰証拠金',
    'panel.positions.menu.Close': 'ポジションを決済',
    'panel.positions.menu.Modify': 'ポジションを変更',
    'panel.positions.menu.ShowToolbar': 'ツールバーの表示',
    'panel.positions.menu.ShowTotals': '総計の表示',
    'panel.positions.menu.DisplayTrades': '取引の表示',
    'panel.positions.menu.View': '表示',
    'panel.positions.Net_PL': '総損益額',
    'panel.positions.Net_PL.InstrumentCurrency': '#hidden#',
    'panel.positions.Operation': '売買種別',
    'panel.positions.PL_ticks': '損益,設定値',
    'panel.positions.pos_number': 'ポジションID',
    'panel.positions.PositionValue': '保有ポジションの価値',
    'panel.positions.ProductType': '商品の種類',
    'panel.positions.profit_usd': 'スポット損益',
    'panel.positions.profit_usd.InstrumentCurrency': '#hidden#',
    'panel.positions.Qunatity': '数量',
    'panel.positions.RealizedPnL': '#hidden#',
    'panel.positions.RealizedPnL.InstrumentCurrency': '#hidden#',
    'panel.positions.Rho': 'ロー',
    'panel.positions.route': 'ルート',
    'panel.positions.SL': 'S/L値',
    'panel.positions.SL_Value': 'SL到達時の損失額',
    'panel.positions.strike': '権利行使価格',
    'panel.positions.swaps': 'スワップ',
    'panel.positions.Symbol': '銘柄',
    'panel.positions.SymbolDescription': '銘柄の説明',
    'panel.positions.Theta': 'セータ',
    'panel.positions.TP': 'T/P値',
    'panel.positions.type': '銘柄タイプ',
    'panel.positions.UsedMargin': '使用されたマージン',
    'panel.positions.UserGroup': 'ユーザーグループ',
    'panel.positions.Vega': 'ベガ',
    'panel.settings.Information': '情報',
    'panel.settings.saveDefault': '正常に保存されました',
    'panel.statement': '取引明細',
    'panel.statement.print': '表示',
    'panel.statement.Account': '取引口座',
    'panel.statement.Instrument': '取引銘柄名',
    'panel.statement.Date': '日付',
    'panel.statement.Operation_type': 'オペレーションタイプ',
    'panel.statement.Operation_ID': 'オペレーションID',
    'panel.statement.Amount': '金額',
    'panel.statement.Open_price': '始値',
    'panel.statement.Close_price': '終値',
    'panel.statement.Quantity': '数量',
    'panel.statement.Currency': '通貨',
    'panel.statement.Trade_opening_date': '当初取引日',
    'panel.statement.FX_rate': '為替レート',
    'panel.statement.searchBtn.tt': 'クリックして取引明細パネルの表示情報を検索',
    'panel.statement.noData': '指定の時間帯及びフィルターでは取引履歴の該当はありません',
    'panel.terceraChart': 'チャート',
    'panel.TerceraSymbolLookupDropDownForm.Add': '追加',
    'panel.TerceraSymbolLookupDropDownForm.All': '全て',
    'panel.TerceraSymbolLookupDropDownForm.Bonds': '債券',
    'panel.TerceraSymbolLookupDropDownForm.Cancel': 'キャンセル',
    'panel.TerceraSymbolLookupDropDownForm.CFDs': 'CFDs',
    'panel.TerceraSymbolLookupDropDownForm.collapceAllButton': '全て折りたたむ',
    'panel.TerceraSymbolLookupDropDownForm.crypto': '仮想通貨',
    'panel.TerceraSymbolLookupDropDownForm.Equities': '株式',
    'panel.TerceraSymbolLookupDropDownForm.ETFs': 'ETFs',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges': '全ての取引所',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges': '取引所',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges': '取引所なし',
    'panel.TerceraSymbolLookupDropDownForm.expandExchangesButton': '取引所フォルダを展開',
    'panel.TerceraSymbolLookupDropDownForm.expandTypesButton': '種別フォルダを展開',
    'panel.TerceraSymbolLookupDropDownForm.expandGroupsButton': 'グループフォルダを展開',
    'panel.TerceraSymbolLookupDropDownForm.Forex': 'Forex',
    'panel.TerceraSymbolLookupDropDownForm.Forward': '先渡',
    'panel.TerceraSymbolLookupDropDownForm.Futures': '先物',
    'panel.TerceraSymbolLookupDropDownForm.indices': 'インデックス',
    'panel.TerceraSymbolLookupDropDownForm.Options': 'オプション',
    'panel.TerceraSymbolLookupDropDownForm.Portfolio': 'シンセティック',
    'panel.TerceraSymbolLookupDropDownForm.Select': '選択',
    'panel.TerceraSymbolLookupDropDownForm.Select all': '全てを選択',
    'panel.TerceraSymbolLookupDropDownForm.Show additionl info': '追加情報の表示',
    'panel.TerceraSymbolLookupDropDownForm.Spot': 'スポット',
    'panel.TerceraSymbolLookupDropDownForm.Spreadbet': 'スプレッドベッティング',
    'panel.TerceraSymbolLookupDropDownForm.TBill': '国債',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types': '全てのタイプ',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types': 'タイプなし',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.types': 'タイプ',
    'panel.timeSales': 'Time & Sales',
    'panel.timeSales.Agressor flag': '売買種別',
    'panel.timeSales.AgressorFlag.Auction': 'オークション',
    'panel.timeSales.AgressorFlag.Buy': '買い',
    'panel.timeSales.AgressorFlag.CrossTrade': 'クロストレード',
    'panel.timeSales.AgressorFlag.None': 'なし',
    'panel.timeSales.AgressorFlag.Sell': '売り',
    'panel.timeSales.AgressorFlag.RLP': '保留',
    'panel.timeSales.ask': 'Ask',
    'panel.timeSales.AskBidSize': 'BidxAskサイズ',
    'panel.timeSales.AskSource': 'Asks MPID',
    'panel.timeSales.bid': 'Bid',
    'panel.timeSales.BidSource': 'Bids MPID',
    'panel.timeSales.BuyerSource': 'Buyer',
    'panel.timeSales.Exchange': 'ソース',
    'panel.timeSales.Instrument': '銘柄',
    'panel.timeSales.MenuName': '時間毎の取引履歴',
    'panel.timeSales.Price': '価格',
    'panel.timeSales.SellerSource': 'Seller',
    'panel.timeSales.Size': '数量',
    'panel.timeSales.Time': '時間',
    'panel.timeSales.Type': '銘柄タイプ',
    'panel.tooltiper.System': 'システム',
    'panel.trades': '約定済み注文',
    'panel.trades.print': '#hidden#',
    'panel.tradingCentral': 'トレーディングセントラル',
    'panel.tradingCentral.screenBtn.text': 'スクリーン',
    'panel.tradingCentral.chartBtn.text': 'チャート',
    'panel.tradingCentral.ScreenButton': '表示',
    'panel.tradingCentral.settings': '設定中...',
    'panel.tradingCentral.settings.technicalAnalysis.term': '期間',
    'panel.tradingCentral.settings.technicalAnalysis.days': '日数',
    'panel.tradingCentral.settings.technicalAnalysis.lastOnly': '最終のみ',
    'panel.tradingCentral.settings.alerts.lastOnly': '最終のみ',
    'panel.tradingCentral.settings.alerts.days': '日数',
    'screen.Alerts.Ask': 'Ask',
    'screen.Alerts.Bid': 'Bid',
    'screen.Alerts.Last': '現在の価格',
    'screen.Alerts.ChangePr': '前日比,%',
    'screen.Alerts.Volume': '出来高',
    'panel.tradingCentral.settings.candlestick.lastOnly': '最終のみ',
    'panel.tradingCentral.settings.candlestick.days': '日数',
    'panel.tradingCentral.term.All': '全て',
    'panel.tradingCentral.term.Intraday': '日中',
    'panel.tradingCentral.term.Short term': 'ショートターム',
    'panel.tradingCentral.term.Middle term': 'ミドルターム',
    'panel.watchlist': 'ウォッチリスト',
    'panel.watchlist.Ask': 'Ask',
    'panel.watchlist.AskSize': 'Askサイズ',
    'panel.watchlist.AuctionEndTime': '#hidden#',
    'panel.watchlist.Bid': 'Bid',
    'panel.watchlist.BidSize': 'Bidサイズ',
    'panel.watchlist.Buy': '買い',
    'panel.watchlist.CBAuctionStart': '#hidden#',
    'panel.watchlist.cell.cancel': 'キャンセル',
    'panel.watchlist.cell.modify': '修正',
    'panel.watchlist.cell.Not allow': '許可されていません',
    'panel.watchlist.cell.sending': '新着',
    'panel.watchlist.cell.ticks': 'ticks',
    'panel.watchlist.Change': '変動幅',
    'panel.watchlist.ChangeFromOpen': 'オープンからの変化',
    'panel.watchlist.ChangeFromOpen%': 'オープンからの変化、％',
    'panel.watchlist.ChangePr': '前日比,%',
    'panel.watchlist.Close': '終値',
    'panel.watchlist.ClosePostMarket': 'ポストマーケットクローズ',
    'panel.watchlist.Comment': 'コメント',
    'panel.watchlist.Company': '会社',
    'panel.watchlist.ContractSize': 'ロットサイズ',
    'panel.watchlist.CurrentSession': '現在のセッション',
    'panel.watchlist.Date': '最終日',
    'panel.watchlist.Descr': '説明',
    'panel.watchlist.Exchange': '取引所',
    'panel.watchlist.ExpDate': '有効期限',
    'panel.watchlist.High': '高値',
    'panel.watchlist.High13': '#hidden#',
    'panel.watchlist.High26': '#hidden#',
    'panel.watchlist.High52': '#hidden#',
    'panel.watchlist.IndicativeAuctionPrice': '個別オークション価格',
    'panel.watchlist.Instrument': '銘柄',
    'panel.watchlist.Last': '現在の価格',
    'panel.watchlist.LastSize': '現在のサイズ',
    'panel.watchlist.Limit.High': '#hidden#',
    'panel.watchlist.Limit.Low': '#hidden#',
    'panel.watchlist.Low': '安値',
    'panel.watchlist.Low13': '#hidden#',
    'panel.watchlist.Low26': '#hidden#',
    'panel.watchlist.Low52': '#hidden#',
    'panel.watchlist.Main Volume': 'メインボリューム',
    'panel.watchlist.Market': '成行',
    'panel.watchlist.MarketCap': '時価総額',
    'panel.watchlist.Match Volume': 'マッチボリューム',
    'panel.watchlist.menu.AddSymbol': '銘柄を追加',
    'panel.watchlist.menu.clearAll': '全て非表示',
    'panel.watchlist.menu.SymbolLists': '銘柄リスト',
    'panel.watchlist.menu.RemoveInstrument': '選択したシンボルを削除',
    'panel.watchlist.menu.SymbolInfo': 'シンボル情報',
    'panel.watchlist.menu.Chart': '#hidden#',
    'panel.watchlist.menu.MarketDepth': '#hidden#',
    'panel.watchlist.menu.TimeSales': '#hidden#',
    'panel.watchlist.menu.OrderEntry': '#hidden#',
    'panel.watchlist.NA': '該当なし',
    'panel.watchlist.noQuotes': '最後の更新',
    'panel.watchlist.NormalMarketSize': '#hidden#',
    'panel.watchlist.OffExchangeValue': '#hidden#',
    'panel.watchlist.OffExchangeVolume': '#hidden#',
    'panel.watchlist.Open': '始値',
    'panel.watchlist.OpenInterest': '#hidden#',
    'panel.watchlist.OpenPreMarket': 'プレマーケットオープン',
    'panel.watchlist.Order': '#hidden#',
    'panel.watchlist.Order.tt': '指値注文',
    'panel.watchlist.Trade': '#hidden#',
    'panel.watchlist.Trade.tt': '成行注文',
    'panel.watchlist.ITChartAdvanced': '高度なチャート',
    'panel.watchlist.ITChartAdvanced.tt': 'ITファイナンス別の高度なチャート',
    'panel.watchlist.PreMarketChange': '開場前変化',
    'panel.watchlist.PreMarketChange%': '開場前変化、％',
    'panel.watchlist.PrevClose': '前日の終値',
    'panel.watchlist.PrevSettlementPrice': '前回の決済価格',
    'panel.watchlist.ReferencePrice': '参考価格',
    'panel.watchlist.RemainingQty': '#hidden#',
    'panel.watchlist.RemainingQtySide': '#hidden#',
    'panel.watchlist.Sell': '売り',
    'panel.watchlist.SettlementPrice': '決済価格',
    'panel.watchlist.Spread': 'スプレッド',
    'panel.watchlist.SpreadPr': 'スプレッド, %',
    'panel.watchlist.Ticks': 'ティック',
    'panel.watchlist.TicksPostMarket': 'ポストマーケットティック',
    'panel.watchlist.TicksPreMarket': 'プレマーケットティック',
    'panel.watchlist.Time': '前回',
    'panel.watchlist.TradedValue': '#hidden#',
    'panel.watchlist.Type': '銘柄タイプ',
    'panel.watchlist.Volume': '出来高',
    'panel.watchlist.VolumePostMarket': 'ポストマーケットボリューム',
    'panel.watchlist.VolumePreMarket': 'プレマーケットボリューム',
    'Panels.MarketDepth.CancelAll': '全てを取消し',
    'panels.orders.buttons.AsMarket': '成行注文への変更',
    'panels.orders.buttons.CancelAllButton': '全注文取消',
    'panels.orders.buttons.CancelBuyButton': '買い注文取消',
    'panels.orders.buttons.CancelByInstrumentButton': '取消',
    'panels.orders.buttons.CancelDayButton': '当日限り注文 取消し',
    'panels.orders.buttons.CancelGTCButton': 'GTC注文 取消し',
    'panels.orders.buttons.CancelLimitsButton': '指値注文 取消し',
    'panels.orders.buttons.CancelSelectedButton': '選択注文 取消',
    'panels.orders.buttons.CancelSellButton': '売り注文 取消',
    'panels.orders.buttons.CancelStopsButton': '逆指値注文 取消',
    'panels.orders.buttons.ModifyOrder': '注文変更',
    'panels.positions.buttons.closeAll': '全てを決済',
    'panels.positions.buttons.closeForInstrument': '決済',
    'panels.positions.buttons.closeLong': '買建玉 決済',
    'panels.positions.buttons.closeNegative': 'マイナス建玉 決済',
    'panels.positions.buttons.closePositive': 'プラス建玉 決済',
    'panels.positions.buttons.closeSelected': '選択建玉 決済',
    'panels.positions.buttons.closeShort': '売建玉 決済',
    'panels.positions.buttons.clxAll': '全てを決済',
    'panels.positions.buttons.clxForInstrument': '決済',
    'panels.positions.ButtonsPanel.helpLabel': '右クリックでボタンの表示/非表示を切り替え',
    'panel.rangeSelect.daily': '日次',
    'panel.rangeSelect.24h': '#hidden#',
    'panel.rangeSelect.7d': '#hidden#',
    'panel.rangeSelect.30d': '#hidden#',
    'panel.rangeSelect.90d': '#hidden#',
    'panel.rangeSelect.range': '範囲',
    'panel.rangeSelect.tooltip': '表示期間を選択',
    'PanelSettingsScreen.Text': '',
    'PanelSettingsScreen.TextSmall': '',
    'Parabolic Time/Price System': 'パラボリック',
    'period.Day': '日',
    'period.Days': '日',
    'period.Hour': '時',
    'period.Hours': '時',
    'period.Min': '分',
    'period.Mins': '分',
    'period.Month': '月',
    'period.Months': '月',
    'period.Week': '週',
    'period.Weeks': '週',
    'period.Year': '年',
    'period.Years': '年',
    'Pivot Point Moving Average': 'ピボットポイント移動平均線',
    PivotPoint: 'ピボットポイント',
    'PopupMultiCkeckBox.All': '全て',
    Português: 'ポルトガル語',
    'Price Channel': 'プライスチャネル',
    'Price Oscillator': 'プライスオシレーター',
    'ProductType.CarryForward': 'キャリーフォワード',
    'ProductType.Delivery': '受渡し',
    'ProductType.General': '全般',
    'ProductType.Intraday': 'イントラデイ',
    'property.%': '％',
    'property.Additional': '追加',
    'property.AllPrice': '価格統計',
    'property.appearance': '配色',
    'property.Ask': 'Ask',
    'property.Author': '作成者',
    'property.autosize': '列の自動サイズ調整',
    'property.autoYScale.Auto': 'オートスケール',
    'property.autoYScale.Manual': '手動スケール',
    'property.Average': '平均',
    'property.Backgr': '背景色',
    'property.Backgr. top': 'バックトップカラー',
    'property.BarPattern': 'バーパターン',
    'property.barsHiLowColor': 'ライン',
    'property.BarStatistics': 'バー統計',
    'property.Bid': 'Bid',
    'property.bodyColor': '実体',
    'property.borderColor': '実体の外枠',
    'property.BottomCenter': '中央下',
    'property.BottomLeft': '左下',
    'property.BottomRight': '右下',
    'property.Browser': 'ブラウザ',
    'property.Buy': '買い',
    'property.BuyColor': '買い',
    'property.cAsk': 'Askサイズ',
    'property.cASK': 'Ask価格で執行',
    'property.cASKHI': 'Ask価格超で執行',
    'property.cBid': 'Bidサイズ',
    'property.cBID': 'Bid価格で執行',
    'property.cBIDLO': 'Bid価格未満で執行',
    'property.cellscin.useSellBuy': 'ボタンの順序を逆にする',
    'property.Change': '変化',
    'property.ChangePercent': '変動率',
    'property.Chart': 'チャート',
    'property.Chart OE': 'チャート注文パネル',
    'property.chart.isBackground': '背景に移動',
    'property.chart.line': 'ライン',
    'property.chart.ray': 'レイ',
    'property.chart.rayStyle': '線種',
    'property.chart.showAngle': '角度を表示',
    'property.chart.showingOE': '表示',
    'property.chart.stickTo': '固定',
    'property.chart.tools.back': '塗りつぶし',
    'property.chart.tools.fibretr.font': 'ラベルフォント',
    'property.chart.tools.fibretr.fontcolor': 'フォントの色',
    'property.ChartAnalyseToolsToolBar': '分析ツールバー',
    'property.ChartDrawingToolsToolBar': '描画ツールバー',
    'property.cLimit': '指値注文',
    'property.Close': '終値',
    'property.Cluster.ClusterUpDownColors': 'クラスター',
    'property.Cluster.ColorScheme': 'カラースキーム',
    'property.Cluster.ColorScheme.ByDelta': 'デルタ別',
    'property.Cluster.ColorScheme.ByDelta.Colors': '色',
    'property.Cluster.ColorScheme.ByDelta.Colors.BuyColor': '買い',
    'property.Cluster.ColorScheme.ByDelta.Colors.SellColor': '売り',
    'property.Cluster.ColorScheme.ByTrades': '取引別',
    'property.Cluster.ColorScheme.ByVolume': 'ボリューム別',
    'property.Cluster.ColorScheme.Color': '色',
    'property.Cluster.ColorScheme.None': 'なし',
    'property.Cluster.CustomStep': 'カスタムステップ',
    'property.Cluster.Down': 'ダウン',
    'property.Cluster.FilterValue': 'フィルター値',
    'property.Cluster.Font': 'フォント',
    'property.Cluster.FontColor': 'フォントの色',
    'property.Cluster.HighlightMax': '最大値をハイライト',
    'property.Cluster.HighlightMax.Colors': '色',
    'property.Cluster.Operator': '演算記号',
    'property.Cluster.PriceStep': '価格ステップ',
    'property.Cluster.PriceStep.Custom': 'カスタム',
    'property.Cluster.PriceStep.MinTick': '最小tick',
    'property.Cluster.ShowOnlySimilarDelta': '同様のデルタのみを表示',
    'property.Cluster.ShowValueFilter': '値フィルター',
    'property.Cluster.Up': 'アップ',
    'property.cMIDLO': 'スプレッド間',
    'property.Color': '色',
    'property.ColoringMode': '色付けモード',
    'property.Colors': '色',
    'property.colors_param': '色',
    'property.colorScheme': 'スタイル',
    'property.ColorsSeparatorGroup': '色',
    'property.ColouringMode': '色付けモード',
    'property.Comments': 'コメント',
    'property.Company': '会社',
    'property.Confirmations': '確認',
    'property.connection': '接続',
    'property.Connection': '接続',
    'property.ContentAlignment': 'アライメント',
    'property.Copyrights': '著作権',
    'property.cQUOTE': 'クオート',
    'property.CreateColor': '作成する',
    'property.crossHairFontColor': 'フォントの色',
    'property.cStop': 'ストップオーダー',
    'property.currency': '通貨',
    'property.CustomYMarkingValue': '最小グリッドステップ',
    'property.Data Box': '情報ウィンドウ',
    'property.Data Style': 'チャートのスタイル',
    'property.Day': '日',
    'property.DaySeparators': '日付区切り線',
    'property.DefaultProductType': '商品の種類のデフォルト設定',
    'property.Defaults': '取引のデフォルト設定',
    'property.deviation': '偏差',
    'property.dK': 'dK',
    'property.Doji': '同時線',
    'property.Downband deviation in %': '下限バンドの偏差',
    'property.email': 'メールアドレス\n（カンマまたはセミコロンを使用）',
    'property.Emulator': 'エミュレータ',
    'property.enable': '有効化',
    'property.Enable Sounds': 'サウンドの有効化',
    'property.Exponential': '指数平滑移動平均線',
    'property.fib.Arc': 'フィボナッチアーク',
    'property.Fibonacci': 'フィボナッチ',
    'property.Filters': 'フィルター',
    'property.FitDrawings': '描写ツールに合わす',
    'property.FitHighLow': '当日の高値/安値に合わす',
    'property.FitIndicator': 'インディケーターに合わす',
    'property.FitOrders': '注文/ポジションに合わす',
    'property.FOK': 'FOK',
    'property.Font': 'フォント',
    'property.Forex': 'Forex',
    'property.Forwards': '先渡取引',
    'property.Futures': '先物',
    'property.FX Cell': 'FXセル',
    'property.G': 'G',
    'property.General': '全般',
    'property.General.ToolTip': '一般設定',
    'property.general.defaultSymbol': '銘柄',
    'property.general.OvernightMarginNotificationMessage': '#hidden#',
    'property.general.showLotsFX': 'ロット単位で表示',
    'property.gr_type': 'スタイル',
    'property.Gradient': '勾配',
    'property.GraphicType.Absolute': '絶対値',
    'property.GraphicType.Log': '対数',
    'property.GraphicType.Relative': '相対',
    'property.GridPrice': '価格（水平）',
    'property.GridPriceHighLight': 'グリッド線の設定',
    'property.GridTime': '時間（垂直）',
    'property.GTC': 'GTC',
    'property.GTD': 'GTD',
    'property.HeaderFont': 'フォント',
    'property.HeaderText': 'ヘッダーテキスト',
    'property.High': '高値',
    'property.HighlightMarkingsStep': 'ハイライトグリッドステップ',
    'property.HistogramLineColor': 'ヒストグラムのライン色',
    'property.HistoryType': '適用価格',
    'property.HorizontalLine.LeftRay': '左に延長',
    'property.hostName': 'ホスト名',
    'property.hotkeyManager.ShowVolume': '数量を表示',
    'property.infowindow_font': 'フォント',
    'property.infoWindow_fore_back_color': '色',
    'property.infowindow_visible': '情報ウィンドウを表示',
    'property.Instrument Types': 'タイプ毎のデフォルトの順序',
    'property.Intrinsic': '極限',
    'property.IOC': 'IOC',
    'property.IsBackground': '背景に表示',
    'property.IsBarsVisible': 'バー数',
    'property.IsChangeVisible': '前日比,%',
    'property.IsCloseVisible': '終値',
    'property.IsDateVisible': '日付',
    'property.IsHighVisible': '高値',
    'property.IsLowVisible': '安値',
    'property.IsOpenVisible': '始値',
    'property.IsRoundedAvgPrice': '端数処理済み平均始値',
    'property.isShowToolTips': 'ツールチップ',
    'property.isShowToolTipsGrop': 'ツールチップ',
    'property.ConfirmationSubgroup.Other': 'その他',
    'property.dealTickets.openOnWorkspace': 'ワークスペースでのオープン取引チケット',
    'property.IsSyncActive': 'チャートを同期',
    'property.IsTicksVisible': 'ティック数',
    'property.IsTimeVisible': '時間',
    'property.IsVolumeVisible': '出来高',
    'property.LabelColors': 'ラベルの色',
    'property.Language': '言語',
    'property.Last': '終値',
    'property.lastPrice_AskIndicatorColor': 'Ask',
    'property.lastPrice_BidIndicatorColor': 'Bid',
    'property.lastPrice_LastIndicatorColor': '終値',
    'property.lastPrice_SpreadIndicatorColor': 'スプレッド範囲の色',
    'property.Left ray': '左に延長',
    'property.Level': 'レベル',
    'property.Limit': '指値',
    'property.Line': 'ライン',
    'property.line.lineName': 'ライン名',
    'property.line.plateVisible': '価格軸にライン{0}の価格を表示',
    'property.line.timeShift': '時間のシフト',
    'property.line.value': '値',
    'property.line.Visible': '表示',
    'property.line.yFactor': 'Yファクター',
    'property.Linear Weighted': '線形加重移動平均線',
    'property.lineProperties': '詳細',
    'property.LOC': 'LOC',
    'property.logBase': 'ログベース',
    'property.LOO': 'LOO',
    'property.LotsMode': 'サイズを表示',
    'property.Low': '安値',
    'property.Mail Server': 'メールサーバー',
    'property.Manual': 'マニュアル',
    'property.Market': '成行',
    'property.MarketCap': 'マーケット・キャップ',
    'property.MarketDepth.ColoringMethod': '着色方法',
    'property.MarketProfile.ColorScheme': 'カラースキーム',
    'property.MarketProfile.ColorScheme.Color': '色',
    'property.MarketProfile.ColorScheme.GradientColor': 'エリア',
    'property.MarketProfile.ColorScheme.PairColor.Color': '色',
    'property.MarketProfile.ColorScheme.VolumeColor': '色',
    'property.MarketProfile.Font': 'フォント',
    'property.MarketProfile.PointOfControl': '制御点',
    'property.MarketProfile.PointOfControlStyle': '制御点のスタイル',
    'property.MarketProfile.ShowSingles': 'シングルを表示',
    'property.MarketProfile.SinglesStyle': 'シングルスタイル',
    'property.MarketProfile.ValueArea': 'バリューエリア',
    'property.MarketProfile.ValueAreaStyleHorisontal': '水平線スタイル',
    'property.MarketProfile.ValueAreaStyleVertical': '縦線スタイル',
    'property.MarketProfile.ValuePercents': '値、％',
    'property.Medium': '中央値',
    'property.message': 'メッセージ',
    'property.method': '測定方法',
    'property.MirrorShow': 'ミラービュー',
    'property.MOC': 'MOC',
    'property.Mode': 'モード',
    'property.Mode.Trades': '取引',
    'property.Modified': '修正移動平均線',
    'property.MonthSeparators': '月の区切り線',
    'property.MOO': 'MOO',
    'property.name': '名前',
    'property.Name': '名前',
    'property.News': 'ニュース',
    'property.None': 'なし',
    'property.Note': '注',
    'property.NotSet': '設定されていません',
    'property.Number': 'カウント',
    'property.OCO': 'OCO',
    'property.OE_AddBidAddAsk': 'Bidで買い/Askで売り',
    'property.OE_ShowBuyMarketSellMarket': '成行買い/成行売り',
    'property.OE_ShowSellBidBuyASk': 'Bidで売り/Askで買い',
    'property.OE_ShowSLTP': 'クローズ注文（SL / TP）',
    'property.OE_Small': '注文の絞り込み',
    'property.OE_Small.ToolTip': 'チェックした場合、注文入力は狭いビューになります',
    'property.oe.confirmClosePosition': 'ポジション決済時の確認',
    'property.oe.confirmOrderCancel': '予約注文取消時の確認',
    'property.oe.confirmOrderChange': '予約注文/ポジション変更時の確認',
    'property.oe.confirmOrderCreate': '発注時の確認',
    'property.oe.confirmReversePosition': '予約注文の売買反転時の確認',
    'property.OffsetMode': 'オフセットを表示',
    'property.OffsetMode.Points': 'ポイント',
    'property.OffsetMode.Ticks': 'Tick',
    'property.OffsetMode.TicksFractionalForForex': 'Tick（外国為替の分数Tick）',
    'property.OnClose': '閉場時',
    'property.OnOpen': '開場時',
    'property.Open': '始値',
    'property.Order Entry': '注文パネル',
    'property.OrderBuyStyleWidth': '買い',
    'property.OrderSellStyleWidth': '売り',
    'property.OverlayVisible': 'チャートを重ねて表示',
    'property.Panel name': 'パネル名',
    'property.panel.accountDetails.Groups.1.General': '全般',
    'property.panel.accountDetails.Groups.2.Margin': '証拠金',
    'property.panel.accountDetails.Groups.3.AccountActivity': 'アカウントアクティビティ',
    'property.panel.accountDetails.Groups.4.TodayResults': '本日の結果',
    'property.panel.accountDetails.Groups.5.RiskManagement': 'リスク管理',
    'property.panel.accountDetails.Groups.6.Info': '情報',
    'property.Panels': 'パネル',
    'property.Parameters': 'パラメーター',
    'property.password': 'パスワード',
    'property.period': '期間',
    'property.Period of indicator': 'インディケーターの期間',
    'property.Period of indicator:': 'インディケーターの期間',
    'property.Period of Linear Regression': '線形回帰の期間',
    'property.Period of MA for envelopes': 'エンベロープの移動平均線の期間',
    'property.Period of MA1': '移動平均線1の期間',
    'property.Period of MA2': '移動平均線2の期間',
    'property.Period of momentum:': 'モメンタムの期間',
    'property.Period of Moving Average': '移動平均線の期間',
    'property.Period of price channel': 'プライスチャネルの期間',
    'property.Period of Simple Moving Average': '単純移動平均線の期間',
    'property.Period of Smoothed Moving Average': '平滑移動平均線の期間',
    'property.Periods': '期間',
    'property.pin': 'PIN',
    'property.pips': 'Pips',
    'property.POCTypes.Line': 'ライン',
    'property.POCTypes.Marker': 'マーカー',
    'property.POCTypes.None': 'なし',
    'property.points': 'ポイント',
    'property.Points': 'ポイント',
    'property.PositionBuyStyleWidth': '買',
    'property.PositionSellStyleWidth': '売',
    'property.Previous': '前日',
    'property.price': '価格',
    'property.Price Scale': '価格スケール',
    'property.PriceIndicatorDrawingType': '価格の表示',
    'property.PriceIndicatorDrawingType.None': 'なし',
    'property.PriceIndicatorDrawingType.ScaleMarker': '価格軸に価格を表示',
    'property.PriceIndicatorDrawingType.ScaleMarkerWithLine': '価格と価格ラインを表示',
    'property.PriceScaleAxisColor': '軸',
    'property.PriceScaleBackColor': '軸の背景',
    'property.PriceScaleFont': 'フォント',
    'property.PriceScaleTextColor': 'テキストの色',
    'property.PriceType': '価格タイプ',
    'property.ProfileColoringTypes.DeltaBars': 'デルタバー',
    'property.ProfileColoringTypes.DeltaPrice': 'デルタプライス',
    'property.ProfileColoringTypes.DeltaProfile': 'デルタプロファイル',
    'property.ProfileColoringTypes.Single': '単色',
    'property.ProfileColoringTypes.UpdownBars': 'バーを上/下する',
    'property.ProfileColoringTypes.UpdownProfile': 'プロファイルを上/下する',
    'property.ProfileColoringTypes.VolumeBars': '出来高',
    'property.ProfileColoringTypes.VolumePrice': '出来高価格',
    'property.protrader.showOrders': '注文',
    'property.Range': '範囲',
    'property.Ray style': '放射状スタイル',
    'property.Relative': '相対的',
    'property.relativeCalcType': '始値',
    'property.RelativeCalculationType.BeginOfTheData': 'データの始まりから',
    'property.RelativeCalculationType.BeginOfTheDay': 'その日の始めから',
    'property.RelativeCalculationType.BeginOfTheScreen': '画面の始めから',
    'property.relativeScaleType': 'スケールタイプ',
    'property.Reserve': '反転',
    'property.Reverse': '反転',
    'property.Right ray': '右に延長',
    'property.routing.account': '取引口座',
    'property.routing.orderType': '注文種別',
    'property.RSI Method:': 'RSIの種類',
    'property.RSI Period:': 'RSIの期間',
    'property.SCRIPTS_SUBGROUP': 'スクリプト',
    'property.ScrollBar': 'スクロールバー',
    'property.secure': '確約',
    'property.Secure': '確約',
    'property.Selection': '選択',
    'property.Sell': '売り',
    'property.SellColor': '売り',
    'property.SeparatorGroup.ActiveOrders': '未約定注文',
    'property.SeparatorGroup.additional': '追加設定',
    'property.SeparatorGroup.Additional': '追加設定',
    'property.SeparatorGroup.Appearance': '配色',
    'property.SeparatorGroup.CrossHair': '十字カーソル',
    'property.SeparatorGroup.ExecutedOrders': '約定済み注文',
    'property.SeparatorGroup.Grid': 'グリッド線',
    'property.SeparatorGroup.HighlightDate': '期間区切り表示',
    'property.SeparatorGroup.HighlightPrices': '価格表示',
    'property.SeparatorGroup.OpenPositions': '未決済ポジション',
    'property.SeparatorGroup.ScaleMarkings': 'スケールマーキング',
    'property.SeparatorGroup.ScaleType': 'スケールタイプ',
    'property.SeparatorGroup.SeparatorOEWidth': '表示',
    'property.SeparatorGroup.Show': '表示',
    'property.SeparatorGroup.TimeToNextBar': 'ローソク足の確定までの時間',
    'property.SeparatorGroup.WindowColors': 'チャートの配色',
    'property.SeparatorGroup.Zoom': 'スケールの調整',
    'property.SeparatorViewButtons': 'クイックトレード',
    'property.Sesonality': '季節性',
    'property.shift': 'シフト',
    'property.showAllSessions': 'エクステンドセッションの表示',
    'property.ShowEmptyBars': 'マーケット閉場時間を時間軸に表示',
    'property.showEvents': '表示',
    'property.ShowFullArc': '円全体を表示',
    'property.ShowHighLow': '当日の高値/安値',
    'property.showOrders': '表示',
    'property.showPositions': '表示',
    'property.ShowPrevCloseLine': '前日の終値',
    'property.ShowTimeToNextBar': '表示',
    'property.ShowTotal': '合計の表示',
    'property.ShowVisualTradingOnLeftSide': '未決済ポジション/注文を左寄せ',
    'property.showVolume': '表示',
    'property.showVolumeMarker': 'バリューマーカーをスケール表示',
    'property.Simple': '単純移動平均線',
    'property.sltpOffsetMode': 'SL/TP価額を相殺設定',
    'property.Smoothing': '平滑化',
    'property.solidPriceColor': '範囲',
    'property.sounds_param': 'サウンド',
    'property.Sources prices for MA': '基準価格',
    'property.Specific': '特定',
    'property.SpreadType': 'スプレッドの表示',
    'property.SpreadType.Area': '範囲',
    'property.SpreadType.Line': 'ライン',
    'property.SpreadType.LineWithLabel': '価格と価格ラインを表示',
    'property.SpreadType.None': 'なし',
    'property.Step of parabolic SAR system': 'パラボリックSAR指標の設定',
    'property.Stick to': '重ねて表示',
    'property.stickTo': '重ねて表示:',
    'property.Stocks': '株式',
    'property.Stop': 'ストップ注文',
    'property.Stop Limit': 'ストップリミット注文',
    'property.Stop-loss': '決済逆指値',
    'property.style': 'スタイル:',
    'property.Sum': '合計の表示',
    'property.Symbol': 'シンボル',
    'property.Symbols': '銘柄',
    'property.T': 'T',
    'property.Take-profit': 'T/P',
    'property.text': 'テキスト',
    'property.TIF': '成行注文の有効期限',
    'property.TIF_Limit_And_Stop_Limit': '(逆)指値注文の有効期限',
    'property.TIF_Stop': '逆指値注文の有効期限',
    'property.Time Scale': '時間スケール',
    'property.TimeLine': 'タイムライン',
    'property.TimelinePreview': 'スクロールバーの表示',
    'property.TimeScaleAxisColor': '軸',
    'property.TimeScaleBackColor': '軸の背景',
    'property.TimeScaleFont': 'フォント',
    'property.TimeScaleImportentFont': '特定日のフォント',
    'property.TimeScaleTextColor': 'フォントの色',
    'property.TimeToNextBarColor': '色',
    'property.TimeToNextBarFont': 'フォント',
    'property.TopCenter': '中央上',
    'property.TopLeft': '左上',
    'property.TopRight': '右上',
    'property.Tr. stop': 'トレーリングストップ',
    'property.trackShowCrossHair': '表示',
    'property.Trading': '取引',
    'property.Trading Defaults': '取引',
    'property.Type of moving average': '移動平均線の種類',
    'property.Type of Moving Average': '移動平均線の種類',
    'property.Typical': '高値 安値 終値の平均値',
    'property.Upband deviation in %': '上限バンドの偏差',
    'property.Update': 'アップデート',
    'property.UseCustomYMarkings': 'グリッド線のカスタマイズ',
    'property.user': 'ログイン',
    'property.useSSL': '保証された',
    'property.Value of confidence interval': '信頼区間',
    'property.ValueColors': '値の色',
    'property.VATypes.Horizontal': '横線',
    'property.VATypes.None': 'なし',
    'property.VATypes.ToNextProfile': '次のプロフィールへ',
    'property.VATypes.Vertical': '縦線',
    'property.vendorName': 'ベンダー',
    'property.View': '表示',
    'property.ViewSeparatorGroup': '表示',
    'property.Visibility': '表示設定',
    'property.VisibilitySeparatorGroup': '表示設定',
    'property.Visible': '表示',
    'property.Visual Trading': '取引の表示設定',
    'property.Volume': '出来高',
    'property.Volume.Font': 'フォント',
    'property.Volume.FontColor': 'フォントの色',
    'property.Volume.UseTicksData': '実際のティックボリュームを使用',
    'property.VolumeColoringMode': 'サイズの配色',
    'property.VolumeDownColor': 'ネガティブ',
    'property.VolumeFixedColor': '等しい',
    'property.VolumeHeightPercent': '高さ、 ％',
    'property.VolumeMode.BuyVolume': '購入量',
    'property.VolumeMode.Delta': 'デルタ',
    'property.VolumeMode.MaxTickVolume': '最大ティックボリューム',
    'property.VolumeMode.SellVolume': '売却量',
    'property.VolumeMode.TotalVolume': '全ボリューム',
    'property.VolumeMode.Trades': '取引',
    'property.VolumeOpacityPercent': '透明性',
    'property.VolumeProfile': 'ボリュームプロファイル',
    'property.VolumeUpColor': 'ポジティブ',
    'property.VWAP': 'VWAP',
    'property.Warnings': '警告',
    'property.WatermarkColor': 'テキストの色',
    'property.WeekSeparators': '週の区切り線',
    'property.Weighted': '四本値の平均値',
    'property.wickColor': 'ヒゲ',
    'property.width': '幅:',
    'property.Win num': '表示位置',
    'property.Win num.addWin': 'サブウィンドウ',
    'property.Win num.main': 'メインウィンドウ',
    'property.Y factor': 'Yファクター',
    'property.YearSeparators': '年の区切り線',
    'property.ZeroLine': 'ゼロライン',
    'property.ZoomValue': 'スケールステップ',
    'propery.MainPriceRenderer.Down': '下降',
    'propery.MainPriceRenderer.Negative': 'マイナス',
    'propery.MainPriceRenderer.Positive': 'プラス',
    'propery.MainPriceRenderer.Up': '上昇',
    'Relative Strength Index': 'RSI',
    'reports.#subgroup.Revenue plan.0': 'コミッション',
    'reports.#subgroup.Revenue plan.1': 'スプレッド',
    'reports.#subgroup.Revenue plan.2': 'スワップ',
    'reports.#subgroup.Revenue plan.3': 'リベート',
    'reports.#subgroup.Revenue plan.4': '構成している銘柄',
    'reports.$broker': 'ブローカー',
    'reports.$broker_create_order': 'ブローカーの作成した注文',
    'reports.$broker_refuse_with_answer': 'ブローカーが回答を拒否します',
    'reports.$client': 'クライアント',
    'reports.$client_request': 'クライアントリクエスト',
    'reports.$close_position': 'ポジションクローズ',
    'reports.$confirm_order_removed': '注文が削除されたことを確認',
    'reports.$enter_system': 'システムに入る',
    'reports.$executed': '実行済み',
    'reports.$leave_system': 'システムからログアウト',
    'reports.$open_position': 'オープンポジション',
    'reports.$order': '注文',
    'reports.$request_order_removed': 'リクエスト注文が削除されました',
    'reports.4CastNews': '4CastNews',
    'reports.Accepted': '受付けられました',
    'reports.accname': 'アカウント名',
    'reports.accoperreqstatus': 'ステータス',
    'reports.account': '取引口座',
    'reports.Account': '取引口座',
    'reports.Account Name': 'アカウント名',
    'reports.Account operation confirmation.': '取引口座の操作確認',
    'reports.Account Operation Report': '取引口座の操作レポート',
    'reports.Account operation report (settled)': '取引口座の操作レポート (確定)',
    'reports.Account Operation Report Summary': '取引口座の操作レポートのサマリー',
    'reports.Account operations report': '取引口座の操作レポート',
    'reports.Account Operations Report': '取引口座の操作レポート',
    'reports.Account Performance Report': '取引口座のパフォーマンスレポート',
    'reports.Account Statement': 'アカウントステートメント',
    'reports.Account statement in defined currency report': '通貨レポート記載されたの口座明細書',
    'reports.Account Statement on chosen moment report': '選択された期間の口座明細書',
    'reports.Account Statement Report': 'アカウントステートメント',
    'reports.account_ID': 'アカウントID',
    'reports.account.balance': '残高',
    'reports.account.crossinstrument': 'クロスシンボル',
    'reports.account.currency': '通貨',
    'reports.account.ismaster': 'マスター',
    'reports.account.mirroraccounts': 'ミラーアカウント',
    'reports.account.name': 'アカウント名',
    'reports.account.status': 'ステータス',
    'reports.account.type': 'タイプ',
    'reports.account.userid': 'ユーザーID',
    'reports.account.visibility.editforsuper': 'スーパーグループを編集',
    'reports.account.visibility.editforthis': 'このグループを編集',
    'reports.account.visibility.tradeforsuper': 'スーパーグループで取引',
    'reports.account.visibility.tradeforthis': 'このグループで取引',
    'reports.account.visibility.viewforsuper': 'スーパーグループの表示',
    'reports.account.visibility.viewforthis': 'このグループを表示',
    'reports.accountcurrency': '通貨',
    'reports.AccountId': '取引口座',
    'reports.accountname': '取引口座',
    'reports.AccountOperationAmount': '量',
    'reports.accountoperationid': '取引口座の操作ID',
    'reports.accountPassword': '取引口座のパスワード',
    'reports.accounts': '取引口座',
    'reports.accountUsername': '取引口座のユーザー名',
    'reports.AccruedInterest': '未収利息',
    'reports.ACM Feed': 'ACMフィード',
    'reports.action': '売買種別',
    'reports.actionname': 'アクション名',
    'reports.Activated': '有効化',
    'reports.Active Orders Report': '有効な注文レポート',
    'reports.actualprice': '実価格',
    'reports.actualtime': '実時刻',
    'reports.addliquidityrebate': '流動性リベートを追加',
    'reports.address': '住所',
    'reports.Adjusment': '調整',
    'reports.Adjustment': '調整',
    'reports.ADMINISTRATION_RULES': '管理ルール',
    'reports.AK Equity Algo': 'AK Equity Algo',
    'reports.AK Genex Equity': 'AK Genex Equity',
    'reports.AK Genex Turdex': 'AK Genex Turdex',
    'reports.alertNotify': 'アラート通知',
    'reports.All brokers': '全ブローカー',
    'reports.All data received.': '受信したすべてのデータ',
    'reports.ALLOW_AUTO_APPROVE_TRANSFER_REQUEST': '転送リクエストの自動承認を許可する',
    'reports.ALLOW_AUTO_ORDER_TYPE': '注文タイプを自動的に設定（指値/停止/市場）',
    'reports.ALLOW_AUTO_RESET_PWD': 'パスワードの自動リセットを許可する',
    'reports.ALLOW_CLOSE_POSITIONS_ON_STOP_OUT': 'ストップアウトでポジションをクローズする',
    'reports.ALLOW_DEMO_AUTO_REGISTRATION': 'デモユーザーの自動登録を許可する',
    'reports.ALLOW_GENERATE_L3': 'L3の生成を許可',
    'reports.ALLOW_GENERATE_PRICE_FROM_SPOT': 'スポットからの価格の生成を許可',
    'reports.ALLOW_HEDGE_BY_SPOT': 'スポット毎のヘッジ',
    'reports.ALLOW_HEDGE_MARGIN': 'ヘッジマージンを許可',
    'reports.ALLOW_JOIN_POSITIONS': '一方向ポジションヘッジを統合する',
    'reports.ALLOW_LAZY_LOAD_QUOTES': '遅延クオートの読み込みを許可する',
    'reports.ALLOW_LIVE_AUTO_REGISTRATION': 'ライブ自動登録を許可する',
    'reports.ALLOW_ONE_BROKER_FOR_ALL_OPERATION': 'すべての操作を1つのブローカーに許可する',
    'reports.ALLOW_ORDER_AUTOEXECUTION_IS_MARKET_PRICE': '市場価格で注文を実行する（falseの場合は注文価格で）',
    'reports.ALLOW_PRICE_EXECUTION_MAPPING': '価格実行マッピング',
    'reports.ALLOW_PWD_VERIFICATION': 'SMSでパスワードを確認する',
    'reports.ALLOW_REFUSE_IF_BROKER_OFFLINE': 'ブローカーがオフラインの場合、注文拒否を許可する',
    'reports.ALLOW_REMOVE_ORDERS_ON_STOP_OUT': 'ストップアウトで注文を削除',
    'reports.ALLOW_SAVE_HISTORY': 'シンボルのクオート履歴を保存',
    'reports.ALLOW_SAVE_QUOTE_LOGS': '配信ログの保存を許可',
    'reports.ALLOW_SAVE_QUOTE_TICKS': '配信ティックの保存を許可',
    'reports.ALLOW_SAVE_QUOTES': '配信の保存を許可',
    'reports.ALLOW_SAVE_TRADE_LOGS': '取引ログの保存を許可',
    'reports.ALLOW_SHORT_POSITIONS': 'ショートポジションの許可',
    'reports.ALLOW_SKIP_REVERSED_PRICE': 'すべての「逆」クオートをスキップ',
    'reports.ALLOW_SPIKE_FILTER': 'スパイクフィルターを許可する',
    'reports.ALLOW_STOP_AVARE_MARGIN': 'ストップアウェアマージンを使用する',
    'reports.ALLOW_STORE_USERS_FILES': 'ユーザーファイルの保存を許可する',
    'reports.ALLOW_USE_CERT': 'サインインリクエストを許可する',
    'reports.ALLOW_USER_LEVERIGE': 'ユーザーのレバレッジを許可',
    'reports.ALLOW_USERS_EMAIL_DUPLICATE': 'デモアカウントの電子メールの複製を許可する',
    'reports.ALLOW_VISIBLITY': '表示の許可',
    'reports.allowLimitOrders': '指値注文を許可する',
    'reports.allowStopOrders': '逆指値注文の許可',
    'reports.already in processing': 'すでに処理中',
    'reports.amount': '数量',
    'reports.Amount': '数量',
    'reports.Amount filled': '約定済み数量',
    'reports.amountperside': '片側あたりの量',
    'reports.An error occurred during sending mail using user settings. Will use default settings.': 'ユーザー設定を使用してメールを送信中にエラーが発生しました。デフォルト設定を使用します。',
    'reports.Aphelion': '遠日点',
    'reports.APPLICATION.NAME': 'アプリケーション名',
    'reports.Approved': '承認済み',
    'reports.asset': '資産',
    'reports.AuditReport': 'バックオフィス変更レポート',
    'reports.Authorization Error: Operation is nor allowed': '認証エラー: 操作は許可されていません',
    'reports.autotrade': '自動取引',
    'reports.Average filled price': '価格',
    'reports.Back Office Changes Report': 'バックオフィス変更レポート',
    'reports.balance': '残高',
    'reports.Balance': '残高',
    'reports.Balance by Instrument Report': 'シンボル別残高レポート',
    'reports.Balance By Instrument Report': 'シンボル別残高レポート',
    'reports.Balance Operations Report': '残高操作レポート',
    'reports.Balance Report': '残高レポート',
    'reports.Balance Report(copy)': '残高レポート(コピー)',
    'reports.Balance Summary report': '残高レポートサマリー',
    'reports.Balance Summary Report': '残高レポートサマリー',
    'reports.balance_after': '後のバランス',
    'reports.balance_before': '前のバランス',
    'reports.balance.amount': '数量',
    'reports.balance.buy': '買い',
    'reports.balance.everbuy': '平均Buy',
    'reports.balance.eversell': '平均Sell',
    'reports.balance.exposure': 'エクスポージャー',
    'reports.balance.exposurenet': 'ネットエクスポージャー',
    'reports.balance.grossexposure': 'グロスエクスポージャー',
    'reports.balance.grosspl': 'スポット損益',
    'reports.balance.grossvalue': '総ポジション値',
    'reports.balance.net': 'ネット',
    'reports.balance.netexposure': 'ネットエクスポージャー',
    'reports.balance.netvalue': 'ネットポジション評価',
    'reports.balance.profit': '収益',
    'reports.balance.sell': '売り',
    'reports.Barclays': 'Barclays',
    'reports.basis': 'コメント',
    'reports.Basis': '詳細',
    'reports.because Not enoght margin to create Order': '証拠金不足のため注文不可',
    'reports.because Price for Stop limit order is incorrect.': '指値注文の価格が間違っています',
    'reports.BeginString': '開始文字列',
    'reports.blocked_funds': 'ロックされた資金（マージン）',
    'reports.blockedbalance': '保留金',
    'reports.Blocking': 'ロック',
    'reports.bmr_brokermessageid': 'メッセージID',
    'reports.bmr_brokermessagename': 'メッセージ名',
    'reports.bmr_confirmtime': '目標時間',
    'reports.bmr_targetuserid': 'ユーザーID',
    'reports.bmr_targetuserlogin': 'ユーザー',
    'reports.Bound To': 'にバインド',
    'reports.Bound to order': '注文にバインド',
    'reports.boundtoorderid': 'バインドされた注文ID',
    'reports.BRANDING_ADMIN_HELP': 'アドミンのヘルプURL',
    'reports.BRANDING_ANONYM_GROUP': '自動登録された匿名ユーザーを配置する',
    'reports.BRANDING_ANONYM_GROUP_SUPPORT': '匿名ユーザーのサポートグループ',
    'reports.BRANDING_CLIENT_MINIMAL_VERSION': '最小クライアントバージョン（強制更新用）',
    'reports.BRANDING_COMMON_RULES': '共通',
    'reports.BRANDING_COMPANY_DESC': 'ブランディング会社の詳細',
    'reports.BRANDING_COMPANY_INFO_RULES': '会社情報',
    'reports.BRANDING_COMPANY_NAME': '会社名',
    'reports.BRANDING_COMPANY_URL': '会社のURL',
    'reports.BRANDING_DEMO_GROUP': '自動登録されたデモユーザーを配置する',
    'reports.BRANDING_DEMOREGISTRATION_URL': 'デモ登録 (空の場合はこのサーバー、0は無効、ページにする場合はURL)',
    'reports.BRANDING_DEPOSIT_URL': 'デポジットURL（無効にする場合は空、ページにする場合はURL）',
    'reports.BRANDING_DISCLAMER': '免責事項テキスト (ログインスキン0に表示)',
    'reports.BRANDING_DOWJONES_ENCRYPTED_TOKEN': 'ダウ・ジョーンズ暗号化済トークン',
    'reports.BRANDING_EMAIL': '会社のEmail',
    'reports.BRANDING_EXT_LINK_RULES': '外部リンク',
    'reports.BRANDING_FORGOTPWD_URL': 'パスワードを忘れた',
    'reports.BRANDING_HTML_FOOTER': 'フッター画像',
    'reports.BRANDING_HTML_LOGINSCREEN_LOGO': 'ログイン画面の画像',
    'reports.BRANDING_HTML_LOGO': 'ロゴイメージ',
    'reports.BRANDING_HTML_NAME': 'アプリケーション名',
    'reports.BRANDING_HTML_STATION_RULES': 'バックオフィス',
    'reports.BRANDING_INFO_MESSAGE': 'ポップアップメッセージ（ログイン後にステーションで開く）',
    'reports.BRANDING_KEY': 'ブランディングキー(インストーラーをリンクするために使用)',
    'reports.BRANDING_KNOWLEDGE_BASE_URL': 'ナレッジベースURL(無効にする場合は-1)',
    'reports.BRANDING_LANG_SET': '言語リソース',
    'reports.BRANDING_LIVE_GROUP': '自動登録されたライブユーザーを配置する',
    'reports.BRANDING_LOGIN_MESSAGE': 'ログイン画面の警告メッセージ',
    'reports.BRANDING_MAIL_CONFIGURATION': 'メールの設定',
    'reports.BRANDING_MAIL_PATTERN': 'パターン',
    'reports.BRANDING_NET_BACKGROUND': '背景画像 (オプション/.bmp 任意のサイズ)',
    'reports.BRANDING_NET_BANNER': 'バナー画像 (.bmp 500x36)',
    'reports.BRANDING_NET_BROKERIMAGE': 'レポート画像 (.bmp 任意のサイズ)',
    'reports.BRANDING_NET_CONFIGURAION': 'デフォルト構成（program.iniファイル）',
    'reports.BRANDING_NET_DEFWORKSPACE': 'デフォルトのワークスペース（* .wrkファイル）',
    'reports.BRANDING_NET_FX_STYLE': 'FXセルスキン（コードを入力）',
    'reports.BRANDING_NET_HELP': 'ヘルプ URL',
    'reports.BRANDING_NET_ICON': 'アイコン画像 (.ico 16x16+32x32)',
    'reports.BRANDING_NET_INSTALLER_BANNER_IMAGE': 'インストーラー用バナー画像（500 * 59）',
    'reports.BRANDING_NET_INSTALLER_DIALOG_IMAGE': 'インストーラー用ダイアログ画像（500 * 316）',
    'reports.BRANDING_NET_LINK': 'アプリケーションリンク',
    'reports.BRANDING_NET_LOADING_IMAGE': 'ローディング画像 *.gif',
    'reports.BRANDING_NET_LOGIN_STYLE': 'ログイン画面スキン（コードを入力）',
    'reports.BRANDING_NET_LOGO': 'ロゴ画像 (.bmp 500x35)',
    'reports.BRANDING_NET_NAME': 'アプリケーション名 (アプリデータ/ショートカット用)',
    'reports.BRANDING_NET_OE_STYLE': '注文パネルスキン (コードを入力)',
    'reports.BRANDING_NET_RIBBONICON': 'リボンアイコン画像 (.ico 32x32)',
    'reports.BRANDING_NET_STATION_RULES': '.Net station',
    'reports.BRANDING_NET_USE_FINGERPRINT': '指紋認証の使用',
    'reports.BRANDING_PHONE': '会社の電話番号',
    'reports.BRANDING_POWERED_BY': 'Powered by（会社名または個人名）',
    'reports.BRANDING_PROPERTIES_RULES': 'ブランディングプロパティ',
    'reports.BRANDING_RULES': 'ブランディングの可視化',
    'reports.BRANDING_SCRIPT_URL': 'スクリプトURL',
    'reports.BRANDING_SERVER_URL': 'ブランドサーバーのURL（最初のページをリンクするURLまたはIP、ログイン前）',
    'reports.BRANDING_STATUS_MESSAGE': 'ステータスバーメッセージ（ステーションステータスバーに表示）',
    'reports.BRANDING_THEME': 'ルックアンドフィール',
    'reports.BRANDING_TOOLS_RULES': 'ツールメニューの外部Webリンク',
    'reports.BRANDING_TTP_FILE': 'パネルテンプレート',
    'reports.BRANDING_USERS_RULES': 'ユーザールール',
    'reports.BRANDING_WITHDRAW_URL': 'URLを撤回する（無効にする空、ページを入れる場合はURL、1-winフォーム）',
    'reports.branding.name': '名前',
    'reports.Broker Message Report': 'ブローカーメッセージレポート',
    'reports.Brokerage': 'ブローカレッジ',
    'reports.brokerCancel': 'ブローカーキャンセル',
    'reports.brokerlogin': 'ブローカーログイン',
    'reports.Buy': '買い',
    'reports.buy trade amount': 'Buy取引金額',
    'reports.buy trades': 'Buy取引',
    'reports.buyselltype': 'Buy/Sellタイプ',
    "reports.Can't allocate route for trade using hadget": 'hadget取引ルートを割り当てることができません',
    "reports.Can't close entry": 'エントリを閉じることができません',
    'reports.Cancel order:': '注文をキャンセルする：',
    'reports.Cancel request accepted by broker': 'ブローカーキャンセル要求受領',
    'reports.Cancel request received by STP': 'STPキャンセル要求受領',
    'reports.Canceled': 'キャンセル',
    'reports.Canceling': 'キャンセル',
    'reports.Cancelled': 'キャンセル',
    'reports.Cancelling': 'キャンセル',
    'reports.CantorFX': 'CantorFX',
    'reports.Cash Deposit And Withdrawal Report': '現金入出金レポート',
    'reports.cash_posted': '現金転記',
    'reports.Champ stats report': 'チャンプ統計レポート',
    'reports.change': '変更',
    'reports.Change password error, new password must differ from old one': 'パスワード変更エラー、新しいパスワードは古いパスワードと異なる必要があります',
    'reports.CHART_OE': 'チャート（OEでビルド）',
    'reports.CHART_VISUAL': 'チャート（ビジュアルトレード）',
    'reports.Chat History Report': 'チャット履歴レポート',
    'reports.CheckCompID': 'comp IDを確認',
    'reports.CheckLatency': 'レイテンシーを確認',
    'reports.ChoiceFX': 'ChoiceFX',
    'reports.CIBC': 'CIBC',
    'reports.Citi': 'Citi',
    'reports.ClearingVmTransfer': 'クリアリングVM転送',
    'reports.clfr_close_date': '終了日',
    'reports.clfr_fund_name': 'ファンド名',
    'reports.clientid': 'クライアントID',
    'reports.clienttype': 'クライアントタイプ',
    'reports.Close Limit order created': '指値注文を作成しました',
    'reports.Close Limit order created is off market': 'クローズ指値注文はオフマーケットです',
    'reports.Close Limit order created is unplaced': '取引所での指値注文はクローズされませんでした',
    'reports.Close Limit order received by broker': '指値注文はブローカーに受領されました',
    'reports.Close Market order created': '成約注文が作成されました',
    'reports.Close Market Order Created': '成約注文を作成しました',
    'reports.Close Market order created is off market': '成約注文はオフマーケットです',
    'reports.Close Market order created is unplaced': '取引所から成約注文が取り下げられました',
    'reports.Close Market order received by broker': '成行注文がブローカーに受領されました',
    'reports.Close OCO order created': 'クローズOCO注文を作成しました',
    'reports.Close OCO order created is off market': 'クローズOCO注文はオフマーケットです',
    'reports.Close OCO order created is unplaced': 'クローズOCOの注文は取引所から取り下げられました',
    'reports.Close OCO order received by broker': 'クローズOCO注文はブローカーに受領されました',
    'reports.Close order type': 'クローズ注文タイプ',
    'reports.Close position:': 'クローズポジション：',
    'reports.Close Sell': 'クローズSell',
    'reports.Close SL order created': 'SL注文を作成しました',
    'reports.Close SL order created is off market': 'SLの注文はオフマーケットです',
    'reports.Close SL order created is unplaced': 'クローズSL注文が取引所から取り下げられました',
    'reports.Close SL order received by broker': 'クローズSL注文がブローカによって受領されました',
    'reports.Close SL tr. stop order created': 'クローズSL Trストップオーダーが作成されました',
    'reports.Close SL tr. stop order created is off market': 'クローズSL Trストップオーダーはオフマーケットです',
    'reports.Close SL tr. stop order created is unplaced': 'クローズSL Trストップオーダーは取引所から取り下げられました',
    'reports.Close SL tr. stop order received by broker': 'クローズSL Trストップオーダーはブローカーに受領されました',
    'reports.Close Stop Limit order created': 'クローズ指値注文が作成されました',
    'reports.Close Stop Limit order created is off market': 'クローズストップ指値注文はオフマーケットです',
    'reports.Close Stop Limit order created is unplaced': 'クローズストップ指値注文は取引所から取り下げられました',
    'reports.Close Stop Limit order received by broker': 'クローズストップ指値注文はブローカーに受領されました',
    'reports.Close Stop order created': 'クローズストップオーダーが作成されました',
    'reports.Close Stop order created is off market': 'クローズストップオーダーはオフマーケットです',
    'reports.Close Stop order created is unplaced': 'クローズストップオーダーは取引所から取り下げられました',
    'reports.Close Stop order received by broker': 'クローズストップオーダーはブローカーに受領されました',
    'reports.Close TP order created': 'クローズTP注文が作成されました',
    'reports.Close TP order created is off market': 'クローズTP注文はオフマーケットです',
    'reports.Close TP order created is unplaced': 'クローズTP注文は取引所から取り下げられました',
    'reports.Close TP order received by broker': 'ブローカーが受け取ったTP注文を閉じる',
    'reports.Close Tr. stop order created': 'クローズTrストップオーダーが作成されました',
    'reports.Close Tr. stop order created is off market': 'クローズTrストップオーダーはオフマーケットです',
    'reports.Close Tr. stop order created is unplaced': 'クローズTrストップオーダーは取引所から取り下げられました',
    'reports.Close Tr. stop order received by broker': 'クローズTrストップオーダーはブローカーに受領されました',
    'reports.CLOSE_ALL': 'すべてクローズ',
    'reports.CLOSE_ALL_ORDERS': '全ての注文を取消す',
    'reports.CLOSE_ALL_POSITIONS': 'すべてのポジションをクローズする',
    'reports.CLOSE_FIFO': '証拠金不足となった場合ポジションを閉じる (FIFO)',
    'reports.CLOSE_LAGES': '証拠金不足となった場合ポジションを閉じる (大きい順)',
    'reports.CLOSE_LIFO': '証拠金不足となった場合ポジションを閉じる (LIFO)',
    'reports.closebytradeid': '取引IDでクローズ',
    'reports.Closed funds report': 'クローズ済みファンドレポート',
    'reports.Closed Positions': 'クローズ済みポジションレポート',
    'reports.closedat': 'クローズ',
    'reports.closedbyorderid': '注文IDでクローズ',
    'reports.ClosedResendInterval': 'クローズされた再送信間隔',
    'reports.CloseOptionPremium': 'クローズオプションプレミアム',
    'reports.Closeposition': 'ポジションを閉じる',
    'reports.closeprice': 'クローズ価格',
    'reports.closetime': 'クローズ時間',
    'reports.comission': '手数料',
    'reports.Comment': 'コメント',
    'reports.comments': 'コメント',
    'reports.comments.CancelOperation': 'キャンセル操作＃',
    'reports.COMMISIONSPLAN_RULES': '収益計画の可視化',
    'reports.commission': '手数料',
    'reports.commission_out': '手数料',
    'reports.commissionpertrade': '取引毎の手数料',
    'reports.commissions': '手数料',
    'reports.Commissions': '手数料',
    'reports.Commissions report': 'コミッションレポート',
    'reports.Commissions Report': 'コミッションレポート',
    'reports.Commissions Report(copy)': 'コミッションレポート(コピー)',
    'reports.commissiontotal': '手数料合計',
    'reports.COMMON_RULES': '共通ルール',
    'reports.Community': 'コミュニティ',
    'reports.ConfigFilePath': 'ファイルパスを設定',
    'reports.Connected': '接続中',
    'reports.Connection established': '接続されました',
    'reports.Connections Report': '接続レポート',
    'reports.ConnectionType': '接続タイプ',
    'reports.connrp_conntime': '接続時間の試行',
    'reports.connrp_fullname': '名前と名字',
    'reports.connrp_ip': 'IP',
    'reports.connrp_lastpwdchange': '最後のパスワード変更',
    'reports.connrp_login': 'ログイン',
    'reports.connrp_success': '接続の成功',
    'reports.ContinueInitializationOnError': 'エラー時に初期化を継続',
    'reports.Contract Specification Report': '契約仕様書',
    'reports.Counter Account Name': 'カウンターアカウント名',
    'reports.Counter Operation Id': 'カウンター操作ID',
    'reports.COUNTERACCOUNTS_RULES': 'カウンターアカウント',
    'reports.Created': '作成',
    'reports.Created by': 'によって作成',
    'reports.createdat': 'で作成',
    'reports.Creating': '作成',
    'reports.creator': '作成者',
    'reports.credit': 'クレジット',
    'reports.Credit': 'クレジット',
    'reports.crossprice': 'クロス価格',
    'reports.crosstype': 'クロスタイプ',
    'reports.currency': '通貨',
    'reports.Currency Exposure Report': '通貨エクスポージャーレポート',
    'reports.Currency summary exposure': '通貨エクスポージャーサマリー',
    'reports.currency.balance_interest': '残高利息（％）',
    'reports.currency.interest_buy_rate': 'ポジティブ利息（％）',
    'reports.currency.interest_sell_rate': 'ネガティブ利子（％）',
    'reports.currency.leverage_negative': 'ネガティブエクスポージャーレバレッジ',
    'reports.currency.leverage_positive': 'ポジティブエクスポージャーレバレッジ',
    'reports.currency.min_change': '最小限の変更',
    'reports.currency.name': '名前',
    'reports.Currenex': 'Currenex',
    'reports.CurrenexFIX': 'CurrenexFIX',
    'reports.Current Balance': '現在の収支',
    'reports.currentprice': '現在の価格',
    'reports.CUSTOM_COLUMN_BTN_SCRIPT': 'カスタム列（ボタンスクリプト）',
    'reports.customReport': 'カスタムレポート',
    'reports.DataDictionary': 'データ辞書',
    'reports.DateTime': '日付/時間',
    'reports.days': '日',
    'reports.daystillexpiration': '有効期限までの日数',
    'reports.DBFX': 'DBFX',
    'reports.dealercommission': 'ディーラーコミッション',
    'reports.debit': 'デビット',
    'reports.Default settings failed': '初期設定が失敗しました',
    'reports.Delayed Limit Order Created': '保留中の指値注文が作成されました',
    'reports.Delayed Limit Order Modified': '保留中の指値注文が変更されました',
    'reports.Delayed Limit Order Removed': '保留中の指値注文が削除されました',
    'reports.Delayed OCO Order Created': '保留中のOCO注文が作成されました',
    'reports.Delayed Stop Limit Order Created': 'ストップ指値注文が作成されました',
    'reports.Delayed Stop Limit Order Modified': 'ストップ指値注文が変更されました',
    'reports.Delayed Stop Limit Order Removed': 'ストップ指値注文が削除されました',
    'reports.Delayed Stop Order Created': '保留中のストップオーダーが作成されました',
    'reports.Delayed Stop Order Modified': '保留中のストップオーダーが変更されました',
    'reports.Delayed Stop Order Removed': '保留中のストップオーダーが削除されました',
    'reports.Delayed Trailing Stop Order Created': 'トレーリングストップ注文を予約しました',
    'reports.Delayed Trailing Stop Order Modified': 'トレーリングストップ注文が変更されました',
    'reports.Delayed Trailing Stop Order Removed': 'トレーリングストップ注文が削除されました',
    'reports.DelayedCharging': '遅延課金',
    'reports.DelayedDataCharging': '遅延データ課金',
    'reports.delivered': '配信済み',
    'reports.DeliveredForward': '前へ配信',
    'reports.Delivery': '受渡',
    'reports.demoMode': 'デモモード',
    'reports.deposit': '入金',
    'reports.Deposit': '入金',
    'reports.Detail': '詳細',
    'reports.Deutsche Bank': 'Deutsche Bank',
    'reports.DeutscheBankFIXFeed': 'DeutscheBankFIXFeed',
    'reports.DGCX': 'DGCX',
    'reports.Dictionary': '辞書',
    'reports.discount': 'ディスカウント',
    'reports.dividends': '配当金',
    'reports.DO_NOT_CLOSE': 'ポジションをクローズしない',
    'reports.DukasCopy': 'DukasCopy',
    'reports.DWT': 'DWT',
    'reports.email': 'Email',
    'reports.EMAIL_NOTIFICATION': 'Eメール通知パターンの可視化',
    'reports.EMAIL_RULES': 'Eメール通知パターンの可視化',
    'reports.EndDay': '終了日',
    'reports.EndTime': '終了時間',
    'reports.entityId': 'エンティティID',
    'reports.enum_StopOutType_AutoClose': 'オートクローズ',
    'reports.enum_StopOutType_CloseAllByInstruments': '銘柄毎にクローズ',
    'reports.enum_StopOutType_CloseAllPositions': 'すべてのポジションをクローズ',
    'reports.enum_StopOutType_CloseLagestLoss': '証拠金不足によりポジションクローズ (損失が大きい順)',
    'reports.enum_StopOutType_ClosePositionsByMarginExcess': 'マージン超過によるポジションのクローズ',
    'reports.enum_StopOutType_DoNotClosePositions': 'ポジションを閉じない',
    'reports.enum_StopOutType_FIFO': '証拠金不足となった場合ポジションを閉じる (FIFO)',
    'reports.enum_StopOutType_LargestFirst': '証拠金不足となった場合ポジションを閉じる (大きい順)',
    'reports.enum_StopOutType_LIFO': '証拠金不足となった場合ポジションを閉じる (LIFO)',
    'reports.Error occured during downloading update:': '更新のダウンロード中にエラーが発生しました：',
    'reports.Error sending email': 'メール送信エラー',
    'reports.Error: Not enough Margin to open position': 'エラー: 証拠金不足のため注文不可',
    'reports.error.certificate_corrupted': 'ユーザーの証明書が破損しています',
    'reports.error.certificate_not_found': 'ユーザーの証明書が見つかりません',
    'reports.error.error_during_saving_route': 'ルートの保存中にエラーが発生しました。管理者に連絡してください。',
    'reports.error.fill_mandatory': '必須項目を全て入力してください',
    'reports.error.name_is_in_use': 'この名前は既に存在します',
    'reports.error.not_valid_email': '無効なE-mail',
    'reports.error.not_valid_firstname': '無効な名前',
    'reports.error.not_valid_instrumentname_capital': '無効な名前：大文字のみが必要です。',
    'reports.error.not_valid_instrumentname_exist': '無効な名前：この名前は既に存在します。',
    'reports.error.not_valid_instrumentname_length': '無効な名前：名前は32文字を超えることはできません。',
    'reports.error.not_valid_instrumenttypename': '銘柄種別が無効です：正しい銘柄種別を指定してください。',
    'reports.error.not_valid_lastname': '無効な苗字',
    'reports.error.not_valid_login': '無効なログイン',
    'reports.error.not_valid_name': '無効な名前',
    'reports.error.not_valid_name_exist': '無効な名前 この名前は既に存在します',
    'reports.error.not_valid_phone_number': '無効な電話番号 デジタルデータのみを入力してください。',
    'reports.error.photo_not_found': '写真が見つかりません',
    'reports.error.too_short_phone_password': '電話パスワードが短すぎます',
    'reports.error.wrong_percenage': '間違ったパーセンテージ',
    'reports.ESignal': 'ESignal',
    'reports.event': 'イベント',
    'reports.Event History Report': 'イベント履歴レポート',
    'reports.Event History Report with IP': 'IP付きイベント履歴レポート',
    'reports.Exception': '例外',
    'reports.exchange': '取引所',
    'reports.Exchange': '取引所',
    'reports.Executed': '約定済み',
    'reports.Executed Orders Report': '実行済み注文レポート',
    'reports.executedat': '実行する',
    'reports.Executing': '約定済み',
    'reports.executionfee': '執行手数料',
    'reports.executionprice': '執行価格',
    'reports.expiredate': '有効期限',
    'reports.expiresat': '期限切れ',
    'reports.external_order_id': '外部注文ID',
    'reports.externalid': '外部ID',
    'reports.externalorderid': '外部注文ID',
    'reports.extfield': '拡張フィールド',
    'reports.EXTFIELDS_RULES': '拡張フィールドの可視化',
    'reports.extprice': '拡張価格',
    'reports.False': '',
    'reports.fee': '手数料',
    'reports.Fee: [addliquidityrebate]': '手数料:[追加リクイディティリベート]',
    'reports.Fee: [Brokerage]': '手数料:[ブローカー]',
    'reports.Fee: [Exchange]': '手数料:[取引所]',
    'reports.Fee: [InvestorProtectionLevy]': '手数料:[投資家保護基金]',
    'reports.Fee: [removeliquidityrebate]': '手数料:[流動性リベートの削除]',
    'reports.Fee: [SecuritiesTransferTax]': '手数料:[有価証券取引税]',
    'reports.Fee: [SettlementFee]': '手数料:[決済手数料]',
    'reports.Fee: [ValueAddedTax]': '手数料:[消費税]',
    'reports.Feed Data': 'フィードデータ',
    'reports.Fees Report': '手数料レポート',
    'reports.FFastFillFIXFeed': 'FFastFillFIXFeed',
    'reports.fiahr_action': 'アクション',
    'reports.fiahr_amount': '量',
    'reports.fiahr_capital': '資本',
    'reports.fiahr_count': '株数',
    'reports.fiahr_date': '日付',
    'reports.fiahr_fee': 'リワード',
    'reports.fiahr_grosspl': 'スポット損益',
    'reports.fiahr_investor_id': 'インベスターID',
    'reports.fiahr_investor_name': 'インベスター',
    'reports.fiahr_netpl': '総損益額',
    'reports.fiahr_pamm_id': 'ファンドID',
    'reports.fiahr_pamm_name': 'ファンド',
    'reports.fiahr_price': '価格',
    'reports.fiahr_requestid': 'リクエストID',
    'reports.Fidessa': 'Fidessa',
    'reports.FileIncludeMilliseconds': 'ファイルにはミリ秒が含まれます',
    'reports.FileIncludeTimeStampForMessages': 'ファイルにはメッセージのタイムスタンプが含まれます',
    'reports.FileLogHeartbeats': 'ファイルログのハートビート',
    'reports.Filled': '成立',
    'reports.Filled close Limit order': '指値決済注文の約定が成立しました',
    'reports.Filled close Limit order (Mutual)': '指値決済注文の約定が成立しました (複数)',
    'reports.Filled close Market order': '成行決済注文の約定が成立しました',
    'reports.Filled close Market order (Mutual)': '成行決済注文の約定が成立しました (複数)',
    'reports.Filled close OCO order': 'OCO決済注文の約定が成立しました',
    'reports.Filled close OCO order (Mutual)': 'OCO決済注文の約定が成立しました (複数)',
    'reports.Filled close SL order': 'S/Lの約定が成立しました',
    'reports.Filled close SL order (Mutual)': 'S/Lの約定が成立しました (複数)',
    'reports.Filled close SL tr. stop order': 'クローズSL Trストップオーダー約定',
    'reports.Filled close SL tr. stop order (Mutual)': 'クローズSL Trストップオーダー約定（相互）',
    'reports.Filled close Stop Limit order': 'ストップリミット注文の約定が成立しました',
    'reports.Filled close Stop Limit order (Mutual)': 'ストップリミット注文の約定が成立しました (複数)',
    'reports.Filled close Stop order': '逆指値決済注文の約定が成立しました',
    'reports.Filled close Stop order (Mutual)': '逆指値決済注文の約定が成立しました (複数)',
    'reports.Filled close TP order': 'T/Pの約定が成立しました',
    'reports.Filled close TP order (Mutual)': 'T/Pの約定が成立しました (複数)',
    'reports.Filled close Tr. stop loss order': 'クローズTrストップロスオーダー約定',
    'reports.Filled close Tr. stop order': 'ストップリミット決済注文の約定が成立しました',
    'reports.Filled close Tr. stop order (Mutual)': 'ストップリミット決済注文の約定が成立しました (複数)',
    'reports.Filled Limit order': '指値注文の約定が成立しました',
    'reports.Filled Limit order (Mutual)': '指値注文がの約定が成立しました (複数)',
    'reports.Filled Market order': '成行注文の約定が成立しました',
    'reports.Filled Market order (Mutual)': '成行注文の約定が成立しました (複数)',
    'reports.Filled OCO order': 'OCO注文の約定が成立しました',
    'reports.Filled OCO order (Mutual)': 'OCO注文の約定が成立しました (複数)',
    'reports.Filled SL order': 'S/Lの約定が成立しました',
    'reports.Filled SL order (Mutual)': 'S/Lの約定が成立しました (複数)',
    'reports.Filled SL tr. stop order': 'トレーリングストップ注文のS/Lの約定が成立しました',
    'reports.Filled SL tr. stop order (Mutual)': 'トレーリングストップ注文のS/Lの約定が成立しました (複数)',
    'reports.Filled Stop Limit order': 'ストップリミット注文の約定が成立しました',
    'reports.Filled Stop Limit order (Mutual)': 'ストップリミット注文の約定が成立しました (複数)',
    'reports.Filled Stop order': '逆指値注文の約定が成立しました',
    'reports.Filled Stop order (Mutual)': '逆指値注文の約定が成立しました (複数)',
    'reports.Filled TP order': 'T/Pの約定が成立しました',
    'reports.Filled TP order (Mutual)': 'T/Pの約定が成立しました (複数)',
    'reports.Filled Tr. stop order': 'トレーリングストップ注文の約定が成立しました',
    'reports.Filled Tr. stop order (Mutual)': 'トレーリングストップ注文の約定が成立しました（複数）',
    'reports.filledamount': '約定量',
    'reports.fir_exit_capital': '現在の資本',
    'reports.fir_exit_price': '現在の株価',
    'reports.fir_investor_id': 'インベスターID',
    'reports.fir_investor_name': 'インベスター名',
    'reports.fir_join_capital': '開始資本',
    'reports.fir_join_date': '参加日',
    'reports.fir_join_price': '参加価格',
    'reports.fir_pamm_id': 'ファンドID',
    'reports.fir_pamm_name': 'ファンド名',
    'reports.fir_reinvest_cycles': '再投資サイクル',
    'reports.fir_share_count': '＃シェア',
    'reports.FirstDerivatives': 'FirstDerivatives',
    'reports.firstname': '名前',
    'reports.FixedFee': '固定コミッション',
    'reports.Foreks': 'Foreks',
    'reports.Forex balances report': 'FX残高レポート',
    'reports.Formal Dialog Report': '公式ダイアログレポート',
    'reports.FORTEX': 'FORTEX',
    'reports.fpghr_count': '株式',
    'reports.fpghr_exit_amount': '出口量',
    'reports.fpghr_exit_price': '出口価格',
    'reports.fpghr_fee': '手数料',
    'reports.fpghr_first_join_date': '参加日',
    'reports.fpghr_grosspl': 'スポット損益',
    'reports.fpghr_investor_id': 'インベスターID',
    'reports.fpghr_investor_name': 'インベスター名',
    'reports.fpghr_join_amount': '参加金額',
    'reports.fpghr_join_price': '参加価格',
    'reports.fpghr_last_exit_date': 'エグジット日',
    'reports.fpghr_netpl': '純損益',
    'reports.fpghr_pamm_id': 'ファンドID',
    'reports.fpghr_pamm_name': 'ファンド名',
    'reports.fpghr_period': '期間',
    'reports.fpghr_period_end_date': '終了日',
    'reports.fpghr_period_start_date': '開始日',
    'reports.fprhr_date': '最後の更新',
    'reports.fprhr_details': '詳細',
    'reports.fprhr_entryid': 'エントリーID',
    'reports.fprhr_event': 'イベント',
    'reports.fprhr_investor_id': 'インベスターID',
    'reports.fprhr_investor_name': 'インベスター',
    'reports.fprhr_pamm_id': 'ファンドID',
    'reports.fprhr_pamm_name': 'ファンド',
    'reports.fprhr_requestid': 'リクエストID',
    'reports.from': 'から',
    'reports.From.SERVER': 'サーバ',
    'reports.fromDate': 'から',
    'reports.fromDateConnAttempt': 'から',
    'reports.fromDatePwdChange': 'から（パス）',
    'reports.FUNCTION_ACCOUNT_GROUP': 'アカウントグループ',
    'reports.FUNCTION_ACCOUNT_OPERATIONS': '資金調達業務の可視化',
    'reports.FUNCTION_ACCOUNTS': 'アカウント',
    'reports.FUNCTION_ALERTS': 'アラート',
    'reports.FUNCTION_ARBITRAGER': 'アービトレージャー',
    'reports.FUNCTION_AUTOBROKET_LIST': 'オートブローカーリスト',
    'reports.FUNCTION_BACK_TESTER': 'バックテスター',
    'reports.FUNCTION_BARS_FROM': 'からバーを作る',
    'reports.FUNCTION_BASE_CURRENCY': 'システムの基本通貨（銘柄のクロスを変更する必要がある場合があります）',
    'reports.FUNCTION_BASKET': 'バスケット',
    'reports.FUNCTION_BETTING': 'ベッティング',
    'reports.FUNCTION_BINDEDORDERS': 'OCO注文を許可する',
    'reports.FUNCTION_BLOCKED_SUMM_FOR_ORDERS': '注文用にロックされた資金',
    'reports.FUNCTION_BRANDING': 'ブランディング',
    'reports.FUNCTION_BRANDINGADMIN': 'ブランディング管理者',
    'reports.FUNCTION_BUILDER': 'ビルダー',
    'reports.FUNCTION_CACHEADMIN': 'キャッシュ管理',
    'reports.FUNCTION_CHART': 'チャート',
    'reports.FUNCTION_CHAT': 'チャット',
    'reports.FUNCTION_CLOSE_POSITIONS_DISABLE': 'ポジションのクローズを許可しない',
    'reports.FUNCTION_COMMUNITY': 'コミュニティ',
    'reports.FUNCTION_CONFIGADMIN': '構成とブランディング',
    'reports.FUNCTION_CONNECTIONSSADMIN': '接続管理',
    'reports.FUNCTION_CROSS_PAIR': 'クロス価格',
    'reports.FUNCTION_CUR_EXP': '通貨エクスポージャー',
    'reports.FUNCTION_DISABLE_BUY': '買い注文の無効化',
    'reports.FUNCTION_DISABLE_OPTION_TRADING': 'オプション注文の無効化',
    'reports.FUNCTION_DISABLE_SELL': '売り注文の無効化',
    'reports.FUNCTION_DO_NOT_MARGIN': 'マージンを使用しない',
    'reports.FUNCTION_EVENT_LOG': 'イベントログ',
    'reports.FUNCTION_FUNDING': '運用管理者',
    'reports.FUNCTION_GENERATE_OPTIONS': 'レベル1からオプションを生成する',
    'reports.FUNCTION_HEDGE_RULE_TYPE': 'ヘッジルールリスト：',
    'reports.FUNCTION_INSTRUMENT_LOOKUP': 'シンボル検索',
    'reports.FUNCTION_INSTRUMENT_PORTFOLIO': 'ポートフォリオ',
    'reports.FUNCTION_INSTRUMENTSADMIN': 'シンボル管理者',
    'reports.FUNCTION_INTERSERVER': 'インターサーバー',
    'reports.FUNCTION_LEVEL2': 'レベル2データと注文',
    'reports.FUNCTION_LEVEL3': 'Time & Sales',
    'reports.FUNCTION_LOCKED_BY_BROKER': 'ブローカーによってロックされています',
    'reports.FUNCTION_MANUAL': '手動注文を許可する',
    'reports.FUNCTION_MARGIN_MODE': 'マージンモード',
    'reports.FUNCTION_MASTER': 'マスターアカウント管理',
    'reports.FUNCTION_MATRIX': 'マトリックス',
    'reports.FUNCTION_MULTI_CONNECTION': 'マルチ接続を許可する',
    'reports.FUNCTION_MUTUAL_CLOSE': '相互クローズを許可する',
    'reports.FUNCTION_NEWS': 'ニュース',
    'reports.FUNCTION_NEWSADMIN': 'ニュース管理者',
    'reports.FUNCTION_NOT_ALLOW_OPEN_POSITION': 'オープンポジションを許可しない',
    'reports.FUNCTION_NOT_ALLOW_SHORT_STOCK': 'ショートストックを許可しない',
    'reports.FUNCTION_ONE_COUNTERACCOUNT': '1つのカウンターアカウント',
    'reports.FUNCTION_ONE_POSITION': 'シンボルごとに1つのポジション（ヘッジ）',
    'reports.FUNCTION_ONE_QUOTECACHECOUNT': 'すべてに同一キャッシュサイズ',
    'reports.FUNCTION_OPTIONS': 'オプション',
    'reports.FUNCTION_ORDER_BOOK': '注文履歴',
    'reports.FUNCTION_PARTIAL_CLOSE': '部分クローズを許可する',
    'reports.FUNCTION_POSITION_BALANCE': 'ポジション残高',
    'reports.FUNCTION_POSITIONSADMIN': 'ポジション管理者',
    'reports.FUNCTION_QUOTE_HISTORY': '配信履歴',
    'reports.FUNCTION_QUOTES': '配信',
    'reports.FUNCTION_REPORT': 'レポート',
    'reports.FUNCTION_REPORTADMIN': 'レポート管理者',
    'reports.FUNCTION_RESERVER_WITHDRAWAL': '出金',
    'reports.FUNCTION_RFQ': 'RFQパネル',
    'reports.FUNCTION_ROLESADMIN': 'ユーザーグループ管理者',
    'reports.FUNCTION_ROUTESADMIN': 'ルート管理者',
    'reports.FUNCTION_SCHEDULERADMIN': 'スケジューラ管理者',
    'reports.FUNCTION_SCRIPT_BUILDER': 'スクリプトビルダー',
    'reports.FUNCTION_SERVICESADMIN': 'サービス管理者',
    'reports.FUNCTION_SETTINGSADMIN': '設定管理者',
    'reports.FUNCTION_SHOW_ORDERS': '注文',
    'reports.FUNCTION_SHOW_POSITIONS': '役職',
    'reports.FUNCTION_SLAVE': 'スレーブ',
    'reports.FUNCTION_SLTP': 'SL/TPを許可',
    'reports.FUNCTION_SMS_SERVICE_DEST': 'SMSサービスの宛先',
    'reports.FUNCTION_SN': 'ライセンス番号',
    'reports.FUNCTION_STRATEGIESADMIN': 'ストラテジー管理者',
    'reports.FUNCTION_SUPPORT_EMAIL': 'サポートのE-mail',
    'reports.FUNCTION_TABLESADMIN': 'テーブル管理者',
    'reports.FUNCTION_THREADEDSAVER': 'スレッドセーバーが有効',
    'reports.FUNCTION_TRADES': '約定済み注文',
    'reports.FUNCTION_TRADING': '取引',
    'reports.FUNCTION_TRADING_MODE': '取引モード',
    'reports.FUNCTION_TRAILING_STOP': 'トレーリングストップを許可',
    'reports.FUNCTION_USER_CONNECTION_POLICY': 'ユーザーのマルチ接続ポリシー',
    'reports.FUNCTION_USERSADMIN': 'ユーザー管理者',
    'reports.FUNCTION_VALIDATE_SSL_CERTIFICATE': 'SSL証明書の検証が必要',
    'reports.FUNCTION_VENDOR_NAME': 'ベンダー名',
    'reports.FUNCTION_WATCHLIST': 'ウォッチリスト',
    'reports.Fund Gain History Report': '資金収益履歴レポート',
    'reports.Fund Investments Report': '資金投資レポート',
    'reports.Fund Investor Action History Report': '資金投資家行動履歴レポート',
    'reports.Fund Investor History Report': '資金投資家履歴レポート',
    'reports.Fund Request History Report': '信金請求履歴レポート',
    'reports.Fund Transfer': '資金振替え',
    'reports.fund_type.closed': 'クローズドエンド',
    'reports.fund_type.open': 'オープンエンド',
    'reports.fundID': 'ファンドID',
    'reports.FUNDING_RULES': '資金調達業務の可視性',
    'reports.fxintegrate.cert.host.verify': 'fx integrate cert host verify',
    'reports.fxintegrate.cert.root.ca.file': 'fx integrate cert root ca file',
    'reports.fxintegrate.host': 'fx integrate host',
    'reports.fxintegrate.logging.file': 'fx integrate logging file',
    'reports.fxintegrate.logging.maxfilecount': 'fx integrate logging maximum file count',
    'reports.fxintegrate.logging.maxfilesize': 'fx integrate logging maximum file size',
    'reports.fxintegrate.logging.output': 'fx integrate logging output',
    'reports.fxintegrate.logging.priority': 'fx integrate logging priority',
    'reports.fxintegrate.port': 'fx integrate port',
    'reports.fxintegrate.protocol': 'fxintegrate protocol',
    'reports.fxintegrate.usessl': 'fx integrate use ssl',
    'reports.GENERAL_COMMON_RULES': '一般共通ルール',
    'reports.GENERAL_USER_RULES': '一般ルール',
    'reports.GFT': 'GFT',
    'reports.gi.available_margin': '余剰証拠金',
    'reports.gi.avaliable_funds': '注文可能額',
    'reports.gi.balance': '残高',
    'reports.gi.balance_blocked_by_orders': '注文によってロックされました',
    'reports.gi.cur_balance': '有効評価額',
    'reports.gi.cur_margin': '通貨マージン、％',
    'reports.gi.fund_capital': '資金',
    'reports.gi.fund_used': '使用資金',
    'reports.gi.maintain_margin': '維持証拠金',
    'reports.gi.margin_orders': '注文のマージン',
    'reports.gi.margin_positions': 'ポジションのマージン',
    'reports.gi.margin_stopout': 'ロスカット',
    'reports.gi.margin_stoptrade': '取引停止',
    'reports.gi.margin_used': '使用したマージン',
    'reports.gi.margin_warning': 'マージンコール',
    'reports.gi.min_margin': '最小マージン',
    'reports.gi.pos_maintenance_req': 'ポジション維持要件',
    'reports.gi.risk_lvl': 'リスクレベル',
    'reports.gi.stock_value': '株式',
    'reports.gi.today_released_pnl': '実現損益',
    'reports.gi.withdrawal_available': '出金可能額',
    'reports.Goldman Sachs': 'Goldman Sachs',
    'reports.grosspl': 'スポット損益',
    'reports.groupid': 'グループID',
    'reports.groupname': 'グループ',
    'reports.GTL': 'GTL',
    'reports.HeartBtInt': 'ハートビート間隔',
    'reports.hedged': 'STP (ストレートスループロセッシング)',
    'reports.helpIndex': 'http://protrader.org/en/knowledge-base/pt3/desktop/account/reports',
    'reports.host': 'ホスト',
    'reports.HOT_BUTTON': 'ホットキー',
    'reports.HotSpot': 'ホットスポット',
    'reports.hours': '時',
    'reports.HTTP.SERVER.HOST': 'HTTPサーバー ホスト',
    'reports.HTTP.SERVER.PORT': 'HTTPサーバー ポート',
    'reports.HTTP.SERVER.PROTOCOL': 'HTTPサーバー プロトコル',
    'reports.HTTP.URL.HEARTBEAT': 'HTTP URL ハートビート',
    'reports.HTTP.URL.LOGIN': 'HTTP URL ログイン',
    'reports.HTTP.URL.LOGOUT': 'HTTP URL ログアウト',
    'reports.HTTP.URL.PASSWORD.VALIDATION': 'HTTP URL パスワード認証',
    'reports.HTTP.URL.PRELOGIN': 'HTTP URL プレログイン',
    'reports.HTTP.URL.QUERY': 'HTTP URL クエリ',
    'reports.HTTP.URL.WORKFLOW': 'HTTP URL ワークフロー',
    'reports.IB Trades Report': 'IB取引レポート',
    'reports.IGIndex': 'IGIndex',
    'reports.IndexingQuoteFrequency(sec)': 'Indexing quote frequency (sec)',
    'reports.Information': '情報',
    'reports.Informational message': '情報メッセージ',
    'reports.Init margin req.': '必要証拠金.',
    'reports.Init. margin req.': '必要証拠金.',
    'reports.INSTALLER_SERVICE_URL': 'サービスインストーラーURL',
    'reports.InstrRouteMap': 'ルート図',
    'reports.instrtype': '銘柄グループ',
    'reports.INSTRTYPES_RULES': '銘柄グループの表示',
    'reports.instrument': '銘柄',
    'reports.Instrument': '銘柄',
    'reports.instrument group': '銘柄グループ',
    'reports.Instrument Specification': '銘柄の仕様',
    'reports.Instrument Statistic Report': '銘柄統計レポート',
    'reports.Instrument Usage By Signal Report': 'シンボル別シグナルレポート使用レポート',
    'reports.Instrument Visibility Report': 'シンボル可視化レポート',
    'reports.INSTRUMENT_DERIVATIVES': 'デリバティブ',
    'reports.INSTRUMENT_GENERAL': '全般',
    'reports.INSTRUMENT_MARGIN': '証拠金',
    'reports.INSTRUMENT_TRADING': '貿易',
    'reports.INSTRUMENT_TYPE_DERIVATIVES': 'デリバティブ',
    'reports.instrument.group': '銘柄グループ',
    'reports.instrument.name': '銘柄名',
    'reports.instrumentgroup': '銘柄グループ',
    'reports.InstrumentId': '銘柄',
    'reports.instrumentname': '銘柄',
    'reports.instruments': '銘柄',
    'reports.instrumenttype': '銘柄タイプ',
    'reports.instrumenttype.duplicatename': 'この名前のシンボルグループは既に存在します',
    'reports.instrumenttype.inheritanceerror': 'タイプ継承のエラー',
    'reports.instrumentType.name': '銘柄グループ名',
    'reports.instrumentType.superId': 'シンボルスーパータイプ名',
    'reports.instrumentVisibilityReport': 'シンボル可視性レポート',
    'reports.Integral': 'Integral',
    'reports.IntegralFIX': 'IntegralFIX',
    'reports.InteractiveData': 'InteractiveData',
    'reports.IntererstOnAvailableFundsAndUnsettledCash': '利用可能な資金の利子+未決済の現金',
    'reports.interest': '利子',
    'reports.InterestOnAvailableFunds': '注文可能額への利子',
    'reports.InterestOnCash': '現金の利子',
    'reports.InterestOnMarginAvailable': '使用可能な証拠金の利子',
    'reports.InterestOnMarginAvailableAndUnsettledCash': '使用可能な証拠金と未決済現金の利子',
    'reports.Invalid installer hash.': 'インストーラーのハッシュ無効',
    'reports.Invalid installer size.': 'インストーラーのサイズ無効',
    'reports.Invalid installer URL.': 'インストーラーURL無効',
    'reports.Invalid password:New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.': 'パスワードは、8文字以上で、大文字、小文字、数字、記号 (@#$%) をそれぞれ1種類以上含めて設定してください もう一度入力し直してください',
    'reports.Invalid price for the order.': '無効な価格による注文',
    'reports.Investor Action History Report': '投資家行動履歴レポート',
    'reports.investorID': 'インベスターID',
    'reports.InvestorProtectionLevy': '投資家保護の賦課金',
    'reports.ip': 'IP',
    'reports.IP': 'IP',
    'reports.IQFeed': 'IQFeed',
    'reports.irdnumber': 'IR番号',
    'reports.is out of range': '範囲外です',
    'reports.IsOnlyLastPeriod': '最終期間',
    'reports.isPriceStream': '価格ストリームです',
    'reports.isShowLots': 'ロット単位で表示',
    'reports.IsShowLots': 'ロット単位で表示',
    'reports.IsShowPamm': 'ファンドを表示',
    'reports.issingle': 'はシングル',
    'reports.JdbcDriver': 'JDBCドライバー',
    'reports.JdbcPassword': 'JDBCパスワード',
    'reports.JdbcSessionIdDefaultPropertyValue': 'JDBCセッションIDのデフォルトプロパティ値',
    'reports.JdbcURL': 'JdbcURL',
    'reports.JdbcUser': 'JDBCユーザー',
    'reports.JMS.INIT.QUEUE': 'JMS初期キュー',
    'reports.JMS.SERVER.HOST': 'JMSサーバー ホスト',
    'reports.JMS.SERVER.PASSWORD': 'JMSサーバー パスワード',
    'reports.JMS.SERVER.PORT': 'JMSサーバー ポート',
    'reports.JMS.SERVER.PROTOCOL': 'JMSサーバー プロトコル',
    'reports.JMS.SERVER.USER': 'JMSサーバー ユーザー',
    'reports.JPMorganTrade': 'JPMorganTrade',
    'reports.keystoreAlias': 'キーストアのエイリアス',
    'reports.keystoreFile': 'キーストアファイル',
    'reports.KyteFIX': 'KyteFIX',
    'reports.L1port': 'L1ポート',
    'reports.L2port': 'L2ポート',
    'reports.LADDER_VIEW': 'Super DOM',
    'reports.LADDER_VIEW_OE': 'Super DOM（OEでビルド）',
    'reports.Last fill price': '前回約定価格',
    'reports.lastname': '苗字',
    'reports.Level1Charging': 'レベル1課金',
    'reports.Level1DataCharging': 'レベル1データ課金',
    'reports.Level2Charging': 'レベル2課金',
    'reports.Level2DataCharging': 'レベル2データ課金',
    'reports.Level3Charging': 'レベル3課金',
    'reports.Level3DataCharging': 'レベル3データ課金',
    'reports.Limit accepted by broker': 'ブローカーが指値注文を受付けました',
    'reports.Limit accepted by STP': 'STPにて指値注文を受付けました',
    'reports.Limit order accepted': '指値注文を受付けました',
    'reports.Limit order activated': '指値注文が有効になりました',
    'reports.Limit order cancel request': '指値注文の取消がリクエストされました',
    'reports.Limit order canceled': '指値注文が取消しされました',
    'reports.Limit order created': '指値注文を受付けました',
    'reports.Limit Order Created': '指値注文を受付けました',
    'reports.Limit order created is off market': '指値注文は取引時間外です',
    'reports.Limit order created is unplaced': '取引所が指値注文を受付けませんでした',
    'reports.Limit order is awaiting market': '指値注文はマーケットで約定の成立待ちです',
    'reports.Limit Order Modified': '指値注文が変更されました',
    'reports.Limit order partially reject': '指値注文の一部が取消しされました',
    'reports.Limit order placing request': '指値注文のリクエストを受付けました',
    'reports.Limit order received by broker': 'ブローカーが指値注文を受付けました',
    'reports.Limit order rejected': '指値注文が受付けされませんでした',
    'reports.Limit Order Removed': '指値注文が削除されました',
    'reports.Limit order replace request': '指値注文の予約内容が変更されました',
    'reports.Limit order replaced': '指値注文が変更されました',
    'reports.Limit order restated': '指値注文が再度注文されました',
    'reports.Linked Users': 'リンクされたユーザー',
    'reports.LMAX': 'LMAX',
    'reports.LMAX FIX': 'LMAX FIX',
    'reports.Local': 'ローカル',
    'reports.locked': 'ロック済み',
    'reports.LogFileMaxSize': 'ログファイルの最大サイズ',
    'reports.login': '取引口座',
    'reports.Login': 'ログイン',
    'reports.LOGIN.USER.NAMESPACE': 'ログインユーザーネームスペース',
    'reports.Login/password combination is not valid': 'アカウント情報が一致しません',
    'reports.LogonTimeout': 'ログオンタイムアウト',
    'reports.Logout': 'ログアウト',
    'reports.Lots to open': '開くロット',
    'reports.lotsize': 'ロットサイズ',
    'reports.Mail Settings': 'メール設定',
    'reports.mail.mailsettings': 'メール設定',
    'reports.mailPattern.ChatDuplicating': 'チャットの重複',
    'reports.mailPattern.DemoUserNotify': 'デモユーザーへの有効期限通知',
    'reports.mailPattern.DemoUserRegistration': 'デモユーザーへの登録確認',
    'reports.mailPattern.DemoUserRemove': 'デモユーザーの削除確認',
    'reports.mailPattern.DepositWithdrawalNotification': '入出金Eメール通知',
    'reports.mailPattern.MarginCall': 'マージンコールの通知',
    'reports.mailPattern.NewsCreated': 'ニュースが作成されました',
    'reports.mailPattern.OrderPlaced': '逆指値/指値の注文が出ました',
    'reports.mailPattern.OrderRemoved': '逆指値/指値の注文が削除されました',
    'reports.mailPattern.PositionClosed': 'ポジションの決済確認',
    'reports.mailPattern.PositionOpened': '新しいポジションの確認',
    'reports.mailPattern.SignalDuplicating': 'シグナルの重複',
    'reports.mailPattern.TransactionStatementNotifyBroker': 'ブローカーへのデイリーリポート',
    'reports.mailPattern.TransactionStatementNotifyUser': 'ユーザーへのデイリーリポート',
    'reports.mailPattern.UserEmailConfirmation': '確認Eメール',
    'reports.mailPattern.UserPasswordRecovery': 'ユーザーパスワードの復旧',
    'reports.mailPattern.UserPasswordReset': 'ユーザーパスワードのリセット',
    'reports.mailPattern.UserRegistration': 'ユーザー登録の確認',
    'reports.mailPattern.UserUnlocking': 'ユーザーへのパスワード攻撃後の解除',
    'reports.Main': 'メイン',
    'reports.MAM trades report': 'マルチアカウントマネージャー取引レポート',
    'reports.MAM_OE': 'マルチアカウントマネージャー(build in OE)',
    'reports.MamManagementFee': 'マルチアカウントマネージャーマネージメント手数料',
    'reports.MamPerfomanceFee': 'マルチアカウントマネージャーパフォーマンス手数料',
    'reports.MamPerformanceFee': 'マルチアカウントマネージャーパフォーマンス手数料',
    'reports.Manual order cancel request': 'マニュアル注文取消リクエスト',
    'reports.Manual order placing request': 'マニュアル注文発注リクエスト',
    'reports.Manual order replace request': 'マニュアル注文変更リクエスト',
    'reports.ManufacturedDividends': '分配金',
    'reports.margin': '証拠金',
    'reports.Margin Call': 'マージンコール',
    'reports.Margin Call Reached': 'マージンコールに達しました',
    'reports.Margin Call Warning.': 'マージンコールの警告.',
    'reports.Margin used': '使用済みマージン',
    'reports.MARGIN_RULES_ACC': 'マージン規則（口座）',
    'reports.MARGIN_RUSLES': 'マージン規則',
    'reports.Margincall': 'マージンコール',
    'reports.MarginCallMessage.header.MarginCallReached': 'マージンコールに達しました',
    'reports.MarginCallMessage.header.MarginWarning': 'マージンコール',
    'reports.MarginCallMessage.Message': '口座に入金してください',
    'reports.Market accepted by broker': '成行注文ブローカー許諾',
    'reports.Market accepted by STP': '成行注文STP受付',
    'reports.Market Close Order Removed': '成行決済注文削除',
    'reports.Market data charging report': 'マーケットデータ請求レポート',
    'reports.Market order accepted': '成行注文受付',
    'reports.Market order activated': '成行注文適用',
    'reports.Market order cancel request': '成行注文キャンセルリクエスト',
    'reports.Market order canceled': '成行注文取消',
    'reports.Market order created': '成行注文作成',
    'reports.Market Order Created': '成行注文作成',
    'reports.Market order created is off market': '成行注文は市場外',
    'reports.Market order created is unplaced': '成行注文は市場に提示されなかった',
    'reports.Market Order Modified': '成行注文が変更されました',
    'reports.Market order partially reject': '成行注文の一部が取消しされました',
    'reports.Market order placing request': '成行注文がリクエストされました',
    'reports.Market order received by broker': 'ブローカーが成行注文を受付けました',
    'reports.Market order rejected': '成行注文が受付けされませんでした',
    'reports.Market Order Removed': '成行注文が取消しされました',
    'reports.Market order replace request': '成行注文の予約内容が変更されました',
    'reports.Market order replaced': '成行注文が変更されました',
    'reports.Market order restated': 'が再度注文されました',
    'reports.Market price': 'マーケットプライス',
    'reports.MARKETDEPTH': '板情報',
    'reports.MarketFactory Feed': 'MarketFactory フィード',
    'reports.Markup': 'マークアップ',
    'reports.MATRIX': 'マトリックス',
    'reports.MATRIX_OE': 'マトリックス(build in OE)',
    'reports.MaxLogsBackups': '最大ログバックアップ',
    'reports.MBT': 'MBT',
    'reports.message': 'メッセージ',
    'reports.Message': 'メッセージ',
    'reports.messageid': 'メッセージID',
    'reports.MFGlobal': 'MFGlobal',
    'reports.MillisecondsinTimeStamp': '1000分の1秒で刻む',
    'reports.MillisecondsInTimeStamp': '1000分の1秒で刻む',
    'reports.minchange': '最低手数料',
    'reports.MinFeeAdjEquity': '',
    'reports.Minimum Open Margin': '',
    'reports.minutes': '分',
    'reports.MLFIX': 'MLFIX',
    'reports.MOB_CANCEL_ALL_ORDERS': 'モバイル（すべての注文取消）',
    'reports.MOB_CHART_OE': 'モバイルチャート（注文）',
    'reports.MOB_CLOSE_ALL_POSITIONS': 'モバイル（すべてのポジションをクローズ）',
    'reports.MOB_MARKETDEPTH': 'モバイル   市場の厚み',
    'reports.MOB_ORDERS_HOT_BTN': 'モバイル　未決済注文(hot buttons)',
    'reports.MOB_OTHER': 'モバイル その他',
    'reports.MOB_POSITIONS_HOT_BTN': 'モバイル　ポジション(hot buttons)',
    'reports.MOB_TAB_CHART_VISUAL_HOT_BTN': 'モバイルチャート (built-in OE/タブレット)',
    'reports.MOB_WL_OE': 'モバイル ウォッチリスト（注文発注）',
    'reports.Modified': '変更',
    'reports.Modifing': '変更',
    'reports.Modifyied by': 'Modified by',
    'reports.Modifying': '変更',
    'reports.months': '月',
    'reports.Morgan Stanley': 'Morgan Stanley',
    'reports.MorganStanleyCFD': 'MorganStanleyCFD',
    'reports.Mutual close request': '相互決済リクエスト',
    'reports.name': '名称',
    'reports.NAMESPACE': 'NAMESPACE',
    'reports.NEED_EMAIL_CONFIRM_ON_REGISTRATION': 'Eメールによる口座登録確認',
    'reports.NeoNet': 'NeoNet',
    'reports.Net Open Positions': 'ネットオープンポジション',
    'reports.netpl': 'ネットP/L',
    'reports.New Back Office Changes Report': 'New Backoffice changes report',
    'reports.New password must differ from several previous passwords.': '新しいパスワードは以前使用されたものは使用できません',
    'reports.New Users Report': '新ユーザーレポート',
    'reports.news.feed.type': 'ニュースフィードタイプ',
    'reports.news.reciving': '受取中のニュース',
    'reports.news.save': 'ニュースの保存',
    'reports.news.sendEmail': 'ニュースをEメールで送付',
    'reports.NewsCharging': '購買中のニュース',
    'reports.NewsFeed': 'ニュースフィード',
    'reports.newvalue': '新しい値',
    'reports.no such position, no such order.': 'ポジション該当なし、注文該当なし',
    'reports.Nomura': '野村',
    'reports.Nomura-Lehman': '野村リーマン',
    'reports.None': 'なし',
    'reports.Not enoght margin to create Order': '証拠金不足により注文不可',
    'reports.Not enough margin to open order': '証拠金不足により注文不可',
    'reports.Not enough Margin to open position': '証拠金不足により注文不可',
    'reports.Not enough offer': '不十分なオファー',
    'reports.Not Filled Orders Report': '約定注文はレポート無し',
    'reports.Not Successful': '失敗',
    'reports.notShowMam': 'MAMを非表示',
    'reports.NotSuccessful': '失敗',
    'reports.number': '数字',
    'reports.objectid': 'オブジェクトID',
    'reports.ObjectId': '注文ID',
    'reports.ObjectID': 'オブジェクトID',
    'reports.OCO accepted by broker': 'OCO注文ブローカー許諾',
    'reports.OCO accepted by STP': 'OCO注文はSTPで受付',
    'reports.OCO order activated': 'OCO注文適用',
    'reports.OCO order cancel request': 'OCO注文キャンセルリクエスト',
    'reports.OCO order canceled': 'OCO注文取消',
    'reports.OCO order created': 'OCO注文作成',
    'reports.OCO Order Created': 'OCO注文作成',
    'reports.OCO order created is off market': 'OCO注文は市場外',
    'reports.OCO order created is unplaced': 'OCO注文は市場に提示されなかった',
    'reports.OCO order partially reject': 'OCO注文一部取消',
    OCO注文のリクエストを受付けました: 'OCO注文発注リクエスト',
    'reports.OCO order received by broker': 'OCO注文ブローカー受付',
    'reports.OCO order rejected': 'OCO注文拒絶',
    'reports.OCO order replace request': 'OCO注文リプレイスリクエスト',
    'reports.OCO order replaced': 'OCO注文変更',
    'reports.OCO order restated': 'OCO注文修正再表示',
    'reports.OE': 'OE',
    'reports.Off market': 'オフマーケット',
    'reports.ONE_MARGIN_FOR_USER_ACC': 'ユーザーの口座に一つのマージン',
    'reports.Open': '開く',
    'reports.Open Buy': 'オープンBuy',
    'reports.Open date': 'オープン日',
    'reports.Open lots': 'オープンロット',
    'reports.Open order is forbidden by trade session': '取引セッションでオープン注文は禁止されています',
    'reports.Open order type': 'オープン注文タイプ',
    'reports.Open Positions By Signal Report': 'シグナルレポートによるポジションのオープン',
    'reports.Open Positions Report': 'オープンポジションレポート',
    'reports.Open price': 'オープン価格',
    'reports.Open Sell': 'オープンセル',
    'reports.OPEN_SPOT_ON_OPTION_EXP': 'オプションの有効期限が切れるスポットの位置',
    'reports.openclosebuysell': '操作タイプ',
    'reports.opengrosspnl': '未評価損益',
    'reports.OpenOptionPremium': 'オープンオプションプレミアム',
    'reports.openorderid': '注文IDを開く',
    'reports.openprice': 'オープン価格',
    'reports.opentime': 'オープン時間',
    'reports.operation': '操作',
    'reports.Operation': '操作',
    'reports.Operation Id': '操作ID',
    'reports.Operation type': '操作タイプ',
    'reports.Operation Type': '操作タイプ',
    'reports.operation.not_enough_margin': '証拠金不足',
    'reports.operation.wrong_length': '量が多すぎます',
    'reports.operation.wrong_length;': '量が多すぎます',
    'reports.operation.wrong_sum': '間違った合計',
    'reports.OperationID': '操作ID',
    'reports.OperationInDisconnectedMode': 'この操作は、切断モードでは使用できません！',
    'reports.operationtype': '操作タイプ',
    'reports.OPTION_MASTER': 'オプションマスター',
    'reports.option_value': 'オプション価値',
    'reports.OrcFIX': 'OrcFIX',
    'reports.order': '注文',
    'reports.Order': '注文',
    'reports.Order Book History': 'オーダーブック履歴',
    'reports.Order Book Report': 'オーダーブックレポート',
    'reports.Order cancel is forbidden by trade session': '注文のキャンセルは、取引セッションによって禁止されています',
    'reports.Order cancel request': '注文キャンセルリクエスト',
    'reports.Order canceled successfully': '注文は正常にキャンセルされました',
    'reports.Order cannot be created since order amount is forbidden.': '注文金額が禁止されているため、注文を作成できません。',
    'reports.Order cannot be created since route is not active.': 'ルートがアクティブでないため、注文を作成できません',
    'reports.Order cannot be created, because position is in executing': 'ポジションは執行中のため、注文を作成できません',
    'reports.Order cannot be created, because route is not active': 'ルートがアクティブではないため、オーダーを作成できません',
    "reports.Order cannot be created, because short positions are forbidden. You don't have enough rights.": 'ショートポジションは禁止されているため、注文を作成できません。十分な権限がありません。',
    'reports.Order cannot be created, because short positions are forbidden. You donґt have enough rights.': 'ショートポジションは禁止されているため、注文を作成できません。十分な権限がありません。',
    'reports.Order cannot be created, because short positions are forbidden.Or yor already have Limit/Stop orders for cover all your positions.': 'ショートポジションは禁止されているため、注文を作成できません。または、すべてのポジションをカバーするための指値/ストップ注文が既にあります。',
    'reports.Order cannot be placed in non-trading day. Please, check the trading calendar.': '取引以外の日に注文することはできません。取引カレンダーを確認してください。',
    'reports.Order groupID': '注文グループID',
    'reports.Order History Report': '注文履歴レポート',
    'reports.Order modification is forbidden by trade session': '注文の変更は取引セッションで禁止されています',
    'reports.Order number': '注文ID',
    'reports.Order placing request': '発注依頼',
    'reports.Order price': '注文価格',
    'reports.Order Price': '注文価格',
    'reports.Order received by STP': 'STPが受け取った注文',
    'reports.Order replace request': '注文交換リクエスト',
    'reports.Order replacing confirmed': '注文の交換を確認しました',
    'reports.Order TIF is forbidden by trade session': '注文TIFは取引セッションで禁止されています',
    'reports.Order type': '注文種別',
    'reports.Order type is forbidden by trade session': '注文タイプは取引セッションで禁止されています',
    'reports.Order was rejected due to invalid price!': '無効な価格のため注文は拒否されました！',
    'reports.order_book_account': '取引口座',
    'reports.order_book_datetime': '日付',
    'reports.order_book_event': 'イベント',
    'reports.order_book_login': 'ログイン',
    'reports.order_book_modified_by': 'ユーザーにより変更',
    'reports.order_book_orderid': '注文ID',
    'reports.order_book_price': '価格',
    'reports.order_book_quantity': '数量',
    'reports.order_book_route': 'ルート',
    'reports.order_book_side': '売買種別',
    'reports.order_book_stop_price': 'ストップ価格',
    'reports.order_book_symbol': '銘柄',
    'reports.order_book_symbol_type': '銘柄タイプ',
    'reports.order_book_tif': '有効期限',
    'reports.order_book_type': 'タイプ',
    'reports.orderCreate': '注文作成',
    'reports.orderExecute': '注文実行',
    'reports.OrderExternalConfirm': '注文の外部確認',
    'reports.orderid': '注文ID',
    'reports.orderId': '注文ID',
    'reports.OrderId': '注文ID',
    'reports.orderModify': '注文変更',
    'reports.orderMutural': '相互注文',
    'reports.orderRemoved': '注文を削除しました',
    'reports.ORDERS_PANEL_HOT_BTN': '約定待ち注文（ホットボタン）',
    'reports.ordertype': '注文種別',
    'reports.Oreder Number': '注文ID',
    'reports.originalvalue': '元の値',
    'reports.OTHER': 'その他',
    'reports.other_account_operations': 'その他のアカウント操作',
    'reports.Out of session trading is forbidden': 'セッション外の取引は禁止されています',
    'reports.Overnight trading is forbidden for account': '本アカウントでオーバーナイト取引は禁止されています',
    'reports.PAMM account can not be removed during investment period.': '投資期間中は資金を移転できません',
    'reports.PAMM join': 'ファンド参加',
    'reports.pamm.OrderByPnL.max_loss': '最大損失',
    'reports.pamm.OrderByPnL.max_profit': '最大利益',
    'reports.pamm.OrderByPnL.min_loss': '最小損失',
    'reports.pamm.OrderByPnL.min_profit': '最小利益',
    'reports.pamm.OrderByPnL.none': '無し',
    'reports.pamm.OrderBySize.max': '最大ポジション',
    'reports.pamm.OrderBySize.min': '最小ポジション',
    'reports.pamm.OrderByTime.first': '最初のオープン',
    'reports.pamm.OrderByTime.last': '最後のオープン',
    'reports.pammState.0': '新着',
    'reports.pammState.1': '投資',
    'reports.pammState.2': '投資終了',
    'reports.pammState.3': 'クリアリング',
    'reports.pammState.4': '投資家を待つ',
    'reports.pammState.6': '投資開始',
    'reports.pammState.clearing': 'クリアリング',
    'reports.pammState.confirmed': '確認済み',
    'reports.pammState.end_of_investment': '投資終了',
    'reports.pammState.investment': '投資',
    'reports.pammState.Investment': '投資',
    'reports.pammState.new': '新着',
    'reports.pammState.pending': '保留中',
    'reports.pammState.start_of_investment': '投資開始',
    'reports.pammState.wait_for_investors': '投資家を待つ',
    'reports.PAMMTransfer': '資金移動',
    'reports.Partially filled': '部分的に約定',
    'reports.Partially filled close Limit order': '部分的に約定したクローズ指値注文',
    'reports.Partially filled Close Limit Order': '部分的に約定したクローズ指値注文',
    'reports.Partially filled close Limit order (Mutual)': '部分的に約定したクローズ指値注文（相互）',
    'reports.Partially filled close Market order': '部分的に約定したクローズ成行注文',
    'reports.Partially filled Close Market Order': '部分的に約定したクローズ成行注文',
    'reports.Partially filled close Market order (Mutual)': '部分的に約定したクローズ成行注文（相互）',
    'reports.Partially filled close OCO order': 'クローズOCO注文が部分約定しました',
    'reports.Partially filled close OCO order (Mutual)': 'クローズOCO注文が部分約定しました（相互に）',
    'reports.Partially filled close SL order': 'クローズSL注文が部分約定しました',
    'reports.Partially filled close SL order (Mutual)': 'クローズSL注文が部分約定しました（相互に）',
    'reports.Partially filled close SL tr. stop order': 'クローズストップSL Trオーダーが部分約定しました',
    'reports.Partially filled close SL tr. stop order (Mutual)': 'クローズストップSL Trオーダーが部分約定しました（相互）',
    'reports.Partially filled close Stop Limit order': 'クローズストップ指値注文が部分約定しました',
    'reports.Partially filled close Stop Limit order (Mutual)': 'クローズストップ指値注文が部分約定しました（相互）',
    'reports.Partially filled close Stop order': 'クローズストップオーダーが部分約定しました',
    'reports.Partially filled Close Stop Order': 'クローズストップオーダーが部分約定しました',
    'reports.Partially filled close Stop order (Mutual)': 'クローズストップオーダーが部分約定しました（相互）',
    'reports.Partially filled close TP order': 'クローズTP注文が部分約定しました',
    'reports.Partially filled close TP order (Mutual)': 'クローズTP注文が部分約定しました（相互に）',
    'reports.Partially filled close Tr. stop order': 'クローズTrストップオーダーが部分約定しました',
    'reports.Partially filled close Tr. stop order (Mutual)': 'クローズTrストップオーダーが部分約定しました（相互）',
    'reports.Partially filled Limit order': '指値注文が部分約定しました',
    'reports.Partially filled Limit order (Mutual)': '指値注文が部分約定しました（相互）',
    'reports.Partially filled Market order': '成行注文が部分約定しました',
    'reports.Partially filled Market Order': '成行注文が部分約定しました',
    'reports.Partially filled Market order (Mutual)': '成行注文が部分約定しました（相互に）',
    'reports.Partially filled OCO order': 'OCO注文が部分約定しました',
    'reports.Partially filled OCO order (Mutual)': 'OCO注文が部分約定しました（相互に）',
    'reports.Partially filled SL order': 'SL注文が部分約定しました',
    'reports.Partially filled SL order (Mutual)': 'SL注文が部分約定しました（相互に）',
    'reports.Partially filled SL tr. stop order': 'SL Trストップオーダーが部分約定しました',
    'reports.Partially filled SL tr. stop order (Mutual)': 'SL Trストップオーダーが部分約定しました（相互）',
    'reports.Partially filled Stop Limit order': 'ストップリミット注文の一部が成立しました',
    'reports.Partially filled Stop Limit order (Mutual)': 'ストップリミット注文の一部が成立しました (複数)',
    'reports.Partially filled Stop order': '逆指値注文の一部が成立しました',
    'reports.Partially filled Stop order (Mutual)': '逆指値注文の一部が成立しました (複数)',
    'reports.Partially filled TP order': 'T/Pが一部成立しました',
    'reports.Partially filled TP order (Mutual)': 'T/Pが一部成立しました (複数)',
    'reports.Partially filled Tr. stop order': 'トレーリングストップ注文が一部成立しました',
    'reports.Partially filled Tr. stop order (Mutual)': 'トレーリングストップ注文が一部成立しました (複数)',
    'reports.Partially filled Traling Stop Order': 'トレーリングストップオーダーが部分約定しました',
    'reports.Password required with level - 1': '無効なパスワード: パスワードは、大文字、小文字、数字をそれぞれ1種類以上含めて設定してください。もう一度入力し直してください',
    'reports.Password required with level - 2': '無効なパスワード: パスワードは、8文字以上で、大文字、小文字、数字をそれぞれ1種類以上含めて設定してください もう一度入力し直してください',
    'reports.Password required with level - 3': '無効なパスワード: パスワードは、8文字以上で、大文字、小文字、数字、記号 (@#$%) をそれぞれ1種類以上含めて設定してください もう一度入力し直してください',
    'reports.PatsystemsFIXFeed': 'PatsystemsFIXFeed',
    'reports.Pending': '保留中',
    'reports.pendingorderscount': 'ペンディングの注文数',
    'reports.PeresysFIX': 'PeresysFIX',
    'reports.Perfomance By Signal Report': 'シグナル別パフォーマンスレポート',
    'reports.Perform': '実行',
    'reports.Performance history report': 'パフォーマンス履歴レポート',
    'reports.PFixFeed': 'PFixFeed',
    'reports.phone': '電話',
    'reports.phonepassword': '電話パスワード',
    'reports.pin': 'Pin',
    'reports.pl_unrealized': '未実現損益',
    'reports.Placed': '受付済み',
    'reports.Please deposit on your account.': '取引口座に入金してください',
    'reports.pnl': 'P/L',
    'reports.port': 'ポート',
    'reports.posId': 'ポジションID',
    'reports.Position': 'ポジション',
    'reports.Position close request': 'ポジション決済のリクエストを受付けました',
    'reports.Position Closed': 'ポジションクローズ',
    'reports.Position Closed (Mutual)': 'ポジションクローズ（相互）',
    'reports.Position Closed (SL)': 'ポジションクローズ（SL）',
    'reports.Position Closed (TP)': 'ポジションクローズ（TP）',
    'reports.Position closed successfully': 'ポジションは正常にクローズされました',
    'reports.POSITION MODIFIED': 'ポジション変更済み',
    'reports.Position modify request': 'ポジション変更リクエスト',
    'reports.Position number': 'ポジションID',
    'reports.positionOpened': 'ポジションオープン',
    'reports.Position Opened (Limit Order)': 'ポジションオープン（指値注文）',
    'reports.Position Types Ratio By Instrument': 'シンボル別ポジションタイプの比率',
    'reports.Position: {1} closed successfully': 'ポジション：{1}は正常にクローズされ間shチア',
    'reports.positionamount': 'ポジション数量',
    'reports.positionid': 'ポジションID',
    'reports.positionId': 'ポジションID',
    'reports.PositionId': 'ポジションID',
    'reports.Positions History Report': 'ポジション履歴レポート',
    'reports.Positions On Chosen Moment Report': '選択したモーメントレポートのポジション',
    'reports.POSITIONS_PANEL_HOT_BTN': 'ポジションパネル（ホットボタン）',
    'reports.positionstatus': 'ポジションステータス',
    'reports.price': '価格',
    'reports.Price': '価格',
    'reports.Price is too close to current Ask price': '価格が現在のAsk価格に近すぎます',
    'reports.Price is too close to current Bid price': '価格が現在のBid価格に近すぎます',
    'reports.priceExchange': '価格交換',
    'reports.priceExternal': '外部価格',
    'reports.product type': '商品の種類',
    'reports.productType': '商品の種類',
    'reports.PROFILE_LANGUAGE': 'プロファイル言語',
    'reports.profit': '利益',
    'reports.Projected balance': '有効評価額',
    'reports.protocol': 'プロトコル',
    'reports.ProTrader': 'プロトレーダー',
    'reports.ProTraderFIX': 'ProTraderFIX',
    'reports.Purchase And Sales Report': '売買レポート',
    'reports.purchaseprice': 'エクスポージャー',
    'reports.quantity': '数量',
    'reports.QueueMaxSize': 'キューの最大サイズ',
    'reports.quicktable.noData': '表示するデータがありません。必要なレポート条件を選択して、必要な情報を取得してください。',
    'reports.Quote is too old': 'クオートが古すぎます',
    'reports.QuoteDelay(sec)': 'クオート遅延（秒）',
    'reports.quoteticker': 'クオートティッカー',
    'reports.raor_administrator': 'ブローカー',
    'reports.raor_approvetime': '時間を承認する',
    'reports.raor_balance': '口座残高から',
    'reports.raor_basis': 'コメント',
    'reports.raor_commission_percent': '手数料（％）',
    'reports.raor_counterbalance': 'アカウント残高へ',
    'reports.raor_countersum': 'アカウント合計へ',
    'reports.raor_crossprice': 'クロス価格',
    'reports.raor_fromaccount': 'アカウントから',
    'reports.raor_operationtime': '操作時間',
    'reports.raor_operationtype': '操作タイプ',
    'reports.raor_requestid': '操作ID',
    'reports.raor_state': '承認状況',
    'reports.raor_sum': 'アカウント合計から',
    'reports.raor_sum_without_commission': '手数料なしの合計',
    'reports.raor_toaccount': 'アカウントへ',
    'reports.rate': 'レート',
    'reports.Rates Report': 'レートレポート',
    'reports.Realised profit/loss': '実現利益/損失',
    'reports.Reason for rejection: Trading disabled by risk rules. .': '拒否の理由：リスクルールにより取引が無効になっています。',
    'reports.receiveBestBidOfferOnly': '最良のBidオファーのみを受ける',
    'reports.recipient': '受取人',
    'reports.ReconnectInterval': '再接続間隔',
    'reports.refreshInterval': '更新間隔',
    'reports.RefreshonLogon': 'ログオン時に更新',
    'reports.RefreshOnLogon': 'ログオン時に更新',
    'reports.Refused': '拒否されました',
    'reports.Refused Amount': 'キャンセルされた数量',
    'reports.Rejected': '拒否されました',
    'reports.remoteid': 'リモートID',
    'reports.Remove SL for': 'のSLを削除',
    'reports.Removed Orders Report': '削除された注文レポート',
    'reports.removedtime': '削除された時間',
    'reports.removeliquidityrebate': '流動性リベートを削除',
    'reports.Replace request received by STP': '再プレースメント要求はSTPに受領されました',
    'reports.REPORT_RULES': 'レポートの可視化',
    'reports.report.currency': '通貨',
    'reports.report.route': 'ルート',
    'reports.reportAccountSummaryReport': 'アカウント明細書レポート',
    'reports.reportAllActiveOrdersReport': '有効な注文レポート',
    'reports.reportAllNotActiveOrdersReport': '約定済み注文レポート',
    'reports.reportAllRemovedOrdersReport': '削除された注文レポート',
    'reports.reportBalanceByInstrument': 'シンボル別残高レポート',
    'reports.reportBalanceReport': '残高レポート',
    'reports.reportFormalDialogReport': '公式ダイアログレポート',
    'reports.reportInformalDialogReport': 'チャット履歴レポート',
    'reports.reportNewUsersReport': '新規ユーザーレポート',
    'reports.reportOpenedPositionReport': 'オープンポジションレポート',
    'reports.reportPerfomanceReport': 'パフォーマンスレポート',
    'reports.reportPurchaseAndSalesReport': '売買レポート',
    'reports.reportSummaryNewReport': '概略報告',
    'reports.reportSummaryReport': '残高サマリーレポート',
    'reports.reportTradeOperationReport': 'トレードオペレーションレポート',
    'reports.reportTradesReport': 'トレードレポート',
    'reports.reportUsersReport': 'ユーザーレポート',
    'reports.Request Account Operation Report': 'アカウント操作レポートのリクエスト',
    'reports.Request is not allowed for this account': 'このアカウントでリクエストは許可されていません',
    'reports.Request not supported': 'リクエストはサポートされていません',
    'reports.RequestAccountOperationReport': 'アカウント操作レポートのリクエスト',
    'reports.Required Balance': '必要な残高',
    'reports.REQUIRED_STRONG_PASSWORDS': '強固なパスワードが必要です',
    'reports.ResetOnDisconnect': '切断時にリセット',
    'reports.ResetOnLogon': 'ログオン時にリセット',
    'reports.ResetOnLogout': 'ログアウト時にリセット',
    'reports.Restated': '再記',
    'reports.Reuters': 'ロイター',
    'reports.Revenue plan': '収益計画',
    'reports.REVENUE_PLAN_GENERAL': '全般',
    'reports.revenue.name': '収益計画名',
    'reports.revenue.speccrossinstrument': '仕様十字記号',
    'reports.revenue.speccurrency': '仕様通貨',
    'reports.Reverse': '逆',
    'reports.reward_type.capital_percent': '資本、％',
    'reports.reward_type.fixed': '固定',
    'reports.reward_type.fixed_and_percent': '固定+期間逆成長率、％',
    'reports.reward_type.no': 'いいえ',
    'reports.reward_type.percent': '期間逆成長率、％',
    'reports.role': '役割',
    'reports.Rollback': 'ロールバック',
    'reports.Rollback position': 'ポジションロールバック',
    'reports.Rollback position request': 'ポジションロールバックリクエスト',
    'reports.route': 'ルート',
    'reports.Route': 'ルート',
    'reports.Route Trading Statistics Report': 'ルート取引統計レポート',
    'reports.Route Visibility': 'ルート可視化レポート',
    'reports.Route Visibility Report': 'ルート可視化レポート',
    'reports.RouteInstrMap': 'シンボルマップ',
    'reports.routename': 'ルート',
    'reports.routes': 'ルート',
    'reports.routes.account': 'リンクされたフィードアカウント',
    'reports.routes.allow_day': '当日',
    'reports.routes.allow_foc': 'FOK',
    'reports.routes.allow_gtc': 'GTC',
    'reports.routes.allow_ioc': 'IOC',
    'reports.routes.allow_market': '成行',
    'reports.routes.allow_moc': 'MOCを許可',
    'reports.routes.allow_moo': 'MOOを許可',
    'reports.routes.allow_stop': 'ストップ',
    'reports.routes.allow_stoplimit': '指値',
    'reports.routes.allow_stoptolimit': 'ストップリミット',
    'reports.routes.route_comment': 'コメント',
    'reports.routes.route_external_feed': '流動性プロバイダー',
    'reports.routes.route_isactive': 'アクティブです',
    'reports.routes.route_isinfo': '情報です',
    'reports.routes.route_istrade': '貿易です',
    'reports.routes.route_login': 'ログイン',
    'reports.routes.route_name': '名前',
    'reports.routes.route_password': 'パスワード',
    'reports.routes.route_saveQuotes': '別の履歴を保存する',
    'reports.routes.route_status': 'ステータス',
    'reports.ROUTESVIS_RULES': 'ルートの可視化',
    'reports.RssNews': 'RSSNews',
    'reports.RTS': 'RTS',
    'reports.rules.error.restriction.max': '数は少なくなければなりません',
    'reports.rules.error.restriction.min': '数は大きくなければなりません',
    'reports.SAVED_ORDERS': '保存した注文',
    'reports.saveLogs': 'ログを保存',
    'reports.saveNews': 'ニュースを保存',
    'reports.Saxo Bank': 'Saxo Bank',
    'reports.SCALPER': 'スキャルパー',
    'reports.Scheduler Log Report': 'スケジューラーログレポート',
    'reports.scheduler.accountoperation_archive': 'アカウント操作のアーカイブ',
    'reports.scheduler.dailyreport': 'レポートを送信',
    'reports.scheduler.dividentsservice': 'ポジションの配当を実行する',
    'reports.scheduler.lastexcecuted': 'で実行',
    'reports.scheduler.modifyRuleTask': 'タスクを変更する',
    'reports.scheduler.movepositiontask': 'ポジションタスク移動',
    'reports.scheduler.openclosetradingfor': 'オープン/クローズ取引',
    'reports.scheduler.opentradingfor': 'オープン取引',
    'reports.scheduler.parameter.backup': 'バックアップレベル',
    'reports.scheduler.parameter.calculateByClosePrice': '現在の終値を使用',
    'reports.scheduler.parameter.calculateStrategy': '計算タイプ',
    'reports.scheduler.parameter.coeff1': 'パート1',
    'reports.scheduler.parameter.coeff2': 'パート2',
    'reports.scheduler.parameter.comment': 'アカウント操作コメント',
    'reports.scheduler.parameter.counteraccount': 'カウンターアカウント',
    'reports.scheduler.parameter.days_before_exp': '有効期限までの日数',
    'reports.scheduler.parameter.divident': '配当額',
    'reports.scheduler.parameter.dividentmode': '配当の種類',
    'reports.scheduler.parameter.email': 'Eメール（コンマ区切り）',
    'reports.scheduler.parameter.inheritUserGroups': 'サブグループを含める',
    'reports.scheduler.parameter.instrtype': '銘柄グループ',
    'reports.scheduler.parameter.instrument': '銘柄',
    'reports.scheduler.parameter.isopen': 'オープン/クローズ取引',
    'reports.scheduler.parameter.isupdateall': 'グループ内のすべての銘柄を更新',
    'reports.scheduler.parameter.margincoeff': 'マージン係数（時間ベース）',
    'reports.scheduler.parameter.max': '最大（％）',
    'reports.scheduler.parameter.message': 'メッセージ',
    'reports.scheduler.parameter.min': '最小（％）',
    'reports.scheduler.parameter.operations': '操作タイプ',
    'reports.scheduler.parameter.owner': 'オブジェクト',
    'reports.scheduler.parameter.ownerType': 'オブジェクトタイプ',
    'reports.scheduler.parameter.putinaleerts': 'アラートに入れる',
    'reports.scheduler.parameter.reconciliation.ClientId': '調整用クライアントID',
    'reports.scheduler.parameter.reconciliation.Direction': '方向',
    'reports.scheduler.parameter.reconciliation.FromDate': '日付から調整を開始',
    'reports.scheduler.parameter.reconciliation.Route': 'ルート',
    'reports.scheduler.parameter.reconciliation.SendItemName': '送信ファイル名',
    'reports.scheduler.parameter.reconciliation.Storage': '保管タイプ',
    'reports.scheduler.parameter.reconciliation.Transport': '輸送タイプ',
    'reports.scheduler.parameter.reconciliation.transportDestination': '宛先',
    'reports.scheduler.parameter.reconciliation.transportLogin': '認証ログイン',
    'reports.scheduler.parameter.reconciliation.transportPassword': '認証パスワード',
    'reports.scheduler.parameter.reconciliation.Type': '調整タイプ',
    'reports.scheduler.parameter.reports': 'レポートを選択',
    'reports.scheduler.parameter.revenueplan': '収益計画を選択',
    'reports.scheduler.parameter.routes': '各アクティブルートは',
    'reports.scheduler.parameter.routesName': 'ルートを含める',
    'reports.scheduler.parameter.rule': 'ルール',
    'reports.scheduler.parameter.ruleType': 'ルールの種類',
    'reports.scheduler.parameter.ruleView': '値',
    'reports.scheduler.parameter.sum': 'システム通貨での数量',
    'reports.scheduler.parameter.swapCoefKey': 'スワップ係数',
    'reports.scheduler.parameter.userGroup': 'ユーザーグループ',
    'reports.scheduler.parameter.usergroups': 'ユーザーグループを選択',
    'reports.scheduler.removedo': '実行日順序削除サービス',
    'reports.scheduler.rolloverservice': '先物ロールオーバーを実行する',
    'reports.scheduler.runinterestservice': 'バランスサービスに金利をかける',
    'reports.scheduler.runsignalsubscribtion': 'シグナルの日次手数料を実行する',
    'reports.scheduler.runswaptask': 'スワップを実行',
    'reports.scheduler.splitinstrument': 'シンボル分割',
    'reports.scheduler.type.accountoperation': 'アカウント操作',
    'reports.scheduler.type.backup': 'バックアップを作成する',
    'reports.scheduler.type.beginbalance': 'バランスを開始',
    'reports.scheduler.type.broadcastmessage': 'オンラインブロードキャストメッセージ',
    'reports.scheduler.type.changemargincoeff': 'マージン係数（時間ベース）',
    'reports.scheduler.type.changerevenue': '収益計画を変更する',
    'reports.scheduler.type.closeoptions': '期限切れのシンボルを非表示',
    'reports.scheduler.type.deletedemousers': 'exprデモユーザーを削除します',
    'reports.scheduler.type.deletequotes': '古いクオートを削除する',
    'reports.scheduler.type.fixswapplan': '固定スワップ計画を計算する',
    'reports.scheduler.type.futuresrollover': '先物ロールオーバーを実行する',
    'reports.scheduler.type.insertlastquotestask': '最後のクオートを挿入',
    'reports.scheduler.type.lockbybroker': 'ブローカーによるロック',
    'reports.scheduler.type.message': 'メッセージ',
    'reports.scheduler.type.modifyobject': 'オブジェクトを変更',
    'reports.scheduler.type.pamm': '資金タスク',
    'reports.scheduler.type.pendingpayment': '支払い待ち',
    'reports.scheduler.type.reconciliation': '調整タスク',
    'reports.scheduler.type.reconnect_routes': 'アクティブなルートの開始/停止',
    'reports.scheduler.type.subscribe': '購読料を受け取る',
    'reports.scheduler.type.unknown': '不明なタイプ',
    'reports.scheduler.type.updatespikefilter': 'スパイクフィルターを更新する',
    'reports.scheduler.type.updateuserstradinginfo': 'ユーザーの取引統計情報を更新する',
    'reports.ScreenLogEvents': 'スクリーンログイベント',
    'reports.ScreenLogShowHeartBeats': '画面ログハートビート表示',
    'reports.ScreenLogShowIncoming': '画面ログ着信表示',
    'reports.ScreenLogShowOutgoing': '画面ログ発信表示',
    'reports.Script': 'スクリプト',
    'reports.SDK': 'SDK',
    'reports.seconds': '秒',
    'reports.SecuritiesTransferTax': '証券譲渡税',
    'reports.Self trading is not allowed.': '自己取引は許可されていません。',
    'reports.Sell': '売り',
    'reports.sell trade amount': '取引量を売却',
    'reports.sell trades': '取引を売却',
    'reports.Send from': 'から送信',
    'reports.Send_Rej': '拒否する',
    'reports.sender': '送信者',
    'reports.SenderCompID': '送信者のコンピューターID',
    'reports.senderip': '送信者IP',
    'reports.SendResetSeqNumFlag': 'リセット連番フラグを送信',
    'reports.sendTag200': 'Tag200を送信',
    'reports.server': 'サーバ',
    'reports.server_url': 'サーバーURL',
    'reports.SERVER.AUTO.RECONNECT': 'サーバーの自動再接続',
    'reports.SERVER.POLLING.INTERVAL': 'サーバーのポーリング間隔',
    'reports.serviceName': 'サービス名',
    'reports.sessionid': 'セッションID',
    'reports.sessiontype': 'セッションの種類',
    'reports.SETTINGS_FILTER': 'フィルター',
    'reports.SETTINGS_GENERAL': '全般',
    'reports.SETTINGS_LOCKING': 'ロッキング',
    'reports.SETTINGS_QUOTE_CACHE_SIZE': 'クオートキャッシュサイズの設定',
    'reports.SETTINGS_QUOTE_SAVE': 'クオートの​​保存設定',
    'reports.SETTINGS_QUOTES': 'クオート',
    'reports.SETTINGS_REGISTRATION': '登録',
    'reports.SETTINGS_TRADING': 'トレーディング',
    'reports.SettleDate': '有効期限',
    'reports.SettleDateForward': '実行日',
    'reports.SettlementFee': '決済手数料',
    'reports.Sharia Operations Report': 'シャリア操作レポート',
    'reports.signedrequest': '署名済みリクエスト',
    'reports.sizeForQuoteSubscription': 'クオートサブスクリプションのサイズ',
    'reports.SL': 'S/L',
    'reports.SL accepted by broker': 'ブローカーがS/Lを受付けました',
    'reports.SL accepted by STP': 'STPにてS/Lを受付けました',
    'reports.SL order accepted': 'S/Lを受付けました',
    'reports.SL order cancel request': 'S/Lの取消がリクエストされました',
    'reports.SL order canceled': 'S/Lが取消しされました',
    'reports.SL order created': 'S/Lを受付けました',
    'reports.SL Order Created': 'S/Lを受付けました',
    'reports.SL order created is off market': 'S/Lはマーケットで約定の成立待ちです',
    'reports.SL order created is unplaced': '取引所がS/Lを受付けませんでした',
    'reports.SL Order Modified': 'S/Lが変更されました',
    'reports.SL order partially reject': 'S/Lの一部が取消しされました',
    'reports.SL order placing request': 'S/Lのリクエストを受付けました',
    'reports.SL order received by broker': 'ブローカーがS/Lを受付けました',
    'reports.SL order rejected': 'S/Lが受付けされませんでした',
    'reports.SL Order Removed': 'S/Lが削除されました',
    'reports.SL order replace request': 'S/Lの注文内容が変更されました',
    'reports.SL order replaced': 'S/Lが変更されました',
    'reports.SL price': 'S/L',
    'reports.SL tr. stop': '',
    'reports.SL tr. stop accepted by broker': 'SL Trストップオーダーはブローカーに受領されました',
    'reports.SL tr. stop accepted by STP': 'SL Trストップオーダーはブローカーに受領されました',
    'reports.SL tr. stop order accepted': 'SL Trストップオーダーが受け付けられました',
    'reports.SL tr. stop order canceled': 'SL Trストップオーダーがキャンセルされました',
    'reports.SL tr. stop order created': 'SL Trストップオーダーが作成されました',
    'reports.SL tr. stop order created is off market': 'SL Trストップオーダーはオフマーケットです',
    'reports.SL tr. stop order created is unplaced': 'SL Trストップオーダーが取り下げられました',
    'reports.SL tr. stop order partially reject': 'SL Trストップオーダーは部分的にキャンセルされました',
    'reports.SL tr. stop order received by broker': 'SL Trストップオーダーはブローカーに受領されました',
    'reports.SL tr. stop order rejected': 'SL Trストップオーダーが拒否されました',
    'reports.SL tr. stop order replaced': 'SL Trストップオーダーが変更されました',
    'reports.SmartRoute(aggregator)': '分割エミュレーター',
    'reports.Sms generation error': 'SMS生成エラー',
    'reports.SMTP server required a secure connection, or identity of the client has not been established.': 'SMTPサーバーに安全な接続が必要であるか、クライアントのIDが確立されていません。',
    'reports.SOAP API methods are not available for execution for this user': 'このユーザーはSOAP APIメソッドを実行できません',
    'reports.SocketConnectHost': 'ソケットconnectHost',
    'reports.SocketConnectPort': 'ソケット接続ポート',
    'reports.SocketKeyStore': 'ソケットキーストア',
    'reports.SocketKeyStorePassword': 'ソケットキーストアのパスワード',
    'reports.SocketUseSSL': 'ソケット使用SSL',
    'reports.SplitOrders': '分割注文',
    'reports.spread': 'スプレッド',
    'reports.Spread Amount Report': 'スプレッド量レポート',
    'reports.spreadpnl': 'スプレッド P/L',
    'reports.Start receiving data from server...': 'サーバーからデータの受信を開始...',
    'reports.StartDay': '開始日',
    'reports.StartTime': '始まる時間',
    'reports.STATIONS_RULES': 'ステーション',
    'reports.Statistic Report': '統計レポート',
    'reports.STATUS_BLOCKED': 'ブロックされました',
    'reports.stock_req': '必要証拠金(株式)',
    'reports.stock_value': '株式価値',
    'reports.StockOperation': '株式操作',
    'reports.stocks_liquidity': '株式流動性',
    'reports.Stop accepted by broker': 'ブローカーが逆指値注文を受付けました',
    'reports.Stop accepted by STP': 'STPにて逆指値注文を受付けました',
    'reports.Stop Limit accepted by broker': 'ブローカーがストップリミット注文を受付けました',
    'reports.Stop Limit accepted by STP': 'STPにてストップリミット注文を受付けました',
    'reports.Stop Limit order accepted': 'ストップリミット注文を受付けました',
    'reports.Stop Limit order activated': 'ストップリミット注文が有効になりました',
    'reports.Stop limit order cancel request': 'ストップリミット注文の取消がリクエストされました',
    'reports.Stop limit order canceled': 'ストップリミット注文が取消しされました',
    'reports.Stop Limit order canceled': 'ストップリミット注文が取消しされました',
    'reports.Stop Limit order created': 'ストップリミット注文を受付けました',
    'reports.Stop Limit Order Created': 'ストップリミット注文を受付けました',
    'reports.Stop Limit order created is off market': 'ストップリミット注文は取引時間外です',
    'reports.Stop Limit order created is unplaced': '取引所がストップリミット注文を受付けませんでした',
    'reports.Stop limit order is awaiting market': 'ストップリミット注文はマーケットで約定の成立待ちです',
    'reports.Stop Limit Order Modified': 'ストップリミット注文が変更されました',
    'reports.Stop Limit order partially reject': 'ストップリミット注文の一部が取消しされました',
    'reports.Stop limit order placing request': 'ストップリミット注文のリクエストを受付けました',
    'reports.Stop Limit order received by broker': 'ブローカーがストップリミット注文を受付けました',
    'reports.Stop Limit order rejected': 'ストップリミット注文が受付けされませんでした',
    'reports.Stop Limit Order Removed': 'ストップリミット注文が削除されました',
    'reports.Stop limit order replace request': 'ストップリミット注文の予約内容が変更されました',
    'reports.Stop limit order replaced': 'ストップリミット注文が変更されました',
    'reports.Stop Limit order replaced': 'ストップリミット注文が変更されました',
    'reports.Stop Limit order restated': 'ストップリミット注文が再度注文されました',
    'reports.Stop loss order canceled': '逆指値注文が取消しされました',
    'reports.Stop Loss price is too big': '無効なS/L設定価格です',
    'reports.Stop order accepted': '逆指値注文を受付けました',
    'reports.Stop order activated': '逆指値注文が有効になりました',
    'reports.Stop order cancel request': '逆指値注文の取消がリクエストされました',
    'reports.Stop order canceled': '逆指値注文が取消しされました',
    'reports.Stop order created': '逆指値注文を受付けました',
    'reports.Stop Order Created': '逆指値注文を受付けました',
    'reports.Stop order created is off market': '逆指値注文は取引時間外です',
    'reports.Stop order created is unplaced': '取引所が逆指値注文を受付けませんでした',
    'reports.Stop order is awaiting market': '逆指値注文はマーケットで約定の成立待ちです',
    'reports.Stop Order Modified': '逆指値注文が変更されました',
    'reports.Stop order partially reject': '逆指値注文の一部が取消しされました',
    'reports.Stop order placing request': '逆指値注文のリクエストを受付けました',
    'reports.Stop order received by broker': 'ブローカーが逆指値注文を受付けました',
    'reports.Stop order rejected': '逆指値注文が受付けされませんでした',
    'reports.Stop Order Removed': '逆指値注文が削除されました',
    'reports.Stop order replace request': '逆指値注文の予約内容が変更されました',
    'reports.Stop order replaced': '逆指値注文が変更されました',
    'reports.Stop order restated': '逆指値注文が再度注文されました',
    'reports.Stop price': 'ストップ価格',
    'reports.stoploss': 'S/L',
    'reports.StopOutType': 'ストップアウトタイプ',
    'reports.stopprice': 'ストップ価格',
    'reports.STP_Route_Change': '元のルート名順に設定する',
    'reports.Strike Price': '権利行使価格',
    'reports.submitted': '提出済み',
    'reports.Subscribe': '契約する',
    'reports.SUBSCRIBE_RULES': '契約プラン',
    'reports.success': '成功',
    'reports.Successful': '成功しました',
    'reports.sum': '合計',
    'reports.Sum': '合計',
    'reports.Summary Report': '概略報告',
    'reports.SunGard': 'SunGard',
    'reports.SUSPEND_MARGINCALL_ON_CLOSE_MARKET': 'クローズドマーケットのマージンコールアシスタントを一時停止',
    'reports.swap': 'スワップ',
    'reports.swapbuy': '買スワップ',
    'reports.swapplan': 'スワッププラン',
    'reports.swaps': 'スワップ',
    'reports.swaps_in': 'スワップイン',
    'reports.swaps_out': 'スワップアウト',
    'reports.swapsell': '売スワップ',
    'reports.symbol': '銘柄',
    'reports.Symbol': '銘柄',
    'reports.symbol_type': '銘柄タイプ',
    'reports.System': 'システム',
    'reports.system_currency': 'システム通貨',
    'reports.systemMonitor.instruments': '銘柄',
    'reports.systemMonitor.onlineConnections': 'オンライン接続',
    'reports.systemMonitor.positions': 'オープンポジション',
    'reports.systemMonitor.regusers': '登録ユーザー',
    'reports.systemMonitor.runningRoutes': 'アクティブルートからルートを開始する',
    'reports.systemMonitor.totalQueueSize': 'サービスキュー',
    'reports.TABLE_ALERT': 'テーブルアラート',
    'reports.tablename': 'テーブル名',
    'reports.TableName': 'テーブル名',
    'reports.Take profit order canceled': 'TP注文がキャンセルされました。',
    'reports.Take Profit price is too big': 'TP価格が',
    'reports.takeprofit': 'T/P',
    'reports.TargetCompID': '対象PCのID',
    'reports.task.comment': 'コメント',
    'reports.task.day': '日',
    'reports.task.hour': '時',
    'reports.task.minute': '分',
    'reports.task.month': '月',
    'reports.task.timezone': 'タイムゾーン調整',
    'reports.task.usedst': 'DSTを設定',
    'reports.task.year': '年',
    'reports.TempFilePath': '一時ファイルパス',
    'reports.Tenfore': '',
    'reports.terminal': 'ターミナル',
    'reports.Terminal': 'ターミナル',
    'reports.TERMINAL_API': 'API',
    'reports.TERMINAL_COMMUNITY': 'コミュニティ',
    'reports.TERMINAL_FIX': '接続を直す',
    'reports.TERMINAL_HTML': 'HTML',
    'reports.TERMINAL_JAVA': 'Java',
    'reports.TERMINAL_MOBILE': 'モバイル',
    'reports.TERMINAL_NET': '.Net',
    'reports.TERMINAL_POCKETPC': 'ポケットPC',
    'reports.TERMINAL_WEB': 'WebStation',
    'reports.terminaltype': '端末の種類',
    'reports.TFDS Quote Receiver': 'TFDSクオート受信者',
    'reports.tif': '有効期限',
    'reports.TIF': '有効期限',
    'reports.time': '時間',
    'reports.Time': '日付/時間',
    'reports.to': 'へ',
    'reports.toDate': 'へ',
    'reports.toDateConnAttempt': 'へ',
    'reports.toDatePwdChange': '（パス）へ',
    'reports.tooLargeData': 'レポートのデータが大きすぎます {2}のうち{1}を表示',
    'reports.total': '合計',
    'reports.total traded amount': '合計取引数量',
    'reports.totalfee': '合計手数料',
    'reports.TP': 'T/P',
    'reports.TP accepted by broker': 'ブローカーがT/Pを受付けました',
    'reports.TP accepted by STP': 'STPにてT/Pを受付けました',
    'reports.TP order accepted': 'T/Pを受付けました',
    'reports.TP order cancel request': 'T/Pの取消がリクエストされました',
    'reports.TP order canceled': 'T/Pが取消しされました',
    'reports.TP order created': 'T/Pを受付けました',
    'reports.TP Order Created': 'T/Pを受付けました',
    'reports.TP order created is off market': 'T/Pはマーケットで約定の成立待ちです',
    'reports.TP order created is unplaced': '取引所がT/Pを受付けませんでした',
    'reports.TP Order Modified': 'T/Pが変更されました',
    'reports.TP order partially reject': 'T/Pの一部が取消しされました',
    'reports.TP order placing request': 'T/Pのリクエストを受付けました',
    'reports.TP order received by broker': 'ブローカーがT/Pを受付けました',
    'reports.TP order rejected': 'T/Pが受付けされませんでした',
    'reports.TP Order Removed': 'T/Pが削除されました',
    'reports.TP order replace request': 'T/Pの予約内容が変更されました',
    'reports.TP order replaced': 'T/Pが変更されました',
    'reports.Tr stop loss order cancel request': 'トレーリングストップのS/Lの取消がリクエストされました',
    'reports.Tr stop loss order placing request': 'トレーリングストップのS/Lのリクエストを受付けました',
    'reports.Tr stop loss order replace request': 'トレーリングストップのS/Lの予約内容が変更されました',
    'reports.tr. stop': 'トレーリングストップ',
    'reports.Tr. stop accepted by broker': 'ブローカーがトレーリングストップのS/Lを受付けました',
    'reports.Tr. stop accepted by STP': 'STPにてトレーリングストップのS/Lを受付けました',
    'reports.Tr. stop is too big': 'トレーリングストップ注文の設定が無効です',
    'reports.Tr. stop loss order accepted': 'トレーリングストップのS/Lを受付けました',
    'reports.Tr. stop loss order canceled': 'トレーリングストップのS/Lが取消しされました',
    'reports.Tr. stop loss order created': 'トレーリングストップのS/Lを受付けました',
    'reports.Tr. stop loss order created is off market': 'トレーリングストップのS/Lは取引時間外です',
    'reports.Tr. stop loss order created is unplaced': '取引所がトレーリングストップのS/Lを受付けませんでした',
    'reports.Tr. stop loss order replaced': 'トレーリングストップのS/Lが変更されました',
    'reports.Tr. stop order accepted': 'トレーリングストップ注文を受付けました',
    'reports.Tr. stop order activated': 'トレーリングストップ注文が有効になりました',
    'reports.Tr. stop order canceled': 'トレーリングストップ注文が取消しされました',
    'reports.Tr. stop order created': 'トレーリングストップ注文を受付けました',
    'reports.Tr. stop order created is off market': 'トレーリングストップ注文は取引時間外です',
    'reports.Tr. stop order created is unplaced': '取引所がトレーリングストップ注文を受付けませんでした',
    'reports.Tr. stop order is awaiting market': 'トレーリングストップ注文はマーケットで約定の成立待ちです',
    'reports.Tr. stop order partially reject': 'トレーリングストップ注文の一部が取消しされました',
    'reports.Tr. stop order received by broker': 'ブローカーがトレーリングストップ注文を受付けました',
    'reports.Tr. stop order placing request': 'トレーリングストップ注文のリクエストを受付けました',
    'reports.Tr. stop order cancel request': 'トレーリングストップ注文の取消がリクエストされました',
    'reports.Tr. stop order replace request': 'トレーリングストップ注文の予約内容が変更されました',
    'reports.Tr. stop order rejected': 'トレーリングストップ注文が受付けされませんでした',
    'reports.Tr. stop order replaced': 'トレーリングストップ注文が変更されました',
    'reports.Tr. stop order restated': 'トレーリングストップ注文が再度注文されました',
    'reports.Tr.SL Order Removed': 'トレーリングストップ注文が削除されました',
    'reports.Trade executed': 'トレード実行',
    'reports.Trade executed (Mutual)': 'トレード実行（相互）',
    'reports.Trade executed (SL)': 'トレード実行（SL）',
    'reports.Trade Operations Report': 'トレード操作レポート',
    'reports.Trade Periods By Signal Report': 'シグナルレポート別トレード期間',
    'reports.trade.BeginString': 'トレード開始ストリング',
    'reports.trade.CheckCompID': 'トレード確認コンピューターID',
    'reports.trade.CheckLatency': 'トレード確認レーテンシー',
    'reports.trade.ClosedResendInterval': 'トレードクローズ再送間隔',
    'reports.trade.ConnectionType': 'トレード接続タイプ',
    'reports.trade.ContinueInitializationOnError': 'エラー時トレード初期化継続',
    'reports.trade.DataDictionary': 'トレードデータ辞書',
    'reports.trade.EndDay': 'トレード終日',
    'reports.trade.EndTime': 'トレード最終時刻',
    'reports.trade.entityId': 'トレードエンティティID',
    'reports.trade.FileIncludeMilliseconds': 'ミリ秒込みトレーディングファイル',
    'reports.trade.FileIncludeTimeStampForMessages': 'メッセージにタイムスタンプ込みのトレードファイル',
    'reports.trade.FileLogHeartbeats': 'トレードファイルログハートビート',
    'reports.trade.fxintegrate.cert.host.verify': 'トレードfxに証明ホスト認証を含める',
    'reports.trade.fxintegrate.cert.root.ca.file': 'トレードfxに証明ルートcaファイルを含める',
    'reports.trade.fxintegrate.host': 'トレードfxにホストを含める',
    'reports.trade.fxintegrate.logging.file': 'トレードfxにログファイルを含める',
    'reports.trade.fxintegrate.logging.maxfilecount': 'トレードfxに最大ログファイル数を含める',
    'reports.trade.fxintegrate.logging.output': 'トレードfxにログアウトプットを含める',
    'reports.trade.fxintegrate.logging.priority': 'トレードfxにログ優先度を含める',
    'reports.trade.fxintegrate.port': 'トレードfxにポートを含める',
    'reports.trade.fxintegrate.protocol': 'トレードfxにプロトコルを含める',
    'reports.trade.fxintegrate.usessl': 'トレードfxにSSL使用を含める',
    'reports.trade.HeartBtInt': 'トレードハートビートInt',
    'reports.trade.JdbcDriver': 'トレードJdbcドライバー',
    'reports.trade.JdbcPassword': 'トレードJdbcパスワード',
    'reports.trade.JdbcURL': 'トレードJdbcURL',
    'reports.trade.JdbcUser': 'トレードJdbcユーザー',
    'reports.trade.keystoreAlias': 'トレードキーストアエイリアス',
    'reports.trade.keystoreFile': 'トレードキーストアファイル',
    'reports.trade.LogFileMaxSize': 'トレードログファイルの最大サイズ',
    'reports.trade.login': 'トレードログイン',
    'reports.trade.MaxLogsBackups': 'トレーディング最大ログバックアップ',
    'reports.trade.MillisecondsinTimeStamp': 'タイムスタンプをミリ秒単位で',
    'reports.trade.MillisecondsInTimeStamp': 'タイムスタンプをトレードミリ単位で',
    'reports.trade.password': 'トレードパスワード',
    'reports.trade.ReconnectInterval': 'トレード再接続間隔',
    'reports.trade.RefreshonLogon': 'ログオン時再更新',
    'reports.trade.RefreshOnLogon': 'ログオン時にトレード更新',
    'reports.trade.ResetOnDisconnect': '切断時にトレードリセット',
    'reports.trade.ResetOnLogon': 'ログオン時にトレードリセット',
    'reports.trade.ResetOnLogout': 'ログアウト時にトレードリセット',
    'reports.trade.saveLogs': 'トレードログ保存',
    'reports.trade.ScreenLogEvents': 'トレードスクリーンログイベント',
    'reports.trade.ScreenLogShowHeartBeats': 'トレードスクリーンログにハートビートを表示',
    'reports.trade.ScreenLogShowIncoming': 'トレードスクリーンログに受信を表示',
    'reports.trade.ScreenLogShowOutgoing': 'トレードスクリーンログに送信を表示',
    'reports.trade.SenderCompID': 'トレード送信者コンピューターID',
    'reports.trade.SendResetSeqNumFlag': 'トレードリセット連番フラグ送信',
    'reports.trade.SocketConnectHost': 'トレードホストソケット接続',
    'reports.trade.SocketConnectPort': 'トレードポートソケット接続',
    'reports.trade.SocketKeyStore': 'トレードキーストアソケット',
    'reports.trade.SocketKeyStorePassword': 'トレードキーストアパスワードソケット',
    'reports.trade.SocketUseSSL': 'トレードSSL使用ソケット',
    'reports.trade.StartDay': 'トレーディング開始日',
    'reports.trade.StartTime': 'トレーディング開始時刻',
    'reports.trade.TargetCompID': 'トレード対象コンピューターID',
    'reports.trade.UseDataDictionary': 'トレードデータ辞書使用',
    'reports.trade.ValidateFieldsHaveValues': 'トレード値有りフィールド検証',
    'reports.trade.ValidateFieldsOutOfOrder': 'トレード順序外フィールド検証',
    'reports.trade.ValidateUserDefinedFields': 'トレードユーザー定義フィールド検証',
    'reports.tradeamount': 'トレード量',
    'reports.tradeid': '取引ID',
    'reports.tradePassword': '取引パスワード',
    'reports.trader': 'トレーダー',
    'reports.Trades by Signal': 'シグナルでトレード',
    'reports.Trades Report': 'トレードレポート',
    'reports.Trades report by instrument': '銘柄別トレードレポート',
    'reports.trades_bought': '購入トレード',
    'reports.trades_sold': '売却トレード',
    'reports.tradevolume': '取引量',
    'reports.trading': '取引',
    'reports.Trading': '取引',
    'reports.Trading disabled by risk rules': 'リスクルールによってトレーディング無効化',
    'reports.TRADING_IDEA_CARD': 'トレーディングアイデアカード',
    'reports.trading_in': 'トレードイン',
    'reports.trading_out': 'トレードアウト',
    'reports.TRADING_RULES_ACC': 'トレーディングルール（アカウント）',
    'reports.TRADING_RUSLES': 'トレーディングルール',
    'reports.TRADINGSYSTEMLIST': 'ストラテジーマネージャー',
    'reports.Traiding out of Main session is forbidden for this account': 'メインセッション外でのトレードアウトは本アカウントで禁止されています',
    'reports.Trailing stop order cancel request': 'トレーリングストップ注文の取消がリクエストされました',
    'reports.Trailing Stop Order Created': 'トレーリングストップ注文を受付けました',
    'reports.Trailing Stop Order Modified': 'トレーリングストップ注文が変更されました',
    'reports.Trailing stop order placing request': 'トレーリングストップ注文のリクエストを受付けました',
    'reports.Trailing Stop Order Removed': 'トレーリングストップ注文が削除されました',
    'reports.Trailing stop order replace request': 'トレーリングストップ注文の予約内容が変更されました',
    'reports.TrailingStop': 'トレーリングストップ',
    'reports.Transfer': '資金移動',
    'reports.TransferFee': '資金移動手数料',
    'reports.True': 'True',
    'reports.type': 'タイプ',
    'reports.Type': 'タイプ',
    'reports.Types': 'タイプ',
    'reports.UBSFIXFeed': 'UBSFIXFeed',
    'reports.Unable to cancel the order since route is not active.': 'ルートがアクティブではないためオーダーをキャンセルできません',
    'reports.Unblocking': 'アンブログ',
    'reports.Unplaced': '取り下げ',
    'reports.unrealized p/l': '未実現損益',
    'reports.unrealizedpnl': '未実現損益',
    'reports.unsettled_cash': '未決済金額',
    'reports.updatedtime': '更新済時間',
    'reports.Updater': 'アップデーター',
    'reports.URL': 'URL',
    'reports.usage': '使用',
    'reports.USE_OPEN_PRICE_IN_MARGIN': 'オープン価格を使用',
    'reports.UseDataDictionary': 'データ辞書を使用',
    'reports.usePrevQuotedOrders': '前回クオート注文を使用',
    'reports.UsePriority': '優先順位を使用',
    'reports.user': 'ユーザー',
    'reports.User': 'ユーザー',
    'reports.User Authorization Error': 'ユーザー認証エラー',
    'reports.user group': 'ユーザーグループ',
    'reports.User Previledges Report': 'ユーザー権限レポート',
    'reports.user.acc_4_signals': 'シグナル用アカウント',
    'reports.user.address': 'アドレス',
    'reports.user.amount': '量',
    'reports.user.amountType': '量タイプ',
    'reports.user.birthday': '生年月日',
    'reports.user.certificate': 'ユーザー証明書',
    'reports.user.chatOnEmail': 'Eメールでチャットを送信',
    'reports.user.city': '市',
    'reports.user.comment': 'コメント',
    'reports.user.commission_type': 'によるコミッション',
    'reports.user.email': 'Email',
    'reports.user.extaccount': '外部アカウント',
    'reports.user.firstname': '名',
    'reports.user.gender': '性',
    'reports.user.group': 'グループ',
    'reports.user.how_handle': 'シグナルの取扱',
    'reports.user.introducebroker': 'IBアカウント',
    'reports.user.language': '言語',
    'reports.user.lastname': '苗字',
    'reports.user.linkedUsers': 'リンクされたユーザー',
    'reports.user.login': 'ログイン',
    'reports.user.middlename': 'ミドルネーム',
    'reports.user.password': '新しいパスワード',
    'reports.user.phonenumber': '電話番号',
    'reports.user.phonepassword': '電話パスワード',
    'reports.user.photo': '写真',
    'reports.user.pin': 'PINコード',
    'reports.user.showInSearch': '検索結果に表示',
    'reports.user.showMyContacts': '自分の連絡先を表示',
    'reports.user.signalsOnEmail': 'Eメールでシグナル',
    'reports.user.state': '状態',
    'reports.user.status': 'ステータス',
    'reports.user.subscribeplan': 'プラン申し込み',
    'reports.user.website': 'ウェブサイト',
    'reports.user.zipcode': '郵便番号',
    'reports.usergroup.group': 'スーパーグループ',
    'reports.usergroup.name': 'ユーザーグループ',
    'reports.USERGROUPSVIS_RULES': 'ユーザーグループ表示',
    'reports.userlogin': 'ユーザーログイン',
    'reports.UserLogin': 'ユーザーログイン',
    'reports.username': 'ユーザー',
    'reports.Users Report': 'ユーザーレポート',
    'reports.Users Report(copy)': 'ユーザーレポート(コピー)',
    'reports.users_num': 'ユーザーnum',
    'reports.useSSL': 'SSL使用',
    'reports.ValidateFieldsHaveValues': '値があるフィールドを検証',
    'reports.ValidateFieldsOutOfOrder': '順序外フィールドを検証',
    'reports.ValidateUserDefinedFields': 'ユーザー定義フィールド検証',
    'reports.value': '値があるフィールドを検証',
    'reports.VALUE_ADDRESS': 'アドレス',
    'reports.VALUE_BASE_CURRENCY': 'ベースシンボルバーツ',
    'reports.VALUE_BRUTEFORCE_LOCK_MINUTS': '値ブルートフォースロック分',
    'reports.VALUE_CITY': '市',
    'reports.VALUE_COMMISSION_ACCOUNT': 'コミッションカウンターアカウント',
    'reports.VALUE_COMMISSION_FOR_TRANSFER': 'トランスファーコミッション',
    'reports.VALUE_COMMISSION_ID': 'レベニュープラン',
    'reports.VALUE_COUNTER_CURRENCY': 'カウンターシンボルパート',
    'reports.VALUE_COUNTRY': '国',
    'reports.VALUE_DAY_BEGIN': '開始日',
    'reports.VALUE_DAY_END': '終日',
    'reports.VALUE_DAYS_TILL_EXP': '失効までの日数',
    'reports.VALUE_DEFAULT_BROKER': 'デフォルトのブローカー設定',
    'reports.VALUE_DEFAULT_LANG': 'デフォルトの言語設定',
    'reports.VALUE_DERIVATIVE_TYPE': 'デリバティブの種類',
    'reports.VALUE_HEDGE_COEFFICIENT': 'ヘッジ係数',
    'reports.VALUE_L2QUOTEGROUP_DEPTH': 'レベル2グループ深度',
    'reports.VALUE_L2QUOTEGROUP_INTERVAL': 'レベル2グループ間隔',
    'reports.VALUE_LEVERAGE': 'レバレッジ',
    'reports.VALUE_LOT_SIZE': 'ロットサイズ',
    'reports.VALUE_LOTSTEP': 'ロットサイズの最小変動幅',
    'reports.VALUE_MARGIN_COEFFICIENT': 'マージン係数（時間ベース）',
    'reports.VALUE_MARGINCALL': 'マージンコール、 ％',
    'reports.VALUE_MARGINTRADING': '取引停止レベル、％',
    'reports.VALUE_MARKET_RANGE': '市場の範囲、ティック',
    'reports.VALUE_MAX_DELTA_QUOTES': '後続の2つのクオート（ティック）間の最大許容価格差',
    'reports.VALUE_MAX_DELTA_TRADES': '2つの後続取引間の最大許容価格差',
    'reports.VALUE_MAXIMUM_CACHE_SIZE': '最大キャッシュサイズ',
    'reports.VALUE_MAXIMUM_FILTER': '最大スパイク値',
    'reports.VALUE_MAXIMUMLOT': '最大ロットサイズ',
    'reports.VALUE_MIN_OPTION_INTERVAL': '後続の2つのオプションクオート間の最大許容時間差',
    'reports.VALUE_MIN_PRICE_DIFF_FOR_MARGIN': 'マージンの最小価格差',
    'reports.VALUE_MINIMALLOT': '最小ロットサイズ',
    'reports.VALUE_MINIMUM_FILTER': '最小スパイク値',
    'reports.value_nav': '値/ナビゲーション',
    'reports.VALUE_NO_QUOTES_MAXTIME': 'クオートなしの最大時間',
    'reports.VALUE_NUMBER_OF_SHARES': '株式数',
    'reports.VALUE_ONE_COUNTER_ACCOUNT': 'カウンターアカウント',
    'reports.VALUE_PIP_SIZE': 'ティックサイズ(価格変動幅の最小単位)',
    'reports.VALUE_PnL_ACCOUNT': 'P / Lカウンターアカウント',
    'reports.VALUE_POST_SESSION_END': 'ポストセッション終了',
    'reports.VALUE_PRE_SESSION_BEGIN': 'セッション開始前',
    'reports.VALUE_PRICE_GENERATION_MODEL': '価格生成モデル',
    'reports.VALUE_PWD_VERIFICATION_LEN': 'パスワードの長さ確認',
    'reports.VALUE_QUOTE_CACHE_15MIN_SIZE': 'クオートもりキャッシュ15分サイズ',
    'reports.VALUE_QUOTE_CACHE_1MIN_SIZE': 'クオートキャッシュ1分サイズ',
    'reports.VALUE_QUOTE_CACHE_30MIN_SIZE': 'クオートキャッシュ30分サイズ',
    'reports.VALUE_QUOTE_CACHE_4HOUR_SIZE': 'クオートキャッシュ4時間サイズ',
    'reports.VALUE_QUOTE_CACHE_5MIN_SIZE': 'クオートキャッシュ5分サイズ',
    'reports.VALUE_QUOTE_CACHE_DAY_SIZE': 'クオートキャッシュ日サイズ',
    'reports.VALUE_QUOTE_CACHE_HOUR_SIZE': 'クオートキャッシュ時間サイズ',
    'reports.VALUE_QUOTE_CACHE_MONTH_SIZE': 'クオートキャッシュの月サイズ',
    'reports.VALUE_QUOTE_CACHE_SIZE': 'クオートキャッシュサイズ',
    'reports.VALUE_QUOTE_CACHE_TIC_SIZE': 'クオートキャッシュティックサイズ',
    'reports.VALUE_QUOTE_CACHE_WEEK_SIZE': 'クオートキャッシュの週サイズ',
    'reports.VALUE_QUOTE_CACHE_YEAR_SIZE': 'クオートキャッシュ年サイズ',
    'reports.VALUE_QUOTE_PATH': 'クオートキャッシュパス',
    'reports.VALUE_QUOTES_FILE_SIZE': 'クオートファイルサイズ',
    'reports.VALUE_SLIPPAGE': 'スリッページ',
    'reports.VALUE_SMS_PATTERN': 'SMSパターン',
    'reports.VALUE_SPOT_INSTRUMENT': 'スポット記号',
    'reports.VALUE_SPREAD_ACCOUNT': 'スプレッドカウンターアカウント',
    'reports.VALUE_STATE': '状態',
    'reports.VALUE_STOPOUT': 'ロスカットレベル, %',
    'reports.VALUE_SWAP_ACCOUNT': 'カウンターアカウントを入れ替える',
    'reports.VALUE_TEMPLATE_NUMERIC': 'メールテンプレート',
    'reports.VALUE_TICK_COAST': 'ティックコースト',
    'reports.VALUE_TIMEZONE_OFFSET': 'タイムゾーンオフセット',
    'reports.VALUE_UNREPEAT_PASSWORD': '繰り返されないパスワードの数',
    'reports.VALUE_WL_ACCOUNT': '入出金カウンター口座',
    'reports.VALUE_WRONG_PASSWORDS_BEFORE_LOCK': '許可されている間違ったパスワード数',
    'reports.VALUE_ZIP': '郵便番号',
    'reports.ValueAddedTax': '消費税',
    'reports.valuedate': '評価日',
    'reports.Visibility': '表示設定',
    'reports.VISIBILITY_BRANDING': 'ブランディングの可視化',
    'reports.VISIBILITY_COMMISSIONSPLAN': '収益計画の可視化',
    'reports.VISIBILITY_EXTFIELDS': '拡張フィールドの可視化',
    'reports.VISIBILITY_INSTRUMENT_TYPE': 'シンボルタイプの可視化',
    'reports.VISIBILITY_ROUTE': 'ルートの可視化',
    'reports.VISIBILITY_SUBSCRIBE_PLAN': '購読プラン',
    'reports.VISIBILITY_USERGROUP': 'ユーザーグループの表示',
    'reports.Wait confirm for cancel': 'キャンセルの確認待ち',
    'reports.Wait confirm for execution': '実行の確認待ち',
    'reports.Wait confirm for modify': '変更の確認待ち',
    'reports.Waiting market': '市場待ち',
    'reports.WEB_CHART_OE': 'Webチャート（OEでビルド）',
    'reports.WEB_CHART_VISUAL': 'Webチャート（ビジュアルトレーディング）',
    'reports.WEB_MARKETDEPTH': 'Webマーケットの厚み',
    'reports.WEB_OE': 'Web OE',
    'reports.WEB_OPTION_MASTER': 'Webオプションチェーン',
    'reports.WEB_ORDERS_PANEL_HOT_BTN': 'Webワーキング注文（ホットボタン）',
    'reports.WEB_OTHER': 'ウェブ その他',
    'reports.WEB_POSITIONS_PANEL_HOT_BTN': 'Web ポジションパネル（ホットボタン）',
    'reports.weeks': '週',
    'reports.Widgets Instrument Statistics Report': 'ウィジェット銘柄統計レポート',
    'reports.widgets_isr_buytrades': '取引を購入する',
    'reports.widgets_isr_commission': '手数料',
    'reports.widgets_isr_instrumentname': '銘柄',
    'reports.widgets_isr_netpl': '純損益',
    'reports.widgets_isr_plticks': 'P/L ティック',
    'reports.widgets_isr_selltrades': 'Sell取引',
    'reports.widgets_isr_swap': 'スワップ',
    'reports.widgets_isr_totaltrades': '総取引',
    'reports.widgets_isr_trades': '取引',
    'reports.widgets_isr_tradevolume': '量',
    'reports.widgets_isr_winningtrades': '勝ちトレード',
    'reports.widthdraw': '出金',
    'reports.Withdraw': '出金',
    'reports.Withdrawal': '出金',
    'reports.withdrawalfee': '出金手数料',
    'reports.Wrong account operation summ.': '間違ったアカウント操作の合計',
    'reports.Wrong recovery key': 'キーコードが違います',
    'reports.years': '年',
    'reports.yes': 'はい',
    "reports.You can't trade with this account.": 'このアカウントでトレードできません',
    Reserve: '反転',
    'ribbon.enviroment': '環境',
    'ribbon.enviroment.help': 'ヘルプ',
    'ribbon.enviroment.logout': 'ログアウト',
    'ribbon.enviroment.New': '新しいワークスペースを作成する',
    'ribbon.enviroment.NotificationWindow.lockTrading': '取引がロックされています',
    'ribbon.enviroment.NotificationWindow.UnlockTrading': '取引のロックが解除されています',
    'ribbon.enviroment.Save': 'ワークスペースを保存',
    'ribbon.enviroment.settings': '設定',
    'ribbon.help.about': 'バージョン情報',
    'ribbon.help.onboarding': 'チュートリアル',
    'ribbon.help.help': '詳細',
    'ribbon.help.home': 'ウェブサイト',
    'ribbon.linking.linkBlue': '青',
    'ribbon.linking.linkEmpty': '無し',
    'ribbon.linking.linkGreen': '緑',
    'ribbon.linking.linkOrange': 'オレンジ',
    'ribbon.linking.linkPurple': '紫',
    'ribbon.linking.linkRed': '赤',
    'ribbon.linking.linkYellow': '黄色',
    'ribbon.more.tradingTools': '取引ツール',
    'ribbon.tools': 'ツール',
    'ribbon.tools.full_logs': 'サーバーメッセージのすべての記録',
    'ribbon.tools.grid': 'グリッド線',
    'ribbon.tools.withdrawal': '出金',
    'ribbon.tools.reports': 'レポート',
    'ribbon.tradingTerminal': 'ターミナル',
    'ribbon.tradingTerminal.statement': '#hidden#',
    'RiskManagement.Messages.DailyLimit': '日次損失限度額に達しました',
    'RiskManagement.Messages.MaxDailyProfit': '日次利益目標に達しました',
    'RiskManagement.Messages.MaxTrailingLimit': '最大ドローダウンに達しました',
    'RiskManagement.Messages.MaxUnrealizedLossLimit': '未実現損失上限額に達しました',
    'RiskManagement.Messages.WeeklyLimit': '週次損失限度額に達しました',
    'screen.about.copyright': '© 2017-2025 TraderEvolution Global LTD.',
    'screen.about.dateBuild': 'リリース日:',
    'screen.about.title': 'バージョン情報',
    'screen.about.titleWeb': 'DealingStationについて',
    'screen.about.versionWeb': 'Build バージョン',
    'screen.additionalVerification.comment': '接続（{1}）には追加の認証が必要です。',
    'screen.additionalVerification.Need activation': 'アクティベーションが必要',
    'screen.additionalVerification.Need activation.comment': 'アクティベーションコードを入力してください (Emailに送信済み)',
    'screen.additionalVerification.Need activation.watermark': '<メール内のアクティベーションコードを入力>',
    'screen.additionalVerification.title': '追加の認証',
    'screen.additionalVerification.title.Email': '追加の認証 (Eメールのワンタイムパスワード)',
    'screen.additionalVerification.title.Fixed': '追加の認証 (固定パスワード)',
    'screen.additionalVerification.title.Sms': '追加の認証 (SMSワンタイムパスワード)',
    'screen.additionalVerification.watermark': '<追加のパスワードを入力>',
    'screen.changepass.cancel': 'キャンセル',
    'screen.changepass.changed': 'パスワードの変更に成功しました',
    'screen.changepass.confirm': '新しいパスワードの確認',
    'screen.changepass.new': '新しいパスワード',
    'screen.changepass.ok': '変更',
    'screen.changepass.old': '現在のパスワード',
    'screen.changepass.title': 'パスワードの変更',
    'screen.changeTradingPass.title': '取引用パスワードの変更',
    'screen.changepass.watermark_confirm': '＜新しいパスワードの確認＞',
    'screen.changepass.watermark_new': '＜新しいパスワード＞',
    'screen.changepass.watermark_old': '＜現在のパスワード＞',
    'screen.changepass.alert_not_match': 'パスワードが一致しません',
    'screen.changepass.wrongVerification': 'パスワードが間違っています',
    'screen.closePosition.cancel': 'キャンセル',
    'screen.closePosition.ok': '決済',
    'screen.closePosition.title': '決済',
    'screen.error.title': 'エラー',
    'screen.export.header': 'エクスポート',
    'screen.export.contextMenu': 'エクスポート',
    'screen.export.Export': 'エクスポート',
    'screen.export.Cancel': 'キャンセル',
    'screen.export.CSV': 'CSV',
    'screen.export.Excel': 'Excel',
    'screen.export.selectFormat.tt': 'ファイルの種類を選択',
    'screen.export.checkAllText': '全てチェック',
    'screen.licensing.emptyFieldErrors.password': 'パスワードが未入力です',
    'screen.licensing.statusMessages.connecting': '接続中...',
    'screen.login.change_pass': 'パスワードがリセットされているか期限切れのため変更が必要です',
    'screen.lookup.scripts.noitems': '該当なし',
    'screen.lookup.scripts.showing': '{1}件表示',
    'screen.lookup.scripts.watermark': '＜検索＞',
    'screen.modifyOrder.amount': '数量:',
    'screen.modifyOrder.bind_to': '保留',
    'screen.modifyOrder.Change order': '注文の変更',
    'screen.modifyOrder.created': '日付/時間:',
    'screen.modifyOrder.instrument': '銘柄:',
    'screen.modifyOrder.market_price': '現在価格:',
    'screen.modifyOrder.modify': '変更',
    'screen.modifyOrder.modifyPosition': 'ポジションの変更',
    'screen.modifyOrder.number': 'ポジションID:',
    'screen.modifyOrder.operation': '売買種別:',
    'screen.modifyOrder.orderNumber': '注文ID:',
    'screen.modifyOrder.ordertype': '注文種別:',
    'screen.modifyOrder.price': '始値:',
    'screen.modifyOrder.route': 'ルート:',
    'screen.properties.cancel': '閉じる',
    'screen.properties.cancel.ToolTip': '閉じる',
    'screen.properties.common': 'プロパティ',
    'screen.properties.ok': 'OK',
    'screen.properties.ok.ToolTip': 'OK',
    'screen.properties.title': '一般設定',
    'screen.properties.unsavedChanges': '変更内容は保存されていません。このままウインドウを閉じますか?',
    'screen.LoginScreen.Connecting': '接続中...',
    'screen.LoginScreen.Error.loginIdIsEmpty': 'ログインIDが未入力です',
    'screen.LoginScreen.Error.passwordIdIsEmpty': 'パスワードが未入力です',
    'screen.LoginScreen.ForgotLinkLabel': 'パスワードをお忘れの方',
    'screen.LoginScreen.ForgotLinkLabel.ToolTip': 'パスワード再設定',
    'screen.LoginScreen.LoadingUserData': 'ユーザー情報の取得中...',
    'screen.LoginScreen.LoginButtonText': 'ログイン',
    'screen.LoginScreen.LoginButtonTooltip': 'プラットフォームへログイン',
    'screen.LoginScreen.LoginIDLabel': 'ログイン',
    'screen.LoginScreen.LoginIDTextBox.ToolTip': 'ユーザーログイン',
    'screen.LoginScreen.PasswordLabel': 'パスワード',
    'screen.LoginScreen.PasswordTextBox.ToolTip': 'ユーザーパスワード',
    'screen.LoginScreen.RegistrationLinkLabel': '登録',
    'screen.LoginScreen.RegistrationLinkLabel.ToolTip': 'デモ口座の登録',
    'screen.LoginScreen.SavePassCheckBox': 'パスワードの保存',
    'screen.LoginScreen.SavePassCheckBox.ToolTip': 'ログイン状態を維持',
    'screen.LoginScreen.Settings.LanguageLabel': '言語',
    'screen.recconection.attemptLabelLabel': '再試行:',
    'screen.recconection.reconnecting': '再接続中...',
    'screen.recconection.seconds': 'お待ちください...',
    'screen.recconection.statusLabelLabel': 'ステータス:',
    'screen.recconection.stop': '中止',
    'screen.recconection.title': '再接続マネージャー',
    'screen.recconection.tryNow': '試す',
    'screen.recconection.waiting': '待機中',
    'screen.RecoveryPassForm.Cancel': 'キャンセル',
    'screen.RecoveryPassForm.Confirm': '新しいパスワードの確認',
    'screen.RecoveryPassForm.Email': 'Email:',
    'screen.RecoveryPassForm.HaveCode': 'キーコードを持っています',
    'screen.RecoveryPassForm.Key': 'キーコード',
    'screen.RecoveryPassForm.Login': 'ログインID:',
    'screen.RecoveryPassForm.New password': '新しいパスワード',
    'screen.RecoveryPassForm.New password contains prohibited symbols': '新しいパスワードに使用できない文字が含まれています',
    'screen.RecoveryPassForm.Send': '再設定',
    'screen.RecoveryPassForm.Title': 'パスワード再設定',
    'screen.RecoveryPassForm.invalid_login': 'ログインIDが違います',
    'screen.RecoveryPassForm.invalid_login_or_email': 'アカウント情報が違います',
    'screen.RecoveryPassForm.invalid_email': '無効なメールアドレスです',
    'screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!': 'パスワードの再設定手続きに必要な情報を送付しました。Emailをご確認ください',
    'screen.registrationWizard.confirmPassword': '*新しいパスワードの確認:',
    'screen.registrationWizard.confirmPassword.watermark': '<新しいパスワーの確認>',
    'screen.registrationWizard.email': '*Email:',
    'screen.registrationWizard.email.watermark': '<Email>',
    'screen.registrationWizard.Country': '国:',
    'screen.registrationWizard.Password': '*パスワード',
    'screen.registrationWizard.Balance': '残高:',
    'screen.registrationWizard.firstName': '*名前:',
    'screen.registrationWizard.firstName.watermark': '＜2文字以上＞',
    'screen.registrationWizard.lastName': '*苗字:',
    'screen.registrationWizard.lastName.watermark': '＜2文字以上＞',
    'screen.registrationWizard.login': 'ログイン:',
    'screen.registrationWizard.login.watermark': '＜自動で作成する場合は空欄＞',
    'screen.registrationWizard.password.watermark': '<パスワードを入力>',
    'screen.registrationWizard.phone': '電話番号:',
    'screen.registrationWizard.phoneNumber.watermark': '<(国番号)電話番号>',
    'screen.registrationWizard.registrationStatus': '登録状況',
    'screen.registrationWizard.successMessage': '登録完了です\nログイン: {1}\nパスワード: {2}',
    'screen.registrationWizard.Header': 'デモ口座の作成',
    'screen.registrationWizard.Register': '登録',
    'screen.registrationWizard.Cancel': 'キャンセル',
    'screen.renameScreen.cancel': 'キャンセル',
    'screen.renameScreen.illegalName': '不正な名前です',
    'screen.renameScreen.ok': 'OK',
    'screen.renameScreen.newList.header': 'リスト名',
    'screen.renameScreen.newList.placeholder': '新しいリスト名',
    'screen.renameScreen.newTemplate.header': 'テンプレート名',
    'screen.renameScreen.newTemplate.placeholder': '新しいテンプレート',
    'screen.renameScreen.rename.header': '名前の変更',
    'screen.rename.alreadyExist.firstPart': '名前',
    'screen.rename.alreadyExist.secondPart': '既に存在します',
    'screen.remove.lastlist': '最低1つのリストが必要です',
    'screen.remove.title': '削除',
    'screen.remove.confirmText.firstPart': '削除',
    'screen.remove.confirmText.secondPart': 'リストを削除しますか？',
    'screen.remove.confirmText.secondPart.templates': 'テンプレートを削除しますか?',
    'screen.ReportMessageTooltip.CreatedAt': 'に作成',
    'screen.ReportMessageTooltip.FilledAt': 'で約定',
    'screen.ReportMessageTooltip.MarginCall': 'マージンコール',
    'screen.ReportMessageTooltip.Market': '成行',
    'screen.ReportMessageTooltip.ModifiedAt': 'で修正',
    'screen.ReportMessageTooltip.RemovedAt': 'で削除',
    'screen.reports.actions_print': '印刷',
    'screen.reports.address': '住所',
    'screen.reports.beginDatePicker.ButtonDropDown.ToolTip': 'クリックしてカレンダーを開く',
    'screen.reports.beginDatePicker.ToolTip': '日付/時刻を選択',
    'screen.reports.brokerzip': '郵便番号',
    'screen.reports.button_printer_setup': 'セットアップ',
    'screen.reports.byGroup': 'グループ別',
    'screen.reports.byInstrumentGroup': 'グループ別',
    'screen.reports.byInstruments': '銘柄別',
    'screen.reports.byUser': '取引口座別',
    'screen.reports.byUserGroup': 'グループ別',
    'screen.reports.cell_data': '',
    'screen.reports.column.inner_text': '',
    'screen.reports.columns_maneger': '列マネージャー...',
    'screen.reports.country': '国',
    'screen.reports.csv': 'CSVにエクスポート...',
    'screen.reports.customerzip': '都市,郵便番号',
    'screen.reports.dataCoverage': 'データカバレッジ',
    'screen.reports.errSaving': 'ファイルの保存中にエラーが発生しました',
    'screen.reports.excel': 'Excelにエクスポート...',
    'screen.reports.exportToButton': 'エクスポート...',
    'screen.reports.filter': 'フィルターマネージャー...',
    'screen.reports.historyFor': 'の履歴',
    'screen.reports.html': 'HTMLにエクスポート...',
    'screen.reports.info': '情報',
    'screen.reports.error': 'エラー',
    'screen.reports.Reports': 'レポート',
    'screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip': '銘柄のグループを選択',
    'screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip': '銘柄を選択',
    'screen.reports.license': 'ライセンス番号',
    'screen.reports.listViewEx.itemAll': '全て',
    'screen.reports.manager': 'マネージャー',
    'screen.reports.minimizeButton.Hide reports parameters': 'レポートパラメータを非表示',
    'screen.reports.minimizeButton.Show reports parameters': 'レポートパラメータを表示する',
    'screen.reports.name': '名前',
    'screen.reports.news.daily': 'デイリー',
    'screen.reports.news.from': 'From',
    'screen.reports.news.hideACriterias': '詳細条件を非表示',
    'screen.reports.news.invalidDateTime': '有効な「from」および「to」の日付/時刻を選択してください',
    'screen.reports.news.monthToDate': '月初来',
    'screen.reports.news.range': '範囲',
    'screen.reports.news.showACriterias': '詳細条件を表示',
    'screen.reports.news.sinceInception': '始まりから',
    'screen.reports.news.till': 'まで',
    'screen.reports.news.yearToDate': '年初来',
    'screen.reports.noData': 'データなし',
    'screen.reports.order': '注文',
    'screen.reports.param.Name': '名前',
    'screen.reports.paramOfReport': 'レポートのパラメーター',
    'screen.reports.pdf': 'PDFにエクスポート...',
    'screen.reports.phone': '電話番号',
    'screen.reports.pos': 'ポジション',
    'screen.reports.president': '代表者の名前',
    'screen.reports.print': '印刷...',
    'screen.reports.processingRequset': 'キャンセル',
    'screen.reports.rangeError': '"From" の日付は "To" の日付の後にすることはできません！',
    'screen.reports.report.button_printer_setup': 'セットアップ',
    'screen.reports.reportHeader.accnumber': 'アカウント番号',
    'screen.reports.reportHeader.from': 'から',
    'screen.reports.reportHeader.Text': '顧客とブローカーの情報',
    'screen.reports.reportHeaders.account': 'アカウント：',
    'screen.reports.reportHeaders.currancy': '通貨:',
    'screen.reports.reportHeaders.date': '日付:',
    'screen.reports.reportHeaders.from': 'From：',
    'screen.reports.reportHeaders.owner': '所有者:',
    'screen.reports.reportHeaders.to': 'To：',
    'screen.reports.reportHeaderText': '顧客とブローカーの情報',
    'screen.reports.reportParameters': 'レポートのパラメータ',
    'screen.reports.reports.Canceled': '操作がキャンセルされました',
    'screen.reports.reports.ExpingToCSV': 'データをCSVドキュメントにエクスポートしています...',
    'screen.reports.reports.ExpingToExcel': 'データをExcelドキュメントにエクスポートしています...',
    'screen.reports.reports.ExpingToHTML': 'データをHTMLドキュメントにエクスポートしています...',
    'screen.reports.reports.ExpingToPDF': 'データをPDFドキュメントにエクスポートしています...',
    'screen.reports.reports.ExportingPrint': '印刷用のデータをエクスポートしています...',
    'screen.reports.reports.ExpPrint': 'データは印刷用に正常にエクスポートされました。',
    'screen.reports.reports.ExpPrintErr': '印刷用にエクスポート中にエラーが発生しました！',
    'screen.reports.reports.ExpToCSV': 'データがCSVに正常にエクスポートされました。',
    'screen.reports.reports.ExpToCSVErr': 'CSVへのエクスポート中にエラーが発生しました！',
    'screen.reports.reports.ExpToExcel': 'データがExcelに正常にエクスポートされました。',
    'screen.reports.reports.ExpToExcelErr': 'Excelへのエクスポート中にエラーが発生しました！',
    'screen.reports.reports.ExpToHTML': 'データはHTMLドキュメントに正常にエクスポートされました。',
    'screen.reports.reports.ExpToHTMLErr': 'HTMLドキュメントへのエクスポート中にエラーが発生しました！',
    'screen.reports.reports.ExpToPDF': 'データはPDFドキュメントに正常にエクスポートされました。',
    'screen.reports.reports.ExpToPDFErr': 'PDFドキュメントへのエクスポート中にエラーが発生しました！',
    'screen.reports.reports.getReport': 'レポートを取得',
    'screen.reports.reports.in_separate_window': 'レポートは別ウィンドウで開かれました',
    'screen.reports.reports.inCurrentWindow': '現在のウィンドウで開く',
    'screen.reports.reports.info': 'レポートマネージャーを使用して、アカウントアクティビティのレポートを表示および印刷する',
    'screen.reports.reports.inSeparateWindow': '別ウィンドウで開く',
    'screen.reports.reports.loading': 'レポートのデータを読み込んでいます...',
    'screen.reports.reports.noData': '申し訳ありませんが、このリクエストに利用可能なデータはありません。別のパラメーターを使用してみてください。',
    'screen.reports.reports.noItemAvailable': '利用可能なアイテムはありません',
    'screen.reports.reportType': 'レポートタイプ',
    'screen.reports.reportTypeCombobox.ToolTip': 'レポートタイプを選択',
    'screen.reports.save': '保存',
    'screen.reports.showSummary': 'サマリーを表示',
    'screen.reports.sinceInception': '始まりから',
    'screen.reports.summary': 'サマリー',
    'screen.reports.table': 'テーブル',
    'screen.reports.title': 'レポートマネージャー',
    'screen.reports.to': 'To',
    'screen.reports.trades': '取引',
    'screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip': 'アカウントのグループを選択',
    'screen.reports.UserAndGroupLookup.usersLookup.ToolTip': '取引口座を選択',
    'screen.TimeZoneSelector.title': 'タイムゾーンを選択',
    'screen.tradingTools.header': 'トレーディングツール',
    'screen.UnlockTradingScreen.lockedLabel': '取引がロックされています',
    'screen.UnlockTradingScreen.unlockButton': 'ロック解除',
    'screen.withdrawal.account': 'アカウントから',
    'screen.withdrawal.Asset': '資産',
    'screen.withdrawal.Availible funds': '利用可能：',
    'screen.withdrawal.avaliableNumeric.ToolTip': '残存資本の設定',
    'screen.withdrawal.Cancel': 'キャンセル',
    'screen.withdrawal.error.not_allowed': '出金タイプのアカウント操作は本ユーザーには許可されていません。ブローカーにお問い合わせください',
    'screen.withdrawal.error.not_enough_balance': '残高が不足しています',
    'screen.withdrawal.error.not_enough_blocked': '無効な金額（ロックされた金額以上をロック解除できません）',
    'screen.withdrawal.error.not_enough_margin': '証拠金不足',
    'screen.withdrawal.error.UnableToBlock': 'エラー! ロックできません',
    'screen.withdrawal.error.WrongSum': 'エラー: 間違った合計',
    'screen.withdrawal.errorLabel': '残高不足で出金できません',
    'screen.withdrawal.Fee': '出金手数料:',
    'screen.withdrawal.from': 'から',
    'screen.withdrawal.Remains': '残高',
    'screen.withdrawal.RequiredAmount': '必要額：',
    'screen.withdrawal.ReservedWithdrawal': '出金',
    'screen.withdrawal.usersLookup.ToolTip': '取引口座を選択',
    'screen.withdrawal.Withdraw': '出金',
    'screen.withdrawal.Withdrawal': '出金',
    'screen.withdrawal.withdrawalNumeric.ToolTip': '引き出し資本を設定',
    'screen.withdrawal.Amount': '金額',
    'Simple Moving Average': '単純移動平均線(SMA)',
    SixMonth: '6カ月',
    'Smoothed Moving Average': '平滑移動平均線(SMMA)',
    'Standard Deviation': '標準偏差',
    'statusBar.passwordpanel.DetalTicket.Text': 'アクセスが許可されました。',
    'statusBar.passwordPanel.passwordBox.invalidPass': '取引パスワードが無効です',
    Stochastic: 'ストキャスティクス',
    'Stochastic Slow': 'スローストキャスティクス',
    Stop: 'ストップ',
    'Stop Limit': 'ストップリミット',
    'Stop-loss': 'ストップロス',
    Suomi: '#hidden#',
    'Swing Index': 'スウィング指数',
    'Take-profit': '利益確定',
    'TerceraAccountLookup.AccountNotExist': 'アカウントが存在しません。',
    'TerceraAccountLookup.IncorrectAccount': '不正のアカウント',
    'TerceraAccountLookup.Linked accounts': 'アカウントへの接続',
    'TerceraAccountLookup.Multy select': '複数選択',
    'TerceraAccountLookup.Select': '選択',
    'TerceraAccountLookup.Select All': '全てを選択',
    'textBoxWithValidating.ErrorText.NameContainsInvalidChar': '名前に不正な文字が含まれます',
    'textBoxWithValidating.ErrorText.NameExist': 'この名前は既に存在しています。',
    ThreeMonth: '3カ月',
    'Tr. stop': 'トレーリングストップ',
    'TradeDefaultManager.Set defaults': 'デフォルトの設定',
    'TradeDefaultManager.Types manager': '管理設定',
    'TradeTool.Amount of order': '注文数量',
    'TradeTool.Cancel order': '注文の取消',
    'TradeTool.Cancel SL': 'SLをキャンセル',
    'TradeTool.Cancel TP': 'TPをキャンセル',
    'TradeTool.Close position': 'ポジションをクローズ',
    'TradeTool.Drag to set SL order': 'ドラッグによるSL設定',
    'TradeTool.Drag to set TP order': 'ドラッグによるTP設定',
    'TradeTool.Order': '注文',
    'TradeTool.Position': 'ポジション',
    'TradeTool.Position P/L': 'スポット損益',
    'TradeTool.Tick and P/L': 'ティックとP/L',
    'TradeTool.Trailing Stop': 'トレーリングストップ',
    'TradeTool.View more actions': '操作メニューを表示',
    TTO: 'TTO',
    'TypesManagerScreen.DefaultLotsSetup': 'デフォルトのロット設定',
    'TypesManagerScreen.NumericLabel.Price increment (arrow), ticks': '価格増分（矢印）、Tick',
    'TypesManagerScreen.NumericLabel.SL default offset, ticks': 'S/L デフォルトのオフセット値(ティック単位)',
    'TypesManagerScreen.NumericLabel.TP default offset, ticks': 'T/P デフォルトのオフセット値(ティック単位)',
    'TypesManagerScreen.NumericLabel.User quantity coefficient': 'ユーザー数量係数',
    'TypesManagerScreen.QuickTable.Column.Default lots': 'デフォルトのロットサイズ',
    'TypesManagerScreen.QuickTable.Column.Enable': '有効化',
    'TypesManagerScreen.Types Manager': '注文設定',
    'UserControl.TerceraNumeric.ValueGreaterMax': '上限を超えています',
    'UserControl.TerceraNumeric.ValueIsEmpty': '未入力',
    'UserControl.TerceraNumeric.ValueLessMin': '値が最小値未満',
    'UserControl.TerceraNumeric.ValueNotMultiple': '値は最小値の倍数ではありません。 変更する',
    'UserControl.TerceraNumeric.ValueNotNumber': '値は数値ではありません',
    Volume: '出来高',
    'Volume Weighted Average Price': '出来高加重平均価格(VWAP)',
    VWAP: 'VWAP',
    allowCreatePanelsFromPanel: '',
    allowLinkSystem: '',
    allowWorkspaceContextMenu: '',
    allowWorkspaceEdit: '',
    'orders.confirm.all.question1': '全てキャンセル',
    'orders.confirm.all.question2': 'しますか？',
    'orders.confirm.all.title': '全ての注文をキャンセル',
    'orders.confirm.cancel.question1': '選択した全ての注文をキャンセルしますか?',
    'positions.confirm.mutual.question1': '選択した（）ポジションを相互にクローズしますか？',
    'positions.confirm.mutual.question2': '',
    'Rows.firstPart': '使用可能なシンボル/行の制限',
    'Rows.secondPart': 'に達しました。 詳細については、サポートチームにお問い合わせください',
    'general.messageBox.cancel.ToolTip': '変更を破棄して閉じる',
    'property.Confirmations.ToolTip': '確認設定',
    'general.messageBox.ok.ToolTip': '変更を適用して閉じる',
    'property.Defaults.ToolTip': '取引デフォルト設定',
    'property.View.ToolTip': '設定を表示',
    'property.Warnings.ToolTip': '警告の設定',
    'Throtling.Count.firstPart': '利用可能なパネル数に達しました。 詳細については、サポートチームにお問い合わせください',
    'Throtling.Count.secondPart': 'ワークスペースを解除して名前を変更します',
    'workspace.At first you should unlock workspace to rename it': '',
    'workspace.Clear': '消去',
    'workspace.clear_ask': 'ワークスペースを消去しますか？',
    'workspace.clear_wsp': 'ワークスペースを消去',
    'workspace.Close': '閉じる',
    'workspace.close_wsp': 'ワークスペースを閉じる',
    'workspace.DefaultNameSpace': 'ワークスペース#',
    'workspace.errorSavingFile': 'ファイルの保存中にエラーが発生しました',
    'workspace.information': '情報',
    'workspace.saved.Message': 'ワークスペースを保存',
    'workspace.Lock': 'ロック',
    'workspace.Lock.Message': 'ワークスペースをロックしました',
    'workspace.menu.Clone': '複製',
    'workspace.menu.Close': '閉じる',
    'workspace.menu.CloseAllButThis': 'これ以外を閉じる',
    'workspace.menu.Lock': 'ロック',
    'workspace.newWorkspaceName': '新しいワークスペース名を入力',
    'workspace.numberOfWorkspace': 'これ以上の数のワークスペースを開くことができません',
    'workspace.Rename': 'ワークスペース名の変更',
    'workspace.Unlock': '解除',
    'workspace.Unlock.Message': 'ワークスペースのロックを解除しました',
    'InstrumentDetailsPanel.ISIN': 'ISIN',
    'InstrumentDetailsPanel.AccruedInterest.descr': '1ロットの現時点の未収利息',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot.descr': '行われた取引の各ロットに対して、固定のリベート値が（計画通貨で）請求/返還されることを意味します',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot.descr': '行われた取引の各ロットに対して、固定のリベート値が（計画通貨で）請求/返還されることを意味します',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume.descr': '請求/返還額が取引量からパーセントとして計算される手段',
    'InstrumentDetailsPanel.AllowedOperations.descr': '指定されたシンボルに許可されている操作を表示します',
    'InstrumentDetailsPanel.AllowedOrderTypes.descr': '指定されたシンボルに許可されている注文タイプを表示します',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday': 'ショートポジションの許可',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery': 'ショートポジション受渡を許可する',
    'InstrumentDetailsPanel.AllowShortPositions.descr': '指定されたシンボルのショートポジションをオープンにすることが許可されているかどうかを示します',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday.descr': '指定されたシンボルのショートポジションをオープンにすることが許可されているかどうかを示します',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery.descr': '指定されたシンボルのショートポジションをオープンにすることが許可されているかどうかを示します',
    'InstrumentDetailsPanel.AssetClass.descr': '取引所で取引されているシンボルの種類',
    'InstrumentDetailsPanel.CloseOutDeadline.descr': '特定の各契約によって各ポジションがクローズされる日付',
    'InstrumentDetailsPanel.ContactMonth.descr': '契約の有効期限。オプションと先物で利用可能。',
    'InstrumentDetailsPanel.ContractSize.descr': '1つの契約の原資産の金額',
    'InstrumentDetailsPanel.CurrentSession.descr': '現在の取引セッションに関する情報',
    'InstrumentDetailsPanel.DeliveryMethod.descr': 'アセット受渡のタイプ',
    'InstrumentDetailsPanel.DeliveryStatus.descr': 'アセット受渡のステータス',
    'InstrumentDetailsPanel.Description.descr': '銘柄の詳細情報',
    'InstrumentDetailsPanel.FirstTradeDate.descr': '契約が最初に取引できる日付。オプションと先物で利用可能。',
    'InstrumentDetailsPanel.HiLimit.descr': '許容価格帯上限を超えた遅延注文は許可されません',
    'InstrumentDetailsPanel.ISIN.descr': '国際証券コード仕様であるISO6166で定められた全世界共通の12桁のコード',
    'InstrumentDetailsPanel.KEYNextHoliday.descr': 'マーケットが休場となる次回の休日',
    'InstrumentDetailsPanel.LastTradeDate.descr': '最後に取引できる日付。オプション、先物、先物で利用可能',
    'InstrumentDetailsPanel.LotSize.descr': '1ロットの基本資産の量',
    'InstrumentDetailsPanel.LotStep.descr': 'ロット変更のステップ',
    'InstrumentDetailsPanel.LowLimit.descr': '許容価格帯下限を超えた遅延注文は許可されません',
    'InstrumentDetailsPanel.MAIN.descr': 'メインセッションの設定に関する情報',
    'InstrumentDetailsPanel.Margin.descr': 'マージン計算タイプに依存するマージン要件を表示します',
    'InstrumentDetailsPanel.Margin_ByAccount.descr': 'アカウント係数に基づいた証拠金要件を表示します',
    'InstrumentDetailsPanel.MarginBuy.descr': '異なるロング/ショートマージンを使用し、オーバーナイトマージンに依存しないロングポジションのマージン要件を示します',
    'InstrumentDetailsPanel.MarginDay.descr': 'ロング/ショートマージンに依存しない1日のマージン要件を示します',
    'InstrumentDetailsPanel.MarginDayBuy.descr': 'ロングポジションの初期およびメンテナンスマージンの日次係数を表示します',
    'InstrumentDetailsPanel.MarginDaySell.descr': 'ショートポジションの初期およびメンテナンスマージンの日次係数を表示します',
    'InstrumentDetailsPanel.MarginOvernight.descr': 'ロング/ショートマージンに依存しないオーバーナイトマージン要件を示します',
    'InstrumentDetailsPanel.MarginOvernightBuy.descr': 'ロングポジションの翌日振替の初期マージンと維持マージンの係数を表示します',
    'InstrumentDetailsPanel.MarginOvernightSell.descr': 'ショートポジションの翌日振替の初期マージンと維持マージンの係数を表示します',
    'InstrumentDetailsPanel.MarginSell.descr': '異なるロング/ショートマージンを使用し、オーバーナイトマージンに依存しないショートポジションのマージン要件を示します',
    'InstrumentDetailsPanel.Margin.Intraday.descr': 'マージン計算タイプに依存するマージン要件を表示します',
    'InstrumentDetailsPanel.MarginBuy.Intraday.descr': '異なるロング/ショートマージンを使用し、オーバーナイトマージンに依存しないロングポジションのマージン要件を示します',
    'InstrumentDetailsPanel.MarginDay.Intraday.descr': 'ロング/ショートマージンに依存しないショーデイズマージン要件',
    'InstrumentDetailsPanel.MarginDayBuy.Intraday.descr': 'ロングポジションの初期およびメンテナンスマージンの日次係数を表示します',
    'InstrumentDetailsPanel.MarginDaySell.Intraday.descr': 'ショートポジションの初期およびメンテナンスマージンの日次係数を表示します',
    'InstrumentDetailsPanel.MarginOvernight.Intraday.descr': 'ロング/ショートマージンに依存しないオーバーナイトマージン要件を示します',
    'InstrumentDetailsPanel.MarginOvernightBuy.Intraday.descr': 'ロングポジションのオーバーナイト振替の初期マージンと維持マージンの係数を表示します',
    'InstrumentDetailsPanel.MarginOvernightSell.Intraday.descr': 'ショートポジションのオーバーナイト振替の初期マージンと維持マージンの係数を表示します',
    'InstrumentDetailsPanel.MarginSell.Intraday.descr': '異なるロング/ショートマージンを使用し、オーバーナイトマージンに依存しないショートポジションのマージン要件を示します',
    'InstrumentDetailsPanel.Margin.Delivery.descr': 'マージン計算タイプに依存するマージン要件を表示します',
    'InstrumentDetailsPanel.MarginBuy.Delivery.descr': '異なるロング/ショートマージンを使用し、オーバーナイトマージンに依存しないロングポジションのマージン要件を示します',
    'InstrumentDetailsPanel.MarginDay.Delivery.descr': 'ロング/ショートマージンに依存しないショーデイズマージン要件',
    'InstrumentDetailsPanel.MarginDayBuy.Delivery.descr': 'ロングポジションの初期およびメンテナンスマージンの日次係数を表示します',
    'InstrumentDetailsPanel.MarginDaySell.Delivery.descr': 'ショートポジションの初期およびメンテナンスマージンの日次係数を表示します',
    'InstrumentDetailsPanel.MarginOvernight.Delivery.descr': 'ロング/ショートマージンに依存しないオーバーナイトマージン要件を示します',
    'InstrumentDetailsPanel.MarginOvernightBuy.Delivery.descr': 'ロングポジションのオーバーナイト振替の初期マージンと維持マージンの係数を表示します',
    'InstrumentDetailsPanel.MarginOvernightSell.Delivery.descr': 'ショートポジションのオーバーナイト振替の初期マージンと維持マージンの係数を表示します',
    'InstrumentDetailsPanel.MarginSell.Delivery.descr': '異なるロング/ショートマージンを使用し、オーバーナイトマージンに依存しないショートポジションのマージン要件を示します',
    'InstrumentDetailsPanel.MaturityDate.descr': '契約の有効期限',
    'InstrumentDetailsPanel.MaximumLot.descr': '取引可能な最大ロットサイズ',
    'InstrumentDetailsPanel.MaximumLot.Intraday': '最大ロットサイズ',
    'InstrumentDetailsPanel.MaximumLot.Intraday.descr': '取引可能な最大ロットサイズ',
    'InstrumentDetailsPanel.MaximumLot.Delivery': '受渡最大ロット',
    'InstrumentDetailsPanel.MaximumLot.Delivery.descr': '取引可能な最大ロットサイズ',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.descr': 'ロット内の楽器ごとのすべてのポジションの最大合計数量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday.descr': 'ロット内の楽器ごとのすべてのポジションの最大合計数量',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery.descr': 'ロット内の楽器ごとのすべてのポジションの最大合計数量',
    'InstrumentDetailsPanel.MinimalLot.descr': '取引可能な最小ロットサイズ',
    'InstrumentDetailsPanel.MinimalLot.Intraday': '最小ロットサイズ',
    'InstrumentDetailsPanel.MinimalLot.Intraday.descr': '取引可能な最小ロットサイズ',
    'InstrumentDetailsPanel.MinimalLot.Delivery': '受渡最小ロット',
    'InstrumentDetailsPanel.MinimalLot.Delivery.descr': '取引可能な最小ロットサイズ',
    'InstrumentDetailsPanel.NextPaymentDate.descr': '次利回り支払い日',
    'InstrumentDetailsPanel.NormalMarketSize.descr': '通常のマーケットサイズ',
    'InstrumentDetailsPanel.NoticeDate.descr': '最も近い有効期限に関する通知が送信される日付。先物で利用可能。',
    'InstrumentDetailsPanel.OrderPerLot.descr': '取引ロット毎に支払う手数料',
    'InstrumentDetailsPanel.OrderVolume.descr': '約定した各部分注文毎の手数料',
    'InstrumentDetailsPanel.PerFill.descr': '注文の約定毎に支払う手数料',
    'InstrumentDetailsPanel.PerLot.descr': '取引ロット毎に支払う手数料',
    'InstrumentDetailsPanel.PerOrderVolume.descr': '約定した各部分注文毎の手数料',
    'InstrumentDetailsPanel.PerPhoneTransaction.descr': '電話注文による手数料',
    'InstrumentDetailsPanel.PerTransaction.descr': 'トランザクション(注文,注文のキャンセル,注文の変更)毎の手数料',
    'InstrumentDetailsPanel.PerVolume.descr': '手数料は各取引量に応じて算出, %',
    'InstrumentDetailsPanel.POSTCLOSE.descr': 'マーケット後セッションの設定に関する情報',
    'InstrumentDetailsPanel.PREOPEN.descr': 'マーケット前セッションの設定に関する情報',
    'InstrumentDetailsPanel.PreviousPaymentDate.descr': '前回利回り支払日',
    'InstrumentDetailsPanel.QuotiongCurrency.descr': 'クオートが計算される通貨に関する情報',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot.descr': '行われた取引の各ロットに対して、固定のリベート値が（計画通貨で）請求/返還されることを意味します',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot.descr': '行われた取引の各ロットに対して、固定のリベート値が（計画通貨で）請求/返還されることを意味します',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume.descr': '請求/返還額が取引量からパーセントとして計算',
    'InstrumentDetailsPanel.SessionStatus.descr': 'セッションのステータス、利用可能な値：Open、Close',
    'InstrumentDetailsPanel.SettlementDate.descr': '受渡が必要な日付',
    'InstrumentDetailsPanel.StrikePrice.descr': 'オプション契約が実行される原資産の価格レベル',
    'InstrumentDetailsPanel.SwapBuy.descr': 'シンボルのオーバーナイトロングポジションに適用されるスワップ値を表示します',
    'InstrumentDetailsPanel.SwapSell.descr': 'シンボルのオーバーナイトショートポジションに適用されるスワップ値を表示します',
    'InstrumentDetailsPanel.Symbol.descr': 'ルックアップで選択されたシンボルの名前',
    'InstrumentDetailsPanel.TickCoast.descr': '1ティックのベースアセットの量',
    'InstrumentDetailsPanel.TickSize.descr': '価格の最小変化',
    'InstrumentDetailsPanel.TradingBalance.descr': 'T+x 決済期間',
    'InstrumentDetailsPanel.TradindBlockedOnSession.descr': '現在のセッションで取引がブロックされているかどうかを示します',
    'InstrumentDetailsPanel.TradingStatus.descr': '取引ステータス、利用可能な値：オープン、クローズ、取引停止',
    'InstrumentDetailsPanel.Underlier.descr': '配信されるアセットの名前。オプションと先物で利用可能。',
    'InstrumentDetailsPanel.VAT.descr': '各手数料オペレーションから支払われる消費税',
    'InstrumentDetailsPanel.Volume.descr': '料金は各取引のボリュームに基づいて計算されます',
    'InstrumentDetailsPanel.Yield.descr': '現在の利回り値',
    'panel.accounts.AccountValue.descr': 'アカウントの合計値',
    'panel.accounts.Asset.descr': '資産名',
    'panel.accounts.AvailableMargin.descr': '当口座にて利用可能な資金',
    'panel.accounts.Balance.descr': '取引口座の残高',
    'panel.accounts.BalancePlusAllRisks.descr': '有効評価額(株式除外)',
    'panel.accounts.BeginBalance.descr': '今日の純利益/損失',
    'panel.accounts.BlockedByOrders.descr': '注文のロックされたマージン',
    'panel.accounts.BlockedByPamm.descr': '資金に投資した資本を開始',
    'panel.accounts.CashBalance.descr': '現金残高',
    'panel.accounts.ClientType.descr': '最後に接続したアカウント所有者 ( .Net, Java, Mobile, Html)',
    'panel.accounts.CreditValue.descr': '取引に利用可能なクレジット資金',
    'panel.accounts.CurBalance.descr': '有効証拠金 (残高+未実現損益)',
    'panel.accounts.CurMargin.descr': '残高＆Allリスクに対する初期マージン要件',
    'panel.accounts.CurrentFundCapital.descr': '資金に投資された現在の資本',
    'panel.accounts.Email.descr': 'Eメールアドレス',
    'panel.accounts.Estimate value.descr': '他の資産から計算された価値',
    'panel.accounts.FundCapitalGain.descr': '現在の資金と開始資金の差額',
    'panel.accounts.FundsUsedPercent.descr': '残高＆Allリスクに使用される資金',
    'panel.accounts.InterestRate.descr': '預金の年利',
    'panel.accounts.Leverage.descr': '取引口座のレバレッジ',
    'panel.accounts.LockedBalance.descr': '出金がロックされた残高',
    'panel.accounts.LockedBy.descr': 'ブローカーによってロックされています（トレーダーのクオート依頼）',
    'panel.accounts.Login.descr': 'アカウント名',
    'panel.accounts.MaintMargin.descr': '残高＆Allリスクのメンテナンスマージンの要件',
    'panel.accounts.MarginAvailableReal.descr': '利用可能な証拠金',
    'panel.accounts.MarginRequirement.descr': 'すべてのオープンポジションのメンテナンス要件',
    'panel.accounts.MarginStopOut.descr': 'ポジションと注文の為、メンテナンスマージンがロックされました',
    'panel.accounts.MarginStopTrade.descr': '残高がこのレベル未満の場合、アカウントでは取引できません',
    'panel.accounts.MarginUsedNew.descr': 'ポジションと注文の初期マージンがロックされました',
    'panel.accounts.MarginWarning.descr': '残高がこのレベル未満の場合、警告マージンメッセージが表示されます',
    'panel.accounts.marginWarnPercent.descr': 'マージンコールレベル, %',
    'panel.accounts.MaxDailyLossLimit.descr': '1日の最大総損益額',
    'panel.accounts.MaxDailyProfit.descr': '1日の最大総利益額',
    'panel.accounts.MaxDrawdownLevel.descr': 'アカウントが持つことができる最小許容予測残高値を示します',
    'panel.accounts.MaximumLot.descr': '1注文あたりの最大取引数量',
    'InstrumentDetailsPanel.Account.descr': 'アカウント名',
    'panel.accounts.MaxOrderAmount.descr': '最大注文数',
    'panel.accounts.MaxOrderCapital.descr': '1注文あたりの最大利用限度額',
    'panel.accounts.MaxOrdersPerDay.descr': '1日あたりの最大注文可能数量',
    'panel.accounts.MaxPendingOrders.descr': '最大予約注文数',
    'panel.accounts.MaxPendingOrdersValue.descr': 'ユーザーが利用できる最大累積保留注文値',
    'panel.accounts.MaxPositionQtyPerSymbol.descr': '1取引あたりの最大注文数量',
    'panel.accounts.MaxPositions.descr': '最大ポジション数',
    'panel.accounts.MaxWeekLostLimit.descr': 'アカウントの週次の最大純損失値',
    'panel.accounts.MinMargin.descr': 'ポジションのマージンがロックされました',
    'panel.accounts.GrossPNL.descr': '手数料やスワップを含まない、本日の為替損益',
    'panel.accounts.OptionPremiumReq.descr': '買い注文のオプションプレミアム',
    'panel.accounts.OptionValue.descr': 'ポートフォリオのオプション現在価値',
    'panel.accounts.Orders.descr': '注文数',
    'panel.accounts.Owner.descr': '取引口座の所有者',
    'panel.accounts.Positions.descr': 'ポジション数',
    'panel.accounts.Profit.descr': 'すべてのオープンポジションの利益/損失',
    'panel.accounts.ProfitNet.descr': 'オープンポジションの純損益',
    'panel.accounts.Role.descr': '資金種別',
    'panel.accounts.Status.descr': 'アカウント所有者のユーザーステータス（オンライン、オフライン）',
    'panel.accounts.Collateral.descr': 'ショートポジションの現在の担保額',
    'panel.accounts.StocksOrdersReq.descr': '必要証拠金(株式)',
    'panel.accounts.StocksValue.descr': 'ポートフォリオの現在株価',
    'panel.accounts.stopOutPercent.descr': 'ロスカット時の維持証拠金率',
    'panel.accounts.stopTradingPercent.descr': '取引停止レベル、％',
    'panel.accounts.TodayFees.descr': 'アカウントによって支払われた手数料額',
    'panel.accounts.TodayRebates.descr': '当日の流動性の作成/除去のためにブローカーによって受領/支払われたすべての資金の合計',
    'panel.accounts.TodayTrades.descr': '本日の取引数',
    'panel.accounts.TodayTurnover.descr': '当日の累計入金額から累計出金額を差し引いた金額',
    'panel.accounts.TodayVolume.descr': '本日の取引量',
    'panel.accounts.TotalMaxPositionsQty.descr': 'ロット内のすべてのポジションの最大合計数量',
    'panel.accounts.TrailingDrawdownLevel.descr': 'トレーリングストップの最大ドローダウン数値',
    'panel.accounts.UnsettledCash.descr': '利益/損失。T+ nバリューサイクルに従ってアカウントに入金されます。',
    'panel.accounts.UserGroup.descr': 'ユーザーグループ',
    'panel.accounts.UserID.descr': 'ユーザーのID',
    'panel.accounts.UserLogin.descr': 'ユーザーがログインしている取引口座',
    'panel.accounts.WithdrawalAvailable.descr': '当口座にて出金できる最大金額',
    'panel.accounts.CurrentDailyLoss.descr': '現時点の日次総損失限度額',
    'panel.accounts.CurrentWeeklyLoss.descr': '現時点の週次総損失限度額',
    'panel.accounts.UnrealizedLoss.descr': 'アカウントの現在の未実現損失額',
    'panel.accounts.UnrealizedLossLimit.descr': 'アカウントの未実現損失の最大値',
    'panel.accounts.VolumeLimitForEquities.descr': 'アカウントの商品タイプ別の全ポジションと注文の最大サマリー量。値は、ロット/契約、金額（数量*ロットサイズまたは数量*ティックコスト/ティックサイズ）、またはマネーエクスポージャーで設定されます',
    'panel.accounts.VolumeLimitForFutures.descr': 'アカウントの商品タイプ別の全ポジションと注文の最大サマリーボリューム。値は、ロット/契約、金額（数量*ロットサイズまたは数量*ティックコスト/ティックサイズ）、またはマネーエクスポージャーで設定されます',
    'panel.accounts.VolumeLimitForOptions.descr': 'アカウントの商品タイプ別の全ポジションと注文の最大サマリーボリューム。値は、ロット/契約、金額（数量*ロットサイズまたは数量*ティックコスト/ティックサイズ）、またはマネーエクスポージャーで設定されます',
    'panel.accountDetails.Rico risks.BMFL': 'BMFリミット',
    'panel.accountDetails.Rico risks.BUOP': '残高カバーされていない操作',
    'panel.accountDetails.Rico risks.CED0': 'クレジット記入0日目',
    'panel.accountDetails.Rico risks.CED1': 'クレジット記入1日目',
    'panel.accountDetails.Rico risks.CED2': 'クレジット記入2日目',
    'panel.accountDetails.Rico risks.CED3': 'クレジット記入3日目',
    'panel.accountDetails.Rico risks.CRLM': 'クレジットリミット',
    'panel.accountDetails.Rico risks.CRLO': 'クレジットリミットオプション',
    'panel.accountDetails.Rico risks.CULM': '現在リミット',
    'panel.accountDetails.Rico risks.D1PB': 'D1有効評価額',
    'panel.accountDetails.Rico risks.D1PD': 'D1有効評価日',
    'panel.accountDetails.Rico risks.D2PD': 'D2有効評価日',
    'panel.accountDetails.Rico risks.D3PD': 'D3有効評価日',
    'panel.accountDetails.Rico risks.DED0': 'デビット記入日0日目',
    'panel.accountDetails.Rico risks.DED1': 'デビット記入日1日目',
    'panel.accountDetails.Rico risks.DED2': 'デビット記入日2日目',
    'panel.accountDetails.Rico risks.DED3': 'デビット記入日3日目',
    'panel.accountDetails.Rico risks.DLBL': '日次流動性残高',
    'panel.accountDetails.Rico risks.DLMT': '日次リミット',
    'panel.accountDetails.Rico risks.EBVL': '購入価格執行',
    'panel.accountDetails.Rico risks.ESVL': '売却価格執行',
    'panel.accountDetails.Rico risks.FBBL': '最終BMF残高',
    'panel.accountDetails.Rico risks.FNBL': '最終残高',
    'panel.accountDetails.Rico risks.FNLE': 'レバレッジ',
    'panel.accountDetails.Rico risks.INBA': '初期残高',
    'panel.accountDetails.Rico risks.INBL': '初期残高',
    'panel.accountDetails.Rico risks.LEVE': 'レバレッジ',
    'panel.accountDetails.Rico risks.LILO': 'レバレッジオプションリミット',
    'panel.accountDetails.Rico risks.LVLM': 'レバレッジリミット',
    'panel.accountDetails.Rico risks.OPBL': 'オプション残高',
    'panel.accountDetails.Rico risks.OVLT': '貸越残高',
    'panel.accountDetails.Rico risks.PBVL': '保留中購入額',
    'panel.accountDetails.Rico risks.POVA': '潜在的貸越額',
    'panel.accountDetails.Rico risks.PRBA': '予測残高',
    'panel.accountDetails.Rico risks.PSVL': '保留中の売却額',
    'panel.accountDetails.Rico risks.SKBL': '株式残高',
    'panel.accountDetails.Rico risks.WDBL': '引出残高',
    'panel.accountDetails.Kyrrex risks.AvailableBalance': '余剰残高',
    'panel.accountDetails.Kyrrex risks.BlockedBalance': '保留金',
    'panel.accountDetails.Kyrrex risks.AvailableBalance.descr': '余剰残高',
    'panel.accountDetails.Kyrrex risks.BlockedBalance.descr': '保留金',
    'panel.accountDetails.Groups.Cedro risks': 'アカウント詳細',
    'panel.accountDetails.Cedro risks.SLDTL': '合計残高',
    'panel.accountDetails.Cedro risks.UNQL': '合計リミット',
    'Order.OrderStatus.STATUS_UNKNOWN': '不明',
    'Order.OrderStatus.STATUS_PENDING_NEW': '新規待ち',
    'Order.OrderStatus.STATUS_NEW': '受付',
    'Order.OrderStatus.STATUS_ACCEPTED': '受付',
    'Order.OrderStatus.STATUS_PENDING_EXECUTION': '約定待ち',
    'Order.OrderStatus.STATUS_REPLACED': '変更',
    'Order.OrderStatus.STATUS_PENDING_CANCEL': '取消待ち',
    'Order.OrderStatus.STATUS_PART_FILLED': '一部約定',
    'Order.OrderStatus.STATUS_FILLED': '約定',
    'Order.OrderStatus.STATUS_PENDING_REPLACE': '変更待ち',
    'Order.OrderStatus.STATUS_WAITING_MARKET': 'マーケット待ち',
    'Order.OrderStatus.STATUS_OFF_MARKET': '取引時間外',
    'Order.OrderStatus.STATUS_UNPLACED': '不成立',
    'pageNavigation.nextButton': '次へ',
    'pageNavigation.backButton': '戻る',
    'pageNavigation.doneButton': '完了',
    'onBoarding.slide1.header1': 'ワークスペース',
    'onBoarding.slide1.text1': 'ワークスペースでは、ユーザーの取引環境に合わせてチャートや各種パネルを配置することができ、複数のワークスペースを活用することで、一つのプラットフォームに複数のレイアウトを設定することができます。ワークスペースは、リアルタイムに自動保存されます。',
    'onBoarding.slide2.header1': '操作メニューを表示',
    'onBoarding.slide2.text1': '複製 - ワークスペースの複製をすることができますワークスペース名の変更 - ワークスペース名を変更できます',
    'onBoarding.slide2.text2': 'ワークスペース名をダブルクリックすることで、ワークスペース名を変更することができます',
    'onBoarding.slide3.header1': 'ワークスペースのロック',
    'onBoarding.slide3.header2': 'ワークスペースを閉じる',
    'onBoarding.slide3.header3': '新しいワークスペースを作成する',
    'onBoarding.slide3.text1': 'ワークスペースがロックされている場合、新しいパネルを表示することや、パネルを閉じたりすることができません',
    'onBoarding.slide3.text2': '「✕」をクリックすると、ワークスペースを閉じることができます',
    'onBoarding.slide3.text3': '「＋」をクリックすると、新しいワークスペースを作成することができます',
    'onBoarding.slide4.header1': '銘柄リンク',
    'onBoarding.slide4.text1': '同じ色のラベルを付与すると、銘柄を切替時、同じ色のラベルを付与したパネル内の銘柄を連動させることができます。',
    'onBoarding.slide5.header1': 'マウス操作による注文',
    'onBoarding.slide5.text1': 'マウスのクリック操作で、(逆)指値注文や、S/L、T/P、ストップリミット注文、OCO注文ができます。',
    'onBoarding.slide5.text2': 'マウス操作による注文時、表示されたウィジェットにて、取引数量や注文の有効期限等の設定を行うことができます',
    'onBoarding.slide5.text3': 'ドラッグ＆ドロップ操作で、(逆)指値注文や、S/L、T/P、ストップリミット注文、OCO注文の予約内容の変更ができます',
    'onBoarding.slide7.header1': 'ドッキングシステム',
    'onBoarding.slide7.text1': 'ドラッグ＆ドロップ操作で、ワークスペース上でパネルを自由に動かすことができます',
    'onBoarding.slide8.header1': 'シンボル情報',
    'onBoarding.slide8.text1': 'ウォッチリストの「i」をクリックすると、シンボル情報を確認することができます',
    'onBoarding.slide9.header1': 'アカウント詳細',
    'onBoarding.slide9.text1': 'クリック後、アカウント詳細の一覧より、希望する項目を選択し、表示する位置にドラッグ＆ドロップすることで、アカウント詳細の2つの表示項目をカスタマイズすることができます',
    'onBoarding.slide10.header1': 'パネルリンク',
    'onBoarding.slide10.text1': 'アカウントによるパネルのリンクを有効にできます',
    'onBoarding.slide10.text2': 'アカウントで注文と位置のフィルタリングを有効にできます',
    'onBoarding.slide11.header1': 'ヘルプ',
    'onBoarding.slide11.header2': '一般設定',
    'onBoarding.slide11.text1': '「ヘルプ」をクリックすると、ヘルプサイトにアクセスします',
    'onBoarding.slide11.text2': '「設定」をクリックすると、一般設定ウインドウが表示されます',
    'onBoarding.slide12.header1': '完了です',
    'IndicatorGroup.Moving Average': 'Moving Average',
    MD: 'MD',
    REGRESSION: 'REGRESSION',
    Regression: 'Regression',
    SMMA: 'SMMA',
    PPMA: 'PPMA',
    SMA: 'SMA',
    EMA: 'EMA',
    'IndicatorGroup.Oscillators': 'Oscillators',
    MACD: 'MACD',
    BB: 'BB',
    BBF: 'BBF',
    'IndicatorGroup.Volume': 'Volume',
    RSI: 'RSI',
    PO: 'PO',
    CCI: 'CCI',
    Aroon: 'Aroon',
    KRI: 'KRI',
    'Kairi Relative Index': 'Kairi Relative Index',
    PPO: 'PPO',
    'Percentage Price Oscillator': 'Percentage Price Oscillator',
    RLW: 'RLW',
    '%R Larry Williams': '%R Larry Williams',
    ROC: 'ROC',
    'Rate of Change': 'Rate of Change',
    QStick: 'QStick',
    TSI: 'TSI',
    'True Strength Index': 'True Strength Index',
    'Pivot point': 'Pivot point',
    SI: 'SI',
    ALLIGATOR: 'ALLIGATOR',
    MMA: 'MMA',
    'IndicatorGroup.Trend': 'Trend',
    STOCHASTIC: 'STOCHASTIC',
    OBV: 'OBV',
    'IndicatorGroup.Volatility': 'Volatility',
    ATR: 'ATR',
    CMO: 'CMO',
    SD: 'SD',
    SAR: 'SAR',
    ADX: 'ADX',
    'IndicatorGroup.Channels': 'Channels',
    KELTNER: 'KELTNER',
    CHANNEL: 'CHANNEL',
    MAE: 'MAE',
    LWMA: 'LWMA',
    MAS3: 'MAS3',
    'IndicatorGroup.Extra': 'Extra',
    Didi: 'Didi',
    HiloEscandina: 'HiloEscandina',
    StopATR: 'StopATR',
    Fractals3: 'Fractals3',
    Fractals5: 'Fractals5',
    'Kaufman adaptive moving average': 'Kaufman adaptive moving average',
    'Multi EMA': 'Multi EMA',
    'Self-Adjusting Alpha with Fractals Energy': 'RSI Self-Adjusting Alpha with Fractals Energy',

    'Web.mobile.active.WorkingOrders': '予約',
    'Web.mobile.active.WorkingOrders.EmptyPlaceholder': '注文がありません',
    'Web.mobile.active.FilledOrders': '約定済み',
    'Web.mobile.active.HistoryOrders': '履歴',
    'Web.mobile.active.Positions': 'ポジション',
    'Web.mobile.active.Positions.EmptyPlaceholder': 'ポジションがありません',
    'Web.mobile.active.Assets': '資産',
    'Web.mobile.active.Orders': '注文',
    'Web.mobile.active.More': '詳細',
    'Web.mobile.active.MamSummary': 'MAMサマリー',
    'Web.mobile.active.OptionChain': 'オプションチェーン',
    'Web.mobile.active.Alerts.NoAlerts': 'アラートなし',
    'Web.mobile.active.EventLog.NoEvents': 'イベントなし',
    'Web.mobile.active.EventLog.DateTime': '日時',
    'Web.mobile.active.MamSummary.Column.OperationType': 'オペレーションタイプ',
    'Web.mobile.active.MamSummary.Column.Amount': '金額',
    'Web.mobile.active.More.Header': '詳細',
    'Web.mobile.active.Settings.Defaults': 'デフォルト',
    'Web.mobile.active.Settings.QuantityInLots': 'ロット単位の数量',
    'Web.mobile.active.Settings.SetSlTpInOffset': 'オフセットでSL/TPを設定',
    'Web.mobile.active.Settings.OrderSending': '注文の送信',
    'Web.mobile.active.Settings.OrderModifying': '注文の変更',
    'Web.mobile.active.Settings.OrderExecuting': '注文の実行',
    'Web.mobile.active.Settings.OrderCancelling': '注文のキャンセル',
    'Web.mobile.active.Settings.PositionModifying': 'ポジションの変更',
    'Web.mobile.active.Table.Total.Profit': '合計利益',
    'Web.mobile.active.NewTrades.PnL': 'P/L',
    'Web.mobile.active.OrderBook.ExecutionType': '実行タイプ',
    'Web.mobile.active.Assets.EmptyPlaceholder': '資産がありません',
    'Web.mobile.active.Assets.DefSymbol': 'デフォルトシンボル',
    'Web.mobile.active.Assets.SODQuantity': 'SOD数量',
    'Web.mobile.active.Assets.CurrentValueForSale': '売却用の現在値',
    'Web.mobile.active.Positions.Profit': 'P/L',
    'Web.mobile.active.AccountDetails.LinkingTooltip': 'この機能は、アカウント別にポジションと注文をフィルタリングします。アカウントの切り替えは、すべてのパネルで同期的に行われます。',
    'Web.mobile.active.Watchlist.EmptyPlaceholder': 'シンボルがありません',
    'Web.mobile.active.Watchlist.ListNamePlaceholder': 'ここに名前を入力',
    'Web.mobile.active.Watchlist.Added': 'ウォッチリストに追加',
    'Web.mobile.active.Watchlist.Removed': 'ウォッチリストから削除',
    'Web.mobile.active.Watchlist.MaxReached': '最大数に達しました',
    'Web.mobile.active.ExchangesFilter.Filter': 'フィルタ',
    'Web.mobile.active.OrderEntry.Send': '注文を送信',
    'Web.mobile.active.OrderEntry.Price': '価格',
    'Web.mobile.active.OrderEntry.TrStop': 'トレイリングストップ',
    'Web.mobile.active.OrderEntry.SlPrice': 'ストップロス価格',
    'Web.mobile.active.OrderEntry.SlOffset': 'ストップロスオフセット',
    'Web.mobile.active.OrderEntry.TrSlOffset': 'トレイリングストップオフセット',
    'Web.mobile.active.OrderEntry.SllPrice': 'ストップロスリミット価格',
    'Web.mobile.active.OrderEntry.SllOffset': 'ストップロスリミットオフセット',
    'Web.mobile.active.OrderEntry.TpPrice': 'テイクプロフィット価格',
    'Web.mobile.active.OrderEntry.TpOffset': 'テイクプロフィットオフセット',
    'Web.mobile.active.Login.ExploreTheApp': 'アプリの探索',
    'Web.mobile.active.InstrumentInfo.Price': '価格',
    'Web.mobile.active.InstrumentInfo.BreakEven': 'ブレークイーブン',
    'Web.mobile.active.Asset.SellExchange': '取引所',
    'Web.mobile.active.Asset.StartDayQtyMargin': 'MA用SOD数量',
    'Web.mobile.active.Asset.LiquidityRate': '流動性率%',
    'Web.mobile.active.Asset.TodayTradedQty': '当日取引数量',
    'Web.mobile.active.Asset.AvailableQtySell': '利用可能数量',
    'Web.mobile.active.FilledOrder.TradeVolume': '取引量',
    'Web.mobile.active.FilledOrder.Date': '日付',
    'Web.mobile.active.FilledOrder.GrossPnL': 'スポット損益',
    'Web.mobile.active.HistoryOrder.Date': '日付',
    'Web.mobile.active.MamSummary.Account': 'アカウント',
    'Web.mobile.active.MamSummary.OperationId': 'オペレーションID',
    'Web.mobile.active.MamSummary.InstrumentName': '銘柄名',
    'Web.mobile.active.MamSummary.OpenPrice': 'オープン価格',
    'Web.mobile.active.MamSummary.ClosePrice': 'クローズ価格',
    'Web.mobile.active.MamSummary.Side': 'サイド',
    'Web.mobile.active.MamSummary.Quantity': '数量',
    'Web.mobile.active.MamSummary.Buy': '買い',
    'Web.mobile.active.MamSummary.Sell': '売り',
    'Web.mobile.active.Position.StopLoss': 'ストップロス',
    'Web.mobile.active.Position.TakeProfit': 'テイクプロフィット',
    'Web.mobile.active.Position.Date': '日付',
    'Web.mobile.active.Order.StopLoss': 'ストップロス',
    'Web.mobile.active.Order.TrStopOffset': 'Trailing SL offset',
    'Web.mobile.active.Order.TrStopLoss': 'SL Trailing stop',
    'Web.mobile.active.Order.TakeProfit': 'テイクプロフィット',
    'Web.mobile.active.Order.QuantityFilled': 'Qty. filled',
    'Web.mobile.active.Search': '検索',
    'Web.mobile.active.CalendarPeriod.Today': '今日',
    'Web.mobile.active.CalendarPeriod.LastWeek': '先週',
    'Web.mobile.active.CalendarPeriod.Last1M': '最後の1か月',
    'Web.mobile.active.CalendarPeriod.Last3M': '最後の3か月',
    'Web.mobile.active.CalendarPeriod.Last6M': '最後の6か月',
    'Web.mobile.active.InstrumentDetails.6.SessionInfo': 'スケジュール',
    'Web.mobile.active.OrderModify.Confirmation.Title.Modify': '注文の変更',
    'Web.mobile.active.OrderModify.Confirmation.Title.Cancel': '注文のキャンセル',
    'Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket': '市場で注文を実行しますか？',
    'Web.mobile.active.OrderModify.Error.SLSmallerMin': 'ストップロスが最小値未満です',
    'Web.mobile.active.OrderModify.Error.SLGreaterMax': 'ストップロスが最大値を超えています',
    'Web.mobile.active.OrderModify.Error.TPSmallerMin': 'テイクプロフィットが最小値未満です',
    'Web.mobile.active.OrderModify.Error.TPGreaterMax': 'テイクプロフィットが最大値を超えています',
    'Web.mobile.active.PositionModify.Confirmation.Title': 'ポジションの変更',
    'Web.mobile.active.PositionClose.Confirmation.Lots': 'ロット',
    'Web.mobile.active.Lists': 'リスト',
    'Web.mobile.active.AccountDetails.Groups.1.AccountSummary': '口座の概要',
    'Web.mobile.active.AccountDetails.Groups.4.TodayResult': '本日の結果',
    'Web.mobile.active.AccountDetails.MarginUsedPercent': '初期証拠金要件 %',
    'Web.mobile.active.AccountDetails.OptionPremiumReq': 'オプションプレミアム要件',
    'Web.mobile.active.AccountDetails.OpenGrossPL': 'オープン総利益/損失',
    'Web.mobile.active.AccountDetails.OpenPostionsNumber': 'オープンポジション数',
    'Web.mobile.active.AccountDetails.OpenOrdersNumber': '稼働中の注文数',
    'Web.mobile.active.AccountDetails.TodaysNetProfit': '本日の純利益',
    'Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators': '追加されたインジケータ',
    'Web.mobile.active.Chart.Menu.ChartSyle': 'チャートスタイル',
    'Web.mobile.active.Chart.Menu.Settings': '設定',
    'Web.mobile.active.Chart.NoDataAvailable': 'No data available for {0}',
    'Web.mobile.active.TrailingStopLoss': 'Tr.',
    'Web.mobile.active.Position.GrossPnL': 'スポット損益',
    'Web.mobile.active.signIn.error.emptyLogin': 'ログインIDが未入力です',
    'Web.mobile.active.signIn.error.emptyPassword': 'パスワードが未入力です',
    'Web.mobile.active.Table.Total.GrossPnL': 'Total Gross P/L',
    'ChartObjectManager.Text': 'オブジェクト・マネージャー',
    'ObjectManagerItem.Name': '名前',
    'ObjectManagerItem.Remove': '削除',
    'ObjectManagerItem.Settings': '設定',
    'ObjectManagerItem.Show on chart': 'チャート上に表示する',
    'ObjectManagerItem.Visible': '表示',
    'ObjectManagerItem.Window': 'ウィンドウ',
    'ChartObjectManager.mainWindow': 'メイン・ウィンドウ',
    'ChartObjectManager.subWindow': 'サブ・ウィンドウ',
    'chart.menu.ObjectManager.RemoveAll': 'すべて削除する',
    'chart.menu.ObjectManager.RemoveSelected': '選択されたものを削除する'
};
