// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MathUtils } from '@shared/utils/MathUtils';
import { Intervals } from '@shared/utils/Instruments/Intervals';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { Resources } from '@shared/localizations/Resources';
import { SlTpPriceType } from '@shared/utils/Enums/Constants';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { SLTPTriggerUtils } from './SLTPTriggerUtils';
import { SLTPTrigger } from '@shared/utils/SlTpTrigger';
import { OffsetModeViewEnum } from '@shared/utils/Trading/OffsetModeViewEnum';
import { SlTpNumericInputParameters, SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { ProfitCalculator } from '../ProfitCalculator';
import { NumericUtils } from '@shared/utils/NumericUtils';
import { IsAllowed } from '../../IsAllowed';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { SlTpHolder } from '@shared/utils/Trading/SlTpHolder';
import { SLTPDynProperty } from '../../SLTPDynProperty';
import { type Instrument } from '../Instrument';
import { SLTPError, SLTPErrorType } from './SLTPError';
import { OrderSlTpBase } from './OrderBrackets/OrderSlTpBase';
import { StopLoss } from './OrderBrackets/StopLoss';
import { TrailingStop } from './OrderBrackets/TrailingStop';
import { BaseSettings } from '../../Settings/BaseGeneralSettingsWrapper';
import { type Account } from '../Account';
import { type ProductType } from '@shared/utils/Instruments/ProductType';

export class SLTPEdit {
    private readonly _mode: SLTPMode;
    private _instrument: Instrument | null | undefined;
    private _sltpHolder: SlTpHolder | null | undefined;

    public get instrument (): Instrument | null | undefined { return this._instrument; }
    public set instrument (value: Instrument | null | undefined) {
        this._instrument = value;
        this.updateOffsetTypes();
    }

    public get sltpHolder (): SlTpHolder { return this._sltpHolder; }
    public set sltpHolder (value: SlTpHolder) {
        this._sltpHolder = value;
        this.updateOffsetTypes();
    }

    public DataCache: any;
    public sl: StopLoss = new StopLoss();
    public tp: OrderSlTpBase = new OrderSlTpBase();
    public trailingStop: TrailingStop = new TrailingStop();
    public trailingStopAllowed: boolean = false;
    public allowedSL: boolean = true;
    public allowedTP: boolean = true;
    public needSetDefaultSLTP: boolean = false;
    public sllValueEnabled: boolean = false; // #91413
    public sltpTriggerShort: any = null; // #109798
    public nonEditableSLTPTriggerShort: any = null; // #110640 <- #109798
    public SkipChange: boolean = false;
    IsActivated: any;

    constructor (dataCache, mode: SLTPMode) {
        this.DataCache = dataCache;
        this._mode = !isNullOrUndefined(mode) ? mode : SLTPMode.None;
        this.getSLError = this.getSLError.bind(this);
        this.getTPError = this.getTPError.bind(this);
        this.updateOffsetTypes();
    }

    public valid (): any {
        const slErr = this.sl.enabled && this.sl.error && !this.IsActivated; // #93540 SL может быть disabled при этом являтся валидным
        const tpErr = this.tp.enabled && this.tp.error;

        const trailingStopErr =
            this.sl.enabled &&
            this.trailingStopAllowed &&
            this.trailingStop.enabled &&
            this.trailingStop.error;

        return /* this.allowed && */ !slErr && !tpErr && !trailingStopErr;
    }

    // TODO. Rename?
    public useTrailingStop (): boolean {
        return this.allowedSL &&
            this.sl.enabled &&
            this.trailingStopAllowed && this.trailingStop.enabled;
    }

    // TODO. Rename?
    public useSL (): boolean {
        return this.allowedSL &&
            this.sl.enabled &&
            (!this.trailingStopAllowed || !this.trailingStop.enabled);
    }

    // TODO. Rename?
    public useTP (): boolean {
        return this.allowedTP && this.tp.enabled;
    }

    // #region DynProperty

    public getDynProperty (tradingData): any {
        const instrument: Instrument = tradingData.instrument;
        const isBuy = tradingData.side === OperationType.Buy;
        const OpenPrice = this.getPrice(tradingData);
        const secondOpenPrice = this.getSecondPrice(tradingData);
        const dp = new SLTPDynProperty();

        dp.trailingStopAllowed = this.trailingStopAllowed;
        dp.allowed = this.allowedSL || this.allowedTP;
        dp.visible = this.allowedSL || this.allowedTP;

        dp.allowedSL = this.allowedSL;
        dp.allowedTP = this.allowedTP;

        dp.sl = this.getDynProperty_SLTPPart(this.sl, instrument, OpenPrice, isBuy);
        dp.tp = this.getDynProperty_SLTPPart(this.tp, instrument, OpenPrice, isBuy);
        dp.trailingStop = this.getDynProperty_SLTPPart(this.trailingStop, instrument, OpenPrice, isBuy);

        if (dp.trailingStop && tradingData.trailingStop) {
            dp.trailingStop.offsetValue = tradingData.trailingStop.value;
        }

        if (!dp.sl.enabled && !dp.tp.enabled) {
            return dp;
        }

        let Qty = 0;
        const account = tradingData.account;
        const orderObject = tradingData.position || tradingData.order;
        const orderType = tradingData.OrderType;
        dp.OrderType = orderType;

        dp.sllValueEnabled = orderObject // если есть orderObject -> то это modify, смотрим по наличию значения, иначе это OE -> по настройке из сеттингов
            ? (orderObject.isPosition && orderObject.SLOrder ? orderObject.SLOrder.StopLimit > 0 : dp.sl.sllValue != null)
            : BaseSettings.isUseStopLimitInsteadStop();

        if (!dp.sllValueEnabled) dp.sl.sllValue = null;

        if (this.DataCache.AllowCustomSLTPTriggerPriceForUser()) {
            if (orderObject) // если есть orderObject -> то это modify
            {
                dp.nonEditableSLTPTriggerShort = SLTPTriggerUtils.GetShortByOrder(orderObject) || // #110640 <- #109798
                    SLTPTriggerUtils.GetTriggersShortForInstrument(instrument);
            } // #110651 <- #109798
            else {
                const canSelectInOE = this.CanSelectSLTPTriggerInOE();
                if (canSelectInOE) {
                    dp.sltpTriggerShort = this.sltpTriggerShort !== null
                        ? this.sltpTriggerShort
                        : SLTPTriggerUtils.GetTriggersShortForInstrument(instrument);
                } else if (canSelectInOE !== null) // если === null -> не разрешено на юзере
                {
                    dp.nonEditableSLTPTriggerShort = SLTPTriggerUtils.GetTriggersShortForInstrument(instrument);
                }
            }
        }

        if (orderObject?.SLOrder) {
            const slOrder = orderObject.SLOrder;
            const activated = slOrder.IsActivated && !dp.trailingStop.enabled;

            this.IsActivated = activated;
            dp.sl.canEdit = !activated; // #93540
            // dp.sl.error = null            // #93646  //#111002
        }

        if (tradingData.quantity !== null) {
            Qty = Quantity.toLots(tradingData.quantity, instrument);
        }

        if (instrument !== null && account !== null && Qty !== null) {
            const side = isBuy ? OperationType.Buy : OperationType.Sell;
            const crossPrice = this.DataCache.CrossRateCache.GetCrossPriceInsSideExp2(instrument, side, account.assetBalanceDefault.Asset.Name);
            const openCross = orderObject?.isPosition ? orderObject.OpenCrossPrice : crossPrice; // #107999
            const closePrice = orderObject?.isPosition ? orderObject.CurPriceClose : OpenPrice;
            const orderParameters = new OrderParameters(instrument, account, isBuy, orderType, OpenPrice, closePrice, Qty, openCross, orderObject?.ProductType, secondOpenPrice);

            const isSlEnabled: boolean = dp.sl.enabled;
            if (isSlEnabled) {
                const isTrailingStopEnabled: boolean = dp.trailingStop.enabled;
                const inOffset = isTrailingStopEnabled || dp.sl.inOffset;
                const offsetType = isTrailingStopEnabled ? dp.trailingStop.offsetType : dp.sl.offsetType;
                let slValue = isTrailingStopEnabled ? dp.trailingStop.value : dp.sl.value;
                if (isValidNumber(dp.sl.sllValue)) {
                    const isOffset: boolean = dp.sl.inOffset;
                    slValue = isOffset ? slValue + dp.sl.sllValue : dp.sl.sllValue;
                }
                const slTpParameters = new SlTpParameters(true, isTrailingStopEnabled, inOffset, offsetType, slValue);
                const profit = this.CalculateSlTpProfit(orderParameters, slTpParameters);
                dp.slLabel = profit.offsetLabel;
                dp.slProfit = profit.profitLabel;
                dp.slProfitValue = profit.profitValue;
            }

            const isTpEnabled: boolean = dp.tp.enabled;
            if (isTpEnabled) {
                const slTpParameters = new SlTpParameters(false, false, dp.tp.inOffset, dp.tp.offsetType, dp.tp.value);
                const profit = this.CalculateSlTpProfit(orderParameters, slTpParameters);
                dp.tpLabel = profit.offsetLabel;
                dp.tpProfit = profit.profitLabel;
                dp.tpProfitValue = profit.profitValue;
            }
        }

        return dp;
    }

    public CanSelectSLTPTriggerInOE (): any {
        return this.DataCache.AllowCustomSLTPTriggerPriceForUser() ? BaseSettings.slTpTriggers.SelectSLTPTriggerInOE : null;
    }

    private getPrice (tradingData: any): number {
        const orderObject = tradingData.position || tradingData.order;
        let price: number = NaN;
        if (isValidNumber(tradingData.limitPrice)) {
            price = tradingData.limitPrice;
        } else if (isValidNumber(tradingData.stopPrice)) {
            price = tradingData.stopPrice;
        } else if (!isNullOrUndefined(orderObject)) {
            if (orderObject.OrderType === OrderType.TrailingStop) {
                price = orderObject.CurPriceOpen;
            } else {
                price = orderObject.Price;
            }
        }
        const instrument: Instrument = tradingData.instrument;
        const account: Account = tradingData.account;
        if (!isNullOrUndefined(instrument) && !isNullOrUndefined(account)) {
            const sp = this.DataCache.GetSpreadPlan(account);
            const quote = tradingData.quote;
            if (isNaN(price) && quote !== null) {
                const isBuy = tradingData.side === OperationType.Buy;
                price = isBuy ? quote.AskSpread_SP_Ins(sp, instrument) : quote.BidSpread_SP_Ins(sp, instrument);
                if (tradingData.OrderType === OrderType.TrailingStop && isValidNumber(tradingData.trailingStop?.value)) {
                    price = instrument.CalculatePrice(price, tradingData.trailingStop.value * (isBuy ? 1 : -1));
                }
            }
        }
        if (isNaN(price)) {
            price = 0;
        }
        return price;
    }

    private getSecondPrice (tradingData: any): number {
        if (tradingData.OrderType !== OrderType.OCO) { return NaN; }
        return isValidNumber(tradingData.stopPrice) ? tradingData.stopPrice : NaN;
    }

    private CalculateSlTpProfit (orderParameters: OrderParameters, slTpParameters: SlTpParameters): { offsetLabel: string, profitLabel: string, profitValue: number } {
        const sign = slTpParameters.isSl ? (orderParameters.isBuy ? -1 : 1) : (orderParameters.isBuy ? 1 : -1);
        const isOffset: boolean = slTpParameters.isOffset;
        const offsetType: OffsetModeViewEnum = slTpParameters.offsetType;
        const value = slTpParameters.value;
        let closePrice: number;
        let offsetTicks: number;
        if (isOffset) {
            const offsetPrice = slTpParameters.isSl && slTpParameters.isTrailingStop && orderParameters.orderType === OrderType.PositionEdit ? orderParameters.closePrice : orderParameters.openPrice;
            if (offsetType === OffsetModeViewEnum.Percent) {
                offsetTicks = SlTpUtils.precentToTicks(orderParameters.isBuy, slTpParameters.isSl, offsetPrice, value, orderParameters.instrument);
            } else {
                offsetTicks = SlTpUtils.toRealValue(value, orderParameters.instrument, offsetType);
            }
            closePrice = orderParameters.instrument.CalculatePrice(offsetPrice, offsetTicks * sign, true);
        } else {
            const closeAbsoluteOffset = value - orderParameters.openPrice;
            closePrice = value;
            offsetTicks = orderParameters.instrument.CalculateTicks(orderParameters.openPrice, closeAbsoluteOffset, true);
        }
        const profitValue = ProfitCalculator.CalculateSLTP(orderParameters.quantity, closePrice, orderParameters.openPrice, orderParameters.instrument, orderParameters.account, orderParameters.isBuy, orderParameters.openCross, orderParameters.productType);
        const profitFormattedValue = orderParameters.account.roundFormatPrice(profitValue);

        let profitLabel: string;
        let offsetLabel: string;

        if (orderParameters.orderType !== OrderType.OCO) {
            const percent = isOffset && offsetType === OffsetModeViewEnum.Percent ? slTpParameters.value : SlTpUtils.calculatePercent(orderParameters.openPrice, closePrice);
            const offsetPrecentLabel = `${SlTpUtils.formatSLTPValue(percent, orderParameters.instrument, OffsetModeViewEnum.Percent)}${SlTpUtils.getSymbolForOffsetMode(OffsetModeViewEnum.Percent)}`;
            profitLabel = `${profitFormattedValue} (${offsetPrecentLabel})`;
            offsetLabel = isOffset ? orderParameters.instrument.formatPrice(closePrice) : SlTpUtils.formatSLTPValue(offsetTicks, orderParameters.instrument, BaseSettings.offsetType);
        } else {
            const stopPartOpenPrice = orderParameters.secondOpenPrice;
            const stopPartClosePrice = orderParameters.instrument.CalculatePrice(stopPartOpenPrice, offsetTicks * sign, true);
            const stopPartProfitValue = ProfitCalculator.CalculateSLTP(orderParameters.quantity, stopPartClosePrice, stopPartOpenPrice, orderParameters.instrument, orderParameters.account, orderParameters.isBuy, orderParameters.openCross, orderParameters.productType);
            const stopPartProfitFormattedValue = orderParameters.account.roundFormatPrice(stopPartProfitValue);
            profitLabel = profitFormattedValue + '/' + stopPartProfitFormattedValue;
            offsetLabel = '';
        }
        return { offsetLabel, profitLabel, profitValue };
    }

    // TODO. Rename.
    public getDynProperty_SLTPPart (srcPart: OrderSlTpBase | StopLoss | TrailingStop, instrument: Instrument, basePrice: number, isBuy: boolean): any {
        const isAbsolute = isNullOrUndefined(srcPart.offsetType);
        const isSl = srcPart instanceof StopLoss || srcPart instanceof TrailingStop;
        const dest: any = {};
        dest.enabled = srcPart.enabled;
        dest.error = srcPart.error;
        const sltpNumericInputParameters = new SlTpNumericInputParameters(instrument, srcPart.offsetType, basePrice, isBuy, isSl);
        const sltpNumericParameters = SlTpUtils.getSlTpNumericParameters(sltpNumericInputParameters);
        dest.minimalValue = sltpNumericParameters.min;
        dest.maximalValue = sltpNumericParameters.max;
        dest.decimalPlaces = sltpNumericParameters.precision;
        dest.increment = sltpNumericParameters.step;
        dest.value = isValidNumber(srcPart.value) ? srcPart.value : dest.minimalValue;
        dest.canEdit = true;
        if (srcPart instanceof StopLoss) {
            dest.sllValue = srcPart.sllValue;
        }
        if (isAbsolute) {
            dest.Intervals = Intervals.GetIntervalsFromInstrument(instrument, NumericUtils.MAXVALUE);
        }
        dest.inOffset = srcPart.offsetType !== null;
        dest.offsetType = srcPart.offsetType;
        return dest;
    }

    // #endregion DynProperty

    // TODO. Refactor.
    public getRawValue (tradingData): SlTpHolder {
        const ins = tradingData.instrument;
        const sltp = new SlTpHolder();

        if (this.useSL()) {
            const value = this.sl.value;
            const offsetType = this.sl.offsetType;
            const absolute = offsetType === null;
            sltp.StopLossPriceType = absolute ? SlTpPriceType.Absolute : SlTpUtils.getSLTPPriceType(offsetType);
            sltp.StopLossPriceValue = absolute ? value : SlTpUtils.toRealValue(value, ins, offsetType);

            const sllValue = this.sl.sllValue;
            if (sllValue != null) {
                sltp.StopLossLimitPriceValue = absolute ? sllValue : SlTpUtils.toRealValue(sllValue, ins, offsetType);
            }
            sltp.SLTPTriggerShortValue = this.sltpTriggerShort || this.nonEditableSLTPTriggerShort;
        }

        if (this.useTrailingStop()) {
            const value = this.trailingStop.value;
            sltp.StopLossPriceType = SlTpPriceType.TrOffset;
            sltp.StopLossPriceValue = SlTpUtils.toRealValue(value, ins, this.trailingStop.offsetType);

            this.trailingStop.RealTrStopPrice = null;

            const ordType = tradingData.OrderType;
            const tq = tradingData.quote;
            const spP = this.DataCache.GetSpreadPlan(tradingData.account);
            const isBuy = tradingData.side === OperationType.Buy;
            let OpenPrice = NaN;
            // if (ordType === OrderType.Market)
            //     OpenPrice = isBuy ? tq.AskSpread_SP_Ins(spP, ins) : tq.BidSpread_SP_Ins(spP, ins)
            if (ordType === OrderType.PositionEdit && tradingData.isPosition) {
                OpenPrice = (isBuy ? tq.BidSpread_SP_Ins(spP, ins) : tq.AskSpread_SP_Ins(spP, ins)) || tradingData.position.CurPriceClose;
            }
            if (!isNaN(OpenPrice) && this.DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_TRAILING_STOP_BY_PRICE)) {
                // Посчитали истинные тики, соответсвенно потом в расчёте цены мы игнорим фракционы
                const SLoffset = SlTpUtils.toRealValue(this.trailingStop.value, ins, this.trailingStop.offsetType) * (isBuy ? -1 : 1);
                sltp.RealTrStopPrice = ins.roundPrice(ins.CalculatePrice(OpenPrice, SLoffset, true));
                this.trailingStop.RealTrStopPrice = sltp.RealTrStopPrice;
            }
        }

        if (this.useTP()) {
            const value = this.tp.value;
            const offsetType = this.tp.offsetType;
            const absolute = this.tp.offsetType === null;
            sltp.TakeProfitPriceType = absolute ? SlTpPriceType.Absolute : SlTpUtils.getSLTPPriceType(offsetType);
            sltp.TakeProfitPriceValue = absolute ? value : SlTpUtils.toRealValue(value, ins, offsetType);
            sltp.SLTPTriggerShortValue = this.sltpTriggerShort || this.nonEditableSLTPTriggerShort;
        }

        return sltp;
    }

    // TODO. UGLY. Refactor.
    // Details are at the top of OrderEditBase.ts.
    // TODO. Absolute price conversion to ticks and vice versa.
    public createDynPropertyForRawSLTP (sltpHolder: SlTpHolder, tradingData): any {
        if (isNullOrUndefined(sltpHolder) || !tradingData?.instrument) {
            return null;
        }

        const ins: Instrument = tradingData.instrument;

        const tpValue = sltpHolder.TakeProfitPriceValue;
        const slValue = sltpHolder.StopLossPriceValue;
        const sllValue = sltpHolder.StopLossLimitPriceValue;

        const isTpEnabled = isValidNumber(tpValue);
        const isSlEnabled = isValidNumber(slValue);
        const isTrStopEnabled = isSlEnabled && sltpHolder.isTrailingStop();

        const dp = this.getDynProperty(tradingData);

        dp.tp.enabled = isTpEnabled;
        dp.sl.enabled = isSlEnabled;
        dp.trailingStop.enabled = isTrStopEnabled;

        if (!sltpHolder.isSlEmpty() || !sltpHolder.isTpEmpty()) {
            dp.sltpTriggerShort = sltpHolder.SLTPTriggerShortValue;
        }

        if (isTpEnabled) {
            dp.tp.value = sltpHolder.isTpAbsolute() ? tpValue : SlTpUtils.toVisualValue(tpValue, ins, this.tp.offsetType);
        }

        if (isTrStopEnabled) {
            const trStopOffsetType = this.trailingStop.offsetType;
            dp.trailingStop.value = SlTpUtils.toVisualValue(slValue, ins, trStopOffsetType);
            dp.trailingStop.visibleValue = SlTpUtils.toVisualValue(slValue, ins, trStopOffsetType);
        }

        if (isSlEnabled) {
            dp.sl.value = sltpHolder.isSlAbsolute() ? slValue : SlTpUtils.toVisualValue(slValue, ins, this.sl.offsetType);
            if (!sltpHolder.isSllEmpty()) {
                dp.sl.sllValue = sltpHolder.isSlAbsolute() ? sllValue : SlTpUtils.toVisualValue(sllValue, ins, this.sl.offsetType);
                this.sl.stillDefaultSLL = false; // #112707
            }
        }
        return dp;
    }

    public update (updateData, tradingData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp) {
            parameterChanged = this.updateFromDynProperty(dp, tradingData) || parameterChanged;
        }

        const sessionSettingsChanged = updateData.sessionSettingsChanged;
        if (sessionSettingsChanged) {
            this.updateOffsetTypes();
            parameterChanged = true || parameterChanged;
        }

        let newInstrument = null;
        let newAccount = null;
        let newQuote = null;

        const newTradingDataDict = updateData.newTradingDataDict;
        if (newTradingDataDict) {
            newInstrument = newTradingDataDict.instrument;
            newAccount = newTradingDataDict.account;
            newQuote = newTradingDataDict.quote;
        }

        if (newInstrument || newAccount) {
            parameterChanged = this.updateAllowedRules(
                tradingData.instrument,
                tradingData.account,
                tradingData.order || tradingData.position) || parameterChanged; // if modify for #92505
        }

        if (sessionSettingsChanged || newInstrument) {
            this.sl.value = this.sl.sllValue = this.tp.value = this.trailingStop.value = this.sltpTriggerShort = null;
            this.needSetDefaultSLTP = true;
            parameterChanged = true || parameterChanged;
        }

        const instrument = tradingData.instrument;
        const quote = newQuote || (tradingData ? tradingData.quote : null);
        if (this.needSetDefaultSLTP && quote && instrument) {
            this.needSetDefaultSLTP = false;
            parameterChanged = this.setDefaultSLTP(tradingData) || parameterChanged;
        }

        return parameterChanged;
    }

    // TODO. UGLY. Refactor. Rename.
    public updateOffsetTypes (): void {
        this.trailingStop.offsetType = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(this.instrument);
        if (!isNullOrUndefined(this.sltpHolder) && !this.sltpHolder.isEmpty()) {
            this.sl.offsetType = SlTpUtils.getSLVisualOffsetType(this.instrument, this.sltpHolder);
            this.tp.offsetType = SlTpUtils.getTPVisualOffsetType(this.instrument, this.sltpHolder);
        } else {
            let defaultOffsetType: OffsetModeViewEnum | null;
            switch (this._mode) {
            case SLTPMode.Absolute:
                defaultOffsetType = null;
                break;
            case SLTPMode.Offset:
                defaultOffsetType = SlTpUtils.getDefaultVisualOffsetType(this.instrument);
                break;
            default:
                defaultOffsetType = BaseSettings.isSlTpInOffset ? SlTpUtils.getDefaultVisualOffsetType(this.instrument) : null;
                break;
            }
            this.sl.offsetType = this.tp.offsetType = defaultOffsetType;
        }
    }

    // TODO. Rename?
    public updateAllowedRules (instrument, account, modifyObj?): boolean {
        let parameterChanged = false;

        let newAllowedSL = IsAllowed.IsSLTPAllowed(instrument, account, true).Allowed;

        if (modifyObj) {
            if (modifyObj.isPosition) {
                if (modifyObj.SLOrder !== null) // try modify position with enabled SL
                { newAllowedSL = IsAllowed.IsPositionModifyingAllowed(modifyObj).Allowed; }; // SLAllowed if modifying allowed #92505
            } else
                if (!isNaN(modifyObj.StopLossPriceValue)) // try modify order with enabled SL
                {
                    newAllowedSL = IsAllowed.IsOrderModifyingAllowed(modifyObj).Allowed;
                } // SLAllowed if modifying allowed #92505
        }

        if (this.allowedSL !== newAllowedSL) {
            this.allowedSL = newAllowedSL;
            parameterChanged = true || parameterChanged;
        }

        const newAllowedTP = IsAllowed.IsSLTPAllowed(instrument, account, false).Allowed;
        if (this.allowedTP !== newAllowedTP) {
            this.allowedTP = newAllowedTP;
            parameterChanged = true || parameterChanged;
        }

        const newTrailingStopAllowed = IsAllowed.IsTrailingStopForSLAllowed(instrument, account, modifyObj ? modifyObj.isPosition : false).Allowed;
        if (this.trailingStopAllowed !== newTrailingStopAllowed) {
            this.trailingStopAllowed = newTrailingStopAllowed;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public updateFromDynProperty (dp, tradingData): boolean // TODO: разобраться что тут происходит, дописать комменты
    {
        let parameterChanged = false;

        let ch = false;
        if (!dp.trailingStop.enabled || dp.sl.enabled != this.sl.enabled) {
            ch = this.updateSLTPartFromDynProperty(dp.sl, this.sl);
        }

        const trCh = dp.trailingStop.enabled !== this.trailingStop.enabled;
        // поменялся sl, обнови trailingStop или произошло ебаное переключение
        if (ch || trCh) {
            if (dp.sl.inOffset && !this.SkipChange) {
                if (dp.trailingStop.enabled) {
                    dp.trailingStop.value = dp.sl.value;
                } else if (trCh) {
                    this.sl.value = dp.trailingStop.value; // #92893
                    if (this.sl.sllValue !== null && this.sl.stillDefaultSLL) // ##112707
                    {
                        this.sl.stillDefaultSLL = false;
                        this.sl.sllValue = this.sl.value;
                    }
                }
            } else if (!this.SkipChange) {
                const td = tradingData;
                let OpenPrice = td.limitPrice || td.stopPrice;

                if (td.quote) {
                    const sp = this.DataCache.GetSpreadPlan(td.account);
                    if (OrderType.Market === td.OrderType) {
                        OpenPrice = td.side === OperationType.Buy ? td.quote.AskSpread_SP_Ins(sp, td.instrument) : td.quote.BidSpread_SP_Ins(sp, td.instrument);
                    }

                    if (td.OrderType === OrderType.PositionEdit && td.isPosition) {
                        OpenPrice = (td.side === OperationType.Buy ? td.quote.BidSpread_SP_Ins(sp, td.instrument) : td.quote.AskSpread_SP_Ins(sp, td.instrument)) || td.position.CurPriceClose;
                    }
                }

                if (!OpenPrice && td.position) {
                    OpenPrice = td.position.Price;
                }

                // При выключении нужно зафигачить
                if (trCh && this.trailingStop.enabled) {
                    let SLoffset = dp.trailingStop.value * (td.side === OperationType.Buy ? -1 : 1);
                    if (OpenPrice) {
                        // Сюда могут насыпать поинты или фракционы, нам нужны тики
                        // Посчитали истинные тики, соответсвенно потом в расчёте цены мы игнорим фракционы
                        SLoffset = SlTpUtils.toRealValue(SLoffset, tradingData.instrument, this.trailingStop.offsetType);
                        dp.sl.value = td.instrument.CalculatePrice(OpenPrice, SLoffset, true);
                        // Применяем новые значения
                        this.updateSLTPartFromDynProperty(dp.sl, this.sl);
                    }
                }
                // #98353
                const slValue = dp.sl.sllValue != null && !dp.trailingStop.enabled ? dp.sl.sllValue : dp.sl.value;

                // Convert price to ticks for TrailingStop
                if (OpenPrice) {
                    const ticks = SlTpUtils.calculateTicks(OpenPrice, slValue, tradingData.instrument);
                    const trStopOffsetType = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(tradingData.instrument);
                    dp.trailingStop.value = SlTpUtils.toVisualValue(ticks, tradingData.instrument, trStopOffsetType);
                }

                if (trCh && this.trailingStop.enabled && this.sl.stillDefaultSLL && // При выключении нужно зафигачить sll = sl  //#112707, 101756 - ок,проверил
                    dp.sllValueEnabled) // #114935: но еще необходимо проверить что sll в принципе включен
                {
                    this.sl.sllValue = dp.sl.value;
                }

                // if (trCh)                                            // с этим if возникает баг #101756
                //     this.sl.sllValue = dp.sl.value      // #99816    // <- этот тикет какой-то отмороженный
            }

            // Убрать нельзя, мы поменяли значение в dp.trailingStop их нужно применить на this.trailingStop, это происходит при смене SL для синхоронизации значений.
            // строчка ниже выполняется при смене значений в самом dp.trailingStop и просмотра что там поменялось и применении на this.trailingStop
            // а также для запуска синхронизации обратной при смене trailingStop менять значения sl
            // ось така херня малята
            this.updateSLTPartFromDynProperty(dp.trailingStop, this.trailingStop); // убрать нельзя а то #93230 но зачем тогда такой же вызов через 7 строчек и последующий за ним if

            parameterChanged = trCh || parameterChanged;
        }

        parameterChanged = ch || parameterChanged;
        parameterChanged = this.updateSLTPartFromDynProperty(dp.tp, this.tp) || parameterChanged;

        // поменялся trailingStop, обнови sl
        ch = this.updateSLTPartFromDynProperty(dp.trailingStop, this.trailingStop);
        // #103417 нужно
        if (ch) {
            if (dp.sl.inOffset && !this.SkipChange) {
                dp.sl.value = dp.trailingStop.value;
            } else if (!this.SkipChange) {
                const td = tradingData;
                let OpenPrice = td.limitPrice || td.stopPrice;

                const sp = this.DataCache.GetSpreadPlan(td.account);
                if (OrderType.Market === td.OrderType && td.quote) {
                    OpenPrice = td.side === OperationType.Buy ? td.quote.AskSpread_SP_Ins(sp, td.instrument) : td.quote.BidSpread_SP_Ins(sp, td.instrument);
                }

                if (!OpenPrice && td.position) {
                    OpenPrice = td.position.Price;
                }

                let needRecalc = true;
                const SLoffset = dp.trailingStop.value * (td.side === OperationType.Buy ? -1 : 1);
                if (td.OrderType === OrderType.PositionEdit && td.isPosition) {
                    // ЕБУЧАЯ ХУЙНЯ при модификации перетягивании на чарте у нас тики trailingStop передаются от position.Price
                    // нужно скоректировать на расчёт от position.CurPriceClose
                    // корректировка происходит в случае модификации позиции
                    OpenPrice = td.position.CurPriceClose;

                    // ну как же мы без поинтов и фракционов?
                    // спасибо  Utils.toRawTicks ))
                    // dp.trailingStop.offsetType и this.trailingStop.offsetType, offsetType в dp.trailingStop нету
                    const checkSLoffset = SlTpUtils.toRealValue(SLoffset, tradingData.instrument, this.trailingStop.offsetType);

                    const slValue = dp.sl.sllValue != null && !dp.trailingStop.enabled ? dp.sl.sllValue : dp.sl.value;
                    const SLoffsetDiff = slValue - td.position.Price;
                    const ticks = td.instrument.CalculateTicks(OpenPrice, SLoffsetDiff, true) * Math.sign(SLoffsetDiff);
                    // при перетаскивании мы получаем одинаковое количество тиков если их отложить между position.Price и slValue
                    // тогда мы не меняем slValue, а вот trailingStop нужно отодвинуть относительно position.CurPriceClose
                    // очередное говно!!!
                    needRecalc = ticks !== checkSLoffset;
                    if (OpenPrice && !needRecalc) {
                        const SLoffsetDiff2 = slValue - OpenPrice;
                        const tiks = td.instrument.CalculateTicks(OpenPrice, SLoffsetDiff2, true) * Math.sign(SLoffsetDiff2);
                        const trStopOffsetType = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(tradingData.instrument);
                        dp.trailingStop.value = SlTpUtils.toVisualValue(tiks, tradingData.instrument, trStopOffsetType);
                        this.updateSLTPartFromDynProperty(dp.trailingStop, this.trailingStop);
                    }
                }
                if (OpenPrice && needRecalc) {
                    // Как оказалось поинты сюда ненужно передавать, а SLoffset Utils.toRawTicks, т.е норм тики
                    // поэтому, да прибудут тики с магической функцией Utils.toRawTicks
                    const tiksCalc = SlTpUtils.toRealValue(SLoffset, tradingData.instrument, this.trailingStop.offsetType);
                    dp.sl.value = td.instrument.CalculatePrice(OpenPrice, tiksCalc);
                }
            }

            // Применяем новые значения
            this.updateSLTPartFromDynProperty(dp.sl, this.sl);
        }

        parameterChanged = ch || parameterChanged;

        if (!parameterChanged) {
            const td = tradingData;
            if (td.OrderType === OrderType.PositionEdit && td.isPosition && this.trailingStop.enabled) {
                const price = td.position.CurPriceClose;
                let SLoffset = dp.trailingStop.value;
                if (BaseSettings.offsetType == OffsetModeViewEnum.Points) {
                    SLoffset = SlTpUtils.toRealValue(SLoffset, tradingData.instrument, this.trailingStop.offsetType);
                } // переводим оффсет из поинтов в тики #92893 UPD да, именно тики, toRawTicks в помощь
                SLoffset *= td.side === OperationType.Buy ? -1 : 1;

                if (price) {
                    dp.sl.value = td.instrument.CalculatePrice(price, SLoffset);
                }

                parameterChanged = trCh || parameterChanged;
            }
        }

        parameterChanged = this.updateSLTPTriggersPartFromDynProperty(dp) || parameterChanged;

        return parameterChanged;
    }

    public updateSLTPTriggersPartFromDynProperty (dp): boolean // значення, що повертається - чи відбулася зміна знач. парам. sltpTriggerShort
    {
        if (dp.nonEditableSLTPTriggerShort !== null) {
            this.nonEditableSLTPTriggerShort = dp.nonEditableSLTPTriggerShort;
        }

        if (this.sltpTriggerShort !== dp.sltpTriggerShort) {
            this.sltpTriggerShort = dp.sltpTriggerShort;
            return true;
        }

        return false;
    }

    // TODO. Rename. Refactor.
    public updateSLTPartFromDynProperty (src, dest): boolean {
        let parameterChanged = false;

        if (dest.enabled !== src.enabled) {
            dest.enabled = src.enabled;
            parameterChanged = true || parameterChanged;
        }

        if (!(isNaN(dest.value) && isNaN(src.value)) &&
            dest.value !== src.value) {
            dest.value = src.value;
            parameterChanged = true || parameterChanged;
        }

        if (!(isNaN(dest.sllValue) && isNaN(src.sllValue)) &&
            dest.sllValue != null && src.sllValue !== null &&
            dest.sllValue !== src.sllValue) {
            dest.sllValue = src.sllValue;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    // TODO. Refactor. UGLY.
    public setDefaultSLTP (tradingData, setSL: any = undefined, setTP: any = undefined, setTrailingStop: any = undefined): boolean {
        setSL = setSL === undefined || setSL;
        setTP = setTP === undefined || setTP;
        setTrailingStop = setTrailingStop === undefined || setTrailingStop;

        const instrument = tradingData.instrument;
        const quote = tradingData.quote;
        const buy = tradingData.side === OperationType.Buy;

        let parameterChanged = false;

        const insDefSettings = BaseSettings.insDefSettingsStorage.GetInstrumentSettings(instrument);

        let basePrice;
        if (isValidNumber(tradingData.limitPrice)) {
            basePrice = tradingData.limitPrice;
        } else if (isValidNumber(tradingData.stopPrice)) {
            basePrice = tradingData.stopPrice;
        } else {
            basePrice = quote.BidSpread_SP_Ins(this.DataCache.GetSpreadPlan(tradingData.account), instrument);
        }

        if (setSL) {
            const slOffset = !isNullOrUndefined(insDefSettings) ? insDefSettings.getDefaultSLOffset(this.sl.offsetType === OffsetModeViewEnum.Percent) : 1;
            parameterChanged = SLTPEdit.setDefault_SLTPPart(this.sl, instrument, basePrice, slOffset * (buy ? -1 : 1)) || parameterChanged;

            this.sl.sllValue = this.sl.value && BaseSettings.isUseStopLimitInsteadStop() ? this.sl.value : null;
            this.sl.stillDefaultSLL = true;
        }

        if (setTP) {
            const tpOffset = !isNullOrUndefined(insDefSettings) ? insDefSettings.getDefaultTPOffset(this.tp.offsetType === OffsetModeViewEnum.Percent) : 1;
            parameterChanged = SLTPEdit.setDefault_SLTPPart(this.tp, instrument, basePrice, tpOffset * (buy ? 1 : -1)) || parameterChanged;
        }

        if (setTrailingStop) {
            const trailingStopTickOffset = !isNullOrUndefined(insDefSettings) ? insDefSettings.getDefaultSLOffset(false) : 1;
            parameterChanged = SLTPEdit.setDefault_SLTPPart(this.trailingStop, instrument, basePrice, trailingStopTickOffset * (buy ? -1 : 1)) || parameterChanged;
        }

        return parameterChanged;
    }

    public static setDefault_SLTPPart (slTpPart, instrument: Instrument, basePrice: number, offset: number): boolean {
        let parameterChanged = false;
        const newValue = slTpPart.offsetType === null ? instrument.CalculatePrice(basePrice, offset, true) : SlTpUtils.toVisualValue(Math.abs(offset), instrument, slTpPart.offsetType);

        if (slTpPart.value !== newValue) {
            slTpPart.value = newValue;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    // #region Validation

    public validate (slBasePriceGetter, tpBasePriceGetter, tradingData): boolean {
        let parameterChanged = false;

        const buy = tradingData.side === OperationType.Buy;
        const instrument = tradingData.instrument;

        parameterChanged = this.validate_SLTPPart(
            this.sl,
            this.getSLError,
            // TODO. Refactor.
            slBasePriceGetter ? slBasePriceGetter() : 0,
            buy,
            instrument,
            this.useSL()
        ) || parameterChanged;

        parameterChanged = this.validate_SLTPPart(
            this.tp,
            this.getTPError,
            // TODO. Refactor.
            tpBasePriceGetter ? tpBasePriceGetter() : 0,
            buy,
            instrument,
            this.useTP()
        ) || parameterChanged;

        return parameterChanged;
    }

    // TODO. Refactor. Rename.
    public validate_SLTPPart (sltpPartSource, sltpPartValidator, basePrice, buy, instrument: Instrument, useSLTPPart): boolean {
        let parameterChanged = false;
        const newErr = useSLTPPart ? sltpPartValidator(basePrice, buy, instrument) : null;
        const oldErr = sltpPartSource.error;
        // TODO. Refactor.
        if (
            (!newErr && oldErr) ||
            (newErr && !oldErr) ||
            (newErr && !newErr.equals(oldErr))
        ) {
            sltpPartSource.error = newErr;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    // TODO. Refactor.
    public getSLError (basePrice: number, buy: boolean, instrument: Instrument): any {
        const offsetType = this.sl.offsetType;
        const value = this.sl.value;
        let error = null;
        if (isNullOrUndefined(offsetType)) {
            if (buy && value >= basePrice) {
                error = new SLTPError(
                    SLTPErrorType.MoreMax,
                    instrument.formatPrice(basePrice - instrument.GetPointSize(value - MathUtils.MATH_ROUND_EPSILON))
                );
            } else if (!buy && value <= basePrice) {
                error = new SLTPError(
                    SLTPErrorType.LessMin,
                    instrument.formatPrice(basePrice + instrument.GetPointSize(value + MathUtils.MATH_ROUND_EPSILON))
                );
            }
        } else if (offsetType === OffsetModeViewEnum.Percent) {
            error = this.getSLTPPercentError(this.sl, basePrice, buy, instrument);
        }

        return error;
    }

    // TODO. Refactor.
    public getTPError (basePrice: number, buy: boolean, instrument: Instrument): any {
        const offsetType = this.tp.offsetType;
        const value = this.tp.value;
        let error = null;

        if (isNullOrUndefined(offsetType)) {
            if (buy && value <= basePrice) {
                error = new SLTPError(
                    SLTPErrorType.LessMin,
                    instrument.formatPrice(basePrice + instrument.GetPointSize(value + MathUtils.MATH_ROUND_EPSILON))
                );
            } else if (!buy && value >= basePrice) {
                error = new SLTPError(
                    SLTPErrorType.MoreMax,
                    instrument.formatPrice(basePrice - instrument.GetPointSize(value - MathUtils.MATH_ROUND_EPSILON))
                );
            }
        } else if (offsetType === OffsetModeViewEnum.Percent) {
            error = this.getSLTPPercentError(this.tp, basePrice, buy, instrument);
        }

        return error;
    }

    private getSLTPPercentError (sltp: OrderSlTpBase, basePrice: number, buy: boolean, instrument: Instrument): SLTPError | null {
        let error = null;
        const value = sltp.value;
        const dp = this.getDynProperty_SLTPPart(sltp, instrument, basePrice, buy);
        if (value < dp.minimalValue) {
            error = new SLTPError(
                SLTPErrorType.LessMin,
                SlTpUtils.formatSLTPValue(dp.minimalValue, instrument, OffsetModeViewEnum.Percent)
            );
        } else if (value > dp.maximalValue) {
            error = new SLTPError(
                SLTPErrorType.MoreMax,
                SlTpUtils.formatSLTPValue(dp.maximalValue, instrument, OffsetModeViewEnum.Percent)
            );
        }
        return error;
    }

    // #endregion Validation

    // TODO. Refactor.
    public getConfirmationText (tradingData): string {
        let result = '';
        let insertSemicolon = false;
        const ins = tradingData.instrument;

        if (this.useTrailingStop()) {
            const sltpPart = this.trailingStop;

            result += Resources.getResource('Tr. stop') + ': ';

            if (this.trailingStop.RealTrStopPrice) {
                result += ins.formatPrice(this.trailingStop.RealTrStopPrice);
            } else {
                const trailingStopValue = sltpPart.visibleValue || sltpPart.value;
                const slFormattedOffset = SlTpUtils.formatSLTPValue(trailingStopValue, ins, sltpPart.offsetType);
                const slOffsetDescr = Resources.getResource(SlTpUtils.getLocalizationForOffsetMode(sltpPart.offsetType));
                result += `${slFormattedOffset} ${slOffsetDescr}`;
            }

            insertSemicolon = true;
        }

        if (this.useSL()) {
            const sl = this.sl;
            const absolute = sl.offsetType === null;

            result +=
                Resources.getResource(absolute
                    ? 'panel.newOrderEntry.slPriceRisk'
                    : 'panel.newOrderEntry.slPriceRiskOffset') +
                ': ';

            result += absolute ? ins.formatPrice(sl.value) : SlTpUtils.formatSLTPValue(sl.value, ins, sl.offsetType);

            if (isValidNumber(sl.sllValue)) {
                result += '/' + (absolute ? ins.formatPrice(sl.sllValue) : SlTpUtils.formatSLTPValue(sl.sllValue, ins, sl.offsetType));
            }

            if (!absolute) {
                result +=
                    ' ' +
                    Resources.getResource(SlTpUtils.getLocalizationForOffsetMode(sl.offsetType));
            }

            insertSemicolon = true;
        }

        const sltpTrShortToShow = this.sltpTriggerShort || this.nonEditableSLTPTriggerShort;
        if (!MathUtils.IsNullOrUndefined(sltpTrShortToShow) && insertSemicolon) {
            result += ' (' + Resources.getResource('property.OESLTrigger') + ' ' +
                SLTPTrigger.GetTextValue(sltpTrShortToShow, tradingData.side, true) + ')';
        }

        if (this.useTP()) {
            const tp = this.tp;
            const absolute = tp.offsetType === null;

            if (insertSemicolon) {
                result += '; ';
            }

            result +=
                Resources.getResource(absolute
                    ? 'panel.newOrderEntry.tpPriceRisk'
                    : 'panel.newOrderEntry.tpPriceRiskOffset') +
                ': ';
            result += absolute ? ins.formatPrice(tp.value) : SlTpUtils.formatSLTPValue(tp.value, ins, tp.offsetType);

            if (!absolute) {
                result +=
                    ' ' +
                    Resources.getResource(SlTpUtils.getLocalizationForOffsetMode(tp.offsetType));
            }

            if (!MathUtils.IsNullOrUndefined(sltpTrShortToShow)) {
                result += ' (' + Resources.getResource('property.OETPTrigger') + ' ' +
                    SLTPTrigger.GetTextValue(sltpTrShortToShow, tradingData.side, false) + ')';
            }
        }

        return result;
    }

    public enableSL (): void {
        this.sl.enabled = true;
    }

    public getSLTPTriggerStr (isSL, isBuy): any // helps to pick trigger price #109798 docs(3.3.2) Trading logic)
    {
        const SLOrTP = this[isSL ? 'sl' : 'tp'];
        const triggerShort = this.sltpTriggerShort || this.nonEditableSLTPTriggerShort;

        if (SLOrTP.enabled) {
            return SLTPEdit.GetStrByShort(triggerShort, isSL, isBuy);
        }

        return null;
    }

    public static GetStrByShort (short, isSL, isBuy): string | null // helps to pick trigger price #109798 docs(3.3.2) Trading logic)
    {
        if (short === null) {
            return null;
        }

        const side = OperationType[isBuy ? 'Buy' : 'Sell'];
        const bidOrAskStr = SLTPTrigger.GetTextValue(short, side, isSL);

        if (bidOrAskStr) {
            // let compareWith = isSL ? 'Bid' : 'Ask'
            return bidOrAskStr; //= = 'Bid' ? (isBuy ? 'Bid' : 'Ask') : (isBuy ? 'Ask' : 'Bid')
        }

        return null;
    }

    public toJSON (): any {
        return {
            sl: this.sl,
            tp: this.tp,
            trailingStop: this.trailingStop,
            sltpTriggerShort: this.sltpTriggerShort,
            sllValueEnabled: this.sllValueEnabled
        };
    }
}

export enum SLTPMode {
    None = 0,
    Absolute = 1,
    Offset = 2
}

class SlTpParameters {
    public readonly isSl: boolean;
    public readonly isTrailingStop: boolean;
    public readonly isOffset: boolean;
    public readonly offsetType: OffsetModeViewEnum;
    public readonly value: number;

    constructor (isSl: boolean, isTrailingStop: boolean, isOffset: boolean, offsetType: OffsetModeViewEnum, value: number) {
        this.isSl = isSl;
        this.isTrailingStop = isTrailingStop;
        this.isOffset = isOffset;
        this.offsetType = offsetType;
        this.value = value;
    }
}

class OrderParameters {
    public readonly instrument: Instrument;
    public readonly account: Account;
    public readonly isBuy: boolean;
    public readonly orderType: OrderType;
    public readonly openPrice: number;
    public readonly closePrice: number;
    public readonly quantity: number;
    public readonly openCross: number;
    public readonly productType: ProductType;
    // Stop part for OCO
    public readonly secondOpenPrice: number;

    constructor (instrument: Instrument, account: Account, isBuy: boolean, orderType: OrderType, openPrice: number, closePrice: number, quantity: number, openCross: number, productType: ProductType, secondOpenPrice: number) {
        this.instrument = instrument;
        this.account = account;
        this.isBuy = isBuy;
        this.orderType = orderType;
        this.openPrice = openPrice;
        this.closePrice = closePrice;
        this.quantity = quantity;
        this.openCross = openCross;
        this.productType = productType;
        this.secondOpenPrice = secondOpenPrice;
    }
}
