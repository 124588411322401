// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '../../../Localizations/Resources';
import { type AccountManagerColumn, StandardFormatterName, type CellAlignment } from '../charting_library';
import { TvColDefVisEnum } from './TvColumnDefaultVisibilityEnum';

export abstract class TvBaseColumns {
    protected defaultalignment: CellAlignment = 'right';
    protected createColumnItem (locKey: string,
        id: string,
        formatter: any = StandardFormatterName.Fixed,
        highlightDiff: boolean = false,
        customdatafields: string[] = undefined,
        hideByDefault: boolean = false): AccountManagerColumn | null {
        if (Resources.isHidden(locKey)) return null;

        const df = isNullOrUndefined(customdatafields) ? [id] : customdatafields;

        const name = Resources.getResource(locKey);
        const toolTipKey = `${locKey}.descr`;
        const toolTip: string = Resources.IsResourcePresent(toolTipKey)
            ? Resources.getResource(`${locKey}.descr`)
            : name;
        const columnItem: AccountManagerColumn = {
            label: name,
            alignment: this.defaultalignment,
            id,
            dataFields: df,
            notSortable: true,
            formatter,
            tooltipProperty: toolTip,
            help: toolTip,
            highlightDiff,
            hideByDefault
        };

        if (highlightDiff) {
            columnItem.highlightDiff = true;
        }

        return columnItem;
    }

    protected createAccSummaryColumnItem (
        locKey: string,
        id: string,
        formatter,
        defVisibility: TvColDefVisEnum): AccountManagerColumn | null {
        const hideByDefault = defVisibility === TvColDefVisEnum.Hidden;
        return this.createColumnItem(
            locKey,
            id,
            formatter,
            false,
            undefined,
            hideByDefault);
    }
}
