// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { Rectangle } from '@shared/commons/Geometry';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { PolyRect, SolidBrush } from '@shared/commons/Graphics';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { DynProperty } from '@shared/commons/DynProperty';
import { type TerceraChart } from '../TerceraChart';
import { type TerceraChartAdvancedParams } from '../Utils/TerceraChartAdvancedParams';

export class TerceraChartHighlightExtendedSessionRenderer extends TerceraChartBaseRenderer<TerceraChart> {
    private highlightBrush: SolidBrush;
    private highlightExtendedSessionsColor: string;

    constructor (chart: TerceraChart) {
        super(chart);
        this.setHighlightColor(ThemeManager.CurrentTheme.Chart_HighlightExtendedSessionsColor);
        this.SetClassName('TerceraChartHighlightExtendedSessionRenderer');
    }

    public override get Visible (): boolean {
        return this._Visible && this.chart.ShowAllSessions;
    }

    public override set Visible (value: boolean) {
        this._Visible = value;
    }

    IsNeedDraw (numberOfLayer: number): boolean {
        return this.assignLayer === numberOfLayer || LayersEnum.Quotes === numberOfLayer;
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: TerceraChartAdvancedParams<TerceraChart> = null): void {
        if (!this.Visible) return;

        const param = advParams;
        if (param?.TerceraChart == null) return;

        const isQuotesLayer = param.layerId === LayersEnum.Quotes;
        // const chartDrawingType = param.TerceraChart.model.chartDrawingType;

        const cashItemSeries = param.TerceraChart.MainCashItemSeries;
        const clientRect = window.ClientRectangle.copy();

        const screenData = cashItemSeries.ChartScreenData.Storage;
        if (screenData.length <= 0) {
            return;
        }

        gr.save();
        gr.beginPath();
        gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip();

        const scX = window.XScale;
        const barWidth = window.XScale;

        const lastScreenDate = screenData[screenData.length - 1].Time;
        let curX = window.PointsConverter.GetScreenXbyTime(lastScreenDate);

        const polyRects = new PolyRect();
        for (let i = screenData.length - 1; i >= 0; i--) {
            if (!screenData[i].IsMainSession) {
                polyRects.rects.push(new Rectangle(curX, clientRect.Y, barWidth - 1, clientRect.Height));
            }

            curX -= scX;
            if (isQuotesLayer) break;
        }

        gr.DrawPolyRect(this.highlightBrush, polyRects);

        // Reset clip.
        gr.restore();
    }

    ThemeChanged (): void {
        super.ThemeChanged();

        this.highlightBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_VolumeFixedColor);
    }

    Properties (): DynProperty[] {
        const properties = super.Properties();

        const SeparatorGroup = '#2#' + Resources.getResource('property.SeparatorGroup.Show');

        const prop = new DynProperty('highlightExtendedSessionsColor', this.highlightExtendedSessionsColor, DynProperty.COLOR, DynProperty.TIME_SCALE_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 3;
        properties.push(prop);

        return properties;
    }

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const dp = DynProperty.getPropertyByName(properties, 'highlightExtendedSessionsColor');
        if (dp != null) {
            if (this.highlightExtendedSessionsColor !== dp.value) {
                this.setHighlightColor(dp.value);
            }
        }
    }

    public setHighlightColor (color: string): void {
        this.highlightExtendedSessionsColor = color;
        this.highlightBrush = new SolidBrush(color);
    }
}
