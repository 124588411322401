// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { PFixFieldSet } from '../Fields/FieldsImport';

export class HistoricalBrokerMessageGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.HISTORICAL_BROKER_MESSAGE_GROUP, null, pfixFieldSet);
    }

    public getMessageID (): number {
        return this.GetValue(FieldsFactory.FIELD_ID);
    }

    public getTimeStamp (): Date | null {
        return this.GetValue(FieldsFactory.FIELD_DATE);
    }

    public getProductID (): number {
        return this.GetValue(FieldsFactory.FIELD_PRODUCT_ID);
    }

    public getSubject (): string {
        return this.GetValue(FieldsFactory.FIELD_TITLE);
    }

    public getMessage (): string {
        return this.GetValue(FieldsFactory.FIELD_TEXT);
    }

    public getIsUnread (): boolean {
        return this.GetValue(FieldsFactory.FIELD_IS_UNREAD);
    }

    public getResponseCases (): string[] {
        return this.GetValue(FieldsFactory.FIELD_BROKER_MESSAGE_RESPONSE_CASES);
    }

    public getDisplayMethod (): number {
        return this.GetValue(FieldsFactory.FIELD_DISPLAY_METHOD);
    }

    public getCustomButtonsGroups (): any[] {
        return this.GetGroups(FieldsFactory.CUSTOM_BUTTONS_GROUP);
    }
}
