// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Rectangle } from '../../Geometry';

export class HeatMapFontManagerParams {
    public logoSize: number;
    public title: string;
    public firstValue: string;
    public secondValue: string;
    public rectangle: Rectangle;
    public graphics: CanvasRenderingContext2D;
}
