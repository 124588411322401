// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectPriceLimitsMessage extends DirectMessageBaseClass {
    public TradableId: number | null = null;
    public HighLimit: number | null = null;
    public LowLimit: number | null = null;

    constructor () {
        super(Message.CODE_PRICE_LIMITS_MESSAGE);
    }
}
