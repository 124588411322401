// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
class AccountOperationTypeClass {
    private readonly mapStorrage = {};
    private createMap (): any {
        const result = {};
        const keys = Object.keys(this.Type);

        const len = keys.length;

        for (let i = 0; i < len; i++) { result[this.Type[keys[i]]] = keys[i]; }

        return result;
    }

    constructor () {
        this.mapStorrage = this.createMap();
    }

    get TypeNames (): any { return this.mapStorrage; }

    public Type = {
        Unknown: 0,
        Trading: 1,
        Deposit: 2,
        Withdraw: 3,
        Brokerage: 4,
        swap: 5,
        MinFeeAdjEquity: 6,
        Blocking: 7,
        Unblocking: 8,
        ManufacturedDividends: 9,
        interest: 10,
        Adjustment: 12,
        dividends: 13,
        StockOperation: 14,
        AccruedInterest: 15,
        Subscribe: 16,
        Transfer: 17,
        Spread: 18,
        Credit: 19,
        Premium: 21,
        DeliveredForward: 22,
        PAMMTransfer: 23,
        DWT: 24,
        ValueAddedTax: 25,
        Exchange: 26,
        SecuritiesTransferTax: 27,
        SettlementFee: 28,
        InvestorProtectionLevy: 29,
        Delivery: 30,
        addliquidityrebate: 31,
        removeliquidityrebate: 32,
        withdrawalfee: 33,
        Markup: 34,
        ClearingVmTransfer: 40,
        Level1DataCharging: 41,
        Level2DataCharging: 42,
        Level3DataCharging: 43,
        DelayedDataCharging: 44,
        NewsCharging: 45,
        MamPerformanceFee: 46,
        MamManagementFee: 47,
        InterestOnCash: 88,
        InterestOnMarginAvailable: 90,
        InterestOnMarginAvailableAndUnsettledCash: 91,
        InterestOnAvailableFunds: 92,
        IntererstOnAvailableFundsAndUnsettledCash: 93,
        OpenOptionPremium: 94,
        CloseOptionPremium: 95,
        TransferFee: 96,
        FixedFee: 97,
        Custodial: 98,
        BalanceCredit: 100,
        BalanceDebit: 101,
        AccruedDividends: 102,
        DividendsTax: 103,
        Funding: 106,
        FundingMarkup: 107,
        PortfolioOvernightFee: 111,
        CareOrder: 113
    };
}

export const AccountOperations = new AccountOperationTypeClass();
