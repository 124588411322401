// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { type StrategySubscriptionStatus } from '../../../../Utils/TradingIdeas/StrategySubscriptionStatus';

export class SubscriptionStrategyRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_SUBSCRIPTION_STRATEGY_REQUEST_MESSAGE, fieldSet);
    }

    public getTradingSystemId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_TRADING_SYSTEM_ID);
    }

    public setTradingSystemId (tradingSystemId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADING_SYSTEM_ID, tradingSystemId);
    }

    public getTradingStrategySubscriptionStatus (): StrategySubscriptionStatus {
        return this.getFieldValue(FieldsFactory.FIELD_TRADING_STRATEGY_SUBSCRIPTION_STATUS);
    }

    public setTradingStrategySubscriptionStatus (subscriptionStatus: StrategySubscriptionStatus): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADING_STRATEGY_SUBSCRIPTION_STATUS, subscriptionStatus);
    }
}
