// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { AllowedReport, ReportType } from '@shared/commons/cache/AllowedReport';
import { DataCache } from '@shared/commons/DataCache';
import { BaseSettingsUtils } from '@shared/commons/UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { Connection } from '@shared/commons/Connection';
import { CustomErrorClass, ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Message } from '@shared/utils/DirectMessages/Message';
import { OrderHistory } from '@shared/commons/cache/OrderHistory';
import { NewTrade } from '@shared/commons/cache/NewTrade';
import { Statement } from '@shared/commons/cache/Statement';
import { AccountOperationReport } from '@shared/commons/cache/AccountOperationReport';
import { OrderExecutor } from '@shared/commons/Trading/OrderExecutor';
import { OrderTifMap } from '@shared/utils/Trading/OrderTifEnum';
import { ProductType } from '@shared/utils/Instruments/ProductType';
import { TIF } from '@shared/utils/Trading/OrderTif';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { QuickTableComparingType } from '@shared/utils/QuickTableMisc/QuickTableComparingType';
import { ReportRequestConfig, EReportRequestParameter } from '@shared/commons/UtilsClasses/Report/ReportRequestConfig';
import { ReportMoreModel } from '@shared/commons/cache/Report/ReportMoreModel';

class _ReportManager {
    public reportsByComplited: Map<ReportType, boolean> = new Map<ReportType, boolean>([
        [ReportType.REPORT_ORDERBOOK, false],
        [ReportType.REPORT_TRADES, false],
        [ReportType.REPORT_STATEMENT, false],
        [ReportType.REPORT_MAMACCOUNTSUMMARY, false]
    ]);

    public async sendRequestByDate (type: ReportType, fromDate: number, toDate: number, login?: string): Promise<any[]> {
        const config = new ReportRequestConfig(type);
        config.addParameter(EReportRequestParameter.FromDate, fromDate.toString());
        config.addParameter(EReportRequestParameter.ToDate, toDate.toString());
        if (!isNullOrUndefined(login)) {
            config.addParameter(EReportRequestParameter.Login, login);
        }

        return await this.sendRequest(config);
    }

    public async sendRequestByRecord (type: ReportType, recordsNumber: number, fromRecordId: number | null = null, login: string | null = null): Promise<any[]> {
        const config = new ReportRequestConfig(type);
        config.addParameter(EReportRequestParameter.RecordsNumber, recordsNumber.toString());
        if (!isNullOrUndefined(fromRecordId)) {
            config.addParameter(EReportRequestParameter.FromRecordId, fromRecordId.toString());
        }
        if (!isNullOrUndefined(login)) {
            config.addParameter(EReportRequestParameter.Login, login);
        }

        return await this.sendRequest(config);
    }

    private async sendRequest (config: ReportRequestConfig): Promise<any[]> {
        if (config.reportType === ReportType.REPORT_ORDERBOOK && this.reportsByComplited.get(config.reportType)) {
            return await Promise.resolve(DataCache.FOrderHistoryCache.orderHistoryArray);
        }

        if (config.reportType === ReportType.REPORT_TRADES && this.reportsByComplited.get(config.reportType)) {
            return await Promise.resolve(DataCache.filledOrdersArray);
        }

        if (config.reportType === ReportType.REPORT_STATEMENT && this.reportsByComplited.get(config.reportType)) {
            return await Promise.resolve(DataCache.StatementArray);
        }

        if (config.reportType === ReportType.REPORT_MAMACCOUNTSUMMARY && this.reportsByComplited.get(config.reportType)) {
            return await Promise.resolve(DataCache.MamSummaryArray);
        }

        const report = DataCache.reportDict[AllowedReport.REPORT_KEY_PREFIX + config.reportType] ?? DataCache.reportDict[AllowedReport.TECHNICAL_REPORT_KEY_PREFIX + config.reportType];
        if (!report) { return await Promise.resolve([]); }

        const pTable: any = {};
        pTable.reportTypeObj = {};
        pTable.mainParamDict = {};
        pTable.reportTypeObj = report;

        pTable.mainParamDict.instruments = '';
        pTable.mainParamDict.login = config.getParameter(EReportRequestParameter.Login) ?? '';
        pTable.mainParamDict.fromDate = config.getParameter(EReportRequestParameter.FromDate) ?? '';
        pTable.mainParamDict.toDate = config.getParameter(EReportRequestParameter.ToDate) ?? '';
        pTable.mainParamDict.recordsNumber = config.getParameter(EReportRequestParameter.RecordsNumber) ?? '';
        pTable.mainParamDict.fromRecordId = config.getParameter(EReportRequestParameter.FromRecordId) ?? '';

        let IsShowLots = 'true';
        if (config.reportType === ReportType.REPORT_STATEMENT) {
            const inLots = BaseSettingsUtils.displayAmountInLots();
            IsShowLots = inLots ? 'true' : 'false';
        }
        pTable.mainParamDict.IsShowLots = IsShowLots;
        pTable.IsTechnical = config.isTechnical;
        try {
            const response = await Connection.vendor.GenerateReportPromise(report.keyForServer, pTable);
            switch (config.reportType) {
            case ReportType.REPORT_ORDER_HISTORY_LOAD_MORE:
            case ReportType.REPORT_FILLED_ORDER_LOAD_MORE:
            case ReportType.REPORT_STATEMENTS_LOAD_MORE:
            case ReportType.REPORT_MAM_SUMMARY_LOAD_MORE:
                return this.parseReportMore(config, response);
            default:
                return await this.parseReport(config, response);
            }
        } catch {
            const ex = new CustomErrorClass('DataCache error', 'DataCache.getReport', 'getReport -> GenerateReportPromise');
            ErrorInformationStorage.GetException(ex);

            console.error('Report fetch error.');
            return [];
        }
    }

    private async parseReport (config: ReportRequestConfig, msg): Promise<any[]> {
        if (!msg) { return []; }

        const data = msg.Code === Message.CODE_REPORT_GROUP_RESPONSE
            ? this.getRowsAndColumnsFromReportResponse(msg) // new scheme from DirectMsg
            : this.getRowsAndColumnsFromOldXMLMessage(msg); // old scheme from msg with xmlString

        const rows = data.rows;
        const columns = data.columns;
        const objArr = [];

        const needReqIns = config.reportType === ReportType.REPORT_ORDERBOOK || config.reportType === ReportType.REPORT_TRADES || config.reportType === ReportType.REPORT_MAMACCOUNTSUMMARY;
        let hasEmptyInstr = false;

        for (let i = 0, len = rows.length; i < len; i++) {
            const r = rows[i];
            const C = r.Cells;
            if (C[0].Label === AllowedReport.TOTAL_ROW_LABEL) {
                continue;
            }

            let item = null;

            switch (config.reportType) {
            case ReportType.REPORT_ORDERBOOK:
                item = new OrderHistory();
                break;
            case ReportType.REPORT_TRADES:
            case ReportType.REPORT_FILLED_ORDER_LOAD_MORE:
                item = new NewTrade(this, null);
                break;
            case ReportType.REPORT_STATEMENT:
                item = new Statement();
                break;
            case ReportType.REPORT_MAMACCOUNTSUMMARY:
                item = new AccountOperationReport();
                break;
            }

            const eDay = 0; const eMonth = 0; const eYear = 0; const putCall = -1; const strikeprice = -1;
            let InsName = '';
            for (let j = 0, len_j = columns.length; j < len_j; j++) {
                const Label = C[j].Label.trim();
                const Value = C[j].Value;
                switch (columns[j].Name) {
                //                |
                // Orders history |
                //                V
                case 'order_book_account':
                    let account = DataCache.GetAccountByIdOrName(Label);
                    if (account == null) { account = DataCache.GetAccountByNumber(Label); }
                    item.Account = account;
                    break;
                case 'order_book_symbol': InsName = Label; break;
                case 'order_book_orderid': item.OrderNumber = Label; break;
                case 'order_book_route': item.Route = Label; break;
                case 'order_book_modified_by': item.TraderId = Label; break;
                case 'order_book_event': item.eventTypeRaw = AllowedReport.EventFromString(Label); break;
                case 'order_book_quantity': item.Quantity = Value; break;
                case 'order_book_price': item.Price = Value; break;
                case 'order_book_stop_price': item.StopLimit = Value; break;
                case 'order_book_disclosed_qty': item.DisclosedQty = Value; break;
                case 'order_book_datetime': item.Date = item.Time = new Date(parseInt(Label)); break;
                case 'order_book_side': item.BuySell = OrderExecutor.getBuySell(Label); break;
                case 'order_book_type': item.OrderType = OrderExecutor.getOrderTypeByName(Label); break;
                case 'order_book_tif':
                    const tifParts = Label.split(' ');
                    const tifType = OrderTifMap[tifParts[0]];
                    const date = tifParts[1] ? tifParts[1] : null;
                    const tif = new TIF(tifType, date);

                    item.TimeInForce = tif;
                    break;
                case 'tradingmode': item.ProductType = ProductType[Label]; break;
                case 'external_order_id': item.ExternalOrderId = Label; break; // TODO 88232 заменил item.ExternalId на item.ExternalOrderId
                case 'expday': item.ExpDay = Label || eDay; break;
                case 'expmonth': item.ExpMonth = Label || eMonth; break;
                case 'expyear': item.ExpYear = Label || eYear; break;
                case 'derivativetype': item.PutCall = Label || putCall; break;
                case 'strikeprice': item.StrikePrice = Label || strikeprice; break;
                case 'order_book_symbol_type': item.SymbolType = Label; break;

                //               |
                // Filled orders |
                //               V
                case 'account':
                    let acc = DataCache.GetAccountByIdOrName(Label);
                    if (acc == null) { acc = DataCache.GetAccountByNumber(Label); }
                    item.Account = acc;
                    break;
                case 'sender': item.Login = Label; break;
                case 'instrumentname': InsName = Label; break;
                case 'orderid': item.OrderId = Label; break;
                case 'routename': item.Route = Label; break;
                case 'amount': item.Amount = Value; break;
                case 'price': item.Price = Value; break;
                case 'crossprice': item.OpenCrossPrise = Value; break;
                case 'time': item.Time = new Date(parseInt(Label)); ; break;
                case 'profit': item.PnL = Value; break;
                case 'type': item.OrderType = OrderExecutor.getOrderTypeByName(Label); break;
                case 'executionfee': item.Comission = -Value; break; // #89723
                case 'extprice': item.ExternalPrice = Value; break;
                case 'tradevolume': item.TradeVolume = Value; break; // ???????????????
                case 'tradableinstrumentid': item.InstrumentTradableID = Value; break;
                case 'operation': item.BuySell = OrderExecutor.getBuySell(Label); break;
                case 'externalid': item.ExternalTradeId = Label; break; // TODO 88232 заменил item.ExtId на item.ExternalTradeId
                case 'rebates': item.Rebates = Value; break;
                case 'execvenue': item.ExecutionVenue = Label; break;
                case 'exchange': item.TradingExchange = Label; break;
                case 'symbol_type': item.SymbolType = Label; break;
                //            |
                // MAM Summary|
                //            V
                case 'accountid': item.AccountId = Value; break;
                case 'account_name': item.AccountName = Value; break;
                case 'date': item.Date = Value; break;
                case 'operationtypeid': item.OperationTypeId = parseInt(Value); break;
                case 'operationtype_name': item.OperationTypeName = Value; break;
                case 'tradableinstrument_name': item.TradableInstrumentName = Value; break;
                case 'open_price': item.OpenPrice = Value; break;
                case 'close_price': item.ClosePrice = Value; break;
                case 'currency_name': item.CurrencyName = Label; break;
                case 'side': item.IsBuy = Value === 'Buy'; break;
                //            |
                // Common     |
                //            V
                case 'leverage': item.Leverage = Value; break;
                case 'description': item.Description = Label; break;
                // OR
                case 'Description': item.Description = Label; break;
                //            |
                // Statement  |
                //            V
                case 'createdat': item.Date = new Date(parseInt(Label)); break;
                case 'operationtype': item.OperationType = Label; break;
                case 'accountoperationid': item.OperationID = Label; break;
                case 'openprice': item.OpenPrice = Label; break;
                case 'closeprice': item.ClosePrice = Label; break;
                case 'opentime': item.TradeOpeningDate = new Date(parseInt(Label)); break;
                case 'quantity': item.Quantity = Value; break;
                case 'currency': item.Currency = Label; break;
                case 'crossprice': item.FXrate = Label; break;
                case 'positionid': item.PositionID = Label; break;

                case 'order_book_bought': item.boughtString = Label; break;
                case 'order_book_sold': item.soldString = Label; break;

                case 'trades_bought': item.boughtString = Label; break;
                case 'trades_sold': item.soldString = Label; break;

                //                              |
                // Statement and  Filled orders |
                //                              V
                case 'tradeid': item.TradeId = Label; break;

                // default: console.log(columns[j].Name + "    " + Label);
                }
            }
            const _Route = DataCache.getRouteByTextName(item.Route);

            const insStringName = InsName;

            const trId = item.InstrumentTradableID ? item.InstrumentTradableID : InsName;
            const rId = _Route ? _Route.RouteId : item.Route;
            if (rId) {
                InsName = InstrumentUtils.GetFullName(trId.toString(), rId.toString());
            }

            item.Instrument = DataCache.getInstrumentByName(InsName);
            if (item.Instrument == null) {
                if (insStringName) {
                    item.InstrumentStr = insStringName;
                }
                hasEmptyInstr = true;
            }

            if (config.reportType === ReportType.REPORT_TRADES) {
                if (item.OpenCrossPrise !== 0) {
                    item.PnL = item.PnL / item.OpenCrossPrise;
                    item.Comission = item.Comission / item.OpenCrossPrise;
                // rebates
                }
            }

            if (item.BuySell === -1) {
                continue;
            }

            objArr.push(item);
        }

        const insPromises = [];
        const reqsObjArr = {};
        const finishFunc = (key, objArr): void => {
            if (key === ReportType.REPORT_ORDERBOOK) {
                const fromDate = config.getParameter(EReportRequestParameter.FromDate);
                const toDate = config.getParameter(EReportRequestParameter.ToDate);
                const isAddDaily = DateTimeUtils.IsCurrentTimeInInterval(+fromDate, +toDate);

                objArr = DataCache.FOrderHistoryCache.getReportHistory(objArr, isAddDaily);
                DataCache.FOrderHistoryCache.orderHistoryArray = objArr;
                this.reportsByComplited.set(config.reportType, true);
            } else if (key === ReportType.REPORT_TRADES) {
                DataCache.filledOrdersArray = objArr;
                this.reportsByComplited.set(config.reportType, true);
            } else if (key === ReportType.REPORT_STATEMENT) {
                DataCache.StatementArray = objArr;
                this.reportsByComplited.set(config.reportType, true);
            } else if (key === ReportType.REPORT_MAMACCOUNTSUMMARY) {
                DataCache.MamSummaryArray = objArr;
                this.reportsByComplited.set(config.reportType, true);
            }
        };

        if (needReqIns && hasEmptyInstr) {
            for (let i = 0; i < objArr.length; i++) {
                const item = objArr[i];
                if (item.Instrument) {
                    continue;
                }

                const trId = item.InstrumentTradableID;
                const _Route = DataCache.getRouteByTextName(item.Route);
                const rId = _Route ? _Route.RouteId : item.Route;
                if (!trId) {
                    continue;
                }

                const InsName = InstrumentUtils.GetFullName(trId.toString(), rId?.toString());

                const exist = !!reqsObjArr[InsName];
                if (!exist) {
                    reqsObjArr[InsName] = [];
                }

                const arr = reqsObjArr[InsName];
                arr.push(item);

                reqsObjArr[trId] = arr;

                if (exist) {
                    continue;
                }

                const tmpR = DataCache.getInstrumentByInstrumentTradableID_NFL(trId, rId, null, true);
                insPromises.push(tmpR);
            }

            return await Promise.all(insPromises).then(function (instruments) {
                for (let i = 0; i < instruments.length; i++) {
                    const ins = instruments[i];
                    if (!ins) {
                        continue;
                    }

                    const key = ins.GetInteriorID();

                    const arrI = reqsObjArr[key] || reqsObjArr[ins.InstrumentTradableID];
                    if (!arrI) {
                        continue;
                    }

                    for (let j = 0; j < arrI.length; j++) {
                        arrI[j].Instrument = ins;
                    }
                }

                finishFunc(config.reportType, objArr);

                return objArr;
            });
        }

        finishFunc(config.reportType, objArr);

        return objArr;
    }

    private parseReportMore (config: ReportRequestConfig, msg): ReportMoreModel[] {
        if (!msg) { return []; }

        const data = this.getRowsAndColumnsFromReportResponse(msg);
        const rows = data.rows;
        const columns = data.columns;
        const reportsMore: ReportMoreModel[] = [];

        for (let i = 0, len = rows.length; i < len; i++) {
            const row = rows[i];
            const cell = row.Cells;
            if (cell[0].Label === AllowedReport.TOTAL_ROW_LABEL) {
                continue;
            }

            const reportMore = new ReportMoreModel();
            for (let j = 0, length = columns.length; j < length; j++) {
                const Value = cell[j].Value;

                switch (config.reportType) {
                case ReportType.REPORT_ORDER_HISTORY_LOAD_MORE:
                    switch (columns[j].Name) {
                    case 'createdat': reportMore.timestamp = Value; break;
                    case 'orderid': reportMore.reportId = Value; break;
                    }
                    break;
                case ReportType.REPORT_FILLED_ORDER_LOAD_MORE:
                    switch (columns[j].Name) {
                    case 'time': reportMore.timestamp = Value; break;
                    case 'tradeid': reportMore.reportId = Value; break;
                    }
                    break;
                case ReportType.REPORT_STATEMENTS_LOAD_MORE:
                    switch (columns[j].Name) {
                    case 'createdat': reportMore.timestamp = Value; break;
                    case 'accountoperationid': reportMore.reportId = Value; break;
                    }
                    break;
                case ReportType.REPORT_MAM_SUMMARY_LOAD_MORE:
                    switch (columns[j].Name) {
                    case 'createdat': reportMore.timestamp = Value; break;
                    case 'accountoperationid': reportMore.reportId = Value; break;
                    }
                    break;
                }
            }
            reportsMore.push(reportMore);
        }
        return reportsMore;
    }

    private getRowsAndColumnsFromReportResponse (msg): any {
        const table = msg.Tables?.length ? msg.Tables[0] : null;

        if (!table) return null;

        const columns: any[] = table.ReportColumns;
        const rows = [];
        const reportRows = table.ReportRows;

        for (let i = 0, len = reportRows.length; i < len; i++) {
            const reportRow = reportRows[i];
            const cells = [];
            rows.push({ Cells: cells });

            for (let j = 0; j < reportRow.length; j++) {
                const rawVal = reportRow[j];
                const val = this.getReportValueByType(rawVal, columns[j].Type);

                cells.push({ Label: rawVal.toString(), Value: val });
            }
        }

        return {
            rows,
            columns
        };
    }

    private getRowsAndColumnsFromOldXMLMessage (reportResponseMessage): any // From XMLString
    {
        if (!reportResponseMessage) {
            return { rows: [], columns: [] };
        }

        const parser = new DOMParser();
        const xml = parser.parseFromString(reportResponseMessage.xmlString, 'text/xml');
        const table = xml.querySelector('table');
        const th = table.querySelector('th');
        const trArr = table.querySelectorAll('tr');

        if (!th) {
            return { rows: [], columns: [] };
        }

        const columns = [];

        const thChildren = th.childNodes;
        for (let i = 0, len = thChildren.length; i < len; i++) {
            const headerItem = thChildren[i] as Element;
            let type = null;
            if (!headerItem.getAttribute) {
                continue;
            }

            switch (headerItem.getAttribute('type')) {
            case 'FLOAT':
            case 'NUMBER':
                type = QuickTableComparingType.Double;
                break;
            case 'INTEGER':
                type = QuickTableComparingType.Int;
                break;
            case 'DATE':
                type = QuickTableComparingType.DateTime;
                break;
            default:
                type = QuickTableComparingType.String;
            }

            columns.push({
                Type: type,
                Name: headerItem.textContent
            });
        }

        const rows = [];

        for (let i = 0, len = trArr.length; i < len; i++) {
            const tr = trArr[i];
            const cells = [];
            rows.push({ Cells: cells });

            const tdArr = tr.childNodes;
            for (let j = 0, j_len = tdArr.length; j < j_len; j++) {
                const td = tdArr[j];
                const rawVal = td.textContent;
                const val = this.getReportValueByType(rawVal, columns[j].Type);

                cells.push({ Label: rawVal.toString(), Value: val });
            }
        }

        return {
            rows,
            columns
        };
    }

    public getReportValueByType (rawVal, type): number {
        let val = null;

        switch (type) {
        case QuickTableComparingType.Double:
            val = parseFloat(rawVal);
            break;
        case QuickTableComparingType.Int:
        case QuickTableComparingType.Long:
            val = parseInt(rawVal);
            break;
        default:
            val = rawVal;
        }

        return val;
    }

    public clear (): void {
        this.reportsByComplited.set(ReportType.REPORT_ORDERBOOK, false);
        this.reportsByComplited.set(ReportType.REPORT_TRADES, false);
        this.reportsByComplited.set(ReportType.REPORT_STATEMENT, false);
        this.reportsByComplited.set(ReportType.REPORT_MAMACCOUNTSUMMARY, false);
    }
}

export const ReportManager = new _ReportManager();
