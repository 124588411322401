// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { PingMessage } from '../Messages/PingMessage';
import { MessageProcessorBase } from './MessageProcessorBase';

export class PingMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): PingMessage[] {
        return [new PingMessage(fieldSet)];
    }
}
