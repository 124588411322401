// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Event } from '../Event/Event';
import { EventSource, EventType } from '../Event/EventConstants';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { Resources } from '@shared/localizations/Resources';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { ReportMessageImportanceLevel } from '@shared/utils/Enums/Constants';
import { BusinessRejectMessagesKey, BusinessRejectTextCodeDict } from '../../BusinessRejectUtils/BusinessRejectUtils';
import { type DirectReportMessage } from '@shared/utils/DirectMessages/DirectReportMessage';
import { UserIndexedDB, UserIndexedDBKey, UserIndexedDBStore } from '../../DataBase/UserIndexedDB';
import { UserManager } from '@shared/utils/Managers/UserManager';
import { ClientType } from '@shared/utils/ClientType';

class _EventCache {
    public static AddTradingToLog = true;
    private get userId (): string | null { return UserManager.getUserKey(); }
    private get beforeLoginKey (): string { return UserManager.getBeforeLoginKey(); }
    private readonly indexedDB = UserIndexedDB;
    private events: Event[] = [];

    public OnAddEvent = new CustomEvent();
    public OnClearEvents = new CustomEvent();

    public loaded (): void {
        this.deleteFromDB();
    }

    public Clear (): void {
        this.events = [];
        this.OnClearEvents.Raise();
    }

    public GetEvents (): Event[] {
        return this.events.slice();
    }

    public Add (ev: Event, isAddToRT: boolean = true): void {
        // +++yura - если операция торговая - добавляем в лог в соответствии с настройкой
        if (ev.EventType === EventType.Trading && !_EventCache.AddTradingToLog) { return; }

        if (isAddToRT) { this.addEventToRealTime(ev); }
        this.addEventToDB(ev);
    }

    public AddException (ex): void {
        const timestamp = new Date();

        const ev = new Event({
            DateTime: timestamp,
            Description: ex.message,
            EventType: EventType.Exception
        });
        const isAddToRT = !ClientType.IsWebMobile();
        this.Add(ev, isAddToRT);
    }

    public isDailyLimitExist (ev: Event): boolean {
        if (ev.BusinessRejectCode !== BusinessRejectTextCodeDict[BusinessRejectMessagesKey.DailyLimit]) {
            return false;
        }

        const events = this.GetEvents();
        for (const event of events) {
            if (event.BusinessRejectCode === ev.BusinessRejectCode) {
                return true;
            }
        }

        return false;
    }

    public FromReportMessage (msg: DirectReportMessage): Event {
        let str = '';
        let acc = '';
        // let productTypeString = '';

        const data = msg.Data;
        const len = data.length;
        for (let i = 0; i < len; i++) {
            const row = data[i];

            const cell0 = row[0];
            const cell1 = Resources.getResource(row[1]);

            if (cell0 === 'Account') {
                acc = cell1;
            }

            // if (cell0 === ORDER_PARAMETER_PRODUCT_TYPE) {
            //     productTypeString = cell1;
            // }

            if (!Resources.isHidden('reports.' + cell0)) {
                str += cell0 + ':' + cell1 + ';';
            }
        }

        let source = msg.ImportanceLevel === ReportMessageImportanceLevel.Critical
            ? EventSource.MarginCall
            : EventSource.In;

        if (msg.reject && msg.BusinessRejectCode != 392) {
            source = EventSource.Refused;
        }

        return new Event({
            DateTime: DateTimeUtils.DateTimeUtcNow(),
            Action: Resources.getResource(msg.Name),
            Description: str,
            // TODO. UGLY. Refactor. Remove when BusinessRejectMessage is treated correctly.
            EventType: EventType.Trading,
            EventSource: source,
            Account: acc,
            ProductType: msg.productType,
            // TODO. Ok? Pass instrument id maybe?
            Instrument: msg.Instrument,
            Roundtrip: msg.Roundtrip,
            BusinessRejectCode: msg.BusinessRejectCode
        });
    }

    public log (action, description: any = undefined, eventType: any = undefined, eventSource: any = undefined): void {
        if (!action) return;

        this.Add(new Event({
            DateTime: DateTimeUtils.DateTimeUtcNow(),
            Action: action,
            Description: description,
            EventType: eventType,
            EventSource: eventSource
        }));
    }

    public async getEventsFromDB (): Promise<Event[]> {
        const userEventObjects = await this.indexedDB.getAllByIndex<Event>(UserIndexedDBStore.EVENT_LOG, UserIndexedDBKey.USER_ID, this.userId);
        const commonEventObjects = await this.indexedDB.getAllByIndex<Event>(UserIndexedDBStore.EVENT_LOG, UserIndexedDBKey.USER_ID, this.beforeLoginKey);
        const eventObjects = [...userEventObjects, ...commonEventObjects];
        const events: Event[] = [];

        eventObjects.forEach(eventObject => {
            const event = new Event({
                DateTime: eventObject.DateTime,
                Action: eventObject.Action,
                Description: eventObject.DescriptionItems,
                EventType: eventObject.EventType,
                EventSource: eventObject.Source
            });
            event.RouteID = eventObject.RouteID;
            event.InstrumentTradableID = eventObject.InstrumentTradableID;

            events.push(event);
        });
        events.sort((a, b) => a.timestamp - b.timestamp);

        return events;
    }

    private addEventToRealTime (event: Event): any {
        this.events.push(event);
        this.OnAddEvent.Raise(event);
    }

    private addEventToDB (event: Event): any {
        event.userId = this.userId ?? this.beforeLoginKey;
        void this.indexedDB.addOrUpdate(UserIndexedDBStore.EVENT_LOG, event);
    }

    private deleteFromDB (): void {
        // delete all records older than 7 days in UTC.
        const upperBoundUTC = Date.now() - 7 * 24 * 60 * 60 * 1000;
        const lowerBoundUTC = 0;
        void this.indexedDB.deleteInRangeByIndex(UserIndexedDBStore.EVENT_LOG, UserIndexedDBKey.TIMESTAMP, lowerBoundUTC, upperBoundUTC);
    }
}

export const EventCache = new _EventCache();
