// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { AlertUtils } from '../../../Utils/Alert/AlertUtils';
import { CreateAlertConditionSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Localizations/Resources';
import { AlertCondition } from '../../../Utils/Alert/AlertConstants';
import { type IAlertConditionItem } from '../../../Utils/Alert/AlertItems';
import { ResourcesManager } from '../../../Commons/properties/ResourcesManager';

export class CreateAlertConditionSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'CreateAlertConditionSelector'; }

    public override oninit (): void {
        super.oninit();

        this.repopulate();
        this.observe('selectedItem', this.updateAlertCondition);
        this.observe('value', this.updateAlertConditionValue);

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public updateAlertCondition (selectedItem: IAlertConditionItem): void {
        if (!isNullOrUndefined(selectedItem)) {
            void this.set({ value: selectedItem.value });
        }
    }

    public updateAlertConditionValue (newV: AlertCondition, oldV): void {
        const items: IAlertConditionItem[] = this.get('items');
        if (!isValidArray(items)) { return; }

        for (let i = 0; i < items.length; i++) {
            if (items[i].value === newV) {
                void this.set({ selectedItem: items[i] });
                break;
            }
        }
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public repopulate (): void {
        const items: IAlertConditionItem[] = AlertUtils.GetAlertConditionItems();
        void this.set({ items, selectedItem: items[AlertCondition.MORE] });
    }

    public localize (): void {
        void this.set('label', Resources.getResource('screen.Alerts.Label.Condition'));
    }
}

ContainerControl.extendWith(CreateAlertConditionSelector, {
    template: CreateAlertConditionSelectorTemplate,
    data: function () {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedItem: null,
            value: -1,

            width: 198
        };
    }
});
