// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Rectangle } from '../../Commons/Geometry';
import { TerceraTextBoxTemplate } from '../../templates.js';
import { Control } from './Control';
import { ControlsTypes } from '../../Controls/UtilsClasses/FactoryConstants';
import $ from 'jquery';
import { type ParamType } from '../../Commons/cache/AllowedReportConstants';

export class TerceraTextBox extends Control {
    public inputElement: any | null = null; // ref to <input> html element
    public paramType: ParamType;

    public override getType (): ControlsTypes { return ControlsTypes.TerceraTextBox; }

    public override oninit (): void {
        super.oninit();
        this.on('focus', this.private_Focus);
        this.on('blur', this.private_Blur);
        this.observe('text', this.private_textChange);
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.observe('focused', this.onFocusedChanged); // uncomment 83805
    }

    public onSubmitEvent (event): void {
        if (event?.original) {
            event.original.preventDefault();
        };
    }

    public getInputElement (): any {
        if (isNullOrUndefined(this.inputElement)) {
            const arr = $(this.find('div input'));
            if (arr) {
                this.inputElement = arr[0];
            }
        }

        return this.inputElement;
    }

    public onFocusedChanged (newVal): void {
        const input = this.getInputElement();
        if (newVal) {
            input.focus();
        } else {
            input.blur();
        }
    }

    public override getAbsoluteRectangle (): Rectangle {
        const $root = $(this.find('div'));
        const $window = $(window);
        const offset = $root.offset();

        return new Rectangle(
            offset.left - $window.scrollLeft(),
            offset.top - $window.scrollTop(),
            $root.width(),
            $root.height());
    }

    protected private_textChange (newText: string, oldText: string): void {
        const result = this.validateValue(newText);
        if (!result) // отменить  изменение и всплытие события если символы недопустимы!!!
        {
            void this.set({ text: oldText });
        }
        // ! надо сохранять последний текст? !
        this.fire(TextBoxEvents.TextChanged, this.get('text'));
    }

    private private_Focus (e): void {
        if (!this.get('enabled')) return;
        if (this.get('skipfocus')) {
            void this.set('skipfocus', false);
            e.node.setSelectionRange(e.node.value.length, e.node.value.length);
            return;
        }
        // Cross-browser hack for the different "input.select()" behaviours after focusing.
        // https://www.impressivewebs.com/input-select-correct-behaviour/
        const inputEl = e.node;
        // setTimeout(function () { inputEl.select(); }, 0);
        inputEl.select();

        void this.set('focused', true);
    }

    private private_Blur (): void {
        void this.set('focused', false);
    }

    public selectAll (): void {
        this.getInputElement().select();
    }

    public validateValue (newText): boolean {
        const regexp = this.get('inputPatern');
        if (regexp) {
            return regexp.test(newText);
        }

        return true;
    }
}

export enum TextBoxEvents {
    KeyDown = 'keyDown',
    TextChanged = 'TextChanged',
    KeyUp = 'keyUp'
};

Control.extendWith(TerceraTextBox, {
    template: TerceraTextBoxTemplate,
    data: function () {
        return {
            maxLength: 200, // максимальная длинна
            isReadOnly: false, // редактируемое поле
            placeholder: '', // текст внутри поля формы, который исчезает при получении фокуса
            text: '', // значение
            inputPatern: null, // патерн допустимых элементов g - флаг не использовать!  пример new RegExp('[1-9]')
            haveError: false,
            autofocus: false,
            inputType: 'text', // input element's type attribute (text/password)
            elementStyle: 'js-input-container',
            additionalClass: ''
        };
    }
});
