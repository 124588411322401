// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type VerticalLineDataCacheTool } from '../../Commons/cache/Tools/VerticalLineDataCacheTool';
import { ToolView } from './ToolView';

export class VerticalLineToolView extends ToolView<VerticalLineDataCacheTool> {
    public override IsSelectCheck (x: number, y: number): boolean {
        const pX = this.screenPoints[0][0];
        const TOOL_DX_HALF = ToolView.TOOL_DX / 2;

        const xLeft = pX - TOOL_DX_HALF;
        const xRight = pX + TOOL_DX_HALF;

        return x >= xLeft && x <= xRight;
    }

    public override Draw (gr, ww, param): void {
    // Ловим ошибки
    /*
    if (screenPoints[0][0] >= ProMath.infinity || screenPoints[0][1] >= ProMath.infinity || screenPoints[0][0] <= ProMath.infinityMinus || screenPoints[0][1] <= ProMath.infinityMinus)
        return;
    */

        const pen = this.dataCacheTool.Pen;
        const x = this.screenPoints[0][0];

        const CR = ww.ClientRectangle;
        const CRY = CR.Y;

        gr.DrawLine(pen, x, CRY, x, CRY + CR.Height);
        super.Draw(gr, ww, param);
    }

    public override DrawSelectedPoint (gr, ww, x: number, y: number): void {

    }

    public override DrawSelectedLine (gr, ww, x1: number, y1: number, x2: number, y2: number): void {
        const CR = ww.ClientRectangle;
        const CRY = CR.Y;

        super.DrawSelectedLine(gr, ww, x1, CRY, x2, CRY + CR.Height);
    }
}
