// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraSymbolLookupBaseDataProvider } from '../../NoNFixedListCore';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { BaseSettings } from '../../Settings/BaseGeneralSettingsWrapper';
import { WatchlistSheetsStorageManager } from '../../../Utils/Managers/WatchlistManager/WatchlistSheetsStorageManager';
import { WatchlistManager } from '../../../Utils/Managers/WatchlistManager/WatchlistManager';

class _TvDefaultWatchlistProvider {
    public async getDefaultWatchlist (): Promise<string[]> {
        try {
            await WatchlistSheetsStorageManager.syncInOut();
        } catch (e) {
            console.warn('TvDefaultWatchlistProvider::Error load session settings');
            return [];
        }
        BaseSettings.reset(); // reset settings to default
        const wlItems = WatchlistManager.getItems();

        if (wlItems.length === 0) { return []; }

        const dataProvider = new TerceraSymbolLookupBaseDataProvider();
        const defaultItem = wlItems[0]; // Makhnytsky said: "We will use the first list as default"
        const symbolIDs = defaultItem.getSymbolIds();
        const symbolNames: string [] = [];

        const instruments = await Promise.all(
            // TODO: ask from server during login in InitDataRequest for default watchlist
            // or request all symbols from server during one request
            symbolIDs.map(async (symbolID) => {
                const instrument = await dataProvider.getInstrumentByName(symbolID);
                return { symbolID, instrument };
            })
        );

        instruments.forEach(({ instrument }) => {
            if (!isNullOrUndefined(instrument)) {
                symbolNames.push(TvSymbolConvertor.getFullname(instrument)); // TODO: replace with ticker?
            }
        });

        return symbolNames;
    }
}

export const TvDefaultWatchlistProvider = new _TvDefaultWatchlistProvider();
