// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { UserWebStorageInstance } from '../../user-web-storage';
import { BaseStorageManager } from '@shared/utils/Managers/BaseStorageManager';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { UserIndexedDB, UserIndexedDBStore } from '@shared/commons/DataBase/UserIndexedDB';
import { DynProperty } from '@shared/commons/DynProperty';
import { WDSettings } from '../settings/WDGeneralSettings';
import { CurrentLang } from '@shared/localizations/Resources';
import { type RemoteDBRecord } from '@shared/utils/DBRecords';

class _WDSettingsStorageManager extends BaseStorageManager<DynProperty[]> {
    public onRestore (dynProperties: DynProperty[]): void {
        if (dynProperties?.length > 0) {
            SessionSettings.applyNewSettings(dynProperties, true, CurrentLang);
        } else {
            WDSettings.reset();
        }
    }

    protected serializeData (): string {
        return DynProperty.serialize(SessionSettings.Properties(true));
    }

    protected deserializeData (data: string): DynProperty[] {
        return DynProperty.deserialize(data);
    }

    protected async fetchRemoteSettings (): Promise<RemoteDBRecord> {
        return await UserWebStorageInstance.settings.get();
    }

    protected async sendRemoteSettings (data: RemoteDBRecord): Promise<void> {
        await UserWebStorageInstance.settings.post(data);
    }
}

export const WDSettingsStorageManager = new _WDSettingsStorageManager(UserIndexedDB, UserIndexedDBStore.WD_SETTINGS);
