// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type ResVector } from './ResVector';

export class DoubleMatrix {
    public Length: number;
    public FArray: any[];

    constructor (length?: number) {
        this.Length = length;
        this.FArray = new Array(length);
    }

    // #region this[int index]
    public get (index: number): any {
        return this.FArray[index];
    }

    public set (index: number, value): void {
        this.FArray[index] = value;
    }
    // #endregion this[int index]

    public Resize (new_size: number): void {
        const newArray = new Array[new_size]();
        for (let i = 0; i < Math.min(new_size, this.FArray.length); i++) {
            newArray[i] = this.FArray[i];
        }

        this.FArray = newArray;
        this.Length = this.FArray.length;
    }

    public Dispose (): void {
        if (this.FArray != null) {
            for (let i = 0; i < this.FArray.length; i++) {
                if (this.FArray[i].Dispose) {
                    this.FArray[i].Dispose();
                }
            }
            this.FArray = null;
        }
    }

    public Clear (): void {
        for (let i = 0, len = this.FArray.length; i < len; i++) {
            this.FArray[i].Clear();
        }
    }
}

/// ///////////////////////////////////////////////////////////////////////////////////////////
export class DoubleVector {
    public FArray: any[];
    public Length: number;

    constructor (length: number) {
        this.FArray = new Array(length);
        this.Length = length;
    }

    // #region this[int index]
    public get (index): any {
        return this.FArray[index];
    }

    public set (index, value): void {
        this.FArray[index] = value;
    }
    // #endregion this[int index]

    public Dispose (): void {
        this.FArray = null;
    }

    public Clear (): void {
        for (let i = 0, len = this.FArray.length; i < len; i++) {
            this.FArray[i].Clear();
        }
    }
}

/// ///////////////////////////////////////////////////////////////////////////////////////////
/// <summary>
/// Расширяемый массив даблов (на базе arraylist)
/// </summary>
export class ExpandDoubleVector {
    public FList: any[];
    public FShift: number;

    constructor (counts?, shift?) {
        this.FList = new Array(counts || 0);
        this.FShift = shift || 0;
    }

    public Add (value): void {
        this.FList.push(value);
    }

    public Clear (): void {
        this.FList.length = 0;
    }

    // #region this[int index]
    public get (index): any {
        index -= this.FShift;
        return (index >= 0 && index < this.FList.length) ? this.FList[index] : 0.0;
    }

    public set (index, value): void {
        if (index >= 0 && index < this.FList.length) {
            this.FList[index] = value;
        }
    }
    // #endregion this[int index]

    get Length (): number {
        return this.FList.length;
    }

    set Length (value: number) {
        this.FList.length = 0;
        this.FList = this.FList.concat(new Array(value));
    }

    // Смещение вдоль вектора
    get Shift (): number { return this.FShift; }
    set Shift (value) { this.FShift = value; }

    public Dispose (): void {
        if (this.FList != null) {
            this.FList.length = 0;
        }
    }
}
