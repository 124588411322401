// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectYTMMessage } from '../../../../Utils/DirectMessages/DirectYTMMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class YTMMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectYTMMessage[] {
        const message = new DirectYTMMessage();

        let tickId = -1;

        const len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_INSTRUMENT_ID) {
                tickId = fixField.Value;
                message.InstrumentID = tickId;
            } else if (fixField.FieldId === FieldsFactory.FIELD_ROUTE_ID) {
                message.RouteId = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_YIELD_RATE_VALUE) {
                message.YieldRate = fixField.Value;
            }
        }

        return [message];
    }
}
