// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '../../../../Utils/Account/AccountFeature';
import { Account } from '../../../cache/Account';

export class TvAccountTodaysResultData {
    public todaysGross: number = 0;
    public todaysNet: number = 0;
    public todaysFees: number = 0;
    public todaysVolume: number = 0;
    public todaysTrades: number = 0;

    public update (acc: Account): boolean {
        let isNeedUpdate = false;

        const todaysGross = Account.GetAccountFeature(AccountFeature.TodayGrossPNL, acc, acc.assetBalanceDefault);
        if (todaysGross !== this.todaysGross) {
            this.todaysGross = todaysGross;
            isNeedUpdate = true;
        }

        const todaysNet = Account.GetAccountFeature(AccountFeature.TodaysNetProfit, acc, acc.assetBalanceDefault);
        if (todaysNet !== this.todaysNet) {
            this.todaysNet = todaysNet;
            isNeedUpdate = true;
        }

        const todaysFees = Account.GetAccountFeature(AccountFeature.TodaysFee, acc, acc.assetBalanceDefault);
        if (todaysFees !== this.todaysFees) {
            this.todaysFees = todaysFees;
            isNeedUpdate = true;
        }

        const todaysVolume = Account.GetAccountFeature(AccountFeature.TodayVolume, acc, acc.assetBalanceDefault);
        if (todaysVolume !== this.todaysVolume) {
            this.todaysVolume = todaysVolume;
            isNeedUpdate = true;
        }

        const todaysTrades = Account.GetAccountFeature(AccountFeature.TodayTrades, acc, acc.assetBalanceDefault);
        if (todaysTrades !== this.todaysTrades) {
            this.todaysTrades = todaysTrades;
            isNeedUpdate = true;
        }

        return isNeedUpdate;
    }
}
