// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MDButton } from './MDButton';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { TerceraButtonEvents, TerceraButtonStyle } from '@shared/utils/Enums/ButtonEnums';

export class BuySellMDButton extends MDButton {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.observe('side', this.onSideChanged);
    }

    public onSideChanged (side): void {
        const buy = side === OperationType.Buy;
        void this.set(
            {
                terceraButtonStyle: TerceraButtonStyle[buy ? 'Buy' : 'Sell'],
                locKey: buy ? 'panel.Level2.oe.BuyButton' : 'panel.Level2.oe.SellButton'
            });
    }

    override private_MouseClick (e): void {
        if (e?.original) {
            this.lastMouseClickX = e.original.clientX;
            this.lastMouseClickY = e.original.clientY;
        }

        if (!this.get('enabled')) {
            return;
        }

        const side = this.get('side');
        this.fire(TerceraButtonEvents.onClick, side);
    };
}

MDButton.extendWith(BuySellMDButton, {
    data: function () {
        return {
            side: null
        };
    }
});
