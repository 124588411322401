// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type LineStyle } from './IndicatorScriptBaseEnums';

export class LevelLine {
    public LineNumber = -1;
    public Name: string;
    public color: any;
    public width: number;
    public style: LineStyle;
    public Vector: any = null;

    private level: number;

    constructor (name: string, level: number, color: any, width: number, style: LineStyle) {
        this.Name = name;
        this.color = color;
        this.width = width;
        this.style = style;
        this.level = level;
    }

    get Level (): number { return this.level; }
    set Level (value) {
        this.level = value;
        if (this.Vector !== null) {
            this.Vector.Value = this.level;
        }
    }
}
