// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { QuickTreeNode } from '../elements/QuickTree/QuickTreeNode';
import { TerceraQuickTree } from '../elements/QuickTree/TerceraQuickTree';
import { TerceraWindowBase } from './TerceraWindowBase';

export class QuickTreeViewScreen extends TerceraWindowBase {
    public quickTree: any = null;

    public override getType (): string { return 'QuickTreeViewScreen'; }

    public override oncomplete (): void {
        super.oncomplete();
        this.OnResize.Subscribe(this.onResize, this);
    }

    public onResize (): void {
        this.quickTree.set({ width: this.get('width'), height: this.get('height') - 65 });
    }

    public Populate (data): void {
        if (data == null) {
            return;
        }

        for (let i = 0; i < data.length; i++) {
            const nodeText = data[i];
            if (!Array.isArray(nodeText)) {
                const node = new QuickTreeNode(nodeText);
                this.quickTree.quickTree.AddNode(node);
            } else {
                this.AddNodesRcursive(data[i], this.quickTree.quickTree.nodeCollection[this.quickTree.quickTree.nodeCollection.length - 1]);
            }
        }
        this.quickTree.quickTree.Draw(true);
    }

    public AddNodesRcursive (data, parentNode): void {
        parentNode.collapsed = false;
        for (let i = 0; i < data.length; i++) {
            const nodeText = data[i];
            if (!Array.isArray(nodeText)) {
                const node = new QuickTreeNode(nodeText);
                parentNode.AddChildNode(node);
            } else {
                this.AddNodesRcursive(data[i], parentNode.childNodes[parentNode.childNodes.length - 1]);
            }
        }
    }

    public override dispose (): void {
        this.OnResize.UnSubscribe(this.onResize, this);

        super.dispose();
    }

    public static Show (data): void {
        const treeView = new QuickTreeViewScreen();
        MainWindowManager.MainWindow.addControl(treeView);
        treeView.setBounds(50, 50, 390, 330);

        const quickTree = new TerceraQuickTree();
        quickTree.setBounds(0, 0, 390, 330 - 65);
        treeView.addControl(quickTree);
        treeView.quickTree = quickTree;

        treeView.Populate(data);
    }
}

TerceraWindowBase.extendWith(QuickTreeViewScreen, {
    data: function () {
        return {
            zIndex: 400
        };
    }
});
