// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { HashTable } from './HashTable';

export class HashtableLightCount extends HashTable {
    private hasValues = false;

    get HasValues (): boolean { return this.hasValues; }
    set HasValues (value) {
        this.hasValues = value;
    }

    public override Set (key, value): any {
        super.Set(key, value);
        this.HasValues = true;
        return this.Get(key);
    }

    public override Clear (key): void {
        super.Clear(key);
        this.HasValues = false;
    }

    get Count (): number { return this.counter; }
}
