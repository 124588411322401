// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolRayType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';

export class BaseGannDataCacheTool extends DataCacheTool {
    public mirrored: boolean;

    constructor () {
        super();
        this.InitBlock();
    }

    protected InitBlock (): void {
        super.InitBlock();
        this.mirrored = false;
        this.RayType = DataCacheToolRayType.Ray;
    }
}
