// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ApplicationInfo } from '@shared/commons/ApplicationInfo';
import { Resources } from '@shared/localizations/Resources';
import { TerceraBaseWindowTemplate, TerceraAboutScreenTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';

// constructor
export class TerceraAboutScreen extends TerceraWindowBase {
    constructor () { super(); }

    public override getType (): string { return 'TerceraAboutScreen'; }

    public override oncomplete (): void {
        super.oncomplete();
        this.updateBrandingImage();
        this.localize();
        this.center();
    }

    public override localize (): void {
        let version = 'Not found';
        let date = 'Not found';
        if (ApplicationInfo.BuildVersionData) {
            const w_BWD = ApplicationInfo.BuildVersionData;
            version = w_BWD.version + '.' + w_BWD.build + '.' + w_BWD.build_version;
            if (w_BWD.developer_version && w_BWD.developer_version !== '0') {
                version += ('-' + w_BWD.developer_version);
            }
            date = w_BWD.date_day;
        }
        void this.set({
            header: document.title == 'web' ? Resources.getResource('screen.about.titleWeb') : Resources.getResource('screen.about.title') + ' ' + document.title,
            copyrightText: Resources.getResource('screen.about.copyright'),
            versionText: Resources.getResource('screen.about.versionWeb') + ' ' + version,
            buildDateText: Resources.getResource('screen.about.dateBuild') + ' ' + date
        });
    }
}

TerceraWindowBase.extendWith(TerceraAboutScreen, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            movable: true,
            showHeader: true,
            showFooter: false,
            resizable: false,
            canSelect: false,
            // alwaysMiddle: true,  // #89762
            focused: true,
            showFullscreenCloud: true,
            width: 309,
            height: 439,
            versionText: 'Build version: 3.1312',
            buildDateText: 'Date of build: 13.12.2013 11:12:13',
            copyrightText: '2017-2025 TraderEvolution Global LTD.',
            style_addition_body: 'about-screen-addition-body',
            style_addition_header: 'about-screen-addition-header'
        };
    },
    partials: { bodyPartial: TerceraAboutScreenTemplate }
});
