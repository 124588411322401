// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MathUtils } from '../../Utils/MathUtils';

export function debounce (f, ms): () => void {
    let isCooldown = false;

    return function () {
        if (isCooldown) return;

        f.call(this);

        isCooldown = true;

        setTimeout(() => isCooldown = false, ms);
    };
}

export function throttle (func, ms): () => void {
    let isThrottled = false;
    let savedArgs;
    let savedThis;

    function wrapper () {
        if (isThrottled) { // (2)
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        func.apply(this, arguments); // (1)

        isThrottled = true;

        setTimeout(function () {
            isThrottled = false; // (3)
            if (savedArgs) {
                wrapper.apply(savedThis, savedArgs);
                savedArgs = savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

export function delay (f, ms): () => void {
    let inProcess = false;
    let id = null;
    return function () {
        if (inProcess) {
            clearTimeout(id);
        }
        id = setTimeout(() => {
            inProcess = false;
            f();
        }, ms);

        inProcess = true;
    };
}

export function format_Market_cap (Market_cap): string {
    let abbreviate = '';
    if (Math.abs(Market_cap) >= 1000000000000) {
        Market_cap /= 1000000000000;
        abbreviate = 'T';
    } else if (Math.abs(Market_cap) >= 1000000000) {
        Market_cap /= 1000000000;
        abbreviate = 'B';
    } else if (Math.abs(Market_cap) >= 1000000) {
        Market_cap /= 1000000;
        abbreviate = 'M';
    } else if (Math.abs(Market_cap) >= 1000) {
        Market_cap /= 1000;
        abbreviate = 'K';
    }

    return MathUtils.TruncateDouble(Market_cap, 2).toLocaleString() + abbreviate;
}

// public static(bool isAbbreviate, string strAbbreviate) FormatDoubleAbbreviate(double Market_cap, int precision)
// {
//             string abbreviate = "";

//             bool isAbbreviate = !string.IsNullOrEmpty(abbreviate);
//             string strAbbreviate = isAbbreviate
//         ? TruncateDouble(Market_cap, precision).ToString() + abbreviate
//         : Market_cap.ToString("N" + precision);

//     return (isAbbreviate, strAbbreviate);
// }
