// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MODE_MAIN, MODE_SIGNAL } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iOsMA extends iBuildInIndicator {
    public FFastMaPeriod: any;
    public FSlowMaPeriod: any;
    public FSignalMaPeriod: any;
    public macd: ExpandDoubleVector = new ExpandDoubleVector();
    public ema1: ExpandDoubleVector = new ExpandDoubleVector();
    public ema2: ExpandDoubleVector = new ExpandDoubleVector();

    constructor (fastMAPeriod, slowMaPeriod, signalPeriod, priceType) {
        super(1);

        this.FFastMaPeriod = fastMAPeriod;
        this.FSlowMaPeriod = slowMaPeriod;
        this.FSignalMaPeriod = signalPeriod;
        this.FPriceType = priceType;
    }

    override get Name (): string { return 'iOsMA'; }

    override get Key (): string { return this.DefaultKey; }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.macd.Add(0.0);
        this.ema1.Add(0.0);
        this.ema2.Add(0.0);
    }

    public override Refresh (count, newThread): void {
        this.macd.Dispose();
        this.macd = new ExpandDoubleVector();
        this.ema1.Dispose();
        this.ema1 = new ExpandDoubleVector();
        this.ema2.Dispose();
        this.ema2 = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override OnQuote (): void {
        this.macd[this.macd.Length - 1] = 0;
        const price = this.GetPrice(this.FPriceType, 0);
        this.ema1[this.ema1.Length - 1] = price;
        this.ema2[this.ema2.Length - 1] = price;
        const Parent = this.Parent;
        if (!Parent) {
            return;
        }

        const start = Math.max(this.FFastMaPeriod, this.FSlowMaPeriod);
        if (start > this.Count - 1) {
            return;
        }

        const macdline = IndicatorFunctions.MACDFunction(this.ema1, this.ema2, MODE_MAIN, this.FFastMaPeriod, this.FSlowMaPeriod, this.FSignalMaPeriod, this.macd);
        const signalline = IndicatorFunctions.MACDFunction(this.ema1, this.ema2, MODE_SIGNAL, this.FFastMaPeriod, this.FSlowMaPeriod, this.FSignalMaPeriod, this.macd);
        this.SetValue(0, 0, macdline - signalline);
    }
}
