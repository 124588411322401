// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { WatchlistInvestingTemplate } from '../templates.js';
import { WatchlistRowDataItem } from '../RowItems/WatchlistRowDataItem';
import { openContextMenu } from '../Controls/elements/InvestingMenu';
import { debounce } from '../Utils/InvestingUtils';
import { openOrderEntry } from '../Screen/InvestingOE';
import { ProcessChartVisible } from '../Investing';
import { WL_ListController } from '../Utils/WL_ListController';
import { HistoryType } from '../../Utils/History/HistoryType';
import { DataCache } from '../../Commons/DataCache';
import { Control } from '../../Controls/elements/Control';
import Ractive from 'ractive';

export class WatchlistInvestingPanel extends Ractive {
    public selectedItem: any = null;
    public debounceHandler: () => void;
    public insArr: any[];

    public getType (): string { return 'WatchlistInvestingPanel'; }

    public override oninit (): void {
        this.on('showChartPanelEvent', this.openChartPanelEvent);
        this.on('closeChartPanelClick', this.closeChartPanelClick);
        this.on('moreButtonClickEvent', this.moreButtonClickEvent);
    }

    public override oncomplete (): void {
        this.createHeaderRow();
        this.createRowTable();

        this.debounceHandler = debounce(this.UpdateData, 400);
        Control.Ticker.Subscribe(this.debounceHandler, this);

        WL_ListController.SetOnAddRemHandler(this.AddOrRemInstrument.bind(this));
    }

    public override onteardown (): void {
        WL_ListController.SetOnAddRemHandler(null);
        Control.Ticker.UnSubscribe(this.debounceHandler, this);
    }

    public openChartPanelEvent (index, tableRowItem): void {
        const items = this.get('arrayRowData');
        if (!items) {
            return;
        }

        const newSelectedItem = tableRowItem;
        if (newSelectedItem === this.selectedItem) {
            return;
        }

        if (this.selectedItem) {
            this.selectedItem.setActiveStatus(false);
        }

        newSelectedItem.setActiveStatus(true);
        this.selectedItem = newSelectedItem;

        void this.set({
            isVisibleChart: true,
            openChartItem: items[index].source
        });
    }

    public closeChartPanelClick (): void {
        if (this.selectedItem) {
            this.selectedItem.setActiveStatus(false);
        }

        this.selectedItem = null;
        void this.set('isVisibleChart', false);
    }

    public moreButtonClickEvent (index, tableRowItem, posX: number, posY: number): void {
        const items = this.get('arrayRowData');
        const itemsMenu = this.createMenuItems(items[index]);
        openContextMenu(posX, posY, itemsMenu);
    }

    public UpdateData (): void {
        this.createRowTable();
    }

    public createHeaderRow (): void {
        const arrayHeaderColumn = [
            { Name: 'Symbol' },
            { Name: 'Last' },
            { Name: 'Change' }
        ];

        void this.set({ arrayHeaderColumn });
    }

    public makeInsSubs (InstrumentTradableID, Route, InteriorID): void {
        const dc = DataCache;
        const qc = dc.FQuoteCache;

        void dc.getInstrumentByInstrumentTradableID_NFL(InstrumentTradableID, Route, InteriorID).then((ins) => {
            if (!ins) {
                return;
            }

            qc.addListener(ins, this, HistoryType.QUOTE_LEVEL1);
            qc.addListener(ins, this, HistoryType.QUOTE_TRADES);
            this.insArr.push(ins);
        });
    }

    public newQuote (): void { }
    public getInstrumentsList (): any[] {
        if (!this.insArr) {
            this.insArr = [];
            const items = WL_ListController.GetItems();
            for (const insID in items) {
                this.makeInsSubs(items[insID].trd_id, items[insID].route, insID);
            }
        }
        return this.insArr;
    }

    public createRowTable (): void {
        const wl_rows = [];

        const MainAccount = DataCache.MainAccountNew;
        const instrumetns = this.getInstrumentsList();

        for (let i = 0; i < instrumetns.length; i++) {
            const instrument = instrumetns[i];
            if (!instrument) {
                continue;
            }
            const Obj: any = {};
            Obj.Symbol = instrument.DisplayName();
            Obj.Description = instrument.DescriptionValue();
            Obj.Img = instrument.LogoAddress;
            Obj.Instrument = instrument;

            const Level1 = instrument.Level1;
            Obj.ChangeValue = Level1.GetChange(MainAccount);
            Obj.Change = Level1.strChangePercent(MainAccount);
            if (Obj.ChangeValue) {
                Obj.Change = parseFloat(Obj.Change).toLocaleString() + '%';
            }
            Obj.Last = Level1.GetLastPrice(MainAccount);

            Obj.LastFormatted = Level1.getFormatPrice(Obj.Last);
            if (!isNaN(Obj.Last)) {
                Obj.LastFormatted = Obj.LastFormatted + ' ' + instrument.Exp2;
            }

            wl_rows.push(Obj);
        }

        const arrayRowData = [];
        //     {
        //         Symbol: "Instr",
        //         Description: "my test instr",
        //         Side: "Short",
        //         Change: "12.00",
        //         Last: "0 USD",
        //         NumberLast: 0,
        //         Img: "current_theme/styleInvesting/imgInvesting/Table/test_img_msft.svg",
        //     },

        for (let i = 0; i < wl_rows.length; i++) {
            const item = wl_rows[i];
            const row = new WatchlistRowDataItem(item);
            arrayRowData.push(row);
        }

        void this.set({ arrayRowData });
    }

    public createMenuItems (dataItem): Array<{ text: string, imgStyle: string, event: () => void }> {
        const items = [
            {
                text: 'Buy',
                imgStyle: 'buy',
                event: () => { openOrderEntry(true, dataItem.source, this.get('accountItem')); }
            },
            {
                text: 'Sell',
                imgStyle: 'sell',
                event: () => { openOrderEntry(false, dataItem.source, this.get('accountItem')); }
            },
            {
                text: 'Advanced chart',
                imgStyle: 'advanced_chart',
                event: () => { ProcessChartVisible(true, dataItem.source.Instrument); }
            },
            {
                text: 'Remove from watchlist',
                imgStyle: 'remove_wl_instr',
                event: () => { this.RemoveItem(dataItem.source.Instrument); }
            }
        ];

        return items;
    }

    public AddOrRemInstrument (isAdd, instrument): void {
        if (!instrument || !this.insArr) {
            return;
        }

        const index = this.insArr.indexOf(instrument);
        if (isAdd && index === -1) {
            this.makeInsSubs(instrument.InstrumentTradableID, instrument.Route, instrument.GetInteriorID());
        }
        if (!isAdd && index !== -1) {
            this.insArr.splice(index, 1);
        }
    }

    public RemoveItem (instrument): void {
        if (!instrument || !this.insArr) {
            return;
        }

        const index = this.insArr.indexOf(instrument);

        if (index === -1) {
            return;
        }

        this.insArr.splice(index, 1);

        WL_ListController.RemoveInstrument(instrument);
    }
}

Ractive.extendWith(WatchlistInvestingPanel, {
    template: WatchlistInvestingTemplate,
    data: function () {
        return {
            isVisibleChart: false,
            arrayRowData: [],
            accountItem: null
        };
    }
});
