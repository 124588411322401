// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Rectangle } from '../../Commons/Geometry';
import { buttonsPopupHandler } from '../../Utils/AppHandlers';
import { TerceraButtonsPopupTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';
import { TerceraMenu } from './TerceraMenu';

export class TerceraButtonsPopup extends ContainerControl {
    constructor () {
        super();
    }

    public override getType (): string { return 'TerceraButtonsPopup'; }

    public override oninit (): void {
        super.oninit();
        this.onButtonClicked = this.onButtonClicked.bind(this);
    }

    public override getClientPanel (): HTMLElement {
        return this.find('.js-buttons-popup');
    }

    public onButtonClicked (): void {
        this.Hide();
    }

    public onItemClicked (item): void {
        if (item?.event) {
            item.event(item.type);
        }
    }

    public Hide (): void {
        this.removeAllControls();
        void this.set('visible', false);
        this.lostFocus();
    }

    public show (items: any[], x: number, y: number, maxColumnsCount: number): void {
        if (typeof this.el !== 'object') {
            this.el = document.getElementById(this.el);
            void this.render(this.el);
        }
        if ((items?.length) === 0) {
            return;
        }

        if (typeof this.el !== 'object') {
            this.el = document.getElementById(this.el);
            void this.render(this.el);
        }

        const rowsCount = Math.ceil(items.length / maxColumnsCount);
        const columnsCount = items.length < maxColumnsCount ? items.length : maxColumnsCount;
        const margin = 3;
        const sizeBtn = 30;

        const w = margin + columnsCount * (sizeBtn + margin);
        const h = margin + rowsCount * (sizeBtn + margin);
        const newLocation = TerceraMenu.CorrectPopupLocation(new Rectangle(x, y, w, h));

        // Positioning.
        void this.set({
            left: newLocation.newX,
            top: newLocation.newY,
            width: w,
            visible: true,
            popupItems: items
        });
    }

    public static ButtonsPopupHandlerInitialize (): void {
        const _buttonsPopup = new TerceraButtonsPopup();
        buttonsPopupHandler.set = async (key) => { await _buttonsPopup.set(key); };
        buttonsPopupHandler.get = (key) => { return _buttonsPopup.get(key); };
        buttonsPopupHandler.Hide = _buttonsPopup.Hide.bind(_buttonsPopup);
        buttonsPopupHandler.show = _buttonsPopup.show.bind(_buttonsPopup);
        buttonsPopupHandler.onButtonClicked = _buttonsPopup.onButtonClicked.bind(_buttonsPopup);
        buttonsPopupHandler.addControl = _buttonsPopup.addControl.bind(_buttonsPopup);
    }
}

ContainerControl.extendWith(TerceraButtonsPopup, {
    el: '#buttonsPopupContainer',
    template: TerceraButtonsPopupTemplate,
    data: function () {
        return {
            visible: false,
            popupItems: null
        };
    }
});
