// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ProductType } from '@shared/utils/Instruments/ProductType';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { TIF } from '@shared/utils/Trading/OrderTif';
import { OrderTifMap } from '@shared/utils/Trading/OrderTifEnum';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { OrderUtils } from '@shared/utils/Trading/OrderUtils';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { Resources } from '@shared/localizations/Resources';
import { OrderTypeBaseParameters } from '../../Commons/cache/OrderParams/order-type/OrderTypeBaseParameters';
import { DataCache } from '../../Commons/DataCache';
import { type Account } from '../cache/Account';
import { type Instrument } from '../cache/Instrument';
import { type Order } from '../cache/Order';
import { type Position } from '../cache/Position';
import { type OrderTypeBase } from '../cache/OrderParams/order-type/OrderTypeBase';
import { OrderEditConfirmationTextParams } from '../cache/OrderParams/OrderEditConfirmationTextParams';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { BaseSettingsUtils } from '../UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { BaseSettings } from '../Settings/BaseGeneralSettingsWrapper';
import { SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { CurrencyUtils } from '@shared/utils/Asset/CurrencyUtils';

class _OrderExecutorUtils {
// TODO. Remove? Refactor. Lots of params.
    public async buildOrderEditConfirmationText (textParams: OrderEditConfirmationTextParams): Promise<string> {
        const orderTypeKey = textParams.OrderTypeKey;
        const tif = textParams.Tif;
        const account = textParams.Account;
        const instrument = textParams.Instrument;
        const quantity = textParams.Quantity;
        const buy = textParams.Buy;
        const priceArray = textParams.PriceArray;
        const sltpText = textParams.SlTpText;
        const priceInOffset = textParams.PriceInOffset;
        const productType = textParams.ProductType;
        const orderType = textParams.OrderType;
        const RealTrStopPrice = textParams.RealTrStopPrice;
        const leverage = textParams.LeverageValue;
        const isMarket = orderType === OrderType.Market;
        const priceInOffsetText = (isMarket || !isNullOrUndefined(RealTrStopPrice)) ? '' : (Resources.getResource(priceInOffset ? 'general.trading.with offset' : 'general.trading.at') + ' ');

        if (isNullOrUndefined(account) || isNullOrUndefined(instrument) || isNullOrUndefined(quantity) || isNullOrUndefined(tif)) {
            return '';
        }

        const amountStr = textParams.IsCashMode
            ? `${Resources.getResource('general.trading.for')} ${CurrencyUtils.FormatWithCurrencySymbol(account.formatPrice(textParams.Cash, true), account.GetAssetBalanceCorrect(instrument, buy)?.Asset.Name)}`
            : InstrumentUtils.formatAmountValue(quantity.toNumber(instrument), instrument, account, productType ?? ProductType.General);

        const tifKey = OrderTifMap[tif.type];
        let result =
        Resources.getResource(
            buy
                ? 'general.trading.Buy'
                : 'general.trading.Sell') +
        ' ' +
        Resources.getResource(orderTypeKey) +
        '(' + Resources.getResource(tifKey) +
        (!isNullOrUndefined(productType) && productType !== ProductType.General ? ', ' + InstrumentUtils.GetLocalizedProductType(instrument, productType) : '') +
        ')' +
        (!isNullOrUndefined(leverage) && isMarket ? (' ' + OrderUtils.GetFormattedLeverage(leverage)) : '') +
        ' ' +
        amountStr +
        ' ' +
        InstrumentUtils.getInstrumentTypeStringLocalized(instrument.InstrType) +
        ' ' +
        instrument.DisplayName() +
        ' ' +
        '(' + instrument.TradingExchange + ')' +
        (!isNullOrUndefined(leverage) && !isMarket ? (' ' + OrderUtils.GetFormattedLeverage(leverage)) : '') +
        ' ' + priceInOffsetText;

        if (!isMarket) {
            for (let i = 0, len = priceArray.length; i < len; i++) {
                const last = i === len - 1;
                if (orderTypeKey === 'Tr. stop' && isNullOrUndefined(RealTrStopPrice)) {
                // сюда должны прийти тики в нормальном виде Utils.toRawTicks
                    const offsetValue = priceArray[i];
                    const offsetMode = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(instrument);
                    result += SlTpUtils.formatSLTPValue(offsetValue, instrument, offsetMode);
                } else if (!isNullOrUndefined(RealTrStopPrice)) {
                    result += instrument.formatPrice(RealTrStopPrice);
                } else {
                    result += instrument.formatPrice(priceArray[i]);
                }

                if (!last) result += ' / ';
            }
        }

        if (isValidString(sltpText)) {
            result += '\n' + sltpText;
        }

        if (!isNullOrUndefined(account)) {
            result +=
            ' ' +
            Resources.getResource('general.trading.for') +
            ' ' +
            account.toString();
        }

        if (!isNullOrUndefined(textParams.PlacedFrom)) {
            const assetBalance = account.assetBalanceDefault;
            if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_DISPLAY_FEES_ON_CONFIRMATIONS)) {
                const totalFee = textParams.TotalFee;

                if (!isNaN(totalFee) && !isNullOrUndefined(totalFee)) {
                    result +=
                ', ' +
                Resources.getResource('OrderEntry.InfoBlock.Fee') +
                ' ~ ' +
                assetBalance.formatPrice(Math.abs(totalFee));
                }
            }

            if (DataCache.isAllowedForMyUser(RulesSet.LEVERAGE_USAGE_WARN)) {
                const afterTradeCash = textParams.AfterTradeCash;
                if (!isNaN(afterTradeCash) && !isNullOrUndefined(afterTradeCash)) {
                    result +=
                ', ' +
                Resources.getResource('OrderEntry.InfoBlock.After trade cash') +
                ' ' +
                assetBalance.formatPrice(afterTradeCash);
                }
            }
        }

        result += ' ?';

        return result;
    }

    // TODO. Refactor. Duplicate of Utils.buildOrderEditConfirmationText.
    public async buildCancelOrderConfirmationText (order: Order): Promise<string> {
        const dc = order.DataCache;
        const orderTypeObj = dc.OrderParameterContainer.GetOrderType(order.OrderType);

        // UGLY.
        const inLots = BaseSettingsUtils.displayAmountInLots();
        const qty = new Quantity(
            Quantity.convertQuantityValue(new Quantity(order.Amount, true), order.Instrument, inLots, order.Account, order.ProductType),
            inLots);

        const ordType = order.OrderType;
        const ordTypes = OrderType;

        let priceArr = null;
        switch (ordType) {
        case ordTypes.TrailingStop:
            priceArr = [order.TrStopOffset];
            break;
        case ordTypes.StopLimit:
            priceArr = [order.StopLimit, order.Price];
            break;
        default:
            priceArr = [order.Price];
        }

        const textParams = new OrderEditConfirmationTextParams();
        textParams.OrderTypeKey = orderTypeObj.localizationKey();
        textParams.Tif = new TIF(order.TimeInForce, new Date(order.ExpireAt));
        textParams.Account = order.Account;
        textParams.Instrument = order.Instrument;
        textParams.Quantity = qty;
        textParams.Buy = order.BuySell === OperationType.Buy;
        textParams.PriceArray = priceArr;
        textParams.PriceInOffset = ordType === ordTypes.TrailingStop;
        textParams.ProductType = order.ProductType;
        textParams.RealTrStopPrice = order.getRealTrStopPrice();
        textParams.LeverageValue = order.Leverage;

        return Resources.getResource('panel.orders.menu.CancelOrder') +
        ' ' +
        await OrderExecutorUtils.buildOrderEditConfirmationText(textParams);
    }

    // TODO. Refactor.
    public getModifyOrderEditConfirmation (order: Order, confirmationText): string {
        return Resources.getResource('screen.modifyOrder.Change order') +
        ' #' + order.OrderNumber +
        ' ' + confirmationText;
    }

    // TODO. Refactor.
    public buildPositionEditConfirmationText (
        position: Position, sltpText): string {
        return Resources.getResource('screen.modifyOrder.modifyPosition') +
        ' #' + position.PositionId +
        ' ' + sltpText +
        '?';
    }

    public buildPositionModifyProductTypeConfirmationText (pos: Position, newPType, quantity): string {
        if (!pos) {
            return 'An error has occurred: no position';
        }

        const instrument = pos.Instrument;
        const productType = pos.ProductType;

        if (!instrument || productType === undefined) {
            return 'An error has occurred: no instrument or(and) productType';
        }

        const currentProductType = InstrumentUtils.GetLocalizedProductType(instrument, productType);
        const newProductType = InstrumentUtils.GetLocalizedProductType(instrument, newPType);

        const price = pos.Price;
        const instrumentDisplayName = instrument.DisplayName ? instrument.DisplayName() : '';

        if (!quantity || !price) {
            return 'An error has occurred: no amount or(and) price';
        }

        const inLots = BaseSettingsUtils.displayAmountInLots();
        const qty = new Quantity(Quantity.convertQuantityValue(new Quantity(quantity, true), instrument, inLots, pos.Account, productType), inLots); // OMG WTF TODO ?
        const qtyStr = (qty?.value?.toString) ? qty.value.toString() : '';

        return Resources.getResource('panel.positions.confirmation.ModifyProductType')
            .replace('{0}', currentProductType)
            .replace('{1}', newProductType)
            .replace('{2}', instrumentDisplayName)
            .replace('{3}', qtyStr)
            .replace('{4}', price.toString());
    }

    public getAllowedTIFArray (orderTypeId, instrument: Instrument | null): any[] {
        if (!DataCache.OrderParameterContainer) {
            return [];
        }

        if (!instrument) {
            return [];
        }

        const route = instrument.DataCache.getRouteByName(instrument.Route);
        if (!route) {
            return [];
        }

        return route.DictOrderTypeTifs[orderTypeId] || [];
    }

    public getAllowedOrderTypeDict (
        account: Account,
        instrument: Instrument,
        forbiddenOrderTypes: OrderType[],
        lastSelectedOrderType?: OrderType,
        mamMode?: boolean): Record<OrderType, OrderTypeBase> {
        mamMode = !!mamMode;
        const allowedOrderTypeDict: Record<number, OrderTypeBase> = {}; // Record<OrderType, OrderTypeBase>

        if (isNullOrUndefined(DataCache.OrderParameterContainer)) {
            return allowedOrderTypeDict;
        }

        const orderTypeDict = DataCache.OrderParameterContainer.OrderTypes;

        const supportedParamObj = new OrderTypeBaseParameters(instrument, account);

        for (const key in orderTypeDict) {
            const ordType = orderTypeDict[key];
            let ordTypeToCheck = ordType;
            const id = ordType.id();
            if (forbiddenOrderTypes.Contains(id)) {
                continue;
            }

            if (BaseSettings.isUseStopLimitInsteadStop()) { // 3.2) Логика при Use stop limit instead of stop = true https://docs.google.com/document/d/11893XluvGdsld2lwMx4H0h94xROrkMWmFzLqAHJ6YNE
                const stopLimitOrderType = orderTypeDict[OrderType.StopLimit];
                if (id === OrderType.OCO) {
                    if (orderTypeDict[OrderType.Limit].IsSupported(supportedParamObj) && stopLimitOrderType.IsSupported(supportedParamObj)) { // Если Stop limit недоступен, то скрываем возможность установить OCO.
                        allowedOrderTypeDict[id] = ordType;
                    } // TODO forbiddenOrderTypes слегка костыльно из-за 92559

                    continue;
                }

                if (id === OrderType.Stop && ordType.IsSupported(supportedParamObj)) { // Если Stop ордер недоступен, то он не должен появляться даже если доступен Stop limit.
                    ordTypeToCheck = stopLimitOrderType;
                } // Если Stop ордер доступен, то он должен скрываться, если не доступен Stop limit
            }

            if (forbiddenOrderTypes?.[id]) {
                continue;
            }

            if (!ordTypeToCheck.IsSupported(supportedParamObj)) {
                continue;
            }

            allowedOrderTypeDict[id] = ordType;
        }

        return allowedOrderTypeDict;
    }
}

export const OrderExecutorUtils = new _OrderExecutorUtils();
