// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType, PriceTypeMap } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class MovingAverages extends IndicatorScriptBase {
    public MAPeriod: number;
    public SourcePrice: number;

    constructor () {
        super();
        this.ProjectName = 'Modified Moving Average';
        this.Comments = 'Moving Average comprises a sloping factor to help it overtake with the growing or declining value of the trading price of the currency';
        this.SetIndicatorLine('Line', Color.Blue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.MAPeriod = 2;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of Modified Moving Average', 1, 1, 9999));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 2,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'MMA(' + this.MAPeriod + ';' + PriceTypeMap[this.SourcePrice].toLowerCase() + ')';
    }

    public override getCustomName (): string {
        return 'MMA (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        const count = this.CurrentData.Count;
        if (count < this.MAPeriod) { return; }

        let mma = 0;
        if (count === this.MAPeriod) {
            let summa = 0; // Sum of prices
            for (let i = 0; i < this.MAPeriod; i++) { summa += this.CurrentData.GetPrice(this.SourcePrice, i); }
            mma = summa = summa / this.MAPeriod;
        } else {
            const price = this.CurrentData.GetPrice(this.SourcePrice, 0);
            mma = (price + (this.MAPeriod - 1) * this.GetValue(0, 1)) / this.MAPeriod;
        }

        // Setting the current value of the indicator
        this.SetValue(0, 0, mma);
    }
}
