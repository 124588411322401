// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Rectangle } from '../Commons/Geometry';
import { TerceraChartWindow } from './Windows/TerceraChartWindow';

export class TerceraChartOverlayWindow extends TerceraChartWindow {
    public Overlay: any = null;
    public TerceraChartOverlayWindow = true;

    public Dispose (): void {
        this.Overlay = null;

        super.Dispose();
    }

    public OnLayout (info = null): void {
        super.OnLayout(info);

        if (this.leftYScaleRenderer != null && info != null) {
            if (this.Overlay.chart.ActiveOverlayGet() === this.Overlay && this.Overlay.separateScale) {
                const clientR = this.ClientRectangle;

                this.leftYScaleRenderer.Visible = true;
                this.leftYScaleRenderer.Rectangle = new Rectangle(0, clientR.Y - this.PaddingTop, info.PreferredWidthScalesLeft, clientR.Height + this.PaddingTop);
            } else {
                this.leftYScaleRenderer.Visible = false;
            }
        }
    }

    public ThemeChanged (): void {
        super.ThemeChanged();
        this.Overlay.ThemeChanged();
    }
}
