// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType, PriceTypeMap } from '../../../../Utils/History/CashItemUtils';
import { MAMode, RSIMode } from '../IndicatorConstants';
import { IndicatorFunctions } from '../IndicatorFunctions';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterSeparator } from '../InputParamaterClasses/InputParameterSeparator';

export class RSI extends IndicatorScriptBase {
    public SourcePrice: number;
    public RSIPeriod: number;
    public RSIMethod: any;
    public MAPeriod: number;
    public MaType: any;
    public previousUp: number;
    public previousDown: number;
    public currentUp: number;
    public currentDown: number;
    public CalcHandlers: any;

    constructor () {
        super();
        this.ProjectName = 'Relative Strength Index';
        this.SetIndicatorLine('Line 1', Color.Green, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('Line 2', Color.SkyBlue, 1, LineStyle.SimpleChart);
        this.SetLevelLine('Line 3', 70, Color.Red, 1, LineStyle.SimpleChart);
        this.SetLevelLine('Line 4', 30, Color.CreateColor(0, 51, 252), 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.SourcePrice = PriceType.Low;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Source prices for RSI', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.RSIPeriod = 14;
        super.InputParameter(new InputParameterInteger('RSIPeriod', 'RSI Period:', 0, 1, 100, 0, 1));

        this.RSIMethod = RSIMode.Simple;
        super.InputParameter(new InputParameterCombobox('RSIMethod', 'RSI Method:', 1,
            [
                { Simple: RSIMode.Simple },
                { 'Wilder`s moving average': RSIMode.WMA }
            ]));

        super.InputParameter(new InputParameterSeparator(undefined, 'Moving average of RSI', 2));

        this.MAPeriod = 5;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of Moving Average', 2, 1, 9999, undefined, 1));

        this.MaType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MaType', 'Type of moving average', 3,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.previousUp = 0;
        this.previousDown = 0;
        this.currentUp = 0;
        this.currentDown = 0;
        this.CalcHandlers = {};
        this.CalcHandlers[RSIMode.Simple] = this.CalcSimple.bind(this);
        this.CalcHandlers[RSIMode.WMA] = this.CalcWMA.bind(this);
    }

    public override GetIndicatorShortName (): string {
        return 'RSI(' + this.RSIPeriod + ',' + this.MAPeriod + ';' + PriceTypeMap[this.SourcePrice].toLowerCase() + ')';
    }

    public override getCustomName (): string {
        return 'RSI (' + this.ProjectName + ')';
    };

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.UpdateIndexDrawBegin();
    }

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.RSIPeriod);
        this.SetIndexDrawBegin(1, this.RSIPeriod + this.MAPeriod);
    }

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count <= this.RSIPeriod) { return; };

        this.CalcHandlers[this.RSIMethod]();

        if ((this.RSIPeriod + this.MAPeriod) <= this.CurrentData.Count) { this.CalcMA(); }
    }

    public NextBar (): void {
        this.previousUp = this.currentUp;
        this.previousDown = this.currentDown;
        super.NextBar();
    }

    public CalcMA (): void {
        let result = 0;
        switch (this.MaType) {
        case MAMode.SMA:
            result = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this, this.MAPeriod, 1, 0);
            break;
        case MAMode.EMA:
        case MAMode.SMMA:
            if ((this.RSIPeriod + this.MAPeriod) === this.CurrentData.Count) { result = IndicatorFunctions.CallMovingFunction(MAMode.SMA, this, this.MAPeriod, 1, 0); } else { result = IndicatorFunctions.CallMovingFunction(this.MaType, this, this.MAPeriod, 1, 0); }
            break;
        case MAMode.LWMA:
            result = IndicatorFunctions.CallMovingFunction(this.MaType, this, this.MAPeriod, 1, 0) || 0;
            break;
        }
        this.SetValue(1, 0, result);
    }

    public CalcSimple (): void {
        const RSIPeriod = this.RSIPeriod;
        const CurrentData = this.CurrentData;

        let sumUp = 0;
        let sumDown = 0;
        for (let i = 0; i < RSIPeriod; i++) {
            const diff = CurrentData.GetPrice(this.SourcePrice, i) - CurrentData.GetPrice(this.SourcePrice, i + 1);
            if (diff >= 0) { sumUp += diff; } else { sumDown += Math.abs(diff); }
        }

        let rsi = 100;
        this.currentUp = sumUp / RSIPeriod;
        this.currentDown = sumDown / RSIPeriod;
        if (this.currentDown !== 0) { rsi = 100 - (100 / (this.currentUp / this.currentDown + 1)); }
        this.SetValue(0, 0, rsi);
    }

    public CalcWMA (): void {
        const CurrentData = this.CurrentData;
        const N = this.RSIPeriod;
        if (CurrentData.Count === N + 1) {
            this.CalcSimple();
            return;
        }

        const diff = CurrentData.GetPrice(this.SourcePrice, 0) - CurrentData.GetPrice(this.SourcePrice, 1);
        let currentUp = 0;
        let currentDown = 0;
        if (diff >= 0) { currentUp = diff; } else { currentDown = Math.abs(diff); }

        let rsi = 100;
        const alpha = 1 / N;
        this.currentUp = alpha * currentUp + (1 - alpha) * this.previousUp;
        this.currentDown = alpha * currentDown + (1 - alpha) * this.previousDown;
        if (this.currentDown !== 0) { rsi = 100 - (100 / (this.currentUp / this.currentDown + 1)); }
        this.SetValue(0, 0, rsi);
    }
}
