// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { ThemeManager } from '../misc/ThemeManager';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { DynProperty } from '@shared/commons/DynProperty';
import { ProfitCalculator } from '@shared/commons/cache/ProfitCalculator';
import { RiskPlan } from '@shared/commons/cache/RiskPlan';
import { Instrument } from '@shared/commons/cache/Instrument';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { IsAllowed } from '@shared/commons/IsAllowed';
import { DataCache } from '@shared/commons/DataCache';
import { DateTimeConvertor } from '@shared/utils/Time/DateTimeConvertor';
import { PositionFormatter } from '@shared/commons/cache/Formatters/PositionFormatter';
import { OrderUtils } from '@shared/utils/Trading/OrderUtils';
import { type Position } from '@shared/commons/cache/Position';
import { type SessionSettings } from '@shared/commons/SessionSettings';
import { type Account } from '@shared/commons/cache/Account';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';
import { WDSettings } from '../settings/WDGeneralSettings';
import { PriceFormatter } from '@shared/utils/Instruments/PriceFormatter';

export class PositionItem extends BaseItem {
    public static NOT_AVAILABLE = 'N/A';
    public FPosition: Position;
    public NetQty: boolean = false;
    public Disabled: boolean;
    public useInstrumentCurrencyForExposition: boolean;
    public userGroupName: string;
    public isSubItem: boolean;
    public superItemId: string;
    public QuickTableEditingInfoMap: any;
    public CellForeColorMap: any;
    public sessionSettings: typeof SessionSettings;
    public customColumnValues: any;

    constructor (position: Position, sessionSettings: typeof SessionSettings, isNetQty?: boolean) {
        super(sessionSettings);
        if (isNullOrUndefined(position)) {
            return;
        }

        this.FPosition = position;

        this.NetQty = !!isNetQty; // #92796 пункт 4 a. ii. доки: https://docs.google.com/document/d/1y4W7_QHbI4siuOD4OwrBucXWxQdbbqJoiw8bfEKvDhI/edit

        this.itemId = PositionItem.GetItemId(position);

        this.Disabled = position.IsPendingExerciseOptionStatus(); // #93127

        this.useInstrumentCurrencyForExposition = false;
        this.userGroupName = null;

        // SuperPosition or its trade (FIFO) #80010
        if (position.SuperPositionId !== '-1') {
            if (position.SuperPositionId !== position.PositionId) {
                this.isSubItem = true;

                const superPos = DataCache.getPositionById(position.SuperPositionId);
                // от этого сеттинга можно избавиться доставая значение ExerciseOptionStatus в Positions но мне это видится менее правильным
                if (!isNullOrUndefined(superPos)) {
                    this.Disabled = superPos.IsPendingExerciseOptionStatus();
                }
            }
            this.superItemId = position.SuperPositionId;

            const controlType = position.isSuperPosition ? DynProperty.EXPAND_SUPER_POSITION_BUTTON_AND_TEXT : DynProperty.COMPONENT_OF_SUPER_POSITION_TEXT;
            this.QuickTableEditingInfoMap[0] = (function () {
                return new QuickTableEditingInfo(controlType);
            })();
        }

        this.QuickTableEditingInfoMap[45] = (function () {
            return new QuickTableEditingInfo(DynProperty.CLOSE_BUTTON);
        })();
        const tm = ThemeManager.CurrentTheme;
        this.CellForeColorMap[0] = tm.TableGoldColor;// "#EDC234";
        this.CellForeColorMap[2] = tm.TableGoldColor;// "#EDC234";
        this.CellForeColorMap[7] = position.BuySell === OperationType.Buy ? tm.TableLongBuyColor : tm.TableShortSellColor;// "#EDC234";
    }

    public override NeedSortMenu (): boolean { return true; }

    public GetMenuIndex (headerKey: string): number {
        const idx = PositionItem.ListMenuIndex.indexOf(headerKey);
        return idx === -1 ? 1000 : idx;
    }

    public ColumnCount (): number { return PositionItem.columnsParams.length; }

    public GetColumnParams (column: number): ColumnParams {
        const result = PositionItem.columnsParams[column];
        let isHidden = Resources.isHidden(result.HeaderKey);
        switch (column) {
        case PositionItem.SL_COL_INDEX:
        case PositionItem.TP_COL_INDEX:
        case PositionItem.SLL_COL_INDEX:
            isHidden = IsAllowed.IsProtectiveOrders();// #108200 doc 4.2
            break;
        case PositionItem.COMMENTS_COL_INDEX:
        case PositionItem.ROUTE_COL_INDEX:
        case PositionItem.TRAILING_STOP_COL_INDEX:
        case PositionItem.USED_MARGIN_COL_INDEX:
        case PositionItem.GROUP_ID_COL_INDEX:
        case PositionItem.USER_GROUP_COL_INDEX:
        case PositionItem.INIT_REQ_COL_INDEX:
        case PositionItem.MA_INIT_REQ_COL_INDEX:
        case PositionItem.MARGIN_SUPLUS_COL_INDEX:
        case PositionItem.PROFIT_USD_INSTRUMENT_CURRENCY_COL_INDEX:
        case PositionItem.COMISSIONS_INSTRUMENT_CURRENCY_COL_INDEX:
        case PositionItem.NET_PL_INSTRUMENT_CURRENCY_COL_INDEX:
        case PositionItem.REALIZED_PNL_COL_INDEX:
        case PositionItem.REALIZED_PNL_INSTRUMENT_CURRENCY_COL_INDEX:
        case PositionItem.DELTA_COL_INDEX:
        case PositionItem.GAMMA_COL_INDEX:
        case PositionItem.VEGA_COL_INDEX:
        case PositionItem.THETA_COL_INDEX:
        case PositionItem.IV_COL_INDEX:
        case PositionItem.RHO_COL_INDEX:
            isHidden = true;
            break;
        case PositionItem.SL_VALUE_COL_INDEX:
            isHidden = !DataCache.isAllowedForMyUser(RulesSet.FUNCTION_SLTP);
            break;
        case PositionItem.PRODUCTTYPE_COL_INDEX:
            isHidden = !RiskPlan.showProductType;
            break;
        }

        result.Hidden = isHidden;
        result.MenuIndex = this.GetMenuIndex(result.HeaderKey);

        return result;
    };

    public getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let FormattedValue = null;

        const position = this.FPosition;
        const account = position.Account;
        const instrument = position.Instrument;
        const ShowLots = WDSettingsUtils.displayAmountInLots();

        switch (column) {
        case PositionItem.QUANTITY_COL_INDEX:
            FormattedValue = DataCache.formatVolume(instrument, Math.abs(value), ShowLots, position.ProductType, account);
            break;
        case PositionItem.BASIS_COL_INDEX:
            FormattedValue = PositionFormatter.PriceStr(position);
            break;
        case PositionItem.EXPDATE_COL_INDEX:
            if (!isNullOrUndefined(value) && value.getTime() > 0) {
                FormattedValue = DateTimeUtils.FormatToDate(value);
            } else {
                FormattedValue = '';
            }
            break;
        case PositionItem.STRIKE_COL_INDEX:
            FormattedValue = position.StrikePrice > 0 ? instrument.formatPrice(position.StrikePrice) : '';
            break;
        case PositionItem.OPERATION_COL_INDEX:
            FormattedValue = value.toString();
            break;
        case PositionItem.DATE_TIME_COL_INDEX:
            FormattedValue = PositionFormatter.DateTimeStr(position);
            break;

        case PositionItem.CUR_PRICE_COL_INDEX:
            FormattedValue = PositionFormatter.CurrentPriceStr(position);
            break;

        case PositionItem.SL_COL_INDEX:
            FormattedValue = PositionFormatter.StopLossPriceStr(position);
            break;

        case PositionItem.TP_COL_INDEX:
            FormattedValue = PositionFormatter.TakeProfitPriceStr(position);
            break;
        case PositionItem.PROFIT_USD_COL_INDEX:
            FormattedValue = position.lastPriceUpdated ? account.formatPrice(position.getGrossPnL(true)) : PositionItem.NOT_AVAILABLE;
            break;
        case PositionItem.SWAPS_COL_INDEX:
            FormattedValue = account.formatPrice(position.GetSwaps(true));
            break;
        case PositionItem.EXPOSITION_USD_COL_INDEX:
            if (this.useInstrumentCurrencyForExposition) {
                const asset = DataCache.GetAssetByName(instrument.Exp2);
                if (!isNullOrUndefined(asset)) {
                    FormattedValue = asset.formatPrice(position.getExpositionValue(true));
                } else {
                    FormattedValue = position.getExpositionValue(true) + ' ' + instrument.Exp2;
                }
            } else {
                FormattedValue = account.formatPrice(position.getExpositionValue(true));
            }
            break;

        case PositionItem.COMISSION_COL_INDEX:
            FormattedValue = PositionFormatter.CommissionStr(position);
            break;
        case PositionItem.TRAILING_STOP_COL_INDEX:
            FormattedValue = !isNullOrUndefined(position.SLOrder) && !isNaN(position.SLOrder.Price) && position.SLOrder.OrderType === OrderType.TrailingStop
                ? instrument.formatPrice(position.SLOrder.TrStopOffset)
                : '';
            break;
        case PositionItem.USED_MARGIN_COL_INDEX:
            FormattedValue = '';
            break;
        case PositionItem.PL_TICKS_COL_INDEX:
            if (!position.lastPriceUpdated) {
                FormattedValue = PositionItem.NOT_AVAILABLE;
            } else {
                if (typeof value === 'number') {
                    const visualOffset = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(instrument);
                    const visualOffsetValue = SlTpUtils.toVisualValue(value, instrument, visualOffset);
                    const visualOffsetStr = SlTpUtils.formatSLTPValue(visualOffsetValue, instrument, visualOffset);
                    FormattedValue = visualOffsetStr + ' ' + Resources.getResource(SlTpUtils.getLocalizationForOffsetMode(visualOffset));
                } else {
                    FormattedValue = '';
                }
            }
            break;
        case PositionItem.NET_PL_COL_INDEX:
            FormattedValue = PositionFormatter.NetPnLStr(position);
            break;
        case PositionItem.INIT_REQ_COL_INDEX:
            FormattedValue = account.formatPrice(position.getInitMargin(true));
            break;
        case PositionItem.MA_INIT_REQ_COL_INDEX:
            FormattedValue = account.formatPrice(position.getMaintMargin(true));
            break;
        case PositionItem.MARGIN_SUPLUS_COL_INDEX:
            FormattedValue = account.formatPrice(position.getInitMargin(true) - position.getMaintMargin(true) + position.getNetPnL(true));
            break;
        case PositionItem.POSITION_VALUE_COL_INDEX:
            {
                const posValue = position.getPositionValue(true);

                if (isNaN(posValue)) {
                    FormattedValue = PositionItem.NOT_AVAILABLE;
                } else
                    if (this.useInstrumentCurrencyForExposition) {
                        const asset = DataCache.GetAssetByName(position.Instrument.Exp2);
                        FormattedValue =
                            asset
                                ? asset.formatPrice(posValue)
                                : posValue + ' ' + position.Instrument.Exp2;
                    } else {
                        FormattedValue = account.formatPrice(posValue);
                    }
            }
            break;
        case PositionItem.SL_VALUE_COL_INDEX:
            FormattedValue = account.formatPrice(value);
            break;
        case PositionItem.START_DAY_QTY_COL_INDEX:
            FormattedValue = value !== 0 ? DataCache.formatVolume(instrument, Math.abs(value), ShowLots, position.ProductType, account) : '';
            break;
        case PositionItem.LEVERAGE_COL_INDEX:
            {
                const leverageStr = OrderUtils.GetFormattedLeverage(value, true);
                FormattedValue = isValidString(leverageStr) ? leverageStr : Resources.getResource('general.N_A');
            }
            break;
        case PositionItem.ACCRUED_INTEREST_COL_INDEX:
            FormattedValue = value != null ? account.formatPrice(value) : Resources.getResource('general.N_A');
            break;
        case PositionItem.NET_PL_PRECENT_POS_EXPOSURE_COL_INDEX:
            FormattedValue = !isNullOrUndefined(value) ? PositionFormatter.NetPnLPrecentPosExposureStr(position, value) : Resources.getResource('general.N_A');
            break;
        }

        if (isNullOrUndefined(FormattedValue)) {
            if (typeof value === 'number') {
                FormattedValue = instrument.formatPrice(value);
            } else {
                FormattedValue = !isNullOrUndefined(value) ? value.toString() : '';
            }
        }

        return new ColumnData(value, FormattedValue);
    }

    public getColumnValue (column: number, useAccCurrency: boolean = null): any {
        const customColumnValues = this.customColumnValues;
        if (column >= PositionItem.columnsParams.length && customColumnValues.hasOwnProperty(column)) {
            return customColumnValues[column];
        }

        const position = this.FPosition;
        const instrument = position.Instrument;
        const account = position.Account;
        const showLots = WDSettingsUtils.displayAmountInLots();
        const UseAccCurrency = useAccCurrency ?? PositionItem.UseAccCurrency(position);

        switch (column) {
        case PositionItem.SYMBOL_COL_INDEX:
            return PositionFormatter.SymbolStr(position);
        case PositionItem.ACCOUNT_COL_INDEX:
            return PositionFormatter.AccountStr(position);
        case PositionItem.QUANTITY_COL_INDEX:
            return PositionItem.GetPositionAmount(position) * PositionItem.GetPositionSign(position, this.NetQty);
        case PositionItem.BASIS_COL_INDEX:
            return PositionFormatter.Price(position);
        case PositionItem.EXPDATE_COL_INDEX:
            if (position.Instrument.ExpDateReal.getFullYear() > 2000) {
                return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(position.Instrument.ExpDateReal);
            } else {
                return DateTimeUtils._ZeroTime;
            }
        case PositionItem.STRIKE_COL_INDEX:
            return position.StrikePrice > 0 ? position.StrikePrice : 0;
        case PositionItem.POSNUMBER_COL_INDEX:
            return PositionFormatter.PositionId(position);
        case PositionItem.OPERATION_COL_INDEX:
            return PositionFormatter.OperationStr(position);
        case PositionItem.DATE_TIME_COL_INDEX:
            return PositionFormatter.DateTime(position);
        case PositionItem.CUR_PRICE_COL_INDEX:
            return PositionFormatter.CurrentPrice(position);
        case PositionItem.SL_COL_INDEX:
            return PositionFormatter.StopLossPrice(position);
        case PositionItem.TP_COL_INDEX:
            return PositionFormatter.TakeProfitPrice(position);
        case PositionItem.PROFIT_USD_COL_INDEX:
            return position.lastPriceUpdated ? position.getGrossPnL(UseAccCurrency) : 0;
        case PositionItem.SWAPS_COL_INDEX:
            return position.GetSwaps(UseAccCurrency);
        case PositionItem.EXPOSITION_USD_COL_INDEX:
            return position.getExpositionValue(UseAccCurrency);
        case PositionItem.COMISSION_COL_INDEX:
            return PositionFormatter.Commission(position, UseAccCurrency);
        case PositionItem.COMMENTS_COL_INDEX:
            return position.Comment;
        case PositionItem.ROUTE_COL_INDEX:
            return position.Route;
        case PositionItem.TYPE_COL_INDEX:
            return InstrumentUtils.getInstrumentTypeStringLocalized(instrument.InstrType);
        case PositionItem.TRAILING_STOP_COL_INDEX:
            return PositionFormatter.TrStopOffset(position);
        case PositionItem.LOGIN_COL_INDEX:
            return account.userLogin;
        case PositionItem.SYMBOL_DESCRIPTION_COL_INDEX:
            return position.GetInstrumentDescriptionValue();
        case PositionItem.USED_MARGIN_COL_INDEX:
            return undefined;
        case PositionItem.PL_TICKS_COL_INDEX:
            return position.lastPriceUpdated ? Instrument.TickValue(instrument, position.CurPriceClose, position.Price, position.BuySell === OperationType.Buy ? 1 : -1) : 0;
        case PositionItem.NET_PL_COL_INDEX:
            return PositionFormatter.NetPnL(position, UseAccCurrency);
        case PositionItem.GROUP_ID_COL_INDEX:
            return '';
        case PositionItem.USER_GROUP_COL_INDEX:
            return isValidString(this.userGroupName) ? this.userGroupName : '';
        case PositionItem.INIT_REQ_COL_INDEX:
            return position.getInitMargin(UseAccCurrency);
        case PositionItem.MA_INIT_REQ_COL_INDEX:
            return position.getMaintMargin(UseAccCurrency);
        case PositionItem.MARGIN_SUPLUS_COL_INDEX:
            return position.getInitMargin(UseAccCurrency) - position.getMaintMargin(UseAccCurrency) + position.getNetPnL(UseAccCurrency);
        case PositionItem.POSITION_VALUE_COL_INDEX:
            return position.getPositionValue(UseAccCurrency);
        case PositionItem.PROFIT_USD_INSTRUMENT_CURRENCY_COL_INDEX:
            return 0;
        case PositionItem.COMISSIONS_INSTRUMENT_CURRENCY_COL_INDEX:
            return -position.GetCommissionFromSettlement(false);
        case PositionItem.NET_PL_INSTRUMENT_CURRENCY_COL_INDEX:
            return 0;
        case PositionItem.SL_VALUE_COL_INDEX:
            return UseAccCurrency ? this.GetSLValue() : this.GetSLValue() / account.getCrossPrice();
        case PositionItem.REALIZED_PNL_COL_INDEX:
        case PositionItem.REALIZED_PNL_INSTRUMENT_CURRENCY_COL_INDEX:
            return position.RealizedPL;
        case PositionItem.DELTA_COL_INDEX:
            return 0;
        case PositionItem.GAMMA_COL_INDEX:
            return 0;
        case PositionItem.VEGA_COL_INDEX:
            return 0;
        case PositionItem.THETA_COL_INDEX:
            return 0;
        case PositionItem.IV_COL_INDEX:
            return 0;
        case PositionItem.RHO_COL_INDEX:
            return 0;
        case PositionItem.PRODUCTTYPE_COL_INDEX:
            return InstrumentUtils.GetLocalizedProductType(instrument, position.ProductType);
        case PositionItem.AVAILABLE_FOR_SELL_COL_INDEX:
            return DataCache.formatVolume(instrument, position.AmountAvailableForSell * (showLots ? 1 : instrument.getLotSize()), showLots, position.ProductType, account);
        case PositionItem.TRADING_EXCHANGE_COL_INDEX:
            return isValidString(instrument.TradingExchange) ? instrument.TradingExchange : '';
        case PositionItem.SLL_COL_INDEX:
        {
            const order = position.SLOrder;
            return order?.StopLimit && order.OrderType !== OrderType.TrailingStop ? position.SLOrder.Price : null;
        }
        case PositionItem.START_DAY_QTY_COL_INDEX:
            return PositionItem.GetPositionStartOfDayAmount(position) * PositionItem.GetPositionSign(position, this.NetQty);
        case PositionItem.ASSET_NAME_COL_INDEX:
            return isValidString(instrument.AssetName) ? instrument.AssetName : '';
        case PositionItem.LEVERAGE_COL_INDEX:
            return !isNullOrUndefined(position.Leverage) ? position.Leverage : '';
        case PositionItem.ACCRUED_INTEREST_COL_INDEX:
            return position.AccruedInterest;
        case PositionItem.NET_PL_PRECENT_POS_EXPOSURE_COL_INDEX:
            return PositionFormatter.NetPnLPrecentPosExposure(position, UseAccCurrency);
        default:
            return '';
        }
    }

    public getFormatTotalValue (column: number, value: number, precisionMode: boolean = false, totalCurrencyAfterFiltration: string = null): string | number {
        let formattedValue = '';

        const pos = this.FPosition;
        const dc = pos.DataCache;
        const asset = dc.GetAssetByName(totalCurrencyAfterFiltration ?? dc.userBaseCurrency);

        switch (column) {
        case PositionItem.QUANTITY_COL_INDEX:
            if (precisionMode) {
                return '2';
            }
            formattedValue = value.toFixed(2);
            break;

        case PositionItem.PROFIT_USD_COL_INDEX:
        case PositionItem.SWAPS_COL_INDEX:
        case PositionItem.EXPOSITION_USD_COL_INDEX:
        case PositionItem.COMISSION_COL_INDEX:
        case PositionItem.NET_PL_COL_INDEX:
        case PositionItem.POSITION_VALUE_COL_INDEX:
            if (precisionMode) {
                return asset.Point;
            }
            formattedValue = asset.formatPrice(value);
            break;

        case PositionItem.NET_PL_PRECENT_POS_EXPOSURE_COL_INDEX:
            if (precisionMode) {
                return '2';
            }
            formattedValue = PriceFormatter.formatPrice(value, 2, false) + ' %';
            break;
        }

        return formattedValue;
    }

    public GetSLValue (): number {
        let slRiskValue = 0;

        try {
            const pos = this.FPosition;
            if (pos != null) {
                // #32401
                if (pos.SLOrder != null) {
                    const ins = pos.Instrument;
                    const acc = pos.Account;
                    const Qty = pos.Amount;
                    const isBuy = pos.BuySell === OperationType.Buy;
                    const price = pos.Price;
                    const slPrice = pos.SLOrder.Price;
                    const openCrossPrice = pos.OpenCrossPrice;

                    slRiskValue = ProfitCalculator.CalculateSLTP(Qty, slPrice, price, ins, acc, isBuy, openCrossPrice, pos.ProductType);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }

        return slRiskValue;
    }

    public GetCurrentAccount (): Account { return this.FPosition.Account; }
    public GetCurrentInstrument (): Instrument { return this.FPosition.Instrument; }
    public InstrumentName (): string { return this.FPosition.Instrument.GetInteriorID(); }
    public DisplayInstrumentName (): string { return this.FPosition.Instrument.DisplayName(); }
    public isModifyAllowed (columnIndex): boolean {
        let modifyAllow = IsAllowed.IsPositionModifyingAllowed(this.FPosition).Allowed;
        if (!modifyAllow) {
            return modifyAllow;
        }

        const acc = this.GetCurrentAccount();
        const ins = this.GetCurrentInstrument();

        if (columnIndex === PositionItem.SL_COL_INDEX) {
            modifyAllow = IsAllowed.IsSLTPAllowed(ins, acc, true).Allowed && !this.FPosition.IsActivated;
        } else if (columnIndex === PositionItem.TP_COL_INDEX) {
            modifyAllow = IsAllowed.IsSLTPAllowed(ins, acc, false).Allowed;
        } else if (columnIndex === PositionItem.PRODUCTTYPE_COL_INDEX) {
            modifyAllow = IsAllowed.IsPositionProductTypeModifyingAllowed([this.FPosition]).Allowed;
        }

        return modifyAllow;
    }

    static SYMBOL_COL_INDEX = 0;
    static ACCOUNT_COL_INDEX = 1;
    static QUANTITY_COL_INDEX = 2;
    static BASIS_COL_INDEX = 3;
    static EXPDATE_COL_INDEX = 4;
    static STRIKE_COL_INDEX = 5;
    static POSNUMBER_COL_INDEX = 6;
    static OPERATION_COL_INDEX = 7;
    static DATE_TIME_COL_INDEX = 8;
    static CUR_PRICE_COL_INDEX = 9;
    static SL_COL_INDEX = 10;
    static TP_COL_INDEX = 11;
    static PROFIT_USD_COL_INDEX = 12;
    static SWAPS_COL_INDEX = 13;
    static EXPOSITION_USD_COL_INDEX = 14;
    static COMISSION_COL_INDEX = 15;
    static COMMENTS_COL_INDEX = 16;
    static ROUTE_COL_INDEX = 17;
    static TYPE_COL_INDEX = 18;
    static TRAILING_STOP_COL_INDEX = 19;
    static LOGIN_COL_INDEX = 20;
    static SYMBOL_DESCRIPTION_COL_INDEX = 21;
    static USED_MARGIN_COL_INDEX = 22;
    static PL_TICKS_COL_INDEX = 23;
    static NET_PL_COL_INDEX = 24;
    static GROUP_ID_COL_INDEX = 25;
    static USER_GROUP_COL_INDEX = 26;
    static INIT_REQ_COL_INDEX = 27;
    static MA_INIT_REQ_COL_INDEX = 28;
    static MARGIN_SUPLUS_COL_INDEX = 29;
    static POSITION_VALUE_COL_INDEX = 30;
    static PROFIT_USD_INSTRUMENT_CURRENCY_COL_INDEX = 31;
    static COMISSIONS_INSTRUMENT_CURRENCY_COL_INDEX = 32;
    static NET_PL_INSTRUMENT_CURRENCY_COL_INDEX = 33;
    static SL_VALUE_COL_INDEX = 34;
    static REALIZED_PNL_COL_INDEX = 35;
    static REALIZED_PNL_INSTRUMENT_CURRENCY_COL_INDEX = 36;
    static DELTA_COL_INDEX = 37;
    static GAMMA_COL_INDEX = 38;
    static VEGA_COL_INDEX = 39;
    static THETA_COL_INDEX = 40;
    static IV_COL_INDEX = 41;
    static RHO_COL_INDEX = 42;
    static PRODUCTTYPE_COL_INDEX = 43;
    static AVAILABLE_FOR_SELL_COL_INDEX = 44;
    static CLOSE_COL_INDEX = 45;
    static TRADING_EXCHANGE_COL_INDEX = 46;
    static SLL_COL_INDEX = 47;
    static START_DAY_QTY_COL_INDEX = 48;
    static ASSET_NAME_COL_INDEX = 49;
    static LEVERAGE_COL_INDEX = 50;
    static ACCRUED_INTEREST_COL_INDEX = 51;
    static NET_PL_PRECENT_POS_EXPOSURE_COL_INDEX = 52;

    static columnsParams =
        [
            /* 0 */ new ColumnParams('panel.positions.Symbol', 70, 0, false, true, true, false, true),
            new ColumnParams('panel.positions.Account', 70, 0, false, false, true, false),
            new ColumnParams('panel.positions.Qunatity', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, true, true, false),
            new ColumnParams('panel.positions.Basis', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.positions.ExpDate', 70, 0, false, false, true),
            /* 5 */new ColumnParams('panel.positions.strike', 70, 0, false, false, true),
            new ColumnParams('panel.positions.pos_number', 70, 0, false, false, false, false, true),
            new ColumnParams('panel.positions.Operation', 70, 0, false, true, true),
            new ColumnParams('panel.positions.date_time', 70, QuickTableColumnType.COL_DATE_SORT, false, false, false),
            new ColumnParams('panel.positions.cur_price', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, false),
            /* 10 */ new ColumnParams('panel.positions.SL', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
            new ColumnParams('panel.positions.TP', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
            new ColumnParams('panel.positions.profit_usd', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, true, false, false),
            new ColumnParams('panel.positions.swaps', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, true, false, false),
            new ColumnParams('panel.positions.exposition_usd', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
            /* 15 */ new ColumnParams('panel.positions.commissions', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, true, true, false),
            new ColumnParams('panel.positions.comments', 70, 0, false, false, false),
            new ColumnParams('panel.positions.route', 70, 0, false, false, false),
            new ColumnParams('panel.positions.type', 70, 0, false, true, true),
            new ColumnParams('panel.positions.tr', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            /* 20 */ new ColumnParams('panel.positions.Login', 70, 0, false, false, true),
            new ColumnParams('panel.positions.SymbolDescription', 70, 0, false, false, true),
            new ColumnParams('panel.positions.UsedMargin', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, false),
            new ColumnParams('panel.positions.PL_ticks', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.positions.Net_PL', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false),
            /* 25 */ new ColumnParams('panel.positions.GroupId', 70, 0, false, false, true),
            new ColumnParams('panel.positions.UserGroup', 70, 0, false, false, true),
            new ColumnParams('panel.positions.InitReq', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.positions.MaintReq', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.positions.MarginSuplus', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            /* 30 */ new ColumnParams('panel.positions.PositionValue', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false),
            new ColumnParams('panel.positions.profit_usd.InstrumentCurrency', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, false, true, false),
            new ColumnParams('panel.positions.commissions.InstrumentCurrency', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, false, true, false, false, true),
            new ColumnParams('panel.positions.Net_PL.InstrumentCurrency', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.positions.SL_Value', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            /* 35 */ new ColumnParams('panel.positions.RealizedPnL', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.positions.RealizedPnL.InstrumentCurrency', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.positions.Delta', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.positions.Gamma', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.positions.Vega', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            /* 40 */ new ColumnParams('panel.positions.Theta', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.positions.IV', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.positions.Rho', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            new ColumnParams('panel.positions.ProductType', 70, QuickTableColumnType.COL_DEFAULT, false, false, false, null, null, null, true),
            new ColumnParams('panel.positions.AvailableForSell', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
            /* 45 */ new ColumnParams('panel.positions.Close', 70, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.positions.ExchangeTrading', 70, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.positions.SLL', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, null, null, null, true),
            new ColumnParams('panel.positions.StartOfDayQty', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
            new ColumnParams('panel.positions.AssetName', 70, QuickTableColumnType.COL_DEFAULT, false, true, true),
            /* 50 */ new ColumnParams('panel.positions.Leverage', 70, QuickTableColumnType.COL_DEFAULT, false, false, true),
            new ColumnParams('panel.positions.AccruedInterest', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, true),
            new ColumnParams('panel.positions.Net_PL_Percent', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false)
        ];

    static ListMenuIndex =
        [
            'panel.positions.Account',
            'panel.positions.UserGroup',
            'panel.positions.Login',
            'panel.positions.route',
            'panel.positions.Symbol',
            'panel.positions.SymbolDescription',
            'panel.positions.type',
            'panel.positions.AssetName',
            'panel.positions.pos_number',
            'panel.positions.GroupId',
            'panel.positions.Operation',
            'panel.positions.Basis',
            'panel.positions.cur_price',
            'panel.positions.Qunatity',
            'panel.positions.StartOfDayQty',
            'panel.positions.date_time',
            'panel.positions.profit_usd',
            'panel.positions.profit_usd.InstrumentCurrency',
            'panel.positions.PL_ticks',
            'panel.positions.Net_PL',
            'panel.positions.Net_PL.InstrumentCurrency',
            'panel.positions.Net_PL_Percent',
            'panel.positions.exposition_usd',
            'panel.positions.PositionValue',
            'panel.positions.commissions',
            'panel.positions.commissions.InstrumentCurrency',
            'panel.positions.swaps',
            'panel.positions.ExpDate',
            'panel.positions.strike',
            'panel.positions.SL',
            'panel.positions.TP',
            'panel.positions.SL_Value',
            'panel.positions.SLL',
            // 'panel.positions.UsedMargin',
            // 'panel.positions.InitReq',
            // 'panel.positions.MaintReq',
            // 'panel.positions.MarginSuplus',
            'panel.positions.ExchangeTrading',
            'panel.positions.Leverage',
            'panel.positions.Close'
        ];

    static GetItemId (position: Position): any { return position.PositionId; }
    static UseAccCurrency (position?: Position): boolean {
        let useAccCurrency = true;

        if (isNullOrUndefined(position)) {
            return useAccCurrency;
        }

        const asset = position.DataCache.userBaseCurrency;
        const assetAcc = position.Account.BaseCurrency;

        if (asset !== assetAcc) {
            useAccCurrency = false;
        }

        return useAccCurrency;
    }

    static GetPositionAmountText (position: Position): number {
        if (isNullOrUndefined(position)) {
            return 0;
        }

        // TODO. fix rounding errors.
        if (WDSettings.isQuantityInLots) {
            return position.Amount;
        } else {
            const lotSize = position.Instrument.getLotSize();
            return position.Amount * lotSize;
        }
    }

    static GetPositionSign (position: Position, displayQtyAbsolute: boolean = true): number {
        let sign = 1;

        if (position.BuySell !== OperationType.Buy && displayQtyAbsolute) {
            sign = -1;
        }

        if (position.Amount < 0 && sign < 0) { sign = 1; }

        return sign;
    }

    static GetPositionAmount (position: Position): number {
        if (position != null) {
            return PositionItem.GetAmount(position.Amount, position.Instrument);
        } else {
            return 0;
        }
    }

    static GetPositionStartOfDayAmount (position: Position): number {
        if (position != null && position.StartOfDayAmount !== null) {
            PositionItem.GetAmount(position.StartOfDayAmount, position.Instrument);
        } else {
            return 0;
        }
    }

    static GetAmount (amount: number, instrument: Instrument): number {
        if (!WDSettings.isQuantityInLots) {
            return amount * instrument.getLotSize();
        } else {
            return amount;
        }
    }
}
