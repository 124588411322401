// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { TerceraPopupBaloonTemplate } from '../../templates.js';
import { Control } from './Control';
import { ThemeManager } from '../misc/ThemeManager';
import $ from 'jquery';

export class TerceraPopupBaloon extends Control {
    constructor () { super(); }

    public show (data): void {
        void this.set({
            text: Resources.getResource(data.locKey),
            iconUrl: ThemeManager.getImageFullUrl(data.iconUrl)
        });

        const $dom = $(this.find('div'));
        $dom.stop().fadeIn(1000, function () { $dom.fadeOut(1000); });
    }

    public static readonly States = {
        WorkspaceLocked: {
            locKey: 'workspace.Lock.Message',
            iconUrl: 'o_notification/48x48_icon_locked.png'
        },
        WorkspaceUnlocked: {
            locKey: 'workspace.Unlock.Message',
            iconUrl: 'o_notification/48x48_icon_unlocked.png'
        },
        WorkspaceSaved: {
            locKey: 'workspace.saved.Message',
            iconUrl: 'o_notification/48x48_icon_succeeded.png'
        },
        TradingLocked: {
            locKey: 'ribbon.enviroment.NotificationWindow.lockTrading',
            iconUrl: 'o_notification/48x48_icon_locked.png'
        },
        TradingUnlocked: {
            locKey: 'ribbon.enviroment.NotificationWindow.UnlockTrading',
            iconUrl: 'o_notification/48x48_icon_unlocked.png'
        }
    };
}

Control.extendWith(TerceraPopupBaloon, {
    template: TerceraPopupBaloonTemplate,
    data: function () {
        return {
            text: '',
            iconUrl: ''
        };
    }
});
