// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceLimitMeasure } from '../../Utils/Instruments/PriceLimitMeasure';
import { Resources } from '../../Localizations/Resources';
import { type Instrument } from './Instrument';

export class Limits {
    public instrument: Instrument | null;
    private hightLimit = NaN;
    private lowLimit = NaN;
    private priceLimitMeasure = PriceLimitMeasure.DISABLE;

    private isHighLimitFrontEndValidationEnabled: any = null;
    private isLowLimitFrontEndValidationEnabled: any = null;

    constructor (instrument: Instrument | null) {
        this.instrument = instrument;
    }

    public static LIMIT_NONE = -1;
    public static LimitType = { High: 0, Low: 1 };

    get HightLimit (): number { return this.hightLimit; }
    set HightLimit (value) { this.hightLimit = value; }

    get LowLimit (): number { return this.lowLimit; }
    set LowLimit (value) { this.lowLimit = value; }

    get PriceLimitMeasure (): PriceLimitMeasure { return this.priceLimitMeasure; }
    set PriceLimitMeasure (value) { this.priceLimitMeasure = value; }

    get IsHighLimitFrontEndValidationEnabled (): any {
        return this.isHighLimitFrontEndValidationEnabled;
    }

    set IsHighLimitFrontEndValidationEnabled (value) {
        this.isHighLimitFrontEndValidationEnabled = value;
    }

    get IsLowLimitFrontEndValidationEnabled (): any {
        return this.isLowLimitFrontEndValidationEnabled;
    }

    set IsLowLimitFrontEndValidationEnabled (value) {
        this.isLowLimitFrontEndValidationEnabled = value;
    }

    public IsFrontEndValidation (): boolean {
        return this.PriceLimitMeasure === PriceLimitMeasure.FRONT_END_VALIDATION;
    }

    public IsHighLimitHide (): boolean {
        return this.hightLimit === 0 ||
            this.hightLimit === Limits.LIMIT_NONE;
    }

    public IsLowLimitHide (): boolean {
        return this.lowLimit === 0 ||
            this.lowLimit === Limits.LIMIT_NONE;
    }

    // function from Java developers for High/Low limit prices
    // prevClose - Базовая цена
    // limit - значение
    public getLimitedPrice (type, prevClose, limit): number {
        let res = 0;

        if (type === Limits.LimitType.Low) // для Low отнимаем
        {
            limit *= -1;
        }

        switch (this.priceLimitMeasure) {
        case PriceLimitMeasure.DISABLE:
        case PriceLimitMeasure.PERCENTAGE_PREV_CLOSE_PRICE:
        case PriceLimitMeasure.PERCENTAGE_CHANGE_FROM_REFERENCE_PRICE:
            res = prevClose * (1 + limit / 100);
            break;
        case PriceLimitMeasure.OFFSET_SETTL_PRICE:
        case PriceLimitMeasure.OFFSET_PREV_CLOSE_PRICE:
        default:
            res = this.instrument ? this.instrument.CalculatePrice(prevClose, limit) : 0;
            break;
        }

        return res > 0 ? res : 0;
    }

    public GetLimitPriceByMeasure (type, limitPrice): number | null {
        const instrument = this.instrument;
        switch (this.priceLimitMeasure) {
        case PriceLimitMeasure.PERCENTAGE_SETTL_PRICE:
        case PriceLimitMeasure.OFFSET_SETTL_PRICE:
        {
            // #93339
            return !isNaN(limitPrice)
                ? this.getLimitedPrice(type, instrument.PrevSettlementPrice, limitPrice)
                : null;
        }

        case PriceLimitMeasure.EXCHANGE_LIMITS:
            return limitPrice;

        case PriceLimitMeasure.PERCENTAGE_PREV_CLOSE_PRICE:
        case PriceLimitMeasure.OFFSET_PREV_CLOSE_PRICE:
        {
            const needUseOpen = isNaN(instrument.Close);
            if (needUseOpen && isNaN(instrument.Open)) {
                return null;
            }

            const curPrice = needUseOpen ? instrument.Open : instrument.Close;
            return this.getLimitedPrice(type, curPrice, limitPrice);
        }

        case PriceLimitMeasure.PERCENTAGE_CHANGE_FROM_REFERENCE_PRICE:
        {
            const referencePrice = instrument.Level1.getReferencePrice;
            if (isNaN(referencePrice)) {
                return null;
            }

            const limRef = this.getLimitedPrice(type, referencePrice, limitPrice);
            // #71795 - делаю округление здесь, чтобы проще покрыть юниттестами
            return this.RoundLimit(type, limRef);
        }

        case PriceLimitMeasure.FRONT_END_VALIDATION:
            return !isNaN(limitPrice) ? limitPrice : null;

        default:
            return null;
        }
    }

    public GetHighLimitPriceByMeasure (): number | null {
        return this.GetLimitPriceByMeasure(Limits.LimitType.High, this.hightLimit);
    }

    public GetLowLimitPriceByMeasure (): number | null {
        return this.GetLimitPriceByMeasure(Limits.LimitType.Low, this.lowLimit);
    }

    public NoneString (sett): string {
        return Resources.getResource('InstrumentDetailsPanel.None');
    }

    public HighLimitPriceByMeasureString (sett?): string {
        const highLimitByMeasure = this.GetHighLimitPriceByMeasure();
        return highLimitByMeasure == null || isNaN(highLimitByMeasure)
            ? this.NoneString(sett)
            : this.instrument.formatPrice(highLimitByMeasure);
    }

    public LowLimitPriceByMeasureString (sett?): string {
        const lowLimitByMeasure = this.GetLowLimitPriceByMeasure();
        return lowLimitByMeasure == null || isNaN(lowLimitByMeasure)
            ? this.NoneString(sett)
            : this.instrument.formatPrice(lowLimitByMeasure);
    }

    public GetHighLowLimitString (): string {
        let highLowLimit = '';
        const instrument = this.instrument;
        if (instrument) {
            highLowLimit =
                (this.lowLimit > 0 ? instrument.formatPrice(this.lowLimit) : 'N/A') +
                ' - ' +
                (this.hightLimit > 0 ? instrument.formatPrice(this.hightLimit) : 'N/A');
        } else { highLowLimit = 'N/A'; }

        return highLowLimit;
    }

    public RoundLimit (type, limRef): any {
        const instrument = this.instrument;

        let roundedLimRef = instrument.RoundPriceToNearestPointSize(limRef);
        if (type === Limits.LimitType.Low) {
            if (limRef > roundedLimRef) {
                roundedLimRef = instrument.CalculatePrice(roundedLimRef, 1);
            }

            return roundedLimRef;
        } else if (type === Limits.LimitType.High) {
            if (limRef < roundedLimRef) {
                roundedLimRef = instrument.CalculatePrice(roundedLimRef, -1);
            }

            return roundedLimRef;
        }

        return limRef;
    }

    public GetHighLimitFromPriceLimitsMessage (): number // #111427
    {
        return this.HightLimit;
    }

    public GetLowLimitFromPriceLimitsMessage (): number // #111427
    {
        return this.LowLimit;
    }

    public ValidatePriceOnFrontEnd (price): boolean // #111427
    {
        if (this.IsHighLimitFrontEndValidationEnabled &&
            !isNaN(this.HightLimit) &&
            price > this.HightLimit) {
            return false;
        }

        if (this.IsLowLimitFrontEndValidationEnabled &&
            !isNaN(this.LowLimit) &&
            price < this.LowLimit) {
            return false;
        }

        return true;
    }
}
