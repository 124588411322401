// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Account } from '../../cache/Account';
import { AccountFeature } from '@shared/utils/Account/AccountFeature';
import { Resources } from '@shared/localizations/Resources';
import { AccountSummaryTablesEnum } from '../Columns/AccountSummaryTablesEnum';
import { TvAccountColumns } from '../Columns/TvAccountColumns';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { TvAccountActivityData } from '../TradingViewPrimitives/AccountData/TvAccountActivityData';
import { TvAccountGeneralData } from '../TradingViewPrimitives/AccountData/TvAccountGeneralData';
import { TvAccountMarginData } from '../TradingViewPrimitives/AccountData/TvAccountMarginData';
import { TvAccountTodaysResultData } from '../TradingViewPrimitives/AccountData/TvAccountTodaysResultData';
import { type AccountManagerPage, type AccountManagerSummaryField, type IBrokerConnectionAdapterHost, type IWatchedValue, type IWatchedValueReadonly, StandardFormatterName, type AccountManagerTable, type CustomTableElementFormatter, type IDelegate } from '../charting_library';
import { GetCustomFormatters } from '../Columns/CustomTableFormatters/BaseCustomTableFormatterName';
import { TvRiskManagementData } from '../TradingViewPrimitives/AccountData/TvRiskManagementData';

export class TvAccountTableManager {
    private readonly generalData: TvAccountGeneralData;
    private readonly marginData: TvAccountMarginData;
    private readonly activityData: TvAccountActivityData;
    private readonly todaysResultsData: TvAccountTodaysResultData;
    private readonly riskManagementData: TvRiskManagementData;

    private readonly balanceValue: IWatchedValue<number>;
    private readonly projectedBalanceValue: IWatchedValue<number>;
    private readonly openGrossPLValue: IWatchedValue<number>;
    private readonly openNetPLValue: IWatchedValue<number>;
    private readonly todayOpenGrossPLValue: IWatchedValue<number>;
    private readonly todayOpenNetPLValue: IWatchedValue<number>;
    private readonly currentDailyLossValue: IWatchedValue<number>;
    private readonly maxDrawdownLevelValue: IWatchedValue<number>;

    private readonly generalDelegate: IDelegate<(t: TvAccountGeneralData) => void>;
    private readonly marginDelegate: IDelegate<(t: TvAccountMarginData) => void>;
    private readonly activityDelegate: IDelegate<(t: TvAccountActivityData) => void>;
    private readonly todaysResultsDelegate: IDelegate<(t: TvAccountTodaysResultData) => void>;
    private readonly riskManagementDelegate: IDelegate<(t: TvRiskManagementData) => void>;

    private needUpdateEquity = false;
    private needUpdateMarginAvailable = false;
    private prevEquity: number = 0;
    private prevMarginAvailable: number = 0;

    private readonly host: IBrokerConnectionAdapterHost;

    constructor (host: IBrokerConnectionAdapterHost) {
        this.host = host;

        this.generalData = new TvAccountGeneralData();
        this.marginData = new TvAccountMarginData();
        this.activityData = new TvAccountActivityData();
        this.todaysResultsData = new TvAccountTodaysResultData();
        this.riskManagementData = new TvRiskManagementData();

        this.generalDelegate = host.factory.createDelegate<(t: TvAccountGeneralData) => void>();
        this.marginDelegate = host.factory.createDelegate<(t: TvAccountMarginData) => void>();
        this.activityDelegate = host.factory.createDelegate<(t: TvAccountActivityData) => void>();
        this.todaysResultsDelegate = host.factory.createDelegate<(t: TvAccountTodaysResultData) => void>();
        this.riskManagementDelegate = host.factory.createDelegate<(t: TvRiskManagementData) => void>();

        this.balanceValue = host.factory.createWatchedValue(this.generalData.balance);
        this.projectedBalanceValue = host.factory.createWatchedValue(this.generalData.projectedBalance);
        this.openGrossPLValue = host.factory.createWatchedValue(this.activityData.openGrossPL);
        this.openNetPLValue = host.factory.createWatchedValue(this.activityData.openNetPL);
        this.todayOpenGrossPLValue = host.factory.createWatchedValue(this.todaysResultsData.todaysGross);
        this.todayOpenNetPLValue = host.factory.createWatchedValue(this.todaysResultsData.todaysNet);
        this.currentDailyLossValue = host.factory.createWatchedValue(this.riskManagementData.currentDailyLoss);
        this.maxDrawdownLevelValue = host.factory.createWatchedValue(this.riskManagementData.maxDrawdownLevel);

        this.generalDelegate.subscribe(null, t => {
            this.balanceValue.setValue(t.balance);
            this.projectedBalanceValue.setValue(t.projectedBalance);
        });

        this.activityDelegate.subscribe(null, t => {
            this.openGrossPLValue.setValue(t.openGrossPL);
            this.openNetPLValue.setValue(t.openNetPL);
        });

        this.todaysResultsDelegate.subscribe(null, t => {
            this.todayOpenGrossPLValue.setValue(t.todaysGross);
            this.todayOpenNetPLValue.setValue(t.todaysNet);
        });

        this.riskManagementDelegate.subscribe(null, t => {
            this.currentDailyLossValue.setValue(t.currentDailyLoss);
            this.maxDrawdownLevelValue.setValue(t.maxDrawdownLevel);
        });
    }

    public subscribeEquity (): void {
        this.needUpdateEquity = true;
    }

    public unsubscribeEquity (): void {
        this.needUpdateEquity = false;
    }

    public subscribeMarginAvailable (symbol: string): void {
        this.needUpdateMarginAvailable = true;
    }

    public unsubscribeMarginAvailable (symbol: string): void {
        this.needUpdateMarginAvailable = false;
    }

    public updateTable (): void {
        this.updateTables();

        if (this.needUpdateEquity) {
            const acc = TvAccountHelper.getCurrentAccount();
            const balance = Account.GetAccountFeature(AccountFeature.Balance, acc, acc.assetBalanceDefault);
            const pnl = acc.Profit;
            const equity = balance + pnl;
            if (equity !== this.prevEquity) this.host.equityUpdate(equity);
            this.prevEquity = equity;
        }

        if (this.needUpdateMarginAvailable) {
            const acc = TvAccountHelper.getCurrentAccount();
            const marginAvailable = Account.GetAccountFeature(AccountFeature.MarginAvailable, acc, acc.assetBalanceDefault);
            if (marginAvailable !== this.prevMarginAvailable) this.host.marginAvailableUpdate(marginAvailable);
            this.prevMarginAvailable = marginAvailable;
        }
    }

    private updateTables (): void {
        const acc = TvAccountHelper.getCurrentAccount();

        if (this.generalData.update(acc)) {
            this.generalDelegate.fire(this.generalData);
        }

        if (this.marginData.update(acc)) {
            this.marginDelegate.fire(this.marginData);
        }

        if (this.activityData.update(acc)) {
            this.activityDelegate.fire(this.activityData);
        }

        if (this.todaysResultsData.update(acc)) {
            this.todaysResultsDelegate.fire(this.todaysResultsData);
        }

        if (this.riskManagementData.update(acc)) {
            this.riskManagementDelegate.fire(this.riskManagementData);
        }
    }

    public getSummary (): AccountManagerSummaryField[] {
        const summaryItems = [
            { resourceKey: 'panel.accounts.Balance', value: this.balanceValue },
            { resourceKey: 'panel.accounts.CurBalance', value: this.projectedBalanceValue },
            { resourceKey: 'panel.accounts.Profit', value: this.openGrossPLValue },
            { resourceKey: 'panel.accounts.ProfitNet', value: this.openNetPLValue },
            { resourceKey: 'panel.accounts.GrossPNL', value: this.todayOpenGrossPLValue },
            { resourceKey: 'panel.accounts.BeginBalance', value: this.todayOpenNetPLValue },
            { resourceKey: 'panel.accounts.CurrentDailyLoss', value: this.currentDailyLossValue },
            { resourceKey: 'panel.accounts.MaxDrawdownLevel', value: this.maxDrawdownLevelValue }
        ];

        return summaryItems.map(item =>
            this.createSummaryItem(Resources.getResource(item.resourceKey), item.value)
        );
    }

    public getPages (): AccountManagerPage[] {
        const tables = this.getTables();
        if (tables.length === 0) {
            return [];
        }

        return [{
            id: 'accountsummary',
            title: Resources.getResource('TvTradingPlatform.AccountSummary.Title'),
            tables
        }];
    }

    private getTables (): AccountManagerTable[] {
        const generalTable = this.createTableItem(AccountSummaryTablesEnum.General);
        const marginTable = this.createTableItem(AccountSummaryTablesEnum.Margin);
        const accountActivityTable = this.createTableItem(AccountSummaryTablesEnum.AccountActivity);
        const todaysResultsTable = this.createTableItem(AccountSummaryTablesEnum.TodaysResults);

        const tables = [generalTable, marginTable, accountActivityTable, todaysResultsTable];
        return tables.filter(t => t !== null);
    }

    private createTableItem (tableType: AccountSummaryTablesEnum): AccountManagerTable | null {
        const columns = TvAccountColumns.getColumns(tableType);
        if (columns.length === 0) {
            return null;
        }

        const changeDelegate = this.getChangeDelegate(tableType);

        const table: AccountManagerTable = {
            id: tableType.toString(),
            title: this.getTableTitle(tableType),
            columns: TvAccountColumns.getColumns(tableType),
            getData: this.getDataDelegate(tableType),
            changeDelegate
        };

        return table;
    }

    public getDataDelegate = (tableType: AccountSummaryTablesEnum): (paginationLastId?: string | number) => Promise<Array<{}>> => {
        switch (tableType) {
        case AccountSummaryTablesEnum.General:
            return this.getGeneralData;
        case AccountSummaryTablesEnum.Margin:
            return this.getMarginData;
        case AccountSummaryTablesEnum.AccountActivity:
            return this.getActivityData;
        case AccountSummaryTablesEnum.TodaysResults:
            return this.getTodayResultsData;
        default:
            throw new Error('Invalid Table Type');
        }
    };

    public getCustomFormatters (): CustomTableElementFormatter[] {
        const formatters: CustomTableElementFormatter[] = GetCustomFormatters();
        return formatters;
    }

    private readonly getGeneralData = async (paginationLastId?: string | number): Promise<Array<{}>> => {
        return await Promise.resolve([this.generalData]);
    };

    private readonly getMarginData = async (paginationLastId?: string | number): Promise<Array<{}>> => {
        return await Promise.resolve([this.marginData]);
    };

    private readonly getActivityData = async (paginationLastId?: string | number): Promise<Array<{}>> => {
        return await Promise.resolve([this.activityData]);
    };

    private readonly getTodayResultsData = async (paginationLastId?: string | number): Promise<Array<{}>> => {
        return await Promise.resolve([this.todaysResultsData]);
    };

    private getChangeDelegate (tableType: AccountSummaryTablesEnum): any {
        switch (tableType) {
        case AccountSummaryTablesEnum.General:
            return this.generalDelegate;
        case AccountSummaryTablesEnum.Margin:
            return this.marginDelegate;
        case AccountSummaryTablesEnum.AccountActivity:
            return this.activityDelegate;
        case AccountSummaryTablesEnum.TodaysResults:
            return this.todaysResultsDelegate;

        default:
            return this.generalDelegate;
        }
    }

    private createSummaryItem (name: string,
        wValue: IWatchedValueReadonly<number>,
        formatter: StandardFormatterName = StandardFormatterName.Fixed,
        isDefault: boolean = true): AccountManagerSummaryField {
        const summaryItem: AccountManagerSummaryField = {
            text: name,
            wValue,
            formatter,
            isDefault
        };

        return summaryItem;
    }

    private getTableTitle (tableType: AccountSummaryTablesEnum): string {
        switch (tableType) {
        case AccountSummaryTablesEnum.General:
            return Resources.getResource('panel.accountDetails.Groups.1.General');
        case AccountSummaryTablesEnum.Margin:
            return Resources.getResource('panel.accountDetails.Groups.2.Margin');
        case AccountSummaryTablesEnum.AccountActivity:
            return Resources.getResource('panel.accountDetails.Groups.3.AccountActivity');
        case AccountSummaryTablesEnum.TodaysResults:
            return Resources.getResource('panel.accountDetails.Groups.4.TodayResults');
        default:
            throw new Error('Invalid Table Type');
        }
    }
}
