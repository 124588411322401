// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type Instrument } from '@shared/commons/cache/Instrument';
import { type OrderTypeBase } from '@shared/commons/cache/OrderParams/order-type/OrderTypeBase';
import { type DynProperty } from '@shared/commons/DynProperty';
import { QuoteValid } from '../Quotes/QuoteValid';
import { OperationType } from './OperationType';
import { OrderType } from './OrderType';
import { SlTpUtils } from './SlTpUtils';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class OrderEditBaseUtils {
    public static LIMIT_PRICE_PARAM = 'limitPrice';
    public static STOP_PRICE_PARAM = 'stopPrice';
    public static SLTP_PARAM = 'sltp';
    public static TRAILING_STOP_PARAM = 'trailingStop';
    public static POSITION_SIZING_PARAM = 'positionSizing';
    public static REAL_TRSTOP_PRICE = 'RealTrStopPrice';
    public static PRODUCT_TYPE_AND_MQ = 'ModifyQuantity'; // Target product type + Modify quantity
    public static ATTENTION_MSG = 'attention';
    public static LIMIT_PRICE_PARAM_CHANGE_FROM_LAST_PRICE = 'limitPriceChangeFromLast'; // https://tp.traderevolution.com/entity/108864
    public static SLTP_TRIGGER = 'sltpTrigger'; // for position with sltp & triggers modify #109386
    public static AFTER_TRADE_CASH = 'afterTradeCash';

    public static GetPriceByName (caller: any, paramName: string): number | null {
        const params: DynProperty[] = caller.get('orderEditParameterArray');

        if (!isValidArray(params)) return null;

        for (let i = 0; i < params.length; i++) {
            const param = params[i];
            if (!isNullOrUndefined(param) && param.name === paramName) {
                return param.value;
            }
        }

        return null;
    }

    public static GetLimitPrice (caller: any): number {
        const orderType: OrderTypeBase = caller.get('orderType');
        const isStop: boolean = !isNullOrUndefined(orderType) && orderType.id() === OrderType.Stop;
        const isTrStop: boolean = !isNullOrUndefined(orderType) && orderType.id() === OrderType.TrailingStop;

        if (isStop) {
            return this.GetStopPrice(caller);
        }

        if (isTrStop) {
            return this.GetTrailingStopPrice(caller);
        }

        return this.GetPriceByName(caller, this.LIMIT_PRICE_PARAM);
    }

    public static GetStopPrice (caller: any): number {
        return this.GetPriceByName(caller, this.STOP_PRICE_PARAM);
    }

    public static GetTrailingStopPrice (caller: any): number {
        const visualOffset = this.GetPriceByName(caller, this.TRAILING_STOP_PARAM);
        const ins: Instrument = caller.get('instrument');
        const isBuy: boolean = caller.get('operation') === OperationType.Buy;

        const lastQuote = !isNullOrUndefined(ins) ? ins.GetLastQuote(QuoteValid.Last) : null;

        if (isNullOrUndefined(lastQuote)) {
            return visualOffset;
        }

        const price = isBuy ? lastQuote.Ask : lastQuote.Bid;
        const sign = isBuy ? 1 : -1;
        const offsetType = SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(ins);
        const realOffset = SlTpUtils.toRealValue(visualOffset, ins, offsetType);
        return ins.CalculatePrice(price, realOffset * sign, true);
    }
};
