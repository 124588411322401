// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ColorStyleWidth } from '../../Commons/DynProperty';
import { Rectangle } from '../../Commons/Geometry';
import { Pen, PenStyle, SolidBrush } from '../../Commons/Graphics';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type TerceraChartWindowsContainer } from '../TerceraChartWindowsContainer';
import { type TerceraChartAdvancedParams } from '../Utils/TerceraChartAdvancedParams';
import { type TerceraChartWindowBase } from '../Windows/TerceraChartWindowBase';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';

export class TerceraChartBaseCrossHairRenderer extends TerceraChartBaseRenderer {
    public readonly ColorStyleWidth: ColorStyleWidth = new ColorStyleWidth(ThemeManager.CurrentTheme.Chart_TrackCursorBackColor, PenStyle.ShapedChart, 1);
    public readonly ForegroundBrush: SolidBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_TrackCursorFontColor);
    public readonly BackgroundBrush: SolidBrush = new SolidBrush(ThemeManager.CurrentTheme.Chart_TrackCursorBackColor);

    constructor (chart: TerceraChartBase) {
        super(chart);
        this.UseInAutoscale = false;
        this.assignLayer = LayersEnum.CrossHair;
        this.ThemeChanged();
    }

    public override Draw (gr: CanvasRenderingContext2D, window: TerceraChartWindowBase, windowsContainer: TerceraChartWindowsContainer, advParams: TerceraChartAdvancedParams = null): void {
        if (!this.Visible) {
            return;
        }
        if (isNullOrUndefined(advParams)) {
            return;
        }
        const cursorPosition = advParams.CursorPosition;

        if (isNullOrUndefined(cursorPosition) || cursorPosition.IsEmpty()) {
            return;
        }

        const clientRectange = window.ClientRectangle;
        const pen = new Pen(this.ColorStyleWidth.Color, this.ColorStyleWidth.Width, this.ColorStyleWidth.Style);
        // Draw horizontal line with YScale plates
        if (cursorPosition.Y > clientRectange.Top() && cursorPosition.Y < clientRectange.Bottom()) {
            gr.DrawLine(pen, clientRectange.Left(), cursorPosition.Y, clientRectange.Right(), cursorPosition.Y);
            const dataY = window.PointsConverter.GetDataY(cursorPosition.Y);
            if (!isNullOrUndefined(window.leftYScaleRenderer) && window.leftYScaleRenderer.Visible) {
                const leftYScaleRect = window.leftYScaleRenderer.Rectangle;
                const leftYScaleFont = window.leftYScaleRenderer.settings.ScaleFont;
                const leftPlateRect = new Rectangle(leftYScaleRect.X, cursorPosition.Y - (leftYScaleFont.Height / 2) - 3, leftYScaleRect.Width, leftYScaleFont.Height + 6);
                gr.FillRect(this.BackgroundBrush, leftPlateRect.X, leftPlateRect.Y, leftPlateRect.Width, leftPlateRect.Height);
                gr.DrawStringInRect(window.leftYScaleRenderer.FormatValue(dataY), leftYScaleFont, this.ForegroundBrush, leftPlateRect, false);
            }
            if (!isNullOrUndefined(window.rightYScaleRenderer) && window.rightYScaleRenderer.Visible) {
                const rightYScaleRect = window.rightYScaleRenderer.Rectangle;
                const rightYScaleFont = window.rightYScaleRenderer.settings.ScaleFont;
                const rightPlateRect = new Rectangle(rightYScaleRect.X, cursorPosition.Y - (rightYScaleFont.Height / 2) - 3, rightYScaleRect.Width, rightYScaleFont.Height + 6);
                gr.FillRect(this.BackgroundBrush, rightPlateRect.X, rightPlateRect.Y, rightPlateRect.Width, rightPlateRect.Height);
                gr.DrawStringInRect(window.rightYScaleRenderer.FormatValue(dataY), rightYScaleFont, this.ForegroundBrush, rightPlateRect, false);
            }
        }
        // Draw vertical line with XScale plates
        if (cursorPosition.X > clientRectange.Left() && cursorPosition.X < clientRectange.Right()) {
            gr.DrawLine(pen, cursorPosition.X, clientRectange.Top(), cursorPosition.X, clientRectange.Bottom());
            if (!isNullOrUndefined(windowsContainer.xScaleRenderer) && windowsContainer.xScaleRenderer.Visible) {
                const dataX = window.PointsConverter.GetDataX(cursorPosition.X);
                const formattedDataX = windowsContainer.xScaleRenderer.FormatValue(dataX);
                const textWidth = gr.GetTextWidth(formattedDataX, windowsContainer.xScaleRenderer.settings.ScaleFont);
                const xScaleRect = windowsContainer.xScaleRenderer.Rectangle;
                const xScaleFont = windowsContainer.xScaleRenderer.settings.ScaleFont;
                const xPlateRect = new Rectangle(cursorPosition.X - (textWidth / 2) - 3, xScaleRect.Y, textWidth + 6, xScaleRect.Height);
                if (xPlateRect.Left() >= xScaleRect.Left() && xPlateRect.Right() <= xScaleRect.Right()) {
                    gr.FillRect(this.BackgroundBrush, xPlateRect.X, xPlateRect.Y, xPlateRect.Width, xPlateRect.Height);
                    gr.DrawStringInRect(formattedDataX, xScaleFont, this.ForegroundBrush, xPlateRect, false);
                }
            }
        }
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();
        this.ColorStyleWidth.Color = ThemeManager.CurrentTheme.Chart_TrackCursorBackColor;
        this.ForegroundBrush.Color = ThemeManager.CurrentTheme.Chart_TrackCursorFontColor;
        this.BackgroundBrush.Color = ThemeManager.CurrentTheme.Chart_TrackCursorBackColor;
    }
}
