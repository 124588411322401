// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '../../../Localizations/Resources';
import { SideBarButtonTemplate } from '../../../templates';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { TerceraButton } from '../TerceraButton';

export class TerceraSideBarButton extends TerceraButton {
    protected readonly tooltipKey: string;
    constructor () { super(); }

    oncomplete (): void {
        super.oncomplete();
        this.localize();
    }

    public getType (): ControlsTypes {
        return ControlsTypes.TerceraSideBarButton;
    }

    public onMouseDown (event): void {
        super.onMouseDown(event, false);
    }

    public localize (): void {
        if (!isNullOrUndefined(this.tooltipKey)) {
            void this.set('tooltip', Resources.getResource(this.tooltipKey));
        }
    }
}

TerceraButton.extendWith(TerceraSideBarButton, {
    template: SideBarButtonTemplate,
    data: function () {
        return {
        };
    }
});
