// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class CancelOrderMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_CANCEL_ORDER_MESSAGE, fieldSet);
    }

    public getOrderId (): bigint {
        return this.getFieldValue(FieldsFactory.FIELD_ORDER_ID);
    }

    public setOrderId (orderId: bigint): void {
        this.setFieldValue(FieldsFactory.FIELD_ORDER_ID, orderId);
    }

    public getOnBehalfId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ON_BEHALF_USER_ID);
    }

    public setOnBehalfId (onBehalfId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ON_BEHALF_USER_ID, onBehalfId);
    }

    public getAccountId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ACCOUNT_ID);
    }

    public setAccountId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ACCOUNT_ID, id);
    }

    public getInstrumentId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_INSTRUMENT_ID);
    }

    public setInstrumentId (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_INSTRUMENT_ID, id);
    }

    public getInstrumentTradableID (): number {
        return this.getFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
    }

    public setInstrumentTradableID (id: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, id);
    }

    public setIsBrokerRequest (broker: boolean): void {
        this.setFieldValue(FieldsFactory.FIELD_IS_BY_BROKER, broker);
    }

    public setPlacedFrom (placedFrom): void {
        this.setFieldValue(FieldsFactory.FIELD_CLIENT_PANEL, placedFrom);
    }
}
