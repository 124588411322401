// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { LinkedSystem } from '../misc/LinkedSystem';
import { FilledOrderItem } from '../cache/FilledOrderItem';
import { ColouringModes } from '../elements/QuickTable/QuickTableColumn';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { ExportScreen } from '../screen/ExportScreen';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { DynProperty } from '@shared/commons/DynProperty';
import { ReportType } from '@shared/commons/cache/AllowedReport';
import { DataCache } from '@shared/commons/DataCache';
import { ApplicationInfo } from '@shared/commons/ApplicationInfo';
import { FilledOrdersPanelTemplate } from '../../templates.js';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type RangeSelectPanel } from './RangeSelectPanel';
import { type NewTrade } from '@shared/commons/cache/NewTrade';
import { ReportManager } from '@shared/utils/Managers/ReportManager';
import { EReportRequestParameter, ReportRequestConfig } from '@shared/commons/UtilsClasses/Report/ReportRequestConfig';

export class FilledOrdersPanel extends ApplicationPanelWithTable<FilledOrderItem> {
    public headerLocaleKey: string = 'panel.trades';
    public moreThanOneTTKey: string = 'panel.orders.menu.MoreOneSymbolInfoDisabled.tt'; // #95439
    public noOneTTKey: string = 'panel.orders.menu.NoSymbolInfoDisabled.tt';
    public rangeSelectPanel: RangeSelectPanel | null = null;
    public InitShowTotals: any;

    public override getType (): PanelNames { return PanelNames.FilledOrdersPanel; }

    public override oncomplete (): void {
        super.oncomplete();

        this.rangeSelectPanel = this.Controls.filledOrdersRangeSelect;
        this.rangeSelectPanel.getReportEvent.Subscribe(this.getFilledOrdersReport, this);
        this.rangeSelectPanel.updateSelectedRange();

        this.populateTableContextMenu();

        this.getQuickTable().OnSelectionChanged.Subscribe(this.selectionChange, this);
        DataCache.OnAddNewTradeEvent.Subscribe(this.AddTradeEvent, this);
        DataCache.OnRemovedTradeEvent.Subscribe(this.RemovedTradeEvent, this);

        this.UpdateShowTotalsStateChange(!!this.InitShowTotals);
        if (this.menuTagDict?.ShowTotals) {
            this.menuTagDict.ShowTotals.checked = !!this.InitShowTotals;
        }
    }

    public override dispose (): void {
        this.getQuickTable().OnSelectionChanged.UnSubscribe(this.selectionChange, this);
        this.rangeSelectPanel.getReportEvent.UnSubscribe(this.getFilledOrdersReport, this);
        DataCache.OnAddNewTradeEvent.UnSubscribe(this.AddTradeEvent, this);
        DataCache.OnRemovedTradeEvent.UnSubscribe(this.RemovedTradeEvent, this);

        super.dispose();
    }

    public override populateItemsDirect (): void {
        const trades = DataCache.newTradesDict;
        const keys = Object.keys(trades);
        const length = keys.length;
        for (let i = 0; i < length; i++) {
            this.getQuickTable().AddItem(new FilledOrderItem(trades[keys[i]]));
        }
    }

    public populateTableContextMenu (): void {
        const items = [];

        if (!Resources.isHidden('screen.export.visibility')) {
            items.push(
                {
                    text: Resources.getResource('screen.export.contextMenu'),
                    event: ExportScreen.show.bind(null, this)
                }
            );
        }

        if (!Resources.isHidden('panel.trades.print')) {
            items.push(
                {
                    text: Resources.getResource('screen.reports.print'),
                    event: this.printTable.bind(this)
                }
            );
        }

        if (!Resources.isHidden('contextMenu.Totals.visibility')) { // переделать в случае появления новых subitems
            items.push(
                {
                    text: Resources.getResource('panel.positions.menu.View'),
                    tag: 'View',
                    enabled: true,
                    subitems: [
                        {
                            text: Resources.getResource('panel.positions.menu.ShowTotals'),
                            tag: 'ShowTotals',
                            checked: false,
                            enabled: true,
                            canCheck: true,
                            event: this.ShowTotalsStateChange.bind(this)
                        }
                    ]
                }
            );
        }

        this.AddSymbolInfoContextMenuItemIfNeed(items);

        this.getQuickTable().setTableContextMenuItems(items);
        this.menuTagDict = TerceraMenu.createTagDictionary(items);
    }

    public AddTradeEvent (dc, trade: NewTrade): void {
        this.getQuickTable().AddItem(new FilledOrderItem(trade));
    }

    public RemovedTradeEvent (): void {
        const qtRactive = this.quickTableRactive;
        const qt = !isNullOrUndefined(qtRactive) ? qtRactive.quickTable : null;
        if (isNullOrUndefined(qt)) return;

        qt.ClearAll();
        const len = DataCache.filledOrdersArray.length;
        for (let i = 0; i < len; i++) {
            qt.AddItem(new FilledOrderItem(DataCache.filledOrdersArray[i]));
        }
    }

    public getFilledOrdersReport (startTime: number, finishTime: number): void {
        void this.set('loading', true);
        ReportManager.reportsByComplited.set(ReportType.REPORT_TRADES, false);
        void ReportManager.sendRequestByDate(ReportType.REPORT_TRADES, startTime, finishTime).then((filledOrders) => {
            const qt = this.getQuickTable();
            if (isNullOrUndefined(qt)) return;
            qt.ClearRows();

            for (const order of filledOrders) {
                qt.AddItem(new FilledOrderItem(order));
            }

            if (LinkedSystem.accLinkingActive) {
                const accId = LinkedSystem.getAccount();
                qt.filterByAccountNumber(accId); // отфильтруем по аккаунту позиции, если раскрылся лишний трейд
            }
        }).finally(() => { void this.set('loading', false); });
    }

    public selectionChange (): void {
        const qt = this.getQuickTable();
        const selectedRowsId = qt.selectedRowIds;
        if (!isValidArray(selectedRowsId)) return;

        let insId = '';
        // let accId = ''

        if (selectedRowsId.length > 0) {
            const row = qt.rows[selectedRowsId[0]];
            const item = !isNullOrUndefined(row) ? row.item : null;
            if (!isNullOrUndefined(item)) {
                const ins = item.GetCurrentInstrument();
                if (!isNullOrUndefined(ins)) {
                    insId = ins.GetInteriorID();
                }

            // let acc = item.GetCurrentAccount()
            // if (acc) accId = acc.BstrAccount
            }

            if (!isValidString(insId)) {
                TerceraMessageBox.Show(Resources.getResource('screen.reports.info'), Resources.getResource('general.InstrumentIsNotAvailableNow'), MessageBoxType.Info, null, null, false, true, null, { cantActionBeforeClose: true });
            }
        }

        this.symbolLink_Out(false, insId);
        // this.accountLink_Out(false, accId)
    }

    public override symbolLink_Out (newSubscriber, instrumentName: string): void {
        if (isNullOrUndefined(instrumentName)) {
            return;
        }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrumentName, newSubscriber);
        }
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();

        properties.push(new DynProperty('ShowTotals', this.getQuickTable().ShowTotals, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public override callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);

        this.InitShowTotals = DynProperty.getPropValue(newProperties, 'ShowTotals');
    }

    public override SetColumnsDefaultDisplayIndex (table): void {
        table.columns[0].displayedIndex = 9;
        table.columns[1].displayedIndex = 10;
        table.columns[2].displayedIndex = 2;
        table.columns[3].displayedIndex = 11;
        table.columns[4].displayedIndex = 3;
        table.columns[5].displayedIndex = 4;
        table.columns[6].displayedIndex = 5;
        table.columns[7].displayedIndex = 7;
        table.columns[8].displayedIndex = 6;
        table.columns[9].displayedIndex = 1;
        table.columns[10].displayedIndex = 0;
        table.columns[11].displayedIndex = 15;
        table.columns[12].displayedIndex = 16;
        table.columns[13].displayedIndex = 17;
        table.columns[14].displayedIndex = 18;
        table.columns[15].displayedIndex = 12;
        table.columns[16].displayedIndex = 13;
        table.columns[17].displayedIndex = 14;
        table.columns[18].displayedIndex = 19;
        table.columns[19].displayedIndex = 8;
    }

    public override SetColumnsColouringMode (table): void {
        this.ProcessSetColumnsColouringMode(table, [7, 8, 19], ColouringModes.Signed);
    }
}

ApplicationPanelWithTable.extendWith(FilledOrdersPanel,
    {
        data: function () {
            return {
                isAccountLinkShow: true,
                isSymbolLinkShow: true,
                isShowExploreTheAppLink: ApplicationInfo.isExploreMode
            };
        },

        partials: {
            bodyPartial: FilledOrdersPanelTemplate
        },

        headerLocaleKey: 'panel.trades',
        moreThanOneTTKey: 'panel.orders.menu.MoreOneSymbolInfoDisabled.tt', // #95439
        noOneTTKey: 'panel.orders.menu.NoSymbolInfoDisabled.tt',
        rangeSelectPanel: null
    });
