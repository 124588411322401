// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectExternalLinkMessage } from '../../../Utils/DirectMessages/DirectExternalLinkMessage';
import { DataCache } from '../../DataCache';
import { Resources } from '../../../Localizations/Resources';
import { BrandingToolLinkGenerator } from './Branding/BrandingToolLinkGenerator';
import { type BrandingToolParams } from './Branding/BrandingToolParams';
import { BaseExtLink } from './BaseExtLink';
import { ResourcesManager } from '../../properties/ResourcesManager';

export class AutochartistIFrameExtLink extends BaseExtLink {
    public brokerID: string = '';
    public secretKey: string = '';
    public accountType: string = '';

    update (brandingTool?: BrandingToolParams, extLinkMessage?: DirectExternalLinkMessage): void {
        if (brandingTool) {
            this.brokerID = brandingTool.brokerId ?? this.brokerID;
            this.secretKey = brandingTool.secretKey ?? this.secretKey;
            this.accountType = brandingTool.accountType ?? this.accountType;
        }
        super.update(brandingTool, extLinkMessage);
    }

    generateUrl (): void {
        const userID = DataCache.getUserID();
        const url = BrandingToolLinkGenerator.generateAutochartistIFrameLink(this, userID ? `${userID}` : '', ResourcesManager.extLinkLocaleName());

        if (url) {
            this.url = url;
            this.updateValue?.(url);
        }
    }
}
