// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { HistoryType } from '@shared/utils/History/HistoryType';
import { InstrumentHolidaysScreen } from './InstrumentHolidaysScreen';
import { OrderTypeBaseParameters } from '@shared/commons/cache/OrderParams/order-type/OrderTypeBaseParameters';
import { CommissionItem } from '@shared/commons/cache/Commissions/CommissionItem';
import { KeyCode, KeyEventProcessor } from '@shared/commons/KeyEventProcessor';
import { MathUtils } from '@shared/utils/MathUtils';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils';
import { VerticalApplicationPanelNew } from '../cache/VerticalPanel/VerticalApplicationPanelNew';
import { VerticalPanelDataProvider } from '../cache/VerticalPanel/VerticalPanelDataProvider';
import { VerticalPanelDataProviderItem } from '../cache/VerticalPanel/VerticalPanelDataProviderItem';
import { QuickTableUtils } from '../elements/QuickTable/QuickTableUtils';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { ProductType } from '@shared/utils/Instruments/ProductType';
import { MarginTypes } from '@shared/utils/Instruments/MarginTypes';
import { TradingMode } from '@shared/utils/Instruments/TradingMode';
import { PriceLimitMeasure } from '@shared/utils/Instruments/PriceLimitMeasure';
import { CommissionOperationType, FeeStringVariable } from '@shared/utils/Commission/CommissionEnums';
import { SwapType } from '@shared/utils/Instruments/SwapType';
import { QuotingType } from '@shared/utils/Instruments/QuotingType';
import { SessionOperations } from '@shared/utils/Enums/Constants';
import { InstrumentTypes } from '@shared/utils/Instruments/InstrumentTypes';
import { KEY_CARE_ORDER, KEY_CustodialFee, KEY_FILLPERLOT, KEY_ORDERPERLOT, KEY_ORDER_VOLUME, KEY_OptionExercise, KEY_PERFILL, KEY_PERORDERVOLUME, KEY_PERPHONETRANSACTION, KEY_PERTRANSACTION, KEY_PERVOLUME, KEY_ShortCommissionInfo, KEY_ShortPositionInterest, KEY_VAT, KEY_VOLUME, KEY_VOLUME_WITH_MIN_PD } from '@shared/utils/Trading/FeeTextConstants';
import { commisionSplitter, commisionSplitterArr } from '@shared/utils/Commission/CommisionSplitter';
import { CommissionPlan } from '@shared/commons/cache/Commissions/CommissionPlan';
import { InstrumentTradingBalance } from '@shared/utils/Instruments/InstrumentTradingBalance';
import { DynProperty } from '@shared/commons/DynProperty';
import { RiskPlan } from '@shared/commons/cache/RiskPlan';
import { Level1Calculator } from '@shared/commons/cache/Level1Calculator';
import { NumericUtils } from '@shared/utils/NumericUtils';
import { Instrument } from '@shared/commons/cache/Instrument';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { DataCache } from '@shared/commons/DataCache';
import { DateTimeConvertor } from '@shared/utils/Time/DateTimeConvertor';
import { OrderUtils } from '@shared/utils/Trading/OrderUtils';
import { LinkedSystem, LinkedSystemAccLinkingValue } from '../misc/LinkedSystem';
import { InstrumentFeatureHelper } from '@shared/utils/Instruments/InstrumentFeatureHelper';
import { GeneralSymbolInfoFeatureEnum } from '@shared/utils/Instruments/InstrumentFeatureEnum';
import { Enum } from '@shared/utils/Enum';
import { type Account } from '@shared/commons/cache/Account';
import { WDSettings } from '../settings/WDGeneralSettings';
import { SubTypes } from '@shared/utils/Session/SubTypes';
import { DayPeriods } from '@shared/utils/Session/DayPeriods';

export class SymbolInfoPanel extends VerticalApplicationPanelNew {
    public static readonly PANEL_WIDTH = 321;
    public static readonly PANEL_HEIGHT = 378;
    public static readonly commisionSplitter = commisionSplitter;
    public static readonly commisionSplitterArr = commisionSplitterArr;
    public static KEY_LowLimit_SortIndex: number;

    public myIns: Instrument | null = null;
    public NeedCalculateRowCount: boolean = false;
    public headerLocaleKey: string = 'panel.instrumentsInfo';
    public skipOnSave: boolean = true;
    public sessionTypesItem: Record<string, any>;
    public marginTypesItem: Record<string, VerticalPanelDataProviderItem>;
    public feeTypesItem: Record<string, VerticalPanelDataProviderItem>;
    public tickSizeCostTypesItem: Record<string, VerticalPanelDataProviderItem>;
    public rebateTypesItem: any;
    public dynamicTypesItem: Record<string, VerticalPanelDataProviderItem>;
    public initLinkInSymbol: Instrument;

    public override getType (): PanelNames { return PanelNames.SymbolInfoPanel; }

    public override oninit (): void {
        super.oninit();

        this.observe('account', this.addAccountInfo, { init: false });
    }

    public override oncomplete (): void {
    // Для общих настроек по типам сессии
        this.sessionTypesItem = {};
        // Для настроек по типам маржи
        this.marginTypesItem = {};
        // Для настроек по типам fee
        this.feeTypesItem = {};
        // Для настроек по типам tickSizeCost
        this.tickSizeCostTypesItem = {};
        // Для настроек по типам rebate
        this.rebateTypesItem = {};

        this.dynamicTypesItem = {};

        super.oncomplete();

        this.quickTableRactive.setShowColumnHeaders(false);

        const qt = this.getQuickTable();
        qt.allowGroupBy = false;
        qt.useVerticalSeparatorForResizing = true;
        qt.OnDrawCell.Subscribe(SymbolInfoPanel.onDrawCell, this);
        qt.AfterEditItem.Subscribe(this.onQuickTableAfterEditItem, this);
        qt.FormatGroup = this.quickTable_FormatGroup.bind(this);

        KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);

        // this.on(Control.Events.LostFocus, this.LostFocus);
        DataCache.OnCommissionPlanUpdate.Subscribe(this.PopulateTable, this);
        WDSettings.settingsChanged.Subscribe(this.PopulateTable, this);

        this.changeInstrument(this.initLinkInSymbol);
    }

    public onGlobalKeyDown (key: KeyCode): void {
        if (key === KeyCode.ESC) {
            this.LostFocus();
        }
    }

    public override accountLink_In (accId: string): boolean {
        if (!LinkedSystem.accLinkingActive) {
            return false;
        }

        if (!isNullOrUndefined(DataCache.Accounts[accId])) {
            void this.set('account', DataCache.Accounts[accId]);
        }

        this.localizeAccountLinkTooltip();

        return true;
    }

    public override accountLink_Out (newSubscriber, account: Account): void {
        if (isNullOrUndefined(account)) {
            const acc: Account = this.get('account');
            if (isNullOrUndefined(acc)) return;
            account = acc;
        }

        if (LinkedSystem.accLinkingActive) {
            this.completed
                ? LinkedSystem.setAccount(LinkedSystemAccLinkingValue, account.BstrAccount)
                : this.accountLink_In(LinkedSystem.getAccount(LinkedSystemAccLinkingValue));
        }
    }

    public override PopulateTable (needAddAccountInfo = true): void {
        super.PopulateTable();

        if (needAddAccountInfo) {
            this.addAccountInfo();
        }
    }

    public addAccountInfo (): void {
        if (isNullOrUndefined(this.get('account'))) {
            void this.set('account', DataCache.getPrimaryAccount());
        }

        if (this.VerticalPanelDataProvider != null) {
            this.VerticalPanelDataProvider.dependency = this.get('account');
        }

        this.updateAccountLinkVisible(LinkedSystem.accLinkingActive, true); // #120034

        if (!DataCache.userHasSameCommissionAndSwapPlanOnAllAccounts()) { // #120126 <- sometimes need to repopulate Symbol info on account change
            this.PopulateTable(false);
        }

        if (this.needAddInfoIconToFeesGroupRow()) {
            this.addInfoIconToGroupRow(SymbolInfoPanel.KEY_fees);
        } else {
            this.removeInfoIconFromGroupRow(SymbolInfoPanel.KEY_fees);
        }
    }

    public needAddInfoIconToFeesGroupRow (): boolean {
        const acc: Account = this.get('account');
        const primaryAcc = DataCache.getPrimaryAccount();

        return !DataCache.userHasSameCommissionAndSwapPlanOnAllAccounts() &&
        (!LinkedSystem.accLinkingActive || acc?.BstrAccount === primaryAcc?.BstrAccount);
    }

    public addInfoIconToGroupRow (groupKey): void {
        const qt = this.getQuickTable();
        if (qt?.groupStorageDict[groupKey] != null) {
            const groupRow = qt.groupStorageDict[groupKey].groupHeaderRow;
            const account = this.get('account');
            if (groupRow != null && account != null) {
                const tt = Resources.getResource(groupKey + '.InfoIconTooltip');
                groupRow.infoIconTooltip = tt.replace('{0}', account.toString());
                qt.redraw();
            }
        }
    }

    public removeInfoIconFromGroupRow (groupKey: string): void {
        const qt = this.getQuickTable();
        if (qt?.groupStorageDict[groupKey] != null) {
            const groupRow = qt.groupStorageDict[groupKey].groupHeaderRow;
            if (groupRow != null && groupRow.infoIconTooltip !== '') {
                groupRow.infoIconTooltip = '';
                qt.redraw();
            }
        }
    }

    public LostFocus (): void {
        void this.set({ visible: false });
        this.OnClose.Raise();
    }

    public static onDrawCell (gr: CanvasRenderingContext2D, e): void {
        const cell = e.cell;
        const formattedValue: string = cell.formattedValue;
        const splits = formattedValue.split(SymbolInfoPanel.commisionSplitter);

        const splitsLen = splits.length;
        if (splitsLen < 2) {
            return;
        }

        let x = Math.round(e.x);
        const y = e.y;
        const w = e.width;
        const h = e.height;

        const bottom = y + h;
        const splitCellWArr = (new Array(splitsLen)).fill(w / splitsLen); // доп. колонки имеют одинаковую ширину в общем случае

        if (splitsLen === 2) // #96044
        {
            splitCellWArr[0] *= (4 / 3); // #96044 для частного случая 2х доп колонок делаем ширину 2:1 (66.(6)% : 33.(3)%)
            splitCellWArr[1] *= (2 / 3);
        }

        const gridColor = e.table.gridColor;

        for (let i = 0; i < splitsLen; i++) {
            const splitCellW = splitCellWArr[i];

            gr.fillStyle = e.BackColor;
            gr.fillRect(x, y, splitCellW, h);

            if (i !== 0) {
                gr.beginPath();
                gr.lineWidth = 1;
                gr.strokeStyle = gridColor;
                gr.moveTo(x, y);
                gr.lineTo(x, bottom);
                gr.stroke();
            }

            const text = splits[i];

            gr.font = e.font;
            gr.fillStyle = e.ForeColor;

            QuickTableUtils.DrawTextInRect(gr, text, x, y, splitCellW, h, 5, 3);

            x += splitCellW;
        }

        e.handled = true;
    }

    public onQuickTableAfterEditItem (params): void {
        if (isNullOrUndefined(params)) {
            return;
        }

        const instrument = this.myIns;
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const table = params.table;
        const row = params.row;
        const cell = params.cell;
        const columnIndex = params.columnIndex;

        if (!isNullOrUndefined(row) && row.item.RowKey === SymbolInfoPanel.KEY_NextHoliday && cell?.value) {
            const scrLocation = ControlsUtils.getAbsoluteLocation(this);

            const sortedColumns = table.sortedColumns;
            const len = sortedColumns.length;
            let columnLeft = scrLocation.X;
            for (let i = 0; i < len; i++) {
                if (i === columnIndex) {
                    break;
                }

                columnLeft += sortedColumns[i].width;
            }

            InstrumentHolidaysScreen.show(instrument, columnLeft - 40, scrLocation.Y + row.displayRectangle.Bottom());
        }

        if (!isNullOrUndefined(row) && (row.item.RowKey === SymbolInfoPanel.KEY_Yield || row.item.RowKey === SymbolInfoPanel.KEY_YTM)) {
            DataCache.GetYieldRate(instrument);
        }

        if (!isNullOrUndefined(row) && row.item.RowKey === SymbolInfoPanel.KEY_ACCRUED_INTEREST) {
            void DataCache.updateInstrumentById(instrument.Id);
        }
    }

    public override dispose (): void {
        this.changeInstrument(null);

        const qt = this.getQuickTable();
        if (!isNullOrUndefined(qt)) {
            qt.OnDrawCell.UnSubscribe(SymbolInfoPanel.onDrawCell, this);
            qt.AfterEditItem.UnSubscribe(this.onQuickTableAfterEditItem, this);
        }

        KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);

        DataCache.OnCommissionPlanUpdate.UnSubscribe(this.PopulateTable, this);
        WDSettings.settingsChanged.UnSubscribe(this.PopulateTable, this);

        super.dispose();
    }

    public changeInstrument (instrument: Instrument): void {
        const oldInstrument = this.VerticalPanelDataProvider.Source;
        this.VerticalPanelDataProvider.Source = instrument;

        // hsa: #73351 подписка и отписка нужны для Reference Price.
        if (!isNullOrUndefined(oldInstrument)) {
            DataCache.FQuoteCache.removeListener(oldInstrument, this, HistoryType.QUOTE_LEVEL1);
        }

        if (isNullOrUndefined(instrument)) {
            return;
        }

        this.myIns = instrument;

        // hsa: #73351 подписка и отписка нужны для Reference Price.
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);

        this.PopulateTable();
        this.updatePanelHeader();

        void this.set({ visible: true });
        this.setFocus();
    }

    public newQuote (msg): void { }

    public override symbolLink_In (symbolName: string): void {
        const newInstr = DataCache.getInstrumentByName(symbolName);
        if (this.completed) {
            this.changeInstrument(newInstr);
        } else {
            this.initLinkInSymbol = newInstr;
        }
    }

    public override getInstrument (): Instrument | null {
        if (isNullOrUndefined(this.myIns)) {
            return null;
        }
        return this.myIns;
    }

    public override updatePanelHeader (): void {
        let headerStr = Resources.getResource(this.headerLocaleKey);
        const instrument = this.myIns;
        if (!isNullOrUndefined(instrument)) {
            headerStr += ' ' + instrument.DisplayName();
        }

        void this.set('header', headerStr);
    }

    public override InitializeDataProvider (): void {
        this.VerticalPanelDataProvider = new VerticalPanelDataProvider(this.sett);
        // Data items
        const dataItems = SymbolInfoPanel.getDataItems();
        this.VerticalPanelDataProvider.items = dataItems;

        // dynamic tickSizeCost
        const tickSizeCost = SymbolInfoPanel.tickSizeCost;
        const tickSizeCostTypesItem = this.tickSizeCostTypesItem = {};
        for (let i = 0; i < tickSizeCost.length; i++) {
            const key = tickSizeCost[i];
            tickSizeCostTypesItem[key] = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_tradingInfo, 90 + i, key, key);
        }

        // +++ dynamic margins
        const margins = SymbolInfoPanel.margins;
        const marginTypesItem = this.marginTypesItem = {};
        for (let i = 0; i < margins.length; i++) {
            const key = margins[i];
            marginTypesItem[key] = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_marginReg, 70 + i, key, key);
        }

        // +++ dynamic fees
        const fees = SymbolInfoPanel.fees;
        const feeTypesItem = this.feeTypesItem = {};
        for (let i = 0; i < fees.length; i++) {
            const key = fees[i];
            feeTypesItem[key] = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_fees, 109 + i, key, key);
        }

        this.VerticalPanelDataProvider.GetVerticalPanelValueHandler = SymbolInfoPanel.GetDataValue.bind(this);
        this.VerticalPanelDataProvider.GetItemsIDHandler = SymbolInfoPanel.GetItemsID.bind(this);
        this.VerticalPanelDataProvider.GetDynamicItemsHandler = this.GetDynamicItems.bind(this);
        // на смену Source отписка и пр.
        this.VerticalPanelDataProvider.DisposeHandler = SymbolInfoPanel.DisposeSource.bind(this);
    }

    public quickTable_FormatGroup (groupValue: string): string {
        if (groupValue === SymbolInfoPanel.KEY_sessionInfo) {
            return SymbolInfoPanel.LocalizeSessionGroup(this.VerticalPanelDataProvider);
        }

        return Resources.getResource(groupValue);
    }

    public static readonly PRODUCT_TYPE_INTRADAY = 'Intraday';// ProductType.Intraday;
    public static readonly PRODUCT_TYPE_DELIVERY = 'Delivery';// ProductType.Delivery;
    public static readonly PRODUCT_TYPE_SEPARATOR = '.';

    public static readonly MARGIN_TIER_SEPARATOR = '#';
    // #region localized keys

    // Groups
    public static readonly KEY_generalInfo = 'InstrumentDetailsPanel.1.GeneralInfo';
    public static readonly KEY_tradingInfo = 'InstrumentDetailsPanel.2.TradingInfo';
    public static readonly KEY_marginReg = 'InstrumentDetailsPanel.3.MarginReg';
    public static readonly KEY_fees = 'InstrumentDetailsPanel.4.Fees';
    public static readonly KEY_rebates = 'InstrumentDetailsPanel.5.Rebates';
    public static readonly KEY_sessionInfo = 'InstrumentDetailsPanel.6.SessionInfo';
    public static readonly KEY_plans = 'InstrumentDetailsPanel.7.Plans';

    // 1. General info
    public static readonly KEY_Symbol = 'InstrumentDetailsPanel.Symbol';
    public static readonly KEY_Description = 'InstrumentDetailsPanel.Description';
    public static readonly KEY_FuturesClass = 'InstrumentDetailsPanel.FuturesClass';
    public static readonly KEY_ExchangeTrading = 'InstrumentDetailsPanel.ExchangeTrading';
    public static readonly KEY_ExchangeMarketData = 'InstrumentDetailsPanel.ExchangeMarketData';
    public static readonly KEY_AssetClass = 'InstrumentDetailsPanel.AssetClass';
    public static readonly KEY_Underlier = 'InstrumentDetailsPanel.Underlier';
    public static readonly KEY_DeliveryMethod = 'InstrumentDetailsPanel.DeliveryMethod';
    public static readonly KEY_ContactMonth = 'InstrumentDetailsPanel.ContactMonth';
    public static readonly KEY_StrikePrice = 'InstrumentDetailsPanel.StrikePrice';
    public static readonly KEY_FirstTradeDate = 'InstrumentDetailsPanel.FirstTradeDate';
    public static readonly KEY_LastTradeDate = 'InstrumentDetailsPanel.LastTradeDate';
    public static readonly KEY_NoticeDate = 'InstrumentDetailsPanel.NoticeDate';
    public static readonly KEY_CloseOutDeadline = 'InstrumentDetailsPanel.CloseOutDeadline';
    public static readonly KEY_TradingBalance = 'InstrumentDetailsPanel.TradingBalance';

    public static readonly KEY_MaturityDate = 'InstrumentDetailsPanel.MaturityDate';
    public static readonly KEY_FaceValue = 'InstrumentDetailsPanel.FaceValue';
    public static readonly KEY_CouponRate = 'InstrumentDetailsPanel.CouponRate';
    public static readonly KEY_CouponCycle = 'InstrumentDetailsPanel.CouponCycle';
    public static readonly KEY_YTM = 'InstrumentDetailsPanel.YTM';
    public static readonly KEY_ACCRUED_INTEREST = 'InstrumentDetailsPanel.AccruedInterest';
    public static readonly KEY_PREV_PAYMENT_DATE = 'InstrumentDetailsPanel.PreviousPaymentDate';
    public static readonly KEY_NEXT_PAYMENT_DATE = 'InstrumentDetailsPanel.NextPaymentDate';
    public static readonly KEY_Exp1 = 'InstrumentDetailsPanel.Exp1';
    public static readonly KEY_Yield = 'InstrumentDetailsPanel.Yield';
    public static readonly KEY_ISIN = 'InstrumentDetailsPanel.ISIN';
    public static readonly KEY_Industry = 'InstrumentDetailsPanel.Industry';
    public static readonly KEY_Sector = 'InstrumentDetailsPanel.Sector';
    public static readonly KEY_ExerciseStyle = 'InstrumentDetailsPanel.ExerciseStyle';
    public static readonly KEY_DAYS_PER_MONTH = 'InstrumentDetailsPanel.BondDaysPerMonth';
    public static readonly KEY_DAYS_PER_YEAR = 'InstrumentDetailsPanel.BondDaysPerYear';

    // 2. Trading info
    public static readonly KEY_TradingStatus = 'InstrumentDetailsPanel.TradingStatus';
    public static readonly KEY_AllowedOperations = 'InstrumentDetailsPanel.AllowedOperations';
    public static readonly KEY_AllowedOrderTypes = 'InstrumentDetailsPanel.AllowedOrderTypes';
    public static readonly KEY_ProductType = 'InstrumentDetailsPanel.ProductType';
    public static readonly KEY_DeliveryStatus = 'InstrumentDetailsPanel.DeliveryStatus';
    public static readonly KEY_AllowShortPositions = 'InstrumentDetailsPanel.AllowShortPositions';
    public static readonly KEY_AllowShortPositionsIntraday = 'InstrumentDetailsPanel.AllowShortPositions' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static KEY_AllowShortPositionsDelivery = 'InstrumentDetailsPanel.AllowShortPositions' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_AllowOvernightTrading = 'InstrumentDetailsPanel.AllowOvernightTrading';
    // public static readonly KEY_PrevSettlement = 'InstrumentDetailsPanel.PrevSettlement'
    public static readonly KEY_CurrentSession = 'InstrumentDetailsPanel.CurrentSession';
    public static readonly KEY_ETB = 'InstrumentDetailsPanel.ETB';
    public static readonly KEY_SessionStatus = 'InstrumentDetailsPanel.SessionStatus';
    public static readonly KEY_NextHoliday = 'InstrumentDetailsPanel.KEYNextHoliday';
    public static readonly KEY_QuotiongCurrency = 'InstrumentDetailsPanel.QuotiongCurrency';
    public static readonly KEY_LotSize = 'InstrumentDetailsPanel.LotSize';
    public static readonly KEY_ContractMultiplier = 'InstrumentDetailsPanel.ContractMultiplier';
    // public static readonly KEY_ContractSize = 'InstrumentDetailsPanel.ContractSize'
    public static readonly KEY_TickSize = 'InstrumentDetailsPanel.TickSize';
    public static readonly KEY_TickCoast = 'InstrumentDetailsPanel.TickCoast';
    public static readonly KEY_MinimalLot = 'InstrumentDetailsPanel.MinimalLot';
    public static readonly KEY_MinimalLotIntraday = 'InstrumentDetailsPanel.MinimalLot' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MinimalLotDelivery = 'InstrumentDetailsPanel.MinimalLot' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_LotStep = 'InstrumentDetailsPanel.LotStep';
    public static readonly KEY_HiLimit = 'InstrumentDetailsPanel.HiLimit';
    public static readonly KEY_LowLimit = 'InstrumentDetailsPanel.LowLimit';
    public static readonly KEY_MaximumLot = 'InstrumentDetailsPanel.MaximumLot';
    public static readonly KEY_MaximumLotIntraday = 'InstrumentDetailsPanel.MaximumLot' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MaximumLotDelivery = 'InstrumentDetailsPanel.MaximumLot' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MaxPositionQty = 'InstrumentDetailsPanel.MaxPositionQtyPerSymbol';
    public static readonly KEY_MaxPositionQtyIntraday = 'InstrumentDetailsPanel.MaxPositionQtyPerSymbol' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MaxPositionQtyDelivery = 'InstrumentDetailsPanel.MaxPositionQtyPerSymbol' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_TradindBlockedOnSession = 'InstrumentDetailsPanel.TradindBlockedOnSession';
    public static readonly KEY_ExchangeTradeSession = 'InstrumentDetailsPanel.ExchangeTradeSession';
    public static readonly KEY_NormalMarketSize = 'InstrumentDetailsPanel.NormalMarketSize';
    public static readonly KEY_HighLimitWarning = 'InstrumentDetailsPanel.HighLimitWarning';
    public static readonly KEY_LowLimitWarning = 'InstrumentDetailsPanel.LowLimitWarning';
    // public static readonly KEY_HiLimitFromPriceLimitsMsg = 'InstrumentDetailsPanel.HiLimitFromPriceLimitsMsg'
    // public static readonly KEY_LowLimitFromPriceLimitsMsg = 'InstrumentDetailsPanel.LowLimitFromPriceLimitsMsg'

    // 3. Margin reg
    public static readonly KEY_Margin_ByAccount = 'InstrumentDetailsPanel.Margin_ByAccount';
    public static readonly KEY_Margin = 'InstrumentDetailsPanel.Margin';
    public static readonly KEY_MarginBuy = 'InstrumentDetailsPanel.MarginBuy';
    public static readonly KEY_MarginSell = 'InstrumentDetailsPanel.MarginSell';
    public static readonly KEY_MarginOvernight = 'InstrumentDetailsPanel.MarginOvernight';
    public static readonly KEY_MarginDay = 'InstrumentDetailsPanel.MarginDay';
    public static readonly KEY_MarginDayBuy = 'InstrumentDetailsPanel.MarginDayBuy';
    public static readonly KEY_MarginDaySell = 'InstrumentDetailsPanel.MarginDaySell';
    public static readonly KEY_MarginOvernightBuy = 'InstrumentDetailsPanel.MarginOvernightBuy';
    public static readonly KEY_MarginOvernightSell = 'InstrumentDetailsPanel.MarginOvernightSell';
    public static readonly KEY_MarginInAccountCurrency = 'InstrumentDetailsPanel.MarginInAccountCurrency';

    public static readonly KEY_Leverage = 'InstrumentDetailsPanel.Leverage'; // #112886
    public static readonly KEY_LeverageIntraday = SymbolInfoPanel.KEY_Leverage + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_LeverageDelivery = SymbolInfoPanel.KEY_Leverage + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;

    public static readonly KEY_MarginIntraday = 'InstrumentDetailsPanel.Margin' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginBuyIntraday = 'InstrumentDetailsPanel.MarginBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginSellIntraday = 'InstrumentDetailsPanel.MarginSell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginOvernightIntraday = 'InstrumentDetailsPanel.MarginOvernight' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginDayIntraday = 'InstrumentDetailsPanel.MarginDay' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginDayBuyIntraday = 'InstrumentDetailsPanel.MarginDayBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginDaySellIntraday = 'InstrumentDetailsPanel.MarginDaySell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginOvernightBuyIntraday = 'InstrumentDetailsPanel.MarginOvernightBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginOvernightSellIntraday = 'InstrumentDetailsPanel.MarginOvernightSell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
    public static readonly KEY_MarginInAccountCurrencyIntraday = 'InstrumentDetailsPanel.MarginInAccountCurrency' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;

    public static readonly KEY_MarginDelivery = 'InstrumentDetailsPanel.Margin' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginBuyDelivery = 'InstrumentDetailsPanel.MarginBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginSellDelivery = 'InstrumentDetailsPanel.MarginSell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginOvernightDelivery = 'InstrumentDetailsPanel.MarginOvernight' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginDayDelivery = 'InstrumentDetailsPanel.MarginDay' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginDayBuyDelivery = 'InstrumentDetailsPanel.MarginDayBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginDaySellDelivery = 'InstrumentDetailsPanel.MarginDaySell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginOvernightBuyDelivery = 'InstrumentDetailsPanel.MarginOvernightBuy' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginOvernightSellDelivery = 'InstrumentDetailsPanel.MarginOvernightSell' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
    public static readonly KEY_MarginInAccountCurrencyDelivery = 'InstrumentDetailsPanel.MarginInAccountCurrency' + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;

    // 4. Fees

    public static readonly KEY_ShortCommissionInfo = KEY_ShortCommissionInfo;
    public static readonly KEY_OptionExercise = KEY_OptionExercise;
    public static readonly KEY_CustodialFee = KEY_CustodialFee;
    public static readonly KEY_ShortPositionInterest = KEY_ShortPositionInterest;
    public static readonly KEY_FILLPERLOT = KEY_FILLPERLOT;
    public static readonly KEY_ORDERPERLOT = KEY_ORDERPERLOT;
    public static readonly KEY_PERFILL = KEY_PERFILL;
    public static readonly KEY_PERTRANSACTION = KEY_PERTRANSACTION;
    public static readonly KEY_PERPHONETRANSACTION = KEY_PERPHONETRANSACTION;
    public static readonly KEY_PERVOLUME = KEY_PERVOLUME;
    public static readonly KEY_PERORDERVOLUME = KEY_PERORDERVOLUME;
    public static readonly KEY_VAT = KEY_VAT;
    public static readonly KEY_VOLUME_WITH_MIN_PD = KEY_VOLUME_WITH_MIN_PD;
    public static readonly KEY_VOLUME = KEY_VOLUME;
    public static readonly KEY_ORDER_VOLUME = KEY_ORDER_VOLUME;
    public static readonly KEY_CARE_ORDER = KEY_CARE_ORDER;

    public static readonly KEY_ShortSwapInfo = 'InstrumentDetailsPanel.SwapDetails';
    public static readonly KEY_SwapBuy = 'InstrumentDetailsPanel.SwapBuy';
    public static readonly KEY_SwapSell = 'InstrumentDetailsPanel.SwapSell';

    public static readonly KEY_Historical_LongSwap = 'InstrumentDetailsPanel.Latest historical long swap';
    public static readonly KEY_Historical_ShortSwap = 'InstrumentDetailsPanel.Latest historical short swap';
    public static readonly KEY_Historical_LastSwap = 'InstrumentDetailsPanel.Historical swap last updated on';

    public static readonly KEY_Historical_LongSwap_shortKey = 'Latest historical long swap';
    public static readonly KEY_Historical_ShortSwap_shortKey = 'Latest historical short swap';
    public static readonly KEY_Historical_LastSwap_shortKey = 'Historical swap last updated on';
    // 5. Session info
    // ...
    public static readonly KEY_SettlementDate = 'InstrumentDetailsPanel.SettlementDate';

    public static readonly SESSION_DAY_PERIOD_PREOPEN = 'InstrumentDetailsPanel.PREOPEN';
    public static readonly SESSION_DAY_PERIOD_MAIN = 'InstrumentDetailsPanel.MAIN';
    public static readonly SESSION_DAY_PERIOD_POST_CLOSE = 'InstrumentDetailsPanel.POSTCLOSE';
    public static readonly SESSION_DAY_PERIOD_BEFORE_MARKET = 'InstrumentDetailsPanel.BEFORE_MARKET';
    public static readonly SESSION_DAY_PERIOD_AFTER_MARKET = 'InstrumentDetailsPanel.AFTER_MARKET';

    // 7. Plans info
    public static readonly KEY_CommissionInfo = 'InstrumentDetailsPanel.Commisions';

    // 8. ExtField
    public static readonly KEY_EXTFIELD_TRADINGUNIT = 'InstrumentDetailsPanel.ExtField.TradingUnit';
    public static readonly KEY_EXTFIELD_PRICEQUOTEUNIT = 'InstrumentDetailsPanel.ExtField.PriceQuoteUnit';
    public static readonly KEY_EXTFIELD_DELIVERYUNIT = 'InstrumentDetailsPanel.ExtField.DeliveryUnit';
    public static readonly KEY_EXTFIELD_LOTSIZE = 'InstrumentDetailsPanel.ExtField.LotSize';
    public static readonly KEY_EXTFIELD_TENDERPERIODSTARTDATE = 'InstrumentDetailsPanel.ExtField.TenderPeriodStartDate';
    public static readonly KEY_EXTFIELD_TENDERPERIODENDDATE = 'InstrumentDetailsPanel.ExtField.TenderPeriodEndDate';
    public static readonly KEY_SMFLAG_PREFIX = 'InstrumentDetailsPanel.ExtField.SMFlag.';
    public static readonly KEY_ASMFLAG_SHORT = 'InstrumentDetailsPanel.ExtField.SMFlag.Short term ASM Flag';
    public static readonly KEY_ASMFLAG_LONG = 'InstrumentDetailsPanel.ExtField.SMFlag.Long term ASM Flag';
    public static readonly KEY_GSMFLAG = 'InstrumentDetailsPanel.ExtField.SMFlag.GSM Flag';
    public static readonly KEY_ESMFLAG = 'InstrumentDetailsPanel.ExtField.SMFlag.ESM Flag';

    // #endregion localized keys

    public static readonly tickSizeCost =
        [
            SymbolInfoPanel.KEY_TickSize,
            SymbolInfoPanel.KEY_TickCoast,
            SymbolInfoPanel.KEY_LotSize
        ];

    public static readonly margins =
        [
            SymbolInfoPanel.KEY_Margin_ByAccount,
            SymbolInfoPanel.KEY_Margin,
            SymbolInfoPanel.KEY_MarginIntraday,
            SymbolInfoPanel.KEY_MarginDelivery,
            SymbolInfoPanel.KEY_MarginBuy,
            SymbolInfoPanel.KEY_MarginBuyIntraday,
            SymbolInfoPanel.KEY_MarginBuyDelivery,
            SymbolInfoPanel.KEY_MarginSell,
            SymbolInfoPanel.KEY_MarginSellIntraday,
            SymbolInfoPanel.KEY_MarginSellDelivery,
            SymbolInfoPanel.KEY_MarginDay,
            SymbolInfoPanel.KEY_MarginDayIntraday,
            SymbolInfoPanel.KEY_MarginDayDelivery,
            SymbolInfoPanel.KEY_MarginOvernight,
            SymbolInfoPanel.KEY_MarginOvernightIntraday,
            SymbolInfoPanel.KEY_MarginOvernightDelivery,
            SymbolInfoPanel.KEY_MarginDayBuy,
            SymbolInfoPanel.KEY_MarginDayBuyIntraday,
            SymbolInfoPanel.KEY_MarginDayBuyDelivery,
            SymbolInfoPanel.KEY_MarginDaySell,
            SymbolInfoPanel.KEY_MarginDaySellIntraday,
            SymbolInfoPanel.KEY_MarginDaySellDelivery,
            SymbolInfoPanel.KEY_MarginOvernightBuy,
            SymbolInfoPanel.KEY_MarginOvernightBuyIntraday,
            SymbolInfoPanel.KEY_MarginOvernightBuyDelivery,
            SymbolInfoPanel.KEY_MarginOvernightSell,
            SymbolInfoPanel.KEY_MarginOvernightSellIntraday,
            SymbolInfoPanel.KEY_MarginOvernightSellDelivery,
            SymbolInfoPanel.KEY_MarginInAccountCurrency,
            SymbolInfoPanel.KEY_Leverage,
            SymbolInfoPanel.KEY_LeverageIntraday,
            SymbolInfoPanel.KEY_LeverageDelivery
        ];

    public static readonly fees =
        [
            SymbolInfoPanel.KEY_CustodialFee,
            SymbolInfoPanel.KEY_ShortPositionInterest,
            SymbolInfoPanel.KEY_Historical_LongSwap,
            SymbolInfoPanel.KEY_Historical_ShortSwap,
            SymbolInfoPanel.KEY_Historical_LastSwap
        ];

    public static createItem (Group, SortIndex, LocalizationKey, Id, DynPropertyControltype?): VerticalPanelDataProviderItem {
        const item = new VerticalPanelDataProviderItem();
        item.Group = Group;
        item.SortIndex = SortIndex;
        item.LocalizationKey = LocalizationKey;
        item.Id = Id;
        if (!isNullOrUndefined(DynPropertyControltype)) {
            item.DynPropertyControltype = DynPropertyControltype;
        }

        return item;
    }

    public static getDataItems (): VerticalPanelDataProviderItem[] {
        const temp: VerticalPanelDataProviderItem[] = [];
        try {
            const generalInfo = SymbolInfoPanel.KEY_generalInfo;
            const tradingInfo = SymbolInfoPanel.KEY_tradingInfo;

            let i = 0;
            // 1. General info
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Symbol, SymbolInfoPanel.KEY_Symbol));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Description, SymbolInfoPanel.KEY_Description));
            // temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_FuturesClass, SymbolInfoPanel.KEY_FuturesClass));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ExchangeTrading, SymbolInfoPanel.KEY_ExchangeTrading));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ExchangeMarketData, SymbolInfoPanel.KEY_ExchangeMarketData));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_AssetClass, SymbolInfoPanel.KEY_AssetClass));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Underlier, SymbolInfoPanel.KEY_Underlier));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_DeliveryMethod, SymbolInfoPanel.KEY_DeliveryMethod));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ContactMonth, SymbolInfoPanel.KEY_ContactMonth));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_FirstTradeDate, SymbolInfoPanel.KEY_FirstTradeDate));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_LastTradeDate, SymbolInfoPanel.KEY_LastTradeDate));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_NoticeDate, SymbolInfoPanel.KEY_NoticeDate));// 10
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_SettlementDate, SymbolInfoPanel.KEY_SettlementDate));
            // temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_CloseOutDeadline, SymbolInfoPanel.KEY_CloseOutDeadline));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_StrikePrice, SymbolInfoPanel.KEY_StrikePrice));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_TradingBalance, SymbolInfoPanel.KEY_TradingBalance));

            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_MaturityDate, SymbolInfoPanel.KEY_MaturityDate));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_FaceValue, SymbolInfoPanel.KEY_FaceValue));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_CouponRate, SymbolInfoPanel.KEY_CouponRate));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_CouponCycle, SymbolInfoPanel.KEY_CouponCycle));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_YTM, SymbolInfoPanel.KEY_YTM, DynProperty.LINK_WITH_TEXT));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ACCRUED_INTEREST, SymbolInfoPanel.KEY_ACCRUED_INTEREST, DynProperty.LINK_WITH_TEXT));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_PREV_PAYMENT_DATE, SymbolInfoPanel.KEY_PREV_PAYMENT_DATE));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_NEXT_PAYMENT_DATE, SymbolInfoPanel.KEY_NEXT_PAYMENT_DATE));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Exp1, SymbolInfoPanel.KEY_Exp1));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Yield, SymbolInfoPanel.KEY_Yield, DynProperty.LINK_WITH_TEXT));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ISIN, SymbolInfoPanel.KEY_ISIN));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Industry, SymbolInfoPanel.KEY_Industry));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_Sector, SymbolInfoPanel.KEY_Sector));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ExerciseStyle, SymbolInfoPanel.KEY_ExerciseStyle));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_DAYS_PER_MONTH, SymbolInfoPanel.KEY_DAYS_PER_MONTH));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_DAYS_PER_YEAR, SymbolInfoPanel.KEY_DAYS_PER_YEAR));
            // #114474
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_GSMFLAG, SymbolInfoPanel.KEY_GSMFLAG));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ESMFLAG, SymbolInfoPanel.KEY_ESMFLAG));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ASMFLAG_LONG, SymbolInfoPanel.KEY_ASMFLAG_LONG));
            temp.push(SymbolInfoPanel.createItem(generalInfo, i++, SymbolInfoPanel.KEY_ASMFLAG_SHORT, SymbolInfoPanel.KEY_ASMFLAG_SHORT));
            // 2. Trading info
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_TradingStatus, SymbolInfoPanel.KEY_TradingStatus));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowedOperations, SymbolInfoPanel.KEY_AllowedOperations));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowedOrderTypes, SymbolInfoPanel.KEY_AllowedOrderTypes));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_ProductType, SymbolInfoPanel.KEY_ProductType));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_DeliveryStatus, SymbolInfoPanel.KEY_DeliveryStatus));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_CurrentSession, SymbolInfoPanel.KEY_CurrentSession));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_SessionStatus, SymbolInfoPanel.KEY_SessionStatus));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_NextHoliday, SymbolInfoPanel.KEY_NextHoliday, DynProperty.LINK));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_QuotiongCurrency, SymbolInfoPanel.KEY_QuotiongCurrency));
            // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_LotSize, SymbolInfoPanel.KEY_LotSize));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_ContractMultiplier, SymbolInfoPanel.KEY_ContractMultiplier));

            // #32476 (Symbol info) перменный тик сайз/кост
            // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_ContractSize, SymbolInfoPanel.KEY_ContractSize));
            // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_TickCoast, SymbolInfoPanel.KEY_TickCoast));
            // temp.push(SymbolInfoPanel.createItem(tradingInfo, 55, SymbolInfoPanel.KEY_TickSize, SymbolInfoPanel.KEY_TickSize));//20
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MinimalLot, SymbolInfoPanel.KEY_MinimalLot));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MinimalLotIntraday, SymbolInfoPanel.KEY_MinimalLotIntraday));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MinimalLotDelivery, SymbolInfoPanel.KEY_MinimalLotDelivery));
            // #36418
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaximumLot, SymbolInfoPanel.KEY_MaximumLot));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaximumLotIntraday, SymbolInfoPanel.KEY_MaximumLotIntraday));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaximumLotDelivery, SymbolInfoPanel.KEY_MaximumLotDelivery));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaxPositionQty, SymbolInfoPanel.KEY_MaxPositionQty));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaxPositionQtyIntraday, SymbolInfoPanel.KEY_MaxPositionQtyIntraday));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_MaxPositionQtyDelivery, SymbolInfoPanel.KEY_MaxPositionQtyDelivery));

            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_LotStep, SymbolInfoPanel.KEY_LotStep));
            // +++ #23263  Price limits
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_HiLimit, SymbolInfoPanel.KEY_HiLimit));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_LowLimit, SymbolInfoPanel.KEY_LowLimit));
            // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg, SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg));  //#111427
            // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg, SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg));
            SymbolInfoPanel.KEY_LowLimit_SortIndex = i;
            i += 20; // поскольку KEY_HighLimitWarning и KEY_LowLimitWarning динамические поля и создаются позже запоминаем SortIndex и резервируем для них 20 строк из расчета что не будет диапазонов больше чем на 10 строк
            /// /+++ #30591  SwapBuy Sell  #31570 -> Fees
            // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_SwapBuy, SymbolInfoPanel.KEY_SwapBuy));
            // temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_SwapSell, SymbolInfoPanel.KEY_SwapSell));
            // +++ #30783
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowShortPositions, SymbolInfoPanel.KEY_AllowShortPositions));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_ETB, SymbolInfoPanel.KEY_ETB));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowShortPositionsIntraday, SymbolInfoPanel.KEY_AllowShortPositionsIntraday));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowShortPositionsDelivery, SymbolInfoPanel.KEY_AllowShortPositionsDelivery));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_AllowOvernightTrading, SymbolInfoPanel.KEY_AllowOvernightTrading));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_NormalMarketSize, SymbolInfoPanel.KEY_NormalMarketSize));

            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT, SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT, SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT, SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE, SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE, SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE));
            temp.push(SymbolInfoPanel.createItem(tradingInfo, i++, SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE, SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE));
            // add Plan (AdminMode)
            // let AdminMode = SymbolInfoPanel.createItem(plansInfo, i++, SymbolInfoPanel.KEY_CommissionInfo, SymbolInfoPanel.KEY_CommissionInfo);
            // AdminMode.Hidden = !Utils.AdminMode;
            // temp.push(AdminMode);

            // 3. Margin reg
            // + dynamic items

            // 4. Fees
            // + dynamic items
            // X. rebates
            // + dynamic items

            // 5. Session info
            // + dynamic items
            // foreach (var item in temp)
            // item.tooltipKey = Resources.GetToolTipKey(item.localizationKey);

            return temp;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            return [];
        }
    }

    /// <summary>
    /// по ключу строки отдаем значение строки
    /// </summary>
    public static GetDataValue (key: string, VerticalPanelDataProvider: VerticalPanelDataProvider, sett): string {
        if (key.endsWith(SymbolInfoPanel.PRODUCT_TYPE_INTRADAY)) {
            key = key.substring(0, key.lastIndexOf(SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR));
            return SymbolInfoPanel.GetDataValueProductType(key, VerticalPanelDataProvider, sett, ProductType.Intraday);
        } else if (key.endsWith(SymbolInfoPanel.PRODUCT_TYPE_DELIVERY)) {
            key = key.substring(0, key.lastIndexOf(SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR));
            return SymbolInfoPanel.GetDataValueProductType(key, VerticalPanelDataProvider, sett, ProductType.Delivery);
        } else {
            const availableProductTypes = VerticalPanelDataProvider.Source.RiskSettings.RiskPlanSettings.availableProductTypes;
            return SymbolInfoPanel.GetDataValueProductType(key, VerticalPanelDataProvider, sett, availableProductTypes[0]);
        }
    }

    public static ConvertToBoolean (value: string): boolean {
        return value === 'true';
    }

    public static GetDataValueProductType (key: string, VerticalPanelDataProvider: VerticalPanelDataProvider, sett, productType: ProductType): string {
        try {
            const currentInstrument: Instrument = VerticalPanelDataProvider.Source;
            const account: Account = VerticalPanelDataProvider.dependency ?? DataCache.MainAccountNew;

            // Женя: закомментил.
            // 1. вся инфа есть в инструменте. 2. инструмент может быть нулл. 3. генерить из инструмента мессадж чтоб с него взять то что есть в инструменте не очень. + вызывается метод отдельно на апдейт каждой из строк.
            // InstrumentMessage mess = currentInstrument.CreateInstrumentMessage();

            // #region // 1. General info
            if (key === SymbolInfoPanel.KEY_Symbol) {
                return currentInstrument.DisplayName();
            } // прячем роут когда надо
            if (key === SymbolInfoPanel.KEY_Description) {
                return currentInstrument.DescriptionValue();
            } // mess.Descr;
            if (key === SymbolInfoPanel.KEY_FuturesClass) {
                return currentInstrument.SourceName;
            }
            if (key === SymbolInfoPanel.KEY_ExchangeTrading) {
                return currentInstrument.TradingExchange;
            } // Никита: сделал нормальное отображение биржи (возможно стоит закэшировать)
            if (key === SymbolInfoPanel.KEY_ExchangeMarketData) {
                return currentInstrument.MarketDataExchange;
            }
            if (key === SymbolInfoPanel.KEY_AssetClass) {
                return InstrumentUtils.getInstrumentTypeStringLocalized(currentInstrument.InstrType, currentInstrument.CFD);
            } // localized instrument type
            if (key === SymbolInfoPanel.KEY_Underlier) {
                return !isNullOrUndefined(currentInstrument.ForwardBaseInstrument) ? currentInstrument.ForwardBaseInstrument.ShortName : '';
            }
            if (key === SymbolInfoPanel.KEY_DeliveryMethod) {
                return currentInstrument.DeliveryMethod === Instrument.Delivery_Physical ? Resources.getResource('InstrumentDetailsPanel.Physically') : Resources.getResource('InstrumentDetailsPanel.Cash');
            }

            if (key === SymbolInfoPanel.KEY_ContactMonth) {
                return DateTimeUtils.formatDate(currentInstrument.ContractMonthDate, 'DD.MM.YYYY');
            }

            if (key === SymbolInfoPanel.KEY_FirstTradeDate) {
                if (currentInstrument.FirstTradeDate === DateTimeUtils._ZeroTime) {
                    return '';
                }

                return DateTimeUtils.formatDate(currentInstrument.FirstTradeDate, 'DD.MM.YYYY');
            }
            if (key === SymbolInfoPanel.KEY_LastTradeDate) {
                return DateTimeUtils.formatDate(currentInstrument.LastTradeDate, 'DD.MM.YYYY');
            }
            if (key === SymbolInfoPanel.KEY_NoticeDate) {
                if (currentInstrument.NoticeDate === DateTimeUtils._ZeroTime) {
                    return '';
                }

                return DateTimeUtils.formatDate(currentInstrument.NoticeDate, 'DD.MM.YYYY');
            }
            if (key === SymbolInfoPanel.KEY_SettlementDate) {
                if (!currentInstrument.SettlementDate) // #46089 ===null
                {
                    return Resources.getResource('InstrumentDetailsPanel.None');
                } else {
                    return DateTimeUtils.formatDate(currentInstrument.SettlementDate, 'DD.MM.YYYY');
                }
            }
            if (key === SymbolInfoPanel.KEY_CloseOutDeadline) {
                return DateTimeUtils.formatDate(currentInstrument.AutoCloseDate, 'DD.MM.YYYY');
            }

            if (key === SymbolInfoPanel.KEY_StrikePrice) {
                return currentInstrument.StrikePrice.toString();
            }

            if (key === SymbolInfoPanel.KEY_TradingBalance) {
                if (currentInstrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_IMMEDIATE) {
                    return Resources.getResource('InstrumentDetailsPanel.immediate');
                } else if (currentInstrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_T_PLUS) {
                    return Resources.getResource('InstrumentDetailsPanel.T+0');
                } else {
                    return ('T + ' + currentInstrument.TradingBalance.toString());
                }
            }

            if (key === SymbolInfoPanel.KEY_MaturityDate) {
                return currentInstrument.MaturityDate ? DateTimeUtils.formatDate(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(currentInstrument.MaturityDate), 'DD.MM.YYYY') : '---';
            }
            if (key === SymbolInfoPanel.KEY_FaceValue) {
                return currentInstrument.FaceValue.toString();
            }
            if (key === SymbolInfoPanel.KEY_CouponRate) {
                return currentInstrument.CouponRate + ' %';
            }
            if (key === SymbolInfoPanel.KEY_CouponCycle) {
                return Instrument.FormatCouponCycle(currentInstrument.CouponCycle);
            }
            if (key === SymbolInfoPanel.KEY_YTM) {
                return isValidNumber(currentInstrument.YieldRate) ? currentInstrument.YieldRate + ' %' : '0 %';
            }
            if (key === SymbolInfoPanel.KEY_ACCRUED_INTEREST) {
                return currentInstrument.formatPrice(currentInstrument.AccruedInterest) + ' ' + currentInstrument.Exp2;
            }
            if (key === SymbolInfoPanel.KEY_DAYS_PER_MONTH) {
                return InstrumentFeatureHelper.getDaysPerFormatted(currentInstrument.DaysPerMonth, GeneralSymbolInfoFeatureEnum.DaysPerMonth);
            }
            if (key === SymbolInfoPanel.KEY_DAYS_PER_YEAR) {
                return InstrumentFeatureHelper.getDaysPerFormatted(currentInstrument.DaysPerYear, GeneralSymbolInfoFeatureEnum.DaysPerYear);
            }
            if (key === SymbolInfoPanel.KEY_PREV_PAYMENT_DATE) {
                return DateTimeUtils.formatDate(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(currentInstrument.PrevPaymentDate), 'DD.MM.YYYY');
            }
            if (key === SymbolInfoPanel.KEY_NEXT_PAYMENT_DATE) {
                return DateTimeUtils.formatDate(currentInstrument.NextPaymentDate, 'DD.MM.YYYY');
            }
            if (key === SymbolInfoPanel.KEY_Exp1) {
                return currentInstrument.Exp1;
            }
            if (key === SymbolInfoPanel.KEY_Yield) {
                return isValidNumber(currentInstrument.YieldRate) ? currentInstrument.YieldRate + ' %' : '0 %';
            }
            if (key === SymbolInfoPanel.KEY_ISIN) {
                return currentInstrument.ISIN;
            }
            if (key === SymbolInfoPanel.KEY_Industry) {
                const industry = currentInstrument.LocalizedIndustry;
                return isValidString(industry)
                    ? industry
                    : (!isNullOrUndefined(currentInstrument.ForwardBaseInstrument) ? currentInstrument.ForwardBaseInstrument.Industry : '');
            }
            if (key === SymbolInfoPanel.KEY_Sector) {
                const sector = currentInstrument.LocalizedSector;
                return isValidString(sector)
                    ? sector
                    : (!isNullOrUndefined(currentInstrument.ForwardBaseInstrument) ? currentInstrument.ForwardBaseInstrument.Sector : '');
            }
            if (key === SymbolInfoPanel.KEY_ExerciseStyle) {
                return currentInstrument.GetLocalizedExerciseStyle();
            }

            // #endregion

            // #region // 2. Trading info
            if (key === SymbolInfoPanel.KEY_TradingStatus) {
                return InstrumentUtils.GetTradingStatus(currentInstrument);
            }
            if (key === SymbolInfoPanel.KEY_AllowedOperations) {
                const allow = SymbolInfoPanel.GetAllowedOperations(currentInstrument);
                if (isValidString(allow)) {
                    return allow;
                } else {
                    return Resources.getResource('panel.watchlist.cell.Not allow');
                }
            }

            if (key === SymbolInfoPanel.KEY_AllowedOrderTypes) {
                const allow = SymbolInfoPanel.GetAllowedOrderTypes(currentInstrument);
                if (isValidString(allow)) {
                    return allow;
                } else {
                    return Resources.getResource('panel.watchlist.cell.Not allow');
                }
            }
            if (key === SymbolInfoPanel.KEY_ProductType) {
                const availableProductTypes = currentInstrument.RiskSettings.RiskPlanSettings.availableProductTypes;

                if (availableProductTypes.length === 2) {
                    return Resources.getResource('ProductType.Intraday') + '/' + Resources.getResource('ProductType.Delivery');
                } else {
                    return availableProductTypes.length > 0 ? InstrumentUtils.GetLocalizedProductType(currentInstrument, availableProductTypes[0]) : '';
                }
            }

            if (key === SymbolInfoPanel.KEY_DeliveryStatus) {
                return currentInstrument.LastTradeDate < DateTimeUtils.DateTimeUtcNow()
                    ? SymbolInfoPanel.getDeliveryStatus(currentInstrument.DeliveryStatus)
                    : '---';
            }

            if (key === SymbolInfoPanel.KEY_CurrentSession) {
                return currentInstrument.ExchangeSessionName;
            }

            if (key === SymbolInfoPanel.KEY_SessionStatus) {
                const tradeSessionStatus = DataCache.GetTradeSessionStatus(currentInstrument.GetTradeSessionStatusId());

                return !isNullOrUndefined(tradeSessionStatus) ? tradeSessionStatus.Name : '';
            }

            if (key === SymbolInfoPanel.KEY_TradindBlockedOnSession) {
                if (currentInstrument.Session.BlockTrading || currentInstrument.Session.CurrentSessionPeriod == null) {
                    return Resources.getResource('InstrumentDetailsPanel.Yes');
                } else {
                    return Resources.getResource('InstrumentDetailsPanel.No');
                }
            }
            if (key === SymbolInfoPanel.KEY_NextHoliday) {
                const holiday = currentInstrument.Session.GetNextHoliday();
                return !isNullOrUndefined(holiday) ? holiday.GetFormattedDate() + ' (' + holiday.name + ')' : '';
            }
            if (key === SymbolInfoPanel.KEY_QuotiongCurrency) {
                return currentInstrument.Exp2;
            }

            if (key === SymbolInfoPanel.KEY_LotSize || /* key == SymbolInfoPanel.KEY_ContractSize || */ key.includes(SymbolInfoPanel.KEY_TickCoast)) {
                if (currentInstrument.InstrType === InstrumentTypes.FUTURES || currentInstrument.InstrType === InstrumentTypes.CFD_FUTURES || currentInstrument.InstrType === InstrumentTypes.OPTIONS || (currentInstrument.InstrType === InstrumentTypes.SPREADBET && key.includes(SymbolInfoPanel.KEY_TickCoast))) {
                    let tickCoast = currentInstrument.FuturesTickCoast;
                    if (tickCoast < 0) {
                        tickCoast = currentInstrument.LotSize * currentInstrument.PointSize;
                    }
                    if (key.includes(SymbolInfoPanel.KEY_TickCoast)) {
                        const commisionKey = key.split(SymbolInfoPanel.commisionSplitter);
                        if (commisionKey.length === 6) {
                            return SymbolInfoPanel.GetFeeString(commisionKey[1], commisionKey[2],
                                commisionKey[3], SymbolInfoPanel.ConvertToBoolean(commisionKey[4]), SymbolInfoPanel.ConvertToBoolean(commisionKey[5]), FeeStringVariable.Price);
                        }
                        return tickCoast.toString();
                    }
                    // if (key == SymbolInfoPanel.KEY_ContractSize)
                    //     return currentInstrument.ContractSize.toString() + (currentInstrument.ForwardBaseInstrument != null ? (" " + currentInstrument.ForwardBaseInstrument.Exp1) : "");

                    if (key === SymbolInfoPanel.KEY_LotSize && currentInstrument.QuotingType === QuotingType.LotSize) {
                        return currentInstrument.LotSize.toString();
                    }

                    return '';
                } else {
                    let stLotSize = currentInstrument.LotSize.toString();
                    if (currentInstrument.isForexSymbol()) {
                        stLotSize += ' ' + currentInstrument.Exp1;
                    }
                    if (key === SymbolInfoPanel.KEY_LotSize) {
                        return stLotSize;
                    }

                    // х.з. зачем так делать, почему не прислать в мессадже тик кост если нужен
                    // double tickCoast = currentInstrument.LotSize * (double)currentInstrument.PointSize;
                    const tickCoast = currentInstrument.FuturesTickCoast;
                    if (key.includes(SymbolInfoPanel.KEY_TickCoast)) {
                        return tickCoast.toString();
                    }
                }
            }

            if (key === SymbolInfoPanel.KEY_ContractMultiplier) {
                return currentInstrument.ContractMultiplier.toString() + ' ' + currentInstrument.Exp1;
            }

            if (key.includes(SymbolInfoPanel.KEY_TickSize)) {
                const commissionKey = key.split(SymbolInfoPanel.commisionSplitter);
                // имя которое отображается в таблице составное:
                const tickSize = currentInstrument.PointSize;
                if (commissionKey.length === 6) {
                    return SymbolInfoPanel.GetFeeString(
                        commissionKey[1],
                        commissionKey[2],
                        commissionKey[3],
                        commissionKey[4] === 'true',
                        commissionKey[5] === 'true',
                        FeeStringVariable.Price);
                }
                return MathUtils.formatValueWithEps(tickSize);
            }

            if (key === SymbolInfoPanel.KEY_MinimalLot) {
                const minLot = currentInstrument.getMinLot(productType, account);
                return MathUtils.formatValueWithEps(minLot);
            }
            if (key === SymbolInfoPanel.KEY_MaximumLot) {
                const maxLot = currentInstrument.getMaxLot(productType, account);
                if (maxLot > 0 && maxLot !== NumericUtils.MAXVALUE) {
                    return MathUtils.formatValueWithEps(maxLot);
                } else {
                    return Resources.getResource('InstrumentDetailsPanel.None');
                }
            }
            if (key === SymbolInfoPanel.KEY_MaxPositionQty) {
                const currenRiskPlanItem = currentInstrument.RiskSettings.RiskPlanSettings.cache[productType];
                if (currenRiskPlanItem?.MaxPositionQtyPerSymbol != null && currenRiskPlanItem.MaxPositionQtyPerSymbol !== -1) {
                    return currenRiskPlanItem.MaxPositionQtyPerSymbol.toString();
                } else {
                    return Resources.getResource('InstrumentDetailsPanel.None');
                }
            }
            if (key === SymbolInfoPanel.KEY_LotStep) {
                return MathUtils.formatValueWithEps(currentInstrument.getLotStep(productType, account));
            }

            if (key === SymbolInfoPanel.KEY_HiLimit) {
                return currentInstrument.Limits.HighLimitPriceByMeasureString(sett);
            }
            if (key === SymbolInfoPanel.KEY_LowLimit) {
                return currentInstrument.Limits.LowLimitPriceByMeasureString(sett);
            }

            // if (key === SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg)
            //     return currentInstrument.Limits.GetHighLimitFromPriceLimitsMessage()
            // if (key === SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg)
            //     return currentInstrument.Limits.GetLowLimitFromPriceLimitsMessage()

            if (key === SymbolInfoPanel.KEY_ShortSwapInfo) {
                if (!isValidString(currentInstrument.ShortSwapInfo)) {
                    return Resources.getResource('InstrumentDetailsPanel.None');
                } else if (currentInstrument.SwapBuy === 0 && currentInstrument.SwapSell === 0) {
                    return Resources.getResource('InstrumentDetailsPanel.None');
                } else {
                    return currentInstrument.ShortSwapInfo;
                }
            }
            if (key === SymbolInfoPanel.KEY_SwapBuy) {
                if (currentInstrument.SwapBuy !== 0) {
                    return SymbolInfoPanel.getSwapBuySell(currentInstrument.SwapBuy, currentInstrument.SwapType);
                } else {
                    return Resources.getResource('InstrumentDetailsPanel.None');
                }
            }
            if (key === SymbolInfoPanel.KEY_SwapSell) {
                if (currentInstrument.SwapSell !== 0) {
                    return SymbolInfoPanel.getSwapBuySell(currentInstrument.SwapSell, currentInstrument.SwapType);
                } else {
                    return Resources.getResource('InstrumentDetailsPanel.None');
                }
            }
            if (key === SymbolInfoPanel.KEY_AllowShortPositions) {
                return SymbolInfoPanel.getLocalizedAllowShortPosition(currentInstrument.IsAllowShortPositions(productType));
            }
            if (key === SymbolInfoPanel.KEY_AllowOvernightTrading) {
                return SymbolInfoPanel.getLocalizedAllowShortPosition(currentInstrument.RiskSettings.isOvernightTradingsAllowed());
            }
            if (key === SymbolInfoPanel.KEY_NormalMarketSize) {
                return currentInstrument.Level1.getNormalMarketSize;
            }
            if (key === SymbolInfoPanel.KEY_ETB) {
                return currentInstrument.Level1.GetETB();
            }

            if (key.includes(SymbolInfoPanel.KEY_HighLimitWarning)) {
                return SymbolInfoPanel.AddHighLowLimitWarningRowValueByKey(key);
            }
            if (key.includes(SymbolInfoPanel.KEY_LowLimitWarning)) {
                return SymbolInfoPanel.AddHighLowLimitWarningRowValueByKey(key);
            }

            if (key === SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT) {
                return currentInstrument.GetExtTradingUnit();
            }
            if (key === SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT) {
                return currentInstrument.GetExtPriceQuoteUnit();
            }
            if (key === SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT) {
                return currentInstrument.GetExtDeliveryUnit();
            }
            if (key === SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE) {
                return currentInstrument.GetExtLotSize();
            }
            if (key === SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE) {
                return currentInstrument.GetExtTenderPeriodStartDate();
            }
            if (key === SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE) {
                return currentInstrument.GetExtTenderPeriodEndDate();
            }

            if (key.includes(SymbolInfoPanel.KEY_SMFLAG_PREFIX)) // #114474
            {
                return currentInstrument.GetExtSMFlagByKey(key);
            }
            // #endregion

            // #region // 3. Margin reg

            const tierData = SymbolInfoPanel.GetTierNumberFromKey(key);
            const tier = tierData.tier;
            key = tierData.keyWithoutTier;

            const margin = RiskPlan.GetMargin(currentInstrument, productType, tier);
            const overnightMargin = RiskPlan.GetOvernightMargin(currentInstrument, productType, tier);
            const shortMargin = RiskPlan.GetShortMargin(currentInstrument, productType, tier);
            const overnightShortMargin = RiskPlan.GetOvernightShortMargin(currentInstrument, productType, tier);
            // теперь присылает сервер,больше не считаем
            if (key === SymbolInfoPanel.KEY_Margin_ByAccount) {
                return Resources.getResource('InstrumentDetailsPanel.Depend from account');
            }
            // case 1
            if (key === SymbolInfoPanel.KEY_Margin) {
                return SymbolInfoPanel.FormatMargin(margin.Initial, margin.Warning, margin.Maintenance, VerticalPanelDataProvider, productType);
            }
            // case 2
            if (key === SymbolInfoPanel.KEY_MarginDay) {
                return SymbolInfoPanel.FormatMargin(margin.Initial, margin.Warning, margin.Maintenance, VerticalPanelDataProvider, productType);
            }
            if (key === SymbolInfoPanel.KEY_MarginOvernight) {
                return SymbolInfoPanel.FormatMargin(overnightMargin.Initial, overnightMargin.Warning, overnightMargin.Maintenance, VerticalPanelDataProvider, productType, true);
            }
            // case 3
            if (key === SymbolInfoPanel.KEY_MarginBuy) {
                return SymbolInfoPanel.FormatMargin(margin.Initial, margin.Warning, margin.Maintenance, VerticalPanelDataProvider, productType);
            }
            if (key === SymbolInfoPanel.KEY_MarginSell) {
                return SymbolInfoPanel.FormatMargin(shortMargin.Initial, shortMargin.Warning, shortMargin.Maintenance, VerticalPanelDataProvider, productType);
            }
            // case 4
            if (key === SymbolInfoPanel.KEY_MarginOvernightBuy) {
                return SymbolInfoPanel.FormatMargin(overnightMargin.Initial, overnightMargin.Warning, overnightMargin.Maintenance, VerticalPanelDataProvider, productType, true);
            }
            if (key === SymbolInfoPanel.KEY_MarginOvernightSell) {
                return SymbolInfoPanel.FormatMargin(overnightShortMargin.Initial, overnightShortMargin.Warning, overnightShortMargin.Maintenance, VerticalPanelDataProvider, productType, true);
            }
            if (key === SymbolInfoPanel.KEY_MarginDayBuy) {
                return SymbolInfoPanel.FormatMargin(margin.Initial, margin.Warning, margin.Maintenance, VerticalPanelDataProvider, productType);
            }
            if (key === SymbolInfoPanel.KEY_MarginDaySell) {
                return SymbolInfoPanel.FormatMargin(shortMargin.Initial, shortMargin.Warning, shortMargin.Maintenance, VerticalPanelDataProvider, productType);
            }
            if (key === SymbolInfoPanel.KEY_MarginInAccountCurrency) {
                // let res = '';
                // if (DataCache?.LoginUser?.Account != null) {
                //     res = DataCache.LoginUser.Account.BaseCurrency;
                // }

                // return currentInstrument.RiskSettings.GetInitMargin(productType).toString() + ' ' + res;

                return '';
            }

            if (key === SymbolInfoPanel.KEY_Leverage) {
                return OrderUtils.GetFormattedLeverage(margin.Leverage);
            }

            // #endregion

            // #region // 4. Fees
            const feeCurrency = CommissionItem.GetCommissionCurrency(account, currentInstrument);
            const comResult = CommissionPlan.TryGetCommissionDataValue(feeCurrency, key, currentInstrument, account);
            if (isValidString(comResult)) {
                return comResult ?? '';
            }

            if (key === SymbolInfoPanel.KEY_CustodialFee) {
                const data = currentInstrument.DataCache.CustodialPlans.GetCustodialDataByInstrument(account.CustodialPlanId, currentInstrument);
                return !isNullOrUndefined(data) ? data.FormattedValue() : '';
            }

            if (key === SymbolInfoPanel.KEY_ShortPositionInterest) {
                const interest = currentInstrument.ShortPositionInterest;
                return isValidNumber(interest) && interest !== 0
                    ? interest + ' %'
                    : Resources.getResource('InstrumentDetailsPanel.None');
            }

            if (key === SymbolInfoPanel.KEY_Historical_LongSwap) {
                const value = SymbolInfoPanel.getHistoricalSwap(currentInstrument, SymbolInfoPanel.KEY_Historical_LongSwap_shortKey);
                return value
                    ? value + ' ' + Resources.getResource('InstrumentDetailsPanel.HistoricalSwap.points')
                    : Resources.getResource('InstrumentDetailsPanel.None');
            }

            if (key === SymbolInfoPanel.KEY_Historical_ShortSwap) {
                const value = SymbolInfoPanel.getHistoricalSwap(currentInstrument, SymbolInfoPanel.KEY_Historical_ShortSwap_shortKey);
                return value
                    ? value + ' ' + Resources.getResource('InstrumentDetailsPanel.HistoricalSwap.points')
                    : Resources.getResource('InstrumentDetailsPanel.None');
            }

            if (key === SymbolInfoPanel.KEY_Historical_LastSwap) {
                const value = SymbolInfoPanel.getHistoricalSwap(currentInstrument, SymbolInfoPanel.KEY_Historical_LastSwap_shortKey);
                return value || Resources.getResource('InstrumentDetailsPanel.None');
            }

            // #endregion

            // #region // 5. Custom additional info
            if (currentInstrument.InstrumentAdditionalInfo != null) {
                const item = currentInstrument.InstrumentAdditionalInfo[key];
                if (!isNullOrUndefined(item)) {
                    return item.Value.toString();
                }
            }
            // #endregion

            // 6. Session info
            return InstrumentFeatureHelper.TradeSessionValue(key, VerticalPanelDataProvider.Source, true, false);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            // Utils.log(ex);
            return '';
        }
    }

    private static getDeliveryStatus (status: number): string {
        switch (status) {
        case 0:
            return Resources.getResource('InstrumentDetailsPanel.WaitForPrice');
        case 1:
            return Resources.getResource('InstrumentDetailsPanel.Delivered');
        case 2:
            return Resources.getResource('InstrumentDetailsPanel.Ready');
        }
        return '';
    }

    public static FormatMargin (init: number, warn: number, maint: number, VerticalPanelDataProvider: VerticalPanelDataProvider, productType: ProductType, isOvernight = false): string {
        const isShowAllKoef = init !== maint || warn !== maint;
        const currentInstrument: Instrument = VerticalPanelDataProvider.Source;

        if (currentInstrument === null) {
            return SymbolInfoPanel.FormatMargin_5ARG(init, warn, maint, '', isShowAllKoef);
        }

        const exp2 = currentInstrument.Exp2;
        const marginType = RiskPlan.GetCalculateMarginTypeNullable(currentInstrument, productType);

        if (marginType === MarginTypes.MARGIN_FIXED) {
            return SymbolInfoPanel.FormatMargin_5ARG(init, warn, maint, exp2, isShowAllKoef);
        }

        // instrument CCY

        if (marginType === MarginTypes.MARGIN_FULL || marginType === MarginTypes.MARGIN_STOCK) {
            return '100 %';
        }

        if (marginType === MarginTypes.MARGIN_PRICE_LIMIT) // Limit based.
        {
        // #28806   MD: futures margin coeficients
        // Alex Khomenko: при типе маржи MARGIN_SETLEMENT / 7 /  инит маржин должна браться не из объекта маржи, а из лимитов, тоесть нужно складывать значения полей 400 399 (*Андрей*)

            init = currentInstrument.Limits.HightLimit + currentInstrument.Limits.LowLimit;
            // #26758 Symbol info и тип маржи Limit based.
            // сервер: значение константы FIELD_PRICE_LIMIT_MESURE:  byte PERCENTEGE = 0; byte OFFSET = 1; byte DISABLE = -1;

            let lotSize = currentInstrument.LotSize;
            if (currentInstrument.isFutureOrOption()) {
                lotSize = currentInstrument.FuturesTickCoast / currentInstrument.PointSize;
            }

            if (currentInstrument.Limits.PriceLimitMeasure === PriceLimitMeasure.PERCENTAGE_SETTL_PRICE) {
                const k = lotSize * currentInstrument.PrevSettlementPrice * 0.01;
                return SymbolInfoPanel.FormatMargin_5ARG(init * k, warn * k, init * /* Возможно это опечатка */maint * k, exp2, isShowAllKoef);
            }

            if (currentInstrument.Limits.PriceLimitMeasure === PriceLimitMeasure.OFFSET_SETTL_PRICE) {
                const k = lotSize * currentInstrument.PointSize;
                return SymbolInfoPanel.FormatMargin_5ARG(init * k, warn * k, maint * k, exp2, isShowAllKoef);
            }

            return Resources.getResource('InstrumentDetailsPanel.None');
        }

        if (marginType === MarginTypes.MARGIN_PT_RISK_MODULE) {
            return Resources.getResource('InstrumentDetailsPanel.PTRM');
        }

        if (marginType === MarginTypes.MARGIN_NSE_VAR) {
            if (isOvernight && RiskPlan.GetUseVarOnlyIntraday(currentInstrument, productType)) {
                return SymbolInfoPanel.FormatMargin_3ARG(init, warn, maint);
            }

            const varKoef = RiskPlan.GetVarCoeffNullable(currentInstrument, productType);

            if (RiskPlan.GetVarCoeffApplyingNullable(currentInstrument, productType) === 0) {
                return SymbolInfoPanel.FormatMargin_3ARG(varKoef * init, varKoef * warn, varKoef * maint);
            }

            return SymbolInfoPanel.FormatMargin_3ARG(Math.max(init, varKoef), Math.max(warn, varKoef), Math.max(maint, varKoef));
        }

        if (marginType === MarginTypes.MARGIN_NONE) {
            return '-';
        }

        if (marginType === MarginTypes.LONG_OPTIONS_FULLY_PAID_MARGIN) {
            return currentInstrument.isOptionSymbol ? SymbolInfoPanel.FormatMarginPercentage_3ARG(1, 0, 0) : SymbolInfoPanel.FormatMarginPercentage_3ARG(0, 0, 0);
        }

        if (marginType === MarginTypes.MARGIN_CUSTOM_FIXED) {
            const currencyStr = RiskPlan.GetCustomCurrencyStr(currentInstrument, productType);
            return SymbolInfoPanel.FormatMargin_5ARG(init, warn, maint, currencyStr, isShowAllKoef);
        }

        const precisions = [MathUtils.getPrecision(init) - 2, MathUtils.getPrecision(warn) - 2, MathUtils.getPrecision(maint) - 2]; //  95646 запоминаем исходную точность после умножения на 100 (= -2 знака ), чтобы точность не менялась в процессе арифметической операции (JS 1 <3)
        return SymbolInfoPanel.FormatMargin_5ARG(init * 100, warn * 100, maint * 100, '%', isShowAllKoef, precisions);
    }

    public static FormatMargin_5ARG (init: number, warn: number, maint: number, sign: string, isShowAllKoef: boolean, precisions?: any[]): string {
        precisions = precisions ?? [];

        const initFormat = MathUtils.TruncateDouble(init, precisions[0] ?? 2);
        const warnFormat = MathUtils.TruncateDouble(warn, precisions[1] ?? 2);
        const maintFormat = MathUtils.TruncateDouble(maint, precisions[2] ?? 2);
        if (isShowAllKoef) {
            if (isValidString(sign)) {
                return initFormat + ' ' + sign + ' / ' + warnFormat + ' ' + sign + ' / ' + maintFormat + ' ' + sign;
            } else {
                return initFormat + '/' + warnFormat + '/' + maintFormat;
            }
        }
        return isValidString(sign) ? initFormat + ' ' + sign : initFormat.toString();
    }

    public static FormatMargin_3ARG (init: number, warn: number, maint: number): string {
        return MathUtils.TruncateDouble(init, 2) + '% / ' + MathUtils.TruncateDouble(warn, 2) + '% / ' + MathUtils.TruncateDouble(maint, 2) + '%';
    }

    public static FormatMarginPercentage_3ARG (init: number, warn: number, maint: number): string {
        if (init !== maint || warn !== maint) {
            return MathUtils.TruncateDouble(init * 100, 2) + '% / ' + MathUtils.TruncateDouble(warn * 100, 2) + '% / ' + MathUtils.TruncateDouble(maint * 100, 2) + '%';
        }

        return MathUtils.TruncateDouble(init * 100, 2) + '%';
    }

    public static GetItemsID (VerticalPanelDataProvider: VerticalPanelDataProvider): string[] {
        const sip = SymbolInfoPanel;
        let currentItems = null;
        const currentInstrument: Instrument = VerticalPanelDataProvider.Source;

        if (currentInstrument.InstrType === InstrumentTypes.FUTURES ||
        currentInstrument.InstrType === InstrumentTypes.CFD_FUTURES ||
        currentInstrument.InstrType === InstrumentTypes.FORWARD) {
        // Futures.
            if (currentInstrument.InstrType === InstrumentTypes.FUTURES ||
            currentInstrument.InstrType === InstrumentTypes.CFD_FUTURES) {
                currentItems =
                [
                    sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                    sip.KEY_AssetClass, sip.KEY_Underlier, sip.KEY_DeliveryMethod,
                    sip.KEY_ContactMonth, sip.KEY_FirstTradeDate, sip.KEY_LastTradeDate,
                    sip.KEY_NoticeDate, sip.KEY_SettlementDate, sip.KEY_TradingStatus,
                    sip.KEY_AllowedOperations, sip.KEY_AllowedOrderTypes, sip.KEY_ProductType,
                    sip.KEY_DeliveryStatus, sip.KEY_CurrentSession, sip.KEY_SessionStatus, sip.KEY_NextHoliday,
                    sip.KEY_QuotiongCurrency, /* sip.KEY_ContractSize, */ sip.KEY_LotStep,
                    sip.KEY_HiLimit, sip.KEY_LowLimit,
                    // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                    sip.KEY_TradingBalance,
                    sip.KEY_TradindBlockedOnSession, sip.KEY_NormalMarketSize
                ];
            }
            // Forward.
            else {
                currentItems =
                [
                    sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                    sip.KEY_AssetClass, sip.KEY_DeliveryMethod, sip.KEY_MaturityDate,
                    sip.KEY_LastTradeDate, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                    sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_CurrentSession, sip.KEY_SessionStatus,
                    sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency, // sip.KEY_ContractSize,
                    sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                    // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                    sip.KEY_TradingBalance, sip.KEY_TradindBlockedOnSession, sip.KEY_NormalMarketSize
                ];
            }
            if (isValidString(currentInstrument.ISIN)) {
                currentItems.push(sip.KEY_ISIN);
            }

            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems);

            SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider);
        } else if (currentInstrument.InstrType === InstrumentTypes.OPTIONS) {
        // Options.
            currentItems =
            [
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_Underlier, sip.KEY_ContactMonth,
                sip.KEY_FirstTradeDate, sip.KEY_LastTradeDate, sip.KEY_SettlementDate,
                sip.KEY_StrikePrice, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_DeliveryStatus,
                sip.KEY_CurrentSession, sip.KEY_SessionStatus, sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency,
                // sip.KEY_ContractSize,
                sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize,
                sip.KEY_ExerciseStyle
            ];
            if (isValidString(currentInstrument.ISIN)) {
                currentItems.push(sip.KEY_ISIN);
            }

            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems);

            SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider);
        } else if (currentInstrument.InstrType === InstrumentTypes.BOND) {
        // для бондов пока используем поля, отображаемые для акций + специфичные для бондов
            currentItems =
            [
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_CurrentSession, sip.KEY_SessionStatus,
                sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency, sip.KEY_LotSize,
                sip.KEY_ContractMultiplier, sip.KEY_LotStep,
                sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_SettlementDate,
                sip.KEY_MaturityDate, sip.KEY_FaceValue, sip.KEY_CouponCycle,
                sip.KEY_CouponRate, sip.KEY_YTM, sip.KEY_ACCRUED_INTEREST,
                sip.KEY_PREV_PAYMENT_DATE, sip.KEY_NEXT_PAYMENT_DATE, sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize, sip.KEY_DAYS_PER_MONTH, sip.KEY_DAYS_PER_YEAR
            ];
            if (isValidString(currentInstrument.ISIN)) {
                currentItems.push(sip.KEY_ISIN);
            }

            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems);

            SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider);
        } else if (currentInstrument.InstrType === InstrumentTypes.TBILL) {
            currentItems =
            [
                // для TBILL пока используем поля, отображаемые для акций
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_CurrentSession, sip.KEY_SessionStatus,
                sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency, sip.KEY_LotSize,
                sip.KEY_ContractMultiplier, sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_SettlementDate,
                // специфичные для TBill
                sip.KEY_MaturityDate, sip.KEY_FaceValue, sip.KEY_Yield,
                sip.KEY_TradindBlockedOnSession, sip.KEY_NormalMarketSize
            ];
            if (isValidString(currentInstrument.ISIN)) {
                currentItems.push(sip.KEY_ISIN);
            }

            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems);

            SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider);
        } else if (currentInstrument.InstrType === InstrumentTypes.SPOT) {
            currentItems =
            [
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_DeliveryMethod, sip.KEY_TradingStatus,
                sip.KEY_AllowedOperations, sip.KEY_AllowedOrderTypes, sip.KEY_ProductType,
                sip.KEY_TradingBalance, sip.KEY_MaturityDate, sip.KEY_DeliveryStatus,
                sip.KEY_CurrentSession, sip.KEY_SessionStatus, sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency,
                // sip.KEY_ContractSize,
                sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize
            ];
            if (isValidString(currentInstrument.ISIN)) {
                currentItems.push(sip.KEY_ISIN);
            }

            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems);

            SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider);
        } else if (currentInstrument.InstrType === InstrumentTypes.CORPORATE) {
            currentItems = [
                sip.KEY_Symbol,
                sip.KEY_Description,
                sip.KEY_ExchangeMarketData,
                sip.KEY_ExchangeTrading,
                sip.KEY_AssetClass,
                sip.KEY_TradingStatus,
                sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes,
                sip.KEY_ProductType,
                sip.KEY_CurrentSession,
                sip.KEY_SessionStatus,
                sip.KEY_NextHoliday,
                sip.KEY_QuotiongCurrency,
                sip.KEY_ContractMultiplier,
                sip.KEY_LotStep,
                sip.KEY_HiLimit,
                sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg,
                // sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance,
                sip.KEY_SettlementDate,
                sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize
            // sip.KEY_Exp1
            ];

            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems);

            SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider);
            if (isValidString(currentInstrument.ISIN)) {
                currentItems.push(sip.KEY_ISIN);
            }

            if (!isNullOrUndefined(currentInstrument.MaturityDate)) {
                currentItems.push(sip.KEY_MaturityDate);
            }
        } else {
        // "Forex-CFD-Equitis-Stock-Forward-Index-ETF-N_A";
            currentItems =
            [
                sip.KEY_Symbol, sip.KEY_Description, sip.KEY_ExchangeTrading, sip.KEY_ExchangeMarketData,
                sip.KEY_AssetClass, sip.KEY_TradingStatus, sip.KEY_AllowedOperations,
                sip.KEY_AllowedOrderTypes, sip.KEY_ProductType, sip.KEY_CurrentSession, sip.KEY_SessionStatus,
                sip.KEY_NextHoliday, sip.KEY_QuotiongCurrency, sip.KEY_ContractMultiplier,
                sip.KEY_LotStep, sip.KEY_HiLimit, sip.KEY_LowLimit,
                // sip.KEY_HiLimitFromPriceLimitsMsg, sip.KEY_LowLimitFromPriceLimitsMsg,
                sip.KEY_TradingBalance, sip.KEY_SettlementDate, sip.KEY_TradindBlockedOnSession,
                sip.KEY_NormalMarketSize
            ];

            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MinimalLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaximumLot, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_MaxPositionQty, currentInstrument, currentItems);
            SymbolInfoPanel.ProcessItemByProductType(sip.KEY_AllowShortPositions, currentInstrument, currentItems);

            if (currentInstrument.InstrType === InstrumentTypes.SPREADBET) {
                const index = currentItems.indexOf(sip.KEY_LotSize);
                if (index !== -1) {
                    currentItems.splice(index, 1, sip.KEY_TickCoast);
                }
            }

            if (currentInstrument.InstrType === InstrumentTypes.INDICIES) {
                const tradingBalanceIdx = currentItems.indexOf(sip.KEY_TradingBalance);
                if (tradingBalanceIdx !== -1) {
                    currentItems.splice(tradingBalanceIdx, 1);
                }

                const lotSizeIdx = currentItems.indexOf(sip.KEY_LotSize);
                if (lotSizeIdx !== -1) {
                    currentItems.splice(lotSizeIdx, 1);
                }

                const tradingBlockedOnSessionIdx = currentItems.indexOf(sip.KEY_TradindBlockedOnSession);
                if (tradingBlockedOnSessionIdx !== -1) {
                    currentItems.splice(tradingBlockedOnSessionIdx, 1);
                }
            }

            // #59361 В раздел General добавляем поле Asset для инструментов
            // с типами Equities/Equities CFD выводим значение из настройки Asset на инструменте.
            // ETF +
            if ((currentInstrument.InstrType === InstrumentTypes.EQUITIES || currentInstrument.InstrType === InstrumentTypes.ETF) &&
            !currentInstrument.HideAsset &&
            currentInstrument.Exp1 !== currentInstrument.ShortName) {
                currentItems.push(sip.KEY_Exp1);
            }

            SymbolInfoPanel.AddTickSizeCostItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddTradeSessionItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddMaintenanceItems(currentItems, VerticalPanelDataProvider);
            SymbolInfoPanel.AddFeesItems(currentItems, VerticalPanelDataProvider);

            if (currentInstrument.InstrType === InstrumentTypes.INDICIES) {
                let idx = currentItems.indexOf(sip.KEY_TradingBalance);
                if (idx !== -1) {
                    currentItems.splice(idx, 1);
                }

                idx = currentItems.indexOf(sip.KEY_LotSize);
                if (idx !== -1) {
                    currentItems.splice(idx, 1);
                }
            }
            if (isValidString(currentInstrument.ISIN)) {
                currentItems.push(sip.KEY_ISIN);
            }
        }

        SymbolInfoPanel.AddHighLowLimitWarning(currentItems, currentInstrument, true); // https://tp.traderevolution.com/entity/108864

        if (isValidString(currentInstrument.Industry) || isValidString(currentInstrument.ForwardBaseInstrument?.Industry)) {
            currentItems.push(sip.KEY_Industry);
        }

        if (isValidString(currentInstrument.Sector) || isValidString(currentInstrument.ForwardBaseInstrument?.Sector)) {
            currentItems.push(sip.KEY_Sector);
        }

        if (currentInstrument.RiskSettings.isOvernightTradingsAllowed() !== null) {
            currentItems.push(sip.KEY_AllowOvernightTrading);
        }

        // custom instrument information
        const insAddInfo = currentInstrument.InstrumentAdditionalInfo;
        for (const key in insAddInfo) {
            currentItems.push(insAddInfo[key].NameKey);
        }

        if (currentInstrument.Level1.GetETB() !== Level1Calculator.NAString) {
            currentItems.push(SymbolInfoPanel.KEY_ETB);
        }

        if (isValidString(currentInstrument.GetExtTradingUnit())) {
            currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_TRADINGUNIT);
        }

        if (isValidString(currentInstrument.GetExtPriceQuoteUnit())) {
            currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_PRICEQUOTEUNIT);
        }

        if (isValidString(currentInstrument.GetExtDeliveryUnit())) {
            currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_DELIVERYUNIT);
        }

        if (isValidString(currentInstrument.GetExtLotSize())) {
            currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_LOTSIZE);
        }

        if (isValidString(currentInstrument.GetExtTenderPeriodStartDate())) {
            currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODSTARTDATE);
        }

        if (isValidString(currentInstrument.GetExtTenderPeriodEndDate())) {
            currentItems.push(SymbolInfoPanel.KEY_EXTFIELD_TENDERPERIODENDDATE);
        }

        const SMFlags = currentInstrument.GetExtSMFlags(); // #114474
        for (const flag in SMFlags) {
            currentItems.push(SymbolInfoPanel.KEY_SMFLAG_PREFIX + flag);
        }

        return SymbolInfoPanel.filterByInstrumentTradableItemsID(
            currentItems,
            VerticalPanelDataProvider);
    }

    public GetDynamicItems (VerticalPanelDataProvider: VerticalPanelDataProvider): VerticalPanelDataProviderItem[] {
        return SymbolInfoPanel.GetDynamicItemsStaticFull(VerticalPanelDataProvider, this.sessionTypesItem, this.marginTypesItem, this.feeTypesItem, this.tickSizeCostTypesItem, this.rebateTypesItem, this.dynamicTypesItem);
    }

    /// <summary>
    /// описание айтемов которые добавляются динамически, просится при смене инструмента
    /// </summary>
    /// <returns>добавляются к getDataItems - которое просится 1 раз при прогрузке панели</returns>
    public static GetDynamicItemsStaticFull (VerticalPanelDataProvider: VerticalPanelDataProvider,
        sessionTypesItem,
        marginTypesItem: Record<string, VerticalPanelDataProviderItem>,
        feeTypesItem,
        tickSizeCostTypesItem: Record<string, VerticalPanelDataProviderItem>,
        rebateTypesItem,
        dynamicTypesItem: Record<string, VerticalPanelDataProviderItem>): VerticalPanelDataProviderItem[] {
    // здесь возвращаем ВСЕ которые будут отображаться
        let res: VerticalPanelDataProviderItem[] = [];

        const ins: Instrument = VerticalPanelDataProvider.Source;
        if (isNullOrUndefined(ins)) return null;

        let exFeeTypeName = null;
        let displayFeeTypeName = null;

        SymbolInfoPanel.AddHighLowLimitWarning(res, ins); // https://tp.traderevolution.com/entity/108864

        // #region tickCostSizeItems

        // добавляем описание на те которые будут отображаться
        const tickCostSizeItems = [];
        SymbolInfoPanel.AddTickSizeCostItems(tickCostSizeItems, VerticalPanelDataProvider);

        for (let i = 0, len = tickCostSizeItems.length; i < len; i++) {
            const tickCostSizeItem = tickCostSizeItems[i];
            const key = tickCostSizeItem.split(SymbolInfoPanel.commisionSplitterArr)[0];
            // имя которое отображается в таблице составное:
            const locKey = displayFeeTypeName = key;
            // если у предыдущей строки имя такое же не показываем
            if (exFeeTypeName === locKey) {
                displayFeeTypeName = '';
            }

            const item = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_tradingInfo, i + 150, displayFeeTypeName, tickCostSizeItem);
            // copy properties from feeTypesItem
            if (!isNullOrUndefined(tickSizeCostTypesItem)) {
                const tickCostSizePropItem = tickSizeCostTypesItem[locKey];
                if (!isNullOrUndefined(tickCostSizePropItem)) {
                    item.Apply(tickCostSizePropItem);
                }
            }
            res.push(item);
            exFeeTypeName = locKey;
        }

        // #endregion tickCostSizeItems

        // #region margin
        const createItem = (localizationKey, id, sortIndex): VerticalPanelDataProviderItem => {
            return SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_marginReg, sortIndex + 70, localizationKey, id);
        };

        const tierLocalized = Resources.getResource('InstrumentDetailsPanel.Tier') + ' ';
        for (let i = 0; i < SymbolInfoPanel.margins.length; i++) {
            const staticMI = SymbolInfoPanel.margins[i];

            if (SymbolInfoPanel.IsMarginRequirements(staticMI)) {
                const ids = SymbolInfoPanel.GetMarginRequirementsItemsFromKey(ins, staticMI);
                const productType = SymbolInfoPanel.GetProductTypeFromMarginKey(staticMI);
                const isTierMargin = RiskPlan.IsTierMargin(ins, productType);
                const isLeverage = SymbolInfoPanel.IsLeverageKey(staticMI);
                const locKey = isTierMargin ? ' ' + Resources.getResource(staticMI) : (isLeverage ? SymbolInfoPanel.GetLeverageKeyResource(ins, staticMI) : staticMI);
                const needSkip = SymbolInfoPanel.NeedSkipEmptyMargin(ins, productType, locKey); // #113777

                for (let j = 0; j < ids.length; j++) {
                    const id = needSkip ? '' : ids[j];
                    let locKeyFinally = locKey;
                    if (isLeverage) {
                        locKeyFinally = locKey + ' ' + (ids.length > 1 ? (j + 1) : '');
                    } else
                        if (isTierMargin) {
                            locKeyFinally = tierLocalized + (j + 1) + locKey;
                        }

                    res.push(createItem(locKeyFinally, id, i)); // ids[j] ай-ди = ключ локализации + productType + tier#
                }
            } else {
                const item = createItem(staticMI, staticMI, i); // staticMI - ай-ди - ключ локализации

                // copy properties from marginTypesItem
                if (!isNullOrUndefined(marginTypesItem?.[staticMI])) {
                    item.Apply(marginTypesItem[staticMI]);
                }
                res.push(item);
            }
        }
        // #endregion

        // #region fees
        const itemsForFeesIds = [];
        SymbolInfoPanel.AddFeesItems(itemsForFeesIds, VerticalPanelDataProvider);
        exFeeTypeName = null;
        displayFeeTypeName = null;

        for (let i = 0; i < itemsForFeesIds.length; i++) {
            const key = itemsForFeesIds[i].split(SymbolInfoPanel.commisionSplitterArr);
            const allS = CommissionOperationType.ALL;
            const suff = CommissionItem.OperationTypeSuffix;
            const locKey = displayFeeTypeName = (key.length === 4 && key[1] != allS) ? key[0] + suff[key[1]] : key[0]; // имя которое отображается в таблице составное: имя + CommissionOperationType, если тип ALL то скипаем вторую часть, иначе добавляем

            if (exFeeTypeName == locKey) {
                displayFeeTypeName = '';
            } // если у предыдущей строки имя такое же не показываем

            const item = SymbolInfoPanel.createItem(SymbolInfoPanel.KEY_fees, i + 90, displayFeeTypeName, itemsForFeesIds[i]);
            res.push(item);

            exFeeTypeName = locKey;
        }
        // #endregion

        // #region Sessions

        const dayPeriods = ins.Session.GetTodayDayPeriods();
        for (let i = 0, len = dayPeriods.length; i < len; i++) {
            const dayPeriod = dayPeriods[i];
            const sessTypeName = SymbolInfoPanel.GetTradeSessionTypeName(dayPeriod);

            const item = new VerticalPanelDataProviderItem();
            item.Group = SymbolInfoPanel.KEY_sessionInfo;
            item.SortIndex = SymbolInfoPanel.GetSessionSortIndex(dayPeriod);
            // андрюшин бред, что выводится имя один раз - hsa - уже такого нет
            item.LocalizationKey = InstrumentFeatureHelper.TradeSessionValue(i.toString() + '.' + sessTypeName, VerticalPanelDataProvider.Source, false, true);
            item.Id = i.toString() + '.' + sessTypeName; // ай-ди сессии
            item.tooltipKey = Resources.GetToolTipKey(sessTypeName);

            // copy properties from session group item
            if (!isNullOrUndefined(sessionTypesItem?.[sessTypeName])) {
                item.Apply(sessionTypesItem[sessTypeName]);
            }

            res.push(item);
        }

        // #endregion Sessions

        // #region Custom information

        const customRes = [];
        for (const instrumentAdditionalInfoID in ins.InstrumentAdditionalInfo) {
            const instrumentAdditionalInfoItem = ins.InstrumentAdditionalInfo[instrumentAdditionalInfoID];
            const item = new VerticalPanelDataProviderItem();
            item.Group = instrumentAdditionalInfoItem.GroupInfo;
            item.SortIndex = instrumentAdditionalInfoItem.SortIndex; // sort index inside group
            item.LocalizationKey = instrumentAdditionalInfoItem.NameKey;
            item.Id = instrumentAdditionalInfoItem.NameKey;
            item.tooltipKey = instrumentAdditionalInfoItem.ToolTipKey;
            item.visible = instrumentAdditionalInfoItem.Visible;
            let dynamicItem: VerticalPanelDataProviderItem | null = null;
            if (dynamicTypesItem != null) {
                dynamicItem = dynamicTypesItem[instrumentAdditionalInfoItem.NameKey];
                if (!isNullOrUndefined(dynamicItem)) {
                    item.Apply(dynamicItem);
                }
                dynamicTypesItem[instrumentAdditionalInfoItem.NameKey] = item;
            }
            customRes.push(item);
        }
        res = res.concat(customRes);

        // #endregion Custom information

        return res;
    }

    public static GetTierNumberFromKey (key: string): { tier: number | null, keyWithoutTier: string } {
        let tier: number | null = null;

        const keyParts = key.split(SymbolInfoPanel.MARGIN_TIER_SEPARATOR);
        if (keyParts.length > 1) {
            tier = parseInt(keyParts[1]);
        }

        key = keyParts[0]; // tier из ключа убираем

        return {
            tier,
            keyWithoutTier: key
        };
    }

    public static IsMarginRequirements (key: string): boolean {
        const marginFields =
        [SymbolInfoPanel.KEY_Margin,
            SymbolInfoPanel.KEY_MarginIntraday,
            SymbolInfoPanel.KEY_MarginDelivery,
            SymbolInfoPanel.KEY_Leverage,
            SymbolInfoPanel.KEY_LeverageIntraday,
            SymbolInfoPanel.KEY_LeverageDelivery,
            SymbolInfoPanel.KEY_MarginDay,
            SymbolInfoPanel.KEY_MarginOvernight];

        return isValidString(key) && marginFields.includes(key);
    }

    public static GetSessionSortIndex (dayPeriod: DayPeriods): number {
        switch (dayPeriod) {
        case DayPeriods.PRE_OPEN:
            return 200;
        case DayPeriods.MAIN:
            return 300;
        case DayPeriods.POST_CLOSE:
            return 400;
        case DayPeriods.BEFORE_MARKET:
            return 100;
        case DayPeriods.AFTER_MARKET:
            return 500;
        default:
            return -1;
        }
    }

    /// <summary>
    /// на смену Source отписка и пр.
    /// </summary>
    public static DisposeSource (VerticalPanelDataProvider): void {
    // отписка на смену и прочее. // пока не надо
    }

    /// определяем какие айтемы отображать (для айтемов, количество которых зависит от ProductType)
    public static ProcessItemByProductType (key: string, instrument: Instrument, currentItems): void {
        if (isNullOrUndefined(instrument) || instrument.RiskSettings.RiskPlanSettings.availableProductTypes.length <= 1) {
            currentItems.push(key);
        } else {
            const aPT = instrument.RiskSettings.RiskPlanSettings.availableProductTypes;
            for (let i = 0; i < aPT.length; i++) {
                let newKey = key;
                const suffix = SymbolInfoPanel.GetProductTypeSuffix(aPT[i]);
                if (isValidString(suffix)) {
                    newKey = key + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + suffix;
                }
                currentItems.push(newKey);
            }
        }
    }

    public static getLocalizedAllowShortPosition (isAllowed: boolean): string {
        return Resources.getResource(isAllowed
            ? 'InstrumentDetailsPanel.Allow short positions'
            : 'InstrumentDetailsPanel.Not allow short positions');
    }

    public static GetProductTypeSuffix (type: ProductType): string {
        const productTypeKeys = Enum.TakeKeysFromEnum(ProductType);
        for (const key of productTypeKeys) {
            if (ProductType[key] === type) {
                return key;
            }
        }
    }

    /// <summary>
    /// Adds keys for TickSizeCost Items, которые показываются СЕЙЧАС
    /// </summary>
    public static AddTickSizeCostItems (itemsForTable: string[], VerticalPanelDataProvider: VerticalPanelDataProvider): void {
        const instr: Instrument = VerticalPanelDataProvider.Source;
        if (instr.VariableTickList == null || instr.VariableTickList.length <= 1) {
            if (instr.QuotingType === QuotingType.TickCost_TickSize) {
                itemsForTable.push(SymbolInfoPanel.KEY_TickCoast);
            }
            itemsForTable.push(SymbolInfoPanel.KEY_TickSize);
        } else {
            if (instr.QuotingType !== QuotingType.LotSize) {
                SymbolInfoPanel.PrepareTickSizeCostItems(itemsForTable, instr, SymbolInfoPanel.KEY_TickCoast);
            }
            SymbolInfoPanel.PrepareTickSizeCostItems(itemsForTable, instr, SymbolInfoPanel.KEY_TickSize);
        }
        if (instr.QuotingType === QuotingType.LotSize) {
            itemsForTable.push(SymbolInfoPanel.KEY_LotSize);
        }
    }

    /// <summary>
    /// Adds keys for sessions, которые показываются СЕЙЧАС
    /// </summary>
    public static AddTradeSessionItems (itemsForTable: string[], VerticalPanelDataProvider: VerticalPanelDataProvider): void {
    // здесь возвращаем только те что СЕЙЧАС отображаем
        const currentInstrument: Instrument = VerticalPanelDataProvider.Source;

        const dayPeriods = currentInstrument.Session.GetTodayDayPeriods();

        for (let i = 0; i < dayPeriods.length; i++) {
            const dayPeriod = dayPeriods[i];
            const descr = SymbolInfoPanel.GetTradeSessionTypeName(dayPeriod);

            itemsForTable.push(i.toString() + '.' + descr); // значение
        }
    }

    /// <summary>
    /// Adds keys for Maintenance Items, которые показываются СЕЙЧАС
    /// </summary>
    public static AddMaintenanceItems (itemsForTable: string[], VerticalPanelDataProvider: VerticalPanelDataProvider): void {
    // здесь возвращаем только те что СЕЙЧАС отображаем

        const currentInstrument: Instrument = VerticalPanelDataProvider.Source;
        RiskPlan.GetAvailableProductTypes(currentInstrument).forEach(productType => {
            SymbolInfoPanel.AddMaintenanceItems_(itemsForTable, currentInstrument, productType);
        });
    // for (let type in RiskPlan.GetAvailableProductTypes(currentInstrument))
    //     SymbolInfoPanel.AddMaintenanceItems_(itemsForTable, currentInstrument, type);
    }

    public static GetLeverageItemsFromProductType (instrument: Instrument, productType: ProductType): string[] {
        const items: string[] = [];
        const itemsN = SymbolInfoPanel.GetLeverageLengthFromProductType(instrument, productType);

        for (let i = 0; i < itemsN; i++) {
            items.push(SymbolInfoPanel.KEY_Leverage + SymbolInfoPanel.MARGIN_TIER_SEPARATOR + i);
        }

        return items;
    }

    public static GetLeverageLengthFromProductType (instrument: Instrument, productType: ProductType): number {
        const isHedgingNettingType = instrument.isHedgingNettingType();
        const leverages = RiskPlan.GetLeverages(instrument, productType);
        const itemsN = isValidArray(leverages) ? (isHedgingNettingType ? leverages.length : 1) : 0;

        return itemsN;
    }

    public static GetLeverageKeyResource (instrument: Instrument, key: string): string {
        const onlyOne = RiskPlan.AvailableOnlyOneProductType(instrument);
        const keyFinally = onlyOne ? SymbolInfoPanel.KEY_Leverage : key;

        return Resources.getResource(keyFinally);
    }

    public static GetMarginRequirementsItemsFromProductType (key: string, instrument: Instrument, productType: ProductType): string[] {
        const items: string[] = [];
        const itemsN = RiskPlan.GetMarginCoeficientListLength(instrument, productType);

        for (let i = 0; i < itemsN; i++) {
            items.push(key + SymbolInfoPanel.MARGIN_TIER_SEPARATOR + i);
        }

        return items;
    }

    public static GetMarginRequirementsItemsFromKey (instrument: Instrument, key: string): string[] {
        const productType = SymbolInfoPanel.GetProductTypeFromMarginKey(key);

        if (!RiskPlan.IsProductTypeAvailableForInstrument(instrument, productType)) {
            return [];
        }

        let productTypeSuffix = productType !== ProductType.General ? (SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + (key === SymbolInfoPanel.KEY_MarginIntraday ? SymbolInfoPanel.PRODUCT_TYPE_INTRADAY : SymbolInfoPanel.PRODUCT_TYPE_DELIVERY)) : '';
        const items: string[] = [];
        let itemsN = RiskPlan.GetMarginCoeficientListLength(instrument, productType);

        if (SymbolInfoPanel.IsLeverageKey(key)) {
            key = SymbolInfoPanel.KEY_Leverage;
            itemsN = SymbolInfoPanel.GetLeverageLengthFromProductType(instrument, productType);

            if (RiskPlan.GetAvailableProductTypes(instrument).length === 1) {
                productTypeSuffix = '';
            }

            if (isValidString(productTypeSuffix)) {
                productTypeSuffix = SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + SymbolInfoPanel.ConvertProductTypeToSymbolInfoSuffix(productType);
            }
        } else
            if (productType !== ProductType.General) {
                key = SymbolInfoPanel.KEY_Margin;
            }

        for (let i = 0; i < itemsN; i++) {
            const keyWithProductType = key + SymbolInfoPanel.MARGIN_TIER_SEPARATOR + i + productTypeSuffix;

            items.push(keyWithProductType);
        }

        return items;
    }

    public static ProductTypeFromMarginKeyMap = (function () {
        const res = {};
        res[SymbolInfoPanel.KEY_MarginIntraday] = res[SymbolInfoPanel.KEY_LeverageIntraday] = ProductType.Intraday;
        res[SymbolInfoPanel.KEY_MarginDelivery] = res[SymbolInfoPanel.KEY_LeverageDelivery] = ProductType.Delivery;

        return res;
    })();

    public static GetProductTypeFromMarginKey (key: string): ProductType {
        return SymbolInfoPanel.ProductTypeFromMarginKeyMap[key] ?? ProductType.General;
    }

    public static ConvertProductTypeToSymbolInfoSuffix (productType: ProductType): string {
        if (productType === ProductType.Intraday) {
            return SymbolInfoPanel.PRODUCT_TYPE_INTRADAY;
        } else if (productType === ProductType.Delivery) {
            return SymbolInfoPanel.PRODUCT_TYPE_DELIVERY;
        }

        return '';
    }

    public static IsLeverageKey (key: string): boolean {
        return isValidString(key) ? key.includes(SymbolInfoPanel.KEY_Leverage) : false;
    }

    // SymbolInfoPanel.IsLeverage = function (key, instrument, productType)
    // {
    //     let isPriceBasedMultipleCoefMarginType = RiskPlan.IsPriceBasedMultipleCoefMarginType(instrument, productType)

    //     return isPriceBasedMultipleCoefMarginType && SymbolInfoPanel.IsLeverageKey(key)
    // }

    public static AddMaintenanceItems_ (itemsForTable: string[], instrument: Instrument, productType: ProductType): void {
        const suffix = RiskPlan.GetAvailableProductTypes(instrument).length > 1 ? SymbolInfoPanel.GetProductTypeSuffix(productType) : '';
        let items: string[] = [];

        if (!RiskPlan.UseOvernightMargin(instrument, productType) && !RiskPlan.UseLongShortMargin(instrument, productType)) {
            items = items.concat(SymbolInfoPanel.GetMarginRequirementsItemsFromProductType(SymbolInfoPanel.KEY_Margin, instrument, productType));
        } else if (RiskPlan.UseOvernightMargin(instrument, productType) && !RiskPlan.UseLongShortMargin(instrument, productType)) {
            items = items.concat(SymbolInfoPanel.GetMarginRequirementsItemsFromProductType(SymbolInfoPanel.KEY_MarginDay, instrument, productType));
            items = items.concat(SymbolInfoPanel.GetMarginRequirementsItemsFromProductType(SymbolInfoPanel.KEY_MarginOvernight, instrument, productType));
        } else if (!RiskPlan.UseOvernightMargin(instrument, productType) && RiskPlan.UseLongShortMargin(instrument, productType)) {
            items.push(SymbolInfoPanel.KEY_MarginBuy);
            items.push(SymbolInfoPanel.KEY_MarginSell);
        } else if (RiskPlan.UseOvernightMargin(instrument, productType) && RiskPlan.UseLongShortMargin(instrument, productType)) {
            if (RiskPlan.GetCalculateMarginTypeNullable(instrument, productType) !== MarginTypes.MARGIN_FULL) {
                items.push(SymbolInfoPanel.KEY_MarginDayBuy);
                items.push(SymbolInfoPanel.KEY_MarginDaySell);
                items.push(SymbolInfoPanel.KEY_MarginOvernightBuy);
                items.push(SymbolInfoPanel.KEY_MarginOvernightSell);
            } else {
                items = items.concat(SymbolInfoPanel.GetMarginRequirementsItemsFromProductType(SymbolInfoPanel.KEY_Margin, instrument, productType));
            }
        }

        items = items.concat(SymbolInfoPanel.GetLeverageItemsFromProductType(instrument, productType));

        // var accounts = BaseApplication.App.MultiDataCache.getSortedAccounts();
        // if (accounts.Count > 0)
        // {
        //     var res = true;
        //     if (accounts.Count > 1)
        //     {
        //         var cur = accounts[0].BaseCurrency;
        //         for (int i = 0; i < accounts.Count; i++)
        //         {

        //             res &= cur == accounts[i].BaseCurrency;

        //         }
        //     }
        //     if (res)
        //         items.Add(KEY_MarginInAccountCurrency);
        // }
        items.forEach(item => {
            if (isValidString(suffix)) {
                itemsForTable.push(item + SymbolInfoPanel.PRODUCT_TYPE_SEPARATOR + suffix);
            } else {
                itemsForTable.push(item);
            }
        });
    }

    /// <summary>
    /// Adds keys for Fees Items, которые показываются СЕЙЧАС
    /// </summary>
    public static AddFeesItems (itemsForTable, VerticalPanelDataProvider: VerticalPanelDataProvider): void {
        if (isNullOrUndefined(itemsForTable) || isNullOrUndefined(VerticalPanelDataProvider)) {
            return;
        }

        const ins: Instrument = VerticalPanelDataProvider.Source;
        const acc: Account = VerticalPanelDataProvider.dependency ?? DataCache.MainAccountNew;

        if (!isNullOrUndefined(acc.CommissionPlan)) {
            acc.CommissionPlan.AddFeeItems(itemsForTable, ins);
        }

        itemsForTable.push(SymbolInfoPanel.KEY_ShortSwapInfo);
        itemsForTable.push(SymbolInfoPanel.KEY_SwapBuy);
        itemsForTable.push(SymbolInfoPanel.KEY_SwapSell);

        const CustodialFeeData = DataCache.CustodialPlans.GetCustodialDataByInstrument(acc.CustodialPlanId, ins);
        if (CustodialFeeData !== null) {
            itemsForTable.push(SymbolInfoPanel.KEY_CustodialFee);
        }

        if (ins.ShortPositionInterest != null) {
            itemsForTable.push(SymbolInfoPanel.KEY_ShortPositionInterest);
        }

        let historical = SymbolInfoPanel.getHistoricalSwap(ins, SymbolInfoPanel.KEY_Historical_LongSwap_shortKey);
        if (historical !== null) {
            itemsForTable.push(SymbolInfoPanel.KEY_Historical_LongSwap);
        }

        historical = SymbolInfoPanel.getHistoricalSwap(ins, SymbolInfoPanel.KEY_Historical_ShortSwap_shortKey);
        if (historical !== null) {
            itemsForTable.push(SymbolInfoPanel.KEY_Historical_ShortSwap);
        }

        historical = SymbolInfoPanel.getHistoricalSwap(ins, SymbolInfoPanel.KEY_Historical_LastSwap_shortKey);
        if (historical !== null) {
            itemsForTable.push(SymbolInfoPanel.KEY_Historical_LastSwap);
        }
    }

    public static AddHighLowLimitWarning (arrToAdd: any[], ins: Instrument, needAddOnlyKeys = false): void {
        if (isNullOrUndefined(arrToAdd) || isNullOrUndefined(ins)) {
            return;
        }

        if (!isNullOrUndefined(ins.WarningForChangeFromLastPrice)) {
            const sP = SymbolInfoPanel.commisionSplitter;
            const group = SymbolInfoPanel.KEY_tradingInfo;
            const ranges = ins.WarningForChangeFromLastPrice.ranges;
            let sortIndex = SymbolInfoPanel.KEY_LowLimit_SortIndex;

            const createItem = (arrToAdd, key, ranges, lastChangePrefix): void => {
                for (let i = 0; i < ranges.length; i++) {
                    const chVal = lastChangePrefix + ranges[i].LastChange;
                    const lowR = ranges[i].LastPrice;
                    const highR = i + 1 < ranges.length ? ranges[i + 1].LastPrice : -1;
                    const id = key + sP + chVal + sP + lowR + sP + highR;

                    arrToAdd.push(needAddOnlyKeys ? id : SymbolInfoPanel.createItem(group, ++sortIndex, i !== 0 ? '' : key, id));
                }
            };

            createItem(arrToAdd, SymbolInfoPanel.KEY_HighLimitWarning, ranges, '+');
            createItem(arrToAdd, SymbolInfoPanel.KEY_LowLimitWarning, ranges, '-');
        }
    }

    public static AddHighLowLimitWarningRowValueByKey (key: string): string {
        if (!isValidString(key)) {
            return;
        }

        const sP = SymbolInfoPanel.commisionSplitter;
        const splitArr = key.split(sP);
        const value = splitArr[1] + ' %';
        const leftBorder = splitArr[2];
        const wordBetween = 'Last';
        const rightBorder = splitArr[3] === '-1' ? '\u221e'/* ∞ */ : splitArr[3];

        return leftBorder + ' <= ' + wordBetween + '  < ' + rightBorder + sP + value;
    }

    public static filterByInstrumentTradableItemsID (list: string[], VerticalPanelDataProvider: VerticalPanelDataProvider): string[] {
        const currentInstrument: Instrument = VerticalPanelDataProvider.Source;
        // let route = currentInstrument == null ? null : BaseApplication.App.MultiDataCache.getRouteByName(currentInstrument.getRoute());

        let forbidden = [];
        if (SymbolInfoPanel.CheckNeedFilter(VerticalPanelDataProvider)) {
            // для типоув Индекс и если не трейдабл убираем из списка те что ниже.
            forbidden = forbidden.concat([
                SymbolInfoPanel.KEY_AllowedOperations, SymbolInfoPanel.KEY_AllowedOrderTypes, SymbolInfoPanel.KEY_ProductType, SymbolInfoPanel.KEY_DeliveryStatus, SymbolInfoPanel.KEY_NextHoliday, SymbolInfoPanel.KEY_TickCoast,
                SymbolInfoPanel.KEY_MinimalLot, SymbolInfoPanel.KEY_MinimalLotIntraday, SymbolInfoPanel.KEY_MinimalLotDelivery, SymbolInfoPanel.KEY_MaximumLot, SymbolInfoPanel.KEY_MaximumLotIntraday, SymbolInfoPanel.KEY_MaximumLotDelivery, SymbolInfoPanel.KEY_MaxPositionQty, SymbolInfoPanel.KEY_MaxPositionQtyIntraday, SymbolInfoPanel.KEY_MaxPositionQtyDelivery, SymbolInfoPanel.KEY_LotStep, SymbolInfoPanel.KEY_HiLimit, SymbolInfoPanel.KEY_LowLimit,
                SymbolInfoPanel.KEY_SwapBuy, SymbolInfoPanel.KEY_SwapSell, SymbolInfoPanel.KEY_AllowShortPositions, SymbolInfoPanel.KEY_AllowShortPositionsIntraday, SymbolInfoPanel.KEY_AllowShortPositionsDelivery,
                // fee при добавлении id-шек
                SymbolInfoPanel.KEY_Margin, SymbolInfoPanel.KEY_MarginOvernight, SymbolInfoPanel.KEY_MarginDay, SymbolInfoPanel.KEY_MarginBuy, SymbolInfoPanel.KEY_MarginSell, SymbolInfoPanel.KEY_MarginDayBuy, SymbolInfoPanel.KEY_MarginDaySell, SymbolInfoPanel.KEY_MarginOvernightBuy, SymbolInfoPanel.KEY_MarginOvernightSell,
                SymbolInfoPanel.KEY_MarginIntraday, SymbolInfoPanel.KEY_MarginOvernightIntraday, SymbolInfoPanel.KEY_MarginDayIntraday, SymbolInfoPanel.KEY_MarginBuyIntraday, SymbolInfoPanel.KEY_MarginSellIntraday, SymbolInfoPanel.KEY_MarginDayBuyIntraday, SymbolInfoPanel.KEY_MarginDaySellIntraday, SymbolInfoPanel.KEY_MarginOvernightBuyIntraday, SymbolInfoPanel.KEY_MarginOvernightSellIntraday,
                SymbolInfoPanel.KEY_MarginDelivery, SymbolInfoPanel.KEY_MarginOvernightDelivery, SymbolInfoPanel.KEY_MarginDayDelivery, SymbolInfoPanel.KEY_MarginBuyDelivery, SymbolInfoPanel.KEY_MarginSellDelivery, SymbolInfoPanel.KEY_MarginDayBuyDelivery, SymbolInfoPanel.KEY_MarginDaySellDelivery, SymbolInfoPanel.KEY_MarginOvernightBuyDelivery, SymbolInfoPanel.KEY_MarginOvernightSellDelivery
            ]);
        }
        // Symbol info - для инструментов с settlement system = immediate прятать блок настроек Margin req
        else if (currentInstrument.TradingBalance === InstrumentTradingBalance.SETTLEMENT_IMMEDIATE) {
        // для типоув Индекс и если не трейдабл убираем из списка те что ниже.
            forbidden = forbidden.concat([
                SymbolInfoPanel.KEY_Margin, SymbolInfoPanel.KEY_MarginOvernight, SymbolInfoPanel.KEY_MarginDay, SymbolInfoPanel.KEY_MarginBuy, SymbolInfoPanel.KEY_MarginSell, SymbolInfoPanel.KEY_MarginDayBuy, SymbolInfoPanel.KEY_MarginDaySell, SymbolInfoPanel.KEY_MarginOvernightBuy, SymbolInfoPanel.KEY_MarginOvernightSell,
                SymbolInfoPanel.KEY_MarginIntraday, SymbolInfoPanel.KEY_MarginOvernightIntraday, SymbolInfoPanel.KEY_MarginDayIntraday, SymbolInfoPanel.KEY_MarginBuyIntraday, SymbolInfoPanel.KEY_MarginSellIntraday, SymbolInfoPanel.KEY_MarginDayBuyIntraday, SymbolInfoPanel.KEY_MarginDaySellIntraday, SymbolInfoPanel.KEY_MarginOvernightBuyIntraday, SymbolInfoPanel.KEY_MarginOvernightSellIntraday,
                SymbolInfoPanel.KEY_MarginDelivery, SymbolInfoPanel.KEY_MarginOvernightDelivery, SymbolInfoPanel.KEY_MarginDayDelivery, SymbolInfoPanel.KEY_MarginBuyDelivery, SymbolInfoPanel.KEY_MarginSellDelivery, SymbolInfoPanel.KEY_MarginDayBuyDelivery, SymbolInfoPanel.KEY_MarginDaySellDelivery, SymbolInfoPanel.KEY_MarginOvernightBuyDelivery, SymbolInfoPanel.KEY_MarginOvernightSellDelivery,
                SymbolInfoPanel.KEY_SwapBuy, SymbolInfoPanel.KEY_SwapSell
            ]);
        }

        // special hiding if "0" #31602
        if (!isNaN(currentInstrument.HightLimit)) {
            forbidden.push(SymbolInfoPanel.KEY_HiLimit);
        }
        if (!isNaN(currentInstrument.LowLimit)) {
            forbidden.push(SymbolInfoPanel.KEY_LowLimit);
        }

        // if (Utils.IsNullOrUndefined(currentInstrument.Limits.GetHighLimitFromPriceLimitsMessage()))
        //     list.splice(list.indexOf(SymbolInfoPanel.KEY_HiLimitFromPriceLimitsMsg), 1)
        // if (Utils.IsNullOrUndefined(currentInstrument.Limits.GetLowLimitFromPriceLimitsMessage()))
        //     list.splice(list.indexOf(SymbolInfoPanel.KEY_LowLimitFromPriceLimitsMsg), 1)
        if (currentInstrument.SwapBuy === 0) {
            forbidden.push(SymbolInfoPanel.KEY_SwapBuy);
        }
        if (currentInstrument.SwapSell === 0) {
            forbidden.push(SymbolInfoPanel.KEY_SwapSell);
        }

        // TODO
        // if (!BaseApplication.App.MultiDataCache.DCache.IsSharedAsset(currentInstrument.Exp1))
        //    forbidden.push(SymbolInfoPanel.KEY_Exp1);

        const apt = currentInstrument.RiskSettings.RiskPlanSettings.availableProductTypes;
        if (apt.length === 1 && apt[0] == ProductType.General) {
            const index = list.indexOf(SymbolInfoPanel.KEY_ProductType);
            if (index >= 0) // Den: не стал восстанавливать логику скрытия через forbidden так как не понятно не сломаю ли что-то, пока скрывается только ProductType
            {
                list.splice(index, 1);
            }

        // forbidden.push(SymbolInfoPanel.KEY_ProductType);
        }

        return list;
    }

    public static CheckNeedFilter (VerticalPanelDataProvider: VerticalPanelDataProvider): boolean {
        const currentInstrument: Instrument = VerticalPanelDataProvider.Source;
        // Route route = currentInstrument == null ? null : BaseApplication.App.MultiDataCache.getRouteByName(currentInstrument.getRoute());

        return (/* (route != null && !route.IsTradable) || route == null || */currentInstrument.InstrType === InstrumentTypes.INDICIES);
    }

    // возвращает полное описание текущей сессии для AllowedOperations SymbolImfo && Watchlist
    public static GetAllowedOperations (currentInstrument: Instrument): string {
        const comma = ', ';
        let allow = '';

        if (isNullOrUndefined(currentInstrument)) {
            return allow;
        }

        const tradingMode = currentInstrument.TradingMode;
        if (tradingMode === TradingMode.FullyOpen || tradingMode === TradingMode.LiquidationOnly) {
            const allowedOperations = Instrument.GetAllowedOperations(currentInstrument);
            const decreaseOnlyPositionCount = Instrument.IsDecreaseOnlyPositionCount(currentInstrument);

            if ((allowedOperations & SessionOperations.Open) === SessionOperations.Open &&
            isValidString(SymbolInfoPanel.GetAllowedOrderTypes(currentInstrument))) {
                allow += Resources.getResource('panel.watchlist.cell.sending') + comma;
            }

            if ((allowedOperations & SessionOperations.Modify) === SessionOperations.Modify) {
                allow += Resources.getResource('panel.watchlist.cell.modify') + comma;
            }

            if ((allowedOperations & SessionOperations.Cancel) === SessionOperations.Cancel) {
                allow += Resources.getResource('panel.watchlist.cell.cancel') + comma;
            }

            if (decreaseOnlyPositionCount) {
                allow += Resources.getResource('InstrumentDetailsPanel.onlyCloseOrders') + comma;
            }
        } else if (tradingMode === TradingMode.TradingHalt) {
            allow = Resources.getResource('panel.watchlist.cell.cancel');
        }

        if (allow.endsWith(comma)) {
            allow = allow.substring(0, allow.length - comma.length);
        }

        return allow;
    }

    // возвращает полное описание текущей сессии для AllowedOrderTypes SymbolImfo
    public static GetAllowedOrderTypes (instrument: Instrument): string {
        const comma = ', ';
        let allow = '';
        const tradingMode = instrument.TradingMode;
        if (tradingMode === TradingMode.FullyOpen ||
        tradingMode === TradingMode.TradingHalt ||
        tradingMode === TradingMode.LiquidationOnly) {
            const supportedParamObj = new OrderTypeBaseParameters(instrument);

            const OrderTypes = DataCache.OrderParameterContainer.OrderTypes;
            for (const orderType in OrderTypes) {
                const oT = OrderTypes[orderType];
                if (oT.IsSupported(supportedParamObj)) {
                    allow += Resources.getResource(oT.localizationKey()) + comma;
                }
            }
        }

        if (allow.endsWith(comma)) {
            allow = allow.substring(0, allow.length - comma.length);
        }

        return allow;
    }

    public static dayOfWeekAsString (dayOfWeek: number): string {
        switch (dayOfWeek) {
        case 0:
            return 'Sunday';
        case 1:
            return 'Monday';
        case 2:
            return 'Tuesday';
        case 3:
            return 'Wednesday';
        case 4:
            return 'Thursday';
        case 5:
            return 'Friday';
        case 6:
            return 'Saturday';
        }
    }

    public static LocalizeSessionGroup (VerticalPanelDataProvider: VerticalPanelDataProvider): string {
        const KeySessionInfo = SymbolInfoPanel.KEY_sessionInfo;
        const instrument: Instrument = VerticalPanelDataProvider.Source;
        // Андрюша просил добавить в группу текущую дату и если праздник то шортен или нон-воркинг
        let additionSessInfo = '';
        const session = !isNullOrUndefined(instrument) ? instrument.Session : null;
        if (!isNullOrUndefined(session)) {
            const dayOfWeek = session.GetStartSessionDayOfWeek();
            const getTodayDay = session.GetStartSessionTodayDay();
            additionSessInfo =
            ' - ' +
            Resources.getResource('general.' + SymbolInfoPanel.dayOfWeekAsString(dayOfWeek)) + ' ' + getTodayDay;

            const holiday = session.GetTodayHoliday();

            if (!isNullOrUndefined(holiday)) {
                if (holiday.IsNotWorking()) {
                    additionSessInfo += ' (' + Resources.getResource('InstrumentDetailsPanel.non trading day') + ')';
                } else if (holiday.IsShorted()) {
                    additionSessInfo += ' (' + Resources.getResource('InstrumentDetailsPanel.shortened') + ')';
                } else {
                    additionSessInfo += ' (' + Resources.getResource('InstrumentDetailsPanel.working') + ')';
                }
            }
        }

        return Resources.getResource(KeySessionInfo) + additionSessInfo;
    }

    public static PrepareTickSizeCostItems (itemsForTable: string[], instrument: Instrument, key: string): void {
        for (let i = 1; i < instrument.VariableTickList.length; i++) {
            let rightBorder = instrument.VariableTickList[i].RightBorder;
            const includeRightBorder = i < instrument.VariableTickList.length - 1 ? !instrument.VariableTickList[i + 1].IncludeLeftBorder : false;
            if (!isFinite(rightBorder)) {
                rightBorder = -1;
            }

            if (key === SymbolInfoPanel.KEY_TickCoast && instrument.isFutureOrOption()) {
                itemsForTable.push(SymbolInfoPanel.KEY_TickCoast + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].LeftBorder + SymbolInfoPanel.commisionSplitter + rightBorder + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].TickCost + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].IncludeLeftBorder + SymbolInfoPanel.commisionSplitter + includeRightBorder);
            }

            if (key === SymbolInfoPanel.KEY_TickSize) {
                const pointSize = instrument.VariableTickList[i].PointSize;
                const point = (pointSize < 1 ? parseFloat(pointSize.toFixed(10)) : pointSize).toString();
                itemsForTable.push(SymbolInfoPanel.KEY_TickSize + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].LeftBorder + SymbolInfoPanel.commisionSplitter + rightBorder + SymbolInfoPanel.commisionSplitter + point + SymbolInfoPanel.commisionSplitter + instrument.VariableTickList[i].IncludeLeftBorder + SymbolInfoPanel.commisionSplitter + includeRightBorder);
            }
        }
    }

    /// <summary>
    /// GetTradeSessionTypeName
    /// </summary>
    public static GetTradeSessionTypeName (dayPeriod: DayPeriods): string {
        switch (dayPeriod) {
        case DayPeriods.PRE_OPEN:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_PREOPEN;
        case DayPeriods.MAIN:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_MAIN;
        case DayPeriods.POST_CLOSE:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_POST_CLOSE;
        case DayPeriods.BEFORE_MARKET:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_BEFORE_MARKET;
        case DayPeriods.AFTER_MARKET:
            return SymbolInfoPanel.SESSION_DAY_PERIOD_AFTER_MARKET;
        default:
            return '';
        }
    }

    // TODO FOR VENDOR!!!!!!!
    public static GetFeeString (lotsFrom: string, lotsTo: string, value: string, allowLimit: boolean, allowHighLimit: boolean, feeVar: FeeStringVariable): string {
        feeVar = feeVar ?? FeeStringVariable.Amount;

        const lf = parseFloat(lotsFrom);
        const lt = parseFloat(lotsTo);
        if (lf === 0 && lt === -1) {
            return value;
        }

        const localizeKey = Resources.getResource(
            feeVar === FeeStringVariable.Price
                ? 'InstrumentDetailsPanel.Price'
                : 'InstrumentDetailsPanel.Amount');

        return lf +
        (allowLimit ? ' <= ' : ' < ') +
        localizeKey +
        (allowHighLimit ? ' <= ' : ' < ') +
        (lt === -1 ? '\u221e'/* ∞ */ : lt.toString()) +
        SymbolInfoPanel.commisionSplitter +
        value;
    }

    public static getSwapBuySell (price, swapType): string {
        switch (swapType) {
        case SwapType.SWAP_IN_CURRENCY:
            return price + ' ' + DataCache.baseCurrency;
        case SwapType.SWAP_IN_PERCENT:
            return price + ' %';
        case SwapType.SWAP_IN_PIPS:
            return price + ' ' + Resources.getResource('general.trading.pips');
        default:
            return '';
        }
    }

    public static getHistoricalSwap (instrument: Instrument, shortKey: string): any {
        if (isNullOrUndefined(instrument)) {
            return null;
        }

        if (isNullOrUndefined(instrument.InstrumentAdditionalInfo)) {
            return null;
        }

        if (instrument.InstrumentAdditionalInfo.hasOwnProperty(shortKey)) {
            return instrument.InstrumentAdditionalInfo[shortKey];
        }

        return null;
    }

    public static NeedSkipEmptyMargin (instrument: Instrument, productType: ProductType, key: string): boolean // #113777, #113782
    {
        const margin = RiskPlan.GetMargin(instrument, productType, 0);

        return (key.indexOf(SymbolInfoPanel.KEY_Margin) === 0) && margin.isEmpty();
    }
}

VerticalApplicationPanelNew.extendWith(SymbolInfoPanel, {
    Name: 'SymbolInfoPanel',
    // topPanelHeight: 25,
    data: function () {
        return {
            isSymbolLinkShow: true,
            isAccountLinkShow: true,
            canLinkByAccount: false,
            canFilterByAccount: false,
            zIndex: 1300,
            showHeader: true,
            showFooter: false,
            dockablePanel: false,
            resizable: false,
            account: null,
            width: SymbolInfoPanel.PANEL_WIDTH,
            height: SymbolInfoPanel.PANEL_HEIGHT,
            closeBtnVisible: true
        };
    }
});
