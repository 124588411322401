// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// Refactor. There's a lot unnecessary base methods here.

import { CustomEvent } from '@shared/utils/CustomEvents';
import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { DirectAssetMessage, type DirectReportMessage } from '@shared/utils/DirectMessages/DirectMessagesImport';
import { generateReportMessageHandler } from '@shared/utils/AppHandlers';

export abstract class DirectVendorBase {
    // #region Constants

    public static readonly DELAY_BETWEEN_CROSSPRICES = 2000;

    public static readonly PFSVendor = 'PFIX';

    // #endregion

    public login: any = null;
    public userSessionId: any = null;
    // TODO.
    public vendorName = '';

    public OnNewMessage = new CustomEvent();
    public OnConnected = new CustomEvent();
    public OnDisconnected = new CustomEvent();

    // working here
    public OnConnectionLost = new CustomEvent();
    public OnError = new CustomEvent();

    public FAssets: Record<string, DirectAssetMessage> | null = null;
    crossRatesLoopHandler: any;

    public AddConnectedListener (method, context): void {
        this.OnConnected.Subscribe(method, context);
    }

    public RemoveConnectedListener (method, context): void {
        this.OnConnected.UnSubscribe(method, context);
    }

    public AddDisconnectedListener (method, context): void {
        this.OnDisconnected.Subscribe(method, context);
    }

    public RemoveDisconnectedListener (method, context): void {
        this.OnDisconnected.UnSubscribe(method, context);
    }

    public AddConnectionLostListener (method, context): void {
        this.OnConnectionLost.Subscribe(method, context);
    }

    public RemoveConnectionLostListener (method, context): void {
        this.OnConnectionLost.UnSubscribe(method, context);
    }

    public AddOnErrorListener (method, context): void {
        this.OnError.Subscribe(method, context);
    }

    public RemoveOnErrorListener (method, context): void {
        this.OnError.UnSubscribe(method, context);
    }

    public AddMessageListener (method, context): void {
        this.OnNewMessage.Subscribe(method, context);
    }

    public RemoveMessageListener (method, context): void {
        this.OnNewMessage.UnSubscribe(method, context);
    }

    public NotifyListeners (message): void {
        this.OnNewMessage.Raise(message);
    }

    public GenerateReportMessage (openOrderMessage): void {
        const reportMsgArr = generateReportMessageHandler.GenerateReportMessage(openOrderMessage) as DirectReportMessage[];
        const len = reportMsgArr.length;
        for (let i = 0; i < len; i++) {
            this.NotifyListeners(reportMsgArr[i]);
        }
    }

    public async Connect (params): Promise<any> {
        return await this.ConnectProcessPromise(params)
            .then((connectionResult) => {
                if (!connectionResult?.Connected) {
                    return Promise.reject(connectionResult);
                }
                return connectionResult;
            })
            .then((connectionResult) => {
                this.Connected(connectionResult);
                return connectionResult;
            })
            .catch((dataError) => {
                void this.DisconnectProcessPromise();
                this.Disconnected(dataError);
                return dataError;
            });
    }

    public ConnectionLost (): void {
        this.OnConnectionLost.Raise();
    }

    public ErrorOccurred (): void {
        this.OnError.Raise();
    }

    // #region Trading

    public placeOrder (data): any {

    }

    // TODO. Interface. Refactor.
    public async cancelOrder (order, placedFrom): Promise<void> {

    }

    public async closePositions (closePositionData, placedFrom): Promise<void | any[]> {

    }

    public async modifyPosition (data): Promise<any> {

    }

    public async modifyOrder (data): Promise<any> {

    }

    // TODO. Refactor. Move to modifyOrder()?
    public async changeOrderToMarket (order, placedFrom): Promise<any> {

    }

    // #endregion Trading

    public Disconnect (disconnectArguments?: { isNoNActivity?: boolean }): void {
        void this.DisconnectProcessPromise(disconnectArguments?.isNoNActivity ?? false)
            .catch((err) => {
                console.error('Vendor disconnect error: ' + err);
            })
            .then(() => {
                clearInterval(this.crossRatesLoopHandler);
                this.Disconnected(disconnectArguments);
            });
    }

    public ParseErrorMessage (text): any {
        try {
            const obj = JSON.parse(text);
            if (obj) { return obj.message; }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            return text;
        }
    }

    // TODO. Rename?
    public Connected (connectedArguments): void {
        this.OnConnected.Raise(connectedArguments);
    }

    // TODO. Rename?
    public Disconnected (disconectArguments): void {
        this.OnDisconnected.Raise(disconectArguments);
    }

    // #region Update Object Callbacks

    public UpdateAccount (accMsg): void {
        this.NotifyListeners(accMsg);
    }

    public UpdatePosition (msg): void {
        this.NotifyListeners(msg);
    }

    public PositionClosedByServer (msg): void {
        this.NotifyListeners(msg);
    }

    public UpdateOrder (msg): void {
        this.NotifyListeners(msg);

        if (msg.OrderStatus !== null) {
            this.GenerateReportMessage(msg);
        }
    }

    public NewQuote (msg): void {
        this.NotifyListeners(msg);
    }

    public AddAsset (currency: string): void {
        if (!currency) {
            return;
        }

        if (this.FAssets == null) {
            this.FAssets = {};
        }

        if (!this.FAssets.hasOwnProperty(currency)) {
            this.FAssets[currency] = this.GetAssetMessage(Object.keys(this.FAssets).length, currency);
            this.NotifyListeners(this.FAssets[currency]);
        }
    }

    public GetAssetMessage (id: number, currency: string): DirectAssetMessage {
        const am = new DirectAssetMessage();
        am.Id = id;
        am.Name = am.Description = currency;
        am.MinChange = Math.pow(10, -2);
        return am;
    }

    public NewTrade (msg): void {
        this.NotifyListeners(msg);
    }

    // #endregion

    // #region For Overriding

    public abstract ConnectProcessPromise (params): Promise<any>;

    public abstract DisconnectProcessPromise (isNoNActivity?): Promise<any>;

    public abstract GetHistoryPromise (historyParams, loadFromServer, signal): any;

    public GetRulesTable (account): any { return {}; }

    public abstract SubscribeSymbol (InstrumentId, route, quoteType, forse, isDataSource): void;

    public abstract UnsubscribeSymbol (InstrumentId, route, quoteType, forse, isDataSource): void;

    public abstract GenerateReportPromise (reportId, paramDict): Promise<any>;
    public abstract SendPing (): Promise<any>;

    // #endregion For Overriding

    // #region TODO. AuthData idk

    public GetVendorName (): string {
        return this.vendorName;
    }

    public GetLogin (): any {
        return this.login;
    }

    public getUserSessionId (): any {
        return this.userSessionId;
    }

    // #endregion
}
