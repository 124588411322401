// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { Resources } from '../../../Localizations/Resources';
import { Point, Rectangle } from '../../../Commons/Geometry';
import { Cursors } from '../../../Commons/Cursors';
import { DrawPointer, DrawPointerTypeEnum } from '../../Utils/DrawPointer';
import { Brushes, Pens } from '../../../Commons/Graphics';
import { ProMath } from '../ToolView';
import { SelectionState, ToolSelectionType } from '../Selection';
import { TerceraChartTradingOperation } from '../../Utils/ChartConstants';
import { OrderToolView, CancelCB } from './OrderToolView';
import { TradingToolViewBase, TradingToolViewType } from './TradingToolViewBase';

import { OperationType } from '../../../Utils/Trading/OperationType';
import { OrderType } from '../../../Utils/Trading/OrderType';
import { TradingLockUtils } from '../../../Utils/TradingLockUtils';
import { type TerceraChartCashItemSeries } from '../../Series/TerceraChartCashItemSeries';
import { BaseSettingsUtils } from '../../../Commons/UtilsClasses/BaseGeneralSettingsUtilsWrapper';

export class StopLimitOrderToolView extends OrderToolView {
    public static readonly LIMIT_OFFSET = 41;

    // this.limitGripRect = Rectangle.Empty();
    public limitIconRect = Rectangle.Empty();
    public limitQuantityRect = Rectangle.Empty();
    public limitInfoRect = Rectangle.Empty();

    // Move
    public linkedOrderToolTipRect = Rectangle.Empty();
    public gripRect = Rectangle.Empty();

    public lastValidLimitPriceValue = NaN;
    public stopLimitPriceValue = NaN;
    public invalidPriceDescr = '';

    public limitGripRect: Rectangle;

    constructor (order, getToolViewByOrderId, terceraChartPanelContext, renderer) {
        super(order, getToolViewByOrderId, terceraChartPanelContext, renderer);

        this.isLinkingStyle = false;
    }

    public override Draw (gr, window, param): void {
        if (!this.Order?.Price) {
            return;
        }

        const CR: Rectangle = window.ClientRectangle;

        const minX = CR.X;
        const maxX = minX + CR.Width;

        try {
            let screenPoints00 = this.screenPoints[0][0];
            if (screenPoints00 < minX + 9) {
                screenPoints00 = minX + 9;
            }

            if (this.myRenderer.ShowVisualTradingOnLeftSide) {
                screenPoints00 = minX + 15;
            }

            const screenPoints01 = this.screenPoints[0][1];

            // Ловим ошибки
            if ((screenPoints00 < 0 && maxX < 0) || screenPoints01 >= ProMath.infinity || screenPoints01 >= ProMath.infinity || screenPoints01 <= ProMath.infinityMinus || screenPoints01 <= ProMath.infinityMinus) {
                return;
            }

            // limit price for stop limit order
            const limitPrice = !isNaN(this.priceValue) ? (this.priceValue - (this.Order.StopLimit - this.Order.Price)) : this.Order.Price;
            if (!this.TradeWasModified) {
                this.stopLimitY = Math.round(window.PointsConverter.GetScreenY(limitPrice));
            }

            // Какая-то часть ордера не видна задаём другой стиль (важно - до отрисовки стоп части)
            this.isLinkingStyle = !CR.Contains(screenPoints00, screenPoints01) || !CR.Contains(screenPoints00, this.stopLimitY);

            // Draw stop part
            if (this.Order.IsActivated) // #92963
            {
                this.MainOrderVisibility = true;
            } // иначе не перемещается ни лимит ни сл/тп
            else {
                OrderToolView.prototype.Draw.apply(this, [gr, window, param]);
            }

            // оптимизация - вне не рисуем ордер и не добавляем его плашку
            if (this.isLinkingStyle) {
                const isVisible = CR.Contains(screenPoints00, this.stopLimitY);
                // Не видны обе части ордера - ничего не рисуем
                if (!this.MainOrderVisibility && !isVisible) {
                    return;
                }

                let tooltipY = !this.MainOrderVisibility ? screenPoints01 : this.stopLimitY;
                const isTop = tooltipY < CR.Y;
                tooltipY = (tooltipY >= (CR.Y + CR.Height) ? CR.Y + CR.Height - TradingToolViewType.ICON_RECT_HEIGHT - 5 : CR.Y + 3);

                const descr = this.MainOrderVisibility ? this.LimitPriceDescription() : this.DescriptionText();

                this.linkedOrderToolTipRect = new Rectangle(this.MainOrderVisibility ? screenPoints00 + StopLimitOrderToolView.LIMIT_OFFSET : screenPoints00, tooltipY, TradingToolViewType.ICON_RECT_WIDTH + gr.GetTextWidth(descr, TradingToolViewBase.tradeFont) + 10, TradingToolViewType.ICON_RECT_HEIGHT);
                this.DrawLinkToOrder(gr, isTop, this.CurrentSelection.CurrentState !== SelectionState.None, this.linkedOrderToolTipRect, this.MainOrderVisibility ? OrderType.Limit : OrderType.StopLimit, descr);

                if (!isVisible) {
                // Draw connection between parent order and linked tooltip
                    const isBuyOrder = this.Order.BuySell == OperationType.Buy;
                    gr.DrawLines(TradingToolViewBase.orderGrayPen, [
                        new Point(this.iconRect.X + 29, isBuyOrder ? this.iconRect.Y + 21 : this.iconRect.Y),
                        new Point(this.iconRect.X + 29, this.linkedOrderToolTipRect.Y + this.linkedOrderToolTipRect.Height / 2),
                        new Point(this.linkedOrderToolTipRect.X, this.linkedOrderToolTipRect.Y + this.linkedOrderToolTipRect.Height / 2)
                    ]);
                    this.gripRect = Rectangle.Empty();
                    // this.iconRect = Rectangle.Empty();
                    this.infoRect = Rectangle.Empty();
                    this.removeButton = Rectangle.Empty();
                    this.slRect = Rectangle.Empty();
                    this.tpRect = Rectangle.Empty();
                    this.limitGripRect = Rectangle.Empty();
                    this.limitIconRect = Rectangle.Empty();
                    this.limitQuantityRect = Rectangle.Empty();
                    this.limitInfoRect = Rectangle.Empty();
                    return;
                }
            } else {
                this.linkedOrderToolTipRect = Rectangle.Empty();
            }

            const hoveredOrSelected = this.CurrentSelection.CurrentState !== SelectionState.None;
            const isBuy = this.Order.BuySell === OperationType.Buy;

            let img = null;
            if (!this.MainOrderVisibility) {
                img = hoveredOrSelected ? TradingToolViewBase.link_out_LMT_hover_full : TradingToolViewBase.link_out_LMT_default_full;
            } else
                if (this.TradeWasModified) {
                    img = this.Collapsed ? TradingToolViewBase.move_LMT_link_closeImage : TradingToolViewBase.move_LMT_link_openImage;
                } else {
                    if (!this.Collapsed) {
                        img = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_LMT_link_open_hoverImage : TradingToolViewBase.buy_LMT_link_open_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_LMT_link_open_hoverImage : TradingToolViewBase.sell_LMT_link_open_defaultImage);
                    } else {
                        img = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_LMT_link_close_hoverImage : TradingToolViewBase.buy_LMT_link_close_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_LMT_link_close_hoverImage : TradingToolViewBase.sell_LMT_link_close_defaultImage);
                    }
                }

            const xOffset = screenPoints00 + StopLimitOrderToolView.LIMIT_OFFSET;
            this.limitIconRect = new Rectangle(xOffset, this.stopLimitY - 11, img.width, img.height);

            // Icon
            gr.drawImage(img, this.limitIconRect.X, this.limitIconRect.Y);

            // Connection line
            if (!this.Order.IsActivated) // #92963
            {
                if (this.Order.Price > this.Order.StopLimit) {
                    let tmpY = this.iconRect.Y;
                    if (!this.linkedOrderToolTipRect.IsEmpty()) {
                        tmpY = this.linkedOrderToolTipRect.Y;
                    }

                    gr.DrawLine(TradingToolViewBase.orderGrayPen, this.limitIconRect.X - 20, this.limitIconRect.Y + this.limitIconRect.Height / 2, this.limitIconRect.X - 20, tmpY);
                    gr.DrawLine(TradingToolViewBase.orderGrayPen, this.limitIconRect.X - 20, this.limitIconRect.Y + this.limitIconRect.Height / 2, this.limitIconRect.X, this.limitIconRect.Y + this.limitIconRect.Height / 2);
                } else {
                    let tmpY = this.iconRect.Y;
                    if (!this.linkedOrderToolTipRect.IsEmpty()) {
                        tmpY = this.linkedOrderToolTipRect.Y;
                    }

                    gr.DrawLine(TradingToolViewBase.orderGrayPen, this.limitIconRect.X - 20, this.limitIconRect.Y + this.limitIconRect.Height / 2, this.limitIconRect.X - 20, tmpY + img.height);
                    gr.DrawLine(TradingToolViewBase.orderGrayPen, this.limitIconRect.X - 20, this.limitIconRect.Y + this.limitIconRect.Height / 2, this.limitIconRect.X, this.limitIconRect.Y + this.limitIconRect.Height / 2);
                }
            }

            if (!this.Collapsed) {
                let backgroundBrush = isBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActiveBrush : TradingToolViewBase.buyBackgroundBrush) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActiveBrush : TradingToolViewBase.sellBackgroundBrush);
                let backgroundPen = isBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.buyBackgroundPen) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActivePen : TradingToolViewBase.sellBackgroundPen);

                if (!this.MainOrderVisibility) {
                    backgroundBrush = hoveredOrSelected ? TradingToolViewBase.linkedStyleHoverBrush : TradingToolViewBase.linkedStyleDefaultBrush;
                    backgroundPen = Pens.Black;
                }

                if (this.TradeWasModified) {
                    backgroundBrush = TradingToolViewBase.grayBackgroundBrush;
                    backgroundPen = TradingToolViewBase.grayBackgroundPen;
                }

                let TextBrush = Brushes.White;
                if (!this.MainOrderVisibility) {
                    TextBrush = Brushes.Black;
                }

                // Quantity
                const showLots = BaseSettingsUtils.displayAmountInLots();
                const qty = showLots ? this.Order.Amount : this.Order.Amount * this.Order.Instrument.LotSize;
                const quantity = this.Order.Instrument.DataCache.formatVolume(this.Order.Instrument, qty, showLots, this.Order.ProductType, this.Order.Account);
                this.limitQuantityRect = new Rectangle(this.limitIconRect.X + this.limitIconRect.Width, this.limitIconRect.Y,
                    Math.floor(gr.GetTextWidth(quantity, TradingToolViewBase.tradeFont)) + 10, this.limitIconRect.Height);
                // Draw Quantuty
                gr.FillRect(backgroundBrush, this.limitQuantityRect.X, this.limitQuantityRect.Y, this.limitQuantityRect.Width, this.limitQuantityRect.Height);
                gr.DrawLine(backgroundPen, this.limitQuantityRect.X, this.limitQuantityRect.Y, this.limitQuantityRect.X, this.limitQuantityRect.Y + this.limitQuantityRect.Height);
                gr.DrawString(quantity, TradingToolViewBase.tradeFont, TextBrush,
                    this.limitQuantityRect.X + this.limitQuantityRect.Width / 2, this.limitQuantityRect.Y + this.limitQuantityRect.Height / 2 + 1, 'center', 'middle');

                // Price
                const limitPriceStr = this.LimitPriceDescription();
                this.limitInfoRect = new Rectangle(this.limitQuantityRect.X + this.limitQuantityRect.Width, this.limitQuantityRect.Y,
                    Math.floor(gr.GetTextWidth(limitPriceStr, TradingToolViewBase.tradeFont)) + 10, this.limitQuantityRect.Height);
                // Draw Price
                gr.FillRect(backgroundBrush, this.limitInfoRect.X, this.limitInfoRect.Y, this.limitInfoRect.Width, this.limitInfoRect.Height);
                gr.DrawLine(backgroundPen, this.limitInfoRect.X, this.limitInfoRect.Y, this.limitInfoRect.X, this.limitInfoRect.Y + this.limitInfoRect.Height);
                gr.DrawString(limitPriceStr, TradingToolViewBase.tradeFont, TextBrush,
                    this.limitInfoRect.X + this.limitInfoRect.Width / 2, this.limitInfoRect.Y + this.limitInfoRect.Height / 2 + 1, 'center', 'middle');

                // Draw sltp
                this.iconRect.X = this.limitIconRect.X;
                this.iconRect.Y = this.limitIconRect.Y;
                this.DrawSLTP(gr, param, maxX, true);
                this.DrawSLTP(gr, param, maxX, false);

                if (this.Order.IsActivated) // #92963 нужно нарисовать крестик закрытия у лимит-части так как стоп-часть скрыта
                {
                    if (!param.TerceraChart.TerceraChartTradingToolsRenderer.NotTradingContext && (this.AllowCancelOrder() || this.AllowClosePosition()) && !TradingLockUtils.TradingLock.tradingLocked) {
                        const limitInfoRect = this.limitInfoRect;
                        this.removeButton = limitInfoRect.copy();
                        this.removeButton.X += limitInfoRect.Width;
                        this.removeButton.Width = 19;

                        let cancelOrderImg = null;
                        if (this.TradeWasModified) { cancelOrderImg = TradingToolViewBase.moveOrder_cancelImage; } else {
                            if (this.CurrentSelection.CurrentHover === ToolSelectionType.Remove) {
                                cancelOrderImg = isBuy ? TradingToolViewBase.buy_cancel_mine_hoverImage : TradingToolViewBase.sell_cancel_mine_hoverImage;
                            } else {
                                cancelOrderImg = (isBuy) ? (hoveredOrSelected ? TradingToolViewBase.buy_cancel_hoverImage : TradingToolViewBase.buy_cancel_defaultImage) : (hoveredOrSelected ? TradingToolViewBase.sell_cancel_hoverImage : TradingToolViewBase.sell_cancel_defaultImage);
                            }
                        }

                        if (cancelOrderImg) {
                            gr.drawImage(cancelOrderImg, this.removeButton.X, this.removeButton.Y);
                        }

                        const backgroundPen = isBuy ? (hoveredOrSelected ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.buyBackgroundPen) : (hoveredOrSelected ? TradingToolViewBase.sellBackgroundActivePen : TradingToolViewBase.sellBackgroundPen);
                        gr.DrawLine(backgroundPen, this.removeButton.X, this.removeButton.Y, this.removeButton.X, this.removeButton.Y + this.removeButton.Height);
                    } else {
                        this.removeButton = Rectangle.Empty();
                    }
                }
            } else {
                this.limitQuantityRect = Rectangle.Empty();
                this.limitInfoRect = Rectangle.Empty();
            }

            const isPosition = this.Order.isPosition;

            let p = isPosition ? this.myRenderer.positionBuyPen : this.myRenderer.orderBuyPen;
            if (!isBuy) {
                p = isPosition ? this.myRenderer.positionSellPen : this.myRenderer.orderSellPen;
            }

            if (this.TradeWasModified) {
                p = TradingToolViewBase.orderGrayPen;
            }

            // Draw lines
            const infoRectDelta = this.limitIconRect.X + this.limitIconRect.Width + this.limitQuantityRect.Width + this.limitInfoRect.Width;
            const skipDrawingLine = infoRectDelta > maxX - 3;
            const y = this.limitIconRect.Y + this.limitIconRect.Height / 2;
            if (!skipDrawingLine) {
                gr.DrawLine(p, infoRectDelta, y, maxX, y);
            }

            // +++ add right line
            // if (!isNaN(this.priceValue ))
            //     gr.DrawLine(p, this.screenPoints00 - 8 - 5, y, 0, y);

            // draw price plate
            if (!this.Collapsed || this.TradeWasModified) {
                let backGroundImage = this.Order.BuySell == OperationType.Buy ? TradingToolViewBase.buyBrush : TradingToolViewBase.sellBrush;
                if (this.TradeWasModified) {
                    backGroundImage = TradingToolViewBase.grayBrush;
                }

                const price = this.TradeWasModified ? (!isNaN(this.stopLimitPriceValue) ? this.stopLimitPriceValue : this.lastValidPriceValue) : this.Order.Price;
                // var price = this.Order.StopLimit;
                const text = this.Order.Instrument.formatPrice(price);
                param.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.VisualTrading, price, backGroundImage, text));
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public override GetCurrentSelection (x: number, y: number): ToolSelectionType {
        let newSelection = ToolSelectionType.None;
        newSelection = super.GetCurrentSelection(x, y);

        if (this.limitIconRect.Contains(x, y)) {
            newSelection = ToolSelectionType.LimitIcon;
        } else if (this.limitInfoRect.Contains(x, y)) {
            newSelection = ToolSelectionType.LimitInfo;
        } else if (this.limitQuantityRect.Contains(x, y)) {
            newSelection = ToolSelectionType.LimitQuantity;
        } else if (this.Order?.IsActivated && newSelection === ToolSelectionType.Line) {
            newSelection = ToolSelectionType.None;
        } // #92963

        return newSelection;
    }

    public override ProcessNewPosition (window, x: number, y: number, cashItemSeries: TerceraChartCashItemSeries = null): void {
        const mouseDownHover = this.CurrentSelection.MouseDownHover;
        this.TradeWasModified = true;
        this.errorMode = false;
        let data = 0;
        data = window.PointsConverter.GetDataY(y);

        if (mouseDownHover === ToolSelectionType.LimitIcon || mouseDownHover === ToolSelectionType.LimitInfo) {
        // Move limit part
            if (this.AllowModify()) {
                this.stopLimitPriceValue = data;
                this.priceValue = this.Price;
                this.stopLimitY = y;
                // this.errorMode = this.Order.BuySell == OperationType.Buy ? this.stopLimitPriceValue < this.Order.StopLimit : this.stopLimitPriceValue > this.Order.StopLimit;

                this.stopLimitY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.stopLimitPriceValue)));

                // дочерние сл/тп тулзы ставим на новые координаты когда двигается родительская
                if ((/* this.Order.SLOrder != null || this.Order.TPOrder != null || */!isNaN(this.Order.StopLossPriceValue) || !isNaN(this.Order.TakeProfitPriceValue)) && this.AllowSLTP()) {
                // Move child trades
                    if (this.slTrade != null) {
                        const orderPrice = this.Order.Price; // this.Order.Price;
                        const slTicks = this.Order.Instrument.CalculateTicks(orderPrice, this.slTrade.Price - orderPrice);

                        const d = this.slTrade.Price - orderPrice;
                        const sign = d === 0 ? 0 : (d > 0 ? 1 : -1);
                        // var correctPriceValue = this.stopLimitPriceValue; /*- (this.Order.StopLimit -this. Order.Price)*/

                        this.slTrade.priceValue = this.Order.Instrument.CalculatePrice(this.Order.Instrument.RoundPriceToNearestPointSize(this.stopLimitPriceValue), slTicks * sign);
                        this.slTrade.priceY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.slTrade.priceValue)));
                        this.slTrade.TradeWasModified = true;
                        this.slTrade.parentValue = this.stopLimitPriceValue;
                        this.slTrade.parentY = y;
                    }
                    if (this.tpTrade != null) {
                        const orderPrice = this.Order.Price; // this.Order.Price;
                        const tpTicks = this.Order.Instrument.CalculateTicks(orderPrice, this.tpTrade.Price - orderPrice);

                        const d = this.tpTrade.Price - orderPrice;
                        const sign = d === 0 ? 0 : (d > 0 ? 1 : -1);
                        // let correctPriceValue = this.stopLimitPriceValue;

                        this.tpTrade.priceValue = this.Order.Instrument.CalculatePrice(this.Order.Instrument.RoundPriceToNearestPointSize(this.stopLimitPriceValue), tpTicks * sign);
                        this.tpTrade.priceY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.tpTrade.priceValue)));
                        this.tpTrade.TradeWasModified = true;
                        this.tpTrade.parentValue = this.stopLimitPriceValue;
                        this.tpTrade.parentY = y;
                    }
                    if (this.sllTrade != null) {
                        const orderPrice = this.Order.Price;
                        const sllTicks = this.Order.Instrument.CalculateTicks(orderPrice, this.sllTrade.Price - orderPrice);

                        const d = this.sllTrade.Price - orderPrice;
                        const sign = d === 0 ? 0 : (d > 0 ? 1 : -1);

                        this.sllTrade.priceValue = this.Order.Instrument.CalculatePrice(this.Order.Instrument.RoundPriceToNearestPointSize(this.stopLimitPriceValue), sllTicks * sign);
                        this.sllTrade.priceY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.sllTrade.priceValue)));
                        this.sllTrade.TradeWasModified = true;
                        if (this.slTrade != null) {
                            this.sllTrade.parentValue = this.slTrade.priceValue;
                            this.sllTrade.parentY = this.slTrade.priceY;
                        }
                    }
                }
            }
        } else // Move stop part & sl/tp
        {
            const moveStopPart = mouseDownHover === ToolSelectionType.Image || mouseDownHover === ToolSelectionType.Info; // в этот else попадает не только move stop part а и move sl/tp - отделяем
            if (moveStopPart && this.Order.IsActivated) {
                return;
            } // #92963

            super.ProcessNewPosition(window, x, y, cashItemSeries);
            this.stopLimitPriceValue = !isNaN(this.priceValue) ? (this.priceValue - (this.Order.StopLimit - this.Order.Price)) : this.Order.Price;
            this.stopLimitPriceValue = this.Order.Instrument.RoundPriceToNearestPointSize(this.stopLimitPriceValue);

            this.stopLimitY = Math.floor(Math.round(window.PointsConverter.GetScreenY(this.stopLimitPriceValue)));
            if (this.slTrade != null) {
                this.slTrade.parentY = this.stopLimitY;
            }

            if (this.tpTrade != null) {
                this.tpTrade.parentY = this.stopLimitY;
            }
        }
    }

    public override ProcessMoveFinish (): void {
        try {
            this.CurrentMovement.IsMovingRightNow = false;
            const mouseDownHover = this.CurrentSelection.MouseDownHover;

            if (this.errorMode) {
                this.CurrentMovement.IsMovingRightNow = false;
                this.slPlased = false;
                this.tpPlased = false;
                this.TradeWasModified = false;
                this.CancelMoving();
                this.ResetMouse();
                return;
            }
            // Limit part moving
            if (mouseDownHover === ToolSelectionType.LimitIcon || mouseDownHover === ToolSelectionType.LimitInfo) {
            // TODO  implement
            // let reset = FinishMove;

                if (this.Order != null) {
                    const newPrice = this.myRenderer.Instrument != null ? this.myRenderer.Instrument.roundPrice(this.stopLimitPriceValue) : this.stopLimitPriceValue;
                    const cancel = new CancelCB();
                    cancel.add(this.undoChanges.bind(this));
                    if (this.slTrade) {
                        this.slTrade.lastValidParentValue = newPrice;
                        cancel.add(this.slTrade.Updated.bind(this.slTrade));
                    }
                    if (this.tpTrade) {
                        this.tpTrade.lastValidParentValue = newPrice;
                        cancel.add(this.tpTrade.Updated.bind(this.tpTrade));
                    }
                    if (this.sllTrade) {
                        this.sllTrade.lastValidParentValue = newPrice;
                        cancel.add(this.sllTrade.Updated.bind(this.sllTrade));
                    }

                    // TODO  implement
                    this.lastValidLimitPriceValue = newPrice;
                    this.terceraChartPanelContext.ChartVisualTrading(
                        this.Order,
                        {
                            price: newPrice,
                            isLimitModify: true,
                            action: TerceraChartTradingOperation.MoveOrder,
                            CancelCallback: cancel.Run.bind(cancel)
                        });
                }
            } else // Stop part, sl/tp moving
            {
                const moveStopPart = mouseDownHover === ToolSelectionType.Image || mouseDownHover === ToolSelectionType.Info; // в этот else попадает не только move stop part а и move sl/tp
                if (moveStopPart && this.Order.IsActivated) {
                    return;
                } // #92963

                OrderToolView.prototype.ProcessMoveFinish.call(this);
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);

            this.ResetMove();
            this.CurrentMovement.IsMovingRightNow = false;
            OrderToolView.prototype.ProcessMoveFinish.call(this);
        }
    }

    public override GetCursor (e): Cursors {
        const curElement = this.CurrentSelection.MouseDownHover === ToolSelectionType.None ? this.CurrentSelection.CurrentHover : this.CurrentSelection.MouseDownHover;

        switch (curElement) {
        case ToolSelectionType.Info:
        case ToolSelectionType.LimitIcon:
        case ToolSelectionType.LimitInfo:
            return this.AllowModify() ? Cursors.NoMoveVert : null;
        case ToolSelectionType.LimitQuantity:
            return Cursors.Hand;
        case ToolSelectionType.SL:
        case ToolSelectionType.TP:
            return this.AllowSLTP() ? (this.errorMode ? Cursors.No : Cursors.NoMoveVert) : null;
        case ToolSelectionType.Remove:
        case ToolSelectionType.Line:
        case ToolSelectionType.None:
        case ToolSelectionType.Trailing:
        default:
            return OrderToolView.prototype.GetCursor.apply(this);
        }
    }

    public override ResetMouse (): void {
        super.ResetMouse();
        this.stopLimitPriceValue = NaN;
        this.stopLimitY = -1;
    }

    public override ProcessClick (e): void {
        if (this.CurrentSelection.MouseDownHover === this.CurrentSelection.CurrentHover) {
            switch (this.CurrentSelection.MouseDownHover) {
            case ToolSelectionType.LimitIcon:
                this.Collapsed = !this.Collapsed;
                break;
            case ToolSelectionType.LimitInfo:
                this.Collapsed = !this.Collapsed;
                break;
            case ToolSelectionType.LimitQuantity:
                if (!TradingLockUtils.TradingLock.tradingLocked) {
                    this.terceraChartPanelContext.ChartVisualTrading(this.Order, { action: TerceraChartTradingOperation.ModifyOrderQuantity, rect: this.limitQuantityRect, quantity: this.Order.Amount });
                }
                break;
            default:
                OrderToolView.prototype.ProcessClick.apply(this, [e]);
                break;
            }
        }
        this.ResetMouse();
    }

    // Stop Price description
    public override DescriptionText (): string {
        const stopPrice = this.Order.Instrument.RoundPriceToNearestPointSize(this.TradeWasModified && !isNaN(this.priceValue) ? this.priceValue : this.Order.StopLimit);
        return this.Order.Instrument.formatPrice(stopPrice);
    }

    public override CheckMinDistanceForStartMoving (x: number, y: number): boolean {
        const mouseDownHover = this.CurrentSelection.MouseDownHover;
        if ((!TradingLockUtils.TradingLock.tradingLocked) && ((mouseDownHover == ToolSelectionType.LimitIcon || mouseDownHover == ToolSelectionType.LimitInfo) && this.AllowModify())) {
            return Math.abs(this.CurrentMovement.PrevY - y) >= this.MinDistanceForStartMoving();
        } else {
            return super.CheckMinDistanceForStartMoving(x, y);
        }
    }

    public QantityRect (): Rectangle {
        return this.limitQuantityRect;
    }

    // Draw tooltip for invisible part of order
    public DrawLinkToOrder (gr, isTop, hoverOrSelected, linkRectangle, orderType, infoStr): void {
        const linkedBrush = hoverOrSelected ? TradingToolViewBase.linkedStyleHoverBrush : TradingToolViewBase.linkedStyleDefaultBrush;
        gr.RenderButton(linkedBrush, Pens.Transparent, linkRectangle);
        let img = TradingToolViewBase.link_out_LMT_default_clip;
        if (orderType === OrderType.Limit) {
            img = hoverOrSelected ? TradingToolViewBase.link_out_LMT_hover_clip : TradingToolViewBase.link_out_LMT_default_clip;
        } else {
            img = hoverOrSelected ? TradingToolViewBase.link_out_STP_hover_clip : TradingToolViewBase.link_out_STP_default_clip;
        }

        gr.drawImage(img, linkRectangle.X + 1, linkRectangle.Y + 1);
        gr.DrawString(infoStr, TradingToolViewBase.tradeFont, Brushes.Black, linkRectangle.X + 57, linkRectangle.Y + 7);
        const center = linkRectangle.X + linkRectangle.Width / 2;
        const y = isTop ? linkRectangle.Y + 1 : linkRectangle.Y + linkRectangle.Height;
        gr.FillPolygon(linkedBrush, [new Point(center - 5, y), new Point(center + 5, y), new Point(center, isTop ? y - 5 : y + 5)]);
    }

    public LimitPriceDescription (): string {
        if (this.errorMode) {
            this.invalidPriceDescr = Resources.getResource('chart.visualTrading.Invalid price');
            return this.invalidPriceDescr;
        } else {
            const limitPrice = this.Order.Instrument.RoundPriceToNearestPointSize(this.TradeWasModified ? this.stopLimitPriceValue : this.Order.Price);
            return this.Order.Instrument.formatPrice(limitPrice);
        }
    }

    public override Localize (): void {
        super.Localize();
        this.invalidPriceDescr = Resources.getResource('chart.visualTrading.Invalid price');
    }

    override get PriceMin (): number | null {
        if (!this.Order) return null;

        return Math.min(this.Order.StopLimit, this.Order.Price);
    }

    override get PriceMax (): number | null {
        if (!this.Order) return null;

        return Math.max(this.Order.StopLimit, this.Order.Price);
    }
}
