// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType, SelectionModeEnum } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty } from '../../DynProperty';

export class HorizontalLineDataCacheTool extends DataCacheTool {
    LeftRay: boolean;

    /**
     * Constructor for HorizontalLineDataCacheTool
     */
    constructor () {
        super();
        this.LeftRay = false;
        this.ToolType = DataCacheToolType.HorizontalLine;
        this.InitPoints(1);
    }

    /**
     * Initializes the block for HorizontalLineDataCacheTool
     */
    InitBlock (): void {
        super.InitBlock();
        this.SelectionMode = SelectionModeEnum.Y;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 1;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 1;
    }

    /**
     * Gets the properties of the tool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();
        properties.push(new DynProperty('HorizontalLine.LeftRay', this.LeftRay, DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP));
        return properties;
    }

    /**
     * Callback function for handling property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);
        const prop: DynProperty | undefined = DynProperty.getPropertyByName(properties, 'HorizontalLine.LeftRay');
        if (prop != null) this.LeftRay = prop.value;
    }
}
