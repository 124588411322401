// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../DataCache';
import { Resources } from '../../../Localizations/Resources';
import { type Exchange } from '../charting_library';

export class _TvExchangesManger {
    public readonly AllExchanges = 'all';

    public getExchanges (): Exchange[] {
        const exchanges = DataCache.NonFixedList
            ? DataCache.EntitlementManager.GetSubscribedExchanges()
            : DataCache.TradingExchanges;
        if (DataCache.NonFixedList) {
            return [];
        }

        exchanges.sort((a, b) => a.localeCompare(b));

        const tvExchanges: Exchange[] = [];
        const allExchange: Exchange = {
            name: Resources.getResource('ExchangeId.All'),
            value: this.AllExchanges,
            desc: ''
        };
        tvExchanges.push(allExchange);

        for (const exchange of exchanges) {
            const tvExchange: Exchange = {
                value: exchange,
                name: exchange,
                desc: ''// exchange
            };
            tvExchanges.push(tvExchange);
        }

        return tvExchanges;
    }
}

export const TvExchangesManger = new _TvExchangesManger();
