// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class ArrayUtils {
    public static RemoveElementFromArray<T>(array: T[], element: T): boolean {
        const remIndex = array.indexOf(element);
        if (remIndex !== -1) {
            array.splice(remIndex, 1);
            return true;
        }
        return false;
    }

    public static HasUniformValue<T> (array: T[]): boolean // checking if all elements of the array are equal to each other
    {
        return array.every((value, index, arr) => value === arr[0]); // all are equal to the first element -> all are equal to each other
    }
}
