// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TradingToolViewBase } from '../../Chart/Tools/TradingTools/TradingToolViewBase';
import { TerceraChartTradingOperation } from '../../Chart/Utils/ChartConstants';
import { OperationType } from '../Trading/OperationType';
import { TradingViewBaseTool } from './TradingViewBaseTool';

export class TradingViewPositionTool extends TradingViewBaseTool {
    constructor () { super(); }

    public override getType (): string { return 'TradingViewPositionTool'; }

    public override id (): any {
        const position = this.get('dataObject');
        return position ? position.PositionId : null;
    }

    public override getPrice (): number | null {
        const position = this.get('dataObject');
        return position ? position.Price : null;
    }

    public override oninit (): void {
        super.oninit();

        this.observe('hovered', this.onhovered);
        this.observe('dataObject', this.updateToolImage);

        this.on('handleMainImageClick handleActionImageClick', (e) => { void this.set('uncollapsed', !this.get('uncollapsed')); });
        this.on('handleCancelImageClick', this.onCancelBtnClick);
    }

    public onhovered (hovered): void {
        this.updateToolImage();
    }

    public updateToolImage (): void {
        const hovered = this.get('hovered');
        const pos = this.get('dataObject');
        if (!pos) return;

        const isBuy = pos.BuySell === OperationType.Buy;

        let imgPropName = isBuy ? 'buy' : 'sell';
        imgPropName += '_open_position_'; // todo
        imgPropName += (hovered ? 'hover' : 'default') + 'Image';

        const backgroundBrush = isBuy ? (hovered ? TradingToolViewBase.buyBackgroundActiveBrush : TradingToolViewBase.buyBackgroundBrush) : (hovered ? TradingToolViewBase.sellBackgroundActiveBrush : TradingToolViewBase.sellBackgroundBrush);
        const backgroundPen = isBuy ? (hovered ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.buyBackgroundPen) : (hovered ? TradingToolViewBase.sellBackgroundActivePen : TradingToolViewBase.sellBackgroundPen);

        const actionPnLText = this.getActionImgText();
        const cancelImg = this.getCancelImage(isBuy);

        if (TradingToolViewBase[imgPropName]) {
            void this.set({
                mainImg: TradingToolViewBase[imgPropName],
                mainSrc: TradingToolViewBase[imgPropName].src,
                // actionImg: actionButtonImg,
                // actionSrc: actionButtonImg.src,
                cancelImg,
                cancelSrc: cancelImg.src,

                actionSectionText: actionPnLText,

                pricePlateColor: backgroundBrush.Color,
                priceLineColor: backgroundPen.Color
            });
        }
    }

    public getCancelImage (isBuy): any {
        const hovered = this.get('hovered');
        const dragging = this.get('dragging');

        const cancelButtonImgHovered = this.get('cancelImageHovered');

        let cancelImgName = null;
        if (dragging) {
            cancelImgName = 'moveOrder_cancelImage';
        } else {
            if (cancelButtonImgHovered) {
                cancelImgName = isBuy ? 'buy_cancel_mine_hoverImage' : 'sell_cancel_mine_hoverImage';
            } else {
                cancelImgName = isBuy ? (hovered ? 'buy_cancel_hoverImage' : 'buy_cancel_defaultImage') : (hovered ? 'sell_cancel_hoverImage' : 'sell_cancel_defaultImage');
            }
        }

        // if (threeDotsMenuVisible)        // #101864
        //     cancelImgName += '_full'   // если есть меню с тремя точками, то кнопка закрытия согласно макету должна стоять чуть отчужденно и тогда рисоваться она должна без обрезанного левого края -> другая картинка

        const cancelOrderImg = TradingToolViewBase[cancelImgName];
        return cancelOrderImg;
    }

    public getActionImgText (): string {
        const pos = this.get('dataObject');
        if (!pos) return;

        const profitValue = pos.getNetPnL(true);
        return pos.Account.formatPrice(profitValue);
    }

    public onCancelBtnClick (): void {
        const core = this.get('chartTradingCoreRef');
        const pos = this.get('dataObject');

        if (core && pos) {
            core.ChartVisualTrading(pos, { action: TerceraChartTradingOperation.ClosePosition });
        }
    }
}

TradingViewBaseTool.extendWith(TradingViewPositionTool, {
    data: function () {
        return {
            className: 'tradingView-position-tool',

            collapseByClick: true,
            uncollapsed: true,

            movable: false,

            mainCursor: 'pointer',
            actionCursor: 'pointer',
            closeCursor: 'pointer',

            height: TradingToolViewBase.buy_open_position_defaultImage.height
        };
    }
    // sell_open_position_defaultImage: TradingToolViewBase.sell_open_position_defaultImage,
    // sell_open_position_hoverImage: TradingToolViewBase.sell_open_position_hoverImage,
    // sell_close_position_defaultImage: TradingToolViewBase.sell_close_position_defaultImage,
    // sell_close_position_hoverImage: TradingToolViewBase.sell_close_position_hoverImage,

    // buy_open_position_defaultImage: TradingToolViewBase.buy_open_position_defaultImage,
    // buy_open_position_hoverImage: TradingToolViewBase.buy_open_position_hoverImage,
    // buy_close_position_defaultImage: TradingToolViewBase.buy_close_position_defaultImage,
    // buy_close_position_hoverImage: TradingToolViewBase.buy_close_position_hoverImage,

    // gray_open_positionImage: TradingToolViewBase.gray_open_positionImage,
    // gray_close_positionImage: TradingToolViewBase.gray_close_positionImage,
});
