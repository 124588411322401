// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class EntitlementCategoryProduct {
    public Name: any;
    public Array = [];
    public startIndex = 0;
    public endIndex = 0;
    public availableWidth = 0;
    public currentStep = 0;
    public VisibleArray = [];
    public IsHide = true; // is visible due to collapsed

    constructor (name) {
        this.Name = name;
    }

    public AddProduct (prod): void {
        this.Array.push(prod);
        if (this.currentStep && this.currentStep < this.Array.length) {
            this.VisibleArray = this.Array.slice(this.startIndex, this.endIndex);
        } else {
            this.VisibleArray = this.Array;
            this.endIndex = this.VisibleArray.length;
        }
    }

    public RemoveProduct (prod): void {
        const index = this.Array.indexOf(prod);
        this.Array.splice(index, 1);
        if (this.currentStep && this.currentStep < this.Array.length) {
            this.VisibleArray = this.Array.slice(this.startIndex, this.endIndex);
        } else {
            this.startIndex = 0;
            this.VisibleArray = this.Array;
            this.endIndex = this.VisibleArray.length;
        }
    }

    public SetAvaliableWidth (width): void {
        this.availableWidth = width;
        this.currentStep = Math.trunc(width / EntitlementCategoryProduct.ONE_CARD_WIDTH);
        // magic math

        if (this.currentStep >= this.Array.length) {
            this.startIndex = 0;
        }

        this.endIndex = this.startIndex + this.currentStep;
        if (this.endIndex > this.Array.length) {
            this.endIndex = this.Array.length;
            this.startIndex = this.endIndex - this.currentStep;
            if (this.startIndex < 0) {
                this.startIndex = 0;
            }
        }

        if (this.currentStep < this.Array.length) {
            this.VisibleArray = this.Array.slice(this.startIndex, this.endIndex);
        } else {
            this.VisibleArray = this.Array;
        }
    }

    public static ONE_CARD_WIDTH = 120;

    public IsExists (prod): boolean {
        const index = this.Array.indexOf(prod);
        return index !== -1;
    }

    public IsEmpty (prod): boolean {
        return this.Array.length === 0;
    }

    public HasCategoryName (): boolean {
        return this.Name !== NO_NAME_CATEGORY;
    }

    public LeftArrowClick (): void {
        this.startIndex -= this.currentStep;
        if (this.startIndex < 0) {
            this.startIndex = 0;
        }

        this.endIndex = this.startIndex + this.currentStep;

        this.VisibleArray = this.Array.slice(this.startIndex, this.endIndex);
    }

    public RightArrowClick (): void {
        this.startIndex += this.currentStep;

        this.endIndex = this.startIndex + this.currentStep;

        this.VisibleArray = this.Array.slice(this.startIndex, this.endIndex);
    }
}

export const NO_NAME_CATEGORY = '0'; // 123 отображается вьіше... "!NO_NAME_CATEGORY" : дело не в сортировке а в представлении обьекта в js даже в случае алфавитного порядка добавления, поля с числовыми ключами оказываются раньше остальных
