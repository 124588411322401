// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectAccountOperationMessage extends DirectMessageBaseClass {
    public OperationID: string;
    public OperationType: number;
    public CounterID: string;
    public AccountID: string;
    public Amount: number;
    public Balance: number;
    public CreatedAt: Date | null;
    public SettlementDate: Date | null;
    public Currency: string;
    public Comment: string;
    public InstrumentName: string;
    public InstrumentTradableId: number;
    public Quantity: number;
    public OpenPrice: number;
    public ClosePrice: number;
    public IsBuy: boolean;
    public IsRemoved: boolean;

    constructor () {
        super(Message.CODE_ACCOUNOPERATION_MESSAGE);
    }
}
