// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// начала  this.Code = код ; а потом PFixMessage.call(this, fieldSet);
import { FieldsFactory } from '../Factory/FieldsFactory';
import { PFixFieldSet } from '../Fields/FieldsImport';

export class PFixMessage {
    public static sequance = 0;

    public FieldSet: PFixFieldSet;
    public Code: number;

    constructor (messageCode: number, fieldSet?: PFixFieldSet | null) {
        if (fieldSet && fieldSet !== null) {
            this.FieldSet = fieldSet;
        } else {
            this.FieldSet = new PFixFieldSet();
        }

        this.Code = messageCode;

        this.FieldSet.SetField(FieldsFactory.FIELD_MSG_TYPE, this.Code);
        this.setSequance();
    }

    public getFieldValue (field): any {
        return this.FieldSet.GetValue(field);
    }

    public setFieldValue (field, value): void {
        this.FieldSet.SetField(field, value);
    }

    public getGroups (group): any[] {
        return this.FieldSet.GetGroups(group);
    }

    public addGroup (group): void {
        this.FieldSet.AddGroup(group);
    }

    public getSequance (): number {
        return this.FieldSet.GetValue(FieldsFactory.FIELD_SEQUANCE_ID);
    }

    public setSequance (): void {
        this.FieldSet.SetField(FieldsFactory.FIELD_SEQUANCE_ID, PFixMessage.sequance++);
    }

    public setRequestId (p): void {
        this.setFieldValue(FieldsFactory.FIELD_REQUEST_ID, p);
    }

    public getRequestId (): number {
        return this.FieldSet.GetValue(FieldsFactory.FIELD_REQUEST_ID);
    }
}
