// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { AdditionalProperty, LOCALE_EN, Resources } from '@shared/localizations/Resources';
import { type DirectMarginCallMessage, DirectReportMessage, Message, type DirectAccountStatusMessage, type DirectOpenOrderMessage, type DirectAccountOperationMessage } from '@shared/utils/DirectMessages/DirectMessagesImport';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { ORDER_PARAMETER_PRODUCT_TYPE, ProductType, getProductTypeKey } from '@shared/utils/Instruments/ProductType';
import { generateReportMessageHandler } from '@shared/utils/AppHandlers';
import { StopOutTypeMap } from '@shared/utils/Instruments/StopOutType';
import { CloseAccountResponseStatus, ReportMessageImportanceLevel, ReportMessageSource, SlTpPriceType } from '@shared/utils/Enums/Constants';
import { OrderTif, OrderTifMap } from '@shared/utils/Trading/OrderTifEnum';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { OrderStatus } from '@shared/utils/Trading/OrderStatus';
import { OrderExecutionType } from '@shared/utils/Trading/OrderExecutionType';
import { AccountTradeStatus } from '@shared/utils/Account/AccountTradeStatus';
import { AccountType } from '@shared/utils/Account/AccountType';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { OrderUtils } from '@shared/utils/Trading/OrderUtils';
import { SLTPTrigger } from '@shared/utils/SlTpTrigger';
import { OffsetModeViewEnum } from '@shared/utils/Trading/OffsetModeViewEnum';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { MathUtils } from '@shared/utils/MathUtils';
import { ApplicationInfo } from './ApplicationInfo';
import { AccountOperations } from './AccountOperations';
import { BusinessRejectMessagesKey, BusinessRejectTextCodeDict } from './BusinessRejectUtils/BusinessRejectUtils';
import { DataCacheHolder } from './cache/DataCacheHolder';
import { TooltipType } from '@shared/utils/ReportMessageTooltipTypeEnum';
import { SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { StopTradingReason } from '@shared/utils/Account/StopTradingReason';

export class GenerateReportMessages {
// #region GenerateReportMessage

    static get DataCache (): any {
        return DataCacheHolder.getDataCache();
    }

    public static GenerateReportMessage (msg): DirectReportMessage[] | DirectReportMessage {
        switch (msg.Code) {
        case Message.CODE_OPENORDER:
            return GenerateReportMessages.GenerateReportMessageFromOpenOrderMessage(msg);
        case Message.CODE_ACCOUNTSTATUS:
            return GenerateReportMessages.GenerateReportMessageFromAccountStatusMessage(msg);
        case Message.CODE_ACCOUNOPERATION_MESSAGE:
            return GenerateReportMessages.GenerateReportMessageFromAccountOperationMessage(msg);
        case Message.CODE_MARGINCALL:
            return GenerateReportMessages.GenerateReportMessageProcessMarginCallMessage(msg);
        case Message.CODE_PFIX_CLOSE_ACCOUNT_RESPONSE_MESSAGE:
            GenerateReportMessages.GenerateCloseAccountDealticket(msg);
        }
    }

    public static Initialize (): any {
    // empty function for loading report messages in WebMobile
    // Reac didn't load empty references
    }

    // marginCallMessage message tooltip
    public static GenerateReportMessageProcessMarginCallMessage (message: DirectMarginCallMessage): DirectReportMessage {
        if (!GenerateReportMessages.DataCache.Loaded) {
            return null;
        }

        const asset = GenerateReportMessages.DataCache.GetAssetById(message.AssetId);
        const acc = GenerateReportMessages.DataCache.GetAccountByNumber(message.UserId); // вспомогательное

        //
        // все возможные лейбы
        //
        const user = { Label: AdditionalProperty + '.User', Value: (acc !== null ? acc.userLogin : null) };
        const account = { Label: AdditionalProperty + '.Account', Value: (acc !== null ? acc.FullAccString : null) };
        const Balance = { Label: AdditionalProperty + '.Projected balance', Value: asset.formatPrice(message.Balance) };
        const UsedMargin = { Label: AdditionalProperty + '.Margin used', Value: asset.formatPrice(message.UsedMargin) };
        const CreatedAt = { Label: AdditionalProperty + '.Time', Value: DateTimeUtils.formatDate(new Date(), 'DD.MM.YYYY HH:mm:ss') };
        const Message = { Label: AdditionalProperty + '.Message', Value: Resources.getResource(AdditionalProperty + '.MarginCallMessage.Message') };
        const StopOutType = { Label: AdditionalProperty + '.StopOutType', Value: Resources.getResource(AdditionalProperty + '.' + StopOutTypeMap[message.StopOutType]) }; // #41858
        const MarginCallReachedMessage = { Label: AdditionalProperty + '.Message', Value: Resources.getResource(AdditionalProperty + '.MarginCallReachedMessage.Message') };

        let selectedLabels = [];
        selectedLabels = [user, account, Balance, UsedMargin, CreatedAt];

        //
        // определяем хэдэр и параметры для выбраного типа ордера
        //
        let header = null;
        if (message.IsMarginCall) {
            header = Resources.getResource(AdditionalProperty + '.MarginCallMessage.header.MarginWarning');
            selectedLabels.push(Message);
        } else {
            header = Resources.getResource(AdditionalProperty + '.MarginCallMessage.header.MarginCallReached');
            selectedLabels.push(StopOutType);

            if (!Resources.isHidden(MarginCallReachedMessage.Value)) {
                selectedLabels.push(MarginCallReachedMessage);
            }
        }

        //
        // переводим в формат Data = string[][]
        //
        const listData = [];
        for (let i = 0, len = selectedLabels.length; i < len; i++) {
            const lb = selectedLabels[i];
            if (lb.Value) {
                listData.push([lb.Label, lb.Value]);
            }
        }

        //
        // Create & return
        //
        const reportMessage = new DirectReportMessage(header, listData);
        reportMessage.XMLText = '1';
        reportMessage.ImportanceLevel = ReportMessageImportanceLevel.Critical;
        reportMessage.source = ReportMessageSource.MarginCallMessage;

        return reportMessage;
    }

    public static GenerateReportMessageFromAccountOperationMessage (message: DirectAccountOperationMessage): DirectReportMessage {
        if (!GenerateReportMessages.DataCache.Loaded) {
            return null;
        }
        //
        // все возможные лейбы
        //

        const a = GenerateReportMessages.DataCache.GetAccountById(message.AccountID); // вспомогательное
        const asset = a == null || a.AccountType == AccountType.MultiAsset || a.assetBalanceDefault == null ? GenerateReportMessages.DataCache.GetAssetByName(message.Currency) : a.assetBalanceDefault.Asset;
        const user = { Label: 'User', Value: (a != null ? a.userLogin : null) };
        const account = { Label: 'Account', Value: a != null ? a.FullAccString : message.AccountID };
        const OperationID = { Label: 'OperationID', Value: message.OperationID };
        const Amount = { Label: 'AccountOperationAmount', Value: message.Amount !== null ? (asset != null ? asset.formatPrice(message.Amount) : message.Amount.toFixed(2) + ' ' + message.Currency) : '' };
        const Balance = { Label: 'Balance', Value: message.Balance !== null && message.Balance != 0 ? message.Balance.toString() + ' ' + message.Currency : '' };
        const CreatedAt = { Label: 'Time', Value: message.CreatedAt !== null ? (/* message.CreatedAt != DateTime.MinValue ? Utils.getJavaTime( */message.CreatedAt.getTime().toString()/* .Ticks).toString() : null */) : '' };
        const Comment = { Label: 'Comment', Value: message.Comment };

        let selectedLabels = [];

        //
        // определяем хэдэр и параметры для выбраного типа ордера
        //
        let header = null;

        let isImportant = false;
        const operType = message.OperationType;
        try {
            const operTypeStr = AccountOperations.TypeNames[operType];

            // #34066
            if (operType == AccountOperations.Type.Adjustment ||
            operType == AccountOperations.Type.Spread) {
                return null;
            }

            if (operType == AccountOperations.Type.Blocking ||
            operType == AccountOperations.Type.Deposit ||
            operType == AccountOperations.Type.Transfer ||
            operType == AccountOperations.Type.Withdraw ||
            operType == AccountOperations.Type.Unblocking) {
                isImportant = true;
            }

            if (message.IsRemoved) {
                header = Resources.getResource('reports.Adjustment');
            } else {
                header = Resources.getResource('reports.' + operTypeStr.toString());
            }
        } catch (ex) {
            header = 'Unknown account operation type #' + operType;
        }

        const OperationType = { Label: 'Operation type', Value: header };

        selectedLabels = [user, account, OperationID, OperationType, Amount, Balance, CreatedAt, Comment];

        const resLabelArr = [];
        for (let i = 0, len = selectedLabels.length; i < len; i++) {
            const lb = selectedLabels[i];
            if (lb.Value) {
                resLabelArr.push([lb.Label, lb.Value]);
            }
        }

        const reportMessage = new DirectReportMessage(header, resLabelArr, message);
        reportMessage.XMLText = '1';
        reportMessage.ImportanceLevel = isImportant ? ReportMessageImportanceLevel.Important : ReportMessageImportanceLevel.Normal;
        reportMessage.source = ReportMessageSource.AccountOperationMessage;

        return reportMessage;
    }

    public static GenerateReportMessageFromAccountStatusMessage (msg: DirectAccountStatusMessage): DirectReportMessage {
    // #74018 - аккаунт удален, нет необходимости показывать сообщения
        if (msg.Status === AccountTradeStatus.SUSPEND_STATUS) {
            return null;
        }

        let key = null;
        switch (msg.StopTradingReason) {
        case StopTradingReason.WEEKLY_LOST_LIMIT:
            key = BusinessRejectMessagesKey.WeeklyLimit;
            break;
        case StopTradingReason.TRAILING_DRAWDOWN_LIMIT:
            key = BusinessRejectMessagesKey.MaxTrailingLimit;
            break;
        case StopTradingReason.MAX_LOSS:
            key = BusinessRejectMessagesKey.DailyLimit;
            break;
        case StopTradingReason.MAX_UNREALIZED_LOSS:
            key = BusinessRejectMessagesKey.MaxUnrealizedLossLimit;
            break;
        case StopTradingReason.MAX_DAILY_PROFIT:
            key = BusinessRejectMessagesKey.MaxDailyProfit;
            break;
        case StopTradingReason.MAX_ORDERS_COUNT_PER_DAY:
            key = BusinessRejectMessagesKey.MaxOrdersCountPerDay;
            break;
        case StopTradingReason.RELATIVE_DRAWDOWN_LEVEL:
            key = BusinessRejectMessagesKey.MaxRelativeDrawDownLevel;
            break;
        case StopTradingReason.EOD_TRAILING_DRAWDOWN:
            key = BusinessRejectMessagesKey.EODTrailingDrawDownLevel;
            break;
        case StopTradingReason.RELATIVE_DAILY_LOSS_LIMIT:
            key = BusinessRejectMessagesKey.RelativeDailyLossLimit;
            break;
        }

        const reasonMessage = Resources.getResource(key || '') || '';
        if (!reasonMessage) return null;

        // TODO. Ugly.
        const a = GenerateReportMessages.DataCache.GetAccountById(msg.BstrAccount);
        const user = { Label: 'User', Value: a ? a.userLogin : null };
        const account = { Label: 'Account', Value: a ? a.FullAccString : null };
        const message = { Label: 'Message', Value: reasonMessage };

        const selectedLabels = [user, account, message];
        const header = 'Trading disabled by risk rules';

        const resLabelArr = [];
        for (let i = 0, len = selectedLabels.length; i < len; i++) {
            const lb = selectedLabels[i];
            if (lb.Value) {
                resLabelArr.push([lb.Label, lb.Value]);
            }
        }

        const code = key !== null ? BusinessRejectTextCodeDict[key] : -1;
        const resMsg = new DirectReportMessage(header, resLabelArr);
        resMsg.BusinessRejectCode = code;
        resMsg.source = ReportMessageSource.AccountStatusMessage;
        return resMsg;
    }

    public static GenerateReportMessageFromOpenOrderMessage (oom: DirectOpenOrderMessage): DirectReportMessage[] {
        const dc = GenerateReportMessages.DataCache;
        if (!dc.Loaded && !ApplicationInfo.isExploreMode) return [];

        const OrderTypeEnum = OrderType;

        const orderType = oom.OrderType;

        let needSkip = false;

        const isClosingOrder = !oom.IsOpen || oom.OrderStatus === OrderStatus.CANCELED;
        const isSLTP =
        isClosingOrder && (
            orderType === OrderTypeEnum.Limit ||
            orderType === OrderTypeEnum.Stop ||
            orderType === OrderTypeEnum.TrailingStop
        );

        let ordTypeStr = Resources.getResourceLang(
            'property.' + OrderUtils.getOrderTypeLocalizationKey(orderType), LOCALE_EN);

        // TODO. Ugly conditions.
        if (orderType === OrderTypeEnum.Limit &&
        isClosingOrder &&
        (oom.BoundToOrderId !== '-1' || oom.PositionId !== '-1')) {
            ordTypeStr = 'TP';
        } else if (
            orderType === OrderTypeEnum.Stop &&
        isClosingOrder &&
        (oom.BoundToOrderId !== '-1' || oom.PositionId !== '-1')) {
            ordTypeStr = 'SL';
        } else if (
            orderType === OrderTypeEnum.TrailingStop &&
        isClosingOrder &&
        (oom.BoundToOrderId !== '-1' || oom.PositionId !== '-1')) {
            ordTypeStr = 'Tr. stop loss';
        }

        const ins = dc.getInstrumentByTradable_ID(oom.InstrumentTradableID, oom.Route);
        if (!ins) {
            oom.CPHFD = GenerateReportMessages.GenerateReportMessageFromOpenOrderMessage;
            dc.AddDeferredMessage(oom);
            return [];
        }
        const acc = dc.GetAccountById(oom.Account);

        const fullOrPartFilledOrderStatus = oom.OrderStatus === OrderStatus.FILLED || oom.OrderStatus === OrderStatus.PART_FILLED;
        const volumeFormatted = !MathUtils.IsNullOrUndefined(oom.LastAmount) && fullOrPartFilledOrderStatus
            ? oom.LastAmount.toString()
            : oom.Quantity.toString();

        // const refusedVolume = 0;

        const user = { Label: 'User', Value: acc ? acc.userLogin : null };
        const account = { Label: 'Account', Value: acc ? acc.FullAccString : null };
        const orderCreatedBy = { Label: 'Created by', Value: oom.OrderCreatedByUser }; // modifyed by
        const amount = { Label: 'Amount', Value: volumeFormatted };
        // const filledAmount = { Label: 'Amount filled', Value: volumeFormatted };
        // const refusedAmount = { Label: 'Refused Amount', Value: refusedVolume.toString() };
        const operation = { Label: 'Operation', Value: OrderUtils.getBuySellStr(oom.BuySell) };
        const orderId = { Label: 'Order number', Value: oom.OrderNumber };
        const instrument = { Label: 'Instrument', Value: ins ? ins.DisplayShortName() : oom.Symbol };
        const instrType = { Label: 'Symbol type', Value: ins ? InstrumentUtils.getInstrumentTypeStringLocalized(ins.InstrType) : '' };
        const tradingExchange = { Label: 'Trading exchange', Value: ins ? ins.TradingExchange : '' };

        let tiff = OrderTifMap[oom.TimeInForce];
        if (oom.TimeInForce === OrderTif.GTD) {
            const format = dc.isAllowedForMyUser(RulesSet.FUNCTION_SHOW_TIME_FOR_GTD) ? 'MM.DD.YYYY HH:mm' : 'MM.DD.YYYY'; // нужен именно формат с месяцами на первом месте для корректного парсинга в ReportMessageTooltip.fixEntries:707 #98285
            const dtString = DateTimeUtils.formatDate(oom.ExpireTime, format);

            tiff += ' ' + dtString;
        }

        let productType = null;
        if (oom.ProductType !== ProductType.General) {
            productType = { Label: ORDER_PARAMETER_PRODUCT_TYPE, Value: getProductTypeKey(oom.ProductType) };
        }

        const route = { Label: 'Route', Value: ''/* dc.getRouteById(oom.Route).Name */ }; // #85093

        const price = {
            Label: GenerateReportMessages.IsStopPrice(orderType, oom.OrderStatus) ? 'Stop price' : 'Price',
            Value: oom.Price && !isNaN(oom.Price)
                ? (ins && !oom.OrderWasManual ? ins.formatPrice(oom.Price) : oom.Price.toString(/* TODO."0.################" */))
                : null
        };

        const leverage = { Label: 'Leverage', Value: OrderUtils.GetFormattedLeverage(oom.Leverage) }; // #112886

        const stopPrice = { Label: 'Stop price', Value: oom.StopLimit > 0 ? (ins ? ins.formatPrice(oom.StopLimit) : oom.StopLimit) : null };
        const time = {
            Label: 'Time',
            Value: oom.LastUpdateTime === null ? null : oom.LastUpdateTime.getTime().toString()
        };
        const labOrderType = { Label: 'Type', Value: ordTypeStr };
        const tif = { Label: 'TIF', Value: tiff };
        const boundToOrder = {
            Label: 'Bound to order',
            Value: oom.BoundToOrderId !== '-1' ? oom.BoundToOrderId : (oom.PositionId !== '-1' ? oom.PositionId : null)
        };

        const lastPrice = { Label: 'Last fill price', Value: oom.LastPrice === null ? null : (ins ? ins.formatPrice(oom.LastPrice) : oom.LastPrice) };
        const orderGroupId = {
            Label: 'Order groupID',
            Value: oom.OrderGroupId !== -1 ? oom.OrderGroupId.toString() : null
        };
        const positionId = { Label: 'Position ID', Value: oom.PositionId };

        const comment = { Label: 'Comment', Value: oom.Comment };
        const lackOfMarginKey = 'AdditionalProperty.LackOfMargin'; let lackOfMargin;
        const assetBalance = dc.GetAssetBalanceCorrect(acc, ins, !oom.BuySell);
        if (!Resources.isHidden(lackOfMarginKey)) {
            lackOfMargin = { Label: lackOfMarginKey, Value: oom.LackOfMargin ? assetBalance.formatPrice(oom.LackOfMargin) : null };
        }

        const ass =
        acc &&
            acc.AccountType !== AccountType.MultiAsset &&
            acc.assetBalanceDefault
            ? acc.assetBalanceDefault.Asset
            : (ins?.Exp2 ? dc.GetAssetByName(ins.Exp2) : null);

        const initReq =
        {
            Label: 'Init. margin req.',
            Value: oom.InitMargin === 0
                ? null
                : (ass ? ass.formatPrice(oom.InitMargin) : oom.InitMargin.toString())
        };

        const clientPanelId =
        {
            Label: 'Send from',
            Value: Resources.getResource('reports.' + PlacedFrom[oom.ClientPanelId])
        };

        // для нестандартных параметров прокидываем дополнительное поле
        const orderComments = { Label: 'Comments', Value: '' };
        // TODO.
        if (oom.AdvancedOrderTypeParameters) { /* TODO */ }

        let slLabel = null;
        let sllLabel = null;
        let slTrigger = null;
        if (!isNaN(oom.StopLossPriceValue)) {
            if (oom.StopLossPriceType === SlTpPriceType.Absolute) {
                slLabel = {
                    Label: 'SL price',
                    Value:
                    ins
                        ? ins.formatPrice(oom.StopLossPriceValue)
                        : oom.StopLossPriceValue.toString()
                };
            } else {
                const sl = oom.StopLossPriceValue;
                const visualOffsetType = SlTpUtils.getVisualOffsetType(ins, oom.StopLossPriceType);
                const visualOffset = SlTpUtils.toVisualValue(sl, ins, visualOffsetType);
                const visualOffsetStr = SlTpUtils.formatSLTPValue(visualOffset, ins, visualOffsetType);
                const offsetDescr = SlTpUtils.getVisualOffsetTypeReportText(visualOffsetType);
                slLabel = {
                    Label: oom.StopLossPriceType === SlTpPriceType.TrOffset ? 'Tr. SL offset' : 'SL offset',
                    Value: `${visualOffsetStr}${offsetDescr}`
                };
            }

            if (oom.StopLossLimit != null) {
                const sll = oom.StopLossLimit;
                if (oom.StopLossPriceType === SlTpPriceType.Absolute) {
                    sllLabel = {
                        Label: 'SL limit price',
                        Value: ins ? ins.formatPrice(sll).toString() : sll.toString()
                    };
                } else {
                    const visualOffsetType = SlTpUtils.getVisualOffsetType(ins, oom.StopLossPriceType);
                    const visualOffset = SlTpUtils.toVisualValue(sll, ins, visualOffsetType);
                    const visualOffsetStr = SlTpUtils.formatSLTPValue(visualOffset, ins, visualOffsetType);
                    const offsetDescr = SlTpUtils.getVisualOffsetTypeReportText(visualOffsetType);
                    sllLabel = {
                        Label: 'SL limit offset',
                        Value: `${visualOffsetStr}${offsetDescr}`
                    };
                }
            }

            slTrigger = {
                Label: 'SL trigger',
                Value: SLTPTrigger.ConvertBidAskRawValueToStr(oom.TriggerSL || oom.TriggerSLTP)
            };
        }

        let tpLabel = null;
        let tpTrigger = null;
        if (!isNaN(oom.TakeProfitPriceValue)) {
            if (oom.TakeProfitPriceType === SlTpPriceType.Absolute) {
                tpLabel = {
                    Label: 'TP price',
                    Value:
                    ins
                        ? ins.formatPrice(oom.TakeProfitPriceValue)
                        : oom.TakeProfitPriceValue.toString()
                };
            } else {
                const tpPriceValue = oom.TakeProfitPriceValue;
                const visualOffsetType = SlTpUtils.getVisualOffsetType(ins, oom.TakeProfitPriceType);
                const visualOffset = SlTpUtils.toVisualValue(tpPriceValue, ins, visualOffsetType);
                const visualOffsetStr = SlTpUtils.formatSLTPValue(visualOffset, ins, visualOffsetType);
                const offsetDescr = SlTpUtils.getVisualOffsetTypeReportText(visualOffsetType);
                tpLabel = {
                    Label: 'TP offset',
                    Value: `${visualOffsetStr}${offsetDescr}`
                };
            }

            tpTrigger = {
                Label: 'TP trigger',
                Value: SLTPTrigger.ConvertBidAskRawValueToStr(oom.TriggerTP || oom.TriggerSLTP)
            };
        }

        // определяем хэдэр и параметры для выбраного типа ордера
        let header = null;
        const addPartialReject = false;
        let needToReturnMessage = true;
        let selectedLabels = [];
        // TODO. Add missing statuses.

        const executionType = oom.ExecutionType
            ? oom.ExecutionType
            : (oom.OrderStatus ? OrderUtils.ConvertOrderStatusToExecutionType(oom.OrderStatus) : OrderExecutionType.UNKNOWN);

        switch (executionType) {
        case OrderExecutionType.PENDING_NEW: {
            header = isClosingOrder && !isSLTP
                ? 'Close ' + orderType + ' order received by broker'
                : orderType + ' order received by broker';

            selectedLabels = [
                user, account, orderId, boundToOrder, orderGroupId,
                instrument, instrType, tradingExchange, route, amount, orderCreatedBy, operation,
                labOrderType, tif, productType, price, stopPrice,
                lastPrice, leverage, orderComments, time, clientPanelId
            ];

            needToReturnMessage = false;
            break;
        }
        case OrderExecutionType.PENDING_EXECUTION: {
            header = 'Order received by STP';

            selectedLabels = [
                user, account, orderId, boundToOrder, orderGroupId,
                instrument, instrType, tradingExchange, route, amount, orderCreatedBy, operation,
                labOrderType, tif, productType, price, stopPrice,
                lastPrice, leverage, orderComments, time, clientPanelId
            ];

            needToReturnMessage = false;
            break;
        }
        case OrderExecutionType.PENDING_CANCEL: {
            header = 'Cancel request received by STP';

            selectedLabels = [
                user, account, orderId, boundToOrder, orderGroupId,
                instrument, instrType, tradingExchange, route, amount, orderCreatedBy, operation,
                labOrderType, tif, productType, price, stopPrice,
                lastPrice, leverage, orderComments, time, clientPanelId
            ];

            needToReturnMessage = false;
            break;
        }
        case OrderExecutionType.PENDING_REPLACE: {
            header = 'Replace request received by STP';
            selectedLabels = [
                user, account, orderId, boundToOrder, orderGroupId,
                instrument, instrType, tradingExchange, route, amount, orderCreatedBy, operation,
                labOrderType, tif, productType, price, stopPrice,
                lastPrice, leverage, orderComments, time, clientPanelId
            ];
            break;
        }
        case OrderExecutionType.NEW:
        {
            header = (isClosingOrder && !isSLTP ? 'Close ' : '') +
                    ordTypeStr +
                    ' order created';

            needSkip = orderType === OrderTypeEnum.Market;

            if (!needSkip) {
                selectedLabels = [
                    user, account, orderId, boundToOrder,
                    orderGroupId, instrument, instrType, tradingExchange, route, amount,
                    orderCreatedBy, operation, labOrderType, tif,
                    productType, price, leverage, stopPrice, lastPrice,
                    slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, orderComments, time, clientPanelId
                ];
            } else {
                selectedLabels = [
                    user, account, orderId, boundToOrder,
                    orderGroupId, instrument, instrType, tradingExchange, route, amount,
                    orderCreatedBy, operation, labOrderType, tif,
                    productType, stopPrice, lastPrice, leverage,
                    slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, orderComments, time, clientPanelId
                ];
            }
            break;
        }
        case OrderExecutionType.ACTIVATED:
        {
            header = ordTypeStr + ' order activated';

            selectedLabels = [
                user, account, orderId, boundToOrder,
                orderGroupId, instrument, instrType, tradingExchange, route, amount,
                orderCreatedBy, operation, labOrderType, tif,
                productType, price, stopPrice, lastPrice, leverage,
                slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, orderComments, time, clientPanelId
            ];
            break;
        }
        case OrderExecutionType.REPLACED:
        {
            if (isClosingOrder && isSLTP) {
                const ord = dc.getOrderById(oom.OrderNumber);
                // мы не отображаем сообщение, если изменяется только статус Active c false на true
                if (ord &&
                        !ord.Active &&
                        oom.RealActive &&
                        oom.Price === ord.Price &&
                        oom.TimeInForce === ord.TimeInForce &&
                        oom.Quantity === ord.Amount &&
                        orderType === ord.OrderType) { return []; }
            }

            header = ordTypeStr + ' order replaced';

            selectedLabels = [
                user, account, orderId, boundToOrder,
                orderGroupId, instrument, instrType, tradingExchange, route, amount,
                orderCreatedBy, operation, labOrderType, tif,
                productType, price, stopPrice, lastPrice, leverage,
                slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, orderComments, time, clientPanelId];
            break;
        }
        case OrderExecutionType.PART_FILLED:
        {
            header = 'Partially filled ' +
                    (isClosingOrder || isSLTP ? 'close ' : '') +
                    ordTypeStr +
                    ' order';

            selectedLabels = [
                user, account, orderId, boundToOrder,
                orderGroupId, instrument, instrType, tradingExchange, route, amount,
                orderCreatedBy, operation, labOrderType, tif,
                productType, price, stopPrice, lastPrice, leverage,
                slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, orderComments, time, clientPanelId];
            break;
        }
        case OrderExecutionType.FILLED:
        {
            header = 'Filled ' +
                    (isClosingOrder || isSLTP ? 'close ' : '') +
                    ordTypeStr +
                    ' order';

            // TODO. oom.ExecutionType.
            // ...

            selectedLabels = [
                user, account, orderId,

                !isClosingOrder && !isSLTP && oom.BoundToOrderId !== '-1'
                    ? boundToOrder
                    : positionId,

                orderGroupId, instrument, instrType, tradingExchange, route, amount,
                orderCreatedBy, operation, labOrderType, tif,
                productType, price, stopPrice, lastPrice, leverage,
                orderComments, time, comment, clientPanelId
            ];
            break;
        }
        case OrderExecutionType.CANCELED:
        {
            header = oom.Comment &&
                    oom.Comment.toLowerCase() === 'rollback position' &&
                    orderType === OrderTypeEnum.Market
                ? 'Rollback position'
                : ordTypeStr + ' order canceled';

            selectedLabels = [
                user, account, orderId, boundToOrder,
                orderGroupId, instrument, instrType, tradingExchange, route, amount,
                orderCreatedBy, operation, labOrderType, tif,
                productType, price, stopPrice, lastPrice, leverage,
                slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, orderComments, time, clientPanelId
            ];
            break;
        }
        case OrderExecutionType.RESTATED:
        {
            header = ordTypeStr + ' order restated';

            selectedLabels = [
                user, account, orderId, boundToOrder,
                orderGroupId, instrument, instrType, tradingExchange, route, amount,
                orderCreatedBy, operation, labOrderType, tif,
                productType, price, stopPrice, lastPrice, leverage,
                slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, orderComments, time, clientPanelId
            ];
            break;
        }
        case OrderExecutionType.REFUSED:
            header = ordTypeStr + ' order rejected';
            selectedLabels = [
                user, account, orderId, boundToOrder, orderGroupId, instrument, instrType, tradingExchange, route, amount,
                orderCreatedBy, operation, labOrderType, tif,
                productType, price, stopPrice, lastPrice, leverage,
                slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, initReq, orderComments, time, comment, lackOfMargin, clientPanelId];
            break;

        case OrderExecutionType.ACCEPTED:
            header = ordTypeStr + ' order accepted';
            selectedLabels = [
                user, account, orderId, boundToOrder, orderGroupId, instrument, instrType, tradingExchange, route, amount,
                orderCreatedBy, operation, labOrderType, tif,
                productType, price, stopPrice, lastPrice, leverage,
                slLabel, sllLabel, slTrigger, tpLabel, tpTrigger, initReq, orderComments, time, comment, clientPanelId];
            break;
        }

        // Mutual приходит только в коммент
        if (oom.Comment && oom.Comment.includes('Mutual')) {
            header += ' (Mutual)';
        }

        // validation - minimum 1 element
        if (selectedLabels.length === 0) {
        // console.error('Error selectedLabels is empty, can\'t Utils.GenerateReportMessage(OpenOrderMessage order)')
            needToReturnMessage = false;
        }

        if (!needToReturnMessage) {
            return [];
        }

        const resultDataArr = [];
        const len = selectedLabels.length;
        for (let i = 0; i < len; i++) {
            const lbl = selectedLabels[i];

            if (ins?.isHideRouteMode && route === lbl) {
                continue;
            }

            if (lbl && (lbl.Value || lbl.Value === 0)) {
                resultDataArr.push([lbl.Label, lbl.Value]);
            }
        }

        const reportMessage = new DirectReportMessage(header, resultDataArr);
        reportMessage.Roundtrip = oom.Roundtrip;
        reportMessage.Instrument = ins;
        reportMessage.account = acc;
        reportMessage.productType = oom.ProductType;
        reportMessage.SkipOnTicketViewer = needSkip;
        reportMessage.source = ReportMessageSource.OpenOrderMessage;

        if (addPartialReject) {
            header = 'Partially reject ' + ordTypeStr + ' order';
            const reportMessagePartialReject = new DirectReportMessage(header, resultDataArr);
            reportMessagePartialReject.Roundtrip = oom.Roundtrip;
            reportMessagePartialReject.Instrument = ins;
            reportMessagePartialReject.account = acc;
            reportMessagePartialReject.productType = oom.ProductType;
            reportMessagePartialReject.SkipOnTicketViewer = needSkip;
            reportMessagePartialReject.source = ReportMessageSource.OpenOrderMessage;

            return [reportMessagePartialReject, reportMessage];
        } else {
            return [reportMessage];
        }
    }

    // #endregion GenerateReportMessage

    public static IsStopPrice (orderType, orderStatus): boolean {
        const orderTypeOk =
        orderType === OrderType.Stop ||
        orderType === OrderType.SLTPStop ||
        orderType === OrderType.TrailingStop;

        const orderStatusOk =
        orderStatus === OrderStatus.PENDING_NEW ||
        orderStatus === OrderStatus.NEW ||
        orderStatus === OrderStatus.REPLACED ||
        orderStatus === OrderStatus.CANCELED ||
        orderStatus === OrderStatus.REFUSED ||
        orderStatus === OrderStatus.ACCEPTED;

        return orderTypeOk && orderStatusOk;
    }

    public static GenerateRefusalOnOptionExercise (instrumentName: string): void {
        const header = 'general.trading.refused';

        const message = new DirectReportMessage();
        message.Name = header;

        const reasonTemplate = Resources.getResource('IsAllowedResponceReason.OptionPendingExerciseDealticket');
        const reason = reasonTemplate.replace('{0}', instrumentName);
        message.Data = [['msg', reason]];

        GenerateReportMessages.DataCache.HandleReportMessageDirect(message);
    }

    public static GenerateCloseAccountDealticket (msg): void {
        const header = 'ribbon.closeAccount';

        const status = msg.status;
        const newMsg = new DirectReportMessage();
        newMsg.Name = header;

        newMsg.Data = new Array(1);
        newMsg.Data[0] = ['msg', `closeAccount.dealticket.${status}`];
        newMsg.tooltipDrawType = GenerateReportMessages.CloseAccountStatusToTooltipType(status);

        GenerateReportMessages.DataCache.HandleReportMessageDirect(newMsg);
    }

    public static CloseAccountStatusToTooltipType (status): TooltipType {
        switch (status) {
        case CloseAccountResponseStatus.New:
            return TooltipType.CAInfo;
        case CloseAccountResponseStatus.Cancelled:
            return TooltipType.CACanceled;
        case CloseAccountResponseStatus.Approve:
            return TooltipType.CAAccepted;
        case CloseAccountResponseStatus.Rejected:
            return TooltipType.CARejected;
        }
    }
}

generateReportMessageHandler.GenerateReportMessage = GenerateReportMessages.GenerateReportMessage;
