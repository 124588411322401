// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '@shared/utils/CustomEvents';
import { Rectangle } from '@shared/commons/Geometry';
import { ThemeManager } from '../misc/ThemeManager';
import { type Timeout } from 'react-number-format/types/types';

export class Scroll {
    public static readonly SCROLL_BTN_WIDTH = 0;
    public static readonly SCROLL_BTN_HEIGHT = 1;
    public static readonly SCROLL_WIDTH = 10;
    public static readonly SCROLL_SLIDER_WIDTH = 6;
    public static readonly SCROLL_SLIDER_LEFT = 2;

    public static readonly SCROLL_SLIDER_MIN_HEIGHT = 17;

    public scrollIndex: number;
    public scrollElementsCount: number;
    public ctx: CanvasRenderingContext2D;
    public elementHeight: number;
    public isSliderMove: boolean = false;
    public OnValueChange: CustomEvent = new CustomEvent();

    public get Visible (): boolean {
        return this._visible;
    }

    public set Visible (value: boolean) {
        this._visible = value;
        if (this._visible) {
            this.showScrollbar();
        } else if (this.autoHideEnabled) {
            this.hideScrollbar();
        }
    }

    public maxElementsOnScreen: number = 0;

    public Hidden: boolean = false;
    public HiddenByScrollAutohide: boolean = false;
    public minY: number;
    public maxY: number;
    public moveDeltaY: number;
    public scaledElementH: number;
    public btnUp: ScrollElement;
    public btnDown: ScrollElement;
    public slider: ScrollElement;
    public controls: ScrollElement[];
    public containerRectangle: Rectangle;

    private _visible: boolean = false;
    private readonly autoHideTimeout: number = 2000; // Time in milliseconds to hide scrollbar after inactivity
    private hideScrollbarTimeoutId: Timeout | null = null;
    private autoHideEnabled: boolean = true; // Enable/disable auto-hide functionality
    private fadeOpacity: number = 1.0;

    constructor (x: number, y: number, width: number, height: number, scrollStartIndex: number, scrollElementsCount: number, ctx: CanvasRenderingContext2D, elementHeight: number, autoHideEnabled = true) {
        this.updateScrollControlsData = this.updateScrollControlsData.bind(this);

        this.scrollIndex = scrollStartIndex;
        this.scrollElementsCount = scrollElementsCount;
        this.ctx = ctx;// .canvas.getContext('2d', { alpha: true });
        this.elementHeight = elementHeight;
        this.autoHideEnabled = autoHideEnabled;

        this.initScrollControls();
        this.setBounds(new Rectangle(x, y, width, height));
    }

    public isActive (): boolean {
        return this.Visible && (!this.autoHideEnabled || (this.autoHideEnabled && !this.HiddenByScrollAutohide));
    }

    public initScrollControls (): void {
        this.btnUp = new ScrollElement(new Rectangle(0, 0, Scroll.SCROLL_BTN_WIDTH, Scroll.SCROLL_BTN_HEIGHT), VisualControlState.None);

        this.btnDown = new ScrollElement(new Rectangle(0, 0, Scroll.SCROLL_BTN_WIDTH, Scroll.SCROLL_BTN_HEIGHT), VisualControlState.None);

        this.slider = new ScrollElement(new Rectangle(0, 0, Scroll.SCROLL_SLIDER_WIDTH, 0), VisualControlState.None);

        this.controls = [this.btnUp, this.slider, this.btnDown];
    }

    // #region Draw methods

    public Draw (): void {
        if (!this.Visible || this.Hidden) { return; }

        const ctx = this.ctx;
        if (isNullOrUndefined(this.ctx)) { return; }
        ctx.save();

        // Set the globalAlpha only for scrollbar drawing
        ctx.globalAlpha = this.fadeOpacity;

        ctx.imageSmoothingEnabled = false;

        const myRect = this.containerRectangle;

        // clear Place
        ctx.fillStyle = ThemeManager.CurrentTheme.scrollBackgroundColor;
        ctx.fillRect(myRect.X, myRect.Y, myRect.Width, myRect.Height);

        Scroll.drawButtons(
            ctx,
            this.btnUp.rectangle, this.btnUp.visualState,
            this.btnDown.rectangle, this.btnDown.visualState);

        Scroll.drawSlider(ctx, this.slider.rectangle, this.slider.visualState);

        ctx.globalAlpha = 1;

        ctx.restore();
    }

    public static drawButtons (ctx: CanvasRenderingContext2D, btnPrevRect, btnPrevVisualState, btnNextRect, btnNextVisualState: VisualControlState, horizontal: boolean = false): void {
        const rectUp = btnPrevRect;
        let imgUp;

        switch (btnPrevVisualState) {
        case VisualControlState.None:
            imgUp = ThemeManager.CurrentTheme.scrollUpImg;
            break;
        case VisualControlState.Hovered:
            imgUp = ThemeManager.CurrentTheme.scrollUpHoverImg;
            break;
        case VisualControlState.Clicked:
            imgUp = ThemeManager.CurrentTheme.scrollUpClickImg;
            break;
        }

        const rectDown = btnNextRect;
        let imgDown;

        switch (btnNextVisualState) {
        case VisualControlState.None:
            imgDown = ThemeManager.CurrentTheme.scrollDownImg;
            break;
        case VisualControlState.Hovered:
            imgDown = ThemeManager.CurrentTheme.scrollDownHoverImg;
            break;
        case VisualControlState.Clicked:
            imgDown = ThemeManager.CurrentTheme.scrollDownClickImg;
            break;
        }

        if (horizontal) {
            let centerX = rectUp.X + rectUp.Width / 2;
            let centerY = rectUp.Y + rectUp.Height / 2;
            ctx.DrawRotatedImage(imgUp, centerX, centerY, -90);

            centerX = rectDown.X + rectDown.Width / 2;
            centerY = rectDown.Y + rectDown.Height / 2;
            ctx.DrawRotatedImage(imgDown, centerX, centerY, -90);
        } else {
            if (imgUp != null) {
                ctx.drawImage(imgUp, rectUp.X - 1, rectUp.Y);
            }

            if (imgDown != null) {
                ctx.drawImage(imgDown, rectDown.X - 1, rectDown.Y);
            }
        }
    }

    public static drawSlider (ctx: CanvasRenderingContext2D, rect, visualState): void {
        const drawData = Scroll.getDrawingData(visualState);
        const imgTop = drawData.imgTop;
        const imgBottom = drawData.imgBottom;
        const middleDrawMethod = drawData.middleDrawMethod;

        if (imgTop == null || imgBottom == null) return;

        const left = rect.X + Scroll.SCROLL_SLIDER_LEFT - 1;

        ctx.drawImage(imgTop, left, rect.Y);

        const lengthToDraw = rect.Height - imgTop.height - imgBottom.height;
        if (lengthToDraw > 0 && middleDrawMethod != null) {
            middleDrawMethod(
                ctx,
                left,
                rect.Y + imgTop.height,
                lengthToDraw);
        }

        ctx.drawImage(imgBottom, left, rect.Y + rect.Height - imgBottom.height);
    }

    public static drawHorizontalSlider (ctx: CanvasRenderingContext2D, rect, visualState: VisualControlState): void {
        const drawData = Scroll.getDrawingData(visualState);
        const imgTop = drawData.imgTop;
        const imgBottom = drawData.imgBottom;
        const middleDrawMethod = drawData.middleDrawMethod;

        if (imgTop == null || imgBottom == null) return;

        ctx.save();

        ctx.DrawRotatedImage(imgTop, rect.X + imgTop.height / 2, rect.Y + 9, -90);
        const lengthToDraw = rect.Width - imgTop.height - imgBottom.height;

        if (lengthToDraw > 0 && middleDrawMethod != null) {
            ctx.save();

            const left = rect.X + imgTop.height;
            const top = rect.Y + 12;

            ctx.translate(left, top);
            ctx.RotateByAngle(-90);
            ctx.translate(-left, -top);

            middleDrawMethod(ctx, left, top, lengthToDraw);

            ctx.restore();
        }

        ctx.DrawRotatedImage(imgBottom, rect.X + rect.Width - imgBottom.height / 2, rect.Y + 9, -90);

        ctx.restore();
    }

    public static drawMiddleDefaultSection (ctx: CanvasRenderingContext2D, x: number, y: number, height: number): void {
        Scroll.drawMiddleSection(ctx, x, y, height,
            ThemeManager.CurrentTheme.scrollVertMidMainColor,
            ThemeManager.CurrentTheme.scrollVertMidBorderColor);
    }

    public static drawMiddleHoverSection (ctx: CanvasRenderingContext2D, x: number, y: number, height: number): void {
        Scroll.drawMiddleSection(ctx, x, y, height,
            ThemeManager.CurrentTheme.scrollVertMidHoverMainColor,
            ThemeManager.CurrentTheme.scrollVertMidHoverBorderColor);
    }

    public static drawMiddleClickSection (ctx: CanvasRenderingContext2D, x: number, y: number, height: number): void {
        Scroll.drawMiddleSection(ctx, x, y, height,
            ThemeManager.CurrentTheme.scrollVertMidClickMainColor,
            ThemeManager.CurrentTheme.scrollVertMidClickBorderColor);
    }

    public static drawMiddleSection (ctx: CanvasRenderingContext2D, x: number, y: number, height: number, mainColor, borderColor): void {
        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = mainColor;
        ctx.fillRect(x, y - 0.5, Scroll.SCROLL_SLIDER_WIDTH, height + 1);

        // Sharp lines.
        // ctx.translate(0.5, 0.5);

        // ctx.strokeStyle = borderColor;
        // ctx.lineWidth = 1;

        // ctx.beginPath();

        // var top = y - 1;
        // var bottom = y + height;

        // ctx.moveTo(x, top);
        // ctx.lineTo(x, bottom);

        // x += 9;

        // ctx.moveTo(x, top);
        // ctx.lineTo(x, bottom);

        ctx.stroke();

        ctx.restore();
    }

    public static getDrawingData (visualState: VisualControlState) {
        let imgTop = null;
        let imgBottom = null;
        let middleDrawMethod = null;

        switch (visualState) {
        case VisualControlState.None:
            imgTop = ThemeManager.CurrentTheme.scrollVertTopImg;
            imgBottom = ThemeManager.CurrentTheme.scrollVertBotImg;
            middleDrawMethod = Scroll.drawMiddleDefaultSection;
            break;
        case VisualControlState.Hovered:
            imgTop = ThemeManager.CurrentTheme.scrollVertTopHoverImg;
            imgBottom = ThemeManager.CurrentTheme.scrollVertBotHoverImg;
            middleDrawMethod = Scroll.drawMiddleHoverSection;
            break;
        case VisualControlState.Clicked:
            imgTop = ThemeManager.CurrentTheme.scrollVertTopClickImg;
            imgBottom = ThemeManager.CurrentTheme.scrollVertBotClickImg;
            middleDrawMethod = Scroll.drawMiddleClickSection;
            break;
        }

        return { imgTop, imgBottom, middleDrawMethod };
    }

    // #endregion

    public setScrollElementsCount (newCount: number): void {
        if (this.scrollElementsCount === newCount) { return; }

        this.scrollElementsCount = newCount;
        this.updateScrollControlsData();
    }

    public setBounds (rect: Rectangle): void {
        if (isNullOrUndefined(rect)) return;

        const cRect = this.containerRectangle;
        if (cRect?.Equals(rect)) return;

        this.containerRectangle = rect;
        this.updateScrollControlsData();

        this.scrollIndex = this.correctScrollIndex(this.scrollIndex);
    }

    public updateScrollControlsData (): void {
        const cRect = this.containerRectangle;
        const elementsTotal = this.scrollElementsCount;

        // Count only entirely visible elements.
        const maxElementsOnScreen = Math.floor(this.containerRectangle.Height / this.elementHeight);

        const allElementsFitScreen = elementsTotal <= maxElementsOnScreen;

        const availableH = cRect.Height - 2 * Scroll.SCROLL_BTN_HEIGHT;

        let scaledElementH = elementsTotal
            ? availableH / elementsTotal
            : 1;

        let sliderH = allElementsFitScreen
            ? availableH
            : maxElementsOnScreen * scaledElementH;

        if (sliderH < Scroll.SCROLL_SLIDER_MIN_HEIGHT) {
            sliderH = Scroll.SCROLL_SLIDER_MIN_HEIGHT;
            scaledElementH =
            (availableH - sliderH) /
            (elementsTotal - maxElementsOnScreen);
        }

        const scrollControlLeft = cRect.X + 1;

        const slider = this.slider;
        slider.rectangle.X = scrollControlLeft;
        slider.rectangle.Height = sliderH;

        const btnUpRect = this.btnUp.rectangle;
        btnUpRect.X = scrollControlLeft;
        btnUpRect.Y = cRect.Y;

        const btnDownRect = this.btnDown.rectangle;
        btnDownRect.X = scrollControlLeft;
        btnDownRect.Y = cRect.Height + cRect.Y - Scroll.SCROLL_BTN_HEIGHT;

        this.minY = cRect.Y + Scroll.SCROLL_BTN_HEIGHT;
        this.maxY = cRect.Y + cRect.Height - Scroll.SCROLL_BTN_HEIGHT - sliderH;
        this.scaledElementH = scaledElementH;
        this.maxElementsOnScreen = maxElementsOnScreen;
        this.Visible = !allElementsFitScreen;

        if (!this.Visible && this.scrollIndex !== 0) {
            this.moveScrollToElement(0);
        } else {
            this.moveScrollToElement(this.scrollIndex);
        }
    }

    public trailingScroll (leadingIdx: number): void {
        if (leadingIdx <= this.scrollIndex) {
            this.moveScrollToElement(leadingIdx);
            return;
        }

        const bottomScrollIndex = this.scrollIndex + this.maxElementsOnScreen - 1;
        if (this.scrollIndex <= leadingIdx && leadingIdx <= bottomScrollIndex) {
            return;
        }

        this.moveScrollToElement(this.scrollIndex + leadingIdx - bottomScrollIndex);
    }

    public correctScrollIndex (preferredIndex: number): number {
        const maxElementsOnScreen = this.maxElementsOnScreen;
        if (maxElementsOnScreen >= this.scrollElementsCount) {
            return 0;
        }

        if (preferredIndex < 0) {
            return 0;
        }

        if (preferredIndex >= this.scrollElementsCount) {
            return this.scrollElementsCount - maxElementsOnScreen - 1;
        }

        const elementsBelowScreen =
        this.scrollElementsCount -
        preferredIndex -
        maxElementsOnScreen;

        if (elementsBelowScreen >= 0) {
            return preferredIndex;
        }

        // Shifting scroll index back.
        preferredIndex += elementsBelowScreen;
        if (preferredIndex < 0) {
            preferredIndex = 0;
        }

        return preferredIndex;
    }

    public moveScrollToElement (index: number): void {
        if (index !== this.scrollIndex) {
            const oldScrollIndex = this.scrollIndex;
            this.scrollIndex = this.correctScrollIndex(index);
            if (oldScrollIndex !== this.scrollIndex) {
                this.OnValueChange.Raise(this.scrollIndex);
            }
        }

        let newY = this.minY + this.scrollIndex * this.scaledElementH;
        if (newY > this.maxY) {
            newY = this.maxY;
        }
        this.slider.rectangle.Y = newY;

        if (this.isActive()) {
            this.Draw();
        }
    }

    public moveSliderToY (newY: number): void {
        if (newY < this.minY) {
            newY = this.minY;
        } else if (newY > this.maxY) {
            newY = this.maxY;
        }

        this.slider.rectangle.Y = newY;

        this.scrollIndex = Math.round((newY - this.minY) / this.scaledElementH);
        this.OnValueChange.Raise(this.scrollIndex);

        if (this.isActive()) {
            this.Draw();
        }
    }

    public mouseWheel (isUp: boolean): void {
        this.moveScrollToElement(this.scrollIndex + (isUp ? -1 : 1));
    }

    public mouseDown (event): void {
        this.showScrollbar();
        if (!this.isActive()) { return; }

        // find part of scroll
        if (this.slider.rectangle.Contains(event.offsetX, event.offsetY)) {
            this.slider.visualState = VisualControlState.Clicked;
            this.isSliderMove = true;
            this.moveDeltaY = event.offsetY - this.slider.rectangle.Y;
            this.Draw();
        } else if (this.btnUp.rectangle.Contains(event.offsetX, event.offsetY)) {
            this.btnUp.visualState = VisualControlState.Clicked;
            this.mouseWheel(true);
        } else if (this.btnDown.rectangle.Contains(event.offsetX, event.offsetY)) {
            this.btnDown.visualState = VisualControlState.Clicked;
            this.mouseWheel(false);
        } else {
            this.moveSliderToY(event.offsetY);
        }
    }

    public mouseMove (event): void {
        this.showScrollbar();
        if (!this.Visible || !this.isSliderMove) {
            return;
        }

        this.slider.visualState = VisualControlState.Clicked;
        this.moveSliderToY(event.offsetY - this.moveDeltaY);
    }

    // TODO. Qtable, qtree call updateControlHoverVisualState.
    // For updating hover/default state only.
    public onMouseMove (event): void {
        this.showScrollbar();
        this.updateControlHoverVisualState(event);
    }

    public mouseUp (event): void {
        this.isSliderMove = false;
        // TODO.
        // Corrects slider y position.
        this.moveScrollToElement(this.scrollIndex);
    }

    public showScrollbar (): void {
        if (this.autoHideEnabled) {
            this.HiddenByScrollAutohide = false;
            this.fadeOpacity = 1.0; // Reset opacity
            if (this.hideScrollbarTimeoutId != null) {
                clearTimeout(this.hideScrollbarTimeoutId);
            }
            this.hideScrollbarTimeoutId = setTimeout(() => { this.hideScrollbar(); }, this.autoHideTimeout);
        }

        this.Draw();
    }

    public hideScrollbar (): void {
        this.fadeOutScrollbar();
    }

    public setAutoHideEnabled (enabled: boolean): void {
        this.autoHideEnabled = enabled;
        if (enabled) {
            this.showScrollbar();
        } else {
            this.HiddenByScrollAutohide = false;
            this.Draw();
        }
    }

    public updateControlHoverVisualState (event): void {
        if (!this.isActive()) {
            return;
        }

        const controls = this.controls;
        const len = controls.length;
        for (let i = 0; i < len; i++) {
            const control = controls[i];
            control.visualState =
            control.rectangle.Contains(event.offsetX, event.offsetY)
                ? VisualControlState.Hovered
                : VisualControlState.None;
        }

        this.Draw();
    }

    public getVisibleCount (): number {
        return this.maxElementsOnScreen;
    }

    public themeChange (): void { }

    public isNeedRedrawAllLayout (): boolean {
        return this.autoHideEnabled && this.fadeOpacity < 1 && !this.HiddenByScrollAutohide;
    }

    private fadeOutScrollbar (): void {
        const step = 0.05;
        const interval = 50; // ms

        const fadeStep = (): void => {
            this.fadeOpacity -= step;
            if (this.fadeOpacity < 0) {
                // this.OnValueChange.Raise(this.scrollIndex);
                this.fadeOpacity = 0;
                this.HiddenByScrollAutohide = true;
            }
        };

        const fadeInterval = setInterval(() => {
            fadeStep();
            if (this.HiddenByScrollAutohide) {
                clearInterval(fadeInterval);
            }
        }, interval);
    }
}

class ScrollElement {
    public rectangle: Rectangle;
    public visualState: VisualControlState;

    constructor (rectangle: Rectangle, visualState: VisualControlState) {
        this.rectangle = rectangle;
        this.visualState = visualState;
    }
}

export enum VisualControlState {
    None = 0,
    Clicked = 1,
    Hovered = 2
}
