// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Message } from '../../../../Utils/DirectMessages/Message';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { PFixMessage } from './PFixMessage';

export class PortfolioModelRequest extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_PORTFOLIO_MODEL_REQUEST_MESSAGE, fieldSet);
    }
}
