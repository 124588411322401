// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ApplicationInfo } from '../Commons/ApplicationInfo';
import { CustomEvent } from './CustomEvents';
import { SoundManager } from './SoundManager';

export class TradingLockUtils {
    public static IsUseLockTradingByPassword = false; // #89340 & #94446

    public static TradingLock = {

        tradingLocked: false,
        LockTradingByPassword: false,

        TradingLockChanged: new CustomEvent(),

        updateTradingLock: function (locked, skip: any = undefined): void {
            const isLocked = locked || ApplicationInfo.isExploreMode;

            if (this.LockTradingByPassword && !isLocked) return;

            if (this.tradingLocked === isLocked) return;

            this.tradingLocked = isLocked || ApplicationInfo.isExploreMode;

            if (!skip) {
                this.TradingLockChanged.Raise();
            }

            SoundManager.tryPlaySound(isLocked ? SoundManager.SoundKeys.Lock : SoundManager.SoundKeys.Unlock);

            if (TradingLockUtils.IsUseLockTradingByPassword && isLocked) {
                this.LockTradingByPassword = true;
            }
        }
    };

    public static ResetBeforeConnection (): void {
        if (TradingLockUtils.TradingLock.LockTradingByPassword) {
            TradingLockUtils.TradingLock.LockTradingByPassword = false;
            TradingLockUtils.TradingLock.updateTradingLock(false);
        }
        TradingLockUtils.IsUseLockTradingByPassword = false; // #89340 & #94446
    }
}
