// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { iCCIEx } from './iCCIEx';

export class iCCI extends iCCIEx {
    constructor (maPeriod, maType) {
        super(maPeriod, maType, PriceType.Typical);
    }

    override get Name (): string { return 'iCCI'; }
}
