// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DynProperty } from '../../../Commons/DynProperty';
import { type SessionSettings } from '../../../Commons/SessionSettings';
import { type AnalyzerVolatilityLine } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionAnalyzer/AnalyzerVolatilityLine';
import { OptionTraderUtils } from '../../../Commons/cache/OptionMaster/OptionTrader/OptionTraderUtils';
import { QuickTableComparingType } from '../../../Utils/QuickTableMisc/QuickTableComparingType';
import { QuickTableColumnType, QuickTableEditingInfo } from '../../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from '../BaseItem';
import { ColumnParams } from '../ColumnParams';

export class OptionVolatilityLabSeriesTableItem extends BaseItem {
    public readonly expirationDate: Date;
    public readonly seriesLine: AnalyzerVolatilityLine;
    constructor (expirationDate: Date, seriesLine: AnalyzerVolatilityLine, sessionSettings: typeof SessionSettings) {
        super(sessionSettings);
        this.expirationDate = expirationDate;
        this.seriesLine = seriesLine;
        this.QuickTableEditingInfoMap[OptionVolatilityLabSeriesTableItem.ACTIVE_COL_INDEX] = new QuickTableEditingInfo(DynProperty.BOOLEAN);
        const colorQuickTableEditingInfo = new QuickTableEditingInfo(DynProperty.COLOR);
        colorQuickTableEditingInfo.IsEditable = false;
        this.QuickTableEditingInfoMap[OptionVolatilityLabSeriesTableItem.COLOR_COL_INDEX] = colorQuickTableEditingInfo;
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        return new ColumnData(value, value.toString());
    }

    public override getColumnValue (column: number): any {
        switch (column) {
        case OptionVolatilityLabSeriesTableItem.ACTIVE_COL_INDEX:
            return this.seriesLine.isActive;
        case OptionVolatilityLabSeriesTableItem.SERIES_COL_INDEX:
            return OptionTraderUtils.formatExpirationDate(this.expirationDate);
        case OptionVolatilityLabSeriesTableItem.COLOR_COL_INDEX:
            return this.seriesLine.color;
        default:
            return undefined;
        }
    }

    public override ColumnCount (): number {
        return OptionVolatilityLabSeriesTableItem.columnsParams.length;
    }

    public override GetColumnParams (i: number): any {
        return OptionVolatilityLabSeriesTableItem.columnsParams[i];
    }

    public override GetDataType (columnIndex: number): number {
        return OptionVolatilityLabSeriesTableItem.dataTypes[columnIndex];
    }

    static readonly ACTIVE_COL_INDEX = 0;
    static readonly SERIES_COL_INDEX = 1;
    static readonly COLOR_COL_INDEX = 2;
    static columnsParams: ColumnParams[] =
        [
            new ColumnParams('panel.optionVolatilityLab.active', 20, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionVolatilityLab.series', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
            new ColumnParams('panel.optionVolatilityLab.color', 20, QuickTableColumnType.COL_DEFAULT, false, true, false)
        ];

    private static readonly dataTypes = [
        QuickTableComparingType.String,
        QuickTableComparingType.String,
        QuickTableComparingType.String
    ];
}
