// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { MathUtils } from '@shared/utils/MathUtils';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { contextMenuHandler } from '@shared/utils/AppHandlers';
import { TerceraQuantityNumericTemplate } from '../../templates';
import { TypesManagerScreen } from '../screen/TypesManagerScreen';
import { ContainerControl } from './ContainerControl';
import { TerceraNumericEvents } from './TerceraNumeric';
import { ProductType } from '@shared/utils/Instruments/ProductType';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { type Instrument } from '@shared/commons/cache/Instrument';
import $ from 'jquery';
import { type InsDefItem, type InsDefSettings } from '@shared/commons/cache/InstrumentDefaults';
import { type Account } from '@shared/commons/cache/Account';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';
import { WDSettings } from '../settings/WDGeneralSettings';

interface IMenuItem {
    text?: string
    tag?: number
    event?: any
    separator?: boolean
}

export class TerceraQuantityNumeric extends ContainerControl {
    public addedInsValuesLots: Record<string, UniqueLimitedQueue> = null;
    public menuItems: IMenuItem[];
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraQuantityNumeric; }

    public override oninit (): void {
        super.oninit();

        this.addedInsValuesLots = {};

        this.on('openQuantityMenu', this.openQuantityMenu);
        // TODO. Ugly. Remove defaultQuantity.
        this.observe('productType', this.UpdateProductType);
        this.observe('defaultQuantity', this.UpdateDefaultQuantity);
        this.observe('instrument', this.UpdateInstrument);
        this.observe('account', this.UpdateAccount);
        this.observe('value', this.onValueChanged);
        this.observe('assetNameForCash', this.UpdateRiskSettings); // to recreate the default quantity dropdown

        WDSettings.settingsChanged.Subscribe(this.UpdateRiskSettings, this);
        WDSettings.insDefSettingsStorage.DefaultSettingsChanged.Subscribe(this.UpdateRiskSettings, this);
    }

    public override dispose (): void {
        WDSettings.settingsChanged.UnSubscribe(this.UpdateRiskSettings, this);
        WDSettings.insDefSettingsStorage.DefaultSettingsChanged.UnSubscribe(this.UpdateRiskSettings, this);

        const ins: Instrument = this.get('instrument');
        if (!isNullOrUndefined(ins)) {
            ins.RiskSettingsUpdated.UnSubscribe(this.UpdateRiskSettings, this);
        }

        super.dispose();
    }

    public UpdateInstrument (n: Instrument, o: Instrument): void {
        if (!isNullOrUndefined(o)) {
            o.RiskSettingsUpdated.UnSubscribe(this.UpdateRiskSettings, this);
        }

        if (!isNullOrUndefined(n)) {
            n.RiskSettingsUpdated.Subscribe(this.UpdateRiskSettings, this);
        }
        this.settingsUpdate();
    }

    public UpdateDefaultQuantity (): void {
        this.settingsUpdate();
    }

    public UpdateRiskSettings (): void {
        this.settingsUpdate(true);
    }

    public UpdateProductType (n: ProductType, o: ProductType): void {
        this.settingsUpdate(!isNullOrUndefined(o));
    }

    public UpdateAccount (n: Account, o: Account): void {
        this.settingsUpdate();
    }

    public settingsUpdate (restoreQty = false): void {
        const account: Account = this.get('account');
        const ins: Instrument = this.get('instrument');
        if (isNullOrUndefined(ins)) { return; }

        const inLots = WDSettingsUtils.displayAmountInLots();
        void this.set('inLots', inLots);

        const selectedProductType: ProductType = this.get('productType') ?? ProductType.General;
        const qtyNumericSettings =
        this.isCashMode
            ? Quantity.getCashNumericSettings(ins, account)
            : Quantity.getQuantityNumericSettings(ins, account, inLots, selectedProductType);

        // TODO. Ugly. Remove.
        const defaultQuantity = this.get('defaultQuantity');
        if (defaultQuantity) {
            qtyNumericSettings.value = Quantity.convertQuantityValue(
                defaultQuantity, ins, inLots, account, selectedProductType);
        }

        if (restoreQty) {
            const value = this.get('value');
            if (this.isCashMode) {
                qtyNumericSettings.value = value;
            } else {
                qtyNumericSettings.value = Quantity.convertQuantityValue(
                    new Quantity(value, inLots), ins, inLots, account, selectedProductType);
            }
        }

        void this.set(qtyNumericSettings);
        this.createDefaultLotsDropDown();
    }

    public onValueChanged (value): void {
        this.fire(TerceraNumericEvents.ValueChanged, value);
    }

    public openQuantityMenu (context): void {
        if (!this.get<boolean>('enabled')) { return; }

        const control = $(this.find('.js-popup-button'));
        const pos = control.offset();
        const height = control.outerHeight();
        contextMenuHandler.Show(this.menuItems, pos.left, pos.top + height);
        this.setFocus();
    }

    public override setFocus (): void {
        super.setFocus();
        this.Controls.QTYNumeric.setFocus();
    };

    public createDefaultLotsDropDown (): void {
        const menuItems: IMenuItem[] = [];

        if (!this.isCashMode) {
            const ins: Instrument = this.get('instrument');
            this.addDefaultLots(menuItems, ins);
            this.addUserDefinedLots(menuItems, ins);
        }

        this.addEditButton(menuItems);
        this.menuItems = menuItems;
    }

    public onDropdownValueSelected (menuItem): void {
        const value = menuItem.tag;
        void this.set('value', value);
    }

    public onEditClicked (): void {
        TypesManagerScreen.show(
            this,
            this.get('instrument'),
            TerceraQuantityNumeric.typesManagerScreenCallBack);
    }

    public static typesManagerScreenCallBack (settings): void {
        SessionSettings.updateInstrumentsDefaultSettings(settings);
    }

    // For TypesManagerScreen.
    public getAllInstrumentsDefaultSettings (): InsDefSettings {
        return WDSettings.insDefSettingsStorage.GetSettingsCopy();
    }

    public addValue (val): void {
        const ins: Instrument = this.get('instrument');
        if (isNullOrUndefined(ins)) {
            return;
        }

        const instrumentID = ins.GetInteriorID();
        const addedInsValuesLots = this.addedInsValuesLots;
        if (!Object.prototype.hasOwnProperty.call(addedInsValuesLots, instrumentID)) {
            addedInsValuesLots[instrumentID] =
            new UniqueLimitedQueue(UniqueLimitedQueue.DROPDOWN_ADDED_VALUES_LIMIT);
        }

        if (!WDSettings.isQuantityInLots) {
            val /= ins.LotSize;
        }

        // пересоздаем список значений
        if (addedInsValuesLots[instrumentID].tryEnqueue(val)) {
            this.createDefaultLotsDropDown();
        }
    }

    public static sortLots (a: number, b: number): number {
        return a - b;
    }

    private get isCashMode (): boolean {
        return this.get('assetNameForCash') !== '';
    }

    private addDefaultLots (menuItems: IMenuItem[], ins: Instrument): void {
        // Getting instrument's defaults.
        const insDefaults: InsDefItem = WDSettings.insDefSettingsStorage.GetInstrumentSettings(ins);
        if (isNullOrUndefined(insDefaults)) {
            return;
        }
        // Populating dropdown by instrument's default lots values.
        const sortedLots = [];
        // Set of doubles.
        const lotValues = {};

        const defLots = insDefaults.DefaultLots;
        const defLotsLen = defLots.length;
        for (let i = 0; i < defLotsLen; i++) {
            const selectiveLot = defLots[i];
            const selectiveLotVal = selectiveLot.Lots;

            if (!selectiveLot.Selected || lotValues[selectiveLotVal]) {
                continue;
            }

            lotValues[selectiveLotVal] = true;
            sortedLots.push(selectiveLotVal);
        }
        sortedLots.sort(TerceraQuantityNumeric.sortLots);

        const strItemsList = [];
        const decimalPrecision = this.get('decimalPrecision');
        const divider = Math.pow(10, decimalPrecision);

        // #37103 - возможна такая ситуация, когда значения default lots меньше чем Lot step,
        // поэтому мы здесь удаляем дубликаты, если необходимо
        const sortedLotsLen = sortedLots.length;
        for (let i = 0; i < sortedLotsLen; i++) {
            let convValue = sortedLots[i];
            if (!WDSettings.isQuantityInLots) {
                convValue *= ins.LotSize;
            }

            // обрезаем без округления дробную часть числа, которая выходит за пределы DecimalPlaces
            let dConvValue = MathUtils.trunc(convValue * divider) / divider;
            // Js float calc fix.
            dConvValue = parseFloat(dConvValue.toFixed(decimalPrecision));
            const strConvValue = MathUtils.formatValueWithEps(InstrumentUtils.formatAmountValue(convValue, ins));

            if (!strItemsList.includes(strConvValue) && dConvValue !== 0) {
                strItemsList.push(strConvValue);
                menuItems.push({
                    text: strConvValue,
                    tag: dConvValue,
                    event: this.onDropdownValueSelected.bind(this)
                });
            }
        }
    }

    private addUserDefinedLots (menuItems: IMenuItem[], ins: Instrument): void { // Populating dropdown by added values.
        const filteredAddedInsValuesLots = this.addedInsValuesLots[ins.GetInteriorID()];
        if (!isNullOrUndefined(filteredAddedInsValuesLots) && filteredAddedInsValuesLots.count() !== 0) {
            if (menuItems.length > 0) {
                menuItems.push({ separator: true });
            }

            const insertPos = menuItems.length;
            const userLotsLen = filteredAddedInsValuesLots.count();
            for (let i = 0; i < userLotsLen; i++) {
                let convValue = filteredAddedInsValuesLots.value(i);

                if (!WDSettings.isQuantityInLots) {
                    convValue *= ins.LotSize;
                }

                menuItems.splice(insertPos, 0, {
                    text: convValue.toFixed(this.get('decimalPrecision')),
                    tag: convValue,
                    event: this.onDropdownValueSelected.bind(this)
                });
            }
        }
    }

    private addEditButton (menuItems: IMenuItem[]): void {
        if (menuItems.length > 0) {
            menuItems.push({ separator: true });
        }

        menuItems.push({
            text: Resources.getResource('NumericComboCtrl.Edit'),
            event: this.onEditClicked.bind(this)
        });
    }
}

// #region UniqueLimitedQueue

class UniqueLimitedQueue {
    public static readonly DROPDOWN_ADDED_VALUES_LIMIT = 3;

    public queue: any[];
    public limit: number;

    constructor (limit: number) {
        this.queue = [];
        this.limit = limit;
    }

    public tryEnqueue (obj): boolean {
        const queue = this.queue;
        if (queue.includes(obj)) {
            return false;
        }

        queue.push(obj);

        const limit = this.limit;
        while (queue.length > limit) {
            queue.shift();
        }

        return true;
    }

    public value (idx: number): any {
        return this.queue[idx];
    }

    public clear (): void {
        this.queue = [];
    }

    public count (): number {
        return this.queue.length;
    }
}

// #endregion

ContainerControl.extendWith(TerceraQuantityNumeric, {
    template: TerceraQuantityNumericTemplate,
    data: function () {
        return {
            isPosAbsolute: false,
            account: null,
            instrument: null,
            inLots: false,
            height: '',
            // TODO. Ugly. Remove.
            defaultQuantity: null,
            noArrowCB: false,
            showArrows: true,
            assetNameForCash: '' // #122525
        };
    }
});
