// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InputParameterBase } from './InputParameterBase';
import { InputParameterType } from './InputParameterEnum';

export class InputParameterCombobox extends InputParameterBase {
    public Variants: any[];

    constructor (variableName = 'Empty', comment = '', inputNumber = 0, variants: any[] = []) {
        super(variableName, InputParameterType.COMBOBOX, comment, inputNumber);
        this.Variants = variants;
    }
}
