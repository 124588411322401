// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MathUtils } from '../MathUtils';

export class _PriceFormatter {
    private readonly _numberFormatters = new Map<number, Intl.NumberFormat>();
    private readonly _numberFormattersWithoutZeroes = new Map<number, Intl.NumberFormat>();

    public getNumberFormatter (point: number): Intl.NumberFormat {
        if (!this._numberFormatters.has(point)) {
            const numberFormatter = new Intl.NumberFormat(undefined, { minimumFractionDigits: point, maximumFractionDigits: point });
            this._numberFormatters.set(point, numberFormatter);
        }

        return this._numberFormatters.get(point);
    }

    private getNumberFormatWithoutZeroes (point: number): Intl.NumberFormat {
        if (!this._numberFormattersWithoutZeroes.has(point)) {
            const numberFormatter = new Intl.NumberFormat(undefined, { minimumFractionDigits: 0, maximumFractionDigits: point });
            this._numberFormattersWithoutZeroes.set(point, numberFormatter);
        }
        return this._numberFormattersWithoutZeroes.get(point);
    }

    public formatPrice (price: number, point: number, withRounding: boolean | undefined = undefined): string {
        if (isNaN(price)) {
            price = 0;
        }
        if (!withRounding) {
            price = MathUtils.TruncateDouble(price, point);
        }
        const formatter = this.getNumberFormatter(point);
        return formatter.format(price);
    }

    public formatPriceWithoutZeroes (price: number, point: number, withRounding: boolean | undefined = undefined): string {
        if (isNaN(price)) {
            price = 0;
        }
        if (!withRounding) {
            price = MathUtils.TruncateDouble(price, point);
        }
        const formatter = this.getNumberFormatWithoutZeroes(point);
        return formatter.format(price);
    }
}

export const PriceFormatter: _PriceFormatter = new _PriceFormatter();
