// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class _Enum {
    IsDefined (enumeration, val): boolean {
        for (const key in enumeration) {
            if (enumeration[key] === val) {
                return true;
            }
        }
        return false;
    }

    TakeKeysFromEnum<T extends string, K> (enumObject: Record<T, K>): string[] {
        const keysArr: string[] = [];
        for (const key in enumObject) {
            if (isNaN(Number(key))) {
                keysArr.push(key);
            }
        }

        return keysArr;
    }

    TakeValueFromEnum<T extends string, K> (enumObject: Record<T, K>): string[] {
        const valuesArr: string[] = [];
        for (const key in enumObject) {
            if (!isNaN(Number(key))) {
                valuesArr.push(key);
            }
        }

        return valuesArr;
    }
}

export const Enum = new _Enum();
