// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class DialogResultsStuct {
    public Button = DialogResultsStuct.CallBackInitiator.notSet;
    public Data = null;

    public static readonly CallBackInitiator =
        {
            notSet: -1,
            okClick: 0,
            cancelClick: 1,
            closeClick: 2
        };
}
