// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { MathUtils } from '../../Utils/MathUtils';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { DPPositionSizingControlsTemplate } from '../../templates.js';
import { DynPropertyControl } from '../elements/DynPropertyControl';
import $ from 'jquery';

interface IPercentCashItem {
    text: string
    canCheck: boolean
    checked: boolean
    tag: string
    enabled: boolean
    event: any
}

export class DPPositionSizingControls extends DynPropertyControl {
    public static readonly PercentTag = 'percent';
    public static readonly FixedTag = 'fixed';

    public selectedAccRiskModeTag: string | null = null;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): string { return 'DPPositionSizingControls'; }

    public override oninit (): void {
        super.oninit();

        this.on('fixedOrPercentageBtnClick', this.fixedOrPercentageBtnClick);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.selectAccRiskMode(DPPositionSizingControls.PercentTag, true);
        this.localize();
    }

    public selectAccRiskMode (tag: string, fromOnComplete: boolean): void {
        if (this.selectedAccRiskModeTag === tag) {
            return;
        }

        this.selectedAccRiskModeTag = tag;

        const accCur = this.get('Currency');
        const isPercent = this.isPercentMode();
        const valueToShowInBtn = isPercent ? '%' : accCur;

        if (!fromOnComplete) {
            const newV = this.get('dp.riskValue');
            void this.set('AccountRisk', isPercent ? MathUtils.TruncateDouble(newV, 2) : newV);
        }

        void this.set('dp.riskModeBtnText', valueToShowInBtn);
        void this.set({
            riskModeBtnText: valueToShowInBtn,
            riskLabel: Resources.getResource('positionSizeCalculator.' + (isPercent ? 'cashRiskLabel' : 'percentRiskLabel'))
        });
    }

    public fixedOrPercentageBtnClick (): void {
        if (!this.get('enabled')) { return; }

        const items = this.getPercentCashItems();
        if (!isNullOrUndefined(items)) {
            const pos = $(this.find('.js-button-fixedOrPercentMode')).offset();
            contextMenuHandler.Show(items, pos.left, pos.top + 21, { width: 111 });
        }
    }

    public getPercentCashItems (): IPercentCashItem[] {
        const isPercentMode = this.isPercentMode();

        const items: IPercentCashItem[] = [{
            text: Resources.getResource('positionSizeCalculator.FixedMenuItem'),
            canCheck: true,
            checked: !isPercentMode,
            tag: DPPositionSizingControls.FixedTag,
            enabled: true,
            event: this.selectAccRiskMode.bind(this, DPPositionSizingControls.FixedTag)
        }, {
            text: Resources.getResource('positionSizeCalculator.PercentMenuItem'),
            canCheck: true,
            checked: isPercentMode,
            tag: DPPositionSizingControls.PercentTag,
            enabled: true,
            event: this.selectAccRiskMode.bind(this, DPPositionSizingControls.PercentTag)
        }];

        return items;
    }

    public isPercentMode (): boolean {
        return this.selectedAccRiskModeTag === DPPositionSizingControls.PercentTag;
    }

    public override localize (): void {
        super.localize();

        void this.set({
            accountRiskLabel: Resources.getResource('positionSizeCalculator.accountRisk'),
            accountRiskTT: Resources.getResource('positionSizeCalculator.accountRisk.tt'),
            riskLabel: Resources.getResource('positionSizeCalculator.cashRiskLabel')
        });
    }
}

DynPropertyControl.extendWith(DPPositionSizingControls, {
    template: DPPositionSizingControlsTemplate,
    data: function () {
        return {
            positionSizingIsVisible: false, // скрываем через эту переменную, а не visible потому что для корректной работы нужно чтобы контролы были созданы
            riskModeBtnText: '%', // текст в кнопке у нумерика (% или валюта)
            riskLabel: '', // подпись второй строки

            showLabel: true,
            width: 176
        };
    },
    computed: {
        AccountRisk: {
            get: function () {
                return this.get('dp.value');
            },
            set: function (value) {
                void this.set('dp.value', value);
            }
        },
        OtherRisk: {
            get: function () {
                return this.get('dp.riskValueFormatted');
            },
            set: function (value) {
                void this.set('dp.riskValueFormatted', value);
            }
        },
        Currency: {
            get: function () {
                return this.get('dp.currencyRiskModeSuffix');
            },
            set: function (value) {
                void this.set('dp.currencyRiskModeSuffix', value);
            }
        }
    }
});
