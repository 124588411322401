// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DrawingsManagerItem } from '../../cache/ObjectManager/DrawingsManagerItem';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { ObjectManagerPanelBase } from './ObjectManagerPanelBase';
import { TerceraChartToolRenderer } from '@front/chart/Renderers/Tools/TerceraChartToolRenderer';
import { type ToolView } from '@front/chart/Tools/ToolView';
import { type DataCacheTool } from '@shared/commons/cache/DataCacheTool';
import { ToolsCache } from '@shared/commons/cache/ToolsCache';
import { TerceraChartActionEnum } from '@front/chart/TerceraChartAction';

export class DrawingsManagerPanel extends ObjectManagerPanelBase<DrawingsManagerItem, DataCacheTool> {
    protected override readonly ChartActionShowOnChart: TerceraChartActionEnum = TerceraChartActionEnum.ShowOnChart;
    protected override readonly ChartActionSettings: TerceraChartActionEnum = TerceraChartActionEnum.ToolSettings;
    protected override readonly ChartActionRemove: TerceraChartActionEnum = TerceraChartActionEnum.DeleteTool;
    protected override readonly ChartActionDeleteAll: TerceraChartActionEnum = TerceraChartActionEnum.DeleteAllTools;

    constructor () {
        super();
        this.Name = 'DrawingsManagerPanel';
        this.headerLocaleKey = 'chart.head.line';
    }

    public override getType (): PanelNames { return PanelNames.DrawingsManagerPanel; }

    public override oncomplete (): void {
        super.oncomplete();
        ToolsCache.OnAddTool.Subscribe(this.AddItemToTable, this);
        ToolsCache.OnRemoveTool.Subscribe(this.RemoveItemFromTable, this);
    }

    public override onteardown (): void {
        ToolsCache.OnAddTool.UnSubscribe(this.AddItemToTable, this);
        ToolsCache.OnRemoveTool.UnSubscribe(this.RemoveItemFromTable, this);
        super.onteardown();
    }

    public AddItemToTable (tool: DataCacheTool): void {
        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }

        quickTable.AddItem(new DrawingsManagerItem(tool));
    }

    public override populateItemsDirect (): void {
        super.populateItemsDirect();
        if (isNullOrUndefined(this.Chart)) {
            return;
        }

        for (const window of this.Chart.windowsContainer.Windows) {
            const renderer = window.GetRenderer(TerceraChartToolRenderer);
            this.populateItems(renderer.tools);
        }
    }

    public populateItems (tools: ToolView[]): void {
        for (const tool of tools) {
            this.AddItemToTable(tool.dataCacheTool);
        }
    }
}
