// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { StandardFormatterName, type AccountManagerColumn } from '../charting_library';
import { BaseCustomTableFormatterName } from './CustomTableFormatters/BaseCustomTableFormatterName';
import { TvBaseColumns } from './TvBaseColumns';

class _TvPositionColumns extends TvBaseColumns {
    public getPositionColumns (): AccountManagerColumn[] {
        const symbol = this.createColumnItem('panel.positions.Symbol', 'symbol', StandardFormatterName.Symbol);
        const side = this.createColumnItem('panel.positions.Operation', 'side', StandardFormatterName.PositionSide);
        const qty = this.createColumnItem('panel.positions.Qunatity', 'qty', StandardFormatterName.FormatQuantity);
        const avgPrice = this.createColumnItem('TvTradingPlatform.Positions.AvgFillPrice', 'avgPrice', StandardFormatterName.FormatPrice);
        const last = this.createColumnItem('panel.positions.cur_price', 'last', StandardFormatterName.FormatPrice, true);
        const takeProfit = this.createColumnItem('panel.positions.TP', 'takeProfit', StandardFormatterName.FormatPrice);
        const stopLoss = this.createColumnItem('panel.positions.SL', 'stopLoss', StandardFormatterName.FormatPrice);
        const netpl = this.createColumnItem('panel.positions.Net_PL', 'netpl', BaseCustomTableFormatterName.OpenNetPL);
        const pl = this.createColumnItem('panel.positions.profit_usd', 'pl', BaseCustomTableFormatterName.OpenGrossPL);
        const id = this.createColumnItem('panel.positions.pos_number', 'id', StandardFormatterName.Text);
        const swaps = this.createColumnItem('panel.positions.swaps', 'swaps', StandardFormatterName.Profit);
        const fee = this.createColumnItem('panel.positions.commissions', 'fee', StandardFormatterName.Profit);

        const columns =
        [
            symbol,
            side,
            qty,
            avgPrice,
            last,
            takeProfit,
            stopLoss,
            netpl,
            pl,
            id,
            swaps,
            fee
        ];

        return columns.filter(c => c !== null);
    }
}

export const TvPositionColumns = new _TvPositionColumns();
