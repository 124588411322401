// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class ATR extends IndicatorScriptBase {
    public MAPeriod: number;
    public MAType: number;

    constructor () {
        super();
        this.ProjectName = 'Average True Range';
        this.Comments = 'Measures market volatility';
        super.SetIndicatorLine('Line', Color.Blue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.MAPeriod = 13;
        super.InputParameter(new InputParameterInteger('MAPeriod', 'Period of Moving Average', 0, 1, 9999));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of moving average', 1,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName('ATR(' + this.MAPeriod + ')');
    };

    public override GetIndicatorShortName (): string {
        return 'ATR(' + this.MAPeriod + ')';
    };

    public override getCustomName (): string {
        return 'ATR (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        if (this.CurrentData.Count < this.MAPeriod) { return; };

        switch (this.MAType) {
        case MAMode.SMA:
            this.SetValue(0, 0, this.GetSMA());
            break;

        case MAMode.EMA:
            this.SetValue(0, 0, this.GetEMA());
            break;

        case MAMode.SMMA:
            this.SetValue(0, 0, this.GetMMA());
            break;

        case MAMode.LWMA:
            this.SetValue(0, 0, this.GetLWMA());
            break;
        }
    };

    public GetTrueRange (index: number): any {
        const CurrentData = this.CurrentData;

        const hi = CurrentData.GetPrice(PriceType.High, index);
        const lo = CurrentData.GetPrice(PriceType.Low, index);
        const prevClose =
            CurrentData.Count > index + 1
                ? CurrentData.GetPrice(PriceType.Close, index + 1)
                : CurrentData.GetPrice(PriceType.Close, index);

        return Math.max(
            hi - lo,
            Math.max(Math.abs(prevClose - hi), Math.abs(prevClose - lo)));
    };

    public GetSMA (): number {
        let summa = 0; // Sum of prices
        // Loop of calculation.
        for (let i = 0; i < this.MAPeriod; i++) { summa += this.GetTrueRange(i); };
        // Returning current value of the SMA
        return summa / this.MAPeriod;
    };

    public GetEMA (): number {
        // Calculation of a coefficient
        const k = 2 / (this.MAPeriod + 1);
        // Sum of prices
        let summa = this.GetTrueRange(this.MAPeriod - 1);

        for (let i = this.MAPeriod - 2; i >= 0; i--) { summa += k * (this.GetTrueRange(i) - summa); };

        // Returning value
        return summa;
    };

    public GetMMA (): number {
        // Current price
        let mma = this.GetTrueRange(0);
        // coefficient
        const k = 1 / this.MAPeriod;

        // Loop of calculation.
        for (let i = 0; i < this.MAPeriod && mma > 0; i++) { mma = this.GetTrueRange(i) * k + mma * (1 - k); };

        // Returning of mma
        return mma;
    };

    public GetLWMA (): number {
        // Numerator of the rate
        let numerator = 0;
        // Denominator of the rate
        let denominator = 0;
        // period counter
        let period = this.MAPeriod;

        // Loop of calculation.
        for (let i = 0; i < this.MAPeriod; i++) {
            numerator += period * this.GetTrueRange(i);
            denominator += period;

            period--;
        }

        return denominator === 0
            ? 0
            : numerator / denominator;
    };
}
