// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type QuotesCallback } from '../charting_library';
import { TvQuoteSubscriptionItem } from './TvQuoteSubscriptionItem';

export class TvQuoteSubscriptionsContainer {
    private readonly subscriptionsMap = new Map<string, TvQuoteSubscriptionItem>();

    public subscribeQuotes (symbols: string[], fastSymbols: string[], onRealtimeCallback: QuotesCallback, listenerGUID: string): void {
        if (!this.subscriptionsMap.has(listenerGUID)) {
            const newSubscriptionItem = new TvQuoteSubscriptionItem();
            this.subscriptionsMap.set(listenerGUID, newSubscriptionItem);
        }

        const subscriptionItem = this.subscriptionsMap.get(listenerGUID);
        subscriptionItem.subscribe(symbols, fastSymbols, onRealtimeCallback);
    }

    public unsubscribeQuotes (listenerGUID: string): void {
        if (!this.subscriptionsMap.has(listenerGUID)) {
            return;
        }
        const subscriptionItem = this.subscriptionsMap.get(listenerGUID);
        subscriptionItem.unsubscribe();
        this.subscriptionsMap.delete(listenerGUID);
    }

    public changeCurrentAccount (): void {
        this.subscriptionsMap.forEach((subscriptionItem) => {
            subscriptionItem.sendRealtimeData();
        });
    }
}
