// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class _AuthenticationStateStorage {
    saveConnection (uuid: string, connectionName: string): void {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 1);
        const encodedConnectionName = encodeURIComponent(connectionName);
        document.cookie = `${uuid}=${encodedConnectionName}; expires=${expirationDate.toUTCString()}; path=/`;
    }

    restoreConnection (search: string): string {
        if (!isValidString(search)) {
            return '';
        }
        const queryParams = new URLSearchParams(search);
        const state = queryParams.get('state');
        if (!isValidString(state)) {
            return '';
        }
        const cookieMatch = document.cookie.match(new RegExp(`(?:^|;\\s*)${state}=([^;]*)`));
        return cookieMatch ? decodeURIComponent(cookieMatch[1]) : '';
    }
}

export const AuthenticationStateStorage = new _AuthenticationStateStorage();
