// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { BaseRowDataItem, RowColorText, RowIndexNames } from './BaseRowDataItem';

export class WatchlistRowDataItem extends BaseRowDataItem {
    public static readonly COLUMNAMOUNT = 3;

    public getColumnAmount (): number {
        return WatchlistRowDataItem.COLUMNAMOUNT;
    }

    public override getValue (index: RowIndexNames): any {
        const source = this.source;
        switch (index) {
        case RowIndexNames.NAME:
            return source.Symbol;

        case RowIndexNames.DESCRIPTION:
            return source.Description;

        case RowIndexNames.IMG:
            return source.Img;

        case RowIndexNames.COLUMN_ONE:
            return source.LastFormatted;

        case RowIndexNames.COLUMN_TWO:
            return source.Change;

        default:
            return null;
        }
    }

    public override getColor (index: RowIndexNames, prewValue = null): RowColorText {
        const source = this.source;
        switch (index) {
        case RowIndexNames.COLUMN_ONE:
            if (!prewValue) {
                return RowColorText.BLACK;
            } else {
                if (this.source.Last === prewValue.source.Last) {
                    return RowColorText.BLACK;
                }
                const profitColor = this.source.Last > prewValue.Last ? RowColorText.GREEN : RowColorText.RED;
                return profitColor;
            }

        case RowIndexNames.COLUMN_TWO:
            if (source.ChangeValue && source.ChangeValue !== 0) {
                const profitColor = source.ChangeValue > 0 ? RowColorText.GREEN : RowColorText.RED;
                return profitColor;
            } else {
                return RowColorText.BLACK;
            }

        default:
            return RowColorText.BLACK;
        }
    }
}
