// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Rectangle, type Point } from '../../../../Commons/Geometry';
import { SolidBrush } from '../../../../Commons/Graphics';
import { HeatmapSymbolFirstValue, HeatmapSymbolSecondValue, HeatmapSymbolTitleBy } from '../../../../Commons/Heatmap/Enums';
import { type SymbolMapItem } from '../../../../Commons/Heatmap/Maps/SymbolMapItem';
import { HeatMapFontManagerParams } from '../../../../Commons/Heatmap/Settings/HeatMapFontManagerParams';
import { type IHeatmapViewSettings } from '../../../../Commons/Heatmap/Settings/IHeatmapViewSettings';
import { HeatmapBaseRenderer } from './HeatmapBaseRenderer';
import { HeatmapLogoProvider } from '../HeatmapLogoProvider';
import { ThemeManager } from '../../../misc/ThemeManager';
import { type HeatmapData } from '../../../../Commons/Heatmap/Models/HeatmapData';

export class HeatmapSymbolRenderer extends HeatmapBaseRenderer {
    private readonly symbolMapItem: SymbolMapItem;
    private readonly settings: IHeatmapViewSettings;
    private readonly heatMapFontManagerParams: HeatMapFontManagerParams = new HeatMapFontManagerParams();

    constructor (symbolMapItem: SymbolMapItem, settings: IHeatmapViewSettings) {
        super();
        this.symbolMapItem = symbolMapItem;
        this.settings = settings;
    }

    public draw (gr: CanvasRenderingContext2D, mousePoint: Point): void {
        if (!this.symbolMapItem.Visible) {
            return;
        }

        const rect = this.symbolMapItem.Rectangle;
        const parentRect: Rectangle = this.symbolMapItem.parentIndustryMap.bodyRectangle;

        let width = rect.Width;
        let height = rect.Height;

        // Adjust width and height based on the parent rectangle
        if (rect.X + rect.Width !== parentRect.X + parentRect.Width) {
            width--;
        }

        if (rect.Y + rect.Height !== parentRect.Y + parentRect.Height) {
            height--;
        }

        const backgroundBrush = new SolidBrush(this.symbolMapItem.getSymbolBackgroundColor());
        const textBrush = new SolidBrush(this.settings.symbolLevelFontColor);

        const drawRect = new Rectangle(rect.X, rect.Y, width, height);
        gr.FillRectWithRect(backgroundBrush, drawRect);

        const title = this.settings.symbolLevelTitleBy === HeatmapSymbolTitleBy.Symbol
            ? this.symbolMapItem.heatmapData.symbol
            : this.symbolMapItem.heatmapData.symbolDescription;
        const firstValue = this.settings.symbolLevelFirstValue === HeatmapSymbolFirstValue.ChangePercentage
            ? this.symbolMapItem.heatmapData.changePercentString
            : '';
        let secondValue = '';
        switch (this.settings.symbolLevelSecondValue) {
        case HeatmapSymbolSecondValue.None:
            secondValue = '';
            break;
        case HeatmapSymbolSecondValue.LastPrice:
            secondValue = this.symbolMapItem.heatmapData.lastPriceString;
            break;
        case HeatmapSymbolSecondValue.MarketCap:
            secondValue = this.symbolMapItem.heatmapData.marketCapString;
            break;
        }

        let logoSize = 24;
        this.heatMapFontManagerParams.logoSize = this.settings.logoVisible && HeatmapLogoProvider.isLogoExist(this.symbolMapItem.heatmapData.logoAddress) ? logoSize : -1;
        this.heatMapFontManagerParams.title = title;
        this.heatMapFontManagerParams.firstValue = firstValue;
        this.heatMapFontManagerParams.secondValue = secondValue;
        this.heatMapFontManagerParams.rectangle = rect;
        this.heatMapFontManagerParams.graphics = gr;

        const result = this.settings.getSymbolFontResult(this.heatMapFontManagerParams);

        if (isNullOrUndefined(result)) {
            this.drawSelection(gr, drawRect, mousePoint);
            return;
        }

        const logoRect = result.logoRectangle;
        logoSize = result.logoRectangle.Height;
        const titleRect = result.titleRectangle;
        const titleFont = result.titleFont;
        const firstValueRect = result.firstValueRectangle;
        const firstValueFont = result.firstValueFont;
        const secondValueRect = result.secondValueRectangle;
        const secondValueFont = result.secondValueFont;

        let logoImage: any = null;
        if (logoSize > 0) {
            logoImage = HeatmapLogoProvider.getLogo(this.symbolMapItem.heatmapData.logoAddress);
        }

        if (!logoRect.IsEmpty() && !isNullOrUndefined(logoImage)) {
            gr.drawImage(logoImage, logoRect.X, logoRect.Y, logoRect.Width, logoRect.Height);
        }
        if (!titleRect.IsEmpty()) {
            gr.DrawStringInRect(title, titleFont, textBrush, titleRect, false);
        }

        if (!firstValueRect.IsEmpty()) {
            gr.DrawStringInRect(firstValue, firstValueFont, textBrush, firstValueRect, false);
        }

        if (!secondValueRect.IsEmpty()) {
            gr.DrawStringInRect(secondValue, secondValueFont, textBrush, secondValueRect, false);
        }

        this.drawSelection(gr, drawRect, mousePoint);
    }

    private drawSelection (gr: CanvasRenderingContext2D, rectangle: Rectangle, mousePoint: Point): void {
        if (this.isHovered(mousePoint)) {
            const strokeLineWidth = 1;
            gr.strokeStyle = ThemeManager.CurrentTheme.HeatmapPanelHeaderHoveredColor;
            gr.lineWidth = strokeLineWidth;
            gr.strokeRect(rectangle.X - 0.5, rectangle.Y, rectangle.Width + strokeLineWidth, rectangle.Height + 0.5);
        }
    }

    public isHovered (mousePoint: Point): boolean {
        if (!this.symbolMapItem.Visible) {
            return false;
        }
        return !mousePoint.IsEmpty() && this.symbolMapItem.Rectangle.Contains(mousePoint.X, mousePoint.Y);
    }

    public getData (): HeatmapData {
        return this.symbolMapItem.heatmapData;
    }
}
