// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { LOCALE_EN, Resources } from '../../../Localizations/Resources';
import { TvChartSupportedResolutionArray } from '../TradingViewPrimitives/TvEnums';
import { type ResolutionString } from '../charting_library';

class _TvHistoryAggregationsHelper {
    private readonly _keySupportedResolutions: string = 'TvTradingPlatform.SupportedResolutions';
    private readonly _keyCustomAggregationsAllowed: string = 'TvTradingPlatform.CustomAggregationsAllowed';
    private _isHasCustomAggregations: boolean = false;
    private _isCustomAggregationsAllowed: boolean = false;

    private readonly ticksResolution: ResolutionString [] = [];

    private readonly secondResolutions: ResolutionString [] = [];
    private readonly secondMultipliers: string [] = [];

    private readonly dayResolutions: ResolutionString [] = [];
    private readonly dayMultipliers: string [] = [];

    private readonly weekResolutions: ResolutionString [] = [];
    private readonly weekMultipliers: string [] = [];

    private readonly monthResolutions: ResolutionString [] = [];
    private readonly monthMultipliers: string [] = [];

    private readonly intradayResolutions: ResolutionString [] = [];
    private readonly intradayMultipliers: string [] = [];

    public getSupportedResolutions (): ResolutionString[] {
        if (!this._isHasCustomAggregations) {
            return TvChartSupportedResolutionArray as ResolutionString[];
        }

        return [
            ...this.ticksResolution,
            ...this.secondResolutions,
            ...this.intradayResolutions,
            ...this.dayResolutions,
            ...this.weekResolutions,
            ...this.monthResolutions
        ];
    }

    public getSecondsMultipliers (): string [] {
        if (!this._isHasCustomAggregations) {
            return [];
        }
        return this.secondMultipliers;
    }

    public getIntradayMultipliers (): string [] {
        if (!this._isHasCustomAggregations) {
            return ['1', '2', '3', '5', '15', '30', '60', '240'];
        }
        return this.intradayMultipliers;
    }

    public getDailyMultipliers (): string [] {
        if (!this._isHasCustomAggregations) {
            return ['1'];
        }
        return this.dayMultipliers;
    }

    public getWeeklyMultipliers (): string [] {
        if (!this._isHasCustomAggregations) {
            return ['1'];
        }
        return this.weekMultipliers;
    }

    public getMonthlyMultipliers (): string [] {
        if (!this._isHasCustomAggregations) {
            return ['1', '12'];
        }
        return this.monthMultipliers;
    }

    public hasWeeklyAndMonthly (): boolean {
        return this.getWeeklyMultipliers().length > 0 && this.getMonthlyMultipliers().length > 0;
    }

    public hasDaily (): boolean {
        return this.getDailyMultipliers().length > 0;
    }

    public hasIntraday (): boolean {
        return this.getIntradayMultipliers().length > 0;
    }

    public hasSeconds (): boolean {
        return this.getSecondsMultipliers().length > 0;
    }

    public hasTicks (): boolean {
        return this.ticksResolution.length > 0;
    }

    public isCustomAggregationsAllowed (): boolean {
        return this._isCustomAggregationsAllowed;
    }

    public parseKeys (): void {
        this.parseCustomAggregations();
        this.parseIsCustomAggregationsAllowed();
    }

    private parseCustomAggregations (): void {
        this._isHasCustomAggregations = Resources.IsResourcePresentEN(this._keySupportedResolutions) &&
            !Resources.isHidden(this._keySupportedResolutions);

        if (!this._isHasCustomAggregations) {
            return;
        }

        try {
            const json = Resources.getResourceLang(this._keySupportedResolutions, LOCALE_EN);
            const data: ResolutionString[] = JSON.parse(json) as ResolutionString[];
            if (data.length === 0) {
                this._isHasCustomAggregations = true;
            }

            for (const agg of data) {
                if (agg.endsWith('T')) {
                    this.ticksResolution.push(agg);
                } else if (agg.endsWith('S')) {
                    this.secondResolutions.push(agg);
                    this.secondMultipliers.push(this.getMultiplier(agg));
                } else if (agg.endsWith('D')) {
                    this.dayResolutions.push(agg);
                    this.dayMultipliers.push(this.getMultiplier(agg));
                } else if (agg.endsWith('W')) {
                    this.weekResolutions.push(agg);
                    this.weekMultipliers.push(this.getMultiplier(agg));
                } else if (agg.endsWith('M')) {
                    this.monthResolutions.push(agg);
                    this.monthMultipliers.push(this.getMultiplier(agg));
                } else {
                    this.intradayResolutions.push(agg);
                    this.intradayMultipliers.push(this.getMultiplier(agg));
                }
            }
        } catch (e) {
            this._isHasCustomAggregations = false;
            console.error('Error parsing custom TV aggregations');
        }
    }

    private parseIsCustomAggregationsAllowed (): void {
        try {
            this._isCustomAggregationsAllowed = Resources.getResourceLang(this._keyCustomAggregationsAllowed, LOCALE_EN) === 'true';
        } catch (e) {
            this._isCustomAggregationsAllowed = false;
            console.error('Error parsing TV allow custom aggregations');
        }
    }

    private getMultiplier (resolution: string): string {
        return resolution.slice(0, -1);
    }
}

export const TvHistoryAggregationsHelper = new _TvHistoryAggregationsHelper();
