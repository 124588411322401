// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Periods } from './TFInfo';
import { Message } from '../DirectMessages/DirectMessagesImport';
import { DateFormater } from '../Time/DateFormater';
import { HistoryType } from './HistoryType';
import { CashItemUtils } from './CashItemUtils';
import { BaseIntervalInputParams } from './BaseIntervalInputParams';
import { type Instrument } from '../../Commons/cache/Instrument';
import { GetHistoryCreator } from './IHistoryMergerCreator';
import { HistoryMergerInputParams } from './HistoryMergerInputParams';
import { type Interval } from './Interval.js';
import { MathUtils } from '../MathUtils';
import { type SpreadItem } from '../../Commons/cache/SpreadItem';
import { MainTypes } from '../Session/MainTypes';

//
// Хранение истории. Аналог кешитема в клиенте
//

export class BaseInterval {
    public Data: number[];

    public static readonly OPEN_INDEX = 0;
    public static readonly CLOSE_INDEX = 1;
    public static readonly HIGH_INDEX = 2;
    public static readonly LOW_INDEX = 3;

    public static readonly SEC_LENGTH = 1000;

    public FLeftTimeTicks: number;
    public FRightTimeTicks: number;
    public Volume: number;
    public Ticks: number;

    public LeftTime: Date;
    public RightTime: Date;

    public LastQuoteId: number | null = null;
    public LastQuoteIdDate: number | null = null;

    public SessionType: number = MainTypes.MAIN_CONTINUOUS1;

    constructor (newObj: BaseIntervalInputParams = new BaseIntervalInputParams(), Period?: number) {
        if (Period === Periods.TIC) {
            this.Data = new Array<number>(2);
            this.Data[BaseInterval.OPEN_INDEX] = newObj.Open;
            this.Data[BaseInterval.CLOSE_INDEX] = newObj.Close;
        } else {
            this.Data = new Array<number>(4);
            this.Data[BaseInterval.OPEN_INDEX] = newObj.Open;
            this.Data[BaseInterval.CLOSE_INDEX] = newObj.Close;
            this.Data[BaseInterval.HIGH_INDEX] = newObj.High;
            this.Data[BaseInterval.LOW_INDEX] = newObj.Low;
        }

        /// <summary>
        /// Границы бара
        /// </summary>
        this.FLeftTimeTicks = newObj.LeftTimeTicks || 0;
        // this.FRightTimeTicks = newObj.FRightTimeTicks || 0;

        this.Volume = newObj.Volume;
        this.Ticks = newObj.Ticks || 0;

        const ref_leftBorder_rightBorder = { leftBorder: 0, rightBorder: 0 };
        this.GetBorders(new Date(this.FLeftTimeTicks), Period, ref_leftBorder_rightBorder, 0, !!newObj.correctStartTime, !!newObj.onlyMainSession, newObj.instrument);

        this.FLeftTimeTicks = ref_leftBorder_rightBorder.leftBorder;
        this.FRightTimeTicks = ref_leftBorder_rightBorder.rightBorder;

        this.LeftTime = new Date(this.FLeftTimeTicks);
        this.RightTime = new Date(this.FRightTimeTicks);
    }

    public toString (): string {
        return this.FLeftTimeTicks + ' - ' + this.FRightTimeTicks;
    }

    public static GetIntervalLength (period): number {
        if (period <= Periods.TIC) {
            return 0;
        } else if (period > 0 && period % Periods.SECOND === 0) {
            return (period * BaseInterval.SEC_LENGTH) / Periods.SECOND;
        } else if (period > 0 && period % Periods.RANGE === 0) {
            return 0;
        } else {
            return 60 * BaseInterval.SEC_LENGTH * period;
        }
    }

    public CalculateNextInterv (
        startTime,
        serverOffset,
        Period: number,
        instrument: Instrument,
        onlyMainSession: boolean): number {
        // Если тики, то интервал - следующий
        if (Period == Periods.TIC) { return 0; }
        const ref_leftBorder_rightBorder = { leftBorder: 0, rightBorder: 0 };
        this.GetBorders(startTime, Period, ref_leftBorder_rightBorder, serverOffset, false, onlyMainSession, instrument);
        // Расчитываем длину интервала между периодами
        const timeSpan = ref_leftBorder_rightBorder.leftBorder - this.FRightTimeTicks;
        // Расчитываем длину интервала в дотнетовских тиках
        const periodSpan = BaseInterval.GetIntervalLength(Period);
        // Возвращаем количество пустых баров
        return periodSpan > 0 ? Math.floor(timeSpan / periodSpan) : 0;
    }

    private GetBorders (startTime, period, ref_leftBorder_rightBorder, serverOffset, correctStartTime, onlyMainSession, instrument): void {
        if (period <= Periods.TIC) {
            ref_leftBorder_rightBorder.leftBorder = startTime.getTime();
            ref_leftBorder_rightBorder.rightBorder = ref_leftBorder_rightBorder.leftBorder;
        } else {
            const year = startTime.getFullYear();
            const month = startTime.getMonth();
            const day = startTime.getDate();
            const hour = startTime.getHours();
            const minute = startTime.getMinutes();
            const seconds = startTime.getSeconds();

            if (period > 0 && (period % Periods.SECOND === 0))// секунды
            {
                ref_leftBorder_rightBorder.leftBorder = (new Date(year, month, day, hour, minute, seconds, 0)).getTime();
            } else if (period >= Periods.HOUR && period % Periods.HOUR === 0)// period под часы или дни
            {
                ref_leftBorder_rightBorder.leftBorder = (new Date(year, month, day, hour, minute, 0, 0)).getTime(); ; // #48134 - добавил минутки для дневок, потому что при экспорте данных обрезались минуты, кроме этого на чарте не отображаются минуты
            } else {
                // nicky было "seconds, 0" за каким-то хреном. убрал, а то build highest periods не работает (в частности)
                ref_leftBorder_rightBorder.leftBorder = (new Date(year, month, day, hour, minute, 0, 0)).getTime(); // банальные минутки
            }
            ref_leftBorder_rightBorder.rightBorder = ref_leftBorder_rightBorder.leftBorder + BaseInterval.GetIntervalLength(period);

            if (instrument && Periods.IsBarAggregation(period)) {
                const interval = this.CorrectBordersForAggregations(
                    new Date(ref_leftBorder_rightBorder.leftBorder),
                    instrument,
                    period,
                    !onlyMainSession);

                ref_leftBorder_rightBorder.leftBorder = interval.From.getTime();
                ref_leftBorder_rightBorder.rightBorder = interval.To.getTime();
                return;
            }

            /* if (correctStartTime && period > Periods.MIN && period < Periods.HOUR)
            {
                // Берем текущую точу во времени (в тиках)
                long currentPoint = (new Date(year, month, day, hour, minute, seconds)).getTime();;
                // Берем длину интервала в тиках
                long intervalLength = period * SEC_LENGTH * 60;
                // берем остаток от деления текущей точки на интервал
                long remain = currentPoint % intervalLength;
                // определяем левую и правую границы интервала
                ref_leftBorder_rightBorder.leftBorder = currentPoint - remain;
                rightBorder = ref_leftBorder_rightBorder.leftBorder + intervalLength;
            } */
            if (correctStartTime) {
                if (period == 2 * Periods.HOUR || period == 3 * Periods.HOUR || period == 4 * Periods.HOUR || period == 6 * Periods.HOUR || period == 8 * Periods.HOUR || period == 12 * Periods.HOUR) // 2h 3h 4h 6h 8h 12h - кратные дню
                {
                    const dx = period / Periods.HOUR;
                    const starthour = Math.floor((hour + serverOffset) / dx) * dx;
                    ref_leftBorder_rightBorder.leftBorder = new Date(year, month, day, 0, 0, 0);
                    ref_leftBorder_rightBorder.leftBorder = ref_leftBorder_rightBorder.leftBorder.setHours(ref_leftBorder_rightBorder.leftBorder.getHours() + starthour - serverOffset);
                    ref_leftBorder_rightBorder.rightBorder = ref_leftBorder_rightBorder.leftBorder + period * BaseInterval.SEC_LENGTH * 60;
                } else if (period > Periods.MIN && period < Periods.DAY) {
                    ref_leftBorder_rightBorder.leftBorder = CashItemUtils.FindStartData(new Date(ref_leftBorder_rightBorder.leftBorder), period, 1).getTime();
                    const intervalLength = period * BaseInterval.SEC_LENGTH * 60;
                    ref_leftBorder_rightBorder.rightBorder = ref_leftBorder_rightBorder.leftBorder + intervalLength;
                } else if (period >= Periods.MONTH && period % Periods.MONTH == 0)// period = N Monthes. годы и месяцы
                {
                    ref_leftBorder_rightBorder.rightBorder = new Date(ref_leftBorder_rightBorder.leftBorder);
                    ref_leftBorder_rightBorder.rightBorder = ref_leftBorder_rightBorder.rightBorder.setMonth(ref_leftBorder_rightBorder.rightBorder.getMonth() + period / Periods.MONTH);
                } else if (period >= Periods.YEAR && period % Periods.YEAR == 0) {
                    ref_leftBorder_rightBorder.rightBorder = new Date(ref_leftBorder_rightBorder.leftBorder);
                    ref_leftBorder_rightBorder.rightBorder = ref_leftBorder_rightBorder.rightBorder.setFullYear(ref_leftBorder_rightBorder.rightBorder.getFullYear() + period / Periods.YEAR);
                } else if (period > Periods.DAY && period % Periods.WEEK != 0 && period % Periods.SECOND != 0 && period % Periods.RANGE != 0) {
                    // N days чарт корректирую на 1970год - чтобы все бары начинались одинаково всегда
                    ref_leftBorder_rightBorder.leftBorder = CashItemUtils.FindStartData(new Date(ref_leftBorder_rightBorder.leftBorder), period, 1).getTime();
                    ref_leftBorder_rightBorder.rightBorder = ref_leftBorder_rightBorder.leftBorder + BaseInterval.GetIntervalLength(period);
                }
            }
        }

        // Period = period;
    }

    public CorrectBordersForAggregations (
        startTime: Date,
        instrument: any,
        period: number,
        showExtendedSession: boolean): Interval {
        const inputParams = new HistoryMergerInputParams();
        inputParams.BaseHistory = [];
        inputParams.NewPeriod = period;
        inputParams.Instrument = instrument;
        inputParams.ShowExtendedSession = showExtendedSession;
        const historyMerger = GetHistoryCreator().GetHistoryMerger(inputParams);
        return historyMerger.FindIntervalBorders(startTime);
    }

    correctBorders (Period): void {
        const ref_leftBorder_rightBorder = { leftBorder: 0, rightBorder: 0 };
        this.GetBorders(this.LeftTime, Period, ref_leftBorder_rightBorder, 0, true, undefined, undefined);
        this.FRightTimeTicks = ref_leftBorder_rightBorder.rightBorder;
        this.RightTime = new Date(this.FRightTimeTicks);
    };

    /// <summary>
    /// Установка новых границ интервала
    /// </summary>
    public SetNewBorders (leftDt, rightDt): void {
        this.FLeftTimeTicks = leftDt;
        this.LeftTime = new Date(this.FLeftTimeTicks);
        this.FRightTimeTicks = rightDt;
    };

    /// <summary>
    /// Добавление котировки в кеш.
    /// Если метод вернет +1,
    /// то время данная котировка по времени находится правее текущего интервала
    /// то есть принадлежит одному из будущих интервалов.
    /// Если метод вернет -1, то данная котировка принадлежит одному из предыдущих интервалов
    /// Если метод вернет 0, то котировка принадлежит текущему интервалу.
    /// </summary>
    public Add (msg, HistoryType, Period, spreadItem, dataCache): number {
        let tsTime = 0;
        switch (msg.Code) {
        // #region LEVEL1:
        case Message.CODE_QUOTE:
            var quoteRec = msg;

            tsTime = quoteRec.Time.getTime();

            if (tsTime >= this.FLeftTimeTicks) {
                if (Period >= Periods.TIC) {
                    // Бар по времени
                    if (tsTime < this.FRightTimeTicks) {
                        // Обновляем цены бара
                        this.UpdateBar(quoteRec, HistoryType, spreadItem, Period, dataCache);
                        // Время принадлежит текущему интервалу, не нужно создавать новый интервал в кеш
                        return 0;
                    }
                }
                // TODO пока ненужно
                // else
                // {
                //    // Бар по количеству котировок (активности)
                //    if (QuoteCounter < (-Period))
                //    {
                //        // Обновляем цены бара
                //        this.UpdateBar(quoteRec, HistoryType, spreadItem, Period);
                //        // Увеличиваем счетчик текущий котировок
                //        QuoteCounter++;
                //        this.Volume = QuoteCounter;
                //        this.Ticks = QuoteCounter;
                //        // Устанавливаем правую границу интервала - текущая котировка
                //        FRightTimeTicks = tsTime;
                //        // Время пока принадлежит текущему интервалу, не нужно создавать новый в кеш
                //        return 0;
                //    }
                // }
                // Время принадлежит следующему интервалу,
                return +1;
            }
            // Время принадлежит предыдущему интервалу, ничего не добавляем
            return -1;
            // #endregion

            // #region TRADES
        case Message.CODE_QUOTE3:
            const tsRec = msg;
            const year = tsRec.cTime.getFullYear();
            const month = tsRec.cTime.getMonth();
            const day = tsRec.cTime.getDate();
            const hour = tsRec.cTime.getHours();
            const minute = tsRec.cTime.getMinutes();
            const second = tsRec.cTime.getSeconds();
            tsTime = new Date(year, month, day, hour, minute, second, 0).getTime();
            if (tsTime >= this.FLeftTimeTicks) {
                if (Period >= Periods.TIC) {
                    // Бар по времени
                    if (tsTime < this.FRightTimeTicks) {
                        // Обновляем цены бара
                        this.UpdateBar(tsRec, HistoryType, spreadItem, Period, dataCache);
                        // Время принадлежит текущему интервалу, не нужно создавать новый интервал в кеш
                        return 0;
                    }
                }
                // else
                // {
                //    // Бар по количеству котировок (активности)
                //    if (QuoteCounter < (-Period))
                //    {
                //        // Обновляем цены бара
                //        UpdateBar(tsRec, HistoryType, spreadItem, Period);
                //        // Увеличиваем счетчик текущий котировок
                //        QuoteCounter++;
                //        // Устанавливаем правую границу интервала - текущая котировка
                //        FRightTimeTicks = tsTime;
                //        // Время пока принадлежит текущему интервалу, не нужно создавать новый в кеш
                //        return 0;
                //    }
                // }
                // Время принадлежит следующему интервалу
                return +1;
            }
            //    // Время принадлежит предыдущему интервалу
            return -1;
            // #endregion

            // #region INSTRUMENT DAY BAR
            // реканцеляция - если приходит мессадж, в этом случае нужно поправить ТЕКУЩИЙ дневной бар (т.е. последний бар)
        case Message.CODE_INSTRUMENT_DAY_BAR:
            const basePeriod = Periods.TranslateToParsePeriod(Period);
            if (basePeriod == Periods.DAY) // в принципе можно бы обойтись без этой проверки, просто, чтобы лишний раз не перевызывать метод
            {
                const idbmRec = msg;
                const tDate = idbmRec.LastTime;
                if (tDate) { tsTime = tDate.getTime(); }
                if (tsTime < this.FRightTimeTicks) { this.UpdateBar(idbmRec, HistoryType, spreadItem, Period, dataCache); }
            }
            return 0;
            // #endregion INSTRUMENT DAY BAR

            // #region // WRONG MESSAGES
        default:
            throw 'Only Quote1Message and Quote3Message instances are allowed in BaseInterval.Add method! ';
            // new Exception();
            // #endregion
        } // end of switch
    };

    /// <summary>
    /// Декомпозиция метода Add
    /// Обновить бар
    /// </summary>
    UpdateBar (msg, historyType, spreadItem, Period, dataCache): void {
        let close = 0.0;
        let high = 0.0;
        let low = 0.0;
        let temp = 0;
        switch (msg.Code) {
        case Message.CODE_QUOTE:
            var quoteRec = msg;

            //
            // Bid
            //
            if (historyType === HistoryType.QUOTE_LEVEL1)// bid
            {
                // Bid
                close = MathUtils.ProcessNaN(quoteRec.BidSpread(spreadItem), this.Data[BaseInterval.CLOSE_INDEX]);
                high = this.Data[BaseInterval.HIGH_INDEX];
                low = this.Data[BaseInterval.LOW_INDEX];
                this.Volume += quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1;
                this.Ticks += quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1;

                // Изменение цены Close
                this.Data[BaseInterval.CLOSE_INDEX] = close;
                // Пересчет цены High
                if (isNaN(high) || high < close) { this.Data[BaseInterval.HIGH_INDEX] = high = close; }
                // Пересчет цены low
                if (isNaN(low) || low > close) { this.Data[BaseInterval.LOW_INDEX] = low = close; }
            }
            //
            // Ask
            //
            else if (historyType == HistoryType.QUOTE_ASK) {
                // Ask
                close = MathUtils.ProcessNaN(quoteRec.AskSpread(spreadItem), this.Data[BaseInterval.CLOSE_INDEX]);
                high = this.Data[BaseInterval.HIGH_INDEX];
                low = this.Data[BaseInterval.LOW_INDEX];

                // Пересчет объема
                this.Volume += quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1;
                this.Ticks += quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1;

                // Изменение цены Close
                this.Data[BaseInterval.CLOSE_INDEX] = close;

                // Пересчет цены High
                if (isNaN(high) || high < close) { this.Data[BaseInterval.HIGH_INDEX] = high = close; }

                // Пересчет цены low
                if (isNaN(low) || low > close) { this.Data[BaseInterval.LOW_INDEX] = low = close; }
            }
            //
            // QUOTE_BIDASK_AVG
            //
            else if (historyType == HistoryType.QUOTE_BIDASK_AVG) {
                // Bid
                close = ((quoteRec.BidSpread(spreadItem) + quoteRec.AskSpread(spreadItem)) / 2.0);
                high = this.Data[BaseInterval.HIGH_INDEX];
                low = this.Data[BaseInterval.LOW_INDEX];
                this.Volume += quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1;
                this.Ticks += quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1;

                // Изменение цены Close
                this.Data[BaseInterval.CLOSE_INDEX] = close;
                // Пересчет цены High
                if (isNaN(high) || high < close) { this.Data[BaseInterval.HIGH_INDEX] = high = close; }
                // Пересчет цены low
                if (isNaN(low) || low > close) { this.Data[BaseInterval.LOW_INDEX] = low = close; }
            }
            //
            // QUOTE_BIDASK_SUM
            //
            else if (historyType == HistoryType.QUOTE_BIDASK_SUM) {
                // Bid
                const bid = quoteRec.BidSpread(spreadItem);
                const ask = quoteRec.BidSpread(spreadItem);
                close = bid;
                high = this.Data[BaseInterval.HIGH_INDEX];
                low = this.Data[BaseInterval.LOW_INDEX];
                this.Volume += quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1;
                this.Ticks += quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1;

                // Изменение цены Close
                this.Data[BaseInterval.CLOSE_INDEX] = close;

                // Пересчет цены High (максимум)
                temp = Math.max(bid, ask);
                if (isNaN(high) || high < temp) { this.Data[BaseInterval.HIGH_INDEX] = high = temp; }

                // Пересчет цены low (минимум)
                temp = Math.min(bid, ask);
                if (isNaN(low) || low > temp) { this.Data[BaseInterval.LOW_INDEX] = low = close; }
            }
            // для бектестера, работа по трейдам
            else if (historyType == HistoryType.QUOTE_TRADES) {
                close = quoteRec.LastPrice;
                high = this.Data[BaseInterval.HIGH_INDEX];
                low = this.Data[BaseInterval.LOW_INDEX];
                this.Volume += quoteRec.LastSize || 0;
                this.Ticks += 1;

                // Изменение цены Close
                this.Data[BaseInterval.CLOSE_INDEX] = close;
                // Пересчет цены High
                if (isNaN(high) || high < close) { this.Data[BaseInterval.HIGH_INDEX] = high = close; }
                // Пересчет цены low
                if (isNaN(low) || low > close) { this.Data[BaseInterval.LOW_INDEX] = low = close; }
            }

            break;

        case Message.CODE_QUOTE3:
            const tsRec = msg;
            close = tsRec.Price;
            high = this.Data[BaseInterval.HIGH_INDEX];
            low = this.Data[BaseInterval.LOW_INDEX];

            // Пересчет объема
            this.Volume += tsRec.Size;
            this.Ticks += 1;

            // Изменение цены Close
            this.Data[BaseInterval.CLOSE_INDEX] = close;

            // Пересчет цены High
            if (isNaN(high) || high < close) { this.Data[BaseInterval.HIGH_INDEX] = high = close; }

            // Пересчет цены low
            if (isNaN(low) || low > close) { this.Data[BaseInterval.LOW_INDEX] = low = close; }
            break;

        case Message.CODE_INSTRUMENT_DAY_BAR:

            const basePeriod = Periods.TranslateToParsePeriod(Period);

            if (basePeriod === Periods.DAY) // обновляем данные только для дневок
            {
                const instrument: Instrument = dataCache.getInstrumentByName(msg.TargetInstrumentName);
                const isExtendedPeriod: boolean = Period !== Periods.DAY;
                if (instrument != null) {
                    const idbm = msg;
                    const type = idbm.InstrumentBarType !== null && idbm.InstrumentBarType !== undefined ? idbm.InstrumentBarType : instrument.HistoryType;
                    const ohlc = instrument.InstrumentDayInfo;

                    // мы должны убедится что тип мессаджа совпадает с типом в кэшайтеме, или с типом инструмента
                    if (type === historyType) {
                        // const open = idbm.Open;// ohlc.OpenSpread(spreadItem, HistoryType); //обсудил с Сотниковым. Чисто теоретичски open может быть изменен
                        const open = ohlc.OpenSpread(spreadItem, historyType);
                        // close = idbm.LastPrice;// ohlc.MainCloseSpread(spreadItem, HistoryType);
                        close = ohlc.MainCloseSpread(spreadItem, historyType);
                        // high = idbm.High;// ohlc.HighSpread(spreadItem, HistoryType);
                        high = ohlc.HighSpread(spreadItem, historyType);
                        // low = idbm.Low;// ohlc.LowSpread(spreadItem, HistoryType);
                        low = ohlc.LowSpread(spreadItem, historyType);
                        const volume = ohlc.getTotalVolume(historyType); // ohlc.TotalVolume(HistoryType);
                        const ticks = ohlc.getTicks(historyType);// ohlc.Ticks(HistoryType);

                        if (!isNaN(open)) {
                            this.Data[BaseInterval.OPEN_INDEX] = isExtendedPeriod && !isNaN(this.Data[BaseInterval.OPEN_INDEX])
                                ? this.Data[BaseInterval.OPEN_INDEX]
                                : open;
                        }

                        if (!isNaN(high) && high !== null) {
                            this.Data[BaseInterval.HIGH_INDEX] = isExtendedPeriod && !isNaN(this.Data[BaseInterval.HIGH_INDEX])
                                ? Math.max(high, this.Data[BaseInterval.HIGH_INDEX])
                                : high;
                        }

                        if (!isNaN(low) && low !== null) {
                            this.Data[BaseInterval.LOW_INDEX] = isExtendedPeriod && !isNaN(this.Data[BaseInterval.LOW_INDEX])
                                ? Math.min(low, this.Data[BaseInterval.LOW_INDEX])
                                : low;
                        }

                        if (!isNaN(close) && close !== null) {
                            this.Data[BaseInterval.CLOSE_INDEX] = close;
                        }

                        if (!isNullOrUndefined(idbm.Volume) && !isExtendedPeriod) // 4)Volume
                        { this.Volume = volume; }

                        if (!isNullOrUndefined(idbm.Ticks) && !isExtendedPeriod) // 4)Ticks
                        { this.Ticks = ticks > 0 ? ticks : 0; }
                    }
                }
            }
            break;
        }
    };

    GetSpreadValue (instr: Instrument, spreadItem: SpreadItem, type: number, bid: number, ask: number, trade: number = 0): number {
        if (spreadItem == null) { return this.GetNonSpreadValue(type, bid, ask, trade); }

        switch (type) {
        case HistoryType.QUOTE_LEVEL1:
            return spreadItem.CalcBid(bid, ask, instr);

        case HistoryType.QUOTE_ASK:
            return spreadItem.CalcAsk(bid, ask, instr);

        case HistoryType.QUOTE_TRADES:
            return trade;

        case HistoryType.QUOTE_BIDASK_AVG:
            return !isNaN(bid) && !isNaN(ask)
                ? spreadItem.CalcBid(bid, ask, instr) / 2 + spreadItem.CalcAsk(bid, ask, instr) / 2
                : NaN;

        default:
            return trade;
        }
    }

    GetNonSpreadValue (type: number, bid: number, ask: number, trade: number): number {
        switch (type) {
        case HistoryType.QUOTE_LEVEL1:
            return bid;
        case HistoryType.QUOTE_ASK:
            return ask;
        case HistoryType.QUOTE_TRADES:
            return trade;
        case HistoryType.QUOTE_BIDASK_AVG:
            return !isNaN(bid) && !isNaN(ask) ? (bid + ask) / 2 : NaN;
        default:
            return trade;
        }
    }

    /// <summary>
    /// Получить следующтй интервал
    /// </summary>
    GetNextInterval (barsToSkip, itemType, Period): BaseInterval {
        const lev1Interv = new BaseInterval();
        const intvLength = BaseInterval.GetIntervalLength(Period);
        lev1Interv.FLeftTimeTicks = this.FLeftTimeTicks + (barsToSkip + 1) * intvLength;
        lev1Interv.LeftTime = new Date(lev1Interv.FLeftTimeTicks);
        lev1Interv.FRightTimeTicks = lev1Interv.FLeftTimeTicks + intvLength;
        lev1Interv.RightTime = new Date(lev1Interv.FRightTimeTicks);
        return lev1Interv;
    };

    /// <summary>
    /// Проверка, сообщение принадлежит интервалу или нет?
    /// </summary>
    /// <param name="message"></param>
    /// <returns></returns>
    Validate (dt): boolean {
        const dtTick = dt.getTime();
        return dtTick >= this.FLeftTimeTicks && dtTick < this.FRightTimeTicks;
    };

    public Init (message, historyType, Period, spreadItem): void {
        if (message.Code === Message.CODE_QUOTE) {
            // Если это котировка левел 1
            const quoteRec = message;

            if (Period == Periods.DAY && (quoteRec.Open !== null && quoteRec.Open !== undefined) && (quoteRec.High !== undefined && quoteRec.High !== null) && (quoteRec.Low !== undefined)) {
                switch (historyType) {
                case HistoryType.QUOTE_LEVEL1:
                case HistoryType.QUOTE_BIDASK_SUM:
                    this.FillData(quoteRec.Open, quoteRec.High, quoteRec.Low, quoteRec.BidSpread(spreadItem), quoteRec.volumeTotal);
                    break;
                case HistoryType.QUOTE_ASK:
                    this.FillData(quoteRec.Open, quoteRec.High, quoteRec.Low, quoteRec.AskSpread(spreadItem), quoteRec.volumeTotal);
                    break;
                case HistoryType.QUOTE_TRADES:
                    this.FillData(quoteRec.Open, quoteRec.High, quoteRec.Low, quoteRec.LastPrice, quoteRec.LastSize || 0); // LastSpread не нужен, т.к. в этом режиме все равно вернется ласт
                    break;
                case HistoryType.QUOTE_BIDASK_AVG:
                    this.FillData(quoteRec.Open, quoteRec.High, quoteRec.Low, (quoteRec.BidSpread(spreadItem) + quoteRec.AskSpread(spreadItem)) / 2.0, quoteRec.volumeTotal);
                    break;
                }
            } else if (Period === Periods.TIC) {
                if (historyType == HistoryType.QUOTE_TRADES)
                // для бекстестинга
                { this.FillData(quoteRec.LastPrice, quoteRec.LastPrice, quoteRec.LastPrice, quoteRec.LastSize || 0, quoteRec.LastSize || 0); } else { this.FillDataBAVA(quoteRec.BidSpread(spreadItem), quoteRec.AskSpread(spreadItem), quoteRec.BidSize, quoteRec.AskSize); }
            } else {
                let initValue;
                switch (historyType) {
                case HistoryType.QUOTE_LEVEL1:
                    initValue = quoteRec.BidSpread(spreadItem);
                    this.FillData(initValue, initValue, initValue, initValue, quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1);
                    break;
                case HistoryType.QUOTE_ASK:
                    initValue = quoteRec.AskSpread(spreadItem);
                    this.FillData(initValue, initValue, initValue, initValue, quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1);
                    break;
                case HistoryType.QUOTE_TRADES:
                    initValue = quoteRec.LastPrice; // LastSpread не нужен, т.к. в этом режиме все равно вернется ласт
                    this.FillData(initValue, initValue, initValue, initValue, quoteRec.LastSize || 0);
                    break;
                case HistoryType.QUOTE_BIDASK_AVG:
                    initValue = (quoteRec.BidSpread(spreadItem) + quoteRec.AskSpread(spreadItem)) / 2.0;
                    this.FillData(initValue, initValue, initValue, initValue, quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1);
                    break;
                case HistoryType.QUOTE_BIDASK_SUM:
                    var bid = quoteRec.BidSpread(spreadItem);
                    var ask = quoteRec.AskSpread(spreadItem);
                    this.FillData(bid, Math.max(bid, ask), Math.min(bid, ask), bid, quoteRec.quoteAdditionalInfo != null ? quoteRec.quoteAdditionalInfo.AccumultedVolume : 1);
                    break;
                }
            }
        } else if (message.Code === Message.CODE_QUOTE3) {
            // Если это котировка трейда, то бид аск равны 0 заполняем только ласт прайс
            const tsRec = message;
            if (Period === Periods.TIC) {
                this.FillDataBAVA(tsRec.Price, tsRec.Price, tsRec.Size, tsRec.Size);
                // fix for simpleWeb Trades History (tic trades) - historyCache must have valid aggresor flag set, otherwise all trades have side==buy
                // aggresor
                // if (tsRec.LType != Quote3Message.AgressorFlagType.None)
                //    AggressorFlag = tsRec.LType;
            } else {
                this.FillData(tsRec.Price, tsRec.Price, tsRec.Price, tsRec.Price, tsRec.Size);
                // AggressorFlag = Quote3Message.AgressorFlagType.None;
            }
        }

        this.Ticks = 1;

        if (message.SessionFlag) { this.SessionType = message.SessionFlag; }
    };

    FillData (open, high, low, close, volume): void {
        this.Data[0] = open;
        this.Data[1] = close;
        this.Data[2] = high;
        this.Data[3] = low;
        this.Volume = volume;
    };

    FillDataBAVA (bid, ask, volume, askVolume): void {
        this.Data[0] = bid;
        this.Data[1] = ask;
        this.Volume = volume;
    };

    // #region Доступ к расчётным данным

    // Средний бид
    public get Median (): number {
        const Data = this.Data;
        return (
            Data[BaseInterval.HIGH_INDEX] +
            Data[BaseInterval.LOW_INDEX]
        ) / 2;
    }

    // Типичный бид
    public get Typical (): number {
        const Data = this.Data;
        return (
            Data[BaseInterval.HIGH_INDEX] +
            Data[BaseInterval.LOW_INDEX] +
            Data[BaseInterval.CLOSE_INDEX]
        ) / 3;
    }

    public get Weighted (): number {
        const Data = this.Data;
        return (
            Data[BaseInterval.HIGH_INDEX] +
            Data[BaseInterval.LOW_INDEX] +
            Data[BaseInterval.CLOSE_INDEX] +
            Data[BaseInterval.CLOSE_INDEX]
        ) / 4;
    }

    public get Close (): number {
        return this.Data[BaseInterval.CLOSE_INDEX];
    }

    // #endregion Доступ к расчётным данным

    IsValid (): boolean {
        const Data = this.Data;

        if (Data.includes(null)) { return false; }

        if (Data.length < 4) { return !isNaN(Data[BaseInterval.OPEN_INDEX]); }

        return (!isNaN(Data[BaseInterval.OPEN_INDEX]) &&
            !isNaN(Data[BaseInterval.CLOSE_INDEX]) &&
            !isNaN(Data[BaseInterval.HIGH_INDEX]) &&
            !isNaN(Data[BaseInterval.LOW_INDEX]) &&
            Data[BaseInterval.CLOSE_INDEX] >= Data[BaseInterval.LOW_INDEX]);
    };

    public static CompareTo (a, b): number {
        if (a.FLeftTimeTicks > b.FLeftTimeTicks) { return 1; }
        return -1;
    };

    ApplySpread (plan, instr, period, historyType, interval): void {
        if (period == Periods.TIC) {
            const ask = plan.CalcAsk(this.Data[0], this.Data[1], instr);
            this.Data[0] = plan.CalcBid(this.Data[0], this.Data[1], instr);
            this.Data[1] = ask;
        } else {
            if (historyType == HistoryType.QUOTE_LEVEL1) {
                const bidOpen = this.Data[BaseInterval.OPEN_INDEX];
                const bidHigh = this.Data[BaseInterval.HIGH_INDEX];
                const bidLow = this.Data[BaseInterval.LOW_INDEX];
                const bidClose = this.Data[BaseInterval.CLOSE_INDEX];

                let askOpen = NaN;
                let askHigh = NaN;
                let askLow = NaN;
                let askClose = NaN;

                if (interval != null) {
                    askOpen = interval.Data[BaseInterval.OPEN_INDEX];
                    askHigh = interval.Data[BaseInterval.HIGH_INDEX];
                    askLow = interval.Data[BaseInterval.LOW_INDEX];
                    askClose = interval.Data[BaseInterval.CLOSE_INDEX];
                }

                // bids spread
                this.Data[BaseInterval.OPEN_INDEX] = plan.CalcBid(bidOpen, askOpen, instr);
                this.Data[BaseInterval.HIGH_INDEX] = plan.CalcBid(bidHigh, askHigh, instr);
                this.Data[BaseInterval.LOW_INDEX] = plan.CalcBid(bidLow, askLow, instr);
                this.Data[BaseInterval.CLOSE_INDEX] = plan.CalcBid(bidClose, askClose, instr);

                // asks spread
                if (interval != null) {
                    interval.Data[BaseInterval.OPEN_INDEX] = plan.CalcAsk(bidOpen, askOpen, instr);
                    interval.Data[BaseInterval.HIGH_INDEX] = plan.CalcAsk(bidHigh, askHigh, instr);
                    interval.Data[BaseInterval.LOW_INDEX] = plan.CalcAsk(bidLow, askLow, instr);
                    interval.Data[BaseInterval.CLOSE_INDEX] = plan.CalcAsk(bidClose, askClose, instr);
                }
            } else if (historyType == HistoryType.QUOTE_ASK) {
                const askOpen = this.Data[BaseInterval.OPEN_INDEX];
                const askHigh = this.Data[BaseInterval.HIGH_INDEX];
                const askLow = this.Data[BaseInterval.LOW_INDEX];
                const askClose = this.Data[BaseInterval.CLOSE_INDEX];

                let bidOpen = NaN;
                let bidHigh = NaN;
                let bidLow = NaN;
                let bidClose = NaN;

                if (interval != null) {
                    bidOpen = interval.Data[BaseInterval.OPEN_INDEX];
                    bidHigh = interval.Data[BaseInterval.HIGH_INDEX];
                    bidLow = interval.Data[BaseInterval.LOW_INDEX];
                    bidClose = interval.Data[BaseInterval.CLOSE_INDEX];
                }

                // asks spread
                this.Data[BaseInterval.OPEN_INDEX] = plan.CalcAsk(bidOpen, askOpen, instr);
                this.Data[BaseInterval.HIGH_INDEX] = plan.CalcAsk(bidHigh, askHigh, instr);
                this.Data[BaseInterval.LOW_INDEX] = plan.CalcAsk(bidLow, askLow, instr);
                this.Data[BaseInterval.CLOSE_INDEX] = plan.CalcAsk(bidClose, askClose, instr);

                // bids spread
                if (interval != null) {
                    interval.Data[BaseInterval.OPEN_INDEX] = plan.CalcBid(bidOpen, askOpen, instr);
                    interval.Data[BaseInterval.HIGH_INDEX] = plan.CalcBid(bidHigh, askHigh, instr);
                    interval.Data[BaseInterval.LOW_INDEX] = plan.CalcBid(bidLow, askLow, instr);
                    interval.Data[BaseInterval.CLOSE_INDEX] = plan.CalcBid(bidClose, askClose, instr);
                }
            }
        }
    };

    public MergeWith (baseInterval: BaseInterval, itemtype: number): void {
        // #region // Слияние бидов
        this.Volume = (this.Volume || 0) + ((baseInterval.Data.length > 2 || itemtype == HistoryType.QUOTE_TRADES) ? baseInterval.Volume : 1);
        this.Ticks += baseInterval.Ticks;

        if (baseInterval.Data.length < 4) {
            const price = (itemtype == HistoryType.QUOTE_ASK && baseInterval.Data.length > 0) ? baseInterval.Data[1] : baseInterval.Data[0];

            if (isNaN(this.Data[BaseInterval.HIGH_INDEX]) || (!isNaN(price) && this.Data[BaseInterval.HIGH_INDEX] < price)) { this.Data[BaseInterval.HIGH_INDEX] = price; }

            // Если текущая LOW цена больше нуля, тогда сравниваем
            if (isNaN(this.Data[BaseInterval.LOW_INDEX]) || (!isNaN(price) && this.Data[BaseInterval.LOW_INDEX] > price)) { this.Data[BaseInterval.LOW_INDEX] = price; }

            // Только в том случае, елси цена закрытия больше нуля
            if (!isNaN(price)) { this.Data[BaseInterval.CLOSE_INDEX] = price; }

            // Если цена открытия текущего бара нулевая - загоняем в нее цену открытия бара слияния
            if (isNaN(this.Data[BaseInterval.OPEN_INDEX])) { this.Data[BaseInterval.OPEN_INDEX] = price; }
        } else {
            // Выбор большей нены HIGH
            if (isNaN(this.Data[BaseInterval.HIGH_INDEX]) || (!isNaN(baseInterval.Data[BaseInterval.HIGH_INDEX]) && this.Data[BaseInterval.HIGH_INDEX] < baseInterval.Data[BaseInterval.HIGH_INDEX])) { this.Data[BaseInterval.HIGH_INDEX] = baseInterval.Data[BaseInterval.HIGH_INDEX]; }

            // Если текущая LOW цена больше нуля, тогда сравниваем
            if (isNaN(this.Data[BaseInterval.LOW_INDEX]) || (!isNaN(baseInterval.Data[BaseInterval.LOW_INDEX]) && this.Data[BaseInterval.LOW_INDEX] > baseInterval.Data[BaseInterval.LOW_INDEX])) { this.Data[BaseInterval.LOW_INDEX] = baseInterval.Data[BaseInterval.LOW_INDEX]; }

            // Только в том случае, елси цена закрытия больше нуля
            if (!isNaN(baseInterval.Data[BaseInterval.CLOSE_INDEX])) { this.Data[BaseInterval.CLOSE_INDEX] = baseInterval.Data[BaseInterval.CLOSE_INDEX]; }

            // Если цена открытия текущего бара нулевая - загоняем в нее цену открытия бара слияния
            if (isNaN(this.Data[BaseInterval.OPEN_INDEX])) { this.Data[BaseInterval.OPEN_INDEX] = baseInterval.Data[BaseInterval.OPEN_INDEX]; }
        }

        this.LastQuoteId = baseInterval.LastQuoteId;
        this.LastQuoteIdDate = baseInterval.LastQuoteIdDate;

        // #endregion
    };
}
