// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../../DataCache';
import { Account } from '../../../cache/Account';
import { Resources } from '../../../../Localizations/Resources';
import { AccountFeature } from '../../../../Utils/Account/AccountFeature';
import { AccountType } from '../../../../Utils/Account/AccountType';
import { TodayResultsGroupEnum } from '../../../../Utils/Enums/AccountDetailsEnum';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { BaseAccountGroupController } from '../BaseControllers/BaseAccountGroupController';
import { type IGroupController } from '../../Interfaces/IGroupController';
import { RowItemDataNumberPair, RowItemDataStringPair } from '../../../cache/VerticalPanel/AccountDetails/RowItemData';
import { VerticalPanelColoringTypes } from '../../VerticalPanelColoringTypes';

export class TodayResultGroupController<GroupItemsType = TodayResultsGroupEnum> extends BaseAccountGroupController<GroupItemsType> implements IGroupController<GroupItemsType> {
    public getGroupItemsTypes (): GroupItemsType[] {
        return [
            TodayResultsGroupEnum.GrossPNL,
            TodayResultsGroupEnum.BeginBalance,
            TodayResultsGroupEnum.TodayFees,
            TodayResultsGroupEnum.TodayVolume,
            TodayResultsGroupEnum.TodayTrades,
            TodayResultsGroupEnum.TodayTurnover,
            TodayResultsGroupEnum.TodayRebates,
            TodayResultsGroupEnum.TodaySwaps
        ] as GroupItemsType[];
    }

    public getItemValue (type: GroupItemsType): RowItemDataNumberPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;
        // const useAccCurrency = true; // TODO remove

        switch (type) {
        case TodayResultsGroupEnum.GrossPNL:
            return new RowItemDataNumberPair(assetBalance.TodayGrossPNL);
        case TodayResultsGroupEnum.BeginBalance:
            return new RowItemDataNumberPair(assetBalance.TodayRealizedPNL);
        case TodayResultsGroupEnum.TodayFees:
            return new RowItemDataNumberPair(assetBalance.TodayFees);
        case TodayResultsGroupEnum.TodayVolume:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.TodayVolume, curAccount, assetBalance));
        case TodayResultsGroupEnum.TodayTrades:
            return new RowItemDataNumberPair(Account.GetAccountFeature(AccountFeature.TodayTrades, curAccount, assetBalance));
        case TodayResultsGroupEnum.TodayTurnover:
            return new RowItemDataNumberPair(assetBalance.TodayTurnover);
        case TodayResultsGroupEnum.TodayRebates:
            return new RowItemDataNumberPair(assetBalance.TodayRebates);
        case TodayResultsGroupEnum.TodaySwaps:
            return new RowItemDataNumberPair(assetBalance.TodaySwaps);
        }
    }

    public getItemFormattedValue (type: GroupItemsType): RowItemDataStringPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;
        const value = this.getItemValue(type);

        switch (type) {
        case TodayResultsGroupEnum.GrossPNL:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(value.firstValue));
        case TodayResultsGroupEnum.BeginBalance:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(value.firstValue));
        case TodayResultsGroupEnum.TodayFees:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(value.firstValue));
        case TodayResultsGroupEnum.TodayVolume:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.TodayVolume, curAccount, assetBalance));
        case TodayResultsGroupEnum.TodayTrades:
            return new RowItemDataStringPair(Account.GetAccountFeatureString(value.firstValue, AccountFeature.TodayTrades, curAccount, assetBalance));
        case TodayResultsGroupEnum.TodayTurnover:
            if (curAccount.AccountType !== AccountType.MultiAsset) {
                return new RowItemDataStringPair('');
            } else {
                return new RowItemDataStringPair(assetBalance.formatPrice(assetBalance.TodayTurnover));
            }
        case TodayResultsGroupEnum.TodayRebates:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(value.firstValue));
        case TodayResultsGroupEnum.TodaySwaps:
            return new RowItemDataStringPair(assetBalance.formatPriceExactly(assetBalance.TodaySwaps));
        }
    }

    public getItemNameKey (type: GroupItemsType): string {
        switch (type) {
        case TodayResultsGroupEnum.GrossPNL:
            return 'panel.accounts.GrossPNL';
        case TodayResultsGroupEnum.BeginBalance:
            return 'panel.accounts.BeginBalance';
        case TodayResultsGroupEnum.TodayFees:
            return 'panel.accounts.TodayFees';
        case TodayResultsGroupEnum.TodayVolume:
            return 'panel.accounts.TodayVolume';
        case TodayResultsGroupEnum.TodayTrades:
            return 'panel.accounts.TodayTrades';
        case TodayResultsGroupEnum.TodayTurnover:
            return 'panel.accounts.TodayTurnover';
        case TodayResultsGroupEnum.TodayRebates:
            return 'panel.accounts.TodayRebates';
        case TodayResultsGroupEnum.TodaySwaps:
            return 'panel.accounts.TodaySwaps';
        }
    }

    public isHiddenItemRow (type: GroupItemsType): boolean {
        const itemNameKey = this.getItemNameKey(type);
        if (Resources.isHidden(itemNameKey)) {
            return true;
        }

        if (type !== TodayResultsGroupEnum.BeginBalance) {
            if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_EXT_RISK)) {
                return true;
            }
        }

        const account = this.Account;
        const dc = DataCache;
        if (account && dc) {
            if (type === TodayResultsGroupEnum.TodayTurnover) {
                // #33209
                return account.AccountType !== AccountType.MultiAsset;
            }
        } else {
            if (type === TodayResultsGroupEnum.TodayTurnover) {
                // #33209, #33495
                let hasMultiAcc = false;
                const accDict = dc.getAccounts();
                for (const accId in accDict) {
                    const acc = accDict[accId];
                    if (acc.AccountType === AccountType.MultiAsset) {
                        hasMultiAcc = true;
                        break;
                    }
                }

                return !hasMultiAcc;
            }
        }
    }

    public getColoringMethod (type: GroupItemsType): VerticalPanelColoringTypes {
        return VerticalPanelColoringTypes.Default;
    }
}
