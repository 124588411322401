// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CrossratesPlan } from './CrossratesPlan';
import { CustomEvent } from '../../Utils/CustomEvents';

export class CrossratesPlans {
    public DCache: any;
    public UpdateEvent = new CustomEvent();
    public _fCrossratesPlans: any = {};
    constructor (dCache) {
        this.DCache = dCache;
    }

    public GetPlanById (id): CrossratesPlan {
        return this._fCrossratesPlans[id] || null;
    }

    public Add (message): void {
        let plan = this.GetPlanById(message.Id);
        if (!plan) {
            plan = this._fCrossratesPlans[message.Id] = new CrossratesPlan(message.Id, this.DCache);
        }

        plan.Update(message);
        this.UpdateEvent.Raise(plan);
    }

    public Clear (): void {
        this._fCrossratesPlans = {};
    }
}
