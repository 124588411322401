// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Intervals } from '../../../../Utils/Instruments/Intervals';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { DynProperty } from '../../../DynProperty';
import { NumericUtils } from '../../../../Utils/NumericUtils';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { SLTPEdit } from '../SLTPEdit';
import { OrderEditBaseUtils } from '../../../../Utils/Trading/OrderEditBaseUtils';
import { OrderEditBase } from './OrderEditBase';
import { type SlTpHolder } from '../../../../Utils/Trading/SlTpHolder';
import { type OrderEditConfirmationTextParams } from '../OrderEditConfirmationTextParams';
import { BaseSettings } from '../../../Settings/BaseGeneralSettingsWrapper';

export class StopLimitOrderEdit extends OrderEditBase {
    public DataCache: any;
    // TODO. Ugly. Refactor. Remove. http://tp.pfsoft.net/entity/76728
    public needSetDefaultStopPrice: boolean = false;
    // Remove https://tp.traderevolution.com/entity/82704
    // data.fullRangeForLimitPrice || false
    // this.forceCorrectLimitPrice = false
    public fullRangeForLimitPrice: boolean = true;

    constructor (data) {
        super(data);

        this.DataCache = data.dataCache;

        this.sltp = new SLTPEdit(data.dataCache, data.sltpMode);
        this.sltpBasePriceGetter = this.sltpBasePriceGetter.bind(this);

        this.DataCache.OnActivateOrder.Subscribe(this.updateStopPriceWhenActivate, this); // #93951
    }

    public override getParameterNameArray (): string[] {
        return [
            OrderEditBaseUtils.STOP_PRICE_PARAM,
            OrderEditBaseUtils.LIMIT_PRICE_PARAM,
            OrderEditBaseUtils.POSITION_SIZING_PARAM,
            OrderEditBaseUtils.SLTP_PARAM,
            OrderEditBaseUtils.AFTER_TRADE_CASH
        ];
    }

    // TODO. Refactor. Same as OrderTypeBase.Id function.
    public override getOrderTypeId (): OrderType {
        return OrderType.StopLimit;
    }

    // TODO. Refactor. Use error/warning dicts for sltp.
    // public valid ()
    // {
    //     return OrderEditBase.prototype.valid.call(this) &&
    //         this.sltp.valid()
    // }

    public override dispose (): void {
        this.DataCache.OnActivateOrder.UnSubscribe(this.updateStopPriceWhenActivate, this);

        super.dispose();
    }

    public updateStopPriceWhenActivate (): void // #93951
    {
        this.ParametersChanged.Raise({ stopPrice: this.toDynProperty_stopPrice() });
    }

    // #region Parameters' DynProperty Generators

    public toDynProperty_stopPrice (): DynProperty {
        const dp = new DynProperty(OrderEditBaseUtils.STOP_PRICE_PARAM);
        dp.type = DynProperty.DOUBLE;

        const ins = this.instrument;
        const intrvls = Intervals.GetIntervalsFromInstrument(ins, NumericUtils.MAXVALUE);
        if (ins) {
            dp.minimalValue = intrvls && (intrvls.length > 0) ? intrvls[0].LeftValue : ins.PointSize;
            dp.maximalValue = 999999999;
            dp.decimalPlaces = ins.Precision;
            dp.increment = ins.PointSize;
        }

        if (intrvls) { dp.Intervals = intrvls; };

        const insDefSettings = BaseSettings.insDefSettingsStorage.GetInstrumentSettings(ins);
        if (insDefSettings) {
            dp.increment = ins?.PointSize * insDefSettings.PriceIncrementTicks;
        }

        dp.value = this.stopPrice;
        if (dp.value === null || isNaN(dp.value)) {
            dp.value = dp.minimalValue;
        }

        if (this.order?.IsActivated) { dp.enabled = false; }; // #92963

        dp.localizationKey = 'panel.newOrderEntry.stopPricePanel';

        return dp;
    }

    public toDynProperty_limitPrice (): DynProperty {
        const dp = new DynProperty(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        dp.type = DynProperty.DOUBLE;

        const ins = this.instrument;
        const intrvls = Intervals.GetIntervalsFromInstrument(ins, NumericUtils.MAXVALUE);
        if (ins) {
            const pointSize = ins.PointSize;
            const basePrice = this.stopPrice || pointSize;

            // TODO. Ugly. Refactor. Remove. http://tp.pfsoft.net/entity/76728
            if (this.fullRangeForLimitPrice) {
                dp.minimalValue = intrvls ? intrvls[0].LeftValue : pointSize;
                dp.maximalValue = 999999999;
            } else if (this.buy()) {
                dp.minimalValue = intrvls ? intrvls[0].LeftValue : basePrice + pointSize;
                dp.maximalValue = 999999999;
            } else {
                dp.minimalValue = intrvls ? intrvls[0].LeftValue : pointSize;
                dp.maximalValue = basePrice - pointSize;
            }

            dp.decimalPlaces = ins.Precision;
            dp.increment = ins.PointSize;
        }

        if (intrvls) { dp.Intervals = intrvls; }

        const insDefSettings = BaseSettings.insDefSettingsStorage.GetInstrumentSettings(ins);
        if (insDefSettings) {
            dp.increment = ins?.PointSize * insDefSettings.PriceIncrementTicks;
        }

        dp.value = this.limitPrice;
        if (dp.value === null || isNaN(dp.value)) { dp.value = dp.minimalValue; }

        dp.localizationKey = 'panel.newOrderEntry.limitPricePanel';

        return dp;
    }

    // #endregion Parameters' DynProperty Generators

    // #region Raw Values

    public toRawValue_limitPrice (): any {
        return this.limitPrice;
    }

    public toRawValue_stopPrice (): any {
        return this.stopPrice;
    }

    // #endregion Raw Values

    // #region Update Parameter Handlers

    public update_limitPrice (updateData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp && this.limitPrice !== dp.value && !(isNaN(this.limitPrice) && isNaN(dp.value))) {
            this.limitPrice = dp.value;
            parameterChanged = true || parameterChanged;
        }

        return parameterChanged;
    }

    public update_stopPrice (updateData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp && this.stopPrice !== dp.value && !(isNaN(this.stopPrice) && isNaN(dp.value))) {
            this.stopPrice = dp.value;
            parameterChanged = true || parameterChanged;
        }

        let newInstrument: any = null;
        let newQuote: any = null;
        let sideChanged = false;

        const newTradingDataDict = updateData.newTradingDataDict;
        if (newTradingDataDict) {
            newInstrument = newTradingDataDict.instrument;
            newQuote = newTradingDataDict.quote;
            sideChanged = 'side' in newTradingDataDict;
        }

        if (newInstrument) {
            this.stopPrice = null;
            this.needSetDefaultStopPrice = true;
            parameterChanged = true || parameterChanged;
        }

        if (this.needSetDefaultStopPrice && newQuote) {
            this.needSetDefaultStopPrice = false;
            this.stopPrice = this.limitPrice = newQuote.BidSpread_SP_Ins(this.instrument?.DataCache.GetSpreadPlan(this.account), this.instrument);
            parameterChanged = true || parameterChanged;
        }

        if (newQuote || sideChanged || parameterChanged) {
            parameterChanged = this.tryUpdateWarning_stopPrice(
                this.stopPrice,
                this.quote) || parameterChanged;
        }

        // TODO. Ugly. Refactor. Remove. http://tp.pfsoft.net/entity/76728
        // MD specific piece of shite.
        // Remove https://tp.traderevolution.com/entity/82704
        // if (this.fullRangeForLimitPrice && (parameterChanged && !sideChanged || !this.limitPrice))
        //     this.forceCorrectLimitPrice = true

        // TODO. Ugly. Updates limit price's min/max values after stop price change.
        if (parameterChanged) {
            this.fireParameterChanged(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        };

        return parameterChanged;
    }

    // TODO.
    public tryUpdateWarning_stopPrice (stopPrice, quote): any {
        if (!quote) return false;

        const order = this.order;
        if (order?.IsActivated) return false; // #93925

        const sp = this.instrument?.DataCache.GetSpreadPlan(this.account);
        const ask = quote.AskSpread_SP_Ins(sp, this.instrument);
        const bid = quote.BidSpread_SP_Ins(sp, this.instrument);

        const buy = this.buy();

        const newWarning =
            buy && stopPrice < ask
                ? 'general.trading.stopBuyMoreAsk'
                : !buy && stopPrice > bid
                    ? 'general.trading.stopSellLessBid'
                    : null;

        return this.warningsManager.setParameterWarning(OrderEditBaseUtils.STOP_PRICE_PARAM, newWarning);
    }

    // #endregion Update Parameter Handlers

    // TODO.
    public validateParameters (): any {
        const updatedParamNameDict = {};

        // // TODO. Ugly. Refactor. Remove. http://tp.pfsoft.net/entity/76728
        // Remove https://tp.traderevolution.com/entity/82704
        // if ((this.forceCorrectLimitPrice || !this.fullRangeForLimitPrice) &&
        //     this.tryCorrectLimitPrice())
        //     updatedParamNameDict[OrderEditBase.LIMIT_PRICE_PARAM] = true

        // TODO. Ugly. Refactor. Remove. http://tp.pfsoft.net/entity/76728
        // Remove https://tp.traderevolution.com/entity/82704
        // this.forceCorrectLimitPrice = false

        if (this.sltp.validate(
            this.sltpBasePriceGetter,
            this.sltpBasePriceGetter,
            this.getTradingData())) {
            updatedParamNameDict[OrderEditBaseUtils.SLTP_PARAM] = true;
        }

        updatedParamNameDict[OrderEditBaseUtils.POSITION_SIZING_PARAM] = true;

        return updatedParamNameDict;
    }

    // Fixing limit price relative to stop price
    // Returns 'true' if limitPrice is changed.
    public tryCorrectLimitPrice (): any {
        const ins = this.instrument;
        if (!ins) return false;

        const pointSize = ins.PointSize;
        const stopPrice = this.stopPrice || pointSize;
        const curLimitPrice = this.limitPrice || pointSize;

        if (this.buy()) {
            const minLimitPrice = stopPrice + pointSize;
            if (curLimitPrice < minLimitPrice) {
                this.limitPrice = minLimitPrice;
                return true;
            }
        } else {
            const maxLimitPrice = stopPrice === pointSize
                ? pointSize
                : stopPrice - pointSize;

            if (curLimitPrice > maxLimitPrice) {
                this.limitPrice = maxLimitPrice;
                return true;
            }
        }

        return false;
    }

    // #region SLTP Price Comparer

    public sltpBasePriceGetter (): any {
        return this.limitPrice;
    }

    // #endregion SLTP Price Comparer

    protected override createConfirmationTextParams (): OrderEditConfirmationTextParams {
        const textParams = super.createConfirmationTextParams();
        textParams.OrderTypeKey = 'Stop Limit';
        textParams.PriceArray = [this.stopPrice, this.limitPrice];
        textParams.OrderType = OrderType.StopLimit;

        return textParams;
    }

    // Returns SlTpHolder instance.
    // TODO. UGLY. Refactor. details are at the top OrderEditBase.ts
    public getRawSLTP (): SlTpHolder {
        return this.sltp.getRawValue(this.getTradingData());
    }

    // TODO. Refactor. Details are in OrderEditBase.ts.
    public override setBasePrice (price, isLimitModify, noNeedToSetDefaultOffset: any = null): void {
        if (isLimitModify) {
            const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
            dp.value = price;
            this.updateParameters(new OrderEditUpdateData({ limitPrice: dp }));

            return;
        }

        let dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.STOP_PRICE_PARAM);
        const startPrice = dp.value;
        dp.value = price;

        this.updateParameters(new OrderEditUpdateData({ stopPrice: dp }));

        const delta = price - startPrice;

        dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        dp.value += delta;

        noNeedToSetDefaultOffset = false || noNeedToSetDefaultOffset;
        if (BaseSettings.isUseStopLimitInsteadStop() && !noNeedToSetDefaultOffset) {
            dp.value = this.getLimitPriceWithDefaultOffset(price);
        }

        this.updateParameters(new OrderEditUpdateData({ limitPrice: dp }));
    }

    public setStopPrice (price): void {
        const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.STOP_PRICE_PARAM);
        dp.value = price;
        this.updateParameters(new OrderEditUpdateData({ stopPrice: dp }));
    }

    public setLimitPrice (price): void {
        const dp = this.createDynPropertyFromParameter(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        dp.value = price;
        this.updateParameters(new OrderEditUpdateData({ limitPrice: dp }));
    }

    // TODO. Refactor. Details are in OrderEditBase.ts.
    public getBasePrice (): any {
        return this.limitPrice;
    }

    public setBreakevenPrice (price): void // вызывается при нажатии на стрелочку-кнопку QuotePricePicker a.k.a Breakeven
    {
        const order = this.order;
        if (!order) return;

        if (order.IsActivated) {
            this.setBasePrice(price, true);
        } else {
            this.setStopPrice(price);
        }
    }

    public skipParamForFlashing (paramName): boolean // при включенном positionSizing надо ли DynPropertyControl c paramName пропускать при подсветке лейблов (к примеру как Stop price не подсвечиваем у Stop limit-a)
    {
        if (paramName == OrderEditBaseUtils.STOP_PRICE_PARAM) {
            return true;
        }

        return false;
    }
}
