// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { DateTimeUtils } from './../Utils/Time/DateTimeUtils';
import { Resources, ISHIDDEN, LOCALE_EN } from '@shared/localizations/Resources';
import { DirectRiskWarningMessage } from './DirectMessages/DirectMessagesImport';
import { ORDER_PARAMETER_PRODUCT_TYPE, ProductType } from './Instruments/ProductType';
import { ReportMessageImportanceLevel } from './Enums/Constants';
import { ErrorCodes } from '@shared/commons/cache/Event/EventConstants';
import { InstrumentUtils } from './Instruments/InstrumentUtils';
import { Quantity } from './Trading/Quantity';
import { DateTimeConvertor } from './Time/DateTimeConvertor';
import { DataCacheHolder } from '@shared/commons/cache/DataCacheHolder';
import { TooltipType } from './ReportMessageTooltipTypeEnum';
import { type Account } from '@shared/commons/cache/Account';
import { BaseSettingsUtils } from '@shared/commons/UtilsClasses/BaseGeneralSettingsUtilsWrapper';

// #region ITooltiper

export interface ITooltiper {
    Localize: () => void
    GetHeader: () => string
    GetText: () => string
    TooltipType: TooltipType
    TooltipDrawType: number
}

// #endregion ITooltiper

// #region ReportMessageTooltip

export class ReportMessageTooltip implements ITooltiper {
    public date = '';
    public refuseMode = false;
    public reportMessage;
    public soundKey = '';

    public tooltipDrawType: TooltipType;
    public dealTicketTooltip: string;

    private header = '';
    private text = '';

    constructor (reportMessage) {
        this.reportMessage = reportMessage;

        if (reportMessage) {
            this.CreateReportToolTip();
        }
    }

    public Localize (): void {
        throw new Error('Not implemented');
    }

    get TooltipType (): TooltipType {
        return TooltipType.Trade;
    }

    get TooltipDrawType (): TooltipType {
        const TT = TooltipType;
        if ([TT.AccountOperation, TT.Idea, TT.CAInfo, TT.CAAccepted, TT.CACanceled, TT.CARejected].includes(this.tooltipDrawType)) {
            return this.tooltipDrawType;
        }

        return this.refuseMode ? TT.Refuse : TT.Trade;
    }

    set TooltipDrawType (value) {
        this.tooltipDrawType = value;
    }

    public GetHeader (): string {
        return this.header;
    }

    public GetText (): string {
        return this.text;
    }

    // TODO. UGLY. Refactor.
    public CreateReportToolTip (): void {
        let type = Resources.getResource('screen.ReportMessageTooltip.Market');
        let operation = '';
        let instrument = '';
        let instrType = '';
        let tradingExchange = '';
        let amount = '';
        let price = '';
        let stopPrice = '';
        let account = '';
        let message = '';
        let isStop = false;
        let productType = ProductType.General;

        const dc = DataCacheHolder.getDataCache();

        const reportMessage = this.reportMessage;
        const name = reportMessage.Name;
        const nameLC = name.toLowerCase();
        const data = reportMessage.Data;

        this.soundKey = name;

        if (ErrorCodes.ProductRequestCantProcess === reportMessage.BusinessRejectCode ||
    ErrorCodes.ProductSubscriptionNoActiveAccounts === reportMessage.BusinessRejectCode ||
    ErrorCodes.ProductSubscriptionNotEnoughBalance === reportMessage.BusinessRejectCode) {
            this.header = this.GetResourceString('FUNCTION_PRODUCTS');
            this.tooltipDrawType = TooltipType.AccountOperation;
            this.text = data.length > 0 ? Resources.getResource(data[0][1]) : '';
            let productName = '';
            const product = DataCacheHolder.getDataCache().EntitlementManager.ProductsCache[reportMessage.ReqProductId];
            if (product) {
                productName = product.Name;
            }

            this.text = this.text.replace('{0}', productName);
            data[0][1] = this.text;
            this.dealTicketTooltip = this.text;
            return;
        }

        // TODO. UGLY. Refactor. Remove when BusinessRejectMessage is treated correctly.
        // BusinessRejectMessage -> DirectReportMessage.
        if (name === 'general.trading.refused' || this.reportMessage.reject) {
            this.header = Resources.getResource(name);
            this.refuseMode = true;

            const locKey = `BusinessRejectMessage.${reportMessage.BusinessRejectCode}`;
            if (Resources.IsResourcePresent(locKey)) {
                this.text = Resources.getResource(locKey);
            } else {
                this.text = data.length > 0 ? Resources.getResource(data[0][1]) : '';
            }

            return;
        }

        let localizedHeader = Resources.getResource('reports.' + name);
        this.header = localizedHeader.includes('reports.')
            ? name
            : localizedHeader;

        if (reportMessage.ShowAsIs) {
            for (let i = 0, len = data.length; i < len; i++) {
                this.text += data[0] + ': ' + data[1] + ' ';
            }
        }
        // информационное сообщение
        else if (nameLC.indexOf('informational message') !== -1) {
            for (let i = 0, len = data.length; i < len; i++) {
                const cells = data[i];
                if (cells[0].toLowerCase() === 'informational message') {
                    this.text = this.GetResourceString(cells[1]);
                    break;
                }
            }
        }
        // Close Account functionality #116085 <- #114238
        else if (name.indexOf('closeAccount') !== -1) {
            for (let i = 0, len = data.length; i < len; i++) {
                const cells = data[i];
                this.header = Resources.getResource(name);
                this.text = this.GetResourceString(cells[1]);
                this.tooltipDrawType = reportMessage.tooltipDrawType;
                this.dealTicketTooltip = this.text;
                break;
            }
        }
        // торговые идеи
        else if (nameLC.indexOf('trading ideas') !== -1) {
            for (let i = 0, len = data.length; i < len; i++) {
                const cells = data[i];
                this.text = this.GetResourceString(cells[1]);
                this.tooltipDrawType = TooltipType.Idea;
                this.dealTicketTooltip = this.text;
                break;
            }
        }
        // margin Call
        else if (reportMessage.ImportanceLevel === ReportMessageImportanceLevel.Critical) {
            this.refuseMode = true;
            this.text = this.getTextForCriticalMarginMsg(data);
        }
        // account operation confirmation
        else if (nameLC.indexOf('account operation confirmation') !== -1) {
            for (let i = 0, len = data.length; i < len; i++) {
                const cells = data[i];
                const headerLowerCase = cells[0].toLowerCase();
                const val = cells[1];

                if (headerLowerCase === 'operation type') {
                    operation = this.GetResourceString(val);
                } else if (headerLowerCase === 'sum') {
                    type = this.GetResourceString(val);
                } else if (headerLowerCase === 'account name') {
                    instrument = this.GetResourceString(val);
                } else if (headerLowerCase === 'time') {
                    this.date = this.GetResourceString(val);
                }
            }
            // Correct Date/Time
            if (this.date) {
                this.date = ReportMessageTooltip.ParceDate(this.date);
            }

            this.text = operation + ' ' + type + ' ' + instrument + ' at ' + this.date;
        } else if (
            nameLC.indexOf(Resources.getResource('dealticket.RiskRuleWarning.Header')) !== -1 &&
    data.length >= 3) {
            this.text = data[2][1];
        } else if (reportMessage.TypeId === DirectRiskWarningMessage.PositionLossLimitRiskRuleType || reportMessage.TypeId === DirectRiskWarningMessage.LossLimitPerTrade) {
            this.header = Resources.getResource(reportMessage.Name);
            let text = reportMessage.Data[0][0] + ': ' + reportMessage.Data[0][1] + '\n'; // user
            text += reportMessage.Data[1][0] + ': ' + reportMessage.Data[1][1] + '\n'; // account
            const mess = reportMessage.Data[2][1]; // mess

            this.TooltipDrawType = TooltipType.Refuse;
            this.refuseMode = true;

            text += mess;
            this.text = text;
        } else if (reportMessage.Name === DirectRiskWarningMessage.RiskRuleWarningHeader && reportMessage.Data.length >= 3) {
            this.header = Resources.getResource(reportMessage.Name);
            this.text = reportMessage.Data[2][1];
            this.TooltipDrawType = TooltipType.Refuse;
            this.refuseMode = true;
        }
        // все торговые
        else {
            this.refuseMode =
        (
            nameLC.indexOf('reject') !== -1 &&
            nameLC.indexOf('partially') === -1
        ) ||
        name.indexOf('Trading disabled') !== -1;

            let shortName = name;
            if (shortName.indexOf('Delayed') === 0 &&
        shortName.length > 8 &&
        // #36897 - DelayedDataCharging
        shortName !== Resources.getResourceLang('reports.DelayedDataCharging', LOCALE_EN)) {
                shortName = shortName.substr(8);
            }

            localizedHeader = Resources.getResource('reports.' + shortName);
            this.header = localizedHeader.includes('reports.') ? shortName : localizedHeader;

            let isAccountOperation = false;
            for (let i = 0, len = data.length; i < len; i++) {
                const cells = data[i];
                const c0 = cells[0];
                const c0LC = c0.toLowerCase();
                const c1 = cells[1];

                if (c0LC === 'operation' || c0LC === 'operation type') {
                    operation = this.GetResourceString(c1);
                } else if (c0LC === 'type' || c0LC.indexOf('order type') !== -1) {
                    type = this.GetResourceString(c1);
                } else if (c0LC === 'instrument' || c0LC === 'symbol') {
                    instrument = this.GetResourceString(c1);
                    if (instrument === c1) {
                        const instr = dc.getInstrumentByName(c1);
                        if (instr) {
                            instrument = InstrumentUtils.RemoveRouteName(instr.DisplayName());
                        }
                    }
                } else if (c0LC === 'amount' || c0LC === 'quantity' || c0LC === 'refused amount') {
                    amount = this.GetResourceString(c1);
                } else if (c0LC === 'accountoperationamount') {
                    amount = this.GetResourceString(c1);
                    isAccountOperation = true;
                } else if (c0LC === 'price' || c0LC === 'order price') {
                    price = this.GetResourceString(c1);
                } else if (c0LC === 'stop price') {
                    stopPrice = this.GetResourceString(c1);
                } else if (c0LC === 'time' || c0LC === 'date/time') {
                    this.date = this.GetResourceString(c1);
                } else if (c0LC === 'account') {
                    account = c1;
                } else if (c0LC === 'isstop') {
                    isStop = c1.toLowerCase() === 'true';
                } else if (c0LC === 'message') {
                    message = c1;
                } else if (c0 === ORDER_PARAMETER_PRODUCT_TYPE) {
                    productType = parseInt(c1);
                } else if (c0LC === 'symbol type') {
                    instrType = c1;
                } else if (c0LC === 'trading exchange') {
                    tradingExchange = c1;
                }
            }

            // AMOUNT ВСЕГДА В ЛОТАХ
            if (!BaseSettingsUtils.displayAmountInLots() &&
        !isAccountOperation &&
        reportMessage.Instrument) {
                amount = dc.formatVolume(
                    reportMessage.Instrument,
                    Quantity.convertQuantityValue(new Quantity(parseFloat(amount), true), reportMessage.Instrument, false, reportMessage.account, reportMessage.productType),
                    false,
                    reportMessage.productType, reportMessage.account);
            }

            const typeLC = type.toLowerCase();
            if (typeLC.includes('stop') && !typeLC.includes('stop limit')) {
                price = stopPrice;
            }

            let created = '';
            // Correct Date/Time
            if (this.date) {
                this.date = ReportMessageTooltip.ParceDate(this.date);

                created = Resources.getResource('screen.ReportMessageTooltip.CreatedAt');
                if (nameLC.indexOf('modified') !== -1) {
                    created = Resources.getResource('screen.ReportMessageTooltip.ModifiedAt');
                } else if (nameLC.indexOf('removed') !== -1) {
                    created = Resources.getResource('screen.ReportMessageTooltip.RemovedAt');
                } else if (nameLC.indexOf('executed') !== -1) {
                    created = Resources.getResource('screen.ReportMessageTooltip.FilledAt');
                } else if (isAccountOperation) {
                    created = Resources.getResource('general.trading.at');
                }

                created = ' ' + created + ' ';
            }

            if (name.indexOf('Trading disabled') !== -1) {
                this.text = message;
            } else {
                this.text = '';
                if (isAccountOperation) {
                    this.text = operation + ' ' + amount + ' ' + account + ' ' + created + this.date;
                } else {
                    this.text = operation + ' ' + type + ' ' + instrType + ' ' + instrument + ' ';
                    if (tradingExchange) {
                        this.text += '(' + tradingExchange + ') ';
                    }
                    this.text += amount;
                    if (price) {
                        this.text += '@' + price;
                    }
                    this.text += created + this.date;
                }
            }
        }

        for (let i = 0, len = data.length; i < len; i++) {
            const cells = data[i];
            const c0 = cells[0];
            const c0LC = c0.toLowerCase();
            const c1 = cells[1];
            if (c0LC === 'time' || c0LC === 'date/time') {
                this.date = this.GetResourceString(c1);
            }
        }

        // Correct Date/Time
        if (this.date) {
            this.date = ReportMessageTooltip.ParceDate(this.date);
        }
    }

    public GetResourceString (key: string): string {
        const reportKey = 'reports.' + key;
        return Resources.getResource(
            Resources.IsResourcePresent(reportKey) ? reportKey : key);
    }

    // TODO. Refactor. Ugly.
    public static ParceDate (date: string, sess?, dateOnly?): string {
        // Сервер теперь передаёт время в тиках
        // Мы конвертируем в нужный часовой пояс
        // TODO. Ugly as fck.
        date = date.replace(/\;/, '');
        const ticks = parseInt(date);

        const srcDate = new Date(isNaN(ticks) ? date : ticks);
        const localDate = DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(srcDate);

        return dateOnly
            ? DateTimeUtils.FormatToDate(localDate)
            : DateTimeUtils.FormatToDateAndTime(localDate);
    }

    // TODO. Refactor. UGLY.
    // Partial duplicate of ReportMessageTooltip.CreateReportToolTip.
    public static fixEntries (data, sesSettings, reportIns, account: Account, productType: ProductType): void {
        const dc = DataCacheHolder.getDataCache();

        for (let i = 0, len = data.length; i < len; i++) {
            const cells = data[i];
            const c0 = cells.caption;
            const c1 = cells.text;

            if (Resources.getResourceLang('reports.' + c0, LOCALE_EN) === ISHIDDEN) {
                continue;
            }

            if (Resources.isHidden(c0) || Resources.isHidden('properties.' + c0)) {
                continue;
            }

            if (c0 === 'Time' ||
        c0 === 'SettleDate' ||
        c0 === 'Date/Time' ||
        c0 === 'DateTime' ||
        c0 === 'Open date') {
                cells.text = ReportMessageTooltip.ParceDate(
                    c1.getTime().toString(),
                    sesSettings,
                    c0 === 'SettleDate');
            } else if (c0 === ORDER_PARAMETER_PRODUCT_TYPE) {
                cells.text = InstrumentUtils.GetLocalizedProductType(reportIns, productType);
            } else if (c0 === 'Amount' || c0 === 'Refused Amount' || c0 === 'Amount filled') {
                const viewInLots = BaseSettingsUtils.displayAmountInLots();
                cells.text = dc.formatVolume(
                    reportIns,
                    Quantity.convertQuantityValue(new Quantity(parseFloat(c1), true), reportIns, viewInLots, account, productType),
                    viewInLots,
                    productType,
                    account);
            } else if (c0.toLowerCase() === 'tif') {
                try {
                    const splits = c1.split(' ');
                    if (splits.length > 1 && splits[0] === Resources.getResource('property.GTD')) {
                        let date = splits[1];
                        const hasTime = splits.length >= 3;
                        if (hasTime) { date += ' ' + splits[2]; }

                        // TODO. Parse exact.
                        const parsedDate = new Date(date);
                        if (!isNaN(parsedDate.getTime())) {
                            const localDate = ReportMessageTooltip.ParceDate(
                                parsedDate.getTime().toString(), sesSettings, !hasTime);

                            cells.text =
                        splits[0] + ' ' + localDate;
                        }
                    }
                } catch (err) {
                    ErrorInformationStorage.GetException(err);
                    console.log('failed to parse tif');
                }
            }
        }
    }

    // TODO. UGLY. Refactor.
    public static localizeEntries (entries): void {
        if (!entries?.length) {
            return;
        }

        for (let i = 0, len = entries.length; i < len; i++) {
            ReportMessageTooltip.localizeEntry(entries[i], entries);
        }
    }

    public static localizeEntry (entry, entries): void {
        const caption = entry.caption;
        const captionKey = 'reports.' + caption;
        entry.caption = Resources.getResource(
            Resources.IsResourcePresent(captionKey)
                ? captionKey
                : caption);

        const text = entry.text;
        let textKey = text;
        if (Resources.IsResourcePresent('reports.' + text)) {
            textKey = 'reports.' + text;
        } else if (Resources.IsResourcePresent('property.' + text)) {
            textKey = 'property.' + text;
        }

        entry.text = Resources.getResource(textKey);

        if (Resources.isHidden(textKey)) {
            const index = entries.indexOf(entry);
            if (index !== -1) {
                entries.splice(index, 1);
            }
        }
    }

    private getTextForCriticalMarginMsg (data): string {
        this.refuseMode = true;
        let text = '';

        if (data.length > 1) {
            const rowsToCollect = ['AdditionalProperty.User', 'AdditionalProperty.Account', 'AdditionalProperty.Time'];
            for (const [label, value] of data) {
                if (!rowsToCollect.includes(label)) {
                    continue; // Processing only the rows specified in rowsToCollect
                }

                if (label === 'AdditionalProperty.Time') { // replacing "Date/Time" label with "at" label (reference #121755)
                    const dateTimeValue = value.replace(' ', ', ');
                    text += `${Resources.getResource('general.trading.at')}: ${dateTimeValue}`;
                    continue;
                }

                text += `${Resources.getResource(label)}: ${value}; `;
            }
        } else { text = Resources.getResource('screen.ReportMessageTooltip.MarginCall'); }

        return text;
    }
}

// #endregion ReportMessageTooltip
