// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import type Ractive from 'ractive';
import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Point } from '../../Commons/Geometry';
import $ from 'jquery';

// #region Capture
class Capture {
    public capturedObject = null;
    public offsetLeft = 0;
    public offsetTop = 0;
    public box = {
        xMin: 0,
        xMax: 0,
        yMin: 0,
        yMax: 0
    };

    public SetCapture (sender, event): void {
        if (!isNullOrUndefined(this.capturedObject)) {
            Capture.onDocumentMouseUp(event);
            return;
        }
        sender.captured = true;
        this.capturedObject = sender;
        try {
            this.offsetLeft = event.pageX - event.offsetX;
            this.offsetTop = event.pageY - event.offsetY;
            const curElement = $(event.target);

            const h = curElement.innerHeight();
            const w = curElement.innerWidth();
            const lt = curElement.offset();
            this.box = {
                xMin: lt.left,
                xMax: lt.left + w,
                yMin: lt.top,
                yMax: lt.top + h
            };

            const $doc = $(document);
            $doc.on('mousemove', Capture.onDocumentMouseMove);
            $doc.on('mouseup', Capture.onDocumentMouseUp);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public ClearCapture (): void {
        const $doc = $(document);
        $doc.off('mousemove', Capture.onDocumentMouseMove);
        $doc.off('mouseup', Capture.onDocumentMouseUp);

        if (!isNullOrUndefined(this.capturedObject)) {
            delete this.capturedObject.captured;
            delete this.capturedObject;
        }

        this.offsetLeft = 0;
        this.offsetTop = 0;

        this.box = {
            xMin: 0,
            xMax: 0,
            yMin: 0,
            yMax: 0
        };
    };

    public static onDocumentMouseMove (event): void {
        const capture = ControlsUtils.Capture;

        const tmpX = event.pageX - capture.offsetLeft;
        const tmpY = event.pageY - capture.offsetTop;

        if (event.pageX > capture.box.xMin) {
            event.offsetX = tmpX;
        } else {
            event.offsetX = capture.box.xMin;
        }

        if (event.pageX < capture.box.xMax) {
            event.offsetX = tmpX;
        } else {
            event.offsetX = capture.box.xMax;
        }

        const pY = event.pageY;
        const minY = capture.box.yMin;
        if (tmpY > minY) {
            event.offsetY = tmpY;
        } else {
            event.offsetY = minY;
        }

        const maxY = capture.box.yMax;
        if (tmpY < maxY) {
            event.offsetY = tmpY;
        } else {
            event.offsetY = maxY;
        }

        capture.capturedObject.onMouseMove(event);
    }

    public static onDocumentMouseUp (event): void {
        const capture = ControlsUtils.Capture;

        if (isNullOrUndefined(event.originalEvent)) {
            event = $.Event(event);
        }

        event.offsetX = event.pageX - capture.offsetLeft;
        event.offsetY = event.pageY - capture.offsetTop;

        if (!isNullOrUndefined(capture.capturedObject)) {
            capture.capturedObject.onMouseUp(event);
        }
        capture.ClearCapture();
    }
}

// #endregion Capture

export enum MouseButtons {
    Left = 0,
    Middle = 1,
    Right = 2
};

export class ControlsUtils {
    public static Capture = new Capture();
    public static TextWidthCache: any;
    private static canvas: any = null;

    public static getAbsoluteLocation (control): Point {
        const $window = $(window);
        const $control = $(control.find('*'));

        const offset = $control.offset();

        const posX = offset.left - $window.scrollLeft();
        const posY = offset.top - $window.scrollTop();

        return new Point(posX, posY);
    }

    public static GetTextWidth (text, font): number { // CanvasRenderingContext2D.prototype.GetTextWidth ?
        let cache = this.TextWidthCache;
        if (!cache) {
            cache = this.TextWidthCache = {};
        }

        const cachedTextWidth = cache[text + font];
        if (cachedTextWidth) {
            return cachedTextWidth;
        }

        const canvas = this.canvas || (this.canvas = document.createElement('canvas')); // re-use canvas object for better performance
        const context = canvas.getContext('2d');
        context.font = font;
        const metrics = context.measureText(text);
        cache[text + font] = metrics.width;
        return metrics.width;
    }

    // #region SVG circle
    public static polarToCartesian (centerX, centerY, radius, angleInDegrees): Point {
        const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

        return new Point(centerX + (radius * Math.cos(angleInRadians)), centerY + (radius * Math.sin(angleInRadians)));
    }

    public static createSVGPathSector (opts): string // возвращает строку svg-path
    {
        // let opts = {      // sector
        //     cx: 8,        // <-- center x
        //     cy: 8,        // <-- center y
        //     radius: 8,    // <-- circle radius
        //     start_angle: 0, // <-- start angle in degrees
        //     end_angle: deg, // <-- end angle in degrees
        // };

        const start = ControlsUtils.polarToCartesian(opts.cx, opts.cy, opts.radius, opts.end_angle);
        const end = ControlsUtils.polarToCartesian(opts.cx, opts.cy, opts.radius, opts.start_angle);
        const largeArcFlag = opts.end_angle - opts.start_angle <= 180 ? '0' : '1';

        const d = [
            'M', start.X, start.Y,
            'A', opts.radius, opts.radius, 0, largeArcFlag, 0, end.X, end.Y,
            'L', opts.cx, opts.cy,
            'Z'
        ].join(' ');

        return d;
    }

    public static getRactiveFromHTMLElement (el: HTMLElement): Ractive {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        return el['_ractive']?.proxy?.ractive;
    }
}
