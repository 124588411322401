// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class NonFixedInstumentStrikesRequest extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_INSTRUMENT_STRIKES_REQUEST, fieldSet);
    }

    public setContractID (contractID: number): void {
        this.setFieldValue(FieldsFactory.FIELD_CONTRACT_ID, contractID);
    }

    public setRequestId (p: number): void {
        this.setFieldValue(FieldsFactory.FIELD_REQUEST_ID, p);
    }
}
