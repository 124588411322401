// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Rectangle } from '../../../Commons/Geometry';
import { Cursors } from '../../../Commons/Cursors';
import { LayersEnum, TerceraChartBaseRenderer } from '../TerceraChartBaseRenderer';
import { MouseButtons } from '../../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartUtils } from '../../TerceraChartUtils';
import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { type TerceraChartBase } from '../../TerceraChartBase';
import { TerceraChartToolRenderer } from './TerceraChartToolRenderer';
import { type TerceraChartWindowBase } from '../../Windows/TerceraChartWindowBase';

export class TerceraChartSelectionRenderer extends TerceraChartBaseRenderer {
    Active: boolean;
    pressed: boolean;
    windowToSelectTools: TerceraChartWindowBase;
    brush: any;
    pen: any;
    selectRect = Rectangle.Empty();

    constructor (chart: TerceraChartBase) {
        super(chart);
        this.Active = false;
        this.pressed = false;
        this.windowToSelectTools = null;
        this.ThemeChanged();
        this.assignLayer = LayersEnum.Tools;
        this.SetClassName('TerceraChartSelectionRenderer');
    }

    SelectTools (): void {
        if (isNullOrUndefined(this.windowToSelectTools)) {
            return;
        }
        const toolRenderer = this.windowToSelectTools.GetRenderer(TerceraChartToolRenderer);
        if (isNullOrUndefined(toolRenderer)) {
            return;
        }
        toolRenderer.SelectToolsByRect(TerceraChartUtils.AbsRectangle(this.selectRect));
    }

    ProcessMouseDown (e: any): boolean {
        if (this.Active && e.Button == MouseButtons.Left) {
            this.pressed = true;
            this.selectRect.X = e.X;
            this.selectRect.Y = e.Y;
            this.windowToSelectTools = this.chart.GetWindowByPoint(e.Location);
            return true;
        }
        return super.ProcessMouseDown(e);
    }

    ProcessMouseMove (e: any): boolean {
        if (this.Active) {
            if (this.pressed) {
                this.selectRect.Width = e.X - this.selectRect.X;
                this.selectRect.Height = e.Y - this.selectRect.Y;
                e.NeedRedraw = [LayersEnum.Tools, LayersEnum.CrossHair];
            } else {
                e.NeedRedraw = LayersEnum.CrossHair;
            }
        }
        return super.ProcessMouseMove(e);
    }

    ProcessMouseUp (e: any): boolean {
        this.pressed = false;
        if (this.Active && e.Button == MouseButtons.Left) {
            this.SelectTools();
            this.selectRect = Rectangle.Empty();
        }
        return super.ProcessMouseDown(e);
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        if (this.Active && this.pressed && this.windowToSelectTools != null) {
            const clientRect = this.windowToSelectTools.ClientRectangle;

            gr.save();
            gr.beginPath();
            gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
            gr.clip();

            const absRect = TerceraChartUtils.AbsRectangle(this.selectRect);

            gr.DrawRect(this.pen, absRect.X, absRect.Y, absRect.Width, absRect.Height);
            gr.FillRect(this.brush, absRect.X, absRect.Y, absRect.Width, absRect.Height);

            // Reset clip.
            gr.restore();
        }
        super.Draw(gr, window, windowsContainer, advParams);
    }

    Dispose (): void {
        this.chart = null;
        super.Dispose();
    }

    ThemeChanged (): void {
        super.ThemeChanged();
        this.pen = ThemeManager.CurrentTheme.Chart_ToolSelectorRectPen;
        this.brush = ThemeManager.CurrentTheme.Chart_ToolSelectorRectBrush;
    }

    GetCursor (e: any): string | null {
        if (this.Active) {
            for (let i = 0; i < this.chart.windowsContainer.Windows.length; i++) {
                if (this.chart.windowsContainer.Windows[i].ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
                    return Cursors.Cross;
                }
            }
        }
        return null;
    }
}
