// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolFactory } from './DataCacheToolFactory';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { DynProperty } from '../DynProperty';
import { type Instrument } from './Instrument';

class _ToolsCache {
    public OnAddTool = new CustomEvent();
    public OnRemoveTool = new CustomEvent();
    public OnChangeTool = new CustomEvent();
    public Tools = [];

    public AddTool (tool): void {
        tool.OnUpdate.Subscribe(this.UpdateTool, this);
        this.Tools.push(tool);
        this.OnAddTool.Raise(tool);
    }

    public RemoveTool (tool): void {
        tool.OnUpdate.UnSubscribe(this.UpdateTool, this);
        const remIndex = this.Tools.indexOf(tool);
        this.Tools.splice(remIndex, 1);

        this.OnRemoveTool.Raise(tool);
    // delete tool;
    }

    public UpdateTool (tool): void {
        this.OnChangeTool.Raise(tool);
    }

    public CheckToolEnabilityAndUpdateInstrument (tool, ins: Instrument): boolean {
        if (tool.AssignedSymbol === ins.GetInteriorID()) {
        // Non fixed list instrument case
            tool.Instrument = ins;
            return true;
        }
        return false;
    }

    public GetTools (ins: Instrument): any[] {
        const res = [];

        if (ins == null) {
            return res;
        }

        for (let i = 0; i < this.Tools.length; i++) {
            const tool = this.Tools[i];
            if (this.CheckToolEnabilityAndUpdateInstrument(tool, ins)) {
                res.push(tool);
            }
        }

        return res;
    }

    public GetToolById (toolId): any {
        const res = null;

        if (!toolId) {
            return res;
        }

        for (let i = 0; i < this.Tools.length; i++) {
            const tool = this.Tools[i];
            if (tool.ID === toolId) {
                return tool;
            }
        }
        return res;
    }

    public Clear (): void {
        this.Tools.length = 0;
    }

    public GetToolsForSave (): string {
        const result = [];
        for (let i = 0; i < this.Tools.length; i++) {
            const tool = this.Tools[i];

            const props = DynProperty.serialize(tool.Properties());
            const type = tool.ToolType;
            result.push({ type, properties: props });
        }
        return JSON.stringify(result);
    }

    public CreateToolsFromSaves (properies, dataCache): void {
        if (!properies) {
            return;
        }

        this.Clear();
        let tools = [];
        try {
            tools = JSON.parse(properies);
        } catch (e) {
            ErrorInformationStorage.GetException(e);
            tools = [];
        }

        for (let i = 0; i < tools.length; i++) {
            const tool = tools[i];
            const newTool = DataCacheToolFactory.Create(tool.type, dataCache);
            newTool.callBack(DynProperty.deserialize(tool.properties));
            this.Tools.push(newTool);
        }
    }
}

export enum ToolsCacheAction {
    Add = 0,
    Remove = 1,
    Update = 2,
    Synhronize = 3
}

export const ToolsCache = new _ToolsCache();
