// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { AlertUtils } from '../../../Utils/Alert/AlertUtils';
import { AlertAction } from '../../../Utils/Alert/AlertConstants';
import { CreateAlertActionSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Localizations/Resources';
import { type IAlertActionItem } from '../../../Utils/Alert/AlertItems';
import { ResourcesManager } from '../../../Commons/properties/ResourcesManager';

export class CreateAlertActionSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'CreateAlertActionSelector'; }

    public override oninit (): void {
        super.oninit();

        this.repopulate();
        this.observe('selectedItem', this.updateAlertAction);
        this.observe('value', this.updateAlertActionValue);

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public updateAlertAction (selectedItem: IAlertActionItem): void {
        if (!isNullOrUndefined(selectedItem)) {
            void this.set({ value: selectedItem.value });
        }
    }

    public updateAlertActionValue (newV: AlertAction, oldV): void {
        const items: IAlertActionItem[] = this.get('items');
        if (!isValidArray(items)) {
            return;
        }

        for (let i = 0; i < items.length; i++) {
            if (items[i].value === newV) {
                void this.set({ selectedItem: items[i] });
                break;
            }
        }
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose.call(this);
    }

    public repopulate (): void {
        const items: IAlertActionItem[] = AlertUtils.GetAlertActionItems();
        void this.set({ items, selectedItem: items[AlertAction.NOTIFY], visible: items.length > 1 });
    }

    public localize (): void {
        void this.set({
            label: Resources.getResource('screen.Alerts.Label.Action')
        });
    }
}

ContainerControl.extendWith(CreateAlertActionSelector, {
    template: CreateAlertActionSelectorTemplate,
    data: function () {
        return {
            label: '',
            instrument: null,

            items: null,
            selectedItem: null,
            defaultValue: null,
            visible: true,

            width: 198
        };
    }
});
