// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Instrument } from '../../cache/Instrument';

class _TvSymbolsManager {
    private currentInstrument: Instrument;

    public getCurrentInstrument (): Instrument {
        return this.currentInstrument;
    }

    public setCurrentInstrument (instrument: Instrument): void {
        this.currentInstrument = instrument;
    }
}

export const TvSymbolsManager = new _TvSymbolsManager();
