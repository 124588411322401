// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

/// <summary>
/// class to hold values needed to draw Pointer
/// </summary>
/// <param name="DrawPointerTypeEnum">Type of pointer - for sorting draw order</param>
/// <param name="curPriceValue">level for drawing pointer. если установлен yLocation - брать высоту с него  иначе из priceValue через преобразование</param>
/// <param name="backgroundBrush">backGround color</param>
/// <param name="formatcurPriceValue">formatted text</param>
/// <param name="f"></param>
/// <param name="borderPen">border if needed</param>
/// <param name="sf"></param>
/// <param name="yLocation">если установлен yLocation - брать высоту с него  иначе из priceValue через преобразование</param>
export class DrawPointer {
    public priceValue: any;
    public backgroundBrush: any;
    public formatPriceValue: any;
    public Font: any;
    public borderPen: any;
    public StringFormat: any;
    public DrawPointerTypeEnum: any;
    public yLocation: number;

    constructor (DrawPointerTypeEnum: DrawPointerTypeEnum, curPriceValue, backgroundBrush, formatcurPriceValue, f?, borderPen?, sf?, yLocation?) {
        this.priceValue = curPriceValue;
        this.backgroundBrush = backgroundBrush;
        this.formatPriceValue = formatcurPriceValue;
        this.Font = f || null;
        this.borderPen = borderPen || null;
        this.StringFormat = sf || null;
        this.DrawPointerTypeEnum = DrawPointerTypeEnum;
        this.yLocation = yLocation || -1;
    }
}

export enum DrawPointerTypeEnum {
    BidAsk = 1,
    Indicator = 2,
    Overlay = 3,
    VisualTrading = 4,
    LowPriority = 5
}

export const DrawPointerComparer = function (x, y) {
    if (x.DrawPointerTypeEnum !== y.DrawPointerTypeEnum) {
        return (y.DrawPointerTypeEnum > x.DrawPointerTypeEnum ? 1 : -1);
    } else {
        return (x.priceValue > y.priceValue ? 1 : -1);
    }
};
