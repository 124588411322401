// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectCrossratesPlanMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { CrossRateData } from '../../../../Utils/CrossRate/CrossRateData';
import { MarkupData } from '../../../../Utils/CrossRate/MarkupData';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class CrossRatesPlanMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectCrossratesPlanMessage[] {
        const cpm = new DirectCrossratesPlanMessage();
        cpm.Id = fieldSet.GetValue(FieldsFactory.FIELD_ID);
        cpm.Name = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        cpm.Description = fieldSet.GetValue(FieldsFactory.FIELD_DESCRIPTION);
        cpm.CounterAccountID = fieldSet.GetValue(FieldsFactory.FIELD_COUNTER_ACCOUNT_ID);
        cpm.MarkupMeasure = fieldSet.GetValue(FieldsFactory.FIELD_CROSS_MARKUP_MEASURE);

        const entrygroups = fieldSet.GetGroups(FieldsFactory.MARKUP_ENTRY_GROUP);
        const len = entrygroups.length;
        for (let i = 0; i < len; i++) {
            const entry = entrygroups[i];

            const groups = entry.GetGroups(FieldsFactory.CROSS_MARKUP_GROUP);
            const res = new Array<CrossRateData>(groups.length);

            for (let j = 0, j_len = groups.length; j < j_len; j++) {
                const resItem = new CrossRateData();
                const group = groups[j];
                res[j] = resItem;
                resItem.Exp1 = group.GetValue(FieldsFactory.FIELD_NAME_EXP1);
                resItem.Exp2 = group.GetValue(FieldsFactory.FIELD_NAME_EXP2);
                resItem.Price = group.GetValue(FieldsFactory.FIELD_CROSS_MARKUP);
            }

            const instrId = entry.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID);
            if (instrId === -2) {
                cpm.NonTradableMarkups = res;
            } else {
                const formula = entry.GetValue(FieldsFactory.FIELD_MARKUP_FORMULA_TYPE);
                cpm.MarkupData.push(new MarkupData(instrId, formula, res));
            }
        }

        return [cpm];
    }
}
