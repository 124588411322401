// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { CustomEvent } from '../../Utils/CustomEvents';
import { type Account } from './Account';
import { FundingRateMarkupPlan } from './FundingRateMarkupPlan';
import { type Instrument } from './Instrument';

export class FundingRateMarkupPlans {
    public UpdateEvent = new CustomEvent();
    public _fFundingRateMarkupPlans: any = {};

    public GetPlanById (id): any {
        return this._fFundingRateMarkupPlans[id] || null;
    }

    public Add (message): void {
        let plan = this.GetPlanById(message.Id);
        if (!plan) {
            plan = this._fFundingRateMarkupPlans[message.Id] = new FundingRateMarkupPlan(message.Id);
        }
        plan.Update(message);
        this.UpdateEvent.Raise(plan);
    }

    public Clear (): void {
        this._fFundingRateMarkupPlans = {};
    }

    public GetDataByInstrument (planId, instr: Instrument | null): any {
        if (instr === null || planId === null) {
            return null;
        }

        const plan = this.GetPlanById(planId);
        return plan != null ? plan.GetData(instr) : null;
    }

    public GetSpreadedFundingRateValue (acc: Account | null, ins: Instrument | null, fundingRateValue): number {
        const data = acc ? this.GetDataByInstrument(acc.FundingRatesPlanId, ins) : null;

        return FundingRateMarkupPlans.GetSpreadedFundingRateValueByPlanData(data, fundingRateValue);
    }

    public static GetSpreadedFundingRateValueByPlanData (planData, fundingRateValue): number {
        if (planData) {
            switch (planData.MarkupType) {
            case FundingRateMarkupPlanTypes.FIXED_PERCENT:
                return fundingRateValue * 100 + planData.MarkupValue;

            case FundingRateMarkupPlanTypes.PERCENT_FROM_FUNDING_RATE:
                return fundingRateValue * 100 + planData.MarkupValue / 100 * fundingRateValue * 100;
            }
        }

        return fundingRateValue;
    }
}

export enum FundingRateMarkupPlanTypes {
    FIXED_PERCENT = 0,
    PERCENT_FROM_FUNDING_RATE = 1
}
