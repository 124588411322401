// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { CustomEvent } from '../../Utils/CustomEvents';
import { CurrentLang, LOCALE_AR, LOCALE_CH_SM, LOCALE_CH_TR, LOCALE_DE, LOCALE_EN, LOCALE_ES, LOCALE_JA, LOCALE_PL, LOCALE_PT, LOCALE_RU, LocaleNames, Resources } from '../../Localizations/Resources';
import { LocalStorage } from '../LocalStorage';
import { ApplicationInfo } from '../ApplicationInfo';

class _ResourcesManager {
    constructor () {
        this.localeChanged = this.localeChanged.bind(this);
        Resources.subscribeOnLocaleChange(this.localeChanged);
    }

    public onLocaleChange = new CustomEvent();

    public InitStartLanguage (): void {
        let lang = this.GetCurrentAppLang();
        LocalStorage.setLanguage(lang);
        if (!lang || lang === 'null') {
            lang = LOCALE_EN;
        }

        Resources.setLocale(lang);
    }

    public GetCurrentAppLang (): string {
        let defLang = ApplicationInfo.LoginLanguage;
        if (defLang) {
            defLang.toLocaleLowerCase();
        }

        if (!defLang) {
            defLang = LOCALE_EN;
        } else if (!LocaleNames.includes(defLang)) {
            defLang = LOCALE_EN;
        }

        let storagelang = LocalStorage.getLanguage();
        if (!LocaleNames.includes(storagelang)) {
            storagelang = LOCALE_EN;
        }

        return storagelang || defLang;
    }

    public linkTokenLangName (): string {
        switch (this.GetCurrentAppLang()) {
        case LOCALE_EN:
            return 'en-GB';
        case LOCALE_ES:
            return 'es-ES';
        default:
            return 'en-GB';
        }
    }

    public extLinkLocaleName (): string {
        switch (this.GetCurrentAppLang()) {
        case LOCALE_EN:
            return 'en_GB';
        case LOCALE_PL:
            return 'pl_PL';
        case LOCALE_PT:
            return 'pt_PT';
        case LOCALE_RU:
            return 'ru_RU';
        case LOCALE_JA:
            return 'ja_JP';
        case LOCALE_CH_TR:
            return 'zh_CN';
        case LOCALE_CH_SM:
            return 'zh_CN';
        case LOCALE_AR:
            return 'ar_AE';
        case LOCALE_ES:
            return 'es_ES';
        case LOCALE_DE:
            return 'de_DE';
        default:
            return 'en_GB';
        }
    }

    private localeChanged (): void {
        LocalStorage.setLanguage(CurrentLang);
        this.onLocaleChange.Raise();
    }
}

export const ResourcesManager = new _ResourcesManager();
