// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class NonFixedNewInstumentRequest extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_INSTRUMENT_REQUEST, fieldSet);
    }

    public setInstrumentID (instrID: number): void {
        this.setFieldValue(FieldsFactory.FIELD_INSTRUMENT_ID, instrID);
    }

    public setInstrumentName (instrName: string): void {
        this.setFieldValue(FieldsFactory.FIELD_NAME, instrName);
    }

    public setInstrumentTradableID (instrName: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, instrName);
    }

    public setRequestId (p: number): void {
        this.setFieldValue(FieldsFactory.FIELD_REQUEST_ID, p);
    }
}
