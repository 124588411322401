// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectSessionMessage } from "@shared/utils/DirectMessages/DirectSessionMessage";
import { Session } from "@shared/utils/Session/Session";

export class SessionsCache {
    private readonly _sessions: Map<number, Session> = new Map();

    public getSession (id: number): Session | null {
        return this._sessions.get(id) ?? null;
    }

    public newMessage(msg: DirectSessionMessage): Session {
        let session: Session = this.getSession(msg.id);
        if (session == null) {
            session = new Session(msg.id, msg.name);
            this._sessions.set(session.Id, session);
        } 
        session.newMessage(msg);
        return session;
    }

    public clear (): void {
        this._sessions.clear();
    }
}