// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MarketConsensusDataItem, MarketConsensusPercentTypeEnum } from '../../../../../Commons/cache/MarketConsensus';
import { DirectAlgorithmMarketConsensusMessage } from '../../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { AlgorithmTopLongShortInstrumentGroup, type PFixFieldSet } from '../../Groups/GroupsImport';
import { MessageProcessorBase } from '../MessageProcessorBase';

export class AlgorithmMarketConsensusMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlgorithmMarketConsensusMessage[] {
        const msg = new DirectAlgorithmMarketConsensusMessage();
        const ff = FieldsFactory;

        msg.AlgorithmId = fs.GetValue(ff.FIELD_ALGORITHM_ID);
        msg.AlgorithmName = fs.GetValue(ff.FIELD_ALGORITHM_NAME);

        const groups = fs.GetGroups(ff.ALGORITHM_TOP_LONG_SHORT_INSTRUMENT_GROUP);
        for (let i = 0; i < groups.length; i++) {
            const gr = new AlgorithmTopLongShortInstrumentGroup(groups[i]);
            const item = new MarketConsensusDataItem(gr.GetGroupContent());
            const data = item.props;

            if (data.MarketConsensusPercentType === MarketConsensusPercentTypeEnum.LONG) {
                msg.TopLongList.push(data);
            } else {
                msg.TopShortList.push(data);
            }
        }

        return [msg];
    }
}
