// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountWidgetAccountsController } from '@shared/commons/AccountWidget/AccountWidgetAccountsController';
import { CustomerAccess } from '@shared/commons/CustomerAccess/CustomerAccess';
import { DataCache } from '@shared/commons/DataCache';
import { Resources } from '@shared/localizations/Resources';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { TradingLockUtils } from '@shared/utils/TradingLockUtils';
import { AccountWidgetMenuTemplate } from '../../../templates';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { ACCOUNT_WIDGET_MENU_TOP_OFFSET } from '../../UtilsClasses/SizeConstants';
import { CloseAccountScreen } from '../../screen/CloseAccountScreen';
import { TerceraChangePasswordScreen } from '../../screen/TerceraChangePasswordScreen';
import { WithdrawalScreen } from '../../screen/WithdrawalScreen';
import { TerceraLinkControlConstants } from '../../UtilsClasses/TerceraLinkControlConstants';
import { LinkedSystem, LinkedSystemAccLinkingValue } from '../../misc/LinkedSystem';
import { AccountWidgetAccountDetailsPanelController } from '@shared/commons/AccountWidget/AccountWidgetAccountDetailsPanelController';
import { TerceraMenuWithOver } from '../TerceraMenuWithOver';
import { Rectangle } from '@shared/commons/Geometry';
import { AccountMenuItemsHelper } from '@shared/commons/AccountWidget/AccountMenuItemsHelper';

export class AccountWidgetMenu extends TerceraMenuWithOver {
    constructor () {
        super();

        this.menuHandler = null;
    }

    get isShowed (): boolean {
        return this._isShowed;
    }

    set isShowed (value: boolean) {
        this._isShowed = value;
        void this.set({ isShowed: value });
    }

    public getType (): string { return 'AccountWidgetMenu'; };

    private async linkClicked (): Promise<void> { // https://tp.traderevolution.com/entity/83015
        if (this.get('accLinkBtnEnabled') === false) { return; }

        const lS = LinkedSystem;
        const newActiveState = !lS.accLinkingActive;
        lS.accLinkingActive = newActiveState;
        const accId = AccountWidgetAccountsController.Account.BstrAccount;
        if (newActiveState) lS.accLinkingOn(accId); else lS.accLinkingOff();
    }

    private accLinkBtnSelectedLinkValue (): void {
        this.accLinkStateUpdate();
        AccountWidgetAccountDetailsPanelController?.accountLinkOut(true);
    }

    private accLinkStateUpdate (): void {
        LinkedSystem.accLinkingActive = this.isAccLinkOn();
    }

    private isAccLinkOn (): boolean {
        return this.get('accLinkBtnSelectedLinkValue') === LinkedSystemAccLinkingValue;
    }

    get accModeWidth (): number { return this.get('accModeWidth'); }
    set accModeWidth (value: number) { void this.set({ accModeWidth: value }); }

    public oncomplete (): void {
        this.on('linkClicked', this.linkClicked);
        this.on('dropDownFormOpened', () => { this.Close(); });
        this.observe('selectedAccount', this.onSelectedAccount, { init: false });
        this.observe('accLinkBtnSelectedLinkValue', this.accLinkBtnSelectedLinkValue, { init: false });

        LinkedSystem.OnAccountLinkingStateChanged.Subscribe(this.OnAccountLinkingStateChanged, this);
        DataCache.OnReintialize.Subscribe(this.dispose, this);
    }

    public dispose (): void {
        LinkedSystem.OnAccountLinkingStateChanged.UnSubscribe(this.OnAccountLinkingStateChanged, this);
        MainWindowManager.MainWindow.onMouseMoveEvt.UnSubscribe(this.TryToCloseMenu, this);
        DataCache.OnReintialize.UnSubscribe(this.dispose, this);
        AccountWidgetMenu.screenInstance = null;
        super.dispose();
    }

    public onSelectedAccount (account): void {
        if (isNullOrUndefined(account)) return;
        AccountWidgetAccountsController.Account = account;
        if (this.isAccLinkOn()) { LinkedSystem.accLinkingOn(AccountWidgetAccountsController.Account.AcctNumber); }
    }

    private onAccBtnClick (): void {
        let items = [];

        const account = AccountWidgetAccountsController.Account;
        const Data = AccountMenuItemsHelper.GetDataToFillOut([account]);

        void this.set({
            accMenuItems: Data.Items,
            onlyOwns: Data.IsOnlyOwnAccounts,
            addtype: Data.IsAddType,
            singleAccount: Data.IsSingleAccount,
            selectedAccount: account
        });

        if (CustomerAccess.panelAllowed(PanelNames.AssetBalancesPanel)) {
            items.push({
                style: 'js-AccountMenu-Accounts',
                name: 'accounts',
                text: Resources.getResource('panel.assetBalances'),
                event: () => {
                    MainWindowManager.Factory.addPanel(PanelNames.AssetBalancesPanel);
                }
            });
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_RESERVER_WITHDRAWAL) && !Resources.isHidden('ribbon.tools.withdrawal')) {
            items.push({ style: 'js-AccountMenu-Withdrawal', name: 'Withdrawal', text: Resources.getResource('ribbon.tools.withdrawal'), event: () => { WithdrawalScreen.show(); } });
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_REPORT) && !Resources.isHidden('ribbon.tools.reports')) {
            items.push({ style: 'js-AccountMenu-Reports', name: 'reports', text: Resources.getResource('ribbon.tools.reports'), event: () => { MainWindowManager.Factory.addPanel(PanelNames.ReportsPanel); } });
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_PRODUCTS) && !Resources.isHidden('ribbon.tools.products')) {
            items.push({ style: 'js-AccountMenu-Products', name: 'products', text: Resources.getResource('ribbon.tools.products'), event: () => { MainWindowManager.Factory.addPanel(PanelNames.ProductsPanel); } });
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_COPY_TRADE) && !Resources.isHidden('panel.CopyTrading')) {
            items.push({ style: 'js-AccountMenu-CopyTrading', name: 'copytrading', text: Resources.getResource('panel.CopyTrading'), event: () => { MainWindowManager.Factory.addPanel(PanelNames.CopyTradingPanel); } });
        }

        if (DataCache.isAllowedForMyUser(RulesSet.ALLOW_CHANGE_PASSWORD)) {
            items.push({ separator: true });
            if (TradingLockUtils.IsUseLockTradingByPassword && !Resources.isHidden('ribbon.changeTradingPass')) { // #89340 & #94446
                items.push({ style: 'js-AccountMenu-ChangeTradingPassword', name: 'changeTPWD', text: Resources.getResource('screen.changeTradingPass.title'), event: () => { TerceraChangePasswordScreen.show(null, null, null, true); } });
            }
            items.push({ style: 'js-AccountMenu-ChangePassword', name: 'changePWD', text: Resources.getResource('screen.changepass.title'), event: () => { TerceraChangePasswordScreen.show(); } });
            items.push({ separator: true });
        }

        items = items.concat(MainWindowManager.MainWindow.getExternalAccountMenuItems());

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_CLOSE_ACCOUNT)) {
            items.push({ separator: true });
            items.push({ style: 'js-AccountMenu-DeleteAccount', name: 'deleteAcc', text: Resources.getResource('ribbon.closeAccount'), event: () => { CloseAccountScreen.ShowMsg(); } });
        }
        void this.set({ menuitems: items });

        void this.updateAccLinkBtnState();
    }

    private updateAccModeWidth (): void {
        const account = AccountWidgetAccountsController.Account;
        const Data = AccountMenuItemsHelper.GetDataToFillOut([account]);
        const items = Data.Items;

        items.forEach(item => {
            const itemWidth = item.GetModeWidth();
            if (itemWidth > this.accModeWidth) {
                this.accModeWidth = itemWidth;
            }
        });
    }

    public override Hide (): void {
        this.isShowed = false;
    }

    private OnAccountLinkingStateChanged (newValueState, newStyle): void {
        void this.set({
            accLinkBtnSelectedLinkValue: newValueState,
            accLinkBtnClass: newStyle
        });
    }

    private async updateAccLinkBtnState (): Promise<void> {
        const forceAccLinking = DataCache.EnableForceLinkingByAccount();
        const singleAccount = DataCache.getNumberOfAccounts() === 1;

        await this.set({
            accLinkBtnEnabled: !forceAccLinking,
            accLinkBtnVisible: !forceAccLinking && !singleAccount,
            accLinkBtnTooltip: 'panel.AccountLinkButton.ToolTip'
        });

        this.accLinkStateUpdate();

        if (forceAccLinking) { LinkedSystem.accLinkingOn(AccountWidgetAccountsController.Account.AcctNumber); }
    };

    public AccountWidgetItemClick (event, item): void {
        if (isNullOrUndefined(item?.Account)) return;
        this.onSelectedAccount(item.Account);
        this.Close();
    }

    public handlerOnMainBtnOver (btnContext, menuItems: any[]): void {
        const x = btnContext.node.offsetLeft;
        const y = 60;
        const btnHeight = btnContext.node.offsetHeight;
        this.rectangleBtwMenuAndBtn = new Rectangle(x, btnContext.node.offsetTop + btnHeight, btnContext.node.offsetWidth, y - btnHeight);

        if (!isNullOrUndefined(this.activeMainMenuBtn)) {
            this.activeMainMenuBtn.set('focusedAccWidget', false);
        }

        this.activeMainMenuBtn = btnContext;
        this.activeMainMenuBtn.set('focusedAccWidget', true);
        this.updateAccModeWidth();
    }

    public private_onItemClick (event, sender): void {
        super.private_onItemClick(event, sender);
        this.Close();
    }

    public Close (): void {
        this.activeMainMenuBtn.set('focusedAccWidget', false);
        this.ResetMainMenuFlags();
        this.Hide();
        this.isMouseOnMenu = false;
    }

    static ShowContextMenu (containerContext): void {
        if (AccountWidgetMenu.screenInstance === null) {
            AccountWidgetMenu.screenInstance = new AccountWidgetMenu();
            MainWindowManager.MainWindow.addControl(AccountWidgetMenu.screenInstance);
        }
        if (AccountWidgetMenu.screenInstance.isShowed) return;

        AccountWidgetMenu.screenInstance.handlerOnMainBtnOver(containerContext, []);
        const node = containerContext.node.parentNode;
        const leftOffset = node.offsetLeft;
        const offsetTop = node.offsetHeight + node.offsetTop;
        AccountWidgetMenu.screenInstance.onAccBtnClick();
        void AccountWidgetMenu.screenInstance.set({ left: leftOffset, top: offsetTop + ACCOUNT_WIDGET_MENU_TOP_OFFSET });
        AccountWidgetMenu.screenInstance.isShowed = true;
        AccountWidgetMenu.screenInstance.setFocus();
    }

    static HideContextMenu (containerContext): void {
        if (!isNullOrUndefined(AccountWidgetMenu.screenInstance)) {
            AccountWidgetMenu.screenInstance.TryToCloseMenu(containerContext);
        }
    }

    public static screenInstance: AccountWidgetMenu = null;
}

TerceraMenuWithOver.extendWith(AccountWidgetMenu, {
    template: AccountWidgetMenuTemplate,
    data: function () {
        return {
            selectedAccount: null,
            menuitems: [],
            accMenuItems: [],
            onlyOwns: false,
            accLinkBtnEnabled: false,
            accLinkBtnSelectedLinkValue: TerceraLinkControlConstants.ColorConstants.NONE,
            accLinkBtnVisible: false,
            accLinkBtnTooltip: '',
            accLinkBtnClass: 'js-link-color-none-acc',
            addtype: true,
            accModeWidth: 30
        };
    }
});
