// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { contextMenuHandler } from '@shared/utils/AppHandlers';
import { TerceraComboBoxTemplate } from '../../templates.js';
import { type IContextMenuItem } from '../UtilsClasses/IContextMenuItem';
import { Control } from './Control';
import $ from 'jquery';

export class TerceraComboBox extends Control {
    public menuItems: any = null;
    public isMenuShown = false;

    public override getType (): string { return 'TerceraComboBox'; }

    public override oninit (): void {
        super.oninit();
        this.observe('items', this.onItemsChanged);
        this.observe('selectedItem', this.onSelectedItemChanged);
    }

    public onItemsChanged (newItems): void {
        if (!newItems || newItems.length === 0) {
            void this.set({ selectedItem: null, items: [] });
            return;
        }

        let newSelItem = null;

        if (this.get('showLastValue')) {
            const oldSelItem = this.get('selectedItem');
            newSelItem = oldSelItem ? this.getItemByValue(oldSelItem.value) : null;
        }
        if (!newSelItem) newSelItem = this.getDefaultItem() || newItems[0];

        void this.set({ selectedItem: newSelItem });
        this.menuItems = this.createMenuItems(newSelItem);
    }

    public onSelectedItemChanged (newValue, oldValue): void {
        if (newValue && oldValue && (newValue.value === oldValue.value)) {
            return;
        }

        const val = newValue ? newValue.value : null;
        this.updateSelection(newValue);
        this.fire(TerceraComboBoxEvents.ValueChange, val);
    }

    public getDefaultItem (): MenuItem {
        return this.getItemByValue(this.get('defaultValue'));
    }

    public getItemByValue (value): MenuItem {
        const items = this.get('items');
        const len = items.length;
        for (let i = 0; i < len; i++) {
            const item = items[i];
            if (item.value === value) {
                return item;
            }
        }

        return null;
    }

    public private_BtnClick (sender): void {
        const isEnabled: boolean = this.get('enabled');
        if (!this.get('enabled') || !this.get('selectedItem')) {
            return;
        }

        if (this.isMenuShown) {
            this.isMenuShown = !this.isMenuShown;
            this.HideMenu();
            return;
        }

        this.MenuShow();
    }

    public MenuShow (sender?): void {
        const width = $(this.find('div')).outerWidth();
        const offset = $(this.find('div')).offset();
        const posY = offset.top - $(window).scrollTop() + 26;
        const posX = offset.left - $(window).scrollLeft();

        const selectedItem = this.get('selectedItem');

        const additionalParams = {
            width: Math.max(this.get('listWidth') || width),
            isComboboxMenu: true,
            initialSelectedValue: selectedItem ? selectedItem.value : null
        };

        this.isMenuShown = true;
        contextMenuHandler.Show(this.menuItems, posX, posY, additionalParams);
    }

    public createMenuItems (selectedItem): any[] {
        const items = this.get('items');
        const menuItems = [];

        const callback = this.private_OnMenuItemSelected.bind(this);

        const len = items.length;
        for (let i = 0; i < len; i++) {
            const item = items[i];

            menuItems.push({
                text: item.text,
                tooltip: item.tooltip,
                style: item.style,
                tag: item.value,
                event: callback,
                imagewidth: this.get('imagewidth') || 25,
                selected: item.value === selectedItem.value
            });
        }

        return menuItems;
    }

    protected updateSelection (selectedItem): void {
        if (isNullOrUndefined(this.menuItems)) return;
        if (isNullOrUndefined(selectedItem)) return;
        for (const item of this.menuItems) { item.selected = item.tag === selectedItem.value; }
    }

    public override getContextMenuItem (): IContextMenuItem {
        const contextMenuItem: any = super.getContextMenuItem();
        contextMenuItem.subitems = [];
        const selectedItem = this.get('selectedItem');
        for (let i = 0; i < this.menuItems.length; i++) {
            const item: any = new Object(this.menuItems[i]);
            if (!item.separator) {
                if (item.enabled === undefined) {
                    item.enabled = true;
                }
                item.canCheck = true;
                item.checked = selectedItem?.value === item.tag;
            }
            contextMenuItem.subitems.push(item);
        }
        return contextMenuItem;
    }

    public override lostFocus (): void {
        super.lostFocus();
        const isMenuShow = this.isMenuShown;
        this.isMenuShown = false;
        if (isMenuShow) { this.HideMenu(); }
    }

    public HideMenu (): void {
        contextMenuHandler.Hide();
    }

    public private_OnMenuItemSelected (menuItem): void {
        const newSelectedItem = this.getItemByValue(menuItem.tag);
        void this.set('selectedItem', newSelectedItem);
        this.fire(TerceraComboBoxEvents.ComboItemClicked, {}, menuItem);
        this.isMenuShown = false;
    }

    public forcedChangeMenuItemSelected (tag): void {
        const newSelectedItem = this.getItemByValue(tag);
        void this.set('selectedItem', newSelectedItem);
    }

    public addItem (newItem): void {
        const myItems = this.get('items');
        myItems.push(newItem);
        void this.set({ items: myItems });
    }

    public addItemRange (newItems): void {
        let myItems = this.get('items');
        myItems = myItems.concat(newItems);
        void this.set({ items: myItems });
    }

    public removeItem (itemIndex): void {
        const myItems = this.get('items');
        myItems.splice(itemIndex, 1);
        void this.set({ items: myItems });
    }

    public setItembyValue (value): void {
        const myItems = this.get('items');
        if (!myItems) return;

        const len = myItems.length;
        for (let i = 0; i < len; i++) {
            if (myItems[i].value === value) {
                void this.set('selectedItem', myItems[i]);
            }
        }
    }

    public count (): number {
        const items = this.get('items');
        return items ? items.length : 0;
    }

    public clearItems (): void {
        void this.set('items', []);
    }

    public clearSelection (): void {
        void this.set({
            selectedItem: {
                value: '',
                text: '',
                style: ''
            }
        });
    }
}

Control.extendWith(TerceraComboBox, {

    template: TerceraComboBoxTemplate,
    data: function () {
        return {
            showLastValue: false,
            items: [],
            selectedItem: null,
            defaultValue: null,
            tooltip: '',
            elementStyle: 'js-comboBox',
            additionalClass: '',
            listWidth: 0,
            useSVGView: false,
            usePNGView: false,
            showArrow: true,
            visibleInfoBtn: false,
            infoBtnTooltip: '',
            updateComboBoxClass: '', // Развитие возможно всегда. Идеал — цель, которая всегда меняется. Эволюция никогда не останавливается. Нельзя останавливаться!
            isPosAbsolute: false
        };
    }
});

// Events
export enum TerceraComboBoxEvents {
    ValueChange = 'ValueChange',
    ComboItemClicked = 'ComboItemClicked'
}

export class MenuItem {
    public text: any;
    public value: any;
    public style: any;

    constructor (text, value, style) {
        this.text = text;
        this.value = value;
        this.style = style;
    }
}
