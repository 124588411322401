// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Position } from '../../cache/Position';
import { Side, type Position as tvPosition } from '../charting_library';
import { PositionFormatter } from '../../cache/Formatters/PositionFormatter';
import { TvSymbolConvertor } from './TvSymbolConvertor';

class _TvPositionConvertor {
    public convertToTvPosition (position: Position): tvPosition {
        const tvPosition = {
            id: PositionFormatter.PositionId(position),
            symbol: this.getSymbol(position), // ticker ?
            qty: PositionFormatter.Amount(position),
            // shortQty?: number;
            // longQty?: number;
            side: this.getPositionSide(position),
            avgPrice: PositionFormatter.Price(position) // open price ?
            // message?: OrderOrPositionMessage;
        };
        return tvPosition;
    }

    private getPositionSide (position: Position): Side {
        return PositionFormatter.IsLong(position) ? Side.Buy : Side.Sell;
    }

    private getSymbol (position: Position): string {
        // const symbol = position.Instrument.DisplayName();
        // return TvInteriorIdCache.getInteriorId(symbol);
        return TvSymbolConvertor.getFullname(position.Instrument);
    }
}

export const TvPositionConvertor = new _TvPositionConvertor();
