// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PriceFormatter } from '@shared/utils/Instruments/PriceFormatter';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { Resources } from '@shared/localizations/Resources';
import { BaseSettings } from '../../../../Settings/BaseGeneralSettingsWrapper';
import { BaseOrderWarning } from './BaseOrderWarning';

export class OrderCapitalExceedWarning extends BaseOrderWarning {
    public getText (): string {
        const orderEdit = this.orderEdit;
        const orderType = orderEdit.getOrderTypeId();
        const basePrice = orderEdit.getBasePrice();
        const warnIfOrderCapitalExceedValue = BaseSettings.warnIfOrderCapitalExceedValue;
        const orderEditQuantity = orderEdit.quantity;
        let orderCapital = 0;
        if (orderEdit.isCashQtyMode) {
            orderCapital = orderEdit.cash;
        } else {
            const amount = orderEditQuantity.inLots ? orderEditQuantity.value * orderEdit.instrument.LotSize : orderEditQuantity.value;
            const curPrice = orderType === OrderType.OCO && orderEdit.stopPrice > basePrice ? orderEdit.stopPrice : basePrice;
            orderCapital = curPrice * amount * orderEdit.account.getCrossPrice();
        }

        if (!BaseSettings.warnIfOrderCapitalExceed || orderCapital < warnIfOrderCapitalExceedValue) {
            return '';
        }

        const sumStr = orderEdit.account?.formatPrice(orderCapital, true) ?? PriceFormatter.formatPrice(orderCapital, 2, true);
        const sumWarnStr = PriceFormatter.formatPrice(warnIfOrderCapitalExceedValue, 2, true);
        const text = Resources.getResource('general.warnings.orderCapitalExceedText', [sumStr, sumWarnStr]);
        return text;
    }
}
