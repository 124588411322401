// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectCustomListMessage } from '@shared/utils/DirectMessages/DirectMessagesImport';
import { AliasesGroup } from '../Groups/GroupsImport';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class CustomListMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectCustomListMessage[] {
        const msg = new DirectCustomListMessage();

        msg.Id = fs.GetValue(FieldsFactory.FIELD_ID);
        msg.Name = fs.GetValue(FieldsFactory.FIELD_NAME);
        msg.Description = fs.GetValue(FieldsFactory.FIELD_DESCRIPTION);
        msg.ListType = fs.GetValue(FieldsFactory.FIELD_LIST_TYPE);

        msg.RawBytes = fs.GetValue(FieldsFactory.FIELD_RAW_BYTES);
        msg.TradableInstrumentId = fs.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_IDS);

        msg.Aliases = AliasesGroup.GetFromFieldSet(fs);

        const responseEnd = fs.GetValue(FieldsFactory.FIELD_RESPONCE_END);
        if (responseEnd != null) {
            msg.ResponseEnd = responseEnd;
        }

        return [msg];
    }
}
