// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingOETemplate } from '../templates.js';
import { closeDataSourceScreen, openDataSourceScreen } from './InvestingDataSourceScreen';
import { openContextMenu, closeContextMenuEvent } from '../Controls/elements/InvestingMenu';
import { OETIFSelector } from '../../Controls/trading/OE/OETIFSelector';
import { Quantity } from '../../Utils/Trading/Quantity';
import { TIF } from '../../Utils/Trading/OrderTif';
import { Control } from '../../Controls/elements/Control';
import { debounce } from '../Utils/InvestingUtils';
import { Connection } from '../../Commons/Connection';
import { OperationType } from '../../Utils/Trading/OperationType';
import { OrderTif } from '../../Utils/Trading/OrderTifEnum';
import { PlacedFrom } from '../../Utils/Trading/PlacedFrom';
import { Account } from '../../Commons/cache/Account';
import { AccountFeature } from '../../Utils/Account/AccountFeature';
import { OrderEditUpdateData } from '../../Utils/Trading/OrderEditUpdateData';
import { LimitOrderType } from '../../Commons/cache/OrderParams/order-type/LimitOrderType';
import { MarketOrderType } from '../../Commons/cache/OrderParams/order-type/MarketOrderType';
import { DataCache } from '../../Commons/DataCache';
import Ractive from 'ractive';

export let openOrderEntry = null;

export class InvestingOE extends Ractive {
    public selectedOESwitcherItem: any = null;
    public Account: any = null;
    public AssetBalance: any = null;
    debounceHandler: () => void;

    public getType (): string { return 'InvestingOE'; }

    public oninit (): void {
        this.on('closeOrderEntry', this.closeOrderEntry);
        this.on('placeOrder', this.placeOrder);
        this.on('clickOESwitcher', this.changeActiveOESwitcher);
        this.on('dataSourceArrowClick', this.dataSourceArrowClick);
        this.on('NYSEArrowClick', this.NYSEArrowClick);

        this.observe('isLimit instrumentItem accountItem', this.UpdateNumericRefs);
    }

    public oncomplete (): void {
        openOrderEntry = (isBuy, instr, account, isAsset) => { this.Show(isBuy, instr, account, isAsset); };
        this.createHeaderSwitcher();

        this.debounceHandler = debounce(this.UpdateData, 400);
        Control.Ticker.Subscribe(this.debounceHandler, this);
    }

    public Show (isBuy, instr, account, isAsset = false): void {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        void this.set({
            showOrderEntry: true,
            offsetTop: scrollTop,
            imgOESrc: instr.Img,
            symbolDescription: instr.Description,
            symbolName: instr.Symbol,
            isBuy,
            isAsset,
            accountItem: account,
            instrumentItem: instr.Instrument
        });

        this.disableScroll();
    }

    public closeOrderEntry (): void {
        void this.set({ showOrderEntry: false });
        this.enableScroll();
    }

    public disableScroll (): void {
    // Get the current page scroll position
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;

        // if any scroll is attempted, set this to the previous value
        window.onscroll = function () {
            window.scrollTo(scrollLeft, scrollTop);
        };
    }

    public enableScroll (): void {
        window.onscroll = function () { };
    }

    public createHeaderSwitcher (): void {
        const arrayOESwitcher: any = [
            { Name: 'Market', isLimit: false },
            { Name: 'Limit', isLimit: true }
        ];

        arrayOESwitcher[0].active = true;
        this.selectedOESwitcherItem = arrayOESwitcher[0];

        void this.set({ arrayOESwitcher, isLimit: this.selectedOESwitcherItem.isLimit });
    }

    public changeActiveOESwitcher (ev, index): void {
        const items = this.get('arrayOESwitcher');
        if (!items) { return; 

        }

        const newselectedOESwitcherItem = items[index];
        if (newselectedOESwitcherItem === this.selectedOESwitcherItem) {
            return;
        }

        if (this.selectedOESwitcherItem) {
            this.selectedOESwitcherItem.active = false;
        }

        newselectedOESwitcherItem.active = true;
        this.selectedOESwitcherItem = newselectedOESwitcherItem;

        void this.set({ arrayOESwitcher: items, isLimit: newselectedOESwitcherItem.isLimit });
    }

    public dataSourceArrowClick (el, posX: number, posY: number): void {
        void this.set({ isActiveDataSource: true });
        openDataSourceScreen(posX, posY);

        const form = document.getElementById('fromDataSourceScreen');
        form.tabIndex = 1;
        form.focus();
        form.addEventListener('blur', () => {
            closeDataSourceScreen();
            void this.set({ isActiveDataSource: false });
        });
    }

    public NYSEArrowClick (el, posX: number, posY: number): void {
        void this.set({
            isActiveNYSE: true
        });

        const itemsMenu = this.createMenuItems();
        openContextMenu(posX, posY, itemsMenu);
        closeContextMenuEvent.Subscribe(this.closeMenu, this);
    }

    public closeMenu (): void {
        closeContextMenuEvent.UnSubscribe(this.closeMenu, this);
        void this.set({ isActiveNYSE: false });
    }

    public createMenuItems (): Array<{ text: string }> {
        const items = [
            {
                text: 'ASX'
            },
            {
                text: 'NASDAQ'
            },
            {
                text: 'NASDAQ OMX BX'
            },
            {
                text: 'NASDAQ OMX PHLX'
            },
            {
                text: 'IEX'
            }
        ];

        return items;
    }

    public placeOrder (): void {
        if (this.selectedOESwitcherItem.isLimit) {
            this.placeOrderLimitOrder();
        } else {
            this.placeOrderMarketOrder();
        }
    }

    public placeOrderMarketOrder (): void {
        const newOrderType = new MarketOrderType();

        const orderEdit = newOrderType.createOrderEditObject({
            dataCache: DataCache
        });

        orderEdit.account = this.get('accountItem');
        orderEdit.instrument = this.get('instrumentItem');
        orderEdit.placedFrom = PlacedFrom.WEB_OE;
        orderEdit.quantity = new Quantity(this.get('quantity'), this.get('inLots'));
        OETIFSelector.GetAdvancedComboBoxItems([newOrderType.id()], this);

        orderEdit.side = this.get('isBuy') ? OperationType.Buy : OperationType.Sell;

        const tifType = this.getTif();
        orderEdit.tif = new TIF(tifType, null);

        const reqData = orderEdit.getDataForRequest();
        void Connection.vendor.placeOrder(reqData);

        this.closeOrderEntry();
    }

    public setAccountItem (): void {
        const account = this.get('accountItem');
        if (!account) return;

        this.Account = account;
        this.AssetBalance = account.assetBalanceDefault;

        this.setAvailableCash();
    }

    public setAvailableCash (): void {
        const curAccount = this.Account;
        const AssetBalance = this.AssetBalance;

        const value = Account.GetAccountFeature(AccountFeature.AvailableFunds, curAccount, AssetBalance);
        const formattedValue = Account.GetAccountFeatureString(value, AccountFeature.AvailableFunds, curAccount, AssetBalance);

        void this.set('availableCash', formattedValue);
    }

    public onteardown (): void {
        Control.Ticker.UnSubscribe(this.debounceHandler, this);
    }

    public UpdateData (): void {
        if (this.Account && this.AssetBalance) {
            this.setAvailableCash();
        }
    }

    public placeOrderLimitOrder (): void {
        const newOrderType = new LimitOrderType();

        const orderEdit = newOrderType.createOrderEditObject({
            dataCache: DataCache
        });

        orderEdit.account = this.get('accountItem');
        orderEdit.instrument = this.get('instrumentItem');
        orderEdit.placedFrom = PlacedFrom.WEB_OE;
        orderEdit.quantity = new Quantity(this.get('quantity'), this.get('inLots'));

        orderEdit.limitPrice = this.get('limitPrice');// 0.67173;
        orderEdit.side = this.get('isBuy') ? OperationType.Buy : OperationType.Sell;

        OETIFSelector.GetAdvancedComboBoxItems([newOrderType.id()], this);

        const tifType = this.getTif();
        orderEdit.tif = new TIF(tifType, null);

        const reqData = orderEdit.getDataForRequest();
        void Connection.vendor.placeOrder(reqData);

        this.closeOrderEntry();
    }

    public UpdateNumericRefs (): void {
        const newOrderType = new LimitOrderType();

        const orderEdit = newOrderType.createOrderEditObject({
            dataCache: DataCache
        });

        orderEdit.account = this.get('accountItem');
        orderEdit.instrument = this.get('instrumentItem');

        if (!orderEdit.account || !orderEdit.instrument) {
            return;
        }

        const tradingDataDict: any = {};

        tradingDataDict.account = orderEdit.account;
        tradingDataDict.instrument = orderEdit.instrument;

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            tradingDataDict));

        const dp = orderEdit.toDynProperty_limitPrice();
        void this.set({ dpData: dp });
        this.setAccountItem();
    }

    public getTif (): OrderTif {
        const tifitems = this.get('items');

        const res = {};

        for (const tf of tifitems) {
            res[tf.value] = true;
        }

        if (res[OrderTif.GTC]) {
            return OrderTif.GTC;
        }
        if (res[OrderTif.Day]) {
            return OrderTif.Day;
        }
        if (res[OrderTif.IOC]) {
            return OrderTif.IOC;
        }
        if (res[OrderTif.FOK]) {
            return OrderTif.FOK;
        }
        if (res[OrderTif.GTS]) {
            return OrderTif.GTS;
        }

        return OrderTif.GTC;
    }
}

Ractive.extendWith(InvestingOE, {
    el: 'orderEntry',
    template: InvestingOETemplate,
    data: function () {
        return {
            showOrderEntry: false,
            offsetTop: null,
            arrayOESwitcher: [],
            isBuy: null,
            isAsset: false,
            accountItem: null,
            instrumentItem: null,
            quantity: null,
            inLots: false,
            limitPrice: 0,
            dpData: null
        };
    }
});
