// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectReportGroupResponse extends DirectMessageBaseClass {
    public Tables: ReportTable[];

    constructor () {
        super(Message.CODE_REPORT_GROUP_RESPONSE);

        this.Tables = [];
    }
}

export class ReportTable {
    ReportName: string;
    ReportColumns: ReportColumn[];
    ReportRows: string[][];
}

export class ReportColumn {
    Type: number;
    Name: string;
}
