// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type QuotesCallback, type ResolutionString } from '../charting_library';
import { TvCashItemManager } from '../Managers/TvCashItemManager';

export class TvHistorySubscriptionsContainer {
    private readonly waitingSubscriptionsMap = new Map<string, TvCashItemManager>();
    private readonly subscriptionsMap = new Map<string, TvCashItemManager>();

    public insertCashItem (interiorId, resolution, cashItem, timeOffset: number, timeZone: string) {
        const tvCashItem = new TvCashItemManager(cashItem, timeOffset, timeZone);
        const key = this.createKey(interiorId, resolution);
        this.waitingSubscriptionsMap.set(key, tvCashItem);
    }

    public subscribeBars (interiorId: string | undefined,
        resolution: ResolutionString,
        subscriberUID: string,
        onRealtimeCallback: QuotesCallback,
        onResetCacheNeededCallback: () => void) {
        const key = this.createKey(interiorId, resolution);
        const tvCashItem: TvCashItemManager | undefined = this.waitingSubscriptionsMap.get(key);
        if (!tvCashItem) {
            console.log(`[TvSubscriptionsContainer::subscribeBars]: CashItem not found for ${key}`);
            return;
        }

        this.waitingSubscriptionsMap.delete(key);
        tvCashItem.setCallBacks(onRealtimeCallback, onResetCacheNeededCallback);
        this.subscriptionsMap.set(subscriberUID, tvCashItem);
    }

    public unsubscribeBars (subscriberUID: string) {
        const tvCashItem = this.subscriptionsMap.get(subscriberUID);
        if (!tvCashItem) {
            console.log(`[TvSubscriptionsContainer::unsubscribeBars]: CashItem not found for ${subscriberUID}`);
            return;
        }

        this.subscriptionsMap.delete(subscriberUID);
        tvCashItem.dispose();
    }

    public resetCacheByNewAccount (): void {
        this.subscriptionsMap.forEach((tvCashItem) => { tvCashItem.callOnResetCacheNeededCallback(); });
    }

    private createKey (interiorId, resolution): string {
        return `${interiorId}_${resolution}`;
    }

    dispose () {
        this.waitingSubscriptionsMap.forEach((tvCashItem) => { tvCashItem.dispose(); });
        this.waitingSubscriptionsMap.clear();
        this.subscriptionsMap.forEach((tvCashItem) => { tvCashItem.dispose(); });
        this.subscriptionsMap.clear();
    }
}
