// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type BrokerConfigFlags, type OrderDurationMetaInfo } from '../charting_library';

class _TvConsoleHelper {
    private prevBrokerConfigFlags: BrokerConfigFlags | null = null;
    private prevAllowedDurations: OrderDurationMetaInfo[] | null = null;
    private debugMode: boolean = false;
    private needLogExecutions: boolean = false;

    public setBrokerConfigFlags (flags: BrokerConfigFlags): void {
        this.prevBrokerConfigFlags = flags;
    }

    public setAllowedDurations (durations: OrderDurationMetaInfo[]): void {
        this.prevAllowedDurations = durations;
    }

    public getBrokerConfigFlags (): void {
        console.warn(this.prevBrokerConfigFlags);
    }

    public getAllowedDurations (): void {
        console.warn(this.prevAllowedDurations);
    }

    public changeDebugMode (): void {
        this.debugMode = !this.debugMode;
        console.warn('Debug mode is ', this.debugMode ? 'enabled' : 'disabled');
        console.warn('Try to relogin to apply changes');
    }

    public getDebugMode (): boolean {
        return this.debugMode;
    }

    public changelogExcections (): void {
        this.needLogExecutions = !this.needLogExecutions;
        console.warn('Excecution logging is ', this.needLogExecutions ? 'enabled' : 'disabled');
    }

    public isExececutionLoggingEnabled (): boolean {
        return this.needLogExecutions;
    }
}

export const tvch = new _TvConsoleHelper();
