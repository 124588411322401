// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { UserWebStorageInstance } from '../user-web-storage';
import { ConntionInfo } from './Connection';
import { CustomErrorClass, ErrorInformationStorage } from './ErrorInformationStorage';
import { LocalStorage } from './LocalStorage';
import { ChangeValidityToTIFDict, Localization, Resources } from '@shared/localizations/Resources';
import { ResourcesManager } from './properties/ResourcesManager';

// Maybe rename this file to LanguageResourceMerger ? (and AgainConnectionsGetter...)

let beforeMergeLocaleKeys = null; // caching old keys values for 2 or more connections

// TODO. Refactor. Rename vars.
function mergeLocales (localeDict, localeOverridenFromBO): void {
    if (!localeDict) {
        return;
    }

    roolbackPreviousMerge(); // in case we have more than two connections with different localization files

    const localization = Localization;
    for (const locale in localeDict) {
        const keyDestDict = localization[locale] || {};
        localization[locale] = keyDestDict;

        if (localeOverridenFromBO) {
            beforeMergeLocaleKeys[locale] = {};
        }

        const keys = localeDict[locale];
        for (const key in keys) {
            if (localeOverridenFromBO) {
                beforeMergeLocaleKeys[locale][key] = keyDestDict[key];
            }

            keyDestDict[key] = keys[key];
        }
    }
}

function roolbackPreviousMerge (): void {
    for (const locale in beforeMergeLocaleKeys) {
        const byLang = beforeMergeLocaleKeys[locale];
        for (const key in byLang) {
            const oldValue = byLang[key];
            if (oldValue === undefined) {
                delete Localization[locale][key];
            } else {
                Localization[locale][key] = oldValue;
            }
        }
    }

    beforeMergeLocaleKeys = {};
}

function ChangeValidityTiTif (): void {
    const dict = ChangeValidityToTIFDict;
    for (const keyL in dict) {
        const dictLang = dict[keyL];
        const keyDestDict = Localization[keyL] || {};
        for (const key in dictLang) {
            keyDestDict[key] = dictLang[key];
        }
    }
}

export async function ApplicationLoader (conName = null): Promise<void> {
    try {
        Resources.setLocalizationWithoutBranding();
        if (!isNullOrUndefined(conName)) {
            const locales = await UserWebStorageInstance.branding.locales(conName);
            mergeLocales(locales, conName !== null);
        }
    } catch (e) {
        const ex = new CustomErrorClass('catch1 error', 'Application.InitializeWebtrader', 'InitializeWebtrader -> UserWebStorage.branding');
        ErrorInformationStorage.GetException(ex);
    }

    try {
        if (!Resources.isHidden('ChangeValidityToTIF')) {
            ChangeValidityTiTif();
        }
        Resources.setNeedForceUpdateLocale();
        ResourcesManager.InitStartLanguage();
    } catch (e) {
        ErrorInformationStorage.GetException(
            new CustomErrorClass('Locale initialization error', 'ApplicationLoader', 'updateChangeValidityToTIF')
        );
    }

    try {
        const connections = await UserWebStorageInstance.connections.get();
        if (JSON.stringify(ConntionInfo.ConnectionInfoArray) !== JSON.stringify(connections.clientConnectionJsonArr)) {
            ConntionInfo.ConnectionInfoArray = connections.clientConnectionJsonArr;
            ConntionInfo.OnConnectionInfoArrayChanged.Raise();
        }
    } catch (e) {
        const ex = new CustomErrorClass('catch2 error', 'Application.InitializeWebtrader', 'InitializeWebtrader -> UserWebStorage.branding');
        ErrorInformationStorage.GetException(ex);
        alert(Resources.getResource('panel.backoffice.You need to add connection!'));
    }

    try {
        ThemeManager.overrideThemeColors();
        await Promise.resolve();
    } catch (e) {
        const ex = new CustomErrorClass('catch3 error', 'Application.InitializeWebtrader', 'InitializeWebtrader -> UserWebStorage.branding');
        ErrorInformationStorage.GetException(ex);
        ThemeManager.setTheme(LocalStorage.getThemeName() || 'dark');
    }
}

export function InvestingCustomSchemeLoader (conName): any {
    return UserWebStorageInstance.branding.investingCustomScheme(conName);
}
