// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { Point, Rectangle } from '@shared/commons/Geometry';
import { Cursors } from '@shared/commons/Cursors';
import { LayersEnum } from '../TerceraChartBaseRenderer';
import { Brushes, Pen, PenStyle } from '@shared/commons/Graphics';
import { SelectionState } from '../../Tools/Selection';
import { TerceraChartTradingOperation } from '../../Utils/ChartConstants';
import { MouseButtons } from '@front/controls/UtilsClasses/ControlsUtils';
import { AlertToolView } from '../../Tools/TradingTools/AlertToolView';
import { NewTradeToolView } from '../../Tools/TradingTools/NewTradeToolView';
import { OrderToolView } from '../../Tools/TradingTools/OrderToolView';
import { PositionToolView } from '../../Tools/TradingTools/PositionToolView';
import { SLTPOrderToolView } from '../../Tools/TradingTools/SLTPOrderToolView';
import { StopLimitOrderToolView } from '../../Tools/TradingTools/StopLimitOrderToolView';
import { StopLossLimitToolView } from '../../Tools/TradingTools/StopLossLimitToolView';
import { TradingToolViewBase, TradingToolViewType } from '../../Tools/TradingTools/TradingToolViewBase';
import { TerceraChartToolRenderer } from './TerceraChartToolRenderer';
import { TerceraChartTradingToolsRenderer_DataCacheDataProvider } from './TerceraChartTradingToolsRenderer_DataCacheDataProvider';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { SlTpPriceType } from '@shared/utils/Enums/Constants';
import { TradeStatusEnum } from '@shared/commons/cache/TradeEnums';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { ArrayUtils } from '@shared/utils/ArrayUtils';
import { DynProperty, ColorStyleWidth } from '@shared/commons/DynProperty';
import { TradingLockUtils } from '@shared/utils/TradingLockUtils';
import { IsAllowed } from '@shared/commons/IsAllowed';
import { DataCache } from '@shared/commons/DataCache';
import { Instrument } from '@shared/commons/cache/Instrument';
import type TerceraChartNewToolRenderer from './TerceraChartNewToolRenderer';
import { type TerceraChartWindowBase } from '../../Windows/TerceraChartWindowBase';
import { BaseSettings } from '@shared/commons/Settings/BaseGeneralSettingsWrapper';
import { type TerceraChartMouseEventArgs } from '../../Utils/TerceraChartMouseEventArgs';
import { ChartZoomRendererButtons } from '../TerceraChartZoomRenderer';

export class TerceraChartTradingToolsRenderer extends TerceraChartToolRenderer {
    public terceraChartPanelContext: any;
    public UseAntialiasForDrawing = false;
    public NotTradingContext = false;
    public account: any = null;

    public ShowingOE = false; /* Allowed mouse trading */
    public IsOCOMode = false; /* Current order plasing type is OCO */

    /* #43546 - таблица для сохраниния состояния Collapsed для ордеров для того чтобы когда ордер становится позицией восстанавливать состояние */
    public orderCollappsedStateTable: any = {};

    public mouseTradeEnabled = false;
    public CtrlMouseTradeEnabled = false;
    /* для группировки трейдов на 1й свече, мы в рисование передаем 2 пустых списка, первый трейд на свече рисуется остальные нет */
    public BuyNewTradeToolsDrawingInfo: Record<number, NewTradeToolView> = {}; /* new Dictionary<int, List<NewTradeToolView>>(); */
    public SellNewTradeToolsDrawingInfo: Record<number, NewTradeToolView> = {}; /* new Dictionary<int, List<NewTradeToolView>>(); */

    public positionBuyPen: Pen = new Pen(ThemeManager.CurrentTheme.BuyColor, 1, PenStyle.SimpleChart);
    public positionSellPen: Pen = new Pen(ThemeManager.CurrentTheme.SellColor, 1, PenStyle.SimpleChart);

    public orderBuyPen: Pen = new Pen(ThemeManager.CurrentTheme.BuyColor, 1, PenStyle.ShapedChart);
    public orderSellPen: Pen = new Pen(ThemeManager.CurrentTheme.SellColor, 1, PenStyle.ShapedChart);

    public readonly alertRedPen: Pen = new Pen(ThemeManager.CurrentTheme.RedColor, 1, PenStyle.ShapedChart);
    public readonly alertYellowPen: Pen = new Pen(ThemeManager.CurrentTheme.YellowColor, 1, PenStyle.ShapedChart);
    public readonly alertGreenPen: Pen = new Pen(ThemeManager.CurrentTheme.GreenColor, 1, PenStyle.ShapedChart);

    public positionBuyPenStyle: PenStyle = PenStyle.SimpleChart;
    public positionSellPenStyle: PenStyle = PenStyle.SimpleChart;

    public orderBuyPenStyle: PenStyle = PenStyle.ShapedChart;
    public orderSellPenStyle: PenStyle = PenStyle.ShapedChart;

    public ShowOrders = true;
    public ShowPositions = true;
    public ShowVisualTradingOnLeftSide = true;
    public ShowEvents = true;
    public ShowAlerts = true;

    public dataProvider: any = null; /* Standard data provider based on DataCache */

    public activeSelectionTrade: any = null; /* Id трейда который нужно выделить */

    public firstOCO_processingInfo: any; /* на мауздаун для осо запоминаем инфо о 1м ордере, на мауз ап торгуем и сбрасываем в нулл. */
    public firstOrderPrice = NaN;
    public firstOrderY = -1;
    public CurrentMousePosition: any = new Point(-1, -1);

    public OrderScale = false;
    public AlertScale = false;

    constructor (terceraChartPanelContext: any, chart: any, instanceWindow: TerceraChartWindowBase, newToolRenderer: TerceraChartNewToolRenderer) {
        super(-1, chart, newToolRenderer);

        this.terceraChartPanelContext = terceraChartPanelContext;
        this.InstanceWindow = instanceWindow;

        this.dataProvider = new TerceraChartTradingToolsRenderer_DataCacheDataProvider(this);
        this.dataProvider.Resubscribe();

        this.SetClassName('TerceraChartTradingToolsRenderer');
    }

    get UseInAutoscale (): boolean { return this.OrderScale || this.AlertScale; }
    set UseInAutoscale (_value: boolean) { /* Empty setter as earlier */ }

    FindMinMax (out_minMax: any, window: any): boolean {
        const windowClientRect = window.ClientRectangle;
        const windowClientRectR = windowClientRect.X + windowClientRect.Width;
        let min = Number.MAX_VALUE;
        let max = -Number.MAX_VALUE;
        for (let i = 0; i < this.tools.length; i++) {
            const item = this.tools[i] as TradingToolViewBase;
            if (item !== null) {
                if (item.ToolViewType === TradingToolViewType.NewTradeToolView) {
                    if (!(item as NewTradeToolView).isActive) {
                        continue; /* если трейд не видим на чарте в автоскейл не учавствует */
                    }
                } else if (!this.ShowVisualTradingOnLeftSide) {
                    if (item.screenPoints[0][0] > windowClientRectR) {
                        continue; /* если ордер не видим на чарте в автоскейл не учавствует */
                    }
                }
                if (this.OrderScale && item.ToolViewType !== TradingToolViewType.AlertToolView) {
                    if (item.PriceMax > max) {
                        max = item.PriceMax;
                    }
                    if (item.PriceMin < min) {
                        min = item.PriceMin;
                    }
                }
                if (this.AlertScale && item.ToolViewType === TradingToolViewType.AlertToolView) {
                    if (item.Price > max) {
                        max = item.Price;
                    }
                    if (item.Price < min) {
                        min = item.Price;
                    }
                }
            }
        }
        out_minMax.tMin = min;
        out_minMax.tMax = max;
        return true;
    }

    /* Активный инструмент, по которому фильтруем тулзы */
    get Instrument (): any { return this.instrument; }
    set Instrument (value: any) {
        this.instrument = value;
        this.dataProvider.Instrument = value;
        this.RepopulateTools();
    }

    get Account (): any { return this.account; }
    set Account (value: any) {
        this.account = value;
        this.dataProvider.Account = value;
        this.RepopulateTools();
    }

    get MouseTradeEnabled (): boolean { return this.mouseTradeEnabled; }
    set MouseTradeEnabled (value: boolean) {
        this.mouseTradeEnabled = value;
        if (!this.mouseTradeEnabled) {
            this.ResetMouseTrading();
        }
    }

    /* Режим быстрой торговли */
    QuickOEMode (): boolean {
        if (!this.Visible) { return false; }

        const hoverTool = this.HoverToolGet();
        const isNotToolsMovingNow = !hoverTool?.CurrentMovement?.IsMovingRightNow; /* #43534 */
        const isHoveredTradingTool: boolean = !isNullOrUndefined(hoverTool) && hoverTool instanceof TradingToolViewBase;
        const isHoveredChartNavigation = this.chart.windowsContainer.zoomRenderer.CurrentHoverButtonType !== ChartZoomRendererButtons.None;

        const stopOrderType = BaseSettings.isUseStopLimitInsteadStop()
            ? OrderType.StopLimit
            : OrderType.Stop;

        return !this.NotTradingContext &&
            (this.MouseTradeEnabled || this.CtrlMouseTradeEnabled) &&
            (IsAllowed.IsTradingAllowed([this.account], this.instrument, OrderType.Limit).Allowed ||
            IsAllowed.IsTradingAllowed([this.account], this.instrument, stopOrderType).Allowed) &&
            !TradingLockUtils.TradingLock.tradingLocked &&
            isNotToolsMovingNow &&
            !isHoveredTradingTool &&
            !isHoveredChartNavigation &&
            this.chart.HasCorrectData();
    }

    get PositionBuyPenStyle (): any { return this.positionBuyPenStyle; }
    set PositionBuyPenStyle (value: any) {
        this.positionBuyPenStyle = value;
        this.positionBuyPen.DashStyle = this.positionBuyPenStyle;
    }

    get PositionSellPenStyle (): any { return this.positionSellPenStyle; }
    set PositionSellPenStyle (value: any) {
        this.positionSellPenStyle = value;
        this.positionSellPen.DashStyle = this.positionSellPenStyle;
    }

    get OrderBuyPenStyle (): any { return this.orderBuyPenStyle; }
    set OrderBuyPenStyle (value: any) {
        this.orderBuyPenStyle = value;
        this.orderBuyPen.DashStyle = this.orderBuyPenStyle;
    }

    get OrderSellPenStyle (): any { return this.orderSellPenStyle; }
    set OrderSellPenStyle (value: any) {
        this.orderSellPenStyle = value;
        this.orderSellPen.DashStyle = this.orderSellPenStyle;
    }

    get DataProvider (): any { return this.dataProvider; }
    set DataProvider (value: any) {
        if (this.dataProvider != null) { this.dataProvider.Dispose(); }

        this.dataProvider = value;

        if (value === null) { return; }

        this.dataProvider.Instrument = this.instrument;
        this.dataProvider.Account = this.account;
    }

    /* Id трейда который нужно выделить */
    get ActiveSelectionTrade (): any { return this.activeSelectionTrade; }
    set ActiveSelectionTrade (value: any) { this.activeSelectionTrade = value; }

    Subscribe (): void { /* Implementation for subscribing to events */ }
    Unsubscribe (): void { /* Implementation for unsubscribing from events */ }

    GetToolViewById (ID: string, type: TradingToolViewType): any {
        for (let i = 0; i < this.tools.length; i++) {
            const tool = this.tools[i] as TradingToolViewBase;
            if (tool !== null && tool.ID === ID && tool.ToolViewType === type) {
                return tool;
            }
        }
        return null;
    }

    /* Repopulate tools from cache */
    RepopulateTools (): void {
        if (this.dataProvider == null || this.dataProvider.IsReady() === false) { return; } // Not ready for populating yet

        this.orderCollappsedStateTable = {};
        this.RemoveAllTools();

        const orders = this.dataProvider.GetOrders(); // SL/TP NOT included
        const positions = this.dataProvider.GetPositions();
        const alerts = this.dataProvider.GetAlerts();
        const trades = this.dataProvider.GetTrades();

        this.tools = [];
        const newOrderToolsView = this.tools;

        for (let i = 0; i < orders.length; i++) {
            const order = orders[i];
            if (order != null) {
                if (order.OrderType === OrderType.Market && order.Price == 0) { continue; }

                if (order.OrderType === OrderType.StopLimit) {
                    newOrderToolsView.push(this.NewStopLimitOrderToolView(order));
                } else { newOrderToolsView.push(this.NewOrderToolView(order)); }

                if (!isNaN(order.StopLossPriceValue)) {
                    const slTool = this.NewSLTPOrderToolView(order, null, true);
                    newOrderToolsView.push(slTool);

                    if (order.StopLossLimitPriceValue != null) {
                        slTool.sllTool = this.NewStopLossLimitToolView(order, null);
                        newOrderToolsView.push(slTool.sllTool);
                    }
                }

                if (!isNaN(order.TakeProfitPriceValue)) { newOrderToolsView.push(this.NewSLTPOrderToolView(order, null, false)); }
            }
        }

        for (let i = 0; i < positions.length; i++) {
            const pos = positions[i];
            const isTrade = pos.SuperPositionId != '-1' && !pos.isSuperPosition;
            if (pos != null && !isTrade) {
                newOrderToolsView.push(this.NewPositionToolView(pos));

                if (pos.SLOrder != null) {
                    const slTool = this.NewSLTPOrderToolView(pos, pos.SLOrder);
                    newOrderToolsView.push(slTool);

                    if (pos.SLOrder.StopLimit && pos.SLOrder.OrderType != OrderType.TrailingStop) {
                        slTool.sllTool = this.NewStopLossLimitToolView(pos, pos.SLOrder);
                        newOrderToolsView.push(slTool.sllTool);
                    }
                }

                if (pos.TPOrder != null) newOrderToolsView.push(this.NewSLTPOrderToolView(pos, pos.TPOrder));
            }
        }

        for (let i = 0; i < trades.length; i++) {
            const trade = trades[i];
            newOrderToolsView.push(new NewTradeToolView(trade, this.BuyNewTradeToolsDrawingInfo, this.SellNewTradeToolsDrawingInfo, this));
        }

        for (let i = 0; i < alerts.length; i++) {
            const alert = alerts[i];
            if (alert != null) newOrderToolsView.push(this.NewAlertToolView(alert));
        }

        // Replace data
        this.tools = newOrderToolsView;
    }

    // Remove all tools
    RemoveAllTools (): void {
        for (let i = 0; i < this.tools.length; i++) {
            const tool = this.tools[i];
            if (tool != null) tool.Dispose();
        }
        this.tools.splice(0, this.tools.length);
    }

    NewOrderToolView (order: any): any {
        return new OrderToolView(order, this.GetToolViewById.bind(this), this.terceraChartPanelContext, this);
    }

    NewPositionToolView (position: any): any {
        return new PositionToolView(position, this.GetToolViewById.bind(this), this.terceraChartPanelContext, this);
    }

    NewSLTPOrderToolView (order: any, sltpOrder: any, isSL = false): any {
        return new SLTPOrderToolView(order, sltpOrder, this.GetToolViewById.bind(this), this.terceraChartPanelContext, this, isSL);
    }

    NewStopLossLimitToolView (order: any, sltpOrder: any): any {
        return new StopLossLimitToolView(order, sltpOrder, this.GetToolViewById.bind(this), this.terceraChartPanelContext, this);
    }

    NewStopLimitOrderToolView (order: any): any {
        return new StopLimitOrderToolView(order, this.GetToolViewById.bind(this), this.terceraChartPanelContext, this);
    }

    NewAlertToolView (alert: any): any {
        return new AlertToolView(alert, this.GetToolViewById.bind(this), this.terceraChartPanelContext, this);
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any = null): void {
        const param = advParams;
        const cashItemSeries = param.mainPriceRenderer.Series;
        if (cashItemSeries == null) {
            return; // draw only for absolute data
        }
        this.BuyNewTradeToolsDrawingInfo = {};
        this.SellNewTradeToolsDrawingInfo = {};

        //
        // base Draw all tools !!!!
        //
        super.Draw(gr, window, windowsContainer, advParams);

        //
        // draw trade connecting lines
        //
        const clientRect = window.ClientRectangle;

        gr.save();
        gr.beginPath();
        gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip();

        // If trades are visible on chart (+++ http://tp.pfsoft.net/entity/28000)
        if (this.ShowEvents) {
            //
            // Group trades by posID
            //
            const groupTrades = {};

            for (let i = 0; i < this.tools.length; i++) {
                const tradeTool = this.tools[i] as NewTradeToolView;
                // Check visibility
                if (!tradeTool?.isActive) { continue; }

                const newTrade = tradeTool.newTrade;

                let curList = groupTrades[newTrade.PositionID];
                if (!curList) {
                    curList = [];
                    groupTrades[newTrade.PositionID] = curList;
                }

                const info = new GroupTradeInfo();
                info.screenX = tradeTool.triangleX;
                info.screenY = tradeTool.triangleY;
                info.posID = newTrade.PositionID;
                info.TradeSide = newTrade.BuySell;
                info.TradeStatus = newTrade.BuySell;
                info.TradeTime = newTrade.Time;
                info.Selected = tradeTool.CurrentSelection.CurrentState === SelectionState.Selected;
                info.tradeTool = tradeTool;

                curList.push(info);
            }

            //
            // Draw lines
            //
            const keys = Object.keys(groupTrades);
            for (let j = 0; j < keys.length; j++) {
                const key = keys[j];
                const tradesPoint = groupTrades[key];

                if (tradesPoint.length > 1) {
                    // sort by time
                    tradesPoint.sort(GroupTradeInfoComparerInstance.Compare);

                    let prevX = tradesPoint[0].screenX;
                    let prevY = tradesPoint[0].screenY;
                    let prevTradeSide = tradesPoint[0].TradeSide;
                    const pathPointsArr: any[] = [];
                    let curPen = null;
                    let needSelect = tradesPoint[0].Selected;

                    for (let i = 1; i < tradesPoint.length; i++) {
                        curPen = prevTradeSide == OperationType.Buy ? this.orderBuyPen : this.orderSellPen;

                        if (tradesPoint[i].TradeStatus == TradeStatusEnum.Open) {
                            needSelect = false;
                            if (tradesPoint[i].Selected) {
                                needSelect = true;
                            }
                        } else {
                            pathPointsArr.push(new Point(prevX, prevY));
                            pathPointsArr.push(new Point(tradesPoint[i].screenX, tradesPoint[i].screenY));
                            if (tradesPoint[i].Selected) {
                                needSelect = true;
                            }
                        }

                        prevX = tradesPoint[i].screenX;
                        prevY = tradesPoint[i].screenY;
                        prevTradeSide = tradesPoint[i].TradeSide;
                    }
                    gr.DrawLines(curPen, pathPointsArr);
                }
            }
        }

        gr.restore();

        if (this.QuickOEMode() && window.ClientRectangle.Contains(this.CurrentMousePosition.X, this.CurrentMousePosition.Y) && param.TerceraChart.MouseIn) // mouse leave - not draw
        {
            let leftText: string | null = null;
            let rightText: string | null = null;

            let leftPen = null;
            let leftBrush = null;

            let rightPen = null;
            let rightBrush = null;

            let r: Rectangle;

            let lastMousePrice = 0;

            lastMousePrice = this.Instrument.roundPrice(window.PointsConverter.GetDataY(this.CurrentMousePosition.Y));

            let mousePosition = OCOMouseClickPosition.Below;
            const sp = DataCache.GetSpreadPlan(this.Account);
            if (this.Instrument.LastQuote !== null) {
                if (lastMousePrice >= this.Instrument.LastQuote.AskSpread_SP_Ins(sp, this.Instrument)) {
                    mousePosition = OCOMouseClickPosition.Above;
                } else if (lastMousePrice < this.Instrument.LastQuote.AskSpread_SP_Ins(sp, this.Instrument) && lastMousePrice > this.Instrument.LastQuote.BidSpread_SP_Ins(sp, this.Instrument)) {
                    mousePosition = OCOMouseClickPosition.Spread;
                }
            }

            //
            // Draw info about first click (for OCO case)
            //
            if (this.firstOCO_processingInfo != null) {
                const wRect = window.Rectangle;
                const wRectX = wRect.X;
                const wRectR = wRectX + wRect.Width;

                // Draw price line
                const priceY = this.firstOrderY;

                let p = this.orderBuyPen;
                if (this.firstOCO_processingInfo.BuySell !== OperationType.Buy) { p = this.orderSellPen; }
                gr.DrawLine(p, wRectX, priceY, wRectR, priceY);

                const textWW = Math.floor(Math.max(gr.GetTextWidth(leftText, TradingToolViewBase.tradeFont) + 10, 50));
                r = new Rectangle(wRectR - textWW - 3, priceY - 18, textWW, 16);

                gr.RenderButton(
                    this.firstOCO_processingInfo.BuySell === OperationType.Buy ? TradingToolViewBase.buyBackgroundBrush : TradingToolViewBase.sellBackgroundBrush,
                    this.firstOCO_processingInfo.BuySell === OperationType.Buy ? TradingToolViewBase.buyBackgroundPen : TradingToolViewBase.sellBackgroundPen,
                    r);

                gr.DrawString(this.instrument.formatPrice(this.firstOrderPrice), TradingToolViewBase.tradeFont, Brushes.White,
                    r.X + r.Width / 2, r.Y + r.Height / 2, 'center', 'middle');
            }
            //
            const LeftProcessingInfo = OCOProcessingResult.ProcessMouseClick(this.instrument, mousePosition, MouseButtons.Left, this.firstOCO_processingInfo);
            const rightProcessingInfo = OCOProcessingResult.ProcessMouseClick(this.instrument, mousePosition, MouseButtons.Right, this.firstOCO_processingInfo);

            const stopLabel = BaseSettings.isUseStopLimitInsteadStop() ? 'stop limit' : 'stop';
            //
            // Set correct images and text
            //
            if (LeftProcessingInfo != null) {
                if (LeftProcessingInfo.Allowed) {
                    leftBrush = (LeftProcessingInfo.BuySell == OperationType.Buy ? TradingToolViewBase.buyBackgroundActiveBrush : TradingToolViewBase.sellBackgroundActiveBrush);
                    leftPen = (LeftProcessingInfo.BuySell == OperationType.Buy ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.sellBackgroundActivePen);
                    leftText = Resources.getResource('chart.quickTrading.' + (LeftProcessingInfo.BuySell == OperationType.Buy ? 'Buy' : 'Sell') + ' ' + (LeftProcessingInfo.OrderType == OrderType.Limit ? 'limit' : stopLabel));
                } else {
                    leftBrush = TradingToolViewBase.grayBackgroundBrush;
                    leftPen = TradingToolViewBase.grayBackgroundPen;
                    leftText = Resources.getResource('chart.quickTrading.' + (LeftProcessingInfo.BuySell == OperationType.Buy ? 'Buy' : 'Sell') + ' ' + (LeftProcessingInfo.OrderType == OrderType.Limit ? 'limit' : stopLabel)) + ': ' + Resources.getResource('chart.quickTrading.Invalid price');
                }
            }
            if (rightProcessingInfo != null) {
                if (rightProcessingInfo.Allowed) {
                    rightBrush = (rightProcessingInfo.BuySell == OperationType.Buy ? TradingToolViewBase.buyBackgroundActiveBrush : TradingToolViewBase.sellBackgroundActiveBrush);
                    rightPen = (rightProcessingInfo.BuySell == OperationType.Buy ? TradingToolViewBase.buyBackgroundActivePen : TradingToolViewBase.sellBackgroundActivePen);
                    rightText = Resources.getResource('chart.quickTrading.' + (rightProcessingInfo.BuySell == OperationType.Buy ? 'Buy' : 'Sell') + ' ' + (rightProcessingInfo.OrderType == OrderType.Limit ? 'limit' : stopLabel));
                } else {
                    rightBrush = TradingToolViewBase.grayBackgroundBrush;
                    rightPen = TradingToolViewBase.grayBackgroundPen;
                    rightText = Resources.getResource('chart.quickTrading.' + (rightProcessingInfo.BuySell == OperationType.Buy ? 'Buy' : 'Sell') + ' ' + (rightProcessingInfo.OrderType == OrderType.Limit ? 'limit' : stopLabel)) + ': ' + Resources.getResource('chart.quickTrading.Invalid price');
                }
            }

            if (leftBrush && leftPen) {
                const textW = Math.floor(Math.max(gr.GetTextWidth(leftText, TradingToolViewBase.tradeFont) + 10, 50));
                r = new Rectangle(this.CurrentMousePosition.X - textW - 10, this.CurrentMousePosition.Y - 24, textW, 16);
                gr.RenderButton(leftBrush, leftPen, r);

                gr.DrawString(leftText, TradingToolViewBase.tradeFont, Brushes.White,
                    r.X + r.Width / 2, r.Y + r.Height / 2, 'center', 'middle');
            }

            if (rightBrush && rightPen) {
                const textW = Math.floor(Math.max(gr.GetTextWidth(rightText, TradingToolViewBase.tradeFont) + 10, 50));
                r = new Rectangle(this.CurrentMousePosition.X + 10, this.CurrentMousePosition.Y - 24, textW, 16);
                gr.RenderButton(rightBrush, rightPen, r);

                gr.DrawString(rightText, TradingToolViewBase.tradeFont, Brushes.White,
                    r.X + r.Width / 2, r.Y + r.Height / 2, 'center', 'middle');
            }

            //
            // OCO
            //
            if (this.IsOCOMode) {
                /* можно ставить ОСО */
                // let QuickOCO_CombinationsCorrected = (LeftProcessingInfo != null && LeftProcessingInfo.Allowed) || (rightProcessingInfo != null && rightProcessingInfo.Allowed);

                rightText = null;
                if (this.firstOCO_processingInfo != null) { rightText = '2/2'; } else { rightText = '1/2'; }

                if (rightText != '') {
                    r = new Rectangle(this.CurrentMousePosition.X + 10, this.CurrentMousePosition.Y + 12, 50, 16);
                    gr.RenderButton(TradingToolViewBase.grayBackgroundBrush, TradingToolViewBase.grayBackgroundPen, r);

                    gr.DrawString(rightText, TradingToolViewBase.tradeFont, Brushes.White,
                        r.X + r.Width / 2, r.Y + r.Height / 2, 'center', 'middle');
                }
            }
        }
    }

    public GetContextMenu (e: any, chart: any): any {
        const HoverTool: any = this.hoverTool;

        if (this.NotTradingContext || TradingLockUtils.TradingLock.tradingLocked ||
            !(HoverTool != null && HoverTool.isTradingTool == null)) {
            return null;
        }

        if (
            (!this.ShowPositions && HoverTool?.isOrderToolView === false) ||
            (!this.ShowOrders && HoverTool?.isOrderToolView === true) ||
            (!this.ShowEvents && HoverTool.ToolViewType === TradingToolViewType.NewTradeToolView)
        ) {
            return super.GetContextMenu(e, chart);
        }

        const movingTools = this.movingTools;
        const len = movingTools.length;
        if (len > 0) {
            for (let i = 0; i < len; i++) {
                const tool = movingTools[i];
                if (tool != null) tool.Updated();
            }
        }

        const menuItems = [];
        return chart.TerceraChartActionProcessor.CreateMenu(menuItems);
    }

    public Dispose (): void {
        this.terceraChartPanelContext = null;

        if (this.DataProvider != null) {
            this.DataProvider.Dispose();
            this.DataProvider = null;
        }

        super.Dispose();
    }

    public GetCursor (e: any): any {
        if (
            e.window?.ClientRectangle.Contains(this.CurrentMousePosition.X, this.CurrentMousePosition.Y) === true &&
            e.window.IsMainWindow === true
        ) {
            const hoverTool = this.HoverToolGet<TradingToolViewBase>();
            if (this.QuickOEMode()) {
                return Cursors.buySellCursor;
            } else if (!isNullOrUndefined(hoverTool)) {
                return hoverTool.GetCursor(e);
            }
        }
        return null;
    }

    public GetTooltip (e: any): any {
        if (
            e.window?.ClientRectangle.Contains(this.CurrentMousePosition.X, this.CurrentMousePosition.Y) &&
            e.window.IsMainWindow
        ) {
            const hoverTool = this.HoverToolGet<TradingToolViewBase>();
            if (!isNullOrUndefined(hoverTool)) {
                return hoverTool.GetTooltip(e);
            }
        }
        return null;
    }

    ProcessSelectionList (x: number, y: number): void {
    // ignore
    }

    ProcessMouseDown (e: any): boolean {
        try {
            // mouse trading
            if (this.QuickOEMode() && e.window?.ClientRectangle.Contains(this.CurrentMousePosition.X, this.CurrentMousePosition.Y) && e.window.IsMainWindow && (e.Button === MouseButtons.Left || e.Button === MouseButtons.Right)) {
                e.NeedRedraw = LayersEnum.Tools;
                return true;
            }
            // mouse down send to tools
            else {
                if (e.Button == MouseButtons.Left || e.Button == MouseButtons.Right) {
                    // #39123.
                    this.ProcessHoverTool(e, e.Location.X, e.Location.Y);
                    return super.ProcessMouseDown(e);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
        return false;
    }

    ProcessMouseMove (e: TerceraChartMouseEventArgs): boolean {
        try {
            super.ProcessMouseMove(e);
            // обновим текущий ховер
            let i;
            const len = this.tools.length;
            for (i = 0; i < len; i++) { this.tools[i].OnMouseMove(e); }

            this.CurrentMousePosition = e.Location;
            if (this.QuickOEMode() && e.window?.ClientRectangle.Contains(this.CurrentMousePosition.X, this.CurrentMousePosition.Y) && e.window.IsMainWindow) {
                e.NeedRedraw = [LayersEnum.Tools, LayersEnum.CrossHair];
                return true;
            } else {
                return false;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }

        return false;
    }

    override ProcessMouseUp (e: TerceraChartMouseEventArgs): boolean {
    // #38265 обновим текущий ховер
        const hoverTool = this.HoverToolGet();
        if (!isNullOrUndefined(hoverTool) && !hoverTool.CurrentMovement.IsMovingRightNow) {
            this.ProcessHoverTool(e, e.Location.X, e.Location.Y);
        }

        try {
            // #region mouse trading
            if (this.QuickOEMode() && e.window?.ClientRectangle.Contains(this.CurrentMousePosition.X, this.CurrentMousePosition.Y) && e.window.IsMainWindow) {
                const cashItemSeries = this.chart.mainPriceRenderer.Series;
                if (cashItemSeries == null) {
                    return false;
                }
                // mouse trading
                let placeOrderParams: any = null;

                // current price
                let lastMousePrice = e.window.PointsConverter.GetDataY(e.Y);
                // TODO only TerceraChartCashItemSeriesDataType.Absolute NOW!!!
                // const dataType = this.chart.cashItemSeriesSettings != null ? this.chart.cashItemSeriesSettings.DataType : TerceraChartCashItemSeriesDataType.Absolute;
                // switch (dataType)
                // {
                //     case TerceraChartCashItemSeriesDataType.Relative:
                //         lastMousePrice = this.chart.cashItemSeriesSettings.relativeDataConverter.Revert(lastMousePrice);
                //         break;
                //     case TerceraChartCashItemSeriesDataType.Log:
                //         lastMousePrice = this.chart.cashItemSeriesSettings.logDataConverter.Revert(lastMousePrice);
                //         break;
                // }
                lastMousePrice = this.Instrument.roundPrice(lastMousePrice);

                let mousePosition = OCOMouseClickPosition.Below;
                const sp = DataCache.GetSpreadPlan(this.Account);
                if (this.Instrument.LastQuote !== null) {
                    if (lastMousePrice >= this.Instrument.LastQuote.AskSpread_SP_Ins(sp, this.Instrument)) {
                        mousePosition = OCOMouseClickPosition.Above;
                    } else if (lastMousePrice < this.Instrument.LastQuote.AskSpread_SP_Ins(sp, this.Instrument) && lastMousePrice > this.Instrument.LastQuote.BidSpread_SP_Ins(sp, this.Instrument)) {
                        mousePosition = OCOMouseClickPosition.Spread;
                    }
                }

                // OCO - remember first order
                if (this.IsOCOMode && this.firstOCO_processingInfo == null) {
                    // cache first order side, type
                    this.firstOCO_processingInfo = OCOProcessingResult.ProcessMouseClick(this.instrument, mousePosition, e.Button, null);

                    // cache first order price
                    this.firstOrderPrice = lastMousePrice;
                    this.firstOrderY = e.Y;
                }
                // second OCO
                else if (this.IsOCOMode && this.firstOCO_processingInfo != null) {
                    placeOrderParams = {};
                    placeOrderParams.lOrdType = OrderType.OCO;
                    let ordType =
                    /* this.firstOCO_processingInfo.OrderType === OrderType.Stop && Utils.UseStopLimitInsteadStop && IsAllowed.IsTradingAllowed(new List<Account>() { Account }, Instrument, OrderType.Stop_LIMIT).Allowed */
                    /* ? OrderType.Limit
                    : */ this.firstOCO_processingInfo.OrderType;
                    placeOrderParams.OCO_firstOrder_OrderType = ordType;
                    placeOrderParams.lBuySell = this.firstOCO_processingInfo.BuySell;

                    placeOrderParams.dPrice = this.firstOrderPrice;
                    placeOrderParams.dStopPrice = lastMousePrice;

                    const processingInfo2 = OCOProcessingResult.ProcessMouseClick(this.instrument, mousePosition, e.Button, this.firstOCO_processingInfo);
                    if (!processingInfo2?.Allowed) {
                        this.ResetMouseTrading();
                        return true;
                    }

                    placeOrderParams.OCO_secondOrder_Operation = processingInfo2.BuySell;
                    ordType =
                        /* processingInfo2.OrderType == OrderType.Stop && Utils.UseStopLimitInsteadStop && IsAllowed.IsTradingAllowed(new List<Account>() { Account }, Instrument, OrderType.Stop_LIMIT).Allowed */
                        /* ? OrderType.Limit
                    : */ processingInfo2.OrderType;
                    placeOrderParams.OCO_secondOrder_OrderType = ordType;
                }
                // Single order
                else if (!this.IsOCOMode) {
                    placeOrderParams = {};
                    //
                    const processingInfo = OCOProcessingResult.ProcessMouseClick(this.instrument, mousePosition, e.Button, null);

                    if (processingInfo == null) {
                        this.ResetMouseTrading();
                        return false;
                    }

                    placeOrderParams.lOrdType = processingInfo.OrderType;
                    placeOrderParams.lBuySell = processingInfo.BuySell;

                    if (processingInfo.OrderType == OrderType.StopLimit || processingInfo.OrderType == OrderType.Stop) {
                        placeOrderParams.dStopPrice = lastMousePrice;
                    } else {
                        placeOrderParams.dPrice = lastMousePrice;
                    }
                }
                //
                // need send order
                //
                if (placeOrderParams != null) {
                    this.ResetMouseTrading();
                    this.terceraChartPanelContext.ChartVisualTrading(null, { action: TerceraChartTradingOperation.PlaceNewOrder, placeOrderParams });
                }
                e.NeedRedraw = LayersEnum.Tools;
                return true;
            }
            // #endregion
            // mouse down send to tools
            else {
                if (e.Button == MouseButtons.Left) {
                    return super.ProcessMouseUp(e);
                }
                if (e.Button == MouseButtons.Right) {
                    this.ProcessMouseDown(e);
                }
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
            this.ResetMouseTrading();
        }

        return false;
    }

    ProcessMouseLeave (e: any): void {
        this.CurrentMousePosition = new Point(-1, -1);
        this.ResetMouseTrading();
    }

    ResetMouseTrading (): void {
        this.firstOrderPrice = this.firstOrderY = NaN;
        this.firstOCO_processingInfo = null;
    }

    Properties (): any[] {
        const ShowPositions = this.ShowPositions;

        const properties: any[] = [];
        // Open positions
        let SeparatorGroup = '#0#' + Resources.getResource('property.SeparatorGroup.OpenPositions');
        // buy
        let prop: any = new DynProperty('showPositions', ShowPositions, DynProperty.BOOLEAN, DynProperty.VISUAL_TRADING_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.assignedProperty = ['PositionBuyStyleWidth', 'PositionSellStyleWidth'];
        prop.sortIndex = 0;
        properties.push(prop);

        const positionBuyPen = this.positionBuyPen;
        let PositionStyleWidth = new ColorStyleWidth(positionBuyPen.Color, this.PositionBuyPenStyle, positionBuyPen.Width);
        // PositionStyleWidth.DisableColor = true;

        prop = new DynProperty('PositionBuyStyleWidth', PositionStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_TRADING_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = ShowPositions;
        prop.sortIndex = 1;
        properties.push(prop);

        // sell
        const positionSellPen = this.positionSellPen;
        PositionStyleWidth = new ColorStyleWidth(positionSellPen.Color, this.PositionSellPenStyle, positionSellPen.Width);
        // PositionStyleWidth.DisableColor = true;

        prop = new DynProperty('PositionSellStyleWidth', PositionStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_TRADING_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = ShowPositions;
        prop.sortIndex = 2;
        properties.push(prop);

        // Active Orders
        SeparatorGroup = '#1#' + Resources.getResource('property.SeparatorGroup.ActiveOrders');

        const ShowOrders = this.ShowOrders;

        prop = new DynProperty('showOrders', ShowOrders, DynProperty.BOOLEAN, DynProperty.VISUAL_TRADING_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.assignedProperty = ['OrderBuyStyleWidth', 'OrderSellStyleWidth'];
        prop.sortIndex = 3;
        properties.push(prop);

        // buy
        const orderBuyPen = this.orderBuyPen;
        let orderStyleWidth = new ColorStyleWidth(orderBuyPen.Color, this.OrderBuyPenStyle, orderBuyPen.Width);
        // orderStyleWidth.DisableColor = true;

        prop = new DynProperty('OrderBuyStyleWidth', orderStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_TRADING_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = ShowOrders;
        prop.sortIndex = 4;
        properties.push(prop);

        // sell
        const orderSellPen = this.orderSellPen;
        orderStyleWidth = new ColorStyleWidth(orderSellPen.Color, this.OrderSellPenStyle, orderSellPen.Width);
        // orderStyleWidth.DisableColor = true;

        prop = new DynProperty('OrderSellStyleWidth', orderStyleWidth, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_TRADING_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.enabled = ShowOrders;
        prop.sortIndex = 5;
        properties.push(prop);

        // Executed Orders
        SeparatorGroup = '#2#' + Resources.getResource('property.SeparatorGroup.ExecutedOrders');

        prop = new DynProperty('showEvents', this.ShowEvents, DynProperty.BOOLEAN, DynProperty.VISUAL_TRADING_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 10;
        properties.push(prop);

        // Additional
        SeparatorGroup = '#3#' + Resources.getResource('property.SeparatorGroup.Additional');

        prop = new DynProperty('ShowVisualTradingOnLeftSide', this.ShowVisualTradingOnLeftSide, DynProperty.BOOLEAN, DynProperty.VISUAL_TRADING_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 20;
        properties.push(prop);

        const Visible = !Resources.isHidden('screen.Alerts.visibility') && DataCache.isAllowedForMyUser(RulesSet.FUNCTION_ALERTS);
        prop = new DynProperty('ShowAlerts', this.ShowAlerts, DynProperty.BOOLEAN, Visible ? DynProperty.ALERTS_GROUP : DynProperty.HIDDEN_GROUP);
        // prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 40;
        properties.push(prop);

        return properties;
    }

    callBack (properties: any[]): void {
        let dp = DynProperty.getPropertyByName(properties, 'showEvents');
        if (dp) this.ShowEvents = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'showOrders');
        if (dp) this.ShowOrders = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'ShowAlerts');
        if (dp) this.ShowAlerts = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'ShowVisualTradingOnLeftSide');
        if (dp) this.ShowVisualTradingOnLeftSide = dp.value;

        dp = DynProperty.getPropertyByName(properties, 'showPositions');
        if (dp) this.ShowPositions = dp.value;

        // style and width of Positions lines
        let styleWidth: any = null;
        // sell
        dp = DynProperty.getPropertyByName(properties, 'PositionSellStyleWidth');
        if (dp) {
            styleWidth = dp.value;
            if (styleWidth) {
                this.PositionSellPenStyle = styleWidth.Style;
                const positionSellPen = this.positionSellPen;
                positionSellPen.Width = styleWidth.Width;
                positionSellPen.Color = styleWidth.Color;
            }
        }
        // buy
        dp = DynProperty.getPropertyByName(properties, 'PositionBuyStyleWidth');
        if (dp) {
            styleWidth = dp.value;
            if (styleWidth) {
                this.PositionBuyPenStyle = styleWidth.Style;
                const positionBuyPen = this.positionBuyPen;
                positionBuyPen.Width = styleWidth.Width;
                positionBuyPen.Color = styleWidth.Color;
            }
        }

        // style and width of orders lines
        // sell
        dp = DynProperty.getPropertyByName(properties, 'OrderSellStyleWidth');
        if (dp) {
            styleWidth = dp.value;
            if (styleWidth) {
                this.OrderSellPenStyle = styleWidth.Style;
                const orderSellPen = this.orderSellPen;
                orderSellPen.Width = styleWidth.Width;
                orderSellPen.Color = styleWidth.Color;
            }
        }
        // buy
        dp = DynProperty.getPropertyByName(properties, 'OrderBuyStyleWidth');
        if (dp) {
            styleWidth = dp.value;
            if (styleWidth) {
                this.OrderBuyPenStyle = styleWidth.Style;
                const orderBuyPen = this.orderBuyPen;
                orderBuyPen.Width = styleWidth.Width;
                orderBuyPen.Color = styleWidth.Color;
            }
        }
    }

    RemoveSLOrder (order: any): void {
        if (!order || order.SLOrder === null) { return; }
        const toRemove = this.GetToolViewById(order.SLOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView);
        if (toRemove != null) {
            const sll = toRemove.sllTool;
            if (sll != null) {
                ArrayUtils.RemoveElementFromArray(this.tools, sll);
                sll.Dispose();
            }
            ArrayUtils.RemoveElementFromArray(this.tools, toRemove);
            toRemove.Dispose();
        }
    }

    RemoveTPOrder (order: any): void {
        if (!order || order.TPOrder === null) { return; }
        const toRemove = this.GetToolViewById(order.TPOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView);
        if (toRemove != null) {
            ArrayUtils.RemoveElementFromArray(this.tools, toRemove);
            toRemove.Dispose();
        }
    }

    AddSLOrder (order: any): void {
        const tool = this.GetToolViewById(order.SLOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView);
        if (tool == null) {
            const slTool = this.NewSLTPOrderToolView(order, order.SLOrder);
            this.tools.push(slTool);

            if (order.SLOrder.OrderType === OrderType.StopLimit) {
                slTool.sllTool = this.NewStopLossLimitToolView(order, order.SLOrder);
                this.tools.push(slTool.sllTool);
            }
        } else {
            tool.parentOrder = order;
            tool.Updated();
        }
    }

    AddTPOrder (order: any): void {
        const tool = this.GetToolViewById(order.TPOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView);
        if (tool == null) { this.tools.push(this.NewSLTPOrderToolView(order, order.TPOrder)); } else {
            tool.parentOrder = order;
            tool.Updated();
        }
    }

    RemoveOrderPosition (order: any): void {
        const isPosition = !!order.isPosition;
        const ID = isPosition ? order.PositionId : order.OrderNumber;
        const type = isPosition ? TradingToolViewType.PositionToolView : TradingToolViewType.OrderToolView;

        const toRemove: any[] = [];
        if (isPosition) {
            const tool = this.GetToolViewById(ID, type);

            if (tool !== null) {
                this.orderCollappsedStateTable[ID] = tool.Collapsed;
                toRemove.push(tool);
            }
        } else {
            let tool = this.GetToolViewById(ID, type);
            if (tool === null) {
                tool = this.GetToolViewById(ID, TradingToolViewType.SLTPOrderToolView);
                if (tool !== null) { toRemove.push(tool); }
            } else {
                toRemove.push(tool);
                const sl = this.GetToolViewById(order.OrderNumber + '-SL', TradingToolViewType.SLTPOrderToolView);
                if (sl != null) {
                    toRemove.push(sl);

                    const sll = sl.sllTool;
                    if (sll != null) { toRemove.push(sll); }
                }

                const tp = this.GetToolViewById(order.OrderNumber + '-TP', TradingToolViewType.SLTPOrderToolView);
                if (tp != null) { toRemove.push(tp); }
            }
        }

        for (let i = 0; i < toRemove.length; i++) {
            const tool = toRemove[i];
            if (tool != null) {
                ArrayUtils.RemoveElementFromArray(this.tools, tool);
                tool.Dispose();
            }
        }
        this.chart.IsDirty(LayersEnum.Tools);
    }

    AddOrderPosition (order: any): void {
        const isPosition = !!order.isPosition;
        const ID = isPosition ? order.PositionId : order.OrderNumber;
        const type = isPosition ? TradingToolViewType.PositionToolView : TradingToolViewType.OrderToolView;
        const tool = this.GetToolViewById(ID, type);
        if (tool == null) {
            if (isPosition) {
                const isTrade = (order.SuperPositionId !== '-1' && !order.isSuperPosition);
                if (!isTrade) {
                    this.tools.push(this.NewPositionToolView(order));

                    const pos = order;
                    const positionTVB = this.GetToolViewById(pos.PositionId, TradingToolViewType.PositionToolView);
                    if (positionTVB != null) {
                        const orderTVB = this.GetToolViewById(pos.PositionId, TradingToolViewType.OrderToolView);
                        let collapsed = null;
                        if (orderTVB != null) { collapsed = orderTVB.Collapsed; } else if (this.orderCollappsedStateTable[pos.PositionId]) {
                            collapsed = this.orderCollappsedStateTable[pos.PositionId];
                            delete this.orderCollappsedStateTable[pos.PositionID];
                        }

                        if (collapsed != null) { positionTVB.Collapsed = collapsed; }
                    }
                }
            } else {
                if (order.OrderType === OrderType.StopLimit) { this.tools.push(this.NewStopLimitOrderToolView(order)); } else { this.tools.push(this.NewOrderToolView(order)); }

                if (!isNaN(order.StopLossPriceValue)) {
                    const slTool = this.NewSLTPOrderToolView(order, null, true);
                    this.tools.push(slTool);

                    if (order.StopLossLimitPriceValue != null) {
                        slTool.sllTool = this.NewStopLossLimitToolView(order, null);
                        this.tools.push(slTool.sllTool);
                    }
                }

                if (!isNaN(order.TakeProfitPriceValue)) {
                    this.tools.push(this.NewSLTPOrderToolView(order, null, false));
                }
            }
        } else {
            tool.Updated(order);
            let slTool = this.GetToolViewById(order.OrderNumber + '-SL', TradingToolViewType.SLTPOrderToolView);
            if (slTool == null && !isNaN(order.StopLossPriceValue)) {
                slTool = this.NewSLTPOrderToolView(order, null, true);
                this.tools.push(slTool);
            } else if (slTool != null && isNaN(order.StopLossPriceValue)) {
                ArrayUtils.RemoveElementFromArray(this.tools, slTool);
                slTool.Dispose();
            } else if (slTool != null) { slTool.Updated(); }
            const tpTool = this.GetToolViewById(order.OrderNumber + '-TP', TradingToolViewType.SLTPOrderToolView);
            if (tpTool == null && !isNaN(order.TakeProfitPriceValue)) {
                this.tools.push(this.NewSLTPOrderToolView(order, null, false));
            } else if (tpTool != null && isNaN(order.TakeProfitPriceValue)) {
                ArrayUtils.RemoveElementFromArray(this.tools, tpTool);
                tpTool.Dispose();
            } else if (tpTool != null) { tpTool.Updated(); }
            const sllTool = slTool ? slTool.sllTool : this.GetToolViewById(order.OrderNumber + '-SLL', TradingToolViewType.StopLossLimitToolView);
            const isPositionAndNotTS = isPosition && order.SLOrder?.StopLimit && order.SLOrder.OrderType !== OrderType.TrailingStop;
            const needToAddSLLTool = isPosition ? isPositionAndNotTS : (!isNaN(order.StopLossPriceValue) && order.StopLossLimitPriceValue != null);
            if (sllTool == null && needToAddSLLTool) {
                const sllTool = this.NewStopLossLimitToolView(order, order.isPosition ? order.SLOrder : null);
                this.tools.push(sllTool);
                if (!slTool && order.SLOrder) { slTool = this.GetToolViewById(order.SLOrder.OrderNumber, TradingToolViewType.SLTPOrderToolView); }
                if (slTool) slTool.sllTool = sllTool;
            } else if (sllTool != null && !isPositionAndNotTS && (order.StopLossLimitPriceValue === null || isNaN(order.StopLossPriceValue) || order.StopLossPriceType == SlTpPriceType.TrOffset)) {
                ArrayUtils.RemoveElementFromArray(this.tools, sllTool);
                sllTool.Dispose();
                if (slTool?.sllTool) { slTool.sllTool = null; }
            } else if (sllTool != null) { sllTool.Updated(); }
        }
        this.chart.IsDirty(LayersEnum.Tools);
    }

    AddNewTrade (trade: any): void {
        const tool = this.GetToolViewById(trade.TradeId, TradingToolViewType.NewTradeToolView);
        if (tool == null) { this.tools.push(new NewTradeToolView(trade, this.BuyNewTradeToolsDrawingInfo, this.SellNewTradeToolsDrawingInfo, this)); } else { tool.Updated(); }
        this.chart.IsDirty(LayersEnum.Tools);
    }

    AddNewAlert (alert: any): void {
        if (!DataCache.AlertManager.AlertsVisible) { return; }
        const tool = this.GetToolViewById(alert.AlertId, TradingToolViewType.AlertToolView);
        if (tool === null) {
            if (alert.CanLocateOnChart(this.instrument)) { this.tools.push(this.NewAlertToolView(alert)); }
        } else {
            if (alert.CanLocateOnChart(this.instrument)) { tool.Updated(alert); } else { this.RemoveAlert(alert); }
        }
        this.chart.IsDirty(LayersEnum.Tools);
    }

    RemoveAlert (alert: any): void {
        const tool = this.GetToolViewById(alert.AlertId, TradingToolViewType.AlertToolView);
        if (tool !== null) {
            ArrayUtils.RemoveElementFromArray(this.tools, tool);
            tool.Dispose();
        }
        this.chart.IsDirty(LayersEnum.Tools);
    }

    AddNewOrderTool (newChartEvent: any): void {
    // Implementation goes here.
    }

    UpdateNewOrderTool (data: any): void {
    // Implementation goes here.
    }

    RemoveNewOrderTool (data: any): void {
    // Implementation goes here.
    }
}

class GroupTradeInfo {
    screenX: number;
    screenY: number;
    posID: number;
    TradeSide: string;
    TradeStatus: string;
    TradeTime: Date;
    Selected: boolean;
    tradeTool: any;

    constructor () {
        this.screenX = 0;
        this.screenY = 0;
        this.posID = 0;
        this.TradeSide = '';
        this.TradeStatus = '';
        this.TradeTime = new Date();
        this.Selected = false;
        this.tradeTool = null;
    }
}

class GroupTradeInfoComparer {
    // Implement IComparer<GroupTradeInfo> if necessary
    Compare (x: GroupTradeInfo, y: GroupTradeInfo): number {
        if (x.TradeTime > y.TradeTime) {
            return 1;
        } else {
            return -1;
        }
    }
}

const GroupTradeInfoComparerInstance = new GroupTradeInfoComparer();

enum OCOMouseClickPosition {
  Above = 0,
  Spread = 1,
  Below = 2,
}

class OCOProcessingResult {
    Allowed: boolean;
    OrderType: number;
    BuySell: number;

    constructor (Allowed: boolean, OrderType: number, BuySell: number) {
        this.Allowed = Allowed;
        this.OrderType = OrderType;
        this.BuySell = BuySell;
    }

    /* #34012  - назначить все операции покупки на левую клавишу мыши, продажи - на правую */
    static ProcessMouseClick (instrument: any, position: number, buttons: number, firstClickResult: OCOProcessingResult | null = null): OCOProcessingResult | null {
        const AllowedTypes: number[] = Instrument.GetAllowedOrderTypes(instrument);
        const Route = DataCache.getRouteById(instrument.Route);
        let StopAllowed: boolean = true;
        let LimitAllowed: boolean = true;
        const stopOrderType: number = BaseSettings.isUseStopLimitInsteadStop() ? OrderType.StopLimit : OrderType.Stop;

        StopAllowed = AllowedTypes.includes(stopOrderType);
        LimitAllowed = AllowedTypes.includes(OrderType.Limit);

        if (Route) {
            StopAllowed = StopAllowed && Route.IsAllowableOrderType(stopOrderType);
            LimitAllowed = LimitAllowed && Route.IsAllowableOrderType(OrderType.Limit);
        }

        if (firstClickResult == null) {
            if (buttons === MouseButtons.Left) {
                switch (position) {
                case OCOMouseClickPosition.Above:
                    return StopAllowed ? new OCOProcessingResult(true, stopOrderType, OperationType.Buy) : null;
                case OCOMouseClickPosition.Spread:
                    return LimitAllowed ? new OCOProcessingResult(true, OrderType.Limit, OperationType.Buy) : null;
                case OCOMouseClickPosition.Below:
                    return LimitAllowed ? new OCOProcessingResult(true, OrderType.Limit, OperationType.Buy) : null;
                }
            } else if (buttons === MouseButtons.Right) {
                switch (position) {
                case OCOMouseClickPosition.Above:
                    return LimitAllowed ? new OCOProcessingResult(true, OrderType.Limit, OperationType.Sell) : null;
                case OCOMouseClickPosition.Spread:
                    return LimitAllowed ? new OCOProcessingResult(true, OrderType.Limit, OperationType.Sell) : null;
                case OCOMouseClickPosition.Below:
                    return StopAllowed ? new OCOProcessingResult(true, stopOrderType, OperationType.Sell) : null;
                }
            }
        } else { /* Second click */
            if (firstClickResult.OrderType == stopOrderType && firstClickResult.BuySell == OperationType.Buy) {
                switch (position) {
                case OCOMouseClickPosition.Above:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(false, OrderType.Limit, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(false, stopOrderType, OperationType.Sell);
                    }
                case OCOMouseClickPosition.Spread:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(LimitAllowed, OrderType.Limit, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(false, stopOrderType, OperationType.Sell);
                    }
                case OCOMouseClickPosition.Below:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(LimitAllowed, OrderType.Limit, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(StopAllowed, stopOrderType, OperationType.Sell);
                    }
                }
            } else if (firstClickResult.OrderType == stopOrderType && firstClickResult.BuySell == OperationType.Sell) {
                switch (position) {
                case OCOMouseClickPosition.Above:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(StopAllowed, stopOrderType, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(LimitAllowed, OrderType.Limit, OperationType.Sell);
                    }
                case OCOMouseClickPosition.Spread:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(false, stopOrderType, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(LimitAllowed, OrderType.Limit, OperationType.Sell);
                    }
                case OCOMouseClickPosition.Below:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(false, stopOrderType, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(false, OrderType.Limit, OperationType.Sell);
                    }
                }
            } else if (firstClickResult.OrderType == OrderType.Limit && firstClickResult.BuySell == OperationType.Buy) {
                switch (position) {
                case OCOMouseClickPosition.Above:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(StopAllowed, stopOrderType, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(LimitAllowed, OrderType.Limit, OperationType.Sell);
                    }
                case OCOMouseClickPosition.Spread:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(false, stopOrderType, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(LimitAllowed, OrderType.Limit, OperationType.Sell);
                    }
                case OCOMouseClickPosition.Below:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(false, stopOrderType, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(false, OrderType.Limit, OperationType.Sell);
                    }
                }
            } else if (firstClickResult.OrderType == OrderType.Limit && firstClickResult.BuySell == OperationType.Sell) {
                switch (position) {
                case OCOMouseClickPosition.Above:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(false, OrderType.Limit, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(false, stopOrderType, OperationType.Sell);
                    }
                case OCOMouseClickPosition.Spread:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(LimitAllowed, OrderType.Limit, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(false, stopOrderType, OperationType.Sell);
                    }
                case OCOMouseClickPosition.Below:
                    if (buttons == MouseButtons.Left) {
                        return new OCOProcessingResult(LimitAllowed, OrderType.Limit, OperationType.Buy);
                    } else {
                        return new OCOProcessingResult(StopAllowed, stopOrderType, OperationType.Sell);
                    }
                }
            }
        }
        return null;
    }
}
