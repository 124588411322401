// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ExternalLinksObserver } from '../../../Commons/UtilsClasses/Observers/ExternalLinksObserver';
import { externalLinkManager } from '../../../Utils/Managers/ExternalLinkManager';
import { TerceraSideBarButton } from '../../elements/SideBar/TerceraSideBarButton';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';

export class VideoWidgetButton extends TerceraSideBarButton {
    override readonly tooltipKey: string = 'panel.VideoWidget';
    private readonly _extLinksObserver = new ExternalLinksObserver();

    constructor () {
        super();
    }

    getType (): ControlsTypes {
        return ControlsTypes.VideoWidgetButton;
    }

    oncomplete (): void {
        super.oncomplete();

        this._extLinksObserver.subscribeToAdd(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.subscribeToUpdate(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.subscribeToRemove(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.subscribeToYtUpdate(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.run();
    }

    onteardown (): void {
        this._extLinksObserver.unsubscribeToAdd(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.unsubscribeToUpdate(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.unsubscribeToRemove(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.unsubscribeToYtUpdate(this.onUpdateExternalLinkEvent);
        this._extLinksObserver.stop();
    }

    private readonly onUpdateExternalLinkEvent = (): void => {
        const ytLinks = externalLinkManager.getYoutubeLinks();
        const streamLink = ytLinks.find((tool) => tool.config.getStreamOnline());
        this.onLiveStreamOnAirChanged(streamLink !== undefined);
    };

    private onLiveStreamOnAirChanged (value: boolean): void {
        void this.set({ svgStyle: value ? 'videoWidgetBtnLive' : 'videoWidgetBtn' });
    }
}

TerceraSideBarButton.extendWith(VideoWidgetButton,
    {
        data: function () {
            return {
                text: '',
                liveStreamOnAir: false, // If there are live streams now, then the button will have a different text and view: js-button-video-widget.live
                svgStyle: 'videoWidgetBtn'
            };
        }
    });
