// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraBaseWindowTemplate, TypesManagerScreenTemplate, TypesManagerScreenFooterTemplate } from '../../templates.js';
import { DefaultLotTableItem } from '../cache/DefaultLotTableItem';
import { Control } from '../elements/Control';
import { QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { TerceraWindowBase } from './TerceraWindowBase';
import { ThemeManager } from '../misc/ThemeManager';
import { DynProperty } from '@shared/commons/DynProperty';
import { SLTPTriggerUtils } from '@shared/commons/cache/OrderParams/SLTPTriggerUtils';
import { SLTPTrigger } from '@shared/utils/SlTpTrigger';
import { Instrument } from '@shared/commons/cache/Instrument';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { HtmlScroll } from '@shared/commons/HtmlScroll';

export class TypesManagerScreen extends TerceraWindowBase {
    public quickTableRactive: any;
    public deferredInstrumentToAdd: any;
    public caller: any;
    public callBack: any;

    constructor () { super(); }

    public override getType (): string { return 'TypesManagerScreen'; }

    public override oninit (): void {
        super.oninit();
        this.observe('itemSettings', this.onItemSettingsChanged);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.observe('triggersVisible', this.triggersVisibleChanged);
        void this.set({
            isAllowSLTPPercent: DataCache.IsAllowSLTPPercentForUser()
        });

        const qtRactive = this.Controls.quickTableRactive;
        this.quickTableRactive = qtRactive;

        this.layoutTable();

        const columnsInfo = new DefaultLotTableItem(null);

        const qt = qtRactive.quickTable;
        qt.canShowHeaderMenu = false;
        qt.allowGroupBy = false;
        qt.lockManualSorting = true;
        qt.InitializeDirect(columnsInfo);
        qt.UpdateSortedColumns();
        qt.AfterEditItem.Subscribe(this.onAfterEditItem, this);

        this.localize();
        this.themeChange();

        ThemeManager.onThemeChange.Subscribe(this.themeChange, this);
        Control.Ticker.Subscribe(this.TickAsync, this);

        qt.needRedrawBackground = true;
        qt.needRedraw = true;

        if (this.deferredInstrumentToAdd) {
            this.Controls.lookup.AddInstrument(this.deferredInstrumentToAdd);
            delete this.deferredInstrumentToAdd;
        }
    }

    public override dispose (): void {
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        ThemeManager.onThemeChange.UnSubscribe(this.themeChange, this);

        delete this.caller;
        delete this.callBack;
        delete this.deferredInstrumentToAdd;

        // TODO.
        MainWindowManager.TypesManagerScreen = null;

        super.dispose();
    }

    private triggersVisibleChanged (value: boolean): void {
        if (value) {
            this.addScrolls();
        } else {
            this.removeScrolls();
        }
    }

    public onItemSettingsChanged (itemSettings): void {
        this.repopulateDefaultLots(itemSettings);

        this.repopulateSLTPTriggers(itemSettings);
    }

    public repopulateDefaultLots (itemSettings): void {
        const qt = this.quickTableRactive ? this.quickTableRactive.quickTable : null;
        if (!qt) return;

        qt.ClearAll();
        qt.BeginUpdate();

        const defaultLots = itemSettings.DefaultLots;
        const len = defaultLots.length;
        for (let i = 0; i < len; i++) {
            const defLot = defaultLots[i];
            const row = qt.AddItem(new DefaultLotTableItem(defLot, i));
            this.ProcessRow(row, defLot);
        }

        qt.EndUpdate();
        qt.needRedraw = true;
    }

    public ProcessRow (row, defaultLot): void {
        if (!row) return;

        const cells = row.cells;

        const selectedLotCell = cells[0];
        if (!selectedLotCell.QuickTableEditingInfo) {
            const info = new QuickTableEditingInfo(DynProperty.BOOLEAN);
            selectedLotCell.QuickTableEditingInfo = info;
            selectedLotCell.ReadOnly = false;
        }

        selectedLotCell.value = defaultLot.Selected;

        const lotValueCell = cells[1];
        if (!lotValueCell.QuickTableEditingInfo) {
            const info = new QuickTableEditingInfo(DynProperty.DOUBLE);
            info.Inc = info.Min = defaultLot.Increment;
            info.Max = 9999999;
            info.DecimalPlaces = defaultLot.Digits;
            lotValueCell.QuickTableEditingInfo = info;
            lotValueCell.ReadOnly = false;
        }

        const lots = defaultLot.Lots;
        lotValueCell.value = defaultLot.Lots;
        lotValueCell.formattedValue = TypesManagerScreen.getFormattedLotValue(lots);
    }

    public static getFormattedLotValue (value): string {
        return value.toString();
    }

    public onAfterEditItem (params): void {
        const row = params.row;
        if (!row) return;

        const itemSettings = this.get('itemSettings');

        const defaultLot = itemSettings.DefaultLots[row.id];

        const qt = this.quickTableRactive.quickTable;
        const colIdx = params.columnIndex;
        switch (colIdx) {
        // Selected lot.
        case 0:
            defaultLot.Selected = !defaultLot.Selected;
            break;
            // Lot value.
        case 1:
            defaultLot.Lots = params.newValue;
            break;
        }

        this.ProcessRow(row, defaultLot);
    }

    public TickAsync (): void {
        const qtRactive = this.quickTableRactive;
        if (qtRactive?.quickTable.needRedraw) {
            const qt = qtRactive.quickTable;
            qt.needRedraw = false;
            qt.Draw();
        }
    }

    public layoutTable (): void {
        if (this.quickTableRactive != null) {
            this.layoutTableResize(this.quickTableRactive);
        }
    }

    public layoutTableResize (table): void {
        table.resetSizes().then(() => { table.setSizes(); });
    }

    public override localize (): void {
        super.localize();

        this.quickTableRactive?.quickTable?.localize();

        void this.set('header', Resources.getResource('TypesManagerScreen.Types Manager'));

        void this.set({
            okBtnText: Resources.getResource('general.messageBox.ok'),
            okBtnTooltip: Resources.getResource('general.messageBox.ok.ToolTip'),
            cancelBtnText: Resources.getResource('general.messageBox.cancel'),
            cancelBtnTooltip: Resources.getResource('general.messageBox.cancel.ToolTip'),

            slDefaultText: Resources.getResource('TypesManagerScreen.NumericLabel.SL default offset, ticks'),
            tpDefaultText: Resources.getResource('TypesManagerScreen.NumericLabel.TP default offset, ticks'),
            slDefaultPercentText: Resources.getResource('TypesManagerScreen.NumericLabel.SL default offset, percent'),
            tpDefaultPercentText: Resources.getResource('TypesManagerScreen.NumericLabel.TP default offset, percent'),
            usrQtyText: Resources.getResource('TypesManagerScreen.NumericLabel.User quantity coefficient'),
            priceIncrementText: Resources.getResource('TypesManagerScreen.NumericLabel.Price increment (arrow), ticks'),
            defLotsSetupText: Resources.getResource('TypesManagerScreen.DefaultLotsSetup')
        });

        if (this.quickTableRactive) {
            this.quickTableRactive.quickTable.localize();
        }
    }

    public themeChange (): void {
        if (this.quickTableRactive) {
            this.quickTableRactive.themeChange();
        }

        const lookup = this.Controls.lookup;
        if (lookup) {
            lookup.themeChange();
        }
    }

    public okClick (): void {
        if (this.callBack) {
            this.callBack(this.get('allSettings'));
        }

        this.close();
    }

    public override onCloseButtonClick (): void {
        if (this.callBack) {
            this.callBack(null);
        }

        this.close();
    }

    public AddInstrument (instrument): void {
        if (!instrument || !(instrument instanceof Instrument)) {
            return;
        }

        const lookup = this.Controls.lookup;
        if (lookup) {
            lookup.AddInstrument(instrument);
        } else {
            const scr = this;
            scr.deferredInstrumentToAdd = instrument;
        }
    }

    public repopulateSLTPTriggers (itemSettings): void {
        if (!itemSettings) {
            return;
        }

        const DC = DataCache;

        const ins = DC.getInstrumentByName(itemSettings.InstrumentName);
        const insRule = ins
            ? ins.IsAllowCustomSLTPTriggerPrice
            : DC.AllowCustomSLTPTriggerPriceForInstrumentType(itemSettings.InstrumentType);

        const userRule = DC.AllowCustomSLTPTriggerPriceForUser();
        const triggersVisible = userRule && insRule;
        void this.set('triggersVisible', triggersVisible);

        this.layoutTable(); // #120102
        if (triggersVisible && !itemSettings.sltpTriggerShort && itemSettings.sltpTriggerShort !== 0) {
            const tr = new SLTPTrigger();

            if (this.caller?.ownerPanel?.arrayProperties) {
                SLTPTriggerUtils.ApplyProperties(tr, this.caller.ownerPanel.arrayProperties);
            } // если поменяли перед открітіем тайм менеджера но не сохранили
            else {
                tr.CopyValueFrom(SessionSettings.GetTriggers());
            }

            void this.set('itemSettings.sltpTriggerShort', tr.GetShort());
        }
    }

    public addScrolls (): void {
        if (!this.append) {
            return;
        }
        const rP = this.find('.js-right-panel');
        if (rP != null) {
            HtmlScroll.addScroll(rP);
        }
    }

    public removeScrolls (): void {
        if (!this.append) {
            return;
        }
        const rP = this.find('.js-right-panel');
        if (rP != null) {
            HtmlScroll.removeScroll(rP);
        }
    }

    public static show (caller, instrumentToAdd, callBack): void {
        const scr = TypesManagerScreen.getInstance();
        if (scr) {
            scr.setFocus();
        }

        const oldCaller = scr.caller;
        if (oldCaller !== caller) {
            scr.caller = caller;
            void scr.set('allSettings',
                caller.getAllInstrumentsDefaultSettings
                    ? caller.getAllInstrumentsDefaultSettings()
                    : null);
        }

        scr.callBack = callBack;
        scr.AddInstrument(instrumentToAdd);
    }

    public static getInstance (): TypesManagerScreen {
        const instance = MainWindowManager.TypesManagerScreen;
        if (instance) {
            return instance;
        }

        const scr = new TypesManagerScreen();
        MainWindowManager.MainWindow.addControl(scr);
        scr.center();

        MainWindowManager.TypesManagerScreen = scr;
        return scr;
    }
}

TerceraWindowBase.extendWith(TypesManagerScreen, {
    data: function () {
        return {
            width: 653,
            height: 480,
            resizable: false,

            allSettings: null,
            itemSettings: null,
            triggersVisible: false,
            isAllowSLTPPercent: false,
            sltpStep: 1,
            sltpMinValue: 1,
            sltpDecimalPrecision: 0,

            style_addition_body: 'js-types-ManagerScreen-AdditionalBody',
            style_addition_footer: 'js-types-ManagerScreen-AdditionalFooter'
        };
    },
    template: TerceraBaseWindowTemplate,
    partials: {
        bodyPartial: TypesManagerScreenTemplate,
        footerPartial: TypesManagerScreenFooterTemplate
    }
});
