// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { DynProperty, PairColor } from '../../DynProperty';
import { Resources } from '@shared/localizations/Resources';
import { FillableDataCacheTool } from './FillableDataCacheTool';
import { ThemeManager } from '@front/controls/misc/ThemeManager';

export class PriceLabelDataCacheTool extends FillableDataCacheTool {
    private readonly borderKey = 'property.borderColor';
    private readonly fontKey = 'property.Font';
    private readonly labelColorKey = 'property.LabelColor';

    constructor () {
        super();
        this.ToolType = DataCacheToolType.PriceLabel;
        this.InitPoints(2);
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();

        this.font = ThemeManager.Fonts.Font_11F25_regular.copy();
    }

    InitBlock (): void {
        super.InitBlock();

        this.FontColor = 'white';
    }

    MaxPointsCount (): number {
        return 1;
    }

    MinPointsCount (): number {
        return 1;
    }

    Properties (): DynProperty[] {
        const oldProps = super.Properties();
        const props = [];

        props.push(new DynProperty(this.borderKey, this.pen.Color, DynProperty.COLOR, DynProperty.VISUAL_GROUP));
        props.push(new DynProperty(this.fontKey, this.font.copy(), DynProperty.FONT, DynProperty.VISUAL_GROUP));
        props.push(new DynProperty(this.labelColorKey, new PairColor(this.FontColor, this.FillColor, Resources.getResource('property.text'), Resources.getResource('property.backgroundColor')), DynProperty.PAIR_COLOR, DynProperty.VISUAL_GROUP));

        const len = oldProps.length;
        for (let i = 0; i < len; i++) {
            const prop = oldProps[i];
            if (
                prop.group === DynProperty.NUMERICAL_PARAMETRS_GROUP ||
                prop.group === DynProperty.HIDDEN_GROUP ||
                prop.name === 'Stick to period' ||
                prop.name === 'IsBackground'
            ) {
                props.push(prop);
            }
        }

        return props;
    }

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const borderProp: DynProperty | undefined = DynProperty.getPropertyByName(properties, this.borderKey);
        if (!isNullOrUndefined(borderProp)) this.pen.Color = borderProp.value;

        const fontProp: DynProperty | undefined = DynProperty.getPropertyByName(properties, this.fontKey);
        if (!isNullOrUndefined(fontProp)) this.font = fontProp.value;

        const pairColorProp = DynProperty.getPropertyByName(properties, this.labelColorKey);
        if (!isNullOrUndefined(pairColorProp)) {
            const pairColor = pairColorProp.value;

            this.FontColor = pairColor.Color1;
            this.FillColor = pairColor.Color2;
        }
    }
}
