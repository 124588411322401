// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type CommissionTypes } from '../../../Utils/Commission/CommissionEnums';
import { CommissionDefaultAsset } from '../../../Utils/Enums/Constants';
import { type RevenueCommissionItem } from './RevenueCommissionItem';

/// Cодержит набор комиссий применительно к инструменту/группе

export class RevenueCommissionEntryItem {
    public Type: any = null;
    public Name: string | null = null;
    public ShortCommissionInfo: string | null = null;

    public InstrumentTypeId: number | null = null;
    public InstrumentId: string | null = null;

    public AssetID = CommissionDefaultAsset.INSTRUMENT_QUOTING_CCY;
    public Currency: string | null = null;

    public UseInVAT: boolean | null = null;

    public CommissionItems: RevenueCommissionItem[] = [];

    get TypeId (): number {
        return this.InstrumentTypeId;
    }

    public GetCommission (param): number {
        let value = 0;

        this.CommissionItems.forEach(function (revenueItem) {
            value += revenueItem.GetCommission(param);
        });

        return value;
    }

    public ContainsCommission (type): boolean {
        const items = this.CommissionItems;
        if (!items?.length) return false;

        for (let i = 0; i < items.length; i++) {
            if (items[i].Type == type) {
                return true;
            }
        };

        return false;
    }

    public GetLotsListForType (outLotsArray, commissionType: CommissionTypes, operationType): any {
        const items = this.CommissionItems;
        if (!items?.length) return false;

        for (let i = 0; i < items.length; i++) {
            items[i].GetLotsListForType(outLotsArray, commissionType, operationType);
        }
    }

    public IsSingleField (type): boolean {
        for (let i = 0; i < this.CommissionItems.length; i++) {
            const item = this.CommissionItems[i];
            if (item.Type == type) {
                return item.IsSingleField();
            }
        }

        return false;
    }

    public ShowOpenClose (type): boolean {
        for (let i = 0; i < this.CommissionItems.length; i++) {
            const item = this.CommissionItems[i];
            if (item.Type == type) {
                return item.IsShowOpenClose();
            }
        }

        return false;
    }

    public ContainsShort (type): boolean {
        for (let i = 0; i < this.CommissionItems.length; i++) {
            const item = this.CommissionItems[i];
            if (item.Type == type) {
                return item.ContainsShort();
            }
        }

        return false;
    }
}
