// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { InformationMessage } from '../Messages/InformationMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class InformationMessageProcessor extends MessageProcessorBase {
    public decoder: any;
    constructor (parentDecoder?) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): InformationMessage[] {
        const message = new InformationMessage(fieldSet);
        return [message];
    }
}
