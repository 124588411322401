// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { DockSystemInstance } from '../DockSystem';
import { DataCache } from '../../Commons/DataCache';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { CustomEvent } from '../../Utils/CustomEvents';
import { AccountWidgetAccountDetailsPanelController } from '../../Commons/AccountWidget/AccountWidgetAccountDetailsPanelController';

class _LinkedSystem {
    public accountStorage = {};
    public symbolStorage = {};

    public inProgress = false;
    public accLinkingActive: boolean = false;
    public currentLinkOutColor = null;

    public OnAccountLinkingStateChanged = new CustomEvent();

    //
    // account id
    //
    public setAccount (color, accountId: string): void {
        const acc = DataCache.GetAccountById(accountId);
        if (!acc) {
            return;
        }

        if (acc.isMAM) {
            return;
        }
        // newSubscriber is obsoleted
        this.accountStorage[color] = accountId;
        this.accountLinkOut(color);
    }

    public getAccount (color = LinkedSystemAccLinkingValue): string { /* return value: AccountId */
        return this.accountStorage[color];
    }

    //
    // instrument FullName
    //
    public setSymbol (color, symbolName, newSubscriber): void {
        if (newSubscriber && !this.symbolStorage[color] || !newSubscriber && this.symbolStorage[color] || !newSubscriber && !this.symbolStorage[color]) {
            this.symbolStorage[color] = symbolName;
        }
        this.symbolLinkOut(color);
    }

    public getSymbol (color): any {
        return this.symbolStorage[color];
    }

    public accountLinkOut (color): void {
        const value = this.getAccount(color);
        const valueControlTag = 'accountLinkValue';
        const method = 'accountLink_In';
        this.linkOut(value, color, valueControlTag, method);
    }

    public symbolLinkOut (color): void {
        const value = this.getSymbol(color);
        const valueControlTag = 'symbolLinkValue';
        const method = 'symbolLink_In';
        this.linkOut(value, color, valueControlTag, method);
    }

    public updateDictState (isByAcc: boolean): void {
        const valueControlTag = isByAcc ? 'accountLinkValue' : 'symbolLinkValue';
        let storage = isByAcc ? this.symbolStorage : this.accountStorage;
        const curWS = WorkSpaceManager.currentWorkspace;

        if (!curWS) {
            return;
        }

        const allPanels = curWS.allPanels;
        const colorConstants = TerceraLinkControlConstants.ColorConstants;
        const colorTabs = {};
        const keys = Object.keys(allPanels);
        const len = keys.length;
        for (let i = 0; i < len; i++) {
            const curPanel = allPanels[keys[i]];

            const myColor = curPanel.get(valueControlTag);
            if (myColor === colorConstants.NONE) {
                continue;
            }

            if (storage.hasOwnProperty(myColor)) {
                colorTabs[myColor] = storage[myColor];
            }
        }

        storage = colorTabs;
    }

    public linkOut (value, color, valueControlTag, method): void {
        try {
            const curWS = WorkSpaceManager.currentWorkspace;

            if (this.inProgress || !curWS) {
                return;
            }

            const allPanels = curWS.allPanels;
            const processedTabs = [];

            const colorConstants = TerceraLinkControlConstants.ColorConstants;

            const accountLinking = valueControlTag === 'accountLinkValue';
            if (accountLinking && this.accLinkingActive && AccountWidgetAccountDetailsPanelController.HasPanel()) {
                processedTabs.push(AccountWidgetAccountDetailsPanelController.AccountDetailsPanel);
            }

            let i = 0;
            const keys = Object.keys(allPanels);
            const keysLen = keys.length;
            for (i = 0; i < keysLen; i++) {
                const curPanel = allPanels[keys[i]];

                const myColor = curPanel.get(valueControlTag);
                if (myColor === colorConstants.NONE && !accountLinking) {
                    continue;
                }

                if (myColor === color || myColor === TerceraLinkControlConstants.STATE_ALL || accountLinking) // || myColor === colorConstants.ALL)
                {
                    processedTabs.push(curPanel);
                }
            }

            this.inProgress = true;
            this.currentLinkOutColor = color;

            const processedTabsLen = processedTabs.length;
            for (i = 0; i < processedTabsLen; i++) {
                const curPanel = processedTabs[i];
                curPanel[method](value, true);
            }

            this.inProgress = false;
            this.currentLinkOutColor = null;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            this.inProgress = false;
            this.currentLinkOutColor = null;
        }
    }

    public accLinkingOn (accId): void {
        const value = LinkedSystemAccLinkingValue;

        this.setAccount(value, accId);

        const style = 'js-link-color-active' + (DataCache.EnableForceLinkingByAccount() ? ' disabled' : '');
        this.OnAccountLinkingStateChanged.Raise(value, style);

        const allPanels = WorkSpaceManager.currentWorkspace.allPanels;

        for (const id in allPanels) {
            allPanels[id].updateAccountLinkVisible(true);
            allPanels[id].accountLink_In(accId, true);
        }

        DockSystemInstance.resizeDockSystem();
    }

    public accLinkingOff (): void {
        if (isNullOrUndefined(WorkSpaceManager.currentWorkspace)) {
            return;
        }

        const value = TerceraLinkControlConstants.ColorConstants.NONE;
        const style = 'js-link-color-none-acc';

        this.OnAccountLinkingStateChanged.Raise(value, style);

        const allPanels = WorkSpaceManager.currentWorkspace.allPanels;

        for (const id in allPanels) {
            allPanels[id].disableAccountLinking();
        }

        DockSystemInstance.resizeDockSystem();
    }

    public Clear (): void {
        this.accountStorage = {};
        this.symbolStorage = {};
        this.inProgress = false;
        this.accLinkingActive = false;
        this.accLinkingOff();
    }
}

export const LinkedSystemAccLinkingValue = -2;

export const LinkedSystem = new _LinkedSystem();
