// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { GridPanelEditTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';
import { TerceraMenu } from './TerceraMenu';
import { Rectangle } from '@shared/commons/Geometry';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { MathUtils } from '@shared/utils/MathUtils';
import { type InitOpts } from 'ractive';

export class GridPanelEdit extends ContainerControl {
    public lostFocusOnOutsideClick: boolean = true;

    private readonly callBack: (data: { rows: number, columns: number }) => Promise<void>;

    constructor (initProps: InitOpts<GridPanelEdit> = null, x?: number, y?: number, data?: { rows: number, columns: number }, callBack?: (data: { rows: number, columns: number }) => Promise<void>) {
        super(initProps);

        this.onNewGridSizeSelected = this.onNewGridSizeSelected.bind(this);
        this.needResetLayout = this.needResetLayout.bind(this);

        const popupRectngle = new Rectangle(x, y, super.get('width'), super.get('height'));
        const correctedRectangle = TerceraMenu.CorrectPopupLocation(popupRectngle);

        this.callBack = callBack;

        void super.set({
            left: correctedRectangle.newX,
            top: correctedRectangle.newY,
            defaultRowsSelected: data.rows,
            defaultColumnsSelected: data.columns
        });
    }

    public override getType (): string {
        return 'GridPanelEdit';
    }

    public override oninit (): void {
        super.oninit();
        void super.set('textResetButton', Resources.getResource('panel.GridPanel.resetMI.Text'));
        super.on('newGridSizeSelected', this.onNewGridSizeSelected);
        super.on('resetButtonClick', this.needResetLayout);
    }

    public override setFocus (): void { super.setFocus(); }
    public override lostFocus (): void {
        super.lostFocus();
        this.hide();
    }

    public hide (): void {
        MainWindowManager.MainWindow.removeControl(this);
    }

    public async onNewGridSizeSelected (context, rows, columns): Promise<void> {
        await this.applyChanges({ rows, columns });
    }

    public async needResetLayout (): Promise<void> {
        await this.applyChanges({ resetLayout: true });
    }

    public async applyChanges (data): Promise<void> {
        if (!MathUtils.IsNullOrUndefined(this.callBack)) {
            await this.callBack(data);
        }
        this.hide();
    }

    static show (x: number, y: number, data: { rows: number, columns: number }, callBack: (data: { rows: number, columns: number }) => Promise<void>): void {
        const gridPanelEdit = new GridPanelEdit(undefined, x, y, data, callBack);
        MainWindowManager.MainWindow.addControl(gridPanelEdit);
        gridPanelEdit.setFocus();
    }
}

ContainerControl.extendWith(GridPanelEdit, {
    template: GridPanelEditTemplate,
    data: function () {
        return {
            defaultRowsSelected: 1,
            defaultColumnsSelected: 1,
            textResetButton: ''
        };
    }
});
