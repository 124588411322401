// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum InfoWindowItemType {
    None = -1,
    Bars = 0,
    Date = 1,
    Time = 2,
    // Additionalinfo = 3, // Additionalinfo is deprecated
    Open = 4,
    High = 5,
    Low = 6,
    Close = 7,
    HighLow = 8,
    Change = 9,
    Ticks = 10,
    Volume = 11,
    Ask = 12,
    Bid = 13,
    PortfolioGrowth = 14,
    Funding = 15,
    Countdown = 16
    /*, Price, Last, Value */
}