// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Rectangle } from '../../Commons/Geometry';
import { type Account } from '../../Commons/cache/Account';
import { AccountsComboBox } from './AccountsComboBox';

export class QuickTableAccountsComboBox extends AccountsComboBox {
    tag: any;
    callback: (value: Account | undefined, tag: any) => void;
    public override getType (): string { return 'QuickTableAccountsComboBox'; }

    constructor (initProps?, data?, callback?: (value: Account | undefined, tag: any) => void, rect?: Rectangle) {
        super();
        this.tag = data;
        this.callback = callback;
        this.setBounds(rect.X, rect.Y, rect.Width, rect.Height);
    }

    protected override onDropDownVisibleChanged (newValue: boolean, oldValue: boolean): void {
        if (oldValue && !newValue) {
            const selectedItem = this.get('selectedItem');
            this.callback(selectedItem, this.tag);
        }
    }

    protected override async populateAsync (): Promise<void> {
        await this.set({ isPosAbsolute: true });
        await super.populateAsync();
        await this.set({ selectedItem: this.tag.cell.QuickTableEditingInfo.GetDataHandler() });
        this.showItems();
        this.setFocus();
    }
}

AccountsComboBox.extendWith(QuickTableAccountsComboBox, { });
