// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { CustomEvent } from '../../../Utils/CustomEvents';
import { InvestingMenuTemplate } from '../../templates.js';
import { HtmlScroll } from '../../../Commons/HtmlScroll';
import Ractive from 'ractive';

export const closeContextMenuEvent = new CustomEvent();
export let openContextMenu = null;
export let hideContextMenu = null;
export let updateContextMenu = null;

export class InvestingMenu extends Ractive {
    public static readonly SCROLL_WIDTH = 10;

    public functionClose: any = null;
    public isShiftComboBox: 0;
    public functionOnMouseDown: (event: any) => void;

    public getType (): string { return 'InvestingMenu'; }

    public override oninit (): void { }

    public override oncomplete (): void {
        this.functionClose = () => { this.Close(); };
        this.functionOnMouseDown = (event) => { this.onMouseDown(event); };

        hideContextMenu = () => { this.Hide(); };
        openContextMenu = (x, y, items, addParamsObj) => { this.Show(x, y, items, addParamsObj); };
        updateContextMenu = (newItems) => { void this.set({ itemsMenu: newItems }); };
    }

    public private_onItemClick (event, sender): void {
        let needClose = true;
        if (sender.canCheck) {
            needClose = false;
        }

        const callback = sender.event;
        if (!isNullOrUndefined(callback)) {
            callback(sender);
        }

        if (needClose) {
            this.Close();
        }
    }

    public Show (posX, posY, items, addParamsObj): void {
        if (isNullOrUndefined(addParamsObj)) {
            addParamsObj = {};
        }

        const newWidth = addParamsObj.width ? addParamsObj.width : null;
        const isDarkStyle = addParamsObj.isDarkStyle ? addParamsObj.isDarkStyle : false;
        this.isShiftComboBox = addParamsObj.isShiftComboBox || 0;

        void this.set({
            left: posX,
            top: posY,
            width: newWidth,
            isDarkStyle,
            showContextMenu: true,
            itemsMenu: items
        });

        this.setFocus();

        const par = this.find('*');
        HtmlScroll.addScroll(par.firstChild);
    }

    public Hide (): void {
        this.Close();
    }

    public setFocus (): void {
        const form = document.getElementById('fromContextMenu');
        form.tabIndex = 1;
        form.focus();

        document.addEventListener('wheel', this.functionClose);
        document.addEventListener('mousedown', this.functionOnMouseDown, { capture: true });
    }

    public onMouseDown (event): void {
        const form = document.getElementById('fromContextMenu');
        const posElement = form.getBoundingClientRect();

        const posX = posElement.x + posElement.width + InvestingMenu.SCROLL_WIDTH;
        const posY = posElement.y + posElement.height;

        const clickX = event.x > posElement.x && event.x < posX;
        const clickY = event.y > posElement.y - this.isShiftComboBox && event.y < posY;

        if (clickX && clickY) {} else { this.Close(); }
    }

    public Close (): void {
        document.removeEventListener('wheel', this.functionClose);
        document.removeEventListener('mousedown', this.functionOnMouseDown, { capture: true });
        void this.set({ showContextMenu: false });
        HtmlScroll.removeScroll(this.find('*').firstChild);
        closeContextMenuEvent.Raise();
    }
}

Ractive.extendWith(InvestingMenu, {
    el: 'contextMenu',
    template: InvestingMenuTemplate,
    data: function () {
        return {
            showContextMenu: false,
            isDarkStyle: false,
            canCheck: false
        };
    }
});
