// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import './GlobalDeclaration';
import './Extensions';
import { Resources, LOCALE_EN } from './Localizations/Resources';
import { ThemeManager } from './Controls/misc/ThemeManager';
import { App } from './Commons/Application';
import { TradingToolViewBase } from './Chart/Tools/TradingTools/TradingToolViewBase';
import { ConntionInfo } from './Commons/Connection';
import { GlobalAnimationFrameHandler } from './Commons/GlobalAnimationFrameHandler';
import { MainWindowManager } from './Controls/UtilsClasses/MainWindowManager';
import { TradingPlatform } from './TradingPlatform/TradingPlatform';
import { TvWorkspaceManager } from './Commons/TradingViewDataFeed/Managers/TvWorkspaceManager';
import { ClientType } from './Utils/ClientType';
import { CreateApplicationInfo } from './Commons/ApplicationInfo';
import { flh } from './Commons/UtilsClasses/FullLogsHelper';
import { tvch } from './Commons/TradingViewDataFeed/Helpers/TvConsoleHelper';
export * from './ControlsImport';
export * from './Investing/ControlsImport';

declare global{
    interface Window {
        flh: any
        tvch: any
    }
}

window.addEventListener('load', function () {
    ClientType.setIsTvTradingPlatform();
    CreateApplicationInfo();
    App.InitializeWebtrader();
    App.InitStorages();
    TvWorkspaceManager.initialize();
    ThemeManager.OwerrideInvestingColors();
    ThemeManager.overrideThemeColors();
    ThemeManager.preloadImagesAsync(TradingToolViewBase.ThemeChanged, true);
    ConntionInfo.ConnectionInfoArray = info;
    MainWindowManager.MainWindow = new TradingPlatform();
    GlobalAnimationFrameHandler();
    Resources.setLocale(LOCALE_EN);
    window.flh = flh;
    window.tvch = tvch;
});

window.addEventListener('contextmenu', function (e) {
    e.preventDefault();
});

const info: any[] = [
    {
        AllowCustomerNo: false,
        Name: 'TE',
        ServerAdress: 'ep-demo.traderevolution.com:443',
        brandingKey: 'traderevolution',
        forgotURL: '-1',
        ipURL: 'https://wtfismyip.com/text',
        isSSL: true,
        registrationLink: '-1'
    },
    {
        AllowCustomerNo: false,
        Name: 'test6',
        ServerAdress: 'test6node1.traderevolution.com:8889',
        brandingKey: 'Branding',
        forgotURL: '-1',
        ipURL: 'https://wtfismyip.com/text',
        isSSL: false,
        registrationLink: '-1'
    },
    {
        AllowCustomerNo: false,
        Name: '15',
        ServerAdress: 'test15node1.traderevolution.com:8889',
        brandingKey: '',
        forgotURL: '-1',
        ipURL: 'https://wtfismyip.com/text',
        isSSL: false,
        registrationLink: '-1'
    },
    {
        AllowCustomerNo: false,
        Name: 'analyst',
        ServerAdress: 'analyst-server.traderevolution.lan:8889',
        brandingKey: '',
        forgotURL: '-1',
        ipURL: 'https://wtfismyip.com/text',
        isSSL: false,
        registrationLink: '-1'
    }];
