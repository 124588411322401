// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';

export enum TvInteriorIdCacheMode {
    TvChart,
    TvTradingPlatform
}
class _TvInteriorIdCache {
    private _mode: TvInteriorIdCacheMode = TvInteriorIdCacheMode.TvChart;
    readonly tradableIdCache = new Map<string, string>();

    public getInteriorId (ticker: string): string {
        if (this.isTvChartMode()) return ticker;

        let tikerWithoutRoute = InstrumentUtils.ParseRouteName(ticker);
        if (this.tradableIdCache.has(tikerWithoutRoute)) {
            return this.tradableIdCache.get(tikerWithoutRoute);
        }

        tikerWithoutRoute = InstrumentUtils.RemoveRouteName(ticker);
        if (this.tradableIdCache.has(tikerWithoutRoute)) {
            return this.tradableIdCache.get(tikerWithoutRoute);
        }

        return ticker;
    }

    public saveInteriorId (fullName: string, interiorId: string): void {
        if (this.isTvChartMode()) return;
        this.tradableIdCache.set(fullName, interiorId);
    }

    public get mode (): TvInteriorIdCacheMode { return this._mode; }
    public set mode (value: TvInteriorIdCacheMode) { this._mode = value; }

    public isTvChartMode (): boolean { return this._mode === TvInteriorIdCacheMode.TvChart; }
    public isTvTradingPlatformMode (): boolean { return this._mode === TvInteriorIdCacheMode.TvTradingPlatform; }
}

export const TvInteriorIdCache = new _TvInteriorIdCache();
