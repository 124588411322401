// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MAMode } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iBearsPower extends iBuildInIndicator {
    public FMaPeriod: any;
    public FEMA: ExpandDoubleVector = new ExpandDoubleVector();

    constructor (maPeriod, priceType) {
        super(1);
        this.FMaPeriod = maPeriod;
        this.FPriceType = priceType;
    }

    override get Name (): string { return 'iBearsPower'; }

    override get Key () { return this.DefaultKey + this.FMaPeriod + this.FPriceType; }

    public override Refresh (count, newThread): void {
        this.FEMA.Dispose();
        this.FEMA = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override NextBar (callBound): void {
        this.FEMA.Add(0.0);
        super.NextBar(callBound);
    }

    public override OnQuote (): void {
        const price = this.GetPrice(this.FPriceType, 0);
        this.FEMA[this.FEMA.Length - 1] = price;
        const Parent = this.Parent;
        if (!Parent) {
            return;
        }
        if (this.FMaPeriod > this.Count - 1) {
            return;
        }

        this.SetValue(this.GetPrice(PriceType.Low, 0) - IndicatorFunctions.CallMovingFunction(MAMode.EMA, this.FEMA, this.FMaPeriod, 1));
    }
}
