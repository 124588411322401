// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraComboBox } from './TerceraComboBox';

export class TerceraWidthComboBox extends TerceraComboBox {
    constructor () {
        super();
    }

    public override getType (): string { return 'TerceraWidthComboBox'; };

    public override oninit (): void {
        super.oninit();
        const widthItems = [];
        for (let i = 0; i < 10; i++) {
            const value = i + 1;
            widthItems.push({
                style: 'js-WidthStyle-' + value + 'px',
                // text: value + 'px', //not need
                value
            });
        }
        void this.set({ items: widthItems, listWidth: 135, usePNGView: true, imagewidth: 90, updateComboBoxClass: 'upClassWidthComboBox' });
    };
}

TerceraComboBox.extendWith(TerceraWidthComboBox, {
    data: function () {
        return {
            showLastValue: true
        };
    }
});
