// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type LibrarySessionId, type LibrarySubsessionInfo } from '../charting_library';
import { TvSessionIdEnum } from './TvEnums';

export class TvSubsessionInfo implements LibrarySubsessionInfo {
    public description = '';
    public id: LibrarySessionId = TvSessionIdEnum.regular;
    public session = '';

    setCorrection (correction) {
        if (correction) {
            this['session-correction'] = correction;
        }
    }

    getCorrection () {
        return this['session-correction'];
    }
}
