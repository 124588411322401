// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type XYSeriesData } from '../../../../../Chart/Series/TerceraChartXYSeries';

export class VolatilityLabChartData {
    public priceStep: number = 0;
    public pricePrecision: number = 0;
    public xScaleLegend: Map<number, string> = new Map<number, string>();
    public readonly underlierPrice: number = NaN;
    public readonly seriesMapDataSeries: Map<Date, XYSeriesData[]> = new Map<Date, XYSeriesData[]>();

    constructor (priceStep: number, pricePrecision: number, underlierPrice: number, seriesMapDataSeries: Map<Date, XYSeriesData[]>, xScaleLegend: Map<number, string>) {
        this.priceStep = priceStep;
        this.pricePrecision = pricePrecision;
        this.xScaleLegend = xScaleLegend;
        this.underlierPrice = underlierPrice;
        this.seriesMapDataSeries = seriesMapDataSeries;
    }
}
