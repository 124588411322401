// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Leve2QuoteType } from '../../Utils/Enums/Constants';
import { MathUtils } from '../../Utils/MathUtils';

export enum Level2Type {
    // Коллекция бидов - сортировка по убыванию
    Bids = 0,
    // Коллекция асков - сортировка по возрастанию
    Asks = 1
}
export enum CompareType {
    Price = 0,
    PriceSize = 1,
    PriceTimeSize = 2
}
export enum Filter {
    None = 0,
    MinusOne = 1,
    Time = 2,
    Size = 4
}

export class Level2Collection {
    public Level2Type: Level2Type;
    public SpreadItem: any;
    public comparer: Level2ItemComparer;
    public instrumentPrecision: any;
    public reverse: boolean;

    public topLevelItem = null;
    public change = false;

    public itemsArr: Level2Item[] = [];
    public items: Record<string, Level2Item> = {};

    constructor (si, l2Type: Level2Type) {
        this.Level2Type = l2Type;
        this.SpreadItem = si;
        this.comparer = new Level2ItemComparer(this.Level2Type === Level2Type.Bids);

        const ins = si ? si.SpreadInstrument : null;
        this.instrumentPrecision = ins === null ? -1 : ins.getPrecision();
        this.reverse = this.Level2Type === Level2Type.Bids;
    }

    public static Synchronized (si, l2Type): Level2Collection {
        const l2Collect = new Level2Collection(si, l2Type);
        return l2Collect;
    }

    public Clear (): void {
        this.itemsArr = [];
        this.items = {};
    }

    public Add (quoteMsg): boolean {
    // Добавление в список

        // Проверка цены, нужно ли удалить запись из таблицы
        const needToBeRemoved: boolean = (isNaN(quoteMsg.Size)) || quoteMsg.Closed;

        const topLevel2QuoteWasChanged = false;

        if (needToBeRemoved) {
            if (!isNullOrUndefined(this.items[quoteMsg.MMID])) {
                delete this.items[quoteMsg.MMID];
            }
        } else {
            const it = new Level2Item(quoteMsg, this.instrumentPrecision);
            this.items[quoteMsg.MMID] = it;
        }

        this.change = true;

        return topLevel2QuoteWasChanged;
    }

    public GetSortedList (isBid: boolean): Level2Item[] {
        const keys = Object.keys(this.items);
        const keysLen = keys.length;
        if (keysLen !== this.itemsArr.length) {
            this.itemsArr = Array(keysLen);
        }

        for (let i = 0; i < keysLen; i++) {
            this.itemsArr[i] = this.items[keys[i]];
        }

        if (isBid) {
            this.itemsArr.sort(Level2Collection.PriceComparerBid);
        } else {
            this.itemsArr.sort(Level2Collection.PriceComparerAsk);
        }

        return this.itemsArr;
    }

    public static PriceComparerBid (a, b) {
    // if (isNaN(a.Quote.Price)) //#85713
    //     return 1;
    // if (isNaN(b.Quote.Price)) //#85713
    //     return -1;
        const xQuoteType = a.Quote ? a.Quote.QuoteType : null;
        const yQuoteType = b.Quote ? b.Quote.QuoteType : null;
        if (xQuoteType != Leve2QuoteType.None || yQuoteType != Leve2QuoteType.None) {
            return Level2Collection.PriceComparerByType(xQuoteType, yQuoteType);
        }

        if (a.Quote.Price < b.Quote.Price) {
            return 1;
        }

        return -1;
    }

    public static PriceComparerAsk (a, b) {
        const xQuoteType = a.Quote ? a.Quote.QuoteType : null;
        const yQuoteType = b.Quote ? b.Quote.QuoteType : null;
        if (xQuoteType != Leve2QuoteType.None || yQuoteType != Leve2QuoteType.None) {
            return Level2Collection.PriceComparerByType(xQuoteType, yQuoteType);
        }

        if (a.Quote.Price > b.Quote.Price) {
            return 1;
        }

        return -1;
    }

    public static PriceComparerByType (xQuoteType, yQuoteType) {
    // #42028 - если с сервера приходит -1, то необходимо такую котировку ставить в последнюю строку стакана.
    // #93376 - перевели с цены на дополнительный филд, так как цены 0 и -1 - это теперь валидные цены

        if (xQuoteType == yQuoteType) {
            return 0;
        }

        if (xQuoteType == Leve2QuoteType.Indicative) {
            return -1;
        } else if (xQuoteType == Leve2QuoteType.WithoutPrice) {
            return 1;
        }

        if (yQuoteType == Leve2QuoteType.Indicative) {
            return 1;
        } else if (yQuoteType == Leve2QuoteType.WithoutPrice) {
            return -1;
        }
    }
}

class Level2ItemComparer {
    public reverse: any;
    public CompateType: CompareType;
    public filter: any;

    constructor (reverse, CompateType?: CompareType, filter?) {
        this.reverse = reverse;
        this.CompateType = CompateType || CompareType.Price;
        this.filter = filter || Filter.None;
    }
}

export class Level2Item {
    // alexb: закешировали некоторые данные, чтобы level2 comparer быстрей работал
    public CachedRoundPrice: any;
    public CachedDateTime: any;

    public instrumentPrecision: any;

    // список MMID для аггрегированной котировки, которая используется для генерации Level1 из Level2
    public MMIDs: any;

    // Ссылка на коировку
    public Quote: any = null;
    public AggregatedVolume: number;
    public VWAPrice: number;

    constructor (qMsg, instrumentPrecision, cacheDataForComparer = true) {
        this.Quote = qMsg;
        this.instrumentPrecision = instrumentPrecision;

        if (cacheDataForComparer && this.Quote != null) {
            this.UpdateRoundPrice();

            if (this.Quote != null) {
                this.CachedDateTime = this.Quote.cTime.getTime();
            }
        }
    }

    public UpdateRoundPrice (): void {
        if (this.Quote === null) {
            return;
        }

        this.CachedRoundPrice = this.instrumentPrecision == -1
            ? this.Quote.Price
            : MathUtils.Round(this.Quote.Price, this.instrumentPrecision);
    }
}
