// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectPortfolioSystemMessage } from '../../../../Utils/DirectMessages/DirectPortfolioSystemMessage';

export class PortfolioSystemMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet): DirectPortfolioSystemMessage[] {
        const message = new DirectPortfolioSystemMessage();

        message.PortfolioManagerLogin = fieldSet.GetValue(FieldsFactory.FIELD_LOGIN);
        message.PortfolioSystemID = fieldSet.GetValue(FieldsFactory.FIELD_PORTFOLIO_SYSTEM_ID);
        message.PortfolioSystemName = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        message.StatusOut = fieldSet.GetValue(FieldsFactory.FIELD_STATUS);

        return [message];
    }
}
