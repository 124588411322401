// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ContainerControl } from './ContainerControl';
import { AccountsComboBoxTemplate } from '../../templates';
import { AccountMenuItem } from '@shared/commons/AccountWidget/AccountMenuItem';
import { AccountMenuItemsHelper } from '@shared/commons/AccountWidget/AccountMenuItemsHelper';
import { type Account } from '@shared/commons/cache/Account';
import { type IContextMenuItem } from '../UtilsClasses/IContextMenuItem';
import { Resources } from '@shared/localizations/Resources';
import { type AccountSelector } from './AccountSelector';

export class AccountsComboBox extends ContainerControl {
    public lostFocusOnOutsideClick: boolean = true;
    private static readonly WIDTH = 206;
    private static readonly SPACING_ADJUSTMENT = 1;
    private static readonly DROPDOWN_BOTTOM_OFFSET = 17;

    get accModeWidth (): number { return this.get('accModeWidth'); }
    set accModeWidth (value: number) { void this.set({ accModeWidth: value }); }

    constructor () {
        super();
        this.onDropDownVisibleChanged = this.onDropDownVisibleChanged.bind(this);
        this.onAccountChanged = this.onAccountChanged.bind(this);
    }

    public override getType (): string { return 'AccountsComboBox'; }

    public override oninit (): void {
        this.observe('selectedItems', this.onSelectedItemsChanged); // initializes and controls the selected elements for multi-selection in AccountsComboBox (as in CopyTrading panel)
    }

    public override oncomplete (): void {
        super.oncomplete();
        void this.populateAsync().then(() => {
            this.observe('showItems', this.onDropDownVisibleChanged);
            this.observe('selectedItem', this.onAccountChanged); // initializes and controls the selected element for single selection in AccountsComboBox (as in AccountSelector from AccountWidget)
            this.observe('excludeAccounts', this.onExcludeAccountsChanged);
        });

        this.updateAccModeWidth();
    }

    public override onMouseDown (event): void {
        super.onMouseDown(event, false);
        const isShown: boolean = this.get('showItems');
        if (isShown) {
            void this.set({ showItems: false });
            return;
        }
        this.showItems();
    }

    public override lostFocus (): void {
        super.lostFocus();
        void this.set({ showItems: false });
    }

    public getMultiSelectText (): string {
        const selectedItems = this.get('selectedItems') || [];

        if (selectedItems.length === 0) {
            return Resources.getResource('noSelection');
        }

        if (selectedItems.length === 1) {
            return selectedItems[0].toString();
        }

        const accMenuItems = this.get('accMenuItems') || [];

        if (selectedItems.length === accMenuItems.length) {
            return Resources.getResource('PopupMultiCkeckBox.All');
        }

        return Resources.getResource('partialSelection', [`${selectedItems.length}/${accMenuItems.length}`]);
    }

    public override getContextMenuItem (): IContextMenuItem {
        return {
            text: Resources.getResource('accountsLookup.ContextMenuItem'),
            style: 'js-button-accountsLookup',
            event: () => {
                const accLookup = (this.parent as AccountSelector)?.getLookup();
                if (accLookup != null) {
                    accLookup.showForm(this.getX(), this.getY());
                }
            }
        };
    }

    private onSelectedItemsChanged (selectedItems: Account[]): void {
        const isMultiSelect = this.get('isMultiSelect');
        if (!isMultiSelect) { return; }

        if (selectedItems == null || selectedItems.length === 0) {
            this.changeSelection(null);
            return;
        }

        selectedItems.forEach((account) => {
            const item = new AccountMenuItem(account);
            this.changeSelection(item);
        });
    }

    private updateAccModeWidth (): void {
        const items = this.get('accMenuItems');
        items.forEach(item => {
            const itemWidth = item.GetModeWidth();
            if (itemWidth > this.accModeWidth) {
                this.accModeWidth = itemWidth;
            }
        });
    }

    private changeSelection (newSelected: AccountMenuItem): void {
        const accMenuItems: AccountMenuItem[] = this.get('accMenuItems');
        const isMultiSelect = this.get('isMultiSelect');
        let selectedItems = this.get('selectedItems') || [];

        if (isMultiSelect) {
            if (newSelected != null) {
                newSelected.Selected = !newSelected.Selected;
                if (newSelected.Selected) {
                    if (!selectedItems.some(acc => acc === newSelected.GetAccount())) {
                        selectedItems.push(newSelected.GetAccount()); // Add only if it doesn't already exist in selectedItems
                    }
                } else {
                    selectedItems = selectedItems.filter(acc => acc !== newSelected.GetAccount()); // Remove if it exists
                }
            }
            // Update Selected state in accMenuItems
            accMenuItems.forEach(item => {
                item.Selected = selectedItems.some(acc => acc === item.GetAccount());
            });
        } else { // Single selection mode
            accMenuItems.forEach(item => {
                item.Selected = item.equal(newSelected);
            });
            selectedItems = newSelected.Selected ? [newSelected.GetAccount()] : []; // Update selectedItems with only one selected account in single-selection mode
        }

        void this.set({ accMenuItems, selectedItems, selectedItemView: newSelected });
    }

    private accountItemClick (item: AccountMenuItem): boolean {
        if (isNullOrUndefined(item)) {
            return;
        }
        this.changeSelection(item);

        if (!this.get('isMultiSelect')) {
            void this.set({
                selectedItem: item.GetAccount(),
                showItems: false
            });
        }
        return false; // cancelBubble
    }

    protected showItems (): void {
        const myEl = this.find('.js-accountsComboBox');
        if (isNullOrUndefined(myEl)) {
            return;
        }
        const dropDownPosition = this.calculateDropdownPosition(myEl);
        void this.set({
            dropdownLeft: dropDownPosition.left,
            dropdownTop: dropDownPosition.top,
            showItems: true
        });
    }

    private calculateDropdownLeft (isPosAbsolute: boolean, rect: DOMRect, myEl: HTMLElement): number {
        let left = isPosAbsolute ? myEl.clientLeft : myEl.offsetLeft;

        const overflowRight = rect.left + AccountsComboBox.WIDTH - window.innerWidth;
        if (overflowRight > 0) left -= overflowRight;

        return left;
    }

    private calculateDropdownTop (isPosAbsolute: boolean, rect: DOMRect, myEl: HTMLElement): number {
        const dropdownItems: AccountMenuItem[] = this.get('accMenuItems') ?? [];
        const height = isPosAbsolute ? myEl.clientHeight : myEl.offsetHeight;
        let dropdownHeight = dropdownItems.length * height;

        if (this.get('needAdditionalDropdownOffset')) {
            dropdownHeight += AccountsComboBox.DROPDOWN_BOTTOM_OFFSET;
        }

        let top = isPosAbsolute ? myEl.clientTop : myEl.offsetTop;

        const overflowBottom = rect.top + height + dropdownHeight - window.innerHeight;
        top = overflowBottom > 0 ? top - dropdownHeight : top + height + AccountsComboBox.SPACING_ADJUSTMENT;

        return top;
    }

    private calculateDropdownPosition (myEl: HTMLElement): { left: number, top: number } {
        const isPosAbsolute: boolean = this.get('isPosAbsolute');
        const rect: DOMRect = myEl.getBoundingClientRect();

        return {
            left: this.calculateDropdownLeft(isPosAbsolute, rect, myEl),
            top: this.calculateDropdownTop(isPosAbsolute, rect, myEl)
        };
    }

    private onExcludeAccountsChanged (): void {
        void this.populateAsync(); // Re-populate the menu items to apply the new excludeAccounts filter
    }

    protected onDropDownVisibleChanged (newValue: boolean, oldValue: boolean): void { /* Virtual */ }

    protected onAccountChanged (newAcc: Account, oldAcc: Account): void {
        if (isNullOrUndefined(newAcc) || newAcc === oldAcc) {
            return;
        }
        const item = new AccountMenuItem(newAcc);
        this.changeSelection(item);
    }

    protected async populateAsync (): Promise<void> {
        const selectedAccounts = this.get('isMultiSelect') === true ? this.get('selectedItems') : [this.get('selectedItem')];
        const Data = AccountMenuItemsHelper.GetDataToFillOut(selectedAccounts);
        const excludeAccounts: Account[] | null = this.get('excludeAccounts');
        // Filter out items with accounts in excludeAccounts
        const filteredItems = excludeAccounts != null
            ? Data.Items.filter(item => !excludeAccounts.some(excludeAcc => excludeAcc === item.GetAccount()))
            : Data.Items;

        await this.set({
            accMenuItems: filteredItems,
            onlyOwns: Data.IsOnlyOwnAccounts,
            addtype: Data.IsAddType
        });
    }
}

ContainerControl.extendWith(AccountsComboBox, {
    template: AccountsComboBoxTemplate,
    data: function () {
        return {
            dropdownLeft: 0,
            dropdownTop: 0,
            needAdditionalDropdownOffset: false,
            excludeAccounts: [], // Account[] - those accounts will NOT be in the drop-down list
            isPosAbsolute: false,
            addtype: true,
            showItems: false,
            selectedItem: null,
            selectedItemView: null,
            accMenuItems: [],
            isMultiSelect: false,
            selectedItems: [],
            accModeWidth: 30
        };
    }
});
