// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
'use strict';
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class PfixQuoteBarGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.QUOTE_BAR_GROUP, null, pfixFieldSet);
    }

    public getTime (): Date | null {
        return this.GetValue(FieldsFactory.FIELD_TIMESTAMP);
    }

    public getBarType (): any {
        return this.GetValue(FieldsFactory.FIELD_BARS_TYPE);
    }

    public getOpen (): number {
        return this.GetValue(FieldsFactory.FIELD_OPEN);
    }

    public getHigh (): number {
        return this.GetValue(FieldsFactory.FIELD_HIGH);
    }

    public getLow (): number {
        return this.GetValue(FieldsFactory.FIELD_LOW);
    }

    public getClose (): number {
        return this.GetValue(FieldsFactory.FIELD_CLOSE_PRICE);
    }

    public getMainClose (): number {
        return this.GetValue(FieldsFactory.FIELD_MAIN_CLOSE_PRICE);
    }

    public getAsk (): number {
        return this.GetValue(FieldsFactory.FIELD_ASK);
    }

    public getAskVolume (): number | null {
        const fieldAskVolume = this.GetValue(FieldsFactory.FIELD_ASK_VOLUME);
        if (fieldAskVolume !== null) {
            return fieldAskVolume;
        } else {
            const fieldAskSize = this.GetValue(FieldsFactory.FIELD_ASK_SIZE);
            if (fieldAskSize !== null) {
                return fieldAskSize;
            }
        }
        return null;
    }

    public getBid (): number {
        return this.GetValue(FieldsFactory.FIELD_BID);
    }

    public getBidVolume (): number | null {
        const fieldBidVolume = this.GetValue(FieldsFactory.FIELD_BID_VOLUME);
        if (fieldBidVolume !== null) {
            return fieldBidVolume;
        } else {
            const fieldBidSize = this.GetValue(FieldsFactory.FIELD_BID_SIZE);
            if (fieldBidSize !== null) {
                return fieldBidSize;
            }
        }
        return null;
    }

    public getPrice (): number {
        return this.GetValue(FieldsFactory.FIELD_PRICE);
    }

    public getVolume (): number | null {
        const fieldVolume = this.GetValue(FieldsFactory.FIELD_VOLUME);
        if (fieldVolume !== null) {
            return fieldVolume;
        } else {
            const fieldSize = this.GetValue(FieldsFactory.FIELD_SIZE);
            if (fieldSize !== null) {
                return fieldSize;
            }
        }
        return null;
    }

    public getSettlementPrice (): number {
        return this.GetValue(FieldsFactory.FIELD_SETTLEMENT_PRICE);
    }

    public GetLastQuoteID (): number {
        return this.GetValue(FieldsFactory.FIELD_SUB_ID);
    }

    public GetLastQuoteIdDate (): number {
        return this.GetValue(FieldsFactory.FIELD_SUB_ID_DATE);
    }
}
