// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DayPeriods } from '@shared/utils/Session/DayPeriods';
import { TvTimeZoneHelper } from '../Helpers/TvTimeZoneHelper';
import { TvFullSession } from '../TradingViewPrimitives/TvConstants';
import { TvSessionIdEnum } from '../TradingViewPrimitives/TvEnums';
import { TvSubsessionInfo } from '../TradingViewPrimitives/TvSubsessionInfo';
import { DateTime } from 'luxon';
import { Session } from '@shared/utils/Session/Session';
import { type SessionPeriod } from '@shared/utils/Session/SessionPeriod';

export class TvSessionConvertor {
    // TODO: fix use Day Start Offset
    convertToTvSession (session: Session, dayPeriodFilter) {
        const sessionPeriods = session.getSessionPeriods();
        const timeZoneId = session.TimeZoneID;
        const timeOffset = session.TimeOffset;
        if (isNullOrUndefined(sessionPeriods) || sessionPeriods.length == 0) { return TvFullSession; }

        const DAYS_ON_A_WEEK = 7;
        let result = '';

        for (let i = 0; i < DAYS_ON_A_WEEK; i++) {
            let daySchedule = this.getSessionPeriodsSchedule(sessionPeriods, timeZoneId, timeOffset, i, dayPeriodFilter);

            if (daySchedule) {
                daySchedule += ':' + (i + 1);

                if (i + 1 < DAYS_ON_A_WEEK) { daySchedule += '|'; }

                result += daySchedule;
            }
        }

        if (result && result.endsWith('|')) {
            result = result.slice(0, -1);
        }

        return result;
    }

    getSessionPeriodsSchedule (sessionPeriods: SessionPeriod[], 
        timeZoneID: string,
        timeOffset: number,
        dayOfWeek, 
        dayPeriodFilter = null) 
    {
        
        let begin;
        let end;
        for (const element of sessionPeriods) {
            const sessionPeriod = element;
            if (sessionPeriod.isBeforeOrAfterMarket()) {
                continue;
            }

            if (dayPeriodFilter != null && dayPeriodFilter !== sessionPeriod.dayPeriod) {
                continue;
            }

            const beginMS = sessionPeriod.GetBegin(dayOfWeek).getTime();
            const endMS = sessionPeriod.GetEnd(dayOfWeek).getTime();

            if (beginMS === endMS) {
                continue;
            }

            const timeZone = TvTimeZoneHelper.getTimeZoneForTV(timeZoneID);
            const daytimeOffset = timeOffset;

            if (!begin) {
                begin = this.convertTimeToTVSessionPartString(timeZone, beginMS, daytimeOffset, dayOfWeek);
            }

            end = this.convertTimeToTVSessionPartString(timeZone, endMS, daytimeOffset, dayOfWeek);
        }

        if (!begin || !end) {
            return '';
        }

        const periodSchedule = begin + '-' + end;
        return periodSchedule;
    }

    convertTimeToTVSessionPartString (timeZone, milliseconds, dayStartOffset, dayOfWeek) {
        const nearestDay = this.#findNextWeekday(dayOfWeek, timeZone);
        const nearestDayWitOffset = nearestDay.plus({ minutes: dayStartOffset });
        const nearestDayWithTime = nearestDayWitOffset.plus({ milliseconds: milliseconds });
        const formattedTime = nearestDayWithTime.toFormat('HHmm');
        if (nearestDayWithTime < nearestDay) {
            return formattedTime + 'F';
        }

        return formattedTime;
    }

    /**
     * Находит ближайший день недели по индексу. Мне нужна ближайшая дата как привязка для времени.
     * @param {number} targetWeekday - индекс дня недели (0 - воскресенье, 1 - понедельник, 6 - суббота).
     * @param {string} timeZone - таймзона сервера
     * @returns {luxon.DateTime} - дата ближайшего дня.
     */
    #findNextWeekday (targetWeekday, timeZone) {
        const now = DateTime.local();

        // Определите, сколько дней осталось до целевого дня недели
        let daysTillTarget = targetWeekday - now.weekday + 1;
        if (daysTillTarget <= 0) {
            daysTillTarget += 7;
        }

        const nextTargetDay = now.plus({ days: daysTillTarget });
        const year = nextTargetDay.year;
        const month = nextTargetDay.month;
        const day = nextTargetDay.day;

        const nextTargetDayInSessionTimeZone = DateTime.fromObject({ year, month, day }, { zone: timeZone });
        return nextTargetDayInSessionTimeZone;
    }

    getHolidays (session: Session) {
        const holidays = session.getHolidays().values();
        const holidaysDateArray: string[] = [];
        for (const holiday of holidays) {
            const formattedDate = this.#formatHolidayDate(holiday.year, holiday.month, holiday.day);

            if (holiday.IsNotWorking() || holiday.IsShorted()) {
                holidaysDateArray.push(formattedDate);
            }
        }

        return holidaysDateArray.join(',');
    }

    getHolidaysCorrections (session: Session, dayPeriodFilter) {
        const timeZoneId = session.TimeZoneID;
        const timeOffset = session.TimeOffset;
        const holidays = session.getHolidays().values();
        const holidaysSessionArray: string[] = [];
        for (const holiday of holidays) {
            const formattedDate = this.#formatHolidayDate(holiday.year, holiday.month, holiday.day);

            if (holiday.IsShorted()) {
                const dayOfWeek = holiday.getDayOfWeek();
                const tvSessionString = this.getSessionPeriodsSchedule(holiday.sessionPeriods, 
                    timeZoneId,
                    timeOffset,
                    dayOfWeek, 
                    dayPeriodFilter);
                if (tvSessionString) {
                    holidaysSessionArray.push(`${tvSessionString}:${formattedDate}`);
                }
            }
        }

        return holidaysSessionArray.join(';');
    }

    #formatHolidayDate (year: number, month: number, day: number): string {
        const yyyy = year.toString();
        const mm = month.toString();
        const dd = day.toString();

        return yyyy + (mm[1] ? mm : '0' + mm[0]) + (dd[1] ? dd : '0' + dd[0]);
    }

    getSubsessions (session: Session): TvSubsessionInfo[] {
        const subsessions: TvSubsessionInfo[] = [];
        const regularSubsession = new TvSubsessionInfo();
        regularSubsession.description = 'Regular Trading Hours';
        regularSubsession.id = TvSessionIdEnum.regular;
        regularSubsession.session = this.convertToTvSession(session, DayPeriods.MAIN);
        regularSubsession.setCorrection(this.getHolidaysCorrections(session, DayPeriods.MAIN));
        subsessions.push(regularSubsession);

        const extendedSubsession = new TvSubsessionInfo();
        extendedSubsession.description = 'Extended Trading Hours';
        extendedSubsession.id = TvSessionIdEnum.extended;
        extendedSubsession.session = this.convertToTvSession(session, null);
        extendedSubsession.setCorrection(this.getHolidaysCorrections(session, null));
        subsessions.push(extendedSubsession);

        const preMarketSession = this.convertToTvSession(session, DayPeriods.PRE_OPEN);
        if (preMarketSession) {
            const premarketSubsession = new TvSubsessionInfo();
            premarketSubsession.description = 'Premarket';
            premarketSubsession.id = TvSessionIdEnum.premarket;
            premarketSubsession.session = preMarketSession;
            premarketSubsession.setCorrection(this.getHolidaysCorrections(session, DayPeriods.PRE_OPEN));
            subsessions.push(premarketSubsession);
        }

        const postMarketSession = this.convertToTvSession(session, DayPeriods.POST_CLOSE);
        if (postMarketSession) {
            const postmarketSubsession = new TvSubsessionInfo();
            postmarketSubsession.description = 'Postmarket';
            postmarketSubsession.id = TvSessionIdEnum.postmarket;
            postmarketSubsession.session = postMarketSession;
            postmarketSubsession.setCorrection(this.getHolidaysCorrections(session, DayPeriods.POST_CLOSE));
            subsessions.push(postmarketSubsession);
        }

        return subsessions;
    }
}
