// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { NewAccountDetailsPanelTemplate } from '../../../templates';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { AccountWidgetAccountsController } from '@shared/commons/AccountWidget/AccountWidgetAccountsController';
import { AccountDetailsGroupsEnum } from '@shared/utils/Enums/AccountDetailsEnum';
import { type Account } from '@shared/commons/cache/Account';
import { AccountDetailsGroupItem } from '@shared/commons/cache/VerticalPanel/AccountDetails/AccountDetailsGroupItem';
import { Control } from '../../elements/Control';
import { HtmlScroll } from '@shared/commons/HtmlScroll';
import { AccountWidgetAccountDetailsPanelController } from '@shared/commons/AccountWidget/AccountWidgetAccountDetailsPanelController';
import { type AccountWidgetCoordinates } from '@shared/commons/AccountWidget/AccountWidgetCoordinates';
import { ApplicationPanel } from '../ApplicationPanel';
import { AccountWidgetAssetsController } from '@shared/commons/AccountWidget/AccountWidgetAssetsController';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { DataCache } from '@shared/commons/DataCache';
import { type AssetBalance } from '@shared/commons/cache/AssetBalance';
import { type Asset } from '@shared/commons/cache/Asset';
import { AccountAdditionalInfoDestinationPanel } from '@shared/utils/Account/AdditionalInfoItem';
import { VerticalPanelDataProviderItem } from '../../cache/VerticalPanel/VerticalPanelDataProviderItem';
import { DynProperty } from '@shared/commons/DynProperty';

export class NewAccountDetailsPanel extends ApplicationPanel {
    private groupItems: AccountDetailsGroupItem[] = [];
    private mouseOnPanel: boolean;
    private readonly myAccLinkBtn: any;
    private readonly accountDinamicProperties: any;
    private detailsGroupItemStateMap = new Map<number, boolean>();
    private readonly arrayGroups: AccountDetailsGroupsEnum[] = [
        AccountDetailsGroupsEnum.General,
        AccountDetailsGroupsEnum.Margin,
        AccountDetailsGroupsEnum.AccountActivity,
        AccountDetailsGroupsEnum.TodayResult,
        AccountDetailsGroupsEnum.RiskManagement
    ];

    public getType (): PanelNames {
        return PanelNames.NewAccountDetailsPanel;
    };

    constructor () {
        super();
    }

    oncomplete (): void {
        super.oncomplete();

        AccountWidgetAccountDetailsPanelController.isMouseOnPanelHandler = () => { return this.isMouseOnPanel(); };

        this.on('panelOver', this.mouseOver);
        this.on('panelLeave', this.mouseLeave);
        this.on('accountDetailsItemDown', this.accountDetailsItemDown);
        this.on('accountDetailsItemMove', this.accountDetailsItemMove);
        this.on('accountDetailsItemUp', this.accountDetailsItemUp);
        this.observe('selectedAsset', this.onSelectedAssetChanged);

        this.scrollAdd();
        this.setDetailsGroupItemState();
        this.createAccountDetailsGroupItem();

        AccountWidgetAccountsController.subscribeOnAccountChanged(this.onAccountChanged);
        AccountWidgetAccountDetailsPanelController.ShowAccountDetailsPanel.Subscribe(this.show, this);
        AccountWidgetAccountDetailsPanelController.HideAccountDetailsPanel.Subscribe(this.hide, this);
        this.onAccountChanged(AccountWidgetAccountsController.Account, null);
        AccountWidgetAccountDetailsPanelController.onAccountLinkOut.Subscribe(this.accountLink_Out, this);
        AccountWidgetAccountDetailsPanelController.onAssetSet.Subscribe(this.selectAsset, this);
        SessionSettings.AdditionalPropertiesHandlers.push(this.Properties);
        Control.Ticker.Subscribe(this.onUpdate, this);
    }

    onteardown (): void {
        AccountWidgetAccountsController.unsubscribeOnAccountChanged(this.onAccountChanged);
        AccountWidgetAccountDetailsPanelController.ShowAccountDetailsPanel.UnSubscribe(this.show, this);
        AccountWidgetAccountDetailsPanelController.HideAccountDetailsPanel.UnSubscribe(this.hide, this);
        AccountWidgetAccountDetailsPanelController.onAccountLinkOut.UnSubscribe(this.accountLink_Out, this);
        AccountWidgetAccountDetailsPanelController.onAssetSet.UnSubscribe(this.selectAsset, this);
        Control.Ticker.UnSubscribe(this.onUpdate, this);
    }

    private accountDetailsItemDown (context, typePointer): void {
        AccountWidgetAccountDetailsPanelController.onTableMouseDown(typePointer);
    }

    private accountDetailsItemMove (context): void {
        AccountWidgetAccountDetailsPanelController.onTableMouseMove(context);
    }

    private accountDetailsItemUp (context): void {
        AccountWidgetAccountDetailsPanelController.onTableMouseUP(context);
    }

    private hide (): void {
        AccountWidgetAccountDetailsPanelController.onPanelClose();
        void this.set({ visible: false, showModalMask: false });
    }

    private show (coordinates: AccountWidgetCoordinates): void {
        AccountWidgetAccountDetailsPanelController.onPanelOpen();
        this.createAccountDetailsGroupItem();
        void this.set({
            visible: true,
            showModalMask: true,
            left: coordinates.positionX,
            top: coordinates.positionY
        });
    }

    private readonly onAccountChanged = (newAccount: Account, oldAccount: Account): void => {
        if (isNullOrUndefined(newAccount)) return;
        void this.set('account', newAccount);
        this.getDynamicItems();

        AccountWidgetAssetsController.Account = newAccount;
        const items: Asset[] = AccountWidgetAssetsController.AssetsArray;
        const assetsVisible = items.length > 1;
        const assetsItems = this.toComboBoxItems(items);
        void this.set({ assetsVisible, assetsItems });
        this.createAccountDetailsGroupItem();
    };

    private toComboBoxItems (assetIds: Asset[]): any[] {
        const arr = [];
        for (const asset of assetIds) {
            arr.push({ text: asset.Name, value: asset });
        }

        const funcSort = function (a, b): number {
            if (a.text.toLowerCase() > b.text.toLowerCase()) { return 1; } else { return -1; }
        };

        arr.sort(funcSort);
        return arr;
    };

    public accountLink_Out (newSubscriber: boolean, account?: Account, sameAccs?: boolean): void {
        if (!account) {
            if (!AccountWidgetAssetsController.Account) { return; }
            account = AccountWidgetAssetsController.Account;
        }
        if (!this.myAccLinkBtn) { return; }

        this.accountLink_Out(newSubscriber, account);

        if (!sameAccs) { SessionSettings.save(); }
    };

    public accountLink_In (accId): boolean {
        AccountWidgetAccountsController.Account = DataCache.GetAccountById(accId);
        return true;
    };

    private selectAsset (assetId): void {
        const items = this.get('assetsItems');
        for (const item of items) {
            if (item.value.Id === assetId) {
                void this.set('selectedAsset', item);
                return;
            }
        }
    };

    private onSelectedAssetChanged (selectedItem): void {
        if (isNullOrUndefined(selectedItem?.value)) { return; };

        const account = AccountWidgetAccountsController.Account;
        let asset: AssetBalance = null;
        if (AccountWidgetAssetsController.AssetsArray.length > 1) {
            asset = account.assetBalances[selectedItem.text];
        }

        if (!isNullOrUndefined(asset)) {
            AccountWidgetAccountDetailsPanelController.setDataSourceAsset(asset);
            AccountWidgetAssetsController.SelectedAsset = selectedItem.value;
        }
    };

    public isShowed (): boolean {
        return this.get('visible');
    };

    private isMouseOnPanel (): boolean {
        return this.mouseOnPanel;
    };

    private mouseOver (containerContext): void { this.mouseOnPanel = true; }
    private mouseLeave (containerContext): void { this.mouseOnPanel = false; }

    private createAccountDetailsGroupItem (): void {
        const dataSource = AccountWidgetAccountDetailsPanelController.DataSource;
        this.groupItems = [];
        if (isNullOrUndefined(dataSource)) {
            return null;
        }

        for (const type of this.arrayGroups) {
            const controller = dataSource.getGroupController(type);
            const isOpenGroup = this.getValueDetailsGroupItemState(type);
            this.groupItems.push(new AccountDetailsGroupItem(type, controller, isOpenGroup));
        }

        void this.set({ groupItems: this.groupItems });
        this.subscribeGroups();
    };

    private subscribeGroups (): void {
        if ((isNullOrUndefined(this.groupItems)) || this.groupItems.length === 0) {
            return;
        };

        for (const group of this.groupItems) {
            group.subscribeChangeOpenedStateEmit(this.onDetailsGroupItemStateChange);
        }
    };

    private readonly onUpdate = (): void => {
        if (!this.get<boolean>('visible')) {
            return;
        }
        if ((isNullOrUndefined(this.groupItems)) || this.groupItems.length === 0) {
            return;
        };

        for (const group of this.groupItems) {
            group.onUpDateEmit();
        }
    };

    private scrollAdd (): void {
        const itemContainer = this.find('.account-details-panel-items-container');
        if (!isNullOrUndefined(itemContainer)) {
            HtmlScroll.addScroll(itemContainer);
        }
    };

    private getDynamicItems (): any[] {
        const res = [];
        const account = this.get('account');
        if (isNullOrUndefined(account)) {
            return;
        }

        const accountAdditionalInfo = account.AccountAdditionalInfo;
        if (!isNullOrUndefined(accountAdditionalInfo)) {
            for (let accountAdditionalInfoItem in accountAdditionalInfo) {
                accountAdditionalInfoItem = accountAdditionalInfo[accountAdditionalInfoItem];
                const item = this.getAdditinalInfoItem(accountAdditionalInfoItem, this.accountDinamicProperties);
                if (item !== null) {
                    res.push(item);
                }
            }
        }

        const assetBalance = account.AssetBalance;
        if (!isNullOrUndefined(assetBalance)) {
            const assetAdditionalInfo = assetBalance.AssetAdditionalInfo;
            if (!isNullOrUndefined(assetAdditionalInfo)) {
                for (let assetAdditionalInfoItem in assetAdditionalInfo) {
                    assetAdditionalInfoItem = assetAdditionalInfo[assetAdditionalInfoItem];
                    const item = this.getAdditinalInfoItem(assetAdditionalInfoItem, this.accountDinamicProperties);
                    if (item !== null) {
                        res.push(item);
                    }
                }
            }
        }

        res.sort(function (a, b) { return a.SortIndex - b.SortIndex; });
        return res;
    };

    private getAdditinalInfoItem (accountAdditionalInfoItem, accountDinamicProperties): VerticalPanelDataProviderItem {
        if (accountAdditionalInfoItem.Destination === AccountAdditionalInfoDestinationPanel.AllPanels ||
        accountAdditionalInfoItem.Destination === AccountAdditionalInfoDestinationPanel.AccountsDetails) {
            const item = new VerticalPanelDataProviderItem();
            item.Group = accountAdditionalInfoItem.GroupInfo;
            item.GroupIndex = accountAdditionalInfoItem.GroupIndex;
            item.SortIndex = accountAdditionalInfoItem.SortIndex; // sort index inside group
            item.LocalizationKey = accountAdditionalInfoItem.NameKey;
            item.Id = accountAdditionalInfoItem.APIKey;
            item.tooltipKey = accountAdditionalInfoItem.ToolTipKey;
            item.visible = accountAdditionalInfoItem.Visible;

            if (!isNullOrUndefined(accountDinamicProperties)) {
            // copy properties
                const baseSesionItem = accountDinamicProperties[accountAdditionalInfoItem.APIKey];
                if (!isNullOrUndefined(baseSesionItem)) {
                    item.Apply(baseSesionItem);
                }

                accountDinamicProperties[accountAdditionalInfoItem.APIKey] = item;
            }

            return item;
        }
    };

    private setDetailsGroupItemState (): void {
        if (this.detailsGroupItemStateMap.size === 0) {
            for (const id of this.arrayGroups) {
                this.detailsGroupItemStateMap.set(id, true);
            }
        }
    };

    private readonly onDetailsGroupItemStateChange = (itemId: number, isOpened: boolean): void => {
        this.detailsGroupItemStateMap.set(itemId, isOpened);
        SessionSettings.save();
    };

    private getValueDetailsGroupItemState (itemId: number): boolean {
        return this.detailsGroupItemStateMap.get(itemId);
    };

    public Properties = (): DynProperty[] => {
        const properties: DynProperty[] = [];
        properties.push(new DynProperty('DetailsGroupItemStateMap', this.mapToJson(this.detailsGroupItemStateMap), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        return properties;
    };

    public setSavedSetting (propertie: string): void {
        const DetailsGroupItemStateMap = this.jsonToMap(propertie);
        if (!isNullOrUndefined(DetailsGroupItemStateMap)) {
            this.detailsGroupItemStateMap = DetailsGroupItemStateMap;
            this.createAccountDetailsGroupItem();
        }
    };

    private mapToJson (map: Map<number, boolean>): string {
        const obj = Object.fromEntries(map);
        return JSON.stringify(obj);
    };

    private jsonToMap (jsonStr: string): Map<number, boolean> {
        if (!isValidString(jsonStr)) { return null; }
        const obj = JSON.parse(jsonStr);
        const entriesObj = Object.entries(obj);
        const map = new Map<number, boolean>();
        for (const [key, value] of entriesObj) {
            const tKey = Number(key);
            const tValue = Boolean(value);
            map.set(tKey, tValue);
        }

        return map;
    };
}

ApplicationPanel.extendWith(NewAccountDetailsPanel,
    {
        template: NewAccountDetailsPanelTemplate,
        data: function () {
            return {
                groupItems: null,
                account: null,
                width: 338,
                height: 546,
                visible: false,
                showModalMask: false,
                assetsItems: [],
                assetsVisible: false,
                selectedAsset: null
            };
        }
    });
