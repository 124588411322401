// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../CustomEvents';
import { WatchlistManager } from './WatchlistManager';
import { WatchlistSheetItem } from './WatchlistSheetItem';
import { WatchlistSheetsStorageManager } from './WatchlistSheetsStorageManager';

export class _WatchlistSheets {
    public static itemsChanged = new CustomEvent();

    private _items: WatchlistSheetItem[] = [];

    get items (): WatchlistSheetItem[] { return this._items; }
    set items (_items: WatchlistSheetItem[]) {
        this._items = _items;
        this.onSettingsChanged();
    }

    public restore (newWLSheets: _WatchlistSheets): void {
        for (const key in newWLSheets) {
            if (Object.prototype.hasOwnProperty.call(newWLSheets, key)) {
                this[key] = newWLSheets[key];
            }
        }
        this.onSettingsChanged();
    }

    public getAllSymbolIds (items: WatchlistSheetItem[]): string[] {
        const symbolIds: string[] = [];

        items.forEach(item => {
            symbolIds.push(...item.getSymbolIds());
        });

        return symbolIds;
    }

    public static serialize (items: WatchlistSheetItem[]): string {
        const saveList = items.map((item) => ({
            name: item.name,
            itemsStr: item.getSymbolIds().join(';')
        }));
        return JSON.stringify(saveList);
    }

    public static deserialize (json: string): _WatchlistSheets {
        const wlSheets = new _WatchlistSheets();
        const sheets = JSON.parse(json) as Array<{ name: string, itemsStr?: string }>;

        sheets.forEach((sheet) => {
            if (sheet.name) {
                const symbolIds = sheet.itemsStr?.split(';').filter((symbol) => symbol) ?? [];
                wlSheets.items.push(new WatchlistSheetItem(sheet.name, new Set(symbolIds)));
            }
        });

        return wlSheets;
    }

    protected onSettingsChanged (): void {
        _WatchlistSheets.itemsChanged.Raise();
        WatchlistSheetsStorageManager.wasChanged();
        WatchlistManager.updateStorageItems();
    }
}

export const WatchlistSheets: _WatchlistSheets = new _WatchlistSheets();
