// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/// <summary>
/// Рендерер для раздела Returns Portfolio Management-a
/// </summary>

import { Resources } from '../../Localizations/Resources';
import { Rectangle } from '../../Commons/Geometry';
import { LayersEnum } from '../../Chart/Renderers/TerceraChartBaseRenderer';
import { Color, LinearGradientBrush, Pen, PolyRect, SolidBrush } from '../../Commons/Graphics';
import { TerceraChartDrawingType } from '../../Chart/Utils/ChartConstants';
import { TerceraChartMainPriceRenderer } from '../../Chart/Renderers/TerceraChartMainPriceRenderer';
import { CustomEvent } from '../../Utils/CustomEvents';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { DynProperty } from '../../Commons/DynProperty';
import { DataCache } from '../../Commons/DataCache';

export class PortfolioChartReturnsRenderer extends TerceraChartMainPriceRenderer {
    public UseInProperties: boolean = false;
    public barsClusterHoveredIndex: any = null; // индекс ховер совокупности баров по инструментам
    public barsClusterSelectedIndex: any = null; // выбранный индекс совокупности инструментов (выбран месяц)
    public oneInstrumentBarHoveredIndex: any = null; // если ховер по строке в таблице инструментов

    public barsClusterHoveredIndexCached: any = null; // запоминаем hover/selected index-ы чтобы вычислять изменения в них и менять стиль отрисовки
    public barsClusterSelectedIndexCached: any = null;
    public oneInstrumentBarHoveredIndexCached: any = null;

    public allIns: any = null; // interiorID всех инструментов участвующих в портфолио
    public skipBarsByIns: any = null; // кол-во баров (от начала) по инструментам которые мы не рисуем т.к. они не влазят в область видимости

    public barsClusterHoverIndexChanged = new CustomEvent();
    public barsClusterSelectedIndexChanged = new CustomEvent();

    public assetTexts: any = null;
    public assetFont = ThemeManager.Fonts.Font_12F_regular;
    public assetColorGreen: any = null; // цвет цифр на графике под столбцами при режиме Asset returns statistics
    public assetColorRed: any = null;
    public allBars: any;
    public hoveredBars: any;
    public selectedBars: any;
    public pensWithOpacityStorage: any;
    public brushesWithOpacityStorage: any;
    public pensStorage: any;
    public brushesStorage: any;
    public infoWindowBackgroundBrush: SolidBrush;
    public assetBrushes: Record<any, SolidBrush>;
    public assetPen: Pen;
    zeroXCached: any;
    zeroYCached: any;

    constructor (terceraChart) {
        super(terceraChart);

        //   TerceraChartMainPriceRenderer.call(this, terceraChart);
        this.ChartDrawingType = TerceraChartDrawingType.Line;

        this.Visible = false;

        this.UseInAutoscale = true;
        this.ThemeChanged();

        this.assignLayer = LayersEnum.Tools;
        this.SetClassName('PortfolioChartReturnsRenderer');
    }

    get SolidPriceBrushColor (): string { return this.solidPriceColorBrush.ColorStart; }
    set SolidPriceBrushColor (value) {
        this.solidPriceColorBrush = new LinearGradientBrush(
            0, 0, 1, 1,
            Color.FromArgb(127, value),
            Color.FromArgb(0, value));
    }

    public override Draw (gr, window, windowsContainer, advParams = null): void {
        if (!this.Visible) {
            return;
        }

        const clientRect = window.ClientRectangle;

        gr.save();
        gr.beginPath();
        gr.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip();

        this.RecreateBarsIfNeed(window.PointsConverter);

        const allBars = this.allBars;
        const hoveredBars = this.hoveredBars;
        const selectedBars = this.selectedBars;

        const allIns = this.allIns;
        if (!allIns) return;

        for (let i = 0; i < allIns.length; i++) {
            if (allBars[allIns[i]]) {
                const someBarIsSelected = this.barsClusterSelectedIndex !== null;
                const pen = someBarIsSelected ? this.pensWithOpacityStorage[i] : this.pensStorage[i];
                const brush = someBarIsSelected ? this.brushesWithOpacityStorage[i] : this.brushesStorage[i];

                gr.DrawPolyRect(brush, allBars[allIns[i]], pen);
            }

            if (hoveredBars[allIns[i]]) {
                const pen = this.pensStorage[i];
                const brush = this.brushesWithOpacityStorage[i];

                gr.DrawPolyRect(brush, hoveredBars[allIns[i]], pen);
            }

            if (selectedBars[allIns[i]]) {
                const pen = this.pensStorage[i];
                const brush = this.brushesStorage[i];

                gr.DrawPolyRect(brush, selectedBars[allIns[i]], pen);
            }
        }

        if (this.chart.PortfolioAssetReturnVisible) {
            this.DrawAssetReturns(gr);
        }

        gr.restore();
    }

    public DrawAssetReturns (gr): void {
        if (this.chart.mainWindow.CanZoomIn()) {
            return;
        }

        let data;
        const w = this.chart.w;
        const h = this.chart.h - 50;
        for (let i = 0; i < this.assetTexts.length; i++) {
            data = this.assetTexts[i];
            gr.DrawString(data.text, this.assetFont, data.brush, data.x, data.y, 'center', 'middle'); // проценты assetReturns

            gr.DrawLine(data.linePen, data.lineX1, data.lineY, data.lineX1, data.y + 5); // вертикальные линии - границы таблицы assetReturns
            gr.DrawLine(data.linePen, data.lineX2, data.lineY, data.lineX2, data.y + 5);
        }
        gr.DrawLine(data.linePen, 0, h, w, h); // горизонтальная линия над процентами
    }

    public PrepareBars (pConverter): void {
        const pCache = DataCache.PortfolioCache;
        const data = pCache.instrumentReturnsByMonth;
        const len: any = pCache.monthReturns ? (pCache.monthReturns.length > 0) || 0 : 0;

        if (!data) return;

        if (!this.allIns) {
            this.allIns = pCache.allInstrumentUsedInPortfolio;
        }

        const y0 = pConverter.GetScreenY(0);
        const clientRectangleWidth = pConverter.ww.ClientRectangle.Width;

        let barSpaceWidth = pConverter.GetScreenX(1) - pConverter.GetScreenX(0);

        const barSpacePadding = barSpaceWidth * 0.1;

        barSpaceWidth -= barSpacePadding * 2;

        const skipBarsByIns = {};
        const allBars = {};
        const hoveredBars = {};
        const selectedBars = {};

        const assetTexts = [];

        for (let i = 0; i < len; i++) {
            const monthKey = pCache.GetKeyByMonthReturnsIndex(i);
            const insReturns = data[monthKey];

            if (!insReturns) continue;

            const barSpaceX = pConverter.GetScreenX(i + 1) + barSpacePadding;

            if (barSpaceX + barSpaceWidth < 0) {
                for (let j = 0; j < insReturns.length; j++) {
                    const ins = insReturns[j];
                    const key = ins.interiorID;

                    if (!skipBarsByIns[key]) { skipBarsByIns[key] = 0; }

                    skipBarsByIns[key]++;
                }
                continue;
            }

            if (barSpaceX > clientRectangleWidth) {
                continue;
            }

            const barWidth = barSpaceWidth / insReturns.length;
            const barPadding = 0.2 * barWidth;

            const isHoveredBars = (i === this.barsClusterHoveredIndex);
            const isSelectedBars = (i === this.barsClusterSelectedIndex);

            for (let j = 0; j < insReturns.length; j++) {
                const ins = insReturns[j];
                const key = ins.interiorID;
                const x = barSpaceX + j * barWidth + barPadding;
                const y = y0;
                const h = pConverter.GetScreenY(ins.percent) - y;
                const w = barWidth - barPadding;

                const rect = new Rectangle(x, y, w, h);

                if (isSelectedBars && !isHoveredBars) {
                    if (this.oneInstrumentBarHoveredIndex === key) {
                        this.CreateOrAddToPolyRect(hoveredBars, key, rect);
                    } else {
                        this.CreateOrAddToPolyRect(selectedBars, key, rect);
                    }
                } else if (!isHoveredBars) {
                    this.CreateOrAddToPolyRect(allBars, key, rect);
                } else {
                    this.CreateOrAddToPolyRect(hoveredBars, key, rect);
                }

                this.AddAssetText(assetTexts, rect, ins);
            }
        }

        this.allBars = allBars;
        this.hoveredBars = hoveredBars;
        this.selectedBars = selectedBars;
        this.assetTexts = assetTexts;
        this.skipBarsByIns = skipBarsByIns;
    }

    public AddAssetText (assetTexts, rect, data): void {
        if (!rect) { return; }

        const value = data.percent;
        let textColor = Color.White;

        if (value > 0) {
            textColor = this.assetColorGreen;
        } else
            if (value < 0) {
                textColor = this.assetColorRed;
            }

        let brush;
        if (!this.assetBrushes) {
            this.assetBrushes = {};
        }

        if (this.assetBrushes[textColor]) {
            brush = this.assetBrushes[textColor];
        } else {
            brush = this.assetBrushes[textColor] = new SolidBrush(textColor);
        }

        if (!this.assetPen) {
            this.assetPen = new Pen(ThemeManager.CurrentTheme.PortfolioReturnsChartAssetLine, 1);
        }

        const result = {
            x: rect.X + rect.Width / 2,
            y: this.chart.height - 42,
            brush, // for text
            text: data.Percent,

            lineX1: rect.X,
            lineX2: rect.X + rect.Width,
            lineY: Math.max(rect.Y, rect.Y + rect.Height),
            linePen: this.assetPen
        };

        assetTexts.push(result);
    }

    public RecreateBarsIfNeed (converter): boolean // return true if bars were changed
    {
        const newZeroX = converter.GetScreenX(0);
        const newZeroY = converter.GetScreenY(0);

        if (this.zeroXCached !== newZeroX || this.zeroYCached !== newZeroY) // поменялся масштаб графика или точка обзора - перестраиваем бары по новой
        {
            this.PrepareBars(converter);

            this.zeroXCached = newZeroX;
            this.zeroYCached = newZeroY;

            return true;
        }

        const selectedClusterIndexChanged = this.barsClusterSelectedIndexCached !== this.barsClusterSelectedIndex;
        const hoveredClusterIndexChanged = this.barsClusterHoveredIndexCached !== this.barsClusterHoveredIndex;
        const hoveredOneInstrumentIndexChanged = this.oneInstrumentBarHoveredIndexCached !== this.oneInstrumentBarHoveredIndex;
        const anyHoverSelectedIndexChanged = selectedClusterIndexChanged || hoveredClusterIndexChanged || hoveredOneInstrumentIndexChanged;

        if (hoveredClusterIndexChanged) {
            this.HoveredClusterIndexChanged(this.barsClusterHoveredIndexCached, this.barsClusterHoveredIndex);

            this.barsClusterHoveredIndexCached = this.barsClusterHoveredIndex;
        }

        if (selectedClusterIndexChanged) {
            this.barsClusterSelectedIndexCached = this.barsClusterSelectedIndex;
            this.PrepareBars(converter);
        }

        if (hoveredOneInstrumentIndexChanged) {
            this.OneInstrumentHoveredIndexChanged(this.oneInstrumentBarHoveredIndexCached, this.oneInstrumentBarHoveredIndex);

            this.oneInstrumentBarHoveredIndexCached = this.oneInstrumentBarHoveredIndex;
        }

        return anyHoverSelectedIndexChanged;
    }

    public CreateOrAddToPolyRect (barsObj, key, rect): void {
        if (!barsObj[key]) {
            barsObj[key] = new PolyRect();
        }

        barsObj[key].rects.push(rect);
    }

    public HoveredClusterIndexChanged (oldIndex, newIndex): void {
        const pCache = DataCache.PortfolioCache;

        let insKey = null;
        const selectedIndex = this.barsClusterSelectedIndex;

        if (oldIndex !== null) {
            const monthKey = pCache.GetKeyByMonthReturnsIndex(oldIndex);
            const realBarIndexes = pCache.instrumentRealBarIndexByMonth[monthKey];
            const isSelected = pCache.GetKeyByMonthReturnsIndex(this.barsClusterSelectedIndex) === monthKey;

            for (insKey in this.hoveredBars) {
                if (this.hoveredBars[insKey] === null) continue;

                const skipBarN = this.skipBarsByIns[insKey] || 0;

                if (isSelected) {
                    this.selectedBars[insKey] = this.hoveredBars[insKey];
                } else {
                    if (!this.allBars[insKey]) {
                        this.allBars[insKey] = new PolyRect();
                    }

                    let indexToInsert = realBarIndexes[insKey] - skipBarN;
                    if (selectedIndex != null && oldIndex > selectedIndex && this.selectedBars[insKey]) {
                        indexToInsert--;
                    }

                    if (realBarIndexes[insKey] !== null) {
                        this.allBars[insKey].rects.splice(indexToInsert, 0, this.hoveredBars[insKey].rects[0]);
                    }
                }
            }

            this.hoveredBars = {};
        }

        if (newIndex !== null) {
            const monthKey = pCache.GetKeyByMonthReturnsIndex(newIndex);
            const realBarIndexes = pCache.instrumentRealBarIndexByMonth[monthKey];

            if (selectedIndex === newIndex) {
                this.hoveredBars = this.selectedBars;
                this.selectedBars = {};
                return;
            }

            for (insKey in this.allBars) {
                const skipBarN = this.skipBarsByIns[insKey] || 0;
                let realIndex = realBarIndexes[insKey];
                if (realIndex != null) {
                    realIndex -= skipBarN;

                    if (selectedIndex != null && newIndex > selectedIndex && this.selectedBars[insKey]) {
                        realIndex--;
                    }

                    if (realIndex < 0) continue;

                    const rect = this.allBars[insKey].rects[realIndex];
                    if (rect) {
                        this.hoveredBars[insKey] = new PolyRect();
                        this.hoveredBars[insKey].rects.push(rect);
                        this.allBars[insKey].rects.splice(realIndex, 1);
                    }
                }
            }
        }
    }

    public OneInstrumentHoveredIndexChanged (oldIndex, newIndex): void {
        let key = null;

        if (oldIndex !== null) {
            for (key in this.hoveredBars) {
                if (this.hoveredBars[key] != null) {
                    break;
                }
            }

            this.selectedBars[key] = this.hoveredBars[key];

            this.hoveredBars[oldIndex] = null;
        }

        if (newIndex !== null) {
            if (this.selectedBars[newIndex]) {
                this.hoveredBars[newIndex] = this.selectedBars[newIndex];
                this.selectedBars[newIndex] = null;
            } else {
                for (key in this.hoveredBars) {
                    if (key != newIndex) {
                        this.selectedBars[key] = this.hoveredBars[key];
                        this.hoveredBars[key] = null;
                    }
                }
            }
        }
    }

    public override ProcessMouseDown (e): boolean {
        if (!this.Visible) {
            return false;
        }

        const barsClusterHoveredIndex = this.barsClusterHoveredIndex;

        if (barsClusterHoveredIndex !== this.barsClusterSelectedIndex && barsClusterHoveredIndex !== null) {
            this.barsClusterSelectedIndex = barsClusterHoveredIndex;
            this.barsClusterSelectedIndexChanged.Raise(barsClusterHoveredIndex);
        } else if (this.barsClusterSelectedIndex != null) {
            this.barsClusterSelectedIndex = null;
            this.barsClusterSelectedIndexChanged.Raise(null);
        }

        return false;
    }

    public override ProcessMouseMove (e): boolean {
        if (!this.Visible) {
            return false;
        }

        const chart = this.chart;
        if (!chart?.mainWindow) {
            return false;
        }

        const pConverter = chart.mainWindow.PointsConverter;
        if (!pConverter) {
            return false;
        }

        let indexChanged = false;
        const clientRect = chart.mainWindow.ClientRectangle;
        const barsClusterHoveredIndex = Math.floor(pConverter.GetDataIndexByX(e.X)) - 1;

        if (e.Y > clientRect.Height || e.X > clientRect.Width || barsClusterHoveredIndex < 0) {
            indexChanged = this.barsClusterHoveredIndex !== null;
            this.barsClusterHoveredIndex = null;
        }

        if (!indexChanged && barsClusterHoveredIndex >= 0) {
            const pCache = DataCache.PortfolioCache;

            const key = pCache.GetKeyByMonthReturnsIndex(barsClusterHoveredIndex);
            const minMaxObj = pCache.instrumentReturnsMinMaxByMonth[key];
            const yData = pConverter.GetDataY(e.Y); // значение процента (цены)

            if (!minMaxObj || yData > minMaxObj.max || yData < minMaxObj.min) // примечание по первому условию: нету инструментов в кластере по ховер индексу - такой ховер нам не нужен, но старый снимем
            {
                indexChanged = this.barsClusterHoveredIndex !== null;
                this.barsClusterHoveredIndex = null;
            } else if (this.barsClusterHoveredIndex != barsClusterHoveredIndex) {
                this.barsClusterHoveredIndex = barsClusterHoveredIndex;
                indexChanged = true;
            }
        }

        if (indexChanged) {
            this.barsClusterHoverIndexChanged.Raise(this.barsClusterHoveredIndex);
            chart.IsDirty(this.assignLayer);
        }

        return false;
    }

    public override ProcessMouseLeave (e): void {
        this.barsClusterHoveredIndex = null;
        this.barsClusterHoverIndexChanged.Raise(this.barsClusterHoveredIndex);
    }

    public CreatePensAndBrushes (): void {
        if (!DataCache?.PortfolioCache) return;

        const cache = DataCache.PortfolioCache;
        const colors = cache.instrumentColor;
        const colorsWithOpacity = cache.instrumentColorRGBA;

        if (!colors || !colorsWithOpacity) return;

        const pens = []; const pensWithOpacity = [];
        const brushes = []; const brushesWithOpacity = [];

        for (let i = 0; i < colors.length; i++) {
            const color = colors[i];
            const colorWithOpacity = colorsWithOpacity[i];

            pens.push(new Pen(color));
            brushes.push(new SolidBrush(color));

            pensWithOpacity.push(new Pen(colorWithOpacity));
            brushesWithOpacity.push(new SolidBrush(colorWithOpacity));
        }

        this.pensStorage = pens;
        this.pensWithOpacityStorage = pensWithOpacity;
        this.brushesStorage = brushes;
        this.brushesWithOpacityStorage = brushesWithOpacity;
    }

    public override Properties (): DynProperty[] {
        const properties = [];

        if (!this.UseInProperties) {
            return properties;
        }

        let prop = new DynProperty('AssetReturnVisibility', this.Visible, DynProperty.BOOLEAN, DynProperty.ASSET_RETURN_GROUP);
        prop.sortIndex = 0;
        properties.push(prop);

        prop = new DynProperty('FitMinMax', this.UseInAutoscale, DynProperty.BOOLEAN, DynProperty.PERCENT_SCALE_GROUP);
        prop.sortIndex = 1;
        prop.separatorGroup = '#1#' + Resources.getResource('property.SeparatorGroup.Zoom');
        properties.push(prop);

        return properties;
    }

    public override callBack (properties: DynProperty[]): void {
        if (!this.UseInProperties) {
            return;
        }

        let p = DynProperty.getPropertyByName(properties, 'AssetReturnVisibility');
        if (p) {
            this.Visible = p.value;
        }

        p = DynProperty.getPropertyByName(properties, 'FitMinMax');
        if (p) {
            this.UseInAutoscale = p.value;
        }
    }

    public override ThemeChanged (): void {
        const theme = ThemeManager.CurrentTheme;

        this.infoWindowBackgroundBrush = new SolidBrush(theme.PortfolioReturnsChartInfoWindowColorDefault);

        this.assetColorGreen = theme.PortfolioReturnsChartTextGreen;
        this.assetColorRed = theme.PortfolioReturnsChartTextRed;

        this.CreatePensAndBrushes();
    }

    public override IsNeedDraw (numberOfLayer): boolean {
        return this.assignLayer === numberOfLayer;
    }

    public override FindMinMax (minMaxResult, window): boolean {
        const pCache = DataCache.PortfolioCache;
        const minMaxByMonth = pCache.instrumentReturnsMinMaxByMonth;
        const result: boolean = true;

        minMaxResult.tMin = Number.MAX_VALUE;
        minMaxResult.tMax = -Number.MAX_VALUE;

        const from = Math.floor(window.i1 - window.im());
        const to = window.i1;

        for (let i = from; i <= to; i++) {
            const minMaxObj = minMaxByMonth[pCache.GetKeyByMonthReturnsIndex(i)];
            if (minMaxObj) {
                minMaxResult.tMin = Math.min(minMaxResult.tMin, minMaxObj.min);
                minMaxResult.tMax = Math.max(minMaxResult.tMax, minMaxObj.max);
            }
        }

        return result;
    }
}
