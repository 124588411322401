// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PfixEntitlementSubscriptionHistoryRequestMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_PRODUCT_SUBSCRIPTION_HISTORY_REQ, fieldSet);
    }

    public getFromUtc (): Date {
        return this.getFieldValue(FieldsFactory.FIELD_START_TIME);
    }

    public setFromUtc (value: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_START_TIME, value);
    }

    public getToUtc (): Date {
        return this.getFieldValue(FieldsFactory.FIELD_END_TIME);
    }

    public setToUtc (value: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_END_TIME, value);
    }
}
