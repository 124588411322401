// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// TODO. Supports stop/limit pairs so far.
// Refactor and delete OCOCustomOrdersEdit class.
// Currently supports Stop/Limit pair only.

import { OperationType } from '../../../../Utils/Trading/OperationType';
import { OrderEditBaseUtils } from '../../../../Utils/Trading/OrderEditBaseUtils';
import { type OrderEditRequestData } from '../../../../Utils/Trading/OrderEditRequestData';
import { OrderEditUpdateData } from '../../../../Utils/Trading/OrderEditUpdateData';
import { type TIF } from '../../../../Utils/Trading/OrderTif';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { OrderUtils } from '../../../../Utils/Trading/OrderUtils';
import { DynProperty } from '../../../DynProperty';
import { BaseSettings } from '../../../Settings/BaseGeneralSettingsWrapper';
import { OrderExecutorUtils } from '../../../Trading/OrderExecutorUtils';
import { Resources } from '../../../../Localizations/Resources';
import { OrderEditConfirmationTextParams } from '../OrderEditConfirmationTextParams';
import { SLTPEdit, SLTPMode } from '../SLTPEdit';
import { OrderEditBase } from './OrderEditBase';

export class OCOOrderEdit extends OrderEditBase {
    public needSetDefaultStopPrice: boolean = false;
    public needSetDefaultLimitPrice: boolean = false;
    public firstOrderType: OrderType | undefined | null;
    public secondOrderType: OrderType | undefined | null;
    public firstOrderTif: TIF;
    public secondOrderTif: TIF;
    public firstOrderSide: any;
    public secondOrderSide: any;

    constructor (data) {
        super(data);

        this.stopPrice = null;
        this.limitPrice = null;

        this.sltp = new SLTPEdit(data.dataCache, SLTPMode.Offset);
    }

    public override getParameterNameArray (): string[] {
        return [
            OrderEditBaseUtils.STOP_PRICE_PARAM,
            OrderEditBaseUtils.LIMIT_PRICE_PARAM,
            OrderEditBaseUtils.POSITION_SIZING_PARAM,
            OrderEditBaseUtils.SLTP_PARAM,
            OrderEditBaseUtils.AFTER_TRADE_CASH
        ];
    }

    // TODO. Refactor. Same as OrderTypeBase.Id function.
    public override getOrderTypeId (): OrderType {
        return OrderType.OCO;
    }

    // TODO. Refactor. Use error/warning dicts for sltp.
    // public valid ()
    // {
    //     return OrderEditBase.prototype.valid.call(this) &&
    //         this.sltp.valid()
    // }

    // #region Parameters' DynProperty Generators

    public createPriceDynProperty (price, localizationKey, isStopPrice = false): DynProperty {
        const dpName = OrderEditBase[isStopPrice ? 'STOP_PRICE_PARAM' : 'LIMIT_PRICE_PARAM'];
        const dp = new DynProperty(dpName);
        dp.type = DynProperty.DOUBLE;

        const ins = this.instrument;
        if (ins) {
            dp.minimalValue = ins.PointSize;
            dp.maximalValue = 999999999;
            dp.decimalPlaces = ins.Precision;
            dp.increment = ins.PointSize;
        }

        const insDefSettings = BaseSettings.insDefSettingsStorage.GetInstrumentSettings(ins);
        if (insDefSettings) {
            dp.increment = ins.PointSize * insDefSettings.PriceIncrementTicks;
        }

        dp.value = price;
        if (dp.value === null || isNaN(dp.value)) {
            dp.value = dp.minimalValue;
        }

        dp.localizationKey = localizationKey;

        return dp;
    }

    public toDynProperty_stopPrice (): DynProperty {
        return this.createPriceDynProperty(
            this.stopPrice,
            'panel.newOrderEntry.stopPricePanel', true);
    }

    public toDynProperty_limitPrice (): DynProperty {
        return this.createPriceDynProperty(
            this.limitPrice,
            'panel.newOrderEntry.limitPricePanel');
    }

    // #endregion Parameters' DynProperty Generators

    // #region Update Parameter Handlers

    // TODO. Refactor. Duplicate of update_limitPrice.
    public update_stopPrice (updateData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp && this.stopPrice !== dp.value) {
            this.stopPrice = dp.value;
            parameterChanged = true || parameterChanged;
        }

        let newInstrument = null;
        let newQuote = null;
        let sideChanged = false;

        const newTradingDataDict = updateData.newTradingDataDict;
        if (newTradingDataDict) {
            newInstrument = newTradingDataDict.instrument;
            newQuote = newTradingDataDict.quote;
            sideChanged = 'side' in newTradingDataDict;
        }

        if (newInstrument) {
            this.stopPrice = null;
            this.needSetDefaultStopPrice = true;
            parameterChanged = true || parameterChanged;
        }

        if (this.needSetDefaultStopPrice && newQuote) {
            this.needSetDefaultStopPrice = false;
            this.stopPrice = newQuote.BidSpread_SP_Ins(this.instrument.DataCache.GetSpreadPlan(this.account), this.instrument);
            parameterChanged = true || parameterChanged;
        }

        if (newQuote || sideChanged || parameterChanged) {
            parameterChanged = this.tryUpdateWarning_stopPrice(
                this.stopPrice,
                this.quote) || parameterChanged;
        }

        return parameterChanged;
    }

    // TODO. Refactor. Duplicate of update_stopPrice.
    public update_limitPrice (updateData): boolean {
        let parameterChanged = false;

        const dp = updateData.dp;
        if (dp && this.limitPrice !== dp.value) {
            this.limitPrice = dp.value;
            parameterChanged = true || parameterChanged;
        }

        let newInstrument = null;
        let newQuote = null;
        let sideChanged = false;

        const newTradingDataDict = updateData.newTradingDataDict;
        if (newTradingDataDict) {
            newInstrument = newTradingDataDict.instrument;
            newQuote = newTradingDataDict.quote;
            sideChanged = 'side' in newTradingDataDict;
        }

        if (newInstrument) {
            this.limitPrice = null;
            this.needSetDefaultLimitPrice = true;
            parameterChanged = true || parameterChanged;
        }

        if (this.needSetDefaultLimitPrice && newQuote) {
            this.needSetDefaultLimitPrice = false;
            this.limitPrice = newQuote.BidSpread_SP_Ins(this.instrument.DataCache.GetSpreadPlan(this.account), this.instrument);
            parameterChanged = true || parameterChanged;
        }

        if (newQuote || sideChanged || parameterChanged) {
            parameterChanged = this.tryUpdateWarning_limitPrice(
                this.limitPrice,
                this.quote) || parameterChanged;
        }

        return parameterChanged;
    }

    // TODO. Duplicate of tryUpdateWarning_limitPrice.
    public tryUpdateWarning_stopPrice (stopPrice, quote): any {
        if (!quote || !stopPrice) {
            return false;
        }

        const sp = this.instrument.DataCache.GetSpreadPlan(this.account);
        const ask = quote.AskSpread_SP_Ins(sp, this.instrument);
        const bid = quote.BidSpread_SP_Ins(sp, this.instrument);

        const buy = this.buy();

        if (this.firstOrderType || this.limitPrice == null) {
            return false;
        } // todo warning for custom OCO

        const newWarning =
        buy && stopPrice < ask
            ? 'general.trading.stopBuyMoreAsk'
            : !buy && stopPrice > bid
                ? 'general.trading.stopSellLessBid'
                : null;

        return this.warningsManager.setParameterWarning(OrderEditBaseUtils.STOP_PRICE_PARAM, newWarning);
    }

    // TODO. Duplicate of tryUpdateWarning_stopPrice.
    public tryUpdateWarning_limitPrice (limitPrice, quote): any {
        if (!quote || !limitPrice) {
            return false;
        }

        const sp = this.instrument.DataCache.GetSpreadPlan(this.account);
        const ask = quote.AskSpread_SP_Ins(sp, this.instrument);
        const bid = quote.BidSpread_SP_Ins(sp, this.instrument);

        const buy = this.buy();

        if (this.firstOrderType) {
            return false;
        } // todo warning for custom OCO

        const newWarning =
        buy && limitPrice > ask
            ? 'general.trading.limitBuyLessAsk'
            : !buy && limitPrice < bid
                ? 'general.trading.limitSellMoreBid'
                : null;

        return this.warningsManager.setParameterWarning(OrderEditBaseUtils.LIMIT_PRICE_PARAM, newWarning);
    }

    // #endregion Update Parameter Handlers

    // #region Raw Values

    public toRawValue_stopPrice (): any {
        return this.stopPrice;
    }

    public toRawValue_limitPrice (): any {
        return this.limitPrice;
    }

    // #endregion Raw Values

    public validateParameters (): any {
        const updatedParamNameDict = {};

        if (this.sltp.validate(null, null, this.getTradingData())) {
            updatedParamNameDict[OrderEditBaseUtils.SLTP_PARAM] = true;
        }

        updatedParamNameDict[OrderEditBaseUtils.POSITION_SIZING_PARAM] = true;

        return updatedParamNameDict;
    }

    public override getDataForRequest (): OrderEditRequestData {
        const data = super.getDataForRequest();

        data.useStopLimitInsteadStop = this.useStopLimitInsteadStop();
        if (data.useStopLimitInsteadStop) {
            data.limitPriceForStopLimitOrder = this.getLimitPriceWithDefaultOffset(this.toRawValue_stopPrice());
        }

        return data;
    }

    public useStopLimitInsteadStop (): boolean // для удобства вызова, чтоб не лазить глубоко в сеттинги
    {
        return BaseSettings.isUseStopLimitInsteadStop();
    }

    public override async getConfirmationText (): Promise<string> {
        const firstOrderType = this.firstOrderType !== undefined ? OrderUtils.getOrderTypeLocalizationKey(this.firstOrderType) : 'Limit';
        const secondOrderType = this.secondOrderType !== undefined ? OrderUtils.getOrderTypeLocalizationKey(this.secondOrderType) : (this.useStopLimitInsteadStop() ? 'Stop Limit' : 'Stop');
        const firstOrderTif = this.firstOrderTif ?? this.tif;
        const secondOrderTif = this.secondOrderTif ?? this.tif;
        const firstSideIsBuy = this.firstOrderSide !== undefined ? this.firstOrderSide === OperationType.Buy : this.buy();
        const secondSideIsBuy = this.secondOrderSide !== undefined ? this.secondOrderSide === OperationType.Buy : this.buy();

        const firstPartPriceArray = [this.toRawValue_limitPrice()];
        if (this.firstOrderType === OrderType.StopLimit && this.useStopLimitInsteadStop()) {
            firstPartPriceArray.push(this.getLimitPriceWithDefaultOffset(this.toRawValue_limitPrice(), firstSideIsBuy));
        }

        const firstTextParams = new OrderEditConfirmationTextParams();
        firstTextParams.OrderTypeKey = firstOrderType;
        firstTextParams.Tif = firstOrderTif;
        firstTextParams.Instrument = this.instrument;
        firstTextParams.Quantity = this.quantity;
        firstTextParams.Buy = firstSideIsBuy;
        firstTextParams.PriceArray = firstPartPriceArray;
        firstTextParams.ProductType = this.productType;
        firstTextParams.OrderType = this.firstOrderType;
        firstTextParams.LeverageValue = this.getLeverageValue();

        let firstPart = await OrderExecutorUtils.buildOrderEditConfirmationText(firstTextParams);

        firstPart = firstPart.substr(0, firstPart.length - 1) + ',';

        const stopPartPriceArray = [this.stopPrice];
        if ((this.secondOrderType === undefined || this.secondOrderType == OrderType.StopLimit) && this.useStopLimitInsteadStop()) {
            stopPartPriceArray.push(this.getLimitPriceWithDefaultOffset(this.toRawValue_stopPrice(), secondSideIsBuy));
        }

        const secondTextParams = new OrderEditConfirmationTextParams();
        secondTextParams.OrderTypeKey = secondOrderType;
        secondTextParams.Tif = secondOrderTif;
        secondTextParams.Instrument = this.instrument;
        secondTextParams.Quantity = this.quantity;
        secondTextParams.Buy = secondSideIsBuy;
        secondTextParams.PriceArray = stopPartPriceArray;
        secondTextParams.ProductType = this.productType;
        secondTextParams.OrderType = this.secondOrderType;
        secondTextParams.LeverageValue = this.getLeverageValue();

        const secondPart = await OrderExecutorUtils.buildOrderEditConfirmationText(secondTextParams);

        const sltpPart = this.sltp.getConfirmationText(this.getTradingData());

        return Resources.getResource('OCO') +
        ' ' +
        Resources.getResource('property.protrader.showOrders') +
        ' ' +
        Resources.getResource('general.trading.for') +
        ' ' +
        this.account.toString() +
        ':\n' +
        firstPart +
        '\n' +
        secondPart +
        (sltpPart ? '\n' + sltpPart : '');
    }

    public setBasePrice (price): void {
        const dpLimitPrice = this.createDynPropertyFromParameter(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        dpLimitPrice.value = price;

        const dpStopPrice = this.createDynPropertyFromParameter(OrderEditBaseUtils.STOP_PRICE_PARAM);
        dpStopPrice.value = this.stopPrice;

        this.updateParameters(new OrderEditUpdateData({
            limitPrice: dpLimitPrice,
            stopPrice: dpStopPrice
        }));
    }

    public setStopPrice (price): void {
        const dpLimitPrice = this.createDynPropertyFromParameter(OrderEditBaseUtils.LIMIT_PRICE_PARAM);
        dpLimitPrice.value = this.limitPrice;

        const dpStopPrice = this.createDynPropertyFromParameter(OrderEditBaseUtils.STOP_PRICE_PARAM);
        dpStopPrice.value = price;

        this.updateParameters(new OrderEditUpdateData({
            limitPrice: dpLimitPrice,
            stopPrice: dpStopPrice
        }));
    }

    public getBasePrice (): any // for #101148
    {
        return this.limitPrice;
    }

    public skipParamForFlashing (paramName): boolean // при включенном positionSizing надо ли DynPropertyControl c paramName пропускать при подсветке лейблов (к примеру как Stop price не подсвечиваем у Stop limit-a)
    {
        if (paramName == OrderEditBaseUtils.STOP_PRICE_PARAM) {
            return true;
        }

        return false;
    }
}
