// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type DirectMamGroupMessage } from '../../Utils/DirectMessages/DirectMamGroupMessage';
import { type Account } from './Account';

class _MamGroupsCache {
    private storage: Record<string, DirectMamGroupMessage> = {};

    public addGroup (message: DirectMamGroupMessage): void {
        this.storage[message.userID] = message;
    }

    public clear (): void {
        this.storage = {};
    }

    public isInMaMGroupForAccount (account: Account, accountId: string): boolean {
        const up = account?.User?.Pin;
        const mamGr = this.storage[up];
        if (isNullOrUndefined(mamGr)) return false;
        const res = mamGr.subAccounts.find((element) => element.accountNumb === accountId);
        return !isNullOrUndefined(res);
    }
}

export const MamGroupsCache = new _MamGroupsCache();
