// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OrderTypeBase } from './OrderTypeBase';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { CareOrderEdit } from '../order-edit/CareOrderEdit';
import { ModifyCareOrder } from '../order-modify/ModifyCareOrder';

export class CareOrderType extends OrderTypeBase {
    constructor () {
        super(CareOrderEdit, ModifyCareOrder);
    }

    public override id (): OrderType {
        return OrderType.Care;
    }
}
