// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Point, Rectangle } from '@shared/commons/Geometry';
import { ToolView } from './ToolView';
import { type PriceLabelDataCacheTool } from '@shared/commons/cache/Tools/PriceLabelDataCacheTool';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { SolidBrush } from '@shared/commons/Graphics';

export class PriceLabelToolView extends ToolView<PriceLabelDataCacheTool> {
    private isLeftDirection: boolean = true;
    private wasCreated: boolean = false;
    private labelRect: Rectangle | null = null;

    public override Draw (gr: CanvasRenderingContext2D, ww, param): void {
        if (this.wasCreated) {
            this.completeDrawing(ww, this.screenPoints[0][0], this.screenPoints[0][1]);
            this.wasCreated = this.CurrentMovement.CreationState = false;
        }
        const pricePosition: Point = new Point(this.screenPoints[0][0], this.screenPoints[0][1]);
        let labelPosition: Point = new Point(this.screenPoints[1][0], this.screenPoints[1][1]);
        if (this.CurrentMovement.CreationState) {
            labelPosition = new Point(pricePosition.X + 10, pricePosition.Y - 15);
        }

        const cacheTool = this.dataCacheTool;
        gr.DrawLine(cacheTool.pen, pricePosition.X, pricePosition.Y, labelPosition.X, labelPosition.Y);
        const pricePointRect = new Rectangle(pricePosition.X - 3, pricePosition.Y - 3, 6, 6);
        const brushColor = new SolidBrush(cacheTool.pen.Color);
        gr.FillEllipse(brushColor, pricePointRect.X, pricePointRect.Y, pricePointRect.Width, pricePointRect.Height);
        this.isLeftDirection = pricePosition.X >= labelPosition.X;

        const price: string = this.getPriceStr(ww, param);
        const textSize = gr.GetTextSize(price, cacheTool.font, false);
        this.DrawLabel(gr, labelPosition, textSize, price);

        super.Draw(gr, ww, param);
    }

    public override IsSelectCheck (x: number, y: number): boolean {
        if (this.labelRect?.Contains(x, y)) {
            return true;
        }
        return super.IsSelectCheck(x, y);
    }

    public override OnFinishCreationTool (): void {
        this.wasCreated = true;
        super.OnFinishCreationTool();
    }

    private DrawLabel (
        gr: CanvasRenderingContext2D,
        location: Point,
        size: {
            width: number
            height: number
        },
        text: string
    ): void {
        const points: Point[] = [];
        const width = size.width + 4;
        const height = size.height + 2;

        let offsetX1 = width / 4;
        let offsetX2 = width + width / 3;

        if (!this.isLeftDirection) {
            offsetX1 *= -1;
            offsetX2 *= -1;
        }

        points.push(new Point(location.X, location.Y));
        points.push(new Point(location.X - offsetX1, location.Y - height / 2));
        points.push(new Point(location.X - offsetX2, location.Y - height / 2));
        points.push(new Point(location.X - offsetX2, location.Y + height / 2));
        points.push(new Point(location.X - offsetX1, location.Y + height / 2));

        // Draw label
        const rad = 5;
        const labelPath = new Path2D();

        if (this.isLeftDirection) {
            labelPath.moveTo(points[0].X, points[0].Y);
            labelPath.lineTo(points[4].X, points[4].Y);
            labelPath.arcTo(points[3].X, points[3].Y, points[2].X, points[2].Y, rad);
            labelPath.arcTo(points[2].X, points[2].Y, points[1].X, points[1].Y, rad);
            labelPath.lineTo(points[1].X, points[1].Y);
            labelPath.lineTo(points[0].X, points[0].Y);

            const width = points[4].X - points[3].X;
            const height = points[3].Y - points[2].Y;
            this.labelRect = new Rectangle(points[2].X, points[2].Y, width, height);
        } else {
            labelPath.moveTo(points[0].X, points[0].Y);
            labelPath.lineTo(points[1].X, points[1].Y);
            labelPath.arcTo(points[2].X, points[2].Y, points[3].X, points[3].Y, rad);
            labelPath.arcTo(points[3].X, points[3].Y, points[4].X, points[4].Y, rad);
            labelPath.lineTo(points[4].X, points[4].Y);
            labelPath.lineTo(points[0].X, points[0].Y);

            const width = points[2].X - points[1].X;
            const height = points[3].Y - points[2].Y;
            this.labelRect = new Rectangle(points[1].X, points[1].Y, width, height);
        }

        // Draw circle
        const circlePath = new Path2D();
        const X = points[0].X - offsetX1;
        const Y = points[0].Y;
        const D = (height - 5) / 3;
        circlePath.arc(X, Y, D / 2, 0, 2 * Math.PI);

        gr.fillStyle = this.dataCacheTool.FillColor;
        gr.fill(labelPath);
        gr.strokeStyle = this.dataCacheTool.pen.Color;
        gr.stroke(labelPath);
        gr.stroke(circlePath);
        gr.globalCompositeOperation = 'destination-out';
        gr.fill(circlePath);
        gr.globalCompositeOperation = 'source-over';

        // Draw text
        gr.fillStyle = this.dataCacheTool.FontColor;
        let textOffset = points[0].X;
        textOffset -= (this.isLeftDirection ? offsetX2 - 1 : offsetX1 - 4);
        const textBrush = new SolidBrush(this.dataCacheTool.FontColor);
        gr.DrawString(text, this.dataCacheTool.font, textBrush, textOffset, points[0].Y - size.height / 2 + 1);
    }

    private getPriceStr (ww, param): string {
        let price = ww.PointsConverter.GetDataY(ww.PointsConverter.GetScreenY(this.dataCacheTool.Points[0][1]));
        const dataType = param.TerceraChart.cashItemSeriesSettings?.DataType ?? TerceraChartCashItemSeriesDataType.Absolute;

        switch (dataType) {
        case TerceraChartCashItemSeriesDataType.Relative:
            price = param.TerceraChart.cashItemSeriesSettings.relativeDataConverter.Revert(price);
            break;
        case TerceraChartCashItemSeriesDataType.Log:
            price = param.TerceraChart.cashItemSeriesSettings.logDataConverter.Revert(price);
            break;
        }

        return param.Instrument.formatPrice(price, false);
    }

    private completeDrawing (ww, x: number, y: number): void {
        if (this.screenPoints[0][0] === this.screenPoints[1][0] && this.screenPoints[0][1] === this.screenPoints[1][1]) {
            this.screenPoints[1][0] = x + 10;
            this.screenPoints[1][1] = y - 15;
            this.dataCacheTool.Points[1][0] = ww.PointsConverter.GetDataX(this.screenPoints[1][0]);
            this.dataCacheTool.Points[1][1] = ww.PointsConverter.GetDataY(this.screenPoints[1][1]);
        }
    }
}
