// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingHeaderTableRowTemplate } from '../templates.js';
import Ractive from 'ractive';

export class InvestingHeaderTableRow extends Ractive {
    constructor () { super(); }

    public getType (): string { return 'InvestingHeaderTableRow'; }

    public override oninit (): void {
        this.on('clickHeaderColumnItem', this.clickHeaderColumnItem);
    }

    public clickHeaderColumnItem (): void {
        this.fire('clickHeaderColumnItemEvent');
    }
}

Ractive.extendWith(InvestingHeaderTableRow, {
    template: InvestingHeaderTableRowTemplate,
    data: function () {
        return {
            arrayHeaderColumn: []
        };
    }
});
