// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum MainTypes {
    AUCTION_PRE_OPEN = 0,
    MAIN_CONTINUOUS1 = 1,
    MAIN_CONTINUOUS2 = 2,
    CONTINUOUS_PRE = 3,
    AUCTION_PRE_CLOSE = 4,
    AUCTION_PRE_MAIN_OPEN = 5,
    AUCTION_OPEN = 6,
    IN_BETWEEN_CLEARING = 7,
    AUCTION_CLOSE = 8,
    CLEARING = 9,
    SETTLEMENT_CLEARING = 10,
    FINAL_SETTLEMENT_CLEARING = 11,
    AUCTION_POST_OPEN = 12,
    AUCTION_POST_CLOSE = 13,
    CONTINUOUS_POST = 14,
}