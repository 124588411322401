// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { AssetsItem } from '../cache/AssetsItem';
import { _AccountItemSelected, type TerceraAccountLookup, _AccountItemAll } from '../elements/Lookup/TerceraAccountLookup';
import { QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { TerceraQuickTreeEvents } from '../elements/QuickTree/TerceraQuickTree';
import { type TerceraLabel } from '../elements/TerceraLabel';
import { TerceraMenu } from '../elements/TerceraMenu';
import { ExportScreen } from '../screen/ExportScreen';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { OperationType } from '../../Utils/Trading/OperationType';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { DynProperty } from '../../Commons/DynProperty';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { Quantity } from '../../Utils/Trading/Quantity';
import { DataCache } from '../../Commons/DataCache';
import { SessionSettings } from '../../Commons/SessionSettings';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { AssetsPanelTemplate } from '../../templates.js';
import { LinkedSystem } from '../misc/LinkedSystem';
import { ApplicationPanelWithTable } from './ApplicationPanelWithTable';
import { type AccountSelector } from '../elements/AccountSelector';
import { type Account } from '../../Commons/cache/Account';
import { type QuickTableRow } from '../elements/QuickTable/QuickTableRow';
import { type Instrument } from '../../Commons/cache/Instrument';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';

export class AssetsPanel extends ApplicationPanelWithTable<AssetsItem> {
    public Name: string = 'AssetsPanel';
    public topPanelHeight: number = 25;

    public myAccount: any = null;
    public accLookup: TerceraAccountLookup = null;
    public headerLocaleKey: string = 'panel.assets';
    public ToolbarVisible: boolean = true;
    public InitShowTotals: boolean = false;
    public staticItems: any = null;
    public noDataLabel: TerceraLabel | null = null;
    public delayAccounts: string;
    public OnUpdateAccount: any;

    public override getType (): PanelNames { return PanelNames.AssetsPanel; }

    public override oncomplete (): void {
        super.oncomplete();

        const menuItems = this.createMenuItems();
        this.staticItems = menuItems;
        this.menuTagDict = TerceraMenu.createTagDictionary(menuItems);
        this.getQuickTable().setTableContextMenuItems(menuItems);

        this.selectionChange();
        this.UpdateShowTotalsStateChange(!!this.InitShowTotals);
        this.ShowToolbarStateUpdate(!!this.ToolbarVisible);
        if (!isNullOrUndefined(this.menuTagDict)) {
            if (!isNullOrUndefined(this.menuTagDict.ShowTotals)) {
                this.menuTagDict.ShowTotals.checked = !!this.InitShowTotals;
            }
            if (!isNullOrUndefined(this.menuTagDict.ToolbarVisible)) {
                this.menuTagDict.ToolbarVisible.checked = !!this.ToolbarVisible;
            }
        }

        this.layoutTable();
        this.localize();
    }

    public getAssetsAccountsLookup (): TerceraAccountLookup {
        return (this.Controls.accountSelector as AccountSelector).getLookup();
    }

    public override jbInit (): void {
        if (DataCache.getNumberOfAccounts() === 1) {
            this.topPanelHeight = 0;
        }

        super.jbInit();

        const noDataLabel = this.Controls.noDataLabel;
        this.noDataLabel = noDataLabel;

        const accountsLookup = this.getAssetsAccountsLookup();
        accountsLookup.onChangeValue.Subscribe(this.accountsLookupOnChangeValue, this);
        accountsLookup.IsMultiSelect = true;
        accountsLookup.isMultiSelectMode = true;
        this.accLookup = accountsLookup;
        if (isValidString(this.delayAccounts)) {
            const delayAccountsArrayString = this.delayAccounts.split(',');
            const delayAccountsArrayAccount = [];
            const len = delayAccountsArrayString.length;
            for (let i = 0; i < len; i++) {
                if (!isNullOrUndefined(DataCache.Accounts[delayAccountsArrayString[i]]) || delayAccountsArrayString[i] === 'All') {
                    delayAccountsArrayAccount.push(DataCache.Accounts[delayAccountsArrayString[i]]);
                }
            }
            accountsLookup.dropDownFormCallBack(delayAccountsArrayAccount);
            delete this.delayAccounts;
        } else {
            let accToInitWith: _AccountItemAll | Account[] = accountsLookup.ItemAll;
            if (DataCache.EnableForceLinkingByAccount()) {
                const accID = LinkedSystem.getAccount();
                accToInitWith = [DataCache.GetAccountById(accID)];
            }

            accountsLookup.dropDownFormCallBack(accToInitWith);
        }

        this.getQuickTable().OnSelectionChanged.Subscribe(this.selectionChange, this);

        this.getQuickTable().OnPaintedPictureButtonClick.Subscribe(this.onPaintedPictureButtonClick, this);
        this.getQuickTable().AfterEditItem.Subscribe(this.onAfterEditItem, this);

        this.quickTableRactive.on(TerceraQuickTreeEvents.DoubleClick, this.onDoubleClicked.bind(this));

        DataCache.OnUpdateAccount.Subscribe(this.UpdateAccount, this);
    }

    public UpdateAccount (account: Account): void {
        this.FillRowsByAcc(account);
        const len = this.getQuickTable().rowsArray.length;
        if (!isNullOrUndefined(this.noDataLabel)) {
            void this.set('noDataLabelVisible', !isValidNumber(len) || len === 0);
        }
    }

    public FillRowsByAcc (account: Account): void {
        if (isNullOrUndefined(account)) {
            return;
        }

        const sharesNames = account.AssetSharesNames;
        const qt = this.getQuickTable();
        const processingRows = [];
        for (let i = 0; i < qt.rowsArray.length; i++) {
            const row = qt.rowsArray[i];
            const item = row.item;
            if (isNullOrUndefined(item)) {
                continue;
            }

            const secondQuantor = !isValidArray(sharesNames) || !sharesNames.includes(item.Asset.Name);
            if (item.Account === account) {
                if (secondQuantor) {
                    qt.RemoveItem(item.ItemId);
                } else {
                    processingRows.push(row);
                }
            }
        }

        if (isValidArray(sharesNames)) {
            for (let i = 0; i < sharesNames.length; i++) {
                const name = sharesNames[i];
                const assetData = account.GetAssetDataInfoByName(name);
                if (assetData == null) {
                    continue;
                }
                const asset = DataCache.GetAssetByName(name);
                if (asset == null) {
                    continue;
                }
                let found = false;

                for (let i = 0; i < processingRows.length; i++) {
                    const row = processingRows[i];
                    const item = row.item;
                    if (isNullOrUndefined(item)) {
                        continue;
                    }

                    if (item.Account === account && item.Asset === asset) {
                        item.UpdatInfoData();
                        item.fireUpdate();
                        found = true;
                        processingRows.splice(i, 1);
                        break;
                    }
                }

                if (!found) {
                    const row = this.getQuickTable().AddItem(new AssetsItem(account, asset, SessionSettings));
                    this.ProcessEditingItem(row);
                }
            }
        }
    }

    public ProcessEditingItem (row: QuickTableRow<AssetsItem>): void {
        const lotValueCell = row.cells[AssetsItem.COL_SELL_EXCHANGE];
        if (isNullOrUndefined(lotValueCell.QuickTableEditingInfo)) {
            const info = new QuickTableEditingInfo(DynProperty.COMBOBOX);
            info.GetDataHandler = this.GetCBDataForRow;
            lotValueCell.QuickTableEditingInfo = info;
            lotValueCell.ReadOnly = false;
        }
    }

    public GetCBDataForRow (row: QuickTableRow<AssetsItem>): any[] {
        const comboboxItems = [];
        if (!row.item.SellExchanges) {
            return comboboxItems;
        }
        for (let i = 0; i < row.item.SellExchanges.length; i++) {
            comboboxItems.push({ text: row.item.SellExchanges[i], value: row.item.SellExchanges[i] });
        }
        return comboboxItems;
    }

    public onAfterEditItem (params): void {
        const row = params.row;
        if (isNullOrUndefined(row)) return;

        row.item.SetSellExchange(params.newValue);
    }

    public onPaintedPictureButtonClick (data, event): void {
        if (isNullOrUndefined(data?.row?.item)) {
            return;
        }

        this.OpenOE(data.row.item, data.row.item.GetSellExchange());
    }

    public OpenOE (item: AssetsItem, preferExchange?) {
        if (isNullOrUndefined(item?.Asset)) {
            return;
        }
        let instrument = null;
        const asset = item.Asset;

        const exchange = preferExchange;
        instrument = asset.Instrument;
        let insPromise = null;

        if (exchange && !isNullOrUndefined(instrument) && instrument.TradingExchange !== exchange || isNullOrUndefined(instrument)) {
            const exch = exchange || item.GetSellExchange();
            const InstrumentInterriorId = asset.GetPreferedInstrumentInterriorIdByExchange(exch);

            if (!isNullOrUndefined(InstrumentInterriorId)) {
                const sp = InstrumentInterriorId.split(InstrumentUtils.SEPARATOR);
                insPromise = DataCache.getInstrumentByInstrumentTradableID_NFL(sp[0], sp[1], InstrumentInterriorId);
            } else {
                insPromise = (async function () {
                    return await Promise.resolve(DataCache.FindInstrumentByTradingExchange(exch));
                })();
            }
        } else {
            insPromise = (async function () {
                return await Promise.resolve(instrument);
            })();
        }

        if (isNullOrUndefined(insPromise)) {
            return;
        }

        insPromise.then(function (instrument: Instrument) {
            if (isNullOrUndefined(instrument)) { return; }

            const acc = item.Account;
            const assetInfo = acc.GetAssetDataInfoByName(asset.Name);
            const lotSize = instrument.LotSize;
            const productType = ProductType.Delivery; // always Delivery?
            // Open OE.
            const openOEMethod = (): void => {
                MainWindowManager.Factory.addPanel(PanelNames.AdvancedOrderEntry, null, (panel) => {
                    // panel.symbolLink_In(instrument.GetInteriorID());
                    const inLots = WDSettingsUtils.displayAmountInLots();
                    const convertedValue = Quantity.convertQuantityValue(new Quantity(assetInfo.GetAvailableQty() / lotSize, true), instrument, inLots, acc, productType);
                    const quantity = new Quantity(convertedValue, inLots);

                    panel.set({ instrument }).then(() => {
                        panel.set({
                            side: OperationType.Sell,
                            account: acc,
                            isCashQtyMode: false, // #124187
                            defaultQuantity: quantity
                        });
                        if (instrument.isProductTypeVisible()) {
                            panel.set({ productType });
                        }

                        panel.setDisclosedQuantityParams('quantity');
                    });
                });
            };
            // setFocus Hack
            setTimeout(openOEMethod, 0);
        });
    }

    public onDoubleClicked (): void {
        const qt = this.getQuickTable();
        const selectedRowIds = qt.selectedRowIds;
        if (isValidArray(selectedRowIds)) {
            const row = qt.rows[selectedRowIds[0]];
            if (!isNullOrUndefined(row)) {
                this.OpenOE(row.item);
            }
        }
    }

    public accountsLookupOnChangeValue (accountsResult: _AccountItemAll | _AccountItemSelected | Account): void {
        if (isNullOrUndefined(this.getQuickTable())) {
            return;
        }
        this.getQuickTable().ClearRows();

        if (accountsResult instanceof _AccountItemAll) {
            const accounts = DataCache.getAccounts();
            const keys = Object.keys(accounts);
            for (let i = 0; i < keys.length; i++) {
                this.FillRowsByAcc(accounts[keys[i]]);
            }
        } else if (accountsResult instanceof _AccountItemSelected) {
            for (let i = 0; i < accountsResult.arraySelected.length; i++) {
                this.FillRowsByAcc(accountsResult.arraySelected[i]);
            }
        } else {
            this.FillRowsByAcc(accountsResult);
        }

        const len = this.getQuickTable().rowsArray.length;
        if (!isNullOrUndefined(this.noDataLabel)) {
            void this.set('noDataLabelVisible', !isValidNumber(len) || len === 0);
        }
    }

    public createMenuItems (): any[] {
        const items = [];

        items.push(
            {
                locKey: 'screen.export.contextMenu',
                text: Resources.getResource('screen.export.contextMenu'),
                event: ExportScreen.show.bind(null, this)
            }
        );

        if (!Resources.isHidden('panel.trades.print')) {
            items.push(
                {
                    locKey: 'screen.reports.print',
                    text: Resources.getResource('screen.reports.print'),
                    event: this.printTable.bind(this)
                }
            );
        }

        const view =
        {
            locKey: 'panel.positions.menu.View',
            tag: 'View',
            enabled: true,
            subitems: []
        };

        if (DataCache.getNumberOfAccounts() !== 1) {
            view.subitems.push({
                locKey: 'panel.Assets.Menu.ShowToolbar',
                tag: 'ShowToolbar',
                checked: this.ToolbarVisible,
                visible: !Resources.isHidden('panel.Assets.Menu.ShowToolbar'),
                enabled: true,
                canCheck: true,
                event: this.ShowToolbarStateChange.bind(this)
            });
        }

        view.subitems.push({
            locKey: 'panel.positions.menu.ShowTotals',
            tag: 'ShowTotals',
            checked: false,
            enabled: true,
            canCheck: true,
            event: this.ShowTotalsStateChange.bind(this)
        });
        items.push(view);

        return items;
    }

    public ShowToolbarStateChange (menuItem): void {
        this.ShowToolbarStateUpdate(menuItem.checked);
    }

    public ShowToolbarStateUpdate (state: boolean): void {
        if (DataCache.getNumberOfAccounts() === 1) {
            state = false;
        }

        void this.accLookup.set('visible', state);
        this.topPanelHeight = state ? 25 : 0;
        this.layoutTable();
    }

    public override localize (): void {
        super.localize();

        const menuTagDict = this.menuTagDict;
        if (isNullOrUndefined(menuTagDict)) return;

        for (const key in menuTagDict) {
            const menuItem = menuTagDict[key];
            menuItem.text = Resources.getResource(menuItem.locKey);
        }

        void this.set({
            noAssetsAvailableText: Resources.getResource('panel.Assets.NoAssetsAvailable')
        });
    }

    public override SetColumnsDefaultDisplayIndex (table): void {
        table.columns[0].displayedIndex = 1;
        table.columns[1].displayedIndex = 2;
        table.columns[2].displayedIndex = 3;
        table.columns[3].displayedIndex = 4;
        table.columns[4].displayedIndex = 5;
        table.columns[5].displayedIndex = 6;
        table.columns[6].displayedIndex = 7;
        table.columns[7].displayedIndex = 8;
        table.columns[8].displayedIndex = 9;
        table.columns[9].displayedIndex = 10;
        table.columns[10].displayedIndex = 11;
        table.columns[11].displayedIndex = 15;
        table.columns[14].displayedIndex = 14;
    }

    public selectionChange (): void {
        const qt = this.getQuickTable();
        const selectedRowIds = qt.selectedRowIds;
        if (!isValidArray(selectedRowIds)) {
            return;
        }

        const items = [];

        if (selectedRowIds.length === 0) {
            items.push(
                {
                    locKey: 'panel.Assets.Menu.SellAsset',
                    tooltip: Resources.getResource('IsAllowedResponceReason.NoAssetsSelected'),
                    text: Resources.getResource('panel.Assets.Menu.SellAsset'),
                    enabled: false,
                    style: 'js-context-menu-error',
                    event: function () { }
                }
            );
        } else if (selectedRowIds.length > 1) {
            items.push(
                {
                    locKey: 'panel.Assets.Menu.SellAsset',
                    tooltip: Resources.getResource('IsAllowedResponceReason.MoreThanOneAssetIsSelected'),
                    text: Resources.getResource('panel.Assets.Menu.SellAsset'),
                    enabled: false,
                    style: 'js-context-menu-error',
                    event: function () { }
                }
            );
        } else {
            const row = qt.rows[selectedRowIds[0]];
            if (!isNullOrUndefined(row)) {
                const exchArr = this.GetCBDataForRow(row);
                for (let i = 0; i < exchArr.length; i++) {
                    items.push(
                        {
                            text: Resources.getResource('panel.Assets.Menu.SellAssetOn') + ' ' + exchArr[i].text,
                            event: this.OpenOE.bind(this, row.item, exchArr[i].text)
                        });
                }
            }
        }

        const menuItems = items.concat(this.staticItems);
        this.menuTagDict = TerceraMenu.createTagDictionary(menuItems);
        qt.setTableContextMenuItems(menuItems);

    // panel.Assets.Menu.SellAsset=Sell asset
    // panel.Assets.Menu.SellAssetOn=Sell asset on
    // IsAllowedResponceReason.NoAssetsSelected =No assets selected
    // IsAllowedResponceReason.MoreThanOneAssetIsSelected=More than one asset is selected
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();
        const acc: _AccountItemAll | _AccountItemSelected | Account = this.accLookup.get('selectedItem');

        if (isNullOrUndefined(acc)) {
            return properties;
        }
        let accNumber = '';
        if (acc instanceof _AccountItemAll || acc instanceof _AccountItemSelected) {
            accNumber = acc.GetDataForSave();
        } else {
            accNumber = acc.AcctNumber;
        }

        properties.push(new DynProperty('account', accNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));

        properties.push(new DynProperty('ShowTotals', this.getQuickTable().ShowTotals, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));
        properties.push(new DynProperty('ToolbarVisible', this.ToolbarVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public override callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);
        const dp = DynProperty.getPropertyByName(newProperties, 'account');
        if (isValidString(dp?.value)) {
            this.delayAccounts = dp.value;
        }

        this.InitShowTotals = DynProperty.getPropValue(newProperties, 'ShowTotals');
        this.ToolbarVisible = DynProperty.getPropValue(newProperties, 'ToolbarVisible');
    }

    public override repopulate (): void {
        super.repopulate();
        if (!isNullOrUndefined(this.accLookup)) {
            this.accountsLookupOnChangeValue(this.accLookup.get('selectedItem'));
        }
    }

    public override dispose (): void {
        DataCache.OnUpdateAccount.UnSubscribe(this.OnUpdateAccount, this);

        super.dispose();
    }

    public override accountLink_In (accId, fromLinkedSystem = false): boolean {
        super.accountLink_In(accId, fromLinkedSystem);
        if (DataCache.EnableForceLinkingByAccount()) {
            const acc = DataCache.GetAccountById(accId);
            const accountsLookup = this.getAssetsAccountsLookup();
            accountsLookup?.dropDownFormCallBack([acc]);
            this.UpdateAccount(acc);
        }
        return true;
    }
}

ApplicationPanelWithTable.extendWith(AssetsPanel,
    {
        Name: 'AssetsPanel',
        topPanelHeight: 25,
        data: function () {
            return {
                isAccountLinkShow: true,
                isSymbolLinkShow: true,
                noDataLabelVisible: false
            };
        },

        partials: {
            bodyPartial: AssetsPanelTemplate
        },

        myAccount: null,
        accLookup: null,
        headerLocaleKey: 'panel.assets',
        ToolbarVisible: true,
        InitShowTotals: false,
        staticItems: null,
        noDataLabel: null
    });
