// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class HelloMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_HELLO_MESSAGE, fieldSet);
    }

    public setHello (hello: string): void {
        this.setFieldValue(FieldsFactory.FIELD_PASSWORD, hello);
    }

    public getHello (): string {
        return this.getFieldValue(FieldsFactory.FIELD_PASSWORD);
    }

    public EncryptionType (): number {
        const field = this.getFieldValue(FieldsFactory.FIELD_ID);
        return field !== null ? field : 0;
    }

    // +++ new
    public getMaxFieldNumber (): number {
        const FIELD_MAX_PFIX_FIELD_INDEX = this.FieldSet.GetField(FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX);
        if (FIELD_MAX_PFIX_FIELD_INDEX) {
            return FIELD_MAX_PFIX_FIELD_INDEX.Value;
        } else {
            return 2147483647;
        }// int.MaxValue;
    }

    // +++ new
    public setMaxFieldNumber (number: number): void {
        this.setFieldValue(FieldsFactory.FIELD_MAX_PFIX_FIELD_INDEX, number);
    }

    // +++ new
    public getProtocolVersion (): string {
        return this.getFieldValue(FieldsFactory.FIELD_PROTOCOL_ID);
    }

    // +++ new
    public setProtocolVersion (version: string): void {
        this.setFieldValue(FieldsFactory.FIELD_PROTOCOL_ID, version);
    }
}
