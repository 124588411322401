// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolRayType } from '../../Commons/cache/DataCacheToolEnums';
import { type GannGridDataCacheTool } from '../../Commons/cache/Tools/GannGridDataCacheTool';
import { ToolView } from './ToolView';

export class GannGridToolView extends ToolView<GannGridDataCacheTool> {
    public DrawGannGrid (gr, ww, highlight): void {
        const cacheTool = this.dataCacheTool;
        const pen = highlight ? cacheTool.PenHighlight : cacheTool.Pen;
        const clientRect = ww.ClientRectangle;

        const screenPoints = this.screenPoints;

        const p0 = screenPoints[0];
        let x0 = p0[0];
        let y0 = p0[1];

        const p1 = screenPoints[1];
        let x1 = p1[0];
        let y1 = p1[1];

        if (clientRect.Contains(x0, y0) || clientRect.Contains(x1, y1)) {
            ToolView.DrawLineWithRay(gr, ww, x0, y0, x1, y1, DataCacheToolRayType.NoRay, pen);
        }

        let dY = y1 - y0;
        let dX = x1 - x0;

        if (Math.abs(dY) < 10) dY = 10;
        if (Math.abs(dX) < 10) dX = 10;

        // ANTI-FREEHAND
        const w = Math.PI / 4;
        // Grid size
        const size = Math.sqrt(dY * dY + dX * dX);
        const ddx = Math.abs((size * Math.cos(w))); // grid-step projected to x
        const ddy = Math.abs((size * Math.sin(w))); // grid-step projected to y

        if (clientRect.Height < ddy && clientRect.Width < ddy) {
            return;
        } // не рисуем сетку если она великовата для экрана

        const scale = Math.max(clientRect.Height / ddy, clientRect.Width / ddx) * 2;
        let beginxPos = x0;
        let beginyPos = y0;

        // #39580
        while (beginxPos < -2 * ddx) {
            beginxPos += 2 * ddx;
            beginyPos += 2 * ddy;
        }
        // #39580
        while (beginyPos > clientRect.Height + 2 * ddy) {
            beginyPos -= 2 * ddy;
        }

        let xPos = beginxPos;
        let yPos = beginyPos;

        // для уравнения прамой по 2м точкам, и поиска пересечения с границами экрана
        const top = clientRect.Y;
        const bottom = top + clientRect.Height;
        const left = clientRect.X;
        const right = clientRect.X + clientRect.Width;
        let x, y;

        while (yPos > -ddy * scale || xPos > -ddx * scale) {
            y0 = yPos;
            y1 = yPos - ddy;
            x0 = xPos;
            x1 = xPos - ddx;

            // check top
            y = top;
            x = -(y - y0) * (x1 - x0) / (y1 - y0) + x0;

            if (x >= 0 && x <= right) {
                gr.DrawLine(pen, x0, y0, x, y);
            } else {
            // check right
                x = right;
                y = y0 - (x - x0) * (y1 - y0) / (x1 - x0);

                if (y >= top && y <= bottom) {
                    gr.DrawLine(pen, x0, y0, x, y);
                }
            }

            yPos -= ddy;
            xPos -= ddx;
        }

        yPos = beginyPos;
        xPos = beginxPos;
        yPos += ddy;
        xPos += ddx;
        while (yPos < clientRect.Height || xPos < clientRect.Width) {
            y0 = yPos;
            y1 = yPos + ddy;
            x0 = xPos;
            x1 = xPos + ddx;
            // check top
            y = top;
            x = -(y - y0) * (x1 - x0) / (y1 - y0) + x0;
            if (x >= 0 && x <= right) {
                gr.DrawLine(pen, x0, y0, x, y);
            } else {
            // check right
                x = right;
                y = y0 - (x - x0) * (y1 - y0) / (x1 - x0);
                if (y >= top && y <= bottom) {
                    gr.DrawLine(pen, x0, y0, x, y);
                }
            }

            yPos += ddy;
            xPos += ddx;
        }

        yPos = beginyPos;
        xPos = beginxPos;
        while (yPos > -clientRect.Width) {
            gr.DrawLine(pen, xPos - ddx * scale, yPos - ddy * scale, xPos + 2 * ddx * scale, yPos + 2 * ddy * scale);
            yPos -= ddy;
            xPos += ddx;
        }
        gr.DrawLine(pen, xPos - ddx * scale, yPos - ddy * scale, xPos + 2 * ddx * scale, yPos + 2 * ddy * scale);
    }

    public override Draw (gr, ww, param): void {
        this.DrawGannGrid(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        this.DrawGannGrid(gr, ww, true);
        super.DrawSelection(gr, ww);
    }
}
