// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Instrument } from '../../Commons/cache/Instrument';
import { type IHistoryBusinessObject } from '../../Commons/cache/interface/IHistoryBusinessObject';
import { Resources } from '../../Localizations/Resources';
import { OperationType } from '../Trading/OperationType';
import { InstrumentTypes } from './InstrumentTypes';
import { QuotingType } from './QuotingType';
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class BoughtSoldUtils {
    private static IsLong (item: IHistoryBusinessObject): boolean {
        return item.BuySell === OperationType.Buy;
    }

    public static Bought (item: IHistoryBusinessObject): number {
        return this.calculateAmount(item, true);
    }

    public static BoughtStr (item: IHistoryBusinessObject): string {
        return this.calculateAmountStr(item, true);
    }

    public static Sold (item: IHistoryBusinessObject): number {
        return this.calculateAmount(item, false);
    }

    public static SoldStr (item: IHistoryBusinessObject): string {
        return this.calculateAmountStr(item, false);
    }

    private static calculateAmount (item: IHistoryBusinessObject, isBought: boolean): number {
        const instrument = item.Instrument;
        if (instrument == null) return 0;

        const isLong = this.IsLong(item);
        const quantity = item.getAmount();
        const price = item.Price;

        return this.CalculateBoughtSold(isLong, quantity, price, instrument, isBought);
    }

    private static calculateAmountStr (item: IHistoryBusinessObject, isBought: boolean): string {
        const instrument = item.Instrument;
        if (item.Price === 0 || instrument == null) {
            return isBought ? item.boughtString ?? '-' : item.soldString ?? '-';
        }

        const value = isBought ? this.Bought(item) : this.Sold(item);
        const isLong = this.IsLong(item);
        return this.FormatTextBoughtSold(instrument, value, isLong, isBought);
    }

    private static FormatTextBoughtSold (instrument: Instrument, value: number, isLong: boolean, isBought: boolean): string {
        const exp1Asset = instrument.DataCache.GetAssetByName(instrument.Exp1);
        const exp2Asset = instrument.DataCache.GetAssetByName(instrument.Exp2);

        const isPositiveTrade = (isLong && isBought) || (!isLong && !isBought);

        if (isPositiveTrade) {
            if (instrument.isFutureOrOption() || instrument.InstrType === InstrumentTypes.CFD_FUTURES) {
                return `${value} ${Resources.getResource('Instrument.Futures.contract')}`;
            } else if (instrument.InstrType === InstrumentTypes.SPREADBET) {
                return `${value} ${Resources.getResource('Instrument.Spreadbet.bet')}`;
            } else if (instrument.InstrType === InstrumentTypes.EQUITIES || instrument.InstrType === InstrumentTypes.ETF) {
                return `${value} ${instrument.ShortName}`;
            } else if (instrument.InstrType === InstrumentTypes.CORPORATE) {
                return `${value} ${Resources.getResource('Instrument.Corporate.Securities')}`;
            } else {
                return exp1Asset != null ? exp1Asset.formatPrice(value) : `${value} ${instrument.Exp1}`;
            }
        } else {
            return exp2Asset != null ? exp2Asset.formatPrice(value) : `${value} ${instrument.Exp2}`;
        }
    }

    private static CalculateBoughtSold (isLong: boolean, quantity: number, price: number, instrument: Instrument, isBought: boolean): number {
        const isPositiveTrade = (isLong && isBought) || (!isLong && !isBought);
        const lotSizeQuotingTypeBase = this.GetLotSizeQuotingTypeBase(instrument);

        if (isPositiveTrade) {
            return this.isForexCryptoEquities(instrument) ? quantity * lotSizeQuotingTypeBase : quantity;
        } else {
            return this.isForexCryptoEquities(instrument)
                ? quantity * price * lotSizeQuotingTypeBase
                : this.isFutureOptionSpreadbet(instrument)
                    ? quantity * price * this.GetLotSizeQuotingTypeBase(instrument, true)
                    : quantity;
        }
    }

    private static GetLotSizeQuotingTypeBase (instrument: Instrument, tickCost: boolean = false): number {
        return instrument.QuotingType === QuotingType.LotSize ? instrument.LotSize : (tickCost ? instrument.GetTickCost() : 1);
    }

    private static isFutureOptionSpreadbet (instrument: Instrument): boolean {
        return instrument.InstrType === InstrumentTypes.FUTURES ||
               instrument.InstrType === InstrumentTypes.OPTIONS ||
               instrument.InstrType === InstrumentTypes.CFD_FUTURES ||
               instrument.InstrType === InstrumentTypes.SPREADBET;
    }

    private static isForexCryptoEquities (instrument: Instrument): boolean {
        return instrument.InstrType === InstrumentTypes.FOREX ||
               instrument.InstrType === InstrumentTypes.CRYPTO ||
               instrument.InstrType === InstrumentTypes.EQUITIES ||
               instrument.InstrType === InstrumentTypes.EQUITIES_CFD ||
               instrument.InstrType === InstrumentTypes.CORPORATE;
    }
}
