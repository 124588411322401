// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { ChartMath } from '../Utils/ChartMath';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { CurveToolView } from './CurveToolView';
import { type TerceraChartCashItemSeries } from '../Series/TerceraChartCashItemSeries';
import { type CircleDataCacheTool } from '../../Commons/cache/Tools/CircleDataCacheTool';

export class CircleToolView extends CurveToolView<CircleDataCacheTool> {
    public override Draw (gr, ww, param): void {
        const lastCurvePoints = this.lastCurvePoints;
        if (!lastCurvePoints) return;

        const dataCacheTool = this.dataCacheTool;
        const fill = dataCacheTool.fill;
        const fillBrush = dataCacheTool.FillBrush;
        const pen = dataCacheTool.Pen;

        //
        // блок для сброса искажения. рисеут эллипс каким он станет в случае сброса масштаба, в случае смещения точки
        // выделена точка, рисуем контур модифая!
        if (this.CurrentSelection.SelectedPointNo >= 0) {
            let centerX, centerY, circleX, circleY, radius;
            const res = this.GetVariables(centerX, centerY, circleX, circleY, radius);
            centerX = res.centerX;
            centerY = res.centerY;
            circleX = res.circleX;
            circleY = res.circleY;
            radius = res.radius;

            const pX = centerX - radius;
            const pY = centerY - radius;
            const diameter = radius * 2;

            if (fill) {
                gr.FillEllipse(fillBrush, pX, pY, diameter, diameter);
            }

            gr.DrawEllipse(pen, pX, pY, diameter, diameter);

            super.Draw(gr, ww, param);
        }

        if (lastCurvePoints.length > 2) {
        // Ловим ошибки
        /*
        if (lastCurvePoints[0].X >= ProMath.infinity || lastCurvePoints[0].Y >= ProMath.infinity || lastCurvePoints[0].X <= ProMath.infinityMinus || lastCurvePoints[0].Y <= ProMath.infinityMinus)
        { }
        else */
            // {
            // рисуем эллипс по точкам
            gr.DrawClosedCurve(pen, lastCurvePoints);
            if (fill) {
                gr.FillClosedCurve(fillBrush, lastCurvePoints);
            }
            // }
        }

        super.Draw(gr, ww, param);
    }

    public override UpdateAdditionalPoints (ww, movePointNo, cashItemSeries: TerceraChartCashItemSeries): void {
    // только 2 базовые точки
        this.UpdateScreenPointsALL(ww, cashItemSeries, true);

        const newPts = this.getCirclePoints(ww, cashItemSeries);
        const newPts_len = newPts.length;

        const dataCacheTool = this.dataCacheTool;

        const temp = dataCacheTool.CopyPoints();

        for (let i = 0; i < newPts_len; i++) {
            temp[2 + i] = newPts[i];
        }

        dataCacheTool.Points = temp;
    }

    public getCirclePoints (ww, cashItemSeries): any[] {
        const dataCacheTool = this.dataCacheTool;
        const pc = ww.PointsConverter;

        const newAddPoints = [];

        let centerX, centerY, circleX, circleY, radius;
        const res = this.GetVariables(centerX, centerY, circleX, circleY, radius);
        centerX = res.centerX;
        centerY = res.centerY;
        circleX = res.circleX;
        circleY = res.circleY;
        radius = res.radius;

        if (!radius) return newAddPoints;

        /// !!! Будем рисовать эллипс по точкам. точек будем искать много чтоб не страдала точность прорисовки кривой по точкам.
        /// http://stackoverflow.com/questions/4467121/solutions-for-y-for-a-rotated-ellipse
        /// уравнение эллипса  x = a*cos(t)*cos(w) - b*sin(t)*sin(w),  y = a*cos(t)*sin(w) + b*sin(t)*cos(w)
        /// где а и b - большая и меньшая оси эллипса соответственно,
        /// w - угол поворота эллипса, t - направление на точку в полярных координатах
        /// х0 и у0 - центр эллипса.
        ///
        const w = 0;
        const a = radius;
        let t = 0;
        const b = radius;

        const incr = 6.28 / dataCacheTool.AdditionalPointsCount();
        for (t = 0; t < 6.28; t += incr)// fix 6,28 чтоб точки не накладывались в петельку
        {
            const xt = a * Math.cos(t) * Math.cos(w) - b * Math.sin(t) * Math.sin(w);
            const yt = b * Math.cos(w) * Math.sin(t) + a * Math.cos(t) * Math.sin(w);
            // relative
            if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Relative) {
                newAddPoints.push([
                    pc.GetDataX(xt + centerX),
                    cashItemSeries.settings.relativeDataConverter.Revert(pc.GetDataY(centerY + yt))
                ]);
            }
            // log
            else if (cashItemSeries && cashItemSeries.settings.DataType === TerceraChartCashItemSeriesDataType.Log) {
                newAddPoints.push([
                    pc.GetDataX(xt + centerX),
                    cashItemSeries.settings.logDataConverter.Revert(pc.GetDataY(centerY + yt))
                ]);
            }
            // absolute
            else {
                newAddPoints.push([
                    pc.GetDataX(xt + centerX),
                    pc.GetDataY(centerY + yt)
                ]);
            }
        }

        return newAddPoints;
    }

    public override DrawSelectedLine (gr, ww, x1: number, y1: number, x2: number, y2: number): void {
        gr.DrawLine(this.dataCacheTool.Pen, x1, y1, x2, y2);
        super.DrawSelectedLine(gr, ww, x1, y1, x2, y2);
    }

    public GetVariables (centerX, centerY, circleX, circleY, radius) {
        const screenPoints = this.screenPoints;
        const scrP0 = screenPoints[0];
        const scrP1 = screenPoints[1];

        centerX = scrP0[0];
        centerY = scrP0[1];
        circleX = scrP1[0];
        circleY = scrP1[1];

        radius = ChartMath.CalcDistanceFromPointToPoint(
            new Point(centerX, centerY),
            new Point(circleX, circleY));

        return {
            centerX,
            centerY,
            circleX,
            circleY,
            radius
        };
    }
}
