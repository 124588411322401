// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TvBaseFormatter } from './TvBaseFormatter';

export class TvPriceFormatter extends TvBaseFormatter {
    private readonly alignToMinMove;

    constructor (symbol: string, alignToMinMove: boolean = false) {
        super(symbol);
        this.alignToMinMove = alignToMinMove;
    }

    public override format (value: number): string {
        if (this.instrument) return this.instrument.formatPrice(value);

        return value.toString();
    }
}
