// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectQuoteMessage } from './DirectQuoteMessage';
import { HistoryType } from '../../Utils/History/HistoryType';
import { Message } from './Message';

export class DirectInstrumentPricesMessage extends DirectQuoteMessage {
    public Type = HistoryType.QUOTE_INSTRUMENT_PRICES;
    public IndicativeAuctionPrice: number | null = null;
    public IndicativeAuctionVolume: number | null = null;
    public LastTradedTime: Date | null = null;
    public AvgTradedPrice: number | null = null;
    public TotalBuyQuantity: number | null = null;
    public TotalSellQuantity: number | null = null;
    public AuctionNumber: number | null = null;
    public AuctionStatus: number | null = null;
    public InitiatorType: number | null = null;
    public InitiatorPrice: number | null = null;
    public InitiatorQuantity: number | null = null;
    public HighOpenInterest: number | null = null;
    public LowOpenInterest: number | null = null;
    // #73350
    public TradedValue: number | null = null;
    public OffExchangeVolume: number | null = null;
    public OffExchangeValue: number | null = null;
    // ------
    public AuctionEndTime: string | null = null;
    public RemainingQty: number | null = null;
    public RemainingQtySide: number | null = null;
    // #113099
    public LPPHigh: number | null = null;
    public LPPLow: number | null = null;

    public FundingRateValue: number | null = null;
    public MarkPrice: number | null = null;
    public NextFundingTimeUTC: Date | null = null;

    constructor () {
        super(Message.CODE_INSTRUMENT_PRICES);
    }
}
