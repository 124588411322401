// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { type AlertAction, type AlertAfterExecute, type AlertCondition, type AlertImportance, type AlertNotification, type AlertType } from '../../../../Utils/Alert/AlertConstants';

export class ReplaceAlertMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_REPLACE_ALERT_MESSAGE, fieldSet);
    }

    public getAlertId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_ID);
    }

    public setAlertId (alertId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_ID, alertId);
    }

    public getTradableInstrumentId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
    }

    public setTradableInstrumentId (instrumentId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID, instrumentId);
    }

    public getRouteId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ROUTE_ID);
    }

    public setRouteId (routeId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ROUTE_ID, routeId);
    }

    public getAlertType (): AlertType {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_TYPE);
    }

    public setAlertType (alertType: AlertType): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_TYPE, alertType);
    }

    public getAlertCondition (): AlertCondition {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_CONDITION);
    }

    public setAlertCondition (alertCondition: AlertCondition): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_CONDITION, alertCondition);
    }

    public getAlertValue (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_VALUE);
    }

    public setAlertValue (alertValue: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_VALUE, alertValue);
    }

    public getAlertImportance (): AlertImportance {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_IMPORTANCE);
    }

    public setAlertImportance (alertImportance: AlertImportance): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_IMPORTANCE, alertImportance);
    }

    public getAlertDate (): Date {
        return this.getFieldValue(FieldsFactory.FIELD_DATE);
    }

    public setAlertDate (alertDate: Date): void {
        this.setFieldValue(FieldsFactory.FIELD_DATE, alertDate);
    }

    public getAlertAfterExecute (): AlertAfterExecute {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_AFTER_EXECUTE);
    }

    public setAlertAfterExecute (alertAfterExecute: AlertAfterExecute): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_AFTER_EXECUTE, alertAfterExecute);
    }

    public getAlertAction (): AlertAction {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_ACTION);
    }

    public setAlertAction (alertAction: AlertAction): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_ACTION, alertAction);
    }

    public getAlertNotification (): AlertNotification {
        return this.getFieldValue(FieldsFactory.FIELD_ALERT_NOTIFICATION);
    }

    public setAlertNotification (alertNotification: AlertNotification): void {
        this.setFieldValue(FieldsFactory.FIELD_ALERT_NOTIFICATION, alertNotification);
    }

    public getAlertText (): string {
        return this.getFieldValue(FieldsFactory.FIELD_TEXT);
    }

    public setAlertText (alertText: string): void {
        this.setFieldValue(FieldsFactory.FIELD_TEXT, alertText);
    }

    public setAlertLeverage (alertLeverage: number): void {
        this.setFieldValue(FieldsFactory.FIELD_LEVERAGE_SHORT, alertLeverage);
    }

    public setAlertAccountId (accountId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ACCOUNT_ID, accountId);
    }

    public getAlertAccountId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_ACCOUNT_ID);
    }
}
