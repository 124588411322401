// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { type ArrowLabelDataCacheTool } from '../../Commons/cache/Tools/ArrowLabelDataCacheTool';
import { ProMath, ToolView } from './ToolView';

export class ArrowLabelToolView extends ToolView<ArrowLabelDataCacheTool> {
    public override Draw(gr: CanvasRenderingContext2D, ww, param): void {
        try {
            const cacheTool = this.dataCacheTool;
            if (cacheTool == null) { return; }

            ToolView.DrawLineWithRay(gr, ww, this.screenPoints[0][0], this.screenPoints[0][1], this.screenPoints[1][0], this.screenPoints[1][1],
                this.dataCacheTool.RayType, this.dataCacheTool.Pen, this.dataCacheTool.PenRay);
    
            const lineWidth = this.dataCacheTool.Pen.Width;
            const arrowWidth = lineWidth >= 4 ? lineWidth * 3 : 10;
            const arrowPoints = this.getArrowPoints([this.screenPoints[0][0], this.screenPoints[0][1]], [this.screenPoints[1][0], this.screenPoints[1][1]], arrowWidth);
    
            gr.beginPath();
            gr.moveTo(arrowPoints[0][0], arrowPoints[0][1]);
            gr.lineTo(arrowPoints[1][0], arrowPoints[1][1]);
            gr.lineTo(arrowPoints[2][0], arrowPoints[2][1]);
            gr.closePath();
    
            gr.fillStyle = this.dataCacheTool.color;
            gr.fill();
    
            super.Draw(gr, ww, param);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public getArrowPoints(
        startPoint: [number, number],
        endPoint: [number, number],
        arrowSize: number
    ): Array<[number, number]> {
        if (
            startPoint[0] >= ProMath.infinity || startPoint[1] >= ProMath.infinity ||
            startPoint[0] <= ProMath.infinityMinus || startPoint[1] <= ProMath.infinityMinus
        ) {
            return [];
        }
    
        const dx = endPoint[0] - startPoint[0];
        const dy = endPoint[1] - startPoint[1];
        const angle = Math.atan2(dy, dx);
        const arrowAngle = Math.PI / 7;
    
        const padding = -(arrowSize / 2);
        const paddedEndPoint: [number, number] = [
            endPoint[0] - padding * Math.cos(angle),
            endPoint[1] - padding * Math.sin(angle)
        ];
    
        const x1 = paddedEndPoint[0] - arrowSize * Math.cos(angle - arrowAngle);
        const y1 = paddedEndPoint[1] - arrowSize * Math.sin(angle - arrowAngle);
        const x2 = paddedEndPoint[0] - arrowSize * Math.cos(angle + arrowAngle);
        const y2 = paddedEndPoint[1] - arrowSize * Math.sin(angle + arrowAngle);
    
        return [
            paddedEndPoint,
            [x1, y1],
            [x2, y2]
        ];
    }
    
    
    
    
}
