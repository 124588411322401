// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { ProductType } from '../../Utils/Instruments/ProductType';
import { OrderType } from '../../Utils/Trading/OrderType';
import { OrderTif } from '../../Utils/Trading/OrderTifEnum';
import { SLTPTrigger } from '../../Utils/SlTpTrigger';
import { AlertAfterExecute, AlertCondition, AlertImportance, AlertNotification, AlertType } from '../../Utils/Alert/AlertConstants';
import { InsDefSettingsStorage } from '../cache/InstrumentDefaults';
import { CustomEvent } from '../../Utils/CustomEvents';

export abstract class _BaseGeneralSettings {
    // Need remove in the future begins block
    public settingsChanged: CustomEvent = new CustomEvent();
    protected _insDefSettingsStorage: InsDefSettingsStorage | null = null;
    get insDefSettingsStorage (): InsDefSettingsStorage {
        if (this._insDefSettingsStorage === null) {
            this._insDefSettingsStorage = new InsDefSettingsStorage();
        }
        return this._insDefSettingsStorage;
    }

    set insDefSettingsStorage (storage: InsDefSettingsStorage) {
        this._insDefSettingsStorage = storage;
    }

    public settingsChangedRaise (): void {
        this.settingsChanged.Raise();
    }
    // ends block

    public reset (): void {}

    protected excludeSerialize = [
        'excludeSerialize',
        'settingsChanged',
        'settingsNeedSave',
        '_insDefSettingsStorage'];

    public restore (newSettings: _BaseGeneralSettings): void {
        for (const key in newSettings) {
            if (!this.excludeSerialize.includes(key)) {
                if (newSettings.hasOwnProperty(key)) {
                    this[key] = newSettings[key];
                }
            }
        }
    }

    // GENERAL SETTINGS
    public language = 'en';
    public currentTheme = 'dark';
    public onboardInFirstTimeShowed = false;
    public disableInactivityPeriod = false;
    public logSnapshotHasBeenSent = false;
    private _isCashModePreferred = false;

    // TRADING DEFAULT SETTINGS
    public tradingAccountID: string | null = null;
    public tradingSymbolID: string | null = null;
    private _isSlTpInOffset = false;
    private _offsetType = OffsetModeViewEnum.Ticks;

    /**
     * Only for save/load. Use method {@link _BaseGeneralSettings.isUseStopLimitInsteadStop}
    *@type {boolean}
    */
    public useStopLimitInsteadStop = false;
    public limitOffsetTicks = 1000;

    private _orderType = OrderType.Market;
    private _productType = ProductType.General;
    private _orderTif = OrderTif.IOC;
    public limitAndStopLimitValidity = OrderTif.GTC;
    public stopValidity = OrderTif.GTC;
    public typesManagerSettings: any = null;
    public slTpTriggers = new SLTPTrigger(10);

    get isSlTpInOffset (): boolean { return this._isSlTpInOffset; }
    set isSlTpInOffset (value: boolean) {
        this._isSlTpInOffset = value;
        this.onSettingsChanged();
    }

    get offsetType (): OffsetModeViewEnum { return this._offsetType; }
    set offsetType (value: OffsetModeViewEnum) {
        this._offsetType = value;
        this.onSettingsChanged();
    }

    get orderType (): OrderType { return this._orderType; }
    set orderType (value: OrderType) {
        this._orderType = value;
        this.onSettingsChanged();
    }

    get productType (): ProductType { return this._productType; }
    set productType (value: ProductType) {
        this._productType = value;
        this.onSettingsChanged();
    }

    get orderTif (): OrderTif { return this._orderTif; }
    set orderTif (value: OrderTif) {
        this._orderTif = value;
        this.onSettingsChanged();
    }

    get isCashModePreferred (): boolean { return this._isCashModePreferred; }
    set isCashModePreferred (value: boolean) {
        if (this._isCashModePreferred !== value) {
            this._isCashModePreferred = value;
            // this.onSettingsChanged(); /* #124573 */
        }
    }

    // CONFIRMATION SETTINGS
    private _confirmOrderSending = true;
    private _confirmOrderCancel = true;
    private _confirmOrderExecuting = true;
    private _confirmOrderModification = true;
    private _confirmOrderPositionsModification = true;

    private _confirmPositionClosing = true;
    private _confirmReversePosition = true;
    private _confirmPositionModification = true;
    private _confirmSubscriptions = true; //  Регулирует отображение скринов при подписке (скрин Subscription)/отказе от подписки (скрин Unsubscribe) на продукт. #104359
    private _confirmAccountFollowing = true; //  #123699
    private _positionSizeCalculatorInfoWindow = true;

    private _openDialTicketsOnWorkspace = false;
    private _showMarginDealTicketOnWorkspace = true;
    private _showDialTicketsInApplicationTray = true;
    private _showInformationMessageAboutSubscribeUnsubscribe = true;
    private _displayQuantityInLots = true;

    get confirmOrderSending (): boolean { return this._confirmOrderSending; }
    set confirmOrderSending (value: boolean) {
        this._confirmOrderSending = value;
        this.onSettingsChanged();
    }

    get confirmOrderCancel (): boolean { return this._confirmOrderCancel; }
    set confirmOrderCancel (value: boolean) {
        this._confirmOrderCancel = value;
        this.onSettingsChanged();
    }

    get confirmOrderExecuting (): boolean { return this._confirmOrderExecuting; }
    set confirmOrderExecuting (value: boolean) {
        this._confirmOrderExecuting = value;
        this.onSettingsChanged();
    }

    get confirmOrderModification (): boolean { return this._confirmOrderModification; }
    set confirmOrderModification (value: boolean) {
        this._confirmOrderModification = value;
        this.onSettingsChanged();
    }

    get confirmOrderPositionsModification (): boolean { return this._confirmOrderPositionsModification; }
    set confirmOrderPositionsModification (value: boolean) {
        this._confirmOrderPositionsModification = value;
        this.onSettingsChanged();
    }

    get confirmPositionClosing (): boolean { return this._confirmPositionClosing; }
    set confirmPositionClosing (value: boolean) {
        this._confirmPositionClosing = value;
        this.onSettingsChanged();
    }

    get confirmReversePosition (): boolean { return this._confirmReversePosition; }
    set confirmReversePosition (value: boolean) {
        this._confirmReversePosition = value;
        this.onSettingsChanged();
    }

    get confirmPositionModification (): boolean { return this._confirmPositionModification; }
    set confirmPositionModification (value: boolean) {
        this._confirmPositionModification = value;
        this.onSettingsChanged();
    }

    get confirmSubscriptions (): boolean { return this._confirmSubscriptions; }
    set confirmSubscriptions (value: boolean) {
        this._confirmSubscriptions = value;
        this.onSettingsChanged();
    }

    get confirmAccountFollowing (): boolean { return this._confirmAccountFollowing; }
    set confirmAccountFollowing (value: boolean) {
        this._confirmAccountFollowing = value;
        this.onSettingsChanged();
    }

    get positionSizeCalculatorInfoWindow (): boolean { return this._positionSizeCalculatorInfoWindow; }
    set positionSizeCalculatorInfoWindow (value: boolean) {
        this._positionSizeCalculatorInfoWindow = value;
        this.onSettingsChanged();
    }

    get openDialTicketsOnWorkspace (): boolean { return this._openDialTicketsOnWorkspace; }
    set openDialTicketsOnWorkspace (value: boolean) {
        this._openDialTicketsOnWorkspace = value;
        this.onSettingsChanged();
    }

    get showMarginDealTicketOnWorkspace (): boolean { return this._showMarginDealTicketOnWorkspace; }
    set showMarginDealTicketOnWorkspace (value: boolean) {
        this._showMarginDealTicketOnWorkspace = value;
        this.onSettingsChanged();
    }

    get showDialTicketsInApplicationTray (): boolean { return this._showDialTicketsInApplicationTray; }
    set showDialTicketsInApplicationTray (value: boolean) {
        this._showDialTicketsInApplicationTray = value;
        this.onSettingsChanged();
    }

    get showInformationMessageAboutSubscribeUnsubscribe (): boolean { return this._showInformationMessageAboutSubscribeUnsubscribe; }
    set showInformationMessageAboutSubscribeUnsubscribe (value: boolean) {
        this._showInformationMessageAboutSubscribeUnsubscribe = value;
        this.onSettingsChanged();
    }

    get isQuantityInLots (): boolean { return this._displayQuantityInLots; }
    set isQuantityInLots (value: boolean) {
        this._displayQuantityInLots = value;
        this.onSettingsChanged();
    }

    // ALERT SETTINGS
    public confirmPlaceAlert = false;
    public confirmCancelAlert = true;
    private _alertNotification = AlertNotification.SHOW_POP_UP + AlertNotification.SOUND;
    private _alertType = AlertType.LAST;
    private _alertCondition = AlertCondition.MORE;
    private _alertImportance = AlertImportance.MEDIUM;
    private _alertAfterExecute = AlertAfterExecute.REMOVE;

    get alertNotification (): AlertNotification { return this._alertNotification; }
    set alertNotification (value: AlertNotification) {
        this._alertNotification = value;
        this.onSettingsChanged();
    }

    get alertType (): AlertType { return this._alertType; }
    set alertType (value: AlertType) {
        this._alertType = value;
        this.onSettingsChanged();
    }

    get alertCondition (): AlertCondition { return this._alertCondition; }
    set alertCondition (value: AlertCondition) {
        this._alertCondition = value;
        this.onSettingsChanged();
    }

    get alertImportance (): AlertImportance { return this._alertImportance; }
    set alertImportance (value: AlertImportance) {
        this._alertImportance = value;
        this.onSettingsChanged();
    }

    get alertAfterExecute (): AlertAfterExecute { return this._alertAfterExecute; }
    set alertAfterExecute (value: AlertAfterExecute) {
        this._alertAfterExecute = value;
        this.onSettingsChanged();
    }

    // WARNING SETTINGS
    public warnIfWrongOrder = true;
    public warnIfIdenticalSubsequentOrderIsPlaced = false;
    public warnIfIdenticalOrderTime = 3;
    public warnIfQtyToCloseMoreThanMaxLot = false;
    public overnightMarginNotificationMessage = true;
    public warnWhenEnteringIntoLeverage = true;
    public warnIfOrderQtyExceed = false;
    public warnIfOrderQtyExceedValue = 100;
    public warnIfOrderCapitalExceed = false;
    public warnIfOrderCapitalExceedValue = 2000000;
    public warnIfTodayGrossLess = false;
    public warnIfTodayGrossLessValue = 0;
    public warnIfAvailibleFundsLess = false;
    public warnIfAvailibleFundsLesssValue = 1;
    public warnIfTodayVolumeExeed = false;
    public warnIfTodayVolumeExeedValue = 1;
    public showInstrumentHalt = false;

    // VIEW SETTINGS
    public roundedAverageOpenPrice = true;
    public reverseButtonsOrder = false;
    public tooltips = true;

    // SOUND SETTINGS
    public enableSounds = true;
    public disableStartEndSound = true;
    public enableWelcomeSound = false;
    public enableFinishSound = false;

    public isUseStopLimitInsteadStop (): boolean {
        return this.useStopLimitInsteadStop && this.offsetType !== OffsetModeViewEnum.Percent;
    }

    public onSettingsChanged (): void {
        this.settingsChangedRaise();
    }
}
