// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type ISettingsAdapter, type InitialSettingsMap } from '../charting_library';

class _TvSettingsAdapter implements ISettingsAdapter {
    public initialSettings: InitialSettingsMap = {};

    public setValue (key: string, value: string): void {
        this.initialSettings[key] = value;
    }

    public removeValue (key: string): void {
        if (this.initialSettings.hasOwnProperty(key)) {
            delete this.initialSettings[key];
        }
    }

    public clear (): void {
        this.initialSettings = {};
    }
}

export const TvSettingsAdapter = new _TvSettingsAdapter();
