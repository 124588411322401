// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { HistoryType } from '../../Utils/History/HistoryType';
import { TerceraChartRactive } from '../../Controls/elements/TerceraChartRactive/TerceraChartRactive';
import { ModelDataType, TerceraChartMVCCommand } from '../../Chart/TerceraChartMVC';
import { TerceraChartDrawingType } from '../../Chart/Utils/ChartConstants';
import { InvestingChartSmallTemplate } from '../templates.js';
import { PeriodDesc } from '../../Utils/History/TFInfo';
import { DataCache } from '../../Commons/DataCache';
import { Control } from '../../Controls/elements/Control';

export class InvestingChartSmall extends TerceraChartRactive {
    public terceraChart: any = null;

    public applyCursor (cursor): void {
        void this.setCursor(cursor);
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.observe('instrumentItem', this.instrumentChanged);
        Control.Ticker.Subscribe(this.TickAsync, this);
        this.observe('chartHeight', (n, o) => { this.setChartPosition(); });
    }

    public TickAsync (): void {
        if (this.terceraChart?.needRedraw) {
            this.terceraChart.needRedraw = false;
            this.terceraChart.Draw();
        }
    }

    public instrumentChanged (newInstrument, oldInstrument): void {
        if (!newInstrument) {
            return;
        }

        const terceraChart = this.terceraChart;
        const controller = terceraChart.chartController;

        if (oldInstrument) {
            this.unsubscribe(oldInstrument);
        }

        this.subscribe(newInstrument);

        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Instrument), newInstrument);

        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Account), DataCache.getPrimaryAccount());

        terceraChart.chartController.SuspendRefreshChart = false;
        terceraChart.RefreshChart();

        terceraChart.GetContextMenu = function () { };

        this.initChartTheme();
        this.onTimeFrameComboBox_ComboItemClicked();
        this.setChartPosition();
    }

    public setChartPosition (): void {
        const left = 15;
        const top = 100;
        const width = this.get('chartWidth');
        const height = this.get('chartHeight');

        this.setBounds(left, top, width, height);
        this.terceraChart.OnResize(Math.abs(width), Math.abs(height));
    }

    public initChartTheme (): void {
        const terceraChart = this.terceraChart;
        const controller = terceraChart.chartController;

        controller.terceraChart.model.SetChartDrawingType(TerceraChartDrawingType.Solid); // Line style

        // terceraChart.TerceraChartWatermarkRenderer.ForeBrush = new SolidBrush(theme.ChartWatermarkColor)

        // terceraChart.TerceraChartFont.Height = 10

        const tradingToolsRenderer = terceraChart.TerceraChartTradingToolsRenderer;

        if (tradingToolsRenderer) {
            tradingToolsRenderer.ShowOrders = false;
            tradingToolsRenderer.ShowPositions = false;
            tradingToolsRenderer.ShowVisualTradingOnLeftSide = false;
            tradingToolsRenderer.ShowEvents = false;
            tradingToolsRenderer.ShowAlerts = false;
        }

        const yScaleRendererSettings = terceraChart.yScaleRendererSettings;

        if (yScaleRendererSettings) {
            yScaleRendererSettings.AutoScaleSwitcherVisible = false;
        }

        if (terceraChart.TerceraChartInfoWindowRenderer) {
            terceraChart.TerceraChartInfoWindowRenderer.Visible = false;
        }
        if (terceraChart.TerceraChartNewAlertRenderer) {
            terceraChart.TerceraChartNewAlertRenderer.Visible = false;
        }

        if (terceraChart.windowsContainer?.scrollerRenderer) {
            terceraChart.windowsContainer.scrollerRenderer.Visible = false;
        }
    }

    public onTimeFrameComboBox_ComboItemClicked (): void {
        const model = this.terceraChart.model;
        const oldTFInfo = model.GetTimeFrameInfo();
        const controller = this.terceraChart.chartController;
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.TFI), oldTFInfo.Copy({ period: 1 }));
        controller.ExecuteCommand(new TerceraChartMVCCommand(ModelDataType.Range), new PeriodDesc(1));
    }

    public override onteardown (): void {
        Control.Ticker.UnSubscribe(this.TickAsync, this);
    }

    public subscribe (instrument): void {
        if (!instrument) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChart;

        qc.addListener(instrument, chart, HistoryType.QUOTE_LEVEL1);
    }

    public unsubscribe (instrument): void {
        if (!instrument) {
            return;
        }

        const qc = DataCache.FQuoteCache;
        const chart = this.terceraChart;

        qc.removeListener(instrument, chart, HistoryType.QUOTE_LEVEL1);

        chart.unsubscribeTrades();
        chart.unsubscribeCashItemFromInstrument();
    }
}

TerceraChartRactive.extendWith(InvestingChartSmall, {
    template: InvestingChartSmallTemplate,
    data: function () {
        return {
            // backgroundColorTop: 'purple',
            backgroundColorBottom: 'rgb(16, 26, 36)',
            currentCursor: 'default',
            terceraChartPanelContext: null,
            instrumentItem: null,
            chartWidth: 600,
            chartHeight: 270
        };
    },
    computed: {
        terceraChartPanelContext: {
            get: function () { return this; },
            set: function (value) { }
        }
    }
});
