// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { contextMenuHandler } from '@shared/utils/AppHandlers';
import { MessageBoxType, TerceraMessageBox } from '../screen/TerceraMessageBox';
import { TerceraRenameScreen } from '../screen/TerceraRenameScreen';
import { Control } from './Control';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { WatchlistComboBoxTemplate } from '../../templates.js';
import $ from 'jquery';
import { type _WatchlistManager, WatchlistManager } from '@shared/utils/Managers/WatchlistManager/WatchlistManager';
import { WatchlistSheetItem } from '@shared/utils/Managers/WatchlistManager/WatchlistSheetItem';
import { _WatchlistSheets } from '@shared/utils/Managers/WatchlistManager/WatchlistSheets';

export class WatchlistComboBox extends Control {
    public static readonly DEFAUL_VALUE_SEPARATOR = 1433;
    public static readonly VALUE_DEFAUL = 0;

    public menuItems: any = null;
    public isMenuShown = false;
    public OnSelectList = new CustomEvent();
    public ownerID: any;

    private get wlManager (): _WatchlistManager { return WatchlistManager; }

    public override getType (): string { return 'WatchlistComboBox'; }

    get selectedItemName (): string {
        return this.get('selectedItem');
    }

    set selectedItemName (name: string) {
        void this.set('selectedItem', name);

        const item = this.wlManager.getItemByName(name);
        this.OnSelectList.Raise(item.getSymbolIds());
    }
  
    constructor () {
        super();
    }

    public oninit (): void {
        this.observe('items', this.onItemsChanged);
        this.observe('selectedItem', this.onSelectedItemChanged);
    }

    public getSelectedItem (): WatchlistSheetItem | null {
        return this.get('selectedItem');
    }

    public setSelectedItemSymbols (symboldIDs: string[]): void {
        const item = this.wlManager.getItemByName(this.selectedItemName);
        item.set(new Set(symboldIDs));
        this.wlManager.saveStorageByItem(item);
    }

    public getItems (): WatchlistSheetItem[] {
        return Array.from(this.wlManager.getItems());
    }

    public getFullItems (): any[] {
        const items: any[] = Array.from(this.wlManager.getItems());
        // add separator
        items.push({ separator: true, value: WatchlistComboBox.DEFAUL_VALUE_SEPARATOR });
        // add create new button
        items.push({ name: Resources.getResource(this.get('createNew')), value: -1, noImages: true });

        return items;
    }

    public override oncomplete (): void {
        super.oncomplete();

        WatchlistSheetItem.symbolsChanged.Subscribe(this.onSymbolsChanged, this);
        _WatchlistSheets.itemsChanged.Subscribe(this.onItemsChanged, this);

        if (isNullOrUndefined(this.selectedItemName) || this.wlManager.getItemByName(this.selectedItemName) === null) {
            this.selectedItemName = this.wlManager.getFirstItem().name;
        }
    }

    public override dispose (): void {
        WatchlistSheetItem.symbolsChanged.UnSubscribe(this.onSymbolsChanged, this);
        _WatchlistSheets.itemsChanged.UnSubscribe(this.onItemsChanged, this);

        super.dispose();
    }

    public onSymbolsChanged (): void {
        const item = this.wlManager.getItemByName(this.selectedItemName);
        this.OnSelectList.Raise(item.getSymbolIds());
    }

    public onItemsChanged (): void {
        this.menuItems = this.createMenuItems();
        void this.set('items', this.menuItems);
    }

    public showMenu (): void {
        const width = $(this.find('div')).outerWidth();
        const offset = $(this.find('div')).offset();
        const posY = offset.top - $(window).scrollTop() + 26;
        const posX = offset.left - $(window).scrollLeft();

        const additionalParams = {
            width: this.get('listWidth') || width,
            isComboboxMenu: true,
            isEditableListComboBoxMenu: true,
            isTemplateMenu: this.get('saveImage')
        };

        this.isMenuShown = true;
        contextMenuHandler.SetCallerControl(this);
        contextMenuHandler.Show(this.menuItems, posX, posY, additionalParams);
    }

    public hideMenu (): void {
        contextMenuHandler.Hide();
    }

    public smallBtnAction (element, key: number): void {
        if (key === 1) { /* rename logic */
            TerceraRenameScreen.show(element.text, this.renameItem.bind(this, element), Resources.getResource(this.get('renameHeader')));
        }
        if (key === 2) { /* delete logic */
            this.deleteItemConfirm(element);
        }
    }

    public private_OnMenuItemSelected (menuItem): void {
        this.selectedItemName = menuItem.text;
        this.isMenuShown = false;
    }

    public private_BtnClick (sender): void {
        const isEnabled: boolean = this.get('enabled');
        if (!isEnabled) { return; }

        if (this.isMenuShown) {
            this.isMenuShown = !this.isMenuShown;
            this.hideMenu();
            return;
        }

        this.showMenu();
    }

    public restoreCorrectSelectItem (selectedText: string): void {
        const items = this.getFullItems();
        for (let i = 0; i < items.length; i++) {
            if (items[i].name === selectedText) {
                this.selectedItemName = selectedText;
                this.onItemsChanged();
                break;
            }
        }
    }

    public createNewClicked (): void {
        const header = this.get('newItemHeader');
        const preferredName = this.wlManager.getPreferredItemName();

        TerceraRenameScreen.show(preferredName, this.createItem.bind(this), Resources.getResource(header));
    }

    public createItem (listName: string): void {
        const header = this.get('newItemHeader');
        const error = this.wlManager.addItem(listName);
        if (error != null) {
            this.showError(listName, error, Resources.getResource(header), this.createItem.bind(this));
        }
        this.selectedItemName = listName;
        this.onItemsChanged();
    }

    public renameItem (element, listName: string): void {
        const header = this.get('renameHeader');
        const error = this.wlManager.renameItem(element.text, listName);
        if (error != null) {
            this.showError(listName, error, Resources.getResource(header), this.renameItem.bind(this));
        }
        this.onItemsChanged();
    }

    public deleteItemConfirm (element): void {
        if (!element) { return; }

        const error = this.wlManager.validateItemRemove();
        if (error != null) {
            const header = Resources.getResource('screen.error.title');
            this.showError(element.text, error, Resources.getResource(header), this.deleteItemConfirm.bind(this));
            // TODO: return; ???
        }

        TerceraMessageBox.Show(
            Resources.getResource('screen.remove.title'),
            `${Resources.getResource('screen.remove.confirmText.firstPart')}'${element.text}'${Resources.getResource(this.get('removeTxtSecondPart'))}`,
            MessageBoxType.Question,
            () => { this.deleteItem(element); });
    }

    public deleteItem (element): void {
        this.wlManager.removeItem(element.text);
        this.onItemsChanged();
    }

    public onSelectedItemChanged (newValue, oldValue): void {
        if (isNullOrUndefined(newValue) || (newValue === oldValue)) { return; }

        this.updateSelection(newValue);
    }

    protected updateSelection (selectedName): void {
        if (isNullOrUndefined(this.menuItems)) return;
        for (const item of this.menuItems) {
            item.selected = item.text === selectedName;
        }
    }

    private showError (newName: string, error: string, renameScreenHeader, renameScreenCallBack): void {
        TerceraMessageBox.Show(Resources.getResource('screen.error.title'), error, MessageBoxType.Error, null, null, false, true, null, { zIndex: 2100 });
        TerceraRenameScreen.show(newName, renameScreenCallBack, renameScreenHeader);
    }

    public createMenuItems (): any[] {
        const items: any[] = this.getFullItems();
        const menuItems = [];
        const len = items.length;
        const selectedItem = this.wlManager.getItemByName(this.selectedItemName);
        const hasSaveImage = false;

        for (let i = 0; i < len; i++) {
            const item = items[i];
            const mItem = item.separator
                ? item
                : {
                    text: item.name,
                    tag: item.value ?? i,
                    enabled: true,
                    event: i + 1 < len ? this.private_OnMenuItemSelected.bind(this) : this.createNewClicked.bind(this),
                    smallEvent: this.smallBtnAction.bind(this),
                    hasImages: !item.noImages,
                    hasSaveImage,
                    imagewidth: this.get('imagewidth') || 25,
                    canCheck: true,
                    checked: selectedItem ? selectedItem.name === item.name : false,
                    hasPlusImg: item.noImages && hasSaveImage,
                    tooltip: item.noImages ? this.get('createNewTooltip') : '',
                    noCheckMark: true
                };

            menuItems.push(mItem);
        }

        return menuItems;
    }

    public localize (): void {
        const menu = this.menuItems;
        if (menu) {
            menu[menu.length - 1].text = Resources.getResource(this.get('createNew'));
        }
    }
}

Control.extendWith(WatchlistComboBox, {
    template: WatchlistComboBoxTemplate,
    data: function () {
        return {
            checkedMenuItem: null,
            storageName: null,
            saveImage: false,
            renameHeader: 'screen.renameScreen.rename.header',
            newItemHeader: 'screen.renameScreen.newList.header',
            createNew: 'editableComboBox.CreateNew',
            createNewTooltip: 'editableComboBox.CreateNew.tooltip',
            removeTxtSecondPart: 'screen.remove.confirmText.secondPart',
            enabled: true,
            showLastValue: true,
            defaultValue: WatchlistComboBox.VALUE_DEFAUL,
            items: [],
            selectedItem: null,
            tooltip: '',
            elementStyle: 'js-comboBox',
            additionalClass: '',
            listWidth: 0,
            useSVGView: false,
            usePNGView: false,
            showArrow: true,
            visibleInfoBtn: false,
            infoBtnTooltip: '',
            updateComboBoxClass: '',
            isPosAbsolute: false
        };
    }
});

// Events
export enum WatchlistComboBoxEvents {
    ValueChange = 'ValueChange',
    ComboItemClicked = 'ComboItemClicked'
}
