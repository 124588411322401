// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { Rectangle } from '../../../Commons/Geometry';
import { Cursors } from '../../../Commons/Cursors';
import { DrawPointer, DrawPointerTypeEnum } from '../../Utils/DrawPointer';
import { LayersEnum } from '../../Renderers/TerceraChartBaseRenderer';
import { ProMath } from '../ToolView';
import { AlertUtils } from '../../../Utils/Alert/AlertUtils';
import { TerceraChartTradingOperation } from '../../Utils/ChartConstants';
import { TerceraChartHistoryType } from '../../TerceraChartUtils';
import { TradingToolViewBase, TradingToolViewType } from './TradingToolViewBase';
import { ToolSelectionType } from '../Selection';
import { AlertCondition, AlertImportance } from '../../../Utils/Alert/AlertConstants';
import { type TerceraChartCashItemSeries } from '../../Series/TerceraChartCashItemSeries';
import { type Alert } from '../../../Commons/cache/Alert';

export class AlertToolView extends TradingToolViewBase {
    public Alert: Alert | null;
    public GetToolViewByAlertId: any;
    public terceraChartPanelContext: any;

    public priceValue = NaN;
    public lastValidPriceValue = NaN;
    public TradeWasModified: boolean;
    public MouseDownHover: ToolSelectionType;

    constructor (alert, getToolViewByAlertId, terceraChartPanelContext, renderer) {
        super();

        this.Alert = alert;
        this.GetToolViewByAlertId = getToolViewByAlertId;
        this.terceraChartPanelContext = terceraChartPanelContext;
        this.myRenderer = renderer;
        this.ToolViewType = TradingToolViewType.AlertToolView;
        this.Collapsed = true;

        this.Updated(alert);
    }

    override get Collapsed (): boolean { return this.collapsed; }
    override set Collapsed (value) { this.collapsed = value; }

    override get ID (): string { return this.Alert.AlertId; }

    override get Price (): number { return this.Alert.Value; }

    public override Dispose (): void {
        this.Alert = null;
        this.GetToolViewByAlertId = null;

        super.Dispose();
    }

    public override Draw (gr, window, param): void {
        this.MainOrderVisibility = false;

        if (!this.myRenderer) {
            return;
        }

        if (!this.myRenderer.ShowAlerts) {
            return;
        }

        if (!this.Alert) {
            return;
        }

        const CR = window.ClientRectangle;

        const minX = CR.X;
        const maxX = minX + CR.Width;
        try {
            let screenPoints00 = this.screenPoints[0][0];
            if (screenPoints00 < minX + 9) {
                screenPoints00 = minX + 9;
            }

            screenPoints00 = minX + 15;

            const screenPoints01 = this.screenPoints[0][1];

            // Ловим ошибки
            if ((screenPoints00 < 0 && maxX < 0) || screenPoints01 >= ProMath.infinity || screenPoints01 >= ProMath.infinity || screenPoints01 <= ProMath.infinityMinus || screenPoints01 <= ProMath.infinityMinus) {
                return;
            }

            // оптимизация - вне не рисуем ордер и не добавляем его плашку
            if (!CR.Contains(screenPoints00, screenPoints01)) {
                return;
            }

            this.MainOrderVisibility = true;
            const mainImgHovered = this.CurrentSelection.CurrentHover === ToolSelectionType.Image;
            const actionButtonImgHovered = this.CurrentSelection.CurrentHover === ToolSelectionType.Action;
            const cancelButtonImgHovered = this.CurrentSelection.CurrentHover === ToolSelectionType.Remove;

            let mainImg = null;
            let actionButtonImg = null;
            let cancelButtonImg = null;

            let penLine = null;
            let backgroundPen = null;
            let backgroundBrush = null;

            if (this.TradeWasModified) {
                mainImg = TradingToolViewBase.gray_alertImage;
                actionButtonImg = TradingToolViewBase.gray_alert_editImage;
                cancelButtonImg = TradingToolViewBase.gray_alert_closeImage;

                penLine = TradingToolViewBase.orderGrayPen;
                backgroundPen = TradingToolViewBase.grayBackgroundPen;
                backgroundBrush = TradingToolViewBase.grayBrush;
            } else if (this.Alert.Importance === AlertImportance.HIGH) {
                mainImg = mainImgHovered ? TradingToolViewBase.red_alert_hoverImage : TradingToolViewBase.red_alert_defaultImage;
                actionButtonImg = actionButtonImgHovered ? TradingToolViewBase.red_alert_edit_hoverImage : TradingToolViewBase.red_alert_edit_defaultImage;
                cancelButtonImg = cancelButtonImgHovered ? TradingToolViewBase.red_alert_close_hoverImage : TradingToolViewBase.red_alert_close_defaultImage;

                penLine = this.myRenderer.alertRedPen;
                backgroundPen = TradingToolViewBase.redAlertBackgroundPen;
                backgroundBrush = TradingToolViewBase.redAlertBackgroundBrush;
            } else if (this.Alert.Importance === AlertImportance.MEDIUM) {
                mainImg = mainImgHovered ? TradingToolViewBase.yellow_alert_hoverImage : TradingToolViewBase.yellow_alert_defaultImage;
                actionButtonImg = actionButtonImgHovered ? TradingToolViewBase.yellow_alert_edit_hoverImage : TradingToolViewBase.yellow_alert_edit_defaultImage;
                cancelButtonImg = cancelButtonImgHovered ? TradingToolViewBase.yellow_alert_close_hoverImage : TradingToolViewBase.yellow_alert_close_defaultImage;

                penLine = this.myRenderer.alertYellowPen;
                backgroundPen = TradingToolViewBase.yellowAlertBackgroundPen;
                backgroundBrush = TradingToolViewBase.yellowAlertBackgroundBrush;
            } else if (this.Alert.Importance === AlertImportance.LOW) {
                mainImg = mainImgHovered ? TradingToolViewBase.green_alert_hoverImage : TradingToolViewBase.green_alert_defaultImage;
                actionButtonImg = actionButtonImgHovered ? TradingToolViewBase.green_alert_edit_hoverImage : TradingToolViewBase.green_alert_edit_defaultImage;
                cancelButtonImg = cancelButtonImgHovered ? TradingToolViewBase.green_alert_close_hoverImage : TradingToolViewBase.green_alert_close_defaultImage;

                penLine = this.myRenderer.alertGreenPen;
                backgroundPen = TradingToolViewBase.greenAlertBackgroundPen;
                backgroundBrush = TradingToolViewBase.greenAlertBackgroundBrush;
            }

            this.imageRect = new Rectangle(screenPoints00 - 8, screenPoints01 - 11, mainImg.width, mainImg.height);
            if (mainImg) {
                gr.drawImage(mainImg, this.imageRect.X, this.imageRect.Y);
            }

            let infoRectDelta = 0;
            if (!this.Collapsed) {
                const imageRect = this.imageRect;
                this.actionButton = imageRect.copy();
                this.actionButton.X += imageRect.Width;
                this.actionButton.Width = 19;

                if (actionButtonImg) {
                    gr.drawImage(actionButtonImg, this.actionButton.X, this.actionButton.Y);
                }

                gr.DrawLine(backgroundPen, this.actionButton.X, this.actionButton.Y, this.actionButton.X, this.actionButton.Y + this.actionButton.Height);

                const actionButton = this.actionButton;
                this.removeButton = actionButton.copy();
                this.removeButton.X += actionButton.Width;
                this.removeButton.Width = 20;

                if (cancelButtonImg) {
                    gr.drawImage(cancelButtonImg, this.removeButton.X, this.removeButton.Y);
                }

                gr.DrawLine(backgroundPen, this.removeButton.X, this.removeButton.Y, this.removeButton.X, this.removeButton.Y + this.removeButton.Height);
            } else {
                this.actionButton = Rectangle.Empty();
                this.removeButton = Rectangle.Empty();
            }

            infoRectDelta = this.imageRect.Width + this.actionButton.Width + this.removeButton.Width - 8;
            const skipDrawingLine = screenPoints00 + infoRectDelta > maxX - 3;

            // Линия
            if (!skipDrawingLine) {
                gr.DrawLine(penLine, screenPoints00 + infoRectDelta, screenPoints01, maxX, screenPoints01);
            }

            // +++ add right line
            if (!isNaN(this.priceValue)) {
                gr.DrawLine(penLine, screenPoints00 - 8 - 5, screenPoints01, 0, screenPoints01);
            }

            // плашка
            // if (!this.Collapsed)
            // {
            const price = this.TradeWasModified ? (!isNaN(this.priceValue) ? this.priceValue : this.lastValidPriceValue) : this.Price;
            const text = this.myRenderer.Instrument.formatPrice(price);
            param.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.VisualTrading, price, backgroundBrush, text));
        // }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public override Updated (newAlert?: Alert, isAlertDataUpdate?): void {
        if (this.Alert === null || !newAlert) {
            return;
        }

        this.Alert = newAlert;

        this.dataCacheTool.Points[0][0];
        this.dataCacheTool.Points[0][1] = this.Price;
        if (!this.CurrentMovement.IsMovingRightNow && !isAlertDataUpdate) {
            this.CancelMoving();
        }

        this.myRenderer.chart.IsDirty(LayersEnum.Tools);
    }

    public override GetCurrentSelection (x: number, y: number): ToolSelectionType {
        let newSelection = ToolSelectionType.None;

        if (this.imageRect.Contains(x, y)) {
            newSelection = ToolSelectionType.Image;
            this.Collapsed = false;
        } else if (this.actionButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Action;
        } else if (this.removeButton.Contains(x, y)) {
            newSelection = ToolSelectionType.Remove;
        } else {
            this.Collapsed = true;
        }

        return newSelection;
    }

    public override ProcessClick (): void {
        if (this.CurrentSelection.MouseDownHover === this.CurrentSelection.CurrentHover) // click
        {
            switch (this.CurrentSelection.MouseDownHover) {
            case ToolSelectionType.Action:
                this.terceraChartPanelContext.ChartVisualTrading(this.Alert, { action: TerceraChartTradingOperation.EditAlert });
                break;
            case ToolSelectionType.Remove:
                this.terceraChartPanelContext.ChartVisualTrading(this.Alert, { action: TerceraChartTradingOperation.RemoveAlert });
                break;
            default:
                break;
            }
        }
        this.ResetMouse();
    }

    public override GetCursor (e): Cursors {
        const curElement = this.CurrentSelection.CurrentHover;

        switch (curElement) {
        case ToolSelectionType.Image:
            return Cursors.NoMoveVert;
        case ToolSelectionType.Action:
        case ToolSelectionType.Remove:
            return Cursors.Default;
        default:
            return null;
        }
    }

    public override IsSelectCheck (x: number, y: number): boolean {
        if (this.TradeWasModified) // тулза смещена и с сервера не пришло подтверждение не двигаем её
        {
            return false;
        }

        const newSelection = this.GetCurrentSelection(x, y);
        return newSelection !== ToolSelectionType.None;
    }

    public override CheckMinDistanceForStartMoving (x: number, y: number): boolean {
        const mouseDownHover = this.CurrentSelection.MouseDownHover;

        if (mouseDownHover === ToolSelectionType.Image) {
            return Math.abs(this.CurrentMovement.PrevY - y) >= this.MinDistanceForStartMoving();
        }

        return false;
    }

    // start move
    public override ProcessMoveStart (x: number, y: number): void {
        super.ProcessMoveStart(x, y);
    }

    // move
    public override ProcessNewPosition (window, x: number, y: number, cashItemSeries: TerceraChartCashItemSeries = null): void {
        this.TradeWasModified = true;
        // this.errorMode = false;
        let data = 0;
        data = window.PointsConverter.GetDataY(y);

        // move alert
        super.ProcessNewPosition(window, x, y, cashItemSeries);
        this.priceValue = data;
    }

    // mouse finish
    public override ProcessMoveFinish (): void {
        try {
            super.ProcessMoveFinish();
            const mouseDownHover = this.CurrentSelection.MouseDownHover;

            // if (this.errorMode)
            // {
            //     this.TradeWasModified = false;
            //     this.CancelMoving();
            //     return;
            // }
            if (mouseDownHover === ToolSelectionType.Image) {
                const newPrice = this.myRenderer.Instrument !== null ? this.myRenderer.Instrument.roundPrice(this.priceValue) : this.priceValue;

                const ht = TerceraChartHistoryType.GetOriginalHistoryType(this.myRenderer.chart.ChartHistoryType(), this.myRenderer.Instrument);
                const valueFromInstrument = AlertUtils.GetCorrectPriceForAlertByInstrument(this.myRenderer.Instrument, ht);
                let condition = null;

                if (!isNaN(valueFromInstrument)) {
                    condition = newPrice > valueFromInstrument ? AlertCondition.MORE_EQUAL : AlertCondition.LESS_EQUAL;
                }
                // let cancel = new AlertToolView.cancelCB();
                // cancel.add(this.undoChanges.bind(this));

                this.lastValidPriceValue = newPrice;
                // if (newPrice <= 0)
                // {
                //     this.CancelMove()
                //     this.Updated(this.Alert, false)
                // }
                // else
                this.terceraChartPanelContext.ChartVisualTrading(
                    this.Alert,
                    {
                        Value: newPrice,
                        Condition: condition,
                        MoveChart: true,
                        action: TerceraChartTradingOperation.AlertReplacement
                    });
            } else {
                super.ProcessMoveFinish();
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);

            this.ResetMouse();
            super.ProcessMoveFinish();
        }
    }

    public FinishMove (): void {
        this.TradeWasModified = false;
        super.ProcessMoveFinish();
        this.ResetMove();
        this.MouseDownHover = ToolSelectionType.None;
    }

    public ResetMove (): void {
        this.lastValidPriceValue = NaN;
        this.ResetMouse();
    }

    public override ResetMouse (): void {
        this.CurrentSelection.MouseDownHover = ToolSelectionType.None;

        // this.errorMode = false;
        this.priceValue = NaN;
    }

    // AlertToolView.cancelCB = function ()
    // {
    //     this.methodArr = [];
    // };

    // AlertToolView.cancelCB.prototype.add = function (method)
    // {
    //     this.methodArr.push(method);
    // };

    // AlertToolView.cancelCB.prototype.Run = function ()
    // {
    //     for (var i = 0; i < this.methodArr.length; i++)
    //         this.methodArr[i]();
    // };

    public CancelMove (): void {
        this.FinishMove();
        this.Updated();
    }

    public override CancelMoving (): void {
        this.ResetMouse();
        this.TradeWasModified = false;
    }

// TODO. Rename. Refactor.
// public override undoChanges  ()
// {
//     this.CancelMove()
//     this.Updated(this.Alert, false)
// }
}
