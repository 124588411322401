// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCache } from '../DataCache';
import { Instrument } from './Instrument';
import { Route } from './Route';

export class RouteUtils {
    public static findRouteForSources (instrument: Instrument | null): any {
        if (!Instrument.IsWorkingInstrument(instrument)) {
            return null;
        }

        const symbolRouteName = instrument.getRoute();
        const dc = DataCache;
        let route = dc.getRouteByName(symbolRouteName);

        /* TODO.
        if (!route)
            route = dc.getInfoRouteByName(symbolRouteName)
        else
        */
        if (route) { route = dc.getRouteById(route.QuoteRouteId); };

        return route;
    }

    public static isSeparatedSourceMode (instrument: Instrument | null): boolean {
        if (!instrument) {
            return false;
        }

        const route = RouteUtils.findRouteForSources(instrument);

        return route && route.ShowMode === Route.ClientShowMode.BuyerSeller;
    }
}
