// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type TerceraChartNumberScaleRenderer } from '../Renderers/Scales/TerceraChartNumberScaleRenderer';
import { XYPointsConverter } from '../Utils/PointsConverter/XYPointsConverter';
import { TerceraChartWindowBase } from './TerceraChartWindowBase';

export class TerceraChartXYWindow extends TerceraChartWindowBase {
    public FminXTotal: number;
    public FmaxXTotal: number;

    public FminYTotal: number;
    public FmaxYTotal: number;

    private dataIsUprising: boolean;

    constructor (rightYScaleRenderer: TerceraChartNumberScaleRenderer, leftYScaleRenderer: TerceraChartNumberScaleRenderer) {
        super(rightYScaleRenderer, leftYScaleRenderer);
        this.AutoScale = false;
        this.PointsConverter = new XYPointsConverter(this);
        if (!isNullOrUndefined(rightYScaleRenderer)) {
            this.rightYScaleRenderer.window = this;
        }
        if (!isNullOrUndefined(leftYScaleRenderer)) {
            this.leftYScaleRenderer.window = this;
        }
        this.IsMainWindow = true;
    }

    public override CalcScales (): boolean {
        this.CalcXScale();
        return super.CalcScales();
    }

    public override ZoomIn (): void {
        if (!this.CanZoomIn()) {
            return;
        }
        const dx = Math.abs(this.FmaxFloatX - this.FminFloatX) / 4.0;
        const dy = Math.abs(this.FmaxFloatY - this.FminFloatY) / 4.0;
        this.ProcessZoomIn(dx, dy);
        this.CalcXScale();
    }

    public override CanZoomIn (): boolean {
        return Math.ceil((this.FmaxXTotal - this.FminXTotal) / (this.FmaxFloatX - this.FminFloatX)) < 16;
    }

    public override ZoomOut (): void {
        if (!this.CanZoomOut()) {
            return;
        }
        const dx = Math.abs(this.FmaxFloatX - this.FminFloatX) / 2.0;
        const dy = Math.abs(this.FmaxFloatY - this.FminFloatY) / 2.0;
        this.ProcessZoomOut(dx, dy);
        this.CalcXScale();
    }

    public override CanZoomOut (): boolean {
        return (this.FmaxFloatX - this.FminFloatX) < (this.FmaxXTotal - this.FminXTotal);
    }

    public override ZoomX (delta: number): boolean {
        if (delta === 0) {
            return false;
        }
        const isZoomIn = delta < 0;
        const absDelta = Math.abs(delta * (this.FmaxFloatX - this.FminFloatX) / 2);
        if (isZoomIn) {
            if (this.CanZoomIn()) {
                this.ProcessZoomIn(absDelta, 0);
            }
            return true;
        } else {
            if (this.CanZoomOut()) {
                this.ProcessZoomOut(absDelta, 0);
            }
            return true;
        }
    }

    public override ZoomY (delta: number): boolean {
        if (delta === 0) {
            return false;
        }
        const isZoomIn = delta < 0;
        const absDelta = Math.abs(delta * (this.FmaxFloatY - this.FminFloatY) / 2);
        const newMinY = isZoomIn ? this.FminFloatY + absDelta : this.FminFloatY - absDelta;
        const newMaxY = isZoomIn ? this.FmaxFloatY - absDelta : this.FmaxFloatY + absDelta;
        this.updateMinMaxY(isZoomIn, newMinY, newMaxY, false);
        return true;
    }

    public override MoveX (newMinX: number, newMaxX: number): boolean {
        let offset = 0;
        if (newMinX < this.FminXTotal) {
            offset = this.FminXTotal - newMinX;
        } else if (newMaxX > this.FmaxXTotal) {
            offset = this.FmaxXTotal - newMaxX;
        }
        this.FminFloatX = newMinX + offset;
        this.FmaxFloatX = newMaxX + offset;
        return true;
    }

    public SetMinMaxXScale (newFminFloatX: number, newFmaxFloatX: number): void {
        this.FminXTotal = this.FminFloatX = newFminFloatX;
        this.FmaxXTotal = this.FmaxFloatX = newFmaxFloatX;
    }

    public SetMinMaxYScale (newFminFloatY: number, newFmaxFloatY: number): void {
        this.FminYTotal = this.FminFloatY = newFminFloatY;
        this.FmaxYTotal = this.FmaxFloatY = newFmaxFloatY;
        this.dataIsUprising = Math.abs(this.FminYTotal) <= Math.abs(this.FmaxYTotal);
        this.CalcScales();
    }

    private CalcXScale (): void {
        if (this.FmaxFloatX === this.FminFloatX) {
            this.XScale = 1;
        } else {
            this.XScale = this.ClientRectangle.Width / (this.FmaxFloatX - this.FminFloatX);
        }
    }

    private ProcessZoomIn (dx: number, dy: number): void {
        const newMinFloatX = this.FminFloatX + dx;
        const newMaxFloatX = this.FmaxFloatX - dx;
        let newMinFloatY = this.FminFloatY;
        let newMaxFloatY = this.FmaxFloatY;

        if (this.dataIsUprising) {
            newMaxFloatY -= dy * 2;
        } else { newMinFloatY += dy * 2; }

        this.updateMinMaxX(true, newMinFloatX, newMaxFloatX);
        this.updateMinMaxY(true, newMinFloatY, newMaxFloatY);
    }

    private ProcessZoomOut (dx: number, dy: number): void {
        const newMinFloatX = this.FminFloatX - dx;
        const newMaxFloatX = this.FmaxFloatX + dx;
        let newMinFloatY = this.FminFloatY;
        let newMaxFloatY = this.FmaxFloatY;

        if (this.dataIsUprising) {
            newMaxFloatY += dy * 2;
        } else { newMinFloatY -= dy * 2; }

        this.updateMinMaxX(false, newMinFloatX, newMaxFloatX);
        this.updateMinMaxY(false, newMinFloatY, newMaxFloatY);
    }

    private updateMinMaxX (isZoomIn: boolean, newMinFloatX: number, newMaxFloatX: number): void {
        const correctedMinMax = isZoomIn ? this.CorrectMinMaxXForZoomIn(newMinFloatX, newMaxFloatX) : this.CorrectMinMaxXForZoomOut(newMinFloatX, newMaxFloatX);
        this.FminFloatX = correctedMinMax.newMinFloatX;
        this.FmaxFloatX = correctedMinMax.newMaxFloatX;
    }

    private updateMinMaxY (isZoomIn: boolean, newMinFloatY: number, newMaxFloatY: number, isCheckBorders: boolean = true): void {
        const correctedMinMax = isZoomIn ? this.CorrectMinMaxYForZoomIn(newMinFloatY, newMaxFloatY) : this.CorrectMinMaxYForZoomOut(newMinFloatY, newMaxFloatY, isCheckBorders);
        this.FminFloatY = correctedMinMax.newMinFloatY;
        this.FmaxFloatY = correctedMinMax.newMaxFloatY;
    }

    private CorrectMinMaxXForZoomOut (newMinFloatX: number, newMaxFloatX: number): { newMinFloatX: number, newMaxFloatX: number } {
        let dx = 0;
        let minX = newMinFloatX;
        let maxX = newMaxFloatX;

        if (newMinFloatX < this.FminXTotal) {
            dx = this.FminXTotal - minX;
            minX += dx;
            maxX = Math.min(maxX + dx, this.FmaxXTotal);
        }
        if (newMaxFloatX > this.FmaxXTotal) {
            dx = maxX - this.FmaxXTotal;
            maxX -= dx;
            minX = Math.max(minX - dx, this.FminXTotal);
        }
        return {
            newMinFloatX: minX,
            newMaxFloatX: maxX
        };
    }

    private CorrectMinMaxXForZoomIn (newMinFloatX: number, newMaxFloatX: number): { newMinFloatX: number, newMaxFloatX: number } {
        return {
            newMinFloatX: Math.min(newMinFloatX, this.FmaxFloatX),
            newMaxFloatX: Math.max(newMaxFloatX, this.FminFloatX)
        };
    }

    private CorrectMinMaxYForZoomOut (newMinFloatY: number, newMaxFloatY: number, isCheckBorders: boolean = true): { newMinFloatY: number, newMaxFloatY: number } {
        if (isCheckBorders && !this.CanZoomOut()) {
            return {
                newMinFloatY: this.FminYTotal,
                newMaxFloatY: this.FmaxYTotal
            };
        } else {
            return {
                newMinFloatY,
                newMaxFloatY
            };
        }
    }

    private CorrectMinMaxYForZoomIn (newMinFloatY: number, newMaxFloatY: number): { newMinFloatY: number, newMaxFloatY: number } {
        return {
            newMinFloatY: Math.max(newMinFloatY, this.FminYTotal),
            newMaxFloatY: Math.min(newMaxFloatY, this.FmaxYTotal)
        };
    }

    public CalculateMovingXOffset (movingDeltaX: number, width: number): number {
        return movingDeltaX * (this.FmaxXTotal - this.FminXTotal) / width + this.FminXTotal;
    }
}
