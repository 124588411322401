// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Control } from '../Control';
import { HotkeysEditControlTemplate } from '../../../templates';
import { Resources } from '@shared/localizations/Resources';
import { HotkeysKeyless } from '@shared/commons/cache/Hotkeys/NamesEventHotkey ';
import { popupErrorHandler } from '@shared/utils/AppHandlers';
import $ from 'jquery';
import { Rectangle } from '@shared/commons/Geometry';
import { MessageBoxType, TerceraMessageBox } from '../../screen/TerceraMessageBox';
import { HotkeysManager } from '@shared/commons/cache/Hotkeys/HotkeysManager';
import { type HotkeysItem } from '@shared/commons/cache/Hotkeys/HotkeysItem';

export class HotkeysEditControl extends Control {
    private oldValue: string = '';
    private hotkeysItem: HotkeysItem = null;
    private readonly shiftedKeysToNumbers = new Map<string, string>([
        ['!', '1'],
        ['@', '2'],
        ['#', '3'],
        ['$', '4'],
        ['%', '5'],
        ['^', '6'],
        ['&', '7'],
        ['*', '8'],
        ['(', '9'],
        [')', '0']
    ]);

    constructor () { super(); }

    public override getType (): string {
        return 'HotkeysEditControl';
    }

    public setData (item: HotkeysItem): void {
        if (isNullOrUndefined(item)) {
            return;
        };

        void this.set({
            value: item.Hotkey,
            text: this.localizeProperty(item.ActionName)
        });

        this.hotkeysItem = item;
    }

    private localizeProperty (key: string): string {
        const locKey = 'property.Hotkeys.' + key;
        return Resources.getResource(locKey);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.on('focus', this.startReadHotkeys);
        this.on('blur', this.stopReadHotkeys);
        this.on('keyDown', function () { });
        this.observe('item', this.setData);
        this.localize();
    }

    public onteardown (): void {
        this.hotkeysItem.TemporaryHotkey = '';
    };

    private readonly startReadHotkeys = (): void => {
        const input = this.find('input');
        if (input instanceof HTMLInputElement) {
            input.select();
        }

        this.oldValue = this.get('value');
        void this.set('focused', true);
        document.addEventListener('keydown', this.onGlobalKeyDown);
    };

    private readonly stopReadHotkeys = (): void => {
        this.saveNewValue();
        void this.set('focused', false);
        this.hidePopupError();
        document.removeEventListener('keydown', this.onGlobalKeyDown);
    };

    public onGlobalKeyDown = (event: KeyboardEvent): void => {
        const { key } = event;
        this.stopBaseEvent(event);

        if (key === 'Escape' || key === 'Esc') {
            void this.set('hasError', true);
            this.find('input').blur();
        }

        if (key === 'Enter') {
            this.find('input').blur();
        }

        if (key === 'Backspace' || key === 'Delete') {
            this.onValueChanged(HotkeysKeyless);
            this.hidePopupError();
            return;
        }

        const combinationString = this.getCombinationString(event);
        if (isValidString(combinationString)) {
            this.onValueChanged(combinationString);
        }
    };

    private stopBaseEvent (event: KeyboardEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private getCombinationString (event: KeyboardEvent): string {
        const { key, ctrlKey, altKey, shiftKey, metaKey } = event;
        const combination = [];

        if (ctrlKey) { combination.push('CTRL'); }
        if (metaKey) { combination.push('COMMAND'); }
        if (shiftKey) { combination.push('SHIFT'); }
        if (altKey) { combination.push('ALT'); }

        if (!['Control', 'Command', 'Shift', 'Alt'].includes(key)) {
            const keyPressed = key.match(/^[a-zA-Z0-9!@#$%^&*()]+$/);
            if (keyPressed) {
                if (key.match(/^[a-zA-Z]$/)) {
                    combination.push(key.toUpperCase());
                } else if (key.match(/^[0-9]$/) || this.shiftedKeysToNumbers.get(key)) {
                    const actualKey = this.shiftedKeysToNumbers.get(key) || key;
                    combination.push(actualKey);
                }
            }
        }

        if (combination.length) {
            if (!['CTRL', 'COMMAND', 'SHIFT', 'ALT'].includes(combination[0])) {
                this.showPopupError(Resources.getResource('hotkeysPopupMessage.ErrorText.MustCombination'));
            } else {
                if (['CTRL', 'COMMAND', 'SHIFT', 'ALT'].includes(combination[combination.length - 1])) {
                    this.showPopupError(Resources.getResource('hotkeysPopupMessage.ErrorText.NeedAddLetterOrNumber'));
                } else {
                    this.hidePopupError();
                }
            }
        }

        return combination.join('+');
    }

    private localize (): void {
        void this.set('tooltip', Resources.getResource('property.ClickToModify'));
    }

    private onValueChanged (value: string): void {
        void this.set('value', value);
    }

    private saveNewValue (): void {
        if (this.get('hasError') || this.checkForDuplicate()) {
            void this.set('value', this.oldValue);
            this.oldValue = '';
        } else {
            const newValue = this.get('value');
            this.oldValue = '';
            this.hotkeysItem.TemporaryHotkey = newValue;
            this.fire(HotkeysEditControlEvents.ValueChanged, this.hotkeysItem, newValue);
        }
    }

    private showPopupError (errorText: string): void {
        if (!this.get<boolean>('enabled') || !this.get<boolean>('focused') || !isValidString(errorText)) {
            return;
        }

        void this.set('hasError', true);
        popupErrorHandler.Show(this, errorText, '');
    }

    private hidePopupError (): void {
        void this.set('hasError', false);
        popupErrorHandler.Hide(this);
    }

    private checkForDuplicate (): boolean {
        const arrayHotkeys = HotkeysManager.GetFilteredArrayHotkeys();
        const currentValue = this.get('value');
        if (arrayHotkeys.includes(currentValue) && this.oldValue !== currentValue) {
            this.showMessageBox();
            return true;
        }

        return false;
    }

    private getErrorTextMessageBox (): string {
        const hotkeysMap = HotkeysManager.GetHotkeysMap();
        for (const key of hotkeysMap.keys()) {
            for (const item of hotkeysMap.get(key)) {
                const { Hotkey, ActionName, TemporaryHotkey } = item;
                const hotkey = isValidString(TemporaryHotkey) ? TemporaryHotkey : Hotkey;
                if (hotkey === this.get('value')) {
                    const action = Resources.getResource('property.Hotkeys.' + ActionName);
                    return Resources.getResource('hotkeysMessageBox.ErrorText')
                        .replace('{0}', action)
                        .replace('{1}', hotkey);
                }
            }
        }
    }

    private showMessageBox (): void {
        const addData = { okText: Resources.getResource('general.messageBox.ok') };
        TerceraMessageBox.Show(
            Resources.getResource('screen.error.title'),
            this.getErrorTextMessageBox(),
            MessageBoxType.Info, null, null, false, true, null, addData);
    }

    public override getAbsoluteRectangle (): Rectangle {
        const $root = $(this.find('div'));
        const $window = $(window);
        const offset = $root.offset();

        return new Rectangle(
            offset.left - $window.scrollLeft() + 25,
            offset.top - $window.scrollTop() + 8,
            $root.width(),
            $root.height());
    }
}

export enum HotkeysEditControlEvents {
    ValueChanged = 'ValueChanged'
};

Control.extendWith(HotkeysEditControl, {
    template: HotkeysEditControlTemplate,
    data: function () {
        return {
            value: '',
            tooltip: '',
            hasError: false
        };
    }
});
