// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type GainerLoserData } from '../../../Commons/cache/Algorithm/GainersLosers/GainerLoserData';
import { Message } from '../Message';
import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';

export class DirectAlgorithmHistoricalGainersLosersTopsMessage extends DirectAlgorithmMessageBase {
    constructor () {
        super(Message.CODE_PFIX_ALGORITHM_HISTORICAL_GAINER_LOSER_TOPS_MESSAGE);
    }

    AlgorithmTop: number;
    GainersLosersData: GainerLoserData[] = [];
}
