// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ApplicationPanelWithTable } from '../ApplicationPanelWithTable';
import { ObjectManagerBaseTemplate } from '../../../templates';
import { type IndicatorsManagerItem } from '../../cache/ObjectManager/IndicatorsManagerItem';
import { TerceraMenu } from '../../elements/TerceraMenu';
import { Resources } from '@shared/localizations/Resources';
import { Control } from '../../elements/Control';
import { type OverlaysManagerItem } from '../../cache/ObjectManager/OverlaysManagerItem';
import { type DrawingsManagerItem } from '../../cache/ObjectManager/DrawingsManagerItem';
import { ObjectManagerScreen } from '../../screen/ObjectManagerScreen';
import { type TerceraChart } from '@front/chart/TerceraChart';
import { DynProperty } from '@shared/commons/DynProperty';
import { TerceraChartAction, type TerceraChartActionEnum } from '@front/chart/TerceraChartAction';

type ObjectManagerItemTypes = IndicatorsManagerItem | DrawingsManagerItem | OverlaysManagerItem;

export class ObjectManagerPanelBase<ItemType extends ObjectManagerItemTypes, ItemSubjectType> extends ApplicationPanelWithTable<ItemType> {
    private _chart: TerceraChart = null;
    protected readonly ChartActionVisibility: TerceraChartActionEnum;
    protected readonly ChartActionSettings: TerceraChartActionEnum;
    protected readonly ChartActionRemove: TerceraChartActionEnum;
    protected readonly ChartActionShowOnChart: TerceraChartActionEnum;
    protected readonly ChartActionDeleteAll: TerceraChartActionEnum;

    get Chart (): TerceraChart {
        return this._chart;
    }

    set Chart (value: TerceraChart) {
        this._chart = value;
    }

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        void this.set('isRelativeStyle', true);
        this.observe('visible', this.onVisibleChanged);
        this.observe('chart', this.onChartChanged);
    }

    public override oncomplete (): void {
        super.oncomplete();
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) {
            return;
        }

        const menuItems = this.createMenuItems();
        this.menuTagDict = TerceraMenu.createTagDictionary(menuItems);
        qt.setTableContextMenuItems(menuItems);
        qt.OnSelectionChanged.Subscribe(this.selectionChange, this);
        qt.OnPaintedPictureButtonClick.Subscribe(this.onPictureButtonClick, this);

        ObjectManagerScreen.OnResizingProcess.Subscribe(this.resizingProcess, this);
        Control.Ticker.Subscribe(this.TickAsync, this);
        this.localize();
    }

    private resizingProcess (): void {
        this.layoutTable();
    }

    public override TickAsync (): void {
        super.TickAsync();
        const isVisible: boolean = this.get<boolean>('visible');
        if (!isVisible) { return null; }
        this.updateAllowedActionsRemoveAll();
        this.getQuickTable().needRedrawBackground = true;
    }

    protected RemoveItemFromTable (itemSubject: ItemSubjectType): void {
        const quickTable = super.getQuickTable();
        if (isNullOrUndefined(quickTable)) {
            return;
        }

        for (const row of quickTable.rowsArray) {
            if (row.item.getItemSubject() === itemSubject) {
                quickTable.RemoveItem(row.item.ItemId);
                break;
            }
        }
    }

    public createMenuItems (): any[] {
        const items = [
            {
                locKey: Resources.getResource('chart.menu.ObjectManager.RemoveSelected'),
                tag: MenuActionEnum.RemoveSelected,
                enabled: false,
                event: this.menuItemAction.bind(this, MenuActionEnum.RemoveSelected)
            },
            {
                locKey: Resources.getResource('chart.menu.ObjectManager.RemoveAll'),
                tag: MenuActionEnum.RemoveAll,
                enabled: true,
                event: this.menuItemAction.bind(this, MenuActionEnum.RemoveAll)
            }
        ];

        return items;
    }

    private menuItemAction (action: MenuActionEnum): void {
        switch (action) {
        case MenuActionEnum.RemoveSelected:
            this.menuActionRemoveSelected(this.getSelectedRowItems());
            break;
        case MenuActionEnum.RemoveAll:
            this.menuActionRemoveAll();
            break;
        }
    }

    private getSelectedRowItems (): ItemType[] {
        const qt = this.getQuickTable();
        const items = [];
        for (const id of qt.selectedRowIds) {
            items.push(qt.rows[id].item);
        }

        return items;
    }

    public dispose (): void {
        ObjectManagerScreen.OnResizingProcess.UnSubscribe(this.resizingProcess, this);
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        super.dispose();
    }

    public localize (): void {
        super.localize();
        const menuTagDict = this.menuTagDict;
        if (!menuTagDict) {
            return;
        }

        for (const key in menuTagDict) {
            const menuItem = menuTagDict[key];
            menuItem.text = Resources.getResource(menuItem.locKey);
        }
    }

    private onVisibleChanged (): void {
        this.layoutTable();
    }

    private onChartChanged (newValue): void {
        if (isNullOrUndefined(newValue)) { return; }
        this.Chart = newValue;
    }

    public selectionChange (): void {
        const qt = this.getQuickTable();
        const selectedRowsId = qt.selectedRowIds;
        if (isValidArray(selectedRowsId)) {
            this.updateAllowedActionsRemoveSelected(true);
        } else {
            this.updateAllowedActionsRemoveSelected(false);
        }
    }

    public updateAllowedActionsRemoveSelected (enabled: boolean): void {
        const menuTagDict = this.menuTagDict;
        if (isNullOrUndefined(menuTagDict)) {
            return;
        }

        menuTagDict[MenuActionEnum.RemoveSelected].enabled = enabled;
    }

    public updateAllowedActionsRemoveAll (): void {
        const menuTagDict = this.menuTagDict;
        if (isNullOrUndefined(menuTagDict)) {
            return;
        }

        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) {
            return;
        }

        const rowsArray = qt.rowsArray;
        if (isValidArray(rowsArray)) {
            menuTagDict[MenuActionEnum.RemoveAll].enabled = true;
        } else {
            menuTagDict[MenuActionEnum.RemoveAll].enabled = false;
        }
    }

    private onPictureButtonClick (data): void {
        if (isNullOrUndefined(data)) { return; }
        const item = data.row.item;
        switch (data.controlType) {
        case DynProperty.VISIBILITY_BUTTON:
            this.processItemVisibility(item);
            break;
        case DynProperty.SETTINGS_BUTTON:
            this.processSettingsOpen(item);
            break;
        case DynProperty.CLOSE_BUTTON:
            this.processCloseItem(item);
            break;
        case DynProperty.SHOW_ON_CHART_BUTTON:
            this.processShowOnChartItem(item);
            break;
        }

        this.getQuickTable().redraw();
    }

    protected processItemVisibility (item: ItemType): void {
        if (!isNullOrUndefined(item) && !isNullOrUndefined(this.Chart)) {
            this.Chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(this.ChartActionVisibility, item.getItemSubject())
            );
        }
    }

    protected processSettingsOpen (item: ItemType): void {
        if (!isNullOrUndefined(item) && !isNullOrUndefined(this.Chart)) {
            this.Chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(this.ChartActionSettings, item.getItemSubject())
            );
        }
    }

    protected processCloseItem (item: ItemType): void {
        if (!isNullOrUndefined(item) && !isNullOrUndefined(this.Chart)) {
            this.Chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(this.ChartActionRemove, item.getItemSubject())
            );
        }
    }

    protected processShowOnChartItem (item: ItemType): void {
        if (!isNullOrUndefined(item) && !isNullOrUndefined(this.Chart)) {
            this.Chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(this.ChartActionShowOnChart, item.getItemSubject())
            );
        }
    }

    protected menuActionRemoveSelected (items: ItemType[]): void {
        for (const item of items) {
            this.processCloseItem(item);
        }
    }

    protected menuActionRemoveAll (): void {
        if (!isNullOrUndefined(this.Chart)) {
            this.Chart.TerceraChartActionProcessor.ProcessTerceraChartAction(
                TerceraChartAction.Create(this.ChartActionDeleteAll, true)
            );
        }
    }
}

ApplicationPanelWithTable.extendWith(ObjectManagerPanelBase, {
    data: function () {
        return {
        };
    },
    partials: {
        bodyPartial: ObjectManagerBaseTemplate
    }
});

enum MenuActionEnum {
    RemoveSelected,
    RemoveAll
}
