// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { SessionPeriod } from '../Session/SessionPeriod';
import { DirectMessageBaseClass } from './DirectMessageBase';

export class DirectQuoteMessage extends DirectMessageBaseClass {
    public PostProcessingMessage: any = null;
    public TradingSession: SessionPeriod | null | undefined = null;
    public OriginalSymbol: any = null;
    public Type = 0;
    public Time: Date | null = null;
    public SeriesGroupName: any = null;
    public quoteAdditionalInfo: any = null;
    public SessionFlag: any = null;
    public Separated = false;
    public InstrumentTradableID: number | null = null;
    public IncomeQuoteTime = new Date();
    public QuoteUniqueID: number | null = null;
    public QuoteType: any;
    public ExpDay: any;
    public ExpMonth: any;
    public ExpYear: any;
    public StrikePrice: any;

    public FRoute = '';
    protected symbol: string | null = null;

    public TargetInstrumentName: string;

    // TODO. Duplicate.
    // Remove either these or HistoryType's constants.
    public static QUOTE_TIME_ZONE = 0;
    public static QUOTE_LEVEL1 = 1;
    public static QUOTE_LEVEL2 = 2;
    public static QUOTE_TRADES = 4;
    public static QUOTE_OPTIONS = 8;
    public static QUOTE_INSTRUMENT_PRICES = 16;
    public static QUOTE_INSTRUMENT_DAY_BAR = 32;
    public static QUOTE_INSTRUMENT_STATISTICS = 64;
    // искусственный режим - бары по аскам
    public static QUOTE_ASK = 1000;
    // искусственный режим - бары по биду и аску (bid+ask)/2
    public static QUOTE_BIDASK_AVG = 1001;
    // искусственный режим - бары по биду и аску:
    // open и close всегда по биду,
    // high=max по всем (bid,ask) и low = min по всем (bid,ask)
    public static QUOTE_BIDASK_SUM = 1002;

    get Route (): string {
        return this.FRoute;
    }

    set Route (value) {
        this.FRoute = value;
    }

    // TODO: Remove cTime
    get cTime (): Date | null {
        return this.Time;
    }

    set cTime (value) {
        this.Time = value;
    }

    get Symbol (): string {
        return this.symbol;
    }

    set Symbol (value) {
        if (value !== null) {
            this.symbol = value;
        }
    }

    public SetSymbolIgnoreCase (symbol): void {
        this.symbol = symbol;
    }

    // Копирую себя в передаваемую котировку
    public CopyQuoteMsg (msg): void {
        const qMsg = msg;
        const keys = Object.keys(this);
        // TODO подумать
        for (let i = 0; i < keys.length; i++) {
            qMsg[keys[i]] = this[keys[i]];
        }
        // qMsg.code = this.code;
        // qMsg.FRoute = this.FRoute;
        qMsg.SetSymbolIgnoreCase(this.symbol);

        // TODO Check
        qMsg.QuoteUniqueID = this.QuoteUniqueID;
        qMsg.QuoteType = this.QuoteType;
    // qMsg.Type = this.Type;
    // qMsg.cTime = this.cTime;
    // qMsg.OriginalSymbol = this.OriginalSymbol;
    // qMsg.SessionFlag = this.SessionFlag;
    // qMsg.Separated = this.Separated;
    // qMsg.VendorName = this.VendorName;
    // qMsg.SeriesGroupName = this.SeriesGroupName;
    // qMsg.InstrumentTradableID = this.InstrumentTradableID;
    }

    public GetKey (): string {
        return this.ExpDay.toString() +
        this.ExpMonth.toString() +
        this.ExpYear.toString() +
        this.StrikePrice.toFixed(5);
    }

    public static IsArtificalType (type): boolean {
        return type >= 1000;
    }
}
