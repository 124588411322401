// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ExpandDoubleVector } from './DoubleMatrix';
import { MAMode, MODE_MAIN, MODE_SIGNAL } from './IndicatorConstants';
import { IndicatorScriptBase } from './IndicatorScriptBase';

export class IndicatorFunctions {
    public static CallMovingFunction (maMode: MAMode, ProcessedIndicator: IndicatorScriptBase | ExpandDoubleVector, Period: number, PreviousValueIndicatorLineNumber: number = undefined, IndicatorLineNumber: number = undefined): number {
        switch (maMode) {
        case MAMode.SMA:
            return this.SMAFunction(ProcessedIndicator, Period, IndicatorLineNumber);
        case MAMode.EMA:
            return this.EMAFunction(ProcessedIndicator, Period, PreviousValueIndicatorLineNumber, IndicatorLineNumber);
        case MAMode.SMMA:
            return this.SMMAFunction(ProcessedIndicator, Period, PreviousValueIndicatorLineNumber, IndicatorLineNumber);
        case MAMode.LWMA:
            return this.LWMAFunction(ProcessedIndicator, Period, IndicatorLineNumber);
        }
    }

    public static MACDFunction (ProcessedIndicator1: ExpandDoubleVector, ProcessedIndicator2: ExpandDoubleVector, IndicatorLineNumber: number, PeriodFast, PeriodSlow, PeriodSignal, differVector: ExpandDoubleVector): number {
        const ema1 = this.EMAFunction(ProcessedIndicator1, PeriodFast, 1); // Sum of prices
        ProcessedIndicator1[ProcessedIndicator1.Length - 1] = ema1;
        const ema2 = this.EMAFunction(ProcessedIndicator2, PeriodSlow, 1);
        ProcessedIndicator2[ProcessedIndicator2.Length - 1] = ema2;
        differVector[differVector.Length - 1] = ProcessedIndicator1[ProcessedIndicator1.Length - 1] - ProcessedIndicator2[ProcessedIndicator2.Length - 1];
        if (IndicatorLineNumber === MODE_MAIN) {
            return differVector[differVector.Length - 1];
        } else if (IndicatorLineNumber === MODE_SIGNAL) {
            return this.SMAFunction(differVector, PeriodSignal);
        }
    }

    public static AOFunction (ProcessedIndicator, IndicatorLineNumber = undefined): number {
        const ma34 = this.SMAFunction(ProcessedIndicator, 34, IndicatorLineNumber); // Sum of prices
        const ma5 = this.SMAFunction(ProcessedIndicator, 5, IndicatorLineNumber);
        return (ma5 - ma34);
    }

    private static SMAFunction (ProcessedIndicator: IndicatorScriptBase | ExpandDoubleVector, Period: number, IndicatorLineNumber: number = undefined): number {
        let summa = 0; // Sum of prices
        for (let i = 0; i < Period; i++) {
            summa += this.ValueRetrieval(ProcessedIndicator, i, IndicatorLineNumber);
        }
        return (summa / Period);
    }

    private static EMAFunction (ProcessedIndicator: IndicatorScriptBase | ExpandDoubleVector, Period: number, PreviousValueIndicatorLineNumber: number, IndicatorLineNumber: number = undefined): number {
        const price = this.ValueRetrieval(ProcessedIndicator, 0, IndicatorLineNumber);
        const k = 2 / (Period + 1);
        const prevEMA = this.ValueRetrieval(ProcessedIndicator, PreviousValueIndicatorLineNumber, IndicatorLineNumber);
        return (prevEMA + k * (price - prevEMA));
    }

    private static SMMAFunction (ProcessedIndicator: IndicatorScriptBase | ExpandDoubleVector, Period: number, PreviousValueIndicatorLineNumber: number, IndicatorLineNumber: number = undefined): number {
        const price = this.ValueRetrieval(ProcessedIndicator, 0, IndicatorLineNumber);
        const prevSMMA = this.ValueRetrieval(ProcessedIndicator, PreviousValueIndicatorLineNumber, IndicatorLineNumber);
        return ((prevSMMA * (Period - 1) + price) / Period);
    }

    private static LWMAFunction (ProcessedIndicator: IndicatorScriptBase | ExpandDoubleVector, Period: number, IndicatorLineNumber: number = undefined): number {
        let numerator = 0; // Numerator of the rate
        let denominator = 0; // Denominator of the rate
        for (let i = 0; i < Period; i++) {
            const weight = Period - i;
            numerator += weight * this.ValueRetrieval(ProcessedIndicator, i, IndicatorLineNumber);
            denominator += weight;
        }
        if (denominator !== 0) {
            return numerator / denominator;
        }
    }

    private static ValueRetrieval (ProcessedIndicator: IndicatorScriptBase | ExpandDoubleVector, index: number, IndicatorLineNumber?: number): number {
        if (IndicatorLineNumber != null && ProcessedIndicator instanceof IndicatorScriptBase) {
            return ProcessedIndicator.GetValue(IndicatorLineNumber, index);
        } else if (ProcessedIndicator instanceof ExpandDoubleVector) {
            return ProcessedIndicator[ProcessedIndicator.Length - 1 - index];
        }
    }
}
