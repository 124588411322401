// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class DateFormater {
    public readonly formattingTokens = /(\[[^\[]*\])|(\\)?(Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Q|YYYYYY|YYYYY|YYYY|YY|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g;

    public formatFunctions = {};
    public formatTokenFunctions = {};

    constructor () {
        this.initFormatTokens();
    }

    public addFormatToken (token, padded, ordinal, callback): void {
        let func = callback;
        if (typeof callback === 'string') {
            func = function () {
                return this[callback]();
            };
        }
        if (token) {
            this.formatTokenFunctions[token] = func;
        }
        if (padded) {
            this.formatTokenFunctions[padded[0]] = function () {
                return DateFormater.zeroFill(func.call(this, arguments[0]), padded[1], padded[2]);
            };
        }
        if (ordinal) {
            this.formatTokenFunctions[ordinal] = function () {
                return this.localeData().ordinal(func.call(this, arguments[0]), token);
            };
        }
    }

    public makeFormatFunction (format): (mom) => string {
        const array = format.match(this.formattingTokens);
        var length;

        for (var i = 0, length = array.length; i < length; i++) {
            if (this.formatTokenFunctions[array[i]]) {
                array[i] = this.formatTokenFunctions[array[i]];
            } else {
            // array[i] = removeFormattingTokens(array[i]);
            }
        }

        return function (mom) {
            let output = '';
            for (let i = 0; i < length; i++) {
                output += array[i] instanceof Function ? array[i].call(mom, format) : array[i];
            }
            return output;
        };
    }

    public formatMoment (m, format): string {
    // if (!m.isValid())
    // {
    //    return m.localeData().invalidDate();
    // }

        // format = expandFormat(format, m.localeData());
        this.formatFunctions[format] = this.formatFunctions[format] || this.makeFormatFunction(format);

        return this.formatFunctions[format](m);
    }

    public static zeroFill (number, targetLength, forceSign): string {
        const absNumber = '' + Math.abs(number);
        const zerosToFill = targetLength - absNumber.length;
        const sign = number >= 0;
        return (sign ? (forceSign ? '+' : '') : '-') +
        Math.pow(10, Math.max(0, zerosToFill)).toString().substr(1) + absNumber;
    }

    public static readonly defaultLocaleMonths = 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_');
    public static readonly defaultLocaleMonthsShort = 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_');

    public static readonly defaultLocaleWeekdays = 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_');
    public static readonly defaultLocaleWeekdaysShort = 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_');
    public static readonly defaultLocaleWeekdaysMin = 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_');
    public static defaultLocaleWeekdaysObj = (function () {
        const res = {};
        const keys = Object.keys(DateFormater.defaultLocaleWeekdays);
        for (let i = 0, len = keys.length; i < len; i++) { res[DateFormater.defaultLocaleWeekdays[keys[i]]] = DateFormater.defaultLocaleWeekdays[keys[i]]; }
        return res;
    })();

    // FORMATTING
    public initFormatTokens (): void {
        this.addFormatToken('M', ['MM', 2], 'Mo', function () {
            return this.getMonth() + 1;
        });
        this.addFormatToken('MMM', 0, 0, function (format) {
            return DateFormater.defaultLocaleMonthsShort[this.getMonth()];
        });
        this.addFormatToken('MMMM', 0, 0, function (format) {
            return DateFormater.defaultLocaleMonths[this.getMonth()];
        });
        this.addFormatToken(0, ['YY', 2], 0, function () {
            return this.getFullYear() % 100;
        });
        this.addFormatToken(0, ['YYYY', 4], 0, function () {
            return this.getFullYear() % 10000;
        });
        this.addFormatToken(0, ['YYYYY', 5], 0, function () {
            return this.getFullYear() % 100000;
        });
        this.addFormatToken(0, ['YYYYYY', 6, true], 0, function () {
            return this.getFullYear() % 1000000;
        });
        this.addFormatToken('D', ['DD', 2], 'Do', function () {
            return this.getDate();
        });
        this.addFormatToken('d', 0, 'do', function () {
            return this.getDay();
        });
        this.addFormatToken('dd', 0, 0, function (format) {
            return DateFormater.defaultLocaleWeekdaysMin[this.getDay()];
        });
        this.addFormatToken('ddd', 0, 0, function (format) {
            return DateFormater.defaultLocaleWeekdaysShort[this.getDay()];
        });
        this.addFormatToken('dddd', 0, 0, function (format) {
            return DateFormater.defaultLocaleWeekdays[this.getDay()];
        });
        this.addFormatToken('H', ['HH', 2], 0, function () {
            return this.getHours();
        });
        this.addFormatToken('h', ['hh', 2], 0, function () {
            return this.getHours() % 12 || 12;
        });
        this.addFormatToken('m', ['mm', 2], 0, function () {
            return this.getMinutes();
        });
        this.addFormatToken('s', ['ss', 2], 0, function () {
            return this.getSeconds();
        });
        this.addFormatToken('S', 0, 0, function () {
            return ~~(this.getMilliseconds() / 100);
        });
        this.addFormatToken(0, ['SS', 2], 0, function () {
            return ~~(this.getMilliseconds() / 10);
        });
        this.addFormatToken(0, ['SSS', 3], 0, function () {
            return this.getMilliseconds();
        });
        this.addFormatToken(0, ['SSSS', 4], 0, function () {
            return this.getMilliseconds() * 10;
        });
        this.addFormatToken(0, ['SSSSS', 5], 0, function () {
            return this.getMilliseconds() * 100;
        });
        this.addFormatToken(0, ['SSSSSS', 6], 0, function () {
            return this.getMilliseconds() * 1000;
        });
        this.addFormatToken(0, ['SSSSSSS', 7], 0, function () {
            return this.getMilliseconds() * 10000;
        });
        this.addFormatToken(0, ['SSSSSSSS', 8], 0, function () {
            return this.getMilliseconds() * 100000;
        });
        this.addFormatToken(0, ['SSSSSSSSS', 9], 0, function () {
            return this.getMilliseconds() * 1000000;
        });

    // TODO
    // addFormatToken('z', 0, 0, 'zoneAbbr');
    // addFormatToken('zz', 0, 0, 'zoneName');
    // addFormatToken('X', 0, 0, 'unix');
    // addFormatToken('x', 0, 0, 'valueOf');
    // addFormatToken('e', 0, 0, 'weekday');
    // addFormatToken('E', 0, 0, 'isoWeekday');
    // addFormatToken('w', ['ww', 2], 'wo', 'week');
    // addFormatToken('W', ['WW', 2], 'Wo', 'isoWeek');
    // addFormatToken('DDD', ['DDDD', 3], 'DDDo', 'dayOfYear');
    // addFormatToken(0, ['gg', 2], 0, function ()
    // {
    //    return this.weekYear() % 100;
    // });
    // addFormatToken(0, ['GG', 2], 0, function ()
    // {
    //    return this.isoWeekYear() % 100;
    // });
    }
}
