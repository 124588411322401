// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Rectangle } from '../../Geometry';
import { type ITreemapItem } from '../TreeMap/ITreemapItem';
import { TreemapCalculator } from '../TreeMap/TreemapCalculator';
import { type IVisibleMap } from './IVisibleMap';

export abstract class BaseMap<T extends ITreemapItem & IVisibleMap> implements ITreemapItem, IVisibleMap {
    private readonly _items: T[] = [];
    protected calculator: TreemapCalculator = new TreemapCalculator();

    public get items (): T[] {
        return [...this._items];
    }

    public abstract get Visible (): boolean;
    public abstract get VisibleCount (): number;

    public get Weight (): number {
        return this._items.reduce((sum, item) => sum + item.Weight, 0);
    }

    public Rectangle: Rectangle = Rectangle.Empty();

    public abstract recalculateArea (): void;

    protected addItem (item: T): void {
        this._items.push(item);
    }

    protected recalculateTreeMap (rect: Rectangle): void {
        const visibleItems = this._items.filter(item => item.Visible);
        this.calculator.recalculate(visibleItems, rect);
    }

    protected isVisible (): boolean {
        return this._items.some(item => item.Visible);
    }

    protected getVisibleCount (): number {
        return this._items.filter(item => item.Visible).length;
    }
}
