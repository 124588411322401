// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class TerceraTIFData {
    public date: string;
    public tif: object;

    constructor (date: string, tif: object) {
        this.date = date;
        this.tif = tif;
    }
}
