// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { Color } from '../../Commons/Graphics';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { ThemeManager } from '../misc/ThemeManager';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';

export class FullLogsItem extends BaseItem {
    public static StaticID = 0;

    public info: any;

    constructor (info) {
        super(null);

        FullLogsItem.StaticID++;
        this.itemId = FullLogsItem.StaticID;
        this.info = info;
        if (info?.IsOutgoingMSG) {
            const colorMap = FullLogsItem.GetOutgoingColorMaps();
            this.CellBackColorMap = colorMap.BackColor;
            this.CellForeColorMap = colorMap.ForeColor;
        }
    }

    public override ColumnCount (): number {
        return FullLogsItem.columnsParams.length;
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = FullLogsItem.columnsParams[column];
        return result;
    }

    public override getColumnData (column: number): ColumnData {
        let o = '';
        try {
            o = this.getColumnValue(column);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
        const FormattedValue = o !== null && o !== undefined ? o.toString() : '';
        return new ColumnData(o, FormattedValue);
    }

    public override getColumnValue (column: number): any {
        try {
            if (this.info == null) { return ''; }

            switch (column) {
            case 0:
                // if (BaseApplication.App.Settings != null && BaseApplication.App.MultiDataCache.DCache != null)
                //    return BaseApplication.App.Settings.ConvertUTCTimeToSelectedTimeZone(info.dateTime, BaseApplication.App.MultiDataCache.DCache).toString("HH:mm:ss.fff");
                // else
                return DateTimeUtils.formatDate(this.info.dateTime, 'HH:mm:ss:SSS');
                // return info.dateTime.toString("HH:mm:ss.fff");

            case 1:
                return this.info.MessageName + ' (' + this.info.MessageCode + ')';

            case 2:
                return this.info.Text;

            case 3:
                return this.info.NodeAddress;

            case 4:
                return this.info.Session;

            case 5:
                return this.info.Side;

            case 6:
                return this.info.TextNew;

            case 7:
                return this.info.StringMode;
            default:
                return '';
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            return '';
        }
    }

    public static readonly columnsParams = [

        new ColumnParams('FullLogs.DateTime', 15, 0, false, true, false),
        new ColumnParams('FullLogs.Message name', 60, 0, false, true, true),
        new ColumnParams('FullLogs.Fields', 80, 0, false, true, true),
        new ColumnParams('FullLogs.Cluster', 20, 0, false, false, true),
        new ColumnParams('FullLogs.Session', 20, 0, false, false, true),
        new ColumnParams('Direction', 20, 0, false, false, true),
        new ColumnParams('FullLogs.FieldsNew', 80, 0, false, false, true),
        new ColumnParams('FullLogs.Mode', 80, 0, false, false, true)
    ];

    public static GetOutgoingColorMaps (): { ForeColor: any[], BackColor: any[] } {
        const result = { ForeColor: [], BackColor: [] };
        for (let i = 0; i < FullLogsItem.columnsParams.length; i++) {
            result.BackColor.push(ThemeManager.CurrentTheme.FullLogPanel_OutgoingMessageBackColor);
            result.ForeColor.push(Color.Black);
        }
        return result;
    }
}
