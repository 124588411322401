// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type CustomInstrumentListTypeEnum } from '../../Utils/CustomInstrumentList/CustomInstrumentListTypeEnum';
import { type DirectCustomListMessage } from '../../Utils/DirectMessages/DirectCustomListMessage';
import { CurrentLang } from '../../Localizations/Resources';
import { CustomInstrumentList } from './CustomInstrumentList';
import { type Instrument } from './Instrument';

export class CustomInstrumentListsCache {
    public readonly instrumentListsDict = new Map<number, CustomInstrumentList>();

    public addOrUpdate (mess: DirectCustomListMessage): void {
        const instrumentList = this.instrumentListsDict.get(mess.Id);
        if (isNullOrUndefined(instrumentList)) {
            this.instrumentListsDict.set(mess.Id, new CustomInstrumentList(mess));
        } else {
            instrumentList.Update(mess);
        }
    }

    public getInstrumentIndustry (instrument: Instrument): string {
        const oldIndustry = instrument.Industry;
        return this.getLocalizedInstrListName(oldIndustry, instrument.IndustryCustomListId);
    }

    public getInstrumentSector (instrument: Instrument): string {
        const oldSector = instrument.Sector;
        return this.getLocalizedInstrListName(oldSector, instrument.SectorCustomListId);
    }

    public clear (): void {
        this.instrumentListsDict.clear();
    }

    public getCustomListsByType (type: CustomInstrumentListTypeEnum): CustomInstrumentList[] {
        const arrCustomListsDict: CustomInstrumentList[] = [];
        this.instrumentListsDict.forEach((customList) => {
            if (customList.type === type) {
                arrCustomListsDict.push(customList);
            }
        });

        return arrCustomListsDict;
    }

    public getLocalizedListName (listId: number): string | null {
        const instrumentList = this.instrumentListsDict.get(listId);
        if (isNullOrUndefined(instrumentList)) {
            return null;
        }

        if (!isNullOrUndefined(instrumentList.langAliases)) {
            return instrumentList.langAliases[CurrentLang]?.Name ?? instrumentList.name;
        }

        return instrumentList.name;
    }

    private getLocalizedInstrListName (listName: string, listId: number | null): string {
        const localizedListName = this.getLocalizedListName(listId);

        if (isValidString(localizedListName)) {
            return localizedListName;
        }

        return listName;
    }
}
