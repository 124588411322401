// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type XYSeriesData } from '../../../../../Chart/Series/TerceraChartXYSeries';

export class AnalyzerChartData {
    public readonly underlierPrice: number;
    public readonly zeroLineSeries: XYSeriesData[];
    public readonly timeLineSeries: XYSeriesData[];
    public readonly intrinsicSeries: XYSeriesData[];
    public readonly volatilitySeries: XYSeriesData[][];

    constructor (underlierPrice: number, zeroLineSeries: XYSeriesData[], timeLineSeries: XYSeriesData[], intrinsicSeries: XYSeriesData[], volatilitySeries: XYSeriesData[][]) {
        this.underlierPrice = underlierPrice;
        this.zeroLineSeries = zeroLineSeries;
        this.timeLineSeries = timeLineSeries;
        this.intrinsicSeries = intrinsicSeries;
        this.volatilitySeries = volatilitySeries;
    }
}
