// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class Level1CalculatorUtils {
    public static getColorByDifference (prev: number, curr: number): ColoringEnum {
        if (isNaN(prev) || isNaN(curr)) {
            return ColoringEnum.Neitral;
        }

        if (curr > prev) {
            return ColoringEnum.Positive;
        } else if (curr < prev) {
            return ColoringEnum.Negative;
        }

        return ColoringEnum.Previous;
    }

    public static getColorBySign (curr: number): ColoringEnum {
        if (isNaN(curr)) {
            return ColoringEnum.Neitral;
        }

        if (curr > 0) {
            return ColoringEnum.Positive;
        } else if (curr < 0) {
            return ColoringEnum.Negative;
        }

        return ColoringEnum.Neitral;
    }

    public static getChangeDirectionArrow (oldChange: number, newChange: number): DirectionArrowEnum {
        if (isNullOrUndefined(newChange) || isNaN(newChange)) {
            return DirectionArrowEnum.Unknown;
        }

        if (isNullOrUndefined(oldChange) || isNaN(oldChange)) {
            const coloring = this.getColorBySign(newChange);
            if (coloring === ColoringEnum.Positive) {
                return DirectionArrowEnum.Up;
            } else if (coloring === ColoringEnum.Negative) {
                return DirectionArrowEnum.Down;
            }
        }

        if (newChange > oldChange) {
            return DirectionArrowEnum.Up;
        } else if (newChange < oldChange) {
            return DirectionArrowEnum.Down;
        }
    }
}

export enum ColoringEnum {
    Neitral,
    Positive,
    Negative,
    Previous,
}

export enum DirectionArrowEnum {
    Up,
    Down,
    Unknown
}
