// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/// ///////////////////////////////////////////////////////////////////////////////////////////

import { DoubleMatrix, ExpandDoubleVector } from './DoubleMatrix';

export class CloudVector extends DoubleMatrix {
    public FFirstLineColor: any;
    public FSecondLineColor: any;
    public FGradientMethod: any;

    constructor (count: number, firstLineColor, secondLineColor, gradientMethod) {
        super();

        this.FFirstLineColor = firstLineColor;
        this.FSecondLineColor = secondLineColor;
        this.FGradientMethod = gradientMethod;
        // this.FArray = new ExpandDoubleVector();
        this.FArray[0] = new ExpandDoubleVector(count, 0);
        this.FArray[1] = new ExpandDoubleVector(count, 0);

        this.FArray[0].Shift = 0;
        this.FArray[1].Shift = 0;
    }

    get GRADIENT_NONE (): number { return 0; }

    get GRADIENT_SIMPLE (): number { return 1; }

    get GRADIENT_LENEAR (): number { return 2; }

    get FirstLineColor (): any { return this.FFirstLineColor; }
    set FirstLineColor (value) {
        this.FFirstLineColor = value;
    }

    get SecondLineColor (): any { return this.FSecondLineColor; }
    set SecondLineColor (value) {
        this.FSecondLineColor = value;
    }

    get GradientMethod (): any { return this.FGradientMethod; }
    set GradientMethod (value) {
        this.FGradientMethod = value;
    }

    public Add (value): void {
        this.FArray[0].Add(value);
        this.FArray[1].Add(value);
    }

    public Get (index: number): any {
        return this.FArray[index];
    }

    get Shift (): number {
        return this.FArray[0].Shift || 0;
    }

    set Shift (value: number) {
        this.FArray[0].Shift = this.FArray[1].Shift = (value || 0);
    }
}
