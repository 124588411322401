// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/// табличка со значениями комисиий в зависимости от размера лота (from to)

import { CommissionOperationType } from '../../../Utils/Commission/CommissionEnums';

export class RevenueCommissionValueItem {
    public OperationType: CommissionOperationType | null = null;
    public FromAmount: number | null = null;
    public ToAmount: number | null = null;
    private minValue = 0.0;
    private maxValue = 0.0;
    private val = 0.0;

    public GetFixedCommissionValue (commission, ignoreMinMaxCorrection = false, markup = 1.0): any {
        if (ignoreMinMaxCorrection) {
            return commission;
        }

        let fixedCommission = 0.0;

        if (this.minValue != 0.0 && commission <= this.minValue) // маркап не учитываем
        {
            fixedCommission = this.minValue;
        } else if (this.maxValue != 0.0 && commission >= this.maxValue) // маркап не учитываем
        {
            fixedCommission = this.maxValue;
        } else {
            fixedCommission = commission * markup; // а здесь уже учитываем маркап #45719
        }

        return fixedCommission;
    }

    public GetCommission (param): number {
        if (param.OperationType != this.OperationType && this.OperationType !== CommissionOperationType.ALL) {
            return 0;
        };

        const amount = parseFloat(param.Lots);

        if (amount >= this.FromAmount && (amount < this.ToAmount || this.ToAmount === -1)) {
            return this.Value;
        }

        return 0;
    }

    public GetLotsListForType (outLotsArray, operationType): void {
        if (operationType === this.OperationType ||
            operationType === CommissionOperationType.ALL ||
            this.OperationType === CommissionOperationType.ALL) // WTF проверить
        {
            if (!outLotsArray.Contains(this.FromAmount)) {
                outLotsArray.push(this.FromAmount);
            }
        }
    }

    get Value (): number { return this.val; }
    set Value (value) { this.val = value; }

    get MinValue (): number { return this.minValue; }
    set MinValue (value) { this.minValue = value; }

    get MaxValue (): number { return this.maxValue; }
    set MaxValue (value) { this.maxValue = value; }

    get IsShort (): boolean {
        return this.OperationType === CommissionOperationType.SHORT;
    }

    get IsOpenOrClose (): boolean {
        return (this.OperationType === CommissionOperationType.OPEN || this.OperationType === CommissionOperationType.CLOSE);
    }

    get IsBuySellShort (): boolean {
        return (this.OperationType === CommissionOperationType.BUY ||
                this.OperationType === CommissionOperationType.SELL ||
                this.OperationType === CommissionOperationType.SHORT);
    }
}
