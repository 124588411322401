// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCacheToolFactory } from '@shared/commons/cache/DataCacheToolFactory';
import { TerceraChartUtils } from '../../TerceraChartUtils';
import { TerceraChartToolRenderer } from './TerceraChartToolRenderer';
import { ArrayUtils } from '@shared/utils/ArrayUtils';
import { ToolsCache } from '@shared/commons/cache/ToolsCache';

export class TerceraChartToolInstanceRenderer extends TerceraChartToolRenderer {
    constructor (chartID: any, chart: any) {
        super(chartID, chart, null);
        this.SupportDataConverter = true;
        this.SetClassName('TerceraChartToolInstanceRenderer');
    }

    AddTool (tool: any): boolean {
        tool.WindowsNumber = this.WindowsNumber;
        ToolsCache.AddTool(tool);
        return true;
    }

    RemoveTool (toolView: any): boolean {
        super.RemoveTool.call(this, toolView);
        const tV = this.GetToolViewByDataCacheTool(toolView.dataCacheTool);
        if (tV === null) return false;

        tV.Dispose();
        return ArrayUtils.RemoveElementFromArray(this.tools, tV);
    }

    ClearTools (): void {
        this.tools.length = 0;
    }

    DrawAllTools (gr: any, window: any, param: any): void {
        for (let i = 0; i < this.tools.length; i++) {
            const tool = this.tools[i];
            tool.UpdateScreenPoints(window);
            tool.Draw(gr, window, param);
        }
    }

    CloneTool (tool: any, ww: any): void {
        const clonedDCTool = DataCacheToolFactory.Clone(tool.dataCacheTool);
        if (ww !== null) {
            TerceraChartUtils.ShiftTool(clonedDCTool, ww);
            // локальная тулза
            this.AddTool(clonedDCTool);
        }
    }
}
