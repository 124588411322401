// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingOrdersPanelTemplate } from '../templates.js';
import { openContextMenu } from '../Controls/elements/InvestingMenu';
import { openOrderEntry } from '../Screen/InvestingOE';
import { OrderRowDataItem } from '../RowItems/OrderRowDataItem';
import { debounce } from '../Utils/InvestingUtils';
import { FilledOrderRowDataItem } from '../RowItems/FilledOrderRowDataItem';
import { Resources } from '@shared/localizations/Resources';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { OrderUtils } from '@shared/utils/Trading/OrderUtils';
import { OrderActionEnum } from '@shared/utils/Trading/OrderActionEnum';
import { DataCache } from '@shared/commons/DataCache';
import { Control } from '@front/controls/elements/Control';
import Ractive from 'ractive';
import { ReportManager } from '@shared/utils/Managers/ReportManager';
import { ReportType } from '@shared/commons/cache/AllowedReport';
import { EReportRequestParameter, ReportRequestConfig } from '@shared/commons/UtilsClasses/Report/ReportRequestConfig';

export class InvestingOrdersPanel extends Ractive {
    public selectedItem: any = null;
    public selectedWorkingFilledSwitcherItem: any = null;
    public debounceHandler: any;
    public filledOrders: any[];
    public prevRange: any;

    public getType (): string { return 'InvestingOrdersPanel'; }

    public oninit (): void {
        this.on('clickWorkingFilledSwitcher', this.changeActiveSwitcherWorkingFilledOrders);
        this.on('showChartPanelEvent', this.showChartPanelEvent);
        this.on('moreButtonClickEvent', this.moreButtonClickEvent);
    }

    public oncomplete (): void {
        this.createSwitcherWorkingFilledOrders();
        this.setRangeComboBoxItems();
        this.debounceHandler = debounce(this.UpdateData, 400);
        Control.Ticker.Subscribe(this.debounceHandler, this);
        this.filledOrders = [];
        this.makeRequest({ value: 1 });
        this.observe('selectedItem', this.makeRequest);
    }

    public onteardown (): void {
        Control.Ticker.UnSubscribe(this.debounceHandler, this);
    }

    public showChartPanelEvent (index, tableRowItem): void {
        const items = this.get('arrayRowData');
        if (!items) {
            return;
        }

        const newSelectedItem = tableRowItem;
        if (newSelectedItem === this.selectedItem) {
            return;
        }

        if (this.selectedItem) {
            this.selectedItem.setActiveStatus(false);
        }

        newSelectedItem.setActiveStatus(true);
        this.selectedItem = newSelectedItem;

        this.fire('ChartOpen', this, { date: items[index] });
    }

    public moreButtonClickEvent (index, tableRowItem, posX, posY): void {
        const items = this.get('arrayRowData');
        const itemsMenu = this.createMenuItems(items[index]);
        openContextMenu(posX, posY, itemsMenu);
    }

    public closeChartPanelEvent (): void {
        if (this.selectedItem) {
            this.selectedItem.setActiveStatus(false);
        }

        this.selectedItem = null;
    }

    public createSwitcherWorkingFilledOrders (): void {
        const arrayWorkingFilledSwitcher: any = [
            { Name: 'Working', isWorkingOrders: true },
            { Name: 'Filled', isWorkingOrders: false }
        ];

        arrayWorkingFilledSwitcher[0].active = true;
        this.selectedWorkingFilledSwitcherItem = arrayWorkingFilledSwitcher[0];

        void this.set({ arrayWorkingFilledSwitcher, isWorkingOrders: this.selectedWorkingFilledSwitcherItem.isWorkingOrders });
        this.changeActiveWorkingFilledTable();
    }

    public changeActiveSwitcherWorkingFilledOrders (ev, index): void {
        const items = this.get('arrayWorkingFilledSwitcher');
        if (!items) {
            return;
        }

        const newSelectedWorkingFilledSwitcherItem = items[index];
        if (newSelectedWorkingFilledSwitcherItem === this.selectedWorkingFilledSwitcherItem) {
            return;
        }

        if (this.selectedWorkingFilledSwitcherItem) {
            this.selectedWorkingFilledSwitcherItem.active = false;
        }

        newSelectedWorkingFilledSwitcherItem.active = true;
        this.selectedWorkingFilledSwitcherItem = newSelectedWorkingFilledSwitcherItem;

        void this.set({ arrayWorkingFilledSwitcher: items, isWorkingOrders: newSelectedWorkingFilledSwitcherItem.isWorkingOrders });
        this.changeActiveWorkingFilledTable();
    }

    public changeActiveWorkingFilledTable (): void {
        if (this.isOrderPanelPart()) {
            this.createWorkingOrdersHeaderRow();
        } else {
            this.createFilledOrdersHeaderRow();
        }
    }

    public createWorkingOrdersHeaderRow (): void {
        const arrayHeaderColumn = [
            { Name: 'Symbol' },
            { Name: 'Side' },
            { Name: 'Quantity' },
            { Name: 'Order Type' },
            { Name: 'Price' }
        ];

        void this.set({ arrayHeaderColumn });
    }

    public createFilledOrdersHeaderRow (): void {
        const arrayHeaderColumn = [
            { Name: 'Symbol' },
            { Name: 'Side' },
            { Name: 'Quantity' },
            { Name: 'Order Type' },
            { Name: 'Profit' }
        ];

        void this.set({ arrayHeaderColumn });
    }

    public UpdateData (): void {
        this.createRowTable();
    }

    public createRowTable (): void {
        let arrayRowData = [];
        if (this.isOrderPanelPart()) {
            arrayRowData = this.createOrderRows();
        } else {
            arrayRowData = this.createFilledOrderRows();
        }

        void this.set({ arrayRowData });
    }

    public isOrderPanelPart (): boolean {
        return this.selectedWorkingFilledSwitcherItem?.isWorkingOrders;
    }

    public createOrderRows (): any[] {
        const arrayRowData = [];
        const dict = DataCache.OrderDict;
        const orderParameterContainer = DataCache.OrderParameterContainer;
        const ordersRows = [];

        const panelAcc = this.get('accountItem');
        if (!panelAcc) {
            return;
        }

        for (const ordId in dict) {
            const order = dict[ordId];
            const Obj: any = {};
            const acc = order.Account;

            if (panelAcc !== acc) {
                continue;
            }

            Obj.Symbol = order.Instrument.DisplayName();
            Obj.Description = order.Instrument.DescriptionValue();
            Obj.Img = order.Instrument.LogoAddress;
            Obj.Instrument = order.Instrument;

            Obj.Side = order.GetFullOperationName(true);
            Obj.Quantity = order.Amount.toLocaleString();
            Obj.Price = Obj.Instrument.formatPrice(order.Price) + ' ' + order.Instrument.Exp2;
            Obj.OrderNumber = order.OrderNumber;
            const orderTypeObj = orderParameterContainer.GetOrderType(order.OrderType);

            Obj.Order_Type = Resources.getResource('property.' + orderTypeObj.localizationKey());
            ordersRows.push(Obj);
        }

        for (let i = 0; i < ordersRows.length; i++) {
            const item = ordersRows[i];
            const row = new OrderRowDataItem(item);
            arrayRowData.push(row);
        }

        return arrayRowData;
    }

    public createFilledOrderRows (): any[] {
        const arrayRowData = [];

        const filledOrdersRows = [];

        const trades = DataCache.newTradesDict;
        for (let i = 0; i < this.filledOrders.length; i++) {
            const trade = this.filledOrders[i];
            if (!trades[trade.TradeId]) {
                filledOrdersRows.push(this.createFilledOrderRowData(trade));
            }
        }
        for (const tr in trades) {
            filledOrdersRows.push(this.createFilledOrderRowData(trades[tr]));
        }

        for (let i = 0; i < filledOrdersRows.length; i++) {
            const item = filledOrdersRows[i];
            if (!item) {
                continue;
            }

            const row = new FilledOrderRowDataItem(item);
            arrayRowData.push(row);
        }

        return arrayRowData;
    }

    public createFilledOrderRowData (filledOrder): any {
        const Obj: any = {};
        if (!filledOrder) {
            return null;
        }

        const panelAcc = this.get('accountItem');
        if (!panelAcc) {
            return null;
        }

        const acc = filledOrder.Account;

        if (panelAcc !== acc) {
            return null;
        }

        Obj.Symbol = filledOrder.Instrument.DisplayName();
        Obj.Description = filledOrder.Instrument.DescriptionValue();
        Obj.Img = filledOrder.Instrument.LogoAddress;
        Obj.Instrument = filledOrder.Instrument;

        const operType = OperationType;
        let sideText = Resources.getResource('general.trading.position.' + OrderUtils.getBuySellStr(operType.Buy));
        if (filledOrder.BuySell === OperationType.Sell) {
            sideText = Resources.getResource('general.trading.position.' + OrderUtils.getBuySellStr(operType.Sell));
        }

        Obj.Side = sideText;
        Obj.Quantity = filledOrder.Amount.toLocaleString();
        Obj.Price = Obj.Instrument.formatPrice(filledOrder.Price) + ' ' + filledOrder.Instrument.Exp2;

        const orderParameterContainer = DataCache.OrderParameterContainer;
        const orderTypeObj = orderParameterContainer.GetOrderType(filledOrder.OrderType);
        Obj.Order_Type = Resources.getResource('property.' + orderTypeObj.localizationKey());

        const crossInstrumentUSD = filledOrder.Instrument.DataCache.CrossRateCache.GetCrossPriceIns(filledOrder.Instrument, filledOrder.BuySell);
        // const profit = (filledOrder.PnL - filledOrder.Comission + filledOrder.Swap) * (DataCache.isAllowedForMainAccount(RulesSet.FUNCTION_CALCULATE_TOTAL_ATACCOUNT_CURRENCY_INSTEAD_SERVER_CURRENCY) ? filledOrder.OpenCrossPrise : crossInstrumentUSD);
        const profit = (filledOrder.PnL - filledOrder.Comission + filledOrder.Swap) * (DataCache.isAllowedForMainAccount(undefined) ? filledOrder.OpenCrossPrise : crossInstrumentUSD);

        Obj.Profit = acc.formatPrice(profit);
        Obj.NumberProfit = profit;

        return Obj;
    }

    public CancelOrder (itemObject): void {
        if (!itemObject) {
            return;
        }

        void DataCache.FOrderExecutor.orderAction(OrderActionEnum.ByIds, false, [itemObject.OrderNumber], [itemObject.OrderNumber], false, PlacedFrom.WEB_ORDERS_PANEL_DB_CLICK);
    }

    public createMenuItems (dataItem): Array<{ text: string, imgStyle: string, event: () => void }> {
        const items = [
        // {
        //     text: 'Details',
        //     imgStyle: "info"
        // },
        // {
        //     text: 'Exercise',
        //     imgStyle: "exercise"
        // },
            {
                text: 'Buy',
                imgStyle: 'buy',
                event: () => { openOrderEntry(true, dataItem.source, this.get('accountItem')); }
            },
            {
                text: 'Sell',
                imgStyle: 'sell',
                event: () => { openOrderEntry(false, dataItem.source, this.get('accountItem')); }
            }];

        if (this.isOrderPanelPart()) {
            items.push(
                {
                    text: 'Close',
                    imgStyle: 'delete',
                    event: () => { this.CancelOrder(dataItem.source); }
                });
        }

        return items;
    }

    public setRangeComboBoxItems (): void {
        const items = this.createRangeComboBoxItems();
        void this.set({ rangeComboBoxItems: items, selectedItem: items[0] });
    }

    public createRangeComboBoxItems (): Array<{ text: string, value: number }> {
        const items = [
            {
                text: 'Today',
                value: 1
            },
            {
                text: 'Last week',
                value: 7
            },
            {
                text: 'Last 1M',
                value: 30
            },
            {
                text: 'Last 3M',
                value: 90
            },
            {
                text: 'Last 6M',
                value: 180
            }
        ];

        return items;
    }

    public makeRequest (rangeDays): void {
        if (!rangeDays || this.prevRange === rangeDays.value) {
            return;
        }
        const nowTime = new Date();
        let startTime: number;
        let finishTime: number;
        if (rangeDays.value === 1) {
            const Y = nowTime.getFullYear(); const M = nowTime.getMonth(); const D = nowTime.getDate();

            startTime = new Date(Y, M, D, 0, 0, 0).getTime();
            finishTime = new Date(Y, M, D, 23, 59, 59).getTime();
        } else {
            let Y = nowTime.getFullYear(); let M = nowTime.getMonth(); let D = nowTime.getDate();
            finishTime = new Date(Y, M, D, 23, 59, 59).getTime();
            nowTime.setDate(-rangeDays.value);
            Y = nowTime.getFullYear();
            M = nowTime.getMonth();
            D = nowTime.getDate();
            startTime = new Date(Y, M, D, 0, 0, 0).getTime();
        }

        ReportManager.reportsByComplited.set(ReportType.REPORT_TRADES, false);
        void ReportManager.sendRequestByDate(ReportType.REPORT_TRADES, startTime, finishTime).then((filledOrders) => {
            this.filledOrders = filledOrders;
            this.prevRange = rangeDays.value;
        });
    }
}

Ractive.extendWith(InvestingOrdersPanel, {
    template: InvestingOrdersPanelTemplate,
    data: function () {
        return {
            arrayHeaderColumn: [],
            arrayRowData: [],
            arrayWorkingFilledSwitcher: [],
            accountItem: null
        };
    }
});
