// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { popupErrorHandler } from '../../Utils/AppHandlers';
import { DPProductTypeAndModifyQuantityTemplate } from '../../templates.js';
import { DynPropertyControl } from '../elements/DynPropertyControl';

export class DPProductTypeAndModifyQuantity extends DynPropertyControl {
    constructor () { super(); }

    public override getType (): string { return 'DPProductTypeAndModifyQuantity'; }

    public override oninit (): void {
        super.oninit();
        this.on('terceraNumericInputClick', this.onInputClick);
    }

    public override oncomplete (): void {
        super.oncomplete();
        void this.set('MQNumericLink', this.Controls.MQNumeric);
        this.observe('dp.customLess', (n, o) => {
            const key = n ? 'general.trading.noQuantityAvailable' : 'UserControl.TerceraNumeric.ValueLessMin';
            void this.set('valueLessMinLocalKey', key).then(() => {
                if (!isNullOrUndefined(this.Controls.MQNumeric)) {
                    this.Controls.MQNumeric.onNeedUpdateValidationStateInfo();
                }
            });
        });
    }

    public override lostFocus (): void {
        if (!isNullOrUndefined(this.Controls.MQNumeric)) {
            if (popupErrorHandler.isShowed()) {
                popupErrorHandler.Hide(this.Controls.MQNumeric);
            }
        }
        super.lostFocus();
    }

    public onInputClick (): void {
        if (!this.get('dp.enabled')) {
            void this.set('dp.enabled', true);
        }
    }

    public override localize (): void {
        const locKeyT = this.get('dp.localizationKey_type');
        const locKeyQ = this.get('dp.localizationKey_qty');
        void this.set({ prTypeLabel: Resources.getResource(locKeyT), mqLabel: Resources.getResource(locKeyQ) });
    }
}

DynPropertyControl.extendWith(DPProductTypeAndModifyQuantity, {
    template: DPProductTypeAndModifyQuantityTemplate,
    data: function () {
        return {
            prTypeLabel: 'Target product type',
            mqLabel: 'Modify Quantity',
            MQNumericLink: null,
            valueGreaterMaxLocalKey: 'general.trading.qtyMoreTodayTradedValue',
            valueLessMinLocalKey: 'UserControl.TerceraNumeric.ValueLessMin'
        };
    }
});
