// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export interface IndexedDBConfig {
    dbName: string
    version: number
    stores: IndexedDBStoreConfig[]
}
export interface IndexedDBStoreConfig {
    storeName: string
    keyPath: string
    autoIncrement: boolean
    indexes?: Array<{ name: string, keyPath: string, options?: IDBIndexParameters }>
}
export class IndexedDB {
    private db: IDBDatabase | null = null;
    protected readonly config: IndexedDBConfig;

    constructor (config: IndexedDBConfig) {
        this.config = config;
    }

    protected async openDB (): Promise<IDBDatabase> {
        if (this.db) return this.db;

        return await new Promise((resolve, reject) => {
            const request = indexedDB.open(this.config.dbName, this.config.version);

            request.onupgradeneeded = () => {
                const db = request.result;
                this.config.stores.forEach(storeConfig => {
                    if (!db.objectStoreNames.contains(storeConfig.storeName)) {
                        const storeOptions: IDBObjectStoreParameters = {
                            keyPath: storeConfig.keyPath,
                            autoIncrement: storeConfig.autoIncrement
                        };
                        const objectStore = db.createObjectStore(storeConfig.storeName, storeOptions);
                        storeConfig.indexes?.forEach(index =>
                            objectStore.createIndex(index.name, index.keyPath, index.options)
                        );
                    }
                });
            };

            request.onsuccess = () => {
                this.db = request.result;
                resolve(this.db);
            };

            request.onerror = () => {
                reject(new Error(`Failed to open database: ${request.error?.message}`));
            };
        });
    }

    private async getObjectStore (storeName: string, transactionMode: IDBTransactionMode): Promise<IDBObjectStore> {
        const db = await this.openDB();
        const transaction = db.transaction(storeName, transactionMode);
        return transaction.objectStore(storeName);
    }

    public async addOrUpdate<T> (storeName: string, item: T): Promise<void> {
        const store = await this.getObjectStore(storeName, 'readwrite');
        await new Promise<void>((resolve, reject) => {
            const request = store.put(item);
            request.onsuccess = () => { resolve(); };
            request.onerror = () => { reject(new Error(`Update failed: ${request.error?.message}`)); };
        });
        console.log(`Data updated successfully in ${storeName}`);
    }

    public async get<T> (storeName: string, key: IDBValidKey): Promise<T | null> {
        const store = await this.getObjectStore(storeName, 'readonly');
        return await new Promise((resolve, reject) => {
            const request = store.get(key);
            request.onsuccess = () => { resolve(request.result as T); };
            request.onerror = () => { reject(new Error(`Get failed: ${request.error?.message}`)); };
        });
    }

    public async getAll<T> (storeName: string): Promise<T[]> {
        const store = await this.getObjectStore(storeName, 'readonly');
        return await new Promise((resolve, reject) => {
            const request = store.getAll();
            request.onsuccess = () => { resolve(request.result as T[]); };
            request.onerror = () => { reject(new Error(`GetAll failed: ${request.error?.message}`)); };
        });
    }

    public async delete (storeName: string, key: IDBValidKey): Promise<void> {
        const store = await this.getObjectStore(storeName, 'readwrite');
        await new Promise<void>((resolve, reject) => {
            const request = store.delete(key);
            request.onsuccess = () => { resolve(); };
            request.onerror = () => { reject(new Error(`Delete failed: ${request.error?.message}`)); };
        });
    }

    public async clear (storeName: string): Promise<void> {
        const store = await this.getObjectStore(storeName, 'readwrite');
        await new Promise<void>((resolve, reject) => {
            const request = store.clear();
            request.onsuccess = () => { resolve(); };
            request.onerror = () => { reject(new Error(`Clear failed: ${request.error?.message}`)); };
        });
    }

    // Reading by Index
    public async getByIndex<T> (storeName: string, indexName: string, indexValue: IDBValidKey): Promise<T | null> {
        const store = await this.getObjectStore(storeName, 'readonly');
        const index = store.index(indexName);

        return await new Promise((resolve, reject) => {
            const request = index.get(indexValue);

            request.onsuccess = () => { resolve(request.result as T); };
            request.onerror = () => { reject(new Error(`Get by index failed: ${request.error?.message}`)); };
        });
    }

    public async getAllByIndex<T> (storeName: string, indexName: string, indexValue: IDBValidKey): Promise<T[]> {
        const store = await this.getObjectStore(storeName, 'readonly');
        const index = store.index(indexName);

        return await new Promise((resolve, reject) => {
            const request = index.getAll(indexValue);

            request.onsuccess = () => { resolve(request.result as T[]); };
            request.onerror = () => { reject(new Error(`GetAll by index failed: ${request.error?.message}`)); };
        });
    }

    public async getAllInRangeByIndex<T> (storeName: string, indexName: string, lowerBound: IDBValidKey, upperBound: IDBValidKey): Promise<T[]> {
        const store = await this.getObjectStore(storeName, 'readonly');
        const index = store.index(indexName);

        const range = IDBKeyRange.bound(lowerBound, upperBound);

        return await new Promise((resolve, reject) => {
            const request = index.getAll(range);

            request.onsuccess = () => { resolve(request.result as T[]); };
            request.onerror = () => { reject(new Error(`GetAll in range by index failed: ${request.error?.message}`)); };
        });
    }

    public async deleteByIndex (storeName: string, indexName: string, indexValue: IDBValidKey): Promise<void> {
        const store = await this.getObjectStore(storeName, 'readwrite');
        const index = store.index(indexName);

        await new Promise<void>((resolve, reject) => {
            const request = index.openCursor(IDBKeyRange.only(indexValue));

            request.onsuccess = () => {
                const cursor = request.result;
                if (cursor) {
                    cursor.delete();
                    cursor.continue();
                } else {
                    resolve();
                }
            };

            request.onerror = () => {
                reject(new Error(`Delete by index failed: ${request.error?.message}`));
            };
        });
    }

    public async deleteInRangeByIndex (storeName: string, indexName: string, lowerBound: IDBValidKey, upperBound: IDBValidKey): Promise<void> {
        const store = await this.getObjectStore(storeName, 'readwrite');
        const index = store.index(indexName);
        const range = IDBKeyRange.bound(lowerBound, upperBound);

        await new Promise<void>((resolve, reject) => {
            const request = index.openCursor(range);

            request.onsuccess = () => {
                const cursor = request.result;
                if (cursor) {
                    cursor.delete();
                    cursor.continue();
                } else {
                    resolve();
                }
            };

            request.onerror = () => {
                reject(new Error(`Delete in range by index failed: ${request.error?.message}`));
            };
        });
    }

    public closeDB (): void {
        if (this.db) {
            this.db.close();
            this.db = null;
        }
    }
}
