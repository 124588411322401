// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
class _TvSlTpCache {
    private readonly slTpIdsMap = new Map<string, SlTPIndexContainer>();

    public insertSlTrailingStopPrice (orderId: string, slPrice: number, sloffset: number): TvSlTpModificationType {
        const sltpContainer = this.getsltpContainer(orderId);

        const prevSlPrice = sltpContainer.slPrice;
        const prevSlTrOfset = sltpContainer.slTrOfset;
        const prevIsTrailingStop = sltpContainer.isSlTrailingStop;
        sltpContainer.slPrice = slPrice;
        sltpContainer.slTrOfset = sloffset;
        sltpContainer.isSlTrailingStop = true;

        const hasSl = !isNaN(prevSlPrice);
        if (!hasSl) {
            return TvSlTpModificationType.New;
        }

        if (!prevIsTrailingStop) {
            return TvSlTpModificationType.Replace; // replace SL with TrSL
        }

        if (prevSlPrice === slPrice && prevSlTrOfset === sloffset) {
            return TvSlTpModificationType.WithoutChanges;
        }

        return TvSlTpModificationType.ModifyOrder;
    }

    public insertSlPrice (orderId: string, slPrice: number): TvSlTpModificationType {
        const sltpContainer = this.getsltpContainer(orderId);

        const prevSlPrice = sltpContainer.slPrice;
        const prevIsTrailingStop = sltpContainer.isSlTrailingStop;
        sltpContainer.slPrice = slPrice;
        sltpContainer.slTrOfset = NaN;
        sltpContainer.isSlTrailingStop = false;

        const hasSl = !isNaN(prevSlPrice);
        if (!hasSl) {
            return TvSlTpModificationType.New;
        }

        if (prevIsTrailingStop) {
            return TvSlTpModificationType.Replace; // replace TrSL with SL
        }

        if (prevSlPrice === slPrice) {
            return TvSlTpModificationType.WithoutChanges;
        }

        return TvSlTpModificationType.ModifyOrder;
    }

    public insertTpPrice (orderId: string, tpPrice: number): TvSlTpModificationType {
        const sltpContainer = this.getsltpContainer(orderId);

        const prevTpPrice = sltpContainer.tpPrice;
        sltpContainer.tpPrice = tpPrice;

        const hasTp = !isNaN(prevTpPrice);
        if (!hasTp) {
            return TvSlTpModificationType.New;
        }

        if (prevTpPrice === tpPrice) {
            return TvSlTpModificationType.WithoutChanges;
        }

        return TvSlTpModificationType.ModifyOrder;
    }

    public getSlTrailingStopPrices (orderId: string): [number, number] {
        const sltpContainer = this.getsltpContainer(orderId);
        return [sltpContainer.slPrice, sltpContainer.slTrOfset];
    }

    public getSlPrice (orderId: string): number {
        const sltpContainer = this.getsltpContainer(orderId);
        return sltpContainer.slPrice;
    }

    public getTpPrice (orderId: string): number {
        const sltpContainer = this.getsltpContainer(orderId);
        return sltpContainer.tpPrice;
    }

    public getSlId (orderId: string, isTrailingStop: boolean): string {
        const sltpContainer = this.getsltpContainer(orderId);
        if (isTrailingStop) {
            const sltrStopIndex = sltpContainer.slTrIndex;
            return `${orderId}-TrSL(${sltrStopIndex})`;
        } else {
            const slIndex = sltpContainer.slIndex;
            return `${orderId}-SL(${slIndex})`;
        }
    }

    public getTpId (orderId: string): string {
        const sltpContainer = this.getsltpContainer(orderId);
        const tpIndex = sltpContainer.tpIndex;
        return `${orderId}-TP(${tpIndex})`;
    }

    public isSlId (orderId: string): boolean {
        return orderId.includes('-SL(') || orderId.includes('-TrSL(');
    }

    public isTpId (orderId: string): boolean {
        return orderId.includes('-TP(');
    }

    public removeSlSuffix (orderId: string): string {
        return orderId.replace(/-SL\(\d+\)/, '').replace(/-TrSL\(\d+\)/, '');
    }

    public removeTpSuffix (orderId: string): string {
        return orderId.replace(/-TP\(\d+\)/, '');
        // const parentOrderId = orderId.replace('-TP', '');
        // this.getsltpContainer(parentOrderId).updateTpIndex();
        // return parentOrderId;
    }

    public removeSl (slOrderId: string): void {
        const orderId = this.removeSlSuffix(slOrderId);
        const sltpContainer = this.getsltpContainer(orderId);
        sltpContainer.clearSl();
    }

    public removeTp (tpOrderId: string): void {
        const orderId = this.removeTpSuffix(tpOrderId);
        const sltpContainer = this.getsltpContainer(orderId);
        sltpContainer.clearTp();
    }

    public hasSlPrice (orderId: string): boolean {
        const sltpContainer = this.getsltpContainer(orderId);
        return !isNaN(sltpContainer.slPrice);
    }

    public hasTpPrice (orderId: string): boolean {
        const sltpContainer = this.getsltpContainer(orderId);
        return !isNaN(sltpContainer.tpPrice);
    }

    private getsltpContainer (orderId: string): SlTPIndexContainer {
        if (this.slTpIdsMap.has(orderId)) {
            return this.slTpIdsMap.get(orderId);
        }
        const sltpContainer = new SlTPIndexContainer();
        this.slTpIdsMap.set(orderId, sltpContainer);
        return sltpContainer;
    }
}

class SlTPIndexContainer {
    public slIndex: number = 1;
    public slPrice: number = NaN;

    public tpIndex: number = 1;
    public tpPrice: number = NaN;

    public slTrIndex: number = 1;
    public slTrOfset: number = NaN;
    public isSlTrailingStop: boolean = false;

    public clearTp (): void {
        this.tpIndex += 1;
        this.tpPrice = NaN;
    }

    public clearSl (): void {
        this.slIndex += 1;
        this.slPrice = NaN;
        this.slTrOfset = NaN;
        this.isSlTrailingStop = false;
    }
}

export enum TvSlTpModificationType {
    WithoutChanges,
    New,
    ModifyOrder,
    Replace
}

export const TvSlTpCache = new _TvSlTpCache();
