// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MathUtils } from '../../../Utils/MathUtils';
import { SLTPTrigger } from '../../../Utils/SlTpTrigger';
import { DynProperty } from '../../DynProperty';
import { Resources } from '../../../Localizations/Resources';
import { BaseSettings } from '../../Settings/BaseGeneralSettingsWrapper';

export class SLTPTriggerUtils {
    public static ApplyProperties (slTpTrigger, properties, fromJson = false): void {
        if (fromJson) {
            const p = DynProperty.getPropertyByName(properties, SLTPTrigger.HIDDEN_DYN_PROPERTY_NAME);
            if (DynProperty.isCorrect(p)) {
                slTpTrigger.FillFromShort(p.value);
            } // в базе храним только одно число хар-ющее состояние радиобатонов и комбобокса
            return;
        }
        // дальше идут properties предназначенньіе не для сохранения, а для отображения в Settings
        let dp = DynProperty.getPropertyByName(properties, SLTPTrigger.BUY_TP_DYN_PROPERTY_NAME);
        if (DynProperty.isCorrect(dp)) {
            slTpTrigger.BuyTPbyBid = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, SLTPTrigger.SELL_TP_DYN_PROPERTY_NAME);
        if (DynProperty.isCorrect(dp)) {
            slTpTrigger.SellTPbyBid = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, SLTPTrigger.BUY_SL_DYN_PROPERTY_NAME);
        if (DynProperty.isCorrect(dp)) {
            slTpTrigger.BuySLbyBid = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, SLTPTrigger.SELL_SL_DYN_PROPERTY_NAME);
        if (DynProperty.isCorrect(dp)) {
            slTpTrigger.SellSLbyBid = dp.value;
        }

        dp = DynProperty.getPropertyByName(properties, SLTPTrigger.TRIGGER_IN_OE_DYN_PROPERTY_NAME);
        if (DynProperty.isCorrect(dp)) {
            slTpTrigger.SelectSLTPTriggerInOE = dp.value;
        }
    }

    public static GetProperties (slTpTrigger, sortIndex = 0, forSaving = false, addInOESetting = true): any[] {
        const properties = [];

        if (forSaving) // сохраняем в базу состояние всех радиобатонов и комбобокса одним числом не большим чем 2^5 - 1
        {
            properties.push(new DynProperty(SLTPTrigger.HIDDEN_DYN_PROPERTY_NAME, slTpTrigger.GetShort(), DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        } else { // дальше идут properties которьіе отображаются в Settings
            properties.push(SLTPTriggerUtils.CreateProperty(slTpTrigger, true, false, ++sortIndex)); // Buy TP trigger price
            properties.push(SLTPTriggerUtils.CreateProperty(slTpTrigger, false, false, ++sortIndex)); // Sell TP trigger price
            properties.push(SLTPTriggerUtils.CreateProperty(slTpTrigger, true, true, ++sortIndex)); // Buy SL trigger price
            properties.push(SLTPTriggerUtils.CreateProperty(slTpTrigger, false, true, ++sortIndex)); // Sell SL trigger price

            if (addInOESetting) {
                const dp = new DynProperty('property.SLTPTriggerInOE', slTpTrigger.SelectSLTPTriggerInOE, DynProperty.BOOLEAN, DynProperty.TRADING_DEFAULTS_GROUP);
                dp.sortIndex = ++sortIndex;
                properties.push(dp);
            }
        }

        return properties;
    }

    public static CreateProperty (slTpTrigger, isBuy, isSL, sortIndex = null): any {
        const partName = (isBuy ? 'Buy' : 'Sell') + (isSL ? 'SL' : 'TP');
        const propLocalKey = 'property.' + partName + 'Trigger';
        const v = slTpTrigger[partName + 'byBid'];
        const value = v !== null ? v : SLTPTriggerUtils.GetDefaultValueForProp(isBuy);

        const dp = new DynProperty(propLocalKey, +value, DynProperty.RADIOBUTTON_COMBOITEM_WITH_LABEL, DynProperty.TRADING_DEFAULTS_GROUP);
        dp.objectVariants = [
            { text: Resources.getResource('property.Bid'), value: 1 },
            { text: Resources.getResource('property.Ask'), value: 0 }
        ];
        dp.COMBOBOX_TYPE = DynProperty.INTEGER;
        if (sortIndex !== null) {
            dp.sortIndex = sortIndex;
        }

        return dp;
    }

    public static GetDefaultValueForProp (isBuy/*, isSL */): boolean {
        return !isBuy; // Buy TP/SL trigger price - ASK ; Sell TP/SL trigger price - BID
    }

    public static GetShortByOrder (order): any // returns short value to identify from order (always acticated and all true for opposite side and null data)
    {
        if (!order?.DataCache.AllowCustomSLTPTriggerPriceForUser()) {
            return null;
        }

        let trSL = order.TriggerSL || order.TriggerSLTP;
        let trTP = order.TriggerTP || order.TriggerSLTP;

        if (order.isPosition) {
            if (order.SLOrder) {
                trSL = order.SLOrder.TriggerSL || order.SLOrder.TriggerSLTP;
            }
            if (order.TPOrder) {
                trTP = order.TPOrder.TriggerTP || order.TPOrder.TriggerSLTP;
            }
        }

        const trShort = SLTPTriggerUtils.GetTriggersShortForInstrument(order.Instrument);
        if (!trSL && !trTP) { return trShort; };

        const tr = SLTPTrigger.CreateFromShort(trShort);
        const side = order.BuySell;

        if (trSL) {
            tr[SLTPTrigger.GetPropertyName(side, true)] = trSL === SLTPTrigger.RAW_VALUE_TO_SERVER.BY_BID;
        }
        if (trTP) {
            tr[SLTPTrigger.GetPropertyName(side, false)] = trTP === SLTPTrigger.RAW_VALUE_TO_SERVER.BY_BID;
        }

        tr.SelectSLTPTriggerInOE = true; // if the order/position already has an SL/TP trigger(s), they cannot disappear

        return tr.GetShort();
    }

    public static GetPropsForLogging (short, oldItem, action, entity, isSnapshot = false): any[] {
        const res = [];

        if (MathUtils.IsNullOrUndefined(short)) {
            return res;
        }

        const oldTr = oldItem ? SLTPTrigger.CreateFromShort(oldItem.sltpTriggerShort) : null;
        const oldProps = oldTr ? SLTPTriggerUtils.GetProperties(oldTr, 0, false, false) : null;
        const tr = SLTPTrigger.CreateFromShort(short);
        const props = SLTPTriggerUtils.GetProperties(tr, 0, false, false);

        for (let i = 0; i < props.length; i++) {
            const prop = props[i];
            if (action) prop.action = action;
            if (entity) prop.entity = entity;

            if (!oldProps || oldProps[i].value != prop.value || isSnapshot) {
                res.push(prop);
            }
        }

        return res;
    }

    public static GetTriggersShortForInstrument (ins): any {
        if (!ins?.IsAllowCustomSLTPTriggerPrice) {
            return null;
        }

        const insSett = BaseSettings.insDefSettingsStorage.GetInstrumentSettings(ins);
        const short = insSett ? insSett.sltpTriggerShort : null;

        const valueFromSettings = MathUtils.IsNullOrUndefined(short)
            ? BaseSettings.slTpTriggers.GetShort()
            : short;

        return SLTPTrigger.GetReversed(valueFromSettings); // беремо значення протилежного side <- docs(3.3.1)
    }
}
