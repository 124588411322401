// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { Cursors } from '@shared/commons/Cursors';
import { LinkedSystem } from '../misc/LinkedSystem';
import { GridPanelTemplate } from '../../templates.js';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { PanelLocKeys, PanelNames } from '../UtilsClasses/FactoryConstants';
import { DynProperty } from '@shared/commons/DynProperty';
import { TerceraSymbolLookupBaseDataProvider } from '@shared/commons/NoNFixedListCore';
import { GridPanelEdit } from '../elements/GridPanelEdit';
import { IsAllowedPanel } from '../WorkSpace/WorkSpaceUtils';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type ResizeSplitter } from '../elements/ResizeSplitter';
import { type ChartPanel } from '../../Controls/panels/ChartPanel';
import { ApplicationPanel } from './ApplicationPanel';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';

export class GridPanel extends ApplicationPanel {
    public dataProvider: TerceraSymbolLookupBaseDataProvider;
    public restoreData: any;
    private allCharts: ChartPanel[] = [];
    private restoresElementsSize: Array<{ prevSize: number, nextSize: number }> = null;

    public override getType (): PanelNames { return PanelNames.GridPanel; }

    public override oninit (): void {
        this.topPanelHeight = 0;
        super.oninit();
    }

    public override async oncomplete (): Promise<void> {
        super.oncomplete();

        this.dataProvider = new TerceraSymbolLookupBaseDataProvider();

        this.onEditCallBack = this.onEditCallBack.bind(this);

        this.on('editClick', this.onEditClick);
        this.on('refreshChartClick', this.onRefreshChartClick);
        this.on('onSplitterResize', this.onSplitterResized);

        this.observe('forbiddenPanel', this.onForbiddenPanelChanged);
        // TODO. SHIT CODE.
        if (!this.get('forbiddenPanel')) {
            if (!await this.tryRestorePanels()) {
                await this.UpdateAllCharts(2, 2);
            }
        }

        this.themeChange();
        this.localize();
    }

    private RefindAllCharts (): void {
        this.allCharts = this.findAllComponents<ChartPanel>('chartPanel');
        this.allCharts.forEach(chart => {
            chart.set('parentContainerControl', this);
            if (!IsAllowedPanel(chart)) {
                chart.set('forbiddenPanel', true);
            }
            WorkSpaceManager.currentWorkspace.addPanelFromContainer(chart);
        });
    }

    public onForbiddenPanelChanged (forbiddenPanel: boolean): void {
        if (forbiddenPanel) {
            this.removeAllControls();
        }
    }

    public override TickAsync (): void {
        this.allCharts.forEach(chart => {
            chart.TickAsync();
        });
    }

    public override populate (addPanel: boolean): void {
        if (!addPanel && WorkSpaceManager.needRestoreWS) {
            this.repopulate();
        }
    }

    public repopulate (): void {
        this.allCharts.forEach(chart => {
            if (IsAllowedPanel(chart)) {
                chart.repopulate();
            } else {
                chart.set('forbiddenPanel', true);
            }
        });
    }

    public override updateSettings (): void {
        this.allCharts.forEach(chart => {
            chart.updateSettings();
        });
    }

    public override themeChange (): void {
        if (this.get('forbiddenPanel')) {
            return;
        }

        this.allCharts.forEach(chart => {
            chart.themeChange();
        });
    }

    public override localize (): void {
        super.localize();

        const headerLocKey = PanelLocKeys[PanelNames.GridPanel];
        void this.set('header', Resources.getResource(headerLocKey));

        if (this.get('forbiddenPanel')) {
            return;
        }

        this.allCharts.forEach(chart => {
            chart.localize();
        });
    }

    // #region Xml Settings

    public override getXmlSettingsTemplate (): any {
        const xml: any = {};

        const xmlSettingsTemplateDict = {};
        const serializedPanelPropsDict = {};

        this.allCharts.forEach(chart => {
            const panelName = chart.get('name');
            xmlSettingsTemplateDict[panelName] = chart.getXmlSettingsTemplate();
            serializedPanelPropsDict[panelName] =
            DynProperty.serialize(chart.Properties());
        });

        xml.xmlSettingsTemplateDict = xmlSettingsTemplateDict;
        xml.serializedPanelPropertiesDict = serializedPanelPropsDict;
        xml.panelType = this.get('panelType');
        xml.rows = this.get('rows');
        xml.columns = this.get('columns');

        return xml;
    }

    public override setXmlSettingsTemplate (value): void {
        if (isNullOrUndefined(value)) return;
        // TODO. SHIT CODE.
        this.restoreData = value;
        const deserializePanelPropertiesDict = {};
        const panelNames = Object.keys(value.serializedPanelPropertiesDict);
        for (const name of panelNames) {
            deserializePanelPropertiesDict[name] = DynProperty.deserialize(value.serializedPanelPropertiesDict[name]);
        }
        void this.set({
            deserializePanelPropertiesDict,
            xmlSettingsTemplateDict: value.xmlSettingsTemplateDict
        });
    }

    public override Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();

        const gridElementsSize = this.findAllComponents<ResizeSplitter>('resizeSplitter').map(splitter => splitter.getElementsSize());
        properties.push(new DynProperty('GridElementsSize', gridElementsSize, DynProperty.UNKNOWN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    public override callBack (newProperties: DynProperty[]): void {
        super.callBack(newProperties);

        this.restoresElementsSize = DynProperty.getPropValue(newProperties, 'GridElementsSize');
    }

    public override setSize (w: number, h: number): void {
        super.setSize(w, h);

        this.layoutTables();
    }

    // TODO. SHIT CODE.
    private async tryRestorePanels (): Promise<boolean> {
        try {
            const restoreData = this.restoreData;
            delete this.restoreData;

            if (isNullOrUndefined(restoreData)) return false;

            const panelType = restoreData.panelType;
            if (isNullOrUndefined(panelType)) return false;

            const rows = restoreData.rows;
            const columns = restoreData.columns;

            await this.UpdateAllCharts(rows, columns);
            void this.set({ panelType });

            if (isValidArray(this.restoresElementsSize)) {
                const gridSplitters = this.findAllComponents<ResizeSplitter>('resizeSplitter');
                for (let i = 0; i < gridSplitters.length; i++) {
                    const elementsSize = this.restoresElementsSize[i];
                    gridSplitters[i].setElementsSize(elementsSize.prevSize, elementsSize.nextSize);
                }
            }

            return true;
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }

        return false;
    }

    // #endregion

    // #region Symbol/Account Link In

    public override symbolLink_In (symbolName: string): void {
        const curLinkColor = LinkedSystem.currentLinkOutColor;
        for (let i = 0; i < this.allCharts.length; i++) {
            const chart = this.allCharts[i];
            const panelColor = chart.get('symbolLinkValue');
            if (panelColor !== curLinkColor &&
                panelColor !== TerceraLinkControlConstants.ColorConstants.ALL) {
                continue;
            }

            chart.symbolLink_In(symbolName);
        }
    }

    public override accountLink_In (accId, fromLinkedSystem: boolean): boolean {
        if (fromLinkedSystem && !this.OnAccountLinking()) {
            return false;
        }

        if (this.allCharts == null) {
            return false;
        }

        for (let i = 0; i < this.allCharts.length; i++) {
            const chart = this.allCharts[i];

            chart.accountLink_In(accId);
        }

        return true;
    }

    public override updateAccountLinkVisible (visible): void {
        super.updateAccountLinkVisible(visible);

        this.allCharts.forEach(chart => {
            chart.updateAccountLinkVisible(visible);
        });
    }

    // #endregion

    public override getClientPanel (): HTMLElement {
        return this.find('.grid-panel-container');
    }

    // #region Layout

    public layoutTables (): void {
        if (this.get('forbiddenPanel')) {
            return;
        }

        this.allCharts.forEach(chart => {
            chart.layoutTable();
        });
    }

    private onSplitterResized (): void {
        this.layoutTables();
    }

    public setInstruments (): void {
        const instrumentArray = this.get('instrumentArray');
        if (!isValidArray(instrumentArray)) {
            return;
        }

        for (let i = 0; i < this.allCharts.length; i++) {
            const instrument = instrumentArray[i];
            if (isNullOrUndefined(instrument)) return;

            this.allCharts[i].symbolLink_In(instrument.GetInteriorID());
        }
    }

    private async setDefaultRowsLayout (): Promise<void> {
        await this.set({ standardHeight: 100 });
        await this.set({ standardWidth: 100 });
        await this.set({ standardHeight: 100 / this.get('rows') });
        await this.set({ standardWidth: 100 / this.get('columns') });
        this.layoutTables();
    }

    public onRefreshChartClick (): void {
        this.allCharts.forEach(panel => {
            const terceraChartRactive = panel.terceraChartRactive;
            const chart = terceraChartRactive ? terceraChartRactive.terceraChart : null;
            if (!isNullOrUndefined(chart)) {
                chart.RefreshChart();
            }
        });
    }

    public onEditClick (context): void {
        const button = context.component;
        GridPanelEdit.show(
            button.getX(),
            button.getY() + 26,
            {
                rows: this.get('rows'),
                columns: this.get('columns')
            },
            this.onEditCallBack
        );
    }

    public instrumentsDropDownCallback (instrumentArray: Instrument[]): void {
        void this.set('instrumentArray', instrumentArray);
        this.setInstruments();
    }

    public async onEditCallBack (data): Promise<void> {
        if (isValidNumber(data.rows) && isValidNumber(data.columns)) {
            await this.repopulatePanels(data.rows, data.columns);
        } else if (data.resetLayout) {
            await this.setDefaultRowsLayout();
        }
    }

    private async repopulatePanels (rows: number, columns: number): Promise<void> {
        await this.deleteAllCharts();
        await this.UpdateAllCharts(rows, columns);
    }

    private async UpdateAllCharts (rows: number, columns: number): Promise<void> {
        await this.set({
            standardHeight: 100 / rows,
            standardWidth: 100 / columns,
            rows,
            columns
        });
        this.RefindAllCharts();
    }

    public RowNumbers (): number[] {
        return Array.from({ length: this.get('rows') }, (_, i) => i);
    }

    public ColumnNumbers (): number[] {
        return Array.from({ length: this.get('columns') }, (_, i) => i);
    }

    public override updatePanelHeader (): any { }

    private async deleteAllCharts (): Promise<void> {
        this.allCharts.forEach(chart => {
            WorkSpaceManager.currentWorkspace.removePanelFromContainer(chart);
            chart.dispose();
        });
        await this.set({ rows: 0, columns: 0 });
        this.allCharts = [];
    }

    public override async dispose (): Promise<void> {
        await this.deleteAllCharts();
        super.dispose();
    }
}

ApplicationPanel.extendWith(GridPanel, {
    partials: { bodyPartial: GridPanelTemplate },
    data: function () {
        return {
            symbolLinkValue: TerceraLinkControlConstants.STATE_ALL,
            accountLinkValue: TerceraLinkControlConstants.STATE_ALL,

            panelTypeEnum: PanelNames,

            panelType: PanelNames.ChartPanel,
            rows: 0,
            columns: 0,
            instrumentArray: [],
            cursor: Cursors.Default,
            standardHeight: 100,
            standardWidth: 100,
            deserializePanelPropertiesDict: {}
        };
    }
});
