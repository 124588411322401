// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Account } from './Account';
import { DataCache } from '../DataCache';
import { AccountSeal } from './AccountEnums';
import { InstrumentTypesColor, InstrumentTypesShortName } from '@shared/utils/Instruments/InstrumentTypes';
import { Resources } from '@shared/localizations/Resources';

class AccountUtilsClass {
    public getAccountsList (): Account[] {
        const accountList = new Array<Account>();
        const accounts = DataCache.getAccounts();
        for (const accId in accounts) {
            accountList.push(accounts[accId]);
        }

        return accountList;
    }

    public getSortedAccountList (): Account[] {
        const accounts = this.getAccountsList();

        return accounts.sort((a, b) => {
            return String.CompareOrdinal(a.FullAccString, b.FullAccString);
        });
    }

    public GetAccountModeText (account: Account): string {
        switch (account.AccountMode) {
        case AccountSeal.Demo:
            return Resources.getResource('Accounts.AccountDetails.Mode.Demo');
        case AccountSeal.Live:
            return Resources.getResource('Accounts.AccountDetails.Mode.Live');
        case AccountSeal.Contest:
            return Resources.getResource('Accounts.AccountDetails.Mode.Contest');
        case AccountSeal.Funded:
            return Resources.getResource('Accounts.AccountDetails.Mode.Funded');
        case AccountSeal.Challenge:
            return Resources.getResource('Accounts.AccountDetails.Mode.Challenge');
        }

        return Resources.getResource('Accounts.AccountDetails.Mode.Demo');
    }

    public GetAccountModeWidth (account: Account): number {
        switch (account.AccountMode) {
        case AccountSeal.Demo:
            return 30;
        case AccountSeal.Live:
            return 30;
        case AccountSeal.Contest:
            return 39;
        case AccountSeal.Funded:
            return 39;
        case AccountSeal.Challenge:
            return 45;
        }

        return 30;
    }

    public getInsTypeShortName (account: Account): string {
        return InstrumentTypesShortName[account.PreferredInstrumentType];
    }

    public getInstrumentColor (account: Account): string {
        return InstrumentTypesColor[account.PreferredInstrumentType];
    }

    public needAddType (account: Account): boolean {
        return account.PreferredInstrumentType !== -1;
    }
}

export const AccountUtils = new AccountUtilsClass();
