// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { DPAttentionMsgTemplate } from '../../templates.js';
import { DynPropertyControl } from '../elements/DynPropertyControl';

export class DPAttentionMsg extends DynPropertyControl {
    constructor () { super(); }

    public override localize (): void {
        const locKey = this.get('dp.localizationKey');
        void this.set('messageBoxText', Resources.getResource(locKey));
    }
}

DynPropertyControl.extendWith(DPAttentionMsg, {
    template: DPAttentionMsgTemplate,
    data: function () {
        return {
            messageBoxText: ''
        };
    }
});
