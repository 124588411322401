// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Rectangle } from '../../Geometry';
import { HeatmapSymbolColorBy, HeatmapSymbolFirstValue, HeatmapSymbolSecondValue, HeatmapSymbolSizeBy, HeatmapSymbolTitleBy } from '../Enums';
import { type HeatMapFontManagerParams } from './HeatMapFontManagerParams';
import { HeatMapFontManagerResult } from './HeatMapFontManagerResult';
import { type IHeatmapViewSettings } from './IHeatmapViewSettings';
import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { Font, Graphics } from '../../Graphics';

export class HeatmapViewSettings implements IHeatmapViewSettings {
    minColor: string = '#D50000';
    midColor: string = '#BFBFBF';
    maxColor: string = '#008BFF';
    gradationScaleVisible: boolean = true;

    sectorLevelBackgroundColor: string = '#121A24';
    sectorLevelFontColor: string = '#FFFFFF';

    industryLevelBackgroundColor: string = '#2A3C53';
    industryLevelFontColor: string = '#E4E2E2';

    symbolLevelTitleBy: HeatmapSymbolTitleBy = HeatmapSymbolTitleBy.Symbol;
    symbolLevelFirstValue: HeatmapSymbolFirstValue = HeatmapSymbolFirstValue.ChangePercentage;
    symbolLevelSecondValue: HeatmapSymbolSecondValue = HeatmapSymbolSecondValue.None;
    symbolLevelSizeBy: HeatmapSymbolSizeBy = HeatmapSymbolSizeBy.MarketCap;
    symbolLevelColorBy: HeatmapSymbolColorBy = HeatmapSymbolColorBy.ChangePercentage;
    logoVisible: boolean = true;
    symbolLevelFontColor: string = '#1F1F1F';

    private readonly _minFontTitle = ThemeManager.Fonts.Font_16F_regular;
    private readonly _minFontValues = ThemeManager.Fonts.Font_12F_regular;

    private readonly canvas: any = Graphics.getCalculationCanvas();

    getSymbolFontResult (inputParams: HeatMapFontManagerParams): HeatMapFontManagerResult {
        const result = new HeatMapFontManagerResult();

        const horizontalOffset = inputParams.rectangle.Width / 10;
        const verticalOffset = inputParams.rectangle.Height / 10;

        const mainRectangle = new Rectangle(
            inputParams.rectangle.X + horizontalOffset,
            inputParams.rectangle.Y + verticalOffset,
            inputParams.rectangle.Width - horizontalOffset,
            inputParams.rectangle.Height - verticalOffset);

        const title = inputParams.title;
        const firstValue = inputParams.firstValue;
        const secondValue = inputParams.secondValue;

        result.logoSize = inputParams.logoSize;
        result.titleFont = this._minFontTitle;
        result.firstValueFont = this._minFontValues;
        result.secondValueFont = this._minFontValues;

        result.logoRectangle = Rectangle.Empty();
        result.titleRectangle = Rectangle.Empty();
        result.firstValueRectangle = Rectangle.Empty();
        result.secondValueRectangle = Rectangle.Empty();

        result.logoRectangle = this.getRectangle(result.logoSize, result.logoSize, mainRectangle.Width - 2, mainRectangle.Height - 2); // decrease by 2 pixels so that the stroke does not overlap the symbol
        result.titleRectangle = this.getRectangleForText(title, result.titleFont, mainRectangle.Width, mainRectangle.Height - this.getRectanglesHeightSum(result));
        result.firstValueRectangle = this.getRectangleForText(firstValue, result.titleFont, mainRectangle.Width, mainRectangle.Height - this.getRectanglesHeightSum(result));
        result.secondValueRectangle = this.getRectangleForText(secondValue, result.titleFont, mainRectangle.Width, mainRectangle.Height - this.getRectanglesHeightSum(result));

        if (this.getRectanglesHeightSum(result) === 0 ||
        (result.logoRectangle.IsEmpty() && result.titleRectangle.IsEmpty())) {
            return null;
        }

        this.expandRectangles(result, inputParams);

        this.centerRectangles(result, inputParams);

        return result;
    }

    private getMainRectangle (rectangle: Rectangle): Rectangle {
        const horizontalOffset = rectangle.Width / 10;
        const verticalOffset = rectangle.Height / 10;

        const mainRectangle = new Rectangle(
            rectangle.X + horizontalOffset,
            rectangle.Y + verticalOffset,
            rectangle.Width - horizontalOffset * 2,
            rectangle.Height - verticalOffset * 2);
        return mainRectangle;
    }

    private getRectangle (width: number, height: number, maxWidth: number, maxHeight: number): Rectangle {
        if (width > maxWidth ||
            height > maxHeight ||
            width < 1 || height < 1) {
            return Rectangle.Empty();
        }
        return new Rectangle(0, 0, width, height);
    }

    private getRectangleForText (text: string, font: Font, maxWidth: number, maxHeight: number): Rectangle {
        if (!isValidString(text)) {
            return Rectangle.Empty();
        }
        const graphics = this.canvas.getContext('2d');
        const size = graphics.GetTextSize(text, font, false);
        return this.getRectangle(size.width, size.height, maxWidth, maxHeight);
    }

    private getRectanglesHeightSum (result: HeatMapFontManagerResult): number {
        return result.logoRectangle.Height +
            result.titleRectangle.Height +
            result.firstValueRectangle.Height +
            result.secondValueRectangle.Height;
    }

    private expandRectangles (result: HeatMapFontManagerResult, inputParams: HeatMapFontManagerParams): void {
        const mainRectangle: Rectangle = this.getMainRectangle(inputParams.rectangle);

        while (true) {
            const newLogoRectResult = this.tryExpandRectangle(result.logoRectangle, mainRectangle);
            const newTitleRectAndFontResult = this.tryExpandRectangleForText(inputParams.title, result.titleFont, result.titleRectangle, mainRectangle);
            const newFirstValueRectAndFontResult = this.tryExpandRectangleForText(inputParams.firstValue, result.firstValueFont, result.firstValueRectangle, mainRectangle);
            const newSecondValueRectAndFontResult = this.tryExpandRectangleForText(inputParams.secondValue, result.secondValueFont, result.secondValueRectangle, mainRectangle);

            const newHeight = newLogoRectResult.expandedRectangle.Height +
                newTitleRectAndFontResult.expandedRectangle.Height +
                newFirstValueRectAndFontResult.expandedRectangle.Height +
                newSecondValueRectAndFontResult.expandedRectangle.Height;

            if (newHeight > mainRectangle.Height ||
                newLogoRectResult.maxSizeReached ||
                newTitleRectAndFontResult.maxSizeReached ||
                newFirstValueRectAndFontResult.maxSizeReached ||
                newSecondValueRectAndFontResult.maxSizeReached) {
                return;
            }

            result.logoRectangle = newLogoRectResult.expandedRectangle;
            result.titleRectangle = newTitleRectAndFontResult.expandedRectangle;
            result.firstValueRectangle = newFirstValueRectAndFontResult.expandedRectangle;
            result.secondValueRectangle = newSecondValueRectAndFontResult.expandedRectangle;

            result.titleFont = newTitleRectAndFontResult.resultFont;
            result.firstValueFont = newFirstValueRectAndFontResult.resultFont;
            result.secondValueFont = newSecondValueRectAndFontResult.resultFont;
        }
    }

    private tryExpandRectangle (rectangle: Rectangle, mainRectangle: Rectangle): { maxSizeReached: boolean, expandedRectangle: Rectangle } {
        if (rectangle.IsEmpty()) {
            return { maxSizeReached: false, expandedRectangle: rectangle };
        }

        const expandedRect = this.getRectangle(rectangle.Width + 8, rectangle.Height + 8, mainRectangle.Width, mainRectangle.Height);
        if (expandedRect.IsEmpty()) {
            return { maxSizeReached: true, expandedRectangle: rectangle };
        }

        return { maxSizeReached: false, expandedRectangle: expandedRect };
    }

    private tryExpandRectangleForText (text: string, font: Font, rectangle: Rectangle, mainRectangle: Rectangle): { maxSizeReached: boolean, resultFont: Font, expandedRectangle: Rectangle } {
        if (rectangle.IsEmpty() || !isValidString(text)) {
            return { maxSizeReached: false, resultFont: font, expandedRectangle: rectangle };
        }

        const newFont: Font = new Font(font.Height + 4, font.Family, font.FontWeight);
        const expandedRect: Rectangle = this.getRectangleForText(text, newFont, mainRectangle.Width, mainRectangle.Height);

        if (expandedRect.IsEmpty()) {
            return { maxSizeReached: true, resultFont: font, expandedRectangle: rectangle };
        }

        return { maxSizeReached: false, resultFont: newFont, expandedRectangle: expandedRect };
    }

    private centerRectangles (result: HeatMapFontManagerResult, inputParams: HeatMapFontManagerParams): void {
        const mainRectangle: Rectangle = this.getMainRectangle(inputParams.rectangle);

        let currentY = mainRectangle.Y + ((mainRectangle.Height - this.getRectanglesHeightSum(result)) / 2);

        if (!result.logoRectangle.IsEmpty()) {
            const x: number = inputParams.rectangle.X + (inputParams.rectangle.Width / 2 - result.logoRectangle.Width / 2);
            result.logoRectangle = new Rectangle(x, currentY, result.logoRectangle.Width, result.logoRectangle.Height);
            currentY += result.logoRectangle.Height;
        }
        if (!result.titleRectangle.IsEmpty()) {
            const x: number = inputParams.rectangle.X + (inputParams.rectangle.Width / 2 - result.titleRectangle.Width / 2);
            result.titleRectangle = new Rectangle(x, currentY, result.titleRectangle.Width, result.titleRectangle.Height);
            currentY += result.titleRectangle.Height;
        }
        if (!result.firstValueRectangle.IsEmpty()) {
            const x: number = inputParams.rectangle.X + (inputParams.rectangle.Width / 2 - result.firstValueRectangle.Width / 2);
            result.firstValueRectangle = new Rectangle(x, currentY, result.firstValueRectangle.Width, result.firstValueRectangle.Height);
            currentY += result.firstValueRectangle.Height;
        }
        if (!result.secondValueRectangle.IsEmpty()) {
            const x: number = inputParams.rectangle.X + (inputParams.rectangle.Width / 2 - result.secondValueRectangle.Width / 2);
            result.secondValueRectangle = new Rectangle(x, currentY, result.secondValueRectangle.Width, result.secondValueRectangle.Height);
            currentY += result.secondValueRectangle.Height;
        }
    }
}
