// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MathUtils } from '@shared/utils/MathUtils';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { type SavedOrder } from './SavedOrder';
import { IsAllowed } from '../../../Commons/IsAllowed';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { SlTpNumericInputParameters, SlTpUtils } from '@shared/utils/Trading/SlTpUtils';

class _SavedOrderValidator {
    isValidInstrument (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (isNullOrUndefined(savedOrder.Instrument)) {
            return { isValid: false, error: 'panel.savedOrders.Error.SymbolIsUnknown' };
        } else {
            return { isValid: true, error: '' };
        }
    }

    isValidAccount (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (isNullOrUndefined(savedOrder.Account)) {
            return { isValid: false, error: 'panel.savedOrders.Error.AccountIsUnknown' };
        } else {
            return { isValid: true, error: '' };
        }
    }

    isValidCash (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const validation = this.validateAccountAndInstrument(savedOrder);
        if (!validation.isValid) return validation;

        if (savedOrder.Cash > 0) {
            if (savedOrder.OrderType === OrderType.OCO) { // #124263
                return { isValid: false, error: 'panel.savedOrders.Error.CashWrongOrderType' };
            }

            const isAllowed = IsAllowed.IsCashTradingAllowed(savedOrder.Account, savedOrder.Instrument, savedOrder.OrderType, savedOrder.ProductType);
            if (!isAllowed) { return { isValid: false, error: 'panel.savedOrders.Error.CashNotAvailable' }; }

            const qtyNumericSettings = Quantity.getCashNumericSettings(savedOrder.Instrument, savedOrder.Account);
            if (!MathUtils.isValueMultipleToStep(savedOrder.Cash, qtyNumericSettings.step, qtyNumericSettings.decimalPrecision)) {
                return { isValid: false, error: 'panel.savedOrders.Error.CashNotMultiple' };
            }
        }
        return { isValid: true, error: '' };
    }

    isValidQuantity (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const validation = this.validateAccountAndInstrument(savedOrder);
        if (!validation.isValid) return validation;

        if (savedOrder.Cash > 0) {
            return { isValid: true, error: '' };
        }

        const { Instrument, Account, ProductType } = savedOrder;
        const minLot: number = Instrument.getMinLot(ProductType, Account);
        const maxLot: number = Instrument.getMaxLot(ProductType, Account);
        const lotStep: number = Instrument.getLotStep(ProductType, Account);
        const precision: number = MathUtils.getPrecision(lotStep);

        if (savedOrder.QuantityLots < minLot) {
            return { isValid: false, error: 'panel.savedOrders.Error.QuantityLessMin' };
        } else if (savedOrder.QuantityLots > maxLot) {
            return { isValid: false, error: 'panel.savedOrders.Error.QuantityMoreMax' };
        } else if (!MathUtils.isValueMultipleToStep(savedOrder.QuantityLots, lotStep, precision)) {
            return { isValid: false, error: 'panel.savedOrders.Error.QuantityNotMultiple' };
        } else {
            return { isValid: true, error: '' };
        }
    }

    isValidPrice (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const validation = this.isValidInstrument(savedOrder);
        if (!validation.isValid) return validation;

        const instrument = savedOrder.Instrument;
        const bid: number = savedOrder.Bid;
        const ask: number = savedOrder.Ask;

        if (savedOrder.OrderType === OrderType.Limit || savedOrder.OrderType === OrderType.OCO) {
            if (savedOrder.Operation === OperationType.Buy && savedOrder.Price >= ask) {
                return { isValid: false, error: 'general.trading.limitBuyLessAsk' };
            } else if (savedOrder.Operation === OperationType.Sell && savedOrder.Price <= bid) {
                return { isValid: false, error: 'general.trading.limitSellMoreBid' };
            } else {
                return this.isValidTrading(savedOrder);
            }
        } else if (savedOrder.OrderType === OrderType.Market) {
            if (!isValidNumber(instrument.GetMarketPrice(savedOrder.Account, savedOrder.Operation))) {
                return { isValid: false, error: 'general.trading.noPriceForMarketOrder' };
            } else {
                return { isValid: true, error: '' };
            }
        }
        return { isValid: true, error: '' };
    }

    isValidStopPrice (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const validation = this.isValidInstrument(savedOrder);
        if (!validation.isValid) return validation;

        const bid: number = savedOrder.Bid;
        const ask: number = savedOrder.Ask;

        if (savedOrder.OrderType === OrderType.Stop || savedOrder.OrderType === OrderType.OCO) {
            if (savedOrder.Operation === OperationType.Buy && savedOrder.StopPrice <= ask) {
                return { isValid: false, error: 'general.trading.stopBuyMoreAsk' };
            } else if (savedOrder.Operation === OperationType.Sell && savedOrder.StopPrice >= bid) {
                return { isValid: false, error: 'general.trading.stopSellLessBid' };
            } else {
                return this.isValidTrading(savedOrder);
            }
        } else if (savedOrder.OrderType === OrderType.StopLimit) {
            if (savedOrder.Operation === OperationType.Buy && savedOrder.StopPrice < ask) {
                return { isValid: false, error: 'general.trading.stopBuyMoreAsk' };
            } else if (savedOrder.Operation === OperationType.Sell && savedOrder.StopPrice > bid) {
                return { isValid: false, error: 'general.trading.stopSellLessBid' };
            } else {
                return this.isValidTrading(savedOrder);
            }
        }
        return { isValid: true, error: '' };
    }

    isValidSl (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (savedOrder.SLTPHolder.isSlEmpty()) {
            return { isValid: true, error: '' };
        }

        const isValidInstrument = this.isValidInstrument(savedOrder);
        if (!isValidInstrument.isValid) {
            return isValidInstrument;
        }
        const isAllowedResponse = IsAllowed.IsTradingAllowed([savedOrder.Account], savedOrder.Instrument, OrderType.Stop);
        if (!isAllowedResponse.Allowed) {
            return { isValid: false, error: isAllowedResponse.ReasonLocalizationKey };
        }
        const isValidTrading = this.isValidTrading(savedOrder);
        if (!isValidTrading.isValid) {
            return isValidTrading;
        }

        if (!savedOrder.SLTPHolder.isSlAbsolute()) {
            const offsetType = SlTpUtils.getSLVisualOffsetType(savedOrder.Instrument, savedOrder.SLTPHolder);
            const inputNumericParameters = new SlTpNumericInputParameters(savedOrder.Instrument, offsetType, savedOrder.BasePrice, savedOrder.Operation === OperationType.Buy, true);
            const sltpNumericParameters = SlTpUtils.getSlTpNumericParameters(inputNumericParameters);
            const visualSL = SlTpUtils.toVisualValue(savedOrder.SLTPHolder.StopLossPriceValue, savedOrder.Instrument, offsetType);
            if (visualSL < sltpNumericParameters.min) {
                return { isValid: false, error: 'UserControl.TerceraNumeric.ValueLessMin' };
            } else if (visualSL > sltpNumericParameters.max) {
                return { isValid: false, error: 'UserControl.TerceraNumeric.ValueGreaterMax' };
            } else {
                return { isValid: true, error: '' };
            }
        } else {
            return { isValid: true, error: '' };
        }
    }

    isValidSll (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (savedOrder.SLTPHolder.isSlEmpty()) {
            return { isValid: true, error: '' };
        }

        const isValidInstrument = this.isValidInstrument(savedOrder);
        if (!isValidInstrument.isValid) {
            return isValidInstrument;
        }
        const isAllowedResponse = IsAllowed.IsTradingAllowed([savedOrder.Account], savedOrder.Instrument, OrderType.StopLimit);
        if (!isAllowedResponse.Allowed) {
            return { isValid: false, error: isAllowedResponse.ReasonLocalizationKey };
        }
        return this.isValidTrading(savedOrder);
    }

    isValidTp (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        if (savedOrder.SLTPHolder.isSlEmpty()) {
            return { isValid: true, error: '' };
        }

        const isValidInstrument = this.isValidInstrument(savedOrder);
        if (!isValidInstrument.isValid) {
            return isValidInstrument;
        }
        const isAllowedResponse = IsAllowed.IsTradingAllowed([savedOrder.Account], savedOrder.Instrument, OrderType.Limit);
        if (!isAllowedResponse.Allowed) {
            return { isValid: false, error: isAllowedResponse.ReasonLocalizationKey };
        }
        const isValidTrading = this.isValidTrading(savedOrder);
        if (!isValidTrading.isValid) {
            return isValidTrading;
        }
        if (!savedOrder.SLTPHolder.isTpAbsolute()) {
            const offsetType = SlTpUtils.getTPVisualOffsetType(savedOrder.Instrument, savedOrder.SLTPHolder);
            const inputNumericParameters = new SlTpNumericInputParameters(savedOrder.Instrument, offsetType, savedOrder.BasePrice, savedOrder.Operation === OperationType.Buy, false);
            const sltpNumericParameters = SlTpUtils.getSlTpNumericParameters(inputNumericParameters);
            const visualTP = SlTpUtils.toVisualValue(savedOrder.SLTPHolder.TakeProfitPriceValue, savedOrder.Instrument, offsetType);
            if (visualTP < sltpNumericParameters.min) {
                return { isValid: false, error: 'UserControl.TerceraNumeric.ValueLessMin' };
            } else if (visualTP > sltpNumericParameters.max) {
                return { isValid: false, error: 'UserControl.TerceraNumeric.ValueGreaterMax' };
            } else {
                return { isValid: true, error: '' };
            }
        } else {
            return { isValid: true, error: '' };
        }
    }

    isValidTrading (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const isAllowedResponse = IsAllowed.IsTradingAllowed([savedOrder.Account], savedOrder.Instrument, savedOrder.OrderType);
        return {
            isValid: isAllowedResponse.Allowed,
            error: isAllowedResponse.ReasonLocalizationKey
        };
    }

    isAllowSl (savedOrder: SavedOrder): boolean {
        const isAllowedResponse = IsAllowed.IsSLTPAllowed(savedOrder.Instrument, savedOrder.Account, true);
        return isAllowedResponse.Allowed;
    }

    isAllowTp (savedOrder: SavedOrder): boolean {
        const isAllowedResponse = IsAllowed.IsSLTPAllowed(savedOrder.Instrument, savedOrder.Account, false);
        return isAllowedResponse.Allowed;
    }

    private validateAccountAndInstrument (savedOrder: SavedOrder): { isValid: boolean, error: string } {
        const accountValidation = this.isValidAccount(savedOrder);
        if (!accountValidation.isValid) return accountValidation;

        return this.isValidInstrument(savedOrder);
    }
}

export const SavedOrderValidator = new _SavedOrderValidator();
