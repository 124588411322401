// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class DataBuffer {
    public data = [];
    public offset = 0;

    constructor (bytes?) {
        if (bytes && bytes !== null) {
            this.data = bytes;
        }
    }

    // установить позицию чтения в начало
    public Reset (): void {
        this.offset = 0;
    }

    public EndOfBuffer (): boolean // getter
    {
        return this.offset >= this.data.length;
    }

    public Length (): number // getter
    {
        return this.data.length;
    }

    public ReadDouble (): number {
        const buffer = new ArrayBuffer(8);
        const dv = new DataView(buffer);
        for (let i = 0; i < 8; i++) {
            dv.setUint8(i, this.data[this.offset++]);
        }

        return dv.getFloat64(0);
    }

    public ReadDate (): Date {
        const ticks = this.ReadInt64();
        return new Date(ticks);
    }

    public ReadSingle (): number {
        const buffer = new ArrayBuffer(4);
        const dv = new DataView(buffer);
        for (let i = 0; i < 4; i++) {
            dv.setUint8(i, this.data[this.offset++]);
        }

        return dv.getFloat32(0);
    }

    public ReadInt32 (): number {
        const buffer = new ArrayBuffer(4);
        const dv = new DataView(buffer);
        for (let i = 0; i < 4; i++) {
            dv.setUint8(i, this.data[this.offset++]);
        }

        return dv.getInt32(0);
    }

    public ReadInt64 (): number {
        const buffer = new ArrayBuffer(8);
        const dv = new DataView(buffer);
        for (let i = 0; i < 8; i++) {
            dv.setUint8(i, this.data[this.offset++]);
        }

        const hi = dv.getInt32(0);
        const lo = dv.getUint32(4);
        return hi * 4294967296 + lo;
    }

    public ReadString (): string {
        let length = this.ReadShort();
        if (length < 0) // #112428
        {
            length += 65536;
        } // 32768 * 2

        const readTo = this.offset + length;

        let string = '';
        while (this.offset < readTo) {
            let byte1 = this.data[this.offset++];
            // значения были отрицательными, поэтому символы парсились неправильно
            if (byte1 < 0) {
                byte1 = byte1 + 256;
            }

            if (byte1 < 0x80) {
                string += String.fromCharCode(byte1);
            } else if (byte1 >= 0xC2 && byte1 < 0xE0) {
                const byte2 = this.data[this.offset++];
                string += String.fromCharCode(((byte1 & 0x1F) << 6) + (byte2 & 0x3F));
            } else if (byte1 >= 0xE0 && byte1 < 0xF0) {
                const byte2 = this.data[this.offset++];
                const byte3 = this.data[this.offset++];
                string += String.fromCharCode(((byte1 & 0xFF) << 12) + ((byte2 & 0x3F) << 6) + (byte3 & 0x3F));
            } else if (byte1 >= 0xF0 && byte1 < 0xF5) {
                const byte2 = this.data[this.offset++];
                const byte3 = this.data[this.offset++];
                const byte4 = this.data[this.offset++];
                let codepoint = ((byte1 & 0x07) << 18) + ((byte2 & 0x3F) << 12) + ((byte3 & 0x3F) << 6) + (byte4 & 0x3F);
                codepoint -= 0x10000;
                string += String.fromCharCode(
                    (codepoint >> 10) + 0xD800,
                    (codepoint & 0x3FF) + 0xDC00
                );
            }
        }

        return string;
    }

    public ReadByte (): any {
        let byte0 = this.data[this.offset++];
        if (byte0 < 0) {
            byte0 = byte0 + 256;
        }
        return byte0;
    }

    public ReadShort (): number {
        const buffer = new ArrayBuffer(2);
        const dv = new DataView(buffer);
        dv.setUint8(0, this.data[this.offset++]);
        dv.setUint8(1, this.data[this.offset++]);

        return dv.getInt16(0);
    }

    public ReadBuffer (length): DataBuffer {
        const buffer = [];
        if (length === 0) {
            return new DataBuffer(buffer);
        }

        if (!length || length === null) {
            console.log('Really Nigga ???');
            length = this.data.length - this.offset;
        }

        for (let i = 0; i < length; i++) {
            buffer.push(this.data[this.offset++]);
        }

        return new DataBuffer(buffer);
    }

    public ReadAllBytes (): any[] {
        const buffer = [];
        while (!this.EndOfBuffer()) {
            buffer.push(this.data[this.offset++]);
        }

        return buffer;
    }

    public ReadBoolean (): boolean {
        return this.data[this.offset++] === 1;
    }

    public WriteDouble (value): void {
        const buffer = new ArrayBuffer(8);
        const dv = new DataView(buffer);
        dv.setFloat64(0, value);

        for (let i = 0; i < 8; i++) {
            this.data.push(dv.getUint8(i));
        }
    }

    public WriteDate (value): void {
        const ticks = value.valueOf();
        this.WriteInt64(ticks);
    }

    public WriteSingle (value): void {
        const buffer = new ArrayBuffer(4);
        const dv = new DataView(buffer);
        dv.setFloat32(0, value);

        for (let i = 0; i < 4; i++) {
            this.data.push(dv.getUint8(i));
        }
    }

    public WriteInt32 (value): void {
        const buffer = new ArrayBuffer(4);
        const dv = new DataView(buffer);
        dv.setInt32(0, value);

        for (let i = 0; i < 4; i++) {
            this.data.push(dv.getUint8(i));
        }
    }

    public WriteInt64 (value): void {
        const buffer = new ArrayBuffer(8);
        const dv = new DataView(buffer);
        dv.setBigInt64(0, BigInt(value));

        for (let i = 0; i < 8; i++) {
            this.data.push(dv.getUint8(i));
        }
    }

    public WriteString (value): void {
    // резервируем место под длину, ее саму запишем позже
        const lengthIndex = this.data.length;
        this.WriteShort(0);

        const length = value.length;
        for (let i = 0; i < length; i++) {
            let charcode = value.charCodeAt(i);

            if (charcode < 0x80) {
                this.data.push(charcode);
            } else if (charcode < 0x800) {
                this.data.push(0xc0 | (charcode >> 6),
                    0x80 | (charcode & 0x3f));
            } else if (charcode < 0xd800 || charcode >= 0xe000) {
                this.data.push(0xe0 | (charcode >> 12),
                    0x80 | ((charcode >> 6) & 0x3f),
                    0x80 | (charcode & 0x3f));
            } else {
                i++;
                charcode = 0x10000 + (((charcode & 0x3ff) << 10) |
                (value.charCodeAt(i) & 0x3ff));
                this.data.push(0xf0 | (charcode >> 18),
                    0x80 | ((charcode >> 12) & 0x3f),
                    0x80 | ((charcode >> 6) & 0x3f),
                    0x80 | (charcode & 0x3f));
            }
        }

        // записываю длину в нужное место
        const buffer = new ArrayBuffer(2);
        const dv = new DataView(buffer);
        dv.setInt16(0, this.data.length - lengthIndex - 2);

        this.data[lengthIndex] = dv.getUint8(0);
        this.data[lengthIndex + 1] = dv.getUint8(1);
    }

    public WriteByte (value): void {
        this.data.push(value);
    }

    public WriteShort (value): void {
        const buffer = new ArrayBuffer(2);
        const dv = new DataView(buffer);
        dv.setInt16(0, value);

        this.data.push(dv.getUint8(0));
        this.data.push(dv.getUint8(1));
    }

    public WriteBuffer (value): void {
        this.WriteAllBytes(value.data);
    }

    public WriteAllBytes (value): void {
        for (let i = 0; i < value.length; i++) {
            this.data.push(value[i]);
        }
    }

    public WriteBoolean (value): void {
        this.data.push(value ? 1 : 0);
    }
}
