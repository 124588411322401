// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CrossRateCalculationType } from '../../Utils/CrossRate/CrossRateCalculationType';
import { CrossRateDataValue } from '../../Utils/CrossRate/CrossRateDataValue';
import { CustomEvent } from '../../Utils/CustomEvents';
import { CrossRateForPositions } from '../../Utils/Enums/Constants';
import { OperationType } from '../../Utils/Trading/OperationType';
import { type Instrument } from './Instrument';

export class CrossRateCache {
    public CrossRateDict: Map<string, CrossRateDataValue> = new Map<string, CrossRateDataValue>();
    public OnUpdate = new CustomEvent();
    public dataCache: any;

    constructor (dataCache) {
        this.dataCache = dataCache;
    }

    // #region Constants

    public static NO_CROSS_PRICE = 1;
    public static CROSS_DELIMITER = '/';

    // #endregion Constants

    public Update (msg): void {
        const crossRateDataArr = msg.CrossRateData;
        const len = crossRateDataArr.length;
        for (let i = 0; i < len; i++) {
            const crd = crossRateDataArr[i];
            const crossString = crd.Exp1 + CrossRateCache.CROSS_DELIMITER + crd.Exp2;
            const dataValue = new CrossRateDataValue();

            if (!isNullOrUndefined(crd.IsReverse)) { dataValue.IsReverse = crd.IsReverse; }
            if (!isNullOrUndefined(crd.Price)) { dataValue.Price = crd.Price; }
            if (!isNullOrUndefined(crd.Bid)) { dataValue.Bid = crd.Bid; }
            if (!isNullOrUndefined(crd.Ask)) { dataValue.Ask = crd.Ask; }
            if (!isNullOrUndefined(crd.Bid) && !isNullOrUndefined(crd.Ask)) { dataValue.Bid_Ask = ((crd.Bid + crd.Ask) / 2); }
            if (!isNullOrUndefined(crd.DefaultPriceType)) { dataValue.DefaultPriceType = crd.DefaultPriceType; }

            this.CrossRateDict.set(crossString, dataValue);
        }

        this.OnUpdate.Raise();
    }

    public Clear (): void {
        this.CrossRateDict.clear();
        this.OnUpdate.Raise();
    }

    public static FillCrossRateGraph (w, r): void {
    // TODO. Workaround.
        if (!w.length) return;

        // Алгоритм Флойда — Уоршелла
        // (кубическая сложность, но можно улучшить)
        // немного адаптирован, так как у нас расстояние между вершинами определяется не суммированием, а умножением
        const len = w[0].length;
        for (let k = 0; k < len; k++) {
            for (let i = 0; i < len; i++) {
                for (let j = 0; j < len; j++) {
                    if (w[i][j] === 0 && w[i][k] !== 0 && w[k][j] !== 0) {
                        w[i][j] = w[i][k] * w[k][j];
                        r[i][j] = r[i][k] || r[k][j];
                    }
                }
            }
        }
    }

    public GetCrossPriceInsSideExp2 (ins: Instrument, side: OperationType, exp2: string, reverseCrossString: boolean = false): number {
        if (isNullOrUndefined(ins)) {
            return CrossRateCache.NO_CROSS_PRICE;
        }

        let crossString = ins.Exp2 + CrossRateCache.CROSS_DELIMITER + exp2;
        if (reverseCrossString) {
            crossString = exp2 + CrossRateCache.CROSS_DELIMITER + ins.Exp2;
        }

        let crosstype = this.GetCrossRateType(ins, side, crossString);
        crosstype = this.CorrectCalcTypeByReverse(crosstype, crossString);

        return this.GetCrossRatesValue(crossString, crosstype);
    }

    public GetCrossPriceExp1Exp2 (exp1: string, exp2: string): number {
        const crossString = exp1 + CrossRateCache.CROSS_DELIMITER + exp2;
        const crosstype = CrossRateCalculationType.BID_ASK_2;

        return this.GetCrossRatesValue(crossString, crosstype);
    }

    public GetCrossPriceExp1 (exp1: string): number {
        const crossString = exp1 + CrossRateCache.CROSS_DELIMITER + this.dataCache.baseCurrency;
        const crosstype = CrossRateCalculationType.BID_ASK_2;

        return this.GetCrossRatesValue(crossString, crosstype);
    }

    public GetCrossPriceIns (ins: Instrument, side: OperationType): number {
        if (isNullOrUndefined(ins)) {
            return CrossRateCache.NO_CROSS_PRICE;
        }

        const crossString = ins.Exp2 + CrossRateCache.CROSS_DELIMITER + this.dataCache.baseCurrency;
        let crosstype = this.GetCrossRateType(ins, side, crossString);
        crosstype = this.CorrectCalcTypeByReverse(crosstype, crossString);

        return this.GetCrossRatesValue(crossString, crosstype);
    }

    private GetNotEmptyCrossRatesStorage (): boolean {
        return this.CrossRateDict.size > 0;
    }

    private GetNotEmptyCrossRatesValue (dataValue: CrossRateDataValue, crossType: CrossRateCalculationType): number {
        if (isNullOrUndefined(dataValue)) {
            return CrossRateCache.NO_CROSS_PRICE;
        }

        let result = NaN;
        switch (crossType) {
        case CrossRateCalculationType.BID:
            result = dataValue.Bid;
            break;
        case CrossRateCalculationType.ASK:
            result = dataValue.Ask;
            break;
        case CrossRateCalculationType.BID_ASK_2:
            result = dataValue.Bid_Ask;
            break;
        }

        if (!isNaN(result)) {
            return result;
        } else if (!isNaN(dataValue.Price)) {
            return dataValue.Price;
        } else {
            return CrossRateCache.NO_CROSS_PRICE;
        }
    }

    private GetCrossRatesValue (crossString: string, crosstype: CrossRateCalculationType): number {
        if (this.GetNotEmptyCrossRatesStorage()) {
            const dataValue = this.CrossRateDict.get(crossString);
            return this.GetNotEmptyCrossRatesValue(dataValue, crosstype);
        }
    }

    private CorrectCalcTypeByReverse (crosstype: CrossRateCalculationType, crossString: string): CrossRateCalculationType {
        // ReverseСross может быть null на старой базе, т.к. CrossRatesCache не создаеться а десериализуется

        if (this.GetNotEmptyCrossRatesStorage()) {
            const dataValue = this.CrossRateDict.get(crossString);
            if (!isNullOrUndefined(dataValue)) {
                if (dataValue.IsReverse) {
                    if (crosstype === CrossRateCalculationType.ASK) {
                        crosstype = CrossRateCalculationType.BID;
                    } else if (crosstype === CrossRateCalculationType.BID) {
                        crosstype = CrossRateCalculationType.ASK;
                    }
                }
            }
        }

        return crosstype;
    }

    private GetCrossRateType (instrument: Instrument, side: OperationType, crossString: string): CrossRateCalculationType {
        if (instrument.ValueCrossRateForPositions === CrossRateForPositions.LatestBidAsk) {
            if (side === OperationType.Buy) {
                return CrossRateCalculationType.BID;
            } else {
                return CrossRateCalculationType.ASK;
            }
        } else if (this.GetNotEmptyCrossRatesStorage()) {
            const dataValue = this.CrossRateDict.get(crossString);
            if (!isNullOrUndefined(dataValue)) {
                return dataValue.DefaultPriceType;
            } else {
                return CrossRateCalculationType.BID_ASK_2;
            }
        } else {
            return CrossRateCalculationType.BID_ASK_2;
        }
    }
}
