// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { FillableDataCacheTool } from './FillableDataCacheTool';

export class EllipseDataCacheTool extends FillableDataCacheTool {
    constructor () {
        super();
        this.ToolType = DataCacheToolType.Ellipse;
        this.InitPoints(83);
    }

    get Scale (): number {
        const pts = this.Points;

        const p0 = pts[0];
        const x0 = p0[0];
        const y0 = p0[1];

        const p1 = pts[1];
        const x1 = p1[0];
        const y1 = p1[1];

        const p2 = pts[2];
        const x2 = p2[0];
        const y2 = p2[1];

        const sqrt = Math.sqrt(
            (Math.pow(x1 - x0, 2) + Math.pow(y1 - y0, 2)) /
            (Math.pow(x2 - (x0 + x1) / 2, 2) + Math.pow(y2 - (y0 + y1) / 2, 2))
        );

        return sqrt / 2;
    }

    set Scale (value: number) {
        const pts = this.Points;

        const p0 = pts[0];
        const x0 = p0[0];
        const y0 = p0[1];

        const p1 = pts[1];
        const x1 = p1[0];
        const y1 = p1[1];

        const p2 = pts[2];

        const ax = x1 - x0;
        const ay = y1 - y0;

        const by = Math.sqrt((ax * ax + ay * ay) / (4 * value * value * (1 + ay * ay / ax / ax)));
        const bx = -ay * by / ax;

        p2[0] = bx + (x0 + x1) / 2;
        p2[1] = by + (y0 + y1) / 2;
    }

    MinPointsCount (): number {
        return 3;
    }

    MaxPointsCount (): number {
        return 3;
    }

    AdditionalPointsCount (): number {
        return 80;
    }
}
