// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { LogonMessage } from '../Messages/LogonMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { TimeZoneInfo } from '../../../../Utils/Time/TimeZoneInfo';

export class LogonMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): LogonMessage[] {
        const logon = new LogonMessage(fieldSet);

        // this.decoder.TimeZone = logon.getTimeZone();
        const serverTimeZoneId = fieldSet.IsSetField(FieldsFactory.FIELD_TIME_ZONE_ID) ? fieldSet.GetValue(FieldsFactory.FIELD_TIME_ZONE_ID) : '';
        this.decoder.TimeZoneId = serverTimeZoneId;
        TimeZoneInfo.SERVER_TIME_ZONE = serverTimeZoneId;

        return [logon];
    }
}
