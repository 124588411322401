// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectInstrumentUpdateMessage } from '../../../../Utils/DirectMessages/DirectInstrumentUpdateMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class InstrumentUpdateMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (decoder) {
        super();

        this.decoder = decoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectInstrumentUpdateMessage[] {
        const message = new DirectInstrumentUpdateMessage();

        const symDecodeMap = this.decoder.InstrumentDecodeMap;
        let tickId = -1;

        const len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_INSTRUMENT_ID) {
                tickId = fixField.Value;
                message.Name = symDecodeMap[tickId] || '';
                message.InstrumentID = tickId;
            } else if (fixField.FieldId === FieldsFactory.FIELD_CONTRACT_ID) {
                message.ContractID = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADE_SESSION_STATUS_ID) {
                message.TradeSessionStatusId = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_EXCHANGE_SESSION_NAME) {
                message.ExchangeSessionName = fixField.Value;
            } else if (fixField.FieldId === FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID) {
                message.InstrumentTradableID = fixField.Value;
            }
        }

        return [message];
    }
}
