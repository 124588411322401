// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType, PriceTypeMap } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class WMA extends IndicatorScriptBase {
    public MaPeriod: number;
    public SourcePrice: number;

    constructor () {
        super();
        this.ProjectName = 'Weighted Moving Average';
        this.Comments = 'Weighted Moving Average';
        this.SetIndicatorLine('wma', Color.Red, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.MaPeriod = 9;
        super.InputParameter(new InputParameterInteger('MaPeriod', 'Period', 0, 1, 9999));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Source prices for WMA', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        for (let i = 1; i <= this.MaPeriod; i++) {
            this.devider += i;
        }
    }

    public override GetIndicatorShortName (): string {
        return 'WMA(' + this.MaPeriod + ';' + PriceTypeMap[this.SourcePrice].toLowerCase() + ')';
    }

    public override getCustomName (): string {
        return 'WMA (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        this.GetWMA();
    }

    private devider: number = 0;

    private GetWMA (): void {
        if (!this.MaPeriod) { return; }

        if (this.CurrentData.Count > this.MaPeriod) {
            let result = 0;
            for (let i = 1; i <= this.MaPeriod; i++) {
                result += i * this.CurrentData.GetPrice(this.SourcePrice, i - 1);
            }
            // Setting the current value of the indicator
            if (this.devider !== 0) { this.SetValue(0, 0, result / this.devider); }
        }
    }
}
