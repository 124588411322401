// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { EntitlmentDocument } from '@shared/utils/Entitlment/EntitlmentDocument';

export class EntitlementDocumentGroup extends PFixFieldSet {
    constructor (pfixFieldSet?) {
        super(FieldsFactory.DOCUMENT_GROUP, null, pfixFieldSet);
    }

    public static CreateDocument (documentId, name, content): EntitlementDocumentGroup {
        const gr = new EntitlementDocumentGroup();
        gr.setId(documentId);
        gr.setName(name);
        gr.setContent(content);

        return gr;
    }

    public GetDoucument (): EntitlmentDocument {
        return new EntitlmentDocument(this.getId(), this.getName(), this.getContent());
    }

    public getId (): number {
        return this.GetValue(FieldsFactory.FIELD_DOCUMENT_ID);
    }

    public setId (value: string): void {
        this.SetField(FieldsFactory.FIELD_DOCUMENT_ID, value);
    }

    public getName (): string {
        return this.GetValue(FieldsFactory.FIELD_NAME);
    }

    public setName (value: string): void {
        this.SetField(FieldsFactory.FIELD_NAME, value);
    }

    public getContent (): string {
        return this.GetValue(FieldsFactory.FIELD_DOCUMENT_CONTENT);
    }

    public setContent (value: string): void {
        this.SetField(FieldsFactory.FIELD_DOCUMENT_CONTENT, value);
    }
}
