// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraLevel1PanelTemplate } from '../../templates.js';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { Control } from './Control';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type Account } from '@shared/commons/cache/Account';
import { Level1ItemType } from '@shared/utils/Enums/Level1ItemType';
import { Level1PanelWrapper } from '@shared/commons/UtilsClasses/Level1PanelWrapper';
import { contextMenuHandler } from '@shared/utils/AppHandlers';
import { MouseButtons } from '../UtilsClasses/ControlsUtils';
import { Level1MenuItem } from '../ContextMenuItems/Level1MenuItem';
import { DynProperty } from '@shared/commons/DynProperty';
import { type ContextHelper } from 'ractive';

class Level1PanelItem {
    type: Level1ItemType;
    label: string;
    value: string;
    color: string;

    private readonly level1PanelWrapper: Level1PanelWrapper;

    constructor (type: Level1ItemType, level1PanelWrapper: Level1PanelWrapper) {
        this.type = type;
        this.level1PanelWrapper = level1PanelWrapper;
        this.label = level1PanelWrapper.getLevel1ItemLabel(type);
        this.updateValues();
    }

    updateValues (): void {
        this.value = this.level1PanelWrapper.getLevel1ItemValueStr(this.type);
        this.color = this.level1PanelWrapper.getLevel1ItemColor(this.type);
    }
}

export class TerceraLevel1Panel extends Control {
    private level1MenuItems: Level1MenuItem[] = [];
    private level1PanelWrapper: Level1PanelWrapper;
    private initTypes: Level1ItemType[];

    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraLevel1Panel; }

    public override oncomplete (): void {
        super.oncomplete();

        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');
        this.level1PanelWrapper = new Level1PanelWrapper(instrument, account);
        this.observe('instrument', (ins: Instrument) => { this.level1PanelWrapper.setInstrument(ins); });
        this.observe('account', (acc: Account) => { this.level1PanelWrapper.setAccount(acc); });

        const initTypes = this.initTypes ?? this.getDefaulTypes();
        this.setActiveLevel1Items(initTypes);
        this.level1MenuItems = this.getMenuItems();

        Control.Ticker.Subscribe(this.TickAsync, this);
    }

    public override dispose (): void {
        Control.Ticker.UnSubscribe(this.TickAsync, this);
        super.dispose();
    }

    public TickAsync (): void {
        const level1ActveItems: Level1PanelItem[] = this.get('level1ActveItems');
        level1ActveItems.forEach(item => {
            item.updateValues();
        });

        void this.set('level1ActveItems', [...level1ActveItems]);
    }

    public menuItemSelectedChanged = (item: Level1MenuItem): void => {
        if (item.checked) {
            this.addLevel1Item(item.tag);
        } else {
            this.removeLevel1Item(item.tag);
        }
    };

    public onMouseDown (context: ContextHelper): void {
        super.onMouseDown(context);

        const event: MouseEvent = context.original as MouseEvent;
        if (event.button === MouseButtons.Right) {
            contextMenuHandler.Show(this.level1MenuItems, event.clientX, event.clientY, { isMultiClick: true });
        }
    }

    public callBack (properties: DynProperty[]): void {
        const dp = DynProperty.getPropertyByName(properties, 'level1ActveTypes');
        this.initTypes = dp?.value;
    }

    public properties (): DynProperty[] {
        const properties: DynProperty[] = [];
        const level1ActveTypes: Level1ItemType[] = this.get<Level1PanelItem[]>('level1ActveItems').map(item => item.type);
        properties.push(new DynProperty('level1ActveTypes', level1ActveTypes, DynProperty.UNKNOWN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    private setActiveLevel1Items (level1Types: Level1ItemType[]): void {
        level1Types.forEach(type => {
            if (!this.level1PanelWrapper.isLevel1ItemHidden(type)) {
                this.addLevel1Item(type);
            }
        });
    }

    private addLevel1Item (type: Level1ItemType): void {
        const level1ActveItems: Level1PanelItem[] = this.get('level1ActveItems');
        level1ActveItems.push(new Level1PanelItem(type, this.level1PanelWrapper));
        void this.set('level1ActveItems', level1ActveItems.sort((a, b) => a.type - b.type));
        this.updateColumnCount();
    }

    private removeLevel1Item (type: Level1ItemType): void {
        let level1ActveItems: Level1PanelItem[] = this.get('level1ActveItems');
        level1ActveItems = level1ActveItems.filter(item => item.type !== type);
        void this.set('level1ActveItems', [...level1ActveItems]);
        this.updateColumnCount();
    }

    private isActiveTypesContainType (type: Level1ItemType): boolean {
        const level1ActveItems: Level1PanelItem[] = this.get('level1ActveItems');
        for (const item of level1ActveItems) {
            if (item.type === type) {
                return true;
            }
        }

        return false;
    }

    private getMenuItems (): any[] {
        const menuItems: Level1MenuItem[] = [];
        const allowedItemTypes: Level1ItemType[] = this.get('level1Types');
        allowedItemTypes.forEach(type => {
            if (!this.level1PanelWrapper.isLevel1ItemHidden(type)) {
                const label = this.level1PanelWrapper.getLevel1ItemLabel(type);
                const isChecked = this.isActiveTypesContainType(type);
                menuItems.push(new Level1MenuItem(label, type, this.menuItemSelectedChanged, isChecked));
            }
        });
        return menuItems;
    }

    private updateColumnCount (): void {
        const level1ActveItems: Level1PanelItem[] = this.get('level1ActveItems');
        const rowsCount = this.get('rowsCount');
        const columnsCount = Math.ceil(level1ActveItems.length / rowsCount);
        void this.set({ columnsCount });
    }

    private getDefaulTypes (): Level1ItemType[] {
        return [
            Level1ItemType.Last,
            Level1ItemType.Open,
            Level1ItemType.ChangePercent,
            Level1ItemType.High,
            Level1ItemType.Volume,
            Level1ItemType.Low
        ];
    }
}

Control.extendWith(TerceraLevel1Panel, {

    template: TerceraLevel1PanelTemplate,
    data: function () {
        return {
            itemMatrix: null,
            account: null,
            instrument: null,
            rowsCount: 2,
            columnsCount: 2,
            level1Types: [],
            level1ActveItems: []
        };
    }
});
