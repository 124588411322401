// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectAssetMessage extends DirectMessageBaseClass {
    public Id = 0;
    public Type = 0;
    public Name: string | null = null;
    public Description: string | null = null;
    public MinChange = 0;
    public RouteId: number | null = null;
    public InstrumentId: any = null;
    public CreditForSell: number | null = null;
    public LiquidityRate: number | null = null;
    public InstrumentTradableID = -1;

    constructor () {
        super(Message.CODE_ASSET_TYPE_MESSAGE);
    }
}
