// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { OEOrderTypeSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources, CurrentLang, LOCALE_JA, LOCALE_ES } from '@shared/localizations/Resources';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { StopLimitOrderType } from '@shared/commons/cache/OrderParams/order-type/StopLimitOrderType';
import { StopOrderType } from '@shared/commons/cache/OrderParams/order-type/StopOrderType';
import { OrderExecutorUtils } from '@shared/commons/Trading/OrderExecutorUtils';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type Account } from '@shared/commons/cache/Account';
import { WDSettings } from '../../settings/WDGeneralSettings';
import { ResourcesManager } from '@shared/commons/properties/ResourcesManager';
import { DataCache } from '@shared/commons/DataCache';

export class OEOrderTypeSelector extends ContainerControl {
    constructor () { super(); }

    public useStopLimitInsteadStop: boolean = false;

    public override getType (): string { return 'OEOrderTypeSelector'; }

    public override oninit (): void {
        super.oninit();
        this.observe('selectedItem', this.updateOrderType);
        this.observe('instrument account forbiddenOrderTypes', this.repopulate);
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        WDSettings.settingsChanged.Subscribe(this.updateUseStopLimitInsteadStopSetting, this);
        this.localize();
    }

    public override oncomplete (): void {
        super.oncomplete();

        DataCache.OnUpdateInstrument.Subscribe(this.repopulate, this);
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);
        WDSettings.settingsChanged.UnSubscribe(this.updateUseStopLimitInsteadStopSetting, this);
        DataCache.OnUpdateInstrument.UnSubscribe(this.repopulate, this);

        super.dispose();
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.newOrderEntry.typePanel'));

        const items = this.get('items');
        const changes = {};

        for (let i = 0, len = isValidArray(items) ? items.length : 0; i < len; i++) {
            changes['items.' + i + '.text'] = Resources.getResource(items[i].value.localizationKey());
        }

        void this.set(changes);

        void this.set('infoBtnTooltip', Resources.getResource('panel.newOrderEntry.stopInfoBtn.tt'));

        this.calcTextWidthForInfoBtnOffset();
    }

    public updateOrderType (selectedItem): void {
        if (isNullOrUndefined(selectedItem)) {
            void this.set('orderType', null);

            return;
        }

        const orderType = selectedItem.value;

        void this.set('orderType', orderType);

        this.updateUseStopLimitInsteadStopSetting();
    }

    public repopulate (): void {
        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');
        if (isNullOrUndefined(instrument) || isNullOrUndefined(account)) {
            return;
        }

        const forbiddenOrderTypes: OrderType[] = this.get('forbiddenOrderTypes');

        const orderTypeDict = OrderExecutorUtils.getAllowedOrderTypeDict(
            account,
            instrument,
            forbiddenOrderTypes,
            WDSettings.orderType,
            false
        );

        const orderTypeSortedArr =
        OEOrderTypeSelector.createSortedOrderTypeArray(orderTypeDict);

        // TODO. Common method?
        const items = orderTypeSortedArr.map(function (orderType) {
            return {
                value: orderType,
                text: Resources.getResource(orderType.localizationKey())
            };
        });

        void this.set({
            defaultValue: orderTypeDict[WDSettings.orderType],
            items
        });
    }

    public updateUseStopLimitInsteadStopSetting (): void // #91413 вызовы методов апдейта
    {
        const selectedItem = this.get('selectedItem');
        const orderType = !isNullOrUndefined(selectedItem) ? selectedItem.value : null;

        if (isNullOrUndefined(orderType)) return;

        this.useStopLimitInsteadStop = WDSettings.isUseStopLimitInsteadStop();

        const stopOrderTypeSelected = orderType.id() === OrderType.Stop;
        const needUseStopLimitInsteadStop = this.useStopLimitInsteadStop && stopOrderTypeSelected;

        if (stopOrderTypeSelected && needUseStopLimitInsteadStop) {
            this.calcTextWidthForInfoBtnOffset();
        } // подсчитать отступ для инфо кнопки

        this.updateInfoBtnVisibility(needUseStopLimitInsteadStop); // обновление видимости инфо кнопки

        this.updateOrderTypeIfUseStopLimitInsteadStop(stopOrderTypeSelected, needUseStopLimitInsteadStop); // наперсточник
    }

    public updateInfoBtnVisibility (visible: boolean): void // изменение видимости инфо иконки #91413
    {
        void this.set('visibleInfoBtn', visible);
    }

    public updateOrderTypeIfUseStopLimitInsteadStop (stopOrderTypeSelected: boolean, needUseStopLimitInsteadStop: boolean): void // #91413
    {
        if (needUseStopLimitInsteadStop) {
            void this.set('orderType', new StopLimitOrderType());
        } // подмена orderType в случае включения/выключения настройки useStopLimitInsteadStop или смены orderType при вкл.настройке
        else
            if (stopOrderTypeSelected) {
                void this.set('orderType', new StopOrderType());
            } // снятие настройки UseStopLimitInsteadStop при выбранном Stop ордере -> он был подменен на StopLimit нужно подменить обратно
    }

    public calcTextWidthForInfoBtnOffset (): void // #91413 подсчитывает отступ для инфо кнопки в orderType комбобоксе
    {
        let res = 40; // значение по умолчанию

        const currentLang = CurrentLang;
        if (currentLang === LOCALE_JA || currentLang === LOCALE_ES) // пока вот так вот всрато :( извините...
        {
            res = 60;
        }

        void this.set('infoBtnOffset', res);
    }

    // TODO. Sorting rule.
    public static createSortedOrderTypeArray (orderTypeDict): any[] {
        const arr = [];
        if (isNullOrUndefined(orderTypeDict)) return arr;

        const sortArr = [
            OrderType.Market,
            OrderType.Limit,
            OrderType.Stop,
            OrderType.StopLimit,
            OrderType.TrailingStop,
            OrderType.Care,
            OrderType.OCO];

        for (let i = 0; i < sortArr.length; i++) {
            if (orderTypeDict[sortArr[i]]) {
                const orderType = orderTypeDict[sortArr[i]];
                arr.push(orderType);
            }
        }

        return arr;
    }
}

ContainerControl.extendWith(OEOrderTypeSelector, {
    template: OEOrderTypeSelectorTemplate,
    data: function () {
        return {
            label: '',

            instrument: null,
            account: null,

            items: null,
            selectedItem: null,
            defaultValue: null,

            orderType: null,
            forbiddenOrderTypes: [],

            showLabel: true,
            visibleInfoBtn: false,
            infoBtnTooltip: '',
            infoBtnOffset: 0,
            width: 198
        };
    }
});
