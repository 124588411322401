// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import EventEmitter from 'events';
import { DynProperty } from '../../DynProperty';
import { HotkeysItem } from './HotkeysItem';
import { ChartHotkeysEnum, HotkeysGroupTrading, HotkeysKeyless, MarketDepthHotkeysEnum, OrderEntryHotkeysEnum, TradingHotkeysEnum, WorkingOrdersHotkeysEnum } from './NamesEventHotkey ';
import { PanelNames } from '@front/controls/UtilsClasses/FactoryConstants';
import hotkeys from 'hotkeys-js';

class _HotkeysManager {
    private readonly HotkeysMap: Map<string, HotkeysItem[]> = new Map<string, HotkeysItem[]>();
    private readonly eventEmitter: EventEmitter = new EventEmitter();

    constructor () {
        this.createDefoltHotkeysMap();
        this.startListeningHotkeys();
    }

    public GetHotkeysMap (): Map<string, HotkeysItem[]> {
        return this.HotkeysMap;
    }

    public GetProperties (): DynProperty[] {
        const properties = [];
        for (const key of this.HotkeysMap.keys()) {
            for (const item of this.HotkeysMap.get(key)) {
                properties.push(this.createProperty(item));
            }
        }

        const dp = new DynProperty('CONTAINER', '', DynProperty.HOTKEY_SETTINGS_CONTAINER, DynProperty.HOTKEYS_GROUP);
        properties.push(dp);

        return properties;
    }

    public CallBackProperties (newProperties: DynProperty[]): void {
        for (const key of this.HotkeysMap.keys()) {
            for (const item of this.HotkeysMap.get(key)) {
                const propLocalKey = 'property.Hotkeys.' + item.ActionName;
                const propValue = DynProperty.getPropValue(newProperties, propLocalKey);
                if (!isNullOrUndefined(propValue)) {
                    item.Hotkey = propValue;
                    item.TemporaryHotkey = '';
                }
            }
        }

        this.restarListeningHotkeys();
    }

    private createProperty (item: HotkeysItem): DynProperty {
        const { ActionName, Hotkey } = item;
        const propLocalKey = 'property.Hotkeys.' + ActionName;
        const dp = new DynProperty(propLocalKey, Hotkey, DynProperty.HOTKEYS, DynProperty.HIDDEN_GROUP);
        dp.objectVariants = item;
        return dp;
    };

    private readonly _onHotkeyPress = (event, handler): void => {
        this.eventEmitter.emit('combinationKeyPress', handler.key, event);
    };

    public SubscribeEventHotkeys (callback: (hotkey: string, event: KeyboardEvent) => void): void {
        this.eventEmitter.on('combinationKeyPress', callback);
    }

    public GetActionByHotkeyForPanel (hotkey: string, panelType?: string): string {
        if (!isNullOrUndefined(panelType)) {
            const items = this.HotkeysMap.get(panelType);
            for (const item of items) {
                if (item.Hotkey === hotkey) {
                    return item.ActionName;
                }
            }
        }

        return '';
    }

    private restarListeningHotkeys (): void {
        this.stopListeningHotkeys();
        this.startListeningHotkeys();
    }

    private startListeningHotkeys (): void {
        const arrayHotkeys = this.GetFilteredArrayHotkeys();
        for (const hotkey of arrayHotkeys) {
            hotkeys(hotkey, this._onHotkeyPress);
        }
    }

    private stopListeningHotkeys (): void {
        hotkeys.unbind();
    }

    public GetFilteredArrayHotkeys (): string[] {
        const arrHotkeys = [];
        for (const key of this.HotkeysMap.keys()) {
            for (const item of this.HotkeysMap.get(key)) {
                const { TemporaryHotkey, Hotkey } = item;
                const hotkey = isValidString(TemporaryHotkey) ? TemporaryHotkey : Hotkey;
                if (!isNullOrUndefined(hotkey) && hotkey !== HotkeysKeyless) {
                    arrHotkeys.push(hotkey);
                }
            }
        }

        return [...new Set(arrHotkeys)];
    }

    private createDefoltHotkeysMap (): void {
        this.createChartItems();
        this.createMarketDepthItems();
        this.createOrderEntryItems();
        this.createTradingItems();
        this.createWorkingOrdersItems();
    }

    private createItemHotkey (name: string, key: string): HotkeysItem {
        return new HotkeysItem(name, key);
    }

    private createChartItems (): void {
        const arrayItems = [
            this.createItemHotkey(ChartHotkeysEnum.DisplayInFullScreenMode, 'ALT+F'),
            this.createItemHotkey(ChartHotkeysEnum.ChangeChartStyle, 'ALT+M'),
            this.createItemHotkey(ChartHotkeysEnum.ZoomIn, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.ZoomOut, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.AutoManualScaling, 'CTRL+J'),
            this.createItemHotkey(ChartHotkeysEnum.MouseTrading, 'ALT+S'),
            this.createItemHotkey(ChartHotkeysEnum.ShowHideInfoWindow, 'ALT+D'),
            this.createItemHotkey(ChartHotkeysEnum.AddIndicator, 'ALT+W'),
            this.createItemHotkey(ChartHotkeysEnum.RemoveDrawings, 'CTRL+K'),
            this.createItemHotkey(ChartHotkeysEnum.ShowFilledOrders, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_Tick, 'CTRL+1'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_1m, 'CTRL+2'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_5m, 'CTRL+3'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_15m, 'CTRL+4'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_30m, 'CTRL+5'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_1H, 'CTRL+6'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_4H, 'CTRL+7'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_1D, 'CTRL+8'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_1W, 'CTRL+9'),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_1M, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.SetPeriod_1Y, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.ObjectManagerIndicators, 'CTRL+I'),
            this.createItemHotkey(ChartHotkeysEnum.ObjectManagerDrawings, 'CTRL+B'),
            this.createItemHotkey(ChartHotkeysEnum.ObjectManagerOverlays, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawLine, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawVerticalLine, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawHorizontalLine, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawArrow, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawComments, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawFibonacciRetracement, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawFibonacciExpansion, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawFibonacciArc, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawFibonacciTimeGoalAnalysis, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawFibonacciFans, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawFibonacciSpiral, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawFibonacciTimeZone, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawFibonacciPhiChannel, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawTriangle, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawPolygon, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.DrawRectangle, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.ShowHideGrid, 'CTRL+G'),
            this.createItemHotkey(ChartHotkeysEnum.ShowOpenPositions, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.ShowVolume, 'CTRL+L'),
            this.createItemHotkey(ChartHotkeysEnum.ShowWorkingOrders, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.CancelLastOrderOnSelectedInstrument, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.CancelAllActiveOrdersOnSelectedInstrument, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.CancelBuyOrderClosestToTheLastPrice, HotkeysKeyless),
            this.createItemHotkey(ChartHotkeysEnum.CancelSellOrderClosestToTheLastPrice, HotkeysKeyless)
        ];

        this.HotkeysMap.set(PanelNames.ChartPanel, arrayItems);
    }

    private createMarketDepthItems (): void {
        const arrayItems = [
            this.createItemHotkey(MarketDepthHotkeysEnum.CancelLastOrderOnSelectedInstrument, HotkeysKeyless),
            this.createItemHotkey(MarketDepthHotkeysEnum.CancelAllActiveOrdersOnSelectedInstrument, HotkeysKeyless),
            this.createItemHotkey(MarketDepthHotkeysEnum.CancelBuyOrderClosestToTheLastPrice, HotkeysKeyless),
            this.createItemHotkey(MarketDepthHotkeysEnum.CancelSellOrderClosestToTheLastPrice, HotkeysKeyless),
            this.createItemHotkey(MarketDepthHotkeysEnum.PlaceBuyOrder, HotkeysKeyless),
            this.createItemHotkey(MarketDepthHotkeysEnum.PlaceSellOrder, HotkeysKeyless),
            this.createItemHotkey(MarketDepthHotkeysEnum.SellAsk, HotkeysKeyless),
            this.createItemHotkey(MarketDepthHotkeysEnum.BuyBid, HotkeysKeyless)
        ];

        this.HotkeysMap.set(PanelNames.MarketDepthPanel, arrayItems);
    }

    private createOrderEntryItems (): void {
        const arrayItems = [
            this.createItemHotkey(OrderEntryHotkeysEnum.OpenBuyOE, HotkeysKeyless),
            this.createItemHotkey(OrderEntryHotkeysEnum.OpenSellOE, HotkeysKeyless),
            this.createItemHotkey(OrderEntryHotkeysEnum.PlaceOrder, HotkeysKeyless)
        ];

        this.HotkeysMap.set(PanelNames.AdvancedOrderEntry, arrayItems);
    }

    private createTradingItems (): void {
        const arrayItems = [
            this.createItemHotkey(TradingHotkeysEnum.CloseAllPositionsImmediately, 'CTRL+SHIFT+R'),
            this.createItemHotkey(TradingHotkeysEnum.CancelAllOrdersImmediately, 'CTRL+SHIFT+Q'),
            this.createItemHotkey(TradingHotkeysEnum.CancelAllOrdersAndCloseAllPositionsImmediately, 'CTRL+SHIFT+X'),
            this.createItemHotkey(TradingHotkeysEnum.OpenReports, 'CTRL+R')
        ];

        this.HotkeysMap.set(HotkeysGroupTrading, arrayItems);
    }

    private createWorkingOrdersItems (): void {
        const arrayItems = [
            this.createItemHotkey(WorkingOrdersHotkeysEnum.ModifyOrder, 'CTRL+2'),
            this.createItemHotkey(WorkingOrdersHotkeysEnum.CancelOrder, 'CTRL+3'),
            this.createItemHotkey(WorkingOrdersHotkeysEnum.ChangeToMarket, 'CTRL+4')
        ];

        this.HotkeysMap.set(PanelNames.OrdersPanel, arrayItems);
    }
}

export const HotkeysManager = new _HotkeysManager();
