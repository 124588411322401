// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type YoutubeExtLink } from '../cache/ExternalLink/YoutubeExtLink';

export class ExtLinkUtils {
    constructor () {}

    public static getEmbedYouTubeLinkByVideoID (videoId): string {
        const linkPrefix = 'https://www.youtube.com/embed/';
        return linkPrefix + videoId;
    }

    public static getVideoIDFromYouTubeLink (url): string {
        if (!url) {
            return null;
        }

        const videoIdRegex = /(?:v=|\/)([\w-]{11})(?:[\?&][\w\.-=]*)?/;
        let match = url.match(videoIdRegex);
        if (match) {
            return match[1];
        }

        const liveIdRegex = /\/live\/(\w+)/;
        match = url.match(liveIdRegex);
        if (match) {
            return match[1];
        }

        return null;
    }

    public static sortYoutubeLinks (items: YoutubeExtLink[]): YoutubeExtLink[] {
        const sortedItems = items.sort((link1, link2) => {
            // Stream online comparison
            if (link1.config.getStreamOnline() || link2.config.getStreamOnline()) {
                if (link1.config.getStreamOnline() && link2.config.getStreamOnline()) {
                    const startDate1 = link1.config.getStreamStartDate();
                    const startDate2 = link2.config.getStreamStartDate();

                    if (startDate1 && startDate2) {
                        return startDate1 > startDate2 ? -1 : 1;
                    }
                } else if (link1.config.getStreamOnline()) {
                    return -1;
                } else if (link2.config.getStreamOnline()) {
                    return 1;
                }
            }

            // Upcoming stream comparison
            const upLiveStream1 = link1.liveStream && link1.config.getStreamEndDate() === null;
            const upLiveStream2 = link2.liveStream && link2.config.getStreamEndDate() === null;

            if (upLiveStream1 || upLiveStream2) {
                if (upLiveStream1 && upLiveStream2) {
                    const createDate1 = link1.config.getStreamStartDate() ?? link1.config.getVideoCreateDate();
                    const createDate2 = link2.config.getStreamStartDate() ?? link2.config.getVideoCreateDate();

                    if (createDate1 && createDate2) {
                        return createDate1 < createDate2 ? -1 : 1;
                    }
                } else if (upLiveStream1) {
                    return -1;
                } else if (upLiveStream2) {
                    return 1;
                }
            }

            // Completed stream & video comparison
            const date1 = link1.config.getStreamEndDate() ?? link1.config.getVideoCreateDate();
            const date2 = link2.config.getStreamEndDate() ?? link2.config.getVideoCreateDate();

            if (date1 && date2) {
                return date1 > date2 ? -1 : 1;
            }

            return 0;
        });

        return sortedItems;
    }

    // Show date if needed for a YoutubeLinkTool
    public static showDateIfNeeded (tool: YoutubeExtLink): boolean {
        if (tool.config.getStreamStartDate() === null || tool.config.getStreamEndDate() !== null || !tool.liveStream) {
            return false;
        }
        return true;
    }
};
