// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { Interval } from '../../../../Utils/History/Interval';
import { HistoryMerger } from './HistoryMerger';
import { type DateTime } from 'luxon';

export abstract class HistoryMergerBasedByDay extends HistoryMerger {
    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
    }

    FindIntervalBorders (date: Date): Interval {
        // Date - начало дня должно быть при любых раскладах с учетом офсета сессии
        const dateTime: DateTime = this.session.ConvertDateToSessionDateTime(date).startOf('day');
        const startSessionDate = dateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        // нужно конвертировать DateTime в тайзону сессии с учетом оффсета

        let from: DateTime = startSessionDate;

        while (!this.IsStartPeriodDate(from)) {
            from = from.minus({ days: 1 });
        }

        const to = from.plus({ days: this.GetDaysInPeriod(from) });

        const result = new Interval();
        result.From = this.session.ConvertSessionDateTimeToDate(from);
        result.To = this.session.ConvertSessionDateTimeToDate(to);
        return result;
    }

    protected abstract IsStartPeriodDate (dateTime: DateTime): boolean;

    protected abstract GetDaysInPeriod (dateTime: DateTime): number;

    protected abstract GetBasePeriod (): number;

    protected GetCount (): number {
        return this.newPeriod / this.GetBasePeriod();
    }
}
