// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// https://tp.traderevolution.com/entity/109798

import { Resources } from '../../Localizations/Resources';
import { MathUtils } from '../../Utils/MathUtils';
import { SLTPTrigger } from '../../Utils/SlTpTrigger';
import { type OperationType } from '../../Utils/Trading/OperationType';
import { SLTPTriggerControlTemplate } from '../../templates.js';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { ContainerControl } from './ContainerControl';

interface IRBProps {
    text: string
    value: number
    checked: boolean
}

export class SLTPTriggerControl extends ContainerControl {
    public trigger: SLTPTrigger;

    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.SLTPTriggerControl; }

    public override oninit (): void {
        super.oninit();
        // dynProperty.sltpTriggerShort
        this.observe('value itemSettings.sltpTriggerShort', this.setRadioItemsFromSLTPTrigger);
        this.observe('side', this.onSideChange);
    }

    public override oncomplete (): void {
        super.oncomplete();

        const rbGroup = this.Controls ? this.Controls.radioComboItemGroup : null;
        if (!isNullOrUndefined(rbGroup)) {
            rbGroup.SelectedValueChanged.Subscribe(this.onRadioButtonStateChanged, this);
        }

        this.localize();
    }

    public override dispose (): void {
        const rbGroup = this.Controls ? this.Controls.radioComboItemGroup : null;
        if (!isNullOrUndefined(rbGroup)) {
            rbGroup.SelectedValueChanged.UnSubscribe(this.onRadioButtonStateChanged, this);
        }

        super.dispose();
    }

    public setRadioItemsFromSLTPTrigger (triggerShort: number): void {
        if (MathUtils.IsNullOrUndefined(triggerShort)) {
            return;
        }

        if (isNullOrUndefined(this.trigger)) {
            this.trigger = new SLTPTrigger();
        }

        const trigger = this.trigger;
        trigger.FillFromShort(triggerShort);

        const rbGroup = this.Controls ? this.Controls.radioComboItemGroup : null;
        if (!isNullOrUndefined(rbGroup)) {
            const propName = this.convertFromControlToPropertyName();
            const byBid = trigger[propName]; // true(byBid) or false(byAsk) of SLTPTrigger[propName]

            if (MathUtils.IsNullOrUndefined(byBid)) // цього не повинно статися, але залишу навсяк випадок
            {
                console.log('Wrong SLTPTrigger property name in SLTPTriggerControl.prototype.onRadioButtonStateChanged');
                return;
            }

            rbGroup.setItems(+byBid, SLTPTriggerControl.CreateRBObjVars(byBid));
        }
    }

    public onRadioButtonStateChanged (rbGroup, val): void {
        if (isNullOrUndefined(rbGroup)) return;

        const isForOE: boolean = this.get('isForOE');
        const outItem = this.get(isForOE ? 'dynProperty' : 'itemSettings');
        const short = outItem ? outItem.sltpTriggerShort : null;

        if (MathUtils.IsNullOrUndefined(short)) {
            return;
        }

        if (isNullOrUndefined(this.trigger)) {
            this.trigger = new SLTPTrigger();
        }

        const tr = this.trigger.FillFromShort(short);
        const propName = this.convertFromControlToPropertyName();

        if (MathUtils.IsNullOrUndefined(tr[propName])) // цього не повинно статися, але залишу навсяк випадок
        {
            console.log('Wrong SLTPTrigger property name in SLTPTriggerControl.prototype.onRadioButtonStateChanged');
            return;
        }

        tr[propName] = !!val;

        const newValue = tr.GetShort();
        outItem.sltpTriggerShort = newValue;
        void this.set('value', newValue);
    }

    public onSideChange (side: OperationType): void {
        if (MathUtils.IsNullOrUndefined(side)) {
            return;
        }

        void this.set('value', null); // При смене значения Side сбрасываем выбранные ранее значения SL trigger\TP trigger на дефолтовые для данной стороны
    }

    public convertFromControlToPropertyName (): string // возвращает соответствующее контролу свойство из SLTPTrigger обьекта
    {
        return SLTPTrigger.GetPropertyName(this.get('side'), this.get('isForSL'));
    }

    public localize (): void {
        void this.set('labelText', Resources.getResource(this.getLabelTextKey()));
    }

    public getLabelTextKey (): string // возвращает одну из строк: "property.OETPTrigger", "property.OESLTrigger" <- если контрол из OrderEntry
    { // либо одну из "property.BuyTPTrigger", "property.BuySLTrigger", "property.SellTPTrigger", "property.SellSLTrigger" <- если контрол из TypesManagerScreen
        const isForOE: boolean = this.get('isForOE');
        const sideStr = this.getSideStr();

        const labelTextKey = 'property.' + (isForOE ? 'OE' : sideStr) + this.getSLTPStr() + 'Trigger';
        return labelTextKey;
    }

    public getSLTPStr (): string { return SLTPTrigger.GetSLTPStr(this.get('isForSL')); } // returns "SL" or "TP" depending on isForSL value
    public getSideStr (): string { return SLTPTrigger.GetSideStr(this.get('side')); } // returns "Buy" or "Sell" depending on the opposite side of order side

    public static CreateRBObjVars (byBid): IRBProps[] {
        const result: IRBProps[] = [
            { text: Resources.getResource('property.Bid'), value: 1, checked: false },
            { text: Resources.getResource('property.Ask'), value: 0, checked: false }
        ];

        if (!MathUtils.IsNullOrUndefined(byBid)) {
            result[0].checked = !!byBid;
            result[1].checked = !byBid;
        }

        return result;
    }
}

ContainerControl.extendWith(SLTPTriggerControl, {
    data: function () {
        return {
            left: 224,
            right: 50,
            width: 130,

            triggersVisible: false,
            isForOE: false, // if not it's control from TypesManager
            isForSL: null, // trigger can be for SL or TP,
            side: null, // also it have to be one of OperationType (Buy or Sell)
            labelText: null,
            value: null, // текущее значение определяющее состояния радиобатонов
            dynProperty: null, // SLTPDynProperty when in OE
            itemSettings: null // работает с TypesManager-ом, по сути itemSettings.sltpTriggerShort тоже что и value
        };
    },
    template: SLTPTriggerControlTemplate
});
