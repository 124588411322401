// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { Color } from '../../../Graphics';
import { MAMode } from '../IndicatorConstants';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class BBW extends IndicatorScriptBase
{
    constructor()
    {
        super();
        this.ProjectName = 'Bollinger Bands Width';
        this.Comments = 'Bollinger Bands Width';
        super.SetIndicatorLine('bbw', Color.Aqua, 1, LineStyle.SimpleChart);
        this.SeparateWindow = true;

        this.Length = 20;
        super.InputParameter(new InputParameterInteger('Length', 'Length', 0, 1, 9999));

        this.SourcePrices = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrices', 'Sources prices for MA', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.Deviation = 2;
        super.InputParameter(new InputParameterInteger('Deviation', 'Interval', 2, -2147483647, 2147483647, 1, 0.1));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of Moving Average', 3,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));
    }

    public Deviation: number;

    public Length: number;

    public MAType: number;

    public SourcePrices: number;

    private ma: iBuildInIndicator;

    public Init(): void
    {
        super.Init();
        super.IndicatorShortName(this.GetIndicatorShortName());
    }

    public GetIndicatorShortName(): string
    {
        return `BBW ${this.Length} ${Object.keys(PriceType).find(key => PriceType[key] === this.SourcePrices)} ${this.Deviation}`;
    }

    public override createInternalIndicators(): iBuildInIndicator[]
    {
        this.ma = this.Indicators.iMA(this.CurrentData, this.Length, this.MAType, 0, this.SourcePrices) as iBuildInIndicator;
        return [this.ma];
    }

    public OnQuote(): void
    {
        super.OnQuote();

        if (this.CurrentData.Count <= this.Length) { return; };

        const maValue = this.ma.GetValue();
        let deviationValue = 0;
        for (let i = 0; i < this.Length; i++) { deviationValue += Math.pow(this.CurrentData.GetPrice(this.SourcePrices, i) - maValue, 2); };

        // Calculation of deviation value
        deviationValue = this.Deviation * Math.sqrt(deviationValue / this.Length);

        const upperBand = maValue + deviationValue;
        const lowerBand = maValue - deviationValue;
        const middleBand = maValue;
        const bandWidth = (upperBand - lowerBand) / middleBand;

        super.SetValue(0, bandWidth);
    }
}
