// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
class _TimeSpan {
// TODO. Rename. Refactor.
    public FromMinutes (minutes): Date {
        return new Date(TimeSpanPeriods.MillisPerMinute * minutes);
    }

    public FromHours (hours): Date {
        return new Date(TimeSpanPeriods.MillisPerHour * hours);
    }

    // TODO. Rename. Refactor.
    public FromDays (days): Date {
        return new Date(TimeSpanPeriods.MillisPerDay * days);
    }

    // TODO. Rename. Refactor.
    public FromUTCTimeOfDay (dt): Date {
        const millis =
        dt.getUTCHours() * TimeSpanPeriods.MillisPerHour +
        dt.getUTCMinutes() * TimeSpanPeriods.MillisPerMinute +
        dt.getUTCSeconds() * TimeSpanPeriods.MillisPerSecond +
        dt.getUTCMilliseconds();

        return new Date(millis);
    }

    // TODO. Rename. Refactor.
    public toSeconds (millis): number {
        return Math.round(millis / TimeSpanPeriods.MillisPerSecond);
    }

    public ticksToTimeSpanObject (ticks) {
        const days = Math.floor(ticks / TimeSpanPeriods.TicksPerDay);

        ticks -= days * TimeSpanPeriods.TicksPerDay;

        const hours = Math.floor(ticks / TimeSpanPeriods.TicksPerHour);

        ticks -= hours * TimeSpanPeriods.TicksPerHour;

        const minutes = Math.floor(ticks / TimeSpanPeriods.TicksPerMinute);

        ticks -= minutes * TimeSpanPeriods.TicksPerMinute;

        const seconds = Math.floor(ticks / TimeSpanPeriods.TicksPerSecond);

        return {
            Days: days,
            Hours: hours,
            Minutes: minutes,
            Seconds: seconds
        };
    }

    public ToTimeSpanString (span, format?): string {
        let formatted = '';

        format = format || TimeSpanFormat.All;

        switch (format) {
        case TimeSpanFormat.All:
            if (span.Days > 0) {
                formatted += span.Days + TimeSpanPeriodString.Day;
            }

            if (span.Hours > 0) {
                formatted += (formatted ? ' ' : '') + span.Hours + TimeSpanPeriodString.Hour;
            }

            if (span.Minutes > 0) {
                formatted += (formatted ? ' ' : '') + span.Minutes + TimeSpanPeriodString.Month;
            }

            if (span.Seconds > 0) {
                formatted += (formatted ? ' ' : '') + span.Seconds + TimeSpanPeriodString.Second;
            }
            break;

        case TimeSpanFormat.YearDay:
            formatted = (span.Days > 0 ? Math.floor(span.Days / 365) + TimeSpanPeriodString.Year : '') +
                (span.Days > 0 ? span.Days % 365 + TimeSpanPeriodString.Day : '');
            break;

        case TimeSpanFormat.DayHour:
            formatted = (span.Days > 0 ? span.Days + TimeSpanPeriodString.Day + ' ' : '') +
                (span.Hours > 0 ? span.Hours + TimeSpanPeriodString.Hour : '');
            break;

        case TimeSpanFormat.HourMinute:
            formatted = (span.Hours < 10 ? '0' : '') + span.Hours + ':' + (span.Minutes < 10 ? '0' : '') + span.Minutes;
            break;

        case TimeSpanFormat.MinuteSecond:
            formatted = (span.Minutes < 10 ? '0' : '') + span.Minutes + ':' + (span.Seconds < 10 ? '0' : '') + span.Seconds;
            break;

        case TimeSpanFormat.HourMinuteSecond:
            const hours = span.Hours + (span.Days > 0 ? span.Days * 24 : 0);
            formatted = (hours < 10 ? '0' : '') + hours + ':' + (span.Minutes < 10 ? '0' : '') + span.Minutes + ':' + (span.Seconds < 10 ? '0' : '') + span.Seconds;
            break;
        }

        return formatted;
    }

    public MomentDurationToTimeSpan (md) {
        let milliseconds = md.asMilliseconds();

        let seconds = Math.floor(milliseconds / 1000);
        milliseconds = milliseconds % 1000;

        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;

        let hours = Math.floor(minutes / 60);
        minutes = minutes % 60;

        const days = Math.floor(hours / 24);
        hours = hours % 24;

        return {
            Milliseconds: milliseconds,
            Seconds: seconds,
            Minutes: minutes,
            Hours: hours,
            Days: days
        };
    }

    public FromMinToMs (minutes: number): number {
        return minutes * 60 * 1000;
    }
}

export const TimeSpan = new _TimeSpan();

export enum TimeSpanPeriods {
    TicksPerDay = 86400000,
    TicksPerHour = 3600000,
    TicksPerMillisecond = 1,
    TicksPerSecond = 1000,
    TicksPerMinute = 60000,
    MillisPerSecond = 1000,
    MillisPerMinute = 60000,
    MillisPerHour = 3600000,
    MillisPerDay = 86400000
}

export enum TimeSpanFormat {
    All = 0,
    YearDay = 1,
    DayHour = 2,
    HourMinute = 3,
    MinuteSecond = 4,
    MilisecondSecondMinute = 5,
    AdvancedAllTime = 6,
    HourMinuteSecond = 7
}

enum TimeSpanPeriodString {
    Year = 'y',
    Day = 'd',
    Hour = 'h',
    Month = 'm',
    Minute = 'm',
    Second = 's'
}
