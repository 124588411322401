// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ErrorInformationStorage } from '../ErrorInformationStorage';
import { OrderHistory } from './OrderHistory';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { OrderExecutionType } from '@shared/utils/Trading/OrderExecutionType';

export class OrderHistoryCache {
    private readonly DataCache: any;
    private dailyHistory: OrderHistory[] = [];
    public orderHistoryArray: OrderHistory[] = [];

    public AddOrderHistoryEvent = new CustomEvent();
    public RemovedOrderHistoryEvent = new CustomEvent();

    constructor (dataCache) {
        this.DataCache = dataCache;
    }

    public newMessage (msg): void {
        const DC = this.DataCache;
        const ins = DC.getInstrumentByTradable_ID(msg.InstrumentTradableID, msg.Route);
        if (!ins) {
            DC.AddDeferredMessage(msg);
            return;
        }

        let orderHistory: OrderHistory = null;
        try {
            switch (msg.EventType) {
            case OrderExecutionType.PENDING_NEW:
            case OrderExecutionType.PENDING_EXECUTION:
            case OrderExecutionType.PENDING_CANCEL:
            case OrderExecutionType.PENDING_REPLACE:
                return;
            }

            orderHistory = OrderHistory.fromMessage(msg, DC);
        } catch (e) {
            ErrorInformationStorage.GetException(e);
        }

        if (orderHistory === null) {
            return;
        }

        if (msg.EventType === OrderExecutionType.REMOVED) {
            this.RemovedOrderHistory(msg);
            this.RemovedOrderHistoryEvent.Raise(orderHistory);
            return;
        }

        const arrDaily = this.dailyHistory;
        if (orderHistory.Daily) {
            arrDaily.push(orderHistory);
        }

        const arr = this.orderHistoryArray;
        arr.push(orderHistory);

        // TODO. Ugly.
        // разрешаю иметь не более 1000 ордеров в истории
        if (arrDaily.length > 1100) {
            arrDaily.splice(0, 100);
        }

        this.AddOrderHistoryEvent.Raise(orderHistory);
    }

    public RemovedOrderHistory (msg): void {
        this.orderHistoryArray = this.orderHistoryArray.filter(orderHistory => orderHistory.FOrderNumber !== msg.FOrderNumber);
    }

    public getReportHistory (reportHistory: OrderHistory[], addDailyHistory: boolean): OrderHistory[] {
        if (addDailyHistory) {
            return this.arrHistoryConcat(reportHistory);
        } else {
            return reportHistory;
        }
    }

    public Clear (): void {
        this.orderHistoryArray = [];
        this.dailyHistory = [];
    }

    private arrHistoryConcat (reportHistory: OrderHistory[]): OrderHistory[] {
        const newArr = this.dailyHistory.concat(reportHistory);
        const result = newArr.filter((value, index, currArr) => {
            return index === currArr.findIndex((fvalue) => {
                return fvalue.FOrderNumber === value.FOrderNumber &&
                fvalue.eventTypeRaw === value.eventTypeRaw &&
                fvalue.eventTypeRaw !== OrderExecutionType.UNKNOWN;
            });
        });

        return result;
    }
}
