// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type CrossRateCalculationType } from './CrossRateCalculationType';
export class CrossRateData {
    public Symbol: any = null;
    public Exp1: string | null = null;
    public Exp2: string | null = null;
    public Price = 0;
    public IsReverse = false;
    public Bid: number | null = null;
    public Ask: number | null = null;
    public DefaultPriceType: CrossRateCalculationType | null = null;

    public toString (): string {
        return this.Exp1 + '/' + this.Exp2 + '=' + this.Price;
    }
}
