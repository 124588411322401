// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../../Utils/CustomEvents';
import { datePickerDropDownHandler } from '../../../Utils/AppHandlers';
import { KeyCode, GetDigit } from '../../../Commons/KeyEventProcessor';
import { TerceraDateTimePickerTemplate } from '../../../templates.js';
import { Control } from '../Control';
import { ScrollUtils } from '../../UtilsClasses/ScrollUtils';
import { type ParamType } from '../../../Commons/cache/AllowedReportConstants';
import moment from 'moment';
import $ from 'jquery';

export class TerceraDateTimePicker extends Control {
    public dateTimePartGetSetDict: any = null;
    public dateTimePartsViewOrder: string[] = ['day', 'month', 'year', 'hours', 'minutes', 'seconds'];
    public DateTimeChanged: CustomEvent;
    public paramType: ParamType;

    public override getType (): string { return 'TerceraDateTimePicker'; }

    public override oninit (): void {
        super.oninit();

        const dateTimePartGetSetDict: any = {};
        dateTimePartGetSetDict.day = { get: this.getDay, set: this.setDay };
        dateTimePartGetSetDict.month = { get: this.getMonth, set: this.setMonth };
        dateTimePartGetSetDict.year = { get: this.getYear, set: this.setYear };
        dateTimePartGetSetDict.hours = { get: this.getHours, set: this.setHours };
        dateTimePartGetSetDict.minutes = { get: this.getMinutes, set: this.setMinutes };
        dateTimePartGetSetDict.seconds = { get: this.getSeconds, set: this.setSeconds };
        this.dateTimePartGetSetDict = dateTimePartGetSetDict;

        this.DateTimeChanged = new CustomEvent();
        this.observe('dateTime', this.onDateTimeChanged);
        this.on('onClick', this.showDateDropDown);
        this.on('containerKeyDown', this.onContainerKeyDown);
        this.on('keyDown', this.onDateTimePartKeyDown);
        this.on('mouseWheel', this.onMouseWheel);
        this.on('containerFocus', this.onContainerFocus);
        this.on('focus', this.onDateTimePartFocus);
        this.on('blur', this.onDateTimePartBlur);
        this.onDateChangedFromDropDown = this.onDateChangedFromDropDown.bind(this);
    }

    public showDateDropDown (): void {
        const $root = $(this.find('div'));
        const $window = $(window);

        const offset = $root.offset();
        const posY = offset.top - $window.scrollTop() + 19;
        const posX = offset.left - $window.scrollLeft();

        datePickerDropDownHandler.Show(
            this.get('dateTime'),
            this.onDateChangedFromDropDown,
            posX,
            posY);
    }

    public onDateChangedFromDropDown (date: Date): void {
        const curDT = this.get('dateTime');
        curDT.setFullYear(date.getFullYear());
        curDT.setMonth(date.getMonth());
        curDT.setDate(date.getDate());

        void this.set('dateTime', curDT);
    }

    public onDateTimeChanged (newVal: Date): void {
        this.DateTimeChanged.Raise(this, newVal);
    }

    public onContainerFocus (): void {
        this.setFocus();
    }

    public onDateTimePartFocus (event, dateTimePartName): void {
        void this.set('focusedDateTimePartName', dateTimePartName);
        this.setFocus();
    }

    public onDateTimePartBlur (event, dateTimePartName): void {
        void this.set('focusedDateTimePartName', null);

        if (dateTimePartName === 'year') {
        // Validation of keyboard input.
            this.setYear(this.getYear());
        }
    }

    public processDateTimePartsNavigation (keyCode: KeyCode): boolean {
        if (keyCode !== KeyCode.LEFT && keyCode !== KeyCode.RIGHT) {
            return false;
        }

        const dateTimePartsViewOrder = this.dateTimePartsViewOrder;
        let resultDateTimePartName = dateTimePartsViewOrder[0];

        const focusedDateTimePartName = this.get('focusedDateTimePartName');
        if (focusedDateTimePartName) {
            let idx = dateTimePartsViewOrder.indexOf(focusedDateTimePartName);
            idx = idx + (keyCode === KeyCode.LEFT ? -1 : 1);

            const maxIdx = dateTimePartsViewOrder.length - 1;

            if (idx > maxIdx) {
                idx = 0;
            } else if (idx < 0) {
                idx = maxIdx;
            }

            resultDateTimePartName = dateTimePartsViewOrder[idx];
        }

        const div = this.find('.' + resultDateTimePartName);
        div.focus();

        return true;
    }

    public onContainerKeyDown (event): boolean {
        this.processDateTimePartsNavigation(event.original.which);
        return false;
    }

    public onDateTimePartKeyDown (event, dateTimePartName): boolean {
        const keyCode = event.original.which;

        if (this.processDateTimePartsNavigation(keyCode)) {
            return false;
        }

        if (keyCode === KeyCode.UP || keyCode === KeyCode.DOWN) {
            this.setScrollValue(dateTimePartName, keyCode === KeyCode.UP);
            return false;
        }

        const digit = GetDigit(keyCode);
        if (isNaN(digit)) return false;

        const dtPartGetSetObj = this.dateTimePartGetSetDict[dateTimePartName];
        const curDTPartValue = dtPartGetSetObj.get.call(this);
        const newDTPartValue = parseInt(curDTPartValue.toString() + digit.toString());

        // TODO.
        const options = {
            setOnlyIfValidValue: true,
            valueFromKeyboardInput: true
        };

        const dtPartSetFunc = dtPartGetSetObj.set;
        const valueWithinBounds = dtPartSetFunc.call(this, newDTPartValue, options);
        if (!valueWithinBounds) {
            dtPartSetFunc.call(this, digit, options);
        }

        return false;
    }

    public override onMouseWheel (context, dateTimePartName): boolean {
        const event = context.event;
        if (this.get('focusedDateTimePartName') !== dateTimePartName) {
            return false;
        }

        this.setScrollValue(dateTimePartName, ScrollUtils.IsScrollUp(event.deltaY));
        return false;
    }

    public setScrollValue (dateTimePartName, setNextValue: boolean): void {
        const getSetObj = this.dateTimePartGetSetDict[dateTimePartName];

        let val = getSetObj.get.call(this);

        val += setNextValue ? 1 : -1;

        getSetObj.set.call(this, val);
    }

    // #region dateTime GET/SET Methods

    public getDay (): number {
        return this.get('dateTime').getDate();
    }

    public setDay (value: number, options): boolean {
        const dt = this.get('dateTime');

        const daysInMonth = moment(dt).endOf('month').date();

        let valueWithinLimits = true;
        if (value < 1) {
            value = daysInMonth;
            valueWithinLimits = false;
        } else if (value > daysInMonth) {
            value = 1;
            valueWithinLimits = false;
        }

        if (options?.setOnlyIfValidValue && !valueWithinLimits) {
            return valueWithinLimits;
        }

        dt.setDate(value);
        void this.update('dateTime');

        return valueWithinLimits;
    }

    public getMonth (): number {
        return this.get('dateTime').getMonth() + 1;
    }

    public setMonth (value, options): boolean {
        let valueWithinLimits = true;

        if (value < 1) {
            value = 12;
            valueWithinLimits = false;
        } else if (value > 12) {
            value = 1;
            valueWithinLimits = false;
        }

        if (options?.setOnlyIfValidValue && !valueWithinLimits) {
            return valueWithinLimits;
        }

        this.get('dateTime').setMonth(value - 1);
        void this.update('dateTime');

        return valueWithinLimits;
    }

    public getYear (): number {
        return this.get('dateTime').getFullYear();
    }

    public setYear (value: number, options?): boolean {
        let minYear: number;
        let maxYear: number;

        const valueFromKeyboardInput = options?.valueFromKeyboardInput;
        if (valueFromKeyboardInput) {
            minYear = 1;
            maxYear = 9999;
        } else {
            minYear = 1900;
            maxYear = 2099;
        }

        let valueWithinLimits = true;

        if (value < minYear) {
            value = maxYear;
            valueWithinLimits = false;
        } else if (value > maxYear) {
            value = minYear;
            valueWithinLimits = false;
        }

        if (options?.setOnlyIfValidValue && !valueWithinLimits) {
            return valueWithinLimits;
        }

        this.get('dateTime').setFullYear(value);
        void this.update('dateTime');

        return valueWithinLimits;
    }

    public getHours (): number {
        return this.get('dateTime').getHours();
    }

    public setHours (value, options): boolean {
        let valueWithinLimits = true;

        if (value < 0) {
            value = 23;
            valueWithinLimits = false;
        } else if (value > 23) {
            value = 0;
            valueWithinLimits = false;
        }

        if (options?.setOnlyIfValidValue && !valueWithinLimits) {
            return valueWithinLimits;
        }

        this.get('dateTime').setHours(value);
        void this.update('dateTime');

        return valueWithinLimits;
    }

    public getMinutes (): number {
        return this.get('dateTime').getMinutes();
    }

    public setMinutes (value: number, options): boolean {
        let valueWithinLimits = true;

        if (value < 0) {
            value = 59;
            valueWithinLimits = false;
        } else if (value > 59) {
            value = 0;
            valueWithinLimits = false;
        }

        if (options?.setOnlyIfValidValue && !valueWithinLimits) {
            return valueWithinLimits;
        }

        this.get('dateTime').setMinutes(value);
        void this.update('dateTime');

        return valueWithinLimits;
    }

    public getSeconds (): number {
        return this.get('dateTime').getSeconds();
    }

    public setSeconds (value: number, options): boolean {
        let valueWithinLimits = true;

        if (value < 0) {
            value = 59;
            valueWithinLimits = false;
        } else if (value > 59) {
            value = 0;
            valueWithinLimits = false;
        }

        if (options?.setOnlyIfValidValue && !valueWithinLimits) {
            return valueWithinLimits;
        }

        this.get('dateTime').setSeconds(value);
        void this.update('dateTime');

        return valueWithinLimits;
    }

    // #endregion
}

Control.extendWith(TerceraDateTimePicker, {

    template: TerceraDateTimePickerTemplate,
    data: function () {
        return {
            height: 20,
            dateTime: null,
            dateFromStyle: false,
            focusedDateTimePartName: null
        };
    },
    computed: {
        // For view.
        day: function () { return moment(this.get('dateTime')).format('DD'); },
        month: function () { return moment(this.get('dateTime')).format('MM'); },
        year: function () { return moment(this.get('dateTime')).format('YYYY'); },
        hours: function () { return moment(this.get('dateTime')).format('HH'); },
        minutes: function () { return moment(this.get('dateTime')).format('mm'); },
        seconds: function () { return moment(this.get('dateTime')).format('ss'); }
    }
});
