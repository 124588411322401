// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PFixFieldSet } from '../../../Fields/FieldsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';
import { type MarketConsensusData } from '../../../../../../Commons/cache/MarketConsensus';

export class AlgorithmTopLongShortInstrumentGroup extends PFixFieldSet {
    constructor (pfixFieldSet: PFixFieldSet) {
        super(FieldsFactory.ALGORITHM_TOP_LONG_SHORT_INSTRUMENT_GROUP, null, pfixFieldSet);
    }

    GetGroupContent = (): MarketConsensusData => {
        const result: MarketConsensusData = {
            TradableId: super.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID),
            RouteId: super.GetValue(FieldsFactory.FIELD_TRADE_ROUTE_ID),
            InstrumentName: super.GetValue(FieldsFactory.FIELD_INSTRUMENT_NAME),
            InstrumentNameTypeId: super.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID),
            Percent: super.GetValue(FieldsFactory.FIELD_PERCENT),
            MarketConsensusPercentType: super.GetValue(FieldsFactory.FIELD_MARKET_CONSENSUS_PERCENT_TYPE),
            IsExpertChoice: super.GetValue(FieldsFactory.FIELD_ALGORITHM_TOP_LONG_TOP_SHORT_EXPERT_CHOICE)
        };

        return result;
    };
}
