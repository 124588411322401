// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export const ar =
{
    'AdditionalProperty.Account': 'الحساب',
    'AdditionalProperty.Reverse': 'عكس',
    'accountDetailsWidget.DropdownButton': '',
    'accountDetailsWidget.DropdownButton.Tooltip': '',
    'additional.verification': 'مطلوب تأكيد إضافي لكلمة المرور',
    Alligator: 'تمساح',
    'Average Directional Index': 'متوسط مؤشر اتجاهي',
    'Average True Range': 'متوسط التغير الفعلي',
    'Bollinger Band': 'بولينجر باند',
    'Bollinger Bands': 'بولينجر باند',
    'bonds.couponCycle.1m': 'شهر',
    'bonds.couponCycle.1y': 'سنة ',
    'bonds.couponCycle.3m': 'ثلاث أشهر',
    'bonds.couponCycle.6m': 'ستة أشهر',
    'BrokerMessage.Cancel': 'إلغاء',
    'BrokerMessage.OK': 'موافق',
    'BrokerMessage.Text': 'رسالة الوسيط',
    'BusinessRejectMessage.28': 'كلمة المرور الحالية غير صحيحة',
    'Chande Momentum Oscillator': 'متذبذب شاندي للزخم',
    'chart.agregationType.Button.ToolTip': 'اختر أنواع التجميع التي تم تحديدها مسبقاً',
    'chart.AllowedPeriods.15M': '15ش',
    'chart.AllowedPeriods.1D': '1ي',
    'chart.AllowedPeriods.1H': '1س',
    'chart.AllowedPeriods.1M': '1د',
    'chart.AllowedPeriods.1S': '1ث',
    'chart.AllowedPeriods.1T': 'وحدة ',
    'chart.AllowedPeriods.1W': '1أ',
    'chart.AllowedPeriods.1Y': '1سن',
    'chart.AllowedPeriods.30M': '30د',
    'chart.AllowedPeriods.4H': '4س',
    'chart.AllowedPeriods.5M': '5د',
    'chart.AllowedPeriods.Month': '1ش',
    'chart.AllowedPeriods.Range': 'المدى',
    'chart.AllowedPeriods.Ticks': 'حركات الأسعار',
    'chart.ChartPanel.accountLookup.ToolTip': 'تحديد الحساب',
    'chart.ChartPanel.symbolLookup.ToolTip': 'اختار رمز',
    'chart.chartScroll.toBegin': 'إلى العرض الأخير',
    'chart.chartScroll.zoomInButton': 'تكبير',
    'chart.chartScroll.zoomOutButton': 'تصغير',
    'chart.ChartSymbolSelector.addIndicator.ButtonToolTip': 'إضافة مؤشر',
    'chart.ChartSymbolSelector.drawingButton.ToolTip': 'إظهار/اخفاء شريط أدوات الرسم',
    'chart.ChartSymbolSelector.mouseTradeButton.ToolTip': 'التداول بالفأرة ',
    'chart.ChartSymbolSelector.oeButton': 'إدخال الأوامر',
    'chart.ChartSymbolSelector.oeButton.ToolTip': 'تجارة الرسم البياني',
    'chart.ChartSymbolSelector.OverlayTooltip': 'إضافة تراكب',
    'chart.ChartSymbolSelector.ObjectManagerTooltip': 'إدارة الموضوع',
    'Chart.chartWindow.auto': 'تلقائي',
    'Chart.chartWindow.collapsed': 'انهار',
    'Chart.chartWindow.manual': 'يدوي',
    'chart.chartWindowManager.SetAuto': 'التحويل إلى مقياس السعر التلقائي',
    'chart.chartWindowManager.SetManual': 'التحويل إلى مقياس السعر اليدوي',
    'chart.head.addOverlay': '...إضافة تراكب',
    'chart.head.charttype': 'النمط',
    'chart.head.line': 'الأدوات',
    'chart.head.overlayRemoveAll': 'حذف الكل',
    'chart.head.overlays': 'التراكبات',
    'chart.indicator': 'المؤشر',
    'chart.indicatorAdd': 'إضافة مؤشر',
    'chart.indicatorRemoveAll': 'حذف الكل',
    'chart.indicators': 'المؤشرات',
    'chart.Infoline.days': 'أيام',
    'chart.Infoline.hours': 'ساعات',
    'chart.Infoline.min': 'دقيقة',
    'chart.Infoline.price': 'السعر',
    'chart.Infoline.sec': 'ثانية',
    'chart.Infoline.time': 'الوقت',
    'chart.infoWindow.Items.Ask': 'العرض:',
    'chart.infoWindow.Items.Bars': 'الشريط #:',
    'chart.infoWindow.Items.Bid': 'الطلب:',
    'chart.infoWindow.Items.Change': 'التغيير ',
    'chart.infoWindow.Items.Close': 'إغلاق:',
    'chart.infoWindow.Items.Date': 'التاريخ:',
    'chart.infoWindow.Items.High': 'أعلى:',
    'chart.infoWindow.Items.Low': 'أقل:',
    'chart.infoWindow.Items.Open': 'فتح:',
    'chart.infoWindow.Items.Ticks': 'حركة الأسعار:',
    'chart.infoWindow.Items.Time': 'الوقت:',
    'chart.infoWindow.Items.Volume': 'الحجم:',
    'chart.instrumentsLookup': 'البحث عن الرموز',
    'chart.layerManager': '...الإعدادات',
    'chart.loading': '...جاري التحميل',
    'chart.loadingData': 'تحميل البيانات لـ',
    'chart.mainlayer.gridPrice': 'شبكة السعر',
    'chart.mainlayer.gridTime': 'شبكة الوقت',
    'chart.mainlayer.volume': 'الحجم؟',
    'chart.menu.cancelByAccount': 'إلغاء الكل حسب الحساب',
    'chart.menu.cancelByInstrument': 'إلغاء الكل حسب الأداة ',
    'chart.menu.cancelOrder': 'إلغاء الأمر',
    'chart.menu.close': 'إغلاق المركز',
    'chart.menu.closeall': 'إغلاق جميع المراكز...',
    'chart.menu.InfoWindowDock': 'تثبيت',
    'chart.menu.InfoWindowHide': 'اخفاء',
    'chart.menu.InfoWindowSettings': 'الخيارات',
    'chart.menu.InfoWindowShortMode': 'الوضع القصير',
    'chart.menu.modify': 'تعديل',
    'chart.menu.PlateIndicatorDublicate': 'مؤشر مكرر',
    'chart.menu.PlateIndicatorRemove': 'حذف المؤشر',
    'chart.menu.PlateIndicatorSettings': 'إعدادات المؤشر',
    'chart.menu.PlateIndicatorVisible': 'اظهار',
    'chart.menu.PlateOverlay.Overlay': 'التراكب',
    'chart.menu.PlateOverlayRemove': 'حذف التراكب',
    'chart.menu.PlateOverlaySettings': 'إعدادات التراكب',
    'chart.menu.PlateOverlayVisible': 'اظهار',
    'chart.menu.rollback': 'وضعية الاستعادة',
    'chart.noAvailableData': 'لا توجد بيانات متوفرة لـ',
    'chart.NoData': 'البيانات غير متوفرة للرمز',
    'chart.priceScaleContextMenu.AutoScale': 'تلقائي',
    'chart.priceScaleContextMenu.FitDay': 'أعلى/أدنى مستوى فيت لليوم',
    'chart.priceScaleContextMenu.FitDrawings': 'رسوم فيت',
    'chart.priceScaleContextMenu.FitIndicators': 'مؤشرات فيت',
    'chart.priceScaleContextMenu.FitOrdersPositions': 'وضعيات فيت',
    'chart.priceScaleContextMenu.ManualScale': 'يدوي',
    'chart.refresh': 'تحديث',
    'chart.Remove': 'حذف',
    'chart.shortPeriodName.m': 'د',
    'chart.shortPeriodName.ticks': 'حركات الأسعار',
    'chart.ShowHighLow.Close': 'إغلاق',
    'chart.ShowHighLow.High': 'أعلى',
    'chart.ShowHighLow.Low': 'أقل',
    'chart.StyleButton.ToolTip': 'النمط',
    'chart.tic.short.15min.name': '15ش',
    'chart.tic.short.1day.name': '1ي',
    'chart.tic.short.1hour.name': '1س',
    'chart.tic.short.1min.name': '1د',
    'chart.tic.short.1tic.name': 'حركة السعر',
    'chart.tic.short.30min.name': '30د',
    'chart.tic.short.4hour.name': '4س',
    'chart.tic.short.5min.name': '5د',
    'chart.tic.short.month.name': '1ش',
    'chart.tic.short.range.name': 'المدى',
    'chart.tic.short.second.name': 'ث',
    'chart.tic.short.week.name': '1أ',
    'chart.tic.short.year.name': '1سن',
    'chart.timeframeselector.rangeButton.ToolTip': 'اختر فترة عرض البيانات',
    'chart.timeframeselector.time': 'الوقت',
    'chart.timeScaleContextMenu.ShowAllSessions': 'إظهار الجلسات الممتدة',
    'chart.timeScaleContextMenu.ShowDaySeparator': 'إظهار فاصل الأيام',
    'chart.timeScaleContextMenu.ShowEmptyBars': 'إظهار الفراغات في السجّل',
    'chart.timeScaleContextMenu.ShowMonthSeparator': 'إظهار فاصل الشهور',
    'chart.timeScaleContextMenu.ShowTimePeriodsSeparator': 'إظهار الفواصل بين الفترات الزمنية',
    'chart.timeScaleContextMenu.ShowWeeksSeparator': 'إظهار فاصل الأسابيع',
    'chart.timeScaleContextMenu.ShowYearSeparator': 'إظهار فاصل السنوات',
    'chart.tool.bar': 'شريط',
    'chart.tool.candle': 'شمعة',
    'chart.tool.clone': 'نسخة',
    'chart.tool.Dash': 'خط فاصل',
    'chart.tool.delete': 'حذف',
    'chart.tool.deleteAll': 'حذف الكل',
    'chart.tool.dot': 'نقاط',
    'chart.tool.dotline': 'خط نقطي',
    'chart.tool.DottedLine': 'خط نقطي',
    'chart.tool.forest': 'فوريست',
    'chart.tool.IsoDot': 'نقطة متماثلة',
    'chart.tool.line': 'خط',
    'chart.tool.properties': 'الخيارات',
    'chart.tool.ShapedLine': 'خط مشكّل',
    'chart.tool.solid': 'المنطقة',
    'chart.tools.ABCPatern': 'نمط الحروف',
    'chart.tools.AndrewPitchfork': 'شوكة أندرو',
    'chart.tools.backcolor': 'لون الخلفية',
    'chart.tools.ButterflyGartley': 'نموذج جارتلي',
    'chart.tools.Circle': 'دائرة',
    'chart.tools.default': 'سهم',
    'chart.tools.Ellipse': '؟؟',
    'chart.tools.eraser': 'الممحاة',
    'chart.tools.FibArc': 'قوس الفيبوناتشى',
    'chart.tools.FibEllipses': 'دوامة الفيبوناتشى',
    'chart.tools.FibExp': 'تمدد الفيبوناتشى',
    'chart.tools.FibFan': 'مروحة الفيبوناتشى',
    'chart.tools.FibPhiChannel': 'قناة الفيبوناتشى',
    'chart.tools.Fibretr': 'مستوى تصحيح الفيبوناتشى',
    'chart.tools.FibSpiral': 'حلزون الفيبوناتشى',
    'chart.tools.FibTga': 'تحليل الفيبوناتشى للوقت الهدف',
    'chart.tools.FibTz': 'الإلتزام بالأوطى',
    'chart.tools.GannFan': 'مروحة جان',
    'chart.tools.GannGrid': 'شبكة جان',
    'chart.tools.GannLine': 'خط جان',
    'chart.tools.horizontal': 'خط أفقي',
    'chart.tools.infoline': 'مسطرة',
    'chart.tools.Line': 'خط',
    'chart.tools.mirrored': 'معكوس',
    'chart.tools.points': 'عدد النقاط',
    'chart.tools.Polygon': 'مضلع',
    'chart.tools.PPZ': 'سعر المنطقة المحورية (س م ح)',
    'chart.tools.PriceChannel': 'قناء السعر',
    'chart.tools.Rectangle': 'مربع',
    'chart.tools.RegressionChannel': 'قناة التراجع',
    'chart.tools.ScalingByRect': 'القياس بالمربع',
    'chart.tools.selection': 'الاختيار',
    'chart.tools.snapToCandle': 'القطع إلى الشمعة',
    'chart.tools.StayDrawingMode': 'البقاء في وضع الرسم',
    'chart.tools.symbol': 'الرمز',
    'chart.tools.text': 'تعليقات',
    'chart.tools.TopBottomPattern': 'وضع القمة والقاع',
    'chart.tools.Triangle': 'مثلث',
    'chart.tools.vertical': 'خط عمودي',
    'chart.tools.ABCPatern.deleteByType': 'جميع أنماط الحروف',
    'chart.tools.AndrewPitchfork.deleteByType': 'جميع شوكات أندرو',
    'chart.tools.ButterflyGartley.deleteByType': 'جميع نماذج جارتلي',
    'chart.tools.Circle.deleteByType': 'جميع دوائر',
    'chart.tools.Ellipse.deleteByType': '؟؟',
    'chart.tools.FibArc.deleteByType': 'جميع اقواس الفيبوناتشى',
    'chart.tools.FibEllipses.deleteByType': 'جميع دوامات الفيبوناتشى',
    'chart.tools.FibExp.deleteByType': 'جميع تمديدات الفيبوناتشى',
    'chart.tools.FibFan.deleteByType': 'جميع مراوح الفيبوناتشى',
    'chart.tools.FibPhiChannel.deleteByType': 'جميع قنوات الفيبوناتشى',
    'chart.tools.Fibretr.deleteByType': 'جميع مستويات تصحيح الفيبوناتشى',
    'chart.tools.FibSpiral.deleteByType': 'جميع حلزونات الفيبوناتشى',
    'chart.tools.FibTga.deleteByType': 'جميع تحليلات الفيبوناتشى للوقت الهدف',
    'chart.tools.FibTz.deleteByType': 'جميع مناطق فيبوناتشي الزمنية',
    'chart.tools.GannFan.deleteByType': 'جميع مراوح جان',
    'chart.tools.GannGrid.deleteByType': 'جميع شبكات جان',
    'chart.tools.GannLine.deleteByType': 'جميع خطوط جان',
    'chart.tools.horizontal.deleteByType': 'جميع الخطوط أفقية',
    'chart.tools.infoline.deleteByType': 'جميع المساطر',
    'chart.tools.Line.deleteByType': 'جميع الخطوط',
    'chart.tools.Polygon.deleteByType': 'جميع المضلعات',
    'chart.tools.PPZ.deleteByType': 'جميع س م ح',
    'chart.tools.PriceChannel.deleteByType': 'جميع قنوات السعر',
    'chart.tools.Rectangle.deleteByType': 'جميع مربعات',
    'chart.tools.RegressionChanel.deleteByType': 'جميع قنوات التراجع',
    'chart.tools.symbol.deleteByType': 'جميع الرموز',
    'chart.tools.text.deleteByType': 'جميع التعليقات',
    'chart.tools.TopBottomPattern.deleteByType': 'جميع أنماط القمة والقاع',
    'chart.tools.Triangle.deleteByType': 'جميع مثلثات',
    'chart.tools.vertical.deleteByType': 'جميع الخطوط العامودية',
    'chart.tools.VolumeProfile.deleteByType': 'All custom volume profiles',
    'chart.view': 'عرض',
    'chart.view.analyseGroupMenu': 'أدوات التحليل',
    'chart.view.analysetoolbar': 'شريط أدوات التحليل',
    'chart.view.barsToRight': 'الأشرطة لليمين',
    'chart.view.colors': 'الخيارات',
    'chart.view.dataInfo': 'نافذة المعلومات',
    'chart.view.Day_HighLow': 'اليوم أعلى/أسفل',
    'chart.view.drawingtoolbar': 'شريط أدوات الرسم',
    'chart.view.font': 'خط المحاور',
    'chart.view.landscapequestion': 'استخدام ترتيبات الورق الأفقي؟',
    'chart.view.merge': 'خطوط متصلة',
    'chart.view.orderEntry': 'تجارة الرسم البياني',
    'chart.view.periods': '...الفترات',
    'chart.view.previousCloseLine': 'خط الإغلاق السابق',
    'chart.view.PrevSettlementPriceLine': 'سعر التسوية السابق',
    'chart.view.scroller': 'شريط التمرير',
    'chart.view.setOrder': 'وضع أمر',
    'chart.view.SettlementPriceLine': 'سعر التسوية ',
    'chart.view.show_cross': 'مستعرض البيانات',
    'chart.view.show_history': 'عرض تاريخ التداول',
    'chart.view.show_set_zero': 'Show zero/set to prev. close zero price',
    'chart.view.showEvents': 'عرض التداولات',
    'chart.view.showNews': 'عرض الأخبار',
    'chart.view.showOrders': 'إظهار الأوامر',
    'chart.view.showPositions': 'إظهار المراكز',
    'chart.view.source': 'نوع البيانات',
    'chart.view.source0': 'افتراضي بالرمز',
    'chart.view.source1': 'الطلب',
    'chart.view.source1000': 'العرض',
    'chart.view.source1001': 'متوسط الطلب/العرض',
    'chart.view.source1002': 'أقل (طلب) أكثر (عرض)',
    'chart.view.source4': 'الأخير',
    'chart.view.sourceBID/ASK': 'بحسب الطلب/العرض',
    'chart.view.sourceTick1': 'بحسب الطلب/العرض',
    'chart.view.TimeToNextBar': 'الوقت بجانب الشريط',
    'chart.view.toolbar': 'شريط الأدوات',
    'chart.view.volume': 'إظهار الحجم',
    'chart.visualTrading.Invalid price': 'سعر غير صحيح',
    'chart.volumeColoringMode.ByBar': 'حسب الشريط',
    'chart.volumeColoringMode.ByDifference': 'بحسب الاختلاف',
    'chart.volumeColoringMode.Fixed': 'ثابت',
    'chart.volumeColoringMode.ByMovingAverage': 'فوق/أسفل المتوسط المتحرك',
    'chart.quickTrading.Buy limit': 'شراء محدد',
    'chart.quickTrading.Buy stop': 'شراء إيقاف',
    'chart.quickTrading.Invalid price': 'السعر غير صحيح',
    'chart.quickTrading.Sell limit': 'بيع حد',
    'chart.quickTrading.Sell stop': 'بيع إيقاف',
    'Commodity Channel Index': 'مؤشر قناة السلع',
    'connect.connection.connect.error': 'فشل الإتصال بالسرفر!',
    'connect.connection.unknown.error': 'خطأ غير معروف!',
    Custom: 'تخصيص',
    Day: 'يوم',
    'dealticket.RiskRuleWarning.Header': 'تحذير قاعدة المخاطر',
    Deutsch: '#hidden#',
    'enum.AccountType.ClosedFund': 'رأسمال محدود',
    'enum.AccountType.MultiAsset': 'أصول متعددة',
    'enum.AccountType.OpenedFund': 'رأسمال متغير',
    'enum.AccountType.SingleCCY': 'عملة واحدة',
    'enum.AssetType.COMMODITIES': 'السلع',
    'enum.AssetType.CRYPTO_CCY': 'العملات الرقمية ',
    'enum.AssetType.CURRENCY': 'العملة',
    'enum.AssetType.SHARES': 'الأسهم',
    'ExchangeId.All': 'جميع الأسواق',
    'Exponential Moving Average': 'متوسط الحركة الأسي',
    FOK: '(FOK) تنفيذ كامل فوري أو إلغاء',
    'FullLogs.Cluster': 'السيرفر',
    'FullLogs.DateTime': 'الوقت',
    'FullLogs.Fields': 'الحقول',
    'FullLogs.Message name': 'اسم الرسالة',
    'FullLogs.Session': 'الجلسة',
    'general.Calculating': '',
    'general.connection.restErrorMsg': '',
    'general.connection.Verification password not valid.': 'التأكد من كلمة المرور غير صحيح',
    'general.CUSTOM': 'تخصيص',
    'general.Friday': 'الجمعة',
    'general.messageBox.cancel': 'إلغاء',
    'general.messageBox.no': 'لا',
    'general.messageBox.ok': 'موافق',
    'general.messageBox.showNextTime': 'عرض المرة القادمة',
    'general.messageBox.yes': 'نعم',
    'general.Monday': 'الإثنين',
    'general.day1.Mo': '',
    'general.day2.Tu': '',
    'general.day3.We': '',
    'general.day4.Th': '',
    'general.day5.Fr': '',
    'general.day6.Sa': '',
    'general.day7.Su': '',
    'general.month.1': 'يناير',
    'general.month.10': 'أكتوبر',
    'general.month.11': 'نوفمبر',
    'general.month.12': 'ديسمبر',
    'general.month.2': 'فبراير',
    'general.month.3': 'مارس',
    'general.month.4': 'أبريل',
    'general.month.5': 'مايو',
    'general.month.6': 'يونيو',
    'general.month.7': 'يوليو',
    'general.month.8': 'أغسطس',
    'general.month.9': 'سبتمبر',
    'general.monthFullName.1': 'يناير',
    'general.monthFullName.10': 'أكتوبر',
    'general.monthFullName.11': 'نوفمبر',
    'general.monthFullName.12': 'ديسمبر',
    'general.monthFullName.2': 'فبراير',
    'general.monthFullName.3': 'مارس',
    'general.monthFullName.4': 'أبريل',
    'general.monthFullName.5': 'مايو',
    'general.monthFullName.6': 'يونيو',
    'general.monthFullName.7': 'يوليو',
    'general.monthFullName.8': 'أغطس',
    'general.monthFullName.9': 'سبتمبر',
    'general.N_A': 'N/A',
    'general.NoData...': 'البيانات غير متوفرة للرمز',
    'general.OvernightMarginNotificationMessage': 'عرض رسالة إشعار بالهامش البائت',
    'general.Saturday': 'السبت',
    'general.Settings': 'الخيارات',
    'general.Sunday': 'الأحد',
    'general.Thursday': 'الخميس',
    'general.trading.at': 'في',
    'general.trading.Buy': 'شراء',
    'general.trading.confirmation': 'التأكيد',
    'general.trading.confirmation.prefix': '',
    'general.trading.confirmationString': '{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {11} {12} {13} {14} {15}',
    'general.trading.for': 'إلى',
    'general.trading.limitBuyLessAsk': 'يجب أن يكون السعر المحدد لأمر الشراء أقل من سعر العرض',
    'general.trading.limitSellMoreBid': 'يجب أن يكون السعر المحدد الأمر البيع أعلى من سعر طلب السوق',
    'general.trading.marketCloseFor': 'السوق يغلق لـ',
    'general.trading.pips': 'حركات الأسعار',
    'general.trading.points': 'نقاط',
    'general.trading.position': 'المراكز',
    'general.trading.position.Buy': 'طويل',
    'general.trading.position.Sell': 'قصير',
    'general.trading.priceNotAvailable': 'غير متاح',
    'general.trading.refused': 'تم رفضه',
    'general.trading.Sell': 'بيع',
    'general.trading.stopBuyMoreAsk': 'يجب أن يكون سعر إيقاف شراء أكثر من سعر عرض السوق',
    'general.trading.stopSellLessBid': 'يجب أن يكون سعر إيقاف البيع أقل من سعر طلب السوق',
    'general.trading.with offset': 'مع التعويض',
    'general.Tuesday': 'الثلاثاء',
    'property.general.WarnIfWrongOrderParam': '',
    'general.Wednesday': 'الأربعاء',
    GTC: 'صالح حتى الإلغاء',
    'helpPage.link': '',
    'homePage.link': '',
    Ichimoku: 'الايشيموكو',
    'Instrument.Futures.contract': 'عقد',
    'Instrument.Level1.ExtendedFields.NormalMarketSize': '',
    'Instrument.Spreadbet.bet': 'الرهانات ',
    'InstrumentDetailsPanel.(Indicative symbol)': '(مؤشر دلالي)',
    'InstrumentDetailsPanel.1.GeneralInfo': 'معلومات عامة',
    'InstrumentDetailsPanel.2.TradingInfo': 'معلومات التداول',
    'InstrumentDetailsPanel.3.MarginReg': 'متطلبات الهامش',
    'InstrumentDetailsPanel.4.Fees': 'الرسوم',
    'InstrumentDetailsPanel.5.Rebates': 'الخصومات',
    'InstrumentDetailsPanel.6.SessionInfo': 'معلومات الجلسة',
    'InstrumentDetailsPanel.7.Plans': 'الخطط',
    'InstrumentDetailsPanel.AccruedInterest': 'فائدة مستحقة ',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot': 'إضافة أمر خصم سيولة لكل حصة ',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot': 'زيادة خصم السيولة على كل حصة',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume': 'زيادة خصم السيولة على كل كمية',
    'InstrumentDetailsPanel.AFTER_MARKET': 'بعد السوق',
    'InstrumentDetailsPanel.Allow short positions': 'نعم',
    'InstrumentDetailsPanel.AllowedOperations': 'العمليات المسموح بها',
    'InstrumentDetailsPanel.AllowedOrderTypes': 'أنواع الأوامر المسموح بها',
    'InstrumentDetailsPanel.AllowShortPositions': 'السماء بالمراكز القصيرة',
    'InstrumentDetailsPanel.Amount': 'الكمية',
    'InstrumentDetailsPanel.AssetClass': 'نوع الرمز',
    'InstrumentDetailsPanel.BEFORE_MARKET': 'قبل السوق',
    'InstrumentDetailsPanel.Cash': 'نقدي',
    'InstrumentDetailsPanel.Close': 'إغلاق',
    'InstrumentDetailsPanel.Closed': 'مغلق',
    'InstrumentDetailsPanel.CloseOutDeadline': 'أخر موعد للإغلاق',
    'InstrumentDetailsPanel.Commisions': 'العمولات',
    'InstrumentDetailsPanel.ContactMonth': 'شهر التعاقد',
    'InstrumentDetailsPanel.ContractMultiplier': '#hidden#',
    'InstrumentDetailsPanel.ContractSize': 'حجم الصفقة ',
    'InstrumentDetailsPanel.CouponCycle': 'Coupon cycle',
    'InstrumentDetailsPanel.CouponRate': 'Coupon rate',
    'InstrumentDetailsPanel.CurrentSession': 'الجلسة الحالية',
    'InstrumentDetailsPanel.DeliveryMethod': 'طريقة التوصيل',
    'InstrumentDetailsPanel.DeliveryStatus': 'حالة التوصيل',
    'InstrumentDetailsPanel.Depend from account': 'بحسب الحساب',
    'InstrumentDetailsPanel.Description': 'الوصف',
    'InstrumentDetailsPanel.Exchange': 'السوق',
    'InstrumentDetailsPanel.ExchangeTradeSession': 'جلسة التداول',
    'InstrumentDetailsPanel.Exp1': 'الأصل',
    'InstrumentDetailsPanel.FaceValue': 'Face value',
    'InstrumentDetailsPanel.FirstTradeDate': 'تاريخ أول تداول',
    'InstrumentDetailsPanel.Futures': 'المستقبلية',
    'InstrumentDetailsPanel.FuturesClass': 'فئة الحصص المستقبلية',
    'InstrumentDetailsPanel.HiLimit': 'الحد الأعلى',
    'InstrumentDetailsPanel.Holiday': 'إجازة ',
    'InstrumentDetailsPanel.HolidayDate': 'التاريخ',
    'InstrumentDetailsPanel.HolidayName': 'اسم العطلة',
    'InstrumentDetailsPanel.Holidays list': 'قائمة العطلات',
    'InstrumentDetailsPanel.HolidayType': 'الجدول',
    'InstrumentDetailsPanel.immediate': 'فوري',
    'InstrumentDetailsPanel.KEYNextHoliday': 'الإجازة القادمة',
    'InstrumentDetailsPanel.LastTradeDate': 'تاريخ آخر تداول',
    'InstrumentDetailsPanel.LotSize': 'حجم الحصة',
    'InstrumentDetailsPanel.LotStep': 'الخطوة بين الحصص',
    'InstrumentDetailsPanel.LowLimit': 'الحد الأدنى',
    'InstrumentDetailsPanel.MAIN': 'رئيسي',
    'InstrumentDetailsPanel.Margin': 'متطلبات الهامش',
    'InstrumentDetailsPanel.Margin_ByAccount': 'الهامش بحسب الحساب',
    'InstrumentDetailsPanel.MarginBuy': 'شراء',
    'InstrumentDetailsPanel.MarginDay': 'يوم',
    'InstrumentDetailsPanel.MarginDayBuy': 'شراء اليوم',
    'InstrumentDetailsPanel.MarginDaySell': 'بيع اليم',
    'InstrumentDetailsPanel.MarginInAccountCurrency': '#hidden#',
    'InstrumentDetailsPanel.MarginOvernight': 'المبيت',
    'InstrumentDetailsPanel.MarginOvernightBuy': 'شراء المبيت',
    'InstrumentDetailsPanel.MarginOvernightSell': 'بيع المبيت',
    'InstrumentDetailsPanel.MarginSell': 'بيع',
    'InstrumentDetailsPanel.MaturityDate': 'تاريخ النضج ',
    'InstrumentDetailsPanel.MaximumLot': 'الحد الأقصى للحصة',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol': 'الكمية القصوى للمركز',
    'InstrumentDetailsPanel.MinimalLot': 'الحد الأدنى للحصة',
    'InstrumentDetailsPanel.NextPaymentDate': 'دفع العائد التالي',
    'InstrumentDetailsPanel.No': 'لا',
    'InstrumentDetailsPanel.non trading day': 'إغلاق',
    'InstrumentDetailsPanel.None': 'لا يوجد',
    'InstrumentDetailsPanel.NormalMarketSize': '',
    'InstrumentDetailsPanel.Not allow short positions': 'لا',
    'InstrumentDetailsPanel.NoticeDate': 'تاريخ الإشعار',
    'InstrumentDetailsPanel.onlyCloseOrders': 'إغلاق الأوامر فقط',
    'InstrumentDetailsPanel.Open': 'فتح',
    'InstrumentDetailsPanel.OrderPerLot': 'الأوامر بالحصة ',
    'InstrumentDetailsPanel.PerFill': 'لكل تنفيذ',
    'InstrumentDetailsPanel.PerLot': 'لكل حصة',
    'InstrumentDetailsPanel.PerOrder': 'لكل أمر',
    'InstrumentDetailsPanel.PerOrderVolume': 'حجم الأمر، %',
    'InstrumentDetailsPanel.PerPhoneTransaction': 'لكل معاملة هاتفية',
    'InstrumentDetailsPanel.PerTransaction': 'لكل معاملة',
    'InstrumentDetailsPanel.PerVolume': 'كمية المنفذة، %',
    'InstrumentDetailsPanel.Physically': 'مادي',
    'InstrumentDetailsPanel.POSTCLOSE': 'ما بعد السوق',
    'InstrumentDetailsPanel.PREOPEN': 'ما قبل السوق',
    'InstrumentDetailsPanel.PreviousPaymentDate': 'دفع العائد السابق',
    'InstrumentDetailsPanel.Price': 'السعر',
    'InstrumentDetailsPanel.ProductType': 'نوع المنتج',
    'InstrumentDetailsPanel.QuotiongCurrency': 'عرض العملة',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot': 'Remove liquidity rebate order per lot',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot': 'حذف خصم السيولة لكل حصة',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume': 'حذف خصم السيولة لكل كمية',
    'InstrumentDetailsPanel.SessionStatus': 'حالة الجلسة',
    'InstrumentDetailsPanel.SettlementDate': 'تاريخ التسوية',
    'InstrumentDetailsPanel.shortened': 'يوم قصير',
    'InstrumentDetailsPanel.StrikePrice': 'سعر التنفيذ',
    'InstrumentDetailsPanel.SwapBuy': 'مبادلة طويلة',
    'InstrumentDetailsPanel.SwapSell': 'مبادلة قصيرة',
    'InstrumentDetailsPanel.Symbol': 'الرمز',
    'InstrumentDetailsPanel.T+0': '(اليوم) T + 0',
    'InstrumentDetailsPanel.TickCoast': 'تكلفة حركة السعر',
    'InstrumentDetailsPanel.TickSize': 'حجم حركة السعر',
    'InstrumentDetailsPanel.TradindBlockedOnSession': 'ممنوع التداول في الجلسة ',
    'InstrumentDetailsPanel.TradingBalance': 'نظام التسوية',
    'InstrumentDetailsPanel.TradingHalt': 'إيقاف التداول',
    'InstrumentDetailsPanel.TradingStatus': 'حالة الرمز',
    'InstrumentDetailsPanel.Underlier': 'الورقة المالية',
    'InstrumentDetailsPanel.VAT': 'الضريبة',
    'InstrumentDetailsPanel.Volume': 'حجم المنفذ',
    'InstrumentDetailsPanel.VolumeWithMinPD': 'حجم المنفذ الحد مع الأدنى في اليوم، %',
    'InstrumentDetailsPanel.working': 'فتح',
    'InstrumentDetailsPanel.Yes': 'نعم',
    'InstrumentDetailsPanel.Yield': 'العائد',
    'InstrumentDetailsPanel.YTM': 'العائد حتى الاستحقاق',
    'InstrumentDetailsPanel.YTM.RefreshLink': 'تحديث',
    IOC: 'فوري أو إلغاء ',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders': 'التنفيذ حيث غير مسموح بالأوامر المرتبطة في السوق',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp': 'التنفيذ في السوق لا يسمح بأوأمر أ.ر/ إ.خ',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType': 'غير مسموح بتغيير نوع الأمر أو نوع أمر السوق',
    'IsAllowedResponceReason.InstrumentForAccountDelivery': 'الأداة لتوصيل الحساب',
    'IsAllowedResponceReason.InstrumentTradingMode': 'غير مسموح بأداة التداول',
    'IsAllowedResponceReason.LockTrading': 'التداول مغلق في المنصة',
    'IsAllowedResponceReason.LockTradingByPassword': 'الرجاء إدخال كلمة السر في الأسفل ',
    'IsAllowedResponceReason.NoLastQuote': 'العرض الأخير غير موجود',
    'IsAllowedResponceReason.NotAllowedByOrderStaus': 'العمليات غير متاحة بحسب حالة الأمر',
    'IsAllowedResponceReason.NotAllowedRouteOrderType': 'لا يمكن إنشاء الأمر، زمن تنفيذ الأمر ونوع الأمر غير مدعومين.',
    'IsAllowedResponceReason.NotClosablePosition': 'وضعية لا يمكن إغلاقه',
    'IsAllowedResponceReason.NotLoadCache': 'لم يتم تحميل الذاكرة المؤقتة',
    'IsAllowedResponceReason.NotVisibleInstrumentRoute': 'لا توجد أداة أو مسار مرئي',
    'IsAllowedResponceReason.OperationNotAllowedByOrderType': 'العملية غير مسموح بها بحسب نوع الأمر',
    'IsAllowedResponceReason.OperationNotAllowedBySession': 'العملية غير مسموح بها بحسب الجلسة',
    'IsAllowedResponceReason.OrderTypeNotAllowedBySession': 'نوع الأمر غير مسموح به بحسب الجلسة',
    'IsAllowedResponceReason.RouteNotRunning': 'المسار لا يعمل',
    'IsAllowedResponceReason.RuleAdministration': 'إدارة القاعدة',
    'IsAllowedResponceReason.RuleClosePositionDisable': 'إغلاق المركز غير مسموح به بحسب القاعدة',
    'IsAllowedResponceReason.RuleFunctionSelfTrading': 'وظيفة القاعدة التداول بالنفس',
    'IsAllowedResponceReason.RuleFunctionTrading': 'التداول غير مسموح به بحسب القاعدة',
    'IsAllowedResponceReason.RuleOCO': 'قاعدة OCO',
    'IsAllowedResponceReason.RuleSlTpNotAllowed': 'إ.خ/ أ.ر غير مسموح به بحسب القاعدة',
    'IsAllowedResponceReason.RuleTrStop': 'قاعدة إيقاف التداول',
    'IsAllowedResponceReason.Unknown': 'سبب غير معروف',
    'Keltner Channel': 'قناة كيلتنر',
    Limit: 'المحدد',
    'Linear Weighted Moving Average': 'فترة متوسط الحركة الخطي المتوازن',
    LOC: 'سعر محدد على الإقفال ',
    LOO: 'سعر محدد على الإفتتاح',
    Manual: 'يدوي',
    Market: 'السوق',
    'MarketDepthPanel.ColoringMethod.ByPriceLVL': 'بحسب مستوى السعر',
    'MarketDepthPanel.ColoringMethod.RelativeToVolume': 'نسبي إلى الحجم',
    'MarketDepthPanel.ColoringMethod.SizeHistogram': '',
    'McGinley Dynamic': 'الديناميكي McGinley',
    'MDLotsMode.General': 'الافتراضيات العامة [{1}]',
    'MDLotsMode.Lots': 'الحصص',
    'MDLotsMode.RealSize': 'الحجم الحقيقي',
    'MessageBox.ThemeChanged.Title': 'تحذير',
    MOC: 'أمر سوق على الإقفال ',
    'Modified Moving Average': 'تعديل المتوسط المتحرك',
    Momentum: 'قوة الاندفاع',
    MOO: 'أمر سوق على الإفتتاح ',
    'Moving Average Envelope': 'ظرف المتوسط المتحرك',
    'Moving Average/Convergence Divergence': 'تقارب وتباعد/المتوسط المتحرك',
    'NetTrader.SaveWorkspace.Save': 'حفظ مكان العمل',
    'NumericComboCtrl.Edit': 'تعديل',
    OCO: 'أمر يلغي الآخر',
    'On Balance Volume': 'على حجم الرصيد',
    OnClose: 'على الإقفال',
    OnOpen: 'على الإفتتاح',
    'Order.ExecutionType.ACCEPTED': 'تم القبول',
    'Order.ExecutionType.ACTIVATED': 'Activated',
    'Order.ExecutionType.CANCELED': 'ملغى',
    'Order.ExecutionType.FILLED': 'منفذ',
    'Order.ExecutionType.NEW': 'تم الإنشاء',
    'Order.ExecutionType.PART_FILLED': 'منفذ جزئي',
    'Order.ExecutionType.REFUSED': 'مرفوض',
    'Order.ExecutionType.REPLACED': 'معدل',
    'Order.ExecutionType.RESTATED': 'Restated',
    'Order.ExecutionType.UNKNOWN': 'Unknown',
    'OrderEntry.InfoBlock.After trade funds': 'بعد تداول الأموال',
    'OrderEntry.InfoBlock.Availible funds': 'الأموال المتاحة',
    'OrderEntry.InfoBlock.Balance': 'الرصيد',
    'OrderEntry.InfoBlock.Fee': 'الرسوم',
    'OrderEntry.InfoBlock.Gross': 'الإجمالي',
    'OrderEntry.InfoBlock.Impact on portfolio': 'التأثير على المحفظة',
    'OrderEntry.InfoBlock.Init. margin': 'الهامش المبدئي',
    'OrderEntry.InfoBlock.Maint. margin': 'هامش الوقاية',
    'OrderEntry.InfoBlock.Margin availible': 'الهامش المتاح',
    'OrderEntry.InfoBlock.Net': 'الصافي',
    'OrderEntry.InfoBlock.Spread in loss': 'الخسارة المبدئية للهامش',
    'OverlayScreen.barsBodyLabel': 'الجسم',
    'OverlayScreen.barsBorderLabel': 'الحد',
    'OverlayScreen.barsHiLowLabel': 'سطر',
    'OverlayScreen.cancelButton': 'إلغاء',
    'OverlayScreen.dataLabel': 'نوع البيانات',
    'OverlayScreen.dojiLabel': 'Doji',
    'OverlayScreen.histogramLineLabel': 'Histogram Line',
    'OverlayScreen.instrumentLabel': 'الرمز',
    'OverlayScreen.Main scale': 'المقياس الاساسي',
    'OverlayScreen.name': 'التراكب',
    'OverlayScreen.numberOverlaysError': 'لا يمكنك إضافة أكثر من 5 تراكبات.',
    'OverlayScreen.okButton': 'موافق',
    'OverlayScreen.Overlays': 'التراكبات',
    'OverlayScreen.priceLabel': 'مقياس السعر',
    'OverlayScreen.priceMarkerCheckbox': 'عرض علامة السعر',
    'OverlayScreen.Separate scale': 'مقياس منفصل',
    'OverlayScreen.solidPriceLabel': 'المنطقة',
    'OverlayScreen.styleLabel': 'النمط',
    'OverlayScreen.wickLabel': 'فتيلة',
    'panel.accountDetails.Groups.1.General': 'عام',
    'panel.accountDetails.Groups.2.Margin': 'الهامش',
    'panel.accountDetails.Groups.3.AccountActivity': 'نشاط الحساب',
    'panel.accountDetails.Groups.4.TodayResults': 'نتائج اليوم',
    'panel.accountDetails.Groups.5.RiskManagement': 'إدارة المخاطر',
    'panel.accountDetails.Groups.55.Plans': 'الخطط',
    'panel.accountDetails.Groups.6.Info': 'معلومات',
    'panel.AccountDetailsPanel': 'تفاصيل الحساب',
    'panel.AccountDetailsPanel.InfoTooltip': '',
    'panel.AccountLink.SymbolLink.ToolTip': 'رابط الرمز',
    'panel.AccountLink.ToolTip': 'رابط الحساب',
    'panel.accounts': 'الحسابات',
    'panel.accounts.AccountDescription': '#hidden#',
    'panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached': 'تم الوصول إلى الحد للخسارة اليومية ',
    'panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached': 'تم الوصول إلى الحجم اليومي الأقصى',
    'panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)': 'حد تدفق الأوامر (في اليوم)',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled': 'معطل',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled': 'مسموح',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Active': 'نشط',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed': 'مغلق تلقائياً',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed': 'مغلق',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules': 'وقف التداول بسبب قواعد المخاطر',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning': '',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached': 'قد وصلا حد الخسارة اليوية المتحركة',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend': 'تعليق',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached': 'تم الوصول إلى حد الخسارة غير المحققة ',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached': 'وصلت حد الخسارة الإسبوعية ',
    'panel.accounts.AccountMode': 'وضع الحساب',
    'panel.accounts.AccountType': 'نوع الحساب',
    'panel.accounts.AccountValue': 'قيمة الحساب',
    'panel.accounts.AllowOvernightTrading': 'السماح بالتداول حتى صباح اليوم الثاني',
    'panel.accounts.AllowTradingPrePost': 'التداول ما بعد/قبل الأسواق',
    'panel.accounts.Asset': 'أصل',
    'panel.accounts.AssetDescription': 'وصف الأصل',
    'panel.accounts.AssetType': 'نوع الأصل',
    'panel.accounts.AvailableMargin': 'الأموال المتاحة',
    'panel.accounts.Balance': 'الرصيد',
    'panel.accounts.BalancePlusAllRisks': 'الرصيد + كل المخاطر',
    'panel.accounts.BeginBalance': 'صافي ر/خ اليوم',
    'panel.accounts.BlockedByOrders': 'الرصيد محاصر بالأوامر ',
    'panel.accounts.BlockedByPamm': 'رأس مال الصندوق المستثمر',
    'panel.accounts.BlockedForStocks': '#hidden#',
    'panel.accounts.BlockedForStocksNew': 'مغلق للأسهم',
    'panel.accounts.CashBalance': 'رصيد المال',
    'panel.accounts.ClientType': 'نوع المحطة',
    'panel.accounts.Commissions': 'العمولات',
    'panel.accounts.CreditValue': 'قيمة الائتمان',
    'panel.accounts.CurBalance': 'الرصيد المتوقع',
    'panel.accounts.CurMargin': 'الهامش المستخدم، %',
    'panel.accounts.CurrentDailyLoss': 'خسارة اليومية ',
    'panel.accounts.CurrentFundCapital': 'رأس مال الصندوق الحالي',
    'panel.accounts.CurrentStatus': 'حالة التداول',
    'panel.accounts.CurrentWeeklyLoss': 'خسارة الأسبوعية ',
    'panel.accounts.Email': 'البريد الإلكتروني',
    'panel.accounts.Estimate value': 'القيمة المتوقعة',
    'panel.accounts.FundCapitalGain': 'ربح رأس مال الصندوق',
    'panel.accounts.FundsUsed': 'الصناديق المستخدمة',
    'panel.accounts.FundsUsedPercent': 'الصناديق المستخدمة، %',
    'panel.accounts.GrossPNL': 'إجمالي ر/خ اليوم',
    'panel.accounts.InsertOnBalance': 'أدخل رصيد',
    'panel.accounts.InterestRate': 'معدّل الفائدة، %',
    'panel.accounts.IntradayRiskManagement': 'حالة إدارة المخاطر',
    'panel.accounts.Leverage': 'قوة الرفع',
    'panel.accounts.LockedBalance': 'الرصيد المغلق',
    'panel.accounts.LockedBy': 'مغلق',
    'panel.accounts.Login': 'الحساب',
    'panel.accounts.MaintMargin': 'مستوى المخاطرة، %',
    'panel.accounts.MarginAndRisks': 'الهامش والمخاطر',
    'panel.accounts.MarginAvailableReal': 'الهامش المتاح',
    'panel.accounts.MarginDeficiency': 'عجز الهامش',
    'panel.accounts.MarginForOthers': '#hidden#',
    'panel.accounts.MarginRequirement': 'متطلبات المحافظة على الوضعية',
    'panel.accounts.MarginStopOut': 'إجمالي متطلبات الهامش',
    'panel.accounts.MarginStopTrade': 'الهامش للتداول ',
    'panel.accounts.MarginSurplus': 'فائض الهامش',
    'panel.accounts.MarginUsedNew': 'الهامش المستخدم',
    'panel.accounts.MarginWarning': 'تحذير الهامش',
    'panel.accounts.marginWarnPercent': 'مستوى تحذير الهامش',
    'panel.accounts.MarketDataCharging': 'تغيّر بيانات السوق',
    'panel.accounts.MaxDailyLossLimit': 'حد الخسارة اليومية ',
    'panel.accounts.MaxDailyProfit': 'هدف الربح اليومي ',
    'panel.accounts.MaxDayVolume': '',
    'panel.accounts.MaximumLot': 'الحد الأقصى للحصة',
    'panel.accounts.MaxOrderAmount': 'الحد الأقصى لكمية الأمر ',
    'panel.accounts.MaxOrderCapital': 'رأس مال الأقصى للأمر الواحد',
    'panel.accounts.MaxOrdersPerDay': '# حدود انسياب الأمر (في اليوم)',
    'panel.accounts.MaxPendingOrders': '# العدد الأقصى للأوأمر المعلقة',
    'panel.accounts.MaxPendingOrdersValue': 'الحد الأعلى لقيمة الصفقات القائمة ',
    'panel.accounts.MaxPositionQtyPerSymbol': 'الكمية القصوى لكل لرمز',
    'panel.accounts.MaxPositions': '# المراكز القصوى',
    'panel.accounts.MaxWeekLostLimit': 'حد الخسارة الإسبوعية',
    'panel.accounts.MinMargin': 'الحد الأدنى للهامش',
    'panel.accounts.Murkups': 'هوامش الربح',
    'panel.accounts.N/A_TelephoneNumber': 'N/A',
    'panel.accounts.OptionPremiumReq': '',
    'panel.accounts.OptionValue': 'قيمة الخيارات ',
    'panel.accounts.Orders': '# الأوامر',
    'panel.accounts.Owner': 'المالك',
    'panel.accounts.Positions': '# المراكز',
    'panel.accounts.PowerOfAttorney': 'تفويض',
    'panel.accounts.Profit': 'الربح',
    'panel.accounts.ProfitNet': ' صافي ر/خ',
    'panel.accounts.Role': 'نوع الصندوق',
    'panel.accounts.Spreads': 'فروق الأسعار (إنتشارات)',
    'panel.accounts.Status': 'الحالة',
    'panel.accounts.Collateral': '',
    'panel.accounts.StocksLiquidity': 'سيولة الأسهم ',
    'panel.accounts.StocksOrdersReq': 'متطلبات أوأمر الأسهم',
    'panel.accounts.StocksValue': 'قيمة السهم',
    'panel.accounts.stopOutPercent': 'مستوى الوقاية للحساب',
    'panel.accounts.stopTradingPercent': 'مستوى إيقاف التداول',
    'panel.accounts.Swap': 'المبادلة',
    'panel.accounts.TelephoneNumber': 'رقم الهاتف',
    'panel.accounts.TodayFees': 'رسوم اليوم',
    'panel.accounts.TodayRebates': 'خصومات اليوم',
    'panel.accounts.TodaySwaps': '#hidden#',
    'panel.accounts.TodayTrades': '# تداولات اليوم',
    'panel.accounts.TodayTurnover': 'عائد اليوم',
    'panel.accounts.TodayVolume': 'حجم اليوم',
    'panel.accounts.TotalMaxPositionsQty': 'إجمالي الحد لعدد المراكز',
    'panel.accounts.TrailingDrawdownLevel': 'حجم الخسارة المتحركة',
    'panel.accounts.UnrealizedLoss': 'خسارة غير محققة ',
    'panel.accounts.UnrealizedLossLimit': 'خسارة حد غير محققة ',
    'panel.accounts.UnsettledCash': 'نقد تحت التسوية',
    'panel.accounts.UserGroup': 'مجموعة المستخدم',
    'panel.accounts.UserID': 'رقم المستخدم',
    'panel.accounts.UserLogin': 'تسجيل دخول المستخدم',
    'panel.accounts.VolumeLimitForEquities': 'حد الكيمة للأصول',
    'panel.accounts.VolumeLimitForFutures': 'حد الكمية المستقبلية ',
    'panel.accounts.VolumeLimitForOptions': 'حد الكمية للخيارات',
    'panel.accounts.WithdrawalAvailable': 'السحب المتاح',
    'panel.assetBalances': 'الحسابات',
    'panel.backoffice': 'المكتب الخلفي',
    'panel.backoffice.You need to add connection!': 'يجب إضافة اتصال!',
    'panel.positionsBalance': 'رصيد المراكز',
    'panel.caption.NotAllowedMode': 'غير مسموح باللوحة الحالية في مجموعة القواعد',
    'panel.caption.showHideToolbar': 'عرض شريط الأدوات ',
    'panel.chart': 'الرسم البياني',
    'panel.close': 'إغلاق',
    'panel.closePosition.Gross_P/L_ToClose': 'إجمال ر/خ للإغلاق',
    'panel.closePosition.QunatityToClose': 'الكمية للإغلاق',
    'panel.copypanel': ' نسخ اللوحة',
    'panel.detail': 'تفاصيل السعر',
    'panel.detail.AuctionEndTime': '',
    'panel.detail.CBAuctionStart': '',
    'panel.detail.change': 'التغيّر',
    'panel.detail.changePr': 'التغير %',
    'panel.detail.CurrentSession': '',
    'panel.detail.high': 'أعلى',
    'panel.detail.last': 'آخر سعر',
    'panel.detail.Limit.High': '',
    'panel.detail.Limit.Low': '',
    'panel.detail.low': 'أقل',
    'panel.detail.NormalMarketSize': '',
    'panel.detail.OffExchangeValue': '',
    'panel.detail.OffExchangeVolume': '',
    'panel.detail.open': 'الإفتتاح ',
    'panel.detail.TradedValue': '',
    'panel.detail.TradingStatus': '',
    'panel.detail.volume': 'الحجم',
    'panel.EventLog.Action': 'الحدث',
    'panel.EventLog.Date': 'التاريخ',
    'panel.EventLog.eventType.alerts': 'التنبيهات',
    'panel.EventLog.eventType.comment': 'تعليق',
    'panel.EventLog.eventType.disconnect': 'قطع الاتصال',
    'panel.EventLog.eventType.exception': 'استثناء',
    'panel.EventLog.eventType.info': 'معلومات',
    'panel.EventLog.eventType.none': 'لا يوجد',
    'panel.EventLog.eventType.system': 'النظام',
    'panel.EventLog.eventType.trading': 'التداول',
    'panel.EventLog.eventType.updater': 'المحدث',
    'panel.EventLog.menu.Clear': 'مسح',
    'panel.EventLog.Message': 'رسالة',
    'panel.EventLog.NamePanel': 'سجّل الأحداث',
    'panel.EventLog.Roundtrip': 'ذهاب وإياب، م ث',
    'panel.EventLog.Type': 'النوع',
    'panel.GridPanel.listButton.ToolTip': 'حدد الرموز للألواح',
    'panel.GridPanel.refreshAllCharts.ToolTip': 'تحديث جميع الرسوم البيانية',
    'panel.GridPanel.resetMI.Text': 'إعادة ضبط حجم اللوحات',
    'panel.informer': 'قائمة المراقبة ',
    'panel.instrumentInfo.AvgPrice': 'متوسط السعر:',
    'panel.instrumentInfo.P/L': 'ر/خ:',
    'panel.instrumentInfo.Qty': 'الكمية:',
    'panel.instruments': 'الرموز',
    'panel.instrumentsInfo': 'معلومات الرمز',
    'panel.knowledgebase': 'قاعدة المعرفة',
    'panel.level2': 'عمق السوق',
    'panel.Level2.Age': '#hidden#',
    'panel.level2.Auction': 'مزاد',
    'panel.Level2.AvgPrice': 'معدل السعر',
    'panel.Level2.Contr. CCY Total value': 'القيمة الإجمالية للعملة المضادة',
    'panel.Level2.Contr. CCY value': 'قيمة العملة المضادة',
    'panel.Level2.DayTradeVolume': 'الحجم',
    'panel.Level2.menu.View': 'عرض',
    'panel.Level2.menu.View.InstrInfo': 'شريط المركز',
    'panel.Level2.menu.View.Level1': 'شريط المستوى1',
    'panel.Level2.menu.View.Order Entry': 'إدخال الأوامر',
    'panel.Level2.MPID': 'رقم صانع السوق',
    'panel.Level2.Number': 'أمر',
    'panel.Level2.oe.BuyButton': 'شراء',
    'panel.Level2.oe.SellButton': 'بيع',
    'panel.Level2.Price': 'السعر',
    'panel.Level2.Size': 'الحجم',
    'panel.Level2.Source': 'المصدر',
    'panel.Level2.Time': 'الوقت',
    'panel.Level2.TotalVol': 'الحجم الإجمالي',
    'panel.log': 'سجّل الأحداث',
    'panel.menu.GroupBy': 'جمع بحسب',
    'panel.menu.new': 'جديد',
    'panel.menu.Remove': 'حذف',
    'panel.menu.resetToDefault': 'إعادة التعيين إلى الافتراضي',
    'panel.neworder': 'إدخال الأوامر',
    'panel.newOrderEntry.accountLabel': 'الحساب',
    'panel.newOrderEntry.AddAskButton': 'بيع على العرض ',
    'panel.newOrderEntry.AddBitButton': 'شراء على الطلب',
    'panel.newOrderEntry.amountLabel': 'الكمية',
    'panel.newOrderEntry.BuyAskButton': 'شراء على العرض',
    'panel.newOrderEntry.BuyMarketButton': 'شراء على السوق',
    'panel.newOrderEntry.buySideCheckBox': 'شراء',
    'panel.newOrderEntry.instrumentLabel': 'الرمز',
    'panel.newOrderEntry.limitPricePanel': 'السعر المحدد',
    'panel.newOrderEntry.placeOrderButton': 'وضع الأمر',
    'panel.newOrderEntry.ProductType': 'نوع المنتج',
    'panel.newOrderEntry.RefreshMarginReq': 'تحديث متطلبات الهامش',
    'panel.newOrderEntry.riskDetailsLabel': 'الهامش',
    'panel.newOrderEntry.SellBidButton': 'بيع على الطلب',
    'panel.newOrderEntry.SellMarketButton': 'بيع على السوق',
    'panel.newOrderEntry.sellSideCheckBox': 'بيع',
    'panel.newOrderEntry.Side': 'جانب',
    'panel.newOrderEntry.singleOrderRadio': 'فردي',
    'panel.newOrderEntry.slPriceRisk': 'سعر/مخاطرة إ.خ',
    'panel.newOrderEntry.slPriceRiskOffset': 'إ.خ التعويض/المخاطر',
    'panel.newOrderEntry.stopLossCheckBox': 'سعر إيقاف الخسارة ',
    'panel.newOrderEntry.stopLossCheckBox.offset': 'تعويض إخ',
    'panel.newOrderEntry.stopPricePanel': 'سعر الإيقاف',
    'panel.newOrderEntry.takeProfitCheckBox': 'سعر أخذ الربح',
    'panel.newOrderEntry.takeProfitCheckBox.offset': 'أ.ر اوفست',
    'panel.newOrderEntry.tifPanel': 'الصلاحية ',
    'panel.newOrderEntry.tpPriceRisk': 'سعر/مخاطرة أ.ر',
    'panel.newOrderEntry.tpPriceRiskOffset': 'تعويض/مخاطرة أ.ر',
    'panel.newOrderEntry.trstopOffset': 'تعويض التوقف المؤقت',
    'panel.newOrderEntry.typePanel': 'نوع الأمر',
    'panel.NewTrades.Account': 'الحساب',
    'panel.NewTrades.Amount': 'الكمية',
    'panel.NewTrades.Bought': 'مشترى',
    'panel.NewTrades.Commission': 'رسوم التنفيذ',
    'panel.NewTrades.Commission.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Date': 'التاريخ',
    'panel.NewTrades.Exchange': 'السوق',
    'panel.NewTrades.ExpirationDate': 'تاريخ الانتهاء',
    'panel.NewTrades.ExternalOrderId': '#hidden#',
    'panel.NewTrades.ExternalPrice': 'سعر خارجي',
    'panel.NewTrades.ExternalTradeId': 'الرقم الخارجي',
    'panel.NewTrades.instrType': 'نوع الرمز',
    'panel.NewTrades.Instrument': 'الرمز',
    'panel.NewTrades.Login': 'اسم المستخدم',
    'panel.NewTrades.Net_PL': 'صافي ر/خ',
    'panel.NewTrades.Net_PL.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Operation': 'جانب',
    'panel.NewTrades.OrderId': 'رقم الأمر',
    'panel.NewTrades.OrderType': 'نوع الأمر',
    'panel.NewTrades.Price': 'السعر',
    'panel.NewTrades.ProductType': 'نوع المنتج',
    'panel.NewTrades.PurchasePrice': 'التعرض',
    'panel.NewTrades.RealizedProfitLoss': 'إجمالي ر/خ',
    'panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Rebates': 'الخصومات',
    'panel.NewTrades.Route': 'مسار',
    'panel.NewTrades.Sold': 'مباع',
    'panel.NewTrades.StrikePrice': 'سعر تنفيذ الخيارات ',
    'panel.NewTrades.Time': 'الوقت',
    'panel.NewTrades.TradeID': 'رقم الصفقة',
    'panel.NewTrades.TradeIDLabel': '#hidden#',
    'panel.OrderBook.Account': 'الحساب',
    'panel.OrderBook.Action': 'جانب',
    'panel.OrderBook.Bought': 'المشترى',
    'panel.OrderBook.Date': 'التاريخ',
    'panel.OrderBook.DislocedQty': '#hidden#',
    'panel.OrderBook.Event': 'الحدث',
    'panel.OrderBook.ExpirationDate': 'تاريخ الانتهاء',
    'panel.OrderBook.ExternalOrderID': '#hidden#',
    'panel.OrderBook.FilledQuantity': '#hidden#',
    'panel.OrderBook.instrType': 'نوع الرمز',
    'panel.OrderBook.Instrument': 'الرمز',
    'panel.OrderBook.Login': 'اسم المستخدم',
    'panel.OrderBook.Message': 'رسالة',
    'panel.OrderBook.OrderNumber': 'رقم الأمر',
    'panel.OrderBook.Price': 'السعر',
    'panel.OrderBook.ProductType': 'نوع المنتج',
    'panel.OrderBook.Quantity': 'الكمية',
    'panel.OrderBook.ReminingQuantity': '#hidden#',
    'panel.OrderBook.RemoteID': 'رقم وحدة التحكم',
    'panel.OrderBook.Route': 'مسار',
    'panel.OrderBook.Sold': 'مباع',
    'panel.OrderBook.StopPrice': 'سعر الإيقاف',
    'panel.OrderBook.StrikePrice': 'سعر الضربة',
    'panel.OrderBook.TIF': 'الصلاحية ',
    'panel.OrderBook.Time': 'الوقت',
    'panel.OrderBook.TraderID': 'تم التعديل بواسطة المستخدم',
    'panel.OrderBook.Type': 'النوع',
    'panel.orderEntry': 'إدخال أمر',
    'panel.orderHistory': 'سجّل الأوامر',
    'panel.orders': 'الأوامر القائمة ',
    'panel.orders.Account': 'الحساب',
    'panel.orders.action': 'جانب',
    'panel.orders.AvgFilledPrice': '#hidden#',
    'panel.orders.BindTo': 'مرتبط بـ',
    'panel.orders.Cancel': '',
    'panel.orders.Comment': '#hidden#',
    'panel.orders.Comments': 'تعليقات',
    'panel.orders.Ct_Ahead': 'Ct. Ahead',
    'panel.orders.CurrentPrice': 'السعر الحالي',
    'panel.orders.date': 'التاريخ',
    'panel.orders.date_time': '',
    'panel.orders.disclosedQty': '#hidden#',
    'panel.orders.Event': 'الحدث',
    'panel.orders.Exchange': 'السوق',
    'panel.orders.ExpirationDate': 'تاريخ الانتهاء',
    'panel.orders.ExternalOrderId': '#hidden#',
    'panel.orders.ExternalOrderStatus': '#hidden#',
    'panel.orders.GroupId': 'رقم مجموعة إدارة الحسابات المتعددة',
    'panel.orders.InitReq': 'المتطلبات المبدئية',
    'panel.orders.Instr_Type': 'نوع الأصل',
    'panel.orders.Login': 'اسم المستخدم',
    'panel.orders.Market': 'السوق',
    'panel.orders.menu.CancelAllByInstrument': 'إلغاء الجميع حسب النوع',
    'panel.orders.menu.CancelAllForAccount': 'إلغاء كل للحساب',
    'panel.orders.menu.CancelAllOrders': 'إلغاء جميع الأوامر',
    'panel.orders.menu.CancelOrder': 'إلغاء الأمر',
    'panel.orders.menu.ExecuteAsMarket': 'التغير إلى السوق',
    'panel.orders.menu.ExecuteAsMarket.question': 'هل أنت متأكد أنك تريد تنفيذ الطلب على سعر السوق؟',
    'panel.orders.menu.ModifyOrder': '...تغيير الأمر',
    'panel.orders.menu.Remove': 'إلغاء الأمر',
    'panel.orders.OCOGrouID': 'معرف مجموعة أمر يلغي الآخر (OCO)',
    'panel.orders.price': 'السعر',
    'panel.orders.ProductType': 'نوع المنتج',
    'panel.orders.quantity': 'الكمية',
    'panel.orders.QuantityFilled': 'الكمية المنفذة',
    'panel.orders.QuantityRemaining': 'الكمية المتبقية',
    'panel.orders.Reference': 'رقم الأمر',
    'panel.orders.RemoteID': 'رقم وحدة التحكم',
    'panel.orders.Route': 'مسار',
    'panel.orders.SL': 'إيقاف الخسارة',
    'panel.orders.SL_Offset': 'إ.خ offset',
    'panel.orders.SL_Price': 'سعر إ.خ',
    'panel.orders.Status': 'الحالة',
    'panel.orders.StopPrice': 'سعر الإيقاف',
    'panel.orders.StrikePrice': 'سعر الضربة',
    'panel.orders.symbol': 'الرمز',
    'panel.orders.symbolDescription': 'وصف الرمز',
    'panel.orders.TIF': 'الصلاحية ',
    'panel.orders.time': 'الوقت',
    'panel.orders.Token': 'رقم الأمر',
    'panel.orders.TP': 'أخذ الربح',
    'panel.orders.TP_Offset': 'أ.ر offset',
    'panel.orders.TP_Price': 'سعر إ.ر',
    'panel.orders.TraderID': 'رقم المتداول',
    'panel.orders.type': 'النوع',
    'panel.orders.UserGroup': 'مجموعة المستخدم',
    'panel.positions': 'المراكز',
    'panel.positions.Account': 'الحساب',
    'panel.positions.AvailableForSell': '',
    'panel.positions.Basis': 'سعر التنفيذ',
    'panel.positions.Close': '',
    'panel.positions.comments': 'تعليقات',
    'panel.positions.commissions': 'الرسوم',
    'panel.positions.commissions.InstrumentCurrency': '#hidden#',
    'panel.positions.confirmation.Reverse': 'عكس',
    'panel.positions.confirmation.ReverseAllPositions': 'هل أنت متأكد من عكس جميع المراكز؟',
    'panel.positions.confirmation.ReverseByInstrument': 'أنت متأكد من عكس جميع المراكز بحسب الرمز والحساب المحددين؟',
    'panel.positions.confirmation.ReverseSelectedPositions': 'أنت متأكد من عكس المراكز المحددة؟',
    'panel.positions.cur_price': 'السعر الحالي',
    'panel.positions.date_time': 'التاريخ/الوقت',
    'panel.positions.Delta': 'دلتا',
    'panel.positions.ExpDate': 'تاريخ الانتهاء',
    'panel.positions.exposition_usd': 'تعرض',
    'panel.positions.Gamma': 'قاما',
    'panel.positions.GroupId': 'رقم مجموعة إدارة الحسابات المتعددة',
    'panel.positions.InitReq': 'المتطلبات المبدئية',
    'panel.positions.IV': 'تقلّب ضمني',
    'panel.positions.Login': 'اسم المستخدم',
    'panel.positions.MaintReq': 'متطلبات الوقاية',
    'panel.positions.MarginSuplus': 'عجز/فائض الهامش',
    'panel.positions.menu.Close': 'إغلاق المركز',
    'panel.positions.menu.Modify': 'تعديل المركز',
    'panel.positions.menu.ShowToolbar': '',
    'panel.positions.menu.View': 'عرض',
    'panel.positions.Net_PL': 'صافي ر/خ',
    'panel.positions.Net_PL.InstrumentCurrency': '#hidden#',
    'panel.positions.Operation': 'جانب',
    'panel.positions.PL_ticks': 'ر/خ، حركة السعر',
    'panel.positions.pos_number': 'رقم المركز',
    'panel.positions.PositionValue': 'قيمة المركز',
    'panel.positions.ProductType': 'نوع المنتج',
    'panel.positions.profit_usd': 'إجمالي ر/خ',
    'panel.positions.profit_usd.InstrumentCurrency': '#hidden#',
    'panel.positions.Qunatity': 'الكمية',
    'panel.positions.RealizedPnL': 'الأرباح و الخسائر المحققة',
    'panel.positions.RealizedPnL.InstrumentCurrency': '#hidden#',
    'panel.positions.Rho': 'Rho',
    'panel.positions.route': 'مسار',
    'panel.positions.SL': 'إيقاف الخسارة',
    'panel.positions.SL_Value': 'إ.خ، القيمة',
    'panel.positions.strike': 'سعر الضربة',
    'panel.positions.swaps': 'المبادلة',
    'panel.positions.Symbol': 'الرمز',
    'panel.positions.SymbolDescription': 'وصف الرمز',
    'panel.positions.Theta': 'ثيتا',
    'panel.positions.TP': 'أخذ الربح',
    'panel.positions.type': 'نوع الرمز',
    'panel.positions.UsedMargin': 'الهامش المستخدم',
    'panel.positions.UserGroup': 'مجموعة المستخدم',
    'panel.positions.Vega': 'فيقا',
    'panel.settings.Information': 'معلومات',
    'panel.settings.saveDefault': 'تم الحفظ بنجاح!',
    'panel.terceraChart': 'الرسم البياني',
    'panel.TerceraSymbolLookupDropDownForm.Add': 'إضافة',
    'panel.TerceraSymbolLookupDropDownForm.All': 'جميع',
    'panel.TerceraSymbolLookupDropDownForm.Bonds': 'سندات',
    'panel.TerceraSymbolLookupDropDownForm.Cancel': 'إلغاء',
    'panel.TerceraSymbolLookupDropDownForm.CFDs': 'عقود الفروقات',
    'panel.TerceraSymbolLookupDropDownForm.collapceAllButton': 'تقليص الكل',
    'panel.TerceraSymbolLookupDropDownForm.crypto': 'تشير العملة المقابلة',
    'panel.TerceraSymbolLookupDropDownForm.Equities': 'الأسهم',
    'panel.TerceraSymbolLookupDropDownForm.ETFs': 'صناديق متداولة في البورصة ',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges': 'جميع الأسواق',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges': 'التحويلات',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges': 'لا نوجد تحويلات',
    'panel.TerceraSymbolLookupDropDownForm.expandExchangesButton': 'تمديد التحويلات',
    'panel.TerceraSymbolLookupDropDownForm.expandTypesButton': 'أنواع التمديد',
    'panel.TerceraSymbolLookupDropDownForm.Forex': 'فوركس',
    'panel.TerceraSymbolLookupDropDownForm.Forward': 'اجل',
    'panel.TerceraSymbolLookupDropDownForm.Futures': 'المستقبلية',
    'panel.TerceraSymbolLookupDropDownForm.indices': 'المؤشرات',
    'panel.TerceraSymbolLookupDropDownForm.Options': 'خيارات',
    'panel.TerceraSymbolLookupDropDownForm.Portfolio': 'المحفظة',
    'panel.TerceraSymbolLookupDropDownForm.Select': '',
    'panel.TerceraSymbolLookupDropDownForm.Select all': 'تحديد الكل',
    'panel.TerceraSymbolLookupDropDownForm.Show additionl info': 'عرض معلومات إضافية',
    'panel.TerceraSymbolLookupDropDownForm.Spot': 'الفوري',
    'panel.TerceraSymbolLookupDropDownForm.Spreadbet': 'فروقات الرهان',
    'panel.TerceraSymbolLookupDropDownForm.TBill': 'اذونات الخزينة ',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types': 'جميع الانواع',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types': 'لا توجد أنواع',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.types': 'الأنواع',
    'panel.timeSales': 'الصفقات وأوقاتها ',
    'panel.timeSales.Agressor flag': 'جانب',
    'panel.timeSales.AgressorFlag.Auction': 'مزاد',
    'panel.timeSales.AgressorFlag.Buy': 'شراء',
    'panel.timeSales.AgressorFlag.CrossTrade': 'تجاول متبادل (cross trade)',
    'panel.timeSales.AgressorFlag.None': 'لا يوجد',
    'panel.timeSales.AgressorFlag.Sell': 'بيع',
    'panel.timeSales.ask': 'العرض',
    'panel.timeSales.AskBidSize': 'حجم الطلب×العرض',
    'panel.timeSales.AskSource': 'أسعار العرض بحسب المساهم في السوق',
    'panel.timeSales.bid': 'الطلب',
    'panel.timeSales.BidSource': 'أسعار الطلب بحسب المساهم في السوق',
    'panel.timeSales.BuyerSource': 'الشاري',
    'panel.timeSales.Exchange': 'المصدر',
    'panel.timeSales.Instrument': 'الرمز',
    'panel.timeSales.MenuName': 'الصفقات وأوقاتها',
    'panel.timeSales.Price': 'السعر',
    'panel.timeSales.SellerSource': 'البائع',
    'panel.timeSales.Size': 'الحجم',
    'panel.timeSales.Time': 'الوقت',
    'panel.timeSales.Type': 'نوع الرمز',
    'panel.tooltiper.System': 'البرنامج',
    'panel.trades': 'الأوامر المنفذة',
    'panel.watchlist': 'قائمة المراقبة ',
    'panel.watchlist.Ask': 'العرض',
    'panel.watchlist.AskSize': 'حجم العرض',
    'panel.watchlist.AuctionEndTime': '',
    'panel.watchlist.Bid': 'الطلب',
    'panel.watchlist.BidSize': 'حجم الطلب',
    'panel.watchlist.Buy': 'شراء',
    'panel.watchlist.CBAuctionStart': '',
    'panel.watchlist.cell.cancel': 'إلغاء',
    'panel.watchlist.cell.modify': 'تعديل',
    'panel.watchlist.cell.Not allow': 'لا تسمح',
    'panel.watchlist.cell.sending': 'إرسال ',
    'panel.watchlist.cell.ticks': 'حركات الأسعار',
    'panel.watchlist.Change': 'التغيّر',
    'panel.watchlist.ChangeFromOpen': 'التغير من الافتتاح',
    'panel.watchlist.ChangeFromOpen%': 'التغير من الافتتاح،%',
    'panel.watchlist.ChangePr': 'التغيّر، %',
    'panel.watchlist.Close': 'إغلاق',
    'panel.watchlist.ClosePostMarket': 'إغلاق ما بعد السوق',
    'panel.watchlist.Comment': 'تعليق',
    'panel.watchlist.Company': 'الشركة',
    'panel.watchlist.ContractSize': 'حجم الحصة',
    'panel.watchlist.CurrentSession': 'الجلسة الحالية',
    'panel.watchlist.Date': 'اخر موعد',
    'panel.watchlist.Descr': 'الوصف',
    'panel.watchlist.Exchange': 'السوق',
    'panel.watchlist.ExpDate': 'تاريخ الانتهاء',
    'panel.watchlist.High': 'أعلى',
    'panel.watchlist.High13': '#hidden#',
    'panel.watchlist.High26': '#hidden#',
    'panel.watchlist.High52': '#hidden#',
    'panel.watchlist.IndicativeAuctionPrice': 'مؤشر سعر المزاد',
    'panel.watchlist.Instrument': 'الرمز',
    'panel.watchlist.Last': 'الأخير',
    'panel.watchlist.LastSize': 'آخر حجم',
    'panel.watchlist.Limit.High': '',
    'panel.watchlist.Limit.Low': '',
    'panel.watchlist.Low': 'أقل',
    'panel.watchlist.Low13': '#hidden#',
    'panel.watchlist.Low26': '#hidden#',
    'panel.watchlist.Low52': '#hidden#',
    'panel.watchlist.Main Volume': 'الحجم الرئيسي',
    'panel.watchlist.Market': 'السوق',
    'panel.watchlist.MarketCap': 'القيمة السوقية ',
    'panel.watchlist.Match Volume': 'تطابق الحجم',
    'panel.watchlist.menu.AddSymbol': '...إضافة رموز',
    'panel.watchlist.menu.clearAll': 'مسح الكل',
    'panel.watchlist.menu.RemoveInstrument': 'حذف الرموز المحددة',
    'panel.watchlist.menu.SymbolInfo': 'معلومات الرمز',
    'panel.watchlist.NA': 'N/A',
    'panel.watchlist.noQuotes': 'آخر تحديث',
    'panel.watchlist.NormalMarketSize': '',
    'panel.watchlist.OffExchangeValue': '',
    'panel.watchlist.OffExchangeVolume': '',
    'panel.watchlist.Open': 'فتح',
    'panel.watchlist.OpenInterest': 'الفائدة المفتوحة',
    'panel.watchlist.OpenPreMarket': 'افتتاح ما قبل السوق',
    'panel.watchlist.PreMarketChange': 'التغير ما قبل السوق',
    'panel.watchlist.PreMarketChange%': 'التغير ما قبل السوق، %',
    'panel.watchlist.PrevClose': 'الإغلاق السابق',
    'panel.watchlist.PrevSettlementPrice': 'سعر التسوية السابق',
    'panel.watchlist.ReferencePrice': 'السعر المرجعي',
    'panel.watchlist.Sell': 'بيع',
    'panel.watchlist.SettlementPrice': 'سعر التسوية',
    'panel.watchlist.Spread': 'فرق السعر',
    'panel.watchlist.SpreadPr': 'الفروقات %',
    'panel.watchlist.Ticks': 'حركات الأسعار',
    'panel.watchlist.TicksPostMarket': 'حركات الأسعار ما بعد السوق',
    'panel.watchlist.TicksPreMarket': 'حركة الأسعر ما قبل السوق',
    'panel.watchlist.Time': 'آخر وقت',
    'panel.watchlist.TradedValue': '',
    'panel.watchlist.Type': 'نوع الرمز',
    'panel.watchlist.Volume': 'الحجم',
    'panel.watchlist.VolumePostMarket': 'حجم ما بعد السوق',
    'panel.watchlist.VolumePreMarket': 'حجم ما قبل السوق',
    'Panels.MarketDepth.CancelAll': 'إلغاء الكل',
    'panels.orders.buttons.AsMarket': 'التغير إلى السوق',
    'panels.orders.buttons.CancelAllButton': 'إلغاء الكل',
    'panels.orders.buttons.CancelBuyButton': 'إلغاء الشراء',
    'panels.orders.buttons.CancelByInstrumentButton': 'إلغاء',
    'panels.orders.buttons.CancelDayButton': 'إلغاء الأيام',
    'panels.orders.buttons.CancelGTCButton': 'إلغاء صالح حتى الإلغاء',
    'panels.orders.buttons.CancelLimitsButton': 'إلغاء محدود',
    'panels.orders.buttons.CancelSelectedButton': 'إلغاء المحدد',
    'panels.orders.buttons.CancelSellButton': 'إلغاء البيع',
    'panels.orders.buttons.CancelStopsButton': 'إلغاء الإيقاف',
    'panels.orders.buttons.ModifyOrder': 'تعديل الأمر',
    'panels.positions.buttons.closeAll': 'إغلاق الكل',
    'panels.positions.buttons.closeForInstrument': 'إغلاق',
    'panels.positions.buttons.closeLong': 'إغلاق طويل',
    'panels.positions.buttons.closeNegative': 'إغلاق السلبي',
    'panels.positions.buttons.closePositive': 'إغلاق الإيجابي',
    'panels.positions.buttons.closeSelected': 'إغلاق المحدد',
    'panels.positions.buttons.closeShort': 'إغلاق القصير',
    'panels.positions.buttons.clxAll': 'إلغاء الكل',
    'panels.positions.buttons.clxForInstrument': 'إغلاق',
    'panels.positions.ButtonsPanel.helpLabel': 'اضغط بالزر الأيمن لإظهار/إخفاء الأزرار',
    'PanelSettingsScreen.Text': '#N/A',
    'PanelSettingsScreen.TextSmall': '#N/A',
    'Parabolic Time/Price System': 'سار (وقت باربوليك/ نظام السعر)',
    'period.Day': 'يوم',
    'period.Days': 'أيام',
    'period.Hour': 'ساعة',
    'period.Hours': 'ساعات',
    'period.Min': 'الأحد الأدنى',
    'period.Mins': 'دقائق',
    'period.Month': 'شهر',
    'period.Months': 'أشهر',
    'period.Week': 'أسبوع',
    'period.Weeks': 'أسابيع',
    'period.Year': 'سنة',
    'period.Years': 'سنوات',
    'Pivot Point Moving Average': 'النقطة المحورية المتوسط المتحرك',
    PivotPoint: 'النقطة المحورية',
    'PopupMultiCkeckBox.All': 'جميع',
    Português: 'Português (Br)',
    'Price Channel': 'قناء السعر',
    'Price Oscillator': 'مؤشر تذبذب السعر',
    'ProductType.CarryForward': 'مدور (carry forward)',
    'ProductType.Delivery': 'تسليم',
    'ProductType.General': 'عام ',
    'ProductType.Intraday': 'خلال اليوم',
    'property.%': '%',
    'property.Additional': 'إضافي',
    'property.AllPrice': 'إحصائيات السعر',
    'property.appearance': 'المظهر',
    'property.Ask': 'العرض',
    'property.Author': 'المؤلف',
    'property.autosize': 'تعديل الحجم تلقائياً',
    'property.autoYScale.Auto': 'مقياس تلقائي',
    'property.autoYScale.Manual': 'مقياس يدوي',
    'property.Average': 'المتوسط',
    'property.Backgr': '',
    'property.Backgr. top': 'لون الجزء العلوي الخلفي',
    'property.BarPattern': 'نمط الشريط',
    'property.barsHiLowColor': 'خط',
    'property.BarStatistics': 'إحصائيات الأشرطة',
    'property.Bid': 'الطلب',
    'property.bodyColor': 'الجسم',
    'property.borderColor': 'الحدود',
    'property.BottomCenter': 'الجزء المنتصف السفلي',
    'property.BottomLeft': 'الجزء الأيسر السفلي',
    'property.BottomRight': 'الجزء الأيمن السفلي',
    'property.Browser': 'المتصفح',
    'property.Buy': 'شراء',
    'property.BuyColor': 'شراء',
    'property.cAsk': 'حجم العرض',
    'property.cASK': 'على العرض',
    'property.cASKHI': 'فوق سعر العرض',
    'property.cBid': 'حجم الطلب',
    'property.cBID': 'على الطلب',
    'property.cBIDLO': 'تحت سعر الطلب',
    'property.cellscin.useSellBuy': 'عكس أزرار الأوامر',
    'property.Change': 'التغيّر',
    'property.ChangePercent': 'التغيّر، %',
    'property.Chart': 'الرسوم البيانية ',
    'property.Chart OE': 'إدخال بيانات الرسم البياني',
    'property.chart.isBackground': 'انقال إلى الخلفية',
    'property.chart.line': 'سطر',
    'property.chart.ray': 'راي',
    'property.chart.rayStyle': 'نمط راي',
    'property.chart.showAngle': 'إظهار الزاوية',
    'property.chart.showingOE': 'إظهار إدخال الأوامر',
    'property.chart.stickTo': 'الالتزام بـ',
    'property.chart.tools.back': 'رسم الخلفية ',
    'property.chart.tools.fibretr.font': 'تسمية الخط',
    'property.chart.tools.fibretr.fontcolor': 'لون الخط',
    'property.ChartAnalyseToolsToolBar': 'شريط أدوات التحليل',
    'property.ChartDrawingToolsToolBar': 'شريط أدوات الرسم',
    'property.cLimit': 'أمر سعر محدد',
    'property.Close': 'إغلاق',
    'property.Cluster.ClusterUpDownColors': 'كتلة',
    'property.Cluster.ColorScheme': 'نظام الألوان',
    'property.Cluster.ColorScheme.ByDelta': 'بحسب دلتا',
    'property.Cluster.ColorScheme.ByDelta.Colors': 'اللون',
    'property.Cluster.ColorScheme.ByDelta.Colors.BuyColor': 'شراء',
    'property.Cluster.ColorScheme.ByDelta.Colors.SellColor': 'بيع',
    'property.Cluster.ColorScheme.ByTrades': 'بحسب التداولات',
    'property.Cluster.ColorScheme.ByVolume': 'بحسب الحجم',
    'property.Cluster.ColorScheme.Color': 'اللون',
    'property.Cluster.ColorScheme.None': 'لا يوجد',
    'property.Cluster.CustomStep': 'الخطوة المخصصة',
    'property.Cluster.Down': 'هابط',
    'property.Cluster.FilterValue': 'قيمة الفلتر',
    'property.Cluster.Font': 'الخط',
    'property.Cluster.FontColor': 'لون الخط',
    'property.Cluster.HighlightMax': 'الحد الأقصى للتظليل',
    'property.Cluster.HighlightMax.Colors': 'الألوان',
    'property.Cluster.Operator': 'مشغل',
    'property.Cluster.PriceStep': 'خطوة السعر',
    'property.Cluster.PriceStep.Custom': 'تخصيص',
    'property.Cluster.PriceStep.MinTick': 'الحد الأدنى لحركة السعر',
    'property.Cluster.ShowOnlySimilarDelta': 'عرض ديلتا المتشابهة فقط',
    'property.Cluster.ShowValueFilter': 'فلتر القيمة',
    'property.Cluster.Up': 'عالي',
    'property.cMIDLO': 'بين فرق السعر',
    'property.Color': 'اللون',
    'property.ColoringMode': 'وضع التلوين',
    'property.Colors': 'الألوان',
    'property.colors_param': 'الألوان',
    'property.colorScheme': 'النمط',
    'property.ColorsSeparatorGroup': 'الألوان',
    'property.ColouringMode': 'وضع التلوين',
    'property.Comments': 'تعليقات',
    'property.Company': 'الشركة',
    'property.Confirmations': 'التأكيدات',
    'property.connection': 'الاتصال',
    'property.Connection': 'الاتصال',
    'property.ContentAlignment': 'المحاذاة',
    'property.Copyrights': 'حقوق النشر',
    'property.cQUOTE': 'العروض',
    'property.CreateColor': 'إنشاء',
    'property.crossHairFontColor': 'لون الخط',
    'property.cStop': 'أمر الإيقاف',
    'property.currency': 'العملة',
    'property.CustomYMarkingValue': 'الحد الأدنى لحركة الشبكة',
    'property.Data Box': 'نافذة المعلومات',
    'property.Data Style': 'نمط البيانات',
    'property.Day': 'يوم',
    'property.DaySeparators': 'فواصل الأيام',
    'property.DefaultProductType': 'نوع المنتج التلقائي',
    'property.Defaults': 'افتراضيات التداول',
    'property.deviation': 'الانحراف',
    'property.dK': 'dK',
    'property.Doji': 'دوجي',
    'property.Downband deviation in %': 'انحراف الشريط النازل %',
    'property.email': 'عناوين البريد الإلكتروني\n(استخدم الفاصلة أو الفاصلة النقطية)',
    'property.Emulator': 'المنافس',
    'property.enable': 'تشغيل',
    'property.Enable Sounds': 'تشغيل الأصوات',
    'property.Exponential': 'لوغاريتمي',
    'property.fib.Arc': 'قوس الفيبوناتشى',
    'property.Fibonacci': 'الفيبوناتشى',
    'property.Filters': 'فلتر',
    'property.FitDrawings': 'رسوم فيت',
    'property.FitHighLow': 'أعلى/أدنى مستوى فيت لليوم',
    'property.FitIndicator': 'مؤشرات فيت',
    'property.FitOrders': 'أوأمر/وضعيات فيت',
    'property.FOK': 'أمر نفذ كامل فوري أو إلغي',
    'property.Font': 'الخط',
    'property.Forex': 'فوركس',
    'property.Forwards': 'إلى الأمام',
    'property.Futures': 'المستقبلية',
    'property.FX Cell': 'خلية الفوركس',
    'property.G': 'G',
    'property.General': 'عام',
    'property.general.defaultSymbol': 'الرمز',
    'property.general.OvernightMarginNotificationMessage': '',
    'property.general.showLotsFX': 'عرض العدد بالحصص',
    'property.gr_type': 'النمط',
    'property.Gradient': 'الانحدار',
    'property.GraphicType.Absolute': 'مطلق',
    'property.GraphicType.Log': 'لوغاريتمي',
    'property.GraphicType.Relative': 'نسبي',
    'property.GridPrice': 'السعر (أفقي)',
    'property.GridPriceHighLight': 'تظليل لون الشبكة',
    'property.GridTime': 'التاريخ (عمودي)',
    'property.GTC': 'صالح حتى الإلغاء',
    'property.GTD': 'صالح حتى اليوم',
    'property.HeaderFont': 'الخط',
    'property.HeaderText': 'نص العنوان',
    'property.High': 'أعلى',
    'property.HighlightMarkingsStep': 'تظليل خطوة الشبكة',
    'property.HistogramLineColor': 'خط مدرج تكراري',
    'property.HistoryType': 'نوع البيانات',
    'property.HorizontalLine.LeftRay': 'راي اليسار',
    'property.hostName': 'اسم المضيف',
    'property.hotkeyManager.ShowVolume': 'إظهار الحجم',
    'property.infowindow_font': 'الخط',
    'property.infoWindow_fore_back_color': 'اللون',
    'property.infowindow_visible': 'إظهار نافذة المعلومات',
    'property.Instrument Types': 'أنواع الأدوات',
    'property.Intrinsic': 'داخلي',
    'property.IOC': 'أمر فوري أو إلغاء',
    'property.IsBackground': 'الخلفية',
    'property.IsBarsVisible': '# أشرطة',
    'property.IsChangeVisible': 'التغيّر',
    'property.IsCloseVisible': 'إغلاق',
    'property.IsDateVisible': 'التاريخ',
    'property.IsHighVisible': 'أعلى',
    'property.IsLowVisible': 'أقل',
    'property.IsOpenVisible': 'فتح',
    'property.IsRoundedAvgPrice': 'متوسط سعر الافتتاح المتداول',
    'property.isShowToolTips': 'تلميحات',
    'property.isShowToolTipsGrop': '',
    'property.IsSyncActive': 'مزامنة مع الخرائط',
    'property.IsTicksVisible': 'حركات الأسعار',
    'property.IsTimeVisible': 'الوقت',
    'property.IsVolumeVisible': 'الحجم',
    'property.LabelColors': 'ألوان العلامة',
    'property.Language': 'اللغة',
    'property.Last': 'الأخير',
    'property.lastPrice_AskIndicatorColor': 'العرض',
    'property.lastPrice_BidIndicatorColor': 'الطلب',
    'property.lastPrice_LastIndicatorColor': 'الأخير',
    'property.lastPrice_SpreadIndicatorColor': 'لون منطقة فرق السعر',
    'property.Left ray': 'راي اليسار',
    'property.Level': 'المستوى',
    'property.Limit': 'المحدد',
    'property.Line': 'سطر',
    'property.line.lineName': 'اسم الخط',
    'property.line.plateVisible': 'مشاهدة السطر {0} علامة',
    'property.line.timeShift': 'إزاحة الوقت',
    'property.line.value': 'القيمة',
    'property.line.Visible': 'اظهار',
    'property.line.yFactor': 'معامل Y',
    'property.Linear Weighted': 'خطي متوازن',
    'property.lineProperties': 'الخصائص',
    'property.LOC': 'أمر سعر محدد على الإقفال ',
    'property.logBase': 'قاعدة السجّل',
    'property.LOO': 'أمر سعر محدد على الإفتتاح ',
    'property.LotsMode': 'إظهار الحجم في',
    'property.Low': 'أقل',
    'property.Mail Server': 'سيرفر البريد',
    'property.Manual': 'يدوي',
    'property.Market': 'السوق',
    'property.MarketCap': 'القيمة السوقية',
    'property.MarketDepth.ColoringMethod': 'طريقة التلوين',
    'property.MarketProfile.ColorScheme': 'نظام الألوان',
    'property.MarketProfile.ColorScheme.Color': 'اللون',
    'property.MarketProfile.ColorScheme.GradientColor': 'المنطقة',
    'property.MarketProfile.ColorScheme.PairColor.Color': 'اللون',
    'property.MarketProfile.ColorScheme.VolumeColor': 'اللون',
    'property.MarketProfile.Font': 'الخط',
    'property.MarketProfile.PointOfControl': 'نقطة التحكم',
    'property.MarketProfile.PointOfControlStyle': 'نمط نقطة التحكم',
    'property.MarketProfile.ShowSingles': 'إظهار الفرديات',
    'property.MarketProfile.SinglesStyle': 'نمط الفرديات',
    'property.MarketProfile.ValueArea': 'منطقة القيمة',
    'property.MarketProfile.ValueAreaStyleHorisontal': 'نمط الخط الأفقي',
    'property.MarketProfile.ValueAreaStyleVertical': 'نمط الخط العمودي',
    'property.MarketProfile.ValuePercents': 'القيمة',
    'property.Medium': 'متوسط',
    'property.message': 'رسالة',
    'property.method': 'الطريقة',
    'property.MirrorShow': 'العرض العكسي',
    'property.MOC': 'السوق على الإقفال ',
    'property.Mode': 'الوضع',
    'property.Mode.Trades': 'التداولات',
    'property.Modified': 'معدل',
    'property.MonthSeparators': 'فواصل الأشهر',
    'property.MOO': 'السوق على الإفتتاح ',
    'property.name': 'الاسم',
    'property.Name': 'الإسم ',
    'property.News': 'الأخبار',
    'property.None': 'لا يوجد',
    'property.Note': 'ملحوظة',
    'property.NotSet': 'غيرمضبوط',
    'property.Number': 'العدد',
    'property.OCO': 'أمر يلغي الآخر (OCO)',
    'property.OE_AddBidAddAsk': 'إضافة طلب / إضافة عرض',
    'property.OE_ShowBuyMarketSellMarket': 'شراء السوق/بيع السوق',
    'property.OE_ShowSellBidBuyASk': 'بيع الطلب / شراء عرض',
    'property.OE_ShowSLTP': 'إغلاق الأوامر',
    'property.OE_Small': 'إدخال أوأمر ضيق',
    'property.OE_Small.ToolTip': 'إذا تم التحديد، سيكون لإدخال الأوامر عرض ضيق',
    'property.oe.confirmClosePosition': 'تأكيد إغلاق المركز',
    'property.oe.confirmOrderCancel': 'تأكيد إلغاء الأمر',
    'property.oe.confirmOrderChange': 'تأكيد تعديل الأمر / المركز',
    'property.oe.confirmOrderCreate': 'تأكيد تنفيذ الأمر',
    'property.oe.confirmReversePosition': 'تأكيد إنعكاس المركز',
    'property.OffsetMode': 'إظهار المعادلة في',
    'property.OnClose': 'عند الإغلاق',
    'property.OnOpen': 'عند الفتح',
    'property.Open': 'فتح',
    'property.Order Entry': 'إدخال الأوامر',
    'property.OrderBuyStyleWidth': 'شراء',
    'property.OrderSellStyleWidth': 'بيع',
    'property.OverlayVisible': 'تراكب مرئي',
    'property.Panel name': 'اسم اللوحة',
    'property.panel.accountDetails.Groups.1.General': 'عام ',
    'property.panel.accountDetails.Groups.2.Margin': 'هامش',
    'property.panel.accountDetails.Groups.3.AccountActivity': 'نشاط الحساب',
    'property.panel.accountDetails.Groups.4.TodayResults': 'نتائج اليوم ',
    'property.panel.accountDetails.Groups.5.RiskManagement': 'ادارة المخاطر',
    'property.panel.accountDetails.Groups.6.Info': 'معلومات',
    'property.Panels': 'اللوحات',
    'property.Parameters': 'المعلمات',
    'property.password': 'كلمة المرور',
    'property.period': 'الفترة',
    'property.Period of indicator': 'فترة المؤشر',
    'property.Period of indicator:': 'فترة المؤشر',
    'property.Period of Linear Regression': 'فترة التراجع الخطي',
    'property.Period of MA for envelopes': 'فترة المتوسط الحركي للأظرف',
    'property.Period of MA1': 'فترة المتوسط الحركي1',
    'property.Period of MA2': 'فترة المتوسط الحركي2',
    'property.Period of momentum:': 'فترة قوة الاندفاع',
    'property.Period of Moving Average': 'فترة المتوسط المتحرك',
    'property.Period of price channel': 'فترة قناء السعر',
    'property.Period of Simple Moving Average': 'فترة المتوسط المتحرك البسيط',
    'property.Period of Smoothed Moving Average': 'فترة المتوسط المتحرك المصقول',
    'property.Periods': 'الفترات',
    'property.pin': 'رقم السري',
    'property.pips': 'النقاط',
    'property.POCTypes.Line': 'سطر',
    'property.POCTypes.Marker': 'الصانع',
    'property.POCTypes.None': 'لا يوجد',
    'property.points': 'النقاط',
    'property.Points': 'النقاط',
    'property.PositionBuyStyleWidth': 'طويل',
    'property.PositionSellStyleWidth': 'قصير',
    'property.Previous': 'السابق',
    'property.price': 'السعر',
    'property.Price Scale': 'مقياس السعر',
    'property.PriceIndicatorDrawingType': 'مؤشر السعر',
    'property.PriceIndicatorDrawingType.None': 'لا يوجد',
    'property.PriceIndicatorDrawingType.ScaleMarker': 'صانع المقياس',
    'property.PriceIndicatorDrawingType.ScaleMarkerWithLine': 'صانع المقياس مع الخط',
    'property.PriceScaleAxisColor': 'المحول',
    'property.PriceScaleBackColor': 'خلفية المحور',
    'property.PriceScaleFont': 'الخط',
    'property.PriceScaleTextColor': 'لون النص',
    'property.PriceType': 'نوع السعر',
    'property.ProfileColoringTypes.DeltaBars': 'قضبان دلتا',
    'property.ProfileColoringTypes.DeltaPrice': 'سعر دلتا',
    'property.ProfileColoringTypes.DeltaProfile': 'ملف دلتا',
    'property.ProfileColoringTypes.Single': 'لون واحد',
    'property.ProfileColoringTypes.UpdownBars': 'قضبان صاعدة/هابطة',
    'property.ProfileColoringTypes.UpdownProfile': 'حالة صاعدة/هابطة',
    'property.ProfileColoringTypes.VolumeBars': 'حجم الأشرطة',
    'property.ProfileColoringTypes.VolumePrice': 'سعر الحجم',
    'property.protrader.showOrders': 'الأوامر',
    'property.Range': 'المدى',
    'property.Ray style': 'نمط راي',
    'property.Relative': 'Relative',
    'property.relativeCalcType': 'سعر البدء',
    'property.RelativeCalculationType.BeginOfTheData': 'بداية البيانات',
    'property.RelativeCalculationType.BeginOfTheDay': 'بداية اليوم',
    'property.RelativeCalculationType.BeginOfTheScreen': 'بداية الشاشة',
    'property.relativeScaleType': 'نوع المقياس',
    'property.Reserve': 'مخزون',
    'property.Reverse': 'عكس',
    'property.Right ray': 'الشعاع الأيمن',
    'property.routing.account': 'الحساب',
    'property.routing.orderType': 'نوع الأمر',
    'property.RSI Method:': 'طريقة مؤشر القوة النسبي',
    'property.RSI Period:': 'فترة مؤشر القوة النسبي',
    'property.SCRIPTS_SUBGROUP': 'النصوص',
    'property.ScrollBar': 'شريط التمرير',
    'property.secure': 'Secure',
    'property.Secure': 'آمن',
    'property.Selection': 'الاختيار',
    'property.Sell': 'بيع',
    'property.SellColor': 'بيع',
    'property.SeparatorGroup.ActiveOrders': 'الأوامر النشطة',
    'property.SeparatorGroup.additional': 'إضافي',
    'property.SeparatorGroup.Additional': 'إضافي',
    'property.SeparatorGroup.Appearance': 'المظهر',
    'property.SeparatorGroup.CrossHair': 'التقاطع',
    'property.SeparatorGroup.ExecutedOrders': 'الأوامر المنفذة',
    'property.SeparatorGroup.Grid': 'شبكة',
    'property.SeparatorGroup.HighlightDate': 'تظليل التاريخ',
    'property.SeparatorGroup.HighlightPrices': 'تظليل الأسعار',
    'property.SeparatorGroup.OpenPositions': 'المراكز المفتوحة',
    'property.SeparatorGroup.ScaleMarkings': 'علامات المقياس',
    'property.SeparatorGroup.ScaleType': 'نوع المقياس',
    'property.SeparatorGroup.SeparatorOEWidth': 'عرض',
    'property.SeparatorGroup.Show': 'عرض',
    'property.SeparatorGroup.TimeToNextBar': 'الوقت بجانب الشريط',
    'property.SeparatorGroup.WindowColors': 'ألوان النافذة',
    'property.SeparatorGroup.Zoom': 'تكبير',
    'property.SeparatorViewButtons': 'أزرار الاختصار',
    'property.Sesonality': 'التغيرات الموسمية',
    'property.shift': 'إزاحة',
    'property.showAllSessions': 'إظهار الجلسة الممتدة',
    'property.ShowEmptyBars': 'فراغات في السجّل',
    'property.showEvents': 'اظهار',
    'property.ShowFullArc': 'إظهار القوس كاملاً',
    'property.ShowHighLow': 'خطوط ارتفاعات/انخفاضات اليوم',
    'property.showOrders': 'اظهار',
    'property.showPositions': 'اظهار',
    'property.ShowPrevCloseLine': 'خط الإغلاق السابق',
    'property.ShowTimeToNextBar': 'اظهار',
    'property.ShowTotal': 'إظهار الإجمالي',
    'property.ShowVisualTradingOnLeftSide': 'ربط المراكز/الأوامر النشطة إلى الجانب الأيسر',
    'property.showVolume': 'اظهار',
    'property.showVolumeMarker': 'إظهار علامة القيمة على المقياس',
    'property.Simple': 'بسيط',
    'property.sltpOffsetMode': 'إظهار سعر إ.خ/أ.ر في التعويض',
    'property.Smoothing': 'المضاعفة',
    'property.solidPriceColor': 'المنطقة',
    'property.sounds_param': 'الأصوات',
    'property.Sources prices for MA': 'مصادر الأسعار للمتوسط المتحرك',
    'property.Specific': 'محدد',
    'property.SpreadType': 'نوع فرق السعر',
    'property.SpreadType.Area': 'المنطقة',
    'property.SpreadType.Line': 'خط',
    'property.SpreadType.LineWithLabel': 'خط معى سعر',
    'property.SpreadType.None': 'لا يوجد',
    'property.Step of parabolic SAR system': 'خطوة نظام باربوليك سار',
    'property.Stick to': 'الالتزام بـ',
    'property.stickTo': 'الالتزام بـ:',
    'property.Stocks': 'الأسهم',
    'property.Stop': 'إيقاف',
    'property.Stop Limit': 'الإيقاف المحدد',
    'property.Stop-loss': 'إيقاف الخسارة',
    'property.style': 'النمط:',
    'property.Sum': 'المجموع',
    'property.Symbol': 'الرمز',
    'property.Symbols': 'الرموز',
    'property.T': 'T',
    'property.Take-profit': 'أخذ الربح',
    'property.text': 'النص',
    'property.TIF': 'مدة صلاحية الأمر',
    'property.TIF_Limit_And_Stop_Limit': 'مدة صلاحية أمر محدد (إيقاف خسارة)',
    'property.TIF_Stop': 'مدة صلاحية أمر الإيقاف',
    'property.Time Scale': 'المقياس الزمني',
    'property.TimeLine': 'خط الوقت',
    'property.TimelinePreview': 'التمرير معاينة التخطيط',
    'property.TimeScaleAxisColor': 'المحول',
    'property.TimeScaleBackColor': 'خلفية المحور',
    'property.TimeScaleFont': 'الخط',
    'property.TimeScaleImportentFont': 'خط التواريخ الهامة',
    'property.TimeScaleTextColor': 'لون الخط',
    'property.TimeToNextBarColor': 'اللون',
    'property.TimeToNextBarFont': 'الخط',
    'property.TopCenter': 'أعلى المنتصف',
    'property.TopLeft': 'أعلى اليسار',
    'property.TopRight': 'أعلى اليمين',
    'property.Tr. stop': 'التحرك، إيقاف',
    'property.trackShowCrossHair': 'مرئي',
    'property.Trading': 'التداول',
    'property.Trading Defaults': 'إفتراضي التداول ',
    'property.Type of moving average': 'نوع المتوسط المتحرك',
    'property.Type of Moving Average': 'نوع المتوسط المتحرك',
    'property.Typical': 'مثالي',
    'property.Upband deviation in %': 'انحراف الشريط الصاعد في %',
    'property.Update': 'تحديث',
    'property.UseCustomYMarkings': 'تخصيصي الشبكة',
    'property.user': 'الدخول',
    'property.useSSL': 'Secure',
    'property.Value of confidence interval': 'قيمة فاصل الثقة',
    'property.ValueColors': 'ألوان القيمة',
    'property.VATypes.Horizontal': 'أفقي',
    'property.VATypes.None': 'لا يوجد',
    'property.VATypes.ToNextProfile': 'إلى الملف التالي',
    'property.VATypes.Vertical': 'عمودي',
    'property.vendorName': 'المزود',
    'property.View': 'العرض',
    'property.ViewSeparatorGroup': 'عرض',
    'property.Visibility': 'Visibility',
    'property.VisibilitySeparatorGroup': 'الرؤية',
    'property.Visible': 'اظهار',
    'property.Visual Trading': 'التداول المرئي',
    'property.Volume': 'أشرطة الكمية ',
    'property.Volume.Font': 'الخط',
    'property.Volume.FontColor': 'لون الخط',
    'property.Volume.UseTicksData': 'استخدام حركات الأسعار ضد الحجم',
    'property.VolumeColoringMode': 'نظام تلوين الحجم',
    'property.VolumeDownColor': 'سلبي',
    'property.VolumeFixedColor': 'يساوي',
    'property.VolumeHeightPercent': 'الارتفاع، %',
    'property.VolumeMode.BuyVolume': 'حجم الشراء',
    'property.VolumeMode.Delta': 'دلتا',
    'property.VolumeMode.MaxTickVolume': 'حجم حركة السعر الأقصى',
    'property.VolumeMode.SellVolume': 'حجم البيع',
    'property.VolumeMode.TotalVolume': 'الحجم الإجمالي',
    'property.VolumeMode.Trades': 'التداولات',
    'property.VolumeOpacityPercent': 'الشفافية',
    'property.VolumeProfile': 'الملف الشخصي للحجم',
    'property.VolumeUpColor': 'إيجابي',
    'property.VWAP': 'VWAP',
    'property.Warnings': 'التحذيرات',
    'property.WatermarkColor': '',
    'property.WeekSeparators': 'فواصل الأسبوع',
    'property.Weighted': 'متوازن',
    'property.wickColor': 'فتيلة',
    'property.width': 'العرض:',
    'property.Win num': 'رقم الربح للمؤشر على الرسم البياني',
    'property.Win num.addWin': 'نافذة متفرعة',
    'property.Win num.main': 'رئيسي',
    'property.Y factor': 'معامل Y',
    'property.YearSeparators': 'فواصل السنة',
    'property.ZeroLine': 'خط الصفر',
    'property.ZoomValue': 'خطوة المقياس',
    'propery.MainPriceRenderer.Down': 'هابط',
    'propery.MainPriceRenderer.Negative': 'سلبي',
    'propery.MainPriceRenderer.Positive': 'إيجابي',
    'propery.MainPriceRenderer.Up': 'عالي',
    'Relative Strength Index': 'مؤشر القوة النسبي',
    'reports.#subgroup.Revenue plan.0': 'العمولات',
    'reports.#subgroup.Revenue plan.1': 'فروق الأسعار (إنتشارات)',
    'reports.#subgroup.Revenue plan.2': 'المبادلة',
    'reports.#subgroup.Revenue plan.3': 'الخصومات',
    'reports.#subgroup.Revenue plan.4': 'أجزاء الرمز',
    'reports.$broker': 'وسيط',
    'reports.$broker_create_order': 'أمر إنشاء وسيط',
    'reports.$broker_refuse_with_answer': 'رفض الوسيط مع جواب',
    'reports.$client': 'عميل',
    'reports.$client_request': 'طلب عميل',
    'reports.$close_position': 'إغلاق مركز',
    'reports.$confirm_order_removed': 'تأكيد حذف الأمر',
    'reports.$enter_system': 'الدخول للنظام',
    'reports.$executed': 'منفذ',
    'reports.$leave_system': 'الخروج من النظام',
    'reports.$open_position': 'فتح مركز',
    'reports.$order': 'أمر',
    'reports.$request_order_removed': 'حذف الأمر المطلوب',
    'reports.4CastNews': '4CastNews',
    'reports.Accepted': 'تم القبول',
    'reports.accname': 'اسم الحساب',
    'reports.accoperreqstatus': 'الحالة',
    'reports.account': 'الحساب',
    'reports.Account': 'الحساب',
    'reports.Account Name': 'اسم الحساب',
    'reports.Account operation confirmation.': 'تأكيد عملية الحساب',
    'reports.Account Operation Report': 'تقرير عملية الحساب',
    'reports.Account operation report (settled)': 'تقرير عملية الحساب (مستقر)',
    'reports.Account Operation Report Summary': 'ملخص تقرير عملية الحساب',
    'reports.Account operations report': 'تقرير عمليات الحساب',
    'reports.Account Operations Report': 'تقرير عمليات الحساب',
    'reports.Account Performance Report': 'تقرير أداء الحساب',
    'reports.Account Statement': 'تقرير حالة الحساب',
    'reports.Account statement in defined currency report': 'تقرير الحساب بالعملة المختارة ',
    'reports.Account Statement on chosen moment report': 'تقرير الحساب في اللحظة المختارة ',
    'reports.Account Statement Report': 'تقرير حالة الحساب',
    'reports.account_ID': 'رقم تعريف الحساب',
    'reports.account.balance': 'الرصيد',
    'reports.account.crossinstrument': 'رمز متقاطع',
    'reports.account.currency': 'العملة',
    'reports.account.ismaster': 'رئيسي',
    'reports.account.mirroraccounts': 'الحسابات العكسية',
    'reports.account.name': 'اسم الحساب',
    'reports.account.status': 'الحالة',
    'reports.account.type': 'النوع',
    'reports.account.userid': 'رقم المستخدم',
    'reports.account.visibility.editforsuper': 'حرر للمجموعة الممتازة',
    'reports.account.visibility.editforthis': 'التعديل في هذه الناجحة',
    'reports.account.visibility.tradeforsuper': 'التداول للمجموعة الناجحة',
    'reports.account.visibility.tradeforthis': 'التداول لهذه المجموعة',
    'reports.account.visibility.viewforsuper': 'العرض للمجموعة الناجحة',
    'reports.account.visibility.viewforthis': 'العرض لهذه المجموعة',
    'reports.accountcurrency': 'العملة',
    'reports.AccountId': 'الحساب',
    'reports.accountname': 'الحساب',
    'reports.AccountOperationAmount': 'الكمية',
    'reports.accountoperationid': 'رقم عملية الحساب',
    'reports.accountPassword': 'كلمة مرور الحساب',
    'reports.accounts': 'الحسابات',
    'reports.accountUsername': 'اسم الحساب',
    'reports.AccruedInterest': 'فوائد مجمعة ',
    'reports.ACM Feed': 'ACM Feed',
    'reports.action': 'جانب',
    'reports.actionname': 'اسم الإجراء',
    'reports.Activated': '',
    'reports.Active Orders Report': 'تقرير بالأوامر النشطة',
    'reports.actualprice': 'السعر الحقيقي',
    'reports.actualtime': 'الوقت الحقيقي',
    'reports.addliquidityrebate': 'إضافة خصم السيولة',
    'reports.address': 'العنوان',
    'reports.Adjusment': 'تعديل',
    'reports.Adjustment': 'تعديلات ',
    'reports.ADMINISTRATION_RULES': 'قواعد الإدارة',
    'reports.AK Equity Algo': 'AK Equity Algo',
    'reports.AK Genex Equity': 'AK Genex Equity',
    'reports.AK Genex Turdex': 'AK Genex Turdex',
    'reports.alertNotify': 'إشعار التنبيه',
    'reports.All brokers': 'جميع الوسطاء',
    'reports.All data received.': 'تم استلام جميع البيانات ',
    'reports.ALLOW_AUTO_APPROVE_TRANSFER_REQUEST': 'السماح بالتأكيد التلقائي لطلبات التحويل',
    'reports.ALLOW_AUTO_ORDER_TYPE': 'تلقائيا تعين نوع الأمر',
    'reports.ALLOW_AUTO_RESET_PWD': 'السماح بإعادة ضبط كلمة المرور تلقائياً',
    'reports.ALLOW_CLOSE_POSITIONS_ON_STOP_OUT': 'إغلاق المراكز عند الخروج',
    'reports.ALLOW_DEMO_AUTO_REGISTRATION': 'السماح بالتسجيل التلقائي للمستخدم التجريبي',
    'reports.ALLOW_GENERATE_L3': 'السماح بإنشاء L3',
    'reports.ALLOW_GENERATE_PRICE_FROM_SPOT': 'السماح بإنشاء سعر من النقطة',
    'reports.ALLOW_HEDGE_BY_SPOT': 'الحماية بالنقطة',
    'reports.ALLOW_HEDGE_MARGIN': 'السماح بحماية الهامش',
    'reports.ALLOW_JOIN_POSITIONS': 'الانضمام لمركز ذو جانب واحد..',
    'reports.ALLOW_LAZY_LOAD_QUOTES': 'Allow layzy quotes load',
    'reports.ALLOW_LIVE_AUTO_REGISTRATION': 'السماح بالتسجيل المباشر التلقائي',
    'reports.ALLOW_ONE_BROKER_FOR_ALL_OPERATION': 'السماح بوسيط واحد لكل العمليات',
    'reports.ALLOW_ORDER_AUTOEXECUTION_IS_MARKET_PRICE': 'تنفيذ الأوامر بسعر السوق (إذا كان سع الأمر غير صحيح)',
    'reports.ALLOW_PRICE_EXECUTION_MAPPING': 'رسم خريطة تنفيذ السعر',
    'reports.ALLOW_PWD_VERIFICATION': 'تأكيد كلمة المرور برسالة قصيرة',
    'reports.ALLOW_REFUSE_IF_BROKER_OFFLINE': 'السماح بالرفض إذا كان الوسيط غير متصل',
    'reports.ALLOW_REMOVE_ORDERS_ON_STOP_OUT': 'حذف الأوامر عند الخروج',
    'reports.ALLOW_SAVE_HISTORY': 'حفظ سجّل العروض للرمز',
    'reports.ALLOW_SAVE_QUOTE_LOGS': 'السماح بحفظ سجّلات العروض',
    'reports.ALLOW_SAVE_QUOTE_TICKS': 'السماح بحفظ حركات العروض',
    'reports.ALLOW_SAVE_QUOTES': 'السماح بحفظ العروض',
    'reports.ALLOW_SAVE_TRADE_LOGS': 'السماح بحفظ سجّلا التداولات',
    'reports.ALLOW_SHORT_POSITIONS': 'السماء بالمراكز القصيرة',
    'reports.ALLOW_SKIP_REVERSED_PRICE': 'تجاهل جميع العروض "المعكوسة"',
    'reports.ALLOW_SPIKE_FILTER': 'السماح بفلتر الشوكة',
    'reports.ALLOW_STOP_AVARE_MARGIN': 'استخدام هامش إدراك الإيقاف',
    'reports.ALLOW_STORE_USERS_FILES': 'السماح بتخزين ملفات المستخدم',
    'reports.ALLOW_USE_CERT': 'السماح باستخدام طلب تسجيل الدخول',
    'reports.ALLOW_USER_LEVERIGE': 'السماح برافعة المستخدم',
    'reports.ALLOW_USERS_EMAIL_DUPLICATE': 'السماح بتكرار البريد الإلكتروني للحسابات التجريبية',
    'reports.ALLOW_VISIBLITY': 'السماح بالرؤية',
    'reports.allowLimitOrders': 'السماح بأوامر سعر محدد',
    'reports.allowStopOrders': 'السماح بأوامر الإيقاف',
    'reports.already in processing': 'جاري التنفيذ بالفعل',
    'reports.amount': 'الكمية',
    'reports.Amount': 'الكمية',
    'reports.Amount filled': 'الكمية المنفذة',
    'reports.amountperside': 'الكمية لكل جانب',
    'reports.An error occurred during sending mail using user settings. Will use default settings.': 'حدث خطأ خلال إرسال بريد باستخدام إعدادات المستخدم. سيتم استخدام الإعدادات الافتراضية.',
    'reports.Aphelion': 'الاوج',
    'reports.APPLICATION.NAME': 'اسم التطبيق',
    'reports.Approved': 'موافق عليه',
    'reports.asset': 'أصل',
    'reports.AuditReport': 'تقرير التغيرات في المكتب الرئيسي',
    'reports.Authorization Error: Operation is nor allowed': 'خطأ في التصديق: غير مسموح بالعملية',
    'reports.autotrade': 'التداول التلقائي',
    'reports.Average filled price': 'السعر',
    'reports.Back Office Changes Report': 'تقرير التغيرات في المكتب الرئيسي',
    'reports.balance': 'الرصيد',
    'reports.Balance': 'الرصيد',
    'reports.Balance by Instrument Report': 'الرصيد بتقرير الرمز ',
    'reports.Balance By Instrument Report': 'تقرير الرصيد بحسب الرمز',
    'reports.Balance Operations Report': 'تقرير عمليات الرصيد',
    'reports.Balance Report': 'تقرير الرصيد',
    'reports.Balance Report(copy)': 'تقرير الرصيد (نسخة)',
    'reports.Balance Summary report': 'تقرير ملخص الرصيد',
    'reports.Balance Summary Report': 'تقرير ملخص الرصيد',
    'reports.balance_after': 'الرصيد بعد',
    'reports.balance_before': 'الرصيد قبل',
    'reports.balance.amount': 'الكمية',
    'reports.balance.buy': 'شراء',
    'reports.balance.everbuy': 'متوسط الشراء',
    'reports.balance.eversell': 'متوسط البيع',
    'reports.balance.exposure': 'التعرض',
    'reports.balance.exposurenet': 'تعرض الصافي',
    'reports.balance.grossexposure': 'إجمالي التعرض',
    'reports.balance.grosspl': 'إجمالي ر/خ',
    'reports.balance.grossvalue': 'قيمة إجمالي المراكز',
    'reports.balance.net': 'الصافي',
    'reports.balance.netexposure': 'صافي التعرض',
    'reports.balance.netvalue': 'قيمة صافي المراكز',
    'reports.balance.profit': 'الربح',
    'reports.balance.sell': 'بيع',
    'reports.Barclays': 'باركليز',
    'reports.basis': 'تعليق',
    'reports.Basis': 'تعليق',
    'reports.because Not enoght margin to create Order': 'لا يوجد هامش كافي لفتح الطلب',
    'reports.because Price for Stop limit order is incorrect.': 'لأن سعر الإيقاف المحدد غير صحيح',
    'reports.BeginString': 'بدء السلسلة',
    'reports.blocked_funds': 'الأموال المغلقة (الهامش)',
    'reports.blockedbalance': 'الرصيد المغلق',
    'reports.Blocking': 'إغلاق',
    'reports.bmr_brokermessageid': 'رقم الرسالة',
    'reports.bmr_brokermessagename': 'اسم الرسالة',
    'reports.bmr_confirmtime': 'وقت الهدف',
    'reports.bmr_targetuserid': 'رقم المستخدم',
    'reports.bmr_targetuserlogin': 'المستخدم',
    'reports.Bound To': 'مرتبط بـ',
    'reports.Bound to order': 'مرتبط بالأمر',
    'reports.boundtoorderid': 'رقم الأمر المرتبط',
    'reports.BRANDING_ADMIN_HELP': 'رباط مساعدة المدير',
    'reports.BRANDING_ANONYM_GROUP': 'وضع المستخدمون الغير معروفون المسجلون تلقائياً في',
    'reports.BRANDING_ANONYM_GROUP_SUPPORT': 'مجموعة الدعم للمستخدمين الغير معروفين',
    'reports.BRANDING_CLIENT_MINIMAL_VERSION': 'الإصدار الأقل للعميل (للتحديث الإجباري)',
    'reports.BRANDING_COMMON_RULES': 'منتشر',
    'reports.BRANDING_COMPANY_DESC': 'وصف شركة الاسم التجاري',
    'reports.BRANDING_COMPANY_INFO_RULES': 'معلومات الشركة',
    'reports.BRANDING_COMPANY_NAME': 'اسم الشركة',
    'reports.BRANDING_COMPANY_URL': 'رابط الشركة',
    'reports.BRANDING_DEMO_GROUP': 'وضع المستخدمون التجريبيون تلقائياً في',
    'reports.BRANDING_DEMOREGISTRATION_URL': 'التسجيل التجريبي (إخلاء هذا السيرفر؛0-معطل؛ رابط صفحة الويب)',
    'reports.BRANDING_DEPOSIT_URL': 'رابط الإيداع (المسح للتعطيل، رابط الصفحة)',
    'reports.BRANDING_DISCLAMER': 'نص التنويه (معروض في طبقة تسجيل الدخول 0)',
    'reports.BRANDING_DOWJONES_ENCRYPTED_TOKEN': 'الرمز المشفر لداون جونز',
    'reports.BRANDING_EMAIL': 'البريد الإلكتروني للشركة',
    'reports.BRANDING_EXT_LINK_RULES': 'روابط خارجية',
    'reports.BRANDING_FORGOTPWD_URL': 'رابط نسيت كلمة مرور',
    'reports.BRANDING_HTML_FOOTER': 'صورة ذيل الصفحة',
    'reports.BRANDING_HTML_LOGINSCREEN_LOGO': 'صورة شاشة تسجيل الدخول',
    'reports.BRANDING_HTML_LOGO': 'صورة الشعار',
    'reports.BRANDING_HTML_NAME': 'اسم التطبيق',
    'reports.BRANDING_HTML_STATION_RULES': 'Back office',
    'reports.BRANDING_INFO_MESSAGE': 'مربع الرسالة (تفتح في المحطة بعد تسجيل الدخول)',
    'reports.BRANDING_KEY': 'مفتاح العلامة التجارية (تستخدم لربط المثبت بها)',
    'reports.BRANDING_KNOWLEDGE_BASE_URL': 'رابط قاعة البيانات (-1 لتعطيلها)',
    'reports.BRANDING_LANG_SET': 'مصادر اللغة',
    'reports.BRANDING_LIVE_GROUP': 'وضع المستخدمون المباشرون تلقائياً في',
    'reports.BRANDING_LOGIN_MESSAGE': 'رسالة التنبيه لشاشة تسجيل الدخول',
    'reports.BRANDING_MAIL_CONFIGURATION': 'تهيئة البريد',
    'reports.BRANDING_MAIL_PATTERN': 'النمط',
    'reports.BRANDING_NET_BACKGROUND': 'صورة الخلفية (اختياري/bmp بأي حجم)',
    'reports.BRANDING_NET_BANNER': 'صورة الإعلان (.bmp 500x36)',
    'reports.BRANDING_NET_BROKERIMAGE': 'صورة التقرير (.bmp بأي حجم)',
    'reports.BRANDING_NET_CONFIGURAION': 'الإعدادات الافتراضية (ملف program.ini)',
    'reports.BRANDING_NET_DEFWORKSPACE': 'مكان العمل الافتراضي (ملف *.wrk)',
    'reports.BRANDING_NET_FX_STYLE': 'غلاف خلية الفوركس (ادخل الكود)',
    'reports.BRANDING_NET_HELP': 'رابط المساعدة',
    'reports.BRANDING_NET_ICON': 'صورة الرمز (.ico 16x16+32x32)',
    'reports.BRANDING_NET_INSTALLER_BANNER_IMAGE': 'صورة مثبت الإعلان (500*59)',
    'reports.BRANDING_NET_INSTALLER_DIALOG_IMAGE': 'صورة مثبت المحاورة (500*316)',
    'reports.BRANDING_NET_LINK': 'رابط التطبيق',
    'reports.BRANDING_NET_LOADING_IMAGE': 'تحميل صورة *.gif',
    'reports.BRANDING_NET_LOGIN_STYLE': 'غلاف شاشة تسجيل الدخول (ادخل الكود)',
    'reports.BRANDING_NET_LOGO': 'صورة الشعار (.bmp 500×35)',
    'reports.BRANDING_NET_NAME': 'Application name (for app data',
    'reports.BRANDING_NET_OE_STYLE': 'غلاف إدخال الأوامر (ادخل الكود)',
    'reports.BRANDING_NET_RIBBONICON': 'صورة رمز الشريط (.ico 32×32)',
    'reports.BRANDING_NET_STATION_RULES': 'محطة .Net',
    'reports.BRANDING_NET_USE_FINGERPRINT': 'بصمة المستخدم',
    'reports.BRANDING_PHONE': 'هاتف الشركة',
    'reports.BRANDING_POWERED_BY': 'مقدّم من (اسم الشخص أو الشركة)',
    'reports.BRANDING_PROPERTIES_RULES': 'خصائص الاسم التجاري',
    'reports.BRANDING_RULES': 'مرئية الاسم التجاري',
    'reports.BRANDING_SCRIPT_URL': 'رابط النص',
    'reports.BRANDING_SERVER_URL': 'راط سيرفر الاسم التجاري (رابط أو أي بي لرابط الصفحة الأولى قبل تسجيل الدخول)',
    'reports.BRANDING_STATUS_MESSAGE': 'رسالة شريط الحالة (يعرض في محطة شريط الحالة)',
    'reports.BRANDING_THEME': 'الشكل والمظهر',
    'reports.BRANDING_TOOLS_RULES': 'روابط الويب الخارجية في قائمة الادوات',
    'reports.BRANDING_TTP_FILE': 'قوالب اللوحة',
    'reports.BRANDING_USERS_RULES': 'قواعد المستخدم',
    'reports.BRANDING_WITHDRAW_URL': 'رابط للسحب (فارخ للتعطيب، رابط للصفحة الأولى، صيغة 1 -win(',
    'reports.branding.name': 'الاسم',
    'reports.Broker Message Report': 'تقرير رسالة الوسيط',
    'reports.Brokerage': 'الوساطة',
    'reports.brokerCancel': 'إلغاء الوسيط',
    'reports.brokerlogin': 'تسجيل دخول الوسيط',
    'reports.Buy': 'شراء',
    'reports.buy trade amount': 'بحسب حجم التداول',
    'reports.buy trades': 'بحسب التداولات',
    'reports.buyselltype': 'نوع البيع/الشراء',
    "reports.Can't allocate route for trade using hadget": 'لا يمكن تخصيص المسار للتداول باستخدام hadget',
    "reports.Can't close entry": 'لا يمكن إغلاق الإدخال',
    'reports.Cancel order:': 'إلغاء الأمر:',
    'reports.Cancel request accepted by broker': 'تم قبول طلب الإلغاء من الوسيط',
    'reports.Cancel request received by STP': 'تم استقبال طلب الإلغاء من التفيذ المباشر',
    'reports.Canceled': 'ملغى',
    'reports.Canceling': 'ملغى',
    'reports.Cancelled': 'ملغى',
    'reports.Cancelling': 'ملغى',
    'reports.CantorFX': 'CantorFX',
    'reports.Cash Deposit And Withdrawal Report': 'تقرير سحب وإيداع الأموال',
    'reports.cash_posted': 'تم إضافة النقد',
    'reports.Champ stats report': 'تقرير إحصائيات البطولة',
    'reports.change': 'التغيّر',
    'reports.Change password error, new password must differ from old one': 'يحب أن تكون كلمة السر الجديدة مختلفة عن القديمة',
    'reports.CHART_OE': 'الرسم البياني (البناء في مدخل الأوامر)',
    'reports.CHART_VISUAL': 'الرسم البياني (تداول مرئي)',
    'reports.Chat History Report': 'تقرير سجّل الرسم البياني',
    'reports.CheckCompID': 'التحقق من رقم الشركة',
    'reports.CheckLatency': 'التحقق من التأخير',
    'reports.ChoiceFX': 'ChoiceFX',
    'reports.CIBC': 'CIBC',
    'reports.Citi': 'Citi',
    'reports.ClearingVmTransfer': 'مسح تحويل الهامش المتغير',
    'reports.clfr_close_date': 'تاريخ الإغلاق',
    'reports.clfr_fund_name': 'اسم الصندوق',
    'reports.clientid': 'رقم العميل',
    'reports.clienttype': 'نوع العميل',
    'reports.Close Limit order created': 'إغلاق أمر المحدد الذي تم إنشاؤه',
    'reports.Close Limit order created is off market': '',
    'reports.Close Limit order created is unplaced': '',
    'reports.Close Limit order received by broker': 'إغلاق أمر المحدد الذي ورد من الوسيط',
    'reports.Close Market order created': 'إغلاق أمر السوق ',
    'reports.Close Market Order Created': 'إغلاق أمر السوق ',
    'reports.Close Market order created is off market': '',
    'reports.Close Market order created is unplaced': '',
    'reports.Close Market order received by broker': 'إغلاق أمر السوق الذي ورد من الوسيط',
    'reports.Close OCO order created': 'إغلاق أمر أمر يلغي الآخر الذي تم إنشاؤه',
    'reports.Close OCO order created is off market': '',
    'reports.Close OCO order created is unplaced': '',
    'reports.Close OCO order received by broker': 'إغلاق أمر أمر يلغي الآخر الذي ورد من الوسيط',
    'reports.Close order type': 'إغلاق نوع الأمر',
    'reports.Close position:': 'إغلاق المركز:',
    'reports.Close Sell': 'إغلاق البيع',
    'reports.Close SL order created': 'إغلاق أمر إ.خ الذي تم إنشاؤه',
    'reports.Close SL order created is off market': '',
    'reports.Close SL order created is unplaced': '',
    'reports.Close SL order received by broker': 'إغلاق أمر إ.خ الذي ورد من الوسيط',
    'reports.Close SL tr. stop order created': 'إغلاق أمر الإيقاف المتحرك إ.خ الذي تم إنشاؤه',
    'reports.Close SL tr. stop order created is off market': '',
    'reports.Close SL tr. stop order created is unplaced': '',
    'reports.Close SL tr. stop order received by broker': 'إغلاق أمر الإيقاف المتحرك إ.خ الذي ورد من الوسيط',
    'reports.Close Stop Limit order created': 'إغلاق أمر الإيقاف المحدد الذي تم إنشاؤه',
    'reports.Close Stop Limit order created is off market': '',
    'reports.Close Stop Limit order created is unplaced': '',
    'reports.Close Stop Limit order received by broker': 'إغلاق أمر الإيقاف المحدد الذي ورد من الوسيط',
    'reports.Close Stop order created': 'إغلاق أمر الإيقاف الذي تم إنشاؤه',
    'reports.Close Stop order created is off market': '',
    'reports.Close Stop order created is unplaced': '',
    'reports.Close Stop order received by broker': 'إغلاق أمر الإيقاف الذي ورد من الوسيط',
    'reports.Close TP order created': 'إغلاق أمر أ.ر الذي تم إنشاؤه',
    'reports.Close TP order created is off market': '',
    'reports.Close TP order created is unplaced': '',
    'reports.Close TP order received by broker': 'إغلاق أمر أ.ر الذي ورد من الوسيط',
    'reports.Close Tr. stop order created': 'إغلاق أمر الإيقاف المتحرك الذي تم إنشاؤه',
    'reports.Close Tr. stop order created is off market': '',
    'reports.Close Tr. stop order created is unplaced': '',
    'reports.Close Tr. stop order received by broker': 'إغلاق أمر الإيقاف المتحرك الذي ,ورد من الوسيط',
    'reports.CLOSE_ALL': 'إغلاق الكل',
    'reports.CLOSE_ALL_ORDERS': 'إلغاء جميع الأوامر',
    'reports.CLOSE_ALL_POSITIONS': 'إغلاق جميع المراكز',
    'reports.CLOSE_FIFO': 'إغلاق المراكز في حال عدم وجود هامش كافي (الوارد أولاً)',
    'reports.CLOSE_LAGES': 'إغلاق المراكز في حال عدم وجود هامش كافي (الأكبر أولاً)',
    'reports.CLOSE_LIFO': 'إغلاق المراكز في حال عدم وجود هامش كافي (الوارد أخيراً)',
    'reports.closebytradeid': 'الإغلاق بحسب رقم الصفقة ',
    'reports.Closed funds report': 'تقرير الصناديق المغلفة',
    'reports.Closed Positions': 'تقرير المراكز المغلقة',
    'reports.closedat': 'إغلاق',
    'reports.closedbyorderid': 'الإغلاق بحسب رقم الأمر',
    'reports.ClosedResendInterval': 'الفواصل بين إعادة الإرسال المغلقة',
    'reports.CloseOptionPremium': 'إغلاق علاوت الخيار',
    'reports.Closeposition': 'إغلاق المركز',
    'reports.closeprice': 'سعر الإغلاق',
    'reports.closetime': 'وقت الإغلاق',
    'reports.comission': 'الرسوم',
    'reports.Comment': 'تعليق',
    'reports.comments': 'تعليقات',
    'reports.comments.CancelOperation': 'إلغاء عملية #',
    'reports.COMMISIONSPLAN_RULES': 'مرئية خطط الربح',
    'reports.commission': 'العمولة',
    'reports.commission_out': 'الرسوم',
    'reports.commissionpertrade': 'العمولة لكل تداول',
    'reports.commissions': 'الرسوم',
    'reports.Commissions': 'الرسوم',
    'reports.Commissions report': 'تقرير الرسوم',
    'reports.Commissions Report': 'تقرير العمولات',
    'reports.Commissions Report(copy)': 'تقرير العمولات (نسخة)',
    'reports.commissiontotal': 'إجمالي العمولات',
    'reports.COMMON_RULES': 'القواعد المشهورة',
    'reports.Community': 'المجتمع',
    'reports.ConfigFilePath': 'إعداد مسار الملف',
    'reports.Connected': 'متصل',
    'reports.Connection established': 'تم الإتصال',
    'reports.Connections Report': 'تقرير الاتصالات',
    'reports.ConnectionType': 'نوع الاتصال',
    'reports.connrp_conntime': 'محاولة وقت الاتصال',
    'reports.connrp_fullname': 'الاسم والاسم الأخير',
    'reports.connrp_ip': 'IP',
    'reports.connrp_lastpwdchange': 'آخر تغيير لكلمة المرور',
    'reports.connrp_login': 'اسم المستخدم',
    'reports.connrp_success': 'نجاح الإتصال',
    'reports.ContinueInitializationOnError': 'متابعة التثبيت عند حدوث خطأ',
    'reports.Contract Specification Report': 'تقرير خصائص التعاقد',
    'reports.Counter Account Name': 'اسم الحساب المعاكس',
    'reports.Counter Operation Id': 'رقم العملية المعاكسة',
    'reports.COUNTERACCOUNTS_RULES': 'الحسابات المعاكسة',
    'reports.Created': 'تم الإنشاء',
    'reports.Created by': 'تم الإنشاء بواسطة',
    'reports.createdat': 'تم الإنشاء عند',
    'reports.Creating': 'تم الإنشاء',
    'reports.creator': 'المنشئ',
    'reports.credit': 'ائتمان',
    'reports.Credit': 'Credit',
    'reports.crossprice': 'السعر المتقابل',
    'reports.crosstype': 'نوع التقاطع',
    'reports.currency': 'العملة',
    'reports.Currency Exposure Report': 'تقرير تعرّض العملة',
    'reports.Currency summary exposure': 'تعرض ملخص العملة',
    'reports.currency.balance_interest': 'فائدة الرصيد(%)',
    'reports.currency.interest_buy_rate': 'الفائدة على الإيجابي (%)',
    'reports.currency.interest_sell_rate': 'الفائدة على السلبي(%)',
    'reports.currency.leverage_negative': 'رافعة التعرض السلبي',
    'reports.currency.leverage_positive': 'رافعة التعرض الإيجابي',
    'reports.currency.min_change': 'التغيّر الأدنى',
    'reports.currency.name': 'الاسم',
    'reports.Currenex': 'Currenex',
    'reports.CurrenexFIX': 'CurrenexFIX',
    'reports.Current Balance': 'الرصيد الحالي',
    'reports.currentprice': 'السعر الحالي',
    'reports.CUSTOM_COLUMN_BTN_SCRIPT': 'تخصيص العمود (النص السفلي)',
    'reports.customReport': 'تخصيص التقارير',
    'reports.DataDictionary': 'قاموس البيانات',
    'reports.DateTime': 'التاريخ/الوقت',
    'reports.days': 'أيام',
    'reports.daystillexpiration': 'الأيام حتى الانتهاء',
    'reports.DBFX': 'DBFX',
    'reports.dealercommission': 'عمولة الوسيط',
    'reports.debit': 'الدائن',
    'reports.Default settings failed': 'فشل الإعدادات الافتراضية',
    'reports.Delayed Limit Order Created': 'تم إنشاء أمر سعر محدد معلق',
    'reports.Delayed Limit Order Modified': 'تم تعديل أمر محدد معلق',
    'reports.Delayed Limit Order Removed': 'تم حذف أمر حد معلق',
    'reports.Delayed OCO Order Created': 'تم إنشاء أمر حد معلق',
    'reports.Delayed Stop Limit Order Created': 'تم إنشاء أمر إقاف محدد',
    'reports.Delayed Stop Limit Order Modified': 'تم تعديل أمر إقاف محدد',
    'reports.Delayed Stop Limit Order Removed': 'تم حذف أمر إقاف محدد',
    'reports.Delayed Stop Order Created': 'تم إنشاء أمر إيقاف معلق',
    'reports.Delayed Stop Order Modified': 'تم تعديل أمر إيقاف معلق',
    'reports.Delayed Stop Order Removed': 'تم حذف أمر إيقاف معلق',
    'reports.Delayed Trailing Stop Order Created': 'تم إنشاء أمر إيقاف متحرك',
    'reports.Delayed Trailing Stop Order Modified': 'تم تعديل أمر إيقاف متحرك',
    'reports.Delayed Trailing Stop Order Removed': 'تم حذف أمر إيقاف متحرك',
    'reports.DelayedCharging': 'شحن مأجل',
    'reports.DelayedDataCharging': 'شحن بيانات مأجل',
    'reports.delivered': 'تم التوصيل',
    'reports.DeliveredForward': 'تم التوصيل إلى الامام',
    'reports.Delivery': 'التوصيل',
    'reports.demoMode': 'الوضع التجريبي',
    'reports.deposit': 'الإيداع',
    'reports.Deposit': 'الإيداع',
    'reports.Detail': 'تفاصيل',
    'reports.Deutsche Bank': 'Deutsche Bank',
    'reports.DeutscheBankFIXFeed': 'DeutscheBankFIXFeed',
    'reports.DGCX': 'DGCX',
    'reports.Dictionary': 'القاموس',
    'reports.discount': 'التخفيض',
    'reports.dividends': 'توزيعات الارباح',
    'reports.DO_NOT_CLOSE': 'لا تغلق المراكز',
    'reports.DukasCopy': 'DukasCopy',
    'reports.DWT': 'DWT',
    'reports.email': 'البريد الإلكتروني',
    'reports.EMAIL_NOTIFICATION': 'وضوح نمط إشعارات البريدج الإلكتروني',
    'reports.EMAIL_RULES': 'وضوح نمط إشعارات البريدج الإلكتروني',
    'reports.EndDay': 'نهاية اليوم ',
    'reports.EndTime': 'نهاية الوقت ',
    'reports.entityId': 'رقم المدخل',
    'reports.enum_StopOutType_AutoClose': 'إغلاق تلقائي',
    'reports.enum_StopOutType_CloseAllByInstruments': 'إغلاق الكل بحسل الاداة',
    'reports.enum_StopOutType_CloseAllPositions': 'إغلاق جميع المراكز',
    'reports.enum_StopOutType_CloseLagestLoss': 'إغلاق المراكز عندما لا يكون الهامش كافي (أكبر خسارة أولاً)',
    'reports.enum_StopOutType_ClosePositionsByMarginExcess': 'إغلاق المراكز عند تعدي الهامش',
    'reports.enum_StopOutType_DoNotClosePositions': 'لا تغلق المراكز',
    'reports.enum_StopOutType_FIFO': 'إغلاق المراكز في حال عدم وجود هامش كافي (الوارد أولاً)',
    'reports.enum_StopOutType_LargestFirst': 'إغلاق المراكز في حال عدم وجود هامش كافي (الأكبر أولاً)',
    'reports.enum_StopOutType_LIFO': 'إغلاق المراكز في حال عدم وجود هامش كافي (الوارد أخيراً)',
    'reports.Error occured during downloading update:': 'خطأ عند تحميل التحديث',
    'reports.Error sending email': 'خطأ في إرسال البريد الإلكتروني',
    'reports.Error: Not enough Margin to open position': 'خطأ: لا يوجد هامش كافي لفتح مركز',
    'reports.error.certificate_corrupted': 'شهادة المستخدم معطوبة.',
    'reports.error.certificate_not_found': 'شهادة المستخدم ي موجودة.',
    'reports.error.error_during_saving_route': 'حدث خطأ خلال حفظ المسار. الرجاء الاتصال بالإدارة.',
    'reports.error.fill_mandatory': 'الرجاء ملئ جميع الحقول المطلوبة.',
    'reports.error.name_is_in_use': 'هذا الاسم موجود بالفعل!',
    'reports.error.not_valid_email': 'البريد الإلكتروني غير صحيح.',
    'reports.error.not_valid_firstname': 'الاسم الأول غير صحيح.',
    'reports.error.not_valid_instrumentname_capital': 'الاسم غير صحيح. أحرف كبيرة فقط.',
    'reports.error.not_valid_instrumentname_exist': 'الاسم غير صحيح. هذا الاسم موجود بالفعل.',
    'reports.error.not_valid_instrumentname_length': 'الاسم غير صحيح. لا يمكن أن يكون الاسم أطول من 32 حرف.',
    'reports.error.not_valid_instrumenttypename': 'نوع الأداة غير صحيحة. الرجا تحديد نوع الأداة الصحيح.',
    'reports.error.not_valid_lastname': 'الاسم الأخير غير صحيح.',
    'reports.error.not_valid_login': 'اسم المستخدم غير صحيح.',
    'reports.error.not_valid_name': 'الاسم غير صحيح.',
    'reports.error.not_valid_name_exist': 'الاسم غير صحيح. هذا الاسم موجود بالفعل.',
    'reports.error.not_valid_phone_number': 'رقم الهاتف غير صحيح. ادخل بيانات رقمية فقط.',
    'reports.error.photo_not_found': 'الصورة غير موجودة',
    'reports.error.too_short_phone_password': 'كلمة مرور الهاتف قصيرة',
    'reports.error.wrong_percenage': 'نسبة خاطئة',
    'reports.ESignal': 'ESignal',
    'reports.event': 'الحدث',
    'reports.Event History Report': 'تقرير سجّل الأحداث',
    'reports.Event History Report with IP': 'Event history report with IP',
    'reports.Exception': 'استثناء',
    'reports.exchange': 'السوق',
    'reports.Exchange': 'السوق',
    'reports.Executed': 'منفذ',
    'reports.Executed Orders Report': 'تقرير الأوامر المنفذة',
    'reports.executedat': 'تنفيذ',
    'reports.Executing': 'في التنفيذ',
    'reports.executionfee': 'رسوم التنفيذ',
    'reports.executionprice': 'سعر التنفيذ',
    'reports.expiredate': 'تاريخ الانتهاء',
    'reports.expiresat': 'الانهاء',
    'reports.external_order_id': 'معرف أمر خارجي',
    'reports.externalid': 'الرقم الخارجي',
    'reports.externalorderid': 'رقم الأمر الخارجي',
    'reports.extfield': 'حقل ممتد',
    'reports.EXTFIELDS_RULES': 'مرئية لحقول الممتدة',
    'reports.extprice': 'سعر الانتهاء',
    'reports.False': 'خاطئ',
    'reports.fee': 'الرسوم',
    'reports.Fee: [addliquidityrebate]': 'رسوم: [addliquidityrebate]',
    'reports.Fee: [Brokerage]': 'رسوم: [Brokerage]',
    'reports.Fee: [Exchange]': 'رسوم: [Exchange]',
    'reports.Fee: [InvestorProtectionLevy]': 'رسوم: [InvestorProtectionLevy]',
    'reports.Fee: [removeliquidityrebate]': 'رسوم: [removeliquidityrebate]',
    'reports.Fee: [SecuritiesTransferTax]': 'رسوم: [SecuritiesTransferTax]',
    'reports.Fee: [SettlementFee]': 'رسوم: [SettlementFee]',
    'reports.Fee: [ValueAddedTax]': 'رسوم: [ValueAddedTax]',
    'reports.Feed Data': 'بيانات التغذية',
    'reports.Fees Report': 'تقرير الرسوم',
    'reports.FFastFillFIXFeed': 'FFastFillFIXFeed',
    'reports.fiahr_action': 'إجراء',
    'reports.fiahr_amount': 'الكمية',
    'reports.fiahr_capital': 'رأس المال',
    'reports.fiahr_count': 'عدد الحصص',
    'reports.fiahr_date': 'التاريخ',
    'reports.fiahr_fee': 'العائد',
    'reports.fiahr_grosspl': 'إجمالي ر/خ',
    'reports.fiahr_investor_id': 'رقم المستثمر',
    'reports.fiahr_investor_name': 'المستثمر',
    'reports.fiahr_netpl': 'صافي ر/خ',
    'reports.fiahr_pamm_id': 'رقم الصندوق',
    'reports.fiahr_pamm_name': 'الصندوق',
    'reports.fiahr_price': 'السعر',
    'reports.fiahr_requestid': 'رقم تعريف الطلب',
    'reports.Fidessa': 'Fidessa',
    'reports.FileIncludeMilliseconds': 'الملف يشمل ملي ثانية',
    'reports.FileIncludeTimeStampForMessages': 'يشمل الملف الطابع الزمني للرسائل',
    'reports.FileLogHeartbeats': 'ملف السجّل الفوري',
    'reports.Filled': 'منفذ',
    'reports.Filled close Limit order': 'إغلاق أمر محدد منفذ',
    'reports.Filled close Limit order (Mutual)': 'إغلاق أمر محدد منفذ (متبادل)',
    'reports.Filled close Market order': 'إغلاق أمر السوق المنفذ',
    'reports.Filled close Market order (Mutual)': 'إغلاق أمر السوق المنفذ (متبادل)',
    'reports.Filled close OCO order': 'إغلاق أمر أمر يلغي الآخر منفذ',
    'reports.Filled close OCO order (Mutual)': 'إغلاق أمر أمر يلغي الآخر منفذ (متبادل)',
    'reports.Filled close SL order': 'إغلاق أمر إ.خ منفذ',
    'reports.Filled close SL order (Mutual)': 'إغلاق أمر إ.خ منفذ (متبادل)',
    'reports.Filled close SL tr. stop order': 'إغلاق أمر الإيقاف المتحرك إ.خ المنفذ',
    'reports.Filled close SL tr. stop order (Mutual)': 'إغلاق أمر الإيقاف المتحرك إ.خ المنفذ (متبادل)',
    'reports.Filled close Stop Limit order': 'إغلاق أمر حد الإيقاف المنفذ',
    'reports.Filled close Stop Limit order (Mutual)': 'إغلاق أمر حد الإيقاف المنفذ (متبادل)',
    'reports.Filled close Stop order': 'إغلاق أمر الإيقاف المتنفذ',
    'reports.Filled close Stop order (Mutual)': 'إغلاق أمر الإيقاف المتنفذ (متبادل)',
    'reports.Filled close TP order': 'إغلاق أمر أ.ر المتنفذ',
    'reports.Filled close TP order (Mutual)': 'إغلاق أمر أ.ر المتنفذ (متبادل)',
    'reports.Filled close Tr. stop loss order': 'إغلاق أمر الإيقاف المتحرك المتنفذ ',
    'reports.Filled close Tr. stop order': 'إغلاق أمر الإيقاف المتحرك المتنفذ',
    'reports.Filled close Tr. stop order (Mutual)': 'إغلاق أمر الإيقاف المتحرك المتنفذ (متبادل)',
    'reports.Filled Limit order': 'أمر المحدد المتنفذ',
    'reports.Filled Limit order (Mutual)': 'أمر المحدد المتنفذ (متبادل)',
    'reports.Filled Market order': 'أمر السوق منفذ',
    'reports.Filled Market order (Mutual)': 'أمر السوق المنفذ (متبادل)',
    'reports.Filled OCO order': 'أمر أمر يلغي الآخر المنفذ',
    'reports.Filled OCO order (Mutual)': 'أمر أمر يلغي الآخر المنفذ (متبادل)',
    'reports.Filled SL order': 'أمر إ.خ المنفذ',
    'reports.Filled SL order (Mutual)': 'أمر إ.خ المنفذ (متبادل)',
    'reports.Filled SL tr. stop order': 'أمر الإيقاف المتحرك إ.خ المنفذ',
    'reports.Filled SL tr. stop order (Mutual)': 'أمر الإيقاف المتحرك إ.خ المنفذ (متبادل)',
    'reports.Filled Stop Limit order': 'أمر الإيقاف المحدد المنفذ',
    'reports.Filled Stop Limit order (Mutual)': 'أمر الإيقاف المحدد المنفذ (متبادل)',
    'reports.Filled Stop order': 'أمر الإيقاف المنفذ',
    'reports.Filled Stop order (Mutual)': 'أمر الإيقاف المنفذ (متبادل)',
    'reports.Filled TP order': 'أمر أ.ر المنفذ',
    'reports.Filled TP order (Mutual)': 'أمر أ.ر المنفذ (متبادل)',
    'reports.Filled Tr. stop order': 'أمر الإيقاف المتحرك المنفذ',
    'reports.Filled Tr. stop order (Mutual)': 'أمر الإيقاف المتحرك المنفذ (متبادل)',
    'reports.filledamount': 'الكمية المنفذة',
    'reports.fir_exit_capital': 'رأس المال الحالي',
    'reports.fir_exit_price': 'سعر السهم الحالي',
    'reports.fir_investor_id': 'رقم المستثمر',
    'reports.fir_investor_name': 'اسم المستثمر',
    'reports.fir_join_capital': 'رأس المال الأولي',
    'reports.fir_join_date': 'تاريخ الانضمام',
    'reports.fir_join_price': 'سعر الانضمام',
    'reports.fir_pamm_id': 'رقم الصندوق',
    'reports.fir_pamm_name': 'اسم الصندوق',
    'reports.fir_reinvest_cycles': 'دورات إعادة الاستثمار',
    'reports.fir_share_count': '#الأسهم',
    'reports.FirstDerivatives': 'أولالمشتقات',
    'reports.firstname': 'الاسم الأول',
    'reports.FixedFee': '',
    'reports.Foreks': 'فوركس',
    'reports.Forex balances report': 'تقرير رصيد الفوركس',
    'reports.Formal Dialog Report': 'تقرير المحاورة الرسمية',
    'reports.FORTEX': 'FORTEX',
    'reports.fpghr_count': 'الأسهم',
    'reports.fpghr_exit_amount': 'الكمية عند الخروج',
    'reports.fpghr_exit_price': 'السعر عند الخروج',
    'reports.fpghr_fee': 'الرسوم',
    'reports.fpghr_first_join_date': 'تاريخ الانضمام',
    'reports.fpghr_grosspl': 'إجمالي ر/خ',
    'reports.fpghr_investor_id': 'رقم المستثمر',
    'reports.fpghr_investor_name': 'اسم المستثمر',
    'reports.fpghr_join_amount': 'الكمية عند الانضمام',
    'reports.fpghr_join_price': 'سعر الانضمام',
    'reports.fpghr_last_exit_date': 'تاريخ الخروج',
    'reports.fpghr_netpl': 'صافي ر/خ',
    'reports.fpghr_pamm_id': 'رقم الصندوق',
    'reports.fpghr_pamm_name': 'اسم الصندوق',
    'reports.fpghr_period': 'الوقت',
    'reports.fpghr_period_end_date': 'تاريخ الانتهاء',
    'reports.fpghr_period_start_date': 'تاريخ البدء',
    'reports.fprhr_date': 'آخر تحديث',
    'reports.fprhr_details': 'التفاصيل',
    'reports.fprhr_entryid': 'رقم الإدخال',
    'reports.fprhr_event': 'الحدث',
    'reports.fprhr_investor_id': 'رقم المستثمر',
    'reports.fprhr_investor_name': 'المستثمر',
    'reports.fprhr_pamm_id': 'رقم الصندوق',
    'reports.fprhr_pamm_name': 'الصندوق',
    'reports.fprhr_requestid': 'رقم تعريف الطلب',
    'reports.from': 'من',
    'reports.From.SERVER': 'السيرفر',
    'reports.fromDate': 'من',
    'reports.fromDateConnAttempt': 'من',
    'reports.fromDatePwdChange': 'من (نافذ)',
    'reports.FUNCTION_ACCOUNT_GROUP': 'مجموعة الحساب',
    'reports.FUNCTION_ACCOUNT_OPERATIONS': 'وضوح عمليات التمويل',
    'reports.FUNCTION_ACCOUNTS': 'الحسابات',
    'reports.FUNCTION_ALERTS': 'التنبيهات',
    'screen.Alerts.Ask': 'العرض',
    'screen.Alerts.Bid': 'الطلب',
    'screen.Alerts.Last': 'الأخير',
    'screen.Alerts.ChangePr': 'التغيّر، %',
    'screen.Alerts.Volume': 'الحجم',
    'reports.FUNCTION_ARBITRAGER': 'المراجح',
    'reports.FUNCTION_AUTOBROKET_LIST': 'قائمة الوسطاء التلقائيون',
    'reports.FUNCTION_BACK_TESTER': 'مختبر الاستراتيجية',
    'reports.FUNCTION_BARS_FROM': 'إنشاء أشرطة من',
    'reports.FUNCTION_BASE_CURRENCY': 'العملة الأساسية للنظام (قد تحتاج لتغيير تقاطعات الأداة)',
    'reports.FUNCTION_BASKET': 'السلة',
    'reports.FUNCTION_BETTING': 'المراهنة',
    'reports.FUNCTION_BINDEDORDERS': 'السماح بأوأمر أمر يلغي الآخر',
    'reports.FUNCTION_BLOCKED_SUMM_FOR_ORDERS': 'الأموال المغلقة للأوأمر',
    'reports.FUNCTION_BRANDING': 'الأسماء التجارية',
    'reports.FUNCTION_BRANDINGADMIN': 'مدير الاسم التجاري',
    'reports.FUNCTION_BUILDER': 'الباني',
    'reports.FUNCTION_CACHEADMIN': 'مدير الذاكرة المؤقتة',
    'reports.FUNCTION_CHART': 'الرسم البياني',
    'reports.FUNCTION_CHAT': 'محادثة',
    'reports.FUNCTION_CLOSE_POSITIONS_DISABLE': 'عدم السماح بإغلاق المراكز',
    'reports.FUNCTION_COMMUNITY': 'المجتمع',
    'reports.FUNCTION_CONFIGADMIN': 'الإعدادات والاسم التجاري',
    'reports.FUNCTION_CONNECTIONSSADMIN': 'مدير الاتصال',
    'reports.FUNCTION_CROSS_PAIR': 'السعر المتقابل',
    'reports.FUNCTION_CUR_EXP': 'نتعرض العملة',
    'reports.FUNCTION_DISABLE_BUY': 'تعطيل أوأمر الشراء',
    'reports.FUNCTION_DISABLE_OPTION_TRADING': 'تعطير أوأمر الخيارات',
    'reports.FUNCTION_DISABLE_SELL': 'تعطيل أوأمر البيع',
    'reports.FUNCTION_DO_NOT_MARGIN': 'عدم استخدام الهامش',
    'reports.FUNCTION_EVENT_LOG': 'سجّل الأحداث',
    'reports.FUNCTION_FUNDING': 'مدير العمليات',
    'reports.FUNCTION_GENERATE_OPTIONS': 'إنشاء خيارات من المستوى1',
    'reports.FUNCTION_HEDGE_RULE_TYPE': 'قائمة قواعد الحماية:',
    'reports.FUNCTION_INSTRUMENT_LOOKUP': 'البحث عن رمز',
    'reports.FUNCTION_INSTRUMENT_PORTFOLIO': 'المحفظة',
    'reports.FUNCTION_INSTRUMENTSADMIN': 'مدير الرموز',
    'reports.FUNCTION_INTERSERVER': 'Interserver',
    'reports.FUNCTION_LEVEL2': 'بيانات وأوأمر المستوى2',
    'reports.FUNCTION_LEVEL3': 'الصفقات وأوقاتها',
    'reports.FUNCTION_LOCKED_BY_BROKER': 'مغلق من قبل الوسيط',
    'reports.FUNCTION_MANUAL': 'السماح للأوأمر اليدوية',
    'reports.FUNCTION_MARGIN_MODE': 'نظام الهامش',
    'reports.FUNCTION_MASTER': 'إدارة الحساب الرئيسي',
    'reports.FUNCTION_MATRIX': 'المصفوفة',
    'reports.FUNCTION_MULTI_CONNECTION': 'السماح بالاتصالات المتعددة',
    'reports.FUNCTION_MUTUAL_CLOSE': 'السماح بالإغلاق المتبادل',
    'reports.FUNCTION_NEWS': 'الأخبار',
    'reports.FUNCTION_NEWSADMIN': 'مدير الأخيار',
    'reports.FUNCTION_NOT_ALLOW_OPEN_POSITION': 'عدم السماح بفتح المركز',
    'reports.FUNCTION_NOT_ALLOW_SHORT_STOCK': 'عدم السماح بالأسهم القصيرة',
    'reports.FUNCTION_ONE_COUNTERACCOUNT': 'حساب معاكس واحد',
    'reports.FUNCTION_ONE_POSITION': 'مركز واحد للرمز (حماية)',
    'reports.FUNCTION_ONE_QUOTECACHECOUNT': 'حجم ذاكرة مؤقت واحدة للكل',
    'reports.FUNCTION_OPTIONS': 'خيارات',
    'reports.FUNCTION_ORDER_BOOK': 'سجّل الأوامر',
    'reports.FUNCTION_PARTIAL_CLOSE': 'السماح بالإغلاق الجزئي',
    'reports.FUNCTION_POSITION_BALANCE': 'رصيد المركز',
    'reports.FUNCTION_POSITIONSADMIN': 'مدير المراكز',
    'reports.FUNCTION_QUOTE_HISTORY': 'سجّل العروض',
    'reports.FUNCTION_QUOTES': 'العروض',
    'reports.FUNCTION_REPORT': 'إبلاغ',
    'reports.FUNCTION_REPORTADMIN': 'مدير التقارير',
    'reports.FUNCTION_RESERVER_WITHDRAWAL': 'سحب',
    'reports.FUNCTION_RFQ': 'لوحة طلب السعر',
    'reports.FUNCTION_ROLESADMIN': 'مدير مجموعات المستخدم',
    'reports.FUNCTION_ROUTESADMIN': 'مدير المسارات',
    'reports.FUNCTION_SCHEDULERADMIN': 'مدير المجدول',
    'reports.FUNCTION_SCRIPT_BUILDER': 'باني النصوص',
    'reports.FUNCTION_SERVICESADMIN': 'مدير الخدمات',
    'reports.FUNCTION_SETTINGSADMIN': 'مدير الإعدادات',
    'reports.FUNCTION_SHOW_ORDERS': 'الأوامر',
    'reports.FUNCTION_SHOW_POSITIONS': 'المراكز',
    'reports.FUNCTION_SLAVE': 'تابع',
    'reports.FUNCTION_SLTP': 'السماح بأوأمر إ.خ/أ.ر',
    'reports.FUNCTION_SMS_SERVICE_DEST': 'وجهة خدمة الرسائل القصيرة',
    'reports.FUNCTION_SN': 'رقم الرخصة',
    'reports.FUNCTION_STRATEGIESADMIN': 'مدير الاستراتيجيات',
    'reports.FUNCTION_SUPPORT_EMAIL': 'البريد الإلكتروني لفريق الدعم',
    'reports.FUNCTION_TABLESADMIN': 'مدير الجداول',
    'reports.FUNCTION_THREADEDSAVER': 'حافظ الانسياب يعمل',
    'reports.FUNCTION_TRADES': 'الأوامر المنفذة',
    'reports.FUNCTION_TRADING': 'التداول',
    'reports.FUNCTION_TRADING_MODE': 'وضع التداول',
    'reports.FUNCTION_TRAILING_STOP': 'السماح بالتوقف المتحرك',
    'reports.FUNCTION_USER_CONNECTION_POLICY': 'استخدام سياسة تعدد الاتصالات',
    'reports.FUNCTION_USERSADMIN': 'مدير المستخدمين',
    'reports.FUNCTION_VALIDATE_SSL_CERTIFICATE': 'يجب التحقق من شاهدة SSL',
    'reports.FUNCTION_VENDOR_NAME': 'اسم المزود',
    'reports.FUNCTION_WATCHLIST': 'قائمة المراقبة ',
    'reports.Fund Gain History Report': 'تقرير سجّل ربح الصندوق',
    'reports.Fund Investments Report': 'تقرير استثمارات الصندوق',
    'reports.Fund Investor Action History Report': 'تقرير سجّل إجراءات مستثمر الصندوق',
    'reports.Fund Investor History Report': 'تقرير سجل مستثمر الصندوق',
    'reports.Fund Request History Report': 'تقرير سجّل طلبات الصندوق',
    'reports.Fund Transfer': 'تحويل مبلق',
    'reports.fund_type.closed': 'نهاية مغلقة',
    'reports.fund_type.open': 'نهاية مغلقة',
    'reports.fundID': 'رقم الصندوق',
    'reports.FUNDING_RULES': 'وضوح عمليات التمويل',
    'reports.fxintegrate.cert.host.verify': 'fxintegrate cert host verify',
    'reports.fxintegrate.cert.root.ca.file': 'ملف هيئة اصدار شهادة دمج الفوركس',
    'reports.fxintegrate.host': 'مضيف دمج الفوركس',
    'reports.fxintegrate.logging.file': 'ملف تسجيل دمج الفوركس',
    'reports.fxintegrate.logging.maxfilecount': 'الحد الأقصى لملفات تسجيل دمج الفوركس',
    'reports.fxintegrate.logging.maxfilesize': 'الحجم الأقصى لملفات تسجيل دمج الفوركس',
    'reports.fxintegrate.logging.output': 'ناتج تسجيل دمج الفوركس',
    'reports.fxintegrate.logging.priority': 'أولوية تسجيل دمج الفوركس',
    'reports.fxintegrate.port': 'منفذ دمج الفوركس',
    'reports.fxintegrate.protocol': 'بروتوكول دمج الفوركس',
    'reports.fxintegrate.usessl': 'شهادلة SSL لدمج الفوركس',
    'reports.GENERAL_COMMON_RULES': 'قواعد عامة معروفة',
    'reports.GENERAL_USER_RULES': 'قواعد عامة',
    'reports.GFT': 'GFT',
    'reports.gi.available_margin': 'الهامش المتاح',
    'reports.gi.avaliable_funds': 'الأموال المتاحة',
    'reports.gi.balance': 'الرصيد',
    'reports.gi.balance_blocked_by_orders': 'مغلق من الأوامر',
    'reports.gi.cur_balance': 'الرصيد المتوقع',
    'reports.gi.cur_margin': 'الهامش الحالي',
    'reports.gi.fund_capital': 'رأس مال الصندوق',
    'reports.gi.fund_used': 'الصناديق المستخدمة',
    'reports.gi.maintain_margin': 'الهامش الوقائي',
    'reports.gi.margin_orders': 'الهامش للأوأمر',
    'reports.gi.margin_positions': 'الهامش للمراكز',
    'reports.gi.margin_stopout': 'توقف',
    'reports.gi.margin_stoptrade': 'إيقاف التداول',
    'reports.gi.margin_used': 'الهامش المستخدم',
    'reports.gi.margin_warning': 'تحذير الهامش',
    'reports.gi.min_margin': 'الهامش الأدنى',
    'reports.gi.pos_maintenance_req': 'متطلبات المحافظة على المركز',
    'reports.gi.risk_lvl': 'مستوى المخاطرة',
    'reports.gi.stock_value': 'الأسهم',
    'reports.gi.today_released_pnl': 'ر/خ المحققة',
    'reports.gi.withdrawal_available': 'السحب المتاح',
    'reports.Goldman Sachs': 'غولدمان ساكس',
    'reports.grosspl': 'إجمالي ر/خ',
    'reports.groupid': 'رقم المجموعة',
    'reports.groupname': 'المجموعة',
    'reports.GTL': 'GTL',
    'reports.HeartBtInt': 'الفاصل بين النبضات',
    'reports.hedged': 'التنفيذ المباشر',
    'reports.helpIndex': '',
    'reports.host': 'المضيف',
    'reports.HOT_BUTTON': 'الاختصار',
    'reports.HotSpot': 'هوت سبوت',
    'reports.hours': 'ساعات',
    'reports.HTTP.SERVER.HOST': 'HTTP SERVER HOST',
    'reports.HTTP.SERVER.PORT': 'HTTP SERVER PORT',
    'reports.HTTP.SERVER.PROTOCOL': 'HTTP SERVER PROTOCOL',
    'reports.HTTP.URL.HEARTBEAT': 'HTTP URL HEARTBEAT',
    'reports.HTTP.URL.LOGIN': 'HTTP URL LOGIN',
    'reports.HTTP.URL.LOGOUT': 'HTTP URL LOGOUT',
    'reports.HTTP.URL.PASSWORD.VALIDATION': 'HTTP URL PASSWORD VALIDATION',
    'reports.HTTP.URL.PRELOGIN': 'HTTP URL PRELOGIN',
    'reports.HTTP.URL.QUERY': 'HTTP URL QUERY',
    'reports.HTTP.URL.WORKFLOW': 'HTTP URL WORKFLOW',
    'reports.IB Trades Report': 'تقرير تداولات IB',
    'reports.IGIndex': 'IGIndex',
    'reports.IndexingQuoteFrequency(sec)': 'تردد ربط العرض بالمؤشر (ثانية)',
    'reports.Information': 'معلومات',
    'reports.Informational message': 'رسالة معلوماتية',
    'reports.Init margin req.': 'متطلبات الهامش المبدئي',
    'reports.Init. margin req.': 'متطلبات الهامش المبدئي',
    'reports.INSTALLER_SERVICE_URL': 'رابط مثبت الخدمة',
    'reports.InstrRouteMap': 'خريطة المسار',
    'reports.instrtype': 'مجموعة الرمز',
    'reports.INSTRTYPES_RULES': 'مرئية مجموعات الرمز',
    'reports.instrument': 'الرمز',
    'reports.Instrument': 'الرمز',
    'reports.instrument group': 'مجموعة الرمز',
    'reports.Instrument Specification': 'خصائص الرمز',
    'reports.Instrument Statistic Report': 'تقرير احصائيات الأداة',
    'reports.Instrument Usage By Signal Report': 'استخدام الأداة من تقرير المؤشر',
    'reports.Instrument Visibility Report': 'تقرير مرئية الرمز',
    'reports.INSTRUMENT_DERIVATIVES': 'المشتقات',
    'reports.INSTRUMENT_GENERAL': 'عام',
    'reports.INSTRUMENT_MARGIN': 'الهامش',
    'reports.INSTRUMENT_TRADING': 'التداول',
    'reports.INSTRUMENT_TYPE_DERIVATIVES': 'المشتقات',
    'reports.instrument.group': 'مجموعة الرمز',
    'reports.instrument.name': 'اسم الرمز',
    'reports.instrumentgroup': 'مجموعة الرمز',
    'reports.InstrumentId': 'الرمز',
    'reports.instrumentname': 'الرمز',
    'reports.instruments': 'الرمز',
    'reports.instrumenttype': 'نوع الرمز',
    'reports.instrumenttype.duplicatename': 'اسم مجموعة الرمز موجود بالفعل',
    'reports.instrumenttype.inheritanceerror': 'خطأ في وراثة الأنواع',
    'reports.instrumentType.name': 'اسم مجموعة الرمز',
    'reports.instrumentType.superId': 'Symbol supertype name',
    'reports.instrumentVisibilityReport': 'تقرير مرئية الرمز',
    'reports.Integral': 'متكامل',
    'reports.IntegralFIX': 'IntegralFIX',
    'reports.InteractiveData': 'بيانات تفاعلية',
    'reports.IntererstOnAvailableFundsAndUnsettledCash': 'فائدة على الأموال المتوفرة  + المبالغ تحت التسوية',
    'reports.interest': 'الفائدة',
    'reports.InterestOnAvailableFunds': 'فائدة على الأموال المتوفرة ',
    'reports.InterestOnCash': 'الفائدة على التقد',
    'reports.InterestOnMarginAvailable': 'الفائددة على الهامش المتاح',
    'reports.InterestOnMarginAvailableAndUnsettledCash': 'الفائدة على الهامش المتاح والنقد تحت التسوية',
    'reports.Invalid installer hash.': 'هاش المثبت غير صحيح.',
    'reports.Invalid installer size.': 'حجم المثبت غير صحيح.',
    'reports.Invalid installer URL.': 'رابط المثبت غير صحيح.',
    'reports.Invalid password:New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.': 'يجب أن تحتوي كلمة المرور الجديدة على حرف واحد على الأقل من الأحرف الكبيرة وحرف واحد من الأحرف الصغيرة ورقم واحد وحرف خاص واحد (على سبيل المثال @ # $٪) وتحتوي على 8 أحرف على الأقل. حاول مرة اخرى.',
    'reports.Invalid price for the order.': 'سعر غير صحيح للطلب',
    'reports.Investor Action History Report': 'تقرير سجّل إجراءات المستثمر',
    'reports.investorID': 'رقم المستثمر',
    'reports.InvestorProtectionLevy': 'ضريبة حماية المستثمر',
    'reports.ip': 'IP',
    'reports.IP': 'IP',
    'reports.IQFeed': 'IQFeed',
    'reports.irdnumber': 'رقم IR',
    'reports.is out of range': 'خارج النطاق',
    'reports.IsOnlyLastPeriod': 'آخر فترة',
    'reports.isPriceStream': 'انسياب السعر',
    'reports.isShowLots': 'عرض العدد بالحصص',
    'reports.IsShowLots': 'عرض العدد بالحصص',
    'reports.IsShowPamm': 'إظهار الصندوق',
    'reports.issingle': 'فردي',
    'reports.JdbcDriver': 'Jdbc driver',
    'reports.JdbcPassword': 'Jdbc password',
    'reports.JdbcSessionIdDefaultPropertyValue': 'JDBC session ID default property value',
    'reports.JdbcURL': 'JdbcURL',
    'reports.JdbcUser': 'Jdbc user',
    'reports.JMS.INIT.QUEUE': 'JMS INITIAL QUEUE',
    'reports.JMS.SERVER.HOST': 'JMS SERVER HOST',
    'reports.JMS.SERVER.PASSWORD': 'JMS SERVER PASSWORD',
    'reports.JMS.SERVER.PORT': 'JMS SERVER PORT',
    'reports.JMS.SERVER.PROTOCOL': 'JMS SERVER PROTOCOL',
    'reports.JMS.SERVER.USER': 'JMS SERVER USER',
    'reports.JPMorganTrade': 'JPMorganTrade',
    'reports.keystoreAlias': 'الاسم المستعار لمخزن الشهادات',
    'reports.keystoreFile': 'keystore file',
    'reports.KyteFIX': 'KyteFIX',
    'reports.L1port': 'منفذL1',
    'reports.L2port': 'منفذL2',
    'reports.LADDER_VIEW': 'Super DOM',
    'reports.LADDER_VIEW_OE': 'Super DOM (build in OE)',
    'reports.Last fill price': 'آخر سعر للملئ',
    'reports.lastname': 'الاسم الأخير',
    'reports.Level1Charging': 'Level1Charging',
    'reports.Level1DataCharging': 'رسوم بيانات المستوى 1',
    'reports.Level2Charging': 'Level2Charging',
    'reports.Level2DataCharging': 'رسوم بيانات المستوى 2',
    'reports.Level3Charging': 'Level3Charging',
    'reports.Level3DataCharging': 'رسوم بيانات المستوى 3',
    'reports.Limit accepted by broker': 'تم قبول أمر المحدد بواسطة الوسيط',
    'reports.Limit accepted by STP': 'تم قبول أمر المحدد من التنفيذ المباشر',
    'reports.Limit order accepted': 'تم قبول أمر المحدد',
    'reports.Limit order activated': '',
    'reports.Limit order cancel request': 'طلب إلغاء أمر المحدد',
    'reports.Limit order canceled': 'تم إلغاء أمر المحدد',
    'reports.Limit order created': 'تم إنشاء أمر المحدد',
    'reports.Limit Order Created': 'تم إنشاء أمر المحدد',
    'reports.Limit order created is off market': '',
    'reports.Limit order created is unplaced': '',
    'reports.Limit order is awaiting market': 'أمر حد بإنتظار السوق',
    'reports.Limit Order Modified': 'تم تعديل أمر المحدد',
    'reports.Limit order partially reject': 'تم إلغاء أمر المحدد جزئياً',
    'reports.Limit order placing request': 'طلب وضع أمر المحدد',
    'reports.Limit order received by broker': 'تم استقبال أمر المحدد بواسطة الوسيط',
    'reports.Limit order rejected': 'تم رفض أمر المحدد',
    'reports.Limit Order Removed': 'تم حذف أمر المحدد',
    'reports.Limit order replace request': 'طلب استبدال أمر المحدد',
    'reports.Limit order replaced': 'تم تعديل أمر المحدد',
    'reports.Limit order restated': '',
    'reports.Linked Users': 'المستخدمون المرتبطون',
    'reports.LMAX': 'LMAX',
    'reports.LMAX FIX': 'LMAX FIX',
    'reports.Local': 'محلي',
    'reports.locked': 'مغلق',
    'reports.LogFileMaxSize': 'الحجم الأقصى لملف السجّل',
    'reports.login': 'اسم المستخدم',
    'reports.Login': 'اسم المستخدم',
    'reports.LOGIN.USER.NAMESPACE': 'مساحة اسم تسجيل دخول المستخدم',
    'reports.Login/password combination is not valid': '',
    'reports.LogonTimeout': 'انتهاء وقت تسجيل الدخول',
    'reports.Logout': 'تسجيل الخروج',
    'reports.Lots to open': 'العقود للفتح',
    'reports.lotsize': 'حجم الحصة',
    'reports.Mail Settings': 'إعدادات البريد',
    'reports.mail.mailsettings': 'إعدادات البريد',
    'reports.mailPattern.ChatDuplicating': 'تكرار المحادثة',
    'reports.mailPattern.DemoUserNotify': 'إشعار بتاريخ الانتهاء للمستخدم التجريبي',
    'reports.mailPattern.DemoUserRegistration': 'تأكيد تسجيل المستخدم التجريبي',
    'reports.mailPattern.DemoUserRemove': 'تأكيد حذف المستخدم التجريبي',
    'reports.mailPattern.DepositWithdrawalNotification': 'إشعار البريد الإلكتروني بالسحب/الإيداع',
    'reports.mailPattern.MarginCall': 'إشعار استدعاء الهامش',
    'reports.mailPattern.NewsCreated': 'تم إنشاء الأخبار',
    'reports.mailPattern.OrderPlaced': 'تم وضع أمر الإيقاف/المحدد',
    'reports.mailPattern.OrderRemoved': 'تم حذف بأمر الإيقاف/المحدد',
    'reports.mailPattern.PositionClosed': 'تأكيد إغلاق المركز',
    'reports.mailPattern.PositionOpened': 'تأكيد بوضع المركز',
    'reports.mailPattern.SignalDuplicating': 'تكرار الإشارة',
    'reports.mailPattern.TransactionStatementNotifyBroker': 'التقرير اليومي للوسيط',
    'reports.mailPattern.TransactionStatementNotifyUser': 'التقرير اليومي للمستخدم',
    'reports.mailPattern.UserEmailConfirmation': 'البريد الإلكتروني للتأكيد',
    'reports.mailPattern.UserPasswordRecovery': 'استعادة كلمة مرور المستخدم',
    'reports.mailPattern.UserPasswordReset': 'إعادة ضبط كلمة مرور المستخدم',
    'reports.mailPattern.UserRegistration': 'تأكيد تسجيل المستخدم',
    'reports.mailPattern.UserUnlocking': 'فتح المستخدم بعد القوة المفرطة',
    'reports.Main': 'رئيسي',
    'reports.MAM trades report': 'تقرير تداولات إدارة الحسابات المتعددة',
    'reports.MAM_OE': 'إدارة الحسابات المتعددة (البناء في مدخل الأوامر)',
    'reports.MamManagementFee': 'رسوم إدارة الحسابات المتعددة',
    'reports.MamPerfomanceFee': 'رسوم أداء إدارة الحسابات المتعددة',
    'reports.MamPerformanceFee': 'رسوم أداء إدارة الحسابات المتعددة',
    'reports.Manual order cancel request': 'طلب إلغاء الأمر يدوياً',
    'reports.Manual order placing request': 'طلب القيام بالأمر يدوياً',
    'reports.Manual order replace request': 'طلب استبدال الأمر يدوياً',
    'reports.ManufacturedDividends': 'الأرباح الموزعة ',
    'reports.margin': 'الهامش',
    'reports.Margin Call': 'نداء الهامش',
    'reports.Margin Call Reached': 'تم الوصول إلى استعاء الهامش',
    'reports.Margin Call Warning.': 'تحذير استعداء الهامش',
    'reports.Margin used': 'الهامش المستعمل',
    'reports.MARGIN_RULES_ACC': 'قواعد الهامش (الحساب)',
    'reports.MARGIN_RUSLES': 'قواعد الهامش',
    'reports.Margincall': 'نداء الهامش',
    'reports.MarginCallMessage.header.MarginCallReached': 'تم الوصول إلى استعاء الهامش',
    'reports.MarginCallMessage.header.MarginWarning': 'تحذير الهامش',
    'reports.MarginCallMessage.Message': 'الرجاء الإيداع في حسابك',
    'reports.Market accepted by broker': 'تم قبول أمر السوق بواسطة الوسيط',
    'reports.Market accepted by STP': 'تم قبول أمر السوق من التنفيض المباشر',
    'reports.Market Close Order Removed': 'تم حذف أمر إغلاق السوق',
    'reports.Market data charging report': 'تقرير رسوم بيانات السوق',
    'reports.Market order accepted': 'تم قبول أمر على سعر السوق',
    'reports.Market order activated': '',
    'reports.Market order cancel request': 'طلب إلغاء أمر السوق',
    'reports.Market order canceled': 'تم إلغاء أمر على سعر السوق',
    'reports.Market order created': 'تم انشاء أمر على سعر السوق',
    'reports.Market Order Created': 'تم انشاء أمر على سعر السوق',
    'reports.Market order created is off market': '',
    'reports.Market order created is unplaced': '',
    'reports.Market Order Modified': 'تم تعديل أمر سعر السوق',
    'reports.Market order partially reject': 'تم إلغاء أمر السوق جزئياً',
    'reports.Market order placing request': 'طلب وضع أمر السوق',
    'reports.Market order received by broker': 'تم تلقي أمر السوق بواسطة الوسيط',
    'reports.Market order rejected': 'تم رفض أمر السوق',
    'reports.Market Order Removed': 'تم حذف أمر السوق',
    'reports.Market order replace request': 'طلب استبدال أمر السوق',
    'reports.Market order replaced': 'تم إلغاء تعديل السوق',
    'reports.Market order restated': '',
    'reports.Market price': 'سعر السوق',
    'reports.MARKETDEPTH': 'عمق السوق',
    'reports.MarketFactory Feed': 'MarketFactory Feed',
    'reports.Markup': 'رفع السعر',
    'reports.MATRIX': 'المصفوفة',
    'reports.MATRIX_OE': 'المصفوفة (البناء في مدخل الأوامر)',
    'reports.MaxLogsBackups': 'الحد الأقصى لنسخ السجّل الاحتياطية',
    'reports.MBT': 'MBT',
    'reports.message': 'رسالة',
    'reports.Message': 'رسالة',
    'reports.messageid': 'رقم الرسالة',
    'reports.MFGlobal': 'MFGlobal',
    'reports.MillisecondsinTimeStamp': 'ملي ثانية في الطابع الزمني',
    'reports.MillisecondsInTimeStamp': 'ملي ثانية في الطابع الزمني',
    'reports.minchange': 'التغير الأدنى',
    'reports.MinFeeAdjEquity': 'الرسوم الأدنى لراس المال المعدل',
    'reports.Minimum Open Margin': 'الحد الأدنى لفتح الهامش',
    'reports.minutes': 'دقائق',
    'reports.MLFIX': 'MLFIX',
    'reports.MOB_CANCEL_ALL_ORDERS': '(إلغاء جميع الأوامر) الهاتف',
    'reports.MOB_CHART_OE': 'رسم بياني الهاتف (إدخال الأوامر)',
    'reports.MOB_CLOSE_ALL_POSITIONS': 'الهاتف (إغلاق جميع المراكز)',
    'reports.MOB_MARKETDEPTH': 'الهاتف عمق السوق',
    'reports.MOB_ORDERS_HOT_BTN': 'الهاتف أوأمر المشغولة',
    'reports.MOB_OTHER': 'الهاتف آخر',
    'reports.MOB_POSITIONS_HOT_BTN': 'الهاتف المراكز',
    'reports.MOB_TAB_CHART_VISUAL_HOT_BTN': 'الهاتف الرسم البياني',
    'reports.MOB_WL_OE': 'الهاتف قائمة المراقبة ',
    'reports.Modified': 'معدل',
    'reports.Modifing': 'معدل',
    'reports.Modifyied by': 'التعديل بواسطة',
    'reports.Modifying': 'معدل',
    'reports.months': 'أشهر',
    'reports.Morgan Stanley': 'Morgan Stanley',
    'reports.MorganStanleyCFD': 'عقود فروقات Morgan Stanley',
    'reports.Mutual close request': 'طلب إغلاق متبادل',
    'reports.name': 'الاسم',
    'reports.NAMESPACE': 'مسافة الاسم',
    'reports.NEED_EMAIL_CONFIRM_ON_REGISTRATION': 'تأكيد تسجيل الحساب بالبريد الإلكتروني',
    'reports.NeoNet': 'NeoNet',
    'reports.Net Open Positions': 'صافي المراكز المفتوحة',
    'reports.netpl': 'صافي ر/خ',
    'reports.New Back Office Changes Report': 'تقرير بتغيرات المكتب الرئيسي الجديدة',
    'reports.New password must differ from several previous passwords.': 'يجب أت تختلف كلمة المرور الجديدة من كلمات المرور السابقة',
    'reports.New Users Report': 'تقرير بالمستخدمين الجدد',
    'reports.news.feed.type': 'نوع الأخبار',
    'reports.news.reciving': 'تلقي الأخبار',
    'reports.news.save': 'حفظ الأخبار',
    'reports.news.sendEmail': 'إرسال الأخبار بالبريد الإلكتروني',
    'reports.NewsCharging': 'رسوم الأخبار',
    'reports.NewsFeed': 'الأخبار',
    'reports.newvalue': 'قيمة جديدة',
    'reports.no such position, no such order.': 'لا يوجد المركز، لا يوجد الأمر',
    'reports.Nomura': 'Nomura',
    'reports.Nomura-Lehman': 'Nomura-Lehman',
    'reports.None': 'لا يوجد',
    'reports.Not enoght margin to create Order': 'لا يوجد هامش كافي لفتح الأمر',
    'reports.Not enough margin to open order': 'لا يوجد هامش كافي لفتح الأمر',
    'reports.Not enough Margin to open position': 'لا يوجد هامش كافي لفتح المركز',
    'reports.Not enough offer': 'لا يكفي العرض',
    'reports.Not Filled Orders Report': 'تقرير الأوامر الغير منفذة',
    'reports.Not Successful': 'غير ناجح',
    'reports.notShowMam': 'لا تظهر إدارة الحسابات المتعددة',
    'reports.NotSuccessful': 'غير ناجح',
    'reports.number': 'رقم',
    'reports.objectid': 'Object ID',
    'reports.ObjectId': 'رقم العنصر',
    'reports.ObjectID': 'Object ID',
    'reports.OCO accepted by broker': 'تم قبول أمر OCO بواسطة الوسيط',
    'reports.OCO accepted by STP': 'تم قبول أمر OCO بواسطة التنفيذ المباشر',
    'reports.OCO order activated': '',
    'reports.OCO order cancel request': 'طلب إلغاء أمر OCO',
    'reports.OCO order canceled': 'تم إلغاء أمر OCO ',
    'reports.OCO order created': 'تم إنشاء أمر OCO',
    'reports.OCO Order Created': 'تم إنشاء أمر OCO',
    'reports.OCO order created is off market': '',
    'reports.OCO order created is unplaced': '',
    'reports.OCO order partially reject': 'تم إلغاء أمر OCO جزئياً',
    'reports.OCO order placing request': 'طلب وضع أمر OCO',
    'reports.OCO order received by broker': 'تم استقبال أمر OCO من الوسيط',
    'reports.OCO order rejected': 'تم رفض أمر OCO',
    'reports.OCO order replace request': 'طلب استبدال أمر OCO',
    'reports.OCO order replaced': 'تم تعديل أمر OCO',
    'reports.OCO order restated': '',
    'reports.OE': 'إدخال الأوامر',
    'reports.Off market': '',
    'reports.ONE_MARGIN_FOR_USER_ACC': 'هامش واحد لحساب المستخدمين',
    'reports.Open': 'فتح',
    'reports.Open Buy': 'الشراء المفتوح',
    'reports.Open date': 'تاريخ الافتتاح',
    'reports.Open lots': 'العقود المفتوحة',
    'reports.Open order is forbidden by trade session': 'ممنوع طلب الفتح من جلسة التجارة.',
    'reports.Open order type': 'نوع الأمر المفتوح',
    'reports.Open Positions By Signal Report': 'المراكز المفتوحة من تقرير المؤشر',
    'reports.Open Positions Report': 'تقرير المراكز المفتوحة',
    'reports.Open price': 'سعر الافتتاح',
    'reports.Open Sell': 'البيع المفتوح',
    'reports.OPEN_SPOT_ON_OPTION_EXP': 'فتح مكان المركز عند انتهاء المركز',
    'reports.openclosebuysell': 'نوع العملية',
    'reports.opengrosspnl': 'إجمال ر/خ المفتوحة',
    'reports.OpenOptionPremium': 'فتح علاوة الخيار',
    'reports.openorderid': 'رقم الأمر المفتوح',
    'reports.openprice': 'سعر الافتتاح',
    'reports.opentime': 'وقت الافتتاح',
    'reports.operation': 'العملية',
    'reports.Operation': 'العملية',
    'reports.Operation Id': 'رقم العملية',
    'reports.Operation type': 'نوع العملية',
    'reports.Operation Type': 'نوع العملية',
    'reports.operation.not_enough_margin': 'لا يوجد هامش كافي',
    'reports.operation.wrong_length': 'الكمية كبيرة',
    'reports.operation.wrong_length;': 'الكمية كبيرة',
    'reports.operation.wrong_sum': 'مجموع خاطئ',
    'reports.OperationID': 'رقم العملية',
    'reports.OperationInDisconnectedMode': 'العملية غير متاح في الوضع الغير متصل!',
    'reports.operationtype': 'نوع العملية',
    'reports.OPTION_MASTER': 'مدير الخيارات',
    'reports.option_value': 'قيمة الخيارات ',
    'reports.OrcFIX': 'OrcFIX',
    'reports.order': 'أمر',
    'reports.Order': 'أمر',
    'reports.Order Book History': 'طلب كتاب التاريخ',
    'reports.Order Book Report': 'تقرير سجل الأوامر',
    'reports.Order cancel is forbidden by trade session': 'ممنوع إلغاء أمر من جلسة التجارة.',
    'reports.Order cancel request': 'طلب إلغاء الأمر',
    'reports.Order canceled successfully': 'تم إلغاء الأمر بنجاح',
    'reports.Order cannot be created since order amount is forbidden.': 'لا يمكن إنشاء الأمر لأن الكمية غير صحيحة',
    'reports.Order cannot be created since route is not active.': 'لا يمكن إنشاء الأمر بسبب أن المسار غير نشط',
    'reports.Order cannot be created, because position is in executing': 'لا يمكن إنشاء الأمر لأن المركز  في التنفيذ',
    'reports.Order cannot be created, because route is not active': 'لا يمكن إنشاء الأمر لأن المسار غير نشط',
    "reports.Order cannot be created, because short positions are forbidden. You don't have enough rights.": 'لا يمكن إنشاء الأمر لأن المركز  القصيرة ممنوعة في حسابك',
    'reports.Order cannot be created, because short positions are forbidden. You donґt have enough rights.': '',
    'reports.Order cannot be created, because short positions are forbidden.Or yor already have Limit/Stop orders for cover all your positions.': 'لا يمكن إنشاء الأمر لأن المركز  القصيرة ممنوعة في حسابك أو لديك أوأمر وقف أو حد تغطي مراكزك',
    'reports.Order cannot be placed in non-trading day. Please, check the trading calendar.': 'لا يمكن وضع الأمر في يوم غير التداول. يرجى التحقق من جدول التداول.',
    'reports.Order groupID': 'Order groupID',
    'reports.Order History Report': 'تقرير سجّل الأوامر',
    'reports.Order modification is forbidden by trade session': 'ممنوع تعديل النظام من خلال جلسة التجارة.',
    'reports.Order number': 'رقم الأمر',
    'reports.Order placing request': 'طلب وضع أمر',
    'reports.Order price': 'سعر الأمر',
    'reports.Order Price': 'سعر الأمر',
    'reports.Order received by STP': 'تم تلقي الطلب بواسطة التنفيذ المباشر',
    'reports.Order replace request': 'طلب استبدال الأمر',
    'reports.Order replacing confirmed': 'تم تأكيد استبدال الأمر',
    'reports.Order TIF is forbidden by trade session': 'ممنوع أجل validity من جلسة التجارة.',
    'reports.Order type': 'نوع الأمر',
    'reports.Order type is forbidden by trade session': 'ممنوع نوع النظام من قبل جلسة التجارة.',
    'reports.Order was rejected due to invalid price!': 'تم رفض السعر الأمر لأن السعر غير صحيح!',
    'reports.order_book_account': 'الحساب',
    'reports.order_book_datetime': 'التاريخ',
    'reports.order_book_event': 'الحدث',
    'reports.order_book_login': 'اسم المستخدم',
    'reports.order_book_modified_by': 'تم التعديل بواسطة المستخدم',
    'reports.order_book_orderid': 'رقم الأمر',
    'reports.order_book_price': 'السعر',
    'reports.order_book_quantity': 'الكمية',
    'reports.order_book_route': 'مسار',
    'reports.order_book_side': 'جانب',
    'reports.order_book_stop_price': 'سعر الإيقاف',
    'reports.order_book_symbol': 'الرمز',
    'reports.order_book_symbol_type': 'نوع الرمز',
    'reports.order_book_tif': 'الصلاحية ',
    'reports.order_book_type': 'النوع',
    'reports.orderCreate': 'إنشاء أمر',
    'reports.orderExecute': 'تنفيذ أمر',
    'reports.OrderExternalConfirm': 'تأكيد أمر خارجي',
    'reports.orderid': 'معرف الأمر',
    'reports.orderId': 'معرف الأمر',
    'reports.OrderId': 'رقم الأمر',
    'reports.orderModify': 'تعديل أمر',
    'reports.orderMutural': 'أمر متبادل',
    'reports.orderRemoved': 'تم حذف الأمر',
    'reports.ORDERS_PANEL_HOT_BTN': 'أوامر القائمة  (أزرار الاختصار)',
    'reports.ordertype': 'نوع الأمر',
    'reports.Oreder Number': 'رقم الأمر',
    'reports.originalvalue': 'القيمة الأصلية',
    'reports.OTHER': 'أخرى',
    'reports.other_account_operations': 'عمليات الحساب الأخرى',
    'reports.Out of session trading is forbidden': 'التداول خارج الجلسة ممنوع',
    'reports.Overnight trading is forbidden for account': 'ممنوع جلسة التداول المسائية للحساب.',
    'reports.PAMM account can not be removed during investment period.': 'لا يمكن حذف الصندوق خلال فترة الاستثمار',
    'reports.PAMM join': 'الانضمام للصندوق',
    'reports.pamm.OrderByPnL.max_loss': 'أكبر خسارة',
    'reports.pamm.OrderByPnL.max_profit': 'أكبر ربح',
    'reports.pamm.OrderByPnL.min_loss': 'أصغر خسارة',
    'reports.pamm.OrderByPnL.min_profit': 'أصغر ربح',
    'reports.pamm.OrderByPnL.none': 'لا يوجد',
    'reports.pamm.OrderBySize.max': 'أكبر مركز',
    'reports.pamm.OrderBySize.min': 'أصغر مركز',
    'reports.pamm.OrderByTime.first': 'أول افتتاح',
    'reports.pamm.OrderByTime.last': 'آخر افتتاح',
    'reports.pammState.0': 'جديد',
    'reports.pammState.1': 'الاستثمار',
    'reports.pammState.2': 'انتهاء الاستثمار',
    'reports.pammState.3': 'تصفية',
    'reports.pammState.4': 'انتظار المستثمرون',
    'reports.pammState.6': 'بدء الاستثمار',
    'reports.pammState.clearing': 'تصفية',
    'reports.pammState.confirmed': 'مؤكد',
    'reports.pammState.end_of_investment': 'انتهاء الاستثمار',
    'reports.pammState.investment': 'الاستثمار',
    'reports.pammState.Investment': 'الاستثمار',
    'reports.pammState.new': 'جديد',
    'reports.pammState.pending': 'معلق',
    'reports.pammState.start_of_investment': 'بدء الاستثمار',
    'reports.pammState.wait_for_investors': 'انتظار المستثمرون',
    'reports.PAMMTransfer': 'تحويل الصندوق',
    'reports.Partially filled': 'منفذ جزئي',
    'reports.Partially filled close Limit order': 'إقفال أمر حد منفذ جزئي',
    'reports.Partially filled Close Limit Order': 'إقفال أمر حد منفذ جزئي',
    'reports.Partially filled close Limit order (Mutual)': 'إقفال أمر حد منفذ جزئي (متبادل)',
    'reports.Partially filled close Market order': 'إقفال أمر سوق منفذ جزئي',
    'reports.Partially filled Close Market Order': 'إقفال أمر سوق منفذ جزئي',
    'reports.Partially filled close Market order (Mutual)': 'إقفال أمر سوق منفذ جزئي (متبادل)',
    'reports.Partially filled close OCO order': 'اقفال أمر OCO منفذ جزئي',
    'reports.Partially filled close OCO order (Mutual)': 'اقفال أمر OCO منفذ جزئي (متبادل)',
    'reports.Partially filled close SL order': 'إقفال أمر  إ.ج منفذ جزئي',
    'reports.Partially filled close SL order (Mutual)': 'إقفال أمر  إ.ج منفذ جزئي (متبادل)',
    'reports.Partially filled close SL tr. stop order': 'إقفال أمر إ.خ وقف متحرك',
    'reports.Partially filled close SL tr. stop order (Mutual)': 'إقفال أمر إ.خ وقف متحرك (متبادل)',
    'reports.Partially filled close Stop Limit order': 'إقفال أمر حد وقف منفذ جزئي',
    'reports.Partially filled close Stop Limit order (Mutual)': 'إقفال أمر حد وقف منفذ جزئي (متابل)',
    'reports.Partially filled close Stop order': 'إقفال أمر وقف منفذ جزئي',
    'reports.Partially filled Close Stop Order': 'إقفال أمر وقف منفذ جزئي',
    'reports.Partially filled close Stop order (Mutual)': 'إقفال أمر وقف منفذ جزئي (متبادل)',
    'reports.Partially filled close TP order': 'إقفال أمر أ.ر منفذ جزئي',
    'reports.Partially filled close TP order (Mutual)': 'إقفال أمر أ.ر منفذ جزئي (متبادل)',
    'reports.Partially filled close Tr. stop order': 'إقفال أمر وقف متحرك  منفذ جزئي',
    'reports.Partially filled close Tr. stop order (Mutual)': 'إقفال أمر وقف متحرك  منفذ جزئي (متبادل)',
    'reports.Partially filled Limit order': 'أمر حد منفذ جزئي',
    'reports.Partially filled Limit order (Mutual)': 'أمر حد منفذ جزئي (متبادل)',
    'reports.Partially filled Market order': 'أمر سوق منفذ جزئي',
    'reports.Partially filled Market Order': 'أمر سوق منفذ جزئي',
    'reports.Partially filled Market order (Mutual)': 'أمر سوق منفذ جزئي (متبادل)',
    'reports.Partially filled OCO order': 'أمر  OCO منفذ جزئي',
    'reports.Partially filled OCO order (Mutual)': 'أمر  OCO منفذ جزئي (متبادل)',
    'reports.Partially filled SL order': 'أمر إ.خ منفذ جزئي',
    'reports.Partially filled SL order (Mutual)': 'أمر إ.خ منفذ جزئي (متبادل)',
    'reports.Partially filled SL tr. stop order': 'أمر إ.خ متابع منفذ جزئي',
    'reports.Partially filled SL tr. stop order (Mutual)': 'أمر إ.خ متابع منفذ جزئي (متبادل)',
    'reports.Partially filled Stop Limit order': 'أمر إ.خ حدد منفذ جزئي',
    'reports.Partially filled Stop Limit order (Mutual)': 'أمر إ.خ حدد منفذ جزئي (متبادل)',
    'reports.Partially filled Stop order': 'أمر إقاف منفذ جزئي',
    'reports.Partially filled Stop order (Mutual)': 'أمر إقاف منفذ جزئي (متبادل)',
    'reports.Partially filled TP order': 'أمر أ.ر منفذ جزئي',
    'reports.Partially filled TP order (Mutual)': 'أمر أ.ر منفذ جزئي (متبادل)',
    'reports.Partially filled Tr. stop order': 'أمر متابع واقف منفذ جزئي',
    'reports.Partially filled Tr. stop order (Mutual)': 'أمر متابع واقف منفذ جزئي (متبادل)',
    'reports.Partially filled Traling Stop Order': 'أمر متابع واقف منفذ جزئي',
    'reports.Password required with level - 1': 'كلمة المرور غير صحيحة. يجب أن تحتوي على الأقل على حرف كبير واحد وحرف كبير واحد ورقم واحد. الرجاء المحاولة مرة أخرى.',
    'reports.Password required with level - 2': 'كلمة المرور غير صحيحة. يجب أن تحتوي على الأقل على حرف كبير واحد وحرف كبير واحد ورقم واحد. وطولها 8 أحرف على الأقل. الرجاء المحاولة مرة أخرى.',
    'reports.Password required with level - 3': 'كلمة المرور غير صحيحة. يجب أن تحتوي على الأقل على حرف كبير واحد وحرف كبير واحد ورقم واحد. وطولها 8 أحرف على الأقل ورمز خاص على الأقل. الرجاء المحاولة مرة أخرى.',
    'reports.PatsystemsFIXFeed': 'PatsystemsFIXFeed',
    'reports.Pending': 'معلق',
    'reports.pendingorderscount': 'عدد الأوامر المعلقة',
    'reports.PeresysFIX': 'PeresysFIX',
    'reports.Perfomance By Signal Report': 'الأداء من تقرير المؤشر',
    'reports.Perform': 'تأدية',
    'reports.Performance history report': 'تقرير تاريخ الأداء',
    'reports.PFixFeed': 'PFixFeed',
    'reports.phone': 'الهاتف',
    'reports.phonepassword': 'كملة مرور الهاتف',
    'reports.pin': 'الرمز السري',
    'reports.pl_unrealized': 'ر/خ غير محققة',
    'reports.Placed': 'تم',
    'reports.Please deposit on your account.': 'الرجاء الإيداع في حسابك',
    'reports.pnl': 'ر/خ',
    'reports.port': 'المنفذ',
    'reports.posId': 'رقم الوضعية',
    'reports.Position': 'الوضعية',
    'reports.Position close request': 'طلب إغلاق الوضعية',
    'reports.Position Closed': 'المركزمغلقة',
    'reports.Position Closed (Mutual)': 'المركز مغلق (تبادلياً)',
    'reports.Position Closed (SL)': 'المركز مغلق (إ.خ)',
    'reports.Position Closed (TP)': 'المركز مغلق (أ.ر)',
    'reports.Position closed successfully': 'تم إغلاق المركز بنجاح',
    'reports.POSITION MODIFIED': 'تم تعديل المركز',
    'reports.Position modify request': 'طلب تعديل الوضعية',
    'reports.Position number': 'رقم الوضعية',
    'reports.positionOpened': 'تم فتح المركز',
    'reports.Position Opened (Limit Order)': 'تم فتح المركز (أمر المحدد)',
    'reports.Position Types Ratio By Instrument': 'نسبة أنواع المراكز بالرمز',
    'reports.Position: {1} closed successfully': 'المركز: {1} مغلق بنجاح',
    'reports.positionamount': 'كمية المركز',
    'reports.positionid': 'رقم المركز',
    'reports.positionId': 'رقم المركز',
    'reports.PositionId': 'رقم المركز',
    'reports.Positions History Report': 'تقرير سجل المراكز',
    'reports.Positions On Chosen Moment Report': 'تقرير المراكز في فترات معينة',
    'reports.POSITIONS_PANEL_HOT_BTN': 'لوحة المراكز (أزرار الاختصار)',
    'reports.positionstatus': 'حالة المركز',
    'reports.price': 'السعر',
    'reports.Price': 'السعر',
    'reports.Price is too close to current Ask price': '',
    'reports.Price is too close to current Bid price': '',
    'reports.priceExchange': 'صرف السعر',
    'reports.priceExternal': 'سعر خارجي',
    'reports.product type': '',
    'reports.productType': 'نوع المنتج',
    'reports.PROFILE_LANGUAGE': 'لغة الملف',
    'reports.profit': 'الربح',
    'reports.Projected balance': 'الرصيد المتوقع ',
    'reports.protocol': 'البروتوكول',
    'reports.ProTrader': 'Protrader',
    'reports.ProTraderFIX': 'ProTraderFIX',
    'reports.Purchase And Sales Report': 'تقرير المبيعات والمشتريات',
    'reports.purchaseprice': 'التعرض',
    'reports.quantity': 'الكمية',
    'reports.QueueMaxSize': 'أقصى حد للطابور',
    'reports.quicktable.noData': 'لا توجد بيانات للعرض. الرجاء اختيار خصائص التقرير المطلوبة للحصول على المعلومات المطلوبة.',
    'reports.Quote is too old': 'العرض قديم',
    'reports.QuoteDelay(sec)': 'تأخير العرض (ثانية)',
    'reports.quoteticker': 'مؤشر السعر',
    'reports.raor_administrator': 'الوسيط',
    'reports.raor_approvetime': 'وقت الموافقة',
    'reports.raor_balance': 'من رصيد الحساب',
    'reports.raor_basis': 'تعليقات',
    'reports.raor_commission_percent': 'العمولة بالنسبة ',
    'reports.raor_counterbalance': 'إلى رصيد الحساب',
    'reports.raor_countersum': 'إلى مجموع الحساب',
    'reports.raor_crossprice': 'السعر المتقابل',
    'reports.raor_fromaccount': 'من الحساب',
    'reports.raor_operationtime': 'وقت العملية',
    'reports.raor_operationtype': 'نوع العملية',
    'reports.raor_requestid': 'رقم العملية',
    'reports.raor_state': 'حالة الموافقة',
    'reports.raor_sum': 'من مجموع الحساب',
    'reports.raor_sum_without_commission': 'المجموع من غير العمولة',
    'reports.raor_toaccount': 'إلى الحساب',
    'reports.rate': 'المعدّل',
    'reports.Rates Report': 'تقرير المعدّلات',
    'reports.Realised profit/loss': 'الربح/الخسارة الحقيقي',
    'reports.Reason for rejection: Trading disabled by risk rules. .': 'سبب الرفض: التداول معطل بسبب قواعد المخاطر',
    'reports.receiveBestBidOfferOnly': 'استقبال أفضل طلبات الشراء فقط',
    'reports.recipient': 'المستقبل',
    'reports.ReconnectInterval': 'الفاصل بيع إعادة الاتصال',
    'reports.refreshInterval': 'الفاصل بين التحديث',
    'reports.RefreshonLogon': 'التحديث عند تسجيل الدخول',
    'reports.RefreshOnLogon': 'تحديث على تسجيل الدخول',
    'reports.Refused': 'تم رفضه',
    'reports.Refused Amount': 'تم رفض الكمية',
    'reports.Rejected': 'مرفوض',
    'reports.remoteid': 'رقم وحدة التحكم',
    'reports.Remove SL for': 'حذف إ.خ لـ',
    'reports.Removed Orders Report': 'تقرير الأوامر المحذوفة',
    'reports.removedtime': 'وقت الحذف',
    'reports.removeliquidityrebate': 'خصومات سيولة الحذف',
    'reports.Replace request received by STP': 'تم تلقي طلب الاستبدال بواسطة التنفيذ المباشر',
    'reports.REPORT_RULES': 'مرئية التقرير',
    'reports.report.currency': 'العملة',
    'reports.report.route': 'مسار',
    'reports.reportAccountSummaryReport': 'تقرير حالة الحساب',
    'reports.reportAllActiveOrdersReport': 'تقرير بالأوامر النشطة',
    'reports.reportAllNotActiveOrdersReport': 'تقرير الأوامر المنفذة',
    'reports.reportAllRemovedOrdersReport': 'تقرير الأوامر المحذوفة',
    'reports.reportBalanceByInstrument': 'تقرير الرصيد بحسب الرمز',
    'reports.reportBalanceReport': 'تقرير الرصيد',
    'reports.reportFormalDialogReport': 'تقرير المحاورة الرسمية',
    'reports.reportInformalDialogReport': 'تقرير سجل المحادثة',
    'reports.reportNewUsersReport': 'تقرير بالمستخدمين الجدد',
    'reports.reportOpenedPositionReport': 'تقرير المراكز المفتوحة',
    'reports.reportPerfomanceReport': 'تقرير الأداء',
    'reports.reportPurchaseAndSalesReport': 'تقرير المبيعات والمشتريات',
    'reports.reportSummaryNewReport': 'تقرير الملخص',
    'reports.reportSummaryReport': 'تقرير ملخص الرصيد',
    'reports.reportTradeOperationReport': 'تقرير عمليات التداول',
    'reports.reportTradesReport': 'تقرير التداولات',
    'reports.reportUsersReport': 'تقرير المستخدمون',
    'reports.Request Account Operation Report': 'تقرير طلبات عمليات الحساب',
    'reports.Request is not allowed for this account': 'الطلب غير مسموح به لهذا الحساب',
    'reports.Request not supported': 'لا يتم اعتماد الطلب.',
    'reports.RequestAccountOperationReport': 'تقرير طلبات عمليات الحساب',
    'reports.Required Balance': 'الرصيد المطلوب',
    'reports.REQUIRED_STRONG_PASSWORDS': 'كلمة المرور القوية مطلوبة',
    'reports.ResetOnDisconnect': 'إعادة الضبط عند قطع الاتصال',
    'reports.ResetOnLogon': 'إعادة الضبط عند تسجيل الدخول',
    'reports.ResetOnLogout': 'إعادة الضبط عند تسجيل الخروج',
    'reports.Restated': '',
    'reports.Reuters': 'رويترز',
    'reports.Revenue plan': 'خطة الارباح',
    'reports.REVENUE_PLAN_GENERAL': 'عام',
    'reports.revenue.name': 'اسم خطة الربح',
    'reports.revenue.speccrossinstrument': 'رمز مضاد محدد',
    'reports.revenue.speccurrency': 'عملة محددة',
    'reports.Reverse': 'عكس',
    'reports.reward_type.capital_percent': 'رأس المال، %',
    'reports.reward_type.fixed': 'ثابت',
    'reports.reward_type.fixed_and_percent': 'ثابت + نمو فترة الاستثمار، %',
    'reports.reward_type.no': 'لا',
    'reports.reward_type.percent': 'نمو فترة الاستثمار',
    'reports.role': 'الدور',
    'reports.Rollback': 'التراجع',
    'reports.Rollback position': 'وضعية الاستعادة',
    'reports.Rollback position request': 'طلب تراجع المركز',
    'reports.route': 'مسار',
    'reports.Route': 'مسار',
    'reports.Route Trading Statistics Report': 'تقرير احصائيات مسار التداول',
    'reports.Route Visibility': 'تقرير مرئية المسار',
    'reports.Route Visibility Report': 'تقرير مرئية المسار',
    'reports.RouteInstrMap': 'خريطة الرمز',
    'reports.routename': 'مسار',
    'reports.routes': 'المسارات',
    'reports.routes.account': 'حساب الأخبار المرتبط',
    'reports.routes.allow_day': 'اليوم',
    'reports.routes.allow_foc': '"(FOK) تنفيذ كامل فوري أو إلغاء',
    'reports.routes.allow_gtc': 'صالح حتى الإلغاء',
    'reports.routes.allow_ioc': '(IOC) فوري أو إلغاء',
    'reports.routes.allow_market': 'السوق',
    'reports.routes.allow_moc': 'السماح بالسوق عند الإغلاق',
    'reports.routes.allow_moo': 'السماح بالسوق عند الافتتاح',
    'reports.routes.allow_stop': 'إيقاف',
    'reports.routes.allow_stoplimit': 'محدد',
    'reports.routes.allow_stoptolimit': 'حد الإيقاف',
    'reports.routes.route_comment': 'تعليق',
    'reports.routes.route_external_feed': 'مزوّد السيولة',
    'reports.routes.route_isactive': 'نشط',
    'reports.routes.route_isinfo': 'معلومات',
    'reports.routes.route_istrade': 'تداول',
    'reports.routes.route_login': 'اسم المستخدم',
    'reports.routes.route_name': 'الاسم',
    'reports.routes.route_password': 'كلمة المرور',
    'reports.routes.route_saveQuotes': 'حفظ لسجّل منفصل',
    'reports.routes.route_status': 'الحالة',
    'reports.ROUTESVIS_RULES': 'مرئية المسارات',
    'reports.RssNews': 'RssNews',
    'reports.RTS': 'RTS',
    'reports.rules.error.restriction.max': 'يجب أن يكون الرقم أقل',
    'reports.rules.error.restriction.min': 'يجب أن يكون الرقم أكبر',
    'reports.SAVED_ORDERS': 'الأوامر المحفوظة',
    'reports.saveLogs': 'حفظ السجلات',
    'reports.saveNews': 'حفظ الأخبار',
    'reports.Saxo Bank': 'Saxo Bank',
    'reports.SCALPER': 'مضارب',
    'reports.Scheduler Log Report': 'تقرير سجّل المجدول',
    'reports.scheduler.accountoperation_archive': 'أرشيف عمليات الحساب',
    'reports.scheduler.dailyreport': 'إرسال تقرير',
    'reports.scheduler.dividentsservice': 'تقسيم الأرباح على المراكز',
    'reports.scheduler.lastexcecuted': 'التنفيذ في',
    'reports.scheduler.modifyRuleTask': 'تعديل المهمة',
    'reports.scheduler.movepositiontask': 'مهمة تحريك المركز',
    'reports.scheduler.openclosetradingfor': 'تداول مفتوح/مغلق',
    'reports.scheduler.opentradingfor': 'تداول مفتوح',
    'reports.scheduler.parameter.backup': 'مستوى الدعم',
    'reports.scheduler.parameter.calculateByClosePrice': 'استخدام سعر الإغلاق الحالي',
    'reports.scheduler.parameter.calculateStrategy': 'نوع الحساب',
    'reports.scheduler.parameter.coeff1': 'الجزء 1',
    'reports.scheduler.parameter.coeff2': 'الجزء 2',
    'reports.scheduler.parameter.comment': 'التعليق على عملية الحساب',
    'reports.scheduler.parameter.counteraccount': 'الحساب المضاد',
    'reports.scheduler.parameter.days_before_exp': 'الأيام حتى الانتهاء',
    'reports.scheduler.parameter.divident': 'كمية الأرباح',
    'reports.scheduler.parameter.dividentmode': 'نوع الأرباح',
    'reports.scheduler.parameter.email': 'البريد الإلكتروني (استخدم الفاصلة بين العناوين)',
    'reports.scheduler.parameter.inheritUserGroups': 'شمل المجموعات الفردية',
    'reports.scheduler.parameter.instrtype': 'مجموعة الرمز',
    'reports.scheduler.parameter.instrument': 'الرمز',
    'reports.scheduler.parameter.isopen': 'تداول مفتوح/مغلق',
    'reports.scheduler.parameter.isupdateall': 'تحديث جميع الأدوات في المجموعة',
    'reports.scheduler.parameter.margincoeff': 'معامل الهامش (بحسب الوقت)',
    'reports.scheduler.parameter.max': 'الحد الأقصى (%)',
    'reports.scheduler.parameter.message': 'رسالة',
    'reports.scheduler.parameter.min': 'الحد الأدنى (%)',
    'reports.scheduler.parameter.operations': 'نوع العملية',
    'reports.scheduler.parameter.owner': 'العنصر',
    'reports.scheduler.parameter.ownerType': 'نوع العنصر',
    'reports.scheduler.parameter.putinaleerts': 'الوضع في التنبيهات',
    'reports.scheduler.parameter.reconciliation.ClientId': 'رقم العميل للتسوية',
    'reports.scheduler.parameter.reconciliation.Direction': 'الاتجاه',
    'reports.scheduler.parameter.reconciliation.FromDate': 'بدء التسوية من التاريخ',
    'reports.scheduler.parameter.reconciliation.Route': 'مسار',
    'reports.scheduler.parameter.reconciliation.SendItemName': 'إرسال اسم الملف',
    'reports.scheduler.parameter.reconciliation.Storage': 'نوع التخزين',
    'reports.scheduler.parameter.reconciliation.Transport': 'نوع النقل',
    'reports.scheduler.parameter.reconciliation.transportDestination': 'الوجهة',
    'reports.scheduler.parameter.reconciliation.transportLogin': 'تسجيل الدخول للتصديق',
    'reports.scheduler.parameter.reconciliation.transportPassword': 'كلمة مرور التصديق',
    'reports.scheduler.parameter.reconciliation.Type': 'نوع التسوية',
    'reports.scheduler.parameter.reports': 'اختر تقرير',
    'reports.scheduler.parameter.revenueplan': 'اختر خطة الربح',
    'reports.scheduler.parameter.routes': 'يجب أن يكون كل مسار نشط',
    'reports.scheduler.parameter.routesName': 'شمل المسارات',
    'reports.scheduler.parameter.rule': 'القاعدة',
    'reports.scheduler.parameter.ruleType': 'نوع القاعدة',
    'reports.scheduler.parameter.ruleView': 'القيمة',
    'reports.scheduler.parameter.sum': 'الكمية بعملة النظام',
    'reports.scheduler.parameter.swapCoefKey': 'معامل التبادل',
    'reports.scheduler.parameter.userGroup': 'مجموعة المستخدم',
    'reports.scheduler.parameter.usergroups': 'اختر مجموعة المستخدمين',
    'reports.scheduler.removedo': 'تشغيل خدمة إزالة أوأمر اليوم',
    'reports.scheduler.rolloverservice': 'تشغيل انتقال العقود المستقبلية',
    'reports.scheduler.runinterestservice': 'تشغيل الفائدة على خدمة الرصيد',
    'reports.scheduler.runsignalsubscribtion': 'تشغيل مصاريف العمولة اليومية للإشارة',
    'reports.scheduler.runswaptask': 'تشغيل التبادل',
    'reports.scheduler.splitinstrument': 'تقيم الرمز',
    'reports.scheduler.type.accountoperation': 'عملية الحساب',
    'reports.scheduler.type.backup': 'إنشاء نسخة احتياطية',
    'reports.scheduler.type.beginbalance': 'بدء الرصيد',
    'reports.scheduler.type.broadcastmessage': 'رسالة البث على الإنترنت',
    'reports.scheduler.type.changemargincoeff': 'معامل الهامش (بحسب الوقت)',
    'reports.scheduler.type.changerevenue': 'تغير خطة الربح',
    'reports.scheduler.type.closeoptions': 'إخفاء الرموز المنتهية',
    'reports.scheduler.type.deletedemousers': 'حذف المستخدمون التجريبيون المنتهون',
    'reports.scheduler.type.deletequotes': 'حذف العروض القديمة',
    'reports.scheduler.type.fixswapplan': 'حساب خطة التبادل الثابتة',
    'reports.scheduler.type.futuresrollover': 'تشغيل انتقال العقود المستقبلية',
    'reports.scheduler.type.insertlastquotestask': 'أدخل آخر العروض',
    'reports.scheduler.type.lockbybroker': 'مغلق من الوسيط',
    'reports.scheduler.type.message': 'رسالة',
    'reports.scheduler.type.modifyobject': 'تعديل العنصر',
    'reports.scheduler.type.pamm': 'مهمة الصندوق',
    'reports.scheduler.type.pendingpayment': 'المدفوعات المعلقة',
    'reports.scheduler.type.reconciliation': 'مهمة التسوية',
    'reports.scheduler.type.reconnect_routes': 'بدء/إيقاف المسارات النشطة',
    'reports.scheduler.type.subscribe': 'الحصول على مدفوعات الاشتراك',
    'reports.scheduler.type.unknown': 'نوع غير معروف',
    'reports.scheduler.type.updatespikefilter': 'تحديث فيلتر الشوكة',
    'reports.scheduler.type.updateuserstradinginfo': 'تحديث بيانات احصائيات تداول المستخدمين',
    'reports.ScreenLogEvents': 'أحداث شاشة السجل',
    'reports.ScreenLogShowHeartBeats': 'عرض نبضات سجل الشاشة',
    'reports.ScreenLogShowIncoming': 'عرض الدواخل سجل الشاشة',
    'reports.ScreenLogShowOutgoing': 'عرض الخوارج سجل الشاشة',
    'reports.Script': 'النص',
    'reports.SDK': 'SDK',
    'reports.seconds': 'الثواني',
    'reports.SecuritiesTransferTax': 'ضريبة نقل الضمانات',
    'reports.Self trading is not allowed.': '!غير مسموح التداول النفس',
    'reports.Sell': 'بيع',
    'reports.sell trade amount': 'كمية تداول البيع',
    'reports.sell trades': 'تداولات البيع',
    'reports.Send from': 'الإرسال من',
    'reports.Send_Rej': 'رفض',
    'reports.sender': 'المرسل',
    'reports.SenderCompID': 'رقم كمبيوتر المرسل',
    'reports.senderip': 'رقم IP للمرسل',
    'reports.SendResetSeqNumFlag': 'إرسال إعادة ضبط رقم التسلسل',
    'reports.sendTag200': 'إرسال Tag200',
    'reports.server': 'السيرفر',
    'reports.server_url': 'رابط السيرفر',
    'reports.SERVER.AUTO.RECONNECT': 'SERVER AUTO RECONNECT',
    'reports.SERVER.POLLING.INTERVAL': 'SERVER POLLING INTERVAL',
    'reports.serviceName': 'اسم الخدمة',
    'reports.sessionid': 'رقم الجلسة',
    'reports.sessiontype': 'نوع الجلسة',
    'reports.SETTINGS_FILTER': 'فلتر',
    'reports.SETTINGS_GENERAL': 'عام',
    'reports.SETTINGS_LOCKING': 'غلق',
    'reports.SETTINGS_QUOTE_CACHE_SIZE': 'إعدادات حجم الذاكرة المؤقتة للعروض',
    'reports.SETTINGS_QUOTE_SAVE': 'إعدادات حفظ العروض',
    'reports.SETTINGS_QUOTES': 'العروض',
    'reports.SETTINGS_REGISTRATION': 'التسجيل',
    'reports.SETTINGS_TRADING': 'التداول',
    'reports.SettleDate': 'تاريخ الانتهاء',
    'reports.SettleDateForward': 'تاريخ القيمة',
    'reports.SettlementFee': 'رسوم التسوية',
    'reports.Sharia Operations Report': 'تقرير العمليات الشرعية',
    'reports.signedrequest': 'طلب موقع عليه',
    'reports.sizeForQuoteSubscription': 'الحجم للاشتراك في العروض',
    'reports.SL': 'إيقاف الخسارة',
    'reports.SL accepted by broker': 'تم قبول أمر إ.خ بواسطة الوسيط',
    'reports.SL accepted by STP': 'تم قبول أمر إ.خ بواسطة التنفيذ المباشر',
    'reports.SL order accepted': 'تم قبول أمر إ.خ ',
    'reports.SL order cancel request': 'طلب إلغاء أمر إ.خ',
    'reports.SL order canceled': 'تم إلغاء أمر إ.خ',
    'reports.SL order created': 'تم إنشاء أمر إ.خ',
    'reports.SL Order Created': 'تم إنشاء أمر إ.خ',
    'reports.SL order created is off market': '',
    'reports.SL order created is unplaced': '',
    'reports.SL Order Modified': 'تم تعديل أمر إ.خ',
    'reports.SL order partially reject': 'تم إلغاء أمر إ.خ جزئياً',
    'reports.SL order placing request': 'طلب وضع أمر إ.خ',
    'reports.SL order received by broker': 'تم تلقي أمر إ.خ من الوسيط',
    'reports.SL order rejected': 'تم رفض أمر إ.خ',
    'reports.SL Order Removed': 'تم حذف أمر إ.خ',
    'reports.SL order replace request': 'طلب استبدال أمر إ.خ',
    'reports.SL order replaced': 'تم تعديل أمر إ.خ',
    'reports.SL price': 'سعر إ.خ',
    'reports.SL tr. stop': 'التوقف المتحرك إيقاف الخسارة',
    'reports.SL tr. stop accepted by broker': 'تم قبول أمر التوقف المتحرك إ.خ بواسطة الوسيط',
    'reports.SL tr. stop accepted by STP': 'تم قبول أمر التوقف المتحرك إ.خ بواسطة التنفيذ المباشر',
    'reports.SL tr. stop order accepted': 'تم قبول أمر إ.خ المتحرك',
    'reports.SL tr. stop order canceled': 'تم إلغاء أمر إ.خ المتحرك',
    'reports.SL tr. stop order created': 'تم إنشاء أمر إ.خ المتحرك',
    'reports.SL tr. stop order created is off market': '',
    'reports.SL tr. stop order created is unplaced': '',
    'reports.SL tr. stop order partially reject': 'تم إلغاء أمر التوقف المتحرك إ.خ جزئياً',
    'reports.SL tr. stop order received by broker': 'تم تلقي أمر التوقف المتحرك إ.خ من الوسيط',
    'reports.SL tr. stop order rejected': 'تم رفض أمر التوقف المتحرك إ.خ',
    'reports.SL tr. stop order replaced': 'تم تغيير أمر التوقف المتحرك إ.خ',
    'reports.SmartRoute(aggregator)': 'SmartRoute (المجمع)',
    'reports.Sms generation error': 'خطأ جيل SMS',
    'reports.SMTP server required a secure connection, or identity of the client has not been established.': ' الخادم SMTP يتطلب اتصال آمن، أو هوية العميل لم يتم تأسيسها.',
    'reports.SOAP API methods are not available for execution for this user': 'SOAP API methods are not available for execution for this user',
    'reports.SocketConnectHost': 'مضيف سوكيت الاتصال',
    'reports.SocketConnectPort': 'منفذ سوكيت الاتصال',
    'reports.SocketKeyStore': 'مخزن شهادات السوكيت',
    'reports.SocketKeyStorePassword': 'كلمة مرور مخزن شهادات السوكيت',
    'reports.SocketUseSSL': 'سوكيت يستخدم SSL',
    'reports.SplitOrders': 'تقسيم الأوامر',
    'reports.spread': 'فرق السعر',
    'reports.Spread Amount Report': 'تقرير كمية فرق السعر',
    'reports.spreadpnl': 'فرق سعر ر/خ',
    'reports.Start receiving data from server...': 'إبدء بتلقي البينات من السرفر',
    'reports.StartDay': 'يوم البدء',
    'reports.StartTime': 'وقت البدء',
    'reports.STATIONS_RULES': 'المحطات',
    'reports.Statistic Report': 'تقرير الاحصائية',
    'reports.STATUS_BLOCKED': 'محظور',
    'reports.stock_req': 'Stock orders req',
    'reports.stock_value': 'قيمة السهم ',
    'reports.StockOperation': 'عملية الأسهم',
    'reports.stocks_liquidity': 'سيولة الأسهم ',
    'reports.Stop accepted by broker': 'تم قبول أمر الإيقاف بواسطة الوسيط',
    'reports.Stop accepted by STP': 'تم قبول أمر الإيقاف بواسطة التنفيذ المباشر',
    'reports.Stop Limit accepted by broker': 'تم قبول أمر إقاف محدد بواسطة الوسيط',
    'reports.Stop Limit accepted by STP': 'تم قبول أمر إقاف محدد بواسطة التنفيذ المباشر',
    'reports.Stop Limit order accepted': 'تم قبول أمر إقاف محدد ',
    'reports.Stop Limit order activated': '',
    'reports.Stop limit order cancel request': 'طلب إلغاء أمر إقاف محدد',
    'reports.Stop limit order canceled': 'تم إلغاء أمر إقاف محدد',
    'reports.Stop Limit order canceled': 'تم إلغاء أمر إقاف محدد',
    'reports.Stop Limit order created': 'تم إنشاء أمر إقاف محدد',
    'reports.Stop Limit Order Created': 'تم إنشاء أمر إقاف محدد',
    'reports.Stop Limit order created is off market': '',
    'reports.Stop Limit order created is unplaced': '',
    'reports.Stop limit order is awaiting market': 'أمر وقف حد بإنتظار السوق',
    'reports.Stop Limit Order Modified': 'تم تعديل أمر إقاف محدد',
    'reports.Stop Limit order partially reject': 'تم إلغاء أمر حد الإيقاف جزئياً',
    'reports.Stop limit order placing request': 'طلب وضع أمر حد الإيقاف',
    'reports.Stop Limit order received by broker': 'تم تلقي أمر حد الإيقاف من الوسيط',
    'reports.Stop Limit order rejected': 'تم رفض أمر حد الإيقاف',
    'reports.Stop Limit Order Removed': 'تم حذف أمر إقاف محدد',
    'reports.Stop limit order replace request': 'طلب رفض أمر حد الإيقاف',
    'reports.Stop limit order replaced': 'تم تعديل أمر إقاف محدد',
    'reports.Stop Limit order replaced': 'تم تعديل أمر إقاف محدد',
    'reports.Stop Limit order restated': '',
    'reports.Stop loss order canceled': 'تم إلغاء أمر وقف الخسارة ',
    'reports.Stop Loss price is too big': 'سعر إ.خ للأمر غير صحيح',
    'reports.Stop order accepted': 'تم قبول أمر الإقاف',
    'reports.Stop order activated': '',
    'reports.Stop order cancel request': 'طلب إلغاء أمر الإيقاف',
    'reports.Stop order canceled': 'تم إلغاء أمر الإيقاف',
    'reports.Stop order created': 'تم إنشاء أمر الايقاف',
    'reports.Stop Order Created': 'تم إنشاء أمر الايقاف',
    'reports.Stop order created is off market': '',
    'reports.Stop order created is unplaced': '',
    'reports.Stop order is awaiting market': 'أمر وقف بإنتظار السوق',
    'reports.Stop Order Modified': 'تم تعديل أمر الإيقاف',
    'reports.Stop order partially reject': 'تم إلغاء أمر الإيقاف جزئياً',
    'reports.Stop order placing request': 'طلب وضع أمر الإيقاف',
    'reports.Stop order received by broker': 'تم تلقي أمر الإيقاف من الوسيط',
    'reports.Stop order rejected': 'تم رفض أمر الإيقاف',
    'reports.Stop Order Removed': 'تم حذف أمر الإيقاف',
    'reports.Stop order replace request': 'طلب استبدال أمر الإيقاف',
    'reports.Stop order replaced': 'تم تعديل أمر الإيقاف',
    'reports.Stop order restated': '',
    'reports.Stop price': 'سعر الإقاف',
    'reports.stoploss': 'إيقاف الخسارة',
    'reports.StopOutType': 'نوع الخروج',
    'reports.stopprice': 'سعر الإيقاف',
    'reports.STP_Route_Change': 'ضبط اسم المسار الأصلي في الأمر',
    'reports.Strike Price': 'سعر الضربة',
    'reports.submitted': 'تم الإرسال',
    'reports.Subscribe': 'اشتراك',
    'reports.SUBSCRIBE_RULES': 'خطط الاشتراك',
    'reports.success': 'نجاح',
    'reports.Successful': 'ناجح',
    'reports.sum': 'المجموع',
    'reports.Sum': 'المجموع',
    'reports.Summary Report': 'تقرير الملخص',
    'reports.SunGard': 'SunGard',
    'reports.SUSPEND_MARGINCALL_ON_CLOSE_MARKET': 'تعليق مساعدة استعداء الهامش للأسواق المغلقة',
    'reports.swap': 'المبادلة',
    'reports.swapbuy': 'مبادلة طويلة',
    'reports.swapplan': 'خطة المبادلة',
    'reports.swaps': 'المبادلة',
    'reports.swaps_in': 'مبادلة رابحة',
    'reports.swaps_out': 'مبادلة خاسرة',
    'reports.swapsell': 'مبادلة قصيرة',
    'reports.symbol': 'الرمز',
    'reports.Symbol': 'الرمز',
    'reports.symbol_type': 'نوع الرمز ',
    'reports.System': 'النظام',
    'reports.system_currency': 'عملة النظام',
    'reports.systemMonitor.instruments': 'الرموز',
    'reports.systemMonitor.onlineConnections': 'الاتصالات المتصلة',
    'reports.systemMonitor.positions': 'المراكز المفتوحة',
    'reports.systemMonitor.regusers': 'المستخدمون المسجلون',
    'reports.systemMonitor.runningRoutes': 'المسارات التي بدأت من المسارات النشطة',
    'reports.systemMonitor.totalQueueSize': 'الطوابير في الخدمات',
    'reports.TABLE_ALERT': 'تنبيه الجدول',
    'reports.tablename': 'اسم الجدول',
    'reports.TableName': 'اسم الجدول',
    'reports.Take profit order canceled': 'تم الغاء أمر أخذ الربح',
    'reports.Take Profit price is too big': 'سعر أخذ الربح كبير',
    'reports.takeprofit': 'أخذ الربح',
    'reports.TargetCompID': 'رقم كمبيوتر الهدف',
    'reports.task.comment': 'تعليق',
    'reports.task.day': 'يوم',
    'reports.task.hour': 'ساعة',
    'reports.task.minute': 'دقيقة',
    'reports.task.month': 'شهر',
    'reports.task.timezone': 'تعويض المنطقة الزمنية',
    'reports.task.usedst': 'استخدم التوقيت الصيفي',
    'reports.task.year': 'سنة',
    'reports.TempFilePath': 'مسار الملف المؤقت',
    'reports.Tenfore': 'Tenfore',
    'reports.terminal': 'المحطة',
    'reports.Terminal': 'Terminal',
    'reports.TERMINAL_API': 'API',
    'reports.TERMINAL_COMMUNITY': 'المجتمع',
    'reports.TERMINAL_FIX': 'FIX Connection',
    'reports.TERMINAL_HTML': 'HTML',
    'reports.TERMINAL_JAVA': 'Java',
    'reports.TERMINAL_MOBILE': 'محول',
    'reports.TERMINAL_NET': '.Net',
    'reports.TERMINAL_POCKETPC': 'كمبيوتر الجيب',
    'reports.TERMINAL_WEB': 'WebStation',
    'reports.terminaltype': 'نوع المحطة',
    'reports.TFDS Quote Receiver': 'متلقي عروض TFDS',
    'reports.tif': 'مدة صلاحية الأمر',
    'reports.TIF': 'مدة صلاحية الأمر',
    'reports.time': 'الوقت',
    'reports.Time': 'الوقت',
    'reports.to': 'إلى',
    'reports.toDate': 'إلى',
    'reports.toDateConnAttempt': 'إلى',
    'reports.toDatePwdChange': 'إلى(نفاذ)',
    'reports.tooLargeData': 'بيانات التقرير كثيرة! يعرض {1} من{2}',
    'reports.total': 'الإجمالي',
    'reports.total traded amount': 'إجمالية الكمية المتداولة',
    'reports.totalfee': 'مجموع الرسوم',
    'reports.TP': 'أخذ الربح',
    'reports.TP accepted by broker': 'تم قبول أمر أ.ر بواسطة الوسيط',
    'reports.TP accepted by STP': 'تم قبول أمر أ.ر من التنفيذ المباشر',
    'reports.TP order accepted': 'تم قبول أمر أ.ر',
    'reports.TP order cancel request': 'طلب إلغاء أمر أ.ر',
    'reports.TP order canceled': 'تم إلغاء أمر أ.ر',
    'reports.TP order created': 'تم إنشاء أمر أ.ر',
    'reports.TP Order Created': 'تم إنشاء أمر أ.ر',
    'reports.TP order created is off market': '',
    'reports.TP order created is unplaced': '',
    'reports.TP Order Modified': 'تم تعديل أمر أ.ر',
    'reports.TP order partially reject': 'تم إلغاء أمر أ.ر جزئياً',
    'reports.TP order placing request': 'طلب وضع أمر أ.ر',
    'reports.TP order received by broker': 'تم تلقي أمر أ.ر من الوسيط',
    'reports.TP order rejected': 'تم رفض أمر أ.ر',
    'reports.TP Order Removed': 'تم حذف أمر أ.ر',
    'reports.TP order replace request': 'طلب استبدال أمر أ.ر',
    'reports.TP order replaced': 'تم تعديل أمر أ.ر',
    'reports.Tr stop loss order cancel request': 'طلب إلغاء أمر التوقف المتحرك إ.خ',
    'reports.Tr stop loss order placing request': 'طلب وضع أمر التوقف المتحرك إ.خ',
    'reports.Tr stop loss order replace request': 'طلب استبدال أمر التوقف المتحرك إ.خ',
    'reports.tr. stop': '',
    'reports.Tr. stop accepted by broker': 'تم قبول أمر التوقف المتحرك بواسطة الوسيط',
    'reports.Tr. stop accepted by STP': 'تم قبول أمر التوقف المتحرك بواسطة التنفيذ',
    'reports.Tr. stop is too big': 'تعويض غير صحيح أمر التوقف المتحرك للأمر',
    'reports.Tr. stop loss order accepted': 'تم قبول أمر التوقف المتحرك',
    'reports.Tr. stop loss order canceled': 'تم إلغاء أمر التوقف المتحرك',
    'reports.Tr. stop loss order created': 'تم إنشاء أمر التوقف المتحرك ',
    'reports.Tr. stop loss order created is off market': '',
    'reports.Tr. stop loss order created is unplaced': '',
    'reports.Tr. stop loss order replaced': 'تم تعديل أمر التوقف المتحرك ',
    'reports.Tr. stop order accepted': 'تم قبول أمر التوقف المتحرك',
    'reports.Tr. stop order activated': '',
    'reports.Tr. stop order canceled': 'تم إلغاء أمر التوقف المتحرك',
    'reports.Tr. stop order created': 'تم إنشاء أمر التوقف المتحرك',
    'reports.Tr. stop order created is off market': '',
    'reports.Tr. stop order created is unplaced': '',
    'reports.Tr. stop order is awaiting market': 'أمر وقف متحرك بإنتظار السوق',
    'reports.Tr. stop order partially reject': 'تم إلغاء أمر التوقف المتحرك جزئياً',
    'reports.Tr. stop order received by broker': 'تم تلقي أمر التوقف المتحرك من الوسيط',
    'reports.Tr. stop order rejected': 'تم رفض أمر التوقف المتحرك',
    'reports.Tr. stop order replaced': 'تم تعديل أمر التوقف المتحرك ',
    'reports.Tr. stop order restated': '',
    'reports.Tr.SL Order Removed': 'تم حذف أمر إيقاف متحرك',
    'reports.Trade executed': 'تم تنفيذ التداول',
    'reports.Trade executed (Mutual)': 'تم تنفيذ التداول (يدوياً)',
    'reports.Trade executed (SL)': 'تم تنفيذ التداول (إ.خ)',
    'reports.Trade Operations Report': 'تقرير عمليات التداول',
    'reports.Trade Periods By Signal Report': 'فترات التداول من تقرير المؤشر',
    'reports.trade.BeginString': 'نص بدء التداول',
    'reports.trade.CheckCompID': 'رقم كمبيوتر التحقق للتداول',
    'reports.trade.CheckLatency': 'تأخر التحقق للتداول',
    'reports.trade.ClosedResendInterval': 'الفاصل بين إعادة إرسال التداول المغلق',
    'reports.trade.ConnectionType': 'نوع اتصال التداول',
    'reports.trade.ContinueInitializationOnError': 'استمرار تثبيت التداول عند حدوث خطأ',
    'reports.trade.DataDictionary': 'مسار بيانات التداول',
    'reports.trade.EndDay': 'يوم نهاية التداول',
    'reports.trade.EndTime': 'وقت نهاية التداول',
    'reports.trade.entityId': 'رقم مدخل التداول',
    'reports.trade.FileIncludeMilliseconds': 'يشمل ملف التداول ملي ثانية',
    'reports.trade.FileIncludeTimeStampForMessages': 'يشمل ملف التداول الطابع الزمني للرسائل',
    'reports.trade.FileLogHeartbeats': 'نبضات سجل ملف التداول',
    'reports.trade.fxintegrate.cert.host.verify': 'التحقق من مضيف شهادة دمج الفوركس',
    'reports.trade.fxintegrate.cert.root.ca.file': 'ملف هيئة اصدار شهادة دمج الفوركس',
    'reports.trade.fxintegrate.host': 'مضيف دمج الفوركس',
    'reports.trade.fxintegrate.logging.file': 'ملف تسجيل دمج الفوركس',
    'reports.trade.fxintegrate.logging.maxfilecount': 'الحد الأقصى لملفات تسجيل دمج الفوركس',
    'reports.trade.fxintegrate.logging.output': 'ناتج تسجيل دمج الفوركس',
    'reports.trade.fxintegrate.logging.priority': 'أولوية تسجيل دمج الفوركس',
    'reports.trade.fxintegrate.port': 'منفذ دمج الفوركس',
    'reports.trade.fxintegrate.protocol': 'بروتوكول دمج الفوركس',
    'reports.trade.fxintegrate.usessl': 'شهادلة SSL لدمج الفوركس',
    'reports.trade.HeartBtInt': 'Trade heartbeat Int',
    'reports.trade.JdbcDriver': 'سائق jdbc للتداول',
    'reports.trade.JdbcPassword': 'كلمة مرور jdbc للتداول',
    'reports.trade.JdbcURL': 'رابط jdbc للتداول',
    'reports.trade.JdbcUser': 'مستخدم jdbcللتداول',
    'reports.trade.keystoreAlias': 'Trade keystore Alias',
    'reports.trade.keystoreFile': 'ملف مخزن الشهادات للتداول',
    'reports.trade.LogFileMaxSize': 'الحجم الأقصى لملف سجل التداول',
    'reports.trade.login': 'تسجيل الدخول للتداول',
    'reports.trade.MaxLogsBackups': 'أقصى عدد لسجلات النسخ الاحتياطية للتداول',
    'reports.trade.MillisecondsinTimeStamp': 'ملي ثانية في الطابع الزمني',
    'reports.trade.MillisecondsInTimeStamp': 'ملي ثانية في الطابع الزمني',
    'reports.trade.password': 'كلمة المرور للتداول',
    'reports.trade.ReconnectInterval': 'الفاصل بين إعادة الاتصال للتداول',
    'reports.trade.RefreshonLogon': 'التحديث عند تسجيل الدخول',
    'reports.trade.RefreshOnLogon': 'التحديث عند تسجيل الدخول',
    'reports.trade.ResetOnDisconnect': 'إعادة ضبط التداول عند قطع الاتصال',
    'reports.trade.ResetOnLogon': 'إعادة ضبط التداول عند تسجيل الدخول',
    'reports.trade.ResetOnLogout': 'إعادة ضبط التداول عند تسجيل الخروج',
    'reports.trade.saveLogs': 'سجلات حفظ التداول',
    'reports.trade.ScreenLogEvents': 'سجل أحداث شاشة التداول',
    'reports.trade.ScreenLogShowHeartBeats': 'عرض نبضات سجل شاشة التداول',
    'reports.trade.ScreenLogShowIncoming': 'عرض دواخل سجل شاشة التداول',
    'reports.trade.ScreenLogShowOutgoing': 'عرض خوارج سجل شاشة التداول',
    'reports.trade.SenderCompID': 'رقم كمبيوتر الإرسال للتداول',
    'reports.trade.SendResetSeqNumFlag': 'إرسال إعادة ضبط الرقم المتسلسل للتداول',
    'reports.trade.SocketConnectHost': 'مضيف سوكيت الاتصال للتداول',
    'reports.trade.SocketConnectPort': 'منفذ سوكيت الاتصال للتداول',
    'reports.trade.SocketKeyStore': 'سوكيت مخزن الشهادات للتداول',
    'reports.trade.SocketKeyStorePassword': 'كلمة مرور سوكيت مخزن الشهادات للتداول',
    'reports.trade.SocketUseSSL': 'شهادة SLL لسوكيت التداول',
    'reports.trade.StartDay': 'يوم بدء التداول',
    'reports.trade.StartTime': 'وقت بدء التداول',
    'reports.trade.TargetCompID': 'رقم الكمبيوتر الهدف للتداول',
    'reports.trade.UseDataDictionary': 'مسار البيانات للتداول',
    'reports.trade.ValidateFieldsHaveValues': 'حقول التحقق من التداول لها قيم',
    'reports.trade.ValidateFieldsOutOfOrder': 'حقول التحقق من التداول خالية من الأوامر',
    'reports.trade.ValidateUserDefinedFields': 'الحقول المحددة لمستخدم التحقق من التداول',
    'reports.tradeamount': 'كمية التداول',
    'reports.tradeid': 'رقم التداول',
    'reports.tradePassword': 'كلمة المرور للتداول',
    'reports.trader': 'المتداول',
    'reports.Trades by Signal': 'الصفقات بالإشارة ',
    'reports.Trades Report': 'تقرير التداولات',
    'reports.Trades report by instrument': 'تقرير التداولات بالأدوات',
    'reports.trades_bought': 'أوأمر شراء',
    'reports.trades_sold': 'أوأمر بيع ',
    'reports.tradevolume': 'حجم التداول',
    'reports.trading': 'التداول',
    'reports.Trading': 'التداول',
    'reports.Trading disabled by risk rules': 'تم تعطيل التداول بواسطة قواعد المخاطر',
    'reports.TRADING_IDEA_CARD': 'بطاقة فكرة التداول',
    'reports.trading_in': 'تداول رابح',
    'reports.trading_out': 'تداول خاسر',
    'reports.TRADING_RULES_ACC': 'قواعد التداول(الحساب)',
    'reports.TRADING_RUSLES': 'قواعد التداول',
    'reports.TRADINGSYSTEMLIST': 'قائمة نظام التداول',
    'reports.Traiding out of Main session is forbidden for this account': 'ممنوع التداول من الجلسة الرئيسية لهذا الحساب.',
    'reports.Trailing stop order cancel request': 'طلب إلغاء أمر الإيقاف المتحرك',
    'reports.Trailing Stop Order Created': 'تم إنشاء أمر إيقاف متحرك',
    'reports.Trailing Stop Order Modified': 'تم تعديل أمر إيقاف متحرك',
    'reports.Trailing stop order placing request': 'طلب وضع أمر الإيقاف المتحرك',
    'reports.Trailing Stop Order Removed': 'تم حذف أمر إيقاف متحرك',
    'reports.Trailing stop order replace request': 'طلب استبدال أمر الإيقاف المتحرك',
    'reports.TrailingStop': 'توقف تلقائي',
    'reports.Transfer': 'تحويل',
    'reports.TransferFee': 'رسوم التحويل ',
    'reports.True': 'صحيح',
    'reports.type': 'النوع',
    'reports.Type': 'النوع',
    'reports.Types': 'الانواع',
    'reports.UBSFIXFeed': 'UBSFIXFeed',
    'reports.Unable to cancel the order since route is not active.': 'لا تستطيع إلغاء الأمر لأن المسار غير  نشط',
    'reports.Unblocking': 'فتح',
    'reports.Unplaced': '',
    'reports.unrealized p/l': 'ر/خ الغير حقيقية',
    'reports.unrealizedpnl': 'ر/خ الغير حقيقية',
    'reports.unsettled_cash': 'نقد تحت التسوية ',
    'reports.updatedtime': 'الوقت المحدث',
    'reports.Updater': 'المحدث',
    'reports.URL': 'URL',
    'reports.usage': 'الاستخدام',
    'reports.USE_OPEN_PRICE_IN_MARGIN': 'استخدام سعر الافتتاح',
    'reports.UseDataDictionary': 'استخدام مساء البيانات',
    'reports.usePrevQuotedOrders': 'إستعمال تسعيرات الأوامر المسبقة ',
    'reports.UsePriority': 'استخدام الاولوية',
    'reports.user': 'المستخدم',
    'reports.User': 'المستخدم',
    'reports.User Authorization Error': 'خطأ في التحقق من المستخدم',
    'reports.user group': 'مجموعة المستخدم',
    'reports.User Previledges Report': 'تقرير مميزات المستخدم',
    'reports.user.acc_4_signals': 'حساب الإشارات',
    'reports.user.address': 'العنوان',
    'reports.user.amount': 'الكمية',
    'reports.user.amountType': 'نوع الكمية',
    'reports.user.birthday': 'تاريخ الميلاد',
    'reports.user.certificate': 'شهادة المستخدمين',
    'reports.user.chatOnEmail': 'إرسال محادثة على البريد الإلكتروني',
    'reports.user.city': 'المدينة',
    'reports.user.comment': 'تعليق',
    'reports.user.commission_type': 'العمولة بواسطة',
    'reports.user.email': 'البريد الإلكتروني',
    'reports.user.extaccount': 'حساب خارجي',
    'reports.user.firstname': 'الاسم الأول',
    'reports.user.gender': 'الجنس',
    'reports.user.group': 'المجموعة',
    'reports.user.how_handle': 'كيفية معاملة الإشارات',
    'reports.user.introducebroker': 'حساب وسيط معرف',
    'reports.user.language': 'اللغة',
    'reports.user.lastname': 'الاسم الأخير',
    'reports.user.linkedUsers': 'المستخدمون المرتبطون',
    'reports.user.login': 'اسم المستخدم',
    'reports.user.middlename': 'الاسم الاوسط',
    'reports.user.password': 'كلمة المرور الجديدة',
    'reports.user.phonenumber': 'رقم الهاتف',
    'reports.user.phonepassword': 'كملة مرور الهاتف',
    'reports.user.photo': 'الصورة',
    'reports.user.pin': 'الرمز السري',
    'reports.user.showInSearch': 'الإظهار في البحث',
    'reports.user.showMyContacts': 'إظهار جهات اتصالي',
    'reports.user.signalsOnEmail': 'الإشارات على البريد الإلكتروني',
    'reports.user.state': 'الولاية',
    'reports.user.status': 'الحالة',
    'reports.user.subscribeplan': 'خطة الاشتراك',
    'reports.user.website': 'الموقع الإلكتروني',
    'reports.user.zipcode': 'الرمز البريدي',
    'reports.usergroup.group': 'مجموعة ناجحة',
    'reports.usergroup.name': 'مجموعة المستخدم',
    'reports.USERGROUPSVIS_RULES': 'مرئية مجموعات المستخدم',
    'reports.userlogin': 'تسجيل دخول المستخدم',
    'reports.UserLogin': 'تسجيل دخول المستخدم',
    'reports.username': 'المستخدم',
    'reports.Users Report': 'تقرير المستخدمون',
    'reports.Users Report(copy)': 'تقرير المستخدمين(نسخة)',
    'reports.users_num': 'رقم المستخدمين',
    'reports.useSSL': 'استخدام SSL',
    'reports.ValidateFieldsHaveValues': 'حقول التحقق لها قيمة',
    'reports.ValidateFieldsOutOfOrder': 'حقول التحقق خالية من الأوامر',
    'reports.ValidateUserDefinedFields': 'الحقول المحددة لمستخدم التحقق',
    'reports.value': 'القيمة',
    'reports.VALUE_ADDRESS': 'العنوان',
    'reports.VALUE_BASE_CURRENCY': 'جزء الرمز الأساسي',
    'reports.VALUE_BRUTEFORCE_LOCK_MINUTS': 'Value bruteforce lock minutes',
    'reports.VALUE_CITY': 'المدينة',
    'reports.VALUE_COMMISSION_ACCOUNT': 'حساب العمولة المضادة',
    'reports.VALUE_COMMISSION_FOR_TRANSFER': 'عمولة التحويل',
    'reports.VALUE_COMMISSION_ID': 'خطة الارباح',
    'reports.VALUE_COUNTER_CURRENCY': 'جزء الرمز المضاد',
    'reports.VALUE_COUNTRY': 'الدولة',
    'reports.VALUE_DAY_BEGIN': 'يوم البدء',
    'reports.VALUE_DAY_END': 'يوم الانتهاء',
    'reports.VALUE_DAYS_TILL_EXP': 'الأيام حتى الانتهاء',
    'reports.VALUE_DEFAULT_BROKER': 'الوسيط الافتراضي',
    'reports.VALUE_DEFAULT_LANG': 'اللغة الافتراضية',
    'reports.VALUE_DERIVATIVE_TYPE': 'نوع المشتقة',
    'reports.VALUE_HEDGE_COEFFICIENT': 'معامل الحماية',
    'reports.VALUE_L2QUOTEGROUP_DEPTH': 'عمق مجموعة المستوى 2',
    'reports.VALUE_L2QUOTEGROUP_INTERVAL': 'فاصل مجموعة المستوى 2',
    'reports.VALUE_LEVERAGE': 'قوة الرفع',
    'reports.VALUE_LOT_SIZE': 'حجم الحصة',
    'reports.VALUE_LOTSTEP': 'الخطوة بين الحصص',
    'reports.VALUE_MARGIN_COEFFICIENT': 'معامل الهامش (بحسب الوقت)',
    'reports.VALUE_MARGINCALL': 'استدعاء الهامش، %',
    'reports.VALUE_MARGINTRADING': 'مستوى إيقاف التداول، %',
    'reports.VALUE_MARKET_RANGE': 'مدى السوق، حركات الأسعار',
    'reports.VALUE_MAX_DELTA_QUOTES': 'أقصى فرق سعر مسموح به بين عرضين متتاليين (حركات الأسعر)',
    'reports.VALUE_MAX_DELTA_TRADES': 'أقصى فرق سعر مسموح به بين تداولين متتاليين',
    'reports.VALUE_MAXIMUM_CACHE_SIZE': 'الحجم الأقصى للذاكرة المؤقتة',
    'reports.VALUE_MAXIMUM_FILTER': 'القيمة القصوى للشوكة',
    'reports.VALUE_MAXIMUMLOT': 'الحد الأقصى للحصة',
    'reports.VALUE_MIN_OPTION_INTERVAL': 'أقصى فرق سعر مسموح به بين عرضي خيارات متتاليين',
    'reports.VALUE_MIN_PRICE_DIFF_FOR_MARGIN': 'أدنى فرق سعر للهامش',
    'reports.VALUE_MINIMALLOT': 'العقد الأدنى',
    'reports.VALUE_MINIMUM_FILTER': 'القيمة الأدنى للشوكة',
    'reports.value_nav': 'صافي قيمة الأصول',
    'reports.VALUE_NO_QUOTES_MAXTIME': 'لا يوجد وقت أقصى للعروض',
    'reports.VALUE_NUMBER_OF_SHARES': 'عدد الحصص',
    'reports.VALUE_ONE_COUNTER_ACCOUNT': 'الحساب المضاد',
    'reports.VALUE_PIP_SIZE': 'حجم حركة السعر(أقل تغير)',
    'reports.VALUE_PnL_ACCOUNT': 'الحساب المضاد ر/خ',
    'reports.VALUE_POST_SESSION_END': 'نهاية بعد الدورة ',
    'reports.VALUE_PRE_SESSION_BEGIN': 'بداية قبل الدورة',
    'reports.VALUE_PRICE_GENERATION_MODEL': 'نموذج إنشاء السعر',
    'reports.VALUE_PWD_VERIFICATION_LEN': 'طولة كلمة مرور التحقق',
    'reports.VALUE_QUOTE_CACHE_15MIN_SIZE': 'حجم 15دقيقة للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_1MIN_SIZE': 'حجم 1دقيقة للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_30MIN_SIZE': 'حجم 30دقيقة للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_4HOUR_SIZE': 'حجم 4ساعات للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_5MIN_SIZE': 'حجم 5دقائق للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_DAY_SIZE': 'حجم يوم للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_HOUR_SIZE': 'حجم ساعة للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_MONTH_SIZE': 'حجم شهر للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_SIZE': 'حجم الذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_TIC_SIZE': 'حجم حركة سعر للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_WEEK_SIZE': 'حجم أسبوع للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_CACHE_YEAR_SIZE': 'حجم سنة للذاكرة المؤقتة للعرض',
    'reports.VALUE_QUOTE_PATH': 'مسار الذاكرة المؤقتة للعروض',
    'reports.VALUE_QUOTES_FILE_SIZE': 'حجم ملف العروض',
    'reports.VALUE_SLIPPAGE': 'فرق السعر',
    'reports.VALUE_SMS_PATTERN': 'نمط الرسائل القصيرة',
    'reports.VALUE_SPOT_INSTRUMENT': 'رمز المنطقة',
    'reports.VALUE_SPREAD_ACCOUNT': 'فرق سعار الحساب المضاد',
    'reports.VALUE_STATE': 'الولاية',
    'reports.VALUE_STOPOUT': 'مستوى الخروج، %',
    'reports.VALUE_SWAP_ACCOUNT': 'مبادلة الحساب المضاد',
    'reports.VALUE_TEMPLATE_NUMERIC': 'قوالب البريد الإلكتروني',
    'reports.VALUE_TICK_COAST': 'شاطئ حركة السعر',
    'reports.VALUE_TIMEZONE_OFFSET': 'تعويض المنطقة الزمنية',
    'reports.VALUE_UNREPEAT_PASSWORD': 'عدد كلمات المرور الغير مكررة',
    'reports.VALUE_WL_ACCOUNT': 'الإيداع/السحب الحساب المضاد',
    'reports.VALUE_WRONG_PASSWORDS_BEFORE_LOCK': 'العدد المسموح لكلمات المرور الخاطئة',
    'reports.VALUE_ZIP': 'الرمز البريدي',
    'reports.ValueAddedTax': 'قيمة الضريبة المضافة',
    'reports.valuedate': 'تاريخ التحقق',
    'reports.Visibility': 'الرؤية',
    'reports.VISIBILITY_BRANDING': 'مرئية الاسم التجاري',
    'reports.VISIBILITY_COMMISSIONSPLAN': 'مرئية خطط الربح',
    'reports.VISIBILITY_EXTFIELDS': 'مرئية لحقول الممتدة',
    'reports.VISIBILITY_INSTRUMENT_TYPE': 'وضوح نوع الرمز',
    'reports.VISIBILITY_ROUTE': 'مرئية المسارات',
    'reports.VISIBILITY_SUBSCRIBE_PLAN': 'خطط الاشتراك',
    'reports.VISIBILITY_USERGROUP': 'مرئية مجموعات المستخدم',
    'reports.Wait confirm for cancel': 'انتظار التأكيد للإلغاء',
    'reports.Wait confirm for execution': 'انتظار التأكيد للتنفيذ',
    'reports.Wait confirm for modify': 'انتظار التأكيد للتعديل',
    'reports.Waiting market': 'انتظار السوق',
    'reports.WEB_CHART_OE': 'شبكة الرسم البياني (بناء في إدخال الأوامر)',
    'reports.WEB_CHART_VISUAL': 'شبكة الرسم البياني (تداول مرئي)',
    'reports.WEB_MARKETDEPTH': 'شبكة عمق السوق',
    'reports.WEB_OE': 'شبكة إدخال الأوامر',
    'reports.WEB_OPTION_MASTER': 'شبكة سلسلة الخيارات',
    'reports.WEB_ORDERS_PANEL_HOT_BTN': 'أوامر القائمة  (ازرار الاختصار)',
    'reports.WEB_OTHER': 'شبكة أخرى',
    'reports.WEB_POSITIONS_PANEL_HOT_BTN': 'شبكة لوحة المراكز (ازرار الاختصار)',
    'reports.weeks': 'أسابيع',
    'reports.Widgets Instrument Statistics Report': 'تقرير إحصائيات الأدوات المصغرة',
    'reports.widgets_isr_buytrades': 'بحسب التداولات',
    'reports.widgets_isr_commission': 'الرسوم',
    'reports.widgets_isr_instrumentname': 'الرمز',
    'reports.widgets_isr_netpl': 'صافي ر/خ',
    'reports.widgets_isr_plticks': 'حركات ر/خ',
    'reports.widgets_isr_selltrades': 'تداولات البيع',
    'reports.widgets_isr_swap': 'المبادلة',
    'reports.widgets_isr_totaltrades': 'إجمالي التداولات',
    'reports.widgets_isr_trades': 'التداولات',
    'reports.widgets_isr_tradevolume': 'الحجم',
    'reports.widgets_isr_winningtrades': 'التداولات الرابحة',
    'reports.widthdraw': 'سحب',
    'reports.Withdraw': 'سحب',
    'reports.Withdrawal': 'سحب',
    'reports.withdrawalfee': 'رسوم السحب',
    'reports.Wrong account operation summ.': 'مجموع عمليات الحساب خاطئ',
    'reports.Wrong recovery key': 'مفتاح الاستعادة خاطئ',
    'reports.years': 'سنوات',
    'reports.yes': 'نعم',
    "reports.You can't trade with this account.": 'لا يمكنك التداول في هذا الحساب',
    Reserve: 'مخزون',
    'ribbon.enviroment': 'البيئة',
    'ribbon.enviroment.help': '',
    'ribbon.enviroment.logout': '',
    'ribbon.enviroment.New': 'مكان عمل جديد',
    'ribbon.enviroment.NotificationWindow.lockTrading': 'التداول مغلق',
    'ribbon.enviroment.NotificationWindow.UnlockTrading': 'التداول مفتوح',
    'ribbon.enviroment.Save': 'حفظ مكان العمل',
    'ribbon.enviroment.settings': 'الإعدادات',
    'ribbon.help.about': 'بشأن',
    'ribbon.help.help': 'مساعدة',
    'ribbon.help.home': 'الصفحة الرئيسية',
    'ribbon.linking.linkBlue': 'أزرق',
    'ribbon.linking.linkEmpty': 'لا يوجد',
    'ribbon.linking.linkGreen': 'أخضر',
    'ribbon.linking.linkOrange': 'برتقالي',
    'ribbon.linking.linkPurple': 'بنفسجي',
    'ribbon.linking.linkRed': 'أحمر',
    'ribbon.linking.linkYellow': 'أصفر',
    'ribbon.tools': 'الأدوات',
    'ribbon.tools.full_logs': 'رسائل تسجيل الدخول للسيرفر كاملة',
    'ribbon.tools.grid': 'شبكة',
    'ribbon.tools.reports': 'التقارير',
    'ribbon.tradingTerminal': 'المحطة',
    'RiskManagement.Messages.DailyLimit': 'وصلت حد الخسارة اليومية',
    'RiskManagement.Messages.MaxDailyProfit': 'وصلت الربح اليومي ',
    'RiskManagement.Messages.MaxTrailingLimit': 'وصلت إلى  max trailing drawdown',
    'RiskManagement.Messages.MaxUnrealizedLossLimit': 'تم الوصول إلى حد الخسارة غير المحققة ',
    'RiskManagement.Messages.WeeklyLimit': 'وصلت  حد الخسارة الإسبوعية',
    'screen.about.copyright': '© 2017-2025 TraderEvolution Global LTD.',
    'screen.about.dateBuild': 'تاريخ البناء:',
    'screen.about.title': 'بشأن',
    'screen.about.titleWeb': '',
    'screen.about.versionWeb': '',
    'screen.additionalVerification.comment': 'اتصال 1 استوجب تحقق إضافي:',
    'screen.additionalVerification.Need activation': 'يحتاج التفعيل',
    'screen.additionalVerification.Need activation.comment': 'الرجاء إدخال رمز التفعيل (تم إرساله إلى بريدك الإلكتروني)',
    'screen.additionalVerification.Need activation.watermark': '<أدخل رمز التفعيل من البريد الإلكتروني>',
    'screen.additionalVerification.title': 'التحقق الإضافي',
    'screen.additionalVerification.title.Email': 'تأكيد إظافي (otp البريد الإلكتروني)',
    'screen.additionalVerification.title.Fixed': 'تأكيد إظافي  (كلمة مرور مثبتة)',
    'screen.additionalVerification.title.Sms': 'تأكيد إظافي (otp رسالة sms)',
    'screen.additionalVerification.watermark': '<أدخل كلمة المرور الإضافية>',
    'screen.changepass.cancel': 'إلغاء',
    'screen.changepass.changed': 'تم تغيير كلمة المرور بنجاح',
    'screen.changepass.confirm': 'تأكيد كلمة المرور الجديدة',
    'screen.changepass.new': 'كلمة المرور الجديدة',
    'screen.changepass.ok': 'موافق',
    'screen.changepass.old': 'كلمة المرور الحالية',
    'screen.changepass.title': 'تغيير كلمة المرور',
    'screen.changepass.watermark_confirm': '<تأكيد كلمة المرور>',
    'screen.changepass.watermark_new': '<كلمة مرور الجديدة>',
    'screen.changepass.watermark_old': '<كلمة المرور القديمة>',
    'screen.closePosition.cancel': 'إلغاء',
    'screen.closePosition.ok': 'إغلاق',
    'screen.closePosition.title': 'إغلاق المركز',
    'screen.error.title': 'خطأ',
    'screen.licensing.emptyFieldErrors.password': 'حقل كلمة الرور فارغة',
    'screen.licensing.statusMessages.connecting': 'جاري الإتصال...',
    'screen.login.change_pass': 'لقد تم إعادة ضبط كلمة المرو أو تم انتهاء صلاحيتها ويجب تغييرها',
    'screen.lookup.scripts.noitems': 'لا توجد عناصر مطابقة ',
    'screen.lookup.scripts.showing': 'عرض  {1} من  العناصر',
    'screen.lookup.scripts.watermark': '<بحث>',
    'screen.modifyOrder.amount': 'الكمية:',
    'screen.modifyOrder.bind_to': 'Bound to (OCO)',
    'screen.modifyOrder.Change order': 'تعديل الأمر',
    'screen.modifyOrder.created': 'التاريخ/الوقت:',
    'screen.modifyOrder.instrument': 'الرمز:',
    'screen.modifyOrder.market_price': 'السعر الحالي:',
    'screen.modifyOrder.modify': 'تعديل',
    'screen.modifyOrder.modifyPosition': 'تعديل المركز',
    'screen.modifyOrder.number': 'رقم المركز:',
    'screen.modifyOrder.operation': 'الجانب:',
    'screen.modifyOrder.orderNumber': 'رقم الأمر:',
    'screen.modifyOrder.ordertype': 'نوع الأمر:',
    'screen.modifyOrder.price': 'سعر الافتتاح:',
    'screen.modifyOrder.route': 'المسار:',
    'screen.properties.cancel': 'إغلاق',
    'screen.properties.cancel.ToolTip': '',
    'screen.properties.common': 'الخصائص',
    'screen.properties.ok': 'موافق',
    'screen.properties.ok.ToolTip': '',
    'screen.properties.title': 'إعدادات',
    'screen.properties.unsavedChanges': 'لديك تغييرات غير محفوظة. الخروج وخسارة التغييرات؟',
    'screen.LoginScreen.Connecting': '...الاتصال',
    'screen.LoginScreen.Error.loginIdIsEmpty': 'حقل رقم تسجيل الدخول خالي',
    'screen.LoginScreen.Error.passwordIdIsEmpty': 'حقل كلمة المرور خالي',
    'screen.LoginScreen.ForgotLinkLabel': 'استعادة',
    'screen.LoginScreen.ForgotLinkLabel.ToolTip': 'استعادة كلمة المرور',
    'screen.LoginScreen.LoadingUserData': '',
    'screen.LoginScreen.LoginButtonText': 'تسجل الدخول',
    'screen.LoginScreen.LoginButtonTooltip': 'تسجيل الدخول إلى المنصة',
    'screen.LoginScreen.LoginIDLabel': 'اسم المستخدم',
    'screen.LoginScreen.LoginIDTextBox.ToolTip': 'تسجيل دخول المستخدم',
    'screen.LoginScreen.PasswordLabel': 'كلمة المرور',
    'screen.LoginScreen.PasswordTextBox.ToolTip': 'كلمة مرور المستخدم',
    'screen.LoginScreen.RegistrationLinkLabel': 'إنشاء حساب تجريبي',
    'screen.LoginScreen.RegistrationLinkLabel.ToolTip': 'تسجيل لحساب تجريبي',
    'screen.LoginScreen.SavePassCheckBox': 'حفظ كلمة المرور',
    'screen.LoginScreen.SavePassCheckBox.ToolTip': 'حفظ بيانات الدخول',
    'screen.LoginScreen.Settings.LanguageLabel': 'اللغة',
    'screen.recconection.attemptLabelLabel': 'المحاولات:',
    'screen.recconection.reconnecting': '...إعادة الاتصال',
    'screen.recconection.seconds': '...ثواني',
    'screen.recconection.statusLabelLabel': 'الحالة:',
    'screen.recconection.stop': 'إيقاف',
    'screen.recconection.title': 'مدير إعادة الاتصال',
    'screen.recconection.tryNow': 'حاول الآن',
    'screen.recconection.waiting': 'انتظار',
    'screen.RecoveryPassForm.Cancel': 'إلغاء',
    'screen.RecoveryPassForm.Confirm': 'تأكيد',
    'screen.RecoveryPassForm.Email': 'البريد الإلكتروني:',
    'screen.RecoveryPassForm.HaveCode': 'لدي الرمز',
    'screen.RecoveryPassForm.Key': 'مفتاح',
    'screen.RecoveryPassForm.Login': 'تسجيل الدخول',
    'screen.RecoveryPassForm.New password': 'كلمة المرور الجديدة',
    'screen.RecoveryPassForm.New password contains prohibited symbols': 'كلمة المرور الجديدة تحتوي على رموز ممنوعة',
    'screen.RecoveryPassForm.Send': 'استعادة',
    'screen.RecoveryPassForm.Title': 'استعادة كلمة المرور',
    'screen.registrationWizard.confirmPassword': '* تأكيد كلمة المرور',
    'screen.registrationWizard.confirmPassword.watermark': '<تأكيد كلمة المرور>',
    'screen.registrationWizard.email': '*البريد الإلكتروني:',
    'screen.registrationWizard.email.watermark': '<البريد الإلكتروني>',
    'screen.registrationWizard.Country': '',
    'screen.registrationWizard.Password': '',
    'screen.registrationWizard.Balance': '',
    'screen.registrationWizard.firstName': '*الاسم الأول:',
    'screen.registrationWizard.firstName.watermark': '<الحد الأقصا حرفان>',
    'screen.registrationWizard.lastName': '*الاسم الأخير:',
    'screen.registrationWizard.lastName.watermark': '<الحد الأقصا حرفان>',
    'screen.registrationWizard.login': 'تسجيل الدخول',
    'screen.registrationWizard.login.watermark': '<min 2 chars>',
    'screen.registrationWizard.password.watermark': '<إدخل كلمة المرور>',
    'screen.registrationWizard.phone': 'رقم الهاتف:',
    'screen.registrationWizard.phoneNumber.watermark': '<(رمز الدولة) رقم الهاتف>',
    'screen.registrationWizard.registrationStatus': 'حالة التسجيل',
    'screen.registrationWizard.successMessage': 'التسجيل تم بنجاح! \nLogin: {1}\nPassword: {2}',
    'screen.registrationWizard.Header': '',
    'screen.registrationWizard.Register': '',
    'screen.registrationWizard.Cancel': '',
    'screen.renameScreen.cancel': 'إلغاء',
    'screen.renameScreen.illegalName': 'الاسم غير قانوني!',
    'screen.renameScreen.ok': 'موافق',
    'screen.ReportMessageTooltip.CreatedAt': 'تم الإنشاء في',
    'screen.ReportMessageTooltip.FilledAt': 'تم الملئ عند',
    'screen.ReportMessageTooltip.MarginCall': 'نداء الهامش',
    'screen.ReportMessageTooltip.Market': 'السوق',
    'screen.ReportMessageTooltip.ModifiedAt': 'تم التعديل في',
    'screen.ReportMessageTooltip.RemovedAt': 'تم الحذف في',
    'screen.reports.actions_print': 'طباعة',
    'screen.reports.address': 'العنوان',
    'screen.reports.beginDatePicker.ButtonDropDown.ToolTip': 'اضغط لفتح النتيجة',
    'screen.reports.beginDatePicker.ToolTip': 'اختر التاريخ/الوقت',
    'screen.reports.brokerzip': 'الرمز البريدي',
    'screen.reports.button_printer_setup': 'إعداد',
    'screen.reports.byGroup': 'بحسب المجموعة',
    'screen.reports.byInstrumentGroup': 'بحسب المجموعة',
    'screen.reports.byInstruments': 'بحسب الادوات',
    'screen.reports.byUser': 'بحسب الادوات',
    'screen.reports.byUserGroup': 'بحسب المجموعة',
    'screen.reports.cell_data': '',
    'screen.reports.column.inner_text': '',
    'screen.reports.columns_maneger': 'إدارة الحانات ',
    'screen.reports.country': 'الدولة',
    'screen.reports.csv': 'تصدير البيانات إلى مستند CSV',
    'screen.reports.customerzip': '',
    'screen.reports.dataCoverage': 'تغطية البيانات',
    'screen.reports.errSaving': 'حدث خطأ خلال حفظ الملف.',
    'screen.reports.excel': 'Excel',
    'screen.reports.exportToButton': '',
    'screen.reports.filter': '...مدير الفيلتر',
    'screen.reports.historyFor': 'سجّل',
    'screen.reports.html': 'HTML',
    'screen.reports.info': 'معلومات',
    'screen.reports.error': '',
    'screen.reports.Reports': '',
    'screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip': 'اختر مجموعة الرمز',
    'screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip': 'اختار رمز',
    'screen.reports.license': 'رقم الرخصة',
    'screen.reports.listViewEx.itemAll': 'جميع',
    'screen.reports.manager': 'المدير',
    'screen.reports.minimizeButton.Hide reports parameters': 'إخفاء معلمات التقارير',
    'screen.reports.minimizeButton.Show reports parameters': 'إظهار معلمات التقارير',
    'screen.reports.name': 'الاسم',
    'screen.reports.news.daily': 'يومي',
    'screen.reports.news.from': 'من',
    'screen.reports.news.hideACriterias': 'إخفاء الخصائص المتقدمة',
    'screen.reports.news.invalidDateTime': 'الرجاء اختيار تاريخ/وقت "من" و "إلى" صحيح',
    'screen.reports.news.monthToDate': 'شهر حتى التاريخ',
    'screen.reports.news.range': 'المدى',
    'screen.reports.news.showACriterias': 'عرض الخصائص المتقدمة',
    'screen.reports.news.sinceInception': 'منذ البداية',
    'screen.reports.news.till': 'حتى',
    'screen.reports.news.yearToDate': 'بداية السنة حتى التاريخ',
    'screen.reports.noData': 'لا توجد بيانات متاحة',
    'screen.reports.order': 'أمر',
    'screen.reports.param.Name': 'الاسم',
    'screen.reports.paramOfReport': 'معلمات التقرير',
    'screen.reports.pdf': 'التصدير إلى ملف PDF...',
    'screen.reports.phone': 'رقم الهاتف',
    'screen.reports.pos': 'المركز',
    'screen.reports.president': 'اسم الرئيس',
    'screen.reports.print': 'الطباعة...',
    'screen.reports.processingRequset': 'إلغاء',
    'screen.reports.rangeError': 'لا يمكن أن يكون تاريخ "من" أحدث من التاريخ "إلى"',
    'screen.reports.report.button_printer_setup': 'إعداد',
    'screen.reports.reportHeader.accnumber': 'رقم الحساب:',
    'screen.reports.reportHeader.from': 'من',
    'screen.reports.reportHeader.Text': 'بيانات العميل والوسيط',
    'screen.reports.reportHeaders.account': 'الحساب:',
    'screen.reports.reportHeaders.currancy': 'العملة:',
    'screen.reports.reportHeaders.date': 'التاريخ:',
    'screen.reports.reportHeaders.from': 'من:',
    'screen.reports.reportHeaders.owner': 'المالك:',
    'screen.reports.reportHeaders.to': 'إلى:',
    'screen.reports.reportHeaderText': 'معلومات العميل والوسيط',
    'screen.reports.reportParameters': 'تقرير المعامل ',
    'screen.reports.reports.Canceled': 'تم إلغاء العملية',
    'screen.reports.reports.ExpingToCSV': 'تصدير البيانات إلى مستند CSV…',
    'screen.reports.reports.ExpingToExcel': 'تصدير البيانات إلى مستند Excel...',
    'screen.reports.reports.ExpingToHTML': 'تصدير البيانات إلى مستند HTML...',
    'screen.reports.reports.ExpingToPDF': 'تصدير البيانات إلى مستند PDF...',
    'screen.reports.reports.ExportingPrint': '...تصدير البيانات للطباعة',
    'screen.reports.reports.ExpPrint': 'تم تصدير البيانات للطباعة بنجاح!',
    'screen.reports.reports.ExpPrintErr': 'حدث خطأ خلال التصدير للطباعة!',
    'screen.reports.reports.ExpToCSV': 'تم التصدير إلى ملف CSV بنجاح',
    'screen.reports.reports.ExpToCSVErr': 'حدث خطأ أثناء التصدير إلى ملف CSV!',
    'screen.reports.reports.ExpToExcel': 'تم تصدير البيانات إلى Excel بنجاح!',
    'screen.reports.reports.ExpToExcelErr': 'حدث خطأ خلال تصدير البيانات إلى Excel!',
    'screen.reports.reports.ExpToHTML': 'تم تصدير البيانات إلى HTML بنجاح!',
    'screen.reports.reports.ExpToHTMLErr': 'حدث خطأ خلال تصدير البيانات إلى HTML!',
    'screen.reports.reports.ExpToPDF': 'تم تصدير البيانات إلى PDF بنجاح!',
    'screen.reports.reports.ExpToPDFErr': 'حدث خطأ خلال تصدير البيانات إلى PDF!',
    'screen.reports.reports.getReport': 'الحصول على تقرير',
    'screen.reports.reports.in_separate_window': 'تم فتح القرير في نافذة منفصلة',
    'screen.reports.reports.inCurrentWindow': 'في النافذة الحالبة',
    'screen.reports.reports.info': 'استخدم مدير التقارير لعرض وطباعة تقارير عن نشاطات حسابك',
    'screen.reports.reports.inSeparateWindow': 'في نافذة منفصلة',
    'screen.reports.reports.loading': '...تحميل البيانات للتقرير',
    'screen.reports.reports.noData': 'نأسف، لا توجد بيانات متاحة لهذا الطلب. حاول استخدام معلمات مختلفة للتقرير',
    'screen.reports.reports.noItemAvailable': 'لا يوجد عنصر متاح',
    'screen.reports.reportType': 'نوع التقرير',
    'screen.reports.reportTypeCombobox.ToolTip': 'اختر نوع التقرير',
    'screen.reports.save': 'حفظ',
    'screen.reports.showSummary': 'إظهار الملخص',
    'screen.reports.sinceInception': 'منذ البداية',
    'screen.reports.summary': 'الملخّص',
    'screen.reports.table': 'الجدول',
    'screen.reports.title': 'مدير التقارير',
    'screen.reports.to': 'إلى',
    'screen.reports.trades': 'التداولات',
    'screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip': 'اختر مجموعة الحساب',
    'screen.reports.UserAndGroupLookup.usersLookup.ToolTip': 'تحديد الكمية',
    'screen.TimeZoneSelector.title': 'اختر المنطقة الزمنية',
    'screen.UnlockTradingScreen.lockedLabel': '',
    'screen.UnlockTradingScreen.unlockButton': '',
    'Simple Moving Average': 'متوسط الحركة البسيط',
    SixMonth: '6 أشهر',
    'Smoothed Moving Average': 'ممهدة المتوسط المتحرك',
    'Standard Deviation': 'الانحراف المعياري',
    'statusBar.passwordpanel.DetalTicket.Text': 'السماح بالوصول إلى نشاط التداول ',
    'statusBar.passwordPanel.passwordBox.invalidPass': 'كلمة مرور التداول غير صالحة ',
    Stochastic: 'مؤشر ستوكاستك',
    'Stochastic Slow': 'مؤشر ستوكاستك البطيء',
    Stop: 'إيقاف',
    'Stop Limit': 'الإيقاف المحدد',
    'Stop-loss': 'إيقاف الخسارة',
    Suomi: '#hidden#',
    'Swing Index': 'مؤشر سوينغ',
    'Take-profit': 'أخذ الربح',
    'TerceraAccountLookup.AccountNotExist': 'الحساب غير موجود',
    'TerceraAccountLookup.IncorrectAccount': 'الحساب غير صحيح',
    'TerceraAccountLookup.Linked accounts': 'الحسابات المرتبطة',
    'TerceraAccountLookup.Multy select': 'اختيار متعدد',
    'TerceraAccountLookup.Select': 'اختيار',
    'TerceraAccountLookup.Select All': 'تحديد الكل',
    'textBoxWithValidating.ErrorText.NameContainsInvalidChar': '',
    'textBoxWithValidating.ErrorText.NameExist': '',
    ThreeMonth: '3 أشهر',
    'Tr. stop': 'توقف تلقائي',
    'TradeDefaultManager.Set defaults': 'ضبط الافتراضيات',
    'TradeDefaultManager.Types manager': ':مدير الأنواع',
    'TradeTool.Amount of order': 'كمية الأمر',
    'TradeTool.Cancel order': 'إلغاء الأمر',
    'TradeTool.Cancel SL': 'إلغاء إ.خ',
    'TradeTool.Cancel TP': 'إلغاء أ.ر',
    'TradeTool.Close position': 'إغلاق المركز',
    'TradeTool.Drag to set SL order': 'اسحب لضبط أمر إ.خ',
    'TradeTool.Drag to set TP order': 'اسحب لضبط أمر أ.ر',
    'TradeTool.Order': 'أمر',
    'TradeTool.Position': 'المركز',
    'TradeTool.Position P/L': 'إجمالي ر/خ',
    'TradeTool.Tick and P/L': 'حركات الأسعار و ر/خ',
    'TradeTool.Trailing Stop': 'توقف تلقائي',
    'TradeTool.View more actions': 'عرض المزيد من الإجراءات',
    TTO: 'TTO',
    'TypesManagerScreen.DefaultLotsSetup': 'إعداد الحصص الافتراضية',
    'TypesManagerScreen.NumericLabel.Price increment (arrow), ticks': 'Price increment (arrow), ticks',
    'TypesManagerScreen.NumericLabel.SL default offset, ticks': 'SL default offset, ticks',
    'TypesManagerScreen.NumericLabel.TP default offset, ticks': 'TP default offset, ticks',
    'TypesManagerScreen.NumericLabel.User quantity coefficient': 'معامل الكمية للمستخدم',
    'TypesManagerScreen.QuickTable.Column.Default lots': 'الحصص الافتراضية',
    'TypesManagerScreen.QuickTable.Column.Enable': 'تشغيل',
    'TypesManagerScreen.Types Manager': 'مدير الأنواع',
    'UserControl.TerceraNumeric.ValueGreaterMax': 'القيمة اكثر من الحد الأعلى',
    'UserControl.TerceraNumeric.ValueIsEmpty': 'القيمة فارغة',
    'UserControl.TerceraNumeric.ValueLessMin': 'القيمة أقل من الحد الأدنى',
    'UserControl.TerceraNumeric.ValueNotMultiple': 'القيمة ليست مضاعفة للتغير الادنى',
    'UserControl.TerceraNumeric.ValueNotNumber': 'القيمة ليست رقم',
    Volume: 'الحجم',
    'Volume Weighted Average Price': 'حجم مرجح متوسط سعر',
    VWAP: 'VWAP',
    allowCreatePanelsFromPanel: '',
    allowLinkSystem: '',
    allowWorkspaceContextMenu: '',
    allowWorkspaceEdit: '',
    'orders.confirm.all.question1': '',
    'orders.confirm.all.question2': '',
    'orders.confirm.all.title': '',
    'orders.confirm.cancel.question1': '',
    'positions.confirm.mutual.question1': '',
    'positions.confirm.mutual.question2': '',
    'Rows.firstPart': '',
    'Rows.secondPart': '',
    'general.messageBox.cancel.ToolTip': '',
    'property.Confirmations.ToolTip': '',
    'general.messageBox.ok.ToolTip': '',
    'property.Defaults.ToolTip': '',
    'property.View.ToolTip': '',
    'property.Warnings.ToolTip': '',
    'Throtling.Count.firstPart': '',
    'Throtling.Count.secondPart': '',
    'workspace.At first you should unlock workspace to rename it': 'يجب فتح مكان العمل أولاً لاستبداله.',
    'workspace.Clear': 'مسح',
    'workspace.clear_ask': 'مسح مكان العمل؟',
    'workspace.clear_wsp': 'مسح مكان العمل',
    'workspace.Close': 'إغلاق',
    'workspace.close_wsp': 'إغلاق مكان العمل',
    'workspace.DefaultNameSpace': 'جديد',
    'workspace.errorSavingFile': 'حدث خطأ خلال حفظ الملف.',
    'workspace.information': 'معلومات',
    'workspace.saved.Message': '',
    'workspace.Lock': 'قفل',
    'workspace.Lock.Message': 'تم غلق مكان المل',
    'workspace.menu.Clone': 'نسخة',
    'workspace.menu.Close': 'إغلاق',
    'workspace.menu.CloseAllButThis': 'إغلاق الكل ماعدا هذه',
    'workspace.menu.Lock': 'قفل',
    'workspace.newWorkspaceName': 'ادخل اسم مكان العمل الجديد',
    'workspace.numberOfWorkspace': 'عدد أماكن العمل المفتوحة يتعدى الحد الأقصى المسموح به',
    'workspace.Rename': 'إعادة التسمية',
    'workspace.Unlock': 'فتح',
    'workspace.Unlock.Message': 'تم فتح مكان العمل',
    'InstrumentDetailsPanel.ISIN': '',
    'panel.accountDetails.Groups.Rico risks': 'Detalhes da conta',
    'panel.accountDetails.Groups.AccountActivity': '2. نشاط الحساب',
    'panel.accountDetails.Groups.General': 'عام.1',
    'panel.accountDetails.Groups.Info': 'معلومات.5',
    'panel.accountDetails.Groups.RiskManagement': '4. Gerenciamento de risco',
    'panel.accountDetails.Groups.TodayResults': '3. نتائج اليوم',
    'panel.accountDetails.Rico risks.AVLM': 'هامش متوفر',
    'panel.accountDetails.Rico risks.BCKA': 'كمية مجمدة',
    'panel.accountDetails.Rico risks.BMBM': 'BMF هامش مسدود',
    'panel.accountDetails.Rico risks.BMFL': 'BMF حد',
    'panel.accountDetails.Rico risks.BOPT': 'رصيد الخيارات',
    'panel.accountDetails.Rico risks.BUOP': 'رصيد المكشوف للعمليات',
    'panel.accountDetails.Rico risks.CED0': 'Crédito no dia 0',
    'panel.accountDetails.Rico risks.CED1': 'Crédito no dia 1',
    'panel.accountDetails.Rico risks.CED2': 'Crédito no dia 2',
    'panel.accountDetails.Rico risks.CED3': 'Crédito no dia 3',
    'panel.accountDetails.Rico risks.CRLM': 'Limite de crédito',
    'panel.accountDetails.Rico risks.CRLO': 'Limite de crédito opt',
    'panel.accountDetails.Rico risks.D1PB': 'الرصيد المتوقع D1',
    'panel.accountDetails.Rico risks.D2PB': 'الرصيد المتوقع D2',
    'panel.accountDetails.Rico risks.D3PB': 'الرصيد المتوقع D3',
    'panel.accountDetails.Rico risks.DED0': 'Débito no dia 0',
    'panel.accountDetails.Rico risks.DED1': 'Débito no dia 1',
    'panel.accountDetails.Rico risks.DED2': 'Débito no dia 2',
    'panel.accountDetails.Rico risks.DED3': 'Débito no dia 3',
    'panel.accountDetails.Rico risks.FNBL': 'إجمالي الرصيد',
    'panel.accountDetails.Rico risks.FNLE': 'رافعة مالية',
    'panel.accountDetails.Rico risks.INBA': 'رصيد الافتتاحي',
    'panel.accountDetails.Rico risks.LEVE': 'رافعة',
    'panel.accountDetails.Rico risks.LILO': 'حد الراقعة للخيارات',
    'panel.accountDetails.Rico risks.LVLM': 'حد الرافعة',
    'panel.accountDetails.Rico risks.OPBL': 'رصيد الخيارات',
    'panel.accountDetails.Rico risks.OVLT': 'حدود السحب',
    'panel.accountDetails.Rico risks.POVA': 'مبلغ السحب على المكشوف المحتمل',
    'panel.accountDetails.Rico risks.PRBA': 'الرصيد المتوقع',
    'panel.accountDetails.Rico risks.SKBL': 'رصيد الأسهم',
    'panel.accountDetails.Rico risks.WDBL': 'رصيد السحب',
    'Order.OrderStatus.STATUS_PENDING_NEW': 'التنفيذ من الوسيط',
    'Order.OrderStatus.STATUS_NEW': 'تم الإنشاء',
    'Order.OrderStatus.STATUS_ACCEPTED': 'مقبول ',
    'Order.OrderStatus.STATUS_PENDING_EXECUTION': 'التنفيذ',
    'Order.OrderStatus.STATUS_REPLACED': 'معدل',
    'Order.OrderStatus.STATUS_PENDING_CANCEL': 'إلغاء',
    'Order.OrderStatus.STATUS_PART_FILLED': 'الجزء الممتلئ',
    'Order.OrderStatus.STATUS_FILLED': 'ممتلئ',
    'Order.OrderStatus.STATUS_PENDING_REPLACE': 'تعديل',
    'Order.OrderStatus.STATUS_WAITING_MARKET': 'انتظار السوق',
    'IndicatorGroup.Moving Average': 'Moving Average',
    MD: 'MD',
    REGRESSION: 'REGRESSION',
    Regression: 'Regression',
    SMMA: 'SMMA',
    PPMA: 'PPMA',
    SMA: 'SMA',
    EMA: 'EMA',
    'IndicatorGroup.Oscillators': 'Oscillators',
    MACD: 'MACD',
    BB: 'BB',
    BBF: 'BBF',
    'IndicatorGroup.Volume': 'Volume',
    RSI: 'RSI',
    PO: 'PO',
    CCI: 'CCI',
    Aroon: 'Aroon',
    KRI: 'KRI',
    'Kairi Relative Index': 'Kairi Relative Index',
    PPO: 'PPO',
    'Percentage Price Oscillator': 'Percentage Price Oscillator',
    RLW: 'RLW',
    '%R Larry Williams': '%R Larry Williams',
    ROC: 'ROC',
    'Rate of Change': 'Rate of Change',
    QStick: 'QStick',
    TSI: 'TSI',
    'True Strength Index': 'True Strength Index',
    'Pivot point': 'Pivot point',
    SI: 'SI',
    ALLIGATOR: 'ALLIGATOR',
    MMA: 'MMA',
    'IndicatorGroup.Trend': 'Trend',
    STOCHASTIC: 'STOCHASTIC',
    OBV: 'OBV',
    'IndicatorGroup.Volatility': 'Volatility',
    ATR: 'ATR',
    CMO: 'CMO',
    SD: 'SD',
    SAR: 'SAR',
    ADX: 'ADX',
    'IndicatorGroup.Channels': 'Channels',
    KELTNER: 'KELTNER',
    CHANNEL: 'CHANNEL',
    MAE: 'MAE',
    LWMA: 'LWMA',
    MAS3: 'MAS3',
    'IndicatorGroup.Extra': 'Extra',
    Didi: 'Didi',
    HiloEscandina: 'HiloEscandina',
    StopATR: 'StopATR',
    Fractals3: 'Fractals3',
    Fractals5: 'Fractals5',
    'Kaufman adaptive moving average': 'Kaufman adaptive moving average',
    'Multi EMA': 'Multi EMA',
    'Self-Adjusting Alpha with Fractals Energy': 'RSI Self-Adjusting Alpha with Fractals Energy',

    'Web.mobile.active.WorkingOrders': 'الأوامر العاملة',
    'Web.mobile.active.WorkingOrders.EmptyPlaceholder': 'لا توجد طلبات',
    'Web.mobile.active.FilledOrders': 'الأوامر المنفذة',
    'Web.mobile.active.HistoryOrders': 'سجل الأوامر',
    'Web.mobile.active.Positions': 'المراكز',
    'Web.mobile.active.Positions.EmptyPlaceholder': 'لا توجد مراكز',
    'Web.mobile.active.Assets': 'الأصول',
    'Web.mobile.active.Orders': 'الطلبات',
    'Web.mobile.active.More': 'المزيد',
    'Web.mobile.active.MamSummary': 'ملخص MAM',
    'Web.mobile.active.OptionChain': 'سلسلة الخيارات',
    'Web.mobile.active.Alerts.NoAlerts': 'لا تنبيهات',
    'Web.mobile.active.EventLog.NoEvents': 'لا توجد أحداث',
    'Web.mobile.active.EventLog.DateTime': 'التاريخ والوقت',
    'Web.mobile.active.MamSummary.Column.OperationType': 'نوع العملية',
    'Web.mobile.active.MamSummary.Column.Amount': 'الكمية',
    'Web.mobile.active.More.Header': 'المزيد',
    'Web.mobile.active.Settings.Defaults': 'الإعدادات الافتراضية',
    'Web.mobile.active.Settings.QuantityInLots': 'الكمية باللوت',
    'Web.mobile.active.Settings.SetSlTpInOffset': 'تعيين SL/TP في الإزاحة',
    'Web.mobile.active.Settings.OrderSending': 'إرسال الطلب',
    'Web.mobile.active.Settings.OrderModifying': 'تعديل الطلب',
    'Web.mobile.active.Settings.OrderExecuting': 'تنفيذ الطلب',
    'Web.mobile.active.Settings.OrderCancelling': 'إلغاء الطلب',
    'Web.mobile.active.Settings.PositionModifying': 'تعديل الموقف',
    'Web.mobile.active.Table.Total.Profit': 'إجمالي الربح',
    'Web.mobile.active.NewTrades.PnL': 'P/L',
    'Web.mobile.active.OrderBook.ExecutionType': 'نوع التنفيذ',
    'Web.mobile.active.Assets.EmptyPlaceholder': 'لا توجد أصول',
    'Web.mobile.active.Assets.DefSymbol': 'الرمز الافتراضي',
    'Web.mobile.active.Assets.SODQuantity': 'كمية SOD',
    'Web.mobile.active.Assets.CurrentValueForSale': 'القيمة الحالية للبيع',
    'Web.mobile.active.Positions.Profit': 'P/L',
    'Web.mobile.active.AccountDetails.LinkingTooltip': 'تُصفِّر هذه الوظيفة مراكزك وطلباتك حسب الحساب. التحول بين الحسابات يتم بشكل متزامن في جميع الألواح.',
    'Web.mobile.active.Watchlist.EmptyPlaceholder': 'لا توجد رموز',
    'Web.mobile.active.Watchlist.ListNamePlaceholder': 'اكتب الاسم هنا',
    'Web.mobile.active.Watchlist.Added': 'تمت إضافته إلى قائمة المراقبة',
    'Web.mobile.active.Watchlist.Removed': 'تمت إزالته من قائمة المراقبة',
    'Web.mobile.active.Watchlist.MaxReached': 'تم الوصول إلى الحد الأقصى',
    'Web.mobile.active.ExchangesFilter.Filter': 'تصفية',
    'Web.mobile.active.OrderEntry.Send': 'إرسال الطلب',
    'Web.mobile.active.OrderEntry.Price': 'السعر',
    'Web.mobile.active.OrderEntry.TrStop': 'وقف التداول',
    'Web.mobile.active.OrderEntry.SlPrice': 'سعر وقف الخسارة',
    'Web.mobile.active.OrderEntry.SlOffset': 'إزاحة وقف الخسارة',
    'Web.mobile.active.OrderEntry.TrSlOffset': 'إزاحة وقف الخسارة التالية',
    'Web.mobile.active.OrderEntry.SllPrice': 'سعر الحد الأدنى لوقف الخسارة',
    'Web.mobile.active.OrderEntry.SllOffset': 'إزاحة الحد الأدنى لوقف الخسارة',
    'Web.mobile.active.OrderEntry.TpPrice': 'سعر الهدف',
    'Web.mobile.active.OrderEntry.TpOffset': 'إزاحة الهدف',
    'Web.mobile.active.Login.ExploreTheApp': 'استكشاف التطبيق',
    'Web.mobile.active.InstrumentInfo.Price': 'السعر',
    'Web.mobile.active.InstrumentInfo.BreakEven': 'النقطة الصفرية',
    'Web.mobile.active.Asset.SellExchange': 'البورصة',
    'Web.mobile.active.Asset.StartDayQtyMargin': 'كمية SOD للمارجن',
    'Web.mobile.active.Asset.LiquidityRate': 'معدل السيولة %',
    'Web.mobile.active.Asset.TodayTradedQty': 'كمية التداول لليوم',
    'Web.mobile.active.Asset.AvailableQtySell': 'الكمية المتاحة للبيع',
    'Web.mobile.active.FilledOrder.TradeVolume': 'حجم التداول',
    'Web.mobile.active.FilledOrder.Date': 'التاريخ',
    'Web.mobile.active.FilledOrder.GrossPnL': 'إجمالي ر/خ',
    'Web.mobile.active.HistoryOrder.Date': 'التاريخ',
    'Web.mobile.active.MamSummary.Account': 'الحساب',
    'Web.mobile.active.MamSummary.OperationId': 'معرف العملية',
    'Web.mobile.active.MamSummary.InstrumentName': 'اسم الأداة',
    'Web.mobile.active.MamSummary.OpenPrice': 'سعر الفتح',
    'Web.mobile.active.MamSummary.ClosePrice': 'سعر الإغلاق',
    'Web.mobile.active.MamSummary.Side': 'الجانب',
    'Web.mobile.active.MamSummary.Quantity': 'الكمية',
    'Web.mobile.active.MamSummary.Buy': 'شراء',
    'Web.mobile.active.MamSummary.Sell': 'بيع',
    'Web.mobile.active.Position.StopLoss': 'وقف الخسارة',
    'Web.mobile.active.Position.TakeProfit': 'هدف الربح',
    'Web.mobile.active.Position.Date': 'التاريخ',
    'Web.mobile.active.Order.StopLoss': 'وقف الخسارة',
    'Web.mobile.active.Order.TrStopOffset': 'Trailing SL offset',
    'Web.mobile.active.Order.TrStopLoss': 'SL Trailing stop',
    'Web.mobile.active.Order.TakeProfit': 'هدف الربح',
    'Web.mobile.active.Order.QuantityFilled': 'Qty. filled',
    'Web.mobile.active.Search': 'بحث',
    'Web.mobile.active.CalendarPeriod.Today': 'اليوم',
    'Web.mobile.active.CalendarPeriod.LastWeek': 'الأسبوع الماضي',
    'Web.mobile.active.CalendarPeriod.Last1M': 'آخر شهر',
    'Web.mobile.active.CalendarPeriod.Last3M': 'آخر 3 أشهر',
    'Web.mobile.active.CalendarPeriod.Last6M': 'آخر 6 أشهر',
    'Web.mobile.active.InstrumentDetails.6.SessionInfo': 'الجدول الزمني',
    'Web.mobile.active.OrderModify.Confirmation.Title.Modify': 'تعديل الطلب',
    'Web.mobile.active.OrderModify.Confirmation.Title.Cancel': 'إلغاء الطلب',
    'Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket': 'تنفيذ الطلب على السوق؟',
    'Web.mobile.active.OrderModify.Error.SLSmallerMin': 'وقف الخسارة أقل من الحد الأدنى الممكن',
    'Web.mobile.active.OrderModify.Error.SLGreaterMax': 'وقف الخسارة أكبر من الحد الأقصى الممكن',
    'Web.mobile.active.OrderModify.Error.TPSmallerMin': 'هدف الربح أقل من الحد الأدنى الممكن',
    'Web.mobile.active.OrderModify.Error.TPGreaterMax': 'هدف الربح أكبر من الحد الأقصى الممكن',
    'Web.mobile.active.PositionModify.Confirmation.Title': 'تعديل المركز',
    'Web.mobile.active.PositionClose.Confirmation.Lots': 'لوت(ات)',
    'Web.mobile.active.Lists': 'قوائم',
    'Web.mobile.active.AccountDetails.Groups.1.AccountSummary': 'ملخص الحساب',
    'Web.mobile.active.AccountDetails.Groups.4.TodayResult': 'نتيجة اليوم',
    'Web.mobile.active.AccountDetails.MarginUsedPercent': 'نسبة الهامش الأولي المطلوبة',
    'Web.mobile.active.AccountDetails.OptionPremiumReq': 'متطلبات الخيارات الممتازة',
    'Web.mobile.active.AccountDetails.OpenGrossPL': 'صافي الربح/الخسارة المفتوحة',
    'Web.mobile.active.AccountDetails.OpenPostionsNumber': 'المراكز المفتوحة',
    'Web.mobile.active.AccountDetails.OpenOrdersNumber': 'الأوامر العاملة',
    'Web.mobile.active.AccountDetails.TodaysNetProfit': 'صافي الربح لليوم',
    'Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators': 'المؤشرات المضافة',
    'Web.mobile.active.Chart.Menu.ChartSyle': 'نمط الرسم البياني',
    'Web.mobile.active.Chart.Menu.Settings': 'الإعدادات',
    'Web.mobile.active.Chart.NoDataAvailable': 'No data available for {0}',
    'Web.mobile.active.TrailingStopLoss': 'Tr.',
    'Web.mobile.active.Position.GrossPnL': 'إجمالي ر/خ',
    'Web.mobile.active.Table.Total.GrossPnL': 'Total Gross P/L',
    'ChartObjectManager.Text': 'إدارة الموضوع',
    'ObjectManagerItem.Name': 'الاسم',
    'ObjectManagerItem.Remove': 'حذف',
    'ObjectManagerItem.Settings': 'الخيارات',
    'ObjectManagerItem.Show on chart': 'العرض على الرسم البياني',
    'ObjectManagerItem.Visible': 'اظهار',
    'ObjectManagerItem.Window': 'نافذة',
    'ChartObjectManager.mainWindow': 'النافذة الرئيسية',
    'ChartObjectManager.subWindow': 'نافذة متفرعة',
    'chart.menu.ObjectManager.RemoveAll': 'حذف الكل',
    'chart.menu.ObjectManager.RemoveSelected': 'حذف المحدد'
};
