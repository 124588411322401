// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PropertySetupScreen } from './PropertySetupScreen';

export class PanelSettingsScreen {
    public static async EditProperties (caller, defaultPage, headerString, parentPanel?): Promise<void> {
        await PropertySetupScreen.editProperty(caller, headerString, parentPanel);
        if (caller?.propertiesScreen) {
            caller.propertiesScreen.selectPage(defaultPage);
        }
    }
}

// const PanelSettingsDefaultPage = {
//     General: 0,
//     Filters: 1,
//     Coloring: 2,
//     Alerts: 3,
//     DataBox: 8,
//     AllPrice: 9,
//     VolumeProfile: 10,
//     BarStatistics: 11,
//     VolumeBars: 12,
//     Sesonality: 13,
//     TerceraChartTimeAndSales: 14,
//     BarPattern: 15
// };
