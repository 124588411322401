// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';

export class RiskPlanDataKey {
    public Route: number | null = null;
    public InstrumentType: InstrumentTypes | null = null;
    public InstrumentId: string | null = null;
    public ContractId: number | null = null;
    public TradableId: number | null = null;

    public toString (): string {
        let result = 'RouteName: ' + (this.Route == null ? 'DEFAULT' : this.Route) + '; ';

        if (this.InstrumentType !== null) {
            result += 'InstrumentType: ' + this.InstrumentType.toString();
        }

        if (this.InstrumentId !== null) {
            result += 'InstrumentId: ' + this.InstrumentId;
        }

        if (this.ContractId !== null) {
            result += 'ContractId: ' + this.ContractId;
        }

        if (this.TradableId !== null) {
            result += 'TradableId: ' + this.TradableId;
        }

        return result;
    }
}
