// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { type MAMode } from '../../IndicatorConstants';
import { iADXEx } from './iADXEx';

export class iADX extends iADXEx {
    constructor (maPeriod, maMode: MAMode) {
        super(maPeriod, PriceType.Close, maMode);
    }

    override get Name (): string { return 'iADX'; }
}
