// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixField } from './PFixField';

export class PFixSignatureField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = new Array(0);
    }

    public override ReadValue (buffer): void {
        const len = buffer.ReadShort();
        const data = buffer.ReadBuffer(len).ReadAllBytes();
        this.Value = data;
    }

    public override Write (buffer): void {
        buffer.WriteShort(this.FieldId);

        if (this.Value == null) {
            return;
        }

        buffer.WriteShort(this.Value.length);
    }
}
