// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class EventDescriptionItem {
    public Header: string;
    public Value: any;

    constructor (header: string | undefined = undefined, value: any = undefined) {
        this.Header = header || '';
        this.Value = value || '';
    }
}
