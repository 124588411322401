// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type OrderEditBase } from '../OrderEditBase';

export abstract class BaseOrderWarning {
    protected readonly orderEdit: OrderEditBase;

    constructor (orderEdit: OrderEditBase) {
        this.orderEdit = orderEdit;
    }

    abstract getText (): string;
}
