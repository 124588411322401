// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DynProperty } from '@shared/commons/DynProperty';
import { type ITradingData } from './TradingData';
import { type PlacedFrom } from './PlacedFrom';

export class OrderEditUpdateData {
    public dpDict: Record<string, DynProperty>;
    public tradingDataDict: ITradingData;
    public sessionSettingsChanged: boolean;
    public placedFrom: PlacedFrom;

    constructor (
        dpDict: Record<string, DynProperty> = {},
        tradingDataDict: ITradingData = null,
        sessionSettingsChanged: boolean = false,
        placedFrom: PlacedFrom = null
    ) {
        this.dpDict = dpDict;
        this.tradingDataDict = tradingDataDict;
        this.sessionSettingsChanged = sessionSettingsChanged;
        this.placedFrom = placedFrom;
    }

    get newTradingDataDict (): ITradingData {
        return this.tradingDataDict;
    }

    get dp (): DynProperty | null {
        const values = Object.values(this.dpDict);
        return values.length > 0 ? values[0] : null;
    }
}
