// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { LayersEnum } from '@front/chart/Renderers/TerceraChartBaseRenderer';
import { DataCacheToolType, DataCacheToolTypeName, GetGroupByToolType, GetToolTypesByGroup } from '@shared/commons/cache/DataCacheToolEnums';
import { TerceraChartAction, TerceraChartActionEnum } from '@front/chart/TerceraChartAction';
import { ThemeManager } from '../misc/ThemeManager';
import { contextMenuHandler } from '@shared/utils/AppHandlers';
import { TerceraChartActionProcessor } from '@front/chart/TerceraChartActionProcessor/TerceraChartActionProcessor';
import { Resources } from '@shared/localizations/Resources';
import { TerceraChartToolsPanelTemplate } from '../../templates';
import { ContainerControl } from './ContainerControl';
import { ControlsUtils } from '../UtilsClasses/ControlsUtils';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { HtmlScrollXY } from '@shared/commons/HtmlScroll';

export class TerceraChartToolsPanel extends ContainerControl {
    private static readonly OutPadding = 5;
    private static readonly ChartHeaderHeight = 25;
    private static readonly MoveSeporatorHeight = 20;
    private static readonly MoveSeporatorWidth = 10;
    private static readonly ChartOEOffset: number = 125;
    private static readonly MovingSize: number = 30;
    private static readonly ToolbarOffset: number = 3;

    public dropDownMenuTimeoutID: NodeJS.Timeout = null;

    private lastPosition: boolean = true;

    public override getType (): string { return ControlsTypes.TerceraChartToolsPanel; }

    public override oninit (): void {
        super.oninit();

        this.observe('actionProcessor', this.onActionProcessorChanged, { init: false });
        this.on('onMovingBtnClick', this.onMovingBtnClick);
        this.on('onMouseUp', this.onMouseUp);

        this.observe('left top', this.onDragToolbar);

        this.setBounds(0, TerceraChartToolsPanel.MovingSize, TerceraChartToolsPanel.MovingSize, TerceraChartToolsPanel.MovingSize);
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.lastPosition = this.get('isVertical');

        this.toolsScrollAdd();
    }

    public onDragToolbar (): void {
        if (this.get('onProcessMove') === false) { return; }

        const x = this.get('left');
        const y = this.get('top');

        const container = this.getClientPanel();

        if (container == null) { return; }

        const bottom = container.offsetHeight - y;
        const right = container.offsetWidth - x;

        const isVertical = (x < y && x < bottom) || (right < y && right < bottom);

        void this.set({
            isVertical,
            isLeftOrTop: (x < right && isVertical) || (y < bottom && !isVertical)
        });
    }

    public recalculateLocation (): void {
        const container = this.getClientPanel();
        if (container == null) { return; }

        const z = TerceraChartToolsPanel.ToolbarOffset;
        const offsetTop = TerceraChartToolsPanel.MovingSize + container.offsetTop;
        const w = container.offsetWidth - TerceraChartToolsPanel.MovingSize - 4;
        const h = container.offsetHeight - TerceraChartToolsPanel.MovingSize - 1;

        const isVertical: boolean = this.get('isVertical');
        const isLeftOrTop: boolean = this.get('isLeftOrTop');

        const x = isLeftOrTop ? (isVertical ? 3 : z) : (isVertical ? w : 0);
        const y = isVertical ? offsetTop : (isLeftOrTop ? offsetTop - z : h + container.offsetTop);

        const needOffsetFromOE: boolean = isVertical && !isLeftOrTop && this.get('chartOEVisible');
        // if (needOffsetFromOE && this.chartOEOffset === null) { this.chartOEOffset = container.getElementsByClassName('chart-order-entry')[0]?.clientWidth; }
        const offsetFromOE = needOffsetFromOE ? TerceraChartToolsPanel.ChartOEOffset : 0;
        this.setLocation(x - offsetFromOE, y);
    }

    public onMovingBtnClick (event): void {
        this.onMouseDown(event, true);
    }

    public onMouseUp (): void {
        void this.set('onProcessMove', false);
    }

    public onItemClicked (item): void {
        if (item?.event) item.event(item.type);
    }

    public showDropDownMenu (action, event): void {
        const aD = action?.Details;
        const groupType = GetGroupByToolType(aD);
        let items: any[] = GetToolTypesByGroup(groupType);
        let longestText = '';
        items = items.map((t) => {
            const action = TerceraChartAction.Create(
                TerceraChartActionEnum.CreationTool,
                t
            );
            const lKey = TerceraChartActionProcessor.ToolStateInfoDict[t].locKey;
            const txt = Resources.getResource(lKey);
            if (txt.length > longestText.length) {
                longestText = txt;
            }
            return {
                tag: t,
                type: action,
                style: `js-drawingTool-${DataCacheToolTypeName[t]}`,
                name: DataCacheToolTypeName[t],
                locKey: lKey,
                text: txt,
                tooltip: txt,
                event: this.onToolStripItemClicked.bind(this, action)
            };
        });

        const isVertical: boolean = this.get('isVertical');
        const isLeftOrTop: boolean = this.get('isLeftOrTop');
        const isBottom: boolean = !isVertical && !isLeftOrTop;
        const isRight: boolean = isVertical && !isLeftOrTop;
        const h = event.node.offsetHeight;
        const w = event.node.offsetWidth;
        const original = event.original;
        const imgW = 55;
        const menuW = ControlsUtils.GetTextWidth(
            longestText,
            ThemeManager.Fonts.Font_12F_regular
        ) + imgW;

        const menuX = original.x - original.offsetX + (isVertical ? (isRight ? -menuW - 15 : w) : 0);
        const menuY = original.y - original.offsetY + (isVertical ? 0 : (isBottom ? -25 * items.length : h));

        contextMenuHandler.Show(items, menuX, menuY, { width: menuW });
    }

    public onHoverToolEvent (caller, event): void {
        if (this.dropDownMenuTimeoutID !== null) clearTimeout(this.dropDownMenuTimeoutID);

        this.dropDownMenuTimeoutID = setTimeout(() => {
            this.showDropDownMenu(caller.type, event);
        }, 0);
    }

    public createDefaultItems (): any[] {
        const callback = this.onToolStripItemClicked.bind(this);
        const onHoverEvent = this.onHoverToolEvent.bind(this);

        return [
            { visible: true, name: 'Cancel', type: TerceraChartAction.Create(TerceraChartActionEnum.Cancel), event: callback },

            { visible: true, name: 'ToolSelector', type: TerceraChartAction.Create(TerceraChartActionEnum.ToolSelector), event: callback },
            // { visible: true, name: 'Eraser', type: TerceraChartAction.Create(TerceraChartActionEnum.Eraser), event: callback },

            { name: 'separator' },

            { visible: true, name: 'SnapToCandle', type: TerceraChartAction.Create(TerceraChartActionEnum.SnapToCandle), event: callback },
            { visible: true, name: 'SetStayInDrawingMode', type: TerceraChartAction.Create(TerceraChartActionEnum.SetStayInDrawingMode), event: callback },

            { name: 'separator' },

            // Lines tools // { visible: true, name: DataCacheToolTypeName[DataCacheToolType.TrendTools], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Line), event: callback, hoverEvent: onHoverEvent },
            { visible: true, name: DataCacheToolTypeName[DataCacheToolType.HorizontalLine], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.HorizontalLine), event: callback, hoverEvent: onHoverEvent },

            { name: 'separator' },

            // { visible: true, name: DataCacheToolTypeName[DataCacheToolType.GeometryTools], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Triangle), event: callback, hoverEvent: onHoverEvent },
            { visible: true, name: DataCacheToolTypeName[DataCacheToolType.Triangle], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.Triangle), event: callback, hoverEvent: onHoverEvent },

            { name: 'separator' },

            // Fibonacci // { visible: true, name: DataCacheToolTypeName[DataCacheToolType.FibonacciTools], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciRetracement), event: callback, hoverEvent: onHoverEvent },
            { visible: true, name: DataCacheToolTypeName[DataCacheToolType.FibonacciRetracement], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.FibonacciRetracement), event: callback, hoverEvent: onHoverEvent },

            { name: 'separator' },

            // Gann // { visible: true, name: DataCacheToolTypeName[DataCacheToolType.GannTools], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannLine), event: callback, hoverEvent: onHoverEvent },
            { visible: true, name: DataCacheToolTypeName[DataCacheToolType.GannLine], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.GannLine), event: callback, hoverEvent: onHoverEvent },

            { name: 'separator' },

            // Elliott  Wave name: DataCacheToolTypeName[DataCacheToolType.ElliottTools]
            { visible: true, name: DataCacheToolTypeName[DataCacheToolType.ElliottImpulse], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.ElliottImpulse), event: callback, hoverEvent: onHoverEvent },

            { name: 'separator' },

            { visible: true, name: DataCacheToolTypeName[DataCacheToolType.InfoLine], type: TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, DataCacheToolType.InfoLine), event: callback, hoverEvent: onHoverEvent },

            { name: 'separator' },

            { visible: true, name: 'DeleteHoverAndSelectedTools', type: TerceraChartAction.Create(TerceraChartActionEnum.DeleteHoverAndSelectedTools), event: callback }
        ];
    }

    public onActionProcessorChanged (newProc, oldProc): void {
        if (oldProc) oldProc.StateChanged.UnSubscribe(this.updateToolbar, this);
        if (newProc) newProc.StateChanged.Subscribe(this.updateToolbar, this);

        if (!newProc) return;

        this.updateToolbar();
    }

    public onResize (): void {
        const parentSize = this.getParentSideSize();
        if (this.get('maxSize') !== parentSize) {
            void this.set({ maxSize: parentSize });

            const items: any[] = this.get('toolStipItems') || [];
            if (items.length === 0) return;

            this.toolsScrollUpdate();
        }

        this.recalculateLocation();
    }

    private getParentSideSize (): number {
        let parentSize = 0;
        if (this.get('isVertical')) {
            parentSize = this.el.parentElement.offsetHeight;

            parentSize -= (TerceraChartToolsPanel.MoveSeporatorHeight + TerceraChartToolsPanel.ChartHeaderHeight);
        } else {
            parentSize = this.el.parentElement.offsetWidth;
            parentSize -= TerceraChartToolsPanel.MoveSeporatorWidth;
        }

        parentSize -= TerceraChartToolsPanel.OutPadding * 2;

        return parentSize;
    }

    private toolsScrollAdd (): void {
        const tools = this.find('.tool-strip-items-container');
        if (!isNullOrUndefined(tools)) {
            const axis = this.get('isVertical') ? 'y' : 'x';
            HtmlScrollXY.addScroll(tools, axis);
        }
    }

    private toolsScrollUpdate (): void {
        const tools = this.find('.tool-strip-items-container');
        if (!isNullOrUndefined(tools)) {
            const curPosition: boolean = this.get('isVertical');
            if (curPosition !== this.lastPosition) {
                this.lastPosition = curPosition;
                const axis = curPosition ? 'y' : 'x';
                HtmlScrollXY.removeScroll(tools);
                HtmlScrollXY.addScroll(tools, axis);
            } else {
                HtmlScrollXY.updateScroll(tools);
            }
        }
    }

    public updateToolbar (): void {
        const actionProcessor = this.get('actionProcessor');
        if (!actionProcessor) return;

        let toolStripItems = this.get('toolStipItems');
        // First init for optimization purposes.
        if (!toolStripItems) toolStripItems = this.createDefaultItems();

        const len = toolStripItems.length;
        for (let i = 0; i < len; i++) {
            const tsItem = toolStripItems[i];
            if (tsItem.name === 'separator') continue;

            const action = tsItem.type;
            const state = actionProcessor.GetTerceraChartActionState(action);

            tsItem.active = state.Active;
            tsItem.enabled = state.Enabled;
            tsItem.visible = state.Visible;
            tsItem.tooltip = state.locKey;
        }
        actionProcessor.chart.IsDirty(LayersEnum.Tools);
        void this.set('toolStipItems', toolStripItems);
        this.onResize();
    }

    public onToolStripItemClicked (action, caller): void {
        this.onGroupBtnClicked(action, caller);

        this.get('actionProcessor').ProcessTerceraChartAction(action);
    }

    public onGroupBtnClicked (action, caller): any {
        const toolType = action.Details;
        const groupType = GetGroupByToolType(toolType);

        if (groupType != null) {
            // may clicked on new tool from Drawing Group btn context menu => need to change group btn image and events
            void this.set('toolStipItems', this.get('toolStipItems').map((item) => {
                const itemToolType = item.type?.Details;
                if (caller != null && groupType === GetGroupByToolType(itemToolType) && toolType !== itemToolType) {
                    item.name = caller.name;
                    item.type = TerceraChartAction.Create(TerceraChartActionEnum.CreationTool, toolType); // change toolStipItem
                    item.event = this.onToolStripItemClicked.bind(this);
                    item.hoverEvent = this.onHoverToolEvent.bind(this);
                }
                return item;
            }));
        }
    }

    public override dispose (): void {
        const actionProcessor = this.get('actionProcessor');
        if (actionProcessor != null) {
            actionProcessor.StateChanged.UnSubscribe(this.updateToolbar, this);
        }

        super.dispose();
    }

    public themeChange (): void {
        this.updateToolbar();
    }

    public localize (): void {}
}

ContainerControl.extendWith(TerceraChartToolsPanel, {
    template: TerceraChartToolsPanelTemplate,

    data () {
        return {
            isVertical: true,
            isLeftOrTop: true,
            movable: true,
            isPosAbsolute: true,
            actionProcessor: null,
            maxSize: 0
        };
    }
});
