// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../DataCache';
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { type InitialSettingsMap } from '../charting_library';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { type ITvChartStateSettings } from '../TradingViewPrimitives/TvChartStateSettings';

interface IChartSettings {
    charts: [{
        panes: [{
            sources: [{
                    state: ITvChartStateSettings
            }]
        }]
    }]
}

interface IWatchlistData {
    symbols: string[]
}

class _TvSavedDataHelper {
    public correctChartSettings (defaultChartSettings: IChartSettings): void {
        if (!isValidArray(defaultChartSettings?.charts)) {
            return;
        }

        const charts = defaultChartSettings.charts;

        for (const chart of charts) {
            const state = chart.panes[0].sources[0].state;
            const symbolName = state.symbol;

            if (!isValidString(symbolName)) {
                return;
            }

            const interiorId = TvInteriorIdCache.getInteriorId(symbolName);
            const currentInstrument = DataCache.getInstrumentByName(interiorId);
            if (isNullOrUndefined(currentInstrument)) {
                const newInstrument = DataCache.getAnyInstrument();
                TvSymbolConvertor.correctTvChartState(state, newInstrument);
            }
        }
    }

    public correctInitialSettings (initSettings: InitialSettingsMap): void {
        if (isNullOrUndefined(initSettings)) {
            return;
        }

        const prefix = 'savedwatch';
        const regex = new RegExp(`^${prefix}\\.\\w+$`);
        const watchlistKeys = Object.keys(initSettings).filter(key => regex.test(key));

        for (const watchlistKey of watchlistKeys) {
            const watchlistInfoJSON = initSettings[watchlistKey];
            const watchlistInfo: IWatchlistData = JSON.parse(watchlistInfoJSON);
            const newSymbols = this.getCorrectListOfSymbols(watchlistInfo.symbols);
            if (newSymbols.length !== watchlistInfo.symbols.length) {
                watchlistInfo.symbols = newSymbols;
                initSettings[watchlistKey] = JSON.stringify(watchlistInfo);
            }
        }
    }

    private getCorrectListOfSymbols (symbols: string[]): string[] {
        const correctSymbols: string[] = [];
        const sectionStartKey = '###';
        for (const symbolName of symbols) {
            if (symbolName.startsWith(sectionStartKey)) {
                correctSymbols.push(symbolName);
                continue;
            }

            const interiorId = TvInteriorIdCache.getInteriorId(symbolName);
            const instrument = DataCache.getInstrumentByName(interiorId);
            if (isNullOrUndefined(instrument)) continue;

            correctSymbols.push(symbolName);
        }

        return correctSymbols;
    }
}

export const TvSavedDataHelper = new _TvSavedDataHelper();
