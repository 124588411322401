// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataStream } from './DataStream';

export class SocketDataStream extends DataStream {
    public StreamOpened = false;

    public OnOpenDelegate: any;
    public OnCloseDelegate: any;
    public OnErrorDelegate: any;

    public ws: WebSocket;

    constructor (ws: WebSocket, delegates) {
        super(delegates.OnMessageDelegate);

        this.OnOpenDelegate = delegates.OnOpenDelegate;
        this.OnCloseDelegate = delegates.OnCloseDelegate;
        this.OnErrorDelegate = delegates.OnErrorDelegate;

        ws.onopen = this.onopen.bind(this);
        ws.onclose = this.onclose.bind(this);
        ws.onmessage = this.onmessage.bind(this);
        ws.onerror = this.onerror.bind(this);

        this.ws = ws;
    }

    public onmessage (evt): void {
        if (this.OnMessageDelegate !== null) {
            this.OnMessageDelegate(evt.data);
        }
    }

    public onopen (): void {
        this.StreamOpened = true;
        if (this.OnOpenDelegate) {
            this.OnOpenDelegate(true);
        }
    }

    public onclose (evt): void {
        if (evt.code !== 1000) {
            this.onerror(evt);
        }

        this.StreamOpened = false;
        if (this.OnCloseDelegate) {
            this.OnCloseDelegate();
        }
    }

    public onerror (evt): void {
        if (this.OnErrorDelegate) {
            this.OnErrorDelegate(false);
        }
        this.Dispose();
    }

    public override Dispose (): void {
        if (!this.ws) {
            return;
        }

        this.StreamOpened = false;
        this.ws.onopen = null;
        this.ws.onclose = null;
        this.ws.onmessage = null;
        this.ws.onerror = null;
        this.OnOpenDelegate = null;
        this.OnCloseDelegate = null;
        this.OnErrorDelegate = null;
        this.ws.close();
        this.ws = null;
    }

    public CloseStream (): void {
        if (!this.ws) {
            return;
        }

        this.StreamOpened = false;
        this.ws.onopen = null;
        this.ws.onclose = null;
        this.ws.onmessage = null;
        this.ws.onerror = null;
        this.OnOpenDelegate = null;
        this.OnCloseDelegate = null;
        this.OnErrorDelegate = null;
        this.ws.close();
        this.ws = null;
    }

    public override Connected (): boolean {
        return this.StreamOpened;
    }

    public override SendData (message): void {
        if (this.Connected()) {
            this.ws.send(message);
        }
    // else
    //    this.Dispose();
    }
}
