// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type DirectQuoteMessage } from '../../../Utils/DirectMessages/DirectQuoteMessage';
import { HistoryType } from '../../../Utils/History/HistoryType';
import { DataCache } from '../../DataCache';
import { TvQuoteUtils } from '../../UtilsClasses/TvQuoteUtils';
import { type Instrument } from '../../cache/Instrument';
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { TvSymbolConvertor } from '../Convertors/TvSymbolConvertor';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';
import { type IBrokerConnectionAdapterHost, type TradingQuotes } from '../charting_library';

export class TvQuoteRealtimeManager {
    private readonly host: IBrokerConnectionAdapterHost;
    private subscribedSymbols: string[] = [];
    private isDelayed: boolean = false;

    constructor (host: IBrokerConnectionAdapterHost) {
        this.host = host;
    }

    public subscribeRealtime (symbol: string): void {
        const instrument = this.getInstrument(symbol);
        if (isNullOrUndefined(instrument)) {
            return;
        }
        this.isDelayed = TvSymbolConvertor.getQuoteDelay(instrument) > 0;
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_TRADES);
        this.subscribedSymbols.push(symbol);
    }

    public unsubscribeRealtime (symbol: string): void {
        const instrument = this.getInstrument(symbol);
        if (isNullOrUndefined(instrument)) {
            return;
        }
        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_TRADES);
        this.subscribedSymbols = this.subscribedSymbols.filter(item => item !== symbol);
    }

    public changeCurrentAccount (): void {
        this.subscribedSymbols.forEach((symbol) => {
            const instrument = this.getInstrument(symbol);
            if (isNullOrUndefined(instrument)) {
                console.warn(`TvQuoteRealtimeManager::changeCurrentAccount::Instrument ${symbol} not found`);
                return;
            }
            this.sendRealtimeUpdate(instrument);
        });
    }

    public newQuote (msg: DirectQuoteMessage): void {
        const interiorId = msg.TargetInstrumentName;
        const instrument = DataCache.getInstrumentByName(interiorId);
        if (isNullOrUndefined(instrument)) {
            console.warn(`TvQuoteRealtimeManager::newQuote::Instrument ${interiorId} not found`);
            console.warn(msg);
            return;
        }

        this.sendRealtimeUpdate(instrument);
    }

    private getInstrument (symbol: string): Instrument {
        const interiorId = TvInteriorIdCache.getInteriorId(symbol);
        const instrument = DataCache.getInstrumentByName(interiorId);
        return instrument;
    }

    private sendRealtimeUpdate (instrument: Instrument): void {
        const symbol = TvSymbolConvertor.getFullname(instrument);
        const account = TvAccountHelper.getCurrentAccount();

        const askPrice = instrument.Level1.GetAsk(account);
        const bidPrice = instrument.Level1.GetBid(account);
        const spreadPrice = askPrice - bidPrice;

        const tradeQuote: TradingQuotes =
            {
                ask: TvQuoteUtils.replaceNaN(askPrice),
                bid: TvQuoteUtils.replaceNaN(bidPrice),
                trade: TvQuoteUtils.replaceNaN(instrument.Level1.GetLastPrice(account)),
                spread: TvQuoteUtils.replaceNaN(spreadPrice),
                bid_size: TvQuoteUtils.replaceNaN(instrument.Level1.getBidSize(true)),
                ask_size: TvQuoteUtils.replaceNaN(instrument.Level1.getAskSize(true)),
                size: TvQuoteUtils.replaceNaN(instrument.Level1.GetLastSize(true)),
                isDelayed: this.isDelayed
                // isHalted?: boolean;
                // isHardToBorrow?: boolean;
                // isNotShortable?: boolean;
            };
        this.host.realtimeUpdate(symbol, tradeQuote);
    }
}
