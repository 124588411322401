// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '../../Utils/CustomEvents';
import { TerceraFontPickerTemplate } from '../../templates.js';
import { ContainerControl } from './ContainerControl';

export class TerceraFontPicker extends ContainerControl {
    public FontChanged: CustomEvent;

    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.FontChanged = new CustomEvent();
        this.observe('font', this.onFontChanged);
    }

    public onFontChanged (newFont): void {
        this.FontChanged.Raise(this, newFont);
    }
}

ContainerControl.extendWith(TerceraFontPicker, {
    data: function () {
        return {
            font: null,
            height: 20
        };
    },

    computed: {
        bold: {
            get: function () {
                const font = this.get('font');
                return font ? font.FontWeight === 'bold' : false;
            },
            set: function (bold) {
                if (this.get('font')) {
                    void this.set('font.FontWeight', bold ? 'bold' : '');
                }
            }
        }
    },

    template: TerceraFontPickerTemplate
});
