// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class TradingSystem // Trading Ideas Analyst's object
{
    public logoBytes: any;
    public login: any;
    public name: any;
    public companyName: any;
    public tradingSystemID: any;
    public riskDisclosureText: any;
    public accepted = true;

    constructor (msg) {
        this.logoBytes = msg.ImageBytes;
        this.login = msg.Login;
        this.name = msg.Name;
        this.companyName = msg.CompanyName;
        this.tradingSystemID = msg.ID;
        this.riskDisclosureText = msg.RiskDisclosure;
    }
}
