// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { Resources } from '@shared/localizations/Resources';
import { QuickTreeNode } from '../QuickTree/QuickTreeNode';
import { TerceraLookupDropDownForm } from './TerceraLookupDropDownForm';
import { IndicatorManager } from '@shared/commons/cache/indicators/IndicatorManager';
import { TerceraIndicatorLookupDropDownFormTemplate } from '../../../templates.js';
import { type LookupDropDownShowParams } from '../../UtilsClasses/LookupDropDownShowParams';
import { ScreensNames } from '../../UtilsClasses/FactoryConstants';

export class TerceraIndicatorLookupDropDownForm extends TerceraLookupDropDownForm {
    constructor () { super(); }

    public override initializationComponents (): void {
        this.textBox = this.Controls.textBox;

        const qtr = this.Controls.quickTree;
        if (!isNullOrUndefined(qtr?.quickTree)) {
            this.quickTree = qtr;
        }
    }

    public override getType (): ScreensNames { return ScreensNames.TerceraLookupDropDownForm; }

    public override fillTree (): void {
        const startNode = new QuickTreeNode(); ;

        const filter = this.currentNameFilter;
        const procs = this.items;

        if (isValidString(filter)) {
            filter.toLowerCase();
        }

        let visibleIndicatorCount = 0;

        const len = procs.length;
        const map = {};
        for (let i = 0; i < len; i++) {
            const handler = procs[i];

            if (isValidString(filter)) {
                if (handler.ShortName.toLowerCase().indexOf(filter) === -1 && handler.FullName.toLowerCase().indexOf(filter) === -1) {
                    continue;
                }
            }

            visibleIndicatorCount++;

            let parentNode = map[handler.DisplayGroup];
            if (isNullOrUndefined(parentNode)) {
                parentNode = new QuickTreeNode();
                parentNode.nodeText = Resources.getResource('IndicatorGroup.' + handler.DisplayGroup);
                parentNode.tag = null;
                parentNode.collapsed = false;
                map[handler.DisplayGroup] = parentNode;

                startNode.AddChildNode(parentNode);
            }

            const subNode = new QuickTreeNode();
            subNode.nodeText = this.getNodeText(handler);
            subNode.tag = handler;

            parentNode.AddChildNode(subNode);
        }

        const qt = this.quickTree.quickTree;

        qt.Clear();
        qt.AddNodeRange(startNode.childNodes);
        this.quickTree.setSizes();

        this.updateVisibleIndicatorsCount(visibleIndicatorCount);
    }

    public getNodeText (item): string {
        const shortName = Resources.getResource(item.ShortNameLocKey ?? item.ShortName);
        const fullName = Resources.getResource(item.FullNameLocKey ?? item.FullName);

        return `${shortName} (${fullName})`;
    }

    public updateVisibleIndicatorsCount (visibleIndicatorsCount: number): void {
        void this.set('labelCountText', isValidNumber(visibleIndicatorsCount)
            ? Resources.getResource('screen.lookup.scripts.showing').replace('{1}', visibleIndicatorsCount.toString())
            : Resources.getResource('screen.lookup.scripts.noitems'));
    }

    public onAddIndicatorBtnClicked (visibleIndicatorsCount): void {
        if (!isNullOrUndefined(this.quickTree?.quickTree)) {
            const selectedNode = this.quickTree.quickTree.selectedNode;
            if (!isNullOrUndefined(selectedNode)) {
                this.callBack(selectedNode.tag);
            }
            this.close();
        }
    }

    public static PopulateWithFolderNodes (handler, curNode: QuickTreeNode): QuickTreeNode {
        const folderNode = new QuickTreeNode();
        folderNode.nodeText = handler.DisplayGroup;
        folderNode.tag = true;
        curNode.AddChildNode(folderNode);
        return folderNode;
    }

    public static ShowForm (params: LookupDropDownShowParams): void {
        let instanceWaiter = null;
        if (isNullOrUndefined(MainWindowManager.TerceraIndicatorLookupDropDownForm)) {
            instanceWaiter = TerceraIndicatorLookupDropDownForm.createInstance().then((instance) => {
                MainWindowManager.TerceraIndicatorLookupDropDownForm = instance;
                return instance;
            });
        } else {
            instanceWaiter = Promise.resolve(MainWindowManager.TerceraIndicatorLookupDropDownForm);
        }

        instanceWaiter.then((instance) => {
            instance.textBox.set('text', '');
            params.isMultiSelect = false;
            params.items = IndicatorManager.GetIndicatorItemsList();
            params.isCentered = true;
            instance.showForm(params);
        });
    }

    public static override async createInstance (): Promise<TerceraIndicatorLookupDropDownForm> {
        const lookup = new TerceraIndicatorLookupDropDownForm();
        lookup.setBounds(0, 0, 410, 485);
        void lookup.set('visible', false);
        MainWindowManager.MainWindow.addControl(lookup);

        return await lookup.OnCompliteWaiter; ;
    }
}

TerceraLookupDropDownForm.extendWith(TerceraIndicatorLookupDropDownForm, {
    partials: { bodyPartial: TerceraIndicatorLookupDropDownFormTemplate }
});
