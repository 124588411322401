// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { Resources } from '@shared/localizations/Resources';
import { SoundManager } from '@shared/utils/SoundManager';
import { TicketViewerTemplate, TerceraToastTemplate } from '../../templates.js';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';
import { TerceraTicketScreen } from '../screen/TerceraTicketScreen';
import { Control } from './Control';
import { Event } from '@shared/commons/cache/Event/Event';
import { EventSource, EventType } from '@shared/commons/cache/Event/EventConstants';
import { EventCache } from '@shared/commons/cache/Event/EventCache';
import { TicketData } from '../UtilsClasses/TicketData';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { ReportMessageTooltip } from '@shared/utils/ReportMessageTooltip';
import { TooltipTypeName, TooltipType } from '@shared/utils/ReportMessageTooltipTypeEnum';
import { type DirectReportMessage } from '@shared/utils/DirectMessages/DirectMessagesImport';
import { WDSettings } from '../settings/WDGeneralSettings';

// TODO. Refactor.
export class TerceraTicketViewer extends Control {
    public ticketData: any = null;
    public queue: any[] = [];
    public ticketLifetime: number = 5000; // 5 seconds showing dealticket
    public needInit: boolean = true;
    public lefterTicketData: TicketData;

    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraTicketViewer; }

    public override oncomplete (): void {
        super.oncomplete();
        this.on('OnClick', this.openTicket);
        this.on('OnClickIdea', this.openLefterTicket); // additional lefter dealticket
    }

    public tryRegisterUpdates (): void {
        if (!this.needInit) {
            return;
        }

        this.needInit = false;
        DataCache.OnReportMessage.Subscribe(this.onReportMessage, this);
        DataCache.OnMessageForTicketViewer.Subscribe(this.ProcessMessageForTicketViewer, this);
    }

    // TODO. Refactor. UGLY.
    public onReportMessage (reportMsg: DirectReportMessage): void {
    // if (!SessionSettings.view.showDealTickets &&
    //     !SessionSettings.sound.enableSound)
    //     return
        if (!DataCache.Loaded) {
            return;
        }

        if (!WDSettings.showDialTicketsInApplicationTray) {
            return;
        }

        if (reportMsg.SkipOnTicketViewer) {
            return;
        }

        const reportTooltip = new ReportMessageTooltip(reportMsg);
        const reportEvent = EventCache.FromReportMessage(reportMsg);

        const ticket: TicketData = TerceraTicketScreen.TicketFromEvent(reportEvent);

        ticket.header = reportTooltip.GetHeader();
        ticket.text = reportTooltip.GetText();
        ticket.soundKey = reportTooltip.soundKey;
        ticket.tooltipDrawType = reportTooltip.TooltipDrawType;
        ticket.dealTicketTooltip = !isValidString(reportTooltip.dealTicketTooltip) ? reportTooltip.dealTicketTooltip : '';
        ticket.showNextTime = WDSettings.openDialTicketsOnWorkspace;

        ReportMessageTooltip.fixEntries(
            ticket.sortedEntries,
            SessionSettings,
            reportMsg.Instrument, reportMsg.account, reportMsg.productType);

        ReportMessageTooltip.localizeEntries(ticket.sortedEntries);

        const needShowMarginDealTicketOnWorkspace = ticket.eventSource === EventSource.MarginCall &&
            WDSettings.showMarginDealTicketOnWorkspace;

        if (needShowMarginDealTicketOnWorkspace || (ticket.isTrade && ticket.showNextTime)) {
            TerceraTicketScreen.show(ticket);
        } else {
            this.showTicket(ticket);
        }
    }

    public ProcessMessageForTicketViewer (eventData): void {
        const ticket = new TicketData();
        ticket.header = '';
        ticket.isTrade = true;
        ticket.header = Resources.getResource('reports.FUNCTION_PRODUCTS_FAILURE');
        ticket.tooltipDrawType = TooltipType.AccountOperation;
        ticket.text = Resources.getResource('BusinessRejectMessage.' + eventData.ErrorCode);
        const product = DataCache.EntitlementManager.ProductsCache[eventData.ReqProductId];
        let productName = '';
        if (!isNullOrUndefined(product)) {
            productName = product.Name;
        }
        ticket.text = ticket.text.replace('{0}', productName);
        ticket.sortedEntries = [{
            caption: 'Error',
            text: ticket.text
        }];
        ticket.dealTicketTooltip = ticket.text;
        ticket.showNextTime = WDSettings.openDialTicketsOnWorkspace;

        const ev = new Event({
            DateTime: DateTimeUtils.DateTimeUtcNow(),
            Action: ticket.header,
            Description: ticket.text,
            Source: ticket.eventSource,
            EventType: EventType.Trading
        });
        // GOVNO
        if (!isNullOrUndefined(ev.DescriptionItems[0])) {
            ev.DescriptionItems[0].Value = ticket.text;
        }
        EventCache.Add(ev);

        if (!WDSettings.showDialTicketsInApplicationTray) {
            return;
        }

        const needShowMarginDealTicketOnWorkspace = ticket.eventSource === EventSource.MarginCall &&
            WDSettings.showMarginDealTicketOnWorkspace;

        if (needShowMarginDealTicketOnWorkspace || (ticket.isTrade && ticket.showNextTime)) {
            TerceraTicketScreen.show(ticket);
        } else {
            this.showTicket(ticket);
        }
    }

    public showTicketWork (): void {
        if (this.queue.length > 0) {
            this.prepareTicket(this.queue.shift());
            setTimeout(this.showTicketWork.bind(this), this.ticketLifetime);
        } else {
            const lastIdeaTicketSetTime = this.get('lastIdeaTicketSetTime');
            const leftTime = lastIdeaTicketSetTime ? this.ticketLifetime - Date.now() + lastIdeaTicketSetTime : 0;

            if (leftTime > 0) {
                setTimeout(this.hideAllTickets.bind(this), leftTime);
            } // время жизни основного тикета закончилось но еще висит тикет идеи
            else {
                this.hideAllTickets();
            }
        }
    }

    public ClearQueue (): void {
        this.queue.length = 0;
        this.hideAllTickets();
    }

    public prepareTicket (ticketData: TicketData): void {
        const needToAddToTheLeft = this.needAddToTheLeftTicket(ticketData);

        this.hideCorrectTicket(needToAddToTheLeft);

        this.setStyleClasses(ticketData);

        needToAddToTheLeft ? this.setDataToLeftTicket(ticketData) : this.setDataToTicket(ticketData);
    }

    public showTicket (ticketData: TicketData) {
        if (isNullOrUndefined(ticketData)) return;

        const visible: boolean = this.get('visible');
        if (!visible) {
            this.prepareTicket(ticketData);
            setTimeout(this.showTicketWork.bind(this), this.ticketLifetime);
        } else {
            if (ticketData.tooltipDrawType === TooltipType.Idea) {
                this.prepareTicket(ticketData);
            } else {
                this.queue.push(ticketData);
            }
        }

        this.tryPlaySound(ticketData);
    }

    public openTicket (): void {
        TerceraTicketScreen.show(this.ticketData);
    }

    public openLefterTicket (): void // событие по нажатию на msg левее основного
    {
    // return // открытие дилтикета с таким же содержимым как в тултипе вероятно и не нужно на десктопе нет
        TerceraTicketScreen.show(this.lefterTicketData);
    }

    public setStyleClasses (ticketData: TicketData): void {
        const isIdeaDealTicket = TerceraTicketViewer.isIdeaDealTicket(ticketData);
        const isLefterTicket = this.needAddToTheLeftTicket(ticketData);

        if (isIdeaDealTicket) {
            void this.set(isLefterTicket ? 'headerClassIdea' : 'headerClass', 'js-idea-header');
            void this.set(isLefterTicket ? 'imageClassIdea' : 'imageClass', 'js-idea-image');
        } else {
            switch (ticketData.tooltipDrawType) {
            case TooltipType.CAInfo:
            case TooltipType.CAAccepted:
            case TooltipType.CARejected:
            case TooltipType.CACanceled:
                void this.set({
                    headerClass: TooltipTypeName[ticketData.tooltipDrawType], // better to use TooltipType instead of TooltipTypeName
                    imageClass: TooltipTypeName[ticketData.tooltipDrawType]
                });
                break;
            case TooltipType.Trade:
                void this.set({
                    headerClass: 'js-TicketViewer-header',
                    imageClass: 'js-TicketViewer-image'
                });
                break;
            case TooltipType.AccountOperation:
                void this.set({
                    headerClass: 'js-account-operation-header',
                    imageClass: 'js-account-operation-image'
                });
                break;
            default:
                void this.set({
                    headerClass: 'js-refuse-header',
                    imageClass: 'js-refuse-image'
                });
                break;
            }
        }
    }

    public setDataToTicket (ticketData: TicketData): void {
        void this.set({
            header: ticketData.header,
            text: ticketData.text,
            dealTicketTooltip: isValidString(ticketData.dealTicketTooltip) ? ticketData.dealTicketTooltip : '',
            visible: true
        });

        this.ticketData = ticketData;
    }

    public setDataToLeftTicket (ticketData: TicketData): void {
        void this.set({
            headerIdea: ticketData.header,
            textIdea: ticketData.text,
            dealTicketTooltipIdea: isValidString(ticketData.dealTicketTooltip) ? ticketData.dealTicketTooltip : '',
            visibleIdea: true,
            lastIdeaTicketSetTime: Date.now()
        });

        this.lefterTicketData = ticketData;
    }

    public hideAllTickets (): void {
        void this.set({ visible: false, visibleIdea: false });
    }

    public hideCorrectTicket (needToAddToTheLeft: boolean): void {
        const lastIdeaTicketSetTime = this.get('lastIdeaTicketSetTime');
        if (lastIdeaTicketSetTime && Date.now() - lastIdeaTicketSetTime > this.ticketLifetime) // левый дилтикет не становится в общую очередь, а имеет свой таймер
        {
            void this.set({
                visibleIdea: false,
                lastIdeaTicketSetTime: null
            });
        }

        void this.set(needToAddToTheLeft ? 'visibleIdea' : 'visible', false); // чтобы новый дилтикет выезжал заново а не подменял старый
    }

    // TODO. IMPORTANT. Move to another place.
    public tryPlaySound (ticketData: TicketData): void {
        const sndKeys = SoundManager.SoundKeys;
        let key = ticketData.soundKey;
        if (!isValidString(key)) {
            SoundManager.tryPlaySound(sndKeys.OrderRejected);
            return;
        }

        key = key.toLowerCase();
        if (key.includes('refused')) {
            SoundManager.tryPlaySound(sndKeys.OrderRejected);
        } else if (key.includes('order created')) {
            SoundManager.tryPlaySound(sndKeys.OrderCreated);
        } else if (key.includes('order canceled')) {
            SoundManager.tryPlaySound(sndKeys.OrderCanceled);
        } else if (key.includes('order replaced')) {
            SoundManager.tryPlaySound(sndKeys.OrderReplaced);
        } else if (key.includes('order rejected')) {
            SoundManager.tryPlaySound(sndKeys.OrderRejected);
        } else if (key.includes('modified')) {
            SoundManager.tryPlaySound(sndKeys.Modify);
        } else if (key.includes('margin warning')) {
            SoundManager.tryPlaySound(sndKeys.MarginWarning);
        } else if (key.includes('order') && key.includes('filled')) {
            SoundManager.tryPlaySound(sndKeys.OrderFilled);
        } else if (key.includes('market') && key.includes('order') && key.includes('filled')) {
            SoundManager.tryPlaySound(sndKeys.PostionClose);
        }
    }

    public createHtml (header: string, text: string, isTrade: boolean): any {
        const str = TerceraToastTemplate;

        return str.replace('{{.header}}', header)
            .replace('{{.text}}', text)
            .replace('{{.imageClass}}', isTrade ? '' : 'js-refuse')
            .replace('{{.headerClass}}', isTrade ? '' : 'js-refuse');
    }

    public needAddToTheLeftTicket (ticketData: TicketData): boolean // если основное место занято и там не дилтикет идей добавляем новый левее
    {
        return TerceraTicketViewer.isIdeaDealTicket(ticketData) &&
                this.get<boolean>('visible') &&
                !isNullOrUndefined(this.ticketData) &&
                this.ticketData.tooltipDrawType !== TooltipType.Idea;
    }

    public static isIdeaDealTicket (ticketData: TicketData): boolean {
        return !isNullOrUndefined(ticketData) && ticketData.tooltipDrawType === TooltipType.Idea;
    }
}

Control.extendWith(TerceraTicketViewer, {
    template: TicketViewerTemplate,
    data: function () {
        return {
            image: '',
            header: '',
            text: '',
            headerClass: 'js-TicketViewer-header',
            imageClass: 'js-TicketViewer-image',
            dealTicketTooltip: '',

            headerIdea: '', // все что с приставкой Idea относится к дополнительным дилтикетам показываемым левее основных если они есть; пока используем только для дилтикетов торговых идей
            textIdea: '',
            visibleIdea: false,
            imageClassIdea: '',
            headerClassIdea: '',
            dealTicketTooltipIdea: '',
            lastIdeaTicketSetTime: null,
            visible: false
        };
    }
});
