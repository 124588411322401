// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { Point, Rectangle } from '../../../Commons/Geometry';
import { TerceraChartCashItemSeriesDataType } from '../../Series/TerceraChartCashItemSeriesEnums';
import { Brushes } from '../../../Commons/Graphics';
import { SelectionState, ToolSelectionType } from '../Selection';
import { TerceraChartMainPriceRenderer } from '../../Renderers/TerceraChartMainPriceRenderer';
import { TradingToolViewBase, TradingToolViewType } from './TradingToolViewBase';

import { ThemeManager } from '../../../Controls/misc/ThemeManager';
import { OperationType } from '../../../Utils/Trading/OperationType';
import { CashItem } from '../../../Commons/cache/History/CashItem';

export class NewTradeToolView extends TradingToolViewBase {
    public newTrade: any;
    public BuyNewTradeToolsDrawingInfo: Record<number, NewTradeToolView>;
    public SellNewTradeToolsDrawingInfo: Record<number, NewTradeToolView>;

    public isActive = false; // видим на чарте
    public triangleX: number;
    public triangleY: number;

    public tradeArrowX: number;
    public tradeArrowY: number;
    public IndexOfBar = -1;
    public okByVisible = false;

    constructor (newTrade, BuyNewTradeToolsDrawingInfo, SellNewTradeToolsDrawingInfo, renderer) {
        super();

        this.newTrade = newTrade;
        this.BuyNewTradeToolsDrawingInfo = BuyNewTradeToolsDrawingInfo;
        this.SellNewTradeToolsDrawingInfo = SellNewTradeToolsDrawingInfo;
        this.myRenderer = renderer;

        this.dataCacheTool.Points[0][0] = newTrade.Time;
        this.dataCacheTool.Points[0][1] = newTrade.Price;

        this.collapsed = true;
        this.isTradingTool = false;
        this.ToolViewType = TradingToolViewType.NewTradeToolView;
    }

    public override Updated (): void { }
    public override CancelMoving (): void { }

    get Collapsed (): boolean { return this.collapsed; }
    set Collapsed (value) { this.collapsed = value; }

    override get ID (): string {
        return this.newTrade != null ? this.newTrade.TradeId : '-2';
    }

    override get Price (): number { return this.newTrade.Price; }

    public override DescriptionText (): string {
        return this.newTrade.Amount + '@' + this.newTrade.Price; ;
    }

    public override Dispose (): void {
        this.newTrade = null;
        this.BuyNewTradeToolsDrawingInfo = null;
        this.SellNewTradeToolsDrawingInfo = null;

        super.Dispose();
    }
    // #endregion

    /// <summary>
    /// Рисование tradetool
    /// </summary>
    public override Draw (gr, window, param): void {
        // Игнорим тулзы, которые лежат за пределами истории - иначе будут стремные линии трейдов уходящие в ноль
        if (!this.myRenderer.ShowEvents || !this.okByVisible /* || !window.ClientRectangle.Contains(tradeArrowX, tradeArrowY) */) {
            this.isActive = false;
            return;
        }
        this.isActive = true; // visible by chart

        const isBuy = this.newTrade.BuySell === OperationType.Buy;
        const curHash = isBuy ? this.myRenderer.BuyNewTradeToolsDrawingInfo : this.myRenderer.SellNewTradeToolsDrawingInfo;
        let prevTradesOnThisBar = curHash[this.IndexOfBar];
        if (prevTradesOnThisBar) {
            if (!(prevTradesOnThisBar.indexOf(this) > -1)) {
                prevTradesOnThisBar.push(this);
            }
        } else {
            prevTradesOnThisBar = [];
            prevTradesOnThisBar.push(this);
        }

        curHash[this.IndexOfBar] = prevTradesOnThisBar;

        const selected = this.CurrentSelection.CurrentState !== SelectionState.None;
        const im = (isBuy) ? (selected ? TradingToolViewBase.buyTradeImageActive : TradingToolViewBase.buyTradeImage) : (selected ? TradingToolViewBase.sellTradeImageActive : TradingToolViewBase.sellTradeImage);
        if (prevTradesOnThisBar[0] == this && im) // draw only firts time
        {
            gr.drawImage(im, this.tradeArrowX, this.tradeArrowY);
        }
        //
        // Show trade info
        //
        if (!prevTradesOnThisBar[0].Collapsed && !this.Collapsed) {
            const deltaY = (isBuy ? 18 : -18) * prevTradesOnThisBar.length;
            const descr = this.DescriptionText();
            const infoRect = new Rectangle(this.tradeArrowX, this.tradeArrowY + deltaY, gr.GetTextWidth(descr, TradingToolViewBase.tradeFont) + 10, 15);
            infoRect.X -= (infoRect.Width - 14) / 2;
            gr.RenderButton(
                isBuy ? TradingToolViewBase.buyBackgroundBrush : TradingToolViewBase.sellBackgroundBrush,
                isBuy ? TradingToolViewBase.buyBackgroundPen : TradingToolViewBase.sellBackgroundPen,
                infoRect);
            gr.DrawString(descr, TradingToolViewBase.tradeFont, Brushes.White, infoRect.X + infoRect.Width / 2, infoRect.Y + infoRect.Height / 2, 'center', 'middle');
        }

        // +++ выделение активного трейда
        if ((param.TerceraChart.TerceraChartTradingToolsRenderer.ActiveSelectionTrade) && this.newTrade.TradeId == param.TerceraChart.TerceraChartTradingToolsRenderer.ActiveSelectionTrade) {
            const rrr = new Rectangle(this.tradeArrowX - 13, Math.floor(this.tradeArrowY) - 13, 38, 38);
            // gr.DrawEllipse(App.ThemeManager.CurrentTheme.LinkColor, rrr);
            gr.DrawEllipse(ThemeManager.CurrentTheme.LinkColorPen, rrr.X, rrr.Y, rrr.Width, rrr.Height);
        }

        //
        // Draw price triangle
        //
        const trPts = new Array(3);// new Point[3];
        trPts[0] = new Point(this.triangleX, this.triangleY);
        trPts[1] = new Point(this.triangleX - 8, this.triangleY - 5);
        trPts[2] = new Point(this.triangleX - 8, this.triangleY + 5);
        gr.FillPolygon(isBuy ? Brushes.Green : Brushes.Red, trPts);
    }

    public override UpdateScreenPoints (window, cashItemSeries = null): void {
        // если вне экрана ничего не считаем и такое не рисуем
        if (cashItemSeries.ChartScreenData.Storage.length < 1) {
            return;
        }

        if ((this.dataCacheTool.Points[0][0] < cashItemSeries.ChartScreenData.Storage[0].Time) || (this.dataCacheTool.Points[0][0] > cashItemSeries.ChartScreenData.Storage[cashItemSeries.ChartScreenData.Storage.length - 1].Time)) {
            this.IndexOfBar = -1;
            this.okByVisible = this.isActive = false;
            return;
        }

        this.IndexOfBar = Math.floor(cashItemSeries.FindIntervalExactly(this.dataCacheTool.Points[0][0]));
        if (this.IndexOfBar !== -1) {
            // ---  #38464 лишнее со старого чарта перехало
            // if (IndexOfBar > 0)
            //    IndexOfBar--;

            let arrowpos = Number.MAX_VALUE;
            const isBuy = this.newTrade.BuySell === OperationType.Buy;

            if (isBuy) {
                arrowpos = cashItemSeries.GetValue(this.IndexOfBar, CashItem.LOW_INDEX);
            } else {
                arrowpos = cashItemSeries.GetValue(this.IndexOfBar, CashItem.HIGH_INDEX);
            }

            let xOffset = 0; // #42720
            if (window.XScale > 25) {
                xOffset = 6;
            } else if (window.XScale > 12) {
                xOffset = 4;
            } else if (window.XScale > 8) {
                xOffset = 2;
            } else if (window.XScale > 4) {
                xOffset = 1;
            }

            // update tool points
            this.triangleX = Math.floor(window.PointsConverter.GetScreenXbyTime(cashItemSeries.GetValue(this.IndexOfBar, CashItem.TIME_INDEX))) + xOffset; // #42720
            this.triangleY = Math.floor(window.PointsConverter.GetScreenY(this.newTrade.Price));

            const procData = TerceraChartMainPriceRenderer.ProcessBarWidth(window);
            const leftBorder = procData.leftBorder;
            const barW = procData.barW;
            this.tradeArrowX = this.triangleX + (barW) / 2 + leftBorder;
            this.tradeArrowY = Math.floor(window.PointsConverter.GetScreenY(arrowpos));

            if (isBuy) {
                this.tradeArrowY += 10;
            } else {
                this.tradeArrowY -= 10;
            }

            this.tradeArrowX -= 7;
            this.tradeArrowY -= 7;
            this.okByVisible = true;
        }
    }

    // #region mouse
    public override IsSelectCheck (x: number, y: number): boolean {
        // first check group rect
        return this.isActive && new Rectangle(this.tradeArrowX, this.tradeArrowY, 14, 14).Contains(x, y);
    }

    public override GetCurrentSelection (x: number, y: number): ToolSelectionType {
        return ToolSelectionType.None;
    }

    public override CheckMinDistanceForStartMoving (x: number, y: number): boolean {
        return false;
    }

    public override ResetMouse (): void {

    }

    // mouse click
    public override ProcessClick (): void {
        this.Collapsed = !this.Collapsed;
        try {
            const isBuy = this.newTrade.BuySell == OperationType.Buy;
            const curHash = isBuy ? this.myRenderer.BuyNewTradeToolsDrawingInfo : this.myRenderer.SellNewTradeToolsDrawingInfo;// Dictionary<int, List<NewTradeToolView>>

            const keys = Object.keys(curHash);
            if (keys.length === 0 || this.IndexOfBar === -1) {
                return;
            }

            let i;
            const len = curHash[this.IndexOfBar].length;
            for (i = 0; i < len; i++) {
                curHash[this.IndexOfBar][i].Collapsed = this.Collapsed;
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }
    // #endregion
}
