// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TimeSpan, TimeSpanFormat } from '@shared/utils/Time/TimeSpan';
import { Resources } from '@shared/localizations/Resources';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { DynProperty } from '@shared/commons/DynProperty';
import { DateTimeConvertor } from '@shared/utils/Time/DateTimeConvertor';
import { type SessionSettingsType } from '@shared/commons/SessionSettings';
import { type Event } from '@shared/commons/cache/Event/Event';
import { EventUtils } from '@shared/commons/UtilsClasses/EventUtils';

export class EventItem extends BaseItem {
    public ev: Event;
    public dataCache: any;
    public foreColorByPanelColoring: any;
    public backColorByPanelColoring: any;

    constructor (ev: Event, settings: SessionSettingsType, dataCache, foreColor, backColor) {
        super(settings);

        // TODO. Ugly. For table init.
        if (!ev) return;

        this.ev = ev;

        this.itemId = ev.Id;

        this.dataCache = dataCache;
        // this.sessionSettings = settings;

        this.foreColorByPanelColoring = foreColor;
        this.backColorByPanelColoring = backColor;

        this.CustomColoring = true;

        this.QuickTableEditingInfoMap[3] = (function () {
            return new QuickTableEditingInfo(DynProperty.INFO_PICTURE);
        })();

        // TODO normalize
        this.CellForeColorMap[0] = this.foreColorByPanelColoring;
        this.CellForeColorMap[1] = this.foreColorByPanelColoring;
        this.CellForeColorMap[2] = this.foreColorByPanelColoring;
        this.CellForeColorMap[3] = this.foreColorByPanelColoring;
        this.CellForeColorMap[5] = this.foreColorByPanelColoring;
    }

    public override GetColumnParams (columnIdx: number): ColumnParams {
        const par = EventItem.columnsParams[columnIdx];
        if (Resources.isHidden(par.HeaderKey)) { par.Hidden = true; }
        return par;
    }

    public override ColumnCount (): number {
        return EventItem.columnsParams.length;
    }

    public override getColumnData (columnIdx: number): ColumnData {
        /* TODO.
        if (column >= columnsParams.Length && customColumnFormattedValues.ContainsKey(column))
        {
            o = customColumnValues[column];
            return customColumnFormattedValues[column];
        }
        */

        const value = this.getColumnValue(columnIdx);
        let formattedValue = null;
        const ev = this.ev;

        switch (columnIdx) {
        case 1:
            formattedValue = DateTimeUtils.formatDate(value, 'DD.MM.YYYY  HH:mm:ss.SSS');
            break;

        case 4:
            formattedValue = ev.Roundtrip > 0
                ? TimeSpan.ToTimeSpanString(ev.Roundtrip, TimeSpanFormat.MilisecondSecondMinute)
                : '';
            break;

        default:
            formattedValue = value.toString();
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (columnIdx: number): any {
        /* TODO.
        if (column >= columnsParams.Length && customColumnValues.ContainsKey(column))
            return customColumnValues[column];
        */
        const ev = this.ev;

        switch (columnIdx) {
        case 0:
            return EventUtils.getEventTypeStr(ev.EventType);

        case 1:
            return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(ev.DateTime);

        case 2:{
            const reportLocKey = 'reports.' + ev.Action;
            if (Resources.IsResourcePresent(reportLocKey)) {
                return Resources.getResource(reportLocKey);
            } else {
                return Resources.getResource(ev.Action);
            }
            // TODO. Looks like shit.
            // else return Resources.getResource(ev.action.Trim().Replace('\n', ' '), sessionSettings.Locale);
        }

        case 3:
            return ev.GetFormattedDescription();

        case 4:
            return ev.Roundtrip;

        default:
            return '';
        }
    }

    public static readonly columnsParams = [
        new ColumnParams('panel.EventLog.Type', 100, 0, false, true, true),
        new ColumnParams('panel.EventLog.Date', 100, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        new ColumnParams('panel.EventLog.Action', 100, 0, false, true, true),
        new ColumnParams('panel.EventLog.Message', 50, 0, false, true, false)
        // new ColumnParams("panel.EventLog.Roundtrip", 40, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
    ];
}
