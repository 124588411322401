// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DynProperty } from '@shared/commons/DynProperty';
import { Resources } from '@shared/localizations/Resources';
import { PriceFormatter } from '@shared/utils/Instruments/PriceFormatter';
import { MathUtils } from '@shared/utils/MathUtils';
import { type OptionAnalyzerChart } from '../../OptionAnalyzerChart';
import { LayersEnum } from '../../Utils/LayersEnum';
import { type TerceraChartMouseEventArgs } from '../../Utils/TerceraChartMouseEventArgs';
import { type TerceraChartNumberScaleRenderer } from '../Scales/TerceraChartNumberScaleRenderer';
import { type InfoWindowItem } from './InfoWindowItem';
import { TerceraChartBaseInfoWindowRenderer } from './TerceraChartBaseInfoWindowRenderer';

enum AnalyzerInfoWindowItem {
    IntrinsicPnL,
    PnL,
    Delta,
    Gamma,
    Vega,
    Theta,
    Rho,
    WhatIf
}

export class TerceraChartAnalyzerInfoWindowRenderer extends TerceraChartBaseInfoWindowRenderer<OptionAnalyzerChart> {
    private _isMouseOver: boolean = false;
    private _defaultDataX: number = 0;
    private _defaultDataY: number = 0;

    constructor (chart: OptionAnalyzerChart) {
        super(chart);
        this.UseInAutoscale = false;
        this.assignLayer = LayersEnum.CrossHair;
        const items: number[] = [
            AnalyzerInfoWindowItem.IntrinsicPnL,
            AnalyzerInfoWindowItem.PnL,
            AnalyzerInfoWindowItem.Delta,
            AnalyzerInfoWindowItem.Gamma,
            AnalyzerInfoWindowItem.Vega,
            AnalyzerInfoWindowItem.Theta,
            AnalyzerInfoWindowItem.Rho,
            AnalyzerInfoWindowItem.WhatIf
        ];
        this.initializeItems(items);
    }

    public setDefaultData (x: number, y: number): void {
        this._defaultDataX = x;
        this._defaultDataY = y;
        if (!this._isMouseOver) {
            this.updateItems(this._defaultDataX, this._defaultDataY);
        }
    }

    public override ProcessMouseEnter (e: TerceraChartMouseEventArgs): void {
        this._isMouseOver = true;
        super.ProcessMouseEnter(e);
    }

    public override ProcessMouseLeave (e: TerceraChartMouseEventArgs): void {
        this._isMouseOver = false;
        this.updateItems(this._defaultDataX, this._defaultDataY);
        super.ProcessMouseLeave(e);
    }

    protected override updateItem (item: InfoWindowItem, dataX: number, dataY: number): void {
        const data = this.chart.getOptionAnalyzerInfoData(dataX);
        switch (item.id) {
        case AnalyzerInfoWindowItem.IntrinsicPnL:
            item.formattedValues = [this.getFormattedValue(data.intrinsicPnL)];
            break;
        case AnalyzerInfoWindowItem.PnL:
            item.formattedValues = [this.getFormattedValue(data.PnL, 5)];
            break;
        case AnalyzerInfoWindowItem.Delta:
            item.formattedValues = [this.getFormattedValue(data.greeks.delta)];
            break;
        case AnalyzerInfoWindowItem.Gamma:
            item.formattedValues = [this.getFormattedValue(data.greeks.gamma)];
            break;
        case AnalyzerInfoWindowItem.Vega:
            item.formattedValues = [this.getFormattedValue(data.greeks.vega)];
            break;
        case AnalyzerInfoWindowItem.Theta:
            item.formattedValues = [this.getFormattedValue(data.greeks.theta)];
            break;
        case AnalyzerInfoWindowItem.Rho:
            item.formattedValues = [this.getFormattedValue(data.greeks.rho)];
            break;
        case AnalyzerInfoWindowItem.WhatIf:
            item.formattedValues = [];
            if (this.chart.getVolatilityLineVisibility(0)) {
                item.formattedValues.push(this.getFormattedValue(data.whatIf[0]));
            }
            if (this.chart.getVolatilityLineVisibility(1)) {
                item.formattedValues.push(this.getFormattedValue(data.whatIf[1]));
            }
            if (this.chart.getVolatilityLineVisibility(2)) {
                item.formattedValues.push(this.getFormattedValue(data.whatIf[2]));
            }
            if (this.chart.getVolatilityLineVisibility(3)) {
                item.formattedValues.push(this.getFormattedValue(data.whatIf[3]));
            }
            if (this.chart.getVolatilityLineVisibility(4)) {
                item.formattedValues.push(this.getFormattedValue(data.whatIf[4]));
            }
            if (item.formattedValues.length === 0) {
                item.formattedValues = ['---'];
            }
            break;
        }
    }

    protected override getItemValueForegroundColors (item: InfoWindowItem): string[] {
        switch (item.id) {
        case AnalyzerInfoWindowItem.IntrinsicPnL:
            return [this.chart.getIntrinsicLineColor()];
        case AnalyzerInfoWindowItem.PnL:
        case AnalyzerInfoWindowItem.Delta:
        case AnalyzerInfoWindowItem.Gamma:
        case AnalyzerInfoWindowItem.Vega:
        case AnalyzerInfoWindowItem.Theta:
        case AnalyzerInfoWindowItem.Rho:
            return [this.chart.getTimeLineColor()];
        case AnalyzerInfoWindowItem.WhatIf:
        {
            const colors: string[] = [];
            if (this.chart.getVolatilityLineVisibility(0)) {
                colors.push(this.chart.getVolatilityLineColor(0));
            }
            if (this.chart.getVolatilityLineVisibility(1)) {
                colors.push(this.chart.getVolatilityLineColor(1));
            }
            if (this.chart.getVolatilityLineVisibility(2)) {
                colors.push(this.chart.getVolatilityLineColor(2));
            }
            if (this.chart.getVolatilityLineVisibility(3)) {
                colors.push(this.chart.getVolatilityLineColor(3));
            }
            if (this.chart.getVolatilityLineVisibility(4)) {
                colors.push(this.chart.getVolatilityLineColor(4));
            }
            return colors;
        }
        }
    }

    protected override localizeItem (item: InfoWindowItem): void {
        const key = this.getLocalizationKey(item.id);
        if (isValidString(key)) {
            item.label = Resources.getResource(key);
        }
    }

    protected override getDynPropertyGroup (): string {
        return DynProperty.ANALYZER_INFO_WINDOW_GROUP;
    }

    private getFormattedValue (value: number, maxPrecision: number = undefined): string {
        const xScaleRenderer = this.chart.windowsContainer.xScaleRenderer as TerceraChartNumberScaleRenderer;
        let precision = xScaleRenderer.Precision;
        if (isValidNumber(maxPrecision)) {
            const valuePrecision = Math.min(MathUtils.getPrecision(value), maxPrecision);
            if (valuePrecision > precision) {
                precision = valuePrecision;
            }
        }
        return PriceFormatter.formatPrice(value, precision);
    }

    private getLocalizationKey (id: AnalyzerInfoWindowItem): string {
        switch (id) {
        case AnalyzerInfoWindowItem.IntrinsicPnL:
            return 'panel.optionAnalyzer.infoWindow.intrinsicPL';
        case AnalyzerInfoWindowItem.PnL:
            return 'panel.optionAnalyzer.infoWindow.PL';
        case AnalyzerInfoWindowItem.Delta:
            return 'panel.optionAnalyzer.infoWindow.delta';
        case AnalyzerInfoWindowItem.Gamma:
            return 'panel.optionAnalyzer.infoWindow.gamma';
        case AnalyzerInfoWindowItem.Vega:
            return 'panel.optionAnalyzer.infoWindow.vega';
        case AnalyzerInfoWindowItem.Theta:
            return 'panel.optionAnalyzer.infoWindow.theta';
        case AnalyzerInfoWindowItem.Rho:
            return 'panel.optionAnalyzer.infoWindow.rho';
        case AnalyzerInfoWindowItem.WhatIf:
            return 'panel.optionAnalyzer.infoWindow.whatif';
        }
    }
}
