// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { EventEmitter } from 'events';
import { type VerticalDetailsTypePointer } from './VerticalDetailsTypePointer';
import { type IGroupController } from '../../VerticalGroupedPanels/Interfaces/IGroupController';
import { RowItemDataColorPair, type RowItemDataNumberPair, type RowItemDataStringPair } from './AccountDetails/RowItemData';

export class VerticalDetailsRowItem<GroupItemsType, GroupsType> {
    private readonly _eventEmitter = new EventEmitter();
    private readonly typePointer: VerticalDetailsTypePointer<GroupItemsType, GroupsType>;
    private readonly controller: IGroupController<GroupItemsType>;

    constructor (typePointer: VerticalDetailsTypePointer<GroupItemsType, GroupsType>, controller: IGroupController<GroupItemsType>) {
        this.controller = controller;
        this.typePointer = typePointer;
    }

    public subscribeOnUpDateEmit (callback: () => void): void {
        this._eventEmitter.on('update', callback);
    }

    public unsubscribeOnUpDateEmit (callback: () => void): void {
        this._eventEmitter.off('update', callback);
    }

    public onUpDateEmit (): void {
        this._eventEmitter.emit('update');
    }

    public getItemColor (): RowItemDataColorPair {
        const itemType = this.typePointer.ItemType;
        const value: RowItemDataNumberPair = this.controller.getItemValue(itemType);
        const strValue: RowItemDataStringPair = this.controller.getItemFormattedValue(itemType);

        if (strValue.isNAString()) {
            return new RowItemDataColorPair(null, this.controller.getColoringMethod(itemType));
        }
        return new RowItemDataColorPair(value, this.controller.getColoringMethod(itemType));
    }

    public getItemName (): string {
        const itemType = this.typePointer.ItemType;
        return this.controller.getItemName(itemType);
    }

    public getItemValue (): RowItemDataStringPair {
        const itemType = this.typePointer.ItemType;
        return this.controller.getItemFormattedValue(itemType);
    }

    public getItemTooltip (): string {
        const itemType = this.typePointer.ItemType;
        return this.controller.getItemTooltip(itemType);
    }

    public getTypePointer (): VerticalDetailsTypePointer<GroupItemsType, GroupsType> {
        return this.typePointer;
    }
}
