// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../Commons/DataCache';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { WL_ListController } from './WL_ListController';
import { Instrument } from '../../Commons/cache/Instrument';
import { InvestingLookupNode } from './InvestingLookupNode';

function CreateResult (instruments, exchanges): any {
    const CoreResult = function () { };
    CoreResult.prototype.getInstruments = function () { return instruments; };
    CoreResult.prototype.getExchanges = function () { return exchanges; };
    return new CoreResult();
}

function CreateInstrumentRow (instrument): InvestingLookupNode {
    const curItemText = instrument.DisplayName();

    const node = new InvestingLookupNode(curItemText);

    const curItemInstrType_Value = instrument.CFD ? InstrumentTypes.EQUITIES_CFD : instrument.InstrType;
    node.Symbol = curItemText;
    node.Description = instrument.DescriptionValue();
    node.CountryFlag = DataCache.CountryCache.GetFlagImage(instrument.CountryId);
    if (node.CountryFlag) {
        node.CountryFlag_src = node.CountryFlag.src;
    }

    node.Trading_exchange = instrument.TradingExchange;
    node.Instrument_type = curItemInstrType_Value;
    node.Instrument_type_text = Instrument.getTypeString(curItemInstrType_Value);
    node.Img_Url = instrument.LogoAddress;
    node.Instrument = instrument;
    node.IsPresent_in_WL = WL_ListController.IsPresent(instrument.GetInteriorID());
    return node;
}

function FilterByStr (filterString, instrumentItem): boolean {
    if (!filterString) {
        return true;
    }

    let curItemText = instrumentItem.DisplayName();
    const descr = instrumentItem.DescriptionValue();
    if (descr) {
        curItemText = `${curItemText}      ${descr}`;
    }

    const curItemFindStr = curItemText.toLowerCase();
    if (curItemFindStr.indexOf(filterString) === -1) {
        return false;
    }

    return true;
}

function CollectExchanges (instrumentItem, storrage): void {
    const curItemExchange_Value = instrumentItem.TradingExchange;

    if (!storrage.hasOwnProperty(curItemExchange_Value)) {
        storrage[curItemExchange_Value] = true;
    }
}

class _LookupCore {
    public GetItems (instrumentsList, filter = ''): any {
        const istrumentsResult = [];
        const ExchangeStrorage = {};
        let Myfilter = '';

        if (filter) { Myfilter = filter.toLowerCase(); }

        for (let i = 0; i < instrumentsList.length; i++) {
            const instrumentItem = instrumentsList[i];
            if (instrumentItem.NeedToHide()) {
                continue;
            }

            CollectExchanges(instrumentItem, ExchangeStrorage);

            if (!FilterByStr(Myfilter, instrumentItem)) {
                continue;
            }

            const instrumentRow = CreateInstrumentRow(instrumentItem);
            istrumentsResult.push(instrumentRow);
        }
        return CreateResult(istrumentsResult, ExchangeStrorage);
    }

    public PrepareInstruments (): any[] {
        const newItems = DataCache.Instruments;
        if (!newItems) {
            return;
        }

        const items = [];
        for (const ins_id in newItems) {
            const curItem = newItems[ins_id];
            if (curItem.NeedToHide()) {
                continue;
            }
            items.push(curItem);
        }

        return items;
    }
}

export const InvestingInstrumentLookupCore = new _LookupCore();

// // _LookupCore.prototype.fillTree = function (props)
// // {
// //     let items = props.items || [],
// //         selectedItem = props.selectedItem || null,
// //         selectedInstrumentType = props.selectedInstrumentType || null,
// //         curTypesFilter = props.curTypesFilter || null,
// //         curExchangeFilter = props.curExchangeFilter || null,
// //         currentNameFilter = props.currentNameFilter || null,
// //         isInstrumentsDefaultsMode = props.isInstrumentsDefaultsMode || null,
// //         existingInstrumentTypes = props.existingInstrumentTypes || null,
// //         // quickTree = props.quickTree,
// //         out_allExchanges = props.out_allExchanges || null,
// //         out_allTypes = props.out_allTypes || null,
// //         dataProvider = props.dataProvider

// //     if (!items)
// //         return

// //     let selectedItemsMap = new Object()
// //     let selectedItemsIsArray = selectedItem instanceof Array
// //     if (selectedItemsIsArray)
// //     {
// //         for (let i = 0; i < selectedItem.length; i++)
// //             selectedItemsMap[selectedItem[i].GetInteriorID()] = selectedItem[i]
// //     }

// //     let selectedNode = null
// //     let nodeRangeTree = {}

// //     // exchange и его ид (для комбобокса)
// //     // [name] = exchangeId
// //     let allExchanges = out_allExchanges || {}
// //     // тип и количество нод в нём  и его ид  (для комбобокса)
// //     // [name] = count; [name].filterId = curItem.InstrType
// //     let allTypes = out_allTypes || {}

// //     let hasFilter = currentNameFilter !== null

// //     let filterString = ''
// //     if (hasFilter)
// //         filterString = currentNameFilter.toLowerCase()

// //     let insTypeNodeArr = []

// //     var allExchangeIdText = Resources.getResource('ExchangeId.All')
// //     if (isInstrumentsDefaultsMode)
// //     {
// //         var allExchangesNode = new InvestingLookupNode(allExchangeIdText)
// //         allExchangesNode.collapsed = false
// //         var allExchangesNodeTreeObj = { node: allExchangesNode }

// //         nodeRangeTree[allExchangeIdText] = allExchangesNodeTreeObj

// //         for (var key in existingInstrumentTypes)
// //         {
// //             var intKey = parseInt(key)

// //             var insTypeText = Instrument.getTypeString(intKey)
// //             var insTypeNode = new InvestingLookupNode(insTypeText)
// //             var insTypeImg = InstrumentLookupManager.getInstrumentTypeImage(intKey)
// //             insTypeNode.tag = InsDefSettings.INSTRUMENT_TYPE + intKey
// //             insTypeNode.InstrumentTypeImg = insTypeImg

// //             allExchangesNodeTreeObj[insTypeText] = {
// //                 node: insTypeNode, groups: {}
// //             }
// //             allExchangesNode.AddChildNode(insTypeNode)

// //             if (key === selectedInstrumentType)
// //                 selectedNode = insTypeNode

// //             insTypeNodeArr.push(insTypeNode)
// //         }
// //     }

// //     var len = items.length
// //     for (var i = 0; i < len; i++)
// //     {
// //         var curItem = items[i]

// //         if (curItem.NeedToHide())
// //             continue

// //         //instrGroup
// //         var instrGroup = curItem.getInstrumentTypeName()
// //         //ExchangeName   TradingExchange
// //         var curItemExchange_Value = isInstrumentsDefaultsMode ? allExchangeIdText : curItem.TradingExchange
// //         //InstrType
// //         var curItemInstrType_Value = curItem.CFD ? Instrument.CFD_INDEX : curItem.InstrType

// //         //localize //TODO ?
// //         let curItemExchangeName = curItemExchange_Value

// //         var curItemInstrTypeText = curItem.getTypeString()

// //         //#region filter
// //         if (!allExchanges.hasOwnProperty(curItemExchange_Value))
// //             allExchanges[curItemExchange_Value] = true

// //         if (!allTypes.hasOwnProperty(curItemInstrType_Value))
// //         {
// //             if (!(Instrument.FUTURES === curItemInstrType_Value && InstrumentUtils.UseFuturesInsteadSpot()))
// //                 allTypes[curItemInstrType_Value] = { totalCount: 1, visibleCount: 0 }
// //         }
// //         else
// //             allTypes[curItemInstrType_Value].totalCount++

// //         if (curExchangeFilter && !curExchangeFilter[curItemExchange_Value])
// //             continue

// //         if (curTypesFilter && !curTypesFilter[curItemInstrType_Value])
// //             continue

// //         let curItemText = curItem.DisplayName()
// //         let descr = curItem.DescriptionValue()
// //         if (descr)
// //             curItemText += (" (" + descr + ")")
// //         if (hasFilter && (!DataCache.NonFixedList || isInstrumentsDefaultsMode))
// //         {
// //             var curItemFindStr = curItemText.toLowerCase()
// //             if (curItemFindStr.indexOf(filterString) === -1)
// //                 continue
// //         }

// //         //todo blizzard sc2
// //         let full_contains = curItem.DisplayShortName().toLocaleLowerCase() === filterString

// //         if (allTypes[curItemInstrType_Value])
// //             allTypes[curItemInstrType_Value].visibleCount++

// //         //нода в которую закинем инструмент
// //         var currentWorkNode = null
// //         //проверяем ExchangeName ,если нет добавляем
// //         InstrumentLookupManager.checkCreateAddNode(nodeRangeTree, curItemExchangeName, true)

// //         //сейчас это ExchangeName нода
// //         currentWorkNode = nodeRangeTree[curItemExchangeName]
// //         currentWorkNode.node.tag = 'exchange'

// //         //проверяем InstrType ,если нет добавляем

// //         var insTypeImg = InstrumentLookupManager.getInstrumentTypeImage(curItem.InstrType, curItem.CFD)

// //         var newNode = null
// //         newNode = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, curItemInstrTypeText)
// //         if (newNode)
// //         {
// //             //set images
// //             newNode.InstrumentTypeImg = insTypeImg
// //             newNode.tag = curItemInstrType_Value
// //             // if (isInstrumentsDefaultsMode)
// //             //     InstrumentLookupManager.PopulateNormalSmallNode(newNode, quickTree.DrawingContext)
// //             // заполняем массив всех InstrType
// //             insTypeNodeArr.push(newNode)
// //         }

// //         //спускаемся ниже ExchangeName->InstrType
// //         var currentWorkNode = currentWorkNode[curItemInstrTypeText]

// //         //смотрим группу. нету? добавляем.
// //         var newNode = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, instrGroup)
// //         if (newNode)
// //         {
// //             if (full_contains)
// //                 newNode.FullContainsCount++
// //             newNode.tag = "group"
// //             newNode.SpecificTag = "instgroup"
// //             newNode.InstrumentTypeImg = insTypeImg
// //             newNode.sortingStop = true
// //             // if (isInstrumentsDefaultsMode)
// //             //     InstrumentLookupManager.PopulateNormalSmallNode(newNode, quickTree.DrawingContext)
// //         }
// //         else
// //         {
// //             let tmp_node = currentWorkNode[instrGroup]
// //             if (tmp_node && full_contains)
// //                 tmp_node.node.FullContainsCount++
// //         }

// //         //спускаемся ниже ExchangeName->InstrType->instrGroup
// //         currentWorkNode = currentWorkNode[instrGroup]
// //         // гадаю, для підвищення якості коду (збільшуючи його компактність) можна об'єднати попередній і наступний рядок в один з двома '=' присвоєннями,
// //         currentWorkNode = OptionProcess({   // але тоді треба перевірити чи коректний буде порядок присвоєння і за підсумком чи отримаємо у currentWorkNode бажаний результат
// //             curItem: curItem,
// //             insTypeImg: insTypeImg,
// //             currentWorkNode: currentWorkNode,
// //             // quickTree: quickTree,
// //             currentNameFilter: currentNameFilter,
// //             dataProvider: dataProvider,
// //             isInstrumentsDefaultsMode: isInstrumentsDefaultsMode,
// //             isFakeQT: props.isFakeQT,
// //             hasFilter: hasFilter
// //         })

// //         //создаём ноду инструмента
// //         var interiorID = curItem.GetInteriorID(),
// //             node = null

// //         let parentNode = currentWorkNode.node,
// //             alreadyIndex = parentNode.childNodes.findIndex((e) => e.nodeText == interiorID)     // #113551
// //         if (alreadyIndex < 0)
// //         {
// //             node = new InstrumentLookupQuickTreeNode(interiorID)
// //             if (currentNameFilter)
// //                 node.textPattern = currentNameFilter
// //             node.tag = curItem
// //             //добавляем её в ноду которую (нашли||сформировали)
// //             if (!parentNode.skipAdding || node.nodeText.indexOf('null') == -1)   // if тут для того чтоб не добавлять времен. опцион. инстр. когда в кеше уже имеем список страйков из [421]
// //                 parentNode.AddChildNode(node)

// //             // InstrumentLookupManager.PopulateNode(node, quickTree.DrawingContext, curItem)
// //         }
// //         else
// //             node = parentNode.childNodes[alreadyIndex]

// //         if (selectedItemsIsArray)
// //         {
// //             if (selectedItemsMap[interiorID])
// //             {
// //                 // quickTree.unCollapseAllParents(node)
// //                 // quickTree.addNodeToDict(node, true)
// //             }
// //         }
// //         else
// //             if (selectedItem === curItem)
// //             {
// //                 selectedNode = node
// //                 node.selected = true
// //             }
// //     }

// //     var keys = Object.keys(nodeRangeTree)
// //     var arrToWork = []

// //     for (var i = 0; i < keys.length; i++)
// //     {
// //         let node = nodeRangeTree[keys[i]].node
// //         node.sortingtoLowerCaseKey = node.nodeText.toLowerCase()

// //         if (!node.nodeLevel || !currentNameFilter || node.FilterDescrText || node.FilterNameText)
// //             arrToWork.push(node)
// //     }
// //     arrToWork.sort(function (a, b)
// //     {
// //         return a.sortingtoLowerCaseKey > b.sortingtoLowerCaseKey ? 1 : -1
// //     })

// //     InstrumentLookupManager.sortInstrumentTypeChildNodes(arrToWork)

// //     // TODO check after all
// //     // if (!isInstrumentsDefaultsMode)
// //     InstrumentLookupManager.removeUnnecessaryGroupChildNodes(insTypeNodeArr)

// //     // quickTree.Clear()
// //     // quickTree.AddNodeRange(arrToWork)

// //     // if (!selectedItemsIsArray)
// //     //     if (hasFilter)
// //     //         quickTree.unCollapseAllPosible(DataCache.NonFixedList)
// //     //     else
// //     //     {
// //     //         quickTree.setSelectedNode(selectedNode)
// //     //         quickTree.goToSelectedNode()
// //     //     }
// // }

// let OptionProcess = function (props)
// {
//     let curItem = props.curItem, insTypeImg = props.insTypeImg, currentWorkNode = props.currentWorkNode, quickTree = props.quickTree, dataProvider = props.dataProvider, isInstrumentsDefaultsMode = props.isInstrumentsDefaultsMode, hasFilter = props.hasFilter, currentNameFilter = props.currentNameFilter
//     if (curItem.isOptionSymbol && curItem.ForwardBaseInstrument || curItem.isFuturesSymbol)
//     {
//         var data = InstrumentLookupManager.getDescription(curItem), curItemforwardBaseInstrument = data.curItemforwardBaseInstrument, description = data.description, curInsNameForvardBaseInstr = data.curInsNameForvardBaseInstr
//         var newNode = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, curInsNameForvardBaseInstr)
//         if (newNode)
//         {
//             newNode.tag = "group"
//             newNode.sortingStop = true
//             newNode.InstrumentTypeImg = insTypeImg
//             // if (isInstrumentsDefaultsMode)
//             //     InstrumentLookupManager.PopulateNormalSmallNode(newNode, quickTree.DrawingContext)
//             // else
//             //     InstrumentLookupManager.PopulateNodeFutureOptionGroupNode(newNode, quickTree.DrawingContext, curInsNameForvardBaseInstr, description, curItem)
//         }
//         //спускаемся ниже ExchangeName->InstrType->instrGroup->(Future || Option) parentInstrument
//         currentWorkNode = currentWorkNode[curInsNameForvardBaseInstr]
//         // гадаю, задля підвищення якості коду (збільшуючи його компактність) можна об'єднати попередній і наступний рядок в один
//         if (curItem.isOptionSymbol)
//             return AddOptionEvent({
//                 curItem: curItem,
//                 curItemforwardBaseInstrument: curItemforwardBaseInstrument,
//                 insTypeImg: insTypeImg,
//                 currentWorkNode: currentWorkNode,
//                 // quickTree: quickTree,
//                 dataProvider: dataProvider,
//                 description: description,
//                 isInstrumentsDefaultsMode: isInstrumentsDefaultsMode,
//                 currentNameFilter: currentNameFilter,
//                 isFakeQT: props.isFakeQT,
//                 hasFilter: hasFilter
//             })
//         // але тоді треба перевірити чи коректний буде порядок присвоєння і за підсумком чи отримаємо у currentWorkNode бажаний результат + тоді можна видалити (якщо не помиляюсь) останній в цьому методі return currentWorkNode
//     }

//     return currentWorkNode
// }

// let AddOptionEvent = function (props)
// {
//     let curItemforwardBaseInstrument = props.curItemforwardBaseInstrument, insTypeImg = props.insTypeImg,
//         description = props.description, currentWorkNode = props.currentWorkNode, quickTree = props.quickTree, curItem = props.curItem,
//         dataProvider = props.dataProvider, hasFilter = props.hasFilter, currentNameFilter = props.currentNameFilter, isInstrumentsDefaultsMode = props.isInstrumentsDefaultsMode

//     //если у опциона родитель фьючерс
//     if (curItemforwardBaseInstrument && (curItemforwardBaseInstrument.isFuturesSymbol || curItemforwardBaseInstrument.IsFakeNonFixed()))
//     {
//         var nodeName = curItemforwardBaseInstrument.DisplayName()
//         description = curItemforwardBaseInstrument.DescriptionValue()
//         //существует ли он в дереве?
//         if (nodeName != currentWorkNode.node.nodeText)
//         {
//             let node = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, nodeName)
//             if (node)
//             {
//                 node.InstrumentTypeImg = insTypeImg
//                 node.sortingStop = true
//                 // if (isInstrumentsDefaultsMode)
//                 //     InstrumentLookupManager.PopulateNormalSmallNode(node, quickTree.DrawingContext)
//                 // else
//                 //     InstrumentLookupManager.PopulateNodeFutureOptionGroupNode(node, quickTree.DrawingContext, nodeName, description, curItem)
//             }
//             //спускаемся ниже ExchangeName->InstrType->instrGroup->Option parentInstrument -> Option parentInstrument (Future)
//             currentWorkNode = currentWorkNode[nodeName]
//         }
//     }

//     var date = Utils.formatDate(curItem.ExpDate, 'DD.MM.YYYY')
//     //теперь дата экспирациив
//     var newNode = InstrumentLookupManager.checkCreateAddNode(currentWorkNode, date)
//     if (newNode)
//     {
//         newNode.missAtUnCollapse = true
//         newNode.sortingStop = true
//         newNode.InstrumentTypeImg = insTypeImg
//         // if (isInstrumentsDefaultsMode)
//         //     InstrumentLookupManager.PopulateNormalSmallNode(newNode, quickTree.DrawingContext)
//         // else
//         //     InstrumentLookupManager.PopulateNodeFutureOptionGroupNode(newNode, quickTree.DrawingContext, date, curItem.SeriesDescription, curItem)

//         newNode.InstrumentTradingExchangeText = newNode.FlagImg = null  // не нужен флаг и exchng поскольку серия - неторговая сущность #113027
//     }
//     //спускаемся ниже ExchangeName->InstrType->instrGroup->Option parentInstrument -> (parentInstrument.isFuturesSymbol? Option parentInstrument(Future) : "without")->ExpDate
//     currentWorkNode = currentWorkNode[date]
//     let needOpenStrike = false  // #113021
//     if (newNode)
//     {
//         let cachedList = InstrumentLookupManager.tryGetCachedStrikeList(curItem)
//         if (cachedList && !props.isFakeQT)
//             needOpenStrike = newNode.addChildNodesByInstrumentsArray(cachedList, currentNameFilter, quickTree)

//         newNode.DoCollapse = function ()
//         {
//             let me = this   //TODO check: #93065 !me.childNodes.length  //#92878 !this.SkipRequest
//             if (me.collapsed && dataProvider && DataCache.NonFixedList && !me.SkipRequest)
//             {
//                 let ins = me.tag,
//                     cachedList = InstrumentLookupManager.tryGetCachedStrikeList(ins)

//                 if (cachedList)
//                     me.addChildNodesByInstrumentsArray(cachedList, currentNameFilter, quickTree)
//                 else
//                 {
//                     dataProvider.GetNonFixedInstrumentStrikes(ins).then((list) =>
//                     {
//                         InstrumentLookupManager.cacheStrikeList(list, ins)
//                         me.addChildNodesByInstrumentsArray(list, currentNameFilter, quickTree)
//                     })
//                 }

//             }
//             else
//                 me.collapsed = !me.collapsed
//         }

//         newNode.tag = curItem
//     }

//     let isLoadStrikesNode = (curItem.StrikePrice === -1 || curItem.StrikePrice === 0) && curItem.IsFakeNonFixed() && !!curItem.InstrDateSettingsList.length,
//         needOpen = (hasFilter && !isLoadStrikesNode) || needOpenStrike

//     currentWorkNode.node.collapsed = !needOpen
//     currentWorkNode.node.SkipRequest = needOpen

//     return currentWorkNode
// }
