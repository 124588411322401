// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import Ractive from 'ractive';

declare global {
    interface Window {
        Ractive: any
    }
}

Ractive.DEBUG = false;
window.Ractive = Ractive;
