// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { UserWebStorageInstance } from '../../../user-web-storage';
import { BaseStorageManager } from '../BaseStorageManager';
import { _WatchlistSheets, WatchlistSheets } from './WatchlistSheets';
import { UserIndexedDB, UserIndexedDBStore } from '@shared/commons/DataBase/UserIndexedDB';
import { ClientType } from '../../ClientType';
import { type RemoteDBRecord, type LocalDBRecord } from '@shared/utils/DBRecords';
import { SessionSettings } from '@shared/commons/SessionSettings';

class _WatchlistSheetsStorageManager extends BaseStorageManager<_WatchlistSheets> {
    public wasChanged (): void {
        this.wasSyncedIn = false;
        if (ClientType.IsWebMobile()) {
            this.wasSyncedOut = false;
        }
        super.wasChanged();
    };

    protected async onRestore (sheets: _WatchlistSheets | null): Promise<void> {
        WatchlistSheets.restore(sheets);
    }

    protected serializeData (): string {
        return _WatchlistSheets.serialize(WatchlistSheets.items);
    }

    protected deserializeData (data: string): _WatchlistSheets {
        return _WatchlistSheets.deserialize(data);
    }

    protected async fetchRemoteSettings (): Promise<RemoteDBRecord> {
        let dbRecord: RemoteDBRecord = await UserWebStorageInstance.settings.getWatchlistSheets();
        const updateTimeSpan = dbRecord?.updateTimeSpan ?? 0;
        if (isNullOrUndefined(dbRecord) || updateTimeSpan === 0) {
            const wlSheetitems = await SessionSettings.requestDefaultWatchlistLists();
            dbRecord = {
                settings: _WatchlistSheets.serialize(wlSheetitems),
                updateTimeSpan: +(new Date())
            };
        }
        return dbRecord;
    }

    protected async sendRemoteSettings (dbRecord: RemoteDBRecord): Promise<void> {
        await UserWebStorageInstance.settings.postWatchlistSheets(dbRecord);
    }
}

export const WatchlistSheetsStorageManager = new _WatchlistSheetsStorageManager(UserIndexedDB, UserIndexedDBStore.WL_SHEETS);
