// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomEvent } from '@shared/utils/CustomEvents';
import { CustomErrorClass, ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { ToolsCache } from '@shared/commons/cache/ToolsCache';
import { popupErrorHandler, workspaceManagerHandler } from '@shared/utils/AppHandlers';
import { UserWebStorageInstance } from '../../user-web-storage';
import { DockSystemInstance } from '../DockSystem';
import { TerceraPopupBaloon } from '../elements/TerceraPopupBaloon';
import { WorkSpace } from './WorkSpace';
import { WorkSpaceDBManager } from './WorkSpaceDBManager';
import { DataCache } from '@shared/commons/DataCache';
import { TerceraSymbolLookupBaseDataProvider } from '@shared/commons/NoNFixedListCore';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { BrowserUtils } from '@shared/commons/UtilsClasses/BrowserUtils';
import { NewNetTraderMode } from '../screen/NewNetTraderMode';
import { ApplicationInfo } from '@shared/commons/ApplicationInfo';
import { DataBaseActions } from '@shared/commons/DBClasses/DBPrimitives';
import { WorkSpaceMetaData } from './WorkSpaceMetaData';
import { type WorkspaceData } from './WorkspaceInfoResult';
import { wmh } from '@shared/commons/UtilsClasses/WorkspaceManagerHelper';
import { WDSettings } from '../settings/WDGeneralSettings';

declare global {
    interface Window {
        WorkSpaceManager: _WorkSpaceManager
    }
}

export const MAX_WORKSPACE_COUNT = 10;
export const FILENAME_PREFIX = 'WorkSpace';

class _WorkSpaceManager {
    currentWorkspace: WorkSpace | null;
    wsList: any = null;
    workSpaceListChanged: CustomEvent;
    needRestoreWS: boolean; // при авторелогине

    WorkspacesLimitReached: CustomEvent;
    private saveIntervalId: NodeJS.Timeout; ;
    private synchronizationIntervalId: NodeJS.Timeout;

    LoadedDataWorkSpace: LoadWorkSpaceResponse;
    OnWorkSpaceChanged: CustomEvent;

    lastChangeWs: any = null; // handler todo

    constructor () {
        this.currentWorkspace = null;
        this.wsList = {};// <id>:<name>
        this.workSpaceListChanged = new CustomEvent();
        this.needRestoreWS = false; // при авторелогине
        this.WorkspacesLimitReached = new CustomEvent();

        WDSettings.settingsChanged.Subscribe(this.updateSettings, this);

        this.saveIntervalId = null;
        this.LoadedDataWorkSpace = new LoadWorkSpaceResponse();
        this.OnWorkSpaceChanged = new CustomEvent();

        wmh.__init(this.saveWorkSpace.bind(this), this.synchronizeWorkspaces.bind(this));
    }

    async initWorkspaces (): Promise<any> {
        const IdsFromDB = await WorkSpaceDBManager.GetAllWorkSpacesIds();
        this.wsList = {};// clear prew
        return await UserWebStorageInstance.workspaces.getInitData()
            .then(async (data) => {
            // TODO. Ugly. Part 1.
                let activeWsId = data.active_ws_id;
                const wsDataArray = data.ws_data_array;

                const [newWsDataArray, isNewActiveWsId, newWSidFromDb] = await WorkSpaceDBManager.FilterWorkSpacesIds(wsDataArray, activeWsId);
                if (!isNullOrUndefined(newWSidFromDb)) { activeWsId = newWSidFromDb; }

                const [mergedWS, newWSidFrommerge] = this.mergeWsArrays(newWsDataArray, IdsFromDB);
                if (!isNullOrUndefined(newWSidFrommerge)) { activeWsId = newWSidFrommerge; }

                let listToPopulate: WorkSpaceMetaData[] = mergedWS;
                for (const wObj of mergedWS) {
                    if (!wObj.name) {
                        listToPopulate = wsDataArray.map((ws) => {
                            const wmd = new WorkSpaceMetaData();
                            wmd.FillByDataFromServer(ws, false);
                            return wmd;
                        });
                        await WorkSpaceDBManager.ClearAll();
                        break;
                    }
                }

                this.addArrayToWsList(listToPopulate);

                if (isNewActiveWsId) { activeWsId = null; };
                const activeWsLoadingHandler = this.processInitWorkSpacesList(listToPopulate, activeWsId);

                return [true, activeWsId, activeWsLoadingHandler];
            })
            .catch(() => {
                const ex = new CustomErrorClass('WorkSpaceManager error', 'WorkSpaceManager.initWorkspaces', 'initWorkspaces -> getInitData');
                ErrorInformationStorage.GetException(ex);
                this.addArrayToWsList(IdsFromDB);
                // this.LoadedDataWorkSpace = new LoadWorkSpaceResponse();
                // this.createWorkSpace();
                return [false, '', null];
            });
    };

    private mergeWsArrays (fromServer: WorkSpaceMetaData[], fromDB: WorkSpaceMetaData[]): [WorkSpaceMetaData[], string] {
        const tmpMap = {};
        const resArray: WorkSpaceMetaData[] = [];
        let serverActive: WorkSpaceMetaData = null;
        let newActiveId: string = null;
        for (const item of fromServer) {
            if (isNullOrUndefined(tmpMap[item.ws_id])) {
                tmpMap[item.ws_id] = item;
                item.fromServer = true;
                item.needLoad = true;
                if (item.isActive) { serverActive = item; newActiveId = item.ws_id; }
                resArray.push(item);
            }
        }
        for (const item of fromDB) {
            if (isNullOrUndefined(tmpMap[item.ws_id])) {
                tmpMap[item.ws_id] = item;
                item.fromServer = false;
                item.needLoad = false;
                resArray.push(item);
            } else {
                const tmp = tmpMap[item.ws_id];
                tmp.needLoad = tmp.updateTimeSpan > item.updateTimeSpan;
            }
            if (item.isActive) {
                if (serverActive === null) { newActiveId = item.ws_id; } else
                    if (item.updateTimeSpan >= serverActive.updateTimeSpan) { newActiveId = item.ws_id; }
            }
        }

        return [resArray, newActiveId];
    }

    async processInitWorkSpacesList (wsDataArray: WorkSpaceMetaData[], activeWsId: string): Promise<void> {
        let ActiveWsPrommise = null;
        for (const wsItem of wsDataArray) {
            if (!wsItem.needLoad) { continue; }
            const wsId = wsItem.ws_id;

            const tmpHandler = this.loadWSFromServerAndSaveInDB(wsId);
            if (activeWsId === wsId) { ActiveWsPrommise = tmpHandler; }
        }
        return ActiveWsPrommise;
    }

    private async loadWSFromServerAndSaveInDB (wsId: string): Promise<void> {
        return await UserWebStorageInstance.workspaces.get(wsId).then(async (wsData) => {
            await WorkSpaceDBManager.SaveWorkSpace(wsId, wsData, true);
        }).catch(async () => { return await WorkSpaceDBManager.GetWorkSpace(wsId); });
    }

    async saveWorkSpace (): Promise<void> {
        const ws = this.currentWorkspace;
        if (ws === null) { await Promise.resolve(); return; }// Promise.reject('currentWorkspace doesn\'t exist.');

        const reqObj = this.getWsDataForSave(ws);
        if (reqObj !== null) { await this.saveWorkSpaceToIndexedDb(ws.fileName, reqObj); } else { await Promise.resolve(); }
    };

    getWsDataForSave (ws: WorkSpace): any {
        if (ws === null) return null;

        const tools = ToolsCache.GetToolsForSave();
        const reqObj = {
            data: ws.serializePanels(),
            name: ws.visibleName,
            locked: ws.locked,
            panels_amount: ws.getPanelsAmount(),
            tools
        };

        return reqObj;
    };

    async saveWorkSpaceToIndexedDb (name, reqObj): Promise<void> {
        if (ApplicationInfo.isExploreMode) { await Promise.resolve(); return; }

        await WorkSpaceDBManager.SaveWorkSpace(name, reqObj);
        if (wmh.isShowLogs()) {
            console.warn(`Workspace ${name} was saved to IndexedDB`);
        }
    };

    async setActiveWorkspaceId (wsID: string): Promise<void> {
        await WorkSpaceDBManager.SetActiveWorkSpace(wsID);
    };

    restoreWorkSpace (wsData): void {
        const currentWorkspace = this.currentWorkspace;
        if (currentWorkspace === null) return;
        try {
            ToolsCache.CreateToolsFromSaves(wsData.tools, DataCache);
            currentWorkspace.visibleName = wsData.name;
            currentWorkspace.updateLocked(wsData.locked);
            currentWorkspace.loadPanels(wsData.data);
            currentWorkspace.populate();
        } catch (e) // #99183
        {
            try {
                const brokenWorkspaceID = currentWorkspace.fileName;

                this.removeWorkSpaces([brokenWorkspaceID]);
                this.deleteFromWsList(brokenWorkspaceID);

                const ex = new CustomErrorClass('WorkSpaceManager', 'restoreWorkSpace', 'restoreWorkSpace -> loadPanels');
                ErrorInformationStorage.GetException(ex);
                currentWorkspace.allPanels = {};
                DockSystemInstance.clearDocking();
            } finally {
                UserWebStorageInstance.workspaces.getDefaultData()
                    .then((defaultData) => {
                        if (defaultData) {
                            const newActiveID = defaultData.active_ws_id;
                            if (newActiveID) { void this.changeWorkSpace(newActiveID, true); }
                        }
                    })
                    .catch((e) => {
                        this.getNewCurrentWs(); // на случай если дефолтового нет
                    });
            }
        }

        DockSystemInstance.dockManager.hideCloseButton(currentWorkspace.locked);

        this.workSpaceListChanged.Raise();
    };

    async RestoreWorkspaces (): Promise<void> {
        if (this.needRestoreWS) { await Promise.resolve(); return; }
        if (this.LoadedDataWorkSpace.IsLoaded) {
            if (this.LoadedDataWorkSpace.JsonData) { this.restoreWorkSpace(this.LoadedDataWorkSpace.JsonData); }
            await Promise.resolve(); return;
        }

        await new Promise((resolve, reject) => {
            this.LoadedDataWorkSpace.OnLoadedDelegate = resolve;
        });
    };

    // TODO. Refactor.
    async changeWorkSpace (wsId: string, noNeedToSaveOldWS: boolean = false): Promise<void> {
        if (this.lastChangeWs !== null) { this.lastChangeWs.cancel(); }

        if (!noNeedToSaveOldWS) { await this.saveWorkSpace(); }

        await (this.lastChangeWs =
        Promise.resolve()
            .then(async () => {
                this.disposeCurrentWorkspaceObj();
                await this.setActiveWorkspaceId(wsId);
            })
            .then(async () => {
                return await WorkSpaceDBManager.GetWorkSpace(wsId);
            })
            .then((wsData) => {
                this.createCurrentWorkspaceObj(wsId, this.wsList[wsId]);
                if (wsData?.data?.insDict) {
                    const instrs = wsData.data.insDict;
                    const k = Object.keys(instrs);

                    if (k.length === 0) { return wsData; }

                    const insReqArr = [];
                    for (let i = 0; i < k.length; i++) {
                        const ins = k[i];
                        const DataProvider = new TerceraSymbolLookupBaseDataProvider();
                        if (ins && !DataCache.getInstrumentByName(ins)) { insReqArr.push(DataProvider.getInstrumentByName(ins)); }
                    }
                    // TODO костыль исправить с тикетом 84863
                    DataCache.currentWorkspaceInsDict = instrs || {};

                    if (insReqArr.length > 0) {
                        return Promise.all(insReqArr)
                            .then(function (insArr) {
                                return wsData;
                            });
                    } else { return wsData; }
                } else { return wsData; }
            })
            .then((wsData) => {
                if (wsData === null) { this.getNewCurrentWs(); } else if (this.LoadedDataWorkSpace.OnLoadedDelegate) {
                    this.restoreWorkSpace(wsData);
                    this.LoadedDataWorkSpace.OnLoadedDelegate();
                    this.LoadedDataWorkSpace.Clear();
                } else if (MainWindowManager.MainWindow.get('netTraderState') === NewNetTraderMode.WorkMode) {
                    this.restoreWorkSpace(wsData);
                } else {
                    this.LoadedDataWorkSpace.IsLoaded = true;
                    this.LoadedDataWorkSpace.JsonData = wsData;
                }
            })
            .then(() => {
                this.OnWorkSpaceChanged.Raise();
            }));
    };

    // -------------------------------- Shieeeeet ---------------------------------//

    closeWorkSpace (wsId: string): void {
        if (wsId in this.wsList) { this.closeWorkspacesWork([wsId]); }
    };

    closeAllWorkSpacesButCurrent (): void {
        const curWsId = this.currentWorkspace.fileName;
        const wsList = this.wsList;
        const ids = [];

        for (const wsId in wsList) {
            if (wsId !== curWsId) { ids.push(wsId); }
        }

        this.closeWorkspacesWork(ids);
    };

    closeWorkspacesWork (ids: string[]): void {
        if (isNullOrUndefined(ids) && ids.length === 0) { return; }

        for (const id of ids) { this.deleteFromWsList(id); }

        if (this.lastChangeWs !== null) { this.lastChangeWs.cancel(); }

        this.removeWorkSpaces(ids);

        // TODO. Ugly.
        const curWs = this.currentWorkspace;
        const curWsId = curWs !== null ? curWs.fileName : null;

        // Current workspace is still here - do nothing.
        if (curWsId in this.wsList) { return; }

        this.disposeCurrentWorkspaceObj();

        this.getNewCurrentWs();
    };

    private removeWorkSpaces (ids: string[]): void {
        for (const id of ids) { void WorkSpaceDBManager.MarkToRemoveWorkSpace(id); }
    }

    // -------------------------------- Shieeeeet ---------------------------------//
    AddWorkSpace (): void {
        void this.saveWorkSpace();
        this.createWorkSpace();
    };

    createWorkSpace (): void {
        if (this.isWorkspaceLimitReached()) { return; }

        if (this.lastChangeWs !== null) { this.lastChangeWs.cancel(); }

        const wsId = this.getNewWorkSpaceFileName();
        const wsName = this.getDefaultWorkSpaceName();

        WorkSpaceDBManager.SaveWorkSpace(wsId, { name: wsName })
            .then(() => {
                void this.setActiveWorkspaceId(wsId);
            }).catch(() => {});

        this.LoadedDataWorkSpace.IsLoaded = true;
        this.addToWsList(wsId, wsName);

        this.disposeCurrentWorkspaceObj();
        this.createCurrentWorkspaceObj(wsId, this.wsList[wsId]);
        this.OnWorkSpaceChanged.Raise();
    };

    cloneCurrentWorkSpace (): void {
        if (this.isWorkspaceLimitReached()) { return; }
        const wsList = this.wsList;
        const keys = Object.keys(wsList);
        let clonedWsNameNew = '';
        const dictToFind = {};
        const clonedWsId = this.getNewWorkSpaceFileName();
        const clonedWsName = this.currentWorkspace.visibleName + '-';
        for (let i = 0; i < keys.length; i++) {
            dictToFind[wsList[keys[i]]] = keys[i];
        }
        for (let i = 1; i <= MAX_WORKSPACE_COUNT; i++) {
            clonedWsNameNew = clonedWsName + i;
            if (!dictToFind[clonedWsNameNew]) break;
        }
        void WorkSpaceDBManager.CloneWorkSpace(this.currentWorkspace.fileName, clonedWsId)
            .then(async () => {
                this.addToWsList(clonedWsId, clonedWsNameNew);
                await this.changeWorkSpace(clonedWsId);
                await this.tryChangeWorkSpaceVisibleName(clonedWsId, clonedWsNameNew);
            });
    };

    // -------------------------------- Shieeeeet ---------------------------------//

    checkText (tb, newName): boolean {
        if (BrowserUtils.regexpSymbol.test(newName)) {
            popupErrorHandler.Show(tb,
                Resources.getResource('textBoxWithValidating.ErrorText.NameContainsInvalidChar'),
                Resources.getResource('screen.error.title'), true);
            return true;
        }
        if (BrowserUtils.regexpEnd.test(newName) || BrowserUtils.regexpText.test(newName)) {
            popupErrorHandler.Show(tb,
                Resources.getResource('textBoxWithValidating.ErrorText.NameExist'),
                Resources.getResource('screen.error.title'), true);
            return true;
        }
        popupErrorHandler.Hide(tb);
        return false;
    };

    async tryChangeWorkSpaceVisibleName (wsFileName: string, newName: string): Promise<boolean> {
        if (!(wsFileName in this.wsList)) { return false; }

        this.wsList[wsFileName] = newName;

        if (this.currentWorkspace.fileName === wsFileName) { this.currentWorkspace.visibleName = newName; }

        await WorkSpaceDBManager.SetWorkSpaceName(wsFileName, newName);
        return true;
    };

    clearAndSaveCurrentWorkSpace (): void {
        const cw = this.currentWorkspace;
        if (cw !== null) {
            cw.clear();
            void this.saveWorkSpace();
        }
    };

    clearCurrentWorkSpace (): void {
        const cw = this.currentWorkspace;
        if (cw !== null) { cw.clear(); }
        this.currentWorkspace = null;
    };

    canClearCurrentWorkSpace (): boolean {
        const cw = this.currentWorkspace;
        return cw?.getPanelsAmount() === 0;
    };

    // -------------------------------- Shieeeeet ---------------------------------//

    disposeCurrentWorkspaceObj (): void {
        const ws = this.currentWorkspace;
        if (ws !== null) ws.dispose();
        this.currentWorkspace = null;
        this.workSpaceListChanged.Raise();
    };

    createCurrentWorkspaceObj (wsId: string, name: string): void {
        this.currentWorkspace = new WorkSpace(name, wsId);
        this.workSpaceListChanged.Raise();
    };

    // -------------------------------- Shieeeeet ---------------------------------//
    // жопоболь: 91022 судя по всему ошибка возникает, когда после удаления единственного врк создаем врк с id Workspace1, но из базы врк еще не удалился -> пока не наведем порядок 84863 такой костылек
    getNewWorkSpaceFileName (): string {
        const wsList = this.wsList;
        const filenamePrefix = FILENAME_PREFIX;

        let key;
        do {
            key = filenamePrefix + (Math.floor(Math.random() * 100000) + 1);
        } while (wsList[key]);

        return key;
    };

    getDefaultWorkSpaceName (): string {
        const wsList = this.wsList;
        const defName = Resources.getResource('workspace.DefaultNameSpace');
        const keys = Object.keys(wsList);
        const dictToFind = {};
        let newDefName = '';
        for (let i = 0; i < keys.length; i++) {
            dictToFind[wsList[keys[i]]] = keys[i];
        }
        for (let i = 1; i <= MAX_WORKSPACE_COUNT; i++) {
            newDefName = defName + i;
            if (!dictToFind[newDefName]) break;
        }
        return newDefName;
    };

    public static workSpaceCompare (fileName1: string, fileName2: string): number {
        const filenamePrefix = FILENAME_PREFIX;

        const number1 = parseInt(fileName1.substring(filenamePrefix.length));
        const number2 = parseInt(fileName2.substring(filenamePrefix.length));

        if (isNaN(number1) || isNaN(number2)) { return 0; }

        return number1 - number2;
    };

    getNewCurrentWs (preferredWsId: string = null): void {
        const wsList = this.wsList;
        let newCurWsId;

        if (preferredWsId in wsList) { newCurWsId = preferredWsId; } else {
        // TODO. Ugly.
            const fileNames = Object.keys(wsList);
            fileNames.sort(_WorkSpaceManager.workSpaceCompare);

            newCurWsId = fileNames[fileNames.length - 1];
        }

        if (newCurWsId) { void this.changeWorkSpace(newCurWsId, false); } else { this.createWorkSpace(); }
    };

    isWorkspaceLimitReached (): boolean {
        if (this.wsListLength() >= MAX_WORKSPACE_COUNT) {
            this.WorkspacesLimitReached.Raise();
            return true;
        }
        return false;
    };

    // -------------------------------- Shieeeeet ---------------------------------//

    wsListLength (): number {
        if (this.wsList === null) { return 0; }

        return Object.keys(this.wsList).length;
    };

    // TODO. Rename.
    addArrayToWsList (wsDataArray): void {
        if (wsDataArray === null || wsDataArray.length === 0) { return; }

        const wsList = this.wsList;
        for (let i = 0, len = wsDataArray.length; i < len; i++) {
            const wsItem = wsDataArray[i];
            wsList[wsItem.ws_id] = wsItem.name;
        }

        this.workSpaceListChanged.Raise();
    };

    // TODO. Rename.
    addToWsList (wsId: string, name: string): void {
        this.wsList[wsId] = name;
        this.workSpaceListChanged.Raise();
    };

    // TODO. Rename.
    deleteFromWsList (wsId: string): void {
        if (isNullOrUndefined(wsId)) { return; }

        let changed = false;
        const wsList = this.wsList;

        if (wsId in wsList) {
            delete wsList[wsId];
            changed = true;
        }

        if (changed) { this.workSpaceListChanged.Raise(); }
    };

    resetCurrentWithDefault (): void// создает новый дефолтовый ws, затем удаляется старый активный ws
    {
        const brokenWorkspaceID = this.currentWorkspace.fileName;
        const wsName = this.currentWorkspace.visibleName;

        UserWebStorageInstance.workspaces.getDefaultData()
            .then((defaultData) => {
                if (defaultData) {
                    const newActiveID = defaultData.active_ws_id;
                    if (newActiveID) {
                        this.changeWorkSpace(newActiveID, true).then(() => {
                            const cW = this.currentWorkspace;
                            if (cW) {
                                cW.visibleName = wsName;
                                this.addToWsList(cW.fileName, cW.visibleName);
                            }

                            this.removeWorkSpaces([brokenWorkspaceID]);
                            this.deleteFromWsList(brokenWorkspaceID);
                        }).catch((e) => {});
                    }
                }
            })
            .catch((e) => {
                this.getNewCurrentWs(); // на случай если дефолтового нет
            });
    };

    // -------------------------------- Shieeeeet ---------------------------------//

    toggleLock (): boolean {
        const workspace = this.currentWorkspace;
        if (workspace === null) return;
        workspace.locked = !workspace.locked;

        DockSystemInstance.dockManager.hideCloseButton(workspace.locked);
        DockSystemInstance.dockManager.resizeTabHost();

        void WorkSpaceDBManager.SetWorkSpaceLocked(workspace.fileName, workspace.locked);

        return workspace.locked;
    };

    processUndock (): boolean {
        if (this.currentWorkspace.locked) {
            MainWindowManager.MainWindow.Controls.popupBaloon.show(
                TerceraPopupBaloon.States.WorkspaceLocked);
            // Processed.
            return true;
        }
        return false;
    };

    processDockResize (): boolean {
        if (this.currentWorkspace.locked) {
            MainWindowManager.MainWindow.Controls.popupBaloon.show(
                TerceraPopupBaloon.States.WorkspaceLocked);
            // Processed.
            return true;
        }
        return false;
    };

    processDialogWindowDrag (): boolean {
        return this.currentWorkspace.locked;
    };

    isDirty (wsId: string): boolean {
        const currentWorkSpace = this.currentWorkspace;
        return currentWorkSpace !== null &&
        currentWorkSpace.fileName === wsId &&
        currentWorkSpace.isDirty;
    };

    // -------------------------------- Shieeeeet ---------------------------------//

    startSaveLoop (): void {
        clearInterval(this.saveIntervalId);
        this.saveIntervalId = setInterval(() => { void this.saveWorkSpace(); }, 60000);
        this.synchronizationIntervalId = setInterval(() => { void this.synchronizeWorkspaces(); }, 600000);
    };

    stopSaveLoop (): void {
        clearInterval(this.saveIntervalId);
        clearInterval(this.synchronizationIntervalId);
        this.saveIntervalId = null;
        this.synchronizationIntervalId = null;
    };

    // -------------------------------- Shieeeeet ---------------------------------//

    updateSettings (): void {
        if (this.currentWorkspace !== null) { this.currentWorkspace.updateSettings(); }
    };

    populateCurrentWorkspace (): void {
        if (this.currentWorkspace !== null) { this.currentWorkspace.populate(); }
    };

    localize (): void {
        if (this.currentWorkspace !== null) { this.currentWorkspace.localize(); }
    };

    themeChange (): void {
        if (this.currentWorkspace !== null) { this.currentWorkspace.themeChange(); }
    };

    TickAsync (): void {
        if (this.currentWorkspace !== null) { this.currentWorkspace.TickAsync(); }
    };

    RefreshPanelsWithInstrumentDescriptions (insArray): void {
        if (this.currentWorkspace !== null) { this.currentWorkspace.RefreshPanelsWithInstrumentDescriptions(insArray); }
    };

    public static numberOfPanelByType (panelType: string): number {
        return WorkSpaceManager.currentWorkspace.numberOfPanelByType(panelType);
    };

    async synchronizeWorkspaces (): Promise<void> {
        const workspaces = await WorkSpaceDBManager.GetAllWorkSpaces();
        for (const ws of workspaces) {
            switch (ws.actionState) {
            case DataBaseActions.ToRemove:
                await UserWebStorageInstance.workspaces.delete([ws.id]);
                await WorkSpaceDBManager.RemoveWorkSpace(ws.id);
                break;
            case DataBaseActions.Update:
                await this.processSaveWSOnServer(ws);
                break;
            case DataBaseActions.Add:
                await UserWebStorageInstance.workspaces.create(ws.id, { name: ws.data.name });
                await this.processSaveWSOnServer(ws);
                break;
            }
        }
    }

    public readonly synchronizeWorkspacesBeforeExit = async (): Promise<void> => {
        this.stopSaveLoop();
        await this.saveWorkSpace();
        this.clearCurrentWorkSpace();
        await this.synchronizeWorkspaces();
    };

    private async processSaveWSOnServer (ws): Promise<void> {
        if (ws.isActive) { UserWebStorageInstance.workspaces.setActiveWorkspaceId(ws.id); }
        if (ws.dataToSend.data) {
            UserWebStorageInstance.workspaces.update(ws.id, this.prepareWSDataToSave(ws));
            void WorkSpaceDBManager.ClearWorkSpaceDataToSend(ws.id);
            if (wmh.isShowLogs()) {
                console.warn('Workspaces were saved to server');
            }
        }
        void WorkSpaceDBManager.SetDataBaseActionstate(ws.id, DataBaseActions.None);
    }

    private prepareWSDataToSave (ws): WorkspaceData {
        const result = ws.dataToSend.data || {};
        if (ws.hasOwnProperty('locked')) { result.locked = ws.locked; }
        if (ws.hasOwnProperty('name')) { result.name = ws.name; }
        if (ws.hasOwnProperty('updateTimeSpan')) { result.updateTimeSpan = ws.updateTimeSpan; }
        return result;
    }

    public workspaceManagerHandlerInitialize (): void {
        workspaceManagerHandler.numberOfPanelByType = _WorkSpaceManager.numberOfPanelByType;
        workspaceManagerHandler.RefreshPanelsWithInstrumentDescriptions = (insArray) => { WorkSpaceManager.RefreshPanelsWithInstrumentDescriptions(insArray); };
    }
};

class LoadWorkSpaceResponse {
    JsonData: any = null;
    IsLoaded: boolean = false;
    OnLoadedDelegate: any = null;

    Clear (): void {
        this.JsonData = null;
        this.IsLoaded = false;
        this.OnLoadedDelegate = null;
    };
};

export const WorkSpaceManager = new _WorkSpaceManager();

window.WorkSpaceManager = WorkSpaceManager; // it is necessary to fix reference in dockspawn.js
