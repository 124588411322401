// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class DirectMessageBaseClass {
    Code: number;
    IsDirect: boolean = true;
    CPHFD: any = null; // Custom Processing Handler For Delay (return to array)

    constructor (code: number) {
        this.Code = code;
    }

    public Clone () {
        return Object.assign({}, this);
    }
}
