// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ScreensNames } from '../../Controls/UtilsClasses/FactoryConstants';
import { TerceraChangePasswordScreen } from '../../Controls/screen/TerceraChangePasswordScreen';
import { InvestingChangePasswordScreenTemplate } from '../templates';

export class InvestingChangePasswordScreen extends TerceraChangePasswordScreen {
    cancelCallBack: any;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () {
        super();
        // void this.set({ width: 312, height: 458 }); // screen sizes set in .investing-change-password-screen class
    }

    getType (): string { return ScreensNames.InvestingChangePasswordScreen; }

    hidePasswords (): void {
        void super.set({
            curPassHided: true,
            newPassHided: true,
            confirmPassHided: true
        });
    }

    closeScreenEventClick (_sender, fromOk: boolean = false): void {
        if (!fromOk && this.cancelCallBack != null) {
            this.cancelCallBack();
        }
        void super.set({ visible: false });
    }
}

TerceraChangePasswordScreen.extendWith(InvestingChangePasswordScreen, {
    template: InvestingChangePasswordScreenTemplate,
    data: function () {
        return {
            curPassHided: true,
            newPassHided: true,
            confirmPassHided: true,
            visible: false
        };
    }
});
