// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type ConnectionErrorsEnum } from './ConnectionEnums';

export class ConnectionAttemptResponse {
    public text: string;
    public ErrorCode: ConnectionErrorsEnum;
    constructor (Text: string, ErrorCode: ConnectionErrorsEnum) {
        this.text = Text;
        this.ErrorCode = ErrorCode;
    }
}
