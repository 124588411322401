// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';
import { DynProperty } from '../../DynProperty';
import { ThemeManager } from '@front/controls/misc/ThemeManager';

export class TextDataCacheTool extends DataCacheTool {
    private readonly fontKey = 'property.Font';
    private readonly fontColorKey = 'property.FontColor';
    private readonly textKey = 'property.text';
    public text: string = 'Text';

    constructor () {
        super();
        this.ToolType = DataCacheToolType.CommentsLabel;
        this.InitPoints(1);

        this.color = 'white';
    }

    public override ThemeChanged (): void {
        super.ThemeChanged();

        this.font = ThemeManager.Fonts.Font_11F25_regular.copy();
    }

    MaxPointsCount (): number {
        return 1;
    }

    MinPointsCount (): number {
        return 1;
    }

    Properties (): DynProperty[] {
        const oldProps = super.Properties();
        const props = [];

        if (this.font != null) {
            props.push(new DynProperty(this.fontKey, this.font.copy(), DynProperty.FONT, DynProperty.VISUAL_GROUP));
        }

        if (this.color != null) {
            props.push(new DynProperty(this.fontColorKey, this.color, DynProperty.COLOR, DynProperty.VISUAL_GROUP));
        }

        if (this.text != null) {
            props.push(new DynProperty(this.textKey, this.text, DynProperty.STRING, DynProperty.VISUAL_GROUP));
        }

        const len = oldProps.length;
        for (let i = 0; i < len; i++) {
            const prop = oldProps[i];
            if (
                prop.group === DynProperty.NUMERICAL_PARAMETRS_GROUP ||
                prop.group === DynProperty.HIDDEN_GROUP ||
                prop.name === 'Stick to period' ||
                prop.name === 'IsBackground'
            ) {
                props.push(prop);
            }
        }

        return props;
    }

    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        const fontProp: DynProperty | undefined = DynProperty.getPropertyByName(properties, this.fontKey);
        if (!isNullOrUndefined(fontProp)) this.font = fontProp.value;

        const fontColorProp: DynProperty | undefined = DynProperty.getPropertyByName(properties, this.fontColorKey);
        if (!isNullOrUndefined(fontColorProp)) this.color = fontColorProp.value;

        const textProp: DynProperty | undefined = DynProperty.getPropertyByName(properties, this.textKey);
        if (!isNullOrUndefined(textProp)) this.text = textProp.value;
    }
}
