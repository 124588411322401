// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class REGRESSION extends IndicatorScriptBase {
    public LRIPeriod: number;
    public sourcePrices: number;

    constructor () {
        super();
        // #region Initialization
        this.ProjectName = 'Regression';
        this.Comments = 'Is the linear regression line used to measure trends';
        // #endregion

        this.SetIndicatorLine('REGRESSION', Color.Crimson, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.LRIPeriod = 2;
        super.InputParameter(new InputParameterInteger('LRIPeriod', 'Period of Linear Regression:', 0, 1, 99999, 0, 1));

        this.sourcePrices = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('sourcePrices', 'Sources prices for MA', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public GetIndicatorShortName (): string {
        return 'REGRESSION(' + this.LRIPeriod + ')';
    };

    public OnQuote (): void {
        super.OnQuote();

        if (this.CurrentData.Count < this.LRIPeriod) { return; };

        let sumX = 0.0;
        let sumY = 0.0;
        let sumXY = 0.0;
        let sumXX = 0.0;

        for (let i = 0; i < this.LRIPeriod; i++) {
            const price = this.CurrentData.GetPrice(this.sourcePrices, i);
            sumX += i;
            sumY += price;
            sumXY += i * price;
            sumXX += price * price;
        }
        const p = (this.LRIPeriod * sumXY - sumX * sumY) / (this.LRIPeriod * sumXX - sumX * sumX);
        const b = (sumY * sumXX - sumX * sumXY) / (this.LRIPeriod * sumXX - sumX * sumX);

        this.SetValue(0, 0, p * this.LRIPeriod + b);
    };
}
