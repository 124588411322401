// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PortfolioManagerPanelTemplate } from '../templates.js';
import Ractive from 'ractive';

export class PortfolioManagerPanel extends Ractive {
    public selectedHeaderSwitcherItem: any = null;
    OpenChartPanel: any;

    public getType (): string { return 'PortfolioManagerPanel'; }

    public override oninit (): void {
        this.on('clickHeaderSwitcher', this.changeActiveHeaderSwitcher);
        this.on('openChartEvent', this.openChartEvent);
        this.on('closeChartPanelClick', this.closeChartPanelClick);
    }

    public override oncomplete (): void {
        this.createHeaderSwitcher();
    }

    public createHeaderSwitcher (): void {
        const arrayHeaderSwitcher: any = [
            { Name: 'Positions', TypePanel: PortfolioManagerPanel.Panels.POSITIONS },
            // { Name: "Assets", TypePanel: PortfolioManagerPanel.Panels.ASSETS },
            { Name: 'Orders', TypePanel: PortfolioManagerPanel.Panels.ORDERS }
        // { Name: "Operations", TypePanel: PortfolioManagerPanel.Panels.OPERATIONS }
        ];

        arrayHeaderSwitcher[0].active = true;
        this.selectedHeaderSwitcherItem = arrayHeaderSwitcher[0];

        void this.set({ arrayHeaderSwitcher });
        this.changeShowPanel();
    }

    public openChartEvent (panel, item): void {
        void this.set({
            isVisibleChart: true,
            openChartItem: item.date.source
        });

        this.OpenChartPanel = panel;
    }

    public closeChartPanelClick (): void {
        void this.set('isVisibleChart', false);
        this.OpenChartPanel.closeChartPanelEvent();
    }

    public changeActiveHeaderSwitcher (ev, index): void {
        const items = this.get('arrayHeaderSwitcher');
        if (!items) {
            return;
        }

        const newSelectedHeaderSwitcherItem = items[index];
        if (newSelectedHeaderSwitcherItem === this.selectedHeaderSwitcherItem) {
            return;
        }

        if (this.selectedHeaderSwitcherItem) {
            this.selectedHeaderSwitcherItem.active = false;
        }

        newSelectedHeaderSwitcherItem.active = true;
        this.selectedHeaderSwitcherItem = newSelectedHeaderSwitcherItem;

        void this.set({ arrayHeaderSwitcher: items });
        this.changeShowPanel();
    }

    public changeShowPanel (): void {
        const whichPanel: any = {};

        switch (this.selectedHeaderSwitcherItem.TypePanel) {
        case PortfolioManagerPanel.Panels.POSITIONS:
            whichPanel.PositionsPanel = true;
            break;
        // case PortfolioManagerPanel.Panels.ASSETS:
        //     whichPanel.AssetsPanel = true;
        //     break;
        case PortfolioManagerPanel.Panels.ORDERS:
            whichPanel.OrdersPanel = true;
            break;
        // case PortfolioManagerPanel.Panels.OPERATIONS:
        //     break;
        };

        void this.set({ whichPanel });
    }

    public static readonly Panels = {
        POSITIONS: 0,
        ASSETS: 1,
        ORDERS: 2,
        OPERATIONS: 3
    };
}

Ractive.extendWith(PortfolioManagerPanel, {
    template: PortfolioManagerPanelTemplate,
    data: function () {
        return {
            isVisibleChart: false,
            arrayHeaderSwitcher: [],
            whichPanel: {},
            accountItem: null
        };
    },
    selectedHeaderSwitcherItem: null
});
