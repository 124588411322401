// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { Rectangle } from '@shared/commons/Geometry';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { LinkedSystem } from '../misc/LinkedSystem';
import { RiskCalculatorTemplate, OrderEditControlsTemplate } from '../../templates.js';
import { DockSystemInstance } from '../DockSystem';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { OrderEditViewBase } from './OrderEditViewBase';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { DynProperty } from '@shared/commons/DynProperty';
import { Instrument } from '@shared/commons/cache/Instrument';
import { IsAllowed, type IsAllowedResponce } from '@shared/commons/IsAllowed';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { OrderEditUpdateData } from '@shared/utils/Trading/OrderEditUpdateData';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { TradingNumericErrorChecker } from '@shared/commons/Trading/TradingNumericErrorChecker';
import { OrderExecutorUtils } from '@shared/commons/Trading/OrderExecutorUtils';
import { type Account } from '@shared/commons/cache/Account';
import { type OrderTypeBase } from '@shared/commons/cache/OrderParams/order-type/OrderTypeBase';
import { type ITradingData } from '@shared/utils/Trading/TradingData';
import { type TIF } from '@shared/utils/Trading/OrderTif';
import { type OEQuantity } from '../trading/OE/OEQuantity';
import { type MarginOEControl } from './MarginOEControl';
import { WDSettings } from '../settings/WDGeneralSettings';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';

export class RiskCalculator extends OrderEditViewBase {
    public static readonly TIME_TO_HIDE = 1000;

    public headerLocaleKey: string = 'panel.riskCalculator';
    public NewParametrsHandler: any = null;
    public closeTimeout: any = null;
    public AutoHideHeaderUpdateHandler: any = null;
    public IsResized: boolean = false;
    public updateTradingAllowedStuff: any;

    public override getType (): PanelNames { return PanelNames.RiskCalculator; }

    public override oninit (): void {
        super.oninit();

        this.observe('instrument', this.onInstrumentChanged);
        this.observe('account', this.onAccountChanged);

        void this.set(
            'singleAccount',
            DataCache.getNumberOfAccounts() === 1);

        this.observe(
            'orderType',
            this.onOrderTypeChanged);

        this.observe(
            'instrument account quantity side tif productType',
            this.onTradingDataChanged);

        this.on('placeOrderClick', this.onPlaceOrderClick);
        this.on('btnCancelClick', () => { this.close(); });

        // if (!Resources.isHidden('OrderEntry.InfoBlock.Visibility'))
        // {
        void this.set('marginOEAllowed', true);
        this.on('onMarginOEVisibleStateChanged', this.onMarginOEVisibleStateChanged);
        // }

        // this.observe('delayedVisible snapshotVisible dataSourceVisible', this.deferLayout)
        // this.observe('dataSourceOpen', this.onDataSourceOpen)
        // DataCache.OnSnapshotResponseReceived.Subscribe(this.updateGetSnapshotBtn, this)     // обновление enability кнопок Get snapshot если на клиенте совершили Get snapshot request
        // Account.TradeStatusChanged.Subscribe(this.updateGetSnapshotBtn, this);               // обновление enability кнопок Get snapshot при изменениях значения на сервере

        DataCache.OnUpdateAccount.Subscribe(this.onUpdateAccount, this);

        this.on('attachClick', this.AttachClick);
    }

    public override oncomplete (): void {
        super.oncomplete();
        void this.set({ showAttachHeaderButton: DockSystemInstance.isAutoHideAvaliable() });
        DockSystemInstance.RegisterPanel(this);

        if (this.get<boolean>('isAlert')) {
            void this.set({ showAttachHeaderButton: false, isAccountLinkShow: false, isSymbolLinkShow: false, canLinkByAccount: false });
        }

        if (this.get<boolean>('attached')) {
            void this.set({ visible: false, movable: false });
            DockSystemInstance.addToAutoHide(this);
            MainWindowManager.MainWindow.resetFocus();
        }
        this.onInstrumentChanged(this.get('instrument'), null);

        this.SetFirstElement();
        this.center();
    }

    public override updatePanelHeader (): void {
        const ins: Instrument = this.get('instrument');
        const header = Resources.getResource(this.headerLocaleKey) + (!isNullOrUndefined(ins) ? (' ' + ins.DisplayName()) : '');
        void this.set({ header });
        if (!isNullOrUndefined(this.AutoHideHeaderUpdateHandler)) {
            this.AutoHideHeaderUpdateHandler(header);
        }
    }

    public onInstrumentChanged (instrument: Instrument, lastInstrument: Instrument): void {
        if (isNullOrUndefined(instrument) || instrument === lastInstrument || !this.completed) {
            return;
        }

        this.subscribeRiskSettingsUpdate(instrument, lastInstrument);

        this.instrumentSettingsUpdate();

        this.symbolLink_Out(false, instrument);
        this.updatePanelHeader();
        this.deferLayout();
    }

    public onAccountChanged (account: Account, lastAccount): void {
        if (isNullOrUndefined(account)) {
            return;
        }

        this.accountLink_Out(false, account);
    }

    public UpdateAutoHideSate (state: boolean): void {
        if (this.get<boolean>('attached') || this.get<boolean>('isAlert')) {
            return;
        }

        void this.set('showAttachHeaderButton', state);
    }

    public ForcedAutoHide (): void {
        if (!this.get<boolean>('attached')) {
            return;
        }

        clearTimeout(this.closeTimeout);
        void this.set({ visible: false });
        MainWindowManager.MainWindow.resetFocus();
    }

    public AttachClick (): void {
        let attached: boolean = this.get('attached');
        if (attached) {
            attached = !attached;
            clearTimeout(this.closeTimeout);
            void this.set({ visible: true, movable: true, attached });
            DockSystemInstance.removeFromAutoHide(this);
        } else {
            attached = !attached;
            this.IsResized = false;
            void this.set({ visible: false, movable: false, attached });
            DockSystemInstance.addToAutoHide(this);
        }
        this.layout();

        if (!attached) {
            this.center();
        }

        MainWindowManager.MainWindow.resetFocus();

        if (this.get<boolean>('marginOEVisible') && !isNullOrUndefined(this.Controls.marginOEControl)) {
            this.Controls.marginOEControl.layoutTable();
        }

        const btnattachText = attached ? Resources.getResource('panel.external.Dettach') : Resources.getResource('panel.external.Attach');
        void this.set('header_button_text', btnattachText);
    }

    public AutoHideHover (): void {
        clearTimeout(this.closeTimeout);
        const visible: boolean = this.get('visible');
        if (visible) {
            return;
        }

        void this.set({ visible: true });
        this.ChangeAutohideLocation();
    }

    public AutoHideLeave (): void {
        if (this.get<boolean>('attached')) {
            this.MakeMeHidden();
        }
    }

    public MakeMeHidden (): void {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = setTimeout(() => {
            if (!this.get<boolean>('focused')) {
                void this.set({ visible: false });
            }
            clearTimeout(this.closeTimeout);
        }, RiskCalculator.TIME_TO_HIDE);
    }

    public ChangeAutohideLocation (): void {
        const newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(
            2000,
            52,
            this.get('width'),
            this.get('height')));

        this.setLocation(newCoord.newX - 27, newCoord.newY);
    }

    public override lostFocus (): void {
        super.lostFocus();
        if (!this.get<boolean>('attached')) {
            return;
        }

        if (MainWindowManager.TerceraInstrumentLookupDropDownForm?.get<boolean>('visible')) {
            MainWindowManager.TerceraInstrumentLookupDropDownForm.AfterLostFocusWaiting = this;
            return;
        }

        if (MainWindowManager.TerceraAccountLookupDropDownForm?.get<boolean>('visible')) {
            MainWindowManager.TerceraAccountLookupDropDownForm.AfterLostFocusWaiting = this;
            return;
        }
        this.MakeMeHidden();
    }

    public override onMouseEnter (event): void {
        this.AutoHideHover();
    }

    public override onMouseLeave (event): void {
        this.AutoHideLeave();
    }

    public override gotFocus (): void {
        super.gotFocus();
        clearTimeout(this.closeTimeout);
    }

    public override dispose (): void {
        if (this.get<boolean>('attached')) {
            DockSystemInstance.removeFromAutoHide(this);
        }

        DockSystemInstance.UnRegisterPanel(this);

        DataCache.OnUpdateAccount.UnSubscribe(this.onUpdateAccount, this);

        const instrument: Instrument = this.get('instrument');
        if (!isNullOrUndefined(instrument)) {
            instrument.RiskSettingsUpdated.UnSubscribe(this.updateTradingAllowedStuff, this);
        }

        super.dispose();
    }

    public SetFirstElement (): void {
        if (this.get<boolean>('singleAccount')) {
            this.Controls.OEInstrumentLookup.setFocus();
        } else {
            this.Controls.OEAccountLookup.setFocus();
        }
    }

    public onPlaceOrderClick (): void {
        if (this.get<boolean>('isAlert')) {
            this.saveOrderParam();
        } else {
            if (TradingNumericErrorChecker.HasErrors(this)) {
                return;
            }

            this.placeOrder(this.orderEdit);
        }
    }

    public selectOrderType (newOrderType: OrderType): void {
        const orderTypeDict = OrderExecutorUtils.getAllowedOrderTypeDict(this.get('account'), this.get('instrument'), [], WDSettings.orderType, false);

        const orderType = orderTypeDict[newOrderType] ?? orderTypeDict[OrderType.Market];

        const selectedItem = { value: orderType, text: Resources.getResource(orderType.localizationKey()) };

        void this.set('selOrderTypeCBItem', selectedItem);
    }

    public onOrderTypeChanged (newOrderType: OrderTypeBase, oldOrderType: OrderTypeBase): void {
        if (isNullOrUndefined(newOrderType) || newOrderType === oldOrderType) {
            return;
        }

        const orderEdit = newOrderType.createOrderEditObject({
            dataCache: DataCache
        });

        this.setOrderEdit(orderEdit);

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            this.getAllTradingDataDict()));
    }

    public onTradingDataChanged (newVal, oldVal, key: string): void {
        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit)) return;

        const tradingDataDict: ITradingData = {};
        tradingDataDict[key] = newVal;

        tradingDataDict.completed = this.completed;

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            tradingDataDict));

        this.deferLayout();
    }

    public getAllTradingDataDict (): ITradingData {
        const tradingDataDict: ITradingData = {};

        const instrument: Instrument = this.get('instrument');
        if (!isNullOrUndefined(instrument)) {
            tradingDataDict.instrument = instrument;
        }

        const account: Account = this.get('account');
        if (!isNullOrUndefined(account)) {
            tradingDataDict.account = account;
        }

        const quantity: Quantity = this.get('quantity');
        if (!isNullOrUndefined(quantity)) {
            tradingDataDict.quantity = quantity;
        }

        const tif: TIF = this.get('tif');
        if (!isNullOrUndefined(tif)) {
            tradingDataDict.tif = tif;
        }

        const side = this.get('side');
        if (!isNullOrUndefined(side)) {
            tradingDataDict.side = side;
        }

        const productType = this.get('productType');
        if (!isNullOrUndefined(productType)) {
            tradingDataDict.productType = productType;
        }

        tradingDataDict.completed = this.completed;

        return tradingDataDict;
    }

    public override layout (): void {
        this.updateOrderEditParametersVisibility();

        if (this.get<boolean>('attached')) {
            void this.set('height', MainWindowManager.MainWindow.GetAvaliableHeight());
        } else {
            void this.set('height', null);
        }

        if (this.get<boolean>('marginOEVisible') && !isNullOrUndefined(this.Controls.marginOEControl)) {
            this.Controls.marginOEControl.layoutTable();
        }
    }

    public saveOrderParam (): void {
        if (!isNullOrUndefined(this.NewParametrsHandler)) {
            this.NewParametrsHandler(this.orderEdit);
        }
    }

    public placeOrder (orderEdit): void {
        orderEdit.placedFrom = PlacedFrom.WEB_OE;
        DataCache.FOrderExecutor.placeOrderPromise(orderEdit).catch(function () {
            const ex = new CustomErrorClass('RiskCalculator error', 'RiskCalculator.placeOrder', 'placeOrder -> placeOrderPromise');
            ErrorInformationStorage.GetException(ex);
        });
    }

    public override updateSettings (): void {
        const ins: Instrument = this.get('instrument');
        const acc: Account = this.get('account');
        if (isNullOrUndefined(ins) || isNullOrUndefined(acc)) {
            return;
        }

        this.updateTradingAllowedAndReasonTooltip();
    }

    public override callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        void this.set('instrument', this.getCallBackInstrument(properties, 'symbol'));

        let dp = DynProperty.getPropertyByName(properties, 'account');
        if (!isNullOrUndefined(dp?.value)) {
            void this.set('account', SessionSettings.getDefValueFromObj(DataCache.Accounts[dp.value], DataCache.Accounts));
        }

        dp = DynProperty.getPropertyByName(properties, 'marginOEVisible');
        if (!isNullOrUndefined(dp)) {
            void this.set('marginOEVisible', dp.value);
        }

        // dp = DynProperty.getPropertyByName(properties, 'dataSourceOpen');
        // if (dp) this.set('dataSourceOpen', dp.value);

        const attached = DynProperty.getPropValue(properties, 'attached');
        void this.set({ attached });
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();
        const ins: Instrument = this.get('instrument');
        if (!isNullOrUndefined(ins)) {
            properties.push(new DynProperty('symbol', ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        const acc: Account = this.get('account');
        if (!isNullOrUndefined(acc)) {
            properties.push(new DynProperty('account', acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        const marginOEVisible: boolean = this.get('marginOEVisible');
        properties.push(new DynProperty('marginOEVisible', marginOEVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        // var dataSourceOpen = this.get('dataSourceOpen');
        // properties.push(new DynProperty("dataSourceOpen", dataSourceOpen, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        properties.push(new DynProperty('attached', this.get('attached'), DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    // #endregion

    // #region Link

    public override symbolLink_Out (newSubscriber, instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            const ins: Instrument = this.get('instrument');
            if (isNullOrUndefined(ins)) return;
            instrument = ins;
        }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
        }
    }

    public override symbolLink_In (symbolName: string): void {
        const newInstr: Instrument = DataCache.getInstrumentByName(symbolName);
        if (!isNullOrUndefined(newInstr)) {
            void this.set('instrument', newInstr);
        }
    }

    // #endregion Link

    public override localize (): void {
        super.localize();

        void this.set('buttonCancelText', Resources.getResource('general.messageBox.cancel'));

        const btnattachText = this.get<boolean>('attached') ? Resources.getResource('panel.external.Dettach') : Resources.getResource('panel.external.Attach');
        void this.set('header_button_text', btnattachText);
    }

    public onUpdateAccount (account: Account): void {
        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit)) return;

        orderEdit.onUpdateAccount(account);
    }

    public instrumentSettingsUpdate (): void {
        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');

        if (!Instrument.IsWorkingInstrument(instrument)) {
            return;
        }

        if (!isNullOrUndefined(account)) {
            this.updateTradingAllowedAndReasonTooltip();
        }

        void this.set('productTypeShow', instrument.isProductTypeVisible());
    }

    public updateTradingAllowedAndReasonTooltip (): void {
        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');

        const ordertype: OrderTypeBase = this.get('orderType');
        let ordT = -1;
        if (!isNullOrUndefined(ordertype)) {
            ordT = ordertype.id();
        }

        const tradingAllowed = IsAllowed.IsTradingAllowed([account], instrument, ordT);
        let tradingForbiddenReason = tradingAllowed.Allowed ? '' : tradingAllowed.ReasonText;

        if (tradingAllowed.Allowed && ordT === -1) {
            tradingForbiddenReason = Resources.getResource('Not selected order type');
            tradingAllowed.Allowed = false;
        }

        void this.set({
            isAllowedResponce: tradingAllowed,
            tradingAllowed: tradingAllowed.Allowed,
            tradingForbiddenReason
        });
    }

    public subscribeRiskSettingsUpdate (instrument: Instrument, lastInstrument: Instrument): void {
        if (!isNullOrUndefined(lastInstrument)) {
            lastInstrument.RiskSettingsUpdated.UnSubscribe(this.instrumentSettingsUpdate, this);
        }

        if (!isNullOrUndefined(instrument)) {
            instrument.RiskSettingsUpdated.Subscribe(this.instrumentSettingsUpdate, this);
        }
    }

    public onMarginOEVisibleStateChanged (): void {
        const oldState: boolean = this.get('marginOEVisible');
        const newState = !oldState;

        void this.set('marginOEVisible', newState);
        void this.set('width', newState ? 685 : 350);

        if (!this.get<boolean>('attached')) {
            void this.set('resizable', newState);
        }

        if (!newState) {
            this.IsResized = false;
            this.layout();
        }

        if (this.get<boolean>('attached')) {
            this.ChangeAutohideLocation();
        }
    }

    public override onQuantityChanged (qtyValue: number): void {
        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit)) return;

        const ins = orderEdit.instrument;
        if (isNullOrUndefined(ins)) return;

        const OEQty: OEQuantity = !isNullOrUndefined(this.Controls) ? this.Controls.OEQuantity : null;
        const acc = orderEdit.account; const pt = orderEdit.productType;
        const isAllowedResponce: IsAllowedResponce = this.get('isAllowedResponce');

        if (isAllowedResponce?.Allowed) { // доп валидацию по qty посчитанную position sizing-ом можно применить если торговля разрешена в IsAllowed.IsTradingAllowed
            const inLots = !isNullOrUndefined(orderEdit.sessionSettings) ? WDSettingsUtils.displayAmountInLots() : null;
            const minLot = Quantity.convertQuantityValue(new Quantity(ins.getMinLot(pt, acc), true), ins, inLots, acc, pt);
            const maxLot = Quantity.convertQuantityValue(new Quantity(ins.getMaxLot(pt, acc), true), ins, inLots, acc, pt);

            const tradingAllowed = qtyValue >= minLot && qtyValue <= maxLot;
            const reasonLocKey = tradingAllowed
                ? ''
                : ('positionSizeCalculator.OEButtonDisabled.' + (qtyValue < minLot ? 'less' : 'more'));

            let reasonText = Resources.getResource(reasonLocKey);
            if (!tradingAllowed) { reasonText = reasonText.replace('{0}', (qtyValue < minLot ? minLot : maxLot).toString()); }

            void this.set({
                tradingAllowed,
                tradingForbiddenReason: reasonText
            });
        }

        if (!isNullOrUndefined(OEQty)) {
            void OEQty.set('value', qtyValue);
            OEQty.updateQuantity();/// // TODO !!!!! TODO !!!!!!!!!!!!!!!!!!!!!!!!!
        }
    }

    public enableSL (): void {
        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit?.enableSL)) {
            return;
        }

        orderEdit.enableSL();
    }

    public onDataSourceOpen (): void {
        this.deferLayout();

        const marginOE: MarginOEControl = this.Controls.marginOEControl;
        if (!isNullOrUndefined(marginOE)) {
            setTimeout(() => { marginOE.UpdateTable(); }, 100);
        }
    }
}

OrderEditViewBase.extendWith(RiskCalculator, {
    partials: {
        bodyPartial: RiskCalculatorTemplate,
        orderEditControls: OrderEditControlsTemplate
    },
    data: function () {
        return {
            showFooter: false,
            resizable: false,
            minWidth: 570,
            dockablePanel: false,
            showHeader: true,
            isAccountLinkShow: true,
            isSymbolLinkShow: true,

            instrument: null,
            account: null,
            quantity: null, // QuantityObject
            side: null,
            tif: null, // TIF object
            orderType: null,
            productType: null,
            productTypeShow: false,

            singleAccount: false,
            canFilterByAccount: false,

            selOrderTypeCBItem: null,
            isAlert: false,

            marginOEAllowed: false,
            marginOEVisible: false,
            // isHeaderMenuButtonShow: true,
            showAttachHeaderButton: true,
            style_addition_header_button: 'js-button-attach style-width-100',
            attached: false
        };
    }
});
