// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType, PriceTypeMap } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorFunctions } from '../IndicatorFunctions';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { type iBuildInIndicator } from '../iBuildInIndicator';

export class MACD extends IndicatorScriptBase {
    public SourcePrice: number;
    public FastEMA: number;
    public SlowEMA: number;
    public MAType: any;
    public SignalPeriod: number;
    public MAHandlers: Array<(arg?: number) => number>;

    private fastEMA: iBuildInIndicator;
    private slowEMA: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'Moving Average/Convergence Divergence';
        this.Comments = 'A trend-following momentum indicator that shows the relationship between two moving averages of prices';
        this.SetIndicatorLine('MACD', Color.DodgerBlue, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('Signal', Color.Red, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('Histogram', Color.Green, 3, LineStyle.HistogrammChart);
        this.SeparateWindow = true;

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 0,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));

        this.FastEMA = 12;
        super.InputParameter(new InputParameterInteger('FastEMA', 'Period of FastEMA', 1, 1, 9999));

        this.SlowEMA = 26;
        super.InputParameter(new InputParameterInteger('SlowEMA', 'Period of SlowEMA', 2, 1, 9999));

        this.MAType = MAMode.SMA;
        super.InputParameter(new InputParameterCombobox('MAType', 'Type of Moving Average for Signal', 3,
            [
                { Simple: MAMode.SMA },
                { Exponential: MAMode.EMA },
                { Modified: MAMode.SMMA },
                { 'Linear Weighted': MAMode.LWMA }
            ]));

        this.SignalPeriod = 9;
        super.InputParameter(new InputParameterInteger('SignalPeriod', 'Period of Signal', 4, 1, 9999));

        this.MAHandlers = [];
    }

    public Init (): void {
        super.IndicatorShortName(this.GetIndicatorShortName());
        this.MAHandlers[MAMode.SMA] = this.SMAHandler.bind(this);
        this.MAHandlers[MAMode.EMA] = this.SMMA_EMAHandler.bind(this);
        this.MAHandlers[MAMode.SMMA] = this.SMMA_EMAHandler.bind(this);
        this.MAHandlers[MAMode.LWMA] = this.LWMAHandler.bind(this);
    }

    public override GetIndicatorShortName (): string {
        return 'MACD(' + this.FastEMA + ',' + this.SlowEMA + ',' + this.SignalPeriod + ';' + PriceTypeMap[this.SourcePrice].toLowerCase() + ')';
    }

    public override getCustomName (): string {
        return 'MACD (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.fastEMA = this.Indicators.iMA(this.CurrentData, this.FastEMA, MAMode.EMA, 0, this.SourcePrice);
        this.slowEMA = this.Indicators.iMA(this.CurrentData, this.SlowEMA, MAMode.EMA, 0, this.SourcePrice);
        return [this.fastEMA, this.slowEMA];
    }

    public SMAHandler (): number {
        return IndicatorFunctions.CallMovingFunction(this.MAType, this, this.SignalPeriod, 1, 0) || 0;
    }

    public LWMAHandler (): number {
        return IndicatorFunctions.CallMovingFunction(this.MAType, this, this.SignalPeriod, 1, 0) || 0;
    }

    public SMMA_EMAHandler (start): number {
        if ((start + this.SignalPeriod) === this.CurrentData.Count) { return IndicatorFunctions.CallMovingFunction(MAMode.SMA, this, this.SignalPeriod, 1, 0) || 0; } else { return IndicatorFunctions.CallMovingFunction(this.MAType, this, this.SignalPeriod, 1, 0) || 0; }
    }

    public OnQuote (): void {
        super.OnQuote();

        const start = Math.max(this.FastEMA, this.SlowEMA);
        if (start > this.CurrentData.Count) { return; }
        const differ = this.fastEMA.GetValue() - this.slowEMA.GetValue();
        this.SetValue(0, 0, differ);

        if ((start + this.SignalPeriod) > this.CurrentData.Count) { return; }

        const signal = this.MAHandlers[this.MAType](start);
        this.SetValue(1, 0, signal);

        this.SetValue(2, 0, differ - signal);
    }
}
