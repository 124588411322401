// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { BrowserUtils } from '../../Commons/UtilsClasses/BrowserUtils';

class _FontUtils {
    public makeCorrectBoldFont (fontWithoutBold: string): string {
        if (BrowserUtils.isSafari) {
            return fontWithoutBold + ' bold';
        }

        return 'bold ' + fontWithoutBold;
    }
}
export const FontUtils: _FontUtils = new _FontUtils();
