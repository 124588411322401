// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectDialogCreatedMessage extends DirectMessageBaseClass {
    public DialogId = 0;
    public Pin: string | null = null;

    constructor () {
        super(Message.CODE_NEW_ORDER_MESSAGE);
    }
}
