// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TimeSpan } from '../../../../Utils/Time/TimeSpan';
import { DateTimeUtils } from '../../../../Utils/Time/DateTimeUtils';
import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { GradientMode } from '../IndicatorConstants';
import { Indicator } from '../Indicator';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { HistoricalDataRequest } from '../HistoricalDataRequest';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterBoolean } from '../InputParamaterClasses/InputParameterBoolean';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';
import { IndicatorDataType, Period } from '../IndicatorEnums';

export class SUPERTRENDMTV3 extends IndicatorScriptBase {
    public AtrPeriod: number;
    public Digit: number;
    public DigitM: number;
    public IsCheckedCloud: boolean;
    public Timeframe: number;
    public PeriodValue: number;
    public DataT: number;

    private _ATR: iBuildInIndicator;
    private _currTrend: number;
    private _prevUP: number;
    private _prevDown: number;
    private _prevTrend: number;
    private _down: number;
    private _up: number;
    private _ATR1: iBuildInIndicator;
    private _currTrend1: number;
    private _prevUP1: number;
    private _prevDown1: number;
    private _prevTrend1: number;
    private _down1: number;
    private _up1: number;

    public timeFrameData: any;
    public historyLoaded: boolean;
    public isProcessing: boolean;
    public isSameTF: boolean;
    public DownTrendColor: string;
    public UpTrendColor: string;

    constructor () {
        super();
        this.ProjectName = 'SUPERTREND MTV3';
        this.Comments = 'SUPERTRENDMTV3';
        this.SetIndicatorLine('SuperTrendMT', Color.Blue, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('SuperTrendMulti', Color.CornflowerBlue, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.AtrPeriod = 10;
        super.InputParameter(new InputParameterInteger('AtrPeriod', 'Period of ATR', 0, 1, 100));

        this.Digit = 3;
        super.InputParameter(new InputParameterInteger('Digit', 'Digit', 1, 1, 100));

        this.DigitM = 3;
        super.InputParameter(new InputParameterInteger('DigitM', 'DigitM', 2, 1, 100));

        this.IsCheckedCloud = false;
        super.InputParameter(new InputParameterBoolean('IsCheckedCloud', 'Display clouds', 3));

        this.Timeframe = Period.Min;
        super.InputParameter(new InputParameterCombobox('Timeframe', 'Timeframe', 4,
            [
                { Second: Period.Second },
                { Minute: Period.Min },
                { Hour: Period.Hour },
                { Day: Period.Day },
                { Week: Period.Week },
                { Month: Period.Month },
                { Year: Period.Year }
            ]));

        this.PeriodValue = 3;
        super.InputParameter(new InputParameterInteger('PeriodValue', 'Value Timeframe', 5, 1, 100));

        this.DataT = IndicatorDataType.Trade;
        super.InputParameter(new InputParameterCombobox('DataT', 'DataType Timeframe', 6,
            [
                { Ask: IndicatorDataType.Ask },
                { Bid: IndicatorDataType.Bid },
                { Trade: IndicatorDataType.Trade }
            ]));

        this.timeFrameData = null;
        this.historyLoaded = false;
        this.isProcessing = false;
        this.isSameTF = false;

        this.DownTrendColor = Color.OrangeRed;
        this.UpTrendColor = Color.CadetBlue;
    }

    public Init (): void {
        this.SetIndexDrawBegin(0, this.AtrPeriod);
        this.isSameTF = false;
    };

    public override GetIndicatorShortName (): string {
        return 'SUPERTRENDMTV3(' + this.AtrPeriod + ')';
    };

    public override getCustomName (): string {
        return 'SUPERTRENDMTV3 (' + this.ProjectName + ')';
    };

    public async RefreshPromise (): Promise<void> {
        this.isSameTF = false;
        await super.RefreshPromise()
            .then(() => {
                const cd = this.CurrentData;
                let result: any = null;
                if (this.Timeframe * this.PeriodValue == cd?.Period) {
                    this.isSameTF = true;
                } else {
                    result = this.GetHistoricalDataPromise(new HistoricalDataRequest({
                        Instrument: cd?.Instrument,
                        Period: this.Timeframe * this.PeriodValue,
                        DataType: this.DataT,
                        fromUTC: cd ? new Date(cd.FirstBarOpenTime) : undefined,
                        toUTC: new Date(DateTimeUtils.DateTimeUtcNow().getTime() + TimeSpan.FromDays(1).getTime())
                    }));
                }
                return result;
            })
            .then((LoadedHistory) => {
                this.timeFrameData = LoadedHistory;

                if (LoadedHistory.Count === 0) {
                    console.log('DataType: ' + this.DataT.toString() + ' does not available in the TF');
                    return;
                }
                this._ATR1 = this.Indicators.iATR(this.timeFrameData, this.AtrPeriod);

                this.AppendInternalIndicators(this._ATR1, this.timeFrameData);

                this.Process(this.CurrentData.Count - 1);

                this.historyLoaded = true;
            });
    };

    public Process (count: number | null = null): void {
        if (count == null) {
            this.SetMTFLine(0);
        } else {
            for (let i = count; i >= 0; i--) {
                this.SetMTFLine(i);
            }
        }
    };

    public SetMTFLine (barIndex): void {
        const currentBarTime = this.CurrentData.Time(barIndex);

        const interval = this.timeFrameData.FindInterval(currentBarTime);

        if (interval >= 0) {
            const offset = this.timeFrameData.HistoryCount - 1 - interval;
            const middle1 = (this.timeFrameData.GetPrice(PriceType.High, offset) + this.timeFrameData.GetPrice(PriceType.Low, offset)) / 2;

            this._down1 = middle1 + (this.DigitM * this._ATR1.GetValue());

            this._up1 = middle1 - (this.DigitM * this._ATR1.GetValue());

            if (this.timeFrameData.GetPrice(PriceType.Close, offset) > this._prevUP1) { this._currTrend1 = 1; } else if (this.timeFrameData.GetPrice(PriceType.Close, offset) < this._prevDown1) { this._currTrend1 = -1; } else { this._currTrend1 = this._prevTrend1; }

            if (this._currTrend1 > 0 && this._up1 < this._prevDown1 && this._currTrend1 <= this._prevTrend1) { this._up1 = this._prevDown1; }
            if (this._currTrend1 < 0 && this._down1 > this._prevUP1 && this._currTrend1 >= this._prevTrend1) { this._down1 = this._prevUP1; }

            if (this._currTrend1 === 1) { this.SetValue(1, barIndex, this._up1); } else if (this._currTrend1 === -1) { this.SetValue(1, barIndex, this._down1); }

            if (this.IsCheckedCloud) {
                this.SetCloud(0, 0, this.GetValue(0, 0), this.GetValue(1, 0), Color.FromHexToArgb(60, this.DownTrendColor), Color.FromHexToArgb(60, this.UpTrendColor), GradientMode.Simple);
            }

            this._prevUP1 = this._down1;
            this._prevDown1 = this._up1;
            this._prevTrend1 = this._currTrend1;
        }
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this._ATR = this.Indicators.iATR(this.CurrentData, this.AtrPeriod);

        return [this._ATR];
    };

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.AtrPeriod);
    };

    public NextBar (): void {
        super.NextBar();

        this._prevUP = this._down;
        this._prevDown = this._up;
        this._prevTrend = this._currTrend;
    };

    public OnQuote (): void {
        super.OnQuote();

        const middle = (this.CurrentData.GetPrice(PriceType.High) + this.CurrentData.GetPrice(PriceType.Low)) / 2;
        this._down = middle + (this.Digit * this._ATR.GetValue());
        this._up = middle - (this.Digit * this._ATR.GetValue());

        if (this.CurrentData.GetPrice(PriceType.Close) > this._prevUP) { this._currTrend = 1; } else if (this.CurrentData.GetPrice(PriceType.Close) < this._prevDown) { this._currTrend = -1; } else { this._currTrend = this._prevTrend; }

        if (this._currTrend > 0 && this._up < this._prevDown && this._currTrend <= this._prevTrend) { this._up = this._prevDown; }
        if (this._currTrend < 0 && this._down > this._prevUP && this._currTrend >= this._prevTrend) { this._down = this._prevUP; }

        if (this._currTrend === 1) {
            this.SetValue(0, this._up);
        } else {
            this.SetValue(0, this._down);
        }

        if (this.historyLoaded && !this.isSameTF) {
            this.Process();
        } else if (this.isSameTF) {
            if (this._currTrend === 1) { this.SetValue(1, this._up); } else { this.SetValue(1, this._down); }
        }
    };
}
