// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Rectangle } from '../../../Commons/Geometry';
import { datePickerDropDownHandler } from '../../../Utils/AppHandlers';
import { TerceraDatePickerDropDownTemplate } from '../../../templates.js';
import { Resources } from '../../../Localizations/Resources';
import { ContainerControl } from '../ContainerControl';
import { TerceraMenu } from '../TerceraMenu';

export class TerceraDatePickerDropDown extends ContainerControl {
    public calendar: any = null;
    public callback: any;

    public override getType (): string { return 'TerceraDatePickerDropDown'; };

    public override oncomplete (): void {
        super.oncomplete();
        this.onDateChanged = this.onDateChanged.bind(this);

        this.calendar = this.Controls.dtpicker;
    }

    public onDateChanged (formattedDate, date, inst): void {
        if (!isNullOrUndefined(this.callback)) {
            this.callback(date);
        }

        this.Hide();
    }

    public Show (date, callback, x: number, y: number, additionalParams): void {
        const picker = this.calendar.picker;

        picker.view = 'days';

        const dateCpy = new Date();
        dateCpy.setTime(date.getTime());

        picker.date = dateCpy;
        picker.selectDate(dateCpy);

        picker.update('minDate',
            additionalParams?.minDate
                ? additionalParams.minDate
                : '');

        picker.update('maxDate',
            additionalParams?.maxDate
                ? additionalParams.maxDate
                : '');

        this.callback = callback;
        picker.update('onSelect', this.onDateChanged);

        picker.show();

        const newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(x, y, 200, 200), null);

        void this.set({
            left: newCoord.newX,
            top: newCoord.newY,
            visible: true
        });
    }

    public localize (): void {
        if (isNullOrUndefined(this.calendar)) return;
        this.calendar.picker.update('language',
            {
                days: [
                    Resources.getResource('general.Sunday'),
                    Resources.getResource('general.Monday'),
                    Resources.getResource('general.Tuesday'),
                    Resources.getResource('general.Wednesday'),
                    Resources.getResource('general.Thursday'),
                    Resources.getResource('general.Friday'),
                    Resources.getResource('general.Saturday')
                ],
                daysShort: [
                    Resources.getResource('general.day7.Su'),
                    Resources.getResource('general.day1.Mo'),
                    Resources.getResource('general.day2.Tu'),
                    Resources.getResource('general.day3.We'),
                    Resources.getResource('general.day4.Th'),
                    Resources.getResource('general.day5.Fr'),
                    Resources.getResource('general.day6.Sa')
                ],
                daysMin: [
                    Resources.getResource('general.day7.Su'),
                    Resources.getResource('general.day1.Mo'),
                    Resources.getResource('general.day2.Tu'),
                    Resources.getResource('general.day3.We'),
                    Resources.getResource('general.day4.Th'),
                    Resources.getResource('general.day5.Fr'),
                    Resources.getResource('general.day6.Sa')
                ],
                months: [
                    Resources.getResource('general.monthFullName.1'),
                    Resources.getResource('general.monthFullName.2'),
                    Resources.getResource('general.monthFullName.3'),
                    Resources.getResource('general.monthFullName.4'),
                    Resources.getResource('general.monthFullName.5'),
                    Resources.getResource('general.monthFullName.6'),
                    Resources.getResource('general.monthFullName.7'),
                    Resources.getResource('general.monthFullName.8'),
                    Resources.getResource('general.monthFullName.9'),
                    Resources.getResource('general.monthFullName.10'),
                    Resources.getResource('general.monthFullName.11'),
                    Resources.getResource('general.monthFullName.12')
                ],
                monthsShort: [
                    Resources.getResource('general.month.1'),
                    Resources.getResource('general.month.2'),
                    Resources.getResource('general.month.3'),
                    Resources.getResource('general.month.4'),
                    Resources.getResource('general.month.5'),
                    Resources.getResource('general.month.6'),
                    Resources.getResource('general.month.7'),
                    Resources.getResource('general.month.8'),
                    Resources.getResource('general.month.9'),
                    Resources.getResource('general.month.10'),
                    Resources.getResource('general.month.11'),
                    Resources.getResource('general.month.12')
                ],
                dateFormat: 'dd.mm.yyyy',
                firstDay: 1
            });
    }

    public Hide (): void {
        void this.set('visible', false);
        this.calendar.picker.update('onSelect', null);
        delete this.callback;
        this.calendar.picker.hide();
    }

    public static DatePickerDropDownHandlerInitialize (): void {
        datePickerDropDownHandler.Show = function (date, callback, x, y, additionalParams) { };
        datePickerDropDownHandler.get = () => { return false; };
        datePickerDropDownHandler.Hide = () => { return false; };
        datePickerDropDownHandler.localize = () => { return false; };
        datePickerDropDownHandler.Init = () => {
            const _datePickerDropDown = new TerceraDatePickerDropDown();
            datePickerDropDownHandler.get = (key) => { return _datePickerDropDown.get(key); };
            datePickerDropDownHandler.Hide = _datePickerDropDown.Hide.bind(_datePickerDropDown);
            datePickerDropDownHandler.Show = _datePickerDropDown.Show.bind(_datePickerDropDown);
            datePickerDropDownHandler.localize = _datePickerDropDown.localize.bind(_datePickerDropDown);
        };
    }
}

ContainerControl.extendWith(TerceraDatePickerDropDown, {
    el: '#datePickerDropDownContainer',
    template: TerceraDatePickerDropDownTemplate,
    data: function () { return { visible: false }; }
});
