// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PriceFormatter } from '../../../../Utils/Instruments/PriceFormatter';
import { MathUtils } from '../../../../Utils/MathUtils';

class _GreeksFormatter {
    private readonly NOT_AVAILABLE = '---';
    private readonly INFINITY = '∞';
    public readonly GREEKS_PERCISION = 4;
    public readonly IV_PERCISION = 3;

    public roundGreek (greek: number): number {
        if (isNaN(greek)) {
            return NaN;
        }
        const precision = MathUtils.GetValuePrecision(greek, 2, this.GREEKS_PERCISION);
        return MathUtils.Round(greek, precision);
    }

    public roundIV (iv: number): number {
        if (isNaN(iv)) {
            return NaN;
        }
        return MathUtils.Round(iv, this.IV_PERCISION);
    }

    public formatGreek (greek: number, precision: number = undefined, withourZeroes: boolean = false): string {
        if (isNaN(greek) || greek === 0) {
            return this.NOT_AVAILABLE;
        }
        const formatPrecision = isValidNumber(precision) ? precision : MathUtils.GetValuePrecision(greek, 2, this.GREEKS_PERCISION);
        return this.formatValue(greek, formatPrecision, withourZeroes);
    }

    public formatIV (iv: number, precision: number = undefined, withourZeroes: boolean = false): string {
        if (isNaN(iv) || iv === 0) {
            return this.NOT_AVAILABLE;
        }
        if (!isFinite(iv)) {
            return this.INFINITY;
        }
        const formatPrecision = isValidNumber(precision) ? precision : this.IV_PERCISION;
        const formattedValue = this.formatValue(iv, formatPrecision, withourZeroes);
        return `${formattedValue} %`;
    }

    private formatValue (value: number, precision: number, withourZeroes: boolean): string {
        const roundedValue = MathUtils.Round(value, precision);
        return withourZeroes ? PriceFormatter.formatPriceWithoutZeroes(roundedValue, precision) : PriceFormatter.formatPrice(roundedValue, precision);
    }
}

export const GreeksFormatter = new _GreeksFormatter();
