// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
const CONST_THEME_NAME = 'themeName';
const CONST_VIEWED_NEWS = 'viewedNews';
const CONST_LAST_CONNECTION_NAME = 'lastConnectionName';
const CONST_TOKEN_FOR_PORTFOLIO = 'TokenForPortfolio';
const CONST_LOCAL_STORAGE_SELECTED_CARD = 'notifPanelSelCardID';
const CONST_LANGUAGE = 'language';
const CONST_CONNECTION_SETTINGS = 'cs';

class _LocalStorage {
    credentialsStorage: any = {};
    webMobileDetailsGroupItemStateMap = new Map<number, boolean>();

    getThemeName (): string {
        return localStorage.getItem(CONST_THEME_NAME);
    }

    setThemeName (themeName): void {
        localStorage.setItem(CONST_THEME_NAME, themeName);
    }

    getViewedNews (): string {
        return localStorage.getItem(CONST_VIEWED_NEWS);
    }

    setViewedNews (jsonViewedNews): void {
        localStorage.setItem(CONST_VIEWED_NEWS, jsonViewedNews);
    }

    getLastConnectionName (): string {
        return localStorage.getItem(CONST_LAST_CONNECTION_NAME);
    }

    setLastConnectionName (connectionName): void {
        localStorage.setItem(CONST_LAST_CONNECTION_NAME, connectionName);
    }

    setTokenForPortfolio (token): void {
        localStorage.setItem(CONST_TOKEN_FOR_PORTFOLIO, token);
    }

    getTokenForPortfolio (): string {
        return localStorage.getItem(CONST_TOKEN_FOR_PORTFOLIO);
    }

    removeTokenForPortfolio (): void {
        localStorage.removeItem(CONST_TOKEN_FOR_PORTFOLIO);
    }

    getNotificationPanelSelectedCards (): string {
        return localStorage.getItem(CONST_LOCAL_STORAGE_SELECTED_CARD);
    }

    setNotificationPanelSelectedCards (selectedId): void {
        localStorage.setItem(CONST_LOCAL_STORAGE_SELECTED_CARD, selectedId);
    }

    getLanguage (): string {
        return localStorage.getItem(CONST_LANGUAGE);
    }

    setLanguage (language): void {
        localStorage.setItem(CONST_LANGUAGE, language);
    }

    getWebMobileDetailsGroupItemState (): Map<number, boolean> {
        return this.webMobileDetailsGroupItemStateMap;
    }

    setWebMobileDetailsGroupItemState (stateMap: Map<number, boolean>): void {
        this.webMobileDetailsGroupItemStateMap = stateMap;
    }

    setConnectionSettings (connectionName: string, login: string, pass: string, customerNo: string): void {
        const objTsave: any = {};
        if (login) {
            objTsave.u = login;
        }

        if (pass != null) {
            objTsave.ep = pass;
        }

        if (customerNo) {
            objTsave.cN = customerNo;
        }

        this.credentialsStorage[connectionName] = objTsave;
        localStorage.setItem(CONST_CONNECTION_SETTINGS, JSON.stringify(this.credentialsStorage));
    }

    getConnectionSettings (name: string): [string, string | null, string] {
        const c = this.credentialsStorage[name];
        if (c != null) {
            const login = c.u || '';
            const encryptedPass = null; // c.ep ?? null; // commented out due to fix already saved passwords #122310 + #122295
            const customerNo = c.cN || '';
            return [login, encryptedPass, customerNo];
        }
        return ['', null, ''];
    }

    loadConnectionSettings (): void {
        try {
            const cs = localStorage.getItem(CONST_CONNECTION_SETTINGS);
            if (!cs) {
                const lcn = localStorage.getItem(CONST_LAST_CONNECTION_NAME);
                if (lcn) {
                    const u = localStorage.getItem('user') || '';
                    const cN = localStorage.getItem('customerNo') || '';
                    const nD = {};
                    const objts: any = { u };
                    if (cN) {
                        objts.cN = cN;
                    }

                    nD[lcn] = objts;
                    this.credentialsStorage = nD;
                }
                localStorage.removeItem('user');
                localStorage.removeItem('customerNo');
                localStorage.setItem('cs', JSON.stringify(this.credentialsStorage));
            } else {
                this.credentialsStorage = JSON.parse(cs);
            }

            localStorage.removeItem('pwd');
        } catch (ex) {

        }
    }
}

export const LocalStorage = new _LocalStorage();
