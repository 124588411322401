// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraDateSelectorDropDownTemplate } from '../../../templates.js';
import { Resources } from '@shared/localizations/Resources';
import { TerceraWindowBase } from '../../screen/TerceraWindowBase';
import { BOTTOM_WINDOWS_MARGIN } from '../../UtilsClasses/SizeConstants';
import { ControlEvents } from '../Control';
import { FullScreenManager } from '../../UtilsClasses/FullScreenManager';

export class DateSelectorButton {
    text: string;
    callback: (date: Date) => void;
    style: string;

    constructor (text: string, callback: (date: Date) => void, style = 'js-button-gray25') {
        this.style = style;
        this.callback = callback;
        this.text = text;
    }
}

export class TerceraDateSelectorDropDown extends TerceraWindowBase {
    public torndown: any;

    constructor () { super(); }

    public override getType (): string { return 'TerceraDateSelectorDropDown'; }

    public override oncomplete (): void {
        super.oncomplete();

        this.on('dateBtnClick', () => { void this.set({ ShowDate: true }); });
        this.on('timeBtnClick', () => { void this.set({ ShowDate: false }); });
        this.on('buttonClick', this.buttonClick);
        this.on('onHoursChanged', this.onHoursChanged);
        this.on('onMinutesChanged', this.onMinutesChanged);
        this.setFocus();

        const picker = this.Controls.dtPicker.picker;
        picker.update('maxDate', '');
        picker.update('minDate', new Date());
        this.localize();

        this.observe('date', (newValue) => {
            if (newValue) {
                this.Controls.dtPicker.picker.selectDate(newValue);
                const h = newValue.getHours();
                const m = newValue.getMinutes();
                void this.set({ hours: h, minutes: m, selectDate: newValue });
            }
        });
        picker.update('onRenderCell', this.datePickerOnRenderCell.bind(this));
        picker.update('onSelect', this.onSelectDateTo.bind(this));
        // this.on(Control.Events.LostFocus, function () { this.close(); }.bind(this));
        this.on(ControlEvents.LostFocus, this.close);
        FullScreenManager.fullscreenExit.Subscribe(this.close, this);
    }

    public setMinMax (minDate: Date, maxDate: Date): void {
        const picker = this.Controls?.dtPicker?.picker;
        picker?.update('minDate', minDate ?? '');
        picker?.update('maxDate', maxDate ?? '');
    }

    public datePickerOnRenderCell (date: Date, cellType: string): { classes: string } {
        const nowDate = new Date();
        const selectedDate = this.get('selectDate');
        if (this.isDayInPeriodRange(date, nowDate, selectedDate)) {
            return { classes: 'datepicker--days-range' };
        }
    }

    public onSelectDateTo (): void {
        const picker = this.Controls.dtPicker.picker;
        const pickerArr = picker.selectedDates;
        const selectDate = pickerArr.length ? pickerArr[0] : pickerArr.currentDate;
        void this.set({ selectDate });
    }

    public override close (): void {
        // Дикая херня!!!
        if (this.torndown) {
            return;
        }
        //

        super.close();
        this.fire('timeSelectorLookupClosed');
    }

    public override dispose (): void {
        FullScreenManager.fullscreenExit.UnSubscribe(this.close, this);

        super.dispose();
    }

    public buttonClick (context, callback: (date: Date) => void): void {
        const picker = this.Controls.dtPicker.picker;
        const dateFromArr = picker.selectedDates;
        const dateFrom = dateFromArr.length ? dateFromArr[0] : picker.currentDate;

        const h = this.get('hours');
        const m = this.get('minutes');
        dateFrom.setHours(h);
        dateFrom.setMinutes(m);

        callback(dateFrom);

        this.close();
    }

    public setCorrectLocation (x: number, y: number): void {
        const width = this.get('width'); const height = this.get('height');

        if (x + width > window.innerWidth) {
            x = window.innerWidth - width - 2;
        }

        const margin = FullScreenManager.IsFullScreenMode ? 0 : BOTTOM_WINDOWS_MARGIN;
        if (y + height > window.innerHeight - margin) {
            y = window.innerHeight - margin - height - 2;
        }

        void this.set({ left: x, top: y });
    }

    public override localize (): void {
        void this.set({
            buttonDateText: Resources.getResource('property.IsDateVisible'),
            buttonTimeText: Resources.getResource('property.IsTimeVisible'),
            hoursLabel: Resources.getResource('timeSelector.Hours'),
            minutesLabel: Resources.getResource('timeSelector.Minutes')
        });
    }

    public onHoursChanged = (context, newHours: number): void => {
        void this.set({ hours: newHours });
    };

    public onMinutesChanged = (context, newMinutes: number): void => {
        void this.set({ minutes: newMinutes });
    };

    private isDayInPeriodRange (date: Date, nowDate: Date, selectedDate: Date): boolean {
        if (nowDate < selectedDate) {
            return date >= nowDate && date <= selectedDate;
        } else {
            return date >= selectedDate && date <= nowDate;
        }
    }
}

TerceraWindowBase.extendWith(TerceraDateSelectorDropDown, {
    data: function () {
        return {
            resizable: false,
            width: 220,
            showHeader: false,
            showFooter: false,
            ShowDate: true,

            buttonDateText: '',
            buttonTimeText: '',
            applyBtnText: '',
            hoursLabel: '',
            minutesLabel: '',

            hours: 0,
            minutes: 0,

            date: new Date(),
            selectedDate: new Date(),
            isOnlyDate: false,
            buttons: [],

            labelsForMinutesSlider: new Map([[15, '15'], [30, '30'], [45, '45']]),
            labelsForHourSlider: new Map([[3, '3'], [6, '6'], [9, '9'], [12, '12'], [15, '15'], [18, '18'], [21, '21']])
        };
    },
    partials: { bodyPartial: TerceraDateSelectorDropDownTemplate }
});
