// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectAlertMessage } from '../../../../Utils/DirectMessages/DirectMessagesImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class AlertMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlertMessage[] {
        const msg = new DirectAlertMessage();
        const group_one = fs.GetGroup(1);

        msg.AccountId = fs.GetValue(FieldsFactory.FIELD_ACCOUNT_ID);
        msg.UserId = fs.GetValue(FieldsFactory.FIELD_USER_ID);
        msg.InstrumentTradableID = fs.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        msg.RouteId = fs.GetValue(FieldsFactory.FIELD_ROUTE_ID);

        msg.AlertId = fs.GetValue(FieldsFactory.FIELD_ALERT_ID);
        msg.AlertType = fs.GetValue(FieldsFactory.FIELD_ALERT_TYPE);
        msg.AlertCondition = fs.GetValue(FieldsFactory.FIELD_ALERT_CONDITION);
        msg.AlertValue = fs.GetValue(FieldsFactory.FIELD_ALERT_VALUE);
        msg.AlertImportance = fs.GetValue(FieldsFactory.FIELD_ALERT_IMPORTANCE);
        msg.Date = fs.GetValue(FieldsFactory.FIELD_DATE);
        msg.AlertAfterExecute = fs.GetValue(FieldsFactory.FIELD_ALERT_AFTER_EXECUTE);
        msg.AlertAction = fs.GetValue(FieldsFactory.FIELD_ALERT_ACTION);
        msg.AlertNotification = fs.GetValue(FieldsFactory.FIELD_ALERT_NOTIFICATION);
        msg.Text = fs.GetValue(FieldsFactory.FIELD_TEXT);

        msg.AlertEvent = fs.GetValue(FieldsFactory.FIELD_ALERT_EVENT);
        msg.AlertStatus = fs.GetValue(FieldsFactory.FIELD_ALERT_STATUS);

        let paramsOb = null;
        if (group_one) {
            paramsOb = {};
            // paramsOb.order_received_by = group_one.GetValue(FieldsFactory.FIELD_ORDER_RECEIVED_BY);
            paramsOb.stopPrice = group_one.GetValue(FieldsFactory.FIELD_STOP_PRICE);
            paramsOb.quantity = group_one.GetValue(FieldsFactory.FIELD_AMOUNT);
            paramsOb.cash = group_one.GetValue(FieldsFactory.FIELD_CASH_ORDER_QTY);
            paramsOb.productType = group_one.GetValue(FieldsFactory.FIELD_PRODUCT_TYPE);
            paramsOb.OrderType = group_one.GetValue(FieldsFactory.FIELD_ORDER_TYPE);
            paramsOb.side = group_one.GetValue(FieldsFactory.FIELD_OPERATION_TYPE);
            // paramsOb.date = group_one.GetValue(FieldsFactory.FIELD_DATE);
            paramsOb.limitPrice = group_one.GetValue(FieldsFactory.FIELD_PRICE);
            // paramsOb.is_by_broker = group_one.GetValue(FieldsFactory.FIELD_IS_BY_BROKER);
            // paramsOb.boundtoorderid = group_one.GetValue(FieldsFactory.FIELD_BOUNDTOORDERID);
            paramsOb.tif = group_one.GetValue(FieldsFactory.FIELD_VALIDITY);
            paramsOb.tradableInstrumentId = group_one.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
            // paramsOb.comment = group_one.GetValue(FieldsFactory.FIELD_COMMENT);
            paramsOb.routeId = group_one.GetValue(FieldsFactory.FIELD_ROUTE_ID);
            // paramsOb.is_open = group_one.GetValue(FieldsFactory.FIELD_IS_OPEN);
            paramsOb.accountId = group_one.GetValue(FieldsFactory.FIELD_ACCOUNT_ID);
            // paramsOb.range = group_one.GetValue(FieldsFactory.FIELD_RANGE);
            // paramsOb.client_order_id = group_one.GetValue(FieldsFactory.FIELD_CLIENT_ORDER_ID);
            // paramsOb.client_panel_id = group_one.GetValue(FieldsFactory.FIELD_CLIENT_PANEL);
            // paramsOb.derivative_position_id = group_one.GetValue(FieldsFactory.FIELD_DERIVATIVE_POSITION_ID);
            paramsOb.expire_at = group_one.GetValue(FieldsFactory.FIELD_EXPIRE_AT);
            // paramsOb.poa_user_id = group_one.GetValue(FieldsFactory.FIELD_POA_USER_ID);
            // paramsOb.order_algorithm = group_one.GetValue(FieldsFactory.FIELD_ORDER_ALGORITHM);
            paramsOb.disclosedQuantity = group_one.GetValue(FieldsFactory.FIELD_DISCLOSED_QUANTITY);

            paramsOb.slType = group_one.GetValue(FieldsFactory.FIELD_SL_PRICE_TYPE);
            paramsOb.tpType = group_one.GetValue(FieldsFactory.FIELD_TP_PRICE_TYPE);
            paramsOb.slPrice = group_one.GetValue(FieldsFactory.FIELD_SL_PRICE);
            paramsOb.tpPrice = group_one.GetValue(FieldsFactory.FIELD_TP_PRICE);
            paramsOb.slLimitPrice = group_one.GetValue(FieldsFactory.FIELD_SL_LIMIT_PRICE);

            paramsOb.trailingStopOffset = group_one.GetValue(FieldsFactory.FIELD_TR_OFFSET);
        }
        msg.OrderPlaceParametrs = paramsOb;
        return [msg];
    }
}
