// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { type OperationType } from '../Trading/OperationType';
import { Message } from './Message';

export class DirectAccountOperationInfoMessage extends DirectMessageBaseClass {
    public RequestId: string;
    public AccountId: string;
    public CounterId: string;
    public AssetId: number;
    public Comment: string;

    public LastUpdateTime: Date | null;
    public OperationType: OperationType;
    public Mode: number;
    public Amount: number;
    public CrossPrice: number;
    public ReqerenceId: number;

    constructor () {
        super(Message.CODE_ACCOUNT_OPERATION_REQUEST_INFO_MESSAGE);
    }
}
