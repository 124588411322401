// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraSymbolLookupBaseDataProvider } from '../../Commons/NoNFixedListCore';
import { CustomEvent } from '../../Utils/CustomEvents';
import { ADD_WL, BUY, OPEN_CHART, REMOVE_WL, SELL } from '../Controls/elements/InvestingLookupDropDownFormItem';
import { closeContextMenuEvent, openContextMenu, updateContextMenu } from '../Controls/elements/InvestingMenu';
import { ProcessChartVisible } from '../Investing';
import { InvestingLookupDropDownFormTemplate } from '../templates.js';
import { InvestingInstrumentLookupCore } from '../Utils/InvestingInstrumentLookUpCore';
import { delay } from '../Utils/InvestingUtils';
import { WL_ListController } from '../Utils/WL_ListController';
import { ComboBoxController } from './ComboBoxController';
import { DropDownInstrumentTypeFilterController } from './DropDownInstrumentTypeFilterController';
import { openOrderEntry } from './InvestingOE';
import Ractive from 'ractive';

export const closeLookupDropDownFormEvent = new CustomEvent();
export let openLookupDropDownForm = null;
export let closeLookupDropDownForm = null;

export class InvestingLookupDropDownForm extends Ractive {
    public dataProvider: TerceraSymbolLookupBaseDataProvider;
    public delayhandler: any;
    public ComboBoxController: ComboBoxController;
    public DropDownInstrumentTypeFilterController: DropDownInstrumentTypeFilterController;
    public currentNameFilter: any;

    constructor () { super(); }

    public getType (): string { return 'InvestingLookupDropDownForm'; }

    public override oninit (): void {
        this.on('comboBoxArrowClick', this.openComboBoxMenu);
        this.on('processRowEvent', this.processRowEvent);
    }

    public override oncomplete (): void {
        openLookupDropDownForm = (x, y, reg_handler) => { this.Show(x, y, reg_handler); };
        closeLookupDropDownForm = () => { this.Close(); };

        this.dataProvider = new TerceraSymbolLookupBaseDataProvider();

        this.delayhandler = delay(this.beforePopulate.bind(this), 300);

        this.ComboBoxController = new ComboBoxController(updateContextMenu);
        this.DropDownInstrumentTypeFilterController = new DropDownInstrumentTypeFilterController(this.UpdateNavigation.bind(this));
        this.UpdateNavigation();
    }

    public beforePopulate (): void {
    // if (this.currentNameFilter === this.oldNameFilter)
    // {
    //     if (list)
    //         this.items = list;

        //     this.fillTree(true)
        //     return
        // }

        // let me = this;
        // this.oldNameFilter = this.currentNameFilter;

        if (this.dataProvider) {
        // let typeFilterSet = this.getCurrentTypesFilter()
            void this.dataProvider.getInstrumentsList(this.currentNameFilter, null, null, false)
                .then((instruments) => {
                    this.populateForm(instruments);
                    console.dir(instruments);
                // me.beforePopulate(instruments);
                });
        }
    }

    public populateForm (instruments): void {
        const resultObj = InvestingInstrumentLookupCore.GetItems(instruments, this.currentNameFilter);
        void this.set({ instrumentArray: resultObj.getInstruments() });
    }

    public openComboBoxMenu (): void {
        const fromNavigationComboBox = document.getElementById('navigationComboBox');
        void this.set({
            activeNavigationComboBox: true,
            disabledItemsDropDown: true
        });

        const posElement = fromNavigationComboBox.getBoundingClientRect();
        const height = posElement.height + 2;
        const posX = posElement.left + window.pageXOffset;
        const posY = posElement.top + window.pageYOffset + height;

        const items = this.ComboBoxController.getMenuItems();
        const additionalParams = {
            width: posElement.width
        };

        openContextMenu(posX, posY, items, additionalParams);
        closeContextMenuEvent.Subscribe(this.closeMenu, this);
    }

    public closeMenu (): void {
        closeContextMenuEvent.UnSubscribe(this.closeMenu, this);
        void this.set({
            activeNavigationComboBox: false,
            disabledItemsDropDown: false
        });
    }

    public Show (posX: number, posY: number, reg_handler): void {
        const resultObj = InvestingInstrumentLookupCore.GetItems(InvestingInstrumentLookupCore.PrepareInstruments());
        this.ComboBoxController.setMenuItems(resultObj.getExchanges());
        void this.set({
            left: posX,
            top: posY,
            showLookupDropDownForm: true,
            instrumentArray: resultObj.getInstruments()
        });

        if (reg_handler) {
            reg_handler((nt) => { this.newTextIn(nt); }, true);
        }
    }

    public newTextIn (newText): void {
        this.currentNameFilter = newText;
        this.delayhandler();
        console.log(newText);
    }

    public blackout_BtnClick (ev): void {
        if (ev.original.target !== ev.original.currentTarget) {
            return;
        }

        this.Close();
    }

    public Close (): void {
        void this.set({ showLookupDropDownForm: false });
        closeLookupDropDownFormEvent.Raise();
    }

    public processRowEvent (row, evtType): void {
        const instrument = row.Instrument;
        switch (evtType) {
        case OPEN_CHART:
            ProcessChartVisible(true, instrument);
            break;
        case ADD_WL:
            WL_ListController.AddInstrument(instrument);
            break;
        case REMOVE_WL:
            WL_ListController.RemoveInstrument(instrument);
            break;
        case BUY:
            openOrderEntry(true, row, this.get('accountItem'));
            break;
        case SELL:
            openOrderEntry(false, row, this.get('accountItem'));
            break;
        }
    }

    public UpdateNavigation (): void {
        void this.set({ arrInstrNavigation: this.DropDownInstrumentTypeFilterController.getNavigation() });
    }
}

Ractive.extendWith(InvestingLookupDropDownForm, {
    template: InvestingLookupDropDownFormTemplate,
    data: function () {
        return {
            showLookupDropDownForm: false,
            activeNavigationComboBox: false,
            disabledItemsDropDown: false,
            arrInstrNavigation: [],
            instrumentArray: [],

            accountItem: null
        };
    }
});
