// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum EventType {
    None = 0,
    Info = 1,
    Exception = 2,
    Trading = 3,
    System = 4,
    Comment = 5,
    Updater = 6,
    Alerts = 7
}

export enum EventSource {
    None = 0,
    Out = 1,
    MarginCall = 2,
    Refused = 3,
    In = 4
}

export enum TradingEventType {
    Request = 0,
    Create = 1,
    Modify = 2,
    Fill = 3,
    MarginCall = 4,
    Remove = 5,
    Reject = 6,
    NotTrading = 7
}

export enum ErrorCodes {
    ProductRequestCantProcess = 432,
    ProductSubscriptionNoActiveAccounts = 436,
    ProductSubscriptionNotEnoughBalance = 437,
    ProductIsNoLongerRelevantForUse = 446
}
