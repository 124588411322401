// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ChartDataType } from '../Utils/ChartConstants';
import { CashItem } from '@shared/commons/cache/History/CashItem';
import { TerceraChartCashItemSeries } from './TerceraChartCashItemSeries';
import { type TerceraChartCashItemSeriesSettings } from './TerceraChartCashItemSeriesSettings';

export class TerceraChartOverlaySeries extends TerceraChartCashItemSeries {
    public ParentSeries: any = null;

    constructor (newCashItem: CashItem, from: Date, to: Date, settings: TerceraChartCashItemSeriesSettings, ParentSeries) {
        super(newCashItem, from, to, settings);
        /// <summary>
        /// Родительская серия, используется для расчета индексов
        /// </summary>
        this.ParentSeries = ParentSeries;

        this.RecalcIndices();
    }

    /// <summary>
    /// Пересчет индексов.
    /// Отталкиваемся от главной серии, чтобы решить проблему с дырками.
    /// </summary>
    public RecalcIndices (): void {
        const ParentSeries = this.ParentSeries;
        if (!ParentSeries) return;

        let bufferList = [];
        const bufferZero = [];
        bufferZero.push(0);

        // Нелинейная шкала времени - тики, ренко и т.д. - синхронизируем по количеству
        if (ParentSeries.NonLinearTimeline && this._cashItem.ChartDataType !== ChartDataType.HeikinAshi) {
            bufferList = ParentSeries.FIndexList;

            let currentIndex = this._cashItem.Count() - 1;

            for (let i = bufferList.length - 1; i >= 0; i--) {
                bufferList[i] = currentIndex;
                currentIndex--;

                if (currentIndex < 0) {
                    bufferList[i] = -1;
                }
            }
        }
        // Линейная шкала - синхронизируем по времени
        else {
            // copy from parent
            bufferList = this.ParentSeries.FIndexList.slice();

            // В дочернем кешитеме стартуем отсюда
            let currentIndex = this._cashItem.FindIntervalByTime(ParentSeries.LeftRelativeBorder);
            if (currentIndex < 0) {
                currentIndex = 0;
            }

            //
            const daysLeft = 60000 * 60 * 24;
            let averTime;
            let indexHole = 1;
            // Correction
            for (let i = 0; i < bufferList.length; i++) {
                let timeFromBase = Math.floor(this.ParentSeries.GetValue(i, CashItem.TIME_INDEX));
                let timeFromCurrent = Math.floor(this._cashItem.GetByConst(currentIndex, CashItem.TIME_INDEX));

                // Для дневок будем хитрить
                if (this._DayBasedData) {
                // correct base time
                    averTime = (timeFromBase + timeFromBase + this.RelativePeriodBar) / 2;
                    timeFromBase = averTime - (averTime % (daysLeft));

                    // correct current time
                    averTime = (timeFromCurrent + timeFromCurrent + this.RelativePeriodBar) / 2;
                    timeFromCurrent = averTime - (averTime % (daysLeft));
                }

                if (timeFromCurrent < timeFromBase) {
                    for (let j = currentIndex; currentIndex < this._cashItem.Count(); j++) {
                        currentIndex++;
                        timeFromCurrent = Math.floor(this._cashItem.GetByConst(currentIndex, CashItem.TIME_INDEX));

                        // correct current time
                        if (this._DayBasedData) {
                            averTime = (timeFromCurrent + timeFromCurrent + this.RelativePeriodBar) / 2;
                            timeFromCurrent = averTime - (averTime % (daysLeft));
                        }

                        if (timeFromCurrent >= timeFromBase) {
                            break;
                        }
                    }
                }

                if (timeFromCurrent === timeFromBase) {
                    bufferList[i] = currentIndex;
                    currentIndex++;
                } else {
                    bufferList[i] = -indexHole;
                    bufferZero.push(Math.floor(this.ParentSeries.GetValue(i, CashItem.TIME_INDEX)));
                    indexHole++;
                }
            }
        }

        // replace
        this.FIndexList = bufferList;
        this.ZeroTimes = bufferZero;
        this.dataBlocks = ParentSeries.dataBlocks;
        this.LeftRelativeBorder = ParentSeries.LeftRelativeBorder;
        this.RightRelativeBorder = ParentSeries.RightRelativeBorder;
    }
}
