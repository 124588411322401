// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum ProductType {
    General = 0,
    Intraday = 1,
    Delivery = 2,
    CorporateAction = 4
}

export function getProductTypeKey (productType: ProductType): string {
    switch (productType) {
    case ProductType.General:
        return 'General';
    case ProductType.Intraday:
        return 'Intraday';
    case ProductType.Delivery:
        return 'Delivery';
    case ProductType.CorporateAction:
        return 'CorporateAction';
    default:
        return null;
    }
}

export const ORDER_PARAMETER_PRODUCT_TYPE = 'productType';
