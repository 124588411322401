// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '../../../../../Utils/Account/AccountFeature';
import { Resources } from '../../../../../Localizations/Resources';
import { BaseSettings } from '../../../../Settings/BaseGeneralSettingsWrapper';
import { Account } from '../../../Account';
import { BaseOrderWarning } from './BaseOrderWarning';

export class AvailibleFundsLessWarning extends BaseOrderWarning {
    public getText (): string {
        const curAcc = this.orderEdit.account;
        const availibleFundsValue = BaseSettings.warnIfAvailibleFundsLesssValue;
        const marginAvailable = Account.GetAccountFeature(AccountFeature.MarginAvailable, curAcc, curAcc.assetBalanceDefault);
        if (BaseSettings.warnIfAvailibleFundsLess && availibleFundsValue > marginAvailable) {
            const formattedMargin = Account.GetAccountFeatureString(marginAvailable, AccountFeature.MarginAvailable, curAcc, curAcc.assetBalanceDefault);
            const formattedWarnMargin = Account.GetAccountFeatureString(availibleFundsValue, AccountFeature.MarginAvailable, curAcc, curAcc.assetBalanceDefault);
            const text = Resources.getResource('general.warnings.availibleFundsLessText', [formattedMargin, formattedWarnMargin]);
            return text;
        }

        return '';
    }
}
