// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum ExternalOrderStatus {
    New = 0,
    Applied = 1,
    PartiallyFilled = 2,
    Filled = 3,
    Rejected = 4,
    Cancelled = 5,
    CancelingForModification = 6,
    PendingModification = 7,
    PendingCancel = 8,
    Freeze = 9,
    WaitingMarket = 10,
    Unplaced = 11,
    Suspended = 12
}
