// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum AccountSeal {
    Unspecified = -1,
    Demo = 0,
    Live = 1,
    Contest = 2,
    Funded = 3,
    Challenge = 4
};

export enum AccountVisibilityType {
    VISIBILITY_FLAG_OWN = 1, // - собственный акаунт
    VISIBILITY_FLAG_GROUP = 2, // - видимость по группе
    VISIBILITY_FLAG_LINK = 4, // - видимость по линке
    VISIBILITY_FLAG_MAM = 8 // - видимость по мам группе
};
