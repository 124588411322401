// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { HotkeysKeyless } from './NamesEventHotkey ';

export class HotkeysItem {
    private _ActionName: string = '';
    private _Hotkey: string = HotkeysKeyless;
    private _TemporaryHotkey: string = '';

    constructor (actionName: string, hotkey: string) {
        this.ActionName = actionName;
        this.Hotkey = hotkey;
    }

    get ActionName (): string { return this._ActionName; };
    set ActionName (value: string) { this._ActionName = value; };

    get Hotkey (): string { return this._Hotkey; };
    set Hotkey (value: string) { this._Hotkey = value.replace(/\s*\+\s*/g, '+'); };

    get TemporaryHotkey (): string { return this._TemporaryHotkey; };
    set TemporaryHotkey (value: string) { this._TemporaryHotkey = value.replace(/\s*\+\s*/g, '+'); };
}
