// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Control } from '../Control';
import { HotkeysSettingsContainerTemplate } from '../../../templates';
import { HotkeysManager } from '@shared/commons/cache/Hotkeys/HotkeysManager';
import { PanelNames } from '../../UtilsClasses/FactoryConstants';
import { HotkeysGroupTrading } from '@shared/commons/cache/Hotkeys/NamesEventHotkey ';
import { Resources } from '@shared/localizations/Resources';
import { HotkeysEditControlEvents } from './HotkeysEditControl';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { type HotkeysItem } from '@shared/commons/cache/Hotkeys/HotkeysItem';

export class HotkeysSettingsContainer extends Control {
    public HotkeyValueChanged: CustomEvent = new CustomEvent();

    constructor () {
        super();
        const map = HotkeysManager.GetHotkeysMap();
        const groupItems = [];

        for (const key of map.keys()) {
            const group = new HotkeyGroup(this.getNameGroup(key), map.get(key));
            groupItems.push(group);
        }

        void this.set({ groupItems });
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.on(HotkeysEditControlEvents.ValueChanged, this.valueChangedEvent);
    }

    private getNameGroup (key: string): string {
        switch (key) {
        case PanelNames.ChartPanel:
            return Resources.getResource('panel.chart');
        case PanelNames.MarketDepthPanel:
            return Resources.getResource('panel.level2');
        case PanelNames.AdvancedOrderEntry:
            return Resources.getResource('panel.orderEntry');
        case HotkeysGroupTrading:
            return Resources.getResource('property.Trading');
        case PanelNames.OrdersPanel:
            return Resources.getResource('panel.orders');
        }
    };

    private valueChangedEvent (context, item: HotkeysItem, value: string): void {
        this.HotkeyValueChanged.Raise(this, item, value);
    }
}

Control.extendWith(HotkeysSettingsContainer, {
    template: HotkeysSettingsContainerTemplate,
    data: function () {
        return {
            groupItems: []
        };
    }
});

export class HotkeyGroup {
    public readonly GroupName: string = '';
    public readonly GroupItems = [];

    constructor (groupName: string, groupItems: any) {
        this.GroupName = groupName;
        this.GroupItems = groupItems;
    }
}
