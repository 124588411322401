// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type DirectSessionMessage } from '../DirectMessages/DirectSessionMessage';
import { HolidayDescription } from './HolidayDescription';
import { type SessionPeriod } from './SessionPeriod';
import { DateTime } from 'luxon';
import { type DayPeriods } from './DayPeriods';

export class Session {
    public readonly Id: number;
    public readonly Name: string;

    public BlockTrading: boolean;
    public TimeZoneID: string;
    public CurrentSessionPeriod: SessionPeriod | null = null;

    /**
     * Current sesion start of current day (with time) in browser timezone
     * includes TimeOffset
     * For example: 18.07.2022 17:00 (server day is 19.07.2022 00:00  -420 minutes (7 hours) TimeOffset)
     */
    public ServerBeginDayDate: Date | null;

    /**
     * @summary
     * The actual server session date (without TimeOffset).
     * For example: 19.07.2022 00:00 (see example above)
     */
    public ServerTodayDate: DateTime | null; // ServerStartTimeInSession in Windows // текущая дата в сессии

    /**
     * @summary
     * The current server date in the server's timezone (with TimeOffset).
     *
     * For example, for Tuesday 19.07 00:00
     * with a TimeOffset of -420 minutes in UTC -5 timezone,
     * the server will send 18.07 17:00 in session timezone.
     *
     * Used only for the on case in symbol info panel.
     */
    private ServerBeginDayDateTime: DateTime | null;

    public TimeOffset: number = 0;
    public FirstDayOfWeek: number = 1; // Monday by default (0 - Sunday, 1 - Monday, 2 - Tuesday, 3 - Wednesday, 4 - Thursday, 5 - Friday, 6 - Saturday)

    private _holidays = new Map<string, HolidayDescription>();
    private _sessionPeriods: SessionPeriod[] = [];

    public constructor (id: number, name: string) {
        this.Id = id;
        this.Name = name;
    }

    public newMessage (msg: DirectSessionMessage): void {
        this.BlockTrading = msg.blockTrading;
        if (!isNullOrUndefined(msg.timeZoneID)) {
            this.TimeZoneID = msg.timeZoneID;
        }

        if (!isNullOrUndefined(msg.timeOffset)) {
            this.TimeOffset = msg.timeOffset;
        }

        if (!isNullOrUndefined(msg.periods)) {
            this._sessionPeriods = msg.periods;
        }

        if (!isNullOrUndefined(msg.holidays)) {
            this._holidays = msg.holidays;
        }

        this.CurrentSessionPeriod = this.GetSessionPeriodByID(msg.currentPeriodId);

        if (!isNullOrUndefined(msg.serverBeginDayDate)) {
            this.ServerBeginDayDate = msg.serverBeginDayDate;
            this.ServerTodayDate = this.ConvertDateToSessionDateTime(this.ServerBeginDayDate).startOf('day');
            this.ServerBeginDayDateTime = DateTime.fromJSDate(this.ServerBeginDayDate, { zone: this.TimeZoneID });
        }

        if (!isNullOrUndefined(msg.firstDayOfWeek)) {
            this.FirstDayOfWeek = msg.firstDayOfWeek;
        }
    }

    public getSessionPeriods (): SessionPeriod[] {
        return this._sessionPeriods;
    }

    public getHolidays (): Map<string, HolidayDescription> {
        return this._holidays;
    }

    public ConvertDateToSessionDateTime (date: Date): DateTime {
        const datetimeInSessionTimeZone: DateTime = DateTime.fromMillis(date.getTime(), { zone: this.TimeZoneID });
        const datetimeInSessionTimeZoneWithOffset: DateTime = datetimeInSessionTimeZone.plus({ minutes: -this.TimeOffset });
        return datetimeInSessionTimeZoneWithOffset;
    }

    public ConvertSessionDateTimeToDate (sessDate: DateTime): Date {
        const datetimeInSessionTimeZoneWithOffset: DateTime = sessDate.plus({ minutes: this.TimeOffset });
        return datetimeInSessionTimeZoneWithOffset.toJSDate();
    }

    public AddTimeToSessionDate (sessDate: DateTime, sessTime: Date): DateTime {
        const sessionDay = sessDate.startOf('day');
        const sessionTime = sessionDay.plus({ milliseconds: sessTime.getTime() });
        return sessionTime;
    }

    public FindSessionPeriodsForDate (sessDate: DateTime): [SessionPeriod[], number] {
        let sessionPeriods = this._sessionPeriods;
        const holidayKey = HolidayDescription.createHolidayKey(sessDate.year, sessDate.month, sessDate.day);

        if (this._holidays.has(holidayKey)) {
            const holidayDesciption = this._holidays.get(holidayKey);
            if (holidayDesciption.IsShorted() && holidayDesciption.HasPeriods()) {
                sessionPeriods = holidayDesciption.GetHolidayPeriods();
            }
        }

        const dayOfWeek = sessDate.weekday % 7;// Sunday (7) -> 0
        return [sessionPeriods, dayOfWeek];
    }

    public GetSessionPeriodRange (date: Date, showExtendedSession: boolean): [Date, Date] {
        const dateTimeInSessionTimeZoneWithOffset: DateTime = this.ConvertDateToSessionDateTime(date);
        const [sessionPeriods, dayOfWeek] = this.FindSessionPeriodsForDate(dateTimeInSessionTimeZoneWithOffset);

        let allowedPeriods = sessionPeriods.filter(period => !period.isBeforeOrAfterMarket());
        if (!showExtendedSession) {
            allowedPeriods = allowedPeriods.filter(period => period.IsMain());
        }

        if (allowedPeriods.length === 0) { return [new Date(0), new Date(0)]; }

        const firstSessionPeriod = allowedPeriods[0];
        const lastSessionPeriod = allowedPeriods[allowedPeriods.length - 1];

        const startFirstSession = firstSessionPeriod.GetBegin(dayOfWeek);
        const endLastSession = lastSessionPeriod.GetEnd(dayOfWeek);

        const start: DateTime = this.AddTimeToSessionDate(dateTimeInSessionTimeZoneWithOffset, startFirstSession);
        const end: DateTime = this.AddTimeToSessionDate(dateTimeInSessionTimeZoneWithOffset, endLastSession);

        return [this.ConvertSessionDateTimeToDate(start), this.ConvertSessionDateTimeToDate(end)];
    }

    public FindSessionPeriod (datetimeUtc: Date): SessionPeriod | undefined {
        // сначала проверяем текущую сессию (over99% котировок принадлежат ей - для производительности)
        const currentPeriod: SessionPeriod = this.CurrentSessionPeriod;

        if (!datetimeUtc || datetimeUtc.getUTCFullYear() <= 1970) { // idbm for example
            return currentPeriod;
        }

        const dateTimeInSessionTimeZoneWithOffset: DateTime = this.ConvertDateToSessionDateTime(datetimeUtc);

        const [sessionPeriods, dayOfWeek] = this.FindSessionPeriodsForDate(dateTimeInSessionTimeZoneWithOffset);

        if (this.ServerTodayDate.equals(dateTimeInSessionTimeZoneWithOffset.startOf('day')) &&
            currentPeriod != null &&
            this.CheckSession(currentPeriod, dayOfWeek, dateTimeInSessionTimeZoneWithOffset)) {
            return currentPeriod;
        }

        return sessionPeriods.find(x => this.CheckSession(x, dayOfWeek, dateTimeInSessionTimeZoneWithOffset));
    }

    public CheckSession (sessionPeriod: SessionPeriod, dayOfWeek: number, datetimeInSessionTimeZoneWithOffset: DateTime): boolean {
        const begin: Date = sessionPeriod.GetBegin(dayOfWeek);
        const end: Date = sessionPeriod.GetEnd(dayOfWeek);

        // hsa: skip periods that have the same start and end
        // this is possible to set the same start and in session settings
        // it is valid for the case when they want to mark only some period
        if (begin.getTime() === end.getTime()) {
            return false;
        }

        const startTime = this.AddTimeToSessionDate(datetimeInSessionTimeZoneWithOffset, begin);
        const endTime = this.AddTimeToSessionDate(datetimeInSessionTimeZoneWithOffset, end);

        return startTime < endTime && startTime <= datetimeInSessionTimeZoneWithOffset && datetimeInSessionTimeZoneWithOffset < endTime;
    }

    // ?
    public isServerTodayDate (date: Date): boolean {
        return this.ServerTodayDate.toJSDate().isSameDay(date);
    }

    public GetTodayHoliday (): HolidayDescription | null {
        const todayServerDate: DateTime = this.ServerTodayDate;
        if (isNullOrUndefined(todayServerDate)) { return null; }
        const key: string = HolidayDescription.createHolidayKey(todayServerDate.year, todayServerDate.month, todayServerDate.day);
        return this._holidays.has(key) ? this._holidays.get(key) : null;
    }

    public GetStartSessionDayOfWeek (): number | null {
        return this.ServerBeginDayDateTime?.weekday;
    }

    public GetStartSessionTodayDay (): number | null {
        return this.ServerBeginDayDateTime?.day;
    }

    public getSortedHolidays (): HolidayDescription[] {
        const sortedHolidays = Array.from(this._holidays.values())
            .sort((a, b) => a.key.localeCompare(b.key));
        return sortedHolidays;
    }

    public isFutureHoliday (holiday: HolidayDescription): boolean {
        const today = this.ServerTodayDate;
        const holidayDate = DateTime.fromObject({
            year: holiday.year,
            month: holiday.month,
            day: holiday.day
        }, { zone: this.TimeZoneID });

        return holidayDate >= today;
    }

    public GetNextHoliday (): HolidayDescription | null {
        const holidays = this.getSortedHolidays();
        for (const holiday of holidays) {
            if (this.isFutureHoliday(holiday)) {
                return holiday;
            }
        }
        return null;
    }

    public GetTodayDayPeriods (): DayPeriods[] | null {
        const [sessionPeriods] = this.FindSessionPeriodsForDate(this.ServerTodayDate.toJSDate());
        return sessionPeriods?.map(x => x.dayPeriod);
    }

    private GetSessionPeriodByID (periodId: number): SessionPeriod | null {
        let sessionPeriod = this._sessionPeriods.find(m => m.id === periodId);
        if (!isNullOrUndefined(sessionPeriod)) { return sessionPeriod; }

        const holiday = this.GetTodayHoliday();
        if (!isNullOrUndefined(holiday) && !isNullOrUndefined(holiday.sessionPeriods)) {
            sessionPeriod = holiday.sessionPeriods.find(m => m.id === periodId);
        }

        if (!isNullOrUndefined(sessionPeriod)) { return sessionPeriod; }

        // hsa: I'm not sure that we need to search in all holidays
        const nonEmptyHolidays = Array.from(this._holidays.values()).filter(x => x.sessionPeriods != null);
        for (const hDay of nonEmptyHolidays) {
            sessionPeriod = hDay.sessionPeriods.find(m => m.id === periodId);
            if (!isNullOrUndefined(sessionPeriod)) { return sessionPeriod; }
        }

        return null;
    }
}
