// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DataCacheToolType } from '../DataCacheToolEnums';
import { DataCacheTool } from '../DataCacheTool';
export class ArrowLabelDataCacheTool extends DataCacheTool {
    private readonly lineKey = 'property.Line';

    constructor () {
        super();
        this.ToolType = DataCacheToolType.ArrowLabel;
        this.InitPoints(2);
    }

    MaxPointsCount (): number {
        return 2;
    }

    MinPointsCount (): number {
        return 2;
    }
}
