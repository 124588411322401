// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { Resources } from '../../Localizations/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TerceraMessageBoxTemplate } from '../../templates.js';
import { TerceraMessageBox, MessageBoxType } from './TerceraMessageBox';

export class TerceraTimeoutMessageBox extends TerceraMessageBox {
    public forceCloseOnLogout: boolean = false;
    public OK_Handler: any = null;
    public NO_Handler: any = null;
    public countingTimer: any = null;
    public startTime: Date;

    public static override Show (): TerceraTimeoutMessageBox {
        const mBox = new TerceraTimeoutMessageBox();
        void mBox.set({
            messageBoxType: MessageBoxType.Warning,
            header: Resources.getResource('disconnectMessageBox.Header'),
            messageBoxText: Resources.getResource('disconnectMessageBox.Text').replace('{0}', '30'),
            showNextTimeChB: false,
            okText: Resources.getResource('disconnectMessageBox.logoutButton'),
            cancelText: Resources.getResource('disconnectMessageBox.cancelButton'),
            okToolTop: Resources.getResource('disconnectMessageBox.logoutButton.descr'),
            cancelToolTop: Resources.getResource('disconnectMessageBox.cancelButton.descr')
        });

        MainWindowManager.MainWindow.addControl(mBox);
        mBox.OK_Handler = new Promise(function (resolve) { mBox.OkClicked = resolve; });
        mBox.NO_Handler = new Promise(function (resolve) { mBox.CancelClicked = resolve; });
        mBox.StartCounting();
        return mBox;
    }

    public override oncomplete (): void {
        super.oncomplete();
        this.arrangeControls();
        this.center();
    }

    public StartCounting (): void {
        this.startTime = new Date();
        this.countingTimer = setInterval(this._Counting.bind(this), 200);
    }

    public _Counting (): void {
        if (!Connection.isConnectionCorrect()) // #93043
        {
            this.cancelClick();
            return;
        }

        const dif = 30 - new Date(new Date().getTime() - this.startTime.getTime()).getSeconds();
        if (dif < 0) {
            this.okClick();
            return;
        }
        const locVal = Resources.getResource('disconnectMessageBox.Text');
        const Text = locVal.replace('{0}', dif.toString());
        void this.set({ messageBoxText: Text });
    }

    public StopCounting (): void {
        clearInterval(this.countingTimer);
    }

    public override okClick (): void {
        if (!isNullOrUndefined(this.OkClicked)) {
            this.OkClicked();
        }
        this.StopCounting();
        this.close();
    }

    public override cancelClick (): void {
        if (!isNullOrUndefined(this.CancelClicked)) {
            this.CancelClicked();
        }
        this.StopCounting();
        this.close();
    }
}

TerceraMessageBox.extendWith(TerceraTimeoutMessageBox, {
    data: function () {
        return {

        };
    },
    partials: { bodyPartial: TerceraMessageBoxTemplate }
});
