// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DynProperty } from '../../../Commons/DynProperty';
import { ErrorInformationStorage } from '../../../Commons/ErrorInformationStorage';
import { type SessionSettingsType } from '../../../Commons/SessionSettings';
import { Resources } from '../../../Localizations/Resources';
import { BaseItem, ColumnData } from '../BaseItem';
import { ColumnParams } from '../ColumnParams';

export class NewVerticalPanelTableItem extends BaseItem {
    public RowKey: string;
    public RowKey_Localized: string;
    public ToolTipKey: string;
    public RowValue: string;
    public GroupStr: string;
    public SortIndex: number;
    public DynPropertyControltype: number = DynProperty.STRING;
    public GroupIndex: any = null;

    private _itemId: number | string;

    constructor (id: string, rowKey: string, RowValue: string, GroupStr: string, SortIndex: number, sessionSettings: SessionSettingsType) {
        super(sessionSettings);

        this._itemId = this.itemId;
        this.itemId = id;
        this.RowKey = rowKey;
        this.RowKey_Localized = rowKey;
        this.RowValue = RowValue;
        this.GroupStr = GroupStr;
        this.SortIndex = SortIndex;
    }

    get ItemId (): number | string {
        return this._itemId;
    }

    set ItemId (id: number | string) {
        this._itemId = id;
    }

    public override ColumnCount (): number {
        return NewVerticalPanelTableItem.columnsParams.length;
    }

    public override getColumnData (column: number): ColumnData {
        const o = this.getColumnValue(column); ;
        return new ColumnData(o, (o ? o.toString() : ''));
    }

    public override getColumnValue (column: number): any {
        try {
            switch (column) {
            case 0:
                return this.RowKey_Localized;

            case 1:
                return this.RowValue;

            case 2:
                return this.GroupStr; // GroupStr;

            case 3:
                return this.SortIndex;

            default:
                return '';
            }
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
            return '';
        }
    }

    public override GetColumnParams (column: number): ColumnParams {
        const result = NewVerticalPanelTableItem.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey) || (column === 2 || column === 3);
        result.Hidden = isHidden;
        return result;
    }

    /// <summary>
    /// Здесь хранятся настройки колонок (один экземпляр на класс)
    /// </summary>
    public static readonly columnsParams =
        [
            new ColumnParams('AccountDetailesPanel.quickTable.Name', 200, 0, false, true, false),
            new ColumnParams('AccountDetailesPanel.quickTable.Value', 300, 0, false, true, false),
            new ColumnParams('Group', 80, 0, false, false, true),
            new ColumnParams('SortIndex', 80, 0, false, false, true)
        ];
}
