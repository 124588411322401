// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { MAMode } from '../IndicatorConstants';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { type iBuildInIndicator } from '../iBuildInIndicator';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';

export class STOCHASTIC extends IndicatorScriptBase {
    public IndPeriod: number;
    public Smooth: number;
    public DoubleSmooth: number;

    private ssdMa: iBuildInIndicator;
    private smoothedMa: iBuildInIndicator;

    constructor () {
        super();
        this.ProjectName = 'Stochastic Slow';
        this.Comments = 'Shows the location of the current close relative to the high/low range over a set number of periods (Slow)';

        this.SetIndicatorLine('STO%K', Color.Green, 1, LineStyle.SimpleChart);
        this.SetIndicatorLine('STO%D', Color.LightSkyBlue, 1, LineStyle.SimpleChart);

        this.SetLevelLine('up', 80, Color.Red, 1, LineStyle.ShapedChart);
        this.SetLevelLine('down', 20, Color.Blue, 1, LineStyle.ShapedChart);

        this.SeparateWindow = true;

        this.IndPeriod = 10;
        super.InputParameter(new InputParameterInteger('IndPeriod', 'Period of indicator:', 0, 1, 328));

        this.Smooth = 3;
        super.InputParameter(new InputParameterInteger('Smooth', '%K Period:', 1, 0, 999));

        this.DoubleSmooth = 3;
        super.InputParameter(new InputParameterInteger('DoubleSmooth', '%D Period:', 2, 1, 999));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.UpdateIndexDrawBegin();
    };

    public override UpdateIndexDrawBegin (): void {
        this.SetIndexDrawBegin(0, this.IndPeriod + this.Smooth - 2);
        this.SetIndexDrawBegin(1, this.IndPeriod + this.Smooth + this.DoubleSmooth - 3);
    };

    public override GetIndicatorShortName (): string {
        return 'SSD(' + this.IndPeriod + ';' + this.Smooth + ';' + this.DoubleSmooth + ')';
    };

    public override getCustomName (): string {
        return 'SSD (' + this.ProjectName + ')';
    };

    public override createInternalIndicators (): iBuildInIndicator[] {
        this.ssdMa =
            this.Indicators.iMAOnArray(this.CurrentData, this.getSSD.bind(this), this.Smooth, MAMode.SMA);

        this.smoothedMa =
            this.Indicators.iMAOnArray(this.CurrentData, this.getPrevValue.bind(this), this.DoubleSmooth, MAMode.SMA);

        return [this.ssdMa, this.smoothedMa];
    };

    public getSSD (index): number {
        const CurrentData = this.CurrentData;

        const count = CurrentData.Count;
        if (this.IndPeriod + this.Smooth - 1 > count) { return 0; }

        let high = CurrentData.GetPrice(PriceType.High, index);
        let low = CurrentData.GetPrice(PriceType.Low, index);
        const close = CurrentData.GetPrice(PriceType.Close, index);

        for (let i = 1; i + index < count && i < this.IndPeriod; i++) {
            let price = CurrentData.GetPrice(PriceType.High, index + i);

            if (price > high) { high = price; };

            price = CurrentData.GetPrice(PriceType.Low, index + i);

            if (price < low) { low = price; };
        }

        high -= low;
        return high > 0.0 ? (100 * (close - low) / high) : 0.0;
    };

    public getPrevValue (index): number {
        const count = this.CurrentData.Count;
        if ((this.IndPeriod + this.Smooth + this.DoubleSmooth - 2) > count) { return 0; }
        return index === 0 ? this.ssdMa.GetValue(0) : this.GetValue(0, index);
    };

    public OnQuote (): void {
        super.OnQuote();
        const count = this.CurrentData.Count;

        if (this.IndPeriod + this.Smooth - 1 > count) { return; }
        // Calculation of smoothed curve
        this.SetValue(0, 0, this.ssdMa.GetValue(0));

        if (this.IndPeriod + this.Smooth + this.DoubleSmooth - 2 > count) { return; }
        // Calculation of double smoothed curve
        this.SetValue(1, 0, this.smoothedMa.GetValue(0));
    };
}
