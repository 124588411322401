// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Instrument } from '../../cache/Instrument';
import { TvInteriorIdCache } from '../Caches/TvInteriorIdCache';
import { type ErrorFormatterParseResult, type INumberFormatter, type SuccessFormatterParseResult } from '../charting_library';
import { DataCache } from '../../DataCache';
import { TvNumberParser } from './TvNumberParser';

export abstract class TvBaseFormatter implements INumberFormatter {
    protected readonly instrument: Instrument;

    constructor (symbol: string) {
        const interiorId = TvInteriorIdCache.getInteriorId(symbol);
        this.instrument = DataCache.getInstrumentByName(interiorId);
    }

    // public abstract formatChange? (currentPrice: number, prevPrice: number): string;

    public abstract format (value: number): string;

    public parse (value: string): ErrorFormatterParseResult | SuccessFormatterParseResult<number> {
        const formatterResult: SuccessFormatterParseResult<number> = {
            res: true,
            value: TvNumberParser.parse(value) // TODO return ErrorFormatterParseResult in case of error
            // suggest?: string;
        };
        return formatterResult;
    }
}
