// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Message } from './Message';
import { DirectXMLMessage } from './DirectXMLMessage';
import { OperationType } from '../Trading/OperationType';

export class DirectCreateMessage extends DirectXMLMessage {
    public DialogId = 0;
    public OrderId = '';
    public Symbol = '';
    public OperationType = OperationType.Buy;
    public OrderType = 0;
    public Amount = 0;
    public Price = 0;
    public OpenOrderId: string | null = null;
    public BoundToOrderId: string | null = null;
    public AccountID: string | null = null;
    public BstrMarket: any = null;
    // yura - NewPrice(для модификации Limit ордеров)
    public NewPrice = 0;
    public ExpDate: any = null;

    constructor () {
        super(Message.CODE_NEW_ORDER_MESSAGE);
    }
}
