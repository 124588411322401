// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Rectangle } from '../Commons/Geometry';
import { HistoryType } from '../Utils/History/HistoryType';
import { ChartHistoryType, TerceraChartDrawingType } from './Utils/ChartConstants';
import { DataCacheToolType } from '../Commons/cache/DataCacheToolEnums';
import { Graphics, SolidBrush } from '../Commons/Graphics';
import { Resources } from '../Localizations/Resources';
import { type TerceraChartWindowBase } from './Windows/TerceraChartWindowBase';

export interface LinearRegressionCoefficients {
    A: number;
    B: number;
    Sigma: number;
}

export class TerceraChartUtils {
    public static AbsRectangle (rect): Rectangle {
        const X = rect.X;
        const Y = rect.Y;
        const R = X + rect.Width;
        const B = Y + rect.Height;

        return new Rectangle(
            Math.min(X, R),
            Math.min(Y, B),
            Math.abs(X - R),
            Math.abs(Y - B)
        );
    }

    public static DrawPointer (gr, window: TerceraChartWindowBase, yScaleValue, br, text, f, borderPen, sf, yLocation = -1): void {
        let reverse = false;
        let priceScale = window.rightYScaleRenderer;
        if (!priceScale) {
            priceScale = window.leftYScaleRenderer;
            reverse = true;
        }

        if (!f) f = priceScale.settings.ScaleFont;

        const autoScaleRectH = priceScale.AutoScaleRect.Height;

        let height = f.Height * 2;
        if (height < 16) height = 16;
        if (height % 2) height++;

        const middleH = height / 2;
        const y = yLocation !== -1 ? yLocation : window.PointsConverter.GetScreenY(yScaleValue);

        const scaleRect = priceScale.Rectangle;
        let x = scaleRect.X;
        const width = scaleRect.Width - 1;

        const scaleRectY = scaleRect.Y;
        const scaleRectB = scaleRectY + scaleRect.Height;

        let rect = new Rectangle(x, y - middleH, width, height);
        let rectX: number, rectY: number;
        if (y < scaleRectB && y > scaleRectY + autoScaleRectH) // входит в диапазон
        {
            rectX = rect.X;
            rectY = rect.Y;
            // const rectR = rectX + rect.Width;
            // const rectB = rectY + rect.Height;
            const rectWidth = rect.Width;
            const rectHeight = rect.Height;

            // var pts = reverse ?
            //     [
            //         new Point(rectR, rectY + middleH),
            //         new Point(rectR - middleH, rectY),
            //         new Point(rectX, rectY),
            //         new Point(rectX, rectB),
            //         new Point(rectR - middleH, rectB),
            //         new Point(rectR, rectY + middleH)
            //     ] :
            //     [
            //         new Point(rectX, rectY + middleH),
            //         new Point(rectX + middleH, rectY),
            //         new Point(rectR, rectY),
            //         new Point(rectR, rectB),
            //         new Point(rectX + middleH, rectB),
            //         new Point(rectX, rectY + middleH)
            //     ];

            // if (br) gr.FillPolygon(br, pts);
            // if (borderPen) gr.DrawPolygon(borderPen, pts);
            if (br) gr.FillRect(br, rectX, rectY, rectWidth, rectHeight);
        } else if (y > scaleRectB) // ниже диапазона
        {
            rect = new Rectangle(x, scaleRectB - height - 5, width, height);

            rectX = rect.X;
            rectY = rect.Y;
            const rectW = rect.Width;
            // const rectR = rectX + rectW;
            const rectB = rectY + rect.Height;

            gr.beginPath();

            if (br) br.applySettings(gr);
            if (borderPen) borderPen.applySettings(gr);

            if (br) {
                gr.FillRect(br, rect.X, rect.Y, rect.Width, rect.Height);
            }
            // gr.arc(rectX + r, rectB - r, r, 0.5 * Math.PI, 190 / 180 * Math.PI);
            // gr.arc(rectX + r, rectY + r, r, Math.PI, 280 / 180 * Math.PI);

            // gr.arc(rectR - r, rectY + r, r, 1.5 * Math.PI, 370 / 180 * Math.PI);
            // gr.arc(rectR - r, rectB - r, r, 0, 100 / 180 * Math.PI);

            x = rectX + (rectW - 10) / 2;
            gr.lineTo(x, rectB);
            x += 5;
            gr.lineTo(x, rectB + 5);
            x += 5;
            gr.lineTo(x, rectB);

            gr.closePath();

            if (br) gr.fill();
            if (borderPen) gr.stroke();
        } else if (y <= scaleRectY + autoScaleRectH)// выше диапазона
        {
            rect = new Rectangle(x, scaleRectY + 5 + autoScaleRectH, width, height);

            rectX = rect.X;
            rectY = rect.Y;
            const rectW = rect.Width;
            // const rectR = rectX + rectW;
            // const rectB = rectY + rect.Height;

            gr.beginPath();

            if (br) br.applySettings(gr);
            if (borderPen) borderPen.applySettings(gr);

            if (br) {
                gr.FillRect(br, rect.X, rect.Y, rect.Width, rect.Height);
            }
            // gr.arc(rectR - r, rectY + r, r, 1.5 * Math.PI, 370 / 180 * Math.PI);
            // gr.arc(rectR - r, rectB - r, r, 0, 100 / 180 * Math.PI);

            // gr.arc(rectX + r, rectB - r, r, 0.5*Math.PI, 190/180*Math.PI);
            // gr.arc(rectX + r, rectY + r,        r,     Math.PI, 280/180*Math.PI);

            x = rectX + (rectW - 10) / 2;
            gr.lineTo(x, rectY);
            x += 5;
            gr.lineTo(x, rectY - 5);
            x += 5;
            gr.lineTo(x, rectY);

            gr.closePath();

            if (br) gr.fill();
            if (borderPen) gr.stroke();
        }

        if (br && text) {
            const testBrush = new SolidBrush(Graphics.GetContrastColor(br.Color));
            // TODO.
            // if(!sf) sf = Utils.StringFormatCenterCenter;
            gr.DrawString(text, f, testBrush,
                rectX + rect.Width / 2 + 1,
                rectY + rect.Height / 2,
                'center', 'middle' /*, sf */);
        }
    }

    public static ShiftTool (out_DataCacheTool, ww): void {
        const dyy = 10;
        let vertShift = 0;
        let shiftRight = null;

        const pc = ww.PointsConverter;
        const points = out_DataCacheTool.Points;

        switch (out_DataCacheTool.ToolType) {
        case DataCacheToolType.VerticalLine:
        case DataCacheToolType.FibonacciTimeZone:{
            const ii = Math.round(pc.GetBarIndex(points[0][0]));
            if (ii + 1 < ww.i1) {
                shiftRight = true;
            } else if (ii > 0) {
                shiftRight = false;
            }
            break;
        }
        default:
            vertShift = dyy;
        }

        const len = points.length;
        for (let i = 0; i < len; i++) {
            if (shiftRight !== null) {
                let scr_X = pc.GetScreenXbyTime(points[i][0]);
                scr_X += shiftRight ? ww.XScale : -ww.XScale;

                points[i][0] = pc.GetDataX(scr_X);
            }

            const yint_Y = pc.GetScreenY(points[i][1]);
            const ydbl_Y = pc.GetDataY(yint_Y + vertShift);

            points[i][1] = ydbl_Y;
        }
    }

    // TODO. Refactor. Optimize.
    public static getChartDrawingTypeLocKey (style: TerceraChartDrawingType): string {
        let styleName = '';

        switch (style) {
        case TerceraChartDrawingType.Line:
            styleName = 'line';
            break;
        case TerceraChartDrawingType.Bar:
            styleName = 'bar';
            break;
        case TerceraChartDrawingType.Candle:
            styleName = 'candle';
            break;
        case TerceraChartDrawingType.Dot:
            styleName = 'dot';
            break;
        case TerceraChartDrawingType.DotLine:
            styleName = 'dotline';
            break;
        case TerceraChartDrawingType.Forest:
            styleName = 'forest';
            break;
        case TerceraChartDrawingType.Solid:
            styleName = 'solid';
            break;
        case TerceraChartDrawingType.Kagi:
            styleName = 'kagi';
            break;
        }

        return 'chart.tool.' + styleName;
    }

    public static ToLocalizedTerceraChartDrawingType (type): string {
        return Resources.getResource(TerceraChartUtils.getChartDrawingTypeLocKey(type));
    }


    public static calculateLinearRegression(data: number[][]): LinearRegressionCoefficients | null {
    if (data.length === 0 || data[0] === null) {
        return null;
    }

    let y = 0;
    let x = 0;
    let Sx = 0, Sy = 0, Sxx = 0, Sxy = 0;
    let Seqiuty = 0, Seqiuty2 = 0;

    let coef: LinearRegressionCoefficients = {
        A: 0,
        B: 0,
        Sigma: 0
    };

    for (let d of data) {
        x = d[0];
        y = d[1];

        Sx += x;
        Sy += y;
        Sxy += x * y;
        Sxx += x * x;

        Seqiuty += y;
    }

    Sx /= data.length;
    Sy /= data.length;
    Sxy /= data.length;
    Sxx /= data.length;

    if (Sx === 0 || (Sx * Sx - Sxx) === 0) {
        return null;
    }

    coef.A = (Sx * Sy - Sxy) / (Sx * Sx - Sxx);
    coef.B = (Sxy - coef.A * Sxx) / Sx;

    Seqiuty /= data.length;

    for (let d of data) {
        y = d[1];
        Seqiuty2 += (y - Seqiuty) * (y - Seqiuty);
    }

    Seqiuty2 /= (data.length - 1);
    coef.Sigma = Math.sqrt(Seqiuty2);

    return coef;
}
}

// ---------------

export class TerceraChartHistoryType {
    ChartDataType: any;
    constructor (typeEnum) {
        this.ChartDataType = typeEnum;
    }

    public HistoryType (): number {
        return TerceraChartHistoryType.GetOriginalHistoryType(this.ChartDataType);
    }

    public static GetOriginalHistoryType (ChartDataType, ins?): number {
        switch (ChartDataType) {
        case ChartHistoryType.Default:
            if (ins) {
                return ins.DefaultChartHistoryType;
            } else {
                return 0;
            }

        case ChartHistoryType.ByBid:
            return HistoryType.BID;
        case ChartHistoryType.ByBidAsk:
            return HistoryType.BID;
        case ChartHistoryType.ByTrades:
            return HistoryType.LAST;
        case ChartHistoryType.ByASk:
            return HistoryType.ASK;
        case ChartHistoryType.BY_BID_ASK_AVERAGE:
            return HistoryType.BIDASK_AVG;
        default:
            return 0;
        }
    }
}
