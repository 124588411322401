// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum PriceLimitMeasure {
    DISABLE = -1,
    PERCENTAGE_SETTL_PRICE = 0,
    OFFSET_SETTL_PRICE = 1,
    INDICATIVE_PRICES = 2,
    EXCHANGE_LIMITS = 3,
    PERCENTAGE_PREV_CLOSE_PRICE = 4,
    OFFSET_PREV_CLOSE_PRICE = 5,
    PERCENTAGE_CHANGE_FROM_REFERENCE_PRICE = 6,
    FRONT_END_VALIDATION = 8,
}
