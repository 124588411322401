// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ModifyMarketOrder } from '../order-modify/ModifyMarketOrder';
import { OrderTypeBase } from './OrderTypeBase';
import { OrderType } from '../../../../Utils/Trading/OrderType';
import { MarketOrderEdit } from '../order-edit/MarketOrderEdit';

export class MarketOrderType extends OrderTypeBase {
    constructor () {
        super(MarketOrderEdit, ModifyMarketOrder);
    }

    public override id (): OrderType {
        return OrderType.Market;
    }
}
