// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { Resources } from '../../Localizations/Resources';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { Leve2QuoteType } from '../../Utils/Enums/Constants';
import { type Asset } from '../../Commons/cache/Asset';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';
import { type ProductType } from '../../Utils/Instruments/ProductType'; import { WDSettings } from '../settings/WDGeneralSettings';
;

export class Level2ItemQ extends BaseItem {
    private Instrument: Instrument | null = null;
    private ProductType: ProductType | null = null;
    private Account: Account | null = null;

    public insLotSize = 1;
    public quoteSize = 1;
    public sizePrecision: any;

    public count: any;
    public VWAP: any;
    public MMPD: any;
    public aggregatedVolume: any;
    public mdLotsMode = MDLotsMode.General;
    public RoundedPrecisionValue: any;
    public RoundedPrecision: any;

    /// <summary>
    /// Произошла раскраска ячейки - через определленое время нужно сбросить цвет
    /// </summary>
    public ColoringOccured = -1;

    public asset: Asset = null;
    public RestoreBackColor: any;
    public RestoreForeColor: any;

    public QuoteMessage: any = null;

    // public id = Level2ItemQ.idCount++;
    // Level2ItemQ.idCount = 0;

    public override ColumnCount (): number {
        return Level2ItemQ.columnsParams.length;
    }

    public SetInstrument (value: Instrument): void {
        if (this.Instrument !== value) {
            this.Instrument = value;
            this.refreshInstrumentData();
        }
    }

    public SetQuoteMessage (value): void {
        this.QuoteMessage = value;
        if (this.QuoteMessage !== null) {
            this.quoteSize = this.QuoteMessage.Size;
        } else {
            this.quoteSize = 1;
        }
    }

    public SetAccount (acc: Account): void {
        if (this.Account !== acc) {
            this.Account = acc;
            this.refreshInstrumentData();
        }
    }

    public SetProductType (newPt: ProductType): void {
        if (this.ProductType !== newPt) {
            this.ProductType = newPt;
            this.refreshInstrumentData();
        }
    }

    public refreshInstrumentData (): void {
        const ins = this.Instrument;
        if (ins != null) {
            this.insLotSize = ins.getLotSize();
            this.asset = ins.DataCache.GetAssetByName(ins.Exp2);
            this.sizePrecision = ins.getLotStepPrecision(this.ProductType, this.Account);
        } else {
            this.insLotSize = 1;
        }
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let formattedValue = '';

        switch (column) {
        case 1:{
            const ins = this.Instrument;
            if (this.QuoteMessage.QuoteType == Leve2QuoteType.None) {
                formattedValue = ins.formatPrice(this.QuoteMessage.Price);
            } else if (this.QuoteMessage.QuoteType == Leve2QuoteType.Indicative) {
                formattedValue = isNaN(ins.Level1.GetIndicativeAuctionPrice()) ? '-' : ins.Level1.strIndicativeAuctionPrice() + Resources.getResource('panel.level2.Auction');
            } else if (this.QuoteMessage.QuoteType == Leve2QuoteType.WithoutPrice) {
                formattedValue = '-';
            } else {
                formattedValue = 'invalid quote type';
            } // заглушка такого быть не должно

            // formattedValue = isNaN(value) ? "-" : this.Instrument.formatPrice(value);
            break;
        }

        case 2:
            formattedValue = this.GetSizeString(value, this.mdLotsMode, this.RoundedPrecision, this.RoundedPrecisionValue, this.Instrument);
            break;

        case 3:
            formattedValue = DateTimeUtils.FormatToTime(value);
            break;

        case 4:
            formattedValue = this.Instrument.formatPricePrecision(value);// this.Instrument.formatPricePrecision(this.VWAP);
            break;

        case 5:
            formattedValue = this.Instrument.DataCache.formatVolume(this.Instrument, value, WDSettings.isQuantityInLots, WDSettings.productType, this.Account);
            break;

        case 8:
            if (WDSettings.isQuantityInLots) {
                formattedValue = this.Instrument.DataCache.formatVolume(this.Instrument, value, WDSettings.isQuantityInLots, WDSettings.productType, this.Account);
            } else {
                formattedValue = this.Instrument.formatPrice(value);
            }// this.Instrument.formatPrice(this.QuoteMessage.DayTradeVolume);
            break;

        case 9:
            formattedValue = this.QuoteMessage.QuoteType !== Leve2QuoteType.None ? '-' : this.asset.formatPrice(value);
            break;
        case 10:
        // - обрезает лишнюю точность
            formattedValue = this.asset.formatPrice(value);
            break;
        case 11:
            formattedValue = DateTimeUtils.formatDate(new Date(value), 'HH:mm:ss');// .toString(); //"hh\:mm\:ss"
            break;

        default:
            if (value !== null) {
                formattedValue = value.toString();
            } else {
                formattedValue = '';
            }
            break;
        }

        return new ColumnData(value, formattedValue);
    }

    public override getColumnValue (column: number): any {
        if (this.Instrument == null) { return null; }

        if (this.QuoteMessage != null) {
            switch (column) {
            case 0:
                return this.MMPD;

            case 1:
                return this.QuoteMessage.Price; // Math.round(this.QuoteMessage.Price, this.Instrument.getPrecision());

            case 2:{
                let inLots = WDSettings.isQuantityInLots;
                if (this.mdLotsMode === MDLotsMode.Lots) {
                    inLots = true;
                } else if (this.mdLotsMode === MDLotsMode.RealSize) {
                    inLots = false;
                }

                let val = 0;

                if (inLots) {
                    val = this.quoteSize / this.insLotSize;
                } else {
                    val = this.quoteSize;
                }

                return val;
            }

            case 3:
                return this.QuoteMessage.cTime;//  this.sessionSettings.ConvertUTCTimeToSelectedTimeZone(this.QuoteMessage.cTime, this.Instrument.DataCache);

            case 4:
                return this.VWAP;

            case 5:
                return WDSettings.isQuantityInLots ? this.aggregatedVolume / this.insLotSize : this.aggregatedVolume;

            case 6:
                return this.QuoteMessage.Source;

            case 7:
                return this.count;

            case 8:
                if (WDSettings.isQuantityInLots) {
                    if ((this.Instrument !== null ? (this.QuoteMessage.DayTradeVolume / this.insLotSize) : this.QuoteMessage.DayTradeVolume) < 0.01) {
                        return this.QuoteMessage.DayTradeVolume;
                    } else {
                        return this.QuoteMessage.DayTradeVolume / this.insLotSize;
                    }
                } else {
                    return this.QuoteMessage.DayTradeVolume;
                }

            case 9:
                return this.QuoteMessage.Price * this.quoteSize * this.Instrument.GetTickCost();

            case 10:
                return this.aggregatedVolume * this.VWAP * this.Instrument.GetTickCost();

            case 11:
                return DateTimeUtils.DateTimeUtcNow().getTime() - this.QuoteMessage.cTime;

            case 12:
                return this.QuoteMessage.NumberOfOrders;
            }
        }
        return null;
    }

    public GetColumnParams (column: number): ColumnParams {
        const result = Level2ItemQ.columnsParams[column];
        const isHidden = Resources.isHidden(result.HeaderKey);

        result.Hidden = isHidden;
        if (column === 6 || column === 11 || column === 8) {
            result.Hidden = true;
        }

        return result;
    }

    public override GetCurrentAccount (): Account | null {
        return this.Account;
    }

    public override GetCurrentInstrument (): Instrument | null {
        return this.Instrument;
    }

    public override InstrumentName (): string {
        return this.Instrument != null ? this.Instrument.GetInteriorID() : '';
    }

    public override Dispose (): void {
        this.sessionSettings = null;
    }

    public GetSizeString (size, mdLotsMode, RoundedPrecision, RoundedPrecisionValue, Instrument): string {
        return size.toFixed(this.sizePrecision);
    }

    /// <summary>
    /// Здесь хранятся настройки колонок (один экземпляр на класс)
    /// </summary>
    public static readonly columnsParams = [
        new ColumnParams('panel.Level2.MPID', 70, 0, false, false, false),
        new ColumnParams('panel.Level2.Price', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.Level2.Size', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.Level2.Time', 70, QuickTableColumnType.COL_DATE_SORT, false, false, false),
        new ColumnParams('panel.Level2.AvgPrice', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.Level2.TotalVol', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.Level2.Source', 70, 0, false, false, false, false, false, 0, true),
        new ColumnParams('panel.Level2.Number', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.Level2.DayTradeVolume', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false, true),
        /* 9 */ new ColumnParams('panel.Level2.Contr. CCY value', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        /* 10 */ new ColumnParams('panel.Level2.Contr. CCY Total value', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.Level2.Age', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.Level2.NumberOfOrders', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false)
    ];
}

export enum MDLotsMode {
    General = 0,
    RealSize = 1,
    Lots = 2
}
