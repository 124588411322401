// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { BaseItem, ColumnData } from '../BaseItem';
import { ColumnParams } from '../ColumnParams';
import { QuickTableEditingInfo } from '../../elements/QuickTable/QuickTableMisc';
import { DynProperty } from '@shared/commons/DynProperty';
import { type TerceraChartIndicatorRenderer } from '@front/chart/Renderers/Indicators/TerceraChartIndicatorRenderer';
import { type IObjectManagerItem } from '@shared/commons/cache/interface/IObjectManagerItem';

export class IndicatorsManagerItem extends BaseItem implements IObjectManagerItem<TerceraChartIndicatorRenderer> {
    public readonly Renderer: TerceraChartIndicatorRenderer;

    constructor (renderer: TerceraChartIndicatorRenderer) {
        super();
        this.Renderer = renderer;

        const visibilityButton = new QuickTableEditingInfo(DynProperty.VISIBILITY_BUTTON);
        visibilityButton.TooltipKey = 'ObjectManagerItem.Visible.ToolTip';
        this.QuickTableEditingInfoMap[IndicatorsManagerItem.VISIBLE_COL_INDEX] = visibilityButton;

        const settingsButton = new QuickTableEditingInfo(DynProperty.SETTINGS_BUTTON);
        settingsButton.TooltipKey = 'ObjectManagerItem.Settings.ToolTip';
        this.QuickTableEditingInfoMap[IndicatorsManagerItem.SETTINGS_COL_INDEX] = settingsButton;

        const closeButton = new QuickTableEditingInfo(DynProperty.CLOSE_BUTTON);
        closeButton.TooltipKey = 'ObjectManagerItem.Remove.ToolTip';
        this.QuickTableEditingInfoMap[IndicatorsManagerItem.REMOVE_COL_INDEX] = closeButton;
    }

    public getItemSubject (): TerceraChartIndicatorRenderer {
        return this.Renderer;
    }

    public override ColumnCount (): number {
        return IndicatorsManagerItem.columnsParams.length;
    }

    public override GetColumnParams (i: number): ColumnParams {
        return IndicatorsManagerItem.columnsParams[i];
    }

    public GetMenuIndex (headerKey: string): number {
        if (IndicatorsManagerItem.ListMenuIndex[headerKey]) {
            return IndicatorsManagerItem.ListMenuIndex[headerKey];
        } else {
            return 1000;
        }
    }

    public override getColumnValue (column: number): any {
        const indicator = this.Renderer.Indicator;
        switch (column) {
        case IndicatorsManagerItem.NAME_COL_INDEX:
            return indicator.ShortName();
        case IndicatorsManagerItem.WINDOW_COL_INDEX:
            if (this.Renderer.WindowsNumber === 0) {
                return Resources.getResource('ChartObjectManager.mainWindow');
            } else {
                return `${Resources.getResource('ChartObjectManager.subWindow')} ${this.Renderer.WindowsNumber}`;
            }
        case IndicatorsManagerItem.VISIBLE_COL_INDEX:
            return this.Renderer.Visible;
        default:
            return '';
        }
    }

    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let FormattedValue = null;

        switch (column) {
        case IndicatorsManagerItem.NAME_COL_INDEX:
            FormattedValue = value;
            break;
        case IndicatorsManagerItem.WINDOW_COL_INDEX:
            FormattedValue = value;
            break;
        }

        return new ColumnData(value, FormattedValue);
    }

    static NAME_COL_INDEX = 0;
    static WINDOW_COL_INDEX = 1;
    static VISIBLE_COL_INDEX = 2;
    static SETTINGS_COL_INDEX = 3;
    static REMOVE_COL_INDEX = 4;

    static columnsParams =
        [
            new ColumnParams('ObjectManagerItem.Name', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Window', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Visible', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Settings', 70, 0, false, true, false),
            new ColumnParams('ObjectManagerItem.Remove', 70, 0, false, true, false)
        ];

    public static readonly ListMenuIndex = [
        'ObjectManagerItem.Name',
        'ObjectManagerItem.Window',
        'ObjectManagerItem.Visible',
        'ObjectManagerItem.Settings',
        'ObjectManagerItem.Remove'];
}
