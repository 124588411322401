// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type PFixFieldSet } from '../../Fields/PFixFieldSet';
import { FieldsFactory } from '../../Factory/FieldsFactory';
import { MessageProcessorBase } from '../MessageProcessorBase';
import { DirectAlgorithmHeatmapMessage } from '../../../../../Utils/DirectMessages/Algorithm/DirectAlgorithmHeatmapMessage';
import { AlgorithmHeatmapRtDataGroup, AlgorithmHeatmapDelayDataGroup } from '../../Groups/GroupsImport';
import { HeatmapAlgorithmData } from '../../../../../Commons/cache/Algorithm/Heatmap/HeatmapAlgorithmData';
import { type AlgorithmHeatmapInstrumentGroup } from '../../Groups/Algorithm/Heatmap/AlgorithmHeatmapInstrumentGroup';

export class AlgorithmHeatmapMessageProcessor extends MessageProcessorBase {
    public override decode (fs: PFixFieldSet): DirectAlgorithmHeatmapMessage[] {
        const msg = new DirectAlgorithmHeatmapMessage();
        const ff = FieldsFactory;

        msg.AlgorithmId = fs.GetValue(ff.FIELD_ALGORITHM_ID);
        msg.AlgorithmName = fs.GetValue(ff.FIELD_ALGORITHM_NAME);
        msg.InstrumentListId = fs.GetValue(ff.FIELD_INSTRUMENT_LIST_ID);

        const rtGroups = fs.GetGroups(ff.ALGORITHM_HEATMAP_RT_GROUP, AlgorithmHeatmapRtDataGroup);
        for (const heatmapRtData of rtGroups) {
            const data = this.CreateHeatmapData(heatmapRtData, false);
            msg.HeatmapAlgorithmData.push(data);
        }

        const delayedGroups = fs.GetGroups(ff.ALGORITHM_HEATMAP_DELAY_DATA_GROUP, AlgorithmHeatmapDelayDataGroup);
        for (const heatmapDelayedtData of delayedGroups) {
            const data = this.CreateHeatmapData(heatmapDelayedtData, true);
            msg.HeatmapAlgorithmData.push(data);
        }

        return [msg];
    }

    private CreateHeatmapData (heatmapGroup: AlgorithmHeatmapInstrumentGroup, isDelayed: boolean): HeatmapAlgorithmData {
        const data = new HeatmapAlgorithmData();
        data.IsDelayed = isDelayed;
        data.TradableId = heatmapGroup.TradableId;
        data.RouteId = heatmapGroup.RouteID;
        data.Change = heatmapGroup.Change;
        data.PercentChange = heatmapGroup.PercentChange;
        data.LastPrice = heatmapGroup.LastPrice;
        return data;
    }
}
