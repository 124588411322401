// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ScreensNames } from '../../Controls/UtilsClasses/FactoryConstants';
import { TerceraRecoverScreen } from '../../Controls/screen/TerceraRecoverScreen';
import { InvestingForgotPasswordScreenTemplate } from '../templates';

export class InvestingForgotPasswordScreen extends TerceraRecoverScreen {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    getType (): string { return ScreensNames.InvestingForgotPasswordScreen; }

    closeScreenEventClick (): void {
        super.clearAllAndHide();
    }
}

TerceraRecoverScreen.extendWith(InvestingForgotPasswordScreen, {
    template: InvestingForgotPasswordScreenTemplate,
    data: function () {
        return {
            visible: false
        };
    }
});
