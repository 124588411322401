// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingPieChartTemplate } from '../templates.js';
import Ractive from 'ractive';

declare global{
    interface Window {
        $: any
    }
}

export class InvestingPieChart extends Ractive {
    constructor () { super(); }

    public getType (): string { return 'InvestingPieChart'; }

    public override oninit (): void { }

    public override oncomplete (): void {
        this.createChartData();
    }

    public createChartData (): void {
        const arrData: any[] = [
            { title: 'Stocks', value: 25, color: '#79FFFF' },
            { title: 'Cash', value: 25, color: '#FF7C82' },
            { title: 'Bonds', value: 25, color: '#79BEFF' },
            { title: 'Forex', value: 12.5, color: '#FFBE81' },
            { title: 'Other', value: 12.5, color: '#E8E8E8' }
        ];

        for (let i = 0; arrData.length > i; i++) {
            const item = arrData[i];
            item.formattedValue = item.value.toFixed(2) + '%';
        }

        this.setValue(arrData);
    }

    public setValue (arr): void {
        void this.set({ arrDataValueParam: arr });
        window.$('#doughnutChart').drawDoughnutChart(arr);
    }

    public mouseHoverEvent (index, hover): void {
        if (this.get('enableHover')) {
            window.$('#doughnutChart').onHoverProcessor(index, hover);
        }
    }
}

Ractive.extendWith(InvestingPieChart, {
    template: InvestingPieChartTemplate,
    data: function () {
        return {
            enableHover: true,
            arrDataValueParam: []
        };
    }
});
