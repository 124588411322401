// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class TvSettingsData {
    public id: string;
    public TvChartSettings: any;
    public TvSettings: any;
    public defaultAccountId?: string;
    public defaultResolution: string;
    public theme: string = 'light';
    public time: number;
}
