// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ConstantVector } from './ConstantVector';
import { DoubleMatrix, ExpandDoubleVector } from './DoubleMatrix';
import { HashtableLightCount } from './HashtableLightCount';
import { MarkersVector } from './MarkersVector';
import { ResVector } from './ResVector';
import { ErrorInformationStorage } from '../../ErrorInformationStorage';
import { Resources } from '../../../Localizations/Resources';
import { HistoricalData } from './HistoricalData';
import { DynProperty, ColorStyleWidth } from '../../DynProperty';
import { IndicatorScriptBase } from './IndicatorScriptBase';
import { type IIndicator } from './IIndicator';
import { CustomEvent } from '../../../Utils/CustomEvents';

// Processor
export class Indicator implements IIndicator {
    public OnError = new CustomEvent();
    public FParent: any = null;
    public Script: IndicatorScriptBase = null;
    public SeparateWindow = true;
    public quickAccessData: any = null;
    public FCount = 0;

    // TODO MB
    public Digits = -1;

    public LinesCount: number;
    public LinePlateVisible: boolean[];
    public LineVisible: boolean[];
    public LineStyles: any[];
    public LineWidth: any[];
    public LineColors: any[];
    public PriceMultiplier: any[];
    public TimeShift: any[];
    public FLineNames: any[];
    public EmptyValue: any[];
    public DrawBegin: any[];
    public LineMarkers: any[];
    public SymbolCode: any;
    public mainChart: any = null;

    private FCloudBuffer: HashtableLightCount;
    private FPriceType: number;
    private FName: string = '';
    private FSymbol: string;
    private FTimeFrameInfo: any = null;

    public get Name (): string { return this.FName; }
    public set Name (value) { this.FName = value; }

    // Период индикатора
    public get Period (): number { return 1; }

    // Цена, по которой будет построен данный индикатор
    public get PriceType (): number { return this.FPriceType; }
    public set PriceType (value) { this.FPriceType = value; }

    public get Symbol (): string { return this.FSymbol; }
    public set Symbol (value: string) { this.FSymbol = value; }

    // Тип истории, по которой индикатор будет работать.

    public get HistoryType (): any { return null; }

    public get Data (): any { return null; }

    // Уникальный ключ
    public get Key (): any { throw new Error(); }

    // Количество доступных точек

    public get Count (): any { return this.FCount; }

    public get MainChart (): any { return this.mainChart; }
    public set MainChart (value: any) { this.mainChart = value; }

    public get TimeFrameInfo (): any { return this.FTimeFrameInfo; }
    public set TimeFrameInfo (value: any) { this.FTimeFrameInfo = value; }

    // для расчетов - нужно ли рефрешнуть индикатор по CIFile'y

    public get RecalculateRange (): any { return null; }

    public repopulate (): void { }

    public getMinBarsCount (): number {
        return this.Script.getMinBarsCount();
    }

    // Получить значение индикатора в точке
    public GetValue (par0, par1): any { }

    // Задать значение индикатора в точке
    public SetValue (par0, par1, par2): any { }

    public Init (async): any { }

    public Complete (): any { }

    public FillFields (): void {
        if (this.Script === null) {
            throw new Error('Used witout Script!!!');
        }

        const linesCount = this.Script.LinesCount;

        this.LinesCount = linesCount;
        this.LinePlateVisible = new Array(linesCount);
        this.LineVisible = new Array(linesCount);
        this.LineStyles = new Array(linesCount);
        this.LineWidth = new Array(linesCount);
        this.LineColors = new Array(linesCount);
        this.PriceMultiplier = new Array(linesCount);
        this.TimeShift = new Array(linesCount);
        this.FLineNames = new Array(linesCount);
        this.EmptyValue = new Array(linesCount);
        this.DrawBegin = new Array(linesCount);
        this.LineMarkers = new Array(linesCount);
        // this.HistogrammStyles = new Array(linesCount);

        this.Script.Data = new DoubleMatrix(linesCount);
        // TODO подумать
        this.Script.LineMarkers = this.LineMarkers;

        this.FCloudBuffer = new HashtableLightCount();
        this.Script.FCloudBuffer = this.FCloudBuffer;

        for (let i = 0; i < linesCount; i++) {
            this.TimeShift[i] = 0;
            this.PriceMultiplier[i] = 1.0;
            this.Script.Data.set(i, new ResVector(i, this.TimeShift, this.PriceMultiplier, new ExpandDoubleVector()));
            // this.HistogrammStyles[i] = -1;
            this.LineVisible[i] = true;
            this.LinePlateVisible[i] = true;
            this.EmptyValue[i] = Number.MAX_VALUE;
            this.LineMarkers[i] = new MarkersVector();
            this.DrawBegin[i] = 0;
        }

        const values = this.Script.GetValues();

        let param = null;
        for (let i = 1; i <= linesCount; i++) {
            const lineIndex = i - 1;
            param = values['line' + i + '.Name'];
            if (param !== null && param !== undefined) {
                this.FLineNames[lineIndex] = param;
            }

            param = values['line' + i + '.Color'];
            if (param !== null && param !== undefined) {
                this.LineColors[lineIndex] = param;
            }

            param = values['line' + i + '.Style'];
            if (param !== null && param !== undefined) {
                this.LineStyles[lineIndex] = param;
            }

            param = values['line' + i + '.Width'];
            if (param != null) {
                this.LineWidth[lineIndex] = param;
            }

            param = values['line' + i + '.Symbol'];
            if (param !== null && param !== undefined) {
                this.SymbolCode[lineIndex] = param;
            }

            param = values['line' + i + '.Visible'];
            if (param !== null && param !== undefined) {
                this.LineVisible[lineIndex] = param;
            }
        }

        param = values.separateWindow;
        if (param !== null && param !== undefined) {
            this.SeparateWindow = param;
        }

        // TODO
        this.Script.Init();

        this.InitIndicatorLevelLines();

        this.Script.UpdateIndicatorLevelLineValueHandler = this.UpdateIndicatorLevelLineValueHandler.bind(this);

        this.CacheQuickAccessData();

        // TODO MB
        for (let i = 0; i < linesCount; i++) {
            this.DrawBegin[i] = this.Script.DrawBegin[i] || 0;
        }
    }

    public Properties (): DynProperty[] {
        let properties: DynProperty[] = [];
        try {
            properties = properties.concat(this.MainProperties());
            properties = properties.concat(this.AdditionalProperties());
        } catch (e) {
            ErrorInformationStorage.GetException(e);
        }
        return properties;
    }

    public MainProperties (): DynProperty[] {
        let properties: DynProperty[] = [];

        if (this.Script === null) {
            return properties;
        }

        properties = properties.concat(this.Script.Properties());

        return properties;
    }

    public AdditionalProperties (): DynProperty[] {
        const additionalProperties: DynProperty[] = [];

        let curGroup = '';
        // Загоняем общие параметры
        for (let i = 0; i < this.LinesCount; i++) {
            const levelLine = this.getLineData(i);// as ConstantVector;
            const isLevelLine = levelLine.isConstantVector;// TODO levelLine != null;
            // #97150
            const lineNumber = this.LinesCount === 1 ? '' : (i + 1);

            curGroup = Resources.getResource('property.Line');
            curGroup += ' #' + lineNumber;
            curGroup += ' ' + Resources.getResource('property.lineProperties');

            let prop = new DynProperty('Visible ' + i, this.LineVisible[i], DynProperty.BOOLEAN, curGroup);
            prop.propertyComment = Resources.getResource('property.line.Visible') + ' ' + lineNumber;
            prop.sortIndex = 1;
            additionalProperties.push(prop);

            prop = new DynProperty('Draw Plate ' + i, this.LinePlateVisible[i], DynProperty.BOOLEAN, curGroup);
            prop.propertyComment = Resources.getResource('property.line.plateVisible').replace('{0}', lineNumber.toString());
            prop.sortIndex = 8;
            additionalProperties.push(prop);

            prop = new DynProperty('Line ' + i, new ColorStyleWidth(this.LineColors[i], this.LineStyles[i], this.LineWidth[i]) /* { AllLineStyles = true } */, DynProperty.COLOR_STYLE_WIDTH, curGroup);
            prop.propertyComment = Resources.getResource('property.Line') + ' ' + lineNumber;
            prop.sortIndex = 2;
            prop.objectVariants = IndicatorScriptBase.GetLineTypesArray();
            prop.objectVariants.useStyleProperties = true;
            additionalProperties.push(prop);

            prop = new DynProperty('Line Name ' + i, this.FLineNames[i], DynProperty.STRING, curGroup);
            prop.propertyComment = Resources.getResource('property.line.lineName') + ' ' + lineNumber;
            prop.sortIndex = 3;
            additionalProperties.push(prop);

            if (!isLevelLine) {
            // remove
            // prop = new DynProperty("Y factor " + i, this.PriceMultiplier[i], DynProperty.DOUBLE, curGroup);
            // prop.propertyComment = Resources.getResource("property.line.yFactor") + " " + lineNumber;
            // prop.sortIndex = 4;
            // additionalProperties.push(prop);
            // #97150
                if (!this.Script.HideLineTimeShift) {
                    prop = new DynProperty('Time Shift ' + i, this.TimeShift[i], DynProperty.INTEGER, curGroup);
                    prop.propertyComment = Resources.getResource('property.line.timeShift') + ' ' + lineNumber;
                    prop.maximalValue = Indicator.NUMERIC_MAX_VALUE;
                    prop.minimalValue = -Indicator.NUMERIC_MAX_VALUE;
                    prop.sortIndex = 6;
                    additionalProperties.push(prop);
                }
            } else {
                prop = new DynProperty('Value ' + i, levelLine.Value, DynProperty.DOUBLE, curGroup);
                prop.propertyComment = Resources.getResource('property.line.value') + ' ' + lineNumber;
                prop.maximalValue = Indicator.NUMERIC_MAX_VALUE;
                prop.minimalValue = -Indicator.NUMERIC_MAX_VALUE;
                prop.increment = 0.00001;
                prop.decimalPlaces = 5;
                prop.sortIndex = 4;
                additionalProperties.push(prop);
            }
        }

        // TODO не надо на вебе пока
        // var aggProp = new DynProperty("ChartObjectTFList", TFConfig, DynProperty.CHATROBJECT_TIF_CONFIG, DynProperty.CHARTOBJECT_TIF_GROUP);
        // additionalProperties.push(aggProp);
        // TODO не надо на вебе пока
        // помечаем настройки линий, видимости и прочей чепухи из индикатора  как те которые нужно скрыть в TSL
        // for (let dp in additionalProperties)
        // {
        //    array = dp.objectVariants;
        //    if (dp.objectVariants == null || dp.objectVariants.length == 0)
        //        array = new object[DynpropertyHiddenMode.Hidden];
        //    else //что-то есть, нужно просто добавить в массив
        //    {
        //        //TODO WTF???????
        //        //Array.Resize<object>(ref array, array.length + 1);
        //        array[array.length - 1] = DynpropertyHiddenMode.Hidden;
        //    }
        //    dp.objectVariants = array;
        // }

        return additionalProperties;
    }

    public callBack (props: DynProperty[]): void {
        let NeedRecalculate = false;
        if (this.Script !== null) {
            NeedRecalculate = this.Script.callBack(props) || NeedRecalculate;
        }

        for (let i = 0; i < this.LinesCount; i++) {
            const levelLine = this.getLineData(i);// as ConstantVector;
            const isLevelLine = levelLine.isConstantVector;// TODO levelLine != null;

            let propName = 'Visible ' + i;
            let dp = DynProperty.getPropertyByName(props, propName);
            if (dp) {
                this.LineVisible[i] = dp.value;
            }

            propName = 'Draw Plate ' + i;
            dp = DynProperty.getPropertyByName(props, propName);
            if (dp) {
                this.LinePlateVisible[i] = dp.value;
            }

            propName = 'Line ' + i;
            dp = DynProperty.getPropertyByName(props, propName);
            if (dp) {
                this.LineColors[i] = dp.value.Color;
                this.LineStyles[i] = dp.value.Style;
                this.LineWidth[i] = dp.value.Width;
            }

            propName = 'Line Name ' + i;
            dp = DynProperty.getPropertyByName(props, propName);
            if (dp) {
                this.FLineNames[i] = dp.value;
            }

            if (!isLevelLine) {
            // remove
            // propName = "Y factor " + i;
            // dp = DynProperty.getPropertyByName(props, propName);
            // if (dp)
            //    this.PriceMultiplier[i] = dp.value;
                propName = 'Time Shift ' + i;
                dp = DynProperty.getPropertyByName(props, propName);
                if (dp) {
                    this.TimeShift[i] = dp.value;
                }
            } else {
                propName = 'Value ' + i;
                dp = DynProperty.getPropertyByName(props, propName);
                if (dp) {
                    levelLine.Value = dp.value;
                }
            }
        }

        // TODO MB
        for (let i = 0; i < this.LinesCount; i++) {
            this.DrawBegin[i] = this.Script.DrawBegin[i] || 0;
        }

        if (this.Script !== null) {
            this.Script.ParametersRecalculationCallBack();
        }

        if (NeedRecalculate) {
            this.Refresh();
        }
    }

    // #97150
    public ShowOnTop (): boolean {
        return !this.Script.HideOnTop;
    }

    public InitIndicatorLevelLines (): void {
        const len = this.Script.LevelLines.length;
        for (let i = 0; i < len; i++) {
            const lvl = this.Script.LevelLines[i];

            this.SetLevelValue(lvl.LineNumber, lvl.Level);
            this.SetLevelStyle(lvl.LineNumber, lvl.style, lvl.width, lvl.color);
            this.FLineNames[lvl.LineNumber] = lvl.Name;

            lvl.Vector = this.Script.Data.get(lvl.LineNumber);
            this.LinePlateVisible[lvl.LineNumber] = true;
            this.LineVisible[lvl.LineNumber] = true;
        }
    }

    public UpdateIndicatorLevelLineValueHandler (rowNumber, value): void {
        this.SetLevelValue(rowNumber, value);
    }

    public SetLevelValue (rowNumber, value): void {
        for (let i = 0; i < this.Script.Data.Length; i++) {
            const vector = this.Script.Data.get(i);
            if (vector !== null && vector.RowNumber === rowNumber && vector.isConstantVector) {
                vector.Value = value;
                return;
            }
        }

        this.LinesCount += 1;
        this.TimeShift.push(0);
        const cv = new ConstantVector(value, rowNumber, this.TimeShift, this.PriceMultiplier);
        const vector = this.Script.Data.get(0);
        for (let i = 0; i < vector.Length; i++) {
            cv.Vector.Add(0.0);
        }

        // TODO MB
        this.EmptyValue[this.LinesCount - 1] = Number.MAX_VALUE;
        this.Script.Data.Length++;
        //
        this.Script.Data.set(this.LinesCount - 1, cv);
    }

    public SetLevelStyle (index, draw_style, line_width, clr): void {
        this.LineStyles[index] = draw_style;
        this.LineWidth[index] = line_width;
        this.LineColors[index] = clr;
    }

    public CacheQuickAccessData (): void {
        this.quickAccessData = null;
        this.quickAccessData = new ExpandDoubleVector(this.Script.Data.Length);
        for (let i = 0; i < this.Script.Data.Length; i++) {
            this.quickAccessData[i] = this.Script.Data.get(i).Vector;
        }
    }

    public getLineData (lineNumber) {
        if (this.Script === null) {
            throw new Error('Script Error');
        }

        if (this.Script.Data) {
            return this.Script.Data.get(lineNumber);
        } else {
            return null;
        }
    }

    get CloudBuffer (): HashtableLightCount { return this.FCloudBuffer; }

    // TODO. Indicator cleanup after instrument change.
    public async Refresh (): Promise<void> {
        if (isNullOrUndefined(this.Script) || isNullOrUndefined(this.FParent)) {
            return;
        }

        // TODO. UGLY. Refactor.
        await this.Script.RefreshPromise();

        this.cleanData();

        const len = this.FParent.FNonEmptyCashArrayCount;
        for (let i = 0; i < len; i++) {
            this.NextBar();
            this.Script.OnQuote();
        }

        await this.Script.AfterRefreshPromise();
    }

    public OnQuote (): void {
        if (this.Script !== null) {
            this.Script.OnQuote();
        }
    }

    public NextBar (): void {
        if (this.Script !== null) {
            this._NextBar();
            this.Script.NextBar();
        }
    }

    public _NextBar (): void {
        const dataLength = this.quickAccessData.Length;
        for (let i = 0; i < dataLength; i++) {
            this.quickAccessData[i].Add(this.EmptyValue[i]);
        }

        if (this.FCloudBuffer.HasValues) {
            for (let i = 0; i < this.FCloudBuffer.Count; i++) {
                this.FCloudBuffer.Get(i).Add(0.0);
            }
        }
        // +++
        for (let i = 0; i < this.LineMarkers.length; i++) {
            this.LineMarkers[i].Add();
        }

        this.FCount = this.quickAccessData[0].Length;
    }

    public getCustomName (): string {
        if (this.Script !== null) {
            return this.Script.customName;
        }

        return '';
    }

    public ShortName (): string {
        if (this.Script !== null) {
            return this.Script.ShortName;
        }

        return '';
    }

    public Dispose (): void {
        if (this.Script !== null) {
            this.Script.Dispose();
        }
    }

    get Parent () { return this.FParent; }
    set Parent (value) {
        this.FParent = value;
        this.Script.setHistoricalData(new HistoricalData(this));
    }

    // GetCashItemCount -> FCount

    public _GetPrice (priceType, offset) {
        const count = this.FCount;
        return this.FParent.GetByType(count - 1 - offset, priceType);
    }

    public _GetLength (): number {
        return this.FCount;
    }

    private cleanData (): void {
        const dataLength = this.quickAccessData.Length;
        for (let i = 0; i < dataLength; i++) {
            this.quickAccessData[i].Clear();
        }

        // +++
        for (let i = 0; i < this.LineMarkers.length; i++) {
            this.LineMarkers[i].Clear();
        }
    }

    public static readonly NUMERIC_MAX_VALUE = 999999999;
}

// #region From PTLIndicator.cs

export class PTLIndicator { // PTLIndicator || function () { }
    public static GetStartIndex (parent, chart): number {
        if (!chart || !parent /* || !(parent is CashItemFile) */) {
            return 0;
        }

        return Math.max(chart.DataExtenderAmount - 100, 0);
    }
}

// #endregion From PTLIndicator.cs
