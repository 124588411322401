// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { HtmlScroll } from '../../Commons/HtmlScroll';
import { FilesHelper } from '../../Utils/FilesHelper';
import { ExportScreenTemplate } from '../../templates.js';
import { TerceraCheckBox } from '../elements/TerceraCheckBox';
import { PanelLocKeys } from '../UtilsClasses/FactoryConstants';
import { TerceraWindowBase } from './TerceraWindowBase';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { TerceraButtonStyle } from '../../Utils/Enums/ButtonEnums';

let instance = null;

export class ExportScreen extends TerceraWindowBase {
    public chbArray: any = null;
    public panel: any;
    public internalChange: boolean;

    constructor () {
        super();

        this.chbArray = [];
    }

    public override getType (): string { return 'ExportScreen'; }

    public override oninit (): void {
        super.oninit();

        this.localize();
    }

    public override localize (): void {
        void this.set({
            header: Resources.getResource('screen.export.header'),

            okText: Resources.getResource('screen.export.Export'),
            cancelText: Resources.getResource('screen.export.Cancel')
        });
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.on('cancelClick', this.close.bind(this));
        this.on('okClick', this.okClick.bind(this));
        this.on('ExportTypeChanged', this.exportTypeChanged.bind(this));
        this.on('onCheckedStateChange', this.onCheckAllStateChange.bind(this));

        const keys = ExportScreen.exportTo; const items = [];
        keys.forEach(function (key) {
            if (!Resources.isHidden(key)) {
                items.push({ text: Resources.getResource(key), value: key });
            }
        });
        void this.set({ cbItems: items });
        this.center();
    }

    public okClick (): void {
        this.exportToFile();
        this.close();
    }

    public exportToFile (): void {
        const data = this.getDataToExport();
        const filename = this.get('saveFileName');

        switch (this.get('exportType')) {
        case ExportScreen.CSV:
            this.exportToCSVFile(filename, data);
            break;
        case ExportScreen.EXCEL:
            this.exportToExcelFile(filename, data);
            break;
        }
    }

    public exportToCSVFile (filename, data): void {
        const preparedForCSV = this.prepareForCSVExport(data);

        FilesHelper.saveFile(filename, preparedForCSV);
    }

    public exportToExcelFile (filename, data): void {
        if (!data?.length) { return; }

        const table = [{
            Data: data,
            TableName: filename
        }];

        FilesHelper.saveExcelFile(filename, table);
    }

    public getDataToExport (): any[] // return Array of Arrays
    {
        const qt = this.panel.quickTableRactive.quickTable;
        const rows = qt.rowsArray;
        const cols = qt.sortedColumns;
        const allCols = qt.sortedColumns;
        const colsID = this.getColsIDToExport();
        const result = [];

        if (colsID.length === 0) { return result; }

        const headers = [];
        for (let j = 0; j < colsID.length; j++) { headers.push(cols[colsID[j]].headerText); }
        result.push(headers);

        const fuckingReplaceSymbol = String.fromCharCode(160);// не переносимый пробел нужно заметь на обычный
        const normalSymbol = String.fromCharCode(32);// не переносимый пробел нужно заметь на обычный
        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];
            if (row.visible) {
                const rowToExport = [];
                for (let j = 0; j < colsID.length; j++) {
                    const ind = cols[colsID[j]].PRIVATE.index;
                    const cell = row.cells[ind];
                    rowToExport.push(cell.formattedValue.replaceAll(fuckingReplaceSymbol, normalSymbol));
                }
                result.push(rowToExport);
            }
        }

        return result;
    }

    public prepareForCSVExport (arrOfArrays): string {
        let result = '';

        if (!arrOfArrays.length) {
            return result;
        }

        for (let i = 0; i < arrOfArrays.length; i++) {
            const row = arrOfArrays[i];
            for (let j = 0; j < row.length; j++) {
                result += row[j] + ';';
            }
            result += '\r\n';
        }

        return result;
    }

    public getColsIDToExport (): any[] {
        const colsToExport = [];
        const cbList = this.chbArray;

        for (let i = 0; i < cbList.length; i++) {
            const cb = cbList[i];
            if (cb.get('checked')) {
                colsToExport.push(cb.get('tag'));
            }
        }

        return colsToExport;
    }

    public populateCheckBoxList (table): void {
        const cols = table.sortedColumns;

        const cbListPanel = this.Controls.checkBoxList;

        let cbNum = 0;
        let chCount = 0;
        const f = this.oneCheck.bind(this);

        for (let i = 0; i < cols.length; i++) {
            if (!cols[i].hidden) {
                const colCheckBox = new TerceraCheckBox();

                void colCheckBox.set({
                    top: 3 + cbNum * 24,
                    left: 12,
                    text: cols[i].headerText,
                    checked: cols[i].visible,
                    tag: i
                });

                if (cols[i].visible) {
                    chCount++;
                }

                cbListPanel.addControl(colCheckBox);
                this.chbArray.push(colCheckBox);
                colCheckBox.on('CheckedStateChange', f);

                cbNum++;
            }
        }

        this.setInternalAllChecked(cbNum === chCount);

        const div = this.find('.js-checkboxlist-panel');
        if (div) {
            HtmlScroll.addScroll(div);
        }
    }

    public setInternalAllChecked (value): void {
    // TODO to think
        this.internalChange = true;
        void this.set('Checked', value).then(function () { this.internalChange = false; }.bind(this));
    }

    public oneCheck (sender): void {
        if (this.internalChange) {
            return;
        }

        const cbList = this.chbArray;
        let res = true;

        for (let i = 0; i < cbList.length; i++) {
            res = res && cbList[i].get('checked');
        }

        if (this.get('Checked') !== res) {
            this.setInternalAllChecked(res);
        }
    }

    public onCheckAllStateChange (context, value): void // Check all CheckBox's logic
    {
    // TODO to think
        if (this.internalChange) {
            return;
        }

        const cbList = this.chbArray;

        this.internalChange = true;

        for (let i = 0; i < cbList.length; i++) {
            cbList[i].set('checked', value);
        }

        this.internalChange = false;
    }

    public exportTypeChanged (context, value): void {
        void this.set('exportType', value);
        void this.set('saveFileName', this.generateFileName());
    }

    public updateControls (panel): void {
        this.panel = panel;

        const qtr = panel.quickTableRactive; // Check box list
        if (qtr?.quickTable) {
            this.populateCheckBoxList(qtr.quickTable);
        }

        void this.set('saveFileName', this.generateFileName());
    }

    public generateFileName (): string {
        const dateStr = DateTimeUtils.formatDate(new Date(), 'DD.MM.YYYY_HH-mm-ss');
        const fileType = this.get('exportType') == ExportScreen.CSV ? '.csv' : ''; // adding extension for Excel in FilesHelper.saveExcelFile
        const fileName = Resources.getResource(PanelLocKeys[this.panel.getType()]) + '_' + dateStr + fileType;

        return fileName;
    }

    public static show (panel): void {
        if (instance) {
            instance.close();
        }

        instance = new ExportScreen();
        instance.on('teardown', function () {
            instance = null;
        });
        MainWindowManager.MainWindow.addControl(instance);

        instance.updateControls(panel);
        instance.setFocus();
    }

    public static readonly CSV = 'screen.export.CSV';
    public static readonly EXCEL = 'screen.export.Excel';

    public static readonly exportTo = [ExportScreen.CSV, ExportScreen.EXCEL];
}

TerceraWindowBase.extendWith(ExportScreen, {
    data: function () {
        return {
            movable: true,
            resizable: false,
            showFooter: false,
            focused: true,

            Checked: false,

            width: 273,
            height: 425,
            zIndex: 300,

            cbItems: [],

            btnOKStyle: TerceraButtonStyle.Blue,
            btnCancelStyle: TerceraButtonStyle.Standard,
            style_addition_header: 'js-ExportScreen-AdditionalHeader',

            exportType: ExportScreen.CSV,
            saveFileName: '',
            cbFormatTooltip: 'screen.export.selectFormat.tt',
            checkAllText: Resources.getResource('screen.export.checkAllText')
        };
    },
    partials: { bodyPartial: ExportScreenTemplate }
});
