// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// https://tp.traderevolution.com/entity/113979

export class UUID {
    public static getAppInstallID (): string {
        const storedID = localStorage.getItem('AppInstallID');
        if (storedID) {
            return storedID;
        }

        const newID = UUID.generateUUIDv5();

        localStorage.setItem('AppInstallID', newID);
        return newID;
    }

    public static generateUUIDv5 (): string {
        return 'xxxxxxxx-xxxx-5xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0; const v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}
