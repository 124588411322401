// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class ChangeTradingPasswordMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_CHANGE_TRADING_PASSWORD_REQUEST, fieldSet);
    }

    public getUserId (): number {
        return this.getFieldValue(FieldsFactory.FIELD_USER_ID);
    }

    public setUserId (userId): void {
        this.setFieldValue(FieldsFactory.FIELD_USER_ID, userId);
    }

    public getPassword (): string {
        return this.getFieldValue(FieldsFactory.FIELD_PASSWORD);
    }

    public setPassword (password: string): void {
        this.setFieldValue(FieldsFactory.FIELD_PASSWORD, password);
    }

    public getNewPassword (): string {
        return this.getFieldValue(FieldsFactory.FIELD_NEWPASSWORD);
    }

    public setNewPassword (password: string): void {
        this.setFieldValue(FieldsFactory.FIELD_NEWPASSWORD, password);
    }
}
