// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Instrument } from './Instrument';

export class AccountOperationReport {
    public AccountId = '';
    public AccountName = '';
    public InstrumentTradableID = 0;
    public TradableInstrumentName = '';
    public Date = 0;
    public OperationTypeId = 0;
    public OperationTypeName = '';
    public OperationID = '';
    public OpenPrice: any = '';
    public ClosePrice: any = '';
    public Amount = 0;
    public CurrencyName = '';
    public IsBuy = false;
    public Quantity = 0;
    public Instrument: Instrument | null = null;

    get IsTrading (): boolean {
        return this.OperationTypeId === 1;
    }
}
