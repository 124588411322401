// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { BaseExtLink } from './BaseExtLink';
import { BrandingToolLinkGenerator } from './Branding/BrandingToolLinkGenerator';

export class NoneExtLink extends BaseExtLink {
    generateUrl (): void {
        const url = BrandingToolLinkGenerator.generateLinkNoneURL(this);
        if (url) {
            this.url = url;
            this.updateValue?.(url);
        }
    }
}
