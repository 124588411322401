// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { Rectangle } from '@shared/commons/Geometry';
import { QuickTableColumn } from '../QuickTable/QuickTableColumn';
import { Control, ControlEvents } from '../Control';
import { KeyCode, KeyEventProcessor } from '@shared/commons/KeyEventProcessor';
import { TerceraLookupTemplate } from '../../../templates.js';
import { ContainerControl } from '../ContainerControl';
import { QuickTableCell } from '../QuickTable/QuickTableCell';
import { QuickTableRactive } from '../QuickTable/QuickTableRactive';
import { QuickTableRow } from '../QuickTable/QuickTableRow';
import { TerceraMenu } from '../TerceraMenu';
import { TerceraPanel } from '../TerceraPanel';
import { TextBoxEvents } from '../TerceraTextBox';
import { popupErrorHandler } from '@shared/utils/AppHandlers';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { ThemeManager } from '../../misc/ThemeManager';
import $ from 'jquery';
import { ResourcesManager } from '@shared/commons/properties/ResourcesManager';

export class TerceraLookup extends ContainerControl {
    public button: any = null;
    public textBox: any = null;
    public items: any = [];
    public autoCompliteQuickTable: any = null;
    public currentNameFilter: string = null;
    public asyncPopulateInterval: any = null;
    public fromDropDownFormOrAutoComplite = false;
    public onChangeValue: CustomEvent = null;
    public callBack: any = null;
    public isMultiSelect = false;
    public selectedItemIndex = -1;
    public selectedItem: any = null;
    public needCloseTableAfterClick = true;
    public AUTOCOMPLETE_MAX_ROWS_COUNT = 10;
    public isFormShowed = false;
    static autoCompleteTablesContainer: any;
    public lastKeyCode: any;

    public override getType (): string { return 'TerceraLookup'; }

    public override oninit (): void {
        super.oninit();

        this.onChangeValue = new CustomEvent();
    }

    private completeBlock (): void {
        this.onChangeValue = new CustomEvent(); // Need for QuickTableAccountLookup and QuickTableInstrumentLookup
        this.onChangeValue.Subscribe(this.runCallback.bind(this), this);

        this.on('lookupBtnClick', this.showForm);
        this.observe('haveError', function (newValue) {
            void this.set({ errorStyle: (newValue ? 'error' : '') });
        });

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.createAutoCompleteTable();

        // TODO. oncomplete is still being executed after dispose/teardown.
        // Reproduced when PropertySetupScreen is called from chart.
        // It might be due to a fast switching of pages.
        // PanelSettingsScreen.selectPage() method.
        if (isNullOrUndefined(this.Controls.lookupTB)) return;

        this.textBox = this.Controls.lookupTB;
        this.textBox.on(TextBoxEvents.TextChanged, this.onTextChange.bind(this));
        this.textBox.on('keyDown', this.onKeyDown.bind(this));
        this.textBox.on('mouseWheel', this.onMouseWheel.bind(this));

        this.on(ControlEvents.LostFocus, this.onLostFocus.bind(this));

        this.setSelectValue(this.get('selectedItem') || this.items[0], /* suppressEvents */true);

        this.observe('selectedItem', this.setSelectValue, { init: false });

        ThemeManager.onThemeChange.Subscribe(this.themeChange, this);
        this.themeChange();
        this.completeBlock();
    }

    // Because of lookup control caching in fx board panel template.
    public override onunrender (): void {
        if (super.onunrender) {
            super.onunrender();
        }

        const qt = this.autoCompliteQuickTable;
        if (!isNullOrUndefined(qt)) qt.set('visible', false);
    }

    public override dispose (): void {
        this.stopQTUpdate();
        ThemeManager.onThemeChange.UnSubscribe(this.themeChange, this);
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);
        this.removeAutoCompleteTable();

        super.dispose();
    }

    public override onteardown (): void {
        this.lookupErrorHideShow(false);
        this.dispose();
        super.onteardown();
    }

    public localize (): void {
        void this.set('btnTooltip', this.getButtonTooltip());
    }

    public getButtonTooltip (): string {
        return '';
    }

    public showForm (): void {
        this.isFormShowed = true;
    }

    public closeForm (): void {
        this.isFormShowed = false;
    }

    public setItems (newItems): void {
        if (isNullOrUndefined(newItems)) { return; }

        const keys = Object.keys(newItems);
        const items = newItems;
        let j;
        const len = keys.length;
        this.items = [];
        for (j = 0; j < len; j++) {
            this.items.push(items[keys[j]]);
        }
    }

    public onBtnClick (): boolean {
        return false;
    }

    public onTextChange (context, value: string): void {
        if (this.fromDropDownFormOrAutoComplite) {
            this.fromDropDownFormOrAutoComplite = false;
            return;
        }
        // if (value === '')
        // {
        //    this.currentNameFilter = null;
        //    this.populateAutoCompliteAsync();
        // }
        // else
        // {
        this.currentNameFilter = value.toLowerCase();
        this.populateAutoCompliteAsync();
    // }
    }

    public populateAutoCompliteAsync (): void {
        clearTimeout(this.asyncPopulateInterval);
        this.asyncPopulateInterval = null;
        this.asyncPopulateInterval = setTimeout(this.populateAutoComplite.bind(this), 300);
    }

    public populateAutoComplite (): void {
        const qTable = this.autoCompliteQuickTable;
        qTable.quickTable.ClearRows();

        if (this.currentNameFilter !== '') {
            this.fillAutoCompliteTableByItems();
        } else {
            qTable.set({
                visible: false
            });
            this.lookupErrorHideShow(false);
            return;
        }

        const rowHeight = qTable.quickTable.rowHeight;
        const rowsCount = qTable.quickTable.visibleRowCount(); // rowsArray.length;
        let visibleRowsCount = this.AUTOCOMPLETE_MAX_ROWS_COUNT;
        if (rowsCount < visibleRowsCount) {
            visibleRowsCount = rowsCount;
        }

        if (rowsCount > 0) {
            qTable.set({
                visible: true,
                height: rowHeight * visibleRowsCount + 2
            });
            this.lookupErrorHideShow(false);
        } else {
            qTable.set({
                visible: false
            });
            this.lookupErrorHideShow(true);
        }
    }

    public fillAutoCompliteTableByItems (): void {
        const qTable = this.autoCompliteQuickTable;

        let findStr = '';
        if (this.currentNameFilter !== null) {
            findStr = this.currentNameFilter.toLowerCase();
        }

        const items = this.items;
        const len = items.length;
        // const qTableWidth = this.get('width');
        for (let j = 0; j < len; j++) {
            const curItem = items[j];
            const curItemStr = curItem.toString(true);

            if (this.currentNameFilter !== null) {
                const curItemFindStr = curItemStr.toLowerCase();
                if (curItemFindStr.indexOf(findStr) === -1) {
                    continue;
                }
            }

            const r = new QuickTableRow();
            r.id = j;
            const cell = new QuickTableCell();
            cell.value = curItem;
            cell.formattedValue = curItemStr;
            cell.TruncateTextOverflowAddingEllipsis = true; // #116684
            r.cells.push(cell);
            qTable.quickTable.AddRow(r);

        // qTableWidth = Math.max(qTableWidth, ControlsUtils.GetTextWidth(curItemStr, qTable.quickTable.HeaderFont));
        }

        // qTable.set('width', qTableWidth);
        qTable.set('width', 400);
    }

    public onAutoCompliteSelectionChanged (fromMouseClick): void {
        if (fromMouseClick) {
            this.onAutoCompliteSelectRow(this.autoCompliteQuickTable);
        }
    }

    public onAutoCompliteSelectRow (qTable: QuickTableRactive): void {
        const selectRowId = qTable.quickTable.selectedRowIds[0];
        const defaultRow = qTable.quickTable.rowsArray[0];
        if (selectRowId === undefined) {
            this.setSelectValue(this.getTypingLookupValueForSet(this.textBox.get('text'), defaultRow));
        } else {
            const selectRow = qTable.quickTable.rows[selectRowId];
            if (isNullOrUndefined(selectRow)) {
                this.setSelectValue(this.getTypingLookupValueForSet(this.textBox.get('text'), defaultRow));
            } else {
                this.setSelectValue(selectRow.cells[0].value);
            }
        }

        if (this.needCloseTableAfterClick) {
            void qTable.set({ visible: false });
            qTable.quickTable.selectedRowIds = [];
            delete qTable.quickTable.lastSelectedRowId;
        }
    }

    public getTypingLookupValueForSet (textValueIndificator, defaultRow?): any {
        return null;
    }

    public dropDownFormCallBack (result): void {
        this.setSelectValue(result);
    }

    public onLostFocus (): void {
        clearTimeout(this.asyncPopulateInterval);
        this.asyncPopulateInterval = null;
        this.autoCompliteQuickTable.set({ visible: false });
        this.autoCompliteQuickTable.lostFocus();

        if (this.get('selectedItem') !== this.selectedItem) {
            this.setSelectValue(this.selectedItem);
        }

        this.lookupErrorHideShow(false);
    }

    public onKeyDown (event): boolean {
        if (!this.get('enabled') || !this.get('focused')) {
            return true;
        }
        this.lastKeyCode = event.original.keyCode;
        const keyCode = event.original.keyCode;

        if (keyCode !== KeyCode.UP &&
        keyCode !== KeyCode.DOWN &&
        keyCode !== KeyCode.ENTER &&
        keyCode !== KeyCode.ESC) {
            return true;
        }

        const autoCompliteQuickTable = this.autoCompliteQuickTable;

        if (autoCompliteQuickTable.get('visible')) {
        // HACK.
            if (keyCode === KeyCode.ENTER) {
                this.onAutoCompliteSelectRow(autoCompliteQuickTable);
                this.textBox.selectAll();
            } else if (keyCode === KeyCode.ESC) {
                this.onLostFocus();
            } else {
                autoCompliteQuickTable.quickTable.trySelectUpDown(keyCode, false);
            }
        } else if (keyCode === KeyCode.ESC) {
            return;
        } else if (keyCode !== KeyCode.ENTER) {
            this.moveToItem(keyCode === KeyCode.DOWN);
        } else if (keyCode === KeyCode.ENTER) {
            this.onAutoCompliteSelectRow(autoCompliteQuickTable);
            this.textBox.selectAll();
        }
        this.lastKeyCode = -1;
        return false;
    }

    // If lookup's table is focused;
    public onAutoCompleteGlobalKeyDownHandled (): void {
        if (KeyEventProcessor.currentButton === KeyCode.ENTER) {
            this.onAutoCompliteSelectRow(this.autoCompliteQuickTable);
        }
    }

    public override onMouseWheel (context): boolean {
        if (!this.get('enabled') || !this.get('focused') ||
        this.autoCompliteQuickTable.get('visible')) {
            return true;
        }

        this.moveToItem(context.event.deltaY < 0);
        return false;
    }

    public moveToItem (next: boolean): void {
        const newIndex = this.selectedItemIndex + (next ? 1 : -1);

        if (newIndex >= 0 && newIndex < this.items.length) {
            this.selectedItemIndex = newIndex;
            this.setSelectValue(this.items[newIndex]);
            this.textBox.selectAll();
        }
    }

    // TODO. Optimize way of finding selected index.
    public setSelectValue (newSelectedItem, suppressEvents?): void {
        if (isNullOrUndefined(newSelectedItem)) {
            return;
        }

        const newText = newSelectedItem.toString(true);
        this.setLookUpText(newText);

        if (this.selectedItem === newSelectedItem) {
            return;
        }

        this.selectedItem = newSelectedItem;
        // For template data binding.
        void this.set('selectedItem', newSelectedItem);

        if (isNullOrUndefined(suppressEvents)) {
            this.onChangeValue?.Raise(newSelectedItem);
            this.fire(TerceraLookupEvents.SelectedItemChanged, newSelectedItem);
        }

        this.updateSelectedItemIndex(newSelectedItem);
    }

    public setLookUpText (newText: string): void {
        this.fromDropDownFormOrAutoComplite = true;
        if (!isNullOrUndefined(this.textBox)) {
            this.textBox.set('text', newText).then(function () {
                this.fromDropDownFormOrAutoComplite = false;
            }.bind(this));
        }
        void this.set('labelText', newText);
    }

    public updateSelectedItemIndex (newSelectedItem): void {
    // Index of selected item has been found already.
        if (newSelectedItem === this.items[this.selectedItemIndex]) {
            return;
        }

        for (let i = 0; i < this.items.length; i++) {
            if (this.items[i] === newSelectedItem) {
                this.selectedItemIndex = i;
                break;
            }
        }
    }

    public override getX (): number {
        const offset = $(this.find('*')).offset();
        const posX = offset.left - $(window).scrollLeft();

        return posX;
    }

    public override getY (): number {
        const offset = $(this.find('*')).offset();
        const posY = offset.top - $(window).scrollTop();

        return posY;
    }

    public runCallback (acc): void {
        if (!isNullOrUndefined(this.callBack)) {
            this.callBack.call(this, acc);
        }
    }

    public correctQTPopupLocation (): void {
        const qt = this.autoCompliteQuickTable;
        if (isNullOrUndefined(qt?.quickTable)) {
            return;
        }

        const rowN = Math.min(qt.quickTable.visibleRowCount(), this.AUTOCOMPLETE_MAX_ROWS_COUNT);

        const p = TerceraMenu.CorrectPopupLocation(new Rectangle(
            this.getX(),
            this.getY() + 25,
            qt.get('width'),
            qt.quickTable.rowHeight * rowN));

        qt.setLocation(p.newX, p.newY);
    }

    public onQTVisibilityChanged (visible: boolean): void {
        if (visible) {
            this.correctQTPopupLocation();

            this.startQTUpdate();
        } else this.stopQTUpdate();
    }

    public startQTUpdate (): void {
        const qt = this.autoCompliteQuickTable;
        if (!isNullOrUndefined(qt)) {
            qt.GlobalKeyDownHandled.Subscribe(this.onAutoCompleteGlobalKeyDownHandled, this);
            qt.quickTable.OnSelectionChanged.Subscribe(this.onAutoCompliteSelectionChanged, this);
        }
        Control.Ticker.Subscribe(this.tick, this);
    }

    public stopQTUpdate (): void {
        const qt = this.autoCompliteQuickTable;
        if (!isNullOrUndefined(qt)) {
            qt.GlobalKeyDownHandled.UnSubscribe(this.onAutoCompleteGlobalKeyDownHandled, this);
            qt.quickTable.OnSelectionChanged.UnSubscribe(this.onAutoCompliteSelectionChanged, this);
        }
        Control.Ticker.UnSubscribe(this.tick, this);
    }

    public tick (): void {
        this.autoCompliteQuickTable.quickTable.Draw();
    }

    public themeChange (): void {
        this.autoCompliteQuickTable.themeChange();
        const qt = this.autoCompliteQuickTable.quickTable;
        qt.gridColor = ThemeManager.CurrentTheme.QuickTable_AutoCompleteGridColor;

        qt.selectedBackColor = ThemeManager.CurrentTheme.QuickTable_AutoCompleteSelectionBackColor;
        qt.hoveredBackColor = qt.selectedBackColor;

        qt.selectedForeColor = ThemeManager.CurrentTheme.QuickTable_AutoCompleteSelectionForeColor;
        qt.hoveredForeColor = qt.selectedForeColor;
    }

    public createAutoCompleteTable (): void {
        const autoCompleteTablesContainer = TerceraLookup.getAutoCompleteTablesContainer();
        const qTable = new QuickTableRactive();
        autoCompleteTablesContainer.addControl(qTable);

        void qTable.set({
            width: 200,
            height: qTable.quickTable.rowHeight * this.AUTOCOMPLETE_MAX_ROWS_COUNT,
            visible: false,
            additionalClass: 'quickTableOuter-position-absolute',
            autocompleteTable: true
        });
        qTable.quickTable.columns.push(new QuickTableColumn());
        qTable.quickTable.UpdateSortedColumns();
        qTable.setShowColumnHeaders(false);
        qTable.enableRowHover(true);
        qTable.observe('visible', this.onQTVisibilityChanged.bind(this));

        this.autoCompliteQuickTable = qTable;
    }

    public removeAutoCompleteTable (): void {
        const autoCompleteTablesContainer = TerceraLookup.getAutoCompleteTablesContainer();
        autoCompleteTablesContainer.removeControl(this.autoCompliteQuickTable);
    }

    public static getAutoCompleteTablesContainer (): TerceraPanel {
        if (!isNullOrUndefined(TerceraLookup.autoCompleteTablesContainer)) {
            return TerceraLookup.autoCompleteTablesContainer;
        }

        TerceraLookup.autoCompleteTablesContainer = new TerceraPanel({
            el: '#autoCompleteTablesContainer'
        });
        return TerceraLookup.autoCompleteTablesContainer;
    }

    public lookupErrorHideShow (isShow: boolean): void {
        if (isShow) {
            void this.set({ haveError: true });
        } else {
            popupErrorHandler.Hide(this);
            void this.set({ haveError: false });
        }
    }

    public showPopupError (errorTextForNumeric: string, headerText: string): void {
        if (!this.get('enabled') || !this.get('focused') || !isValidString(errorTextForNumeric)) {
            return;
        }

        popupErrorHandler.Show(this, errorTextForNumeric, headerText);
    }

    public override getAbsoluteRectangle (): Rectangle {
        const $root = $(this.find('div'));
        const $window = $(window);
        const offset = $root.offset();

        return new Rectangle(
            offset.left - $window.scrollLeft(),
            offset.top - $window.scrollTop(),
            $root.width(),
            $root.height());
    }
}

export enum TerceraLookupEvents {
    SelectedItemChanged = 'SelectedItemChanged'
}

ContainerControl.extendWith(TerceraLookup, {
    template: TerceraLookupTemplate,
    data: function () {
        return {
            btnClass: 'js-lookup-show',
            btnName: 'instrumentLookup',
            cssClass: 'js-tercera-lookup',
            errorStyle: '',
            selectedItem: null,
            labelText: '',
            labelShow: false,
            haveError: false,
            isReadOnly: false,
            btnTooltip: '',
            isInstrumentLookup: false,
            isPosAbsolute: false,
            tbIsVisible: true,
            showDisabledToolTip: false
        };
    }
});
