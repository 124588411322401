// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ContainerControl } from '../ContainerControl';
import { VerticalDetailsItemTemplate } from '../../../templates';
import { ControlsTypes } from '../../UtilsClasses/FactoryConstants';
import { type VerticalDetailsRowItem } from '../../../Commons/cache/VerticalPanel/VerticalDetailsRowItem';
import { ControlsUtils } from '../../UtilsClasses/ControlsUtils';

export class VerticalDetailsItem<GroupItemsType, GroupsType> extends ContainerControl {
    private item: VerticalDetailsRowItem<GroupItemsType, GroupsType> = null;
    captured: boolean = false;

    public getType (): ControlsTypes {
        return ControlsTypes.VerticalDetailsItem;
    };

    constructor () {
        super();
    }

    oncomplete (): void {
        super.oncomplete();
        this.on('onItemMouseDown', this.onMouseDown);
        this.observe('row', this.setItemRow);
    }

    onteardown (): void {
        this.item.unsubscribeOnUpDateEmit(this.setDataRow);
    }

    public onMouseDown (context): void {
        const typePointer = this.item.getTypePointer();
        this.fire('accountDetailsItemDown', typePointer);
        ControlsUtils.Capture.SetCapture(this, context.original);
    }

    public onMouseMove (context): void {
        this.fire('accountDetailsItemMove');
    }

    public onMouseUp (context): void {
        this.fire('accountDetailsItemUp');
    }

    private setItemRow (value: VerticalDetailsRowItem<GroupItemsType, GroupsType>): void {
        if (isNullOrUndefined(value)) {
            return;
        };

        this.item = value;
        this.item.subscribeOnUpDateEmit(this.setDataRow);
        this.setDataRow();
    }

    private readonly setDataRow = (): void => {
        const rowName = this.item.getItemName();
        const rowNameTooltip = this.item.getItemTooltip();
        const values = this.item.getItemValue();
        const colors = this.item.getItemColor();

        void this.set({
            rowName,
            rowNameTooltip,
            rowValueTooltip: values.toString(),
            firstRowValue: values.firstValue,
            secondRowValue: values.secondValue,
            firstValueColor: colors.firstValue,
            secondValueColor: colors.secondValue
        });
    };
}

ContainerControl.extendWith(VerticalDetailsItem,
    {
        template: VerticalDetailsItemTemplate,
        data: function () {
            return {
                row: null,
                rowName: '',
                rowNameTooltip: '',
                rowValueTooltip: '',
                firstRowValue: '',
                firstValueColor: '',
                secondRowValue: '',
                secondValueColor: ''
            };
        }
    });
