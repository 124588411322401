// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { TerceraChartTradingOperation } from '../../Chart/Utils/ChartConstants';
import { DataCache } from '../../Commons/DataCache';
import { CustomErrorClass, ErrorInformationStorage } from '../../Commons/ErrorInformationStorage';
import { GenerateReportMessages } from '../../Commons/GenerateReportMessages';
import { PositionEdit } from '../../Commons/cache/OrderParams/PositionEdit';
import { SLTPTriggerUtils } from '../../Commons/cache/OrderParams/SLTPTriggerUtils';
import { Resources } from '../../Localizations/Resources';
import { PanelNames } from '../../Controls/UtilsClasses/FactoryConstants';
import { SlTpPriceType } from '../Enums/Constants';
import { OperationType } from './OperationType';
import { OrderEditUpdateData } from './OrderEditUpdateData';
import { type IOrderParams } from './OrderParams';
import { TIF } from './OrderTif';
import { OrderType } from './OrderType';
import { SlTpUtils } from './SlTpUtils';
import { PlacedFrom } from './PlacedFrom';
import { Quantity } from './Quantity';
import { SlTpHolder } from './SlTpHolder';
import { BaseSettings } from '../../Commons/Settings/BaseGeneralSettingsWrapper';
import { BaseSettingsUtils } from '../../Commons/UtilsClasses/BaseGeneralSettingsUtilsWrapper';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Order } from '../../Commons/cache/Order';
import { SLTPMode } from '../../Commons/cache/OrderParams/SLTPEdit';
import { Position } from '../../Commons/cache/Position';
import { type OrderEditBase } from '../../Commons/cache/OrderParams/order-edit/OrderEditBase';

export class ChartTradingCore {
    isMobileChart = false;
    myTradeNumeric = null;
    myVisualWidget = null;
    chartOE = null;
    terceraChartRactive = null;
    TradingNumericErrorChecker = null;
    EditPositionScreen = null;
    ModifyOrderScreen = null;
    TerceraMessageBox = null;
    contextMenuHandler = null;
    MainWindowManager = null;

    constructor (isMobileChart = false) {
        this.isMobileChart = isMobileChart;
    }

    public ChartVisualTrading (order, newData, instrumentItem): any {
        const confirm = !this.isMobileChart;
        switch (newData.action) {
        case TerceraChartTradingOperation.ActionButton:{
            const tc = this.terceraChartRactive.terceraChart;
            const lastMouseMoveArgs = tc.lastMouseMoveArgs;
            const menu = tc.GetContextMenu(lastMouseMoveArgs, tc);
            // TODO?
            if (menu && !this.isMobileChart) {
                this.contextMenuHandler.Show(menu, lastMouseMoveArgs.ClientLocation.X, lastMouseMoveArgs.ClientLocation.Y);
            }
            return;
        }
        case TerceraChartTradingOperation.PlaceNewOrder:
            return this.placeNewOrderFromVisualTrading(newData.placeOrderParams, instrumentItem);

        case TerceraChartTradingOperation.CancelOrder:
            if (this.isMobileChart) { return null; }
            return DataCache.FOrderExecutor.cancelOrdersByIdPromise([order.OrderNumber], confirm, PlacedFrom.WEB_CHART_VISUAL);

        case TerceraChartTradingOperation.CancelOrdersAccount:
            void DataCache.FOrderExecutor.cancelOrdersByAccountPromise(order.Account, confirm, PlacedFrom.WEB_CHART_VISUAL);
            return;

        case TerceraChartTradingOperation.CancelOrdersSymbol:
            void DataCache.FOrderExecutor.cancelOrdersByInstrumentAndAccountPromise(order.Instrument, order.Accounttrue, confirm, PlacedFrom.WEB_CHART_VISUAL);
            return;

        case TerceraChartTradingOperation.ClosePosition:
            if (this.isMobileChart) { return null; }
            void DataCache.FOrderExecutor.closePositionsByIdPromise(
                [order.PositionId],
                confirm,
                PlacedFrom.WEB_CHART_VISUAL_DB_CLICK);
            return;

        case TerceraChartTradingOperation.ModifyOrderPosition:
            // TODO?
            if (this.isMobileChart) { return; }
            if (order.isClosingOrder() || order.isPosition) {
                this.EditPositionScreen?.show(order.PositionId,
                    PlacedFrom.WEB_CHART_VISUAL, null, true);
            } else {
                this.ModifyOrderScreen?.show(
                    order.OrderNumber,
                    PlacedFrom.WEB_CHART_VISUAL);
            }
            return;

        case TerceraChartTradingOperation.ModifyOrderQuantity:{
            // TODO?
            if (this.isMobileChart) {
                return;
            }

            const rect = newData.rect;
            const quantity = newData.quantity;

            this.myTradeNumeric.set({
                left: rect.X + 5,
                top: rect.Y + 25,
                width: rect.Width + 1,
                height: rect.Height,
                instrument: order.Instrument,
                noArrowCB: true,
                showArrows: false
            }).then(function () {
                this.myTradeNumeric.set({ value: quantity });
            }.bind(this));

            this.myTradeNumeric.set('visible', true);
            this.myTradeNumeric.Controls.QTYNumeric.setFocus();
            void new Promise(function (resolve, reject) {
                this.chartOE.parent.myTradeNumericPromise = resolve;
            }.bind(this)).then(function (quantity) {
                this.chartOE.parent.changeQuantity(order, { quantity: new Quantity(quantity, BaseSettingsUtils.displayAmountInLots()) });
            }.bind(this));

            return;
        }
        case TerceraChartTradingOperation.PlaceSL:
        case TerceraChartTradingOperation.ModifySL:
        case TerceraChartTradingOperation.PlaceTP:
        case TerceraChartTradingOperation.ModifyTP:
        case TerceraChartTradingOperation.ModifySLL:
            newData.placedFrom = PlacedFrom.WEB_CHART_VISUAL;
            if (order.isPosition) {
                if (order.IsPendingExerciseOptionStatus()) {
                    GenerateReportMessages.GenerateRefusalOnOptionExercise(order.GetInstrumentDisplayName()); // #122442
                }
                return this.changePositionSLTP(order, newData);
            } else {
                return this.changeOrderSLTP(order, newData);
            }

        case TerceraChartTradingOperation.RemoveSL:
        case TerceraChartTradingOperation.RemoveTP:
            newData.placedFrom = PlacedFrom.WEB_CHART_VISUAL;
            if (order.isPosition) {
                const sltp = order[newData.action == TerceraChartTradingOperation.RemoveSL ? 'SLOrder' : 'TPOrder'];
                void DataCache.FOrderExecutor.cancelOrdersByIdPromise([sltp.OrderNumber], confirm, PlacedFrom.WEB_CHART_VISUAL);
            } else {
                return this.changeOrderSLTP(order, newData);
            }
            return;

        case TerceraChartTradingOperation.MoveOrder:
            newData.placedFrom = PlacedFrom.WEB_CHART_VISUAL;
            return this.moveOrder(order, newData);

        case TerceraChartTradingOperation.ChangeToMarket:
            void DataCache.FOrderExecutor.changeOrdersToMarketPromise([order.OrderNumber], confirm, PlacedFrom.WEB_CHART_VISUAL);
            return;

        case TerceraChartTradingOperation.AlertReplacement:
            DataCache.AlertManager.ReplaceAlertFromChart(order, newData);
            return;

        case TerceraChartTradingOperation.EditAlert:{
            // TODO?
            if (this.isMobileChart) {
                return;
            }

            const panel = this.MainWindowManager?.Factory.addPanel(PanelNames.CreateAlertPanel);
            panel.set('movable', true);
            panel.center();
            panel.setUpdateParams(order);
            panel.setFocus();
            return;
        }
        case TerceraChartTradingOperation.RemoveAlert:
            DataCache.AlertManager.RemoveAlertProcess(order, null, confirm);
            return;

        case TerceraChartTradingOperation.ChartNewAlert:
            DataCache.AlertManager.ChartNewAlert(newData);
        }
    }

    public placeNewOrderFromVisualTrading (placeOrderParams, instrumentItem): any {
        if (!this.myVisualWidget || this.TradingNumericErrorChecker?.HasErrors(this.myVisualWidget)) {
            return this.WM_placeNewOrderFromVisualTrading(placeOrderParams, instrumentItem);
        }

        placeOrderParams.fromChart = true;
        placeOrderParams.place = true;
        placeOrderParams.lTif = this.myVisualWidget.get('selectedItem').value;

        placeOrderParams.expireTime = this.myVisualWidget.get('gtdDate');

        placeOrderParams.quantity = this.myVisualWidget.get('quantity');
        placeOrderParams.placedFrom = PlacedFrom.WEB_CHART_VISUAL;

        const chartOE = this.chartOE;
        placeOrderParams.productType = chartOE.get('productType');
        placeOrderParams.leverageValue = this.myVisualWidget.get('leverageValue');

        try {
            const ins = instrumentItem;
            const route = DataCache.getRouteByName(ins.Route);
            const isAllowedByTIFandOrderType = route.IsAllowableTif(placeOrderParams.lTif, placeOrderParams.lOrdType);

            if (!isAllowedByTIFandOrderType) {
                this.TerceraMessageBox?.Show(Resources.getResource('screen.error.title'), Resources.getResource('IsAllowedResponceReason.NotAllowedRouteOrderType'), this.TerceraMessageBox?.msgType.Error, null, null, false, true);
                return;
            }
        } catch (err) {
            console.error(err);
        }

        chartOE.OrderParamsSet(placeOrderParams);
    }

    public WM_placeNewOrderFromVisualTrading (orderParams: IOrderParams, instrumentItem): any {
        orderParams.fromChart = true;
        orderParams.place = true;
        // placeOrderParams["lTif"] = this.myVisualWidget.get("selectedItem").value;

        // placeOrderParams["expireTime"] = this.myVisualWidget.get("gtdDate");

        // placeOrderParams["quantity"] = this.myVisualWidget.get("quantity");
        orderParams.placedFrom = PlacedFrom.WEB_CHART_VISUAL;

        // placeOrderParams["productType"] = chartOE.get('productType')
        // placeOrderParams["leverageValue"] = chartOE.get('leverageValue')

        if (isNullOrUndefined(orderParams)) {
            return null;
        }

        const orderType = orderParams.lOrdType;
        const orderTypeObj = DataCache.OrderParameterContainer.OrderTypes[orderType];

        const orderEditCtorData = {
            dataCache: DataCache,
            // TODO. UGLY. Details are in OrderEditBase.ts
            sltpMode: SLTPMode.Offset
        };

        // TODO. Ugly.
        const orderEdit = orderTypeObj.createOrderEditObject(orderEditCtorData);

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            {
                account: orderParams.account,
                instrument: orderParams.instrument,
                side: orderParams.lBuySell,

                quantity: new Quantity(
                    orderParams.quantity,
                    BaseSettingsUtils.displayAmountInLots()),

                tif: new TIF(orderParams.lTif, orderParams.expireTime),
                productType: orderParams.productType,
                leverageValue: orderParams.leverageValue,
                placedFrom: orderParams.placedFrom
            }));

        const chartSLTPHolder = this.getChartSLTPHolder(orderParams);

        // TODO. Ugly.
        switch (orderType) {
        case OrderType.Limit:
            orderEdit.setLimitPrice(orderParams.dPrice);
            break;
        case OrderType.Stop:
            orderEdit.setStopPrice(orderParams.dStopPrice);
            break;
        case OrderType.StopLimit:
            orderEdit.setBasePrice(orderParams.dStopPrice);
            break;
            // case OrderType.OCO:
            //     this.setOCOOrderEdit(orderEdit, orderParams)
            //     break
        }

        // TODO. Ugly.
        orderEdit.setSLTP(chartSLTPHolder);

        return orderEdit;
    }

    public getChartSLTPHolder = function (orderParams: IOrderParams): SlTpHolder {
        // Chart SL/TP are always offset.
        // const offsetType = BaseSettings.ShowOffsetIn;
        const instrument = orderParams.instrument;

        const sltpHolder = new SlTpHolder();

        const slEnabled = !isNaN(orderParams.slPriceValue);
        // let trStopEnabled = this.get('enabledSL') && this.get('trStopChecked')

        if (slEnabled) {
            sltpHolder.StopLossPriceValue = orderParams.slPriceValue;
            // OrderUtils.toRawTicks(orderParams.slPriceValue, offsetType, instrument)

            // sltpHolder.StopLossPriceType = trStopEnabled
            //     ? SlTpPriceType.TrOffset
            //     : SlTpPriceType.Offset

            sltpHolder.StopLossPriceType = SlTpPriceType.Absolute;

            // if (this.get('isSLLVisible'))
            //     sltpHolder.StopLossLimitPriceValue =
            //         OrderUtils.toRawTicks(this.get('priceSLL'), offsetType, instrument)
        }

        const tpEnabled = !isNaN(orderParams.tpPriceValue);
        if (tpEnabled) {
            sltpHolder.TakeProfitPriceValue = orderParams.tpPriceValue;
            // OrderUtils.toRawTicks(orderParams.tpPriceValue, offsetType, instrument)

            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
        }

        const anyEnabled = slEnabled /* || trStopEnabled */ || tpEnabled;
        if (anyEnabled) // #110841
        {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetTriggersShortForInstrument(instrument);
        }

        return sltpHolder;
    };

    private moveOrder (order, newData): any {
        const newPrice = newData.price;
        const ins: Instrument = order.Instrument;
        const isLimitModify = newData.isLimitModify;

        const modifyOrdObj = this.createModifyOrderObject(order);

        if (modifyOrdObj === null) {
            if (newData.CancelCallback) {
                newData.CancelCallback();
                return;
            }
        }
        // Moving sl/tp along with order's price change.
        // TODO. UGLY as fook boi.
        // Trailing stop's sl/tp values are always in offset,
        // thus there's no need to change sl/tp.
        const ordTypes = OrderType;
        switch (order.OrderType) {
        case ordTypes.Stop:
        case ordTypes.StopLimit:
        case ordTypes.Limit:
        {
            // TODO. UGLY. It works just cuz JS doesn't give a dayum.
            const prevOrderPrice = modifyOrdObj.getBasePrice();
            let newOrderPrice = newPrice;
            if (order.OrderType === ordTypes.StopLimit) {
                modifyOrdObj.setBasePrice(newPrice, isLimitModify, true);
                newOrderPrice = modifyOrdObj.getBasePrice();
            }
            let priceDifferenceInTicks = SlTpUtils.getPriceDifferenceInTicks(prevOrderPrice, newOrderPrice, ins);

            if (priceDifferenceInTicks === 0) {
                break;
            }
            // Ewww.
            priceDifferenceInTicks *= newOrderPrice > prevOrderPrice ? 1 : -1;
            const sltpHolder: SlTpHolder = modifyOrdObj.getRawSLTP();

            if (isValidNumber(sltpHolder.StopLossPriceValue)) {
                if (sltpHolder.isSlAbsolute()) {
                    sltpHolder.StopLossPriceValue = ins.CalculatePrice(sltpHolder.StopLossPriceValue, priceDifferenceInTicks);
                    if (isValidNumber(sltpHolder.StopLossLimitPriceValue)) {
                        sltpHolder.StopLossLimitPriceValue = ins.CalculatePrice(sltpHolder.StopLossLimitPriceValue, priceDifferenceInTicks);
                    }
                }
            }

            if (isValidNumber(sltpHolder.TakeProfitPriceValue) && sltpHolder.isTpAbsolute()) {
                sltpHolder.TakeProfitPriceValue = ins.CalculatePrice(sltpHolder.TakeProfitPriceValue, priceDifferenceInTicks);
            }

            // TODO. Ugly. Method is not base,
            // it works just cuz JS doesn't give a dayum.
            modifyOrdObj.setSLTP(sltpHolder);
            break;
        }
        }

        modifyOrdObj.setBasePrice(newPrice, isLimitModify, true);
        modifyOrdObj.placedFrom = newData.placedFrom;

        if (this.isMobileChart) {
            return modifyOrdObj;
        }

        DataCache.FOrderExecutor.modifyOrderPromiseWithCallbacks(modifyOrdObj, null, null, this.isMobileChart)
            .then(function (confirmed) {
                if (newData.CancelCallback && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('MoveOrder error', 'ChartPanel.moveOrder', 'moveOrder -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);

                if (newData.CancelCallback) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
                // TODO. Refactor.
                modifyOrdObj.dispose();
            });
    }

    public createModifyOrderObject (order: Order): OrderEditBase {
        const orderTypeObj = DataCache.OrderParameterContainer.GetOrderType(order.OrderType);
        return orderTypeObj.createModifyOrderObject({
            dataCache: DataCache,
            order
        });
    }

    // #region SL/TP modify logic
    private changePositionSLTP (position: Position, newData): PositionEdit {
        const action = newData.action;
        const modifyPosObj = new PositionEdit({ dataCache: DataCache, position });

        const newPrice = newData.price;
        const newIsTrStop = newData.ts;
        let sltpHolder: SlTpHolder;
        switch (action) {
        case TerceraChartTradingOperation.PlaceTP:
            sltpHolder = this.addTP(position, newPrice);
            break;
        case TerceraChartTradingOperation.ModifyTP:
            sltpHolder = this.modifyTP(position, newPrice);
            break;
        case TerceraChartTradingOperation.PlaceSL:
            sltpHolder = this.addSL(position, newPrice, newIsTrStop);
            break;
        case TerceraChartTradingOperation.ModifySL:
            sltpHolder = this.modifySL(position, newPrice, newIsTrStop);
            break;
        case TerceraChartTradingOperation.RemoveTP:
            sltpHolder = this.removeTP(position);
            break;
        case TerceraChartTradingOperation.RemoveSL:
            sltpHolder = this.removeSL(position);
            break;
        case TerceraChartTradingOperation.ModifySLL:
            sltpHolder = this.modifySLL(position, newPrice);
            break;
        default:
            sltpHolder = position.createSlTpHolder();
            break;
        }

        if (sltpHolder.TakeProfitPriceValue || sltpHolder.StopLossPriceValue) {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetShortByOrder(position);
        }
        modifyPosObj.placedFrom = newData.placedFrom;
        modifyPosObj.setSLTP(sltpHolder);
        // Trailing stop implicit modification fix.
        // For instance: dragging of tp order causes tr stop order change as well
        // due to setting up trailing stop value in offset ticks.
        // Server treats it as an offset relative to current price...
        // Closing trailing stop order receives price updates from a server,
        // but Order.TrStopOffset field isn't recalculated accordingly.
        // Whatever. Refactor this pile of shit.
        modifyPosObj.setCanEditSLOrTrailingStop(
            action === TerceraChartTradingOperation.PlaceSL ||
            action === TerceraChartTradingOperation.ModifySL ||
            action === TerceraChartTradingOperation.RemoveSL ||
            action === TerceraChartTradingOperation.ModifySLL);
        modifyPosObj.setCanEditTP(
            action === TerceraChartTradingOperation.PlaceTP ||
            action === TerceraChartTradingOperation.ModifyTP ||
            action === TerceraChartTradingOperation.RemoveTP);

        if (this.isMobileChart) {
            return modifyPosObj;
        }

        DataCache.FOrderExecutor.modifyPositionPromise(modifyPosObj, this.isMobileChart)
            .then(function (confirmed) {
                if (newData.CancelCallback && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('ChangePositionSLTP error', 'ChartPanel.changePositionSLTP', 'changePositionSLTP -> modifyPositionPromise');
                ErrorInformationStorage.GetException(ex);

                if (newData.CancelCallback) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
                // TODO. Refactor.
                modifyPosObj.dispose();
            });
    }

    private changeOrderSLTP (order: Order, newData): any {
        const newPrice = newData.price;
        const newIsTrStop = newData.ts;
        const modifyOrdObj: OrderEditBase = this.createModifyOrderObject(order);
        const action = newData.action;
        let sltpHolder: SlTpHolder;
        switch (action) {
        case TerceraChartTradingOperation.PlaceTP:
            sltpHolder = this.addTP(order, newPrice);
            break;
        case TerceraChartTradingOperation.ModifyTP:
            sltpHolder = this.modifyTP(order, newPrice);
            break;
        case TerceraChartTradingOperation.PlaceSL:
            sltpHolder = this.addSL(order, newPrice, newIsTrStop);
            break;
        case TerceraChartTradingOperation.ModifySL:
            sltpHolder = this.modifySL(order, newPrice, newIsTrStop);
            break;
        case TerceraChartTradingOperation.ModifySLL:
            sltpHolder = this.modifySLL(order, newPrice);
            break;
        case TerceraChartTradingOperation.RemoveTP:
            sltpHolder = this.removeTP(order);
            break;
        case TerceraChartTradingOperation.RemoveSL:
            sltpHolder = this.removeSL(order);
            break;
        default:
            sltpHolder = order.createSlTpHolder();
            break;
        }

        if (sltpHolder.TakeProfitPriceValue || sltpHolder.StopLossPriceValue) {
            sltpHolder.SLTPTriggerShortValue = SLTPTriggerUtils.GetShortByOrder(order);
        }

        modifyOrdObj.placedFrom = newData.placedFrom;
        modifyOrdObj.setSLTP(sltpHolder);

        if (this.isMobileChart) {
            return modifyOrdObj;
        }

        DataCache.FOrderExecutor.modifyOrderPromiseWithCallbacks(modifyOrdObj, null, null, this.isMobileChart)
            .then(function (confirmed) {
                if (newData.CancelCallback && !confirmed) {
                    newData.CancelCallback();
                }
            })
            .catch(function () {
                const ex = new CustomErrorClass('ChangeOrderSLTP error', 'ChartPanel.changeOrderSLTP', 'changeOrderSLTP -> modifyOrderPromiseWithCallbacks');
                ErrorInformationStorage.GetException(ex);

                if (newData.CancelCallback) {
                    newData.CancelCallback();
                }
            })
            .finally(function () {
                // TODO. Refactor.
                modifyOrdObj.dispose();
            });
    }

    private addSL (tradingObject: Order | Position, slPrice: number, isTrStop: boolean): SlTpHolder {
        const isPosition = tradingObject instanceof Position;
        const sltpHolder: SlTpHolder = tradingObject.createSlTpHolder();
        const inOffset = sltpHolder.isTpEmpty() ? BaseSettings.isSlTpInOffset : !sltpHolder.isTpAbsolute();
        if (isTrStop) {
            const basePrice = tradingObject.GetBasePriceForTrailingStop();
            sltpHolder.StopLossPriceType = SlTpPriceType.TrOffset;
            sltpHolder.StopLossPriceValue = SlTpUtils.calculateTicks(basePrice, slPrice, tradingObject.Instrument);
        } else if (!isPosition && inOffset) {
            const price = tradingObject.Price;
            let slPriceType: SlTpPriceType;
            if (BaseSettings.isUseStopLimitInsteadStop()) {
                slPriceType = SlTpPriceType.Offset;
            } else if (!sltpHolder.isTpEmpty()) {
                slPriceType = sltpHolder.TakeProfitPriceType;
            } else {
                slPriceType = SlTpUtils.getSLTPPriceType(BaseSettings.offsetType);
            }
            const slPriceValue = slPriceType === SlTpPriceType.Percent ? SlTpUtils.calculatePercent(price, slPrice) : SlTpUtils.calculateTicks(price, slPrice, tradingObject.Instrument);
            sltpHolder.StopLossPriceType = slPriceType;
            sltpHolder.StopLossPriceValue = slPriceValue;
            if (BaseSettings.isUseStopLimitInsteadStop()) {
                sltpHolder.StopLossLimitPriceValue = BaseSettings.limitOffsetTicks;
            }
        } else {
            sltpHolder.StopLossPriceType = SlTpPriceType.Absolute;
            sltpHolder.StopLossPriceValue = slPrice;
            if (BaseSettings.isUseStopLimitInsteadStop()) {
                const slSign = tradingObject.BuySell === OperationType.Buy ? -1 : 1;
                sltpHolder.StopLossLimitPriceValue = tradingObject.Instrument.CalculatePrice(slPrice, BaseSettings.limitOffsetTicks * slSign);
            }
        }
        return sltpHolder;
    }

    private modifySL (tradingObject: Order | Position, slPrice: number, isTrStop: boolean): SlTpHolder {
        const sltpHolder: SlTpHolder = tradingObject.createSlTpHolder();
        if (isTrStop) {
            const basePrice = tradingObject.GetBasePriceForTrailingStop();
            sltpHolder.StopLossPriceType = SlTpPriceType.TrOffset;
            sltpHolder.StopLossPriceValue = SlTpUtils.calculateTicks(basePrice, slPrice, tradingObject.Instrument);
            sltpHolder.StopLossLimitPriceValue = null;
        } else if (sltpHolder.isSlAbsolute()) {
            const absoluteOffset = slPrice - sltpHolder.StopLossPriceValue;
            sltpHolder.StopLossPriceValue = slPrice;
            if (!sltpHolder.isSllEmpty()) {
                sltpHolder.StopLossLimitPriceValue = sltpHolder.StopLossLimitPriceValue + absoluteOffset;
            }
        } else {
            // Only in order case
            const newOffset = sltpHolder.StopLossPriceType === SlTpPriceType.Percent ? SlTpUtils.calculatePercent(tradingObject.Price, slPrice) : SlTpUtils.calculateTicks(tradingObject.Price, slPrice, tradingObject.Instrument);
            sltpHolder.StopLossPriceValue = newOffset;
        }
        return sltpHolder;
    }

    private modifySLL (tradingObject: Order | Position, sllPrice: number): SlTpHolder {
        const sltpHolder: SlTpHolder = tradingObject.createSlTpHolder();
        if (sltpHolder.isSlAbsolute()) {
            sltpHolder.StopLossLimitPriceValue = sllPrice;
        } else {
            // Only in order case
            const slPrice = tradingObject.GetStopLossInPriceValue();
            const sign = (tradingObject.BuySell === OperationType.Buy ? 1 : -1) * Math.sign(slPrice - sllPrice);
            sltpHolder.StopLossLimitPriceValue = SlTpUtils.calculateTicks(tradingObject.GetStopLossInPriceValue(), sllPrice, tradingObject.Instrument) * sign;
        }
        return sltpHolder;
    }

    private removeSL (tradingObject: Order | Position): SlTpHolder {
        const sltpHolder = tradingObject.createSlTpHolder();
        sltpHolder.StopLossPriceValue = NaN;
        sltpHolder.StopLossLimitPriceValue = null;
        return sltpHolder;
    }

    private addTP (tradingObject: Order | Position, tpPrice: number): SlTpHolder {
        const isPosition = tradingObject instanceof Position;
        const sltpHolder: SlTpHolder = tradingObject.createSlTpHolder();
        const inOffset = sltpHolder.isSlEmpty() || sltpHolder.isTrailingStop() ? BaseSettings.isSlTpInOffset : !sltpHolder.isSlAbsolute();
        if (!isPosition && inOffset) {
            const price = tradingObject.Price;
            let tpPriceType: SlTpPriceType;
            if (sltpHolder.isSlEmpty() || sltpHolder.isTrailingStop()) {
                tpPriceType = SlTpUtils.getSLTPPriceType(BaseSettings.offsetType);
            } else {
                tpPriceType = sltpHolder.StopLossPriceType;
            }
            const tpPriceValue = tpPriceType === SlTpPriceType.Percent ? SlTpUtils.calculatePercent(price, tpPrice) : SlTpUtils.calculateTicks(price, tpPrice, tradingObject.Instrument);
            sltpHolder.TakeProfitPriceType = tpPriceType;
            sltpHolder.TakeProfitPriceValue = tpPriceValue;
        } else {
            sltpHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            sltpHolder.TakeProfitPriceValue = tpPrice;
        }
        return sltpHolder;
    }

    private modifyTP (tradingObject: Order | Position, tpPrice: number): SlTpHolder {
        const sltpHolder: SlTpHolder = tradingObject.createSlTpHolder();
        if (sltpHolder.isTpAbsolute()) {
            sltpHolder.TakeProfitPriceValue = tpPrice;
        } else {
            // Only in order case
            const orderPrice = tradingObject.Price;
            sltpHolder.TakeProfitPriceValue = sltpHolder.TakeProfitPriceType === SlTpPriceType.Percent ? SlTpUtils.calculatePercent(orderPrice, tpPrice) : SlTpUtils.calculateTicks(orderPrice, tpPrice, tradingObject.Instrument);
        }
        return sltpHolder;
    }

    private removeTP (tradingObject: Order | Position): SlTpHolder {
        const sltpHolder: SlTpHolder = tradingObject.createSlTpHolder();
        sltpHolder.TakeProfitPriceValue = NaN;
        return sltpHolder;
    }
    // #endregion
}
