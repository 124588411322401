// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum CustomInstrumentListTypeEnum {
    None = -1, // hsa: этого нет на сервере
    ThematicList = 0,
    SectorDefinition = 1,
    IndustryDefenition = 2,
    MarketHeatMap = 3,
    IndiciesChart = 4,
    DefaultList = 5,
    CustomList = 6,
}
