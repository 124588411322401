// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectQuoteMessage } from './DirectQuoteMessage';
import { HistoryType } from '../../Utils/History/HistoryType';
import { Message } from './Message';
import { type SpreadPlan } from '../../Commons/cache/SpreadPlan';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type SpreadItem } from '../../Commons/cache/SpreadItem';

export class DirectQuote1Message extends DirectQuoteMessage {
    public volumeTotal: number | null = null;

    public Bid = Number.NaN;
    public BidSize = Number.NaN;

    public Ask = Number.NaN;
    public AskSize = Number.NaN;

    public LastPice: number | null = null;
    public LastSize: number | null = null;

    public Open: number | null = null;
    public High: number | null = null;
    public Low: number | null = null;
    public PrevClose: number | null = null;
    public AskOpen: number | null = null;
    public AskHigh: number | null = null;
    public AskLow: number | null = null;
    public AskPrevClose: number | null = null;

    public BidMainClose: any = null;
    public AskMainClose: any = null;

    public BidVenue: number | null = null;
    public AskVenue: number | null = null;

    public Ticks: number | null = null;
    public TicksPreMarket: number | null = null;
    public TicksPostMarket: number | null = null;
    public VolumePreMarket: number | null = null;
    public VolumePostMarket: number | null = null;
    public OpenInterest: number | null = null;
    public PrevSettlementPrice: number | null = null;
    public TotalVolume: number | null = null;

    // Фикс для DirectFX CGQ. Для форексных инструментов история приходит уже сагрегированная, необходимо агрегировать риал тайм данные по тому же алгоритму.
    // Если пришел Ask обновляем бар ask ценой, если bid то bid ценой
    // http://tp.pfsoft.net/entity/63639
    public updateByBid: any = null;

    constructor () {
        super(Message.CODE_QUOTE);

        this.Type = HistoryType.QUOTE_LEVEL1;
    }

    // #region TODO. Overloading. SpreadItem.

    public BidSpread_SP_Ins (SpreadPlan: SpreadPlan, ins: Instrument, round: boolean = true): number {
        if (SpreadPlan && ins && SpreadPlan.IsNotEmptyPlan(ins)) {
            return SpreadPlan.CalcBid(this.Bid, this.Ask, ins, round);
        } else {
            return this.Bid;
        }
    }

    // TODO. Overloading. SpreadItem.
    public BidSpread (spreadItem: SpreadItem): number {
        return spreadItem ? spreadItem.CalcBid(this.Bid, this.Ask, spreadItem.SpreadInstrument) : this.Bid;
    }

    // #region TODO. Overloading. SpreadItem.

    public AskSpread_SP_Ins (SpreadPlan: SpreadPlan, ins: Instrument, round: boolean = true): number {
        if (SpreadPlan && ins && SpreadPlan.IsNotEmptyPlan(ins)) {
            return SpreadPlan.CalcAsk(this.Bid, this.Ask, ins, round);
        } else {
            return this.Ask;
        }
    }

    // TODO. Overloading. SpreadItem.
    public AskSpread (spreadItem: SpreadItem): number {
        return spreadItem ? spreadItem.CalcAsk(this.Bid, this.Ask, spreadItem.SpreadInstrument) : this.Ask;
    }

    public LastSpread (SpreadPlan: SpreadPlan, ins: Instrument): number {
        if (SpreadPlan && ins && SpreadPlan.IsNotEmptyPlan(ins)) {
            if (ins.HistoryType === DirectQuoteMessage.QUOTE_LEVEL1) {
                return SpreadPlan.CalcBid(this.Bid, this.Ask, ins);
            } else if (ins.HistoryType === DirectQuoteMessage.QUOTE_ASK) {
                return SpreadPlan.CalcAsk(this.Bid, this.Ask, ins);
            } else if (this.LastPice !== null) {
                return this.LastPice;
            } else {
                return ins.Level1.GetLastPrice();
            }
        } else {
            if (ins.HistoryType === DirectQuoteMessage.QUOTE_LEVEL1) {
                return this.Bid;
            } else if (ins.HistoryType === DirectQuoteMessage.QUOTE_ASK) {
                return this.Ask;
            } else if (this.LastPice !== null) {
                return this.LastPice;
            } else {
                return ins ? ins.Level1.GetLastPrice() : Number.NaN;
            }
        }
    }

    get IsValid (): boolean {
        return !isNaN(this.Bid) && !isNaN(this.Ask);
    }

    // TODO.
    public GetQuote3Message (previousPrice): any {
        return null;
    /*
      var q3m = new Quote3Message();
      q3m.Symbol = Symbol;
      q3m.Route = Route;
      q3m.cTime = cTime;
      q3m.Price = Bid;
      q3m.Size = 1;

      Quote3Message.AgressorFlagType agressor = Quote3Message.AgressorFlagType.None;
      if (!isNaN(previousPrice))
      {
          if (q3m.Price > previousPrice)
              agressor = Quote3Message.AgressorFlagType.Ask;
          else if (q3m.Price < previousPrice)
              agressor = Quote3Message.AgressorFlagType.Bid;
      }
      q3m.LType = agressor;

      return q3m;
    */
    }
}
