// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '../../../../../Utils/Account/AccountFeature';
import { Resources } from '../../../../../Localizations/Resources';
import { BaseSettings } from '../../../../Settings/BaseGeneralSettingsWrapper';
import { Account } from '../../../Account';
import { BaseOrderWarning } from './BaseOrderWarning';

export class TodayGrossLessWarning extends BaseOrderWarning {
    public getText (): string {
        const warnGrossValue = BaseSettings.warnIfTodayGrossLessValue;
        const curAcc = this.orderEdit.account;
        const gross = Account.GetAccountFeature(AccountFeature.TodayGrossPNL, curAcc, curAcc.assetBalanceDefault) + curAcc.Profit; // if not account currency: curAcc.TodayGrossPNLCrossPrice + (curAcc.Profit / curAcc.getCrossPrice());
        if (BaseSettings.warnIfTodayGrossLess && warnGrossValue > gross) {
            const formattedGross = curAcc.assetBalanceDefault.formatPrice(gross);
            const formattedWarnGross = curAcc.assetBalanceDefault.formatPrice(warnGrossValue);
            const text = Resources.getResource('general.warnings.todayGrossLessText', [formattedGross, formattedWarnGross]);
            return text;
        }

        return '';
    }
}
