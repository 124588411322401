// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum MarginInfoGroupType {
    KEY_GROUP_RISKS,
    KEY_GROUP_FEES
}

export enum MarginInfoItemType {
    // 1.Risks
    KEY_BALANCE,
    KEY_AVAILABLE_FUNDS,
    KEY_INCOMING_FUNDS,
    KEY_MARGIN_AVAILABLE,
    KEY_INIT_MARGIN,
    KEY_MAINT_MARGIN,
    KEY_WARN_MARGIN,
    KEY_IMPACT_ON_PORTFOLIO,
    KEY_AFTER_TRADE_FUNDS,
    KEY_BLOCKED_FOR_STOCKS,
    KEY_SPREAD_INIT_LOSS,
    KEY_PL_PER_TICK,
    KEY_ALLOW_SHORT_POSITIONS,
    KEY_AFTER_TRADE_CASH,
    // 2.Fees
    KEY_FILL_PER_LOT,
    KEY_ORDER_PER_LOT,
    KEY_FILL_VOLUME,
    KEY_FILL_VOLUME_WITH_MIN_PD,
    KEY_PER_FILL,
    KEY_PER_TRANSACTION,
    KEY_PER_PHONE_TRANSACTION,
    KEY_VAT,
    KEY_ORDER_VOLUME,
    KEY_SHORT_SWAP_INFO,
    KEY_FREE_COMMISSION,
    KEY_TOTAL_FEES,
    KEY_LONG_SWAP,
    KEY_SHORT_SWAP,
    KEY_ORDER_CARE
}
