// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';
import { Message } from './../Message';
import { type HighLowData } from '../../../Commons/cache/Algorithm/HighLow/HighLowData';

export class DirectAlgorithmHighLowMessage extends DirectAlgorithmMessageBase {
    constructor () { super(Message.CODE_PFIX_ALGORITHM_HIGH_LOW_MESSAGE); }

    HighLowItems: HighLowData[] = [];
}
