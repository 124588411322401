// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Point } from '../../Commons/Geometry';
import { Pen } from '../../Commons/Graphics';
import { XYSeriesData } from '../Series/TerceraChartXYSeries';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type TerceraChartWindow } from '../Windows/TerceraChartWindow';
import { TerceraChartStyledLineRenderer } from './TerceraChartStyledLineRenderer';

export class TerceraChartStyledVerticalLineRenderer extends TerceraChartStyledLineRenderer {
    constructor (chart: TerceraChartBase) {
        super(chart);
        this.UseInAutoscale = false;
    }

    public override Draw (gr: CanvasRenderingContext2D, window: TerceraChartWindow, windowsContainer: any, advParams: any): void {
        if (!this.Visible) {
            return;
        }

        if (isNullOrUndefined(this.ColorStyleWidth)) {
            return;
        }

        if (isNullOrUndefined(this.Series) || this.Series.Count() === 0) {
            return;
        }

        const points = this.getPoints(window);
        if (points.length === 0) {
            return;
        }
        gr.save();
        const clientRect = window.ClientRectangle;
        const rect = new Path2D();
        rect.rect(clientRect.X, clientRect.Y, clientRect.Width, clientRect.Height);
        gr.clip(rect);
        const pen = new Pen(this.ColorStyleWidth.Color, this.ColorStyleWidth.Width, this.ColorStyleWidth.Style);
        for (let i = 0; i < points.length; i += 2) {
            gr.DrawLine(pen, points[i].X, points[i].Y, points[i + 1].X, points[i + 1].Y);
        }
        gr.restore();
    }

    protected override getPoints (window: TerceraChartWindow): Point[] {
        const points: Point[] = [];
        const clientRectangle = window.ClientRectangle;
        for (let i = 0; i < this.Series.Count(); i++) {
            const x = this.Series.GetValue(i, XYSeriesData.X_INDEX);
            const screenX = window.PointsConverter.GetScreenX(x);
            points.push(new Point(screenX, clientRectangle.Top()));
            points.push(new Point(screenX, clientRectangle.Bottom()));
        }
        return points;
    }
}
