// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectSessionMessage } from '@shared/utils/DirectMessages/DirectSessionMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';
import { HolidayDescription } from '@shared/utils/Session/HolidayDescription';
import { SessionPeriod } from '@shared/utils/Session/SessionPeriod';
import { DayType } from '@shared/utils/Session/DayType';

export class SessionMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectSessionMessage[] {
        const msg = new DirectSessionMessage();

        msg.id = fieldSet.GetValue(FieldsFactory.FIELD_ID);
        msg.name = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
        msg.currentPeriodId = fieldSet.GetValue(FieldsFactory.FIELD_TRADE_SESSION_CURRENT_PERIOD_ID);
        msg.blockTrading = fieldSet.GetValue(FieldsFactory.FIELD_BLOCK_TRADING);
        msg.timeZoneID = fieldSet.GetValue(FieldsFactory.FIELD_TIME_ZONE_ID);
        msg.timeOffset = fieldSet.GetValue(FieldsFactory.FIELD_TIME_OFFSET) || 0;
        msg.firstDayOfWeek = fieldSet.GetValue(FieldsFactory.FIELD_DAY_INDEX) || 1; // Monday by default
        msg.serverBeginDayDate = fieldSet.GetValue(FieldsFactory.FIELD_BEGIN_DAY_DATE);

        msg.holidays = this.getHolidays(fieldSet);
        msg.periods = this.getSessionPeriods(fieldSet, false);
        return [msg];
    }

    private getSessionPeriods (fieldSet: PFixFieldSet, isHoliday: boolean): SessionPeriod[] | null {
        const periodGroups = fieldSet.GetGroups(FieldsFactory.SESSION_GROUP_NAME);
        if ((periodGroups.length === 1 && periodGroups[0].Count() === 0) || periodGroups.length === 0) {
            return null;
        }

        const sessionPeriods: SessionPeriod[] = [];

        for (const periodGroup of periodGroups) {
            const [begins, ends] = this.getPeriodTimeRange(periodGroup, isHoliday);
            const id: number = periodGroup.GetValue(FieldsFactory.FIELD_ID);
            const description = periodGroup.GetValue(FieldsFactory.FIELD_NAME);
            const dayPeriod = periodGroup.GetValue(FieldsFactory.FIELD_SESSION_DAY_PERIOD);

            const tradingSession = new SessionPeriod(id, description, dayPeriod, begins, ends);
            tradingSession.mainType = periodGroup.GetValue(FieldsFactory.FIELD_SESSION_PERIOD_TYPE);
            tradingSession.subType = periodGroup.GetValue(FieldsFactory.FIELD_SESSION_SUB_PERIOD_TYPE);
            tradingSession.isIntraday = periodGroup.GetValue(FieldsFactory.FIELD_SESSIOSN_IS_INTRADAY);
            tradingSession.decreaseOnlyPositionCount = periodGroup.GetValue(FieldsFactory.FIELD_IS_DECREASE_ONLY_POSITION_COUNT);
            tradingSession.allowOptionsExerciseOnLastTradeDate = periodGroup.GetValue(FieldsFactory.FIELD_TRADE_SESSION_OPTION_EXERCISE);
            sessionPeriods.push(tradingSession);
        }

        return sessionPeriods;
    }

    private getHolidays (fieldSet: PFixFieldSet): Map<string, HolidayDescription> | null {
        const holidays = new Map<string, HolidayDescription>();

        const holidaysGroups = fieldSet.GetGroups(FieldsFactory.HOLIDAY_GROUP);
        if ((holidaysGroups.length === 1 && holidaysGroups[0].Count() === 0) || holidaysGroups.length === 0) { return null; }

        for (const holiday of holidaysGroups) {
            const holidayDescr = new HolidayDescription();
            holidayDescr.name = holiday.GetValue(FieldsFactory.FIELD_NAME);
            holidayDescr.day = holiday.GetValue(FieldsFactory.FIELD_EXP_DAY);
            holidayDescr.month = holiday.GetValue(FieldsFactory.FIELD_EXP_MONTH);
            holidayDescr.year = holiday.GetValue(FieldsFactory.FIELD_EXP_YEAR);
            holidayDescr.key = HolidayDescription.createHolidayKey(holidayDescr.year, holidayDescr.month, holidayDescr.day);
            holidayDescr.holidayType = holiday.GetValue(FieldsFactory.FIELD_DAY_TYPE);
            holidayDescr.sessionPeriods = this.getSessionPeriods(holiday, true);

            if (holidayDescr.holidayType == DayType.SHORTED && holidayDescr.sessionPeriods == null) {
                const sessionID = fieldSet.GetValue(FieldsFactory.FIELD_ID);
                const sessionName = fieldSet.GetValue(FieldsFactory.FIELD_NAME);
                console.error(`Invalid SHORTED holiday ${holidayDescr.key} without periods.`);
                console.error(`Session id: ${sessionID} Session name: ${sessionName}`);
            }

            holidays.set(holidayDescr.key, holidayDescr);
        }

        return holidays;
    }

    private getPeriodTimeRange (periodGroup: PFixFieldSet, isHoliday: boolean): [Date[], Date[]] {
        const times = periodGroup.GetGroups(FieldsFactory.TRADE_SESSION_PERIOD_TIME_GROUP);
        let timesCount = times.length;
        if (isHoliday && timesCount == 1) { // shortened day for holiday
            timesCount = 7; // days of week
        }
        const begins = new Array(timesCount);
        const ends = new Array(timesCount);

        for (let i = 0, len = times.length; i < len; i++) {
            // 0 - sunday ... 5 - friday, 6-saturday
            const time = times[i];
            const index = time.GetValue(FieldsFactory.FIELD_DAY_INDEX) || -1; // https://tp.traderevolution.com/entity/106776 (comment dated 28.07.22)
            const sessionPeriodBegin = time.GetValue(FieldsFactory.FIELD_SESSION_BEGIN); // 09:00 как время задано в настройках сессии без оффсета
            const sessionPeriodEnd = time.GetValue(FieldsFactory.FIELD_SESSION_END);

            if (index >= 0) {
                begins[index] = sessionPeriodBegin;
                ends[index] = sessionPeriodEnd;
            } else {
                for (let j = 0; j < timesCount; j++) {
                    begins[j] = sessionPeriodBegin;
                    ends[j] = sessionPeriodEnd;
                }
            }
        }
        return [begins, ends];
    }
}
