// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class NewOrderMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_NEW_ORDER_MESSAGE, fieldSet);
    }

    public setOnBehalfId (onBehalfId: number): void {
        this.setFieldValue(FieldsFactory.FIELD_ON_BEHALF_USER_ID, onBehalfId);
    }

    public setPlacedFrom (placedFrom): void {
        this.setFieldValue(FieldsFactory.FIELD_CLIENT_PANEL, placedFrom);
    }
}
