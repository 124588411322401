// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InstrumentSpecificType } from '../Utils/Instruments/InstrumentSpecificType';
import { InstrumentUtils } from '../Utils/Instruments/InstrumentUtils';
import { Instrument } from './cache/Instrument';

export class SearchHelper {
    public FullName: string;
    public InstrumentTradableID: number | null;
    public Route: number | null;
    public Id: number | null;
    public InstrumentSpecificType: InstrumentSpecificType | null;
    public isHide: boolean;

    constructor (FullName: string | null) {
        if (!isValidString(FullName)) {
            return;
        }

        this.FullName = FullName;
        const splitedName = FullName.split(InstrumentUtils.SEPARATOR);
        if (splitedName.length !== 2) {
            return;
        }

        this.InstrumentTradableID = parseInt(splitedName[0].split(Instrument.CONTINUOUS_CONTRACT_POSTFIX)[0]);
        this.Route = parseInt(splitedName[1]);

        const continuousSplit = splitedName[1].split('|');
        if (continuousSplit.length > 1) {
            this.Id = parseInt(continuousSplit[1]);
            this.InstrumentSpecificType = InstrumentSpecificType.ContinuousContract;
            this.FullName = this.FullName.replace('|' + this.Id, '');
        }
    }

    public GetInteriorID (): string {
        return this.FullName;
    }
}
