// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '../../../../Utils/Account/AccountFeature';
import { Account } from '../../../cache/Account';

export class TvRiskManagementData {
    public currentDailyLoss: number = 0;
    public maxDrawdownLevel: number = 0;

    public update (acc: Account): boolean {
        let isNeedUpdate = false;
        let currentDailyLoss = 0;
        if (acc.MaxDailyLossLimit !== -1) {
            currentDailyLoss = Account.GetAccountFeature(AccountFeature.CurrentDailyLoss, acc, acc.assetBalanceDefault);
        }

        if (currentDailyLoss !== this.currentDailyLoss) {
            this.currentDailyLoss = currentDailyLoss;
            isNeedUpdate = true;
        }

        const maxDrawdownLevel = isValidNumber(acc.MaxDrawdownLevel) ? acc.MaxDrawdownLevel : 0;
        if (maxDrawdownLevel !== this.maxDrawdownLevel) {
            this.maxDrawdownLevel = maxDrawdownLevel;
            isNeedUpdate = true;
        }

        return isNeedUpdate;
    }
}
