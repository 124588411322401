// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterDouble } from '../InputParamaterClasses/InputParameterDouble';

export class SAR extends IndicatorScriptBase {
    public Step: number;
    public Maximum: number;
    public first: boolean;
    public dirlong: boolean;
    public start: number;
    public last_high: number;
    public last_low: number;
    public ep: number;
    public sar: number;
    public price_low: number;
    public price_high: number;

    constructor () {
        super();
        this.ProjectName = 'Parabolic Time/Price System';
        this.Comments = 'Helps to define the direction of the prevailing trend and the moment to close positions opened during the reversal';
        this.SetIndicatorLine('Line', Color.Firebrick, 4, LineStyle.DotChart);
        this.SeparateWindow = false;

        this.Step = 0.02;
        super.InputParameter(new InputParameterDouble('Step', 'Step of parabolic SAR system', 0, -9999, 9999, 2, 0.1));

        this.Maximum = 0.2;
        super.InputParameter(new InputParameterDouble('Maximum', 'Maximum', 1, -9999, 9999, 1, 0.1));

        this.first = false;
        this.dirlong = false;
        this.start = 0;
        this.last_high = 0;
        this.last_low = 0;
        this.ep = 0;
        this.sar = 0;
        this.price_low = 0;
        this.price_high = 0;
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
        this.first = true;
        this.dirlong = true;
        this.start = this.Step;
        this.last_high = -10000000;
        this.last_low = 10000000;
        this.sar = 0;
    };

    public async RefreshPromise (): Promise<void> {
        await super.RefreshPromise()
            .then(async () => {
                this.first = true;
                this.dirlong = true;
                this.start = this.Step;
                this.last_high = -10000000;
                this.last_low = 10000000;
                this.sar = 0;

                await Promise.resolve();
            });
    };

    public override GetIndicatorShortName (): string {
        return 'SAR(' + this.Step + ';' + this.Maximum + ')';
    };

    public override getCustomName (): string {
        return 'SAR (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        super.OnQuote();

        const CurrentData = this.CurrentData;
        if (CurrentData.Count === 1) { return; };

        if (this.first) {
            this.price_low = CurrentData.GetPrice(PriceType.Low, 0);
            this.price_high = CurrentData.GetPrice(PriceType.High, 0);

            if (this.last_low > this.price_low) { this.last_low = this.price_low; };

            if (this.last_high < this.price_high) { this.last_high = this.price_high; };

            const prev_low = CurrentData.GetPrice(PriceType.Low, 1);
            const prev_high = CurrentData.GetPrice(PriceType.High, 1);

            if (this.price_high > prev_high &&
                this.price_low > prev_low) {
                this.SetValue(0, 0, prev_low);
                this.first = false;
                return;
            }

            if (this.price_high < prev_high &&
                this.price_low < prev_low) {
                this.dirlong = false;
                this.SetValue(0, 0, prev_high);
                this.first = false;
                return;
            }
        }

        this.price_low = CurrentData.GetPrice(PriceType.Low, 0);
        this.price_high = CurrentData.GetPrice(PriceType.High, 0);
        const price = this.GetValue(0, 1);

        // --- check for reverse
        if (this.dirlong && this.price_low < price) {
            this.start = this.Step;
            this.dirlong = false;
            this.ep = this.price_low;
            this.last_low = this.price_low;
            this.SetValue(0, this.last_high);
            return;
        }

        if (!this.dirlong && this.price_high > price) {
            this.start = this.Step;
            this.dirlong = true;
            this.ep = this.price_high;
            this.last_high = this.price_high;
            this.SetValue(0, this.last_low);
            return;
        }

        // --- calculate current value
        this.sar = price + this.start * (this.ep - price);

        // ---- check long direction
        if (this.dirlong) {
            if (this.ep < this.price_high &&
                this.start + this.Step <= this.Maximum) { this.start += this.Step; };

            if (this.price_high < CurrentData.GetPrice(PriceType.High, 1) &&
                CurrentData.Count === 2) { this.sar = price; };

            if (this.sar > CurrentData.GetPrice(PriceType.Low, 1)) { this.sar = CurrentData.GetPrice(PriceType.Low, 1); };

            if (this.sar > CurrentData.GetPrice(PriceType.Low, 2)) { this.sar = CurrentData.GetPrice(PriceType.Low, 2); };

            if (this.sar > this.price_low) {
                this.start = this.Step;
                this.dirlong = false;
                this.ep = this.price_low;
                this.last_low = this.price_low;
                this.SetValue(0, this.last_high);
                return;
            }

            if (this.ep < this.price_high) {
                this.last_high = this.price_high;
                this.ep = this.price_high;
            }
        } else {
            if (this.ep > this.price_low &&
                this.start + this.Step <= this.Maximum) { this.start += this.Step; };

            if (this.price_low < CurrentData.GetPrice(PriceType.Low, 1) &&
                CurrentData.Count === 2) { this.sar = price; };

            if (this.sar < CurrentData.GetPrice(PriceType.High, 1)) { this.sar = CurrentData.GetPrice(PriceType.High, 1); };

            if (this.sar < CurrentData.GetPrice(PriceType.High, 2)) { this.sar = CurrentData.GetPrice(PriceType.High, 2); };

            if (this.sar < this.price_high) {
                this.start = this.Step;
                this.dirlong = true;
                this.ep = this.price_high;
                this.last_high = this.price_high;
                this.SetValue(0, this.last_low);
                return;
            }

            if (this.ep > this.price_low) {
                this.last_low = this.price_low;
                this.ep = this.price_low;
            }
        }

        this.SetValue(0, this.sar);
    };
}
