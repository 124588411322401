// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { ControlsUtils } from '../../UtilsClasses/ControlsUtils';
import { TerceraChartRactiveTemplate } from '../../../templates';
import { Resources } from '@shared/localizations/Resources';
import { Control } from '../Control';
import { ThemeManager } from '../../misc/ThemeManager';
import { KeyEventProcessor } from '@shared/commons/KeyEventProcessor';
import { DynProperty } from '@shared/commons/DynProperty';
import { RactiveTooltip } from '../../misc/Decorators';
import { type TerceraChartBase } from '@front/chart/TerceraChartBase';
import $ from 'jquery';

export class BaseChartRactive<Chart extends TerceraChartBase> extends Control {
    public static staticID: number = 0;

    public terceraChart: Chart = null;
    public canvas = null;
    public context = null;
    public tooltipTimeout = null;
    public captured: boolean;

    private accumulatedDeltaX: number = 0;
    private accumulatedDeltaY: number = 0;
    private readonly pinchSensitivity: number = 0.5;
    private readonly scrollThreshold: number = 5;

    constructor () {
        super();
    }

    protected createChart (): Chart { return undefined; }
    public getType (): string { return 'TerceraChartRactive'; }
    public override oninit (): void {
        BaseChartRactive.staticID++;
        void this.set('id', 'chart' + BaseChartRactive.staticID);
    }

    public override oncomplete (): void {
        super.oncomplete();
        KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);
        try {
            this.canvas = $('#' + this.get('id'))[0];
            this.context = this.canvas.getContext('2d');

            this.terceraChart = this.createChart();
            this.terceraChart.Initialize();
            this.terceraChart.OnUpdateTooltip.Subscribe(this.onUpdateTooltip, this);

            this.terceraChart.ThemeChanged();

            this.observe('width', this.onWidthChanged);
            this.observe('height', this.onHeightChanged);
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log('chart oncomplete exception: ' + ex);
        }
    }

    public onteardown (): void {
        super.onteardown();
        this.terceraChart.OnUpdateTooltip.UnSubscribe(this.onUpdateTooltip, this);
        KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);
    }

    public onUpdateTooltip (tooltip: string): void {
        if (this.tooltipTimeout) { clearTimeout(this.tooltipTimeout); }

        this.tooltipTimeout = null;

        if (!tooltip) {
            RactiveTooltip.setTooltipVisibility(false);
            return;
        }

        this.tooltipTimeout = setTimeout(function (tooltip) {
            RactiveTooltip.showTooltip(tooltip);
        }, 500, tooltip);
    }

    public onResizeBegin (): void {

    }

    public onResizeEnd (): void {

    }

    public onWidthChanged (newWidth): void {
        const devicePixelRatio = window.devicePixelRatio || 1;
        this.terceraChart.width = newWidth;
        this.canvas.width = newWidth * devicePixelRatio;
        this.context.scale(devicePixelRatio, devicePixelRatio);
        this.terceraChart.OnResize(this.get('width'), this.get('height'));
    }

    public onHeightChanged (newHeight): void {
        const devicePixelRatio = window.devicePixelRatio || 1;
        this.terceraChart.height = newHeight;
        this.canvas.height = newHeight * devicePixelRatio;
        this.context.scale(devicePixelRatio, devicePixelRatio);
        this.terceraChart.OnResize(this.get('width'), this.get('height'));
    }

    public async setSizes (): Promise<void> {
        const panel = this.find('*');
        const w = panel.offsetWidth || 10;
        const h = panel.offsetHeight || 10;
        await this.set({ width: w, height: h });
    }

    public async resetSizes (): Promise<void> {
        await this.set({ width: 10, height: 10 });
    }

    public async setCursor (cursor): Promise<void> {
        await this.set('currentCursor', cursor || 'default');
    }

    public onMouseDown (event): void {
        super.onMouseDown(event);
        const newChartEvent = this.terceraChart.ImproveArgs(event.original || event);
        this.terceraChart.ProcessMouseDown(newChartEvent);
        ControlsUtils.Capture.SetCapture(this, event.original);
    }

    public onMouseMove (event): void {
        if (this.captured && event.original) { return; }
        super.onMouseMove(event);
        const newChartEvent = this.terceraChart.ImproveArgs(event.original || event);
        this.terceraChart.ProcessMouseMove(newChartEvent);
    }

    public onMouseUp (event): void {
        if (this.captured && event.original) { return; }
        super.onMouseUp(event);
        const newChartEvent = this.terceraChart.ImproveArgs(event.original || event);
        this.terceraChart.ProcessMouseUp(newChartEvent);
    }

    public onMouseWheel (event): void {
        super.onMouseWheel(event);
        const correctEvent = event.original || event;
        correctEvent.preventDefault();
        correctEvent.stopPropagation();
        const deltaX = correctEvent.deltaX || 0;
        const deltaY = correctEvent.deltaY || 0;

        this.accumulatedDeltaX += deltaX;
        this.accumulatedDeltaY += deltaY;

        if (Math.abs(this.accumulatedDeltaX) >= this.scrollThreshold || Math.abs(this.accumulatedDeltaY) >= this.scrollThreshold) {
            if (Math.abs(this.accumulatedDeltaX) > Math.abs(this.accumulatedDeltaY)) {
                this.handleHorizontalScroll(correctEvent);
            } else {
                this.handleVerticalScroll(correctEvent, deltaY);
            }
            this.accumulatedDeltaX = 0;
            this.accumulatedDeltaY = 0;
        }
    }

    private handleHorizontalScroll (correctEvent: any): void {
        const scrollEvent = this.terceraChart.ImproveArgs(correctEvent);
        this.terceraChart.ProcessMouseWheel(scrollEvent);
    }

    private handleVerticalScroll (correctEvent: any, deltaY: number): void {
        const scrollEvent = this.terceraChart.ImproveArgs(correctEvent);
        this.terceraChart.ProcessMouseWheel(scrollEvent);
    }

    public onGlobalKeyDown (): void {
        const container = this.get('parentContainerControl');
        if (container?.get('focused') || this.get('focused')) { this.terceraChart.OnKeyDown(); }
    }

    public onMouseEnter (event): void {
        super.onMouseEnter(event);
        this.terceraChart.ProcessMouseEnter(event);
    }

    public onMouseLeave (event): void {
        super.onMouseLeave(event);
        this.terceraChart.ProcessMouseLeave(event);
    }

    public onDoubleClick (event): void {
        super.onDoubleClick(event);
        const newChartEvent = this.terceraChart.ImproveArgs(event.original || event);
        this.terceraChart.ProcessMouseDoubleClick(newChartEvent);
    }

    public onClick (event): void {
        super.onClick(event);
        const newChartEvent = this.terceraChart.ImproveArgs(event.original || event);
        this.terceraChart.ProcessMouseClick(newChartEvent);
    }

    public themeChange (): void {
    // this.set('backgroundColorTop', App.ThemeManager.CurrentTheme.Chart_BottomColor);
        this.set('backgroundColorBottom', ThemeManager.CurrentTheme.Chart_BottomColor);

        if (this.terceraChart) { this.terceraChart.ThemeChanged(); }
    }

    public localize (): void {
        if (this.terceraChart) { this.terceraChart.Localize(); }
    }

    // #region ICaller

    public Properties (): DynProperty[] {
        const properties = this.terceraChart.Properties();

        // Window colors
        const SeparatorGroup = '#-1#' + Resources.getResource('property.SeparatorGroup.WindowColors');

        /* var prop = new DynProperty("Backgr. top", this.get('backgroundColorTop'), DynProperty.COLOR, DynProperty.VISUAL_GROUP);
    prop.separatorGroup = SeparatorGroup;
    prop.sortIndex = 0;
    properties.push(prop); */

        const prop = new DynProperty('property.Backgr', this.get('backgroundColorBottom'), DynProperty.COLOR, DynProperty.VISUAL_GROUP);
        prop.separatorGroup = SeparatorGroup;
        prop.sortIndex = 1;
        properties.push(prop);

        return properties;
    }

    public callBack (properties: DynProperty[]): void {
        this.terceraChart.callBack(properties);

        /* dp = DynProperty.getPropertyByName(properties, "Backgr. top");
    if (dp) this.set('backgroundColorTop', dp.value); */

        const dp = DynProperty.getPropertyByName(properties, 'property.Backgr');
        if (dp) this.set('backgroundColorBottom', dp.value);
    }

// #endregion
}

Control.extendWith(BaseChartRactive, {
    template: TerceraChartRactiveTemplate,
    data: function () {
        return {
            // backgroundColorTop: 'purple',
            backgroundColorBottom: 'rgb(16, 26, 36)',
            currentCursor: 'default',
            terceraChartPanelContext: null,
            useStyle: true,
            width: 0,
            height: 0
        };
    }
});
