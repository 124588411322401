// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { ToolView } from './ToolView';
import { RegressionChannelDataCacheTool } from '@shared/commons/cache/Tools/RegressionChannelDataCacheTool';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { TerceraChartUtils } from '../TerceraChartUtils';
import { CashItem } from '@shared/commons/cache/History/CashItem';

export class RegressionChannelToolView extends ToolView<RegressionChannelDataCacheTool> {
    
    protected CalculateCoefficients(ww, param): void {
        const tool = this.dataCacheTool;
        if (!tool) return;

        tool.needRecalculate = false;
        tool.coef = null;

        const minTime = Math.min(tool.Points[0][0], tool.Points[1][0]);
        const maxTime = Math.max(tool.Points[0][0], tool.Points[1][0]);
        const indexBegin = Math.ceil(ww.PointsConverter.GetBarIndex(minTime as unknown as bigint));
        const indexEnd = Math.floor(ww.PointsConverter.GetBarIndex(maxTime as unknown as bigint));

        const data: number[][] = [];

        const mainCashItemSeries = param.mainPriceRenderer.Series;
        const dataType = param.TerceraChart.cashItemSeriesSettings?.DataType ?? TerceraChartCashItemSeriesDataType.Absolute;

        let pointIndex = 0;

        for (let i = indexBegin; i <= indexEnd; i++) {
            const realCashItemIndex = mainCashItemSeries.GetIndex(i);

            if (realCashItemIndex >= 0) {
                const tmp = new Array(3);
                tmp[0] = pointIndex + 1;
                switch (dataType) {
                    case TerceraChartCashItemSeriesDataType.Absolute:
                        tmp[1] = mainCashItemSeries.GetValue(i, tool.PriceTypeInt);
                        break;
                    case TerceraChartCashItemSeriesDataType.Relative:
                        tmp[1] = mainCashItemSeries.settings.relativeDataConverter.Revert(mainCashItemSeries.GetValue(i, tool.PriceTypeInt));
                        break;
                    case TerceraChartCashItemSeriesDataType.Log:
                        tmp[1] = mainCashItemSeries.settings.logDataConverter.Revert(mainCashItemSeries.GetValue(i, tool.PriceTypeInt));
                        break;
                }

                tmp[2] = mainCashItemSeries.GetValue(i, CashItem.TIME_INDEX);
                data.push(tmp);
                pointIndex++;
            }
        }

        tool.coef = TerceraChartUtils.calculateLinearRegression(data);

        if (tool.coef) {
            const x1 = tool.Points[0][0] < tool.Points[1][0] ? data[0][0] : data[data.length - 1][0];
            const x2 = tool.Points[0][0] < tool.Points[1][0] ? data[data.length - 1][0] : data[0][0];

            tool.Points[0][1] = tool.coef.A * x1 + tool.coef.B;
            tool.Points[1][1] = tool.coef.A * x2 + tool.coef.B;

            tool.Points[0][0] = tool.Points[0][0] < tool.Points[1][0] ? data[0][2] : data[data.length - 1][2];
            tool.Points[1][0] = tool.Points[0][0] < tool.Points[1][0] ? data[data.length - 1][2] : data[0][2];

            this.UpdateScreenPoints(ww);
        }
    }

    public override Draw(gr, ww, param): void {
        const tool = this.dataCacheTool;
        if (tool) {
            if (tool.needRecalculate) {
                this.CalculateCoefficients(ww, param);
                this.FireUpdate();
            }

            if (tool.coef) {
                const coordinates: Point[] = new Array(6);

                const cashItemSeries = param.mainPriceRenderer.Series;
                const dataType = param.TerceraChart.cashItemSeriesSettings?.DataType ?? TerceraChartCashItemSeriesDataType.Absolute;

                switch (dataType) {
                    case TerceraChartCashItemSeriesDataType.Absolute:
                        coordinates[0] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(tool.Points[0][1] + tool.coef.Sigma * tool.UpperDeviation)));
                        coordinates[1] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(tool.Points[1][1] + tool.coef.Sigma * tool.UpperDeviation)));
                        coordinates[2] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(tool.Points[0][1])));
                        coordinates[3] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(tool.Points[1][1])));
                        coordinates[4] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(tool.Points[0][1] + tool.coef.Sigma * tool.LowerDeviation)));
                        coordinates[5] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(tool.Points[1][1] + tool.coef.Sigma * tool.LowerDeviation)));
                        break;
                    case TerceraChartCashItemSeriesDataType.Relative:
                        coordinates[0] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(tool.Points[0][1] + tool.coef.Sigma * tool.UpperDeviation))));
                        coordinates[1] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(tool.Points[1][1] + tool.coef.Sigma * tool.UpperDeviation))));
                        coordinates[2] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(tool.Points[0][1]))));
                        coordinates[3] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(tool.Points[1][1]))));
                        coordinates[4] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(tool.Points[0][1] + tool.coef.Sigma * tool.LowerDeviation))));
                        coordinates[5] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.relativeDataConverter.Calculate(tool.Points[1][1] + tool.coef.Sigma * tool.LowerDeviation))));
                        break;
                    case TerceraChartCashItemSeriesDataType.Log:
                        coordinates[0] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(tool.Points[0][1] + tool.coef.Sigma * tool.UpperDeviation))));
                        coordinates[1] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(tool.Points[1][1] + tool.coef.Sigma * tool.UpperDeviation))));
                        coordinates[2] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(tool.Points[0][1]))));
                        coordinates[3] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(tool.Points[1][1]))));
                        coordinates[4] = new Point(this.screenPoints[0][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(tool.Points[0][1] + tool.coef.Sigma * tool.LowerDeviation))));
                        coordinates[5] = new Point(this.screenPoints[1][0], Math.round(ww.PointsConverter.GetScreenY(cashItemSeries.settings.logDataConverter.Calculate(tool.Points[1][1] + tool.coef.Sigma * tool.LowerDeviation))));
                        break;
                }

                gr.DrawLine(tool.Pen, coordinates[2].X, coordinates[2].Y, coordinates[3].X, coordinates[3].Y);
                gr.DrawLine(tool.UpLinePen, coordinates[0].X, coordinates[0].Y, coordinates[1].X, coordinates[1].Y);
                gr.DrawLine(tool.DownLinePen, coordinates[4].X, coordinates[4].Y, coordinates[5].X, coordinates[5].Y);

                gr.FillPolygon(tool.UpBrush, [coordinates[0], coordinates[1], coordinates[3], coordinates[2]]);
                gr.FillPolygon(tool.DownBrush, [coordinates[2], coordinates[3], coordinates[5], coordinates[4]]);
            }
        }

        gr.DrawLine(tool.Pen, this.screenPoints[0][0], this.screenPoints[0][1], this.screenPoints[1][0], this.screenPoints[1][1]);
        super.Draw(gr, ww, param);
    }

    public OnFinishCreationTool(): void
    {
        this.dataCacheTool.needRecalculate = true;
    }

    public ProcessMoveFinish(): void {
        super.ProcessMoveFinish();

        this.dataCacheTool.needRecalculate = true;
    }
}

