// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Point } from '../../Commons/Geometry';
import { type GannLineDataCacheTool } from '../../Commons/cache/Tools/GannLineDataCacheTool';
import { ChartMath } from '../Utils/ChartMath';
import { ToolView } from './ToolView';

export class GannLineToolView extends ToolView<GannLineDataCacheTool> {
    public override IsSelectCheck (x: number, y: number): boolean {
        const scrP = this.screenPoints[0];

        return ChartMath.CalcDistanceFromPointToPoint(
            new Point(x, y),
            new Point(scrP[0], scrP[1])) <= ToolView.POINT_DX * 2;
    }

    public DrawGannLine (gr, ww, highlight): void {
        const cacheTool = this.dataCacheTool;
        const mirrored = cacheTool.mirrored;
        const pen = highlight ? cacheTool.PenHighlight : cacheTool.Pen;
        const clientRect = ww.ClientRectangle;

        const screenPoints = this.screenPoints;
        const scrP = screenPoints[0];
        const x = scrP[0];
        const y = scrP[1];

        if (mirrored) {
            gr.DrawLine(pen, x, y, x + (clientRect.Height - y) / Math.tan(Math.atan(1 / 1.0)), clientRect.Height);
        } else {
            gr.DrawLine(pen, x, y, x + y / Math.tan(Math.atan(1 / 1.0)), 0);
        }
    }

    public override Draw (gr, ww, param): void {
        this.DrawGannLine(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        this.DrawGannLine(gr, ww, true);
        super.DrawSelection(gr, ww);
    }
}
