// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MAMode } from '../../IndicatorConstants';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iCCIEx extends iBuildInIndicator {
    public FMaPeriod: any;
    public FMaType: MAMode;
    public ma: ExpandDoubleVector = new ExpandDoubleVector();

    constructor (maPeriod, maType: MAMode, priceType) {
        super(1);
        this.FMaPeriod = maPeriod;
        this.FMaType = maType | MAMode.SMA;
        this.FPriceType = priceType;
    }

    override get Name () { return 'iCCIEx'; }

    override get Key () { return this.DefaultKey + this.FMaPeriod + this.FMaType; }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.ma.Add(0.0);
    }

    public override Refresh (count, newThread): void {
        this.ma.Dispose();
        this.ma = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override OnQuote (): void {
        this.ma[this.ma.Length - 1] = this.GetPrice(this.FPriceType, 0);
        const Parent = this.Parent;
        if (!Parent || this.FCount < this.FMaPeriod || this.FMaPeriod == 0) {
            return;
        }
        let d = 0.0;
        const maValue = IndicatorFunctions.CallMovingFunction(this.FMaType, this.ma, this.FMaPeriod, 1);
        for (let i = 0; i < this.FMaPeriod; i++) {
            d += Math.abs(this.GetPrice(this.FPriceType, i) - maValue);
        }

        d = 0.015 * (d / this.FMaPeriod);
        if (d != 0.0) {
            this.SetValue(0, 0, (this.GetPrice(this.FPriceType, 0) - maValue) / d);
        }
    }
}
