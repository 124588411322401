// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type QuotesCallback } from '../charting_library';
import { type CashItem } from '../../cache/History/CashItem';
import { TvHistoryConvertor } from '../Convertors/TvHistoryConvertor';

export class TvCashItemManager {
    // удаляем старые бары при создании
    // удаляем старые бары когда срабатывает NextBar
    // возвращаем ohlc bar для tv

    private readonly cashItem: CashItem;
    private readonly timeOffset: number;
    private readonly timeZone: string;
    private onBarUpdated: QuotesCallback | null = null;
    private onResetCacheNeededCallback: () => void;
    private readonly historyConvertor = new TvHistoryConvertor();

    constructor (cashItem: CashItem, timeOffset: number, timeZone: string) {
        this.cashItem = cashItem;
        this.timeOffset = timeOffset;
        this.timeZone = timeZone;
        this.cashItem.HistoryExpanded.Subscribe(this.onHistoryExpanded, this);
        this.cashItem.QuoteProcessed.Subscribe(this.onQuoteProcessed, this);
        this.removeHistory();
    }

    dispose () {
        this.onBarUpdated = null;
        this.cashItem.HistoryExpanded.UnSubscribe(this.onHistoryExpanded, this);
        this.cashItem.QuoteProcessed.UnSubscribe(this.onQuoteProcessed, this);
        this.cashItem.Dispose();
    }

    onHistoryExpanded (cashItem, oldLength, newLength) {
        this.removeHistory();
    }

    onQuoteProcessed (cashItem, quote) {
        const barIndex = this.cashItem.FNonEmptyCashArray.length - 1;
        if (barIndex < 0) return;

        const baseInterval = this.cashItem.FNonEmptyCashArray[barIndex];
        const period = this.cashItem.FPeriod;

        const tvBar = this.historyConvertor.convertToTvBar(baseInterval, period, this.timeOffset, this.timeZone);
        this.callOnBarUpdated(tvBar);
    }

    public setCallBacks (onBarUpdated: QuotesCallback, onResetCacheNeededCallback: () => void) {
        this.onBarUpdated = onBarUpdated;
        this.onResetCacheNeededCallback = onResetCacheNeededCallback;
    }

    public callOnResetCacheNeededCallback () {
        if (this.onResetCacheNeededCallback) {
            this.onResetCacheNeededCallback();
        }
    }

    private callOnBarUpdated (tvBar) {
        if (this.onBarUpdated) {
            this.onBarUpdated(tvBar);
        }
    }

    private removeHistory () {
        const count = this.cashItem.FNonEmptyCashArray.length;
        if (count <= 1) return;

        this.cashItem.FNonEmptyCashArray = [this.cashItem.FNonEmptyCashArray[count - 1]];
        this.cashItem.FNonEmptyCashArray.length = 1;
        this.cashItem.FNonEmptyCashArrayCount = 1;
    }
}
