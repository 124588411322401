// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { MouseButtons } from '../UtilsClasses/ControlsUtils';
import { contextMenuHandler } from '../../Utils/AppHandlers';
import { TradingButtonStripeTemplate } from '../../templates.js';
import { Control } from './Control';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type IsAllowedResponce } from '../../Commons/IsAllowed';
import { ResourcesManager } from '../../Commons/properties/ResourcesManager';

export class TradingButtonStripe extends Control {
    constructor () {
        super();
    }

    public override getType (): string { return 'TradingButtonStripe'; }

    public override oninit (): void {
        super.oninit();
        this.on('tradingButtonClick', this.onTradingButtonClick);
        this.onMenuItemClicked = this.onMenuItemClicked.bind(this);
        this.observe('actionDataDict.*.visible', this.onVisibilityChanged);
    }

    public override oncomplete (): void {
        super.oncomplete();
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        const key = 'panels.positions.ButtonsPanel.helpLabel';
        void this.set({
            hiddenItemsMessage: Resources.isHidden(key) ? '' : Resources.getResource(key),
            filteredText: ` (${Resources.getResource('property.Filtered')})`
        });

        const actionDataDict = this.get('actionDataDict');
        if (isNullOrUndefined(actionDataDict)) {
            return;
        }

        for (const action in actionDataDict) {
            const data = actionDataDict[action];
            data.text = Resources.getResource(data.locKey);
            data.tooltip = data.tooltipLocKey ? Resources.getResource(data.tooltipLocKey) : '';
        }
        void this.update('actionDataDict');
    }

    // TODO. Ugly.
    public override onMouseDown (event, isHeader): void {
        if (Control.IsEventProcessed(event)) {
            return;
        }

        const origEvt = event.original;

        super.onMouseDown(event, isHeader);

        if (origEvt.button === MouseButtons.Right) {
            contextMenuHandler.Show(
                this.getMenuItems(),
                origEvt.clientX,
                origEvt.clientY,
                { isMultiClick: true });
        }

        Control.ProcessEvent(event);
    }

    // TODO. Optimize?
    public getMenuItems (): MenuItem[] {
        const actionArr = this.get('actionArray');
        const actionDataDict = this.get('actionDataDict');
        const menuItemArr: MenuItem[] = [];
        for (let i = 0, len = actionArr ? actionArr.length : 0; i < len; i++) {
            const action = actionArr[i];
            const data = actionDataDict[action];

            const menuItem = new MenuItem();
            menuItem.text = Resources.getResource(data.locKey);
            menuItem.enabled = true;
            menuItem.localizeKey = data.locKey;
            menuItem.canCheck = true;
            menuItem.checked = data.visible;
            menuItem.tag = action;
            menuItem.event = this.onMenuItemClicked;

            menuItemArr.push(menuItem);
        }

        return menuItemArr;
    }

    public onMenuItemClicked (menuItem: MenuItem): void {
        const action = menuItem.tag;
        void this.set('actionDataDict.' + action + '.visible', menuItem.checked);
    }

    // TODO. Optimize.
    public onVisibilityChanged (): void {
        const actionArr = this.get('actionArray');
        const actionDataDict = this.get('actionDataDict');
        let visibleItemCount = 0;
        const len = actionArr ? actionArr.length : 0;
        for (let i = 0; i < len; i++) {
            const action = actionArr[i];
            const data = actionDataDict[action];
            data.visibilityIdx = data.visible ? visibleItemCount++ : i;
        }

        void this.set('showHiddenItemsMessage', visibleItemCount === 0);
        void this.update('actionDataDict');
    }

    // TODO. Meh.
    public setItemArray (itemArray: TradingButtonItem[]): void {
        const actionArr = [];
        const actionDataDict = {};
        for (let i = 0, len = itemArray ? itemArray.length : 0; i < len; i++) {
            const item = itemArray[i];
            const action = item.action;
            if (Resources.isHidden(item.locKey)) {
                continue;
            }

            actionArr.push(action);
            actionDataDict[action] =
            {
                action,
                enabled: item.enabled,
                visible: item.visible,
                visibilityIdx: i,
                instrument: item.instrument,
                locKey: item.locKey,
                text: Resources.getResource(item.locKey),
                isFiltered: item.isFiltered
            };
        }

        void this.set({
            actionArray: actionArr,
            actionDataDict
        });
    }

    // TODO. Ugly. Refactor.
    public updateEnability (enabledActionSet: Record<number, IsAllowedResponce>): void {
        if (isNullOrUndefined(enabledActionSet)) return;

        const actionDataDict = this.get('actionDataDict');
        if (isNullOrUndefined(actionDataDict)) return;

        for (const actionKey in actionDataDict) {
            if (isNullOrUndefined(enabledActionSet[actionKey])) {
                actionDataDict[actionKey].enabled = false;
                continue;
            }

            const actionData = actionDataDict[actionKey];
            actionData.enabled = enabledActionSet[actionKey].Allowed;
            actionData.tooltipLocKey = enabledActionSet[actionKey].ReasonLocalizationKey;
            actionData.tooltip = enabledActionSet[actionKey].ReasonText;
        }

        void this.update('actionDataDict');
    }

    public setInstrument (action, instrument: Instrument): void {
        const actionDataDict = this.get('actionDataDict');
        if (isNullOrUndefined(actionDataDict)) return;

        const actionData = actionDataDict[action];
        if (isNullOrUndefined(actionData)) return;

        actionData.instrument = instrument;

        void this.update('actionDataDict');
    }

    // TODO. Refactor. Rename evants.
    public onTradingButtonClick (context, action): void {
        this.fire('tradingAction', action);
    }

    public getBtnsVisibility (): Array<{ a: string, v: boolean }> {
        const result = [];
        const actionDataDict = this.get('actionDataDict');
        if (isNullOrUndefined(actionDataDict)) {
            return result;
        }

        for (const actionKey in actionDataDict) {
            result.push({ a: actionKey, v: actionDataDict[actionKey].visible });
        }

        return result;
    }

    public setBtnsVisibility (data): void {
        if (isNullOrUndefined(data)) {
            return;
        }

        const actionDataDict = this.get('actionDataDict');
        if (isNullOrUndefined(actionDataDict)) {
            return;
        }

        for (let i = 0; i < data.length; i++) {
            const action = data[i].a;
            const visible = data[i].v;
            if (actionDataDict[action]) {
                actionDataDict[action].visible = visible;
            }
        }
    }
}

Control.extendWith(TradingButtonStripe, {
    data: function () {
        return {
            showHiddenItemsMessage: false,
            hiddenItemsMessage: '',
            filteredText: '',
            actionArray: [],
            actionDataDict: {},
            buttonWidth: 100,
            buttonHeight: 22
        };
    },
    template: TradingButtonStripeTemplate
});

export class TradingButtonItem {
    public readonly action: number;
    public readonly locKey: string;
    public readonly isFiltered: boolean;
    public instrument: Instrument = null;
    public enabled: boolean = false;
    public visible: boolean = true;

    constructor (action: number, locKey: string, isFiltered: boolean) {
        this.action = action;
        this.locKey = locKey;
        this.isFiltered = isFiltered;
    }
}

class MenuItem {
    public text: string;
    public enabled: boolean;
    public localizeKey: string;
    public canCheck: boolean;
    public checked: boolean;
    public tag: any;
    public event: any;

    // constructor (text: string, enabled: boolean, localizeKey: string, canCheck: boolean, checked: boolean, tag: any, event: any) {
    //     this.text = text;
    //     this.enabled = enabled;
    //     this.localizeKey = localizeKey;
    //     this.canCheck = canCheck;
    //     this.checked = checked;
    //     this.tag = tag;
    //     this.event = event;
    // }
}
