// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum CommissionTypes {
    PerLot = 0,
    PerFill = 1,
    PerTransaction = 2,
    PerPhoneTransaction = 3,
    VAT = 4,
    PerVolume = 5,
    PerOrderVolume = 6,
    VolumeWithMinPD = 7,
    OrderPerLot = 8,
    TotalFee = 9,
    OptionExercise = 10,
    ShortCommissionInfo = 11,
    SwapBuy = 12,
    SwapSell = 13
}

export enum CommissionOperationType { // значения используются в ключах локализации для InstrumentDetails - имя не менять
    ALL = 0,
    BUY = 1,
    SHORT = 2,
    SELL = 3,
    OPEN = 4,
    CLOSE = 5,
    OPENBUY = 6,
    OPENSELL = 7,
    OPENSHORT = 8,
    CLOSEBUY = 9,
    CLOSESELL = 10
}

export enum CommissionPaymentType {
    Absolute = 0,
    VolumePercent = 1
}

export enum FeeStringVariable {
    Amount = 0,
    Price = 1
}
