// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '../../Localizations/Resources';
import { Rectangle } from '../../Commons/Geometry';
import { DrawPointer, DrawPointerTypeEnum } from '../Utils/DrawPointer';
import { TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { ThemeManager } from '../../Controls/misc/ThemeManager';
import { type TerceraChartBase } from '../TerceraChartBase';

export class TradingCentralLinesRenderer extends TerceraChartBaseRenderer {
    private dataObj: any | null = null;
    private textWidthCached: Record<string, number> = {};

    private resistanceLocalizedText: string = '';
    private supportLocalizedText: string = '';
    private pivotLocalizedText: string = '';

    private namePlateBrush: any; // Replace 'any' with the actual type of brush
    private namePlateBorderPen: any; // Replace 'any' with the actual type of pen
    private namePlateTextBrush: any; // Replace 'any' with the actual type of brush
    private pivotPen: any; // Replace 'any' with the actual type of pen
    private resistancePen: any; // Replace 'any' with the actual type of pen
    private supportPen: any; // Replace 'any' with the actual type of pen
    private pivotBrush: any; // Replace 'any' with the actual type of brush
    private resistanceBrush: any; // Replace 'any' with the actual type of brush
    private supportBrush: any; // Replace 'any' with the actual type of brush
    private font: any; // Replace 'any' with the actual type of font

    static NAME_PLATE_HEIGHT: number = 16;
    static NAME_PLATE_TEXT_MARGIN: number = 10;
    static NAME_PLATE_OFFSET: number = 2;

    constructor (chart: TerceraChartBase) {
        super(chart);
        this.ThemeChanged();
        this.Localize();
        this.SetClassName('TradingCentralLinesRenderer');
    }

    Draw (gr: any, window: any, windowsContainer: any, advParams: any) {
        if (!this.Visible) return;

        const clipRect = window.ClientRectangle;
        if (!advParams || !clipRect) return;

        const data = this.dataObj;
        if (!data) return;

        const resistanceArr = data.resistanceValues; // draw resistances
        if (resistanceArr) {
            for (let i = 0; i < resistanceArr.length; i++) {
                this.DrawFullSet(gr, window, resistanceArr[i], this.resistancePen, this.resistanceBrush, this.resistanceLocalizedText + (i + 1), advParams);
            }
        }

        const supportArr = data.supportValues; // draw supports
        if (supportArr) {
            for (let i = 0; i < supportArr.length; i++) {
                this.DrawFullSet(gr, window, supportArr[i], this.supportPen, this.supportBrush, this.supportLocalizedText + (i + 1), advParams);
            }
        }

        this.DrawFullSet(gr, window, data.pivotValue, this.pivotPen, this.pivotBrush, this.pivotLocalizedText, advParams); // draw pivot
    }

    DrawFullSet (gr: any, window: any, valueForY: number, pen: any, brush: any, textName: string, advParams: any) {
        if (isNaN(valueForY)) return;

        const clientRect = window.ClientRectangle;
        const Y = window.PointsConverter.GetScreenY(valueForY);

        if (Y < 0 || Y > clientRect.Height) return;

        gr.DrawLine(pen, clientRect.X + 1, Y, clientRect.X + clientRect.Width, Y);

        this.DrawPriceRectangle(gr, clientRect, Y, valueForY, brush, advParams);

        this.DrawNameRectangle(gr, clientRect, Y, textName);
    }

    DrawPriceRectangle (gr: any, clientRect: any, Y: number, price: number, brush: any, advParams: any) {
        if (!advParams.DrawPointers) return;

        advParams.DrawPointers.push(new DrawPointer(DrawPointerTypeEnum.Indicator, price, brush, price));
    }

    DrawNameRectangle (gr: any, clientRect: any, Y: number, textName: string) {
        if (!this.textWidthCached[textName]) this.textWidthCached[textName] = gr.GetTextWidth(textName, this.font);

        const plateWidth = this.textWidthCached[textName] + TradingCentralLinesRenderer.NAME_PLATE_TEXT_MARGIN;
        const plateHeight = TradingCentralLinesRenderer.NAME_PLATE_HEIGHT;
        const offset = TradingCentralLinesRenderer.NAME_PLATE_OFFSET;

        const x = clientRect.X + clientRect.Width - plateWidth - offset;
        const y = Y - plateHeight - offset;

        gr.RenderButton(this.namePlateBrush, this.namePlateBorderPen, new Rectangle(x, y, plateWidth, plateHeight));

        gr.DrawString(textName, this.font, this.namePlateTextBrush, x + plateWidth / 2, y + plateHeight / 2, 'center', 'middle');
    }

    setLinesValues (dataObj: any) {
        if (!dataObj) return;

        this.dataObj = dataObj;
    }

    FindMinMax (minMaxResult: any, window: any) {
        const data = this.dataObj;
        if (!data) return false;

        minMaxResult.tMin = Number.MAX_VALUE;
        minMaxResult.tMax = -Number.MAX_VALUE;

        const resistanceValues: any[] = data.resistanceValues || [];
        const supportValues: any[] = data.supportValues || [];
        const pivotValue: any[] = data.pivotValue ? [data.pivotValue] : [];

        const allValues = resistanceValues.concat(supportValues, pivotValue);

        for (let i = 0; i < allValues.length; i++) {
            const value = allValues[i];
            if (!isNaN(value)) {
                minMaxResult.tMin = Math.min(minMaxResult.tMin, value);
                minMaxResult.tMax = Math.max(minMaxResult.tMax, value);
            }
        }

        return true;
    }

    Localize () {
        const prefix = 'panel.tradingCentral.chart.';

        this.resistanceLocalizedText = Resources.getResource(prefix + 'resistance');
        this.supportLocalizedText = Resources.getResource(prefix + 'support');
        this.pivotLocalizedText = Resources.getResource(prefix + 'pivot');
    }

    ThemeChanged () {
        super.ThemeChanged();

        const theme = ThemeManager.CurrentTheme;

        this.namePlateBrush = theme.grayBackgroundBrush;
        this.namePlateBorderPen = theme.grayBackgroundPen;
        this.namePlateTextBrush = theme.TC_namePlateTextBrush;

        this.pivotPen = theme.TC_pivotPen;
        this.resistancePen = theme.TC_resistancePen;
        this.supportPen = theme.TC_supportPen;

        this.pivotBrush = theme.TC_pivotBrush;
        this.resistanceBrush = theme.TC_resistanceBrush;
        this.supportBrush = theme.TC_supportBrush;

        this.font = ThemeManager.Fonts.Font_10F_regular;
    }
}
