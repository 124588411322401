// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { CustomErrorClass, ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { Rectangle } from '@shared/commons/Geometry';
import { LinkedSystem } from '../misc/LinkedSystem';
import { KeyCode, KeyEventProcessor } from '@shared/commons/KeyEventProcessor';
import { AdvancedOrderEntryTemplate, OrderEditControlsTemplate, OrderEditParameterVisibilityTemplate } from '../../templates.js';
import { DockSystemInstance } from '../DockSystem';
import { TerceraLinkControlConstants } from '../UtilsClasses/TerceraLinkControlConstants';
import { TerceraMenu } from '../elements/TerceraMenu';
import { PanelNames } from '../UtilsClasses/FactoryConstants';
import { OrderEditViewBase } from './OrderEditViewBase';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { PlacedFrom } from '@shared/utils/Trading/PlacedFrom';
import { RulesSet } from '@shared/utils/Rules/RulesSet';
import { Account } from '@shared/commons/cache/Account';
import { InstrumentSpecificType } from '@shared/utils/Instruments/InstrumentSpecificType';
import { DynProperty } from '@shared/commons/DynProperty';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { Instrument } from '@shared/commons/cache/Instrument';
import { IsAllowed, IsAllowedResponce } from '@shared/commons/IsAllowed';
import { Quantity } from '@shared/utils/Trading/Quantity';
import { OrderEditUpdateData } from '@shared/utils/Trading/OrderEditUpdateData';
import { DataCache } from '@shared/commons/DataCache';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { TradingNumericErrorChecker } from '@shared/commons/Trading/TradingNumericErrorChecker';
import { OrderExecutorUtils } from '@shared/commons/Trading/OrderExecutorUtils';
import { MathUtils } from '@shared/utils/MathUtils';
import { type OrderEditBase } from '@shared/commons/cache/OrderParams/order-edit/OrderEditBase';
import { type OrderTypeBase } from '@shared/commons/cache/OrderParams/order-type/OrderTypeBase';
import { type ITradingData } from '@shared/utils/Trading/TradingData';
import { type TIF } from '@shared/utils/Trading/OrderTif';
import { type ProductType } from '@shared/utils/Instruments/ProductType';
import { type OEQuantity } from '../trading/OE/OEQuantity';
import { WDSettings } from '../settings/WDGeneralSettings';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';
import { HotkeysManager } from '@shared/commons/cache/Hotkeys/HotkeysManager';
import { OrderEntryHotkeysEnum } from '@shared/commons/cache/Hotkeys/NamesEventHotkey ';
import { type OperationType } from '@shared/utils/Trading/OperationType';
import { type MarginOEControl } from './MarginOEControl';

// TODO. Optimize. DynProperty + js properties interface for orderEdit parameters.
// Something like NotificationObserver in MVVM .NET.
// OrderEdit parameter <-> SINGLE reusable DynProperty per Parameter <-> Ractive Control.

export class AdvancedOrderEntry extends OrderEditViewBase {
    public static readonly TIME_TO_HIDE = 1000;

    public headerLocaleKey: string = 'panel.orderEntry';
    public NewParametrsHandler: any = null;
    public closeTimeout: any = null;
    public AutoHideHeaderUpdateHandler: any = null;
    public IsResized: boolean = false;
    public updateTradingAllowedStuff: any;
    public getCallBackInstrument: any;
    public flushQtyTimer: NodeJS.Timeout;
    private marginOEResizeObserver: ResizeObserver;

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): PanelNames { return PanelNames.AdvancedOrderEntry; }

    public override oninit (): void {
        super.oninit();

        this.observe('instrument', this.onInstrumentChanged);
        this.observe('account', this.onAccountChanged);

        void this.set('accountVisible', DataCache.getNumberOfAccounts() > 1);

        this.observe(
            'orderType',
            this.onOrderTypeChanged);

        this.observe(
            'instrument account quantity estQtyValue cash isCashQtyMode side tif productType disclosedQuantity positionSizingChecked leverageValue userComment',
            this.onTradingDataChanged);

        this.observe('positionSizingChecked', this.updatePositionSizingControls);
        this.observe('instrument account isCashQtyMode', this.turnOffPositionSizing);
        this.observe('isCashQtyMode', this.needUpdateAllowedOrderTypes);

        this.on('placeOrderClick', this.onPlaceOrderClick);
        this.on('btnCancelClick', () => { this.close(); });

        if (!Resources.isHidden('OrderEntry.InfoBlock.Visibility')) {
            void this.set('width', 350);
            void this.set('marginOEAllowed', true);
            this.on('onMarginOEVisibleStateChanged', this.onMarginOEVisibleStateChanged);
        }

        void this.set('positionSizingVisible', DataCache.isAllowedForMyUser(RulesSet.FUNCTION_POSITION_SIZING));
        this.observe('delayedVisible snapshotVisible dataSourceVisible', this.deferLayout);
        this.observe('dataSourceOpen', this.onDataSourceOpen);
        DataCache.OnSnapshotResponseReceived.Subscribe(this.updateGetSnapshotBtn, this); // обновление enability кнопок Get snapshot если на клиенте совершили Get snapshot request
        Account.TradeStatusChanged.Subscribe(this.updateGetSnapshotBtn, this); // обновление enability кнопок Get snapshot при изменениях значения на сервере
        this.updateGetSnapshotBtn();

        DataCache.OnUpdateAccount.Subscribe(this.onUpdateAccount, this);
        DataCache.OnUpdateInstrument.Subscribe(this.onUpdateInstrument, this);

        this.on('attachClick', this.AttachClick);
    }

    public override oncomplete (): void {
        super.oncomplete();
        void this.set({ showAttachHeaderButton: DockSystemInstance.isAutoHideAvaliable() });
        DockSystemInstance.RegisterPanel(this);

        this.OnResize.Subscribe(this.resizingProcess, this);

        KeyEventProcessor.OnKeyDown.Subscribe(this.onGlobalKeyDown, this);

        if (this.get<boolean>('isCreateOrderParmeters')) {
            void this.set({ showAttachHeaderButton: false, isAccountLinkShow: false, isSymbolLinkShow: false, canLinkByAccount: false });
        }

        if (this.get<boolean>('attached')) {
            void this.set({ visible: false, movable: false });
            DockSystemInstance.addToAutoHide(this);
            MainWindowManager.MainWindow.resetFocus();
        }
        this.onInstrumentChanged(this.get('instrument'), null);
        this.marginOEResizeObserver = new ResizeObserver(this.layoutMarginOE.bind(this));
        this.marginOEResizeObserver.observe(this.find('.advanced-order-entry'));

        this.SetFirstElement();
        this.center();
    }

    public override updatePanelHeader (): void {
        const ins: Instrument = this.get('instrument');
        const header = Resources.getResource(this.headerLocaleKey) + (!isNullOrUndefined(ins) ? (' ' + ins.DisplayName()) : '');
        void this.set({ header });
        if (!isNullOrUndefined(this.AutoHideHeaderUpdateHandler)) {
            this.AutoHideHeaderUpdateHandler(header);
        }
    }

    public onInstrumentChanged (instrument: Instrument, lastInstrument: Instrument): void {
        if (isNullOrUndefined(instrument) || instrument === lastInstrument || !this.completed) {
            return;
        }

        this.subscribeRiskSettingsUpdate(instrument, lastInstrument);

        this.instrumentSettingsUpdate();
        this.isShowCommentSelector();

        this.symbolLink_Out(false, instrument);
        this.updatePanelHeader();
        this.deferLayout();
    }

    public onAccountChanged (account: Account, lastAccount): void {
        if (isNullOrUndefined(account)) { return; }

        this.instrumentSettingsUpdate(); // for productTypeShow and others update

        this.accountLink_Out(false, account);
    }

    public resizingProcess (): void {
        this.IsResized = true;

        if (this.get<boolean>('marginOEVisible') && !isNullOrUndefined(this.Controls.marginOEControl)) {
            this.Controls.marginOEControl.layoutTable();
        }
    }

    public UpdateAutoHideSate (state): void {
        if (this.get<boolean>('attached') || this.get<boolean>('isCreateOrderParmeters')) {
            return;
        }
        void this.set('showAttachHeaderButton', state);
    }

    public ForcedAutoHide (): void {
        if (!this.get<boolean>('attached')) {
            return;
        }

        clearTimeout(this.closeTimeout);
        void this.set({ visible: false });
        MainWindowManager.MainWindow.resetFocus();
    }

    public AttachClick (): void {
        let attached: boolean = this.get('attached');
        if (attached) {
            attached = !attached;
            clearTimeout(this.closeTimeout);
            void this.set({ visible: true, movable: true, attached, resizable: false });

            if (this.get<boolean>('marginOEVisible') && !isNullOrUndefined(this.Controls.marginOEControl)) {
                void this.set('resizable', true);
            }

            DockSystemInstance.removeFromAutoHide(this);
        } else {
            attached = !attached;
            this.IsResized = false;
            void this.set({ visible: false, movable: false, attached, resizable: false });
            DockSystemInstance.addToAutoHide(this);
        }
        this.layout();

        if (!attached) {
            this.center();
        }

        MainWindowManager.MainWindow.resetFocus();

        if (this.get<boolean>('marginOEVisible') && !isNullOrUndefined(this.Controls.marginOEControl)) {
            this.Controls.marginOEControl.layoutTable();
        }

        const btnattachText = attached ? Resources.getResource('panel.external.Dettach') : Resources.getResource('panel.external.Attach');
        void this.set('header_button_text', btnattachText);
    }

    public AutoHideHover (): void {
        clearTimeout(this.closeTimeout);
        const visible: boolean = this.get('visible');
        if (visible) {
            return;
        }
        void this.set({ visible: true });
        this.ChangeAutohideLocation();
    }

    public onGlobalKeyDown (key: KeyCode): void {
        if (this.get<boolean>('focused')) {
            if (key === KeyCode.ESC) {
                this.close();
            }
        }
    }

    public AutoHideLeave (): void {
        if (this.get<boolean>('attached')) {
            this.MakeMeHidden();
        }
    }

    public MakeMeHidden (): void {
        clearTimeout(this.closeTimeout);
        this.closeTimeout = setTimeout(() => {
            if (!this.get<boolean>('focused')) {
                void this.set({ visible: false });
            }
            clearTimeout(this.closeTimeout);
        }, AdvancedOrderEntry.TIME_TO_HIDE);
    }

    public ChangeAutohideLocation (): void {
        const newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(
            2000,
            52,
            this.get('width'),
            this.get('height')));

        this.setLocation(newCoord.newX - 27, newCoord.newY);
    }

    public override lostFocus (): void {
        super.lostFocus();
        if (!this.get<boolean>('attached')) {
            return;
        }

        if (MainWindowManager.TerceraInstrumentLookupDropDownForm?.get<boolean>('visible')) {
            MainWindowManager.TerceraInstrumentLookupDropDownForm.AfterLostFocusWaiting = this;
            return;
        }

        if (MainWindowManager.TerceraAccountLookupDropDownForm?.get<boolean>('visible')) {
            MainWindowManager.TerceraAccountLookupDropDownForm.AfterLostFocusWaiting = this;
            return;
        }
        this.MakeMeHidden();
    }

    public override onMouseEnter (event): void {
        this.AutoHideHover();
    }

    public override onMouseLeave (event): void {
        this.AutoHideLeave();
    }

    public override gotFocus (): void {
        super.gotFocus();
        clearTimeout(this.closeTimeout);
    }

    public override dispose (): void {
        if (this.get<boolean>('attached')) {
            DockSystemInstance.removeFromAutoHide(this);
        }

        DockSystemInstance.UnRegisterPanel(this);

        KeyEventProcessor.OnKeyDown.UnSubscribe(this.onGlobalKeyDown, this);

        DataCache.OnUpdateAccount.UnSubscribe(this.onUpdateAccount, this);
        DataCache.OnUpdateInstrument.UnSubscribe(this.onUpdateInstrument, this);
        DataCache.OnSnapshotResponseReceived.UnSubscribe(this.updateGetSnapshotBtn, this);
        Account.TradeStatusChanged.UnSubscribe(this.updateGetSnapshotBtn, this);

        const instrument: Instrument = this.get('instrument');
        if (!isNullOrUndefined(instrument)) { // TODO! в AdvancedOrderEntry нет метода updateTradingAllowedStuff, заменим на this.instrumentSettingsUpdate ?
            instrument.RiskSettingsUpdated.UnSubscribe(this.updateTradingAllowedStuff, this);
        }

        this.OnResize.UnSubscribe(this.resizingProcess, this);

        this.marginOEResizeObserver.disconnect();
        OrderEditViewBase.prototype.dispose.call(this);
    }

    public SetFirstElement (): void {
        if (this.get<boolean>('accountVisible')) {
            this.Controls.OEAccountLookup.setFocus();
        } else {
            this.Controls.OEInstrumentLookup.setFocus();
        }
    }

    public onPlaceOrderClick (): void {
        if (this.get<boolean>('isCreateOrderParmeters')) {
            this.saveOrderParam();
        } else {
            if (TradingNumericErrorChecker.HasErrors(this)) {
                return;
            }

            this.placeOrder(this.orderEdit);
        }
    }

    public async selectOrderType (newOrderType: OrderType): Promise<void> {
        const forbiddenOrderTypes = this.get('forbiddenOrderTypes');
        const orderTypeDict = OrderExecutorUtils.getAllowedOrderTypeDict(this.get('account'), this.get('instrument'), forbiddenOrderTypes, WDSettings.orderType, false);
        const orderType = orderTypeDict[newOrderType] ?? orderTypeDict[OrderType.Market];
        const selectedItem = { value: orderType, text: Resources.getResource(orderType.localizationKey()) };
        await this.set('selOrderTypeCBItem', selectedItem);
    }

    public onOrderTypeChanged (newOrderType: OrderTypeBase, oldOrderType: OrderTypeBase): void {
        if (isNullOrUndefined(newOrderType) || newOrderType === oldOrderType || newOrderType?.id() === oldOrderType?.id()) {
            return;
        }

        this.isShowCommentSelector();
        const orderEdit: OrderEditBase = newOrderType.createOrderEditObject({
            dataCache: DataCache
        });

        this.setOrderEdit(orderEdit);

        this.addDisclosedQuantityIfNeed();

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            this.getAllTradingDataDict()));

        this.updatePositionSizingControls();
    }

    private isShowCommentSelector (): void {
        const orderType: OrderTypeBase = this.get('orderType');
        if (!isNullOrUndefined(orderType) && orderType.id() === OrderType.Care) {
            const instrument: Instrument = this.get('instrument');
            if (!isNullOrUndefined(instrument)) {
                void this.set('enableComment', instrument.EnableComment);
            }
        } else {
            void this.set('enableComment', false);
        }
    }

    // TODO. Rename; Parameters' constants.
    public onTradingDataChanged (newVal, oldVal, key: string): void {
        if (newVal === oldVal) return;

        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit)) return;

        const tradingDataDict: any = {};
        tradingDataDict[key] = newVal;

        tradingDataDict.completed = this.completed;

        this.addDisclosedQuantityIfNeed(key);

        orderEdit.updateParameters(new OrderEditUpdateData(
            null,
            tradingDataDict));

        this.deferLayout();
    }

    // TODO. Rename; Parameters' constants.
    public getAllTradingDataDict (): ITradingData {
        const tradingDataDict: ITradingData = {};

        const instrument: Instrument = this.get('instrument');
        if (!isNullOrUndefined(instrument)) tradingDataDict.instrument = instrument;

        const account: Account = this.get('account');
        if (!isNullOrUndefined(account)) tradingDataDict.account = account;

        const quantity: Quantity = this.get('quantity');
        if (!isNullOrUndefined(quantity)) tradingDataDict.quantity = quantity;

        const estQtyValue: number = this.get('estQtyValue');
        if (!isNullOrUndefined(estQtyValue)) tradingDataDict.estQtyValue = estQtyValue;

        const cash: number = this.get('cash');
        if (!isNullOrUndefined(cash)) tradingDataDict.cash = cash;

        const isCashQtyMode: boolean = this.get('isCashQtyMode');
        if (!isNullOrUndefined(isCashQtyMode)) tradingDataDict.isCashQtyMode = isCashQtyMode;

        const tif: TIF = this.get('tif');
        if (!isNullOrUndefined(tif)) tradingDataDict.tif = tif;

        const side: OperationType = this.get('side');
        if (side !== null) tradingDataDict.side = side;

        const productType: ProductType = this.get('productType');
        if (productType !== null) tradingDataDict.productType = productType;

        const leverageValue: number = this.get('leverageValue');
        if (isValidNumber(leverageValue) && leverageValue !== 0) tradingDataDict.leverageValue = leverageValue;

        const disclosedQuantity = this.get('disclosedQuantity');
        if (disclosedQuantity !== null) tradingDataDict.disclosedQuantity = disclosedQuantity;

        const positionSizingChecked: boolean = this.get('positionSizingChecked');
        if (!isNullOrUndefined(positionSizingChecked)) tradingDataDict.positionSizingChecked = positionSizingChecked;

        const userComment: string = this.get('userComment');
        if (isValidString(userComment)) tradingDataDict.userComment = userComment;

        tradingDataDict.completed = this.completed;

        return tradingDataDict;
    }

    public override layout (): void {
        this.updateOrderEditParametersVisibility();

        if (this.get<boolean>('attached')) {
            void this.set('height', MainWindowManager.MainWindow.GetAvaliableHeight());
        } else {
            void this.set('height', null);
        }

        // Adjust the panel if it overflows the screen #124527
        this.resizePanelToFitScreen();
    }

    private resizePanelToFitScreen (): void {
        /* Check that the panel is fully within the screen */
        const panelRect = this.find('*').getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const isPanelOverflowing = panelRect.bottom > windowHeight;

        if (isPanelOverflowing) {
            /* If the panel doesn't fit, move it up by the overflow amount */
            const offset = panelRect.bottom - windowHeight;
            const newTopPosition = panelRect.top - offset;
            this.setLocation(panelRect.left, newTopPosition);
        }
    }

    public saveOrderParam (): void {
        if (!isNullOrUndefined(this.NewParametrsHandler)) {
            this.NewParametrsHandler(this.orderEdit);
        }

        this.close();
    }

    public placeOrder (orderEdit): void {
        orderEdit.placedFrom = PlacedFrom.WEB_OE;
        DataCache.FOrderExecutor.placeOrderPromise(orderEdit, null, null, this.focusWarningNumeric.bind(this)).catch(function () {
            const ex = new CustomErrorClass('AdvancedOrderEntry error', 'AdvancedOrderEntry.placeOrder', 'placeOrder -> placeOrderPromise');
            ErrorInformationStorage.GetException(ex);
        });
    }

    public override updateSettings (): void {
        const ins: Instrument = this.get('instrument');
        const acc: Account = this.get('account');

        if (isNullOrUndefined(ins) || isNullOrUndefined(acc)) {
            return;
        }

        this.updateTradingAllowedAndReasonTooltip();
    }

    // #region ICaller

    public override callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        void this.set('instrument', this.getCallBackInstrument(properties, 'symbol'));

        let dp = DynProperty.getPropertyByName(properties, 'account');
        if (dp?.value != null) {
            void this.set('account', SessionSettings.getDefValueFromObj(DataCache.Accounts[dp.value], DataCache.Accounts));
        }

        dp = DynProperty.getPropertyByName(properties, 'marginOEVisible');
        if (!isNullOrUndefined(dp)) {
            void this.set('marginOEVisible', dp.value);
        }

        dp = DynProperty.getPropertyByName(properties, 'dataSourceOpen');
        if (!isNullOrUndefined(dp)) {
            void this.set('dataSourceOpen', dp.value);
        }

        dp = DynProperty.getPropertyByName(properties, 'resizable');
        if (!isNullOrUndefined(dp)) {
            void this.set('resizable', dp.value);
        }

        const attached = DynProperty.getPropValue(properties, 'attached');
        void this.set({ attached });
    }

    public override Properties (): DynProperty[] {
        const properties = super.Properties();
        const ins = this.get('instrument');
        if (!isNullOrUndefined(ins)) {
            properties.push(new DynProperty('symbol', ins.GetInteriorID(), DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        const acc = this.get('account');
        if (!isNullOrUndefined(acc)) {
            properties.push(new DynProperty('account', acc.AcctNumber, DynProperty.STRING, DynProperty.HIDDEN_GROUP));
        }

        const marginOEVisible = this.get('marginOEVisible');
        properties.push(new DynProperty('marginOEVisible', marginOEVisible, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        const resizable = this.get('resizable');
        properties.push(new DynProperty('resizable', resizable, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        const dataSourceOpen = this.get('dataSourceOpen');
        properties.push(new DynProperty('dataSourceOpen', dataSourceOpen, DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        properties.push(new DynProperty('attached', this.get('attached'), DynProperty.BOOLEAN, DynProperty.HIDDEN_GROUP));

        return properties;
    }

    // #endregion

    // #region Link

    public override symbolLink_Out (newSubscriber, instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            const ins: Instrument = this.get('instrument');
            if (isNullOrUndefined(ins)) return;
            instrument = ins;
        }

        const color = this.get('symbolLinkValue');
        if (color !== TerceraLinkControlConstants.STATE_NONE) {
            LinkedSystem.setSymbol(color, instrument.GetInteriorID(), newSubscriber);
        }
    }

    public override symbolLink_In (symbolName: string): void {
        const newInstr: Instrument = DataCache.getInstrumentByName(symbolName);
        if (!isNullOrUndefined(newInstr)) {
            void this.set('instrument', newInstr);
        }
    }

    // #endregion Link

    public override localize (): void {
        super.localize();
        void this.set('disclosedLabel', Resources.getResource('panel.newOrderEntry.disclosedLabel'));
        void this.set('buttonCancelText', Resources.getResource('general.messageBox.cancel'));

        void this.set('delayedText', Resources.getResource('panel.newOrderEntry.delayedText'));
        void this.set('snapshotText', Resources.getResource('panel.newOrderEntry.snapshotText'));
        const btnattachText = this.get<boolean>('attached') ? Resources.getResource('panel.external.Dettach') : Resources.getResource('panel.external.Attach');
        void this.set('header_button_text', btnattachText);
        this.updateTooltipForSnapshotBtn();
    }

    public onUpdateAccount (account: Account): void {
        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit)) return;

        orderEdit.onUpdateAccount(account);
    }

    public onUpdateInstrument (instrument: Instrument): void {
        const myInstrument: Instrument = this.get('instrument');
        if (!isNullOrUndefined(myInstrument) && myInstrument === instrument) {
            this.updateTradingAllowedAndReasonTooltip();
        }
    }

    public instrumentSettingsUpdate (): void {
        const instrument = this.get('instrument');
        const productType = this.get('productType');
        const account = this.get('account');

        if (!Instrument.IsWorkingInstrument(instrument)) {
            return;
        }

        if (!isNullOrUndefined(account)) {
            this.updateTradingAllowedAndReasonTooltip();
        }

        void this.set('productTypeShow', instrument.isProductTypeVisible(account));
        void this.set('leverageShow', instrument.isLeverageVisible(account, productType));

        this.updateDelayedAndSnapshotVisibility();
    }

    public updateTradingAllowedAndReasonTooltip (): void {
        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');

        const ordertype: OrderTypeBase = this.get('orderType');
        let ordT = -1;
        if (!isNullOrUndefined(ordertype)) {
            ordT = ordertype.id();
        }

        let tradingAllowed = IsAllowed.IsTradingAllowed([account], instrument, ordT);
        if (this.get<boolean>('isCreateOrderParmeters')) {
            tradingAllowed = IsAllowedResponce.AllowedResponce();
        }

        if (tradingAllowed.Allowed && ordT === -1) {
            tradingAllowed.ReasonText = Resources.getResource('Not selected order type');
            tradingAllowed.Allowed = false;
        }

        void this.set({
            isAllowedResponce: tradingAllowed,
            tradingAllowed: tradingAllowed.Allowed,
            tradingForbiddenReason: tradingAllowed.ReasonText
        });
    }

    public subscribeRiskSettingsUpdate (instrument: Instrument, lastInstrument: Instrument): void {
        if (!isNullOrUndefined(lastInstrument)) {
            lastInstrument.RiskSettingsUpdated.UnSubscribe(this.instrumentSettingsUpdate, this);
        }

        if (!isNullOrUndefined(instrument)) {
            instrument.RiskSettingsUpdated.Subscribe(this.instrumentSettingsUpdate, this);
        }
    }

    public onMarginOEVisibleStateChanged (): void {
        const oldState: boolean = this.get('marginOEVisible');
        const newState = !oldState;

        void this.set('marginOEVisible', newState);
        void this.set('width', newState ? 684 : 350);

        if (!this.get<boolean>('attached')) {
            void this.set('resizable', newState);
        }

        if (!newState) {
            this.IsResized = false;
            this.layout();
        }

        if (this.get<boolean>('attached')) {
            this.ChangeAutohideLocation();
        }
    }

    public override onQuantityChanged (qtyValue: number): void {
        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit)) return;

        const ins = orderEdit.instrument;
        if (isNullOrUndefined(ins)) return;

        const OEQty: OEQuantity = !isNullOrUndefined(this.Controls) ? this.Controls.OEQuantity : null;
        const acc = orderEdit.account; const pt = orderEdit.productType;
        const isAllowedResponce: IsAllowedResponce = this.get('isAllowedResponce');

        if (isAllowedResponce?.Allowed) { // доп валидацию по qty посчитанную position sizing-ом можно применить если торговля разрешена в IsAllowed.IsTradingAllowed
            const inLots = !isNullOrUndefined(orderEdit.sessionSettings) ? WDSettingsUtils.displayAmountInLots() : null;
            const minLot = Quantity.convertQuantityValue(new Quantity(ins.getMinLot(pt, acc), true), ins, inLots, acc, pt);
            const maxLot = Quantity.convertQuantityValue(new Quantity(ins.getMaxLot(pt, acc), true), ins, inLots, acc, pt);

            const tradingAllowed = qtyValue >= minLot && qtyValue <= maxLot;
            const reasonLocKey = tradingAllowed
                ? ''
                : ('positionSizeCalculator.OEButtonDisabled.' + (qtyValue < minLot ? 'less' : 'more'));

            let reasonText = Resources.getResource(reasonLocKey);
            if (!tradingAllowed) {
                reasonText = reasonText.replace('{0}', (qtyValue < minLot ? minLot : maxLot).toString());
            }

            void this.set({
                tradingAllowed,
                tradingForbiddenReason: reasonText
            });
        }

        if (!isNullOrUndefined(OEQty)) {
            void OEQty.set('value', qtyValue);
            OEQty.updateQuantity();/// // TODO !!!!! TODO !!!!!!!!!!!!!!!!!!!!!!!!!
        }

        if (this.get<boolean>('positionSizingChecked')) {
            this.flushQuantity();
        }
    }

    public flushQuantity (): void {
        void this.set('qtyCustomCSSClass', 'js-flushed-qty-style');

        if (!isNullOrUndefined(this.flushQtyTimer)) {
            clearTimeout(this.flushQtyTimer);
        }

        this.flushQtyTimer = setTimeout(() => { void this.set('qtyCustomCSSClass', ''); }, 1000);
    }

    public updatePositionSizingControls (): void { // обновить подсветку лейблов, участвующих в positionSizing-e, включить SL
        if (isNullOrUndefined(this.orderEdit)) return;

        const checked: boolean = this.get('positionSizingChecked');

        setTimeout(() => {
            if (isNullOrUndefined(this.orderEdit)) return;

            if (checked) {
                this.enableSL();
            } // при смене orderEdit сбрасывается enable sl-а, который при включенном positionSizing должен быть всегда

            this.orderEdit.customCSS = checked ? 'js-flushed-labels-for-position-sizing' : '';

            const paramArray = this.get('orderEditParameterArray');
            for (let i = 0; i < paramArray.length; i++) {
                if (!this.orderEdit.skipParamForFlashing(paramArray[i].name)) {
                    paramArray[i].customCSS = checked ? 'js-flushed-labels-for-position-sizing' : '';
                }
            }

            void this.set('orderEditParameterArray', paramArray);
        }, 1);
    }

    public turnOffPositionSizing (newData, oldData): void { // #100580
        if (isNullOrUndefined(newData) || isNullOrUndefined(oldData)) {
            return;
        }

        if (newData instanceof Instrument && newData.GetInteriorID() === oldData.GetInteriorID()) { // инструменты
            return;
        } // #100615 коммент

        if (newData instanceof Account && newData.AcctNumber === oldData.AcctNumber) { // аккаунты
            return;
        }

        void this.set('positionSizingChecked', false);
    }

    public enableSL (): void {
        const orderEdit = this.orderEdit;
        if (isNullOrUndefined(orderEdit?.enableSL)) {
            return;
        }

        orderEdit.enableSL();
    }

    public updateDelayedAndSnapshotVisibility (): void {
        let instrument: Instrument = this.get('instrument');
        if (isNullOrUndefined(instrument)) {
            return;
        }

        if (instrument.InstrumentSpecificType === InstrumentSpecificType.ContinuousContract) {
            instrument = DataCache.getInstrument(instrument.InstrumentTradableID.toString(), instrument.Route);
        } // #100762

        const canGetSnapshot = instrument.CanGetSnapshot();
        const delayMoreThanZero = instrument.GetDelay() > 0;
        const subsribeErrorCode = instrument.HasLvl1SubscriptionErrorCode;
        let delayedVisible = delayMoreThanZero; // 5), 6), 7), 8)
        let snapshotVisible = canGetSnapshot; // 1), 2), 3), 4), 6), 7), 8)

        // 1) delay > 0 && snapshot == TRUE && error code = true - ПОКАЗЫВАТЬ КНОПКУ, НЕ ПОКАЗЫВАТЬ DELAYED DATA - не реальный кейс
        // 2) delay > 0 && snapshot == TRUE &&  error code = false - ПОКАЗЫВАТЬ КНОПКУ И DELAYED DATA
        // 3) delay > 0 && snapshot == FALSE && error code = false - НЕ ПОКАЗЫВАТЬ КНОПКУ И DELAYED DATA
        // 4) delay > 0 && snapshot == FALSE &&  error code = true - НЕ ПОКАЗЫВАТЬ КНОПКУ И DELAYED DATA

        // 5) delay = 0 && snapshot == TRUE && error code = true - ПОКАЗЫВАТЬ КНОПКУ, НЕ ПОКАЗЫВАТЬ DELAYED DATA
        // 6) delay = 0 && snapshot == TRUE && error code = false - НЕ ПОКАЗЫВАТЬ КНОПКУ И DELAYED DATA
        // 7) delay = 0 && snapshot == FALSE && error code = false - НЕ ПОКАЗЫВАТЬ КНОПКУ И DELAYED DATA
        // 8) delay = 0 && snapshot == FALSE && error code = true - НЕ ПОКАЗЫВАТЬ КНОПКУ И DELAYED DATA

        if (delayMoreThanZero) {
            if (canGetSnapshot) { // 1), 2)
                delayedVisible = !subsribeErrorCode;
            } else { // 3), 4)
                delayedVisible = false;
            } // 5)
        } else if (canGetSnapshot) {
            snapshotVisible = subsribeErrorCode;
        }

        void this.set({
            snapshotVisible,
            delayedVisible
        });
    }

    public updateGetSnapshotBtn (): void {
        this.updateSnapshotBtnEnability();
        this.updateTooltipForSnapshotBtn();
    }

    public updateTooltipForSnapshotBtn (): void {
        const snapshotBtnDisabled = AdvancedOrderEntry.isGetSnapshotBtnDisabled();
        const tt = Resources.getResource('panel.newOrderEntry.snapshotBtn.' + (snapshotBtnDisabled ? 'disabled' : 'enabled') + '.tt');

        void this.set('snapshotBtnTooltip', tt);
    }

    public updateSnapshotBtnEnability (): void {
        void this.set('snapshotBtnDisabled', AdvancedOrderEntry.isGetSnapshotBtnDisabled());
    }

    public static isGetSnapshotBtnDisabled (): boolean {
        return DataCache.getAvailableNumberOfSnapshotRequests() === 0;
    }

    public onDataSourceOpen (): void {
        this.deferLayout();

        const marginOE: MarginOEControl = this.Controls.marginOEControl;
        if (!isNullOrUndefined(marginOE)) {
            setTimeout(() => { marginOE.UpdateTable(); }, 100);
        }
    }

    public async fillByOrderEdit (orderEdit: OrderEditBase): Promise<void> {
        if (MathUtils.IsNullOrUndefined(orderEdit)) {
            return;
        }
        await this.set({ instrument: orderEdit.instrument, account: orderEdit.account });
        await this.selectOrderType(orderEdit.getOrderTypeId());
        await this.set({
            side: orderEdit.side,
            defaultTif: orderEdit.tif,
            productType: orderEdit.productType,
            defaultQuantity: orderEdit.quantity,
            disclosedQuantity: orderEdit.disclosedQuantity,
            leverageValue: orderEdit.leverageValue
        });
        const cash = orderEdit.cash;
        const isCashQtyMode = orderEdit.isCashQtyMode = cash > 0;
        this.setOrderEdit(orderEdit);
        await this.set({ isCashQtyMode, cash });
        if (isCashQtyMode) {
            this.onQuantityChanged(cash);
        }
    }

    public override HotkeyPressed (hotkey: string): boolean {
        const isPressed = super.HotkeyPressed(hotkey);
        if (isPressed) {
            return true;
        }

        const hotkeyAction = HotkeysManager.GetActionByHotkeyForPanel(hotkey, this.getType());
        switch (hotkeyAction) {
        case OrderEntryHotkeysEnum.PlaceOrder:
            this.placeOrder(this.orderEdit);
            return true;
        }

        return false;
    }

    private needUpdateAllowedOrderTypes (isCashQtyMode: boolean): void {
        if (this.orderEdit != null) { this.orderEdit.skipSessionSettingsChanged = true; }
        const forbiddenOrderTypes = isCashQtyMode ? [OrderType.OCO] : [];
        void this.set({ forbiddenOrderTypes });
    }

    private layoutMarginOE (): void {
        if (!this.get<boolean>('marginOEVisible') || isNullOrUndefined(this.Controls.marginOEControl)) {
            return;
        }

        this.Controls.marginOEControl.layoutTable();
    }
}

OrderEditViewBase.extendWith(AdvancedOrderEntry, {
    partials: {
        bodyPartial: AdvancedOrderEntryTemplate,
        orderEditControls: OrderEditControlsTemplate,
        orderEditParameterVisibility: OrderEditParameterVisibilityTemplate
    },
    data: function () {
        return {
            showFooter: false,
            resizable: false,
            width: 340,
            // height: 500,
            minWidth: 570,
            dockablePanel: false,
            showHeader: true,
            isAccountLinkShow: false,
            isSymbolLinkShow: true,

            instrument: null,
            account: null,
            quantity: null, // QuantityObject
            side: null,
            tif: null, // TIF object
            orderType: null,
            productType: null,
            productTypeShow: false,
            leverageShow: false,
            enableComment: false,
            userComment: '',

            disclosedQuantityShow: false,
            disclosedQuantity: null,
            dqMinValue: null,
            dqMaxValue: null,
            dqTooltip: 'panel.newOrderEntry.disclosedLabel.tt',
            dqLessMinLocalKey: 'UserControl.TerceraDQNumeric.ValueLessMin',
            dqGreaterMaxLocalKey: 'UserControl.TerceraDQNumeric.ValueGreaterMax',
            disclosedLabel: '',

            accountVisible: false, // resulting account lookup visibility
            isEnabledInstrumentLookup: true,
            isEnabledAccountLookup: true,
            canFilterByAccount: false,

            selOrderTypeCBItem: null,
            isCreateOrderParmeters: false,

            placeButtonAdditionalClass: '',
            placeButtonLocalizationKey: '',

            isCashQtyMode: false, // #122525
            estQtyValue: null,

            positionSizingVisible: false,
            positionSizingChecked: false,
            delayedVisible: false,
            snapshotVisible: false,
            delayedText: '',
            snapshotText: '',
            snapshotBtnTooltip: '',
            snapshotBtnDisabled: false,

            dataSourceVisible: false, // true to enable data source
            dataSourceOpen: false,

            marginOEAllowed: false,
            marginOEVisible: false,
            // isHeaderMenuButtonShow: true,
            showAttachHeaderButton: true,
            style_addition_header_button: 'js-button-attach style-width-100',
            attached: false,

            forbiddenOrderTypes: []
        };
    }
});
