// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MarkupFormula } from './MarkupFormula';

export class MarkupValue {
    public CrossRates: any;
    public Formula: any;

    constructor (crossRates: any = undefined, formula: any = undefined) {
        this.CrossRates = crossRates || {};
        this.Formula = !isNaN(formula) && formula !== null ? formula : MarkupFormula.None;
    }
}
