// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { StandardFormatterName, type OrderTableColumn } from '../charting_library';
import { TvBaseColumns } from './TvBaseColumns';

class _TvOrderColumns extends TvBaseColumns {
    public getOrderColumns (): OrderTableColumn[] {
        const symbol = this.createColumnItem('panel.orders.symbol', 'symbol', StandardFormatterName.Symbol);
        const side = this.createColumnItem('panel.orders.action', 'side', StandardFormatterName.Side);
        const type = this.createColumnItem('panel.orders.type',
            'type',
            StandardFormatterName.Type,
            false,
            ['type', 'parentId', 'stopType']);
        const qty = this.createColumnItem('panel.orders.quantity', 'qty', StandardFormatterName.FormatQuantity);
        const qtyrem = this.createColumnItem('panel.orders.QuantityRemaining', 'qtyrem', StandardFormatterName.FormatQuantity);
        const limitPrice = this.createColumnItem('panel.orders.price', 'limitPrice', StandardFormatterName.FormatPrice);
        const stopPrice = this.createColumnItem('panel.orders.StopPrice', 'stopPrice', StandardFormatterName.FormatPrice);
        const last = this.createColumnItem('panel.orders.CurrentPrice', 'last', StandardFormatterName.FormatPrice, true);
        const tif = this.createColumnItem('panel.orders.TIF', 'tif', StandardFormatterName.Text);
        const stopLoss = this.createColumnItem('panel.orders.SL', 'stopLoss', StandardFormatterName.FormatPrice);
        const takeProfit = this.createColumnItem('panel.orders.TP', 'takeProfit', StandardFormatterName.FormatPrice);
        // supportedStatusFilters: [0]
        const status = this.createColumnItem('panel.orders.Status', 'status', StandardFormatterName.Status);
        const filledQty = this.createColumnItem('panel.orders.QuantityFilled', 'filledQty', StandardFormatterName.FormatQuantity);
        const id = this.createColumnItem('panel.orders.Reference', 'id', StandardFormatterName.Text);
        const date = this.createColumnItem('panel.orders.date_time', 'date', StandardFormatterName.LocalDateOrDateTime);

        const columns =
        [
            symbol,
            side,
            type,
            qty,
            qtyrem,
            limitPrice,
            stopPrice,
            last,
            tif,
            stopLoss,
            takeProfit,
            status,
            filledQty,
            id,
            date
        ];

        return columns.filter(c => c !== null);
    }
}

export const TvOrderColumns = new _TvOrderColumns();
