// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { Interval } from '../../../../../Utils/History/Interval';
import { DateTimeConvertor } from '../../../../../Utils/Time/DateTimeConvertor';
import { type DynProperty } from '../../../../DynProperty';
import { Color } from '../../../../Graphics';
import { TimeInterval } from '../../../TimeInterval';
import { BarData, type HistoricalData } from '../../HistoricalData';
import { HistoricalDataRequest } from '../../HistoricalDataRequest';
import { IndicatorDataType, Period } from '../../IndicatorEnums';
import { IndicatorScriptBase } from '../../IndicatorScriptBase';
import { LineStyle } from '../../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../../InputParamaterClasses/InputParameterInteger';
import { InputParameterRangeControl } from '../../InputParamaterClasses/InputParameterRangeControl';
import { VWAPCalculator } from './VWAPCalculator';
import { VWAPHistoryStatus } from './VWAPHistoryStatus';

export class VWAP extends IndicatorScriptBase {
    private vwapHistoryStatus = VWAPHistoryStatus.None;

    private vwapHistoricalRequest: HistoricalDataRequest;

    public DaysCount = 1;
    public TimeInterval: TimeInterval = new TimeInterval();

    private calculator: VWAPCalculator;
    private byTrades = false;

    constructor () {
        super();

        this.ProjectName = 'Volume Weighted Average Price';
        this.Comments = 'Weighted Moving Average where Volume is used as weights';
        this.SetIndicatorLine('Line', Color.Orange2, 3, LineStyle.SimpleChart);
        this.SeparateWindow = false;
        // #97150
        this.HideLineTimeShift = true;

        super.InputParameter(new InputParameterInteger('DaysCount', 'VWAP.DaysNumber', 0, 1, 366));
        super.InputParameter(new InputParameterRangeControl('TimeInterval', 'VWAP.StartEndDayTime', 1));
    }

    public override Init (): void {
        super.Init();

        this.calculator = new VWAPCalculator(this.DaysCount, this.TimeInterval);
    }

    public override GetIndicatorShortName (): string {
        return `VWAP(${this.DaysCount})`;
    }

    public async RefreshPromise (): Promise<any> {
        await super.RefreshPromise();
        this.calculator.cleanInterval();
        this.calculator.updateParameters(this.DaysCount, this.TimeInterval);
        this.vwapHistoryStatus = VWAPHistoryStatus.None;
        if (this.calculator.IsValidTimeFrame(this.CurrentData)) {
            this.vwapHistoricalRequest = new HistoricalDataRequest({
                Instrument: this.CurrentData.Instrument,
                DataType: this.CurrentData.DataType,
                Period: Period.Min,
                PeriodValue: 1
            });
        } else {
            this.vwapHistoryStatus = VWAPHistoryStatus.Invalid;
        }

        this.byTrades = this.CurrentData.DataType === IndicatorDataType.Trade;
    }

    public override OnQuote (): void {
        super.OnQuote();

        switch (this.vwapHistoryStatus) {
        case VWAPHistoryStatus.Invalid:
            break;
        case VWAPHistoryStatus.None:
            void this.LoadVWAPHistory();
            break;
        case VWAPHistoryStatus.Processed: {
            const dateTime = DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(new Date());
            const volume = this.GetLastSize();
            const price = this.GetLastPrice();
            this.UpdateVWAPLine(dateTime, volume, price, 0);
            break;
        }
        }
    }

    // public override getMinBarsCount (): number {
    //     return 1440;
    // }

    private async LoadVWAPHistory (): Promise<void> {
        if (this.CurrentData != null && this.CurrentData.Count > 0) {
            this.vwapHistoryStatus = VWAPHistoryStatus.Loading;
            const interval = this.getCorrectTimePeriod();
            if (!isNullOrUndefined(interval)) {
                this.vwapHistoricalRequest.fromUTC = interval.From;
                this.vwapHistoricalRequest.toUTC = interval.To;
                const historicalData = await this.GetHistoricalDataPromise(this.vwapHistoricalRequest);
                this.ProcessVWAPHistory(historicalData);
            } else {
                this.vwapHistoryStatus = VWAPHistoryStatus.Invalid;
            }
        }
    }

    private ProcessVWAPHistory (historicalData: HistoricalData): void {
        if (historicalData instanceof BarData) {
            for (let i = historicalData.Count - 1; i >= 0; i--) {
                const volume = historicalData.Volume(i);
                const price = historicalData.getPriceByTypeAndOffset(PriceType.Typical, i);
                const intervalDateTime = historicalData.Time(i);
                let offset = this.CurrentData.FindInterval(intervalDateTime.getTime());
                if (offset === -1) {
                    continue;
                }
                const barTime = DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(intervalDateTime);
                const vector = this.Data?.get(0);
                offset = vector.Length - offset - 1;
                this.UpdateVWAPLine(barTime, volume, price, offset);
            }
        }
        this.vwapHistoryStatus = VWAPHistoryStatus.Processed;
    }

    private UpdateVWAPLine (dateTime: Date, volume: number, price: number, offset: number): void {
        this.calculator.CalculateVWAP(dateTime, volume, price);
        if (this.calculator.AllowSetVWAP(dateTime)) {
            this.SetValue(0, offset, this.calculator.LastVWAPValue);
        }
    }

    private GetLastPrice (): number {
        const level1 = this.CurrentData.Instrument.Level1;
        return level1.GetLastPriceByDefaultChartHistoryType();
    }

    private GetLastSize (): number {
        if (!this.byTrades) {
            return 1;
        }

        const level1 = this.CurrentData.Instrument.Level1;
        return level1.GetLastSize(false);
    }

    private getCorrectTimePeriod (): Interval | null {
        const firstBarOpenTime = new Date(this.CurrentData.FirstBarOpenTime);
        if (firstBarOpenTime.getHours() * 60 + firstBarOpenTime.getMinutes() > this.TimeInterval.getStartInMins()) {
            firstBarOpenTime.setDate(firstBarOpenTime.getDate() + 1);
        }
        firstBarOpenTime.setHours(0, 0, 0, 0);

        const to = new Date();
        to.setHours(0, 0, 0, 0);
        to.setDate(to.getDate() + 1);
        const intervalInDays = Math.floor((to.getTime() - firstBarOpenTime.getTime()) / (1000 * 60 * 60 * 24));
        const coff = Math.floor(intervalInDays / this.DaysCount);
        if (coff < 1) {
            return null;
        }
        const possibleDays = coff * this.DaysCount;

        const from = new Date(to);
        from.setDate(to.getDate() - possibleDays);
        from.setHours(this.TimeInterval.startHours, this.TimeInterval.startMins);

        return new Interval(from, to);
    }

    protected override setProperties (props: DynProperty[]): boolean {
        const needRecalculate = super.setProperties(props);
        if (!(this.TimeInterval instanceof TimeInterval)) {
            this.TimeInterval = new TimeInterval((this.TimeInterval as any).startHours, (this.TimeInterval as any).startMins, (this.TimeInterval as any).endHours, (this.TimeInterval as any).endMins);
        }
        // let dp = DynProperty.getPropertyByName(props, 'VWAP.StartEndDayTime');
        // if (!isNullOrUndefined(dp)) {
        //     needRecalculate = this.TimeInterval.tryUpdate(dp.value);
        // }

        // dp = DynProperty.getPropertyByName(props, 'VWAP.DaysNumber');
        // if (this.DaysCount !== dp?.value) {
        //     this.DaysCount = dp.value;
        //     needRecalculate = true;
        // }

        return needRecalculate;
    }
}
