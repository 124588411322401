// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ControlsTypes } from '../../../Controls/UtilsClasses/FactoryConstants';
import { TerceraCheckBox } from '../../../Controls/elements/TerceraCheckBox';
import { InvestingCheckBoxTemplate } from '../../templates';

class InvestingCheckBox extends TerceraCheckBox {
    constructor () { super(); }

    public getType (): ControlsTypes { return ControlsTypes.InvestingCheckBox; }
}

TerceraCheckBox.extendWith(InvestingCheckBox, {
    template: InvestingCheckBoxTemplate,
    data: function () {
        return {
            checked: false,
            checkBoxCheckedState: 'none',
            text: '',
            tag: null
        };
    }
});

export { InvestingCheckBox };
