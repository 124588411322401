// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
'use strict';
import { PFixField } from './PFixField';

export class PFixIntArrayField extends PFixField {
    constructor (fieldId, buffer?) {
        super(fieldId, buffer);

        this.Value = [];
    }

    public override ReadValue (buffer): void {
        const len = buffer.ReadInt32();
        const array = new Array(len);
        for (let i = 0; i < len; i++) {
            array[i] = buffer.ReadInt32();
        }

        this.Value = array;
    }

    public override Write (buffer): void {
        const array = this.Value;

        buffer.WriteShort(this.FieldId);
        buffer.WriteInt32(array.length);

        for (let i = 0, len = array.length; i < len; i++) {
            buffer.WriteInt32(array[i]);
        }
    }
}
