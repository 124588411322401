// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectRiskPlanMessage extends DirectMessageBaseClass {
    public Id = 0;
    public Description: string | null = null;
    public Name: string | null = null;
    public Data = [];

    constructor () {
        super(Message.CODE_PFIX_RISK_PLAN_MESSAGE);
    }
}
