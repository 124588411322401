// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class PasswordRecoveryChangeRequest extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_RECOVERY_CHANGE_REQUEST, fieldSet);
    }

    public setPassword (password: string): void {
        this.setFieldValue(FieldsFactory.FIELD_PASSWORD, password);
    }

    public setLogin (login: string): void {
        this.setFieldValue(FieldsFactory.FIELD_LOGIN, login);
    }

    public setKey (key: string): void {
        this.setFieldValue(FieldsFactory.FIELD_KEY, key);
    }

    public setAccessToken (accessToken: string): void {
        this.setFieldValue(FieldsFactory.FIELD_ACCESS_TOKEN, accessToken);
    }
}
