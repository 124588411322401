// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type DynProperty } from '@shared/commons/DynProperty';
import { Point, Rectangle } from '@shared/commons/Geometry';
import { SolidBrush, type Font } from '@shared/commons/Graphics';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { LayersEnum } from './Renderers/TerceraChartBaseRenderer';
import { TerceraChartMouseEventArgs } from './Utils/TerceraChartMouseEventArgs';
import { type TerceraChartWindowsContainer } from './TerceraChartWindowsContainer';
import { Layer } from './Utils/Layer';
import { Enum } from '@shared/utils/Enum';
import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Cursors } from '@shared/commons/Cursors';
import { MouseButtons } from '@front/controls/UtilsClasses/ControlsUtils';
import { TerceraChartMouseState } from './Utils/TerceraChartMouseState';
import { contextMenuHandler } from '@shared/utils/AppHandlers';
import { TerceraChartPriceScaleLayoutInfo } from './Utils/TerceraChartPriceScaleLayoutInfo';
import { type TerceraChartBaseActionProcessor } from './TerceraChartActionProcessor/TerceraChartBaseActionProcessor';
import { type TerceraChartWindowBase } from './Windows/TerceraChartWindowBase';
import { TerceraChartAdvancedParams } from './Utils/TerceraChartAdvancedParams';
import { type IChartContextMenuItem } from '@front/controls/panels/ChartContextMenuItem';
import { type TerceraChartBaseScaleRendererSettings } from './Renderers/Scales/TerceraChartBaseScaleRenderer';

export abstract class TerceraChartBase<ChartWindow extends TerceraChartWindowBase = TerceraChartWindowBase, YRenderSettings extends TerceraChartBaseScaleRendererSettings = TerceraChartBaseScaleRendererSettings, XRenderSettings extends TerceraChartBaseScaleRendererSettings = TerceraChartBaseScaleRendererSettings> {
    public context: CanvasRenderingContext2D;
    public terceraChartPanelContext;
    public width: number = 0;
    public height: number = 0;
    public w: number = 0;
    public h: number = 0;
    public OnUpdateTooltip: CustomEvent = new CustomEvent();
    public onHasCorrectDataChanged = new CustomEvent();
    public Cursor: Cursors;
    public CursorPosition: Point;
    public HoverWindowXValue: number;
    public HoverWindowYValue: number;
    public HoverWindow: TerceraChartWindowBase | null = null;
    public windowsContainer: TerceraChartWindowsContainer | null = null;
    public mainWindow: ChartWindow | null = null;
    public needRedraw: boolean = true;
    public layersCashedData: any = {};

    public TerceraChartFont: Font;
    public BottomColor: string;
    public TopColor: string;

    public lastMouseDownState: any = null;
    public lastMouseMoveArgs: any = null;

    public MouseIn: boolean = false;
    public MouseState: TerceraChartMouseState = null;

    public ContextMenuItems: IChartContextMenuItem[] = [];

    public lastMouseDownI1: number = null;
    public lastMouseDownMaxX: any = null;
    public lastMouseDownMinX: any = null;
    public lastMouseDownMaxY: number = null;
    public lastMouseDownMinY: number = null;

    public MovingAlloved: boolean = true;
    public snapToCandle: boolean = false;

    public BarsToRigth_Bars: number;
    public NoDataMessage: string = 'No data';
    public TerceraChartNoDataBrushColor: SolidBrush;
    public oldHasCorrectData: boolean;
    public _forceUpdateInitiators = [];

    public xScaleRendererSettings: XRenderSettings;
    public yScaleRendererSettings: YRenderSettings;

    public TerceraChartActionProcessor: TerceraChartBaseActionProcessor;

    protected _rightBarIndexIncludingEmptyBars = 0;
    protected readonly minBarIndexOnChart = 1;

    private layers: Record<string, Layer> = {};
    private readonly DEFAULT_BARSTORIGHT_PERCENT: number = 10;

    constructor (ctx: CanvasRenderingContext2D, terceraChartPanelContext) {
        this.context = ctx;
        this.terceraChartPanelContext = terceraChartPanelContext;
        this.BarsToRigth_Bars = this.minBarIndexOnChart;
        this.initDrawingsLayers();
    }

    public get rightBarIndexIncludingEmptyBars (): number {
        return this._rightBarIndexIncludingEmptyBars;
    }

    public set rightBarIndexIncludingEmptyBars (value) {
        this.mainWindow.i1 = value;
        this._rightBarIndexIncludingEmptyBars = value;
    }

    public Initialize (): void {
        this.windowsContainer = this.CreateWindowsContainer();
        this.mainWindow = this.CreateMainWindow();
        this.mainWindow.IsMainWindow = true;
        this.windowsContainer.Windows.push(this.mainWindow);
        this.InitializeRenderers();
    }

    public getType (): string {
        return 'TerceraChartBase';
    }

    // #region ThemeChanged/Localize
    public ThemeChanged (): void {
        this.TerceraChartNoDataBrushColor = new SolidBrush(ThemeManager.CurrentTheme.ContentColor);
        this.TerceraChartFont = ThemeManager.Fonts.Font_10F_regular.copy();
        this.BottomColor = ThemeManager.CurrentTheme.Chart_BottomColor;
        this.TopColor = ThemeManager.CurrentTheme.Chart_TopColor;
        const windowsContainer = this.windowsContainer;
        if (!isNullOrUndefined(windowsContainer)) {
            windowsContainer.ThemeChanged();
            const wcRenderers = windowsContainer.Renderers;
            for (let i = 0; i < wcRenderers.length; i++) {
                wcRenderers[i].ThemeChanged();
            }
            for (let i = 0; i < windowsContainer.Windows.length; i++) {
                const allRenderers = windowsContainer.Windows[i].GetAllRenderers();
                for (let j = 0; j < allRenderers.length; j++) {
                    allRenderers[j].ThemeChanged();
                }
            }
        }
        this.IsDirty();
    }

    public Localize (): void {
        const windwsContainer = this.windowsContainer;
        if (isNullOrUndefined(windwsContainer)) {
            return;
        }
        windwsContainer.Localize();
        this.IsDirty();
    }
    // #endregion

    // #region Properties/Callback
    public Properties (): DynProperty[] {
        let properties: DynProperty[] = [];
        const len = this.windowsContainer.Renderers.length;
        for (let i = 0; i < len; i++) {
            properties = properties.concat(this.windowsContainer.Renderers[i].Properties());
        }

        const windowsLength = this.windowsContainer.Windows.length;
        for (let windowIndex = 0; windowIndex < windowsLength; windowIndex++) {
            const window = this.windowsContainer.Windows[windowIndex];
            const renderersLength = window.Renderers.length;
            for (let rendererIndex = 0; rendererIndex < renderersLength; rendererIndex++) {
                properties = properties.concat(window.Renderers[rendererIndex].Properties());
            }
        }
        return properties;
    }

    public callBack (properties: DynProperty[]): void {
        const len = this.windowsContainer.Renderers.length;
        for (let i = 0; i < len; i++) {
            this.windowsContainer.Renderers[i].callBack(properties);
        }

        const windowsLength = this.windowsContainer.Windows.length;
        for (let windowIndex = 0; windowIndex < windowsLength; windowIndex++) {
            const window = this.windowsContainer.Windows[windowIndex];
            const renderersLength = window.Renderers.length;
            for (let rendererIndex = 0; rendererIndex < renderersLength; rendererIndex++) {
                window.Renderers[rendererIndex].callBack(properties);
            }
        }
    }
    // #endregion

    // #region EventsProcessors
    public ImproveArgs (ev: any): TerceraChartMouseEventArgs {
        const X = ev.offsetX;
        const Y = ev.offsetY;
        const HoverWindow = this.SetWindowCursorPosition(X, Y);
        // Стараемся не перерисовывать чарт на каждое движение мыши
        const result = new TerceraChartMouseEventArgs(HoverWindow, ev);
        return result;
    }

    public ProcessMouseDown (e: any): boolean {
        this.lastMouseDownState = e.Location;
        const isProcessed = this.windowsContainer.ProcessMouseDown(e);
        if (!isProcessed && this.BarsCount() > 0) {
            if (e.Button !== MouseButtons.Left) {
                return false;
            }
            // Тягание чарта?
            const activeWindow: TerceraChartWindowBase = this.GetActiveWindowFromEvent(e);
            if (isNullOrUndefined(activeWindow) || !activeWindow.ClientRectangle.Contains(e.Location.X, e.Location.Y)) {
                return false;
            }
            this.lastMouseDownI1 = this.rightBarIndexIncludingEmptyBars; // ! from main
            this.lastMouseDownMaxX = activeWindow.FmaxFloatX;
            this.lastMouseDownMinX = activeWindow.FminFloatX;
            this.lastMouseDownMaxY = activeWindow.FmaxFloatY;
            this.lastMouseDownMinY = activeWindow.FminFloatY;
            this.MouseState = TerceraChartMouseState.Moving;
            return true;
        }
    }

    public ProcessMouseUp (e: any): boolean {
        const mouseProcessed = this.windowsContainer.ProcessMouseUp(e);
        if (mouseProcessed) {
            this.UpdateCursor(e);
            this.UpdateTooltip(e);
        }

        this.MouseState = TerceraChartMouseState.None;
        this.IsDirty();

        if (!mouseProcessed) {
            if (e.Button === MouseButtons.Right) {
                const contextMenuItems = this.GetContextMenuItems(e, this);
                if (contextMenuItems !== null) {
                    contextMenuHandler.Show(contextMenuItems, e.ClientLocation.X, e.ClientLocation.Y);
                }
            }
        }
        return mouseProcessed;
    }

    public ProcessMouseClick (e: any): boolean {
        return this.windowsContainer.ProcessMouseClick(e);
    }

    public ProcessMouseDoubleClick (e: any): boolean {
        return this.windowsContainer.ProcessMouseDoubleClick(e);
    }

    public ProcessMouseWheel (e: any): boolean {
        let res = false;
        res = this.windowsContainer.ProcessMouseWheel(e);
        if (res) {
            this.IsDirty();
        }
        return res;
    }

    public ProcessMouseEnter (e: any): void {
        try {
            this.windowsContainer.ProcessMouseEnter(e);
            this.MouseIn = true;
            this.IsDirty();
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
    }

    public ProcessMouseMove (e: any): boolean {
        let mouseMoveProcessed = false;
        this.lastMouseMoveArgs = e;
        // Идёт тягание чарта
        if (this.MouseState === TerceraChartMouseState.Moving && this.MovingAlloved) {
            const xDelta = e.Location.X - this.lastMouseDownState.X;
            if (xDelta !== 0) {
                this.moveScreenByX(this.calulateNewI1FromDelta(xDelta), true);
            }
            const yDelta = e.Location.Y - this.lastMouseDownState.Y;
            const activeWindow = this.GetActiveWindow();
            if (yDelta !== 0 && !isNullOrUndefined(activeWindow) && !activeWindow.AutoScale) {
                this.moveScreenByY(activeWindow, yDelta);
            }
            this.UpdateCursor(e);
            mouseMoveProcessed = true;
            e.NeedRedraw = true;
        } else {
            try {
                mouseMoveProcessed = this.windowsContainer.ProcessMouseMove(e);
            } finally {
                this.UpdateCursor(e);
                this.UpdateTooltip(e);
            }

            if (!mouseMoveProcessed) {
                const len = this.mainWindow.Renderers.length;
                let res;
                for (let i = 0; i < len; i++) {
                    res = this.mainWindow.Renderers[i].ProcessMouseMove(e);
                }
            }
        }

        if (e.NeedRedraw === true) {
            this.IsDirty();
        } else if (!e.NeedRedraw) {

        } else if (e.NeedRedraw.length) {
            const len = e.NeedRedraw.length;
            for (let i = 0; i < len; i++) {
                this.IsDirty(e.NeedRedraw[i]);
            }
        } else {
            this.IsDirty(e.NeedRedraw);
        }

        return mouseMoveProcessed;
    }

    public ProcessMouseLeave (e: any): void {
        try {
            this.windowsContainer.ProcessMouseLeave(e);
            this.HoverWindow = null;
            this.CursorPosition = Point.Empty();
            this.HoverWindowYValue = 0;
            this.MouseIn = false;
            this.IsDirty();
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
            console.log(ex);
        }
        this.UpdateCursor(null);
        this.UpdateTooltip(null);
    }

    public OnKeyDown (): void { }

    public OnResize (width: number, height: number): void {
        this.w = width;
        this.h = height;

        this.resizeLayers(width, height);

        this.windowsContainer.Rectangle.Width = width;
        this.windowsContainer.Rectangle.Height = height;
        this.windowsContainer.LayoutWindows();
        this.CheckBarsCountOnScreen();

        this.IsDirty();
    }

    protected CheckBarsCountOnScreen (): void {

    }
    // #endregion

    // #region Layers
    public initDrawingsLayers (): void {
        const keys = Enum.TakeValueFromEnum(LayersEnum);
        const len = keys.length;
        const l = {};

        for (let i = 0; i < len; i++) {
            l[keys[i]] = new Layer(document.createElement('canvas'));
        }

        this.layers = l;
        this.layersCashedData.keys = Object.keys(this.layers);
        this.layersCashedData.layersLength = this.layersCashedData.keys.length;
    }

    public clearLayers (): void {
        const len = this.layersCashedData.layersLength;
        for (let i = 0; i < len; i++) {
            const lay = this.layers[this.layersCashedData.keys[i]];
            if (lay.isDirty) {
                lay.context.clearRect(0, 0, this.w, this.h);
            }
        }
    }

    public resizeLayers (w: number, h: number): void {
        const devicePixelRatio = window?.devicePixelRatio || 1;
        const len = this.layersCashedData.layersLength;
        for (let i = 0; i < len; i++) {
            const lay = this.layers[this.layersCashedData.keys[i]];
            lay.canvas.width = w * devicePixelRatio;
            lay.canvas.height = h * devicePixelRatio;
            lay.context.scale(devicePixelRatio, devicePixelRatio);
        }
    }

    public needRedrawAllLayers (): void {
        const len = this.layersCashedData.layersLength;
        for (let i = 0; i < len; i++) {
            this.layers[this.layersCashedData.keys[i]].isDirty = true;
        }
    }

    public drawLayersData (): void {
        const len = this.layersCashedData.layersLength;
        for (let i = 0; i < len; i++) {
            const lay = this.layers[this.layersCashedData.keys[i]];
            this.context.drawImage(lay.canvas, 0, 0, this.w, this.h);
            lay.isDirty = false;
        }
    }

    public IsDirty (result: boolean | LayersEnum = true): void {
        if (result === true) {
            this.needRedrawAllLayers();
        } else {
            this.layers[result as LayersEnum].isDirty = true;
        }

        this.needRedraw = true;
    }
    // #endregion

    // #region Tooltip
    public GetTooltip (e): string {
        const len = this.mainWindow.Renderers.length;
        for (let i = 0; i < len; i++) {
            const renderer = this.mainWindow.Renderers[i];
            const newTooltip = renderer.GetTooltip(e);
            if (newTooltip != null) {
                return newTooltip;
            }
        }

        const newTooltip = this.windowsContainer.GetTooltip(e);
        if (newTooltip != null) {
            return newTooltip;
        }

        return null;
    }

    public UpdateTooltip (e): void {
        if (isNullOrUndefined(e)) {
            return;
        }
        let newTooltip = null;
        try {
            newTooltip = this.GetTooltip(e);
        } finally {
            this.OnUpdateTooltip.Raise(newTooltip);
        }
    }
    // #endregion

    // #region Cursor
    public GetCursor (e: any): Cursors {
        const len = this.mainWindow.Renderers.length;
        for (let i = 0; i < len; i++) {
            const renderer = this.mainWindow.Renderers[i];
            const newCursor = renderer.GetCursor(e);
            if (newCursor != null) {
                return newCursor;
            }
        }

        const newCursor = this.windowsContainer.GetCursor(e);
        if (newCursor != null) {
            return newCursor;
        }

        return null;
    }

    public UpdateCursor (e: any = null): void {
        if (e == null) {
            return;
        }

        let newCursor = null;
        try {
            newCursor = this.GetCursor(e);
        } finally {
            // поменялся или стал обратно дефаулт
            if (this.Cursor !== newCursor && newCursor !== null || (this.Cursor !== Cursors.Default && newCursor === null)) {
                this.IsDirty(LayersEnum.CrossHair);
                this.Cursor = newCursor;
                this.terceraChartPanelContext.applyCursor(newCursor);
            }
        }
    }

    public ForceUpdateCursor (): void {
        if (isNullOrUndefined(this.lastMouseMoveArgs)) {
            return;
        }
        const newCursor = this.GetCursor(this.lastMouseMoveArgs);
        if (!isNullOrUndefined(newCursor)) {
            this.IsDirty(LayersEnum.CrossHair);
            this.Cursor = newCursor;
            this.terceraChartPanelContext.applyCursor(newCursor);
        }
    }
    // #endregion

    // #region Window

    // Initialize containers, windows, renderers

    // Step 1: Intialize windows container with renderers
    public abstract CreateWindowsContainer (): TerceraChartWindowsContainer;
    // Step 2: Intialize main window with renderers
    public abstract CreateMainWindow (): ChartWindow;
    // Step 3: Initialize other renderers
    public abstract InitializeRenderers (): void;

    public GetActiveWindow (): TerceraChartWindowBase {
        return this.HoverWindow;
    }

    public GetActiveWindowFromEvent (e: any): TerceraChartWindowBase {
        return e.window ?? this.mainWindow;
    }

    public GetHoverWindow (point: Point): TerceraChartWindowBase {
        for (let i = 0; i < this.windowsContainer.Windows.length; i++) {
            if (this.windowsContainer.Windows[i].Rectangle.Contains(point.X, point.Y)) {
                return this.windowsContainer.Windows[i];
            }
        }
        return null;
    }

    public GetWindowByPoint (point: Point): TerceraChartWindowBase {
        if (this.windowsContainer == null) {
            return null;
        }
        if (this.windowsContainer.Windows != null) {
            for (let i = 0; i < this.windowsContainer.Windows.length; i++) {
                const clientRect = this.windowsContainer.Windows[i].ClientRectangle;
                if (clientRect.Contains(point.X, point.Y)) {
                    return this.windowsContainer.Windows[i];
                }
            }
        }
        return null;
    }

    public GetLastMouseDownWindow (): TerceraChartWindowBase {
        return this.GetWindowByPoint(this.lastMouseDownState);
    }

    public SetWindowCursorPosition (X: number, Y: number): TerceraChartWindowBase {
        this.CursorPosition = new Point(X, Y);
        const HoverWindow = this.GetHoverWindow(new Point(X, Y));
        if (HoverWindow != null) {
            this.HoverWindowXValue = HoverWindow.PointsConverter.GetDataX(X);
            this.HoverWindowYValue = HoverWindow.PointsConverter.GetDataY(Y);
        } else {
            this.HoverWindowYValue = 0;
            this.HoverWindowXValue = 0;
        }
        this.HoverWindow = HoverWindow;
        return HoverWindow;
    }

    public LayoutWindowsContainers (): void {
        if (this.windowsContainer != null) {
            this.windowsContainer.Rectangle.Width = this.w;
            this.windowsContainer.Rectangle.Height = this.h;
        }
        this.windowsContainer.LayoutWindows();
        this.IsDirty();
    }

    public moveScreenByY (activeWindow: TerceraChartWindowBase, yDelta): void {
        const mouseDownWindow = this.GetLastMouseDownWindow();
        if (mouseDownWindow === activeWindow) {
            const delta = yDelta / (activeWindow.ClientRectangle.Height / (activeWindow.FmaxFloatY - activeWindow.FminFloatY));
            activeWindow.MoveY(this.lastMouseDownMinY + delta, this.lastMouseDownMaxY + delta);
        }
    }

    public moveScreenByX (newI1: number, allowChangeBarsToRight: boolean = false): boolean {
        const mainWindow = this.mainWindow;
        const barsCount = this.BarsCount();
        const possibleRightBarIndex = barsCount - 1 + this.BarsToRigth_Bars;
        // Пытаемся установить правую границу в будущее - разрешаем, только если allowChangeBarsToRight
        if ((newI1 > possibleRightBarIndex || Math.floor(newI1 - mainWindow.im()) >= barsCount - this.minBarIndexOnChart) && allowChangeBarsToRight) {
            const newBarsToRigth = (newI1 - (barsCount - 1));
            const maxEmptyRightBarIndex = Math.floor(mainWindow.im() - this.minBarIndexOnChart);
            if (newBarsToRigth > maxEmptyRightBarIndex) {
                this.BarsToRigth_Bars = maxEmptyRightBarIndex;
                newI1 = barsCount - 1 + maxEmptyRightBarIndex;
            } else {
                this.BarsToRigth_Bars = newBarsToRigth;
            }
        } else if (newI1 > possibleRightBarIndex) {
            newI1 = possibleRightBarIndex;
        }

        if (this.isNeedUpdateRightIndex(newI1)) {
            this.rightBarIndexIncludingEmptyBars = newI1;
            return true;
        } else {
            return false;
        }
    }

    public ToBegin (): void {
        const barsCount = this.BarsCount();
        if (barsCount === 0) {
            return;
        }
        // reset BarsToRight
        this.BarsToRigth_Bars = Math.ceil(this.mainWindow.im() * this.DEFAULT_BARSTORIGHT_PERCENT / 100);
        this.moveScreenByX(barsCount - 1 + this.BarsToRigth_Bars);
    }

    public ToCustomPositionOnChart (newPosition: number): void {
        const barsCount = this.BarsCount();
        if (barsCount === 0) {
            return;
        }
        this.moveScreenByX(newPosition);
    }

    public IsAtTheBegin (): boolean {
        const barsCount = this.BarsCount();
        if (barsCount === 0) {
            return true;
        }
        return this.mainWindow.i1 > barsCount;
    }

    public BarsCount (): number {
        return 0;
    }

    public HasCorrectData (): boolean {
        return this.BarsCount() > 0;
    }

    public FindIntervalExactly (dataX: number): number {
        return 0;
    }

    public CalculateMinMax (): void {
        this.windowsContainer.CalculateMinMax();
    }

    public getSnapToCandle (): boolean {
        return this.snapToCandle;
    }

    public setSnapToCandle (value: boolean): void {
        if (this.snapToCandle === value) {
            return;
        }

        this.snapToCandle = value;

        let i = 0;
        const len = this.mainWindow.Renderers.length;
        for (i; i < len; i++) {
            if (this.mainWindow.Renderers[i].ApplyNewSnapMode) {
                this.mainWindow.Renderers[i].ApplyNewSnapMode(this.snapToCandle);
            }
        }
    }

    public XScaleRect (): Rectangle {
        return this.windowsContainer.xScaleRenderer.Rectangle;
    }

    public ZoomToRectangle (r, startPoint): void {
        const mainWindow = this.mainWindow;
        if (r.Width < mainWindow.XScale) {
            return;
        }

        const i1Time = mainWindow.PointsConverter.GetDataX(r.X + r.Width);
        const newI1Index = this.FindIntervalExactly(i1Time) - 1;

        const barsInRect = r.Width / mainWindow.XScale;
        const newxScale = mainWindow.ClientRectangle.Width / barsInRect;
        mainWindow.CheckAndSetXScale(Math.round(newxScale));

        this.moveScreenByX(Math.round(newI1Index));

        const clickedWindow = this.GetWindowByPoint(startPoint);
        if (clickedWindow) {
            const clickedWindowPC = clickedWindow.PointsConverter;

            const RY = r.Y;
            const RB = RY + r.Height;

            const windows = this.windowsContainer.Windows;
            const wLen = windows.length;
            for (let i = 0; i < wLen; i++) {
                const ww = windows[i];
                // Анализируем окно, в котором делали зуминг - устаналвиваем значения min/max
                if (ww === clickedWindow) {
                    clickedWindow.AutoScale = false;
                    clickedWindow.FmaxFloatY = clickedWindowPC.GetDataY(RY);
                    clickedWindow.FminFloatY = clickedWindowPC.GetDataY(RB);
                }
                // Для остальных окон цену не имеем права ставить, поэтом просто autofit'им
                else {
                    if (!ww.AutoScale) {
                        ww.AutoFit();
                    }
                }
            }
        }
        this.Draw();
    }

    protected calulateNewI1FromDelta (deltaX: number): number {
        return Math.round(this.lastMouseDownI1 - deltaX);
    }
    // #endregion

    // #region Context menu
    public GetContextMenuItems (e, chart): any {
        return this.HasCorrectData() ? this.windowsContainer.GetContextMenu(e, chart) : null;
    }
    // #endregion

    // #region Drawing
    public GetAdvancedParamsForRenderer (gr: CanvasRenderingContext2D): TerceraChartAdvancedParams {
        const p: TerceraChartAdvancedParams = new TerceraChartAdvancedParams();
        p.Font = this.TerceraChartFont;
        p.CursorPosition = this.CursorPosition;
        p.HoverWindowXValue = this.HoverWindowXValue;
        p.HoverWindowYValue = this.HoverWindowYValue;
        p.TerceraChart = this;
        p.DrawPointers = [];
        p.LayoutInfo = this.GetTerceraChartPriceScaleLayoutInfo(gr);
        p.layers = this.layers;
        p.Tag = {};
        p.layerId = LayersEnum.Main;
        p.exitAfterFirst = null;
        p.isNeedConnectFirstAndLastPoints = true;
        return p;
    }

    public GetTerceraChartPriceScaleLayoutInfo (gr: CanvasRenderingContext2D): TerceraChartPriceScaleLayoutInfo {
        const res = new TerceraChartPriceScaleLayoutInfo();
        let rightMaxWidth = 0;
        let leftMaxWidth = 0;
        for (let i = 0; i < this.windowsContainer.Windows.length; i++) {
            const rightYScaleRenderer = this.windowsContainer.Windows[i].rightYScaleRenderer;
            if (!isNullOrUndefined(rightYScaleRenderer)) {
                const curRightWidth = this.windowsContainer.Windows[i].rightYScaleRenderer.GetPreferredWidth(gr, rightYScaleRenderer.FormatValue.bind(rightYScaleRenderer));
                if (rightMaxWidth < curRightWidth) {
                    rightMaxWidth = curRightWidth;
                }
            }
            const leftYScaleRenderer = this.windowsContainer.Windows[i].leftYScaleRenderer;
            if (!isNullOrUndefined(leftYScaleRenderer)) {
                const curLeftWidth = this.windowsContainer.Windows[i].leftYScaleRenderer.GetPreferredWidth(gr, leftYScaleRenderer.FormatValue.bind(leftYScaleRenderer));
                if (leftMaxWidth < curLeftWidth) {
                    leftMaxWidth = curLeftWidth;
                }
            }
        }
        // Celing to 5 pixels
        const ceilingRightVal = Math.max(Math.floor(Math.ceil(rightMaxWidth / 5.0) * 5), 0);
        const ceilingLeftVal = Math.max(Math.floor(Math.ceil(leftMaxWidth / 5.0) * 5), 0);
        res.PreferredWidthScales = ceilingRightVal;
        res.PreferredWidthScalesLeft = ceilingLeftVal;
        return res;
    }

    public SynchronizeBeforeDraw (): void {
        const barsCount = this.BarsCount();
        if (barsCount === 0) {
            return;
        }
        // Synhronize i1, XScale between all windows
        for (let i = 1; i < this.windowsContainer.Windows.length; i++) {
            this.windowsContainer.Windows[i].i1 = this.mainWindow.i1;
            this.windowsContainer.Windows[i].XScale = this.mainWindow.XScale;
        }
    }

    public DrawNoData (gr: CanvasRenderingContext2D): void {
        const r = new Rectangle(0, 0, this.w, this.h);
        const textSizes = gr.measureText(this.NoDataMessage);
        const locationImg = (r.Width - textSizes.width - 42) / 2;
        if (locationImg > 0) {
            const img = ThemeManager.CurrentTheme.messageboxIconInfoImage;
            if (!isNullOrUndefined(img)) {
                gr.drawImage(img, locationImg, r.Height / 2 - 16);
            }
            gr.DrawString(this.NoDataMessage, this.TerceraChartFont, this.TerceraChartNoDataBrushColor, locationImg + 42, r.Height / 2 - this.TerceraChartFont.Height / 2);
        } else {
            gr.DrawString(this.NoDataMessage, this.TerceraChartFont, this.TerceraChartNoDataBrushColor, r.X, r.Y);
        }
    }

    public Draw (): void {
        this.SynchronizeBeforeDraw();
        // Clear old image
        this.context.clearRect(0, 0, this.w, this.h);
        this.clearLayers();

        this.UpdateHasCorrectData();
        if (!this.HasCorrectData()) {
            this.DrawNoData(this.context);
            return;
        }

        // Set correct min/max for windows
        this.CalculateMinMax();
        this.DrawRenderersOnLayers();
        this.drawLayersData();
    }

    public DrawRenderersOnLayers (): void {
        const len = this.layersCashedData.layersLength;
        // Get all drawing params
        const advParams = this.GetAdvancedParamsForRenderer(this.context);
        const w = this.windowsContainer.Windows;
        const l = w.length;
        for (let i = 0; i < l; i++) {
            if (w[i].CalcScales()) {
                this.IsDirty(true);
                this.clearLayers();
            }
        }
        for (let i = 0; i < len; i++) {
            const key = this.layersCashedData.keys[i];
            const lay = this.layers[key];
            if (!lay.isDirty) {
                continue;
            }
            const gr = lay.context;
            advParams.layerId = parseInt(key);
            // Start drawing renderers
            this.windowsContainer.Draw(gr, advParams);
        }
    }

    public AddForceUpdate (): void {
        for (let i = 0; i < this._forceUpdateInitiators.length; i++) {
            const rend = this._forceUpdateInitiators[i];
            if (rend.IsNeedForceUpdate()) {
                this.IsDirty(rend.GetAssignLayer());
            }
        }
    }
    // #endregion

    public UpdateHasCorrectData (): void {
        const hasCorrectData = this.HasCorrectData();
        const onHasCorrectDataChanged = this.oldHasCorrectData !== hasCorrectData;
        if (onHasCorrectDataChanged) {
            this.oldHasCorrectData = hasCorrectData;
            this.onHasCorrectDataChanged.Raise(hasCorrectData);
        }
    }

    public Dispose (): void {
        this.windowsContainer.Dispose();
        this.clearContextAndLayers();
    }

    protected isNeedUpdateRightIndex (newIndex: number): boolean {
        return this.rightBarIndexIncludingEmptyBars !== newIndex;
    }

    private clearContextAndLayers (): void {
        this.context.clearRect(0, 0, this.w, this.h);
        this.context = null;

        const len = this.layersCashedData.layersLength;
        for (let i = 0; i < len; i++) {
            const lay = this.layers[this.layersCashedData.keys[i]];
            lay.context.clearRect(0, 0, this.w, this.h);
            lay.canvas.width = 0;
            lay.canvas.height = 0;
            lay.canvas.remove();
            lay.canvas = null;
            lay.context = null;
        }

        this.layers = {};
        this.layersCashedData = {};
    }

    public afterZoomOutEvents (): void {}
}
