// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '@shared/localizations/Resources';
import { Rectangle } from '@shared/commons/Geometry';
import { LayersEnum, TerceraChartBaseRenderer } from './TerceraChartBaseRenderer';
import { MouseButtons } from '@front/controls/UtilsClasses/ControlsUtils';
import { TerceraChartAction, TerceraChartActionEnum } from '../TerceraChartAction';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { type TerceraChartBase } from '../TerceraChartBase';
import { type TerceraChartBaseActionProcessor } from '../TerceraChartActionProcessor/TerceraChartBaseActionProcessor';
import { type TerceraChartMouseEventArgs } from '../Utils/TerceraChartMouseEventArgs';

export class TerceraChartZoomRenderer extends TerceraChartBaseRenderer {
    static MARGIN = 100;
    static SIZE = 24;
    static SPACEBETWEENBTNS = 12;

    zoomInRect: Rectangle;
    zoomOutRect: Rectangle;
    toBeginRect: Rectangle = new Rectangle();
    navigationBtnsContainer = new Rectangle();
    scalingByRectRect: Rectangle;
    Rectangle: Rectangle;
    CurrentVisibleButtons: number;
    CurrentHoverButtonType: number;
    chartActionProcessor: TerceraChartBaseActionProcessor;

    static zoomInDefaultImage: any;
    static zoomInHoverImage: any;
    static zoomInDisableImage: any;
    static zoomOutDefaultImage: any;
    static zoomOutHoverImage: any;
    static zoomOutDisableImage: any;
    static toBeginDefaultImage: any;
    static toBeginHoverImage: any;
    static toBeginDisableImage: any;

    private opacity = 0;
    private opacityTimer: NodeJS.Timeout;

    constructor (chart: TerceraChartBase, chartActionProcessor: TerceraChartBaseActionProcessor) {
        super(chart);

        this.zoomInRect = new Rectangle();
        this.zoomOutRect = new Rectangle();
        this.scalingByRectRect = new Rectangle();
        this.Rectangle = new Rectangle();
        this.CurrentVisibleButtons = ChartZoomRendererButtons.All;
        this.CurrentHoverButtonType = ChartZoomRendererButtons.None;
        this.assignLayer = LayersEnum.Tools;

        this.chartActionProcessor = chartActionProcessor;
        this.ThemeChanged();
        this.SetClassName('TerceraChartZoomRenderer');
    }

    override Draw (gr: CanvasRenderingContext2D, window: any, windowsContainer: any, advParams: any = null): void {
        if (this.opacity <= 0) {
            return;
        }

        const MARGIN = TerceraChartZoomRenderer.MARGIN;
        const SIZE = TerceraChartZoomRenderer.SIZE;
        const spaceBetweenBtns = TerceraChartZoomRenderer.SPACEBETWEENBTNS;
        let x = this.Rectangle.X + MARGIN;
        const y = this.Rectangle.Y + MARGIN;

        let state;
        let im;

        gr.globalAlpha = this.opacity;

        if ((this.CurrentVisibleButtons & ChartZoomRendererButtons.ZoomOut) !== 0) {
            this.zoomOutRect.X = x;
            this.zoomOutRect.Y = y;
            this.zoomOutRect.Width = SIZE;
            this.zoomOutRect.Height = SIZE;
            x += SIZE + spaceBetweenBtns;

            im = TerceraChartZoomRenderer.zoomOutDefaultImage;
            state = this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, ChartZoomRendererButtons.ZoomOut));
            if (!state.Enabled) {
                im = TerceraChartZoomRenderer.zoomOutDisableImage;
            } else if (this.CurrentHoverButtonType === ChartZoomRendererButtons.ZoomOut) {
                im = TerceraChartZoomRenderer.zoomOutHoverImage;
            }

            if (im) gr.drawImage(im, this.zoomOutRect.X, this.zoomOutRect.Y);
        }

        if ((this.CurrentVisibleButtons & ChartZoomRendererButtons.ZoomIn) !== 0) {
            this.zoomInRect.X = x;
            this.zoomInRect.Y = y;
            this.zoomInRect.Width = SIZE;
            this.zoomInRect.Height = SIZE;
            x += SIZE + spaceBetweenBtns;

            im = TerceraChartZoomRenderer.zoomInDefaultImage;
            state = this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, ChartZoomRendererButtons.ZoomIn));
            if (!state.Enabled) {
                im = TerceraChartZoomRenderer.zoomInDisableImage;
            } else if (this.CurrentHoverButtonType === ChartZoomRendererButtons.ZoomIn) {
                im = TerceraChartZoomRenderer.zoomInHoverImage;
            }

            if (im) gr.drawImage(im, this.zoomInRect.X, this.zoomInRect.Y);
        }

        if ((this.CurrentVisibleButtons & ChartZoomRendererButtons.ToBegin) !== 0) {
            this.toBeginRect.X = x;
            this.toBeginRect.Y = y;
            this.toBeginRect.Width = SIZE;
            this.toBeginRect.Height = SIZE;

            im = TerceraChartZoomRenderer.toBeginDefaultImage;
            state = this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ToBegin, ChartZoomRendererButtons.ToBegin));
            if (!state.Enabled) {
                im = TerceraChartZoomRenderer.toBeginDisableImage;
            } else if (this.CurrentHoverButtonType === ChartZoomRendererButtons.ToBegin) {
                im = TerceraChartZoomRenderer.toBeginHoverImage;
            }

            if (im) gr.drawImage(im, this.toBeginRect.X, this.toBeginRect.Y);
        }

        const btnsCount = !isNullOrUndefined(im) ? 3 : 2
        this.navigationBtnsContainer.X = this.zoomOutRect.X + SIZE / 2;
        this.navigationBtnsContainer.Y = this.zoomOutRect.Y;
        this.navigationBtnsContainer.Width = (SIZE + spaceBetweenBtns) * (btnsCount - 1);
        this.navigationBtnsContainer.Height = SIZE;

        gr.globalAlpha = 1.0;
    }

    ThemeChanged (): void {
        super.ThemeChanged();

        TerceraChartZoomRenderer.ThemeChangedStatic();
    }

    static ThemeChangedStatic (): void {
        TerceraChartZoomRenderer.zoomInDefaultImage = ThemeManager.CurrentTheme.zoomInDefaultImage;
        TerceraChartZoomRenderer.zoomInHoverImage = ThemeManager.CurrentTheme.zoomInHoverImage;
        TerceraChartZoomRenderer.zoomInDisableImage = ThemeManager.CurrentTheme.zoomInDisableImage;
        TerceraChartZoomRenderer.zoomOutDefaultImage = ThemeManager.CurrentTheme.zoomOutDefaultImage;
        TerceraChartZoomRenderer.zoomOutHoverImage = ThemeManager.CurrentTheme.zoomOutHoverImage;
        TerceraChartZoomRenderer.zoomOutDisableImage = ThemeManager.CurrentTheme.zoomOutDisableImage;
        TerceraChartZoomRenderer.toBeginDefaultImage = ThemeManager.CurrentTheme.tobeginDefaultImage;
        TerceraChartZoomRenderer.toBeginHoverImage = ThemeManager.CurrentTheme.tobeginHoverImage;
        TerceraChartZoomRenderer.toBeginDisableImage = ThemeManager.CurrentTheme.tobeginDisableImage;
    }

    GetCurrentHoverButton (pt: any): number {
        if (this.zoomInRect.Contains(pt.X, pt.Y)) return ChartZoomRendererButtons.ZoomIn;
        else if (this.zoomOutRect.Contains(pt.X, pt.Y)) return ChartZoomRendererButtons.ZoomOut;
        else if (this.toBeginRect.Contains(pt.X, pt.Y) && this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ToBegin, ChartZoomRendererButtons.ToBegin)).Enabled) return ChartZoomRendererButtons.ToBegin;
        else if (this.navigationBtnsContainer.Contains(pt.X, pt.Y)) return ChartZoomRendererButtons.Container;
        // else if (this.scalingByRectRect.Contains(pt.X, pt.Y)) return ChartZoomRendererButtons.ScalingByRectangle;
        else return ChartZoomRendererButtons.None;
    }

    ProcessMouseDown (e: any): boolean {
        if (e.Button !== MouseButtons.Left || !this.Visible) return false;
        const newHoverButtonType = this.GetCurrentHoverButton(e.Location);
        if (newHoverButtonType === ChartZoomRendererButtons.None) return false;
        if (newHoverButtonType === ChartZoomRendererButtons.ToBegin) {
            this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ToBegin, this));
        }

        return this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, newHoverButtonType));
    }

    ProcessMouseMove (e: any): boolean {
        const oldVisible = this.Visible;
        this.Visible = this.Rectangle.Contains(e.Location.X, e.Location.Y);
        if (this.Visible && !oldVisible) {
            this.animationOfAppearance();
        } else if (!this.Visible) {
            if (oldVisible) {
                this.animationOfDisappearance();
            }
            return;
        }

        const newHoverButtonType = this.GetCurrentHoverButton(e.Location);
        if (newHoverButtonType !== this.CurrentHoverButtonType) {
            this.CurrentHoverButtonType = newHoverButtonType;
            e.NeedRedraw = true;
        }
        return super.ProcessMouseMove(e);
    }

    ProcessMouseWheel (e: TerceraChartMouseEventArgs<WheelEvent>): boolean {
        if (e._baseEvent.ctrlKey || // Ignore if the Ctrl key is pressed.
            Math.abs(e._baseEvent.deltaX) >= Math.abs(e._baseEvent.deltaY) // Ignore if horizontal scroll dominates or it's purely horizontal.
        ) { return false; }

        const step = e.Delta / 120;
        if (step > 0) {
            this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, ChartZoomRendererButtons.ZoomIn));
        } else {
            this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, ChartZoomRendererButtons.ZoomOut));
        }
        return true;
    }

    ProcessMouseLeave (e: any): void {
        super.ProcessMouseLeave(e);
        this.CurrentHoverButtonType = ChartZoomRendererButtons.None;
        if (this.Visible) {
            this.animationOfDisappearance();
        }
    }

    Do_ZoomIn (): void {
        this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, ChartZoomRendererButtons.ZoomIn));
    }

    Do_ZoomOut (): void {
        this.chartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, ChartZoomRendererButtons.ZoomOut));
    }

    Dispose (): void {
        this.chartActionProcessor = null;
        clearInterval(this.opacityTimer);
        this.opacityTimer = null;
        super.Dispose();
    }

    GetTooltip (e: any): string | null {
        if (!this.Visible || this.CurrentHoverButtonType === ChartZoomRendererButtons.None) return null;
        let state;
        if (this.CurrentHoverButtonType === ChartZoomRendererButtons.ToBegin) {
            state = this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.ToBegin, this.CurrentHoverButtonType));
        } else {
            state = this.chartActionProcessor.GetTerceraChartActionState(TerceraChartAction.Create(TerceraChartActionEnum.Zoom, this.CurrentHoverButtonType));
        }

        if (state?.ToolTiplocKey != null) {
            return Resources.getResource(state.ToolTiplocKey);
        }
        return null;
    }

    private animationOfAppearance (): void {
        clearInterval(this.opacityTimer);
        this.opacityTimer = setInterval(() => {
            this.opacity += 0.1;
            this.chart.IsDirty(this.assignLayer);
            if (this.opacity >= 1) {
                clearInterval(this.opacityTimer);
                this.opacityTimer = null;
            }
        }, 50);
    }

    private animationOfDisappearance (): void {
        clearInterval(this.opacityTimer);
        this.opacityTimer = setInterval(() => {
            this.opacity -= 0.1;
            this.chart.IsDirty(this.assignLayer);
            if (this.opacity <= 0) {
                clearInterval(this.opacityTimer);
                this.opacityTimer = null;
            }
        }, 50);
    }
}

export enum ChartZoomRendererButtons {
    None = 0,
    ZoomIn = 2,
    ZoomOut = 4,
    ScalingByRectangle = 8,
    ToBegin = 16,
    All = 31,
    Container = 32
}
