// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectPositionMessage } from '@shared/utils/DirectMessages/DirectPositionMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class PositionMessageProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): DirectPositionMessage[] {
        const message = new DirectPositionMessage();
        // Operation Type
        const type = fieldSet.GetValue(FieldsFactory.FIELD_OPERATION_TYPE);
        message.OperationType = type;
        // Amount
        message.Amount = fieldSet.GetValue(FieldsFactory.FIELD_AMOUNT);
        // Instrument ID
        // let instrId = fieldSet.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID)
        // message.InstrumentID = instrId
        // message.Symbol = decoder.GetSymbolById(instrId)

        message.PositionId = fieldSet.GetValue(FieldsFactory.FIELD_POSITION_ID).toString();
        message.SuperPositionId = fieldSet.GetValue(FieldsFactory.FIELD_SUPER_POSITION_ID).toString();
        message.OrderWasManual = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_IS_WAS_MANUAL);
        // Open Order ID
        const fieldLastOrderId = fieldSet.GetValue(FieldsFactory.FIELD_LAST_ORDER_ID);
        message.OpenOrderId =
        fieldLastOrderId === null
            ? message.PositionId
            : fieldLastOrderId.toString();
        // Open price
        message.OpenPrice = fieldSet.GetValue(FieldsFactory.FIELD_OPEN_PRICE);
        // Open time
        message.OpenTime = fieldSet.GetValue(FieldsFactory.FIELD_DATE);
        // Close Order ID
        const fieldCloseOrderId = fieldSet.GetValue(FieldsFactory.FIELD_CLOSEORDER_ID);
        message.CloseOrderId = fieldCloseOrderId ? fieldCloseOrderId.toString() : '0';
        // Close price
        message.ClosePrice = fieldSet.GetValue(FieldsFactory.FIELD_CLOSE_PRICE);
        // Close time
        message.CloseTime = fieldSet.GetValue(FieldsFactory.FIELD_CLOSE_TIME);
        // Stop Loss Order ID
        message.StopLossOrderId = fieldSet.GetValue(FieldsFactory.FIELD_SL_ORDER_ID).toString();
        // Take profit Order ID
        message.TakeProfitOrderId = fieldSet.GetValue(FieldsFactory.FIELD_TP_ORDER_ID).toString();
        // PIN
        message.Account = fieldSet.GetValue(FieldsFactory.FIELD_ACCOUNT_ID).toString();

        // Swaps
        message.Swaps = fieldSet.GetValue(FieldsFactory.FIELD_SWAP);

        // Route
        const routeId = fieldSet.GetValue(FieldsFactory.FIELD_ROUTE_ID);
        message.Route = routeId;// decoder.GetRouteById(routeId)

        // Comment
        message.Comment = fieldSet.GetValue(FieldsFactory.FIELD_COMMENT);
        // Group ID
        message.OrderGroupId = fieldSet.GetValue(FieldsFactory.FIELD_ORDER_GROUP_ID);

        // Commission
        message.Commission = fieldSet.GetValue(FieldsFactory.FIELD_COMMISION);

        message.OptionExerciseStatus = fieldSet.GetValue(FieldsFactory.FIELD_OPTION_EXERCISE_STATUS);
        message.CorporateActionType = fieldSet.GetValue(FieldsFactory.FIELD_CORP_ACTION_TYPE);

        // master id
        const masterID = fieldSet.GetValue(FieldsFactory.FIELD_MASTER_ORDER_ID);
        // fix
        if (masterID > 0) {
            message.MasterID = masterID.toString();
        }

        message.TrailingStop = fieldSet.GetValue(FieldsFactory.FIELD_IS_TRAILING);
        message.TrailingOffset = fieldSet.GetValue(FieldsFactory.FIELD_TR_OFFSET);
        message.OpenCrossPrice = fieldSet.GetValue(FieldsFactory.FIELD_OPEN_CROSS_PRICE);
        message.InitMargin = fieldSet.GetValue(FieldsFactory.FIELD_INIT_MARGINGSIZE);
        message.MaintMargin = fieldSet.GetValue(FieldsFactory.FIELD_MAINTANCE_MARGIN);
        message.SettlementPrice = fieldSet.GetValue(FieldsFactory.FIELD_SETTLEMENT_PRICE);
        message.ProductType = fieldSet.GetValue(FieldsFactory.FIELD_PRODUCT_TYPE);

        message.InstrumentTradableID = fieldSet.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID);
        message.StartOfDayAmount = fieldSet.GetValue(FieldsFactory.FIELD_UPLOADED_AMOUNT);

        message.ProductTypeModifiedAmount = fieldSet.GetValue(FieldsFactory.FIELD_MODIFIED_AMOUNT); // 104653
        message.Leverage = fieldSet.GetValue(FieldsFactory.FIELD_LEVERAGE_SHORT);

        message.AccruedInterest = fieldSet.GetValue(FieldsFactory.FIELD_INTEREST); // 119214

        return [message];
    }
}
