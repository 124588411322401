// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum OrderActionEnum {
    Unspecified = 0,
    All = 1,
    Buy = 2,
    Sell = 3,
    Days = 4,
    GTCs = 5,
    Stops = 6,
    Limits = 7,
    ByIds = 8,
    ByInstrument = 9,
    ChangeToMarket = 10,
    ByAccount = 11,
    ByAccountAndInstrument = 12,
    ModifyOrder = 13,
    LinkAsOCO = 14,
    UnLinkAsOCO = 15,
    ShowToolbar = 16
}
