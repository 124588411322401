// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Account } from '../cache/Account';
import { type Asset } from '../cache/Asset';
import { EventEmitter } from 'events';

class _AccountWidgetAssetsController {
    private _Account: Account = null;
    private _AssetsArray: Asset[] = [];
    public _SelectedAsset: Asset = null;

    private readonly _eventEmitter: EventEmitter = new EventEmitter();

    set Account (account: Account) {
        this._Account = account;
        this.PopulateAssets(account);
    }

    PopulateAssets (account: Account): void {
        this._AssetsArray = [];
        for (const key in account.assetBalances) { this._AssetsArray.push(account.assetBalances[key].Asset); }
    }

    get AssetsArray (): Asset[] {
        return this._AssetsArray;
    }

    set SelectedAsset (asset: Asset) {
        this._SelectedAsset = asset;
        this.OnOnAssetChangedEmit(asset);
    }

    get SelectedAsset (): Asset {
        return this._SelectedAsset;
    }

    public subscribeOnAssetChanged (callback: () => void): void {
        this._eventEmitter.on('OnAssetChanged', callback);
    }

    public unsubscribeOnAssetChanged (callback: () => void): void {
        this._eventEmitter.off('OnAssetChanged', callback);
    }

    public OnOnAssetChangedEmit (value: Asset): void {
        this._eventEmitter.emit('OnAssetChanged', value);
    }
}

export const AccountWidgetAssetsController = new _AccountWidgetAssetsController();
