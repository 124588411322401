// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type Account } from '../../cache/Account';
import { type Instrument } from '../../cache/Instrument';
import { type OrderType } from '../../../Utils/Trading/OrderType';
import { type ProductType } from '../../../Utils/Instruments/ProductType';

export class MarginInfoParameters {
    account: Account;
    instrument: Instrument;
    orderType: OrderType;

    amountLots: number;
    cash: number;
    isCashMode: boolean;

    limitPrice: number;
    stopPrice: number;
    isLong: boolean;

    productType: ProductType;
    leverageValue: any;
}
