// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type FibonacciEllipseDataCacheTool } from '../../Commons/cache/Tools/FibonacciEllipseDataCacheTool';
import { ToolView } from './ToolView';
import { ToolViewUtils } from './Utils/ToolViewUtils';

export class FibonacciEllipseToolView extends ToolView<FibonacciEllipseDataCacheTool> {
    /* TODO.
public IsSelectCheck = function (x, y)
{
    var screenPoints = this.screenPoints;

    var scrP0 = screenPoints[0];
    var scrP0X = scrP0[0];
    var scrP0Y = scrP0[1];

    var scrP1 = screenPoints[1];
    var scrP1X = scrP1[0];
    var scrP1Y = scrP1[1];

    var fibLevel = this.dataCacheTool.fibLevel;
    var fibLvl = fibLevel[fibLevel.length - 1];

    var h = Math.abs(scrP0X - ((scrP0X - scrP1X) * fibLvl / 100));
    var k = Math.abs(scrP0Y - ((scrP0Y - scrP1Y) * fibLvl / 100));
    var w = Math.abs(((scrP0X - scrP1X) * fibLvl / 100) * 2);
    var h = Math.abs(((scrP0Y - scrP1Y) * fibLvl / 100) * 2);

    var rx = w / 2;
    var ry = h / 2;

    var xRes = Math.pow((x - h), 2) / Math.pow(rx, 2);
    var yRes = Math.pow((y - k), 2) / Math.pow(ry, 2);

    return xRes + yRes <= 1;
};
*/

    public DrawFibEllipse (gr, ww, highlight): void {
        const dataCacheTool = this.dataCacheTool;
        const fibLevel = dataCacheTool.fibLevel;
        const points = dataCacheTool.Points;
        const pen = highlight ? dataCacheTool.PenHighlight : dataCacheTool.Pen;
        const font = dataCacheTool.font;
        const fontColor = dataCacheTool.FontColor;
        const instrument = dataCacheTool.Instrument;

        const screenPoints = this.screenPoints;

        const scrP0 = screenPoints[0];
        const scrP0X = scrP0[0];
        const scrP0Y = scrP0[1];

        const scrP1 = screenPoints[1];
        const scrP1X = scrP1[0];
        const scrP1Y = scrP1[1];

        const fibLevel_len = fibLevel.length;
        for (let i = 0; i < fibLevel_len; i++) {
            const fibLvl = fibLevel[i];
            if (isNullOrUndefined(fibLvl)) continue;

            const x = scrP0X - ((scrP0X - scrP1X) * fibLvl / 100);
            const y = scrP0Y - ((scrP0Y - scrP1Y) * fibLvl / 100);
            const w = ((scrP0X - scrP1X) * fibLvl / 100) * 2;
            const h = ((scrP0Y - scrP1Y) * fibLvl / 100) * 2;

            gr.DrawEllipse(pen, x, y, w, h);

            if (!highlight && (Math.abs(scrP0X - scrP1X) > 5 && Math.abs(scrP0Y - scrP1Y) > 5)) {
                ToolViewUtils.DrawFibonacciText(gr, ww,
                    scrP0X,
                    scrP0Y - ((scrP0Y - scrP1Y) * fibLvl / 100),
                    ToolViewUtils.FormatFibonacciText(fibLvl, points, instrument),
                    font, fontColor);
            }
        }
    }

    public override Draw (gr, ww, param): void {
        this.DrawFibEllipse(gr, ww, false);
        super.Draw(gr, ww, param);
    }

    public override DrawSelection (gr, ww): void {
        this.DrawFibEllipse(gr, ww, true);
        super.DrawSelection(gr, ww);
    }
}
