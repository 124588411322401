// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
'use strict';
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';

export class SourceGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.SOURCE_GROUP, null, pfixFieldSet);
    }

    public getSource (): string {
        return this.GetValue(FieldsFactory.FIELD_SOURCE) || '';
    }

    public getSourceId (): number {
        return this.GetValue(FieldsFactory.FIELD_SOURCE_ID) || 0;
    }

    public getExternalSourceId (): string {
        return this.GetValue(FieldsFactory.FIELD_SOURCE_EXT_ID) || '';
    }
}
