// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraButton } from '../TerceraButton';
import { AccountWidgetMainHeaderButtonTemplate } from '../../../templates';
// import { TerceraButton } from './TerceraButton';

export class AccountWidgetMainHeaderButton extends TerceraButton {
    constructor () {
        super();
    }

    getType (): string { return 'AccountWidgetMainHeaderButton'; };
}

TerceraButton.extendWith(AccountWidgetMainHeaderButton, {
    template: AccountWidgetMainHeaderButtonTemplate
});
