// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class RowPropertyClass {
    public Id = '';
    public Group = '';
    public SortIndex = 0;
    public LocalizationKey = '';
    public Hidden = false;
    public visible = true;
    public tooltipKey = '';
    public arrayList = [];

    public Properties (): any[] {
        return this.arrayList;
    }

    public callBack (v): void {
        this.arrayList = v;
    }

    public Apply (sourceItem): void {
        this.visible = sourceItem.visible;
        this.arrayList = sourceItem.Properties;
    }

    public ToString (): string {
        return this.Group + '.' + this.LocalizationKey;
    }
}
