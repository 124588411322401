// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { Message } from '../../../../Utils/DirectMessages/Message';
import { PFixMessage } from './PFixMessage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class SOAPReqMessage extends PFixMessage {
    constructor (fieldSet?: PFixFieldSet) {
        super(Message.CODE_PFIX_SOAP_REQ, fieldSet);
    }

    public getName (): string {
        return this.getFieldValue(FieldsFactory.FIELD_NAME);
    }

    public setName (name: string): void {
        this.setFieldValue(FieldsFactory.FIELD_NAME, name);
    }
}
