// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { VolumeFormatter } from '../../Utils/Volume/VolumeFormatter';
import { QuickTableColumnType, QuickTableEditingInfo } from '../elements/QuickTable/QuickTableMisc';
import { BaseItem, ColumnData } from './BaseItem';
import { ColumnParams } from './ColumnParams';
import { CustomEvent } from '../../Utils/CustomEvents';
import { ThemeManager } from '../misc/ThemeManager';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';
import { DynProperty } from '../../Commons/DynProperty';
import { MathUtils } from '../../Utils/MathUtils';
import { QuoteValid } from '../../Utils/Quotes/QuoteValid';
import { InstrumentUtils } from '../../Utils/Instruments/InstrumentUtils';
import { DataCache } from '../../Commons/DataCache';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { type SessionSettingsType } from '../../Commons/SessionSettings';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';
import { WDSettings } from '../settings/WDGeneralSettings';

export class BaseInformer extends BaseItem {
    public static num = 0;

    public delayedFireUpdate: boolean;
    public dataCache: any;
    public numId: string;
    public customColumnValues: any = {};
    public customColumnFormattedValues: any = {};
    public instrument: Instrument | null = null;
    public ContractSize: number = 0;
    public Comment: string = '';
    public QuickTableEditingInfoMapChanged: CustomEvent;

    protected _parent: any;

    constructor (dataCache, sessionSettings: SessionSettingsType, delayedFireUpdate: boolean) {
        super(sessionSettings);

        this.delayedFireUpdate = delayedFireUpdate || false;
        this.dataCache = dataCache;
        this.numId = (BaseInformer.num++).toString();
    }

    public override InstrumentName (saveMode?): string {
        return this.instrument.GetInteriorID(saveMode);
    }

    public override ColumnCount (): number {
        return BaseInformer.columnsParams.length;
    }

    public override getColumnValue (column: number): any {
        const customColumnValues = this.customColumnValues;
        if (column >= BaseInformer.columnsParams.length && customColumnValues.hasOwnProperty(column)) {
            return customColumnValues[column];
        }

        const dc = this.dataCache;
        const instrument = this.instrument;
        const Level1 = instrument.Level1;
        const insDayInfo = this.instrument.InstrumentDayInfo;
        const sess = this.sessionSettings;
        const ShowLots = WDSettings.isQuantityInLots;
        const account = this.GetCurrentAccount();

        if (!Level1) { return 0; }

        switch (column) {
        case 0:
            return instrument.DisplayName();

        case 3:
            return Level1.GetLastPrice(account);

        case 4:
            return Level1.GetLastSize(ShowLots);

        case 5:
        case 6:
            const lastMessageTime: Date = Level1.getLastMessageTime;
            if (isNullOrUndefined(lastMessageTime)) return null;
            if (lastMessageTime.getTime() === 0) return null;
            return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(lastMessageTime);

        case 8:
            return Level1.GetBid(account);

        case 9:
            return Level1.getBidSize(ShowLots);

        case 10:
            return Level1.GetAsk(account);

        case 11:
            return Level1.getAskSize(ShowLots);

        case 12:
            return Level1.GetChange(account);

        case 13:
            return Level1.GetChangePercent(account);

        case 14:
            return insDayInfo.getOpen(account);

        case 15:
            return insDayInfo.getHigh(account);

        case 16:
            return insDayInfo.getLow(account);

        case 17:
            return insDayInfo.getPrevClose(account);

        case 18:
            return instrument.LotSize;

        case 19:
            if (instrument !== null && instrument.ExpDateReal.getFullYear() > 2000) {
                return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(instrument.ExpDateReal);
            } else {
                return DateTimeUtils._ZeroTime;
            }

        case 20:
            return Level1.GetSpread(account);

        case 21:
            return Level1.GetSpreadPercent(account);

        case 22:
            return instrument.DescriptionValue();

        case 23:
            return instrument.InstrType;

        case 24:
            return 0;

        case 25:
            return Level1.GetLastUpdate();

        case 26:
            return Level1.GetIndicativeAuctionPrice();

        case 27:
            return instrument.ExchangeSessionName;

        case 28:
            return InstrumentUtils.GetTradingStatus(instrument);

        case 31:
            return Level1.GetTicks();

        case 40:
            return instrument.OpenInterest > 0 ? instrument.OpenInterest : 0;

        case 41:
            return instrument.SettlementPrice;

        case 42:
            return instrument.PrevSettlementPrice;

        case 43:
            return insDayInfo.getClose(account);

        case 44:
            return instrument != null ? instrument.MarketDataExchange : Resources.getResource('ExchangeId.None');

        case 45:
            return insDayInfo.TotalVolume();

        case 46:
            return Level1.GetIndicativeAuctionVolume(ShowLots);

        case 47:
            return this.Comment || '';

        case 48:
            return instrument.High13Week === null ? NaN : instrument.High13Week;

        case 49:
            return instrument.Low13Week === null ? NaN : instrument.Low13Week;

        case 50:
            return instrument.High26Week === null ? NaN : instrument.High26Week;

        case 51:
            return instrument.Low26Week === null ? NaN : instrument.Low26Week;

        case 52:
            return instrument.FiftyTwoWeekHighPrice === null ? NaN : instrument.FiftyTwoWeekHighPrice;

        case 53:
            return instrument.FiftyTwoWeekLowPrice === null ? NaN : instrument.FiftyTwoWeekLowPrice;

            // ------------------------------------------------------------------------------------

        case 54:
            return MathUtils.ProcessNaN(Level1.getReferencePrice);

        case 55:
            return MathUtils.ProcessNaN(Level1.getTradedValue);

        case 56:
            return MathUtils.ProcessNaN(Level1.getOffExchangeVolume(ShowLots));

        case 57:
            return MathUtils.ProcessNaN(Level1.getOffExchangeValue);

        case 58:
            return Level1.getNormalMarketSize;

        case 59:
            return MathUtils.ProcessNaN(instrument.Limits.GetHighLimitPriceByMeasure());

        case 60:
            return MathUtils.ProcessNaN(instrument.Limits.GetLowLimitPriceByMeasure());

        case 61:
            return Level1.CbAuctionStart;

        case 62:
            return Level1.strAuctionEndTime;

        case 63:
            return Level1.strRemainingQty;

        case 64:
            return Level1.strRemainingQtySide;

        case 65:
            return instrument.GetInterTraderButtonStatus(false);

        case 66:
            return instrument.GetInterTraderButtonStatus(true);

        case 68:
            return Level1.GetAvgTradedPrice();

        case 69:
            return Level1.GetTotalBuyQty();

        case 70:
            return Level1.GetTotalSellQty();

        case 71:
            return Level1.GetHighOpenInterest();

        case 72:
            return Level1.GetLowOpenInterest();

        case 73:
            return Level1.GetInitiatorPrice();

        case 74:
            return Level1.GetInitiatorQty();

        case 75:
            return Level1.GetInitiatorType();

        case 76:
            return Level1.GetLastTradedTime();

        case 77:
            return Level1.GetAuctionNumber();

        case 78:
            return Level1.GetAuctionStatus();

        case 79:
            return Level1.GetFiftyTwoWeekHighPrice();

        case 80:
            return Level1.GetFiftyTwoWeekLowPrice();

        case 81:
            return Level1.GetNSEValue();

        case 82:
            return Level1.GetDPRValue();

        case 83:
            return instrument.GetExtToken();

        case 84:
            return instrument.GetExtInsType();

        case 85:
            return instrument.GetExtMarketType();

        case 86:
            return instrument.GetExtSymbol();

        case 87:
            return instrument.GetExtSeries();

        case 88:
            return instrument != null ? instrument.TradingExchange : Resources.getResource('ExchangeId.None');

        case 89:
            return Level1.GetBidSourceName();

        case 90:
            return Level1.GetAskSourceName();

        case 91:
            return Level1.GetLastSourceName();

        case 92:
            return Level1.GetLPP();

        case 93:
            return Level1.GetSpreadedFundingRateValue();
        case 94:
            return Level1.GetNextFundingSettlement();

        default:
            return 0;
        }
    }

    public override GetCurrentAccount (): Account {
        const WL = this._parent;
        return WL != null ? WL.get('account') : DataCache.getPrimaryAccount();
    }

    // TODO.
    public override getColumnData (column: number): ColumnData {
        const value = this.getColumnValue(column);
        let formattedValue = '';
        let groupByValue = '';
        const instrument = this.instrument;
        const Level1 = instrument.Level1;
        const sess = this.sessionSettings;
        const ShowLots = WDSettings.isQuantityInLots;
        const account = this.GetCurrentAccount();
        if (!Level1) {
            return new ColumnData(value, Resources.getResource('panel.watchlist.NA'));
        }

        switch (column) {
        case 0:
            formattedValue = value ? value.toString() : '';
            break;
        case 3:
            formattedValue = Level1.getFormatPrice(value);
            break;
        // TODO.
        case 4:
            formattedValue = Level1.StrLastSize(ShowLots);
            break;
        // TODO.
        case 5:
            if (isNullOrUndefined(value)) {
                formattedValue = Resources.getResource('panel.watchlist.NA');
            } else {
                formattedValue = DateTimeUtils.FormatToDate(value);
            }
            break;
        // TODO.
        case 6:
            if (isNullOrUndefined(value)) {
                formattedValue = Resources.getResource('panel.watchlist.NA');
            } else {
                formattedValue = DateTimeUtils.FormatToTime(value);
            }
            break;
        case 8:
            formattedValue = Level1.getFormatPrice(value);
            break;
        case 10:
            formattedValue = Level1.getFormatPrice(value);
            break;
        case 12:
            formattedValue = Level1.strChange(account);
            break;
        case 13:
            formattedValue = Level1.strChangePercent(account);
            break;
        case 14:
            formattedValue = Level1.getFormatPrice(value);
            break;
        case 15:
            formattedValue = Level1.getFormatPrice(value);
            break;
        case 16:
            formattedValue = Level1.getFormatPrice(value);
            break;
        case 17:
            formattedValue = Level1.getFormatPrice(value);
            break;
        case 19:
            if (value && value.getTime() > 0) {
                formattedValue = DateTimeUtils.FormatToDate(value);
            } else {
                formattedValue = '';
            }
            break;
        case 20:
            formattedValue = Level1.strSpread(account);
            break;

        case 21:
            formattedValue =
                instrument && !isNaN(value)
                    ? Level1.strSpreadPercent(account, value)
                    : Resources.getResource('panel.watchlist.NA');
            break;
        case 23:
            if (instrument) {
                formattedValue = instrument.getTypeString();
                groupByValue = InstrumentUtils.getPutCallOptionsTypeStringLocalized(instrument); // #114165
            }
            break;
        case 26:
            formattedValue = Level1.strIndicativeAuctionPrice();
            break;
        case 40:
            formattedValue = VolumeFormatter.getVolumeFormat(value);
            break;
        case 41:
            formattedValue = Level1.strSettlementPrice();
            break;
        case 42:
            formattedValue = Level1.strPrevSettlementPrice();
            break;
        case 45:
            formattedValue = Level1.strVolume(false);
            break;
        case 46:
            formattedValue = Level1.strIndicativeAuctionVolume(ShowLots);
            break;
        case 54:
            formattedValue = Level1.strReferencePrice;
            break;
        case 55:
            formattedValue = Level1.strTradedValue;
            break;
        case 56:
            formattedValue = Level1.strOffExchangeVolume(ShowLots);
            break;
        case 57:
            formattedValue = Level1.strOffExchangeValue;
            break;
        case 58:
            formattedValue = Level1.getNormalMarketSize;
            break;
        case 59:
            formattedValue = instrument.Limits.HighLimitPriceByMeasureString(sess);
            break;
        case 60:
            formattedValue = instrument.Limits.LowLimitPriceByMeasureString(sess);
            break;
        case 61:
            formattedValue = Level1.strCbAuctionStart();
            break;
        case 65:
            formattedValue = value ? value.status : null;
            break;

        case 66:
            formattedValue = value ? value.status : null;
            break;

            // nanFields not -1

        case 43:
        case 48:
        case 49:
        case 50:
        case 51:
        case 52:
        case 53:
        case 68:
        case 71:
        case 72:
        case 73:
        case 79:
        case 80:
        case 9:
        case 11:
            formattedValue = (isNaN(value) || value === null) ? Resources.getResource('general.N_A') : value.toString();
            break;

        case 81:
            formattedValue = Level1 != null ? Level1.GetNSEFormatted(/* ShowLots */false, Resources.getResource('panel.watchlist.NA')) : Resources.getResource('panel.watchlist.NA');
            break;

        case 93:
            formattedValue = Level1 != null ? Level1.GetSpreadedFundingRateValueFormatted(account, Resources.getResource('panel.watchlist.NA')) : Resources.getResource('panel.watchlist.NA');
            break;
        case 94:
            formattedValue = Level1 != null ? Level1.StrNextFundingSettlement(Resources.getResource('panel.watchlist.NA')) : Resources.getResource('panel.watchlist.NA');
            break;

        default:
            // TODO.
            formattedValue =
                value === null ||
                    value === '' ||
                    value === undefined ||
                    value === -1
                    ? Resources.getResource('general.N_A')
                    : value.toString();
        }

        // TODO. Refactor. Ugly.
        if (!formattedValue && formattedValue !== '') {
            formattedValue = Resources.getResource('general.N_A');
        }

        return new ColumnData(value, formattedValue, groupByValue);
    }

    public override GetColumnParams (column: number): ColumnParams {
        const par = BaseInformer.columnsParams[column];
        par.MenuIndex = this.GetMenuIndex(par.HeaderKey);
        if (Resources.isHidden(par.HeaderKey)) {
            par.Hidden = true;
        }

        if (column === 67) {
            const iTChartKey = Resources.getResourceLang('ITAdvancedChart.AllowKey', 'en');
            const iTChartValue = Resources.getResourceLang('ITAdvancedChart.AllowValue', 'en');

            let showItChart = DataCache.ExternalLinksCache.getITChartAdvancedExtLink() !== null;// #87796
            if (showItChart && iTChartKey && iTChartValue) {
                const acc = DataCache.MainAccountNew;
                if (acc !== null) {
                    const us = acc.User;
                    if (us !== null) {
                        const usAF = us.UserAdditionalInfo;
                        if (usAF !== null) {
                            const val = usAF[iTChartKey];
                            if (val) {
                                showItChart = val === iTChartValue;
                            } else {
                                showItChart = false;
                            }
                        } else {
                            showItChart = false;
                        }
                    } else {
                        showItChart = false;
                    }
                } else {
                    showItChart = false;
                }
            }

            par.Hidden = !showItChart;
            par.Visible = showItChart;
        }

        return par;
    }

    public override GetCurrentInstrument (): Instrument {
        return this.instrument;
    }

    public changeSymbol (symbol): void {
        const dc = this.dataCache;
        const qc = dc.FQuoteCache;
        if (this.instrument) {
            qc.removeListener(this.instrument, this, HistoryType.QUOTE_LEVEL1);
            qc.removeListener(this.instrument, this, HistoryType.QUOTE_TRADES);

            this.instrument.Level1.OnCbAuctionStartChanged.UnSubscribe(this.OnLastQuote, this);
            this.instrument.ExchangeSessionNameUpdated.UnSubscribe(this.OnExchangeSessionNameUpdated, this);
            this.instrument.RiskSettingsUpdated.UnSubscribe(this.OnInsRiskSettingsUpdated, this);

            this.instrument = null;
        }

        this.instrument = dc.getInstrumentByName(symbol);
        if (this.instrument) {
            this.ContractSize = this.instrument.LotSize;

            qc.addListener(this.instrument, this, HistoryType.QUOTE_LEVEL1);
            qc.addListener(this.instrument, this, HistoryType.QUOTE_TRADES);

            this.instrument.Level1.OnCbAuctionStartChanged.Subscribe(this.OnLastQuote, this);
            this.instrument.ExchangeSessionNameUpdated.Subscribe(this.OnExchangeSessionNameUpdated, this);
            this.instrument.RiskSettingsUpdated.Subscribe(this.OnInsRiskSettingsUpdated, this);
        } else {
            this.ContractSize = 0;
        }
    }

    public newQuote (quote): void {
        if (!quote) { return; }

        this.fireUpdate();
    }

    public OnLastQuote (): void {
        if (!isNullOrUndefined(this.instrument)) {
            const message = this.instrument.GetLastQuote(QuoteValid.Last);
            if (message) {
                this.newQuote(message);
            }
        }
    }

    public OnExchangeSessionNameUpdated (): void {
        this.fireUpdate();
    }

    public override Dispose (): void {
        this.changeSymbol(null);

        const p = this._parent;
        if (p) {
            this.QuickTableEditingInfoMapChanged.UnSubscribe(p.OnQuickTableEditingInfoMapChanged, p);
        }
    }

    public OnInsRiskSettingsUpdated (): void {
        const ins = this.GetCurrentInstrument();
        if (isNullOrUndefined(ins)) { return; }

        const symbolColumnEditingInfo = ins.HasDelay() ? new QuickTableEditingInfo(DynProperty.DELAYED_PICTURE_RIGHT_AND_TEXT) : null;
        const hasChanged = this.QuickTableEditingInfoMap[0] != symbolColumnEditingInfo;

        if (hasChanged) {
            this.QuickTableEditingInfoMap[0] = symbolColumnEditingInfo;
            this.QuickTableEditingInfoMapChanged.Raise(this.ItemId, symbolColumnEditingInfo); // обновился айтем, но нужная инфа не в нем, а в ячейке, потому подписали WatchList на обновление айтема и из WL организуем обновление полей у cell и перерисовку
        }
    }

    public static readonly columnsParams = [
    /* 0 */ new ColumnParams('panel.watchlist.Instrument', 100, QuickTableColumnType.COL_DEFAULT, true, true, false),
        new ColumnParams('panel.watchlist.Company', 110, QuickTableColumnType.COL_DEFAULT, false, false, false, true),
        new ColumnParams('panel.watchlist.Market', 40, QuickTableColumnType.COL_DEFAULT, false, false, true, true),
        new ColumnParams('panel.watchlist.Last', 60, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, true, false/*, beginGroupFlag: true, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.LastSize', 60, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        /* 5 */ new ColumnParams('panel.watchlist.Date', 60, QuickTableColumnType.COL_DATE_SORT, false, false, false),
        new ColumnParams('panel.watchlist.Time', 60, QuickTableColumnType.COL_TIME_SORT, false, false, false),
        new ColumnParams('panel.watchlist.Main Volume', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false, true/*, beginGroupFlag: true */),
        new ColumnParams('panel.watchlist.Bid', 60, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.BidSize', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, true, false, false/*, emptyValue: -1.0 */),
        /* 10 */ new ColumnParams('panel.watchlist.Ask', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.AskSize', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, true, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.Change', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.ChangePr', 70, QuickTableColumnType.COL_MORELESS_NUMERIC, false, true, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.Open', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        /* 15 */ new ColumnParams('panel.watchlist.High', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.Low', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.PrevClose', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.ContractSize', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.ExpDate', 70, QuickTableColumnType.COL_DATE_SORT, false, false, false),
        /* 20 */ new ColumnParams('panel.watchlist.Spread', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.SpreadPr', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.Descr', 100, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.Type', 70, QuickTableColumnType.COL_DEFAULT, false, false, true),
        new ColumnParams('panel.watchlist.MarketCap', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false/*, hidden: true */),
        /* 25 */ new ColumnParams('panel.watchlist.noQuotes', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.IndicativeAuctionPrice', 70, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false/*, beginGroupFlag: true, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.CurrentSession', 70, QuickTableColumnType.COL_DEFAULT, false, false, false), // 27
        new ColumnParams('InstrumentDetailsPanel.TradingStatus', 70, QuickTableColumnType.COL_DEFAULT, false, false, false), // 28
        new ColumnParams('panel.watchlist.OpenPreMarket', 70, QuickTableColumnType.COL_DEFAULT, false, false, false, true),
        /* 30 */ new ColumnParams('panel.watchlist.ClosePostMarket', 70, QuickTableColumnType.COL_DEFAULT, false, false, false, true),
        new ColumnParams('panel.watchlist.Ticks', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.TicksPreMarket', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        new ColumnParams('panel.watchlist.TicksPostMarket', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        new ColumnParams('panel.watchlist.VolumePreMarket', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        /* 35 */ new ColumnParams('panel.watchlist.VolumePostMarket', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        new ColumnParams('panel.watchlist.PreMarketChange%', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        new ColumnParams('panel.watchlist.PreMarketChange', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        new ColumnParams('panel.watchlist.ChangeFromOpen%', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        new ColumnParams('panel.watchlist.ChangeFromOpen', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        /* 40 */ new ColumnParams('panel.watchlist.OpenInterest', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.SettlementPrice', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.PrevSettlementPrice', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.Close', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false, true),
        new ColumnParams('panel.watchlist.ExchangeMarketData', 70, QuickTableColumnType.COL_DEFAULT, false, false),
        /* 45 */ new ColumnParams('panel.watchlist.Volume', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, true, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.Match Volume', 60, QuickTableColumnType.COL_SIMPLE_NUMERIC, true, false, false/*, emptyValue: -1.0 */),
        new ColumnParams('panel.watchlist.Comment', 60, QuickTableColumnType.COL_DEFAULT, true, false, false),
        new ColumnParams('panel.watchlist.High13', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.Low13', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        /* 50 */ new ColumnParams('panel.watchlist.High26', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.Low26', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.High52', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.Low52', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.ReferencePrice', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        /* 55 */ new ColumnParams('panel.watchlist.TradedValue', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.OffExchangeVolume', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.OffExchangeValue', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.NormalMarketSize', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.Limit.High', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        /* 60 */ new ColumnParams('panel.watchlist.Limit.Low', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.CBAuctionStart', 70, QuickTableColumnType.COL_DATE_SORT, false, false, false),
        new ColumnParams('panel.watchlist.AuctionEndTime', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.RemainingQty', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.RemainingQtySide', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        /* 65 */ new ColumnParams('panel.watchlist.Order', 42, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.Trade', 42, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.ITChartAdvanced', 42, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams('panel.watchlist.AvgTradedPrice', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.TotalBuyQuantity', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        /* 70 */ new ColumnParams('panel.watchlist.TotalSellQuantity', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.HighOpenInterest', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.LowOpenInterest', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.InitiatorPrice', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.InitiatorQuantity', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        /* 75 */ new ColumnParams('panel.watchlist.InitiatorType', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.LastTradedTime', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.AuctionNumber', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.AuctionStatus', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.FiftyTwoWeekHighPrice', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        /* 80 */ new ColumnParams('panel.watchlist.FiftyTwoWeekLowPrice', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.NSEValue', 70, QuickTableColumnType.COL_UPDOWN_NUMERIC, false, false, false),
        new ColumnParams('panel.watchlist.DPR', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.Code', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.InstrumentType', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        /* 85 */ new ColumnParams('panel.watchlist.MarketType', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.Symbol', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.Series', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.ExchangeTrading', 70, QuickTableColumnType.COL_DEFAULT, false, false),
        new ColumnParams('panel.watchlist.BidSource', 70, QuickTableColumnType.COL_DEFAULT, false, false),
        /* 90 */ new ColumnParams('panel.watchlist.AskSource', 70, QuickTableColumnType.COL_DEFAULT, false, false),
        new ColumnParams('panel.watchlist.LastSource', 70, QuickTableColumnType.COL_DEFAULT, false, false),
        new ColumnParams('panel.watchlist.LPP', 70, QuickTableColumnType.COL_DEFAULT, false, false),
        new ColumnParams('panel.watchlist.CurrentFundingRate', 70, QuickTableColumnType.COL_DEFAULT, false, false, false),
        new ColumnParams('panel.watchlist.NextFundingSettlement', 70, QuickTableColumnType.COL_DEFAULT, false, false, false)
    ];

    public override NeedSortMenu (): boolean {
        return true;
    }

    public GetMenuIndex (headerKey: string): number {
        if (BaseInformer.ListMenuIndex.Contains(headerKey)) {
            return BaseInformer.ListMenuIndex.indexOf(headerKey);
        } else {
            return 1000;
        }
    }

    public static readonly ListMenuIndex =
        [
        //
            'panel.watchlist.Instrument',
            'panel.watchlist.Descr',
            'panel.watchlist.Type',
            'panel.watchlist.ExchangeMarketData',
            'panel.watchlist.ExchangeTrading',
            'panel.watchlist.ContractSize',
            'panel.watchlist.ExpDate',
            'panel.watchlist.noQuotes',
            'panel.watchlist.Date',
            'panel.watchlist.Time',
            'panel.watchlist.Last',
            'panel.watchlist.LastSize',
            'panel.watchlist.LastSource',
            'panel.watchlist.Bid',
            'panel.watchlist.BidSize',
            'panel.watchlist.BidSource',
            'panel.watchlist.Ask',
            'panel.watchlist.AskSize',
            'panel.watchlist.AskSource',
            'panel.watchlist.Spread',
            'panel.watchlist.SpreadPr',

            'panel.watchlist.Main Volume',
            'panel.watchlist.Change',
            'panel.watchlist.ChangePr',
            'panel.watchlist.Open',
            'panel.watchlist.High',
            'panel.watchlist.Low',
            'panel.watchlist.PrevClose',
            'panel.watchlist.OpenInterest',
            'panel.watchlist.Ticks',
            'panel.watchlist.Close',

            'panel.watchlist.IndicativeAuctionPrice',
            'panel.watchlist.Match Volume',
            'panel.watchlist.CurrentSession',
            'InstrumentDetailsPanel.TradingStatus',
            'panel.watchlist.Volume',
            'panel.watchlist.OpenPreMarket',
            'panel.watchlist.ClosePostMarket',
            'panel.watchlist.TicksPreMarket',
            'panel.watchlist.TicksPostMarket',
            'panel.watchlist.VolumePreMarket',
            'panel.watchlist.VolumePostMarket',
            'panel.watchlist.PreMarketChange%',
            'panel.watchlist.PreMarketChange',
            'panel.watchlist.ChangeFromOpen%',
            'panel.watchlist.ChangeFromOpen',
            'panel.watchlist.SettlementPrice',

            'panel.watchlist.PrevSettlementPrice',
            'panel.watchlist.Company',
            'panel.watchlist.Market',
            'panel.watchlist.MarketCap',

            'panel.watchlist.TradedValue',
            'panel.watchlist.OffExchangeVolume',
            'panel.watchlist.OffExchangeValue',
            'panel.watchlist.NormalMarketSize',
            'panel.watchlist.Limit.High',
            'panel.watchlist.Limit.Low',
            'panel.watchlist.CBAuctionStart',

            'panel.watchlist.AvgTradedPrice',
            'panel.watchlist.TotalBuyQuantity',
            'panel.watchlist.TotalSellQuantity',
            'panel.watchlist.HighOpenInterest',
            'panel.watchlist.LowOpenInterest',
            'panel.watchlist.InitiatorPrice',
            'panel.watchlist.InitiatorQuantity',
            'panel.watchlist.InitiatorType',
            'panel.watchlist.LastTradedTime',
            'panel.watchlist.AuctionNumber',
            'panel.watchlist.AuctionStatus',
            'panel.watchlist.FiftyTwoWeekHighPrice',
            'panel.watchlist.FiftyTwoWeekLowPrice',
            'panel.watchlist.NSEValue',
            'panel.watchlist.DPR',
            'panel.watchlist.Code',
            'panel.watchlist.InstrumentType',
            'panel.watchlist.MarketType',
            'panel.watchlist.Symbol',
            'panel.watchlist.Series'
        ];
}

export class Infor extends BaseInformer {
    constructor (symbol, dataCache, sessionSettings: SessionSettingsType, delayedFireUpdate: boolean, parent) {
        super(dataCache, sessionSettings, delayedFireUpdate);

        this.itemId = symbol;
        this.changeSymbol(symbol);

        const ins = this.GetCurrentInstrument();
        const isDelayed = ins && ins.HasDelay();

        this.QuickTableEditingInfoMap[0] = isDelayed ? new QuickTableEditingInfo(DynProperty.DELAYED_PICTURE_RIGHT_AND_TEXT) : null;
        this.QuickTableEditingInfoMap[65] = new QuickTableEditingInfo(DynProperty.ORDER_BUTTON);
        this.QuickTableEditingInfoMap[66] = new QuickTableEditingInfo(DynProperty.TRADE_BUTTON);
        this.QuickTableEditingInfoMap[67] = new QuickTableEditingInfo(DynProperty.ITCHART_ADVANCED);

        this.CellForeColorMap[0] = ThemeManager.CurrentTheme.TableGoldColor;// "#EDC234";

        this.QuickTableEditingInfoMapChanged = new CustomEvent(); // Raise произойдет когда у инструмента соотв. данному айтему обновятся RiskSettings, и в частности изменится QuoteDelay -> надо обновить QuickTableEditingInfo уже не у айтема (не только у него), а (главное обновить) у ячейки (см. public override OnInsRiskSettingsUpdated
        if (parent) {
            this._parent = parent; // in order to make UnSubscribe in item.Dispose(), we save ref to WatchList-caller - owner of this item here
            this.QuickTableEditingInfoMapChanged.Subscribe(parent.OnQuickTableEditingInfoMapChanged, parent);
        }
    }
}
