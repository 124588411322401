// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectUserGroup extends DirectMessageBaseClass {
    public superGroupId: number | null = null;
    public groupId: number | null = null;
    public groupName: string | null = null;

    constructor () {
        super(Message.CODE_USER_GROUP);
    }

    public get GroupName (): string | null {
        return this.groupName;
    }

    public set GroupName (value) {
        this.groupName = value;
    }

    public get GroupId (): number | null {
        return this.groupId;
    }

    public set GroupId (value) {
        this.groupId = value;
    }

    public get SuperGroupId (): number | null {
        return this.superGroupId;
    }

    public set SuperGroupId (value) {
        this.superGroupId = value;
    }
}
