// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type IObserver } from './IObserver';
import { EventEmitter } from 'events';
import { DataCache } from '../../DataCache';
import { type BaseExtLink } from '../../cache/ExternalLink/BaseExtLink';

export class ExternalLinksObserver implements IObserver {
    private readonly _eventEmitter: EventEmitter;

    constructor () {
        this._eventEmitter = new EventEmitter();
    }

    // subscribe
    public subscribeToAdd (callback: (businessObject: BaseExtLink) => void): void {
        this._eventEmitter.on('add', callback);
    }

    public subscribeToUpdate (callback: (businessObject: BaseExtLink) => void): void {
        this._eventEmitter.on('update', callback);
    }

    public subscribeToRemove (callback: (businessObject: BaseExtLink) => void): void {
        this._eventEmitter.on('remove', callback);
    }

    public subscribeToYtUpdate (callback: (businessObject: BaseExtLink) => void): void {
        this._eventEmitter.on('updateYt', callback);
    }

    // unsubscribe
    public unsubscribeToAdd (callback: (businessObject: BaseExtLink) => void): void {
        this._eventEmitter.off('add', callback);
    }

    public unsubscribeToUpdate (callback: (businessObject: BaseExtLink) => void): void {
        this._eventEmitter.off('update', callback);
    }

    public unsubscribeToRemove (callback: (businessObject: BaseExtLink) => void): void {
        this._eventEmitter.off('remove', callback);
    }

    public unsubscribeToYtUpdate (callback: (businessObject: BaseExtLink) => void): void {
        this._eventEmitter.off('updateYt', callback);
    }

    public run (): void {
        this.subscribe();
    }

    public stop (): void {
        this.unsubscribe();
    }

    private subscribe (): void {
        DataCache.ExternalLinksCache.onAdd.Subscribe(this.addExternalLinkEvent, this);
        DataCache.ExternalLinksCache.onUpdate.Subscribe(this.updateExternalLinkEvent, this);
        DataCache.ExternalLinksCache.onRemove.Subscribe(this.removeExternalLinkEvent, this);
        DataCache.ExternalLinksCache.onYtDataUpdate.Subscribe(this.updateExternalYtLink, this);
    }

    private unsubscribe (): void {
        DataCache.ExternalLinksCache.onAdd.UnSubscribe(this.addExternalLinkEvent, this);
        DataCache.ExternalLinksCache.onUpdate.UnSubscribe(this.updateExternalLinkEvent, this);
        DataCache.ExternalLinksCache.onRemove.UnSubscribe(this.removeExternalLinkEvent, this);
        DataCache.ExternalLinksCache.onYtDataUpdate.UnSubscribe(this.updateExternalYtLinkEvent, this);
    }

    private addExternalLink (link: BaseExtLink): void { this._eventEmitter.emit('add', link); }
    private updateExternalLink (link: BaseExtLink): void { this._eventEmitter.emit('update', link); }
    private removeExternalLink (link: BaseExtLink): void { this._eventEmitter.emit('remove', link); }
    private updateExternalYtLink (link: BaseExtLink): void { this._eventEmitter.emit('updateYt', link); }

    private addExternalLinkEvent (link: BaseExtLink): void { this.addExternalLink(link); }
    private updateExternalLinkEvent (link: BaseExtLink): void { this.updateExternalLink(link); }
    private removeExternalLinkEvent (link: BaseExtLink): void { this.removeExternalLink(link); }
    private updateExternalYtLinkEvent (link: BaseExtLink): void { this.updateExternalYtLink(link); }
}
