// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { DataSourceWidgetTemplate } from '../../templates.js';
import { Control } from '../elements/Control';
import { Level1Calculator } from '../../Commons/cache/Level1Calculator';
import { Instrument } from '../../Commons/cache/Instrument';
import { DataCache } from '../../Commons/DataCache';
import { type Account } from '../../Commons/cache/Account';
import { WDSettings } from '../settings/WDGeneralSettings';
import { WDSettingsUtils } from '../UtilsClasses/WDGeneralSettingsUtils';
import { ResourcesManager } from '../../Commons/properties/ResourcesManager';

export class DataSourceWidget extends Control {
    public static readonly BID = 'Bid';
    public static readonly ASK = 'Ask';
    public static readonly LAST = 'Last';

    public static readonly HISTORY_TYPES_IN_USE = [
        HistoryType.QUOTE_LEVEL1,
        HistoryType.QUOTE_TRADES,
        HistoryType.QUOTE_INSTRUMENT_DAY_BAR
    ];

    public displayAmountInLots: boolean = false;
    public unsubscribingInstrument: Instrument | null = null;
    public disabledTooltipCommon: string = '';
    public disabledWhenOnlyTradeRouteTooltip: string = '';
    public strNA: string = '---'; // если какое-то значение NaN - вместо него отображается эта локализированная строка
    public VenuesCache: any = {};

    public override getType (): string { return 'DataSourceWidget'; }

    public override oninit (): void {
        super.oninit();

        this.observe('instrument', this.onInstrumentChanged);
        this.observe('opened', this.onOpenedChanged);

        this.on('onButtonClick', this.onOpenButtonClick);

        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        WDSettings.settingsChanged.Subscribe(this.settingsChanged, this);

        DataCache.FQuoteCache.onRequestQuotesSubscription.Subscribe(this.onRequestQuotesSubscription, this);
        DataCache.FQuoteCache.onCancelQuotesSubscription.Subscribe(this.onCancelQuotesSubscription, this);

        DataCache.EntitlementManager.OnAddOrUpdate.Subscribe(this.onEntitlementManagerUpdate, this);

        Control.Ticker.Subscribe(this.TickAsync, this);

        this.localize();
    }

    public override oncomplete (): void {
        super.oncomplete();

        if (this.get('visible') && this.get('opened')) {
        // resubscribe cheat for stoped ins, if loaded WS and opened
            this.tryUnsubscribeFromQuotes(this.get('instrument'));
            this.trySubscribeToQuotes(this.get('instrument'));
        }

        this.settingsChanged();
    }

    public override dispose (): void {
        this.tryUnsubscribeFromQuotes(this.get('instrument'));

        WDSettings.settingsChanged.UnSubscribe(this.settingsChanged, this);
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        DataCache.FQuoteCache.onRequestQuotesSubscription.UnSubscribe(this.onRequestQuotesSubscription, this);
        DataCache.FQuoteCache.onCancelQuotesSubscription.UnSubscribe(this.onCancelQuotesSubscription, this);

        DataCache.EntitlementManager.OnAddOrUpdate.UnSubscribe(this.onEntitlementManagerUpdate, this);

        Control.Ticker.UnSubscribe(this.TickAsync, this);

        super.dispose();
    }

    public onInstrumentChanged (newInstrument: Instrument, oldInstrument: Instrument): void {
        if (isNullOrUndefined(newInstrument) || newInstrument === oldInstrument) { return; }

        let opened: boolean = this.get('opened');
        const visible = this.updateVisibleState(newInstrument);
        const enabled = newInstrument.IsDataSourceEnable();

        void this.set('enabled', enabled);

        if (!enabled || !visible) {
            void this.set('disabledTooltip', newInstrument.IsInfoRouteForDataSourceAvailable() ? this.disabledTooltipCommon : this.disabledWhenOnlyTradeRouteTooltip);

            if (opened) {
                this.tryUnsubscribeFromQuotes(oldInstrument);
                opened = false;
                void this.set('opened', opened);
            }
        }

        const dsIns = DataCache.GetDataSourceInstrument(newInstrument);
        if (!isNullOrUndefined(dsIns)) {
            void this.set('delayedVisible', dsIns.GetDelay() > 0);
        }

        this.tryUnsubscribeFromQuotes(oldInstrument);

        if (enabled && Resources.IsResourcePresentEN('controls.DataSource.CustomText')) {
            void this.set('CustomText', Resources.getResource('controls.DataSource.CustomText'));
            void this.set('opened', true);
            return;
        }

        if (!opened) {
            return;
        }

        this.trySubscribeToQuotes(newInstrument);
    }

    public newQuote (quote): void {
        void this.set('quote', quote);
    }

    public TickAsync (): void {
        void this.set({
            askPrice: this.getPrice(DataSourceWidget.ASK),
            askSize: this.getSize(DataSourceWidget.ASK),
            askVenue: this.getVenue(DataSourceWidget.ASK),

            bidPrice: this.getPrice(DataSourceWidget.BID),
            bidSize: this.getSize(DataSourceWidget.BID),
            bidVenue: this.getVenue(DataSourceWidget.BID),

            lastPrice: this.getPrice(DataSourceWidget.LAST),
            lastSize: this.getSize(DataSourceWidget.LAST),
            lastVenue: this.getVenue(DataSourceWidget.LAST)
        });
    }

    public trySubscribeToQuotes (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) { return; }

        const dsIns = DataCache.GetDataSourceInstrument(instrument);
        if (isNullOrUndefined(dsIns)) { return; }

        dsIns.RiskSettingsUpdated.Subscribe(this.onUpdateInsRiskSettings, this);

        DataSourceWidget.HISTORY_TYPES_IN_USE.forEach(function (type) {
            DataCache.FQuoteCache.addListener(dsIns, this, type, true);
        }.bind(this));
    }

    public tryUnsubscribeFromQuotes (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) { return; }

        const dsIns = DataCache.GetDataSourceInstrument(instrument);
        if (isNullOrUndefined(dsIns)) { return; }

        this.unsubscribingInstrument = instrument;

        dsIns.RiskSettingsUpdated.UnSubscribe(this.onUpdateInsRiskSettings, this);

        DataSourceWidget.HISTORY_TYPES_IN_USE.forEach(function (type) {
            DataCache.FQuoteCache.removeListener(dsIns, this, type, true);
        }.bind(this));
    }

    public onRequestQuotesSubscription (instrumentTradableID, route, quoteType, forse, isDataSource: boolean): void {
        if (!isDataSource) { return; }

        // Небыло фильтрации по инструменту, любой даже не видимый виджет на любую подписку подписывался/отписывался
        const instrument: Instrument = this.get('instrument');
        if (isNullOrUndefined(instrument) || !this.get('visible')) {
            return;
        }

        const instrumentCaller = DataCache.getInstrumentByTradable_ID(instrumentTradableID, route);
        const dsIns = DataCache.GetDataSourceInstrument(instrument);
        if (!isNullOrUndefined(dsIns) && dsIns === instrumentCaller) {
            DataCache.SendDataSourceSubscriptionMessage(instrument, true, quoteType);
        }
    }

    public onCancelQuotesSubscription (instrumentTradableID, route, quoteType, forse, isDataSource: boolean): void {
        if (!isDataSource) { return; }

        // Небыло фильтрации по инструменту, любой даже не видимый виджет на любую подписку подписывался/отписывался
        const instrument = this.unsubscribingInstrument;
        if (isNullOrUndefined(instrument)) {
            return;
        }

        const instrumentCaller = DataCache.getInstrumentByTradable_ID(instrumentTradableID, route);
        const dsIns = DataCache.GetDataSourceInstrument(instrument);
        if (!isNullOrUndefined(dsIns) && dsIns === instrumentCaller) {
            DataCache.SendDataSourceSubscriptionMessage(instrument, false, quoteType);
        }
    }

    public localize (): void {
        void this.set({
            priceLabel: Resources.getResource('dataSourceWidget.price'),
            sizeLabel: Resources.getResource('dataSourceWidget.size'),
            venueLabel: Resources.getResource('dataSourceWidget.venue'),
            lastLabel: Resources.getResource('dataSourceWidget.last'),
            bidLabel: Resources.getResource('dataSourceWidget.bid'),
            askLabel: Resources.getResource('dataSourceWidget.ask'),
            buttonTitle: Resources.getResource('dataSourceWidget.btnText'),
            openedBtnTooltip: Resources.getResource('dataSourceWidget.openedBtn.tt'),
            closedBtnTooltip: Resources.getResource('dataSourceWidget.closedBtn.tt'),
            delayedTooltip: Resources.getResource('dataSourceWidget.delayed.tt'),
            noDataText: Resources.getResource('dataSourceWidget.noData.text')
        });

        const disabledCommonTTLocalized = Resources.getResource('dataSourceWidget.disabled.tt');
        const disabledTradeRouteTTLocalized = Resources.getResource('dataSourceWidget.disabledWhenOnlyTradeRoute.tt');

        const ins: Instrument = this.get('instrument');
        if (Instrument.IsWorkingInstrument(ins)) {
            void this.set('disabledTooltip', ins.IsInfoRouteForDataSourceAvailable() ? disabledCommonTTLocalized : disabledTradeRouteTTLocalized);
        }

        this.disabledTooltipCommon = disabledCommonTTLocalized;
        this.disabledWhenOnlyTradeRouteTooltip = disabledTradeRouteTTLocalized;

        this.strNA = Resources.getResource('general.N_A');
    }

    public settingsChanged (): void {
        this.displayAmountInLots = WDSettingsUtils.displayAmountInLots();
    }

    public onOpenedChanged (): void {
        const opened: boolean = this.get('opened');
        const ins: Instrument = this.get('instrument');

        if (opened && Resources.IsResourcePresentEN('controls.DataSource.CustomText')) {
            return;
        }

        if (opened) {
            this.trySubscribeToQuotes(ins);
        } else {
            this.tryUnsubscribeFromQuotes(ins);
        }
    }

    public onOpenButtonClick (): void {
        if (!this.get('enabled')) {
            return;
        }

        void this.set('opened', !this.get('opened'));
    }

    public onUpdateInsRiskSettings (): void {
        const ins = DataCache.GetDataSourceInstrument(this.get('instrument'));

        if (!isNullOrUndefined(ins)) {
            void this.set('delayedVisible', ins.GetDelay() > 0);
        }
    }

    public updateVisibleState (ins: Instrument): boolean {
        const newVisibleState = DataCache.EntitlementManager.IsDataSourceAvailable(ins);
        void this.set('visible', newVisibleState);

        return newVisibleState;
    }

    public onEntitlementManagerUpdate (): void {
        this.updateVisibleState(this.get('instrument')); // ради изменения visible состояния на лету без логина или смены инструмента
    }

    public getPrice (service: string): string {
        const ins: Instrument = this.get('instrument');
        const acc: Account = this.get('account');
        const level1 = !isNullOrUndefined(ins?.DataSourceLevel1) ? ins.DataSourceLevel1 : null;

        let result = !isNullOrUndefined(level1)
            ? (service === DataSourceWidget.LAST
                ? level1.StrLastPrice(acc, true)
                : level1['str' + service](acc, true))
            : null;

        if (isNullOrUndefined(result)) {
            result = this.strNA;
        }

        return result;
    }

    public getSize (service: string): string {
        const ins: Instrument = this.get('instrument');
        const showLots = this.displayAmountInLots;
        const level1 = !isNullOrUndefined(ins?.DataSourceLevel1) ? ins.DataSourceLevel1 : null;
        const methodName = 'Str' + service + 'Size';

        let result = !isNullOrUndefined(level1) ? level1[methodName](showLots) : null;

        if (isNullOrUndefined(result)) {
            result = this.strNA;
        }

        return result;
    }

    public getVenue (service: string): string {
        const ins = this.get('instrument');
        const methodName = 'Get' + service + 'SourceName';
        const instrumentDayBarMessageUpdateMode = !isNullOrUndefined(ins) ? ins.InstrumentDayBarMessageUpdateMode : null;
        let result = !isNullOrUndefined(ins?.DataSourceLevel1) ? ins.DataSourceLevel1[methodName](instrumentDayBarMessageUpdateMode) : null;

        if (isNullOrUndefined(result) || result === -1) {
            result = this.strNA;
        }

        if (Level1Calculator.NAString === result && !!this.VenuesCache[methodName]) {
            result = this.VenuesCache[methodName];
        } else {
            this.VenuesCache[methodName] = result;
        }

        return result;
    }
}

Control.extendWith(DataSourceWidget, {
    template: DataSourceWidgetTemplate,
    data: function () {
        return {
            name: 'DataSourceWidget',

            instrument: null,
            account: null,
            quote: null,

            visible: false,
            opened: false,
            comboBoxButtonType: true,

            priceLabel: '',
            sizeLabel: '',
            venueLabel: '',
            lastLabel: '',
            bidLabel: '',
            askLabel: '',

            askPrice: null,
            askSize: null,
            askVenue: null,
            bidPrice: null,
            bidSize: null,
            bidVenue: null,
            lastPrice: null,
            lastSize: null,
            lastVenue: null,

            buttonTitle: 'Data source',
            openedBtnTooltip: 'Hide data source',
            closedBtnTooltip: 'Show data source',
            disabledTooltip: '', // используемый в темплейте - может быть общего вида или уточненный по причине отсутствия инфо роута
            delayedTooltip: 'Delayed data',
            delayedVisible: true,

            enabled: false,
            hasData: true,
            noDataText: 'No data to display',
            zIndex: null,
            noDataWidth: 300, // ширина окна при No data to display
            CustomText: null
        };
    }
});
