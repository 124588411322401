// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
/// <summary>
/// Обертка вектора результата

import { type ExpandDoubleVector } from './DoubleMatrix';

/// </summary>
export class ResVector {
    public FRowNumber: number;
    public FTimeShift: any[];
    public FPriceMult: any[];
    public Vector: ExpandDoubleVector;

    constructor (rowNumber: number, timeShift: any[], priceMult: any[], vector: ExpandDoubleVector) {
        this.FRowNumber = rowNumber;
        this.FTimeShift = timeShift;
        this.FPriceMult = priceMult;
        this.Vector = vector;
    }

    get TimeShift (): any {
        return this.FTimeShift[this.FRowNumber];
    }

    // #region IDoubleVector Members

    public get (index): number {
        const priceMult = this.FPriceMult[this.FRowNumber];
        return index >= 0 && index < this.Vector.Length ? this.Vector.get(index) * priceMult : 0.0;
    }

    public set (index, value): void {
        this.Vector.set(index, value);
    }

    get Length (): number {
        return this.Vector.Length;
    }

    // #endregion

    public Dispose (): void {
        if (this.Vector != null) {
            this.Vector.Dispose();
            this.Vector = null;
        }
    }
}
