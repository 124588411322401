// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { Pen } from '@shared/commons/Graphics';
import { TerceraChartUtils } from '@front/chart/TerceraChartUtils';
import { ProcessChartVisible } from '../Investing';
import { openOrderEntry } from '../Screen/InvestingOE';
import { InvestingAdvancedChartPanelTemplate } from '../templates.js';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { ChartPanel } from '@front/controls/panels/ChartPanel';
import { Control } from '@front/controls/elements/Control';
import { PanelNames } from '@front/controls/UtilsClasses/FactoryConstants';

export const closeAdvancedChartPanelEvent = new CustomEvent();

export class InvestingAdvancedChartPanel extends ChartPanel {
    public terceraChartRactive: any = null;
    public chartOE: any = null;
    public needUpdateLimitStopNumerics: boolean = false;
    public ModelChanged: any = null;
    public myVisualWidget: any = null;
    public myTradeNumeric: any = null; // нумерик для редактирования quantity размещенных на Chart-e ордеров
    public myTradeNumericPromise: any = null;
    public initialTemplateSelected: boolean = false;

    constructor () {
        super();

        this.Name = 'ChartPanel';
    }

    public override getType (): PanelNames { return PanelNames.InvestingAdvancedChartPanel; }

    public override oninit (): void {
        this.on('closeAdvancedChartPanel', this.Close);
        this.ModelChanged = new CustomEvent();
    }

    public override async oncomplete (): Promise<void> {
        await super.oncomplete();
        Control.Ticker.Subscribe(this.TickAsync, this);
        this.on('buy_click', () => { openOrderEntry(true, this.makeInsDataForOE(this.get('instrumentItem')), this.get('accountItem')); });
        this.on('sell_click', () => { openOrderEntry(false, this.makeInsDataForOE(this.get('instrumentItem')), this.get('accountItem')); });

        this.setSize();
        this.MakeDefaults();

        const newIns = this.get('instrumentItem');
        this.setInstrumentInfo(newIns);
    }

    public makeInsDataForOE (instrument): any {
        if (!instrument) {
            return {};
        }

        const Obj: any = {};
        Obj.Symbol = instrument.DisplayName();
        Obj.Description = instrument.DescriptionValue();
        Obj.Img = instrument.LogoAddress;
        Obj.Instrument = instrument;

        return Obj;
    }

    public override setSize (): void {
        const from = document.getElementById('advancedChart');
        const rect = from.getBoundingClientRect();

        const w = rect.width - 16;
        const h = rect.height;

        this.terceraChartRactive.setBounds(0, 0, w, h);
        this.terceraChartRactive.terceraChart.OnResize(Math.abs(w), Math.abs(h));
    }

    public setInstrumentInfo (instrument): void {
        if (!instrument) {
            return;
        }

        const logoSrc = instrument.LogoAddress;
        const name_value = instrument.DisplayName();
        const description_value = instrument.DescriptionValue();

        void this.set({
            logoSrc,
            name_value,
            description_value
        });
    }

    public MakeDefaults (): void {
        const terceraChart = this.terceraChartRactive.terceraChart;
        const mainPriceR = terceraChart.mainPriceRenderer;
        const theme = ThemeManager.CurrentTheme;
        if (mainPriceR) {
            mainPriceR.barsUpBorderColorPen = new Pen(theme.PortfolioReturnsChartMainLineColor, 1);
            mainPriceR.barsDownBorderColorPen = new Pen(theme.PortfolioReturnsChartMainLineColor, 1);
        }
        if (terceraChart.TerceraChartNewAlertRenderer) {
            terceraChart.TerceraChartNewAlertRenderer.Visible = false;
        }

        if (terceraChart.TerceraChartTradingToolsRenderer) {
            terceraChart.TerceraChartTradingToolsRenderer.Visible = false;
        }

        const tradingToolsRenderer = terceraChart.TerceraChartTradingToolsRenderer;

        if (tradingToolsRenderer) {
            tradingToolsRenderer.ShowOrders = false;
            tradingToolsRenderer.ShowPositions = false;
            tradingToolsRenderer.ShowVisualTradingOnLeftSide = false;
            tradingToolsRenderer.ShowEvents = false;
            tradingToolsRenderer.ShowAlerts = false;
        }
        terceraChart.GetContextMenu = () => { return null; };
    }

    public Close (): void {
        ProcessChartVisible(false);
        closeAdvancedChartPanelEvent.Raise();
    }

    public override updateDrawingStyleComboBox (): void {
        if (!this.terceraChartRactive?.terceraChart) {
            return;
        }

        const chart = this.terceraChartRactive.terceraChart;
        const allowedStyles = chart.model.CurrentAllowedStyles;
        const len = allowedStyles.length;
        const items = [];
        for (let i = 0; i < len; i++) {
            const style = allowedStyles[i];
            items.push({ style: 'js-drawing-style' + style, text: TerceraChartUtils.ToLocalizedTerceraChartDrawingType(style), value: style });
        }
        const cBox = this.Controls.drawingStyleComboBox;
        if (cBox) {
            cBox.set({ usePNGView: false, showArrow: true, items, enabled: len > 1 });
            cBox.setItembyValue(chart.model.GetChartDrawingType());
        }
    }
}

ChartPanel.extendWith(InvestingAdvancedChartPanel, {
    template: InvestingAdvancedChartPanelTemplate,
    data: function () {
        return {
            isAccountLinkShow: true,
            isSymbolLinkShow: true,
            instrumentItem: null,
            accountItem: null,

            isShowOE: false,
            oeSmall: true,

            showToolsPanel: false,
            showMainToolBarPanel: true,
            showAnalyzeToolsPanel: false,
            showAccountLookUp: true,
            tradingLocked: false,

            canFilterByAccount: false,

            showOverlayButton: true,

            dataSourceOpen: false,
            dataSourceVisible: true, // true to enable data source
            dataSourceBottom: 5,

            hasCorrectData: false,
            height: 500,
            width: 500,
            addWidgets: false,

            editableCBtooltip: 'chart.templates.tooltip',

            logoSrc: '',
            name_value: '',
            description_value: ''
        };
    },
    computed: {
        terceraChartPanelContext: {
            get: function () { return this; },
            set: function (value) { }
        }
    }
});
