// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { SlTpPriceType } from '../../../../../Utils/Enums/Constants';
import { OperationType } from '../../../../../Utils/Trading/OperationType';
import { OrderType } from '../../../../../Utils/Trading/OrderType';
import { OrderUtils } from '../../../../../Utils/Trading/OrderUtils';
import { Quantity } from '../../../../../Utils/Trading/Quantity';
import { Resources } from '../../../../../Localizations/Resources';
import { type Order } from '../../../Order';
import { type Position } from '../../../Position';
import { SavedOrder } from '../../../SavedOrders/SavedOrder';
import { LinkedPriceType } from '../../../SavedOrders/SavedOrderEnums';

export class PaperPosition extends SavedOrder {
    private _position: Position = undefined;

    public Analyse: boolean = false;

    public get PaperPositionType (): string {
        return !isNullOrUndefined(this._position) ? Resources.getResource('panel.optionPaperPositions.Position') : Resources.getResource('panel.optionPaperPositions.PaperPosition');
    }

    public get CurrentClosePrice (): number {
        return !isNullOrUndefined(this._position) && this._position.lastPriceUpdated ? this._position.CurPriceClose : NaN;
    }

    public get Fee (): number {
        return !isNullOrUndefined(this._position) ? this._position.GetCommissionFromSettlement(true) : 0;
    }

    public get OperationStr (): string {
        return this.isPosition() ? Resources.getResource(`general.trading.position.${OrderUtils.getBuySellStr(this.Operation)}`) : super.OperationStr;
    }

    public get OrderTypeStr (): string {
        return !isNullOrUndefined(this._position) ? '' : super.OrderTypeStr;
    }

    public get TifStr (): string {
        return !isNullOrUndefined(this._position) ? '' : super.TifStr;
    }

    public get QuantityAmount (): number {
        return Quantity.convertLotsToAmount(this.QuantityLots, this.Instrument, false);
    }

    public get PriceForGreeksCalculation (): number {
        if (this.isPosition()) {
            return this._position.CurPriceClose;
        } else if (this.OrderType === OrderType.Market) {
            return this.Instrument.GetMarketPrice(this.Account, this.Operation);
        } else if (this.OrderType === OrderType.Stop) {
            return this.StopPrice;
        } else {
            return this.GetRealPrice;
        }
    }

    public get PriceForPnlCalculation (): number {
        if (this.OrderType === OrderType.Market) {
            return this.Instrument.GetMarketPrice(this.Account, this.Operation);
        } else if (this.OrderType === OrderType.Stop) {
            return this.StopPrice;
        } else {
            return this.GetRealPrice;
        }
    }

    private get GetRealPrice (): number {
        if (this.OrderType === OrderType.TrailingStop) {
            const currentPrice = this.Operation === OperationType.Buy ? this.Instrument.Level1.GetAsk(this.Account) : this.Instrument.Level1.GetBid(this.Account);
            if (currentPrice <= 0) { return 0; }

            const ticks = (this.Operation === OperationType.Buy ? 1 : -1) * this.StopPrice;

            return this.Instrument.CalculatePrice(currentPrice, ticks);
        }
        return this.Price;
    }

    public attachPosition (position: Position): void {
        this._position = position;
        this.Price = NaN;
        this.StopPrice = NaN;
        this.LinkedPriceOffset = 0;
        this.LinkedPriceType = LinkedPriceType.None;
        this.Active = false;
        this.updatePosition();
    }

    public isAttachedPosition (position: Position): boolean {
        return this._position === position;
    }

    public isPosition (): boolean { return !isNullOrUndefined(this._position); }

    public isSubPosition (): boolean { return !isNullOrUndefined(this._position) && this._position.isTradePosition; }

    public updatePosition (): void {
        const position = this._position;
        if (isNullOrUndefined(position)) {
            return;
        }
        this.Operation = position.BuySell;
        this.QuantityLots = position.Amount;
        this.TIF = position.TimeInForce;
        this.OrderType = position.OrderType;
        this.ProductType = position.ProductType;
        this.Leverage = position.Leverage;
        this.Price = position.Price;
        const slOrder: Order = position.SLOrder;
        if (!isNullOrUndefined(slOrder)) {
            this.SLTPHolder.StopLossPriceType = slOrder.OrderType === OrderType.TrailingStop ? SlTpPriceType.TrOffset : SlTpPriceType.Absolute;
            this.SLTPHolder.StopLossPriceValue = slOrder.OrderType === OrderType.TrailingStop ? slOrder.TrStopOffset : slOrder.Price;
        } else {
            this.SLTPHolder.StopLossPriceValue = NaN;
        }
        const tpOrder: Order = position.TPOrder;
        if (!isNullOrUndefined(tpOrder)) {
            this.SLTPHolder.TakeProfitPriceType = SlTpPriceType.Absolute;
            this.SLTPHolder.TakeProfitPriceValue = tpOrder.Price;
        } else {
            this.SLTPHolder.TakeProfitPriceValue = NaN;
        }
    }
}
