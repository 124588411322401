// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType, PriceTypeMap } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class SMA extends IndicatorScriptBase {
    public MaPeriod: number;
    public SourcePrice: number;

    constructor () {
        super();
        this.ProjectName = 'Simple Moving Average';
        this.Comments = 'Average price for the last N periods';
        this.SetIndicatorLine('Line', Color.Red, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.MaPeriod = 1;
        super.InputParameter(new InputParameterInteger('MaPeriod', 'Period of Simple Moving Average', 0, 1, 9999));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('MaPeriod', 'Period of Simple Moving Average', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    }

    public override GetIndicatorShortName (): string {
        return 'SMA(' + this.MaPeriod + ';' + PriceTypeMap[this.SourcePrice].toLowerCase() + ')';
    }

    public override getCustomName (): string {
        return 'SMA (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        // Checking, if current amount of bars
        // more, than period of moving average. If it is
        // then the calculation is possible
        if (this.CurrentData.Count < this.MaPeriod) { return; }
        let summa = 0.0; // Sum of prices
        for (let i = 0; i < this.MaPeriod; i++)
        // Adding bar's price to the summa
        { summa += this.CurrentData.GetPrice(this.SourcePrice, i); }
        // Setting the current value of the indicator
        this.SetValue(0, 0, summa / this.MaPeriod);
    }

    public getMinBarsCount (): number {
        return this.MaPeriod;
    }
}
