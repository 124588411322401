// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export class InvestingLookupNode {
    public nodeText: string;
    public visible = true;
    public selected = false;
    public nodeId = '';
    public nodeLevel = 0;
    public hasVisibleChild = false;
    public childNodes = [];
    public parentNode = null;
    public collapsed = true;
    public defaultImage = null;
    public selectedImage = null;
    public tag = null;
    public checked = false;

    public Symbol;
    public Description;
    public Country;
    public Trading_exchange;
    public Instrument_type;
    public Img_Url;
    public CountryFlag: HTMLImageElement;
    public CountryFlag_src: string;
    public Instrument_type_text: string;
    public Instrument: any;
    public IsPresent_in_WL: boolean;

    constructor (text: string) {
        this.nodeText = text;
    }

    public AddChildNode (newNode): void {
        newNode.parentNode = this;
        this.childNodes.push(newNode);
    }

    public AddChildNodeRange (newNodesArray): void {
        const len = newNodesArray.length;
        for (let i = 0; i < len; i++) {
            newNodesArray[i].parentNode = this;
        }

        this.childNodes = this.childNodes.concat(newNodesArray);
    }

    public RemoveChildNode (index): void {
        this.childNodes.splice(index - 1, index);
    }

    public HasChildNodes (): boolean {
        return this.childNodes.length > 0;
    }

    public GetFirstChild (): any {
        return this.childNodes[0] || null;
    }

    public GetLastChild (): any {
        return (this.childNodes.length > 0) ? this.childNodes[this.childNodes.length - 1] : null;
    }

    public getFullPath (): string {
        let path = this.nodeText;
        let parent = this.parentNode;
        while (parent !== null) {
            path += parent.nodeText;
            parent = parent.parentNode;
        }
        return path;
    }

    public setCheck (value): void {
        if (this.checked === value) {
            return;
        }
        const parent = this.parentNode;
        if (parent) {
            const previousCount = parent.checkedChildsCount;
            if (value) {
                parent.checkedChildsCount++;
            } else {
                parent.checkedChildsCount--;
            }
            if (parent.checkedChildsCount === parent.childNodes.length) {
                parent.setCheck(true);
            } else
                if (previousCount === parent.childNodes.length) {
                    parent.setCheck(false);
                }
        }
        this.checked = value;
    }

    public DoCollapse (): void {
        this.collapsed = !this.collapsed;
    }
}
