// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { OETIFSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '@shared/localizations/Resources';
import { TIF } from '@shared/utils/Trading/OrderTif';
import { OrderTif, OrderTifMap } from '@shared/utils/Trading/OrderTifEnum';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { OrderExecutorUtils } from '@shared/commons/Trading/OrderExecutorUtils';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { ResourcesManager } from '@shared/commons/properties/ResourcesManager';

// TODO. Move logic to TerceraTIFComboBox?
// TODO. Fix last selected + default TIF.
export class OETIFSelector extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'OETIFSelector'; }

    public override oninit (): void {
        super.oninit();
        this.observe('orderType instrument defaultTif', this.repopulate);
        this.observe('selectedItem gtdDate', this.updateTIF);
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        super.dispose();
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.newOrderEntry.tifPanel'));

        const items = this.get('items');
        const changes = {};

        for (let i = 0, len = items ? items.length : 0; i < len; i++) {
            changes['items.' + i + '.text'] =
            Resources.getResource(OrderTifMap[items[i].value]);
        }

        void this.set(changes);
    }

    public updateTIF (): void {
        OETIFSelector.GetAdvancedUpdateTIF(this);
    }

    public repopulate (): void {
        const orderType = this.get('orderType');
        const orderTypeId = orderType ? orderType.id() : null;

        OETIFSelector.GetAdvancedComboBoxItems([orderTypeId], this);
    }

    public static GetAdvancedComboBoxItems (orderTypesArray, panel): void {
        const instrument: Instrument = panel.get('instrumentItem') ?? panel.get('instrument');
        const orderTypeId = orderTypesArray[0];

        void panel.set('orderTypeId', orderTypeId);

        let items: Array<{ value: OrderTif, text: string }> = null;
        if (orderTypeId && instrument) {
            let nums = [];
            for (let i = 0, len = orderTypesArray.length; i < len; i++) {
                nums = nums.concat(OrderExecutorUtils.getAllowedTIFArray(orderTypesArray[i], instrument));
            }

            const object = {};

            for (let i = 0; i < nums.length; i++) { object[nums[i]] = nums[i]; }

            // TODO. Sorting of allowedTIFArr.
            // ...
            items = TIF.createVariantsForTifs(Object.keys(object));
        }

        const isSelectDefaultValue = !isNullOrUndefined(items) && isNullOrUndefined(panel.get('selectedItem'));
        void panel.set('items', items);

        // TODO. Remove. Ugly.
        let defaultTif = SessionSettings.getDefaultTifForOrderType(orderTypeId);
        if (isNullOrUndefined(defaultTif) || !isValidArray(items)) {
            return;
        }

        const orderTif = panel.get('defaultTif');
        if (!isNullOrUndefined(orderTif)) {
            defaultTif = orderTif.type;
        }

        let selectedOrderTif = panel.get('selectedItem').value;
        if (isSelectDefaultValue && !isNullOrUndefined(items.find(item => item.value === defaultTif))) {
            selectedOrderTif = defaultTif;
        }

        for (let i = 0, len = items.length; i < len; i++) {
            const item = items[i];
            if (item.value === selectedOrderTif) {
                void panel.set('selectedItem', item);
                const expTime: Date = selectedOrderTif === OrderTif.GTD
                    ? ((orderTif ? orderTif.expirationTime : null) || TIF.getDefaultExpireTimeForOrderType(orderTypeId, instrument))
                    : new Date();
                if (!isNullOrUndefined(expTime)) {
                    void panel.set('gtdDate', expTime);
                }

                return;
            }
        }
    }

    public static GetAdvancedUpdateTIF (panel): void {
        let result = null;

        const selectedItem = panel.get('selectedItem');
        if (!isNullOrUndefined(selectedItem)) {
            const tif = selectedItem.value;
            const isGTD = tif === OrderTif.GTD;

            result = new TIF(tif, isGTD ? panel.get('gtdDate') : null);
        }

        panel.set('tif', result);
    };
}

ContainerControl.extendWith(OETIFSelector, {
    template: OETIFSelectorTemplate,
    data: function () {
        return {
            label: '',

            orderType: null,
            instrument: null,
            items: null,

            orderTypeId: null,

            gtdDate: null,
            selectedItem: null,

            tif: null,

            // TODO. Remove. Ugly.
            defaultTif: null,

            showLabel: true,

            width: 198
        };
    }
});
