// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoryMetadata } from '../charting_library';

export class TvHistoryMetadata implements HistoryMetadata {
    noData: boolean = false;
    nextTime: number | null = null;

    constructor (noData, nextTime = null) {
        this.noData = noData;
        this.nextTime = nextTime;
    }
}
