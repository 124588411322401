// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export enum HeatmapSymbolTitleBy {
    Symbol,
    Description,
}

export enum HeatmapSymbolFirstValue {
    None,
    ChangePercentage,
}

export enum HeatmapSymbolSecondValue {
    None,
    LastPrice,
    MarketCap,
}

export enum HeatmapSymbolSizeBy {
    MarketCap,
}

export enum HeatmapSymbolColorBy {
    ChangePercentage,
}

export enum HeatmapInfoWindowMode {
    AttachedToCursor,
    Hidden
}

export enum HeatmapInfoState {
    None,
    NoData,
    WaitingData,
    NoDataForFilter,
}
