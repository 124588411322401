// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { OEProductTypeSelectorTemplate } from '../../../templates.js';
import { ContainerControl } from '../../elements/ContainerControl';
import { Resources } from '../../../Localizations/Resources';
import { ProductType } from '../../../Utils/Instruments/ProductType';
import { InstrumentUtils } from '../../../Utils/Instruments/InstrumentUtils';
import { type Instrument } from '../../../Commons/cache/Instrument.js';
import { type Account } from '../../../Commons/cache/Account.js';
import { WDSettings } from '../../settings/WDGeneralSettings';
import { ResourcesManager } from '../../../Commons/properties/ResourcesManager';

export class OEProductTypeSelector extends ContainerControl {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): string { return 'OEProductTypeSelector'; }

    public override oninit (): void {
        super.oninit();
        this.observe('selectedItem', this.updateProductType);
        this.observe('productType', this.onProductTypeChange);
        this.observe('instrument account', this.repopulate);
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);

        super.dispose();
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.newOrderEntry.ProductType'));
    }

    public updateProductType (selectedItem): void {
        void this.set('productType', selectedItem ? selectedItem.value : null);
    }

    public onProductTypeChange (value): void {
        const selItem = this.get('selectedItem');
        const items = this.get('items');

        if (items && selItem && selItem.value != value) {
            for (let i = 0; i < items.length; i++) {
                if (items[i].value == value) {
                    void this.set('selectedItem', items[i]);
                }
            }
        }
    }

    public repopulate (): void {
        const instrument: Instrument = this.get('instrument');
        const account: Account = this.get('account');
        const insID = !isNullOrUndefined(instrument) ? instrument.GetInteriorID() : null;
        const dict: number[] = !isNullOrUndefined(account?.RiskPlan) ? account.RiskPlan.GetRisksForInstrument(insID).availableProductTypes : InstrumentUtils.getAllowedProductTypeDict(instrument);

        if (isNullOrUndefined(instrument) || isNullOrUndefined(account) || (dict.length === 0)) {
            void this.set('visible', false);
            return;
        }

        const items = dict.map(function (productType) {
            return {
                value: productType,
                text: InstrumentUtils.GetLocalizedProductType(instrument, productType)
            };
        });

        const visible = items[0].value !== ProductType.General; // при ProductType.General скрываем комбобокс
        const enabled = items.length !== 1; // при единственном ProductType делаем комбобокс enabled
        const defaultValue = WDSettings.productType;

        void this.set({
            defaultValue,
            items,
            visible,
            enabled
        });
    }
}

ContainerControl.extendWith(OEProductTypeSelector, {
    template: OEProductTypeSelectorTemplate,
    data: function () {
        return {
            label: '',

            instrument: null,
            account: null,

            items: null,
            selectedItem: null,
            defaultValue: null,

            productType: null,
            showLastValue: false,

            visible: false,
            enabled: true,

            showLabel: true,

            width: 198,
            tooltip: 'OEProductTypeSelector.tooltip'
        };
    }
});
