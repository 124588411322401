// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export class HashTable {
    public table = new Array(127);
    public counter = 0;

    public _hash (key): number {
        let hash = 0;
        for (let i = 0; i < key.length; i++) {
            hash += key.charCodeAt(i);
        }

        return hash % this.table.length;
    }

    public Set (key, value): void {
        const index = this._hash(key);
        if (this.table[index]) {
            for (let i = 0; i < this.table[index].length; i++) {
                if (this.table[index][i] != null && this.table[index][i][0] === key) {
                    this.table[index][i][1] = value;
                    return;
                }
            }
            this.table[index].push([key, value]);
        } else {
            this.table[index] = [];
            this.table[index].push([key, value]);
        }
        this.counter++;
    }

    public Get (key): any {
        const index = this._hash(key);
        if (this.table[index] != null && this.table.length > 0) {
            for (let i = 0; i < this.table.length; i++) {
                if (this.table[index][i] != null && this.table[index][i][0] === key) {
                    return this.table[index][i][1];
                }
            }
        }
        return undefined;
    }

    public Clear (key): any {
        const index = this._hash(key);

        if (this.table[index] != null && this.table[index].length > 0) {
            for (let i = 0; i < this.table.length; i++) {
                if (this.table[index][i][0] === key) {
                    this.table[index].splice(i, 1);
                    this.counter--;
                    return true;
                }
            }
        } else {
            return false;
        }
    }

    public Display (): void {
        this.table.forEach((values, index) => {
            const chainedValues = values.map(
                ([key, value]) => `[ ${key}: ${value} ]`
            );
            console.log(`${index}: ${chainedValues}`);
        });
    }
}
