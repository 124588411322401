// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ChartZoomRendererButtons } from '../Renderers/TerceraChartZoomRenderer';
import { type TerceraChartAction } from '../TerceraChartAction';
import { type TerceraXYChart } from '../TerceraXYChart';
import { TerceraChartBaseActionProcessor } from './TerceraChartBaseActionProcessor';

export class TerceraChartXYActionProcessor extends TerceraChartBaseActionProcessor<TerceraXYChart> {
    override processZoomAction (action: TerceraChartAction): boolean {
        let processed = false;

        switch (action.Details) {
        case ChartZoomRendererButtons.ZoomIn:
            this.chart.mainWindow.ZoomIn();
            processed = true;
            break;
        case ChartZoomRendererButtons.ZoomOut:
            this.chart.mainWindow.ZoomOut();
            processed = true;
            break;
        default:
            processed = super.processZoomAction(action);
        }

        return processed;
    }
}
