// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export abstract class DataStream {
    public OnMessageDelegate: any;

    constructor (onMessageDelegate) {
        if (onMessageDelegate && onMessageDelegate !== null) {
            this.OnMessageDelegate = onMessageDelegate;
        } else {
            this.OnMessageDelegate = null;
        }
    }

    public abstract Dispose ();

    public abstract Connected () ;

    public abstract SendData (message) ;
}
