// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { ExpandDoubleVector } from '../../DoubleMatrix';
import { MAMode, MODE_MAIN, MODE_MINUSDI, MODE_PLUSDI } from '../../IndicatorConstants';
import { IndicatorFunctions } from '../../IndicatorFunctions';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iADXEx extends iBuildInIndicator {
    public FMaMode: MAMode;
    public FMaPeriod: any;
    public FDrawBegin: any;
    public ARRAY_PDM: ExpandDoubleVector = new ExpandDoubleVector();
    public ARRAY_MDM: ExpandDoubleVector = new ExpandDoubleVector();
    public ARRAY_DX: ExpandDoubleVector = new ExpandDoubleVector();
    public FMAMethod: string;
    public ma: ExpandDoubleVector;

    constructor (maPeriod, priceType, maMode: MAMode) {
        super(3);
        this.FPriceType = priceType;
        this.FMaMode = maMode;
        this.FMaPeriod = maPeriod;
        this.FDrawBegin = (maMode === MAMode.EMA) ? 1 : this.FMaPeriod;
    }

    override get Name (): string { return 'iADXEx'; }

    override get Key (): string { return this.DefaultKey + this.FMaPeriod + this.FMAMethod + this.FPriceType; }

    public override NextBar (callBound): void {
        super.NextBar(callBound);
        this.ARRAY_PDM.Add(0.0);
        this.ARRAY_MDM.Add(0.0);
        this.ARRAY_DX.Add(0.0);
    }

    public override Refresh (count, newThread): any {
        this.ma.Dispose();
        this.ma = new ExpandDoubleVector();
        super.Refresh(count, newThread);
    }

    public override OnQuote (): void {
        this.ARRAY_PDM[this.ARRAY_PDM.Length - 1] = 0;
        this.ARRAY_MDM[this.ARRAY_MDM.Length - 1] = 0;
        this.ARRAY_DX[this.ARRAY_DX.Length - 1] = 0;
        const Parent = this.Parent;
        if (!Parent) {
            return;
        }
        if (Parent == null || this.FCount == 1) {
            return;
        }

        const hi = this.GetPrice(PriceType.High, 0);
        const lo = this.GetPrice(PriceType.Low, 0);

        // Вычисление направления движения (DM)
        let plusDM = hi - this.GetPrice(PriceType.High, 1);
        if (plusDM < 0.0) {
            plusDM = 0.0;
        }
        let minusDM = this.GetPrice(PriceType.Low, 1) - lo;
        if (minusDM < 0.0) {
            minusDM = 0.0;
        }
        if (plusDM == minusDM) {
            plusDM = minusDM = 0.0;
        }
        if (plusDM > minusDM) {
            minusDM = 0.0;
        } else {
            plusDM = 0.0;
        }

        // Вычисление индексов направлений DI
        const prevClose = this.GetPrice(this.FPriceType, 1);
        const TR = Math.max(hi - lo, Math.max(Math.abs(prevClose - hi), Math.abs(prevClose - lo)));
        if (TR != 0.0) {
            this.ARRAY_PDM[this.ARRAY_PDM.Length - 1] = 100 * plusDM / TR;
            this.ARRAY_MDM[this.ARRAY_MDM.Length - 1] = 100 * minusDM / TR;
        }

        if (this.FCount < this.FDrawBegin) {
            return;
        }
        const plusDI = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.ARRAY_PDM, this.FMaPeriod, 1);
        const minusDI = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.ARRAY_MDM, this.FMaPeriod, 1);
        // Вычисление индексе движения DX (ADX)
        const sumDI = plusDI + minusDI;
        if (sumDI != 0) {
            this.ARRAY_DX[this.ARRAY_DX.Length - 1] = 100.0 * Math.abs((plusDI - minusDI) / sumDI);
        }

        const adx = IndicatorFunctions.CallMovingFunction(this.FMaMode, this.ARRAY_DX, this.FMaPeriod, 1);
        // Setting values
        this.SetValue(MODE_MAIN, 0, adx);
        this.SetValue(MODE_MINUSDI, 0, minusDI);
        this.SetValue(MODE_PLUSDI, 0, plusDI);
    }
}
