// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraPictureBoxTemplate } from '../../templates.js';
import { Control } from './Control';

export class TerceraPictureBox extends Control {
    constructor () { super(); }

    public override oninit (): void {
        super.oninit();
        this.on('imageLoaded', this.onImageLoaded);
        this.on('imageNotLoaded', this.onImageNotLoaded);
        this.observe('width height', this.onSizeChanged);
    }

    public onSizeChanged (): void {
        this.fire(TerceraPictureBoxEvents.SizeChanged);
    }

    public onImageLoaded (): void {
        void this.set('imageLoaded', true);

        if (this.get<boolean>('useCustomSize')) return;

        const img: HTMLImageElement = this.find('img');
        if (isNullOrUndefined(img)) return;

        void this.set({
            width: img.naturalWidth,
            height: img.naturalHeight
        });
    }

    public onImageNotLoaded (): void {
        void this.set('imageLoaded', false);
    }
}

export enum TerceraPictureBoxEvents {
    SizeChanged = 'sizeChanged'
}

Control.extendWith(TerceraPictureBox, {
    template: TerceraPictureBoxTemplate,
    data: function () {
        return {
            imageUrl: '',
            useCustomSize: true,
            imageLoaded: false,
            component_style: 'js-picture-box'
        };
    }
});
