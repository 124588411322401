// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { MessageProcessorBase } from './MessageProcessorBase';
import { RefreshTokenMesage } from '../Messages/RefreshTokenMesage';
import { type PFixFieldSet } from '../Fields/PFixFieldSet';

export class RefreshTokenProcessor extends MessageProcessorBase {
    public decoder: any;

    constructor (parentDecoder?) {
        super();

        this.decoder = parentDecoder;
    }

    public override decode (fieldSet: PFixFieldSet): RefreshTokenMesage[] {
        const message = new RefreshTokenMesage(fieldSet);
        return [message];
    }
}
