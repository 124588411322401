// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { Color } from '../../../Commons/Graphics';
import { ThemeManager } from '@front/controls/misc/ThemeManager';
import { EventType, EventSource, TradingEventType } from './EventConstants';
import { EventDescriptionItem } from './EventDescriptionItem';
import { type Account } from '../Account';
import { type ProductType } from '@shared/utils/Instruments/ProductType';
import { DataCache } from '../../DataCache';

export class Event {
    public Id: number;
    public userId: string | null;
    public Account: Account | null;
    public Action: any;
    public EventType: EventType;
    public Source: EventSource;
    public DateTime: any;
    public InstrumentTradableID: number;
    public RouteID: number;
    public ProductType: ProductType | null;
    public Roundtrip: any;
    public DescriptionItems: EventDescriptionItem[] = [];
    public BusinessRejectCode: number;
    public timestamp: number;

    constructor (params) {
        this.Account = params.Account ?? '';
        this.Action = params.Action ?? '';
        this.EventType = params.EventType ?? EventType.None;
        this.Source = params.EventSource ?? EventSource.None;
        this.DateTime = params.DateTime ?? DateTimeUtils.DateTimeUtcNow();
        this.timestamp = this.DateTime.getTime();
        this.Id = Event.GeneratedId++;
        this.InstrumentTradableID = params.Instrument?.InstrumentTradableID ?? null;
        this.RouteID = params.Instrument?.Route ?? null;
        this.ProductType = params.ProductType ?? null;
        this.Roundtrip = params.Roundtrip ?? 0;
        this.BusinessRejectCode = params.BusinessRejectCode ?? -1;

        if (Array.isArray(params.Description)) { // events from indexedDB
            this.DescriptionItems = params.Description.map(item => new EventDescriptionItem(item.Header, item.Value));
        } else {
            this.ParseEventDescriptionItems(params.Description);
        }
    }

    public static GeneratedId = 0;

    public ParseEventDescriptionItems (msg): void {
        if (!msg) return;

        this.DescriptionItems = [];

        if (this.EventType === EventType.Comment) {
            this.DescriptionItems.push(new EventDescriptionItem(msg, ''));
            return;
        }

        if (this.EventType === EventType.Trading && this.Source === EventSource.Refused) // Alert Event Msg Parsing
        {
            this.DescriptionItems.push(new EventDescriptionItem('Refused', msg.split('Error:')[1].split(';')[0]));
            return;
        }

        const ins = DataCache.getInstrumentByTradable_ID(this.InstrumentTradableID, this.RouteID);
        const hideRoute = ins ? ins.isHideRouteMode : false;

        if (typeof msg === 'string') {
            const splits = msg.split(';');
            const len = splits.length;
            for (let i = 0; i < len; i++) {
                const split = splits[i];
                if (!split) continue;

                const item = new EventDescriptionItem();

                const headerValueArr = split.split(':');

                item.Header = headerValueArr[0];
                item.Value = headerValueArr[1] || '';
                for (let j = 2; j < headerValueArr.length; j++) { item.Value += ':' + headerValueArr[j]; }

                if (item.Header === 'Route' && hideRoute) { continue; }

                // Тики в строку
                switch (item.Header) {
                case 'Time':
                case 'DateTime':
                case 'SettleDate':
                    const millis = parseFloat(item.Value);
                    item.Value = new Date(isNaN(millis) ? item.Value : millis);
                    break;
                }

                this.DescriptionItems.push(item);
            }
        }
    }

    public GetFormattedDescription (): string {
        let resultStr = '';

        const descriptionItems = this.DescriptionItems;
        const len = descriptionItems.length;
        for (let i = 0; i < len; i++) {
            const descriptionItem = descriptionItems[i];

            const localizedHeader = descriptionItem.Header;
            const localizedValue =
            descriptionItem.Value
                ? descriptionItem.Value.toString()
                : '';

            if (localizedValue) {
                resultStr += localizedHeader + ':' + localizedValue + '; ';
            } else {
                resultStr += localizedHeader;
            }
        }

        return resultStr;
    }

    public GetColorRow (eventColoring): any {
        let fore = Color.Empty;
        let back = Color.Empty;

        const tm = ThemeManager.CurrentTheme;

        switch (this.GetTradingEventType()) {
        case TradingEventType.MarginCall:
            back = eventColoring ? eventColoring.MarginCallBackColor : tm.EventLog_MarginCallBackColor;
            fore = eventColoring ? eventColoring.MarginCallForeColor : tm.EventLog_MarginCallForeColor;
            break;

        case TradingEventType.Reject:
            back = eventColoring ? eventColoring.RefusedBackColor : tm.EventLog_RefusedBackColor;
            fore = eventColoring ? eventColoring.RefusedForeColor : tm.EventLog_RefusedForeColor;
            break;

        case TradingEventType.Fill:
            back = eventColoring ? eventColoring.FilledBackColor : tm.EventLog_FilledBackColor;
            fore = eventColoring ? eventColoring.FilledForeColor : tm.EventLog_FilledForeColor;
            break;

        case TradingEventType.Create:
            back = eventColoring ? eventColoring.CreateBackColor : tm.EventLog_CreateBackColor;
            fore = eventColoring ? eventColoring.CreateForeColor : tm.EventLog_CreateForeColor;
            break;

        case TradingEventType.Modify:
            back = eventColoring ? eventColoring.ModifyBackColor : tm.EventLog_ModifyBackColor;
            fore = eventColoring ? eventColoring.ModifyForeColor : tm.EventLog_ModifyForeColor;
            break;

        case TradingEventType.Remove:
            back = eventColoring ? eventColoring.RemoveBackColor : tm.EventLog_RemoveBackColor;
            fore = eventColoring ? eventColoring.RemoveForeColor : tm.EventLog_RemoveForeColor;
            break;

        case TradingEventType.Request:
            back = eventColoring ? eventColoring.RequestBackColor : tm.EventLog_RequestBackColor;
            fore = eventColoring ? eventColoring.RequestForeColor : tm.EventLog_RequestForeColor;
            break;
        }

        return { ForeColor: fore, BackColor: back };
    }

    public GetTradingEventType (): TradingEventType {
        const action = this.Action.toLowerCase();

        if (this.Source === EventSource.MarginCall) {
            return TradingEventType.MarginCall;
        } else if (action.indexOf('request') !== -1) {
            return TradingEventType.Request;
        } else if (
            this.Source === EventSource.Refused ||
        action.indexOf('refuse') !== -1 ||
        action.indexOf('reject') !== -1 ||
        action.indexOf('trading disabled') !== -1) {
            return TradingEventType.Reject;
        } else if (action.indexOf('fill') !== -1) {
            return TradingEventType.Fill;
        } else if (action.indexOf('create') !== -1) {
            return TradingEventType.Create;
        } else if (action.indexOf('modif') !== -1) // modify/modified
        {
            return TradingEventType.Modify;
        } else if (action.indexOf('remove') !== -1) {
            return TradingEventType.Remove;
        }
        // все остальные трейдинговые - это реквесты, все вопросы по раскраске к андрею
        else if (this.EventType === EventType.Trading) {
            return TradingEventType.Request;
        }

        return TradingEventType.NotTrading;
    }
}
