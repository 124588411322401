// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { HistoryType } from '../../Utils/History/HistoryType';
import { QuotePricePickerTemplate } from '../../templates.js';
import { ContainerControl } from '../elements/ContainerControl';
import { ThemeManager } from '../misc/ThemeManager';
import { OperationType } from '../../Utils/Trading/OperationType';
import { type Instrument } from '../../Commons/cache/Instrument';
import { type Account } from '../../Commons/cache/Account';
import { ResourcesManager } from '../../Commons/properties/ResourcesManager';

export class QuotePricePicker extends ContainerControl {
    constructor () { super(); }

    public override getType (): string { return 'QuotePricePicker'; }

    public override oninit (): void {
        super.oninit();
        this.observe('instrument', this.onInstrumentChanged);
        this.observe('quote side account instrument', this.updatePrice);
        this.observe('price', this.onPriceChanged);
        this.observe('side', this.onSideChanged);
        this.on('click', this.pickPrice);
        ResourcesManager.onLocaleChange.Subscribe(this.localize, this);
        this.localize();
    }

    public override dispose (): void {
        ResourcesManager.onLocaleChange.UnSubscribe(this.localize, this);
        this.unsubscribeQuotes(this.get('instrument'));

        super.dispose();
    }

    public localize (): void {
        void this.set('label', Resources.getResource('panel.orders.CurrentPrice'));
    }

    public onInstrumentChanged (newInstument: Instrument, oldInstrument: Instrument): void {
        if (isNullOrUndefined(newInstument) || newInstument === oldInstrument) { return; }

        this.unsubscribeQuotes(oldInstrument);
        this.subscribeQuotes(newInstument);
    }

    public subscribeQuotes (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) { return; }

        // TODO. Refactor.
        this.newQuote(instrument.LastQuote);
        instrument.DataCache.FQuoteCache.addListener(instrument, this, HistoryType.QUOTE_LEVEL1);
    }

    public unsubscribeQuotes (instrument: Instrument): void {
        if (isNullOrUndefined(instrument)) {
            return;
        }

        // TODO. Refactor.
        instrument.DataCache.FQuoteCache.removeListener(instrument, this, HistoryType.QUOTE_LEVEL1);
        this.newQuote(null);
    }

    public newQuote (quote): void {
        if (quote && quote.Type === HistoryType.QUOTE_INSTRUMENT_DAY_BAR) {
            return;
        }

        void this.set('quote', quote);
    }

    public updatePrice (): void {
        const ins: Instrument = this.get('instrument');
        const acc: Account = this.get('account');
        const side = this.get('side');
        const quote = this.get('quote');

        if (isNullOrUndefined(quote) || isNullOrUndefined(ins) || isNullOrUndefined(acc)) {
            void this.set('price', null);
            return;
        }

        let price = null;
        if (side === OperationType.Buy) {
            price = quote.AskSpread_SP_Ins(ins.DataCache.GetSpreadPlan(acc), ins);
        } else if (side === OperationType.Sell) {
            price = quote.BidSpread_SP_Ins(ins.DataCache.GetSpreadPlan(acc), ins);
        }

        void this.set('price', price);
    }

    public onSideChanged (side: OperationType): void {
        const buy = side === OperationType.Buy;
        void this.set('sideColor', ThemeManager.CurrentTheme[buy ? 'SellColor' : 'BuyColor']);
    }

    public onPriceChanged (price: number): void {
        const ins: Instrument = this.get('instrument');

        void this.set(
            'fPrice',
            !isNullOrUndefined(ins) && price !== null
                ? ins.formatPrice(price)
                : Resources.getResource('general.N_A'));
    }

    public pickPrice (): void {
        this.fire('pickPrice', this.get('price'));
    }
}

ContainerControl.extendWith(QuotePricePicker, {
    template: QuotePricePickerTemplate,
    data: function () {
        return {
            label: '',

            account: null,
            instrument: null,
            side: null,
            quote: null,

            price: null,

            fPrice: '',
            sideColor: ''
        };
    }
});
