// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { Rectangle } from '@shared/commons/Geometry';
import { KeyCode } from '@shared/commons/KeyEventProcessor';
import { MathUtils } from '@shared/utils/MathUtils';
import { UpDownType, type Intervals } from '@shared/utils/Instruments/Intervals';
import { popupErrorHandler, terceraNumericHandler } from '@shared/utils/AppHandlers';
import { TerceraNumericTemplate } from '../../templates';
import { Control, ControlEvents } from './Control';
import { CustomEvent } from '@shared/utils/CustomEvents';
import { ScrollUtils } from '../UtilsClasses/ScrollUtils';
import { ControlsTypes } from '../UtilsClasses/FactoryConstants';

import $ from 'jquery';
import { TradingNumericErrorChecker } from '@shared/commons/Trading/TradingNumericErrorChecker';
import { HtmlScroll } from '@shared/commons/HtmlScroll';

// TODO. Refactor.
export class TerceraNumeric extends Control {
    public static readonly NUMERIC_MAXVALUE = 99999999;
    public static readonly OnHasErrorsChanged = new CustomEvent();
    public static decimalseparator: string = '.';
    public static commaseparator: string = ',';

    public currentMoveType: UpDownType = null;
    public regExp: RegExp;
    public requiredInterval: any; // Интервал для повторов valueUpDownChangeProcess
    public isRequiredIntervalStart: boolean; // запущен ли интервал
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraNumeric; }

    public override oninit (): void {
        super.oninit();

        this.updateRegExp(this.get('allowMinus'));

        this.currentMoveType = UpDownType.None;

        this.on('focus', this.private_Focus);
        this.on('mousewheel', this.private_MouseWheel);
        this.on('keyDown', this.private_KeyDown);
        this.on('keyUp', this.private_KeyUp);
        this.on(ControlEvents.LostFocus, this.onLostFocus);

        this.observe('visible', this.onVisibleChanged);
        // ! надо сохранить числовое значение и текстовое паралельно !
        this.observe('value', this.updateNumeric);
        this.observe('formattedValue', this.onFormattedValueChanged, { init: false });
        this.observe('minValue maxValue customError', this.checkErrors, { init: false });
        this.observe('decimalPrecision', this.onDecimalPrecisionChanged, { init: false });
        this.observe('allowMinus', () => { this.updateRegExp(this.get('allowMinus')); }, { init: false });

        this.observe('enabled errorText', this.onNeedUpdateValidationStateInfo, { init: false });
        this.observe('hasError', function (newValue) { TerceraNumeric.OnHasErrorsChanged.Raise(this, newValue); }, { init: false });

        this.observe('DynPropertyData', this.onDynPropertyDataChanged, { init: false });

        this.setInitialValue();
    }

    public setInitialValue (): void {
        const initialValue = this.getValue() ?? this.get('defaultValue');
        void this.set('value', initialValue);
    }

    public onDynPropertyDataChanged (newValue, oldValue): void {
        if (!newValue) {
            return;
        }

        void this.set({
            value: newValue.value,
            min: newValue.minimalValue,
            max: newValue.maximalValue,
            step: newValue.increment,
            numericPrecision: newValue.decimalPlaces,
            incrementCoefficient: 1,
            validationStateInfo: 1
        });
    }

    public private_arrowMousedown (sender, isUp: boolean): void {
        if (!this.get<boolean>('enabled')) return;

        this.loopValueChange(isUp, true);
    }

    public private_arrowMouseup (sender, isUp: boolean): void {
        if (!this.get<boolean>('enabled')) return;

        this.loopValueChange(isUp, false);
    }

    public private_arrowMouseout (sender, isUp: boolean): void {
        if (!this.get<boolean>('enabled')) return;

        this.loopValueChange(isUp, false);
    }

    public override onMouseDown (event): void {
        super.onMouseDown(event);
        this.fire('TerceraNumericClicked'); // #86098
    }

    // TODO. Ugly.
    public processEnterKeyDown (): void {
        this.updateNumeric(this.digitValue(this.get('formattedValue')));

        if (!this.get<boolean>('hasError') || !this.get<boolean>('tradingNumeric')) {
            return;
        }

        const minValue = this.get('minValue');
        const maxValue = this.get('maxValue');
        const value = this.get('value');

        let newValue = value;

        if (value > maxValue) {
            newValue = maxValue;
        } else if (value < minValue) {
            newValue = minValue;
        } else {
        // TODO. Duplicate of TryParseValue.
            const interval = this.GetInterval(newValue, true);
            let step = 0;
            let stepPrecision = 0;
            if (!isNullOrUndefined(interval)) {
                step = interval.Increment;
                stepPrecision = interval.DecimalPlaces;
            } else {
                step = this.get('step');
                stepPrecision = MathUtils.getPrecision(step);
            }

            if (step && !MathUtils.isValueMultipleToStep(newValue, step, stepPrecision)) {
                newValue = parseFloat(MathUtils.RoundToIncrement(newValue, step).toFixed(stepPrecision));
                // TODO. Ugly. For a case when minValue < step.
                if (newValue < step) {
                    newValue = step;
                }
            }
        }

        void this.set('value', newValue);
    }

    public private_KeyDown (event): void {
        if (!this.get<boolean>('enabled')) return;

        if (event.original.keyCode === KeyCode.UP) {
            this.loopValueChange(true, true);
        }
        if (event.original.keyCode === KeyCode.DOWN) {
            this.loopValueChange(false, true);
        }

        if (event.original.keyCode === KeyCode.ENTER) {
            this.processEnterKeyDown();
        }
    }

    public private_KeyUp (event): void {
        if (!this.get<boolean>('enabled')) return;

        if (event.original.keyCode === KeyCode.UP) {
            this.loopValueChange(true, false);
        }
        if (event.original.keyCode === KeyCode.DOWN) {
            this.loopValueChange(false, false);
        }
    }

    public private_KeyPress (sender, event): boolean {
        if (!this.get<boolean>('enabled')) return;

        const result = this.validateNumericValue(event);
        if (!result) { // отменить  изменение и всплытие события если символы недопустимы!!!
            event.stopPropagation();
            event.preventDefault();
            return false;
        }
    }

    public private_Focus (e): void {
        if (!this.get<boolean>('enabled') || this.get<boolean>('focused')) return;

        if (this.get<boolean>('tradingNumeric')) {
            if (this.get<boolean>('hasError')) {
                this.showPopupError(this.get('errorText'));
            }
        }

        // Cross-browser hack for the different "input.select()" behaviours after focusing.
        // https://www.impressivewebs.com/input-select-correct-behaviour/
        const inputEl = e.node;
        // setTimeout(function () { inputEl.select(); }, 0);
        inputEl.select();
    }

    public private_MouseWheel (context): boolean {
        const event = context.event;
        if ((!this.get<boolean>('enabled') || !this.get<boolean>('focused') || this.find('input') !== document.activeElement) && !this.get<boolean>('onlyMouseWheel')) {
            return;
        }

        const isUp = ScrollUtils.IsScrollUp(event.deltaY);
        this.currentMoveType = isUp ? UpDownType.Up : UpDownType.Down;
        this.valueUpDownChangeProcess(isUp);

        return false;
    }

    public override setFocus (forceEnable: boolean = false): void {
        super.setFocus();

        if (forceEnable) {
            void this.set('enabled', true);
        }

        if (!this.get<boolean>('enabled')) {
            return;
        }

        const inputEl = this.find('input');
        if (!isNullOrUndefined(inputEl)) {
            inputEl.focus();
        }
    }

    public onLostFocus (): void {
        if (this.isRequiredIntervalStart) {
            clearInterval(this.requiredInterval);
            this.isRequiredIntervalStart = false;
        }

        if (this.get<boolean>('tradingNumeric')) {
            if (popupErrorHandler.isShowed()) { // #85040
                const strValue: string = this.get('formattedValue');
                if (strValue !== '') {
                    const step = this.get('step');
                    const minValue = this.get('minValue');
                    const maxValue = this.get('maxValue');
                    let newValue = parseFloat(strValue);
                    const stepPrecision = this.get('decimalPrecision');
                    if (isNaN(newValue)) { // #92426
                        newValue = 0;
                    }
                    newValue = parseFloat(MathUtils.RoundToIncrement(newValue, step).toFixed(stepPrecision));
                    if (newValue < minValue) {
                        newValue = minValue;
                    }

                    if (newValue > maxValue) {
                        newValue = maxValue;
                    }

                    void this.set('value', newValue);
                    void this.set('formattedValue', newValue.toFixed(stepPrecision));
                }
            }
            popupErrorHandler.Hide(this);
            return;
        }

        this.updateNumeric(this.getValue());
    }

    public updateRegExp (allowMinus: boolean): void {
        let regStr = '0-9';
        if (allowMinus) {
            regStr += '\-';
        }

        this.regExp = new RegExp('[' + regStr + TerceraNumeric.decimalseparator + ']');
    }

    public loopValueChange (isUp: boolean, start: boolean): void {
        this.currentMoveType = isUp ? UpDownType.Up : UpDownType.Down;
        if (start) {
            if (!this.isRequiredIntervalStart) {
                this.isRequiredIntervalStart = true;
                this.requiredInterval = setInterval(this.valueUpDownChangeProcess.bind(this), 150, isUp);
            }
        } else {
            if (this.isRequiredIntervalStart) {
                clearInterval(this.requiredInterval);
                this.isRequiredIntervalStart = false;
            }
            return;
        }
        this.valueUpDownChangeProcess(isUp);
    }

    public valueUpDownChangeProcess (isUp: boolean): void {
        const oldVal = this.get('value');

        const interval = this.GetInterval(oldVal);
        let step = this.get('step');
        const incrementCoefficient = this.get('incrementCoefficient');
        let decimalPrecision = this.get('decimalPrecision');
        if (!isNullOrUndefined(interval)) {
            if (step !== interval.Increment) {
                void this.set({ step: interval.Increment });
                step = interval.Increment;
            }
            if (decimalPrecision !== interval.DecimalPlaces) {
                void this.set({ decimalPrecision: interval.DecimalPlaces });
                decimalPrecision = interval.DecimalPlaces;
            }
        }

        step *= incrementCoefficient;

        let newValue = 0;
        if (isUp) {
            newValue = (oldVal + step);
        } else {
            newValue = (oldVal - step);
        }

        const res = this.validateValue(
            oldVal,
            newValue,
            this.get('minValue'),
            this.get('maxValue'),
            decimalPrecision,
            true);

        void this.set({ value: res });
    }

    public override onteardown (): void {
        popupErrorHandler.Hide(this);
    }

    public onNeedUpdateValidationStateInfo (): void {
        this.TryParseValue(this.digitValue(this.get('formattedValue')));
        void this.set('validationStateInfo', {
            enabled: this.get('enabled'),
            errorText: this.get('errorText')
        });
    }

    public onVisibleChanged (newValue, oldValue): void {
        if (!newValue) {
            void this.set({ hasError: false, errorText: '' });
        } else {
            this.checkErrors();
        }
    }

    public checkErrors (): void {
        if (!this.get<boolean>('tradingNumeric')) {
            return;
        }

        const val = this.get('value');
        const fVal = this.get('formattedValue');

        if (fVal) {
            this.TryParseValue(fVal);
        } else {
            this.updateNumeric(val);
        }
    }

    public onDecimalPrecisionChanged (): void {
        this.onFormattedValueChanged(this.get('formattedValue'));
    }

    public updateNumeric (newValue: number): void {
        if (!isValidNumber(newValue)) {
            newValue = this.get('minValue');
        }

        if (!isValidNumber(newValue)) {
            return;
        }

        const interval = this.GetInterval(newValue, true);
        let decimalPrecision = this.get('decimalPrecision');
        if (!isNullOrUndefined(interval)) {
            const step = this.get('step');
            if (step !== interval.Increment) {
                void this.set({ step: interval.Increment });
            }
            if (decimalPrecision !== interval.DecimalPlaces) {
                void this.set({ decimalPrecision: interval.DecimalPlaces });
                decimalPrecision = interval.DecimalPlaces;
            }
        }
        const res = this.validateValue(newValue, newValue, this.get('minValue'), this.get('maxValue'), decimalPrecision);
        if (this.get<boolean>('tradingNumeric')) {
            this.TryParseValue(res);
        }

        const newFormatedValue = this.valueToString(res, interval);
        void this.set({ formattedValue: newFormatedValue, value: parseFloat(newFormatedValue) });

        const prop = this.get('DynPropertyData');
        if (prop.value) {
            prop.value = parseFloat(newFormatedValue);
            void this.set({ DynPropertyData: prop });
        }

        this.fire(TerceraNumericEvents.ValueChanged, this.getValue());
    }

    public onFormattedValueChanged (newValue: string): void {
        let isCorrect = newValue !== '-';

        if (newValue.includes(TerceraNumeric.commaseparator)) // #115398
        {
            newValue = newValue.replace(TerceraNumeric.commaseparator, TerceraNumeric.decimalseparator);
        }

        if (this.get<boolean>('tradingNumeric')) {
            isCorrect = this.TryParseValue(this.digitValue(newValue));
        }

        if (!isValidString(newValue) || !isCorrect) {
            return;
        }

        this.setValue(newValue);
        this.updateNumeric(this.getValue());
    }

    // TODO. Refactor. Ugly.
    public TryParseValue (inputValue): boolean {
        const textValue = inputValue || inputValue === 0 ? inputValue.toString() : '';
        let correctValue = false;
        let errorTextForNumeric = '';

        if (textValue === '') {
            errorTextForNumeric = Resources.getResource('UserControl.TerceraNumeric.ValueIsEmpty');
        } else {
            const parsedValue = this.digitValue(textValue);

            if (isNaN(parsedValue) || textValue.split(TerceraNumeric.decimalseparator).length > 2) {
                errorTextForNumeric = Resources.getResource('UserControl.TerceraNumeric.ValueNotNumber');
            } else {
                const interval = this.GetInterval(parsedValue, true);
                let step = 0;
                let stepPrecision = 0;
                if (!isNullOrUndefined(interval)) {
                    step = interval.Increment;
                    stepPrecision = interval.DecimalPlaces;
                } else {
                    step = this.get('step');
                    stepPrecision = MathUtils.getPrecision(step);
                }

                const minValue: number = this.get('minValue');
                const maxValue: number = this.get('maxValue');
                const formatMin: string = MathUtils.formatValueWithEps(minValue);
                const formatMax: string = MathUtils.formatValueWithEps(maxValue);

                if (parsedValue < minValue) {
                    const key = this.get('valueLessMinLocalKey');
                    const standartKey = key === 'UserControl.TerceraNumeric.ValueLessMin';
                    errorTextForNumeric = Resources.getResource(key);
                    if (standartKey) {
                        errorTextForNumeric += formatMin;
                    }
                } else if (parsedValue > maxValue) {
                // TODO think
                    const key = this.get('valueGreaterMaxLocalKey');
                    const standartKey = key === 'UserControl.TerceraNumeric.ValueGreaterMax';
                    errorTextForNumeric = Resources.getResource(key);
                    if (standartKey) {
                        errorTextForNumeric += formatMax;
                    }
                } else if (step && !MathUtils.isValueMultipleToStep(parsedValue, step, stepPrecision) && (!this.get<boolean>('SkipValidationForMaxValue') || parsedValue !== maxValue)) {
                    errorTextForNumeric = Resources.getResource('UserControl.TerceraNumeric.ValueNotMultiple') + MathUtils.formatValueWithEps(step);
                } else {
                    correctValue = true;
                }
            }
        }

        // TODO. Refactor. Ugly.
        const customError = this.get('customError');
        if (correctValue && customError) {
            errorTextForNumeric = customError.toString();
        }

        if (this.get<boolean>('enabled') && this.get<boolean>('visible')) {
            void this.set({
                hasError: !correctValue || customError,
                errorText: errorTextForNumeric
            });
        }

        // TODO. Ugly.
        if (correctValue && !customError) {
            popupErrorHandler.Hide(this);
        } else {
            this.showPopupError(errorTextForNumeric);
        }

        return correctValue;
    }

    public showPopupError (errorTextForNumeric: string): void {
        if (!this.get<boolean>('enabled') || !this.get<boolean>('focused') || !isValidString(errorTextForNumeric)) {
            return;
        }

        popupErrorHandler.Show(this, errorTextForNumeric, '');
    }

    public override getAbsoluteRectangle (): Rectangle {
        const $root = $(this.find('div'));
        const $window = $(window);
        const offset = $root.offset();

        return new Rectangle(
            offset.left - $window.scrollLeft(),
            offset.top - $window.scrollTop(),
            $root.width(),
            $root.height());
    }

    public validateValue (oldVal: number, newVal: number, min: number, max: number, prec: number, valueFromUpDown?: boolean): number {
        const tmpNewVal = parseFloat(newVal?.toString());
        const tmpMin = parseFloat(min?.toString());
        const tmpMax = parseFloat(max?.toString());

        let result = tmpNewVal;

        if (!this.get<boolean>('tradingNumeric') || valueFromUpDown) {
            if (min !== undefined && tmpMin > tmpNewVal) {
                result = tmpMin;
            } else if (max !== undefined && tmpMax < tmpNewVal) {
                result = tmpMax;
            }
        }

        if (isValidNumber(result)) {
            result = parseFloat(result.toFixed(prec));
            return result;
        } else {
            return oldVal;
        }
    }

    public digitValue (value: string): number | null {
        if (!isValidString(value)) return null;
        return parseFloat(value);
    }

    public valueToString (value: number, interval: Intervals): string {
        let prec = this.get('decimalPrecision');
        if (!isNullOrUndefined(interval)) {
            prec = interval.DecimalPlaces;
        }

        const floatValue = parseFloat(value.toString());
        const floatValueRound = floatValue.toFixed(prec);
        const res = floatValueRound.replace(/[.]/g, TerceraNumeric.decimalseparator);
        return res;// value.toLocaleString();
    }

    public validateNumericValue (event): boolean {
        const regexp = this.regExp;
        const e = event || window.event;
        const isIE = document.all;
        const code = isIE ? e.keyCode : e.which;
        if (code < 32 || e.ctrlKey || e.altKey) {
            return true;
        }

        const char = String.fromCharCode(code);

        return regexp.test(char);
    }

    public setValue (value): void {
        void this.set('value', this.digitValue(value));
    }

    public getValue (): number {
        return this.get('value');
    }

    public GetInterval (value, forDecimalPlaces: boolean = false): Intervals | null {
        const Intervals: Intervals[] = this.get('Intervals');

        if (!isValidArray(Intervals)) {
            return null;
        }

        for (let i = 0; i < Intervals.length; i++) {
            if (!forDecimalPlaces ? Intervals[i].ValueInIntervalUpDown(value, this.currentMoveType) : Intervals[i].ValueInInterval(value)) {
                return Intervals[i];
            }
        }

        if (value >= Intervals[Intervals.length - 1].RightValue) {
            return Intervals[Intervals.length - 1];
        }

        if (value <= Intervals[0].LeftValue) {
            return Intervals[0];
        }

        return null;
    }

    public static NumericHandlerInitialize (): void {
        terceraNumericHandler.OnHasErrorsChanged = TerceraNumeric.OnHasErrorsChanged;

        TradingNumericErrorChecker.subscribeToHandler();
    }
}

export enum TerceraNumericEvents {
    ValueChanged = 'ValueChanged'
};

Control.extendWith(TerceraNumeric, {
    template: TerceraNumericTemplate,
    data: function () {
        return {
            name: 'num',
            minValue: 1, // Минимальное значение
            maxValue: 100000, // Максимальное значение
            step: 1, // Шаг изменения

            // из-за внедрения интервалов возникла необходимость ввести коэффициент,
            // с помощью которого мы будем откладывать количество интервалов (количество тиков)
            incrementCoefficient: 1,

            decimalPrecision: 0, // Количество знаков после decimalseparator
            value: 0, // реальное значение
            formattedValue: '', // видимое значения
            defaultValue: 0.000001,
            showArrows: true,
            tradingNumeric: false,
            hasError: false,
            errorText: '',
            customError: null,
            validationStateInfo: null,
            SkipValidationForMaxValue: false,
            Intervals: [],
            zIndex: 0,
            allowMinus: true,
            valueLessMinLocalKey: 'UserControl.TerceraNumeric.ValueLessMin',
            valueGreaterMaxLocalKey: 'UserControl.TerceraNumeric.ValueGreaterMax',
            DynPropertyData: {},
            onlyMouseWheel: false,
            isPosAbsolute: false,
            enableOnSelect: false,
            rightAlignedText: '',
            rightAlignedTextAdditionalClass: ''
        };
    }
});
