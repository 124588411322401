// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '@shared/utils/Account/AccountFeature';
import { Account } from '../../../cache/Account';

export class TvAccountMarginData {
    public initialMarginReq: number = 0;
    public initialMarginReqPercent: number = 0;
    public maintMarginReq: number = 0;
    public maintMarginReqPercent: number = 0;
    public marginWarningLevel: number = 0;
    public marginAvailable: number = 0;
    public warningMargin: number = 0;
    public blockedForStocks: number = 0;
    public stocksOrdersReq: number = 0;

    public update (acc: Account): boolean {
        let isNeedUpdate = false;

        const initialMarginReq = Account.GetAccountFeature(AccountFeature.MarginUsed, acc, acc.assetBalanceDefault);
        if (initialMarginReq !== this.initialMarginReq) {
            this.initialMarginReq = initialMarginReq;
            isNeedUpdate = true;
        }

        const initialMarginReqPercent = Account.GetAccountFeature(AccountFeature.MarginUsedPercent, acc, acc.assetBalanceDefault);
        if (initialMarginReqPercent !== this.initialMarginReqPercent) {
            this.initialMarginReqPercent = initialMarginReqPercent;
            isNeedUpdate = true;
        }

        const maintMarginReq = Account.GetAccountFeature(AccountFeature.TotalMaintReq, acc, acc.assetBalanceDefault);
        if (maintMarginReq !== this.maintMarginReq) {
            this.maintMarginReq = maintMarginReq;
            isNeedUpdate = true;
        }

        const maintMarginReqPercent = Account.GetAccountFeature(AccountFeature.RiskLevelPercent, acc, acc.assetBalanceDefault);
        if (maintMarginReqPercent !== this.maintMarginReqPercent) {
            this.maintMarginReqPercent = maintMarginReqPercent;
            isNeedUpdate = true;
        }

        const marginWarningLevel = Account.GetAccountFeature(AccountFeature.MarginWarningLevel, acc, acc.assetBalanceDefault);
        if (marginWarningLevel !== this.marginWarningLevel) {
            this.marginWarningLevel = marginWarningLevel;
            isNeedUpdate = true;
        }

        const marginAvailable = Account.GetAccountFeature(AccountFeature.MarginAvailable, acc, acc.assetBalanceDefault);
        if (marginAvailable !== this.marginAvailable) {
            this.marginAvailable = marginAvailable;
            isNeedUpdate = true;
        }

        const warningMargin = Account.GetAccountFeature(AccountFeature.WarningMargin, acc, acc.assetBalanceDefault);
        if (warningMargin !== this.warningMargin) {
            this.warningMargin = warningMargin;
            isNeedUpdate = true;
        }

        const blockedForStocks = Account.GetAccountFeature(AccountFeature.BlockedForStocks, acc, acc.assetBalanceDefault);
        if (blockedForStocks !== this.blockedForStocks) {
            this.blockedForStocks = blockedForStocks;
            isNeedUpdate = true;
        }

        const stocksOrdersReq = Account.GetAccountFeature(AccountFeature.StocksOrdersReq, acc, acc.assetBalanceDefault);
        if (stocksOrdersReq !== this.stocksOrdersReq) {
            this.stocksOrdersReq = stocksOrdersReq;
            isNeedUpdate = true;
        }

        return isNeedUpdate;
    }
}
