// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingLookupTemplate } from '../../templates.js';
import { openLookupDropDownForm, closeLookupDropDownFormEvent } from '../../Screen/InvestingLookupDropDownForm';
import Ractive from 'ractive';

export class InvestingLookup extends Ractive {
    public skipOpenDropDownForm: boolean = false;
    public listener: any;

    public getType (): string { return 'InvestingLookup'; }

    public override oninit (): void {
        this.observe('text', this.populateText);
        this.on('clearText', this.clearText);
    }

    public blur (): void {
        this.skipOpenDropDownForm = false;
        void this.set('width', 340);
        closeLookupDropDownFormEvent.UnSubscribe(this.blur, this);
        void this.set('text', '');
        this.listener = null;
    }

    public clearText (): void {
        void this.set({ text: '', visibleClearBtn: false });
    }

    public populateText (newText: string, oldText): void {
        if (!newText?.length) {
            return;
        }

        if (!isNullOrUndefined(this.listener)) {
            this.listener(newText);
        }

        void this.set('visibleClearBtn', true);
    }

    public setListener (listener, register): void {
        if (register) {
            this.listener = listener;
        } else {
            this.listener = null;
        }
    }

    public private_BtnClick (ev): void {
        if (this.skipOpenDropDownForm) { return; }

        this.skipOpenDropDownForm = true;
        void this.set({ width: 735 });

        const posElement = ev.original.currentTarget.getBoundingClientRect();
        const height = posElement.height;
        const posX = posElement.left + window.pageXOffset;
        const posY = posElement.top + window.pageYOffset + height + 2;

        openLookupDropDownForm(posX, posY, (l, r) => { this.setListener(l, r); });
        closeLookupDropDownFormEvent.Subscribe(this.blur, this);
    }
}

Ractive.extendWith(InvestingLookup, {
    template: InvestingLookupTemplate,
    data: function () {
        return {
            width: 300,
            maxLength: 200, // максимальная длинна
            inputType: 'text',
            isReadOnly: false, // редактируемое поле
            placeholder: 'Search by instrument name or ticker', // текст внутри поля формы, который исчезает при получении фокуса
            text: '', // значение
            inputPatern: null, // патерн допустимых элементов g - флаг не использовать!  пример new RegExp('[1-9]')
            haveError: false,
            autofocus: false,
            visibleClearBtn: false
        };
    }
});
