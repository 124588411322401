// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { Rectangle } from '../../Commons/Geometry';
import { TerceraMenu } from '../elements/TerceraMenu';
import { BOTTOM_WINDOWS_MARGIN } from '../UtilsClasses/SizeConstants';
import { tooltipHandler } from '../../Utils/AppHandlers';
import Ractive from 'ractive';
import $ from 'jquery';
import { WDSettings } from '../settings/WDGeneralSettings';

class _RactiveTooltip extends Ractive {
    public readonly X_OFFSET = 5;
    public readonly Y_OFFSET = 20;// pointer height

    public lastMouseMoveX: number;
    public lastMouseMoveY: number;

    private tooltip: any;

    public getTooltip (): any {
        if (isNullOrUndefined(this.tooltip)) {
            this.tooltip = $('#tooltipContainer div');
        }

        return this.tooltip;
    }

    public showTooltip (text: string): void {
        text = Resources.getResource(text);
        text = text.replace('\n', '<br>');
        const tooltip = this.getTooltip();
        tooltip.html(text);
        const w = tooltip.outerWidth();
        const h = tooltip.outerHeight();
        const newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(this.lastMouseMoveX - RactiveTooltip.X_OFFSET, this.lastMouseMoveY + RactiveTooltip.Y_OFFSET, w, h), null);

        if (window.innerHeight - RactiveTooltip.Y_OFFSET > h + newCoord.newY + BOTTOM_WINDOWS_MARGIN) {
            tooltip.addClass('ractive-tooltip-upper');
        } else {
            tooltip.addClass('ractive-tooltip-lower');
            newCoord.newY = this.lastMouseMoveY - h - RactiveTooltip.Y_OFFSET;
        }

        tooltip.css({
            left: newCoord.newX,
            top: newCoord.newY
        });

        this.setTooltipVisibility(true);
    }

    public setTooltipVisibility (visible: boolean): void {
        const tooltip = this.getTooltip();
        tooltip.css('display', visible ? 'block' : 'none');
        if (!visible) {
            tooltip.removeClass('ractive-tooltip-upper');
            tooltip.removeClass('ractive-tooltip-lower');
        }
    }

    public onDocumentMouseMove (event): void {
        RactiveTooltip.lastMouseMoveX = event.clientX;
        RactiveTooltip.lastMouseMoveY = event.clientY;
    }

    public decorator (node, text: string): { teardown: () => void } {
        const $node = $(node);
        let timeout = null;
        const checkEnability = (): boolean => {
            const getRes = this.get();
            const enabled: boolean = getRes.enabled;
            const showDisabledToolTip: boolean = getRes.showDisabledToolTip;
            return enabled || showDisabledToolTip;
        };
        const mouseover = function (event): void {
            if (!checkEnability() ||
                event.currentTarget !== this ||
                !isValidString(text) ||
                !WDSettings.tooltips) {
                return;
            }

            timeout = setTimeout(function (text) {
                RactiveTooltip.showTooltip(text);
            }, 500, text);
        };

        const mouseout = function (): void {
            if (!isNullOrUndefined(timeout)) {
                clearTimeout(timeout);
            }
            RactiveTooltip.setTooltipVisibility(false);
        };

        $node.on('mouseenter', mouseover);
        $node.on('mouseleave', mouseout);
        $node.on('click', mouseout);

        return {
            teardown: function () {
                $node.off('mouseenter', mouseover);
                $node.off('mouseleave', mouseout);
            }
        };
    }

    public tooltipHandlerInitialize (): void {
        tooltipHandler.showTooltip = RactiveTooltip.showTooltip.bind(RactiveTooltip);
        tooltipHandler.setTooltipVisibility = RactiveTooltip.setTooltipVisibility.bind(RactiveTooltip);
    }
}

export const RactiveTooltip = new _RactiveTooltip();

document.addEventListener('mousemove', RactiveTooltip.onDocumentMouseMove);

Ractive.decorators.tooltip = RactiveTooltip.decorator;
