// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type HistoryMergerInputParams } from '../../../../Utils/History/HistoryMergerInputParams';
import { Periods } from '../../../../Utils/History/TFInfo';
import { HistoryMergerBasedByDay } from './HistoryMergerBasedByDay';
import { type DateTime } from 'luxon';

export class HistoryMergerDay extends HistoryMergerBasedByDay {
    constructor (inputParams: HistoryMergerInputParams) {
        super(inputParams);
    }

    protected override GetDaysInPeriod (dateTime: DateTime): number {
        return this.GetCount();
    }

    protected override IsStartPeriodDate (dateTime: DateTime): boolean {
        return true;
    };

    protected override GetBasePeriod (): number {
        return Periods.DAY;
    }
}
