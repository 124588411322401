// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Rectangle } from '../../Commons/Geometry';
import { type TerceraChartToolRenderer } from '../Renderers/Tools/TerceraChartToolRenderer';
import { TerceraChartWindowBase } from './TerceraChartWindowBase';

export class TerceraChartWindow extends TerceraChartWindowBase {
    // #region Properties

    public indicatorRendererSettings = { UseInAutoscale: true };

    public ToolsRenderer: TerceraChartToolRenderer;

    // #endregion
    public TerceraChartOverlayWindow: boolean;

    public prevScaleW: number = 0;

    public override OnLayout (info = null): void {
        //    //
        //    // надо рендерить плашки индюков и оверлеев?
        //    //

        const plateRenderer = this.GetMainThreadRenderer('TerceraChartPlateRenderer');

        if ((plateRenderer != null && !plateRenderer.IsEmpty()) || this.TerceraChartOverlayWindow) // this instanceof TerceraChartOverlayWindow медленный добавил флаг. сравнить на его присутствие намного быстрее, где нет this.TerceraChartOverlayWindow=undefined
        {
            this.HeaderVisible = true;
            this.PaddingTop = this.HeaderHeight + 1;
        } else {
            this.PaddingTop = 0;
            this.HeaderVisible = false;
        }

        this.PaddingRight = info == null ? this.prevScaleW : info.PreferredWidthScales;

        if (info != null) {
            this.PaddingLeft = info.PreferredWidthScalesLeft;
        }

        this.UpdateClientRectangle();

        const clientR = this.ClientRectangle;
        if (this.rightYScaleRenderer) {
            this.rightYScaleRenderer.Rectangle = new Rectangle(
                clientR.X + clientR.Width,
                clientR.Y - this.PaddingTop,
                this.PaddingRight,
                clientR.Height + this.PaddingTop);
        }

        if (this.leftYScaleRenderer != null && info != null) {
            if (this.IsMainWindow) {
                //
                // Показываем левую шкалу только, когда нет оверлеев но есть задвоенные индюки
                // (Если есть оверлеи - они показывают шкалу, поэтому встроенная не нужна)
                //
                if (!info.HasOverlays && info.HasDoubledIndicator) {
                    this.leftYScaleRenderer.Visible = true;
                    this.leftYScaleRenderer.Rectangle = new Rectangle(0, clientR.Y - this.PaddingTop, info.PreferredWidthScalesLeft, clientR.Height + this.PaddingTop);
                } else {
                    this.leftYScaleRenderer.Visible = false;
                }
            } else {
                if (info.HasDoubledIndicator || info.HasOverlays) {
                    this.leftYScaleRenderer.Visible = true;
                    this.leftYScaleRenderer.Rectangle = new Rectangle(0, clientR.Y - this.PaddingTop, info.PreferredWidthScalesLeft, clientR.Height + this.PaddingTop);
                } else {
                    this.leftYScaleRenderer.Visible = false;
                }
            }
        }

        if (plateRenderer != null && !plateRenderer.IsEmpty()) // обновляем ректангл, для всех окон место на коллапсер, разделитель рисуется в виндоу контейнере
        {
            let headerRect = new Rectangle();
            let collapserRect = new Rectangle();

            const res = this.GetWindowHeaderRectangle(headerRect, collapserRect);

            headerRect = res.WindowHeaderRectangle;
            collapserRect = res.collapserRectangle;

            plateRenderer.Rectangle = new Rectangle(headerRect.X + 1, headerRect.Y, headerRect.Width - collapserRect.Width - 1, this.HeaderHeight);
        }

        this.LayoutPaddingRenderers();

        if (info != null) {
            this.prevScaleW = info.PreferredWidthScales;
        }
    }

    public override Dispose (): void {
        super.Dispose();
        this.indicatorRendererSettings = null;
    }
}
