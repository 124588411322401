// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
export const en =
{
    'accountsLookup.ContextMenuItem': 'Accounts lookup',
    'accountDetailsWidget.DropdownButton': 'Account',
    'accountDetailsWidget.DropdownButton.Tooltip': 'Account menu',
    'additional.verification': 'Additional password verification required.',
    'AdditionalProperty.Message': 'Message',
    'AdditionalProperty.Position closed because the loss limit was reached': 'Position {0} will be closed because the loss limit was reached',
    'AdditionalProperty.enum_StopOutType_AutoClose': 'Auto close',
    'AdditionalProperty.enum_StopOutType_CloseAllByInstruments': 'Close all by instruments',
    'AdditionalProperty.enum_StopOutType_CloseAllPositions': 'Close all positions',
    'AdditionalProperty.enum_StopOutType_CloseLagestLoss': 'Close positions while not enough margin (Largest loss first)',
    'AdditionalProperty.enum_StopOutType_ClosePositionsByMarginExcess': 'Close positions by margin excess',
    'AdditionalProperty.enum_StopOutType_DoNotClosePositions': 'Do not close positions',
    'AdditionalProperty.enum_StopOutType_ExternalLossLimit': 'Daily loss limit',
    'AdditionalProperty.enum_StopOutType_FIFO': 'Close positions while not enough margin (FIFO)',
    'AdditionalProperty.enum_StopOutType_LargestFirst': 'Close positions while not enough margin (Largest first)',
    'AdditionalProperty.enum_StopOutType_LIFO': 'Close positions while not enough margin (LIFO)',
    'AdditionalProperty.User': 'User',
    'AdditionalProperty.Account': 'Account',
    'AdditionalProperty.Projected balance': 'Projected balance',
    'AdditionalProperty.Margin used': 'Margin used',
    'AdditionalProperty.Time': 'Date/Time',
    'AdditionalProperty.MarginCallMessage.Message': 'Please deposit your account',
    'AdditionalProperty.MarginCallReachedMessage.Message': '#hidden#',
    'AdditionalProperty.StopOutType': 'Stop out type',
    'AdditionalProperty.MarginCallMessage.header.MarginWarning': 'Margin warning',
    'AdditionalProperty.MarginCallMessage.header.MarginCallReached': 'Margin call reached',
    'AdditionalProperty.LackOfMargin': 'Lack of margin',
    'AdditionalProperty.ErrorPriceOutOfTradingLimits': 'Price is outside of the trading limits',
    'AdditionalProperty.Reverse': 'Reverse',
    Alligator: 'Alligator',
    'Average Directional Index': 'Average directional index',
    'Average True Range': 'Average true range',
    'Bollinger Band': 'Bollinger Band',
    'Bollinger Bands': 'Bollinger bands',
    'bonds.couponCycle.1m': '1 month',
    'bonds.couponCycle.1y': '1 year',
    'bonds.couponCycle.3m': '3 months',
    'bonds.couponCycle.6m': '6 months',
    'BrokerMessage.Cancel': 'Cancel',
    'BrokerMessage.OK': 'OK',
    'BrokerMessage.Text': 'Broker message',
    'BusinessRejectMessage.28': 'Incorrect current password',
    'BusinessRejectMessage.31': 'Incorrect new password: you cannot use the same password',
    'BusinessRejectMessage.201': 'New password contains prohibited symbols',
    'BusinessRejectMessage.202': 'Password cannot be the same as login',
    'BusinessRejectMessage.203': 'Please enter new password',
    'BusinessRejectMessage.221': "Password can't be changed: New password should have at least 1 upper case character, 1 lower case character and 1 number. Please try again.",
    'BusinessRejectMessage.222': "Password can't be changed: New password should have at least 1 upper case character, 1 lower case character, 1 number and contain 8 characters at least. Please try again.",
    'BusinessRejectMessage.223': "Password can't be changed: New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.",
    'BusinessRejectMessage.338': 'Trading unlock password not allowed by trading unlock logic.',
    'BusinessRejectMessage.339': 'Incorrect current trading password.',
    'BusinessRejectMessage.419': 'Unable to create alert. Alerts can only be created for accounts of the logged-in user.',
    'BusinessRejectMessage.432': 'Product request cannot be processed.',
    'BusinessRejectMessage.433': 'The total limit of alerts has been reached.',
    'BusinessRejectMessage.434': 'Alert cannot be created due to wrong node.',
    'BusinessRejectMessage.435': 'Bid/Ask prices are not available for the instrument.',
    'BusinessRejectMessage.436': 'Product {0} subscription failure: Unable to subscribe because there are no active accounts for charging on product.',
    'BusinessRejectMessage.437': 'Product {0} subscription failure: Not enough balance to pay fee.',
    'BusinessRejectMessage.446': 'Product is no longer relevant for use',
    'BusinessRejectMessage.452': 'Market order is forbidden due to no trades occurring during current day.',
    'BusinessRejectMessage.453': 'Order cannot be created due to negative price.',
    'BusinessRejectMessage.461': 'Reason for rejection: Nothing to change.',
    'BusinessRejectMessage.466': 'Limit price is too close to current Ask price',
    'BusinessRejectMessage.467': 'Limit price is too close to current Bid price',
    'BusinessRejectMessage.468': 'TP price is too close to the order price',
    'BusinessRejectMessage.469': 'Stop price is too close to current Ask price',
    'BusinessRejectMessage.470': 'Stop price is too close to current Bid price',
    'BusinessRejectMessage.471': 'SL price is too close to the order price',
    'BusinessRejectMessage.472': 'Limit price is too close to Stop price',
    'BusinessRejectMessage.475': 'Only In-the-money options can be exercised',
    'BusinessRejectMessage.483': 'Order with Waiting cancellation status cannot be modified',
    'BusinessRejectMessage.484': 'Order with Waiting cancellation status cannot be canceled',
    'reports.Reason for rejection: Nothing to change.': 'Reason for rejection: Nothing to change.',
    'Chande Momentum Oscillator': 'Chande Momentum oscillator',
    'chart.agregationType.Button': 'Aggregation types',
    'chart.goTo.Button': 'Go to',
    'chart.agregationType.Button.ToolTip': 'Use predefined aggregation types',
    'chart.AllowedPeriods.15M': '15m',
    'chart.AllowedPeriods.1D': '1D',
    'chart.AllowedPeriods.1H': '1H',
    'chart.AllowedPeriods.1M': '1m',
    'chart.AllowedPeriods.1S': '1S',
    'chart.AllowedPeriods.1T': '1 tick',
    'chart.AllowedPeriods.1W': '1W',
    'chart.AllowedPeriods.1Y': '1Y',
    'chart.AllowedPeriods.30M': '30m',
    'chart.AllowedPeriods.4H': '4H',
    'chart.AllowedPeriods.5M': '5m',
    'chart.AllowedPeriods.Month': '1M',
    'chart.AllowedPeriods.Range': 'Range',
    'chart.AllowedPeriods.Ticks': 'Ticks',
    'chart.ChartPanel.accountLookup.ToolTip': 'Select account',
    'chart.ChartPanel.symbolLookup.ToolTip': 'Select symbol',
    'chart.chartScroll.toBegin': 'To last quote',
    'chart.chartScroll.zoomInButton': 'Zoom in',
    'chart.chartScroll.zoomOutButton': 'Zoom out',
    'chart.ChartSymbolSelector.addIndicator.ButtonToolTip': 'Add indicator',
    'chart.ChartSymbolSelector.drawingButton': 'Drawings toolbar',
    'chart.ChartSymbolSelector.drawingButton.ToolTip': 'Show/Hide drawing toolbar',
    'chart.ChartSymbolSelector.mouseTradeButton': 'Mouse trading',
    'chart.ChartSymbolSelector.mouseTradeButton.ToolTip': 'Mouse trading',
    'chart.ChartSymbolSelector.oeButton': 'Chart Order entry',
    'chart.ChartSymbolSelector.oeButton.ToolTip': 'Chart Order entry',
    'chart.ChartSymbolSelector.OverlayTooltip': 'Add overlay',
    'chart.ChartSymbolSelector.ObjectManagerTooltip': 'Object manager',
    'Chart.chartWindow.auto': 'Auto',
    'Chart.chartWindow.collapsed': 'Collapsed',
    'Chart.chartWindow.manual': 'Manual',
    'chart.chartWindowManager.SetAuto': 'Switch to auto price scale',
    'chart.chartWindowManager.SetManual': 'Switch to manual price scale',
    'chart.head.addOverlay': 'Add overlay...',
    'chart.head.charttype': 'Style',
    'chart.head.line': 'Drawing tools',
    'chart.head.overlayRemoveAll': 'Remove all',
    'chart.head.overlays': 'Overlays',
    'chart.indicator': 'Indicator',
    'chart.indicatorAdd': 'Add indicator',
    'chart.indicatorRemoveAll': 'Remove all',
    'chart.indicators': 'Indicators',
    'chart.Infoline.days': 'days',
    'chart.Infoline.hours': 'hours',
    'chart.Infoline.min': 'min',
    'chart.Infoline.price': 'price',
    'chart.Infoline.sec': 'sec',
    'chart.Infoline.time': 'time',
    'chart.infoWindow.Items.Ask': 'Ask:',
    'chart.infoWindow.Items.Bars': '#Bars:',
    'chart.infoWindow.Items.Bid': 'Bid:',
    'chart.infoWindow.Items.Change': 'Change,%',
    'chart.infoWindow.Items.Close': 'Close:',
    'chart.infoWindow.Items.Date': 'Date:',
    'chart.infoWindow.Items.High': 'High:',
    'chart.infoWindow.Items.Low': 'Low:',
    'chart.infoWindow.Items.Open': 'Open:',
    'chart.infoWindow.Items.Ticks': 'Ticks:',
    'chart.infoWindow.Items.Time': 'Time:',
    'chart.infoWindow.Items.Volume': 'Volume:',
    'chart.infoWindow.Items.PortfolioGrowth': 'Portfolio balance:',
    'chart.instrumentsLookup': 'Symbols lookup',
    'chart.layerManager': 'Settings...',
    'chart.loading': 'Loading...',
    'chart.loadingData': 'Loading data for',
    'chart.mainlayer.gridPrice': 'Grid price',
    'chart.mainlayer.gridTime': 'Grid time',
    'chart.mainlayer.volume': 'Volume bars',
    'chart.menu.cancelByAccount': 'Cancel all by account',
    'chart.menu.cancelByInstrument': 'Cancel all by symbol',
    'chart.menu.cancelOrder': 'Cancel order',
    'chart.menu.close': 'Close position',
    'chart.menu.closeall': 'Close all positions...',
    'chart.menu.InfoWindowDock': 'Dock',
    'chart.menu.InfoWindowHide': 'Hide',
    'chart.menu.InfoWindowHidden': 'Hidden',
    'chart.menu.InfoWindowSeparateWindow': 'Separate Window',
    'chart.menu.InfoWindowAttachedToCursor': 'Attached To Cursor',
    'chart.menu.InfoWindowSettings': 'Settings',
    'chart.menu.InfoWindowShortMode': 'Short mode',
    'chart.menu.modify': 'Modify',
    'chart.menu.PlateIndicatorDublicate': 'Duplicate Indicator',
    'chart.menu.PlateIndicatorRemove': 'Close',
    'chart.menu.PlateIndicatorSettings': 'Settings',
    'chart.menu.PlateIndicatorVisible': 'Visible',
    'chart.menu.PlateOverlay.Overlay': 'Overlay',
    'chart.menu.PlateOverlayRemove': 'Close',
    'chart.menu.PlateOverlaySettings': 'Settings',
    'chart.menu.PlateOverlayVisible': 'Visible',
    'chart.menu.rollback': 'Rollback position',
    'chart.noAvailableData': 'No available data for',
    'chart.NoData': 'No data',
    'chart.priceScaleContextMenu.AutoScale': 'Auto',
    'chart.priceScaleContextMenu.FitAlerts': 'Fit alerts',
    'chart.priceScaleContextMenu.FitDay': 'Fit day High/Low',
    'chart.priceScaleContextMenu.FitDrawings': 'Fit drawings',
    'chart.priceScaleContextMenu.FitIndicators': 'Fit indicators',
    'chart.priceScaleContextMenu.FitOrdersPositions': 'Fit orders/positions',
    'chart.priceScaleContextMenu.ManualScale': 'Manual',
    'chart.refresh': 'Refresh',
    'chart.Remove': 'Remove',
    'chart.shortPeriodName.m': 'm',
    'chart.shortPeriodName.ticks': 'ticks',
    'chart.ShowHighLow.Close': 'Close',
    'chart.ShowHighLow.High': 'High',
    'chart.ShowHighLow.Low': 'Low',
    'chart.StyleButton.ToolTip': 'Style',
    'chart.templates.tooltip': 'Templates',
    'chart.templates.visibility': '#not hidden#',
    'chart.tic.short.15min.name': '15m',
    'chart.tic.short.1day.name': '1D',
    'chart.tic.short.1hour.name': '1H',
    'chart.tic.short.1min.name': '1m',
    'chart.tic.short.1tic.name': 'Tick',
    'chart.tic.short.30min.name': '30m',
    'chart.tic.short.4hour.name': '4H',
    'chart.tic.short.5min.name': '5m',
    'chart.tic.short.month.name': '1M',
    'chart.tic.short.range.name': 'Range',
    'chart.tic.short.second.name': 's',
    'chart.tic.short.week.name': '1W',
    'chart.tic.short.year.name': '1Y',
    'chart.info.high': 'High',
    'chart.info.low': 'Low',
    'chart.info.volume': 'Volume',
    'chart.info.ticks': 'Ticks',
    'chart.info.open': 'Open',
    'chart.info.change': 'Change',
    'chart.info.prevClose': 'Prev. close',
    'chart.timeframeselector.rangeButton': 'Data period',
    'chart.timeframeselector.rangeButton.ToolTip': 'Select period of data displaying',
    'chart.timeframeselector.time': 'Time',
    'chart.timeScaleContextMenu.ShowAllSessions': 'Show extended sessions',
    'chart.timeScaleContextMenu.ShowDaySeparator': 'Show days separator',
    'chart.timeScaleContextMenu.ShowEmptyBars': 'Show holes in the history',
    'chart.timeScaleContextMenu.ShowMonthSeparator': 'Show months separator',
    'chart.timeScaleContextMenu.ShowTimePeriodsSeparator': 'Show time periods separators',
    'chart.timeScaleContextMenu.ShowWeeksSeparator': 'Show weeks separator',
    'chart.timeScaleContextMenu.ShowYearSeparator': 'Show years separator',
    'chart.tool.bar': 'Bar',
    'chart.tool.candle': 'Candle',
    'chart.tool.clone': 'Clone',
    'chart.tool.Dash': 'Dash',
    'chart.tool.delete': 'Remove',
    'chart.tool.deleteAll': 'Remove all',
    'chart.tool.dot': 'Dots',
    'chart.tool.dotline': 'Dotted line',
    'chart.tool.DottedLine': 'Dotted line',
    'chart.tool.forest': 'Histogram',
    'chart.tool.IsoDot': 'Iso Dot',
    'chart.tool.line': 'Line',
    'chart.tool.properties': 'Settings',
    'chart.tool.ShapedLine': 'Shaped line',
    'chart.tool.solid': 'Area',
    'chart.tools.ABCPatern': 'ABC pattern',
    'chart.tools.AndrewPitchfork': "Andrew's pitchfork",
    'chart.tools.backcolor': 'Color of background',
    'chart.tools.ButterflyGartley': 'Gartley butterfly',
    'chart.tools.Circle': 'Circle',
    'chart.tools.default': 'Pointer',
    'chart.tools.ElliottImpulse': 'Elliott Impulse Wave (12345)',
    'chart.tools.ElliottCorrection': 'Elliott Correction Wave (ABC)',
    'chart.tools.ElliottTriangle': 'Elliott Triangle Wave (ABCDE)',
    'chart.tools.ElliottTripleCombo': 'Elliott Triple Combo Wave (WXYXZ)',
    'chart.tools.ElliottDoubleCombo': 'Elliott Double Combo Wave (WXY)',
    'chart.tools.Ellipse': 'Ellipse',
    'chart.tools.eraser': 'Eraser',
    'chart.tools.FibArc': 'Fibonacci arc',
    'chart.tools.FibEllipses': 'Fibonacci ellipses',
    'chart.tools.FibExp': 'Fibonacci expansion',
    'chart.tools.FibFan': 'Fibonacci fans',
    'chart.tools.FibPhiChannel': 'Fibonacci phi-channel',
    'chart.tools.Fibretr': 'Fibonacci retracement',
    'chart.tools.FibSpiral': 'Fibonacci spiral',
    'chart.tools.FibTga': 'Fibonacci time goal analysis',
    'chart.tools.FibTz': 'Fibonacci time zone',
    'chart.tools.GannFan': 'Gann fan',
    'chart.tools.GannGrid': 'Gann grid',
    'chart.tools.GannLine': 'Gann line',
    'chart.tools.horizontal': 'Horizontal line',
    'chart.tools.infoline': 'Ruler',
    'chart.tools.arrowLabel': 'Arrow',
    'chart.tools.commentsLabel': 'Comments',
    'chart.tools.priceLabel': 'Price label',
    'chart.tools.Line': 'Line',
    'chart.tools.mirrored': 'Activate mirror view',
    'chart.tools.points': 'Point count',
    'chart.tools.Polygon': 'Polygon',
    'chart.tools.PPZ': 'PPZ (Price pivot zone)',
    'chart.tools.PriceChannel': 'Price channel',
    'chart.tools.Rectangle': 'Rectangle',
    'chart.tools.RegressionChannel': 'Regression Channel',
    'chart.tools.ScalingByRect': 'Scaling by rectangle',
    'chart.tools.selection': 'Selection',
    'chart.tools.snapToCandle': 'Snap to candle',
    'chart.tools.StayDrawingMode': 'Stay in drawing mode',
    'chart.tools.symbol': 'Mark',
    'chart.tools.text': 'Comments',
    'chart.tools.TopBottomPattern': 'Top Bottom Pattern',
    'chart.tools.Triangle': 'Triangle',
    'chart.tools.vertical': 'Vertical line',
    'chart.tools.ABCPatern.deleteByType': 'All ABC patterns',
    'chart.tools.AndrewPitchfork.deleteByType': "All Andrew's pitchforks",
    'chart.tools.ButterflyGartley.deleteByType': "All Gartley's butterflies",
    'chart.tools.Circle.deleteByType': 'All circles',
    'chart.tools.Ellipse.deleteByType': 'All ellipses',
    'chart.tools.ElliottImpulse.deleteByType': 'All Elliott Impulse Wave (12345)',
    'chart.tools.ElliottCorrection.deleteByType': 'All Elliott Correction Wave (ABC)',
    'chart.tools.ElliottTriangle.deleteByType': 'All Elliott Triangle Wave (ABCDE)',
    'chart.tools.ElliottTripleCombo.deleteByType': 'All Elliott Triple Combo Wave (WXYXZ)',
    'chart.tools.ElliottDoubleCombo.deleteByType': 'All Elliott Double Combo Wave (WXY)',
    'chart.tools.FibArc.deleteByType': 'All Fibonacci arcs',
    'chart.tools.FibEllipses.deleteByType': 'All Fibonacci ellipses',
    'chart.tools.FibExp.deleteByType': 'All Fibonacci expansions',
    'chart.tools.FibFan.deleteByType': 'All Fibonacci fans',
    'chart.tools.FibPhiChannel.deleteByType': 'All Fibonacci phi-channels',
    'chart.tools.Fibretr.deleteByType': 'All Fibonacci retracements',
    'chart.tools.FibSpiral.deleteByType': 'All Fibonacci spirals',
    'chart.tools.FibTga.deleteByType': 'All Fibonacci time goal analysises',
    'chart.tools.FibTz.deleteByType': 'All Fibonacci time zones',
    'chart.tools.GannFan.deleteByType': 'All Gann fans',
    'chart.tools.GannGrid.deleteByType': 'All Gann grids',
    'chart.tools.GannLine.deleteByType': 'All Gann lines',
    'chart.tools.horizontal.deleteByType': 'All horizontal lines',
    'chart.tools.infoline.deleteByType': 'All Rulers',
    'chart.tools.arrowLabel.deleteByType': 'All Arrows',
    'chart.tools.commentsLabel.deleteByType': 'All Comments',
    'chart.tools.priceLabel.deleteByType': 'All Price labels',
    'chart.tools.Line.deleteByType': 'All lines',
    'chart.tools.Polygon.deleteByType': 'All polygons',
    'chart.tools.PPZ.deleteByType': 'All PPZ',
    'chart.tools.PriceChannel.deleteByType': 'All price channels',
    'chart.tools.Rectangle.deleteByType': 'All rectangles',
    'chart.tools.RegressionChanel.deleteByType': 'All Regression channels',
    'chart.tools.symbol.deleteByType': 'All symbols',
    'chart.tools.text.deleteByType': 'All comments',
    'chart.tools.TopBottomPattern.deleteByType': 'All Top bottom patterns',
    'chart.tools.Triangle.deleteByType': 'All triangles',
    'chart.tools.vertical.deleteByType': 'All vertical lines',
    'chart.tools.VolumeProfile.deleteByType': 'All custom volume profiles',
    'chart.tools.FibonacciTools': 'Fibonacci',
    'chart.tools.GannTools': 'Gann',
    'chart.tools.GeometryTools': 'Geometry',
    'chart.tools.ElliottTools': 'Elliott Wave',
    'chart.tools.TrendTools': 'Trend',
    'chart.tools.SecondaryTools': 'Secondary tools',
    'chart.view': 'View',
    'chart.view.analyseGroupMenu': 'Analysis tools',
    'chart.view.analysetoolbar': 'Analysis tools',
    'chart.view.barsToRight': 'Bars to right...',
    'chart.view.colors': 'Settings',
    'chart.view.dataInfo': 'Info window',
    'chart.view.Day_HighLow': 'Day high/low',
    'chart.view.drawingtoolbar': 'Drawing toolbar',
    'chart.view.font': 'Axis font',
    'chart.view.landscapequestion': 'Use landscape paper arrangement?',
    'chart.view.merge': 'Continuous lines',
    'chart.view.orderEntry': 'Chart Order entry',
    'chart.view.periods': 'Intervals...',
    'chart.view.previousCloseLine': 'Previous close',
    'chart.view.PrevSettlementPriceLine': 'Prev. settlement price',
    'chart.view.scroller': 'Scrollbar',
    'chart.view.setOrder': 'Set order',
    'chart.view.SettlementPriceLine': 'Settlement price',
    'chart.view.show_cross': 'Track cursor',
    'chart.view.show_history': 'Show trade history',
    'chart.view.show_set_zero': 'Show empty bars',
    'chart.view.showEvents': 'Show Trades',
    'chart.view.showNews': 'Show News',
    'chart.view.showOrders': 'Show orders',
    'chart.view.showPositions': 'Show positions',
    'chart.view.source': 'Data type',
    'chart.view.source0': 'Default by symbol',
    'chart.view.source1': 'Bid',
    'chart.view.source1000': 'Ask',
    'chart.view.source1001': 'Bid/Ask average',
    'chart.view.source1002': 'Min (Bid) and Max (Ask)',
    'chart.view.source4': 'Last',
    'chart.view.sourceBID/ASK': 'By Bid/Ask',
    'chart.view.sourceTick1': 'By Bid/Ask',
    'chart.view.TimeToNextBar': 'Time to next bar',
    'chart.view.toolbar': 'Toolbar',
    'chart.view.volume': 'Show volume',
    'chart.visualTrading.Invalid price': 'Invalid price',
    'chart.volumeColoringMode.ByBar': 'By bar',
    'chart.volumeColoringMode.ByDifference': 'By Difference',
    'chart.volumeColoringMode.Fixed': 'Fixed',
    'chart.volumeColoringMode.ByMovingAverage': 'Above/below the moving average',
    'chart.quickTrading.Buy limit': 'Buy Limit',
    'chart.quickTrading.Buy stop': 'Buy Stop',
    'chart.quickTrading.Buy stop limit': 'Buy Stop limit',
    'chart.quickTrading.Invalid price': 'invalid price',
    'chart.quickTrading.Sell limit': 'Sell Limit',
    'chart.quickTrading.Sell stop': 'Sell Stop',
    'chart.quickTrading.Sell stop limit': 'Sell Stop limit',
    'Commodity Channel Index': 'Commodity channel index',
    'connect.connection.connect.error': 'Wrong connections parameters',
    'connect.connection.tradestream.error': 'No trading streams were found!',
    'connect.connection.unknown.error': 'Unknown error!',
    'contextMenu.Totals.visibility': '#not hidden#',
    Custom: 'Custom',
    'dataSourceWidget.btnText': 'Data source',
    'dataSourceWidget.openedBtn.tt': 'Hide data source',
    'dataSourceWidget.closedBtn.tt': 'Show data source',
    'dataSourceWidget.delayed.tt': 'Delayed data',
    'dataSourceWidget.disabled.tt': 'Data source data is not allowed for this instrument',
    'dataSourceWidget.disabledWhenOnlyTradeRoute.tt': 'Data source data is not allowed for the trade route',
    'dataSourceWidget.noData.text': 'No data to display',
    'dataSourceWidget.price': 'Price',
    'dataSourceWidget.size': 'Size',
    'dataSourceWidget.venue': 'Venue',
    'dataSourceWidget.last': 'Last',
    'dataSourceWidget.bid': 'Bid',
    'dataSourceWidget.ask': 'Ask',
    'dayTimeLabel.days': 'days',
    'dayTimeLabel.hours': 'hours',
    'dayTimeLabel.minutes': 'minutes',
    'dayTimeLabel.seconds': 'seconds',
    'dayTimeLabel.day': 'day',
    'dayTimeLabel.hour': 'hour',
    'dayTimeLabel.minute': 'minute',
    'dayTimeLabel.second': 'second',
    'dayTimeLabel.lifeTime': 'Lifetime',
    'dayTimeLabel.lifeTimeExpired': 'Lifetime expired',
    Day: 'Day',
    'DaysPerType.Actual': 'Actual',
    'dealticket.RiskRuleWarning.Header': 'Risk rule warning',
    'dealticket.RiskRuleWarning.AccountName': 'Account',
    'dealticket.RiskRuleWarning.UserName': 'User',
    Deutsch: '#hidden#',
    'enum.AccountType.ClosedFund': 'Closed fund',
    'enum.AccountType.MultiAsset': 'Multi asset',
    'enum.AccountType.OpenedFund': 'Opened fund',
    'enum.AccountType.SingleCCY': 'Single currency',
    'enum.AssetType.COMMODITIES': 'Commodities',
    'enum.AssetType.CRYPTO_CCY': 'Crypto currency',
    'enum.AssetType.CURRENCY': 'Currency',
    'enum.AssetType.SHARES': 'Shares',
    'editableComboBox.CreateNew': 'Create new',
    'editableComboBox.CreateNewTemplate': 'Create template',
    'editableComboBox.CreateNew.tooltip': 'Click to create a new list of instruments',
    'editableComboBox.CreateNewTemplate.tooltip': 'Click to create a new template',
    'editableComboBox.defaultList': 'Default list',
    'editableComboBox.tooltip': 'Saved lists',
    EstQuantity: 'Est. Quantity: ',
    'ExchangeId.All': 'All exchanges',
    'Exponential Moving Average': 'Exponential moving average',
    FOK: 'FOK',
    'FullLogs.Cluster': 'Server',
    'FullLogs.DateTime': 'Time',
    'FullLogs.Fields': 'Fields',
    'FullLogs.Message name': 'Message name',
    'FullLogs.Session': 'Session',
    'FullLogs.FieldsNew': 'New fields',
    'FullLogs.Mode': 'Mode',
    'general.addToWatchlist': 'Add to watchlist',
    'general.connection.status.Connected': 'Connected',
    'general.connection.status.Disconnected': 'Disconnected',
    'general.connection.status.Reconnecting': 'Reconnecting...',
    'general.connection.CancelConnectRequested': 'Cancel connect requested',
    'general.connection.fullInfo': 'Full info',
    'general.connection.is not valid. Attempt': 'is not valid. Attempt',
    'general.connection.lastMessage': 'Last message was',
    'general.connection.Login/password combination is not valid. Attempt': 'Login/password combination is not valid. Attempt {0}.',
    'general.connection.Login/password combination is not valid.': 'Login/password combination is not valid.',
    'general.connection.status': 'Status',
    'general.connection.This client type is not allowed for user': 'This client type is not allowed for user',
    'general.connection.User/password combination for user': 'Login/Password combination',
    'general.connection.User/password combination is not valid. Atemp': 'User/password combination is not valid. Attempt',
    'general.connection.User/password combination is not valid.': 'Login/password combination is not valid.',
    'general.connection.Verification password not valid.': 'Verification password is not valid',
    'User is locked after brute force!': 'User is locked after brute force!',
    'screen.LoginScreen.responceErrors.bruteforceBlockedMinutes': 'User {1} is blocked, please try again in about {2} minute(s) or contact your support team.',
    'User is blocked. Please contact support.': 'User is blocked. Please contact support.',
    'general.Instrument': 'Instrument',
    'general.Calculating': 'Calculating...',
    'general.connection.restErrorMsg': 'At the moment, there is a problem with saving settings and workspaces. Please contact support department',
    'general.CUSTOM': 'Custom',
    'general.Friday': 'Friday',
    'general.messageBox.cancel': 'Cancel',
    'general.messageBox.logout': 'Logout',
    'general.messageBox.no': 'No',
    'general.messageBox.ok': 'OK',
    'general.messageBox.showNextTime': 'Show next time',
    'general.messageBox.yes': 'Yes',
    'general.Monday': 'Monday',
    'general.day1.Mo': 'Mon',
    'general.day2.Tu': 'Tue',
    'general.day3.We': 'Wed',
    'general.day4.Th': 'Thu',
    'general.day5.Fr': 'Fri',
    'general.day6.Sa': 'Sat',
    'general.day7.Su': 'Sun',
    'general.month.1': 'Jan',
    'general.month.10': 'Oct',
    'general.month.11': 'Nov',
    'general.month.12': 'Dec',
    'general.month.2': 'Feb',
    'general.month.3': 'Mar',
    'general.month.4': 'Apr',
    'general.month.5': 'May',
    'general.month.6': 'Jun',
    'general.month.7': 'Jul',
    'general.month.8': 'Aug',
    'general.month.9': 'Sep',
    'general.monthFullName.1': 'January',
    'general.monthFullName.10': 'October',
    'general.monthFullName.11': 'November',
    'general.monthFullName.12': 'December',
    'general.monthFullName.2': 'February',
    'general.monthFullName.3': 'March',
    'general.monthFullName.4': 'April',
    'general.monthFullName.5': 'May',
    'general.monthFullName.6': 'June',
    'general.monthFullName.7': 'July',
    'general.monthFullName.8': 'August',
    'general.monthFullName.9': 'September',
    'general.N_A': 'N/A',
    'general.NoData...': 'No data...',
    'general.NoDataMatchingYourCriteria': 'No data matching your criteria',
    'general.OvernightMarginNotificationMessage': 'Show overnight margin notification message',
    'general.Settings': 'Settings',
    'general.Saturday': 'Saturday',
    'general.Sunday': 'Sunday',
    'general.Thursday': 'Thursday',
    'general.trading.at': 'at',
    'general.trading.Buy': 'Buy',
    'general.trading.confirmation': 'Confirmation',
    'general.trading.confirmation.prefix': '',
    'general.trading.confirmationString': '{1} {2} {3} {4} {5} {6} {7} {8} {9} {10} {14} {13} {14} {15} {11} {12} {16}',
    'general.trading.for': 'for',
    'general.trading.limitBuyLessAsk': 'Limit Buy order price is at or more than market Ask price, order may be filled immediately.',
    'general.trading.limitSellMoreBid': 'Limit Sell order price is at or less than market Bid price, order may be filled immediately.',
    'general.trading.limitBuyLessAllowableFromLast': 'Limit order price is less than allowable % from Last price {1}, are you sure to submit order?',
    'general.trading.limitBuyLessAllowableFromBase': 'Limit order price is less than allowable % from Base price {1}, are you sure to submit order?',
    'general.trading.limitSellMoreAllowableFromLast': 'Limit order price is greater than allowable % from Last price {1}, are you sure to submit order?',
    'general.trading.limitSellMoreAllowableFromBase': 'Limit order price is greater than allowable % from Base price {1}, are you sure to submit order?',
    'general.trading.marketCloseFor': 'Market Close for',
    'general.trading.pips': 'ticks',
    'general.trading.points': 'points',
    'general.trading.percent': '%',
    'general.trading.position': 'Position',
    'general.trading.PositionExerciseReject': 'Position exercise reject',
    'general.trading.position.Buy': 'Long',
    'general.trading.position.Sell': 'Short',
    'general.trading.priceNotAvailable': 'Not available',
    'general.trading.refused': 'Refused',
    'general.trading.Sell': 'Sell',
    'general.trading.orderStatus.PlacingOrder': 'Placing order...',
    'general.trading.orderStatus.CancelledByUser': 'Cancelled by user...',
    'general.trading.orderStatus.IllegalParameters': "Illegal order's parameters",
    'general.trading.orderStatus.Opened': 'opened successfully',
    'general.trading.stopBuyMoreAsk': 'Stop Buy order price is at or less than market Ask price, order may be filled immediately.',
    'general.trading.stopSellLessBid': 'Stop Sell order price is at or more than market Bid price, order may be filled immediately.',
    'general.trading.with offset': 'with offset',
    'general.Tuesday': 'Tuesday',
    'property.general.WarnIfWrongOrderParam': 'Warn if wrong order',
    'property.general.WarnIfWrongOrderParam.tooltip': 'Show warning if order parameters are wrong',
    'general.WarnIfOrderRepeat': 'not hidden',
    'property.general.WarnIfIdenticalOrderTime': '',
    'property.general.WarnIfIdenticalOrder': 'Warn if identical subsequent order is placed (sec)',
    'property.general.WarnIfOrderQtyExceed': 'Warn if order quantity exceeds',
    'property.general.WarnIfOrderQtyExceed.tooltip': 'This warning triggers if the order quantity exceeds the allowed limit.',
    'property.general.WarnIfOrderCapitalExceed': 'Warn if order capital exceeds',
    'property.general.WarnIfOrderCapitalExceed.tooltip': 'This warning triggers if the order capital exceeds the permitted amount.',
    'property.general.WarnIfTodayGrossLess': 'Warn if today\'s gross less',
    'property.general.WarnIfTodayGrossLess.tooltip': 'This warning triggers if today\'s gross income is less than the specified threshold.',
    'property.general.WarnIfAvailibleFundsLess': 'Warn if available funds less',
    'property.general.WarnIfAvailibleFundsLess.tooltip': 'This warning triggers if the available funds fall below the specified minimum.',
    'property.general.WarnIfTodayVolumeExeed': 'Warn if today\'s volume exceeds',
    'property.general.WarnIfTodayVolumeExeed.tooltip': 'This warning triggers if today\'s trading volume exceeds the allowed limit.',
    'property.general.ShowInstrumentHalt': 'Show symbol halt warnings',
    'property.general.ShowInstrumentHalt.tooltip': 'Warning displays if there is a halt on the trading symbol.',
    'property.general.WarnIfQtyToCloseMoreThanMaxLot': 'Warn if Quantity to close > Maximum lot',
    'property.general.WarnWhenEnteringIntoLeverage': 'Warn when entering into leverage',
    'general.WarnIfIdenticalOrdertText': 'You are trying to place the order with identical parameters to the previous one during the last {0} seconds.',
    'general.warnings.quantityExceedText': 'Your order quantity [{0}] exceeds warning level [{1}].',
    'general.warnings.orderCapitalExceedText': 'Your order capital [{0}] exceeds warning level [{1}].',
    'general.warnings.todayGrossLessText': 'Today gross [{0}] is less than warning limit [{1}]',
    'general.warnings.availibleFundsLessText': 'Available funds [{0}] is less than warning limit [{1}]',
    'general.warnings.todayVolumeExeedText': 'Today total volume [{0}] exceeds warning limit [{1}]',
    'general.warnings.instrumentHaltHeader': '{0} trading halt',
    'general.warnings.instrumentHaltText': 'Warning. On instrument \'{0}\' achieved trading limits. Trading temporarily suspended. Available actions: cancel orders. After halt trading limits will be expanded.',
    'general.Wednesday': 'Wednesday',
    'general.InstrumentIsNotAvailableNow': 'Instrument is not available anymore',
    GTC: 'GTC',
    'helpPage.link': 'https://guide.traderevolution.com/project/',
    'homePage.link': 'http://traderevolution.com/',
    Ichimoku: 'Ichimoku',
    'Instrument.Futures.contract': 'contract',
    'Instrument.Level1.ExtendedFields.NormalMarketSize': 'Normal market size',
    'Instrument.Spreadbet.bet': 'bets',
    'Instrument.Corporate.Securities': 'securities',
    'Instrument.ExerciseStyle.EUROPEAN': 'European',
    'Instrument.ExerciseStyle.AMERICAN': 'American',
    'InstrumentDetailsPanel.(Indicative symbol)': '(Indicative symbol)',
    'InstrumentDetailsPanel.1.GeneralInfo': 'General info',
    'InstrumentDetailsPanel.2.TradingInfo': 'Trading info',
    'InstrumentDetailsPanel.3.MarginReg': 'Margin requirements',
    'InstrumentDetailsPanel.4.Fees': 'Fees',
    'InstrumentDetailsPanel.4.Fees.InfoIconTooltip': 'Fees for {0}. To find out about fees for another account, open panel with account lookup or apply the account link.',
    'InstrumentDetailsPanel.5.Rebates': 'Rebates',
    'InstrumentDetailsPanel.6.SessionInfo': 'Indicative market schedule',
    'InstrumentDetailsPanel.7.Plans': 'Plans',
    'InstrumentDetailsPanel.AccruedInterest': 'Accrued interest',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot': 'Add liquidity rebate order per lot',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot': 'Add liquidity rebate per lot',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume': 'Add liquidity rebate per volume',
    'InstrumentDetailsPanel.AFTER_MARKET': 'After market',
    'InstrumentDetailsPanel.Allow short positions': 'Yes',
    'InstrumentDetailsPanel.AllowedOperations': 'Allowed operations',
    'InstrumentDetailsPanel.AllowedOrderTypes': 'Allowed order  types',
    'InstrumentDetailsPanel.AllowShortPositions': 'Allow short positions',
    'InstrumentDetailsPanel.AllowOvernightTrading': 'Allow overnight trading',
    'InstrumentDetailsPanel.Amount': 'Qty.',
    'InstrumentDetailsPanel.AssetClass': 'Symbol type',
    'InstrumentDetailsPanel.BEFORE_MARKET': 'Before market',
    'InstrumentDetailsPanel.Cash': 'Cash',
    'InstrumentDetailsPanel.Close': 'Close',
    'InstrumentDetailsPanel.Closed': 'Closed',
    'InstrumentDetailsPanel.CloseOutDeadline': 'Close-out deadline',
    'InstrumentDetailsPanel.Commisions': 'Commisions',
    'InstrumentDetailsPanel.ContactMonth': 'Contract month',
    'InstrumentDetailsPanel.ContractMultiplier': '#hidden#',
    'InstrumentDetailsPanel.ContractSize': 'Contract size',
    'InstrumentDetailsPanel.CouponCycle': 'Coupon cycle',
    'InstrumentDetailsPanel.CouponRate': 'Coupon rate, %',
    'InstrumentDetailsPanel.CurrentSession': 'Current session',
    'InstrumentDetailsPanel.DeliveryMethod': 'Delivery method',
    'InstrumentDetailsPanel.DeliveryStatus': 'Delivery status',
    'InstrumentDetailsPanel.Depend from account': 'Depends on account',
    'InstrumentDetailsPanel.Description': 'Description',
    'InstrumentDetailsPanel.Exchange': 'Exchange',
    'InstrumentDetailsPanel.ExchangeTradeSession': 'Exchange session',
    'InstrumentDetailsPanel.Exp1': 'Asset',
    'InstrumentDetailsPanel.FaceValue': 'Face value',
    'InstrumentDetailsPanel.FirstTradeDate': 'First trade date',
    'InstrumentDetailsPanel.Futures': 'Futures',
    'InstrumentDetailsPanel.FuturesClass': 'Futures class',
    'InstrumentDetailsPanel.HiLimit': 'High limit',
    'InstrumentDetailsPanel.HiLimitFromPriceLimitsMsg': 'High limit',
    'InstrumentDetailsPanel.Holiday': 'Holiday',
    'InstrumentDetailsPanel.HolidayDate': 'Date',
    'InstrumentDetailsPanel.HolidayName': 'Holiday name',
    'InstrumentDetailsPanel.Holidays list': 'Holidays list',
    'InstrumentDetailsPanel.HolidayType': 'Schedule',
    'InstrumentDetailsPanel.immediate': 'immediate',
    'InstrumentDetailsPanel.KEYNextHoliday': 'Next holiday',
    'InstrumentDetailsPanel.LastTradeDate': 'Last trade date',
    'InstrumentDetailsPanel.LiquidationOnly': 'Liquidation only',
    'InstrumentDetailsPanel.LotSize': 'Lot size',
    'InstrumentDetailsPanel.LotStep': 'Lot step',
    'InstrumentDetailsPanel.LowLimit': 'Low limit',
    'InstrumentDetailsPanel.LowLimitFromPriceLimitsMsg': 'Low limit',
    'InstrumentDetailsPanel.MAIN': 'Main',
    'InstrumentDetailsPanel.Margin': 'Margin req.',
    'InstrumentDetailsPanel.Margin_ByAccount': 'Margin by account',
    'InstrumentDetailsPanel.MarginBuy': 'Buy',
    'InstrumentDetailsPanel.MarginDay': 'Day',
    'InstrumentDetailsPanel.MarginDayBuy': 'Day buy',
    'InstrumentDetailsPanel.MarginDaySell': 'Day sell',
    'InstrumentDetailsPanel.MarginBuy.Intraday': 'Intraday buy',
    'InstrumentDetailsPanel.MarginSell.Intraday': 'Intraday sell',
    'InstrumentDetailsPanel.MarginBuy.Delivery': 'Delivery buy',
    'InstrumentDetailsPanel.MarginSell.Delivery': 'Delivery sell',
    'InstrumentDetailsPanel.MarginInAccountCurrency': '#hidden#',
    'InstrumentDetailsPanel.MarginOvernight': 'Overnight',
    'InstrumentDetailsPanel.MarginOvernightBuy': 'Overnight buy',
    'InstrumentDetailsPanel.MarginOvernightSell': 'Overnight sell',
    'InstrumentDetailsPanel.MarginSell': 'Sell',
    'InstrumentDetailsPanel.MaturityDate': 'Maturity date',
    'InstrumentDetailsPanel.MaximumLot': 'Maximum lot',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol': 'Max position qty',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday': 'Intraday max position qty',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery': 'Delivery max position qty',
    'InstrumentDetailsPanel.MinimalLot': 'Minimal lot',
    'InstrumentDetailsPanel.NextPaymentDate': 'Next yield payment',
    'InstrumentDetailsPanel.No': 'No',
    'InstrumentDetailsPanel.non trading day': 'Close',
    'InstrumentDetailsPanel.None': 'None',
    'InstrumentDetailsPanel.NormalMarketSize': '#hidden#',
    'InstrumentDetailsPanel.Not allow short positions': 'No',
    'InstrumentDetailsPanel.NoticeDate': 'Notice date',
    'InstrumentDetailsPanel.onlyCloseOrders': 'Only close orders',
    'InstrumentDetailsPanel.Open': 'Open',
    'InstrumentDetailsPanel.OptionExercise': 'Option exercise fee',
    'InstrumentDetailsPanel.OptionExercise1': 'Option exercise fee (Long)',
    'InstrumentDetailsPanel.OptionExercise3': 'Option exercise fee (Short)',
    'InstrumentDetailsPanel.PerOrderVolume1': 'Order volume (Buy), %',
    'InstrumentDetailsPanel.PerOrderVolume2': 'Order volume (Short), %',
    'InstrumentDetailsPanel.PerOrderVolume3': 'Order volume (Sell), %',
    'InstrumentDetailsPanel.PerOrder': 'Per order',
    'InstrumentDetailsPanel.PerOrderVolume': 'Order volume, %',
    'InstrumentDetailsPanel.PerPhoneTransaction': 'Per phone transaction',
    'InstrumentDetailsPanel.PerTransaction': 'Per transaction',
    'InstrumentDetailsPanel.PerVolume': 'Fill volume, %',
    'InstrumentDetailsPanel.Physically': 'Physically',
    'InstrumentDetailsPanel.POSTCLOSE': 'Post-market',
    'InstrumentDetailsPanel.PREOPEN': 'Pre-market',
    'InstrumentDetailsPanel.PreviousPaymentDate': 'Previous yield payment',
    'InstrumentDetailsPanel.Price': 'Price',
    'InstrumentDetailsPanel.ProductType': 'Product type',
    'InstrumentDetailsPanel.QuotiongCurrency': 'Quoting currency',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot': 'Remove liquidity rebate order per lot',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot': 'Remove liquidity rebate per lot',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume': 'Remove liquidity rebate per volume',
    'InstrumentDetailsPanel.SessionStatus': '#hidden#',
    'InstrumentDetailsPanel.SettlementDate': 'Settlement date',
    'InstrumentDetailsPanel.shortened': 'Shortened day',
    'InstrumentDetailsPanel.ShortCommissionInfo': 'Commission info',
    'InstrumentDetailsPanel.ShortPostionInterest': 'Short position interest',
    'InstrumentDetailsPanel.StrikePrice': 'Strike price',
    'InstrumentDetailsPanel.SwapDetails': 'Swap details',
    'InstrumentDetailsPanel.SwapBuy': 'Long swap',
    'InstrumentDetailsPanel.SwapSell': 'Short swap',
    'InstrumentDetailsPanel.Latest historical long swap': 'Latest historical long swap',
    'InstrumentDetailsPanel.Latest historical short swap': 'Latest historical short swap',
    'InstrumentDetailsPanel.Historical swap last updated on': 'Historical swap last updated on',
    'InstrumentDetailsPanel.Symbol': 'Symbol',
    'InstrumentDetailsPanel.T+0': 'T + 0 (today)',
    'InstrumentDetailsPanel.TickCoast': 'Tick cost',
    'InstrumentDetailsPanel.TickSize': 'Tick size',
    'InstrumentDetailsPanel.Tier': 'Tier',
    'InstrumentDetailsPanel.TradindBlockedOnSession': 'Trading blocked on session',
    'InstrumentDetailsPanel.TradingBalance': 'Settlement system',
    'InstrumentDetailsPanel.TradingHalt': 'Trading halt',
    'InstrumentDetailsPanel.TradingStatus': 'Symbol status',
    'InstrumentDetailsPanel.Underlier': 'Underlier',
    'InstrumentDetailsPanel.VAT': 'VAT',
    'InstrumentDetailsPanel.Volume': 'Fill volume',
    'InstrumentDetailsPanel.VolumeWithMinPD': 'Fill volume %, with min p/d',
    'InstrumentDetailsPanel.VolumeWithMinPDOPEN': 'Fill volume %, with min p/d (Open)',
    'InstrumentDetailsPanel.VolumeWithMinPDCLOSE': 'Fill volume %, with min p/d (Close)',
    'InstrumentDetailsPanel.PerVolume1': 'Fill volume (Buy), %',
    'InstrumentDetailsPanel.PerVolume2': 'Fill volume (Short), %',
    'InstrumentDetailsPanel.PerVolume3': 'Fill volume (Sell), %',
    'InstrumentDetailsPanel.working': 'Open',
    'InstrumentDetailsPanel.Yes': 'Yes',
    'InstrumentDetailsPanel.Yield': 'Yield',
    'InstrumentDetailsPanel.YTM': 'YTM, %',
    'InstrumentDetailsPanel.YTM.RefreshLink': 'Refresh',
    'InstrumentDetailsPanel.HistoricalSwap.points': 'points',
    'InstrumentDetailsPanel.HighLimitWarning': 'High limit warning',
    'InstrumentDetailsPanel.HighLimitWarning.descr': 'The upper limit of the allowable last price range with last change % above which limit orders are received a warning',
    'InstrumentDetailsPanel.LowLimitWarning': 'Low limit warning',
    'InstrumentDetailsPanel.LowLimitWarning.descr': 'The lower limit of the allowable last price range with last change % below which limit orders are received a warning',
    'InstrumentDetailsPanel.ExtField.TradingUnit': '#hidden#',
    'InstrumentDetailsPanel.ExtField.PriceQuoteUnit': '#hidden#',
    'InstrumentDetailsPanel.ExtField.DeliveryUnit': '#hidden#',
    'InstrumentDetailsPanel.ExtField.LotSize': '#hidden#',
    'InstrumentDetailsPanel.ExtField.TenderPeriodStartDate': '#hidden#',
    'InstrumentDetailsPanel.ExtField.TenderPeriodEndDate': '#hidden#',
    'InstrumentDetailsPanel.ExtField.SMFlag.Short term ASM Flag': 'Short term ASM Flag',
    'InstrumentDetailsPanel.ExtField.SMFlag.Long term ASM Flag': 'Long term ASM Flag',
    'InstrumentDetailsPanel.ExtField.SMFlag.GSM Flag': 'GSM Flag',
    'InstrumentDetailsPanel.ExtField.SMFlag.ESM Flag': 'ESM Flag',
    'InstrumentDetailsPanel.CareOrder': 'Care order',
    IOC: 'IOC',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForBindOrders': 'Execute as Market is not allowed for bound orders',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedForSlTp': 'Execute at market not allowed for SL/TP',
    'IsAllowedResponceReason.ExecuteAtMarketNotAllowedOrderType': 'Change order type or market order type is not allowed',
    'IsAllowedResponceReason.InstrumentForAccountDelivery': 'Instrument for account delivery',
    'IsAllowedResponceReason.InstrumentTradingMode': 'Instrument trading not allowed',
    'IsAllowedResponceReason.LockTrading': 'Trading is locked in platform',
    'IsAllowedResponceReason.LockTradingByPassword': 'Please, enter trading password on the bottom of the terminal',
    'IsAllowedResponceReason.NoLastQuote': 'No last quote',
    'IsAllowedResponceReason.NotAllowedByOrderStaus': 'Operations not allowed by order status',
    'IsAllowedResponceReason.NotAllowedMaxLotHasBeenReached': 'Max lot has been reached',
    'IsAllowedResponceReason.NotAllowedByExploreMode': 'Please log in to start trading',
    'IsAllowedResponceReason.NotAllowedPositionCloseOnly': 'Only positions closing is allowed',
    'IsAllowedResponceReason.NotAllowedRouteOrderType': 'Order cannot be created, since selected validity and Order type are not supported.',
    'IsAllowedResponceReason.NotClosablePosition': 'Not closable position',
    'IsAllowedResponceReason.NotLoadCache': 'Cache was not loaded',
    'IsAllowedResponceReason.NotVisibleInstrumentRoute': 'Not visible instrument or route',
    'IsAllowedResponceReason.NotAllowedForTrades': 'Not allowed for trades',
    'IsAllowedResponceReason.NotSupportedTIF': 'Not supported TIF',
    'IsAllowedResponceReason.OperationNotAllowedByOrderType': 'Operation is not allowed by order type',
    'IsAllowedResponceReason.OperationNotAllowedBySession': 'Operation is not allowed by session',
    'IsAllowedResponceReason.OptionPendingExerciseStatus': 'Position for contract is waiting for exercise. Сancel the exercise possible during the trading session.',
    'IsAllowedResponceReason.OptionPendingExerciseDealticket': 'Trading is forbidden due to position for contract {0} is waiting for exercise.',
    'IsAllowedResponceReason.OrderTypeNotAllowedBySession': 'Order type is not allowed by session',
    'IsAllowedResponceReason.RouteNotRunning': 'Instrument trading not allowed.',
    'IsAllowedResponceReason.RuleAdministration': 'Rule administration',
    'IsAllowedResponceReason.RuleClosePositionDisable': 'Position closing is not allowed by Rule',
    'IsAllowedResponceReason.RuleFunctionSelfTrading': 'Rule function self trading',
    'IsAllowedResponceReason.RuleTrading': 'Trading is not allowed by Rule',
    'IsAllowedResponceReason.RuleFunctionTrading': 'Trading is disabled by risk rules',
    'IsAllowedResponceReason.RuleOCO': 'Rule OCO',
    'IsAllowedResponceReason.RuleSlTpNotAllowed': 'SL/TP is not allowed by Rule',
    'IsAllowedResponceReason.RuleTrStop': 'Rule Tr. stop',
    'IsAllowedResponceReason.Unknown': 'Unknown reason',
    'IsAllowedResponceReason.NotAllowedCorporateActionPositions': 'Disabled for corporate action positions',
    'IsAllowedResponceReason.WrongTraidingPositionType': 'Wrong trading position type',
    'Keltner Channel': 'Keltner channel',
    Limit: 'Limit',
    'Linear Weighted Moving Average': 'Linear weighted moving average',
    LOC: 'LOC',
    LOO: 'LOO',
    Manual: 'Manual',
    Market: 'Market',
    'MarketDepthPanel.ColoringMethod.ByPriceLVL': 'By price level',
    'MarketDepthPanel.ColoringMethod.RelativeToVolume': 'Relative to volume',
    'MarketDepthPanel.ColoringMethod.SizeHistogram': 'Size histogram',
    'McGinley Dynamic': 'McGinley Dynamic',
    'MDLotsMode.General': 'General defaults ({1})',
    'MDLotsMode.Lots': 'Lots',
    'MDLotsMode.RealSize': 'Real size',
    'MessageBox.ThemeChanged.Title': 'Warning',
    MOC: 'MOC',
    'Modified Moving Average': 'Modified Moving Average',
    Momentum: 'Momentum',
    MOO: 'MOO',
    'Moving Average Envelope': 'Moving average envelope',
    'Moving Average/Convergence Divergence': 'Moving average/Convergence Divergence',
    'NetTrader.SaveWorkspace.Save': 'Save workspace',
    'NumericComboCtrl.Edit': 'Set defaults',
    OCO: 'OCO',
    'OEProductTypeSelector.tooltip': 'Set product type',
    'OELeverageSelector.tooltip': 'Set leverage',
    'On Balance Volume': 'On Balance volume',
    OnClose: 'On Close',
    OnOpen: 'On Open',
    'Order.ExecutionType.ACCEPTED': 'Accepted',
    'Order.ExecutionType.ACTIVATED': 'Activated',
    'Order.ExecutionType.CANCELED': 'Cancelled',
    'Order.ExecutionType.FILLED': 'Filled',
    'Order.ExecutionType.NEW': 'Created',
    'Order.ExecutionType.PART_FILLED': 'Partially filled',
    'Order.ExecutionType.REFUSED': 'Rejected',
    'Order.ExecutionType.REPLACED': 'Modified',
    'Order.ExecutionType.RESTATED': 'Restated',
    'Order.ExecutionType.UNKNOWN': 'Unknown',
    'OrderEntry.InfoBlock.1.Risks': 'Risks',
    'OrderEntry.InfoBlock.2.Fees': 'Fees',
    'OrderEntry.InfoBlock.After trade funds': 'After trade funds',
    'OrderEntry.InfoBlock.Availible funds': 'Available  funds',
    'OrderEntry.InfoBlock.Balance': 'Balance',
    'OrderEntry.InfoBlock.Blocked for Stocks': 'Blocked for Stocks',
    'OrderEntry.InfoBlock.Fee': 'Fee',
    'OrderEntry.InfoBlock.Gross': 'Gross',
    'OrderEntry.InfoBlock.Impact on portfolio': 'Impact on portfolio',
    'OrderEntry.InfoBlock.Init. margin': 'Init. margin',
    'OrderEntry.InfoBlock.Maint. margin': 'Maint. margin',
    'OrderEntry.InfoBlock.Warn. margin': 'Warn. margin',
    'OrderEntry.InfoBlock.Margin availible': 'Margin available',
    'OrderEntry.InfoBlock.Net': 'Net',
    'OrderEntry.InfoBlock.Spread in loss': 'Spread init. loss',
    'OrderEntry.InfoBlock.P/L per Tick': 'P/L per Tick',
    'OrderEntry.InfoBlock.Allow short positions': 'Allow short positions',
    'OrderEntry.InfoBlock.After trade cash': 'After trade cash',
    'OrderEntry.InfoBlock.Free commission': 'Free commission',
    'OrderEntry.InfoBlock.FillPerLot': 'Fill per lot',
    'OrderEntry.InfoBlock.OrderPerLot': 'Order per lot',
    'OrderEntry.InfoBlock.FillVolume': 'Fill volume',
    'OrderEntry.InfoBlock.FillVolumeWithMinPD': 'Fill volume %, with min p/d',
    'OrderEntry.InfoBlock.PerFill': 'Per fill',
    'OrderEntry.InfoBlock.PerTransaction': 'Per transaction',
    'OrderEntry.InfoBlock.PerPhoneTransaction': 'Per phone transaction',
    'OrderEntry.InfoBlock.VAT': 'VAT',
    'OrderEntry.InfoBlock.OrderVolume': 'Order volume',
    'OrderEntry.InfoBlock.SwapDetails': 'Swap details',
    'OrderEntry.InfoBlock.LongSwap': 'Swap buy',
    'OrderEntry.InfoBlock.refreshBtnTooltip': 'Click to refresh margin data, or wait 30 sec.',
    'OrderEntry.InfoBlock.ShortSwap': 'Swap sell',
    'OrderEntry.InfoBlock.TotalFee': 'Total fee',
    'OrderEntry.InfoBlock.Visibility': '#not hidden#',
    'OrderEntry.InfoBlock.Incoming funds': 'Incoming  funds',
    'OrderEntry.InfoBlock.CareOrder': 'Care order',
    'OverlayScreen.barsBodyLabel': 'Body',
    'OverlayScreen.barsBorderLabel': 'Border',
    'OverlayScreen.barsHiLowLabel': 'Line',
    'OverlayScreen.cancelButton': 'Cancel',
    'OverlayScreen.dataLabel': 'Data type',
    'OverlayScreen.dojiLabel': 'Doji',
    'OverlayScreen.histogramLineLabel': 'Histogram Line',
    'OverlayScreen.instrumentLabel': 'Symbol',
    'OverlayScreen.Main scale': 'Main scale',
    'OverlayScreen.name': 'Overlay',
    'OverlayScreen.numberOverlaysError': 'You cannot add more than 5 overlays.',
    'OverlayScreen.okButton': 'OK',
    'OverlayScreen.Overlays': 'Overlays',
    'OverlayScreen.priceLabel': 'Price scaling',
    'OverlayScreen.priceMarkerCheckbox': 'Show price marker',
    'OverlayScreen.Separate scale': 'Separate scale',
    'OverlayScreen.solidPriceLabel': 'Area',
    'OverlayScreen.styleLabel': 'Style',
    'OverlayScreen.wickLabel': 'Wick',
    'panel.accountDetails.Groups.1.General': 'General',
    'panel.accountDetails.Groups.2.Margin': 'Margin',
    'panel.accountDetails.Groups.3.AccountActivity': 'Account activity',
    'panel.accountDetails.Groups.4.TodayResults': 'Today results',
    'panel.accountDetails.Groups.5.RiskManagement': 'Risk management',
    'panel.accountDetails.Groups.55.Plans': 'Plans',
    'panel.accountDetails.Groups.6.Info': 'Info',
    'panel.AccountDetailsPanel': 'Account details',
    'panel.AccountDetailsPanel.InfoTooltip': 'Change the fields in the widget, by Drag&Drop from the table',
    'panel.MarketConsensusPanel': 'Market consensus',
    'panel.MarketConsensusPanel.topLong': 'Top Long',
    'panel.MarketConsensusPanel.topShort': 'Top Short',
    'panel.accountDetails.Groups.Rico risks': 'Account details',
    'panel.accountDetails.Groups.Kyrrex risks': 'Account details',
    'panel.accountDetails.Groups.AccountActivity': '2. Account activity',
    'panel.accountDetails.Groups.General': '1. General',
    'panel.accountDetails.Groups.Info': '5. Info',
    'panel.accountDetails.Groups.RiskManagement': '4. Risk management',
    'panel.accountDetails.Groups.TodayResults': '3. Today results',
    'panel.accountDetails.Groups.Portfolio': 'Portfolio',
    'panel.accountDetails.Groups.Stocks': 'Stocks',
    'panel.accountDetails.Groups.Credit': 'Credit',
    'panel.accountDetails.Groups.Cash and Buying Power': 'Cash and Buying Power',
    'panel.accountDetails.Kyrrex risks.AvailableBalance': 'Available balance',
    'panel.accountDetails.Kyrrex risks.BlockedBalance': 'Blocked balance',
    'panel.accountDetails.Rico risks.AVLM': 'Available margin',
    'panel.accountDetails.Rico risks.BCKA': 'Blocked amount',
    'panel.accountDetails.Rico risks.BKBL': 'Blocked balance',
    'panel.accountDetails.Rico risks.BLNC': 'Balance',
    'panel.accountDetails.Rico risks.BMBM': 'BMF blocked margin',
    'panel.accountDetails.Rico risks.BMFL': 'BMF limit',
    'panel.accountDetails.Rico risks.BOPT': 'Balance options',
    'panel.accountDetails.Rico risks.BUOP': 'Balance uncovered operation',
    'panel.accountDetails.Rico risks.CED0': 'Credit entry day 0',
    'panel.accountDetails.Rico risks.CED1': 'Credit entry day 1',
    'panel.accountDetails.Rico risks.CED2': 'Credit entry day 2',
    'panel.accountDetails.Rico risks.CED3': 'Credit entry day 3',
    'panel.accountDetails.Rico risks.CRLM': 'Credit limit',
    'panel.accountDetails.Rico risks.CRLO': 'Credit limit opt',
    'panel.accountDetails.Rico risks.CULM': 'Current limit',
    'panel.accountDetails.Rico risks.D1PB': 'D1 projected balance',
    'panel.accountDetails.Rico risks.D2PB': 'D2 projected balance',
    'panel.accountDetails.Rico risks.D3PB': 'D3 projected balance',
    'panel.accountDetails.Rico risks.D1PD': 'D1 proj. date',
    'panel.accountDetails.Rico risks.D2PD': 'D2 proj. date',
    'panel.accountDetails.Rico risks.D3PD': 'D3 proj. date',
    'panel.accountDetails.Rico risks.DED0': 'Debit entry day 0',
    'panel.accountDetails.Rico risks.DED1': 'Debit entry day 1',
    'panel.accountDetails.Rico risks.DED2': 'Debit entry day 2',
    'panel.accountDetails.Rico risks.DED3': 'Debit entry day 3',
    'panel.accountDetails.Rico risks.DLBL': 'Day liq. balance',
    'panel.accountDetails.Rico risks.DLMT': 'Daily limit',
    'panel.accountDetails.Rico risks.EBVL': 'Exec. bought value',
    'panel.accountDetails.Rico risks.ESVL': 'Exec. sold value',
    'panel.accountDetails.Rico risks.FBBL': 'Final bmf balance',
    'panel.accountDetails.Rico risks.FNBL': 'Final balance',
    'panel.accountDetails.Rico risks.FNLE': 'Financial leverage',
    'panel.accountDetails.Rico risks.INBA': 'Initial balance',
    'panel.accountDetails.Rico risks.INBL': 'Initial balance',
    'panel.accountDetails.Rico risks.LEVE': 'Leverage',
    'panel.accountDetails.Rico risks.LILO': 'Leverage options limit',
    'panel.accountDetails.Rico risks.LVLM': 'Leverage limit',
    'panel.accountDetails.Rico risks.NTEQ': 'Net equity',
    'panel.accountDetails.Rico risks.OPBL': 'Options balance',
    'panel.accountDetails.Rico risks.OVLT': 'Overdraft limit',
    'panel.accountDetails.Rico risks.PBVL': 'Pending bought value',
    'panel.accountDetails.Rico risks.POVA': 'Potencial overdraft amount',
    'panel.accountDetails.Rico risks.PRBA': 'Project balance',
    'panel.accountDetails.Rico risks.PSVL': 'Pending sold value',
    'panel.accountDetails.Rico risks.SKBL': 'Stocks balance',
    'panel.accountDetails.Rico risks.WDBL': 'Withdrawal balance',
    'panel.accountDetails.Portfolio.TR_OAL': 'Net Portfolio Value',
    'panel.accountDetails.Portfolio.TR_T1B': 'T1 Net Portfolio Value',
    'panel.accountDetails.Portfolio.TR_T2B': 'T2 Net Portfolio Value',
    'panel.accountDetails.Portfolio.TR_ASN': 'Buy Sell Net Balance',
    'panel.accountDetails.Stocks.TR_CHS': 'Stocks Balance',
    'panel.accountDetails.Stocks.TR_CHST1': 'T1 Stock Balance',
    'panel.accountDetails.Stocks.TR_CHST2': 'T2 Stock Balance',
    'panel.accountDetails.Stocks.TR_CBK': 'Cash',
    'panel.accountDetails.Stocks.TR_CBKT1': 'T1 Cash',
    'panel.accountDetails.Stocks.TR_CBKT2': 'T2 Cash',
    'panel.accountDetails.Credit.TR_KRD': 'Credit Balance',
    'panel.accountDetails.Credit.TR_KRDT1': 'T1 Credit Balance',
    'panel.accountDetails.Credit.TR_KRDT2': 'T2 Credit Balance',
    'panel.accountDetails.Credit.TR_OZK': 'Debt to Equity Ratio',
    'panel.accountDetails.Credit.TR_OZ1': 'T1 Debt to Equity Ratio',
    'panel.accountDetails.Credit.TR_OZ2': 'T2 Debt to Equity Ratio',
    'panel.accountDetails.Cash and Buying Power.TR_NAK': 'Cash Limit',
    'panel.accountDetails.Cash and Buying Power.TR_ISL': 'Intraday Buying Power',
    'panel.accountDetails.Cash and Buying Power.TR_LMT': 'Credit Balance',
    'panel.accountDetails.Cash and Buying Power.TR_KIL': 'Available Credit Balance',
    'panel.accountDetails.Cash and Buying Power.TR_ASL': 'Available Short Sell Limit',
    'panel.accountDetails.Cash and Buying Power.TR_GKZ': 'Overall Change',
    'panel.accountDetails.Cash and Buying Power.TR_TPLM': 'Total Equity Value',
    'panel.accountDetails.Cash and Buying Power.TR_TPLMT1': 'T1 Total Equity Value',
    'panel.accountDetails.Cash and Buying Power.TR_TPLMT2': 'T2 Total Equity Value',
    'panel.AccountLink.SymbolLink.ToolTip': 'Symbol link',
    'panel.AccountLink.ToolTip': 'Account link',
    'panel.AccountLink.CanFilterToolTip': 'The data in the panel is filtered by account',
    'panel.AccountLinkButton.ToolTip': 'This function filters your positions and orders by account. Account switches synchronously in all panels',
    'panel.accounts.AccountLink.ToolTip': 'Account linking enabled',
    'panel.accounts': 'Accounts',
    'panel.accounts.AccountDescription': '#hidden#',
    'panel.accounts.accountDetails.RiskManagement.MaxOrderCapital.Unlimited': 'Unlimited',
    'panel.accounts.accountDetails.RiskManagement.Trading.Daily loss limit reached': 'Daily loss limit was reached',
    'panel.accounts.accountDetails.RiskManagement.Trading.Max day volume  reached': 'Max day volume  reached',
    'panel.accounts.accountDetails.RiskManagement.Trading.Order Flow Limit(per day)': 'Order Flow Limit(per day)',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Disabled': 'Disabled',
    'panel.accounts.accountDetails.RiskManagement.Trading.State.Enabled': 'Enabled',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Active': 'Active',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Auto closed': 'Auto closed',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Closed': 'Closed',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules': 'Trading disabled by risk rules',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Disabled by risk rules.maxOrdersPerDay': 'Trading disabled by risk rules. The maximum number of orders per day was reached',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.FinraDayTraderStatus': 'FINRA Day trader pattern',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.LiquidationOnlyStatus': 'Liquidation only',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.MarginWarning': 'Warning about possible stop out',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max trailing drawdown was reached': 'Max trailing drawdown was reached',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Suspend': 'Suspend',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Unrealized loss limit reached': 'Unrealized loss limit reached',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Weekly lost limit reached': 'Weekly loss limit was reached',
    'panel.accounts.AccountMode': 'Account mode',
    'panel.accounts.AccountType': 'Account type',
    'panel.accounts.AccountValue': 'Account value',
    'panel.accounts.AllowOvernightTrading': '#hidden#',
    'panel.accounts.AllowTradingPrePost': 'Trading on pre/post  markets',
    'panel.accounts.Asset': 'Asset',
    'panel.accounts.AssetDescription': 'Asset description',
    'panel.accounts.AssetType': 'Asset type',
    'panel.accounts.AvailableMargin': 'Available funds',
    'panel.accounts.Balance': 'Balance',
    'panel.accounts.BalancePlusAllRisks': 'Balance & All risks',
    'panel.accounts.BeginBalance': "Today's net",
    'panel.accounts.BlockedByOrders': 'Margin used for orders',
    'panel.accounts.BlockedByPamm': 'Invested fund capital',
    'panel.accounts.BlockedForStocks': '#hidden#',
    'panel.accounts.BlockedForStocksNew': 'Blocked for Stocks',
    'panel.accounts.CashBalance': 'Cash balance',
    'panel.accounts.ClientType': 'Terminal type',
    'panel.accounts.Commissions': 'Commissions',
    'panel.accounts.CreditValue': 'Credit value',
    'panel.accounts.CurBalance': 'Projected balance',
    'panel.accounts.CurMargin': 'Initial margin req., %',
    'panel.accounts.CurrentDailyLoss': 'Daily loss',
    'panel.accounts.CurrentFundCapital': 'Current fund capital',
    'panel.accounts.CurrentStatus': 'Trading status',
    'panel.accounts.CurrentStatus.descr': 'Trading status',
    'panel.accounts.CurrentWeeklyLoss': 'Weekly loss',
    'panel.accounts.Email': 'Email',
    'panel.accounts.EODTrailingDrawdownLevel': 'EOD trailing drawdown level',
    'panel.accounts.RelativeDailyLossLimit': 'Max relative daily loss limit',
    'panel.accounts.Estimate value': 'Estimate value',
    'panel.accounts.FundCapitalGain': 'Fund capital gain',
    'panel.accounts.FundsUsed': 'Funds used',
    'panel.accounts.FundsUsedPercent': 'Funds used, %',
    'panel.accounts.DayTraderPatternProtection': 'Day trader pattern protection',
    'panel.accounts.AvailableDayTrades': 'Available day trades',
    'panel.accounts.GrossPNL': "Today's gross",
    'panel.accounts.InsertOnBalance': 'Insert on balance',
    'panel.accounts.InterestRate': 'Interest rate, %',
    'panel.accounts.IntradayRiskManagement': 'Risk management status',
    'panel.accounts.InitialMarginWithoutWaived': '#hidden#',
    'panel.accounts.InitialMarginWithoutWaived.descr': 'Initial margin requirement without waived',
    'panel.accounts.Leverage': 'Leverage',
    'panel.accounts.LockedBalance': 'Blocked balance',
    'panel.accounts.LockedBy': 'Locked',
    'panel.accounts.Login': 'Account',
    'panel.accounts.MaintMargin': 'Maint. margin req., %',
    'panel.accounts.MarginAndRisks': 'Margin and risks',
    'panel.accounts.MarginAvailableReal': 'Margin available',
    'panel.accounts.MarginDeficiency': 'Margin deficiency',
    'panel.accounts.MarginForOthers': '#hidden#',
    'panel.accounts.MarginRequirement': 'Pos. maint. req.',
    'panel.accounts.MarginStopOut': 'Maint. margin req.',
    'panel.accounts.MarginStopTrade': 'Stop trading',
    'panel.accounts.MarginSurplus': 'Margin surplus',
    'panel.accounts.MarginUsedNew': 'Initial margin req.',
    'panel.accounts.MarginWarning': 'Warning margin',
    'panel.accounts.marginWarnPercent': 'Margin warning level',
    'panel.accounts.MarketDataCharging': 'Market data charging',
    'panel.accounts.MaxDailyLossLimit': 'Daily loss limit',
    'panel.accounts.MaxDailyProfit': 'Daily profit target',
    'panel.accounts.MaxDayVolume': '#hidden#',
    'panel.accounts.MaximumLot': 'Maximum lot',
    'panel.accounts.MaxOrderAmount': 'Max. order amount',
    'panel.accounts.MaxOrderCapital': 'Max. order capital',
    'panel.accounts.MaxOrdersPerDay': 'Max orders count per day',
    'panel.accounts.MaxPendingOrders': '# Max. pending orders',
    'panel.accounts.MaxDrawdownLevel': 'Max. drawdown level',
    'panel.accounts.MaxPendingOrdersValue': 'Max. pending orders value',
    'panel.accounts.MaxPositionQtyPerSymbol': 'Max qty. per symbol',
    'panel.accounts.MaxPositions': '# Max. positions',
    'panel.accounts.MaxWeekLostLimit': 'Weekly loss limit',
    'panel.accounts.MinMargin': 'Margin used for  positions',
    'panel.accounts.Murkups': 'Markups',
    'panel.accounts.N/A_TelephoneNumber': 'N/A',
    'panel.accounts.OptionPremiumReq': 'Option​ ​premium​ ​req.',
    'panel.accounts.OptionValue': 'Option value',
    'panel.accounts.Orders': '# Orders',
    'panel.accounts.Owner': 'Owner',
    'panel.accounts.Positions': '# Positions',
    'panel.accounts.PowerOfAttorney': 'Power of attorney',
    'panel.accounts.Profit': 'Open gross  P/L',
    'panel.accounts.ProfitNet': 'Open net P/L',
    'panel.accounts.Role': 'Fund type',
    'panel.accounts.Spreads': 'Spreads',
    'panel.accounts.Status': 'Status',
    'panel.accounts.Collateral': '#hidden#',
    'panel.accounts.BuyingPower': '#hidden#',
    'panel.accounts.GivenLeverage': '#hidden#',
    'panel.accounts.OpenBalance': '#hidden#',
    'panel.accounts.StopOut': 'Stop out value',
    'panel.accounts.StopOut.descr': 'Stop out value',
    'panel.accounts.StocksLiquidity': 'Stocks liquidity',
    'panel.accounts.StocksOrdersReq': 'Stocks orders req.',
    'panel.accounts.StocksValue': 'Stocks value',
    'panel.accounts.stopOutPercent': 'Stop out level',
    'panel.accounts.stopTradingPercent': 'Stop trading level',
    'panel.accounts.Swap': 'Swap',
    'panel.accounts.TelephoneNumber': 'Telephone number',
    'panel.accounts.TodaysPnLPercent': "Today's PnL %",
    'panel.accounts.TodayFees': "Today's fees",
    'panel.accounts.TodayRebates': "Today's rebates",
    'panel.accounts.TodaySwaps': '#hidden#',
    'panel.accounts.TodayTrades': "# Today's trades",
    'panel.accounts.TodayTurnover': 'Today turnover',
    'panel.accounts.TodayVolume': "Today's volume",
    'panel.accounts.TotalMaxPositionsQty': 'Total max position qty',
    'panel.accounts.TrailingDrawdownLevel': 'Trailing drawdown level',
    'panel.accounts.UnrealizedLoss': 'Unrealized loss',
    'panel.accounts.UnrealizedLossLimit': 'Unrealized loss limit',
    'panel.accounts.UnsettledCash': 'Unsettled cash',
    'panel.accounts.UserGroup': 'User group',
    'panel.accounts.UserID': 'User ID',
    'panel.accounts.UserLogin': 'User login',
    'panel.accounts.VolumeLimitForALL': 'Total volume limit',
    'panel.accounts.VolumeLimitForFOREX': 'Forex volume limit',
    'panel.accounts.VolumeLimitForEQUITIES': 'Equities volume limit',
    'panel.accounts.VolumeLimitForFUTURES': 'Futures volume limit',
    'panel.accounts.VolumeLimitForOPTIONS': 'Options volume limit',
    'panel.accounts.VolumeLimitForEQUITIES_CFD': 'Equities CFD volume limit',
    'panel.accounts.VolumeLimitForFORWARD': 'Forward volume limit',
    'panel.accounts.VolumeLimitForCFD_FUTURES': 'Futures CFD volume limit',
    'panel.accounts.VolumeLimitForINDICIES': 'Indices volume limit',
    'panel.accounts.VolumeLimitForCRYPTO': 'Crypto ccy volume limit',
    'panel.accounts.VolumeLimitForSPREADBET': 'Spreadbet volume limit',
    'panel.accounts.VolumeLimitForBOND': 'Bonds volume limit',
    'panel.accounts.VolumeLimitForETF': 'ETF volume limit',
    'panel.accounts.VolumeLimitForTBILL': 'T-Bills volume limit',
    'panel.accounts.VolumeLimitForSPOT': 'Spot volume limit',
    'panel.accounts.VolumeLimitForCORPORATE': 'Corporate volume limit',
    'panel.accounts.WithdrawalAvailable': 'Withdrawal available',
    'panel.accounts.WaivedMargin': 'Waived margin',
    'panel.accounts.WaivedMargin.descr': 'Waived margin for the account',
    'panel.accounts.WarningMarginReq': 'Warn. margin req.',
    'panel.accounts.WarningMarginReq.descr': 'Warning margin requirement for positions and orders.',
    'panel.accounts.WarningMarginReqPercent': 'Warn. margin req.%',
    'panel.accounts.WarningMarginReqPercent.descr': 'Warning margin requirement in relation to Balance&All risks.',
    'panel.accounts.MarginBeforeWarning': 'Margin before warning',
    'panel.accounts.MarginBeforeWarning.descr': 'Margin available before warning triggering',
    'panel.accounts.IncomingFunds': 'Incoming funds',
    'panel.accounts.IncomingFunds.descr': "You can use that sum before it is settled on Balance. But you can't withdraw it.",
    'panel.assetBalances': 'Accounts',
    'panel.backoffice': 'Backoffice',
    'panel.backoffice.You need to add connection!': 'You need to add connection!',
    'panel.caption.NotAllowedMode': 'Current panel is not allowed in rules set',
    'panel.caption.showHideToolbar': 'Show toolbar',
    'panel.chart': 'Chart',
    'panel.close': 'Close',
    'panel.closePosition.Gross_P/L_ToClose': 'Gross P/L to close',
    'panel.closePosition.QunatityToClose': 'Quantity to close',
    'panel.closePosition.productTypeText': 'Product type',
    'panel.copypanel': 'Duplicate panel',
    'panel.CopyTrading': 'Copy trading',
    'panel.CopyTrading.ShowConfirmationLabel': 'Show information message about start/stop copy trading',
    'panel.CopyTrading.Confirmation.Start.Caption': 'Confirm start copy trading',
    'panel.CopyTrading.Confirmation.Start.Message': 'When the master account opens a position or partially closes it, the same action will be executed for the selected follower accounts.\n\nNote, take profit or stop loss orders will not be automatically created for the follower accounts.\n\nAre you sure you want to start copy trading with the selected accounts?',
    'panel.CopyTrading.Confirmation.Stop.Caption': 'Confirm stop copy trading',
    'panel.CopyTrading.Confirmation.Stop.Message': 'This action will stop copying the trades of the master account to the follower accounts.\n\nAre you sure you want to stop following?',
    'panel.CopyTrading.Error.Start.Message': 'You cannot follow this account as it is already following one of your selected accounts.',
    'panel.CopyTrading.Error.Stop.Message': 'Failed to stop copy trading, please try again later.',
    'panel.CopyTrading.Error.Reject.AlreadyFollowingMaster': 'Account {0} is already following another master account. An account can only follow one master.',
    'panel.CopyTrading.Error.Reject.MasterCannotFollow': 'Account {0} cannot follow another account as it already has followers. A master account cannot be a follower.',
    'panel.CopyTrading.item.fromAccount': 'From account',
    'panel.CopyTrading.item.toAccount': 'To accounts',
    'panel.CopyTrading.RowControl.ButtonStart.Text': 'Start',
    'panel.CopyTrading.RowControl.ButtonStart.Tooltip': 'Start copying',
    'panel.CopyTrading.RowControl.ButtonStart.Disabled.Tooltip': 'Select a follower to start copying.',
    'panel.CopyTrading.RowControl.ButtonStop.Text': 'Stop',
    'panel.CopyTrading.RowControl.ButtonStop.Tooltip': 'Stop copying',
    'panel.detail': 'Detail quote',
    'panel.detail.AuctionEndTime': '#hidden#',
    'panel.detail.CBAuctionStart': '#hidden#',
    'panel.detail.change': 'Change',
    'panel.detail.changePr': 'Change, %',
    'panel.detail.CurrentSession': '#hidden#',
    'panel.detail.high': 'High',
    'panel.detail.bid': 'Bid',
    'panel.detail.ask': 'Ask',
    'panel.detail.last': 'Last',
    'panel.detail.Limit.High': '#hidden#',
    'panel.detail.Limit.Low': '#hidden#',
    'panel.detail.low': 'Low',
    'panel.detail.NormalMarketSize': '#hidden#',
    'panel.detail.OffExchangeValue': '#hidden#',
    'panel.detail.OffExchangeVolume': '#hidden#',
    'panel.detail.open': 'Open',
    'panel.detail.prevClose': 'Prev. close',
    'panel.detail.RemainingQty': '#hidden#',
    'panel.detail.RemainingQtySide': '#hidden#',
    'panel.detail.TradedValue': '#hidden#',
    'panel.detail.TradingStatus': '#hidden#',
    'panel.detail.TotalBuyQty': '#hidden#',
    'panel.detail.TotalSellQty': '#hidden#',
    'panel.detail.LastTradedTime': '#hidden#',
    'panel.detail.LastTime': 'Last time',
    'panel.detail.LastUpdateTime': '#hidden#',
    'panel.detail.AvgTradedPrice': '#hidden#',
    'panel.detail.NSEValue': '#hidden#',
    'panel.detail.DPR': '#hidden#',
    'panel.detail.FiftyTwoWeekHighPrice': '#hidden#',
    'panel.detail.FiftyTwoWeekLowPrice': '#hidden#',
    'panel.detail.volume': 'Volume',
    'panel.detail.BidSource': '#hidden#',
    'panel.detail.AskSource': '#hidden#',
    'panel.detail.LastSource': '#hidden#',
    'panel.detail.OpenInterest': '#hidden#',
    'panel.detail.IndicativeAuctionPrice': '#hidden#',
    'panel.detail.SettlementPrice': '#hidden#',
    'panel.detail.PrevSettlementPrice': '#hidden#',
    'panel.detail.VWAP': '#hidden#',
    'panel.detail.ReferencePrice': '#hidden#',
    'panel.detail.Spread': '#hidden#',
    'panel.detail.ETB': '#hidden#',
    'panel.detail.BidSize': '#hidden#',
    'panel.detail.AskSize': '#hidden#',
    'panel.detail.LastSize': '#hidden#',
    'panel.watchlist.BidSource': 'Bid source',
    'panel.watchlist.AskSource': 'Ask source',
    'panel.watchlist.LastSource': 'Last source',
    'panel.EventLog.Action': 'Event',
    'panel.EventLog.Date': 'Date',
    'panel.EventLog.eventType.alerts': 'Alerts',
    'panel.EventLog.eventType.comment': 'Comment',
    'panel.EventLog.eventType.disconnect': 'Disconnect',
    'panel.EventLog.eventType.exception': 'Exception',
    'panel.EventLog.eventType.info': 'Info',
    'panel.EventLog.eventType.none': 'None',
    'panel.EventLog.eventType.system': 'System',
    'panel.EventLog.eventType.trading': 'Trading',
    'panel.EventLog.eventType.updater': 'Updater',
    'panel.EventLog.menu.Clear': 'Clear',
    'panel.EventLog.Message': 'Details',
    'panel.EventLog.NamePanel': 'Event log',
    'panel.EventLog.Roundtrip': 'Roundtrip, ms',
    'panel.EventLog.Type': 'Type',
    'panel.external.Attach': 'Attach',
    'panel.external.Dettach': 'Detach',
    'panel.GridPanel.listButton.ToolTip': 'Select symbol for the panel',
    'panel.GridPanel.recreateButton.ToolTip': 'Specify the number of panels',
    'panel.GridPanel.refreshAllCharts.ToolTip': 'Refresh all charts',
    'panel.GridPanel.resetMI.Text': 'Reset panels size',
    'panel.informer': 'Watchlist',
    'panel.Ideas': 'Ideas',
    'panel.Ideas.riskDisclosure': 'RISK DISCLOSURE',
    'panel.Ideas.riskDisclosure.agreeBtnTxt': 'I agree',
    'panel.Ideas.riskDisclosure.noDataText': 'No trading ideas',
    'panel.Ideas.productTypeChangeToIntraday': 'The idea was suggested with the product type - Delivery. You changed the product type on Intraday. Are you sure?',
    'panel.Ideas.productTypeChangeToDelivery': 'The idea was suggested with the product type - Intraday. You changed the product type on Delivery. Are you sure?',
    'panel.Ideas.AttachmentFile': 'Attachment',
    'panel.Ideas.AttachmentScreen': 'Screen',
    'panel.Ideas.orderButtonTextBuy': 'Place buy order',
    'panel.Ideas.orderButtonTextSell': 'Place sell order',
    'panel.Ideas.orderButtonVisibility': 'not hidden',
    'panel.Ideas.quantityVisiblility': 'not hidden',
    'panel.Ideas.DealTicketHeader': 'Trading ideas',
    'panel.Ideas.DealTicketText': 'You received a new trading idea from ',
    'panel.Ideas.SubscribeDealTicketText': 'You were subscribed to ',
    'panel.Ideas.UnsubscribeDealTicketText': 'You have unsubscribed from ',
    'panel.Ideas.FilteringByInstrumentTypes': '#hidden#',
    'panel.Ideas.FilteringAllTypes': 'All',
    'panel.Notifications': 'Notifications',
    'panel.Notifications.tt': 'Click to open Notifications panel',
    'panel.Notifications.noSelCardTxt': 'Select message to see full text',
    'panel.Notifications.noNotificationsText': 'You have no notifications',
    'panel.VideoWidget': 'Video',
    'panel.VideoWidget.noDataText': 'Your video library is empty',
    'panel.VideoWidget.isLoadingText': 'Your video library is loading',
    'panel.VideoWidget.TimeDateLabelTextForLIVE': 'Now',
    'screen.Alerts.Settings.Screen': 'Alert settings',
    'screen.Alerts.Settings.ContextMenu': 'Settings...',
    'screen.Alerts.Action.PlaceOrder': 'Place order + Notify',
    'screen.Alerts.Action.Notify': 'Notify',
    'screen.Alerts.Action.Edit': 'Edit',
    'screen.Alerts.Condition.Greater': 'Greater',
    'screen.Alerts.Condition.Less': 'Less',
    'screen.Alerts.Condition.Greater or equal': 'Greater or equal',
    'screen.Alerts.Ask': 'Ask',
    'screen.Alerts.Bid': 'Bid',
    'screen.Alerts.Last': 'Last',
    'screen.Alerts.ChangePr': 'Change, %',
    'screen.Alerts.Volume': 'Volume',
    'screen.Alerts.Condition.Less or equal': 'Less or equal',
    'screen.Alerts.Notify.Popup': 'Show Pop-up',
    'screen.Alerts.Notify.Sound': 'Sound',
    'screen.Alerts.Notify.Push': 'Push',
    'screen.Alerts.Notify.Email': 'Email',
    'screen.Alerts.Action.Start': 'Start',
    'screen.Alerts.AddAlert': 'Create new',
    'screen.Alerts.AddAlert.Tooltip': 'Click to create a new alert',
    'screen.Alerts.AddAlert.Disabled.Tooltip': 'The maximum number of alerts has been exceeded',
    'screen.Alerts.noDataText': 'No alerts',
    'screen.Alerts.AfterExecute.Remove': 'Remove',
    'screen.Alerts.AfterExecute.Stop': 'Stop',
    'screen.Alerts.Alert': 'Alert',
    'screen.Alerts.Button.Apply.Tooltip': 'Click to apply changes',
    'screen.Alerts.Button.Create': 'Create',
    'screen.Alerts.Button.Create.Tooltip': 'The field must not be empty',
    'screen.Alerts.Header.Create': 'Create alert',
    'screen.Alerts.Header.Edit': 'Editing',
    'screen.Alerts.Importance.High': 'High',
    'screen.Alerts.Importance.Low': 'Low',
    'screen.Alerts.Importance.Medium': 'Medium',
    'screen.Alerts.Label.Action': 'Action',
    'screen.Alerts.Label.Notify': 'Notification type',
    'screen.Alerts.Label.Notify.InfoTooltip': '',
    'screen.Alerts.Label.OrderParameters': 'Order parameters',
    'screen.Alerts.Label.OrderParameters.Tooltip': 'Click to select order parameters',
    'screen.Alerts.Label.AfterExecute.Tooltip': 'Choose alert behavior after execution',
    'screen.Alerts.Label.AfterExecute': 'After execute',
    'screen.Alerts.Label.AlertType.Tooltip': 'Set an alert type',
    'screen.Alerts.Label.AlertType': 'Alert type',
    'screen.Alerts.Label.Condition.Tooltip': 'Set an alert condition',
    'screen.Alerts.Label.Condition': 'Condition',
    'screen.Alerts.Label.Importance': 'Importance',
    'screen.Alerts.Label.Symbol': 'Symbol',
    'screen.Alerts.Label.Account': 'Account',
    'screen.Alerts.Label.Value': 'Value',
    'screen.Alerts.Label.Expiration': 'Expiration',
    'screen.Alerts.Label.Expiration.Tooltip': 'Date and time of expiration of the alert',
    'screen.Alerts.Label.Expiration.InfoTooltip': 'After expiration, the alert will be removed',
    'screen.Alerts.Message': 'Alert message',
    'screen.Alerts.Notify.All': 'All notifications',
    'screen.Alerts.Notify.Few': 'notifications',
    'screen.Alerts.RemoveAlertMessage': 'Alert will be removed. Are you sure?',
    'screen.Alerts.TableItem.Condition': 'Condition',
    'screen.Alerts': 'Alerts',
    'screen.Alerts.visibility': '#not hidden#',
    'screen.Alerts.WarningHeader': 'Warning',
    'screen.Alerts.SendOeAlert': 'The order {0} has been sent',
    'screen.Alerts.item.stopped': 'Stopped',
    'screen.Alerts.item.active': 'Active',
    'panel.instrumentInfo.AvgPrice': 'Avg. price:',
    'panel.instrumentInfo.Price': 'Price:',
    'panel.instrumentInfo.P/L': 'P/L:',
    'panel.instrumentInfo.Qty': 'QTY:',
    'panel.instrumentInfo.BEven': 'B-even:',
    'panel.instruments': 'Symbols',
    'panel.instrumentsInfo': 'Symbol info',
    'panel.knowledgebase': 'Knowledge base',
    'panel.assets': 'Assets',
    'panel.Assets.Asset type': 'Asset type',
    'panel.Assets.Description': 'Description',
    'panel.Assets.Interest rate, %': 'Interest rate, %',
    'panel.Assets.Menu.SellAsset': 'Sell asset',
    'panel.Assets.Minimal change': 'Minimal change',
    'panel.Assets.Name': 'Name',
    'panel.Assets.NoAssetsAvailable': 'No assets available',
    'panel.Assets.QuickTable.Column.Account': 'Account',
    'panel.Assets.QuickTable.Column.AssetName': 'Asset name',
    'panel.Assets.QuickTable.Column.AvailableQtySell': 'Available qty. for sell',
    'panel.Assets.QuickTable.Column.CurrentPrice': 'Current price',
    'panel.Assets.QuickTable.Column.CurrentQty': '#hidden#',
    'panel.Assets.QuickTable.Column.CurrentValue': '#hidden#',
    'panel.Assets.QuickTable.Column.CurrentValueSell': 'Current value for sell',
    'panel.Assets.QuickTable.Column.DefaultSymbol': 'Default symbol',
    'panel.Assets.QuickTable.Column.ExchangeTrading': 'Trading exchange',
    'panel.Assets.QuickTable.Column.LiquidityRate': 'Liquidity rate, %',
    'panel.Assets.QuickTable.Column.SellAsset.cellsTooltip': 'Launch Order entry in order to sell asset',
    'panel.Assets.QuickTable.Column.SellAsset': 'Sell asset',
    'panel.Assets.QuickTable.Column.StartDayQty': 'Start day qty.',
    'panel.Assets.QuickTable.Column.StartDayQtyMargin': 'Start day qty. for margin available',
    'panel.Assets.QuickTable.Column.TodayTradedQty.descr': 'Net quantity of the asset traded today',
    'panel.Assets.QuickTable.Column.TodayTradedQty': 'Today traded qty.',
    'panel.Assets.QuickTable.Column.SellExchange': 'Sell exchange',
    'panel.Assets.Menu.SellAssetOn': 'Sell asset on',
    'panel.Assets.Menu.ShowToolbar': 'Show toolbar',
    'IsAllowedResponceReason.NoAssetsSelected': 'No assets selected',
    'IsAllowedResponceReason.MoreThanOneAssetIsSelected': 'More than one asset is selected',
    'panel.level2': 'Market depth',
    'panel.Level2.Age': '#hidden#',
    'panel.level2.Auction': ' AUC',
    'panel.Level2.AvgPrice': 'Avg. price',
    'panel.Level2.Contr. CCY Total value': 'Contr. CCY Total value',
    'panel.Level2.Contr. CCY value': 'Contr CCY value',
    'panel.Level2.DayTradeVolume': 'Volume',
    'panel.Level2.menu.View': 'View',
    'panel.Level2.menu.View.InstrInfo': 'Position bar',
    'panel.Level2.menu.View.Level1': 'Level1 bar',
    'panel.Level2.menu.View.Order Entry': 'Order entry',
    'panel.Level2.MPID': 'MPID',
    'panel.Level2.Number': 'Order',
    'panel.Level2.oe.BuyButton': 'Buy',
    'panel.Level2.oe.SellButton': 'Sell',
    'panel.Level2.Price': 'Price',
    'panel.Level2.Size': 'Size',
    'panel.Level2.Source': 'Source',
    'panel.Level2.Time': 'Time',
    'panel.Level2.TotalVol': 'Total size',
    'panel.Level2.NumberOfOrders': '#hidden#',
    'panel.log': 'Event log',
    'panel.menu.GroupBy': 'Group by',
    'panel.menu.new': 'New',
    'panel.menu.Remove': 'Remove',
    'panel.menu.AddToWL': 'Add',
    'panel.menu.RemoveFromWL': 'Remove',
    'panel.menu.resetToDefault': 'Reset to defaults',
    'panel.menu.renameList': 'Click to rename the list of instruments',
    'panel.menu.removeList': 'Click to remove the list of instruments',
    'panel.menu.renameTemplate': 'Click to rename template',
    'panel.menu.removeTemplate': 'Click to remove template',
    'panel.menu.saveList': 'Click to re-save the template',
    'panel.menu.symbolWasNotFound': 'Symbol was not found',
    'panel.neworder': 'Order entry',
    'panel.newOrderEntry.accountLabel': 'Account',
    'panel.newOrderEntry.AddOrder': 'Add order',
    'panel.newOrderEntry.AddAskButton': 'Sell Ask',
    'panel.newOrderEntry.AddBitButton': 'Buy Bid',
    'panel.newOrderEntry.AddSavedOrder': 'Add to saved orders',
    'panel.newOrderEntry.AddPaperPosition': 'Add paper order',
    'panel.newOrderEntry.amountLabel': 'Quantity',
    'panel.newOrderEntry.cashLabel': 'Cash',
    'panel.newOrderEntry.BuyAskButton': 'Buy Ask',
    'panel.newOrderEntry.BuyMarketButton': 'Buy Market',
    'panel.newOrderEntry.buySideCheckBox': 'Buy',
    'panel.newOrderEntry.delayedText': 'Delayed data',
    'panel.newOrderEntry.disclosedLabel': '#hidden#',
    'panel.newOrderEntry.disclosedLabel.tt': 'Set disclosed quantity',
    'panel.newOrderEntry.instrumentLabel': 'Symbol',
    'panel.newOrderEntry.Leverage': 'Leverage',
    'panel.newOrderEntry.limitPricePanel': 'Limit price',
    'panel.newOrderEntry.placeOrderButton': 'Place order',
    'panel.newOrderEntry.ProductType': 'Product type',
    'panel.newOrderEntry.RefreshMarginReq': 'Click to refresh margin data, or wait 30 sec.',
    'panel.newOrderEntry.riskDetailsLabel': 'Margin',
    'panel.newOrderEntry.SaveParams': 'Save params',
    'panel.newOrderEntry.SellBidButton': 'Sell Bid',
    'panel.newOrderEntry.SellMarketButton': 'Sell Market',
    'panel.newOrderEntry.sellSideCheckBox': 'Sell',
    'panel.newOrderEntry.Side': 'Side',
    'panel.newOrderEntry.singleOrderRadio': 'Single',
    'panel.newOrderEntry.slPriceRisk': 'SL price',
    'panel.newOrderEntry.slPriceRiskOffset': 'SL offset',
    'panel.newOrderEntry.snapshotText': 'Get snapshot',
    'panel.newOrderEntry.snapshotBtn.enabled.tt': 'Get real-time data snapshot',
    'panel.newOrderEntry.snapshotBtn.disabled.tt': 'Snapshot requests limit has been reached',
    'panel.newOrderEntry.stopLossCheckBox': 'SL price',
    'panel.newOrderEntry.stopLossCheckBox.offset': 'SL offset',
    'panel.newOrderEntry.stopPricePanel': 'Stop price',
    'panel.newOrderEntry.stopLimitLimitPrice': 'SL Limit price',
    'panel.newOrderEntry.stopLimitLimitPrice.tt': 'Set Limit price for SL',
    'panel.newOrderEntry.stopLimitLimitOffset': 'SL Limit offset',
    'panel.newOrderEntry.stopLimitLimitOffset.tt': 'Set Limit offset for SL order',
    'panel.newOrderEntry.stopInfoBtn.tt': 'Additional Limit price is available due to “Use stop limit instead of stop” is active',
    'panel.newOrderEntry.takeProfitCheckBox': 'TP price',
    'panel.newOrderEntry.takeProfitCheckBox.offset': 'TP offset',
    'panel.newOrderEntry.tifPanel': 'Validity',
    'panel.newOrderEntry.tpPriceRisk': 'TP price',
    'panel.newOrderEntry.tpPriceRiskOffset': 'TP offset',
    'panel.newOrderEntry.trstopOffset': 'Tr. stop offset',
    'panel.newOrderEntry.typePanel': 'Order type',
    'panel.newOrderEntry.stopLossNumeric.ToolTip.offset': 'Set Stop loss in offset values. To switch over to price, go to General Settings\\Trading defaults',
    'panel.newOrderEntry.stopLossNumeric.ToolTip': 'Set Stop loss in price values. To switch over to offset, go to General Settings\\Trading defaults',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip.offset': 'Set Take profit in offset values. To switch over to price, go to General Settings\\Trading defaults',
    'panel.newOrderEntry.takeProfitNumeric.ToolTip': 'Set Take profit in price values. To switch over to offset, go to General Settings\\Trading defaults',
    'panel.newOrderEntry.Comment': 'Comment',
    'panel.newOrderEntry.Comment.ToolTip': 'Specify the comment for this order',
    'panel.NewTrades.Account': 'Account',
    'panel.NewTrades.Amount': 'Quantity',
    'panel.NewTrades.Bought': 'Bought',
    'panel.NewTrades.Commission': 'Execution fee',
    'panel.NewTrades.Commission.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Date': 'Date/Time',
    'panel.NewTrades.Exchange': '#hidden#',
    'panel.NewTrades.ExchangeTrading': 'Trading exchange',
    'panel.NewTrades.ExecutionVenue': 'Execution venue',
    'panel.NewTrades.ExpirationDate': 'Exp. date',
    'panel.NewTrades.ExternalOrderId': '#hidden#',
    'panel.NewTrades.ExternalPrice': 'External price',
    'panel.NewTrades.ExternalTradeId': '#hidden#',
    'panel.NewTrades.instrType': 'Symb. type',
    'panel.NewTrades.Instrument': 'Symbol',
    'panel.NewTrades.Leverage': 'Leverage',
    'panel.NewTrades.Login': 'Login',
    'panel.NewTrades.Net_PL': 'Net P/L',
    'panel.NewTrades.Net_PL.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Operation': 'Side',
    'panel.NewTrades.OrderId': 'Order ID',
    'panel.NewTrades.OrderType': 'Order type',
    'panel.NewTrades.Price': 'Price',
    'panel.NewTrades.ProductType': 'Product type',
    'panel.NewTrades.PurchasePrice': 'Exposure',
    'panel.NewTrades.RealizedProfitLoss': 'Gross P/L',
    'panel.NewTrades.RealizedProfitLoss.InInstrumentQuotingCurrency': '#hidden#',
    'panel.NewTrades.Rebates': 'Rebates',
    'panel.NewTrades.Route': 'Route',
    'panel.NewTrades.Sold': 'Sold',
    'panel.NewTrades.StrikePrice': 'Strike price',
    'panel.NewTrades.Time': 'Time',
    'panel.NewTrades.TradeID': 'Trade ID',
    'panel.NewTrades.TradeIDLabel': '#hidden#',
    'panel.NewTrades.Description': 'Description',
    'panel.news': 'News',
    'panel.news.feed': 'Feed',
    'panel.news.feedComboBox.tt': 'Select news feed',
    'panel.news.all': 'All',
    'panel.news.received': 'Received',
    'panel.news.source': 'Source',
    'panel.news.subject': 'Subject',
    'panel.news.context': 'Content',
    'panel.news.category': 'Category',
    'panel.news.symbol': 'Symbol',
    'panel.news.priority': 'Priority',
    'panel.news.Priority_1': 'High',
    'panel.news.Priority_10': 'Low',
    'panel.news.Priority_5': 'Middle',
    'panel.news.Priority_All': 'All',
    'panel.news.menu.clearAll': 'Clear table',
    'panel.news.menu.removeOneRow': 'Remove',
    'panel.news.menu.ShowToolbar': 'Show toolbar',
    'panel.news.noData': 'No data to display',
    'panel.optionChain': 'Option chain',
    'panel.optionChain.call': 'Call',
    'panel.optionChain.put': 'Put',
    'panel.optionChain.lastPrice': 'Last',
    'panel.optionChain.iv': 'IV',
    'panel.optionChain.paperPosition': 'Paper position',
    'panel.optionChain.gamma': 'Gamma',
    'panel.optionChain.ask': 'Ask',
    'panel.optionChain.bid': 'Bid',
    'panel.optionChain.askSize': 'Ask size',
    'panel.optionChain.bidSize': 'Bid size',
    'panel.optionChain.delta': 'Delta',
    'panel.optionChain.theta': 'Theta',
    'panel.optionChain.vega': 'Vega',
    'panel.optionChain.volume': 'Volume',
    'panel.optionChain.monetaryValue': 'Monetary value',
    'panel.optionChain.monetaryValue.tt': 'Price value in cash',
    'panel.optionChain.rho': 'Rho',
    'panel.optionChain.change': 'Change',
    'panel.optionChain.changePercent': 'Change, %',
    'panel.optionChain.open': 'Open',
    'panel.optionChain.high': 'High',
    'panel.optionChain.low': 'Low',
    'panel.optionChain.prevClose': 'Prev. close',
    'panel.optionChain.lastTime': 'Last time',
    'panel.optionChain.openInterest': 'Open interest',
    'panel.optionChain.strike': 'Strike',
    'panel.optionAnalyzer': 'Analyzer',
    'panel.optionAnalyzer.chart.NoData': 'Add paper position to see the profile',
    'panel.optionAnalyzer.chartTypeLabel': 'Chart type',
    'panel.optionAnalyzer.linesLabel': 'Lines',
    'panel.optionAnalyzer.tPlusLabel': 'T+',
    'panel.optionAnalyzer.tPlusDaysLabel': 'T+, days',
    'panel.optionAnalyzer.volatityLabel': 'Volatility, %',
    'panel.optionAnalyzer.volatityChangeLabel': 'Volatility change, %',
    'panel.optionAnalyzer.daysLabel': 'Days',
    'panel.optionAnalyzer.modeLabel': 'Mode',
    'panel.optionAnalyzer.infoWindow.intrinsicPL': 'Intrinsic P/L',
    'panel.optionAnalyzer.infoWindow.PL': 'P/L',
    'panel.optionAnalyzer.infoWindow.delta': 'Delta',
    'panel.optionAnalyzer.infoWindow.gamma': 'Gamma',
    'panel.optionAnalyzer.infoWindow.vega': 'Vega',
    'panel.optionAnalyzer.infoWindow.theta': 'Theta',
    'panel.optionAnalyzer.infoWindow.rho': 'Rho',
    'panel.optionAnalyzer.infoWindow.whatif': 'What if',
    'panel.optionMaster': 'Option master',
    'panel.optionMaster.settingsHeader': 'Option master - settings',
    'panel.optionMaster.optionTraderInfoControl.currentPortfolio': 'Current portfolio',
    'panel.optionMaster.optionTraderInfoControl.paperPositions': 'Portfolio + analyzed paper positions',
    'panel.optionMaster.optionTraderInfoControl.greeks': 'Portfolio greeks',
    'panel.optionOrders': 'Working orders',
    'panel.optionPositions': 'Positions',
    'panel.optionPaperPositions': 'Test on paper',
    'panel.optionPaperPositions.addUnderlier': 'Add underlier',
    'panel.optionPaperPositions.cancelAll': 'Cancel all',
    'panel.optionPaperPositions.placeSelected': 'Place selected',
    'panel.optionPaperPositions.shortPositionsAreForbidden': 'Short positions are forbidden',
    'panel.optionPaperPositions.Side': 'Side',
    'panel.optionPaperPositions.Symbol': 'Symbol',
    'panel.optionPaperPositions.Strike': 'Strike price',
    'panel.optionPaperPositions.Cash': 'Cash',
    'panel.optionPaperPositions.Quantity': 'Quantity',
    'panel.optionPaperPositions.OrderType': 'Order type',
    'panel.optionPaperPositions.ProductType': 'Product type',
    'panel.optionPaperPositions.Offset': 'Offset',
    'panel.optionPaperPositions.Price': 'Price',
    'panel.optionPaperPositions.Link': 'Link',
    'panel.optionPaperPositions.StopPrice': 'Stop price (offset)',
    'panel.optionPaperPositions.CurrentPrice': 'Current price',
    'panel.optionPaperPositions.TIF': 'Validity',
    'panel.optionPaperPositions.SL_Offset': 'SL offset',
    'panel.optionPaperPositions.SL_Price': 'SL price',
    'panel.optionPaperPositions.TP_Offset': 'TP offset',
    'panel.optionPaperPositions.TP_Price': 'TP price',
    'panel.optionPaperPositions.Delta': 'Delta',
    'panel.optionPaperPositions.TrailingStop': 'Trailing stop',
    'panel.optionPaperPositions.Gamma': 'Gamma',
    'panel.optionPaperPositions.Vega': 'Vega',
    'panel.optionPaperPositions.Theta': 'Theta',
    'panel.optionPaperPositions.IV': 'IV',
    'panel.optionPaperPositions.Fee': 'Fee',
    'panel.optionPaperPositions.UnderlierDate': 'Underlier exp. date',
    'panel.optionPaperPositions.Type': 'Type',
    'panel.optionPaperPositions.DaysToExpire': 'Days to expire',
    'panel.optionPaperPositions.Date': 'Exp. date',
    'panel.optionPaperPositions.Rho': 'Rho',
    'panel.optionPaperPositions.SLL_Offset': 'SL limit offset',
    'panel.optionPaperPositions.SLL_Price': 'SL limit price',
    'panel.optionPaperPositions.Leverage': 'Leverage',
    'panel.optionPaperPositions.Analyse': 'Analyse',
    'panel.optionPaperPositions.Send': 'Send',
    'panel.optionPaperPositions.Remove': 'Remove',
    'panel.optionPaperPositions.Position': 'Position',
    'panel.optionPaperPositions.PaperPosition': 'Paper',
    'panel.optionPaperPositions.menu.ShowTotals': 'Show totals',
    'panel.optionVolatilityLab': 'Volatility lab',
    'panel.optionVolatilityLab.ChartNoData': 'Select required series to see volatility smile',
    'panel.OrderBook.Account': 'Account',
    'panel.OrderBook.Action': 'Side',
    'panel.OrderBook.Bought': 'Bought',
    'panel.OrderBook.Date': 'Date/Time',
    'panel.OrderBook.DislocedQty': '#hidden#',
    'panel.OrderBook.Event': 'Event',
    'panel.OrderBook.ExpirationDate': 'Exp. date',
    'panel.OrderBook.ExternalOrderID': '#hidden#',
    'panel.OrderBook.FilledQuantity': '#hidden#',
    'panel.OrderBook.instrType': 'Symb. type',
    'panel.OrderBook.Instrument': 'Symbol',
    'panel.OrderBook.Login': 'Login',
    'panel.OrderBook.Message': 'Message',
    'panel.OrderBook.OrderNumber': 'Order ID',
    'panel.OrderBook.Price': 'Price',
    'panel.OrderBook.ProductType': 'Product type',
    'panel.OrderBook.Quantity': 'Quantity',
    'panel.OrderBook.ReminingQuantity': '#hidden#',
    'panel.OrderBook.RemoteID': 'Remote ID',
    'panel.OrderBook.Route': 'Route',
    'panel.OrderBook.Sold': 'Sold',
    'panel.OrderBook.StopPrice': 'Stop price',
    'panel.OrderBook.StrikePrice': 'Strike price',
    'panel.OrderBook.TIF': 'Validity',
    'panel.OrderBook.Time': 'Time',
    'panel.OrderBook.TraderID': 'Modified by user',
    'panel.OrderBook.Type': 'Type',
    'panel.OrderBook.ExchangeTrading': 'Trading exchange',
    'panel.OrderBook.Description': 'Description',
    'panel.OrderBook.Leverage': 'Leverage',
    'panel.orderEntry': 'Order entry',
    'panel.orderHistory': 'Orders history',
    'panel.orderHistory.print': '#hidden#',
    'panel.orders': 'Working orders',
    'panel.orders.Account': 'Account',
    'panel.orders.action': 'Side',
    'panel.orders.AvgFilledPrice': '#hidden#',
    'panel.orders.BindTo': 'Bound to',
    'panel.orders.Cancel': 'Cancel',
    'panel.orders.Comment': '#hidden#',
    'panel.orders.Comments': 'Comments',
    'panel.orders.Ct_Ahead': 'Ct. ahead',
    'panel.orders.CurrentPrice': 'Current price',
    'panel.orders.date': 'Date',
    'panel.orders.date_time': 'Date/Time',
    'panel.orders.disclosedQty': '#hidden#',
    'panel.orders.Event': 'Event',
    'panel.orders.Exchange': 'Exchange',
    'panel.orders.ExchangeTrading': 'Trading exchange',
    'panel.orders.ExpirationDate': 'Exp. date',
    'panel.orders.ExternalOrderId': '#hidden#',
    'panel.orders.ExternalOrderStatus': '#hidden#',
    'panel.orders.ExternalOrderStatus_0': 'New',
    'panel.orders.ExternalOrderStatus_1': 'Applied',
    'panel.orders.ExternalOrderStatus_2': 'Partially filled',
    'panel.orders.ExternalOrderStatus_3': 'Filled',
    'panel.orders.ExternalOrderStatus_4': 'Rejected',
    'panel.orders.ExternalOrderStatus_5': 'Cancelled',
    'panel.orders.ExternalOrderStatus_6': 'Canceling for modification',
    'panel.orders.ExternalOrderStatus_7': 'Pending modification',
    'panel.orders.ExternalOrderStatus_8': 'Pending cancel',
    'panel.orders.ExternalOrderStatus_9': 'Freeze',
    'panel.orders.ExternalOrderStatus_10': 'Waiting market',
    'panel.orders.ExternalOrderStatus_11': 'Unplaced',
    'panel.orders.ExternalOrderStatus_12': 'Suspended',
    'panel.orders.GroupId': 'MAM group ID',
    'panel.orders.InitReq': 'Initial req.',
    'panel.orders.Instr_Type': 'Symb. type',
    'panel.orders.Login': 'Login',
    'panel.orders.Leverage': 'Leverage',
    'panel.orders.Market': 'Market',
    'panel.orders.menu.CancelAllByInstrument': 'Cancel all by selected symbol',
    'panel.orders.menu.CancelAllForAccount': 'Cancel all for selected account',
    'panel.orders.menu.CancelAllOrders': 'Cancel all orders',
    'panel.orders.menu.CancelOrder': 'Cancel order',
    'panel.orders.menu.ExecuteAsMarket': 'Change to Market',
    'panel.orders.menu.ExecuteAsMarket.question': 'Are you sure you want to execute order at market price?',
    'panel.orders.menu.ModifyOrder': 'Modify order...',
    'panel.orders.menu.MoreOneSymbolInfoDisabled.tt': 'More than one order selected',
    'panel.orders.menu.NoSymbolInfoDisabled.tt': 'No orders selected',
    'panel.orders.menu.Remove': 'Cancel order',
    'panel.orders.menu.ShowToolbar': 'Show toolbar',
    'panel.orders.OCOGrouID': 'OCO group ID',
    'panel.orders.price': 'Price',
    'panel.orders.ProductType': 'Product type',
    'panel.orders.quantity': 'Quantity',
    'panel.orders.QuantityFilled': 'Qty. filled',
    'panel.orders.QuantityRemaining': 'Qty. remaining',
    'panel.orders.Reference': 'Order ID',
    'panel.orders.RemoteID': 'Remote ID',
    'panel.orders.Route': 'Route',
    'panel.orders.SL': 'SL',
    'panel.orders.SL_Offset': 'SL offset',
    'panel.orders.SL_Price': 'SL price',
    'panel.orders.SLL_Price': 'SL limit price',
    'panel.orders.SLL_Offset': 'SL limit offset',
    'panel.orders.Status': 'Status',
    'panel.orders.StopPrice': 'Stop price',
    'panel.orders.StrikePrice': 'Strike price',
    'panel.orders.symbol': 'Symbol',
    'panel.orders.symbolDescription': 'Symbol description',
    'panel.orders.TIF': 'Validity',
    'panel.orders.time': 'Time',
    'panel.orders.Token': 'Order ID',
    'panel.orders.TP': 'TP',
    'panel.orders.TP_Offset': 'TP offset',
    'panel.orders.TP_Price': 'TP price',
    'panel.orders.TraderID': 'Trader ID',
    'panel.orders.type': 'Type',
    'panel.orders.UserGroup': 'User group',
    'panel.orders.showChartMI': '#hidden#',
    'panel.orders.showMDMI': '#hidden#',
    'panel.orders.showTSMI': '#hidden#',
    'panel.orders.showOEMI': '#hidden#',
    'property.Columns': 'Columns',
    'property.ColorBy': 'Color by',
    'property.Coloring mode.None': 'None',
    'property.Coloring mode.By difference': 'By difference',
    'property.Coloring mode.By sign': 'By sign',
    'property.ColoringMethod': 'Coloring method',
    'property.ColoringMethod.Classic': 'Classic',
    'property.ColoringMethod.HistoricalVolatility': 'Historical volatility',
    'property.Calculation': 'Calculation',
    'property.Calculation method.Net Qty': 'Net Qty',
    'property.Calculation method.Average': 'Average',
    'property.Calculation method.Count': 'Count',
    'property.Calculation method.Absolute sum': 'Absolute sum',
    'property.Calculator': 'Calculator',
    'property.SummaryGrid.None': 'None',
    'property.SummaryGrid.Vertical': 'Vertical',
    'property.SummaryGrid.Horizontal': 'Horizontal',
    'property.SummaryGrid.HorVer': 'Vertical & horizontal',
    'property.SummaryColorsForColumn0': 'Colors',
    'property.SummaryColorsForColumn1': 'Colors',
    'property.SummaryColorsForColumn2': 'Colors',
    'property.SummaryColorsForColumn3': 'Colors',
    'property.SummaryColorsForColumn4': 'Colors',
    'property.SummaryColorsForColumn5': 'Colors',
    'property.SummaryColorsForColumn6': 'Colors',
    'property.SummaryColorsForColumn7': 'Colors',
    'property.SummaryColorsForColumn8': 'Colors',
    'property.SummaryColorsForColumn9': 'Colors',
    'property.SummaryColorsForColumn10': 'Colors',
    'property.SummaryColorsForColumn11': 'Colors',
    'property.SummaryColorsForColumn12': 'Colors',
    'property.SummaryColorsForColumn13': 'Colors',
    'property.SummaryColorsForColumn14': 'Colors',
    'property.SummaryColorsForColumn15': 'Colors',
    'property.SummaryShowTotalForColumn5': 'Show totals',
    'property.SummaryShowTotalForColumn6': 'Show totals',
    'property.SummaryShowTotalForColumn7': 'Show totals',
    'property.SummaryShowTotalForColumn8': 'Show totals',
    'property.SummaryShowTotalForColumn9': 'Show totals',
    'property.SummaryShowTotalForColumn10': 'Show totals',
    'property.SummaryShowTotalForColumn11': 'Show totals',
    'property.SummaryShowTotalForColumn12': 'Show totals',
    'property.SummaryShowTotalForColumn14': 'Show totals',
    'property.SummaryShowTotalForColumn15': 'Show totals',
    'property.Coloring mode4': 'Coloring mode',
    'property.Coloring mode5': 'Coloring mode',
    'property.Coloring mode6': 'Coloring mode',
    'property.Coloring mode7': 'Coloring mode',
    'property.Coloring mode8': 'Coloring mode',
    'property.Coloring mode9': 'Coloring mode',
    'property.Coloring mode10': 'Coloring mode',
    'property.Coloring mode11': 'Coloring mode',
    'property.Coloring mode12': 'Coloring mode',
    'property.Coloring mode14': 'Coloring mode',
    'property.Coloring mode15': 'Coloring mode',
    'property.Increase color4': 'Increase color',
    'property.Increase color5': 'Increase color',
    'property.Increase color6': 'Increase color',
    'property.Increase color7': 'Increase color',
    'property.Increase color8': 'Increase color',
    'property.Increase color9': 'Increase color',
    'property.Increase color10': 'Increase color',
    'property.Increase color11': 'Increase color',
    'property.Increase color12': 'Increase color',
    'property.Increase color14': 'Increase color',
    'property.Increase color15': 'Increase color',
    'property.Decrease color4': 'Decrease color',
    'property.Decrease color5': 'Decrease color',
    'property.Decrease color6': 'Decrease color',
    'property.Decrease color7': 'Decrease color',
    'property.Decrease color8': 'Decrease color',
    'property.Decrease color9': 'Decrease color',
    'property.Decrease color10': 'Decrease color',
    'property.Decrease color11': 'Decrease color',
    'property.Decrease color12': 'Decrease color',
    'property.Decrease color14': 'Decrease color',
    'property.Decrease color15': 'Decrease color',
    'property.subscriptions.confirmSubscribeAndUnsubscribe': 'Show information message about subscribe/unsubscribe',
    'panel.summary.Symbol': 'Symbol',
    'panel.summary.SymbolDescription': 'Symbol description',
    'panel.summary.SymbolType': 'Symbol type',
    'panel.summary.TradingExchange': 'Trading exchange',
    'panel.summary.OpenPrice': 'Open price',
    'panel.summary.CurrentPrice': 'Current price',
    'panel.summary.Quantity': 'Quantity',
    'panel.summary.PosExposure': 'Pos. exposure',
    'panel.summary.PosValue': 'Pos. value',
    'panel.summary.GrossPL': 'Gross P/L',
    'panel.summary.NetPL': 'Net P/L',
    'panel.summary.Fee': 'Fee',
    'panel.summary.ReturnPercent': 'Return, %',
    'panel.summary.Sparkline': 'Sparkline',
    'panel.summary.PortfolioWeight': 'Portfolio weight',
    'panel.summary.ModelAllocation': 'Model allocation',
    'panel.positions': 'Positions',
    'panel.positions.Account': 'Account',
    'panel.positions.AccruedInterest': 'Accrued interest',
    'panel.positions.AccruedInterest.tt': 'Interest accrued on position',
    'panel.positions.AssetName': '#hidden#',
    'panel.positions.AvailableForSell': '#hidden#',
    'panel.positions.Basis': 'Open price',
    'panel.positions.Close': 'Close',
    'panel.positions.comments': 'Comments',
    'panel.positions.commissions': 'Fee',
    'panel.positions.commissions.InstrumentCurrency': '#hidden#',
    'panel.positions.confirmation.Reverse': 'Reverse',
    'panel.positions.confirmation.ReverseAllPositions': 'Are you sure you want to reverse all positions?',
    'panel.positions.confirmation.ReverseByInstrument': 'Are you sure you want to reverse all positions by selected symbol and account?',
    'panel.positions.confirmation.ReverseSelectedPositions': 'Are you sure you want to reverse selected positions?',
    'panel.positions.confirmation.ModifyProductType': 'Change Product type from {0} to {1} for {2}, {3}, {4} ?',
    'panel.positions.cur_price': 'Current price',
    'panel.positions.date_time': 'Date/Time',
    'panel.positions.Delta': 'Delta',
    'panel.positions.ExpDate': 'Exp. date',
    'panel.positions.exposition_usd': 'Pos. exposure',
    'panel.positions.Gamma': 'Gamma',
    'panel.positions.GroupId': 'MAM group ID',
    'panel.positions.InitReq': 'Initial req.',
    'panel.positions.IV': 'IV',
    'panel.positions.Login': 'Login',
    'panel.positions.Leverage': 'Leverage',
    'panel.positions.MaintReq': 'Maintenance req.',
    'panel.positions.MarginSuplus': 'Surpl./Def. margin',
    'panel.positions.menu.Close': 'Close position',
    'panel.positions.menu.MutualClose': 'Mutual close…',
    'panel.positions.menu.ExercisePosition': 'Exercise position',
    'panel.positions.menu.ExerciseCancel': 'Cancel exercise',
    'panel.positions.menu.ExercisePositionDisabled.tt': 'More than one position is selected',
    'panel.positions.menu.CancelExerciseDisabledCuzTrSession.tt': 'Operation is not allowed by session',
    'panel.positions.menu.ModifyProductType': 'Modify Product type',
    'panel.positions.menu.Modify': 'Modify position',
    'panel.positions.menu.MoreOneSymbolInfoDisabled.tt': 'More than one position selected',
    'panel.positions.menu.NoSymbolInfoDisabled.tt': 'No positions selected',
    'panel.positions.menu.ShowToolbar': 'Show toolbar',
    'panel.positions.menu.ShowTotals': 'Show totals',
    'panel.positions.menu.DisplayTrades': 'Display trades',
    'panel.positions.menu.ShowGroupQtyAs': '#hidden#',
    'panel.positions.menu.ShowGroupQtyAs.locKey': 'Show group qty as',
    'panel.positions.menu.ShowGroupQtyAs.Total': 'Total qty',
    'panel.positions.menu.ShowGroupQtyAs.Net': 'Net qty',
    'panel.positions.menu.View': 'View',
    'panel.positions.Net_PL': 'Net P/L',
    'panel.positions.Net_PL.InstrumentCurrency': '#hidden#',
    'panel.positions.Net_PL_Percent': 'Net P/L %',
    'panel.positions.Net_PL_Percent.Tooltip': 'Net P/L in % from the Position exposure',
    'panel.positions.Operation': 'Side',
    'panel.positions.PL_ticks': 'P/L, offset',
    'panel.positions.pos_number': 'Position ID',
    'panel.positions.PositionValue': 'Pos. value',
    'panel.positions.ProductType': 'Product type',
    'panel.positions.profit_usd': 'Gross P/L',
    'panel.positions.profit_usd.InstrumentCurrency': '#hidden#',
    'panel.positions.Qunatity': 'Quantity',
    'panel.positions.RealizedPnL': '#hidden#',
    'panel.positions.RealizedPnL.InstrumentCurrency': '#hidden#',
    'panel.positions.Rho': 'Rho',
    'panel.positions.route': 'Route',
    'panel.positions.SL': 'SL price',
    'panel.positions.SL_Value': 'SL, value',
    'panel.positions.SLL': 'SL limit priсe',
    'panel.positions.strike': 'Strike price',
    'panel.positions.swaps': 'Swaps',
    'panel.positions.Symbol': 'Symbol',
    'panel.positions.SymbolDescription': 'Symbol description',
    'panel.positions.Theta': 'Theta',
    'panel.positions.TP': 'TP price',
    'panel.positions.type': 'Symb. type',
    'panel.positions.UsedMargin': 'Used margin',
    'panel.positions.UserGroup': 'User group',
    'panel.positions.Vega': 'Vega',
    'panel.positions.ExchangeTrading': 'Trading exchange',
    'panel.positions.showChartMI': '#hidden#',
    'panel.positions.showMDMI': '#hidden#',
    'panel.positions.showTSMI': '#hidden#',
    'panel.positions.showOEMI': '#hidden#',
    'panel.positions.OrderType': 'Order type',
    'panel.positionsBalance': 'Positions balance',
    'panel.positionsBalance.Symbol': 'Symbol',
    'panel.positionsBalance.NetPositionQty': 'Net position qty.',
    'panel.positionsBalance.BreakEvenPoint': 'Break-even point',
    'panel.positionsBalance.BreakEven': 'Break-even',
    'panel.positionsBalance.CurrentPrice': 'Current price',
    'panel.positionsBalance.GrossPL': 'Gross P/L',
    'panel.positionsBalance.LongQty': 'Long qty.',
    'panel.positionsBalance.AverageLong': 'Average long',
    'panel.positionsBalance.ShortQty': 'Short qty.',
    'panel.positionsBalance.AverageShort': 'Average short',
    'panel.positionsBalance.GrossExposure': 'Gross exposure',
    'panel.positionsBalance.NetExposure': 'Net exposure',
    'panel.positionsBalance.StrikePrice': 'Strike price',
    'panel.positionsBalance.ExpDate': 'Exp. date',
    'panel.positionsBalance.PositionValue': 'Position value',
    'panel.positionsBalance.SymbType': 'Symb. type',
    'panel.positionsBalance.ProductType': 'Product type',
    'panel.positionsBalance.Symbol.tt': 'Symbol name',
    'panel.positionsBalance.NetPositionQty.tt': 'Net amount of positions (Long qty - Short qty)',
    'panel.positionsBalance.BreakEvenPoint.tt': 'Break-even point',
    'panel.positionsBalance.CurrentPrice.tt': 'Current market price',
    'panel.positionsBalance.GrossPL.tt': 'Profit/Loss in account currency',
    'panel.positionsBalance.LongQty.tt': 'Amount of long positions',
    'panel.positionsBalance.AverageLong.tt': 'Average price of long positions',
    'panel.positionsBalance.ShortQty.tt': 'Amount of short positions',
    'panel.positionsBalance.AverageShort.tt': 'Average price of short positions',
    'panel.positionsBalance.GrossExposure.tt': 'Gross exposure of opened positions',
    'panel.positionsBalance.NetExposure.tt': 'Net exposure of opened positions',
    'panel.positionsBalance.StrikePrice.tt': 'Strike price',
    'panel.positionsBalance.ExpDate.tt': 'Expiration date',
    'panel.positionsBalance.PositionValue.tt': 'Position value',
    'panel.positionsBalance.SymbType.tt': 'Symb. type (market/category)',
    'panel.positionsBalance.menu.MoreOneSymbolInfoDisabled.tt': 'More than one symbol selected',
    'panel.positionsBalance.menu.NoSymbolInfoDisabled.tt': 'No symbols selected',
    'panel.positionsBalance.menu.ShowToolbar': 'Show toolbar',
    'Web.mobile.active.PositionsBalance.EmptyPlaceholder': 'As of yet there are no positions',
    'panel.positions.StartOfDayQty.tt': 'Start of day uploaded qty',
    'panel.positions.StartOfDayQty': '#hidden#',
    'panel.savedOrders': 'Saved orders',
    'panel.savedOrders.placeButtonText': 'Place active',
    'panel.savedOrders.account': 'Account',
    'panel.savedOrders.account.descr': 'Account name',
    'panel.savedOrders.active': 'Active',
    'panel.savedOrders.active.descr': 'Make order active',
    'panel.savedOrders.ask': 'Ask',
    'panel.savedOrders.ask.descr': 'Last best Ask price',
    'panel.savedOrders.bid': 'Bid',
    'panel.savedOrders.bid.descr': 'Last best Bid price',
    'panel.savedOrders.cash': 'Cash',
    'panel.savedOrders.cash.descr': 'Cash order quantity',
    'panel.savedOrders.ChartCoef': 'Chart coef.',
    'panel.savedOrders.ChartCoef.descr': 'Price coefficient which is used for chart building',
    'panel.savedOrders.disclosedQty': '#hidden#',
    'panel.savedOrders.ExchangeTrading': 'Trading exchange',
    'panel.savedOrders.ExchangeTrading.descr': 'Trading exchange',
    'panel.savedOrders.ExpiryDate': 'Exp. date',
    'panel.savedOrders.ExpiryDate.descr': 'Exp. date',
    'panel.savedOrders.instrument': 'Symbol',
    'panel.savedOrders.instrument.descr': 'Symbol name',
    'panel.savedOrders.last': 'Last',
    'panel.savedOrders.last.descr': 'Last trade price',
    'panel.savedOrders.Leverage': 'Leverage',
    'panel.savedOrders.Link': 'Link',
    'panel.savedOrders.Link.descr': 'Link',
    'panel.savedOrders.LinkedPriceOffset': 'Offset',
    'panel.savedOrders.LinkedPriceOffset.descr': 'Offset',
    'panel.savedOrders.menu.AddOrder': 'Add order...',
    'panel.savedOrders.menu.EditOrder': 'Edit order',
    'panel.savedOrders.menu.RemoveOrder': 'Remove order',
    'panel.savedOrders.menu.RemoveAll': 'Remove all',
    'panel.savedOrders.menu.SavedOrdersLists': 'Saved lists',
    'panel.savedOrders.menu.ShowToolbar': 'Show toolbar',
    'panel.savedOrders.menu.ShowTotals': 'Show totals',
    'panel.savedOrders.operation': 'Side',
    'panel.savedOrders.operation.descr': 'Buy or Sell',
    'panel.savedOrders.OptionsType': 'Options type',
    'panel.savedOrders.OptionsType.descr': 'Options type',
    'panel.savedOrders.OrderType': 'Order type',
    'panel.savedOrders.OrderType.descr': 'Order type (Market, Limit, Stop, etc.)',
    'panel.savedOrders.price': 'Price',
    'panel.savedOrders.price.descr': 'Limit or market price',
    'panel.savedOrders.ProductType': 'Product type',
    'panel.savedOrders.quantity': 'Quantity',
    'panel.savedOrders.quantity.descr': 'Set quantity',
    'panel.savedOrders.RemoveOrder': 'Remove',
    'panel.savedOrders.route': 'Route',
    'panel.savedOrders.SL_Offset': 'SL offset',
    'panel.savedOrders.SLL_Offset': 'SL limit offset',
    'panel.savedOrders.SLL_Offset.descr': 'Limit offset for SL',
    'panel.savedOrders.SL_Price': 'SL price',
    'panel.savedOrders.SLL_Price': 'SL limit price',
    'panel.savedOrders.SLL_Price.descr': 'Limit price for SL order',
    'panel.savedOrders.status': 'Status',
    'panel.savedOrders.status.descr': 'Status of order (active, pending)',
    'panel.savedOrders.stopPrice': 'Stop price',
    'panel.savedOrders.stopPrice.descr': 'Stop price',
    'panel.savedOrders.StrikePrice': 'Strike price',
    'panel.savedOrders.StrikePrice.descr': 'Strike price',
    'panel.savedOrders.SubmitOrder': 'Place',
    'panel.savedOrders.SubmitOrder.descr': 'Send this order',
    'panel.savedOrders.SymbolType': 'Symb. type',
    'panel.savedOrders.SymbolType.descr': 'Symbol type (market/category)',
    'panel.savedOrders.tif': 'Validity',
    'panel.savedOrders.tif.descr': 'Order validity (GTC, IOC, etc.)',
    'panel.savedOrders.TP_Offset': 'TP offset',
    'panel.savedOrders.TP_Price': 'TP price',
    'panel.savedOrders.Trailing': 'Trailing',
    'panel.savedOrders.TrailingStop': 'Trailing stop',
    'panel.savedOrders.TrailingStop.descr': 'Trailing stop',
    'panel.savedOrders.Error.AccountIsUnknown': 'Account is unknown',
    'panel.savedOrders.Error.SymbolIsUnknown': 'Symbol is unknown',
    'panel.savedOrders.Error.QuantityLessMin': 'Order quantity should be more than minimum lot',
    'panel.savedOrders.Error.QuantityMoreMax': 'Order quantity should be less than maximum lot',
    'panel.savedOrders.Error.QuantityNotMultiple': 'Quantity is not multiple to min. change',
    'panel.savedOrders.Error.CashNotMultiple': 'Cash is not multiple to min. change',
    'panel.savedOrders.Error.CashNotAvailable': 'Cash trading is forbidden',
    'panel.savedOrders.Error.CashWrongOrderType': 'Order type is forbidden for Cash',
    'panel.savedOrders.AdditionalParams': 'Additional params',
    'panel.settings.Information': 'Information',
    'panel.settings.saveDefault': 'Saved successfully!',
    'panel.statement': 'Statement',
    'panel.statement.print': 'not hidden',
    'panel.statement.Account': 'Account',
    'panel.statement.Instrument': 'Symbol',
    'panel.statement.Date': 'Date',
    'panel.statement.Operation_type': 'Operation type',
    'panel.statement.Operation_ID': 'Operation ID',
    'panel.statement.Amount': 'Amount',
    'panel.statement.Open_price': 'Open price',
    'panel.statement.Close_price': 'Close price',
    'panel.statement.Quantity': 'Quantity',
    'panel.statement.Currency': 'Currency',
    'panel.statement.Trade_opening_date': 'Position opening date',
    'panel.statement.FX_rate': '#hidden#',
    'panel.statement.positionID': 'Position ID',
    'panel.statement.tradeID': 'Trade ID',
    'panel.statement.Portfolio_overnight_fee': 'Portfolio overnight fee',
    'panel.statement.searchBtn.tt': 'Click to Search, for view info in Statement panel',
    'panel.statement.noData': 'There is no account history for the selected time frame and filters.',
    'StatementItem.PositionID.GroupKey.Format': 'Position#{0}',
    'StatementItem.PositionID.GroupKey.EmptyValue': 'Others',
    'panel.terceraChart': 'Chart',
    'panel.tradingViewChart': 'Chart TradingView',
    'panel.tradingViewChart.header': 'Chart TradingView',
    'panel.TerceraSymbolLookupDropDownForm.Add': 'Add',
    'panel.TerceraSymbolLookupDropDownForm.All': 'All',
    'panel.TerceraSymbolLookupDropDownForm.Bonds': 'Bonds',
    'panel.TerceraSymbolLookupDropDownForm.Cancel': 'Cancel',
    'panel.TerceraSymbolLookupDropDownForm.CFDs': 'CFDs',
    'panel.TerceraSymbolLookupDropDownForm.collapceAllButton': 'Collapse all',
    'panel.TerceraSymbolLookupDropDownForm.crypto': 'Crypto CCY',
    'panel.TerceraSymbolLookupDropDownForm.Equities': 'Equities',
    'panel.TerceraSymbolLookupDropDownForm.ETFs': 'ETFs',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.All exchanges': 'All exchanges',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.exchanges': 'exchanges',
    'panel.TerceraSymbolLookupDropDownForm.exchangeComboBox.No exchanges': 'No exchanges',
    'panel.TerceraSymbolLookupDropDownForm.expandExchangesButton': 'Expand exchanges',
    'panel.TerceraSymbolLookupDropDownForm.expandTypesButton': 'Expand types',
    'panel.TerceraSymbolLookupDropDownForm.expandGroupsButton': 'Expand groups',
    'panel.TerceraSymbolLookupDropDownForm.Other': 'Other',
    'panel.TerceraSymbolLookupDropDownForm.Forex': 'Forex',
    'panel.TerceraSymbolLookupDropDownForm.Forward': 'Forward',
    'panel.TerceraSymbolLookupDropDownForm.Futures': 'Futures',
    'panel.TerceraSymbolLookupDropDownForm.indices': 'Indices',
    'panel.TerceraSymbolLookupDropDownForm.Options': 'Options',
    'panel.TerceraSymbolLookupDropDownForm.Options.Put': 'Put Options',
    'panel.TerceraSymbolLookupDropDownForm.Options.Call': 'Call Options',
    'panel.TerceraSymbolLookupDropDownForm.Portfolio': 'Synthetic',
    'panel.TerceraSymbolLookupDropDownForm.Select': 'Select',
    'panel.TerceraSymbolLookupDropDownForm.Select all': 'Select all',
    'panel.TerceraSymbolLookupDropDownForm.Show additionl info': 'Show additional info',
    'panel.TerceraSymbolLookupDropDownForm.Spot': 'Spot',
    'panel.TerceraSymbolLookupDropDownForm.Spreadbet': 'Spreadbet',
    'panel.TerceraSymbolLookupDropDownForm.TBill': 'T-bill',
    'panel.TerceraSymbolLookupDropDownForm.Corporate': 'Corporate',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.All types': 'All types',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.No types': 'No types',
    'panel.TerceraSymbolLookupDropDownForm.typeComboBox.types': 'types',
    'panel.timeSales': 'Time & Sales',
    'panel.timeSales.Agressor flag': 'Side',
    'panel.timeSales.AgressorFlag.Auction': 'Auction',
    'panel.timeSales.AgressorFlag.Buy': 'Buy',
    'panel.timeSales.AgressorFlag.CrossTrade': 'Cross Trade',
    'panel.timeSales.AgressorFlag.None': 'None',
    'panel.timeSales.AgressorFlag.Sell': 'Sell',
    'panel.timeSales.AgressorFlag.RLP': 'RLP',
    'panel.timeSales.ask': 'Ask',
    'panel.timeSales.AskBidSize': 'BidxAsk size',
    'panel.timeSales.AskSource': 'Asks MPID',
    'panel.timeSales.bid': 'Bid',
    'panel.timeSales.BidSource': 'Bids MPID',
    'panel.timeSales.BuyerSource': 'Buyer',
    'panel.timeSales.Exchange': 'Source',
    'panel.timeSales.Instrument': 'Symbol',
    'panel.timeSales.MenuName': 'Time & Sales',
    'panel.timeSales.Price': 'Price',
    'panel.timeSales.SellerSource': 'Seller',
    'panel.timeSales.Size': 'Size',
    'panel.timeSales.Time': 'Time',
    'panel.timeSales.Type': 'Symb. type',
    'panel.tooltiper.System': 'System',
    'panel.trades': 'Filled orders',
    'panel.trades.print': '#hidden#',
    'panel.tradingCentral': 'Trading central',
    'panel.tradingCentral.screenBtn.text': 'Screen',
    'panel.tradingCentral.chartBtn.text': 'Chart',
    'panel.tradingCentral.ScreenButton': 'not hidden',
    'panel.tradingCentral.settings': 'Settings...',
    'panel.tradingCentral.settings.technicalAnalysis.term': 'Term',
    'panel.tradingCentral.settings.technicalAnalysis.days': 'Days',
    'panel.tradingCentral.settings.technicalAnalysis.lastOnly': 'Last only',
    'panel.tradingCentral.settings.alerts.lastOnly': 'Last only',
    'panel.tradingCentral.settings.alerts.days': 'Days',
    'panel.tradingCentral.settings.candlestick.lastOnly': 'Last only',
    'panel.tradingCentral.settings.candlestick.days': 'Days',
    'panel.tradingCentral.term.All': 'All',
    'panel.tradingCentral.term.Intraday': 'Intraday',
    'panel.tradingCentral.term.Short term': 'Short term',
    'panel.tradingCentral.term.Middle term': 'Middle term',
    'panel.tradingCentral.chart.resistance': 'Resistance',
    'panel.tradingCentral.chart.support': 'Support',
    'panel.tradingCentral.chart.pivot': 'Pivot',
    'panel.watchlist': 'Watchlist',
    'panel.watchlist.Ask': 'Ask',
    'panel.watchlist.AskSize': 'Ask size',
    'panel.watchlist.AuctionEndTime': '#hidden#',
    'panel.watchlist.Bid': 'Bid',
    'panel.watchlist.BidSize': 'Bid size',
    'panel.watchlist.Buy': 'Buy',
    'panel.watchlist.CBAuctionStart': '#hidden#',
    'panel.watchlist.cell.cancel': 'Cancel',
    'panel.watchlist.cell.modify': 'Modification',
    'panel.watchlist.cell.Not allow': 'Not allow',
    'panel.watchlist.cell.sending': 'New',
    'panel.watchlist.cell.ticks': 'ticks',
    'panel.watchlist.Change': 'Change',
    'panel.watchlist.ChangeFromOpen': 'Change from open',
    'panel.watchlist.ChangeFromOpen%': 'Change from open, %',
    'panel.watchlist.ChangePr': 'Change, %',
    'panel.watchlist.Close': 'Close',
    'panel.watchlist.ClosePostMarket': 'Post market close',
    'panel.watchlist.Comment': 'Comment',
    'panel.watchlist.Company': 'Company',
    'panel.watchlist.ContractSize': 'Lot size',
    'panel.watchlist.CurrentSession': 'Current session',
    'panel.watchlist.Date': 'Last date',
    'panel.watchlist.Descr': 'Description',
    'panel.watchlist.Exchange': 'Exchange',
    'panel.watchlist.ExpDate': 'Exp. date',
    'panel.watchlist.High': 'High',
    'panel.watchlist.High13': '#hidden#',
    'panel.watchlist.High26': '#hidden#',
    'panel.watchlist.High52': '#hidden#',
    'panel.watchlist.IndicativeAuctionPrice': 'Ind. auction pr.',
    'panel.watchlist.Instrument': 'Symbol',
    'panel.watchlist.Last': 'Last',
    'panel.watchlist.LastSize': 'Last size',
    'panel.watchlist.Limit.High': '#hidden#',
    'panel.watchlist.Limit.Low': '#hidden#',
    'panel.watchlist.Low': 'Low',
    'panel.watchlist.Low13': '#hidden#',
    'panel.watchlist.Low26': '#hidden#',
    'panel.watchlist.Low52': '#hidden#',
    'panel.watchlist.Main Volume': 'Main volume',
    'panel.watchlist.Market': 'Market',
    'panel.watchlist.MarketCap': 'Market cap.',
    'panel.watchlist.Match Volume': 'Match volume',
    'panel.watchlist.menu.AddSymbol': 'Add symbols...',
    'panel.watchlist.menu.clearAll': 'Clear all',
    'panel.watchlist.menu.SymbolLists': 'Symbol lists',
    'panel.watchlist.menu.RemoveInstrument': 'Remove selected symbols',
    'panel.watchlist.menu.SymbolInfo': 'Symbol info',
    'panel.watchlist.menu.Chart': '#hidden#',
    'panel.watchlist.menu.MarketDepth': '#hidden#',
    'panel.watchlist.menu.TimeSales': '#hidden#',
    'panel.watchlist.menu.OrderEntry': '#hidden#',
    'panel.watchlist.menu.NoSymbolSelected.tt': 'Watchlist has no selected symbol',
    'panel.watchlist.menu.MoreThanOneSymbolSelected.tt': 'More than one symbol selected',
    'panel.watchlist.NA': 'N/A',
    'panel.watchlist.noQuotes': 'Last update',
    'panel.watchlist.NormalMarketSize': '#hidden#',
    'panel.watchlist.OffExchangeValue': '#hidden#',
    'panel.watchlist.OffExchangeVolume': '#hidden#',
    'panel.watchlist.Open': 'Open',
    'panel.watchlist.OpenInterest': '#hidden#',
    'panel.watchlist.OpenPreMarket': 'Pre market open',
    'panel.watchlist.Order': '#hidden#',
    'panel.watchlist.Order.tt': 'Place limit order',
    'panel.watchlist.Trade': '#hidden#',
    'panel.watchlist.Trade.tt': 'Place market order',
    'panel.watchlist.ITChartAdvanced': 'Advanced Chart',
    'panel.watchlist.ITChartAdvanced.tt': 'Advanced Chart by IT-Finance',
    'panel.watchlist.PreMarketChange': 'Pre market change',
    'panel.watchlist.PreMarketChange%': 'Pre market change, %',
    'panel.watchlist.PrevClose': 'Prev. close',
    'panel.watchlist.PrevSettlementPrice': 'Previous settlement price',
    'panel.watchlist.ReferencePrice': 'Reference price',
    'panel.watchlist.RemainingQty': '#hidden#',
    'panel.watchlist.RemainingQtySide': '#hidden#',
    'panel.watchlist.Sell': 'Sell',
    'panel.watchlist.SettlementPrice': 'Settlement price',
    'panel.watchlist.Spread': 'Spread',
    'panel.watchlist.SpreadPr': 'Spread, %',
    'panel.watchlist.Ticks': 'Ticks',
    'panel.watchlist.TicksPostMarket': 'Post market ticks',
    'panel.watchlist.TicksPreMarket': 'Pre market ticks',
    'panel.watchlist.Time': 'Last time',
    'panel.watchlist.TradedValue': '#hidden#',
    'panel.watchlist.Type': 'Symb. type',
    'panel.watchlist.Volume': 'Volume',
    'panel.watchlist.VolumePostMarket': 'Post market volume',
    'panel.watchlist.VolumePreMarket': 'Pre market volume',
    'panel.watchlist.AvgTradedPrice': '#hidden#',
    'panel.watchlist.TotalBuyQuantity': '#hidden#',
    'panel.watchlist.TotalSellQuantity': '#hidden#',
    'panel.watchlist.HighOpenInterest': '#hidden#',
    'panel.watchlist.LowOpenInterest': '#hidden#',
    'panel.watchlist.InitiatorPrice': '#hidden#',
    'panel.watchlist.InitiatorQuantity': '#hidden#',
    'panel.watchlist.InitiatorType': '#hidden#',
    'panel.watchlist.LastTradedTime': '#hidden#',
    'panel.watchlist.AuctionNumber': '#hidden#',
    'panel.watchlist.AuctionStatus': '#hidden#',
    'panel.watchlist.FiftyTwoWeekHighPrice': '#hidden#',
    'panel.watchlist.FiftyTwoWeekLowPrice': '#hidden#',
    'panel.watchlist.NSEValue': '#hidden#',
    'panel.watchlist.LPP': '#hidden#',
    'panel.watchlist.DPR': '#hidden#',
    'panel.watchlist.Code': '#hidden#',
    'panel.watchlist.InstrumentType': '#hidden#',
    'panel.watchlist.MarketType': '#hidden#',
    'panel.watchlist.Symbol': '#hidden#',
    'panel.watchlist.Series': '#hidden#',
    'panel.watchlist.ExchangeMarketData': 'Market data exchange',
    'panel.watchlist.ExchangeTrading': 'Trading exchange',
    'panel.watchlist.showBidAskColumns': '#hidden#',
    'Panels.MarketDepth.CancelAll': 'Cancel all',
    'panels.orders.buttons.AsMarket': 'Change to Market',
    'panels.orders.buttons.CancelAllButton': 'Cancel all',
    'panels.orders.buttons.CancelBuyButton': 'Cancel buy',
    'panels.orders.buttons.CancelByInstrumentButton': 'Cancel',
    'panels.orders.buttons.CancelDayButton': 'Cancel Days',
    'panels.orders.buttons.CancelGTCButton': 'Cancel GTCs',
    'panels.orders.buttons.CancelLimitsButton': 'Cancel Limits',
    'panels.orders.buttons.CancelSelectedButton': 'Cancel selected',
    'panels.orders.buttons.CancelSellButton': 'Cancel sell',
    'panels.orders.buttons.CancelStopsButton': 'Cancel Stops',
    'panels.orders.buttons.ModifyOrder': 'Modify order',
    'panels.positions.buttons.closeAll': 'Close all',
    'panels.positions.buttons.closeForInstrument': 'Close',
    'panels.positions.buttons.closeLong': 'Close long',
    'panels.positions.buttons.closeNegative': 'Close negative',
    'panels.positions.buttons.closePositive': 'Close positive',
    'panels.positions.buttons.closeSelected': 'Close selected',
    'panels.positions.buttons.closeShort': 'Close short',
    'panels.positions.buttons.clxAll': 'CLX all',
    'panels.positions.buttons.clxForInstrument': 'CLX',
    'panels.positions.buttons.reverse': 'Reverse',
    'panels.positions.buttons.reverseSelected': 'Reverse selected',
    'panels.positions.ButtonsPanel.helpLabel': 'Right-click to show/hide buttons',
    'panel.rangeSelect.daily': 'Daily',
    'panel.rangeSelect.24h': '#hidden#',
    'panel.rangeSelect.7d': '#hidden#',
    'panel.rangeSelect.30d': '#hidden#',
    'panel.rangeSelect.90d': '#hidden#',
    'panel.rangeSelect.range': 'Range',
    'panel.rangeSelect.tooltip': 'Select period for displaying',
    'panel.rangeSelect.logs.tooltip': 'Select period for displaying logs',
    'panel.rangeSelect.range.all': 'All',
    'panel.filterSelect.logs.tooltip': 'Select log types to display',
    'panel.riskCalculator': 'Risk calculator',
    'PanelSettingsScreen.Text': '- Settings',
    'PanelSettingsScreen.TextSmall': '- settings',
    'Parabolic Time/Price System': 'Parabolic Time/Price system',
    'period.Day': 'Day',
    'period.Days': 'Days',
    'period.Hour': 'Hour',
    'period.Hours': 'Hours',
    'period.Min': 'Min',
    'period.Mins': 'Mins',
    'period.Month': 'Month',
    'period.Months': 'Months',
    'period.Week': 'Week',
    'period.Weeks': 'Weeks',
    'period.Year': 'Year',
    'period.Years': 'Years',
    'Pivot Point Moving Average': 'Pivot Point Moving Average',
    PivotPoint: 'Pivot point',
    'PopupMultiCkeckBox.All': 'All',
    'portfolio.performance.allocation.title': 'Allocation',
    'portfolio.performance.growth.title': 'Portfolio growth',
    'portfolio.performance.portfolioAllocationLabel': 'Portfolio allocation',
    'portfolio.performance.modelAllocationLabel': 'Model allocation',
    'portfolio.performance.contextMenu.AllocationType': 'Allocation type',
    'portfolio.performance.contextMenu.AllocationType.Portfolio': 'Portfolio',
    'portfolio.performance.contextMenu.AllocationType.Model': 'Model',
    'portfolio.performance.contextMenu.View.Total': 'Total return',
    'portfolio.performance.contextMenu.View.PortfolioBalance': 'Portfolio balance',
    'portfolio.DiagramSettings.header': 'Allocation charts - settings',
    'property.PNL': 'P/L',
    'property.PortfolioPerformanceDiagramBackColor': 'Back color',
    'property.PortfolioPerformanceTotalReturnVisible': 'Visible',
    'property.PortfolioPerformancePortfolioBalanceVisible': 'Visible',
    'property.PortfolioPerformanceTotalReturnColors': 'Colors',
    'property.PortfolioPerformancePortfolioBalanceColor': 'Color',
    'portfolio.performance.cash': 'Cash',
    'portfolio.performance.allocationCB.Symbol': 'Symbol',
    'portfolio.performance.allocationCB.Symbol type': 'Symbol type',
    'portfolio.performance.allocationCB.Country': 'Country',
    'portfolio.performance.allocationCB.Sector': 'Sector',
    'portfolio.performance.allocationCB.Industry': 'Industry',
    'portfolio.performance.allocationCB.Currency': 'Currency',
    'portfolio.performance.growth.contextMenu.GridTime': 'Grid time',
    'portfolio.performance.growth.contextMenu.GridBalance': 'Grid balance',
    'portfolio.performance.growth.contextMenu.Crosshair': 'Crosshair',
    'portfolio.performance.growth.contextMenu.Refresh': 'Refresh',
    'portfolio.performance.growth.contextMenu.Settings': 'Settings',
    'portfolio.performance.growth.contextMenu.InfoWindow': 'Info window',
    'portfolio.performance.growth.contextMenu.InfoWindow.Separate': 'Separate window',
    'portfolio.performance.growth.contextMenu.InfoWindow.Attached': 'Attached to cursor',
    'portfolio.performance.growth.contextMenu.InfoWindow.Hidden': 'Hidden',
    'property.Portfolio balance scale': 'Portfolio balance scale',
    'property.SeparatorGroup.HighlightPortfolioBalance': 'Highlight Portfolio balance',
    'portfolio.performance.growth.contextMenu.Style': 'Style',
    'property.IsPortfolioGrowthVisible': 'Balance',
    'portfolio.returns.title': 'Returns',
    'portfolio.returns.tableHeader.date': 'Period',
    'portfolio.returns.tableHeader.return': 'Portfolio return, %',
    'portfolio.returns.tableHeader.balance': 'Portfolio balance',
    'portfolio.returns.tableHeader.monthPercent': 'Portfolio:',
    'portfolio.returns.infoWindow.dateHeader': 'Date:',
    'portfolio.returns.contextMenu.View': 'View',
    'portfolio.returns.contextMenu.Refresh': 'Refresh',
    'portfolio.returns.contextMenu.Settings': 'Settings',
    'portfolio.returns.contextMenu.GridTime': 'Grid time',
    'portfolio.returns.contextMenu.GridPercent': 'Grid percent',
    'portfolio.returns.contextMenu.Scrollbar': 'Scrollbar',
    'portfolioReturns.InfoWindowMode.SeparateWindow': 'Separate Window',
    'portfolioReturns.InfoWindowMode.None': 'None',
    'portfolio.returns.StatisticSwitcher.Portfolio': 'Portfolio returns statistics',
    'portfolio.returns.StatisticSwitcher.Asset': 'Asset returns statistics',
    'portfolio.returns.StatisticSwitcher.Asset.ConfirmText': 'Incorrect zooming. Do you want to zoom in?',
    'portfolio.returns.StatisticSwitcher.Asset.Zoom': 'Zoom',
    'portfolioReturns.AssetReturnText': 'Return',
    Português: 'Português (Br)',
    'positionSizeCalculator.header': 'Position size calculator',
    'positionSizeCalculator.infoText': 'Calculator helps to determine the recommended position quantity based on the risk you are willing to take.',
    'positionSizeCalculator.infoText.linkPart': 'More in the {0}Position sizing article{0}.',
    'positionSizeCalculator.helpLink': 'https://guide.traderevolution.com/project/desktop-application-for-windows/windows/trading-panels/order-entry/position-sizing',
    'positionSizeCalculator.accountRisk': 'Account risk',
    'positionSizeCalculator.accountRisk.tt': 'If you want to risk a certain percentage of your Available funds, type this percentage in. Alternatively, swap the risk unit to fixed and enter the amount of cash you wish to risk',
    'positionSizeCalculator.cashRiskLabel': 'Cash risk',
    'positionSizeCalculator.percentRiskLabel': 'Percentage risk',
    'positionSizeCalculator.PercentMenuItem': 'Percentage',
    'positionSizeCalculator.FixedMenuItem': 'Fixed',
    'positionSizeCalculator.UnableToCalc': 'Unable to calculate value',
    'positionSizeCalculator.button.tt': 'Enables additional fields for automatic calculation of the quantity',
    'positionSizeCalculator.buttonDisabled.tt': 'Unable to use Position size calculator because SL is forbidden',
    'positionSizeCalculator.OEButtonDisabled.less': 'Quantity is less than min allowed ({0})',
    'positionSizeCalculator.OEButtonDisabled.more': 'Quantity is more than max allowed ({0})',
    'Price Channel': 'Price channel',
    'Price Oscillator': 'Price oscillator',
    'ProductType.CarryForward': 'Carry forward',
    'ProductType.Delivery': 'Delivery',
    'ProductType.General': 'General',
    'ProductType.Intraday': 'Intraday',
    'ProductType.CorporateAction': 'Corporate Action',
    'property.%': '%',
    'property.Additional': 'Additional',
    'property.AllPrice': 'Price  statistics',
    'property.appearance': 'Appearance',
    'property.Ask': 'Ask',
    'property.AskColor': 'Ask color',
    'property.AskBid/2': '(Ask+Bid)/2',
    'property.Author': 'Author',
    'property.autosize': 'Autosize of columns',
    'property.autoYScale.Auto': 'Auto scale',
    'property.autoYScale.Manual': 'Manual scale',
    'property.Average': 'Average',
    'property.Backgr': 'Back color',
    'property.Backgr. top': 'Back top color',
    'property.BarPattern': 'Bar pattern',
    'property.barsHiLowColor': 'Line',
    'property.BarStatistics': 'Bar statistics',
    'property.Basis': 'Basis',
    'property.Beta': 'BETA',
    'property.Bid': 'Bid',
    'property.BidColor': 'Bid color',
    'property.BidAskAverage': '(Bid+Ask)/2',
    'property.bodyColor': 'Body',
    'property.borderColor': 'Border',
    'property.backgroundColor': 'Back',
    'property.BottomCenter': 'BottomCenter',
    'property.BottomLeft': 'BottomLeft',
    'property.BottomRight': 'BottomRight',
    'property.Alerts': 'Alerts',
    'property.Action': 'Action',
    'property.NoPriceForCurrentAlertType': 'No price for current Alert type',
    'property.Notification': 'Notification type',
    'property.AlertType': 'Alert type',
    'property.Condition': 'Condition',
    'property.Importance': 'Importance',
    'property.AfterExecute': 'After execute',
    'property.Browser': 'Browser',
    'property.Buy': 'Buy',
    'property.BuyColor': 'Buy',
    'property.cAsk': 'Ask size',
    'property.cASK': 'At Ask',
    'property.cASKHI': 'Above Ask',
    'property.cBid': 'Bid size',
    'property.cBID': 'At Bid',
    'property.cBIDLO': 'Below Bid',
    'property.cellscin.useSellBuy': 'Reverse buttons order',
    'property.Change': 'Change',
    'property.ChangePercent': 'Change, %',
    'property.Chart': 'Chart',
    'property.Chart OE': 'Chart Order entry',
    'property.chart.isBackground': 'Move to background',
    'property.chart.line': 'Line',
    'property.chart.ray': 'RAY',
    'property.chart.rayStyle': 'Ray style',
    'property.chart.showAngle': 'Show angle',
    'property.chart.showingOE': 'Visible',
    'property.chart.stickTo': 'Stick to',
    'property.chart.tools.back': 'Fill drawing',
    'property.chart.tools.fibretr.font': 'Label Font',
    'property.chart.tools.fibretr.fontcolor': 'Font color',
    'property.ChartAnalyseToolsToolBar': 'Analysis  toolbar',
    'property.ChartDrawingToolsToolBar': 'Drawing toolbar',
    'property.chart.regression.RegressionLine': 'Regression line',
    'property.chart.regression.UpLine': 'Top line',
    'property.chart.regression.DownLine': 'Bottom line',
    'property.chart.regression.Area.Top': 'Top area',
    'property.chart.regression.Area.Bottom': 'Bottom area',
    'property.chart.regression.UpperDeviation': 'Upper Deviation',
    'property.chart.regression.LowerDeviation': 'Lower Deviation',
    'property.chart.regression.PriceType': 'Price Type',
    'property.cLimit': 'Limit order',
    'property.Close': 'Close',
    'property.Cluster.ClusterUpDownColors': 'Cluster',
    'property.Cluster.ColorScheme': 'Color scheme',
    'property.Cluster.ColorScheme.ByDelta': 'By delta',
    'property.Cluster.ColorScheme.ByDelta.Colors': 'Color',
    'property.Cluster.ColorScheme.ByDelta.Colors.BuyColor': 'Buy',
    'property.Cluster.ColorScheme.ByDelta.Colors.SellColor': 'Sell',
    'property.Cluster.ColorScheme.ByTrades': 'By trades',
    'property.Cluster.ColorScheme.ByVolume': 'By volume',
    'property.Cluster.ColorScheme.Color': 'Color',
    'property.Cluster.ColorScheme.None': 'None',
    'property.Cluster.CustomStep': 'Custom step',
    'property.Cluster.Down': 'Down',
    'property.Cluster.FilterValue': 'Filter value',
    'property.Cluster.Font': 'Font',
    'property.Cluster.FontColor': 'Font color',
    'property.Cluster.HighlightMax': 'Highlight maximum',
    'property.Cluster.HighlightMax.Colors': 'Colors',
    'property.Cluster.Operator': 'Operator',
    'property.Cluster.PriceStep': 'Price step',
    'property.Cluster.PriceStep.Custom': 'Custom',
    'property.Cluster.PriceStep.MinTick': 'Minimum tick',
    'property.Cluster.ShowOnlySimilarDelta': 'Show only similar delta',
    'property.Cluster.ShowValueFilter': 'Value filter',
    'property.Cluster.Up': 'Up',
    'property.cMIDLO': 'Between spread',
    'property.Color': 'Color',
    'property.ColoringMode': 'Coloring mode',
    'property.Colors': 'Colors',
    'property.colors_param': 'Colors',
    'property.colorScheme': 'Style',
    'property.ColorsSeparatorGroup': 'Colors',
    'property.ColouringMode': 'Coloring mode',
    'property.Comments': 'Comments',
    'property.Company': 'Company',
    'property.Confirmations': 'Confirmations',
    'property.connection': 'Connection',
    'property.Connection': 'Connection',
    'property.ContentAlignment': 'Alignment',
    'property.Copyrights': 'Copyrights',
    'property.cQUOTE': 'Quotes',
    'property.CreateColor': 'Create',
    'property.crossHairFontColor': 'Font color',
    'property.cStop': 'Stop Order',
    'property.currency': 'Currency',
    'property.CurrentHV': 'Current HV, %',
    'property.CurrentLastPrice': 'Current last price',
    'property.CustomUnderlierPrice': 'Custom underlier price',
    'property.CustomYMarkingValue': 'Min. grid step',
    'property.Data Box': 'Info window',
    'property.Data Style': 'Data style',
    'property.Day': 'Day',
    'property.GTS': 'GTS',
    'property.DaySeparators': 'Day separators',
    'property.DefaultProductType': 'Default product type',
    'property.Defaults': 'Trading defaults',
    'property.deviation': 'deviation',
    'property.DisableStartEndSounds': '#hidden#',
    'property.dK': 'dK',
    'property.Doji': 'Doji',
    'property.Downband deviation in %': 'Downband deviation in %',
    'property.ElliotWaves.Font': 'Font color',
    'property.email': 'Email addresses\n(Use comma or semicolon)',
    'property.Emulator': 'Emulator',
    'property.enable': 'Enable',
    'property.Enable Sounds': 'Enable sounds',
    'property.Error': 'Error',
    'property.Example': 'Example',
    'property.Exponential': 'Exponential',
    'property.fib.Arc': 'Fib. Arc',
    'property.Fibonacci': 'Fibonacci',
    'property.FirstValue': 'First value',
    'property.Filters': 'Filters',
    'property.Filtered': 'Filtered',
    'property.FitDrawings': 'Fit drawings',
    'property.FitAlerts': 'Fit alerts',
    'property.FitHighLow': 'Fit day High/Low',
    'property.FitIndicator': 'Fit Indicators',
    'property.FitOrders': 'Fit  orders/positions',
    'property.FOK': 'FOK',
    'property.Font': 'Font',
    'property.FontColor': 'Font color',
    'property.LabelColor': 'Label color',
    'property.Forex': 'Forex',
    'property.Forward': 'Forward',
    'property.Forwards': 'Forwards',
    'property.Futures': 'Futures',
    'property.FX Cell': 'FX cell',
    'property.G': 'G',
    'property.General': 'General',
    'property.General.ToolTip': 'General settings',
    'property.general.defaultSymbol': 'Symbol',
    'property.general.OvernightMarginNotificationMessage': '#hidden#',
    'property.general.showLotsFX': 'Display quantity in lots',
    'property.gr_type': 'Style',
    'property.Gradient': 'Gradient',
    'property.GraphicType.Absolute': 'Absolute',
    'property.GraphicType.Log': 'Logarithmic',
    'property.GraphicType.Relative': 'Relative',
    'property.GridPrice': 'Price (horizontal)',
    'property.GridPriceHighLight': 'Highlight grid color',
    'property.GridTime': 'Time (vertical)',
    'property.GTC': 'GTC',
    'property.GTD': 'GTD',
    'property.HeaderFont': 'Font',
    'property.HeaderText': 'Header text',
    'property.HideAdditionalPanels': 'Hide additional panels',
    'property.High': 'High',
    'property.HighlightMarkingsStep': 'Highlight grid step',
    'property.highlightExtendedSessionsColor': 'Highlight extended sessions',
    'property.HistogramLineColor': 'Histogram Line',
    'property.HistoryType': 'Data type',
    'property.HorizontalLine.LeftRay': 'Extend left',
    'property.hostName': 'Host name',
    'property.hotkeyManager.ShowVolume': 'Show volume',
    'property.infowindow_font': 'Font',
    'property.infoWindow_fore_back_color': 'Color',
    'property.infoWindow_value_neutral_color': 'Neutral color',
    'property.infoWindow_value_increase_color': 'Increase color',
    'property.infoWindow_value_decrease_color': 'Decrease color',
    'property.infowindow_visible': 'Show Info window',
    'property.infowindow_displayMode': 'Info Window Mode',
    'property.InputParameters': 'Input parameters',
    'property.Instrument Types': 'Order defaults by type',
    'property.InTheMoneyColor': 'In-the-money color',
    'property.OutTheMoneyColor': 'Out-the-money color',
    'property.OHLAverage': 'OHL/3',
    'property.OHLCAverage': 'OHLC/4',
    'property.InterestRate': 'Interest rate, %',
    'property.Intrinsic': 'Intrinsic',
    'property.IOC': 'IOC',
    'property.IsBackground': 'Is background',
    'property.OnTop': 'On top',
    'property.IsBarsVisible': '#Bars',
    'property.IsChangeVisible': 'Change, %',
    'property.IsCloseVisible': 'Close',
    'property.IsDateVisible': 'Date',
    'property.IsHighVisible': 'High',
    'property.IsLowVisible': 'Low',
    'property.IsHighLowVisible': 'High - Low',
    'property.IsOpenVisible': 'Open',
    'property.IsRoundedAvgPrice': 'Rounded average open price',
    'property.isShowToolTips': 'Tooltips',
    'property.isShowToolTipsGrop': 'Tooltips',
    'property.ConfirmationSubgroup.Other': 'Other',
    'property.dealTickets.openOnWorkspace': 'Open deal tickets on workspace',
    'property.dealTickets.showDealTickets': 'Show deal tickets in application tray',
    'property.dealTickets.showDealTickets.ToolTip': 'Defines whether deal tickets will be displayed in application tray or not',
    'property.dealTickets.openMarginOnWorkspace.Default': '#hidden#',
    'property.dealTickets.showMarginDealTicketsOnWorkspace': 'Show margin deal ticket on workspace',
    'property.dealTickets.showMarginDealTicketsOnWorkspace.ToolTip': 'If activated, the Margin Warning and Margin Call deal tickets will appear on the workspace',
    'property.IsSyncActive': 'Synchronize with charts',
    'property.IsTicksVisible': 'Ticks',
    'property.IsTimeVisible': 'Time',
    'property.IsVolumeVisible': 'Volume',
    'property.IVCalculationPrice': 'IV calculation price',
    'property.LabelColors': 'Label colors',
    'property.Language': 'Language',
    'property.Last': 'Last',
    'property.LastPrice': 'Last price',
    'property.lastPrice_AskIndicatorColor': 'Ask',
    'property.lastPrice_BidIndicatorColor': 'Bid',
    'property.lastPrice_LastIndicatorColor': 'Last',
    'property.lastPrice_SpreadIndicatorColor': 'Spread area color',
    'property.Left ray': 'Extend left',
    'property.Level': 'Level',
    'property.Care': 'Care',
    'property.Limit': 'Limit',
    'property.limitOffsetTicks': 'Limit offset, ticks',
    'property.Line': 'Line',
    'property.line.lineName': 'Line name',
    'property.line.plateVisible': 'Show line {0} marker',
    'property.line.timeShift': 'Time shift',
    'property.line.value': 'Value',
    'property.line.Visible': 'Visible',
    'property.line.yFactor': 'Y factor',
    'property.Linear Weighted': 'Linear weighted',
    'property.lineProperties': 'properties',
    'property.Logo': 'Logo',
    'property.LOC': 'LOC',
    'property.logBase': 'Log base',
    'property.LOO': 'LOO',
    'property.LotsMode': 'Show size in',
    'property.Low': 'Low',
    'property.Mail Server': 'Mail server',
    'property.Manual': 'Manual',
    'property.Market': 'Market',
    'property.MarketCap': 'Market Cap',
    'property.MarketDepth.ColoringMethod': 'Coloring method',
    'property.MarketProfile.ColorScheme': 'Color scheme',
    'property.MarketProfile.ColorScheme.Color': 'Color',
    'property.MarketProfile.ColorScheme.GradientColor': 'Area',
    'property.MarketProfile.ColorScheme.PairColor.Color': 'Color',
    'property.MarketProfile.ColorScheme.VolumeColor': 'Color',
    'property.MarketProfile.Font': 'Font',
    'property.MarketProfile.PointOfControl': 'Point of control',
    'property.MarketProfile.PointOfControlStyle': 'Point of control style',
    'property.MarketProfile.ShowSingles': 'Show singles',
    'property.MarketProfile.SinglesStyle': 'Singles style',
    'property.MarketProfile.ValueArea': 'Value area',
    'property.MarketProfile.ValueAreaStyleHorisontal': 'Horisontal line style',
    'property.MarketProfile.ValueAreaStyleVertical': 'Vertical line style',
    'property.MarketProfile.ValuePercents': 'Value, %',
    'property.Medium': 'Medium',
    'property.message': 'Message',
    'property.method': 'method',
    'property.MirrorShow': 'Mirror view',
    'property.MOC': 'MOC',
    'property.Mode': 'Mode',
    'property.Mode.Trades': 'Trades',
    'property.Modified': 'Modified',
    'property.MonthSeparators': 'Month separators',
    'property.MOO': 'MOO',
    'property.name': 'Name',
    'property.Name': 'Name',
    'property.News': 'News',
    'property.None': 'None',
    'property.Note': 'Note',
    'property.Notifications': 'Notifications',
    'property.NotificationType': 'Notification type',
    'property.NotSet': 'NotSet',
    'property.Number': 'Count',
    'property.OCO': 'OCO',
    'property.OE_AddBidAddAsk': 'Buy Bid/Sell Ask',
    'property.OE_ShowBuyMarketSellMarket': 'Buy Market/Sell Market',
    'property.OE_ShowSellBidBuyASk': 'Sell Bid/Buy Ask',
    'property.OE_ShowSLTP': 'Close orders (SL/TP)',
    'property.OE_Small': 'Narrow Order entry',
    'property.OE_Small.ToolTip': 'If checked, Order entry will have a narrow view',
    'property.oe.confirmClosePosition': 'Confirm position closing',
    'property.oe.confirmOrderCancel': 'Confirm order cancellation',
    'property.oe.confirmOrderChange': 'Confirm order/positions modification',
    'property.oe.confirmOrderCreate': 'Confirm order placement',
    'property.oe.confirmOrderCreateAlert': 'Confirm order placement',
    'property.oe.confirmAlertRemove': 'Confirm alert remove',
    'property.oe.confirmReversePosition': 'Confirm position reversing',
    'property.OrderCreateAlertGroup': 'Alerts',
    'property.OffsetMode': 'Show offset in',
    'property.OffsetMode.Points': 'Points',
    'property.OffsetMode.Ticks': 'Ticks',
    'property.OffsetMode.TicksFractionalForForex': 'Ticks (fractional ticks for Forex)',
    'property.OffsetMode.Percent': 'Percent',
    'property.OnClose': 'On Close',
    'property.OnOpen': 'On Open',
    'property.Open': 'Open',
    'property.OptionChain': 'Option chain',
    'property.Order Entry': 'Order entry',
    'property.OrderBuyStyleWidth': 'Buy',
    'property.OrderSellStyleWidth': 'Sell',
    'property.OutputParameters': 'Output parameters',
    'property.OverlayVisible': 'Overlay visible',
    'property.Panel name': 'Panel name',
    'property.panel.accountDetails.Groups.1.General': 'General',
    'property.panel.accountDetails.Groups.2.Margin': 'Margin',
    'property.panel.accountDetails.Groups.3.AccountActivity': 'Account activity',
    'property.panel.accountDetails.Groups.4.TodayResults': 'Today results',
    'property.panel.accountDetails.Groups.5.RiskManagement': 'Risk management',
    'property.panel.accountDetails.Groups.6.Info': 'Info',
    'property.Panels': 'Panels',
    'property.Parameters': 'Parameters',
    'property.password': 'Password',
    'property.period': 'period',
    'property.Period of indicator': 'Period of indicator',
    'property.Period of indicator:': 'Period of indicator',
    'property.Period of Linear Regression': 'Period of linear regression',
    'property.Period of MA for envelopes': 'Period of MA for envelopes',
    'property.Period of MA1': 'Period of MA1',
    'property.Period of MA2': 'Period of MA2',
    'property.Period of momentum:': 'Period of momentum',
    'property.Period of Moving Average': 'Period of Moving Average',
    'property.Period of price channel': 'Period of price channel',
    'property.Period of Simple Moving Average': 'Period of Simple Moving Average',
    'property.Period of Smoothed Moving Average': 'Period of Smoothed Moving Average',
    'property.Periods': 'Periods',
    'property.Calсulation cycle, bars': 'Calсulation cycle, bars',
    'property.Calculation price': 'Calculation price',
    'property.pin': 'PIN',
    'property.pips': 'Pips',
    'property.POCTypes.Line': 'Line',
    'property.POCTypes.Marker': 'Marker',
    'property.POCTypes.None': 'None',
    'property.points': 'Points',
    'property.Points': 'Points',
    'property.PositionBuyStyleWidth': 'Long',
    'property.PositionSellStyleWidth': 'Short',
    'property.Portfolio return': 'Portfolio return',
    'property.Analyzer': 'Analyzer',
    'property.AnalyzerInfo': 'Info window',
    'property.ATMStrikeColor': 'ATM strike color',
    'property.Asset return': 'Asset return',
    'property.Percent scale': 'Percent scale',
    'property.AssetReturnVisibility': 'Visible',
    'property.Previous': 'Previous',
    'property.Price': 'Price',
    'property.price': 'price',
    'property.Price Scale': 'Price scale',
    'property.PriceIndicatorDrawingType': 'Price indicator',
    'property.PriceIndicatorDrawingType.None': 'None',
    'property.PriceIndicatorDrawingType.ScaleMarker': 'Scale marker',
    'property.PriceIndicatorDrawingType.ScaleMarkerWithLine': 'Scale marker with line',
    'property.PriceScaleAxisColor': 'Axis',
    'property.PriceScaleBackColor': 'Axis background',
    'property.PriceScaleFont': 'Font',
    'property.PriceScaleTextColor': 'Text color',
    'property.PriceType': 'Price type',
    'property.PricingModel': 'Pricing model',
    'property.PricingModel.BlackScholes': 'Black-Scholes',
    'property.Probability': 'Probability',
    'property.ProbabilityCalculation': 'Probability calculation',
    'property.ProbabilityCalculation.None': 'None',
    'property.ProbabilityCalculation.Single': 'Single',
    'property.ProbabilityCalculation.OR': 'OR',
    'property.ProbabilityCalculation.AND': 'AND',
    'property.ProbabilitySimulations': 'Probability simulations',
    'property.ProbabilityScenario': 'Probability scenario',
    'property.ProbabilityScenario.OneTouch': 'One touch',
    'property.ProbabilityScenario.OutOfRange': 'Out of range',
    'property.ProfileColoringTypes.DeltaBars': 'Delta bars',
    'property.ProfileColoringTypes.DeltaPrice': 'Delta price',
    'property.ProfileColoringTypes.DeltaProfile': 'Delta profile',
    'property.ProfileColoringTypes.Single': 'Single color',
    'property.ProfileColoringTypes.UpdownBars': 'Up/Down bars',
    'property.ProfileColoringTypes.UpdownProfile': 'Up/Down profile',
    'property.ProfileColoringTypes.VolumeBars': 'Volume bars',
    'property.ProfileColoringTypes.VolumePrice': 'Volume price',
    'property.protrader.showOrders': 'Orders',
    'property.Range': 'Range',
    'property.Ray style': 'Ray style',
    'property.Relative': 'Relative',
    'property.relativeCalcType': 'Start price',
    'property.RelativeCalculationType.BeginOfTheData': 'Begin of the data',
    'property.RelativeCalculationType.BeginOfTheDay': 'Begin of the day',
    'property.RelativeCalculationType.BeginOfTheScreen': 'Begin of the screen',
    'property.relativeScaleType': 'Scale type',
    'property.Reserve': 'Reserve',
    'property.Reverse': 'Reverse',
    'property.Right ray': 'Extend right',
    'property.routing.account': 'Account',
    'property.routing.orderType': 'Order type',
    'property.RSI Method:': 'RSI Method',
    'property.RSI Period:': 'RSI Period',
    'property.ScaleColors': 'Scale colors',
    'property.SCRIPTS_SUBGROUP': 'Scripts',
    'property.ScrollBar': 'Scrollbar',
    'property.SecondStandardDeviationColor': '2 standard deviation color',
    'property.SecondValue': 'Second value',
    'property.secure': 'Secure',
    'property.Secure': 'Secure',
    'property.Selection': 'Selection',
    'property.Sell': 'Sell',
    'property.SellColor': 'Sell',
    'property.SeparatorGroup.ActiveOrders': 'Working orders',
    'property.SeparatorGroup.additional': 'Additional',
    'property.SeparatorGroup.Additional': 'Additional',
    'property.SeparatorGroup.Appearance': 'Appearance',
    'property.SeparatorGroup.CrossHair': 'Crosshair',
    'property.SeparatorGroup.Watermark': 'Watermark',
    'property.SeparatorGroup.ExecutedOrders': 'Filled orders (starting from session)',
    'property.SeparatorGroup.Grid': 'Grid',
    'property.SeparatorGroup.HighlightDate': 'Highlight date',
    'property.SeparatorGroup.HighlightPercent': 'Highlight Percent',
    'property.SeparatorGroup.HighlightPrices': 'Highlight prices',
    'property.SeparatorGroup.OpenPositions': 'Open positions',
    'property.SeparatorGroup.ScaleMarkings': 'Scale markings',
    'property.SeparatorGroup.ScaleType': 'Scale type',
    'property.SeparatorGroup.SeparatorOEWidth': 'View',
    'property.SeparatorGroup.Show': 'Show',
    'property.SeparatorGroup.TimeToNextBar': 'Time to next bar',
    'property.SeparatorGroup.WindowColors': 'Background colors',
    'property.SeparatorGroup.Zoom': 'Zoom',
    'property.SeparatorViewButtons': 'Quick trading',
    'property.Sesonality': 'Seasonality',
    'property.shift': 'shift',
    'property.ShowAdditionalPanels': 'Show additional panels',
    'property.ShowAlerts': 'Show alerts',
    'property.showAllSessions': 'Show extended session',
    'property.ShowEmptyBars': 'Holes in the history',
    'property.showEvents': 'Visible',
    'property.ShowFullArc': 'Show full arc',
    'property.ShowHighLow': 'Day High/Low',
    'property.showOrders': 'Visible',
    'property.showPositions': 'Visible',
    'property.ShowPrevCloseLine': 'Previous close',
    'property.ShowTimeToNextBar': 'Visible',
    'property.ShowTotal': 'Show total',
    'property.ShowToolbar': 'Show toolbar',
    'property.ShowVisualTradingOnLeftSide': 'Bound active positions/orders to the left side',
    'property.showVolume': 'Visible',
    'property.showVolumeMarker': 'Show value marker on scale',
    'property.Side': 'Side',
    'property.Simple': 'Simple',
    'property.SimulationModel': 'Simulation model',
    'property.SimulationModel.ByAbsolutePrices': 'By absolute prices',
    'property.SimulationModel.ByRelativePrices': 'By relative prices',
    'property.SimulationModel.ByLogarithmicPrices': 'By logarithmic prices',
    'property.SimulationExamples': 'Simulation examples',
    'property.SizeBy': 'Size by',
    'property.sltpOffsetMode': 'Set SL/TP values in offset',
    'property.Smoothing': 'Smoothing',
    'property.solidPriceColor': 'Area',
    'property.sounds_param': 'Sounds',
    'property.Sources prices for MA': 'Sources prices for MA',
    'property.Specific': 'Specific',
    'property.SpreadType': 'Spread type',
    'property.SpreadType.Area': 'Area',
    'property.SpreadType.Line': 'Line',
    'property.SpreadType.LineWithLabel': 'Line with price',
    'property.SpreadType.None': 'None',
    'property.StandardDeviationColor': 'Standard deviation color',
    'property.Step of parabolic SAR system': 'Step of parabolic SAR system',
    'property.Stick to': 'Stick to',
    'property.stickTo': 'Stick to:',
    'property.Stocks': 'Equities',
    'property.Stop': 'Stop',
    'property.Stop Limit': 'Stop limit',
    'property.Stop-loss': 'Stop loss',
    'property.Stop-loss-SLL': 'Stop loss/Stop loss limit',
    'property.style': 'Style:',
    'property.StrikePrice': 'Strike price',
    'property.StrikePrices': 'Strike prices',
    'property.StrikesAggregationStyle': 'Strikes aggregation style',
    'property.StrikesAggregationStyle.FromATMStrike': 'From ATM strike',
    'property.StrikesAggregationStyle.CustomUnderlierPrice': 'Custom underlier price',
    'property.Sum': 'Sum',
    'property.Symbol': 'Symbol',
    'property.Symbols': 'Symbols',
    'property.T': 'T',
    'property.Take-profit': 'Take profit',
    'property.text': 'Text',
    'property.TIF': 'Market validity',
    'property.TIF_Limit_And_Stop_Limit': 'Limit validity (Stop limit validity)',
    'property.TIF_Stop': 'Stop validity',
    'property.TimeChange': 'Time change',
    'property.Time Scale': 'Time scale',
    'property.TimeLine': 'Time line',
    'property.TimelinePreview': 'Scrollbar chart preview',
    'property.TimeScaleAxisColor': 'Axis',
    'property.TimeScaleBackColor': 'Axis background',
    'property.TimeScaleFont': 'Font',
    'property.TimeScaleImportentFont': 'Important dates font',
    'property.TimeScaleTextColor': 'Font color',
    'property.TimeToNextBarColor': 'Color',
    'property.TimeToNextBarFont': 'Font',
    'property.Title': 'Title',
    'property.TopCenter': 'TopCenter',
    'property.TopLeft': 'TopLeft',
    'property.TopRight': 'TopRight',
    'property.Tr. stop': 'Trailing stop',
    'property.Tr. stop loss': 'Tr. stop loss',
    'property.trackShowCrossHair': 'Visible',
    'property.Trading': 'Trading',
    'property.Trading Defaults': 'Trading',
    'property.Type of moving average': 'Type of moving average',
    'property.Type of Moving Average': 'Type of Moving Average',
    'property.Typical': 'Typical',
    'property.Upband deviation in %': 'Upband deviation in %',
    'property.Update': 'Update',
    'property.UseCustomYMarkings': 'Custom grid',
    'property.useStopLimitInsteadStop': 'Use stop limit instead of stop',
    'property.user': 'Login',
    'property.useSSL': 'Secure',
    'property.UnderlierPrice': 'Underlier price',
    'property.UnderlyingPrice': 'Underlying price',
    'property.Value of confidence interval': 'Value of confidence interval',
    'property.ValueColors': 'Value colors',
    'property.VATypes.Horizontal': 'Horizontal',
    'property.VATypes.None': 'None',
    'property.VATypes.ToNextProfile': 'To next profile',
    'property.VATypes.Vertical': 'Vertical',
    'property.VannaVolgaModel': 'Vanna-Volga model',
    'property.vendorName': 'Vendor',
    'property.View': 'View',
    'property.ViewSeparatorGroup': 'View',
    'property.VisibleSeries': 'Visible series',
    'property.Visibility': 'Visibility',
    'property.VisibilitySeparatorGroup': 'Visibility',
    'property.Visible': 'Visible',
    'property.Visual Trading': 'Visual trading',
    'property.VolatilityChange': 'Volatility change',
    'property.Volatility Lab': 'Volatility lab',
    'property.Volume': 'Volume bars',
    'property.Volume.Font': 'Font',
    'property.Volume.FontColor': 'Font color',
    'property.Volume.UseTicksData': 'Use real tick volume',
    'property.VolumeBars.MovingAverage.Active': 'Show moving average',
    'property.VolumeBars.MovingAverage.Line': 'Line color',
    'property.VolumeBars.MovingAverage.Period': 'MA period',
    'property.VolumeColoringMode': 'Size coloring scheme',
    'property.VolumeDownColor': 'Negative',
    'property.VolumeFixedColor': 'Equal',
    'property.VolumeHeightPercent': 'Height, %',
    'property.VolumeMode.BuyVolume': 'Buy volume',
    'property.VolumeMode.Delta': 'Delta',
    'property.VolumeMode.MaxTickVolume': 'Max tick volume',
    'property.VolumeMode.SellVolume': 'Sell volume',
    'property.VolumeMode.TotalVolume': 'Total volume',
    'property.VolumeMode.Trades': 'Trades',
    'property.VolumeOpacityPercent': 'Transparency',
    'property.VolumeProfile': 'Volume profile',
    'property.VolumeUpColor': 'Positive',
    'property.VolumeBars.Visible.Tooltip': 'Set this to false to hide Volume bars on the chart.',
    'property.VolumeBars.PercentageHeight.Tooltip': 'Adjust the percentage to change the height of Volume bars on the chart.',
    'property.VolumeBars.SizeColoringScheme.Tooltip': 'Choose a coloring scheme for Volume bars.',
    'property.VolumeBars.Fixed.Tooltip': 'Use an equal color scheme for Volume bars.',
    'property.VolumeBars.ByBar.Tooltip': 'Color Volume bars based on open and close prices.',
    'property.VolumeBars.ByDifference.Tooltip': 'Color Volume bars based on the comparison with the next bar.',
    'property.VolumeBars.AboveBelowMovingAverage.Tooltip': 'Color Volume bars based on their position relative to the moving average.',
    'property.VolumeBars.EqualColor.Tooltip': 'Select a color scheme for Volume bars based on the specified criteria.',
    'property.VolumeBars.PositiveColor.Tooltip': 'Select a color scheme for Volume bars based on the specified criteria.',
    'property.VolumeBars.NegativeColor.Tooltip': 'Select a color scheme for Volume bars based on the specified criteria.',
    'property.VolumeBars.FontColor.Tooltip': 'Choose the font color for displaying the volume value of bar.',
    'property.VolumeBars.ShowMovingAverage.Tooltip': 'Check this box to configure the moving average line settings.',
    'property.VolumeBars.MAPeriod.Tooltip': 'Adjust the period for calculating the moving average of volume bars.',
    'property.VolumeBars.ShowValueMarketOnScale.Tooltip': 'Check this box to show a value marker on the chart scale.',
    'property.VWAP': 'VWAP',
    'property.Warnings': 'Warnings',
    'property.WatermarkColor': 'Watermark color',
    'property.WatermarkMode': 'Watermark mode',
    'watermarkMode.None': 'None',
    'watermarkMode.Symbol': 'Symbol',
    'watermarkMode.Description': 'Description',
    'watermarkMode.SymbolAndDescription': 'Symbol + Description',
    'watermarkMode.Logo': 'Instrument logo',
    'property.WeekSeparators': 'Week separators',
    'property.Weighted': 'Weighted',
    'property.wickColor': 'Wick',
    'property.width': 'Width:',
    'property.Win num': 'Win num on chart for indicator',
    'property.Win num.addWin': 'Sub window',
    'property.Win num.main': 'Main',
    'property.Y factor': 'Y factor',
    'property.YearSeparators': 'Year separators',
    'property.ZeroLine': 'Zero line',
    'property.ZoomValue': 'Scale step',
    'property.DisableInactivityPeriod': '#hidden#',
    'property.BuyTPTrigger': 'Buy TP trigger price',
    'property.BuySLTrigger': 'Buy SL trigger price',
    'property.SellTPTrigger': 'Sell TP trigger price',
    'property.SellSLTrigger': 'Sell SL trigger price',
    'property.SLTPTriggerInOE': 'Select SL/\TP trigger price in OE',
    'property.BuyTPTrigger.tt': 'Allows to select price type for Buy Take profit order trigger',
    'property.BuySLTrigger.tt': 'Allows to select price type for Buy Stop loss order trigger',
    'property.SellTPTrigger.tt': 'Allows to select price type for Sell Take profit order trigger',
    'property.SellSLTrigger.tt': 'Allows to select price type for Sell Stop loss order trigger',
    'property.SLTPTriggerInOE.tt': 'If active, user will have a possibility to select SL/\TP trigger price in Order entry panel',
    'property.OETPTrigger': 'TP trigger',
    'property.OESLTrigger': 'SL trigger',
    'dynProperty.inactive.tooltip': 'Unable to change setting in disconnected mode!',
    'property.general.DisableInactivityPeriod.confirmation.caption': 'Risk disclosure',
    'property.general.DisableInactivityPeriod.confirmation.message': 'Disable inactivity period.\nI know, understand, and accept the risks associated with disabling the inactivity period.',
    'propery.MainPriceRenderer.Down': 'Down',
    'propery.MainPriceRenderer.Negative': 'Negative',
    'propery.MainPriceRenderer.Positive': 'Positive',
    'propery.MainPriceRenderer.Up': 'Up',
    'Relative Strength Index': 'Relative strength index',
    'reports.#subgroup.Revenue plan.0': 'Commissions',
    'reports.#subgroup.Revenue plan.1': 'Spreads',
    'reports.#subgroup.Revenue plan.2': 'Swaps',
    'reports.#subgroup.Revenue plan.3': 'Rebates',
    'reports.#subgroup.Revenue plan.4': 'Symbol parts',
    'reports.$broker': 'broker',
    'reports.$broker_create_order': 'broker create order',
    'reports.$broker_refuse_with_answer': 'broker refuse with answer',
    'reports.$client': 'client',
    'reports.$client_request': 'client request',
    'reports.$close_position': 'close position',
    'reports.$confirm_order_removed': 'confirm order removed',
    'reports.$enter_system': 'enter system',
    'reports.$executed': 'executed',
    'reports.$leave_system': 'leave system',
    'reports.$open_position': 'open position',
    'reports.$order': 'order',
    'reports.$request_order_removed': 'request order removed',
    'reports.4CastNews': '4CastNews',
    'reports.Accepted': 'Accepted',
    'reports.accname': 'Account name',
    'reports.accoperreqstatus': 'Status',
    'reports.account': 'Account',
    'reports.Account': 'Account',
    'reports.Initial product type': 'Initial product type',
    'reports.Target product type': 'Target product type',
    'reports.Position ID': 'Position ID',
    'reports.Product type modification request': 'Product type modification request',
    'reports.Account Name': 'Account name',
    'reports.Account operation confirmation.': 'Account operation confirmation',
    'reports.Account Operation Report': 'Account Operation Report',
    'reports.Account operation report (settled)': 'Account operation report (settled)',
    'reports.Account Operation Report Summary': 'Summary account operation report',
    'reports.Account operations report': 'Account operations report',
    'reports.Account Operations Report': 'Account operations report',
    'reports.Account Performance Report': 'Account performance report',
    'reports.Account Statement': 'Account statement report',
    'reports.Account statement in defined currency report': 'Account statement in defined currency report',
    'reports.Account Statement on chosen moment report': 'Account statement on chosen moment report',
    'reports.Account Statement Report': 'Account statement report',
    'reports.account_ID': 'Account ID',
    'reports.account.balance': 'Balance',
    'reports.account.crossinstrument': 'Cross symbol',
    'reports.account.currency': 'Currency',
    'reports.account.ismaster': 'Is Master',
    'reports.account.mirroraccounts': 'Mirror accounts',
    'reports.account.name': 'Account name',
    'reports.account.status': 'Status',
    'reports.account.type': 'Type',
    'reports.account.userid': 'User ID',
    'reports.account.visibility.editforsuper': 'Edit for super group',
    'reports.account.visibility.editforthis': 'Edit for this group',
    'reports.account.visibility.tradeforsuper': 'Trade for super group',
    'reports.account.visibility.tradeforthis': 'Trade for this group',
    'reports.account.visibility.viewforsuper': 'View for super group',
    'reports.account.visibility.viewforthis': 'View for this group',
    'reports.accountcurrency': 'Currency',
    'reports.AccountId': 'Account',
    'reports.accountname': 'Account',
    'reports.AccountOperationAmount': 'Amount',
    'reports.accountoperationid': 'Account operation ID',
    'reports.accountPassword': 'Account password',
    'reports.accounts': 'Account(s)',
    'reports.accountUsername': 'Account user name',
    'reports.AccruedInterest': 'Accrued interest',
    'reports.ACM Feed': 'ACM Feed',
    'reports.action': 'Side',
    'reports.actionname': 'Action name',
    'reports.Activated': 'Activated',
    'reports.Active Orders Report': 'Active orders report',
    'reports.actualprice': 'Actual price',
    'reports.actualtime': 'Actual time',
    'reports.addliquidityrebate': 'Add liquidity rebate',
    'reports.address': 'Address',
    'reports.Adjusment': 'Adjustment',
    'reports.Adjustment': 'Adjustment',
    'reports.ADMINISTRATION_RULES': 'Administration rules',
    'reports.AK Equity Algo': 'AK Equity Algo',
    'reports.AK Genex Equity': 'AK Genex Equity',
    'reports.AK Genex Turdex': 'AK Genex Turdex',
    'reports.alertNotify': 'Alert notify',
    'reports.All brokers': 'All brokers',
    'reports.All data received.': 'All data received.',
    'reports.ALLOW_AUTO_APPROVE_TRANSFER_REQUEST': 'Allow auto approve transfer request',
    'reports.ALLOW_AUTO_ORDER_TYPE': 'Automatically set order type (Limit/Stop/Market)',
    'reports.ALLOW_AUTO_RESET_PWD': 'Allow password auto reset',
    'reports.ALLOW_CLOSE_POSITIONS_ON_STOP_OUT': 'Close positions on Stop out',
    'reports.ALLOW_DEMO_AUTO_REGISTRATION': 'Allow demo user auto registration',
    'reports.ALLOW_GENERATE_L3': 'Allow generate L3',
    'reports.ALLOW_GENERATE_PRICE_FROM_SPOT': 'Allow generating price from spot',
    'reports.ALLOW_HEDGE_BY_SPOT': 'Hedge by spot',
    'reports.ALLOW_HEDGE_MARGIN': 'Allow hedge margin',
    'reports.ALLOW_JOIN_POSITIONS': 'Join one-side positions on hedging',
    'reports.ALLOW_LAZY_LOAD_QUOTES': 'Allow lazy quotes load',
    'reports.ALLOW_LIVE_AUTO_REGISTRATION': 'Allow live auto registration',
    'reports.ALLOW_ONE_BROKER_FOR_ALL_OPERATION': 'Allow one broker for all operations',
    'reports.ALLOW_ORDER_AUTOEXECUTION_IS_MARKET_PRICE': 'Execute orders at market price (at order price if false)',
    'reports.ALLOW_PRICE_EXECUTION_MAPPING': 'Price execution mapping',
    'reports.ALLOW_PWD_VERIFICATION': 'Verify password by sms',
    'reports.ALLOW_REFUSE_IF_BROKER_OFFLINE': 'Allow refuse if Broker is offline',
    'reports.ALLOW_REMOVE_ORDERS_ON_STOP_OUT': 'Remove orders on stop out',
    'reports.ALLOW_SAVE_HISTORY': 'Save quote history for symbol',
    'reports.ALLOW_SAVE_QUOTE_LOGS': 'Allow save quote logs',
    'reports.ALLOW_SAVE_QUOTE_TICKS': 'Allow quote ticks saving',
    'reports.ALLOW_SAVE_QUOTES': 'Allow quotes saving',
    'reports.ALLOW_SAVE_TRADE_LOGS': 'Allow save trade logs',
    'reports.ALLOW_SHORT_POSITIONS': 'Allow short positions',
    'reports.ALLOW_SKIP_REVERSED_PRICE': "Skip all 'reversed' quotes",
    'reports.ALLOW_SPIKE_FILTER': 'Allow spike filter',
    'reports.ALLOW_STOP_AVARE_MARGIN': 'Use stop aware margin',
    'reports.ALLOW_STORE_USERS_FILES': 'Allow storage of user files',
    'reports.ALLOW_USE_CERT': 'Allow usage of sign in request',
    'reports.ALLOW_USER_LEVERIGE': 'Allow user leverage',
    'reports.ALLOW_USERS_EMAIL_DUPLICATE': 'Allow duplicating e-mails for demo accounts',
    'reports.ALLOW_VISIBLITY': 'Allow visibility',
    'reports.allowLimitOrders': 'Allow limit orders',
    'reports.allowStopOrders': 'Allow stop orders',
    'reports.already in processing': 'already in processing',
    'reports.amount': 'Quantity',
    'reports.Amount': 'Quantity',
    'reports.Amount filled': 'Quantity filled',
    'reports.amountperside': 'Amount per side',
    'reports.An error occurred during sending mail using user settings. Will use default settings.': 'An error occurred during sending mail using user settings. Will use default settings.',
    'reports.Aphelion': 'Aphelion',
    'reports.APPLICATION.NAME': 'APPLICATION NAME',
    'reports.Approved': 'Approved',
    'reports.asset': 'Asset',
    'reports.AuditReport': 'Backoffice changes report',
    'reports.Authorization Error: Operation is nor allowed': 'Authorization error: Operation is nod allowed',
    'reports.autotrade': 'Auto trade',
    'reports.Average filled price': 'Price',
    'reports.Back Office Changes Report': 'Back office changes report',
    'reports.balance': 'Balance',
    'reports.Balance': 'Balance',
    'reports.Balance by Instrument Report': 'Balance by symbol report',
    'reports.Balance By Instrument Report': 'Balance by symbol report',
    'reports.Balance Operations Report': 'Balance operations report',
    'reports.Balance Report': 'Balance report',
    'reports.Balance Report(copy)': 'Balance report(copy)',
    'reports.Balance Summary report': 'Balance summary report',
    'reports.Balance Summary Report': 'Balance summary report',
    'reports.balance_after': 'Balance after',
    'reports.balance_before': 'Balance before',
    'reports.balance.amount': 'Quantity',
    'reports.balance.buy': 'Buy',
    'reports.balance.everbuy': 'Average buy',
    'reports.balance.eversell': 'Average sell',
    'reports.balance.exposure': 'Exposure',
    'reports.balance.exposurenet': 'Exposure net',
    'reports.balance.grossexposure': 'Gross exposure',
    'reports.balance.grosspl': 'Gross P/L',
    'reports.balance.grossvalue': 'Gross position value',
    'reports.balance.net': 'Net',
    'reports.balance.netexposure': 'Net exposure',
    'reports.balance.netvalue': 'Net position value',
    'reports.balance.profit': 'Profit',
    'reports.balance.sell': 'Sell',
    'reports.Barclays': 'Barclays',
    'reports.basis': 'Comment',
    'reports.Basis': 'Details',
    'reports.because Not enoght margin to create Order': 'because not enough margin to create order',
    'reports.because Price for Stop limit order is incorrect.': 'since price for Stop limit order is incorrect.',
    'reports.BeginString': 'Begin string',
    'reports.blocked_funds': 'Blocked funds(margin)',
    'reports.blockedbalance': 'Blocked balance',
    'reports.Blocking': 'Blocking',
    'reports.bmr_brokermessageid': 'Message ID',
    'reports.bmr_brokermessagename': 'Message name',
    'reports.bmr_confirmtime': 'Target time',
    'reports.bmr_targetuserid': 'User ID',
    'reports.bmr_targetuserlogin': 'User',
    'reports.Bound To': 'Bound to',
    'reports.Bound to order': 'Bound to order',
    'reports.boundtoorderid': 'Bound order ID',
    'reports.BRANDING_ADMIN_HELP': 'Admin help URL',
    'reports.BRANDING_ANONYM_GROUP': 'Place auto registered anonymous users into',
    'reports.BRANDING_ANONYM_GROUP_SUPPORT': 'Support group for anonymous users',
    'reports.BRANDING_CLIENT_MINIMAL_VERSION': 'Minimal client version(for force update)',
    'reports.BRANDING_COMMON_RULES': 'Common',
    'reports.BRANDING_COMPANY_DESC': 'Branding company descr.',
    'reports.BRANDING_COMPANY_INFO_RULES': 'Company information',
    'reports.BRANDING_COMPANY_NAME': 'Company name',
    'reports.BRANDING_COMPANY_URL': 'Company URL',
    'reports.BRANDING_DEMO_GROUP': 'Place auto registered demo users into',
    'reports.BRANDING_DEMOREGISTRATION_URL': 'Demo registration (empty-this server; 0-disable; url-web page)',
    'reports.BRANDING_DEPOSIT_URL': 'Deposit URL (empty to disable, url for page)',
    'reports.BRANDING_DISCLAMER': 'Disclaimer text (showed in login skin 0)',
    'reports.BRANDING_DOWJONES_ENCRYPTED_TOKEN': 'Dow Jones encrypted token',
    'reports.BRANDING_EMAIL': 'Company Email',
    'reports.BRANDING_EXT_LINK_RULES': 'External links',
    'reports.BRANDING_FORGOTPWD_URL': 'Forgot password URL',
    'reports.BRANDING_HTML_FOOTER': 'Footer image',
    'reports.BRANDING_HTML_LOGINSCREEN_LOGO': 'Login screen image',
    'reports.BRANDING_HTML_LOGO': 'Logo image',
    'reports.BRANDING_HTML_NAME': 'Application name',
    'reports.BRANDING_HTML_STATION_RULES': 'Back office',
    'reports.BRANDING_INFO_MESSAGE': 'Popup message (opened in station after login)',
    'reports.BRANDING_KEY': 'Branding Key(used to link installators to it)',
    'reports.BRANDING_KNOWLEDGE_BASE_URL': 'Knowledge base URL(-1 to disable)',
    'reports.BRANDING_LANG_SET': 'Language resources',
    'reports.BRANDING_LIVE_GROUP': 'Place auto registered live users into',
    'reports.BRANDING_LOGIN_MESSAGE': 'Login screen attention message',
    'reports.BRANDING_MAIL_CONFIGURATION': 'Mail configuration',
    'reports.BRANDING_MAIL_PATTERN': 'Pattern',
    'reports.BRANDING_NET_BACKGROUND': 'Background image (optional/.bmp any size)',
    'reports.BRANDING_NET_BANNER': 'Banner image (.bmp 500x36)',
    'reports.BRANDING_NET_BROKERIMAGE': 'Report image (.bmp any size)',
    'reports.BRANDING_NET_CONFIGURAION': 'Default configuration (program.ini file)',
    'reports.BRANDING_NET_DEFWORKSPACE': 'Default workspace (*.wrk file)',
    'reports.BRANDING_NET_FX_STYLE': 'FX cell skin (enter code)',
    'reports.BRANDING_NET_HELP': 'Help URL',
    'reports.BRANDING_NET_ICON': 'Icon image (.ico 16x16+32x32)',
    'reports.BRANDING_NET_INSTALLER_BANNER_IMAGE': 'Installer banner image(500*59)',
    'reports.BRANDING_NET_INSTALLER_DIALOG_IMAGE': 'Installer dialog image(500*316)',
    'reports.BRANDING_NET_LINK': 'Application link',
    'reports.BRANDING_NET_LOADING_IMAGE': 'Loading image *.gif',
    'reports.BRANDING_NET_LOGIN_STYLE': 'Login screen skin (enter code)',
    'reports.BRANDING_NET_LOGO': 'Logo image (.bmp 500x35)',
    'reports.BRANDING_NET_NAME': 'Application name (for app data, short-cuts)',
    'reports.BRANDING_NET_OE_STYLE': 'Order entry skin (enter code)',
    'reports.BRANDING_NET_RIBBONICON': 'Ribbon icon image (.ico 32x32)',
    'reports.BRANDING_NET_STATION_RULES': '.Net station',
    'reports.BRANDING_NET_USE_FINGERPRINT': 'Use fingerprint',
    'reports.BRANDING_PHONE': 'Company phone',
    'reports.BRANDING_POWERED_BY': 'Powered by (company or person name)',
    'reports.BRANDING_PROPERTIES_RULES': 'Branding properties',
    'reports.BRANDING_RULES': 'Branding visibility',
    'reports.BRANDING_SCRIPT_URL': 'Script URL',
    'reports.BRANDING_SERVER_URL': 'Branded server URL (URL or ip to link first page, before login)',
    'reports.BRANDING_STATUS_MESSAGE': 'Status bar message (showed in station status bar)',
    'reports.BRANDING_THEME': 'Look & Feel',
    'reports.BRANDING_TOOLS_RULES': 'External web links in Tools menu',
    'reports.BRANDING_TTP_FILE': 'Panel Templates',
    'reports.BRANDING_USERS_RULES': 'User rules',
    'reports.BRANDING_WITHDRAW_URL': 'Withdraw URL (empty to disable, url for page, 1 -win form)',
    'reports.branding.name': 'Name',
    'reports.Broker Message Report': 'Broker message report',
    'reports.Brokerage': 'Brokerage',
    'reports.brokerCancel': 'Broker cancel',
    'reports.brokerlogin': 'Broker login',
    'reports.Buy': 'Buy',
    'reports.buy trade amount': 'Buy trade amount',
    'reports.buy trades': 'Buy trades',
    'reports.buyselltype': 'Buy/Sell type',
    "reports.Can't allocate route for trade using hadget": "Can't allocate route for trade using hadget",
    "reports.Can't close entry": "Can't close entry",
    'reports.Cancel order:': 'Cancel order:',
    'reports.Cancel request accepted by broker': 'Сancellation request accepted by broker',
    'reports.Cancel request received by STP': 'Сancellation request received by STP',
    'reports.Canceled': 'Cancelled',
    'reports.Canceling': 'Cancelled',
    'reports.Cancelled': 'Cancelled',
    'reports.Cancelling': 'Cancelled',
    'reports.CantorFX': 'CantorFX',
    'reports.Cash Deposit And Withdrawal Report': 'Cash deposit and withdrawal report',
    'reports.cash_posted': 'Cash posted',
    'reports.Cash': 'Cash',
    'reports.Champ stats report': 'Champ statistics report',
    'reports.change': 'Change',
    'reports.Change password error, new password must differ from old one': 'Change password error, new password must differ from old one',
    'reports.CHART_OE': 'Desktop:Chart OE',
    'reports.DESKTOP_ALERT': 'Desktop:Alert',
    'reports.REST': 'SERVER:REST',
    'reports.MAC_ALERT': 'MAC:Alert',
    'reports.ANDROID_ALERT': 'MOB:Alert “Android”',
    'reports.IOS_ALERT': 'MOB:Alert “IOS”',
    'reports.CHART_VISUAL': 'Desktop:Chart Visual',
    'reports.Chat History Report': 'Chat history report',
    'reports.CheckCompID': 'Check comp ID',
    'reports.CheckLatency': 'Check latency',
    'reports.ChoiceFX': 'ChoiceFX',
    'reports.CIBC': 'CIBC',
    'reports.Citi': 'Citi',
    'reports.ClearingVmTransfer': 'Clearing VM transfer',
    'reports.clfr_close_date': 'Close date',
    'reports.clfr_fund_name': 'Fund name',
    'reports.clientid': 'Client ID',
    'reports.clienttype': 'Client type',
    'reports.Close Limit order created': 'Close limit order created',
    'reports.Close Limit order created is off market': 'Close limit order is off market',
    'reports.Close Limit order created is unplaced': 'Close limit order was unplaced on exchange',
    'reports.Close Limit order received by broker': 'Close limit order received by broker',
    'reports.Close Market order created': 'Close market order created',
    'reports.Close Market Order Created': 'Close Market order created',
    'reports.Close Market order created is off market': 'Close market order is off market',
    'reports.Close Market order created is unplaced': 'Close market order was unplaced on exchange',
    'reports.Close Market order received by broker': 'Close market order received by broker',
    'reports.Close OCO order created': 'Close OCO order created',
    'reports.Close OCO order created is off market': 'Close OCO order is off market',
    'reports.Close OCO order created is unplaced': 'Close OCO order was unplaced on exchange',
    'reports.Close OCO order received by broker': 'Close OCO order received by broker',
    'reports.Close order type': 'Close order type',
    'reports.Close position:': 'Close position:',
    'reports.Close Sell': 'Close Sell',
    'reports.Close SL order created': 'Close SL order created',
    'reports.Close SL order created is off market': 'Close SL order is off market',
    'reports.Close SL order created is unplaced': 'Close SL order was unplaced on exchange',
    'reports.Close SL order received by broker': 'Close SL order received by broker',
    'reports.Close SL tr. stop order created': 'Close SL Tr. stop order created',
    'reports.Close SL tr. stop order created is off market': 'Close SL Tr. stop order is off market',
    'reports.Close SL tr. stop order created is unplaced': 'Close SL Tr. stop order was unplaced on exchange',
    'reports.Close SL tr. stop order received by broker': 'Close SL Tr. stop order received by broker',
    'reports.Close Stop Limit order created': 'Close stop limit order created',
    'reports.Close Stop Limit order created is off market': 'Close stop limit order is off market',
    'reports.Close Stop Limit order created is unplaced': 'Close stop limit order was unplaced on exchange',
    'reports.Close Stop Limit order received by broker': 'Close stop limit order received by broker',
    'reports.Close Stop order created': 'Close stop order created',
    'reports.Close Stop order created is off market': 'Close stop order is off market',
    'reports.Close Stop order created is unplaced': 'Close stop order was unplaced on exchange',
    'reports.Close Stop order received by broker': 'Close stop order received by broker',
    'reports.Close TP order created': 'Close TP order created',
    'reports.Close TP order created is off market': 'Close TP order is off market',
    'reports.Close TP order created is unplaced': 'Close TP order was unplaced on exchange',
    'reports.Close TP order received by broker': 'Close TP order received by broker',
    'reports.Close Tr. stop order created': 'Close Tr. stop order created',
    'reports.Close Tr. stop order created is off market': 'Close Tr. stop order is off market',
    'reports.Close Tr. stop order created is unplaced': 'Close Tr. stop order was unplaced on exchange',
    'reports.Close Tr. stop order received by broker': 'Close Tr. stop order received by broker',
    'reports.CLOSE_ALL': 'Close all',
    'reports.CLOSE_ALL_ORDERS': 'SERVER:Cancel all orders',
    'reports.CLOSE_ALL_POSITIONS': 'SERVER:Close all positions',
    'reports.CLOSE_FIFO': 'Close positions while not enough margin (FIFO)',
    'reports.CLOSE_LAGES': 'Close positions while not enough margin (Largest first)',
    'reports.CLOSE_LIFO': 'Close positions while not enough margin (LIFO)',
    'reports.closebytradeid': 'Close by trade ID',
    'reports.Closed funds report': 'Closed funds report',
    'reports.Closed Positions': 'Closed positions report',
    'reports.closedat': 'Close',
    'reports.closedbyorderid': 'Close by order ID',
    'reports.ClosedResendInterval': 'Closed resend interval',
    'reports.CloseOptionPremium': 'Close option premium',
    'reports.Closeposition': 'Close position',
    'reports.closeprice': 'Close price',
    'reports.closetime': 'Close time',
    'reports.comission': 'Fee',
    'reports.Comment': 'Comment',
    'reports.comments': 'Comments',
    'reports.comments.CancelOperation': 'Cancel operation #',
    'reports.COMMISIONSPLAN_RULES': 'Revenue plans visibility',
    'reports.commission': 'Commission',
    'reports.commission_out': 'Fee out',
    'reports.commissionpertrade': 'Commission per trade',
    'reports.commissions': 'Commissions',
    'reports.Commissions': 'Fee',
    'reports.Commissions report': 'Commissions report',
    'reports.Commissions Report': 'Commissions report',
    'reports.Commissions Report(copy)': 'Commissions report(copy)',
    'reports.commissiontotal': 'Commission total',
    'reports.COMMON_RULES': 'Common rules',
    'reports.Community': 'Community',
    'reports.ConfigFilePath': 'Configure file path',
    'reports.Connected': 'Connected',
    'reports.Connection established': 'Connection established',
    'reports.Connections Report': 'Connections report',
    'reports.ConnectionType': 'Connection type',
    'reports.connrp_conntime': 'Connection time attempt',
    'reports.connrp_fullname': 'Name and Last name',
    'reports.connrp_ip': 'IP',
    'reports.connrp_lastpwdchange': 'Last password change',
    'reports.connrp_login': 'Login',
    'reports.connrp_success': 'Success of connection',
    'reports.ContinueInitializationOnError': 'Continue initialization on error',
    'reports.Contract Specification Report': 'Contract specification report',
    'reports.Counter Account Name': 'Counter account name',
    'reports.Counter Operation Id': 'Counter operation ID',
    'reports.COUNTERACCOUNTS_RULES': 'Counter accounts',
    'reports.Created': 'Created',
    'reports.Created by': 'Created by',
    'reports.createdat': 'Created at',
    'reports.Creating': 'Created',
    'reports.creator': 'Creator',
    'reports.credit': 'Credit',
    'reports.Credit': 'Credit',
    'reports.crossprice': 'Cross price',
    'reports.crosstype': 'Cross type',
    'reports.currency': 'Currency',
    'reports.Currency Exposure Report': 'Currency exposure report',
    'reports.Currency summary exposure': 'Currency summary exposure',
    'reports.currency.balance_interest': 'Balance interest(%)',
    'reports.currency.interest_buy_rate': 'Interest on positive(%)',
    'reports.currency.interest_sell_rate': 'Interest on negative(%)',
    'reports.currency.leverage_negative': 'Negative exposure leverage',
    'reports.currency.leverage_positive': 'Positive exposure leverage',
    'reports.currency.min_change': 'Minimal change',
    'reports.currency.name': 'Name',
    'reports.Currenex': 'Currenex',
    'reports.CurrenexFIX': 'CurrenexFIX',
    'reports.Current Balance': 'Current balance',
    'reports.currentprice': 'Current price',
    'reports.CUSTOM_COLUMN_BTN_SCRIPT': 'Desktop:Custom column',
    'reports.customReport': 'Custom reports',
    'reports.DataDictionary': 'Data dictionary',
    'reports.DateTime': 'Date/Time',
    'reports.days': 'days',
    'reports.daystillexpiration': 'Days till expiration',
    'reports.DBFX': 'DBFX',
    'reports.dealercommission': 'Dealer commission',
    'reports.debit': 'Debit',
    'reports.Default settings failed': 'Default settings failed',
    'reports.Delayed Limit Order Created': 'Pending Limit order created',
    'reports.Delayed Limit Order Modified': 'Pending Limit order modified',
    'reports.Delayed Limit Order Removed': 'Pending Limit order removed',
    'reports.Delayed OCO Order Created': 'Pending OCO order created',
    'reports.Delayed Stop Limit Order Created': 'Stop limit order created',
    'reports.Delayed Stop Limit Order Modified': 'Stop limit order modified',
    'reports.Delayed Stop Limit Order Removed': 'Stop limit order removed',
    'reports.Delayed Stop Order Created': 'Pending Stop order created',
    'reports.Delayed Stop Order Modified': 'Pending Stop order modified',
    'reports.Delayed Stop Order Removed': 'Pending Stop order removed',
    'reports.Delayed Trailing Stop Order Created': 'Trailing stop order created',
    'reports.Delayed Trailing Stop Order Modified': 'Trailing stop order modified',
    'reports.Delayed Trailing Stop Order Removed': 'Trailing stop order removed',
    'reports.DelayedCharging': 'DelayedCharging',
    'reports.DelayedDataCharging': 'Delayed data charging',
    'reports.delivered': 'Delivered',
    'reports.DeliveredForward': 'Delivered forward',
    'reports.Delivery': 'Delivery',
    'reports.demoMode': 'Demo mode',
    'reports.deposit': 'Deposit',
    'reports.Deposit': 'Deposit',
    'reports.Detail': 'Detail',
    'reports.Deutsche Bank': 'Deutsche Bank',
    'reports.DeutscheBankFIXFeed': 'DeutscheBankFIXFeed',
    'reports.DGCX': 'DGCX',
    'reports.Dictionary': 'Dictionary',
    'reports.discount': 'Discount',
    'reports.dividends': 'Dividends',
    'reports.DO_NOT_CLOSE': 'Do not close positions',
    'reports.DukasCopy': 'DukasCopy',
    'reports.DWT': 'DWT',
    'reports.email': 'Email',
    'reports.EMAIL_NOTIFICATION': 'E-mail notification pattern visibility',
    'reports.EMAIL_RULES': 'Email notification pattern visibility',
    'reports.EndDay': 'End day',
    'reports.EndTime': 'End time',
    'reports.entityId': 'Entity ID',
    'reports.enum_StopOutType_AutoClose': 'Auto close',
    'reports.enum_StopOutType_CloseAllByInstruments': 'Close all by instruments',
    'reports.enum_StopOutType_CloseAllPositions': 'Close all positions',
    'reports.enum_StopOutType_CloseLagestLoss': 'Close positions while not enough margin (Largest loss first)',
    'reports.enum_StopOutType_ClosePositionsByMarginExcess': 'Close positions by margin excess',
    'reports.enum_StopOutType_DoNotClosePositions': 'Do not close positions',
    'reports.enum_StopOutType_FIFO': 'Close positions while not enough margin (FIFO)',
    'reports.enum_StopOutType_LargestFirst': 'Close positions while not enough margin (Largest first)',
    'reports.enum_StopOutType_LIFO': 'Close positions while not enough margin (LIFO)',
    'reports.Error occured during downloading update:': 'Error occurred during downloading update:',
    'reports.Error sending email': 'Error sending email',
    'reports.Error: Not enough Margin to open position': 'Error: Not enough margin to open position',
    'reports.error.certificate_corrupted': 'User certificate is corrupted.',
    'reports.error.certificate_not_found': 'User certificate is not found.',
    'reports.error.error_during_saving_route': 'An error occurred while saving Route. Please contact your administrator.',
    'reports.error.fill_mandatory': 'Please fill in all mandatory fields.',
    'reports.error.name_is_in_use': 'This name already exists!',
    'reports.error.not_valid_email': 'Invalid E-mail.',
    'reports.error.not_valid_firstname': 'Invalid first name.',
    'reports.error.not_valid_instrumentname_capital': 'Invalid Name. Only capital letters are required.',
    'reports.error.not_valid_instrumentname_exist': 'Invalid Name. This name already exists.',
    'reports.error.not_valid_instrumentname_length': "Invalid Name. Name can't be longer than 32 characters.",
    'reports.error.not_valid_instrumenttypename': 'Invalid instrument type. Please specify correct instrument type.',
    'reports.error.not_valid_lastname': 'Invalid last name.',
    'reports.error.not_valid_login': 'Invalid login.',
    'reports.error.not_valid_name': 'Invalid name.',
    'reports.error.not_valid_name_exist': 'Invalid Name. This name already exists.',
    'reports.error.not_valid_phone_number': 'Invalid phone number. Enter only digital data.',
    'reports.error.photo_not_found': 'Photo is not found',
    'reports.error.too_short_phone_password': 'Too short phone password',
    'reports.error.wrong_percenage': 'Wrong percentage',
    'reports.ESignal': 'ESignal',
    'reports.event': 'Event',
    'reports.Event History Report': 'Event history report',
    'reports.Event History Report with IP': 'Event history report with IP',
    'reports.Exception': 'Exception',
    'reports.exchange': 'Exchange',
    'reports.Exchange': 'Exchange',
    'reports.Executed': 'Filled',
    'reports.Executed Orders Report': 'Executed orders report',
    'reports.executedat': 'Execute',
    'reports.Executing': 'Filled',
    'reports.executionfee': 'Execution fee',
    'reports.executionprice': 'Execution price',
    'reports.expiredate': 'Expiration date',
    'reports.expiresat': 'Expire',
    'reports.external_order_id': 'External order id',
    'reports.externalid': 'External ID',
    'reports.externalorderid': 'External order ID',
    'reports.extfield': 'Ext. field',
    'reports.EXTFIELDS_RULES': 'Extended fields visibility',
    'reports.extprice': 'Ext. price',
    'reports.False': 'False',
    'reports.fee': 'Fee',
    'reports.Fee: [addliquidityrebate]': 'Fee: [addliquidityrebate]',
    'reports.Fee: [Brokerage]': 'Fee: [Brokerage]',
    'reports.Fee: [Exchange]': 'Fee: [Exchange]',
    'reports.Fee: [InvestorProtectionLevy]': 'Fee: [InvestorProtectionLevy]',
    'reports.Fee: [removeliquidityrebate]': 'Fee: [removeliquidityrebate]',
    'reports.Fee: [SecuritiesTransferTax]': 'Fee: [SecuritiesTransferTax]',
    'reports.Fee: [SettlementFee]': 'Fee: [SettlementFee]',
    'reports.Fee: [ValueAddedTax]': 'Fee: [ValueAddedTax]',
    'reports.Fee: [CareOrder]': 'Fee: [CareOrder]',
    'reports.Feed Data': 'Feed data',
    'reports.Fees Report': 'Fees report',
    'reports.FFastFillFIXFeed': 'FFastFillFIXFeed',
    'reports.fiahr_action': 'Action',
    'reports.fiahr_amount': 'Amount',
    'reports.fiahr_capital': 'Capital',
    'reports.fiahr_count': 'Share Count',
    'reports.fiahr_date': 'Date',
    'reports.fiahr_fee': 'Reward',
    'reports.fiahr_grosspl': 'Gross P/L',
    'reports.fiahr_investor_id': 'Investor ID',
    'reports.fiahr_investor_name': 'Investor',
    'reports.fiahr_netpl': 'Net P/L',
    'reports.fiahr_pamm_id': 'Fund ID',
    'reports.fiahr_pamm_name': 'Fund',
    'reports.fiahr_price': 'Price',
    'reports.fiahr_requestid': 'Request ID',
    'reports.Fidessa': 'Fidessa',
    'reports.FileIncludeMilliseconds': 'File includes milliseconds',
    'reports.FileIncludeTimeStampForMessages': 'File includes time stamp for messages',
    'reports.FileLogHeartbeats': 'File log heartbeats',
    'reports.Filled': 'Filled',
    'reports.Filled close Limit order': 'Close limit order filled',
    'reports.Filled close Limit order (Mutual)': 'Close limit order filled (mutually)',
    'reports.Filled close Market order': 'Close market order filled',
    'reports.Filled close Market order (Mutual)': 'Close Market order filled (mutually)',
    'reports.Filled close OCO order': 'Close OCO order filled',
    'reports.Filled close OCO order (Mutual)': 'Close OCO order filled (mutually)',
    'reports.Filled close SL order': 'Close SL order filled',
    'reports.Filled close SL order (Mutual)': 'Close SL order filled (mutually)',
    'reports.Filled close SL tr. stop order': 'Close SL Tr. stop order filled',
    'reports.Filled close SL tr. stop order (Mutual)': 'Close SL Tr. stop order filled (mutually)',
    'reports.Filled close Stop Limit order': 'Close stop limit order filled',
    'reports.Filled close Stop Limit order (Mutual)': 'Close stop limit order filled (mutually)',
    'reports.Filled close Stop order': 'Close stop order filled',
    'reports.Filled close Stop order (Mutual)': 'Close stop order filled (mutually)',
    'reports.Filled close TP order': 'Close TP order filled',
    'reports.Filled close TP order (Mutual)': 'Close TP order filled (mutually)',
    'reports.Filled close Tr. stop loss order': 'Filled close Tr. stop loss order',
    'reports.Filled close Tr. stop order': 'Close Tr. stop order filled',
    'reports.Filled close Tr. stop order (Mutual)': 'Close Tr. stop order filled (mutually)',
    'reports.Filled Limit order': 'Limit order filled',
    'reports.Filled Limit order (Mutual)': 'Limit order filled (mutually)',
    'reports.Filled Market order': 'Market order filled',
    'reports.Filled Market order (Mutual)': 'Market order filled (mutually)',
    'reports.Filled OCO order': 'OCO order filled',
    'reports.Filled OCO order (Mutual)': 'OCO order filled (mutually)',
    'reports.Filled SL order': 'SL order filled',
    'reports.Filled SL order (Mutual)': 'SL order filled (mutually)',
    'reports.Filled SL tr. stop order': 'SL Tr. stop order filled',
    'reports.Filled SL tr. stop order (Mutual)': 'SL Tr. stop order filled (mutually)',
    'reports.Filled Stop Limit order': 'Stop limit order filled',
    'reports.Filled Stop Limit order (Mutual)': 'Stop limit order filled (mutually)',
    'reports.Filled Stop order': 'Stop order filled',
    'reports.Filled Stop order (Mutual)': 'Stop order filled (mutually)',
    'reports.Filled TP order': 'TP order filled',
    'reports.Filled TP order (Mutual)': 'TP order filled (mutually)',
    'reports.Filled Tr. stop order': 'Tr. stop order filled',
    'reports.Filled Tr. stop order (Mutual)': 'Tr. stop order filled (mutually)',
    'reports.filledamount': 'Filled amount',
    'reports.fir_exit_capital': 'Current capital',
    'reports.fir_exit_price': 'Current share price',
    'reports.fir_investor_id': 'Investor ID',
    'reports.fir_investor_name': 'Investor name',
    'reports.fir_join_capital': 'Start capital',
    'reports.fir_join_date': 'Join date',
    'reports.fir_join_price': 'Join price',
    'reports.fir_pamm_id': 'Fund id',
    'reports.fir_pamm_name': 'Fund name',
    'reports.fir_reinvest_cycles': 'Reinvest cycles',
    'reports.fir_share_count': '#Shares',
    'reports.FirstDerivatives': 'FirstDerivatives',
    'reports.firstname': 'First name',
    'reports.FixedFee': 'Fixed Commission',
    'reports.Custodial': 'Custodial',
    'reports.PortfolioOvernightFee': 'Portfolio overnight fee',
    'reports.CareOrder': 'Care order',
    'reports.Foreks': 'Foreks',
    'reports.Forex balances report': 'Forex balances report',
    'reports.Formal Dialog Report': 'Formal dialog report',
    'reports.FORTEX': 'FORTEX',
    'reports.fpghr_count': 'Shares',
    'reports.fpghr_exit_amount': 'Exit amount',
    'reports.fpghr_exit_price': 'Exit price',
    'reports.fpghr_fee': 'Fee',
    'reports.fpghr_first_join_date': 'Join date',
    'reports.fpghr_grosspl': 'Gross P/L',
    'reports.fpghr_investor_id': 'Investor ID',
    'reports.fpghr_investor_name': 'Investor name',
    'reports.fpghr_join_amount': 'Join amount',
    'reports.fpghr_join_price': 'Join price',
    'reports.fpghr_last_exit_date': 'Exit date',
    'reports.fpghr_netpl': 'Net P/L',
    'reports.fpghr_pamm_id': 'Fund ID',
    'reports.fpghr_pamm_name': 'Fund name',
    'reports.fpghr_period': 'Period',
    'reports.fpghr_period_end_date': 'End date',
    'reports.fpghr_period_start_date': 'Start date',
    'reports.fprhr_date': 'Last update',
    'reports.fprhr_details': 'Details',
    'reports.fprhr_entryid': 'Entry ID',
    'reports.fprhr_event': 'Event',
    'reports.fprhr_investor_id': 'Investor ID',
    'reports.fprhr_investor_name': 'Investor',
    'reports.fprhr_pamm_id': 'Fund ID',
    'reports.fprhr_pamm_name': 'Fund',
    'reports.fprhr_requestid': 'Request ID',
    'reports.from': 'From',
    'reports.From.SERVER': 'Server',
    'reports.fromDate': 'From',
    'reports.fromDateConnAttempt': 'From',
    'reports.fromDatePwdChange': 'From(Pass)',
    'reports.FUNCTION_ACCOUNT_GROUP': 'Account group',
    'reports.FUNCTION_ACCOUNT_OPERATIONS': 'Funding operations visibility',
    'reports.FUNCTION_ACCOUNTS': 'Accounts',
    'reports.FUNCTION_ALERTS': 'Alerts',
    'reports.FUNCTION_ARBITRAGER': 'Arbitrager',
    'reports.FUNCTION_AUTOBROKET_LIST': 'Autobroker list',
    'reports.FUNCTION_BACK_TESTER': 'Backtester',
    'reports.FUNCTION_BARS_FROM': 'Make bars from',
    'reports.FUNCTION_BASE_CURRENCY': 'System base currency (you might need to change instrument crosses)',
    'reports.FUNCTION_BASKET': 'Basket',
    'reports.FUNCTION_BETTING': 'Betting',
    'reports.FUNCTION_BINDEDORDERS': 'Allow OCO orders',
    'reports.FUNCTION_BLOCKED_SUMM_FOR_ORDERS': 'Blocked fund for orders',
    'reports.FUNCTION_BRANDING': 'Brandings',
    'reports.FUNCTION_BRANDINGADMIN': 'Branding admin',
    'reports.FUNCTION_BUILDER': 'Builder',
    'reports.FUNCTION_CACHEADMIN': 'Cache admin',
    'reports.FUNCTION_CHART': 'Chart',
    'reports.FUNCTION_CHAT': 'Chat',
    'reports.FUNCTION_CLOSE_POSITIONS_DISABLE': 'Not to allow  close positions',
    'reports.FUNCTION_COMMUNITY': 'Community',
    'reports.FUNCTION_CONFIGADMIN': 'Configuration and branding',
    'reports.FUNCTION_CONNECTIONSSADMIN': 'Connections admin',
    'reports.FUNCTION_CROSS_PAIR': 'Cross price',
    'reports.FUNCTION_CUR_EXP': 'Currency exposure',
    'reports.FUNCTION_DISABLE_BUY': 'Disable buy orders',
    'reports.FUNCTION_DISABLE_OPTION_TRADING': 'Disable option orders',
    'reports.FUNCTION_DISABLE_SELL': 'Disable sell orders',
    'reports.FUNCTION_DO_NOT_MARGIN': 'Not to use margin',
    'reports.FUNCTION_EVENT_LOG': 'Event log',
    'reports.FUNCTION_FUNDING': 'Operations admin',
    'reports.FUNCTION_GENERATE_OPTIONS': 'Generate options from level1',
    'reports.FUNCTION_HEDGE_RULE_TYPE': 'Hedge rule list:',
    'reports.FUNCTION_INSTRUMENT_LOOKUP': 'Symbol lookup',
    'reports.FUNCTION_INSTRUMENT_PORTFOLIO': 'Portfolio',
    'reports.FUNCTION_INSTRUMENTSADMIN': 'Symbols admin',
    'reports.FUNCTION_INTERSERVER': 'Interserver',
    'reports.FUNCTION_LEVEL2': 'Level2 data and orders',
    'reports.FUNCTION_LEVEL3': 'Time & Sales',
    'reports.FUNCTION_LOCKED_BY_BROKER': 'Locked by broker',
    'reports.FUNCTION_MANUAL': 'Allow manual orders',
    'reports.FUNCTION_MARGIN_MODE': 'Margin mode',
    'reports.FUNCTION_MASTER': 'Master account management',
    'reports.FUNCTION_MATRIX': 'Matrix',
    'reports.FUNCTION_MULTI_CONNECTION': 'Allow multi-connection',
    'reports.FUNCTION_MUTUAL_CLOSE': 'Allow mutual closing',
    'reports.FUNCTION_NEWS': 'News',
    'reports.FUNCTION_NEWSADMIN': 'News admin',
    'reports.FUNCTION_NOT_ALLOW_OPEN_POSITION': 'Not allow open positions',
    'reports.FUNCTION_NOT_ALLOW_SHORT_STOCK': 'Not to allow short stocks',
    'reports.FUNCTION_ONE_COUNTERACCOUNT': 'One counter account',
    'reports.FUNCTION_ONE_POSITION': 'One position per symbol (hedging)',
    'reports.FUNCTION_ONE_QUOTECACHECOUNT': 'One cache size for all',
    'reports.FUNCTION_OPTIONS': 'Options',
    'reports.FUNCTION_ORDER_BOOK': 'Orders history',
    'reports.FUNCTION_PARTIAL_CLOSE': 'Allow partial closing',
    'reports.FUNCTION_POSITION_BALANCE': 'Pos. balance',
    'reports.FUNCTION_POSITIONSADMIN': 'Positions admin',
    'reports.FUNCTION_QUOTE_HISTORY': 'Quote history',
    'reports.FUNCTION_QUOTES': 'Quotes',
    'reports.FUNCTION_REPORT': 'Report',
    'reports.FUNCTION_REPORTADMIN': 'Report admin',
    'reports.FUNCTION_RESERVER_WITHDRAWAL': 'Withdrawal',
    'reports.FUNCTION_PRODUCTS': 'Products',
    'reports.FUNCTION_PRODUCTS_FAILURE': 'Product subscription failure',
    'reports.FUNCTION_RFQ': 'RFQ Panel',
    'reports.FUNCTION_ROLESADMIN': 'User groups admin',
    'reports.FUNCTION_ROUTESADMIN': 'Routes admin',
    'reports.FUNCTION_SCHEDULERADMIN': 'Scheduler admin',
    'reports.FUNCTION_SCRIPT_BUILDER': 'Script builder',
    'reports.FUNCTION_SERVICESADMIN': 'Services admin',
    'reports.FUNCTION_SETTINGSADMIN': 'Settings admin',
    'reports.FUNCTION_SHOW_ORDERS': 'Orders',
    'reports.FUNCTION_SHOW_POSITIONS': 'Positions',
    'reports.FUNCTION_SLAVE': 'Slave',
    'reports.FUNCTION_SLTP': 'Allow SL/TP orders',
    'reports.FUNCTION_SMS_SERVICE_DEST': 'SMS Service destination',
    'reports.FUNCTION_SN': 'License number',
    'reports.FUNCTION_STRATEGIESADMIN': 'Strategies admin',
    'reports.FUNCTION_SUPPORT_EMAIL': 'E-mail of support Team',
    'reports.FUNCTION_TABLESADMIN': 'Tables admin',
    'reports.FUNCTION_THREADEDSAVER': 'Threaded saver enabled',
    'reports.FUNCTION_TRADES': 'Filled orders',
    'reports.FUNCTION_TRADING': 'Trading',
    'reports.FUNCTION_TRADING_MODE': 'Trading mode',
    'reports.FUNCTION_TRAILING_STOP': 'Allow trailing stop',
    'reports.FUNCTION_USER_CONNECTION_POLICY': 'User multi-connection policy',
    'reports.FUNCTION_USERSADMIN': 'Users admin',
    'reports.FUNCTION_VALIDATE_SSL_CERTIFICATE': 'Need validation of SSL certificate',
    'reports.FUNCTION_VENDOR_NAME': 'Vendor name',
    'reports.FUNCTION_WATCHLIST': 'WatchList',
    'reports.FUNCTION_ALERT': 'Alert',
    'reports.FUNCTION_ASSETS': 'Assets',
    'reports.Fund Gain History Report': 'Fund gain history report',
    'reports.Fund Investments Report': 'Fund investments report',
    'reports.Fund Investor Action History Report': 'Fund investor action history report',
    'reports.Fund Investor History Report': 'Fund investor history report',
    'reports.Fund Request History Report': 'Fund request history report',
    'reports.Fund Transfer': 'Fund Transfer',
    'reports.fund_type.closed': 'Closed-End',
    'reports.fund_type.open': 'Open-End',
    'reports.fundID': 'Fund ID',
    'reports.Funding': 'Funding',
    'reports.FUNDING_RULES': 'Funding operations visibility',
    'reports.fxintegrate.cert.host.verify': 'fx integrate cert host verify',
    'reports.fxintegrate.cert.root.ca.file': 'fx integrate cert root ca file',
    'reports.fxintegrate.host': 'fx integrate host',
    'reports.fxintegrate.logging.file': 'fx integrate logging file',
    'reports.fxintegrate.logging.maxfilecount': 'fx integrate logging maximum file count',
    'reports.fxintegrate.logging.maxfilesize': 'fx integrate logging maximum file size',
    'reports.fxintegrate.logging.output': 'fx integrate logging output',
    'reports.fxintegrate.logging.priority': 'fx integrate logging priority',
    'reports.fxintegrate.port': 'fx integrate port',
    'reports.fxintegrate.protocol': 'fxintegrate protocol',
    'reports.fxintegrate.usessl': 'fx integrate use ssl',
    'reports.GENERAL_COMMON_RULES': 'General common rules',
    'reports.GENERAL_USER_RULES': 'General rules',
    'reports.GFT': 'GFT',
    'reports.gi.available_margin': 'Margin available',
    'reports.gi.avaliable_funds': 'Available funds',
    'reports.gi.balance': 'Balance',
    'reports.gi.balance_blocked_by_orders': 'Blocked by orders',
    'reports.gi.cur_balance': 'Projected balance',
    'reports.gi.cur_margin': 'Cur. margin, %',
    'reports.gi.fund_capital': 'Fund capital',
    'reports.gi.fund_used': 'Funds used',
    'reports.gi.maintain_margin': 'Maintenance margin',
    'reports.gi.margin_orders': 'Margin for orders',
    'reports.gi.margin_positions': 'Margin for positions',
    'reports.gi.margin_stopout': 'Stop out',
    'reports.gi.margin_stoptrade': 'Stop trade',
    'reports.gi.margin_used': 'Margin used',
    'reports.gi.margin_warning': 'Margin warning',
    'reports.gi.min_margin': 'Min. margin',
    'reports.gi.pos_maintenance_req': 'Pos. maint. req.',
    'reports.gi.risk_lvl': 'Risk level',
    'reports.gi.stock_value': 'Stock',
    'reports.gi.today_released_pnl': 'Realized P/L',
    'reports.gi.withdrawal_available': 'Withdrawal available',
    'reports.Goldman Sachs': 'Goldman Sachs',
    'reports.grosspl': 'Gross P/L',
    'reports.groupid': 'Group ID',
    'reports.groupname': 'Group',
    'reports.GTL': 'GTL',
    'reports.HeartBtInt': 'Heartbeat interval',
    'reports.hedged': 'STP (Straight-Through processing)',
    'reports.helpIndex': 'http://protrader.org/en/knowledge-base/pt3/desktop/account/reports',
    'reports.host': 'Host',
    'reports.HOT_BUTTON': 'Desktop:Hot Button',
    'reports.HotSpot': 'HotSpot',
    'reports.hours': 'hours',
    'reports.HTTP.SERVER.HOST': 'HTTP SERVER HOST',
    'reports.HTTP.SERVER.PORT': 'HTTP SERVER PORT',
    'reports.HTTP.SERVER.PROTOCOL': 'HTTP SERVER PROTOCOL',
    'reports.HTTP.URL.HEARTBEAT': 'HTTP URL HEARTBEAT',
    'reports.HTTP.URL.LOGIN': 'HTTP URL LOGIN',
    'reports.HTTP.URL.LOGOUT': 'HTTP URL LOGOUT',
    'reports.HTTP.URL.PASSWORD.VALIDATION': 'HTTP URL PASSWORD VALIDATION',
    'reports.HTTP.URL.PRELOGIN': 'HTTP URL PRELOGIN',
    'reports.HTTP.URL.QUERY': 'HTTP URL QUERY',
    'reports.HTTP.URL.WORKFLOW': 'HTTP URL WORKFLOW',
    'reports.IB Trades Report': 'IB trades report',
    'reports.IGIndex': 'IGIndex',
    'reports.IndexingQuoteFrequency(sec)': 'Indexing quote frequency (sec)',
    'reports.Information': 'Information',
    'reports.Informational message': 'Informational message',
    'reports.Init margin req.': 'Init. margin req.',
    'reports.Init. margin req.': 'Init. margin req.',
    'reports.INSTALLER_SERVICE_URL': 'Service installer URL',
    'reports.InstrRouteMap': 'Route map',
    'reports.instrtype': 'Symbol group',
    'reports.INSTRTYPES_RULES': 'Symbol groups visibility',
    'reports.instrument': 'Symbol',
    'reports.Instrument': 'Symbol',
    'reports.instrument group': 'Symbol group',
    'reports.Instrument Specification': 'Symbol specification',
    'reports.Instrument Statistic Report': 'Instrument statistic report',
    'reports.Instrument Usage By Signal Report': 'Symbol usage by signal report',
    'reports.Instrument Visibility Report': 'Symbol visibility report',
    'reports.INSTRUMENT_DERIVATIVES': 'Derivatives',
    'reports.INSTRUMENT_GENERAL': 'General',
    'reports.INSTRUMENT_MARGIN': 'Margin',
    'reports.INSTRUMENT_TRADING': 'Trading',
    'reports.INSTRUMENT_TYPE_DERIVATIVES': 'Derivatives',
    'reports.instrument.group': 'Symbol group',
    'reports.instrument.name': 'Symbol name',
    'reports.instrumentgroup': 'Symbol group',
    'reports.InstrumentId': 'Symbol',
    'reports.instrumentname': 'Symbol',
    'reports.instruments': 'Instrument(s)',
    'reports.instrumenttype': 'Symbol type',
    'reports.instrumenttype.duplicatename': 'Symbol group with this name already exists',
    'reports.instrumenttype.inheritanceerror': 'Error in types inheritance',
    'reports.instrumentType.name': 'Symbol group name',
    'reports.instrumentType.superId': 'Symbol super type name',
    'reports.instrumentVisibilityReport': 'Symbol visibility report',
    'reports.Integral': 'Integral',
    'reports.IntegralFIX': 'IntegralFIX',
    'reports.InteractiveData': 'InteractiveData',
    'reports.IntererstOnAvailableFundsAndUnsettledCash': 'Interest on Available funds+Unsettled cash',
    'reports.interest': 'Interest',
    'reports.InterestOnAvailableFunds': 'Interest on Available funds',
    'reports.InterestOnCash': 'Interest on Cash',
    'reports.InterestOnMarginAvailable': 'Interest on Margin available',
    'reports.InterestOnMarginAvailableAndUnsettledCash': 'Interest on Margin available and Unsettled cash',
    'reports.Invalid installer hash.': 'Invalid installer hash.',
    'reports.Invalid installer size.': 'Invalid installer size.',
    'reports.Invalid installer URL.': 'Invalid installer URL.',
    'reports.AccruedDividends': 'Accrued Dividends',
    'reports.DividendsTax': 'Dividends Tax',
    'reports.BalanceDebit': 'Balance Debit',
    'reports.BalanceCredit': 'Balance Credit',
    'reports.Invalid password:New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.': 'New password should have at least 1 upper case character, 1 lower case character, 1 number, 1 special character (e.g. @#$%) and contain 8 characters at least. Please try again.',
    'reports.Invalid price for the order.': 'Invalid price for the order.',
    'reports.Investor Action History Report': 'Investor action history report',
    'reports.investorID': 'Investor ID',
    'reports.InvestorProtectionLevy': 'Investor protection levy',
    'reports.ip': 'IP',
    'reports.IP': 'IP',
    'reports.IQFeed': 'IQFeed',
    'reports.irdnumber': 'IR number',
    'reports.is out of range': 'is out of range',
    'reports.IsOnlyLastPeriod': 'Last period',
    'reports.isPriceStream': 'is price stream',
    'reports.isShowLots': 'Display quantity in lots',
    'reports.IsShowLots': 'Display quantity in lots',
    'reports.IsShowPamm': 'Show fund',
    'reports.issingle': 'Is single',
    'reports.JdbcDriver': 'Jdbc driver',
    'reports.JdbcPassword': 'Jdbc password',
    'reports.JdbcSessionIdDefaultPropertyValue': 'JDBC session ID default property value',
    'reports.JdbcURL': 'JdbcURL',
    'reports.JdbcUser': 'Jdbc user',
    'reports.JMS.INIT.QUEUE': 'JMS INITIAL QUEUE',
    'reports.JMS.SERVER.HOST': 'JMS SERVER HOST',
    'reports.JMS.SERVER.PASSWORD': 'JMS SERVER PASSWORD',
    'reports.JMS.SERVER.PORT': 'JMS SERVER PORT',
    'reports.JMS.SERVER.PROTOCOL': 'JMS SERVER PROTOCOL',
    'reports.JMS.SERVER.USER': 'JMS SERVER USER',
    'reports.JPMorganTrade': 'JPMorganTrade',
    'reports.keystoreAlias': 'Key store Alias',
    'reports.keystoreFile': 'Key store file',
    'reports.KyteFIX': 'KyteFIX',
    'reports.L1port': 'L1port',
    'reports.L2port': 'L2port',
    'reports.LADDER_VIEW': 'Desktop:Super DOM',
    'reports.LADDER_VIEW_OE': 'Desktop:Super DOM OE',
    'reports.Last fill price': 'Last fill price',
    'reports.lastname': 'Last name',
    'reports.Level1Charging': 'Level1Charging',
    'reports.Level1DataCharging': 'Level 1 data charging',
    'reports.Level2Charging': 'Level2Charging',
    'reports.Level2DataCharging': 'Level 2 data charging',
    'reports.Level3Charging': 'Level3Charging',
    'reports.Level3DataCharging': 'Level 3 data charging',
    'reports.Limit accepted by broker': 'Limit order accepted by broker',
    'reports.Limit accepted by STP': 'Limit order accepted by STP',
    'reports.Limit order accepted': 'Limit order accepted',
    'reports.Limit order activated': 'Limit order activated',
    'reports.Limit order cancel request': 'Limit order cancel request',
    'reports.Limit order canceled': 'Limit order cancelled',
    'reports.Limit order created': 'Limit order created',
    'reports.Limit Order Created': 'Limit order created',
    'reports.Limit order created is off market': 'Limit order is off market',
    'reports.Limit order created is unplaced': 'Limit order was unplaced on exchange',
    'reports.Limit order is awaiting market': 'Limit order is awaiting market',
    'reports.Limit Order Modified': 'Limit order modified',
    'reports.Limit order partially reject': 'Limit order partially cancelled',
    'reports.Limit order placing request': 'Limit order placing request',
    'reports.Limit order received by broker': 'Limit order received by broker',
    'reports.Limit order rejected': 'Limit order rejected',
    'reports.Limit Order Removed': 'Limit order removed',
    'reports.Limit order replace request': 'Limit order replace request',
    'reports.Limit order replaced': 'Limit order modified',
    'reports.Limit order restated': 'Limit order restated',
    'reports.Care order replace request': 'Care order replace request',
    'reports.Linked Users': 'Linked users',
    'reports.LMAX': 'LMAX',
    'reports.LMAX FIX': 'LMAX FIX',
    'reports.Local': 'Local',
    'reports.locked': 'Locked',
    'reports.LogFileMaxSize': 'Log file maximum size',
    'reports.login': 'Account(s)',
    'reports.Login': 'Login',
    'reports.LOGIN.USER.NAMESPACE': 'LOGIN USER NAMESPACE',
    'reports.Login/password combination is not valid': 'Login/password combination is not valid',
    'reports.LogonTimeout': 'Logon timeout',
    'reports.Logout': 'Logout',
    'reports.Lots to open': 'Lots to open',
    'reports.lotsize': 'Lot Size',
    'reports.Mail Settings': 'Mail settings',
    'reports.mail.mailsettings': 'Mail settings',
    'reports.mailPattern.ChatDuplicating': 'Chat duplicating',
    'reports.mailPattern.DemoUserNotify': 'Demo-user expiration date notification',
    'reports.mailPattern.DemoUserRegistration': 'Demo-user registration confirmation',
    'reports.mailPattern.DemoUserRemove': 'Demo-user removing confirmation',
    'reports.mailPattern.DepositWithdrawalNotification': 'Deposit/Withdrawal email notification',
    'reports.mailPattern.MarginCall': 'Margin call notification',
    'reports.mailPattern.NewsCreated': 'News were created',
    'reports.mailPattern.OrderPlaced': 'The Stop/Limit order was placed',
    'reports.mailPattern.OrderRemoved': 'The Stop/Limit order was removed',
    'reports.mailPattern.PositionClosed': 'The confirmation of position closing',
    'reports.mailPattern.PositionOpened': 'The confirmation of position placement',
    'reports.mailPattern.SignalDuplicating': 'Signal duplicating',
    'reports.mailPattern.TransactionStatementNotifyBroker': 'Daily report for broker',
    'reports.mailPattern.TransactionStatementNotifyUser': 'Daily report for User',
    'reports.mailPattern.UserEmailConfirmation': 'Confirmation email',
    'reports.mailPattern.UserPasswordRecovery': 'User password recovering',
    'reports.mailPattern.UserPasswordReset': 'User password reset',
    'reports.mailPattern.UserRegistration': 'User registration confirmation',
    'reports.mailPattern.UserUnlocking': 'User unlocking after brute force',
    'reports.Main': 'Main',
    'reports.MAM trades report': 'MAM trades report',
    'reports.MAM_OE': 'Desktop:MAM OE',
    'reports.MamManagementFee': 'MAM management fee',
    'reports.MamPerfomanceFee': 'MamPerfomanceFee',
    'reports.MamPerformanceFee': 'MAM performance fee',
    'reports.Manual order cancel request': 'Manual order cancel request',
    'reports.Manual order placing request': 'Manual order placing request',
    'reports.Manual order replace request': 'Manual order replace request',
    'reports.ManufacturedDividends': 'Manufactured dividends',
    'reports.margin': 'Margin',
    'reports.Margin Call': 'Margin call',
    'reports.Margin Call Reached': 'Margin call reached',
    'reports.Margin Call Warning.': 'Margin call warning.',
    'reports.Margin used': 'Margin used',
    'reports.MARGIN_RULES_ACC': 'Margin rules(account)',
    'reports.MARGIN_RUSLES': 'Margin rules',
    'reports.Margincall': 'Margin call',
    'reports.MarginCallMessage.header.MarginCallReached': 'Margin call reached',
    'reports.MarginCallMessage.header.MarginWarning': 'Margin warning',
    'reports.MarginCallMessage.Message': 'Please deposit your account',
    'reports.Market accepted by broker': 'Market order accepted by broker',
    'reports.Market accepted by STP': 'Market order accepted by STP',
    'reports.Market Close Order Removed': 'Market close order removed',
    'reports.Market data charging report': 'Market data charging report',
    'reports.Market order accepted': 'Market order accepted',
    'reports.Market order activated': 'Market order activated',
    'reports.Market order cancel request': 'Market order cancel request',
    'reports.Market order canceled': 'Market order cancelled',
    'reports.Market order created': 'Market order created',
    'reports.Market Order Created': 'Market order created',
    'reports.Market order created is off market': 'Market order is off market',
    'reports.Market order created is unplaced': 'Market order was unplaced on exchange',
    'reports.Market Order Modified': 'Market order modified',
    'reports.Market order partially reject': 'Market order partially cancelled',
    'reports.Market order placing request': 'Market order placing request',
    'reports.Market order received by broker': 'Market order received by broker',
    'reports.Market order rejected': 'Market order rejected',
    'reports.Market Order Removed': 'Market order removed',
    'reports.Market order replace request': 'Market order replace request',
    'reports.Market order replaced': 'Market order modified',
    'reports.Market order restated': 'Market order restated',
    'reports.Market price': 'Market price',
    'reports.MARKETDEPTH': 'Desktop:Market Depth',
    'reports.MarketFactory Feed': 'MarketFactory Feed',
    'reports.Markup': 'Markup',
    'reports.MATRIX': 'Desktop:Matrix',
    'reports.MATRIX_OE': 'Desktop:Matrix OE',
    'reports.MaxLogsBackups': 'Maximum logs backups',
    'reports.MBT': 'MBT',
    'reports.message': 'Message',
    'reports.Message': 'Message',
    'reports.messageid': 'Message ID',
    'reports.MFGlobal': 'MFGlobal',
    'reports.MillisecondsinTimeStamp': 'Milliseconds in time stamp',
    'reports.MillisecondsInTimeStamp': 'Milliseconds in time stamp',
    'reports.minchange': 'Min. change',
    'reports.MinFeeAdjEquity': 'Min Fee adj equity',
    'reports.Minimum Open Margin': 'Minimum open margin',
    'reports.minutes': 'minutes',
    'reports.MLFIX': 'MLFIX',
    'reports.MOB_CANCEL_ALL_ORDERS': 'MOB:Cancel all orders',
    'reports.MOB_CHART_OE': 'MOB:Chart OE',
    'reports.MOB_CLOSE_ALL_POSITIONS': 'MOB:Close all positions',
    'reports.MOB_MARKETDEPTH': 'MOB:Market Depth',
    'reports.MOB_ORDERS_HOT_BTN': 'MOB:Orders Hot BTN',
    'reports.MOB_OTHER': 'MOB:Other',
    'reports.MOB_POSITIONS_HOT_BTN': 'MOB:Positions Hot BTN',
    'reports.MOB_TAB_CHART_VISUAL_HOT_BTN': 'MOB:Tab Chart Visual Hot BTN',
    'reports.MOB_WL_OE': 'MOB:WL OE',
    'reports.MOB_CHART_VISUAL_TRADING': 'MOB:Chart Visual Trading',
    'reports.MOB_TRADING_IDEAS': 'MOB:Trading Ideas',
    'reports.MOB_INVESTING_CHART_OE': 'MOB:Inv. Chart OE',
    'reports.MOB_INVESTING_WATCHLIST_OPTION_OE': 'MOB:Inv. Watchlist Option OE',
    'reports.MOB_INVESTING_ASSETS_SELL_ASSET': 'MOB:Inv. Sell assets',
    'reports.MOB_INVESTING_POSITIONS_INCREASE': 'MOB:Inv. Positions increase',
    'reports.MOB_INVESTING_CANCEL_ORDERS': 'MOB:Inv. Cancel order',
    'reports.MOB_INVESTING_CLOSE_POSITIONS': 'MOB:Inv. Close position',
    'reports.MOB_INVESTING_TRADING_IDEAS': 'MOB:Inv. Trading Ideas',
    'reports.MOB_INVESTING_OTHER': 'MOB:Inv. Other',
    'reports.Modified': 'Modified',
    'reports.Modifing': 'Modified',
    'reports.Modifyied by': 'Modified by',
    'reports.Modifying': 'Modified',
    'reports.months': 'months',
    'reports.Morgan Stanley': 'Morgan Stanley',
    'reports.MorganStanleyCFD': 'MorganStanleyCFD',
    'reports.Mutual close request': 'Mutual close request',
    'reports.name': 'Name',
    'reports.NAMESPACE': 'NAMESPACE',
    'reports.NEED_EMAIL_CONFIRM_ON_REGISTRATION': 'Account registration confirmation by e-mail',
    'reports.NeoNet': 'NeoNet',
    'reports.Net Open Positions': 'Net open positions',
    'reports.netpl': 'Net P/L',
    'reports.New Back Office Changes Report': 'New Backoffice changes report',
    'reports.New password must differ from several previous passwords.': 'New password must differ from several previous passwords',
    'reports.New Users Report': 'New users report',
    'reports.news.feed.type': 'News feed type',
    'reports.news.reciving': 'News receiving',
    'reports.news.save': 'news save',
    'reports.news.sendEmail': 'news send e-mail',
    'reports.NewsCharging': 'News charging',
    'reports.NewsFeed': 'NewsFeed',
    'reports.newvalue': 'New value',
    'reports.no such position, no such order.': 'no such position, no such order.',
    'reports.Nomura': 'Nomura',
    'reports.Nomura-Lehman': 'Nomura-Lehman',
    'reports.None': 'None',
    'reports.Not enoght margin to create Order': 'Not enough margin to create Order',
    'reports.Not enough margin to open order': 'Not enough margin to create order',
    'reports.Not enough Margin to open position': 'Not enough margin to open position',
    'reports.Not enough offer': 'not enough offer',
    'reports.Not Filled Orders Report': 'Not filled orders report',
    'reports.Not Successful': 'Not successful',
    'reports.notShowMam': "Don't show MAM",
    'reports.NotSuccessful': 'Not successful',
    'reports.number': 'number',
    'reports.objectid': 'Object ID',
    'reports.ObjectId': 'Order ID',
    'reports.ObjectID': 'Object ID',
    'reports.OCO accepted by broker': 'OCO order accepted by broker',
    'reports.OCO accepted by STP': 'OCO order accepted by STP',
    'reports.OCO order activated': 'OCO order activated',
    'reports.OCO order cancel request': 'OCO order cancel request',
    'reports.OCO order canceled': 'OCO order cancelled',
    'reports.OCO order created': 'OCO order created',
    'reports.OCO Order Created': 'OCO order created',
    'reports.OCO order created is off market': 'OCO order is off market',
    'reports.OCO order created is unplaced': 'OCO order was unplaced on exchange',
    'reports.OCO order partially reject': 'OCO order partially cancelled',
    'reports.OCO order placing request': 'OCO order placing request',
    'reports.OCO order received by broker': 'OCO order received by broker',
    'reports.OCO order rejected': 'OCO order rejected',
    'reports.OCO order replace request': 'OCO order replace request',
    'reports.OCO order replaced': 'OCO order modified',
    'reports.OCO order restated': 'OCO order restated',
    'reports.OE': 'Desktop:OE',
    'reports.Off market': 'Off market',
    'reports.ONE_MARGIN_FOR_USER_ACC': 'One margin for users account',
    'reports.Open': 'Open',
    'reports.Open Buy': 'Open Buy',
    'reports.Open date': 'Open date',
    'reports.Open lots': 'Open lots',
    'reports.Open order is forbidden by trade session': 'Open order is forbidden by trade session',
    'reports.Open order type': 'Open order type',
    'reports.Open Positions By Signal Report': 'Open positions by signal report',
    'reports.Open Positions Report': 'Open positions report',
    'reports.Open price': 'Open price',
    'reports.Open Sell': 'Open Sell',
    'reports.OPEN_SPOT_ON_OPTION_EXP': 'Open Spot position on option expire',
    'reports.openclosebuysell': 'Operation type',
    'reports.opengrosspnl': 'Open gross P/L',
    'reports.OpenOptionPremium': 'Open option premium',
    'reports.openorderid': 'Open order ID',
    'reports.openprice': 'Open price',
    'reports.opentime': 'Open time',
    'reports.operation': 'Operation',
    'reports.Operation': 'Operation',
    'reports.Operation Id': 'Operation ID',
    'reports.Operation type': 'Operation type',
    'reports.Operation Type': 'Operation type',
    'reports.operation.not_enough_margin': 'Not enough margin',
    'reports.operation.wrong_length': 'Too big quantity',
    'reports.operation.wrong_length;': 'Too big quantity',
    'reports.operation.wrong_sum': 'Wrong sum',
    'reports.OperationID': 'Operation ID',
    'reports.OperationInDisconnectedMode': "This operation isn't available in disconnected mode!",
    'reports.operationtype': 'Operation type',
    'reports.OPTION_MASTER': 'Desktop:Option Master',
    'reports.option_value': 'Option value',
    'reports.OrcFIX': 'OrcFIX',
    'reports.order': 'Order',
    'reports.Order': 'Order',
    'reports.Order Book History': 'Order book history',
    'reports.Order Book Report': 'Order book report',
    'reports.Order cancel is forbidden by trade session': 'Order cancel is forbidden by trade session',
    'reports.Order cancel request': 'Order cancel request',
    'reports.Order canceled successfully': 'Order cancelled successfully',
    'reports.Order cannot be created since order amount is forbidden.': 'Order cannot be created since order amount is forbidden.',
    'reports.Order cannot be created since route is not active.': 'Order cannot be created since route is not active',
    'reports.Order cannot be created, because position is in executing': 'Order cannot be created, because position is in executing',
    'reports.Order cannot be created, because route is not active': 'Order cannot be created, because route is not active',
    "reports.Order cannot be created, because short positions are forbidden. You don't have enough rights.": "Order cannot be created, because short positions are forbidden. You don't have enough rights.",
    'reports.Order cannot be created, because short positions are forbidden. You donґt have enough rights.': "Order cannot be created, because short positions are forbidden. You don't have enough rights.",
    'reports.Order cannot be created, because short positions are forbidden.Or yor already have Limit/Stop orders for cover all your positions.': 'Order cannot be created, because short positions are forbidden.Or you already have Limit/Stop orders for cover all your positions.',
    'reports.Order cannot be placed in non-trading day. Please, check the trading calendar.': 'Order cannot be placed in non-trading day. Please, check the trading calendar.',
    'reports.Order groupID': 'Order groupID',
    'reports.Order History Report': 'Order history report',
    'reports.Order modification is forbidden by trade session': 'Order modification is forbidden by trade session',
    'reports.Order number': 'Order ID',
    'reports.Order placing request': 'Order placing request',
    'reports.Order price': 'Order price',
    'reports.Order Price': 'Order price',
    'reports.Order received by STP': 'Order received by STP',
    'reports.Order replace request': 'Order replace request',
    'reports.Order replacing confirmed': 'Order replacement confirmed',
    'reports.Order TIF is forbidden by trade session': 'Order validity is forbidden by trade session',
    'reports.Order type': 'Order type',
    'reports.Order type is forbidden by trade session': 'Order type is forbidden by trade session',
    'reports.Order was rejected due to invalid price!': 'Order was rejected due to invalid price!',
    'reports.order_book_account': 'Account',
    'reports.order_book_datetime': 'Date',
    'reports.order_book_event': 'Event',
    'reports.order_book_login': 'Login',
    'reports.order_book_modified_by': 'Modified by user',
    'reports.order_book_orderid': 'Order ID',
    'reports.order_book_price': 'Price',
    'reports.order_book_quantity': 'Quantity',
    'reports.order_book_route': 'Route',
    'reports.order_book_side': 'Side',
    'reports.order_book_stop_price': 'Stop price',
    'reports.order_book_symbol': 'Symbol',
    'reports.order_book_symbol_type': 'Symb. type',
    'reports.order_book_tif': 'Validity',
    'reports.order_book_type': 'Type',
    'reports.orderCreate': 'Order create',
    'reports.orderExecute': 'Order execute',
    'reports.OrderExternalConfirm': 'Order external confirm',
    'reports.orderid': 'Order ID',
    'reports.orderId': 'Order ID',
    'reports.OrderId': 'Order ID',
    'reports.orderModify': 'Order modify',
    'reports.orderMutural': 'order mutual',
    'reports.orderRemoved': 'Order removed',
    'reports.ORDERS_PANEL_HOT_BTN': 'Desktop:Orders Panel Hot BTN',
    'reports.ordertype': 'Order type',
    'reports.Oreder Number': 'Order ID',
    'reports.originalvalue': 'Original value',
    'reports.OTHER': 'Desktop:Other',
    'reports.other_account_operations': 'Other account operations',
    'reports.Out of session trading is forbidden': 'Out of session trading is forbidden',
    'reports.Overnight trading is forbidden for account': 'Overnight trading is forbidden for account',
    'reports.PAMM account can not be removed during investment period.': 'Fund cannot be removed during investment period',
    'reports.PAMM join': 'Fund join',
    'reports.pamm.OrderByPnL.max_loss': 'Biggest  loss',
    'reports.pamm.OrderByPnL.max_profit': 'Biggest  profit',
    'reports.pamm.OrderByPnL.min_loss': 'Smallest loss',
    'reports.pamm.OrderByPnL.min_profit': 'Smallest profit',
    'reports.pamm.OrderByPnL.none': 'None',
    'reports.pamm.OrderBySize.max': 'Biggest position',
    'reports.pamm.OrderBySize.min': 'Smallest position',
    'reports.pamm.OrderByTime.first': 'First open',
    'reports.pamm.OrderByTime.last': 'Last open',
    'reports.pammState.0': 'New',
    'reports.pammState.1': 'Investment',
    'reports.pammState.2': 'End of investment',
    'reports.pammState.3': 'Сlearing',
    'reports.pammState.4': 'Wait for investors',
    'reports.pammState.6': 'Start of investment',
    'reports.pammState.clearing': 'Сlearing',
    'reports.pammState.confirmed': 'confirmed',
    'reports.pammState.end_of_investment': 'End of investment',
    'reports.pammState.investment': 'Investment',
    'reports.pammState.Investment': 'Investment',
    'reports.pammState.new': 'New',
    'reports.pammState.pending': 'pending',
    'reports.pammState.start_of_investment': 'Start of investment',
    'reports.pammState.wait_for_investors': 'Wait for investors',
    'reports.PAMMTransfer': 'Fund transfer',
    'reports.Partially filled': 'Partially filled',
    'reports.Partially filled close Limit order': 'Close limit order partially filled',
    'reports.Partially filled Close Limit Order': 'Close limit order partially filled',
    'reports.Partially filled close Limit order (Mutual)': 'Close limit order partially filled (mutually)',
    'reports.Partially filled close Market order': 'Close market order partially filled',
    'reports.Partially filled Close Market Order': 'Close Market order partially filled',
    'reports.Partially filled close Market order (Mutual)': 'Close market order partially filled (mutually)',
    'reports.Partially filled close OCO order': 'Close OCO order partially filled',
    'reports.Partially filled close OCO order (Mutual)': 'Close OCO order partially filled (mutually)',
    'reports.Partially filled close SL order': 'Close SL order partially filled',
    'reports.Partially filled close SL order (Mutual)': 'Close SL order partially filled (mutually)',
    'reports.Partially filled close SL tr. stop order': 'Close SL Tr. stop order partially filled',
    'reports.Partially filled close SL tr. stop order (Mutual)': 'Close SL Tr. stop order partially filled (mutually)',
    'reports.Partially filled close Stop Limit order': 'Close stop limit order partially filled',
    'reports.Partially filled close Stop Limit order (Mutual)': 'Close stop limit order partially filled (mutually)',
    'reports.Partially filled close Stop order': 'Close stop order partially filled',
    'reports.Partially filled Close Stop Order': 'Close stop order partially filled',
    'reports.Partially filled close Stop order (Mutual)': 'Close stop order partially filled (mutually)',
    'reports.Partially filled close TP order': 'Close TP order partially filled',
    'reports.Partially filled close TP order (Mutual)': 'Close TP order partially filled (mutually)',
    'reports.Partially filled close Tr. stop order': 'Close Tr. stop order partially filled',
    'reports.Partially filled close Tr. stop order (Mutual)': 'Close Tr. stop order partially filled (mutually)',
    'reports.Partially filled Limit order': 'Limit order partially filled',
    'reports.Partially filled Limit order (Mutual)': 'Limit order partially filled (mutually)',
    'reports.Partially filled Market order': 'Market order partially filled',
    'reports.Partially filled Market Order': 'Market order partially filled',
    'reports.Partially filled Market order (Mutual)': 'Market order partially filled (mutually)',
    'reports.Partially filled OCO order': 'OCO order partially filled',
    'reports.Partially filled OCO order (Mutual)': 'OCO order partially filled (mutually)',
    'reports.Partially filled SL order': 'SL order partially filled',
    'reports.Partially filled SL order (Mutual)': 'SL order partially filled (mutually)',
    'reports.Partially filled SL tr. stop order': 'SL Tr. stop order partially filled',
    'reports.Partially filled SL tr. stop order (Mutual)': 'SL Tr. stop order partially filled (mutually)',
    'reports.Partially filled Stop Limit order': 'Stop limit order partially filled',
    'reports.Partially filled Stop Limit order (Mutual)': 'Stop limit order partially filled (mutually)',
    'reports.Partially filled Stop order': 'Stop order partially filled',
    'reports.Partially filled Stop order (Mutual)': 'Stop order partially filled (mutually)',
    'reports.Partially filled TP order': 'TP order partially filled',
    'reports.Partially filled TP order (Mutual)': 'TP order partially filled (mutually)',
    'reports.Partially filled Tr. stop order': 'Tr. stop order partially filled',
    'reports.Partially filled Tr. stop order (Mutual)': 'Tr. stop order partially filled (mutually)',
    'reports.Partially filled Traling Stop Order': 'Trailing stop order partially filled',
    'reports.Password required with level - 1': 'Invalid password: The new password should have at least 1 upper case character, 1 lower case character, 1 number. Please try again',
    'reports.Password required with level - 2': 'Invalid password: The new password should have at least 1 upper case character, 1 lower case character, 1 number and have length with 8 charters at least. Please try again',
    'reports.Password required with level - 3': 'Invalid password: The new password should have at least 1 upper case character, 1 lower case character, 1 number and have length with 8 charters at least and have one special symbol at least. Please try again',
    'reports.PatsystemsFIXFeed': 'PatsystemsFIXFeed',
    'reports.Pending': 'Pending',
    'reports.pendingorderscount': 'Pending orders count',
    'reports.PeresysFIX': 'PeresysFIX',
    'reports.Perfomance By Signal Report': 'Performance by signal report',
    'reports.Perform': 'Perform',
    'reports.Performance history report': 'Performance history report',
    'reports.PFixFeed': 'PFixFeed',
    'reports.phone': 'Phone',
    'reports.phonepassword': 'Phone password',
    'reports.pin': 'Pin',
    'reports.pl_unrealized': 'P/L unrealized',
    'reports.Placed': 'Placed',
    'reports.Please deposit on your account.': 'Please deposit your account',
    'reports.pnl': 'P/L',
    'reports.port': 'port',
    'reports.posId': 'Position ID',
    'reports.Position': 'Position',
    'reports.PositionReverseRequest': 'Reverse position request',
    'reports.Position close request': 'Position close request',
    'reports.Position Closed': 'Position closed',
    'reports.Position Closed (Mutual)': 'Position closed (mutually)',
    'reports.Position Closed (SL)': 'Position closed (SL)',
    'reports.Position Closed (TP)': 'Position closed (TP)',
    'reports.Position closed successfully': 'Position closed successfully',
    'reports.POSITION MODIFIED': 'Position modified',
    'reports.Position modify request': 'Position modify request',
    'reports.Position number': 'Position ID',
    'reports.positionOpened': 'Position opened',
    'reports.Position Opened (Limit Order)': 'Position opened (Limit order)',
    'reports.Position Types Ratio By Instrument': 'Position types ratio by symbol',
    'reports.Position: {1} closed successfully': 'Position: {1} closed successfully',
    'reports.positionamount': 'Position amount',
    'reports.positionid': 'Position ID',
    'reports.positionId': 'Position ID',
    'reports.PositionId': 'Position ID',
    'reports.Positions History Report': 'Positions history report',
    'reports.Positions On Chosen Moment Report': 'Positions on chosen moment report',
    'reports.POSITIONS_PANEL_HOT_BTN': 'Desktop:Positions Panel Hot BTN',
    'reports.positionstatus': 'Position status',
    'reports.price': 'Price',
    'reports.Price': 'Price',
    'reports.Price is too close to current Ask price': 'Price is too close to current Ask price',
    'reports.Price is too close to current Bid price': 'Price is too close to current Bid price',
    'reports.priceExchange': 'Price exchange',
    'reports.priceExternal': 'Price external',
    'reports.product type': 'Product type',
    'reports.productType': 'Product type',
    'reports.PROFILE_LANGUAGE': 'Profile language',
    'reports.profit': 'Profit',
    'reports.Projected balance': 'Projected balance',
    'reports.protocol': 'Protocol',
    'reports.ProTrader': 'Protrader',
    'reports.ProTraderFIX': 'ProTraderFIX',
    'reports.Purchase And Sales Report': 'Purchase and sales report',
    'reports.purchaseprice': 'Exposure',
    'reports.quantity': 'Quantity',
    'reports.QueueMaxSize': 'Queue maximum size',
    'reports.quicktable.noData': 'There is no data for displaying. Please select the needed report criteria to get the required information.',
    'reports.Quote is too old': 'Quote is too old',
    'reports.QuoteDelay(sec)': 'Quote delay (sec)',
    'reports.quoteticker': 'Quote ticker',
    'reports.raor_administrator': 'Broker',
    'reports.raor_approvetime': 'Approve time',
    'reports.raor_balance': 'From account balance',
    'reports.raor_basis': 'Comments',
    'reports.raor_commission_percent': 'Commission in percent',
    'reports.raor_counterbalance': 'To account balance',
    'reports.raor_countersum': 'To account sum',
    'reports.raor_crossprice': 'Cross price',
    'reports.raor_fromaccount': 'From account',
    'reports.raor_operationtime': 'Operation time',
    'reports.raor_operationtype': 'Operation type',
    'reports.raor_requestid': 'Operation ID',
    'reports.raor_state': 'Approval status',
    'reports.raor_sum': 'From account sum',
    'reports.raor_sum_without_commission': 'Sum without commission',
    'reports.raor_toaccount': 'To account',
    'reports.rate': 'rate',
    'reports.Rates Report': 'Rates report',
    'reports.Realised profit/loss': 'Realized Profit/Loss',
    'reports.Reason for rejection: Trading disabled by risk rules. .': 'Reason for rejection: Trading disabled by risk rules. .',
    'reports.receiveBestBidOfferOnly': 'Receive best Bid offer only',
    'reports.recipient': 'Recipient',
    'reports.ReconnectInterval': 'Reconnect interval',
    'reports.refreshInterval': 'refresh interval',
    'reports.RefreshonLogon': 'Refresh on logon',
    'reports.RefreshOnLogon': 'Refresh on logon',
    'reports.Refused': 'Refused',
    'reports.Refused Amount': 'Quantity cancelled',
    'reports.Rejected': 'Rejected',
    'reports.remoteid': 'Remote ID',
    'reports.Remove SL for': 'Remove SL for',
    'reports.Removed Orders Report': 'Removed orders report',
    'reports.removedtime': 'Removed time',
    'reports.removeliquidityrebate': 'Remove liquidity rebate',
    'reports.Replace request received by STP': 'Replacement request received by STP',
    'reports.REPORT_RULES': 'Report visibility',
    'reports.report.currency': 'Currency',
    'reports.report.route': 'Route',
    'reports.reportAccountSummaryReport': 'Account statement report',
    'reports.reportAllActiveOrdersReport': 'Active orders report',
    'reports.reportAllNotActiveOrdersReport': 'Filled orders report',
    'reports.reportAllRemovedOrdersReport': 'Removed orders report',
    'reports.reportBalanceByInstrument': 'Balance by symbol report',
    'reports.reportBalanceReport': 'Balance report',
    'reports.reportFormalDialogReport': 'Formal dialog report',
    'reports.reportInformalDialogReport': 'Chat history report',
    'reports.reportNewUsersReport': 'New users report',
    'reports.reportOpenedPositionReport': 'Opened positions report',
    'reports.reportPerfomanceReport': 'Performance report',
    'reports.reportPurchaseAndSalesReport': 'Purchase and sales report',
    'reports.reportSummaryNewReport': 'Summary report',
    'reports.reportSummaryReport': 'Balance summary report',
    'reports.reportTradeOperationReport': 'Trade operation report',
    'reports.reportTradesReport': 'Trades report',
    'reports.reportUsersReport': 'Users report',
    'reports.Request Account Operation Report': 'Request account operation report',
    'reports.Request is not allowed for this account': 'Request is not allowed for this account',
    'reports.Request not supported': 'Request not supported',
    'reports.RequestAccountOperationReport': 'Request account operation report',
    'reports.Required Balance': 'Required balance',
    'reports.REQUIRED_STRONG_PASSWORDS': 'Strong Password is required',
    'reports.ResetOnDisconnect': 'Reset on disconnect',
    'reports.ResetOnLogon': 'Reset on logon',
    'reports.ResetOnLogout': 'Reset on logout',
    'reports.Restated': 'Restated',
    'reports.Reuters': 'Reuters',
    'reports.Revenue plan': 'Revenue plan',
    'reports.REVENUE_PLAN_GENERAL': 'General',
    'reports.revenue.name': 'Revenue plan name',
    'reports.revenue.speccrossinstrument': 'Spec. cross symbol',
    'reports.revenue.speccurrency': 'Spec. currency',
    'reports.Reverse': 'Reverse',
    'reports.reward_type.capital_percent': 'Capital, %',
    'reports.reward_type.fixed': 'Fixed',
    'reports.reward_type.fixed_and_percent': 'Fixed + Inv. period growth, %',
    'reports.reward_type.no': 'No',
    'reports.reward_type.percent': 'Inv. period growth, %',
    'reports.role': 'Role',
    'reports.Rollback': 'Rollback',
    'reports.Rollback position': 'Rollback position',
    'reports.Rollback position request': 'Rollback position request',
    'reports.route': 'Route',
    'reports.Route': 'Route',
    'reports.Route Trading Statistics Report': 'Route Trading Statistics Report',
    'reports.Route Visibility': 'Route visibility report',
    'reports.Route Visibility Report': 'Route visibility report',
    'reports.RouteInstrMap': 'symbol map',
    'reports.routename': 'Route',
    'reports.routes': 'Routes',
    'reports.routes.account': 'Linked feed account',
    'reports.routes.allow_day': 'DAY',
    'reports.routes.allow_foc': 'FOK',
    'reports.routes.allow_gtc': 'GTC',
    'reports.routes.allow_ioc': 'IOC',
    'reports.routes.allow_market': 'Market',
    'reports.routes.allow_moc': 'Allow MOC',
    'reports.routes.allow_moo': 'Allow MOO',
    'reports.routes.allow_stop': 'Stop',
    'reports.routes.allow_stoplimit': 'Limit',
    'reports.routes.allow_stoptolimit': 'Stop limit',
    'reports.routes.route_comment': 'Comment',
    'reports.routes.route_external_feed': 'Liquidity provider',
    'reports.routes.route_isactive': 'Is Active',
    'reports.routes.route_isinfo': 'Is Info',
    'reports.routes.route_istrade': 'Is Trade',
    'reports.routes.route_login': 'Login',
    'reports.routes.route_name': 'Name',
    'reports.routes.route_password': 'Password',
    'reports.routes.route_saveQuotes': 'Save separate history',
    'reports.routes.route_status': 'Status',
    'reports.ROUTESVIS_RULES': 'Routes visibility',
    'reports.RssNews': 'RSSNews',
    'reports.RTS': 'RTS',
    'reports.rules.error.restriction.max': 'Number must be less',
    'reports.rules.error.restriction.min': 'Number must be greater',
    'reports.SAVED_ORDERS': 'Desktop:Saved orders',
    'reports.saveLogs': 'Save logs',
    'reports.saveNews': 'Save news',
    'reports.Saxo Bank': 'Saxo Bank',
    'reports.SCALPER': 'Desktop:Scalper',
    'reports.Scheduler Log Report': 'Scheduler log report',
    'reports.scheduler.accountoperation_archive': 'Archive account operations',
    'reports.scheduler.dailyreport': 'Send report',
    'reports.scheduler.dividentsservice': 'Run dividends on positions',
    'reports.scheduler.lastexcecuted': 'Executed at',
    'reports.scheduler.modifyRuleTask': 'Modifying task',
    'reports.scheduler.movepositiontask': 'Move position task',
    'reports.scheduler.openclosetradingfor': 'Open/Close trading',
    'reports.scheduler.opentradingfor': 'Open trading',
    'reports.scheduler.parameter.backup': 'Backup level',
    'reports.scheduler.parameter.calculateByClosePrice': 'Use current closing price',
    'reports.scheduler.parameter.calculateStrategy': 'Calculating type',
    'reports.scheduler.parameter.coeff1': 'Part 1',
    'reports.scheduler.parameter.coeff2': 'Part 2',
    'reports.scheduler.parameter.comment': 'Account operation comment',
    'reports.scheduler.parameter.counteraccount': 'Counter account',
    'reports.scheduler.parameter.days_before_exp': 'Days before expiration',
    'reports.scheduler.parameter.divident': 'Dividends quantity',
    'reports.scheduler.parameter.dividentmode': 'Dividends type',
    'reports.scheduler.parameter.email': 'E-mails (separated by comma)',
    'reports.scheduler.parameter.inheritUserGroups': 'Include sub groups',
    'reports.scheduler.parameter.instrtype': 'Symbol group',
    'reports.scheduler.parameter.instrument': 'Symbol',
    'reports.scheduler.parameter.isopen': 'Open/Close trading',
    'reports.scheduler.parameter.isupdateall': 'Update all instruments in group',
    'reports.scheduler.parameter.margincoeff': 'Margin coefficient (time based)',
    'reports.scheduler.parameter.max': 'Maximum (%)',
    'reports.scheduler.parameter.message': 'Message',
    'reports.scheduler.parameter.min': 'Minimum (%)',
    'reports.scheduler.parameter.operations': 'Operation type',
    'reports.scheduler.parameter.owner': 'Object',
    'reports.scheduler.parameter.ownerType': 'Object type',
    'reports.scheduler.parameter.putinaleerts': 'Put into Alerts',
    'reports.scheduler.parameter.reconciliation.ClientId': 'Client ID for reconciliation',
    'reports.scheduler.parameter.reconciliation.Direction': 'Direction',
    'reports.scheduler.parameter.reconciliation.FromDate': 'Start reconciliation from date',
    'reports.scheduler.parameter.reconciliation.Route': 'Route',
    'reports.scheduler.parameter.reconciliation.SendItemName': 'Sending file name',
    'reports.scheduler.parameter.reconciliation.Storage': 'Storage type',
    'reports.scheduler.parameter.reconciliation.Transport': 'Transport type',
    'reports.scheduler.parameter.reconciliation.transportDestination': 'Destination',
    'reports.scheduler.parameter.reconciliation.transportLogin': 'Authentication login',
    'reports.scheduler.parameter.reconciliation.transportPassword': 'Authentication password',
    'reports.scheduler.parameter.reconciliation.Type': 'Reconciliation type',
    'reports.scheduler.parameter.reports': 'Select report',
    'reports.scheduler.parameter.revenueplan': 'Select revenue plan',
    'reports.scheduler.parameter.routes': 'Each active route must be',
    'reports.scheduler.parameter.routesName': 'Include routes',
    'reports.scheduler.parameter.rule': 'Rule',
    'reports.scheduler.parameter.ruleType': 'Rule type',
    'reports.scheduler.parameter.ruleView': 'Value',
    'reports.scheduler.parameter.sum': 'Quantity in system currency',
    'reports.scheduler.parameter.swapCoefKey': 'Swap coefficient',
    'reports.scheduler.parameter.userGroup': 'User group',
    'reports.scheduler.parameter.usergroups': 'Select users group',
    'reports.scheduler.removedo': 'Run day order removing service',
    'reports.scheduler.rolloverservice': 'Run futures Roll-Over',
    'reports.scheduler.runinterestservice': 'Run interest on balance service',
    'reports.scheduler.runsignalsubscribtion': 'Run daily charge commission for signal',
    'reports.scheduler.runswaptask': 'Run swap',
    'reports.scheduler.splitinstrument': 'Split symbol',
    'reports.scheduler.type.accountoperation': 'Account operation',
    'reports.scheduler.type.backup': 'Create backup',
    'reports.scheduler.type.beginbalance': 'Begin balance',
    'reports.scheduler.type.broadcastmessage': 'Online broadcast message',
    'reports.scheduler.type.changemargincoeff': 'Margin coefficient (time based)',
    'reports.scheduler.type.changerevenue': 'Change revenue plan',
    'reports.scheduler.type.closeoptions': 'Hide expired symbols',
    'reports.scheduler.type.deletedemousers': 'Delete expr. demo users',
    'reports.scheduler.type.deletequotes': 'Delete old quotes',
    'reports.scheduler.type.fixswapplan': 'Calculate fixed swap plan',
    'reports.scheduler.type.futuresrollover': 'Run futures roll-over',
    'reports.scheduler.type.insertlastquotestask': 'Insert last quotes',
    'reports.scheduler.type.lockbybroker': 'Lock by broker',
    'reports.scheduler.type.message': 'Message',
    'reports.scheduler.type.modifyobject': 'Modify object',
    'reports.scheduler.type.pamm': 'Fund task',
    'reports.scheduler.type.pendingpayment': 'Pending payment',
    'reports.scheduler.type.reconciliation': 'Reconciliation task',
    'reports.scheduler.type.reconnect_routes': 'Active routes start/stop',
    'reports.scheduler.type.subscribe': 'Get subscribe payments',
    'reports.scheduler.type.unknown': 'Unknown type',
    'reports.scheduler.type.updatespikefilter': 'Update spike filter',
    'reports.scheduler.type.updateuserstradinginfo': 'Update users trade statistic info',
    'reports.ScreenLogEvents': 'Screen log events',
    'reports.ScreenLogShowHeartBeats': 'Screen log show heartbeats',
    'reports.ScreenLogShowIncoming': 'Screen log show incoming',
    'reports.ScreenLogShowOutgoing': 'Screen log show outgoing',
    'reports.Script': 'Script',
    'reports.SDK': 'Desktop:SDK',
    'reports.seconds': 'seconds',
    'reports.SecuritiesTransferTax': 'Securities transfer tax',
    'reports.Self trading is not allowed.': 'Self trading is not allowed.',
    'reports.Sell': 'Sell',
    'reports.sell trade amount': 'Sell trade amount',
    'reports.sell trades': 'Sell trades',
    'reports.Send from': 'Sent from',
    'reports.Send_Rej': 'Reject',
    'reports.sender': 'Sender',
    'reports.SenderCompID': 'Sender Computer ID',
    'reports.senderip': 'Sender IP',
    'reports.SendResetSeqNumFlag': 'Send reset sequent number flag',
    'reports.sendTag200': 'send Tag200',
    'reports.server': 'server',
    'reports.server_url': 'Server URL',
    'reports.SERVER.AUTO.RECONNECT': 'SERVER AUTO RECONNECT',
    'reports.SERVER.POLLING.INTERVAL': 'SERVER POLLING INTERVAL',
    'reports.serviceName': 'Service name',
    'reports.sessionid': 'Session ID',
    'reports.sessiontype': 'Session type',
    'reports.SETTINGS_FILTER': 'Filters',
    'reports.SETTINGS_GENERAL': 'General',
    'reports.SETTINGS_LOCKING': 'Locking',
    'reports.SETTINGS_QUOTE_CACHE_SIZE': 'Quote cache size setting',
    'reports.SETTINGS_QUOTE_SAVE': 'Quote save setting',
    'reports.SETTINGS_QUOTES': 'Quotes',
    'reports.SETTINGS_REGISTRATION': 'Registration',
    'reports.SETTINGS_TRADING': 'Trading',
    'reports.SettleDate': 'Expiration date',
    'reports.SettleDateForward': 'Value date',
    'reports.SettlementFee': 'Settlement fee',
    'reports.Sharia Operations Report': 'Sharia operations report',
    'reports.signedrequest': 'Signed request',
    'reports.sizeForQuoteSubscription': 'size for quote subscription',
    'reports.SL': 'Stop loss',
    'reports.SL accepted by broker': 'SL order accepted by broker',
    'reports.SL accepted by STP': 'SL order accepted by STP',
    'reports.SL order accepted': 'SL order accepted',
    'reports.SL order cancel request': 'SL order cancel request',
    'reports.SL order canceled': 'SL order cancelled',
    'reports.SL order created': 'SL order created',
    'reports.SL Order Created': 'SL order created',
    'reports.SL order created is off market': 'SL order is off market',
    'reports.SL order created is unplaced': 'SL order was unplaced on exchange',
    'reports.SL Order Modified': 'SL order modified',
    'reports.SL order partially reject': 'SL order partially cancelled',
    'reports.SL order placing request': 'SL order placing request',
    'reports.SL order received by broker': 'SL order received by broker',
    'reports.SL order rejected': 'SL order rejected',
    'reports.SL Order Removed': 'SL order removed',
    'reports.SL order replace request': 'SL order replace request',
    'reports.SL order replaced': 'SL order modified',
    'reports.SL price': 'SL price',
    'reports.SL tr. stop': 'Stop loss tr. stop',
    'reports.SL tr. stop accepted by broker': 'SL Tr. stop order accepted by broker',
    'reports.SL tr. stop accepted by STP': 'SL Tr. stop order accepted by STP',
    'reports.SL tr. stop order accepted': 'SL Tr. stop order accepted',
    'reports.SL tr. stop order canceled': 'SL Tr. stop order cancelled',
    'reports.SL tr. stop order created': 'SL Tr. stop order created',
    'reports.SL tr. stop order created is off market': 'SL Tr. stop order is off market',
    'reports.SL tr. stop order created is unplaced': 'SL Tr. stop order was unplaced on exchange',
    'reports.SL tr. stop order partially reject': 'SL Tr. stop order partially cancelled',
    'reports.SL tr. stop order received by broker': 'SL Tr. stop order received by broker',
    'reports.SL tr. stop order rejected': 'SL Tr. stop order rejected',
    'reports.SL tr. stop order replaced': 'SL Tr. stop order modified',
    'reports.SmartRoute(aggregator)': 'Splitting emulator',
    'reports.Sms generation error': 'Sms generation error',
    'reports.SMTP server required a secure connection, or identity of the client has not been established.': 'SMTP server required a secure connection, or identity of the client has not been established.',
    'reports.SOAP API methods are not available for execution for this user': 'SOAP API methods are not available for execution for this user',
    'reports.SocketConnectHost': 'Socket connectHost',
    'reports.SocketConnectPort': 'Socket connects port',
    'reports.SocketKeyStore': 'Socket key store',
    'reports.SocketKeyStorePassword': 'Socket key store password',
    'reports.SocketUseSSL': 'Socket use SSL',
    'reports.SplitOrders': 'Split orders',
    'reports.spread': 'Spread',
    'reports.Spread Amount Report': 'Spread amount report',
    'reports.spreadpnl': 'Spread P/L',
    'reports.Start receiving data from server...': 'Start receiving data from server...',
    'reports.StartDay': 'Start day',
    'reports.StartTime': 'Start time',
    'reports.STATIONS_RULES': 'Stations',
    'reports.Statistic Report': 'Statistic report',
    'reports.STATUS_BLOCKED': 'Blocked',
    'reports.stock_req': 'Stock orders req',
    'reports.stock_value': 'Stock value',
    'reports.StockOperation': 'Stock operation',
    'reports.stocks_liquidity': 'Stocks liquidity ',
    'reports.Stop accepted by broker': 'Stop order accepted by broker',
    'reports.Stop accepted by STP': 'Stop order accepted by STP',
    'reports.Stop Limit accepted by broker': 'Stop limit order accepted by broker',
    'reports.Stop Limit accepted by STP': 'Stop limit order accepted by STP',
    'reports.Stop Limit order accepted': 'Stop limit order accepted',
    'reports.Stop Limit order activated': 'Stop limit order activated',
    'reports.Stop limit order cancel request': 'Stop limit order cancel request',
    'reports.Stop limit order canceled': 'Stop limit order cancelled',
    'reports.Stop Limit order canceled': 'Stop limit order cancelled',
    'reports.Stop Limit order created': 'Stop limit order created',
    'reports.Stop Limit Order Created': 'Stop limit order created',
    'reports.Stop Limit order created is off market': 'Stop limit order is off market',
    'reports.Stop Limit order created is unplaced': 'Stop limit order was unplaced on exchange',
    'reports.Stop limit order is awaiting market': 'Stop limit order is awaiting market',
    'reports.Stop Limit Order Modified': 'Stop limit order modified',
    'reports.Stop Limit order partially reject': 'Stop limit order partially cancelled',
    'reports.Stop limit order placing request': 'Stop limit order placing request',
    'reports.Stop Limit order received by broker': 'Stop limit order received by broker',
    'reports.Stop Limit order rejected': 'Stop limit order rejected',
    'reports.Stop Limit Order Removed': 'Stop limit order removed',
    'reports.Stop limit order replace request': 'Stop limit order replace request',
    'reports.Stop limit order replaced': 'Stop limit order modified',
    'reports.Stop Limit order replaced': 'Stop limit order modified',
    'reports.Stop Limit order restated': 'Stop limit order restated',
    'reports.Stop loss order canceled': 'Stop loss order cancelled',
    'reports.Stop Loss price is too big': 'Invalid SL price for order',
    'reports.Stop order accepted': 'Stop order accepted',
    'reports.Stop order activated': 'Stop order activated',
    'reports.Stop order cancel request': 'Stop order cancel request',
    'reports.Stop order canceled': 'Stop order cancelled',
    'reports.Stop order created': 'Stop order created',
    'reports.Stop Order Created': 'Stop order created',
    'reports.Stop order created is off market': 'Stop order is off market',
    'reports.Stop order created is unplaced': 'Stop order was unplaced on exchange',
    'reports.Stop order is awaiting market': 'Stop order is awaiting market',
    'reports.Stop Order Modified': 'Stop order modified',
    'reports.Stop order partially reject': 'Stop order partially cancelled',
    'reports.Stop order placing request': 'Stop order placing request',
    'reports.Stop order received by broker': 'Stop order received by broker',
    'reports.Stop order rejected': 'Stop order rejected',
    'reports.Stop Order Removed': 'Stop order removed',
    'reports.Stop order replace request': 'Stop order replace request',
    'reports.Stop order replaced': 'Stop order modified',
    'reports.Stop order restated': 'Stop order restated',
    'reports.Stop price': 'Stop price',
    'reports.stoploss': 'SL',
    'reports.StopOutType': 'Stop out type',
    'reports.stopprice': 'Stop price',
    'reports.STP_Route_Change': 'Set original route name in order',
    'reports.Strike Price': 'Strike price',
    'reports.submitted': 'Submitted',
    'reports.Subscribe': 'Subscribe',
    'reports.SUBSCRIBE_RULES': 'Subscribe plans',
    'reports.success': 'Success',
    'reports.Successful': 'Successful',
    'reports.sum': 'Sum',
    'reports.Sum': 'Sum',
    'reports.Summary Report': 'Summary report',
    'reports.SunGard': 'SunGard',
    'reports.SUSPEND_MARGINCALL_ON_CLOSE_MARKET': 'Suspend margin call assistant for closed markets',
    'reports.swap': 'Swap',
    'reports.swapbuy': 'Long swap',
    'reports.swapplan': 'Swap plan',
    'reports.swaps': 'Swaps',
    'reports.swaps_in': 'Swap in',
    'reports.swaps_out': 'Swaps out',
    'reports.swapsell': 'Short swap',
    'reports.symbol': 'Symbol',
    'reports.Symbol': 'Symbol',
    'reports.symbol_type': 'Symbol type',
    'reports.System': 'System',
    'reports.system_currency': 'System currency',
    'reports.systemMonitor.instruments': 'Symbols',
    'reports.systemMonitor.onlineConnections': 'Online connections',
    'reports.systemMonitor.positions': 'Open positions',
    'reports.systemMonitor.regusers': 'Registered users',
    'reports.systemMonitor.runningRoutes': 'Started routes from active routes',
    'reports.systemMonitor.totalQueueSize': 'Queues in services',
    'reports.TABLE_ALERT': 'Desktop:Table Alert',
    'reports.tablename': 'Table name',
    'reports.TableName': 'Table name',
    'reports.Take profit order canceled': 'Take profit order cancelled',
    'reports.Take Profit price is too big': 'Take Profit price is too big',
    'reports.takeprofit': 'TP',
    'reports.TargetCompID': 'Target computer ID',
    'reports.task.comment': 'Comment',
    'reports.task.day': 'Day',
    'reports.task.hour': 'Hour',
    'reports.task.minute': 'Minute',
    'reports.task.month': 'Month',
    'reports.task.timezone': 'Time zone offset',
    'reports.task.usedst': 'Use DST',
    'reports.task.year': 'Year',
    'reports.TempFilePath': 'Temporary file path',
    'reports.Tenfore': 'Tenfore',
    'reports.terminal': 'Terminal',
    'reports.Terminal': 'Terminal',
    'reports.TERMINAL_API': 'API',
    'reports.TERMINAL_COMMUNITY': 'Community',
    'reports.TERMINAL_FIX': 'FIX Connection',
    'reports.TERMINAL_HTML': 'HTML',
    'reports.TERMINAL_JAVA': 'Java',
    'reports.TERMINAL_MOBILE': 'Mobile',
    'reports.TERMINAL_NET': '.Net',
    'reports.TERMINAL_POCKETPC': 'Pocket PC',
    'reports.TERMINAL_WEB': 'WebStation',
    'reports.terminaltype': 'Terminal type',
    'reports.TFDS Quote Receiver': 'TFDS quote receiver',
    'reports.tif': 'Validity',
    'reports.TIF': 'Validity',
    'reports.time': 'Time',
    'reports.Time': 'Date/Time',
    'reports.to': 'To',
    'reports.toDate': 'To',
    'reports.toDateConnAttempt': 'To',
    'reports.toDatePwdChange': 'To(Pass)',
    'reports.tooLargeData': "Report's data is too large! Shows {1} of {2} rows",
    'reports.total': 'Total',
    'reports.total traded amount': 'Total traded amount',
    'reports.totalfee': 'Total fee',
    'reports.TP': 'Take profit',
    'reports.TP accepted by broker': 'TP order accepted by broker',
    'reports.TP accepted by STP': 'TP order accepted by STP',
    'reports.TP order accepted': 'TP order accepted',
    'reports.TP order cancel request': 'TP order cancel request',
    'reports.TP order canceled': 'TP order cancelled',
    'reports.TP order created': 'TP order created',
    'reports.TP Order Created': 'TP order created',
    'reports.TP order created is off market': 'TP order is off market',
    'reports.TP order created is unplaced': 'TP order was unplaced on exchange',
    'reports.TP Order Modified': 'TP order modified',
    'reports.TP order partially reject': 'TP order partially cancelled',
    'reports.TP order placing request': 'TP order placing request',
    'reports.TP order received by broker': 'TP order received by broker',
    'reports.TP order rejected': 'TP order rejected',
    'reports.TP Order Removed': 'TP order removed',
    'reports.TP order replace request': 'TP order replace request',
    'reports.TP order replaced': 'TP order modified',
    'reports.Tr stop loss order cancel request': 'Tr. stop loss order cancel request',
    'reports.Tr stop loss order placing request': 'Tr. stop loss order placing request',
    'reports.Tr stop loss order replace request': 'Tr. stop loss order replace request',
    'reports.tr. stop': 'Tr. stop',
    'reports.Tr. stop accepted by broker': 'Tr. stop order accepted by broker',
    'reports.Tr. stop accepted by STP': 'Tr. stop order accepted by STP',
    'reports.Tr. stop is too big': 'Invalid trailing stop offset for order',
    'reports.Tr. stop loss order accepted': 'Tr. stop loss order accepted',
    'reports.Tr. stop loss order canceled': 'Tr. stop loss order cancelled',
    'reports.Tr. stop loss order created': 'Tr. stop loss order created',
    'reports.Tr. stop loss order created is off market': 'Tr. stop loss order is off market',
    'reports.Tr. stop loss order created is unplaced': 'Tr. stop loss order was unplaced on exchange',
    'reports.Tr. stop loss order replaced': 'Tr. stop loss order modified',
    'reports.Tr. stop order accepted': 'Tr. stop order accepted',
    'reports.Tr. stop order activated': 'Tr. stop order activated',
    'reports.Tr. stop order canceled': 'Tr. stop order cancelled',
    'reports.Tr. stop order created': 'Tr. stop order created',
    'reports.Tr. stop order created is off market': 'Tr. stop order is off market',
    'reports.Tr. stop order created is unplaced': 'Tr. stop order was unplaced on exchange',
    'reports.Tr. stop order is awaiting market': 'Tr. stop order is awaiting market',
    'reports.Tr. stop order partially reject': 'Tr. stop order partially cancelled',
    'reports.Tr. stop order received by broker': 'Tr. stop order received by broker',
    'reports.Tr. stop order placing request': 'Tr. stop order placing request',
    'reports.Tr. stop order cancel request': 'Tr. stop order cancel request',
    'reports.Tr. stop order replace request': 'Tr. stop order replace request',
    'reports.Tr. stop order rejected': 'Tr. stop order rejected',
    'reports.Tr. stop order replaced': 'Tr. stop order modified',
    'reports.Tr. stop order restated': 'Tr. stop order restated',
    'reports.Tr.SL Order Removed': 'Trailing stop order removed',
    'reports.Trade executed': 'Trade executed',
    'reports.Trade executed (Mutual)': 'Trade executed (Mutually)',
    'reports.Trade executed (SL)': 'Trade executed (SL)',
    'reports.Trade Operations Report': 'Trade operations report',
    'reports.Trade Periods By Signal Report': 'Trade periods by signal report',
    'reports.trade.BeginString': 'Trade begin string',
    'reports.trade.CheckCompID': 'Trade check Computer ID',
    'reports.trade.CheckLatency': 'Trade check latency',
    'reports.trade.ClosedResendInterval': 'Trade closed resend interval',
    'reports.trade.ConnectionType': 'Trade connection type',
    'reports.trade.ContinueInitializationOnError': 'Trade continue initialization on error',
    'reports.trade.DataDictionary': 'Trade data dictionary',
    'reports.trade.EndDay': 'Trade end day',
    'reports.trade.EndTime': 'Trade end time',
    'reports.trade.entityId': 'Trade entity ID',
    'reports.trade.FileIncludeMilliseconds': 'Trading file includes milliseconds',
    'reports.trade.FileIncludeTimeStampForMessages': 'Trade file includes time stamp for messages',
    'reports.trade.FileLogHeartbeats': 'Trade file log heartbeats',
    'reports.trade.fxintegrate.cert.host.verify': 'Trade fx integrate cert host verify',
    'reports.trade.fxintegrate.cert.root.ca.file': 'Trade fx integrate cert root ca file',
    'reports.trade.fxintegrate.host': 'Trade fxintegrate host',
    'reports.trade.fxintegrate.logging.file': 'Trade fx integrate logging file',
    'reports.trade.fxintegrate.logging.maxfilecount': 'Trade fx integrate logging maximum file count',
    'reports.trade.fxintegrate.logging.output': 'Trade fx integrate logging output',
    'reports.trade.fxintegrate.logging.priority': 'Trade fxintegrate logging priority',
    'reports.trade.fxintegrate.port': 'Trade fx integrate port',
    'reports.trade.fxintegrate.protocol': 'Trade fx integrate protocol',
    'reports.trade.fxintegrate.usessl': 'Trade fx integrate use ssl',
    'reports.trade.HeartBtInt': 'Trade heartbeat Int',
    'reports.trade.JdbcDriver': 'Trade Jdbc driver',
    'reports.trade.JdbcPassword': 'Trade Jdbc password',
    'reports.trade.JdbcURL': 'Trade Jdbc URL',
    'reports.trade.JdbcUser': 'trade Jdbc user',
    'reports.trade.keystoreAlias': 'Trade key store Alias',
    'reports.trade.keystoreFile': 'Trade key store file',
    'reports.trade.LogFileMaxSize': 'Trade log file maximum size',
    'reports.trade.login': 'Trade login',
    'reports.trade.MaxLogsBackups': 'Trading maximum logs backups',
    'reports.trade.MillisecondsinTimeStamp': 'Milliseconds in time stamp',
    'reports.trade.MillisecondsInTimeStamp': 'Trade milliseconds in time stamp',
    'reports.trade.password': 'Trade password',
    'reports.trade.ReconnectInterval': 'Trade reconnect interval',
    'reports.trade.RefreshonLogon': 'Refresh on logon',
    'reports.trade.RefreshOnLogon': 'Trade refresh on logon',
    'reports.trade.ResetOnDisconnect': 'Trade reset on disconnect',
    'reports.trade.ResetOnLogon': 'Trade reset on logon',
    'reports.trade.ResetOnLogout': 'trade reset on logout',
    'reports.trade.saveLogs': 'Trade save logs',
    'reports.trade.ScreenLogEvents': 'Trade screen log events',
    'reports.trade.ScreenLogShowHeartBeats': 'Trade screen log show heartbeats',
    'reports.trade.ScreenLogShowIncoming': 'Trade screen log shows incoming',
    'reports.trade.ScreenLogShowOutgoing': 'Trade screen log show outgoing',
    'reports.trade.SenderCompID': 'Trade sender comp ID',
    'reports.trade.SendResetSeqNumFlag': 'Trade send reset sequent number flag',
    'reports.trade.SocketConnectHost': 'Trade socket  connect host',
    'reports.trade.SocketConnectPort': 'Trade socket connect port',
    'reports.trade.SocketKeyStore': 'Trade socket key store',
    'reports.trade.SocketKeyStorePassword': 'Trade socket key store password',
    'reports.trade.SocketUseSSL': 'Trade socket use SSL',
    'reports.trade.StartDay': 'Trading start day',
    'reports.trade.StartTime': 'Trading start time',
    'reports.trade.TargetCompID': 'Trade target Comp ID',
    'reports.trade.UseDataDictionary': 'Trade use data dictionary',
    'reports.trade.ValidateFieldsHaveValues': 'Trade validate fields have values',
    'reports.trade.ValidateFieldsOutOfOrder': 'Trade validate fields out of order',
    'reports.trade.ValidateUserDefinedFields': 'Trade validate user defined fields',
    'reports.tradeamount': 'Trade amount',
    'reports.tradeid': 'Trade ID',
    'reports.tradePassword': 'Trade password',
    'reports.trader': 'Trader',
    'reports.Trades by Signal': 'Trades by signal',
    'reports.Trades Report': 'Trades report',
    'reports.Trades report by instrument': 'Trades report by instrument',
    'reports.trades_bought': 'Trades bought',
    'reports.trades_sold': 'Trades sold',
    'reports.tradevolume': 'Trade volume',
    'reports.trading': 'Trading',
    'reports.Trading': 'Trading',
    'reports.Trading disabled by risk rules': 'Trading disabled by risk rules',
    'reports.TRADING_IDEA_CARD': 'Desktop:Trading Idea card',
    'reports.trading_in': 'Trading in',
    'reports.trading_out': 'Trading out',
    'reports.TRADING_RULES_ACC': 'Trading rules(account)',
    'reports.TRADING_RUSLES': 'Trading rules',
    'reports.TRADINGSYSTEMLIST': 'Desktop:Trading system list',
    'reports.Traiding out of Main session is forbidden for this account': 'Traiding out of Main session is forbidden for this account',
    'reports.Trailing stop order cancel request': 'Trailing stop order cancel request',
    'reports.Trailing Stop Order Created': 'Trailing stop order created',
    'reports.Trailing Stop Order Modified': 'Trailing stop order modified',
    'reports.Trailing stop order placing request': 'Trailing stop order placing request',
    'reports.Trailing Stop Order Removed': 'Trailing stop order removed',
    'reports.Trailing stop order replace request': 'Trailing stop order replace request',
    'reports.TrailingStop': 'Trailing stop',
    'reports.Transfer': 'Transfer',
    'reports.TransferFee': 'Transfer Fee',
    'reports.True': 'True',
    'reports.type': 'Type',
    'reports.Type': 'Type',
    'reports.Types': 'Types',
    'reports.UBSFIXFeed': 'UBSFIXFeed',
    'reports.Unable to cancel the order since route is not active.': 'Unable to cancel the order since route is not active.',
    'reports.Unblocking': 'Unblocking',
    'reports.Unplaced': 'Unplaced',
    'reports.unrealized p/l': 'Unrealized P/L',
    'reports.unrealizedpnl': 'Unrealized P/L',
    'reports.unsettled_cash': 'Unsettled cash',
    'reports.updatedtime': 'Updated time',
    'reports.Updater': 'Updater',
    'reports.URL': 'URL',
    'reports.usage': 'Usage',
    'reports.USE_OPEN_PRICE_IN_MARGIN': 'Use open price',
    'reports.UseDataDictionary': 'Use data dictionary',
    'reports.usePrevQuotedOrders': 'use prev. quoted orders',
    'reports.UsePriority': 'Use priority',
    'reports.user': 'User',
    'reports.User': 'User',
    'reports.User Authorization Error': 'User authorization error',
    'reports.user group': 'User group',
    'reports.User Previledges Report': 'User privileges report',
    'reports.user.acc_4_signals': 'Account for signals',
    'reports.user.address': 'Address',
    'reports.user.amount': 'Amount',
    'reports.user.amountType': 'Amount type',
    'reports.user.birthday': 'Date of birth',
    'reports.user.certificate': 'Users certificate',
    'reports.user.chatOnEmail': 'Send chat on e-mail',
    'reports.user.city': 'City',
    'reports.user.comment': 'Comment',
    'reports.user.commission_type': 'Commission by',
    'reports.user.email': 'Email',
    'reports.user.extaccount': 'External account',
    'reports.user.firstname': 'First name',
    'reports.user.gender': 'Gender',
    'reports.user.group': 'Group',
    'reports.user.how_handle': 'How handle signals',
    'reports.user.introducebroker': 'Introducing broker account',
    'reports.user.language': 'Language',
    'reports.user.lastname': 'Last name',
    'reports.user.linkedUsers': 'Linked users',
    'reports.user.login': 'Login',
    'reports.user.middlename': 'Middle name',
    'reports.user.password': 'New password',
    'reports.user.phonenumber': 'Phone number',
    'reports.user.phonepassword': 'Phone password',
    'reports.user.photo': 'Photo',
    'reports.user.pin': 'Pin code',
    'reports.user.showInSearch': 'Show in search',
    'reports.user.showMyContacts': 'Show my contacts',
    'reports.user.signalsOnEmail': 'Signals on e-mail',
    'reports.user.state': 'State',
    'reports.user.status': 'Status',
    'reports.user.subscribeplan': 'Subscribe plan',
    'reports.user.website': 'Web-site',
    'reports.user.zipcode': 'ZIP code',
    'reports.usergroup.group': 'Super group',
    'reports.usergroup.name': 'User group',
    'reports.USERGROUPSVIS_RULES': 'User groups visibility',
    'reports.userlogin': 'User login',
    'reports.UserLogin': 'User login',
    'reports.username': 'User',
    'reports.Users Report': 'Users report',
    'reports.Users Report(copy)': 'Users report(copy)',
    'reports.users_num': 'Users num',
    'reports.useSSL': 'use SSL',
    'reports.ValidateFieldsHaveValues': 'Validate fields have values',
    'reports.ValidateFieldsOutOfOrder': 'Validate fields out Of order',
    'reports.ValidateUserDefinedFields': 'Validate user defined fields',
    'reports.value': 'Value',
    'reports.VALUE_ADDRESS': 'Address',
    'reports.VALUE_BASE_CURRENCY': 'Base symbol part',
    'reports.VALUE_BRUTEFORCE_LOCK_MINUTS': 'Value brute force lock minutes',
    'reports.VALUE_CITY': 'City',
    'reports.VALUE_COMMISSION_ACCOUNT': 'Commission counter account',
    'reports.VALUE_COMMISSION_FOR_TRANSFER': 'Commission for transfer',
    'reports.VALUE_COMMISSION_ID': 'Revenue plan',
    'reports.VALUE_COUNTER_CURRENCY': 'Counter symbol part',
    'reports.VALUE_COUNTRY': 'Country',
    'reports.VALUE_DAY_BEGIN': 'Begin day',
    'reports.VALUE_DAY_END': 'End day',
    'reports.VALUE_DAYS_TILL_EXP': 'Days till expiration',
    'reports.VALUE_DEFAULT_BROKER': 'Default Broker',
    'reports.VALUE_DEFAULT_LANG': 'Default language',
    'reports.VALUE_DERIVATIVE_TYPE': 'Derivative type',
    'reports.VALUE_HEDGE_COEFFICIENT': 'Hedge coefficient',
    'reports.VALUE_L2QUOTEGROUP_DEPTH': 'Level 2 group depth',
    'reports.VALUE_L2QUOTEGROUP_INTERVAL': 'Level 2 group interval',
    'reports.VALUE_LEVERAGE': 'Leverage',
    'reports.VALUE_LOT_SIZE': 'Lot size',
    'reports.VALUE_LOTSTEP': 'Lot step',
    'reports.VALUE_MARGIN_COEFFICIENT': 'Margin coefficient(time based)',
    'reports.VALUE_MARGINCALL': 'Margin call, %',
    'reports.VALUE_MARGINTRADING': 'Stop trading level, %',
    'reports.VALUE_MARKET_RANGE': 'Market range, ticks',
    'reports.VALUE_MAX_DELTA_QUOTES': 'Maximum allowable price difference between two subsequent quotes (ticks)',
    'reports.VALUE_MAX_DELTA_TRADES': 'Maximum allowable price difference between two subsequent trades',
    'reports.VALUE_MAXIMUM_CACHE_SIZE': 'Maximum cache size',
    'reports.VALUE_MAXIMUM_FILTER': 'Max spike value',
    'reports.VALUE_MAXIMUMLOT': 'Maximum lot',
    'reports.VALUE_MIN_OPTION_INTERVAL': 'Minimum allowable time difference between two options quotes',
    'reports.VALUE_MIN_PRICE_DIFF_FOR_MARGIN': 'Minimal price diff for margin',
    'reports.VALUE_MINIMALLOT': 'Minimum lot',
    'reports.VALUE_MINIMUM_FILTER': 'Min spike value',
    'reports.value_nav': 'Value/Nav',
    'reports.VALUE_NO_QUOTES_MAXTIME': 'No quotes max time',
    'reports.VALUE_NUMBER_OF_SHARES': 'Number of shares',
    'reports.VALUE_ONE_COUNTER_ACCOUNT': 'Counter account',
    'reports.VALUE_PIP_SIZE': 'Tick size(minimal change)',
    'reports.VALUE_PnL_ACCOUNT': 'P/L counter account',
    'reports.VALUE_POST_SESSION_END': 'Post Session end',
    'reports.VALUE_PRE_SESSION_BEGIN': 'Pre session start',
    'reports.VALUE_PRICE_GENERATION_MODEL': 'Price generation model',
    'reports.VALUE_PWD_VERIFICATION_LEN': 'Verification password length',
    'reports.VALUE_QUOTE_CACHE_15MIN_SIZE': 'Quote cache 15min size',
    'reports.VALUE_QUOTE_CACHE_1MIN_SIZE': 'Quote cache 1min size',
    'reports.VALUE_QUOTE_CACHE_30MIN_SIZE': 'Quote cache 30min size',
    'reports.VALUE_QUOTE_CACHE_4HOUR_SIZE': 'Quote cache 4hour size',
    'reports.VALUE_QUOTE_CACHE_5MIN_SIZE': 'Quote cache 5min size',
    'reports.VALUE_QUOTE_CACHE_DAY_SIZE': 'Quote cache day size',
    'reports.VALUE_QUOTE_CACHE_HOUR_SIZE': 'Quote cache hour size',
    'reports.VALUE_QUOTE_CACHE_MONTH_SIZE': 'Quote cache month size',
    'reports.VALUE_QUOTE_CACHE_SIZE': 'Quote cache size',
    'reports.VALUE_QUOTE_CACHE_TIC_SIZE': 'Quote cache tic size',
    'reports.VALUE_QUOTE_CACHE_WEEK_SIZE': 'Quote cache week size',
    'reports.VALUE_QUOTE_CACHE_YEAR_SIZE': 'Quote cache year size',
    'reports.VALUE_QUOTE_PATH': 'Quotes cache path',
    'reports.VALUE_QUOTES_FILE_SIZE': 'Quotes file size',
    'reports.VALUE_SLIPPAGE': 'Slippage',
    'reports.VALUE_SMS_PATTERN': 'SMS Pattern',
    'reports.VALUE_SPOT_INSTRUMENT': 'Spot symbol',
    'reports.VALUE_SPREAD_ACCOUNT': 'Spread counter account',
    'reports.VALUE_STATE': 'State',
    'reports.VALUE_STOPOUT': 'Stop Out level, %',
    'reports.VALUE_SWAP_ACCOUNT': 'Swap counter account',
    'reports.VALUE_TEMPLATE_NUMERIC': 'E-mail templates',
    'reports.VALUE_TICK_COAST': 'Tick coast',
    'reports.VALUE_TIMEZONE_OFFSET': 'Time zone offset',
    'reports.VALUE_UNREPEAT_PASSWORD': 'Number of unrepeated passwords',
    'reports.VALUE_WL_ACCOUNT': 'Deposit/Withdrawal counter account',
    'reports.VALUE_WRONG_PASSWORDS_BEFORE_LOCK': 'Number of wrong passwords allowed',
    'reports.VALUE_ZIP': 'Zip code',
    'reports.ValueAddedTax': 'Value added tax',
    'reports.valuedate': 'Valuation date',
    'reports.Visibility': 'Visibility',
    'reports.VISIBILITY_BRANDING': 'Branding visibility',
    'reports.VISIBILITY_COMMISSIONSPLAN': 'Revenue plans visibility',
    'reports.VISIBILITY_EXTFIELDS': 'Extended fields visibility',
    'reports.VISIBILITY_INSTRUMENT_TYPE': 'Symbol type visibility',
    'reports.VISIBILITY_ROUTE': 'Routes visibility',
    'reports.VISIBILITY_SUBSCRIBE_PLAN': 'Subscribe plans',
    'reports.VISIBILITY_USERGROUP': 'User groups visibility',
    'reports.Wait confirm for cancel': 'Wait confirm for cancel',
    'reports.Wait confirm for execution': 'Wait confirm for execution',
    'reports.Wait confirm for modify': 'Wait confirm for modification',
    'reports.Waiting market': 'Waiting market',
    'reports.WEB_CHART_OE': 'WEB:Chart OE',
    'reports.WEB_CHART_VISUAL': 'WEB:Chart Visual',
    'reports.WEB_MARKETDEPTH': 'WEB:Market Depth',
    'reports.WEB_OE': 'WEB:OE',
    'reports.WEB_OPTION_MASTER': 'WEB:Option Master',
    'reports.WEB_ORDERS_PANEL_HOT_BTN': 'WEB:Orders Panel Hot Buttons',
    'reports.WEB_OTHER': 'WEB:Other',
    'reports.WEB_POSITIONS_PANEL_HOT_BTN': 'WEB:Positions Panel Hot Buttons',
    'reports.WEB_ALERT': 'WEB:Alert',
    'reports.WEB_SAVED_ORDERS': 'WEB:Saved Orders',
    'reports.SERVER': 'Server:Server',
    'reports.weeks': 'weeks',
    'reports.Widgets Instrument Statistics Report': 'Widgets instrument statistics report',
    'reports.widgets_isr_buytrades': 'Buy trades',
    'reports.widgets_isr_commission': 'Fee',
    'reports.widgets_isr_instrumentname': 'Symbol',
    'reports.widgets_isr_netpl': 'Net P/L',
    'reports.widgets_isr_plticks': 'P/L ticks',
    'reports.widgets_isr_selltrades': 'Sell trades',
    'reports.widgets_isr_swap': 'Swap',
    'reports.widgets_isr_totaltrades': 'Total trades',
    'reports.widgets_isr_trades': 'Trades',
    'reports.widgets_isr_tradevolume': 'Volume',
    'reports.widgets_isr_winningtrades': 'Winning trades',
    'reports.widthdraw': 'Withdrawal',
    'reports.Withdraw': 'Withdrawal',
    'reports.Withdrawal': 'Withdrawal',
    'reports.withdrawalfee': 'Withdrawal fee',
    'reports.Wrong account operation summ.': 'Wrong account operation sum',
    'reports.Wrong recovery key': 'Wrong recovery key',
    'reports.years': 'years',
    'reports.yes': 'Yes',
    'reports.64': 'Web:Positions CM',
    "reports.You can't trade with this account.": "You can't trade with this account.",
    Reserve: 'Reserve',
    'RiskRuleType.NegativeProjectedBalance': 'User: {0}, Account: {1}. Stop out was triggered by negative Projected balance.',
    'RiskRuleType.AvailableCash': 'Warning! You are starting to use broker’s credit funds (leverage)',
    'ribbon.closeAccount': 'Close account',
    'screen.closeAccount.header': 'Confirm close account',
    'screen.closeAccount.okBtnText': 'Send request',
    'screen.closeAccount.closeBtnText': 'Close',
    'screen.closeAccount.confirmText': 'Are you sure you want to close your user account?',
    'screen.closeAccount.cancelText': 'Cancel request',
    'screen.closeAccount.approveText': 'Your request is accepted. We will complete the necessary actions shortly and the account will be closed.',
    'screen.closeAccount.submitText': 'Your request has been successfully submitted. The broker will contact you as soon as possible.\n\nPlease, mind that it will not be processed immediately, and you will still be able to use your account. However, once you close your account, it will no longer be available.',
    'closeAccount.dealticket.0': 'Your request has been successfully submitted. The broker will contact you as soon as possible.',
    'closeAccount.dealticket.1': 'Your request is accepted. We will complete the necessary actions shortly and the account will be closed.',
    'closeAccount.dealticket.2': 'Your request is rejected. For more details contact your broker.',
    'closeAccount.dealticket.3': 'Your request is cancelled.',
    'ribbon.enviroment': 'Environment',
    'ribbon.enviroment.help': 'Help',
    'ribbon.enviroment.logout': 'Log out',
    'ribbon.enviroment.New': 'New workspace',
    'ribbon.enviroment.NotificationWindow.lockTrading': 'Trading locked',
    'ribbon.enviroment.NotificationWindow.UnlockTrading': 'Trading unlocked',
    'ribbon.enviroment.Save': 'Save workspace',
    'ribbon.enviroment.settings': 'Settings',
    'ribbon.enviroment.settings.disabled.tt': 'Unable to change settings in disconnected mode!',
    'ribbon.help.about': 'About',
    'ribbon.help.onboarding': 'Onboarding',
    'ribbon.help.help': 'More',
    'ribbon.help.home': 'Home page',
    'ribbon.help.downloadLogFile': 'Download log',
    'ribbon.linking.linkBlue': 'Blue',
    'ribbon.linking.linkEmpty': 'None',
    'ribbon.linking.linkGreen': 'Green',
    'ribbon.linking.linkOrange': 'Orange',
    'ribbon.linking.linkPurple': 'Purple',
    'ribbon.linking.linkRed': 'Red',
    'ribbon.linking.linkYellow': 'Yellow',
    'ribbon.more.tradingTools': 'Trading tools',
    'ribbon.more.brokerInfo': 'Broker info',
    'screen.brokerInfo.header': 'Broker information',
    'ribbon.tools': 'Tools',
    'ribbon.tools.full_logs': 'Full logging server messages',
    'ribbon.tools.grid': 'Grid',
    'ribbon.tools.withdrawal': 'Withdrawal',
    'ribbon.tools.reports': 'Reports',
    'ribbon.tools.products': 'Subscriptions',
    'ribbon.tools.portfolio': 'My Portfolio',
    'ribbon.tradingTerminal': 'Terminal',
    'ribbon.tradingTerminal.statement': '',
    'ribbon.tradingTerminal.riskCalculator': '#hidden#',
    'RiskManagement.Messages.DailyLimit': 'Daily loss limit was reached',
    'RiskManagement.Messages.MaxDailyProfit': 'Daily profit was reached',
    'RiskManagement.Messages.MaxTrailingLimit': 'Max trailing drawdown was reached',
    'RiskManagement.Messages.MaxUnrealizedLossLimit': 'Unrealized loss limit was reached',
    'RiskManagement.Messages.WeeklyLimit': 'Weekly loss limit was reached',
    'RiskManagement.Messages.MaxOrdersCountPerDay': 'Trading was disabled by risk rules. The maximum number of orders per day has been reached.',
    'RiskManagement.Messages.MaxRelativeDrawDownLevel': 'Max relative drawdown level was reached',
    'RiskManagement.Messages.EODTrailingDrawDownLevel': 'EOD trailing drawdown level was reached',
    'RiskManagement.Messages.RelativeDailyLossLimit': 'Relative daily loss limit was reached',
    'screen.about.copyright': '© 2017-2025 TraderEvolution Global LTD.',
    'screen.about.dateBuild': 'Date of build:',
    'screen.about.title': 'About',
    'screen.about.titleWeb': 'About TraderEvolution',
    'screen.about.versionWeb': 'Build version',
    'screen.additionalVerification.comment': 'Connection ({1}) required additional verification:',
    'screen.additionalVerification.Need activation': 'Need activation',
    'screen.additionalVerification.Need activation.comment': 'Please enter activation code (sent to your mail)',
    'screen.additionalVerification.Need activation.watermark': 'Please enter your OTP here',
    'screen.additionalVerification.title': 'Additional verification required',
    'screen.additionalVerification.title.Email': 'Additional verification required (e-mail one-time pass)',
    'screen.additionalVerification.title.Fixed': 'Additional verification required (fixed pass)',
    'screen.additionalVerification.title.Sms': 'Additional verification required (SMS one-time pass)',
    'screen.additionalVerification.title.SmsPlusEmailOneTimePass': 'Additional verification required (SMS + E-mail one-time pass)',
    'screen.additionalVerification.title.DailySmsPlusEmailOneTimePass': 'Additional verification (Daily SMS + E-mail one-time pass)',
    'screen.additionalVerification.watermark': 'Please enter your OTP here',
    'screen.changepass.cancel': 'Cancel',
    'screen.changepass.changed': 'Password changed successfully',
    'screen.changepass.changed.login': 'Password changed successfully. Please log in',
    'screen.changepass.confirm': 'Confirm new password',
    'screen.changepass.new': 'New password',
    'screen.changepass.ok': 'OK',
    'screen.changepass.old': 'Current password',
    'screen.changepass.title': 'Change password',
    'screen.changeTradingPass.title': 'Change trading password',
    'screen.changepass.watermark_confirm': '<Confirm password>',
    'screen.changepass.watermark_new': '<New password>',
    'screen.changepass.watermark_old': '<Current password>',
    'screen.changepass.alert_not_match': 'Password and confirmation do not match',
    'screen.changepass.wrongVerification': 'Wrong Verification',
    'screen.changepass.tooeasy': 'Your password is too common and easy to guess',
    'screen.closePosition.cancel': 'Cancel',
    'screen.closePosition.ok': 'Close',
    'screen.closePosition.title': 'Close',
    'screen.closePosition.maxLotWarning': 'Quantity is greater than Maximum lot. Orders will be created to close the position.',
    'screen.closePosition.additionalInfoLabel': 'Commission (est.)',
    'screen.closePosition.EstimatedClosingFee': 'Estimated closing fee',
    'screen.PositionExerciseRequestScreen.title': 'Position exercise request',
    'screen.PositionExerciseRequestScreen.exercise': 'Exercise',
    'screen.PositionExerciseRequestScreen.exerciseError': 'Only In-the-money options can be exercised',
    'screen.PositionExerciseCancelConfirm.text': 'Cancel exercise request for position #{1} ?',
    'screen.PositionExerciseComplete.header': 'Received status "Pending exercise" for position',
    'screen.PositionExerciseComplete.text': 'Position {1} for account {2} is blocked for exercise. To unblock position exercise request should be cancelled.',
    'screen.PositionExerciseDealticket.header': 'Position exercise request',
    'screen.PositionExerciseCancelDealticket.header': 'Position cancel exercise request',
    'screen.editPosition.header': 'Edit position',
    'screen.error.title': 'Error',
    'screen.export.header': 'Export',
    'screen.export.contextMenu': 'Export',
    'screen.export.visibility': '#not hidden#',
    'screen.export.Export': 'Export',
    'screen.export.Cancel': 'Cancel',
    'screen.export.CSV': 'CSV',
    'screen.export.Excel': 'Excel',
    'screen.export.selectFormat.tt': 'Select file type',
    'screen.export.checkAllText': 'Check all',
    'screen.licensing.emptyFieldErrors.password': 'Password field is empty',
    'screen.licensing.statusMessages.connecting': 'Connecting...',
    'screen.login.change_pass': 'The password has been reset or expired and needs to be changed',
    'screen.login.bad_branding_key': 'The application is not allowed for user',
    'screen.lookup.scripts.noitems': 'No matching items',
    'screen.lookup.scripts.showing': 'Showing {1} items',
    'screen.lookup.scripts.watermark': '<Search>',
    'screen.modifyOrder.amount': 'Quantity:',
    'screen.modifyOrder.bind_to': 'Bound to:',
    'screen.modifyOrder.Change order': 'Modify order',
    'screen.modifyOrder.created': 'Date/Time:',
    'screen.modifyOrder.instrument': 'Symbol:',
    'screen.modifyOrder.leverage': 'Leverage:',
    'screen.modifyOrder.market_price': 'Current price:',
    'screen.modifyOrder.modify': 'Modify',
    'screen.modifyOrder.modifyPosition': 'Modify position',
    'screen.modifyOrder.number': 'Position ID:',
    'screen.modifyOrder.operation': 'Side:',
    'screen.modifyOrder.orderNumber': 'Order ID:',
    'screen.modifyOrder.ordertype': 'Order type:',
    'screen.modifyOrder.price': 'Open price:',
    'screen.modifyOrder.productType': 'Product type:',
    'screen.modifyOrder.route': 'Route:',
    'screen.properties.cancel': 'Close',
    'screen.properties.cancel.ToolTip': 'Close',
    'screen.properties.common': 'properties',
    'screen.properties.ok': 'OK',
    'screen.properties.ok.ToolTip': 'OK',
    'screen.properties.title': 'General Settings',
    'screen.properties.unsavedChanges': 'You have unsaved changes. Exit and lose your changes?',
    'screen.LoginScreen.Connecting': 'Connecting...',
    'screen.LoginScreen.Error.loginIdIsEmpty': 'Login ID field is empty',
    'screen.LoginScreen.Error.passwordIdIsEmpty': 'Password field is empty',
    'screen.LoginScreen.Error.noWorkingServers': 'Not found working servers',
    'screen.LoginScreen.ExploreLinkLabel': 'Explore the app',
    'screen.LoginScreen.ExploreLinkLabel.ToolTip': 'Enter the application in a demo mode, please note that you will not be allowed to trade or change application settings',
    'screen.LoginScreen.ForgotLinkLabel': 'Forgot password',
    'screen.LoginScreen.ForgotLinkLabel.ToolTip': 'Password recovery',
    'screen.LoginScreen.LoadingUserData': 'Loading user data...',
    'screen.LoginScreen.LoginButtonText': 'Log in',
    'screen.LoginScreen.LoginButtonTooltip': 'Log in to the platform',
    'screen.LoginScreen.LoginIDLabel': 'Login',
    'screen.LoginScreen.LoginIDTextBox.ToolTip': 'User login',
    'screen.LoginScreen.PasswordLabel': 'Password',
    'screen.LoginScreen.PasswordTextBox.ToolTip': 'User password',
    'screen.LoginScreen.RegistrationLinkLabel': 'Registration',
    'screen.LoginScreen.RegistrationLinkLabel.ToolTip': 'Demo account registration',
    'screen.LoginScreen.SavePassCheckBox': 'Save password',
    'screen.LoginScreen.SavePassCheckBox.ToolTip': 'Remember credentials',
    'screen.LoginScreen.ShowPassEye.ToolTip': 'Press to show password',
    'screen.LoginScreen.ShowPassCrossed.ToolTip': 'Press to hide password',
    'screen.LoginScreen.Settings.LanguageLabel': 'Language',
    'screen.LoginScreen.CustomerNo': 'CustomerNo',
    'screen.LoginScreen.Error.CustomerNoIsEmpty': 'CustomerNo field is empty',
    'screen.LoginScreen.ChatLink.visible': '#hidden#',
    'screen.LoginScreen.ChatLink.url': 'https://chat.movidesk.com/ChatWidget/Landing/7A6E5709B4C1431997F4F6B3CB10619E',
    'screen.LoginScreen.ChatLink.label': 'Live chat',
    'screen.LoginScreen.LoginError': 'Login Error',
    'screen.recconection.attemptLabelLabel': 'Attempts:',
    'screen.recconection.reconnecting': 'Reconnecting...',
    'screen.recconection.seconds': 'seconds...',
    'screen.recconection.statusLabelLabel': 'Status:',
    'screen.recconection.stop': 'Stop',
    'screen.recconection.title': 'Reconnection manager',
    'screen.recconection.tryNow': 'Try now',
    'screen.recconection.waiting': 'Waiting',
    'screen.RecoveryPassForm.Cancel': 'Cancel',
    'screen.RecoveryPassForm.Confirm': 'Confirm password',
    'screen.RecoveryPassForm.Email': 'Email:',
    'screen.RecoveryPassForm.HaveCode': 'I have a key',
    'screen.RecoveryPassForm.Key': 'Key',
    'screen.RecoveryPassForm.Login': 'Login:',
    'screen.RecoveryPassForm.New password': 'New password',
    'screen.RecoveryPassForm.New password contains prohibited symbols': 'New password contains prohibited symbols',
    'screen.RecoveryPassForm.Send': 'Recover',
    'screen.RecoveryPassForm.Title': 'Password recovery',
    'screen.RecoveryPassForm.invalid_login': 'Invalid login to recover your password',
    'screen.RecoveryPassForm.invalid_login_or_email': 'Invalid login or email to recover your password',
    'screen.RecoveryPassForm.invalid_email': 'Invalid email for this user',
    'screen.RecoveryPassForm.Your request for password recovering has sent successfully. Please check your email!': 'Your request for password recovering has been sent successfully. Please check your email!',
    'screen.registrationWizard.confirmPassword': '*Confirm password:',
    'screen.registrationWizard.confirmPassword.watermark': '<Confirm your password>',
    'screen.registrationWizard.email': '*Email:',
    'screen.registrationWizard.email.watermark': '<Email>',
    'screen.registrationWizard.Country': 'Country:',
    'screen.registrationWizard.Password': '*Password:',
    'screen.registrationWizard.Balance': 'Balance:',
    'screen.registrationWizard.firstName': '*First name:',
    'screen.registrationWizard.firstName.watermark': '<min 2 chars>',
    'screen.registrationWizard.lastName': '*Last name:',
    'screen.registrationWizard.lastName.watermark': '<min 2 chars>',
    'screen.registrationWizard.login': 'Login:',
    'screen.registrationWizard.login.watermark': '<Leave empty for auto-generation>',
    'screen.registrationWizard.password.watermark': '<Enter your password>',
    'screen.registrationWizard.phone': 'Phone number:',
    'screen.registrationWizard.phoneNumber.watermark': '<(country code) phone number>',
    'screen.registrationWizard.registrationStatus': 'Registration status',
    'screen.registrationWizard.successMessage': 'Registration is successful!\nLogin: {1}\nPassword: {2}',
    'screen.registrationWizard.Header': 'Create demo account',
    'screen.registrationWizard.Register': 'Register',
    'screen.registrationWizard.Cancel': 'Cancel',
    'screen.renameScreen.cancel': 'Cancel',
    'screen.renameScreen.illegalName': 'Illegal name!',
    'screen.renameScreen.ok': 'OK',
    'screen.renameScreen.newList.header': 'List name',
    'screen.renameScreen.newList.placeholder': 'New list',
    'screen.renameScreen.newTemplate.header': 'Template name',
    'screen.renameScreen.newTemplate.placeholder': 'New template',
    'screen.renameScreen.rename.header': 'Rename',
    'screen.rename.alreadyExist.firstPart': 'Name ',
    'screen.rename.alreadyExist.secondPart': ' already exists.',
    'screen.remove.lastlist': 'There should be at least one list',
    'screen.remove.title': 'Remove',
    'screen.remove.confirmText.firstPart': 'Remove ',
    'screen.remove.confirmText.secondPart': ' list?',
    'screen.remove.confirmText.secondPart.templates': ' template?',
    'screen.ReportMessageTooltip.CreatedAt': 'created at',
    'screen.ReportMessageTooltip.FilledAt': 'filled at',
    'screen.ReportMessageTooltip.MarginCall': 'Margin call',
    'screen.ReportMessageTooltip.Market': 'Market',
    'screen.ReportMessageTooltip.ModifiedAt': 'modified at',
    'screen.ReportMessageTooltip.RemovedAt': 'removed at',
    'screen.reports.actions_print': 'Print',
    'screen.reports.address': 'Address',
    'screen.reports.beginDatePicker.ButtonDropDown.ToolTip': 'Click to open calendar',
    'screen.reports.beginDatePicker.ToolTip': 'Select date/time',
    'screen.reports.brokerzip': 'Zip code',
    'screen.reports.button_printer_setup': 'Setup',
    'screen.reports.byGroup': 'By group',
    'screen.reports.byInstrumentGroup': 'By group',
    'screen.reports.byInstruments': 'By instruments',
    'screen.reports.byUser': 'By account',
    'screen.reports.byUserGroup': 'By group',
    'screen.reports.cell_data': '',
    'screen.reports.column.inner_text': '',
    'screen.reports.columns_maneger': 'Columns manager...',
    'screen.reports.country': 'Country',
    'screen.reports.csv': 'Export to CSV...',
    'screen.reports.customerzip': 'City, Zip',
    'screen.reports.dataCoverage': 'Data coverage',
    'screen.reports.errSaving': 'An error occurred while saving file.',
    'screen.reports.excel': 'Export to Excel...',
    'screen.reports.exportToButton': 'Export to...',
    'screen.reports.filter': 'Filter manager...',
    'screen.reports.historyFor': 'History for',
    'screen.reports.html': 'Export to HTML...',
    'screen.reports.info': 'Information',
    'screen.reports.error': 'Error',
    'screen.reports.Reports': 'Reports',
    'screen.reports.InstrumentAndGroupLookup.byGroupCombobox.ToolTip': "Select symbol's group",
    'screen.reports.InstrumentAndGroupLookup.instrunentLookup.ToolTip': 'Select symbol',
    'screen.reports.license': 'License number',
    'screen.reports.listViewEx.itemAll': 'All',
    'screen.reports.manager': 'Manager',
    'screen.reports.minimizeButton.Hide reports parameters': 'Hide reports parameters',
    'screen.reports.minimizeButton.Show reports parameters': 'Show reports parameters',
    'screen.reports.name': 'Name',
    'screen.reports.news.daily': 'Daily',
    'screen.reports.news.from': 'From',
    'screen.reports.news.hideACriterias': 'Hide advanced criteria',
    'screen.reports.news.invalidDateTime': "Please select valid 'from' and 'to' date/time",
    'screen.reports.news.monthToDate': 'Month-to-date',
    'screen.reports.news.range': 'Range',
    'screen.reports.news.showACriterias': 'Show advanced criteria',
    'screen.reports.news.sinceInception': 'Since inception',
    'screen.reports.news.till': 'Till',
    'screen.reports.news.yearToDate': 'Year-to-date',
    'screen.reports.noData': 'No data available',
    'screen.reports.order': 'Order',
    'screen.reports.param.Name': 'Name',
    'screen.reports.paramOfReport': 'Parameters of report',
    'screen.reports.pdf': 'Export to PDF...',
    'screen.reports.phone': 'Telephone number',
    'screen.reports.pos': 'Position',
    'screen.reports.president': 'Name of president',
    'screen.reports.print': 'Print...',
    'screen.reports.processingRequset': 'Cancel',
    'screen.reports.rangeError': '"From" date cannot be more than "To" date!',
    'screen.reports.report.button_printer_setup': 'Setup',
    'screen.reports.reportHeader.accnumber': 'Account number',
    'screen.reports.reportHeader.from': 'From',
    'screen.reports.reportHeader.Text': 'Customer and broker information',
    'screen.reports.reportHeaders.account': 'Account:',
    'screen.reports.reportHeaders.currancy': 'Currency:',
    'screen.reports.reportHeaders.date': 'Date:',
    'screen.reports.reportHeaders.from': 'From:',
    'screen.reports.reportHeaders.owner': 'Owner:',
    'screen.reports.reportHeaders.to': 'To:',
    'screen.reports.reportHeaderText': 'Customer and Broker information',
    'screen.reports.reportParameters': 'Report parameters',
    'screen.reports.reports.Canceled': 'Operation was cancelled',
    'screen.reports.reports.ExpingToCSV': 'Exporting data to CSV document...',
    'screen.reports.reports.ExpingToExcel': 'Exporting data to Excel document...',
    'screen.reports.reports.ExpingToHTML': 'Exporting data to HTML document...',
    'screen.reports.reports.ExpingToPDF': 'Exporting data to PDF document...',
    'screen.reports.reports.ExportingPrint': 'Exporting data for printing...',
    'screen.reports.reports.ExpPrint': 'Data was exported for printing successfully.',
    'screen.reports.reports.ExpPrintErr': 'An error occurred during exporting for printing!',
    'screen.reports.reports.ExpToCSV': 'Data was exported to CSV successfully.',
    'screen.reports.reports.ExpToCSVErr': 'An error occurred during exporting to CSV!',
    'screen.reports.reports.ExpToExcel': 'Data was exported to Excel successfully.',
    'screen.reports.reports.ExpToExcelErr': 'An error occurred during exporting to Excel!',
    'screen.reports.reports.ExpToHTML': 'Data was exported to HTML document successfully.',
    'screen.reports.reports.ExpToHTMLErr': 'An error occurred during exporting to HTML document!',
    'screen.reports.reports.ExpToPDF': 'Data was exported to PDF document successfully.',
    'screen.reports.reports.ExpToPDFErr': 'An error occurred during exporting to PDF document!',
    'screen.reports.reports.getReport': 'Get report',
    'screen.reports.reports.in_separate_window': 'Report was opened in separate window',
    'screen.reports.reports.inCurrentWindow': 'In current window',
    'screen.reports.reports.info': 'Use Report manager to view and print reports of account activity',
    'screen.reports.reports.inSeparateWindow': 'In separate window',
    'screen.reports.reports.loading': 'Loading data for report...',
    'screen.reports.reports.noData': 'Sorry, there is no available data for this request. Try to use another parameters of report.',
    'screen.reports.reports.noItemAvailable': 'No item available',
    'screen.reports.reportType': 'Report type',
    'screen.reports.reportTypeCombobox.ToolTip': 'Select report type',
    'screen.reports.save': 'Save',
    'screen.reports.showSummary': 'Show summary',
    'screen.reports.sinceInception': 'Since inception',
    'screen.reports.summary': 'Summary',
    'screen.reports.table': 'Table',
    'screen.reports.title': 'Report manager',
    'screen.reports.to': 'To',
    'screen.reports.trades': 'Trades',
    'screen.reports.UserAndGroupLookup.byGroupCombobox.ToolTip': "Select account's group",
    'screen.reports.UserAndGroupLookup.usersLookup.ToolTip': 'Select account',
    'screen.snapshot.header': 'Snapshot',
    'screen.snapshot.lastLabel': 'Last',
    'screen.snapshot.bidLabel': 'Bid',
    'screen.snapshot.askLabel': 'Ask',
    'screen.snapshot.priceLabel': 'Price:',
    'screen.snapshot.sizeLabel': 'Size:',
    'screen.snapshot.changeLabel': 'Change:',
    'screen.snapshot.highLabel': 'Today high:',
    'screen.snapshot.lowLabel': 'Today low:',
    'screen.snapshot.changePercentLabel': 'Change, %:',
    'screen.snapshot.volumeLabel': 'Today volume:',
    'screen.snapshot.getSnapshotBtnText': 'Get snapshot',
    'screen.snapshot.getSnapshotBtnText.minusOne.tt': 'Refresh snapshot',
    'screen.snapshot.getSnapshotBtnText.Zero.tt': 'Snapshot requests limit has been reached',
    'screen.snapshot.getSnapshotBtnText.moreZero.tt': 'Get real-time data snapshot',
    'screen.snapshot.closeBtnText': 'Close',
    'screen.PanelSettingsScreen.applyButton': 'Apply',
    'screen.TimeZoneSelector.title': 'Select time zones',
    'screen.tradingTools.header': 'Trading tools',
    'screen.UnlockTradingScreen.lockedLabel': 'Trading locked',
    'screen.UnlockTradingScreen.unlockButton': 'Unlock',
    'screen.withdrawal.account': 'From account',
    'screen.withdrawal.Asset': 'Asset',
    'screen.withdrawal.Availible funds': 'Available:',
    'screen.withdrawal.avaliableNumeric.ToolTip': 'Set remaining capital',
    'screen.withdrawal.Cancel': 'Cancel',
    'screen.withdrawal.error.not_allowed': 'Account Operation with type Withdrawal is not allowed for your user, please contact your broker',
    'screen.withdrawal.error.not_enough_balance': 'Not enough balance',
    'screen.withdrawal.error.not_enough_blocked': 'Invalid amount(cannot unblock more than blocked)',
    'screen.withdrawal.error.not_enough_margin': 'Not enough margin',
    'screen.withdrawal.error.UnableToBlock': 'Error! Unable to block.',
    'screen.withdrawal.error.WrongSum': 'Error: wrong sum.',
    'screen.withdrawal.errorLabel': 'Not enough money for withdrawal',
    'screen.withdrawal.Fee': 'Withdrawal fee:',
    'screen.withdrawal.from': 'from',
    'screen.withdrawal.Remains': 'Remains',
    'screen.withdrawal.RequiredAmount': 'Required amount:',
    'screen.withdrawal.ReservedWithdrawal': 'Withdrawal',
    'screen.withdrawal.usersLookup.ToolTip': 'Select account',
    'screen.withdrawal.Withdraw': 'Withdraw',
    'screen.withdrawal.Withdrawal': 'Withdraw',
    'screen.withdrawal.withdrawalNumeric.ToolTip': 'Set withdrawing capital',
    'screen.withdrawal.Amount': 'Amount',
    'Simple Moving Average': 'Simple moving average',
    SixMonth: '6 Month',
    'Smoothed Moving Average': 'Smoothed moving average',
    'Standard Deviation': 'Standard deviation',
    'statusBar.passwordpanel.DetalTicket.Text': 'Allowed access to trading activity',
    'statusBar.passwordPanel.passwordBox.invalidPass': 'Trading password is not valid.',
    Stochastic: 'Stochastic',
    'Stochastic Slow': 'Stochastic Slow',
    Stop: 'Stop',
    'Stop Limit': 'Stop limit',
    'Stop-loss': 'Stop loss',
    Suomi: '#hidden#',
    'Swing Index': 'Swing index',
    'Take-profit': 'Take profit',
    'TerceraAccountLookup.AccountNotExist': 'Account does not exist',
    'TerceraAccountLookup.IncorrectAccount': 'Incorrect account',
    'TerceraAccountLookup.Linked accounts': 'Linked accounts',
    'TerceraAccountLookup.Multy select': 'Multi select',
    'TerceraAccountLookup.Select': 'Select',
    'TerceraAccountLookup.Select All': 'Select all',
    'TerceraInstrumentLookup.InstrTypeImg.tt': 'Click to open the symbol info panel',
    'TerceraInstrumentLookup.DelayedIco.tt': 'Delayed for {0} minutes',
    'TerceraInstrumentLookup.DelayedIco.NotPluralDelay.tt': 'Delayed for {0} minute',
    'textBoxWithValidating.ErrorText.NameContainsInvalidChar': 'Name contains invalid characters',
    'textBoxWithValidating.ErrorText.NameExist': 'This name already exist',
    ThreeMonth: '3 Month',
    'Tr. stop': 'Trailing stop',
    'tr. stop': 'Trailing stop loss',
    'TradeDefaultManager.Set defaults': 'Set defaults',
    'TradeDefaultManager.Types manager': 'Types manager',
    'TradeTool.Amount of order': 'Order quantity',
    'TradeTool.Cancel order': 'Cancel order',
    'TradeTool.ChangeToMarket': 'Modify order type to Market',
    'TradeTool.Cancel SL': 'Cancel SL',
    'TradeTool.Cancel TP': 'Cancel TP',
    'TradeTool.Close position': 'Close position',
    'TradeTool.Drag to set SL order': 'Drag to set SL order',
    'TradeTool.Drag to set TP order': 'Drag to set TP order',
    'TradeTool.Leverage': 'Leverage',
    'TradeTool.Order': 'Order',
    'TradeTool.Position': 'Position',
    'TradeTool.Position P/L': 'Gross P/L',
    'TradeTool.Tick and P/L': 'Ticks and P/L',
    'TradeTool.Trailing Stop': 'Trailing stop',
    'TradeTool.View more actions': 'View more actions',
    'Trade order limit reached': 'The maximum number of orders per day was reached',
    TTO: 'TTO',
    'TypesManagerScreen.DefaultLotsSetup': 'Default lots setup',
    'TypesManagerScreen.NumericLabel.Price increment (arrow), ticks': 'Price increment (arrow), ticks',
    'TypesManagerScreen.NumericLabel.SL default offset, ticks': 'SL default offset, ticks',
    'TypesManagerScreen.NumericLabel.TP default offset, ticks': 'TP default offset, ticks',
    'TypesManagerScreen.NumericLabel.SL default offset, percent': 'SL default offset, %',
    'TypesManagerScreen.NumericLabel.TP default offset, percent': 'TP default offset, %',
    'TypesManagerScreen.NumericLabel.User quantity coefficient': 'User quantity coefficient',
    'TypesManagerScreen.QuickTable.Column.Default lots': 'Default lots',
    'TypesManagerScreen.QuickTable.Column.Enable': 'Enable',
    'TypesManagerScreen.Types Manager': 'Types manager',
    'UserControl.TerceraNumeric.ValueGreaterMax': 'Value is greater than maximum ',
    'UserControl.TerceraNumeric.ValueIsEmpty': 'Value is empty',
    'UserControl.TerceraNumeric.ValueLessMin': 'Value is less than minimum ',
    'UserControl.TerceraNumeric.ValueNotMultiple': 'Value is not multiple to min. change ',
    'UserControl.TerceraNumeric.ValueNotNumber': 'Value is not a number',
    'UserControl.TerceraDQNumeric.ValueLessMin': 'Value cannot be less than ',
    'UserControl.TerceraDQNumeric.ValueGreaterMax': 'Value cannot be greater than ',
    'UserControl.TerceraSLTPNumeric.ValueIsEmpty': 'Incorrect SL/TP value',
    'UserControl.TerceraSLTPNumeric.ChartOffsetWarning': 'Please note that the value is in offset from the order price',
    Volume: 'Volume',
    'Volume Weighted Average Price': 'Volume weighted average price',
    VWAP: 'VWAP',
    'VWAP.DaysNumber': 'Days number',
    'VWAP.StartEndDayTime': 'Start/End day time',
    allowCreatePanelsFromPanel: '',
    allowLinkSystem: '',
    allowWorkspaceContextMenu: '',
    allowWorkspaceEdit: '',
    'orders.confirm.all.question1': 'Cancel all (',
    'orders.confirm.all.question2': ') orders?',
    'orders.confirm.all.title': 'Cancel all orders',
    'orders.confirm.cancel.question1': 'Cancel all orders by selected symbol?',
    'positions.confirm.mutual.question1': 'Do you want to mutual close selected (',
    'positions.confirm.mutual.question2': ') positions?',
    'Rows.firstPart': 'The limit of available symbols/rows in',
    'Rows.secondPart': 'has been reached. Please contact your support team for detailed information',
    'general.messageBox.cancel.ToolTip': 'Discard changes and close',
    'property.Confirmations.ToolTip': 'Confirmation settings',
    'general.messageBox.ok.ToolTip': 'Apply changes and close',
    'property.Defaults.ToolTip': 'Trading default settings',
    'property.Description': 'Description',
    'property.View.ToolTip': 'View settings',
    'property.Warnings.ToolTip': 'Warning settings',
    'Throtling.Count.firstPart': 'The limit of available',
    'Throtling.Count.secondPart': 'panels has been reached. Please contact your support team for detailed information',
    'workspace.At first you should unlock workspace to rename it': 'At first you should unlock workspace to rename it.',
    'workspace.Clear': 'Clear',
    'workspace.clear_ask': 'Clear workspace?',
    'workspace.clear_wsp': 'Clear workspace',
    'workspace.Close': 'Close',
    'workspace.close_wsp': 'Close workspace',
    'workspace.DefaultNameSpace': 'Wrk#',
    'workspace.errorSavingFile': 'An error occurred while saving file.',
    'workspace.information': 'Information',
    'workspace.saved.Message': 'Workspace saved',
    'workspace.Lock': 'Lock',
    'workspace.Lock.Message': 'Workspace locked',
    'workspace.menu.Clone': 'Clone',
    'workspace.menu.Close': 'Close',
    'workspace.menu.CloseAllButThis': 'Close all but this',
    'workspace.menu.Lock': 'Lock',
    'workspace.menu.Reset': '#hidden#',
    'workspace.menu.Reset.tt': 'Change workspace to default settings',
    'workspace.newWorkspaceName': 'Enter new workspace name',
    'workspace.numberOfWorkspace': 'The number of open workspaces exceeds the maximum allowed',
    'workspace.Rename': 'Rename',
    'workspace.Unlock': 'Unlock',
    'workspace.Unlock.Message': 'Workspace unlocked',
    'InstrumentDetailsPanel.ISIN': 'ISIN',
    'InstrumentDetailsPanel.Industry': 'Industry',
    'InstrumentDetailsPanel.Industry.descr': 'Industry',
    'InstrumentDetailsPanel.Sector': 'Sector',
    'InstrumentDetailsPanel.Sector.descr': 'Sector',
    'InstrumentDetailsPanel.AccruedInterest.descr': 'Current accrued interest calculated for 1 lot',
    'InstrumentDetailsPanel.AddLiqudityRebatePerLot.descr': 'Means that for each lot in the made trade the fixed rebate value will be charged/returned (in the plan currency)',
    'InstrumentDetailsPanel.AddLiqudityRebateOrderPerLot.descr': 'Means that for each lot in the made trade the fixed rebate value will be charged/returned (in the plan currency)',
    'InstrumentDetailsPanel.AddLiqudityRebatePerVolume.descr': 'Means that charged/returned sum will be calculated as a percent from trade volume',
    'InstrumentDetailsPanel.AllowedOperations.descr': 'Shows operations allowed for given symbol',
    'InstrumentDetailsPanel.AllowedOrderTypes.descr': 'Shows order types allowed for given symbol',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday': 'Intraday allow short positions',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery': 'Delivery allow short positions',
    'InstrumentDetailsPanel.AllowShortPositions.descr': 'Shows whether allowed or not to open short positions by given symbol',
    'InstrumentDetailsPanel.AllowShortPositions.Intraday.descr': 'Shows whether allowed or not to open short positions by given symbol',
    'InstrumentDetailsPanel.AllowShortPositions.Delivery.descr': 'Shows whether allowed or not to open short positions by given symbol',
    'InstrumentDetailsPanel.AssetClass.descr': 'Type of the symbol traded on the market',
    'InstrumentDetailsPanel.BondDaysPerMonth': 'Days per month',
    'InstrumentDetailsPanel.BondDaysPerMonth.descr': 'Days number in a month used for interest calculations',
    'InstrumentDetailsPanel.BondDaysPerYear': 'Days per year',
    'InstrumentDetailsPanel.BondDaysPerYear.descr': 'Days number in a year used for interest calculations',
    'InstrumentDetailsPanel.CloseOutDeadline.descr': 'Date when the positions will be closed by each specific contract',
    'InstrumentDetailsPanel.ContactMonth.descr': 'Date of the contract expiration. Available for Options and Futures.',
    'InstrumentDetailsPanel.ContractSize.descr': 'Amount of the underlying asset for one contract',
    'InstrumentDetailsPanel.CurrentSession.descr': 'Information about current trading session',
    'InstrumentDetailsPanel.DeliveryMethod.descr': 'Type of asset delivery',
    'InstrumentDetailsPanel.DeliveryStatus.descr': 'Status of asset delivery',
    'InstrumentDetailsPanel.Description.descr': 'Detailed information about the symbol',
    'InstrumentDetailsPanel.ExerciseStyle': 'Exercise style',
    'InstrumentDetailsPanel.ExerciseStyle.descr': 'Option exercise style',
    'InstrumentDetailsPanel.FirstTradeDate.descr': 'Date when the contract can be traded firstly. Available for Options and Futures.',
    'InstrumentDetailsPanel.HiLimit.descr': 'The upper limit of the allowable price band above which delayed orders are not accepted',
    'InstrumentDetailsPanel.ISIN.descr': 'International securities identification number',
    'InstrumentDetailsPanel.KEYNextHoliday.descr': 'Shows date of the next holiday when trading is not allowed',
    'InstrumentDetailsPanel.LastTradeDate.descr': 'Date when the contract can be traded for the last time. Available for Options, Futures and Forward',
    'InstrumentDetailsPanel.Leverage': 'Leverage',
    'InstrumentDetailsPanel.Leverage.Intraday': 'Intraday leverage',
    'InstrumentDetailsPanel.Leverage.Delivery': 'Delivery leverage',
    'InstrumentDetailsPanel.LotSize.descr': 'Amount of the base asset for one lot',
    'InstrumentDetailsPanel.LotStep.descr': 'Step of the lot changes',
    'InstrumentDetailsPanel.LowLimit.descr': 'The lower limit of the allowable price band above which delayed orders are not accepted',
    'InstrumentDetailsPanel.MAIN.descr': 'Information about settings for Main session',
    'InstrumentDetailsPanel.Margin.descr': 'Shows margin requirements which depend on margin calculation type',
    'InstrumentDetailsPanel.Margin_ByAccount.descr': 'Shows margin requirements based on the account coefficients',
    'InstrumentDetailsPanel.MarginBuy.descr': 'Shows margin requirements for long positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.MarginDay.descr': 'Shows day margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginDayBuy.descr': 'Shows day coefficients of the initial and maintenance margin for the long position',
    'InstrumentDetailsPanel.MarginDaySell.descr': 'Shows day coefficients of the initial and maintenance margin for the short position',
    'InstrumentDetailsPanel.MarginOvernight.descr': 'Shows overnight margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginOvernightBuy.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of long position overnight',
    'InstrumentDetailsPanel.MarginOvernightSell.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of short position overnight',
    'InstrumentDetailsPanel.MarginSell.descr': 'Shows margin requirements for short positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.Margin.Intraday.descr': 'Shows margin requirements which depend on margin calculation type',
    'InstrumentDetailsPanel.Margin.Delivery': 'Delivery margin req.',
    'InstrumentDetailsPanel.Margin.Intraday': 'Intraday margin req.',
    'InstrumentDetailsPanel.MarginBuy.Intraday.descr': 'Shows margin requirements for long positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.MarginDay.Intraday.descr': 'Showsday margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginDayBuy.Intraday.descr': 'Showsday coefficients of the initial and maintenance margin for the long position',
    'InstrumentDetailsPanel.MarginDaySell.Intraday.descr': 'Showsday coefficients of the initial and maintenance margin for the short position',
    'InstrumentDetailsPanel.MarginOvernight.Intraday.descr': 'Shows overnight margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginOvernightBuy.Intraday.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of long position overnight',
    'InstrumentDetailsPanel.MarginOvernightSell.Intraday.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of short position overnight',
    'InstrumentDetailsPanel.MarginSell.Intraday.descr': 'Shows margin requirements for short positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.Margin.Delivery.descr': 'Shows margin requirements which depend on margin calculation type',
    'InstrumentDetailsPanel.MarginBuy.Delivery.descr': 'Shows margin requirements for long positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.MarginDay.Delivery.descr': 'Showsday margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginDayBuy.Delivery.descr': 'Showsday coefficients of the initial and maintenance margin for the long position',
    'InstrumentDetailsPanel.MarginDaySell.Delivery.descr': 'Showsday coefficients of the initial and maintenance margin for the short position',
    'InstrumentDetailsPanel.MarginOvernight.Delivery.descr': 'Shows overnight margin requirements which don’t depend on long/short margin',
    'InstrumentDetailsPanel.MarginOvernightBuy.Delivery.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of long position overnight',
    'InstrumentDetailsPanel.MarginOvernightSell.Delivery.descr': 'Shows the coefficients of the initial and maintenance margin for the transfer of short position overnight',
    'InstrumentDetailsPanel.MarginSell.Delivery.descr': 'Shows margin requirements for short positions which use different long/short margin and don’t depend on overnight margin',
    'InstrumentDetailsPanel.MaturityDate.descr': 'Date of the contract expiration',
    'InstrumentDetailsPanel.MaximumLot.descr': 'The largest trade allowed in lots',
    'InstrumentDetailsPanel.MaximumLot.Intraday': 'Intraday maximum lot',
    'InstrumentDetailsPanel.MaximumLot.Intraday.descr': 'The largest trade allowed in lots',
    'InstrumentDetailsPanel.MaximumLot.Delivery': 'Delivery maximum lot',
    'InstrumentDetailsPanel.MaximumLot.Delivery.descr': 'The largest trade allowed in lots',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.descr': 'Maximum sum qty of all positions per instrument in lots',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Intraday.descr': 'Maximum sum qty of all positions per instrument in lots',
    'InstrumentDetailsPanel.MaxPositionQtyPerSymbol.Delivery.descr': 'Maximum sum qty of all positions per instrument in lots',
    'InstrumentDetailsPanel.MinimalLot.descr': 'The smallest part of lot for which trading is available',
    'InstrumentDetailsPanel.MinimalLot.Intraday': 'Intraday minimal lot',
    'InstrumentDetailsPanel.MinimalLot.Intraday.descr': 'The smallest part of lot for which trading is available',
    'InstrumentDetailsPanel.MinimalLot.Delivery': 'Delivery minimal lot',
    'InstrumentDetailsPanel.MinimalLot.Delivery.descr': 'The smallest part of lot for which trading is available',
    'InstrumentDetailsPanel.NextPaymentDate.descr': 'Next yield payment date',
    'InstrumentDetailsPanel.NormalMarketSize.descr': 'Normal market size',
    'InstrumentDetailsPanel.NoticeDate.descr': 'Date when the notification about soonest expiration date will be sent. Available for Futures.',
    'InstrumentDetailsPanel.OrderPerLot.descr': 'Fee paid for each lot which was traded',
    'InstrumentDetailsPanel.PerPhoneTransaction.descr': 'Fee paid for each transaction which was made by Phone dealer',
    'InstrumentDetailsPanel.PerTransaction.descr': 'Fee for each transaction. Transactions: place an order, cancel an order, and modify an order',
    'InstrumentDetailsPanel.PerVolume.descr': 'Fee calculates on base of the volume of each trade, %',
    'InstrumentDetailsPanel.POSTCLOSE.descr': 'Information about settings for Post-market session',
    'InstrumentDetailsPanel.PREOPEN.descr': 'Information about settings for Pre-market session',
    'InstrumentDetailsPanel.PreviousPaymentDate.descr': 'Previous yield payment date',
    'InstrumentDetailsPanel.QuotiongCurrency.descr': 'Information about currency in which quote is calculated',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerLot.descr': 'Means that for each lot in the made trade the fixed rebate value will be charged/returned (in the plan currency)',
    'InstrumentDetailsPanel.RemoveLiqudityRebateOrderPerLot.descr': 'Means that for each lot in the made trade the fixed rebate value will be charged/returned (in the plan currency)',
    'InstrumentDetailsPanel.RemoveLiqudityRebatePerVolume.descr': 'Means that charged/returned sum will be calculated as a percent from trade volume',
    'InstrumentDetailsPanel.SessionStatus.descr': 'Status of the session, available values: Open, Close',
    'InstrumentDetailsPanel.SettlementDate.descr': 'Date when delivery has to take place',
    'InstrumentDetailsPanel.StrikePrice.descr': 'Price level of the underlier by which Option contract will be executed',
    'InstrumentDetailsPanel.SwapBuy.descr': 'Shows the swap value applied to overnight long positions of the symbol',
    'InstrumentDetailsPanel.SwapSell.descr': 'Shows the swap value applied to overnight short positions of the symbol',
    'InstrumentDetailsPanel.Symbol.descr': 'Name of the symbol chosen in the lookup',
    'InstrumentDetailsPanel.TickCoast.descr': 'Amount of base asset for one tick',
    'InstrumentDetailsPanel.TickSize.descr': 'Minimal change of the price',
    'InstrumentDetailsPanel.TradingBalance.descr': 'T+x settlement cycle',
    'InstrumentDetailsPanel.TradindBlockedOnSession.descr': 'Shows whether trading is blocked for current session',
    'InstrumentDetailsPanel.TradingStatus.descr': 'Trading status, available values: Open, Closed, Trading halt',
    'InstrumentDetailsPanel.Underlier.descr': 'Name of an asset which will be delivered. Available for Options and Futures.',
    'InstrumentDetailsPanel.VAT.descr': 'Value added tax paid from each fee operation',
    'InstrumentDetailsPanel.Volume.descr': 'Fee calculates on base of the volume of each trade',
    'InstrumentDetailsPanel.Yield.descr': 'Current yield value',
    'InstrumentDetailsPanel.ExchangeTrading': 'Trading exchange',
    'InstrumentDetailsPanel.ExchangeMarketData': 'Market data exchange',
    'InstrumentDetailsPanel.CustodialFee': 'Custody',
    'InstrumentDetailsPanel.CustodialFee.descr': 'Custody fees',
    'panel.accounts.AccountValue.descr': 'Total value of account',
    'panel.accounts.Asset.descr': 'Asset name',
    'panel.accounts.AvailableMargin.descr': 'Funds available for this account',
    'panel.accounts.Balance.descr': 'Balance of account',
    'panel.accounts.BalancePlusAllRisks.descr': 'Balance&All risks',
    'panel.accounts.BeginBalance.descr': 'Net profit/loss for today',
    'panel.accounts.BlockedByOrders.descr': 'Margin blocked for orders',
    'panel.accounts.BlockedByPamm.descr': 'Start capital invested for funds',
    'panel.accounts.CashBalance.descr': 'Cash balance',
    'panel.accounts.CopyTrading': 'Copy trading',
    'panel.accounts.ClientType.descr': 'Account owner last connected from ( .Net, Java, Mobile, Html)',
    'panel.accounts.CreditValue.descr': 'Credit funds available for trading',
    'panel.accounts.CurBalance.descr': 'Current equity (balance + unrealized P/L)',
    'panel.accounts.CurMargin.descr': 'Initial margin req. in relation to Balance&All risks',
    'panel.accounts.CurrentFundCapital.descr': 'Current capital invested for funds',
    'panel.accounts.Email.descr': 'Email address',
    'panel.accounts.EODTrailingDrawdownLevel.descr': 'Indicates minimal allowed Projected balance value (recalculated at the end of each day) that the account can have',
    'panel.accounts.RelativeDailyLossLimit.descr': 'Indicates the maximum allowed losses',
    'panel.accounts.Estimate value.descr': 'Value calculated from other asset',
    'panel.accounts.FundCapitalGain.descr': 'Difference between current and start fund capital',
    'panel.accounts.FundsUsedPercent.descr': 'Funds used in relation to Balance&All risks',
    'panel.accounts.InterestRate.descr': 'Annual interest rate on deposit',
    'panel.accounts.Leverage.descr': "Account's initial margin leverage",
    'panel.accounts.LockedBalance.descr': 'Locked balance for further withdrawing',
    'panel.accounts.LockedBy.descr': 'Locked by broker (request for quote for traders)',
    'panel.accounts.Login.descr': 'Account name',
    'panel.accounts.MaintMargin.descr': 'Maintenance margin req. in relation to Balance&All risks',
    'panel.accounts.MarginAvailableReal.descr': 'Margin available for the account',
    'panel.accounts.MarginRequirement.descr': 'Maintenance requirement for all opened positions',
    'panel.accounts.MarginStopOut.descr': 'Maintenance margin blocked for positions and orders',
    'panel.accounts.MarginStopTrade.descr': 'Account prevented from trading if balance is less than this level',
    'panel.accounts.MarginUsedNew.descr': 'Initial margin blocked for positions and orders',
    'panel.accounts.MarginWarning.descr': 'Warning margin message occurs if balance is less than this level',
    'panel.accounts.marginWarnPercent.descr': 'Margin warning level, %',
    'panel.accounts.MaxDailyLossLimit.descr': "Maximum value of day's net loss for account",
    'panel.accounts.MaxDailyProfit.descr': 'Maximum value of daily net profit for account',
    'panel.accounts.MaxDrawdownLevel.descr': 'Indicates minimal allowed Projected balance value that the account can have',
    'panel.accounts.MaximumLot.descr': 'Maximal quantity for one order',
    'InstrumentDetailsPanel.Account.descr': 'Name of the account',
    'panel.accounts.MaxOrderAmount.descr': 'Maximal order amount',
    'panel.accounts.MaxOrderCapital.descr': 'Maximal capital for one order',
    'panel.accounts.MaxOrdersPerDay.descr': 'Number of orders that user can place during a day',
    'panel.accounts.MaxPendingOrders.descr': 'Maximal pending orders count',
    'panel.accounts.MaxPendingOrdersValue.descr': 'Maximum cumulative pending orders value available for user',
    'panel.accounts.MaxPositionQtyPerSymbol.descr': 'Maximal quantity for one position per symbol',
    'panel.accounts.MaxPositions.descr': 'Maximal positions count',
    'panel.accounts.MaxWeekLostLimit.descr': "Maximum value of week's net loss for account",
    'panel.accounts.MinMargin.descr': 'Margin blocked for positions',
    'panel.accounts.GrossPNL.descr': 'Gross profit/loss for today',
    'panel.accounts.OptionPremiumReq.descr': 'Option premium for buy orders',
    'panel.accounts.OptionValue.descr': 'Current option value in portfolio',
    'panel.accounts.Orders.descr': 'Number of orders',
    'panel.accounts.Owner.descr': "Account's owner",
    'panel.accounts.Positions.descr': 'Number of positions',
    'panel.accounts.Profit.descr': 'Profit/loss of all opened positions',
    'panel.accounts.ProfitNet.descr': 'Net profit or loss on open positions',
    'panel.accounts.Role.descr': 'Fund type',
    'panel.accounts.Status.descr': 'Account owner user status (online, offline)',
    'panel.accounts.Collateral.descr': 'Сurrent collateral value for short positions',
    'panel.accounts.StocksOrdersReq.descr': 'Stock orders requirement',
    'panel.accounts.StocksValue.descr': 'Current stocks value in portfolio',
    'panel.accounts.stopOutPercent.descr': 'Maint. margin req% when stop out occurs',
    'panel.accounts.stopTradingPercent.descr': 'Stop trading level, %',
    'panel.accounts.TodayFees.descr': 'Amount of fees paid by account',
    'panel.accounts.TodayRebates.descr': 'Sum of all funds that have been received/paid by the broker for the creation/removal of liquidity for the current day',
    'panel.accounts.TodayTrades.descr': 'Count of today trades',
    'panel.accounts.TodayTurnover.descr': 'Turnover of the funds per day for present account',
    'panel.accounts.TodayVolume.descr': "Today's traded volume",
    'panel.accounts.TotalMaxPositionsQty.descr': 'Maximum sum qty of all positions in lots',
    'panel.accounts.TrailingDrawdownLevel.descr': 'Maximum drawdown level for account',
    'panel.accounts.UnsettledCash.descr': 'Profit/Loss, which will be credited to the account in accordance with T+n value cycle',
    'panel.accounts.UserGroup.descr': 'User group',
    'panel.accounts.UserID.descr': 'ID of user',
    'panel.accounts.UserLogin.descr': 'Login of user',
    'panel.accounts.WithdrawalAvailable.descr': 'Maximum balance available for withdrawn',
    'panel.accounts.CurrentDailyLoss.descr': 'Current net daily loss limit value for account',
    'panel.accounts.CurrentWeeklyLoss.descr': 'Current net weekly loss limit value for account',
    'panel.accounts.UnrealizedLoss.descr': 'Current unrealized loss value for account',
    'panel.accounts.UnrealizedLossLimit.descr': 'Maximum value of unrealized loss for account',
    'panel.accounts.VolumeLimitForALL.descr': 'The maximum summary volume of all positions and orders for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size)” or in money exposure',
    'panel.accounts.VolumeLimitForFOREX.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForEQUITIES.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForFUTURES.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForOPTIONS.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForEQUITIES_CFD.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForFORWARD.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForCFD_FUTURES.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForINDICIES.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForCRYPTO.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForSPREADBET.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForBOND.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForETF.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForTBILL.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForSPOT.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.VolumeLimitForCORPORATE.descr': 'The maximum summary volume of all positions and orders by instrument types for an account. Value is set in lots/contracts, in amount (Lots*Lot size or Lots*Tick cost/Tick size) or in money exposure',
    'panel.accounts.LossLimitPerTrade': 'Loss limit per trade',
    'panel.accounts.LossLimitPerTrade.descr': 'Indicates maximum loss level per single trade',
    'panel.accountDetails.ATIVA.ativa_intraday_total': 'Saldo total',
    'panel.accountDetails.ATIVA.ativa_intraday_disponivel': 'Saldo disponivel',
    'panel.accountDetails.ATIVA.ativa_intraday_d0': 'D+0',
    'panel.accountDetails.ATIVA.ativa_intraday_d1': 'D+1',
    'panel.accountDetails.ATIVA.ativa_intraday_d2': 'D+2',
    'panel.accountDetails.Kyrrex risks.AvailableBalance.descr': 'Available balance',
    'panel.accountDetails.Kyrrex risks.BlockedBalance.descr': 'Blocked balance',
    'panel.accountDetails.Groups.Cedro risks': 'Account details',
    'panel.accountDetails.Cedro risks.AVBA': 'Available Balance',
    'panel.accountDetails.Cedro risks.DAY_1_CREDITS': 'D1',
    'panel.accountDetails.Cedro risks.DAY_2_CREDITS': 'D2',
    'panel.accountDetails.Cedro risks.PRBA': 'Project Balance',
    'panel.accountDetails.Cedro risks.TOTL': 'Total',
    'panel.accountDetails.Cedro risks.LMAL': 'Total Limit (Day Trade)',
    'panel.accountDetails.Cedro risks.LMDI': 'Total balance (Day Trade)',
    'panel.accountDetails.Cedro risks.SLDTL': 'Total balance (Position)',
    'panel.accountDetails.Cedro risks.UNQL': 'Total limit (Position)',
    'Order.OrderStatus.STATUS_UNKNOWN': 'Unknown',
    'Order.OrderStatus.STATUS_PENDING_NEW': 'Pending new',
    'Order.OrderStatus.STATUS_NEW': 'Created',
    'Order.OrderStatus.STATUS_ACCEPTED': 'Accepted',
    'Order.OrderStatus.STATUS_PENDING_EXECUTION': 'Pending execution',
    'Order.OrderStatus.STATUS_REPLACED': 'Modified',
    'Order.OrderStatus.STATUS_PENDING_CANCEL': 'Pending cancel',
    'Order.OrderStatus.STATUS_PART_FILLED': 'Partially filled',
    'Order.OrderStatus.STATUS_FILLED': 'Filled',
    'Order.OrderStatus.STATUS_PENDING_REPLACE': 'Pending modification',
    'Order.OrderStatus.STATUS_WAITING_MARKET': 'Waiting for market',
    'Order.OrderStatus.STATUS_WAITING_CANCELLATION': 'Waiting cancellation',
    'Order.OrderStatus.STATUS_OFF_MARKET': 'Off market',
    'Order.OrderStatus.STATUS_UNPLACED': 'Unplaced',
    'pageNavigation.nextButton': 'Next',
    'pageNavigation.backButton': 'Back',
    'pageNavigation.doneButton': 'Done',
    'onBoarding.slide1.header1': 'Workspace control area',
    'onBoarding.slide1.text1': 'Workspace consists of windows and panels, their configurations, and on-screen location. Any changes to a workspace are saved automatically',
    'onBoarding.slide2.header1': 'Additional workspace actions',
    'onBoarding.slide2.text1': 'Clone – creates a duplicate of the active workspace </br> Rename – allows you to rename the active workspace',
    'onBoarding.slide2.text2': 'You can rename the workspace by double-clicking on its name',
    'onBoarding.slide3.header1': 'Workspace lock',
    'onBoarding.slide3.header2': 'Close workspace',
    'onBoarding.slide3.header3': 'Add workspace',
    'onBoarding.slide3.text1': 'You can lock and unlock your workspace by clicking the lock sign. If the workspace is locked, you won’t be able to close existing windows or add new ones',
    'onBoarding.slide3.text2': 'You can close a workspace by clicking the “X” button',
    'onBoarding.slide3.text3': 'You can create a new workspace by clicking the “+” button',
    'onBoarding.slide4.header1': 'Link windows by color',
    'onBoarding.slide4.text1': 'You can switch symbols automatically in all windows by changing it in only one place. In order to do so, click the Link icon and choose a matching color in all the relevant windows',
    'onBoarding.slide5.header1': 'Mouse trading',
    'onBoarding.slide5.text1': 'You can place trade orders directly on the chart. Activate Mouse Trading in the top right corner of the chart',
    'onBoarding.slide5.text2': 'You can set both the Quantity and Order validity in the widget which pops out after activation of the feature',
    'onBoarding.slide5.text3': 'Use drag&drop to modify orders and positions, add Stop Loss or Take Profit, or change the quantity',
    'onBoarding.slide6.header1': 'Margin risk',
    'onBoarding.slide6.text1': 'Before placing the order, you can review additional risk information by extending the Order Entry panel',
    'onBoarding.slide6.text2': 'Buttons to show/hide the panel',
    'onBoarding.slide7.header1': 'Windows Docking',
    'onBoarding.slide7.text1': 'Any window in the workspace can be moved and docked with other windows. Simply drag any window to where you want to dock it, until blue buttons with arrows appear',
    'onBoarding.slide8.header1': 'Instrument info',
    'onBoarding.slide8.text1': 'You can find additional information about each instrument by clicking on the instrument type icon',
    'onBoarding.slide9.header1': 'Account details widget',
    'onBoarding.slide9.text1': 'Drag&drop any field from the list to customize the info shown in the widget',
    'onBoarding.slide10.header1': 'Window linking by account',
    'onBoarding.slide10.text1': 'You can enable the linking of windows by account',
    'onBoarding.slide10.text2': 'You can enable orders and position filtering by account',
    'onBoarding.slide11.header1': 'Help',
    'onBoarding.slide11.header2': 'General Settings',
    'onBoarding.slide11.text1': 'For a full User Manual, click on the Help button',
    'onBoarding.slide11.text2': 'Here you can edit general settings, hotkeys, trading defaults and more',
    'onBoarding.slide12.header1': 'DONE!',
    'IndicatorGroup.Moving Average': 'Moving Average',
    MD: 'MD',
    REGRESSION: 'REGRESSION',
    Regression: 'Regression',
    SMMA: 'SMMA',
    PPMA: 'PPMA',
    SMA: 'SMA',
    EMA: 'EMA',
    'IndicatorGroup.Oscillators': 'Oscillators',
    MACD: 'MACD',
    BB: 'BB',
    BBF: 'BBF',
    'IndicatorGroup.Volume': 'Volume',
    RSI: 'RSI',
    PO: 'PO',
    CCI: 'CCI',
    Aroon: 'Aroon',
    KRI: 'KRI',
    'Kairi Relative Index': 'Kairi Relative Index',
    PPO: 'PPO',
    'Percentage Price Oscillator': 'Percentage Price Oscillator',
    RLW: 'RLW',
    '%R Larry Williams': '%R Larry Williams',
    ROC: 'ROC',
    'Rate of Change': 'Rate of Change',
    QStick: 'QStick',
    TSI: 'TSI',
    'True Strength Index': 'True Strength Index',
    SI: 'SI',
    ALLIGATOR: 'ALLIGATOR',
    MMA: 'MMA',
    'IndicatorGroup.Trend': 'Trend',
    STOCHASTIC: 'STOCHASTIC',
    OBV: 'OBV',
    'IndicatorGroup.Volatility': 'Volatility',
    ATR: 'ATR',
    CMO: 'CMO',
    SD: 'SD',
    SAR: 'SAR',
    ADX: 'ADX',
    'IndicatorGroup.Channels': 'Channels',
    KELTNER: 'KELTNER',
    CHANNEL: 'CHANNEL',
    MAE: 'MAE',
    LWMA: 'LWMA',
    MAS3: 'MAS3',
    'IndicatorGroup.Extra': 'Extra',
    Didi: '#hidden#',
    HiloEscandina: '#hidden#',
    StopATR: '#hidden#',
    Fractals3: 'Fractals3',
    Fractals5: 'Fractals5',
    'IndicatorGroup.Custom': 'Custom',
    'SUPER TREND': '#hidden#',
    'SUPER TREND.ShortName': 'SuperTrend',
    'SUPER TREND.FullName': 'Super trend',
    'EMA CROSS': '#hidden#',
    'EMA CROSS.ShortName': 'EMACross',
    'EMA CROSS.FullName': 'EMA cross',
    'MULTIMOV AVG': '#hidden#',
    'MULTIMOV AVG.ShortName': 'MultiMovAvg',
    'MULTIMOV AVG.FullName': 'Moving average multi timeframe',
    'SUPERTREND MTV3': '#hidden#',
    'SUPERTREND MTV3.ShortName': 'SuperTrendMTv3',
    'SUPERTREND MTV3.FullName': 'Super trend multi timeframe',
    'Kaufman adaptive moving average': 'Kaufman adaptive moving average',
    'Multi EMA': 'Multi EMA',
    'Self-Adjusting Alpha with Fractals Energy': 'RSI Self-Adjusting Alpha with Fractals Energy',
    'disconnectMessageBox.Header': 'Connection',
    'disconnectMessageBox.Text': 'The connection will be dropped in {0} seconds due to inactivity. Click "Logout" to drop the connection now, or click "Cancel" to stay logged in.',
    'disconnectMessageBox.logoutButton': 'Logout',
    'disconnectMessageBox.cancelButton': 'Cancel',
    'disconnectMessageBox.logoutButton.descr': 'If click, the connection will drop now due to inactivity.',
    'disconnectMessageBox.cancelButton.descr': 'If click, the connection will not drop due to inactivity.',
    'Max. concurrent connections': 'Max. concurrent connections',
    'Max. concurrent connections reason': 'The maximum limit for concurrent connections has been reached',
    ChangeValidityToTIF: '#hidden#',
    'Resources.Property.Opening market orders are forbidden': 'Opening market orders are forbidden',
    'Session has expired. Please login.': 'Session has expired. Please login.',
    'The total limit of alerts has been reached.': 'The total limit of alerts has been reached.',
    'Alert cannot be created due to wrong node.': 'Alert could not be accepted. Please contact support team',
    'potrfolio.management.headerLabel.MyPortfolio': 'My Portfolio',
    'potrfolio.metrics.textMetrics': 'Metrics',
    'potrfolio.metrics.textMetricsTitle': 'Portfolio growth',
    'potrfolio.metrics.addpanel.header': 'Add metrics',
    'potrfolio.metrics.addpanel.buttonText': 'Add',
    'alert.event.header.created': 'Created',
    'alert.event.text.created': 'Alert {0} {1} was created',
    'alert.event.header.modified': 'Modified',
    'alert.event.text.modified': 'Alert {0} {1} was modified',
    'alert.event.header.stopped': 'Stopped',
    'alert.event.text.stopped': 'Alert {0} {1} was stopped',
    'alert.event.header.executed': 'Executed',
    'alert.event.text.executed': 'Alert {0} {1} was executed',
    'alert.event.header.expired': 'Expired',
    'alert.event.text.expired': 'Alert {0} {1} expired',
    'alert.event.header.deleted': 'Deleted',
    'alert.event.text.deleted': 'Alert {0} {1} was deleted',
    'alert.event.header.restarted': 'Restarted',
    'alert.event.text.restarted': 'Alert {0} {1} was restarted',
    'alert.specifyOrder.Error': 'Specify order parameters to create an alert with Action = Place order',
    'alert.specifyAlertType.Error': 'For instruments with the Forex type, it is impossible that "Alert type = Volume". Specify another parameter',
    'alert.specifyExpirationTime.Error': 'Expiration date less then current time',
    'alert.specifyAlertPanelBtn.Error': 'The maximum number of alerts for the user has been reached.',
    'screen.products.Products': 'Subscriptions',
    'screen.products.Categories': 'Subscriptions',
    'screen.products.Categories.NoData': 'No Subscriptions',
    'screen.products.toolbar.filterCB.All': 'All',
    'screen.products.toolbar.filterCB.Subscribed': 'Subscribed',
    'screen.products.toolbar.filterCB.AvailableForSubscription': 'Available for subscription',
    'screen.products.toolbar.filterCB.Processing': 'Processing',
    'screen.products.SubscribeRequestConfirmScreen.header': 'Subscription',
    'screen.products.SubscribeRequestConfirmScreen.text': 'You are applying for a subscription.\nIt may take some time to process the request if documents verification is required.\nThe current status of your request can be checked in the Requests tab.\nIf the request is processed successfully, the button on the card will change to Subscribed',
    'screen.products.SubscribeRequestConfirmScreen.OkBtnText': 'Ok',
    'screen.products.Request': 'Request',
    'screen.products.Request.ToolTip': 'Contains outcome subscribe and unsubscribe requests',
    'screen.products.ButtonSubscribe': 'Subscribe',
    'screen.products.ButtonSubscribed': 'Subscribed',
    'screen.products.CancelSubscribeRequestButton.text': 'Cancel request',
    'screen.products.CancelSubscribeRequestButton.tt': 'Request <date/time> <requestType> <productName> is being processed.\nClick the button to cancel the request.',
    'screen.products.ButtonUnsubscribe': 'Unsubscribe',
    'screen.documets.Header': 'Sign documents',
    'screen.documets.Button.ToolTip': 'Required fields are missed',
    'screen.products.ButtonUnsubscribe.Click': 'As soon as the request is processed, you will be unsubscribed immediately, without waiting for the end of the period for which the fee was charged.',
    'panel.product.ProductName': 'Subscription',
    'panel.product.ProductType': 'Request type',
    'panel.product.ProductStatus': 'Request status',
    'panel.product.ProductStatus.Value.None': 'None',
    'panel.product.ProductStatus.Value.Processing': 'Processing',
    'panel.product.ProductStatus.Value.Approved': 'Approved',
    'panel.product.ProductStatus.Value.Rejected': 'Rejected',
    'panel.product.ProductStatus.Value.Canceled': 'Canceled',
    'panel.product.Comment': 'Comment',
    'panel.product.DateTime': 'Date/Time',
    'panel.product.Cancel': 'Cancel',
    'panel.Products.Request.CancelRequest': 'Cancel request',
    'panel.Products.Request.CancelRequest.Message': 'Do you want to cancel request:\n<date/time> <requestType> <productName>?',
    'Product is no longer relevant for use': 'Product is no longer relevant for use',
    'panel.Products.Unsubscribe.PopupMessage': 'You are applying to unsubscribe.\nIt may take some time to process the request. While processing, the subscription remains in effect and you can enjoy its benefits.\nThe current status of your request can be checked in the Requests tab.\nDo you want to continue?',
    'AdditionalProperty.Unsubscribe': 'Unsubscribe',
    'Bid/Ask prices are not available for the instrument.': 'Bid/Ask prices are not available for the instrument.',
    'RangeControl.StartDaySlide.Tooltip': 'Start day time',
    'RangeControl.EndDaySlide.Tooltip': 'End day time',
    'AccountDetailesPanel.quickTable.Name': 'Name',
    'AccountDetailesPanel.quickTable.Value': 'Value',
    'corporateActionType.BonusIssue': 'Bonus shares',
    'corporateActionType.StockDividends': 'Stock dividends',
    'corporateActionType.CashDividends': 'Cash dividends',
    'corporateActionType.RightsIssue': 'Rights issue',
    'corporateActionType.SpinOff': 'Spin off',
    'InstrumentDetailsPanel.OrderPerLot': 'Order per lot',
    'InstrumentDetailsPanel.OrderPerLotBUY.descr': 'Fee paid for each lot which was traded (Buy)',
    'InstrumentDetailsPanel.OrderPerLotBUY': 'Order per lot (Buy)',
    'InstrumentDetailsPanel.OrderPerLotCLOSE.descr': 'Fee calculates on base of the volume of each trade (Close)',
    'InstrumentDetailsPanel.OrderPerLotCLOSE': 'Order per lot (Close)',
    'InstrumentDetailsPanel.OrderPerLotCLOSEBUY.descr': 'Fee paid for each lot which was traded (Close Buy)',
    'InstrumentDetailsPanel.OrderPerLotCLOSEBUY': 'Order per lot (Close Buy)',
    'InstrumentDetailsPanel.OrderPerLotCLOSESELL.descr': 'Fee paid for each lot which was traded (Close Sell)',
    'InstrumentDetailsPanel.OrderPerLotCLOSESELL': 'Order per lot (Close Sell)',
    'InstrumentDetailsPanel.OrderPerLotOPEN.descr': 'Fee calculates on base of the volume of each trade (Open)',
    'InstrumentDetailsPanel.OrderPerLotOPEN': 'Order per lot (Open)',
    'InstrumentDetailsPanel.OrderPerLotOPENBUY.descr': 'Fee paid for each lot which was traded (Open Buy)',
    'InstrumentDetailsPanel.OrderPerLotOPENBUY': 'Order per lot (Open Buy)',
    'InstrumentDetailsPanel.OrderPerLotOPENSELL.descr': 'Fee paid for each lot which was traded (Open Sell)',
    'InstrumentDetailsPanel.OrderPerLotOPENSELL': 'Order per lot (Open Sell)',
    'InstrumentDetailsPanel.OrderPerLotOPENSHORT.descr': 'Fee paid for each lot which was traded (Open Short)',
    'InstrumentDetailsPanel.OrderPerLotOPENSHORT': 'Order per lot (Open Short)',
    'InstrumentDetailsPanel.OrderPerLotSELL.descr': 'Fee paid for each lot which was traded (Sell)',
    'InstrumentDetailsPanel.OrderPerLotSELL': 'Order per lot (Sell)',
    'InstrumentDetailsPanel.OrderPerLotSHORT': 'Order per lot (Short)',
    'InstrumentDetailsPanel.OrderVolume.descr': 'Fee paid per each part of order volume that was filled',
    'InstrumentDetailsPanel.OrderVolume': 'Order volume',
    'InstrumentDetailsPanel.OptionExercise.descr': 'Will be charged in case of option exercise per each lot of position',
    'InstrumentDetailsPanel.OptionExerciseSELL': 'Option exercize fee(Short)',
    'InstrumentDetailsPanel.OptionExerciseSELL.descr': 'Will be charged in case of option exercise per each lot of short position',
    'InstrumentDetailsPanel.OptionExerciseBUY': 'Option exercize fee(Long)',
    'InstrumentDetailsPanel.OptionExerciseBUY.descr': 'Will be charged in case of option exercise per each lot of long position',
    'InstrumentDetailsPanel.PerFill.descr': 'Fee paid for each fill of the order',
    'InstrumentDetailsPanel.PerFill': 'Per fill',
    'InstrumentDetailsPanel.PerLot.descr': 'Fee paid for each lot which was traded',
    'InstrumentDetailsPanel.PerLot': 'Fill per lot',
    'InstrumentDetailsPanel.PerLotBUY.descr': 'Fee paid for each lot which was traded (Buy)',
    'InstrumentDetailsPanel.PerLotBUY': 'Fill per lot (Buy)',
    'InstrumentDetailsPanel.PerLotCLOSE.descr': 'Fee calculates on base of the volume of each trade (Close)',
    'InstrumentDetailsPanel.PerLotCLOSE': 'Fill per lot (Close)',
    'InstrumentDetailsPanel.PerLotCLOSEBUY.descr': 'Fee paid for each lot which was traded (Close Buy)',
    'InstrumentDetailsPanel.PerLotCLOSEBUY': 'Fill per lot (Close Buy)',
    'InstrumentDetailsPanel.PerLotCLOSESELL.descr': 'Fee paid for each lot which was traded (Close Sell)',
    'InstrumentDetailsPanel.PerLotCLOSESELL': 'Fill per lot (Close Sell)',
    'InstrumentDetailsPanel.PerLotOPEN.descr': 'Fee calculates on base of the volume of each trade (Open)',
    'InstrumentDetailsPanel.PerLotOPEN': 'Fill per lot (Open)',
    'InstrumentDetailsPanel.PerLotOPENBUY.descr': 'Fee paid for each lot which was traded (Open Buy)',
    'InstrumentDetailsPanel.PerLotOPENBUY': 'Fill per lot (Open Buy)',
    'InstrumentDetailsPanel.PerLotOPENSELL.descr': 'Fee paid for each lot which was traded (Open Sell)',
    'InstrumentDetailsPanel.PerLotOPENSELL': 'Fill per lot (Open Sell)',
    'InstrumentDetailsPanel.PerLotOPENSHORT.descr': 'Fee paid for each lot which was traded (Open Short)',
    'InstrumentDetailsPanel.PerLotOPENSHORT': 'Fill per lot (Open Short)',
    'InstrumentDetailsPanel.PerLotSELL.descr': 'Fee paid for each lot which was traded (Sell)',
    'InstrumentDetailsPanel.PerLotSELL': 'Fill per lot (Sell)',
    'InstrumentDetailsPanel.PerLotSHORT.descr': 'Fee paid for each lot which was traded (Short)',
    'InstrumentDetailsPanel.PerLotSHORT': 'Fill per lot (Short)',
    'InstrumentDetailsPanel.PerOrderVolume.descr': 'Fee paid per each part of order volume that was filled',
    'InstrumentDetailsPanel.PerOrderVolumeBUY.descr': 'Fee paid per each part of order volume that was filled (Buy), %',
    'InstrumentDetailsPanel.PerOrderVolumeBUY': 'Order volume (Buy), %',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSE.descr': 'Fee paid per each part of order volume that was filled (Close), %',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSE': 'Order volume (Close), %',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSEBUY.descr': 'Fee paid per each part of order volume that was filled (Close Buy), %',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSEBUY': 'Order volume (Close Buy), %',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSESELL.descr': 'Fee paid per each part of order volume that was filled (Close Sell), %',
    'InstrumentDetailsPanel.PerOrderVolumeCLOSESELL': 'Order volume (Close Sell), %',
    'InstrumentDetailsPanel.PerOrderVolumeOPEN.descr': 'Fee paid per each part of order volume that was filled (Open), %',
    'InstrumentDetailsPanel.PerOrderVolumeOPEN': 'Order volume (Open), %',
    'InstrumentDetailsPanel.PerOrderVolumeOPENBUY.descr': 'Fee paid per each part of order volume that was filled (Open Buy), %',
    'InstrumentDetailsPanel.PerOrderVolumeOPENBUY': 'Order volume (Open Buy), %',
    'InstrumentDetailsPanel.PerOrderVolumeOPENSELL.descr': 'Fee paid per each part of order volume that was filled (Open Sell), %',
    'InstrumentDetailsPanel.PerOrderVolumeOPENSELL': 'Order volume (Open Sell), %',
    'InstrumentDetailsPanel.PerOrderVolumeOPENSHORT.descr': 'Fee paid per each part of order volume that was filled (Open Short), %',
    'InstrumentDetailsPanel.PerOrderVolumeOPENSHORT': 'Order volume (Open Short), %',
    'InstrumentDetailsPanel.PerOrderVolumeSELL.descr': 'Fee paid per each part of order volume that was filled (Sell), %',
    'InstrumentDetailsPanel.PerOrderVolumeSELL': 'Order volume (Sell), %',
    'InstrumentDetailsPanel.PerOrderVolumeSHORT.descr': 'Fee paid per each part of order volume that was filled (Short), %',
    'InstrumentDetailsPanel.PerOrderVolumeSHORT': 'Order volume (Short), %',
    'InstrumentDetailsPanel.PerVolumeBUY.descr': 'Fee calculates on base of the volume of each trade (Buy), %',
    'InstrumentDetailsPanel.PerVolumeBUY': 'Fill volume (Buy), %',
    'InstrumentDetailsPanel.PerVolumeCLOSE.descr': 'Fee calculates on base of the volume of each trade (Close), %',
    'InstrumentDetailsPanel.PerVolumeCLOSE': 'Fill volume (Close), %',
    'InstrumentDetailsPanel.PerVolumeCLOSEBUY.descr': 'Fee calculates on base of the volume of each trade (Close Buy), %',
    'InstrumentDetailsPanel.PerVolumeCLOSEBUY': 'Fill volume (Close Buy), %',
    'InstrumentDetailsPanel.PerVolumeCLOSESELL.descr': 'Fee calculates on base of the volume of each trade (Close Sell), %',
    'InstrumentDetailsPanel.PerVolumeCLOSESELL': 'Fill volume (Close Sell), %',
    'InstrumentDetailsPanel.PerVolumeOPEN.descr': 'Fee calculates on base of the volume of each trade (Open), %',
    'InstrumentDetailsPanel.PerVolumeOPEN': 'Fill volume (Open), %',
    'InstrumentDetailsPanel.PerVolumeOPENBUY.descr': 'Fee calculates on base of the volume of each trade (Open Buy), %',
    'InstrumentDetailsPanel.PerVolumeOPENBUY': 'Fill volume (Open Buy), %',
    'InstrumentDetailsPanel.PerVolumeOPENSELL.descr': 'Fee calculates on base of the volume of each trade (Open Sell), %',
    'InstrumentDetailsPanel.PerVolumeOPENSELL': 'Fill volume (Open Sell), %',
    'InstrumentDetailsPanel.PerVolumeOPENSHORT.descr': 'Fee calculates on base of the volume of each trade (Open Short), %',
    'InstrumentDetailsPanel.PerVolumeOPENSHORT': 'Fill volume (Open Short), %',
    'InstrumentDetailsPanel.PerVolumeSELL.descr': 'Fee calculates on base of the volume of each trade (Sell), %',
    'InstrumentDetailsPanel.PerVolumeSELL': 'Fill volume (Sell), %',
    'InstrumentDetailsPanel.PerVolumeSHORT.descr': 'Fee calculates on base of the volume of each trade (Short), %',
    'InstrumentDetailsPanel.PerVolumeSHORT': 'Fill volume (Short), %',
    'system.ChangePassLow': "The new password should contain at least {0} characters and shouldn't contain a character (&). Please try again",
    'system.ChangePassBase': "The new password should have at least {0} characters, 1 upper case character, 1 lower case character, 1 number and shouldn't contain a character (&). Please try again.",
    'system.ChangePassHigh': "The new password should have at least {0} characters, 1 upper case character, 1 lower case character, 1 number, 1 special character(e.g.@#S%) and shouldn't contain a character (&). Please try again.",
    'system.TooLongPass': 'Your password can be up to {0} characters long',
    'screen.modifyOrder.modifyPosition.ModifyQuantity': 'Modify Quantity',
    'screen.modifyOrder.modifyPosition.TargetProductType': 'Target product type',
    'screen.MonthCalendarPopupForm.Apply': 'Apply',
    'screen.modifyOrder.modifyPosition.ModifyQuantity.SlTpInfo': 'Please note: SL/TP orders will be cancelled after product type modification',
    'general.trading.qtyMoreTodayTradedValue': 'Value cannot be greater than today traded qty',
    'general.trading.noPriceForMarketOrder': 'No price for market order',
    'general.trading.noQuantityAvailable': 'No quantity available to modify.',
    'reports.Product type modified': 'Product type modified',
    'screen.changepass.wrongKey': 'Wrong recovery key',
    'screen.changepass.lockedAfterBrut': 'User is locked after brute force!',
    'screen.changepass.wrongOldOrNewPassword': 'Incorrect current password',
    'screen.changepass.pwdMustBeDifferent': 'Incorrect new password: you cannot use the same password',
    'screen.changepass.errorEmail': 'Invalid email for remember your password.',
    'screen.changepass.wrongLogin': 'Wrong login!',
    'reports.Product type modification rejected': 'Product type modification rejected',
    'InstrumentDetailsPanel.ETB': 'ETB',
    'InstrumentDetailsPanel.ETB.descr': 'Easy-to-borrow (ETB) means that there’s a supply of stock and generally would be available for short',
    'portfolio.metrics.TapeRolling': 'Tape rolling',
    'portfolio.metrics.contextMenu.Customize': 'Customize',
    'portfolio.metrics.CustomizeBarPanel.header': 'Customize metrics bar',
    'portfolio.metrics.CustomizeBarPanel.okBtn': 'OK',
    'portfolio.metrics.CustomizeBarPanel.closeBtn': 'Close',
    'panel.accounts.MaxRelativeDrawDownLevel': 'Max relative drawdown level',
    'panel.accounts.MaxRelativeDrawDownLevel.descr': 'Indicates minimal allowed Projected balance value (calculated relative to current balance) that the account can have',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Max relative drawdown level was reached': 'Max relative drawdown level was reached',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.EOD trailing drawdown level was reached': 'EOD trailing drawdown level was reached',
    'panel.accounts.accountDetails.RiskManagement.TradingStatus.Relative daily loss limit was reached': 'Relative daily loss limit was reached',
    'Not selected order type': 'Not selected order type',
    'panel.AlgorithmMarketConsensusPanel': 'Market consensus',
    'panel.AlgorithmMarketConsensusPanel.TopLong': 'Top Long',
    'panel.AlgorithmMarketConsensusPanel.TopShort': 'Top Short',
    'panel.AlgorithmMarketConsensusPanel.menu.MoreOneSymbolInfoDisabled.tt': 'More than one symbol selected',
    'panel.AlgorithmMarketConsensusPanel.menu.NoSymbolInfoDisabled.tt': 'No symbols selected',
    'panel.AlgorithmMarketConsensusPanel.Settings.ContextMenu': 'Settings...',
    'panel.AlgorithmMarketConsensusPanel.Settings.Screen': 'Market consensus settings',
    'panel.AlgorithmMarketConsensusPanel.noData': 'Waiting for a data...',
    'panel.GainersLosersPanel': 'Gainers/Losers list',
    'panel.GainersLosersPanel.Item.Change': 'Change %',
    'panel.GainersLosersPanel.Item.Volume': 'Volume',
    'panel.GainersLosersPanel.Item.Last': 'Last',
    'panel.GainersLosersPanel.Item.Last.descr': 'Last trade price',
    'panel.GainersLosersPanel.Item.ChangeGainers.descr': 'Displays the percentage on which the increase occurred',
    'panel.GainersLosersPanel.Item.ChangeLosers.descr': 'Displays the percentage on which the decrease occurred',
    'panel.GainersLosersPanel.Item.GainersSymbols.descr': 'Symbols that show an increase in price',
    'panel.GainersLosersPanel.Item.GainersSymbols': 'Gainers',
    'panel.GainersLosersPanel.Item.LosersSymbols.descr': 'Symbols showing a decrease in price',
    'panel.GainersLosersPanel.Item.LosersSymbols': 'Losers',
    'panel.GainersLosersPanel.Item.Symbol': 'Symbol',
    'panel.GainersLosersPanel.Property.GainersColor': 'Gainers symbol color',
    'panel.GainersLosersPanel.Property.LosersColor': 'Losers symbol color',
    'panel.HeatmapPanel': 'Heatmap',
    'panel.HeatmapPanel.Settings.Header': 'Heatmap - settings',
    'panel.HeatmapPanel.Settings.GradationScale': 'Gradation scale',
    'panel.HeatmapPanel.Settings.IndustryLevel': 'Industry level',
    'panel.HeatmapPanel.Settings.SectorLevel': 'Sector level',
    'panel.HeatmapPanel.Settings.SymbolLevel': 'Symbol level',
    'panel.HighLowPanel.noData': 'Waiting for a data...',
    'panel.HighLowPanel': 'High/Low list',
    'panel.HighLowPanel.menu.ClearTable': 'Clear table',
    'panel.HighLowPanel.Item.Symbol': 'Symbol',
    'panel.HighLowPanel.Item.Symbol.descr': 'Symbol name',
    'panel.HighLowPanel.Item.Change': 'Change',
    'panel.HighLowPanel.Item.Change.descr': 'The value shows how much the price changed in absolute terms',
    'panel.HighLowPanel.Item.ChangePr': 'Change %',
    'panel.HighLowPanel.Item.ChangePr.descr': 'The value shows the percentage change in price',
    'panel.HighLowPanel.Item.Price': 'Price',
    'panel.HighLowPanel.Item.Price.descr': 'The price at which there was a breakout or touch',
    'panel.HighLowPanel.Item.Time': 'Time',
    'panel.HighLowPanel.Item.Time.descr': 'Quotes time',
    'panel.HighLowPanel.Item.Volume': 'Volume',
    'panel.HighLowPanel.Item.Volume.descr': 'Cumulative volume',
    'panel.HighLowPanel.Item.Exchange': 'Exchange',
    'panel.HighLowPanel.Item.Exchange.descr': 'Exchange',
    'reports.POSITIONS_PANEL_DB_CLICK': 'Desktop:Positions Panel DC',
    'reports.ORDERS_PANEL_DB_CLICK': 'Desktop:Orders Panel DC',
    'reports.CHART_VISUAL_DB_CLICK': 'Desktop:Chart Visual DC',
    'reports.PORTFOLIO_MANAGER': 'Desktop: Portfolio Manager',
    'reports.POSITIONS_PANEL_CONTEXT_MENU': 'Desktop:Positions CM',
    'reports.ORDERS_PANEL_CONTEXT_MENU': 'Desktop:Working orders CM',
    'reports.CHART_VISUAL_CONTEXT_MENU': 'Desktop:Chart Visual CM',
    'reports.WEB_CHART_VISUAL_DB_CLICK': 'WEB:Chart Visual Trading DC',
    'reports.WEB_ORDERS_PANEL_DB_CLICK': 'WEB:Orders Panel DC',
    'reports.WEB_POSITIONS_PANEL_DB_CLICK': 'WEB:Positions Panel DC',
    'reports.WEB_PORTFOLIO_MANAGER': 'WEB:Portfolio Manager',
    'reports.WEB_CHART_VISUAL_CONTEXT_MENU': 'WEB:Chart Visual Trading CM',
    'reports.WEB_ORDERS_PANEL_CONTEXT_MENU': 'WEB:Orders Panel CM',
    'reports.WEB_POSITIONS_PANEL_CONTEXT_MENU': 'WEB:Positions Panel CM',
    'reports.MOB_PORTFOLIO_MANAGER': 'MOB:Portfolio Manager',
    'reports.SERVER_OTHER': 'SERVER:Other',
    'reports.MOB_OPTIONS_PANEL': 'MOB:Options Panel',
    'property.TopLongColors': 'Top Long colors ',
    'property.TopShortColors': 'Top Short colors',
    'chart.infoWindow.Items.Countdown': 'Countdown:',
    'chart.infoWindow.Items.Funding': 'Funding:',
    'chart.infoWindow.Items.Countdown.VisibilityByDefault': '#hidden#',
    'chart.infoWindow.Items.Funding.VisibilityByDefault': '#hidden#',
    'chart.infoWindow.Items.Bars.VisibilityByDefault': '#hidden#',
    'chart.infoWindow.Items.Date.VisibilityByDefault': '#hidden#',
    'chart.infoWindow.Items.Time.VisibilityByDefault': '#hidden#',
    'chart.infoWindow.Items.Change.VisibilityByDefault': '#hidden#',
    'chart.infoWindow.Items.HighLow.VisibilityByDefault': '#hidden#',
    'chart.openOEByDefault': '#hidden#',
    'panel.watchlist.CurrentFundingRate.descr': 'If the funding rate is positive, longs pay shorts. If negative, shorts pay longs',
    'panel.watchlist.CurrentFundingRate': 'Current funding rate',
    'panel.watchlist.NextFundingSettlement': 'Next Funding Settlement',
    'property.IsCountdownVisible': 'Countdown',
    'property.IsFundingVisible': 'Funding',
    'panel.detail.Countdown': '#hidden#',
    'panel.detail.Funding': '#hidden#',
    'FlagSM.Message': 'Security is under Surveillance Measure{0}, please make sure you would like to continue.',
    'FlagSM.Short term ASM Flag.11': 'Stage 1 Short Term ASM scrip',
    'FlagSM.Short term ASM Flag.12': 'Stage 2 Short Term ASM scrip',
    'FlagSM.Long term ASM Flag.11': 'Stage 1 Long Term ASM scrip',
    'FlagSM.Long term ASM Flag.12': 'Stage 2 Long Term ASM scrip',
    'FlagSM.Long term ASM Flag.13': 'Stage 3 Long Term ASM scrip',
    'FlagSM.Long term ASM Flag.14': 'Stage 4 Long Term ASM scrip',
    'FlagSM.ESM Flag.11': 'Stage 1 ESM scrip',
    'FlagSM.ESM Flag.12': 'Stage 2 ESM scrip',
    'FlagSM.GSM Flag.10': 'Scrips in GSM',
    'FlagSM.GSM Flag.11': 'Stage 1 GSM scrip',
    'FlagSM.GSM Flag.12': 'Stage 2 GSM scrip',
    'FlagSM.GSM Flag.13': 'Stage 3 GSM scrip',
    'FlagSM.GSM Flag.14': 'Stage 4 GSM scrip',
    'FlagSM.GSM Flag.16': 'Stage 1 GSM scrip as per notice no. 20170807-31',
    'FlagSM.GSM Flag.299': 'Shortlisted under GSM',
    'FlagSM.GSM Flag.21': 'GSM stage 1',
    'FlagSM.GSM Flag.22': 'GSM stage 2',
    'FlagSM.GSM Flag.23': 'GSM stage 3',
    'FlagSM.GSM Flag.24': 'GSM stage 4',
    'FlagSM.GSM Flag.25': 'GSM stage 5',
    'FlagSM.GSM Flag.26': 'GSM stage 6',
    'FlagSM.GSM Flag.211': 'STASM stage 1',
    'FlagSM.GSM Flag.212': 'STASM stage 2',
    'FlagSM.GSM Flag.213': 'LTASM stage 1',
    'FlagSM.GSM Flag.214': 'LTASM stage 2',
    'FlagSM.GSM Flag.215': 'LTASM stage 3',
    'FlagSM.GSM Flag.216': 'LTASM stage 4',
    'FlagSM.GSM Flag.220': 'IBC receipt',
    'FlagSM.GSM Flag.221': 'ASM IBC stage 1',
    'FlagSM.GSM Flag.222': 'ASM IBC stage 2',
    'FlagSM.GSM Flag.223': 'ASM ICA stage 1',
    'FlagSM.GSM Flag.224': 'ASM ICA stage 2',
    'FlagSM.GSM Flag.225': 'High promoter encumbrance',
    'FlagSM.GSM Flag.226': 'High/Non promoter encumbrance',
    'FlagSM.GSM Flag.230': 'SMS info. list',
    'FlagSM.GSM Flag.231': 'SMS watch list',
    'FlagSM.GSM Flag.232': 'Unsolicited video',
    'FlagSM.GSM Flag.233': 'Unsolicited video and LTASM st. 4',
    'FlagSM.GSM Flag.234': 'ESM stage 1',
    'FlagSM.GSM Flag.235': 'ESM stage 2',
    'FlagSM.GSM Flag.236': 'ESM 1 and GSM 0',
    'FlagSM.GSM Flag.237': 'ESM 2 and GSM 0',
    'FlagSM.GSM Flag.250': 'LTASM 1 and GSM 0',
    'FlagSM.GSM Flag.251': 'LTASM 2 and GSM 0',
    'FlagSM.GSM Flag.252': 'LTASM 3 and GSM 0',
    'FlagSM.GSM Flag.253': 'LTASM 4 and GSM 0',
    'FlagSM.GSM Flag.254': 'STASM 1 and GSM 0',
    'FlagSM.GSM Flag.255': 'STASM 2 and GSM 0',
    'FlagSM.GSM Flag.256': 'High/Non promoter encumbrance and GSM 0',
    'FlagSM.GSM Flag.257': 'High promoter encumbrance and GSM 0',
    'FlagSM.GSM Flag.258': 'IBC 1 and GSM 0',
    'FlagSM.GSM Flag.259': 'IBC 2 and GSM 0',
    'FlagSM.GSM Flag.260': 'ICA 1 and GSM 0',
    'FlagSM.GSM Flag.261': 'ICA 2 and GSM 0',
    'FlagSM.GSM Flag.262': 'IBC - receipt and GSM 0',
    'FlagSM.GSM Flag.263': 'GSM 1 and IBC - receipt',
    'FlagSM.GSM Flag.264': 'GSM 2 and IBC - receipt',
    'FlagSM.GSM Flag.265': 'GSM 3 and IBC - receipt',
    'FlagSM.GSM Flag.266': 'GSM 4 and IBC - receipt',
    'panel.ClosePositionsMutually': 'Close positions mutually',
    'panel.ClosePositionsMutually.Symbol': 'Symbol:',
    'panel.ClosePositionsMutually.PositionID': 'Position',
    'panel.ClosePositionsMutually.Side': 'Side',
    'panel.ClosePositionsMutually.Quantity': 'Quantity',
    'panel.ClosePositionsMutually.Total': 'Total:',
    'panel.ClosePositionsMutually.CloseBtn': 'Close',
    'panel.ClosePositionsMutually.CancelBtn': 'Cancel',
    'panel.General.FullscreenModeBtn.Tooltip': 'Fullscreen mode',
    'panel.General.ExploreTheApp.Text': 'Please, log in to start trading',
    'panel.General.ExploreTheApp.LinkPartText': 'log in',
    'TvTradingPlatform.AccountSummary.Title': 'Account summary',
    'TvTradingPlatform.Quantity': 'Quantity',
    'TvTradingPlatform.Positions.AvgFillPrice': 'Avg fill price',
    'TvTradingPlatform.TimeFrame.1D.title': '1D',
    'TvTradingPlatform.TimeFrame.1D.desciption': '1 day in 1 minutes intervals',
    'TvTradingPlatform.TimeFrame.5D.title': '5D',
    'TvTradingPlatform.TimeFrame.5D.desciption': '5 days in 5 minutes intervals',
    'TvTradingPlatform.TimeFrame.1M.title': '1M',
    'TvTradingPlatform.TimeFrame.1M.desciption': '1 month in 30 minutes intervals',
    'TvTradingPlatform.TimeFrame.3M.title': '3M',
    'TvTradingPlatform.TimeFrame.3M.desciption': '3 months in 1 hour intevals',
    'TvTradingPlatform.TimeFrame.6M.title': '6M',
    'TvTradingPlatform.TimeFrame.6M.desciption': '6 months in 4 hour intevals',
    'TvTradingPlatform.TimeFrame.1Y.title': '1Y',
    'TvTradingPlatform.TimeFrame.1Y.desciption': '1 year in 1 week intervals',
    'TvTradingPlatform.TimeFrame.5Y.title': '5Y',
    'TvTradingPlatform.TimeFrame.5Y.desciption': '5 years in 1 week intervals',
    'TvTradingPlatform.OrderConfirmation.Exchange': 'Exchange',
    'TvTradingPlatform.OrderConfirmation.TimeInForce': 'Time in force',
    'TvTradingPlatform.OrderType.StopLimit': 'Stop Limit',
    'property.ImpliedVolatility': 'Implied Volatility',
    'property.ImpliedVolatilityPercent': 'Implied Volatility, %',
    'property.OP_IV_tt': 'Calculation type',
    'property.Clear_tt': 'Clear',
    'property.OptionPrice': 'Option price',
    'property.DaysTillExpiration': 'Days till expiration',
    'property.Put': 'Put',
    'property.Puts': 'Puts',
    'property.Call': 'Call',
    'property.Calls': 'Calls',
    'property.CallsAndPuts': 'Calls&Puts',
    'property.Delta': 'Delta',
    'property.Gamma': 'Gamma',
    'property.Theta': 'Theta',
    'property.Vega': 'Vega',
    'property.Rho': 'Rho',
    'property.TheoreticalOptionPrice': 'Theoretical option price',
    'property.TheoreticalPrice': 'Theoretical price',
    'property.Calculate': 'Calculate',
    'screen.OptionCalculator': 'Option calculator',
    'Web.mobile.active.WorkingOrders': 'Working',
    'Web.mobile.active.WorkingOrders.EmptyPlaceholder': 'No orders',
    'Web.mobile.active.FilledOrders': 'Filled',
    'Web.mobile.active.HistoryOrders': 'History',
    'Web.mobile.active.Positions': 'Positions',
    'Web.mobile.active.Positions.EmptyPlaceholder': 'No positions',
    'Web.mobile.active.Assets': 'Assets',
    'Web.mobile.active.Orders': 'Orders',
    'Web.mobile.active.More': 'More',
    'Web.mobile.active.MamSummary': 'MAM summary',
    'Web.mobile.active.OptionChain': 'Option Chain',
    'Web.mobile.active.Alerts.NoAlerts': 'No alerts',
    'Web.mobile.active.EventLog.NoEvents': 'No events',
    'Web.mobile.active.EventLog.DateTime': 'Date & time',
    'Web.mobile.active.MamSummary.Column.OperationType': 'Operation type',
    'Web.mobile.active.MamSummary.Column.Amount': 'Amount',
    'Web.mobile.active.More.Header': 'More',
    'Web.mobile.active.Settings.Defaults': 'Defaults',
    'Web.mobile.active.Settings.QuantityInLots': 'Quantity in lots',
    'Web.mobile.active.Settings.SetSlTpInOffset': 'Set SL/TP in offset',
    'Web.mobile.active.Settings.OrderSending': 'Order sending',
    'Web.mobile.active.Settings.OrderModifying': 'Order modifying',
    'Web.mobile.active.Settings.OrderExecuting': 'Order executing',
    'Web.mobile.active.Settings.OrderCancelling': 'Order cancelling',
    'Web.mobile.active.Settings.PositionModifying': 'Position modifying',
    'Web.mobile.active.Settings.PositionClosing': 'Position closing',
    'Web.mobile.active.Table.Total.Profit': 'Total profit',
    'Web.mobile.active.NewTrades.PnL': 'P/L',
    'Web.mobile.active.OrderBook.ExecutionType': 'Exec. type',
    'Web.mobile.active.Assets.EmptyPlaceholder': 'No assets',
    'Web.mobile.active.Assets.DefSymbol': 'Def. symbol',
    'Web.mobile.active.Assets.SODQuantity': 'SOD qty.',
    'Web.mobile.active.Assets.CurrentValueForSale': 'Cur. value for sell',
    'Web.mobile.active.Positions.Profit': 'P/L',
    'Web.mobile.active.AccountDetails.LinkingTooltip': 'This function filters your positions and orders by account. Account switches synchronously in all panels.',
    'Web.mobile.active.Watchlist.EmptyPlaceholder': 'No symbols',
    'Web.mobile.active.Watchlist.ListNamePlaceholder': 'Type the name here',
    'Web.mobile.active.Watchlist.Added': 'Added to watchlist',
    'Web.mobile.active.Watchlist.Removed': 'Removed from watchlist',
    'Web.mobile.active.Watchlist.MaxReached': 'Maximum number reached',
    'Web.mobile.active.ExchangesFilter.Filter': 'Filter',
    'Web.mobile.active.OrderEntry.Send': 'Send order',
    'Web.mobile.active.OrderEntry.Price': 'Price',
    'Web.mobile.active.OrderEntry.TrStop': 'Trailing stop',
    'Web.mobile.active.OrderEntry.SlPrice': 'Stop loss price',
    'Web.mobile.active.OrderEntry.SlOffset': 'Stop loss offset',
    'Web.mobile.active.OrderEntry.TrSlOffset': 'Trailing SL offset',
    'Web.mobile.active.OrderEntry.SllPrice': 'Stop loss limit price',
    'Web.mobile.active.OrderEntry.SllOffset': 'Stop loss limit offset',
    'Web.mobile.active.OrderEntry.TpPrice': 'Take profit price',
    'Web.mobile.active.OrderEntry.TpOffset': 'Take profit offset',
    'Web.mobile.active.Login.ExploreTheApp': 'Explore the app',
    'Web.mobile.active.InstrumentInfo.Price': 'Price',
    'Web.mobile.active.InstrumentInfo.BreakEven': 'B-even',
    'Web.mobile.active.Asset.SellExchange': 'Exchange',
    'Web.mobile.active.Asset.StartDayQtyMargin': 'SOD qty. for MA',
    'Web.mobile.active.Asset.LiquidityRate': 'Liq rate, %',
    'Web.mobile.active.Asset.TodayTradedQty': 'Intraday qty.',
    'Web.mobile.active.Asset.AvailableQtySell': 'Available qty.',
    'Web.mobile.active.FilledOrder.TradeVolume': 'Trade volume',
    'Web.mobile.active.FilledOrder.Date': 'Date',
    'Web.mobile.active.FilledOrder.GrossPnL': 'Gross P/L',
    'Web.mobile.active.HistoryOrder.Date': 'Date',
    'Web.mobile.active.MamSummary.EmptyPlaceholder': 'You have no operations.',
    'Web.mobile.active.MamSummary.Account': 'Account',
    'Web.mobile.active.MamSummary.OperationId': 'Operation ID',
    'Web.mobile.active.MamSummary.InstrumentName': 'Symbol',
    'Web.mobile.active.MamSummary.OpenPrice': 'Open price',
    'Web.mobile.active.MamSummary.ClosePrice': 'Close price',
    'Web.mobile.active.MamSummary.Side': 'Side',
    'Web.mobile.active.MamSummary.Quantity': 'Quantity',
    'Web.mobile.active.MamSummary.Buy': 'Buy',
    'Web.mobile.active.MamSummary.Sell': 'Sell',
    'Web.mobile.active.MarketConsensus.ExpertChoice.Tooltip': 'Expert choice',
    'Web.mobile.active.Position.StopLoss': 'Stop loss',
    'Web.mobile.active.Position.TakeProfit': 'Take profit',
    'Web.mobile.active.Position.Date': 'Date',
    'Web.mobile.active.Order.StopLoss': 'Stop loss',
    'Web.mobile.active.Order.TrStopOffset': 'Trailing SL offset',
    'Web.mobile.active.Order.TrStopLoss': 'SL Trailing stop',
    'Web.mobile.active.Order.TakeProfit': 'Take profit',
    'Web.mobile.active.Order.QuantityFilled': 'Qty. filled',
    'Web.mobile.active.Search': 'Search',
    'Web.mobile.active.CalendarPeriod.Today': 'Today',
    'Web.mobile.active.CalendarPeriod.LastWeek': 'Last week',
    'Web.mobile.active.CalendarPeriod.Last1M': 'Last 1M',
    'Web.mobile.active.CalendarPeriod.Last3M': 'Last 3M',
    'Web.mobile.active.CalendarPeriod.Last6M': 'Last 6M',
    'Web.mobile.active.InstrumentDetails.6.SessionInfo': 'Schedule',
    'Web.mobile.active.OrderModify.Confirmation.Title.Modify': 'Modifying order',
    'Web.mobile.active.OrderModify.Confirmation.Title.Cancel': 'Canceling order',
    'Web.mobile.active.OrderModify.Confirmation.Title.ExecuteAtMarket': 'Execute order at market?',
    'Web.mobile.active.OrderModify.Error.SLSmallerMin': 'Stop Loss is smaller than minimum possible value',
    'Web.mobile.active.OrderModify.Error.SLGreaterMax': 'Stop Loss is greater than maximum possible value',
    'Web.mobile.active.OrderModify.Error.TPSmallerMin': 'Take Profit is smaller than minimum possible value',
    'Web.mobile.active.OrderModify.Error.TPGreaterMax': 'Take Profit is greater than maximum possible value',
    'Web.mobile.active.PositionModify.Confirmation.Title': 'Modifying position',
    'Web.mobile.active.PositionClose.Confirmation.Lots': 'lot(s)',
    'Web.mobile.active.Lists': 'Lists',
    'Web.mobile.active.Lists.description': 'Select lists to add the item or uncheck to remove',
    'Web.mobile.active.AccountDetails.Groups.1.AccountSummary': 'Account summary',
    'Web.mobile.active.AccountDetails.Groups.4.TodayResult': 'Today result',
    'Web.mobile.active.AccountDetails.MarginUsedPercent': 'Initial margin req. %',
    'Web.mobile.active.AccountDetails.OptionPremiumReq': 'Option premium req.',
    'Web.mobile.active.AccountDetails.OpenGrossPL': 'Open gross P/L',
    'Web.mobile.active.AccountDetails.OpenPostionsNumber': 'Open Positions',
    'Web.mobile.active.AccountDetails.OpenOrdersNumber': 'Working Orders',
    'Web.mobile.active.AccountDetails.TodaysNetProfit': "Today's net profit",
    'Web.mobile.active.Chart.IndicatorsMenu.AddedIndicators': 'Added indicators',
    'Web.mobile.active.Chart.Menu.ChartSyle': 'Chart style',
    'Web.mobile.active.Chart.Menu.Settings': 'Settings',
    'Web.mobile.active.Chart.NoDataAvailable': 'No data available for {0}',
    'Web.mobile.active.TrailingStopLoss': 'Tr.',
    'Web.mobile.active.Position.GrossPnL': 'Gross P/L',
    'Web.mobile.active.Position.PositionValue': 'Position value',
    'Web.mobile.active.Position.Net_PL_Percent': '#hidden#',
    'Web.mobile.active.Position.Swaps': 'Swaps',
    'Web.mobile.active.AccountDetails.PieChart.In': 'in',
    'Web.mobile.active.signIn.error.emptyLogin': 'Login field is empty',
    'Web.mobile.active.signIn.error.emptyPassword': 'Password field is empty',
    'Web.Mobile.active.Withdrawal.Error': 'Wrong value to withdraw',
    'Web.Mobile.advancedOE.OEButtonDisabled.more': 'Quantity is more than max allowed: ',
    'Web.mobile.active.Table.Total.GrossPnL': 'Total Gross P/L',
    'Web.mobile.hidden.AccountDetails.MarginWarning': '#hidden#',
    'IsAllowedResponceReason.OpenNotAllowedInTrSession': 'Account: {0}; Operation "Order sending" is not allowed due to trading session rules',
    'Web.Mobile.Webpage.notAvailable': 'Webpage not available',
    'Web.mobile.chart.maxFavoriteReached': 'Maximum number is reached.',
    'Accounts.AccountDetails.Mode.Demo': 'Demo',
    'Accounts.AccountDetails.Mode.Live': 'Live',
    'Accounts.AccountDetails.Mode.Contest': 'Contest',
    'Accounts.AccountDetails.Mode.Funded': 'Funded',
    'Accounts.AccountDetails.Mode.Challenge': 'Challenge',
    'screen.confirmation.CancelOrder': 'Cancel order',
    'screen.confirmation.CancelOrder.Message': 'Are you sure you want to cancel order?',
    'screen.Confirmation.CommissionInfo': '#hidden#',
    'screen.confirmation.ModifyOrder': 'Confirm order modification',
    'screen.confirmation.ModifyOrder.Message': 'Are you sure you want to modify order?',
    'screen.confirmation.ModifyPosition': 'Confirm position modification',
    'screen.confirmation.ModifyPosition.Message': 'Are you sure you want to modify position?',
    'screen.confirmation.PlaceOrder': 'Confirm order placement',
    'screen.confirmation.PlaceOrder.Message': 'Are you sure you want to place order?',
    'screen.confirmation.ReversePosition': 'Confirm position reversing',
    'screen.confirmation.EstimatedFee': 'Estimated fees',
    'screen.confirmation.AfterTradeCash': 'After trade cash',
    'screen.confirmation.positionID': 'Position ID',
    'screen.confirmation.orderID': 'Order ID',
    'screen.confirmation.SLprice': 'SL price',
    'screen.confirmation.TPprice': 'TP price',
    'limitPrice.shortCaption': 'LMT:',
    'stopPrice.shortCaption': 'STP:',
    'trailingStop.shortCaption': 'Offset:',
    'panel.accounts.LiveLeverage': '#hidden#',
    'property.ClickToModify': 'Click to modify',
    'panel.accounts.AvailableCash': 'Available cash',
    'panel.accounts.TotalPositionsValue': 'Total positions value',
    'panel.accounts.FreeCommission': 'Free commission',
    'ServerMargin.AvailableCashRiskWarningMessage': 'After placing this order, you will start using broker\'s credit funds (leverage).',
    'timeSelector.Hours': 'Hours',
    'timeSelector.Minutes': 'Minutes',
    'GoToSelector.Btn.tt': 'Allows to navigate to the required history on the chart',
    'GoToSelector.Btn.Forbidden.tt': 'Not applicable for ticks history',
    'property.Hotkeys': 'Hotkeys',
    'property.Hotkeys.ToolTip': 'Hotkeys settings',
    'property.Hotkeys.DisplayInFullScreenMode': 'Display in full screen mode',
    'property.Hotkeys.ChangeChartStyle': 'Change chart style',
    'property.Hotkeys.ZoomIn': 'Zoom in',
    'property.Hotkeys.ZoomOut': 'Zoom out',
    'property.Hotkeys.AutoManualScaling': 'Auto/Manual scaling',
    'property.Hotkeys.MouseTrading': 'Mouse trading',
    'property.Hotkeys.ShowHideInfoWindow': 'Show/Hide info window',
    'property.Hotkeys.AddIndicator': 'Add indicator',
    'property.Hotkeys.RemoveDrawings': 'Remove drawings',
    'property.Hotkeys.ShowFilledOrders': 'Show Filled orders',
    'property.Hotkeys.SetPeriod_Tick': 'Set period (Tick)',
    'property.Hotkeys.SetPeriod_1m': 'Set period (1 m)',
    'property.Hotkeys.SetPeriod_5m': 'Set period (5 m)',
    'property.Hotkeys.SetPeriod_15m': 'Set period (15 m)',
    'property.Hotkeys.SetPeriod_30m': 'Set period (30 m)',
    'property.Hotkeys.SetPeriod_1H': 'Set period (1 H)',
    'property.Hotkeys.SetPeriod_4H': 'Set period (4 H)',
    'property.Hotkeys.SetPeriod_1D': 'Set period (1 D)',
    'property.Hotkeys.SetPeriod_1W': 'Set period (1 W)',
    'property.Hotkeys.SetPeriod_1M': 'Set period (1 M)',
    'property.Hotkeys.SetPeriod_1Y': 'Set period (1 Y)',
    'property.Hotkeys.ObjectManagerIndicators': 'Object manager Indicators',
    'property.Hotkeys.ObjectManagerDrawings': 'Object manager Drawings',
    'property.Hotkeys.ObjectManagerOverlays': 'Object manager Overlays',
    'property.Hotkeys.DrawLine': 'Draw Line',
    'property.Hotkeys.DrawVerticalLine': 'Draw Vertical line',
    'property.Hotkeys.DrawHorizontalLine': 'Draw Horizontal line',
    'property.Hotkeys.DrawArrow': 'Draw Arrow',
    'property.Hotkeys.DrawComments': 'Draw Comments',
    'property.Hotkeys.DrawFibonacciRetracement': 'Draw Fibonacci retracement',
    'property.Hotkeys.DrawFibonacciExpansion': 'Draw Fibonacci expansion',
    'property.Hotkeys.DrawFibonacciArc': 'Draw Fibonacci arc',
    'property.Hotkeys.DrawFibonacciTimeGoalAnalysis': 'Draw Fibonacci time goal analysis',
    'property.Hotkeys.DrawFibonacciFans': 'Draw Fibonacci fans',
    'property.Hotkeys.DrawFibonacciSpiral': 'Draw Fibonacci spiral',
    'property.Hotkeys.DrawFibonacciTimeZone': 'Draw Fibonacci time zone',
    'property.Hotkeys.DrawFibonacciPhiChannel': 'Draw Fibonacci phi channel',
    'property.Hotkeys.DrawTriangle': 'Draw Triangle',
    'property.Hotkeys.DrawPolygon': 'Draw Polygon',
    'property.Hotkeys.DrawRectangle': 'Draw Rectangle',
    'property.Hotkeys.ShowHideGrid': 'Show/Hide grid',
    'property.Hotkeys.ShowOpenPositions': 'Show Open positions',
    'property.Hotkeys.ShowVolume': 'Show volume',
    'property.Hotkeys.ShowWorkingOrders': 'Show Working orders',
    'property.Hotkeys.Chart.CancelLastOrderOnSelectedInstrument': 'Cancel last order on selected instrument',
    'property.Hotkeys.Chart.CancelAllActiveOrdersOnSelectedInstrument': 'Cancel all active orders on selected instrument',
    'property.Hotkeys.Chart.CancelBuyOrderClosestToTheLastPrice': 'Cancel Buy order(s) closest to the last price',
    'property.Hotkeys.Chart.CancelSellOrderClosestToTheLastPrice': 'Cancel Sell order(s) closest to the last price',
    'property.Hotkeys.MarketDepth.CancelLastOrderOnSelectedInstrument': 'Cancel last order on selected instrument',
    'property.Hotkeys.MarketDepth.CancelAllActiveOrdersOnSelectedInstrument': 'Cancel all active orders on selected instrument',
    'property.Hotkeys.MarketDepth.CancelBuyOrderClosestToTheLastPrice': 'Cancel Buy order(s) closest to the last price',
    'property.Hotkeys.MarketDepth.CancelSellOrderClosestToTheLastPrice': 'Cancel Sell order(s) closest to the last price',
    'property.Hotkeys.PlaceBuyOrder': 'Place Buy order',
    'property.Hotkeys.PlaceSellOrder': 'Place Sell order',
    'property.Hotkeys.SellAsk': 'Sell Ask',
    'property.Hotkeys.BuyBid': 'Buy Bid',
    'property.Hotkeys.OpenBuyOE': 'Open Buy OE',
    'property.Hotkeys.OpenSellOE': 'Open Sell OE',
    'property.Hotkeys.PlaceOrder': 'Place Order',
    'property.Hotkeys.CloseAllPositionsImmediately': 'Close all positions immediately',
    'property.Hotkeys.CancelAllOrdersImmediately': 'Cancel all orders immediately',
    'property.Hotkeys.CancelAllOrdersAndCloseAllPositionsImmediately': 'Cancel all orders and close all positions immediately',
    'property.Hotkeys.OpenReports': 'Open Reports',
    'property.Hotkeys.ModifyOrder': 'Modify order',
    'property.Hotkeys.CancelOrder': 'Cancel order',
    'property.Hotkeys.ChangeToMarket': 'Change to Market',
    'hotkeysMessageBox.ErrorText': 'This combination is already used for \'{0} ({1})\' action!',
    'hotkeysPopupMessage.ErrorText.MustCombination': 'Must be a combination of Ctrl, Shift, or Alt with a letter or number',
    'hotkeysPopupMessage.ErrorText.NeedAddLetterOrNumber': 'Need to add a letter or number',
    'ChartObjectManager.Text': 'Object manager',
    'button.cancel': 'Cancel',
    'ObjectManagerItem.Name': 'Name',
    'ObjectManagerItem.Remove': 'Remove',
    'ObjectManagerItem.Remove.ToolTip': 'Remove this item from the chart.',
    'ObjectManagerItem.Settings': 'Settings',
    'ObjectManagerItem.Settings.ToolTip': 'Open settings for this item.',
    'ObjectManagerItem.Show on chart': 'Show on chart',
    'ObjectManagerItem.Visible': 'Visible',
    'ObjectManagerItem.Visible.ToolTip': 'Show or hide this item on the chart.',
    'ObjectManagerItem.Window': 'Window',
    'general.connection.safeDisconect': 'Login/password combination is not valid.',
    cashValueNotMultipleToMinChange: 'Cash value is not multiple to min. change = {0}',
    cashBelowMinimumAllowedValue: 'The cash amount is below the minimum allowed value = {0}',
    cashAboveMaximumAllowedValue: 'The cash amount exceeds the maximum allowed value = {0}',
    'ChartObjectManager.mainWindow': 'Main window',
    'ChartObjectManager.subWindow': 'Sub window',
    'chart.menu.ObjectManager.RemoveAll': 'Remove all',
    'chart.menu.ObjectManager.RemoveSelected': 'Remove selected',
    partialSelection: '{0} items selected',
    noSelection: 'Select followers',
    'reports.AUTO_INVEST_MOB_ANDROID': 'MOB:Auto-invest “Android”',
    'reports.AUTO_INVEST_MOB_IOS': 'MOB:Auto-invest “IOS”',
    'reports.AUTO_INVEST_MOB_WEB': 'MOB:Auto-invest “WEB”',
    'reports.AUTO_INVEST_DESKTOP': 'Desktop:Auto-invest',
    'reports.AUTO_INVEST_WEB': 'WEB:Auto-invest',
    'reports.AUTO_INVEST_MOB_INV_ANDROID': 'MOB:Inv. Auto-invest “Android”',
    'reports.AUTO_INVEST_MOB_INV_IOS': 'MOB:Inv. Auto-invest “IOS”',
    'propery.MainPriceRenderer.Text': 'Text',
    'propery.MainPriceRenderer.Back': 'Back',
    'TerceraAccountLookup.AccountItems': 'items'
};
