// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type Instrument } from '@shared/commons/cache/Instrument';
import { NumericLinks } from '@shared/commons/cache/OrderParams/order-edit/OrderEditBase';
import { OrderEditBaseUtils } from '@shared/utils/Trading/OrderEditBaseUtils';
import { SlTpUtils } from '@shared/utils/Trading/SlTpUtils';
import { DPTradingNumericTemplate } from '../../templates.js';
import { DynPropertyControl } from '../elements/DynPropertyControl';

// TODO. Mousewheel increment bug, value validation bug.
export class DPTradingNumeric extends DynPropertyControl {
    constructor () { super(); }

    public override oncomplete (): void {
        super.oncomplete();

        const numericLinkKey = this.get('name') === OrderEditBaseUtils.LIMIT_PRICE_PARAM ? NumericLinks.LimitPrice : NumericLinks.StopPrice;

        void this.set(numericLinkKey, this.Controls.TradingNumeric);
    }

    protected override onNameChanged (): void {
        const name: string = this.get('name');
        const instrument = this.get<Instrument>('instrument');
        if (name === OrderEditBaseUtils.TRAILING_STOP_PARAM) {
            const offsetModeView = name === OrderEditBaseUtils.TRAILING_STOP_PARAM ? SlTpUtils.getDefaultVisualOffsetTypeExcludePercent(instrument) : SlTpUtils.getDefaultVisualOffsetType(instrument);
            const numericRightAlignedText = SlTpUtils.getSymbolForOffsetMode(offsetModeView);
            void this.set('rightAlignedText', numericRightAlignedText);
        } else {
            void this.set('rightAlignedText', '');
        }
    }
}

DynPropertyControl.extendWith(DPTradingNumeric, {
    template: DPTradingNumericTemplate,
    data: function () {
        return {
            instrument: null,
            showLabel: true,
            PriceNumericLink: null,
            StopPriceNumericLink: null,
            rightAlignedText: '',
            rightAlignedTextAdditionalClass: '',
            width: 200,
            additionalClassName: ''
        };
    }
});
