// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectSubscribeResponseMessage extends DirectMessageBaseClass {
    public InstrumentId: any = null;
    public Route: any = null;
    public CanGetSnapshot = false; // #100287
    public Delay = 0;
    public IsDataSource = false; // #101396
    // #49909
    // уникальный идентификатор SubcribeMessage в котором была отправлена группа,
    // на основании которой был сгенерирован этот мессадж
    public RequestID = 0;
    // #49909
    // уникальный идентификатор группы в SubcribeMessage на основании которой
    // был сгенерирован этот мессадж, т.е. по RequestID мы находим сам мессадж,
    // по GroupID - группу
    // и уже по этой группе находим инструмент и подписку
    public GroupID = -1;
    // #49909
    // здесь возвращается битовый флаг подписки,
    // например мы отправили 3 - это значит мы подписываемся на quote1 и quote2 (1+2=3)
    // в ответ нам пришел флаг 1 - значит мы удачно подписались только на quote1
    // 0 - подписка не произошла
    // может принимать значения 0 1 2 4 8  (4 - трейды, 8 - опционы?)
    public Service = 0;
    // #49909
    // Если пришел ErrorCode, значит подписка прошла неудачно
    public ErrorCode: number | null = null;

    constructor () {
        super(Message.CODE_SUBSCRIBE_RESPONSE_MESSAGE);
    }
}
