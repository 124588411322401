// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type DirectExternalLinkMessage } from '../../../Utils/DirectMessages/DirectExternalLinkMessage';
import { BaseExtLink } from './BaseExtLink';
import { BrandingToolLinkGenerator } from './Branding/BrandingToolLinkGenerator';
import { type BrandingToolParams } from './Branding/BrandingToolParams';
import { YouTubeHTMLConfig } from './YouTubeHTMLConfig';
import { ExtLinkUtils } from '../../UtilsClasses/ExtLinkUtils';

export class YoutubeExtLink extends BaseExtLink {
    public availUntil: number = -1;
    public availUntilDate: Date = new Date();
    public liveStream: boolean = false;
    public config: YouTubeHTMLConfig = new YouTubeHTMLConfig();

    constructor (availUntil: number = -1, liveStream: boolean = false, url: string = '', clientUseInternalBrowser: boolean = false) {
        super();
        this.availUntil = availUntil;
        this.availUntilDate = new Date(availUntil);
        this.liveStream = liveStream;
        this.urlStr = url;
        this.clientUseInternalBrowser = clientUseInternalBrowser;
    }

    public update (brandingTool: BrandingToolParams | null, extLinkMessage: DirectExternalLinkMessage): void {
        super.update(brandingTool, extLinkMessage);
        this.availUntil = brandingTool?.availableUntil ?? this.availUntil;
        this.availUntilDate = new Date(this.availUntil);
        this.liveStream = brandingTool?.liveStream ?? this.liveStream;
    }

    public generateUrl (): void {
        const url = BrandingToolLinkGenerator.generateLinkNoneURL(this);
        if (url) {
            this.url = url;
            if (this.updateValue) {
                this.updateValue(url);
            }
        }
    }

    public get videoID (): string {
        return ExtLinkUtils.getVideoIDFromYouTubeLink(this.urlStr);
    }

    public get embedUrl (): string {
        return ExtLinkUtils.getEmbedYouTubeLinkByVideoID(this.videoID);
    }
}
