// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ControlsTypes } from '../../../Controls/UtilsClasses/FactoryConstants';
import { TerceraTextBox } from '../../../Controls/elements/TerceraTextBox';
import { InvestingTextBoxTemplate } from '../../templates.js';

export class InvestingTextBox extends TerceraTextBox {
    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.InvestingTextBox; }

    public override oninit (): void {
        super.oninit();

        this.observe('text', this.private_textChange);
    }

    protected override private_textChange (newText: string, oldText: string): void {
        super.private_textChange(newText, oldText);

        if (newText.length) {
            void this.set('visibleTitlePlaceholder', true);
        } else {
            void this.set('visibleTitlePlaceholder', false);
        }
    }
}

TerceraTextBox.extendWith(InvestingTextBox, {
    template: InvestingTextBoxTemplate,
    data: function () {
        return {
            visibleTitlePlaceholder: false,
            maxLength: 200, // максимальная длинна
            isReadOnly: false, // редактируемое поле
            placeholder: '', // текст внутри поля формы, который исчезает при получении фокуса
            text: '', // значение
            inputPatern: null, // патерн допустимых элементов g - флаг не использовать!  пример new RegExp('[1-9]')
            inputType: 'text',
            haveError: false,
            autofocus: false
        };
    }
});
