// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Point, type Rectangle } from '../../../Commons/Geometry';
import { MouseButtons } from '../../../Controls/UtilsClasses/ControlsUtils';
import { TerceraChartAction, TerceraChartActionEnum } from '../../TerceraChartAction';
import { type TerceraChartBase } from '../../TerceraChartBase';
import { type TerceraChartWindow } from '../../Windows/TerceraChartWindow';
import { RendererDocking } from '../Utils/RendererDocking';
import { TerceraChartNumberScaleRenderer } from './TerceraChartNumberScaleRenderer';

export class TerceraChartNumberVerticalScaleRenderer extends TerceraChartNumberScaleRenderer<TerceraChartBase> {
    private _lastMousePosition: Point = Point.Empty();
    private _isMoving: boolean = false;
    public Docking: RendererDocking = RendererDocking.Right;

    public override Draw (gr: CanvasRenderingContext2D, window: TerceraChartWindow, windowsContainer: any, advParams: any): void {
        if (!this.Visible) { return; }

        const scaleRect: Rectangle = this.Rectangle;
        const windowClientRect: Rectangle = window.ClientRectangle;

        gr.fillStyle = this.settings.scaleBackBrush.Color;
        gr.fillRect(scaleRect.X, scaleRect.Y, scaleRect.Width, scaleRect.Height);

        if (this.Docking === RendererDocking.Left) {
            gr.DrawLine(this.settings.scaleAxisPen, scaleRect.Right() - 1, scaleRect.Top(), scaleRect.Right() - 1, scaleRect.Bottom());
        } else {
            gr.DrawLine(this.settings.scaleAxisPen, scaleRect.Left(), scaleRect.Top(), scaleRect.Left(), scaleRect.Bottom());
        }

        const FontHeightPixels = this.settings.ScaleFont.Height;

        const numbers = this.getNumbers(window);
        for (let i = 0; i < numbers.length; i++) {
            const vi = numbers[i];
            const ty = window.PointsConverter.GetScreenY(vi);

            if ((ty > scaleRect.Top()) && (ty < scaleRect.Bottom())) {
                gr.DrawLine(this.settings.scaleGridPen, windowClientRect.Left(), ty, window.ClientRectangle.Right(), ty);
                if (this.Docking === RendererDocking.Left) {
                    gr.DrawLine(this.settings.scaleAxisPen, scaleRect.Right(), ty, scaleRect.Right() - 3, ty);
                } else {
                    gr.DrawLine(this.settings.scaleAxisPen, scaleRect.Left(), ty, scaleRect.Left() + 3, ty);
                }
            }

            if (ty > scaleRect.Top() && ty < scaleRect.Bottom()) {
                const formatedValue = this.FormatValue(vi);
                const y = Math.floor(ty - FontHeightPixels / 2);
                if (y >= scaleRect.Top() && y <= scaleRect.Bottom()) {
                    let x = scaleRect.X + 4;
                    let textAlign = 'left';
                    if (this.Docking === RendererDocking.Left) {
                        x = scaleRect.Right() - 4;
                        textAlign = 'right';
                    }
                    gr.DrawString(formatedValue, this.settings.ScaleFont, this.settings.scaleTextBrush, x, y, textAlign);
                }
            }
        }
    }

    private getNumbers (window: TerceraChartWindow): number[] {
        const scaleRect: Rectangle = this.Rectangle;
        const windowClientRect: Rectangle = window.ClientRectangle;

        const maxV = window.PointsConverter.GetDataY(windowClientRect.Y);
        const minV = window.PointsConverter.GetDataY(windowClientRect.Bottom());

        const numbers: number[] = [];

        if (!isFinite(maxV) || !isFinite(minV)) {
            return numbers;
        }

        const maxC = scaleRect.Y + scaleRect.Height;
        const minC = scaleRect.Y;
        const step = this.calcStep(maxV, minV, maxC, minC, 50);
        const start = this.calcStart(step, minV);

        if (step === 0) {
            return numbers;
        }

        const count = Math.floor(((maxV + 3 * step - start) / step));

        if (count < 0) {
            return numbers;
        }

        for (let i = 0; i < count; i++) {
            numbers.push(start + i * step);
        }

        return numbers;
    }

    public override ProcessMouseDown (e: any): boolean {
        const mouseDownLocation = e.Location;
        if (e.Button === MouseButtons.Left && this.Rectangle.Contains(mouseDownLocation.X, mouseDownLocation.Y)) {
            this._lastMousePosition = mouseDownLocation;
            this._isMoving = true;
            return true;
        } else {
            this._isMoving = false;
            return super.ProcessMouseDown(e);
        }
    }

    public override ProcessMouseUp (e: any): boolean {
        this._isMoving = false;
        return super.ProcessMouseUp(e);
    }

    public override ProcessMouseMove (e: any): boolean {
        const mouseMoveLocation: Point = e.Location;
        if (this._isMoving && this.Rectangle.Contains(mouseMoveLocation.X, mouseMoveLocation.Y)) {
            const delta = (mouseMoveLocation.Y - this._lastMousePosition.Y) / this.Rectangle.Height;
            this.chart.TerceraChartActionProcessor.ProcessTerceraChartAction(TerceraChartAction.Create(TerceraChartActionEnum.ZoomY, delta));
            e.NeedRedraw = true;
            this._lastMousePosition = mouseMoveLocation;
            return true;
        } else {
            this._lastMousePosition = mouseMoveLocation;
            return super.ProcessMouseMove(e);
        }
    }
}
