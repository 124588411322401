// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';

interface IInstrNavigation {
    Name: string
    Value: InstrumentTypes
    Selected: boolean
    Event: (item: any) => void
}

export class DropDownInstrumentTypeFilterController {
    public arrInstrNavigation: IInstrNavigation[];
    public hashTable: any;
    public updateHandler: any;

    constructor (updateHandler) {
        this.arrInstrNavigation = this.createNavigation();
        this.hashTable = this.CreateHashTable();
        this.updateHandler = updateHandler;
    }

    public itemClicked (item): void {
        this.hashTable[item.Name].Selected = !item.Selected;
        if (this.updateHandler) {
            this.updateHandler();
        }
    }

    public createNavigation (): IInstrNavigation[] {
        const arrInstrNavigation = [
            {
                Name: 'All',
                Value: -1,
                Selected: true,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'Stocks',
                Value: InstrumentTypes.EQUITIES,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'Futures',
                Value: InstrumentTypes.FUTURES,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'Forex',
                Value: InstrumentTypes.FOREX,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'Options',
                Value: InstrumentTypes.OPTIONS,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'Crypto',
                Value: InstrumentTypes.CRYPTO,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'Bonds',
                Value: InstrumentTypes.BOND,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'CFDs',
                Value: InstrumentTypes.EQUITIES_CFD,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'T-B',
                Value: InstrumentTypes.TBILL,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            },
            {
                Name: 'EQ',
                Value: InstrumentTypes.EQUITIES,
                Selected: false,
                Event: (item) => { this.itemClicked(item); }
            }
        ];

        return arrInstrNavigation;
    }

    public getNavigation (): IInstrNavigation[] {
        return this.arrInstrNavigation;
    }

    public CreateHashTable (): any {
        const HT = {};

        const arr = this.arrInstrNavigation;

        for (let i = 0; i < arr.length; i++) {
            HT[arr[i].Name] = arr[i];
        }

        return HT;
    }
}
