// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type EntitlementDocumentGroup } from '../../Vendors/PFSVendor/WebFix/Groups/GroupsImport';
import { DirectMessageBaseClass } from './DirectMessageBase';
import { Message } from './Message';

export class DirectEntitlementProductDocumentResponseMessage extends DirectMessageBaseClass {
    public documents: EntitlementDocumentGroup[] = [];

    constructor () {
        super(Message.CODE_PFIX_PRODUCT_DOCUMENT_RESP);
    }

    public GetDocuments (): any[] {
        return this.documents;
    }

    public AddDocument (document): void {
        this.documents.push(document);
    }
}
