// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '../../Localizations/Resources';
import { InstrumentTypes } from './InstrumentTypes';
import { InstrumentUtils } from './InstrumentUtils';

class _InstrumentLookupInstrumentType {
    private nonFixedListTypes: InstrumentTypes[] = [];

    public getNonFixedListTypes (): InstrumentTypes[] {
        if (isValidArray(this.nonFixedListTypes)) {
            return this.nonFixedListTypes;
        }

        const types: InstrumentTypes[] = [];

        // если не фиксированный список - показываем все эксченжи и типы инструментов, кроме #hidden# и запрещенных dataProvider
        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.CFDs')) {
            types.push(InstrumentTypes.CFD_FUTURES);
        }
        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Futures') && !InstrumentUtils.UseFuturesInsteadSpot()) {
            types.push(InstrumentTypes.FUTURES);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Forex')) {
            types.push(InstrumentTypes.FOREX);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Equities')) {
            types.push(InstrumentTypes.EQUITIES);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Options')) {
            types.push(InstrumentTypes.OPTIONS);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.indices')) {
            types.push(InstrumentTypes.INDICIES);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.crypto')) {
            types.push(InstrumentTypes.CRYPTO);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Spreadbet')) {
            types.push(InstrumentTypes.SPREADBET);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Bonds')) {
            types.push(InstrumentTypes.BOND);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.ETFs')) {
            types.push(InstrumentTypes.ETF);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.TBill')) {
            types.push(InstrumentTypes.TBILL);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Spot')) {
            types.push(InstrumentTypes.SPOT);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Forward')) {
            types.push(InstrumentTypes.FORWARD);
        }

        if (!Resources.isHidden('panel.TerceraSymbolLookupDropDownForm.Corporate')) {
            types.push(InstrumentTypes.CORPORATE);
        }

        this.nonFixedListTypes = types;
        return this.nonFixedListTypes;
    }
}

export const InstrumentLookupInstrumentType = new _InstrumentLookupInstrumentType();
