// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixFieldSet } from '../Fields/FieldsImport';
import { FieldsFactory } from '../Factory/FieldsFactory';
import { UrlGroup, SupportedAggregationsGroup } from './GroupsImport';

export class ClusterNodeGroup extends PFixFieldSet {
    constructor (pfixFieldSet) {
        super(FieldsFactory.CLUSTER_NODE_GROUP, null, pfixFieldSet);
    }

    public getNodeID (): number {
        return this.GetValue(FieldsFactory.FIELD_NODE_ID);
    }

    public getNodeLoad (): number {
        return this.GetValue(FieldsFactory.FIELD_NODE_LOAD);
    }

    public getConnectionMode (): number {
        return this.GetValue(FieldsFactory.FIELD_CONNECTION_MODE);
    }

    public GetUrls (): UrlGroup[] {
        return this.GetGroups(FieldsFactory.SERVER_URL_GROUP, UrlGroup);
    }

    public GetUrl (useSSL): any {
        const groups = this.GetUrls();
        // дима утверждает, что если подходящего режима (ссл или не-ссл) нет,
        // то не подключаться вообще на эту ноду. Могут быть трудновыявл проблемы.
        for (let i = 0, len = groups.length; i < len; i++) {
            const url = groups[i];
            const protocol = url.getProtocol();
            // тут по идее нужно анализировать загрузку, ну да пох пока на нее
            if ((useSSL && protocol === 1) || (!useSSL && protocol === 0)) {
                return url.getValue();
            }
        }
        // TODO. Remove?
        // когда неправильный формат (старый) на сервере - нет филда протокол,
        // беру любой адрес, полагая его ссл/нессл по моей локальной настройке
        for (let i = 0, len = groups.length; i < len; i++) {
            const url = groups[i];
            if (!url.IsSetField(FieldsFactory.FIELD_ADDRESS_PROTOCOL)) {
                return url.getValue();
            }
        }

        // TODO.
        console.log("Node ID='" + this.getNodeID() + "' hasn't any URL for '" + useSSL ? 'Secure' : 'Non-secure' + "' mode ");

        return null;
    }

    public GetUrlsPair (): any {
        const groups = this.GetUrls();
        // дима утверждает, что если подходящего режима (ссл или не-ссл) нет,
        // то не подключаться вообще на эту ноду. Могут быть трудновыявл проблемы.
        const result: any = {};
        for (let i = 0, len = groups.length; i < len; i++) {
            const url = groups[i];
            const protocol = url.getProtocol();
            if (protocol === 1) {
                result.useSSL = url.getValue();
            } else if (protocol === 0) {
                result.noSSL = url.getValue();
            }
        }

        return result;
    }

    public SupportedAggregationsGroups (): SupportedAggregationsGroup[] {
        return this.GetGroups(FieldsFactory.SUPPORTED_AGGREGATIONS_GROUP, SupportedAggregationsGroup);
    }
}
