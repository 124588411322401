// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type IBrokerConnectionAdapterHost, type AccountManagerSummaryField, type AccountManagerPage, type AccountMetainfo, type AccountId, type CustomTableElementFormatter } from '../charting_library';
import { DataCache } from '../../DataCache';
import { type Account } from '../../cache/Account';
import { AccountType } from '@shared/utils/Account/AccountType';
import { TvAccountTableManager } from './TvAccountTableManager';
import { TvAccountHelper } from '../Helpers/TvAccountHelper';

export class TvAccountsManager {
    private readonly accountTableManager: TvAccountTableManager;

    constructor (host: IBrokerConnectionAdapterHost) {
        this.accountTableManager = new TvAccountTableManager(host);
        this.updateAccountData();
    }

    public getSummary (): AccountManagerSummaryField[] {
        return this.accountTableManager.getSummary();
    }

    public getPages (): AccountManagerPage[] {
        return this.accountTableManager.getPages();
    }

    public getCustomFormatters (): CustomTableElementFormatter[] {
        return this.accountTableManager.getCustomFormatters();
    }

    public async accountsMetainfo (): Promise<AccountMetainfo[]> {
        return await new Promise((resolve, reject) => {
            const accounts = DataCache.Accounts;
            const items: AccountMetainfo[] = [];

            for (const accID in accounts) {
                const account: Account = accounts[accID];

                if (account.AccountType === AccountType.MultiAsset) {
                    continue;
                }

                const item: AccountMetainfo =
                {
                    id: account.BstrAccount as AccountId,
                    name: account.FullAccString
                };
                const asset = account.assetBalanceDefault ? account.assetBalanceDefault.Asset : null;
                if (asset) {
                    item.currency = asset ? asset.Name : '';
                }
                items.push(item);
            }
            if (items.length === 0) {
                const item: AccountMetainfo =
                {
                    id: TvAccountHelper.getFakeAccountId(),
                    name: 'You can use only Single Currency account type. Please, contact your broker for more information.'
                };
                items.push(item);
            }
            items.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
            resolve(items);
        });
    }

    public changeAccount (): void {
        this.updateAccountData();
    }

    public subscribeEquity (): void {
        this.accountTableManager.subscribeEquity();
    }

    public unsubscribeEquity (): void {
        this.accountTableManager.unsubscribeEquity();
    }

    public subscribeMarginAvailable (symbol: string): void {
        this.accountTableManager.subscribeMarginAvailable(symbol);
    }

    public unsubscribeMarginAvailable (symbol: string): void {
        this.accountTableManager.unsubscribeMarginAvailable(symbol);
    }

    public static getDefaultAccount (): Account {
        let curAccount: Account = DataCache.MainAccountNew;
        if (curAccount.AccountType === AccountType.SingleCCY) {
            return curAccount;
        }

        curAccount = curAccount.User.Accounts.find(acc => acc.AccountType === AccountType.SingleCCY);
        if (!isNullOrUndefined(curAccount)) {
            return curAccount;
        }

        const accounts = DataCache.Accounts;

        for (const accID in accounts) {
            const account: Account = accounts[accID];

            if (account.AccountType === AccountType.SingleCCY) {
                return account;
            }
        }

        return null;
    }

    public updateAccountData (): void {
        const account = TvAccountHelper.getCurrentAccount();
        if (isNullOrUndefined(account)) return;
        this.accountTableManager.updateTable();
    }
}
