// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Connection } from '../../Commons/Connection';
import { Resources } from '../../Localizations/Resources';
import { KeyCode } from '../../Commons/KeyEventProcessor';
import { TerceraBaseWindowTemplate, TerceraTradingPasswordTemplate } from '../../templates.js';
import { BOTTOM_WINDOWS_MARGIN } from '../UtilsClasses/SizeConstants';
import { TerceraWindowBase } from './TerceraWindowBase';
import { TradingLockUtils } from '../../Utils/TradingLockUtils';
import { ApplicationInfo } from '../../Commons/ApplicationInfo';
import { DataCache } from '../../Commons/DataCache';
import $ from 'jquery';

// constructor
export class TerceraTradingPassword extends TerceraWindowBase {
    constructor () { super(); }

    public override getType (): string { return 'TerceraTradingPassword'; }

    public override oncomplete (): void {
        super.oncomplete();

        void this.set({ allowPreviewPassword: DataCache.AllowPreviewPassword });

        this.localize();
        this.setCorrectPlace();
        this.on('UnlockClick', this.UnlockClick);
        this.Controls.tbPwd.on('keyDown', this.OnPasswordKeyDown.bind(this));
        $(window).on('resize', this.setCorrectPlace.bind(this));
    }

    public setCorrectPlace (): void {
        const mWindow = $(window);
        const wWidth = mWindow.width();
        const wHeight = mWindow.height();
        let l = wWidth - 310 - 3;
        l = l > 0 ? l : 0;
        let t = wHeight - 190 - BOTTOM_WINDOWS_MARGIN - 5;
        t = t > 0 ? t : 0;
        void this.set({ left: l, top: t });
    }

    public UnlockClick (): void {
        const pass = this.get('tradePass');

        if (!pass) {
            void this.set({ visibilityError: true, textErrorLabel: Resources.getResource('screen.licensing.emptyFieldErrors.password') });
            return;
        }

        let ExternalResourse = null;
        if (ApplicationInfo.ExternalResourse !== -1) { ExternalResourse = ApplicationInfo.ExternalResourse; }

        void Connection.vendor.sendTradePassword(pass, ExternalResourse).then(function (answer) {
            if (answer) {
                void this.set({ visibilityError: false });
                TradingLockUtils.TradingLock.LockTradingByPassword = false;
                TradingLockUtils.TradingLock.updateTradingLock(false);
                this.dispose();
            } else { void this.set({ visibilityError: true, textErrorLabel: Resources.getResource('statusBar.passwordPanel.passwordBox.invalidPass') }); }
        }.bind(this));
    }

    public override localize (): void {
        void this.set({
            placeholderPassword: Resources.getResource('screen.LoginScreen.PasswordLabel'),
            textPasswordLabel: Resources.getResource('screen.UnlockTradingScreen.lockedLabel'),
            textButton: Resources.getResource('screen.UnlockTradingScreen.unlockButton'),
            textErrorLabel: Resources.getResource('statusBar.passwordPanel.passwordBox.invalidPass')
        });
    }

    public OnPasswordKeyDown (e): void {
        if (e.original.keyCode === KeyCode.ENTER) {
            this.UnlockClick();
        }
    }
}

TerceraWindowBase.extendWith(TerceraTradingPassword, {
    template: TerceraBaseWindowTemplate,
    data: function () {
        return {
            width: 310,
            height: 190,
            left: 100,
            top: 100,
            movable: false,
            showFooter: false,
            resizable: false,
            focused: true,
            showFullscreenCloud: true,
            visibilityError: false,
            textButton: '',
            textPasswordLabel: '',
            textErrorLabel: '',
            allowPreviewPassword: false,
            tradePass: '',
            placeholderPassword: '',
            style_addition_body: 'tradingPassword-AdditionBody',
            style_addition_header: 'tradingPassword-AdditionHeader'
        };
    },
    partials: { bodyPartial: TerceraTradingPasswordTemplate }
});
