// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '../../Localizations/Resources';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { TradingToolsScreenTemplate } from '../../templates.js';
import { TerceraWindowBase } from './TerceraWindowBase';
import { DataCache } from '../../Commons/DataCache';
import { type BaseExtLink } from '../../Commons/cache/ExternalLink/BaseExtLink';

let instance: TradingToolsScreen = null;
export class TradingToolsScreen extends TerceraWindowBase {
    constructor () { super(); }

    public override getType (): string { return 'TradingToolsScreen'; }

    public override oncomplete (): void {
        super.oncomplete();

        this.getLinks();

        this.on('tradingCentralLinkClick', this.tradingCentralLinkClick);
        this.on('autochartistLinkClick', this.autochartistLinkClick);

        this.center();
        this.localize();
    }

    public getLinks (): void {
        void this.set('tradingCentralTool', DataCache.ExternalLinksCache.getTradingCentralExtLink());
        void this.set('autochartistTool', DataCache.ExternalLinksCache.getAutochartistExtLink());
    }

    public static getTradingCentralLink (): string | null {
        const tool = DataCache.ExternalLinksCache.getTradingCentralExtLink();

        if (tool == null) { return null; }

        return tool.getUrl().toString();
    }

    public getAutochartistLink (): string | null {
        const tool = DataCache.ExternalLinksCache.getAutochartistExtLink();

        if (isNullOrUndefined(tool)) { return null; }

        return tool.getUrl().toString();
    }

    public static getAutochartist_iFrameLink (tool: BaseExtLink): string | null {
        if (!tool) { return null; }

        return tool.getUrl().toString();
    }

    public override localize (): void {
        void this.set({
            header: Resources.getResource('screen.tradingTools.header')
        });
    }

    public autochartistLinkClick (): void {
        window.open(this.getAutochartistLink());
    }

    public tradingCentralLinkClick (): void {
        window.open(TradingToolsScreen.getTradingCentralLink());
    }

    public static show (): void {
        if (!instance) {
            instance = new TradingToolsScreen();
            instance.on('teardown', function () {
                instance = null;
            });
            MainWindowManager.MainWindow.addControl(instance);
        }

        instance.setFocus();
    }
}

TerceraWindowBase.extendWith(TradingToolsScreen, {
    partials: { bodyPartial: TradingToolsScreenTemplate },
    data: function () {
        return {
            showHeader: true,
            showFooter: false,
            resizable: false,
            alwaysMiddle: false,
            showBorder: true,
            width: 309,
            height: 207,
            tradingCentralTool: null,
            autochartistTool: null,
            style_addition_body: 'tradingTools-AdditionBody',
            style_addition_header: 'tradingTools-AdditionHeader'
        };
    }
});
