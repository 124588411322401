// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { TerceraCheckBoxTemplate } from '../../templates.js';
import { ControlsTypes } from '../../Controls/UtilsClasses/FactoryConstants';
import { Control } from './Control';
import { type ParamType } from '../../Commons/cache/AllowedReportConstants';

export class TerceraCheckBox extends Control {
    public paramType: ParamType;

    constructor () { super(); }

    public override getType (): ControlsTypes { return ControlsTypes.TerceraCheckBox; }

    public override oninit (): void {
        super.oninit();
        this.observe('checked', this.onCheckedChanged);
        this.observe('checkBoxCheckedState', this.onCheckBoxCheckedStateChanged);
    }

    public onCheckedChanged (checked: boolean): void {
        if (this.get('checkBoxCheckedState') !== CheckBoxCheckedStates.Indeterminate) {
            void this.set('checkBoxCheckedState', checked ? CheckBoxCheckedStates.Checked : CheckBoxCheckedStates.None);
        }

        this.fire(CheckBoxEvents.CheckedStateChange, checked);
    }

    public onCheckBoxCheckedStateChanged (state: CheckBoxCheckedStates): void {
        void this.set('checked',
            state === CheckBoxCheckedStates.Indeterminate ||
            state === CheckBoxCheckedStates.Checked);
    }

    public private_CheckBoxClick (sender): void {
        if (!this.get<boolean>('enabled')) {
            return;
        }

        this.fire(CheckBoxEvents.CheckBoxClicked, sender);
        const curState = this.get('checkBoxCheckedState');
        if (curState === CheckBoxCheckedStates.Indeterminate) {
            void this.set('checkBoxCheckedState', CheckBoxCheckedStates.Checked);
        } else {
            void this.toggle('checked');
        }
    }
}

export enum CheckBoxCheckedStates {
    None = 'none',
    Indeterminate = 'indeterminate',
    Checked = 'checked'
};

export enum CheckBoxEvents {
    CheckedStateChange = 'CheckedStateChange',
    CheckBoxClicked = 'CheckBoxClicked'
}

Control.extendWith(TerceraCheckBox, {

    template: TerceraCheckBoxTemplate,
    data: function () {
        return {
            checked: false, // checked или нет
            checkBoxCheckedState: 'none', // тип checked
            text: '',
            tag: null
        };
    }
});
