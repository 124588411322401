// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Resources } from '@shared/localizations/Resources';
import { DataCacheToolRayType, DataCacheToolType } from '../DataCacheToolEnums';
import { Color } from '../../../Commons/Graphics';
import { DataCacheTool } from '../DataCacheTool';
import { BaseFibonacciDataCacheTool } from './BaseFibonacciDataCacheTool';
import { DynProperty, ColorStyleWidth } from '../../DynProperty';

export class FibonacciRetracementDataCacheTool extends BaseFibonacciDataCacheTool {
    levelsVisibility: boolean[];

    /**
     * Constructor for FibonacciRetracementDataCacheTool
     */
    constructor () {
        super();
        this.ToolType = DataCacheToolType.FibonacciRetracement;
        this.InitPoints(2);
    }

    /**
     * Initializes the block for the tool
     */
    InitBlock (): void {
        super.InitBlock();

        this.fibLevel = [0, 23.6, 38.2, 50, 61.8, 78.6, 100, 138.2, 161.8, 261.8, 423.6];

        this.levelsVisibility = [];
        let i = 11;
        while (i--) this.levelsVisibility[i] = true;
    }

    /**
     * Gets the minimum points count required for the tool
     * @returns {number} - Minimum points count
     */
    MinPointsCount (): number {
        return 2;
    }

    /**
     * Gets the maximum points count required for the tool
     * @returns {number} - Maximum points count
     */
    MaxPointsCount (): number {
        return 2;
    }

    /**
     * Properties for FibonacciRetracementDataCacheTool
     * @returns {DynProperty[]} - Array of dynamic properties
     */
    Properties (): DynProperty[] {
        const props = super.Properties();

        props.push(new DynProperty('chart.tools.fibretr.fontcolor', this.FontColor, DynProperty.COLOR, DynProperty.VISUAL_GROUP));
        props.push(new DynProperty(Resources.getResource('EnableNegativeFibonacciValue'), true, DynProperty.BOOLEAN, DynProperty.FIBONACCI_PARAMETERS_GROUP));

        let p = new DynProperty('Left ray',
            this.RayType === DataCacheToolRayType.LeftRay || this.RayType === DataCacheToolRayType.DoubleRay,
            DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        p.sortIndex = 0;
        props.push(p);

        p = new DynProperty('Right ray',
            this.RayType === DataCacheToolRayType.RightRay || this.RayType === DataCacheToolRayType.DoubleRay,
            DynProperty.BOOLEAN, DynProperty.VISUAL_GROUP);
        p.sortIndex = 1;
        props.push(p);

        const colorStyleWidthRays = new ColorStyleWidth(Color.Empty, this.styleRay, 1);
        colorStyleWidthRays.enableStyleOnly();
        props.push(new DynProperty('Ray style', colorStyleWidthRays, DynProperty.COLOR_STYLE_WIDTH, DynProperty.VISUAL_GROUP));

        return props;
    }

    /**
     * Callback function for handling dynamic property changes
     * @param {DynProperty[]} properties - Array of dynamic properties
     */
    callBack (properties: DynProperty[]): void {
        super.callBack(properties);

        let prop = DynProperty.getPropertyByName(properties, 'chart.tools.fibretr.fontcolor');
        if (prop != null) this.FontColor = prop.value;

        prop = DynProperty.getPropertyByName(properties, 'Ray style');
        if (prop != null) this.StyleRay = prop.value.Style;

        this.RayType = DataCacheTool.GetRayType(properties);
    }
}
