// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type DirectQuoteMessage } from '../../Utils/DirectMessages/DirectQuoteMessage';
import { HistoryType } from '../../Utils/History/HistoryType';
import { InstrumentTypes } from '../../Utils/Instruments/InstrumentTypes';
import { OptionPutCall } from '../../Utils/Instruments/OptionPutCall';
import { DataCache } from '../DataCache';
import { type Account } from '../cache/Account';
import { type Instrument } from '../cache/Instrument';
import { type Position } from '../cache/Position';
import { EventEmitter } from 'events';

export class PositionExerciseOptionValidator {
    private readonly instrument: Instrument;
    private readonly underlier: Instrument;
    private readonly account: Account;
    private readonly position: Position;

    constructor (position: Position) {
        this.position = position;
        this.instrument = position.Instrument;
        this.account = position.Account;
        this.underlier = this.instrument.ForwardBaseInstrument;
        this.subscribeUnderlier();
    }

    public isAllowExercise (): boolean {
        if (!this.instrument.AllowExerciseForItmOption) return true; // always enabled

        const underlierPrice = this.underlier.Level1.GetLastPrice(this.account);
        if (isNaN(underlierPrice)) return false;

        const strikePrice: number = this.instrument.StrikePrice;

        if (this.instrument.PutCall === OptionPutCall.OPTION_CALL_VANILLA) {
            return underlierPrice > strikePrice;
        } else if (this.instrument.PutCall === OptionPutCall.OPTION_PUT_VANILLA) {
            return underlierPrice < strikePrice;
        }

        return false;
    }

    public newQuote (msg: DirectQuoteMessage): void {
    }

    public dispose (): void {
        this.unsubscribeUnderlier();
    }

    private subscribeUnderlier (): void {
        if (!this.isAllowExerciseOnlyForItmOptions()) return;

        DataCache.FQuoteCache.addListener(this.underlier, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.addListener(this.underlier, this, HistoryType.QUOTE_TRADES);
    }

    private unsubscribeUnderlier (): void {
        if (!this.isAllowExerciseOnlyForItmOptions()) return;

        DataCache.FQuoteCache.removeListener(this.underlier, this, HistoryType.QUOTE_LEVEL1);
        DataCache.FQuoteCache.removeListener(this.underlier, this, HistoryType.QUOTE_TRADES);
    }

    private isAllowExerciseOnlyForItmOptions (): boolean {
        return this.instrument.InstrType === InstrumentTypes.OPTIONS &&
        !isNullOrUndefined(this.underlier) &&
        this.instrument.AllowExerciseForItmOption;
    }
}
