// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { RowIndexNames } from '../RowItems/BaseRowDataItem';
import { InvestingTableRowTemplate } from '../templates.js';
import Ractive from 'ractive';

export class InvestingTableRow extends Ractive {
    constructor () { super(); }

    public getType (): string { return 'InvestingTableRow'; }

    public override oninit (): void {
        this.on('componentClick', this.private_MouseClick);
        this.on('moreButtonClick', this.moreButtonClick);
    }

    public override oncomplete (): void {
        this.setDataColumn();
        this.observe('rowData', this.updateRowData);
    }

    public private_MouseClick (): void {
        this.fire('tableRowClick', this);
    }

    public moreButtonClick (sender, x, y): void {
        this.fire('moreButtonClickEvent', this, x, y);
    }

    public setActiveStatus (status): void {
        void this.set('isActive', status);
    }

    public updateRowData (newValue, prewValue): void {
        this.setDataColumn(prewValue);
    }

    public setDataColumn (prewValue = null): void {
        const data = this.get('rowData');
        if (!data) return;
        const columnIndex = data.getColumnAmount();
        const arrDataColumn = this.getDataColumn(data, columnIndex, prewValue);

        void this.set({
            name_value: data.getValue(RowIndexNames.NAME),
            description_value: data.getValue(RowIndexNames.DESCRIPTION),
            logoSrc: data.getValue(RowIndexNames.IMG),
            arrDataColumn
        });
    }

    public getDataColumn (data, columnIndex, prewValue = null): Array<{ Value: any, Color: any }> {
        const arrDataColumn: Array<{ Value: any, Color: any }> = [];
        for (let i = 1; i < columnIndex; i++) {
            arrDataColumn.push(this.getValueAndColor(data, i, prewValue));
        }

        return arrDataColumn;
    }

    public getValueAndColor (data, index, prewValue = null): { Value: any, Color: any } {
        const value = data.getValue(index);
        const color = data.getColor(index, prewValue);

        return { Value: value, Color: color };
    }
}

Ractive.extendWith(InvestingTableRow, {
    template: InvestingTableRowTemplate,
    data: function () {
        return {
            rowData: null,
            isActive: false,
            arrDataColumn: []
        };
    }
});
