// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum StopTradingReason {
    NONE = 0,
    UNKNOWN = 1,
    MAX_LOSS = 2,
    MAX_DAY_VOL = 3,
    WEEKLY_LOST_LIMIT = 4,
    TRAILING_DRAWDOWN_LIMIT = 5,
    MAX_UNREALIZED_LOSS = 6,
    MAX_DAILY_PROFIT = 7,
    MAX_ORDERS_COUNT_PER_DAY = 9,
    RELATIVE_DRAWDOWN_LEVEL = 11,
    EOD_TRAILING_DRAWDOWN = 14,
    RELATIVE_DAILY_LOSS_LIMIT = 15
}
