// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingTemplate } from './templates.js';
import { openContextMenu, closeContextMenuEvent } from './Controls/elements/InvestingMenu';
import { Connection } from '../Commons/Connection';
import { DataCache } from '../Commons/DataCache';
import { BrandingUtils } from '../Commons/UtilsClasses/BrandingUtils';
import { CloseAccountScreen } from '../Controls/screen/CloseAccountScreen';
import { RulesSet } from '../Utils/Rules/RulesSet';
import Ractive from 'ractive';
import { AppNames } from '../Controls/UtilsClasses/FactoryConstants';

export let ProcessChartVisible = null;

export class Investing extends Ractive {
    public selectedArrayHeaderButton: any = null;
    public PreparedAccountsItems: any = null;
    public pingInterval: NodeJS.Timeout;

    public getType (): string { return AppNames.Investing; }

    public override oninit (): void {
        this.on('loginEvent', this.loginEvent);
        this.on('accMenuBtnClick', this.accMenuBtnClick);
        this.on('clickHeaderButton', this.changeActivePanel);
        this.on('openAccountComboBox', this.openAccountComboBox);
    }

    public override oncomplete (): void {
        ProcessChartVisible = (value, instrument) => { this.setVisibleChart(value, instrument); };
        this.createButtonsHeader();
    }

    public openAccountComboBox (sender, x, y): void {
        const fromAccountComboBox = document.getElementById('accountComboBox');
        void this.set('activeAccountComboBox', true);

        const posElement = fromAccountComboBox.getBoundingClientRect();
        const posX = posElement.left + window.pageXOffset;
        const posY = y;

        const items = this.createListAccountItems();
        const additionalParams = {
            width: posElement.width,
            isDarkStyle: true
        };

        openContextMenu(posX, posY, items, additionalParams);
        closeContextMenuEvent.Subscribe(this.closeAccountComboBox, this);
    }

    public closeAccountComboBox (): void {
        closeContextMenuEvent.UnSubscribe(this.closeAccountComboBox, this);
        void this.set('activeAccountComboBox', false);
    }

    public createListAccountItems (): any[] {
        return this.PreparedAccountsItems || [];
    }

    public loginEvent (): void {
        void this.set({
            login: false,
            onDisconnectHandler: () => { this.logoutEvt(); }
        });
        this.StartPingLoop();
        this.createAccountsContainerData();
    }

    public logoutEvt (): void {
        void this.set('login', true);
        this.StopPingLoop();
    }

    public StartPingLoop (): void {
        const loop = function () {
            Connection.SendPing().then(function (value) {
            });
        };
        loop();
        this.pingInterval = setInterval(loop, 10000);
    }

    public StopPingLoop (): void {
        clearInterval(this.pingInterval);
    }

    public changeActivePanel (context, index): void {
        const items = this.get('arrayHeaderButton');
        if (!items) { return; }

        const newselectedArrayHeaderButton = items[index];
        if (newselectedArrayHeaderButton === this.selectedArrayHeaderButton) {
            return;
        }

        if (this.selectedArrayHeaderButton) {
            this.selectedArrayHeaderButton.active = false;
        }

        newselectedArrayHeaderButton.active = true;
        this.selectedArrayHeaderButton = newselectedArrayHeaderButton;

        void this.set({ arrayHeaderButton: items });
        this.changeShowPanel();
    }

    public createAccountsContainerData (): void {
        const accounts = DataCache.Accounts;

        const accountItems = [];
        for (const acc in accounts) {
            const me = accounts[acc];
            const ItemObj: any = {};
            ItemObj.value = me;
            ItemObj.text = me.FullAccString;
            ItemObj.event = this.AccountChanged.bind(this);
            accountItems.push(ItemObj);
        }

        this.PreparedAccountsItems = accountItems;
        if (accountItems.length > 0) {
            void this.set({
                selectedAccountNameCBValueName: accountItems[0].text,
                selectedAccountName: accountItems[0].value.FirstName,
                selectedAccount: accountItems[0].value

            });
        }
    }

    public AccountChanged (item): void {
        if (!item) {
            return;
        }

        const newAcc = item.value;
        const Acc = this.get('selectedAccount');
        if (!Acc || !newAcc || newAcc === Acc) {
            return;
        }

        void this.set({
            selectedAccountNameCBValueName: newAcc.FullAccString,
            selectedAccountName: newAcc.FirstName,
            selectedAccount: newAcc
        });
    }

    public createButtonsHeader (): void {
        const arrayHeaderButton: any = [
            { Name: 'Portfolio', StyleBtn: 'portfolio_header_button', TypePanel: Investing.Panels.PORTFOLIO },
            { Name: 'Watchlist', StyleBtn: 'watchlist_header_button', TypePanel: Investing.Panels.WATCHLIST }
        // { Name: "Discover", StyleBtn: "discover_header_button", TypePanel: Investing.Panels.DISCOVER },
        // { Name: "More", StyleBtn: "more_header_button", TypePanel: Investing.Panels.MORE }
        ];

        arrayHeaderButton[0].active = true;
        this.selectedArrayHeaderButton = arrayHeaderButton[0];

        void this.set({ arrayHeaderButton });
        this.changeShowPanel();
    }

    public createAccMenuItems (): Array<{ text: string, imgStyle: string, event: any }> {
        const items = [
        // {
        //     text: 'Withdrawal',
        //     imgStyle: "withdrawal"
        // },
        // {
        //     text: 'Transfer',
        //     imgStyle: "transfer"
        // },
        // {
        //     text: 'Change password',
        //     imgStyle: "change_password"
        // },
        // {
        //     text: 'Subscriptions',
        //     imgStyle: "subscriptions"
        // },
        // {
        //     text: 'Settings',
        //     imgStyle: "settings"
        // },
            {
                text: 'Log out',
                imgStyle: 'log_out',
                event: this.logoutEvent.bind(this)
            }
        ];

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_INVESTING_CLOSE_ACCOUNT)) {
            items.push({
                text: 'Close account',
                imgStyle: 'close_account',
                event: CloseAccountScreen.ShowMsg.bind(this, /* isInvesting= */true)
            });
        }

        return items;
    }

    public accMenuBtnClick (sender, posX: number, posY: number): void {
        const itemsMenu = this.createAccMenuItems();
        const additionalParams = {
            isDarkStyle: true
        };

        openContextMenu(posX, posY, itemsMenu, additionalParams);
    }

    public logoutEvent (): void {
        Connection.disconnect();
    }

    public changeShowPanel (): void {
        const whichPanel: any = {};

        switch (this.selectedArrayHeaderButton.TypePanel) {
        case Investing.Panels.PORTFOLIO:
            whichPanel.Portfolio = true;
            break;
        case Investing.Panels.WATCHLIST:
            whichPanel.Watchlist = true;
            break;
        case Investing.Panels.DISCOVER:
            break;
        case Investing.Panels.MORE:
            break;
        };

        void this.set({ whichPanel });
    }

    public setVisibleChart (value, instrument): void {
        void this.set({
            ChartVisible: value,
            instrumentItem_Chart: instrument
        });
    }

    public setBrandingWebHeader (header, brandingKey): void {
        const brandingInvestingHeader = BrandingUtils.GetBrandingWebHeader(header, this.get('logoImageSrc'), brandingKey);
        if (brandingInvestingHeader) {
            void this.set('logoImageSrc', brandingInvestingHeader);
        }
    }

    public static readonly Panels = {
        PORTFOLIO: 0,
        WATCHLIST: 1,
        DISCOVER: 2,
        MORE: 3
    };
}

Ractive.extendWith(Investing, {
    template: InvestingTemplate,
    el: 'body',
    data: function () {
        return {
            logoImageSrc: 'current_theme/styleInvesting/imgInvesting/LogoTE/logo_te.svg',
            login: true,
            onDisconnectHandler: null,
            activeAccountComboBox: false,
            selectedAccountName: '',
            selectedAccount: null,
            ChartVisible: false,
            instrumentItem_Chart: null
        };
    },
    partials: {}
});
