// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { DirectAlgorithmMessageBase } from './DirectAlgorithmMessageBase';
import { Message } from '../Message';
import { type HeatmapAlgorithmData } from '../../../Commons/cache/Algorithm/Heatmap/HeatmapAlgorithmData';

export class DirectAlgorithmHeatmapMessage extends DirectAlgorithmMessageBase {
    constructor () {
        super(Message.CODE_PFIX_ALGORITHM_HEATMAP_MESSAGE);
    }

    public InstrumentListId: number;
    public HeatmapAlgorithmData: HeatmapAlgorithmData[] = [];
}
