// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { type IIndicator } from './IIndicator';
import { PriceType } from '../../../Utils/History/CashItemUtils';
import { DoubleMatrix, ExpandDoubleVector } from './DoubleMatrix';
import { PTLIndicator } from './Indicator';
import { TFInfo } from '../../../Utils/History/TFInfo';
import { DynProperty } from '../../DynProperty';
import { CustomEvent } from '../../../Utils/CustomEvents';

// Базовый встроенный индикатор
export class iBuildInIndicator implements IIndicator {
    public OnError = new CustomEvent();
    public FData: DoubleMatrix;
    public timeFrameInfo: TFInfo = new TFInfo();
    public FParent: any = null;
    public FPriceType: number = PriceType.Open;
    public FSymbol: string = '';
    public FCount = 0;
    public lastCalculationOffset = 0;
    public mainChart = null;

    public _disposed = false;

    public FUseCalcOnArray = false;
    public FArrayReversal = false;
    // место хранения данных, на основе которых
    // считается значение индикатора
    public FPriceData: any = null;

    public offset = 0;
    public args: any = null;

    constructor (linesCount: number) {
        linesCount = linesCount === undefined ? 1 : linesCount;

        const FData = this.FData = new DoubleMatrix(linesCount);

        for (let i = 0; i < linesCount; i++) { FData.set(i, new ExpandDoubleVector()); };
    }

    public get Parent (): any { return this.FParent; }
    public set Parent (value) { this.FParent = value; }

    // Имя индикатора - ключ создания
    public get Name (): string { return 'iBaseBuildInIndicator'; }
    public set Name (value) { }

    // Период индикатора
    public get Period (): number { return this.timeFrameInfo.Periods; }

    // Цена, по которой будет построен данный индикатор
    public get PriceType (): number { return this.FPriceType; }
    public set PriceType (value) { this.FPriceType = value; }

    // Символ, для которого производится расчет
    public get Symbol (): string { return this.FSymbol; }
    public set Symbol (value: string) { this.FSymbol = value; }

    // TODO. Ugly.
    public get DefaultKey (): string {
        return this.Name + this.Symbol + this.Period;
    }

    public get Key (): any { throw new Error(); }

    // Тип истории, по которой индикатор будет работать.
    public get HistoryType (): any { return this.timeFrameInfo.HistoryType; }

    // Список с данными
    public get Data (): any { return this.FData; }

    public get MainChart (): any { return this.mainChart; }
    public set MainChart (value: any) { this.mainChart = value; }

    public get CalculationStartIndex (): any {
        this.lastCalculationOffset = PTLIndicator.GetStartIndex(this.FParent, this.mainChart);

        return this.lastCalculationOffset;
    }

    public get TimeFrameInfo (): any { return this.timeFrameInfo; }
    public set TimeFrameInfo (value: any) { this.timeFrameInfo = value; }

    public get Count (): any { return this.FCount; }

    // Вычисления индикатора по массиву
    public get PriceData (): any { return this.FPriceData; }
    public set PriceData (value: any) {
        this.FUseCalcOnArray = !!value;
        if (this.FUseCalcOnArray) {
            this.FPriceData = value;
        }
    }

    public get ArrayReversal (): any { return this.FArrayReversal; }
    public set ArrayReversal (value) { this.FArrayReversal = value; }

    public get SeparateWindow (): any { return true; }

    public get RecalculateRange (): any {
        const startIdx = PTLIndicator.GetStartIndex(this.FParent, this.mainChart);
        return this.lastCalculationOffset > startIdx;
    }

    SetLineShift (line, shift) {
        const v = this.FData.get(line);
        if (v) { v.Shift = shift; }
    }

    _GetValue (line, index) {
        const v = this.FData.get(line);
        const length = v.Length - 1;
        return v.get(length - index + (v.Shift || 0));
    }

    // TODO. Ugly.
    GetValue (par0?: number, par1?: number) {
        if (par0 !== undefined && par1 !== undefined) { return this.getValueByLineAndIndex(par0, par1); };

        if (par0 !== undefined && par1 === undefined) { return this.getValueByIndex(par0); };

        if (par0 === undefined && par1 === undefined) { return this.getValue(); };

        throw new Error();
    }

    getValueByLineAndIndex (line, index) {
        const v = this.FData.get(line);
        const length = v.Length - 1;
        return v.get(length - index);
    }

    getValueByIndex (index) {
        const v = this.FData.get(0);
        const length = v.Length - 1;
        return v.get(length - index);
    }

    getValue () {
        const v = this.FData.get(0);
        const length = v.Length;
        return v.get(length - 1);
    }

    SetValue (par0, par1?, par2?) {
        if (par0 !== undefined && par1 !== undefined && par2 !== undefined) { this.setValueByLineAndIndex(par0, par1, par2); return; };

        if (par0 !== undefined && par1 !== undefined && par2 === undefined) { this.setValueByIndex(par0, par1); return; };

        if (par0 !== undefined && par1 === undefined && par2 === undefined) { this.setValue(par0); return; };

        throw new Error();
    }

    setValueByLineAndIndex (line, index, value) {
        const v = this.FData.get(line);
        const i = v.Length - 1 - index;
        if (i >= 0 && i < v.Length) { v.set(i, value); };
    }

    setValueByIndex (index, value) {
        const v = this.FData.get(0);
        v.set(v.Length - 1 - index, value);
    }

    setValue (value) {
        const v = this.FData.get(0);
        v.set(v.Length - 1, value);
    }

    Refresh (par0, par1) {
        if (par0 !== undefined && par1 !== undefined) { this.refreshByCount(par0, par1); return; };

        if (par0 !== undefined && par1 === undefined) { this.refresh(par0); return; };

        throw new Error();
    }

    refreshByCount (count, newThread) {
        this.Refresh_Action(count);
    }

    refresh (newThread) {
        if (this.FParent) { this.refreshByCount(this.FParent.Count(), newThread); };
    }

    Refresh_Action (count) {
        if (this._disposed) { return; };

        const offset = this.CalculationStartIndex;
        this.SetOffset(offset);

        this.FCount = 0;
        for (let i = offset; i < count; i++) {
            this.NextBar(true);
            this.OnQuote(this.FParent, true);
        }
    }

    SetOffset (offset) {
        const FData = this.FData;
        for (let i = 0, len = FData.Length; i < len; i++) {
            const vector = FData.get(i);/* as ExpandDoubleVector */
            /*
        ExpandDoubleVectorFile ov = vector as ExpandDoubleVectorFile;
        if (ov != null)
        {
            ov.Clear();
            ov.Offset = offset;
        }
        else */ if (vector) { vector.Length = 0; };
        }
    }

    NextBar (callBound) {
        if (this._disposed) { return; };

        const FData = this.FData;

        for (let i = 0, len = FData.Length; i < len; i++) {
            const vector = FData.get(i);/* as ExpandDoubleVector */
            if (vector) vector.Add(0);
        }

        // cache count
        this.FCount = FData.get(0).Length;
    }

    OnQuote (ci, callBound, callFromRefresh = false) {

    }

    Init (async) {
        return true;
    }

    Complete () {

    }

    GetPrice (price, index) {
        const FCount = this.FCount;

        return this.FUseCalcOnArray
            ? this.FPriceData.get(FCount - 1 - index)
            : this.FParent.GetByType(FCount - 1 - index, price);
    }

    GetVolume (index) {
        return this.FParent.GetVolume(this.FCount - 1 - index);
    }

    Dispose () {
        this._disposed = true;

        const FData = this.FData;
        if (FData.Dispose) { FData.Dispose(); };

        this.FPriceData = null;
        this.mainChart = null;
    }

    Properties () {
        const properties: DynProperty[] = [];
        const args = this.args;
        if (!args) { return properties; };

        for (let i = 0, len = args.length; i < len; i++) {
            const prop = new DynProperty(
                'Value' + i,
                args[i],
                DynProperty.DOUBLE,
                '');

            properties.push(prop);
        }

        return properties;
    }

    callBack (properties) {
        if (!properties) { return; };

        const args = this.args;

        for (let i = 0, len = properties.length; i < len; i++) {
            const dynProp = DynProperty.getPropValue(properties, 'Value' + i);
            args[i] = dynProp.value;
        }
    }
}
