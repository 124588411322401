// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../DataCache';
import { SLTPTrigger } from '@shared/utils/SlTpTrigger';
import { DateTimeConvertor } from '@shared/utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { AccountType } from '@shared/utils/Account/AccountType';
import { OperationType } from '@shared/utils/Trading/OperationType';
import { OrderType } from '@shared/utils/Trading/OrderType';
import { InstrumentUtils } from '@shared/utils/Instruments/InstrumentUtils';
import { type Position } from '../Position';
import { BaseSettings } from '../../Settings/BaseGeneralSettingsWrapper';
import { PriceFormatter } from '@shared/utils/Instruments/PriceFormatter';

const NOT_AVAILABLE = 'N/A';

export class PositionFormatter {
    static PositionId (position) {
        return position.SuperPositionId == -1 ? position.OrderNumber /* PositionId ? */ : (position.isSuperPosition ? position.PositionId : '');
    }

    static Operation (position) { return position.BuySell; }
    static OperationStr (position) { return position.GetFullOperationName(true); }

    static IsLong (position): boolean { return this.Operation(position) === OperationType.Buy; }

    static SymbolStr (position) { return position.GetInstrumentDisplayName(); }

    static AccountStr (position) { return position.Account.toString(); }

    static DateTime (position) {
        const openTime = position.OpenTime;
        return DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(openTime);
    }

    static DateTimeStr (position) {
        const dateTime = this.DateTime(position);
        return DateTimeUtils.formatDate(dateTime, 'DD.MM.YYYY  HH:mm:ss');
    }

    static Amount (position) {
        const amount = position.Amount;
        if (BaseSettings.isQuantityInLots) { return amount; } else { return amount * position.Instrument.LotSize; }
    }

    static AmountStr (position) {
        const amount = this.Amount(position);
        return DataCache.formatVolume(position.Instrument, Math.abs(amount), BaseSettings.isQuantityInLots, position.ProductType, position.Account);
    }

    static Price (position) { return position.Price; }
    static PriceStr (position) {
        const price = this.Price(position);
        return position.Instrument.formatPricePrecision(price, false, false);
    }

    static CurrentPrice (position) {
        return position.lastPriceUpdated && !isNaN(position.CurPriceClose) ? position.CurPriceClose : NaN;
    }

    static CurrentPriceStr (position) {
        const curPriceClose = this.CurrentPrice(position);
        return isNaN(curPriceClose) ? NOT_AVAILABLE : position.Instrument.formatPrice(curPriceClose);
    }

    static StopLossPrice (position) {
        return position.SLOrder ? position.SLOrder.getPriceForStopLoss() : NaN;
    }

    static StopLossPriceStr (position, trailingStopStr = '', defaultStr = '') {
        const slPrice = this.StopLossPrice(position);
        if (isNaN(slPrice)) { return defaultStr; }
        // #109798
        const slTrigger = position.SLOrder.TriggerSL || position.SLOrder.TriggerSLTP;
        const stopLossStr = position.Instrument.formatPrice(slPrice);
        const slTriggerStr = slTrigger ? ` ${SLTPTrigger.ConvertBidAskRawValueToStr(slTrigger)}` : '';
        const trStopStr = position.SLOrder.OrderType === OrderType.TrailingStop && trailingStopStr ? ` ${trailingStopStr}` : '';
        return `${stopLossStr}${slTriggerStr}${trStopStr}`;
    }

    static TrStopOffset (position) {
        return position.SLOrder ? position.SLOrder.TrStopOffset : NaN;
    }

    static TakeProfitPrice (position) {
        return position.TPOrder ? position.TPOrder.Price : NaN;
    }

    static TakeProfitPriceStr (position, defaultStr = '') {
        const tpPrice = this.TakeProfitPrice(position);
        if (isNaN(tpPrice)) { return defaultStr; }
        const takeProfitStr = position.Instrument.formatPrice(tpPrice);
        const tpTrigger = position.TPOrder.TriggerTP || position.TPOrder.TriggerSLTP;
        if (tpTrigger) // #109798
        { return takeProfitStr + ' ' + SLTPTrigger.ConvertBidAskRawValueToStr(tpTrigger); } else { return takeProfitStr; }
    }

    static Commission (position, useAccCurrency) {
        // #26643 //+++yura - знак коммиссии переворачиваем
        return -position.GetCommissionFromSettlement(useAccCurrency);
    }

    static CommissionStr (position) {
        const instrumnet = position.Instrument;
        const account = position.Account;
        if (account.AccountType === AccountType.MultiAsset) {
            const Exp2_Asset = DataCache.GetAssetByName(instrumnet.Exp2);
            return Exp2_Asset
                ? Exp2_Asset.formatPrice(-position.GetCommissionFromSettlement(true))
                : ((-position.GetCommissionFromSettlement(true)).toString() + ' ' + instrumnet.Exp2);
        } else { return account.formatPrice(-position.GetCommissionFromSettlement(true)); }
    }

    static SwapsStr (position): string {
        const account = position.Account;
        return account.formatPrice(position.GetSwaps(true));
    }

    static NetPnLPrecentPosExposure (position: Position, useAccCurrency: boolean): number {
        if (!position.lastPriceUpdated) { return 0; }
        return (PositionFormatter.NetPnL(position, useAccCurrency) / position.getExpositionValue(useAccCurrency)) * 100;
    }

    static NetPnLPrecentPosExposureStr (position: Position, value: number): string {
        if (!position.lastPriceUpdated) { return PriceFormatter.formatPrice(0, 2, false) + ' %'; }
        return PriceFormatter.formatPrice(value, 2, false) + ' %';
    }

    static NetPnL (position: Position, useAccCurrency: boolean) {
        if (!position.lastPriceUpdated) { return 0; }
        return position.getNetPnL(useAccCurrency);
    }

    static NetPnLStr (position: Position) {
        if (!position.lastPriceUpdated) { return position.Account.formatPrice(0); } ;
        return position.Account.formatPrice(position.getNetPnL(true));
    }

    static GrossPnL (position: Position, useAccCurrency: boolean) {
        if (!position.lastPriceUpdated) { return 0; }
        return position.getGrossPnL(useAccCurrency);
    }

    static PositionValue (position: Position, useAccCurrency: boolean) {
        if (!position.lastPriceUpdated) { return 0; }
        return position.getPositionValue(useAccCurrency);
    }

    static GrossPnLStr (position: Position, useAccCurrency: boolean) {
        if (!position.lastPriceUpdated) { return position.Account.formatPrice(0); } ;
        return position.Account.formatPrice(position.getGrossPnL(useAccCurrency));
    }

    static PositionValueStr (position: Position, useAccCurrency: boolean) {
        if (!position.lastPriceUpdated) { return position.Account.formatPrice(0); } ;
        return position.Account.formatPrice(position.getPositionValue(useAccCurrency));
    }

    static ProductType (position) { return position.ProductType; }
    static ProductTypeStr (position) { return InstrumentUtils.GetLocalizedProductType(position.Instrument, position.ProductType); }
    static TradingExchange (position) { return position.Instrument.TradingExchange; }
}
