// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { FieldsFactory } from '../Factory/FieldsFactory';
import { MessageProcessorBase } from './MessageProcessorBase';
import { DirectBMCounterMessage } from '../../../../Utils/DirectMessages/DirectBMCounterMessage';
import { type PFixFieldSet } from '../Fields/FieldsImport';

export class BMCounterMessageProcessor extends MessageProcessorBase {
    public override decode (fieldSet: PFixFieldSet): DirectBMCounterMessage[] {
        const msg = new DirectBMCounterMessage();

        const len = fieldSet.Count();
        for (let i = 0; i < len; i++) {
            const fixField = fieldSet.fieldSet[i];
            if (fixField.FieldId === 0) {
                continue;
            }

            if (fixField.FieldId === FieldsFactory.FIELD_COUNT) {
                const arr = fixField.Value;
                if (arr) {
                    msg.UnreadIDs = arr;
                }
            }
        }

        return [msg];
    }
}
