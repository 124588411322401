// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum AssetTradingMode {
    Delivery = 0,
    General = 1,
    All = 2
 }

export enum CalculationMethod {
    Last = 0,
    PrevClose = 1
}
