// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

export enum OrderStatus {
    // этого статуса нет на сервере, завел его для обработки ошибок
    UNKNOWN = 0,
    // Obsolete.
    // этого статусе нет на сервере но он используется в TradeEmulator, не смог быстро от него отказаться
    REPLACED = 20,
    // ордер создан в нашей системе, но ожидает подтверждения с STP (при STP торговле);
    PENDING_NEW = 1,
    // условия исполнения ордера были выполнены, ордер ожидает подтверждения о исполнении с ЛП
    PENDING_EXECUTION = 2,
    // запрос на отмену отправлен, ордер ожидает подтверждение отмены
    PENDING_CANCEL = 3,
    // запрос на модификацию отправлен, ордер ожидает подтверждения модификации
    PENDING_REPLACE = 4,
    // модификация неактивного ордера (раньше была константа CONFIRMED_NEW)
    PENDING_REPLACE_NOT_ACTIVE = 5,
    // ордер создан в нашей системе (отправлен запрос на LP)
    NEW = 10,
    // ордер создан нашей системой, но неактивен до выполнения
    // определенных условий. Пример = реверс позиции, открывающий ордер будет иметь статус Accepted
    // пока не исполнится закрывающий ордер
    ACCEPTED = 11,
    // ордер частично исполнен
    PART_FILLED = 30,
    // ордер полностью исполнен
    FILLED = 31,
    // ордер отменен
    CANCELED = 40,
    // ордер отклонен
    REFUSED = 41,
    // ордер создан, но торговая сессия закрыта, ордер находится в состоянии ожидания открытия рынка
    WAITING_MARKET = 50,
    // #123040
    WAITING_CANCELLATION = 51,
    // ордер создан локально и будет отправлен на биржу после открытия рынка.
    OFF_MARKET = 60,
    // ордер был деактивирован на бирже, при этом в нашей системе он остался и с ним могут выполнятся действия.
    // Исполнится он сможет только когда биржа его активирует
    UNPLACED = 70,
    // удаляем ордер из панелей
    REMOVED = 80
}
