// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Resources } from '../../../Localizations/Resources';
import { type ResolutionString, type TimeFrameItem } from '../charting_library';

class _TvTimeFrameHelper {
    public getTimeFrames (): TimeFrameItem [] {
        const timeFrames: TimeFrameItem [] =
        [
            {
                text: '1d',
                resolution: '1' as ResolutionString,
                description: Resources.getResource('TvTradingPlatform.TimeFrame.1D.desciption'),
                title: Resources.getResource('TvTradingPlatform.TimeFrame.1D.title')
            },
            {
                text: '5d',
                resolution: '5' as ResolutionString,
                description: Resources.getResource('TvTradingPlatform.TimeFrame.5D.desciption'),
                title: Resources.getResource('TvTradingPlatform.TimeFrame.5D.title')
            },
            {
                text: '1m',
                resolution: '30' as ResolutionString,
                description: Resources.getResource('TvTradingPlatform.TimeFrame.1M.desciption'),
                title: Resources.getResource('TvTradingPlatform.TimeFrame.1M.title')
            },
            {
                text: '3m',
                resolution: '60' as ResolutionString,
                description: Resources.getResource('TvTradingPlatform.TimeFrame.3M.desciption'),
                title: Resources.getResource('TvTradingPlatform.TimeFrame.3M.title')
            },
            {
                text: '6m',
                resolution: '240' as ResolutionString,
                description: Resources.getResource('TvTradingPlatform.TimeFrame.6M.desciption'),
                title: Resources.getResource('TvTradingPlatform.TimeFrame.6M.title')
            },
            {
                text: '1y',
                resolution: '1W' as ResolutionString,
                description: Resources.getResource('TvTradingPlatform.TimeFrame.1Y.desciption'),
                title: Resources.getResource('TvTradingPlatform.TimeFrame.1Y.title')
            },
            {
                text: '5y',
                resolution: '1W' as ResolutionString,
                description: Resources.getResource('TvTradingPlatform.TimeFrame.5Y.desciption'),
                title: Resources.getResource('TvTradingPlatform.TimeFrame.5Y.title')
            }
        ];

        return timeFrames;
    }
}

export const TvTimeFrameHelper = new _TvTimeFrameHelper();
