// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from './DataCache';

let RecalculateCounter = 0;

export function RecalculateBalances (): void {
    // TODO тупой апдейт надо переделать
    if (RecalculateCounter === 5) {
        DataCache.RecalculateBalances();
        RecalculateCounter = 0;
    }

    RecalculateCounter++;
}
