// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Connection } from '../../Connection';
import { EventEmitter } from 'events';
import { TvWorkspaceManagerState } from '../TradingViewPrimitives/Workspace/TvWorkspaceManagerState';
import { TvSettingsData } from '../Settings/TvSettingsData';
import { TvSettingsAdapter } from '../Settings/TvSettingsAdapter';
import { ConnectionStates } from '../../ConnectionEnums';
import { TvWorkspaceProvider } from '../Providers/TvWorkspaceProvider';

export enum TvSaveWorkspaceMode {
    None = 0,
    IndexedDB = 1,
    Server = 2,
    Both = 3 // IndexedDB | Server
}

export class _TvWorkspaceManager {
    private readonly _eventEmitter: EventEmitter = new EventEmitter();
    private serverIntervalId: NodeJS.Timeout | undefined;
    private indexedDbIntervalId: NodeJS.Timeout | undefined;
    private state: TvWorkspaceManagerState = TvWorkspaceManagerState.Unknown;
    private chartSettings: any = null;
    private defaultAccountId: string;
    private defaultResolution: string;
    private theme: string = 'light';

    public initialize (): void {
        Connection.onGetToken.Subscribe(this.onGetToken, this);
        Connection.onConnectStateChange.Subscribe(this.connectionStateChange, this);
    }

    public getState (): TvWorkspaceManagerState {
        return this.state;
    }

    public getChartSettings (): any {
        return this.chartSettings;
    }

    public subscribeLoadWorkspace (callback: () => void): void {
        this._eventEmitter.on('loaded', callback);
    }

    public unsubscribeLoadWorkspace (callback: () => void): void {
        this._eventEmitter.off('loaded', callback);
    }

    public subscribeSaveWorkspace (callback: (mode: TvSaveWorkspaceMode) => void): void {
        this._eventEmitter.on('save', callback);
    }

    public unsubscribeSaveWorkspace (callback: (mode: TvSaveWorkspaceMode) => void): void {
        this._eventEmitter.off('save', callback);
    }

    public async saveWorkSpace (data, mode: TvSaveWorkspaceMode): Promise<void> {
        const savedData = new TvSettingsData();
        savedData.TvChartSettings = data;
        savedData.TvSettings = TvSettingsAdapter.initialSettings;
        savedData.defaultResolution = this.defaultResolution;
        savedData.defaultAccountId = this.defaultAccountId;
        savedData.theme = this.theme;
        savedData.time = Date.now();
        if ((mode & TvSaveWorkspaceMode.Server) !== 0) {
            await TvWorkspaceProvider.saveWorkSpaceOnServer(savedData);
        }
        if ((mode & TvSaveWorkspaceMode.IndexedDB) !== 0) {
            await TvWorkspaceProvider.saveWorkSpaceToIndexedDb(savedData);
        }
    }

    public setDefaultAccountId (accountId: string): void {
        this.defaultAccountId = accountId;
    }

    public getDefaultAccountId (): string {
        return this.defaultAccountId;
    }

    public setDefaultResolution (resolution: string): void {
        this.defaultResolution = resolution;
    }

    public getDefaultResolution (): string {
        return this.defaultResolution;
    }

    public setTheme (theme: string): void {
        if (this.theme !== theme) {
            document.documentElement.classList.remove('dark', 'light');
            document.documentElement.classList.add(theme);
            this.theme = theme;
        }
    }

    public getTheme (): string {
        return this.theme;
    }

    public isOrdersPanelOpen (): boolean {
        return TvSettingsAdapter.initialSettings['trading.tradingPanelOpened'] === 'true';
    }

    private async loadWorkSpace (): Promise<void> {
        this.state = TvWorkspaceManagerState.Loading;
        this.clearPrevSettings();
        const settingsData = await TvWorkspaceProvider.getWorkSpace();
        if (!isNullOrUndefined(settingsData)) {
            this.chartSettings = settingsData.TvChartSettings;
            this.defaultResolution = settingsData.defaultResolution;
            this.defaultAccountId = settingsData.defaultAccountId;
            this.setTheme(settingsData.theme);
            if (!isNullOrUndefined(settingsData.TvSettings)) {
                TvSettingsAdapter.initialSettings = settingsData.TvSettings;
            }
        } else {
            this.clearLocalStorage();
        }

        this.state = TvWorkspaceManagerState.Loaded;
    }

    private async onGetToken (): Promise<void> {
        await this.loadWorkSpace();
        this.callOnLoaded();
    }

    private callOnLoaded (): void {
        this._eventEmitter.emit('loaded');
    }

    private callOnSave (mode: TvSaveWorkspaceMode): void {
        this._eventEmitter.emit('save', mode);
    }

    private async connectionStateChange (): Promise<void> {
        const state = Connection.getState();
        switch (state) {
        case ConnectionStates.CONNECTED:
            TvWorkspaceProvider.reinitDatabase();
            this.startInterval();
            break;
        case ConnectionStates.DISCONNECTED:
            this.stopInterval();
            break;
        }
    }

    private startInterval (): void {
        const tenMinutes = 1000 * 60 * 10;
        this.serverIntervalId = setInterval(() => { this.saveWorkspaceToServerByTimer(); }, tenMinutes);

        const oneMinute = 1000 * 60;
        this.indexedDbIntervalId = setInterval(() => { this.saveWorkspaceToIndexedDbByTimer(); }, oneMinute);
    }

    private stopInterval (): void {
        if (!isNullOrUndefined(this.serverIntervalId)) {
            clearInterval(this.serverIntervalId);
        }

        if (!isNullOrUndefined(this.indexedDbIntervalId)) {
            clearInterval(this.indexedDbIntervalId);
        }
    }

    private saveWorkspaceToServerByTimer (): void {
        this.callOnSave(TvSaveWorkspaceMode.Server);
    }

    private saveWorkspaceToIndexedDbByTimer (): void {
        this.callOnSave(TvSaveWorkspaceMode.IndexedDB);
    }

    private clearLocalStorage (): void {
        for (let i = localStorage.length - 1; i >= 0; i--) {
            const key = localStorage.key(i);
            if (!isNullOrUndefined(key) && key.startsWith('tradingview.')) {
                localStorage.removeItem(key);
            }
        }
    }

    private clearPrevSettings (): void {
        this.chartSettings = null;
        this.defaultResolution = null;
        this.defaultAccountId = null;
        this.theme = 'light';
        TvSettingsAdapter.clear();
    }
}

export const TvWorkspaceManager = new _TvWorkspaceManager();
