// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { terceraCheckBoxButtonTemplate } from '../../templates.js';
import { TerceraCheckBox } from './TerceraCheckBox';

export class TerceraCheckBoxButton extends TerceraCheckBox {
    constructor () { super(); }
}

TerceraCheckBox.extendWith(TerceraCheckBoxButton, {
    template: terceraCheckBoxButtonTemplate,
    data: function () {
        return {
            checked: false, // checked или нет
            isSL: false,
            text: ''
        };
    }
});
