// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { type StatisticsGroupMessage } from '../../Utils/DirectMessages/StatisticsGroupMessage';

export class Statistics {
    public Type: number;
    public BeginProjectedBalance: number;
    public AccountOperationStatistic: Map<number, number> = new Map<number, number>();

    constructor () {
        this.Type = null;
        this.BeginProjectedBalance = null;
    }

    public FillByMessage (message: StatisticsGroupMessage): void {
        this.Type = message.Type;
        if (message.BeginProjectedBalance !== null) {
            this.BeginProjectedBalance = message.BeginProjectedBalance;
        }
        if (isValidArray(message.AccountOperationStatistic)) {
            message.AccountOperationStatistic.forEach(statistic => {
                this.AccountOperationStatistic.set(statistic.OperationType, statistic.Amount);
            });
        }
    }
}
