// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { MAMode, MODE_MAIN, MODE_MINUSDI, MODE_PLUSDI } from '../../IndicatorConstants';
import { iADXEx } from './iADXEx';

export class iADXEma extends iADXEx {
    public K: number;
    public adx: number = 0;
    public plusDI: number = 0;
    public minusDI: number = 0;

    constructor (maPeriod) {
        super(maPeriod, PriceType.Close, MAMode.EMA);

        this.K = 2.0 / (maPeriod + 1.0);
    }

    override get Name (): string { return 'iADXEma'; }

    public override Refresh (count, newThread): void {
        this.adx = 0;
        this.plusDI = 0;
        this.minusDI = 0;
        super.Refresh(count, newThread);
    }

    public override OnQuote (): void {
        const Parent = this.Parent;
        if (!Parent) {
            return;
        }
        if (Parent == null || this.FCount == 1) {
            return;
        }

        const hi = this.GetPrice(PriceType.High, 0);
        const lo = this.GetPrice(PriceType.Low, 0);

        // Вычисление направления движения (DM)
        let plusDM = hi - this.GetPrice(PriceType.High, 1);
        if (plusDM < 0.0) {
            plusDM = 0.0;
        }
        let minusDM = this.GetPrice(PriceType.Low, 1) - lo;
        if (minusDM < 0.0) {
            minusDM = 0.0;
        }
        if (plusDM == minusDM) {
            plusDM = minusDM = 0.0;
        }
        if (plusDM > minusDM) {
            minusDM = 0.0;
        } else {
            plusDM = 0.0;
        }

        // Вычисление индексов направлений DI
        const prevClose = this.GetPrice(this.FPriceType, 1);
        const TR = Math.max(hi - lo, Math.max(Math.abs(prevClose - hi), Math.abs(prevClose - lo)));
        if (TR != 0.0) {
            this.plusDI += this.K * (100 * plusDM / TR - this.plusDI);
            this.minusDI += this.K * (100 * minusDM / TR - this.minusDI);
        } else {
            this.plusDI += this.K * (-this.plusDI);
            this.minusDI += this.K * (-this.minusDI);
        }

        // Вычисление индексе движения DX (ADX)
        const sumDI = this.plusDI + this.minusDI;
        if (sumDI != 0) {
            this.adx += this.K * (100.0 * Math.abs((this.plusDI - this.minusDI) / sumDI) - this.adx);
        } else {
            this.adx += this.K * (-this.adx);
        }

        // Setting values
        this.SetValue(MODE_MAIN, 0, this.adx);
        this.SetValue(MODE_PLUSDI, 0, this.plusDI);
        this.SetValue(MODE_MINUSDI, 0, this.minusDI);
    }
}
