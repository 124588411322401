// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { LinkedSystem, LinkedSystemAccLinkingValue } from '../misc/LinkedSystem';
import { QuickTableRactive } from '../elements/QuickTable/QuickTableRactive';
import { PanelLocKeys, PanelNames } from '../UtilsClasses/FactoryConstants';
import { BrowserUtils } from '@shared/commons/UtilsClasses/BrowserUtils';
import { ErrorInformationStorage } from '@shared/commons/ErrorInformationStorage';
import { Resources } from '@shared/localizations/Resources';
import { ThemeManager } from '../misc/ThemeManager';
import { DynProperty } from '@shared/commons/DynProperty';
import { DataCache } from '@shared/commons/DataCache';
import { CustomerAccess } from '@shared/commons/CustomerAccess/CustomerAccess';
import { MainWindowManager } from '../UtilsClasses/MainWindowManager';
import { PanelItemsFactory } from './PanelItemsFactory';
import { WorkSpaceManager } from '../WorkSpace/WorkSpaceManager';
import { DateTimeUtils } from '@shared/utils/Time/DateTimeUtils';
import { SessionSettings } from '@shared/commons/SessionSettings';
import { type Instrument } from '@shared/commons/cache/Instrument';
import { type BaseItem } from '../cache/BaseItem';
import { type QuickTable } from '../elements/QuickTable/QuickTable';
import { ApplicationPanel } from './ApplicationPanel';
import { type QuickTableRow } from '../elements/QuickTable/QuickTableRow';
import { type BaseChartRactive } from '../elements/TerceraChartRactive/BaseChartRactive';

export class ApplicationPanelWithTable<ItemType extends BaseItem> extends ApplicationPanel {
    public static readonly OPEN_CHART = 'openChart';
    public static readonly OPEN_MD = 'openMD';
    public static readonly OPEN_TS = 'openTS';
    public static readonly OPEN_OE = 'openOE';

    public static readonly SYMBOLINFOPANEL = 'SymbolInfoPanel';

    public quickTableRactive: QuickTableRactive<ItemType>;
    public Name: string;
    public margin: number;

    public menuTagDict: any; // контекстное меню quickTable
    public moreThanOneTTKey: string;
    public noOneTTKey: string;

    public NeedCalculateRowCount: boolean;
    public rowCount: number;
    public InitGroupByColumnIndex: any;

    constructor () {
        super();

        this.quickTableRactive = null;

        this.menuTagDict = []; // контекстное меню quickTable
        this.moreThanOneTTKey = '';
        this.noOneTTKey = '';

        this.NeedCalculateRowCount = true;
        this.headerLocaleKey = '';
        this.rowCount = 0;

        this.InitGroupByColumnIndex = null;
    }

    public override getType (): PanelNames { return null; }

    // Init QuickTableRactive
    protected jbInit (): void {
        try {
            const panelInfo = PanelItemsFactory.GetPanelItem(this.getType());
            if (panelInfo) {
                const myQuickTableRactive = new QuickTableRactive<ItemType>();
                this.addControl(myQuickTableRactive, true);
                const qt = myQuickTableRactive.quickTable;

                // TODO
                qt.InitializeDirect(new panelInfo() as ItemType);
                this.SetColumnsDefaultDisplayIndex(qt);
                this.SetColumnsColouringMode(qt);
                qt.UpdateSortedColumns();
                myQuickTableRactive.enableRowHoverWithBorder(true);
                this.quickTableRactive = myQuickTableRactive;

                if (this.deferredXmlSettingsTemplate) {
                    qt.xmlSettingsTemplate = this.deferredXmlSettingsTemplate;
                    this.visibleColumnChanged(qt.getVisibleColumn());
                    delete this.deferredXmlSettingsTemplate;
                }

                this.layoutTable();
                this.OnResize.Subscribe(this.layoutTable, this);

                qt.OnVisibleColumnChanged.Subscribe(this.visibleColumnChanged, this);
                qt.beforeTableContextMenuShowing = this.preparePopup.bind(this);
            }

            this.populateItemsDirect();
        } catch (ex) {
            ErrorInformationStorage.GetException(ex);
        }
    }

    public override LinkSystemHandler (newVal, oldVal, key): void {
        super.LinkSystemHandler(newVal, oldVal, key);

        if (!newVal && key === 'isAccountLinkShow') {
            this.filterByAccountNumber();
        }
    }

    public getQuickTable (): QuickTable<ItemType> | null {
        return this.quickTableRactive?.quickTable ?? null;
    }

    public populateItemsDirect (): any { }

    public SetColumnsDefaultDisplayIndex (table): any { }

    protected SetColumnsColouringMode (table): any { }

    public ProcessSetColumnsColouringMode (table, arr, type, upCustomForeColor: string = '', downCustomForeColor: string = '', upCustomBackColor: string = '', downCustomBackColor: string = ''): void {
        const theme = ThemeManager.CurrentTheme;
        const upForeColor = isValidString(upCustomForeColor) ? upCustomForeColor : theme.TableValueUpForeColor;
        const downForeColor = isValidString(downCustomForeColor) ? downCustomForeColor : theme.TableValueDownForeColor;
        for (let i = 0; i < arr.length; i++) {
            const column = table.columns[arr[i]];
            column.ColouringMode = type;
            column.ValueUpForeColor = upForeColor;
            column.ValueDownForeColor = downForeColor;
            if (isValidString(upCustomBackColor)) {
                column.ValueUpBackColor = upCustomBackColor;
            }
            if (isValidString(downCustomBackColor)) {
                column.ValueDownBackColor = downCustomBackColor;
            }
        }
    }

    public ShowTotalsStateChange (menuItem): void {
        this.UpdateShowTotalsStateChange(menuItem.checked);
    }

    public UpdateShowTotalsStateChange (state: boolean): any {
        const qt = this.getQuickTable();
        if (qt == null) { return; }

        qt.ShowTotals = state;
        qt.onResize();
    }

    public UpdateGroupByState (InitGroupByColumnIndex: number): any {
        const qt = this.getQuickTable();
        if (qt == null) { return; }

        const menu = qt.groupByContextMenuItems;
        if (menu == null) { return; }

        let groupByMenuItem = null;

        for (let i = 0; i < menu.subitems.length; i++) {
            const menuItem = menu.subitems[i];

            if (menuItem.tag.PRIVATE.index === InitGroupByColumnIndex) {
                groupByMenuItem = menuItem;
                break;
            }
        }

        if (groupByMenuItem != null) {
            groupByMenuItem.checked = true;
            qt.onGroupByMenuItemClicked(groupByMenuItem);
        }
    }

    public layoutTable (): void {
        if (this.quickTableRactive != null) {
            this.layoutTableResize(this.quickTableRactive);
        }
    }

    public async layoutTableResize (table: BaseChartRactive<any> | QuickTableRactive): Promise<void> {
        await table.resetSizes();
        await table.setSizes();
    }

    public override oncomplete (): void {
        this.jbInit();
        super.oncomplete();

        this.layoutTable();

        if (this.InitGroupByColumnIndex !== null) {
            this.UpdateGroupByState(this.InitGroupByColumnIndex);
        }

        const qt = this.getQuickTable();
        qt?.OnAddItem.Subscribe(this.OnAddItemToQuickTable, this);
    }

    public OnAddItemToQuickTable (row): void {
        if (!LinkedSystem.accLinkingActive) {
            return;
        }

        row.visible = this.isRowVisibleOnAccountLinking(row);
    }

    public visibleColumnChanged (columnIndexesArray): void {
        const msgData: any = {};
        msgData.columnIndexesArray = columnIndexesArray; // what's the purpose ?
    }

    protected clearTable (tableBehavior?): void {
        const qt = this.getQuickTable();
        if (qt != null) {
            qt.ClearAll();
        }
    }

    public preparePopup (): void {
        this.updateSymbolInfoMenuItem();
    }

    public override populate (addPanel: boolean): void {
        if (!addPanel && WorkSpaceManager.needRestoreWS) {
            this.repopulate();
        }
    }

    public override repopulate (force?): void {
        const qt = this.getQuickTable();

        if (qt != null) {
            qt.ClearAll();
            this.visibleColumnChanged(qt.getVisibleColumn());
        }

        this.Controls?.TerceraInstrumentLookup?.repopulate(true);
    }

    public override setSize (w: number, h: number): void {
        super.setSize(w, h);

        this.layoutTable();
    }

    public override themeChange (): void {
        if (this.quickTableRactive) {
            this.quickTableRactive.themeChange();
        }
    }

    public override localize (): void {
        super.localize();

        this.getQuickTable()?.localize();
    }

    public dispose (): void {
        const qt = this.getQuickTable();
        qt?.OnAddItem.UnSubscribe(this.OnAddItemToQuickTable, this);

        this.OnResize.UnSubscribe(this.layoutTable, this);

        super.dispose();
    }

    public override Properties (): DynProperty[] {
        const properties: DynProperty[] = super.Properties();

        if (!isNullOrUndefined(this.quickTableRactive)) {
            properties.push(new DynProperty('GroupByColumnIndex', this.getQuickTable().groupByColumnIndex, DynProperty.INTEGER, DynProperty.HIDDEN_GROUP));
        }

        return properties;
    }

    public override callBack (newProperties): void {
        super.callBack(newProperties);

        this.InitGroupByColumnIndex = DynProperty.getPropValue(newProperties, 'GroupByColumnIndex');
    }

    public override TickAsync (): void {
    /* if (this.quickTableRactive && this.getQuickTable().needRedraw) */
        const qt = this.getQuickTable();
        if (qt == null) { return; }

        if (qt.rowCountChanged) {
            this.rowCount = qt.getDataRowsCount();
            qt.rowCountChanged = false;
            this.updatePanelHeader();
        }

        if (!this.get<boolean>('visible')) { return; }

        qt.updateRowsCellsValues(this.forceUpdate);
        qt.needRedraw = false;
        qt.Draw();
        this.forceUpdate = false;
    }

    public override accountLink_In (accId, fromLinkedSystem = false): boolean {
        const isAccountLinkIn = super.accountLink_In(accId, fromLinkedSystem);

        if (!isAccountLinkIn) return false;

        this.filterByAccountNumber(accId);

        return true;
    }

    public override updatePanelHeader (): void {
        const qt = this.getQuickTable();

        let subItemsCount = qt != null ? qt.getSubItemsRowsCount(false) : 0;
        let rowNumSt = (this.rowCount - subItemsCount).toString(); // - (all FIFO sub items) #80010

        if (LinkedSystem.accLinkingActive && !isNullOrUndefined(this.quickTableRactive) && this.get('canLinkByAccount') as boolean) {
            subItemsCount = qt.getSubItemsRowsCount(true);
            const visibleRowCount = qt.visibleRowCount() - subItemsCount; // - (visible FIFO sub items) #80010
            if (DataCache.EnableForceLinkingByAccount()) { rowNumSt = `${visibleRowCount}`; } else { rowNumSt = `${visibleRowCount}/${rowNumSt}`; }
        }
        void this.set({ header: Resources.getResource(this.headerLocaleKey) + (this.NeedCalculateRowCount ? (`  (${rowNumSt})`) : '') });
    }

    public isRowVisibleOnAccountLinking (row: QuickTableRow<ItemType>): boolean {
        if (!(this.get('canFilterByAccount') as boolean) || !(this.get('canLinkByAccount') as boolean)) {
            return true;
        }

        const linkAccId = LinkedSystem.getAccount(LinkedSystemAccLinkingValue);
        const rowAcc = row?.item?.GetCurrentAccount();

        return !rowAcc || rowAcc?.AcctNumber === linkAccId;
    }

    public override enableAccountLinking (): void {
        super.enableAccountLinking();

        const accId = LinkedSystem.getAccount(LinkedSystemAccLinkingValue);
        this.filterByAccountNumber(accId);
    }

    public override disableAccountLinking (): void {
        super.disableAccountLinking();

        this.getQuickTable()?.setAllRowsVisibility(true);
    }

    public filterByAccountNumber (accId: string = ''): void {
        const qt = this.getQuickTable();
        if (!this.get('canLinkByAccount') || qt == null) {
            return;
        }

        qt.filterByAccountNumber(accId);
    }

    public override getXmlSettingsTemplate (): any {
        const qt = this.getQuickTable();
        return qt != null ? qt.xmlSettingsTemplate : super.getXmlSettingsTemplate();
    }

    public override setXmlSettingsTemplate (value): void {
        const qt = this.getQuickTable();
        if (qt != null) {
            qt.xmlSettingsTemplate = value;
            this.visibleColumnChanged(qt.getVisibleColumn());
        } else {
            super.setXmlSettingsTemplate(value);
        }
    }

    public setVisibleColumns (arr: number[]): void {
        const qt = this.getQuickTable();
        if (qt == null) { return; }

        qt.setVisibleColumn(arr);
    }

    /// <summary>
    /// Инструмент выбранный в таблице (первый если их несколько)
    /// </summary>
    public override getInstrument (): Instrument | null {
        const qt = this.getQuickTable();
        if (!qt?.selectedRowIds?.[0]) {
            return null;
        }

        const row = qt.rows[qt.selectedRowIds[0]];
        return row?.item?.GetCurrentInstrument() ?? null;
    }

    public openSymbolInfo (): void {
        const ins = this.getInstrument();
        if (isNullOrUndefined(ins)) { return; }

        MainWindowManager.MainWindow.ShowSymbolInfo(ins, this.getAccount());
    }

    public AddSymbolInfoContextMenuItemIfNeed (items: any[], addSeparator = false): void {
        if (CustomerAccess.panelAllowed(PanelNames.SymbolInfoPanel)) {
            if (addSeparator) {
                items.push({ separator: true });
            }

            const key = 'panel.watchlist.menu.SymbolInfo';

            items.push({
                locKey: key,
                text: Resources.getResource(key),
                event: this.openSymbolInfo.bind(this),
                enabled: false,
                disabledReason: null,
                tag: ApplicationPanelWithTable.SYMBOLINFOPANEL
            });
        }
    }

    public AddOpeningPanelsCM (items: any[]): any[] {
        const lokKeys = this.GetOpeningPanelsCMLocKeysSet();
        let lK = lokKeys[ApplicationPanelWithTable.OPEN_CHART];
        const newItems = [];

        if (!Resources.isHidden(lK)) {
            newItems.push({
                locKey: lK,
                event: this.openPanel.bind(this, PanelNames.ChartPanel),
                enabled: false,
                tag: ApplicationPanelWithTable.OPEN_CHART
            });
        }

        lK = lokKeys[ApplicationPanelWithTable.OPEN_MD];
        if (!Resources.isHidden(lK)) {
            newItems.push({
                locKey: lK,
                event: this.openPanel.bind(this, PanelNames.MarketDepthPanel),
                enabled: false,
                tag: ApplicationPanelWithTable.OPEN_MD
            });
        }

        lK = lokKeys[ApplicationPanelWithTable.OPEN_TS];
        if (!Resources.isHidden(lK)) {
            newItems.push({
                locKey: lK,
                event: this.openPanel.bind(this, PanelNames.TimeSalesPanel),
                enabled: false,
                tag: ApplicationPanelWithTable.OPEN_TS
            });
        }

        lK = lokKeys[ApplicationPanelWithTable.OPEN_OE];
        if (!Resources.isHidden(lK)) {
            newItems.push({
                locKey: lK,
                event: this.openPanel.bind(this, PanelNames.AdvancedOrderEntry),
                enabled: false,
                tag: ApplicationPanelWithTable.OPEN_OE
            });
        }

        if (newItems.length > 0) {
            newItems.unshift({ separator: true });
            newItems.push({ separator: true });
        }

        return items.concat(newItems);
    }

    public SetOpeningPanelsCMEnability (menuTagDict: Record<string, any>, enabled): void {
        if (menuTagDict[ApplicationPanelWithTable.OPEN_CHART]) {
            menuTagDict[ApplicationPanelWithTable.OPEN_CHART].enabled = enabled;
        }

        if (menuTagDict[ApplicationPanelWithTable.OPEN_MD]) {
            menuTagDict[ApplicationPanelWithTable.OPEN_MD].enabled = enabled;
        }

        if (menuTagDict[ApplicationPanelWithTable.OPEN_TS]) {
            menuTagDict[ApplicationPanelWithTable.OPEN_TS].enabled = enabled;
        }

        if (menuTagDict[ApplicationPanelWithTable.OPEN_OE]) {
            menuTagDict[ApplicationPanelWithTable.OPEN_OE].enabled = enabled;
        }
    }

    public GetOpeningPanelsCMLocKeysSet (): any { return {}; }

    public getSelectedItemsNumber (): number {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) return 0;

        const selectedRowIds = qt.selectedRowIds;
        if ((selectedRowIds?.length) !== 0) {
            return selectedRowIds.length;
        }

        return 0;
    }

    public getSelectedDifferentItemsNumber (): number {
        const qt = this.getQuickTable();
        if (isNullOrUndefined(qt)) {
            return 0;
        }

        const selRowIds = qt.selectedRowIds;
        if (isNullOrUndefined(selRowIds) || selRowIds.length === 0) {
            return 0;
        }

        const firstItem = this.getInstrument();
        if (isNullOrUndefined(firstItem)) {
            return 0;
        }

        const firstID = firstItem.GetInteriorID();
        for (let i = 1; i < selRowIds.length; i++) // может быть выбрано несколько ордеров(позиций) одного и того же инструмента
        {
            const selRowID = selRowIds[i];
            const row = selRowID ? qt.rows[selRowID] : null;
            const ins: Instrument | null = row?.item?.GetCurrentInstrument() ?? null;

            if (!isNullOrUndefined(ins) || ins.GetInteriorID() !== firstID) // выбраны разные инструменты
            {
                return selRowIds.length; // пока нам достаточно знать, что выбрано хотя бы 2 разных инструмента
            }
        }

        return 1; // нулевой выбранный совпадает с остальными выбранными - это один и тот же инструмент
    }

    public updateSymbolInfoMenuItem (): void {
        const menuArr = this.menuTagDict;
        const menuItem = menuArr ? menuArr[ApplicationPanelWithTable.SYMBOLINFOPANEL] : null;
        this.updateMenuItem(menuItem);
    }

    public updateMenuItem (menuItem): void {
        if (isNullOrUndefined(menuItem)) {
            return;
        }

        const moreThanOneTTKey = this.moreThanOneTTKey;
        const noOneTTKey = this.noOneTTKey;

        const ins = this.getInstrument();
        const selInsNum = this.getSelectedDifferentItemsNumber();
        const enabled = !!(selInsNum === 1 && !isNullOrUndefined(ins));
        let tooltip = enabled ? '' : Resources.getResource(selInsNum ? moreThanOneTTKey : noOneTTKey);

        if (isNullOrUndefined(ins) && this.getSelectedItemsNumber() > 0) // выбранного инструмента нет у нас в Instruments (к примеру строка по скрытому или удаленному инструменту в Orders history)
        {
            tooltip = this.noSymbolWasFoundTooltip;
        }

        menuItem.enabled = enabled;
        menuItem.tooltip = tooltip;
        menuItem.style = enabled ? '' : 'js-context-menu-error';
    }

    public static tableToHtml (cols, rows, tableTitle): string {
        const visibleColumns = cols.filter(col => col.visible && !col.hidden);
        const tableHeader = visibleColumns.map(col => `<th style="border-collapse:collapse;border:thin solid black;">${col.headerText}</th>`).join('');
        const visibleColumnsID = visibleColumns.map(col => col.PRIVATE.index);

        const tableRows = rows.filter(row => row.visible).map(row => {
            const cells = visibleColumnsID.map(col => `<td style="border:none" align="center">${row.cells[col].formattedValue}</td>`);
            return `<tr>${cells.join('')}</tr>`;
        }).join('');

        const currentDate = DateTimeUtils.formatDate(new Date(), 'DD.MM.YYYY HH:mm');
        const acc = DataCache.getPrimaryAccount();

        const additionalInfo = `
        <h3>${DataCache.CompanyName || ''}</h3>
        <div style="position:absolute;right:24;width:242;">
            <table width="100%" style="border:none">
                <tr><td>${Resources.getResource('panel.OrderBook.Date')}:</td><td align="right">${currentDate}</td></tr>
                <tr><td>${Resources.getResource('panel.OrderBook.Login')}:</td><td align="right">${DataCache.UserLogin}</td></tr>
                <tr><td>${Resources.getResource('panel.accounts.Owner')}:</td><td align="right">${acc.FirstName} ${acc.LastName}</td></tr>
                <tr><td>${Resources.getResource('property.currency')}:</td><td align="right">${DataCache.getUserBaseCurrency()}</td></tr>
            </table>
        </div><br><br><br><br><br>`;

        return `${additionalInfo}<table width="100%"><caption><h4>${tableTitle}</h4></caption><tr>${tableHeader}</tr>${tableRows}</table>`;
    }

    public printTable (): void {
        const qt = this.getQuickTable();
        const title = Resources.getResource(PanelLocKeys[this.getType()]);
        const printWin = window.open('', '', 'width=' + screen.availWidth + ',height=' + screen.availHeight);
        const doc = printWin.document;

        doc.open();
        doc.write(ApplicationPanelWithTable.tableToHtml(qt.sortedColumns, qt.rowsArray, title));
        doc.close();

        printWin.print();

        const closePrintWin = (): void => { printWin.close(); };
        if (BrowserUtils.isChrome) {
            printWin.onafterprint = closePrintWin;
        } else {
            closePrintWin();
        }
    }

    public getCallBackInstrument (properties: DynProperty[], propName: string): Instrument | null {
        const dp = DynProperty.getPropertyByName(properties, propName);
        let symbol: Instrument | null = null;
        if (!isNullOrUndefined(dp?.value)) {
            symbol = DataCache.getInstrumentByName(dp.value);
        }

        if (isNullOrUndefined(symbol)) {
            symbol = SessionSettings.getFirstTradableInstrument();
        }
        return symbol;
    }
}

ApplicationPanel.extendWith(ApplicationPanelWithTable, {});
