// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { ConfirmationTypesEnum } from '../../Utils/Trading/ConfirmationTypesEnum';
import { OffsetModeViewEnum } from '../../Utils/Trading/OffsetModeViewEnum';
import { type Account } from '../cache/Account';
import { type Instrument } from '../cache/Instrument';
import { DataCache } from '../DataCache';
import { Resources } from '../../Localizations/Resources';
import { BaseSettings } from '../Settings/BaseGeneralSettingsWrapper';

export const DISPLAY_QUANTITY_IN_LOTS_V = 'property.general.showLotsFX';

export class _BaseGeneralSettingsUtils {
    public displayAmountInLots (): boolean {
        if (!Resources.isHidden(DISPLAY_QUANTITY_IN_LOTS_V)) {
            return BaseSettings.isQuantityInLots;
        }

        return false;
    }

    public existTradingSymbolIdOrFirst (symbolID: string): string | null {
        if (!isNullOrUndefined(symbolID)) {
            const foundSymbol = DataCache.getInstrumentByName(symbolID);
            if (!isNullOrUndefined(foundSymbol)) {
                return foundSymbol.GetInteriorID();
            }
        }
        const firstSymbol = DataCache.getFirstTradableInstrument();
        if (!isNullOrUndefined(firstSymbol)) {
            return firstSymbol.GetInteriorID();
        }

        return null;
    };

    public updateConfirmation (operationType, showConfirmation): void {
        if (operationType === undefined || showConfirmation === undefined) {
            return;
        }

        switch (operationType) {
        case ConfirmationTypesEnum.OrderCancel:
            if (BaseSettings.confirmOrderCancel !== showConfirmation) {
                BaseSettings.confirmOrderCancel = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.OrderPlace:
            if (BaseSettings.confirmOrderSending !== showConfirmation) {
                BaseSettings.confirmOrderSending = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.OrderPlaceAlert:
            if (BaseSettings.confirmPlaceAlert !== showConfirmation) {
                BaseSettings.confirmPlaceAlert = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.AlertCancel:
            if (BaseSettings.confirmCancelAlert !== showConfirmation) {
                BaseSettings.confirmCancelAlert = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.PositionClose:
            if (BaseSettings.confirmPositionClosing !== showConfirmation) {
                BaseSettings.confirmPositionClosing = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.PositionReverse:
            if (BaseSettings.confirmReversePosition !== showConfirmation) {
                BaseSettings.confirmReversePosition = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.Modify:
            if (BaseSettings.confirmOrderPositionsModification !== showConfirmation) {
                BaseSettings.confirmOrderPositionsModification = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.DealTickets:
            if (BaseSettings.openDialTicketsOnWorkspace !== showConfirmation) {
                BaseSettings.openDialTicketsOnWorkspace = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.ShowMarginDealTicketsOnWorkspace:
            if (BaseSettings.showMarginDealTicketOnWorkspace !== showConfirmation) {
                BaseSettings.showMarginDealTicketOnWorkspace = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.PositionSizeCalculatorInfoWindow:
            if (BaseSettings.positionSizeCalculatorInfoWindow !== showConfirmation) {
                BaseSettings.positionSizeCalculatorInfoWindow = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.SubscriptionsAndUnsubscriptions:
            if (BaseSettings.confirmSubscriptions !== showConfirmation) {
                BaseSettings.confirmSubscriptions = showConfirmation;
            }
            break;
        case ConfirmationTypesEnum.CopyTradingStartStopFollow:
            if (BaseSettings.confirmAccountFollowing !== showConfirmation) {
                BaseSettings.confirmAccountFollowing = showConfirmation;
            }
            break;
        }
    }

    public useConfirmation (type: ConfirmationTypesEnum): boolean {
        const confirmTypes = ConfirmationTypesEnum;
        switch (type) {
        case confirmTypes.OrderCancel:
            return BaseSettings.confirmOrderCancel;
        case confirmTypes.OrderPlace:
            return BaseSettings.confirmOrderSending;
        case confirmTypes.PositionClose:
            return BaseSettings.confirmPositionClosing;
        case confirmTypes.Modify:
            return BaseSettings.confirmOrderPositionsModification;
        case confirmTypes.DealTickets:
            return BaseSettings.openDialTicketsOnWorkspace;
        case confirmTypes.ShowMarginDealTicketsOnWorkspace:
            return BaseSettings.showMarginDealTicketOnWorkspace;
        case confirmTypes.OrderPlaceAlert:
            return BaseSettings.confirmPlaceAlert;
        case confirmTypes.AlertCancel:
            return BaseSettings.confirmCancelAlert;
        case confirmTypes.PositionSizeCalculatorInfoWindow:
            return BaseSettings.positionSizeCalculatorInfoWindow;
        case confirmTypes.SubscriptionsAndUnsubscriptions:
            return BaseSettings.confirmSubscriptions;
        case confirmTypes.CopyTradingStartStopFollow:
            return BaseSettings.confirmAccountFollowing;
        }

        throw new Error('WDGeneralSettingsUtils Not supported');
    }

    public needToShowWarning (confirmData: any = undefined): boolean { // some warnings ignore the warnOnWrongOrder setting #110331
        return BaseSettings.warnIfWrongOrder || (confirmData?.needShowWarningForce);
    }

    public get selectedTradingSymbol (): Instrument | null {
        const symbolID = BaseSettings.tradingSymbolID;
        if (!isNullOrUndefined(symbolID)) {
            const foundSymbol = DataCache.getInstrumentByName(symbolID);
            if (!isNullOrUndefined(foundSymbol)) {
                return foundSymbol;
            }
        }
        const firstSymbol = DataCache.getFirstTradableInstrument();

        return firstSymbol;
    };

    public set selectedTradingSymbol (symbolID: string) {
        if (!isNullOrUndefined(symbolID)) {
            BaseSettings.tradingSymbolID = symbolID;
        }
    };

    public existTradingAccountIdOrFirst (accountID: string): string | null {
        if (!isNullOrUndefined(accountID)) {
            const foundAccount = DataCache.GetAccountById(accountID);
            if (!isNullOrUndefined(foundAccount)) {
                return foundAccount.AcctNumber;
            }
        }
        const firstAccount = DataCache.getAccountFirst();
        if (!isNullOrUndefined(firstAccount)) {
            return firstAccount.AcctNumber;
        }

        return null;
    };

    public get selectedTradingAccount (): Account | null {
        const accountID = BaseSettings.tradingAccountID;
        if (!isNullOrUndefined(accountID)) {
            const foundAccount = DataCache.GetAccountById(accountID);
            if (!isNullOrUndefined(foundAccount)) {
                return foundAccount;
            }
        }
        const firstAccount = DataCache.getAccountFirst();
        if (!isNullOrUndefined(firstAccount)) {
            return firstAccount;
        }

        return null;
    };

    public set selectedTradingAccount (accountID: string) {
        BaseSettings.tradingAccountID = accountID;
    };

    public IsTicksFractionalForForex (): boolean {
        return BaseSettings.offsetType === OffsetModeViewEnum.TicksFractionalForForex;
    }
}
