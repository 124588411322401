// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { Rectangle } from '../../Geometry';
import { RowOrientation } from './Enums';
import { type ITreemapItem } from './ITreemapItem';

export class TreemapCalculator {
    private _fullAreaRect: Rectangle;
    private _fullAreaSquare: number;
    private _weightSum: number;

    public recalculate<T extends ITreemapItem>(list: T[], areaRect: Rectangle): void {
        const items = list.filter(x => this.isValidItem(x));

        this._fullAreaRect = areaRect;
        items.sort((x, y) => y.Weight - x.Weight);

        this._fullAreaSquare = this._fullAreaRect.Width * this._fullAreaRect.Height;
        this._weightSum = items.reduce((sum, item) => sum + item.Weight, 0);

        this.squarify(items, [], this.getShortestSide());
    }

    private isValidItem (item: ITreemapItem): boolean {
        return item != null && Math.round(item.Weight) !== 0;
    }

    private squarify (items: ITreemapItem[], row: ITreemapItem[], sideLength: number): void {
        if (items.length === 0) {
            if (row.length === 0) return;

            if (row.length === 1) {
                row[0].Rectangle = this._fullAreaRect; // остатки
            } else {
                this.computeTreeMaps(row, true);
            }
            return;
        }

        const item = items[0];
        const row2 = [...row, item];
        const items2 = items.slice(1);

        const worst1 = this.worst(row, sideLength);
        const worst2 = this.worst(row2, sideLength);

        if (row.length === 0 || worst1 > worst2) {
            this.squarify(items2, row2, sideLength);
        } else {
            this.computeTreeMaps(row, false);
            this.squarify(items, [], this.getShortestSide());
        }
    }

    private computeTreeMaps (items: ITreemapItem[], isLastRow: boolean): void {
        const orientation = this.getOrientation();

        let areaSum = 0;
        items.forEach(item => {
            areaSum += this.getRealArea(item);
        });

        let currentRow: Rectangle;

        if (isLastRow) {
            currentRow = new Rectangle(this._fullAreaRect.X, this._fullAreaRect.Y, this._fullAreaRect.Width, this._fullAreaRect.Height);
            this._fullAreaRect = new Rectangle(0, 0, 0, 0); // Предполагается, что это эквивалент RectangleF.Empty
        } else {
            if (orientation === RowOrientation.Horizontal) {
                const rowWidth = Math.floor(this._fullAreaRect.Height !== 0 ? areaSum / this._fullAreaRect.Height : 0);
                currentRow = new Rectangle(this._fullAreaRect.X, this._fullAreaRect.Y, rowWidth, this._fullAreaRect.Height);

                const rectX = this._fullAreaRect.X + currentRow.Width;
                const rectWidth = Math.floor(this._fullAreaRect.Width - currentRow.Width);
                this._fullAreaRect = new Rectangle(rectX, this._fullAreaRect.Y, Math.max(0, rectWidth), this._fullAreaRect.Height);
            } else {
                const rowHeight = Math.floor(this._fullAreaRect.Width !== 0 ? areaSum / this._fullAreaRect.Width : 0);
                currentRow = new Rectangle(this._fullAreaRect.X, this._fullAreaRect.Y, this._fullAreaRect.Width, rowHeight);

                const rectY = this._fullAreaRect.Y + currentRow.Height;
                const rectHeight = Math.floor(this._fullAreaRect.Height - currentRow.Height);
                this._fullAreaRect = new Rectangle(this._fullAreaRect.X, rectY, this._fullAreaRect.Width, Math.max(0, rectHeight));
            }
        }

        let prevX = currentRow.X;
        let prevY = currentRow.Y;

        items.forEach((item, i) => {
            item.Rectangle = this.getRectangle(orientation, item, prevX, prevY, currentRow.Width, currentRow.Height);
            if (orientation === RowOrientation.Horizontal) {
                prevY += item.Rectangle.Height;
            } else {
                prevX += item.Rectangle.Width;
            }

            // Корректировка расчетов
            if (i === items.length - 1) {
                const rect = item.Rectangle;

                const realEndPointX = currentRow.X + currentRow.Width;
                const realEndPointY = currentRow.Y + currentRow.Height;

                const calcEndPointX = rect.X + rect.Width;
                const calcEndPointY = rect.Y + rect.Height;

                if (realEndPointX !== calcEndPointX) {
                    const diffX = Math.floor(realEndPointX - calcEndPointX);
                    item.Rectangle = new Rectangle(rect.X, rect.Y, rect.Width + diffX, rect.Height);
                }

                if (realEndPointY !== calcEndPointY) {
                    const diffY = Math.floor(realEndPointY - calcEndPointY);
                    item.Rectangle = new Rectangle(rect.X, rect.Y, rect.Width, rect.Height + diffY);
                }
            }
        });
    }

    private getShortestSide (): number {
        return Math.min(this._fullAreaRect.Width, this._fullAreaRect.Height);
    }

    protected getOrientation (): RowOrientation {
        return this._fullAreaRect.Width > this._fullAreaRect.Height ? RowOrientation.Horizontal : RowOrientation.Vertical;
    }

    private worst (row: ITreemapItem[], sideLength: number): number {
        if (row.length === 0) return 0;

        let maxArea = 0;
        let minArea = Number.MAX_VALUE;
        let totalArea = 0;

        row.forEach(item => {
            const realArea = this.getRealArea(item);
            maxArea = Math.max(maxArea, realArea);
            minArea = Math.min(minArea, realArea);
            totalArea += realArea;
        });

        if (minArea === Number.MAX_VALUE) {
            minArea = 0;
        }

        if (totalArea === 0 || sideLength === 0 || minArea === 0) {
            return 0;
        }

        const val1 = Math.floor((sideLength * sideLength * maxArea) / (totalArea * totalArea));
        const val2 = Math.floor((totalArea * totalArea) / (sideLength * sideLength * minArea));
        return Math.max(val1, val2);
    }

    protected getRectangle (orientation: RowOrientation,
        item: ITreemapItem,
        x: number,
        y: number,
        width: number,
        height: number): Rectangle {
        if (orientation === RowOrientation.Horizontal) {
            return new Rectangle(x, y, width, width !== 0 ? Math.floor(this.getRealArea(item) / width) : 0);
        } else {
            return new Rectangle(x, y, height !== 0 ? Math.floor(this.getRealArea(item) / height) : 0, height);
        }
    }

    private getRealArea (item: ITreemapItem): number {
        return Math.floor(this._fullAreaSquare * item.Weight / this._weightSum);
    }
}
