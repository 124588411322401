// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { PFixFieldSet } from '../../../Fields/FieldsImport';
import { FieldsFactory } from '../../../Factory/FieldsFactory';

export class AlgorithmGainersLosersInstrumentGroup extends PFixFieldSet {
    constructor (pfixFieldSet: PFixFieldSet) {
        super(FieldsFactory.ALGORITHM_GAINERS_LOSERS_INSTRUMENT_GROUP, null, pfixFieldSet);
    }

    get Behavior (): number { return super.GetValue(FieldsFactory.FIELD_BEHAVIOR); }
    get InstrumentId (): number { return super.GetValue(FieldsFactory.FIELD_INSTRUMENT_ID); }
    get InstrumentName (): string { return super.GetValue(FieldsFactory.FIELD_NAME); }
    get InstrumentTypeId (): number { return super.GetValue(FieldsFactory.FIELD_INSTRUMENTTYPE_ID); }
    get InterestChange (): number { return super.GetValue(FieldsFactory.FIELD_INTEREST_CHANGE); }
    get LastPrice (): number { return super.GetValue(FieldsFactory.FIELD_LAST_PRICE); }
    get MarketDataExchange (): string { return super.GetValue(FieldsFactory.FIELD_MARKET_DATA_EXCHANGE); }
    get RouteID (): number { return super.GetValue(FieldsFactory.FIELD_ROUTE_ID); }
    get TradableInsID (): number { return super.GetValue(FieldsFactory.FIELD_TRADABLE_INSTRUMENT_ID); }
    get TradingExchange (): string { return super.GetValue(FieldsFactory.FIELD_TRADING_EXCHANGE); }
    get Volume (): number { return super.GetValue(FieldsFactory.FIELD_VOLUME); }
}
