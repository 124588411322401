// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iWPR extends iBuildInIndicator {
    public FRPeriod: any;

    constructor (period) {
        super(1);

        this.FRPeriod = period;
    }

    override get Name (): string { return 'iWPR'; }

    override get Key (): string { return this.DefaultKey + this.FRPeriod; }

    public override OnQuote (): void {
        if (this.Parent === null || this.FCount < this.FRPeriod) {
            return;
        }

        let high = 0.0;
        let low = 10000000.0;
        for (let period = 0; period < this.FRPeriod; period++) {
            let price = this.GetPrice(PriceType.High, period);
            if (price > high) {
                high = price;
            }
            price = this.GetPrice(PriceType.Low, period);
            if (price < low) {
                low = price;
            }
        }

        // %R = (HIGH(i-n)-CLOSE)/(HIGH(i-n)-LOW(i-n))*100
        if (high > 0.0 && (high - low) > 0.0) {
            this.SetValue(-100 * (high - this.GetPrice(PriceType.Close, 0)) / (high - low));
        } else {
            this.SetValue(0.0);
        }
    }
}
