// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { BaseItem, ColumnData } from './BaseItem';
import { QuickTableComparingType } from '../../Utils/QuickTableMisc/QuickTableComparingType';
import { ColumnParams } from './ColumnParams';
import { QuickTableColumnType } from '../elements/QuickTable/QuickTableMisc';
import { Resources } from '../../Localizations/Resources';
import { ThemeManager } from '../misc/ThemeManager';
import { IsHigh, type HighLowData } from '../../Commons/cache/Algorithm/HighLow/HighLowData';
import { type Instrument } from '../../Commons/cache/Instrument';
import { VolumeFormatter } from '../../Utils/Volume/VolumeFormatter';
import { DateTimeConvertor } from '../../Utils/Time/DateTimeConvertor';
import { DateTimeUtils } from '../../Utils/Time/DateTimeUtils';

export class HighLowItem extends BaseItem {
    private static StaticId: number = 0;
    private readonly Id: number;
    public readonly Data: HighLowData;
    public readonly Instrument: Instrument;
    public readonly CellForeColorMap = {};

    constructor (data: HighLowData) {
        super(null);
        this.Id = HighLowItem.StaticId;
        HighLowItem.StaticId++;

        this.Data = data;

        this.CellForeColorMap[HighLowItem.SYMBOL_COL_INDEX] = ThemeManager.CurrentTheme.TableGoldColor;
        this.CellForeColorMap[HighLowItem.PRICE_COL_INDEX] = IsHigh(data.EventType)
            ? ThemeManager.CurrentTheme.TableValueUpForeColor
            : ThemeManager.CurrentTheme.TableValueDownForeColor;
    }

    public GetItemId (): number { return this.ItemId as number; }

    public getColumnValue (column: number): any {
        switch (column) {
        case HighLowItem.SYMBOL_COL_INDEX:
            if (this.Data.InstrumentName != null) {
                return this.Data.InstrumentName;
            } else {
                return this.Instrument != null ? this.Instrument.DisplayName() : Resources.getResource('general.N_A');
            }
        case HighLowItem.CHANGE_COL_INDEX:
            return this.Data.AbsoluteChange;
        case HighLowItem.CHANGE_PR_COL_INDEX:
            return this.Data.InterestChange;
        case HighLowItem.PRICE_COL_INDEX:
            return this.Data.LastPrice;
        case HighLowItem.TIME_COL_INDEX:
            return this.Data.LastTradeDate;
        case HighLowItem.VOLUME_COL_INDEX:
            return this.Data.Volume;
        case HighLowItem.EXCHANGE_COL_INDEX:
            return this.Data.MarketDataExchange ?? this.Data.TradingExchange;
        }
        return '';
    }

    public getColumnData (column: number): ColumnData {
        const value: any = this.getColumnValue(column);
        let formattedValue = '';
        switch (column) {
        case HighLowItem.CHANGE_PR_COL_INDEX:
            formattedValue = isNaN(value) ? Resources.getResource('general.N_A') : value.toFixed(2);
            break;
        case HighLowItem.TIME_COL_INDEX:
            formattedValue = DateTimeUtils.FormatToDateAndTime(DateTimeConvertor.ConvertUTCTimeToSelectedTimeZone(value));
            break;
        case HighLowItem.VOLUME_COL_INDEX:
            formattedValue = VolumeFormatter.getVolumeFormat(value);
            break;
        default:
            formattedValue = value?.toString();
        }
        return new ColumnData(value, formattedValue);
    }

    // ITableItem methods
    public addItemListener (listener: any /* IItemListener */): void { }
    public removeItemListener (listener: any /* IItemListener */): void { }
    public ColumnCount (): number {
        return HighLowItem.columnsParams.length;
    }

    public GetColumnParams (i: number): any { /* ColumnParams | null */
        return HighLowItem.columnsParams[i];
    }

    public GetDataType (columnIndex: number): number { /* QuickTableComparingType */
        return HighLowItem.dataTypes[columnIndex];
    }
    // ITableItem methods end

    // Static Columns

    public static readonly SYMBOL_COL_INDEX = 0;
    public static readonly CHANGE_COL_INDEX = 1;
    public static readonly CHANGE_PR_COL_INDEX = 2;
    public static readonly PRICE_COL_INDEX = 3;
    public static readonly TIME_COL_INDEX = 4;
    public static readonly VOLUME_COL_INDEX = 5;
    public static readonly EXCHANGE_COL_INDEX = 6;

    private static readonly dataTypes: any /* QuickTableComparingType[] */ = [
        QuickTableComparingType.String,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.Double,
        QuickTableComparingType.DateTime,
        QuickTableComparingType.Double,
        QuickTableComparingType.String
    ];

    public static columnsParams: ColumnParams[] = [
        new ColumnParams('panel.HighLowPanel.Item.Symbol', 40, QuickTableColumnType.COL_DEFAULT, false, true, false),
        new ColumnParams('panel.HighLowPanel.Item.Change', 40, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.HighLowPanel.Item.ChangePr', 40, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.HighLowPanel.Item.Price', 40, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, true, false),
        new ColumnParams('panel.HighLowPanel.Item.Time', 40, QuickTableColumnType.COL_DATE_SORT, false, true, false),
        new ColumnParams('panel.HighLowPanel.Item.Volume', 40, QuickTableColumnType.COL_SIMPLE_NUMERIC, false, false, false),
        new ColumnParams('panel.HighLowPanel.Item.Exchange', 40, QuickTableColumnType.COL_DEFAULT, false, false, false)
    ];
    // Static Columns end
}
