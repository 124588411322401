// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { AccountFeature } from '@shared/utils/Account/AccountFeature';
import { Account } from '../../../cache/Account';

export class TvAccountGeneralData {
    public balance: number = 0;
    public projectedBalance: number = 0;
    public availableFunds: number = 0;
    public blockedBalance: number = 0;
    public unsettledCash: number = 0;

    public update (acc: Account): boolean {
        let isNeedUpdate = false;
        const balance = Account.GetAccountFeature(AccountFeature.Balance, acc, acc.assetBalanceDefault);
        if (balance !== this.balance) {
            this.balance = balance;
            isNeedUpdate = true;
        }

        const projectedBalance = Account.GetAccountFeature(AccountFeature.ProjectedBalance, acc, acc.assetBalanceDefault);
        if (projectedBalance !== this.projectedBalance) {
            this.projectedBalance = projectedBalance;
            isNeedUpdate = true;
        }

        const availableFunds = Account.GetAccountFeature(AccountFeature.AvailableFunds, acc, acc.assetBalanceDefault);
        if (availableFunds !== this.availableFunds) {
            this.availableFunds = availableFunds;
            isNeedUpdate = true;
        }

        const blockedBalance = Account.GetAccountFeature(AccountFeature.BlockedBalance, acc, acc.assetBalanceDefault);
        if (blockedBalance !== this.blockedBalance) {
            this.blockedBalance = blockedBalance;
            isNeedUpdate = true;
        }

        const unsettledCash = Account.GetAccountFeature(AccountFeature.UnsettledCash, acc, acc.assetBalanceDefault);
        if (unsettledCash !== this.unsettledCash) {
            this.unsettledCash = unsettledCash;
            isNeedUpdate = true;
        }

        return isNeedUpdate;
    }
}
