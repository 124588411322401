// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// TODO. !!! C# STRUCT.
// Настройка маржинальных коэффициентов для фьючерсов  MARGIN_COEFFICIENT_GROUP(32) в инструмент мессадже.
export class MarginCoefficients {
    /// может быть 0
    public FuturesExpDay = 0;
    /// может быть 0
    public FuturesExpMonth = 0;
    // может быть 0
    public FuturesExpYear = 0;

    public Futures_HOLD_MARGINGSIZE = 0;
    public Futures_INIT_MARGINGSIZE = 0;
    public Futures_INIT_MARGINGSIZE_OVERNIGHT = 0;
    public Futures_HOLD_MARGINGSIZE_OVERNIGHT = 0;
    public Futures_INIT_MARGINGSIZE_SHORT = 0;
    public Futures_HOLD_MARGINGSIZE_SHORT = 0;
    public Futures_INIT_MARGINGSIZE_OVERNIGHT_SHORT = 0;
    public Futures_HOLD_MARGINGSIZE_OVERNIGHT_SHORT = 0;
    public Futures_VAR_COEFF = 0;
    public Futures_USE_VAR_ONLY_INTRADAY = false;
    public Futures_VAR_COEFF_APPLYING = 0;
    public Futures_WARN_MARGINGSIZE: number;
    public Futures_WARN_MARGINGSIZE_OVERNIGHT: number;
    public Futures_WARN_MARGINGSIZE_SHORT: number;
    public Futures_WARN_MARGINGSIZE_OVERNIGHT_SHORT: number;
}
