// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { ApplicationInfo } from '../../Commons/ApplicationInfo';

const STOR_NAME = 'WL_List';
const _STORAGE = window.localStorage;

const TmpStorage =
{
    '220:462': { trd_id: '220', route: '462' },
    '212:462': { trd_id: '212', route: '462' },
    '217:462': { trd_id: '217', route: '462' },
    '221:462': { trd_id: '221', route: '462' },
    '219:462': { trd_id: '219', route: '462' },
    '2457:14545': { trd_id: '2457', route: '14545' },
    '2427:14545': { trd_id: '2427', route: '14545' },
    '2466:14545': { trd_id: '2466', route: '14545' },
    '2445:14545': { trd_id: '2445', route: '14545' },
    '2459:14545': { trd_id: '2459', route: '14545' },
    '208:3751': { trd_id: '208', route: '3751' },
    '2236:14545': { trd_id: '2236', route: '14545' },
    '2245:14545': { trd_id: '2245', route: '14545' },
    '2200:14545': { trd_id: '2200', route: '14545' },
    '2207:14545': { trd_id: '2207', route: '14545' },
    '2220:14545': { trd_id: '2220', route: '14545' },
    '2239:14545': { trd_id: '2239', route: '14545' },
    '2419:14545': { trd_id: '2419', route: '14545' },
    '2398:14545': { trd_id: '2398', route: '14545' },
    '2203:14545': { trd_id: '2203', route: '14545' },

    '1741:14544': { trd_id: '1741', route: '14544' },
    '1454:14544': { trd_id: '1454', route: '14544' }

};

let WL_List = {};
let AddRemHandler = null;
class _ListController {
    constructor () {
        const stor = _STORAGE.getItem(STOR_NAME);
        if (!stor) {
            WL_List = initStorage();
        } else {
            WL_List = loadStorage();
        }
    }

    public IsPresent (ins_interID): boolean {
        return !!WL_List[ins_interID];
    }

    public AddInstrument (instrument): void {
        if (!instrument) {
            return;
        }
        WL_List[instrument.GetInteriorID()] = { trd_id: instrument.InstrumentTradableID, route: instrument.Route };
        saveStorage();
        if (AddRemHandler) {
            AddRemHandler(true, instrument);
        }
    }

    public RemoveInstrument (instrument): void {
        if (!instrument) {
            return;
        }

        delete WL_List[instrument.GetInteriorID()];
        saveStorage();

        if (AddRemHandler) {
            AddRemHandler(false, instrument);
        }
    }

    public GetItems (): any {
        return WL_List;
    }

    public SetOnAddRemHandler (handler): void {
        AddRemHandler = handler;
    }
}

const saveStorage = function () {
    if (ApplicationInfo.isExploreMode) {
        return;
    }
    const data = JSON.stringify(WL_List);
    _STORAGE.setItem(STOR_NAME, data);
};

const initStorage = function () {
    const data = JSON.stringify(TmpStorage);
    _STORAGE.setItem(STOR_NAME, data);
    return TmpStorage;
};

const loadStorage = function () {
    return JSON.parse(_STORAGE.getItem(STOR_NAME));
};

export const WL_ListController = new _ListController();
