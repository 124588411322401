// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Md5 } from 'ts-md5';
import { blowfish } from 'sladex-blowfish';
import { type BaseExtLink } from '../BaseExtLink';
import { type AutochartistExtLink } from '../AutochartistExtLink';
import { type AutochartistIFrameExtLink } from '../AutochartistIFrameExtLink';
import { Connection } from '../../../Connection';
import { TimeSpanPeriods } from '../../../../Utils/Time/TimeSpan';

function md5 (input: string): string {
    return Md5.hashStr(input);
}

function base64Decode (input: string): Uint8Array {
    return Uint8Array.from(Buffer.from(input, 'base64'));
}

function blowfishEncrypt (data: string, key: Uint8Array): string {
    const bf = new blowfish(key);
    bf.setIv(null);
    bf.setEncoding('base64');
    return bf.encode(data);
}

export class BrandingToolLinkGenerator {
    static generateTCRPLink (
        tcrpURL: string,
        partnerID: string,
        userID: string,
        locale: string,
        encryptionKey: string
    ): URL | null {
        try {
            const url = new URL(tcrpURL);
            const queryParams = new URLSearchParams(url.search);

            const date = Math.floor(Date.now() / TimeSpanPeriods.TicksPerSecond);
            const tokenStr = `${partnerID},${userID},${locale},${date}`;

            const key = base64Decode(encryptionKey);
            if (key.length < 5 || key.length > 56) {
                return null;
            }

            const encryptedToken = blowfishEncrypt(tokenStr, key);

            queryParams.set('token', encryptedToken);
            url.search = queryParams.toString();

            return url;
        } catch {
            return null;
        }
    }

    static generateAutochartistLink (
        autochartistTool: AutochartistExtLink,
        userName: string,
        locale: string
    ): URL | null {
        try {
            const url = new URL(autochartistTool.urlStr);
            const host = `${autochartistTool.ssoDNS}.${url.hostname}`;
            const path = `${
                url.pathname.endsWith('/') ? url.pathname : url.pathname + '/'
            }${autochartistTool.ssoEndPoint}`;
            url.hostname = host;
            url.pathname = path;

            const isLive = autochartistTool.accountType.toUpperCase() === 'LIVE';
            const queryParams = new URLSearchParams(url.search);

            queryParams.set('username', userName);
            queryParams.set('account_type', autochartistTool.accountType);

            const expire = Math.floor(Date.now() / 1000) + 24 * 60 * 60; // 24 hours from now
            if (isLive) {
                queryParams.set('expire', expire.toString());
            }

            queryParams.set('locale', locale);

            const tokenAccType = isLive ? '0' : '1';
            const tokenStr = isLive
                ? `${userName}|${tokenAccType}|${expire}${autochartistTool.secretKey}`
                : `${userName}|${tokenAccType}${autochartistTool.secretKey}`;

            queryParams.set('logintoken', md5(tokenStr));
            url.search = queryParams.toString();

            return url;
        } catch {
            return null;
        }
    }

    static generateAutochartistIFrameLink (
        autochartistIFrameTool: AutochartistIFrameExtLink,
        userName: string,
        locale: string
    ): URL | null {
        try {
            const url = new URL(autochartistIFrameTool.urlStr);
            const isLive = autochartistIFrameTool.accountType.toUpperCase() === 'LIVE';
            const queryParams = new URLSearchParams(url.search);

            queryParams.set('broker_id', autochartistIFrameTool.brokerID);
            queryParams.set('account_type', autochartistIFrameTool.accountType);
            queryParams.set('user', userName);
            queryParams.set('trade_now', 'n');

            const expire = Math.floor(Date.now() / 1000) + 24 * 60 * 60; // 24 hours from now
            if (isLive) {
                queryParams.set('expire', expire.toString());
            }

            queryParams.set('locale', locale);
            queryParams.set(
                'css',
                'https://www.autochartist.com/components-css/traderevolution-to.css'
            );
            queryParams.set('layout', 'vertical');

            const tokenAccType = isLive ? '0' : '1';
            const tokenStr = isLive
                ? `${userName}|${tokenAccType}|${expire}${autochartistIFrameTool.secretKey}`
                : `${userName}|${tokenAccType}${autochartistIFrameTool.secretKey}`;

            queryParams.set('token', md5(tokenStr));
            url.search = queryParams.toString();

            return url;
        } catch {
            return null;
        }
    }

    static generateLinkTokenLangURL (brandingTool: BaseExtLink, locale: string): URL | null {
        try {
            const url = new URL(brandingTool.urlStr);
            const queryParams = new URLSearchParams(url.search);

            queryParams.set('token', Connection.vendor.loginParams.accessToken ?? '');
            queryParams.set('theme', 'night');
            queryParams.set('language', locale);
            queryParams.set('platform', 'TraderEvolution');

            url.search = queryParams.toString();
            return url;
        } catch {
            return null;
        }
    }

    static generateLinkNoneURL (brandingTool: BaseExtLink): URL | null {
        try {
            return brandingTool.urlStr ? new URL(brandingTool.urlStr) : null;
        } catch {
            return null;
        }
    }
}
