// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { PriceType } from '../../Utils/History/CashItemUtils';
import { TerceraChartCashItemSeriesDataType } from '../Series/TerceraChartCashItemSeriesEnums';
import { TerceraChartDrawingType } from './ChartConstants';

/// <summary>
/// Конвертер-пустышка, ничего не делает
/// </summary>
export class ScreenPointsConverterEmpty {// : IScreenPointsConverter
    public Convert (window, refScreenXY): boolean {
        return false;
    }
}

/// <summary>
/// Snap to candle
/// </summary>
// IScreenPointsConverter
export class ScreenPointsConverterSnap {
    public static DX = 10;
    public static DY = 10;

    public Convert (window, refScreenXY): boolean {
        if (window == null) {
            return false;
        }

        const mainPrice = window.GetMainThreadRenderer('TerceraChartMainPriceRenderer');
        if (mainPrice == null) {
            return false;
        }

        const drawingStyle = mainPrice.ChartDrawingType;
        if (drawingStyle == TerceraChartDrawingType.Dot || drawingStyle == TerceraChartDrawingType.DotLine ||
        drawingStyle == TerceraChartDrawingType.Forest || drawingStyle == TerceraChartDrawingType.Line ||
        drawingStyle == TerceraChartDrawingType.Solid) {
            return false;
        }

        const cashItemSeries = mainPrice.Series;
        if (cashItemSeries == null) {
            return false;
        }

        const cashitem = cashItemSeries.CashItem;
        const pointsConverter = window.PointsConverter;

        if (cashitem.FPeriod == 0)
        // для 1тикового графика не стикаемся
        {
            return false;
        }

        // ищем индекс бара, над которым находится курсор. Скипаем пустые бары слева и справа от данных
        const time = pointsConverter.GetDataX(refScreenXY.screenX);
        const barIndex = cashitem.FindIntervalByTime(time);
        if (barIndex < 0 || barIndex >= cashitem.Count()) {
            return false;
        }

        const outdxdy: any = {};
        outdxdy.dx = 0;
        outdxdy.dy = 0;

        // проверяем текущий бар (под курсором)
        this.SnapToBar(window, refScreenXY.screenX, refScreenXY.screenY, cashitem, pointsConverter, barIndex, outdxdy, cashItemSeries);
        if (ScreenPointsConverterSnap.SnapTo(refScreenXY, outdxdy.dx, outdxdy.dy)) {
            return true;
        }

        // проверяем бар справа, если справа есть данные, и мы не в конце экрана
        if (barIndex + 1 < cashitem.Count() && barIndex < window.i1) {
            this.SnapToBar(window, refScreenXY.screenX, refScreenXY.screenY, cashitem, pointsConverter, barIndex + 1, outdxdy, cashItemSeries);
            if (ScreenPointsConverterSnap.SnapTo(refScreenXY, outdxdy.dx, outdxdy.dy)) {
                return true;
            }
        }

        // проверяем бар слева, если слева есть данные, и мы не в конце экрана
        if (barIndex > 0 && barIndex > (window.i1 - Math.ceil(window.im) + 1)) {
            this.SnapToBar(window, refScreenXY.screenX, refScreenXY.screenY, cashitem, pointsConverter, barIndex - 1, outdxdy, cashItemSeries);
            if (ScreenPointsConverterSnap.SnapTo(refScreenXY, outdxdy.dx, outdxdy.dy)) {
                return true;
            }
        }

        return false;
    }

    /// <summary>
    /// Если можно - перемещаю координаты
    /// </summary>
    public static SnapTo (refScreenXY, dx: number, dy: number): boolean {
        if (Math.abs(dx) <= ScreenPointsConverterSnap.DX && Math.abs(dy) <= ScreenPointsConverterSnap.DY) {
            refScreenXY.screenX += dx;
            refScreenXY.screenY += dy;
            return true;
        } else {
            return false;
        }
    }

    /// <summary>
    /// Определить для указанного бара путь для перемещения тулзы (dx, dy) как приращение текущих координат (screenX, screenY)
    /// </summary>
    public SnapToBar (window, screenX, screenY, cashitem, pointsConverter, barIndex, outdxdy, series = null): void {
    // время начала и конца бара
        const openTime = cashitem.GetOpenTime(barIndex);
        const closeTime = cashitem.GetCloseTime(barIndex);

        // экранные координаты по X начала, конца и середины бара
        const leftX = pointsConverter.GetScreenXbyTime(openTime);
        const middleX = pointsConverter.GetScreenXbyTime((openTime + closeTime) / 2);
        const rightX = pointsConverter.GetScreenXbyTime(closeTime);

        // экранные координаты по Y OHLC цен
        // не забываем, что Y-координата High меньше Low (выглядит нелогично), так как нумерация ординат идет сверху экрана

        const dataType = series != null ? series.settings.DataType : TerceraChartCashItemSeriesDataType.Absolute;

        let highY = 0;
        let openY = 0;
        let closeY = 0;
        let lowY = 0;

        switch (dataType) {
        case TerceraChartCashItemSeriesDataType.Absolute:
            highY = pointsConverter.GetScreenY(cashitem.GetByType(barIndex, PriceType.High));
            openY = pointsConverter.GetScreenY(cashitem.GetByType(barIndex, PriceType.Open));
            closeY = pointsConverter.GetScreenY(cashitem.GetByType(barIndex, PriceType.Close));
            lowY = pointsConverter.GetScreenY(cashitem.GetByType(barIndex, PriceType.Low));
            break;

        case TerceraChartCashItemSeriesDataType.Relative:
            highY = pointsConverter.GetScreenY(series.settings.relativeDataConverter.Calculate(cashitem.GetByType(barIndex, PriceType.High)));
            openY = pointsConverter.GetScreenY(series.settings.relativeDataConverter.Calculate(cashitem.GetByType(barIndex, PriceType.Open)));
            closeY = pointsConverter.GetScreenY(series.settings.relativeDataConverter.Calculate(cashitem.GetByType(barIndex, PriceType.Close)));
            lowY = pointsConverter.GetScreenY(series.settings.relativeDataConverter.Calculate(cashitem.GetByType(barIndex, PriceType.Low)));
            break;

        case TerceraChartCashItemSeriesDataType.Log:
            highY = pointsConverter.GetScreenY(series.settings.logDataConverter.Calculate(cashitem.GetByType(barIndex, PriceType.High)));
            openY = pointsConverter.GetScreenY(series.settings.logDataConverter.Calculate(cashitem.GetByType(barIndex, PriceType.Open)));
            closeY = pointsConverter.GetScreenY(series.settings.logDataConverter.Calculate(cashitem.GetByType(barIndex, PriceType.Close)));
            lowY = pointsConverter.GetScreenY(series.settings.logDataConverter.Calculate(cashitem.GetByType(barIndex, PriceType.Low)));
            break;
        }

        // расстояние до центральной линии (середине)
        const centerDX = middleX - screenX;
        const centerDY = Math.max(Math.min(screenY, lowY), highY) - screenY;
        const centerDistance = Math.abs(centerDX) + Math.abs(centerDY);

        // расстояние до линии open
        const openDX = Math.min(Math.max(screenX, leftX), rightX) - screenX;
        const openDY = openY - screenY;
        const openDistance = Math.abs(openDX) + Math.abs(openDY);

        // расстояние до линии close
        const closeDX = openDX;
        const closeDY = closeY - screenY;
        const closeDistance = Math.abs(closeDX) + Math.abs(closeDY);

        // вычисляю расстояние (просто как сумму модулей, чтобы с квадратами не связываться, хотя правильно Math.Sqrt(dx*dx + dy*dy) )
        // и ищу наименьшее - туда будем пытаться стикнуться

        if (centerDistance <= openDistance && centerDistance <= closeDistance) {
            outdxdy.dx = centerDX;
            outdxdy.dy = centerDY;
        } else if (openDistance <= centerDistance && openDistance <= closeDistance) {
            outdxdy.dx = openDX;
            outdxdy.dy = openDY;
        } else if (closeDistance <= centerDistance && closeDistance <= openDistance) {
            outdxdy.dx = closeDX;
            outdxdy.dy = closeDY;
        } else {
        // невозможный кейс
            outdxdy.dx = 0;
            outdxdy.dy = 0;
        }
    }
}

export class ScreenPointsConverterFactory {
    public static GetScreenPointsConverter (useSnap): ScreenPointsConverterSnap | ScreenPointsConverterEmpty {
        if (useSnap) {
            return new ScreenPointsConverterSnap();
        } else {
            return new ScreenPointsConverterEmpty();
        }
    }

    /// // <summary>
    /// // Позволяет включать/выключать режим Snap To Candle
    /// // </summary>
    // public interface ISnapToCandle
    // {
    //    void ApplyNewSnapMode(bool useSnap);
    // }
}
