// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { MathUtils } from '../../../../../Utils/MathUtils';
import { PriceType } from '../../../../../Utils/History/CashItemUtils';
import { iBuildInIndicator } from '../../iBuildInIndicator';

export class iOBV extends iBuildInIndicator {
    constructor (priceType) {
        super(1);
        this.FPriceType = priceType;
    }

    public get Name (): string {
        return 'iOBV';
    }

    public get Key (): string {
        return this.DefaultKey + this.FPriceType;
    }

    public OnQuote (ci, callBound, callFromRefresh): void {
        const Parent = this.Parent;
        if (!Parent) { return; };

        const FCount = this.FCount;
        const FPriceType = this.FPriceType;

        if (FCount === 1) {
            this.SetValue(Parent.GetVolume(0), undefined, undefined); // this.SetValue(Utils.MAXVALUE)
            return;
        }

        const curPrice = this.GetPrice(FPriceType, 0);
        const prevPrice = this.GetPrice(FPriceType, 1);
        const prevVolume = FCount === 2 ? MathUtils.ProcessNaN(Parent.GetVolume(0)) : this._GetValue(0, 1);

        if ((
            FPriceType === PriceType.Typical &&
        Math.abs(prevPrice - curPrice) < 0.00001) ||
        prevPrice === curPrice) {
            this.SetValue(prevVolume, undefined, undefined);
            return;
        }

        const volume = MathUtils.ProcessNaN(Parent.GetVolume(FCount - 1));
        if (curPrice > prevPrice) {
            this.SetValue(prevVolume + volume, undefined, undefined);
        } else /* if (curPrice < prevPrice) */
        {
            this.SetValue(prevVolume - volume, undefined, undefined);
        }
    };
}
