// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { InvestingComboBoxTemplate } from '../../templates.js';
import { TerceraComboBox } from '../../../Controls/elements/TerceraComboBox';
import { closeContextMenuEvent, hideContextMenu, openContextMenu } from './InvestingMenu';

export class InvestingComboBox extends TerceraComboBox {
    constructor () {
        super();
    }

    public override getType (): string { return 'InvestingComboBox'; };

    public override private_BtnClick (sender): void {
        if (!this.get('enabled') || !this.get('selectedItem')) {
            return;
        }

        if (this.isMenuShown) {
            this.isMenuShown = !this.isMenuShown;
            this.HideMenu();
            return;
        }

        this.MenuShow(sender);
    }

    public override MenuShow (evt): void {
        const posElement = evt.original.currentTarget.getBoundingClientRect();

        const width = posElement.width;
        const height = posElement.height;
        const posX = posElement.left + window.pageXOffset;
        const posY = posElement.top + window.pageYOffset + height;

        void this.set('focused', true);

        const additionalParams = {
            width: this.get('listWidth') || width,
            isShiftComboBox: height
        };

        this.isMenuShown = true;
        openContextMenu(posX, posY, this.menuItems, additionalParams);
        closeContextMenuEvent.Subscribe(this.LostFocus, this);
    }

    public override HideMenu (): void {
        hideContextMenu();
    }

    public LostFocus (): void {
        closeContextMenuEvent.UnSubscribe(this.LostFocus, this);
        void this.set('focused', false);
        this.isMenuShown = false;
    }
}

TerceraComboBox.extendWith(InvestingComboBox, {
    template: InvestingComboBoxTemplate,
    data: function () {
        return {
            showLastValue: false,
            items: [],
            selectedItem: null,
            defaultValue: null,
            tooltip: '',
            listWidth: 0,
            usePNGView: false,
            showArrow: true,
            visibleInfoBtn: false,
            infoBtnTooltip: '',
            updateComboBoxClass: '' // Развитие возможно всегда. Идеал — цель, которая всегда меняется. Эволюция никогда не останавливается. Нельзя останавливаться!
        };
    }
});
