// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Rectangle } from '../../../Commons/Geometry';
import { ControlEvents } from '../Control';
import { TerceraWindowBase } from '../../screen/TerceraWindowBase';
import { MainWindowManager } from '../../UtilsClasses/MainWindowManager';
import { CheckBoxEvents } from '../TerceraCheckBox';
import { type TerceraQuickTree, TerceraQuickTreeEvents } from '../QuickTree/TerceraQuickTree';
import { TextBoxEvents } from '../TerceraTextBox';
import { TerceraMenu } from '../TerceraMenu';
import { Resources } from '../../../Localizations/Resources';
import { QuickTreeNode } from '../QuickTree/QuickTreeNode';
import { type LookupDropDownShowParams } from '../../UtilsClasses/LookupDropDownShowParams';
import { type TerceraSymbolLookupBaseDataProvider } from '../../../Commons/NoNFixedListCore';

export class TerceraLookupDropDownForm<TNode extends QuickTreeNode = QuickTreeNode> extends TerceraWindowBase {
    public callBack: any = null;
    public closeCallback: any = null;
    public textBox: any = null;
    public quickTree: TerceraQuickTree<TNode> = null;
    public items: any[] | null = null;
    public currentNameFilter: string = null;
    public oldNameFilter: string = null;
    public asyncPopulateInterval: any = null;
    public selectedItem: any = null;
    public isMultiSelect: boolean = false;
    public isMultiSelectMode: boolean = false;
    public autoClose: boolean = true;
    public forceCloseOnLogout: boolean = false;
    public caller: any = null;
    public OnCompliteWaiter: Promise<any>;
    public dataProvider: TerceraSymbolLookupBaseDataProvider;
    public showMAM: boolean;
    public isOptionMasterMode: boolean;
    public cachedItems: any;
    private _complete_finished: (value: any) => void;

    public override getType (): string { return 'TerceraLookupDropDownForm'; }

    public override oninit (): void {
        super.oninit();
        this.OnCompliteWaiter = new Promise((resolve, reject) => {
            this._complete_finished = resolve;
        });
    }

    public override oncomplete (): void {
        super.oncomplete();

        this.initializationComponents();
        this.on(ControlEvents.LostFocus, function () {
        // remove call stack overflow
            const caller = this.caller;
            this.caller = null;
            // TODO unificate  AfterLostFocusWaiting
            if (!isNullOrUndefined(caller) && !this.AfterLostFocusWaiting) {
                caller.setFocus();
            }

            if (this.autoClose) {
                if (!isNullOrUndefined(this.closeCallback)) {
                    this.closeCallback();
                    this.closeCallback = null;
                }
                this.set('visible', false);
            }
        }.bind(this));

        if (!isNullOrUndefined(this.Controls.multiSelect)) {
            this.Controls.multiSelect.on(CheckBoxEvents.CheckedStateChange,
                function (sender, value) {
                    if (this.quickTree.quickTree.UseCHB) {
                        this.quickTree.quickTree.isMultiSelectCheckboxUsing = value;
                    } else {
                        this.quickTree.quickTree.isMultiSelect = value;
                    }
                    this.quickTree.quickTree.Draw();
                }.bind(this));
        }

        this.quickTree.on(TerceraQuickTreeEvents.DoubleClick,
            function () {
                if (this.quickTree.quickTree.isMultiSelectCheckboxUsing) return;
                this.onAddBtnClicked(); // #84756 (2nd issue)
                this.hide();
            }.bind(this));

        this.quickTree.on(TerceraQuickTreeEvents.EnterPress,
            this.onEnterPressed.bind(this));

        this.textBox.on(TextBoxEvents.TextChanged,
            function (context, value) {
                this.currentNameFilter = value === '' ? null : value.toLowerCase();
                this.populateAsync();
            }.bind(this));

        this.quickTree.quickTree.SelectionChanged.Subscribe(this.selectionChanged, this);
        this.selectionChanged();

        this.localize();

        // Promise
        this._complete_finished(this);
        this._complete_finished = null;
        this.OnCompliteWaiter = null;
    }

    public override localize (): void {
        void this.set({
            selectAllText: Resources.getResource('TerceraAccountLookup.Select All'),
            multiSelectText: Resources.getResource('TerceraAccountLookup.Multy select'),
            cancelBtnText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Cancel')
        });
    }

    public initializationComponents (): void {
        throw new Error('Not implemented');
    }

    public onAddBtnClicked (isBtnClick = false): void {
        if (isBtnClick && !this.get('enabledAddBtn')) {
            return;
        }

        const qt = this.quickTree.quickTree;
        if (this.get('isMultiSelect')) {
            if (!isNullOrUndefined(this.callBack) && !isNullOrUndefined(qt.selectedNodesDict)) {
                let result = [];
                const dict = qt.selectedNodesDict;
                const keys = Object.keys(dict);
                const len = keys.length;
                for (let i = 0; i < len; i++) {
                    const curNode = dict[keys[i]];
                    if (curNode.childNodes?.length > 0) {
                        result = result.concat(this.recursionAddCheckedResults(curNode.childNodes));
                    } else if (!isNullOrUndefined(curNode.tag) && this.isValidResult(curNode.tag)) {
                        result.push(curNode.tag);
                    }
                }
                this.callBack(result);
            }
        } else if (!isNullOrUndefined(this.callBack) && !isNullOrUndefined(qt.selectedNode)) {
            this.callBack(qt.selectedNode.tag);
        }
        this.close();
    }

    protected isValidResult (nodeTag: any): boolean {
        return true;
    }

    public onEnterPressed (): void {
    // TODO.
        if (!this.get('enabledAddBtn')) { return; }

        this.onAddBtnClicked();
    }

    public selectionChanged (): void {
        const qt = this.quickTree.quickTree;
        if (this.get('isMultiSelect')) {
            const len = this.getSelectedNodesCount();
            void this.set({
                addBtnText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Add') + (len ? '[' + len + ']' : ''),
                enabledAddBtn: this.isButtonAddEnable()
            });
            this.changeCheckedNodes();
            qt.Draw();
        } else {
            void this.set({
                addBtnText: Resources.getResource('panel.TerceraSymbolLookupDropDownForm.Add'),
                enabledAddBtn: this.isButtonAddEnable()
            });
        }
    }

    public changeCheckedNodes (): void {
        const qt = this.quickTree.quickTree;
        const nodesArr = qt.nodeCollection;
        if (nodesArr.length > 0) {
            for (const node of nodesArr) {
                const childNodesArr = node.childNodes;
                if (childNodesArr.length > 0) {
                    node.checked = true;
                    for (const child of childNodesArr) {
                        if (qt.selectedNodesDict[child.nodeText + node.nodeText]) {
                            child.checked = true;
                        } else {
                            child.checked = false;
                            node.checked = false;
                        }
                    }
                }
            }
        }
    }

    public getSelectedNodesCount (): number {
        const qt = this.quickTree.quickTree;
        return !isNullOrUndefined(qt.selectedNodesDict) ? Object.keys(qt.selectedNodesDict).length : 0;
    }

    public isButtonAddEnable (): boolean {
        const qt = this.quickTree.quickTree;
        if (this.get('isMultiSelect')) {
            const len = !isNullOrUndefined(qt.selectedNodesDict) ? Object.keys(qt.selectedNodesDict).length : 0;
            return !!len;
        } else {
            return !isNullOrUndefined(qt.selectedNode?.tag);
        }
    }

    public recursionAddCheckedResults (nodes): any[] {
        let result = [];
        const len = nodes.length;
        for (let i = 0; i < len; i++) {
            const curNode = nodes[i];
            if (curNode.childNodes.length > 0) {
                result = result.concat(this.recursionAddCheckedResults(curNode.childNodes));
            } else if (!isNullOrUndefined(curNode.tag) && this.isValidResult(curNode.tag)) {
                result.push(curNode.tag);
            }
        }
        return result;
    }

    public override close (): void {
        const qt = this.quickTree.quickTree;
        qt.selectedNodesDict = {};
        qt.selectedNode = null;
        this.autoClose = true;
        this.lostFocus();
        if (!isNullOrUndefined(this.AfterLostFocusWaiting)) {
            this.AfterLostFocusWaiting.setFocus();
            this.AfterLostFocusWaiting = null;
        }
    }

    public async showForm (parametersStruct: LookupDropDownShowParams): Promise<void> {
        this.themeChange();

        this.setParentPanel(parametersStruct.parentPanel);
        void this.set('visible', true);

        if (!isNullOrUndefined(parametersStruct.caller)) {
            await this.set('parentContainerControl', parametersStruct.caller);
        }

        const isMultiSelect = !!parametersStruct.isMultiSelect;
        void this.set('isMultiSelect', isMultiSelect);
        if (!isNullOrUndefined(this.Controls.multiSelect)) {
            this.Controls.multiSelect.set({
                visible: !!parametersStruct.isMultiSelectMode,
                checked: isMultiSelect
            });
        }

        this.quickTree.setIsMultiSelect(isMultiSelect);
        if (parametersStruct.isMultiSelectCheckboxUsing) {
            this.quickTree.quickTree.isMultiSelect = false;
        }
        this.quickTree.quickTree.isMultiSelectCheckboxUsing = !!parametersStruct.isMultiSelectCheckboxUsing;
        this.quickTree.quickTree.UseCHB = !!parametersStruct.isMultiSelectCheckboxUsing;

        void this.set('isShowFooterBtn', isMultiSelect || !!parametersStruct.showButtons);

        void this.set({ isShowSelectAll: parametersStruct.isShowSelectAll });
        void this.set('isOptionMasterMode', parametersStruct.isOptionMasterMode);

        const newCoord = TerceraMenu.CorrectPopupLocation(new Rectangle(parametersStruct.x, parametersStruct.y, this.get('width'), this.get('height')), null);
        this.setLocation(newCoord.newX, newCoord.newY);
        this.callBack = parametersStruct.callBack;
        this.closeCallback = parametersStruct.closeCallback;
        this.items = parametersStruct.items;
        this.selectedItem = parametersStruct.selectedItem;
        this.dataProvider = parametersStruct.dataProvider;
        this.showMAM = parametersStruct.showMAM;
        this.isOptionMasterMode = parametersStruct.isOptionMasterMode;
        this.fillTree();
        this.cachedItems = parametersStruct.items;

        this.textBox.setFocus();
        this.textBox.set({
            text: '',
            placeholder: Resources.getResource('screen.lookup.scripts.watermark')
        });
        this.autoClose = parametersStruct.autoClose;

        if (!isNullOrUndefined(parametersStruct.caller)) {
            this.caller = parametersStruct.caller;
        }

        if (parametersStruct.isCentered) {
            this.center();
        }

        this.localize();
    }

    public hide (): void {
        const qt = this.quickTree.quickTree;
        if (!isNullOrUndefined(this.callBack) && !isNullOrUndefined(qt.selectedNode)) {
            this.callBack(qt.selectedNode.tag);
        }
        this.currentNameFilter = null;
        this.oldNameFilter = null;
        this.close();
    }

    public populateAsync (): void {
        clearTimeout(this.asyncPopulateInterval);
        this.asyncPopulateInterval = null;
        this.asyncPopulateInterval = setTimeout(this.fillTree.bind(this), 300);
    }

    public fillTree (): void {
        const items = this.items;
        const selectedItem = this.selectedItem;
        let selectedNode;

        const len = items.length;
        const nodeRange = [];
        for (let i = 0; i < len; i++) {
            const curItem = items[i];

            if (this.currentNameFilter !== null) {
                const curItemFindStr = curItem.toString().toLowerCase();
                if (!curItemFindStr.includes(this.currentNameFilter)) { continue; }
            }
            const node = new QuickTreeNode(curItem.toString());
            node.tag = curItem;
            if (selectedItem === curItem) {
                selectedNode = node;
                node.selected = true;
            }
            nodeRange.push(node);
        }

        this.quickTree.quickTree.Clear();
        this.quickTree.quickTree.AddNodeRange(nodeRange);
        this.quickTree.quickTree.setSelectedNode(selectedNode);
        this.quickTree.quickTree.goToSelectedNode();
    }

    public themeChange (): void {

    }

    public override afterParentPanelTeardownCallback (): void {
        this.hide();
    }

    public static createInstance (): any {
        const lookup = new TerceraLookupDropDownForm();
        lookup.setBounds(200, 200, 200, 200);
        void lookup.set({
            visible: false
        });
        MainWindowManager.MainWindow.addControl(lookup);
        return lookup;
    }
}

TerceraWindowBase.extendWith(TerceraLookupDropDownForm, {
    data: function () {
        return {
            showHeader: false,
            showFooter: false,
            movable: false,
            resizable: false,
            isLookup: true,
            isMultiSelect: false,
            multiSelectText: '',
            addBtnText: '',
            cancelBtnText: '',
            enabledAddBtn: false,
            isOptionMasterMode: false
        };
    }
});
