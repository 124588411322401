// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
import { DataCache } from '../../../DataCache';
import { Resources } from '../../../../Localizations/Resources';
import { getAccountTypeStr } from '../../../../Utils/Account/AccountType';
import { InfoGroupEnum } from '../../../../Utils/Enums/AccountDetailsEnum';
import { RulesSet } from '../../../../Utils/Rules/RulesSet';
import { BaseAccountGroupController } from '../BaseControllers/BaseAccountGroupController';
import { type IGroupController } from '../../Interfaces/IGroupController';
import { RowItemDataNumberPair, RowItemDataStringPair } from '../../../cache/VerticalPanel/AccountDetails/RowItemData';
import { VerticalPanelColoringTypes } from '../../VerticalPanelColoringTypes';

export class InfoGroupController<GroupItemsType = InfoGroupEnum> extends BaseAccountGroupController<GroupItemsType> implements IGroupController<GroupItemsType> {
    public getGroupItemsTypes (): GroupItemsType[] {
        return [
            InfoGroupEnum.Owner,
            InfoGroupEnum.UserID,
            InfoGroupEnum.UserLogin,
            InfoGroupEnum.UserGroup,
            InfoGroupEnum.Email,
            InfoGroupEnum.Login,
            InfoGroupEnum.AccountType,
            InfoGroupEnum.LockedBy,
            InfoGroupEnum.AccountMode,
            InfoGroupEnum.ClientType,
            InfoGroupEnum.Status,
            InfoGroupEnum.TelephoneNumber,
            InfoGroupEnum.Asset,
            InfoGroupEnum.AssetDescription,
            InfoGroupEnum.AssetType
        ] as GroupItemsType[];
    }

    public getItemValue (type: GroupItemsType): RowItemDataNumberPair {
        return new RowItemDataNumberPair(NaN);
    }

    public getItemFormattedValue (type: GroupItemsType): RowItemDataStringPair {
        const curAccount = this.Account;
        const assetBalance = this.AssetBalance;

        switch (type) {
        case InfoGroupEnum.Login:
            return new RowItemDataStringPair(curAccount.FullAccString);
        case InfoGroupEnum.AccountType:
            return new RowItemDataStringPair(Resources.getResource('enum.AccountType.' + getAccountTypeStr(curAccount.AccountType)));
        case InfoGroupEnum.Asset:
            return new RowItemDataStringPair(assetBalance.Asset.Name);

        default: return new RowItemDataStringPair('');
        }
    }

    public getItemNameKey (type: GroupItemsType): string {
        switch (type) {
        case InfoGroupEnum.Login:
            return 'panel.accounts.Login';
        case InfoGroupEnum.AccountType:
            return 'panel.accounts.AccountType';
        case InfoGroupEnum.Asset:
            return 'panel.accounts.Asset';
        }
    }

    public isHiddenItemRow (type: GroupItemsType): boolean {
        const itemNameKey = this.getItemNameKey(type);
        if (Resources.isHidden(itemNameKey)) {
            return true;
        }

        if (DataCache.isAllowedForMyUser(RulesSet.FUNCTION_EXT_RISK)) {
            return true;
        }

        return true;
    }

    public getColoringMethod (type: GroupItemsType): VerticalPanelColoringTypes {
        return VerticalPanelColoringTypes.Default;
    }
}
