// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.
// содержит определение,

import { type RiskPlanDataKey } from './RiskPlanDataKey';
import { RiskPlanItem } from './RiskPlanItem';

// к какой группе инструментов применить указанный план
export class RiskPlanData {
    public Item = new RiskPlanItem();
    public RiskPlanDataKey: RiskPlanDataKey | null = null;

    public toString (): string {
        return 'TODO. RiskPlanData.prototype.toString';
    }
}
