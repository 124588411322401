// Copyright TraderEvolution Global LTD. © 2017-2025. All rights reserved.

import { Color } from '../../../Graphics';
import { PriceType, PriceTypeMap } from '../../../../Utils/History/CashItemUtils';
import { IndicatorScriptBase } from '../IndicatorScriptBase';
import { LineStyle } from '../IndicatorScriptBaseEnums';
import { InputParameterInteger } from '../InputParamaterClasses/InputParameterInteger';
import { InputParameterCombobox } from '../InputParamaterClasses/InputParameterCombobox';

export class LWMA extends IndicatorScriptBase {
    public MaPeriod: number;
    public SourcePrice: number;

    constructor () {
        super();
        this.Comments = 'The linear average price for the last N periods';
        this.ProjectName = 'Linear Weighted Moving Average';
        this.SetIndicatorLine('Line', Color.GreenYellow, 1, LineStyle.SimpleChart);
        this.SeparateWindow = false;

        this.MaPeriod = 2;
        super.InputParameter(new InputParameterInteger('MaPeriod', 'Period of Linear Weighted Moving Average', 1, 1, 9999));

        this.SourcePrice = PriceType.Close;
        super.InputParameter(new InputParameterCombobox('SourcePrice', 'Sources prices for MA', 1,
            [
                { Close: PriceType.Close },
                { Open: PriceType.Open },
                { High: PriceType.High },
                { Low: PriceType.Low },
                { Typical: PriceType.Typical },
                { Medium: PriceType.Medium },
                { Weighted: PriceType.Weighted }
            ]));
    }

    public Init (): void {
        this.IndicatorShortName(this.GetIndicatorShortName());
    };

    public override GetIndicatorShortName (): string {
        return 'LWMA(' + this.MaPeriod + ';' + PriceTypeMap[this.SourcePrice].toLowerCase() + ')';
    };

    public override getCustomName (): string {
        return 'LWMA (' + this.ProjectName + ')';
    };

    public OnQuote (): void {
        if (this.CurrentData.Count < this.MaPeriod) {
            return;
        }

        let numerator = 0.0; // Numerator of the rate
        let denominator = 0.0; // Denominator of the rate

        for (let i = 0; i < this.MaPeriod; i++) {
            const weight = (this.MaPeriod - i);
            numerator += weight * this.CurrentData.GetPrice(this.SourcePrice, i);
            denominator += weight;
        }
        // Setting the current value of the indicator
        this.SetValue(0, 0, numerator / denominator);
    };
};
